import * as types from './action-types';
import OrdersService, { Order_Status, ProductType } from '../../utility/services/orders-service';
import moment from 'moment';
import {
    constructCanDescription,
    constructEndDescription,
    isProductEndType,
    setValues
} from '../../utility/helpers/order-helpers';
import { LinkedProductionOrderRequest, OrderPlanningProduct } from '../reducers/product-planning';
import { getWeek } from '../../utility/helpers/date-helpers';
import { FilterType } from '../../utility/helpers/filter-helpers';
import { AuthState } from '../reducers/auth';
import {
    Activity,
    filterShipToIdsByPermission,
    SecurityLevel
} from '../../utility/auth/useSecurity';
import { AllocationProduct } from '../../components/pages/ProductPlanningATM/models/AllocationForTable';
import { matchAllocationsBySnoSku, sortProductsAlphabetically } from './product-planning-atm';
import { PlanningAllocation } from '../../components/pages/ProductPlanningATM/models/PlanningAllocation';
import { SnoSkuAllocationsByWeek } from '../reducers/makeit-bulk-atm';

export const loadProductPlanning = (
    weekRange: number,
    allocations: SnoSkuAllocationsByWeek[],
    expandAgedBalance?: string
) => {
    return (dispatch, getState) => {
        dispatch({ type: types.PRODUCT_PLANNING_LOADING });
        const state = getState();
        const currentWeek = getWeek(0);
        const requestedDate = moment(currentWeek).add(weekRange, 'days').format('MM/DD/YYYY');
        const agedBalanceProducts = state.customerDashboard.makeItDashboardWidget?.agedBalance;
        const shipToId = state.customerContext.selectedAccountId;
        const fromDashboardLink = state.productPlanning?.fromDashboardLink;

        const account = state.customerContext.shipToAccounts.find(
            (account) => account.accountId === state.customerContext.selectedAccountId
        );

        const auth: AuthState = getState().auth;
        OrdersService.getProductPlanning(getState(), {
            ShipToIds: filterShipToIdsByPermission(
                auth,
                [state.customerContext.selectedAccountId],
                Activity.PlanningPage,
                SecurityLevel.View
            ),
            WithAvailablePallets: true,
            IntervalLengthDays: 7,
            RequestedDate: [requestedDate],
            TotalsForPreviousNDays: weekRange,
            PaymentTerms: [account.paymentTerms]
        })
            .then((response) => {
                let allocationsBySnoSku = [] as PlanningAllocation[];
                let uniqueSnoSkus = [] as string[];
                response.data.products.forEach((product) => {
                    const canBottleDesc = constructCanDescription(product);
                    const endDesc = constructEndDescription(product);
                    const isEndType = isProductEndType(product.type);
                    product.isActive = product.isAged && fromDashboardLink ? true : false;
                    product.description = isEndType ? endDesc : canBottleDesc;
                    product.graphicIdAndVersion =
                        product.graphicId && product.graphicVersion
                            ? product.graphicId + '-' + product.graphicVersion
                            : '';
                    if (product.destinations) {
                        product.destinations?.forEach((destination) => {
                            if (destination.shipToId.toString() === shipToId) {
                                product.displayId = destination.customerProductId
                                    ? destination.customerProductId
                                    : product.productSku;
                                product.displayName = destination.customerProductName
                                    ? destination.customerProductName
                                    : product.name;
                            }
                        });
                    }
                    if (product.previousPalletTotals && product.previousPalletTotals.length > 0) {
                        product.previousPalletTotals.forEach((total, index) => {
                            setValues(product, total, index);
                        });
                    }
                    if (
                        expandAgedBalance &&
                        agedBalanceProducts &&
                        agedBalanceProducts.length > 0
                    ) {
                        product.isActive =
                            agedBalanceProducts.filter((sku) => sku === product.productSku).length >
                            0;
                    }

                    matchAllocationsBySnoSku(
                        allocationsBySnoSku,
                        product,
                        allocations,
                        uniqueSnoSkus
                    );
                });
                sortProductsAlphabetically(allocationsBySnoSku);
                dispatch({
                    type: types.PRODUCT_PLANNING_LOADED,
                    products: response.data.products,
                    allocationsBySnoSku: allocationsBySnoSku
                });
            })
            .catch((error) => {
                dispatch({
                    type: types.PRODUCT_PLANNING_LOADING_ERROR,
                    error
                });
            });
    };
};

export const productPlanningFilter = (filterValue: string[], type: FilterType) => {
    return (dispatch, getState) => {
        dispatch({ type: types.PRODUCT_PLANNING_LOADING });
        const products = getState().productPlanning.products;
        let filteredProducts: any[] = [];
        if (products) {
            if (filterValue.length === 1 && filterValue[0] === '') {
                filteredProducts = [...products];
            } else {
                filterValue.forEach((filter) => {
                    let currentlyFilteredProducts: any[] = [];
                    if (filter === 'CAMPAIGN') {
                        currentlyFilteredProducts = products.filter((p) => p.campaignTiming);
                    } else if (filter === 'AGING') {
                        currentlyFilteredProducts = products.filter((p) => p.isAged);
                    } else if (filter === 'CAN') {
                        currentlyFilteredProducts = products.filter(
                            (p) => p.type === ProductType.Cans
                        );
                    } else if (filter === 'END') {
                        currentlyFilteredProducts = products.filter(
                            (p) => p.type === ProductType.Ends
                        );
                    }
                    filteredProducts = filteredProducts.concat(
                        currentlyFilteredProducts.filter((p) => !filteredProducts.includes(p))
                    );
                });
            }
        }

        dispatch({
            type: types.PRODUCT_PLANNING_FILTER,
            filteredProducts: filteredProducts,
            view: filterValue
        });
    };
};

export const productPlanningUpdateRange = (range: number) => {
    return (dispatch: any) => {
        dispatch({
            type: types.PRODUCT_PLANNING_UPDATE_RANGE,
            range: range
        });
    };
};

export const productPlanningFromDashboardLink = (fromLink: boolean) => {
    return (dispatch: any) => {
        dispatch({
            type: types.PRODUCT_PLANNING_FROM_DASHBOARD_LINK,
            fromLink: fromLink
        });
    };
};

export const productPlanningToggleQuantityUnit = () => {
    return (dispatch: any, getState) => {
        const existingQuantityUnit = getState().productPlanning.quantityUnit;
        dispatch({
            type: types.PRODUCT_PLANNING_UPDATE_QUANTITY_UNIT,
            quantityUnit: existingQuantityUnit === 'pallets' ? 'eaches' : 'pallets'
        });
    };
};

export const getProductionOrdersForProduct = (
    weekNumber: number,
    product: OrderPlanningProduct | AllocationProduct,
    statuses?: Order_Status[]
) => {
    return (dispatch, getState) => {
        dispatch({ type: types.PRODUCT_PLANNING_LINKED_POS_LOADING });
        const state = getState();
        const shipToId = state.customerContext.selectedAccountId;
        const { paymentTerms, prodOrderType } = state.customerContext.shipToAccounts.find(
            (shipTo) => shipTo.accountId === shipToId
        );
        const startDate = moment()
            .add(weekNumber * 7, 'days')
            .startOf('isoWeek')
            .format('MM/DD/YYYY');
        const endDate = moment(startDate).add(6, 'days').format('MM/DD/YYYY');
        const productSku = product.productSku! as string;

        const auth: AuthState = getState().auth;
        let requestObj: LinkedProductionOrderRequest = {
            ShipToIds: filterShipToIdsByPermission(
                auth,
                [shipToId],
                Activity.PlanningPage,
                SecurityLevel.View
            ),
            endDate: [endDate],
            paymentTerms: [paymentTerms],
            prodOrderType: [prodOrderType]
        };

        if (statuses) {
            requestObj.statuses = statuses;
        }

        if (shipToId) {
            OrdersService.getLinkedProductionOrders(getState(), productSku, requestObj)
                .then((response) => {
                    const linkedPOs = response.data;
                    const newLinkedPOs = {
                        product: product,
                        linkedProductionOrders: linkedPOs.orders
                    };
                    dispatch({
                        type: types.PRODUCT_PLANNING_LINKED_POS_LOADED,
                        linkedPOs: newLinkedPOs
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: types.PRODUCT_PLANNING_LOADING_ERROR,
                        error: `Linked POs for ${productSku} failed to load with: ` + error
                    });
                });
        }
    };
};

export const clearActiveShippingItem = () => {
    return (dispatch) => {
        dispatch({
            type: types.PRODUCT_PLANNING_SHIPPING_ITEM_CLEARED,
            activeShippingItem: undefined
        });
    };
};

export const clearLinkedPOs = () => {
    return (dispatch) => {
        dispatch({
            type: types.PRODUCT_PLANNING_LINKED_PO_CLEARED,
            linkedPOs: undefined
        });
    };
};

export const updateCollapse = (currentProduct: OrderPlanningProduct) => {
    return (dispatch, getState) => {
        const { products } = getState().productPlanning;
        const productsToUpdate = [...products] as OrderPlanningProduct[];

        productsToUpdate.forEach((product) => {
            if (product.productSku === currentProduct.productSku) {
                product.isActive = !product.isActive;
            }
        });

        dispatch({
            type: types.PRODUCT_PLANNING_UPDATE_COLLAPSE,
            products: productsToUpdate
        });
    };
};

export const updateCollapseAll = (collapsed: boolean) => {
    return (dispatch, getState) => {
        const { products } = getState().productPlanning;
        const productsToUpdate = [...products] as OrderPlanningProduct[];

        productsToUpdate.forEach((product) => {
            if (collapsed) {
                product.isActive = false;
            } else if (!collapsed) {
                product.isActive = true;
            }
        });

        dispatch({
            type: types.PRODUCT_PLANNING_UPDATE_COLLAPSE,
            products: productsToUpdate
        });
    };
};

export const resetProductPlanning = () => {
    return (dispatch) => {
        dispatch({
            type: types.PRODUCT_PLANNING_RESET
        });
    };
};
