import React, { useEffect } from 'react';
import { NavigationalPageTemplate } from '../../templates/NavigationalPageTemplate';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { useDispatch } from 'react-redux';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { EndDetailsState } from '../../../store/reducers/product-end-details';
import { loadEndDetailsPageContent } from '../../../store/actions/product-end-details';
import EndDetailsTile from './components/EndDetailsTile';
import { useQuery } from '../../../utility/helpers/query-helpers';
import { getMediaPath } from '../../../utility/helpers/content-helpers';

const useStyles = makeStyles((theme) => ({
    mainHeader: {
        marginBottom: '3em',
        marginLeft: '0.5em'
    },
    tile: {
        '&:last-child': {
            borderRight: 0
        }
    }
}));

const ProductEndDetailsPage = () => {
    const regionCulture = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const regionCode = regionCulture.regionCode;
    const endsState = useTypedSelector<EndDetailsState>((state) => state.endDetails);
    const ends = endsState.endContent;
    const query = useQuery();
    const endCodes = query.get('ids')?.split(',') || [];
    const dispatch = useDispatch();
    const classes = useStyles();
    const isEU = regionCode === 'EU';
    useTranslation();

    useEffect(() => {
        dispatch(loadEndDetailsPageContent(regionCode, regionCulture.cultureCode, endCodes));
    }, []);

    const icon = (
        <img
            src={process.env.PUBLIC_URL + '/assets/DesignInspiration_Icon_white.svg'}
            alt="Inspiration Icon"
        />
    );

    const bannerLink = isEU
        ? getMediaPath(
              `/CustomerPortalCMS/media/customer-portal-media/Regions/EU/PDF%20Library/Ends-Information-Pack.pdf`
          )
        : `/source/content/${regionCode}/Pages/Design-Inspiration`;

    const buttonTitle = isEU ? (
        <Trans i18nKey="endsPack">Ends Information Pack</Trans>
    ) : (
        <Trans i18nKey="designInspiration">Design Inspiration</Trans>
    );

    return (
        <NavigationalPageTemplate
            banner={{
                header: endsState?.banner?.header,
                description: endsState?.banner?.description || '',
                link: '/product-portfolio',
                linkContent: <Trans i18nKey="backToPortfolio">Back to Portfolio</Trans>,
                imageUrl: endsState?.banner?.image?.imageUrl || '',
                buttonLink: bannerLink,
                buttonContent: buttonTitle,
                buttonIcon: icon,
                targetString: isEU ? '_blank' : null
            }}
        >
            <Grid container>
                <Grid container item xs={12} justify="flex-start" className={classes.mainHeader}>
                    <Typography variant="h3">
                        <Trans i18nKey="endFinishesAndEffects">End Finishes & Effects</Trans>
                    </Typography>
                </Grid>
                {ends && (
                    <Grid container item spacing={0} xs={12}>
                        {ends?.map((end, index) => (
                            <EndDetailsTile
                                end={end}
                                key={index}
                                regionCode={regionCode}
                                queryContent={endCodes}
                            />
                        ))}
                    </Grid>
                )}
            </Grid>
        </NavigationalPageTemplate>
    );
};

export default ProductEndDetailsPage;
