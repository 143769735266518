import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { TradeItProduct, TradeItRequestState } from '../../../../store/reducers/trade-it';
import TradeItConfirmationColumns from './TradeItConfirmationColumns';
import TradeItConfirmationRow from './TradeItConfirmationRow';
import StandardTable from '../../../reusable/molecules/StandardTable';

interface Props {
    originalShipToId: string;
    receivingShipToId: string;
}

export default function TradeItConfirmationGrid({ originalShipToId, receivingShipToId }: Props) {
    const [columns, setColumns] = useState<any>([]);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { products, loaded, submittedRequest } = useTypedSelector<TradeItRequestState>(
        (state) => state.tradeItRequest
    );
    const [requestedProducts, setRequestedProducts] = useState<TradeItProduct[] | undefined>(
        undefined
    );

    useEffect(() => {
        setColumns(
            TradeItConfirmationColumns.getTradeItConfirmationColumns(
                cultureCode,
                originalShipToId,
                receivingShipToId
            )
        );
    }, [cultureCode]);

    useEffect(() => {
        if (loaded && products && submittedRequest) {
            const requestedProducts: TradeItProduct[] = [];
            submittedRequest.requestLines.forEach((line) => {
                const requestedProduct = products.find(
                    (product) => product.productSku === line.productSku
                );
                requestedProducts.push({
                    campaignTiming: requestedProduct ? requestedProduct.campaignTiming : undefined,
                    displayName: requestedProduct ? requestedProduct.displayName : undefined,
                    productSku: line.productSku,
                    graphicIdAndVersion: requestedProduct
                        ? requestedProduct.graphicIdAndVersion
                        : undefined,
                    displayId: requestedProduct ? requestedProduct.displayId : undefined,
                    originalShipToProductionBalance: line.accountFromOriginalBalance,
                    receivingShipToProductionBalance: line.accountToOriginalBalance,
                    quantityToTrade: line.quantity,
                    quantityPerPallet: requestedProduct
                        ? requestedProduct?.quantityPerPallet
                        : undefined
                });
            });
            setRequestedProducts(requestedProducts);
        }
    }, [products, loaded, submittedRequest]);

    const CustomOrderRow = ({ row }: { row: TradeItProduct }) => {
        return <TradeItConfirmationRow product={row} />;
    };

    return (
        <Grid container data-testid="products-grid">
            {requestedProducts && (
                <StandardTable
                    gridName="Trade It Confirmation Grid"
                    columns={columns}
                    lines={requestedProducts}
                    rowComponent={CustomOrderRow}
                    columnWidths={[20, 13, 14, 14, 11, 14, 14]}
                    itemsPerPage={10}
                />
            )}
        </Grid>
    );
}
