import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { Grid, makeStyles, Typography, Paper, Button } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { blackWeight, ballGray, containerMaxWidth } from '../../../themes/globalConstants';
import { useHistory, useParams } from 'react-router-dom';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { AuthState } from '../../../store/reducers/auth';
import { useDispatch } from 'react-redux';
import { getShipToAccounts } from '../../../store/actions/customer-context';
import { Activity } from '../../../utility/auth/useSecurity';
import { DefaultQueryParams } from '../../../utility/helpers/query-helpers';
import { MakeItATMOrderDetailsState } from '../../../store/reducers/atm-production-order-details';
import {
    loadATMLinkedDeliveryOrders,
    loadATMProductionOrder
} from '../../../store/actions/atm-production-order-details';
import {
    DateProperties,
    getDateProperties,
    groupOrdersBySnoSku
} from '../../../utility/helpers/make-it-bulk-helpers';
import moment from 'moment';
import {
    BulkUploadMakeItATMState,
    MakeItBulkATMOrder,
    MakeItBulkATMOrderByWeekAndSku
} from '../../../store/reducers/makeit-bulk-atm';
import { getAllocationsWithShipTos } from '../../../store/actions/makeit-bulk-atm';
import { loadMakeItBulkProducts } from '../../../store/actions/makeit-bulk-upload';
import { BulkUploadMakeItState } from '../../../store/reducers/makeit-bulk-upload';
import MakeItATMOrderDetailsSubheader from './components/MakeItATMOrderDetailsSubheader';
import MakeItATMOrderDetailsGrid from './components/MakeItATMOrderDetailsGrid';
import MakeItATMOrderDetailsShareLinks from './components/MakeItATMOrderDetailsShareLinks';
import { setUseSummaryStoredSearch } from '../../../store/actions/make-it-summary';

const useStyles = makeStyles(() => ({
    paper: {
        marginTop: '3em',
        padding: '2em',
        borderRadius: 4,
        maxWidth: containerMaxWidth
    },
    header: {
        marginTop: '1em',
        marginBottom: '2em'
    },
    subheader: {
        marginBottom: '1em'
    },
    title: {
        fontWeight: blackWeight,
        color: ballGray
    },
    actionBtn: {
        borderRadius: 'unset'
    },
    italic: {
        fontStyle: 'italic'
    }
}));

const MakeItATMOrderDetails = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const { id } = useParams<DefaultQueryParams>();
    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { atmProductionOrder } = useTypedSelector<MakeItATMOrderDetailsState>(
        (state) => state.atmProductionOrderDetails
    );
    const { products } = useTypedSelector<BulkUploadMakeItState>(
        (state) => state.bulkUploadMakeItState
    );
    const { allocations } = useTypedSelector<BulkUploadMakeItATMState>(
        (state) => state.bulkUploadMakeItATMState
    );
    const [activeDate, setActiveDate] = useState<DateProperties>({
        fullDate: '',
        week: '',
        year: ''
    });
    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);
    const [shipToName, setShipToName] = useState<string>('');
    const [accountId, setAccountId] = useState<string>('');
    const [shipToAddress, setDisplayAddress] = useState<string>('');
    const [ordersBySnoSku, setOrdersBySnoSku] = useState<MakeItBulkATMOrderByWeekAndSku[]>();

    const handleBackNavigation = () => {
        history.goBack();
    };

    // If going back to the ATM Make-It Summary page, use the stored search to repopulate the search bar
    useEffect(() => {
        return () => {
            if (history.location.pathname.includes('make-it-po-summary')) {
                dispatch(setUseSummaryStoredSearch(true));
            }
        };
    }, [dispatch, history]);

    useEffect(() => {
        if (!products && shipToAccounts && shipToAccounts.length > 0) {
            const shipToIds = shipToAccounts.map((account) => {
                return account.accountId;
            });
            dispatch(loadMakeItBulkProducts(shipToIds, true, false, false));
        }
        
    }, [dispatch, products, shipToAccounts]);

    useEffect(() => {
        if (atmProductionOrder) {
            setActiveDate(getDateProperties(moment(atmProductionOrder.weekStart)));
        }
        
    }, [atmProductionOrder]);

    useEffect(() => {
        if (
            atmProductionOrder &&
            !allocations &&
            accountId &&
            activeDate &&
            activeDate.fullDate !== ''
        ) {
            let startDate = moment(activeDate.fullDate).format('M/D/YYYY');
            let endDate = moment(activeDate.fullDate).format('M/D/YYYY');
            dispatch(getAllocationsWithShipTos(startDate, endDate, [Number(accountId)]));
        }
    }, [activeDate, accountId, atmProductionOrder, allocations, dispatch]);

    useEffect(() => {
        if (allocations && atmProductionOrder) {
            let orderAsATMBulkOrder = [atmProductionOrder] as unknown as MakeItBulkATMOrder[];
            let groupedOrdersBySnoSku = groupOrdersBySnoSku(orderAsATMBulkOrder, allocations);
            setOrdersBySnoSku(groupedOrdersBySnoSku);
        }
    }, [allocations, atmProductionOrder]);

    useEffect(() => {
        if (id && products) {
            dispatch(loadATMProductionOrder(Number(id)));
            dispatch(loadATMLinkedDeliveryOrders(Number(id)));
        }
        
    }, [dispatch, id, products]);

    useEffect(() => {
        if (permissions) {
            if (!shipToAccounts?.length) {
                dispatch(getShipToAccounts());
            }
        }
        
    }, [dispatch, shipToAccounts, permissions, accountId]);

    const setShippingInformation = (accountInformation) => {
        if (accountInformation[0]) {
            setShipToName(accountInformation[0].name);
            setAccountId(accountInformation[0].accountId);
            if (accountInformation[0].address) {
                const shipToAddress = accountInformation[0].address;
                setDisplayAddress(
                    shipToAddress.address1?.trimEnd() +
                        ', ' +
                        shipToAddress.city +
                        ', ' +
                        shipToAddress.stateProvince +
                        ' ' +
                        shipToAddress.postalCode
                );
            }
        }
    };

    if (!shipToAddress.length && !shipToName.length) {
        if (atmProductionOrder && shipToAccounts) {
            setShippingInformation(
                shipToAccounts.filter(
                    (account) => account.accountId === atmProductionOrder.shipToId.toString()
                )
            );
        }
    }

    const footerActions = (
        <>
            <Grid item>
                <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    data-testid="back-btn"
                    className={classes.actionBtn}
                    onClick={handleBackNavigation}
                >
                    <Trans i18nKey="goBack">Go Back</Trans>
                </Button>
            </Grid>
        </>
    );

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('makeIt', 'Make It'),
                description: t('productionOrderDetails', 'Production Order Details'),
                thinBanner: true
            }}
            actionFooter={{
                footerAction: footerActions,
                justify: 'center',
                sticky: true
            }}
            activity={Activity.MakeItATMSummary}
        >
            <Paper elevation={2} className={classes.paper}>
                <Grid container spacing={2} align-items="flex-start">
                    <Grid container alignItems="baseline" className={classes.header}>
                        <Grid item xs={9} justify="flex-start">
                            <Typography
                                variant="h3"
                                className={clsx(classes.title, {
                                    [classes.italic]: !atmProductionOrder?.productionOrderNumber
                                })}
                            >
                                <Trans i18nKey="ballOrderNumber">Ball Order #</Trans>{' '}
                                {atmProductionOrder?.productionOrderNumber ?? (
                                    <Trans i18nKey="pending">Pending</Trans>
                                )}
                            </Typography>
                            {atmProductionOrder?.productionOrderNumber === undefined && (
                                <Typography variant="h6">
                                    <Trans i18nKey="cannotExportDetails">
                                        You cannot export order details until a Ball Production
                                        Order Number is assigned
                                    </Trans>
                                </Typography>
                            )}
                        </Grid>

                        <Grid container item xs={3} justify="flex-end">
                            {atmProductionOrder && ordersBySnoSku && ordersBySnoSku[0] && (
                                <MakeItATMOrderDetailsShareLinks
                                    shipToName={shipToName}
                                    shipToAddress={shipToAddress}
                                    atmProductionOrder={atmProductionOrder}
                                    lines={ordersBySnoSku[0].linesBySnoSku}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid container className={classes.subheader}>
                        {atmProductionOrder && (
                            <MakeItATMOrderDetailsSubheader
                                shipToAddress={shipToAddress}
                                shipToName={shipToName}
                                shipToId={accountId}
                                order={atmProductionOrder}
                            />
                        )}
                    </Grid>
                </Grid>
                {ordersBySnoSku &&
                    ordersBySnoSku[0] &&
                    ordersBySnoSku[0].linesBySnoSku?.map((linesBySnoSkuItem) => {
                        if (linesBySnoSkuItem.lines.length > 0) {
                            return (
                                <MakeItATMOrderDetailsGrid
                                    linesBySnoSku={linesBySnoSkuItem}
                                    weekStart={activeDate}
                                />
                            );
                        }
                        return null;
                    })}
            </Paper>
        </ProcessingPageTemplate>
    );
};

export default MakeItATMOrderDetails;
