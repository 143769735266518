import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class ScrapItConfirmationColumns {
    static getScrapItColumns = (culture: string) => {
        return [
            createColumn('displayId', {
                dataType: ColumnDataType.Numeric,
                label: `${TranslationService.getTranslatedText(
                    culture,
                    'productDescription'
                )} (${TranslationService.getTranslatedText(
                    culture,
                    'ballProductId'
                )} / ${TranslationService.getTranslatedText(culture, 'graphicId')})`,
                filterable: true,
                sortable: true,
                searchable: true
            }),
            createColumn('customerProductId', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'customerProductId'),
                filterable: true,
                sortable: true,
                searchable: true,
            }),
            createColumn('graphicIdAndVersion', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('graphicId', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('displayName', {
                dataType: ColumnDataType.String,
                filterable: true,
                sortable: true,
                searchable: true,
                visible: false
            }),
            createColumn('productSku', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('description', {
                dataType: ColumnDataType.String,
                filterable: true,
                sortable: true,
                searchable: true,
                visible: false
            }),
            //Hidden until Multiple ShipTo Selection allowed
            createColumn('brand', {
                dataType: ColumnDataType.String,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('coating', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'coating'),
                filterable: true,
                sortable: true,
                searchable: true
            }),
            createColumn('inputPallets', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(
                    culture,
                    'quantityToScrap'
                ),
                filterable: false
            }),
            createColumn('inputEaches', {
                dataType: ColumnDataType.String,
                filterable: false,
                sortable: false,
                visible: false
            }),
            createColumn('type', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('quantityPerPallet', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('customerProductId', {
                dataType: ColumnDataType.String,
                filterable: false,
                sortable: true,
                searchable: true,
                visible: false
            }),
            createColumn('customerProductName', {
                dataType: ColumnDataType.String,
                filterable: false,
                sortable: true,
                searchable: true,
                visible: false
            })
        ];
    };
}
