import React, { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    Button,
    makeStyles,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from '@material-ui/core';
import {
    brightGrey,
    boldWeight,
    large,
    xl,
    blackWeight,
    small
} from '../../../themes/globalConstants';
import { useTranslation, Trans } from 'react-i18next';
import Modal from './Modal';
import { currencyFormatter } from '../../../utility/helpers/formatters';
import { productTypeHelper } from '../../../utility/helpers/order-helpers';
import { MakeItBulkLineItem } from '../../../store/reducers/makeit-bulk-upload';
import { MakeItBulkATMLineItem } from '../../../store/reducers/makeit-bulk-atm';
import moment from 'moment';
import { getMoqFee } from '../../../utility/helpers/production-order-helpers';

interface Props {
    open: boolean;
    onClose: () => void;
    onAcceptFee: (accept: boolean) => void;
    isAtm: boolean;
    bulkProducts?: MakeItBulkLineItem[];
    atmProducts?: MakeItBulkATMLineItem[];
}

const useStyles = makeStyles((theme) => ({
    modal: {
        '& .MuiPaper-root': {
            maxWidth: '80em'
        },
        '& .MuiDialog-paperScrollPaper': {
            borderRadius: 'unset',
            overflow: 'hidden'
        },
        '& .MuiDialogTitle-root': {
            justifyContent: 'left'
        }
    },
    content: {
        letterSpacing: '1px',
        color: brightGrey,
        padding: '2em'
    },
    contentWrapper: {
        width: '100%'
    },
    textBuffer: {
        marginTop: '1em'
    },
    text: {
        fontSize: large,
        fontWeight: boldWeight,
        letterSpacing: '0.2px'
    },
    button: {
        height: 40,
        marginTop: 30,
        fontSize: small,
        fontWeight: blackWeight,
        textTransform: 'uppercase',
        marginRight: 5,
        padding: '0.5em',
        width: '90%'
    },
    buttonContainer: {
        marginTop: '1em'
    },
    contentHeader: {
        fontSize: xl,
        fontWeight: boldWeight,
        color: theme.palette.info.dark
    },
    table: {
        margin: '1.5em 0'
    },
    eachesText: {
        fontSize: small
    }
}));

const MakeItBulkFeeModal = (props: Props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(props.open);
    const [formattedProducts, setFormattedProducts] = useState<any[]>([]);

    const handleCloseModal = () => {
        setOpen(false);
        props.onClose();
    };

    const handleAcceptFee = () => {
        props.onAcceptFee(true);
        handleCloseModal();
    };

    const handleEditQuantity = () => {
        handleCloseModal();
    };

    const { t } = useTranslation();

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    useEffect(() => {
        let productsWithQuantities: any[] = [];
        if (props.bulkProducts) {
            productsWithQuantities = props.bulkProducts.map((product) => {
                product.displayQuantity = product.quantity
                    ? product.quantity
                    : product.palletQuantity;
                return product;
            });
        } else if (props.atmProducts) {
            productsWithQuantities = props.atmProducts.map((product) => {
                product.displayQuantity = product.quantity
                    ? product.quantity
                    : product.palletQuantity;
                return product;
            });
        }
        let filteredProducts = productsWithQuantities.filter(
            (product) =>
                productTypeHelper(product.type) !== 'END' &&
                product.displayQuantity &&
                product.minimumOrderQuantity &&
                product.displayQuantity < product.minimumOrderQuantity
        );
        setFormattedProducts(filteredProducts);
    }, [props.atmProducts, props.bulkProducts]);

    return (
        <Modal
            open={open}
            close={handleCloseModal}
            title={t('minimumOrderQuantities', 'Minimum Order Quantities')}
            closeIcon={true}
            fullWidth={true}
            className={classes.modal}
        >
            <Grid
                container
                justify="flex-start"
                className={classes.content}
                data-testid="fee-modal"
            >
                <Grid item className={classes.contentWrapper}>
                    <Grid item className={classes.textBuffer}>
                        <Typography className={classes.text} data-testid="subheader">
                            <Trans i18nKey="feeWarning">
                                One or more quantities do not meet the minimum order quantity and
                                will incur a fee.
                            </Trans>
                        </Typography>
                    </Grid>
                    <TableContainer>
                        <Table
                            className={classes.table}
                            size="medium"
                            aria-label="fee table"
                            data-testid="fee-table"
                        >
                            <TableHead>
                                <TableRow>
                                    {props.isAtm ? (
                                        <TableCell colSpan={1} data-testid="atm-week-cell">
                                            <Typography className={classes.contentHeader}>
                                                <Trans i18nKey="orderWeek">Order Week</Trans>
                                            </Typography>
                                        </TableCell>
                                    ) : (
                                        <TableCell colSpan={2} data-testid="customer-po-cell">
                                            <Typography className={classes.contentHeader}>
                                                <Trans i18nKey="customerPoNumber">
                                                    Customer PO #
                                                </Trans>
                                            </Typography>
                                        </TableCell>
                                    )}
                                    <TableCell colSpan={2} data-testid="ship-to-cell">
                                        <Typography className={classes.contentHeader}>
                                            <Trans i18nKey="shipTo">Ship To</Trans>
                                        </Typography>
                                    </TableCell>
                                    <TableCell colSpan={2} data-testid="product-cell">
                                        <Typography className={classes.contentHeader}>
                                            <Trans i18nKey="product">Product</Trans>
                                        </Typography>
                                    </TableCell>
                                    <TableCell colSpan={1} data-testid="min-order-qty-cell">
                                        <Typography className={classes.contentHeader}>
                                            <Trans i18nKey="minimumOrderQuantity">
                                                Minimum Order Quantity
                                            </Trans>
                                        </Typography>
                                    </TableCell>
                                    <TableCell colSpan={1} data-testid="requested-qty-cell">
                                        <Typography className={classes.contentHeader}>
                                            <Trans i18nKey="requestedQuantity">
                                                Requested Quantity
                                            </Trans>
                                        </Typography>
                                    </TableCell>
                                    <TableCell colSpan={2} data-testid="fee-cell">
                                        <Typography className={classes.contentHeader}>
                                            <Trans i18nKey="fee">Fee</Trans>
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formattedProducts.map((product, index) => {
                                    return (
                                        <TableRow key={index}>
                                            {props.isAtm ? (
                                                <TableCell colSpan={1}>
                                                    <Typography variant="body1">
                                                        {moment(product.weekStart).format(
                                                            'MM/DD/YYYY'
                                                        )}
                                                    </Typography>
                                                </TableCell>
                                            ) : (
                                                <TableCell colSpan={2}>
                                                    <Typography variant="body1">
                                                        {product.customerProductionOrderId}
                                                    </Typography>
                                                </TableCell>
                                            )}
                                            <TableCell colSpan={2}>
                                                <Typography variant="body1">
                                                    {product.shipToName}
                                                </Typography>
                                                <Typography variant="body1">
                                                    {product.shipToAddress}{' '}
                                                    {'(' + product.shipToId?.toString() + ')'}
                                                </Typography>
                                            </TableCell>
                                            <TableCell colSpan={2}>
                                                <Typography variant="body1">
                                                    {product.displayId}
                                                    {' - '}
                                                    {product.displayName}
                                                </Typography>
                                            </TableCell>
                                            {props.isAtm ? (
                                                <TableCell colSpan={1}>
                                                    <Typography variant="body1">
                                                        {product.minimumOrderQuantity}
                                                        {' PL'}
                                                    </Typography>
                                                    <Typography
                                                        variant="subtitle1"
                                                        className={classes.eachesText}
                                                    >
                                                        {Number(
                                                            product.minimumOrderQuantity *
                                                                product.quantityPerPallet
                                                        ).toLocaleString()}
                                                        {' ea.'}
                                                    </Typography>
                                                </TableCell>
                                            ) : (
                                                <TableCell colSpan={1}>
                                                    <Typography variant="body1">
                                                        {product.minimumOrderQuantity}
                                                        {' PL'}
                                                    </Typography>
                                                </TableCell>
                                            )}
                                            {props.isAtm ? (
                                                <TableCell colSpan={1}>
                                                    <Typography variant="body1">
                                                        {product.palletQuantity}
                                                        {' PL'}
                                                    </Typography>
                                                    <Typography
                                                        variant="subtitle1"
                                                        className={classes.eachesText}
                                                    >
                                                        {Number(
                                                            product.palletQuantity *
                                                                product.quantityPerPallet
                                                        ).toLocaleString()}
                                                        {' ea.'}
                                                    </Typography>
                                                </TableCell>
                                            ) : (
                                                <TableCell colSpan={1}>
                                                    <Typography variant="body1">
                                                        {product.palletQuantity}
                                                        {' PL'}
                                                    </Typography>
                                                </TableCell>
                                            )}

                                            <TableCell align="left" colSpan={2}>
                                                {currencyFormatter(
                                                    'NA',
                                                    getMoqFee(
                                                        product.displayQuantity,
                                                        product.moqFees
                                                    )
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid item className={classes.textBuffer}>
                        <Typography className={classes.text} data-testid="confirm-fee-msg">
                            <Trans i18nKey="confirmFeeMessage">
                                Do you want to update the quantity or accept the fee?
                            </Trans>
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        alignItems="center"
                        justify="flex-end"
                        className={classes.buttonContainer}
                    >
                        <Grid item xs={3}>
                            <Button
                                type="button"
                                color="primary"
                                variant="outlined"
                                data-testid="edit-qty-btn"
                                onClick={handleEditQuantity}
                                className={classes.button}
                            >
                                <Trans i18nKey="editQuantity">Edit Quantity</Trans>
                            </Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                data-testid="accept-fee-btn"
                                onClick={handleAcceptFee}
                                className={classes.button}
                            >
                                <Trans i18nKey="acceptFee">Accept Fee</Trans>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default MakeItBulkFeeModal;
