import { Activity, SecurityLevel } from '../../../utility/auth/useSecurity';
import { Grid, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { useDispatch } from 'react-redux';
import { SCRAP_IT_RESET_PRODUCT_STATE } from '../../../store/actions/action-types';
import ScrapItSummaryTable from './components/ScrapItSummaryTable';
import { loadScrapItRequests } from '../../../store/root-actions';
import { ScrapItState } from '../../../store/reducers/scrap-it';

const useStyles = makeStyles(() => ({
    dashboard: {
        padding: '0'
    },
    tableContainer: {
        marginTop: '1.750em'
    }
}));

export default function ScrapItSummary() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { scrapItRequests, loaded } = useTypedSelector<ScrapItState>(
        (state) => state.scrapItState
    );

    useEffect(() => {
        if (selectedAccountId) {
            dispatch(loadScrapItRequests(parseInt(selectedAccountId)));
        }
    }, [selectedAccountId]);

    useEffect(() => {
        return () => {
            dispatch({ type: SCRAP_IT_RESET_PRODUCT_STATE });
        };
    }, [dispatch]);

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('scrapIt', 'Scrap It'),
                description: t('summary', 'Summary'),
                thinBanner: true,
                displayDropdown: true
            }}
            activity={Activity.ScrapIt}
            restrictToSecurityLevel={SecurityLevel.Edit}
            loading={false}
        >
            <Grid container className={classes.dashboard} data-testid={'scrap-it-summary'}>
                {loaded && (
                    <Grid
                        data-testid="scrap-it-summary-table"
                        item
                        xs={12}
                        className={classes.tableContainer}
                    >
                        <ScrapItSummaryTable
                            scrapItRequests={scrapItRequests}
                        ></ScrapItSummaryTable>
                    </Grid>
                )}
            </Grid>
        </ProcessingPageTemplate>
    );
}
