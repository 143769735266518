import { FormHelperText, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import { Controller, ErrorMessage, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { red } from '../../../../themes/globalConstants';
import { enUS } from '../../../../utility/translations/locales';
import FormSection from '../../../reusable/molecules/FormSection';
import PhoneInput from '../../../reusable/molecules/PhoneInput';
import TextInput from '../../../reusable/molecules/TextInput';
import clsx from 'clsx';
import { SupplierReference } from '../../../../store/reducers/onboarding-dashboard';
import { isValidEmail } from '../../../../utility/helpers/user-helpers';

interface Props {
    supplierReferences?: SupplierReference[];
}

const useStyles = makeStyles(() => ({
    helperText: {
        '& .MuiFormHelperText-root': {
            marginLeft: 0
        }
    },
    errorText: {
        color: red
    },
    inputLine: {
        marginBottom: '0.75em'
    },
    supplierReferenceHelper: {
        marginBottom: '0.5em'
    },
    formSectionWrapper: {
        padding: '8px 8px 0px 8px'
    }
}));

export default function SupplierReferences({ supplierReferences }: Props): ReactElement {
    const { control, errors, setValue, getValues } = useFormContext();
    const { t } = useTranslation();
    const classes = useStyles();
    const [supplierReferenceSections, setSupplierReferenceSections] = useState<JSX.Element[]>([]);
    const [fieldsHavePopulated, setFieldsHavePopulated] = useState<boolean>(false);

    const isRequired = <Trans i18nKey="required">Required</Trans>;

    const fieldName = 'supplierReferences';

    useEffect(() => {
        if (
            supplierReferenceSections &&
            supplierReferenceSections.length === 3 &&
            supplierReferences?.length &&
            !fieldsHavePopulated
        ) {
            supplierReferences.forEach((reference, index) => {
                setValue(`${fieldName}[${index}].firstName`, reference.firstName);
                setValue(`${fieldName}[${index}].lastName`, reference.lastName);
                setValue(`${fieldName}[${index}].address`, reference.address);
                setValue(`${fieldName}[${index}].contact`, reference.contact);
                setValue(`${fieldName}[${index}].contactEmail`, reference.contactEmail);
                setValue(`${fieldName}[${index}].phoneNumber`, reference.phoneNumber);
                setValue(`${fieldName}[${index}].faxNumber`, reference.faxNumber);
            });
            setFieldsHavePopulated(true);
        }
    }, [supplierReferenceSections, supplierReferences]);

    useEffect(() => {
        if (control && errors) {
            const sections: JSX.Element[] = [];

            for (let index = 0; index < 3; index++) {
                sections.push(
                    <Fragment key={index}>
                        <Grid
                            container
                            xs={12}
                            spacing={2}
                            justify="flex-start"
                            className={classes.inputLine}
                        >
                            <Grid item xs={3}>
                                <TextInput
                                    type="text"
                                    label={<Trans i18nKey="firstName">First Name</Trans>}
                                    name={`${fieldName}[${index}].firstName`}
                                    testId={`${fieldName}[${index}].firstName`}
                                    control={control}
                                    rules={{ required: isRequired }}
                                    error={
                                        errors[`${fieldName}`] &&
                                        errors[`${fieldName}`][index]?.firstName
                                    }
                                    errors={errors}
                                    styles={classes.helperText}
                                    inputProps={{
                                        'data-testid': `${fieldName}[${index}].firstName`,
                                        maxLength: 50
                                    }}
                                />
                                <FormHelperText className={classes.errorText}>
                                    <ErrorMessage
                                        errors={errors}
                                        name={`${fieldName}[${index}].firstName`}
                                        message={isRequired}
                                    />
                                </FormHelperText>
                            </Grid>
                            <Grid item xs={3}>
                                <TextInput
                                    type="text"
                                    label={<Trans i18nKey="lastName">Last Name</Trans>}
                                    name={`${fieldName}[${index}].lastName`}
                                    testId={`${fieldName}[${index}].lastName`}
                                    control={control}
                                    rules={{ required: isRequired }}
                                    error={
                                        errors[`${fieldName}`] &&
                                        errors[`${fieldName}`][index]?.lastName
                                    }
                                    errors={errors}
                                    styles={classes.helperText}
                                    inputProps={{
                                        'data-testid': `${fieldName}[${index}].lastName`,
                                        maxLength: 50
                                    }}
                                />
                                <FormHelperText className={classes.errorText}>
                                    <ErrorMessage
                                        errors={errors}
                                        name={`${fieldName}[${index}].lastName`}
                                        message={isRequired}
                                    />
                                </FormHelperText>
                            </Grid>
                        </Grid>
                        <Grid xs={12} className={classes.inputLine}>
                            <TextInput
                                type="text"
                                label={<Trans i18nKey="address">Address</Trans>}
                                name={`${fieldName}[${index}].address`}
                                testId={`${fieldName}[${index}].address`}
                                control={control}
                                rules={{ required: isRequired }}
                                error={
                                    errors[`${fieldName}`] && errors[`${fieldName}`][index]?.address
                                }
                                errors={errors}
                                styles={classes.helperText}
                                inputProps={{
                                    'data-testid': `${fieldName}[${index}].address`,
                                    maxLength: 40
                                }}
                            />
                            <FormHelperText className={classes.errorText}>
                                <ErrorMessage
                                    errors={errors}
                                    name={`${fieldName}[${index}].address`}
                                    message={isRequired}
                                />
                            </FormHelperText>
                        </Grid>
                        <Grid container xs={12} spacing={2} className={classes.inputLine}>
                            <Grid item xs={6}>
                                <TextInput
                                    type="text"
                                    label={<Trans i18nKey="arContact">A/R Contact</Trans>}
                                    name={`${fieldName}[${index}].contact`}
                                    testId={`${fieldName}[${index}].contact`}
                                    control={control}
                                    rules={{ required: isRequired }}
                                    error={
                                        errors[`${fieldName}`] &&
                                        errors[`${fieldName}`][index]?.contact
                                    }
                                    errors={errors}
                                    styles={classes.helperText}
                                    inputProps={{
                                        'data-testid': `${fieldName}[${index}].contact`,
                                        maxLength: 50
                                    }}
                                />
                                <FormHelperText className={classes.errorText}>
                                    <ErrorMessage
                                        errors={errors}
                                        name={`${fieldName}[${index}].contact`}
                                        message={isRequired}
                                    />
                                </FormHelperText>
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput
                                    type="text"
                                    label={<Trans i18nKey="arEmail">A/R Email</Trans>}
                                    name={`${fieldName}[${index}].contactEmail`}
                                    testId={`${fieldName}[${index}].contactEmail`}
                                    control={control}
                                    rules={{
                                        required: isRequired,
                                        validate: {
                                            isValidEmail: (value) => {
                                                return (
                                                    isValidEmail(value) || (
                                                        <Trans i18nKey="incorrectEmailAddress">
                                                            Invalid Email Format
                                                        </Trans>
                                                    )
                                                );
                                            }
                                        }
                                    }}
                                    error={
                                        errors[`${fieldName}`] &&
                                        errors[`${fieldName}`][index]?.contactEmail
                                    }
                                    errors={errors}
                                    styles={classes.helperText}
                                    inputProps={{
                                        'data-testid': `${fieldName}[${index}].firstName`,
                                        maxLength: 255
                                    }}
                                />
                                <FormHelperText className={classes.errorText}>
                                    {errors[`${fieldName}`] &&
                                        errors[`${fieldName}`][index]?.contactEmail && (
                                            <ErrorMessage
                                                errors={errors}
                                                name={`${fieldName}[${index}].contactEmail`}
                                            />
                                        )}
                                </FormHelperText>
                            </Grid>
                        </Grid>
                        <Grid container xs={12} spacing={2} className={classes.inputLine}>
                            <Grid item xs={6}>
                                <Controller
                                    as={
                                        <PhoneInput
                                            label={
                                                <Trans i18nKey="phoneNumber">Phone Number</Trans>
                                            }
                                            testId={`${fieldName}[${index}].phoneNumber`}
                                            disableAreaCodes={true}
                                            defaultCountry={'us'}
                                            name={`${fieldName}[${index}].phoneNumber`}
                                            error={
                                                errors[`${fieldName}`] &&
                                                errors[`${fieldName}`][index]?.phoneNumber
                                            }
                                            defaultValue={getValues(
                                                `${fieldName}[${index}].phoneNumber`
                                            )}
                                        />
                                    }
                                    rules={{
                                        required: isRequired,
                                        pattern: /^\+([0-9][ \-()]{0,2}){9,14}[0-9]$/
                                    }}
                                    name={`${fieldName}[${index}].phoneNumber`}
                                    control={control}
                                    errors={errors}
                                    styles={clsx({
                                        [classes.errorText]:
                                            errors[`${fieldName}`] &&
                                            errors[`${fieldName}`][index]?.phoneNumber
                                    })}
                                    defaultValue=""
                                />
                                <FormHelperText className={classes.errorText}>
                                    {errors[`${fieldName}`] &&
                                        errors[`${fieldName}`][index]?.phoneNumber?.type ===
                                            'required' && (
                                            <ErrorMessage
                                                errors={errors}
                                                name={`${fieldName}[${index}].phoneNumber`}
                                                message={isRequired}
                                            />
                                        )}
                                    {errors[`${fieldName}`] &&
                                        errors[`${fieldName}`][index]?.phoneNumber?.type ===
                                            'pattern' && (
                                            <ErrorMessage
                                                errors={errors}
                                                name={`${fieldName}[${index}].phoneNumber`}
                                                message={t(
                                                    'invalidPhoneNumber',
                                                    'Invalid Phone Number'
                                                )}
                                            />
                                        )}
                                </FormHelperText>
                            </Grid>
                            <Grid item xs={6}>
                                <Controller
                                    as={
                                        <PhoneInput
                                            label={<Trans i18nKey="faxNumber">Fax Number</Trans>}
                                            testId={`${fieldName}[${index}].faxNumber`}
                                            disableAreaCodes={true}
                                            defaultCountry={'us'}
                                            name={`${fieldName}[${index}].faxNumber`}
                                            error={
                                                errors[`${fieldName}`] &&
                                                errors[`${fieldName}`][index]?.faxNumber
                                            }
                                            defaultValue={getValues(
                                                `${fieldName}[${index}].faxNumber`
                                            )}
                                        />
                                    }
                                    rules={{
                                        required: isRequired,
                                        pattern: /^\+([0-9][ \-()]{0,2}){9,14}[0-9]$/
                                    }}
                                    name={`${fieldName}[${index}].faxNumber`}
                                    control={control}
                                    errors={errors}
                                    styles={clsx({
                                        [classes.errorText]:
                                            errors[`${fieldName}`] &&
                                            errors[`${fieldName}`][index]?.faxNumber
                                    })}
                                    defaultValue=""
                                />
                                <FormHelperText className={classes.errorText}>
                                    {errors[`${fieldName}`] &&
                                        errors[`${fieldName}`][index]?.faxNumber?.type ===
                                            'required' && (
                                            <ErrorMessage
                                                errors={errors}
                                                name={`${fieldName}[${index}].faxNumber`}
                                                message={isRequired}
                                            />
                                        )}
                                    {errors[`${fieldName}`] &&
                                        errors[`${fieldName}`][index]?.faxNumber?.type ===
                                            'pattern' && (
                                            <ErrorMessage
                                                errors={errors}
                                                name={`${fieldName}[${index}].faxNumber`}
                                                message={t(
                                                    'invalidFaxNumber',
                                                    'Invalid Fax Number'
                                                )}
                                            />
                                        )}
                                </FormHelperText>
                            </Grid>
                        </Grid>
                    </Fragment>
                );
            }
            setSupplierReferenceSections(sections);
        }
    }, [control, errors]);

    return (
        <FormSection
            testId={'credit-app-header-section'}
            sectionHeader={<Trans i18nKey="supplierReferences">{enUS.supplierReferences}</Trans>}
        >
            <Grid xs={12} className={classes.formSectionWrapper}>
                <Grid xs={12}>
                    <Typography className={classes.supplierReferenceHelper}>
                        <Trans i18nKey="supplierReferenceHelper">
                            {enUS.supplierReferenceHelper}
                        </Trans>
                    </Typography>
                </Grid>
                {supplierReferenceSections}
            </Grid>
        </FormSection>
    );
}
