import React, { ReactElement, useMemo } from 'react';
import rootTheme from '../../../../themes/rootTheme';
import { Grid, makeStyles, TextField } from '@material-ui/core';
import { styles } from './styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { DateTimePicker } from '@material-ui/pickers';
import moment, { Moment } from 'moment';
import { Trans } from 'react-i18next';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { Region } from '../../../../store/reducers/graphic-intake';

interface Props {
    startDate: Moment;
    endDate: Moment;
    startDateLabel: string;
    endDateLabel: string;
    startDateErrorMessage?: string;
    endDateErrorMessage?: string;
    setStartDate: (date) => void;
    setEndDate: (date) => void;
}

export default function DateSection({
    startDate,
    endDate,
    startDateLabel,
    endDateLabel,
    startDateErrorMessage,
    endDateErrorMessage,
    setStartDate,
    setEndDate
}: Props): ReactElement {
    const useStyles = makeStyles(styles(rootTheme) as any);
    const classes = useStyles();
    const { regionCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    // Setting conversion to local time zone in the DatePicker instead of the Page Input
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const convertedStartDate = moment.utc(startDate).local();
    const convertedEndDate = moment.utc(endDate).local();

    const handleDateTimePickerDateReformat = () => {
        setTimeout(() => {
            const dateElement = document.querySelector('.MuiTypography-root.MuiTypography-h4');
            if (!dateElement || regionCode === Region.NA) return;

            // If not NA region, split the date text and put the number first
            const textElements = dateElement.innerHTML.split(' ');
            if (Number.isNaN(Number.parseInt(textElements[0])))
                dateElement.innerHTML = `${textElements[1]} ${textElements[0]}`;
        });
    };

    const dateTimeFormat = useMemo(() => {
        return regionCode === Region.NA ? 'MMMM Do YYYY hh:mm a' : 'Do MMMM YYYY HH:mm';
    }, [regionCode]);

    return (
        <Grid
            container
            spacing={3}
            align-items="flex-start"
            className={classes.section}
            onClick={handleDateTimePickerDateReformat}
            onKeyDown={handleDateTimePickerDateReformat}
        >
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid item xs={4}>
                    <DateTimePicker
                        fullWidth
                        value={convertedStartDate}
                        disablePast
                        onChange={setStartDate}
                        ampm={regionCode === Region.NA}
                        format={dateTimeFormat}
                        label={startDateLabel}
                        showTodayButton
                        error={!!startDateErrorMessage}
                        helperText={startDateErrorMessage ? <>{startDateErrorMessage}</> : null}
                    />
                </Grid>
                <Grid item xs={4}>
                    <DateTimePicker
                        fullWidth
                        value={convertedEndDate}
                        disablePast
                        onChange={setEndDate}
                        minDate={moment(startDate)?.add(1, 'hour')}
                        ampm={regionCode === Region.NA}
                        format={dateTimeFormat}
                        label={endDateLabel}
                        showTodayButton
                        error={!!endDateErrorMessage}
                        helperText={endDateErrorMessage ? <>{endDateErrorMessage}</> : null}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        disabled
                        type="text"
                        label={<Trans i18nKey="timeZone">Time Zone</Trans>}
                        defaultValue={timezone}
                        fullWidth
                        helperText={
                            <Trans i18nKey="timeZoneHelper">
                                Time is displayed in local time zone
                            </Trans>
                        }
                        className={classes.displayInput}
                    />
                </Grid>
            </MuiPickersUtilsProvider>
        </Grid>
    );
}
