import React from 'react';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Grid, makeStyles } from '@material-ui/core';
import { Activity } from '../../../utility/auth/useSecurity';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { BulkUploadDeliveryState } from '../../../store/reducers/bulk-upload';
import { useTypedSelector } from '../../../store/reducers/reducer';
import Button from '../../reusable/atoms/Button';
import { useDispatch } from 'react-redux';
import { resetBulkUploadState } from '../../../store/root-actions';
import DisableBackButton from '../../reusable/atoms/DisableBackButton';
import DeliveryBulkUploadConfirmationSection from './components/DeliveryBulkUploadConfirmationSection';

const useStyles = makeStyles((theme) => ({
    main: {
        padding: '0',
        marginTop: '1.750em'
    },
    actionBtn: {
        height: '3.375em',
        width: '13.75em',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom'
    }
}));

export default function DeliveryBulkUploadConfirmation() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const { submittedOrders } = useTypedSelector<BulkUploadDeliveryState>(
        (state) => state.bulkUploadDelivery
    );

    const handleDashboardNav = () => {
        dispatch(resetBulkUploadState());
        history.push('/dashboard');
    };

    const handlePlanningView = () => {
        history.push('/product-planning');
    };

    DisableBackButton('/ship-it-bulk-upload-review', '/ship-it-bulk-upload-confirmation');

    const footerActions = (
        <>
            <Grid container item xs={6} justify="center">
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="planning-btn"
                    onClick={handlePlanningView}
                    className={classes.actionBtn}
                >
                    <Trans i18nKey="planningView">Planning View</Trans>
                </Button>
            </Grid>
            <Grid container item xs={6} justify="center">
                <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    data-testid="my-dashboard-btn"
                    className={classes.actionBtn}
                    onClick={handleDashboardNav}
                    disabled={false}
                >
                    <Trans i18nKey="myDashboard">My Dashboard</Trans>
                </Button>
            </Grid>
        </>
    );

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('shipIt', 'Ship It'),
                description: t('orderConfirmation', 'Order Confirmation').toUpperCase(),
                thinBanner: true,
                displayDropdown: false
            }}
            actionFooter={{
                footerAction: footerActions,
                sticky: true
            }}
            activity={Activity.ShipItBulkUpload}
            shipTos={false}
        >
            <Grid
                container
                justify="space-between"
                data-testid="ship-it-bulk-upload-confirmation-page"
            >
                <Grid container className={classes.main}>
                    {submittedOrders &&
                        submittedOrders.map((order, index) => {
                            const deliveryOrderNumber = (index += 1);
                            return (
                                <DeliveryBulkUploadConfirmationSection
                                    key={`delivery_order_section_${index}`}
                                    orderInformation={order}
                                    deliveryOrderNumber={deliveryOrderNumber}
                                />
                            );
                        })}
                </Grid>
            </Grid>
        </ProcessingPageTemplate>
    );
}
