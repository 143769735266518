import React from 'react';
import Highligher from 'react-highlight-words';
import { ContentSection, Link as ILink } from '../../../../store/reducers/content-page';
import {
    Grid,
    makeStyles,
    Typography,
    ExpansionPanel as MuiExpansionPanel,
    ExpansionPanelSummary as MuiExpansionPanelSummary,
    ExpansionPanelDetails,
    withStyles
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ContentLink from './ContentLink';

interface Props {
    section: ContentSection;
    searchWords: string[];
}

const useStyles = makeStyles((theme) => ({
    header: {
        fontWeight: 700
    },
    container: {
        marginBottom: '2em'
    },
    faq: {
        width: '100%'
    },
    highlight: {
        backgroundColor: fade(theme.palette.primary.main, 0.3)
    },
    faqAnswer: {
        whiteSpace: 'pre-wrap'
    },
    image: {
        maxHeight: '100%',
        maxWidth: '100%',
        display: 'block',
        margin: 'auto'
    }
}));

const StyledExpansionPanel = withStyles((theme) => ({
    root: {
        boxShadow: 'none',
        borderRadius: '0',
        background: 'transparent',
        borderBottom: `5px solid ${theme.palette.info.light}`,
        '&:before': {
            display: 'none'
        }
    }
}))(MuiExpansionPanel);

const StyledExpansionPanelSummary = withStyles((theme) => ({
    root: {
        display: 'inline-flex',
        borderRadius: '0',
        padding: 0,
        minHeight: 65,
        '&$expanded': {
            minHeight: 65
        }
    },
    content: {
        margin: '1.25em 0',
        alignItems: 'center'
    },
    expanded: {
        color: theme.palette.primary.main
    }
}))(MuiExpansionPanelSummary);

export default function FaqSection({ section, searchWords }: Props) {
    const classes = useStyles();
    const domParser = new DOMParser();
    const decode = (input: string) =>
        domParser.parseFromString(`<!doctype html><body>${input}</body>`, 'text/html').body
            .innerText;

    return (
        <Grid container item spacing={3} className={classes.container}>
            <Grid item xs={12}>
                <Typography variant="h3" component="h1" className={classes.header}>
                    <Highligher
                        highlightClassName={classes.highlight}
                        searchWords={searchWords}
                        textToHighlight={section.header}
                        autoEscape={true}
                    />
                </Typography>
            </Grid>
            {section.subsections?.map((subsection) => {
                const link =
                    subsection.links && subsection.links.length !== 0
                        ? (subsection.links[0] as ILink)
                        : null;
                const hasImage = !!subsection?.image?.imageUrl;

                return (
                    <Grid item xs={12}>
                        <StyledExpansionPanel key={subsection.header} className={classes.faq}>
                            <StyledExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon color="primary" />}
                            >
                                <Typography variant="h4" component="h2">
                                    <Highligher
                                        highlightClassName={classes.highlight}
                                        searchWords={searchWords}
                                        textToHighlight={subsection.header}
                                        autoEscape={true}
                                    />
                                </Typography>
                            </StyledExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container spacing={2}>
                                    {hasImage && (
                                        <Grid item xs={3}>
                                            <img
                                                className={classes.image}
                                                src={subsection.image?.imageUrl}
                                                alt={subsection.header + ' image'}
                                            ></img>
                                        </Grid>
                                    )}
                                    <Grid container item xs={hasImage ? 9 : 12}>
                                        <Grid item container xs={12}>
                                            <Typography className={classes.faqAnswer}>
                                                <Grid item xs={12}>
                                                    <Highligher
                                                        highlightClassName={classes.highlight}
                                                        searchWords={searchWords}
                                                        textToHighlight={decode(
                                                            subsection.description
                                                        )}
                                                    />
                                                </Grid>
                                                {link && (
                                                    <Grid item xs={12}>
                                                        <ContentLink link={link} />
                                                    </Grid>
                                                )}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </StyledExpansionPanel>
                    </Grid>
                );
            })}
        </Grid>
    );
}
