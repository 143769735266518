import { SecurityLevel } from '../../../../utility/auth/useSecurity';

export interface UserAdminRoleTableHeader {
    headerText: string;
    headerKey: string;
}

export interface UserAdminRoleRow {
    activity: string;
    activityKey: string;
    accessLevels: SecurityLevel[];
    isSubheader: boolean;
}

export const userAdminRolesTableHeaders: UserAdminRoleTableHeader[] = [
    {
        headerText: 'All Access',
        headerKey: 'allAccess'
    },
    {
        headerText: 'Pricing Contact',
        headerKey: 'pricingContact'
    },
    {
        headerText: 'Order Fulfillment',
        headerKey: 'orderFulfillment'
    },
    {
        headerText: 'Make It Only',
        headerKey: 'makeItOnly'
    },
    {
        headerText: 'Ship It Only',
        headerKey: 'shipItOnly'
    },
    {
        headerText: 'Procurement Management',
        headerKey: 'procurementManagement'
    },
    {
        headerText: 'Forecast Management',
        headerKey: 'forecastManagement'
    },
    {
        headerText: 'Packaging Management',
        headerKey: 'packagingManagement'
    },
    {
        headerText: 'Co-Packer',
        headerKey: 'coPacker'
    },
    {
        headerText: 'Warehousing / Logistics',
        headerKey: 'warehousingLogistics'
    },
    {
        headerText: 'Art Management',
        headerKey: 'artManagement'
    },
    {
        headerText: 'Marketing Management',
        headerKey: 'marketingManagement'
    },
    {
        headerText: 'Accounts Payable / Accounts Receivable',
        headerKey: 'accountsPayableAccountsReceivable'
    },
    {
        headerText: 'Graphics Vendor',
        headerKey: 'graphicsVendor'
    },
    {
        headerText: 'User Administrator',
        headerKey: 'userAdministrator'
    }
];

/**
 * Returns a list of user permissions for a specific persona.
 * @property activity - string value of activity
 * @property activityKey - string value of translations key for activity
 * @property accessLevels - array of security level for each persona,
 *      per index: [0]=All Access [1]=Pricing Contact [2]=Order Fulfillment [3]=Make It Only [4]=Ship It Only [5]=Procurement Management [6]=Forecast Management
 *      [7]=Packaging Management [8]=Co-Packer [9]=Warehousing / Logistics [10]=Art Management [11]=Marketing Management
 *      [12]=Accounts Payable / Accounts Receivable [13]=Graphics Vendor  [14]=User Administrator
 * @property isSubheader - boolean value if row is subheader so we may style accordingly
 */
export const userAdminRolesTableRows: UserAdminRoleRow[] = [
    {
        /* Graphics */
        activity: 'Graphics',
        activityKey: 'graphics',
        accessLevels: [
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None
        ],
        isSubheader: true
    },
    {
        activity: 'Graphics Intake Form',
        activityKey: 'graphicsIntakeForm',
        accessLevels: [
            SecurityLevel.Edit,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.Edit,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.Edit,
            SecurityLevel.Edit,
            SecurityLevel.None,
            SecurityLevel.Edit,
            SecurityLevel.None
        ],
        isSubheader: false
    },
    {
        activity: 'Draft Graphics Intake Forms',
        activityKey: 'draftGraphicsIntakeForms',
        accessLevels: [
            SecurityLevel.Edit,
            SecurityLevel.None,
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.Edit,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.Edit,
            SecurityLevel.Edit,
            SecurityLevel.None,
            SecurityLevel.Edit,
            SecurityLevel.None
        ],
        isSubheader: false
    },
    {
        activity: 'In Progress Graphics Statuses',
        activityKey: 'inProgressGraphicsStatuses',
        accessLevels: [
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.View,
            SecurityLevel.None
        ],
        isSubheader: false
    },
    // {
    //     activity: 'Graphics Quote',
    //     activityKey: 'graphicsQuote',
    //     accessLevels: [
    //         SecurityLevel.Edit,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.Edit,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.Edit,
    //         SecurityLevel.View,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None
    //     ],
    //     isSubheader: false
    // },
    // {
    //     activity: 'Graphics Proof',
    //     activityKey: 'graphicsProof',
    //     accessLevels: [
    //         SecurityLevel.Edit,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.View,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.Edit,
    //         SecurityLevel.View,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None
    //     ],
    //     isSubheader: false
    // },
    // {
    //     activity: 'Graphics Standard (Pilot Can)',
    //     activityKey: 'graphicsStandardPilotCan',
    //     accessLevels: [
    //         SecurityLevel.Edit,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.View,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.Edit,
    //         SecurityLevel.View,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None
    //     ],
    //     isSubheader: false
    // },
    {
        activity: 'Recently Completed Graphics',
        activityKey: 'recentlyCompletedGraphics',
        accessLevels: [
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.View,
            SecurityLevel.None
        ],
        isSubheader: false
    },
    /* Ordering */
    {
        activity: 'Ordering',
        activityKey: 'ordering',
        accessLevels: [
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None
        ],
        isSubheader: true
    },
    {
        activity: 'Planning',
        activityKey: 'planning',
        accessLevels: [
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None
        ],
        isSubheader: false
    },
    {
        activity: 'New / Open Production Orders',
        activityKey: 'newOpenProductionOrders',
        accessLevels: [
            SecurityLevel.Edit,
            SecurityLevel.None,
            SecurityLevel.Edit,
            SecurityLevel.Edit,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.None
        ],
        isSubheader: false
    },
    {
        activity: 'Draft Production Orders',
        activityKey: 'draftProductionOrders',
        accessLevels: [
            SecurityLevel.Edit,
            SecurityLevel.None,
            SecurityLevel.Edit,
            SecurityLevel.Edit,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None
        ],
        isSubheader: false
    },
    {
        activity: 'Closed Production Orders',
        activityKey: 'closedProductionOrders',
        accessLevels: [
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.None
        ],
        isSubheader: false
    },
    {
        activity: 'New / Open Delivery Orders',
        activityKey: 'newOpenDeliveryOrders',
        accessLevels: [
            SecurityLevel.Edit,
            SecurityLevel.None,
            SecurityLevel.Edit,
            SecurityLevel.None,
            SecurityLevel.Edit,
            SecurityLevel.None,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.Edit,
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.None
        ],
        isSubheader: false
    },
    // {
    //     activity: 'Draft Delivery Orders',
    //     activityKey: 'draftDeliveryOrders',
    //     accessLevels: [
    //         SecurityLevel.Edit,
    //         SecurityLevel.None,
    //         SecurityLevel.Edit,
    //         SecurityLevel.None,
    //         SecurityLevel.Edit,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.Edit,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None
    //     ],
    //     isSubheader: false
    // },
    {
        activity: 'Closed Delivery Orders',
        activityKey: 'closedDeliveryOrders',
        accessLevels: [
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.None
        ],
        isSubheader: false
    },
    {
        activity: 'Plan It/Plan It Summary',
        activityKey: 'planItPlanItSummary',
        accessLevels: [
            SecurityLevel.Edit,
            SecurityLevel.None,
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.Edit,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None
        ],
        isSubheader: false
    },
    /* Payments (CIA Only) */
    {
        activity: 'Payments (CIA Only)',
        activityKey: 'paymentsCIAOnly',
        accessLevels: [
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None
        ],
        isSubheader: true
    },
    {
        activity: 'Order Payment Status',
        activityKey: 'orderPaymentStatus',
        accessLevels: [
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.None
        ],
        isSubheader: false
    },
    {
        activity: 'Order Payment Financials',
        activityKey: 'orderPaymentFinancials',
        accessLevels: [
            SecurityLevel.Edit,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.Edit,
            SecurityLevel.None,
            SecurityLevel.None
        ],
        isSubheader: false
    },
    /* Liquid Testing */
    {
        activity: 'Liquid Testing',
        activityKey: 'liquidTesting',
        accessLevels: [
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None
        ],
        isSubheader: true
    },
    {
        activity: 'Liquid Intake Form',
        activityKey: 'liquidIntakeForm',
        accessLevels: [
            SecurityLevel.Edit,
            SecurityLevel.None,
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.Edit,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.Edit,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None
        ],
        isSubheader: false
    },
    /* Account Admin */
    {
        activity: 'Account Admin',
        activityKey: 'accountAdmin',
        accessLevels: [
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None
        ],
        isSubheader: true
    },
    {
        activity: 'Warranty Letter',
        activityKey: 'warrantyLetter',
        accessLevels: [
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None
        ],
        isSubheader: false
    },
    {
        activity: 'Terms and Conditions',
        activityKey: 'termsandConditions',
        accessLevels: [
            SecurityLevel.Edit,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.Edit,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.View
        ],
        isSubheader: false
    },
    // {
    //     activity: 'Temporarily Hide SKUs',
    //     activityKey: 'temporarilyHideSKUs',
    //     accessLevels: [
    //         SecurityLevel.Edit,
    //         SecurityLevel.None,
    //         SecurityLevel.Edit,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.Edit,
    //         SecurityLevel.None,
    //         SecurityLevel.Edit,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.Edit,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None
    //     ],
    //     isSubheader: false
    // },
    // {
    //     activity: 'Manage Bill-To',
    //     activityKey: 'manageBillTo',
    //     accessLevels: [
    //         SecurityLevel.Edit,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.Edit,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.Edit,
    //         SecurityLevel.None,
    //         SecurityLevel.None
    //     ],
    //     isSubheader: false
    // },
    // {
    //     activity: 'Manage Ship-To',
    //     activityKey: 'manageShipTo',
    //     accessLevels: [
    //         SecurityLevel.Edit,
    //         SecurityLevel.None,
    //         SecurityLevel.Edit,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.Edit,
    //         SecurityLevel.Edit,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.Edit,
    //         SecurityLevel.None,
    //         SecurityLevel.None
    //     ],
    //     isSubheader: false
    // },
    {
        activity: 'Customer Product ID Mapping to Ball IDs',
        activityKey: 'customerProductIDMappingtoBallIDs',
        accessLevels: [
            SecurityLevel.Edit,
            SecurityLevel.None,
            SecurityLevel.Edit,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.Edit,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None
        ],
        isSubheader: false
    },
    /* User Admin */
    {
        activity: 'User Admin',
        activityKey: 'userAdmin',
        accessLevels: [
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None
        ],
        isSubheader: true
    },
    {
        activity: 'Create New User',
        activityKey: 'createNewUser',
        accessLevels: [
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.Edit
        ],
        isSubheader: false
    },
    {
        activity: 'Edit permissions',
        activityKey: 'editpermissions',
        accessLevels: [
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.View,
            SecurityLevel.Edit
        ],
        isSubheader: false
    },
    {
        activity: 'Disable Permissions',
        activityKey: 'disablePermissions',
        accessLevels: [
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.Edit
        ],
        isSubheader: false
    },
    // {
    //     activity: 'Troubleshooting',
    //     activityKey: 'troubleshooting',
    //     accessLevels: [
    //         SecurityLevel.Edit,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.None,
    //         SecurityLevel.Edit
    //     ],
    //     isSubheader: false
    // },
    /* Pricing Contact */
    {
        activity: 'Pricing',
        activityKey: 'pricing',
        accessLevels: [
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None
        ],
        isSubheader: true
    },
    {
        activity: 'Contracts & Documents',
        activityKey: 'contractsAndDocuments',
        accessLevels: [
            SecurityLevel.None,
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.View
        ],
        isSubheader: false
    },
    {
        activity: 'Contract Acknowledgement',
        activityKey: 'contractAcknowledgement',
        accessLevels: [
            SecurityLevel.None,
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None
        ],
        isSubheader: false
    },
    {
        activity: 'Pricing Breakdown',
        activityKey: 'pricingBreakdown',
        accessLevels: [
            SecurityLevel.None,
            SecurityLevel.View,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.None,
            SecurityLevel.View
        ],
        isSubheader: false
    }
];
