import { ErrorStatus, findDistinctObjects } from '../../utility/helpers/axios-helpers';
import OrdersService, {
    GetProductionOrdersResponse,
    OrderType,
    Order_Status,
    QuantityType,
    RowsOrColumns
} from '../../utility/services/orders-service';
import { BulkUploadTemplateRequest, TemplateMapping } from '../reducers/bulk-upload';
import { ForecastStatus, MakeItBulkATMLineItem, WeekQuantity } from '../reducers/makeit-bulk-atm';
import {
    PLANIT_BULK_UPLOAD_ATM_LOADING,
    PLANIT_BULK_UPLOAD_ERROR,
    PLANIT_BULK_UPLOAD_RESET_STATE,
    PLANIT_BULK_ATM_SUBMIT_REVIEW,
    PLANIT_BULK_UPLOAD_ATM_LOADED,
    PLANIT_BULK_UPLOAD_ATM_UPDATE_PALLET_QUANTITY,
    PLANIT_BULK_ATM_SNOSKU_ORDER,
    PLANIT_BULK_ATM_DELETE_PRODUCT,
    PLANIT_BULK_ATM_ADD_PRODUCT,
    PLANIT_BULK_ATM_SUBMIT_ORDERS_LOADING,
    PLANIT_BULK_ATM_SUBMIT_ORDERS_ERROR,
    PLANIT_BULK_ATM_SUBMIT_ORDERS_SUCCESS,
    PLANIT_BULK_ATM_SUBMIT_DELTA_REVIEW,
    PLANIT_BULK_ATM_UPDATED_PRODUCT_SKU,
    PLANIT_BULK_ATM_EXISTING_ORDERS,
    PLANIT_BULK_ATM_EDIT_ORDER_LOADED,
    PLANIT_BULK_UPLOAD_ATM_TEMPLATE_ERROR,
    PLANIT_BULK_UPLOAD_ATM_TEMPLATE_CLEAR,
    PLANIT_BULK_UPLOAD_LOADING,
    PLANIT_BULK_UPLOAD_GET_PRODUCTS,
    PLANIT_BULK_UPLOAD_REMOVE_ORDER,
    PLANIT_BULK_UPLOAD_REFRESH_STATE,
    PLANIT_BULK_UPLOAD_FILE,
    PLANIT_BULK_ATM_UPDATE_CUSTOMER_PO
} from './action-types';
import moment from 'moment';
import { DateProperties } from '../../utility/helpers/make-it-bulk-helpers';
import {
    createProductRequestObj,
    getCustomerProductInformation,
    getMatchingProductInformation,
    getProductLeadTime
} from '../../utility/helpers/production-order-helpers';
import { MakeItProductsRequest, ProductionOrder } from '../reducers/makeit-dashboard';
import { AuthState } from '../reducers/auth';
import {
    Activity,
    filterShipToIdsByPermission,
    SecurityLevel,
    ValidationMethod
} from '../../utility/auth/useSecurity';
import { Product } from '../reducers/product-portfolio';
import { End } from '../reducers/product-end-details';
import { getProductLeadTimes } from './makeit-dashboard';
import ProductService, {
    LeadTimesResponse,
    ProductSearchResponse
} from '../../utility/services/product-service';
import { EndsForShipToId } from './makeit-bulk-upload';
import EndService from '../../utility/services/end-service';
import { AxiosResponse } from 'axios';
import {
    InactiveProduct,
    PlanItBulkATMLineItem,
    PlanItBulkATMOrder,
    PlanItBulkATMOrderByWeekAndSku,
    PlanItLineError,
    UnrecognizedProduct
} from '../reducers/planit-bulk-atm';
import { MakeItBulkLineItem } from '../reducers/makeit-bulk-upload';

export const loadBulkUploadPlanItATM = (userName: string) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: PLANIT_BULK_UPLOAD_ATM_LOADING });
        OrdersService.getBulkUpload(state, userName, OrderType.Forecast)
            .then((response) => {
                let weekQuantities: WeekQuantity[] = [];
                if (response.data.rowsOrColumns === RowsOrColumns.Columns) {
                    response.data.mappings.forEach((field) => {
                        if (field.fieldName.includes('WeekNumber')) {
                            weekQuantities.push({ quantity: field.columnLetter });
                        }
                    });
                }
                dispatch({
                    type: PLANIT_BULK_UPLOAD_ATM_LOADED,
                    mappings: response.data.mappings,
                    quantityType: response.data.quantityType,
                    rowsOrColumns: response.data.rowsOrColumns,
                    firstRowOfData: response.data.firstRowOfData,
                    weekQuantities: weekQuantities
                });
            })
            .catch((error) => {
                if (error.response.status === ErrorStatus.NotFound) {
                    dispatch({ type: PLANIT_BULK_UPLOAD_ATM_LOADED, mappings: [] });
                } else {
                    dispatch({
                        type: PLANIT_BULK_UPLOAD_ERROR,
                        error
                    });
                }
            });
    };
};

export const resetPlanItBulkState = () => {
    return (dispatch) => {
        dispatch({
            type: PLANIT_BULK_UPLOAD_RESET_STATE
        });
    };
};

export const refreshPlanItBulkState = () => {
    return (dispatch) => {
        dispatch({ type: PLANIT_BULK_UPLOAD_REFRESH_STATE });
    };
};

export const removeMakeItBulkOrder = () => {
    return (dispatch) => {
        dispatch({
            type: PLANIT_BULK_UPLOAD_REMOVE_ORDER
        });
    };
};

export const createPlanItBulkUploadATMTemplate = (
    userName: string,
    mappings: TemplateMapping[],
    quantityType: QuantityType,
    rowsOrColumns?: RowsOrColumns,
    weekQuantities?: WeekQuantity[],
    firstRowOfData?: number
) => {
    return (dispatch, getState) => {
        const { auth } = getState();
        dispatch({ type: PLANIT_BULK_UPLOAD_ATM_LOADING });
        const requestObj: BulkUploadTemplateRequest = {
            userName: userName,
            orderType: OrderType.Forecast,
            mappings: mappings,
            quantityType: quantityType,
            rowsOrColumns: rowsOrColumns,
            firstRowOfData: firstRowOfData
        };

        OrdersService.createBulkUploadDeliveryTemplate(auth.accessToken, requestObj)
            .then((response) => {
                dispatch({
                    type: PLANIT_BULK_UPLOAD_ATM_LOADED,
                    mappings: response.data.mappings,
                    quantityType: response.data.quantityType,
                    rowsOrColumns: response.data.rowsOrColumns,
                    weekQuantities: weekQuantities,
                    firstRowOfData: response.data.firstRowOfData
                });
            })
            .catch((error) => {
                dispatch({
                    type: PLANIT_BULK_UPLOAD_ATM_TEMPLATE_ERROR,
                    error
                });
            });
    };
};

export const updatePlanItBulkUploadATMTemplate = (
    userName: string,
    mappings: TemplateMapping[],
    quantityType: QuantityType,
    rowsOrColumns?: RowsOrColumns,
    weekQuantities?: WeekQuantity[],
    firstRowOfData?: number
) => {
    return (dispatch, getState) => {
        const { auth } = getState();
        dispatch({ type: PLANIT_BULK_UPLOAD_ATM_LOADING });
        const requestObj: BulkUploadTemplateRequest = {
            userName: userName,
            orderType: OrderType.Forecast,
            mappings: mappings,
            quantityType: quantityType,
            rowsOrColumns: rowsOrColumns,
            firstRowOfData: firstRowOfData
        };

        requestObj.quantityType = quantityType;

        OrdersService.updateBulkUploadDeliveryTemplate(auth.accessToken, requestObj)
            .then((response) => {
                dispatch({
                    type: PLANIT_BULK_UPLOAD_ATM_LOADED,
                    mappings: response.data.mappings,
                    quantityType: response.data.quantityType,
                    rowsOrColumns: response.data.rowsOrColumns,
                    weekQuantities: weekQuantities,
                    firstRowOfData: response.data.firstRowOfData
                });
            })
            .catch((error) => {
                dispatch({
                    type: PLANIT_BULK_UPLOAD_ATM_TEMPLATE_ERROR,
                    error
                });
            });
    };
};

/**
 * Removes the product from a make it bulk atm order.
 * @param userSuppliedProductId
 * @param weekStart
 * @param shipToId
 */
export function deleteProductFromAtmOrder(productSku: string, weekStart: string, shipToId: number) {
    return (dispatch, getState) => {
        const state = getState();
        const forecastOrders = state.bulkUploadPlanIt.forecastOrders as PlanItBulkATMOrder[];
        const existingOrders = state.bulkUploadPlanIt.existingOrders as PlanItBulkATMOrder[];

        //If existingOrders, isolate existing order
        const existingOrderToUpdate = existingOrders.length
            ? existingOrders.find(
                  (existingOrder) =>
                      moment(existingOrder.atmWeekStart)
                          .startOf('day')
                          .isSame(moment(weekStart).startOf('day')) &&
                      existingOrder.shipToId === shipToId
              )
            : undefined;

        //Must remove from existing order for tracking
        if (existingOrderToUpdate) {
            let newLines = existingOrderToUpdate.lines.filter(
                (existingLine) => existingLine.productSku !== productSku
            );
            existingOrderToUpdate.lines = newLines;
        }

        if (forecastOrders) {
            const forecastOrdersToUpdate = forecastOrders.map((order) => {
                if (
                    !(
                        moment(order.weekStart)
                            .startOf('day')
                            .isSame(moment(weekStart).startOf('day')) && order.shipToId === shipToId
                    )
                ) {
                    return order;
                }
                // remove matching products added by user (with no originalCsvLineNumber)
                const updatedLines = order.lines.map((line) => {
                    if (line.productSku !== productSku) {
                        return line;
                    } else if (line.productSku === productSku) {
                        return {
                            ...line,
                            deleted: true
                        };
                    }
                    return null;
                });

                return { ...order, lines: updatedLines };
            });
            dispatch({
                type: PLANIT_BULK_ATM_DELETE_PRODUCT,
                forecastOrders: forecastOrdersToUpdate
            });
        }
    };
}

export function addProductToForecastOrder(
    activeDate: DateProperties,
    shipToId: number,
    item: PlanItBulkATMLineItem
) {
    return (dispatch, getState) => {
        const state = getState();
        const products = state.bulkUploadPlanIt.products as PlanItBulkATMLineItem[];
        const forecastOrders = state.bulkUploadPlanIt.forecastOrders as PlanItBulkATMOrder[];
        const existingOrders = state.bulkUploadPlanIt.existingOrders as PlanItBulkATMOrder[];
        const requestedDate = moment(activeDate.fullDate).format('YYYY-MM-DDTHH:mm:ss');

        if (forecastOrders) {
            const forecastOrdersToUpdate = forecastOrders.map((order) => {
                if (
                    !(
                        moment(order.weekStart)
                            .startOf('day')
                            .isSame(moment(activeDate.fullDate).startOf('day')) &&
                        order.shipToId === shipToId
                    )
                ) {
                    return order;
                }

                //If existingOrders, isolate existing order
                const existingOrderToUpdate = existingOrders.length
                    ? existingOrders.find(
                          (existingOrder) =>
                              moment(existingOrder.atmWeekStart)
                                  .startOf('day')
                                  .isSame(moment(activeDate.fullDate).startOf('day')) &&
                              existingOrder.shipToId === shipToId
                      )
                    : undefined;

                let updatedLines = order.lines.filter((line) => {
                    if (line.displayId !== item.displayId) {
                        return line;
                    }
                    return null;
                });
                const addItem = products.find((product) => product.productId === item.productId);
                const destination = addItem?.destinations?.find(
                    (d) => d.shipToId === order.shipToId
                );
                const productStatus =
                    addItem?.status === 'ACTIVE' && destination?.status === 'ACTIVE'
                        ? 'ACTIVE'
                        : 'INACTIVE';
                const newLine = {
                    ...(addItem as PlanItBulkATMLineItem),
                    productStatus: productStatus,
                    isCustomerProductIdDistinct: true,
                    originalPalletQuantity: 0,
                    requestedDate: requestedDate
                };
                updatedLines.push(newLine);
                if (existingOrderToUpdate) {
                    //Also push to existing orders
                    existingOrderToUpdate?.lines.push({
                        ...(addItem as PlanItBulkATMLineItem),
                        isCustomerProductIdDistinct: true,
                        originalPalletQuantity: 0,
                        requestedDate: requestedDate
                    });
                } else {
                    //Fresh order, must add to existing orders for tracking
                    existingOrders.push({
                        ...order,
                        atmWeekStart: order.weekStart,
                        lines: [
                            {
                                ...(addItem as PlanItBulkATMLineItem),
                                isCustomerProductIdDistinct: true,
                                originalPalletQuantity: 0,
                                requestedDate: requestedDate
                            }
                        ]
                    });
                }

                return { ...order, lines: updatedLines };
            });
            dispatch({
                type: PLANIT_BULK_ATM_ADD_PRODUCT,
                forecastOrders: forecastOrdersToUpdate
            });
        }
    };
}

export const updatePalletQuantityATM = (
    activeDate: DateProperties,
    shipToId: number,
    quantity: number,
    updatedLine: PlanItBulkATMLineItem
) => {
    return (dispatch, getState) => {
        const state = getState();
        const forecastOrders = state.bulkUploadPlanIt.forecastOrders as PlanItBulkATMOrder[];
        const existingOrders = state.bulkUploadPlanIt.existingOrders as PlanItBulkATMOrder[];
        if (forecastOrders) {
            const forecastOrdersToUpdate = forecastOrders.map((order) => {
                if (
                    !(
                        moment(order.weekStart)
                            .startOf('day')
                            .isSame(moment(activeDate.fullDate).startOf('day')) &&
                        order.shipToId === shipToId
                    )
                ) {
                    return order;
                }

                //If existingOrders, isolate existing order
                const existingOrderToUpdate = existingOrders.length
                    ? existingOrders.find(
                          (existingOrder) =>
                              moment(existingOrder.atmWeekStart)
                                  .startOf('day')
                                  .isSame(moment(activeDate.fullDate).startOf('day')) &&
                              existingOrder.shipToId === shipToId
                      )
                    : undefined;

                const updatedLines = order.lines.map((line) => {
                    if (
                        line.originalCsvLineNumber &&
                        line.originalCsvLineNumber !== updatedLine.originalCsvLineNumber
                    ) {
                        return line;
                    } else if (
                        !line.originalCsvLineNumber &&
                        (line.displayId !== updatedLine.displayId ||
                            line.productSku !== updatedLine.productSku)
                    ) {
                        return line;
                    } else {
                        if (existingOrderToUpdate) {
                            //If the line being updated matches, we want to preserve any updated quantities
                            const lineToUpdate = existingOrderToUpdate.lines.find(
                                (existingLine) => existingLine.productSku === line.productSku
                            );
                            if (lineToUpdate) {
                                lineToUpdate.palletQuantity = quantity >= 0 ? quantity : undefined;
                            }
                        }
                        line.palletsRounded = false;
                        return {
                            ...line,
                            palletQuantity: quantity >= 0 ? quantity : undefined
                        };
                    }
                });
                return { ...order, lines: updatedLines };
            });

            dispatch({
                type: PLANIT_BULK_UPLOAD_ATM_UPDATE_PALLET_QUANTITY,
                forecastOrders: forecastOrdersToUpdate
            });
        }
    };
};

export const reviewPlanItBulkUploadATM = (
    userName: string,
    file: File | File[],
    weeks?: moment.Moment[]
) => {
    return (dispatch, getState) => {
        const state = getState();
        const products = state.bulkUploadPlanIt.products as PlanItBulkATMLineItem[];

        dispatch({ type: PLANIT_BULK_UPLOAD_ATM_LOADING });

        OrdersService.reviewPlanItBulkUpload(state, userName, file, weeks)
            .then((response) => {
                let orders = [] as PlanItBulkATMOrder[];
                let errors = [] as PlanItLineError[];
                let unrecognizedProducts = [] as UnrecognizedProduct[];
                let inactiveProducts = [] as InactiveProduct[];
                if (response.data.orders) {
                    orders = response.data.orders;
                }
                if (response.data.unrecognizedProducts) {
                    unrecognizedProducts = response.data.unrecognizedProducts;
                }

                if (response.data.inactiveProducts) {
                    inactiveProducts = getInactiveDescriptions(
                        response.data.inactiveProducts,
                        products
                    );
                }

                if (response.data.lineErrors) {
                    errors = response.data.lineErrors;
                }
                bulkUploadResponseHelper(orders, products);
                dispatch({
                    type: PLANIT_BULK_ATM_SUBMIT_REVIEW,
                    forecastOrders: orders,
                    unrecognizedProducts: unrecognizedProducts,
                    inactiveProducts: inactiveProducts,
                    forecastLineErrors: errors,
                    weeks
                });
            })
            .then(() => {
                const forecastOrders = getState().bulkUploadPlanIt.forecastOrders;
                if (forecastOrders?.length) {
                    const sortedCsvForecastOrders: PlanItBulkATMOrder[] =
                        getState().bulkUploadPlanIt.forecastOrders.sort((a, b) =>
                            moment(a.weekStart).diff(moment(b.weekStart))
                        );
                    const startWeek = moment(sortedCsvForecastOrders[0].weekStart).format(
                        'M/D/YYYY'
                    );
                    const endWeek = moment(
                        sortedCsvForecastOrders[sortedCsvForecastOrders.length - 1].weekStart
                    ).format('M/D/YYYY');
                    const shipToIds = sortedCsvForecastOrders.reduce((prev, curr) => {
                        if (!prev.includes(curr.shipToId)) {
                            prev.push(curr.shipToId);
                        }
                        return prev;
                    }, [] as Array<number>);

                    return OrdersService.getExistingOrders(
                        state,
                        startWeek,
                        endWeek,
                        shipToIds,
                        ForecastStatus.Forecast
                    );
                }
            })
            .then((resp) => {
                if (resp) {
                    dispatch({
                        type: PLANIT_BULK_ATM_EXISTING_ORDERS,
                        existingOrders: JSON.parse(JSON.stringify(resp))
                    });
                    tryResolveDeltaPlanIt(resp, dispatch, getState);
                }
            })
            .catch((error) => {
                dispatch({
                    type: PLANIT_BULK_UPLOAD_ERROR,
                    error
                });
            });
    };
};

export const uploadPlanItFile = (file?: File) => {
    return (dispatch) => {
        if (file) {
            dispatch({ type: PLANIT_BULK_UPLOAD_FILE, file: file });
        } else {
            dispatch({ type: PLANIT_BULK_UPLOAD_FILE, file: undefined });
        }
    };
};

export const tryResolveDeltaPlanIt = (
    existingForecastOrders: Array<GetProductionOrdersResponse>,
    dispatch,
    getState
) => {
    const state = getState();
    const csvForecastOrders: PlanItBulkATMOrder[] = state.bulkUploadPlanIt.forecastOrders;
    existingForecastOrders = existingForecastOrders.filter(
        (order) =>
            order.lines &&
            order.lines.length &&
            csvForecastOrders.some((csvForecastOrder) =>
                moment(order.atmWeekStart)
                    .startOf('day')
                    .isSame(moment(csvForecastOrder.weekStart).startOf('day'))
            )
    );

    //
    // Find matches based on shipToId, productSku and starting Week.
    // Iterate backwards to support conditional splicing of the array.
    //
    // Check for a Ship To & Week match.
    //
    for (let i = csvForecastOrders.length - 1; i >= 0; i--) {
        let csvOrderRemoved = false;
        const existingOrder = existingForecastOrders.find((existingOrder) => {
            return (
                existingOrder.shipToId === csvForecastOrders[i].shipToId &&
                moment(existingOrder.atmWeekStart)
                    .startOf('day')
                    .isSame(moment(csvForecastOrders[i].weekStart).startOf('day'))
            );
        });

        //
        // Existing order match found.
        // Iterate existing order lines, looking for CSV line matches...
        //
        if (existingOrder && existingOrder.lines && existingOrder.lines.length) {
            for (let j = 0; j < existingOrder.lines.length; j++) {
                const existingLine = existingOrder.lines[j];
                if (csvOrderRemoved) {
                    continue;
                }

                const productSku = existingLine.productSku;
                const csvLineIndex = csvForecastOrders[i].lines!.findIndex(
                    (line) => line.productSku === productSku
                );

                //
                // CSV line match found.
                //
                if (csvLineIndex !== -1) {
                    // Remove the CSV line from the CSV order.
                    const splicedCsvLine = csvForecastOrders[i].lines.splice(csvLineIndex, 1)[0];

                    // Set properties of CSV line to the existing line.
                    existingOrder.lines[j] = {
                        ...existingLine,
                        ...splicedCsvLine,
                        originalPalletQuantity: existingLine.palletQuantity
                    };

                    // Remove the CSV order if there aren't any more lines.
                    if (csvForecastOrders[i].lines?.length === 0) {
                        csvForecastOrders.splice(i, 1);
                        csvOrderRemoved = true;
                        continue;
                    }
                }
            }

            //
            // If the CSV order matches an existing order, but there
            // are still leftover lines, move the CSV lines to the
            // existing order and delete the CSV order.
            //
            if (
                !csvOrderRemoved &&
                csvForecastOrders[i].lines &&
                csvForecastOrders[i].lines.length > 0
            ) {
                const splicedCsvLines = csvForecastOrders[i].lines.splice(
                    0,
                    csvForecastOrders[i].lines.length
                );
                existingOrder.lines = existingOrder.lines.concat(splicedCsvLines);
                csvForecastOrders.splice(i, 1);
            }
        }
    }

    const products = state.bulkUploadPlanIt.products as PlanItBulkATMLineItem[];
    const convertedExistingForecastOrders: Array<PlanItBulkATMOrder> = existingForecastOrders.map(
        (order) => {
            return {
                ...order,
                weekStart: order.atmWeekStart || '',
                snoSku: undefined,
                customerProductionOrderId: order.customerProductionOrderId || '',
                lines: order.lines || []
            };
        }
    );
    const updatedOrderLinesNotIncludedInCsv: Array<PlanItBulkATMOrder> =
        convertedExistingForecastOrders.map((order) => {
            order.lines = order.lines.map((line: MakeItBulkATMLineItem) => {
                return {
                    ...line,
                    notIncludedInCsv: !!line.originalCsvLineNumber ? false : true,
                    palletQuantity: !!line.originalCsvLineNumber ? line.palletQuantity : 0
                };
            });
            return order;
        });
    bulkUploadResponseHelper(updatedOrderLinesNotIncludedInCsv, products);
    dispatch({
        type: PLANIT_BULK_ATM_SUBMIT_DELTA_REVIEW,
        forecastOrders: csvForecastOrders.concat(updatedOrderLinesNotIncludedInCsv)
    });
};

export const saveOrdersBySnoSku = (ordersBySnoSku: PlanItBulkATMOrderByWeekAndSku[]) => {
    return (dispatch) => {
        dispatch({
            type: PLANIT_BULK_ATM_SNOSKU_ORDER,
            ordersBySnoSku: ordersBySnoSku
        });
    };
};

export const updateExpand = (shipToId: number, snoSku: string, weekStart: string) => {
    return (dispatch, getState) => {
        const { ordersBySnoSku } = getState().bulkUploadPlanIt;
        const ordersBySnoSkuToUpdate = [...ordersBySnoSku] as PlanItBulkATMOrderByWeekAndSku[];

        ordersBySnoSkuToUpdate.forEach((order) => {
            order.linesBySnoSku.forEach((line) => {
                if (
                    order.shipToId === shipToId &&
                    line.snoSku === snoSku &&
                    moment(line.weekStart).startOf('day').isSame(moment(weekStart).startOf('day'))
                ) {
                    line.isActive = !line.isActive;
                }
            });
        });

        dispatch({
            type: PLANIT_BULK_ATM_SNOSKU_ORDER,
            ordersBySnoSku: ordersBySnoSkuToUpdate
        });
    };
};

export const updateExpandAll = (
    collapsed: boolean,
    selectedDate: string,
    selectedShipTo?: string
) => {
    return (dispatch, getState) => {
        const { ordersBySnoSku } = getState().bulkUploadPlanIt;
        const ordersBySnoSkuToUpdate = [...ordersBySnoSku] as PlanItBulkATMOrderByWeekAndSku[];

        ordersBySnoSkuToUpdate.forEach((order) => {
            if (
                moment(order.weekStart).format('MM/DD/YYYY') ===
                    moment(selectedDate).format('MM/DD/YYYY') &&
                // if there is not a selectedShipTo, it's confirmation
                // if there is a selectedShipTo, it's review and needs to match the id
                (!selectedShipTo ||
                    (selectedShipTo && selectedShipTo === order.shipToId.toString()))
            ) {
                order.linesBySnoSku.forEach((line) => {
                    line.isActive = collapsed ? false : true;
                });
            }
        });

        dispatch({
            type: PLANIT_BULK_ATM_SNOSKU_ORDER,
            ordersBySnoSku: ordersBySnoSkuToUpdate
        });
    };
};

export const updatePlanItBulkATMItemProductSku = (
    activeDate: DateProperties,
    shipToId: number,
    csvLineNumber: number,
    updatedLine: PlanItBulkATMLineItem,
    palletQuantity: number
) => {
    return (dispatch, getState) => {
        const state = getState();
        const forecastOrders = JSON.parse(
            JSON.stringify(state.bulkUploadPlanIt.forecastOrders)
        ) as PlanItBulkATMOrder[];
        const existingOrders = state.bulkUploadPlanIt.existingOrders as PlanItBulkATMOrder[];
        const products = state.bulkUploadPlanIt.products as PlanItBulkATMLineItem[];
        if (forecastOrders) {
            let forecastOrdersToUpdate = forecastOrders.map((order) => {
                /*If there are existingOrders,
                 *isolate to compare the newly updated lines to the existing lines.
                 */
                const existingOrderToCompare = existingOrders.length
                    ? existingOrders.find(
                          (order) =>
                              order.shipToId === shipToId &&
                              moment(order.atmWeekStart)
                                  .startOf('day')
                                  .isSame(moment(activeDate.fullDate).startOf('day'))
                      )
                    : undefined;

                if (
                    order.shipToId === shipToId &&
                    moment(order.weekStart)
                        .startOf('day')
                        .isSame(moment(activeDate.fullDate).startOf('day'))
                ) {
                    /*If there is a line that already exists,
                     *Find it so we can set the lineId and pallet info. Otherwise, clear those values.
                     */
                    const existingOrderLine = existingOrderToCompare?.lines.find(
                        (existingLine) => existingLine.productSku === updatedLine.productSku
                    );

                    let linesToUpdate = order.lines
                        //Groom out existing lines, we will add them back in later
                        .filter((line) => line.originalCsvLineNumber)
                        .map((line) => {
                            if (line.originalCsvLineNumber === csvLineNumber) {
                                line.productSku = updatedLine.productSku;
                                line.graphicId = updatedLine.graphicId;
                                line.graphicIdAndVersion = updatedLine.graphicIdAndVersion;
                                line.displayName = updatedLine.displayName;
                                line.snoSku = updatedLine.snoSku!;
                                line.isCustomerProductIdDistinct = true;
                                line.productionOrderLineId = existingOrderLine
                                    ? existingOrderLine.productionOrderLineId
                                    : undefined;
                                line.originalPalletQuantity = existingOrderLine
                                    ? existingOrderLine.originalPalletQuantity
                                    : 0;
                                line.palletQuantity = Math.ceil(palletQuantity);
                                line.palletsRounded = palletQuantity % 1 !== 0;
                            }
                            return line;
                        });

                    /*If existingOrderToCompare, loop through lines
                     *and ensure we didn't lose any in linesToUpdate.
                     */
                    if (existingOrderToCompare) {
                        existingOrderToCompare.lines.forEach((existingLine) => {
                            let matchingLineIndex = linesToUpdate.findIndex(
                                (lineToUpdate) =>
                                    lineToUpdate.productSku === existingLine.productSku
                            );
                            //Check if existing line not included the newly mapped lines
                            if (matchingLineIndex === -1) {
                                let matchingProduct = products.find(
                                    (product) => product.productSku === existingLine.productSku
                                ) as PlanItBulkATMLineItem;

                                //Create new line object
                                let existingLineToAdd = {
                                    ...existingLine,
                                    ...matchingProduct,
                                    originalPalletQuantity: existingLine.originalPalletQuantity,
                                    deleted: false
                                };
                                linesToUpdate.push(existingLineToAdd);
                            }
                        });
                    }
                    return { ...order, lines: linesToUpdate };
                } else {
                    return { ...order };
                }
            });
            dispatch({
                type: PLANIT_BULK_ATM_UPDATED_PRODUCT_SKU,
                forecastOrders: forecastOrdersToUpdate
            });
        }
    };
};

const bulkUploadResponseHelper = (orders: PlanItBulkATMOrder[], products: any) => {
    orders.forEach((order) => {
        order.lines.forEach((line: PlanItBulkATMLineItem, index) => {
            if (line.userSuppliedProductId || line.productSku) {
                let idToMatch = line.userSuppliedProductId
                    ? line.userSuppliedProductId
                    : line.productSku;

                let matchingProduct = products.find(
                    (product) =>
                        product.productSku === idToMatch ||
                        getCustomerProductInformation(product, order.shipToId).customerProductId ===
                            idToMatch
                );

                if (matchingProduct) {
                    const customerProductInformation = getCustomerProductInformation(
                        matchingProduct,
                        order.shipToId
                    );
                    matchingProduct.displayId = customerProductInformation.customerProductId
                        ? customerProductInformation.customerProductId
                        : matchingProduct.productSku;
                    matchingProduct.displayName = customerProductInformation.customerProductName
                        ? customerProductInformation.customerProductName
                        : matchingProduct.name;

                    const destination = matchingProduct.destinations.find(
                        (d) => d.shipToId === order.shipToId
                    );
                    order.lines[index] = combineProductData(line, matchingProduct, destination);
                }

                order.lines[index].deleted = false;
                order.lines[index].originalPalletQuantity = order.lines[index]
                    .originalPalletQuantity
                    ? order.lines[index].originalPalletQuantity
                    : 0;
                if (
                    order.lines[index].eachesQuantity &&
                    order.lines[index].palletQuantity &&
                    order.lines[index].quantityPerPallet
                ) {
                    order.lines[index].palletsRounded =
                        order.lines[index].eachesQuantity !==
                        order.lines[index].palletQuantity! * order.lines[index].quantityPerPallet!;
                }
            }
        });
    });
};

export const atmBulkUploadResponseHelper = (orders: any) => {
    orders.forEach((order) => {
        order.weekStart = order.atmWeekStart;
    });
};

export const submitForecastOrders = (callback: Function) => {
    return (dispatch, getState) => {
        const state = getState();
        const accessToken = state.auth.accessToken;
        const userName = state.auth.userInfo.preferred_username;
        const region = state.regionCulture.regionCode;
        const forecastOrders = state.bulkUploadPlanIt.forecastOrders as PlanItBulkATMOrder[];

        const payload = constructOrdersForRequest(forecastOrders, userName, region);

        dispatch({ type: PLANIT_BULK_ATM_SUBMIT_ORDERS_LOADING });

        OrdersService.submitPlanItBulkAtmUpload(payload, accessToken)
            .then((response) => {
                if (response.data?.orders?.length) {
                    atmBulkUploadResponseHelper(response.data.orders);
                    const updatedOrders: PlanItBulkATMOrder[] = forecastOrders
                        .filter(
                            (order) =>
                                order.productionOrderId ||
                                order.lines.some((line) => !line.deleted && line.palletQuantity)
                        )
                        .map((order) => {
                            const orderFromApi = response.data.orders.find(
                                (apiOrder) =>
                                    apiOrder.shipToId === order.shipToId &&
                                    moment(apiOrder.weekStart)
                                        .startOf('day')
                                        .isSame(moment(order.weekStart).startOf('day'))
                            );
                            return {
                                ...order,
                                canQuantityPallets: orderFromApi?.canQuantityPallets
                                    ? orderFromApi.canQuantityPallets
                                    : 0,
                                canQuantitySKUs: orderFromApi?.canQuantitySKUs
                                    ? orderFromApi.canQuantitySKUs
                                    : 0,
                                canQuantityEaches: orderFromApi?.canQuantityEaches
                                    ? orderFromApi.canQuantityEaches
                                    : 0,
                                endQuantityPallets: orderFromApi?.endQuantityPallets
                                    ? orderFromApi.endQuantityPallets
                                    : 0,
                                endQuantitySKUs: orderFromApi?.endQuantitySKUs
                                    ? orderFromApi.endQuantitySKUs
                                    : 0,
                                endQuantityEaches: orderFromApi?.endQuantityEaches
                                    ? orderFromApi.endQuantityEaches
                                    : 0
                            };
                        });
                    dispatch({
                        type: PLANIT_BULK_ATM_SUBMIT_ORDERS_SUCCESS,
                        forecastOrders: updatedOrders
                    });
                } else {
                    const updatedAtmOrders: PlanItBulkATMOrder[] = forecastOrders.map(
                        (atmOrder) => {
                            return {
                                ...atmOrder,
                                canQuantityPallets: 0,
                                canQuantitySKUs: 0,
                                canQuantityEaches: 0,
                                endQuantityPallets: 0,
                                endQuantitySKUs: 0,
                                endQuantityEaches: 0
                            };
                        }
                    );
                    dispatch({
                        type: PLANIT_BULK_ATM_SUBMIT_ORDERS_SUCCESS,
                        forecastOrders: updatedAtmOrders
                    });
                }
                callback(false);
            })
            .catch((error) => {
                dispatch({ type: PLANIT_BULK_ATM_SUBMIT_ORDERS_ERROR, error: 'submit' });
                callback(false);
            });
    };
};

export const constructOrdersForRequest = (
    storeAtmOrders: PlanItBulkATMOrder[],
    userName: string,
    region: string
) => {
    let forecastOrders: any[] = [];
    storeAtmOrders.forEach((atmOrder) => {
        let linesByWeekAndShipTo: any[] = [];
        const requestedDate = atmOrder.lines[0].requestedDate
        linesByWeekAndShipTo = atmOrder.lines
            .filter((line) => !line.deleted && line.palletQuantity)
            .map((line) => ({ ...line, shipToId: atmOrder.shipToId }));
        if (linesByWeekAndShipTo.length || atmOrder.productionOrderId) {
            forecastOrders.push({
                region: region,
                createdBy: userName,
                submittedBy: userName,
                status: Order_Status.Confirmed,
                shipToId: atmOrder.shipToId,
                lines: linesByWeekAndShipTo,
                weekStart: requestedDate,
                customerProductionOrderId: atmOrder.customerProductionOrderId,
                productionOrderId: atmOrder.productionOrderId
            });
        }
    });
    return forecastOrders;
};

export const resetTemplateError = () => ({
    type: PLANIT_BULK_UPLOAD_ATM_TEMPLATE_CLEAR
});

/**
 * Combines product data with data from the line.
 * @param line
 * @param matchingProduct
 */
function combineProductData(line, matchingProduct, destination) {
    const productStatus =
        matchingProduct?.status === 'ACTIVE' && destination?.status === 'ACTIVE'
            ? 'ACTIVE'
            : 'INACTIVE';
    return { ...line, ...matchingProduct, status: line.status, productStatus };
}

/**
 * Loads atm order for edit from summary page
 * @param order
 * @param products
 * @returns
 */
export const loadEditForecastOrder = (
    order: ProductionOrder,
    products: PlanItBulkATMLineItem[]
) => {
    return (dispatch) => {
        const lines: PlanItBulkATMLineItem[] =
            order.lines?.map((line) => {
                const matchingProduct = products.find(
                    (product) => product.productSku === line.productSku
                );
                const destination = matchingProduct?.destinations?.find(
                    (d) => d.shipToId === order.shipToId
                );
                let updatedLine = combineProductData(
                    line,
                    matchingProduct,
                    destination
                ) as PlanItBulkATMLineItem;

                updatedLine.originalPalletQuantity = line.palletQuantity;
                updatedLine.isCustomerProductIdDistinct = true;

                return updatedLine;
            }) || [];

        const atmOrder: PlanItBulkATMOrder = {
            ...order,
            lines: lines,
            weekStart: order.atmWeekStart!,
            canQuantityPallets: order.canQuantityPallets ?? 0,
            canQuantitySKUs: order.canQuantitySKUs ?? 0,
            endQuantityPallets: order.endQuantityPallets ?? 0,
            endQuantitySKUs: order.endQuantitySKUs ?? 0
        };
        dispatch({
            type: PLANIT_BULK_ATM_EDIT_ORDER_LOADED,
            forecastOrders: [atmOrder],
            products: products
        });
    };
};

const getEndsForShipToIds = (state: any, shipToIds: string[]): EndsForShipToId[] => {
    const apiEnds: EndsForShipToId[] = [];
    shipToIds.forEach((shipToId) => {
        EndService.getEnds(state, shipToId).then((response) => {
            apiEnds.push({
                shipToId: shipToId,
                ends: response.data.ends
            });
        });
    });
    return apiEnds;
};

const getMultipleProductsForShipToIds = (state: any, requestObj, shipToIds: string[]) => {
    const promises: Promise<AxiosResponse<ProductSearchResponse>>[] = [];
    shipToIds.forEach((shipToId) =>
        promises.push(
            ProductService.getMultipleProducts(state, {
                ...requestObj,
                accountId: shipToId
            }).then((response) => {
                if (response.data.products) {
                    response.data.products.forEach((product) => {
                        product.requestShipToId = shipToId;
                    });
                }
                return response;
            })
        )
    );
    return Promise.all(promises);
};

// pass loadLeadTimes = false if you don't need leadTimes data on products to save time and API calls
export const loadPlanItBulkProducts = (shipToIds: string[], loadLeadTimes: boolean = true) => {
    return (dispatch, getState) => {
        dispatch({
            type: PLANIT_BULK_UPLOAD_LOADING
        });
        const state = getState();
        const auth: AuthState = getState().auth;
        const shipTosAsNumber = shipToIds.map((shipToId) => {
            return parseInt(shipToId);
        });
        const requestObj: MakeItProductsRequest = {
            ShipToIds: filterShipToIdsByPermission(
                auth,
                shipTosAsNumber,
                Activity.PlanItBulkUpload,
                SecurityLevel.Edit,
                ValidationMethod.byAccess
            ),
            activeOnly: false
        };
        const regionCode = state.regionCulture.regionCode;

        let products: any[] = [];

        const endsForShipToIds = getEndsForShipToIds(getState, shipToIds);

        OrdersService.getMakeItProducts(state.auth.accessToken, requestObj)
            .then((response) => {
                products = findDistinctObjects(response.data.products, 'productSku');
                if (products.length > 0) {
                    let productRequest = createProductRequestObj(products, undefined, shipToIds);
                    return getMultipleProductsForShipToIds(getState, productRequest, shipToIds);
                }
            })
            .then((responses) => {
                const responseData: {
                    destination: Number;
                    products: Product[];
                    ends: End[] | undefined;
                }[] = [];
                responses?.forEach((response, index) => {
                    responseData.push({
                        destination: Number(shipToIds[index]),
                        products: response.data.products,
                        ends: endsForShipToIds.find((e) => e.shipToId === shipToIds[index])?.ends
                    });
                });

                if (responseData) {
                    // Loop over products from order-api to find matches
                    products.forEach((product) => {
                        const apiProducts = responseData.find((r) =>
                            product.destinations
                                .map(({ shipToId }) => shipToId)
                                .includes(r.destination)
                        )?.products;

                        const apiEnds = responseData.find((r) =>
                            product.destinations
                                .map(({ shipToId }) => shipToId)
                                .includes(r.destination)
                        )?.ends;

                        if (apiProducts) {
                            getMatchingProductInformation(
                                apiProducts,
                                product,
                                product.size,
                                product.type,
                                product.shape,
                                apiEnds,
                                regionCode
                            );
                        }
                    });
                }

                return Promise.resolve(products);
            })
            .then((products) => {
                if (loadLeadTimes) {
                    getProductLeadTimes(products, state).then((leadTimes) => {
                        products.forEach((product) => {
                            product.leadTimeWeeks = getProductLeadTime(
                                product,
                                leadTimes as LeadTimesResponse[]
                            );
                        });

                        dispatch({
                            type: PLANIT_BULK_UPLOAD_GET_PRODUCTS,
                            products: products
                        });
                    });
                } else {
                    dispatch({
                        type: PLANIT_BULK_UPLOAD_GET_PRODUCTS,
                        products: products
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: PLANIT_BULK_UPLOAD_ERROR,
                    error
                });
            });
    };
};

export const updatePlanItBulkItemAllProductSkus = (
    shipToId: number,
    customerProductId: string,
    selectedProduct: MakeItBulkLineItem
) => {
    return (dispatch, getState) => {
        const state = getState();
        const forecastOrders = JSON.parse(
            JSON.stringify(state.bulkUploadPlanIt.forecastOrders)
        ) as PlanItBulkATMOrder[];
        const existingOrders = state.bulkUploadPlanIt.existingOrders as PlanItBulkATMOrder[];
        const products = state.bulkUploadPlanIt.products as PlanItBulkATMLineItem[];
        if (forecastOrders) {
            let forecastOrdersToUpdate = forecastOrders.map((forecastOrder) => {
                if (
                    forecastOrder.shipToId === shipToId &&
                    forecastOrder.lines.filter(
                        (line) =>
                            line.userSuppliedProductId === customerProductId &&
                            line.isCustomerProductIdDistinct === false &&
                            selectedProduct.productSku &&
                            line.candidateProductSkus?.includes(selectedProduct.productSku)
                    ).length > 0
                ) {
                    /*If there are existingOrders,
                     *isolate to compare the newly updated lines to the existing lines.
                     */
                    const existingOrderToCompare = existingOrders.length
                        ? existingOrders.find(
                              (order) =>
                                  order.shipToId === shipToId &&
                                  moment(order.atmWeekStart)
                                      .startOf('day')
                                      .isSame(moment(forecastOrder.atmWeekStart).startOf('day'))
                          )
                        : undefined;
                    /*If there is a line that already exists,
                     *Find it so we can set the lineId and pallet info. Otherwise, clear those values.
                     */
                    const existingOrderLine = existingOrderToCompare?.lines.find(
                        (existingLine) => existingLine.productSku === selectedProduct.productSku
                    );
                    let linesToUpdate = forecastOrder.lines
                        //Groom out existing lines, we will add them back in later
                        .filter((line) => line.originalCsvLineNumber)
                        .map((line) => {
                            if (line.userSuppliedProductId === customerProductId) {
                                line.productSku = selectedProduct.productSku;
                                line.graphicId = selectedProduct.graphicId;
                                line.graphicIdAndVersion = selectedProduct.graphicIdAndVersion;
                                line.displayName = selectedProduct.displayName;
                                line.snoSku = selectedProduct.snoSku!;
                                line.isCustomerProductIdDistinct = true;
                                line.productionOrderLineId = existingOrderLine
                                    ? existingOrderLine.productionOrderLineId
                                    : undefined;
                                line.originalPalletQuantity = existingOrderLine
                                    ? existingOrderLine.originalPalletQuantity
                                    : 0;
                                line.palletsRounded = line.palletQuantity
                                    ? line.palletQuantity % 1 !== 0
                                    : undefined;
                            }
                            return line;
                        });
                    /*If existingOrderToCompare, loop through lines
                     *and ensure we didn't lose any in linesToUpdate.
                     */
                    if (existingOrderToCompare) {
                        existingOrderToCompare.lines.forEach((existingLine) => {
                            let matchingLineIndex = linesToUpdate.findIndex(
                                (lineToUpdate) =>
                                    lineToUpdate.productSku === existingLine.productSku
                            );
                            //Check if existing line not included the newly mapped lines
                            if (matchingLineIndex === -1) {
                                let matchingProduct = products.find(
                                    (product) => product.productSku === existingLine.productSku
                                ) as PlanItBulkATMLineItem;

                                //Create new line object
                                let existingLineToAdd = {
                                    ...existingLine,
                                    ...matchingProduct,
                                    originalPalletQuantity: existingLine.originalPalletQuantity,
                                    deleted: false
                                };
                                linesToUpdate.push(existingLineToAdd);
                            }
                        });
                    }
                    return { ...forecastOrder, lines: linesToUpdate };
                } else {
                    return { ...forecastOrder };
                }
            });
            dispatch({
                type: PLANIT_BULK_ATM_UPDATED_PRODUCT_SKU,
                forecastOrders: forecastOrdersToUpdate
            });
        }
    };
};

export const getInactiveDescriptions = (inactiveProducts: InactiveProduct[], products: any) => {
    return inactiveProducts.map((inactiveProduct) => {
        let matchingProduct = products.find(
            (product) =>
                product.productSku === inactiveProduct.productId ||
                getCustomerProductInformation(product, inactiveProduct.shipToId)
                    .customerProductId === inactiveProduct.productId
        );
        if (matchingProduct) {
            const customerProductInformation = getCustomerProductInformation(
                matchingProduct,
                inactiveProduct.shipToId
            );
            matchingProduct.displayName = customerProductInformation.customerProductName
                ? customerProductInformation.customerProductName
                : matchingProduct.name;
        }
        return { ...inactiveProduct, productDescription: matchingProduct?.displayName };
    });
};

export const updatePlanItCustomerProductionOrderNumber = (
    productionOrderId: string,
    week: string,
    shipToId: number
) => {
    return (dispatch, getState) => {
        const state = getState();
        const forecastOrders = state.bulkUploadPlanIt.forecastOrders as PlanItBulkATMOrder[];
        const updatedForecastOrders = forecastOrders.map((order) => {
            if (
                moment(order.weekStart).format('MM/DD/YYYY') ===
                    moment(week).format('MM/DD/YYYY') &&
                order.shipToId === shipToId
            ) {
                return { ...order, customerProductionOrderId: productionOrderId };
            } else {
                return { ...order };
            }
        });
        dispatch({
            type: PLANIT_BULK_ATM_UPDATE_CUSTOMER_PO,
            forecastOrders: updatedForecastOrders
        });
    };
};