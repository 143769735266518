import React, { useState } from 'react';
import { makeStyles, Grid, Typography, Link } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import {
    ballBlue,
    ballGray,
    medium,
    regularWeight,
    small
} from '../../../../themes/globalConstants';
import Modal from '../../../reusable/molecules/Modal';
import PricingBreakdownPDF from './PricingBreakdownPdf';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { PricingLine } from '../../../../store/reducers/pricing-breakdown';
import { Account } from '../../../../store/reducers/customer-context';
import { getAccountAddress } from '../../../../utility/helpers/address-helpers';
import { formatDate } from '../../../../utility/helpers/date-helpers';
import moment from 'moment';

interface Props {
    items: PricingLine[];
    selectedShipTo: Account;
    selectedBillTo: Account;
    effectiveDate?: string;
    currency?: string;
}

const useStyles = makeStyles(() => ({
    label: {
        textTransform: 'uppercase',
        fontSize: small
    },
    infoHeaderText: {
        textTransform: 'uppercase',
        fontSize: small,
        margin: '0 0.5em'
    },
    linkContainer: {
        display: 'flex',
        paddingBottom: '0.5em !important'
    },
    link: {
        display: 'flex',
        fontSize: medium,
        fontWeight: regularWeight,
        width: '10.75em',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    icon: {
        color: ballBlue,
        paddingTop: '-6px'
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        marginTop: '0.5em'
    },
    toolbarWrapper: {
        alignItems: 'center',
        marginTop: '1em',
        '& .MuiGrid-container': {
            margin: 0
        },
        '& + div.MuiFormControl-root': {
            marginTop: '-0.75em !important'
        }
    },
    subtitle: {
        color: ballGray
    },
    effectiveDate: {
        marginLeft: '1em'
    },
    headerText: {
        fontSize: medium,
    },
    pricingQty: {
        margin: '1em 0 0 1em',
    },
}));

const PricingBreakdownToolbar = ({
    items,
    selectedBillTo,
    selectedShipTo,
    effectiveDate,
    currency
}: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [openPdf, setOpenPdf] = useState(false);

    const printForm = () => {
        setOpenPdf(true);
    };

    const onClose = () => {
        setOpenPdf(false);
    };

    return (
        <Grid container className={classes.toolbarWrapper}>
            <Grid container item xs={8} justify="flex-start">
                <Grid item xs={3} className={classes.effectiveDate}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="effectiveDate">Effective Date</Trans>
                    </Typography>
                    <Typography className={classes.headerText} data-testid="effective-date">
                        {effectiveDate}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="currency">Currency</Trans>
                    </Typography>
                    <Typography className={classes.headerText} data-testid="currency">
                        {currency}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item xs={4} spacing={2} alignItems="center" justify="flex-end">
                <Grid item>
                    <Typography variant="h6" className={classes.label}>
                        <Trans i18nKey="share">Share:</Trans>
                    </Typography>
                </Grid>
                <Grid item className={classes.linkContainer}>
                    <Link
                        className={classes.link}
                        underline="always"
                        data-testid="print-btn"
                        onClick={printForm}
                    >
                        <Typography>
                            <Trans i18nKey="downloadPDF">Download .PDF</Trans>
                        </Typography>
                        <GetAppOutlinedIcon fontSize="small" className={classes.icon} />
                    </Link>
                </Grid>
                <Modal
                    open={openPdf}
                    title={t('pricingBreakdown', 'Pricing Breakdown')}
                    close={onClose}
                    closeIcon={true}
                    fullScreen={true}
                >
                    <PricingBreakdownPDF
                        title={t('pricingBreakdown', 'Pricing Breakdown')}
                        pricingData={items}
                        currency={items[0]?.currency ?? 'USD'}
                        effectiveDate={formatDate(items[0]?.effectiveDate ?? moment().toString())}
                        billToId={selectedBillTo.accountId}
                        billToAddress={getAccountAddress(selectedBillTo)}
                        billToName={selectedBillTo.name}
                        shipToId={selectedShipTo.accountId}
                        shipToAddress={getAccountAddress(selectedShipTo)}
                        shipToName={selectedShipTo.name}
                    />
                </Modal>
            </Grid>
            <Grid container item xs={6}>
                <Typography variant="h6" className={`${classes.subtitle} ${classes.pricingQty} ${classes.headerText}`}>
                    <Trans i18nKey="pricingQtyNote">Prices are listed at a per thousand rate unless otherwise noted</Trans>
                </Typography>
            </Grid>
        </Grid>
    );
};

export default PricingBreakdownToolbar;
