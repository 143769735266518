import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { useTranslation, Trans } from 'react-i18next';
import { makeStyles, Typography, TableRow, TableCell, Grid, Tooltip } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';
import Button from '../../../reusable/atoms/Button';
import { Invoice } from '../../../../store/reducers/make-it-summary';
import { getPaymentStatus } from '../../../../utility/helpers/pending-payment-helpers';
import { getFormattedDateStringForApplication } from '../../../../utility/helpers/date-helpers';
import {
    blackWeight,
    ballLtGray,
    red,
    ballBlue,
    yellow,
    small
} from '../../../../themes/globalConstants';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { getAccountAddress } from '../../../../utility/helpers/address-helpers';
import { PendingPaymentsState } from '../../../../store/reducers/pending-payments';
import { currencyFormatter } from '../../../../utility/helpers/formatters';

interface Props {
    item: Invoice;
    onSubmitPayment: (item: Invoice) => void;
    disableSubmit: boolean;
}

const useStyles = makeStyles((theme) => ({
    tableRowContainer: {
        '& p': {
            paddingLeft: '0.375em'
        },
        '& .MuiInputBase-input': {
            textAlign: 'right',
            paddingRight: '.25em'
        }
    },
    textField: {
        width: '3.125em',
        height: '1.5em',
        border: `2px solid ${ballLtGray}`,
        borderRadius: '.25em',
        marginRight: '0.5em',
        fontWeight: blackWeight
    },
    alignCenter: {
        textAlign: 'center'
    },
    alignRight: {
        textAlign: 'right'
    },
    paletteInputContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    tooltip: {
        marginLeft: '0.25em'
    },
    red: {
        fill: red
    },
    blue: {
        fill: ballBlue
    },
    yellow: {
        fill: yellow
    },
    disabledBtn: {
        color: theme.palette.secondary.main,
        fontSize: small,
        fontWeight: blackWeight,
        textTransform: 'uppercase'
    },
    fontWeightBlack: {
        fontWeight: blackWeight
    },
    ballBlue: {
        color: ballBlue,
        '& p': {
            fontWeight: blackWeight
        }
    }
}));

export default function PendingPaymentsRow({ item, onSubmitPayment, disableSubmit }: Props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const paymentDueDate = getFormattedDateStringForApplication(item.paymentDueDate.toString()) || '';

    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { invoices } = useTypedSelector<PendingPaymentsState>(
        (state) => state.pendingPaymentsDashboard
    );

    const onClickSubmit = () => {
        onSubmitPayment(item);
    };

    const formatShipToString = (invoiceId: string) => {
        if (shipToAccounts && invoices.length) {
            let filteredInvoices = invoices.filter((inv) => invoiceId === inv.invoiceId);
            const invoice = filteredInvoices[0];
            let filteredAccounts = shipToAccounts.filter(
                (shipToAccount) => invoice.shipToId === +shipToAccount.accountId
            );

            if (filteredAccounts[0]) {
                return (
                    <>
                        <Typography key={'ship-to-' + invoiceId}>
                            {filteredAccounts[0].name}
                        </Typography>
                        <Typography key={'ship-to-address-' + invoiceId}>
                            {getAccountAddress(filteredAccounts[0])}
                        </Typography>
                    </>
                );
            }
        }
        return;
    };

    const formatDate = (date: Date) => {
        return moment(date).format('L');
    };

    const displayPaymentDue = (date: Date, id: string) => {
        const paymentStatus = getPaymentStatus(date);
        let paymentCell: React.ReactNode[] = [
            <Typography key={'payment-date-' + id}>{formatDate(date)}</Typography>
        ];

        switch (paymentStatus) {
            case 'overdue':
                return [
                    ...paymentCell,
                    <Tooltip
                        title={<>{t('paymentOverDue', 'Payment is overdue')}</>}
                        arrow
                        key={'warning-' + id}
                    >
                        <img
                            className={clsx(classes.tooltip, classes.red)}
                            src={process.env.PUBLIC_URL + '/assets/Error_Icon.svg'}
                            alt="Error Icon"
                        />
                    </Tooltip>
                ];
            case 'dueThisWeek':
                return [
                    ...paymentCell,
                    <Tooltip
                        title={<>{t('paymentOverDueThisWeek', 'Payment is due this week')}</>}
                        arrow
                        key={'warning-' + id}
                    >
                        <img
                            className={clsx(classes.tooltip, classes.yellow)}
                            src={process.env.PUBLIC_URL + '/assets/Warning_Icon.svg'}
                            alt="Warning Icon"
                        />
                    </Tooltip>
                ];
            case 'dueThisMonth':
                return [
                    ...paymentCell,
                    <Tooltip
                        title={<>{t('paymentOverDueThisMonth', 'Payment is due this month')}</>}
                        arrow
                        key={'warning-' + id}
                    >
                        <WarningIcon className={clsx(classes.tooltip, classes.blue)} />
                    </Tooltip>
                ];
            default:
                return paymentCell;
        }
    };

    useTranslation();

    return (
        <>
            {item && item.submittedDate && item.paymentDueDate && item.invoiceId && (
                <TableRow className={classes.tableRowContainer}>
                    <TableCell>
                        <Typography
                            className={classes.fontWeightBlack}
                            data-testid="customer-order-id"
                        >
                            {item.customerProductionOrderId}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography data-testid="production-order-number">
                            {item.productionOrderNumber}
                        </Typography>
                    </TableCell>
                    <TableCell>{formatShipToString(item.invoiceId)}</TableCell>
                    <TableCell>
                        <Typography data-testid="invoice-amount">
                            {currencyFormatter('NA', item.amount)}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography data-testid="submitted-date">
                            {getFormattedDateStringForApplication(item.submittedDate.toString())}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Grid
                            container
                            alignItems="center"
                            className={classes.ballBlue}
                            data-testid="payment-due-date"
                        >
                            {displayPaymentDue(paymentDueDate, item.invoiceId)}
                        </Grid>
                    </TableCell>
                    <TableCell>
                        {!disableSubmit ? (
                            <Button
                                type="button"
                                variant="outlined"
                                color="primary"
                                onClick={onClickSubmit}
                                data-testid="submitted-button"
                            >
                                <Trans i18nKey="clickIfSubmitted">Click If Submitted</Trans>
                            </Button>
                        ) : (
                            <Typography
                                className={classes.disabledBtn}
                                data-testid="payment-submitted"
                            >
                                <Trans i18nKey="paymentSubmitted">Payment Submitted</Trans>
                            </Typography>
                        )}
                    </TableCell>
                </TableRow>
            )}
        </>
    );
}
