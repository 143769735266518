import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import { ProductToShip } from '../../../../store/reducers/shipping-dashboard';
import {
    blackWeight,
    ballGray,
    small,
    warningLabel,
    ballBlue,
    boldWeight
} from '../../../../themes/globalConstants';
import { generateKey } from '../../../../utility/helpers/order-helpers';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import ShipmentColumns from './ShipmentColumns';
import ShipmentRow from './ShipmentRow';
import { changeShipmentExpandableLoads } from '../../../../store/actions/edit-shipments';
import { useDispatch } from 'react-redux';
import ShipItConfigToolbar from '../../../reusable/molecules/ShipItConfigToolbar';

interface Props {
    items: ProductToShip[];
    showWarning?: boolean;
    warning?: React.ReactNode;
    shipmentId?: string;
    isEditShipment?: boolean;
    maxPallets?: number;
}

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        width: '100%',
        '& .MuiPaper-root': {
            boxShadow: 'unset'
        },
        '& .MuiToolbar-root': {
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                }
            }
        },
        '& .MuiTableSortLabel-root': {
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em'
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableCell-head': {
            color: ballGray,
            fontSize: small,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            '&:not(:first-of-type)': {
                borderLeft: 'unset'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                '&:nth-child(1)': {
                    width: '10%'
                },
                '&:nth-child(2)': {
                    width: '10%'
                },
                '&:nth-child(3)': {
                    width: '15%'
                },
                '&:nth-child(4)': {
                    width: '30%'
                },
                '&:nth-child(5)': {
                    width: '15%'
                },
                '&:nth-child(6)': {
                    width: '10%'
                },
                '&:nth-child(7)': {
                    width: '10%'
                }
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    },
    warningContainer: {
        marginTop: '1em',
        width: '100%',
        padding: '0.875em 0',
        borderRadius: '.125em',
        '& .MuiAlert-icon': {
            color: ballBlue,
            display: 'flex',
            alignItems: 'center'
        }
    },
    warning: {
        backgroundColor: warningLabel,
        justifyContent: 'center'
    },
    warningText: {
        fontWeight: boldWeight,
        textAlign: 'center'
    }
}));

export default function ShipmentGrid({
    items,
    maxPallets,
    showWarning = false,
    warning,
    shipmentId,
    isEditShipment
}: Props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { state } = useTypedSelector((state) => state);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const onCollapseAll = () => {
        if (shipmentId) {
            dispatch(changeShipmentExpandableLoads(state, shipmentId.toString(), false));
        }
    };
    const onExpandAll = () => {
        if (shipmentId) {
            dispatch(changeShipmentExpandableLoads(state, shipmentId.toString(), true));
        }
    };

    const CustomToolbarOptions = new ToolbarOptions({
        customItems: (
            <ShipItConfigToolbar
                onCollapseAll={onCollapseAll}
                onExpandAll={onExpandAll}
                warning={showWarning}
                warningText={warning}
                isEditShipment={isEditShipment}
                maxPallets={maxPallets}
            />
        ),
        exportButton: false,
        printButton: false,
        advancePagination: false,
        enablePagination: false,
        searchText: false
    });

    const ProductRow = ({ row }: { row: Partial<ProductToShip> }) => {
        return (
            <ShipmentRow
                key={generateKey('product')}
                item={row as ProductToShip}
                shipmentId={shipmentId}
            />
        );
    };

    return (
        <Grid
            container
            className={classes.tableWrapper}
            key={generateKey('edit-shipment-grid')}
            data-testid="edit-shipments-data-grid"
        >
            <DataGrid
                columns={ShipmentColumns.getShipmentColumns(cultureCode)}
                dataSource={items}
                gridName="Edit Shipments Grid"
                rowComponent={ProductRow}
                toolbarOptions={CustomToolbarOptions}
            />
        </Grid>
    );
}
