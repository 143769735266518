import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';

interface Props {
    errorComponent?: any;
    homePath: string;
}

const LoginCallback = ({ errorComponent, homePath }: Props) => {
    const { authService, authState } = useOktaAuth();
    const authStateReady = !authState.isPending;

    let ErrorReporter = errorComponent;

    useEffect(() => {
        authService.setFromUri(homePath);
        authService.handleAuthentication();
    }, [authService]);

    if (authStateReady && authState.error) {
        return <ErrorReporter error={authState.error} />;
    }

    return null;
};

export default LoginCallback;
