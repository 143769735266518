import { makeStyles, Grid, Typography, CircularProgress, TextField } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
    blackWeight,
    boldWeight,
    large,
    red,
    small,
    white,
    black
} from '../../../themes/globalConstants';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import OrderCancelModal from '../../reusable/molecules/OrderCancelModal';
import Button from '../../reusable/atoms/Button';
import { useTypedSelector } from '../../../store/reducers/reducer';
import PlanItConfigGrid from './components/PlanItConfigGrid';
import { OrderProductTableRow } from '../../../store/reducers/orders-dashboard';
import { useHistory } from 'react-router';
import { updateProductsToOrder, resetMakeItState } from '../../../store/actions/makeit-dashboard';
import { Activity, SecurityLevel } from '../../../utility/auth/useSecurity';
import { AuthState } from '../../../store/reducers/auth';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { selectIsLargeCustomerAccount } from '../../../store/selectors';
import { selectIsImpersonation } from '../../../store/selectors/index';
import QuantityUnitSelector from '../../reusable/molecules/QuantityUnitSelector';
import { QuantityUnitSelectorProvider } from '../../reusable/molecules/QuantityUnitSelectorContext';
import ImpersonationWarning from '../../reusable/molecules/ImpersonationWarning';
import { PlanItGuidedState } from '../../../store/reducers/plan-it-guided';
import {
    resetPlanItGuidedState,
    updatePlanItProducts,
    submitForecastOrders,
    updateCustomerPO,
    removeProductPlanItGuidedConfiguration
} from '../../../store/actions/plan-it-guided';
import AddProductsModal from '../../reusable/molecules/AddProductsModal/AddProductsModal';
import { DateProperties, getDateProperties } from '../../../utility/helpers/make-it-bulk-helpers';
import moment from 'moment';
import { PlanItSummaryState } from '../../../store/reducers/plan-it-summary';
import ShipToDropdown from '../MakeItBulkReviewATM/components/ShipToDropdown';
import WeekSelectorDropdown from '../MakeItBulkReviewATM/components/WeekSelectorDropdown';
import { FormattedWeek } from '../MakeItBulkReviewATM/models/FormattedWeek';
import { FormattedAccount } from '../MakeItBulkReviewATM/models/FormattedAccount';
import { loadForecastOrders } from '../../../store/actions/plan-it-summary';
import ErrorAlert from '../../reusable/atoms/ErrorAlert';
import { customerPoIsValid } from '../../../utility/helpers/order-helpers';

const useStyles = makeStyles((theme) => ({
    dashboard: {
        padding: '0'
    },
    main: {
        padding: '0',
        marginTop: '2.750em'
    },
    content: {
        marginBottom: '1em'
    },
    actionBar: {
        margin: '1.5em 0',
        padding: '0'
    },
    btnWrapper: {
        '& button:last-child': {
            marginLeft: '1.25em'
        }
    },
    actionBtn: {
        borderRadius: 'unset'
    },
    title: {
        fontWeight: blackWeight,
        color: theme.palette.secondary.main,
        padding: '0 0 1em 0'
    },
    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '0.5em'
    },
    selector: {
        display: 'flex',
        marginTop: '2em'
    },
    fieldLabel: {
        fontSize: large,
        fontWeight: blackWeight,
        textTransform: 'uppercase'
    },
    fieldInput: {
        width: '85%',
        '& .MuiInputBase-root': {
            border: '1px solid',
            borderColor: theme.palette.secondary.light,
            backgroundColor: white,
            height: '2em',
            padding: '0.5em',
            '&:before': {
                display: 'none'
            }
        }
    },
    fieldHelper: {
        fontSize: small,
        fontWeight: boldWeight
    },
    errorMessage: {
        fontSize: 12,
        fontWeight: boldWeight,
        color: red
    },
    quantityUnitSelector: {
        marginBottom: '1em'
    },
    poLabel: {
        color: black,
        fontSize: '18px',
        fontWeight: 'bold',
        marginTop: '2em',
        marginBottom: '.5em'
    },
    poFieldInput: {
        width: '360px',
        height: '100%',
        marginRight: '0.25em',

        '& .MuiInputBase-root': {
            border: '1px solid',
            borderColor: theme.palette.secondary.light,
            backgroundColor: white,
            height: '100%',
            padding: '0.5em',
            '&:before': {
                display: 'none'
            }
        }
    }
}));

const PlanItConfiguration = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { quantityUnit, submitState, guidedOrders } = useTypedSelector<PlanItGuidedState>(
        (state) => state.planItGuided
    );
    const { loaded } = useTypedSelector<PlanItSummaryState>((state) => state.planItSummary);
    const { userInfo } = useTypedSelector<AuthState>((state) => state.auth);
    const { selectedAccountId, accounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const isSpotCustomer = !useTypedSelector<boolean>(selectIsLargeCustomerAccount);

    const [filteredViewProducts, setFilteredViewProducts] = useState<OrderProductTableRow[]>();
    const [currentViewTypes, setCurrentViewTypes] = useState([''] as string[]);
    const [formattedWeekList, setFormattedWeekList] = useState<FormattedWeek[]>();
    const [shipToAccountsList, setShipToAccountsList] = useState<FormattedAccount[]>([]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [shipToId, setShipToId] = useState<string | undefined>(selectedAccountId);

    const [warning, setWarning] = useState<boolean>(false);
    const [warningText, setWarningText] = useState<string>('');
    const [warningTranslation, setWarningTranslation] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);
    const [draft] = useState<boolean>(false);
    const [submit, setSubmit] = useState<boolean>(false);
    const [weeksWithCsvWarnings, setWeeksWithCsvWarnings] = useState<string[]>([]);
    const [submitError] = useState<boolean>(false);
    const [impersonationWarning, setImpersonationWarning] = useState<boolean>(false);
    const isImpersonation = useTypedSelector<boolean>(selectIsImpersonation);
    const [addProductsOpen, setAddProductsOpen] = useState(false);
    const [productsToOrder, setProductsToOrder] = useState<OrderProductTableRow[]>([]);
    const [weeksWithBlankQuantities, setWeeksWithBlankQuantities] = useState<string[]>([]);
    const [customerPO, setCustomerPO] = useState<string>('');
    const customerPOMaxLength = 25;
    const [activeDate, setActiveDate] = useState<DateProperties>({
        fullDate: '',
        week: '',
        year: ''
    });

    // page state of productsToOrder
    const [tableInformation, setTableInformation] = useState<OrderProductTableRow[]>(
        [] as OrderProductTableRow[]
    );
    const dashboardLink = '/dashboard';

    const onCloseConfirmation = () => {
        setOpen(false);
    };

    const onCancel = () => {
        setOpen(true);
    };

    const onConfirmedCancel = () => {
        dispatch(resetMakeItState());
        history.push(dashboardLink);
    };

    const handleBackNavigation = () => {
        dispatch(updateProductsToOrder(tableInformation));
        history.push('/plan-it?from=review');
    };

    const onAddProductsOpen = () => {
        setAddProductsOpen(true);
    };

    const onAddProductsClose = () => {
        setAddProductsOpen(false);
    };

    const handleUpdateCustomerPO = () => {
        dispatch(updateCustomerPO(customerPO, activeDate.fullDate));
    };

    const handleSubmit = useCallback(() => {
        const undefinedOrderQuantity = tableInformation.find(
            (product) => product.quantity === undefined
        );

        if (productsToOrder && !undefinedOrderQuantity) {
            setSubmit(true);
            setWarning(false);
            if (!isImpersonation) {
                dispatch(submitForecastOrders());
            } else {
                setSubmit(false);
                setImpersonationWarning(true);
            }
        } else {
            if (undefinedOrderQuantity) {
                setWarningTranslation('blankForecastQuantity');
                setWarningText(
                    'Forecasted quantity cannot be blank. Please enter a quantity or remove the product from the forecast.'
                );
            }
            setWarning(true);
        }
    }, [
        dispatch,
        history,
        isImpersonation,
        productsToOrder,
        selectedAccountId,
        tableInformation,
        userInfo
    ]);

    useEffect(() => {
        return () => {
            if (
                history.location.pathname !== '/plan-it' &&
                history.location.pathname !== '/plan-it-configuration' &&
                history.location.pathname !== '/plan-it-confirmation'
            ) {
                dispatch(resetPlanItGuidedState());
            }
        };
    }, [dispatch, history.location.pathname]);

    useEffect(() => {
        if (!!guidedOrders && activeDate.fullDate !== '' && loaded) {
            const foundOrder = guidedOrders.find((order) => order.week === activeDate.fullDate);
            if (!!foundOrder) {
                const productsToOrderWithSortIndex = foundOrder.productsToOrder.map(
                    (product, index) => {
                        return { ...product, sortIndex: index };
                    }
                );
                setProductsToOrder(productsToOrderWithSortIndex);
                setTableInformation(productsToOrderWithSortIndex);
                setCustomerPO(foundOrder.customerProductionOrderId ?? '');
            }
        } else if (!!guidedOrders && activeDate.fullDate === '') {
            setActiveDate(getDateProperties(moment(guidedOrders[0].week)));
        }
    }, [guidedOrders, activeDate, loaded]);

    useEffect(() => {
        if (!!guidedOrders && loaded) {
            let weeksWithWarnings: string[] = [];
            let weeksWithAlerts: string[] = [];
            guidedOrders.forEach((order) => {
                if (
                    order.productsToOrder.find(
                        (product) =>
                            product.quantity === undefined || product.palletQuantity === undefined
                    )
                ) {
                    weeksWithWarnings.push(order.week ?? '');
                }
                if (order.productsToOrder.find((product) => product.includedInExistingForecast)) {
                    weeksWithAlerts.push(order.week ?? '');
                }
            });
            setWeeksWithBlankQuantities(weeksWithWarnings);
            setWeeksWithCsvWarnings(weeksWithAlerts);
        }
    }, [guidedOrders, loaded]);

    useEffect(() => {
        if (submitState === 'success') {
            //  Redirect to confirmation page
            history.push('/plan-it-confirmation');
            setSubmit(false);
        }
    }, [submitState, history]);

    const handleItemUpdates = (tableItem: OrderProductTableRow) => {
        setWarning(false);
        const orderForSelectedWeek = guidedOrders?.find((order) => {
            return order.week === activeDate.fullDate;
        });
        const products = [...(orderForSelectedWeek?.productsToOrder ?? [])];

        let changedProductIndex = products.findIndex((product) => {
            return product.productSku === tableItem.productSku;
        });
        products[changedProductIndex] = { ...products[changedProductIndex], ...tableItem };

        dispatch(updatePlanItProducts(products, activeDate.fullDate));
    };

    const handleRemoveItem = (item: OrderProductTableRow) => {
        dispatch(removeProductPlanItGuidedConfiguration(item, activeDate.fullDate));
    };

    const handleViewFilterClick = (viewType: string[]) => {
        setCurrentViewTypes(viewType);
        if (productsToOrder) {
            if (viewType[0] === '') {
                setFilteredViewProducts(productsToOrder);
            } else if (viewType[0] === 'CAMPAIGN') {
                const updatedProducts = productsToOrder.filter((product) => product.campaignTiming);
                setFilteredViewProducts(updatedProducts);
            } else if (viewType[0] === 'ALERTS') {
                const updatedProducts = productsToOrder.filter(
                    (product) => product.includedInExistingForecast
                );
                setFilteredViewProducts(updatedProducts);
            } else if (viewType[0] === 'WARNINGS') {
                const updatedProducts = productsToOrder.filter((product) => !product.quantity);
                setFilteredViewProducts(updatedProducts);
            }
        }
    };

    useEffect(() => {
        if (productsToOrder) {
            setTableInformation(productsToOrder);
            handleViewFilterClick(currentViewTypes);
        }
        // eslint-disable-next-line
    }, [productsToOrder]);

    useEffect(() => {
        if (tableInformation) {
            const undefinedOrderQuantity = tableInformation.find(
                (product) => product.quantity === undefined
            );
            if (!undefinedOrderQuantity) {
                setWarning(false);
            }
        }
        // eslint-disable-next-line
    }, [tableInformation]);

    useEffect(() => {
        if (selectedAccountId && accounts && !shipToAccountsList.length) {
            dispatch(loadForecastOrders([parseInt(selectedAccountId)], true));
        }
        if (selectedAccountId && accounts) {
            let formattedAccount = {
                account: accounts.find((account) => account.accountId === selectedAccountId)!,
                state: weeksWithBlankQuantities.length > 0 ? 'warning' : 'valid'
            } as FormattedAccount;
            setShipToAccountsList([formattedAccount]);
        }
    }, [selectedAccountId, accounts, weeksWithBlankQuantities]);

    useEffect(() => {
        if (formattedWeekList && formattedWeekList.length > 0) {
            setFormattedWeekList(undefined);
        }
    }, [selectedAccountId]);

    useEffect(() => {
        if (!!guidedOrders && loaded) {
            let weekListAsDate: FormattedWeek[] = [];
            guidedOrders.forEach((order) => {
                weekListAsDate.push({
                    weekAsDate: moment(order.week),
                    state: weeksWithBlankQuantities.find((week) => week === order.week)
                        ? 'warning'
                        : 'valid',
                    hasAlert: !!weeksWithCsvWarnings.find((week) => week === order.week)
                });
            });
            setFormattedWeekList(weekListAsDate);
        }
    }, [guidedOrders, weeksWithCsvWarnings, weeksWithBlankQuantities, loaded]);

    useEffect(() => {
        if (!!customerPO) {
            handleUpdateCustomerPO();
        }
    }, [customerPO]);

    const customerPoInputIsValid = useMemo(() => {
        if (!isSpotCustomer) return true;

        return customerPO && customerPoIsValid(customerPO);
    }, [customerPO]);

    const showInvalidCharactersWarning = useMemo(() => {
        return (
            customerPO &&
            customerPO.length > 0 &&
            !customerPO.match(/^[a-zA-Z0-9\/_\-\:. ',#()+&;=]*$/)
        );
    }, [customerPO]);

    const footerActions = (
        <>
            <Grid container item xs={2} className={classes.btnWrapper}>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="cancel-btn"
                    onClick={onCancel}
                    className={classes.actionBtn}
                >
                    <Trans i18nKey="cancel">Cancel</Trans>
                </Button>
            </Grid>
            <Grid container item xs={7}>
                <ImpersonationWarning showWarning={impersonationWarning} warningType={'SUBMIT'} />
            </Grid>
            <Grid container item xs={3} justify="flex-end" className={classes.btnWrapper}>
                <Button
                    type="button"
                    color="primary"
                    variant="outlined"
                    data-testid="back-btn"
                    onClick={handleBackNavigation}
                    className={classes.actionBtn}
                >
                    <Trans i18nKey="back">Back</Trans>
                </Button>
                <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    data-testid="submit-order-btn"
                    className={classes.actionBtn}
                    onClick={handleSubmit}
                    disabled={
                        weeksWithBlankQuantities.length > 0 || submit || !customerPoInputIsValid
                    }
                >
                    <Trans i18nKey="submitForecast">Submit Forecast</Trans>
                </Button>
            </Grid>
        </>
    );

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('planIt', 'Plan It'),
                description: t('setQuantities', 'Set Quantities'),
                thinBanner: true,
                displayDropdown: true,
                disableSelect: true
            }}
            actionFooter={{
                footerAction: footerActions,
                justify: 'space-between',
                sticky: true
            }}
            activity={Activity.PlanItBulkUpload}
            restrictToSecurityLevel={SecurityLevel.Edit}
            loading={!loaded}
        >
            <Grid container spacing={2} className={classes.dashboard}>
                <QuantityUnitSelectorProvider state={{ quantityUnit: quantityUnit }}>
                    <Grid container item xs={12} className={classes.main}>
                        <Grid
                            container
                            item
                            xs={4}
                            className={classes.selector}
                            data-testid="ship-to-selector"
                        >
                            <ShipToDropdown
                                name="shipToSelect"
                                testId="shipToSelect"
                                selectObjects={shipToAccountsList}
                                selected={selectedAccountId}
                                updateSelected={setShipToId}
                                showIcons={weeksWithBlankQuantities.length > 0}
                                showCsvWarning={weeksWithCsvWarnings.length > 0}
                            />
                        </Grid>
                        {formattedWeekList && (
                            <Grid
                                container
                                item
                                xs={4}
                                className={classes.selector}
                                data-testid="dateBar"
                            >
                                <WeekSelectorDropdown
                                    testId="weekSelect"
                                    weeks={formattedWeekList}
                                    selectedDate={activeDate}
                                    onDateSelected={setActiveDate}
                                    showIcons={false}
                                />
                            </Grid>
                        )}
                        <Grid
                            container
                            item
                            xs={4}
                            alignItems="center"
                            justify="flex-end"
                            className={classes.content}
                        >
                            <Button
                                type="button"
                                color="primary"
                                variant="outlined"
                                data-testid="add-products-btn"
                                className={classes.actionBtn}
                                onClick={onAddProductsOpen}
                            >
                                <Trans i18nKey="addProducts">Add Products</Trans>
                            </Button>
                        </Grid>
                        {isSpotCustomer && (
                            <>
                                <Grid>
                                    <Typography className={classes.poLabel}>
                                        <Trans i18nKey="customerPoNumLong">
                                            Customer PO Number
                                        </Trans>
                                    </Typography>
                                </Grid>
                                <Grid container alignItems="center">
                                    <TextField
                                        label=""
                                        variant="standard"
                                        onChange={(control) => setCustomerPO(control.target.value)}
                                        onBlur={handleUpdateCustomerPO}
                                        className={classes.poFieldInput}
                                        inputProps={{
                                            maxLength: customerPOMaxLength,
                                            'data-testid': 'po-number-input'
                                        }}
                                        value={customerPO}
                                        placeholder={t(
                                            'poNumberPlaceholder',
                                            '25 characters or less'
                                        )}
                                        error={!customerPoInputIsValid}
                                        helperText={
                                            showInvalidCharactersWarning && (
                                                <Trans i18nKey="poNumberWarning">
                                                    Please enter a Customer PO Number with no
                                                    special characters
                                                </Trans>
                                            )
                                        }
                                    />
                                </Grid>
                            </>
                        )}
                        <QuantityUnitSelector
                            type={'PlanIt'}
                            quantityUnit={quantityUnit}
                            styles={classes.quantityUnitSelector}
                        />
                        {loaded ? (
                            <PlanItConfigGrid
                                items={filteredViewProducts ? filteredViewProducts : []}
                                onRemoveItem={handleRemoveItem}
                                onItemUpdates={handleItemUpdates}
                                onViewFiltersClick={handleViewFilterClick}
                                warning={warning}
                                warningMessage={warningText}
                                warningTranslation={warningTranslation}
                            />
                        ) : (
                            <Grid
                                container
                                item
                                xs={12}
                                alignItems="center"
                                className={classes.actionBar}
                            >
                                <Grid container item xs={12} className={classes.spinningLoader}>
                                    <CircularProgress />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </QuantityUnitSelectorProvider>
            </Grid>
            {(draft || submit) && (
                <Grid container item xs={12} alignItems="center" className={classes.actionBar}>
                    <Grid container item xs={12} className={classes.spinningLoader}>
                        <CircularProgress />
                    </Grid>
                </Grid>
            )}
            {submitError && (
                <Grid
                    container
                    item
                    xs={12}
                    alignItems="center"
                    justify="center"
                    className={classes.actionBar}
                >
                    <Typography className={classes.errorMessage} data-testid="po-number-error">
                        <Trans i18nKey="submitError">
                            There was an error saving your Plan It order. Please try again later.
                        </Trans>
                    </Typography>
                </Grid>
            )}
            {productsToOrder && !productsToOrder.length && (
                <Grid
                    container
                    item
                    xs={12}
                    alignItems="center"
                    justify="center"
                    className={classes.actionBar}
                >
                    <Typography
                        className={classes.errorMessage}
                        data-testid="all-products-removed-message"
                    >
                        <Trans i18nKey="allProductsRemoved">
                            All products have been removed. Please add products to proceed.
                        </Trans>
                    </Typography>
                </Grid>
            )}
            <OrderCancelModal
                open={open}
                saveProgress={onConfirmedCancel}
                navigationLink={dashboardLink}
                onClose={onCloseConfirmation}
                onCancel={onCloseConfirmation}
                data-testid={'order-cancel-modal'}
            />
            <AddProductsModal
                open={addProductsOpen}
                onClose={onAddProductsClose}
                selectedShipTo={selectedAccountId}
                activeDate={activeDate}
                fromPlanItGuided={true}
            />
            <ErrorAlert
                showError={submitState === 'error'}
                errorMessage={t(
                    'orderFailedMessage',
                    'The system was unable to place your order. Please try again later.'
                )}
            />
        </ProcessingPageTemplate>
    );
};

export default PlanItConfiguration;
