import React, { useEffect, useState } from 'react';
import {
    ballBlue,
    blackWeight,
    boldWeight,
    containerMaxWidth,
    dateOptionInvalidGray
} from '../../../../themes/globalConstants';
import { Grid, makeStyles } from '@material-ui/core';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import TranslationService from '../../../../utility/services/translation-service';
import { generateKey } from '../../../../utility/helpers/order-helpers';
import MakeItBulkCampaignColumns from './MakeItBulkCampaignColumns';
import MakeItBulkCampaignRow from './MakeItBulkCampaignRow';
import { CampaignRun } from '../../../../store/reducers/product-portfolio';
import { ProductionOrderLine } from '../../../../store/reducers/makeit-dashboard';

interface Props {
    campaignRuns?: CampaignRun[] | ProductionOrderLine[];
    hasCampaignRuns?: boolean;
}

const useStyles = makeStyles((theme) => ({
    action: {
        cursor: 'pointer'
    },
    tableWrapper: {
        marginTop: '3em',
        padding: '2em',
        borderRadius: 4,
        maxWidth: containerMaxWidth,
        '& .MuiToolbar-root': {
            padding: 0,
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                },
                '&:first-of-type ': {
                    paddingLeft: 0
                }
            },
            '& > h2': {
                fontSize: '1.5em',
                fontWeight: boldWeight,
                color: theme.palette.secondary.main,
                paddingLeft: '0.625em',
                fontFamily: 'Lato',
                minWidth: '12em'
            }
        },
        '& .MuiTableSortLabel-root': {
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em',
            width: '99%',
            justifyContent: 'space-between',
            '& .MuiSvgIcon-root': {
                fontSize: 14,
                opacity: '1'
            }
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                '&:nth-child(1)': {
                    width: '20%'
                },
                '&:nth-child(2)': {
                    width: '16%'
                },
                '&:nth-child(3)': {
                    width: '16%'
                },
                '&:nth-child(4)': {
                    width: '16%'
                },
                '&:nth-child(5)': {
                    width: '16%'
                },
                '&:nth-child(6)': {
                    width: '16%'
                }
            }
        },
        '& .MuiInputBase-formControl': {
            border: `1px solid ${dateOptionInvalidGray}`,
            borderRadius: '.125em',
            '&:before': {
                display: 'none'
            },
            '&:after': {
                display: 'none'
            },
            '& svg': {
                height: '.875em',
                width: '.875em',
                fill: ballBlue
            },
            '& .MuiIconButton-root': {
                '&:hover': {
                    backgroundColor: 'transparent !important'
                }
            },
            '& .MuiInput-input': {
                paddingLeft: '.25em'
            }
        },
        '& .MuiTable-root': {
            '&:last-child': {
                display: 'none'
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    }
}));

const MakeItBulkCampaignGrid = ({ campaignRuns, hasCampaignRuns }: Props) => {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [columns, setColumns] = useState<any>([]);

    const options = new ToolbarOptions({
        title: TranslationService.getTranslatedText(cultureCode, 'currentAndUpcomingCampaigns'),
        advancePagination: false,
        exportButton: false,
        printButton: false,
        searchText: true,
        itemsPerPage: campaignRuns ? campaignRuns.length : 10
    });

    const CampaignRow = ({ row }: { row: Partial<CampaignRun | ProductionOrderLine> }) => {
        if (row) {
            return (
                <MakeItBulkCampaignRow
                    campaign={row}
                    hasCampaignRuns={hasCampaignRuns}
                    key={row.productDescription}
                />
            );
        } else {
            return null;
        }
    };

    useEffect(() => {
        setColumns(MakeItBulkCampaignColumns.getCampaignColumns(cultureCode));
    }, [cultureCode]);

    return (
        <Grid className={classes.tableWrapper} key={generateKey('make-it-bulk-campaigns')}>
            {campaignRuns && (
                <DataGrid
                    gridName="Make It Bulk Campaigns"
                    columns={columns}
                    dataSource={campaignRuns}
                    rowComponent={CampaignRow}
                    toolbarOptions={options}
                />
            )}
        </Grid>
    );
};

export default MakeItBulkCampaignGrid;
