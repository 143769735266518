import * as types from './action-types';
import OrdersService from '../../utility/services/orders-service';
import {
    ProductToShip,
    DeliveryShipment,
    DeliveryOrder,
    ShipItProductsRequest,
    ExpandableLoad,
    ProductWithPallets,
    QuantityUnit
} from '../reducers/shipping-dashboard';
import { convertToMT } from '../../utility/helpers/date-helpers';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import {
    constructCanDescription,
    constructEndDescription,
    isProductEndType,
    productTypeHelper
} from '../../utility/helpers/order-helpers';
import {
    Activity,
    filterShipToIdsByPermission,
    SecurityLevel
} from '../../utility/auth/useSecurity';
import { AuthState } from '../reducers/auth';

export const shippingDashboardUpdateResults = (products) => ({
    type: types.SHIPPING_DASHBOARD_LOADED,
    products: products
});

export const shippingDashboardUpdatePallets = (productsWithPallets, scrapIt: boolean = false) => ({
    type: scrapIt ? types.SCRAP_IT_UPDATE_PALLETS : types.SHIPPING_DASHBOARD_UPDATE_PALLETS,
    productsWithPallets: productsWithPallets
});

export const loadShippingDashboard = (
    providedShipTo?: string,
    providedDeliveryDate?: string,
    scrapIt: boolean = false
) => {
    return (dispatch, getState) => {
        dispatch({
            type: scrapIt ? types.SCRAP_IT_LOADING : types.SHIPPING_DASHBOARD_LOADING,
            products: null,
            productsWithPallets: null
        });
        const accessToken = getState().auth.accessToken;
        const shipToId = providedShipTo
            ? providedShipTo
            : getState().customerContext.selectedAccountId;
        const selectedDate = providedDeliveryDate
            ? providedDeliveryDate
            : scrapIt
            ? getState().scrapItState.selectedDeliveryDate
            : getState().shippingDashboard.selectedDeliveryDate;
        const queryDate = moment(selectedDate).add(7, 'days').format();
        const viewState = scrapIt
            ? getState().scrapItState.view
            : getState().shippingDashboard.view;
        const rangeState = getState().shippingDashboard.range;

        const auth: AuthState = getState().auth;
        const requestObj: ShipItProductsRequest = {
            ShipToIds: filterShipToIdsByPermission(
                auth,
                [shipToId],
                Activity.NewOpenDeliveryOrders,
                SecurityLevel.Edit
            )
        };
        if (!!viewState && viewState[0].length !== 0) {
            requestObj.Types = viewState;
        }
        if (!!rangeState && rangeState[0].length !== 0) {
            requestObj.UsedWithinNMonths = rangeState;
        }
        if (scrapIt) {
            requestObj.ActiveOnly = false;
        }
        if (shipToId) {
            OrdersService.getShipItProducts(accessToken, requestObj, queryDate)
                .then((response) => {
                    const products = response.data.products.filter(
                        (product) => product.availablePallets && product.availablePallets > 0
                    );
                    products.map((product) => {
                        const canBottleDesc = constructCanDescription(product);
                        const endDesc = constructEndDescription(product);
                        const isEndType = isProductEndType(product.type);
                        product.description = isEndType ? endDesc : canBottleDesc;
                        product.availableItemsPerPallet = product?.quantityPerPallet!;
                        product.agedPalletQuantity = scrapIt ? 0 : undefined;
                        product.orderedPallets = 0;
                        product.graphicIdAndVersion =
                            product.graphicId && product.graphicVersion
                                ? product.graphicId + '-' + product.graphicVersion
                                : '';

                        let matchingDestinationStatus: string | undefined = undefined;
                        if (product.destinations) {
                            product.destinations?.forEach((destination) => {
                                if (
                                    destination.shipToId.toString() === shipToId &&
                                    destination.status === 'ACTIVE'
                                ) {
                                    product.customerProductId = destination.customerProductId;
                                    product.customerProductName = destination.customerProductName;
                                    if (matchingDestinationStatus === undefined) {
                                        matchingDestinationStatus = product.status;
                                    }
                                }
                            });
                        }
                        product.status = matchingDestinationStatus ?? 'INACTIVE';
                        product.displayId = product.productSku;
                        product.displayName = product.customerProductName
                            ? product.customerProductName
                            : product.name;
                        return null;
                    });

                    if (products.length) {
                        dispatch({
                            type: scrapIt ? types.SCRAP_IT_LOADED : types.SHIPPING_DASHBOARD_LOADED,
                            products: products
                        });
                    } else {
                        dispatch({
                            type: scrapIt
                                ? types.SCRAP_IT_NO_PRODUCTS
                                : types.SHIPPING_DASHBOARD_NO_PRODUCTS,
                            products
                        });
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: scrapIt
                            ? types.SCRAP_IT_LOADING_ERROR
                            : types.SHIPPING_DASHBOARD_LOADING_ERROR,
                        error
                    });
                });
        }
    };
};

export const shippingDashboardUpdateView = (
    view: string[],
    type: string,
    scrapIt: boolean = false
) => {
    return (dispatch: any) => {
        dispatch({
            type: type,
            view: view
        });
        dispatch(loadShippingDashboard(undefined, undefined, scrapIt));
    };
};

export const shippingDashboardUpdateRange = (range: string[], type: string) => {
    return (dispatch: any) => {
        dispatch({
            type: type,
            range: range
        });
        dispatch(loadShippingDashboard());
    };
};

export const updateShippingDashboardQuantityUnit = (
    quantityUnit: QuantityUnit,
    scrapIt: boolean = false
) => {
    return (dispatch: any, getState) => {
        const productsWithPallets: Array<ProductWithPallets> = scrapIt
            ? getState().scrapItState.productsWithPallets
            : getState().shippingDashboard.productsWithPallets;
        const existingQuantityUnit = scrapIt
            ? getState().scrapItState.quantityUnit
            : getState().shippingDashboard.quantityUnit;

        if (existingQuantityUnit !== quantityUnit) {
            const convertedProductsWithPallets = productsWithPallets.map((product) => {
                if (product.inputPallets && product.inputEaches) {
                    let inputPallets: number = product.inputPallets;
                    let inputEaches: number = product.inputEaches;
                    switch (quantityUnit) {
                        case 'pallets': {
                            inputEaches = product.inputPallets * product.quantityPerPallet!;
                            break;
                        }
                    }
                    return {
                        ...product,
                        inputPallets,
                        inputEaches
                    };
                }
                return product;
            });
            dispatch({
                type: scrapIt
                    ? types.SCRAP_IT_UPDATE_QUANTITY_UNIT
                    : types.SHIPPING_DASHBOARD_UPDATE_QUANTITY_UNIT,
                quantityUnit: quantityUnit,
                productsWithPallets: convertedProductsWithPallets
            });
        }
    };
};

export const loadProductsToBeShipped = () => {
    return (dispatch, getState) => {
        dispatch({ type: types.SHIPPING_ORDER_LOADING });
        const productsToShip = getState().shippingDashboard.productsToBeShipped;

        dispatch({
            type: types.SHIPPING_ORDER_LOADED,
            productsToBeShipped: productsToShip
        });
    };
};

export const updateProductToBeShipped = (
    productsToShip: ProductToShip[],
    scrapIt: boolean = false
) => {
    return (dispatch) => {
        dispatch({
            type: scrapIt
                ? types.SCRAP_IT_ORDER_PRODUCTS_UPDATE
                : types.SHIPPING_ORDER_PRODUCTS_UPDATE,
            productsToBeShipped: productsToShip
        });
    };
};

export const selectDeliveryDate = (date: moment.Moment) => {
    const formattedDate = convertToMT(date);
    return (dispatch) => {
        dispatch({
            type: types.SHIPPING_DASHBOARD_UPDATE_DELIVERY_DATE,
            selectedDeliveryDate: formattedDate
        });
    };
};

export const updateShipments = (shipments: DeliveryShipment[]) => {
    return (dispatch) => {
        dispatch({
            type: types.SHIPPING_ORDER_SHIPMENTS_UPDATE,
            shipmentsOrder: shipments
        });
    };
};

export const updateDeliveryOrder = (order: DeliveryOrder) => {
    return (dispatch) => {
        dispatch({
            type: types.SHIPPING_UPDATE_DELIVERY_ORDER,
            deliveryOrder: order
        });
    };
};

export const updateDeliveryOrderProductsUpdateError = (error: any) => {
    return (dispatch) => {
        dispatch({
            type: types.DELIVERY_ORDER_UPDATE_ERROR,
            error: 'Error loading products for delivery order with: ' + error
        });
    };
};

export const updateDeliveryOrderUpdateError = (error: any) => {
    return (dispatch) => {
        dispatch({
            type: types.DELIVERY_ORDER_UPDATE_ERROR,
            error: 'Error updating delivery order: ' + error
        });
    };
};

export const toggleAdd = (product: ProductToShip, remove: boolean) => {
    return (dispatch, getState) => {
        let productsToShip: ProductToShip[] = [...getState().shippingDashboard.productsToBeShipped];

        productsToShip.map((item) => {
            if (item.productSku === product.productSku) {
                item.addDisabled = remove;
            }
            return null;
        });

        dispatch(updateProductToBeShipped(productsToShip));
    };
};

export const toggleAddDisabled = (currentShipment: DeliveryShipment) => {
    return (dispatch, getState) => {
        let productsToShip: ProductToShip[] = [...getState().shippingDashboard.productsToBeShipped];
        let itemsInShipment = currentShipment.loads;

        productsToShip.map((item) => {
            let productsToDisable = itemsInShipment?.find(
                (product) => product.productSku === item.productSku
            );
            if (
                (item.calculatedTotalsForPrevNDays &&
                    item.calculatedTotalsForPrevNDays[currentShipment.deliveryDateIndex!] !== 0 &&
                    productTypeHelper(item.type) === currentShipment.shipmentType &&
                    productsToDisable === undefined) ||
                currentShipment.shipmentType === undefined
            ) {
                item.addDisabled = false;
            }
            return null;
        });
        dispatch(updateProductToBeShipped(productsToShip));
    };
};

export const disableAllProducts = () => {
    return (dispatch, getState) => {
        let productsToShip: ProductToShip[] = [...getState().shippingDashboard.productsToBeShipped];

        productsToShip.map((item) => {
            item.addDisabled = true;
            return null;
        });

        dispatch(updateProductToBeShipped(productsToShip));
    };
};

export const resetShipItState = () => {
    return (dispatch) => {
        dispatch({
            type: types.SHIPPING_DASHBOARD_RESET_STATE
        });
    };
};

export const resetShipItProductState = () => {
    return (dispatch) => {
        dispatch({
            type: types.SHIPPING_DASHBOARD_RESET_PRODUCT_STATE,
            products: [],
            productsWithPallets: [],
            productsToBeShipped: [],
            shipmentsOrder: [],
            deliveryOrder: {}
        });
    };
};

export const updateConfigFlag = (value: boolean) => {
    return (dispatch) => {
        dispatch({
            type: types.SHIPPING_DASHBOARD_CONFIG_FLAG,
            fromConfig: value
        });
    };
};

export const updateExpandableLoad = (expandableLoad: ExpandableLoad) => {
    return (dispatch, getState) => {
        const expandableLoads = getState().shippingDashboard.expandableLoads;
        const updatedExpandableLoads = expandableLoads.filter(
            (load) =>
                load.shipmentId !== expandableLoad.shipmentId ||
                load.displayId !== expandableLoad.displayId
        );
        dispatch({
            type: types.SHIPPING_DASHBOARD_EXPANDABLE_LOAD_CHANGE,
            expandableLoads: [...updatedExpandableLoads, expandableLoad]
        });
    };
};

export const changeAllExpandableLoads = (shipmentId: string, expanded: boolean) => {
    return (dispatch, getState) => {
        const expandableLoads = getState().shippingDashboard.expandableLoads;
        const updatedExpandableLoads = expandableLoads
            .filter((load) => load.shipmentId === shipmentId)
            .map((load) => {
                return { ...load, expanded: expanded };
            });
        dispatch({
            type: types.SHIPPING_DASHBOARD_EXPANDABLE_LOAD_CHANGE,
            expandableLoads: [...updatedExpandableLoads]
        });
    };
};
