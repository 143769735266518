import React, { ReactNode, useEffect, useState } from 'react';
import { makeStyles, Snackbar, Typography, SnackbarOrigin } from '@material-ui/core';
import {
    black,
    boldWeight,
    infoDark,
    shipGreen,
    shipRed,
    warningBorder
} from '../../../themes/globalConstants';
import { Alert } from '@material-ui/lab';
import clsx from 'clsx';

export type SeverityState = 'error' | 'warning' | 'info' | 'success';

export interface AlertState extends SnackbarOrigin {
    open: boolean;
}

interface Props {
    key?: any;
    message: ReactNode;
    showAlert: boolean;
    dataTestId?: String;
    severity?: SeverityState;
    adjustedPlacement?: 'left' | 'center' | 'right';
    duration?: number;
}

const useStyles = makeStyles((theme) => ({
    alert: {
        marginTop: '5em',
        justifyContent: 'center',
        '& .MuiAlert-message': {
            '& .MuiTypography-root': {
                color: black,
                fontWeight: boldWeight
            }
        }
    },
    successBorder: {
        border: `1px solid ${shipGreen}`
    },
    warningBorder: {
        border: `1px solid ${warningBorder}`
    },
    infoBorder: {
        border: `1px solid ${infoDark}`
    },
    errorBorder: {
        border: `1px solid ${shipRed}`
    }
}));

/** Example Implementation
 * 
 * <ToasterAlert
        message={t(
            'userAdminSuccessMessage',
            `${enUS.userAdminSuccessMessage}`
        )}
        showAlert={true}
        dataTestId="new-user-added-success-alert"
        severity={'success'}
    />
 */

export default function ToasterAlert({
    key,
    message,
    showAlert,
    dataTestId,
    severity,
    adjustedPlacement = 'right',
    duration = 6000
}: Props) {
    const classes = useStyles();
    const [alertState, setAlertState] = useState<AlertState>({
        open: showAlert,
        vertical: 'top',
        horizontal: adjustedPlacement
    });
    const { vertical, horizontal, open } = alertState;
    const handleClose = () => {
        setAlertState({ ...alertState, open: false });
    };

    /**
     * Set alert state if showAlert changed to true and
     * component where toaster is displayed has already been rendered.
     */
    useEffect(() => {
        if (showAlert) {
            setAlertState({ ...alertState, open: showAlert });
        }
    }, [showAlert, message]);

    if (!message) return null;

    return (
        <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            onClose={handleClose}
            autoHideDuration={duration}
            key={vertical + horizontal + message + key}
            data-testid={`toast-${severity}`}
        >
            <Alert
                severity={severity}
                onClose={handleClose}
                className={clsx(classes.alert, {
                    [classes.successBorder]: severity === 'success',
                    [classes.infoBorder]: severity === 'info',
                    [classes.warningBorder]: severity === 'warning',
                    [classes.errorBorder]: severity === 'error'
                })}
                data-testid={dataTestId ?? ''}
            >
                <Typography>{message}</Typography>
            </Alert>
        </Snackbar>
    );
}
