import React, { ReactElement } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Link from '../atoms/Link';
import ContactUsLink from './ContactUsLink';
import LogoutLink from './LogoutLink';
import { containerMaxWidth, boldWeight, small } from '../../../themes/globalConstants';
import FooterLogo from '../atoms/FooterLogo';

const useStyles = makeStyles((theme) => ({
    footer: {
        backgroundColor: theme.palette.secondary.main,
        padding: '.75em 2em',
        position: 'inherit',
        marginTop: 'auto'
    },
    spacer: {
        color: theme.palette.secondary.contrastText,
        marginLeft: '1em',
        marginRight: '1em'
    },
    footerLink: {
        color: theme.palette.secondary.contrastText,
        fontWeight: boldWeight,
        textTransform: 'none',
        cursor: 'pointer',
        fontSize: small
    },
    container: {
        maxWidth: containerMaxWidth,
        padding: '0 0.625em',
        marginLeft: 'auto',
        marginRight: 'auto'
    }
}));

function Footer(): ReactElement {
    const classes = useStyles();

    return (
        <footer className={classes.footer}>
            <Grid container justify="center" alignItems="center">
                <Grid item>
                    <FooterLogo />
                    <span className={classes.spacer}>|</span>
                    <Link
                        url="https://www.ball.com/na/additional-site-content/special-pages/privacy-statement"
                        underline="none"
                        className={classes.footerLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        data-testid="ballHome"
                    >
                        Terms of Use and Privacy Policies
                    </Link>
                    <span className={classes.spacer}>|</span>
                    <Link
                        url="https://www.ball.com/na/additional-site-content/special-pages/eu-us-privacy-shield-policy"
                        underline="none"
                        className={classes.footerLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        data-testid="ballHome"
                    >
                        EU-U.S. and Swiss-U.S. Privacy Shield Policy
                    </Link>
                    <span className={classes.spacer}>|</span>
                    <Link
                        url="https://www.ball.com/na/additional-site-content/special-pages/california-transparency-act-and-uk-modern-slavery"
                        underline="none"
                        className={classes.footerLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        data-testid="ballHome"
                    >
                        California Transparency Act &amp; UK Modern Slavery Act Statement
                    </Link>
                    <span className={classes.spacer}>|</span>
                    <ContactUsLink className={classes.footerLink} />
                    <span className={classes.spacer}>|</span>
                    <LogoutLink className={classes.footerLink} position="footer" />
                </Grid>
            </Grid>
        </footer>
    );
}

export default Footer;
