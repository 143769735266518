import React from 'react';
import { Link } from 'react-router-dom';
import { IconButton, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    button: {
        '&:hover': {
            backgroundColor: 'transparent'
        },
        marginLeft: -15
    }
}));

const Logo = () => {
    const classes = useStyles();

    return (
        <Link to="/">
            <IconButton className={classes.button} data-testid="home-link">
                <img
                    src={process.env.PUBLIC_URL + '/assets/TheSource_Logo.svg'}
                    alt="The Source Logo"
                    height="50"
                    width="165"
                />
            </IconButton>
        </Link>
    );
};

export default Logo;
