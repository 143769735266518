import UserService from '../../utility/services/user-service';
import { DashboardElement, DashboardElementId } from '../reducers/user-configuration';
import * as types from './action-types';

/**
 * Fetches dashboard positions from user configuration.  Used on page load only.
 * @returns
 */
export const getDashboardPositions = () => {
    return (dispatch, getState) => {
        const accessToken = getState().auth.accessToken;
        UserService.getUserConfiguration(accessToken)
            .then((data) => {
                const dashboardPositions: Array<DashboardElement> = data.dashboardPositions.map(
                    (position) => ({ name: position.name, columnPosition: position.columnPosition })
                );

                dispatch({
                    type: types.USER_CONFIGURATION_FETCH_DASHBOARD_POSITIONS_SUCCESS,
                    dashboardPositions
                });
            })
            .catch(() => {});
    };
};

/**
 *
 * @param positions Array of string identifiers for dashboard widgets.  The order will be ascending.
 * @returns
 */
export const updateDashboardPositions = (positionOrder: Array<DashboardElementId>) => {
    return (dispatch, getState) => {
        const { auth } = getState();
        const dashboardPositions = positionOrder.reduce((prev, curr, i) => {
            prev.push({ name: curr, columnPosition: i, rowPosition: 0, elementCode: '' });
            return prev;
        }, [] as Array<DashboardElement>);

        dispatch({
            type: types.USER_CONFIGURATION_UPDATE_DASHBOARD_POSITIONS,
            dashboardPositions
        });

        UserService.updateUserConfiguration(
            auth.accessToken,
            auth.userInfo.email,
            dashboardPositions
        );
    };
};
