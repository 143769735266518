import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import rootTheme from '../../../../themes/rootTheme';
import { styles } from './styles';
import {
    Grid,
    makeStyles,
    Typography,
    RadioGroup,
    Radio,
    FormControlLabel
} from '@material-ui/core';

interface Props {
    header: string;
    options: any[];
    labelOverrides?: { [key: string]: string };
    selection: any;
    setSelection: (selection) => void;
}

export default function RadioGroupSection({
    header,
    options,
    labelOverrides,
    selection,
    setSelection
}: Props): ReactElement {
    const useStyles = makeStyles(styles(rootTheme) as any);
    const classes = useStyles();
    const { t } = useTranslation();

    function getLabel(option: any): string {
        return t(`recipientType${option}`, (labelOverrides && labelOverrides[option]) || option);
    }

    return (
        <Grid container spacing={2} align-items="flex-start" className={classes.section}>
            <Grid item xs={12}>
                <Typography variant="h5" className={classes.subHeader}>
                    {header}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <RadioGroup
                    row
                    value={selection}
                    className={classes.noSelect}
                    onChange={(event: any) => setSelection(event.target.value)}
                >
                    {options.map((option) => (
                        <FormControlLabel
                            key={option}
                            value={option}
                            control={<Radio />}
                            label={getLabel(option)}
                        />
                    ))}
                </RadioGroup>
            </Grid>
        </Grid>
    );
}
