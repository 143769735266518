import {
    CONTRACT_SUMMARY_LOADING,
    CONTRACT_SUMMARY_LOADED,
    CONTRACT_SUMMARY_ERROR,
    CONTRACT_ACKNOWLEDGEMENT_ERROR,
    CONTRACT_ACKNOWLEDGEMENT_SUCCEEDED,
    CONTRACT_ACKNOWLEDGEMENT_RESET
} from '../actions/action-types';

export interface ContractAndDocument {
    billTo: string;
    documentId?: number;
    documentName: string;
    approver?: string;
    approvalDate?: string;
    expirationDate?: string;
    effectiveDate?: string;
    pdfUrl?: string;
    isApproved?: boolean;
    isActive?: boolean;
}

export interface ContractSummaryState {
    documents: ContractAndDocument[];
    requiresAcknowledgement: any[];
    loaded: boolean;
    loading: boolean;
    error?: any;
    acknowledgementStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
}

const initialState: ContractSummaryState = {
    documents: [],
    requiresAcknowledgement: [],
    loaded: false,
    error: undefined,
    loading: false,
    acknowledgementStatus: 'idle'
};

const contractSummary = (
    state: ContractSummaryState = initialState,
    action: { type: string; [x: string]: any }
): ContractSummaryState => {
    switch (action.type) {
        case CONTRACT_SUMMARY_LOADING:
            return { ...state, documents: [], loaded: false, loading: true };
        case CONTRACT_SUMMARY_LOADED:
            return {
                ...state,
                documents: action.documents,
                requiresAcknowledgement: action.requiresAcknowledgement,
                loaded: true,
                loading: false
            };
        case CONTRACT_SUMMARY_ERROR:
            return { ...state, error: action.error, loaded: true, loading: false };
        case CONTRACT_ACKNOWLEDGEMENT_ERROR:
            return { ...state, error: action.error, acknowledgementStatus: 'failed' };
        case CONTRACT_ACKNOWLEDGEMENT_SUCCEEDED:
            return { ...state, acknowledgementStatus: 'succeeded' };
        case CONTRACT_ACKNOWLEDGEMENT_RESET:
            return { ...state, acknowledgementStatus: 'idle' };
        default:
            return state;
    }
};

export default contractSummary;
