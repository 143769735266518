import {
    Activity,
    filterShipToIdsByPermission,
    SecurityLevel
} from '../../utility/auth/useSecurity';
import OrdersService, { Order_Status } from '../../utility/services/orders-service';
import { AuthState } from '../reducers/auth';
import {
    MAKE_IT_ATM_SUMMARY_LOADING,
    MAKE_IT_ATM_SUMMARY_LOADED,
    MAKE_IT_ATM_SUMMARY_ERROR
} from './action-types';
import { ProductionOrderSummary } from '../reducers/make-it-summary';
import { ForecastStatus } from '../reducers/makeit-bulk-atm';

export const loadProductionOrdersATM = (accounts: number[]) => {
    return (dispatch, getState) => {
        const auth: AuthState = getState().auth;

        dispatch({ type: MAKE_IT_ATM_SUMMARY_LOADING });
        OrdersService.getProductionOrderSummaries(auth.accessToken, {
            shipToIds: filterShipToIdsByPermission(
                auth,
                accounts,
                Activity.MakeItATMSummary,
                SecurityLevel.View
            ),
            statuses: [
                Order_Status.Submit,
                Order_Status.Open,
                Order_Status.EditedPending,
                Order_Status.Closed,
                Order_Status.Completed,
                Order_Status.Cancelled,
                Order_Status.CancelledPending
            ]
        })
            .then((response) => {
                const summaries: ProductionOrderSummary[] = response.data.summaries;
                const nonForecastSummaries = summaries.filter((summary) => {
                    return summary.atmOrderType !== ForecastStatus.Forecast;
                });
                const atmSummaries = nonForecastSummaries.map((summary) => {
                    if (!summary.atmWeekStart) summary.atmWeekStart = summary.firstRequestedDate;

                    return summary;
                });
                dispatch({ type: MAKE_IT_ATM_SUMMARY_LOADED, orders: atmSummaries });
            })
            .catch((error) => {
                dispatch({ type: MAKE_IT_ATM_SUMMARY_ERROR, error });
            });
    };
};
