import React, { useEffect, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import {
    statusLtGreen,
    statusGreen,
    ballLtGray_3,
    ballGray,
    ltYellowPill,
    ltYellowPillText
} from '../../../../themes/globalConstants';
import { useTranslation } from 'react-i18next';
import { OrderProductTableRow } from '../../../../store/reducers/orders-dashboard';
import clsx from 'clsx';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';

interface Props {
    line: OrderProductTableRow;
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'inline-block',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '4px 8px',
        borderRadius: '20px',
        textTransform: 'capitalize',
        marginTop: '0.5em',
        '&:first-child': {
            marginTop: '0'
        }
    },
    active: {
        backgroundColor: statusLtGreen,
        color: statusGreen
    },
    inactive: {
        backgroundColor: ballLtGray_3,
        color: ballGray
    },
    statusChange: {
        backgroundColor: ltYellowPill,
        color: ltYellowPillText,
        fontStyle: 'italic'
    }
}));
const StatusLabel = ({ line }: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((store) => store.regionCulture);
    const [displayStatus, setDisplayStatus] = useState<string>('');
    const [displayChangeStatuses, setDisplayChangeStatuses] = useState<string[]>([]);

    useEffect(() => {
        if (!line.statusChanges || line.statusChanges.length === 0) {
            setDisplayChangeStatuses([]);
            setDisplayStatus(t(line.status!.toLowerCase(), line.status!.toLowerCase()));
        } else {
            var statuses = line?.statusChanges.map((sc) => t(sc.code, cultureCode)) as string[];
            setDisplayChangeStatuses(statuses);
            setDisplayStatus('');
        }
    }, [line]);

    return (
        <>
            {displayChangeStatuses.length > 0 ? (
                displayChangeStatuses.map((d) => (
                    <Typography key={d} className={clsx(classes.root, classes.statusChange)}>
                        {d}
                    </Typography>
                ))
            ) : (
                <Typography
                    className={clsx(
                        classes.root,
                        { [classes.active]: displayStatus === t('active') },
                        { [classes.inactive]: displayStatus === t('inactive') }
                    )}
                >
                    {displayStatus}
                </Typography>
            )}
        </>
    );
};

export default StatusLabel;
