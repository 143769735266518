import React, { createRef } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { successGreen } from '../../../../themes/globalConstants';
import { enUS } from '../../../../utility/translations/locales';
import BuyerInformation from '../components/BuyerInformation';
import ESignature from '../components/ESignature';
import Instructions from '../components/Instructions';
import SellerInformation from '../components/SellerInformation';
import moment from 'moment';
import { CertificateType, TaxApplication } from '../../../../utility/services/onboarding-service';
import {
    getStateProvSelectionByName,
    NCACountries
} from '../../../../utility/helpers/address-helpers';

export function useSalesAndUseTaxSection(): [
    {
        header: string;
        icon: boolean;
    }[],
    React.RefObject<HTMLElement>[],
    {
        section: JSX.Element;
    }[]
] {
    const { t } = useTranslation();

    const sections = [
        {
            header: t('instructionsAnchorText', enUS.instructionsAnchorText),
            icon: false
        },
        {
            header: t('sellerInformationAnchorText', enUS.sellerInformationAnchorText),
            icon: false
        },
        {
            header: t('buyerInformationAnchorText', enUS.buyerInformationAnchorText),
            icon: false
        },
        {
            header: t('eSignatureAnchorText', enUS.eSignatureAnchorText),
            icon: false
        }
    ];

    const formSections = [
        {
            section: <Instructions />
        },
        {
            section: <SellerInformation />
        },
        {
            section: <BuyerInformation />
        },
        {
            section: <ESignature />
        }
    ];

    return [sections, sections.map(() => createRef<HTMLElement>()), formSections];
}

const useConfirmationIconStyles = makeStyles(() => ({
    completedIcon: {
        fill: successGreen,
        width: 23,
        height: 23
    }
}));

export const salesAndUseTaxSellers = [
    {
        id: 'issuedToSeller1',
        name: 'Ball Metal Food Container, LLC',
        address: 'Westminster, CO 80021'
    },
    {
        id: 'issuedToSeller2',
        name: 'Ball Metal Beverage Container Corp.',
        address: 'Westminster, CO 80021'
    },
    {
        id: 'issuedToSeller3',
        name: 'Ball Advanced Aluminum Technologies Corp.',
        address: 'Westminster, CO 80021'
    },
    {
        id: 'issuedToSeller4',
        name: 'Ball Metal Food Container (Oakdale), LLC',
        address: 'Oakdale, CA 95361'
    },
    {
        id: 'issuedToSeller5',
        name: 'Ball Aerosol And Specialty Container Inc.',
        address: 'Westminster, CO 80021'
    }
];

export function ConfirmationIcon() {
    const classes = useConfirmationIconStyles();
    return (
        <img
            data-testid="confirmation-icon"
            className={classes.completedIcon}
            src={process.env.PUBLIC_URL + '/assets/Confirmation_icon.svg'}
            alt="Confirmation Icon"
        />
    );
}

export const defaultFormState = {
    signatureDate: moment(),
    stateArray: [{}],
    signaturePhoneNumber: '',
    signatureEmail: ''
};

export const getFormStateFromTaxApp = (taxApp: TaxApplication) => {
    const formState = {
        purchaseOrderNumber: taxApp.purchaseOrderNumber,
        nameOfFirm: taxApp.buyerInformation.address.name,
        buyerAddressLine1: taxApp.buyerInformation.address.addressLine1,
        buyerAddressLine2: taxApp.buyerInformation.address.addressLine2,
        buyerAddressLine3: taxApp.buyerInformation.address.addressLine3,
        buyerCity: taxApp.buyerInformation.address.city,
        buyerState: getStateProvSelectionByName(
            taxApp.buyerInformation.address.state,
            NCACountries.USA
        ),
        buyerZipcode: taxApp.buyerInformation.address.zipCode,
        ...getIsRegisteredFormState(taxApp.buyerInformation.registeredAs?.[0]),
        descriptionOfBusiness: taxApp.buyerInformation.description,
        descriptionOfProperty: taxApp.buyerInformation.tangibleDescription,
        ...getStateArrayFormState(taxApp.buyerInformation.stateRegistrations),
        ...getIssuedToSellerFormState(taxApp.issuedToSeller),
        certificateType: getCertificateTypeFormState(taxApp.certificateType),
        acceptTerms: taxApp.eSignature.certification,
        signatureFirstName: taxApp.eSignature.firstName,
        signatureLastName: taxApp.eSignature.lastName,
        signatureDate: moment(),
        signatureTitle: taxApp.eSignature.title,
        signatureEmail: taxApp.eSignature.email,
        signaturePhoneNumber: taxApp.eSignature.phoneNumber
    };
    return formState;
};

export const getIsRegisteredFormState = (isRegisteredAsApiValue: string | undefined) => {
    if (isRegisteredAsApiValue) {
        switch (isRegisteredAsApiValue) {
            case 'wholesaler' || 'retailer' || 'manufacturer' || 'seller' || 'lessor':
                return { isRegisteredAs: isRegisteredAsApiValue };
            default:
                return { isRegisteredAs: 'other', other: isRegisteredAsApiValue };
        }
    }
};

export const getStateArrayFormState = (
    stateRegistrations: Array<{ state: string; registrationId: string }> | undefined
) => {
    if (stateRegistrations && stateRegistrations.length) {
        return {
            stateArray: stateRegistrations.map((stateRegistration) => {
                return {
                    state: getStateProvSelectionByName(stateRegistration.state, NCACountries.USA),
                    registrationNumber: stateRegistration.registrationId
                };
            })
        };
    } else {
        return {
            stateArray: [{}]
        };
    }
};

export const getIssuedToSellerFormState = (issuedToSeller: Array<string> | undefined) => {
    if (issuedToSeller && issuedToSeller.length) {
        return {
            issuedToSeller1: issuedToSeller.find(
                (seller) => seller === salesAndUseTaxSellers[0].name
            )
                ? salesAndUseTaxSellers[0].name
                : false,
            issuedToSeller2: issuedToSeller.find(
                (seller) => seller === salesAndUseTaxSellers[1].name
            )
                ? salesAndUseTaxSellers[1].name
                : false,
            issuedToSeller3: issuedToSeller.find(
                (seller) => seller === salesAndUseTaxSellers[2].name
            )
                ? salesAndUseTaxSellers[2].name
                : false,
            issuedToSeller4: issuedToSeller.find(
                (seller) => seller === salesAndUseTaxSellers[3].name
            )
                ? salesAndUseTaxSellers[3].name
                : false,
            issuedToSeller5: issuedToSeller.find(
                (seller) => seller === salesAndUseTaxSellers[4].name
            )
                ? salesAndUseTaxSellers[4].name
                : false
        };
    } else {
        return {};
    }
};

const getCertificateTypeFormState = (certificateType) => {
    switch (certificateType) {
        case CertificateType.BLANKET:
            return 'blanketCertificate';
        case CertificateType.SINGLE:
            return 'singlePurchaseCertificate';
        default:
            return '';
    }
};
