import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class UnrecognizedProductsColumns {
    static getColumns = (culture: string) => {
        return [
            createColumn('', {
                label: '',
                visible: true
            }),
            createColumn('lineNumberString', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'csvRowNumber'),
                sortable: true,
                filterable: true,
                searchable: true
            }),
            createColumn('productId', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'productId'),
                filterable: true,
                sortable: true,
                searchable: true
            }),
            createColumn('shipToId', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'shipTo'),
                filterable: true,
                sortable: true,
                searchable: true
            }),
            createColumn('productName', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'productName'),
                filterable: true,
                sortable: true,
                searchable: true
            })
            // Uncomment In Future Story
            // createColumn('productSize', {
            //     dataType: ColumnDataType.String,
            //     label: TranslationService.getTranslatedText(culture, 'productSizeShape'),
            //     filterable: true,
            //     sortable: true,
            //     searchable: true
            // })
        ];
    };
}
