import { AuthState } from '../../store/reducers/auth';
import { hasPersonas, Persona } from '../auth/useSecurity';
import { Account, AddressType } from '../../store/reducers/customer-context';
import { Product } from '../../store/reducers/product-portfolio';
import { ProductListData } from '../../components/pages/GraphicsIntake/components/ProductOptions';
import { Approver } from '../../store/reducers/graphic-intake';

export interface SelectInputDropdown {
    index: number;
    name: string;
    value: string;
}

export const MAX_APPROVERS = 6;

export const isGraphicsCustomer = (auth: AuthState) => {
    const hasCustomerPersona = hasPersonas(
        [Persona.AllAccess, Persona.MarketingMgmt, Persona.ProcurementMgmt, Persona.ArtMgmt],
        auth
    );
    return hasCustomerPersona;
};

export const isGraphicsVendor = (auth: AuthState) => {
    const hasVendorPersona = hasPersonas([Persona.GraphicsVendor], auth);
    return hasVendorPersona;
};

export const getBillToAccounts = (accounts: Account[]) => {
    return accounts.filter(
        (a) =>
            a.addressType === AddressType.CX ||
            a.addressType === AddressType.CB ||
            a.addressType === AddressType.CP ||
            a.addressType === AddressType.CG
    );
};

export const getShipToAccountsList = (accounts: Account[]) => {
    return accounts.filter(
        (a) => a.addressType === AddressType.CX || a.addressType === AddressType.CS
    );
};

export const getCompanyList = (accounts: Account[]) => {
    let companyList = getBillToAccounts(accounts);
    companyList.sort((account1, account2) => {
        const name1 = account1.name.toUpperCase();
        const name2 = account2.name.toUpperCase();

        if (name1 < name2) return -1;
        if (name1 > name2) return 1;

        return 0;
    });

    return companyList;
};

export const formatAccountDropdown = (accounts: Account[]) => {
    const formattedAccounts = accounts.map((account, index) => {
        let label = account.name;
        let value = account.accountId;
        return {
            index: index,
            name: label,
            value: value
        } as SelectInputDropdown;
    });
    return formattedAccounts;
};

// convert graphics approvers data into an array of approvers
export const getGraphicsApproverArray = (data: any) => {
    let approvers = [] as Approver[];

    for (let approverIndex = 0; approverIndex < MAX_APPROVERS; approverIndex++) {
        let newApprover = {} as Approver;
        let nameKey = `approvers[${approverIndex}].approverName`;
        let emailKey = `approvers[${approverIndex}].approverEmail`;
        let streetKey = `approvers[${approverIndex}].approverAddressStreet`;
        let cityKey = `approvers[${approverIndex}].approverAddressCity`;
        let stateKey = `approvers[${approverIndex}].approverAddressState`;
        let postalCodeKey = `approvers[${approverIndex}].approverAddressPostalCode`;
        let countryKey = `approvers[${approverIndex}].approverAddressCountry`;
        let phoneKey = `approvers[${approverIndex}].approverPhoneNumber`;
        let typeKey = `approvers[${approverIndex}].approverType`;

        if (data[nameKey]) {
            newApprover.approverName = data[nameKey];
            newApprover.approverEmail = data[emailKey];
            newApprover.approverAddressStreet = data[streetKey];
            newApprover.approverAddressCity = data[cityKey];
            newApprover.approverAddressState = data[stateKey];
            newApprover.approverAddressPostalCode = data[postalCodeKey];
            const approverCountry = data[countryKey];
            if (approverCountry) {
                newApprover.approverAddressCountry = approverCountry.value;
            }
            newApprover.approverPhoneNumber = data[phoneKey];
            newApprover.approverType = data[typeKey];

            const fullAddress =
                data[streetKey] +
                ', ' +
                data[cityKey] +
                ', ' +
                data[stateKey] +
                ', ' +
                data[postalCodeKey];

            newApprover.approverAddress = fullAddress;

            approvers.push(newApprover);
        }
    }

    return approvers;
};

//Converts possible API responses to graphics
export const answerFormatter = (status: string | boolean | undefined) => {
    switch (status) {
        case 'Yes':
            return 'yes';
        case 'No':
            return 'no';
        case true:
            return 'yes';
        case false:
            return 'no';
        case 'Unknown':
            return 'unknown';
        case '':
            return '';
    }
};

export const requestTypeFormatter = (requestType: string | undefined) => {
    switch (requestType) {
        case 'New':
            return 'new';
        case 'Existing':
            return 'existing';
    }
};

export const productSort = (a: Product, b: Product) => {
    let volumeDifference = a.volumeML - b.volumeML;
    if (!volumeDifference) {
        volumeDifference = a.volumeOz - b.volumeOz;
    }

    if (volumeDifference === 0) {
        return a.style!.label.localeCompare(b.style!.label, 'en');
    }
    return volumeDifference;
};

export const productListDataSort = (a: ProductListData, b: ProductListData) => {
    let volumeDifference = a.volumeML - b.volumeML;
    if (!volumeDifference) {
        volumeDifference = a.volumeOz - b.volumeOz;
    }

    if (volumeDifference === 0) {
        return a.label.localeCompare(b.label, 'en');
    }
    return volumeDifference;
};
