import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { loadInvoices } from '../../../store/actions/pending-payments';
import { PendingPaymentsState } from '../../../store/reducers/pending-payments';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import PendingPaymentsGrid from './components/PendingPaymentsGrid';
import { ltBlueGrey_34 } from '../../../themes/globalConstants';
import { Activity } from '../../../utility/auth/useSecurity';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { useQuery } from '../../../utility/helpers/query-helpers';
import ClearFilterWarning from '../../reusable/molecules/ClearFilterWarning';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    dashboard: {
        padding: '0'
    },
    main: {
        padding: '0'
    },
    article: {
        backgroundColor: ltBlueGrey_34,
        padding: '1.750em',
        margin: '3em 0 3em 0'
    },
    articleText: {
        marginBottom: '.625em'
    }
}));

export default function PendingPayments() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const query = useQuery();
    const paymentType = query.get('type') ?? undefined;
    const { t } = useTranslation();
    const { invoices, loading } = useTypedSelector<PendingPaymentsState>(
        (state) => state.pendingPaymentsDashboard
    );
    const { selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );

    const handleClearFilter = () => {
        history.push('/pending-payments');
    };

    useEffect(() => {
        if (selectedAccountId && !loading) {
            dispatch(loadInvoices(paymentType, selectedAccountId));
        }
    }, [dispatch, selectedAccountId]);

    useTranslation();

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('pendingPayments', 'Pending Payments'),
                description: '',
                thinBanner: true,
                displayDropdown: true
            }}
            activity={Activity.OrderPaymentFinancials}
        >
            <Grid container alignItems="flex-start" className={classes.dashboard}>
                <Grid container item className={classes.article}>
                    <Grid item className={classes.articleText}>
                        <Typography data-testid="display-id">
                            <Trans i18nKey="pendingPaymentsInvoiceEmail">
                                Pro forma invoices for the orders below have been sent to you. When
                                remitting a payment, please reference the corresponding Ball
                                Production Order Number. Note: Submitting payments past the due date
                                will impact the first available date for your order.
                            </Trans>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography data-testid="pending-payments-submit-payments">
                            <Trans i18nKey="pendingPaymentsSubmitPayments">
                                In order to expedite processing of a payment, click the “Click if
                                Submitted” button below and provide a remittance number (optional)
                                for your payment.
                            </Trans>
                        </Typography>
                    </Grid>
                    {paymentType ? (
                        <ClearFilterWarning
                            showWarning={typeof paymentType !== 'undefined'}
                            warningText={t(
                                'dataIsFiltered',
                                'Data is filtered. Click icon to clear filter: '
                            )}
                            handleClearFilter={handleClearFilter}
                        />
                    ) : null}
                </Grid>
                <Grid container item className={classes.main}>
                    <PendingPaymentsGrid invoices={invoices} />
                </Grid>
            </Grid>
        </ProcessingPageTemplate>
    );
}
