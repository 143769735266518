/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography, makeStyles, Collapse, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
    MakeItBulkATMLineItem,
    MakeItBulkATMLinesBySnoSku
} from '../../../../store/reducers/makeit-bulk-atm';
import {
    small,
    boldWeight,
    blackWeight,
    large,
    black,
    successGreen,
    warningBorder,
    warningLabel,
    medium,
    xl,
    lato,
    ballDrkBlue
} from '../../../../themes/globalConstants';
import clsx from 'clsx';
import { Trans, useTranslation } from 'react-i18next';
import {
    AllocationQuantityState,
    evaluateAllocationQuantityValid
} from '../../../../utility/helpers/make-it-bulk-helpers';
import { generateKey, ToggleAll } from '../../../../utility/helpers/order-helpers';
import WarningAlert from '../../../reusable/atoms/WarningAlert';
import {
    formatNumberWithLocale,
    stringFormatterReplace
} from '../../../../utility/helpers/formatters';
import ErrorAlert from '../../../reusable/atoms/ErrorAlert';
import { editShipmentUseStyles } from '../../../../utility/helpers/shipment-helpers';
import Link from '../../../reusable/atoms/Link';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { MakeItBulkLineItem } from '../../../../store/reducers/makeit-bulk-upload';
import { useMakeItBulkReviewATM } from '../../MakeItBulkReviewATM/context/MakeItBulkReviewATMContext';
import { MakeItBulkATMOrderSectionHeader } from '../../../reusable/molecules/MakeItBulkATMOrderSectionHeader';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { getCustomerProductInformation } from '../../../../utility/helpers/production-order-helpers';
import MakeItConversionReviewOrderSectionProducts from './MakeItConversionReviewOrderSectionProducts';
import { MakeItConversionATMState } from '../../../../store/reducers/makeit-conversion-atm';
import MakeItConversionReviewAddProductGrid from './MakeItConversionReviewAddProductGrid';

interface Props {
    order: MakeItBulkATMLinesBySnoSku;
    shipToId: number;
    isActive: boolean;
    expand: ToggleAll;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: '1em',
        position: 'relative',
        '& h2': {
            fontSize: xl,
            fontFamily: lato,
            fontWeight: blackWeight,
            color: theme.palette.secondary.main
        }
    },
    quantityContainer: {
        alignContent: 'center'
    },
    quantityTitleBlack: {
        fontWeight: blackWeight,
        color: theme.palette.secondary.main
    },
    quantityBlack: {
        fontWeight: blackWeight,
        color: black
    },
    quantityBold: {
        fontWeight: boldWeight,
        color: black
    },
    largeFont: {
        fontSize: large
    },
    mediumFont: {
        fontSize: medium
    },
    icon: {
        width: '2em',
        height: '1.8em',
        position: 'absolute'
    },
    completedIcon: {
        fill: successGreen
    },
    warningContainer: {
        width: '95%',
        border: `1px solid ${warningBorder}`,
        borderRadius: '.125em',
        justifyContent: 'center',
        '& .MuiAlert-icon': {
            color: warningBorder,
            display: 'flex',
            alignItems: 'center'
        },
        backgroundColor: warningLabel
    },
    addProductGrid: {
        width: '54.75em',
        margin: '0 auto 0 auto',
        display: 'none',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    showProductGrid: {
        display: 'block'
    },
    addProductLink: {
        fontSize: small,
        '&.enabled': {
            color: ballDrkBlue,
            cursor: 'pointer'
        }
    },
    subheaderPaper: {
        marginTop: '2em',
        padding: '1em',
        borderRadius: 2,
        width: '100%'
    }
}));

const MakeItConversionReviewOrderSection = ({ order, shipToId, isActive, expand }: Props) => {
    const classes = useStyles();
    const editShipmentClasses = editShipmentUseStyles();
    const { t } = useTranslation();
    const [openDrawer, setOpenDrawer] = useState<boolean>();
    const [allocationState, setAllocationState] = useState<AllocationQuantityState>(undefined);
    const [difference, setDifference] = useState<number>(0);
    const [product, setProduct] = useState<MakeItBulkATMLineItem>();
    const [addProducts, setAddProducts] = useState<boolean>(false);
    const { products } = useTypedSelector<MakeItConversionATMState>(
        (state) => state.makeItConversionATMState
    );
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [enableAddProductButton, setEnableAddProductButton] = useState<boolean>(true);
    const { activeDate } = useMakeItBulkReviewATM();

    const handleToggleExpand = () => {
        order.isActive = !order.isActive;
        if (openDrawer) {
            // close the product grid before collapsing
            setEnableAddProductButton(true);
            setAddProducts(false);
        }
        setOpenDrawer(!openDrawer);
    };

    const handleShowProductGrid = () => {
        setEnableAddProductButton(false);
        setAddProducts(true);
    };

    const handleCloseProductGrid = () => {
        setEnableAddProductButton(true);
        setAddProducts(false);
    };

    const getAvailableProducts = (): MakeItBulkLineItem[] | undefined => {
        if (products && order) {
            return products.filter((product) => {
                if (
                    product.snoSku === order.snoSku &&
                    product.destinations &&
                    product.destinations.filter((destination) => destination.shipToId === shipToId)
                        .length > 0 &&
                    !order.lines
                        .filter(
                            (line) =>
                                (line.originalCsvLineNumber || line.productionOrderLineId) &&
                                !line.deleted
                        )
                        .map((line) => line.displayId)
                        .includes(product.displayId)
                ) {
                    // Adding check here to make sure we have the correct customer product information
                    const customerProductInformation = getCustomerProductInformation(
                        product,
                        shipToId
                    );
                    product.displayId = customerProductInformation.customerProductId
                        ? customerProductInformation.customerProductId
                        : product.productSku;
                    product.displayName = customerProductInformation.customerProductName
                        ? customerProductInformation.customerProductName
                        : product.name;
                    return product;
                }

                return null;
            });
        }
    };

    useEffect(() => {
        if (expand.toggle) {
            setOpenDrawer(true);
        } else if (!expand.toggle) {
            setOpenDrawer(false);
            setEnableAddProductButton(true);
            setAddProducts(false);
        }
    }, [expand]);

    useEffect(() => {
        setOpenDrawer(isActive);
    }, [isActive]);

    useEffect(() => {
        if (order.allocationAmount !== undefined) {
            setAllocationState(evaluateAllocationQuantityValid(order));
            setDifference(Math.abs(order.totalPalletQuantity - order.allocationAmount));
        }
        if (order.lines.length) {
            setProduct(order.lines[0]);
        } else {
            let matchingProduct = getAvailableProducts()?.[0] as MakeItBulkATMLineItem;
            matchingProduct && setProduct(matchingProduct);
        }
    }, [order]);

    useEffect(() => {
        setEnableAddProductButton(true);
        setAddProducts(false);
    }, [activeDate]);

    function renderTotalText(isAlert: boolean) {
        return (
            <>
                <Typography
                    variant="subtitle2"
                    className={clsx(
                        isAlert ? classes.quantityBold : classes.quantityTitleBlack,
                        isAlert ? classes.mediumFont : classes.largeFont
                    )}
                >
                    {` ${t('totalEnteredQuantity', 'Total entered quantity').toUpperCase()}:`}
                </Typography>
                <Typography
                    variant="subtitle2"
                    className={clsx(
                        isAlert ? classes.quantityBold : classes.quantityBlack,
                        isAlert ? classes.mediumFont : classes.largeFont
                    )}
                >
                    &nbsp;
                    {`${formatNumberWithLocale(
                        cultureCode,
                        Number(order.totalPalletQuantity)
                    )} PL `}
                    {`${t('of', 'of').toUpperCase()} `}
                    {`${formatNumberWithLocale(cultureCode, Number(order.allocationAmount))} PL`}
                </Typography>
            </>
        );
    }

    function getAllocationMetStatus() {
        return (
            <>
                {allocationState === 'valid' && (
                    <>
                        <Grid container item xs={11} justify="flex-end">
                            {renderTotalText(false)}
                        </Grid>
                        <Grid container item xs={1}>
                            <img
                                data-testid="complete-icon"
                                className={clsx(classes.icon, classes.completedIcon)}
                                src={process.env.PUBLIC_URL + '/assets/Confirmation_icon.svg'}
                                alt="Confirmation Icon"
                            />
                        </Grid>
                    </>
                )}
                {allocationState === 'belowAllocationWarning' && (
                    <>
                        <Grid item xs={9}>
                            <WarningAlert
                                warningMessage={''}
                                showWarning={true}
                                className={classes.warningContainer}
                                data-testid="warning-alert"
                            >
                                <Grid container item xs={12}>
                                    <Typography
                                        variant="subtitle2"
                                        className={clsx(classes.quantityBlack, classes.largeFont)}
                                    >
                                        {difference === 1
                                            ? t(
                                                  'addPallet',
                                                  'Add 1 pallet to meet your supply plan'
                                              )
                                            : stringFormatterReplace(
                                                  t(
                                                      'addPallets',
                                                      'Add {i} pallets to meet your supply plan'
                                                  ),
                                                  `${formatNumberWithLocale(
                                                      cultureCode,
                                                      Number(difference)
                                                  )}`
                                              )}
                                    </Typography>
                                </Grid>
                                <Grid container item xs={12}>
                                    {renderTotalText(true)}
                                </Grid>
                            </WarningAlert>
                        </Grid>
                    </>
                )}
                {allocationState === 'aboveAllocationError' && (
                    <>
                        <Grid item xs={9}>
                            <ErrorAlert
                                errorMessage={''}
                                showError={true}
                                removeMargin={true}
                                dataTestId="error-alert-test-id"
                            >
                                <Grid container item xs={12}>
                                    <Typography
                                        variant="subtitle2"
                                        className={clsx(classes.quantityBlack, classes.largeFont)}
                                    >
                                        {difference === 1
                                            ? t(
                                                  'removePallet',
                                                  'Remove 1 pallet to meet your supply plan'
                                              )
                                            : stringFormatterReplace(
                                                  t(
                                                      'removePallets',
                                                      'Remove {i} pallets to meet your supply plan'
                                                  ),
                                                  `${formatNumberWithLocale(
                                                      cultureCode,
                                                      Number(difference)
                                                  )}`
                                              )}
                                    </Typography>
                                </Grid>
                                <Grid container item xs={12}>
                                    {renderTotalText(true)}
                                </Grid>
                            </ErrorAlert>
                        </Grid>
                    </>
                )}
            </>
        );
    }

    return (
        <Grid container>
            <Paper
                elevation={2}
                className={classes.subheaderPaper}
                key={`${shipToId}-${order.snoSku}`}
                data-testid="atm-order-section"
            >
                <Grid container xs={12}>
                    <Grid container item xs={5} justify="flex-start">
                        <MakeItBulkATMOrderSectionHeader
                            product={product}
                            shipToId={shipToId}
                            handleToggleExpand={handleToggleExpand}
                            openDrawer={openDrawer}
                            showCampaignPill={true}
                        />
                    </Grid>
                    <Grid
                        container
                        item
                        xs={7}
                        justify="flex-end"
                        className={classes.quantityContainer}
                    >
                        {order.allocationAmount !== undefined && getAllocationMetStatus()}
                        {(product as any)?.inactiveProduct && (
                            <ErrorAlert
                                errorMessage={t('inactiveProductError', {
                                    sku: product?.productSku
                                })}
                                showError={true}
                                removeMargin={true}
                                dataTestId="error-alert-inactive-product"
                            />
                        )}
                    </Grid>
                </Grid>
                <Collapse in={openDrawer} data-testid="collapse-section">
                    <Grid>
                        <MakeItConversionReviewOrderSectionProducts lines={order.lines} />
                        <Grid
                            container
                            justify="flex-end"
                            className={editShipmentClasses.actionsBar}
                        >
                            <Grid item className={editShipmentClasses.shipmentBtnContainer}>
                                <Link
                                    underline="always"
                                    data-testid="add-product-link"
                                    className={`${classes.addProductLink} ${
                                        enableAddProductButton ? 'enabled' : ''
                                    }`}
                                    onClick={handleShowProductGrid}
                                    disabled={!enableAddProductButton}
                                >
                                    {'+ '}
                                    <Trans i18nKey="addProduct">add product</Trans>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Collapse>
            </Paper>
            {addProducts && (
                <Grid container className={classes.root}>
                    <Grid
                        item
                        className={clsx(classes.addProductGrid, {
                            [classes.showProductGrid]: addProducts
                        })}
                        key={generateKey('add-product-grid')}
                    >
                        <MakeItConversionReviewAddProductGrid
                            snoSku={order.snoSku}
                            products={getAvailableProducts()}
                            handleCloseProductGrid={handleCloseProductGrid}
                        />
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default MakeItConversionReviewOrderSection;
