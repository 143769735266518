import React from 'react';
import PageBanner from '../reusable/molecules/PageBanner';
import { BlobProvider } from '@react-pdf/renderer';
import { Grid, LinearProgress, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Font } from '@react-pdf/renderer';
import { Trans } from 'react-i18next';

Font.register({
    family: 'Roboto',
    src: process.env.PUBLIC_URL + '/assets/Roboto-Regular.ttf'
});

const useStyles = makeStyles((theme) => ({
    pdfViewer: {
        width: '100%',
        position: 'absolute',
        margin: 0,
        padding: 0,
        border: 0
    },
    banner: {
        // this makes the assumption that the header is 100px tall
        top: 100,
        height: 'calc(100% - 100px) !important'
    },
    noBanner: {
        height: '100% !important'
    },
    loadingMessage: {
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '0.5em'
    }
}));

interface Props {
    key?: any;
    title: string;
    children?: any;
    hideBanner?: Boolean;
}

const PdfPageTemplate = ({ title, children, hideBanner = false }: Props) => {
    const banner = {
        thinBanner: true,
        header: title
    };

    const classes = useStyles();

    return (
        <>
            {!hideBanner && (
                <section>
                    <PageBanner {...banner} />
                </section>
            )}
            <section>
                <BlobProvider document={children}>
                    {({ blob, url, loading, error }) => {
                        if (loading) {
                            return (
                                <>
                                    <LinearProgress variant="query" />
                                    <Grid container item xs={12} className={classes.loadingMessage}>
                                        <Typography>
                                            <Trans i18nKey="loadingMessage">
                                                Loading PDF. This may take up to a few minutes.
                                            </Trans>
                                        </Typography>
                                    </Grid>
                                </>
                            );
                        }
                        if (error) {
                            return (
                                <Grid container item xs={12} className={classes.loadingMessage}>
                                    <Typography>
                                        <Trans i18nKey="errorPDF">
                                            An error occurred while loading PDF.
                                        </Trans>
                                    </Typography>
                                </Grid>
                            );
                        }
                        return (
                            <iframe
                                title={title}
                                src={url || undefined}
                                className={clsx([
                                    classes.pdfViewer,
                                    hideBanner ? classes.noBanner : classes.banner
                                ])}
                            />
                        );
                    }}
                </BlobProvider>
            </section>
        </>
    );
};

export default PdfPageTemplate;
