import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
    TableRow,
    TableCell,
    Typography,
    Grid,
    Input,
    Button,
    InputAdornment,
    IconButton
} from '@material-ui/core';
import moment from 'moment';
import clsx from 'clsx';
import {
    createLeadTimeDisplay,
    calculateLeadTime,
    setDateTimeToMidnight
} from '../../../../utility/helpers/date-helpers';
import SingleDaypickerModal from '../../../reusable/molecules/SingleDaypickerModal';
import { MakeItBulkLineItem } from '../../../../store/reducers/makeit-bulk-upload';
import Modal from '../../../reusable/molecules/Modal';
import { numericRegex } from '../../../../utility/helpers/formatters';
import {
    evaluateChildLinePalletQuantityValid,
    ChildLinePalletQuantityState
} from '../../../../utility/helpers/make-it-bulk-helpers';
import { enUS } from '../../../../utility/translations/locales';
import { useMakeItBulkUploadReviewOrderSection } from '../context/MakeItBulkUploadReviewOrderSectionContext';
import { getMakeItLeadTime } from '../../../../utility/helpers/production-order-helpers';
import { getEachesConversion } from '../../../../utility/helpers/shipment-helpers';

interface Props {
    item: MakeItBulkLineItem;
    childRowIndex: number;
    onRemoveItem: (key: number) => void;
    onQtyUpdate: () => void;
    onBlurSetQty: () => void;
    onSubRowSelectedDateChange: () => void;
    styles: any;
}

const MakeItBulkUploadReviewChildRow = ({
    item,
    childRowIndex,
    styles,
    onRemoveItem,
    onQtyUpdate,
    onBlurSetQty,
    onSubRowSelectedDateChange
}: Props) => {
    const classes = styles();
    useTranslation();
    const [purchaseQty, setPurchaseQty] = useState(0);
    const [selectedDate, setSelectedDate] = useState<string>();
    const [daypickerDate, setDaypickerDate] = useState<string>();
    const [firstDay, setFirstDay] = useState<moment.Moment | null>(null);
    const [calendarOpen, setCalendarOpen] = useState(false);
    const [reqDateBeforeFirstAvail, setReqDateBeforeFirstAvail] = useState<boolean>(false);
    const [palletQuantityState, setPalletQuantityState] =
        useState<ChildLinePalletQuantityState>(undefined);
    const [disabled, setDisabled] = useState<boolean>(false);
    const { markedRemoved } = useMakeItBulkUploadReviewOrderSection();

    const leadTime = item.leadTimeWeeks ?? getMakeItLeadTime(item);
    const leadTimeDisplay = createLeadTimeDisplay(leadTime);
    const today = moment().format('MM/DD/YYYY');
    const calendarIcon = (
        <img
            className={classes.calendarIcon}
            src={process.env.PUBLIC_URL + '/assets/Calendar_Icon.svg'}
            alt="Calendar Icon"
        />
    );

    const disabledCalendarIcon = (
        <img
            src={process.env.PUBLIC_URL + '/assets/Disabled_Calendar_Icon.svg'}
            alt="Calendar Icon"
        />
    );

    const onOpenCalendar = () => {
        if (disabled) {
            //prevents calendar from opening when it should be disabled
            return;
        }
        setCalendarOpen(true);
    };

    const onCloseCalendar = () => {
        setCalendarOpen(false);
    };

    const handleOkClose = () => {
        setCalendarOpen(false);
        setSelectedDate(daypickerDate);

        if (daypickerDate !== item.requestedDate) {
            item.requestedDate = daypickerDate;
            onSubRowSelectedDateChange();
        }
    };

    const handleDateSelect = (newDate: moment.Moment | null) => {
        const date = moment(newDate).format('MM/DD/YYYY');
        setDaypickerDate(date);
    };

    const setQuantityOnBlur = () => {
        onBlurSetQty();
    };

    const updatePurchaseCount = (count: string) => {
        const quantity = parseInt(count);
        if (numericRegex(count)) {
            if (!isNaN(quantity)) {
                setPurchaseQty(quantity);
                item.palletQuantity = quantity;
            } else {
                setPurchaseQty(0);
                item.palletQuantity = 0;
            }
            onQtyUpdate();
        }
    };

    useEffect(() => {
        const today = moment();
        const leadTimeDay = calculateLeadTime(today, leadTime, 'weeks');
        const firstAvailableDay = setDateTimeToMidnight(leadTimeDay);
        setFirstDay(firstAvailableDay);
        // it will only run at the beginning.
    }, []);

    useEffect(() => {
        if (item.requestedDate) {
            setSelectedDate(moment(item.requestedDate).format('MM/DD/YYYY'));
        } else {
            setSelectedDate(leadTimeDisplay);
            item.requestedDate = leadTimeDisplay;
        }
    }, [item, leadTimeDisplay]);

    useEffect(() => {
        if (item.requestedDate) {
            setSelectedDate(moment(item.requestedDate).format('MM/DD/YYYY'));
        }

        let purchaseQuantity = 0;
        if (item.palletQuantity) {
            purchaseQuantity = item.palletQuantity;
            setPurchaseQty(purchaseQuantity);
        }
        setPalletQuantityState(evaluateChildLinePalletQuantityValid(purchaseQuantity));
    }, [item]);

    useEffect(() => {
        if (selectedDate && firstDay) {
            if (setDateTimeToMidnight(selectedDate).isBefore(firstDay)) {
                setReqDateBeforeFirstAvail(true);
            } else {
                setReqDateBeforeFirstAvail(false);
            }
        }
    }, [selectedDate, firstDay]);

    useEffect(() => {
        setDisabled(markedRemoved);
    }, [markedRemoved]);

    useTranslation();

    function hasPalletQuantityError(): boolean {
        switch (palletQuantityState) {
            case 'zeroError':
                return true;
            default:
                return false;
        }
    }

    return (
        <>
            <TableRow className={classes.tableRowContainer} data-testid="child-row-table">
                <TableCell className={classes.alignCenter}>
                    <Typography className={classes.bold}>{item.originalCsvLineNumber}</Typography>
                </TableCell>
                <TableCell />
                <TableCell className={classes.hiddenCell} />
                <TableCell className={classes.hiddenCell} />
                <TableCell className={classes.hiddenCell} />
                <TableCell
                    className={clsx(classes.alignCenter, {
                        [classes.requestedDateError]: reqDateBeforeFirstAvail
                    })}
                    data-testid="calendar-input"
                >
                    <Grid container>
                        <Grid container item alignItems="flex-start">
                            <Grid item xs={2}>
                                {reqDateBeforeFirstAvail && (
                                    <img
                                        className={classes.reqDateBeforeFirstAvailErr}
                                        src={process.env.PUBLIC_URL + '/assets/Error_Icon.svg'}
                                        alt="Error Icon"
                                    />
                                )}
                            </Grid>
                            <Grid item xs={10}>
                                <Input
                                    id={item.productSku}
                                    type="button"
                                    fullWidth
                                    disableUnderline={true}
                                    onClick={onOpenCalendar}
                                    value={selectedDate}
                                    disabled={disabled}
                                    className={classes.calendarInput}
                                    data-testid="split-calendar-input"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="calendar modal trigger"
                                                data-testid="calendar-btn"
                                                disabled={disabled}
                                                onClick={onOpenCalendar}
                                            >
                                                {disabled ? disabledCalendarIcon : calendarIcon}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </Grid>
                        </Grid>
                        {reqDateBeforeFirstAvail && (
                            <Grid container>
                                <Typography
                                    className="helper-text"
                                    data-testid="early-requested-date-helper"
                                >
                                    <Trans i18nKey="chooseLaterDate">
                                        Please choose a later date
                                    </Trans>
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </TableCell>
                <TableCell
                    className={clsx(
                        {
                            [classes.blueBackground]: hasPalletQuantityError() === false
                        },
                        {
                            [classes.feeError]: hasPalletQuantityError()
                        }
                    )}
                >
                    <Grid container alignContent="center">
                        {hasPalletQuantityError() && (
                            <img
                                data-testid="child-line-error-icon"
                                src={process.env.PUBLIC_URL + '/assets/Error_Icon.svg'}
                                alt="Error Icon"
                            />
                        )}
                        <Grid item className={classes.inputContainer}>
                            <Input
                                type="text"
                                fullWidth
                                className={classes.textField}
                                disableUnderline={true}
                                value={purchaseQty}
                                disabled={disabled}
                                data-testid="split-quantity-input"
                                onBlur={setQuantityOnBlur}
                                onChange={(e) => updatePurchaseCount(e.target.value)}
                            />
                            <Typography>PL</Typography>
                        </Grid>
                    </Grid>
                    <Typography className="helper-text" data-testid="eaches-quantity">
                        {getEachesConversion(purchaseQty, item.quantityPerPallet!)}
                    </Typography>
                    {palletQuantityState === 'zeroError' && (
                        <Grid container>
                            <Typography
                                className="validation-error-text"
                                data-testid={`quantity-zero-error-${item.productSku}-${childRowIndex}`}
                            >
                                <Trans i18nKey="palletQuantityZeroError">
                                    {enUS.palletQuantityZeroError}
                                </Trans>
                            </Typography>
                        </Grid>
                    )}
                </TableCell>
                <TableCell
                    className={clsx(
                        classes.hiddenCell,
                        {
                            [classes.blueBackground]: hasPalletQuantityError() === false
                        },
                        {
                            [classes.splitFeeError]: hasPalletQuantityError()
                        }
                    )}
                />
                <TableCell className={clsx(classes.alignCenter, classes.blueBackground)}>
                    <Button
                        type="button"
                        className={classes.trash}
                        data-testid={`split-delete-icon`}
                        disabled={disabled}
                        onClick={() => onRemoveItem(childRowIndex)}
                    >
                        {disabled && (
                            <img
                                src={process.env.PUBLIC_URL + '/assets/DisabledTrashIcon.svg'}
                                alt="Disabled Trash Icon"
                            />
                        )}
                        {!disabled && (
                            <img
                                src={process.env.PUBLIC_URL + '/assets/Trash.svg'}
                                alt="Trash Icon"
                            />
                        )}
                    </Button>
                </TableCell>
            </TableRow>
            <Modal
                open={calendarOpen}
                title={<Trans i18nKey="todayWithDate">Today: {{ today }}</Trans>}
                close={onCloseCalendar}
                closeIcon={true}
                maxWidth="xl"
            >
                <div className={classes.calendarDiv}>
                    <Grid item />
                    <SingleDaypickerModal
                        onCancel={onCloseCalendar}
                        onOk={handleOkClose}
                        selectDate={(newDate) => handleDateSelect(newDate)}
                        leadTime={0}
                        firstDay={firstDay}
                        calculateOutsideRange={true}
                    />
                </div>
            </Modal>
        </>
    );
};

export default MakeItBulkUploadReviewChildRow;
