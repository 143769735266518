import {
    PRODUCT_END_DETAILS_LOADING,
    PRODUCT_END_DETAILS_LOADED,
    PRODUCT_END_DETAILS_ERROR,
    PRODUCT_END_LOADED_BANNER,
    PRODUCT_END_LOADED_CONTENT
} from '../actions/action-types';
import { Product, ProductVolume } from './product-portfolio';

export interface EndDetailsState {
    loading: boolean;
    error?: string;
    banner?: Section;
    endContent?: End[];
}

export interface Style {
    styleCode: string;
    label: string;
    sequence: number;
}

export interface SizeRange {
    sizeRangeCode: string;
    label: string;
}

export interface End {
    endCode: string;
    regionCode?: string;
    label: string;
    diameter: number;
    pricePerThousand?: number;
    sequence: number;
    features: EndFeature[];
    thirdParty?: boolean;
    compatibleProducts: Product[];
    //This is a dictionary/hashmap of compatible sizes (value) grouped by stylecode (key)
    compatibleSizesByStyle?: Map<string, ProductVolume[]>;
    leadTimeWeeks?: number;
}

export interface EndFeature {
    featureCode: string;
    type: string;
    category: string;
    label: string;
    sequence: number;
    recommended: boolean;
}

export interface Section {
    header: string;
    description: string;
    summary?: string;
    paragraph?: string;
    links?: Link[];
    image?: Image;
    icon?: Icon;
    [x: string]: any;
}

export interface Link {
    linkText: string;
    linkUrl?: string;
    destination?: string;
    icon?: Icon;
    action?: string;
}

export interface Image {
    imageUrl: string;
    alternateText: string;
}

export interface Icon {
    defaultUrl: string;
    hoverUrl: string;
}

const initialState: EndDetailsState = {
    loading: false,
    banner: { header: '', description: '' },
    endContent: undefined
};

const endDetails = (state = initialState, action: any) => {
    switch (action.type) {
        case PRODUCT_END_DETAILS_LOADING:
            return {
                ...state,
                loading: true
            };
        case PRODUCT_END_DETAILS_LOADED:
            return {
                ...state,
                loading: false,
                error: undefined
            };
        case PRODUCT_END_DETAILS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        case PRODUCT_END_LOADED_BANNER:
            return {
                ...state,
                banner: action.banner
            };
        case PRODUCT_END_LOADED_CONTENT:
            return {
                ...state,
                endContent: action.endContent
            };
        default:
            return state;
    }
};

export default endDetails;
