import { ErrorStatus } from '../../utility/helpers/axios-helpers';
import OrdersService, {
    MakeItOrderATMCreateDraftRequest,
    MakeItOrderATMUpdateDraftRequest,
    OrderType,
    Order_Status,
    ProductStatus,
    QuantityType,
    RowsOrColumns
} from '../../utility/services/orders-service';
import { BulkUploadTemplateRequest, TemplateMapping } from '../reducers/bulk-upload';
import { MakeItBulkLineItem, MakeItLineError } from '../reducers/makeit-bulk-upload';
import {
    AllocationRequest,
    AvailableBalance,
    ForecastStatus,
    MakeItBulkATMLineItem,
    MakeItBulkATMOrder,
    MakeItBulkATMOrderByWeekAndSku,
    MakeItBulkATMOrderRequest,
    SnoSkuAllocationsByWeek,
    SnoSkuPalletQuantity,
    WeekQuantity
} from '../reducers/makeit-bulk-atm';
import {
    MAKEIT_BULK_UPLOAD_ATM_LOADING,
    MAKEIT_BULK_UPLOAD_ERROR,
    MAKEIT_BULK_UPLOAD_RESET_STATE,
    MAKEIT_BULK_ATM_SUBMIT_REVIEW,
    MAKEIT_BULK_UPLOAD_ATM_LOADED,
    MAKEIT_BULK_UPLOAD_ATM_UPDATE_PALLET_QUANTITY,
    MAKEIT_BULK_ATM_SNOSKU_ORDER,
    MAKEIT_BULK_UPLOAD_ATM_LOAD_ALLOCATIONS,
    MAKEIT_BULK_ATM_DELETE_PRODUCT,
    MAKEIT_BULK_ATM_ADD_PRODUCT,
    MAKEIT_BULK_UPLOAD_ATM_LOAD_ALLOCATIONS_ERROR,
    MAKEIT_BULK_ATM_SUBMIT_ORDERS_LOADING,
    MAKEIT_BULK_ATM_SUBMIT_ORDERS_ERROR,
    MAKEIT_BULK_ATM_SUBMIT_ORDERS_SUCCESS,
    MAKEIT_BULK_ATM_SAVE_DRAFT_LOADING,
    MAKEIT_BULK_ATM_SAVE_DRAFT_ERROR,
    MAKEIT_BULK_ATM_SAVE_DRAFT_SUCCESS,
    MAKEIT_BULK_ATM_UPDATED_PRODUCT_SKU,
    MAKEIT_BULK_ATM_EXISTING_ORDERS,
    MAKEIT_BULK_ATM_EDIT_ORDER_LOADED,
    MAKEIT_BULK_ATM_EDIT_ORDER_ERROR,
    MAKEIT_BULK_UPLOAD_ATM_TEMPLATE_ERROR,
    MAKEIT_BULK_UPLOAD_ATM_TEMPLATE_CLEAR,
    MAKEIT_BULK_UPLOAD_ATM_RESET_ALLOCATIONS_STATE,
    MAKEIT_BULK_UPLOAD_ATM_LOAD_ALLOCATIONS_LOADING,
    MAKEIT_DASHBOARD_UPDATE_REQUESTED_DATE,
    MAKEIT_BULK_ATM_DRAFT_ORDER_ERROR,
    MAKEIT_BULK_ATM_COPY_PRODUCT,
    MAKEIT_BULK_ATM_ADD_PRODUCT_ERROR,
    MAKEIT_BULK_ATM_UPDATE_CUSTOMER_PO,
    MAKEIT_BULK_ATM_ADD_WEEK,
    MAKEIT_BULK_ATM_LOAD_ORDERS
} from './action-types';
import moment, { Moment } from 'moment';
import { DateProperties } from '../../utility/helpers/make-it-bulk-helpers';
import AllocationService from '../../utility/services/allocation-service';
import {
    getCustomerProductInformation,
    getMoqFeeFloor
} from '../../utility/helpers/production-order-helpers';
import { ProductionOrder } from '../reducers/makeit-dashboard';
import { atmBulkOrderBuilder } from '../../components/pages/MakeItATMSummary/utils/makeit-atm-summary-utils';
import { isProductCanType, isProductEndType } from '../../utility/helpers/order-helpers';
import { OrderProductTableRow } from '../reducers/orders-dashboard';
import { getMondayOfWeek } from '../../components/pages/MakeIt/utilities/make-it-utils';
import { OrderPlanningProduct } from '../reducers/product-planning';
import { Account } from '../reducers/customer-context';
import { ProductionOrderLinkedDeliveryOrder } from './../reducers/production-order-details';
import { getFirstAvailableDate, formattedMTDate } from '../../utility/helpers/date-helpers';
import { bulkUploadUpdateOrderQuantities } from './makeit-bulk-upload';
import { getAvailableQuantity } from './makeit-dashboard';
import {
    Activity,
    filterShipToIdsByPermission,
    SecurityLevel
} from '../../utility/auth/useSecurity';

export const loadBulkUploadMakeItATM = (userName: string) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: MAKEIT_BULK_UPLOAD_ATM_LOADING });
        OrdersService.getBulkUpload(state, userName, OrderType.ProductionATM)
            .then((response) => {
                let weekQuantities: WeekQuantity[] = [];
                if (response.data.rowsOrColumns === RowsOrColumns.Columns) {
                    response.data.mappings.forEach((field) => {
                        if (field.fieldName.includes('WeekNumber')) {
                            weekQuantities.push({ quantity: field.columnLetter });
                        }
                    });
                }
                dispatch({
                    type: MAKEIT_BULK_UPLOAD_ATM_LOADED,
                    mappings: response.data.mappings,
                    quantityType: response.data.quantityType,
                    rowsOrColumns: response.data.rowsOrColumns,
                    firstRowOfData: response.data.firstRowOfData,
                    weekQuantities: weekQuantities
                });
            })
            .catch((error) => {
                if (error.response?.status === ErrorStatus.NotFound) {
                    dispatch({ type: MAKEIT_BULK_UPLOAD_ATM_LOADED, mappings: [] });
                } else {
                    dispatch({
                        type: MAKEIT_BULK_UPLOAD_ERROR,
                        error
                    });
                }
            });
    };
};

export const createMakeItBulkUploadATMTemplate = (
    userName: string,
    mappings: TemplateMapping[],
    quantityType: QuantityType,
    rowsOrColumns?: RowsOrColumns,
    weekQuantities?: WeekQuantity[],
    firstRowOfData?: number
) => {
    return (dispatch, getState) => {
        const { auth } = getState();
        dispatch({ type: MAKEIT_BULK_UPLOAD_ATM_LOADING });
        const requestObj: BulkUploadTemplateRequest = {
            userName: userName,
            orderType: OrderType.ProductionATM,
            mappings: mappings,
            quantityType: quantityType,
            rowsOrColumns: rowsOrColumns,
            firstRowOfData: firstRowOfData
        };

        OrdersService.createBulkUploadDeliveryTemplate(auth.accessToken, requestObj)
            .then((response) => {
                dispatch({
                    type: MAKEIT_BULK_UPLOAD_ATM_LOADED,
                    mappings: response.data.mappings,
                    quantityType: response.data.quantityType,
                    rowsOrColumns: response.data.rowsOrColumns,
                    weekQuantities: weekQuantities,
                    firstRowOfData: response.data.firstRowOfData
                });
            })
            .catch((error) => {
                dispatch({
                    type: MAKEIT_BULK_UPLOAD_ATM_TEMPLATE_ERROR,
                    error
                });
            });
    };
};

export const updateMakeItBulkUploadATMTemplate = (
    userName: string,
    mappings: TemplateMapping[],
    quantityType: QuantityType,
    rowsOrColumns?: RowsOrColumns,
    weekQuantities?: WeekQuantity[],
    firstRowOfData?: number
) => {
    return (dispatch, getState) => {
        const { auth } = getState();
        dispatch({ type: MAKEIT_BULK_UPLOAD_ATM_LOADING });
        const requestObj: BulkUploadTemplateRequest = {
            userName: userName,
            orderType: OrderType.ProductionATM,
            mappings: mappings,
            quantityType: quantityType,
            rowsOrColumns: rowsOrColumns,
            firstRowOfData: firstRowOfData
        };

        requestObj.quantityType = quantityType;

        OrdersService.updateBulkUploadDeliveryTemplate(auth.accessToken, requestObj)
            .then((response) => {
                dispatch({
                    type: MAKEIT_BULK_UPLOAD_ATM_LOADED,
                    mappings: response.data.mappings,
                    quantityType: response.data.quantityType,
                    rowsOrColumns: response.data.rowsOrColumns,
                    weekQuantities: weekQuantities,
                    firstRowOfData: response.data.firstRowOfData
                });
            })
            .catch((error) => {
                dispatch({
                    type: MAKEIT_BULK_UPLOAD_ATM_TEMPLATE_ERROR,
                    error
                });
            });
    };
};

/**
 * Removes the product from a make it bulk atm order.
 * @param userSuppliedProductId
 * @param weekStart
 * @param shipToId
 */
export function deleteProductFromAtmOrder(productSku: string, weekStart: string, shipToId: number) {
    return (dispatch, getState) => {
        const state = getState();
        const atmOrders = state.bulkUploadMakeItATMState.atmOrders as MakeItBulkATMOrder[];
        const existingOrders = state.bulkUploadMakeItATMState
            .existingOrders as MakeItBulkATMOrder[];

        //If existingOrders, isolate existing order
        const existingOrderToUpdate = existingOrders.length
            ? existingOrders.find(
                  (existingOrder) =>
                      moment(existingOrder.atmWeekStart)
                          .startOf('day')
                          .isSame(moment(weekStart).startOf('day')) &&
                      existingOrder.shipToId === shipToId
              )
            : undefined;

        //Must remove from existing order for tracking
        if (existingOrderToUpdate) {
            let newLines = existingOrderToUpdate.lines.filter(
                (existingLine) => existingLine.productSku !== productSku
            );
            existingOrderToUpdate.lines = newLines;
        }

        if (atmOrders) {
            const atmOrdersToUpdate = atmOrders.map((order) => {
                if (
                    !(
                        moment(order.weekStart)
                            .startOf('day')
                            .isSame(moment(weekStart).startOf('day')) && order.shipToId === shipToId
                    )
                ) {
                    return order;
                }

                // remove matching products added by user (with no originalCsvLineNumber)
                const updatedLines = order.lines.map((line) => {
                    if (line.productSku !== productSku) {
                        return line;
                    } else if (line.productSku === productSku) {
                        return {
                            ...line,
                            deleted: true
                        };
                    }
                    return null;
                });

                return { ...order, lines: updatedLines };
            });
            dispatch({
                type: MAKEIT_BULK_ATM_DELETE_PRODUCT,
                atmOrders: atmOrdersToUpdate
            });
        }
    };
}

export function addProductToAtmOrder(
    activeDate: DateProperties,
    shipToId: number,
    item: MakeItBulkLineItem
) {
    return (dispatch, getState) => {
        const state = getState();
        const products = state.bulkUploadMakeItState.products as MakeItBulkLineItem[];
        const atmOrders = state.bulkUploadMakeItATMState.atmOrders as MakeItBulkATMOrder[];
        const existingOrders = state.bulkUploadMakeItATMState
            .existingOrders as MakeItBulkATMOrder[];
        const allocationsAndProducts = state.bulkUploadMakeItATMState.allocationsAndProducts;
        const requestedDate = moment(activeDate.fullDate).format('YYYY-MM-DDTHH:mm:ss');
        const availableBalances = state.bulkUploadMakeItATMState.availableBalances;

        if (atmOrders) {
            const atmOrdersToUpdate = atmOrders.map((order) => {
                if (
                    !(
                        moment(order.weekStart)
                            .startOf('day')
                            .isSame(moment(activeDate.fullDate).startOf('day')) &&
                        order.shipToId === shipToId
                    )
                ) {
                    return order;
                }

                //If existingOrders, isolate existing order
                const existingOrderToUpdate = existingOrders.length
                    ? existingOrders.find(
                          (existingOrder) =>
                              moment(existingOrder.atmWeekStart)
                                  .startOf('day')
                                  .isSame(moment(activeDate.fullDate).startOf('day')) &&
                              existingOrder.shipToId === shipToId
                      )
                    : undefined;

                let updatedLines = order.lines.filter((line) => {
                    if (line.displayId !== item.displayId) {
                        return line;
                    }
                    return null;
                });
                const addItem = products.find((product) => product.productId === item.productId);
                const newLine = {
                    ...(addItem as MakeItBulkATMLineItem),
                    availableQuantity:
                        addItem &&
                        getAvailableQuantity(
                            availableBalances || [],
                            order.shipToId,
                            moment.utc(requestedDate).format('MM/DD/YYYY'),
                            addItem.productSku
                        ),
                    isCustomerProductIdDistinct: true,
                    originalPalletQuantity: 0,
                    requestedDate: requestedDate
                };
                updatedLines.push(newLine);
                if (existingOrderToUpdate) {
                    //Also push to existing orders
                    existingOrderToUpdate?.lines.push({
                        ...(addItem as MakeItBulkATMLineItem),
                        isCustomerProductIdDistinct: true,
                        originalPalletQuantity: 0,
                        requestedDate: requestedDate
                    });
                } else {
                    //Fresh order, must add to existing orders for tracking
                    existingOrders.push({
                        ...order,
                        atmWeekStart: order.weekStart,
                        lines: [
                            {
                                ...(addItem as MakeItBulkATMLineItem),
                                isCustomerProductIdDistinct: true,
                                originalPalletQuantity: 0,
                                requestedDate: requestedDate
                            }
                        ]
                    });
                }
                return { ...order, lines: updatedLines };
            });
            dispatch({
                type: MAKEIT_BULK_ATM_ADD_PRODUCT,
                atmOrders: atmOrdersToUpdate,
                allocationsAndProducts: allocationsAndProducts
            });
        }
    };
}

export const resetMakeItBulkATMState = () => {
    return (dispatch) => {
        dispatch({ type: MAKEIT_DASHBOARD_UPDATE_REQUESTED_DATE, requestedDate: '' });

        dispatch({
            type: MAKEIT_BULK_UPLOAD_RESET_STATE
        });
    };
};

export const updatePalletQuantityATM = (
    activeDate: DateProperties,
    shipToId: number,
    quantity: number,
    updatedLine: MakeItBulkATMLineItem
) => {
    return (dispatch, getState) => {
        const state = getState();
        const atmOrders = state.bulkUploadMakeItATMState.atmOrders as MakeItBulkATMOrder[];
        const existingOrders = state.bulkUploadMakeItATMState
            .existingOrders as MakeItBulkATMOrder[];
        if (atmOrders) {
            const atmOrdersToUpdate = atmOrders.map((order) => {
                if (
                    !(
                        moment(order.weekStart)
                            .startOf('day')
                            .isSame(moment(activeDate.fullDate).startOf('day')) &&
                        order.shipToId === shipToId
                    )
                ) {
                    return order;
                }

                //If existingOrders, isolate existing order
                const existingOrderToUpdate = existingOrders.length
                    ? existingOrders.find(
                          (existingOrder) =>
                              moment(existingOrder.atmWeekStart)
                                  .startOf('day')
                                  .isSame(moment(activeDate.fullDate).startOf('day')) &&
                              existingOrder.shipToId === shipToId
                      )
                    : undefined;

                const updatedLines = order.lines.map((line) => {
                    if (
                        line.originalCsvLineNumber &&
                        line.originalCsvLineNumber !== updatedLine.originalCsvLineNumber
                    ) {
                        return line;
                    } else if (
                        !line.originalCsvLineNumber &&
                        (line.displayId !== updatedLine.displayId ||
                            line.productSku !== updatedLine.productSku)
                    ) {
                        return line;
                    } else {
                        if (existingOrderToUpdate) {
                            //If the line being updated matches, we want to preserve any updated quantities
                            const lineToUpdate = existingOrderToUpdate.lines.find(
                                (existingLine) => existingLine.productSku === line.productSku
                            );
                            if (lineToUpdate) {
                                lineToUpdate.palletQuantity = quantity >= 0 ? quantity : undefined;
                            }
                        }
                        line.palletsRounded = false;
                        return {
                            ...line,
                            palletQuantity: quantity >= 0 ? quantity : undefined,
                            minimumMet: updatedLine.minimumMet,
                            withinRange: updatedLine.withinRange
                        };
                    }
                });
                return { ...order, lines: updatedLines };
            });

            dispatch({
                type: MAKEIT_BULK_UPLOAD_ATM_UPDATE_PALLET_QUANTITY,
                atmOrders: atmOrdersToUpdate
            });
        }
    };
};

export const reviewMakeItBulkUploadATM = (
    userName: string,
    file: File | File[],
    weeks?: moment.Moment[]
) => {
    return (dispatch, getState) => {
        const state = getState();
        const accessToken = state.auth.accessToken;
        const products = state.bulkUploadMakeItState.products as MakeItBulkLineItem[];

        dispatch({ type: MAKEIT_BULK_UPLOAD_ATM_LOADING });

        OrdersService.reviewMakeItBulkUpload(state, userName, true, file, weeks)
            .then(async (response) => {
                let orders = [] as MakeItBulkATMOrder[];
                let errors = [] as MakeItLineError[];
                if (response.data.orders) {
                    orders = response.data.orders;
                }
                if (response.data.lineErrors) {
                    errors = response.data.lineErrors;
                }

                const availableBalances: AvailableBalance[] = [];
                const getAvailableBalancesPromises: Promise<any>[] = orders.map((order) => {
                    availableBalances.push({
                        shipToId: order.shipToId,
                        requestedDate: moment(order.weekStart!).format('MM/DD/YYYY'),
                        balances: []
                    });
                    return OrdersService.getMakeItProducts(accessToken, {
                        ShipToIds: [order.shipToId],
                        RequestedDate: [order.weekStart!],
                        WithAvailablePallets: true
                    });
                });

                const responses = await Promise.all(getAvailableBalancesPromises).catch((error) => {
                    return dispatch({
                        type: MAKEIT_BULK_UPLOAD_ERROR,
                        error
                    });
                });

                responses.forEach((response, index) => {
                    const productsWithAvailablePallets = response.data.products;
                    availableBalances[index].balances = productsWithAvailablePallets.map(
                        (product) => {
                            return {
                                productSku: product.productSku,
                                quantity: product.availablePallets
                            };
                        }
                    );
                });

                bulkUploadResponseHelper(orders, products, availableBalances);
                dispatch({
                    type: MAKEIT_BULK_ATM_SUBMIT_REVIEW,
                    atmOrders: orders,
                    lineErrors: errors,
                    weeks,
                    availableBalances
                });
            })
            .then(() => {
                const atmOrders = getState().bulkUploadMakeItATMState.atmOrders;
                if (atmOrders?.length) {
                    const sortedCsvAtmOrders: MakeItBulkATMOrder[] =
                        getState().bulkUploadMakeItATMState.atmOrders.sort((a, b) =>
                            moment(a.weekStart).diff(moment(b.weekStart))
                        );
                    const startWeek = moment(sortedCsvAtmOrders[0].weekStart).format('M/D/YYYY');
                    const endWeek = moment(
                        sortedCsvAtmOrders[sortedCsvAtmOrders.length - 1].weekStart
                    ).format('M/D/YYYY');
                    const shipToIds = sortedCsvAtmOrders.reduce((prev, curr) => {
                        if (!prev.includes(curr.shipToId)) {
                            prev.push(curr.shipToId);
                        }
                        return prev;
                    }, [] as Array<number>);

                    return OrdersService.getExistingOrders(
                        state,
                        startWeek,
                        endWeek,
                        shipToIds,
                        ForecastStatus.ATM
                    );
                }
            })
            .then((resp) => {
                if (resp) {
                    const existingOrders = JSON.parse(JSON.stringify(resp));
                    existingOrders.forEach((order) => {
                        order.weekStart = order.atmWeekStart;
                        order.lines = order.lines.map((line) => {
                            const matchingProduct = products.find(
                                (prod) => prod.productSku === line.productSku!
                            );
                            return {
                                ...line,
                                weekStart: order.atmWeekStart,
                                snoSku: matchingProduct?.snoSku
                            };
                        });
                    });

                    dispatch({
                        type: MAKEIT_BULK_ATM_EXISTING_ORDERS,
                        existingOrders: existingOrders.filter(
                            (order) =>
                                order.status !== Order_Status.Draft &&
                                order.status !== Order_Status.Cancelled
                        ),
                        existingDrafts: existingOrders.filter(
                            (order) => order.status === Order_Status.Draft
                        )
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: MAKEIT_BULK_UPLOAD_ERROR,
                    error
                });
            });
    };
};

export const saveOrdersBySnoSku = (ordersBySnoSku: MakeItBulkATMOrderByWeekAndSku[]) => {
    return (dispatch) => {
        dispatch({
            type: MAKEIT_BULK_ATM_SNOSKU_ORDER,
            ordersBySnoSku: ordersBySnoSku
        });
    };
};

export const updateExpand = (shipToId: number, snoSku: string, weekStart: string) => {
    return (dispatch, getState) => {
        const { ordersBySnoSku } = getState().bulkUploadMakeItATMState;
        const ordersBySnoSkuToUpdate = [...ordersBySnoSku] as MakeItBulkATMOrderByWeekAndSku[];

        ordersBySnoSkuToUpdate.forEach((order) => {
            order.linesBySnoSku.forEach((line) => {
                if (
                    order.shipToId === shipToId &&
                    line.snoSku === snoSku &&
                    moment(line.weekStart).startOf('day').isSame(moment(weekStart).startOf('day'))
                ) {
                    line.isActive = !line.isActive;
                }
            });
        });

        dispatch({
            type: MAKEIT_BULK_ATM_SNOSKU_ORDER,
            ordersBySnoSku: ordersBySnoSkuToUpdate
        });
    };
};

export const updateExpandAll = (
    collapsed: boolean,
    selectedDate: string,
    selectedShipTo?: string
) => {
    return (dispatch, getState) => {
        const { ordersBySnoSku } = getState().bulkUploadMakeItATMState;
        const ordersBySnoSkuToUpdate = [...ordersBySnoSku] as MakeItBulkATMOrderByWeekAndSku[];

        ordersBySnoSkuToUpdate.forEach((order) => {
            if (
                moment(order.weekStart).format('MM/DD/YYYY') ===
                    moment(selectedDate).format('MM/DD/YYYY') &&
                // if there is not a selectedShipTo, it's confirmation
                // if there is a selectedShipTo, it's review and needs to match the id
                (!selectedShipTo ||
                    (selectedShipTo && selectedShipTo === order.shipToId.toString()))
            ) {
                order.linesBySnoSku.forEach((line) => {
                    line.isActive = collapsed ? false : true;
                });
            }
        });

        dispatch({
            type: MAKEIT_BULK_ATM_SNOSKU_ORDER,
            ordersBySnoSku: ordersBySnoSkuToUpdate
        });
    };
};

export const getAllocations = (startDate: string, endDate: string) => {
    return (dispatch, getState) => {
        const state = getState();
        const { regionCode } = state.regionCulture;
        const orders = state.bulkUploadMakeItATMState.atmOrders as MakeItBulkATMOrder[];
        const products = state.bulkUploadMakeItState.products as MakeItBulkLineItem[];
        let requestShipToIds: number[] = [];
        orders.forEach((order) => {
            if (!requestShipToIds.find((shipToId) => shipToId === order.shipToId)) {
                requestShipToIds.push(order.shipToId);
            }
        });

        //Create request object
        const allocationRequest = {
            shipToIds: requestShipToIds,
            startDate: [startDate],
            endDate: [endDate]
        } as AllocationRequest;

        AllocationService.getAllocations(state, regionCode, allocationRequest)
            .then((response) => {
                const allocations = filterAllocations(response.data.allocations, products);
                dispatch({
                    type: MAKEIT_BULK_UPLOAD_ATM_LOAD_ALLOCATIONS,
                    allocations: allocations
                });
            })
            .catch((error) => {
                dispatch({
                    type: MAKEIT_BULK_UPLOAD_ATM_LOAD_ALLOCATIONS_ERROR,
                    error
                });
            });
    };
};

// Allocation for Ends are temporarily not enforced. See CP-4839
export const filterAllocations = (
    allocations: SnoSkuAllocationsByWeek[],
    products: MakeItBulkLineItem[]
) =>
    allocations.filter(
        (allocation) =>
            products.findIndex(
                (product) => product.snoSku === allocation.snoSku && isProductCanType(product.type)
            ) >= 0
    );

export const filterAllocationsSpot = (
    allocations: SnoSkuAllocationsByWeek[],
    products: OrderPlanningProduct[]
) =>
    allocations.filter(
        (allocation) =>
            products.findIndex(
                (product) => product.snoSku === allocation.snoSku && isProductCanType(product.type)
            ) >= 0
    );

export const filterLeadTimeViolations = (atmOrders: MakeItBulkATMOrder[]) => {
    //removes weeks where every product violates lead times
    let filteredAtmOrders: MakeItBulkATMOrder[] = [];
    let deletedOrders: MakeItBulkATMOrder[] = [];
    atmOrders.forEach((order) => {
        let foundLine = order.lines.find(
            (line) =>
                !moment(getFirstAvailableDate(line.leadTimeWeeks ?? 1)).isAfter(
                    moment(order.weekStart)
                )
        );
        if (!!foundLine) {
            filteredAtmOrders.push(order);
        } else {
            deletedOrders.push(order);
        }
    });
    return [filteredAtmOrders, deletedOrders];
};

export const findFirstWeek = (atmOrders: MakeItBulkATMOrder[]) => {
    //sorts the weeks and finds the first one from bulk upload/plan it so it can be selected
    const weeksWithQuantities = atmOrders
        .filter((order) => order.canQuantityPallets > 0 || order.endQuantityPallets > 0)
        .map((orders) => orders.weekStart)
        .sort((a, b) => moment(a).diff(moment(b)));
    return weeksWithQuantities.length > 0 ? weeksWithQuantities : ['NO_VALID_DATE'];
};

export const updateMakeItBulkATMItemProductSku = (
    activeDate: DateProperties,
    shipToId: number,
    csvLineNumber: number,
    updatedLine: MakeItBulkLineItem,
    palletQuantity: number
) => {
    return (dispatch, getState) => {
        const state = getState();
        const atmOrders = JSON.parse(
            JSON.stringify(state.bulkUploadMakeItATMState.atmOrders)
        ) as MakeItBulkATMOrder[];
        const existingOrders = state.bulkUploadMakeItATMState
            .existingOrders as MakeItBulkATMOrder[];
        const products = state.bulkUploadMakeItState.products as MakeItBulkLineItem[];
        if (atmOrders) {
            let atmOrdersToUpdate = atmOrders.map((order) => {
                /*If there are existingOrders,
                 *isolate to compare the newly updated lines to the existing lines.
                 */
                const existingOrderToCompare = existingOrders.length
                    ? existingOrders.find(
                          (order) =>
                              order.shipToId === shipToId &&
                              moment(order.atmWeekStart)
                                  .startOf('day')
                                  .isSame(moment(activeDate.fullDate).startOf('day'))
                      )
                    : undefined;

                if (
                    order.shipToId === shipToId &&
                    moment(order.weekStart)
                        .startOf('day')
                        .isSame(moment(activeDate.fullDate).startOf('day'))
                ) {
                    /*If there is a line that already exists,
                     *Find it so we can set the lineId and pallet info. Otherwise, clear those values.
                     */
                    const existingOrderLine = existingOrderToCompare?.lines.find(
                        (existingLine) => existingLine.productSku === updatedLine.productSku
                    );

                    let linesToUpdate = order.lines
                        //Groom out existing lines, we will add them back in later
                        .filter((line) => line.originalCsvLineNumber)
                        .map((line) => {
                            if (line.originalCsvLineNumber === csvLineNumber) {
                                line.productSku = updatedLine.productSku;
                                line.graphicId = updatedLine.graphicId;
                                line.graphicIdAndVersion = updatedLine.graphicIdAndVersion;
                                line.displayName = updatedLine.displayName;
                                line.snoSku = updatedLine.snoSku!;
                                line.isCustomerProductIdDistinct = true;
                                line.productionOrderLineId = existingOrderLine
                                    ? existingOrderLine.productionOrderLineId
                                    : undefined;
                                line.originalPalletQuantity = existingOrderLine
                                    ? existingOrderLine.originalPalletQuantity
                                    : 0;
                                line.palletQuantity = Math.ceil(palletQuantity);
                                line.palletsRounded = palletQuantity % 1 !== 0;
                                //Reset MOQ for line being updated
                                line = setFeesMOQData(updatedLine, line);
                            }
                            return line;
                        });

                    /*If existingOrderToCompare, loop through lines
                     *and ensure we didn't lose any in linesToUpdate.
                     */
                    if (existingOrderToCompare) {
                        existingOrderToCompare.lines.forEach((existingLine) => {
                            let matchingLineIndex = linesToUpdate.findIndex(
                                (lineToUpdate) =>
                                    lineToUpdate.productSku === existingLine.productSku
                            );
                            //Check if existing line not included the newly mapped lines
                            if (matchingLineIndex === -1) {
                                let matchingProduct = products.find(
                                    (product) => product.productSku === existingLine.productSku
                                ) as MakeItBulkATMLineItem;

                                //Create new line object
                                let existingLineToAdd = {
                                    ...existingLine,
                                    ...matchingProduct,
                                    originalPalletQuantity: existingLine.originalPalletQuantity,
                                    deleted: false
                                };

                                //Validate MOQs before adding line back in
                                let lineWithMOQSet = setFeesMOQData(
                                    matchingProduct,
                                    existingLineToAdd
                                );
                                linesToUpdate.push(lineWithMOQSet);
                            }
                        });
                    }
                    return { ...order, lines: linesToUpdate };
                } else {
                    return { ...order };
                }
            });
            dispatch({
                type: MAKEIT_BULK_ATM_UPDATED_PRODUCT_SKU,
                atmOrders: atmOrdersToUpdate
            });
        }
    };
};

export const getAllocationsWithShipTos = (
    startDate: string,
    endDate: string,
    shipTos: number[],
    filtered: boolean = true,
    ignoreZeros: boolean = false
) => {
    return (dispatch, getState) => {
        dispatch({ type: MAKEIT_BULK_UPLOAD_ATM_LOAD_ALLOCATIONS_LOADING });
        const state = getState();
        const { regionCode } = state.regionCulture;
        const products = state.bulkUploadMakeItState.products as MakeItBulkLineItem[];

        //Create request object
        const allocationRequest = {
            shipToIds: shipTos,
            startDate: [startDate],
            endDate: [endDate]
        } as AllocationRequest;

        AllocationService.getAllocations(state, regionCode, allocationRequest)
            .then((response) => {
                const filteredAllocations = (
                    filtered
                        ? filterAllocations(response.data.allocations, products)
                        : response.data.allocations
                ).filter((alloc) => alloc.ignoreAllocation === false);
                const allocations = ignoreZeros
                    ? filteredAllocations.filter((alloc) => alloc.quantity !== 0)
                    : filteredAllocations;
                dispatch({
                    type: MAKEIT_BULK_UPLOAD_ATM_LOAD_ALLOCATIONS,
                    allocations: allocations
                });
            })
            .catch((error) => {
                dispatch({
                    type: MAKEIT_BULK_UPLOAD_ATM_LOAD_ALLOCATIONS_ERROR,
                    error
                });
            });
    };
};

export const getAllocationsWithShipTosSpot = (
    startDate: string,
    endDate: string,
    shipTos: number[],
    filtered: boolean = true
) => {
    return (dispatch, getState) => {
        dispatch({ type: MAKEIT_BULK_UPLOAD_ATM_LOAD_ALLOCATIONS_LOADING });
        const state = getState();
        const { regionCode } = state.regionCulture;
        const products = state.productPlanning.products as OrderPlanningProduct[];

        //Create request object
        const allocationRequest = {
            shipToIds: shipTos,
            startDate: [startDate],
            endDate: [endDate]
        } as AllocationRequest;

        AllocationService.getAllocations(state, regionCode, allocationRequest)
            .then((response) => {
                const allocations = filtered
                    ? filterAllocationsSpot(response.data.allocations, products)
                    : response.data.allocations;
                dispatch({
                    type: MAKEIT_BULK_UPLOAD_ATM_LOAD_ALLOCATIONS,
                    allocations: allocations
                });
            })
            .catch((error) => {
                dispatch({
                    type: MAKEIT_BULK_UPLOAD_ATM_LOAD_ALLOCATIONS_ERROR,
                    error
                });
            });
    };
};

export const resetAllocationsState = () => {
    return (dispatch) => {
        dispatch({
            type: MAKEIT_BULK_UPLOAD_ATM_RESET_ALLOCATIONS_STATE,
            allocations: null
        });
    };
};

export const bulkUploadResponseHelper = (
    orders: MakeItBulkATMOrder[],
    products: any,
    availableBalances: AvailableBalance[] = []
) => {
    orders.forEach((order) => {
        order.lines.forEach((line: MakeItBulkATMLineItem, index) => {
            if (line.userSuppliedProductId || line.productSku) {
                let idToMatch = line.userSuppliedProductId
                    ? line.userSuppliedProductId
                    : line.productSku;
                let matchingProduct = products.find((product) => {
                    const customerProductInfo = getCustomerProductInformation(
                        product,
                        order.shipToId
                    );
                    return (
                        product.productSku === idToMatch ||
                        (customerProductInfo.customerProductId === idToMatch &&
                            customerProductInfo.status !== 'INACTIVE') ||
                        product.productSku === line.productSku
                    );
                });

                if (matchingProduct) {
                    const customerProductInformation = getCustomerProductInformation(
                        matchingProduct,
                        order.shipToId
                    );
                    matchingProduct.displayId = customerProductInformation.customerProductId
                        ? customerProductInformation.customerProductId
                        : matchingProduct.productSku;
                    matchingProduct.displayName = customerProductInformation.customerProductName
                        ? customerProductInformation.customerProductName
                        : matchingProduct.name;
                    order.lines[index] = combineProductData(line, matchingProduct);
                    order.lines[index] = setFeesMOQData(matchingProduct, order.lines[index]);
                    order.lines[index].productStatus = getProductStatus(
                        matchingProduct,
                        order.shipToId
                    );
                }
                order.lines[index].deleted = false;
                order.lines[index].originalPalletQuantity = order.lines[index]
                    .originalPalletQuantity
                    ? order.lines[index].originalPalletQuantity
                    : 0;
                if (
                    order.lines[index].eachesQuantity &&
                    order.lines[index].palletQuantity &&
                    order.lines[index].quantityPerPallet
                ) {
                    order.lines[index].palletsRounded =
                        order.lines[index].eachesQuantity !==
                        order.lines[index].palletQuantity! * order.lines[index].quantityPerPallet!;
                }
                if (availableBalances.length > 0) {
                    order.lines[index].availableQuantity = getAvailableQuantity(
                        availableBalances,
                        order.shipToId,
                        moment.utc(order.weekStart).format('MM/DD/YYYY'),
                        order.lines[index].productSku
                    );
                }
            }
        });
    });
};

const atmBulkUploadResponseHelper = (orders: any) => {
    orders.forEach((order) => {
        order.weekStart = order.atmWeekStart;
    });
};

const getProductStatus = (product: any, shipToId: number): ProductStatus => {
    if (product.status && product.status === ProductStatus.Inactive) {
        return product.status;
    } else {
        const activeTargetDesinations = product.destinations.filter((d) => d.shipToId === shipToId);
        return activeTargetDesinations.length > 0 ? ProductStatus.Active : ProductStatus.Inactive;
    }
};

export const copyAtmOrderBySnoSku = (
    activeDate: string,
    snoSku: string,
    newDate: string,
    move?: boolean
) => {
    return (dispatch, getState) => {
        const state = getState();
        const atmOrders = state.bulkUploadMakeItATMState.atmOrders as MakeItBulkATMOrder[];
        let linesToCopy: MakeItBulkATMLineItem[] = [];
        const filteredOrders = atmOrders.filter((order) => order.weekStart === activeDate);
        const activeMonday = getMondayOfWeek(moment.utc(activeDate)).format('MM/DD/YYYY');
        const newMonday = getMondayOfWeek(moment.utc(newDate)).format('MM/DD/YYYY');
        filteredOrders.forEach((order) => {
            order.lines.forEach((line) => {
                if (line.snoSku === snoSku) {
                    linesToCopy.push(line);
                }
            });
        });
        atmOrders.forEach((order) => {
            if (getMondayOfWeek(moment.utc(order.weekStart)).format('MM/DD/YYYY') === newMonday) {
                order.lines.forEach((line) => {
                    const foundLine = linesToCopy.find(
                        (lineToCopy) => lineToCopy.productSku === line.productSku
                    );
                    if (!!foundLine) {
                        line.palletQuantity = foundLine.palletQuantity;
                    }
                });
            }
        });
        if (move) {
            atmOrders.forEach((order) => {
                if (
                    getMondayOfWeek(moment.utc(order.weekStart)).format('MM/DD/YYYY') ===
                    activeMonday
                ) {
                    order.lines.forEach((line) => {
                        const foundLine = linesToCopy.find(
                            (lineToCopy) => lineToCopy.productSku === line.productSku
                        );
                        if (!!foundLine) {
                            line.palletQuantity = 0;
                        }
                    });
                }
            });
        }
        dispatch({
            type: MAKEIT_BULK_ATM_COPY_PRODUCT,
            atmOrders: [...atmOrders]
        });
    };
};

export const submitAtmOrders = (callback: Function) => {
    return (dispatch, getState) => {
        const state = getState();
        const accessToken = state.auth.accessToken;
        const userName = state.auth.userInfo.preferred_username;
        const region = state.regionCulture.regionCode;
        const atmOrders = state.bulkUploadMakeItATMState.atmOrders as MakeItBulkATMOrder[];
        const ordersBySnoSku = state.bulkUploadMakeItATMState.ordersBySnoSku;
        const shipToAccounts = state.customerContext.shipToAccounts;

        const ordersToSubmit = atmOrders.filter((order) => {
            const orderBySnoSku = ordersBySnoSku.find(
                (product) =>
                    product.weekStart === order.weekStart && product.shipToId === order.shipToId
            );
            return orderBySnoSku.state !== 'error';
        });

        const payload = constructOrdersForRequest(ordersToSubmit, userName, region, shipToAccounts);

        dispatch({ type: MAKEIT_BULK_ATM_SUBMIT_ORDERS_LOADING });

        OrdersService.submitMakeItBulkAtmUpload(payload, accessToken)
            .then((response) => {
                if (response.data?.orders?.length) {
                    atmBulkUploadResponseHelper(response.data.orders);
                    const updatedAtmOrders: MakeItBulkATMOrder[] = atmOrders
                        .filter((atmOrder) =>
                            response.data.orders.find(
                                (apiOrder) =>
                                    apiOrder.shipToId === atmOrder.shipToId &&
                                    moment
                                        .parseZone(apiOrder.weekStart ?? apiOrder.atmWeekStart)
                                        .format('MM/DD/YYYY') ===
                                        moment(atmOrder.weekStart ?? apiOrder.atmWeekStart).format(
                                            'MM/DD/YYYY'
                                        )
                            )
                        )
                        .map((atmOrder) => {
                            const orderFromApi = response.data.orders.find(
                                (apiOrder) =>
                                    apiOrder.shipToId === atmOrder.shipToId &&
                                    moment
                                        .parseZone(apiOrder.weekStart ?? apiOrder.atmWeekStart)
                                        .format('MM/DD/YYYY') ===
                                        moment(atmOrder.weekStart ?? apiOrder.atmWeekStart).format(
                                            'MM/DD/YYYY'
                                        )
                            );
                            bulkUploadUpdateOrderQuantities([orderFromApi]);
                            return {
                                ...atmOrder,
                                canQuantityPallets: orderFromApi?.canQuantityPallets
                                    ? orderFromApi.canQuantityPallets
                                    : 0,
                                canQuantitySKUs: orderFromApi?.canQuantitySKUs
                                    ? orderFromApi.canQuantitySKUs
                                    : 0,
                                canQuantityEaches: orderFromApi?.canQuantityEaches
                                    ? orderFromApi.canQuantityEaches
                                    : 0,
                                endQuantityPallets: orderFromApi?.endQuantityPallets
                                    ? orderFromApi.endQuantityPallets
                                    : 0,
                                endQuantitySKUs: orderFromApi?.endQuantitySKUs
                                    ? orderFromApi.endQuantitySKUs
                                    : 0,
                                endQuantityEaches: orderFromApi?.endQuantityEaches
                                    ? orderFromApi.endQuantityEaches
                                    : 0
                            };
                        });
                    dispatch({
                        type: MAKEIT_BULK_ATM_SUBMIT_ORDERS_SUCCESS,
                        atmOrders: updatedAtmOrders
                    });
                } else {
                    const updatedAtmOrders: MakeItBulkATMOrder[] = atmOrders.map((atmOrder) => {
                        return {
                            ...atmOrder,
                            canQuantityPallets: 0,
                            canQuantitySKUs: 0,
                            canQuantityEaches: 0,
                            endQuantityPallets: 0,
                            endQuantitySKUs: 0,
                            endQuantityEaches: 0
                        };
                    });
                    dispatch({
                        type: MAKEIT_BULK_ATM_SUBMIT_ORDERS_SUCCESS,
                        atmOrders: updatedAtmOrders
                    });
                }
                callback(false);
            })
            .catch((error) => {
                dispatch({ type: MAKEIT_BULK_ATM_SUBMIT_ORDERS_ERROR, error: 'submit' });
                callback(false);
            });
    };
};

export const constructOrdersForRequest = (
    storeAtmOrders: MakeItBulkATMOrder[],
    userName: string,
    region: string,
    accounts: Account[],
    isDraft = false
) => {
    let atmOrders: any[] = [];
    storeAtmOrders.forEach((atmOrder) => {
        let linesByWeekAndShipTo: any[] = [];
        linesByWeekAndShipTo = atmOrder.lines
            .filter((line) => !line.deleted && line.palletQuantity)
            .map((line) => ({
                ...line,
                shipToId: atmOrder.shipToId,
                ...(isDraft && { status: 'SUBMIT' })
            }));

        if (linesByWeekAndShipTo.length || atmOrder.productionOrderId) {
            const shipToAccount = accounts.find(
                (a) => a.accountId === atmOrder.shipToId.toString()
            );
            atmOrders.push({
                region: region,
                createdBy: userName,
                submittedBy: userName,
                status: Order_Status.Submit,
                shipToId: atmOrder.shipToId,
                paymentTerms: shipToAccount?.paymentTerms,
                lines: linesByWeekAndShipTo,
                customerProductionOrderId: atmOrder.customerProductionOrderId,
                productionOrderId: atmOrder.productionOrderId
            });
        }
    });
    return atmOrders;
};

export const resetTemplateError = () => ({
    type: MAKEIT_BULK_UPLOAD_ATM_TEMPLATE_CLEAR
});

/**
 * Combines product data with data from the line.
 * @param line
 * @param matchingProduct
 */
function combineProductData(line, matchingProduct) {
    if (matchingProduct) return { ...matchingProduct, ...line, status: line.status };
    else
        return {
            ...line,
            displayName: line.productName,
            coating: line.productCoating,
            displayId: line.productSku,
            graphicIdAndVersion: line.productGraphicId,
            type: line.productType,
            unit: line.productUnits,
            size: line.productSize,
            shape: line.productShape,
            endProfile: line.productEndProfile,
            neckDiameter: line.productNeckSize,
            baseCoat: line.productCoating,
            inactiveProduct: true
        };
}

/**
 * Loads atm order for edit from summary page
 * @param order
 * @param products
 * @returns
 */
export const loadEditATMOrder = (
    order: ProductionOrder,
    otherOrdersSameWeek: ProductionOrder[],
    products: MakeItBulkLineItem[]
) => {
    return async (dispatch, getState) => {
        var state = getState();
        const auth = getState().auth;
        const accessToken = getState().auth.accessToken;
        const getFullOrderDataPromises: Promise<any>[] = [
            OrdersService.getATMProductionOrder(getState(), order.productionOrderId || 0),
            OrdersService.getProductionOrders(accessToken, {
                shipToIds: [order.shipToId],
                productionOrderIds: otherOrdersSameWeek.map((order) => order.productionOrderId || 0)
            }),
            OrdersService.getMakeItProducts(accessToken, {
                ShipToIds: filterShipToIdsByPermission(
                    auth,
                    [order.shipToId],
                    Activity.NewOpenProductionOrders,
                    SecurityLevel.Edit
                ),
                RequestedDate: [order.atmWeekStart!],
                WithAvailablePallets: true
            })
        ];
        const responses = await Promise.all(getFullOrderDataPromises).catch((error) => {
            return dispatch({
                type: MAKEIT_BULK_ATM_EDIT_ORDER_ERROR,
                error
            });
        });
        const orderFromApi = responses[0]?.data;
        const otherOrdersSameWeekFromApi = responses[1]?.data?.orders || [];
        const productsWithAvailablePallets = responses[2]?.data?.products;

        const availableBalances = [
            {
                shipToId: order.shipToId,
                requestedDate: moment(order.atmWeekStart).format('MM/DD/YYYY'),
                balances: productsWithAvailablePallets.map((product) => {
                    return {
                        productSku: product.productSku,
                        quantity: product.availablePallets
                    };
                })
            } as AvailableBalance
        ];

        const lines: MakeItBulkATMLineItem[] =
            orderFromApi?.lines?.map((line) => {
                const matchingProduct = products.find(
                    (product) => product.productSku === line.productSku
                );
                let updatedLine = combineProductData(
                    line,
                    matchingProduct
                ) as MakeItBulkATMLineItem;

                updatedLine.availableQuantity = getAvailableQuantity(
                    availableBalances,
                    order.shipToId,
                    moment.utc(order.atmWeekStart).format('MM/DD/YYYY'),
                    line.productSku
                );
                updatedLine.originalPalletQuantity = line.palletQuantity;
                updatedLine.isCustomerProductIdDistinct = true;
                updatedLine = setFeesMOQData(matchingProduct, updatedLine);

                return updatedLine;
            }) || [];
        const targetSnoSkus = lines.map((line) => line.snoSku);
        otherOrdersSameWeekFromApi.forEach((order) => {
            order.lines?.forEach((line) => {
                const matchingProduct = products.find(
                    (product) => product.productSku === line.productSku
                );
                if (matchingProduct?.snoSku && !targetSnoSkus.includes(matchingProduct.snoSku)) {
                    targetSnoSkus.push(matchingProduct.snoSku);
                }
            });
        });

        const otherOrdersWeeklyPalletQuantityBySnoSku = getWeeklyPalletQuantityBySnoSku(
            targetSnoSkus,
            otherOrdersSameWeek,
            products
        );

        const atmOrder: MakeItBulkATMOrder = {
            ...order,
            lines: lines,
            weekStart: order.atmWeekStart!,
            canQuantityPallets: order.canQuantityPallets ?? 0,
            canQuantitySKUs: order.canQuantitySKUs ?? 0,
            endQuantityPallets: order.endQuantityPallets ?? 0,
            endQuantitySKUs: order.endQuantitySKUs ?? 0,
            otherOrdersWeeklyPalletQuantityBySnoSku: otherOrdersWeeklyPalletQuantityBySnoSku
        };
        dispatch({
            type: MAKEIT_BULK_ATM_EDIT_ORDER_LOADED,
            atmOrders: [atmOrder],
            availableBalances
        });
        OrdersService.getExistingOrders(
            state,
            order.atmWeekStart!,
            order.atmWeekStart!,
            [order.shipToId],
            ForecastStatus.ATM
        )
            .then((resp) => {
                if (resp) {
                    const existingOrders = JSON.parse(JSON.stringify(resp));
                    existingOrders
                        // during existing, filter out the current order
                        .filter(
                            (existingOrder) =>
                                existingOrder.productionOrderId !== order.productionOrderId
                        )
                        .forEach((existingOrder) => {
                            existingOrder.weekStart = order.atmWeekStart;
                            existingOrder.lines = existingOrder.lines.map((line) => {
                                const matchingProduct = products.find(
                                    (prod) => prod.productSku === line.productSku!
                                );
                                return {
                                    ...line,
                                    weekStart: order.atmWeekStart,
                                    snoSku: matchingProduct?.snoSku
                                };
                            });
                        });
                    dispatch({
                        type: MAKEIT_BULK_ATM_EXISTING_ORDERS,
                        existingOrders: existingOrders.filter(
                            (existingOrder) => existingOrder.status !== Order_Status.Draft
                        ),
                        existingDrafts: existingOrders.filter(
                            (existingOrder) => existingOrder.status === Order_Status.Draft
                        )
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: MAKEIT_BULK_ATM_DRAFT_ORDER_ERROR,
                    error
                });
            });
    };
};

export const setFeesMOQData = (matchingProduct, line: MakeItBulkATMLineItem) => {
    const updatedLine: MakeItBulkATMLineItem = JSON.parse(JSON.stringify(line));
    if (matchingProduct?.minimumOrderQuantity && updatedLine.palletQuantity) {
        const minValue = matchingProduct.moqFees
            ? getMoqFeeFloor(matchingProduct.moqFees)
            : matchingProduct.minimumOrderQuantity;
        updatedLine.withinRange =
            minValue <= updatedLine.palletQuantity &&
            updatedLine.palletQuantity < matchingProduct.minimumOrderQuantity;
        updatedLine.minimumMet =
            matchingProduct?.minimumOrderQuantity <= updatedLine.palletQuantity;
    } else {
        updatedLine.minimumMet = line.palletQuantity !== null && line.palletQuantity !== 0;
    }
    return updatedLine;
};

/**
 * returns the total pallet quantity
 * for the specified snoSkus in all of the
 * production orders (ATM) within ordersSameWeek
 * @param targetSnoSkus
 * @param ordersSameWeek
 * @param products
 */
const getWeeklyPalletQuantityBySnoSku = (
    targetSnoSkus: string[],
    ordersSameWeek: ProductionOrder[],
    products: MakeItBulkLineItem[]
): SnoSkuPalletQuantity[] => {
    const snoSkuPalletQuantities: SnoSkuPalletQuantity[] = targetSnoSkus.map((snoSku) => {
        return { snoSku: snoSku, palletQuantity: 0 };
    });
    ordersSameWeek.forEach((order) => {
        const atmOrder = atmBulkOrderBuilder(order, products);
        atmOrder.lines.forEach((line) => {
            if (targetSnoSkus.includes(line.snoSku)) {
                const snoSkuPalletQuantity = snoSkuPalletQuantities.find(
                    (item) => item.snoSku === line.snoSku
                );
                if (snoSkuPalletQuantity) {
                    snoSkuPalletQuantity.palletQuantity += line.palletQuantity ?? 0;
                }
            }
        });
    });
    return snoSkuPalletQuantities;
};

export const createDraftOrder = (draftName: string) => {
    return (dispatch, getState) => {
        const state = getState();
        const accessToken = state.auth.accessToken;
        const userName = state.auth.userInfo.preferred_username;
        const region = state.regionCulture.regionCode;
        const atmOrders = state.bulkUploadMakeItATMState.atmOrders as MakeItBulkATMOrder[];

        const formattedOrders = constructDraftForRequest(atmOrders, userName, region);
        const payload = {
            createDraft: { name: draftName },
            productionOrders: formattedOrders
        } as MakeItOrderATMCreateDraftRequest;

        dispatch({ type: MAKEIT_BULK_ATM_SAVE_DRAFT_LOADING });

        OrdersService.submitCreateDraft(payload, accessToken)
            .then((response) => {
                dispatch({ type: MAKEIT_BULK_ATM_SAVE_DRAFT_SUCCESS });
            })
            .catch((error) => {
                dispatch({ type: MAKEIT_BULK_ATM_SAVE_DRAFT_ERROR, error: 'submitDraft' });
            });
    };
};

export const updateDraftOrder = (
    draftName: string,
    draftId: number,
    ordersToRestore?: ProductionOrder[]
) => {
    return (dispatch, getState) => {
        const state = getState();
        const accessToken = state.auth.accessToken;
        const userName = state.auth.userInfo.preferred_username;
        const region = state.regionCulture.regionCode;
        const atmOrders = (ordersToRestore ??
            state.bulkUploadMakeItATMState.atmOrders) as MakeItBulkATMOrder[];

        const formattedOrders = constructDraftForRequest(atmOrders, userName, region);

        const payload = {
            updateDraft: { name: draftName, draftId: draftId },
            productionOrders: formattedOrders
        } as MakeItOrderATMUpdateDraftRequest;

        dispatch({ type: MAKEIT_BULK_ATM_SAVE_DRAFT_LOADING });

        OrdersService.submitUpdateDraft(payload, accessToken)
            .then((response) => {
                dispatch({ type: MAKEIT_BULK_ATM_SAVE_DRAFT_SUCCESS });
            })
            .catch((error) => {
                dispatch({ type: MAKEIT_BULK_ATM_SAVE_DRAFT_ERROR, error: 'submitDraft' });
            });
    };
};

export const submitDraftAsMakeIt = (callback: Function) => {
    return (dispatch, getState) => {
        const state = getState();
        const atmOrders = state.bulkUploadMakeItATMState.atmOrders as MakeItBulkATMOrder[];
        const ordersBySnoSku = state.bulkUploadMakeItATMState.ordersBySnoSku;
        const accessToken = state.auth.accessToken;
        const userName = state.auth.userInfo.preferred_username;
        const region = state.regionCulture.regionCode;
        const shipToAccounts = state.customerContext.shipToAccounts;
        dispatch({ type: MAKEIT_BULK_ATM_SUBMIT_ORDERS_LOADING });

        const ordersToSubmit = atmOrders.filter((order) => {
            const orderBySnoSku = ordersBySnoSku.find(
                (product) =>
                    product.weekStart === order.weekStart && product.shipToId === order.shipToId
            );
            return orderBySnoSku.state !== 'error';
        });

        const formattedOrders = constructOrdersForRequest(
            ordersToSubmit,
            userName,
            region,
            shipToAccounts,
            true
        );

        const apiCalls = formattedOrders.map((order) =>
            OrdersService.submitDraftAsMakeItOrder(order, accessToken)
        );

        Promise.all(apiCalls)
            .then((response) => {
                dispatch({
                    type: MAKEIT_BULK_ATM_SUBMIT_ORDERS_SUCCESS,
                    atmOrders: ordersToSubmit
                });
            })
            .catch((error) => {
                dispatch({ type: MAKEIT_BULK_ATM_SUBMIT_ORDERS_ERROR, error: 'submit' });
                callback(false);
            });
    };
};

export const constructDraftForRequest = (
    storeAtmOrders: MakeItBulkATMOrder[],
    userName: string,
    region: string
) => {
    let atmOrders: MakeItBulkATMOrderRequest[] = [];
    storeAtmOrders.forEach((atmOrder) => {
        let linesByWeekAndShipTo: any[] = [];
        linesByWeekAndShipTo = atmOrder.lines
            .filter((line) => !line.deleted && line.palletQuantity)
            .map((line) => ({ ...line, shipToId: atmOrder.shipToId }));
        if (linesByWeekAndShipTo.length || atmOrder.productionOrderId) {
            atmOrders.push({
                ...atmOrder,
                customerProductionOrderId: atmOrder.customerProductionOrderId,
                status: Order_Status.Draft,
                lines: linesByWeekAndShipTo,
                shipToId: atmOrder.shipToId,
                canQuantityPallets: atmOrder.canQuantityPallets,
                canQuantitySKUs: atmOrder.canQuantitySKUs,
                canQuantityEaches: atmOrder.canQuantityEaches,
                endQuantityPallets: atmOrder.endQuantityPallets,
                endQuantitySKUs: atmOrder.endQuantitySKUs,
                endQuantityEaches: atmOrder.endQuantityEaches,
                shipToName: linesByWeekAndShipTo[0].shipToName,
                shipToAddress: linesByWeekAndShipTo[0].shipToAddress,
                createdBy: userName,
                region: region
            });
        }
    });
    return atmOrders;
};

const getOrdersFromAllocations = (
    allocations: SnoSkuAllocationsByWeek[],
    products: OrderProductTableRow[],
    shipToId: number
): MakeItBulkATMOrder[] => {
    const orders: MakeItBulkATMOrder[] = [];

    if (allocations && products && shipToId) {
        const weeks = allocations
            .filter((allocation) => allocation.shipToId === shipToId)
            .map((a) => moment(a.week))
            .filter(
                (week, index, weeks) => weeks.findIndex((w) => w.isSame(week, 'day')) === index
            );
        weeks.forEach((week) => {
            const allocationsForSelectedWeek = allocations.filter(
                (allocation) =>
                    moment(allocation.week).format('MM/DD/YYYY') === week.format('MM/DD/YYYY') &&
                    allocation.shipToId === shipToId
            );
            const targetSnoSkus = allocationsForSelectedWeek.map((alloc) => alloc.snoSku);
            const supplyPlanProducts = products.filter(
                (product) =>
                    product.snoSku &&
                    product.destinations?.map((dest) => dest.shipToId).includes(shipToId) &&
                    targetSnoSkus.includes(product.snoSku) &&
                    !isProductEndType(product.type)
            );
            const endTypeProducts = products.filter(
                (product) =>
                    isProductEndType(product.type) &&
                    product.destinations?.map((dest) => dest.shipToId).includes(shipToId)
            );
            orders.push({
                customerProductionOrderId: '', // value will be assigned by user
                weekStart: week.format('MM/DD/YYYY'),
                shipToId: shipToId,
                lines: [
                    ...supplyPlanProducts.map((product) => {
                        return {
                            ...product,
                            requestedDate: moment(week).format('YYYY-MM-DDTHH:mm:ss'),
                            palletQuantity: 0,
                            isCustomerProductIdDistinct: true
                        } as MakeItBulkATMLineItem;
                    }),
                    ...endTypeProducts.map((product) => {
                        return {
                            ...product,
                            requestedDate: moment(week).format('YYYY-MM-DDTHH:mm:ss'),
                            palletQuantity: 0,
                            isCustomerProductIdDistinct: true
                        } as MakeItBulkATMLineItem;
                    })
                ],
                canQuantityPallets: 0,
                canQuantitySKUs: 0,
                endQuantityPallets: 0,
                endQuantitySKUs: 0
            });
        });
    }
    return orders;
};
export const loadProductsForAllocationExemptWeeks = (orderingWeeks: string[]) => {
    return (dispatch, getState) => {
        const state = getState();
        const shipToId = parseInt(state.customerContext.selectedAccountId);
        const atmOrders: MakeItBulkATMOrderRequest[] = orderingWeeks.map((orderingWeek) => {
            return {
                customerProductionOrderId: '', // value will be assigned by user
                weekStart: orderingWeek,
                shipToId: shipToId,
                lines: [],
                canQuantityPallets: 0,
                canQuantitySKUs: 0,
                endQuantityPallets: 0,
                endQuantitySKUs: 0
            };
        });
        dispatch({
            type: MAKEIT_BULK_ATM_LOAD_ORDERS,
            atmOrders,
            allocationsAndProducts: [],
            deletedOrders: []
        });
    };
};
export const loadProductsByAllocation = (fromDraft?: boolean) => {
    return (dispatch, getState) => {
        const state = getState();
        const shipToId = parseInt(state.customerContext.selectedAccountId);
        let products: OrderProductTableRow[] = state.makeItDashboard.products;
        const allocations: SnoSkuAllocationsByWeek[] = state.bulkUploadMakeItATMState.allocations;
        const atmOrders: MakeItBulkATMOrder[] = state.bulkUploadMakeItATMState.atmOrders;

        const orders = getOrdersFromAllocations(allocations, products, shipToId);
        const filteredAndDeletedOrders = filterLeadTimeViolations(orders);
        dispatch({
            type: MAKEIT_BULK_ATM_LOAD_ORDERS,
            atmOrders: fromDraft ? atmOrders : filteredAndDeletedOrders[0],
            allocationsAndProducts: allocations,
            deletedOrders: filteredAndDeletedOrders[1]
        });
    };
};

export const loadProductsByProductList = (orderingWeeks: string[]) => {
    return (dispatch, getState) => {
        const state = getState();
        const shipToId = parseInt(state.customerContext.selectedAccountId);
        const products: OrderProductTableRow[] = state.makeItDashboard.products;
        const allocations = state.bulkUploadMakeItATMState.allocations;

        const atmOrders: MakeItBulkATMOrderRequest[] = orderingWeeks.map((orderingWeek) => {
            return {
                customerProductionOrderId: '', // value will be assigned by user
                weekStart: orderingWeek,
                shipToId: shipToId,
                lines: [
                    ...products.map((product) => {
                        return {
                            ...product,
                            requestedDate: moment(orderingWeek).format('YYYY-MM-DDTHH:mm:ss'),
                            palletQuantity: 0,
                            isCustomerProductIdDistinct: true
                        } as MakeItBulkATMLineItem;
                    })
                ],
                canQuantityPallets: 0,
                canQuantitySKUs: 0,
                endQuantityPallets: 0,
                endQuantitySKUs: 0
            };
        });

        dispatch({
            type: MAKEIT_BULK_ATM_LOAD_ORDERS,
            atmOrders,
            allocationsAndProducts: allocations,
            deletedOrders: []
        });
    };
};

export const loadProductsFromDraft = () => {
    return (dispatch, getState) => {
        const state = getState();
        const allocations: SnoSkuAllocationsByWeek[] = state.bulkUploadMakeItATMState.allocations;
        const atmOrders: MakeItBulkATMOrder[] = state.bulkUploadMakeItATMState.atmOrders;

        dispatch({
            type: MAKEIT_BULK_ATM_LOAD_ORDERS,
            atmOrders: atmOrders,
            allocationsAndProducts: allocations,
            deletedOrders: []
        });
    };
};

const getAllocationsForProductionOrders = (
    allocations: SnoSkuAllocationsByWeek[],
    atmOrders: MakeItBulkATMOrder[],
    products: OrderProductTableRow[]
): SnoSkuAllocationsByWeek[] => {
    const allocatedProducts = [...allocations];
    atmOrders.forEach((order) => {
        order.lines.forEach((line) => {
            const product = products.find((p) => p.productSku === line.productSku);
            if (product && product.snoSku) {
                const newProduct = {
                    shipToId: order.shipToId,
                    week: order.weekStart,
                    snoSku: product.snoSku,
                    // set these to zero; if a real allocation is available it will be used instead
                    quantity: 0
                };
                if (
                    !allocatedProducts.find(
                        (p) =>
                            p.shipToId === newProduct.shipToId &&
                            p.week === newProduct.week &&
                            p.snoSku === newProduct.snoSku
                    )
                ) {
                    allocatedProducts.push(newProduct);
                }
            }
        });
    });

    return allocatedProducts;
};

const getLinesFromProductionOrder = (
    order,
    products,
    linkedShipments?: ProductionOrderLinkedDeliveryOrder[],
    edit: boolean = false
): MakeItBulkATMLineItem[] => {
    return order.lines.map((line) => {
        const product = products.find((p) => p.productSku === line.productSku);
        return {
            ...product,
            ...line,
            productionOrderLineId:
                edit && !!line.productionOrderLineId ? line.productionOrderLineId : undefined,
            originalPalletQuantity: line.palletQuantity,
            isCustomerProductIdDistinct: true,
            hasLinkedDeliveryOrder:
                linkedShipments &&
                linkedShipments.some((shipment) => shipment.productSKU === product.productSku),
            isRequestedDateAfterLeadTime: moment(line.requestedDate).isBefore(
                moment(product.firstAvailableDate)
            )
        };
    });
};

export const loadProductsFromPlanIt = () => {
    return (dispatch, getState) => {
        const state = getState();
        const shipToId = parseInt(state.customerContext.selectedAccountId);
        const products = state.makeItDashboard.products;
        const allocations = state.bulkUploadMakeItATMState.allocations;
        const copyToMakeItOrders: ProductionOrder[] = state.planItSummary.copyToMakeItOrders;

        const orders: MakeItBulkATMOrder[] = [];

        const promises = copyToMakeItOrders.map((order) => {
            if (order.productionOrderId) {
                return OrdersService.getProductionOrder(getState(), order.productionOrderId);
            }
            return null;
        });

        Promise.all(promises)
            .then((responses) => {
                if (responses) {
                    responses.forEach((response) => {
                        const forecast = response?.data;
                        orders.push({
                            customerProductionOrderId: forecast?.customerProductionOrderId
                                ? forecast?.customerProductionOrderId
                                : '',
                            weekStart: moment(forecast?.atmWeekStart!).format('MM/DD/YYYY'),
                            shipToId: shipToId,
                            lines: getLinesFromProductionOrder(forecast, products),
                            canQuantityPallets: forecast?.canQuantityPallets ?? 0,
                            canQuantitySKUs: forecast?.canQuantitySKUs ?? 0,
                            endQuantityPallets: forecast?.endQuantityPallets ?? 0,
                            endQuantitySKUs: forecast?.endQuantitySKUs ?? 0
                        });
                    });
                }
                orders.forEach((order) => {
                    order.lines.forEach((line) => {
                        if (!line.originalPalletQuantity && !!line.palletQuantity) {
                            line.originalPalletQuantity =
                                line.palletQuantity > 0 ? line.palletQuantity : 0;
                        }
                    });
                    const hasPlanItForThisWeek = orders.find(
                        (o) => o.weekStart === order.weekStart
                    );
                    if (hasPlanItForThisWeek) {
                        order.lines = order.lines.filter(
                            (p) => p.originalPalletQuantity! > 0 || isProductEndType(p.type)
                        );
                    }
                });
                const filteredAndDeletedOrders = filterLeadTimeViolations(orders);
                const weekToDisplay = findFirstWeek(filteredAndDeletedOrders[0]);
                dispatch({
                    type: MAKEIT_BULK_ATM_LOAD_ORDERS,
                    atmOrders: filteredAndDeletedOrders[0],
                    allocationsAndProducts: [...allocations],
                    deletedOrders: filteredAndDeletedOrders[1],
                    weekToDisplay: weekToDisplay
                });
            })
            .catch((error) => {
                dispatch({
                    type: MAKEIT_BULK_ATM_ADD_PRODUCT_ERROR,
                    error
                });
            });
    };
};

export const loadProductsFromOrder = (productionOrderId: String) => {
    return (dispatch, getState) => {
        const state = getState();
        const { regionCode } = state.regionCulture;
        const shipToId = parseInt(state.customerContext.selectedAccountId);
        const products = state.makeItDashboard.products;

        const orders: MakeItBulkATMOrder[] = [];

        const productionOrderRequest = OrdersService.getProductionOrder(
            getState(),
            Number(productionOrderId)
        );
        const linkedShipmentsRequest = OrdersService.getProductionOrderLinkedDeliveryOrders(
            getState(),
            +productionOrderId
        );
        Promise.all([productionOrderRequest, linkedShipmentsRequest])
            .then(([productionOrderResponse, linkedShipmentsResponse]) => {
                if (productionOrderResponse) {
                    const orderForEdit = productionOrderResponse?.data;
                    const date = moment
                        .utc(orderForEdit.lines[0].requestedDate)
                        .format('MM/DD/YYYY');
                    orders.push({
                        customerProductionOrderId: orderForEdit?.customerProductionOrderId ?? '',
                        productionOrderNumber: orderForEdit?.productionOrderNumber,
                        weekStart: date,
                        shipToId: shipToId,
                        lines: getLinesFromProductionOrder(
                            orderForEdit,
                            products,
                            linkedShipmentsResponse?.data.linkedDeliveryOrders ?? [],
                            true
                        ),
                        productionOrderId: orderForEdit?.productionOrderId,
                        canQuantityPallets: orderForEdit?.canQuantityPallets ?? 0,
                        canQuantitySKUs: orderForEdit?.canQuantitySKUs ?? 0,
                        endQuantityPallets: orderForEdit?.endQuantityPallets ?? 0,
                        endQuantitySKUs: orderForEdit?.endQuantitySKUs ?? 0
                    });

                    const startDate = date;
                    const endDate = moment(startDate).add(1, 'week').format('MM/DD/YYYY');

                    const allocationRequest = {
                        shipToIds: [shipToId],
                        startDate: [startDate],
                        endDate: [endDate]
                    } as AllocationRequest;
                    dispatch({ type: MAKEIT_BULK_UPLOAD_ATM_LOAD_ALLOCATIONS_LOADING });
                    AllocationService.getAllocations(state, regionCode, allocationRequest)
                        .then((response) => {
                            const allocations = response.data.allocations.filter(
                                (alloc) => alloc.ignoreAllocation === false
                            );
                            dispatch({
                                type: MAKEIT_BULK_UPLOAD_ATM_LOAD_ALLOCATIONS,
                                allocations: allocations
                            });
                            // include products from this order
                            const orderToEditAllocations = getAllocationsForProductionOrders(
                                allocations,
                                orders,
                                products
                            );
                            dispatch({
                                type: MAKEIT_BULK_ATM_ADD_PRODUCT,
                                atmOrders: [...orders],
                                allocationsAndProducts: orderToEditAllocations
                            });
                        })
                        .catch((error) => {
                            dispatch({
                                type: MAKEIT_BULK_UPLOAD_ATM_LOAD_ALLOCATIONS_ERROR,
                                error
                            });
                        });
                }
            })
            .catch((error) => {
                dispatch({
                    type: MAKEIT_BULK_ATM_ADD_PRODUCT_ERROR,
                    error
                });
            });
    };
};

export const updateCustomerProductionOrderNumber = (
    productionOrderId: string,
    week: string,
    shipToId: number
) => {
    return (dispatch, getState) => {
        const state = getState();
        const orders = state.bulkUploadMakeItATMState.atmOrders as MakeItBulkATMOrder[];
        const orderIndex = orders.findIndex(
            (order) =>
                moment(order.weekStart).format('MM/DD/YYYY') ===
                    moment(week).format('MM/DD/YYYY') && order.shipToId === shipToId
        );
        if (orderIndex === -1) {
            return;
        }
        orders[orderIndex].customerProductionOrderId = productionOrderId;
        dispatch({
            type: MAKEIT_BULK_ATM_UPDATE_CUSTOMER_PO,
            atmOrders: Object.assign([], orders)
        });
    };
};

export const mergeOrders = (orders1: MakeItBulkATMOrder[], orders2: MakeItBulkATMOrder[]) => {
    const mergedOrders: MakeItBulkATMOrder[] = Object.assign([], orders2);
    orders1.forEach((orderToMove) => {
        var existingOrder = mergedOrders.find(
            (o) => o.weekStart === orderToMove.weekStart && o.shipToId === orderToMove.shipToId
        );
        if (existingOrder === undefined) {
            mergedOrders.push(orderToMove);
        } else {
            existingOrder.customerProductionOrderId = orderToMove.customerProductionOrderId;
            orderToMove.lines.forEach((lineToMove) => {
                const isCan = lineToMove.type === 'CAN';
                var existingLine = existingOrder!.lines.find(
                    (existingLine) => existingLine.productSku === lineToMove.productSku
                );
                if (!existingLine) {
                    existingOrder!.lines.push(lineToMove);
                    existingOrder!.canQuantitySKUs =
                        (existingOrder?.canQuantitySKUs ?? 0) + (isCan ? 1 : 0);
                    existingOrder!.endQuantitySKUs =
                        (existingOrder?.endQuantitySKUs ?? 0) + (!isCan ? 1 : 0);
                } else {
                    existingLine.palletQuantity =
                        (existingLine.palletQuantity ?? 0) + (lineToMove.palletQuantity ?? 0);
                    if (isCan) {
                        existingOrder!.canQuantityEaches =
                            (existingOrder?.canQuantityEaches ?? 0) +
                            (lineToMove.quantityPerPallet ?? 0) * (lineToMove.palletQuantity ?? 0);
                        existingOrder!.canQuantityPallets =
                            (existingOrder?.canQuantityPallets ?? 0) +
                            (lineToMove.palletQuantity ?? 0);
                    } else {
                        existingOrder!.endQuantityEaches =
                            (existingOrder?.endQuantityEaches ?? 0) +
                            (lineToMove.quantityPerPallet ?? 0) * (lineToMove.palletQuantity ?? 0);
                        existingOrder!.endQuantityPallets =
                            (existingOrder?.endQuantityPallets ?? 0) +
                            (lineToMove.palletQuantity ?? 0);
                    }
                }
            });
        }
    });
    return mergedOrders;
};

export const loadProductsFromBulkUpload = () => {
    return (dispatch, getState) => {
        const state = getState();
        const { regionCode } = state.regionCulture;
        const products = state.bulkUploadMakeItState.products;
        const ordersFromUpload: ProductionOrder[] = state.bulkUploadMakeItState.orders;

        const orders: MakeItBulkATMOrder[] = [];
        const shipToIds = ordersFromUpload
            .map((order) => order.shipToId)
            .filter((value, index, self) => self.indexOf(value) === index);
        const startDate =
            moment().startOf('day').isoWeekday() === 1
                ? moment().format('MM/DD/YYYY')
                : moment().add(7, 'days').startOf('isoWeek').format('MM/DD/YYYY');
        const endDate = moment().add(1, 'year').format('MM/DD/YYYY');

        const allocationRequest = {
            shipToIds: shipToIds,
            startDate: [startDate],
            endDate: [endDate]
        } as AllocationRequest;
        dispatch({ type: MAKEIT_BULK_UPLOAD_ATM_LOAD_ALLOCATIONS_LOADING });
        AllocationService.getAllocations(state, regionCode, allocationRequest)
            .then((response) => {
                const filteredAllocations = filterAllocations(response.data.allocations, products);
                const allocations = filteredAllocations.filter(
                    (alloc) => alloc.ignoreAllocation === false
                );
                dispatch({
                    type: MAKEIT_BULK_UPLOAD_ATM_LOAD_ALLOCATIONS,
                    allocations: allocations
                });
                let datesAndShipTos: { date: string; shipToId: number; snoSkus: string[] }[] = [];
                ordersFromUpload &&
                    ordersFromUpload.forEach((orderFromUpload) => {
                        if (orderFromUpload.lines.length > 0) {
                            const requestedDate = moment(orderFromUpload.lines[0].requestedDate);
                            if (requestedDate) {
                                const lines = getLinesFromProductionOrder(
                                    orderFromUpload,
                                    products
                                );
                                const mondayOfWeek =
                                    getMondayOfWeek(requestedDate).format('MM/DD/YYYY');
                                let snoSkus: string[] = [];
                                orders.push({
                                    customerProductionOrderId:
                                        orderFromUpload?.customerProductionOrderId ?? '',
                                    weekStart: mondayOfWeek,
                                    shipToId: orderFromUpload.shipToId,
                                    lines: lines,
                                    canQuantityPallets: orderFromUpload?.canQuantityPallets ?? 0,
                                    canQuantitySKUs: orderFromUpload?.canQuantitySKUs ?? 0,
                                    endQuantityPallets: orderFromUpload?.endQuantityPallets ?? 0,
                                    endQuantitySKUs: orderFromUpload?.endQuantitySKUs ?? 0
                                });
                                lines.forEach((line) => {
                                    if (!!snoSkus.length) {
                                        if (!snoSkus.includes(line.snoSku))
                                            snoSkus.push(line.snoSku);
                                    } else {
                                        snoSkus.push(line.snoSku);
                                    }
                                });
                                datesAndShipTos.push({
                                    date: mondayOfWeek,
                                    shipToId: orderFromUpload.shipToId,
                                    snoSkus: snoSkus
                                });
                            }
                        }
                    });

                const filteredAndDeletedOrders = filterLeadTimeViolations(orders);
                const weekToDisplay =
                    filteredAndDeletedOrders.length > 0
                        ? findFirstWeek(filteredAndDeletedOrders[0])
                        : [];

                dispatch({
                    type: MAKEIT_BULK_ATM_LOAD_ORDERS,
                    atmOrders: filteredAndDeletedOrders[0],
                    allocationsAndProducts: allocations,
                    deletedOrders: filteredAndDeletedOrders[1],
                    weekToDisplay: weekToDisplay
                });
            })
            .catch((error) => {
                dispatch({
                    type: MAKEIT_BULK_UPLOAD_ATM_LOAD_ALLOCATIONS_ERROR,
                    error
                });
            });
    };
};

export const addSpotOrder = (weekToAdd: Moment) => {
    return (dispatch, getState) => {
        const state = getState();
        const ends = state?.bulkUploadMakeItState?.products?.filter((p) => p.type === 'END') ?? [];
        const cans = state?.bulkUploadMakeItState?.products?.filter((p) => p.type === 'CAN') ?? [];
        const endsAndCans = [...ends, ...cans];
        let selectedShipToId = parseInt(state.customerContext?.selectedAccountId);
        let existingOrders = state?.bulkUploadMakeItATMState?.atmOrders ?? [];
        let addedWeeks: string[] = state?.bulkUploadMakeItATMState?.addedWeeks ?? [];
        let deletedOrders: MakeItBulkATMOrder[] =
            state?.bulkUploadMakeItATMState?.deletedOrders ?? [];
        let deletedOrderFound = false;
        if (!!selectedShipToId && !!deletedOrders) {
            const foundDeletedOrder = deletedOrders.find((order) => {
                const modifyWeekToAdd = formattedMTDate(moment(weekToAdd), 'MM/DD/YYYY');
                return order.weekStart === modifyWeekToAdd && order.shipToId === selectedShipToId;
            });
            if (foundDeletedOrder) {
                deletedOrderFound = true;
                existingOrders.push(foundDeletedOrder);
            }
        }
        if (!deletedOrderFound) {
            existingOrders.push({
                customerProductionOrderId: '',
                weekStart: weekToAdd.format('MM/DD/YYYY'),
                shipToId: +state.customerContext.selectedAccountId,
                canQuantityPallets: 0,
                canQuantitySKUs: 0,
                endQuantityPallets: 0,
                endQuantitySKUs: 0,
                lines: endsAndCans.map((productionLine) => ({
                    ...productionLine,
                    palletQuantity: 0,
                    isCustomerProductIdDistinct: true,
                    requestedDate: weekToAdd.toISOString()
                }))
            });
        }
        addedWeeks.push(weekToAdd.format('MM/DD/YYYY'));
        dispatch({
            type: MAKEIT_BULK_ATM_ADD_WEEK,
            atmOrders: [...existingOrders],
            allocationsAndProducts: [...state.bulkUploadMakeItATMState.allocationsAndProducts],
            addedWeeks: [...addedWeeks]
        });
    };
};

export const cancelProductionOrder = (callback: Function) => {
    return (dispatch, getState) => {
        const state = getState();
        const accessToken = state.auth.accessToken;
        const userName = state.auth.userInfo.preferred_username;
        const region = state.regionCulture.regionCode;
        const shipToAccounts = state.customerContext.shipToAccounts;
        const order = state.bulkUploadMakeItATMState.atmOrders[0];
        order.lines.forEach((line) => (line.palletQuantity = 0));
        order.canQuantityPallets = 0;
        order.canQuantitySKUs = 0;
        order.endQuantityPallets = 0;
        order.endQuantitySKUs = 0;

        const payload = constructOrdersForRequest([order], userName, region, shipToAccounts);

        dispatch({ type: MAKEIT_BULK_ATM_SUBMIT_ORDERS_LOADING });
        OrdersService.submitMakeItBulkAtmUpload(payload, accessToken)
            .then((response) => {
                dispatch({ type: MAKEIT_BULK_ATM_SUBMIT_ORDERS_SUCCESS });
                callback(true);
            })
            .catch((error) => {
                dispatch({ type: MAKEIT_BULK_ATM_SUBMIT_ORDERS_ERROR, error: 'submit' });
                callback(false);
            });
    };
};
