import React, { ReactElement, useEffect, useState } from 'react';
import { regions } from '../../../static-data/dropdown-lists';
import AutocompleteInput from './AutocompleteInput';
import { Trans, useTranslation } from 'react-i18next';
import { KeyValuePair } from '../../../store/types';

interface Props {
    defaultValue?: string;
    regionCode: string;
    control: any;
    name: string;
    rules?: any;
    error?: boolean;
    errors?: any;
    styles?: any;
    helperText?: ReactElement;
    handleLoadDraftApproverCountry?: (name: string, selectedCountry: KeyValuePair) => void;
}

const CountrySelect = (props: Props) => {
    const countryList = regions.find((r) => r.value === props.regionCode)?.countries;
    const [selectedCountry, setSelectedCountry] = useState<KeyValuePair>();
    useTranslation();

    const handleOnChange = (selectedOption: KeyValuePair) => {
        setSelectedCountry(selectedOption);
    };

    //load default value if necessary
    useEffect(() => {
        if (countryList && props.defaultValue) {
            let country = countryList.find((country) => country.value === props.defaultValue);
            if (country) {
                setSelectedCountry(country);
            }
        }
    }, [countryList, props.defaultValue]);

    useEffect(() => {
        if (selectedCountry && props.handleLoadDraftApproverCountry) {
            props.handleLoadDraftApproverCountry(props.name, selectedCountry);
        }
    }, [selectedCountry]);

    return (
        <AutocompleteInput
            label={<Trans i18nKey="country">Country</Trans>}
            name={props.name}
            testId="country-dropdown"
            control={props.control}
            rules={props.rules}
            error={props.error}
            errors={props.errors}
            selectObj={countryList}
            value={selectedCountry}
            onChange={handleOnChange}
            helperText={props.helperText}
        />
    );
};

export default CountrySelect;
