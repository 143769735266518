import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation, Trans } from 'react-i18next';
import { Grid, makeStyles, Typography, Button } from '@material-ui/core';
import { OrderProductTableRow } from '../../../../store/reducers/orders-dashboard';
import {
    blackWeight,
    large,
    ltBlueGrey_34,
    orange,
    boldWeight,
    medium,
    regularWeight,
    ballDrkBlue,
    xl,
    black
} from '../../../../themes/globalConstants';
import { isProductCanType, isProductEndType } from '../../../../utility/helpers/order-helpers';
import {
    getConvertedQuantityUnitPallets,
    getFormattedSelectedQuantityUnit
} from '../../../../utility/helpers/shipment-helpers';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { formatNumberWithLocale } from '../../../../utility/helpers/formatters';
interface Props {
    products: OrderProductTableRow[];
    onRemoveAll: (itemList: OrderProductTableRow[]) => void;
    onRemoveOrder: (item: OrderProductTableRow) => void;
    isLargeCustomer: boolean;
}

const useStyles = makeStyles((theme) => ({
    checkoutCansContainer: {
        padding: '1.5em 0.5em 0.875em 0.5em',
        backgroundColor: 'white',
        marginBottom: '.125em',
        borderTopRightRadius: '0.250em',
        borderTopLeftRadius: '0.250em',
        '&:first-child': {
            marginTop: '2.25em'
        }
    },
    checkoutEndsContainer: {
        padding: '0.875em 0.5em',
        backgroundColor: 'white',
        marginTop: '.875em',
        marginBottom: '.125em',
        borderRadius: '0.250em'
    },
    title: {
        fontWeight: blackWeight,
        color: theme.palette.secondary.main,
        marginBottom: '0.875em'
    },
    checkoutItem: {
        padding: '0.875em 0.5em',
        backgroundColor: 'white',
        '&:not(:last-of-type)': {
            marginBottom: '.125em'
        }
    },
    //Checkout text is wider here than Ship It due to not needing to accommodate pallets
    checkoutText: {
        width: '15em',
        wordWrap: 'break-word'
    },
    counterWrapper: {
        padding: '0.75em 0',
        width: '100%'
    },
    counterTitle: {
        fontSize: large,
        fontWeight: blackWeight,
        marginRight: '.25em',
        display: 'flex',
        alignItems: 'center'
    },
    counter: {
        width: '1.5em',
        height: '1.5em',
        borderRadius: '50%',
        backgroundColor: ltBlueGrey_34,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '.5em'
    },
    hidden: {
        display: 'none'
    },
    trash: {
        minWidth: 'unset',
        '&:hover': {
            backgroundColor: 'white'
        },
        '& svg': {
            fill: orange
        }
    },
    removeAllBtn: {
        paddingRight: '0.375em',
        paddingLeft: '0.375em',
        height: '2.28em'
    },
    preview: {
        border: `1px solid ${theme.palette.primary.main}`,
        borderStyle: 'dashed',
        padding: '10em 1.875em',
        textAlign: 'center'
    },
    previewText: {
        fontWeight: boldWeight,
        fontSize: medium,
        '&:last-child': {
            marginTop: '0.5em'
        }
    },
    productListWrapper: {
        maxHeight: '19.500em',
        overflowY: 'auto'
    },
    orderPalletContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    quantityDisplay: {
        display: 'flex',
        flexDirection: 'column'
    },
    orderPallet: {
        fontSize: medium,
        fontWeight: blackWeight,
        textAlign: 'right',
        wordBreak: 'break-all'
    },
    orderEaches: {
        fontWeight: regularWeight
    },
    totalContainer: {
        padding: '0.875em 0.5em',
        backgroundColor: 'white',
        marginBottom: '1.125em',
        borderBottomRightRadius: '0.250em',
        borderBottomLeftRadius: '0.250em'
    },
    totalPalletsContainer: {
        color: ballDrkBlue,
        marginBottom: '1em',
        '& > p': {
            fontSize: xl,
            fontWeight: blackWeight
        }
    },
    totalQuantityEaches: {
        display: 'flex',
        flexDirection: 'column',

        '& p:nth-of-type(1)': {
            fontSize: xl,
            fontWeight: blackWeight
        },

        '& p': {
            textAlign: 'right'
        }
    },
    canEachesTotal: {
        fontSize: '.75rem',
        color: black,
        fontWeight: regularWeight
    }
}));

export default function MakeItCheckout({
    products,
    onRemoveAll,
    onRemoveOrder,
    isLargeCustomer
}: Props) {
    const classes = useStyles();
    const [cansList, setCansList] = useState([] as OrderProductTableRow[]);
    const [endsList, setEndsList] = useState([] as OrderProductTableRow[]);
    const [cansEachesTotal, setCansEachesTotal] = useState<number>(0);
    const [endsEachesTotal, setEndsEachesTotal] = useState<number>(0);
    const [canPallets, setCanPallets] = useState<number>(0);
    const [endPallets, setEndPallets] = useState<number>(0);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const handleRemoveOrder = (item: OrderProductTableRow) => {
        onRemoveOrder(item);
    };

    useEffect(() => {
        if (products.length > 0) {
            const canList = products.filter((product) =>
                isProductCanType(product.type)
            ) as OrderProductTableRow[];
            const endList = products.filter((product) =>
                isProductEndType(product.type)
            ) as OrderProductTableRow[];

            setCansList(canList);
            setEndsList(endList);
        }
    }, [products]);

    useEffect(() => {
        setCansEachesTotal(cansList.reduce((sum, product) => sum + product.eachesQuantity!, 0));
        setEndsEachesTotal(endsList.reduce((sum, product) => sum + product.eachesQuantity!, 0));
        setCanPallets(cansList.reduce((sum, product) => sum + product.palletQuantity!, 0));
        setEndPallets(endsList.reduce((sum, product) => sum + product.palletQuantity!, 0));
    }, [cansList, endsList]);

    useTranslation();

    return (
        <Grid container item>
            {products.length === 0 && (
                <Grid
                    container
                    className={classes.checkoutCansContainer}
                    data-testid="empty-checkout-container"
                >
                    <Grid item xs={12}>
                        <Typography variant="h3" className={classes.title}>
                            <Trans i18nKey="yourOrder">Your Order</Trans>
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} className={classes.preview}>
                        <Typography className={classes.previewText}>
                            <Trans i18nKey="addInstructions">
                                Build your order by clicking the "ADD" button for each product you’d
                                like to include.
                            </Trans>
                        </Typography>
                        <Typography className={classes.previewText}>
                            <Trans i18nKey="qtyInstructions">
                                You will enter quantities and requested dates for the selected
                                products on the next screen.
                            </Trans>
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {products.length > 0 && (
                <>
                    <Grid
                        container
                        className={classes.checkoutCansContainer}
                        data-testid="cans-checkout-section"
                    >
                        <Grid item xs={12}>
                            <Typography variant="h3" className={classes.title}>
                                <Trans i18nKey="yourOrder">Your Order</Trans>
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={7}
                            alignItems="center"
                            className={classes.counterWrapper}
                        >
                            <Typography className={classes.counterTitle}>
                                <Trans i18nKey="cans">Cans</Trans> /{' '}
                            </Typography>
                            <Typography className={classes.counterTitle}>
                                <Trans i18nKey="bottles">Bottles</Trans>
                                <Typography
                                    component="span"
                                    variant="subtitle2"
                                    className={classes.counter}
                                >
                                    {cansList.length}
                                </Typography>
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={5}
                            justify="flex-end"
                            alignContent="center"
                            className={clsx({ [classes.hidden]: cansList.length === 0 })}
                        >
                            <Button
                                type="button"
                                variant="outlined"
                                size="small"
                                onClick={() => onRemoveAll(cansList)}
                                className={classes.removeAllBtn}
                                data-testid="remove-all-btn"
                            >
                                <Trans i18nKey="removeAll">Remove All</Trans>
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.productListWrapper}>
                        {cansList &&
                            cansList.map((product, i) => {
                                return (
                                    <Grid
                                        container
                                        item
                                        key={i}
                                        className={classes.checkoutItem}
                                        justify="space-between"
                                        data-testid={`checkout-product-${product.displayId}`}
                                    >
                                        <Grid item className={classes.checkoutText} xs={7}>
                                            <Typography variant="h6">
                                                {product.displayId}
                                            </Typography>
                                            <Typography variant="subtitle2">
                                                {product.displayName}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            className={classes.orderPalletContainer}
                                            xs={5}
                                            justify="flex-end"
                                        >
                                            {isLargeCustomer && (
                                                <div className={classes.quantityDisplay}>
                                                    <Typography
                                                        className={classes.orderPallet}
                                                        data-testid="cans-pallet-count"
                                                    >
                                                        {formatNumberWithLocale(
                                                            cultureCode,
                                                            product.palletQuantity ?? 0
                                                        )}{' '}
                                                        PL
                                                    </Typography>
                                                    <Typography
                                                        className={clsx([
                                                            classes.orderPallet,
                                                            classes.orderEaches
                                                        ])}
                                                        data-testid="cans-pallet-count"
                                                    >
                                                        {formatNumberWithLocale(
                                                            cultureCode,
                                                            +getConvertedQuantityUnitPallets(
                                                                'pallets',
                                                                product.palletQuantity!,
                                                                product.quantityPerPallet!
                                                            )
                                                        )}
                                                        {' ' +
                                                            getFormattedSelectedQuantityUnit(
                                                                'eaches'
                                                            )}
                                                    </Typography>
                                                </div>
                                            )}
                                            <Button
                                                type="button"
                                                onClick={() => {
                                                    handleRemoveOrder(product);
                                                }}
                                                className={classes.trash}
                                                data-testid={`checkout-trash-${product.displayId}`}
                                            >
                                                <img
                                                    src={
                                                        process.env.PUBLIC_URL + '/assets/Trash.svg'
                                                    }
                                                    alt="Trash Icon"
                                                />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                    </Grid>
                    {isLargeCustomer && (
                        <Grid container item className={classes.totalContainer}>
                            <Grid
                                container
                                item
                                justify="space-between"
                                className={classes.totalPalletsContainer}
                            >
                                <Typography>
                                    <Trans i18nKey="totalPallets">Total Pallets</Trans>
                                </Typography>
                                <div className={classes.totalQuantityEaches}>
                                    <Typography data-testid="can-pallet-total">
                                        {formatNumberWithLocale(cultureCode, canPallets)}{' '}
                                        {getFormattedSelectedQuantityUnit('pallets')}
                                    </Typography>
                                    <Typography
                                        className={classes.canEachesTotal}
                                        data-testid="can-eaches-total"
                                    >
                                        {`${formatNumberWithLocale(
                                            cultureCode,
                                            Number(cansEachesTotal)
                                        )} ${getFormattedSelectedQuantityUnit('eaches')}`}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    )}
                    <Grid
                        container
                        className={classes.checkoutEndsContainer}
                        data-testid="ends-checkout-section"
                    >
                        <Grid
                            container
                            item
                            xs={7}
                            alignItems="center"
                            className={classes.counterWrapper}
                        >
                            <Typography className={classes.counterTitle}>
                                <Trans i18nKey="ends">Ends</Trans> /{' '}
                            </Typography>
                            <Typography className={classes.counterTitle}>
                                <Trans i18nKey="closures">Closures</Trans>
                                <Typography
                                    component="span"
                                    variant="subtitle2"
                                    className={classes.counter}
                                >
                                    {endsList.length}
                                </Typography>
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={5}
                            justify="flex-end"
                            alignContent="center"
                            className={clsx({ [classes.hidden]: endsList.length === 0 })}
                        >
                            <Button
                                type="button"
                                variant="outlined"
                                size="small"
                                onClick={() => onRemoveAll(endsList)}
                                className={classes.removeAllBtn}
                                data-testid="remove-all-ends-btn"
                            >
                                <Trans i18nKey="removeAll">Remove All</Trans>
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.productListWrapper}>
                        {endsList &&
                            endsList.map((end, i) => {
                                return (
                                    <Grid
                                        container
                                        item
                                        key={i}
                                        className={classes.checkoutItem}
                                        justify="space-between"
                                        data-testid={`checkout-product-${end.displayId}`}
                                    >
                                        <Grid item className={classes.checkoutText} xs={7}>
                                            <Typography variant="h6">{end.displayId}</Typography>
                                            <Typography variant="subtitle2">
                                                {end.displayName}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            className={classes.orderPalletContainer}
                                            xs={5}
                                            justify="flex-end"
                                        >
                                            {isLargeCustomer && (
                                                <div className={classes.quantityDisplay}>
                                                    <Typography
                                                        className={classes.orderPallet}
                                                        data-testid="ends-pallet-count"
                                                    >
                                                        {formatNumberWithLocale(
                                                            cultureCode,
                                                            end.palletQuantity ?? 0
                                                        )}{' '}
                                                        PL
                                                    </Typography>
                                                    <Typography
                                                        className={clsx([
                                                            classes.orderPallet,
                                                            classes.orderEaches
                                                        ])}
                                                        data-testid="ends-pallet-count"
                                                    >
                                                        {formatNumberWithLocale(
                                                            cultureCode,
                                                            +getConvertedQuantityUnitPallets(
                                                                'pallets',
                                                                end.palletQuantity!,
                                                                end.quantityPerPallet!
                                                            )
                                                        )}
                                                        {' ' +
                                                            getFormattedSelectedQuantityUnit(
                                                                'eaches'
                                                            )}
                                                    </Typography>
                                                </div>
                                            )}
                                            <Button
                                                type="button"
                                                onClick={() => {
                                                    handleRemoveOrder(end);
                                                }}
                                                className={classes.trash}
                                                data-testid={`checkout-trash-${end.displayId}`}
                                            >
                                                <img
                                                    src={
                                                        process.env.PUBLIC_URL + '/assets/Trash.svg'
                                                    }
                                                    alt="Trash Icon"
                                                />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                    </Grid>

                    {isLargeCustomer && (
                        <Grid container item className={classes.totalContainer}>
                            <Grid
                                container
                                item
                                justify="space-between"
                                className={classes.totalPalletsContainer}
                            >
                                <Typography>
                                    <Trans i18nKey="totalPallets">Total Pallets</Trans>
                                </Typography>
                                <div className={classes.totalQuantityEaches}>
                                    <Typography data-testid="end-pallet-total">
                                        {formatNumberWithLocale(cultureCode, endPallets)}{' '}
                                        {getFormattedSelectedQuantityUnit('pallets')}
                                    </Typography>
                                    <Typography
                                        className={classes.canEachesTotal}
                                        data-testid="end-eaches-total"
                                    >
                                        {`${formatNumberWithLocale(
                                            cultureCode,
                                            Number(endsEachesTotal)
                                        )} ${getFormattedSelectedQuantityUnit('eaches')}`}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    );
}
