import React from 'react';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { makeStyles } from '@material-ui/core';
import { addFormItemButtonBlue } from '../../../themes/globalConstants';
import Button from './Button';

interface Props {
  onClick: () => void;
  text: string;
}

const useStyles = makeStyles(() => ({
  button: {
    color: addFormItemButtonBlue,
    textTransform: 'none',
    textDecoration: 'underline',

    '&:hover': {
      textDecoration: 'underline',
    },

    '& .MuiSvgIcon-root': {
      marginRight: '0.25em',
      width: '.8125em',
      height: '.8125em'
    }
  }
}))

export default function AddItemFormButton({onClick, text}: Props) {
  const classes = useStyles();
  return (
    <Button data-testid="add-item-button" className={classes.button} onClick={onClick}><AddCircleOutlineIcon/>{text}</Button>
  )
}