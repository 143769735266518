import React from 'react';
import PdfPageTemplate from '../../../templates/PdfPageTemplate';
import { View, BlobProvider } from '@react-pdf/renderer';
import ReactPdf from '@react-pdf/renderer';
import { isIE } from '../../../../utility/helpers/user-helpers';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { getAccountAddress } from '../../../../utility/helpers/address-helpers';
import { isProductCanType } from '../../../../utility/helpers/order-helpers';
import { ProductType } from '../../../../utility/services/orders-service';
import { getSnoSkuDescription } from '../../../../utility/helpers/make-it-bulk-helpers';
import { formatNumberWithLocale } from '../../../../utility/helpers/formatters';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import {
    PlanItBulkATMLineItem,
    PlanItBulkATMLinesBySnoSku,
    PlanItBulkATMOrderByWeekAndSku
} from '../../../../store/reducers/planit-bulk-atm';
import StandardPDF from '../../../reusable/molecules/StandardPDF';
import StandardPDFTable from '../../../reusable/molecules/StandardPDFTable';
import { MakeItBulkATMLinesBySnoSku } from '../../../../store/reducers/makeit-bulk-atm';
import StandardPDFFlex from '../../../reusable/molecules/StandardPDFFlex';
import StandardPDFLabeledText from '../../../reusable/molecules/StandardPDFLabeledText';

interface Props {
    title: string;
    orders: PlanItBulkATMOrderByWeekAndSku[];
}

const PlanItGuidedConfirmationPDF = ({ title, orders }: Props) => {
    const { t } = useTranslation();
    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const getShipToName = (shipToId: number) => {
        if (shipToAccounts) {
            let shipTo = shipToAccounts.find((acc) => acc.accountId === shipToId.toString());
            return shipTo ? shipTo.name : '';
        } else {
            return '';
        }
    };

    const getShipToAddress = (shipToId: number) => {
        if (shipToAccounts) {
            let shipTo = shipToAccounts.find((acc) => acc.accountId === shipToId.toString());
            return shipTo ? getAccountAddress(shipTo) : '';
        } else {
            return '';
        }
    };

    const getTotalQuantitySnoSku = (lines: PlanItBulkATMLineItem[], type: ProductType) => {
        let pallets = 0;
        lines?.forEach((line) => {
            if (line.type === type) {
                pallets += line.palletQuantity ? line.palletQuantity : 0;
            }
        });
        return pallets;
    };

    const getTotalEachesSnoSku = (lines: PlanItBulkATMLineItem[], type: ProductType) => {
        let eaches = 0;
        lines?.forEach((line) => {
            if (line.type === type) {
                eaches +=
                    line.palletQuantity && line.quantityPerPallet
                        ? line.palletQuantity * line.quantityPerPallet
                        : 0;
            }
        });
        return eaches;
    };

    const getTotalQuantityATM = (lines: PlanItBulkATMLinesBySnoSku[], type: ProductType) => {
        let pallets = 0;
        lines?.forEach((line) => {
            pallets += line.lines ? getTotalQuantitySnoSku(line.lines, type) : 0;
        });
        return pallets;
    };

    const getTotalQuantityATMFormatted = (
        lines: PlanItBulkATMLinesBySnoSku[],
        type: ProductType
    ) => {
        const quantity = getTotalQuantityATM(lines, type);
        return formatNumberWithLocale(cultureCode, quantity);
    };

    const getTotalEachesATM = (lines: PlanItBulkATMLinesBySnoSku[], type: ProductType) => {
        let eaches = 0;
        lines?.forEach((line) => {
            eaches += line.lines ? getTotalEachesSnoSku(line.lines, type) : 0;
        });
        return eaches;
    };

    const getTotalEachesATMFormatted = (lines: PlanItBulkATMLinesBySnoSku[], type: ProductType) => {
        const quantity = getTotalEachesATM(lines, type);
        return formatNumberWithLocale(cultureCode, quantity);
    };

    const getATMSkus = (lines: PlanItBulkATMLinesBySnoSku[], type: ProductType) => {
        let skus = 0;
        lines.forEach((line) => {
            if (line.lines[0].type === type) {
                skus += line.lines.length;
            }
        });
        return skus;
    };

    const sortByWeek = (orders: PlanItBulkATMOrderByWeekAndSku[]) => {
        orders.sort((a, b) => moment(a.weekStart).valueOf() - moment(b.weekStart).valueOf());
        return orders;
    };

    const columns = [
        t('productGraphicId', 'PRODUCT ID / GRAPHIC ID'),
        t('productDescription', 'PRODUCT DESCRIPTION'),
        t('coating', 'Coating'),
        t('forecastedQuantity', 'Forecasted Quantity')
    ];

    const getRowsForSnoSku = (
        lines: MakeItBulkATMLinesBySnoSku
    ): Array<string | string[] | ReactPdf.View>[] => {
        // extract row data from the lines (same number of fields as columns)
        return lines.lines.map((line) => [
            [(line.displayId ?? '') + ' / ' + (line.graphicIdAndVersion ?? '')],
            [line.displayName ?? ''],
            line.coating ?? '',
            [
                !!line.palletQuantity
                    ? formatNumberWithLocale(cultureCode, line.palletQuantity) + ' PL'
                    : '',
                !!line.palletQuantity && !!line.quantityPerPallet
                    ? formatNumberWithLocale(
                          cultureCode,
                          line.palletQuantity * line.quantityPerPallet
                      ) + ' ea.'
                    : ''
            ]
        ]);
    };

    // for each order (unique ship/week), create a header AND section for each SNOSKU
    const getOrderDisplay = (order: PlanItBulkATMOrderByWeekAndSku) => {
        return (
            <View style={{ marginBottom: '30px' }}>
                <StandardPDFFlex
                    justify="space-between"
                    style={{
                        paddingVertical: '8px',
                        borderBottom: '1px solid #B9D3DC',
                        borderTop: '1px solid #B9D3DC',
                        marginBottom: '8px'
                    }}
                >
                    <StandardPDFFlex>
                        <StandardPDFLabeledText
                            labelText={t('planIt', 'Plan It').toLocaleUpperCase()}
                            headerText={t('forecast', 'Forecast').toLocaleUpperCase()}
                            style={{ marginRight: '24px' }}
                        />
                        <StandardPDFLabeledText
                            labelText={t('forecastWeek', 'Forecast Week').toLocaleUpperCase()}
                            headerText={moment.utc(order.weekStart).format('MM/DD/YYYY')}
                        />
                    </StandardPDFFlex>
                    <StandardPDFFlex justify="flex-end">
                        <StandardPDFLabeledText
                            labelText={t('canQuantity', 'Can Quantity').toLocaleUpperCase()}
                            headerText={
                                getTotalQuantityATMFormatted(
                                    order.linesBySnoSku,
                                    ProductType.Cans
                                ) +
                                ` PL` +
                                (getATMSkus(order.linesBySnoSku, ProductType.Cans) > 0
                                    ? ' / ' +
                                      getATMSkus(order.linesBySnoSku, ProductType.Cans) +
                                      (getATMSkus(order.linesBySnoSku, ProductType.Cans) > 1
                                          ? ' SKUS'
                                          : ' SKU')
                                    : '')
                            }
                            textAlign="right"
                            style={{ marginRight: '40px' }}
                            subheaderLines={
                                getTotalQuantityATM(order.linesBySnoSku, ProductType.Cans) > 0
                                    ? getTotalEachesATMFormatted(
                                          order.linesBySnoSku,
                                          ProductType.Cans
                                      ) + ` ea.`
                                    : ''
                            }
                        />
                        <StandardPDFLabeledText
                            labelText={t('endQuantity', 'End Quantity').toLocaleUpperCase()}
                            headerText={
                                getTotalQuantityATMFormatted(
                                    order.linesBySnoSku,
                                    ProductType.Ends
                                ) +
                                ` PL` +
                                (getATMSkus(order.linesBySnoSku, ProductType.Ends) > 0
                                    ? ' / ' +
                                      getATMSkus(order.linesBySnoSku, ProductType.Ends) +
                                      (getATMSkus(order.linesBySnoSku, ProductType.Ends) > 1
                                          ? ' SKUS'
                                          : ' SKU')
                                    : '')
                            }
                            textAlign="right"
                            subheaderLines={
                                getTotalQuantityATM(order.linesBySnoSku, ProductType.Ends) > 0
                                    ? getTotalEachesATMFormatted(
                                          order.linesBySnoSku,
                                          ProductType.Ends
                                      ) + ` ea.`
                                    : ''
                            }
                        />
                    </StandardPDFFlex>
                </StandardPDFFlex>
                <StandardPDFFlex>
                    <StandardPDFLabeledText
                        labelText={t('shipToId', 'Ship To Id').toLocaleUpperCase()}
                        headerText={getShipToName(order.shipToId)}
                        subheaderLines={[
                            getShipToAddress(order.shipToId),
                            '#' + order.shipToId.toString()
                        ]}
                        headerSize={10}
                    />
                </StandardPDFFlex>
                {order.linesBySnoSku.map((lines) => (
                    <View style={{ marginVertical: '16px' }}>
                        <StandardPDFFlex justify="space-between" style={{ marginBottom: '8px' }}>
                            <StandardPDFLabeledText
                                labelText={t(
                                    'productAttributes',
                                    'Product Attributes'
                                ).toLocaleUpperCase()}
                                headerText={getSnoSkuDescription(
                                    isProductCanType(lines.lines[0].type),
                                    lines.lines[0]
                                )}
                            />
                            <StandardPDFLabeledText
                                labelText={t(
                                    'totalEnteredQuantity',
                                    'Total Entered Quantity'
                                ).toLocaleUpperCase()}
                                headerText={
                                    formatNumberWithLocale(
                                        cultureCode,
                                        getTotalQuantitySnoSku(lines.lines, ProductType.Cans) +
                                            getTotalQuantitySnoSku(lines.lines, ProductType.Ends)
                                    ) + ' PL'
                                }
                                textAlign="right"
                                subheaderLines={
                                    formatNumberWithLocale(
                                        cultureCode,
                                        getTotalEachesSnoSku(lines.lines, ProductType.Ends) +
                                            getTotalEachesSnoSku(lines.lines, ProductType.Cans)
                                    ) + ` ea.`
                                }
                            />
                        </StandardPDFFlex>

                        <StandardPDFTable
                            columns={columns}
                            rows={getRowsForSnoSku(lines)}
                            columnWidths={[30, 40, 10, 20]}
                            rightAlignLastColumn={true}
                        />
                    </View>
                ))}
            </View>
        );
    };

    const myDoc = (
        <StandardPDF title={title}>
            <View>{sortByWeek(orders).map((order) => getOrderDisplay(order))}</View>
        </StandardPDF>
    );

    if (!isIE()) {
        return <PdfPageTemplate title={title}>{myDoc}</PdfPageTemplate>;
    } else {
        return (
            <BlobProvider document={myDoc}>
                {({ blob, url, loading, error }) => {
                    if (blob && window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
                        (window.navigator as any).msSaveOrOpenBlob(blob);
                    }
                    return null;
                }}
            </BlobProvider>
        );
    }
};

export default PlanItGuidedConfirmationPDF;
