import React, { useState, useEffect } from 'react';
import {
    withStyles,
    makeStyles,
    Typography,
    ExpansionPanel as MuiExpansionPanel,
    ExpansionPanelSummary as MuiExpansionPanelSummary,
    ExpansionPanelDetails
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ContentLink from './ContentLink';
import sanitizeHtml from 'sanitize-html-react';
import { ContentSubSection } from '../../../../store/reducers/content-page';
import clsx from 'clsx';

interface Props {
    section: ContentSubSection;
    collapse?: boolean;
}

const useStyles = makeStyles((theme) => ({
    details: {
        display: 'block',
        padding: 0,
        marginBottom: '2.5em',
        marginLeft: '2.5em',
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0
        }
    },
    summaryIcon: {
        width: '1.5em',
        marginRight: '0.875em'
    },
    link: {
        marginTop: '1.5em'
    },
    description: {
        marginBottom: '1.25em'
    },
    expandIcon: {
        marginLeft: '1em',
        fontSize: 30
    },
    expandedIcon: {
        transform: 'rotate(180deg)'
    }
}));

const StyledExpansionPanel = withStyles((theme) => ({
    root: {
        boxShadow: 'none',
        borderRadius: '0',
        background: 'transparent',
        borderBottom: `5px solid ${theme.palette.info.light}`,
        '&:before': {
            display: 'none'
        }
    }
}))(MuiExpansionPanel);

const StyledExpansionPanelSummary = withStyles((theme) => ({
    root: {
        borderRadius: '0',
        padding: 0,
        minHeight: '4em',
        '&$expanded': {
            minHeight: '4em'
        }
    },
    content: {
        margin: '1.25em 0',
        alignItems: 'center'
    },
    expanded: {
        color: theme.palette.primary.main
    }
}))(MuiExpansionPanelSummary);

const Accordion = (props: Props) => {
    const collapseFlag = props.collapse;
    const classes = useStyles();
    const section = props.section;
    const link = section.links && section.links.length !== 0 ? section.links[0] : null;
    const [expand, setExpand] = useState<boolean | false>(false);

    const handlePanelClick = () => {
        setExpand(!expand);
    };

    useEffect(() => {
        setExpand(false);
    }, [collapseFlag, setExpand]);

    return (
        <StyledExpansionPanel expanded={expand} onChange={handlePanelClick} role="accordion-handle-expand">
            <StyledExpansionPanelSummary aria-controls="panel1a-content" id="panel1a-header">
                {section.image && section.image.imageUrl && (
                    <img
                        src={section.image.imageUrl}
                        alt={section.header + ' image'}
                        className={classes.summaryIcon}
                    ></img>
                )}
                <Typography>{section.header}</Typography>
                <ExpandMoreIcon
                    className={clsx(classes.expandIcon, { [classes.expandedIcon]: expand })}
                />
            </StyledExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.details}>
                <Typography
                    variant="body1"
                    component="p"
                    className={classes.description}
                    dangerouslySetInnerHTML={{ __html: sanitizeHtml(section.description) }}
                />
                <ContentLink link={link} />
            </ExpansionPanelDetails>
        </StyledExpansionPanel>
    );
};

export default Accordion;
