import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import { makeStyles, Grid, Collapse, IconButton } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import PendingPaymentsRow from './PendingPaymentsRow';
import { Invoice } from '../../../../store/reducers/make-it-summary';
import { getPaymentStatus } from '../../../../utility/helpers/pending-payment-helpers';
import {
    blackWeight,
    small,
    vanilla,
    boldWeight,
    medium,
    black
} from '../../../../themes/globalConstants';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { PendingPaymentsState } from '../../../../store/reducers/pending-payments';
import SubmitPaymentModal from './SubmitPaymentModal';
import { Order_Status } from '../../../../utility/services/orders-service';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import PendingPaymentColumns from './PendingPaymentsColumns';
import { generateKey } from '../../../../utility/helpers/order-helpers';
import { CustomerContextState } from '../../../../store/reducers/customer-context';

interface Props {
    invoices: Invoice[];
}

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        width: '100%',
        marginBottom: '2em',
        '& .MuiToolbar-root': {
            padding: '0 0.125em',
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                }
            }
        },
        '& .MuiTableCell-head': {
            justifyContent: 'space-between',
            fontSize: small,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main
        },
        '& .MuiTableSortLabel-root': {
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: small,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em',
            '& .MuiSvgIcon-root': {
                fontSize: 14
            }
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                '&:nth-child(1)': {
                    width: '13%'
                },
                '&:nth-child(2)': {
                    width: '13%'
                },
                '&:nth-child(3)': {
                    width: '22%'
                },
                '&:nth-child(5)': {
                    width: '13%'
                },
                '&:nth-child(7)': {
                    width: '14%'
                }
            }
        },
        '& .MuiTablePagination-root': {
            '& .MuiTablePagination-caption': {
                marginLeft: '1em',
                fontWeight: boldWeight,
                color: black
            },
        },
        '& .MuiTablePagination-input': {
            fontSize: medium
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    },
    warningContainer: {
        width: '98%'
    },
    warning: {
        padding: '.125em 1.5em .125em 2.125em',
        backgroundColor: vanilla
    },
    warningText: {
        textAlign: 'center',
        fontSize: medium,
        fontWeight: boldWeight
    },
    warningIcon: {
        width: 25
    }
}));

export default function PendingPaymentsGrid({ invoices }: Props) {
    const classes = useStyles();
    const [warning, setWarning] = useState(false); //show warning if a payment is due this week
    const [warningStatus, setWarningStatus] = useState('');
    const [open, setOpen] = useState(false);
    const [currentItem, setCurrentItem] = useState({} as Invoice);
    const [columns, setColumns] = useState<any>([]);
    const [delinquentInvoices, setDelinquentInvoices] = useState<Invoice[]>([]);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { submittedInvoices } = useTypedSelector<PendingPaymentsState>(
        (state) => state.pendingPaymentsDashboard
    );
    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );

    const warningIcon = (
        <img src={process.env.PUBLIC_URL + '/assets/Warning_Icon.svg'} className={classes.warningIcon} data-testid="warning_icon" alt="Warning Icon" />
    );

    const errorIcon = (
        <img src={process.env.PUBLIC_URL + '/assets/Error_Icon.svg'} className={classes.warningIcon} data-testid="error_icon" alt="Error Icon" />
    );

    const infoIcon = (
        <img src={process.env.PUBLIC_URL + '/assets/InfoTriangle_Icon.svg'} className={classes.warningIcon} data-testid="info_icon" alt="Info Icon" />
    );

    function getIcon(status: string) {
        switch (status) {
            case 'overdue':
                return errorIcon;
            case 'dueThisWeek':
                return warningIcon;
            case 'dueThisMonth':
                return infoIcon;
            default:
                return null;
        }
    }

    const CollapsibleHeader = () => {
        return (
            <Grid container justify="center">
                <Collapse
                    in={warning}
                    className={classes.warningContainer}
                >
                    <Alert
                        severity="warning"
                        data-testid="pending-payment-warning"
                        className={classes.warning}
                        icon={getIcon(warningStatus)}
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setWarning(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        <Trans
                            i18nKey="pendingPaymentsDeadlineWarning"
                            className={classes.warningText}
                        >
                            You have payment(s) nearing or past a deadline, which will impact your
                            order requested date if not paid by the due date. If you have paid,
                            please click "Click if Submitted" below to notify us.
                        </Trans>
                    </Alert>
                </Collapse>
            </Grid>
        );
    };

    const CustomToolbarOptions = new ToolbarOptions({
        customItems: <CollapsibleHeader />,
        exportButton: false,
        printButton: false,
        advancePagination: false,
        searchText: false
    });

    const handleOpenModal = () => {
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    const onSubmitPayment = (item: Invoice) => {
        setCurrentItem(item);
        handleOpenModal();
    };

    const getShipToName = (invoiceId?: string) => {
        if (shipToAccounts && invoices) {
            let filteredinvoices = invoices.filter((i) => invoiceId === i.invoiceId);
            const invoice = filteredinvoices[0];
            let filteredAccounts = shipToAccounts.filter(
                (shipToAccount) => invoice.shipToId === +shipToAccount.accountId
            );

            if (filteredAccounts[0]) {
                return filteredAccounts[0].name;
            }
        }
    };

    const CustomerShippingRow = ({ row }: { row: Partial<Invoice> }) => {
        if (row.status === Order_Status.Payment_Delinquent) {
            // only display invoices with payment due
            let disableBtn = false;
            if (submittedInvoices.length) {
                submittedInvoices.forEach((submittedInvoice) => {
                    row.invoiceId === submittedInvoice.invoiceId && (disableBtn = true);
                });
            }
            return (
                <PendingPaymentsRow
                    item={row as Invoice}
                    key={row.invoiceId}
                    onSubmitPayment={onSubmitPayment}
                    disableSubmit={disableBtn}
                />
            );
        } else {
            return null;
        }
    };

    useEffect(() => {
        setColumns(PendingPaymentColumns.getPendingPaymentColumns(cultureCode));
    }, [cultureCode]);

    useEffect(() => {
        if (invoices.length) {
            let priority = '';
            const flaggedInvoices = invoices.filter(
                (invoice) => invoice.status === Order_Status.Payment_Delinquent
            );
            flaggedInvoices.forEach(
                (invoice) => (invoice.shipToString = getShipToName(invoice.invoiceId))
            );
            setDelinquentInvoices(flaggedInvoices);

            if (flaggedInvoices.length) {
                for (let i = 0; i < flaggedInvoices.length; i++) {
                    const invoice = flaggedInvoices[i];
                    if (getPaymentStatus(invoice.paymentDueDate) === 'overdue') {
                        setWarning(true);
                        setWarningStatus('overdue');
                    } else if (getPaymentStatus(invoice.paymentDueDate) === 'dueThisWeek') {
                        setWarning(true);
                        priority = 'dueThisWeek';
                        setWarningStatus('dueThisWeek');
                    } else if (
                        getPaymentStatus(invoice.paymentDueDate) === 'dueThisMonth' &&
                        priority !== 'dueThisWeek'
                    ) {
                        setWarning(true);
                        setWarningStatus('dueThisMonth');
                    }
                }
            }
        } else {
            setDelinquentInvoices([]);
            setWarning(false);
            setWarningStatus('');
        }
    }, [invoices]);

    useTranslation();

    return (
        <Grid
            container
            className={classes.tableWrapper}
            key={generateKey('pending-payments-table')}
        >
            <DataGrid
                columns={columns}
                dataSource={delinquentInvoices}
                gridName="Pending Payments Grid"
                rowComponent={CustomerShippingRow}
                toolbarOptions={CustomToolbarOptions}
            />
            <SubmitPaymentModal open={open} onClose={handleCloseModal} item={currentItem} />
        </Grid>
    );
}
