import React from 'react';
import ReactPDF, { View, Text } from '@react-pdf/renderer';

interface Props {
    labelText: string;
    headerText: string;
    textAlign?: 'left' | 'right' | 'center';
    subheaderLines?: string | string[];
    headerSize?: number;
    subheaderSize?: number;
    headerWeight?: 'normal' | 'bold' | 'black';
    subheaderWeight?: 'normal' | 'bold' | 'black';
    style?: ReactPDF.Style;
}

const StandardPDFLabeledText = ({
    labelText,
    headerText,
    textAlign = 'left',
    headerSize = 16,
    subheaderSize = 10,
    headerWeight = 'bold',
    subheaderWeight = 'normal',
    subheaderLines = [],
    style = {}
}: Props) => {
    const alignItems =
        textAlign === 'left' ? 'flex-start' : textAlign === 'right' ? 'flex-end' : 'center';

    const getFont = (weight: 'normal' | 'bold' | 'black') => {
        switch (weight) {
            case 'black':
                return 'Lato Black';
            case 'bold':
                return 'Lato Bold';
            case 'normal':
            default:
                return 'Lato';
        }
    };

    return (
        <View style={{ ...style, fontFamily: 'Lato', display: 'flex', alignItems }}>
            <Text
                style={{
                    fontSize: '8px',
                    textTransform: 'uppercase',
                    fontFamily: 'Lato Bold',
                    color: '#404040'
                }}
            >
                {labelText}
            </Text>
            <Text style={{ fontFamily: getFont(headerWeight), fontSize: headerSize }}>
                {headerText}
            </Text>
            {[subheaderLines].flat().map((line) => (
                <Text style={{ fontSize: subheaderSize, fontFamily: getFont(subheaderWeight) }}>
                    {line}
                </Text>
            ))}
        </View>
    );
};

export default StandardPDFLabeledText;
