import { makeStyles, Menu, MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { ProductStatusChangeCode } from '../../../../store/reducers/manage-products';
import { ProductStatusChange } from '../../../../store/reducers/orders-dashboard';
import { FlagTypes, GetFeatureFlag } from '../../../../utility/helpers/feature-flag';
import { useManageProductsContext } from '../context/ManageProductsContext';

interface Props {
    onRequestDeactivation: () => void;
    onRequestActivation: () => void;
    onEditProductInfo: () => void;
    onMenuBlur: () => void;
    anchorEl: HTMLElement | null;
    statusChanges?: ProductStatusChange[];
}

const useStyles = makeStyles(() => ({
    menuWrapper: {
        '& .MuiPopover-paper': {
            marginTop: '3.2em',
            paddingTop: '0.5em',
            paddingBottom: '0.5em',
            marginRight: '2em',
            minWidth: '15em',
            borderTop: '1em'
        }
    }
}));

const ManageProductsContextMenu = ({
    onRequestDeactivation,
    onRequestActivation,
    onEditProductInfo,
    onMenuBlur,
    anchorEl,
    statusChanges
}: Props) => {
    const classes = useStyles();
    const [isActiveTab, setIsActiveTab] = useState<boolean>(true);
    const { selectedTab } = useManageProductsContext();
    const activateProductFeatureFlag = GetFeatureFlag(FlagTypes.ActivateProduct);
    const deactivateProductFeatureFlag = GetFeatureFlag(FlagTypes.DeactivateProduct);

    useEffect(() => {
        if (selectedTab) {
            setIsActiveTab(selectedTab === 'Active');
        }
    }, [selectedTab]);
    const requestDeactivationDisabled = () => {
        if (statusChanges) {
            // currently pending deactivation
            return statusChanges
                .map((statusChange) => statusChange.code)
                .includes(ProductStatusChangeCode.PSC00003)
                ? true
                : false;
        } else {
            return false;
        }
    };

    const requestActivationDisabled = () => {
        if (statusChanges) {
            // currently pending activation or plate change
            const statusChangesCodes = statusChanges.map((statusChange) => statusChange.code);
            const pendingActivationCodes = [
                ProductStatusChangeCode.PSC00002,
                ProductStatusChangeCode.PSC00004
            ];

            return pendingActivationCodes.some((code) => statusChangesCodes.includes(code))
                ? true
                : false;
        } else {
            return false;
        }
    };

    const requestEditProductInfoDisabled = () => {
        if (statusChanges) {
            // currently pending data change
            return statusChanges
                .map((statusChange) => statusChange.code)
                .includes(ProductStatusChangeCode.PSC00001)
                ? true
                : false;
        } else {
            return false;
        }
    };

    return (
        <Menu
            className={classes.menuWrapper}
            keepMounted
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: -110,
                vertical: 'top'
            }}
            onBlur={onMenuBlur}
        >
            {isActiveTab && !!deactivateProductFeatureFlag && (
                <MenuItem onClick={onRequestDeactivation} disabled={requestDeactivationDisabled()}>
                    <Trans i18nKey="requestDeactivation">Request Deactivation</Trans>
                </MenuItem>
            )}
            {!isActiveTab && !!activateProductFeatureFlag && (
                <MenuItem onClick={onRequestActivation} disabled={requestActivationDisabled()}>
                    <Trans i18nKey="requestActivation">Request Activation</Trans>
                </MenuItem>
            )}
            <MenuItem onClick={onEditProductInfo} disabled={requestEditProductInfoDisabled()}>
                <Trans i18nKey="editProductInfo">Edit Product Information</Trans>
            </MenuItem>
        </Menu>
    );
};

export default ManageProductsContextMenu;
