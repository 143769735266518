import React, { useState, useEffect } from 'react';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { Button, makeStyles, Paper, Grid, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import {
    blackWeight,
    boldWeight,
    ballGray,
    vanilla,
    ballBlue,
    medium,
    containerMaxWidth
} from '../../../themes/globalConstants';
import { DeliveryOrder, ShippingState } from '../../../store/reducers/shipping-dashboard';
import { useHistory } from 'react-router-dom';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { formatFromDatetime, formattedMTDate } from '../../../utility/helpers/date-helpers';
import moment from 'moment';
import DisableBackButton from '../../reusable/atoms/DisableBackButton';
import { Activity } from '../../../utility/auth/useSecurity';
import InfoSubheader from '../../reusable/molecules/InfoSubheader';
import { InfoSubheaderItem } from '../../../store/reducers/glp';
import CancelDeliveryOrderConfirmationGrid from './components/CancelDeliveryOrderConfirmationGrid';
import CancelDeliveryOrderConfirmationShareLinks from './components/CancelDeliveryOrderConfirmationShareLinks';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { usePrevious } from '../../../utility/helpers/react-util';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: '3em',
        padding: '2em',
        borderRadius: 4,
        maxWidth: containerMaxWidth
    },
    header: {
        marginTop: '1em',
        marginBottom: '1em'
    },
    actionBtn: {
        borderRadius: 'unset'
    },
    title: {
        fontWeight: blackWeight,
        color: ballGray
    },
    subTitle: {
        color: ballGray,
        fontSize: '1em',
        fontWeight: blackWeight,
        marginTop: '.5em'
    },
    warning: {
        padding: '.125em 1.5em .125em 2.125em',
        backgroundColor: vanilla,
        '& .MuiAlert-icon': {
            color: ballBlue
        }
    },
    alertText: {
        textAlign: 'center',
        fontSize: medium,
        fontWeight: boldWeight
    }
}));

const CancelDeliveryOrderConfirmation = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [shipToName, setShipToName] = useState<string>('');
    const [displayAddress, setDisplayAddress] = useState<string>('');
    const [subheaderItems, setSubheaderItems] = useState<InfoSubheaderItem[]>();
    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { deliveryOrder } = useTypedSelector<ShippingState>((state) => state.shippingDashboard);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const dashboardLink = '/dashboard';
    const history = useHistory();
    const prevCultureCode = usePrevious(cultureCode);

    DisableBackButton('/edit-shipments-configuration', '/edit-shipments-confirmation');

    const handleDashboardNavigation = () => {
        history.push(dashboardLink);
    };

    const footerActions = (
        <Button
            type="button"
            color="primary"
            variant="contained"
            data-testid="dashboard-btn"
            className={classes.actionBtn}
            onClick={handleDashboardNavigation}
        >
            <Trans i18nKey="myDashboard">My Dashboard</Trans>
        </Button>
    );

    const renderShipments = deliveryOrder?.shipments?.map((shipment, i) => {
        return (
            <CancelDeliveryOrderConfirmationGrid
                key={i}
                shipmentIndex={i}
                currentShipment={shipment}
            />
        );
    });

    const createSubheader = (deliveryOrder: DeliveryOrder) => {
        setSubheaderItems([
            {
                subtitle: t('cancelledDate', 'Cancelled Date'),
                body: formatFromDatetime(deliveryOrder.deliveryWeekDate as string)
            },
            {
                subtitle: t('quantity', 'Quantity'),
                body: deliveryOrder.totalOrderQuantity + ' PL',
                subtext: `${Number((deliveryOrder.canQuantityEaches) || 0 + (deliveryOrder.endQuantityEaches || 0)).toLocaleString()} ea.`
            },
            {
                subtitle: t('products', 'Products'),
                body:
                    deliveryOrder.totalOrderSkus +
                    ' ' +
                    (deliveryOrder.totalOrderSkus === 1 ? t('sku', 'SKU') : t('skus', 'SKUs'))
            }
        ] as InfoSubheaderItem[]);
    };

    useEffect(() => {
        if (deliveryOrder) {
            const accountInformation =
                shipToAccounts &&
                shipToAccounts.filter(
                    (account) => account.accountId === deliveryOrder.shipToId?.toString()
                );
            if (cultureCode !== prevCultureCode || !subheaderItems?.length) {
                createSubheader(deliveryOrder);
            }
            if (!displayAddress && !shipToName) {
                if (accountInformation && accountInformation[0]) {
                    setShipToName(accountInformation[0].name);
                    if (accountInformation[0].address) {
                        const shipToAddress = accountInformation[0].address;
                        setDisplayAddress(
                            shipToAddress.address1?.trim() +
                                ', ' +
                                shipToAddress.city?.trim() +
                                ', ' +
                                shipToAddress.stateProvince?.trim() +
                                ' ' +
                                shipToAddress.postalCode
                        );
                    }
                }
            }
        }
    }, [deliveryOrder, shipToAccounts, cultureCode]);

    return (
        <ProcessingPageTemplate
            actionFooter={{
                footerAction: footerActions,
                justify: 'center',
                sticky: true
            }}
            activity={Activity.NewOpenDeliveryOrders}
            banner={{
                header: t('shipIt', 'Ship It'),
                description: t(
                    'cancellationReceived',
                    'Thank you. Your cancellation has been received'
                ),
                thinBanner: true,
                displayDropdown: false,
                disableSelect: true
            }}
        >
            <Paper elevation={2} className={classes.paper}>
                <Grid container spacing={2} alignItems="flex-start">
                    <Grid container alignItems="baseline" className={classes.header}>
                        <Grid container item xs={6} justify="flex-start">
                            <Typography variant="h3" className={classes.title}>
                                <Trans i18nKey="deliveryOrderNumber">Delivery Order #</Trans>{' '}
                                {deliveryOrder?.deliveryOrderNumber}
                            </Typography>
                        </Grid>
                        <Grid container item xs={6} justify="flex-end">
                            {deliveryOrder && (
                                <CancelDeliveryOrderConfirmationShareLinks
                                    dateSubmitted={formattedMTDate(
                                        moment(deliveryOrder.submittedDate),
                                        'MM/DD/YYYY'
                                    )}
                                    deliveryOrder={deliveryOrder}
                                    shipToAddress={displayAddress}
                                    shipToName={shipToName}
                                />
                            )}
                        </Grid>
                        <Grid container item xs={12} justify="flex-start">
                            <Typography variant="h3" className={classes.subTitle}>
                                {t('status', 'Status')}: {t('cancelled', 'Cancelled')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        {subheaderItems && (
                            <InfoSubheader
                                shipToName={shipToName}
                                shipToAddress={displayAddress}
                                subheaderItems={subheaderItems}
                            />
                        )}
                    </Grid>
                </Grid>
                {renderShipments}
            </Paper>
        </ProcessingPageTemplate>
    );
};

export default CancelDeliveryOrderConfirmation;
