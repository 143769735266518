import React, { useState } from 'react';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { Activity } from '../../../utility/auth/useSecurity';
import { Trans, useTranslation } from 'react-i18next';
import { enUS } from '../../../utility/translations/locales/en-US/en-US';
import { useTypedSelector } from '../../../store/reducers/reducer';
import MyAccountDashboardTabs from './components/MyAccountDashboardTabs';
import { useHistory } from 'react-router';
import { selectIsLargeCustomer, selectIsPlanItOnly } from '../../../store/selectors';

const useStyles = makeStyles((theme) => ({
    main: {
        padding: '0',
        marginTop: '1.750em'
    },
    requestAccessBtn: {
        borderRadius: 'unset',
        marginRight: '2em'
    }
}));

export default function MyAccountDashboard() {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(true);
    const classes = useStyles();
    const history = useHistory();

    const onRequestAccess = () => {
        history.push('/request-access');
    };

    const isLargeCustomer = useTypedSelector<boolean>(selectIsLargeCustomer);
    const hasOnlyPlanItAccounts = useTypedSelector<boolean>(selectIsPlanItOnly);
    const canRequestAccess = !(isLargeCustomer && hasOnlyPlanItAccounts);

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('myAccount', 'My Account'),
                thinBanner: true,
                description: ''
            }}
            activity={Activity.OpenAccess}
            loading={loading}
        >
            <Grid container className={classes.main}>
                <Grid container justify="flex-end">
                    {canRequestAccess && (
                        <Button
                            type="button"
                            color="primary"
                            variant="outlined"
                            data-testid="request-access-btn"
                            onClick={onRequestAccess}
                            className={classes.requestAccessBtn}
                        >
                            <Trans i18nKey="requestAccess">${enUS.requestAccess}</Trans>
                        </Button>
                    )}
                </Grid>
                <MyAccountDashboardTabs dashboardLoading={setLoading} />
            </Grid>
        </ProcessingPageTemplate>
    );
}
