import { makeStyles, Grid, Typography, TextField, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import Modal from '../molecules/Modal';
import { useState, ReactNode, useEffect } from 'react';
import React, { ReactElement } from 'react';
import Button from '../atoms/Button';
import { Link } from 'react-router-dom';
import { brightGrey, boldWeight, xl, red, large } from '../../../themes/globalConstants';
import ImpersonationWarning from './ImpersonationWarning';

interface Props {
    title: ReactNode;
    open: boolean;
    logo?: boolean;
    subheader?: ReactNode;
    description?: ReactNode;
    cancelText?: ReactNode;
    continueText?: ReactNode;
    submitText?: ReactNode;
    navigationLink?: string;
    onClose?: () => void;
    saveProgress?: () => void;
    canSaveDraft?: boolean;
    closeModalBtn?: boolean;
    saveInput?: boolean;
    saveInputLabel?: ReactNode;
    saveInputDefaultValue?: string;
    saveDraft?: (name: string) => void;
    error?: boolean;
    errorLabel?: ReactNode;
    onCancel?: () => void;
    maxLength?: number;
    helperText?: ReactElement;
    showSpinner?: boolean;
    submitButtonClassName?: string;
    showImpersonationWarning?: boolean;
    impersonationWarningType?: 'DISCARD' | 'SAVE' | 'SUBMIT';
    disableContinue?: boolean;
    onSave?: () => void;
    scrapIt?: boolean;
    isPricingAcknowlege?: boolean;
}

const useStyles = makeStyles({
    modal: {
        '& .MuiPaper-root': {
            maxWidth: '40.5em'
        },
        '& .MuiDialog-paperScrollPaper': {
            borderRadius: 'unset',
            overflow: 'hidden'
        },
        '& .MuiDialogTitle-root': {
            justifyContent: 'left'
        }
    },
    modalWidth: {
        '& .MuiPaper-root': {
            maxWidth: '55.5em'
        }
    },
    content: {
        textAlign: 'center',
        letterSpacing: '1px',
        color: brightGrey
    },
    textBuffer: {
        marginTop: '2em'
    },
    text: {
        marginTop: '0.625em',
        fontSize: '1.5rem',
        fontWeight: boldWeight,
        textAlign: 'left'
    },
    subsection: {
        fontSize: xl,
        fontWeight: boldWeight,
        letterSpacing: '0.13px',
        textAlign: 'left'
    },
    buttonLink: {
        textDecoration: 'none'
    },
    button: {
        height: 'auto',
        marginTop: '1.875em',
        fontSize: large,
        fontWeight: 'normal',
        textTransform: 'capitalize',
        borderRadius: 'unset',
        minWidth: '10em',
        marginLeft: '1em'
    },
    scrapItButton: {
        height: 'auto',
        marginTop: '1.875em',
        fontSize: large,
        fontWeight: 'normal',
        textTransform: 'capitalize',
        borderRadius: 'unset',
        minWidth: '10em',
        marginLeft: '16em'
    },
    successIcon: {
        height: '6.25em',
        width: '6.25em',
        marginTop: '1.25em'
    },
    buttonContainer: {
        marginTop: '1em'
    },
    contentWrapper: {
        width: '100%',
        padding: '0 20px 30px 20px'
    },
    saveInput: {
        width: 500,
        margin: 'auto'
    },
    altBtnWrapper: {
        width: 550,
        margin: 'auto'
    },
    errorMessage: {
        color: red,
        marginTop: '0.25em'
    },
    actionBar: {
        margin: '1.5em 0',
        padding: '0'
    },
    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '0.5em'
    },
    actionButtons: {
        display: 'flex'
    }
});

const ConfirmationModal = (props: Props) => {
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(props.open);
    const [draftName, setDraftName] = useState<string>('');

    const onSaveDraftName = () => {
        if (props.saveDraft) {
            props.saveDraft(draftName);
        }
    };

    const closeConfirmationModal = () => {
        setOpen(false);
        if (props.onClose) {
            props.onClose();
        }
    };

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    useEffect(() => {
        if (props.saveInputDefaultValue) {
            setDraftName(props.saveInputDefaultValue);
        }
    }, [props.saveInputDefaultValue]);

    return (
        <>
            <Modal
                open={open}
                close={closeConfirmationModal}
                title={props.title}
                closeIcon={true}
                fullWidth={true}
                className={clsx(classes.modal, {
                    [classes.modalWidth]: props.isPricingAcknowlege
                })}
            >
                {props.showSpinner && (
                    <Grid container item xs={12} alignItems="center" className={classes.actionBar}>
                        <Grid container item xs={12} className={classes.spinningLoader}>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                )}
                {
                    <Grid container className={classes.content}>
                        <Grid item className={classes.contentWrapper} data-testid="confirm-modal">
                            {props.logo && (
                                <Grid item>
                                    <Typography>
                                        <img
                                            className={classes.successIcon}
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/assets/Confirmation_icon.svg'
                                            }
                                            alt="Confirmation Icon"
                                        />
                                    </Typography>
                                </Grid>
                            )}
                            {props.subheader && (
                                <Grid item className={classes.textBuffer}>
                                    <Typography
                                        className={classes.subsection}
                                        data-testid="subheader"
                                    >
                                        {props.subheader}
                                    </Typography>
                                </Grid>
                            )}
                            {props.description && (
                                <Grid item>
                                    <Typography
                                        className={props.logo ? classes.text : classes.subsection}
                                        data-testid="description"
                                    >
                                        {props.description}
                                    </Typography>
                                </Grid>
                            )}
                            {props.showImpersonationWarning && props.impersonationWarningType && (
                                <Grid container item>
                                    {props.impersonationWarningType === 'SAVE' && (
                                        <ImpersonationWarning
                                            showWarning={props.showImpersonationWarning}
                                            warningType={'SAVE'}
                                        />
                                    )}
                                    {props.impersonationWarningType === 'SUBMIT' && (
                                        <ImpersonationWarning
                                            showWarning={props.showImpersonationWarning}
                                            warningType={'SUBMIT'}
                                        />
                                    )}
                                    {props.impersonationWarningType === 'DISCARD' && (
                                        <ImpersonationWarning
                                            showWarning={props.showImpersonationWarning}
                                            warningType={'DISCARD'}
                                        />
                                    )}
                                    {props.impersonationWarningType !== 'DISCARD' &&
                                        props.impersonationWarningType !== 'SAVE' &&
                                        props.impersonationWarningType !== 'SUBMIT' && (
                                            <ImpersonationWarning
                                                showWarning={props.showImpersonationWarning}
                                                warningType={'SUBMIT'}
                                            />
                                        )}
                                </Grid>
                            )}
                            {props.saveInput && props.saveInputLabel && (
                                <Grid container item justify="center">
                                    <Grid item xs={7}>
                                        <TextField
                                            id="save-input"
                                            data-testid="save-input"
                                            label={props.saveInputLabel}
                                            variant="filled"
                                            fullWidth
                                            margin="normal"
                                            name="name"
                                            aria-label="name"
                                            defaultValue={draftName}
                                            onChange={(e) => {
                                                setDraftName(e.target.value);
                                            }}
                                            inputProps={{ maxLength: props.maxLength }}
                                            helperText={props.helperText}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                            {props.error && props.errorLabel && (
                                <Grid container item>
                                    <Grid item xs={12}>
                                        <Typography
                                            id="error-label"
                                            data-testid="error-label"
                                            className={classes.errorMessage}
                                        >
                                            {props.errorLabel}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid
                                item
                                xs={12}
                                alignItems="center"
                                container
                                justify="flex-end"
                                className={clsx(classes.buttonContainer, {
                                    [classes.altBtnWrapper]: props.saveInput
                                })}
                            >
                                <Grid
                                    item
                                    className={!props.scrapIt ? classes.actionButtons : undefined}
                                >
                                    <Grid item>
                                        {props.closeModalBtn && !props.saveInput && (
                                            <Button
                                                type="button"
                                                variant="outlined"
                                                className={classes.button}
                                                data-testid="close-btn"
                                                onClick={props.onCancel ?? closeConfirmationModal}
                                            >
                                                {props.cancelText}
                                            </Button>
                                        )}
                                        {props.cancelText && !props.onCancel && !props.saveInput && (
                                            <Link
                                                to={props.navigationLink || '/'}
                                                className={classes.buttonLink}
                                            >
                                                <Button
                                                    type="button"
                                                    variant="outlined"
                                                    color="secondary"
                                                    data-testid="cancel-btn"
                                                    className={classes.button}
                                                >
                                                    {props.cancelText}
                                                </Button>
                                            </Link>
                                        )}
                                        {props.cancelText && props.onCancel && !props.saveInput && (
                                            <Button
                                                type="button"
                                                variant="outlined"
                                                color="secondary"
                                                data-testid="cancel-btn"
                                                className={
                                                    !props.scrapIt
                                                        ? classes.button
                                                        : classes.scrapItButton
                                                }
                                                onClick={props.onCancel}
                                            >
                                                {props.cancelText}
                                            </Button>
                                        )}
                                        {props.saveInput && props.cancelText && (
                                            <Grid item xs={4}>
                                                <Button
                                                    type="button"
                                                    variant="outlined"
                                                    color="secondary"
                                                    data-testid="alt-cancel-btn"
                                                    onClick={closeConfirmationModal}
                                                    className={classes.button}
                                                >
                                                    {props.cancelText}
                                                </Button>
                                            </Grid>
                                        )}
                                    </Grid>
                                    {props.continueText && props.onCancel && (
                                        <Button
                                            type="button"
                                            variant="contained"
                                            color="primary"
                                            data-testid="continue-btn"
                                            onClick={
                                                !props.saveInput
                                                    ? props.saveProgress
                                                    : onSaveDraftName
                                            }
                                            className={classes.button}
                                            disabled={
                                                (props.saveInput && draftName.length === 0) ||
                                                props.disableContinue
                                            }
                                        >
                                            {props.continueText}
                                        </Button>
                                    )}
                                    {props.continueText && !props.onCancel && props.canSaveDraft && (
                                        <Button
                                            type="button"
                                            variant="contained"
                                            color="primary"
                                            data-testid="continue-btn"
                                            onClick={
                                                !props.saveInput
                                                    ? props.saveProgress
                                                    : onSaveDraftName
                                            }
                                            className={classes.button}
                                            disabled={
                                                props.saveInput && draftName.length === 0
                                                    ? true
                                                    : false
                                            }
                                        >
                                            {props.continueText}
                                        </Button>
                                    )}
                                    {!props.saveInput && props.saveInputLabel && (
                                        <Button
                                            type="button"
                                            variant="contained"
                                            color="primary"
                                            data-testid="saveMakeItChanges-btn"
                                            onClick={props.onSave}
                                            className={classes.button}
                                        >
                                            {props.saveInputLabel}
                                        </Button>
                                    )}
                                    {props.submitText && !props.saveInput && (
                                        <Link
                                            to={props.navigationLink || '/'}
                                            className={classes.buttonLink}
                                        >
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                data-testid="submit-btn"
                                                className={
                                                    props.submitButtonClassName ?? classes.button
                                                }
                                            >
                                                {props.submitText}
                                            </Button>
                                        </Link>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Modal>
        </>
    );
};

export default ConfirmationModal;
