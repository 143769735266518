import React from 'react';
import PasswordHelperText from './PasswordHelperText';

interface RegexProps {
    pattern: string;
    helpText: React.ReactNode;
    value: string;
    testId?: string;
}

export default function RegexPasswordHelperText({ pattern, helpText, value, testId }: RegexProps) {
    return <PasswordHelperText invalid={!value?.match(pattern)} helpText={helpText} testId={testId}/>;
}
