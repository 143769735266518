import { ProductionOrderForTable } from '../../components/pages/MakeItATMSummary/models/ProductionOrderForTable';
import {
    PLAN_IT_SUMMARY_LOADING,
    PLAN_IT_SUMMARY_LOADED,
    PLAN_IT_SUMMARY_ERROR,
    PLAN_IT_SUMMARY_UPDATE_COPY_TO_MAKE_IT_ORDERS,
    PLAN_IT_SUMMARY_CLEAR_COPY_TO_MAKE_IT_ORDERS
} from '../actions/action-types';
import { ProductionOrder } from './makeit-dashboard';

export interface PlanItSummaryState {
    orders: ProductionOrder[];
    loaded: boolean;
    forecastOrdersLoaded: boolean;
    error?: any;
    copyToMakeItOrders: ProductionOrderForTable[];
}

export interface InactiveProducts {
    forecastWeeks: string,
    ballProductId: string,
    productDescription: string
}

const initialState: PlanItSummaryState = {
    orders: [],
    forecastOrdersLoaded: false,
    loaded: false,
    error: undefined,
    copyToMakeItOrders: []
};

const planItSummary = (
    state: PlanItSummaryState = initialState,
    action: { type: string; [x: string]: any }
): PlanItSummaryState => {
    switch (action.type) {
        case PLAN_IT_SUMMARY_LOADING:
            return { ...state, loaded: false };
        case PLAN_IT_SUMMARY_LOADED:
            return { ...state, orders: action.orders, loaded: true, forecastOrdersLoaded: true };
        case PLAN_IT_SUMMARY_ERROR:
            return { ...state, error: action.error };
        case PLAN_IT_SUMMARY_UPDATE_COPY_TO_MAKE_IT_ORDERS:
            return { ...state, copyToMakeItOrders: action.copyToMakeItOrders };
        case PLAN_IT_SUMMARY_CLEAR_COPY_TO_MAKE_IT_ORDERS:
            return { ...state, copyToMakeItOrders: []}
        default:
            return state;
    }
};

export default planItSummary;
