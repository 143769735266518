import React from 'react';
import Modal from '../../../reusable/molecules/Modal';
import { Grid, Typography, makeStyles, CircularProgress } from '@material-ui/core';
import Button from '../../../reusable/atoms/Button';
import { Trans, useTranslation } from 'react-i18next';
import { boldWeight, xl } from '../../../../themes/globalConstants';
import ErrorAlert from '../../../reusable/atoms/ErrorAlert';

interface Props {
    open: boolean;
    submitting: boolean;
    onClose: () => void;
    onContinue: () => void;
    showError?: boolean;
}

const useStyles = makeStyles((theme) => ({
    confirmationText: {
        fontWeight: boldWeight,
        color: theme.palette.info.dark,
        fontSize: xl,
        margin: '2em'
    },
    buttonGrid: {
        paddingBottom: '2em'
    },
    modalButton: {
        width: '14.03em',
        height: '3.3em',
        margin: '0 1.5em'
    },
    actionBar: {
        margin: '1.5em 0',
        padding: '0'
    },
    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '0.5em'
    },
    saveButton: {
        width: '14.03em',
        height: '3.3em',
        marginLeft: '1.5em',
        marginRight: '2em'
    },
    errorDisplay: {
        margin: '1em'
    }
}));

const EditDeliveryOrderSubmitChangesModal = ({ open, submitting, onClose, onContinue, showError }: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    let modalTitle = t('changeDeliveryOrderModalTitle', 'You are about to change your delivery order');

    const onCancel = () => {
        onClose();
    };

    return (
        <Modal open={open} close={onClose} title={modalTitle} maxWidth="sm" closeIcon={true} fullWidth={true}>
            <Grid container>
                {submitting && (
                    <Grid container item xs={12} alignItems="center" className={classes.actionBar}>
                        <Grid container item xs={12} className={classes.spinningLoader}>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                )}
                {showError && (
                    <Grid container item xs={12} className={classes.errorDisplay}>
                        <ErrorAlert
                            errorMessage={t('errorProcessingShipment', 'There has been an error processing your edited Delivery Order.')}
                            showError={showError}
                            dataTestId="edit-shipment-validation-error"
                        />
                    </Grid>
                )}
                <Grid container item xs={12}>
                    <Typography align="left" className={classes.confirmationText}>
                        <Trans i18nKey="changeDeliveryOrderModalConfirmationText">Are you sure you want to save the changes to your delivery order?</Trans>
                    </Typography>
                </Grid>
                <Grid container className={classes.buttonGrid} justify="flex-end">
                    <Grid item>
                        <Button variant="outlined" color="secondary" onClick={onCancel} className={classes.modalButton} disabled={submitting}>
                            <Trans i18nKey="no">No</Trans>
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={onContinue} className={classes.saveButton} disabled={submitting}>
                            <Trans i18nKey="yesSave">Yes, Save</Trans>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default EditDeliveryOrderSubmitChangesModal;