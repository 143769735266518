import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';


function DisableBackButton (previousLink, currentLink) {
    const history = useHistory();

    useEffect(() => {
        history.listen((nextPath, action) => {
            if (action === "POP" && nextPath.pathname === previousLink){
                history.push(currentLink);
            }
        });
    });
};

export default DisableBackButton;