import React from 'react';
import { Grid, Typography, Button, makeStyles, TableRow, TableCell } from '@material-ui/core';
import { boldWeight, medium } from '../../../themes/globalConstants';
import { Trans, useTranslation } from 'react-i18next';
import Modal from './Modal';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import StandardTable from './StandardTable';
import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../utility/services/translation-service';
import { InactiveProducts } from '../../../store/reducers/plan-it-summary';

interface Props {
    productsWithWeeks: InactiveProducts[];
    onClose: () => void;
    onSubmit: () => void;
}

const useStyles = makeStyles((theme) => ({
    modal: {
        '& .MuiPaper-root': {
            maxWidth: '40.5em'
        },
        '& .MuiDialog-paperScrollPaper': {
            borderRadius: 'unset',
            overflow: 'hidden'
        },
        '& .MuiDialogTitle-root': {
            justifyContent: 'start'
        },
        '& .MuiDialogContent-root': {
            padding: '1.875em'
        },
        '& .MuiPaper-elevation1': {
            boxShadow: 'none'
        }
    },
    button: {
        height: 40,
        marginTop: 30,
        fontSize: 14,
        fontWeight: boldWeight,
        textTransform: 'uppercase',
        width: '100%'
    },
    label: {
        color: theme.palette.info.dark,
        fontSize: medium,
        marginBottom: '2.5em',
        marginTop: '1.2em'
    },
    subLabel: {
        color: theme.palette.info.dark,
        fontSize: medium,
        marginBottom: '1.143em',
        marginTop: '2.857em'
    }
}));

const InactiveProductsModal = (props: Props) => {
    const { t } = useTranslation();
    useTranslation();
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const cols = [
        createColumn('forecastWeeks', {
            dataType: ColumnDataType.String,
            label: TranslationService.getTranslatedText(cultureCode, 'forecastWeek')
        }),
        createColumn('ballProductId', {
            dataType: ColumnDataType.String,
            label: TranslationService.getTranslatedText(cultureCode, 'ballProductId')
        }),
        createColumn('productDescription', {
            dataType: ColumnDataType.String,
            label: TranslationService.getTranslatedText(cultureCode, 'productDescription')
        })
    ];

    const InactiveProductRow = ({ row }: { row: InactiveProducts }) => {
        if (row) {
            return (
                <TableRow>
                    <TableCell data-testid={'forecast-weeks'}>
                        <Typography display="block">{row.forecastWeeks}</Typography>
                    </TableCell>
                    <TableCell data-testid={'ball-product-id'}>
                        <Typography display="block">{row.ballProductId}</Typography>
                    </TableCell>
                    <TableCell data-testid={'product-description'}>
                        <Typography display="block">{row.productDescription}</Typography>
                    </TableCell>
                </TableRow>
            );
        } else {
            return null;
        }
    };

    return (
        <Modal
            open={true}
            close={props.onClose}
            title={t('inactiveProductsIdentified', 'Inactive Products Identified')}
            closeIcon={true}
            fullWidth={true}
            className={classes.modal}
        >
            {
                <>
                    <Grid item xs={12}>
                        <Typography className={classes.label} variant="body1">
                            <Trans i18nKey="inactiveProductsSubheader">
                                We have identified inactive products on one or more of the forecasts
                                you are trying to convert. These products will not be included on
                                your Make it. Please reach out to your Account Representative if you
                                have questions.
                            </Trans>
                        </Typography>
                    </Grid>
                    <StandardTable
                        gridName="Inactive Products"
                        columns={cols}
                        lines={props.productsWithWeeks}
                        rowComponent={InactiveProductRow}
                        columnWidths={[25, 30, 45]}
                        searchText={false}
                        enablePagination={false}
                        hideToolbar={true}
                        testId="inactive-product-modal-static-datatable"
                        className="modal-table"
                    />
                    <Grid item xs={12}>
                        <Typography className={classes.subLabel} variant="body1">
                            <Trans i18nKey="proceedWithConversionText">
                                Click continue to proceed with conversion.
                            </Trans>
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} alignItems="flex-end" justify="space-between">
                        <Grid item xs={3}>
                            <Button
                                type="button"
                                variant="outlined"
                                color="secondary"
                                className={classes.button}
                                onClick={props.onClose}
                                data-testid="cancel-btn"
                            >
                                <Trans i18nKey="cancel">Cancel</Trans>
                            </Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={props.onSubmit}
                                data-testid="continue-btn"
                            >
                                <Trans i18nKey="continue">Continue</Trans>
                            </Button>
                        </Grid>
                    </Grid>
                </>
            }
        </Modal>
    );
};

export default InactiveProductsModal;
