import moment from 'moment';
import React, { useEffect, useState } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import clsx from 'clsx';
import { makeStyles, Grid, Typography, Box } from '@material-ui/core';
import {
    ballLtGray,
    ballBlue,
    ltBlueGrey,
    ballLtBlue,
    paleGrey
} from '../../../themes/globalConstants';
import { useTranslation, Trans } from 'react-i18next';
import SingleDayPicker from '../atoms/SingleDayPicker';
import Button from '../atoms/Button';
import { calculateLeadTime } from '../../../utility/helpers/date-helpers';
import WarningAlert from '../atoms/WarningAlert';

interface Props {
    leadTime: number;
    leadTimeIncrement?: string;
    firstDay: moment.Moment | null;
    highlightedDay?: moment.Moment | null;
    warning?: boolean;
    warningMessage?: string;
    calculateOutsideRange?: boolean;
    onCancel: () => void;
    onOk: () => void;
    selectDate: (date: moment.Moment | null) => void;
}

const useStyles = makeStyles((theme) => ({
    legend: {
        height: '4.57em',
        width: '36.57em',
        border: `1px solid ${ltBlueGrey}`,
        borderRadius: 4
    },
    legendText: {
        marginLeft: '2em',
        color: ballBlue
    },
    dateLegendBox: {
        height: 44,
        width: 44,
        borderRadius: 4
    },
    day: {
        width: '100%',
        height: '100%',
        margin: '25%'
    },
    restrictedBox: {
        backgroundColor: paleGrey,
        marginLeft: 0
    },
    firstAvailableBox: {
        backgroundColor: ballLtBlue,
        border: `2px solid ${ballBlue}`
    },
    availableReleaseBox: {
        border: `1px solid ${ballLtGray}`
    },
    navBtn: {
        position: 'absolute',
        top: 18,
        lineHeight: 0.78,
        borderRadius: 3,
        padding: '6px 9px',
        color: ballBlue,
        fontSize: '2rem'
    },
    prevIcon: {
        transform: 'rotate(90deg)',
        left: 22
    },
    nextIcon: {
        transform: 'rotate(270deg)',
        right: 22
    },
    keyText: {
        marginLeft: '0.5em'
    },
    btnContainer: {
        paddingRight: '0.7em'
    },
    cancelBtn: {
        width: '6.43em',
        height: '2.86em'
    },
    okBtn: {
        width: '6.43em',
        height: '2.86em'
    }
}));

const SingleDaypickerModal = (props: Props) => {
    const classes = useStyles();

    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [warningMessage, setWarningMessage] = useState<string>('');
    const increment = props.leadTimeIncrement ? props.leadTimeIncrement : 'weeks';

    useTranslation();

    const isOutsideRange = (day) => {
        if (props.calculateOutsideRange && props.firstDay) {
            return day.isBefore(props.firstDay.startOf('day'));
        } else {
            const today = moment();
            const initialDay = calculateLeadTime(today, props.leadTime, increment);
            return day.isBefore(initialDay.startOf('day'));
        }
    };

    const getFirstMonth = () => {
        if (props.calculateOutsideRange && props.firstDay) {
            return moment(props.firstDay);
        } else {
            const today = moment();
            const initialDay = calculateLeadTime(today, props.leadTime, increment);
            const momentObj = moment(initialDay);
            return moment(momentObj);
        }
    };

    const handleDateChange = (newDate: moment.Moment | null) => {
        props.selectDate(newDate);
    };

    useEffect(() => {
        if (props.warning && props.warningMessage) {
            setShowWarning(props.warning);
            setWarningMessage(props.warningMessage);
        }
    }, [props.warning, props.warningMessage]);
    return (
        <div>
            <WarningAlert showWarning={showWarning} warningMessage={warningMessage} />
            <SingleDayPicker
                numberOfMonths={2}
                date={null}
                highlightedDay={props.highlightedDay}
                isFocused={true}
                keepOpen={true}
                isOutsideRange={isOutsideRange}
                firstMonthAvailable={getFirstMonth}
                onDateChange={(newDate) => handleDateChange(newDate)}
                firstDay={props.firstDay}
            />
            {/* LEGEND */}
            <Grid container>
                <Grid
                    container
                    item
                    xs={7}
                    alignItems="center"
                    spacing={0}
                    className={classes.legend}
                >
                    <Grid item xs={2}>
                        <Typography variant="subtitle2" className={classes.legendText}>
                            <Trans i18nKey="legend">Legend</Trans>
                        </Typography>
                    </Grid>
                    <Grid container item xs={1}>
                        <Box className={clsx(classes.dateLegendBox, classes.restrictedBox)}>
                            <Typography variant="body1" className={classes.day}>
                                <Trans i18nKey="day">Day</Trans>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body1" className={classes.keyText}>
                            <Trans i18nKey="restrictedDate">
                                Restricted
                                <br />
                                Date
                            </Trans>
                        </Typography>
                    </Grid>
                    <Grid container item xs={1}>
                        <Box className={clsx(classes.dateLegendBox, classes.firstAvailableBox)}>
                            <Typography variant="body1" className={classes.day}>
                                <Trans i18nKey="day">Day</Trans>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body1" className={classes.keyText}>
                            <Trans i18nKey="dateFirstAvailable">Date First Available</Trans>
                        </Typography>
                    </Grid>
                    <Grid container item xs={1}>
                        <Box className={clsx(classes.dateLegendBox, classes.availableReleaseBox)}>
                            <Typography variant="body1" className={classes.day}>
                                <Trans i18nKey="day">Day</Trans>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body1" className={classes.keyText}>
                            <Trans i18nKey="availableDate">
                                Available
                                <br />
                                Date
                            </Trans>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    item
                    xs={5}
                    justify="flex-end"
                    alignItems="center"
                    spacing={0}
                    className={classes.btnContainer}
                >
                    <Grid item xs={2}>
                        <Button
                            type="button"
                            variant="outlined"
                            color="secondary"
                            data-testid="cancel-btn"
                            onClick={props.onCancel}
                            className={classes.cancelBtn}
                        >
                            <Trans i18nKey="cancel">Cancel</Trans>
                        </Button>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={2}>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            data-testid="ok-btn"
                            onClick={props.onOk}
                            className={classes.okBtn}
                        >
                            <Trans i18nKey="ok">Ok</Trans>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default SingleDaypickerModal;
