import { FormHelperText, Grid, makeStyles } from '@material-ui/core';
import React, { ReactElement, useEffect, useState } from 'react';
import { ErrorMessage, useFormContext } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { red } from '../../../../themes/globalConstants';
import { enUS } from '../../../../utility/translations/locales';
import FormSection from '../../../reusable/molecules/FormSection';
import TextInput from '../../../reusable/molecules/TextInput';
import clsx from 'clsx';
import SelectInput from '../../../reusable/molecules/SelectInput';
import { naCountries } from '../../../../static-data/dropdown-lists';
import RadioInput from '../../../reusable/molecules/RadioInput';
import { stateDropdownSelector } from '../../../../utility/helpers/onboardHelpers';
import { Address, OnboardingDashboardState } from '../../../../store/reducers/onboarding-dashboard';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { OnboardingStepStatus } from '../../../../utility/services/onboarding-service';
import { postalCodePattern } from '../../../../utility/helpers/address-helpers';

interface Props {
    billingAddress?: Address;
    actualLocation?: Address;
}

const useStyles = makeStyles(() => ({
    helperText: {
        '& .MuiFormHelperText-root': {
            marginLeft: 0
        }
    },
    errorText: {
        color: red
    },
    inputLine: {
        marginBottom: '0.75em'
    },
    radioGroup: {
        marginTop: '1.5em',
        paddingLeft: '0.5em'
    },
    formSectionWrapper: {
        padding: '8px 8px 0px 8px'
    }
}));
export default function BillingAddress({ billingAddress, actualLocation }: Props): ReactElement {
    const classes = useStyles();
    const { control, errors, watch, setValue } = useFormContext();
    const [billStateDropdownList, setBillStateDropdownList] = useState<any[]>([]);
    const [actualStateDropdownList, setActualStateDropdownList] = useState<any[]>([]);
    const isRequired = <Trans i18nKey="required">Required</Trans>;

    const selectedBillCountry = watch('billingAddress.country');
    const selectedActualCountry = watch('actualLocation.country');
    const hasDifferentAddress = watch('hasSameRealAddress') === 'false';
    const usePreviouslyEnteredBillingAddress = watch('usePreviouslyEnteredBillingAddress');
    const [disableBillingAddress, setDisableBillingAddress] = useState<boolean>(false);

    const { billingAndShipping, accountStatus } = useTypedSelector<OnboardingDashboardState>(
        (state) => state.onboardingDashboardState
    );

    useEffect(() => {
        setAddressFields('billingAddress', billingAddress);
    }, [billingAddress]);

    const setAddressFields = (
        fieldName: 'billingAddress' | 'actualLocation',
        address: Address | undefined
    ) => {
        if (address) {
            setValue(`${fieldName}.name`, address.name);
            setValue(`${fieldName}.country`, address.country);
            setValue(`${fieldName}.addressLine1`, address.addressLine1);
            setValue(`${fieldName}.addressLine2`, address.addressLine2);
            setValue(`${fieldName}.addressLine3`, address.addressLine3);
            setValue(`${fieldName}.city`, address.city);
            setValue(`${fieldName}.state`, address.state);
            setValue(`${fieldName}.zipCode`, address.zipCode);
        }
    };

    useEffect(() => {
        if (actualLocation) {
            if (Object.keys(actualLocation).length > 0) {
                setValue('hasSameRealAddress', 'false');
            }
        }
    }, [actualLocation]);

    useEffect(() => {
        if (actualLocation && hasDifferentAddress) {
            setAddressFields('actualLocation', actualLocation);
        }
    }, [hasDifferentAddress, actualLocation]);

    useEffect(() => {
        if (selectedBillCountry) {
            stateDropdownSelector(selectedBillCountry, setBillStateDropdownList);
        }
    }, [selectedBillCountry]);

    useEffect(() => {
        if (selectedActualCountry) {
            stateDropdownSelector(selectedActualCountry, setActualStateDropdownList);
        }
    }, [selectedActualCountry]);

    useEffect(() => {
        if (usePreviouslyEnteredBillingAddress === 'true') {
            setDisableBillingAddress(true);
            setAddressFields(
                'billingAddress',
                billingAndShipping.billingAndShippingForm?.billingAddress
            );
        } else if (usePreviouslyEnteredBillingAddress === 'false') {
            setDisableBillingAddress(false);
            setAddressFields('billingAddress', {
                name: '',
                country: '',
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                city: '',
                state: '',
                zipCode: ''
            });
        }
    }, [usePreviouslyEnteredBillingAddress]);

    return (
        <FormSection
            testId={'billing-address-section'}
            sectionHeader={<Trans i18nKey="billingAddress">{enUS.billingAddress}</Trans>}
        >
            <Grid xs={12} className={classes.formSectionWrapper}>
                {accountStatus.addressesStatus === OnboardingStepStatus.COMPLETE && (
                    <Grid container xs={12} className={classes.inputLine}>
                        <Grid item xs={12}>
                            <RadioInput
                                styles={classes.radioGroup}
                                label={
                                    <Trans i18nKey="usePreviouslyEnteredBillingAddress">
                                        Use previously entered Billing Address?
                                    </Trans>
                                }
                                name="usePreviouslyEnteredBillingAddress"
                                testId="usePreviouslyEnteredBillingAddress"
                                control={control}
                                radioControls={[
                                    { value: 'true', label: <Trans i18nKey="yes">Yes</Trans> },
                                    { value: 'false', label: <Trans i18nKey="no">No</Trans> }
                                ]}
                                defaultValue=""
                            />
                        </Grid>
                    </Grid>
                )}
                <Grid container xs={12} className={classes.inputLine}>
                    <Grid item xs={6}>
                        <TextInput
                            type="text"
                            label={<Trans i18nKey="billMailingName">Bill Mailing Name</Trans>}
                            name="billingAddress.name"
                            testId="billingAddress.name"
                            control={control}
                            rules={{ required: isRequired }}
                            error={errors.billingAddress?.name}
                            errors={errors}
                            styles={classes.helperText}
                            readOnly={disableBillingAddress}
                            inputProps={{
                                'data-testid': 'billingAddress.name',
                                maxLength: 50
                            }}
                        />
                        <FormHelperText
                            className={clsx({ [classes.errorText]: !!errors.billingAddress?.name })}
                        >
                            {errors.billingAddress?.name?.type === 'required' && (
                                <ErrorMessage
                                    errors={errors}
                                    name={'billingAddress.name'}
                                    message={isRequired}
                                />
                            )}
                            {!errors.billingAddress?.name && (
                                <Trans i18nKey="billMailingNameHelper">
                                    Name of person who will be receiving communications
                                </Trans>
                            )}
                        </FormHelperText>
                    </Grid>
                </Grid>
                <Grid container xs={12} className={classes.inputLine}>
                    <Grid item xs={6}>
                        <SelectInput
                            label={<Trans i18nKey="billingCountry">Billing Country</Trans>}
                            name="billingAddress.country"
                            testId="billingAddress.country"
                            control={control}
                            rules={{ required: isRequired }}
                            error={errors.billingAddress?.country}
                            errors={errors}
                            styles={classes.helperText}
                            disabled={disableBillingAddress}
                            selectObj={naCountries}
                        />
                    </Grid>
                </Grid>
                <Grid xs={12} className={classes.inputLine}>
                    <TextInput
                        type="text"
                        label={<Trans i18nKey="billingAddressLine1">Billing Address Line 1</Trans>}
                        name="billingAddress.addressLine1"
                        testId="billingAddress.addressLine1"
                        control={control}
                        rules={{ required: isRequired }}
                        error={errors.billingAddress?.addressLine1}
                        errors={errors}
                        styles={classes.helperText}
                        readOnly={disableBillingAddress}
                        inputProps={{
                            'data-testid': 'billingAddress.addressLine1',
                            maxLength: 40
                        }}
                    />
                    <FormHelperText className={classes.errorText}>
                        {errors.billingAddress?.addressLine1?.type === 'required' && (
                            <ErrorMessage
                                errors={errors}
                                name={'billingAddress.addressLine1'}
                                message={isRequired}
                            />
                        )}
                    </FormHelperText>
                </Grid>
                <Grid xs={12} className={classes.inputLine}>
                    <TextInput
                        type="text"
                        label={
                            <Trans i18nKey="billingAddressLine2">
                                Billing Address Line 2 (Optional)
                            </Trans>
                        }
                        name="billingAddress.addressLine2"
                        testId="billingAddress.addressLine2"
                        control={control}
                        rules={''}
                        error={errors.billingAddress?.addressLine2}
                        errors={errors}
                        styles={classes.helperText}
                        readOnly={disableBillingAddress}
                        inputProps={{
                            'data-testid': 'billingAddress.addressLine2',
                            maxLength: 40
                        }}
                    />
                </Grid>
                <Grid xs={12} className={classes.inputLine}>
                    <TextInput
                        type="text"
                        label={
                            <Trans i18nKey="billingAddressLine3">
                                Billing Address Line 3 (Optional)
                            </Trans>
                        }
                        name="billingAddress.addressLine3"
                        testId="billingAddress.addressLine3"
                        control={control}
                        rules={''}
                        error={errors.billingAddress?.addressLine3}
                        errors={errors}
                        styles={classes.helperText}
                        readOnly={disableBillingAddress}
                        inputProps={{
                            'data-testid': 'billingAddress.addressLine3',
                            maxLength: 40
                        }}
                    />
                </Grid>
                <Grid container xs={12} className={classes.inputLine} spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            type="text"
                            label={<Trans i18nKey="city">City</Trans>}
                            name="billingAddress.city"
                            testId="billingAddress.city"
                            control={control}
                            rules={{ required: isRequired }}
                            error={errors.billingAddress?.city}
                            errors={errors}
                            styles={classes.helperText}
                            readOnly={disableBillingAddress}
                            inputProps={{
                                'data-testid': 'billingAddress.city',
                                maxLength: 25
                            }}
                        />
                        <FormHelperText className={classes.errorText}>
                            {errors.billingAddress?.city?.type === 'required' && (
                                <ErrorMessage
                                    errors={errors}
                                    name={'billingAddress.city'}
                                    message={isRequired}
                                />
                            )}
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput
                            label={<Trans i18nKey="stateProvince">State / Province</Trans>}
                            name="billingAddress.state"
                            testId="billingAddress.state"
                            control={control}
                            rules={{ required: isRequired }}
                            error={errors.billingAddress?.state}
                            errors={errors}
                            styles={classes.helperText}
                            disabled={disableBillingAddress}
                            selectObj={billStateDropdownList}
                        />
                    </Grid>
                </Grid>
                <Grid container xs={12} className={classes.inputLine} spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            type="text"
                            label={<Trans i18nKey="postalCode">Postal Code</Trans>}
                            name="billingAddress.zipCode"
                            testId="billingAddress.zipCode"
                            control={control}
                            rules={{ 
                                required: isRequired,
                                pattern: postalCodePattern,
                             }}
                            error={errors.billingAddress?.zipCode}
                            errors={errors}
                            styles={classes.helperText}
                            readOnly={disableBillingAddress}
                            inputProps={{
                                'data-testid': 'billingAddress.zipCode',
                                maxLength: 11
                            }}
                        />
                        <FormHelperText className={classes.errorText}>
                            {errors.billingAddress?.zipCode?.type === 'required' && (
                                <ErrorMessage
                                    errors={errors}
                                    name={'billingAddress.zipCode'}
                                    message={isRequired}
                                />
                            )}
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={6} />
                </Grid>
                <Grid container xs={12} className={classes.inputLine}>
                    <Grid item xs={6}>
                        <RadioInput
                            styles={classes.radioGroup}
                            label={
                                <Trans i18nKey="differentRealAddress">
                                    Is your actual location address the same as your billing
                                    address?
                                </Trans>
                            }
                            name="hasSameRealAddress"
                            testId="hasSameRealAddress"
                            control={control}
                            radioControls={[
                                { value: 'true', label: <Trans i18nKey="yes">Yes</Trans> },
                                { value: 'false', label: <Trans i18nKey="no">No</Trans> }
                            ]}
                            error={errors.hasSameRealAddress}
                            errors={errors}
                            defaultValue="true"
                        />
                    </Grid>
                </Grid>
                {hasDifferentAddress && (
                    <>
                        <Grid container xs={12} className={classes.inputLine}>
                            <Grid item xs={6}>
                                <TextInput
                                    type="text"
                                    label={
                                        <Trans i18nKey="actualMailingName">
                                            Actual Location Mailing Name
                                        </Trans>
                                    }
                                    name="actualLocation.name"
                                    testId="actualLocation.name"
                                    control={control}
                                    rules={{ required: isRequired }}
                                    error={errors.actualLocation?.name}
                                    errors={errors}
                                    styles={classes.helperText}
                                    inputProps={{
                                        'data-testid': 'actualLocation.name',
                                        maxLength: 50
                                    }}
                                />
                                <FormHelperText
                                    className={clsx({
                                        [classes.errorText]: !!errors.actualLocation?.name
                                    })}
                                >
                                    {errors.actualLocation?.name?.type === 'required' && (
                                        <ErrorMessage
                                            errors={errors}
                                            name={'actualLocation.name'}
                                            message={isRequired}
                                        />
                                    )}
                                    {!errors.actualLocation?.name && (
                                        <Trans i18nKey="billMailingNameHelper">
                                            Name of person who will be receiving communications
                                        </Trans>
                                    )}
                                </FormHelperText>
                            </Grid>
                        </Grid>
                        <Grid container xs={12} className={classes.inputLine}>
                            <Grid item xs={6}>
                                <SelectInput
                                    label={
                                        <Trans i18nKey="actualCountry">
                                            Actual Location Country
                                        </Trans>
                                    }
                                    name="actualLocation.country"
                                    testId="actualLocation.country"
                                    control={control}
                                    rules={{ required: isRequired }}
                                    error={errors.actualLocation?.country}
                                    errors={errors}
                                    styles={classes.helperText}
                                    selectObj={naCountries}
                                />
                            </Grid>
                        </Grid>
                        <Grid xs={12} className={classes.inputLine}>
                            <TextInput
                                type="text"
                                label={
                                    <Trans i18nKey="actualAddressLine1">
                                        Actual Location Address Line 1
                                    </Trans>
                                }
                                name="actualLocation.addressLine1"
                                testId="actualLocation.addressLine1"
                                control={control}
                                rules={{ required: isRequired }}
                                error={errors.actualLocation?.addressLine1}
                                errors={errors}
                                styles={classes.helperText}
                                inputProps={{
                                    'data-testid': 'actualLocation.addressLine1',
                                    maxLength: 40
                                }}
                            />
                            <FormHelperText className={classes.errorText}>
                                {errors.actualLocation?.addressLine1?.type === 'required' && (
                                    <ErrorMessage
                                        errors={errors}
                                        name={'actualLocation.addressLine1'}
                                        message={isRequired}
                                    />
                                )}
                            </FormHelperText>
                        </Grid>
                        <Grid xs={12} className={classes.inputLine}>
                            <TextInput
                                type="text"
                                label={
                                    <Trans i18nKey="actualAddressLine2">
                                        Actual Location Address Line 2 (Optional)
                                    </Trans>
                                }
                                name="actualLocation.addressLine2"
                                testId="actualLocation.addressLine2"
                                control={control}
                                rules={''}
                                error={errors.actualLocation?.addressLine2}
                                errors={errors}
                                styles={classes.helperText}
                                inputProps={{
                                    'data-testid': 'actualLocation.addressLine2',
                                    maxLength: 40
                                }}
                            />
                        </Grid>
                        <Grid xs={12} className={classes.inputLine}>
                            <TextInput
                                type="text"
                                label={
                                    <Trans i18nKey="actualAddressLine3">
                                        Actual Location Address Line 3 (Optional)
                                    </Trans>
                                }
                                name="actualLocation.addressLine3"
                                testId="actualLocation.addressLine3"
                                control={control}
                                rules={''}
                                error={errors.actualLocation?.addressLine3}
                                errors={errors}
                                styles={classes.helperText}
                                inputProps={{
                                    'data-testid': 'actualLocation.addressLine3',
                                    maxLength: 40
                                }}
                            />
                        </Grid>
                        <Grid container xs={12} className={classes.inputLine} spacing={2}>
                            <Grid item xs={6}>
                                <TextInput
                                    type="text"
                                    label={<Trans i18nKey="actualCity">Actual Location City</Trans>}
                                    name="actualLocation.city"
                                    testId="actualLocation.city"
                                    control={control}
                                    rules={{ required: isRequired }}
                                    error={errors.actualLocation?.city}
                                    errors={errors}
                                    styles={classes.helperText}
                                    inputProps={{
                                        'data-testid': 'actualLocation.city',
                                        maxLength: 25
                                    }}
                                />
                                <FormHelperText className={classes.errorText}>
                                    {errors.actualLocation?.city?.type === 'required' && (
                                        <ErrorMessage
                                            errors={errors}
                                            name={'actualLocation.city'}
                                            message={isRequired}
                                        />
                                    )}
                                </FormHelperText>
                            </Grid>
                            <Grid item xs={6}>
                                <SelectInput
                                    label={
                                        <Trans i18nKey="actualStateProvince">
                                            {' '}
                                            Actual Location State / Province
                                        </Trans>
                                    }
                                    name="actualLocation.state"
                                    testId="actualLocation.state"
                                    control={control}
                                    rules={{ required: isRequired }}
                                    error={errors.actualLocation?.state}
                                    errors={errors}
                                    styles={classes.helperText}
                                    selectObj={actualStateDropdownList}
                                />
                            </Grid>
                        </Grid>
                        <Grid container xs={12} className={classes.inputLine} spacing={2}>
                            <Grid item xs={6}>
                                <TextInput
                                    type="text"
                                    label={
                                        <Trans i18nKey="actualPostalCode">
                                            Actual Location Postal Code
                                        </Trans>
                                    }
                                    name="actualLocation.zipCode"
                                    testId="actualLocation.zipCode"
                                    control={control}
                                    rules={{ 
                                        required: isRequired,
                                        pattern: postalCodePattern,
                                     }}
                                    error={errors.actualLocation?.zipCode}
                                    errors={errors}
                                    styles={classes.helperText}
                                    inputProps={{
                                        'data-testid': 'actualLocation.zipCode',
                                        maxLength: 11
                                    }}
                                />
                                <FormHelperText className={classes.errorText}>
                                    {errors.actualLocation?.zipCode?.type === 'required' && (
                                        <ErrorMessage
                                            errors={errors}
                                            name={'actualLocation.zipCode'}
                                            message={isRequired}
                                        />
                                    )}
                                </FormHelperText>
                            </Grid>
                            <Grid item xs={6} />
                        </Grid>
                    </>
                )}
            </Grid>
        </FormSection>
    );
}
