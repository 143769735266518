import React, { useState, ReactNode, Fragment } from 'react';
import { Button, Menu, makeStyles, Grid, Typography } from '@material-ui/core';
import {
    black_2,
    boldWeight,
    medium,
    navBackgroundGray
} from '../../../themes/globalConstants';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { Region } from '../../../store/reducers/graphic-intake';
import clsx from 'clsx';
export class MenuSection {
    key: string;
    name?: string;
    items?: JSX.Element[];

    constructor(key: string) {
        this.key = key;
    }
}
interface Props {
    menuName: ReactNode;
    sections: MenuSection[];
    children: ReactNode;
    testid?: string;
}

const useStyles = makeStyles((theme) => ({
    popoverPaper: {
        width: '100%',
        maxHeight: 'unset',
        maxWidth: 'unset',
        top: '4.5em !important',
        left: '0px !important',
        borderRadius: 0,
        boxShadow: `0 1px 4px 0 ${black_2}`
    },
    button: {
        fontSize: medium,
        fontWeight: boldWeight,
        letterSpacing: '1px',
        textTransform: 'capitalize',
        color: theme.palette.common.black
    },
    selected: {
        borderBottom: '4px solid #009bbb'
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    container: {},
    southAmericaContainer: {
        maxWidth: '95em',
        marginLeft: 'auto',
        marginRight: 'auto',
        height: '27.5em'
    },
    menuName: {
        fontSize: medium,
        fontWeight: boldWeight,
        color: theme.palette.secondary.main,
        marginTop: '1.8em',
        marginBottom: '0.5em'
    },
    menuNameBar: {
        border: `2px solid ${theme.palette.info.light}`
    },
    navWrapper: {
        '&:focus': {
            outline: 'none'
        }
    },
    sideMenu: {
        backgroundColor: navBackgroundGray,
        padding: '0 40px 40px 40px'
    },
    contentContainer: {
        padding: '30px 30px 30px 60px'
    }
}));

const NavBarMenu = ({ menuName, sections, children, testid }: Props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const classes = useStyles();
    const { regionCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <div className={clsx(classes.buttonContainer, { [classes.selected]: !!anchorEl })}>
                <Button
                    onClick={handleClick}
                    aria-haspopup="true"
                    className={classes.button}
                    data-testid={testid && `${testid}-nav-btn`}
                >
                    {menuName}
                </Button>
            </div>

            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                classes={{ paper: classes.popoverPaper }}
            >
                <section role="navigation" className={classes.navWrapper}>
                    <Grid
                        container
                        className={
                            regionCode === Region.SA
                                ? classes.southAmericaContainer
                                : classes.container
                        }
                    >
                        <Grid item xs={9} className={classes.contentContainer}>
                            {children}
                        </Grid>
                        <Grid item xs={3} className={classes.sideMenu}>
                            {sections
                                .filter((section) => section.items && section.items.length > 0)
                                .map((section) => {
                                    return (
                                        <Fragment key={section.key}>
                                            <Typography className={classes.menuName}>
                                                {section.name}
                                            </Typography>
                                            <hr className={classes.menuNameBar} />
                                            {section.items}
                                        </Fragment>
                                    );
                                })}
                        </Grid>
                    </Grid>
                </section>
            </Menu>
        </>
    );
};

export default NavBarMenu;
