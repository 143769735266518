import React from 'react';
import { Typography, Grid, makeStyles } from '@material-ui/core';
import { ContentSection } from '../../../../store/reducers/content-page';
import VideoPlayer from '../../../reusable/molecules/VideoPlayer';

const useStyles = makeStyles((theme) => ({
    container: {
        textAlign: 'center',
        marginBottom: '3em',
        fontSize: '1.5rem'
    },
    header: {
        fontWeight: 700
    },
    pointer: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    box: {
        margin: 0,
        padding: 0
    },
    iframe: {
        margin: 0,
        padding: 0,
        border: 0
    },
    videoContainer: {
        position: 'relative',
        maxHeight: '20em',
        minWidth: '30em',
        minHeight: '20em',
        maxWidth: '30em',
        margin: 'auto'
    },
    video: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
    },
    subtitles: {
        marginTop: '1em',
        maxWidth: '50%'
    },
    subheader: {
        fontSize: '16px'
    }
}));

const SingleVideoContentSection = (section: ContentSection) => {
    const classes = useStyles();
    const embedLink: string = section.videoUrl.replace('/#/videos/', '/embed?id=');

    return (
        <>
            <Grid container item spacing={3} className={classes.container}>
                {section.header && (
                    <Grid item xs={12}>
                        <Typography variant="h2">{section.header}</Typography>
                    </Grid>
                )}
                {section.description && (
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.subheader}>
                            {section.description}
                        </Typography>
                    </Grid>
                )}
                <Grid
                    item
                    container
                    xs={12}
                    className={classes.videoContainer}
                    alignItems="center"
                    justify="center"
                >
                    {section.videoUrl && (
                        <div className={classes.videoContainer}>
                            <Grid
                                container
                                item
                                xs={12}
                                className={classes.videoContainer}
                                justify="center"
                            >
                                <VideoPlayer embedLink={embedLink} />
                            </Grid>
                        </div>
                    )}
                </Grid>
                <Grid item container xs={12} alignItems="center" justify="center">
                    <div className={classes.subtitles}>
                        <Typography variant="h4">{section.paragraph}</Typography>
                        <Typography variant="h5">{section.paragraph1}</Typography>
                        <Typography variant="h5">{section.paragraph2}</Typography>
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

export default SingleVideoContentSection;
