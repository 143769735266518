import { Product } from '../../store/reducers/product-portfolio';
import { Region } from '../../store/reducers/graphic-intake';
import { mdash } from '../../themes/globalConstants';

export const getProductDescriptionForRegion = (product: Product, regionCode: string) => {
    if (product.description) {
        return product.description;
    }

    let description =
        regionCode === Region.NA
            ? `${product.volumeOz}oz/${product.volumeML}ml ${mdash} ${product.style?.label}`
            : `${product.volumeML}ml ${mdash} ${product.style?.label}`;
    if (product.productCode === 'SLM_CN_8_250_NA_WN') {
        description += ` (Wine Only) `;
    }

    return description;
};

export const getProductDetailsHeader = (product: Product, regionCode: string) => {
    if (product.description) {
        return product.description;
    }

    let description =
        regionCode === Region.NA
            ? `${product?.volumeOz || ''}OZ/${product?.volumeML || ''}ML ${
                  product?.style?.label || ''
              }`
            : `${product?.volumeML || ''}ML ${product?.style?.label || ''}`;

    if (product.productCode === 'SLM_CN_8_250_NA_WN') {
        description += ` (Wine Only) `;
    }

    return description;
};

export const convertCapacityCheckProductCodeNCA = (productName: string) => {
    switch (productName) {
        case '12oz/355mL - Standard':
            return 'STD_CN_12_355_NA';
        case '375mL – Standard':
            return 'STD_CN_12_375_NA';
        case '16oz/473mL - Standard':
            return 'STD_CN_16_473_NA';
        case '19.2oz/568mL - Standard':
            return 'STD_CN_19_568_NA';
        case '7.5oz - Sleek':
            return 'SLK_CN_7_222_NA';
        case '8.0oz - Sleek':
            return 'SLK_CN_8_237_NA';
        case '12oz/355mL - Sleek':
            return 'SLK_CN_12_355_NA';
        case '5oz/170mL - Slim':
            return 'SLM_CN_5_170_NA';
        case '187mL - Slim':
            return 'SLM_CN_6_187_NA';
        case '6.8oz/200mL - Slim':
            return 'SLM_CN_6_200_NA';
        case '8.4oz - Slim':
            return 'SLM_CN_8_250_NA';
        case '250mL - Slim Wine Only':
            return 'SLM_CN_8_250_NA_WN';
        case '24oz - King':
            return 'KNG_CN_24_710_NA';
        case '25.4oz/750mL - King':
            return 'KNG_CN_25_750_NA';
        case '32oz - King':
            return 'KNG_CN_32_950_NA';
        case '12oz - Alumi-Tek® - Bottle':
            return 'ATK_BTL_12_355_NA';
        case '16oz - Alumi-Tek® - Bottle':
            return 'ATK_BTL_16_473_NA';
        case '24oz - Cap Can':
            return 'CAP_CN_24_710_NA';
        default:
            return productName;
    }
};

export const getAerosolProducts = () => [
    // {
    //     productCode: 'BEBO',
    //     sizeRange: { sizeRangeCode: '12TO17OZ' },
    //     style: {
    //         styleCode: 'IEB',
    //         label: 'Impact Extruded Bottle'
    //     },
    //     type: 'BOTTLE',
    //     description: '250ml-440ml - BeBo',
    //     maximumColors: 9,
    //     minOrderQuantityPallets: '29 - 41',
    //     quantityPerPallet: '2430 - 3400',
    //     quantityPerTruck: 6,
    //     sequence: 7,
    //     volumeML: 8,
    //     volumeOz: 9,
    //     windowHeightMM: '152 - 148mm',
    //     windowHeightInches: '152 - 148mm',
    //     windowWidthInches: '183mm',
    //     windowWidthMM: '183mm',
    //     flangedCanHeightInches: '151 - 228mm',
    //     flangedCanHeightMM: '151 - 228mm',
    //     bodyOutsideDiameterInches: '59mm',
    //     bodyOutsideDiameterMM: '59mm',
    //     content: {
    //         headerImage: getMediaPath(
    //             `/getattachment/af9ddae6-537a-4a10-9405-1f228abd4488/Bottle_BeBo_330ml-(2).png?lang=en-US&amp;width=3684&amp;height=1840&amp;ext=.png`
    //         ),
    //         canImage: getMediaPath(
    //             '/getattachment/16f9a1ff-5773-4dfd-8ed1-7972bada0c43/BEBO.png?lang=en-US&amp;width=2000&amp;height=1800&amp;ext=.png'
    //         ),

    //         schematicCanImage: getMediaPath(
    //             '/getattachment/60ebd7b4-9b5d-4370-aa6e-2af4629452d5/BeBo-(1).png?lang=en-US&amp;width=1500&amp;height=1500&amp;ext=.png'
    //         )
    //     },
    //     features: [
    //         {
    //             featureCode: 'BRE',
    //             type: 'INKS',
    //             label: 'Ball Resealable End'
    //         },
    //         {
    //             featureCode: 'COLDFILL',
    //             type: 'FILL',
    //             label: 'Cold Fill Compatible'
    //         },
    //         {
    //             featureCode: 'DYNAMARK',
    //             type: 'EFFECTS',
    //             label: 'Dynamark'
    //         },
    //         {
    //             featureCode: 'EYERIS',
    //             type: 'EFFECTS',
    //             label: 'Eyeris® High Definition'
    //         },
    //         {
    //             featureCode: 'HOTFILL',
    //             type: 'FILL',
    //             label: '"Hot Fill Compatible"'
    //         },
    //         {
    //             featureCode: 'MATTE',
    //             type: 'VARNISH',
    //             label: 'Matte'
    //         },
    //         {
    //             featureCode: 'PASTEURIZATION',
    //             type: 'FILL',
    //             label: 'Pasteurisation Compatible'
    //         },
    //         {
    //             featureCode: 'SATIN',
    //             type: 'VARNISH',
    //             label: 'Satin'
    //         }
    //     ]
    // },
    {
        productCode: 'BEBO2',
        productPortfolio: true,
        sizeRange: { sizeRangeCode: ['0TO12OZ', '12TO17OZ'] },
        style: {
            styleCode: 'IEB',
            label: 'Impact Extruded Bottle'
        },
        type: 'BOTTLE',
        description: '8oz-12oz - BeBo2',
        maximumColors: 9,
        minOrderQuantityPallets: '34 - 41',
        quantityPerPallet: '2,430 - 2,916',
        quantityPerTruck: 6,
        sequence: 7,
        volumeML: 8,
        volumeOz: 9,
        windowHeightMM: '58.242 - 100.5mm',
        windowHeightInches: '58.242 - 100.5mm',
        windowWidthInches: '183mm',
        windowWidthMM: '183mm',
        flangedCanHeightInches: '173 - 214mm',
        flangedCanHeightMM: '173 - 214mm',
        bodyOutsideDiameterInches: '59mm',
        bodyOutsideDiameterMM: '59mm',
        productCopy:
            'The BeBo2 pry-off “Impact” bottle is made with aluminum, the most recycled material on the planet, so your customers can feel good about the bottle, your beverage, and their environmental impact. These unbreakable impact extruded aluminum bottles are adapted to be filled on standard glass bottle filling lines.',
        content: {
            headerImage: `${process.env.REACT_APP_CONTENT_API}/getmedia/1d72c5d7-e559-4036-beef-dc6ba6b39882/Bottle_bebo2_8oz.png?width=3684&height=1840&ext=.png`,
            canImage: `${process.env.REACT_APP_CONTENT_API}/getmedia/3357b7f7-96e4-4d51-8a1a-ff3af9d590f8/BEBO2.png?width=2000&height=1800&ext=.png`,
            schematicCanImage: `${process.env.REACT_APP_CONTENT_API}/getmedia/62e41b1d-9c89-4776-8809-f8b6ced61e7f/BeBo2-250ml-8oz.png?width=1501&height=1501&ext=.png`
        },
        features: [
            {
                featureCode: 'COLDFILL',
                type: 'FILL',
                label: 'Cold Fill Compatible'
            },
            {
                featureCode: 'DYNAMARK',
                type: 'EFFECTS',
                label: 'Dynamark'
            },
            {
                featureCode: 'EYERIS',
                type: 'EFFECTS',
                label: 'Eyeris® High Definition'
            },
            {
                featureCode: 'HOTFILL',
                type: 'FILL',
                label: '"Hot Fill Compatible"'
            },
            {
                featureCode: 'MATTE',
                type: 'VARNISH',
                label: 'Matte'
            },
            {
                featureCode: 'PASTEURIZATION',
                type: 'FILL',
                label: 'Pasteurisation Compatible'
            },
            {
                featureCode: 'SATIN',
                type: 'VARNISH',
                label: 'Satin'
            }
        ]
    },
    {
        productCode: 'BEBO3',
        productPortfolio: true,
        sizeRange: { sizeRangeCode: ['0TO12OZ', '12TO17OZ'] },
        style: {
            styleCode: 'IEB',
            label: 'Impact Extruded Bottle'
        },
        type: 'BOTTLE',
        description: '8oz-16oz - BeBo3',
        maximumColors: 9,
        minOrderQuantityPallets: '26 - 41',
        quantityPerPallet: '2,430 - 3,888',
        quantityPerTruck: 6,
        sequence: 7,
        volumeML: 8,
        volumeOz: 9,
        windowHeightMM: '148 - 237mm',
        windowHeightInches: '148 - 237mm',
        windowWidthInches: '183mm',
        windowWidthMM: '183mm',
        flangedCanHeightInches: '151 - 228mm',
        flangedCanHeightMM: '151 - 228mm',
        bodyOutsideDiameterInches: '59mm',
        bodyOutsideDiameterMM: '59mm',
        productCopy:
            'The BeBo3 “Impact” bottle features a twist-off, tamper-evident ROPP closure that ensures and maintains beverage freshness. Made from infinitely recyclable impact extruded aluminum, these bottles are unbreakable and adapted to be filled on standard glass bottle filling lines.',
        content: {
            headerImage: `${process.env.REACT_APP_CONTENT_API}/getmedia/48c7969c-34ca-4a9f-b37a-fca230d08b87/Bottle_BeBo3_12oz.png?width=3684&height=1840&ext=.png`,
            canImage: `${process.env.REACT_APP_CONTENT_API}/getmedia/6d0f6c37-f400-40b6-923b-ebaf48daef29/Bottle_BeBo3_12oz-1.png?width=2000&height=1800&ext=.png`,
            schematicCanImage: `${process.env.REACT_APP_CONTENT_API}/getmedia/6d0f6c37-f400-40b6-923b-ebaf48daef29/Bottle_BeBo3_12oz-1.png?width=2000&height=1800&ext=.png`
        },
        features: [
            {
                featureCode: 'COLDFILL',
                type: 'FILL',
                label: 'Cold Fill Compatible'
            },
            {
                featureCode: 'DYNAMARK',
                type: 'EFFECTS',
                label: 'Dynamark'
            },
            {
                featureCode: 'EYERIS',
                type: 'EFFECTS',
                label: 'Eyeris® High Definition'
            },
            {
                featureCode: 'HOTFILL',
                type: 'FILL',
                label: '"Hot Fill Compatible"'
            },
            {
                featureCode: 'MATTE',
                type: 'VARNISH',
                label: 'Matte'
            },
            {
                featureCode: 'PASTEURIZATION',
                type: 'FILL',
                label: 'Pasteurisation Compatible'
            },
            {
                featureCode: 'SATIN',
                type: 'VARNISH',
                label: 'Satin'
            }
        ]
    },
    {
        productCode: 'ATB-E',
        productPortfolio: true,
        sizeRange: { sizeRangeCode: ['0TO12OZ', '12TO17OZ', '17OZPLS'] },
        style: {
            styleCode: 'IEB',
            label: 'Impact Extruded Bottle'
        },
        type: 'BOTTLE',
        description: '11oz-22oz - ATB-E',
        maximumColors: 9,
        minOrderQuantityPallets: '32 - 51',
        quantityPerPallet: '1,950 - 3,120',
        quantityPerTruck: 6,
        sequence: 7,
        volumeML: 8,
        volumeOz: 9,
        windowHeightMM: '144 - 240mm',
        windowHeightInches: '144 - 240mm',
        windowWidthInches: '183mm',
        windowWidthMM: '183mm',
        flangedCanHeightInches: '144 - 240mm',
        flangedCanHeightMM: '144 - 240mm',
        bodyOutsideDiameterInches: '66mm',
        bodyOutsideDiameterMM: '66mm',
        productCopy:
            'Made from infinitely recyclable impact extruded aluminum, the ATB-E “Impact” bottle is our solution for a sustainable and unbreakable beverage bottle. The impact extrusion process allows the ATB-E to be custom shaped, enabling greater differentiation, while the ROPP closure seals in freshness.',
        content: {
            headerImage: `${process.env.REACT_APP_CONTENT_API}/getmedia/47c707bb-7884-4eb3-a0b6-41f96be7c6fc/Bottle_ATB-E_12oz.png?width=3684&height=1840&ext=.png`,
            canImage: `${process.env.REACT_APP_CONTENT_API}/getmedia/c15053d8-4c0f-4b77-8bf3-73bd354d105d/Bottle_ATB-E_12oz-1.png?width=2000&height=1800&ext=.png`,
            schematicCanImage: `${process.env.REACT_APP_CONTENT_API}/getmedia/326fc6e6-55cd-4c77-8a4a-93470a54c869/ATB-E-12oz.png?width=1501&height=1501&ext=.png`
        },
        features: [
            {
                featureCode: 'COLDFILL',
                type: 'FILL',
                label: 'Cold Fill Compatible'
            },
            {
                featureCode: 'DYNAMARK',
                type: 'EFFECTS',
                label: 'Dynamark'
            },
            {
                featureCode: 'EYERIS',
                type: 'EFFECTS',
                label: 'Eyeris® High Definition'
            },
            {
                featureCode: 'HOTFILL',
                type: 'FILL',
                label: '"Hot Fill Compatible"'
            },
            {
                featureCode: 'MATTE',
                type: 'VARNISH',
                label: 'Matte'
            },
            {
                featureCode: 'PASTEURIZATION',
                type: 'FILL',
                label: 'Pasteurisation Compatible'
            },
            {
                featureCode: 'SATIN',
                type: 'VARNISH',
                label: 'Satin'
            }
        ]
    }
];
