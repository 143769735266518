import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthState } from '../../../store/reducers/auth';
import { useDispatch } from 'react-redux';
import { getKBMaxRegion } from '../../../utility/helpers/kbmax-helpers';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { addKbMaxListener } from '../../../utility/helpers/event-listener-helpers';

import KBMaxTemplate, { KBMaxConfig } from '../../templates/KBMaxTemplate';
import { CAPACITY_CHECK_CLEAR } from '../../../store/actions/action-types';
import { Region } from '../../../store/reducers/graphic-intake';

declare global {
    interface Window {
        kbmax: any;
    }
}

const useStyles = makeStyles((theme) => ({
    iframeWrapper: {
        height: 800
    },
    hidden: {
        visibility: 'hidden'
    },
    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '0.5em'
    }
}));

interface Props {
    kbMaxSettings?: KBMaxConfig;
}

export default function KBMaxCapacityCheck({ kbMaxSettings }: Props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { userInfo } = useTypedSelector<AuthState>((state) => state.auth);
    const { regionCode, cultureCode } = useTypedSelector<RegionCultureState>(
        (state) => state.regionCulture
    );

    const [hasKbMaxListener, setHasKbMaxListener] = useState<boolean>(false);
    const [settings, setSettings] = useState<KBMaxConfig>({
        elementId: 'thesource-kbmax-capacity-check',
        configurator: regionCode === Region.NA ? '37' : '1',
        showFields: true,
        showHeader: false,
        showConfigHeader: true,
        showDrawer: false,
        showMove: false,
        bindToForm: '',
        loadStyle: 'none',
        style: { height: '800px' }
    });

    const [header, setHeader] = useState<string>(
        t('compareBuildEstimate', 'Compare, Build & Estimate')
    );

    useEffect(() => {
        let config: any;
        if (window.kbmax && cultureCode && regionCode && userInfo) {
            let configurationFields = {};
            const prefix = 'Field - (From Source)';
            configurationFields[`${prefix} Locale`] = cultureCode;
            configurationFields[`${prefix} Region`] = getKBMaxRegion(regionCode);
            configurationFields[`${prefix} Name`] = userInfo.name;
            configurationFields[`${prefix} email`] = userInfo.email;
            configurationFields[`${prefix} Set Filtered Selections`] = true;
            configurationFields[`${prefix} Set UI Language`] = true;
            configurationFields[`${prefix} Show Get Estimate`] = false;

            config = new window.kbmax.ConfiguratorEmbed({
                elementId: settings.elementId,
                configuratorId: settings.configurator,
                configuredProductId: settings.configuredProductId,
                showFields: settings.showFields,
                showHeader: settings.showHeader,
                showConfigHeader: settings.showConfigHeader,
                showMove: settings.showMove,
                showDrawer: settings.showDrawer,
                bindToFormSelector: settings.bindToForm,
                loadStyle: settings.style,
                fields: configurationFields
            });

            if (!hasKbMaxListener && config) {
                addKbMaxListener(config, dispatch);
                setHasKbMaxListener(true);
            }
        }

        return () => {
            // clear config and state and removing listener on page leave
            config = null;
            window.removeEventListener('message', (e) => { });
            dispatch({ type: CAPACITY_CHECK_CLEAR });
        };
    }, [regionCode, cultureCode, userInfo]);

    useEffect(() => {
        if (kbMaxSettings) {
            setSettings(kbMaxSettings);
        }
    }, [kbMaxSettings]);

    useEffect(() => {
        setHeader(t('capacityCheck', 'Capacity Check'));
    }, [regionCode]);

    return (
        <KBMaxTemplate
            banner={{
                header: header,
                description: '',
                kbMaxBanner: true,
                displayDropdown: true
            }}
        >
            <div
                className={classes.iframeWrapper}
                id={settings.elementId}
            />
        </KBMaxTemplate>
    );
}
