import React, { useState, useEffect } from 'react';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import ManageProductsTabs from './components/ManageProductsTabs';
import { Activity, hasPersonasByShipTo, Persona } from '../../../utility/auth/useSecurity';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { shouldShowAtmBanner } from '../../../utility/helpers/banner-helpers';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import {
    loadProductInformation,
    unrecognizedProductsCancelUpdates
} from '../../../store/actions/manage-products';
import { useDispatch } from 'react-redux';
import { MANAGE_PRODUCTS_RESET } from '../../../store/actions/action-types';
import { selectIsLargeCustomerAccount, selectSelectedShipTo } from '../../../store/selectors';
import { ManageProductsState } from '../../../store/reducers/manage-products';
import { AuthState } from '../../../store/reducers/auth';

const useStyles = makeStyles((theme) => ({
    actionBtn: {
        height: '3.375em',
        width: '13.75em',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom'
    }
}));

export default function ManageProducts() {
    const dispatch = useDispatch();
    const location = useLocation();
    const classes = useStyles();
    const requiredPermissions = [
        Persona.AllAccess,
        Persona.MakeItOnly,
        Persona.OrderFulfillment,
        Persona.PlanItOnly
    ];

    const [loading, setLoading] = useState<boolean>(false);
    const { t } = useTranslation();
    const history = useHistory();
    const { regionCode, cultureCode } = useTypedSelector<RegionCultureState>(
        (state) => state.regionCulture
    );
    const { selectedAccountId, shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);
    const { error, isEdited, loaded } = useTypedSelector<ManageProductsState>(
        (state) => state.manageProductsState
    );
    const selectedShipTo = useTypedSelector(selectSelectedShipTo);
    const isLargeCustomerAccount = useTypedSelector(selectIsLargeCustomerAccount);
    const [lockPeriodWeeks, setLockPeriodWeeks] = useState<number>(0);
    const [showFooter, setShowFooter] = useState<boolean>(false);
    var [headerText, setHeaderText] = useState<string>(t('manageProducts', 'Manage Products'));

    const toggleFooterActions = (show: boolean) => {
        setShowFooter(show);
    };

    const handleCancel = () => {
        dispatch(unrecognizedProductsCancelUpdates());
    };

    const footerActions = (
        <Grid container item xs={12} justify="space-between">
            <Grid item xs={2}>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="cancel-button"
                    onClick={handleCancel}
                    className={classes.actionBtn}
                    disabled={!isEdited}
                >
                    <Trans i18nKey="cancel">Cancel</Trans>
                </Button>
            </Grid>
            <Grid item xs={2}>
                <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    data-testid="save-button"
                    className={classes.actionBtn}
                    onClick={() => {}}
                    disabled={!isEdited}
                >
                    <Trans i18nKey="save">Save</Trans>
                </Button>
            </Grid>
        </Grid>
    );

    useEffect(() => {
        if (requiredPermissions && selectedAccountId && permissions) {
            const accountIsAuthorized = hasPersonasByShipTo(
                requiredPermissions,
                selectedAccountId,
                permissions ?? []
            );
            if (!accountIsAuthorized) history.push('/unauthorized');
        }
    }, [requiredPermissions, selectedAccountId, permissions]);

    useEffect(() => {
        return () => {
            // Reset manage products state on page leave
            if (!history.location.pathname.includes('manage-products')) {
                dispatch({ type: MANAGE_PRODUCTS_RESET });
            }
        };
    }, [dispatch]);

    // Forces header text to update on language change
    useEffect(() => {
        setHeaderText(t('manageProducts', 'Manage Products'));
    }, [cultureCode]);

    useEffect(() => {
        if (selectedAccountId) {
            setLockPeriodWeeks(
                isLargeCustomerAccount
                    ? selectedShipTo?.lockedPeriodDays
                        ? (selectedShipTo?.lockedPeriodDays || 14) / 7
                        : 2
                    : 0
            );
            dispatch(loadProductInformation());
        }
    }, [selectedAccountId]);

    return (
        <ProcessingPageTemplate
            banner={{
                header: headerText,
                description: '',
                thinBanner: true,
                displayDropdown: true,
                shouldShowATMBanner: shouldShowAtmBanner(
                    shipToAccounts || [],
                    selectedAccountId || '',
                    location.pathname,
                    regionCode
                )
            }}
            activity={Activity.ManageProducts}
            loading={loading || !loaded}
            actionFooter={
                showFooter && {
                    footerAction: footerActions,
                    justify: 'space-between',
                    sticky: true
                }
            }
            errorMessage={
                error
                    ? 'There has been an error while getting your products. Please try again later.'
                    : ''
            }
        >
            <Grid
                container
                item
                xs={12}
                alignItems="center"
                justify="center"
                data-testid="error-message"
            ></Grid>
            <Grid container data-testid="tabs-container">
                <ManageProductsTabs
                    setDashboardLoading={setLoading}
                    dashboardLoading={loading}
                    lockPeriodWeeks={lockPeriodWeeks}
                    toggleFooterActions={toggleFooterActions}
                />
            </Grid>
        </ProcessingPageTemplate>
    );
}
