import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { makeStyles, Typography, TableRow, TableCell, Link } from '@material-ui/core';
import {
    ballBlue,
    blackWeight,
    boldWeight,
    ltBlueGrey_34,
    medium
} from '../../../../themes/globalConstants';
import { DeliveryOrderLinkedProductionOrder } from '../../../../store/reducers/shipping-dashboard';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { useDeliveryOrderDetailsATM } from '../context/DeliveryOrderDetailsContext';
import EachesConversion from '../../../reusable/atoms/EachesConversion';

interface Props {
    linkedProductionOrder?: DeliveryOrderLinkedProductionOrder;
    active: boolean;
    deliveryInstructions?: string;
    quantityPerPallet?: number;
}

const useStyles = makeStyles((theme) => ({
    name: {
        fontWeight: boldWeight,
        fontSize: medium,
        letterSpacing: 0.17
    },
    expand: {
        transform: 'rotate(-90deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: 'rotate(0)'
    },
    expandContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    expandableSection: {
        maxHeight: '15.125em',
        minHeight: '2.6em',
        overflowY: 'auto'
    },
    expandableSectionText: {
        padding: '7px'
    },
    standardRow: {
        '& > td:nth-child(n+4)': {
            textAlign: 'right'
        }
    },
    altRow: {
        backgroundColor: ltBlueGrey_34,
        '& > td': {
            borderBottomColor: 'white'
        },
        '& > td:nth-child(n+3)': {
            textAlign: 'right'
        }
    },
    hidden: {
        display: 'none'
    },
    display: {
        display: 'table-row'
    },
    borderlessCell: {
        borderLeft: 'unset'
    },
    activeCell: {
        backgroundColor: ltBlueGrey_34
    },
    activeRow: {
        boxShadow:
            '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 4px 3px 0px rgba(0,0,0,0.12)'
    },
    standardCell: {
        textAlign: 'left',
        '& :first-child': {
            fontWeight: blackWeight
        }
    },
    linkSpacer: {
        marginRight: '.25em'
    },
    spacer: {
        marginLeft: '1em',
        marginRight: '1em'
    },
    linkedPO: {
        textDecoration: `underline ${ballBlue}`
    }
}));

export default function DeliveryOrderExpandableSection({
    linkedProductionOrder,
    active,
    deliveryInstructions,
    quantityPerPallet
}: Props) {
    const classes = useStyles();
    const { isATM } = useDeliveryOrderDetailsATM();

    useTranslation();

    const setExpandableSectionInfo = (
        linkedProductionOrder?: DeliveryOrderLinkedProductionOrder
    ) => {
        return (
            <div className={classes.expandableSection}>
                <Typography
                    className={classes.expandableSectionText}
                    key={'linked-order-' + linkedProductionOrder?.productionOrderId}
                >
                    <Trans i18nKey="linkedProductionOrder">Production Order</Trans>
                    {' #'}:<span className={classes.linkSpacer}></span>
                    <Link
                        to={
                            isATM
                                ? `/make-it-po-order/${linkedProductionOrder?.productionOrderId}`
                                : `/production-order/${linkedProductionOrder?.productionOrderId}`
                        }
                        component={RouterLink}
                        underline="none"
                        data-testid="production-order-link"
                        className={clsx(classes.linkedPO, classes.linkSpacer)}
                    >
                        {isATM &&
                            linkedProductionOrder?.productionOrderNumber &&
                            linkedProductionOrder?.productionOrderNumber}
                        {isATM && linkedProductionOrder?.productionOrderNumber === undefined && (
                            <Trans i18nKey="pending">Pending</Trans>
                        )}
                        {!isATM && linkedProductionOrder?.customerProductionOrderId}
                    </Link>
                    <span className={classes.spacer}>|</span>
                    <Trans i18nKey="quantity">Quantity</Trans>
                    {': '}
                    {linkedProductionOrder?.quantity?.toLocaleString()} PL
                    {linkedProductionOrder?.quantity && quantityPerPallet && (
                        <>
                            {' / '}
                            <span data-testid="eaches-conversion">
                                <EachesConversion
                                    palletQuantity={linkedProductionOrder.quantity}
                                    quantityPerPallet={quantityPerPallet}
                                    label={true}
                                />
                            </span>
                        </>
                    )}
                    <span className={classes.spacer}>|</span>
                    <Trans i18nKey="deliveryInstructions">Delivery Instructions</Trans>
                    {': '}
                    {deliveryInstructions || ''}
                </Typography>
            </div>
        );
    };

    return (
        <TableRow
            className={clsx(classes.altRow, classes.hidden, {
                [classes.display]: active
            })}
        >
            <TableCell colSpan={12}>{setExpandableSectionInfo(linkedProductionOrder)}</TableCell>
        </TableRow>
    );
}
