import {
    ATM_PRODUCTION_ORDER_LOADING,
    ATM_PRODUCTION_ORDER_LOADED,
    ATM_PRODUCTION_ORDER_LOADING_ERROR,
    ATM_PRODUCTION_ORDER_RESET_STATE,
    ATM_PRODUCTION_ORDER_DELIVERY_ORDER_LOADING,
    ATM_PRODUCTION_ORDER_DELIVERY_ORDER_LOADED,
    ATM_PRODUCTION_ORDER_DELIVERY_ORDER_LOADING_ERROR
} from '../actions/action-types';
import { MakeItBulkATMOrder } from './makeit-bulk-atm';
import { LinkedDeliveryOrder } from './production-order-details';

export interface MakeItATMOrderDetailsState {
    atmProductionOrder?: MakeItBulkATMOrder;
    linkedDeliveryOrder?: LinkedDeliveryOrder;
    loading: boolean;
    error?: string;
}

export const initialState: MakeItATMOrderDetailsState = {
    atmProductionOrder: undefined,
    linkedDeliveryOrder: undefined,
    loading: false
};

const atmProductionOrderDetails = (state = initialState, action: any) => {
    switch (action.type) {
        case ATM_PRODUCTION_ORDER_LOADING:
            return {
                ...state,
                loading: true
            };
        case ATM_PRODUCTION_ORDER_LOADED:
            return {
                ...state,
                loading: false,
                loaded: true,
                atmProductionOrder: action.atmProductionOrder
            };
        case ATM_PRODUCTION_ORDER_LOADING_ERROR:
            return {
                ...state,
                details: undefined,
                loading: false,
                error: action.error
            };
        case ATM_PRODUCTION_ORDER_RESET_STATE:
            return {
                ...state,
                atmProductionOrder: action.atmProductionOrder
            };
        case ATM_PRODUCTION_ORDER_DELIVERY_ORDER_LOADING:
            return { ...state, loaded: false, loading: true };
        case ATM_PRODUCTION_ORDER_DELIVERY_ORDER_LOADED:
            return {
                ...state,
                loaded: true,
                loading: false,
                linkedDeliveryOrder: action.linkedDeliveryOrder
            };
        case ATM_PRODUCTION_ORDER_DELIVERY_ORDER_LOADING_ERROR:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
};

export default atmProductionOrderDetails;
