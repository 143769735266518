import { Grid, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import {
    boldWeight,
    blackWeight,
    ballBlue,
    dateOptionInvalidGray,
    small
} from '../../../../themes/globalConstants';
import TranslationService from '../../../../utility/services/translation-service';
import { getPendingRequestsRoles } from '../../../../utility/helpers/user-admin-helpers';
import MyAccountMyAccessColumns from './MyAccountMyAccessColumns';
import { AuthState } from '../../../../store/reducers/auth';
import {
    getShippingInformation,
    ShipToAccountInformation
} from '../../../../utility/helpers/address-helpers';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { MyAccessTableRow } from '../Models/MyAccessTableRow';
import MyAccountMyAccessRow from './MyAccountMyAccessRow';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: '1em'
    },
    tableWrapper: {
        '& .MuiToolbar-root': {
            padding: 0,
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                },
                '&:first-of-type ': {
                    paddingLeft: 0
                }
            },
            '& > h2': {
                fontSize: '1.5em',
                fontWeight: boldWeight,
                color: theme.palette.secondary.main,
                paddingLeft: '0.625em'
            },
            '& .MuiInputBase-formControl': {
                border: `1px solid ${dateOptionInvalidGray}`,
                borderRadius: '.125em',
                '&:before': {
                    display: 'none'
                },
                '&:after': {
                    display: 'none'
                },
                '& svg': {
                    height: '.875em',
                    width: '.875em',
                    fill: ballBlue
                },
                '& .MuiIconButton-root': {
                    '&:hover': {
                        backgroundColor: 'transparent !important'
                    }
                },
                '& .MuiInput-input': {
                    paddingLeft: '.25em'
                }
            }
        },
        '& .MuiTableSortLabel-root': {
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em',
            width: '99%',
            justifyContent: 'space-between',
            '& .MuiSvgIcon-root': {
                fontSize: 14,
                opacity: '1'
            }
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                '&:nth-child(1)': {
                    width: '30%'
                },
                '&:nth-child(2)': {
                    width: '50%'
                },
                '&:nth-child(3)': {
                    width: '20%',
                    fontSize: small,
                    fontWeight: blackWeight,
                    textTransform: 'uppercase',
                    color: theme.palette.secondary.main,
                    borderRadius: '0.25em',
                    paddingLeft: '0.5em',
                    justifyContent: 'space-between'
                }
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    }
}));

const MyAccountMyAccessTab = () => {
    const classes = useStyles();
    useTranslation();

    const { accounts } = useTypedSelector<CustomerContextState>((state) => state.customerContext);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);

    const [locationsForTable, setLocationsForTable] = useState<MyAccessTableRow[]>([]);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [columns, setColumns] = useState<any>([]);

    useEffect(() => {
        if (accounts && permissions) {
            const accountIds: string[] = [];
            permissions.forEach((permission) =>
                permission.accountIds.forEach((accountId) => {
                    if (!accountIds.includes(accountId)) {
                        accountIds.push(accountId);
                    }
                })
            );

            const locations: MyAccessTableRow[] = [];
            accountIds.forEach((accountId) => {
                const personaIds: string[] = [];
                permissions.forEach((permission) => {
                    if (permission.accountIds.includes(accountId)) {
                        personaIds.push(permission.personaId);
                    }
                });
                const accountInformation = getLocation(accountId);
                if (accountInformation) {
                    let formattedlocation: MyAccessTableRow = {
                        accountId: accountId,
                        location: accountInformation?.shipToName + ` (${accountId})`,
                        address: accountInformation?.displayAddress,
                        roles: getPendingRequestsRoles(personaIds, cultureCode)
                    };
                    locations.push(formattedlocation);
                }
            });
            setLocationsForTable(locations.sort((a, b) => a.location.localeCompare(b.location)));
            setIsLoaded(true);
        }
    }, [accounts, permissions]);

    const getLocation = (accountId: string): ShipToAccountInformation | undefined => {
        if (accounts) {
            const filteredAccount = accounts.find((account) => account.accountId === accountId);
            if (filteredAccount) {
                const locationInfo = getShippingInformation(
                    filteredAccount
                ) as ShipToAccountInformation;
                return locationInfo;
            }
        }
    };

    const options = new ToolbarOptions({
        title: TranslationService.getTranslatedText(cultureCode, 'myAccess'),
        advancePagination: false,
        exportButton: false,
        printButton: false,
        searchText: true
    });

    const MyAccessTableItem = ({ row }: { row: Partial<MyAccessTableRow> }) => {
        if (row) {
            return <MyAccountMyAccessRow location={row as MyAccessTableRow} key={row.accountId!} />;
        } else {
            return null;
        }
    };

    useEffect(() => {
        setColumns(MyAccountMyAccessColumns.getMyAccessColumns(cultureCode));
    }, [cultureCode]);

    return (
        <Paper elevation={2} className={classes.paper}>
            <Grid className={classes.tableWrapper}>
                {locationsForTable && isLoaded && (
                    <DataGrid
                        gridName="locations"
                        columns={columns}
                        dataSource={locationsForTable}
                        rowComponent={MyAccessTableItem}
                        toolbarOptions={options}
                    />
                )}
            </Grid>
        </Paper>
    );
};

export default MyAccountMyAccessTab;
