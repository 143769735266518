import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { Trans } from "react-i18next";
import { boldWeight, brightGrey, xl } from "../../../../themes/globalConstants";
import Button from "../../../reusable/atoms/Button";
interface Props {
    onContinue: () => void;
    onCancel: () => void;
}

const useStyles = makeStyles({
    modal: {
        '& .MuiPaper-root': {
            maxWidth: '40.5em'
        },
        '& .MuiDialog-paperScrollPaper': {
            borderRadius: 'unset',
            overflow: 'hidden'
        },
        '& .MuiDialogTitle-root': {
            justifyContent: 'center',
            marginLeft: '2.5em'
        }
    },
    content: {
        textAlign: 'center',
        letterSpacing: '1px',
        color: brightGrey
    },
    textBuffer: {
        marginTop: '2em'
    },
    text: {
        marginTop: 10,
        marginLeft: '1em',
        marginRight: '1em',
        fontSize: '1em',
        fontWeight: boldWeight
    },
    subsection: {
        fontSize: xl,
        fontWeight: boldWeight,
        letterSpacing: '0.13px'
    },
    buttonLink: {
        textDecoration: 'none'
    },
    button: {
        height: 40,
        marginTop: 10,
        marginBottom: 25,
        fontSize: 16,
        textTransform: 'capitalize',
        borderRadius: 'unset'
    },
    buttonContainer: {
        marginTop: '1em'
    },
    contentWrapper: {
        width: '100%'
    },
    altBtnWrapper: {
        width: 550,
        margin: 'auto'
    },
});

const ShipItConfigChangeDayModal = ({ onContinue, onCancel }: Props) => {
    const classes = useStyles();

    return (
        <Grid container justify="center" className={classes.content}>
            <Grid item className={classes.contentWrapper}>
                <Grid item>
                    <Typography
                        className={classes.text}
                        data-testid="description"
                    >
                        <Trans i18nKey="changeDeliveryDayWarning">
                            Changing your delivery date could require alterations to your shipment.  Do you want to continue?
                        </Trans>
                    </Typography>
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    alignItems="center"
                    justify="center"
                    className={classes.buttonContainer}
                >
                    <Grid item xs={4}>
                        <Button
                            type="button"
                            variant="outlined"
                            className={classes.button}
                            data-testid="close-btn"
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            type="button"
                            variant="outlined"
                            color="primary"
                            data-testid="cancel-btn"
                            className={classes.button}
                            onClick={onContinue}
                        >
                            Yes, Continue
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    )
}
export default ShipItConfigChangeDayModal;