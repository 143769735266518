import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography, TableRow, TableCell, Grid } from '@material-ui/core';
import { ProductionOrderLineItem } from '../../../../store/reducers/makeit-dashboard';
import {
    blackWeight,
    boldWeight,
    medium
} from '../../../../themes/globalConstants';
import {
    constructPDFCanDescription,
    constructPDFEndDescription,
    getGraphicIdAndVersion
} from '../../../../utility/helpers/order-helpers';
import { getUtcMidnight } from '../../../../utility/helpers/date-helpers';
import { getEachesConversion } from '../../../../utility/helpers/shipment-helpers';

interface Props {
    line: ProductionOrderLineItem;
}

const useStyles = makeStyles((theme) => ({
    name: {
        fontWeight: boldWeight,
        fontSize: medium,
        letterSpacing: 0.17
    },
    tableRowContainer: {
        '& p:first-of-type': {
            fontWeight: blackWeight
        },
        '& .MuiInputBase-input': {
            textAlign: 'right',
            paddingRight: '.25em'
        }
    }
}));

const CancelProductionOrderConfirmationRow = ({ line }: Props) => {
    const classes = useStyles();

    const getDesc = (line: ProductionOrderLineItem) => {
        return line.productType === 'END'
            ? constructPDFEndDescription(line)
            : constructPDFCanDescription(line);
    };

    const formatDate = (date?: string) => {
        if (date) {
            return getUtcMidnight(date).format('MM/DD/YYYY');
        } else {
            return '';
        }
    };

    useTranslation();

    return (
        <TableRow
            hover={true}
            key={line.productionOrderLineId}
            className={classes.tableRowContainer}
        >
            <TableCell>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        <Typography
                            key={'product-id-' + line.productionOrderLineId}
                            data-testid="display-id"
                        >
                            {line.displayId}
                        </Typography>
                        <Typography
                            key={'graphic-id-' + line.productionOrderLineId}
                            data-testid="graphic-id"
                        >
                            {getGraphicIdAndVersion(line.productType, line.productGraphicId)}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        <Typography
                            key={'product-desc-' + line.productionOrderLineId}
                            data-testid="display-name"
                        >
                            {line.displayName}
                        </Typography>
                        <Typography
                            key={'product-characteristics-' + line.productionOrderLineId}
                            data-testid="description"
                        >
                            {getDesc(line)}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        <Typography
                            key={'coating-' + line.productionOrderLineId}
                            data-testid="coating"
                        >
                            {line.productCoating}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        <Typography
                            key={'product-date-' + line.productionOrderLineId}
                            data-testid="product-date"
                        >
                            {formatDate(line.requestedDate)}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        <Typography
                            key={'product-quantity-' + line.productionOrderLineId}
                            data-testid="quantity"
                        >
                            {line.palletQuantity?.toLocaleString()} PL
                        </Typography>
                        <Typography>
                            {getEachesConversion(line.palletQuantity!, line.quantityPerPallet!)}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
        </TableRow>
    );
};

export default CancelProductionOrderConfirmationRow;
