import React, { useEffect, useState } from 'react';
import { makeStyles, Box, Typography, Tabs, Tab, Grid } from '@material-ui/core';
import {
    small,
    blackWeight,
    inactiveTabBlue,
    lato,
    xl,
    black,
    medium,
    white,
    ballDrkBlue
} from '../../../../themes/globalConstants';
import { useQuery } from '../../../../utility/helpers/query-helpers';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import TranslationService from '../../../../utility/services/translation-service';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { TabPanelProps } from '../../../../utility/helpers/summary-helpers';
import { OrderPlanningProduct } from '../../../../store/reducers/product-planning';
import ProductPlanningATMSupplyGrid from '../../ProductPlanningATM/components/ProductPlanningATMSupplyGrid';
import { PlanningAllocation } from '../../ProductPlanningATM/models/PlanningAllocation';
import ProductPlanningATMGrid from '../../ProductPlanningATM/components/ProductPlanningATMGrid';

interface Props {
    products: OrderPlanningProduct[];
    allocations: PlanningAllocation[];
    onViewFiltersClicked: (viewType: string[]) => void;
    onRangeFiltersClicked: (selectedRange: number) => void;
    onUnitsFiltersClicked: () => void;
    currentViewTypes: string[];
    selectedRange: number;
    currentUnit: string;
}

enum TabStatus {
    'productionBalance' = 0,
    'supplyPlan' = 1
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: '1.5em',
        position: 'relative',
        '& h2': {
            fontSize: xl,
            fontFamily: lato,
            fontWeight: blackWeight,
            color: theme.palette.secondary.main
        }
    },
    tabPanel: {
        width: '100%'
    },
    counter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '0.5em',
        fontSize: small,
        fontWeight: blackWeight
    },
    tabs: {},
    tabLabel: {
        backgroundColor: inactiveTabBlue,
        height: '56px',
        borderTopLeftRadius: '0.625em',
        borderTopRightRadius: '0.625em',
        '&:not(:first-of-type)': {
            marginLeft: '8px'
        },
        padding: '0 3em',
        color: ballDrkBlue,
        textTransform: 'none',
        maxWidth: 'none'
    },
    tabLabelText: {
        fontSize: medium,
        fontWeight: blackWeight
    },
    selectedTab: {
        backgroundColor: white,
        color: black,
        boxShadow: '0px 0px 6px rgba(185, 211, 220, 0.8), inset 0px -3px 0px #009BBB'
    }
}));

const TabPanel = (props: TabPanelProps) => {
    const classes = useStyles();
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`product-planning-atm-tabpanel-${index}`}
            aria-labelledby={`product-planning-atm-tab-${index}`}
            {...other}
            className={classes.tabPanel}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
};

//This assigns props for accessibility purposes
const a11yProps = (index: any) => {
    return {
        id: `product-planning-atm-tab-${index}`,
        'aria-controls': `product-planning-atm-tabpanel-${index}`
    };
};

const ProductPlanningTabs = ({
    products,
    allocations,
    onViewFiltersClicked,
    onRangeFiltersClicked,
    onUnitsFiltersClicked,
    selectedRange,
    currentViewTypes,
    currentUnit
}: Props) => {
    const classes = useStyles();
    const query = useQuery();
    const status = query.get('status');
    const initialValue = getValueFromStatus(status);
    const [value, setValue] = useState<number>(initialValue);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    // Handle tab selection change
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        const val = TabStatus[newValue];
        window.history.replaceState('', '', `?status=${val}`);
        setValue(newValue);
    };

    function getValueFromStatus(status) {
        return (TabStatus as any)[status?.toLowerCase() || ''] || 0;
    }

    useEffect(() => {
        const value = getValueFromStatus(status);
        setValue(value);
    }, [status]);

    // Generate Tab Label
    const TabLabel = (label: string) => {
        return (
            <Grid container justify="center" alignItems="center">
                <Typography className={classes.tabLabelText}>{label}</Typography>
            </Grid>
        );
    };

    return (
        <div className={classes.root} data-testid="product-planning-tabs">
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="product planning tabs"
                className={classes.tabs}
                scrollButtons="auto"
                variant="scrollable"
                indicatorColor="primary"
                textColor="primary"
            >
                <Tab
                    label={TabLabel(
                        TranslationService.getTranslatedText(cultureCode, 'productionBalance')
                    )}
                    {...a11yProps(0)}
                    className={`${classes.tabLabel} ${value === 0 && classes.selectedTab}`}
                    value={0}
                />
                <Tab
                    label={TabLabel(
                        TranslationService.getTranslatedText(cultureCode, 'supplyPlan')
                    )}
                    {...a11yProps(1)}
                    className={`${classes.tabLabel} ${value === 1 && classes.selectedTab}`}
                    value={1}
                />
            </Tabs>
            <TabPanel value={value} index={0}>
                <ProductPlanningATMGrid
                    products={products}
                    allocations={allocations}
                    onViewFiltersClicked={onViewFiltersClicked}
                    onRangeFiltersClicked={onRangeFiltersClicked}
                    onUnitsFiltersClicked={onUnitsFiltersClicked}
                    selectedRange={selectedRange}
                    stateName="productPlanning"
                    currentViewTypes={currentViewTypes}
                    currentUnit={currentUnit}
                />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ProductPlanningATMSupplyGrid
                    allocations={allocations}
                    onViewFiltersClicked={onViewFiltersClicked}
                    onRangeFiltersClicked={onRangeFiltersClicked}
                    onUnitsFiltersClicked={onUnitsFiltersClicked}
                    selectedRange={selectedRange}
                    stateName="productPlanning"
                    currentViewTypes={currentViewTypes}
                    currentUnit={currentUnit}
                />
            </TabPanel>
        </div>
    );
};

export default ProductPlanningTabs;
