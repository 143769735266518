import React from 'react';
import {
    Grid,
    makeStyles,
    Typography,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableBody
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Link from '../../../reusable/atoms/Link';
import { Trans } from 'react-i18next';
import moment from 'moment';
import { generateKey } from '../../../../utility/helpers/order-helpers';
import { getWeek } from '../../../../utility/helpers/date-helpers';
import { medium, blackWeight, xl } from '../../../../themes/globalConstants';
import {
    getOrderFirstRequestedDate,
    getRevisedAvailableDate
} from '../../../../utility/helpers/pending-payment-helpers';
import { formatNumberWithLocale } from '../../../../utility/helpers/formatters';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';

const useStyles = makeStyles((theme) => ({
    main: {
        fontSize: medium,
        padding: '1em'
    },
    weekText: {
        marginLeft: '1em'
    },
    columnHeaders: {
        fontSize: xl,
        fontWeight: blackWeight
    }
}));

const ProductionBalanceModal = ({ linkedPOs, weekNumber, isCiaAccount, quantityPerPallet }) => {
    const classes = useStyles();
    const date = getWeek(weekNumber);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    return (
        <Grid container className={classes.main}>
            <Grid item xs={12}>
                <Typography variant="body1" className={classes.weekText}>
                    <Trans i18nKey="weekOfWithDate">Week of {{ date }}</Trans>
                </Typography>
            </Grid>
            <Grid container item xs={12}>
                <Table
                    size="small"
                    aria-label="production order table"
                    key={generateKey('production-orders-table')}
                    data-testid="production-balance-table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell
                                colSpan={2}
                                className={classes.columnHeaders}
                                data-testid="production-orders"
                            >
                                <Trans i18nKey="productionOrders">Production Order(s)</Trans>
                            </TableCell>
                            {isCiaAccount && (
                                <TableCell
                                    colSpan={2}
                                    className={classes.columnHeaders}
                                    data-testid="original-date"
                                >
                                    <Trans i18nKey="originalRequestedDate">
                                        Original Requested Date
                                    </Trans>
                                </TableCell>
                            )}
                            <TableCell
                                colSpan={2}
                                className={classes.columnHeaders}
                                data-testid="requested-date"
                            >
                                <Trans i18nKey="requestedDate">Requested Date</Trans>
                            </TableCell>
                            {isCiaAccount && (
                                <TableCell
                                    colSpan={2}
                                    className={classes.columnHeaders}
                                    data-testid="revised-date"
                                >
                                    <Trans i18nKey="revisedAvailableDate">
                                        Revised Available Date
                                    </Trans>
                                </TableCell>
                            )}
                            <TableCell
                                colSpan={1}
                                align="right"
                                className={classes.columnHeaders}
                                data-testid="pallets"
                            >
                                <Trans i18nKey="pallets">Pallets</Trans>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {linkedPOs &&
                            linkedPOs.linkedProductionOrders.map((linkedPO, index) =>
                                linkedPO.lines.map((linkedLine) => (
                                    <TableRow key={'production-orders-table-row_' + index}>
                                        <TableCell colSpan={2}>
                                            <Link
                                                component={RouterLink}
                                                underline="always"
                                                to={`/production-order/${linkedPO.productionOrderId}`}
                                            >
                                                {linkedPO.productionOrderNumber ??
                                                    linkedPO.productionOrderId}
                                            </Link>
                                        </TableCell>
                                        {isCiaAccount && (
                                            <TableCell colSpan={2}>
                                                {linkedPO.invoice
                                                    ? getOrderFirstRequestedDate(linkedPO.invoice)
                                                    : '-'}
                                            </TableCell>
                                        )}
                                        <TableCell colSpan={2}>
                                            {moment(linkedLine.requestedDate).format('MM/DD/YYYY')}
                                        </TableCell>
                                        {isCiaAccount && (
                                            <TableCell colSpan={2}>
                                                {linkedPO.invoice
                                                    ? getRevisedAvailableDate(linkedPO.invoice)
                                                    : '-'}
                                            </TableCell>
                                        )}
                                        <TableCell align="right">
                                            {linkedLine.palletQuantity + ' PL'}

                                            <Typography
                                                variant="body1"
                                                className={classes.weekText}
                                            >
                                                {formatNumberWithLocale(
                                                    cultureCode,
                                                    linkedLine.palletQuantity * quantityPerPallet
                                                ) + ' ea.'}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    );
};

export default ProductionBalanceModal;
