import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LocalStorage } from 'tubular-common';

import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { Activity } from '../../../utility/auth/useSecurity';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { CustomerContextState, ProdOrderType } from '../../../store/reducers/customer-context';
import { makeItSummaryGridName } from '../../../themes/globalConstants';
import MakeItATMTabs from './components/MakeItATMTabs';
import { BulkUploadMakeItState } from '../../../store/reducers/makeit-bulk-upload';
import { loadMakeItBulkProducts } from '../../../store/actions/makeit-bulk-upload';
import { useHistory } from 'react-router';
import { getShipToInformation } from '../../../utility/helpers/summary-helpers';

export default function MakeItATMSummary() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const { selectedAccountId, shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { products, loading } = useTypedSelector<BulkUploadMakeItState>(
        (state) => state.bulkUploadMakeItState
    );

    const [pageLoading, setPageLoading] = useState<boolean>(true);
    const [storage] = useState<any>(new LocalStorage(makeItSummaryGridName));

    useEffect(() => {
        if (selectedAccountId && shipToAccounts) {
            const shipToAccount = getShipToInformation(shipToAccounts, selectedAccountId);
            if (
                shipToAccount &&
                shipToAccount.prodOrderType !== ProdOrderType.AuthorizationToManufacture
            ) {
                history.push('/make-it-summary');
            }
        }

        // Clears the search input on Ship to change
        if (storage.getTextSearch() !== '') {
            storage.setTextSearch('');
        }
    }, [history, selectedAccountId, shipToAccounts, storage]);

    // load products for allocation calculations
    useEffect(() => {
        if (!products && shipToAccounts?.length && !loading) {
            const shipToIds = shipToAccounts.map((account) => {
                return account.accountId;
            });
            dispatch(loadMakeItBulkProducts(shipToIds, true, false, false));
        }
    }, [dispatch, loading, products, shipToAccounts]);

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('makeIt', 'Make It'),
                description: t('productionOrderSummary', 'Production Order Summary').toUpperCase(),
                thinBanner: true,
                displayDropdown: true
            }}
            activity={Activity.MakeItATMSummary}
            loading={pageLoading}
            onlyCheckSelectedLargeAccount={true}
        >
            <Grid container data-testid="makeit-atm-summary">
                <MakeItATMTabs
                    selectedShipToId={selectedAccountId}
                    dashboardLoading={setPageLoading}
                />
            </Grid>
        </ProcessingPageTemplate>
    );
}
