export const loadState = (stateName: string) => {
    try {
        const serializedState = localStorage.getItem(stateName);
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState)
    } catch (err) {
        return undefined;
    }
}

export const saveState = (stateName: string, state: object) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem(stateName, serializedState);
    } catch (err) {
        // to define
    }
}

export const removeState = (stateName: string) => {
    try {
        localStorage.removeItem(stateName);
    } catch (err) {
        // to define
    }
}
