import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { Grid, makeStyles, Typography, Link } from '@material-ui/core';
import {
    blackWeight,
    boldWeight,
    medium,
    yellowNoticeBackground,
    yellowNoticeBackgroundBorder
} from '../../../themes/globalConstants';
import { useTypedSelector } from '../../../store/reducers/reducer';
import {
    loadProductPlanning,
    productPlanningFilter,
    productPlanningUpdateRange,
    productPlanningToggleQuantityUnit,
    resetProductPlanning
} from '../../../store/actions/product-planning';
import {
    Activity,
    getSecurityLevel,
    SecurityContext,
    SecurityLevel
} from '../../../utility/auth/useSecurity';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import Button from '../../reusable/atoms/Button';
import ProductPlanningTabs from './components/ProductPlanningTabs';
import {
    OrderPlanningProduct,
    ProductPlanningState,
    TotalNDayRanges
} from '../../../store/reducers/product-planning';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { FilterType, getFilteredAllocations } from '../../../utility/helpers/filter-helpers';
import { AuthState } from '../../../store/reducers/auth';
import { useQuery } from '../../../utility/helpers/query-helpers';
import { selectIsCopackerAccount, selectIsLargeCustomerAccount } from '../../../store/selectors';
import CopyToMakeItModal from '../../reusable/molecules/CopyToMakeItModal';
import { PLAN_IT_SUMMARY_CLEAR_COPY_TO_MAKE_IT_ORDERS } from '../../../store/actions/action-types';
import { ProductionOrder } from '../../../store/reducers/makeit-dashboard';
import { loadForecastOrders } from '../../../store/actions/plan-it-summary';
import { PlanItSummaryState } from '../../../store/reducers/plan-it-summary';
import moment from 'moment';
import { PlanningAllocation } from '../ProductPlanningATM/models/PlanningAllocation';
import {
    BulkUploadMakeItATMState,
    SnoSkuAllocationsByWeek
} from '../../../store/reducers/makeit-bulk-atm';
import {
    getAllocationsWithShipTosSpot,
    loadMakeItBulkProducts,
    refreshMakeItBulkState
} from '../../../store/root-actions';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '0'
    },
    main: {
        padding: '0',
        marginTop: '1.750em'
    },
    btnWrapper: {
        '& button:last-child': {
            marginLeft: '1.25em'
        }
    },
    actionBtn: {
        borderRadius: 'unset'
    },
    title: {
        fontWeight: blackWeight,
        color: theme.palette.secondary.main,
        padding: '0 0 1em 0',
        marginTop: '0.5em'
    },
    actionsWrapper: {
        alignItems: 'center',
        marginBottom: '1.750em'
    },
    boldText: {
        fontWeight: boldWeight
    },
    clockIcon: {
        width: '5%',
        marginRight: '3.75%',
        marginTop: '0.5%',
        float: 'left'
    },
    clockIconContainer: {
        backgroundColor: yellowNoticeBackground,
        borderWidth: 1,
        borderColor: yellowNoticeBackgroundBorder,
        borderStyle: 'solid',
        width: '30%',
        padding: '8px 16px'
    },
    actions: {
        justifyContent: 'flex-end',
        '& svg': {
            width: '100%',
            height: '100%'
        },
        '& a': {
            display: 'flex',
            fontSize: 12,
            fontWeight: boldWeight
        },
        '& :not(:last-child)': {
            marginRight: '1em'
        }
    },
    actionHeader: {
        color: theme.palette.secondary.main,
        fontWeight: boldWeight
    },
    iconWrapper: {
        width: '1.125em',
        marginLeft: '0.375em'
    },
    link: {
        fontSize: medium,
        marginLeft: '.25em'
    }
}));

export default function ProductPlanning() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const query = useQuery();
    const filteredRange = query.get('range');
    const expandAgedBalance = query.get('expand') ?? undefined;
    const makeItLink = '/make-it';
    const shipItLink = '/ship-it';
    const [filteredAllocations, setFilteredAllocations] = useState<PlanningAllocation[]>([]);
    const [openConvertPlanItModal, setOpenConvertPlanItModal] = useState<boolean>(false);
    const [forecastOrders, setForecastOrders] = useState<ProductionOrder[]>([]);
    const [currentShipTo, setCurrentShipTo] = useState<string | undefined>(undefined);
    const [currentViewTypes, setCurrentViewTypes] = useState<string[]>(['']);
    const [hasPOView, setHasPOView] = useState<boolean>(false);
    const [hasDOView, setHasDOView] = useState<boolean>(false);
    const [showClockIcon, setShowClockIcon] = useState<boolean>(false);
    const isCopacker = useTypedSelector<boolean>(selectIsCopackerAccount);

    const isLargeCustomerAccount = useTypedSelector<boolean>(selectIsLargeCustomerAccount);
    const { selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const {
        products,
        range,
        loading,
        view,
        filteredProducts,
        loaded,
        allocationsBySnoSku,
        quantityUnit
    } = useTypedSelector<ProductPlanningState>((state) => state.productPlanning);
    const { orders, forecastOrdersLoaded, error } = useTypedSelector<PlanItSummaryState>(
        (state) => state.planItSummary
    );
    const { allocations } = useTypedSelector<BulkUploadMakeItATMState>(
        (state) => state.bulkUploadMakeItATMState
    );
    const [allocationsForShipTo, setAllocationsForShipTo] = useState<
        SnoSkuAllocationsByWeek[] | undefined
    >(undefined);
    const allocationsLoaded = useTypedSelector<BulkUploadMakeItATMState>(
        (state) => state.bulkUploadMakeItATMState
    ).loaded;
    const auth = useTypedSelector<AuthState>((state) => state.auth);

    const securityContext = useMemo<SecurityContext>(() => {
        return { accountId: selectedAccountId } as SecurityContext;
    }, [selectedAccountId]);

    const handleNavigation = (destination: string) => {
        history.push(destination);
    };

    const handleViewFiltersClicked = (viewType: string[]) => {
        setCurrentViewTypes(viewType);
    };

    const handleRangeFiltersClicked = useCallback(
        (selectedRange: number) => {
            if (range !== selectedRange) {
                dispatch(productPlanningUpdateRange(selectedRange));
            }
        },
        [range]
    );

    const handleUnitsFiltersClicked = () => {
        dispatch(productPlanningToggleQuantityUnit());
    };

    const handlePermissions = useCallback(() => {
        // Checks for view/none level permissions for Production Orders
        const poSecurityLevel = getSecurityLevel(
            Activity.NewOpenProductionOrders,
            auth,
            securityContext
        );
        setHasPOView(
            SecurityLevel.None === poSecurityLevel || SecurityLevel.View === poSecurityLevel
        );

        // Checks for view/none level permissions for Delivery Orders
        const doSecurityLevel = getSecurityLevel(
            Activity.NewOpenDeliveryOrders,
            auth,
            securityContext
        );
        setHasDOView(
            SecurityLevel.None === doSecurityLevel || SecurityLevel.View === doSecurityLevel
        );
    }, [auth, securityContext]);

    useEffect(() => {
        if (selectedAccountId && currentShipTo && selectedAccountId !== currentShipTo) {
            // user selected a different shipTo - refresh state
            setFilteredAllocations([]);
            setCurrentShipTo(undefined);
            setAllocationsForShipTo(undefined);
            dispatch(refreshMakeItBulkState());
            dispatch(resetProductPlanning());
        }
    }, [selectedAccountId, currentShipTo]);

    useEffect(() => {
        if (selectedAccountId && !loaded && !allocationsLoaded) {
            // ensure state is cleared before triggering a reload
            setCurrentShipTo(selectedAccountId);
        }
    }, [selectedAccountId, loaded, allocationsLoaded]);

    useEffect(() => {
        if (products?.length) {
            const agedProducts = products.filter((product) => product.isAged);
            if (!agedProducts.length) {
                setShowClockIcon(false);
            } else {
                setShowClockIcon(true);
            }
        }
    }, [showClockIcon, products, setShowClockIcon]);

    useEffect(() => {
        if (allocations) {
            setAllocationsForShipTo(allocations);
        }
    }, [allocations]);

    useEffect(() => {
        if (currentShipTo) {
            let startDate = moment().startOf('isoWeek').format('M/D/YYYY');
            let endDate = moment().add(17, 'weeks').startOf('isoWeek').format('M/D/YYYY');
            setAllocationsForShipTo(undefined);
            dispatch(
                getAllocationsWithShipTosSpot(startDate, endDate, [Number(currentShipTo)], false)
            );
        }
    }, [dispatch, currentShipTo]);

    useEffect(() => {
        if (allocationsForShipTo && currentShipTo) {
            dispatch(
                loadProductPlanning(
                    TotalNDayRanges.seventeenWeeks,
                    allocationsForShipTo,
                    expandAgedBalance
                )
            );
        }
    }, [dispatch, allocationsForShipTo, currentShipTo, expandAgedBalance]);

    useEffect(() => {
        if (selectedAccountId && products?.length) {
            if (isLargeCustomerAccount && !isCopacker) {
                history.push('/po-product-planning');
            }
            handlePermissions();
        }
    }, [selectedAccountId, allocations]);

    useEffect(() => {
        allocationsBySnoSku?.length &&
            setFilteredAllocations(getFilteredAllocations(view!, allocationsBySnoSku, products));
    }, [allocationsBySnoSku]);

    useEffect(() => {
        if (JSON.stringify(currentViewTypes) !== JSON.stringify(view)) {
            dispatch(productPlanningFilter(currentViewTypes, FilterType.View));
            if (allocationsBySnoSku) {
                const filteredAlloc = getFilteredAllocations(
                    currentViewTypes,
                    allocationsBySnoSku,
                    products
                );
                setFilteredAllocations(filteredAlloc);
            }
        }
    }, [currentViewTypes]);

    useEffect(() => {
        if (auth.permissions && auth.permissions.length) {
            handlePermissions();
        }
    }, [handlePermissions, auth, selectedAccountId]);

    useEffect(() => {
        if (currentShipTo) {
            dispatch(loadForecastOrders([parseInt(currentShipTo)]));
            dispatch(loadMakeItBulkProducts([currentShipTo], true, true, false));
        }
    }, [currentShipTo]);

    useEffect(() => {
        if (orders && forecastOrdersLoaded) {
            const activeOrders = orders.filter((order) =>
                moment(order.atmWeekStart).isSameOrAfter(moment())
            );
            setForecastOrders(activeOrders);
        }
    }, [orders, forecastOrdersLoaded]);

    useEffect(() => {
        if (filteredRange) {
            handleRangeFiltersClicked(parseInt(filteredRange));
        }
    }, [filteredRange]);

    useEffect(() => {
        return () => {
            dispatch(resetProductPlanning());
        };
    }, []);

    useTranslation();

    const onClose = () => {
        dispatch({ type: PLAN_IT_SUMMARY_CLEAR_COPY_TO_MAKE_IT_ORDERS });
        setOpenConvertPlanItModal(false);
    };

    const handlePlanItLinkClick = () => {
        setOpenConvertPlanItModal(true);
    };

    const onSubmit = () => {
        setOpenConvertPlanItModal(false);
        history.push('/make-it?from=summary');
        // All of the orders are loaded into the copyToMakeItOrders array in the Plan It Summary State on routing
    };

    const planItToMakeItLink = (
        <Link
            component="button"
            underline="none"
            className={classes.link}
            onClick={handlePlanItLinkClick}
            data-testid="plan-it-conversion-link"
        >
            <Trans i18nKey="copyToMakeIt">Copy To Make It</Trans>
        </Link>
    );

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('productPlanning', 'Product Planning'),
                description: t('emptyString', ''),
                thinBanner: true,
                displayDropdown: true
            }}
            activity={Activity.PlanningPage}
            loading={loading}
            fallbackActivity={Activity.PlanningPageATM}
            fallbackPath={'/po-product-planning'}
        >
            <Grid
                container
                className={classes.actionsWrapper}
                justify="space-between"
                data-testid="product-plan"
            >
                <Grid container item md={12} className={classes.main}>
                    <Grid container item xs={12} justify="flex-end" className={classes.btnWrapper}>
                        <Button
                            type="button"
                            color="primary"
                            variant="outlined"
                            data-testid="make-it-button"
                            className={classes.actionBtn}
                            onClick={() => handleNavigation(makeItLink)}
                            disabled={hasPOView}
                        >
                            <Trans i18nKey="makeIt">Make It</Trans>
                        </Button>
                        <Button
                            type="button"
                            color="primary"
                            variant="outlined"
                            data-testid="ship-it-button"
                            className={classes.actionBtn}
                            onClick={() => handleNavigation(shipItLink)}
                            disabled={hasDOView}
                        >
                            <Trans i18nKey="shipIt">Ship It</Trans>
                        </Button>
                    </Grid>
                    <Grid container item md={12} className={classes.main} justify="flex-end">
                        {planItToMakeItLink}
                    </Grid>
                    {showClockIcon && (
                        <Grid
                            container
                            justify="flex-start"
                            alignItems="flex-start"
                            className={classes.clockIconContainer}
                        >
                            <img
                                data-testid="aging-balance-icon"
                                className={classes.clockIcon}
                                src={process.env.PUBLIC_URL + '/assets/icon-clock.png'}
                                alt="Aging Balance"
                            />
                            <Grid>
                                <Typography className={classes.boldText}>
                                    <Trans i18nKey="agingBalanceIndicator">
                                        Indicates an aging balance
                                    </Trans>
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid container>
                    <ProductPlanningTabs
                        products={filteredProducts as OrderPlanningProduct[]}
                        allocations={filteredAllocations}
                        onViewFiltersClicked={handleViewFiltersClicked}
                        onRangeFiltersClicked={handleRangeFiltersClicked}
                        onUnitsFiltersClicked={handleUnitsFiltersClicked}
                        selectedRange={range}
                        currentViewTypes={!!view ? view : []}
                        currentUnit={quantityUnit}
                    />
                </Grid>
            </Grid>
            <CopyToMakeItModal
                openModal={openConvertPlanItModal}
                title={t('copyToMakeIt', 'Copy To Make It')}
                onClose={onClose}
                onSubmit={onSubmit}
                error={error}
                dataLoaded={loaded}
                forecastOrders={forecastOrders}
            />
        </ProcessingPageTemplate>
    );
}
