import { PlanningAllocation } from '../../components/pages/ProductPlanningATM/models/PlanningAllocation';
import {
    PRODUCT_PLANNING_ATM_DELIVERY_ORDERS_LOADED,
    PRODUCT_PLANNING_ATM_LOADED,
    PRODUCT_PLANNING_ATM_LOADING,
    PRODUCT_PLANNING_ATM_LOADING_ERROR,
    PRODUCT_PLANNING_ATM_UPDATE_RANGE,
    PRODUCT_PLANNING_ATM_UPDATE_FILTER,
    PRODUCT_PLANNING_ATM_DELIVERY_ORDERS_LOADING,
    PRODUCT_PLANNING_ATM_DELIVERY_ORDERS_ERROR,
    PRODUCT_PLANNING_ATM_UPDATE_QUANTITY_UNIT,
    PRODUCT_PLANNING_ATM_UPDATE_COLLAPSE,
    PRODUCT_PLANNING_ATM_RESET
} from '../actions/action-types';
import { QuantityUnit } from './shipping-dashboard';
import { ShipmentDeliveryOrder, TotalNDayRanges } from './product-planning';

export interface ProductPlanningATMState {
    range: number;
    view: string[];
    allocationsBySnoSku: PlanningAllocation[];
    loading: boolean;
    loaded: boolean;
    error?: any;
    quantityUnit: QuantityUnit;
    deliveryOrdersForProduct?: ShipmentDeliveryOrder[];
}

const initialState: ProductPlanningATMState = {
    allocationsBySnoSku: [],
    range: TotalNDayRanges.nineWeeks,
    view: [''],
    loading: false,
    loaded: false,
    deliveryOrdersForProduct: [],
    quantityUnit: 'pallets'
};

const productPlanningATM = (state = initialState, action: any): ProductPlanningATMState => {
    switch (action.type) {
        case PRODUCT_PLANNING_ATM_LOADED:
            return {
                ...state,
                loaded: true,
                loading: false,
                allocationsBySnoSku: action.allocationsBySnoSku
            };
        case PRODUCT_PLANNING_ATM_RESET:
            return {
                ...initialState
            };
        case PRODUCT_PLANNING_ATM_LOADING:
            return { ...state, loaded: false, loading: true, error: undefined };
        case PRODUCT_PLANNING_ATM_LOADING_ERROR:
            return { ...state, loaded: true, loading: false, error: action.error };
        case PRODUCT_PLANNING_ATM_UPDATE_RANGE:
            return { ...state, range: action.range };
        case PRODUCT_PLANNING_ATM_UPDATE_FILTER:
            return { ...state, view: action.view };
        case PRODUCT_PLANNING_ATM_UPDATE_QUANTITY_UNIT:
            return { ...state, quantityUnit: action.quantityUnit };

        case PRODUCT_PLANNING_ATM_DELIVERY_ORDERS_LOADING:
            return {
                ...state,
                loaded: false,
                loading: true,
                error: undefined,
                deliveryOrdersForProduct: undefined
            };
        case PRODUCT_PLANNING_ATM_DELIVERY_ORDERS_LOADED:
            return {
                ...state,
                loaded: true,
                loading: false,
                error: undefined,
                deliveryOrdersForProduct: action.deliveryOrdersForProduct
            };
        case PRODUCT_PLANNING_ATM_DELIVERY_ORDERS_ERROR:
            return {
                ...state,
                loaded: true,
                loading: false,
                error: action.error,
                deliveryOrdersForProduct: undefined
            };
        case PRODUCT_PLANNING_ATM_UPDATE_COLLAPSE:
            return {
                ...state,
                allocationsBySnoSku: action.allocationsBySnoSku
            };
        default:
            return state;
    }
};

export default productPlanningATM;
