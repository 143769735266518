import { Grid, FormHelperText, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import {
    medium,
    ltBlueGrey_34,
    xl,
    boldWeight,
    red,
    warningLabel
} from '../../../../themes/globalConstants';
import TextInput from '../../../reusable/molecules/TextInput';
import SelectInput from '../../../reusable/molecules/SelectInput';
import FormSection from '../../../reusable/molecules/FormSection';
import { EmeaGraphics } from '../../../../store/reducers/graphic-intake';
import { useFormContext, ErrorMessage } from 'react-hook-form';
import {
    emeaOverVarnish,
    emeaBasecoatColour,
    emeaBallSpecialInk
} from '../../../../static-data/dropdown-lists';

interface Props {
    isEditDraft: boolean;
    graphicData?: EmeaGraphics;
}

const useStyles = makeStyles((theme) => ({
    subsectionHeader: {
        fontSize: xl,
        fontWeight: boldWeight,
        color: theme.palette.info.dark,
        marginBottom: '2em'
    },
    label: {
        color: theme.palette.info.dark,
        fontSize: medium,
        marginBottom: '0.5em'
    },
    radioGroup: {
        marginTop: '1.5em',
        paddingLeft: '0.5em'
    },
    divider: {
        height: 5,
        backgroundColor: ltBlueGrey_34,
        margin: '1.5em 0'
    },
    helperText: {
        '& .MuiFormHelperText-root': {
            marginLeft: 0
        }
    },
    warningContainer: {
        display: 'flex',
        backgroundColor: warningLabel,
        margin: '1em 1.5em 1em 0.5em'
    },
    warningIconCtn: {
        textAlign: 'center'
    },
    warningIcon: {
        fill: red
    },
    warningLabel: {
        fontSize: medium,
        fontWeight: boldWeight
    },
    capitalize: {
        textTransform: 'capitalize'
    },
    errorText: {
        color: red
    }
}));

const ColourInfo = ({ isEditDraft, graphicData }: Props) => {
    const classes = useStyles();
    const [draftLoaded, setDraftLoaded] = useState<boolean>(false);
    const { control, errors, watch, setValue } = useFormContext();
    const showBasecoatField = watch('metalType') === 'Steel';

    useEffect(() => {
        const graphicIsValid = graphicData?.requestName !== '';

        if (isEditDraft && graphicData && graphicIsValid) {
            setValue('overVarnish', graphicData.overvarnish);
            setValue('basecoatColour', graphicData.basecoatColour);
            setValue('specialInk', graphicData.specialInk);
            setValue('colourTarget', graphicData.colourTarget);
            setDraftLoaded(true);
        }
    }, [isEditDraft, graphicData, setValue]);

    const isRequired = <Trans i18nKey="required">Required</Trans>;

    return (
        <FormSection
            testId={'colour-information-section'}
            sectionHeader={<Trans i18nKey="colourSubInfo">Colour Information</Trans>}
        >
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                    <SelectInput
                        label={<Trans i18nKey="overVarnish">Over Varnish</Trans>}
                        name="overVarnish"
                        testId="overVarnish"
                        control={control}
                        rules={{ required: isRequired }}
                        error={errors.overVarnish}
                        errors={errors}
                        styles={classes.helperText}
                        selectObj={emeaOverVarnish}
                        helperText={
                            <Trans i18nKey="overVarnishHelper">
                                Select the varnish you would like Ball to apply to the design{' '}
                            </Trans>
                        }
                    />
                </Grid>
                {(showBasecoatField || (isEditDraft && !draftLoaded)) && (
                    <Grid item xs={6}>
                        <SelectInput
                            label={<Trans i18nKey="basecoatColour">Basecoat Colour</Trans>}
                            name="basecoatColour"
                            testId="basecoatColour"
                            control={control}
                            rules={{ required: isRequired }}
                            error={errors.basecoatColour}
                            errors={errors}
                            styles={classes.helperText}
                            selectObj={emeaBasecoatColour}
                            helperText={
                                <Trans i18nKey="basecoatColourHelper">
                                    Basecoat only available on steel cans{' '}
                                </Trans>
                            }
                        />
                    </Grid>
                )}
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                    <SelectInput
                        label={<Trans i18nKey="specialInk">Ball Special Ink</Trans>}
                        name="specialInk"
                        testId="specialInk"
                        control={control}
                        error={errors.specialInk}
                        errors={errors}
                        selectObj={emeaBallSpecialInk}
                        defaultValue="No Special ink"
                        helperText={
                            <Trans i18nKey="specialInkHelper">
                                Select the special effect on inks you wish Ball to apply to your
                                design{' '}
                            </Trans>
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        type="text"
                        label={<Trans i18nKey="colourTarget">Colour Target</Trans>}
                        name="colourTarget"
                        testId="colourTarget"
                        control={control}
                        rules={{ required: isRequired }}
                        error={errors.colourTarget}
                        errors={errors}
                        helperText={
                            <Trans i18nKey="colourTargetHelper">
                                Specify if you have a colour target you would like Ball to match{' '}
                            </Trans>
                        }
                    />
                    <FormHelperText className={classes.errorText}>
                        {errors.colourTarget && errors.colourTarget.type === 'required' && (
                            <ErrorMessage
                                errors={errors}
                                name={'colourTarget'}
                                message={isRequired}
                            />
                        )}
                    </FormHelperText>
                </Grid>
            </Grid>
        </FormSection>
    );
};

export default ColourInfo;
