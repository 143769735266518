import React, { useEffect, useState } from 'react';
import { makeStyles, Typography, TableRow, TableCell, Grid } from '@material-ui/core';
import { ltBlueGrey_34, medium } from '../../../../themes/globalConstants';
import {
    formatStatus,
    getGraphicIdAndVersion,
    orderStatusCodeFormatter,
    ToggleAll
} from '../../../../utility/helpers/order-helpers';
import { Trans, useTranslation } from 'react-i18next';
import { MakeItBulkATMLineItem } from '../../../../store/reducers/makeit-bulk-atm';
import { currencyFormatter, formatNumberWithLocale } from '../../../../utility/helpers/formatters';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import MakeItATMOrderExpandableSection from './MakeItATMOrderExpandableSection';
import { MakeItATMOrderDetailsState } from '../../../../store/reducers/atm-production-order-details';
import { ProductionOrderLinkedDeliveryOrder } from '../../../../store/reducers/production-order-details';
import { Order_Status } from '../../../../utility/services/orders-service';
import { getMoqFee } from '../../../../utility/helpers/production-order-helpers';

interface Props {
    line: MakeItBulkATMLineItem;
    expand: ToggleAll;
}

const useStyles = makeStyles((theme) => ({
    name: {
        fontSize: medium,
        letterSpacing: 0.17
    },
    expand: {
        transform: 'rotate(-90deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: 'rotate(0)'
    },
    expandContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    expandableSection: {
        maxHeight: '13.125em',
        overflowY: 'scroll'
    },
    standardRow: {
        '& > td:nth-child(n+4)': {
            textAlign: 'right'
        }
    },
    altRow: {
        backgroundColor: ltBlueGrey_34,
        '& > td': {
            borderBottomColor: 'white'
        }
    },
    hidden: {
        display: 'none'
    },
    display: {
        display: 'table-row'
    },
    borderlessCell: {
        borderLeft: 'unset'
    },
    activeCell: {
        backgroundColor: ltBlueGrey_34
    },
    activeRow: {
        boxShadow:
            '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 4px 3px 0px rgba(0,0,0,0.12)'
    },
    cancelledCell: {
        color: 'red'
    }
}));

const MakeItATMOrderDetailsLineRow = ({ line, expand }: Props) => {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [active, setActive] = useState<boolean>(false);
    const { linkedDeliveryOrder } = useTypedSelector<MakeItATMOrderDetailsState>(
        (state) => state.atmProductionOrderDetails
    );
    const [displayFee, setDisplayFee] = useState<string>('-');

    const [filteredDeliveryOrders, setFilteredDeliveryOrders] = useState<
        ProductionOrderLinkedDeliveryOrder[] | undefined
    >(undefined);

    useEffect(() => {
        if (line && line.palletQuantity) {
            let fee = getMoqFee(line.palletQuantity, line.moqFees) ?? 0;
            fee > 0 ? setDisplayFee(currencyFormatter(cultureCode, fee)) : setDisplayFee('-');
        }
    }, [line, cultureCode]);

    useTranslation();

    const toggleExpand = () => {
        setActive(!active);
    };

    useEffect(() => {
        setActive(expand.toggle);
    }, [expand]);

    const getEaches = (line: MakeItBulkATMLineItem) => {
        if (line.quantityPerPallet && line.palletQuantity) {
            let eaches = line.quantityPerPallet * line.palletQuantity;
            return formatNumberWithLocale(cultureCode, eaches) + ' ea.';
        } else {
            return '0 ea.';
        }
    };

    useEffect(() => {
        if (linkedDeliveryOrder) {
            setFilteredDeliveryOrders(
                linkedDeliveryOrder.linkedDeliveryOrders?.filter(
                    (orders) =>
                        orders.productionOrderLineId === line.productionOrderLineId &&
                        orders.status !== Order_Status.Cancelled
                )
            );
        }
    }, [line.productionOrderLineId, linkedDeliveryOrder, line]);

    return (
        <>
            <TableRow hover={true} key={line.productionOrderLineId}>
                <TableCell>
                    <Grid container>
                        <Grid item xs={12} className={classes.name}>
                            <Typography
                                key={'line-number' + line.productionOrderLineNumber}
                                data-testid="production-order-line-number"
                            >
                                {line.productionOrderLineNumber}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid container>
                        <Grid item xs={10} className={classes.name}>
                            <Typography>
                                <strong>
                                    {line.customerProductId ? line.customerProductId : '-'}
                                </strong>
                            </Typography>
                            <Typography
                                key={'product-id-' + line.productionOrderLineId}
                                data-testid="display-id"
                            >
                                <strong>{line.displayId}</strong>
                            </Typography>
                            <Typography
                                key={'graphic-id-' + line.productionOrderLineId}
                                data-testid="graphic-id"
                            >
                                {getGraphicIdAndVersion(line.productType, line.productGraphicId)}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} className={classes.expandContainer}>
                            <ExpandMoreIcon
                                className={clsx(classes.expand, {
                                    [classes.expandOpen]: active
                                })}
                                onClick={toggleExpand}
                                color="primary"
                            />
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid container>
                        <Grid item xs={12} className={classes.name}>
                            <Typography
                                key={'product-desc-' + line.productionOrderLineId}
                                data-testid="display-name"
                            >
                                {line.displayName}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid container>
                        <Grid item xs={12} className={classes.name}>
                            <Typography
                                key={'coating-' + line.productionOrderLineId}
                                data-testid="coating"
                            >
                                {line.productCoating}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid container>
                        <Grid item xs={12} className={classes.name}>
                            <Typography
                                key={'product-quantity-' + line.productionOrderLineId}
                                data-testid="quantity"
                            >
                                {line.palletQuantity &&
                                    formatNumberWithLocale(cultureCode, line.palletQuantity)}{' '}
                                PL
                            </Typography>
                            <Typography
                                key={'product-eaches-' + line.productionOrderLineId}
                                data-testid="eaches"
                            >
                                {getEaches(line)}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                {
                    <TableCell data-testid="fee">
                        <Grid container alignContent="center">
                            {displayFee}
                        </Grid>
                    </TableCell>
                }
                <TableCell>
                    <Grid container>
                        <Grid item xs={12} className={classes.name}>
                            <Typography
                                key={'status-' + line.productionOrderLineId}
                                data-testid="status"
                                className={line.status === 'CANC' ? classes.cancelledCell : ''}
                            >
                                <Trans i18nKey={orderStatusCodeFormatter(line.status)}>
                                    {formatStatus(line.status)}
                                </Trans>
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
            </TableRow>
            <MakeItATMOrderExpandableSection
                active={active}
                line={line}
                linkedDeliveryOrders={filteredDeliveryOrders}
            />
        </>
    );
};

export default MakeItATMOrderDetailsLineRow;
