import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { createBrowserHistory } from 'history';

export let appInsights: ApplicationInsights;

const getAppInsights = () => {
    const browserHistory = createBrowserHistory({ basename: '' });
    var reactPlugin = new ReactPlugin();
    appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: { history: browserHistory }
            },
            enableAutoRouteTracking: true,
            autoTrackPageVisitTime: true,
            enableCorsCorrelation: true,
            enableAjaxPerfTracking: true
        }
    });
    appInsights.loadAppInsights();
}

export default getAppInsights;