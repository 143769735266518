import React, { useState, useEffect } from 'react';
import UnrecognizedProductsColumns from './UnrecognizedProductsColumns';
import UnrecognizedProductsRow from './UnrecognizedProductsRow';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import StandardTable from '../../../reusable/molecules/StandardTable';
import { UnrecognizedProduct } from '../../../../store/reducers/planit-bulk-atm';

interface Props {
    products: UnrecognizedProduct[];
}

export default function UnrecognizedProductsTable({ products }: Props) {
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const [columns, setColumns] = useState<any>([]);

    const columnWidths = [10, 15, 30, 15, 30];

    useEffect(() => {
        setColumns(UnrecognizedProductsColumns.getColumns(cultureCode));
    }, [cultureCode]);

    const ProductAlertsLine = ({ row }: { row: UnrecognizedProduct }) => {
        if (row) {
            return <UnrecognizedProductsRow key={row.lineNumber} line={row as UnrecognizedProduct} />;
        } else {
            return null;
        }
    };

    return (
        <StandardTable
            gridName="Unrecognized Products"
            columns={columns}
            columnWidths={columnWidths}
            lines={products}
            rowComponent={ProductAlertsLine}
            itemsPerPage={10}
        />
    );
}
