import { makeStyles, Paper, Grid, Typography, IconButton, Collapse } from "@material-ui/core";
import { ltBlueGrey_8, blackWeight, xl, boldWeight, ballBlue, xxl } from "../../../themes/globalConstants";
import React, { useEffect, useState } from "react";
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import clsx from 'clsx';

interface Props {
    testId: string;
    sectionHeader: React.ReactNode;
    children?: any;
    collapsible?: boolean;
    showSectionOnLoad?: boolean;
}

const useStyles = makeStyles((theme) => ({
    paper: {
        boxShadow: `0 3px 6px 0 ${ltBlueGrey_8}`,
        borderRadius: 4
    },
    main: {
        padding: '4em',
        marginTop: 0
    },
    sectionHeader: {
        fontSize: xxl,
        letterSpacing: '0.21px',
        color: theme.palette.secondary.main,
        fontWeight: blackWeight
    },
    subsectionHeader: {
        fontSize: xl,
        fontWeight: boldWeight,
        color: theme.palette.info.dark,
        marginBottom: '2em'
    },
    btnContainer: {
        marginTop: '3em'
    },
    actionBtn: {
        height: '3.375em',
        width: '13.75em',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom'
    },
    toggleButton: {
        padding: '0 !important',
        '&:hover': {
            background: 'transparent'
        }
    },
    arrow: {
        fill: ballBlue
    },
    rotateArrow: {
        transform: 'rotate(180deg)'
    },
    collapse: {
        width: '100%'
    },

}));

const FormSection = ({ testId, sectionHeader, children, collapsible, showSectionOnLoad }: Props) => {
    const classes = useStyles();
    const [showFormSection, setShowFormSection] = useState<boolean>(true);

    useEffect(() => {
        if (showSectionOnLoad !== undefined) {
            setShowFormSection(showSectionOnLoad);
        }
    }, [showSectionOnLoad])

    return (
        <Paper className={classes.paper}>
            <Grid container data-testid={testId} className={classes.main} spacing={2}>
                {collapsible ? (
                    <>
                        <Grid container item xs={12} data-testid="collapsible-form-section">
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                className={classes.toggleButton}
                                data-testid="form-section-collapse-area"
                                onClick={() => {
                                    setShowFormSection(!showFormSection);
                                }}
                            >
                                <Typography className={classes.sectionHeader}>
                                    {sectionHeader}
                                </Typography>
                                <ExpandMoreOutlinedIcon
                                    className={clsx(classes.arrow, { [classes.rotateArrow]: showFormSection })}
                                    fontSize="large"
                                    data-testid="collapse-arrow"
                                />
                            </IconButton>
                        </Grid>
                        <Collapse in={showFormSection} className={classes.collapse}>
                            {children}
                        </Collapse>
                    </>
                ) : (
                        <>
                            <Grid item xs={12} data-testid="non-collapsible-section">
                                <Typography className={classes.sectionHeader}>
                                    {sectionHeader}
                                </Typography>
                            </Grid>
                            {children}
                        </>
                    )}
            </Grid>
        </Paper>
    )
}

export default FormSection;