import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class openDeliveryOrderColumns {
    static getOpenDeliveryOrderColumns = (culture: string, showEditCancel: boolean) => {
        return [
            createColumn('deliveryOrderId', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('shipToName', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'shipTo'),
                sortable: true,
                filterable: true,
                searchable: true
            }),
            createColumn('deliveryOrderNumber', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'deliveryOrderNumber'),
                sortable: true,
                searchable: false
            }),
            createColumn('deliveryOrderNumberString', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            }),
            createColumn('status', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'status'),
                sortable: true,
                searchable: true
            }),
            createColumn('reviewStatus', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: false
            }),
            createColumn('submittedDate', {
                dataType: ColumnDataType.Date,
                label: TranslationService.getTranslatedText(culture, 'submitted'),
                sortable: true,
                filterable: true,
                searchable: false
            }),
            createColumn('formattedSubmittedDate', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            }),
            createColumn('shipmentDeliveryDateTime', {
                dataType: ColumnDataType.Date,
                label: TranslationService.getTranslatedText(culture, 'nextDeliveryWeek'),
                sortable: true,
                filterable: true,
                searchable: false
            }),
            createColumn('formattedShipmentDeliveryDateTime', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            }),
            createColumn('orderQuantityText', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'totalDeliveryQuantity'),
                sortable: true,
                searchable: true
            }),
            createColumn('orderQuantityContent', {
                dataType: ColumnDataType.String,
                sortable: true,
                searchable: false,
                visible: false
            }),
            createColumn('actions', {
                label: TranslationService.getTranslatedText(culture, 'actions'),
                visible: showEditCancel
            }),
            createColumn('shipToId', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('shipToAddress', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            }),
            createColumn('totalShipments', {
                dataType: ColumnDataType.Numeric,
                visible: false,
                searchable: false
            }),
            createColumn('totalOrderQuantity', {
                dataType: ColumnDataType.Numeric,
                visible: false,
                searchable: false
            }),
            createColumn('totalDeliveryQuantity', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('totalOrderSkus', {
                dataType: ColumnDataType.Numeric,
                visible: false,
                searchable: false
            }),
            createColumn('earliestShipmentDate', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: false
            }),
            createColumn('latestShipmentDate', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: false
            }),
            createColumn('isLocked', {
                dataType: ColumnDataType.Boolean,
                visible: false,
                searchable: false
            }),
            createColumn('pristineDeliveryOrder', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: false
            }),
            createColumn('searchTerms', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            })
        ];
    };
}
