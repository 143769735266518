import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { hasPersonas, Persona } from '../../../utility/auth/useSecurity';
import { AuthState } from '../../../store/reducers/auth';
import NavBarMenu from './NavBarMenu';
import NavBarTile from './NavBarTile';

const SiteAdminNav = () => {
    const [menuTiles, setMenuTiles] = useState<JSX.Element[]>([]);
    const auth = useTypedSelector<AuthState>((state) => state.auth);
    const newGraphicIcon = '/Submit_New_Graphic_Nav_Icon.svg';

    useEffect(() => {
        const tiles: JSX.Element[] = [];

        if (hasPersonas([Persona.CommunicationManagement], auth)) {
            tiles.push(
                <Grid item xs={6} key={'manage-communications'}>
                    <NavBarTile
                        link="/manage-communications"
                        translationKey="manageCommunication"
                        defaultText="Manage Communication"
                        icon={newGraphicIcon}
                        isProduct={false}
                        secondaryText="Manage Communications with the users of The Source."
                        secondaryTranslationKey="manageCommunicationsSource"
                    />
                </Grid>
            );
        }
        setMenuTiles(tiles);
    }, [hasPersonas, auth]);

    return (
        <NavBarMenu menuName={<Trans i18nKey="siteAdmin">Site Admin</Trans>} sections={[]}>
            <Grid container>{menuTiles}</Grid>
        </NavBarMenu>
    );
};

export default SiteAdminNav;
