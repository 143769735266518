import React, { useMemo, useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { ballBlue, boldWeight, small } from '../../../../themes/globalConstants';
import { Trans, useTranslation } from 'react-i18next';
import {
    ArrayFilterType,
    getSelectedRangeLabel,
    NumberFilterType,
    ViewType,
    weekFilter
} from '../../../../utility/helpers/filter-helpers';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { selectStateByName } from '../../../../store/selectors';
import { StateName } from '../../../../utility/helpers/state-helpers';
import ProductPlanningFilterMenu from './ProductPlanningFilterMenu';
import RangeFilterToolbar from '../../../reusable/molecules/RangeFilterToolbar';
import Button from '../../../reusable/atoms/Button';
import Image from '../../../reusable/atoms/Image';

interface Props {
    stateName: StateName;
    onExpandAll?: () => void;
    onCollapseAll?: () => void;
    onViewFiltersClicked: (viewTypes: string[]) => void;
    onRangeFiltersClicked?: (rangeTypes: number) => void;
    onUnitsFiltersClicked: (unitTypes: string[]) => void;
    unitType?: ArrayFilterType[];
    weekFilterType?: NumberFilterType[];
    viewFilterType?: ViewType[];
    currentViewTypes: string[];
    currentUnit: string;
}

const useStyles = makeStyles((theme) => ({
    toolbarWrapper: {
        padding: '1.125em 0.2em',
        alignItems: 'center',
        '& .MuiGrid-container': {
            margin: 0
        }
    },
    toolbarLabel: {
        fontSize: small,
        textTransform: 'uppercase',
        marginRight: '0.25em'
    },
    filtersWrapper: {
        display: 'flex',
        alignItems: 'baseline'
    },
    btn: {
        padding: 0,
        minWidth: 'auto',
        fontSize: small,
        fontWeight: boldWeight,
        margin: '0 0.5em'
    },
    expandCollapseWrapper: {
        alignItems: 'center',
        color: ballBlue,
        justifyContent: 'flex-end'
    },
    filterLabel: {
        color: theme.palette.primary.main,
        border: '1px solid #8E9295'
    },
    icon: {
        marginLeft: '0.75em'
    }
}));

export default function ProductPlanningToolbar({
    stateName,
    onExpandAll,
    onCollapseAll,
    onViewFiltersClicked,
    onRangeFiltersClicked,
    onUnitsFiltersClicked,
    weekFilterType,
    currentViewTypes,
    currentUnit
}: Props) {
    const classes = useStyles();
    const selectedState = useTypedSelector((state) => selectStateByName(state, stateName));
    const rangeLabel = useMemo(() => getSelectedRangeLabel(selectedState.range), [selectedState.range]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const filterIcon = `${process.env.PUBLIC_URL}/assets/Filter_Icon.svg`;

    useTranslation();

    const handleActionMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuBlur = () => {
        setAnchorEl(null);
    };

    return (
        <Grid container className={classes.toolbarWrapper}>
            <Grid item xs={12} className={classes.filtersWrapper}>
                {onExpandAll && onCollapseAll && (
                    <Grid
                        container
                        item
                        justify="flex-end"
                        className={classes.expandCollapseWrapper}
                        xs={2}
                        data-testid="expand-collapse-buttons"
                    >
                        <Button
                            className={classes.btn}
                            color="primary"
                            onClick={() => onExpandAll()}
                        >
                            <Trans i18nKey="expandAll">Expand All</Trans>
                        </Button>
                        <Typography>|</Typography>
                        <Button
                            className={classes.btn}
                            color="primary"
                            onClick={() => onCollapseAll()}
                        >
                            <Trans i18nKey="collapseAll">Collapse All</Trans>
                        </Button>
                    </Grid>
                )}
                {selectedState.range && weekFilterType && onRangeFiltersClicked && (
                    <Grid
                        container
                        item
                        xs={9}
                        justify="flex-end"
                        data-testid="range-filter-toolbar"
                    >
                        <RangeFilterToolbar
                            rangeTypes={weekFilterType || weekFilter}
                            rangeChange={(newRange) => onRangeFiltersClicked(newRange)}
                            testId={'week-range-btn-grp'}
                            value={rangeLabel}
                            multiSelect={true}
                        />
                    </Grid>
                )}
                <Grid container item xs={1} justify="flex-end" data-testid="filter-button">
                    <Button onClick={handleActionMenuClick} className={classes.filterLabel}>
                        <Trans i18nKey="filter">Filter</Trans>
                        <Image
                            src={filterIcon}
                            alt={'Filter Icon'}
                            className={classes.icon}
                        />
                    </Button>
                </Grid>
            </Grid>
            <ProductPlanningFilterMenu
                onMenuBlur={handleMenuBlur}
                anchorEl={anchorEl}
                currentViewTypes={currentViewTypes}
                onUnitChange={onUnitsFiltersClicked}
                handleUpdateViews={onViewFiltersClicked}
                currentUnit={currentUnit}
            />
        </Grid>
    );
}
