import React, { useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import {
    lato,
    ltBlueGrey,
    ballGray,
    white,
    ballLtGray,
    ballBlue,
    datepickerHoverGray,
    datepickerDefaultGray,
    paleGrey,
    ballLtBlue
} from '../../../themes/globalConstants';
import { useTranslation } from 'react-i18next';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import { DayPickerSingleDateController } from 'react-dates';
import clsx from 'clsx';
import styled from 'styled-components';
import { Moment } from 'moment';

interface Props {
    keepOpen?: boolean;
    numberOfMonths?: number;
    isFocused: boolean;
    date?: moment.Moment | null;
    firstDay?: moment.Moment | null;
    highlightedDay?: moment.Moment | null;
    isOutsideRange?: (day: any) => boolean;
    firstMonthAvailable: () => Moment;
    onDateChange?: (date: moment.Moment | null) => void;
}

const useStyles = makeStyles((theme) => ({
    navBtn: {
        position: 'absolute',
        top: 18,
        lineHeight: 0.78,
        borderRadius: 3,
        padding: '6px 9px',
        color: ballBlue,
        fontSize: '2rem'
    },
    prevIcon: {
        transform: 'rotate(90deg)',
        left: 22
    },
    nextIcon: {
        transform: 'rotate(270deg)',
        right: 22
    },
    highlightedDay: {
        backgroundColor: ballLtBlue,
        color: 'black',
        border: `2px solid ${ballBlue} !important`,
        borderRadius: 2,
        height: '100%',
        width: '93%',
    },
    standardDay: {
        height: '100%',
        width: '100%',
        paddingTop: '.3em'
    },
    dayNumber: {
        text: lato,
        paddingTop: '.8em'
    }
}));

const StyledWrapper = styled.div`
    & .DayPicker,
    .DayPicker_weekHeaders {
        font-family: ${lato};
    }

    .DayPicker_transitionContainer {
        border: 0.5px solid ${ltBlueGrey};
        border-radius: 4px;
        margin-bottom: 2em;
    }

    .CalendarDay__selected_span {
        background: ${ballGray};
        color: ${white};
        border-bottom: 1px solid ${ballLtGray};
    }

    .CalendarDay__selected {
        background: ${ballBlue};
        border: 1px double ${ballBlue};
        color: ${white};
    }

    .CalendarDay__selected:hover {
        background: ${ballLtGray};
        color: ${datepickerHoverGray};
        border: 1px double ${ballBlue};
        height: '100%';
        width: '85%';
    }

    .CalendarDay__hovered_span:hover,
    .CalendarDay__hovered_span {
        background: ${ballLtGray};
        height: '100%';
        width: '85%';
    }

    .CalendarDay__highlighted_calendar,
    .CalendarDay__highilghted_calendar_4 {
        background: ${ballBlue};
        border: 1px double ${ballBlue};
        color: ${white};
    }

    .CalendarDay__blocked_out_of_range {
        background-color: ${paleGrey};
    }

    .CalendarDay__default {
        border: 1px solid ${datepickerDefaultGray};
    }

    .CalendarDay__outside {
        color: ${ballGray};
        border: none;
    }
`;

const SingleDayPicker = (props: Props) => {
    // If there is a highlighted day prop, initialize with that day selected
    const [date, setDate] = useState<moment.Moment | null>(props.highlightedDay ? props.highlightedDay : null);
    const classes = useStyles();
    useTranslation();

    const handleDateChange = (newDate: moment.Moment | null) => {
        setDate(newDate);
        if (props.onDateChange) {
            props.onDateChange(newDate);
        }
    };

    const isSpecialDate = (day) => {
        if (day.isSame(props.firstDay, 'day')) {
            return 'firstAvailable';
        } else {
            return 'standardDay';
        }
    };

    const renderDay = (day) => {
        const specialDate = isSpecialDate(day);
        return (
            <div
                className={clsx(
                    { [classes.highlightedDay]: specialDate === 'firstAvailable' },
                    { [classes.standardDay]: specialDate === 'standardDay' }
                )}
            >
                <Typography className={classes.dayNumber} text-align="center">
                    {day.format('D')}
                </Typography>
            </div>
        );
    };

    const prevIcon = <ExpandMoreOutlinedIcon className={clsx(classes.navBtn, classes.prevIcon)} />;
    const nextIcon = <ExpandMoreOutlinedIcon className={clsx(classes.navBtn, classes.nextIcon)} />;

    return (
        <StyledWrapper>
            <DayPickerSingleDateController
                navPrev={prevIcon}
                navNext={nextIcon}
                date={date}
                horizontalMonthPadding={38}
                daySize={55}
                focused={props.isFocused}
                enableOutsideDays
                hideKeyboardShortcutsPanel={true}
                isOutsideRange={props.isOutsideRange}
                numberOfMonths={props.numberOfMonths}
                keepOpenOnDateSelect={props.keepOpen}
                initialVisibleMonth={props.firstMonthAvailable}
                onDateChange={(newDate) => handleDateChange(newDate)}
                onFocusChange={() => {}}
                renderDayContents={renderDay}
            />
        </StyledWrapper>
    );
};

export default SingleDayPicker;
