import { revokeAllLocationRoleAccess } from '../../components/reusable/molecules/LocationRoleAccessTable/context/actions';
import {
    RoleAccessKey,
    ShipToRoleAccess
} from '../../components/reusable/molecules/LocationRoleAccessTable/models/role-access.model';
import { UserPermission } from '../../store/reducers/auth';
import { Account } from '../../store/reducers/customer-context';
import { Culture } from '../../store/reducers/region-culture';
import { PermissionChangeItem } from '../../store/reducers/user-admin';
import { Persona } from '../auth/useSecurity';
import TranslationService from '../services/translation-service';

export const updateShipToRoleAccess = (
    permissions: UserPermission[] | null,
    roles: ShipToRoleAccess[],
    currentUserAdminAccounts: Account[] = []
): ShipToRoleAccess[] => {
    return roles.map((account) => {
        if (
            currentUserAdminAccounts.length > 0 &&
            currentUserAdminAccounts.some((a) => a.accountId === account.accountId)
        ) {
            account.isUserAdmin = true;
        }
        permissions?.forEach((permission) => {
            if (permission.accountIds.includes(account.accountId)) {
                switch (permission.personaId) {
                    case Persona.AllAccess:
                        account.allAccess = true;
                        break;
                    case Persona.ArAp:
                        account.acctPayableReceivable = true;
                        break;
                    case Persona.ArtMgmt:
                        account.artMgt = true;
                        break;
                    case Persona.CoPacker:
                        account.coPacker = true;
                        break;
                    case Persona.ForecastMgmt:
                        account.forecastMgt = true;
                        break;
                    case Persona.GraphicsVendor:
                        account.graphicsVendor = true;
                        break;
                    case Persona.MarketingMgmt:
                        account.marketingMgt = true;
                        break;
                    case Persona.OrderFulfillment:
                        account.orderFullfillment = true;
                        break;
                    case Persona.PackagingMgmt:
                        account.packagingMgt = true;
                        break;
                    case Persona.ProcurementMgmt:
                        account.procurementMgt = true;
                        break;
                    case Persona.WarehousingLogistics:
                        account.warehousingLogistics = true;
                        break;
                    case Persona.PricingContact:
                        account.pricingContact = true;
                        break;
                    case Persona.MakeItOnly:
                        account.makeItOnly = true;
                        break;
                    case Persona.ShipItOnly:
                        account.shipItOnly = true;
                        break;
                }
            }
        });
        return account;
    });
};

export const getRoleName = (personaId: Persona, cultureCode: Culture): string => {
    switch (personaId) {
        case Persona.AccountAdmin:
            return TranslationService.getTranslatedText(cultureCode, 'accountAdmin');
        case Persona.AllAccess:
            return TranslationService.getTranslatedText(cultureCode, 'allAccess');
        case Persona.ArAp:
            return TranslationService.getTranslatedText(cultureCode, 'arAp');
        case Persona.ArtMgmt:
            return TranslationService.getTranslatedText(cultureCode, 'artMgmt');
        case Persona.CoPacker:
            return TranslationService.getTranslatedText(cultureCode, 'coPacker');
        case Persona.ForecastMgmt:
            return TranslationService.getTranslatedText(cultureCode, 'forecastMgmt');
        case Persona.GraphicsVendor:
            return TranslationService.getTranslatedText(cultureCode, 'graphicsVendor');
        case Persona.MarketingMgmt:
            return TranslationService.getTranslatedText(cultureCode, 'marketingMgmt');
        case Persona.OrderFulfillment:
            return TranslationService.getTranslatedText(cultureCode, 'orderFulfillment');
        case Persona.PackagingMgmt:
            return TranslationService.getTranslatedText(cultureCode, 'packagingMgmt');
        case Persona.ProcurementMgmt:
            return TranslationService.getTranslatedText(cultureCode, 'procurementMgmt');
        case Persona.WarehousingLogistics:
            return TranslationService.getTranslatedText(cultureCode, 'warehousingLogistics');
        case Persona.MakeItOnly:
            return TranslationService.getTranslatedText(cultureCode, 'makeItOnly');
        case Persona.ShipItOnly:
            return TranslationService.getTranslatedText(cultureCode, 'shipItOnly');
        case Persona.Impersonation:
            return TranslationService.getTranslatedText(cultureCode, 'impersonation');
        case Persona.PricingContact:
            return TranslationService.getTranslatedText(cultureCode, 'pricingContact');
        case Persona.PlanItOnly:
            return TranslationService.getTranslatedText(cultureCode, 'planItOnly');
        default:
            return '';
    }
};

export const getUserRoles = (
    permissions: UserPermission[] | null,
    cultureCode: Culture
): string => {
    const roles = permissions?.map((permission) => {
        return `${getRoleName(permission.personaId, cultureCode)} (${
            permission.accountIds.length
        })`;
    });
    return roles ? roles.sort().join(', ') : '';
};

export const getPendingRoles = (permissions: any, cultureCode: Culture) => {
    const personas: Persona[] = permissions.map((permission) => permission.persona);
    const uniquePersonas = Array.from(new Set(personas));
    const roles = uniquePersonas?.map((permission: Persona) => {
        return `${getRoleName(permission, cultureCode)} (${
            personas?.filter((persona) => persona === permission).length
        })`;
    });

    return roles ? roles.sort().join(', ') : '';
};

export const getPendingRequestsRoles = (personas: any, cultureCode: Culture): string => {
    const roles = personas?.map((persona) => {
        return `${getRoleName(persona, cultureCode)}`;
    });
    return roles ? roles.sort().join(', ') : '';
};

export const clearTableValues = (currentAccounts, dispatch) => {
    const accounts = currentAccounts.map((account) => {
        return account.accountId;
    });
    Object.values(RoleAccessKey).forEach((key) => {
        revokeAllLocationRoleAccess(accounts, key, dispatch);
    });
};

export const getTableUserPermissions = (accounts: Array<ShipToRoleAccess>) => {
    const permissions: Array<UserPermission> = [];
    Object.values(RoleAccessKey).forEach((key) => {
        const accountIds = accounts.reduce((prev, curr) => {
            if (curr[key] === true) {
                prev.push(curr.accountId);
            }
            return prev;
        }, [] as Array<string>);
        if (accountIds.length) {
            const permission: UserPermission = {
                personaId: getPersonaFromKey(key),
                accountIds: accountIds
            };
            permissions.push(permission);
        }
    });
    return permissions;
};

function getPersonaFromKey(key: RoleAccessKey): Persona {
    switch (key) {
        case RoleAccessKey.AllAccess:
            return Persona.AllAccess;
        case RoleAccessKey.AcctPayableReceivable:
            return Persona.ArAp;
        case RoleAccessKey.ArtMgt:
            return Persona.ArtMgmt;
        case RoleAccessKey.CoPacker:
            return Persona.CoPacker;
        case RoleAccessKey.ForecastMgt:
            return Persona.ForecastMgmt;
        case RoleAccessKey.GraphicsVendor:
            return Persona.GraphicsVendor;
        case RoleAccessKey.MarketingMgt:
            return Persona.MarketingMgmt;
        case RoleAccessKey.OrderFullfillment:
            return Persona.OrderFulfillment;
        case RoleAccessKey.PackagingMgt:
            return Persona.PackagingMgmt;
        case RoleAccessKey.ProcurementMgt:
            return Persona.ProcurementMgmt;
        case RoleAccessKey.WarehousingLogistics:
            return Persona.WarehousingLogistics;
        case RoleAccessKey.PricingContact:
            return Persona.PricingContact;
        case RoleAccessKey.MakeItOnly:
            return Persona.MakeItOnly;
        case RoleAccessKey.ShipItOnly:
            return Persona.ShipItOnly;
    }
}

export function hasAnyPermissions(accountPermissions: ShipToRoleAccess[]): boolean {
    let roles = accountPermissions.map((account) => [
        account.acctPayableReceivable,
        account.allAccess,
        account.artMgt,
        account.coPacker,
        account.forecastMgt,
        account.graphicsVendor,
        account.marketingMgt,
        account.orderFullfillment,
        account.packagingMgt,
        account.procurementMgt,
        account.warehousingLogistics,
        account.pricingContact
    ]);
    return roles.flat().includes(true);
}

export function getPermissionChangeItems(accounts: ShipToRoleAccess[]): PermissionChangeItem[] {
    const permissions: PermissionChangeItem[] = [];
    accounts.forEach((account) => {
        let personas: string[] = [];
        Object.values(RoleAccessKey).forEach((key) => {
            if (account[key] === true) {
                personas.push(getPersonaFromKey(key));
            }
        });
        permissions.push({
            accountId: account.accountId,
            personas
        });
    });
    return permissions;
}
