import { Activity, SecurityLevel } from '../../utility/auth/useSecurity';
import * as types from './action-types';

export const setSecurityLevel = (
    activity: Activity,
    securityLevel: SecurityLevel,
    personas?: string
) => ({
    type: types.SECURITY_LEVEL_SET,
    activity: activity,
    securityLevel: securityLevel,
    personas: personas
});

export const clearSecurityLevel = () => ({
    type: types.SECURITY_LEVEL_CLEARED,
    activity: undefined,
    securityLevel: SecurityLevel.None,
    personas: undefined
});

export const setPersonas = (personas?: string) => ({
    type: types.SECURITY_LEVEL_SET_PERSONAS,
    personas: personas
});
