import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { ballGray } from '../../../../themes/globalConstants';

interface Props {
    attributeDescription: string;
    secondaryAttributes: string;
}

const useStyles = makeStyles((theme) => ({
    main: {
        borderTop: 'none',
        borderRadius: 'unset'
    },
    toolbarWrapper: {
        alignItems: 'center',
        '& .MuiGrid-container': {
            margin: 0
        }
    },
    btn: {
        padding: 0,
        minWidth: 'auto'
    },
    subtitle: {
        color: ballGray
    },
    attributeDescription: {
        marginLeft: '1em'
    }
}));

const PlanItOrderDetailsToolbar = ({ attributeDescription, secondaryAttributes }: Props) => {
    const classes = useStyles();

    useTranslation();

    return (
        <Grid container className={classes.toolbarWrapper}>
            <Grid container item xs={6} justify="flex-start">
                <Grid item xs={5} className={classes.attributeDescription}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="productAttributes">Product Attributes</Trans>
                    </Typography>
                    <Typography className="classes.shipToText" data-testid="attribute-description">
                        {attributeDescription}
                    </Typography>
                    <Typography className="classes.shipToText" data-testid="secondary-attributes">
                        {secondaryAttributes}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PlanItOrderDetailsToolbar;
