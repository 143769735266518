import React, { useState } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { boldWeight, small, white, addFormItemButtonBlue } from '../../../themes/globalConstants';
import { useTranslation } from 'react-i18next';
import { shipAltGreen } from './../../../themes/globalConstants';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

interface Props {
    isActivate: boolean;
    onChange: (state: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
    confirmButton: {
        fontSize: 14,
        height: 14,
        fontWeight: boldWeight,
        textTransform: 'capitalize',
        border: 'none',
        '&.MuiToggleButton-root.Mui-selected': {
            backgroundColor: 'transparent',
            color: addFormItemButtonBlue
        }
    },
    toggleControl: {
        '& .MuiTypography-body1': {
            fontWeight: boldWeight,
            fontSize: small,
            textTransform: 'uppercase'
        },
        '& .MuiSwitch-thumb': {
            backgroundColor: white
        },
        '& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track': {
            backgroundColor: shipAltGreen,
            opacity: 1
        }
    }
}));

const ProductStatusToggleConfirmation = ({ isActivate, onChange }: Props) => {
    const { t } = useTranslation();
    useTranslation();
    const classes = useStyles();

    const [toastConfirmed, setToastConfirmed] = useState<undefined | boolean>(undefined);

    const toggleHandler = (event, toastIsConfirmed: boolean) => {
        setToastConfirmed(toastIsConfirmed);
        onChange(toastIsConfirmed);
    };

    return (
        <>
            <Grid item>
                <Typography>
                    {isActivate
                        ? t('confirmActivation', 'Are you sure you want to activate this product?')
                        : t(
                              'confirmDeactivation',
                              'Are you sure you want to deactivate this product?'
                          )}
                </Typography>
            </Grid>
            <ToggleButtonGroup exclusive onChange={toggleHandler} value={toastConfirmed}>
                <ToggleButton
                    value={true}
                    className={classes.confirmButton}
                    data-testid="yes-confirmation"
                >
                    <u>
                        <Typography>Yes</Typography>
                    </u>
                </ToggleButton>
                <ToggleButton
                    value={false}
                    className={classes.confirmButton}
                    data-testid="no-confirmation"
                >
                    <u>
                        <Typography>No</Typography>
                    </u>
                </ToggleButton>
            </ToggleButtonGroup>
        </>
    );
};

export default ProductStatusToggleConfirmation;
