import React, { ChangeEvent, FocusEvent } from 'react';
import { InputAdornment, TextField, makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { ballBlue, dateOptionInvalidGray } from '../../../themes/globalConstants';

interface Props {
    onChange?: (e: ChangeEvent<HTMLInputElement>) => any;
    onBlur?: (e: FocusEvent<HTMLInputElement>) => any;
    inputProps?: { 'data-testid': string };
}

const useStyles = makeStyles(() => ({
    searchInput: {
        '& .MuiInputBase-formControl': {
            border: `1px solid ${dateOptionInvalidGray}`,
            borderRadius: '.125em',
            '&:before': {
                display: 'none'
            },
            '&:after': {
                display: 'none'
            },
            '& svg': {
                height: '.875em',
                width: '.875em',
                fill: ballBlue
            },
            '& .MuiIconButton-root': {
                '&:hover': {
                    backgroundColor: 'transparent !important'
                }
            },
            '& .MuiInput-input': {
                paddingLeft: '.25em'
            },
            // Manual override to force position 'end' on icon because position="end" attribute is not working.
            '& .MuiInputAdornment-root': {
                marginLeft: 8,
                marginRight: 0
            }
        }
    }
}));

const defaultInputProps = {
    'data-testid': 'search-bar'
};

const SearchBar = ({ onChange, onBlur, inputProps }: Props) => {
    const classes = useStyles();
    inputProps = { ...defaultInputProps, ...inputProps };

    return (
        <TextField
            className={classes.searchInput}
            variant="standard"
            fullWidth
            InputProps={{
                startAdornment: (
                    <InputAdornment position="end">
                        <SearchIcon color="primary" />
                    </InputAdornment>
                )
            }}
            inputProps={inputProps}
            onChange={onChange}
            onBlur={onBlur}
        />
    );
};

export default SearchBar;
