import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { useTranslation, Trans } from 'react-i18next';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Grid, makeStyles, Typography, Paper } from '@material-ui/core';
import {
    getWeek,
    removeYearFromDate,
    setDateTimeToMidnight
} from '../../../../utility/helpers/date-helpers';
import { large, boldWeight, blackWeight, small, xs } from '../../../../themes/globalConstants';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import Modal from '../../../reusable/molecules/Modal';
import DaypickerRangeModal from '../../../reusable/molecules/DaypickerRangeModal';
import { Moment } from 'moment';
import { getMakeItFirstAvailableDate } from '../utilities/make-it-utils';
import { enUS } from '../../../../utility/translations/locales';
import { setRequestedMakeItWeek } from '../../../../store/actions/makeit-dashboard';
import MakeItChangeRequestedWeekModal from './MakeItChangeRequestedWeekModal';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { MakeState } from '../../../../store/reducers/makeit-dashboard';

interface Props {
    onDateUpdate: () => void;
    itemsInCart: number;
    lockedWindowDays: number;
    dateLimit?: number;
    hideSubtext?: boolean;
    selectedShipToId?: string;
}

interface DateProperties {
    fullDate: string;
    week: string;
    year: string;
}

const useStyles = makeStyles((theme) => ({
    dateBar: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginTop: '1.5em',
        '& > div': {
            '&:first-of-type': {
                borderTopLeftRadius: '0.25em',
                borderBottomLeftRadius: '0.25em',
                width: '9em'
            },
            '&:last-of-type': {
                borderTopRightRadius: '0.25em',
                borderBottomRightRadius: '0.25em',
                width: '4em'
            }
        }
    },
    dateOption: {
        color: theme.palette.secondary.main,
        height: '3.125em',
        cursor: 'pointer',
        minWidth: '5em',
        '&:hover': {
            borderBottomLeftRadius: '0.25em',
            borderBottomRightRadius: '0.25em',
            '& > div:first-child': {
                borderBottomLeftRadius: 'unset',
                color: theme.palette.primary.main,
                '& p': {
                    fontWeight: blackWeight
                }
            },
            '& > div:last-child': {
                borderBottomRightRadius: 'unset'
            },
            boxShadow:
                'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px'
        }
    },
    dateOptionMain: {
        textAlign: 'center',
        padding: '0.250em',
        fontWeight: boldWeight,
        '& p': {
            width: '100%'
        }
    },
    dateOptionDisabled: {
        pointerEvents: 'none',
        opacity: '0.4'
    },
    dateOptionTag: {
        fontSize: small,
        padding: '0.75em',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        borderBottomLeftRadius: '0.25em',
        borderBottomRightRadius: '0.25em',
        border: `1px solid ${theme.palette.primary.main}`,
        width: '100%',
        '& p': {
            fontWeight: blackWeight,
            fontSize: xs
        }
    },
    dateOptionWeek: {
        fontSize: large
    },
    dateOptionYear: {
        fontSize: small
    },
    dateOptionBtn: {
        height: '3.125em',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '& > svg': {
            fill: theme.palette.primary.main
        }
    },
    stepNum: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        borderRadius: '50%',
        width: '1.875em',
        height: '1.875em',
        fontSize: large,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: blackWeight
    },
    stepWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    stepTitle: {
        fontWeight: blackWeight,
        color: theme.palette.secondary.main,
        paddingLeft: '0.5em'
    },
    stepText: {
        marginTop: '0.875em',
        marginBottom: '1.5em'
    },
    activeDate: {
        color: theme.palette.primary.main,
        borderBottomLeftRadius: '0.25em',
        borderBottomRightRadius: '0.25em',
        boxShadow: `rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, 
                    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, 
                    rgba(0, 0, 0, 0.12) 0px 1px 5px 0px`,
        '& p': {
            fontWeight: blackWeight
        }
    },
    calendarDiv: {
        margin: '2em'
    },
    plainTextDate: {
        fontSize: small,
        background: 'none',
        color: theme.palette.primary.main,
        '& p': {
            fontWeight: blackWeight,
            fontSize: small
        },
        right: 0,
        textAlign: 'right',
        marginTop: '0.5em'
    },
    firstDateOption: {
        width: '9em'
    }
}));

const getDate = (weekNum: number) => {
    const fullDate = getWeek(weekNum);
    const week = moment(fullDate).format('MM/DD');
    const year = moment(fullDate).format('YYYY');

    return {
        fullDate: fullDate,
        week: week,
        year: year
    } as DateProperties;
};

interface OriginalRange {
    startDate: Moment;
    endDate: Moment;
}

export default function MakeItRequestOptions({
    itemsInCart,
    onDateUpdate,
    lockedWindowDays: leadTime,
    dateLimit = 4,
    hideSubtext = false,
    selectedShipToId
}: Props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const today = moment().format('MM/DD/YYYY');
    const [activeDate, setActiveDate] = useState<DateProperties>({
        fullDate: '',
        week: '',
        year: ''
    });
    //Daypicker State
    const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<Moment>(moment());
    const [endDate, setEndDate] = useState<Moment>(moment());
    const [openChangeRequestedWeek, setOpenChangeRequestedWeek] = useState<boolean>(false);
    const currentDate = removeYearFromDate(moment().format('L'));
    const [weekClicked, setWeekClicked] = useState<any>();
    const [toOpenCalendar, setToOpenCalendar] = useState<boolean>(false);
    const [originalRange, setOriginalRange] = useState<OriginalRange>({
        startDate: moment(),
        endDate: moment()
    });
    const [weekOffset, setWeekOffset] = useState<number>(0);
    const { requestedDate } = useTypedSelector<MakeState>((state) => state.makeItDashboard);

    const handleClick = (x) => {
        if (itemsInCart > 0) {
            setWeekClicked(x);
            setOpenChangeRequestedWeek(true);
        } else {
            updateRequestedWeek(x);
        }
    };

    const onContinueClicked = () => {
        setOpenChangeRequestedWeek(false);

        if (toOpenCalendar) {
            setCalendarOpen(true);
            setToOpenCalendar(false);
        } else if (weekClicked) {
            updateRequestedWeek(weekClicked);
        }
    };

    const updateRequestedWeek = (selectedDate: DateProperties) => {
        const momentDateString = moment(selectedDate.fullDate);
        setActiveDate(selectedDate);
        setStartDate(momentDateString);
        calculateInitialDateRange(momentDateString);
        dispatch(setRequestedMakeItWeek(setDateTimeToMidnight(selectedDate.fullDate)));
        onDateUpdate();
    };

    const onCloseChangeRequestedWeek = () => {
        setOpenChangeRequestedWeek(false);
    };

    const handleCalendarOpen = () => {
        setOriginalRange({ startDate: startDate, endDate: endDate });

        if (itemsInCart > 0) {
            setToOpenCalendar(true);
            setOpenChangeRequestedWeek(true);
        } else {
            setCalendarOpen(true);
        }
    };

    const handleCalendarClose = () => {
        handleDateRangeChange(originalRange.startDate, originalRange.endDate);

        setCalendarOpen(false);
    };

    const handleOkClose = () => {
        setCalendarOpen(false);

        const date = activeDate.week + '/' + activeDate.year;
        const momentDateString = setDateTimeToMidnight(date);
        dispatch(setRequestedMakeItWeek(momentDateString));
    };

    const handleDateRangeChange = (startDate, endDate) => {
        const activeWeek = moment(startDate).format('MM/DD');
        const activeYear = moment(startDate).format('YYYY');
        setActiveDate({ fullDate: startDate, week: activeWeek, year: activeYear });
        setStartDate(startDate);
        setEndDate(endDate);
    };

    const calculateFirstAvailableDay = () => {
        const firstAvailableDay = getMakeItFirstAvailableDate(leadTime);
        return removeYearFromDate(firstAvailableDay.format('L'));
    };

    //Should open on default month available OR month of previously selected day
    const calculateFirstMonthAvailable = () => {
        if (!startDate) {
            const firstAvailableDay = moment().add(leadTime, 'days').isoWeekday(1);
            return firstAvailableDay.add(7, 'weeks');
        } else {
            return startDate;
        }
    };

    const calculateInitialStartDate = useCallback(() => {
        const initialStartDate = getMakeItFirstAvailableDate(leadTime).add(dateLimit, 'weeks');
        setStartDate(initialStartDate);
        calculateInitialDateRange(initialStartDate);
    }, [dateLimit, leadTime]);

    const calculateOutsideRange = (day) => {
        const today = moment();
        return day.isBefore(today.add(weekOffset - 1, 'weeks').endOf('isoWeek'));
    };

    const calculateInitialDateRange = (initialStartDate) => {
        const otherDate = moment(initialStartDate).startOf('day').add(6, 'days');
        setEndDate(otherDate);
        setOriginalRange({ startDate: initialStartDate, endDate: otherDate });
    };

    const getWeekOffset = useCallback((): number => {
        const firstAvailableDate = getMakeItFirstAvailableDate(leadTime);
        let offset = 0;
        while (true) {
            if (moment(getDate(offset + 1).fullDate).isBefore(firstAvailableDate)) {
                offset++;
            } else {
                break;
            }
        }
        return offset;
    }, [leadTime]);

    useEffect(() => {
        const defaultDate =
            requestedDate && requestedDate !== 'Invalid date'
                ? moment(requestedDate)
                : getMakeItFirstAvailableDate(leadTime);
        const activeWeek = moment(defaultDate).format('MM/DD');
        const activeYear = moment(defaultDate).format('YYYY');
        const fullDate = defaultDate.format('MM/DD/YYYY');

        dispatch(setRequestedMakeItWeek(defaultDate));
        setActiveDate({ fullDate: fullDate, week: activeWeek, year: activeYear });
        calculateInitialStartDate();

        const weekOffset = getWeekOffset();
        setWeekOffset(weekOffset);
        // trigger based on requestedDate will set Initial date for popup calendar
    }, [selectedShipToId, leadTime, dispatch, calculateInitialStartDate, getWeekOffset]);

    useEffect(() => {
        dispatch(setRequestedMakeItWeek(moment(activeDate.fullDate, 'MM/DD/YYYY')));
    }, [dispatch, activeDate]);

    const buildWeeksOptions = (numOfWeeks: number) => {
        let options: any[] = [];

        for (let x = 0; x < numOfWeeks; x++) {
            options.push(
                <Paper
                    variant="outlined"
                    data-testid="week-option-btns"
                    square
                    elevation={2}
                    className={clsx(classes.dateOption)}
                    key={x}
                    onClick={() => handleClick(getDate(x + weekOffset))}
                    data-value={`${getDate(x + weekOffset).fullDate}`}
                >
                    <Grid container className={clsx(x === 0 && classes.firstDateOption)}>
                        <Grid
                            container
                            item
                            justify="center"
                            className={clsx(
                                classes.dateOptionMain,
                                getDate(x + weekOffset).week === activeDate.week &&
                                    classes.activeDate
                            )}
                        >
                            <Typography className={classes.dateOptionWeek}>
                                {getDate(x + weekOffset).week}
                            </Typography>
                            <Typography className={classes.dateOptionYear}>
                                {getDate(x + weekOffset).year}
                            </Typography>
                        </Grid>
                        <Grid container item justify="space-between">
                            {x === 0 && (
                                <Grid container item className={classes.dateOptionTag}>
                                    <Grid item xs={12}>
                                        <Typography data-testid="today-text">
                                            <Trans i18nKey="today">Today</Trans>: {currentDate}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography data-testid="first-available-text">
                                            <Trans i18nKey="firstAvailable">First Available</Trans>:{' '}
                                            {calculateFirstAvailableDay()}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            );
        }
        options.push(
            <>
                <Paper
                    variant="outlined"
                    square
                    elevation={2}
                    key={numOfWeeks + 1}
                    className={classes.dateOptionBtn}
                    onClick={handleCalendarOpen}
                    data-testid="ellipse-btn"
                >
                    {<MoreHorizIcon />}
                </Paper>
            </>
        );

        return options;
    };

    return (
        <>
            <Grid data-testid="makeit-date-selector" container item justify="space-between">
                <Grid container item xs={12}>
                    <Grid item className={classes.stepWrapper}>
                        <Typography component="span" className={classes.stepNum}>
                            1
                        </Typography>
                        <Typography variant="h3" className={classes.stepTitle}>
                            <Trans i18nKey="selectRequestedWk">{enUS.selectRequestedWk}</Trans>:
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item>
                    <div>
                        <Grid item xs={12} className={classes.dateBar}>
                            {buildWeeksOptions(dateLimit)}
                        </Grid>
                        <Grid container item justify="flex-end">
                            <Grid item className={classes.plainTextDate}>
                                <Typography
                                    data-value={`${activeDate.week}/${activeDate.year}`}
                                    data-testid="selected-week"
                                >
                                    <Trans i18nKey="selectedWeek">Selected Week</Trans>:
                                    <br />
                                    {activeDate.week}
                                    {'/'}
                                    {activeDate.year}
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
            <Modal
                open={calendarOpen}
                title={<Trans i18nKey="todayWithDate">Today: {{ today }}</Trans>}
                secondaryTitle={
                    <>
                        {!hideSubtext && (
                            <Trans i18nKey="firstShipment">
                                Your first shipment must be at least {{ leadTime }} days from today
                            </Trans>
                        )}
                    </>
                }
                close={handleCalendarClose}
                closeIcon={true}
                maxWidth="xl"
                data-testid="request-day-modal"
            >
                <div className={classes.calendarDiv}>
                    <DaypickerRangeModal
                        startDate={startDate}
                        endDate={endDate}
                        onCancel={handleCalendarClose}
                        onOk={handleOkClose}
                        firstMonthVisible={calculateFirstMonthAvailable}
                        isOutsideRange={calculateOutsideRange}
                        onDateChange={(startDate, endDate) =>
                            handleDateRangeChange(startDate, endDate)
                        }
                    />
                </div>
            </Modal>
            <Modal
                open={openChangeRequestedWeek}
                title={t('changeRequestedWeek', 'Change Requested Week')}
                close={onCloseChangeRequestedWeek}
                closeIcon={true}
                maxWidth={'sm'}
            >
                <MakeItChangeRequestedWeekModal
                    onContinue={onContinueClicked}
                    onCancel={onCloseChangeRequestedWeek}
                />
            </Modal>
        </>
    );
}
