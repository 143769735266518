import React from 'react';
import PdfPageTemplate from '../../../templates/PdfPageTemplate';
import { Document, Page, Text, View, BlobProvider, Image } from '@react-pdf/renderer';
import pdfStyles from '../../../../themes/pdfStyles';
import { isIE } from '../../../../utility/helpers/user-helpers';
import {
    getFormattedDateStringForApplication,
    getSimpleFormattedDate,
    getSimpleFormattedTime
} from '../../../../utility/helpers/date-helpers';
import {
    formatStatus,
    getFilteredProductionOrders,
    getGraphicIdAndVersion,
    orderStatusCodeFormatter
} from '../../../../utility/helpers/order-helpers';
import { useTranslation, Trans } from 'react-i18next';
import {
    DeliveryOrder,
    DeliveryShipment,
    LinkedProductionOrders,
    DeliveryOrderLinkedProductionOrder,
    ProductToShip
} from '../../../../store/reducers/shipping-dashboard';

interface Props {
    title: string;
    shipToName: string;
    shipToAddress: string;
    order: DeliveryOrder;
    linkedDeliveryOrder?: LinkedProductionOrders;
}

const DeliveryOrderPDF = ({
    title,
    shipToName,
    shipToAddress,
    order,
    linkedDeliveryOrder
}: Props) => {
    const ballLogo = process.env.PUBLIC_URL + '/assets/Ball_RGB.jpg';
    const sourceLogo = process.env.PUBLIC_URL + '/assets/TheSource_Logo.png';

    const { t } = useTranslation();

    const deliveryOrderShipments = order?.shipments?.map(
        (shipment: DeliveryShipment, index: number) => {
            const deliveryDate = getSimpleFormattedDate('', shipment.deliveryDateTime);
            const deliveryTime = getSimpleFormattedTime('', shipment.deliveryDateTime);

            return (
                <>
                    <View style={pdfStyles.borderlessTable}>
                        <View style={pdfStyles.tableRow}>
                            <View style={pdfStyles.borderLessTableColNarrow3}>
                                <Text style={pdfStyles.header3}>
                                    <Trans i18nKey="shipment">Shipment</Trans>
                                    {' ' + shipment.truckId}
                                </Text>
                            </View>
                        </View>
                        <View style={pdfStyles.tableRow}>
                            <View style={pdfStyles.borderLessTableColNarrow2}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="dateTime">Date / Time</Trans>
                                </Text>
                            </View>
                            <View style={pdfStyles.borderLessTableCol}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="shipTo">Ship To</Trans>
                                </Text>
                            </View>
                            <View style={pdfStyles.borderLessTableCol}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="shipmentShippedFrom">Shipped From</Trans>
                                </Text>
                            </View>
                            <View style={pdfStyles.borderLessTableCol}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="shipmentCarrier">Carrier</Trans>
                                </Text>
                            </View>
                            <View style={pdfStyles.borderLessTableColNarrow2}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="cans">Cans</Trans>
                                </Text>
                            </View>
                            <View style={pdfStyles.borderLessTableColNarrow2}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="ends">Ends</Trans>
                                </Text>
                            </View>
                        </View>
                        <View style={pdfStyles.tableRow}>
                            <View style={pdfStyles.borderLessTableColNarrow2}>
                                <Text style={pdfStyles.subtitle3}>{deliveryDate}</Text>
                                <Text style={pdfStyles.subtitle3}>{deliveryTime}</Text>
                            </View>
                            <View style={pdfStyles.borderLessTableCol}>
                                <Text style={pdfStyles.subtitle3}>{shipToName}</Text>
                                <Text style={pdfStyles.subtitle3}>{shipToAddress}</Text>
                            </View>
                            <View style={pdfStyles.borderLessTableCol}>
                                <Text style={pdfStyles.subtitle3}>{shipment.warehouseName}</Text>
                            </View>
                            <View style={pdfStyles.borderLessTableCol}>
                                <Text style={pdfStyles.subtitle3}>{shipment.carrier}</Text>
                            </View>
                            <View style={pdfStyles.borderLessTableColNarrow2}>
                                <Text style={pdfStyles.subtitle3}>
                                    {shipment.canQuantityPallets} PL / {shipment.canQuantitySKUs}{' '}
                                    SKU
                                </Text>
                            </View>
                            <View style={pdfStyles.borderLessTableColNarrow2}>
                                <Text style={pdfStyles.subtitle3}>
                                    {shipment.endQuantityPallets} PL / {shipment.endQuantitySKUs}{' '}
                                    SKU
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View style={[pdfStyles.borderlessTable, pdfStyles.marginTop20]}>
                        <View style={pdfStyles.tableRow}>
                            <View style={pdfStyles.borderLessTableColNarrow}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="productId">Product Id</Trans>
                                    {' /'}
                                </Text>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="graphicId">Graphic Id</Trans>
                                </Text>
                            </View>
                            <View style={pdfStyles.borderLessTableColWide3}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="productDescription">Product Description</Trans>
                                    {' /'}
                                </Text>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="characteristics">Characteristics</Trans>
                                </Text>
                            </View>
                            <View style={pdfStyles.borderLessTableColNarrow2}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="quantity">Quantity</Trans>
                                </Text>
                            </View>
                            <View style={pdfStyles.borderLessTableColNarrow2}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="releaseNumberPDF">Release #</Trans>
                                </Text>
                            </View>
                            <View style={pdfStyles.borderLessTableColNarrow9}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="fillerLineNumber">Filler Line #</Trans>
                                </Text>
                            </View>
                            <View style={pdfStyles.borderLessTableColNarrow2}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="referenceNumberPDF">Reference #</Trans>
                                </Text>
                            </View>
                            <View style={pdfStyles.borderLessTableColNarrow2}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="status">Status</Trans>
                                </Text>
                            </View>
                            <View style={pdfStyles.borderLessTableColNarrow2}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="customerPoNumber">Customer PO #</Trans>
                                </Text>
                            </View>
                            <View style={pdfStyles.borderLessTableCol}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="deliveryInstructions">
                                        Delivery Instructions
                                    </Trans>
                                </Text>
                            </View>
                        </View>
                        <View style={pdfStyles.divider5} />
                        {shipment.loads?.map((load: ProductToShip, index: number) => {
                            let filteredProductionOrder: DeliveryOrderLinkedProductionOrder =
                                getFilteredProductionOrders(load.loadId, linkedDeliveryOrder);

                            return (
                                <>
                                    <View style={pdfStyles.tableRow}>
                                        <View style={pdfStyles.borderlessTable}>
                                            <View style={pdfStyles.tableRow}>
                                                <View style={pdfStyles.borderLessTableColNarrow}>
                                                    <Text style={pdfStyles.tableCell2}>
                                                        {load.displayId}
                                                    </Text>
                                                    <Text style={pdfStyles.tableCell2}>
                                                        {getGraphicIdAndVersion(
                                                            load.type,
                                                            load.productGraphicId
                                                        )}
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={[pdfStyles.borderLessTableColWide3]}>
                                            <Text style={[pdfStyles.tableCell2]}>
                                                {load.displayName}
                                            </Text>
                                            <Text style={pdfStyles.tableCell2}>
                                                {load.description}
                                            </Text>
                                        </View>
                                        <View style={pdfStyles.borderLessTableColNarrow2}>
                                            <Text style={pdfStyles.tableCell2}>
                                                <Text style={pdfStyles.tableCell2}>
                                                    {load.palletQuantity} PL
                                                </Text>
                                            </Text>
                                        </View>
                                        <View style={pdfStyles.borderLessTableColNarrow2}>
                                            <Text style={pdfStyles.tableCell2}>
                                                {load.releaseNumber}
                                            </Text>
                                        </View>
                                        <View style={pdfStyles.borderLessTableColNarrow9}>
                                            <Text style={pdfStyles.tableCell2}>
                                                {load.fillerLine}
                                            </Text>
                                        </View>
                                        <View style={pdfStyles.borderLessTableColNarrow2}>
                                            <Text style={pdfStyles.tableCell2}>
                                                {load.referenceNumber}
                                            </Text>
                                        </View>
                                        <View style={pdfStyles.borderLessTableColNarrow2}>
                                            <Text style={pdfStyles.tableCell2}>
                                                <Trans
                                                    i18nKey={orderStatusCodeFormatter(load.status)}
                                                >
                                                    {formatStatus(load.status)}
                                                </Trans>
                                            </Text>
                                        </View>
                                        <View style={pdfStyles.borderLessTableColNarrow2}>
                                            <Text style={pdfStyles.tableCell2}>
                                                {load.purchaseOrderNumber}
                                            </Text>
                                        </View>
                                        <View style={pdfStyles.borderLessTableCol}>
                                            <Text style={pdfStyles.tableCell2}>
                                                {load.deliveryInstructions}
                                            </Text>
                                        </View>
                                    </View>
                                    {linkedDeliveryOrder && filteredProductionOrder && (
                                        <>
                                            <View
                                                style={[pdfStyles.tableRow, pdfStyles.marginTop10]}
                                            >
                                                <View
                                                    style={pdfStyles.borderLessTableColNarrow3}
                                                ></View>
                                                <View style={pdfStyles.borderLessTableColWide}>
                                                    <Text style={pdfStyles.header2}>
                                                        <Trans i18nKey="linkedProductionOrder">
                                                            Production Order
                                                        </Trans>
                                                        {' #'}
                                                    </Text>
                                                </View>
                                                <View style={pdfStyles.borderLessTableColNarrow2}>
                                                    <Text style={pdfStyles.header2}>
                                                        <Trans i18nKey="quantity">Quantity</Trans>
                                                    </Text>
                                                </View>
                                                <View style={pdfStyles.borderLessTableColNarrow2}>
                                                    <Text style={pdfStyles.header2}></Text>
                                                </View>
                                                <View style={pdfStyles.borderLessTableColNarrow2}>
                                                    <Text style={pdfStyles.header2}></Text>
                                                </View>
                                                <View style={pdfStyles.borderLessTableColNarrow2}>
                                                    <Text style={pdfStyles.header2}></Text>
                                                </View>
                                                <View style={pdfStyles.borderLessTableColNarrow2}>
                                                    <Text style={pdfStyles.header2}></Text>
                                                </View>
                                            </View>
                                            <View style={pdfStyles.tableRow}>
                                                <View
                                                    style={pdfStyles.borderLessTableColNarrow3}
                                                ></View>
                                                <View style={pdfStyles.dividerThinGray} />
                                            </View>
                                            <View style={pdfStyles.tableRow}>
                                                <View
                                                    style={pdfStyles.borderLessTableColNarrow3}
                                                ></View>
                                                <View style={pdfStyles.borderLessTableColWide}>
                                                    <Text style={pdfStyles.tableCell2}>
                                                        {
                                                            filteredProductionOrder.customerProductionOrderId
                                                        }
                                                    </Text>
                                                </View>
                                                <View style={pdfStyles.borderLessTableColNarrow2}>
                                                    <Text style={pdfStyles.tableCell2}>
                                                        {filteredProductionOrder.quantity} PL
                                                    </Text>
                                                </View>
                                                <View style={pdfStyles.borderLessTableColNarrow2}>
                                                    <Text style={pdfStyles.tableCell2}></Text>
                                                </View>
                                                <View style={pdfStyles.borderLessTableColNarrow2}>
                                                    <Text style={pdfStyles.tableCell2}></Text>
                                                </View>
                                                <View style={pdfStyles.borderLessTableColNarrow2}>
                                                    <Text style={pdfStyles.tableCell2}></Text>
                                                </View>
                                                <View style={pdfStyles.borderLessTableColNarrow2}>
                                                    <Text style={pdfStyles.tableCell2}></Text>
                                                </View>
                                            </View>
                                        </>
                                    )}
                                    {index + 1 !== shipment.loads?.length && (
                                        <View style={pdfStyles.dividerThinBlack} />
                                    )}
                                </>
                            );
                        })}
                    </View>
                    <View style={pdfStyles.divider} />
                </>
            );
        }
    );

    const myDoc = (
        <Document title={title}>
            <Page size="A4" style={pdfStyles.page} orientation={'landscape'}>
                <View style={pdfStyles.logoSection} fixed>
                    <Image src={sourceLogo} style={pdfStyles.sourceLogo} />
                    <Image src={ballLogo} style={pdfStyles.ballLogo} />
                </View>
                <View style={pdfStyles.divider} fixed />
                <View style={pdfStyles.borderlessTable}>
                    <View style={pdfStyles.tableRow}>
                        <View style={pdfStyles.borderLessTableColNarrow4}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="deliveryOrder">Delivery Order</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColWide5}></View>
                        <View style={pdfStyles.borderLessTableColNarrow3}>
                            <Text
                                style={[
                                    pdfStyles.header2,
                                    pdfStyles.textAlignRight,
                                    pdfStyles.marginRight20
                                ]}
                            >
                                <Trans i18nKey="total">Total</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow3}>
                            <Text style={[pdfStyles.header2, pdfStyles.textAlignRight]}>
                                <Trans i18nKey="submitted">Submitted</Trans>
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                        <View style={pdfStyles.borderLessTableColNarrow4}>
                            <Text style={pdfStyles.subtitle2}>
                                {order?.deliveryOrderNumber
                                    ? order?.deliveryOrderNumber
                                    : t('pending', 'Pending')}
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColWide5}></View>
                        <View style={pdfStyles.borderLessTableColNarrow3}>
                            <Text
                                style={[
                                    pdfStyles.subtitle2,
                                    pdfStyles.textAlignRight,
                                    pdfStyles.marginRight20
                                ]}
                            >
                                {order?.totalOrderQuantity +
                                    ' PL / ' +
                                    order?.totalOrderSkus +
                                    ' SKU'}
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow3}>
                            <Text style={[pdfStyles.subtitle2, pdfStyles.textAlignRight]}>
                                {getFormattedDateStringForApplication(order?.submittedDate)}
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={pdfStyles.divider} />
                {deliveryOrderShipments}
                <View style={[pdfStyles.fullWidth, pdfStyles.footer]} fixed>
                    <Text
                        style={pdfStyles.header2}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </View>
            </Page>
        </Document>
    );

    if (!isIE()) {
        return <PdfPageTemplate title={title}>{myDoc}</PdfPageTemplate>;
    } else {
        return (
            <BlobProvider document={myDoc}>
                {({ blob, url, loading, error }) => {
                    if (blob && window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
                        (window.navigator as any).msSaveOrOpenBlob(blob);
                    }
                    return null;
                }}
            </BlobProvider>
        );
    }
};

export default DeliveryOrderPDF;
