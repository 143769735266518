import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class ScrapItColumns {
    static getScrapItColumns = (culture: string, isReviewGrid?: boolean) => {
        return [
            createColumn('displayId', {
                dataType: ColumnDataType.Numeric,
                label: `${TranslationService.getTranslatedText(
                    culture,
                    'productDescription'
                )} (${TranslationService.getTranslatedText(
                    culture,
                    'ballProductId'
                )} / ${TranslationService.getTranslatedText(culture, 'graphicId')})`,
                filterable: true,
                sortable: true,
                searchable: true
            }),
            createColumn('customerProductId', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'customerProductId'),
                filterable: true,
                sortable: true,
                searchable: true,
            }),
            createColumn('graphicIdAndVersion', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('graphicId', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('displayName', {
                dataType: ColumnDataType.String,
                filterable: true,
                sortable: true,
                searchable: true,
                visible: false
            }),
            createColumn('productSku', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('name', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('description', {
                dataType: ColumnDataType.String,
                filterable: true,
                sortable: true,
                searchable: true,
                visible: false
            }),
            //Hidden until Multiple ShipTo Selection allowed
            createColumn('brand', {
                dataType: ColumnDataType.String,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('coating', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'coating'),
                filterable: true,
                sortable: true,
                searchable: true
            }),
            createColumn('status', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'status'),
                filterable: true,
                sortable: true,
                searchable: true,
                visible: !isReviewGrid
            }),
            createColumn('availablePallets', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'availablePallets'),
                filterable: true,
                sortable: true
            }),
            createColumn('agedPalletQuantity', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'agedBalance'),
                filterable: true,
                sortable: true
            }),
            createColumn('availableItemsPerPallet', {
                dataType: ColumnDataType.Numeric,
                filterable: false,
                sortable: false,
                visible: false
            }),
            createColumn('inputPallets', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(
                    culture,
                    'inputPallets'
                ),
                filterable: false
            }),
            createColumn('type', {
                dataType: ColumnDataType.String,
                label: ' ',
                visible: !isReviewGrid
            }),
            createColumn('inputEaches', {
                dataType: ColumnDataType.String,
                filterable: false,
                sortable: false,
                visible: false
            }),
            createColumn('quantityPerPallet', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('previousPalletTotals', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('shape', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('size', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('neckDiameter', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('endProfile', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('tabColor', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('unit', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('orderedPallets', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('customerProductId', {
                dataType: ColumnDataType.String,
                filterable: false,
                sortable: true,
                searchable: true,
                visible: false
            }),
            createColumn('customerProductName', {
                dataType: ColumnDataType.String,
                filterable: false,
                sortable: true,
                searchable: true,
                visible: false
            })
        ];
    };
}
