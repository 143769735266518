import React, { ReactElement, useState, useEffect } from 'react';
import { Grid, makeStyles, useTheme, useMediaQuery } from '@material-ui/core';
import { NavigationalPageTemplate } from '../../templates/NavigationalPageTemplate';
import ProductGrid from './components/ProductGrid';
import { useDispatch } from 'react-redux';
import {
    loadProductPortfolioPageContent,
    productPortfolioNavSearch,
    loadProductPortfolioBanner,
    loadCapacityChecks
} from '../../../store/root-actions';
import ProductSubheader from './components/ProductSubheader';
import ProjectsModal from './components/ProjectsModal';
import Facets from './components/Facets';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { ProductPortfolioState, Facet } from '../../../store/reducers/product-portfolio';
import { useQuery, getProductIds } from '../../../utility/helpers/query-helpers';
import MobileFacets from './components/MobileFacets';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { ProductPortfolioProvider } from './context/ProductPortfolioContext';
import { Region } from '../../../store/reducers/graphic-intake';
import { CapacityCheckState } from '../../../store/reducers/capacity-checks';
import { CAPACITY_CHECK_CLEAR_ERROR } from '../../../store/actions/action-types';

const useStyles = makeStyles((theme) => ({
    filter: {
        paddingRight: '1.25em'
    }
}));

export default function ProductPortfolio(): ReactElement {
    const dispatch = useDispatch();
    const theme = useTheme();

    const productPortfolioState = useTypedSelector<ProductPortfolioState>(
        (state) => state.productPortfolio
    );
    const { regionCode, cultureCode } = useTypedSelector<RegionCultureState>(
        (state) => state.regionCulture
    );
    const { results, loaded } = useTypedSelector<ProductPortfolioState>(
        (state) => state.productPortfolio
    );
    const { capacityChecks } = useTypedSelector<CapacityCheckState>((state) => state.capacityCheck);

    const selectedProducts = results?.filter((result) => result.selected) || null;
    const productCount = selectedProducts.length || 0;
    const classes = useStyles();
    const query = useQuery();
    const facetCategory = query.get('category');
    const facetType = query.get('codes')?.split(',') || [];
    const facetLabel = query.get('label')?.split(',') || [];
    const [projectsModal, setProjectsModal] = useState<boolean>(false);
    const [enableProjectsModal, setEnableProjectsModal] = useState<boolean>(false);
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    const handleOpenProjectsModal = () => {
        setProjectsModal(true);
    };

    const handleCloseProjectsModal = () => {
        dispatch({ type: CAPACITY_CHECK_CLEAR_ERROR });
        setProjectsModal(false);
    };

    useEffect(() => {
        dispatch(loadProductPortfolioPageContent());
    }, []);

    useEffect(() => {
        if (
            loaded &&
            (productPortfolioState.regionCode !== regionCode ||
                productPortfolioState.cultureCode !== cultureCode)
        ) {
            dispatch(loadProductPortfolioBanner());
        }
    }, [
        productPortfolioState.regionCode,
        productPortfolioState.cultureCode,
        loaded,
        regionCode,
        cultureCode,
        dispatch
    ]);

    useEffect(() => {
        if (facetCategory && facetType && facetLabel) {
            const facets: Facet[] = [];
            facetType.forEach((facetCode, index) => {
                const label = facetLabel[index] === 'Alumi' ? 'Alumi-Tek®' : facetLabel[index];
                facets.push({ facetCode: facetCode, label: label, selected: true });
            });
            const selectedFacet = {
                category: facetCategory,
                value: facets
            };
            dispatch(productPortfolioNavSearch(selectedFacet));
        }
    }, [dispatch, facetCategory]);

    useEffect(() => {
        setEnableProjectsModal(regionCode === Region.NA && capacityChecks?.length > 0);
    }, [capacityChecks, regionCode]);

    useEffect(() => {
        dispatch(loadCapacityChecks());
    }, [dispatch]);

    const bannerData = productPortfolioState.banner;
    const bannerLinks = bannerData?.links;
    const linkData = bannerData && bannerLinks ? bannerLinks[0].linkText : null;
    const linkUrl = bannerData && bannerLinks ? `/source/content${bannerLinks[0].linkUrl}` : null;
    const icon = (
        <img
            src={process.env.PUBLIC_URL + '/assets/DesignInspiration_Icon_white.svg'}
            alt="Inspiration Icon"
        />
    );

    return (
        <ProductPortfolioProvider>
            <div className="portfolio-div">
                <NavigationalPageTemplate
                    banner={{
                        header: bannerData?.header,
                        description: bannerData?.description,
                        imageUrl: bannerData?.image?.imageUrl,
                        buttonLink: linkUrl,
                        buttonContent: linkData,
                        buttonIcon: icon
                    }}
                    subheader={
                        <ProductSubheader
                            productCodes={getProductIds(selectedProducts)}
                            onOpenProjectsModal={handleOpenProjectsModal}
                            enableProjectsModal={enableProjectsModal}
                        />
                    }
                    loading={productPortfolioState.loading}
                >
                    <Grid container>
                        <Grid item container xs={12}>
                            {matches ? (
                                <Grid container item xs={12}>
                                    <MobileFacets />
                                </Grid>
                            ) : (
                                <Grid item xs={3} className={classes.filter}>
                                    <Facets />
                                </Grid>
                            )}
                            <Grid item xs={12} sm={9}>
                                <ProductGrid productCount={productCount} />
                            </Grid>
                        </Grid>
                    </Grid>
                </NavigationalPageTemplate>
                {enableProjectsModal && (
                    <ProjectsModal open={projectsModal} onClose={handleCloseProjectsModal} />
                )}
            </div>
        </ProductPortfolioProvider>
    );
}
