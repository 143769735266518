import moment from 'moment';
import {
    Activity,
    filterShipToIdsByPermission,
    SecurityLevel,
    ValidationMethod
} from '../../utility/auth/useSecurity';
import OrdersService, {
    Order_Status,
    DashboardRequest,
    GetDraftsRequest
} from '../../utility/services/orders-service';
import { AuthState } from '../reducers/auth';
import { Invoice } from '../reducers/make-it-summary';
import {
    CUSTOMER_DASHBOARD_ERROR,
    CUSTOMER_DASHBOARD_LOADING,
    CUSTOMER_DASHBOARD_MAKEIT_WIDGET_INVOICES,
    CUSTOMER_DASHBOARD_LOADED,
    CUSTOMER_DASHBOARD_CACHE_SAVE
} from './action-types';

export const loadDashboardInformation = (account: string, paymentTerms: string) => {
    return (dispatch, getState) => {
        const state = getState();
        const auth: AuthState = state.auth;
        const region = state.regionCulture.regionCode;
        const culture = state.regionCulture.cultureCode;
        dispatch({ type: CUSTOMER_DASHBOARD_LOADING });

        const dashboardRequest: DashboardRequest = {
            shipToId: account
        };

        let date = moment().startOf('day').format('MM/DD/YYYY');
        dashboardRequest.orderedPriorTo = date;
        dashboardRequest.paymentTerms = paymentTerms;

        let dashboardCalls = [
            OrdersService.getDrafts(
                auth.accessToken,
                {
                    shipToIds: [account]
                } as GetDraftsRequest,
                culture,
                region
            ),
            OrdersService.getDashboardInformation(auth.accessToken, dashboardRequest)
        ];

        Promise.all(dashboardCalls)
            .then(([getDraftsResponse, getDashboardInformationResponse]) => {
                const dashboard = {
                    ...getDashboardInformationResponse.data,
                    makeItDashboard: {
                        ...getDashboardInformationResponse.data.makeItDashboard,
                        draftOrders: getDraftsResponse.data.length
                    }
                };
                dispatch({
                    type: CUSTOMER_DASHBOARD_LOADED,
                    dashboard,
                    shipToId: account
                });
                const dateLimit = moment().add(15, 'minutes').toDate();
                dispatch({
                    type: CUSTOMER_DASHBOARD_CACHE_SAVE,
                    dashboard,
                    shipToId: account,
                    dateLimit
                });
            })
            .catch((error) => {
                dispatch({ type: CUSTOMER_DASHBOARD_ERROR });
            });
    };
};

export const loadCachedDashboard = (account: string) => {
    return (dispatch, getState) => {
        dispatch({ type: CUSTOMER_DASHBOARD_LOADING });

        const state = getState();
        const cachedDashboard = state.customerDashboard.cachedDashboard.find(
            (cd) => cd.shipToId === account
        );

        dispatch({ type: CUSTOMER_DASHBOARD_LOADED, dashboard: cachedDashboard.data });
    };
};

export const getDashboardInvoices = (accounts: number[]) => {
    return (dispatch, getState) => {
        const auth: AuthState = getState().auth;

        OrdersService.getInvoices(auth.accessToken, {
            shipToIds: filterShipToIdsByPermission(
                auth,
                accounts,
                Activity.NewOpenProductionOrders,
                SecurityLevel.View,
                ValidationMethod.byAccess
            )
        })
            .then((response) => {
                let pendingInvoices: Invoice[] = [];
                let pastDueInvoices: Invoice[] = []; // Payment due date is past the current date
                let approachingInvoices: Invoice[] = []; // Payment due date is upcoming in the next 7 days
                let today = moment().startOf('day');
                let sevenDaysOut = moment().startOf('day').add(7, 'days');

                if (response.data.invoices) {
                    pendingInvoices = response.data.invoices;
                    pendingInvoices.forEach((invoice) => {
                        if (
                            invoice.status === Order_Status.Payment_Delinquent &&
                            moment(invoice.paymentDueDate).startOf('day').isBefore(today)
                        ) {
                            pastDueInvoices.push(invoice);
                        } else if (
                            invoice.status === Order_Status.Payment_Delinquent &&
                            moment(invoice.paymentDueDate)
                                .startOf('day')
                                .isBetween(today, sevenDaysOut)
                        ) {
                            approachingInvoices.push(invoice);
                        }
                    });
                }

                dispatch({
                    type: CUSTOMER_DASHBOARD_MAKEIT_WIDGET_INVOICES,
                    pendingInvoices,
                    pastDueInvoices,
                    approachingInvoices
                });
            })
            .catch(() => {
                dispatch({ type: CUSTOMER_DASHBOARD_ERROR });
                return;
            });
    };
};
