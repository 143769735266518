import {
    Activity,
    filterShipToIdsByPermission,
    SecurityLevel
} from '../../utility/auth/useSecurity';
import {
    getSnoSkuAttributes,
    getSnoSkuDescription
} from '../../utility/helpers/make-it-bulk-helpers';
import { isProductCanType } from '../../utility/helpers/order-helpers';
import {
    createProductRequestObj,
    getMakeItMatchingProductInformation,
    getProductLeadTime
} from '../../utility/helpers/production-order-helpers';
import OrdersService from '../../utility/services/orders-service';
import ProductService, { LeadTimesResponse } from '../../utility/services/product-service';
import TranslationService from '../../utility/services/translation-service';
import { AuthState } from '../reducers/auth';
import { MakeItProductsRequest } from '../reducers/makeit-dashboard';
import {
    PRODINFO_PRODUCTS_LOADED,
    PRODINFO_PRODUCTS_LOADING,
    PRODINFO_PRODUCTS_LOADING_ERROR,
    PRODINFO_PRODUCTS_NO_PRODUCTS,
    PRODINFO_PRODUCTS_RESET_STATE
} from './action-types';
import { getProductLeadTimes } from './makeit-dashboard';

export const loadProductsForProductInformationPage = (
    activity: Activity.MakeItBulkUpload | Activity.ShipItBulkUpload | Activity.PlanItBulkUpload
) => {
    return (dispatch, getState) => {
        dispatch({ type: PRODINFO_PRODUCTS_LOADING });
        const state = getState();
        const cultureCode = getState().regionCulture?.cultureCode || 'en-US';
        const accessToken = state.auth.accessToken;
        const shipToId = state.customerContext.selectedAccountId;
        const productsToOrder = state.makeItDashboard.productsToOrder
            ? state.makeItDashboard.productsToOrder
            : [];
        const rangeState = state.makeItDashboard.range;
        const currentlyAdded = productsToOrder.filter((item) => item.addDisabled);
        const auth: AuthState = state.auth;

        const requestObj: MakeItProductsRequest = {
            ShipToIds: filterShipToIdsByPermission(auth, [shipToId], activity, SecurityLevel.View)
        };
        if (!!rangeState && rangeState[0].length !== 0) {
            requestObj.UsedWithinNMonths = rangeState;
        }

        if (shipToId) {
            OrdersService.getMakeItProducts(accessToken, requestObj)
                .then((response) => {
                    // get lead time && moq information here
                    let products = response.data.products;
                    // This if() statement prevents continual loading loop if no products
                    if (products.length > 0) {
                        let productRequest = createProductRequestObj(products, currentlyAdded, [
                            shipToId
                        ]);

                        const productReq = { ...productRequest, accountId: shipToId };

                        ProductService.getMultipleProducts(getState, productReq)
                            .then((response) => {
                                // Response information from the product-api
                                const responseData = response?.data?.products;
                                if (responseData) {
                                    // Loop over products from order-api to find matches
                                    products.forEach((product) => {
                                        getMakeItMatchingProductInformation(
                                            responseData,
                                            product,
                                            product.size,
                                            product.type,
                                            product.shape
                                        );
                                        product.snoSkuDescription = getSnoSkuDescription(
                                            isProductCanType(product.type),
                                            product
                                        );
                                        product.snoSkuAttributes = getSnoSkuAttributes(
                                            isProductCanType(product.type),
                                            product
                                        );
                                    });
                                }

                                return Promise.resolve(products);
                            })
                            .then((products) => getProductLeadTimes(products, state))
                            .then((leadTimes) => {
                                products.forEach((product) => {
                                    product.leadTimeWeeks = getProductLeadTime(
                                        product,
                                        leadTimes as LeadTimesResponse[]
                                    );
                                    product.leadTimeString =
                                        product.leadTimeWeeks.toString() +
                                        ' ' +
                                        TranslationService.getTranslatedText(cultureCode, 'weeks');
                                });
                                dispatch({
                                    type: PRODINFO_PRODUCTS_LOADED,
                                    products,
                                    shipToId
                                });
                            })
                            .catch((error) => {
                                dispatch({
                                    type: PRODINFO_PRODUCTS_LOADING_ERROR,
                                    error
                                });
                            });
                    } else {
                        dispatch({
                            type: PRODINFO_PRODUCTS_NO_PRODUCTS,
                            shipToId: shipToId
                        });
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: PRODINFO_PRODUCTS_LOADING_ERROR,
                        error
                    });
                });
        }
    };
};

export const productInformationPageResetState = () => {
    return (dispatch) => {
        dispatch({
            type: PRODINFO_PRODUCTS_RESET_STATE
        });
    };
};

export const productInformationPageUpdateView = (view: string[], type: string) => {
    return (dispatch: any) => {
        dispatch({
            type: type,
            view: view
        });
    };
};
