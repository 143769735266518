import React, { ReactElement, ReactNode } from 'react';
import Header from '../reusable/molecules/Header';
import Footer from '../reusable/molecules/Footer';
import { Section } from '../../store/reducers/glp';
import Banner from '../reusable/atoms/Banner';
import { Grid, makeStyles, AppBar, useTheme, useMediaQuery } from '@material-ui/core';
import MobileHeader from '../reusable/molecules/MobileHeader';
import MobileFooter from '../reusable/molecules/MobileFooter';
import { CommunicationMessagesDisplayBannerModal } from '../reusable/CommunicationsMessagesDisplayBannerModal';

interface Props {
    key?: any;
    title?: string;
    children: ReactNode;
    banner?: Section;
    loading?: boolean;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%'
    },
    divider: {
        marginTop: '2em'
    },
    alert: {
        marginBottom: '10px'
    }
}));

export function GraphicalPageTemplate({ children, title, banner, loading }: Props): ReactElement {
    const classes = useStyles();
    //Use different menu for small screens
    const theme = useTheme();
    const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const smScreens = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div className={classes.root}>
            <AppBar position="sticky">
                {xsScreen ? (
                    <MobileHeader title={title} loading={loading} />
                ) : (
                    <Header title={title} loading={loading} />
                )}
            </AppBar>
            {banner ? <Banner {...banner} /> : null}
            <Grid className={classes.alert}>
                <CommunicationMessagesDisplayBannerModal />
            </Grid>

            <main role="main">{children}</main>
            {smScreens ? <MobileFooter /> : <Footer />}
        </div>
    );
}
