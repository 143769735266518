import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import { ColumnDataType, createColumn } from 'tubular-common';
import { Grid, TableRow, TableCell, makeStyles } from '@material-ui/core';
import Link from '../../../reusable/atoms/Link';
import { AuthState } from '../../../../store/reducers/auth';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import OrdersService from '../../../../utility/services/orders-service';
import { MakeItSummaryState } from '../../../../store/reducers/make-it-summary';
import { boldWeight, medium, orange, blackWeight, small } from '../../../../themes/globalConstants';
import DeleteGraphicModal from '../../../reusable/molecules/DeleteGraphicModal';
import { ProductionOrderSummary } from '../../../../store/reducers/make-it-summary';
import { loadDraftProductionOrders } from '../../../../store/actions/make-it-summary';
import TranslationService from '../../../../utility/services/translation-service';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { generateKey } from '../../../../utility/helpers/order-helpers';

const useStyles = makeStyles((theme) => ({
    action: {
        cursor: 'pointer'
    },
    name: {
        fontWeight: boldWeight,
        fontSize: medium,
        letterSpacing: 0.17
    },
    trashIcon: {
        fill: orange
    },
    tableWrapper: {
        '& .MuiToolbar-root': {
            padding: 0,
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                },
                '&:first-of-type ': {
                    paddingLeft: 0
                }
            },
            '& > h2': {
                fontSize: '1.5em',
                fontWeight: boldWeight,
                color: theme.palette.secondary.main,
                paddingLeft: '0.625em'
            }
        },
        '& .MuiTableSortLabel-root': {
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em',
            width: '99%',
            justifyContent: 'space-between',
            '& .MuiSvgIcon-root': {
                margin: 0
            }
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                '&:nth-child(1)': {
                    width: '15%'
                },
                '&:nth-child(2)': {
                    width: '30%'
                },
                '&:nth-child(3)': {
                    width: '25%'
                },
                '&:nth-child(4)': {
                    width: '15%'
                },
                '&:nth-child(5)': {
                    width: '15%',
                    fontSize: small,
                    fontWeight: blackWeight,
                    textTransform: 'uppercase',
                    color: theme.palette.secondary.main,
                    borderRadius: '0.25em',
                    paddingLeft: '1.5em'
                }
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    }
}));

// add all columns that need to be in the table even if not visible as a column
const getMakeItDraftColumns = (culture: string) => {
    return [
        createColumn('productionOrderId', {
            dataType: ColumnDataType.Numeric,
            visible: false
        }),
        createColumn('shipToId', {
            dataType: ColumnDataType.String,
            label: TranslationService.getTranslatedText(culture, 'shipTo'),
            filterable: true,
            sortable: true
        }),
        createColumn('productionOrderNumber', {
            dataType: ColumnDataType.String,
            label: TranslationService.getTranslatedText(culture, 'draftOrderName'),
            filterable: true,
            sortable: true,
            searchable: true
        }),
        createColumn('customerProductionOrderId', {
            dataType: ColumnDataType.String,
            label: TranslationService.getTranslatedText(culture, 'customerPoNumber'),
            filterable: true,
            sortable: true,
            searchable: true
        }),
        createColumn('lastSaved', {
            dataType: ColumnDataType.String,
            label: TranslationService.getTranslatedText(culture, 'lastSaved'),
            filterable: true,
            sortable: true
        }),
        createColumn('actions', {
            label: TranslationService.getTranslatedText(culture, 'actions')
        })
    ];
};

const getAccounts = (state: any) => {
    const accountIds = state.auth.permissions.map((x) => x.accountIds.flat()).flat();
    const accounts = accountIds.map((x) => +x);
    const accountSet = new Set<number>(accounts);

    return Array.from(accountSet);
};

const DraftRowComponent = ({ row }: { row: Partial<ProductionOrderSummary> }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const { state } = useTypedSelector((state) => state);
    const { accessToken } = useTypedSelector<AuthState>((state) => state.auth);
    const accounts = getAccounts(state);

    const onDelete = () => {
        setOpen(true);
    };

    const onCloseConfirmation = () => {
        setOpen(false);
    };

    const onDeleteGraphic = () => {
        if (open) {
            setOpen(false);
        }
        OrdersService.deleteProductionOrder(accessToken, row.productionOrderId).then(() => {
            dispatch(loadDraftProductionOrders(getAccounts(accounts)));
        });
    };

    return (
        <TableRow hover={true} key={row.productionOrderId}>
            <TableCell>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        {row.shipToId}
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        {row.productionOrderNumber}
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        {row.customerProductionOrderId}
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        {row.lastSaved}
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid container justify="space-around" alignItems="center">
                    <Grid item>
                        <Link
                            to={`/make-it-intake/${row.productionOrderId}`}
                            component={RouterLink}
                            underline="always"
                        >
                            Edit Order
                        </Link>
                    </Grid>
                    <Grid item>|</Grid>
                    <Grid item>
                        <Link onClick={onDelete} className={classes.action} underline="always">
                            Delete
                        </Link>
                    </Grid>
                </Grid>
            </TableCell>
            <DeleteGraphicModal
                open={open}
                deleteGraphic={onDeleteGraphic}
                navigationLink="/make-it-summary"
                onClose={onCloseConfirmation}
                closeModalBtn={true}
            />
        </TableRow>
    );
};

const MakeItDraftTab = () => {
    const classes = useStyles();

    const { draftOrders } = useTypedSelector<MakeItSummaryState>((state) => state.makeItSummary);
    const state = useTypedSelector((state) => state);
    const { accessToken, permissions } = useTypedSelector<AuthState>((state) => state.auth);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [columns, setColumns] = useState<any>([]);
    const accounts = getAccounts(state);
    const options = new ToolbarOptions({
        title: TranslationService.getTranslatedText(cultureCode, 'draftProductionOrders'),
        advancePagination: false,
        exportButton: false,
        printButton: false,
        searchText: true
    });

    const dispatch = useDispatch();

    useEffect(() => {
        if (accessToken && permissions?.length) {
            dispatch(loadDraftProductionOrders(accounts));
        }
    }, [dispatch, accessToken, permissions, accounts]);

    useEffect(() => {
        setColumns(getMakeItDraftColumns(cultureCode));
    }, [cultureCode]);

    return (
        <Grid className={classes.tableWrapper} key={generateKey('po-draft-tab')}>
            <DataGrid
                gridName="Draft Orders"
                columns={columns}
                dataSource={draftOrders}
                rowComponent={DraftRowComponent}
                toolbarOptions={options}
            />
        </Grid>
    );
};

export default MakeItDraftTab;
