import React from 'react';
import { Grid, Typography, makeStyles, Divider } from '@material-ui/core';
import { Trans } from 'react-i18next';
import { DeliveryShipment } from '../../../../store/reducers/shipping-dashboard';
import {
    getFullFormattedDateTime
} from '../../../../utility/helpers/date-helpers';
import {
    ballGray,
    ballDrkBlue,
    xxl,
    blackWeight,
    medium,
    ltBlueGrey_34
} from '../../../../themes/globalConstants';
import clsx from 'clsx';
import CancelDeliveryOrderConfirmationConfigGrid from './CancelDeliveryOrderConfirmationConfigGrid';

interface Props {
    currentShipment: DeliveryShipment;
    shipmentIndex: number;
}

const useStyles = makeStyles((theme) => ({
    main: {
        width: '100%'
    },
    cardSubheader: {
        color: ballGray
    },
    dateDescription: {
        color: ballGray,
        display: 'flex',
        alignItems: 'center'
    },
    altDescriptionColor: {
        color: ballDrkBlue
    },
    dateDescriptionMain: {
        fontSize: xxl,
        fontWeight: blackWeight
    },
    cardDetailsWrapper: {
        '& .MuiGrid-item': {
            paddingLeft: '2em'
        }
    },
    deliveryTitle: {
        fontWeight: blackWeight,
        fontSize: medium
    },
    actionsBar: {
        marginTop: '1.25em'
    },
    shipmentEditedStatusText: {
        color: ballGray,
        fontSize: medium,
        textTransform: 'none'
    },
    divider: {
        height: '0.32em',
        backgroundColor: ltBlueGrey_34,
        width: '100%',
        marginTop: '0.5em',
        marginBottom: '0.5em'
    }
}));

const CancelDeliveryOrderConfirmationGrid = ({ currentShipment, shipmentIndex }: Props) => {
    const classes = useStyles();
    const shipmentDayAndTime = getFullFormattedDateTime('', currentShipment.deliveryDateTime);

    return (
        <>
            <Grid className={classes.main} container item justify="space-between">
                <Divider className={classes.divider} />
                <Grid container item xs={12} className={classes.dateDescription}>
                    <Grid item md={12}>
                        <Typography
                            className={clsx(
                                classes.dateDescriptionMain,
                                classes.altDescriptionColor
                            )}
                        >
                            <Trans i18nKey="shipment">Shipment</Trans>{' '}
                            <Trans i18nKey="cancelled">Cancelled</Trans>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            <Trans i18nKey="deliveryDate">Delivery Date</Trans>
                            {': '}
                            {shipmentDayAndTime}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider className={classes.divider} />
            </Grid>
            <Grid container>
                <Grid container item>
                    {currentShipment.loads && currentShipment.loads.length && (
                        <CancelDeliveryOrderConfirmationConfigGrid
                            items={currentShipment.loads}
                            shipment={currentShipment}
                        />
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default CancelDeliveryOrderConfirmationGrid;
