import { AuthState } from '../../store/reducers/auth';
import { hasPersonas, hasPersonasByShipTo, Persona } from '../auth/useSecurity';

export function hasMakeItPersonas(auth: AuthState, selectedShipToId: string) {
    const display = hasPersonasByShipTo(
        [
            Persona.AllAccess,
            Persona.PackagingMgmt,
            Persona.ForecastMgmt,
            Persona.OrderFulfillment,
            Persona.WarehousingLogistics,
            Persona.CoPacker,
            Persona.ArAp,
            Persona.MakeItOnly
        ],
        selectedShipToId,
        auth.permissions ?? []
    );
    return display;
}

export function hasShipItPersonas(auth: AuthState, selectedShipToId: string) {
    const display = hasPersonasByShipTo(
        [
            Persona.AllAccess,
            Persona.CoPacker,
            Persona.PackagingMgmt,
            Persona.ForecastMgmt,
            Persona.OrderFulfillment,
            Persona.WarehousingLogistics,
            Persona.ArAp,
            Persona.ShipItOnly
        ],
        selectedShipToId,
        auth.permissions ?? []
    );
    return display;
}

export function hasOrderingPersonas(auth: AuthState) {
    const display = hasPersonas(
        [
            Persona.AllAccess,
            Persona.PackagingMgmt,
            Persona.ProcurementMgmt,
            Persona.ForecastMgmt,
            Persona.OrderFulfillment,
            Persona.WarehousingLogistics,
            Persona.CoPacker,
            Persona.ArAp,
            Persona.MakeItOnly
        ],
        auth
    );
    return display;
}

export function hasGraphicsPersonas(auth: AuthState) {
    return hasPersonas(
        [
            Persona.AllAccess,
            Persona.MarketingMgmt,
            Persona.PackagingMgmt,
            Persona.ProcurementMgmt,
            Persona.ArtMgmt,
            Persona.ForecastMgmt,
            Persona.OrderFulfillment,
            Persona.WarehousingLogistics,
            Persona.CoPacker
        ],
        auth
    );
}

export function hasPendingPaymentPersonas(auth: AuthState) {
    return hasPersonas([Persona.AllAccess, Persona.ArAp], auth);
}

export function hasProductPlanningPersonas(auth: AuthState) {
    return hasPersonas(
        [
            Persona.AllAccess,
            Persona.PackagingMgmt,
            Persona.ProcurementMgmt,
            Persona.ForecastMgmt,
            Persona.OrderFulfillment,
            Persona.WarehousingLogistics,
            Persona.CoPacker,
            Persona.MakeItOnly
        ],
        auth
    );
}

export function getProductionBalanceSum(productionBalances?: number[]) {
    const productionBalanceSum = (accumulator, currentValue) => accumulator + currentValue;

    if (productionBalances?.length) {
        return productionBalances.reduce(productionBalanceSum);
    }
    return 0;
}

export function getSkuCount(skus?: number[]) {
    if (skus) {
        return skus.length;
    }
    return 0;
}
