import React, { useState, useEffect } from 'react';
import { numericRegex } from '../../../../utility/helpers/formatters';
import { useTranslation } from 'react-i18next';
import {
    TableRow,
    TableCell,
    Grid,
    Input,
    Button,
    InputAdornment,
    IconButton
} from '@material-ui/core';
import { OrderProductTableRow } from '../../../../store/reducers/orders-dashboard';
import Modal from '../../../reusable/molecules/Modal';
import moment from 'moment';
import clsx from 'clsx';
import {
    createLeadTimeDisplay,
    calculateLeadTime,
    setDateTimeToMidnight
} from '../../../../utility/helpers/date-helpers';
import SingleDaypickerModal from '../../../reusable/molecules/SingleDaypickerModal';
import {
    getEaches,
    getMakeItLeadTime,
    resetItemQuantities,
    resolveQuantity
} from '../../../../utility/helpers/production-order-helpers';
import QuantityInputCell from '../../../reusable/atoms/QuantityInputCell';
import { MakeState } from '../../../../store/reducers/makeit-dashboard';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { resolvePalletCount } from '../../../../utility/helpers/shipment-helpers';

interface Props {
    item: OrderProductTableRow;
    onRemoveItem: (item: OrderProductTableRow) => void;
    onQtyUpdate: () => void;
    onBlurSetQty: () => void;
    onSubRowSelectedDateChange: () => void;
    styles: any;
}

const MakeItConfigChildRow = ({
    item,
    onRemoveItem,
    onQtyUpdate,
    onBlurSetQty,
    onSubRowSelectedDateChange,
    styles
}: Props) => {
    const classes = styles();
    const { t } = useTranslation();
    const { quantityUnit } = useTypedSelector<MakeState>((state) => state.makeItDashboard);
    const [inputQuantity, setInputQuantity] = useState<number>(resolveQuantity(quantityUnit, item));
    const [selectedDate, setSelectedDate] = useState<string>();
    const [daypickerDate, setDaypickerDate] = useState<string>();
    const [firstDay, setFirstDay] = useState<moment.Moment | null>(null);
    const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
    const leadTime = item.leadTimeWeeks ?? getMakeItLeadTime(item);
    const leadTimeDisplay = createLeadTimeDisplay(leadTime);

    const today = moment().format('MM/DD/YYYY');
    const calendarIcon = (
        <img
            className={classes.calendarIcon}
            src={process.env.PUBLIC_URL + '/assets/Calendar_Icon.svg'}
            alt="Calendar Icon"
        />
    );

    const updatePurchaseCount = (count: string) => {
        let numOfPallets = 0;
        if (numericRegex(count)) {
            const parsedCount = parseInt(count);
            if (!isNaN(parsedCount)) {
                numOfPallets = resolvePalletCount(
                    parsedCount,
                    item.quantityPerPallet!,
                    quantityUnit
                );
                setInputQuantity(parsedCount);
                item.quantity = numOfPallets;
                item.palletQuantity = numOfPallets;
                item.eachesQuantity = getEaches(numOfPallets, item.quantityPerPallet);
            } else {
                setInputQuantity(numOfPallets);
                resetItemQuantities(item);
            }
        } else {
            setInputQuantity(numOfPallets);
            resetItemQuantities(item);
        }
        onQtyUpdate();
    };

    const setQuantityOnBlur = () => {
        onBlurSetQty();
    };

    const handleRemoveItem = () => {
        onRemoveItem(item);
    };

    const onOpenCalendar = () => {
        setCalendarOpen(true);
    };

    const onCloseCalendar = () => {
        setCalendarOpen(false);
    };

    const handleOkClose = () => {
        setCalendarOpen(false);
        setSelectedDate(daypickerDate);

        if (daypickerDate !== item.requestedDate) {
            item.requestedDate = daypickerDate;
            onSubRowSelectedDateChange();
        }
    };

    const handleDateSelect = (newDate: moment.Moment | null) => {
        const date = moment(newDate).format('MM/DD/YYYY');
        setDaypickerDate(date);
    };

    useEffect(() => {
        const today = moment();
        const leadTimeDay = calculateLeadTime(today, leadTime, 'weeks');
        const firstAvailableDay = setDateTimeToMidnight(leadTimeDay);
        setFirstDay(firstAvailableDay);
        // run only once while beginning
    }, []);

    useEffect(() => {
        if (item.requestedDate) {
            setSelectedDate(item.requestedDate);
        } else {
            setSelectedDate(leadTimeDisplay);
            item.requestedDate = leadTimeDisplay;
        }
    }, [item, leadTimeDisplay]);

    useEffect(() => {
        if (item.requestedDate) {
            setSelectedDate(item.requestedDate);
        }
    }, [item]);

    useTranslation();

    return (
        <>
            <TableRow className={classes.tableRowContainer} data-testid="child-row-table">
                <TableCell className={classes.hiddenCell} />
                <TableCell className={classes.hiddenCell} />
                <TableCell className={classes.hiddenCell} />
                <TableCell className={classes.hiddenCell} />

                <TableCell className={classes.alignCenter}>
                    <Input
                        id={item.productSku}
                        type="button"
                        fullWidth
                        onClick={onOpenCalendar}
                        disableUnderline={true}
                        value={selectedDate}
                        className={classes.calendarInput}
                        data-testid="split-calendar-input"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="calendar modal trigger"
                                    data-testid="calendar-btn"
                                    onClick={onOpenCalendar}
                                >
                                    {calendarIcon}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </TableCell>
                <TableCell className={classes.hiddenCell} />

                <TableCell className={classes.blueBackground}>
                    <Grid container alignContent="center">
                        <Grid item className={classes.inputContainer}>
                            <QuantityInputCell
                                quantity={inputQuantity}
                                quantityPerPallet={item.quantityPerPallet!}
                                quantityUnit={quantityUnit}
                                onBlur={setQuantityOnBlur}
                                onChange={updatePurchaseCount}
                                disabled={false}
                            />
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell className={clsx(classes.hiddenCell, classes.blueBackground)} />
                <TableCell className={clsx(classes.alignCenter, classes.blueBackground)}>
                    <Button
                        type="button"
                        className={classes.trash}
                        onClick={handleRemoveItem}
                        data-testid="split-delete-icon"
                    >
                        <img src={process.env.PUBLIC_URL + '/assets/Trash.svg'} alt="Trash Icon" />
                    </Button>
                </TableCell>
            </TableRow>
            <Modal
                open={calendarOpen}
                title={t('today', `Today: ${today}`)}
                secondaryTitle={t(
                    'leadTimeForThisProduct',
                    `Lead Time for this Product: ${leadTime} weeks`
                )}
                close={onCloseCalendar}
                closeIcon={true}
                maxWidth="xl"
            >
                <div className={classes.calendarDiv}>
                    <SingleDaypickerModal
                        onCancel={onCloseCalendar}
                        onOk={handleOkClose}
                        selectDate={(newDate) => handleDateSelect(newDate)}
                        leadTime={leadTime}
                        firstDay={firstDay}
                    />
                </div>
            </Modal>
        </>
    );
};

export default MakeItConfigChildRow;
