import { TableRow, TableCell, Typography } from '@material-ui/core';
import React from 'react';
import { InactiveProduct } from '../../../../store/reducers/planit-bulk-atm';

interface Props {
    line: InactiveProduct;
}

const InactiveProductsRow = ({ line }: Props) => {  
    return (
        <TableRow hover={true}>
            <TableCell data-testid={'csv-row'}>
                <Typography>{line.lineNumberString}</Typography>
            </TableCell>
            <TableCell data-testid={'product-id'}>
                <Typography>{line.productId}</Typography>
            </TableCell>
            <TableCell data-testid={'ship-to'}>
                <Typography>{line.shipToId}</Typography>
            </TableCell>
            <TableCell data-testid={'product-description'}>
                <Typography>{line.productDescription}</Typography>
            </TableCell>
        </TableRow>
    );
};

export default InactiveProductsRow;
