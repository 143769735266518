import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import {
    blackWeight,
    boldWeight,
    ballBlue,
    dateOptionInvalidGray,
    small,
    black
} from '../../../../themes/globalConstants';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import DeliveryBulkUploadColumns from './DeliveryBulkUploadColumns';
import { OrderProduct } from '../../../../store/reducers/orders-dashboard';
import { BulkUploadShipment } from '../../../../store/reducers/bulk-upload';
import DeliveryBulkUploadRow from './DeliveryBulkUploadRow';
import { generateKey } from '../../../../utility/helpers/order-helpers';
import { Account } from '../../../../store/reducers/customer-context';
import DeliveryBulkSubheader from './DeliveryBulkUploadSubheader';
import { ProductToShip } from '../../../../store/reducers/shipping-dashboard';

interface Props {
    loadInformation: OrderProduct[];
    shipmentInformation: BulkUploadShipment;
    index: number;
    accountInformation: Account;
}

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        width: '100%',
        '& .MuiPaper-root': {
            boxShadow: 'unset'
        },
        '& .MuiToolbar-root': {
            padding: '0 0.125em',
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                }
            },
            '& ::-ms-clear': {
                display: 'none'
            },
            '& .MuiInputBase-formControl': {
                border: `1px solid ${dateOptionInvalidGray}`,
                borderRadius: '.125em',
                '&:before': {
                    display: 'none'
                },
                '&:after': {
                    display: 'none'
                },
                '& svg': {
                    height: '.875em',
                    width: '.875em',
                    fill: ballBlue
                },
                '& .MuiIconButton-root': {
                    '&:hover': {
                        backgroundColor: 'transparent !important'
                    }
                },
                '& .MuiInput-input': {
                    paddingLeft: '.25em'
                }
            }
        },
        '& .MuiTableSortLabel-root': {
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: small,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            margin: '0 .125em 1.125em .125em',
            padding: '0.875em 1.125em',
            lineHeight: '1.2em',
            '& .MuiSvgIcon-root': {
                fontSize: 14,
                opacity: '1',
                marginRight: '-0.375em'
            }
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                padding: 0,
                '&:nth-child(2)': {
                    width: '10%'
                },
                '&:nth-child(3)': {
                    width: '15%'
                },
                '&:nth-child(4)': {
                    width: '30%'
                }
            }
        },
        '& .MuiTablePagination-root': {
            '& .MuiTablePagination-caption': {
                marginLeft: '1em',
                fontWeight: boldWeight,
                color: black
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    }
}));

export default function DeliveryBulkUploadGrid({
    loadInformation,
    shipmentInformation,
    accountInformation,
    index
}: Props) {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const CustomToolbarOptions = new ToolbarOptions({
        customItems: (
            <DeliveryBulkSubheader
                accountInformation={accountInformation}
                currentShipment={shipmentInformation}
                shipmentIndex={index}
            />
        ),
        exportButton: false,
        printButton: false,
        advancePagination: false,
        enablePagination: false,
        searchText: false
    });

    const BulkUploadRow = ({ row }: { row: Partial<ProductToShip> }) => {
        return (
            <DeliveryBulkUploadRow
                key={generateKey('bulk_shipment_row')}
                item={row as ProductToShip}
            />
        );
    };

    return (
        <Grid container className={classes.tableWrapper} key={generateKey('bulk_shipment_table')}>
            <DataGrid
                columns={DeliveryBulkUploadColumns(cultureCode)}
                dataSource={loadInformation}
                gridName="Delivery Bulk Upload Grid"
                rowComponent={BulkUploadRow}
                toolbarOptions={CustomToolbarOptions}
            />
        </Grid>
    );
}
