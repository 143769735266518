import {
    GLP_LOADED,
    GLP_LOADING,
    GLP_LOADING_ERROR,
    GLP_LOADED_MISSION,
    GLP_LOADED_PARTNERING,
    GLP_LOADED_FIND_PRODUCTS,
    GLP_LOADED_BANNER,
    GLP_LOADED_CAN_CONTENT
} from '../actions/action-types';

export interface GlpState {
    loading: boolean;
    loadedRegion?: string;
    loadedLanguage?: string;
    error?: string;
    mission?: Section;
    partnering?: Section;
    findProducts?: Section;
    banner?: Section;
    canContent?: Section;
}

export interface Section {
    header: string;
    description: string;
    summary?: string;
    paragraph?: string;
    links?: Link[];
    image?: Image;
    icon?: Icon;
    displayDropdown?: boolean;
    completeDrowdown?: boolean;
    billToDropdown?: boolean;
    [x: string]: any;
}

export interface Link {
    linkText: string;
    linkUrl?: string;
    destination?: string;
    icon?: Icon;
}

export interface Image {
    imageUrl: string;
    alternateText: string;
}

export interface Icon {
    defaultUrl: string;
    hoverUrl: string;
}

export interface InfoSubheaderItem {
    subtitle: string;
    body: string;
    subtext?: string;
}

const initialState: GlpState = {
    loading: false,
    banner: { header: '', description: '' }
};

const glp = (state = initialState, action: any) => {
    switch (action.type) {
        case GLP_LOADED:
            return {
                ...state,
                loading: false,
                error: undefined,
                loadedRegion: action.region,
                loadedLanguage: action.language
            };
        case GLP_LOADING:
            return {
                ...state,
                loading: true
            };
        case GLP_LOADING_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
                loadedRegion: undefined,
                loadedLanguage: undefined
            };

        case GLP_LOADED_MISSION:
            return {
                ...state,
                mission: action.mission
            };
        case GLP_LOADED_PARTNERING:
            return {
                ...state,
                partnering: action.partnering
            };
        case GLP_LOADED_FIND_PRODUCTS:
            return {
                ...state,
                findProducts: action.findProducts
            };
        case GLP_LOADED_BANNER:
            return {
                ...state,
                banner: action.banner
            };
        case GLP_LOADED_CAN_CONTENT:
            return {
                ...state,
                canContent: action.canContent
            };
        default:
            return state;
    }
};

export default glp;
