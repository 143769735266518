import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { makeStyles, Typography, TableRow, TableCell, Grid, Input, Link } from '@material-ui/core';
import {
    ballLtGray,
    small,
    white,
    regularWeight,
    shipRed,
    errorBackground,
    boldWeight,
    blackWeight,
    medium,
    informationAlert,
    black,
    warningBorder,
    vanilla
} from '../../../../themes/globalConstants';
import clsx from 'clsx';
import { getPalletQuantityKey } from '../../../../utility/helpers/make-it-bulk-helpers';
import { formatNumberWithLocale, numericRegex } from '../../../../utility/helpers/formatters';
import { useMakeItBulkReviewATM } from '../../MakeItBulkReviewATM/context/MakeItBulkReviewATMContext';
import { useDispatch } from 'react-redux';
import {
    deleteProductFromAtmOrder,
    updatePlanItBulkATMItemProductSku,
    updatePalletQuantityATM,
    updatePlanItBulkItemAllProductSkus
} from '../../../../store/actions/planit-bulk-atm';
import Button from '../../../reusable/atoms/Button';
import moment from 'moment';
import { Alert } from '@material-ui/lab';
import MakeItBulkProductIdModal from '../../../reusable/molecules/MakeItBulkProductIdModal';
import { MakeItBulkLineItem } from '../../../../store/reducers/makeit-bulk-upload';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import {
    BulkUploadPlanItATMState,
    PlanItBulkATMLineItem
} from '../../../../store/reducers/planit-bulk-atm';
import {
    getMoqFeeCeiling,
    getMoqFeeFloor
} from '../../../../utility/helpers/production-order-helpers';

interface Props {
    item: PlanItBulkATMLineItem;
    hasMultiplePersonas: boolean;
}

const useStyles = makeStyles(() => ({
    tableRowContainer: {
        '& .helper-text': {
            fontWeight: `${regularWeight} !important`,
            fontSize: small
        },
        '& .validation-error-text': {
            fontWeight: `${boldWeight} !important`,
            fontSize: small
        },
        '& .MuiInputBase-input': {
            textAlign: 'right',
            paddingRight: '.25em'
        }
    },
    textField: {
        width: '7em',
        height: '1.5em',
        border: `1.8px solid ${ballLtGray}`,
        borderRadius: '.25em',
        margin: '0 0.5em 0 0.5em',
        backgroundColor: white,
        fontWeight: blackWeight
    },
    alignCenter: {
        textAlign: 'center'
    },
    bold: {
        fontWeight: blackWeight
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    trash: {
        minWidth: 'unset'
    },
    feeWarning: {
        border: `1px solid ${warningBorder}`,
        borderRight: 'none',
        backgroundColor: vanilla
    },
    dupSkuError: {
        border: `1px solid ${shipRed}`,
        backgroundColor: errorBackground
    },
    pallet: {
        fontSize: medium
    },
    eachesCountPadding: {
        paddingRight: '6.5em'
    },
    informationalAlert: {
        backgroundColor: informationAlert,
        borderRadius: '.125em',
        alignItems: 'center',
        width: '95%',
        maxHeight: '2em'
    },
    informationAlertText: {
        fontWeight: boldWeight,
        fontSize: small,
        color: black
    },
    originalQuantityPallets: {
        fontWeight: blackWeight
    },
    link: {
        fontSize: small,
        fontWeight: regularWeight,
        '&:hover': {
            cursor: 'pointer'
        }
    },
    warningBackground: {
        backgroundColor: informationAlert
    },
    warningText: {
        fontSize: small,
        fontStyle: 'italic'
    },
    icon: {
        width: '100%',
        height: '100%'
    }
}));

const PlanItBulkReviewOrderSectionRow = ({ item, hasMultiplePersonas }: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [purchaseQty, setPurchaseQty] = useState<number | undefined>(undefined);
    const [disabled, setDisabled] = useState<boolean>(false);
    const leadTime = item.leadTimeWeeks ?? 1;
    const { activeDate, shipToId } = useMakeItBulkReviewATM();
    const [hasBlankQuantity, setHasBlankQuantity] = useState<boolean>(false);
    const [productIdOpen, setProductIdOpen] = useState<boolean>(false);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const hasMoqFees = item.moqFees && item.moqFees.length > 0;
    const eachesValue =
        item.palletQuantity && item.quantityPerPallet
            ? item.palletQuantity * item.quantityPerPallet
            : 0;
    const originalEachesValue =
        item.originalPalletQuantity && item.quantityPerPallet
            ? item.originalPalletQuantity * item.quantityPerPallet
            : 0;
    const uniqueProductId = !!item.isCustomerProductIdDistinct;
    const customerProductId = item.customerProductId;
    const candidateProductSkus = item.candidateProductSkus;
    const notIncludedInCsv = !!item.notIncludedInCsv ? item.notIncludedInCsv : false;
    const { isEdit } = useTypedSelector<BulkUploadPlanItATMState>(
        (state) => state.bulkUploadPlanIt
    );

    useEffect(() => {
        if (item && item.palletQuantity !== undefined) {
            setPurchaseQty(!isNaN(item.palletQuantity) ? item.palletQuantity : undefined);

            setHasBlankQuantity(isNaN(item.palletQuantity));
        } else {
            // added item and quantity is still blank
            setHasBlankQuantity(true);
        }
        if (isEdit) {
            const today = moment();
            setDisabled(moment(activeDate.fullDate).isSameOrBefore(today, 'day'));
        }
    }, [item, isEdit, activeDate]);

    const handleRemoveParentItem = () => {
        let dateWeek = moment.utc(activeDate.fullDate).startOf('day').format();
        dateWeek = dateWeek.substring(0, dateWeek.length - 1); // Match format to weekStart date
        dispatch(deleteProductFromAtmOrder(item.productSku!, dateWeek, shipToId));
    };

    const palletQuantityInputChange = (count) => {
        if (count === '') {
            setPurchaseQty(undefined);
            return;
        }
        let quantity = 0;
        if (numericRegex(count)) {
            if (!isNaN(parseInt(count))) {
                quantity = parseInt(count);
            }
            setPurchaseQty(!isNaN(quantity) ? quantity : undefined);
        }
    };

    const handlePalletQuantityOnBlur = (value) => {
        const quantity = parseInt(value);
        dispatch(updatePalletQuantityATM(activeDate, shipToId, quantity, item));
    };

    const onOpenProductId = () => {
        setProductIdOpen(true);
    };

    const onCloseProductId = () => {
        setProductIdOpen(false);
    };

    useTranslation();

    function handleProductSkuSelection(product: MakeItBulkLineItem) {
        //Set Pallet Quantity
        let palletQuantity = item.palletQuantity ? item.palletQuantity : 0;
        if (item.originalQuantity && product.quantityPerPallet) {
            palletQuantity = parseInt(item.originalQuantity) / product.quantityPerPallet;
        }
        dispatch(
            updatePlanItBulkATMItemProductSku(
                activeDate,
                shipToId,
                item.originalCsvLineNumber!,
                product as PlanItBulkATMLineItem,
                palletQuantity
            )
        );
    }

    const handleProductSkuSelectionApplyAll = (selectedProduct: MakeItBulkLineItem) => {
        if (customerProductId) {
            dispatch(
                updatePlanItBulkItemAllProductSkus(shipToId, customerProductId, selectedProduct)
            );
        }
    };

    return (
        <>
            <TableRow
                className={classes.tableRowContainer}
                data-testid={`product-${item.displayId}`}
            >
                {!isEdit && (
                    <TableCell data-testid="csv-line">
                        <Grid container alignContent="center">
                            <Grid container>
                                <Typography className={classes.bold}>
                                    {item.originalCsvLineNumber}
                                </Typography>
                            </Grid>
                        </Grid>
                    </TableCell>
                )}
                <TableCell
                    data-testid="display-id"
                    className={clsx({
                        [classes.dupSkuError]: !uniqueProductId
                    })}
                >
                    {uniqueProductId ? (
                        <Grid container alignContent="center">
                            <Grid container>
                                <Typography className={classes.bold}>{item.displayId}</Typography>
                            </Grid>
                            <Grid container>
                                <Typography>{item.graphicIdAndVersion}</Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container alignContent="center">
                            <Grid container>
                                <img
                                    src={process.env.PUBLIC_URL + '/assets/Error_Icon.svg'}
                                    alt="Error Icon"
                                />
                                <Typography>{item.displayId}</Typography>
                            </Grid>
                            <Grid container>
                                <Typography
                                    className="helper-text"
                                    data-testid="dup-product-id-helper"
                                >
                                    <Trans i18nKey="dupProductId">
                                        Duplicate Product IDs found
                                    </Trans>
                                </Typography>
                            </Grid>
                            <Grid container>
                                <Link
                                    className={classes.link}
                                    underline="always"
                                    data-testid="dup-product-id-link"
                                    onClick={onOpenProductId}
                                >
                                    <Typography className="helper-text">
                                        <Trans i18nKey="clickToChooseCorrect">
                                            Click to choose the correct one.
                                        </Trans>
                                    </Typography>
                                </Link>
                            </Grid>
                        </Grid>
                    )}
                </TableCell>
                <TableCell
                    data-testid="product-description"
                    className={clsx({
                        [classes.warningBackground]: notIncludedInCsv
                    })}
                >
                    {notIncludedInCsv && (
                        <img
                            data-testid="info-icon"
                            src={process.env.PUBLIC_URL + '/assets/InfoTriangle_Icon.svg'}
                            alt="Information Icon"
                        />
                    )}
                    <Typography>{item.displayName}</Typography>
                    {notIncludedInCsv && (
                        <Typography className={classes.warningText}>
                            <Trans i18nKey="productNotIncluded">
                                Product not included on updated file. Enter quantity to add.
                            </Trans>
                        </Typography>
                    )}
                </TableCell>
                <TableCell data-testid="status">
                    <Typography>{item.productStatus}</Typography>
                </TableCell>
                <TableCell data-testid="coating">
                    <Typography>{item.coating}</Typography>
                </TableCell>
                {item.minimumOrderQuantity ? (
                    <TableCell data-testid="minimum-order-quantity">
                        <Typography className={classes.originalQuantityPallets}>
                            <Trans i18nKey="minimumOrderIs">Minimum Order is </Trans>{' '}
                            {item.minimumOrderQuantity} PL
                        </Typography>
                        {hasMoqFees && (
                            <Typography className="helper-text">
                                <Trans i18nKey="feeForOrders">Fees for orders</Trans>{' '}
                                {getMoqFeeFloor(item.moqFees)} - {getMoqFeeCeiling(item.moqFees)} PL
                            </Typography>
                        )}
                    </TableCell>
                ) : (
                    <TableCell data-testid="minimum-order-quantity">
                        <Typography>-</Typography>
                    </TableCell>
                )}
                {hasMultiplePersonas && (
                    <TableCell data-testid="leadtime-information">
                        <Typography data-leadtime={leadTime} data-testid="leadtime">
                            {`${leadTime} `}
                            {leadTime === 1 ? (
                                <Trans i18nKey="week">Week</Trans>
                            ) : (
                                <Trans i18nKey="weeks">Weeks</Trans>
                            )}
                        </Typography>
                    </TableCell>
                )}
                <TableCell data-testid="original-quantity">
                    <Grid container direction="column">
                        <Grid item>
                            <Typography className={classes.originalQuantityPallets}>
                                {formatNumberWithLocale(
                                    cultureCode,
                                    item.originalPalletQuantity || 0
                                )}{' '}
                                PL
                            </Typography>
                        </Grid>
                        {item.originalPalletQuantity !== undefined && (
                            <Grid item>
                                <Typography
                                    className="helper-text"
                                    data-testid="original-quantity-eaches"
                                >
                                    {formatNumberWithLocale(cultureCode, originalEachesValue)}
                                    {' ea.'}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </TableCell>
                <TableCell
                    className={clsx({
                        [classes.feeWarning]: hasBlankQuantity,
                        [classes.warningBackground]: notIncludedInCsv
                    })}
                >
                    <Grid container>
                        {hasBlankQuantity && (
                            <Grid item>
                                <img
                                    data-testid="error-icon"
                                    src={process.env.PUBLIC_URL + '/assets/Warning_Icon.svg'}
                                    alt="Error Icon"
                                />
                            </Grid>
                        )}
                        <Grid item className={classes.inputContainer}>
                            <Input
                                id={getPalletQuantityKey(item.productSku!)}
                                disableUnderline={true}
                                fullWidth
                                disabled={disabled}
                                type="text"
                                className={classes.textField}
                                value={purchaseQty !== undefined ? purchaseQty : ''}
                                inputProps={{ 'data-testid': 'quantity-input' }}
                                data-testid={`pallet-quantity-${item.productSku}`}
                                onBlur={(event) => handlePalletQuantityOnBlur(event.target.value)}
                                onChange={(event) => palletQuantityInputChange(event.target.value)}
                            />
                        </Grid>
                        <Grid item>
                            <Typography className={classes.pallet}>PL</Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-end" className={classes.eachesCountPadding}>
                        <Typography className="helper-text" data-testid="eaches-quantity">
                            {formatNumberWithLocale(cultureCode, eachesValue)}
                            {' ea.'}
                        </Typography>
                    </Grid>

                    {item.palletsRounded && (
                        <Grid container>
                            <Alert
                                className={classes.informationalAlert}
                                icon={false}
                                data-testid="pallet-alert"
                            >
                                <Typography className={classes.informationAlertText}>
                                    <Trans i18nKey="quantityRounded">
                                        Quantity rounded up to the nearest full pallet.
                                    </Trans>
                                </Typography>
                            </Alert>
                        </Grid>
                    )}
                    {hasBlankQuantity && (
                        <Grid container>
                            <Typography className="helper-text" data-testid="blank-quantity-error">
                                <Trans i18nKey="enterAQuantity">Please enter a quantity.</Trans>
                            </Typography>
                        </Grid>
                    )}
                </TableCell>
                <TableCell className={classes.alignCenter} data-testid="delete-icon">
                    <Button
                        type="button"
                        className={classes.trash}
                        disabled={disabled}
                        onClick={handleRemoveParentItem}
                        data-testid={`delete-product-btn-${item.productSku}`}
                    >
                        {disabled && (
                            <img
                                src={process.env.PUBLIC_URL + '/assets/DisabledTrashIcon.svg'}
                                alt="Disabled Trash Icon"
                            />
                        )}
                        {!disabled && (
                            <img
                                src={process.env.PUBLIC_URL + '/assets/Trash.svg'}
                                alt="Trash Icon"
                            />
                        )}
                    </Button>
                </TableCell>
            </TableRow>
            <MakeItBulkProductIdModal
                open={productIdOpen}
                customerProductId={customerProductId!}
                candidateProductSkus={candidateProductSkus!}
                onClose={onCloseProductId}
                isAtm={true}
                onAcceptProductSku={handleProductSkuSelection}
                productSource={'PlanIt'}
                onApplyToAllProductSku={handleProductSkuSelectionApplyAll}
            />
        </>
    );
};

export default PlanItBulkReviewOrderSectionRow;
