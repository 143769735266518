import React from 'react';
import PdfPageTemplate from '../../../templates/PdfPageTemplate';
import { Document, Page, Text, View, BlobProvider, Image } from '@react-pdf/renderer';
import pdfStyles from '../../../../themes/pdfStyles';
import {
    ProductionOrderLine,
    ProductCountInformation
} from '../../../../store/reducers/makeit-dashboard';
import { isIE } from '../../../../utility/helpers/user-helpers';
import { useTranslation, Trans } from 'react-i18next';
import { getGraphicIdAndVersion } from '../../../../utility/helpers/order-helpers';
import { getUtcMidnight } from '../../../../utility/helpers/date-helpers';
import { formatNumberWithLocale } from '../../../../utility/helpers/formatters';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';

interface Props {
    title: string;
    customerPo: string;
    dateSubmitted: string;
    orderList: ProductionOrderLine[];
    countInformation: ProductCountInformation;
    shipToAddress: string;
    shipToName: string;
}

const ConfirmationPDF = ({
    title,
    orderList,
    customerPo,
    dateSubmitted,
    countInformation,
    shipToAddress,
    shipToName
}: Props) => {
    const ballLogo = process.env.PUBLIC_URL + '/assets/Ball_RGB.jpg';
    const sourceLogo = process.env.PUBLIC_URL + '/assets/TheSource_Logo.png';

    useTranslation();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const productRows = orderList.map((p, index) => {
        const requestedDate = getUtcMidnight(p.requestedDate).format('MM/DD/YYYY');
        return (
            <>
                <View style={pdfStyles.divider7} />
                <View style={[pdfStyles.tableRow]} key={index} wrap={false}>
                    <View style={pdfStyles.borderLessTableCol}>
                        <Text style={[pdfStyles.tableCell2]}>
                            {p.displayId}
                            {' / '}
                            {getGraphicIdAndVersion(p.type, p.graphicIdAndVersion)}
                        </Text>
                    </View>
                    <View style={pdfStyles.borderLessTableColWide2}>
                        <Text style={[pdfStyles.tableCell2]}>
                            {p.displayName + ' '}
                            {p.size}
                            {p.unit}
                        </Text>
                    </View>
                    <View style={pdfStyles.borderLessTableColNarrow2}>
                        <Text style={[pdfStyles.tableCell2]}>{p.coating}</Text>
                    </View>
                    <View style={pdfStyles.borderLessTableColNarrow7}>
                        <Text style={[pdfStyles.tableCell3]}>{requestedDate}</Text>
                    </View>
                    <View style={pdfStyles.borderLessTableColNarrow2}>
                        <Text style={[pdfStyles.tableCell3]}>
                            {p.palletQuantity}
                            {' PL'}
                        </Text>
                        <Text style={[pdfStyles.tableCell5, pdfStyles.textAlignRight]}>
                            {p.palletQuantity && p.quantityPerPallet &&
                                formatNumberWithLocale(
                                    cultureCode,
                                    p.palletQuantity * p.quantityPerPallet
                                )
                            }
                            {' ea.'}
                        </Text>
                    </View>
                </View>
            </>
        );
    });

    const myDoc = (
        <Document title={title}>
            <Page size="A4" style={pdfStyles.page} orientation={'portrait'}>
                <View style={pdfStyles.logoSection} fixed>
                    <Image src={sourceLogo} style={pdfStyles.sourceLogo} />
                    <Image src={ballLogo} style={pdfStyles.ballLogo} />
                </View>
                <View style={pdfStyles.divider6} fixed />
                <View style={pdfStyles.borderlessTable}>
                    <View style={pdfStyles.tableRow}>
                        <View style={[pdfStyles.borderLessTableColWide4]}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="customerPoNumber">Customer PO #</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow2}>
                            <Text style={[pdfStyles.header4]}>
                                <Trans i18nKey="dateSubmitted">DATE SUBMITTED</Trans>
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                        <View style={[pdfStyles.borderLessTableColWide4]}>
                            <Text style={pdfStyles.subtitle6}>{customerPo}</Text>
                        </View>
                        <View style={[pdfStyles.borderLessTableColNarrow2]}>
                            <Text style={pdfStyles.subtitle5}>{dateSubmitted}</Text>
                        </View>
                    </View>
                </View>
                <View style={pdfStyles.divider6} />
                <View style={pdfStyles.borderlessTable}>
                    <View style={pdfStyles.tableRow}>
                        <View style={pdfStyles.borderLessTableColWide2}>
                            <Text style={[pdfStyles.header2]}>
                                <Trans i18nKey="shipTo">SHIP TO</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow2}>
                            <Text style={[pdfStyles.header4]}>
                                <Trans i18nKey="cans">CANS</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow2}>
                            <Text style={[pdfStyles.header4]}>
                                <Trans i18nKey="ends">ENDS</Trans>
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                        <View style={pdfStyles.borderLessTableColWide2}>
                            <Text style={[pdfStyles.subtitle3]}>{shipToName}</Text>
                            <Text style={[pdfStyles.subtitle3]}>{shipToAddress.toUpperCase()}</Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow2}>
                            <Text style={[pdfStyles.subtitle4]}>
                                {
                                    formatNumberWithLocale(
                                        cultureCode,
                                        countInformation.canBtlCount
                                    )
                                } PL /{' '}
                                {countInformation.canBtlSkuCount} SKU
                            </Text>
                            <Text style={[pdfStyles.subtitle7, pdfStyles.textAlignRight, pdfStyles.marginBottom10]}>
                                {countInformation.canEachesCount && 
                                    formatNumberWithLocale(
                                            cultureCode,
                                            countInformation.canEachesCount
                                    )
                                } ea.
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow2}>
                            <Text style={[pdfStyles.subtitle4]}>
                                {
                                    formatNumberWithLocale(
                                        cultureCode,
                                        countInformation.endCount
                                    )
                                } PL / {countInformation.endSkuCount} SKU
                            </Text>
                            <Text style={[pdfStyles.subtitle7, pdfStyles.textAlignRight, pdfStyles.marginBottom10]}>
                                {countInformation.endEachesCount && 
                                    formatNumberWithLocale(
                                        cultureCode,
                                        countInformation.endEachesCount
                                    )
                                } ea.
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={pdfStyles.borderlessTable}>
                    <View style={pdfStyles.tableRow}>
                        <View style={pdfStyles.borderLessTableCol}>
                            <Text style={[pdfStyles.header2]}>
                                <Trans i18nKey="productGraphicId">PRODUCT ID / GRAPHIC ID</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColWide2}>
                            <Text style={[pdfStyles.header2]}>
                                <Trans i18nKey="descriptionCharacteristics">
                                    PRODUCT DESCRIPTION / CHARACTERISTICS
                                </Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow2}>
                            <Text style={[pdfStyles.header2]}>
                                <Trans i18nKey="coating">COATING</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow7}>
                            <Text style={[pdfStyles.header4]}>
                                <Trans i18nKey="requestedDate">REQUESTED DATE</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow2}>
                            <Text style={[pdfStyles.header4]}>
                                <Trans i18nKey="quantity">QUANTITY</Trans>
                            </Text>
                        </View>
                    </View>
                    {productRows}
                </View>
                <View style={[pdfStyles.fullWidth, pdfStyles.footer]} fixed>
                    <Text
                        style={pdfStyles.header2}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </View>
            </Page>
        </Document>
    );

    if (!isIE()) {
        return <PdfPageTemplate title={title}>{myDoc}</PdfPageTemplate>;
    } else {
        return (
            <BlobProvider document={myDoc}>
                {({ blob, url, loading, error }) => {
                    if (blob && window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob);
                    }
                    return null;
                }}
            </BlobProvider>
        );
    }
};

export default ConfirmationPDF;
