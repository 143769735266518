import React from 'react';
import { useTranslation} from 'react-i18next';
import { makeStyles, Typography, TableRow, TableCell, Grid } from '@material-ui/core';
import {
    blackWeight,
    boldWeight
} from '../../../../themes/globalConstants';
import { ProductToShip } from '../../../../store/reducers/shipping-dashboard';

interface Props {
    item: ProductToShip;
}

const useStyles = makeStyles((theme) => ({
    displayName: {
        fontWeight: blackWeight,
        textTransform: 'uppercase'
    },
    displayQuantity: {
        fontWeight: boldWeight
    }
}));

export default function ScrapItConfirmationRow({
    item,
}: Props) {
    const classes = useStyles();

    useTranslation();

    return (
        <TableRow
            data-testid={`product-${item.productSku}`}
            data-pallet-quantity={`${item.availablePallets}`}
            data-can-type={`${item.type}`}
        >
            <TableCell>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography data-testid="display-name" className={classes.displayName}>
                            {item.displayName}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography data-testid="display-graphic-ids">
                            {'('}
                            {item.productSku}{!!item.graphicIdAndVersion ? '/' + item.graphicIdAndVersion : ''}
                            {')'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid container>
                    <Typography data-testid="customer-product-id">
                        {!!item.customerProductId ? item.customerProductId : '-'}
                    </Typography>
                </Grid>
            </TableCell>
            <TableCell>
                <Typography data-testid="coating">{item.coating}</Typography>
            </TableCell>
            <TableCell align='right'>
                <Typography data-testid="pallets" className={classes.displayQuantity}>
                    {Number(item.inputPallets).toLocaleString()}
                    {' PL'}
                </Typography>
                <Typography data-testid="eaches">
                    {Number(
                        item.inputPallets! * item.quantityPerPallet!
                    ).toLocaleString()}
                    {' ea.'}
                </Typography>
            </TableCell>
        </TableRow>
    );
}
