import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class MyAccountMyAccessColumns {
    static getMyAccessColumns = (culture: string) => {
        return [
            createColumn('location', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'location'),
                visible: true,
                sortable: true,
                searchable: true
            }),
            createColumn('address', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'address'),
                visible: true,
                sortable: true,
                searchable: true
            }),
            createColumn('roles', {
                label: TranslationService.getTranslatedText(culture, 'roles'),
                visible: true,
                sortable: true,
                searchable: true
            })
        ];
    };
}
