import { makeStyles, Typography, Card, CardContent, Grid } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import Image from '../atoms/Image';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    ballBlack,
    black_8,
    boldWeight,
    medium,
    regularWeight
} from '../../../themes/globalConstants';
import Link from '../atoms/Link';

interface Props {
    translationKey?: string;
    defaultText: string;
    secondaryText?: string;
    secondaryTranslationKey?: string;
    source?: string;
    link?: string;
    icon?: string;
    altIcon?: JSX.Element;
    iconClassName?: string;
    externalLink?: boolean;
    url?: string;
    isProduct: boolean;
    testId?: string;
    disabled?: boolean;
    onClick?: () => void;
}

const useStyles = makeStyles((theme) => ({
    iconRoot: {
        transition: 'transform .2s',
        '&:hover': {
            overflow: 'visible',
            boxShadow: `0 0 10px 0 ${black_8}`
        },
        '& .content-title': {
            fontSize: medium,
            fontWeight: boldWeight,
            color: ballBlack,
            letterSpacing: '0.15px'
        },
        '& .content-subtitle': {
            fontSize: 12,
            fontWeight: regularWeight,
            letterSpacing: '0.15px'
        }
    },
    imageRoot: {
        width: 'fit-content',
        textAlign: 'center',
        marginTop: '1em',
        height: '14em',
        transition: 'transform .2s',
        '&:hover': {
            transform: 'scale(1.1)',
            overflow: 'visible',
            boxShadow: `0 0 10px 0 ${black_8}`,
            '& .content-title': {
                color: theme.palette.primary.main
            }
        },
        '& .content-title': {
            fontSize: 13,
            fontWeight: 'bold',
            letterSpacing: 1
        }
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.secondary.main,
        cursor: 'pointer'
    },
    image: {
        backgroundSize: 'contain'
    },
    contactIcon: {
        height: '4.5em',
        margin: '0.5em 0 1em 0'
    },
    tileSection: {
        marginRight: 8,
        marginBottom: 8
    }
}));

const NavBarTile = ({
    translationKey,
    defaultText,
    secondaryText,
    secondaryTranslationKey,
    source,
    link,
    isProduct,
    icon,
    altIcon,
    iconClassName,
    externalLink,
    url,
    testId,
    disabled,
    onClick
}: Props) => {
    useTranslation();
    const classes = useStyles();
    const baseUrl = `${process.env.REACT_APP_CONTENT_API}/content/media?mediaPath=/CustomerPortalCMS/media/customer-portal-media`;
    const iconLink = `${baseUrl}/Icon%20Library/${source}`;
    const imageLink = `${baseUrl}/Image%20Library/${source}`;
    const localIcon = `${process.env.PUBLIC_URL}/assets/${icon}`;

    const getTileText = () => {
        return (
            <Grid item xs container justify="flex-start" direction="column">
                {translationKey ? (
                    <Typography className="content-title">
                        <Trans i18nKey={translationKey}>{defaultText}</Trans>
                    </Typography>
                ) : (
                    <Typography className="content-title"> {defaultText}</Typography>
                )}
                {secondaryTranslationKey ? (
                    <Typography className="content-subtitle">
                        <Trans i18nKey={secondaryTranslationKey}>{secondaryText}</Trans>
                    </Typography>
                ) : (
                    <Typography className="content-subtitle">
                        {disabled ? <i>{secondaryText}</i> : secondaryText}
                    </Typography>
                )}
            </Grid>
        );
    };

    return (
        <section data-testid={testId ? testId : defaultText} className={classes.tileSection}>
            <Card elevation={0} className={isProduct ? classes.imageRoot : classes.iconRoot}>
                {link && !disabled ? (
                    <RouterLink
                        key={translationKey}
                        to={link}
                        aria-label={defaultText}
                        className={classes.link}
                        onClick={onClick}
                        data-testid={testId ? 'link-' + testId : 'link-' + defaultText}
                    >
                        <CardContent>
                            <Grid
                                container
                                justify="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                            >
                                {icon && (
                                    <Grid item xs="auto">
                                        <Image
                                            src={localIcon}
                                            alt={defaultText}
                                            className={iconClassName}
                                        />
                                    </Grid>
                                )}
                                {!icon && source && (
                                    <Grid item xs="auto">
                                        <Image
                                            src={!isProduct ? iconLink : imageLink}
                                            alt={defaultText}
                                            className={!isProduct ? iconClassName : classes.image}
                                        />
                                    </Grid>
                                )}
                                {altIcon && (
                                    <Grid item xs="auto">
                                        {altIcon}
                                    </Grid>
                                )}
                                {getTileText()}
                            </Grid>
                        </CardContent>
                    </RouterLink>
                ) : externalLink ? (
                    <Link
                        key={translationKey}
                        url={url}
                        target="_blank"
                        rel="noreferrer noopener"
                        underline="none"
                        aria-label={defaultText}
                        className={classes.link}
                    >
                        <CardContent>
                            <Grid
                                container
                                justify="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                            >
                                {icon ? (
                                    <Grid item xs="auto">
                                        <Image
                                            src={localIcon}
                                            alt={defaultText}
                                            className={iconClassName}
                                        />
                                    </Grid>
                                ) : (
                                    <Grid item xs="auto">
                                        <Image
                                            src={!isProduct ? iconLink : imageLink}
                                            alt={defaultText}
                                            className={!isProduct ? iconClassName : classes.image}
                                        />
                                    </Grid>
                                )}
                                {getTileText()}
                            </Grid>
                        </CardContent>
                    </Link>
                ) : (
                    <CardContent className={classes.link}>
                        <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
                            {icon ? (
                                <Grid item xs="auto">
                                    <Image
                                        src={localIcon}
                                        alt={defaultText}
                                        className={iconClassName}
                                    />
                                </Grid>
                            ) : (
                                <Grid item xs="auto">
                                    <Image
                                        src={!isProduct ? iconLink : imageLink}
                                        alt={defaultText}
                                        className={!isProduct ? iconClassName : classes.image}
                                    />
                                </Grid>
                            )}
                            {getTileText()}
                        </Grid>
                    </CardContent>
                )}
            </Card>
        </section>
    );
};

export default NavBarTile;
