import React, { ReactNode, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { Region } from '../../../store/reducers/graphic-intake';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import ConfirmationModal from './ConfirmationModal';

interface Props {
    saveProgress: () => void;
    onClose: () => void;
    open: boolean;
    saveProgressText?: ReactNode;
    onCancel?: () => void;
    canSaveDraft?: boolean;
}

const CancelProgressModal = ({
    saveProgress,
    open,
    onClose,
    onCancel,
    saveProgressText,
    canSaveDraft
}: Props) => {
    const [deleteProgressKey, setDeleteProgressKey] = useState<string>('deleteProgress');
    const [saveProgressKey, setSaveProgressKey] = useState<string>('saveProgress');
    const [cancelSubmissionKey, setCancelSubmissionKey] = useState<string>('cancelSubmission');
    const [intakeCancelConfirmationKey, setIntakeCancelConfirmationKey] = useState<string>(
        'intakeCancelConfirmation'
    );
    const [changesPermanentlyGoneKey, setChangesPermanentlyGoneKey] =
        useState<string>('changesPermanentlyGone');
    const { regionCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    useEffect(() => {
        const region = regionCode === Region.EU ? 'Emea' : '';
        setDeleteProgressKey(`deleteProgress${region}`);
        setSaveProgressKey(`saveProgress${region}`);
        setCancelSubmissionKey(`cancelSubmission${region}`);
        setIntakeCancelConfirmationKey(`intakeCancelConfirmation${region}`);
        setChangesPermanentlyGoneKey(`changesPermanentlyGone${region}`);
    }, [regionCode]);

    return (
        <ConfirmationModal
            title={<Trans i18nKey={cancelSubmissionKey}>Cancel Your Submission</Trans>}
            open={open}
            logo={false}
            subheader={
                <Trans i18nKey={intakeCancelConfirmationKey}>
                    Are you sure you want to cancel your progress without saving?
                </Trans>
            }
            description={
                <Trans i18nKey={changesPermanentlyGoneKey}>
                    Any changes made to your submission will be permanently gone.
                </Trans>
            }
            cancelText={<Trans i18nKey={deleteProgressKey}>Delete Progress</Trans>}
            continueText={
                saveProgressText ? (
                    saveProgressText
                ) : (
                    <Trans i18nKey={saveProgressKey}>Save Progress</Trans>
                )
            }
            saveProgress={saveProgress}
            onClose={onClose}
            onCancel={onCancel}
            canSaveDraft={canSaveDraft}
        />
    );
};

export default CancelProgressModal;
