import { Grid, makeStyles, Button, TextField, FormHelperText, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import FormSection from '../../../reusable/molecules/FormSection';
import { medium, blackWeight, ballBlue, red } from '../../../../themes/globalConstants';

interface Props {
    handleVerifyIds: (accounts: string[]) => void;
    errors: boolean[];
    locationsVerified: boolean;
    handleEditLocations: () => void;
}

const useStyles = makeStyles((theme) => ({
    label: {
        color: theme.palette.info.dark,
        fontSize: medium
    },
    helperText: {
        '& .MuiFormHelperText-root': {
            marginLeft: 0
        }
    },
    btnContainer: {
        width: '30em',
        margin: 'auto',
        height: '3em'
    },
    btn: {
        width: '15em'
    },
    addLocationId: {
        backgroundColor: 'transparent',
        outline: 'none',
        fontWeight: blackWeight,
        fontSize: medium,
        textDecoration: 'underline',
        '&:hover': {
            color: ballBlue,
            cursor: 'pointer'
        }
    },
    addIcon: {
        paddingLeft: '0.5em'
    },
    errorText: {
        color: red
    },
    locationsContainer: {
        marginTop: '1em',
        marginLeft: '0.5em'
    },
    location: {
        fontSize: 18
    }
}));

const LocationInformation = ({
    handleVerifyIds,
    errors,
    locationsVerified,
    handleEditLocations
}: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [locationIds, setLocationIds] = useState<string[]>(['']);
    const [hasMultipleFields, setHasMultipleFields] = useState<boolean>(false);

    const handleOnUserInputChange = (locationInput, index) => {
        let idArray = [...locationIds];
        idArray[index] = locationInput;
        setLocationIds(idArray);
    };
    const handleAddField = () => {
        let idArray = [...locationIds];
        idArray.push('');
        setLocationIds(idArray);
    };
    const handleRemoveField = (index) => {
        let idArray = [...locationIds];
        idArray = idArray.filter((loc, i) => i !== index);
        setLocationIds(idArray);
    };
    const onVerifyIds = () => {
        handleVerifyIds(locationIds);
    };
    useEffect(() => {
        setHasMultipleFields(locationIds.length > 1);
    }, [locationIds]);

    return (
        <FormSection
            testId={'user-information-form'}
            sectionHeader={<Trans i18nKey="locationInformation">Location Information</Trans>}
        >
            {!locationsVerified && (
                <>
                    {locationIds.map((location, index) => {
                        return (
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={6} key={index}>
                                    <TextField
                                        data-testid="location-id"
                                        label={`${t('shipToBillTo', 'Ship To / Bill To #')}`}
                                        fullWidth
                                        value={location}
                                        name={`location[${index}]`}
                                        onChange={(e) =>
                                            handleOnUserInputChange(e.target.value, index)
                                        }
                                        error={errors[index]}
                                        disabled={locationsVerified}
                                    />
                                </Grid>
                                <Button
                                    type="button"
                                    onClick={() => handleRemoveField(index)}
                                    disabled={!hasMultipleFields}
                                >
                                    {(!hasMultipleFields || locationsVerified) && (
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/assets/DisabledTrashIcon.svg'
                                            }
                                            alt="Disabled Trash Icon"
                                        />
                                    )}
                                    {hasMultipleFields && !locationsVerified && (
                                        <img
                                            src={process.env.PUBLIC_URL + '/assets/Trash.svg'}
                                            alt="Trash Icon"
                                        />
                                    )}
                                </Button>
                            </Grid>
                        );
                    })}
                    <Grid container item xs={12} spacing={2}>
                        <img
                            src={process.env.PUBLIC_URL + '/assets/Circle_Add.svg'}
                            alt="Add Icon"
                            className={classes.addIcon}
                        />
                        <Button
                            variant="text"
                            color="primary"
                            data-testid="add-shipto-btn"
                            className={classes.addLocationId}
                            onClick={handleAddField}
                            disabled={locationsVerified}
                        >
                            <Trans i18nKey="addAnotherShipTo">
                                Add Another Ship To / Bill To #
                            </Trans>
                        </Button>
                    </Grid>
                    <Grid container>
                        {errors.includes(true) && (
                            <FormHelperText className={classes.errorText} data-testid="invalid-id">
                                <Trans i18nKey="invalidIds">
                                    One or more ID numbers are not valid. Please update or remove
                                    these locations to proceed.
                                </Trans>
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        className={classes.btnContainer}
                        alignItems="center"
                        justify="flex-end"
                        xs={12}
                    >
                        {!locationsVerified && (
                            <Grid container item justify="flex-end" xs={12}>
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    data-testid="confirm-btn"
                                    onClick={onVerifyIds}
                                    className={classes.btn}
                                >
                                    <Trans i18nKey="confirm">Confirm</Trans>
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </>
            )}
            {locationsVerified && (
                <Grid container spacing={2}>
                    <Grid container item className={classes.locationsContainer} xs={12}>
                        <Grid container item xs={12}>
                            <Typography>
                                <Trans i18nKey="shipToBillTo">Ship To / Bill To #</Trans>
                            </Typography>
                        </Grid>
                        <Grid xs={12}>
                            {locationIds.map((location) => (
                                <Typography variant="h4" className={classes.location}>
                                    {location}
                                </Typography>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid container item justify="flex-end" xs={12}>
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            data-testid="edit-btn"
                            onClick={handleEditLocations}
                            className={classes.btn}
                        >
                            <Trans i18nKey="edit">Edit</Trans>
                        </Button>
                    </Grid>
                </Grid>
            )}
        </FormSection>
    );
};
export default LocationInformation;
