import { ColumnDataType, createColumn } from 'tubular-common';
import { Region } from '../../../../store/reducers/graphic-intake';
import TranslationService from '../../../../utility/services/translation-service';

export default class GraphicsCompletedColumns {
    static getGraphicsCompletedColumns = (culture: string, regionCode: string) => {
        const region = regionCode === Region.EU ? 'Emea' : '';
        const graphicNameAndIdKey = `graphicNameAndId${region}`;
        return [
            createColumn('sortIndex', {
                searchable: false,
                sortable: false,
                filterable: false,
                visible: false,
                dataType: ColumnDataType.Numeric
            }),
            createColumn('requestName', {
                searchable: true,
                filterable: true,
                sortable: true,
                label: TranslationService.getTranslatedText(culture, graphicNameAndIdKey),
                dataType: ColumnDataType.String
            }),
            createColumn('graphicsNumber', {
                searchable: true,
                filterable: false,
                visible: false
            }),
            createColumn('searchableStatus', {
                label: TranslationService.getTranslatedText(culture, 'status'),
                visible: true,
                searchable: true,
                filterable: true,
                sortable: true
            }),
            createColumn('statuses', {
                visible: false,
                searchable: false,
                filterable: false,
                sortable: false
            }),
            createColumn('graphicsId', {
                searchable: true,
                filterable: false,
                visible: false
            })
        ];
    };
}
