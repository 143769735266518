import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class PlanItOrderDetailsColumns {
    static getPlanItOrderDetailsColumns = (culture: string) => {
        return [
            createColumn('productionOrderLineId', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('displayId', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'customerProductGraphicId'),
                filterable: true,
                sortable: true,
                searchable: false
            }),
            createColumn('displayName', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'productDescription'),
                filterable: true,
                sortable: true,
                searchable: false
            }),
            createColumn('status', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'status'),
                filterable: true,
                sortable: true,
                searchable: false
            }),
            createColumn('productCoating', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'coating'),
                filterable: true,
                sortable: true,
                searchable: false
            }),
            createColumn('palletQuantity', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'forecastedQuantity'),
                filterable: true,
                sortable: true,
                searchable: false
            }),
            createColumn('quantityPerPallet', {
                dataType: ColumnDataType.Numeric,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('productDescription', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productSku', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productName', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('requestedDate', {
                dataType: ColumnDataType.Date,
                visible: false
            }),
            createColumn('status', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'lineStatus'),
                filterable: true,
                sortable: true,
                searchable: false
            }),
            createColumn('customerProductStatus', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productUnits', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productSize', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('productShape', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productNeckSize', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productGraphicId', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productTabColor', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productType', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productEndProfile', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('customerProductId', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('customerProductName', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productStatus', {
                dataType: ColumnDataType.String,
                visible: false
            })
        ];
    };
}
