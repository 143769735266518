import {
    OnboardingAccountStatus,
    OnboardingStepStatus,
    OnboardingStepState,
    TaxApplication,
    JoiningStatus
} from '../../utility/services/onboarding-service';
import * as types from '../actions/action-types';

/**
 * @param Unknown Unrecognized Ship Type,
 * @param Shipped Order will be shipped to client,
 * @param Pickup Order will be picked up
 */
export enum ShipType {
    Unknown = 'UNKNOWN',
    Shipped = 'SHIPPED',
    Pickup = 'PICKUP'
}

/**
 * @param Unknown Unrecognized Entity Type,
 * @param Legal Legal Entity,
 * @param Entrepreneur Private Party Entrepreneur
 */
export enum EntityType {
    Unknown = 'UNKNOWN',
    Legal = 'LEGAL',
    Entrepreneur = 'ENTREPRENEUR'
}

/**
 * @param Unknown Unrecognized Entity Type,
 * @param Ball Ball Imports,
 * @param Customer Customer Imports
 */
export enum Importer {
    Unknown = 'UNKNOWN',
    Ball = 'BALL',
    Customer = 'CUSTOMER'
}

export interface Address {
    name?: string;
    country?: string;
    addressLine1?: string;
    addressLine2?: string;
    addressLine3?: string;
    city?: string;
    state?: string;
    zipCode?: string;
}

export interface ShippingInformation {
    shipType?: ShipType;
    palletConfiguration?: string;
    specialEquipment?: string;
}

export interface IdInformation {
    cfdi?: boolean;
    taxId?: string;
    entityType?: EntityType;
    importer?: Importer;
}

export interface BillingAndShippingForm {
    id?: string;
    accountId?: string;
    username?: string;
    state?: OnboardingStepState;
    billingAddress?: Address;
    shippingAddress?: Address;
    shippingInformation?: ShippingInformation;
    idInformation?: IdInformation;
    shippingSameAsBillingAddress?: string;
}

export interface AccountStatus {
    accountId: string;
    termsStatus: OnboardingStepStatus;
    addressesStatus: OnboardingStepStatus;
    creditAppStatus: OnboardingStepStatus;
    taxAppStatus: OnboardingStepStatus;
    status: ApiStatus;
    joiningStatus?: JoiningStatus;
}

export interface OnboardingDashboardState {
    accountStatus: AccountStatus;
    salesAndUseTax: SalesAndUseTaxState;
    termsAndConditions: OnboardingDashboardStep;
    billingAndShipping: BillingAndShippingState;
    creditApplication: CreditApplicationState;
    inviteSuccess: boolean;
    capacityCheckError?: any;
}

type ApiStatus = 'loading' | 'success' | 'idle' | 'error';

interface OnboardingDashboardStep {
    state: OnboardingStepState;
    submissionStatus: ApiStatus;
    saveStatus: ApiStatus;
    error?: any;
}

interface SalesAndUseTaxState extends OnboardingDashboardStep {
    salesAndUseTaxForm?: TaxApplication;
}

export interface BillingAndShippingState extends OnboardingDashboardStep {
    billingAndShippingForm?: BillingAndShippingForm;
}

interface CreditApplicationState extends OnboardingDashboardStep {
    creditApplicationForm?: CreditApplication;
}

export interface CreditApplication {
    id?: string;
    accountId?: string;
    username?: string;
    state?: number;
    legalBusinessName?: string;
    businessName?: string;
    businessType?: string;
    monthlyCreditDesired?: string;
    billingAddress?: Address;
    actualLocation?: Address;
    companyInformation?: CompanyInformation;
    supplierReferences?: SupplierReference[];
    additionalComments?: string;
    fileExplanation?: string;
    hasSameRealAddress?: boolean;
}

export interface Address {
    name?: string;
    country?: string;
    addressLine1?: string;
    addressLine2?: string;
    addressLine3?: string;
    city?: string;
    state?: string;
    zipCode?: string;
}

export interface CompanyInformation {
    contact?: string;
    contactEmail?: string;
    phoneNumber?: string;
    country?: string;
    incorporationState?: string;
    dateEstablished?: string;
    businessType?: string;
    headsOfBusiness?: CompanyOfficer[];
}

export interface CompanyOfficer {
    firstName?: string;
    lastName?: string;
    title?: string;
}

export interface SupplierReference {
    firstName?: string;
    lastName?: string;
    address?: string;
    contact?: string;
    contactEmail?: string;
    phoneNumber?: string;
    faxNumber?: string;
}

const initialState: OnboardingDashboardState = {
    accountStatus: {
        accountId: '',
        addressesStatus: OnboardingStepStatus.INCOMPLETE,
        taxAppStatus: OnboardingStepStatus.INCOMPLETE,
        creditAppStatus: OnboardingStepStatus.INCOMPLETE,
        termsStatus: OnboardingStepStatus.INCOMPLETE,
        status: 'idle',
        joiningStatus: JoiningStatus.INCOMPLETE
    },
    salesAndUseTax: {
        state: OnboardingStepState.UNKNOWN,
        submissionStatus: 'idle',
        saveStatus: 'idle',
        error: undefined,
        salesAndUseTaxForm: undefined
    },
    termsAndConditions: {
        state: OnboardingStepState.UNKNOWN,
        submissionStatus: 'idle',
        saveStatus: 'idle',
        error: undefined
    },
    billingAndShipping: {
        state: OnboardingStepState.UNKNOWN,
        submissionStatus: 'idle',
        saveStatus: 'idle',
        error: undefined,
        billingAndShippingForm: undefined
    },
    creditApplication: {
        state: OnboardingStepState.UNKNOWN,
        submissionStatus: 'idle',
        saveStatus: 'idle',
        error: undefined,
        creditApplicationForm: undefined
    },
    inviteSuccess: false
};

const onboardingDashboardState: (
    state: OnboardingDashboardState,
    action: any
) => OnboardingDashboardState = (state = initialState, action: any) => {
    switch (action.type) {
        // -- SALES AND USE TAX APPLICATION -- //
        case types.ONBOARDING_SALESANDUSETAX_SUBMIT_SUCCESS: {
            return {
                ...state,
                salesAndUseTax: {
                    ...state.salesAndUseTax,
                    state: OnboardingStepState.COMPLETE,
                    submissionStatus: 'success',
                    error: undefined,
                    salesAndUseTaxForm: action.salesAndUseTaxForm
                }
            };
        }
        case types.ONBOARDING_SALESANDUSETAX_SUBMIT_LOADING: {
            return {
                ...state,
                salesAndUseTax: {
                    ...state.salesAndUseTax,
                    submissionStatus: 'loading'
                }
            };
        }
        case types.ONBOARDING_SALESANDUSETAX_SUBMIT_ERROR: {
            return {
                ...state,
                salesAndUseTax: {
                    ...state.salesAndUseTax,
                    submissionStatus: 'error',
                    error: action.error
                }
            };
        }
        case types.ONBOARDING_SALESANDUSETAX_SAVEPROGRESS_SUCCESS: {
            return {
                ...state,
                salesAndUseTax: {
                    ...state.salesAndUseTax,
                    state: OnboardingStepState.DRAFT,
                    saveStatus: 'success',
                    error: undefined
                }
            };
        }
        case types.ONBOARDING_SALESANDUSETAX_SAVEPROGRESS_LOADING: {
            return {
                ...state,
                salesAndUseTax: {
                    ...state.salesAndUseTax,
                    saveStatus: 'loading'
                }
            };
        }
        case types.ONBOARDING_SALESANDUSETAX_SAVEPROGRESS_ERROR: {
            return {
                ...state,
                salesAndUseTax: {
                    ...state.salesAndUseTax,
                    saveStatus: 'error',
                    error: action.error
                }
            };
        }
        case types.ONBOARDING_SALESANDUSETAX_BACKAFTERSAVE:
        case types.ONBOARDING_SALESANDUSETAX_CONTINUEEDIT: {
            return {
                ...state,
                salesAndUseTax: {
                    ...state.salesAndUseTax,
                    saveStatus: 'idle'
                }
            };
        }
        case types.ONBOARDING_SALESANDUSETAX_CANCEL: {
            return {
                ...state,
                salesAndUseTax: {
                    ...initialState.salesAndUseTax,
                    state: state.salesAndUseTax.state
                }
            };
        }
        case types.ONBOARDING_SALESANDUSETAX_FETCH_SUCCESS: {
            const taxApp: TaxApplication = action.salesAndUseTaxForm;
            return {
                ...state,
                salesAndUseTax: {
                    ...state.salesAndUseTax,
                    state: taxApp.state,
                    error: undefined,
                    salesAndUseTaxForm: taxApp
                }
            };
        }

        // -- ACCOUNT STATUS -- //
        case types.ONBOARDING_ACCOUNTSTATUS_RESET: {
            return {
                ...state,
                accountStatus: {
                    ...state.accountStatus,
                    status: 'idle'
                }
            };
        }
        case types.ONBOARDING_ACCOUNTSTATUS_SUCCESS: {
            return {
                ...state,
                accountStatus: { ...action.accountStatus, status: 'success' },
                salesAndUseTax: {
                    ...state.salesAndUseTax,
                    state:
                        (action.accountStatus as OnboardingAccountStatus).taxAppStatus ===
                        OnboardingStepStatus.COMPLETE
                            ? OnboardingStepState.COMPLETE
                            : OnboardingStepState.UNKNOWN
                },
                termsAndConditions: {
                    ...state.termsAndConditions,
                    state:
                        (action.accountStatus as OnboardingAccountStatus).termsStatus ===
                        OnboardingStepStatus.COMPLETE
                            ? OnboardingStepState.COMPLETE
                            : OnboardingStepState.UNKNOWN
                },
                billingAndShipping: {
                    ...state.billingAndShipping,
                    state:
                        (action.accountStatus as OnboardingAccountStatus).addressesStatus ===
                        OnboardingStepStatus.COMPLETE
                            ? OnboardingStepState.COMPLETE
                            : OnboardingStepState.UNKNOWN
                },
                creditApplication: {
                    ...state.creditApplication,
                    state:
                        (action.accountStatus as OnboardingAccountStatus).creditAppStatus ===
                        OnboardingStepStatus.COMPLETE
                            ? OnboardingStepState.COMPLETE
                            : OnboardingStepState.UNKNOWN
                }
            };
        }

        // -- CREDIT APPLICATION -- //
        case types.ONBOARDING_CREDITAPP_SUCCESS: {
            return {
                ...state,
                creditApplication: {
                    ...state.creditApplication,
                    complete: true,
                    error: undefined,
                    creditApplicationForm: action.creditApplicationForm
                }
            };
        }
        case types.ONBOARDING_CREDITAPP_DRAFT_LOADED: {
            return {
                ...state,
                creditApplication: {
                    ...state.creditApplication,
                    state: !!action.creditApplicationForm?.state
                        ? action.creditApplicationForm.state
                        : OnboardingStepState.UNKNOWN,
                    complete: false,
                    error: undefined,
                    creditApplicationForm: action.creditApplicationForm,
                    saveProgressState: 'idle'
                }
            };
        }
        case types.ONBOARDING_CREDITAPP_DRAFT_SAVED: {
            return {
                ...state,
                creditApplication: {
                    ...state.creditApplication,
                    complete: false,
                    error: undefined,
                    creditApplicationForm: action.creditApplicationForm,
                    saveProgressState: 'success'
                }
            };
        }
        case types.ONBOARDING_CREDITAPP_SUBMIT_SUCCESS: {
            return {
                ...state,
                creditApplication: {
                    ...state.creditApplication,
                    complete: true,
                    error: undefined,
                    creditApplicationForm: action.creditApplicationForm,
                    submissionStatus: 'success'
                }
            };
        }
        case types.ONBOARDING_CREDITAPP_LOADING: {
            return {
                ...state,
                creditApplication: {
                    ...state.creditApplication,
                    saveProgressState: 'loading',
                    error: undefined,
                    creditApplicationForm: undefined
                }
            };
        }
        case types.ONBOARDING_CREDITAPP_ERROR: {
            return {
                ...state,
                creditApplication: {
                    ...state.creditApplication,
                    submissionState: 'error',
                    saveProgressState: 'error',
                    error: action.error
                }
            };
        }

        // -- BILLING AND SHIPPING -- //
        case types.ONBOARDING_BILLINGANDSHIPPING_DRAFT_LOADED: {
            return {
                ...state,
                billingAndShipping: {
                    ...state.billingAndShipping,
                    complete: false,
                    error: undefined,
                    state: !!action.billingAndShippingForm?.state
                        ? action.billingAndShippingForm.state
                        : OnboardingStepState.UNKNOWN,
                    billingAndShippingForm: action.billingAndShippingForm,
                    saveProgressState: 'idle'
                }
            };
        }
        case types.ONBOARDING_BILLINGANDSHIPPING_DRAFT_SAVED: {
            return {
                ...state,
                billingAndShipping: {
                    ...state.billingAndShipping,
                    complete: false,
                    error: undefined,
                    billingAndShippingForm: action.billingAndShippingForm,
                    saveProgressState: 'success'
                }
            };
        }
        case types.ONBOARDING_BILLINGANDSHIPPING_LOADING: {
            return {
                ...state,
                billingAndShipping: {
                    ...state.billingAndShipping,
                    saveProgressState: 'loading',
                    error: undefined,
                    billingAndShippingForm: undefined
                }
            };
        }
        case types.ONBOARDING_BILLINGANDSHIPPING_LOADED: {
            return {
                ...state,
                billingAndShipping: {
                    ...state.salesAndUseTax,
                    state: action.billingAndShippingForm.state,
                    error: undefined,
                    billingAndShippingForm: action.billingAndShippingForm
                }
            };
        }
        case types.ONBOARDING_BILLINGANDSHIPPING_SUCCESS: {
            return {
                ...state,
                billingAndShipping: {
                    ...state.billingAndShipping,
                    complete: true,
                    error: undefined,
                    billingAndShippingForm: action.billingAndShippingForm
                }
            };
        }
        case types.ONBOARDING_BILLINGANDSHIPPING_ERROR: {
            return {
                ...state,
                billingAndShipping: {
                    ...state.billingAndShipping,
                    submissionState: 'error',
                    saveProgressState: 'error',
                    error: action.error,
                    billingAndShippingForm: undefined
                }
            };
        }
        case types.ONBOARDING_INVITATION_SUCCESS: {
            return {
                ...state,
                inviteSuccess: true
            };
        }
        case types.ONBOARDING_INVITATION_RESET: {
            return {
                ...state,
                inviteSuccess: false
            };
        }
        case types.ONBOARDING_LOAD_CAPACITY_CHECKS_ERROR: {
            return {
                ...state,
                capacityCheckError: action.error
            };
        }
        default: {
            return state;
        }
    }
};

export default onboardingDashboardState;
