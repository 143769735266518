import { ColumnDataType, createColumn } from 'tubular-common';
import { TotalNDayRanges } from '../../../../store/reducers/product-planning';
import { getWeek, removeYearFromDate } from '../../../../utility/helpers/date-helpers';
import TranslationService from '../../../../utility/services/translation-service';

export default class ProductPlanningATMSupplyColumns {
    private static getWeekLabel = (culture: string, weekNum: number) => {
        const date = removeYearFromDate(getWeek(weekNum));
        const thisWeek = TranslationService.getTranslatedText(culture, 'thisWeek');
        const week = TranslationService.getTranslatedText(culture, 'week');
        return weekNum === 0 ? `${thisWeek} ${date}` : `${weekNum}+ ${week} ${date}`;
    };

    static getProductPlanningATMSupplyColumns = (culture: string, selectedRange: number) => {
        return [
            createColumn('snoSkuDescription', {
                dataType: ColumnDataType.String,
                filterable: true,
                isKey: true,
                label: TranslationService.getTranslatedText(culture, 'supplyPlanAttributes'),
                sortable: true,
                searchable: true
            }),
            createColumn('snoSkuAttributes', {
                dataType: ColumnDataType.String,
                filterable: true,
                sortable: true,
                searchable: true,
                visible: false
            }),
            createColumn('currentWeek', {
                dataType: ColumnDataType.Numeric,
                filterable: true,
                label: ProductPlanningATMSupplyColumns.getWeekLabel(culture, 0),
                sortable: selectedRange === TotalNDayRanges.nineWeeks,
                searchable: false,
                visible: selectedRange === TotalNDayRanges.nineWeeks
            }),
            createColumn('weekOne', {
                dataType: ColumnDataType.Numeric,
                filterable: true,
                label: ProductPlanningATMSupplyColumns.getWeekLabel(culture, 1),
                sortable: true,
                searchable: false,
                visible: selectedRange === TotalNDayRanges.nineWeeks
            }),
            createColumn('weekTwo', {
                dataType: ColumnDataType.Numeric,
                filterable: true,
                label: ProductPlanningATMSupplyColumns.getWeekLabel(culture, 2),
                sortable: true,
                searchable: false,
                visible: selectedRange === TotalNDayRanges.nineWeeks
            }),
            createColumn('weekThree', {
                dataType: ColumnDataType.Numeric,
                filterable: true,
                label: ProductPlanningATMSupplyColumns.getWeekLabel(culture, 3),
                sortable: true,
                searchable: false,
                visible: selectedRange === TotalNDayRanges.nineWeeks
            }),
            createColumn('weekFour', {
                dataType: ColumnDataType.Numeric,
                filterable: true,
                label: ProductPlanningATMSupplyColumns.getWeekLabel(culture, 4),
                sortable: true,
                searchable: false,
                visible: selectedRange === TotalNDayRanges.nineWeeks
            }),
            createColumn('weekFive', {
                dataType: ColumnDataType.Numeric,
                filterable: true,
                label: ProductPlanningATMSupplyColumns.getWeekLabel(culture, 5),
                sortable: true,
                searchable: false,
                visible: selectedRange === TotalNDayRanges.nineWeeks
            }),
            createColumn('weekSix', {
                dataType: ColumnDataType.Numeric,
                filterable: true,
                label: ProductPlanningATMSupplyColumns.getWeekLabel(culture, 6),
                sortable: true,
                searchable: false,
                visible: selectedRange === TotalNDayRanges.nineWeeks
            }),
            createColumn('weekSeven', {
                dataType: ColumnDataType.Numeric,
                filterable: true,
                label: ProductPlanningATMSupplyColumns.getWeekLabel(culture, 7),
                sortable: true,
                searchable: false,
                visible: selectedRange === TotalNDayRanges.nineWeeks
            }),
            createColumn('weekEight', {
                dataType: ColumnDataType.Numeric,
                filterable: true,
                label: ProductPlanningATMSupplyColumns.getWeekLabel(culture, 8),
                sortable: true,
                searchable: false,
                visible: selectedRange === TotalNDayRanges.nineWeeks
            }),
            createColumn('weekNine', {
                dataType: ColumnDataType.Numeric,
                filterable: true,
                label: ProductPlanningATMSupplyColumns.getWeekLabel(culture, 9),
                sortable: true,
                searchable: false,
                visible: selectedRange === TotalNDayRanges.seventeenWeeks
            }),
            createColumn('weekTen', {
                dataType: ColumnDataType.Numeric,
                filterable: true,
                label: ProductPlanningATMSupplyColumns.getWeekLabel(culture, 10),
                sortable: true,
                searchable: false,
                visible: selectedRange === TotalNDayRanges.seventeenWeeks
            }),
            createColumn('weekEleven', {
                dataType: ColumnDataType.Numeric,
                filterable: true,
                label: ProductPlanningATMSupplyColumns.getWeekLabel(culture, 11),
                sortable: true,
                searchable: false,
                visible: selectedRange === TotalNDayRanges.seventeenWeeks
            }),
            createColumn('weekTweleve', {
                dataType: ColumnDataType.Numeric,
                filterable: true,
                label: ProductPlanningATMSupplyColumns.getWeekLabel(culture, 12),
                sortable: true,
                searchable: false,
                visible: selectedRange === TotalNDayRanges.seventeenWeeks
            }),
            createColumn('weekThirteen', {
                dataType: ColumnDataType.Numeric,
                filterable: true,
                label: ProductPlanningATMSupplyColumns.getWeekLabel(culture, 13),
                sortable: true,
                searchable: false,
                visible: selectedRange === TotalNDayRanges.seventeenWeeks
            }),
            createColumn('weekFourteen', {
                dataType: ColumnDataType.Numeric,
                filterable: true,
                label: ProductPlanningATMSupplyColumns.getWeekLabel(culture, 14),
                sortable: true,
                searchable: false,
                visible: selectedRange === TotalNDayRanges.seventeenWeeks
            }),
            createColumn('weekFifteen', {
                dataType: ColumnDataType.Numeric,
                filterable: true,
                label: ProductPlanningATMSupplyColumns.getWeekLabel(culture, 15),
                sortable: true,
                searchable: false,
                visible: selectedRange === TotalNDayRanges.seventeenWeeks
            }),
            createColumn('weekSixteen', {
                dataType: ColumnDataType.Numeric,
                filterable: true,
                label: ProductPlanningATMSupplyColumns.getWeekLabel(culture, 16),
                sortable: true,
                searchable: false,
                visible: selectedRange === TotalNDayRanges.seventeenWeeks
            }),
            createColumn('isActive', {
                dataType: ColumnDataType.Boolean,
                visible: false
            }),
            createColumn('weekAllocationQuantity', {
                dataType: ColumnDataType.Numeric,
                searchable: false,
                filterable: false,
                sortable: false,
                visible: false
            }),
            createColumn('products', {
                dataType: ColumnDataType.String,
                searchable: false,
                filterable: false,
                sortable: false,
                visible: false
            }),
            createColumn('snoSku', {
                dataType: ColumnDataType.Numeric,
                searchable: false,
                filterable: false,
                sortable: false,
                visible: false
            })
        ];
    };
}
