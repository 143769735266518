import moment from 'moment';
import { ProductionOrderForTable } from '../../components/pages/MakeItATMSummary/models/ProductionOrderForTable';
import {
    Activity,
    filterShipToIdsByPermission,
    SecurityLevel
} from '../../utility/auth/useSecurity';
import OrdersService from '../../utility/services/orders-service';
import { AuthState } from '../reducers/auth';
import { OrderProductTableRow } from '../reducers/orders-dashboard';
import {
    PLAN_IT_SUMMARY_LOADING,
    PLAN_IT_SUMMARY_LOADED,
    PLAN_IT_SUMMARY_ERROR,
    PLAN_IT_SUMMARY_UPDATE_COPY_TO_MAKE_IT_ORDERS,
    PLAN_IT_GUIDED_SET_PRODUCTS_EXISTING
} from './action-types';

export const loadForecastOrders = (accounts: number[], guided: boolean = false) => {
    return (dispatch, getState) => {
        const auth: AuthState = getState().auth;

        dispatch({ type: PLAN_IT_SUMMARY_LOADING });
        OrdersService.getForecastOrders(auth.accessToken, {
            shipToIds: filterShipToIdsByPermission(
                auth,
                accounts,
                Activity.PlanItSummary,
                SecurityLevel.View
            )
        })
            .then((response) => {
                if (guided) {
                    const state = getState().planItGuided;
                    const makeItState = getState().makeItDashboard;
                    let guidedOrders = !!state.guidedOrders ? state.guidedOrders : [];
                    let products = makeItState.products;
                    if (!!guidedOrders && guidedOrders.length > 0) {
                        guidedOrders.forEach((order) => {
                            let productsToOrder = order.productsToOrder;
                            let filteredForecasts = response.data.orders.filter(
                                (forecastOrder) =>
                                    moment.utc(forecastOrder.atmWeekStart).format('MM/DD/YYYY') ===
                                    moment(order.week!).format('MM/DD/YYYY')
                            );
                            if (filteredForecasts.length > 0) {
                                order.productionOrderId = filteredForecasts[0].productionOrderId;
                                order.customerProductionOrderId =
                                    filteredForecasts[0].customerProductionOrderId;
                                let lines: any[] = [];
                                filteredForecasts.forEach((order) => {
                                    order.lines.forEach((line) => {
                                        lines.push(line);
                                    });
                                });
                                let updatedProductsToOrder: OrderProductTableRow[] = [];
                                lines.forEach((line) => {
                                    const product = {
                                        ...products.find(
                                            (product) => product.productSku === line.productSku
                                        )
                                    };
                                    if (!!product) {
                                        product.requestedDate = productsToOrder[0].requestedDate!;
                                        product.originalPalletQuantity = line.palletQuantity;
                                        product.palletQuantity = line.palletQuantity;
                                        product.quantity = line.palletQuantity;
                                        product.includedInExistingForecast = true;
                                        product.originalEachesQuantity =
                                            line.palletQuantity * product.quantityPerPallet;
                                        product.productionOrderLineId = line.productionOrderLineId;
                                        const foundExisting = updatedProductsToOrder.find(
                                            (p) => p.productSku === product.productSku
                                        );
                                        if (!foundExisting) {
                                            updatedProductsToOrder.push(product);
                                        }
                                    }
                                });
                                order.productsToOrder.forEach((product) => {
                                    const updatedProduct = {
                                        ...product
                                    };
                                    delete updatedProduct.productionOrderLineId;
                                    delete updatedProduct.originalPalletquantity;
                                    delete updatedProduct.originalEachesQuantity;
                                    const foundMatch = updatedProductsToOrder.find(
                                        (p) => p.productSku === updatedProduct.productSku
                                    );
                                    if (!!foundMatch) {
                                        const index = updatedProductsToOrder.indexOf(foundMatch);
                                        updatedProductsToOrder[index].includedInExistingForecast =
                                            false;
                                        updatedProductsToOrder[index].palletQuantity = undefined;
                                        updatedProductsToOrder[index].quantity = undefined;
                                    } else {
                                        updatedProductsToOrder.push(updatedProduct);
                                    }
                                });
                                updatedProductsToOrder.forEach((product) => {
                                    if (!product.originalPalletQuantity) {
                                        product.originalPalletQuantity = 0;
                                        product.originalEachesQuantity = 0;
                                    }
                                });
                                order.productsToOrder = updatedProductsToOrder;
                            }
                        });
                        dispatch({
                            type: PLAN_IT_GUIDED_SET_PRODUCTS_EXISTING,
                            guidedOrders: [...guidedOrders]
                        });
                    }
                }
                dispatch({ type: PLAN_IT_SUMMARY_LOADED, orders: response.data.orders });
            })
            .catch((error) => {
                dispatch({ type: PLAN_IT_SUMMARY_ERROR, error });
            });
    };
};

export const updateCopyToMakeItOrders = (order: ProductionOrderForTable) => {
    return (dispatch, getState) => {
        const state = getState().planItSummary;
        let copyOrders = state.copyToMakeItOrders;
        if (
            !state.copyToMakeItOrders.find((o) => o.productionOrderId === order.productionOrderId)
        ) {
            copyOrders.push({
                ...order,
                copyToMakeItChecked: true
            } as ProductionOrderForTable);
        } else {
            copyOrders = copyOrders.filter((o) => o.productionOrderId !== order.productionOrderId);
        }
        copyOrders = copyOrders.filter((order) => order.copyToMakeItChecked === true);
        dispatch({
            type: PLAN_IT_SUMMARY_UPDATE_COPY_TO_MAKE_IT_ORDERS,
            copyToMakeItOrders: copyOrders
        });
    };
};
