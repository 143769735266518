import React, { useState } from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import Link from '../../../reusable/atoms/Link';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import { ballBlue, small, regularWeight } from '../../../../themes/globalConstants';
import Modal from '../../../reusable/molecules/Modal';
import ReactPdf from '@react-pdf/renderer';
import { CSVLink } from 'react-csv';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import {
    getGraphicIdAndVersion,
    isProductCanType
} from '../../../../utility/helpers/order-helpers';
import moment from 'moment';
import {
    formatProductAttributes,
    getBasecoat,
    getTabIndicator
} from '../../../../utility/helpers/make-it-bulk-helpers';
import {
    MakeItBulkATMOrderByWeekAndSku,
    MakeItBulkATMLineItem
} from '../../../../store/reducers/makeit-bulk-atm';
import MakeItConversionConfirmationPDF from './MakeItConversionConfirmationPDF';
import { filterOutDeletedOrders } from '../../MakeItBulkATMConfirmation/utils/mib-atm-confirmation-utils';

interface Props {
    atmOrdersBySnoSku: MakeItBulkATMOrderByWeekAndSku[];
}

const useStyles = makeStyles((theme) => ({
    label: {
        textTransform: 'uppercase',
        fontSize: small,
        paddingTop: '0.5em'
    },
    link: {
        fontSize: small,
        fontWeight: regularWeight,
        width: '8.75em',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    icon: {
        color: ballBlue
    }
}));

const pdfStyles = ReactPdf.StyleSheet.create({
    link: {
        fontSize: small,
        fontWeight: regularWeight,
        width: '8.75em'
    }
});

const MakeItConversionConfirmationShareLinks = ({ atmOrdersBySnoSku }: Props) => {
    const classes = useStyles();
    const [openPdf, setOpenPdf] = useState(false);
    const { t } = useTranslation();
    const createDate = moment().format('MM-DD-YYYY');

    const printForm = () => {
        setOpenPdf(true);
    };

    const onClose = () => {
        setOpenPdf(false);
    };

    interface CSVRow {
        ballProdOrderNumber?: any;
        ballProdOrderLineNumber?: any;
        orderWeek?: string;
        shipToId: string;
        characteristics: string;
        supplyPlan: string;
        productId?: string;
        graphicId?: string;
        coating?: string;
        quantityPallets?: string;
        quantityEaches?: string;
    }

    const csvHeaders = [
        { label: 'BALL PRODUCTION ORDER NUMBER', key: 'ballProdOrderNumber' },
        { label: 'BALL PRODUCTION ORDER LINE NUMBER', key: 'ballProdOrderLineNumber' },
        { label: 'ORDER WEEK', key: 'orderWeek' },
        { label: 'SHIP TO ID', key: 'shipToId' },
        { label: 'CHARACTERISTICS', key: 'characteristics' },
        { label: 'SUPPLY PLAN', key: 'supplyPlan' },
        { label: 'PRODUCT ID', key: 'productId' },
        { label: 'GRAPHIC ID', key: 'graphicId' },
        { label: 'COATING', key: 'coating' },
        { label: 'QUANTITY TO PURCHASE (PL)', key: 'quantityPallets' },
        { label: 'QUANTITY TO PURCHASE (EA)', key: 'quantityEaches' }
    ];

    const getCharacteristics = (product: MakeItBulkATMLineItem) => {
        return isProductCanType(product.type)
            ? `${formatProductAttributes([
                product.unit,
                product.shape,
                product.neckDiameter
            ])} ${formatProductAttributes([
                getBasecoat(product.basecoat),
                product.specialtyInk,
                product.retort
            ])}`
            : `${formatProductAttributes([
                product.size,
                product.endProfile
            ])} ${formatProductAttributes([
                product.shellGauge,
                getTabIndicator(product.tabIndicator),
                product.retort
            ])}`;
    };

    const translateCSVHeaders = () => {
        csvHeaders.forEach((header) => {
            header.label = t(header.key, header.label).toUpperCase();
        });
        return csvHeaders;
    };

    const csvArray = () => {
        let csvRows: CSVRow[] = [];
        atmOrdersBySnoSku.forEach((order) => {
            order.linesBySnoSku.forEach((lineBySnoSku) => {
                lineBySnoSku.lines.forEach((line) => {
                    const row: CSVRow = {
                        ballProdOrderNumber: order.productionOrderNumber ?? t('pending', 'Pending'),
                        ballProdOrderLineNumber:
                            line.productionOrderLineNumber ?? t('pending', 'Pending'),
                        orderWeek: moment(order.weekStart).format('MM/DD/YYYY'),
                        shipToId: order.shipToId.toString(),
                        characteristics: getCharacteristics(line),
                        supplyPlan: lineBySnoSku.allocationAmount
                            ? `${lineBySnoSku.totalPalletQuantity} Quantity of ${lineBySnoSku.allocationAmount} Supply Plan`
                            : '',
                        productId: line.displayId,
                        graphicId: getGraphicIdAndVersion(line.type, line.graphicIdAndVersion),
                        coating: line.coating,
                        quantityPallets:
                            !line.deleted && line.palletQuantity
                                ? line.palletQuantity.toString() + ' PL'
                                : '',
                        quantityEaches:
                            !line.deleted && line.palletQuantity && line.quantityPerPallet
                                ? (line.palletQuantity * line.quantityPerPallet).toString() + ' EA'
                                : ''
                    };
                    csvRows.push(row);
                });
            });
        });
        return csvRows;
    };

    const csvContents = csvArray();

    return (
        <Grid container item xs={12} spacing={1} justify="flex-end">
            <Grid item data-testid="share-btn">
                <Typography variant="h6" className={classes.label}>
                    <Trans i18nKey="share">share</Trans>
                </Typography>
            </Grid>
            <Grid item>
                <Link
                    className={classes.link}
                    underline="always"
                    data-testid="print-btn"
                    onClick={printForm}
                >
                    <Trans i18nKey="print">Print</Trans>
                </Link>
                <PrintOutlinedIcon fontSize="small" className={classes.icon} />
            </Grid>
            <Grid item>
                <CSVLink
                    className={
                        'MuiTypography-root MuiLink-root MuiLink-underlineAlways MuiTypography-colorPrimary'
                    }
                    style={pdfStyles.link}
                    underline="always"
                    data-testid="download-btn"
                    headers={translateCSVHeaders()}
                    data={csvContents}
                    filename={`PO_${createDate}.csv`}
                >
                    <Trans i18nKey="downloadCSV">Download .CSV</Trans>
                </CSVLink>
                <GetAppOutlinedIcon fontSize="small" className={classes.icon} />
            </Grid>
            <Modal
                open={openPdf}
                title={t('makeIt', 'Make It')}
                close={onClose}
                closeIcon={true}
                fullScreen={true}
            >
                <MakeItConversionConfirmationPDF
                    title={t('makeIt', 'Make It')}
                    orders={filterOutDeletedOrders(atmOrdersBySnoSku)}
                />
            </Modal>
        </Grid>
    );
};

export default MakeItConversionConfirmationShareLinks;
