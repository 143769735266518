import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import CanResearchSection from './components/CanResearchSection';
import ProductPortfolioBanner from './components/GLPProductBanner';
import { GraphicalPageTemplate } from '../../templates/GraphicalPageTemplate';
import PartneringWithYou from './components/PartneringWithYou';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { GlpState } from '../../../store/reducers/glp';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { loadGlobalLandingPageContent } from '../../../store/actions/glp';
import MissionStatement from './components/MissionStatement';
import { makeStyles, Container, useTheme, useMediaQuery, Hidden } from '@material-ui/core';
import { containerMaxWidth, aliceBlue } from '../../../themes/globalConstants';

const useStyles = makeStyles((theme) => ({
    container: {
        maxWidth: containerMaxWidth,
        padding: '2em'
    },
    background: {
        background: `linear-gradient(to bottom, ${aliceBlue} 60%, ${theme.palette.common.white} 60%)`,
        [theme.breakpoints.down('sm')]: {
            background: `linear-gradient(to bottom, ${aliceBlue} 65%, ${theme.palette.common.white} 60%)`
        },
        [theme.breakpoints.only('xs')]: {
            background: `linear-gradient(to bottom, ${aliceBlue} 70%, ${theme.palette.common.white} 60%)`
        }
    }
}));

const WelcomePage = () => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const glpState = useTypedSelector<GlpState>((state) => state.glp);
    const regionCulture = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    // dispatch when language/culture don't match what is loaded
    useEffect(() => {
        if (
            regionCulture.cultureCode !== glpState.loadedLanguage ||
            regionCulture.regionCode !== glpState.loadedRegion
        ) {
            dispatch(
                loadGlobalLandingPageContent(regionCulture.regionCode, regionCulture.cultureCode)
            );
        }
    }, [
        dispatch,
        glpState.loadedLanguage,
        glpState.loadedRegion,
        regionCulture.cultureCode,
        regionCulture.regionCode
    ]);

    //Set grid container spacing for different screens
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <GraphicalPageTemplate banner={glpState.banner} loading={glpState.loading}>
            <div className={classes.background}>
                <Container className={classes.container}>
                    <Grid container item xs={12} spacing={matches ? 0 : 7}>
                        <Grid container item xs={12}>
                            {glpState.canContent ? (
                                <CanResearchSection {...glpState.canContent} />
                            ) : null}
                        </Grid>
                        <Grid container item xs={12}>
                            {glpState.findProducts ? (
                                <ProductPortfolioBanner {...glpState.findProducts} />
                            ) : null}
                        </Grid>
                        <Grid item md={1} />
                        <Hidden smDown>
                            <Grid item md={4}>
                                {glpState.partnering ? (
                                    <PartneringWithYou {...glpState.partnering} />
                                ) : null}
                            </Grid>
                        </Hidden>
                        <Grid item md={6} xs={12}>
                            {glpState.mission ? <MissionStatement {...glpState.mission} /> : null}
                        </Grid>
                        <Hidden mdUp>
                            <Grid item xs={12}>
                                {glpState.partnering ? (
                                    <PartneringWithYou {...glpState.partnering} />
                                ) : null}
                            </Grid>
                        </Hidden>
                    </Grid>
                </Container>
            </div>
        </GraphicalPageTemplate>
    );
};

export default WelcomePage;
