import React from 'react';
import { Grid, GridJustification, makeStyles, Typography } from '@material-ui/core';
import { InfoSubheaderItem } from '../../../../store/reducers/glp';
import { ballGray, regularWeight, small } from '../../../../themes/globalConstants';

interface Props {
    subheaderItems: InfoSubheaderItem[];
    justify: GridJustification;
}

const useStyles = makeStyles((theme) => ({
    subtitle: {
        color: ballGray
    },
    item: {
        paddingRight: '1.6em',
        '& .helper-text': {
            fontWeight: `${regularWeight} !important`,
            fontSize: small
        }
    },
    capitalize: {
        textTransform: 'capitalize'
    }
}));

const PlanItBulkConfirmationSubheader = ({ subheaderItems, justify }: Props) => {
    const classes = useStyles();

    return (
        <Grid container>
            <Grid container item xs={12} justify={justify}>
                {subheaderItems.map((item, index) => (
                    <Grid item key={index} className={classes.item}>
                        <Typography variant="subtitle2" className={classes.subtitle}>
                            {item.subtitle}
                        </Typography>
                        <Typography variant="body1">{item.body}</Typography>
                        {item.subtext && (
                            <Typography className="helper-text">{item.subtext}</Typography>
                        )}
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
};

export default PlanItBulkConfirmationSubheader;
