import { ballBlue, ballDrkBlue, ballGray } from '../../../../themes/globalConstants';

export const styles = (theme) => ({
    modal: {
        width: 515,
        backgroundColor: 'white'
    },
    closeIcon: {
        padding: '0.5rem',
        marginLeft: 'auto',
        cursor: 'pointer'
    },
    sourceLogo: {
        margin: '0 auto',
        paddingLeft: '5rem',
        paddingRight: '5rem'
    },
    form: {
        padding: '2rem'
    },
    success: {
        padding: '2rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center'
    },
    formElement: {
        marginTop: '1.75rem'
    },
    header1: {
        fontWeight: 'bold'
    },
    label: {
        color: 'black',
        fontSize: '1em',
        marginBottom: '7px !important'
    },
    visitPurpose: {
        marginBottom: '2rem'
    },
    sectionHeader: {
        marginTop: '1em'
    },
    input: {
        color: '#1e1e1e',
        fontSize: '.9em',
        padding: '10px',
        marginBottom: '5px',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: '4px',
        width: '-webkit-fill-available',
        '& option': {
            padding: 10
        },
        '&:focus-visible': {
            outline: 'none',
            borderColor: 'black'
        }
    },
    error: {
        color: '#F44336',
        '&.message': {
            fontSize: '1em',
            marginLeft: '.5em'
        }
    },
    errorMessage: {
        color: '#F44336',
        textAlign: 'center',
        marginTop: '1.5em',
        marginBottom: '-1em',
        display: 'flex',
        justifyContent: 'center'
    },
    scoreContainer: {
        transform: 'scale(.65)',
        transformOrigin: '0 0',
        marginTop: '.5rem',
        marginBottom: '-1rem'
    },
    contactInputSection: {
        marginTop: '1rem'
    },
    textArea: {
        width: '100% !important',
        minHeight: '60px',
        boxSizing: 'border-box',
        padding: '18px 14px',
        fontSize: 14,
        border: '1px solid',
        borderRadius: '4px',
        color: '#1e1e1e',
        borderColor: '#999',
        fontFamily: "'Lato', 'Helvetica', 'Arial', sans-serif",
        '&:focus': {
            outline: 'none',
            border: '1px solid',
            borderColor: 'black'
        }
    },
    charactersLeft: {
        float: 'right'
    },
    noSelect: {
        userSelect: 'none'
    },
    privacyPolicy: {
        fontSize: '.8em',
        '& a': {
            color: `${ballBlue} !important`
        }
    },
    buttonContainer: {
        marginTop: '3rem',
        display: 'flex',
        justifyContent: 'right'
    },
    button: {
        borderRadius: '4px',
        textTransform: 'capitalize',
        fontWeight: 'normal',
        fontSize: '.95em',
        paddingTop: 5,
        paddingBottom: 5,
        transition: 'all .5s ease',
        '&.MuiButton-text': {
            padding: '5px 15px 4px 15px'
        },
        '&.close': {
            border: '1px black solid',
            marginRight: 10,
            '&:hover': {
                backgroundColor: 'black',
                color: 'white'
            }
        },
        '&.submit': {
            color: 'white',
            backgroundColor: ballBlue,
            '&:hover': {
                backgroundColor: ballDrkBlue
            },
            '&:disabled': {
                backgroundColor: ballGray
            },
            '&.success': {
                margin: '0 auto',
                marginTop: '3rem',
                fontSize: '1.2rem'
            }
        }
    }
});
