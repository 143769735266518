import React, { useState } from 'react';
import { DragDropContext, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { DraggableStyleProps } from './Draggable';
import { DraggableList } from './DraggableList';
import { DragAndDropItem } from './models';

interface Props {
    items: Array<DragAndDropItem>;
    styleConfig?: DraggableStyleProps;
    onDropEnd: (arg: DropResult, provided: ResponderProvided) => void;
    disabled?: boolean;
}

/**
 * Library details: https://github.com/atlassian/react-beautiful-dnd
 * Implementation details for customer placeholder: https://medium.com/@larryliu_50901/how-to-finally-add-a-custom-placeholder-react-beautiful-dnd-1b1359a94e30
 * @param param0 
 * @returns 
 */
export default function DragAndDrop({
    items,
    onDropEnd,
    styleConfig = { draggablePaddingBottom: '1em' },
    disabled
}: Props) {
    const [draggableStyleConfig] = useState(styleConfig);
    const [placeholderProps, setPlaceholderProps] = useState<any>({});

    const queryAttr = 'data-rbd-draggable-id';

    const handleOnDropEnd = (arg, provided) => {
        setPlaceholderProps({});
        onDropEnd(arg, provided);
    };

    /**
     * Sets the placeholder styling for the draggable's source area (where it currently sits in the list) when the drag action begins.
     * @param event 
     * @returns 
     */
    const handleDragStart = (event) => {
        const draggedDOM = getDraggedDom(event.draggableId) as any;

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const sourceIndex = event.source.index;
        const clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
            [...draggedDOM.parentNode.children].slice(0, sourceIndex).reduce((total, curr) => {
                const style = curr.currentStyle || window.getComputedStyle(curr);
                const marginBottom = parseFloat(style.marginBottom);
                return total + curr.clientHeight + marginBottom;
            }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft)
        });
    };

    const getDraggedDom = (draggableId) => {
        const domQuery = `[${queryAttr}='${draggableId}']`;
        const draggedDOM = document.querySelector(domQuery);

        return draggedDOM;
    };

    /**
     * Sets the placeholder styling on the area the draggable is on top of when the destination is not the same as the source.
     * @param event 
     * @returns 
     */
    const onDragUpdate = (event) => {
        if (!event.destination) {
            return;
        }

        const draggedDOM = getDraggedDom(event.draggableId) as any;

        if (!draggedDOM) {
            return;
        }

        const { clientHeight, clientWidth } = draggedDOM;
        const destinationIndex = event.destination.index;
        const sourceIndex = event.source.index;

        const childrenArray = [...draggedDOM.parentNode.children];
        const movedItem = childrenArray[sourceIndex];
        childrenArray.splice(sourceIndex, 1);

        const updatedArray = [
            ...childrenArray.slice(0, destinationIndex),
            movedItem,
            ...childrenArray.slice(destinationIndex + 1)
        ];

        const clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
            updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
                const style = curr.currentStyle || window.getComputedStyle(curr);
                const marginBottom = parseFloat(style.marginBottom);
                return total + curr.clientHeight + marginBottom;
            }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
            clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft)
        });
    };

    return (
        <DragDropContext
            onDragEnd={handleOnDropEnd}
            onDragUpdate={onDragUpdate}
            onDragStart={handleDragStart}
        >
            <Droppable droppableId="customer-dashboard-list">
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{ position: 'relative' }}
                    >
                        <DraggableList
                            disabled={disabled || items.length < 2}
                            items={items}
                            styleConfig={draggableStyleConfig}
                        />
                        {provided.placeholder}
                        <div
                            id="custom-placeholder"
                            style={{
                                position: 'absolute',
                                top: placeholderProps.clientY,
                                left: placeholderProps.clientX,
                                height: placeholderProps.clientHeight,
                                width: placeholderProps.clientWidth,
                                backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='7' ry='7' stroke='%23009BBC8C' stroke-width='10' stroke-dasharray='6%2c 30' stroke-dashoffset='23' stroke-linecap='square'/%3e%3c/svg%3e")`,
                                borderRadius: '7px',
                                opacity: snapshot.isDraggingOver ? '1' : '0',
                                transition: 'opacity ease 600ms'
                            }}
                        />
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}
