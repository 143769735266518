import React from 'react';
import Link from '../atoms/Link';
import { useTranslation } from 'react-i18next';

interface Props {
    className?: string;
}

const MyAccountLink = ({ className }: Props) => {
    const { t } = useTranslation();

    return (
        <Link
            id="my-account-dashboard"
            underline="none"
            component="button"
            aria-label="profile-button"
            className={className}
        >
            {t('myAccount', 'My Account')}
        </Link>
    );
};

export default MyAccountLink;
