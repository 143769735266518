import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

interface Props {
    stepComponent: React.ReactElement;
    children: React.ReactNode;
    disabled?: boolean;
}

const useStyles = makeStyles((theme) => ({
    stepSection: {
        padding: '1em 2em 2em',
        transition: 'opacity 250ms ease'
    },
    stepLabel: {
        marginRight: '3em',
        flexGrow: 0
    },
    visitedIcon: {
        fill: theme.palette.info.main
    },
    sectionDisabled: {
        opacity: '0.25'
    }
}));

export default function OnboardingWizardStepSection({ stepComponent, children, disabled }: Props) {
    const classes = useStyles();
    const childrenWithProps = React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { disabled: disabled });
        }
        return child;
    });

    return (
        <>
            <Grid
                container
                className={clsx(classes.stepSection, {
                    [classes.sectionDisabled]: disabled
                })}
            >
                <Grid item xs className={classes.stepLabel}>
                    {stepComponent}
                </Grid>
                <Grid item xs>
                    {childrenWithProps}
                </Grid>
            </Grid>
        </>
    );
}
