import Axios from 'axios';

export enum ContactPreference {
    'Phone' = 'Phone',
    'Email' = 'Email',
    'No_Contact' = 'No_Contact'
}

export interface SurveyResult {
    score: number;
    visitPurpose: string;
    suggestions: string;
    name: string;
    contactPreference: ContactPreference;
    phone: string;
    contactEmail: string;
    oktaEmail: string;
}

export default class CustomerFeedbackService {
    private static createConfig = (accessToken: string) => ({
        headers: { Authorization: `Bearer ${accessToken}` }
    });

    public static submitFeedbackSurvey = (accessToken: string, surveyResult: SurveyResult) => {
        const url = `${process.env.REACT_APP_MESSAGE_API}/feedback/survey`;

        return Axios.post(url, surveyResult, CustomerFeedbackService.createConfig(accessToken));
    };
}
