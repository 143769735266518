import React, { useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { boldWeight, blackWeight } from '../../../../themes/globalConstants';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { ScrapItLine } from '../../../../store/reducers/scrap-it-details';
import StandardTable from '../../../reusable/molecules/StandardTable';
import ScrapItRequestDetailsRow from './ScrapItRequestDetailsRow';
import ScrapItRequestDetailsColumns from './ScrapItRequestDetailsColumns';

interface Props {
    scrapLines: ScrapItLine[];
}

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        marginTop: '2em',
        '& .MuiToolbar-root': {
            padding: 0,
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                },
                '&:first-of-type ': {
                    paddingLeft: 0
                }
            },
            '& > h2': {
                fontSize: '1.5em',
                fontWeight: boldWeight,
                color: theme.palette.secondary.main,
                paddingLeft: '0.625em',
                fontFamily: 'Lato',
                minWidth: '12em'
            }
        },
        '& .MuiTableSortLabel-root': {
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em',
            width: '99%',
            justifyContent: 'space-between',
            '& .MuiSvgIcon-root': {
                margin: 0
            }
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                '&:nth-child(1)': {
                    width: '18%'
                },
                '&:nth-child(2)': {
                    width: '35%'
                },
                '&:nth-child(3)': {
                    width: '17%'
                },
                '&:nth-child(4)': {
                    width: '15%'
                },
                '&:nth-child(5)': {
                    width: '15%'
                }
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    }
}));

const ScrapItRequestDetailsGrid = ({ scrapLines }: Props) => {
    const classes = useStyles();
    const [columns, setColumns] = useState<any>([]);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    useEffect(() => {
        setColumns(ScrapItRequestDetailsColumns.getScrapItRequestDetailsColumns(cultureCode));
    }, [cultureCode]);

    const CustomOrderRow = ({ row }: { row: ScrapItLine }) => {
        return <ScrapItRequestDetailsRow line={row} />;
    };

    return (
        <Grid className={classes.tableWrapper} data-testid="scrap-lines-grid">
            {scrapLines && (
                <StandardTable
                    gridName="Scrap It Request Details Grid"
                    columns={columns}
                    lines={scrapLines}
                    rowComponent={CustomOrderRow}
                    columnWidths={[22, 20, 20, 20, 18]}
                    itemsPerPage={10}
                />
            )}
        </Grid>
    );
};

export default ScrapItRequestDetailsGrid;
