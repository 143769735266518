import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { makeStyles, Typography, TableRow, TableCell, Grid, Link } from '@material-ui/core';
import Button from '../../../reusable/atoms/Button';
import {
    ballLtGray,
    regularTimeout,
    regularWeight,
    blackWeight,
    errorBackground,
    shipRed,
    boldWeight
} from '../../../../themes/globalConstants';
import { numericRegex } from '../../../../utility/helpers/formatters';
import { ScrapItState } from '../../../../store/reducers/scrap-it';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import {
    getEachesConversion,
    resolvePalletCount
} from '../../../../utility/helpers/shipment-helpers';
import QuantityInputCell from '../../../reusable/atoms/QuantityInputCell';
import StatusLabel from './StatusLabel';
import clsx from 'clsx';
import { ProductWithPallets } from '../../../../store/reducers/shipping-dashboard';
import { AgedStatus } from '../../../../utility/services/orders-service';

interface Props {
    item: ProductWithPallets;
    onAddItem: (item: ProductWithPallets, numberOfPallets: number, type: string) => void;
    onPalletAdd: (item: ProductWithPallets, numberOfPallets: number) => void;
    onInputBlur: (product: ProductWithPallets, numberOfInputPallets: number) => void;
    onDeleteItem: (item: ProductWithPallets) => void;
    onLookup: (item: ProductWithPallets) => void;
    isReviewGrid?: boolean;
}

const useStyles = makeStyles((theme) => ({
    tableRowContainer: {
        '& .MuiInputBase-input': {
            textAlign: 'right',
            paddingRight: '.25em'
        },
        '& .MuiTableCell-root': {
            '&:not(:last-child)': {
                paddingLeft: '1.125em'
            }
        },
        '& .normal': {
            fontWeight: `${regularWeight} !important`,
            textAlign: 'right'
        }
    },
    displayName: {
        fontWeight: blackWeight,
        textTransform: 'uppercase'
    },
    displayQuantity: {
        fontWeight: boldWeight
    },
    textField: {
        width: '4.25em',
        height: '1.5em',
        border: `2px solid ${ballLtGray}`,
        borderRadius: '.25em',
        marginRight: '0.5em'
    },
    alignCenter: {
        textAlign: 'center'
    },
    alignRight: {
        textAlign: 'right'
    },
    paletteInputContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    addBtn: {
        padding: 0,
        height: '2.28em',
        width: '3.2em'
    },
    paddingLeft: {
        paddingLeft: '.5em'
    },
    eachesInput: {
        flexDirection: 'column-reverse'
    },
    quantityError: {
        border: `1px solid ${shipRed}`,
        borderRight: 'none',
        backgroundColor: errorBackground
    },
    trash: {
        minWidth: 'unset'
    },
    statusPadding: {
        textAlign: 'center'
    },
    icon: {
        width: '1em',
        marginRight: '0.5em'
    }
}));

export default function ScrapItRow({
    item,
    onAddItem,
    onPalletAdd,
    onInputBlur,
    onDeleteItem,
    onLookup,
    isReviewGrid
}: Props) {
    const classes = useStyles();
    const { quantityUnit, agedProducts } = useTypedSelector<ScrapItState>(
        (state) => state.scrapItState
    );
    const [agedStatus, setAgedStatus] = useState<AgedStatus>(AgedStatus.NotAging);
    const [pallets, setPallets] = useState(item.inputPallets ? item.inputPallets : 0);
    const [quantity, setQuantity] = useState(resolveQuantity());
    const [tooManyPallets, setTooManyPallets] = useState(false);

    function resolveQuantity() {
        switch (quantityUnit) {
            case 'pallets':
                return item.inputPallets ? item.inputPallets : 0;
            case 'eaches':
                return item.inputEaches ? item.inputEaches : 0;
        }
    }

    const type = item.type!;
    const addItem = () => {
        onAddItem(item, pallets!, type);
        onPalletAdd(item, pallets!);
    };

    const handleQuantityInputChange = (count: string) => {
        if (numericRegex(count)) {
            const parsedCount = parseInt(count);
            if (!isNaN(parsedCount)) {
                const numOfPallets = resolvePalletCount(
                    parsedCount,
                    item.quantityPerPallet!,
                    quantityUnit
                );
                setQuantity(parsedCount);
                setPallets(numOfPallets);
            } else {
                setQuantity(0);
                setPallets(0);
            }
        } else {
            setQuantity(0);
            setPallets(0);
        }
        forceBlur();
    };

    const handleQuantityInputBlur = (count: string) => {
        let numOfPallets = parseInt(count);
        onInputBlur(item, numOfPallets);
    };

    const displayAgedStatusIcon = () => {
        switch (agedStatus) {
            case AgedStatus.Aging:
                return (
                    <img
                        src={process.env.PUBLIC_URL + '/assets/icon-clock-warning.svg'}
                        className={classes.icon}
                        alt="Aging Balance"
                    />
                );
            case AgedStatus.Aged:
                return (
                    <img
                        src={process.env.PUBLIC_URL + '/assets/icon-clock-danger.svg'}
                        className={classes.icon}
                        alt="Aging Balance"
                    />
                );
            default:
                break;
        }
    };

    //Forces input to blur after certain amt of time since last keyup
    const forceBlur = () => {
        document.querySelectorAll('#quantity-input').forEach((element) => {
            if (element === document.activeElement) {
                const el = element as HTMLElement;
                let timeout;

                element.addEventListener('keyup', (e) => {
                    clearTimeout(timeout);
                    timeout = setTimeout(() => {
                        return el.blur();
                    }, regularTimeout);
                });
            }
        });
    };

    useEffect(() => {
        const matchingProduct = agedProducts.find(
            (product) => product.productSku === item.productSku
        );
        if (matchingProduct) {
            // if any products are aged the overall status is aged.  If the first statement doesn't
            // execute, then we assume there are no aged products and check if any are aging instead
            // to set the overall status to aging.
            if (matchingProduct.agedOrders.some((order) => order.agedStatus === AgedStatus.Aged)) {
                setAgedStatus(AgedStatus.Aged);
            } else if (
                matchingProduct.agedOrders.filter((order) => order.agedStatus === AgedStatus.Aging)
                    .length > 0
            ) {
                setAgedStatus(AgedStatus.Aging);
            }
        }
    }, [agedProducts]);

    useEffect(() => {
        setTooManyPallets(
            (item.availablePallets && pallets > item.availablePallets) ||
                (item.availablePallets === 0 && pallets !== 0)
                ? true
                : false
        );
    }, [item.availablePallets, pallets]);

    useTranslation();

    return (
        <TableRow
            className={classes.tableRowContainer}
            data-testid={`product-${item.productSku}`}
            data-pallet-quantity={`${item.availablePallets}`}
            data-can-type={`${item.type}`}
        >
            <TableCell>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography data-testid="display-name" className={classes.displayName}>
                            {item.displayName}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography data-testid="display-graphic-ids">
                            {'('}
                            {item.productSku}
                            {!!item.graphicIdAndVersion ? '/' + item.graphicIdAndVersion : ''}
                            {')'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid container>
                    <Typography data-testid="customer-product-id">
                        {!!item.customerProductId ? item.customerProductId : '-'}
                    </Typography>
                </Grid>
            </TableCell>
            <TableCell>
                <Typography data-testid="coating">{item.coating}</Typography>
            </TableCell>
            {!isReviewGrid && (
                <TableCell data-testid={'status'} className={classes.statusPadding}>
                    <StatusLabel line={item} />
                </TableCell>
            )}
            <TableCell className={classes.alignRight}>
                {agedProducts.find((res) => res.productSku === item.productSku) ? (
                    <Grid item alignItems="center">
                        {displayAgedStatusIcon()}
                        <Link
                            component="button"
                            underline="always"
                            data-testid="pallets"
                            onClick={() => onLookup(item)}
                        >
                            <Typography color="primary" className={classes.displayQuantity}>
                                {Number(item.availablePallets).toLocaleString()}
                                {' PL'}
                            </Typography>
                        </Link>
                    </Grid>
                ) : (
                    <Typography data-testid="pallets" className={classes.displayQuantity}>
                        {Number(item.availablePallets).toLocaleString()}
                        {' PL'}
                    </Typography>
                )}
                <Typography data-testid="eaches">
                    {Number(
                        item.availablePallets! * item.availableItemsPerPallet!
                    ).toLocaleString()}
                    {' ea.'}
                </Typography>
            </TableCell>
            <TableCell className={classes.alignRight}>
                <Typography data-testid="aged-pallets" className={classes.displayQuantity}>
                    {Number(item.agedPalletQuantity).toLocaleString()}
                    {' PL'}
                </Typography>
                <Typography data-testid="aged-eaches">
                    {getEachesConversion(
                        item.agedPalletQuantity!,
                        item.availableItemsPerPallet!
                    ).toLocaleString()}
                </Typography>
            </TableCell>
            <TableCell
                data-testid="quantity-input"
                className={clsx({ [classes.quantityError]: tooManyPallets })}
            >
                <QuantityInputCell
                    quantity={quantity}
                    quantityPerPallet={item.quantityPerPallet!}
                    quantityUnit={quantityUnit}
                    onBlur={handleQuantityInputBlur}
                    onChange={handleQuantityInputChange}
                    hasError={tooManyPallets}
                    showEmptyCellOnUndefined={true}
                />
                {tooManyPallets && (
                    <Grid container>
                        <Typography
                            className={clsx(classes.displayQuantity, 'helper-text')}
                            data-testid="maximum-quantity-error"
                        >
                            <Trans i18nKey="cannotExceedAvailableBalance">
                                Cannot exceed Available Balance
                            </Trans>
                        </Typography>
                    </Grid>
                )}
            </TableCell>
            {isReviewGrid ? (
                <TableCell align="center" data-testid="delete-icon">
                    <Button
                        type="button"
                        className={classes.trash}
                        onClick={onDeleteItem}
                        data-testid="delete-product-btn"
                    >
                        <img src={process.env.PUBLIC_URL + '/assets/Trash.svg'} alt="Trash Icon" />
                    </Button>
                </TableCell>
            ) : (
                <TableCell>
                    <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        onClick={addItem}
                        disabled={pallets === 0 || tooManyPallets}
                        className={classes.addBtn}
                        data-testid="add-product-btn"
                    >
                        <Trans i18nKey="add">add</Trans>
                    </Button>
                </TableCell>
            )}
        </TableRow>
    );
}
