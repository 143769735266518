import React, { useEffect, useState } from 'react';
import { makeStyles, Grid, Paper } from '@material-ui/core';
import { fullTruckPallets, white } from '../../../../themes/globalConstants';
import {
    DeliveryShipment,
    ShipmentEditStatus
} from '../../../../store/reducers/shipping-dashboard';
import { Trans, useTranslation } from 'react-i18next';
import ShipmentTableSubheader from '../../../reusable/molecules/ShipmentTableSubheader';
import ShipmentGrid from './ShipmentGrid';
import Button from '../../../reusable/atoms/Button';
import { useHistory } from 'react-router-dom';
import {
    findEarliestShippingDate,
    isDateWithin24Hrs
} from '../../../../utility/helpers/date-helpers';
import moment from 'moment';
import ShipmentCancelModal from '../../../reusable/molecules/ShipmentCancelModal';
import WarningAlert from '../../../reusable/atoms/WarningAlert';
import { AuthState } from '../../../../store/reducers/auth';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { Persona } from '../../../../utility/auth/useSecurity';
import { isPersonaAccount } from '../../../../utility/helpers/user-helpers';
import { productTypeHelper } from '../../../../utility/helpers/order-helpers';
import { getPalletsPerTruck } from '../../../../utility/helpers/shipment-helpers';
import {
    selectIsCustomerPickup,
    selectIsLargeCustomer,
    selectIsLargeCustomerAccount
} from '../../../../store/selectors';
import { enUS } from '../../../../utility/translations/locales';

interface Props {
    currentShipment: DeliveryShipment;
    shipmentIndex: number;
    editingLocked: boolean;
    edited?: boolean;
    cancelled?: boolean;
    shipmentStatus?: ShipmentEditStatus;
    onToggleCancelShipment: (currentShipment: DeliveryShipment) => void;
}

const useStyles = makeStyles((theme) => ({
    main: {
        marginBottom: '3em',
        paddingBottom: '1em',
        width: '65em',
        margin: 'auto'
    },
    subheader: {
        padding: '0 1em'
    },
    actionsBar: {
        marginTop: '1.25em',
        padding: '1em'
    },
    cancelBtn: {
        marginRight: '2em',
        color: theme.palette.error.main,
        border: `1px solid ${theme.palette.error.main}`,
        '&:hover': {
            color: white,
            backgroundColor: theme.palette.error.main
        }
    },
    editBtn: {
        width: '11.5em'
    }
}));

const ShipmentSummaryCard = ({
    currentShipment,
    shipmentIndex,
    editingLocked,
    edited,
    cancelled,
    shipmentStatus,
    onToggleCancelShipment
}: Props) => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);
    const [cancelModal, setCancelModal] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [shippingDate, setShippingDate] = useState<moment.Moment | string>();
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [warningText, setWarningText] = useState<React.ReactNode>([]);
    const [shipmentEdited, setShipmentEdited] = useState<boolean>(false);
    const [shipmentCancelled, setShipmentCancelled] = useState<boolean>(false);
    const [shipmentTitle, setShipmentTitle] = useState<object>({});
    const [shippingDateSubtitle, setShippingDateSubtitle] = useState<object>({});
    const [isCopacker, setIsCopacker] = useState<boolean>(false);
    const editLink = `/edit-shipments-configuration/${currentShipment.shipmentId}`;
    const disabledWarningText = t(
        'shipmentsWithin24Hrs',
        'Shipments scheduled to ship in the next 24 hours are not editable'
    );
    const isLargeCustomer = useTypedSelector<boolean>(selectIsLargeCustomer);
    const isLargeCustomerAccount = useTypedSelector<boolean>(selectIsLargeCustomerAccount);
    const isCustomerPickup = useTypedSelector<boolean>(selectIsCustomerPickup);
    const maxPallets = getPalletsPerTruck(isLargeCustomer, isCopacker, isLargeCustomerAccount);
    const under25PalletWarning = t('atmFreightWeightWarning', enUS.atmFreightWeightWarning);
    const [shipmentNumber, setShipmentNumber] = useState<number | undefined>(undefined);

    const handleEdit = () => {
        if (!editingLocked) {
            history.push(editLink);
        }
    };

    const handleCancel = () => {
        onToggleCancelShipment(currentShipment);
        setCancelModal(false);
    };

    const handleUndoCancel = () => {
        onToggleCancelShipment(currentShipment);
    };

    const handleOpenCancelModal = () => {
        setCancelModal(true);
    };

    const handleCloseCancelModal = () => {
        setCancelModal(false);
    };

    const warningAlert = (alertText: string, showFormula: boolean) => {
        return (
            <Grid data-testid="shipment-warning">
                <WarningAlert
                    showWarning={true}
                    warningMessage={alertText}
                    maxPallets={maxPallets}
                    useFormulaDropdown={true}
                />
            </Grid>
        );
    };

    useEffect(() => {
        if (permissions && currentShipment.shipToId) {
            // check if user has co-packer permissions
            const coPackerCheck = isPersonaAccount(
                permissions,
                Persona.CoPacker,
                currentShipment.shipToId.toString()
            );
            setIsCopacker(!!coPackerCheck);
        }
    }, [permissions, currentShipment]);

    useEffect(() => {
        const isCancelledStatus = shipmentStatus === 'Cancelled';
        const isEditedStatus = shipmentStatus === 'Edited';

        setShipmentCancelled(cancelled || isCancelledStatus);
        setShipmentEdited(edited || isEditedStatus);
    }, [cancelled, edited, shipmentStatus]);

    useEffect(() => {
        const deliveryTitle = {
            key: t('deliveryDate', 'Delivery Date'),
            value: currentShipment.updatedDeliveryDate
                ? currentShipment.updatedDeliveryDate
                : currentShipment.deliveryDateTime
        };
        setShipmentTitle(deliveryTitle);
        setShipmentNumber(currentShipment.truckId);
    }, [currentShipment, currentShipment.edited, t]);

    useEffect(() => {
        if (currentShipment) {
            const warnings: React.ReactNodeArray = [];
            if (shippingDate) {
                const isPastCutoff = isDateWithin24Hrs(shippingDate);
                if (isPastCutoff && !edited) {
                    setDisabled(true);
                    setShowWarning(true);
                    warnings.push(warningAlert(disabledWarningText, false));
                }

                if (editingLocked) {
                    setDisabled(true);
                }
            }

            // check if pallets in the shipment is below 25, the shipment is for cans, and the user isn't a co-packer for this ship to
            if (
                currentShipment.shipmentQuantity &&
                currentShipment.shipmentQuantity < fullTruckPallets &&
                !isCustomerPickup &&
                productTypeHelper(currentShipment.shipmentType) === 'CAN' &&
                !isCopacker
            ) {
                warnings.push(warningAlert(under25PalletWarning, true));
                setShowWarning(true);
            }
            setWarningText(warnings);
        }

        if (currentShipment && currentShipment.updatedDeliveryDate) {
            setShippingDateSubtitle({
                key: t('shipping', 'Shipping'),
                value: t('pending', 'Pending')
            });
        } else if (cancelled) {
            setShippingDateSubtitle({
                key: t('deliveryDate', 'Delivery Date'),
                value: currentShipment.deliveryDateTime
            });
        } else {
            if (shippingDate) {
                setShippingDateSubtitle({ key: t('shipping', 'Shipping'), value: shippingDate });
            }
        }
    }, [shippingDate, currentShipment, editingLocked, isCopacker]);

    useEffect(() => {
        if (currentShipment) {
            if (edited && currentShipment.updatedDeliveryDate) {
                const calculatedShippingDate = findEarliestShippingDate(
                    currentShipment.updatedDeliveryDate
                );
                setShippingDate(calculatedShippingDate);
            } else {
                if (currentShipment.shipDateTime) {
                    setShippingDate(currentShipment.shipDateTime);
                } else {
                    const calculatedShippingDate = findEarliestShippingDate(
                        currentShipment.deliveryDateTime
                    );
                    setShippingDate(calculatedShippingDate);
                }
            }
        }
    }, [currentShipment, edited]);

    return (
        <Paper className={classes.main} id={'summary-card-' + currentShipment.shipmentId}>
            <Grid container item className={classes.subheader}>
                <ShipmentTableSubheader
                    currentShipment={currentShipment}
                    title={shipmentTitle}
                    subtitle={shippingDateSubtitle}
                    shipmentNumber={shipmentNumber}
                    shipmentIndex={shipmentIndex}
                    edited={shipmentEdited}
                    cancelled={shipmentCancelled}
                />
            </Grid>
            <Grid container>
                <Grid container item>
                    {currentShipment.loads && currentShipment.loads.length && (
                        <ShipmentGrid
                            items={currentShipment.loads}
                            showWarning={showWarning}
                            warning={warningText}
                            isEditShipment={true}
                            shipmentId={currentShipment.shipmentId?.toString()}
                            maxPallets={maxPallets}
                        />
                    )}
                </Grid>
            </Grid>
            <Grid container justify="flex-end" className={classes.actionsBar}>
                {shipmentCancelled ? (
                    <Grid container item xs={12} justify="flex-end">
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            data-testid="undo-cancel-btn"
                            onClick={handleUndoCancel}
                        >
                            <Trans i18nKey="undo">Undo</Trans>
                        </Button>
                    </Grid>
                ) : (
                    <Grid container item xs={12} justify="flex-end">
                        <Button
                            type="button"
                            variant="outlined"
                            data-testid="cancel-shipment-btn"
                            className={classes.cancelBtn}
                            onClick={handleOpenCancelModal}
                            disabled={disabled}
                        >
                            <Trans i18nKey="cancelShipment">Cancel Shipment</Trans>
                        </Button>
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            data-testid="edit-shipment-btn"
                            className={classes.editBtn}
                            onClick={handleEdit}
                            disabled={disabled}
                        >
                            <Trans i18nKey="editShipment">Edit Shipment</Trans>
                        </Button>
                    </Grid>
                )}
            </Grid>
            <ShipmentCancelModal
                open={cancelModal}
                onCancelShipment={handleCancel}
                onClose={handleCloseCancelModal}
                onClickNo={handleCloseCancelModal}
            />
        </Paper>
    );
};

export default ShipmentSummaryCard;
