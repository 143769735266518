import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography, TableRow, TableCell, Grid } from '@material-ui/core';
import { ProductToShip } from '../../../../store/reducers/shipping-dashboard';
import {
    constructCanDescription,
    constructEndDescription,
    isProductCanType,
    isProductEndType,
    getGraphicIdAndVersion
} from '../../../../utility/helpers/order-helpers';
import { Flags, FlagTypes } from '../../../../utility/helpers/feature-flag';
import EachesConversion from '../../../reusable/atoms/EachesConversion';

interface Props {
    item: ProductToShip;
    sequence: number;
}

const useStyles = makeStyles((theme) => ({
    tableRowContainer: {
        '& .MuiInputBase-input': {
            textAlign: 'right',
            paddingRight: '.25em'
        }
    },
    sequence: {
        marginLeft: '0.750em'
    }
}));

export default function ShipItConfirmationRow({ item, sequence }: Props) {
    const classes = useStyles();
    const [palletQuantity, setPalletQuantity] = useState(0);
    const [releaseNumber, setReleaseNumber] = useState('');
    const [purchaseOrderNumber, setPONumber] = useState<string>('');
    const [deliveryInstructions, setDeliveryInstructions] = useState<string>('');
    const [fillerLine, setFillerLine] = useState('');
    const [reference, setReference] = useState('');
    const canBottleDesc = constructCanDescription(item);
    const endDesc = constructEndDescription(item);
    const isEndType = isProductEndType(item.type);
    const isCanType = isProductCanType(item.type);

    useEffect(() => {
        if (item.palletQuantity !== undefined) {
            setPalletQuantity(item.palletQuantity);
        }
        if (item.releaseNumber !== undefined) {
            setReleaseNumber(item.releaseNumber);
        }
        if (item.fillerLine !== undefined) {
            setFillerLine(item.fillerLine);
        }
        // temporary fix for reference vs referenceNumber issue
        if (item.reference !== undefined || item.referenceNumber !== undefined) {
            const refNum = `${item.reference ?? item.referenceNumber}`;
            setReference(refNum);
        }
        if (item.purchaseOrderNumber !== undefined) {
            setPONumber(item.purchaseOrderNumber);
        }
        if (item.deliveryInstructions !== undefined) {
            setDeliveryInstructions(item.deliveryInstructions);
        }
    }, [item]);

    useTranslation();

    return (
        <TableRow className={classes.tableRowContainer}>
            <TableCell data-testid="sequence">
                <Grid container alignItems="center">
                    <Grid item className={classes.sequence}>
                        <Typography>{item.sequence}</Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Typography>
                    <strong>{item.customerProductId ? item.customerProductId : '-'}</strong>
                </Typography>
                <Typography data-testid="product-sku">
                    <strong>{item.userSuppliedProductId ?? item.displayId}</strong>
                </Typography>
                <Typography data-testid="graphic-id">
                    {getGraphicIdAndVersion(item.type, item.graphicIdAndVersion)}
                </Typography>
            </TableCell>
            <TableCell data-testid="product-description">
                <Typography>{item.displayName}</Typography>
                {isEndType && <Typography>{endDesc}</Typography>}
                {isCanType && <Typography>{canBottleDesc}</Typography>}
            </TableCell>
            <TableCell>
                <Grid container alignContent="center">
                    <Grid item>
                        <Typography data-testid="pallet-quantity">{palletQuantity} PL</Typography>
                        <Typography data-testid="eaches-quantity">
                            {item.palletQuantity && item.quantityPerPallet && (
                                <EachesConversion
                                    palletQuantity={item.palletQuantity}
                                    quantityPerPallet={item.quantityPerPallet}
                                />
                            )}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell data-testid="release-number">
                <Grid item>
                    <Typography>{releaseNumber}</Typography>
                </Grid>
            </TableCell>
            <TableCell data-testid="filler-line">
                <Grid item>
                    <Typography>{fillerLine}</Typography>
                </Grid>
            </TableCell>
            <TableCell data-testid="reference-number">
                <Grid item>
                    <Typography>{reference}</Typography>
                </Grid>
            </TableCell>
            <Flags authorizedFlags={[FlagTypes.Coke]}>
                <TableCell data-testid="po-number">
                    <Grid item>
                        <Typography>{purchaseOrderNumber}</Typography>
                    </Grid>
                </TableCell>
            </Flags>
            <TableCell data-testid="delivery-instructions">
                <Grid item>{deliveryInstructions}</Grid>
            </TableCell>
        </TableRow>
    );
}
