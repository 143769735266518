import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { Trans } from 'react-i18next';
import { ballGray, blackWeight, large } from '../../../../themes/globalConstants';
import { ProductionOrder } from '../../../../store/reducers/makeit-dashboard';
import moment from 'moment';
import { orderStatusCodeFormatter } from '../../../../utility/helpers/order-helpers';
import { formatStatus } from '../../../../utility/helpers/order-helpers';
import { formattedMTDate } from '../../../../utility/helpers/date-helpers';

interface Props {
    order?: ProductionOrder;
}

const useStyles = makeStyles((theme) => ({
    main: {
        marginBottom: '1.5em'
    },
    header: {
        fontSize: large,
        fontWeight: blackWeight,
        color: ballGray,
        textTransform: 'uppercase'
    },
    subtitle: {
        color: ballGray
    },
    item: {
        paddingLeft: '1em'
    },
    createdBy: {
        marginTop: '1em',
        marginBottom: '.5em'
    }
}));

const EditProductionOrderSubheader = ({ order }: Props) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.main}>
            <Grid container item xs={4} justify="flex-start" data-testid="customer-po">
                <Typography className={classes.header}>
                    <Trans i18nKey="customerPO">Customer PO</Trans>
                    {' #'}
                    {order?.customerProductionOrderId}
                </Typography>
            </Grid>
            <Grid container item xs={8} justify="flex-end">
                <Grid item className={classes.item}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="status">Status</Trans>
                    </Typography>
                    <Typography variant="body1" data-testid="status">
                        <Trans i18nKey={orderStatusCodeFormatter(order?.status)}>
                            {formatStatus(order?.status)}
                        </Trans>
                    </Typography>
                </Grid>
                <Grid item className={classes.item}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="dateSubmitted">Date Submitted</Trans>
                    </Typography>
                    <Typography variant="body1" data-testid="date-submitted">
                        {formattedMTDate(moment(order?.submittedDate), 'MM/DD/YYYY')}
                    </Typography>
                </Grid>
                <Grid item className={classes.item}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="canQuantity">Can Quantity</Trans>
                    </Typography>
                    <Typography variant="body1" data-testid="can-quantity">
                        {order?.canQuantityPallets?.toLocaleString() +
                            ' PL / ' +
                            order?.canQuantitySKUs +
                            ' SKU'}
                    </Typography>
                    <Typography variant="body1">
                        {order?.canQuantityEaches?.toLocaleString()} ea.
                    </Typography>
                </Grid>
                <Grid item className={classes.item}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="endQuantity">End Quantity</Trans>
                    </Typography>
                    <Typography variant="body1" data-testid="end-quantity">
                        {order?.endQuantityPallets?.toLocaleString() +
                            ' PL / ' +
                            order?.endQuantitySKUs +
                            ' SKU'}
                    </Typography>
                    <Typography variant="body1">
                        {order?.endQuantityEaches?.toLocaleString()} ea.
                    </Typography>
                </Grid>
                <Grid item className={classes.item}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="ballOrderNumber">Ball Order #</Trans>
                    </Typography>
                    <Typography variant="body1" data-testid="production-order-number">
                        {order?.productionOrderNumber}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.createdBy}>
                <Grid item>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="createdBy">Created By</Trans>
                    </Typography>
                    <Typography variant="body1" data-testid="created-by">
                        {order?.submittedBy}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default EditProductionOrderSubheader;
