import React, { useEffect, useState } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { ballGray } from '../../../../themes/globalConstants';
import { getFormattedDateStringForApplication } from '../../../../utility/helpers/date-helpers';
import { MakeItBulkATMOrder } from '../../../../store/reducers/makeit-bulk-atm';
import { getFormattedEachesFromNumber } from '../../../../utility/helpers/shipment-helpers';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { formatNumberWithLocale } from '../../../../utility/helpers/formatters';
import { isProductCanType } from '../../../../utility/helpers/order-helpers';

interface Props {
    shipToAddress: string;
    shipToName: string;
    shipToId: string;
    order: MakeItBulkATMOrder;
}

const useStyles = makeStyles((theme) => ({
    main: {
        marginBottom: '1.5em'
    },
    subtitle: {
        color: ballGray
    },
    item: {
        paddingLeft: '1em'
    },
    createdBy: {
        marginTop: '1em',
    },
    customerPO: {
        marginTop: '1em',
        marginBottom: '.5em'
    }
}));

const PlanItOrderDetailsSubheader = ({ shipToAddress, shipToId, shipToName, order }: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [orderCanPallets, setOrderCanPallets] = useState<number>(0);
    const [orderCanSkus, setOrderCanSkus] = useState<number>(0);
    const [orderCanEaches, setOrderCanEaches] = useState<number>(0);
    const [orderEndPallets, setOrderEndPallets] = useState<number>(0);
    const [orderEndSkus, setOrderEndSkus] = useState<number>(0);
    const [orderEndEaches, setOrderEndEaches] = useState<number>(0);

    const formatOrderQuantity = (quantity?: number, SKUs?: number) => {
        if (SKUs === 1) {
            return (
                <Typography>
                    {formatNumberWithLocale(cultureCode, quantity || 0)} PL / {SKUs}{' '}
                    <Trans i18nKey="sku">SKU</Trans>
                </Typography>
            );
        } else {
            return (
                <Typography>
                    {formatNumberWithLocale(cultureCode, quantity || 0)} PL / {SKUs}{' '}
                    <Trans i18nKey="skus">SKUs</Trans>
                </Typography>
            );
        }
    };

    const getOrderQuantities = (order: MakeItBulkATMOrder) => {
        let canPL = 0;
        let canSKUs = [] as string[];
        let canEaches = 0;
        let endPL = 0;
        let endSKUs = [] as string[];
        let endEaches = 0;

        order.lines.forEach((forecastedLine) => {
            if (isProductCanType(forecastedLine.type)) {
                canPL += forecastedLine.palletQuantity ? forecastedLine.palletQuantity : 0;
                canEaches +=
                    forecastedLine.palletQuantity && forecastedLine.quantityPerPallet
                        ? forecastedLine.palletQuantity * forecastedLine.quantityPerPallet
                        : 0;
                if (
                    forecastedLine.productSku &&
                    canSKUs.indexOf(forecastedLine.productSku) === -1
                ) {
                    canSKUs.push(forecastedLine.productSku);
                }
            } else {
                endPL += forecastedLine.palletQuantity ? forecastedLine.palletQuantity : 0;
                endEaches +=
                    forecastedLine.palletQuantity && forecastedLine.quantityPerPallet
                        ? forecastedLine.palletQuantity * forecastedLine.quantityPerPallet
                        : 0;
                if (
                    forecastedLine.productSku &&
                    endSKUs.indexOf(forecastedLine.productSku) === -1
                ) {
                    endSKUs.push(forecastedLine.productSku);
                }
            }
        });

        setOrderCanPallets(canPL);
        setOrderCanSkus(canSKUs.length);
        setOrderCanEaches(canEaches);
        setOrderEndPallets(endPL);
        setOrderEndSkus(endSKUs.length);
        setOrderEndEaches(endEaches);
    };

    useEffect(() => {
        getOrderQuantities(order);
    }, [order]);

    return (
        <Grid container className={classes.main}>
            <Grid container item xs={5} justify="flex-start">
                <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="shipTo">Ship To</Trans>
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography className="classes.shipToText" data-testid="ship-to-name">
                        {shipToName}
                    </Typography>
                    <Typography className="classes.shipToText" data-testid="ship-to-address">
                        {shipToAddress}
                    </Typography>
                    <Typography className="classes.shipToText" data-testid="ship-to-id">
                        #{shipToId}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item xs={7} justify="flex-end">
                <Grid item xs={2} className={classes.item}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="dateSubmitted">Date Submitted</Trans>
                    </Typography>
                    <Typography variant="body1" data-testid="date-submitted">
                        {getFormattedDateStringForApplication(order?.createDate)}
                    </Typography>
                </Grid>
                <Grid item xs={2} className={classes.item}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="canQuantity">Can Quantity</Trans>
                    </Typography>
                    <Typography variant="body1" data-testid="can-quantity">
                        {formatOrderQuantity(orderCanPallets, orderCanSkus)}
                    </Typography>
                    <Typography variant="body1" data-testid="can-eaches">
                        {`${getFormattedEachesFromNumber(orderCanEaches, cultureCode)} ea.`}
                    </Typography>
                </Grid>
                <Grid item xs={2} className={classes.item}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="endQuantity">End Quantity</Trans>
                    </Typography>
                    <Typography variant="body1" data-testid="end-quantity">
                        {formatOrderQuantity(orderEndPallets, orderEndSkus)}
                    </Typography>
                    <Typography variant="body1" data-testid="end-eaches">
                        {`${getFormattedEachesFromNumber(orderEndEaches, cultureCode)} ea.`}
                    </Typography>
                </Grid>
                <Grid item xs={2} className={classes.item}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="forecastWeek">Forecast Week</Trans>
                    </Typography>
                    <Typography variant="body1" data-testid="forecast-week">
                        {getFormattedDateStringForApplication(order.weekStart)}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.createdBy}>
                <Grid item xs={2}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="createdBy">Created By</Trans>
                    </Typography>
                    <Typography variant="body1" data-testid="created-by">
                        {order?.createdBy === 'ETL'
                            ? 'Ball'
                            : order?.createdBy ?? order?.submittedBy}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.customerPO}>
                <Grid item xs={2}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="customerPO">Customer PO</Trans>
                    </Typography>
                    <Typography variant="body1" data-testid="customer-po">
                        {order?.customerProductionOrderId ?? t('noCustomerPO', 'No Customer PO')}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PlanItOrderDetailsSubheader;
