import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { useTranslation } from 'react-i18next';
import { Activity } from '../../../utility/auth/useSecurity';
import ManageLocationsTable from './components/ManageLocationsTable';
import { AddressType, CustomerContextState } from '../../../store/reducers/customer-context';
import { AuthState } from '../../../store/reducers/auth';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
    spacer: {
        height: '1.5em'
    },
    actionBtn: {
        height: '3.375em',
        width: '13.75em',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom'
    }
}));

export default function ManageLocations() {
    const { adminAccounts, loaded } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    const billToIds = [AddressType.CB, AddressType.CX];

    useEffect(() => {
        if (loaded) {
            if (
                !adminAccounts ||
                adminAccounts.filter((a) => billToIds.includes(a.addressType)).length === 0
            ) {
                history.push('/unauthorized', { activity: Activity.ManageLocations });
            }
        }
    }, [adminAccounts, permissions]);

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('manageLocations', 'Manage Locations'),
                description: '',
                thinBanner: true,
                displayDropdown: true,
                completeDropdown: true
            }}
            activity={Activity.ManageLocations}
        >
            <div className={classes.spacer}></div>
            <ManageLocationsTable></ManageLocationsTable>
        </ProcessingPageTemplate>
    );
}
