import React, { Dispatch, SetStateAction, useEffect, useState, useCallback } from 'react';
import { makeStyles, Box, Typography, Tabs, Tab, Grid } from '@material-ui/core';
import useSecurity, { Activity, SecurityLevel } from '../../../../utility/auth/useSecurity';
import {
    small,
    blackWeight,
    inactiveTabBlue,
    lato,
    xl,
    black,
    medium,
    white,
    ballDrkBlue,
    addFormItemButtonBlue,
    ltBlueGrey
} from '../../../../themes/globalConstants';
import {
    InvoiceRow,
    MakeItSummaryState,
    ProductionOrderSummary
} from '../../../../store/reducers/make-it-summary';
import { useQuery } from '../../../../utility/helpers/query-helpers';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import MakeItDraftTab from './MakeItDraftTab';
import MakeItOpenTab from './MakeItOpenTab';
import MakeItPendingPaymentTab from './MakeItPendingPaymentTab';
import { useDispatch } from 'react-redux';
import {
    loadClosedProductionOrders,
    loadOpenProductionOrders,
    loadPendingPaymentOrders
} from '../../../../store/actions/make-it-summary';
import { AuthState } from '../../../../store/reducers/auth';
import MakeItClosedTab from './MakeItClosedTab';
import TranslationService from '../../../../utility/services/translation-service';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import {
    formattedMTDate,
    getFormattedDateStringForApplication
} from '../../../../utility/helpers/date-helpers';
import moment from 'moment';
import {
    CustomerContextState,
    ProdOrderType,
    Account,
    AddressType
} from '../../../../store/reducers/customer-context';
import {
    getShipToAddress,
    getShipToName,
    simpleFormatOrderQuantity
} from '../../../../utility/helpers/order-helpers';
import { Order_Status } from '../../../../utility/services/orders-service';
import { usePrevious } from '../../../../utility/helpers/react-util';
import {
    getCiaAccount,
    getRevisedAvailableDate,
    getShipToInformation,
    TabPanelProps
} from '../../../../utility/helpers/summary-helpers';
import { MAKE_SUMMARY_LOADING, MAKE_SUMMARY_RESET } from '../../../../store/actions/action-types';
interface Props {
    setDashboardLoading: Dispatch<SetStateAction<boolean>>;
    dashboardLoading: boolean;
}

enum TabStatus {
    'open' = 0,
    'pending' = 1,
    'closed' = 2,
    'draft' = 3
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: '4.5em',
        position: 'relative',
        '& h2': {
            fontSize: xl,
            fontFamily: lato,
            fontWeight: blackWeight,
            color: theme.palette.secondary.main
        }
    },
    tabPanel: {
        width: '100%'
    },
    counter: {
        width: '2em',
        height: '2em',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '0.5em',
        fontSize: small,
        fontWeight: blackWeight,
        backgroundColor: ltBlueGrey,
        color: ballDrkBlue
    },
    selectedCounter: {
        backgroundColor: addFormItemButtonBlue,
        color: white
    },
    tabs: {},
    tabLabel: {
        backgroundColor: inactiveTabBlue,
        height: '56px',
        borderTopLeftRadius: '0.625em',
        borderTopRightRadius: '0.625em',
        '&:not(:first-of-type)': {
            marginLeft: '8px'
        },
        padding: '0 3em',
        color: ballDrkBlue,
        textTransform: 'none',
        maxWidth: 'none'
    },
    tabLabelText: {
        fontSize: medium,
        fontWeight: blackWeight
    },
    selectedTab: {
        backgroundColor: white,
        color: black,
        boxShadow: '0px 0px 6px rgba(185, 211, 220, 0.8), inset 0px -3px 0px #009BBB'
    }
}));

const TabPanel = (props: TabPanelProps) => {
    const classes = useStyles();
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`make-it-tabpanel-${index}`}
            aria-labelledby={`make-it-tab-${index}`}
            {...other}
            className={classes.tabPanel}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
};

//This assigns props for accessibility purposes
const a11yProps = (index: any) => {
    return {
        id: `make-it-tab-${index}`,
        'aria-controls': `makeit-summary-tabpanel-${index}`
    };
};

const MakeItTabs = ({ dashboardLoading, setDashboardLoading }: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const query = useQuery();
    const status = query.get('status');
    const initialValue = getValueFromStatus(status);
    const [value, setValue] = useState<number>(initialValue);
    const [showCustomerPOCol, setShowCustomerPOCol] = useState<boolean>(true);
    const [isCiaAccount, setIsCiaAccount] = useState<boolean>(false);
    const [formattedInvoices, setFormattedInvoices] = useState<InvoiceRow[]>({} as InvoiceRow[]);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const draftOrdersSecurity = useSecurity(Activity.DraftProductionOrders);
    const { shipToAccounts, selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { accessToken, permissions } = useTypedSelector<AuthState>((state) => state.auth);
    const {
        openOrders,
        closedOrders,
        draftOrders,
        pendingOrders,
        openOrdersLoaded,
        closedOrdersLoaded,
        pendingOrdersLoaded,
        error
    } = useTypedSelector<MakeItSummaryState>((state) => state.makeItSummary);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const prevSelectedShipTo = usePrevious(selectedAccountId);
    const showDraftTab = false;

    const getOrderFirstRequestedDate = (paymentDueDate: Date, string: boolean) => {
        // payment due date is calculated as 4 weeks before the order first requested date
        // so, can calculate the other way around since we already have the paymentDueDate

        let orderFirstRequestedDate = moment(paymentDueDate).add(4, 'weeks');
        if (string) {
            return formattedMTDate(orderFirstRequestedDate, 'MM/DD/YYYY');
        } else {
            return orderFirstRequestedDate.format('YYYY-MM-DDTHH:mm:ssZ');
        }
    };

    // Load Pending Orders if the selectedShipToId is a CIA account
    const getPendingOrders = useCallback(
        (shipToAccounts: Account[], selectedShipToId: string) => {
            const isCiaAccount = getCiaAccount(shipToAccounts, selectedShipToId);

            if (isCiaAccount) {
                dispatch(loadPendingPaymentOrders([parseInt(selectedShipToId)]));
                setIsCiaAccount(true); // show tab if account is CIA
            } else {
                setIsCiaAccount(false);
            }
        },
        [dispatch]
    );

    useEffect(() => {
        return () => {
            // Reset summary page information on page leave
            dispatch({ type: MAKE_SUMMARY_RESET });
        };
    }, [dispatch]);

    // Check to see if content has loaded into the page
    useEffect(() => {
        if ((openOrdersLoaded && closedOrdersLoaded) || error !== undefined) {
            if (isCiaAccount && pendingOrdersLoaded) {
                setDashboardLoading(false);
            } else if (!isCiaAccount) {
                setDashboardLoading(false);
            }
        }
    }, [
        openOrdersLoaded,
        closedOrdersLoaded,
        pendingOrdersLoaded,
        error,
        isCiaAccount,
        setDashboardLoading
    ]);

    // Check for intial page load of data
    useEffect(() => {
        if (
            shipToAccounts &&
            shipToAccounts.length &&
            selectedAccountId &&
            !dashboardLoading &&
            !openOrdersLoaded &&
            !closedOrdersLoaded &&
            !pendingOrdersLoaded
        ) {
            if (accessToken && permissions?.length) {
                // Validate current ship to is of the correct address type to pull in page data
                const shipToAccount = getShipToInformation(shipToAccounts, selectedAccountId);

                if (
                    shipToAccount &&
                    (shipToAccount.addressType === AddressType.CS ||
                        shipToAccount.addressType === AddressType.CX)
                ) {
                    setDashboardLoading(true);
                    dispatch(loadClosedProductionOrders([parseInt(selectedAccountId)]));
                    dispatch(loadOpenProductionOrders([parseInt(selectedAccountId)]));
                }
                getPendingOrders(shipToAccounts, selectedAccountId);
            }

            const productionOrderTypes = shipToAccounts
                .map((account) => account.prodOrderType)
                .filter((value, index, self) => self.indexOf(value) === index);
            if (
                productionOrderTypes.length === 1 &&
                productionOrderTypes[0] === ProdOrderType.AuthorizationToManufacture
            ) {
                //if all accounts are ALL then hide the col
                setShowCustomerPOCol(false);
            }
        }
    }, [
        dispatch,
        shipToAccounts,
        selectedAccountId,
        dashboardLoading,
        openOrdersLoaded,
        closedOrdersLoaded,
        pendingOrdersLoaded,
        accessToken,
        permissions?.length,
        getPendingOrders,
        setDashboardLoading
    ]);

    // If we have pending orders, need to format the row information
    const formatInvoiceRow = useCallback(
        (pendingOrders: ProductionOrderSummary[]) => {
            let formattedInvoiceList: InvoiceRow[] = [];
            pendingOrders.forEach((pendingOrder) => {
                if (
                    pendingOrder.invoiceStatus === Order_Status.Payment_Delinquent &&
                    pendingOrder.invoicePaymentDueDate
                ) {
                    let invoiceRow: InvoiceRow = {
                        productionOrderId: pendingOrder.productionOrderId.toString(),
                        shipToId: pendingOrder.shipToId,
                        invoiceId: pendingOrder.invoiceId?.toString() || '',
                        remittanceNumber: pendingOrder.invoiceRemittanceNumber,
                        amount: pendingOrder.invoiceAmount || 0,
                        paymentDueDate: pendingOrder.invoicePaymentDueDate,
                        status: pendingOrder.invoiceStatus,

                        shipToName: getShipToName(
                            pendingOrder.productionOrderId,
                            shipToAccounts,
                            pendingOrders
                        ),
                        shipToAddress: getShipToAddress(
                            pendingOrder.productionOrderId,
                            shipToAccounts,
                            pendingOrders
                        ),
                        totalOrderQuantity: pendingOrder.totalOrderQuantity,
                        totalOrderSKUs: pendingOrder.totalOrderSKUs,
                        customerProductionOrderId: pendingOrder.customerProductionOrderId
                            ? pendingOrder.customerProductionOrderId
                            : '',
                        productionOrderNumber: pendingOrder.productionOrderNumber.toString(),
                        ballPONumber: +pendingOrder.productionOrderNumber,
                        searchableBallPONumber: pendingOrder.productionOrderNumber.toString(),
                        revisedAvailableDate: getRevisedAvailableDate(
                            pendingOrder.invoicePaymentDueDate,
                            false
                        ),
                        revisedAvailableDateString: getRevisedAvailableDate(
                            pendingOrder.invoicePaymentDueDate,
                            true
                        ),
                        submittedDateString: getFormattedDateStringForApplication(
                            pendingOrder.submittedDate?.toString()
                        ),
                        requestedDate: getOrderFirstRequestedDate(
                            pendingOrder.invoicePaymentDueDate,
                            false
                        ),
                        requestedDateString: getOrderFirstRequestedDate(
                            pendingOrder.invoicePaymentDueDate,
                            true
                        ),
                        paymentDueDateString: moment(
                            getFormattedDateStringForApplication(
                                pendingOrder.invoicePaymentDueDate?.toString()
                            )
                        ).format('L'),
                        orderQuantityString: simpleFormatOrderQuantity(
                            cultureCode,
                            pendingOrder?.totalOrderQuantity,
                            pendingOrder?.totalOrderSKUs
                        ),
                        orderQuantityEachesString:
                            pendingOrder.totalOrderQuantityEaches?.toLocaleString() + ' ea.'
                    };

                    formattedInvoiceList.push(invoiceRow);
                }
            });

            setFormattedInvoices(formattedInvoiceList);
            setIsLoaded(true);
        },
        [cultureCode, shipToAccounts]
    );

    useEffect(() => {
        if (pendingOrders && (!formattedInvoices.length || !isLoaded)) {
            formatInvoiceRow(pendingOrders);
        }
    }, [formatInvoiceRow, formattedInvoices.length, isLoaded, pendingOrders]);

    useEffect(() => {
        if (shipToAccounts && selectedAccountId) {
            const isCiaAccount = getCiaAccount(shipToAccounts, selectedAccountId);
            setIsCiaAccount(isCiaAccount);
        }
    }, [selectedAccountId, shipToAccounts]);

    // Reload Ship It Summary information only if the Selected Ship To has changed
    useEffect(() => {
        if (
            accessToken &&
            permissions?.length &&
            selectedAccountId &&
            prevSelectedShipTo &&
            shipToAccounts &&
            prevSelectedShipTo !== selectedAccountId
        ) {
            setDashboardLoading(true);
            dispatch({ type: MAKE_SUMMARY_LOADING });
            dispatch(loadClosedProductionOrders([parseInt(selectedAccountId)]));
            dispatch(loadOpenProductionOrders([parseInt(selectedAccountId)]));

            getPendingOrders(shipToAccounts, selectedAccountId);
        }
    }, [
        accessToken,
        dispatch,
        getPendingOrders,
        permissions?.length,
        prevSelectedShipTo,
        selectedAccountId,
        setDashboardLoading,
        shipToAccounts
    ]);

    // Handle tab selection change
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        const val = TabStatus[newValue];
        // Update query param so back button will return to same tab selection
        window.history.replaceState('', '', `?status=${val}`);
        setValue(newValue);
    };

    function getValueFromStatus(status) {
        return (TabStatus as any)[status?.toLowerCase() || ''] || 0;
    }

    useEffect(() => {
        const value = getValueFromStatus(status);
        setValue(value);
    }, [status]);

    // Generate Tab Label
    const TabLabel = (label: string, count: number, index: number) => {
        return (
            <Grid container justify="center" alignItems="center">
                <Typography className={classes.tabLabelText}>{label}</Typography>
                <Typography
                    component="span"
                    className={`${classes.counter} ${value === index && classes.selectedCounter}`}
                >
                    {count}
                </Typography>
            </Grid>
        );
    };

    return (
        <div className={classes.root} data-testid="make-it-tabs">
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="make it summary tabs"
                className={classes.tabs}
                scrollButtons="auto"
                variant="scrollable"
                indicatorColor="primary"
                textColor="primary"
            >
                <Tab
                    label={TabLabel(
                        TranslationService.getTranslatedText(cultureCode, 'openProductionOrders'),
                        openOrders ? openOrders.length : 0,
                        0
                    )}
                    {...a11yProps(0)}
                    className={`${classes.tabLabel} ${value === 0 && classes.selectedTab}`}
                    value={0}
                />
                {isCiaAccount && (
                    <Tab
                        label={TabLabel(
                            TranslationService.getTranslatedText(cultureCode, 'pendingPayment'),
                            formattedInvoices ? formattedInvoices.length : 0,
                            1
                        )}
                        {...a11yProps(1)}
                        className={`${classes.tabLabel} ${value === 1 && classes.selectedTab}`}
                        value={1}
                    />
                )}
                <Tab
                    label={TabLabel(
                        TranslationService.getTranslatedText(cultureCode, 'closedProductionOrders'),
                        closedOrders ? closedOrders.length : 0,
                        2
                    )}
                    {...a11yProps(2)}
                    className={`${classes.tabLabel} ${value === 2 && classes.selectedTab}`}
                    value={2}
                />
                {showDraftTab && (
                    <Tab
                        label={TabLabel(
                            TranslationService.getTranslatedText(
                                cultureCode,
                                'draftProductionOrders'
                            ),
                            draftOrders.length,
                            3
                        )}
                        {...a11yProps(3)}
                        className={`${classes.tabLabel} ${value === 3 && classes.selectedTab}`}
                        disabled={draftOrdersSecurity === SecurityLevel.None}
                        value={3}
                    />
                )}
            </Tabs>
            <TabPanel value={value} index={0}>
                {openOrders && <MakeItOpenTab showCustomerPOCol={showCustomerPOCol} />}
            </TabPanel>
            {isCiaAccount && (
                <TabPanel value={value} index={1}>
                    {pendingOrders && (
                        <MakeItPendingPaymentTab formattedInvoices={formattedInvoices} />
                    )}
                </TabPanel>
            )}
            <TabPanel value={value} index={2}>
                {closedOrders && openOrders && (
                    <MakeItClosedTab showCustomerPOCol={showCustomerPOCol} />
                )}
            </TabPanel>
            <TabPanel value={value} index={3}>
                <MakeItDraftTab />
            </TabPanel>
        </div>
    );
};

export default MakeItTabs;
