import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsights } from '../../utility/AppInsights';
import { Persona } from '../../utility/auth/useSecurity';
import AccountService from '../../utility/services/account-service';
import UserService from '../../utility/services/user-service';
import { UserPermission, UserPermissionDto } from '../reducers/auth';
import { UserPermissionChange, UserAdminUserPermissions } from '../reducers/user-admin';
import * as types from './action-types';

export const loadUserPermissions = (userEmail: string) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.USER_ADMIN_USER_LOADING
        });
        const accessToken = getState().auth.accessToken;

        if (userEmail) {
            UserService.getUserPermissions(userEmail, accessToken)
                .then((response) => {
                    if (response) {
                        const user = response.data;
                        dispatch({
                            type: types.USER_ADMIN_USER_LOADED,
                            userToEdit: userEmail,
                            permissions: user.permissions,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            lastLogin: user.lastLogin
                        });
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: types.USER_ADMIN_USER_LOADING_ERROR,
                        error
                    });
                });
        }
    };
};
export const loadViewEditUser = (
    userEmail: string,
    permissions: UserPermission[] | null,
    firstName?: string,
    lastName?: string,
    lastLogin?: Date,
    editable?: boolean
) => {
    return (dispatch) => {
        dispatch({
            type: types.USER_ADMIN_USER_LOADING
        });
        dispatch({
            type: types.USER_ADMIN_VIEW_EDIT_USER,
            userToEdit: userEmail,
            permissions: permissions,
            firstName: firstName,
            lastName: lastName,
            lastLogin: lastLogin,
            editable: editable || false
        });
    };
};

export const loadAdminUsers = () => {
    return async (dispatch, getState) => {
        dispatch({
            type: types.USER_ADMIN_USERS_LOADING
        });
        const accessToken = getState().auth.accessToken;

        const removeBallUser = (users) => {
            const lowerCaseUsers = users.map((user) => {
                user.userName = user.userName.toLocaleLowerCase();
                return user;
            });
            return lowerCaseUsers.filter(
                (user) =>
                    user.userName.toLowerCase() ===
                        getState().auth.userInfo.preferred_username.toLowerCase() ||
                    !user.userName.includes('@ball.com')
            );
        };
        try {
            const [activeUsers, inactiveUsers] = await Promise.all([
                UserService.getAdminUsers(accessToken, 'ACTIVE').then((response) => {
                    if (response) {
                        return removeBallUser(response.data.users);
                    }
                }),
                UserService.getAdminUsers(accessToken, 'INACTIVE').then((response) => {
                    if (response) {
                        return removeBallUser(response.data.users);
                    }
                })
            ]);
            dispatch({
                type: types.USER_ADMIN_USERS_LOADED,
                activeUsers: activeUsers,
                inactiveUsers: inactiveUsers
            });
        } catch (error) {
            dispatch({
                type: types.USER_ADMIN_USERS_LOADING_ERROR,
                error
            });
        }
    };
};

export const disableAdminUser = (userEmail: string) => {
    return (dispatch, getState) => {
        const accessToken = getState().auth.accessToken;
        UserService.disableAdminUser(userEmail, accessToken)
            .then((response) => {
                if (response) {
                    dispatch(loadAdminUsers());
                }
            })
            .catch((error) => {
                dispatch({
                    type: types.USER_ADMIN_USER_DISABLING_ERROR,
                    error
                });
            });
    };
};

export const updateAdminUserStatus = (userName: string, status: boolean) => {
    return (dispatch, getState) => {
        const accessToken = getState().auth.accessToken;
        UserService.updateAdminUserStatus(accessToken, userName, status)
            .then((response) => {
                if (response) {
                    dispatch(loadAdminUsers());
                }
            })
            .catch((error) => {
                dispatch({
                    type: types.USER_ADMIN_USER_UPDATE_STATUS_ERROR,
                    error
                });
            });
    };
};

export const clearUserPermissions = () => {
    return (dispatch) => {
        dispatch({
            type: types.USER_ADMIN_USER_CLEARED
        });
    };
};

export const getUserAdminPendingRequests = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.USER_ADMIN_USERS_LOADING
        });

        const accessToken = getState().auth.accessToken;
        UserService.getPendingRequests(accessToken).then((response) => {
            if (response) {
                const pendingRequestsFromAPI = response.data.permissionChanges;
                dispatch({
                    type: types.USER_ADMIN_PENDING_REQUESTS,
                    permissionChanges: pendingRequestsFromAPI
                });
            }
        });
    };
};

export const resubmitRequest = (requestId: Number, onSuccess: () => void) => {
    return (dispatch, getState) => {
        const accessToken = getState().auth.accessToken;
        UserService.resubmitPermissionRequest(accessToken, requestId)
            .then(() => {
                dispatch(getPermissionChangesForUser());
                onSuccess();
            })
            .catch((error) => {
                dispatch({
                    type: types.MY_ACCOUNT_PERMISSION_CHANGES_ERROR,
                    error
                });
            });
    };
};

export const cancelRequest = (requestId: Number) => {
    return (dispatch, getState) => {
        const accessToken = getState().auth.accessToken;
        UserService.cancelPermissionRequest(accessToken, requestId)
            .then(() => {
                dispatch(getPermissionChangesForUser());
            })
            .catch((error) => {
                dispatch({
                    type: types.MY_ACCOUNT_PERMISSION_CHANGES_ERROR,
                    error
                });
            });
    };
};

export const getPermissionChangesForUser = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.MY_ACCOUNT_PERMISSION_CHANGES_LOADING
        });

        const accessToken = getState().auth.accessToken;
        UserService.getPermissionChangesForUser(accessToken)
            .then((response) => {
                const permissionChanges = response.data.permissionChanges;
                const permissionAccounts = permissionChanges.map(
                    (permissionChange) => permissionChange.accountId
                );
                return Promise.all([
                    permissionChanges,
                    AccountService.getAccounts(getState(), false, permissionAccounts)
                ]);
            })
            .then(([permissionChanges, getAccountsResponse]) => {
                let accountsResponse = getAccountsResponse?.data.accounts;
                if (permissionChanges && accountsResponse) {
                    permissionChanges.forEach((permissionChange) => {
                        const account = accountsResponse.find(
                            (account) => account.accountId === permissionChange.accountId
                        );
                        if (account?.address) {
                            permissionChange.accountCity = account.address.city;
                            permissionChange.accountStateProvince = account.address.stateProvince;
                        }
                    });
                }
                dispatch({
                    type: types.MY_ACCOUNT_PERMISSION_CHANGES_LOADED,
                    permissionChanges: permissionChanges
                });
            })
            .catch((error) => {
                dispatch({
                    type: types.MY_ACCOUNT_PERMISSION_CHANGES_ERROR,
                    error
                });
            });
    };
};

export const getUserAdminPendingRegistrations = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.USER_ADMIN_USERS_LOADING
        });

        const accessToken = getState().auth.accessToken;
        UserService.getPendingRegistrations(accessToken)
            .then((response) => {
                if (response) {
                    const pendingRegistrations = response.data.registrations
                        .map((user) => {
                            user.userName = user.userName.toLocaleLowerCase();
                            return user;
                        })
                        .filter((user) => !user.userName.includes('ball.com'));
                    dispatch({
                        type: types.USER_ADMIN_PENDING_REGISTRATIONS,
                        pendingRegistrations: pendingRegistrations
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: types.USER_ADMIN_USERS_LOADING_ERROR,
                    error
                });
            });
    };
};

export const getPendingRequest = (id: string) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.USER_ADMIN_USERS_LOADING
        });

        const accessToken = getState().auth.accessToken;
        UserService.getPendingRequest(accessToken, id).then((response) => {
            if (response) {
                const pendingRequest = response.data;
                if (response.data.userName) {
                    UserService.getUserPermissions(response.data.userName, accessToken).then(
                        (response) => {
                            if (response) {
                                const user = response.data;
                                pendingRequest.firstName = user.firstName;
                                pendingRequest.lastName = user.lastName;
                            }

                            dispatch({
                                type: types.USER_ADMIN_PENDING_REQUEST_LOADED,
                                pendingRequest: pendingRequest
                            });
                        }
                    );
                }
            }
        });
    };
};

export const rejectUserPermissionChange = (id: string) => {
    return (dispatch, getState) => {
        const accessToken = getState().auth.accessToken;
        UserService.rejectUserPermissionChange(accessToken, id).then((response) => {
            if (response) {
                //reload the user admin dashboard to remove rejected
                dispatch(getUserAdminPendingRequests());
                dispatch(loadAdminUsers());
                dispatch({
                    type: types.USER_ADMIN_PENDING_REQUEST_REJECTED
                });
            }
        });
    };
};

export const approveUserPermissionChange = (change: UserPermissionChange) => {
    return (dispatch, getState) => {
        const accessToken = getState().auth.accessToken;
        UserService.approveUserPermissionChange(accessToken, change).then((response) => {
            if (response) {
                //reload the user admin dashboard to remove approved
                dispatch(getUserAdminPendingRequests());
                dispatch(loadAdminUsers());
                dispatch({
                    type: types.USER_ADMIN_PENDING_REQUEST_APPROVED
                });
            }
        });
    };
};

const getSubmitNewUserStarted = () => ({
    type: types.USER_ADMIN_ADD_USER_LOADING
});

const getSubmitNewUserSuccess = () => ({
    type: types.USER_ADMIN_ADD_USER_SUCCESS
});

const getSubmitNewUserFailed = (error) => ({
    type: types.USER_ADMIN_ADD_USER_ERROR,
    error
});

export const resetPendingRequest = () => ({
    type: types.USER_ADMIN_PENDING_REQUEST_RESET
});

export const resendPendingRegistrationRequest = (registrationId: string, onSuccess: () => void) => {
    return (dispatch, getState) => {
        const accessToken = getState().auth.accessToken;
        UserService.resendPendingRegistrationRequest(accessToken, registrationId)
            .then(() => {
                dispatch(getPermissionChangesForUser());
                onSuccess();
            })
            .catch((error) => {
                const user = getState().auth?.userInfo?.email;
                if (appInsights && user) {
                    const appInsightsError =
                        error.message + ' resendPendingRegistrationRequest-' + user;
                    appInsights.trackException({
                        error: new Error(appInsightsError),
                        severityLevel: SeverityLevel.Error
                    });
                }
            });
    };
};

export const updateUserPermissions = (
    userEmail: string,
    permissions: Array<UserPermission>,
    onSuccess: () => void
) => {
    return (dispatch, getState) => {
        dispatch(getSubmitNewUserStarted());
        const accessToken = getState().auth.accessToken;
        const authPermissions = getState().auth.permissions;
        const currentPermissions: UserPermission[] = getState().userAdmin.permissions;
        const requestPermissions: UserPermissionDto[] = convertUserPermissionsToDtos(
            userEmail,
            permissions,
            1
        );

        // Find all existing permissions for this user in state...
        // ...request to deactivate the permission if not passed into this function...
        // ...the result is that all permissions will be deactivated except for ones passed into this function.
        currentPermissions.forEach((currentPermission) => {
            currentPermission.accountIds.forEach((accountId) => {
                const matchingRequestPermission = requestPermissions.find(
                    (requestPermission) =>
                        requestPermission.persona === currentPermission.personaId &&
                        requestPermission.accountId === accountId
                );

                // Exclude Account Admin from deactivations
                if (
                    !matchingRequestPermission &&
                    currentPermission.personaId !== Persona.AccountAdmin
                ) {
                    const requestToDeactivate: UserPermissionDto = {
                        userName: userEmail,
                        accountId,
                        persona: currentPermission.personaId,
                        status: 0
                    };
                    requestPermissions.push(requestToDeactivate);
                }
            });
        });

        const allowedPermissionUpdates = filterPermissionsRequestByAdminAccess(
            authPermissions,
            requestPermissions
        );

        UserService.updateUserPermissions(accessToken, allowedPermissionUpdates)
            .then(() => {
                dispatch(getSubmitNewUserSuccess());
                onSuccess();
            })
            .catch((err) => {
                dispatch(getSubmitNewUserFailed(err.toString()));
            });
    };
};

export const setAllUserPermissionsStatus = (
    username: string,
    newStatus: 1 | 0,
    accountId: string
) => {
    return (dispatch, getState) => {
        dispatch({
            type: types.USER_ADMIN_USERS_LOADING
        });
        const accessToken = getState().auth.accessToken;
        const activeUsers: UserAdminUserPermissions[] =
            getState().userAdminDashboard.activeUsers ?? [];
        const inactiveUsers: UserAdminUserPermissions[] =
            getState().userAdminDashboard.inactiveUsers ?? [];
        const allUsers = activeUsers.concat(inactiveUsers);
        const permissionsMatchingUsername = allUsers.filter(
            (user) => user.userName.toLowerCase() === username.toLowerCase()
        );
        let permissionDtos: UserPermissionDto[] = [];
        permissionsMatchingUsername.forEach((permission) => {
            permissionDtos = permissionDtos.concat(
                convertUserPermissionsToDtos(username, permission.permissions ?? [], newStatus)
            );
        });
        const permissionDtosMatchingAccountId = permissionDtos.filter(
            (request) => request.accountId === accountId
        );

        UserService.updateUserPermissions(accessToken, permissionDtosMatchingAccountId)
            .then((response) => {
                if (response) {
                    dispatch(loadAdminUsers());
                }
            })
            .catch((error) => {
                dispatch({
                    type: types.USER_ADMIN_USER_UPDATE_STATUS_ERROR,
                    error
                });
            });
    };
};

const convertUserPermissionsToDtos = (
    userEmail: string,
    userPermissions: UserPermission[],
    status: 1 | 0
): UserPermissionDto[] => {
    const requestPermissions: UserPermissionDto[] = [];

    userPermissions.forEach((permission) => {
        permission.accountIds.forEach((accountId) => {
            const requestPermission: UserPermissionDto = {
                userName: userEmail,
                accountId,
                persona: permission.personaId,
                status: status
            };
            requestPermissions.push(requestPermission);
        });
    });

    return requestPermissions;
};

const filterPermissionsRequestByAdminAccess = (
    loggedInUserPermissions: UserPermission[],
    requestPermissions: UserPermissionDto[]
) => {
    const adminPermissions = loggedInUserPermissions.filter(
        (permission) => permission.personaId === Persona.AccountAdmin
    );
    let adminAccountIds: string[] = [];
    adminPermissions.forEach((permission) => {
        adminAccountIds = adminAccountIds.concat(permission.accountIds);
    });

    return requestPermissions.filter((permission) =>
        adminAccountIds.includes(permission.accountId)
    );
};
