import { ColumnDataType, createColumn } from 'tubular-common';
import { FlagTypes, GetFeatureFlag } from '../../../../utility/helpers/feature-flag';
import TranslationService from '../../../../utility/services/translation-service';

export default class CancelDeliveryOrderConfirmationConfigColumns {
    static getCancelDeliveryOrderConfirmationConfigColumns = (culture: string) => {
        const cokeFeatureFlag = GetFeatureFlag(FlagTypes.Coke);

        return [
            createColumn('sequence', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'loadSeq')
            }),
            createColumn('displayId', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'productGraphicId')
            }),
            createColumn('displayName', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('description', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'productDescription')
            }),
            createColumn('palletQuantity', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'quantity')
            }),
            createColumn('releaseNumber', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'releaseNumberPDF')
            }),
            createColumn('fillerLine', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'fillerLineNumber')
            }),
            createColumn('referenceNumber', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'referenceNumberPDF')
            }),
            createColumn('purchaseOrderNumber', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'customerPoNumber'),
                visible: !!cokeFeatureFlag
            }),
            createColumn('deliveryInstructions', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'deliveryInstructions')
            }),
            createColumn('productSku', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('name', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('graphicId', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('type', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('quantityPerPallet', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('orderedPallets', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('description', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('customerProductId', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('customerProductName', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('graphicIdAndVersion', {
                dataType: ColumnDataType.String,
                visible: false
            })
        ];
    };
}
