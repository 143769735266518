import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography, TableRow, TableCell, Grid } from '@material-ui/core';
import { ProductToShip } from '../../../../store/reducers/shipping-dashboard';
import { blackWeight, ballLtGray, orange } from '../../../../themes/globalConstants';
import { Flags, FlagTypes } from '../../../../utility/helpers/feature-flag';
import { getEachesConversion } from '../../../../utility/helpers/shipment-helpers';

interface Props {
    item: ProductToShip;
    sequence: number;
    deliveryInstructions: any;
}

const useStyles = makeStyles((theme) => ({
    tableRowContainer: {
        '& p:first-of-type': {
            fontWeight: blackWeight
        },
        '& .MuiInputBase-input': {
            textAlign: 'right',
            paddingRight: '.25em'
        }
    },
    textField: {
        border: `2px solid ${ballLtGray}`,
        borderRadius: '.125em',
        fontWeight: blackWeight
    },
    smalltextField: {
        width: '3.125em',
        height: '1.5em',
        marginRight: '0.5em'
    },
    alignCenter: {
        textAlign: 'center'
    },
    alignRight: {
        textAlign: 'right'
    },
    alignLeft: {
        '& .MuiInputBase-input': {
            textAlign: 'left',
            paddingLeft: '0.375em'
        }
    },
    paletteInputContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    addBtn: {
        padding: 0,
        height: '2.28em',
        width: '3.2em'
    },
    trash: {
        paddingRight: '0',
        minWidth: 'unset',
        '&:hover': {
            backgroundColor: 'white'
        },
        '& svg': {
            fill: orange
        }
    },
    colorBlock: {
        width: 16,
        height: 50
    },
    sequence: {
        marginLeft: '0.750em'
    },
    selectWrapper: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiInputBase-root': {
            backgroundColor: theme.palette.common.white,
            border: `2px solid ${ballLtGray}`,
            borderRadius: '.125em',
            '&:before': {
                border: 0
            },
            '&.Mui-focused': {
                '& .MuiSvgIcon-root': {
                    transform: 'rotate(-180deg)'
                }
            },
            '& .MuiSvgIcon-root': {
                fill: theme.palette.primary.main,
                transition: 'transform 200ms'
            }
        }
    },
    fillerLineSelect: {
        '& .MuiFilledInput-underline ': {
            padding: '0 !important',
            '& > input': {
                padding: '0.375em !important',
                fontWeight: blackWeight
            },
            '& .MuiInputAdornment-root': {
                marginTop: 0,
                cursor: 'pointer'
            }
        }
    },
    eaches: {
        wordBreak: 'break-all'
    }
}));

const CancelDeliveryOrderConfirmationConfigRow = ({
    item,
    sequence,
    deliveryInstructions
}: Props) => {
    const classes = useStyles();
    const [palletQuantity, setPalletQuantity] = useState(0);
    const [releaseNumber, setReleaseNumber] = useState('');
    const [fillerLine, setFillerLine] = useState('');
    const [reference, setReference] = useState('');
    const [purchaseOrderNumber, setPurchaseOrderNumber] = useState('');

    useEffect(() => {
        if (item.palletQuantity !== undefined) {
            setPalletQuantity(item.palletQuantity);
        }
        if (item.releaseNumber !== undefined) {
            setReleaseNumber(item.releaseNumber);
        }
        if (item.fillerLine !== undefined) {
            setFillerLine(item.fillerLine);
        }
        if (item.referenceNumber !== undefined) {
            setReference(item.referenceNumber);
        }
        if (item.purchaseOrderNumber !== undefined) {
            setPurchaseOrderNumber(item.purchaseOrderNumber);
        }
    }, [item]);

    useTranslation();

    return (
        <TableRow className={classes.tableRowContainer}>
            <TableCell data-testid="sequence">
                <Grid container alignItems="center">
                    <Grid item className={classes.sequence}>
                        <Typography>{sequence}</Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Typography data-testid="product-sku">{item.displayId}</Typography>
                <Typography data-testid="graphic-id">{item.graphicIdAndVersion}</Typography>
            </TableCell>
            <TableCell data-testid="product-description">
                <Typography variant="subtitle2">{item.displayName}</Typography>
                <Typography>{item.description}</Typography>
            </TableCell>
            <TableCell>
                <Typography>{palletQuantity} PL</Typography>
                <Typography data-testid="eaches-qty" className={classes.eaches}>
                    {item.quantityPerPallet &&
                        getEachesConversion(item.palletQuantity, item.quantityPerPallet, true)}
                </Typography>
            </TableCell>
            <TableCell data-testid="release-number">
                <Grid item>
                    <Typography>{releaseNumber}</Typography>
                </Grid>
            </TableCell>
            <TableCell data-testid="filler-line">
                <Grid item className={classes.selectWrapper}>
                    <Typography>{fillerLine}</Typography>
                </Grid>
            </TableCell>
            <TableCell data-testid="reference-number">
                <Grid item>
                    <Typography>{reference}</Typography>
                </Grid>
            </TableCell>
            <Flags authorizedFlags={[FlagTypes.Coke]}>
                <TableCell data-testid="purchase-order-number">
                    <Grid item>
                        <Typography>{purchaseOrderNumber}</Typography>
                    </Grid>
                </TableCell>
            </Flags>
            <TableCell data-testid="delivery-instructions">
                <Grid item>
                    <Typography>{deliveryInstructions}</Typography>
                </Grid>
            </TableCell>
        </TableRow>
    );
};

export default CancelDeliveryOrderConfirmationConfigRow;
