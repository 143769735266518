import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class MakeItColumns {
    static getMakeItColumns = (culture: string, isLargeCustomer: boolean) => {
        return [
            createColumn('productId', {
                dataType: ColumnDataType.Numeric,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('displayId', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture,'customerProductGraphicId'),
                filterable: true,
                sortable: true,
                searchable: true
            }),
            createColumn('graphicIdAndVersion', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'graphicID'),
                filterable: true,
                sortable: false,
                searchable: true,
                visible: false
            }),
            createColumn('graphicId', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('leadTimeWeeks', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'leadTime'),
                visible: isLargeCustomer,
                filterable: true,
                sortable: true,
                searchable: false
            }),
            createColumn('leadTimeString', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            }),
            createColumn('displayName', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'productDescription'),
                filterable: true,
                sortable: true,
                searchable: true
            }),
            createColumn('productSku', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            }),
            createColumn('name', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('description', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(
                    culture,
                    isLargeCustomer ? 'supplyPlanAttributes' : 'characteristics'
                ),
                filterable: true,
                sortable: true,
                searchable: true
            }),
            createColumn('coating', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'coating'),
                filterable: true,
                sortable: true,
                searchable: true
            }),
            createColumn('quantityToPurchase', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'quantityToPurchase'),
                filterable: false,
                sortable: false,
                searchable: false,
                visible: isLargeCustomer
            }),
            createColumn('type', {
                dataType: ColumnDataType.String,
                filterable: true,
                sortable: true,
                searchable: true,
                visible: false
            }),
            createColumn('addDisabled', {
                dataType: ColumnDataType.Boolean,
                label: ' ',
                filterable: false,
                sortable: false,
                searchable: false
            }),
            createColumn('customerProductId', {
                dataType: ColumnDataType.String,
                filterable: false,
                sortable: true,
                searchable: true,
                visible: false
            }),
            createColumn('customerProductName', {
                dataType: ColumnDataType.String,
                filterable: false,
                sortable: true,
                searchable: true,
                visible: false
            }),
            createColumn('campaignTiming', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('campaignRuns', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('quantityPerPallet', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('palletQuantity', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('eachesQuantity', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('snoSkuDescription', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('snoSkuAttributes', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('snoSku', {
                dataType: ColumnDataType.String,
                visible: false
            })
        ];
    };
}
