import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { resumeATMDraftOrder } from '../../../../store/actions/make-it-drafts';
import { DraftInformation, MakeItDraftsState } from '../../../../store/reducers/make-it-drafts';
import { ProductionOrder } from '../../../../store/reducers/makeit-dashboard';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { white } from '../../../../themes/globalConstants';
import { useHistory } from 'react-router';
import {
    formatProductionOrdersAsBulkATMOrder,
    isLargeCustomerShipToId
} from '../../../../utility/helpers/make-it-draft-helpers';
import { BulkUploadMakeItState } from '../../../../store/reducers/makeit-bulk-upload';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { selectCustomerAccount } from '../../../../store/actions/customer-context';

interface Props {
    orders: ProductionOrder[];
    draftInformation: DraftInformation;
    accountsWithMakeIt: string[];
    openDiscardModal: (orders: ProductionOrder[], entireDraftSelected: boolean) => void;
    openRestoreDraftsModal: (orders: ProductionOrder[], draft: DraftInformation) => void;
    activeTab: string;
}
const useStyles = makeStyles((theme) => ({
    button: {
        borderRadius: 'unset',
        marginRight: '2em',
        marginTop: '2.5em'
    },
    discardBtn: {
        marginTop: '2.5em',
        marginRight: '2em',
        color: theme.palette.error.main,
        border: `1px solid ${theme.palette.error.main}`,
        '&:hover': {
            color: white,
            backgroundColor: theme.palette.error.main
        }
    }
}));

const MakeItDraftsSummaryOrderSectionActions = ({
    draftInformation,
    orders,
    accountsWithMakeIt,
    openDiscardModal,
    openRestoreDraftsModal,
    activeTab
}: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const { selectedDraft } = useTypedSelector<MakeItDraftsState>((state) => state.makeItDrafts);
    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );

    const { products } = useTypedSelector<BulkUploadMakeItState>(
        (state) => state.bulkUploadMakeItState
    );

    const getSelectedProductionOrders = () => {
        let selectedProductionOrderIds: string[] = [];
        // get all checkboxes for this draft section
        document
            .querySelectorAll<HTMLInputElement>(
                `.MuiCheckbox-root[data-draftId="${draftInformation.draftId}"]`
            )
            .forEach((el) => {
                let productionOrderId = el.getAttribute('data-poId');
                const checkbox = el.getElementsByTagName('input')[0];

                // if checkbox is selected and not disabled
                if (checkbox.value === 'true' && !checkbox.disabled && productionOrderId) {
                    selectedProductionOrderIds.push(productionOrderId);
                }
            });

        // filter orders to include selected checkboxes and check permissions again to prevent inspect element changes
        return orders.filter(
            (order) =>
                order.productionOrderId &&
                selectedProductionOrderIds.includes(order.productionOrderId.toString()) &&
                accountsWithMakeIt.includes(order.shipToId.toString())
        );
    };

    const handleDiscard = () => {
        const productionOrders = getSelectedProductionOrders();
        const entireDraftSelected = productionOrders.length === orders.length;
        openDiscardModal(productionOrders, entireDraftSelected);
    };

    const handleRestoreDraftsModal = () => {
        const productionOrders = getSelectedProductionOrders();
        openRestoreDraftsModal(productionOrders, draftInformation);
    };

    const handleSubmit = () => {
        if (!!products) {
            const { orders, weeks } = formatProductionOrdersAsBulkATMOrder(
                getSelectedProductionOrders()
            );
            dispatch(
                resumeATMDraftOrder(draftInformation, orders, weeks, activeTab === 'archivedDrafts')
            );
            dispatch(selectCustomerAccount(String(orders[0].shipToId)));

            const isLargeCustomer = isLargeCustomerShipToId(
                String(orders[0].shipToId),
                shipToAccounts
            );

            if (isLargeCustomer) {
                history.push('/make-it-bulk-upload-po-review?from=draft');
            } else {
                history.push('/make-it?from=draft');
            }
        }
    };

    return (
        <Grid item xs={12} container justify="flex-end">
            <Button
                type="button"
                variant="outlined"
                data-testid="discard-draft-btn"
                className={classes.discardBtn}
                onClick={handleDiscard}
                disabled={selectedDraft !== draftInformation.draftId || !products}
            >
                <Trans i18nKey="discard">Discard</Trans>
            </Button>
            <Button
                type="submit"
                color="primary"
                variant="contained"
                className={classes.button}
                data-testid="resume-draft-btn"
                onClick={handleSubmit}
                disabled={selectedDraft !== draftInformation.draftId || !products}
            >
                {activeTab === 'openDrafts' ? (
                    <Trans i18nKey="resumeOrder">Resume Order</Trans>
                ) : (
                    <Trans i18nKey="viewDraft">View Draft</Trans>
                )}
            </Button>
            {activeTab === 'archivedDrafts' && (
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    data-testid="resume-draft-btn"
                    onClick={handleRestoreDraftsModal}
                    disabled={selectedDraft !== draftInformation.draftId || !products}
                >
                    <Trans i18nKey="restoreDraft">Restore Draft</Trans>
                </Button>
            )}
        </Grid>
    );
};

export default MakeItDraftsSummaryOrderSectionActions;
