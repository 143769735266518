import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { boldWeight } from '../../../themes/globalConstants';
import Button from '../atoms/Button';
import Modal from './Modal';

interface Props {
    onConfirmCancel: () => void;
    onClose: () => void;
    open: boolean;
}

const useStyles = makeStyles((theme) => ({
    modal: {
        '& .MuiPaper-root': {
            maxWidth: '40.5em'
        },
        '& .MuiDialog-paperScrollPaper': {
            borderRadius: 'unset',
            overflow: 'hidden'
        },
        '& .MuiDialogTitle-root': {
            justifyContent: 'start'
        },
        '& .MuiDialogContent-root': {
            padding: '1.875em'
        },
        '& .MuiPaper-elevation1': {
            boxShadow: 'none'
        }
    },
    button: {
        height: 40,
        marginTop: 30,
        fontSize: 14,
        fontWeight: boldWeight,
        textTransform: 'uppercase',
        width: '100%'
    },
    cancelButton: {
        marginRight: 20
    },
    statusSwitchContainer: {
        marginTop: 40
    },
    confirmationMessageContainer: {
        marginTop: 16,
        '& .MuiTypography-body1': {
            fontWeight: boldWeight
        }
    }
}));

const CancelOrderModal = ({ open, onClose, onConfirmCancel }: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <Modal
            open={open}
            close={onClose}
            title={t('cancelOrder', 'Cancel Order')}
            closeIcon={true}
            fullWidth={true}
            className={classes.modal}
        >
            {
                <>
                    <Typography>
                        {t('areYouSureCancelOrder', 'Are you sure you want to cancel this order?')}
                    </Typography>
                    <Grid container alignItems="flex-end" justify="flex-end">
                        <Grid item className={classes.cancelButton}>
                            <Button
                                type="button"
                                variant="outlined"
                                color="secondary"
                                className={classes.button}
                                onClick={onClose}
                                data-testid="cancel-btn"
                            >
                                <Trans i18nKey="no">No</Trans>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={onConfirmCancel}
                                data-testid="submit-btn"
                            >
                                <Trans i18nKey="yes">Yes</Trans>
                            </Button>
                        </Grid>
                    </Grid>
                </>
            }
        </Modal>
    );
};

export default CancelOrderModal;
