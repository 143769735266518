import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class PricingBreakdownColumns {
    static getColumns = (culture: string) => {
        return [
            createColumn('sortIndex', {
                searchable: false,
                sortable: false,
                filterable: false,
                visible: false,
                dataType: ColumnDataType.Numeric
            }),
            createColumn('itemDescription', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'itemDescription'),
                visible: true,
                sortable: true,
                filterable: true,
                searchable: true
            }),
            createColumn('currentBasePrice', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'currentBasePrice'),
                visible: true,
                sortable: true,
                filterable: true,
                searchable: false
            }),
            createColumn('newBasePrice', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'newBasePrice'),
                visible: true,
                sortable: true,
                filterable: true,
                searchable: false
            }),
            createColumn('freightChargeFromLocation', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'freightChargeFromLocation'),
                visible: true,
                sortable: true,
                filterable: true,
                searchable: true
            }),
            createColumn('freightCharges', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'freightChargesAvailable'),
                visible: true,
                sortable: true,
                filterable: true,
                searchable: false
            }),
            createColumn('newBasePriceWithFreight', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'newBaseWithFreight'),
                visible: true,
                sortable: true,
                filterable: true,
                searchable: false
            }),
            // Needed for search functionality
            createColumn('currentBasePriceFormat', {
                dataType: ColumnDataType.String,
                visible: false,
                sortable: false,
                filterable: false,
                searchable: true
            }),
            createColumn('newBasePriceFormat', {
                dataType: ColumnDataType.String,
                visible: false,
                sortable: false,
                filterable: false,
                searchable: true
            }),
            createColumn('freightChargesFormat', {
                dataType: ColumnDataType.String,
                visible: false,
                sortable: false,
                filterable: false,
                searchable: true
            }),
            createColumn('newBasePriceWithFreightFormat', {
                dataType: ColumnDataType.String,
                visible: false,
                sortable: false,
                filterable: false,
                searchable: true
            })
        ];
    };
}