import React from 'react';
import { Grid, Typography, makeStyles, Link, Paper } from '@material-ui/core';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';

import { useTranslation, Trans } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { boldWeight, medium, ballGray, black_5 } from '../../../../themes/globalConstants';
import { enUS } from '../../../../utility/translations/locales';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { selectIsLargeCustomerAccount } from '../../../../store/selectors';

const useStyles = makeStyles((theme) => ({
    paper: {
        borderRadius: 4,
        padding: '2em',
    },
    wrapper: {
        color: theme.palette.secondary.main,
        '& svg': {
            width: '1em',
            marginRight: '0.25em'
        }
    },
    header: {
        fontWeight: boldWeight,
        marginBottom: '1em'
    },
    subHeader: {
        color: ballGray,
        fontWeight: boldWeight,
        marginBottom: '.5em',
        textTransform: 'uppercase'
    },
    subHeaderDetails: {
        color: black_5,
        fontWeight: boldWeight,
        marginBottom: '1em'
    },
    altIcon: {
        color: ballGray
    },
    icon: {
        marginBottom: '0.625em',
        maxHeight: '2.5em'
    },
    iconContainer: {
        marginTop: '1em'
    },
    link: {
        fontSize: medium,
        textDecoration: 'underline'
    },
    subContainer: {
        paddingRight: '2em'
    }
}));

export default function PlanItWidget() {
    const classes = useStyles();
    useTranslation();

    const isLargeCustomerAccount = useTypedSelector<boolean>(selectIsLargeCustomerAccount);

    const planItIcon = <AssignmentOutlinedIcon fontSize="large" className={classes.altIcon} />;
    const planItLink = isLargeCustomerAccount ? '/plan-it-bulk-upload' : '/plan-it';
    const planItSummaryLink = '/plan-it-summary';

    return (
        <Paper elevation={2} className={classes.paper}>
            <Grid container item alignItems="flex-start" data-testid="plan-it-widget">
                <Grid item xs={12} className={classes.iconContainer}>
                    <Grid item xs={12} className={classes.icon}>
                        {planItIcon}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h3" className={classes.header}>
                        <Trans i18nKey="planIt">Plan It</Trans>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container item>
                        <Grid item xs={4} className={classes.subContainer}>
                            <Typography variant="subtitle1" className={classes.subHeader}>
                                <Trans i18nKey="submitPlanIt">
                                    ${enUS.submitPlanIt}
                                </Trans>
                            </Typography>
                            <Typography className={classes.subHeaderDetails}>
                                <Trans i18nKey="planItDashboardDesc">
                                    ${enUS.planItDashboardDesc}
                                </Trans>
                            </Typography>
                            <Link
                                to={planItLink}
                                underline="none"
                                component={RouterLink}
                                data-testid="create-update-plan-it-link"
                                className={classes.link}
                            >
                                <Trans i18nKey="createPlanItLink">${enUS.createPlanItLink}</Trans>
                            </Link>
                        </Grid>
                        <Grid item xs={4} className={classes.subContainer}>
                            <Typography variant="subtitle1" className={classes.subHeader}>
                                <Trans i18nKey="planItSummary">
                                    ${enUS.planItSummary}
                                </Trans>
                            </Typography>
                            <Typography className={classes.subHeaderDetails}>
                                <Trans i18nKey="planItSummaryDashboardDesc">
                                    ${enUS.planItSummaryDashboardDesc}
                                </Trans>
                            </Typography>
                            <Link
                                to={planItSummaryLink}
                                underline="none"
                                component={RouterLink}
                                data-testid="plan-it-summary-link"
                                className={classes.link}
                            >
                                <Trans i18nKey="viewPlanItSummary">
                                    ${enUS.viewPlanItSummary}
                                </Trans>
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
}
