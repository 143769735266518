import { makeStyles, Grid, Button } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import { addProductToForecastOrder } from '../../../../store/actions/planit-bulk-atm';
import { BulkUploadMakeItATMState } from '../../../../store/reducers/makeit-bulk-atm';
import { MakeItBulkLineItem } from '../../../../store/reducers/makeit-bulk-upload';
// import { OrderProductTableRow } from '../../../../store/reducers/orders-dashboard';
import {
    BulkUploadPlanItATMState,
    PlanItBulkATMLineItem
} from '../../../../store/reducers/planit-bulk-atm';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { addProductToAtmOrder } from '../../../../store/root-actions';
import {
    ballBlue,
    blackWeight,
    boldWeight,
    dateOptionInvalidGray
} from '../../../../themes/globalConstants';
import { generateKey } from '../../../../utility/helpers/order-helpers';
import TranslationService from '../../../../utility/services/translation-service';
// import { getLineIsWithinCampaign } from '../../../pages/MakeIt/utilities/make-it-utils';
import { useMakeItBulkReviewATM } from '../../../pages/MakeItBulkReviewATM/context/MakeItBulkReviewATMContext';
import MakeItBulkReviewATMAddProductColumns from './MakeItBulkReviewATMAddProductColumns';
import MakeItBulkReviewATMAddProductRow from './MakeItBulkReviewATMAddProductRow';

interface Props {
    products?: MakeItBulkLineItem[];
    handleCloseProductGrid: () => void;
    snoSku: string;
    isPlanIt?: boolean;
    hasMultiplePersonas?: boolean;
}

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        '& .MuiTable-root': {
            tableLayout: 'fixed'
        },
        '& .MuiToolbar-root': {
            padding: 0,
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                },
                '&:first-of-type ': {
                    paddingLeft: 0
                }
            },
            '& > h2': {
                fontSize: '1.5em',
                fontWeight: boldWeight,
                color: theme.palette.secondary.main,
                paddingLeft: '0.625em'
            },
            '& .MuiInputBase-formControl': {
                border: `1px solid ${dateOptionInvalidGray}`,
                borderRadius: '.125em',
                '&:before': {
                    display: 'none'
                },
                '&:after': {
                    display: 'none'
                },
                '& svg': {
                    height: '.875em',
                    width: '.875em',
                    fill: ballBlue
                },
                '& .MuiIconButton-root': {
                    '&:hover': {
                        backgroundColor: 'transparent !important'
                    }
                },
                '& .MuiInput-input': {
                    paddingLeft: '.25em'
                }
            }
        },
        '& .MuiTableSortLabel-root': {
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em',
            width: '99%',
            justifyContent: 'space-between',
            '& .MuiSvgIcon-root': {
                fontSize: 14,
                opacity: '1'
            }
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                '&:nth-child(1)': {
                    width: '20%'
                },
                '&:nth-child(2)': {
                    width: '40%'
                },
                '&:nth-child(3)': {
                    width: '15%'
                },
                '&:nth-child(4)': {
                    width: '15%'
                },
                '&:nth-child(5)': {
                    width: '10%'
                }
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    },
    actionsBar: {
        marginTop: '1.250em'
    },
    actionBtn: {
        borderRadius: 'unset'
    },
    btnWrapper: {
        '& button': {
            '&:not(:last-child)': {
                marginRight: '1.125em'
            }
        }
    }
}));

const MakeItBulkReviewATMAddProductGrid = ({
    products,
    handleCloseProductGrid,
    snoSku,
    isPlanIt,
    hasMultiplePersonas
}: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [columns, setColumns] = useState<any>([]);
    const [availableProducts, setAvailableProducts] = useState<MakeItBulkLineItem[]>([]);
    const [selectedProducts, setSelectedProducts] = useState<string[]>([]);

    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { activeDate, shipToId } = useMakeItBulkReviewATM();
    const { atmOrders } = useTypedSelector<BulkUploadMakeItATMState>(
        (state) => state.bulkUploadMakeItATMState
    );
    const { forecastOrders } = useTypedSelector<BulkUploadPlanItATMState>(
        (state) => state.bulkUploadPlanIt
    );

    useEffect(() => {
        const orders = atmOrders?.length ? atmOrders : forecastOrders;
        if (orders) {
            const activeOrder = orders.find(
                (order) =>
                    moment(order.weekStart).valueOf() === moment(activeDate.fullDate).valueOf() &&
                    order.shipToId === shipToId
            );
            if (activeOrder) {
                setSelectedProducts(
                    activeOrder.lines
                        .filter((line) => line.snoSku === snoSku && !line.deleted)
                        .map((line) => line.displayId!)
                );
            }
        }
    }, [activeDate.fullDate, atmOrders, forecastOrders, shipToId, snoSku]);

    useEffect(() => {
        setColumns(
            MakeItBulkReviewATMAddProductColumns.getColumns(cultureCode, hasMultiplePersonas)
        );
    }, [cultureCode, hasMultiplePersonas]);

    useEffect(() => {
        if (products) {
            setAvailableProducts(products);
        }
    }, [products]);

    const CustomToolbarOptions = new ToolbarOptions({
        title: TranslationService.getTranslatedText(cultureCode, 'addProducts'),
        exportButton: false,
        printButton: false,
        advancePagination: false,
        searchText: true,
        rowsPerPageOptions: [5, 10, 20],
        itemsPerPage: 5
    });

    const handleAddSelectedProduct = (item: MakeItBulkLineItem) => {
        atmOrders?.length
            ? dispatch(addProductToAtmOrder(activeDate, shipToId, item))
            : dispatch(
                  addProductToForecastOrder(activeDate, shipToId, item as PlanItBulkATMLineItem)
              );
    };

    // returns true if requested week falls within the lead time for the product
    const withinLeadTime = (row: MakeItBulkLineItem) => {
        let productLeadTime = row.leadTimeWeeks ?? 2;
        let todayPlusLeadTime = moment().add(productLeadTime, 'weeks');
        let requestedDate = moment(activeDate.fullDate);
        let isWithinLeadTime = todayPlusLeadTime.isAfter(requestedDate);
        return isWithinLeadTime;
    };

    const ProductRow = ({ row }: { row: MakeItBulkLineItem }) => {
        // const isCampaignAndOutsideOrderingWindow = !getLineIsWithinCampaign(
        //     row as OrderProductTableRow
        // );

        return (
            <MakeItBulkReviewATMAddProductRow
                key={row.productSku}
                item={row}
                addDisabled={
                    selectedProducts.includes(row.displayId!) || (!isPlanIt && withinLeadTime(row))
                }
                hasMultiplePersonas={hasMultiplePersonas}
                handleAddSelectedProduct={handleAddSelectedProduct}
                isCampaignAndOutsideOrderingWindow={false}
            />
        );
    };

    return (
        <Grid
            container
            className={classes.tableWrapper}
            key={generateKey('makeit-bulk-atm-add-product')}
            data-testid="make-it-bulk-review-atm-product-grid"
        >
            <DataGrid
                columns={columns}
                dataSource={availableProducts}
                gridName="Make It Bulk Add Product Grid"
                rowComponent={ProductRow}
                toolbarOptions={CustomToolbarOptions}
            />
            <Grid container item xs={12} justify="flex-end" className={classes.actionsBar}>
                <Grid item className={classes.btnWrapper}>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        data-testid="close-btn"
                        className={classes.actionBtn}
                        onClick={handleCloseProductGrid}
                        disabled={false}
                    >
                        <Trans i18nKey="close">Close</Trans>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default MakeItBulkReviewATMAddProductGrid;
