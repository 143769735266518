import React, { useEffect, useState } from 'react';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { Grid, makeStyles, Typography, Paper, Button } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { blackWeight, ballGray, containerMaxWidth } from '../../../themes/globalConstants';
import ProductionOrderDetailsSubheader from './components/ProductionOrderDetailsSubheader';
import { useHistory, useParams } from 'react-router-dom';
import { useTypedSelector } from '../../../store/reducers/reducer';
import {
    CustomerContextState,
    PaymentTerms,
    ProdOrderType
} from '../../../store/reducers/customer-context';
import { AuthState } from '../../../store/reducers/auth';
import { useDispatch } from 'react-redux';
import {
    loadLinkedDeliveryOrders,
    loadProductionOrder
} from '../../../store/actions/production-order-details';
import { getShipToAccounts } from '../../../store/actions/customer-context';
import ProductionOrderShareLinks from './components/ProductionOrderShareLinks';
import {
    Activity,
    hasAccess,
    Persona,
    SecurityContext,
    getSecurityLevel,
    SecurityLevel
} from '../../../utility/auth/useSecurity';
import ProductionOrderDetailsGrid from './components/ProductionOrderDetailsGrid';
import { DefaultQueryParams } from '../../../utility/helpers/query-helpers';
import { ProductionOrderDetailsState } from '../../../store/reducers/production-order-details';
import { Order_Status } from '../../../utility/services/orders-service';
import { selectCustomerAccount } from '../../../store/actions/customer-context';
import { setUseSummaryStoredSearch } from '../../../store/actions/make-it-summary';
import { selectIsLargeCustomerAccount } from './../../../store/selectors/index';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '2em',
        borderRadius: 4,
        maxWidth: containerMaxWidth
    },
    header: {
        marginTop: '1em',
        marginBottom: '2em'
    },
    subheader: {
        marginBottom: '1em'
    },
    title: {
        fontWeight: blackWeight,
        color: ballGray
    },
    actionBtn: {
        borderRadius: 'unset'
    },
    btnWrapper: {
        marginTop: '1.750em',
        marginBottom: '1.750em',
        '& button:last-child': {
            marginLeft: '1.25em'
        }
    }
}));

const ProductionOrderDetails = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const { id } = useParams<DefaultQueryParams>();
    const { shipToAccounts, selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { productionOrder, linkedDeliveryOrder } = useTypedSelector<ProductionOrderDetailsState>(
        (state) => state.productionOrderDetails
    );
    const isLargeCustomer = useTypedSelector<boolean>(selectIsLargeCustomerAccount);
    const auth = useTypedSelector<AuthState>((state) => state.auth);
    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);
    const [shipToName, setShipToName] = useState<string>('');
    const [accountId, setAccountId] = useState<string>('');
    const [shipToAddress, setDisplayAddress] = useState<string>('');
    const [showEditButton, setShowEditButton] = useState<boolean>(false);
    const [showCustomerPO, setShowCustomerPO] = useState<boolean>(true);
    const [ciaCustomer, setCiaCustomer] = useState<boolean>(false);
    const [isCoPacker, setIsCoPacker] = useState<boolean>(true);
    const [hasShipItPermissions, setHasShipItPermissions] = useState<boolean>(false);
    const handleBackNavigation = () => {
        history.goBack();
    };

    const handleEditClicked = () => {
        if (productionOrder) {
            if (isLargeCustomer) {
                history.push(`/edit-production-order/${productionOrder.productionOrderId}`);
            } else {
                history.push(`/make-it/${productionOrder.productionOrderId}`);
            }
        }
    };

    const handleShipItClicked = () => {
        if (productionOrder?.shipToId) {
            dispatch(selectCustomerAccount(productionOrder.shipToId.toString()));
            history.push(`/ship-it`);
        }
    };

    const disableShipItButton = (): boolean => {
        const orderHasOpenStatus = productionOrder?.status === Order_Status.Open;
        return !(orderHasOpenStatus && hasShipItPermissions);
    };

    useEffect(() => {
        if (id) {
            dispatch(loadProductionOrder(Number(id)));
            dispatch(loadLinkedDeliveryOrders(Number(id)));
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (permissions) {
            if (!shipToAccounts?.length) {
                dispatch(getShipToAccounts());
            }
            if (accountId) {
                let context: SecurityContext = { accountId: accountId };
                setIsCoPacker(hasAccess([Persona.CoPacker], auth, context));
            }
        }
    }, [dispatch, shipToAccounts, permissions, accountId]);

    useEffect(() => {
        if (productionOrder) {
            if (selectedAccountId && shipToAccounts) {
                shipToAccounts.forEach((shipTo) => {
                    if (
                        shipTo.accountId === productionOrder.shipToId.toString() &&
                        shipTo.paymentTerms === PaymentTerms.CIA
                    ) {
                        setCiaCustomer(true);
                    }
                });
            }
            setShowEditButton(productionOrder.status === Order_Status.Open);
        }
    }, [productionOrder, shipToAccounts]);

    // If going back to the Make-It Summary page, use the stored search to repopulate the search bar
    useEffect(() => {
        return () => {
            if (history.location.pathname.includes('make-it-summary')) {
                dispatch(setUseSummaryStoredSearch(true));
            }
        };
    }, [history]);

    useEffect(() => {
        if (productionOrder) {
            const securityContext = { accountId: `${productionOrder.shipToId}` };

            const shipItSecurityLevel = getSecurityLevel(
                Activity.NewOpenDeliveryOrders,
                auth,
                securityContext
            );

            setHasShipItPermissions(shipItSecurityLevel === SecurityLevel.Edit);
        }
    }, [productionOrder, auth]);

    const setShippingInformation = (accountInformation) => {
        if (accountInformation[0]) {
            setShipToName(accountInformation[0].name);
            setAccountId(accountInformation[0].accountId);
            if (accountInformation[0].address) {
                const shipToAddress = accountInformation[0].address;
                setDisplayAddress(
                    shipToAddress.address1?.trimEnd() +
                        ', ' +
                        shipToAddress.city +
                        ', ' +
                        shipToAddress.stateProvince +
                        ' ' +
                        shipToAddress.postalCode
                );
            }
            if (
                accountInformation[0].prodOrderType &&
                accountInformation[0].prodOrderType === ProdOrderType.AuthorizationToManufacture
            ) {
                setShowCustomerPO(false);
            }
        }
    };

    if (!shipToAddress.length && !shipToName.length) {
        if (productionOrder && shipToAccounts) {
            setShippingInformation(
                shipToAccounts.filter(
                    (account) => account.accountId === productionOrder.shipToId.toString()
                )
            );
        }
    }

    const footerActions = (
        <>
            {showEditButton && !ciaCustomer ? (
                <>
                    <Grid item xs={2}>
                        <Button
                            type="button"
                            color="secondary"
                            variant="outlined"
                            data-testid="dashboard-btn"
                            className={classes.actionBtn}
                            onClick={handleBackNavigation}
                        >
                            <Trans i18nKey="goBack">Go Back</Trans>
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            data-testid="edit-production-order-btn"
                            className={classes.actionBtn}
                            onClick={handleEditClicked}
                            disabled={isCoPacker}
                        >
                            <Trans i18nKey="editProductionOrder">Edit Production Order</Trans>
                        </Button>
                    </Grid>
                </>
            ) : (
                <Grid item>
                    <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        data-testid="dashboard-btn"
                        className={classes.actionBtn}
                        onClick={handleBackNavigation}
                    >
                        <Trans i18nKey="goBack">Go Back</Trans>
                    </Button>
                </Grid>
            )}
        </>
    );

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('makeIt', 'Make It'),
                description: t('productionOrderDetails', 'Production Order Details'),
                thinBanner: true
            }}
            actionFooter={{
                footerAction: footerActions,
                justify: showEditButton && !ciaCustomer ? 'space-between' : 'center',
                sticky: true
            }}
            activity={Activity.MakeIt}
        >
            <Grid container item md={12}>
                <Grid container item xs={12} justify="flex-end" className={classes.btnWrapper}>
                    <Button
                        type="button"
                        color="primary"
                        variant="outlined"
                        data-testid="ship-it-button"
                        className={classes.actionBtn}
                        onClick={handleShipItClicked}
                        disabled={disableShipItButton()}
                    >
                        <Trans i18nKey="shipIt">Ship It</Trans>
                    </Button>
                </Grid>
            </Grid>
            <Paper elevation={2} className={classes.paper}>
                <Grid container spacing={2} align-items="flex-start">
                    <Grid container alignItems="baseline" className={classes.header}>
                        <Grid container item xs={9} justify="flex-start">
                            {showCustomerPO && (
                                <Typography variant="h3" className={classes.title}>
                                    <Trans i18nKey="customerPO">Customer PO</Trans>
                                    {' #'}
                                    {productionOrder?.customerProductionOrderId}
                                </Typography>
                            )}
                            {!showCustomerPO && (
                                <Typography variant="h3" className={classes.title}>
                                    <Trans i18nKey="ballOrderNumber">Ball Order #</Trans>
                                    {productionOrder?.productionOrderNumber}
                                </Typography>
                            )}
                        </Grid>
                        <Grid container item xs={3} justify="flex-end">
                            <ProductionOrderShareLinks
                                shipToAddress={shipToAddress}
                                shipToName={shipToName}
                                order={productionOrder}
                                lines={productionOrder?.lines}
                                linkedDeliveryOrder={linkedDeliveryOrder}
                                showCustomerPO={showCustomerPO}
                            />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.subheader}>
                        <ProductionOrderDetailsSubheader
                            shipToAddress={shipToAddress}
                            shipToName={shipToName}
                            order={productionOrder}
                            showCustomerPO={showCustomerPO}
                        />
                    </Grid>
                </Grid>
                {linkedDeliveryOrder && (
                    <ProductionOrderDetailsGrid
                        lines={productionOrder?.lines}
                        linkedDeliveryOrder={linkedDeliveryOrder}
                    />
                )}
            </Paper>
        </ProcessingPageTemplate>
    );
};

export default ProductionOrderDetails;
