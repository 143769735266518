import React from 'react';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { ballGray, black, medium, small } from '../../../../themes/globalConstants';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import WarningAlert from '../../../reusable/atoms/WarningAlert';
import { MakeItATMSummaryState } from '../../../../store/reducers/make-it-atm-summary';
import { BulkUploadMakeItState } from '../../../../store/reducers/makeit-bulk-upload';
import { useDispatch } from 'react-redux';
import { resetATMProductionOrderState } from '../../../../store/actions/atm-production-order-details';
import { loadEditATMOrder, resetAllocationsState } from '../../../../store/root-actions';
import { MAKEIT_BULK_ATM_EDIT_ORDER_LOADING } from '../../../../store/actions/action-types';
import { MakeItATMOrderDetailsState } from '../../../../store/reducers/atm-production-order-details';
import { useHistory } from 'react-router';
import moment from 'moment';

interface Props {
    attributeDescription: string;
    secondaryAttributes: string;
    remainingBalance: number;
    onExpandAll: () => void;
    onCollapseAll: () => void;
    isEditable: boolean;
}

const useStyles = makeStyles((theme) => ({
    main: {
        borderTop: 'none',
        borderRadius: 'unset'
    },
    toolbarWrapper: {
        alignItems: 'center',
        '& .MuiGrid-container': {
            margin: 0
        }
    },
    toolbarLabel: {
        fontSize: small,
        textTransform: 'uppercase'
    },
    btn: {
        padding: 0,
        minWidth: 'auto'
    },
    subtitle: {
        color: ballGray
    },
    attributeDescription: {
        marginLeft: '1em'
    },
    updateATM: {
        color: black,
        fontSize: medium,
        maxHeight: '2em',
        paddingLeft: '0',
        marginLeft: '1em',
        textDecoration: 'underline'
    },
    messageText: {
        paddingTop: '0.25em'
    }
}));

const MakeItATMOrderDetailsToolbar = ({
    attributeDescription,
    secondaryAttributes,
    remainingBalance,
    onExpandAll,
    onCollapseAll,
    isEditable
}: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { orders } = useTypedSelector<MakeItATMSummaryState>((state) => state.makeItATMSummary);
    const { atmProductionOrder } = useTypedSelector<MakeItATMOrderDetailsState>(
        (state) => state.atmProductionOrderDetails
    );
    const { products } = useTypedSelector<BulkUploadMakeItState>(
        (state) => state.bulkUploadMakeItState
    );

    const resetLoadedOrder = () => {
        dispatch(resetATMProductionOrderState());
        dispatch(resetAllocationsState());
    };

    const handleEditClick = () => {
        resetLoadedOrder();
        if (orders && products && atmProductionOrder) {
            dispatch({ type: MAKEIT_BULK_ATM_EDIT_ORDER_LOADING });
            const orderToEdit = orders.find(
                (ord) => ord.productionOrderId === atmProductionOrder.productionOrderId
            );
            const otherOrdersSameWeek = orders.filter(
                (order) =>
                    moment(order.atmWeekStart).isSame(moment(orderToEdit?.atmWeekStart), 'day') &&
                    order.productionOrderId !== orderToEdit?.productionOrderId
            );
            if (orderToEdit) {
                dispatch(loadEditATMOrder(orderToEdit, otherOrdersSameWeek, products));
                history.push('/make-it-bulk-upload-po-review');
            }
        }
    };

    const warningMessage = (
        <Grid container justify="center">
            <Typography className={classes.messageText}>
                {isEditable ? (
                    <Trans i18nKey="underSupplyPlanWarningProduct">
                        You're below the available supply plan for this product group
                    </Trans>
                ) : (
                    <Trans i18nKey="underSupplyPlanWarningProductPast">
                        You were below the available supply plan for this product group
                    </Trans>
                )}
            </Typography>
            {isEditable ? (
                <Button
                    variant="text"
                    color="primary"
                    data-testid="edit-button"
                    className={classes.updateATM}
                    onClick={handleEditClick}
                    disabled={false}
                >
                    <Typography>
                        <Trans i18nKey="updateYourATM">Update your ATM</Trans>
                    </Typography>
                </Button>
            ) : null}
        </Grid>
    );

    const handleExpandAll = () => {
        onExpandAll();
    };

    const handleCollapseAll = () => {
        onCollapseAll();
    };

    useTranslation();

    return (
        <Grid container className={classes.toolbarWrapper}>
            <Grid container item xs={12} justify="flex-start" data-testid="warning-message">
                <WarningAlert showWarning={false} warningMessage={warningMessage} />
            </Grid>
            <Grid container item xs={6} justify="flex-start">
                <Grid item xs={5} className={classes.attributeDescription}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="productAttributes">Product Attributes</Trans>
                    </Typography>
                    <Typography className="classes.shipToText" data-testid="attribute-description">
                        {attributeDescription}
                    </Typography>
                    <Typography className="classes.shipToText" data-testid="secondary-attributes">
                        {secondaryAttributes}
                    </Typography>
                </Grid>
                {/* As logged in CP-4675 removing Remaining Balance until a future enhancement, CP-4677 */}
            </Grid>
            <Grid container item xs={6} justify="flex-end" alignItems="center" spacing={2}>
                <Grid item>
                    <Button
                        className={classes.btn}
                        color="primary"
                        onClick={handleExpandAll}
                        data-testid="expand-all"
                    >
                        <Trans i18nKey="expandAll">Expand All</Trans>
                    </Button>
                </Grid>
                <Grid item>
                    <Typography>|</Typography>
                </Grid>
                <Grid item>
                    <Button
                        className={classes.btn}
                        color="primary"
                        onClick={handleCollapseAll}
                        data-testid="collapse-all"
                    >
                        <Trans i18nKey="collapseAll">Collapse All</Trans>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default MakeItATMOrderDetailsToolbar;
