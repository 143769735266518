import { Grid, Typography, makeStyles, Collapse, Paper, Button } from '@material-ui/core';
import React, { useEffect, useState, useCallback } from 'react';
import {
    BulkUploadMakeItATMState,
    MakeItBulkATMLineItem,
    MakeItBulkATMLinesBySnoSku
} from '../../../../store/reducers/makeit-bulk-atm';
import {
    small,
    boldWeight,
    blackWeight,
    large,
    black,
    successGreen,
    medium,
    xl,
    lato,
    ballDrkBlue,
    orange,
    ballBlue
} from '../../../../themes/globalConstants';
import clsx from 'clsx';
import { Trans, useTranslation } from 'react-i18next';
import MakeItReviewOrderSectionProducts from './MakeItReviewOrderSectionProducts';
import {
    AllocationQuantityState,
    evaluateAllocationQuantityValid
} from '../../../../utility/helpers/make-it-bulk-helpers';
import {
    generateKey,
    isProductEndType,
    ToggleAll
} from '../../../../utility/helpers/order-helpers';
import { useDispatch } from 'react-redux';
import { updateExpand } from '../../../../store/actions/makeit-bulk-atm';
import WarningAlert from '../../atoms/WarningAlert';
import {
    formatNumberWithLocale,
    stringFormatterReplace
} from '../../../../utility/helpers/formatters';
import ErrorAlert from '../../atoms/ErrorAlert';
import { editShipmentUseStyles } from '../../../../utility/helpers/shipment-helpers';
import Link from '../../atoms/Link';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import {
    BulkUploadMakeItState,
    MakeItBulkLineItem
} from '../../../../store/reducers/makeit-bulk-upload';
import MakeItBulkReviewATMAddProductGrid from '../MakeItBulkAddProducts/MakeItBulkReviewATMAddProductGrid';
import { MakeItBulkATMOrderSectionHeader } from '../MakeItBulkATMOrderSectionHeader';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { getCustomerProductInformation } from '../../../../utility/helpers/production-order-helpers';
import { useMakeItBulkReviewATM } from '../../../pages/MakeItBulkReviewATM/context/MakeItBulkReviewATMContext';

interface Props {
    order: MakeItBulkATMLinesBySnoSku;
    shipToId: number;
    isActive: boolean;
    expand: ToggleAll;
    showPrevPalletQty: boolean;
    showCsvLineNumber: boolean;
    planItToMakeIt: boolean;
    showButtons?: boolean;
    onCopyGroup?: () => void;
    onMoveGroup?: () => void;
    onRemoveGroup?: () => void;
    makeItSpot?: boolean;
    isDraft?: boolean;
    editingOrder?: boolean;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: '1em',
        position: 'relative',
        '& h2': {
            fontSize: xl,
            fontFamily: lato,
            fontWeight: blackWeight,
            color: theme.palette.secondary.main
        }
    },
    quantityContainer: {
        alignContent: 'center'
    },
    quantityTitleBlack: {
        fontWeight: blackWeight,
        color: theme.palette.secondary.main
    },
    quantityBlack: {
        fontWeight: blackWeight,
        color: black
    },
    quantityBold: {
        fontWeight: boldWeight,
        color: black
    },
    largeFont: {
        fontSize: large
    },
    mediumFont: {
        fontSize: medium
    },
    icon: {
        width: '2em',
        height: '1.8em',
        position: 'absolute'
    },
    completedIcon: {
        fill: successGreen
    },
    warningContainer: {
        width: '95%'
    },
    addProductGrid: {
        width: '54.75em',
        margin: '0 auto 0 auto',
        display: 'none',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    showProductGrid: {
        display: 'block'
    },
    addProductLink: {
        fontSize: small,
        '&.enabled': {
            color: ballDrkBlue,
            cursor: 'pointer'
        }
    },
    subheaderPaper: {
        marginTop: '2em',
        padding: '1em',
        borderRadius: 2,
        width: '100%'
    },
    trash: {
        paddingRight: '0',
        minWidth: 'unset',
        '&:hover': {
            backgroundColor: 'white'
        },
        '& svg': {
            fill: orange
        }
    },
    groupActionButton: {
        fontSize: medium,
        '&:hover': {
            backgroundColor: 'transparent',
            color: ballBlue,
            outline: 'none'
        },
        '&:focus': {
            backgroundColor: 'transparent',
            color: ballBlue
        }
    }
}));

const MakeItReviewOrderSection = ({
    order,
    shipToId,
    isActive,
    expand,
    showPrevPalletQty,
    showCsvLineNumber,
    planItToMakeIt,
    showButtons = false,
    onCopyGroup,
    onMoveGroup,
    onRemoveGroup,
    makeItSpot,
    isDraft = false,
    editingOrder = false
}: Props) => {
    const classes = useStyles();
    const editShipmentClasses = editShipmentUseStyles();
    const { t } = useTranslation();
    const [openDrawer, setOpenDrawer] = useState<boolean>();
    const [allocationState, setAllocationState] = useState<AllocationQuantityState>(undefined);
    const [difference, setDifference] = useState<number>(0);
    const dispatch = useDispatch();
    const [product, setProduct] = useState<MakeItBulkATMLineItem>();
    const [addProducts, setAddProducts] = useState<boolean>(false);
    const { products } = useTypedSelector<BulkUploadMakeItState>(
        (state) => state.bulkUploadMakeItState
    );
    const { readonly } = useTypedSelector<BulkUploadMakeItATMState>(
        (state) => state.bulkUploadMakeItATMState
    );
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [enableAddProductButton, setEnableAddProductButton] = useState<boolean>(true);
    const { activeDate } = useMakeItBulkReviewATM();
    const [copyButtonDisabled, setCopyButtonDisabled] = useState<boolean>(true);
    const [moveButtonDisabled, setMoveButtonDisabled] = useState<boolean>(true);
    const [removeButtonDisabled, setRemoveButtonDisabled] = useState<boolean>(true);
    const [isEndType, setIsEndType] = useState<boolean>(
        order.lines.length > 0 && order.lines[0].type
            ? isProductEndType(order.lines[0].type)
            : false
    );

    useEffect(() => {
        if (products) {
            if (makeItSpot) {
                //Make It Spot always has lines in the order so we need to set isEndType
                //It is possible the else if statement could be removed for redding
                //And we never need to look to see if order.lines.length is equal to 0
                //However, did not want to remove it at this moment, as it could impact bulk upload
                const groupProducts = products.filter((p) => p.snoSku === order.snoSku);
                if (groupProducts.length > 0) {
                    setIsEndType(isProductEndType(groupProducts[0].type));
                }
            } else if (order.lines.length === 0) {
                // for orders with 0 lines, we need to determine the type from the product collection
                const groupProducts = products.filter((p) => p.snoSku === order.snoSku);
                if (groupProducts.length > 0) {
                    setIsEndType(isProductEndType(groupProducts[0].type));
                }
            }
        }
    }, [order, products, makeItSpot]);

    useEffect(() => {
        setCopyButtonDisabled(onCopyGroup === undefined);
        setMoveButtonDisabled(onMoveGroup === undefined);
        setRemoveButtonDisabled(onRemoveGroup === undefined);
    }, [onCopyGroup, onMoveGroup, onRemoveGroup]);

    const handleGroupRemovalDisable = () => {
        if (editingOrder) setRemoveButtonDisabled(true);
    };

    const handleToggleExpand = () => {
        dispatch(updateExpand(shipToId, order.snoSku, order.weekStart));
        if (openDrawer) {
            // close the product grid before collapsing
            setEnableAddProductButton(true);
            setAddProducts(false);
        }
        setOpenDrawer(!openDrawer);
    };

    const handleShowProductGrid = () => {
        setEnableAddProductButton(false);
        setAddProducts(true);
    };

    const handleCloseProductGrid = () => {
        setEnableAddProductButton(true);
        setAddProducts(false);
    };

    const getAvailableProducts = useCallback((): MakeItBulkLineItem[] | undefined => {
        if (products && order) {
            return products.filter((product) => {
                if (
                    product.snoSku === order.snoSku &&
                    product.destinations &&
                    product.destinations.filter((destination) => destination.shipToId === shipToId)
                        .length > 0 &&
                    !order.lines
                        .filter(
                            (line) =>
                                (line.originalCsvLineNumber || line.productionOrderLineId) &&
                                !line.deleted
                        )
                        .map((line) => line.displayId)
                        .includes(product.displayId)
                ) {
                    // Adding check here to make sure we have the correct customer product information
                    const customerProductInformation = getCustomerProductInformation(
                        product,
                        shipToId
                    );
                    product.displayId = customerProductInformation.customerProductId
                        ? customerProductInformation.customerProductId
                        : product.productSku;
                    product.displayName = customerProductInformation.customerProductName
                        ? customerProductInformation.customerProductName
                        : product.name;
                    return product;
                }
                return null;
            });
        }
    }, [order, products, shipToId]);

    useEffect(() => {
        if (expand.toggle) {
            setOpenDrawer(true);
        } else if (!expand.toggle) {
            setOpenDrawer(false);
            setEnableAddProductButton(true);
            setAddProducts(false);
        }
    }, [expand]);

    useEffect(() => {
        setOpenDrawer(isActive);
    }, [isActive]);

    useEffect(() => {
        if (isEndType) {
            setAllocationState(undefined);
        } else if (order.allocationAmount !== undefined) {
            setAllocationState(evaluateAllocationQuantityValid(order));
            setDifference(Math.abs(order.totalPalletQuantity - order.allocationAmount));
        } else {
            setAllocationState(undefined);
        }
        if (order.lines.length) {
            setProduct(order.lines[0]);
        } else {
            let matchingProduct = getAvailableProducts()?.[0] as MakeItBulkATMLineItem;
            matchingProduct && setProduct(matchingProduct);
        }
    }, [getAvailableProducts, order, isEndType]);

    useEffect(() => {
        setEnableAddProductButton(true);
        setAddProducts(false);
    }, [activeDate]);

    function renderTotalText(isAlert: boolean) {
        return (
            <>
                <Typography
                    variant="subtitle2"
                    className={clsx(
                        isAlert ? classes.quantityBold : classes.quantityTitleBlack,
                        isAlert ? classes.mediumFont : classes.largeFont
                    )}
                >
                    {` ${t('totalEnteredQuantity', 'Total entered quantity')}:`}
                    &nbsp;
                    {`${formatNumberWithLocale(
                        cultureCode,
                        Number(order.totalPalletQuantity)
                    )} PL `}
                    {`${t('of', 'of')} `}
                    {`${formatNumberWithLocale(cultureCode, Number(order.allocationAmount))} PL`}
                </Typography>
            </>
        );
    }

    function getAllocationMetStatus() {
        return (
            <>
                {allocationState === 'valid' && (
                    <>
                        <Grid container item xs={11} justify="flex-end">
                            {renderTotalText(false)}
                        </Grid>
                        <Grid container item xs={1}>
                            <img
                                data-testid="complete-icon"
                                className={clsx(classes.icon, classes.completedIcon)}
                                src={process.env.PUBLIC_URL + '/assets/Confirmation_icon.svg'}
                                alt="Confirmation Icon"
                            />
                        </Grid>
                    </>
                )}
                {allocationState === 'belowAllocationWarning' && (
                    <>
                        <Grid item xs={9}>
                            <WarningAlert
                                warningMessage={''}
                                showWarning={true}
                                data-testid="warning-alert"
                            >
                                <Typography
                                    variant="subtitle2"
                                    className={clsx(classes.quantityBlack, classes.largeFont)}
                                >
                                    {difference === 1
                                        ? t('addPallet', 'Add 1 pallet to meet your supply plan')
                                        : stringFormatterReplace(
                                              t(
                                                  'addPallets',
                                                  'Add {i} pallets to meet your supply plan'
                                              ),
                                              `${formatNumberWithLocale(
                                                  cultureCode,
                                                  Number(difference)
                                              )}`
                                          )}
                                </Typography>

                                {renderTotalText(true)}
                            </WarningAlert>
                        </Grid>
                    </>
                )}
                {allocationState === 'aboveAllocationError' && (
                    <>
                        <Grid item xs={9}>
                            <ErrorAlert
                                errorMessage={''}
                                showError={true}
                                removeMargin={true}
                                dataTestId="error-alert-test-id"
                            >
                                <Grid container item xs={12}>
                                    <Typography
                                        variant="subtitle2"
                                        className={clsx(classes.quantityBlack, classes.largeFont)}
                                    >
                                        {difference === 1
                                            ? t(
                                                  'removePallet',
                                                  'Remove 1 pallet to meet your supply plan'
                                              )
                                            : stringFormatterReplace(
                                                  t(
                                                      'removePallets',
                                                      'Remove {i} pallets to meet your supply plan'
                                                  ),
                                                  `${formatNumberWithLocale(
                                                      cultureCode,
                                                      Number(difference)
                                                  )}`
                                              )}
                                    </Typography>
                                </Grid>
                                <Grid container item xs={12}>
                                    {renderTotalText(true)}
                                </Grid>
                            </ErrorAlert>
                        </Grid>
                    </>
                )}
            </>
        );
    }

    return (
        <Grid container>
            <Paper
                elevation={2}
                className={classes.subheaderPaper}
                key={`${shipToId}-${order.snoSku}`}
                data-testid="atm-order-section"
            >
                <Grid container item xs={12}>
                    <Grid container item xs={5} justify="flex-start" alignItems="center">
                        <MakeItBulkATMOrderSectionHeader
                            product={product}
                            shipToId={shipToId}
                            handleToggleExpand={handleToggleExpand}
                            openDrawer={openDrawer}
                            showCampaignPill={true}
                        />
                    </Grid>
                    <Grid
                        container
                        item
                        xs={showButtons ? 5 : 7}
                        justify="flex-end"
                        className={classes.quantityContainer}
                    >
                        {allocationState && getAllocationMetStatus()}
                        {(product as any)?.inactiveProduct && (
                            <ErrorAlert
                                errorMessage={t('inactiveProductError', {
                                    sku: product?.productSku
                                })}
                                showError={true}
                                removeMargin={true}
                                dataTestId="error-alert-inactive-product"
                            />
                        )}
                    </Grid>
                    {showButtons && (
                        <Grid
                            container
                            item
                            xs={2}
                            justify="flex-end"
                            className={classes.quantityContainer}
                        >
                            <Button
                                variant="text"
                                color="primary"
                                data-testid="copy-group-button"
                                className={classes.groupActionButton}
                                onClick={onCopyGroup}
                                disabled={copyButtonDisabled || readonly}
                            >
                                <Trans i18nKey="copy">Copy</Trans>
                            </Button>

                            <Button
                                variant="text"
                                color="primary"
                                data-testid="move-group-button"
                                className={classes.groupActionButton}
                                onClick={onMoveGroup}
                                disabled={moveButtonDisabled || readonly}
                            >
                                <Trans i18nKey="move">Move</Trans>
                            </Button>
                            <Button
                                type="button"
                                className={classes.trash}
                                onClick={onRemoveGroup}
                                data-testid="trash-icon"
                                disabled={removeButtonDisabled || readonly}
                            >
                                {removeButtonDisabled || readonly ? (
                                    <img
                                        src={
                                            process.env.PUBLIC_URL + '/assets/DisabledTrashIcon.svg'
                                        }
                                        alt="Disabled Trash Icon"
                                        data-testid="disabled-trash-icon"
                                    />
                                ) : (
                                    <img
                                        src={process.env.PUBLIC_URL + '/assets/Trash.svg'}
                                        alt="Trash Icon"
                                        data-testid="trash-icon"
                                    />
                                )}
                            </Button>
                        </Grid>
                    )}
                </Grid>
                <Collapse in={openDrawer} data-testid="collapse-section">
                    <Grid>
                        <MakeItReviewOrderSectionProducts
                            lines={order.lines}
                            showPrevPalletQty={showPrevPalletQty}
                            showCsvLineNumber={showCsvLineNumber}
                            planItToMakeIt={planItToMakeIt}
                            isDraft={isDraft}
                            handleGroupRemovalDisable={handleGroupRemovalDisable}
                        />
                        <Grid
                            container
                            justify="flex-end"
                            className={editShipmentClasses.actionsBar}
                        >
                            <Grid item className={editShipmentClasses.shipmentBtnContainer}>
                                <Link
                                    underline="always"
                                    data-testid="add-product-link"
                                    className={`${classes.addProductLink} ${
                                        enableAddProductButton ? 'enabled' : ''
                                    }`}
                                    onClick={handleShowProductGrid}
                                    disabled={!enableAddProductButton}
                                >
                                    {'+ '}
                                    <Trans i18nKey="addProduct">add product</Trans>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Collapse>
            </Paper>
            {addProducts && (
                <Grid container className={classes.root}>
                    <Grid
                        item
                        className={clsx(classes.addProductGrid, {
                            [classes.showProductGrid]: addProducts
                        })}
                        key={generateKey('add-product-grid')}
                    >
                        <MakeItBulkReviewATMAddProductGrid
                            snoSku={order.snoSku}
                            products={getAvailableProducts()}
                            handleCloseProductGrid={handleCloseProductGrid}
                        />
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default MakeItReviewOrderSection;
