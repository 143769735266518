import React from 'react';
import { makeStyles, Drawer, Grid, Typography } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import clsx from 'clsx';
import Facets from './Facets';
import { Trans, useTranslation } from 'react-i18next';
import Button from '../../../reusable/atoms/Button';

interface Props {}

const drawerWidth = '60em';

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingRight: '2em',
        paddingTop: '2em'
    },
    hide: {
        display: 'none'
    },
    filterBy: {
        margin: '0 0 1em 0'
    },
    chevron: {
        alignItems: 'center'
    },
    drawer: {
        width: drawerWidth
    },
    drawerOpen: {
        width: '15em',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        overflowX: 'scroll'
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1
    },
    facetsContainer: {
        marginLeft: '1em'
    }
}));

const MobileFacets = (props: Props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState<boolean | undefined>(false);
    useTranslation();

    const handleFilterOpen = () => {
        setOpen(true);
    };

    const handleFilterClose = () => {
        setOpen(false);
    };

    return (
        <Grid container>
            <Grid container item xs={12} justify="flex-start" className={classes.filterBy}>
                <Button
                    variant="text"
                    onClick={handleFilterOpen}
                    aria-label="filter-menu-toggle"
                    endIcon={
                        !open ? (
                            <ChevronRightIcon color="primary" />
                        ) : (
                            <ChevronLeftIcon color="primary" />
                        )
                    }
                >
                    <Typography variant="subtitle2">
                        <Trans i18nKey="filterBy">Filter By</Trans>
                    </Typography>
                </Button>
            </Grid>
            <Drawer
                anchor="left"
                open={open}
                onClose={handleFilterClose}
                variant="temporary"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open
                })}
                classes={{
                    paper: clsx(classes.paper, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open
                    })
                }}
            >
                {open && (
                    <Grid container className={classes.facetsContainer}>
                        <Grid item xs={12}>
                            <Facets />
                        </Grid>
                    </Grid>
                )}
            </Drawer>
        </Grid>
    );
};

export default MobileFacets;
