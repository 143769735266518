import { DataGrid, ToolbarOptions } from 'tubular-react';
import { Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import {
    ballBlue,
    black,
    blackWeight,
    boldWeight,
    dateOptionInvalidGray,
    planItGridName,
    small
} from '../../../../themes/globalConstants';
import {
    defaultPaginationRows,
    getGridElementValue,
    paginationOptions
} from '../../../../utility/helpers/grid-helpers';

import { LocalStorage } from 'tubular-common';
import PlanItColumns from './PlanItColumns';
import PlanItRow from './PlanItRow';
import PlanItToolbar from './PlanItToolbar';
import { OrderProductTableRow } from '../../../../store/reducers/orders-dashboard';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { useTypedSelector } from '../../../../store/reducers/reducer';

interface Props {
    makeItItems: OrderProductTableRow[];
    onAddItem: (order: OrderProductTableRow, addDisabled: boolean, removeAll: boolean) => void;
    onViewFiltersClick: (viewTypes: string[]) => void;
    onRangeFiltersClick: (rangeTypes: string[]) => void;
    resetPagination: boolean;
    onResetPagination: () => void;
    storage: LocalStorage;
    checkOutList: OrderProductTableRow[];
}

const useStyles = () =>
    makeStyles((theme) => ({
        tableWrapper: {
            width: '100%',
            marginTop: '1.5em',
            '& .MuiToolbar-root': {
                padding: '0 0.125em',
                '& > button': {
                    '&:hover': {
                        backgroundColor: 'transparent'
                    },
                    '&:last-of-type': {
                        display: 'none'
                    }
                },
                '& ::-ms-clear': {
                    display: 'none'
                },
                '& .MuiInputBase-formControl': {
                    border: `1px solid ${dateOptionInvalidGray}`,
                    borderRadius: '.125em',
                    '&:before': {
                        display: 'none'
                    },
                    '&:after': {
                        display: 'none'
                    },
                    '& svg': {
                        height: '.875em',
                        width: '.875em',
                        fill: ballBlue
                    },
                    '& .MuiIconButton-root': {
                        '&:hover': {
                            backgroundColor: 'transparent !important'
                        }
                    },
                    '& .MuiInput-input': {
                        paddingLeft: '.25em'
                    }
                }
            },
            '& .MuiTableSortLabel-root': {
                display: 'flex',
                justifyContent: 'space-between',
                fontSize: small,
                fontWeight: blackWeight,
                textTransform: 'uppercase',
                color: theme.palette.secondary.main,
                borderRadius: '0.25em',
                margin: '0 .125em 1.125em .125em',
                padding: '0.875em 1.125em',
                '& .MuiSvgIcon-root': {
                    fontSize: 14,
                    opacity: '1',
                    marginRight: '-0.375em'
                }
            },
            '& .MuiTableSortLabel-active': {
                backgroundColor: theme.palette.primary.main,
                color: 'white',
                '& .MuiSvgIcon-root': {
                    fill: 'white'
                }
            },
            '& .MuiTableHead-root': {
                '& th': {
                    borderLeft: 'none',
                    padding: 0,
                    '&:nth-child(1)': {
                        width: '14%'
                    },
                    '&:nth-child(2)': { width: '30%' },
                    '&:nth-child(3)': { width: '30%' },
                    '&:nth-child(4)': { width: '14%' },
                    '&:nth-child(5)': { width: '14%' }
                }
            }
        },
        '& .MuiTablePagination-root': {
            '& .MuiTablePagination-caption': {
                marginLeft: '1em',
                fontWeight: boldWeight,
                color: black
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    }));

export default function PlanItGrid({
    makeItItems,
    onAddItem,
    onViewFiltersClick,
    onRangeFiltersClick,
    resetPagination,
    onResetPagination,
    storage,
    checkOutList
}: Props) {
    const [columns, setColumns] = useState<any>([]);
    const [selectedPaginationRows, setSelectedPaginationRows] = useState<number>(0);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const classes = useStyles()();
    const showRangeFilter = false; // added with CP-2214, change to true or remove when we want to show RangeFilterToolbar
    const columnsKey = useMemo(() => JSON.stringify(columns), [columns]);

    const CustomToolbarOptions = new ToolbarOptions({
        customItems: (
            <PlanItToolbar
                onViewFiltersClicked={onViewFiltersClick}
                onRangeFiltersClicked={onRangeFiltersClick}
                showRangeFilter={showRangeFilter}
            />
        ),
        exportButton: false,
        printButton: false,
        advancePagination: false,
        rowsPerPageOptions: paginationOptions.rowsPerPage,
        itemsPerPage: selectedPaginationRows
    });

    const CustomerPlanItRow = ({ row }: { row: Partial<OrderProductTableRow> }) => {
        return (
            <PlanItRow
                item={{
                    ...row,
                    addDisabled: !!checkOutList.find((i) => i.productSku === row.productSku)
                }}
                onAddItem={onAddItem}
                getPaginationRows={getPaginationRows}
            />
        );
    };

    const getPaginationRows = () => {
        const gridElementValue = getGridElementValue('.MuiTablePagination-select');
        setSelectedPaginationRows(gridElementValue);
    };

    useEffect(() => {
        if (selectedPaginationRows === 0) {
            getPaginationRows();
        }

        if (resetPagination) {
            onResetPagination();
            if (selectedPaginationRows !== defaultPaginationRows) {
                setSelectedPaginationRows(defaultPaginationRows);
            }
        }
    }, [selectedPaginationRows, resetPagination, onResetPagination]);

    useEffect(() => {
        setColumns(PlanItColumns.getPlanItColumns(cultureCode, true, 'GUIDED'));
    }, [cultureCode]);

    return (
        <Grid container className={classes.tableWrapper} key={columnsKey}>
            <DataGrid
                columns={columns}
                dataSource={makeItItems}
                gridName={planItGridName}
                rowComponent={CustomerPlanItRow}
                toolbarOptions={CustomToolbarOptions}
                storage={storage}
            />
        </Grid>
    );
}
