import moment, { Moment } from 'moment';
import OrdersService, { Order_Status } from '../../utility/services/orders-service';
import { OrderProductTableRow } from '../reducers/orders-dashboard';
import { ProductionOrder } from '../reducers/makeit-dashboard';
import { GuidedOrder } from '../reducers/plan-it-guided';
import { QuantityUnit } from '../reducers/shipping-dashboard';
import {
    PLAN_IT_GUIDED_RESET,
    PLAN_IT_GUIDED_SET_PRODUCTS,
    PLAN_IT_GUIDED_UPDATE_QUANTITY_UNIT,
    PLAN_IT_GUIDED_SUBMIT_LOADING,
    PLAN_IT_GUIDED_SUBMIT_SUCCESS,
    PLAN_IT_GUIDED_SUBMIT_ERROR,
    PLAN_IT_GUIDED_ADD_WEEK,
    PLAN_IT_GUIDED_SET_CUSTOMER_PO,
    PLAN_IT_GUIDED_INITIZLIZE_WEEKS,
    PLAN_IT_GUIDED_TOGGLE_SELECTED_WEEK
} from './action-types';
import { atmBulkUploadResponseHelper } from './planit-bulk-atm';

export const updatePlanItProducts = (products: OrderProductTableRow[], week: string) => {
    return (dispatch, getState) => {
        const state = getState();
        const guidedOrders = state.planItGuided.guidedOrders;
        const updatedGuidedOrders = guidedOrders.map((order) => {
            if (order.week === week) {
                return { ...order, productsToOrder: products };
            } else {
                return order;
            }
        });
        dispatch({
            type: PLAN_IT_GUIDED_SET_PRODUCTS,
            guidedOrders: updatedGuidedOrders
        });
    };
};

export const updatePlanItGuidedQuantityUnit = (quantityUnit: QuantityUnit) => {
    return (dispatch: any, getState) => {
        const productsToOrder: Array<OrderProductTableRow> =
            getState().planItGuided.productsToOrder;
        const existingQuantityUnit = getState().planItGuided.quantityUnit;

        if (existingQuantityUnit !== quantityUnit) {
            dispatch({
                type: PLAN_IT_GUIDED_UPDATE_QUANTITY_UNIT,
                quantityUnit: quantityUnit,
                productsToOrder: productsToOrder
            });
        }
    };
};

export const updateCustomerPO = (customerPO: string, week: string) => {
    return (dispatch: any, getState) => {
        const state = getState();
        const guidedOrders: GuidedOrder[] = state.planItGuided.guidedOrders;
        const foundOrder = guidedOrders.find((order) => order.week === week);
        if (!!foundOrder) {
            const index = guidedOrders.indexOf(foundOrder);
            guidedOrders[index].customerProductionOrderId = customerPO;
            dispatch({
                type: PLAN_IT_GUIDED_SET_CUSTOMER_PO,
                guidedOrders: [...guidedOrders]
            });
        }
    };
};

export const addProductPlanItConfiguration = (product: OrderProductTableRow, week: string) => {
    return (dispatch: any, getState) => {
        const state = getState();
        const productsToOrder: Array<OrderProductTableRow> = state.planItGuided.productsToOrder;
        const guidedOrders: GuidedOrder[] = state.planItGuided.guidedOrders;
        const productToPush = product;
        const updatedGuidedOrders = guidedOrders.map((order) => {
            if (
                order.week === week &&
                !order.productsToOrder.find((p) => p.productSku === productToPush.productSku)
            ) {
                return {
                    ...order,
                    productsToOrder: [...order.productsToOrder].concat([productToPush])
                };
            } else {
                return order;
            }
        });
        dispatch({
            type: PLAN_IT_GUIDED_SET_PRODUCTS,
            productsToOrder: productsToOrder,
            guidedOrders: updatedGuidedOrders
        });
    };
};

export const addProductPlanItGuided = (product: OrderProductTableRow) => {
    return (dispatch: any, getState) => {
        const state = getState();
        const productsToOrder: Array<OrderProductTableRow> = state.planItGuided.productsToOrder;
        const guidedOrders: GuidedOrder[] = state.planItGuided.guidedOrders;
        const productToPush = product;
        let updatedProductsToOrder = productsToOrder.map((product) => product);
        if (!productsToOrder.find((p) => p.productSku === productToPush.productSku)) {
            updatedProductsToOrder = updatedProductsToOrder.concat([productToPush]);
        }

        const updatedGuidedOrders = guidedOrders.map((order) => {
            if (!order.productsToOrder.find((p) => p.productSku === productToPush.productSku)) {
                return {
                    ...order,
                    productsToOrder: [...order.productsToOrder].concat([productToPush])
                };
            } else {
                return order;
            }
        });
        dispatch({
            type: PLAN_IT_GUIDED_SET_PRODUCTS,
            productsToOrder: updatedProductsToOrder,
            guidedOrders: updatedGuidedOrders
        });
    };
};

export const removeAllPlanItGuided = (itemList: OrderProductTableRow[]) => {
    return (dispatch: any, getState) => {
        const state = getState();
        const productsToOrder = state.planItGuided.productsToOrder;
        const guidedOrders: GuidedOrder[] = state.planItGuided.guidedOrders;

        const removedProductSkus = itemList.map((item) => item.productSku);

        const updatedProductsToOrder = productsToOrder.filter(
            (product) => !removedProductSkus.includes(product.productSku)
        );

        const updatedGuidedOrders = guidedOrders.map((order) => {
            order.productsToOrder = order.productsToOrder.filter(
                (product) => !removedProductSkus.includes(product.productSku)
            );
            return order;
        });
        dispatch({
            type: PLAN_IT_GUIDED_SET_PRODUCTS,
            productsToOrder: updatedProductsToOrder,
            guidedOrders: updatedGuidedOrders
        });
    };
};

export const removeProductPlanItGuidedConfiguration = (
    product: OrderProductTableRow,
    week: string
) => {
    return (dispatch: any, getState) => {
        const state = getState();
        let productsToOrder: Array<OrderProductTableRow> = state.planItGuided.productsToOrder;
        const guidedOrders: GuidedOrder[] = state.planItGuided.guidedOrders;

        const updatedGuidedOrders = guidedOrders.map((order) => {
            if (order.week === week) {
                return {
                    ...order,
                    productsToOrder: order.productsToOrder.filter(
                        (productToOrder) => productToOrder.productSku !== product.productSku
                    )
                };
            } else {
                return order;
            }
        });
        dispatch({
            type: PLAN_IT_GUIDED_SET_PRODUCTS,
            productsToOrder: productsToOrder,
            guidedOrders: updatedGuidedOrders
        });
    };
};

export const removeProductPlanItGuided = (product: OrderProductTableRow) => {
    return (dispatch: any, getState) => {
        const state = getState();
        let productsToOrder: Array<OrderProductTableRow> = state.planItGuided.productsToOrder;
        const guidedOrders: GuidedOrder[] = state.planItGuided.guidedOrders;
        const updatedProductsToOrder = productsToOrder.filter(
            (productToOrder) => productToOrder.productSku !== product.productSku
        );
        const updatedGuidedOrders = guidedOrders.map((order) => {
            return {
                ...order,
                productsToOrder: order.productsToOrder.filter(
                    (productToOrder) => productToOrder.productSku !== product.productSku
                )
            };
        });
        dispatch({
            type: PLAN_IT_GUIDED_SET_PRODUCTS,
            productsToOrder: updatedProductsToOrder,
            guidedOrders: updatedGuidedOrders
        });
    };
};

export const resetPlanItGuidedState = () => {
    return (dispatch: any) => {
        dispatch({
            type: PLAN_IT_GUIDED_RESET
        });
    };
};

export const addWeeksPlanItGuided = (week: Moment) => {
    return (dispatch, getState) => {
        const state = getState();
        const weeks: string[] = state.planItGuided.weeks;
        const formattedWeek = week.format('MM/DD/YYYY');
        const updatedWeeks = [...weeks];

        if (!weeks.find((wk) => wk === formattedWeek)) {
            updatedWeeks.push(week.format('MM/DD/YYYY'));
            updatedWeeks.sort((a, b) => moment(a).diff(moment(b)));
        }
        dispatch({
            type: PLAN_IT_GUIDED_ADD_WEEK,
            weeks: updatedWeeks
        });
    };
};

export const copyPreviousForecast = (productionOrder: ProductionOrder, requestedDate: string) => {
    return (dispatch, getState) => {
        const state = getState();
        const region = state.regionCulture.regionCode;
        const accessToken = state.auth.accessToken;
        const userName = state.auth.userInfo.preferred_username;
        const productsToOrder = state.planItGuided.productsToOrder;
        const orderStatus = Order_Status.Confirmed;
        const updatedRequestedDate = moment.utc(requestedDate).format('YYYY-MM-DDThh:mm:ss');
        const currentDate = moment.utc().format('YYYY-MM-DDThh:mm:ss');
        const shipToId = productionOrder.shipToId ?? 0;

        const order: any = productionOrder;

        order.lines.forEach((line) => {
            line.requestedDate = updatedRequestedDate;
            line.createDate = currentDate;
            line.updateDate = currentDate;
            line.shipToId = shipToId;
            delete line.productionOrderLineId;
        });

        const payload = [
            {
                createdBy: userName,
                submittedBy: userName,
                status: orderStatus,
                region: region,
                productionOrderId: undefined,
                shipToId: shipToId,
                lines: order.lines
            }
        ];

        OrdersService.submitPlanItBulkAtmUpload(payload, accessToken)
            .then((response) => {
                if (response.data?.orders?.length) {
                    atmBulkUploadResponseHelper(response.data.orders);
                    dispatch({
                        type: PLAN_IT_GUIDED_SUBMIT_SUCCESS,
                        productsToOrder,
                        forecastOrders: response.data.orders
                    });
                } else {
                    dispatch({ type: PLAN_IT_GUIDED_SUBMIT_ERROR });
                }
            })
            .catch((error) => {
                dispatch({ type: PLAN_IT_GUIDED_SUBMIT_ERROR });
            });
    };
};

export const submitForecastOrders = () => {
    return (dispatch, getState) => {
        const state = getState();
        const accessToken = state.auth.accessToken;
        const userName = state.auth.userInfo.preferred_username;
        const region = state.regionCulture.regionCode;
        const shipToId = state.customerContext.selectedAccountId;
        const guidedOrders = state.planItGuided.guidedOrders;

        dispatch({ type: PLAN_IT_GUIDED_SUBMIT_LOADING, guidedOrders: guidedOrders });

        let ordersToSubmit: any[] = [];

        guidedOrders.forEach((order) => {
            const lines = OrdersService.createMakeItOrderlines(order.productsToOrder);
            lines.forEach(
                (line) =>
                    (line.requestedDate =
                        moment.utc(order.week).format('YYYY-MM-DD') + 'T00:00:00.000Z')
            );
            const formattedOrder = {
                lines,
                shipToId: parseInt(shipToId),
                customerProductionOrderId: order.customerProductionOrderId ?? undefined,
                productionOrderId: order.productionOrderId ?? undefined,
                createdBy: userName,
                submittedBy: userName,
                status: Order_Status.Confirmed,
                region: region
            };
            ordersToSubmit.push(formattedOrder);
        });

        OrdersService.submitPlanItBulkAtmUpload(ordersToSubmit, accessToken)
            .then((response) => {
                if (response.data?.orders?.length) {
                    let forecasts: any[] = [];
                    atmBulkUploadResponseHelper(response.data.orders);
                    guidedOrders.forEach((order) => {
                        let foundForecast = response.data.orders.find(
                            (o) => moment.utc(o.atmWeekStart).format('MM/DD/YYYY') === order.week
                        );
                        if (!!foundForecast) {
                            foundForecast.lines.forEach((line) => {
                                if (!!line.productionOrderLineId) {
                                    let foundProduct = order.productsToOrder.find(
                                        (p) =>
                                            p.productionOrderLineId === line.productionOrderLineId
                                    );
                                    if (!!foundProduct) {
                                        line.originalPalletQuantity =
                                            foundProduct.originalPalletQuantity;
                                        line.originalEachesQuantity =
                                            foundProduct.originalEachesQuantity;
                                        line.eachesQuantity =
                                            line.palletQuantity * foundProduct.quantityPerPallet;
                                    }
                                }
                            });
                            forecasts.push(foundForecast);
                        }
                    });

                    dispatch({
                        type: PLAN_IT_GUIDED_SUBMIT_SUCCESS,
                        guidedOrders: guidedOrders,
                        forecastOrders: [...forecasts]
                    });
                } else {
                    dispatch({ type: PLAN_IT_GUIDED_SUBMIT_ERROR });
                }
            })
            .catch((error) => {
                dispatch({ type: PLAN_IT_GUIDED_SUBMIT_ERROR });
            });
    };
};

export const initializeAvailableWeeks = () => {
    return (dispatch: any) => {
        const initialWeeks: string[] = [];
        for (let i = 0; i < 5; i++) {
            const today = moment();
            initialWeeks.push(
                today
                    .add(i + 10, 'weeks')
                    .startOf('isoWeek')
                    .format('MM/DD/YYYY')
            );
        }

        dispatch({
            type: PLAN_IT_GUIDED_INITIZLIZE_WEEKS,
            weeks: initialWeeks
        });
    };
};

export const toggleWeekPlanItGuided = (week: Moment) => {
    return (dispatch, getState) => {
        const state = getState();
        const guidedOrders = state.planItGuided.guidedOrders;
        const selectedWeeks: string[] = state.planItGuided.selectedWeeks;
        const formattedWeek = week.format('MM/DD/YYYY');
        const productsToOrder = state.planItGuided.productsToOrder;

        let updatedSelectedWeeks: string[] = [];
        let updatedGuidedOrders: GuidedOrder[] = [];
        if (selectedWeeks.find((wk) => wk === formattedWeek)) {
            // unselect the week
            updatedSelectedWeeks = selectedWeeks.filter((wk) => wk !== formattedWeek);
            updatedGuidedOrders = guidedOrders.filter((order) => order.week !== formattedWeek);
        } else {
            // select the week
            updatedSelectedWeeks = selectedWeeks.map((wk) => wk);
            updatedGuidedOrders = guidedOrders.map((order) => order);
            updatedSelectedWeeks.push(formattedWeek);
            updatedGuidedOrders.push({
                week: formattedWeek,
                productsToOrder: productsToOrder.map((productToOrder) => productToOrder)
            });
            updatedSelectedWeeks.sort((a, b) => moment(a).diff(moment(b)));
            updatedGuidedOrders.sort((a, b) => moment(a.week).diff(moment(b.week)));
        }

        dispatch({
            type: PLAN_IT_GUIDED_TOGGLE_SELECTED_WEEK,
            selectedWeeks: updatedSelectedWeeks,
            guidedOrders: updatedGuidedOrders
        });
    };
};
