// TODO: When navigation bar is fully updated this file will be deprecated and can be removed in lieu of NavBarTile.tsx
import React, { useState, ReactNode } from 'react';
import { Button, Menu, makeStyles, Grid, Typography } from '@material-ui/core';
import { black_2, boldWeight, medium, containerMaxWidth } from '../../../themes/globalConstants';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { Region } from '../../../store/reducers/graphic-intake';
import clsx from 'clsx';
interface Props {
    menuName: ReactNode;
    menuItems?: JSX.Element[];
    children: ReactNode;
    testid?: string;
}

const useStyles = makeStyles((theme) => ({
    popoverPaper: {
        width: '100%',
        maxHeight: 'unset',
        maxWidth: 'unset',
        top: '4.5em !important',
        left: '0px !important',
        borderRadius: 0,
        boxShadow: `0 1px 4px 0 ${black_2}`,
        padding: '0 1em 1em 1em'
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    button: {
        fontSize: medium,
        fontWeight: boldWeight,
        letterSpacing: '1px',
        textTransform: 'capitalize',
        color: theme.palette.common.black
    },
    container: {
        maxWidth: containerMaxWidth,
        marginLeft: 'auto',
        marginRight: 'auto',
        height: '25.5em'
    },
    southAmericaContainer: {
        maxWidth: '95em',
        marginLeft: 'auto',
        marginRight: 'auto',
        height: '27.5em'
    },
    menuName: {
        fontSize: medium,
        fontWeight: boldWeight,
        color: theme.palette.secondary.main,
        marginTop: '1.8em',
        marginBottom: '0.5em'
    },
    menuNameBar: {
        border: `2px solid ${theme.palette.info.light}`,
        marginBottom: '1.5em'
    },
    navWrapper: {
        '&:focus': {
            outline: 'none'
        }
    },
    selected: {
        borderBottom: '4px solid #009bbb'
    }
}));

const NavMenu = ({ menuName, menuItems, children, testid }: Props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const classes = useStyles();
    const { regionCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <div className={clsx(classes.buttonContainer, { [classes.selected]: !!anchorEl })}>
                <Button
                    onClick={handleClick}
                    aria-haspopup="true"
                    className={classes.button}
                    data-testid={testid && `${testid}-nav-btn`}
                >
                    {menuName}
                </Button>
            </div>

            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                classes={{ paper: classes.popoverPaper }}
            >
                <section role="navigation" className={classes.navWrapper}>
                    <Grid
                        container
                        className={
                            regionCode === Region.SA
                                ? classes.southAmericaContainer
                                : classes.container
                        }
                    >
                        <Grid item xs={2}>
                            {!!menuItems && menuItems.length > 0 && (
                                <>
                                    <Typography className={classes.menuName}>{menuName}</Typography>
                                    <hr className={classes.menuNameBar} />
                                    {menuItems}
                                </>
                            )}
                        </Grid>
                        <Grid item xs={1} />
                        <Grid item xs={9}>
                            {children}
                        </Grid>
                    </Grid>
                </section>
            </Menu>
        </>
    );
};

export default NavMenu;
