import React, { useState } from 'react';
import {
    Grid,
    Typography,
    makeStyles,
    Paper,
    Switch,
    FormControlLabel,
    FormGroup
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
    boldWeight,
    large,
    shipAltGreen,
    small,
    toggleActivationBg,
    white
} from '../../../themes/globalConstants';

interface Props {
    isActivate: boolean;
    onChange: (state: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
    statusSwitchPaper: {
        backgroundColor: toggleActivationBg,
        padding: '12px 32px 12px 32px'
    },
    subheader: {
        fontSize: large,
        fontWeight: boldWeight
    },
    toggleControl: {
        '& .MuiTypography-body1': {
            fontWeight: boldWeight,
            fontSize: small,
            textTransform: 'uppercase'
        },
        '& .MuiSwitch-thumb': {
            backgroundColor: white
        },
        '& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track': {
            backgroundColor: shipAltGreen,
            opacity: 1
        }
    }
}));

const ProductStatusToggle = ({ isActivate, onChange }: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [checked, setChecked] = useState(!isActivate);

    const switchHandler = (event) => {
        setChecked(event.target.checked);
        onChange(event.target.checked);
    };

    return (
        <Paper className={classes.statusSwitchPaper}>
            <Grid container>
                <Grid item xs={10}>
                    <Typography className={classes.subheader}>
                        {isActivate
                            ? t('activateProduct', 'Activate Product')
                            : t('deactivateProduct', 'Deactivate Product')}
                    </Typography>
                    <Typography>
                        {isActivate
                            ? t(
                                  'activateHelper',
                                  'Requests to activate product will be submitted and a notification will be sent to alert of status. Product will remain inactive until approval sent.'
                              )
                            : t(
                                  'deactivateHelper',
                                  'Requests to deactivate product will be submitted and a notification will be sent to alert of status. Product will remain active until approval sent.'
                              )}
                    </Typography>
                </Grid>
                <Grid item container xs={2} alignItems="center">
                    <Grid item>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        defaultChecked={!isActivate}
                                        onChange={switchHandler}
                                        color="primary"
                                        data-testid="toggle-switch"
                                    />
                                }
                                label={checked ? t('active') : t('inactive')}
                                labelPlacement="bottom"
                                className={classes.toggleControl}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default ProductStatusToggle;
