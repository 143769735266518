import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../store/reducers/reducer';
import { useEffect } from 'react';
import { RegionCultureState } from '../../store/reducers/region-culture';

const TranslationHandler = () => {
  const { cultureCode } = useTypedSelector<RegionCultureState>(state => state.regionCulture);
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(cultureCode);
  }, [cultureCode, i18n]);

  return null;
};

export default TranslationHandler;
