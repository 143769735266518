import React from 'react';
import Modal from '../../../reusable/molecules/Modal';
import {
    AppBar,
    Grid,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Toolbar,
    Typography
} from '@material-ui/core';
import Button from '../../../reusable/atoms/Button';
import { Trans, useTranslation } from 'react-i18next';
import {
    blackWeight,
    boldWeight,
    medium,
    regularExtraWeight,
    regularWeight,
    small,
    white,
    xs
} from '../../../../themes/globalConstants';
import { generateKey } from '../../../../utility/helpers/order-helpers';
import { ErrorCondition, SubmitStatus } from '../models/WeekStatus';
import moment from 'moment';

interface Props {
    open: boolean;
    onClose: () => void;
    onSubmit: () => void;
    orderSubmitStatus: SubmitStatus[];
}

const useStyles = makeStyles((theme) => ({
    modalBtn: {
        height: '3.3em',
        width: '10em',
        marginRight: '3em'
    },
    buttonGrid: {
        paddingTop: '1em',
        paddingBottom: '1em'
    },
    modalContainer: {
        paddingRight: '0.5em',
        height: '25em',
        overflowY: 'scroll',
        overflowX: 'hidden',
        width: '50em',
        marginLeft: '2em'
        //marginTop: '2em'
    },
    errorWeeks: {
        fontStyle: 'italic'
    },
    not: {
        fontWeight: blackWeight
    },
    modalMessageContainter: {
        height: '1.25em',
        marginTop: '1em',
        marginBottom: '0.5em'
    },
    errorMessageContainer: {
        marginTop: '1em',
        backgroundColor: '#f7f7f7'
    },
    appBar: {
        top: 'auto',
        bottom: 0,
        width: '100%'
    },
    toolBar: {
        backgroundColor: white
    },
    columnHeaders: {
        fontSize: medium,
        fontWeight: boldWeight,
        width: '50%'
    },
    shipToCol: {
        fontSize: small,
        fontWeight: regularExtraWeight
    },
    weekCol: {
        fontSize: small,
        fontWeight: regularWeight
    },
    errorReason: {
        fontSize: xs,
        fontWeight: boldWeight,
        fontStyle: 'italic'
    },
    errorWeekCol: {
        fontSize: small,
        fontWeight: regularWeight,
        fontStyle: 'italic'
    },
    errorIcon: {
        marginTop: '0.75rem'
    },
    areYouSure: {
        fontSize: medium,
        fontWeight: boldWeight,
        marginTop: '1em',
        marginBottom: '0.5em'
    }
}));

const SubmitModal = ({ open, onClose, onSubmit, orderSubmitStatus }: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const modalTitle = t('confirmSubmission', 'Confirm Submission');
    const validOrders = orderSubmitStatus
        .filter(
            (shipTo) =>
                shipTo.weeks.length > 0 &&
                shipTo.weeks.some((week) => week.errorConditions.length === 0)
        )
        .map((order) => {
            return {
                shipToId: order.shipToId,
                shipToName: order.shipToName,
                weeks: order.weeks
                    .filter((week) => week.errorConditions.length === 0)
                    .sort((a, b) => moment(a.week).valueOf() - moment(b.week).valueOf())
            };
        })
        .sort((a, b) => a.shipToName?.localeCompare(b.shipToName));
    const invalidOrders = orderSubmitStatus
        .filter(
            (shipTo) =>
                shipTo.weeks.length > 0 &&
                shipTo.weeks.some((week) => week.errorConditions.length !== 0)
        )
        .map((order) => {
            return {
                shipToId: order.shipToId,
                shipToName: order.shipToName,
                weeks: order.weeks
                    .filter((week) => week.errorConditions.length !== 0)
                    .sort((a, b) => moment(a.week).valueOf() - moment(b.week).valueOf())
            };
        })
        .sort((a, b) => a.shipToName?.localeCompare(b.shipToName));

    const getErrorMessage = (errorConditions: ErrorCondition[]) => {
        if (errorConditions.length > 1) {
            return t('multipleErrors', 'Multiple errors');
        } else {
            switch (errorConditions[0]) {
                case 'over':
                    return t('overSupplyPlan', 'Over supply plan');
                case 'noPurchaseOrderNumber':
                    return t('noCustomerPONumber', 'No Customer PO #');
                case 'belowMinimumQuantity':
                    return t('minQtyNotMet', 'Minimum order quantity not met');
                case 'quantityUndefined':
                    return t('productWithoutQuantity', 'Product without Quantity');
            }
        }
    };

    const handleCancel = () => {
        onClose();
    };
    const handleSubmit = () => {
        onSubmit();
    };

    const not = (
        <span className={classes.not}>
            <Trans i18nKey="not">not</Trans>
        </span>
    );

    return (
        <Modal open={open} title={modalTitle} close={handleCancel} closeIcon={true} maxWidth="xl">
            <Grid container className={classes.modalContainer}>
                {validOrders.length > 0 && (
                    <Grid item xs={12}>
                        <Grid
                            item
                            className={classes.modalMessageContainter}
                            data-testid="submit-makeit-for-following"
                        >
                            <Typography variant="body1">
                                <Trans i18nKey="submitMakeItForFollowing">
                                    You are about to submit Make It order(s) for the following:
                                </Trans>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Table
                                size="small"
                                aria-label="make it order table"
                                key={generateKey('makeit-orders-table')}
                                data-testid="makeit-orders-table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            colSpan={2}
                                            className={classes.columnHeaders}
                                            data-testid="ship-to"
                                        >
                                            <Trans i18nKey="shipTo">Ship To</Trans>
                                        </TableCell>
                                        <TableCell
                                            colSpan={2}
                                            className={classes.columnHeaders}
                                            data-testid="submit-weeks"
                                        >
                                            <Trans i18nKey="week">Week</Trans>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {validOrders &&
                                        validOrders.map((submittedWeek, index) => (
                                            <TableRow key={'submit-week-table-row_' + index}>
                                                <TableCell
                                                    colSpan={2}
                                                    className={classes.shipToCol}
                                                >
                                                    {`${submittedWeek.shipToName} - #${submittedWeek.shipToId}`}
                                                </TableCell>
                                                <TableCell colSpan={2} className={classes.weekCol}>
                                                    {submittedWeek.weeks
                                                        .map((w) => w.week)
                                                        .join(' ')}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                )}
                {invalidOrders.length > 0 && (
                    <Grid item xs={12} className={classes.errorMessageContainer}>
                        <Grid
                            item
                            className={classes.modalMessageContainter}
                            data-testid="submit-makeit-error-weeks"
                        >
                            <Typography variant="body1" className={classes.errorWeeks}>
                                <Trans i18nKey="submitMakeItErrorWeeks">
                                    Please note that the following week(s) are {not} included due to
                                    error(s):
                                </Trans>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Table
                                size="small"
                                aria-label="make it order table"
                                key={generateKey('makeit-orders-table')}
                                data-testid="makeit-orders-error-table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            colSpan={2}
                                            className={classes.columnHeaders}
                                            data-testid="error-ship-to"
                                        >
                                            <Trans i18nKey="shipTo">Ship To</Trans>
                                        </TableCell>
                                        <TableCell
                                            colSpan={2}
                                            className={classes.columnHeaders}
                                            data-testid="error-submit-weeks"
                                        >
                                            <Trans i18nKey="week">Week</Trans>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {invalidOrders &&
                                        invalidOrders.map((invalidOrder) =>
                                            invalidOrder.weeks.map((submittedWeek, index) => (
                                                <TableRow key={'submit-week-table-row_' + index}>
                                                    <TableCell colSpan={2}>
                                                        <Grid container alignContent="center">
                                                            <Grid
                                                                item
                                                                xs={1}
                                                                className={classes.errorIcon}
                                                            >
                                                                <img
                                                                    src={
                                                                        process.env.PUBLIC_URL +
                                                                        '/assets/Error_Icon.svg'
                                                                    }
                                                                    alt="Error Icon"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={11}>
                                                                <Grid container>
                                                                    <Grid container>
                                                                        <Typography
                                                                            className={
                                                                                classes.shipToCol
                                                                            }
                                                                        >
                                                                            {`${invalidOrder.shipToName} - #${invalidOrder.shipToId}`}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid container>
                                                                        <Typography
                                                                            className={
                                                                                classes.errorReason
                                                                            }
                                                                        >
                                                                            {getErrorMessage(
                                                                                submittedWeek.errorConditions
                                                                            )}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                    <TableCell
                                                        colSpan={2}
                                                        className={classes.errorWeekCol}
                                                    >
                                                        {submittedWeek.week}
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        )}
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                )}
                <Grid container>
                    <Grid item data-testid="are-you-sure-submit">
                        <Typography className={classes.areYouSure}>
                            <Trans i18nKey="areYouSureSubmit">
                                Are you sure you want to submit?
                            </Trans>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <AppBar className={classes.appBar} position="sticky" elevation={10}>
                <Toolbar className={classes.toolBar} variant="regular">
                    <Grid container className={classes.buttonGrid} justify="flex-end">
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleCancel}
                            className={classes.modalBtn}
                            data-testid="cancel-btn"
                        >
                            <Trans i18nKey="cancel">Cancel</Trans>
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleSubmit}
                            className={classes.modalBtn}
                            data-testid="add-btn"
                        >
                            <Trans i18nKey="submit">Submit</Trans>
                        </Button>
                    </Grid>
                </Toolbar>
            </AppBar>
        </Modal>
    );
};

export default SubmitModal;
