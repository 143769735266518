import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class ShipToAddressColumns {
    static getShipToColumns = (culture: string) => {
        return [
            createColumn('name', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'shipToName'),
                sortable: true,
                filterable: true,
                searchable: true
            }),
            createColumn('accountId', {
                dataType: ColumnDataType.String,
                searchable: true,
                visible: false
            }),
            createColumn('sortableAccountId', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'shipToId'),
                sortable: true,
                filterable: true,
                searchable: false
            }),
            createColumn('formattedAddress', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'shipToAddress'),
                searchable: true
            })
        ];
    };
}
