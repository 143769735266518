import { Grid } from '@material-ui/core';
import React from 'react';
import { Trans } from 'react-i18next';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { useDispatch } from 'react-redux';
import { resetManageProductsState } from '../../../store/actions/manage-products';
import {
    selectHasAdminBillTo,
    selectHasOrderingMakePermissions,
    selectHasPricingBillTo,
    selectHasTermsAndConditionsSummaryAccess
} from '../../../store/selectors';
import { FlagTypes, GetFeatureFlag } from '../../../utility/helpers/feature-flag';
import NavBarMenu from './NavBarMenu';
import NavBarTile from './NavBarTile';
interface Props {
    isUserAdmin: boolean;
    isVendor: boolean;
}

const UserAdminNav = ({ isUserAdmin, isVendor }: Props) => {
    const dispatch = useDispatch();
    const manageLocationsIcon = '/Manage_Locations_Nav_Icon.svg';
    const manageProductsIcon = '/Manage_Products_Nav_Icon.svg';
    const pricingIcon = '/Pricing_Nav_Icon.svg';
    const manageUsersIcon = '/Manage_Users_Nav_Icon.svg';
    const addUserIcon = '/Add_User_Nav_Icon.svg';
    const isPricingCustomer = useTypedSelector<boolean>(selectHasPricingBillTo);
    const isBillToAdmin = useTypedSelector<boolean>(selectHasAdminBillTo);
    const manageLocationsFeatureFlag = GetFeatureFlag(FlagTypes.ManageLocations);
    const showTermsAndConditions = useTypedSelector<boolean>(
        selectHasTermsAndConditionsSummaryAccess
    );
    const hasOrderingMakePermissions = useTypedSelector<boolean>(selectHasOrderingMakePermissions);

    const clearManageProductsState = () => {
        dispatch(resetManageProductsState());
    };

    return (
        <NavBarMenu
            menuName={<Trans i18nKey="admin">Admin</Trans>}
            sections={[]}
            testid="user-admin"
        >
            <Grid container>
                {!isVendor && (
                    <Grid item xs={4}>
                        <NavBarTile
                            link="/user-admin-dashboard"
                            translationKey="manageUsers"
                            defaultText="Manage Users"
                            icon={manageUsersIcon}
                            isProduct={false}
                            testId="user-admin-dashboard"
                            secondaryText="Check pending requests and registration and manage active users."
                            secondaryTranslationKey="checkPendingRequests"
                        />
                    </Grid>
                )}
                {manageLocationsFeatureFlag && isBillToAdmin && (
                    <Grid item xs={4}>
                        <NavBarTile
                            link="/manage-locations"
                            translationKey="manageLocations"
                            defaultText="Manage Locations"
                            icon={manageLocationsIcon}
                            isProduct={false}
                            testId={'manageLocationsCard'}
                            secondaryText="Monitor and request new locations."
                            secondaryTranslationKey="monitorAndRequest"
                        />
                    </Grid>
                )}
                {hasOrderingMakePermissions && (
                    <Grid item xs={4}>
                        <NavBarTile
                            link="/manage-products"
                            translationKey="manageProducts"
                            defaultText="Manage Products"
                            icon={manageProductsIcon}
                            isProduct={false}
                            onClick={clearManageProductsState}
                            testId={'manageProductsCard'}
                            secondaryText="View active and inactive products and manage product statuses."
                            secondaryTranslationKey="viewActiveAndInactiveProducts"
                        />
                    </Grid>
                )}
                {isUserAdmin && (
                    <Grid item xs={4}>
                        <NavBarTile
                            link="/add-new-user"
                            translationKey="addNewUser"
                            defaultText="Add New User"
                            icon={addUserIcon}
                            isProduct={false}
                            secondaryText="Give account access to new users."
                            secondaryTranslationKey="giveAccountAccess"
                        />
                    </Grid>
                )}
                {(isPricingCustomer || isBillToAdmin) && (
                    <Grid item xs={4}>
                        <NavBarTile
                            link="/pricing"
                            translationKey="pricing"
                            defaultText="Pricing"
                            icon={pricingIcon}
                            isProduct={false}
                            testId="pricing"
                            secondaryText="View pricing information. "
                            secondaryTranslationKey="viewPricingInformation"
                        />
                    </Grid>
                )}
                {showTermsAndConditions && (
                    <Grid item xs={4}>
                        <NavBarTile
                            link="/terms-and-pricing"
                            translationKey="termsAndConditions"
                            defaultText="Terms and Conditions"
                            icon={pricingIcon}
                            isProduct={false}
                            testId="terms-pricing-nav"
                            secondaryText="View terms and conditions."
                            secondaryTranslationKey="viewPricingTerms"
                        />
                    </Grid>
                )}
            </Grid>
        </NavBarMenu>
    );
};

export default UserAdminNav;
