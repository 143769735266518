import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Checkbox, Grid, Link, Popover, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { RoleAccessKey, ShipToRoleAccess } from './models/role-access.model';
import LocationRoleAccessTooltip from './LocationRoleAccessTooltip';
import {
    ballGreen,
    boldWeight,
    medium,
    small,
    yellowNoticeBackground,
    yellowNoticeBackgroundBorder,
    warningBorder,
    warningLabel
} from '../../../../themes/globalConstants';
import { Trans, useTranslation } from 'react-i18next';
import { enUS } from '../../../../utility/translations/locales';
import { Link as RouterLink } from 'react-router-dom';
import RequestAccessCommentSection from '../../../pages/RequestAccess/components/RequestAccessCommentSection';
import SearchBar from '../../atoms/SearchBar';
import { Persona } from '../../../../utility/auth/useSecurity';
import {
    UserAdminRoleRow,
    userAdminRolesTableRows
} from '../../../pages/UserAdminRolesAndAccess/components/user-admin-roles';
import {
    useLocationRoleAccessDispatch,
    useLocationRoleAccessState
} from './context/LocationRoleAccessContext';
import {
    grantAllLocationRoleAccess,
    updateLocationRoleAccess,
    revokeAllLocationRoleAccess
} from './context/actions';
import WarningAlert from '../../atoms/WarningAlert';
import { Status } from '../../../../store/reducers/customer-context';

interface Props {
    readOnly?: boolean;
    requestAccess?: boolean;
}

const tableBorderWidth = '0px';

const useStyles = makeStyles((theme) => ({
    table: {
        marginTop: '180px',
        'border-collapse': 'collapse',
        marginRight: '2rem'
    },
    rotateHeader: {
        whiteSpace: 'nowrap',
        position: 'relative',
        width: 42
    },
    tableName: {
        fontSize: '1.5em',
        fontWeight: boldWeight,
        color: theme.palette.secondary.main,
        paddingBottom: '1em'
    },
    link: {
        fontSize: medium
    },
    rolesAndAccessLink: {
        fontSize: medium,
        marginLeft: 4
    },
    rotate: {
        position: 'absolute',
        top: 0,
        left: 0,
        textAlign: 'left',
        width: '100%',
        transform: `translate( calc( 50% - ${tableBorderWidth}/2), ${tableBorderWidth}) rotate(315deg)`,
        transformOrigin: `0% calc(100% - ${tableBorderWidth})`,
        height: `10px`
    },
    rotateSpan: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        borderBottom: `${tableBorderWidth} solid gray`
    },
    td: {},
    popover: {
        pointerEvents: 'none',
        padding: '1.5em',
        marginLeft: '0.750em',
        marginTop: '-1.75em'
    },
    paper: {
        padding: theme.spacing(1)
    },
    company: {
        paddingTop: '0.5em',
        fontSize: medium,
        lineHeight: '1.2em'
    },
    address: {
        fontSize: small
    },
    warning: {
        fontSize: small
    },
    inactiveWarning: {
        border: `1px solid ${warningBorder}`,
        borderRight: 'none',
        backgroundColor: warningLabel
    },
    icon: {
        color: ballGreen,
        height: '1rem',
        width: '1rem',
        marginLeft: '0.2em'
    },
    noAdminContainer: {
        backgroundColor: yellowNoticeBackground,
        borderWidth: 1,
        borderColor: yellowNoticeBackgroundBorder,
        borderStyle: 'solid'
    },
    alertContainer: {
        marginTop: '2em',
        width: '90%'
    }
}));

export function LocationRoleAccessTable({ requestAccess, readOnly = false }: Props) {
    const classes = useStyles();
    const { accounts } = useLocationRoleAccessState();
    const dispatch = useLocationRoleAccessDispatch();
    const { t } = useTranslation();
    const rolesAndAccessTableLink = '/roles-and-access';
    // permission states
    const [filteredRows, setFilteredRows] = useState<Array<ShipToRoleAccess>>([]);
    const [filter, setFilter] = useState<string>('');
    const [allAccess, setAllAccess] = useState(false);
    const [allAccessIndeterminate, setAllAccessIndeterminate] = useState(false);
    const [orderFulfillment, setOrderFulfillment] = useState(false);
    const [orderFullfillmentIndeterminate, setOrderFullfillmentIndeterminate] = useState(false);
    const [procurementMgt, setProcurementMgt] = useState(false);
    const [procurementMgtIndeterminate, setProcurementMgtIndeterminate] = useState(false);
    const [forecastMgt, setForecastMgt] = useState(false);
    const [forecastMgtIndeterminate, setForecastMgtIndeterminate] = useState(false);
    const [packagingMgt, setPackagingMgt] = useState(false);
    const [packagingMgtIndeterminate, setPackagingMgtIndeterminate] = useState(false);
    const [coPacker, setCoPacker] = useState(false);
    const [coPackerIndeterminate, setCoPackerIndeterminate] = useState(false);
    const [warehousingLogistics, setWarehousingLogistics] = useState(false);
    const [warehousingLogisticsIndeterminate, setWarehousingLogisticsIndeterminate] =
        useState(false);
    const [artMgt, setArtMgt] = useState(false);
    const [artMgtIndeterminate, setArtMgtIndeterminate] = useState(false);
    const [marketingMgt, setMarketingMgt] = useState(false);
    const [marketingMgtIndeterminate, setMarketingMgtIndeterminate] = useState(false);
    const [acctPayableReceivable, setAcctPayableReceivable] = useState(false);
    const [acctPayableReceivableIndeterminate, setAcctPayableReceivableIndeterminate] =
        useState(false);
    const [graphicsVendor, setGraphicsVendor] = useState(false);
    const [graphicsVendorIndeterminate, setGraphicsVendorIndeterminate] = useState(false);
    const [pricingContact, setPricingContact] = useState(false);
    const [pricingContactIndeterminate, setPricingContactIndeterminate] = useState(false);
    const [makeItOnly, setMakeItOnly] = useState(false);
    const [makeItOnlyIndeterminate, setMakeItOnlyIndeterminate] = useState(false);
    const [shipItOnly, setShipItOnly] = useState(false);
    const [shipItOnlyIndeterminate, setShipItOnlyIndeterminate] = useState(false);
    const [hasAdminForAllAccounts, setHasAdminForAllAccounts] = useState(true);

    // general states
    const [openAccessPopover, setOpenAccessPopover] = useState(false);
    const [tooltipRows, setTooltipRows] = useState<UserAdminRoleRow[]>([]);
    const [tooltipSecurityLevelIndex, setTooltipSecurityLevelIndex] = useState<number>(0);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [rows, setRows] = useState<Array<ShipToRoleAccess>>(accounts);

    const infoIcon = (
        <img
            src={process.env.PUBLIC_URL + '/assets/Info_Icon.svg'}
            alt="Info Icon"
            className={classes.icon}
        />
    );

    useEffect(() => {
        setRows(accounts);
        let filteredUserAdminRolesTableRows =
            filterUserAdminRolesTableRows(userAdminRolesTableRows);
        setTooltipRows(filteredUserAdminRolesTableRows);
    }, [accounts]);

    useEffect(() => {
        filterRows(new RegExp(filter, 'i'));
    }, [filter, rows]);

    useEffect(() => {
        if (filteredRows.some((row) => row.isUserAdmin === false)) {
            setHasAdminForAllAccounts(false);
        }
    }, [filteredRows]);

    const filterUserAdminRolesTableRows = (userAdminRolesTableRows: UserAdminRoleRow[]) => {
        return userAdminRolesTableRows.filter(
            (row) =>
                row.activityKey !== 'userAdmin' &&
                row.activityKey !== 'createNewUser' &&
                row.activityKey !== 'editpermissions' &&
                row.activityKey !== 'disablePermissions' &&
                row.activityKey !== 'troubleshooting' &&
                row.activityKey !== 'graphics'
        );
    };

    const filterRows = (regex: RegExp) => {
        const filtered = accounts.filter((r) => regex.test(`${r.accountId} ${r.name}`));
        setFilteredRows(filtered);
    };

    const handleRowCheckboxChange = (
        checked: boolean,
        accountId: string,
        key: RoleAccessKey,
        allSelected: boolean,
        setRole: (boolean) => void,
        setRoleIntermediate: (boolean) => void
    ) => {
        updateLocationRoleAccess(accountId, key, checked, dispatch);

        if (allSelected === true) {
            setRole(true);
            setRoleIntermediate(true);
        }
    };
    const handleHeaderCheckboxChange = (
        event,
        key: RoleAccessKey,
        indeterminate: boolean,
        setRole: (boolean) => void,
        setRoleIntermediate: (boolean) => void
    ) => {
        let checked = event.target.checked;

        const filteredAccountIds = filteredRows
            .filter((account) => account.isUserAdmin)
            .reduce((prev, curr) => {
                prev.push(curr.accountId);
                return prev;
            }, [] as Array<string>);
        if (event.target.checked === true || indeterminate === true) {
            checked = true;
            grantAllLocationRoleAccess(filteredAccountIds, key, dispatch);
        } else {
            revokeAllLocationRoleAccess(filteredAccountIds, key, dispatch);
        }
        setRole(checked);
        setRoleIntermediate(false);
    };

    const handlePopoverOpen = (
        event: React.MouseEvent<HTMLElement, MouseEvent>,
        persona: Persona
    ) => {
        setOpenAccessPopover(true);
        setAnchorEl(event.currentTarget);
        let securityLevelIndex = indexForPersona(persona);
        setTooltipSecurityLevelIndex(securityLevelIndex);
    };

    const handlePopoverClose = () => {
        setOpenAccessPopover(false);
        setAnchorEl(null);
    };

    const handleFilterChange = (filter) => {
        setFilter(filter);
    };

    // match index to user-admin-roles.ts accessLevels in userAdminRolesTableRows data
    const indexForPersona = (persona: Persona) => {
        switch (persona) {
            case Persona.AllAccess:
                return 0;
            case Persona.PricingContact:
                return 1;
            case Persona.OrderFulfillment:
                return 2;
            case Persona.MakeItOnly:
                return 3;
            case Persona.ShipItOnly:
                return 4;
            case Persona.ProcurementMgmt:
                return 5;
            case Persona.ForecastMgmt:
                return 6;
            case Persona.PackagingMgmt:
                return 7;
            case Persona.CoPacker:
                return 8;
            case Persona.WarehousingLogistics:
                return 9;
            case Persona.ArtMgmt:
                return 10;
            case Persona.MarketingMgmt:
                return 11;
            case Persona.ArAp:
                return 12;
            case Persona.GraphicsVendor:
                return 13;
            case Persona.AccountAdmin:
                return 14;
            default:
                return 0;
        }
    };

    return (
        <>
            <Grid data-testid="location-role-access">
                <Typography className={classes.tableName}>
                    <Trans i18nKey="locationRoleAccess">{enUS.locationRoleAccess}</Trans>
                </Typography>
            </Grid>
            <Grid container item>
                <Typography>
                    <Trans
                        i18nKey={
                            requestAccess
                                ? 'locationRoleAccessTableRequest'
                                : 'locationRoleAccessTableInst'
                        }
                    >
                        {requestAccess
                            ? enUS.locationRoleAccessTableRequest
                            : enUS.locationRoleAccessTableInst}
                    </Trans>
                    <Link
                        underline="none"
                        data-testid="roles-and-access-page-link"
                        className={classes.rolesAndAccessLink}
                        to={rolesAndAccessTableLink}
                        target="_blank"
                        component={RouterLink}
                    >
                        <Trans i18nKey="locationRoleAccessTableInstLink">
                            {enUS.locationRoleAccessTableInstLink}
                        </Trans>
                    </Link>
                </Typography>
            </Grid>
            {!readOnly && !hasAdminForAllAccounts && !requestAccess && (
                <Grid
                    item
                    data-testid="warning-not-administrator"
                    className={classes.alertContainer}
                >
                    <WarningAlert
                        warningMessage={t(
                            'notAdminListedAccounts',
                            'You are not a User Administrator for some of the listed accounts.'
                        )}
                        showWarning={!hasAdminForAllAccounts}
                    />
                </Grid>
            )}
            <Table
                className={classes.table}
                size="small"
                aria-label="locations and role access table"
            >
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <SearchBar onChange={(e) => handleFilterChange(e.target.value)} />
                        </TableCell>
                        <TableCell className={classes.rotateHeader} align="center">
                            <div className={classes.rotate}>
                                <span
                                    className={classes.rotateSpan}
                                    onMouseEnter={(e) => handlePopoverOpen(e, Persona.AllAccess)}
                                    onMouseLeave={handlePopoverClose}
                                >
                                    <Link
                                        underline="none"
                                        data-testid="all-access-header"
                                        className={classes.link}
                                    >
                                        <Trans i18nKey="allAccess">{enUS.allAccess}</Trans>
                                    </Link>
                                    {infoIcon}
                                </span>
                            </div>
                            {!readOnly && (
                                <Checkbox
                                    data-testid="select-product"
                                    inputProps={{ 'aria-label': 'all access checkbox' }}
                                    checked={allAccess}
                                    disabled={readOnly}
                                    onChange={(e) =>
                                        handleHeaderCheckboxChange(
                                            e,
                                            RoleAccessKey.AllAccess,
                                            allAccessIndeterminate,
                                            setAllAccess,
                                            setAllAccessIndeterminate
                                        )
                                    }
                                ></Checkbox>
                            )}
                        </TableCell>
                        <TableCell className={classes.rotateHeader} align="center">
                            <div className={classes.rotate}>
                                <span
                                    className={classes.rotateSpan}
                                    onMouseEnter={(e) =>
                                        handlePopoverOpen(e, Persona.PricingContact)
                                    }
                                    onMouseLeave={handlePopoverClose}
                                >
                                    <Link
                                        underline="none"
                                        data-testid="pricing-contact-header"
                                        className={classes.link}
                                    >
                                        <Trans i18nKey="pricingContact">
                                            {enUS.pricingContact}
                                        </Trans>
                                    </Link>
                                    {infoIcon}
                                </span>
                            </div>
                            {!readOnly && (
                                <Checkbox
                                    checked={pricingContact}
                                    onChange={(e) =>
                                        handleHeaderCheckboxChange(
                                            e,
                                            RoleAccessKey.PricingContact,
                                            pricingContactIndeterminate,
                                            setPricingContact,
                                            setPricingContactIndeterminate
                                        )
                                    }
                                    indeterminate={pricingContactIndeterminate}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    disabled={readOnly}
                                />
                            )}
                        </TableCell>
                        <TableCell className={classes.rotateHeader} align="center">
                            <div className={classes.rotate}>
                                <span
                                    className={classes.rotateSpan}
                                    onMouseEnter={(e) =>
                                        handlePopoverOpen(e, Persona.OrderFulfillment)
                                    }
                                    onMouseLeave={handlePopoverClose}
                                >
                                    <Link
                                        underline="none"
                                        data-testid="order-fulfillment-header"
                                        className={classes.link}
                                    >
                                        <Trans i18nKey="orderFulfillment">
                                            {enUS.orderFulfillment}
                                        </Trans>
                                    </Link>
                                    {infoIcon}
                                </span>
                            </div>
                            {!readOnly && (
                                <Checkbox
                                    checked={orderFulfillment}
                                    onChange={(e) =>
                                        handleHeaderCheckboxChange(
                                            e,
                                            RoleAccessKey.OrderFullfillment,
                                            orderFullfillmentIndeterminate,
                                            setOrderFulfillment,
                                            setOrderFullfillmentIndeterminate
                                        )
                                    }
                                    indeterminate={orderFullfillmentIndeterminate}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    disabled={readOnly}
                                />
                            )}
                        </TableCell>
                        <TableCell className={classes.rotateHeader} align="center">
                            <div className={classes.rotate}>
                                <span
                                    className={classes.rotateSpan}
                                    onMouseEnter={(e) => handlePopoverOpen(e, Persona.MakeItOnly)}
                                    onMouseLeave={handlePopoverClose}
                                >
                                    <Link
                                        underline="none"
                                        data-testid="make-it-only-header"
                                        className={classes.link}
                                    >
                                        <Trans i18nKey="makeItOnly">{enUS.makeItOnly}</Trans>
                                    </Link>
                                    {infoIcon}
                                </span>
                            </div>
                            {!readOnly && (
                                <Checkbox
                                    checked={makeItOnly}
                                    onChange={(e) =>
                                        handleHeaderCheckboxChange(
                                            e,
                                            RoleAccessKey.MakeItOnly,
                                            makeItOnlyIndeterminate,
                                            setMakeItOnly,
                                            setMakeItOnlyIndeterminate
                                        )
                                    }
                                    indeterminate={makeItOnlyIndeterminate}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    disabled={readOnly}
                                />
                            )}
                        </TableCell>
                        <TableCell className={classes.rotateHeader} align="center">
                            <div className={classes.rotate}>
                                <span
                                    className={classes.rotateSpan}
                                    onMouseEnter={(e) => handlePopoverOpen(e, Persona.ShipItOnly)}
                                    onMouseLeave={handlePopoverClose}
                                >
                                    <Link
                                        underline="none"
                                        data-testid="ship-it-only-header"
                                        className={classes.link}
                                    >
                                        <Trans i18nKey="shipItOnly">{enUS.shipItOnly}</Trans>
                                    </Link>
                                    {infoIcon}
                                </span>
                            </div>
                            {!readOnly && (
                                <Checkbox
                                    checked={shipItOnly}
                                    onChange={(e) =>
                                        handleHeaderCheckboxChange(
                                            e,
                                            RoleAccessKey.ShipItOnly,
                                            shipItOnlyIndeterminate,
                                            setShipItOnly,
                                            setShipItOnlyIndeterminate
                                        )
                                    }
                                    indeterminate={shipItOnlyIndeterminate}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    disabled={readOnly}
                                />
                            )}
                        </TableCell>
                        <TableCell className={classes.rotateHeader} align="center">
                            <div className={classes.rotate}>
                                <span
                                    className={classes.rotateSpan}
                                    onMouseEnter={(e) =>
                                        handlePopoverOpen(e, Persona.ProcurementMgmt)
                                    }
                                    onMouseLeave={handlePopoverClose}
                                >
                                    <Link
                                        underline="none"
                                        data-testid="procurement-mgt-header"
                                        className={classes.link}
                                    >
                                        <Trans i18nKey="procurementMgmt">
                                            {enUS.procurementMgmt}
                                        </Trans>
                                    </Link>
                                    {infoIcon}
                                </span>
                            </div>
                            {!readOnly && (
                                <Checkbox
                                    checked={procurementMgt}
                                    onChange={(e) =>
                                        handleHeaderCheckboxChange(
                                            e,
                                            RoleAccessKey.ProcurementMgt,
                                            procurementMgtIndeterminate,
                                            setProcurementMgt,
                                            setProcurementMgtIndeterminate
                                        )
                                    }
                                    indeterminate={procurementMgtIndeterminate}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    disabled={readOnly}
                                />
                            )}
                        </TableCell>
                        <TableCell className={classes.rotateHeader} align="center">
                            <div className={classes.rotate}>
                                <span
                                    className={classes.rotateSpan}
                                    onMouseEnter={(e) => handlePopoverOpen(e, Persona.ForecastMgmt)}
                                    onMouseLeave={handlePopoverClose}
                                >
                                    <Link
                                        underline="none"
                                        data-testid="forecast-mgt-header"
                                        className={classes.link}
                                    >
                                        <Trans i18nKey="forecastMgmt">{enUS.forecastMgmt}</Trans>
                                    </Link>
                                    {infoIcon}
                                </span>
                            </div>
                            {!readOnly && (
                                <Checkbox
                                    checked={forecastMgt}
                                    onChange={(e) =>
                                        handleHeaderCheckboxChange(
                                            e,
                                            RoleAccessKey.ForecastMgt,
                                            forecastMgtIndeterminate,
                                            setForecastMgt,
                                            setForecastMgtIndeterminate
                                        )
                                    }
                                    indeterminate={forecastMgtIndeterminate}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    disabled={readOnly}
                                />
                            )}
                        </TableCell>
                        <TableCell className={classes.rotateHeader} align="center">
                            <div className={classes.rotate}>
                                <span
                                    className={classes.rotateSpan}
                                    onMouseEnter={(e) =>
                                        handlePopoverOpen(e, Persona.PackagingMgmt)
                                    }
                                    onMouseLeave={handlePopoverClose}
                                >
                                    <Link
                                        underline="none"
                                        data-testid="packaging-mgt-header"
                                        className={classes.link}
                                    >
                                        <Trans i18nKey="packagingMgmt">{enUS.packagingMgmt}</Trans>
                                    </Link>
                                    {infoIcon}
                                </span>
                            </div>
                            {!readOnly && (
                                <Checkbox
                                    checked={packagingMgt}
                                    onChange={(e) =>
                                        handleHeaderCheckboxChange(
                                            e,
                                            RoleAccessKey.PackagingMgt,
                                            packagingMgtIndeterminate,
                                            setPackagingMgt,
                                            setPackagingMgtIndeterminate
                                        )
                                    }
                                    indeterminate={packagingMgtIndeterminate}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    disabled={readOnly}
                                />
                            )}
                        </TableCell>
                        <TableCell className={classes.rotateHeader} align="center">
                            <div className={classes.rotate}>
                                <span
                                    className={classes.rotateSpan}
                                    onMouseEnter={(e) => handlePopoverOpen(e, Persona.CoPacker)}
                                    onMouseLeave={handlePopoverClose}
                                >
                                    <Link
                                        underline="none"
                                        data-testid="co-packer-header"
                                        className={classes.link}
                                    >
                                        <Trans i18nKey="coPacker">{enUS.coPacker}</Trans>
                                    </Link>
                                    {infoIcon}
                                </span>
                            </div>
                            {!readOnly && (
                                <Checkbox
                                    checked={coPacker}
                                    onChange={(e) =>
                                        handleHeaderCheckboxChange(
                                            e,
                                            RoleAccessKey.CoPacker,
                                            coPackerIndeterminate,
                                            setCoPacker,
                                            setCoPackerIndeterminate
                                        )
                                    }
                                    indeterminate={coPackerIndeterminate}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    disabled={readOnly}
                                />
                            )}
                        </TableCell>
                        <TableCell className={classes.rotateHeader} align="center">
                            <div className={classes.rotate}>
                                <span
                                    className={classes.rotateSpan}
                                    onMouseEnter={(e) =>
                                        handlePopoverOpen(e, Persona.WarehousingLogistics)
                                    }
                                    onMouseLeave={handlePopoverClose}
                                >
                                    <Link
                                        underline="none"
                                        data-testid="warehousing-logistics-header"
                                        className={classes.link}
                                    >
                                        <Trans i18nKey="warehousingLogistics">
                                            {enUS.warehousingLogistics}
                                        </Trans>
                                    </Link>
                                    {infoIcon}
                                </span>
                            </div>
                            {!readOnly && (
                                <Checkbox
                                    checked={warehousingLogistics}
                                    onChange={(e) =>
                                        handleHeaderCheckboxChange(
                                            e,
                                            RoleAccessKey.WarehousingLogistics,
                                            warehousingLogisticsIndeterminate,
                                            setWarehousingLogistics,
                                            setWarehousingLogisticsIndeterminate
                                        )
                                    }
                                    indeterminate={warehousingLogisticsIndeterminate}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    disabled={readOnly}
                                />
                            )}
                        </TableCell>
                        <TableCell className={classes.rotateHeader} align="center">
                            <div className={classes.rotate}>
                                <span
                                    className={classes.rotateSpan}
                                    onMouseEnter={(e) => handlePopoverOpen(e, Persona.ArtMgmt)}
                                    onMouseLeave={handlePopoverClose}
                                >
                                    <Link
                                        underline="none"
                                        data-testid="art-mgt-header"
                                        className={classes.link}
                                    >
                                        <Trans i18nKey="artMgmt">{enUS.artMgmt}</Trans>
                                    </Link>
                                    {infoIcon}
                                </span>
                            </div>
                            {!readOnly && (
                                <Checkbox
                                    checked={artMgt}
                                    onChange={(e) =>
                                        handleHeaderCheckboxChange(
                                            e,
                                            RoleAccessKey.ArtMgt,
                                            artMgtIndeterminate,
                                            setArtMgt,
                                            setArtMgtIndeterminate
                                        )
                                    }
                                    indeterminate={artMgtIndeterminate}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    disabled={readOnly}
                                />
                            )}
                        </TableCell>
                        <TableCell className={classes.rotateHeader} align="center">
                            <div className={classes.rotate}>
                                <span
                                    className={classes.rotateSpan}
                                    onMouseEnter={(e) =>
                                        handlePopoverOpen(e, Persona.MarketingMgmt)
                                    }
                                    onMouseLeave={handlePopoverClose}
                                >
                                    <Link
                                        underline="none"
                                        data-testid="marketing-mgt-header"
                                        className={classes.link}
                                    >
                                        <Trans i18nKey="marketingMgmt">{enUS.marketingMgmt}</Trans>
                                    </Link>
                                    {infoIcon}
                                </span>
                            </div>
                            {!readOnly && (
                                <Checkbox
                                    checked={marketingMgt}
                                    onChange={(e) =>
                                        handleHeaderCheckboxChange(
                                            e,
                                            RoleAccessKey.MarketingMgt,
                                            marketingMgtIndeterminate,
                                            setMarketingMgt,
                                            setMarketingMgtIndeterminate
                                        )
                                    }
                                    indeterminate={marketingMgtIndeterminate}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    disabled={readOnly}
                                />
                            )}
                        </TableCell>
                        <TableCell className={classes.rotateHeader} align="center">
                            <div className={classes.rotate}>
                                <span
                                    className={classes.rotateSpan}
                                    onMouseEnter={(e) => handlePopoverOpen(e, Persona.ArAp)}
                                    onMouseLeave={handlePopoverClose}
                                >
                                    <Link
                                        underline="none"
                                        data-testid="acct-payable-header"
                                        className={classes.link}
                                    >
                                        <Trans i18nKey="acctPayableReceivable">
                                            {enUS.acctPayableReceivable}
                                        </Trans>
                                    </Link>
                                    {infoIcon}
                                </span>
                            </div>
                            {!readOnly && (
                                <Checkbox
                                    checked={acctPayableReceivable}
                                    onChange={(e) =>
                                        handleHeaderCheckboxChange(
                                            e,
                                            RoleAccessKey.AcctPayableReceivable,
                                            acctPayableReceivableIndeterminate,
                                            setAcctPayableReceivable,
                                            setAcctPayableReceivableIndeterminate
                                        )
                                    }
                                    indeterminate={acctPayableReceivableIndeterminate}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    disabled={readOnly}
                                />
                            )}
                        </TableCell>
                        <TableCell className={classes.rotateHeader} align="center">
                            <div className={classes.rotate}>
                                <span
                                    className={classes.rotateSpan}
                                    onMouseEnter={(e) =>
                                        handlePopoverOpen(e, Persona.GraphicsVendor)
                                    }
                                    onMouseLeave={handlePopoverClose}
                                >
                                    <Link
                                        underline="none"
                                        data-testid="graphics-vendor-header"
                                        className={classes.link}
                                    >
                                        <Trans i18nKey="graphicsVendor">
                                            {enUS.graphicsVendor}
                                        </Trans>
                                    </Link>
                                    {infoIcon}
                                </span>
                            </div>
                            {!readOnly && (
                                <Checkbox
                                    checked={graphicsVendor}
                                    onChange={(e) =>
                                        handleHeaderCheckboxChange(
                                            e,
                                            RoleAccessKey.GraphicsVendor,
                                            graphicsVendorIndeterminate,
                                            setGraphicsVendor,
                                            setGraphicsVendorIndeterminate
                                        )
                                    }
                                    indeterminate={graphicsVendorIndeterminate}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    disabled={readOnly}
                                />
                            )}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredRows.map((row) => {
                        return (
                            <TableRow
                                key={row.name}
                                data-testid={`role-access-row-${row.accountId}`}
                            >
                                <TableCell
                                    component="th"
                                    scope="row"
                                    className={clsx(
                                        !readOnly &&
                                            !row.isUserAdmin &&
                                            !requestAccess &&
                                            classes.noAdminContainer,
                                        row.status === Status.Inactive && classes.inactiveWarning
                                    )}
                                >
                                    <Typography variant="subtitle2" className={classes.company}>
                                        {row.name} ({row.accountId})
                                    </Typography>
                                    <Typography variant="body1" className={classes.address}>
                                        {row.address.address1}
                                    </Typography>{' '}
                                    <Typography variant="body1" className={classes.address}>
                                        {row.address.city}
                                        {', '}
                                        {row.address.stateProvince} {row.address.postalCode}
                                        {', '}
                                        {row.address.country}
                                    </Typography>
                                    {!readOnly && !row.isUserAdmin && !requestAccess && (
                                        <Typography variant="body1" className={classes.warning}>
                                            <Trans i18nKey="noAdminforLocation">
                                                You are not an Admin for this Location.
                                            </Trans>
                                        </Typography>
                                    )}
                                </TableCell>
                                {row.status === Status.Inactive ? (
                                    <TableCell
                                        colSpan={14}
                                        className={classes.inactiveWarning}
                                        align="center"
                                    >
                                        <Typography>
                                            <Trans i18nKey="inactiveAccountWarning">
                                                This account is inactive, contact your Ball
                                                representative to re-activate.
                                            </Trans>
                                        </Typography>
                                    </TableCell>
                                ) : (
                                    <>
                                        <TableCell className={classes.td} align="center">
                                            {(row.allAccess || !readOnly) && (
                                                <Checkbox
                                                    checked={row.allAccess}
                                                    inputProps={{
                                                        'aria-label': `all access ${row.accountId}`
                                                    }}
                                                    onChange={(e) =>
                                                        handleRowCheckboxChange(
                                                            e.target.checked,
                                                            row.accountId,
                                                            RoleAccessKey.AllAccess,
                                                            allAccess,
                                                            setAllAccess,
                                                            setAllAccessIndeterminate
                                                        )
                                                    }
                                                    disabled={
                                                        row.disabledList.allAccess ||
                                                        readOnly ||
                                                        (!requestAccess && !row.isUserAdmin)
                                                    }
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell className={classes.td} align="center">
                                            {(row.pricingContact || !readOnly) && (
                                                <Checkbox
                                                    checked={row.pricingContact}
                                                    inputProps={{
                                                        'aria-label': `pricing contact ${row.accountId}`
                                                    }}
                                                    onChange={(e) =>
                                                        handleRowCheckboxChange(
                                                            e.target.checked,
                                                            row.accountId,
                                                            RoleAccessKey.PricingContact,
                                                            pricingContact,
                                                            setPricingContact,
                                                            setPricingContactIndeterminate
                                                        )
                                                    }
                                                    name={row.name}
                                                    disabled={
                                                        row.disabledList.pricingContact ||
                                                        !row.isBillToAccount ||
                                                        readOnly ||
                                                        (!requestAccess && !row.isUserAdmin)
                                                    }
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell className={classes.td} align="center">
                                            {(row.orderFullfillment || !readOnly) && (
                                                <Checkbox
                                                    checked={row.orderFullfillment}
                                                    inputProps={{
                                                        'aria-label': `order fulfillment ${row.accountId}`
                                                    }}
                                                    onChange={(e) =>
                                                        handleRowCheckboxChange(
                                                            e.target.checked,
                                                            row.accountId,
                                                            RoleAccessKey.OrderFullfillment,
                                                            orderFulfillment,
                                                            setOrderFulfillment,
                                                            setOrderFullfillmentIndeterminate
                                                        )
                                                    }
                                                    name={row.name}
                                                    disabled={
                                                        row.disabledList.orderFullfillment ||
                                                        !row.isShipToAccount ||
                                                        readOnly ||
                                                        (!requestAccess && !row.isUserAdmin)
                                                    }
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell className={classes.td} align="center">
                                            {(row.makeItOnly || !readOnly) && (
                                                <Checkbox
                                                    disabled={
                                                        row.disabledList.makeItOnly ||
                                                        !row.isShipToAccount ||
                                                        readOnly ||
                                                        (!requestAccess && !row.isUserAdmin)
                                                    }
                                                    checked={row.makeItOnly}
                                                    inputProps={{
                                                        'aria-label': `make it only ${row.accountId}`
                                                    }}
                                                    onChange={(e) =>
                                                        handleRowCheckboxChange(
                                                            e.target.checked,
                                                            row.accountId,
                                                            RoleAccessKey.MakeItOnly,
                                                            makeItOnly,
                                                            setMakeItOnly,
                                                            setMakeItOnlyIndeterminate
                                                        )
                                                    }
                                                    name={row.name}
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell className={classes.td} align="center">
                                            {(row.shipItOnly || !readOnly) && (
                                                <Checkbox
                                                    disabled={
                                                        row.disabledList.shipItOnly ||
                                                        !row.isShipToAccount ||
                                                        readOnly ||
                                                        (!requestAccess && !row.isUserAdmin)
                                                    }
                                                    checked={row.shipItOnly}
                                                    inputProps={{
                                                        'aria-label': `ship it only ${row.accountId}`
                                                    }}
                                                    onChange={(e) =>
                                                        handleRowCheckboxChange(
                                                            e.target.checked,
                                                            row.accountId,
                                                            RoleAccessKey.ShipItOnly,
                                                            shipItOnly,
                                                            setShipItOnly,
                                                            setShipItOnlyIndeterminate
                                                        )
                                                    }
                                                    name={row.name}
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell className={classes.td} align="center">
                                            {(row.procurementMgt || !readOnly) && (
                                                <Checkbox
                                                    checked={row.procurementMgt}
                                                    inputProps={{
                                                        'aria-label': `procurement management ${row.accountId}`
                                                    }}
                                                    onChange={(e) =>
                                                        handleRowCheckboxChange(
                                                            e.target.checked,
                                                            row.accountId,
                                                            RoleAccessKey.ProcurementMgt,
                                                            procurementMgt,
                                                            setProcurementMgt,
                                                            setProcurementMgtIndeterminate
                                                        )
                                                    }
                                                    name={row.name}
                                                    disabled={
                                                        row.disabledList.procurementMgt ||
                                                        !row.isShipToAccount ||
                                                        readOnly ||
                                                        (!requestAccess && !row.isUserAdmin)
                                                    }
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell className={classes.td} align="center">
                                            {(row.forecastMgt || !readOnly) && (
                                                <Checkbox
                                                    checked={row.forecastMgt}
                                                    inputProps={{
                                                        'aria-label': `forecast management ${row.accountId}`
                                                    }}
                                                    onChange={(e) =>
                                                        handleRowCheckboxChange(
                                                            e.target.checked,
                                                            row.accountId,
                                                            RoleAccessKey.ForecastMgt,
                                                            forecastMgt,
                                                            setForecastMgt,
                                                            setForecastMgtIndeterminate
                                                        )
                                                    }
                                                    name={row.name}
                                                    disabled={
                                                        row.disabledList.forecastMgt ||
                                                        !row.isShipToAccount ||
                                                        readOnly ||
                                                        (!requestAccess && !row.isUserAdmin)
                                                    }
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell className={classes.td} align="center">
                                            {(row.packagingMgt || !readOnly) && (
                                                <Checkbox
                                                    checked={row.packagingMgt}
                                                    inputProps={{
                                                        'aria-label': `packaging management ${row.accountId}`
                                                    }}
                                                    onChange={(e) =>
                                                        handleRowCheckboxChange(
                                                            e.target.checked,
                                                            row.accountId,
                                                            RoleAccessKey.PackagingMgt,
                                                            packagingMgt,
                                                            setPackagingMgt,
                                                            setPackagingMgtIndeterminate
                                                        )
                                                    }
                                                    name={row.name}
                                                    disabled={
                                                        row.disabledList.packagingMgt ||
                                                        !row.isShipToAccount ||
                                                        readOnly ||
                                                        (!requestAccess && !row.isUserAdmin)
                                                    }
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell className={classes.td} align="center">
                                            {(row.coPacker || !readOnly) && (
                                                <Checkbox
                                                    checked={row.coPacker}
                                                    inputProps={{
                                                        'aria-label': `co packer ${row.accountId}`
                                                    }}
                                                    onChange={(e) =>
                                                        handleRowCheckboxChange(
                                                            e.target.checked,
                                                            row.accountId,
                                                            RoleAccessKey.CoPacker,
                                                            coPacker,
                                                            setCoPacker,
                                                            setCoPackerIndeterminate
                                                        )
                                                    }
                                                    name={row.name}
                                                    disabled={
                                                        row.disabledList.coPacker ||
                                                        !row.isShipToAccount ||
                                                        readOnly ||
                                                        (!requestAccess && !row.isUserAdmin)
                                                    }
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell className={classes.td} align="center">
                                            {(row.warehousingLogistics || !readOnly) && (
                                                <Checkbox
                                                    checked={row.warehousingLogistics}
                                                    inputProps={{
                                                        'aria-label': `warehousing logistics ${row.accountId}`
                                                    }}
                                                    onChange={(e) =>
                                                        handleRowCheckboxChange(
                                                            e.target.checked,
                                                            row.accountId,
                                                            RoleAccessKey.WarehousingLogistics,
                                                            warehousingLogistics,
                                                            setWarehousingLogistics,
                                                            setWarehousingLogisticsIndeterminate
                                                        )
                                                    }
                                                    name={row.name}
                                                    disabled={
                                                        row.disabledList.warehousingLogistics ||
                                                        !row.isShipToAccount ||
                                                        readOnly ||
                                                        (!requestAccess && !row.isUserAdmin)
                                                    }
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell className={classes.td} align="center">
                                            {(row.artMgt || !readOnly) && (
                                                <Checkbox
                                                    disabled={
                                                        row.disabledList.artMgt ||
                                                        !row.isBillToAccount ||
                                                        readOnly ||
                                                        (!requestAccess && !row.isUserAdmin)
                                                    }
                                                    checked={row.artMgt}
                                                    inputProps={{
                                                        'aria-label': `art management ${row.accountId}`
                                                    }}
                                                    onChange={(e) =>
                                                        handleRowCheckboxChange(
                                                            e.target.checked,
                                                            row.accountId,
                                                            RoleAccessKey.ArtMgt,
                                                            artMgt,
                                                            setArtMgt,
                                                            setArtMgtIndeterminate
                                                        )
                                                    }
                                                    name={row.name}
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell className={classes.td} align="center">
                                            {(row.marketingMgt || !readOnly) && (
                                                <Checkbox
                                                    disabled={
                                                        row.disabledList.marketingMgt ||
                                                        !row.isBillToAccount ||
                                                        readOnly ||
                                                        (!requestAccess && !row.isUserAdmin)
                                                    }
                                                    checked={row.marketingMgt}
                                                    inputProps={{
                                                        'aria-label': `marketing management ${row.accountId}`
                                                    }}
                                                    onChange={(e) =>
                                                        handleRowCheckboxChange(
                                                            e.target.checked,
                                                            row.accountId,
                                                            RoleAccessKey.MarketingMgt,
                                                            marketingMgt,
                                                            setMarketingMgt,
                                                            setMarketingMgtIndeterminate
                                                        )
                                                    }
                                                    name={row.name}
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell className={classes.td} align="center">
                                            {(row.acctPayableReceivable || !readOnly) && (
                                                <Checkbox
                                                    disabled={
                                                        row.disabledList.acctPayableReceivable ||
                                                        !row.isBillToAccount ||
                                                        readOnly ||
                                                        (!requestAccess && !row.isUserAdmin)
                                                    }
                                                    checked={row.acctPayableReceivable}
                                                    inputProps={{
                                                        'aria-label': `account payable receiveable ${row.accountId}`
                                                    }}
                                                    onChange={(e) =>
                                                        handleRowCheckboxChange(
                                                            e.target.checked,
                                                            row.accountId,
                                                            RoleAccessKey.AcctPayableReceivable,
                                                            acctPayableReceivable,
                                                            setAcctPayableReceivable,
                                                            setAcctPayableReceivableIndeterminate
                                                        )
                                                    }
                                                    name={row.name}
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell className={classes.td} align="center">
                                            {(row.graphicsVendor || !readOnly) && (
                                                <Checkbox
                                                    disabled={
                                                        row.disabledList.graphicsVendor ||
                                                        !row.isBillToAccount ||
                                                        readOnly ||
                                                        (!requestAccess && !row.isUserAdmin)
                                                    }
                                                    checked={row.graphicsVendor}
                                                    inputProps={{
                                                        'aria-label': `graphics vendor ${row.accountId}`
                                                    }}
                                                    onChange={(e) =>
                                                        handleRowCheckboxChange(
                                                            e.target.checked,
                                                            row.accountId,
                                                            RoleAccessKey.GraphicsVendor,
                                                            graphicsVendor,
                                                            setGraphicsVendor,
                                                            setGraphicsVendorIndeterminate
                                                        )
                                                    }
                                                    name={row.name}
                                                />
                                            )}
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            {requestAccess && <RequestAccessCommentSection />}
            <Popover
                id="role-access-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper
                }}
                open={openAccessPopover}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                onClose={() => setOpenAccessPopover(false)}
                disableRestoreFocus
            >
                {tooltipRows && (
                    <LocationRoleAccessTooltip
                        rows={tooltipRows}
                        securityLevelIndex={tooltipSecurityLevelIndex}
                    />
                )}
            </Popover>
        </>
    );
}
