import { Grid, makeStyles, FormHelperText } from '@material-ui/core';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { red } from '../../../../themes/globalConstants';
import { ErrorMessage, useFormContext } from 'react-hook-form';
import TextInput from '../../../reusable/molecules/TextInput';
import RadioInput from '../../../reusable/molecules/RadioInput';
import FormSection from '../../../reusable/molecules/FormSection';
import { KeyValuePair } from '../../../../store/types';
import CountrySelect from '../../../reusable/molecules/CountrySelect';
import { Region } from '../../../../store/reducers/graphic-intake';
import StateProvinceSelect from '../../../reusable/molecules/StateProvinceSelect';
import {
    getNACountrySelection,
    NCACountries,
    postalCodePattern
} from '../../../../utility/helpers/address-helpers';
import clsx from 'clsx';
import { OnboardingDashboardState } from '../../../../store/reducers/onboarding-dashboard';
import { useTypedSelector } from '../../../../store/reducers/reducer';

const useStyles = makeStyles(() => ({
    helperText: {
        '& .MuiFormHelperText-root': {
            marginLeft: 0
        }
    },
    errorText: {
        color: red
    }
}));

const OnboardShippingAddressSection = () => {
    const classes = useStyles();
    const { control, errors, watch, setValue } = useFormContext();

    const { billingAndShipping } = useTypedSelector<OnboardingDashboardState>(
        (state) => state.onboardingDashboardState
    );

    useTranslation();

    const [selectedCountry, setSelectedCountry] = useState<string>(
        billingAndShipping.billingAndShippingForm?.shippingAddress?.country ?? NCACountries.USA
    );
    const [initialLoad, setInitialLoad] = useState<boolean>(true);
    const isRequired = <Trans i18nKey="required">Required</Trans>;

    const shippingSameAsBilling = watch('shippingSameAsBillingAddress') === 'Yes';

    const handleLoadShippingCountry = (name: string, selectedCountry: KeyValuePair) => {
        if (!initialLoad) {
            setValue('shippingAddress.state', '');
            setValue(name, selectedCountry);
            setSelectedCountry(selectedCountry.value);
        } else {
            const shippingCountry = getNACountrySelection(
                billingAndShipping.billingAndShippingForm?.shippingAddress?.country ??
                    NCACountries.USA
            );
            setValue(name, shippingCountry);
            setSelectedCountry(shippingCountry.value);
        }

        setInitialLoad(false);
    };

    const handleLoadShippingState = (name: string, selectedState: KeyValuePair) => {
        setValue(name, selectedState);
    };

    return (
        <FormSection
            testId={'shipping-address-section'}
            sectionHeader={<Trans i18nKey="shippingAddress">Shipping Address</Trans>}
        >
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={12} data-testid="shipping-same-as-billing">
                    <RadioInput
                        label={
                            <Trans i18nKey="shippingSameAsBillingAddress">
                                Is your shipping address the same as your billing address?
                            </Trans>
                        }
                        name="shippingSameAsBillingAddress"
                        testId="shippingSameAsBillingAddress"
                        control={control}
                        rules={{ required: isRequired }}
                        radioControls={[
                            { value: 'Yes', label: <Trans i18nKey="yes">Yes</Trans> },
                            { value: 'No', label: <Trans i18nKey="no">No</Trans> }
                        ]}
                        error={errors.shippingSameAsBillingAddress}
                        errors={errors}
                        defaultValue=""
                    />
                </Grid>
            </Grid>
            {!shippingSameAsBilling && (
                <>
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={6}>
                            <TextInput
                                type="text"
                                label={<Trans i18nKey="shippingName">Shipping Name</Trans>}
                                name="shippingAddress.name"
                                testId="shippingAddress.name"
                                control={control}
                                rules={{ required: isRequired }}
                                error={errors.shippingAddress?.name}
                                errors={errors}
                                styles={classes.helperText}
                                inputProps={{
                                    'data-testid': 'shipping-name',
                                    maxLength: 50
                                }}
                            />
                            <FormHelperText
                                className={clsx({
                                    [classes.errorText]: !!errors.shippingAddress?.name
                                })}
                            >
                                {errors.shippingAddress?.name?.type === 'required' && (
                                    <ErrorMessage
                                        errors={errors}
                                        name={'shippingAddress.name'}
                                        message={isRequired}
                                    />
                                )}
                                {!errors.shippingAddress?.name && (
                                    <Trans i18nKey="shippingNameHelper">
                                        Name of person who will be receiving shipments
                                    </Trans>
                                )}
                            </FormHelperText>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={6} data-testid="shippingAddress.country">
                            <CountrySelect
                                defaultValue={NCACountries.USA}
                                name={'shippingAddress.country'}
                                regionCode={Region.NA}
                                control={control}
                                rules={{ required: isRequired }}
                                error={errors.shippingAddress?.country}
                                errors={errors}
                                handleLoadDraftApproverCountry={handleLoadShippingCountry}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={12}>
                            <TextInput
                                type="text"
                                label={
                                    <Trans i18nKey="shippingAddressLine1">
                                        Shipping Address Line 1
                                    </Trans>
                                }
                                name="shippingAddress.addressLine1"
                                testId="shippingAddress.addressLine1"
                                control={control}
                                rules={{ required: isRequired }}
                                error={errors.shippingAddress?.addressLine1}
                                errors={errors}
                                styles={classes.helperText}
                                helperText={
                                    <Trans i18nKey="shippingAddressLine1Helper">
                                        Street Type, Street Name, House Number
                                    </Trans>
                                }
                                inputProps={{
                                    'data-testid': 'ship-adress-line1',
                                    maxLength: 40
                                }}
                            />
                            <FormHelperText className={classes.errorText}>
                                {errors.shippingAddress?.addressLine1 &&
                                    errors.shippingAddress?.addressLine1.type === 'required' && (
                                        <ErrorMessage
                                            errors={errors}
                                            name={'shippingAddress.addressLine1'}
                                            message={isRequired}
                                        />
                                    )}
                            </FormHelperText>
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput
                                type="text"
                                label={
                                    <Trans i18nKey="shippingAddressLine2">
                                        Shipping Address Line 2
                                    </Trans>
                                }
                                name="shippingAddress.addressLine2"
                                testId="shippingAddress.addressLine2"
                                control={control}
                                rules={''}
                                error={errors.shippingAddress?.addressLine2}
                                errors={errors}
                                styles={classes.helperText}
                                helperText={
                                    <Trans i18nKey="shippingAddressLine2Helper">
                                        Neighborhood, Municipality
                                    </Trans>
                                }
                                inputProps={{
                                    'data-testid': 'ship-address-line2',
                                    maxLength: 40
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput
                                type="text"
                                label={
                                    <Trans i18nKey="shippingAddressLine3">
                                        Shipping Address Line 3
                                    </Trans>
                                }
                                name="shippingAddress.addressLine3"
                                testId="shippingAddress.addressLine3"
                                control={control}
                                rules={''}
                                error={errors.shippingAddress?.addressLine3}
                                errors={errors}
                                styles={classes.helperText}
                                inputProps={{
                                    'data-testid': 'ship-address-line3',
                                    maxLength: 40
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={6}>
                            <TextInput
                                type="text"
                                label={<Trans i18nKey="city">City</Trans>}
                                name="shippingAddress.city"
                                testId="shippingAddress.city"
                                control={control}
                                rules={{ required: isRequired }}
                                error={errors.shippingAddress?.city}
                                errors={errors}
                                styles={classes.helperText}
                                inputProps={{
                                    'data-testid': 'ship-city',
                                    maxLength: 25
                                }}
                            />
                            <FormHelperText className={classes.errorText}>
                                {errors.shippingAddress?.city &&
                                    errors.shippingAddress?.city.type === 'required' && (
                                        <ErrorMessage
                                            errors={errors}
                                            name={'shippingAddress.city'}
                                            message={isRequired}
                                        />
                                    )}
                            </FormHelperText>
                        </Grid>
                        <Grid item xs={6}>
                            <StateProvinceSelect
                                defaultValue={
                                    billingAndShipping.billingAndShippingForm?.shippingAddress
                                        ?.country ?? NCACountries.USA
                                }
                                name={'shippingAddress.state'}
                                countryCode={selectedCountry}
                                control={control}
                                rules={{ required: isRequired }}
                                error={errors.shippingAddress?.state}
                                errors={errors}
                                handleLoadStateProvince={handleLoadShippingState}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput
                                type="text"
                                label={<Trans i18nKey="postalCode">Postal Code</Trans>}
                                name="shippingAddress.zipCode"
                                testId="shippingAddress.zipCode"
                                control={control}
                                rules={{
                                    required: isRequired,
                                    pattern: postalCodePattern
                                }}
                                error={errors.shippingAddress?.zipCode}
                                errors={errors}
                                styles={classes.helperText}
                                inputProps={{
                                    'data-testid': 'bill-address-name',
                                    maxLength: 11
                                }}
                            />
                            <FormHelperText className={classes.errorText}>
                                {errors.shippingAddress?.zipCode &&
                                    errors.shippingAddress?.zipCode.type === 'required' && (
                                        <ErrorMessage
                                            errors={errors}
                                            name={'shippingAddress.zipCode'}
                                            message={isRequired}
                                        />
                                    )}
                            </FormHelperText>
                        </Grid>
                    </Grid>
                </>
            )}
        </FormSection>
    );
};

export default OnboardShippingAddressSection;
