import React, { useEffect, useState, useMemo } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import { black, blackWeight, boldWeight } from '../../../../themes/globalConstants';
import MakeItConfigRow from './MakeItConfigRow';
import { OrderProductTableRow } from '../../../../store/reducers/orders-dashboard';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import MakeItConfigColumns from './MakeItConfigColumns';
import ErrorAlert from '../../../reusable/atoms/ErrorAlert';
import { getGridElementValue, paginationOptions } from '../../../../utility/helpers/grid-helpers';
import MakeItConfigToolbar from './MakeItConfigToolbar';

interface Props {
    items: OrderProductTableRow[];
    onRemoveItem: (item: OrderProductTableRow) => void;
    onItemUpdates: (item: OrderProductTableRow) => void;
    onViewFiltersClick: (viewTypes: string[]) => void;
    warning: boolean;
    warningMessage: string;
    warningTranslation: string;
    poError: boolean;
}

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        width: '100%',
        '& .MuiToolbar-root': {
            minHeight: 0,
            padding: '0 0.125em',
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                }
            }
        },
        '& .MuiTableSortLabel-root': {
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em'
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTablePagination-root': {
            '& .MuiTablePagination-caption': {
                marginLeft: '1em',
                fontWeight: boldWeight,
                color: black
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                '&:nth-child(1)': {
                    width: '12%'
                },
                '&:nth-child(2)': {
                    width: '30%'
                },
                '&:nth-child(4)': {
                    width: '10%'
                },
                '&:nth-child(5)': {
                    width: '10%'
                },
                '&:nth-child(6)': {
                    width: '12%'
                },
                '&:nth-child(7), &:nth-child(8)': {
                    borderLeft: 'none'
                }
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    }
}));

export default function MakeItConfigGrid({
    items,
    onRemoveItem,
    onItemUpdates,
    onViewFiltersClick,
    warning,
    warningMessage,
    warningTranslation,
    poError
}: Props) {
    const classes = useStyles();
    const { t } = useTranslation();

    const [columns, setColumns] = useState<any>([]);
    const [selectedPaginationRows, setSelectedPaginationRows] = useState<number>(0);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const columnsKey = useMemo(() => JSON.stringify(columns), [columns]);

    const getPaginationRows = () => {
        const gridElementValue = getGridElementValue('.MuiTablePagination-select');
        setSelectedPaginationRows(gridElementValue);
    };

    const CustomToolbarOptions = new ToolbarOptions({
        customItems: (
            <Grid container>
                <Grid container item xs={12}>
                    <ErrorAlert
                        showError={poError}
                        errorMessage={t('poNumberHelper', 'A PO Number is required.')}
                        dataTestId="po-number-error"
                    />
                </Grid>
                <Grid container item xs={12}>
                    <ErrorAlert
                        showError={warning}
                        errorMessage={t(warningTranslation, warningMessage)}
                    />
                </Grid>
                <MakeItConfigToolbar 
                    onViewFiltersClicked={onViewFiltersClick}
                />
            </Grid>
        ),
        exportButton: false,
        printButton: false,
        advancePagination: false,
        searchText: false,
        rowsPerPageOptions: paginationOptions.rowsPerPage,
        itemsPerPage: selectedPaginationRows
    });

    const CustomerMakeItRow = ({ row }: { row: Partial<OrderProductTableRow> }) => {
        return (
            <MakeItConfigRow
                key={row.productSku}
                item={row}
                onRemoveItem={onRemoveItem}
                onItemUpdates={onItemUpdates}
                warning={warning}
                getPaginationRows={getPaginationRows}
            />
        );
    };

    useEffect(() => {
        setColumns(MakeItConfigColumns.getMakeItConfigColumns(cultureCode, true, false));
    }, [cultureCode]);

    useEffect(() => {
        if (selectedPaginationRows === 0) {
            getPaginationRows();
        }
    }, [selectedPaginationRows]);

    return (
        <Grid
            container
            className={classes.tableWrapper}
            key={columnsKey}
            data-testid="make-it-config-data-grid"
        >
            <DataGrid
                columns={columns}
                dataSource={items}
                gridName="Make It Config Grid"
                rowComponent={CustomerMakeItRow}
                toolbarOptions={CustomToolbarOptions}
            />
        </Grid>
    );
}
