import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../reusable/molecules/Modal';
import SingleDaypickerModal from '../../../reusable/molecules/SingleDaypickerModal';


const useStyles = makeStyles((theme) => ({
    main: {
        margin: '2em'
    },
    calendar: {
        padding: '2em'
    }
}))

const EditShipmentsConfigDateModal = ({ open, onCancel, onOk, selectDate, firstDay, leadTime, highlightedDay, showChangeWarning }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const today = moment();
    const titleText = t('todayWithDate', 'Today');
    const titleDate = today.format('M/D/YYYY');
    const title = titleText + titleDate;
    const secondaryTitle = t('changeDeliveryDateWarning', 'Changing your delivery date could require alterations to your shipment');
    const warningText = t('productsRemovedFromShipment', 'Some products or quantities may not be available for shipment on this date and will be removed from your shipment.')

    return (
        <div className={classes.main}>
            <Modal
                title={title}
                secondaryTitle={secondaryTitle}
                open={open}
                close={onCancel}
                closeIcon={true}
                maxWidth="xl"
            >
                <div className={classes.calendar}>
                    <SingleDaypickerModal
                        onCancel={onCancel}
                        onOk={onOk}
                        selectDate={(newDate) => selectDate(newDate)}
                        firstDay={firstDay}
                        leadTime={leadTime}
                        highlightedDay={highlightedDay}
                        leadTimeIncrement='days'
                        warning={showChangeWarning}
                        warningMessage={warningText}
                        calculateOutsideRange
                    />
                </div>
            </Modal >
        </div>
    )
}

export default EditShipmentsConfigDateModal;