import React, { useEffect, useState } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import { black, blackWeight, boldWeight } from '../../../../themes/globalConstants';
import MakeItBulkUploadReviewRow from './MakeItBulkUploadReviewRow';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { generateKey } from '../../../../utility/helpers/order-helpers';
import { MakeItBulkLineItem } from '../../../../store/reducers/makeit-bulk-upload';
import MakeItConfigColumns from '../../MakeItConfiguration/components/MakeItConfigColumns';

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        width: '100%',
        marginTop: '1em',
        '& .MuiToolbar-root': {
            minHeight: 0,
            padding: '0 0.125em',
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                }
            }
        },
        '& .MuiTableCell-head': {
            justifyContent: 'space-between',
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em'
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTablePagination-root': {
            '& .MuiTablePagination-caption': {
                marginLeft: '1em',
                fontWeight: boldWeight,
                color: black
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                '&:nth-child(2)': {
                    width: '16%'
                },
                '&:nth-child(3)': {
                    width: '25%'
                },
                '&:nth-child(4)': {
                    width: '8%'
                },
                '&:nth-child(5)': {
                    width: '10%'
                },
                '&:nth-child(6)': {
                    width: '15%'
                },
                '&:nth-child(7)': {
                    width: '16%'
                }
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    }
}));

interface Props {
    items: MakeItBulkLineItem[];
}

const MakeItBulkUploadReviewGrid = ({ items }: Props) => {
    const classes = useStyles();

    const [columns, setColumns] = useState<any>([]);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const CustomToolbarOptions = new ToolbarOptions({
        exportButton: false,
        printButton: false,
        enablePagination: false,
        searchText: false
    });

    const CustomerMakeItRow = ({ row }: { row: Partial<MakeItBulkLineItem> }) => {
        return <MakeItBulkUploadReviewRow key={row.productSku} item={row} />;
    };

    useEffect(() => {
        setColumns(MakeItConfigColumns.getMakeItConfigColumns(cultureCode, false, false, true));
    }, [cultureCode]);

    return (
        <Grid
            container
            className={classes.tableWrapper}
            key={generateKey('makeit-bulk-review')}
            data-testid="make-it-bulk-review-data-grid"
        >
            <DataGrid
                columns={columns}
                dataSource={items}
                gridName="Make It Bulk Review Grid"
                rowComponent={CustomerMakeItRow}
                toolbarOptions={CustomToolbarOptions}
            />
        </Grid>
    );
};

export default MakeItBulkUploadReviewGrid;
