import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import { useTranslation, Trans } from 'react-i18next';
import { Grid, makeStyles, Typography, Button, CircularProgress } from '@material-ui/core';
import {
    ShippingState,
    ProductToShip,
    DeliveryShipment
} from '../../../store/reducers/shipping-dashboard';
import { useTypedSelector } from '../../../store/reducers/reducer';
import OrderCancelModal from '../../reusable/molecules/OrderCancelModal';
import {
    loadProductsToBeShipped,
    updateShipments,
    disableAllProducts,
    toggleAdd,
    updateConfigFlag,
    toggleAddDisabled,
    updateDeliveryOrder
} from '../../../store/actions/shipping-dashboard';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import ShipItConfigProductsToShip from './components/ShipItConfigProductsToShip';
import ShipItConfigShipment from './components/ShipItConfigShipment';
import {
    getSimpleFormattedDate,
    subtractTimezoneOffset
} from '../../../utility/helpers/date-helpers';
import {
    generateKey,
    getDeliveryLeadTimeDays,
    isProductCanType,
    isProductEndType,
    productTypeHelper
} from '../../../utility/helpers/order-helpers';
import {
    ltBlueGrey_34,
    small,
    blackWeight,
    ballGray,
    xl,
    boldWeight
} from '../../../themes/globalConstants';
import OrdersService from '../../../utility/services/orders-service';
import { useHistory } from 'react-router';
import Modal from '../../reusable/molecules/Modal';
import { CustomerContextState, ProdOrderType } from '../../../store/reducers/customer-context';
import { Activity, Persona, SecurityLevel } from '../../../utility/auth/useSecurity';
import { AuthState } from '../../../store/reducers/auth';
import { isPersonaAccount } from '../../../utility/helpers/user-helpers';
import { FlagTypes, useFeatureFlag } from '../../../utility/helpers/feature-flag';
import { selectIsImpersonation } from '../../../store/selectors';
import ImpersonationWarning from '../../reusable/molecules/ImpersonationWarning';

const useStyles = makeStyles((theme) => ({
    dashboard: {
        padding: '0'
    },
    main: {
        padding: '0',
        marginTop: '2.750em'
    },
    side: {
        backgroundColor: ltBlueGrey_34,
        flexDirection: 'column',
        padding: '1em',
        marginTop: '.5em'
    },
    actionBar: {
        margin: '1.5em 0',
        padding: '0'
    },
    btnWrapper: {
        '& button:last-child': {
            marginLeft: '1.25em'
        }
    },
    actionBtn: {
        borderRadius: 'unset'
    },
    disclaimer: {
        fontSize: small,
        marginTop: '1.875em'
    },
    deliveryWeekTitle: {
        fontSize: 25,
        fontWeight: blackWeight,
        color: ballGray,
        marginBottom: '1em'
    },
    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '0.5em'
    },
    warningModaltext: {
        padding: '2.25em 2.25em',
        fontSize: xl,
        fontWeight: boldWeight
    },
    warningBtnsWrapper: {
        padding: '1em 2.25em 1.625em 2.25em',
        '& > button': {
            '&:first-of-type': {
                marginRight: '1em'
            }
        }
    }
}));

const ShipItConfiguration = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const state = useTypedSelector((state) => state);
    const { selectedAccountId, shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { productsToBeShipped, shipmentsOrder, selectedDeliveryDate } =
        useTypedSelector<ShippingState>((state) => state.shippingDashboard);
    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);
    const [submit, setSubmit] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [focusedIndex, setFocusedIndex] = useState<number>(-1);
    const [submitReady, setSubmitReady] = useState<boolean>(false);
    const [customerPickUp, setCustomerPickUp] = useState<boolean>(false);
    const [isLargeCustomer, setIsLargeCustomer] = useState<boolean>(false);
    const [openWarningModal, setOpenWarningModal] = useState<boolean>(false);
    const [remainingBalance, setRemainingBalance] = useState<number>(0);
    const [focusedDeliveryDateIndex, setFocusedDeliveryDateIndex] = useState<number>(-1);
    const [leadTime, setLeadTime] = useState<number | undefined>();
    const [isCopacker, setIsCopacker] = useState<boolean>(false);
    const shipItLink = '/ship-it';
    const dashboardLink = '/dashboard';
    const deliveryWeek = moment(selectedDeliveryDate);
    const isImpersonation = useTypedSelector<boolean>(selectIsImpersonation);
    const [impersonationWarning, setImpersonationWarning] = useState<boolean>(false);

    const cokeFeatureFlag = useFeatureFlag().find((flag) => flag.name === FlagTypes.Coke)?.isActive;

    const onCloseConfirmation = () => {
        setOpen(false);
    };

    const onCancel = () => {
        setOpen(true);
    };

    const onConfirmedCancel = () => {
        setOpen(false);
        history.push(dashboardLink);
    };

    const handleAddProductToShipment = (product: ProductToShip) => {
        let currentShipments = shipmentsOrder as DeliveryShipment[];
        let type = product.type;
        const isEndType = isProductEndType(type);
        const isCanType = isProductCanType(type);

        if (isCanType) {
            productsToBeShipped.map((storedProduct, i) => {
                const can = isProductCanType(storedProduct.type);
                if (!can) {
                    dispatch(toggleAdd(storedProduct, true));
                }
                return null;
            });
        } else if (isEndType) {
            productsToBeShipped.map((storedProduct, i) => {
                const end = isProductEndType(storedProduct.type);
                if (!end) {
                    dispatch(toggleAdd(storedProduct, true));
                }
                return null;
            });
        }

        dispatch(toggleAdd(product, true));

        if (currentShipments.length !== 0 && focusedIndex !== -1) {
            let shipmentsArr = [...currentShipments];
            let currentLoads = [...currentShipments][focusedIndex].loads || [];
            let newShipment = {
                ...currentShipments[focusedIndex],
                loads: [
                    ...currentLoads,
                    { ...product, shipmentIndex: currentShipments.length - 1, palletQuantity: 0 }
                ],
                shipmentType: product.type
            };

            shipmentsArr.splice(focusedIndex, 1, newShipment);
            dispatch(updateShipments([...shipmentsArr]));
        }
    };

    const handleDeleteShipment = (index: number) => {
        let shipmentsArr = [...(shipmentsOrder as DeliveryShipment[])];

        shipmentsArr.splice(index, 1);
        setFocusedIndex(-1);
        dispatch(updateShipments([...shipmentsArr]));
        dispatch(disableAllProducts());
    };

    const handleCompletedShipment = (shipment: DeliveryShipment, index: number) => {
        let shipmentsArr = [...(shipmentsOrder as DeliveryShipment[])];
        let savedShipment = { ...shipment, saved: true };

        shipmentsArr.splice(index, 1, savedShipment);
        setFocusedIndex(-1);
        setFocusedDeliveryDateIndex(-1);
        dispatch(updateShipments([...shipmentsArr]));
    };

    const handleEditShipment = (
        shipment: DeliveryShipment,
        shipmentIndex: number,
        dateIndex: number
    ) => {
        let shipmentsArr = [...(shipmentsOrder as DeliveryShipment[])];
        let type = shipment.shipmentType;
        let filteredProducts: ProductToShip[] = [];

        if (type && productTypeHelper(type) === 'CAN') {
            filteredProducts = productsToBeShipped.filter((orders) => {
                const endOrder = isProductEndType(orders.type);
                return endOrder;
            });

            shipment.loads?.map((load) => {
                filteredProducts = filteredProducts.filter((product) => {
                    return product.productSku !== load.productSku;
                });
                return null;
            });
        } else if (type && productTypeHelper(type) === 'END') {
            filteredProducts = productsToBeShipped.filter((orders) => {
                const endOrder = isProductEndType(orders.type);
                return endOrder;
            });

            shipment.loads?.map((load) => {
                filteredProducts = filteredProducts.filter((product) => {
                    return product.productSku !== load.productSku;
                });
                return null;
            });
        }

        filteredProducts.map((product) => {
            if (
                product.calculatedTotalsForPrevNDays &&
                product.calculatedTotalsForPrevNDays[dateIndex] !== 0
            ) {
                dispatch(toggleAdd(product, false));
            }
            return null;
        });

        shipmentsArr.splice(shipmentIndex, 1, shipment);
        dispatch(updateShipments([...shipmentsArr]));
        setFocusedIndex(shipmentIndex);
        setFocusedDeliveryDateIndex(dateIndex);
    };

    const enableAddShipment = () => {
        if (updateShipments.length !== 0 && focusedIndex === -1) {
            return true;
        } else if (focusedIndex === -1) {
            return true;
        } else {
            return false;
        }
    };

    const createNewShipment = useCallback(() => {
        dispatch(updateShipments([{ loads: [], id: generateKey('shipment-') }]));
        setFocusedIndex(0);
    }, [dispatch]);

    const handleAddShipment = () => {
        dispatch(
            updateShipments([
                { loads: [], id: generateKey('shipment-') },
                ...(shipmentsOrder as DeliveryShipment[])
            ])
        );
        setFocusedIndex(0);
    };

    const handleDeliveryDateChange = (dateIndex: number, shipmentIndex: number) => {
        setFocusedDeliveryDateIndex(dateIndex);

        let shipmentsArr = [...(shipmentsOrder as DeliveryShipment[])];
        let savedShipment = { ...shipmentsArr[shipmentIndex] };

        shipmentsArr.splice(shipmentIndex, 1, savedShipment);
        dispatch(updateShipments([...shipmentsArr]));

        setFocusedDeliveryDateIndex(dateIndex);

        if (shipmentsOrder && shipmentsOrder.length && dateIndex !== -1) {
            productsToBeShipped.map((product) => {
                if (
                    product.calculatedTotalsForPrevNDays &&
                    product.calculatedTotalsForPrevNDays[dateIndex] !== 0
                ) {
                    dispatch(toggleAdd(product, false));
                } else {
                    dispatch(toggleAdd(product, true));
                }
                return null;
            });
        }
    };

    const handleRemoveProduct = (shipment: DeliveryShipment, shipmentIndex: number) => {
        let shipmentsArr = [...(shipmentsOrder as DeliveryShipment[])];

        shipmentsArr.splice(shipmentIndex, 1, shipment);

        if (shipment.loads && !shipment.loads.length) {
            shipmentsArr[shipmentIndex].shipmentType = undefined;
            dispatch(toggleAddDisabled(shipmentsArr[shipmentIndex]));
        }
        dispatch(updateShipments([...shipmentsArr]));
    };

    const checkSubmit = () => {
        let productBalance = 0;
        productsToBeShipped.map((product) => {
            productBalance += product.calculatedTotalsForPrevNDays
                ? product.calculatedTotalsForPrevNDays.slice(-1)[0]
                : 0;
            return null;
        });

        if (productBalance !== 0) {
            setRemainingBalance(productBalance);
            setOpenWarningModal(true);
        } else {
            handleSubmit();
        }
    };

    const handleSubmit = () => {
        let currentShipments: any[] = [];
        setSubmit(true);
        shipmentsOrder?.map((shipment, i) => {
            let tempLoads: any[] = [];

            shipment.loads?.map((load) => {
                tempLoads.push({
                    releaseNumber: load.releaseNumber ? load.releaseNumber : '',
                    referenceNumber: load.reference ? load.reference : '',
                    fillerLine: load.fillerLine ? load.fillerLine : '',
                    palletQuantity: load.palletQuantity,
                    sequence: load.sequence,
                    productSku: load.productSku,
                    purchaseOrderNumber: load.purchaseOrderNumber,
                    deliveryInstructions: load.deliveryInstructions
                });
                return null;
            });

            const deliveryTime = subtractTimezoneOffset(shipment.deliveryDateTime);

            currentShipments.push({
                truckId: i + 1,
                deliveryDateTime: deliveryTime,
                deliveryInstructions: shipment.deliveryInstructions,
                status: null,
                loads: tempLoads
            });
            return null;
        });
        if (!isImpersonation) {
            OrdersService.submitShipItOrder(currentShipments, state)
                .then((response) => {
                    let responseObj = { ...response.data };
                    let responseShipments = [...response.data.shipments];
                    if (responseObj.shipments.length) {
                        responseObj.shipments.map((shipment, i) => {
                            let tempShipment = { ...shipment };
                            let tempShipmentLoads = [...shipment.loads];

                            shipment.loads.map((load, x) => {
                                let tempLoad = { ...load };

                                productsToBeShipped.map((product) => {
                                    if (load.productSku === product.productSku) {
                                        tempLoad = {
                                            ...tempLoad,
                                            name: product.name,
                                            graphicId: product.graphicId,
                                            graphicVersion: product.graphicVersion,
                                            reference: tempLoad.referenceNumber,
                                            type: product.type,
                                            size: product.size,
                                            shape: product.shape,
                                            neckDiameter: product.neckDiameter,
                                            endProfile: product.endProfile,
                                            tabColor: product.tabColor,
                                            unit: product.unit,
                                            customerProductId: product.customerProductId,
                                            customerProductName: product.customerProductName,
                                            displayId: product.customerProductId
                                                ? product.customerProductId
                                                : product.productSku,
                                            displayName: product.customerProductName
                                                ? product.customerProductName
                                                : product.name,
                                            graphicIdAndVersion: product.graphicIdAndVersion,
                                            purchaseOrderNumber: tempLoad.purchaseOrderNumber,
                                            deliveryInstructions: tempLoad.deliveryInstructions
                                        };
                                        tempShipmentLoads.splice(x, 1, tempLoad);
                                        if (tempLoad.type === 'CAN' || tempLoad.type === 'BOTTLE') {
                                            tempShipment.shipmentType = 'Cans/Bottles';
                                        } else {
                                            tempShipment.shipmentType = 'Ends/Closures';
                                        }
                                    }
                                    return null;
                                });
                                return null;
                            });
                            shipment.shipmentType = tempShipment.shipmentType;
                            tempShipment = { ...shipment, loads: tempShipmentLoads };
                            responseShipments.splice(i, 1, tempShipment);
                            return null;
                        });
                    }

                    responseObj = { ...responseObj, shipments: responseShipments };
                    responseObj.customerPickup = customerPickUp;
                    dispatch(updateDeliveryOrder(responseObj));
                    history.push('/ship-it-confirmation');
                })
                .catch((error) => {
                    // handle error
                })
                .finally(() => {
                    setSubmit(false);
                });
        } else {
            setSubmit(false);
            setImpersonationWarning(true);
            setOpenWarningModal(false);
        }
    };

    const handleCloseSubmitWarning = () => {
        setOpenWarningModal(false);
    };

    const handleBackNavigation = () => {
        dispatch(updateConfigFlag(true));
        history.push(shipItLink);
    };

    const renderShipments = shipmentsOrder?.map((shipment, i) => {
        return (
            leadTime && (
                <ShipItConfigShipment
                    key={shipment.id}
                    shipmentIndex={i}
                    shipment={shipment}
                    onDeleteShipment={handleDeleteShipment}
                    onCompleteShipment={handleCompletedShipment}
                    onEditShipment={handleEditShipment}
                    shipments={shipmentsOrder as DeliveryShipment[]}
                    onSelectDeliveryDate={handleDeliveryDateChange}
                    onDeleteProduct={handleRemoveProduct}
                    customerPickUp={customerPickUp}
                    isCopacker={isCopacker}
                    leadTime={leadTime}
                    isLargeCustomer={isLargeCustomer}
                />
            )
        );
    });

    useEffect(() => {
        dispatch(loadProductsToBeShipped());
        createNewShipment();

        if (selectedAccountId && shipToAccounts) {
            shipToAccounts.map((shipTo) => {
                if (shipTo.accountId === selectedAccountId) {
                    if (shipTo.modeOfTransport === 'CPU') {
                        setCustomerPickUp(true);
                    }
                    if (shipTo.prodOrderType === ProdOrderType.AuthorizationToManufacture) {
                        setIsLargeCustomer(!!cokeFeatureFlag);
                    }
                }
                return null;
            });

            // check to see if the user is a co-packer on the selected ship to
            if (permissions) {
                const isCopackerCheck = isPersonaAccount(
                    permissions,
                    Persona.CoPacker,
                    selectedAccountId
                );
                setIsCopacker(!!isCopackerCheck);
            }
            setLeadTime(getDeliveryLeadTimeDays(parseInt(selectedAccountId), shipToAccounts));
        }
        // Runs only once
    }, []);

    useEffect(() => {
        if (shipmentsOrder?.length === 0) {
            createNewShipment();
        } else {
            let isReadyToSubmit = true;
            shipmentsOrder?.map((shipment) => {
                if (!shipment.saved) {
                    isReadyToSubmit = false;
                }
                return null;
            });
            setSubmitReady(isReadyToSubmit);
        }
    }, [createNewShipment, shipmentsOrder]);

    useEffect(() => {
        if (focusedIndex === -1 && focusedDeliveryDateIndex === -1) {
            dispatch(disableAllProducts());
        }
    }, [shipmentsOrder, focusedDeliveryDateIndex, focusedIndex, dispatch]);

    useTranslation();

    const footerActions = (
        <>
            <Grid container item xs={2} className={classes.btnWrapper}>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="cancel-btn"
                    onClick={onCancel}
                    className={classes.actionBtn}
                >
                    <Trans i18nKey="cancel">Cancel</Trans>
                </Button>
            </Grid>
            <Grid container item xs={7}>
                <ImpersonationWarning showWarning={impersonationWarning} warningType={'SUBMIT'} />
            </Grid>
            <Grid container item xs={3} justify="flex-end" className={classes.btnWrapper}>
                <Button
                    type="button"
                    color="primary"
                    variant="outlined"
                    data-testid="back-btn"
                    className={classes.actionBtn}
                    disabled={submit}
                    onClick={handleBackNavigation}
                >
                    <Trans i18nKey="back">Back</Trans>
                </Button>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    data-testid="submit-btn"
                    className={classes.actionBtn}
                    disabled={submit || !submitReady}
                    onClick={checkSubmit}
                >
                    <Trans i18nKey="submit">Submit</Trans>
                </Button>
            </Grid>
        </>
    );

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('shipIt', 'Ship It'),
                description: t('buildYourShipments', 'BUILD YOUR SHIPMENTS'),
                thinBanner: true,
                displayDropdown: true,
                disableSelect: true
            }}
            actionFooter={{
                footerAction: footerActions,
                justify: 'space-between',
                sticky: true
            }}
            activity={Activity.NewOpenDeliveryOrders}
            restrictToSecurityLevel={SecurityLevel.Edit}
        >
            <Grid container spacing={2} alignItems="flex-start" className={classes.dashboard}>
                <Grid container item md={3} className={classes.side}>
                    {leadTime && (
                        <ShipItConfigProductsToShip
                            products={productsToBeShipped}
                            onAddProduct={handleAddProductToShipment}
                            enableAdd={enableAddShipment()}
                            onAddShipment={handleAddShipment}
                            shipments={shipmentsOrder as DeliveryShipment[]}
                            deliveryDateIndex={focusedDeliveryDateIndex}
                            deliveryDate={deliveryWeek}
                            leadTime={leadTime}
                        />
                    )}
                </Grid>
                <Grid
                    container
                    item
                    md={9}
                    className={classes.main}
                    data-testid="delivery-week"
                    data-value={getSimpleFormattedDate('', deliveryWeek)}
                >
                    <Typography className={classes.deliveryWeekTitle}>
                        <Trans i18nKey="deliveryWeek">Delivery Week</Trans>:{' '}
                        {getSimpleFormattedDate('', deliveryWeek)}
                    </Typography>
                    {renderShipments}
                </Grid>
            </Grid>
            {submit && (
                <Grid container item xs={12} alignItems="center" className={classes.actionBar}>
                    <Grid container item xs={12} className={classes.spinningLoader}>
                        <CircularProgress />
                    </Grid>
                </Grid>
            )}
            <OrderCancelModal
                open={open}
                saveProgress={onConfirmedCancel}
                navigationLink={dashboardLink}
                onClose={onCloseConfirmation}
                onCancel={onCloseConfirmation}
            />
            <Modal
                open={openWarningModal}
                close={handleCloseSubmitWarning}
                title={t('youDidntLoadAll', `You didn't load all your pallets`)}
                closeIcon={true}
                fullWidth={true}
            >
                <Grid container>
                    <Grid item>
                        <Typography className={classes.warningModaltext}>
                            {
                                <Trans i18nKey="remainingPalletsToBeOrdered">
                                    There are {{ remainingBalance }} unassigned pallets in your
                                    order. If you continue, the unloaded pallets will remain
                                    unreleased and available for future delivery orders.
                                </Trans>
                            }
                        </Typography>
                    </Grid>
                    <Grid container item justify="flex-end" className={classes.warningBtnsWrapper}>
                        <Button
                            type="button"
                            color="primary"
                            variant="outlined"
                            data-testid="continue-btn"
                            disabled={submit}
                            onClick={handleCloseSubmitWarning}
                        >
                            <Trans i18nKey="continueBuildingShipments">
                                Continue Building Shipments
                            </Trans>
                        </Button>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            data-testid="submit-btn"
                            disabled={submit}
                            onClick={handleSubmit}
                        >
                            <Trans i18nKey="submit">Submit</Trans>
                        </Button>
                    </Grid>
                </Grid>
            </Modal>
        </ProcessingPageTemplate>
    );
};

export default ShipItConfiguration;
