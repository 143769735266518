import React, { ReactNode } from 'react';
import {
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox as MaterialCheckbox,
    withStyles
} from '@material-ui/core';

interface Props {
    classes?: object;
    className?: string;
    size?: any;
    checked: boolean;
    label: ReactNode;
    ariaLabel: string;
    onChange: (value) => void;
    placement?: 'end' | 'start' | 'bottom' | 'top';
}

const StyledCheckbox = withStyles((theme) => ({
    root: {
        color: theme.palette.secondary.light,
        '&$checked': {
            color: theme.palette.primary.dark
        }
    },
    checked: {}
}))(MaterialCheckbox);

const Checkbox = (props: Props) => {
    return (
        <FormControl>
            <FormGroup>
                <FormControlLabel
                    control={
                        <StyledCheckbox
                            classes={props.classes}
                            checked={props.checked || false}
                            onChange={(event) => {
                                props.onChange(event.target.checked);
                            }}
                            inputProps={{ 'aria-label': props.ariaLabel }}
                            size={props.size || 'medium'}
                        />
                    }
                    label={props.label}
                    className={props.className}
                    labelPlacement={props.placement || 'end'}
                    data-testid={props['data-testid']}
                />
            </FormGroup>
        </FormControl>
    );
};

export default Checkbox;
