import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import ManageLocationsTableColumns from './ManageLocationsTableColumns';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { StandardTableStyles } from '../../../reusable/molecules/StandardTable';
import ManageLocationsTableRow from './ManageLocationsTableRow';
import { AddressType, CustomerContextState } from '../../../../store/reducers/customer-context';

const useStyles = makeStyles((theme) => {
    return {
        ...StandardTableStyles(theme, [4, 5, 2, 1])
    };
});

export default function ManageLocationsTable() {
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { adminAccounts, loaded } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const classes = useStyles();
    const [tableData, setTableData] = useState<any[]>([]);
    const toolbarOptions = new ToolbarOptions({
        advancePagination: false,
        exportButton: false,
        printButton: false,
        enablePagination: false,
        searchText: true,
        itemsPerPage: 50
    });
    const billToIds = [AddressType.CB, AddressType.CX];

    useEffect(() => {
        if (loaded && adminAccounts) {
            setTableData(adminAccounts.filter((a) => billToIds.includes(a.addressType)));
        }
    }, [adminAccounts]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Paper>
                    <Grid container>
                        <Grid
                            container
                            item
                            xs={12}
                            direction="row-reverse"
                            className={classes.tableWrapper}
                        >
                            <DataGrid
                                columns={ManageLocationsTableColumns.getManageLocationsTableColumns(
                                    cultureCode
                                )}
                                rowComponent={ManageLocationsTableRow}
                                dataSource={tableData}
                                gridName={'manage-locations'}
                                toolbarOptions={toolbarOptions}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}
