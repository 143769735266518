import { Grid, makeStyles, Typography, Paper } from '@material-ui/core';
import React from 'react';
import { Trans } from 'react-i18next';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { UserAdminState } from '../../../../store/reducers/user-admin';
import { ballGray, boldWeight } from '../../../../themes/globalConstants';
import { formatForUsersTimezone } from '../../../../utility/helpers/date-helpers';

const useStyles = makeStyles((theme) => ({
    main: {
        marginBottom: '1.5em',
        paddingBottom: '3em'
    },
    subtitle: {
        color: ballGray
    },
    item: {
        paddingLeft: '1em'
    },
    paper: {
        marginBottom: '1em',
        paddingLeft: '3em',
        paddingTop: '1em'
    },
    titleContainer: {
        marginBottom: '1em'
    },
    title: {
        paddingTop: '1em',
        fontSize: '1.5em',
        fontWeight: boldWeight,
        color: theme.palette.secondary.main,
        paddingLeft: '0.625em'
    }
}));

const UserInformation = () => {
    const classes = useStyles();

    const { userToEdit, firstName, lastName, lastLogin } = useTypedSelector<UserAdminState>(
        (state) => state.userAdmin
    );

    const fullName = firstName && lastName ? `${firstName} ${lastName}` : '';

    return (
        <Paper elevation={2} className={classes.paper}>
            <div className={classes.titleContainer}>
                <Typography className={classes.title}>
                    {<Trans i18nKey="userInformation">User Information</Trans>}
                </Typography>
            </div>
            <Grid container className={classes.main}>
                <Grid container item xs={12} justify="flex-start">
                    <Grid item xs={3} className={classes.item}>
                        <Typography variant="subtitle2" className={classes.subtitle}>
                            <Trans i18nKey="email">Email</Trans>
                        </Typography>
                        <Typography variant="body1" data-testid="user-email">
                            {userToEdit}
                        </Typography>
                    </Grid>
                    <Grid item xs={3} className={classes.item}>
                        <Typography variant="subtitle2" className={classes.subtitle}>
                            <Trans i18nKey="name">Name</Trans>
                        </Typography>
                        <Typography variant="body1" data-testid="user-full-name">
                            {fullName ? fullName : ''}
                        </Typography>
                    </Grid>
                    <Grid item xs={3} className={classes.item}>
                        <Typography variant="subtitle2" className={classes.subtitle}>
                            <Trans i18nKey="lastLogin">Last Login</Trans>
                        </Typography>
                        <Typography variant="body1" data-testid="user-last-login">
                            {lastLogin ? formatForUsersTimezone(lastLogin) : ''}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};
export default UserInformation;
