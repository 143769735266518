import { Collapse, Grid, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { MakeItBulkATMLinesBySnoSku } from '../../../../store/reducers/makeit-bulk-atm';
import { blackWeight, lato, xl } from '../../../../themes/globalConstants';
import { ToggleAll } from '../../../../utility/helpers/order-helpers';
import { MakeItBulkATMOrderSectionHeader } from '../../../reusable/molecules/MakeItBulkATMOrderSectionHeader';
import { useDispatch } from 'react-redux';
import { updateExpand } from '../../../../store/root-actions';
import { useTranslation } from 'react-i18next';
import { InfoSubheaderItem } from '../../../../store/reducers/glp';
import MakeItBulkATMConfirmationSubheader from './MakeItBulkATMConfirmationSubheader';
import MakeItBulkATMConfirmationOrderSectionGrid from './MakeItBulkATMConfirmationOrderSectionGrid';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { getAccountAddress } from '../../../../utility/helpers/address-helpers';
import { getFormattedEachesFromNumber } from '../../../../utility/helpers/shipment-helpers';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { formatNumberWithLocale } from '../../../../utility/helpers/formatters';
import { ProductType } from '../../../../utility/services/orders-service';

interface Props {
    order: MakeItBulkATMLinesBySnoSku;
    shipToId: number;
    expand: ToggleAll;
    isActive: boolean;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: '1em',
        position: 'relative',
        '& h2': {
            fontSize: xl,
            fontFamily: lato,
            fontWeight: blackWeight,
            color: theme.palette.secondary.main
        }
    },
    collapse: {
        width: '100%'
    },
    quantityContainer: {
        alignContent: 'center'
    },
    subheaderPaper: {
        marginTop: '2em',
        padding: '1em',
        borderRadius: 2,
        width: '100%'
    }
}));

const MakeItBulkATMConfirmationOrderSection = ({ order, shipToId, isActive }: Props) => {
    const classes = useStyles();
    const product = order.lines[0];
    const [openDrawer, setOpenDrawer] = useState<boolean>();
    const [shipToName, setShipToName] = useState<string>('');
    const [shipToAddress, setShipToAddress] = useState<string>('');
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const handleToggleExpand = () => {
        dispatch(updateExpand(shipToId, order.snoSku, order.weekStart));
        setOpenDrawer(!openDrawer);
    };

    const getQuantity = (type: string): String => {
        if (product.type !== type) {
            return '0 PL';
        }
        const pallets = order.totalPalletQuantity;
        const skus = order.lines.length;
        return `${formatNumberWithLocale(cultureCode, pallets)} PL/${skus} ${
            skus > 1 ? t('skus', 'SKUs') : t('sku', 'SKU')
        }`;
    };

    const getTotalEaches = () => {
        let totalEaches = 0;
        order.lines.forEach((line) => {
            if (line && line.palletQuantity && line.quantityPerPallet) {
                totalEaches += line.palletQuantity * line.quantityPerPallet;
            }
        });
        return totalEaches;
    };

    const createSubheader = () => {
        const totalEnteredQuantity = order.allocationAmount
            ? `${formatNumberWithLocale(
                  cultureCode,
                  order.totalPalletQuantity
              )} / ${formatNumberWithLocale(cultureCode, order.allocationAmount)} PL`
            : `${formatNumberWithLocale(cultureCode, order.totalPalletQuantity)} PL`;
        const canQuantity = getQuantity(ProductType.Cans);
        const endQuantity = getQuantity(ProductType.Ends);
        const totalEaches = getTotalEaches();
        const formattedEaches = `${getFormattedEachesFromNumber(totalEaches, cultureCode)} ea.`;

        return [
            {
                subtitle: t('totalEnteredQuantity', 'Total Entered Quantity'),
                body: totalEnteredQuantity,
                subtext: formattedEaches
            },
            {
                subtitle: t('canQuantity', 'Can Quantity'),
                body: canQuantity,
                subtext: canQuantity !== '0 PL' && formattedEaches
            },
            {
                subtitle: t('endQuantity', 'End Quantity'),
                body: endQuantity,
                subtext: endQuantity !== '0 PL' && formattedEaches
            }
        ] as InfoSubheaderItem[];
    };

    useEffect(() => {
        setOpenDrawer(isActive);
    }, [isActive]);

    useEffect(() => {
        if (shipToAccounts && (!shipToName || !shipToAddress)) {
            const shipToAccount = shipToAccounts.find(
                (account) => String(shipToId) === account.accountId
            );
            if (shipToAccount) {
                setShipToName(shipToAccount.name);
                setShipToAddress(getAccountAddress(shipToAccount));
            }
        }
    }, [shipToAccounts, shipToAddress, shipToId, shipToName]);

    return (
        <Grid container>
            <Paper
                elevation={2}
                className={classes.subheaderPaper}
                key={`${shipToId}-${order.snoSku}`}
                data-testid="atm-order-section"
            >
                <Grid container xs={12}>
                    <Grid container item xs={6} justify="flex-start">
                        <MakeItBulkATMOrderSectionHeader
                            product={product}
                            shipToId={shipToId}
                            shipToName={shipToName}
                            shipToAddress={shipToAddress}
                            handleToggleExpand={handleToggleExpand}
                            openDrawer={openDrawer}
                        />
                    </Grid>
                    <Grid
                        container
                        item
                        xs={6}
                        justify="flex-end"
                        className={classes.quantityContainer}
                    >
                        <MakeItBulkATMConfirmationSubheader
                            subheaderItems={createSubheader()}
                            justify="flex-end"
                        />
                    </Grid>
                </Grid>
                <Collapse
                    in={openDrawer}
                    className={classes.collapse}
                    data-testid="collapse-section"
                >
                    <MakeItBulkATMConfirmationOrderSectionGrid lines={order.lines} />
                </Collapse>
            </Paper>
        </Grid>
    );
};

export default MakeItBulkATMConfirmationOrderSection;
