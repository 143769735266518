import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
    makeStyles,
    Typography,
    TableRow,
    TableCell,
    Grid,
    Input,
    IconButton,
    InputAdornment,
    Link
} from '@material-ui/core';
import {
    ballLtGray,
    small,
    white,
    regularWeight,
    ballBlue,
    xs,
    shipRed,
    errorBackground,
    warningBorder,
    vanilla,
    boldWeight,
    informationAlert,
    black
} from '../../../../themes/globalConstants';
import Button from '../../../reusable/atoms/Button';
import {
    constructCanDescription,
    constructEndDescription,
    isProductCanType,
    isProductEndType
} from '../../../../utility/helpers/order-helpers';
import Modal from '../../../reusable/molecules/Modal';
import moment from 'moment';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import clsx from 'clsx';
import {
    createLeadTimeDisplay,
    setDateTimeToMidnight
} from '../../../../utility/helpers/date-helpers';
import SingleDaypickerModal from '../../../reusable/molecules/SingleDaypickerModal';
import {
    getMakeItLeadTime,
    getMoqFeeCeiling,
    getMoqFeeFloor
} from '../../../../utility/helpers/production-order-helpers';
import MakeItBulkUploadReviewChildRow from './MakeItBulkUploadReviewChildRow';
import { updatePalletQuantity } from '../../../../store/root-actions';
import { useDispatch } from 'react-redux';
import {
    getPalletQuantityKey,
    PalletQuantityState,
    getFeeStructure,
    evaluatePalletQuantityValid,
    handleSplit
} from '../../../../utility/helpers/make-it-bulk-helpers';
import {
    deleteProductFromOrder,
    deleteProductionOrder,
    handleChildLineUpdates,
    updateMakeItBulkItemProductSku,
    updateMakeItBulkItemRequestedDate
} from '../../../../store/actions/makeit-bulk-upload';
import { MakeItBulkLineItem } from '../../../../store/reducers/makeit-bulk-upload';
import { useMakeItBulkUploadReviewOrderSection } from '../context/MakeItBulkUploadReviewOrderSectionContext';
import { enUS } from '../../../../utility/translations/locales/';
import { numericRegex } from '../../../../utility/helpers/formatters';
import ConfirmationModal from '../../../reusable/molecules/ConfirmationModal';
import MakeItBulkProductIdModal from '../../../reusable/molecules/MakeItBulkProductIdModal';
import { Alert } from '@material-ui/lab';
import { getEachesConversion } from '../../../../utility/helpers/shipment-helpers';

interface Props {
    item: MakeItBulkLineItem;
}

const useStyles = makeStyles((theme) => ({
    tableRowContainer: {
        '& .helper-text': {
            fontWeight: `${regularWeight} !important`,
            fontSize: small
        },
        '& .validation-error-text': {
            fontWeight: `${boldWeight} !important`,
            fontSize: small
        },
        '& .MuiInputBase-input': {
            textAlign: 'right',
            paddingRight: '.25em'
        }
    },
    textField: {
        width: '6em',
        height: '1.5em',
        border: `1.8px solid ${ballLtGray}`,
        borderRadius: '.25em',
        marginRight: '0.5em',
        backgroundColor: white
    },
    alignCenter: {
        textAlign: 'center'
    },
    bold: {
        fontWeight: boldWeight
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    quantityText: {
        paddingLeft: '0.5em'
    },
    splitContainer: {
        borderLeft: 'none',
        paddingLeft: 0
    },
    split: {
        transform: 'rotate(90deg)',
        fill: ballBlue
    },
    splitText: {
        color: ballBlue,
        fontSize: xs
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    reqDateBeforeFirstAvailErr: {
        verticalAlign: 'middle'
    },
    trash: {
        minWidth: 'unset'
    },
    calendarDiv: {
        margin: '2em'
    },
    calendarInput: {
        border: `1.8px solid ${ballLtGray}`,
        borderRadius: 2,
        cursor: 'pointer',
        float: 'right',
        backgroundColor: white
    },
    calendarIcon: {
        color: ballBlue
    },
    feeError: {
        border: `1px solid ${shipRed}`,
        borderRight: 'none',
        backgroundColor: errorBackground
    },
    requestedDateError: {
        border: `1px solid ${shipRed}`,
        backgroundColor: errorBackground
    },
    feeWarning: {
        border: `1px solid ${warningBorder}`,
        borderRight: 'none',
        backgroundColor: vanilla
    },
    splitFeeWarning: {
        border: `1px solid ${warningBorder}`,
        borderLeft: 'none',
        backgroundColor: vanilla
    },
    splitFeeError: {
        border: `1px solid ${shipRed}`,
        borderLeft: 'none',
        backgroundColor: errorBackground
    },
    dupSkuError: {
        border: `1px solid ${shipRed}`,
        backgroundColor: errorBackground
    },
    link: {
        fontSize: small,
        fontWeight: regularWeight,
        '&:hover': {
            cursor: 'pointer'
        }
    },
    informationalAlert: {
        backgroundColor: informationAlert,
        borderRadius: '.125em',
        alignItems: 'center',
        maxHeight: '2.7em'
    },
    informationAlertText: {
        fontWeight: boldWeight,
        fontSize: small,
        color: black
    }
}));

const MakeItBulkUploadReviewRow = ({ item }: Props) => {
    const classes = useStyles();
    const [purchaseQty, setPurchaseQty] = useState<number>(0);
    const [isSplit, setIsSplit] = useState<boolean>(false);
    const [totalSplitPalletQty, setTotalSplitPalletQty] = useState<number>(0);
    const [firstDay, setFirstDay] = useState<moment.Moment | null>(null);
    const [subRows, setSubrows] = useState<MakeItBulkLineItem[]>([]);
    const [selectedDate, setSelectedDate] = useState<string>();
    const [daypickerDate, setDaypickerDate] = useState<string>();
    const [calendarOpen, setCalendarOpen] = useState(false);
    const [counter, setCounter] = useState(0);
    const [disabled, setDisabled] = useState<boolean>(false);
    const canBottleDesc = constructCanDescription(item);
    const endDesc = constructEndDescription(item);
    const isEndType = isProductEndType(item.type);
    const isCanType = isProductCanType(item.type);
    const leadTime = item.leadTimeWeeks ?? getMakeItLeadTime(item);
    const baseLeadTimeDisplay = createLeadTimeDisplay(leadTime);
    const leadTimeDisplay = item.requestedDate
        ? item.requestedDate
        : createLeadTimeDisplay(leadTime);
    // Warning and Error logic
    const hasMoqFees = item.moqFees && item.moqFees.length > 0;
    const moqFeeFloor = getMoqFeeFloor(item.moqFees);
    const moqFeeCeiling = getMoqFeeCeiling(item.moqFees);
    const [reqDateBeforeFirstAvail, setReqDateBeforeFirstAvail] = useState<boolean>(false);
    const dispatch = useDispatch();
    const [palletQuantityState, setPalletQuantityState] = useState<PalletQuantityState>(undefined);
    const { markedRemoved, lineCount } = useMakeItBulkUploadReviewOrderSection();
    const [openDeleteLastProductModal, setOpenDeleteLastProductModal] = useState<boolean>(false);
    const uniqueProductId: boolean = !!item.isCustomerProductIdDistinct;
    const [productIdOpen, setProductIdOpen] = useState(false);
    const customerProductId = item.customerProductId;
    const candidateProductSkus = item.candidateProductSkus;

    useEffect(() => {
        setDisabled(isSplit || markedRemoved);
    }, [isSplit, markedRemoved]);

    const today = moment().format('MM/DD/YYYY');
    const calendarIcon = (
        <img
            className={classes.calendarIcon}
            src={process.env.PUBLIC_URL + '/assets/Calendar_Icon.svg'}
            alt="Calendar Icon"
        />
    );
    const disabledCalendarIcon = (
        <img
            src={process.env.PUBLIC_URL + '/assets/Disabled_Calendar_Icon.svg'}
            alt="Calendar Icon"
        />
    );

    const onOpenCalendar = () => {
        if (disabled) {
            //prevents calendar from opening when it should be disabled
            return;
        }
        setCalendarOpen(true);
    };

    const onCloseCalendar = () => {
        setCalendarOpen(false);
    };

    const onOpenProductId = () => {
        setProductIdOpen(true);
    };

    const onCloseProductId = () => {
        setProductIdOpen(false);
    };

    const handleOkClose = () => {
        setCalendarOpen(false);
        setSelectedDate(daypickerDate);

        if (daypickerDate !== item.requestedDate) {
            item.requestedDate = daypickerDate;
            dispatch(
                updateMakeItBulkItemRequestedDate(
                    item.customerProductionOrderId,
                    item.productSku,
                    item.requestedDate,
                    item.shipToId
                )
            );
        }
    };

    const setQuantityAndMOQValues = (quantity: number) => {
        if (item.minimumOrderQuantity) {
            const minValue = item.moqFees
                ? getMoqFeeFloor(item.moqFees)
                : item.minimumOrderQuantity;
            item.withinRange = minValue <= quantity && quantity < item.minimumOrderQuantity;
            item.minimumMet = quantity >= item.minimumOrderQuantity;
        } else {
            item.minimumMet = quantity !== null && quantity !== undefined && quantity !== 0;
        }
        item.palletQuantity = quantity;
        item.palletsRounded = false;
    };

    const palletQuantityInputChange = (count) => {
        let quantity = 0;
        if (numericRegex(count)) {
            if (!isNaN(parseInt(count))) {
                quantity = parseInt(count);
            }
            setPurchaseQty(quantity);
        }
    };

    const handlePalletQuantityOnBlur = (value) => {
        const quantity = parseInt(value);
        setQuantityAndMOQValues(quantity);
        dispatch(updatePalletQuantity(item.customerProductionOrderId!, item.productSku!, item));
    };

    const handleDateSelect = (newDate: moment.Moment | null) => {
        const date = moment(newDate).format('MM/DD/YYYY');
        setDaypickerDate(date);
    };

    const onSplit = () => {
        const splitRows = handleSplit(item, subRows, counter, selectedDate);

        // Update item, local subrows, and isSplit
        item.childLines = splitRows.childLines;
        item.palletQuantity = splitRows.palletQuantity;
        item.requestedDate = splitRows.requestedDate;

        setCounter(splitRows.counter);
        setSubrows(splitRows.childLines);
        setIsSplit(true);
        setReqDateBeforeFirstAvail(false);
        dispatch(handleChildLineUpdates(item));
    };

    const handleRemoveParentItem = () => {
        if (isLastProductInOrder()) {
            setOpenDeleteLastProductModal(true);
        } else {
            dispatch(
                deleteProductFromOrder(
                    item.customerProductionOrderId!,
                    item.productSku!,
                    item.shipToId!
                )
            );
        }
    };

    function isLastProductInOrder() {
        return lineCount === 1;
    }

    const handleRemoveChildItem = (subrowIndex: number) => {
        dispatch(
            deleteProductFromOrder(
                item.customerProductionOrderId!,
                item.productSku!,
                item.shipToId!,
                {
                    childLineIndex: subrowIndex
                }
            )
        );
    };

    // Calculate total for rows with subrows
    const totalCalculations = (rows: MakeItBulkLineItem[]) => {
        let total = 0;
        rows.forEach((item) => {
            if (item.palletQuantity) {
                return (total += item.palletQuantity);
            }
        });
        setTotalSplitPalletQty(total);
    };

    useEffect(() => {
        if (item && item.firstAvailableDate) {
            setFirstDay(moment(item.firstAvailableDate));
        }
    }, [item]);

    useEffect(() => {
        if (!selectedDate) {
            setSelectedDate(moment(leadTimeDisplay).format('MM/DD/YYYY'));
        }
    }, [leadTimeDisplay, selectedDate]);

    useEffect(() => {
        let isDateBeforeFirstAvail = false;
        if (selectedDate && firstDay) {
            if (setDateTimeToMidnight(selectedDate).isBefore(firstDay) && !isSplit) {
                isDateBeforeFirstAvail = true;
            }
            setReqDateBeforeFirstAvail(isDateBeforeFirstAvail);
        }
    }, [selectedDate, firstDay, isSplit]);

    useEffect(() => {
        let totalPalletCount: number = 0;
        if (
            item.requestedDate &&
            item.palletQuantity &&
            (!item.childLines || item.childLines.length === 0)
        ) {
            totalPalletCount = item.palletQuantity;
            setSelectedDate(moment(item.requestedDate).format('MM/DD/YYYY'));
            setPurchaseQty(item.palletQuantity);
        } else if (item.childLines && item.childLines.length > 0) {
            totalPalletCount = 0;
            item.childLines.forEach((row) => {
                if (row.palletQuantity) {
                    return (totalPalletCount += row.palletQuantity);
                }
            });
            setTotalSplitPalletQty(totalPalletCount);
            setPurchaseQty(0);
            setSubrows(item.childLines);
            setIsSplit(true);
            setCounter(item.childLines.length);
        }

        setPalletQuantityState(
            evaluatePalletQuantityValid(
                totalPalletCount,
                item.minimumOrderQuantity!,
                getFeeStructure(item),
                getMoqFeeFloor(item.moqFees),
                getMoqFeeCeiling(item.moqFees)
            )
        );
    }, [item]);

    // Updates total calculations for rows with subrows
    const handleQtyUpdate = () => {
        totalCalculations(subRows);
    };

    const handleSubRowSelectedDateChange = () => {
        dispatch(handleChildLineUpdates(item));
    };

    const handleQuantityOnBlur = () => {
        updateMinimumMet();
    };

    // Validates whether or not minimum qty has been met
    const updateMinimumMet = () => {
        const quantity = purchaseQty === 0 ? totalSplitPalletQty : purchaseQty;
        setQuantityAndMOQValues(quantity);
        dispatch(handleChildLineUpdates(item));
    };

    useTranslation();

    function getQuantityFeeVerbiage() {
        return `${moqFeeFloor}-${moqFeeCeiling} PL`;
    }

    function hasPalletQuantityError(palletQuantityState: PalletQuantityState): boolean {
        switch (palletQuantityState) {
            case 'zeroError':
            case 'minimumOrderQuantityError':
                return true;
            default:
                return false;
        }
    }

    function onConfirmRemoveLastProduct() {
        setOpenDeleteLastProductModal(false);
        dispatch(deleteProductionOrder(item.customerProductionOrderId!, item.shipToId!));
    }

    function handleProductSkuSelection(product: MakeItBulkLineItem) {
        dispatch(
            updateMakeItBulkItemProductSku(
                item.customerProductionOrderId!,
                item.customerProductId!,
                product,
                item.shipToId
            )
        );
    }

    return (
        <>
            <TableRow
                className={classes.tableRowContainer}
                data-testid={`product-${item.displayId}`}
            >
                <TableCell className={classes.alignCenter}>
                    <Typography className={classes.bold}>{item.originalCsvLineNumber}</Typography>
                </TableCell>
                <TableCell
                    className={clsx({
                        [classes.dupSkuError]: !uniqueProductId
                    })}
                >
                    {uniqueProductId && (
                        <Grid container alignContent="center">
                            <Grid container>
                                <Typography>{item.displayId}</Typography>
                            </Grid>
                            <Grid container>
                                <Typography>{item.graphicIdAndVersion}</Typography>
                            </Grid>
                        </Grid>
                    )}
                    {!uniqueProductId && (
                        <Grid container alignContent="center">
                            <Grid container>
                                <img
                                    src={process.env.PUBLIC_URL + '/assets/Error_Icon.svg'}
                                    alt="Error Icon"
                                />
                                <Typography>{item.displayId}</Typography>
                            </Grid>
                            <Grid container>
                                <Typography
                                    className="helper-text"
                                    data-testid="dup-product-id-helper"
                                >
                                    <Trans i18nKey="dupProductId">
                                        Duplicate Product IDs found
                                    </Trans>
                                </Typography>
                            </Grid>
                            <Grid container>
                                <Link
                                    className={classes.link}
                                    underline="always"
                                    data-testid="dup-product-id-link"
                                    onClick={onOpenProductId}
                                >
                                    <Typography className="helper-text">
                                        <Trans i18nKey="clickToChooseCorrect">
                                            Click to choose the correct one.
                                        </Trans>
                                    </Typography>
                                </Link>
                            </Grid>
                        </Grid>
                    )}
                </TableCell>
                <TableCell data-testid="product-description">
                    <Typography>{item.displayName}</Typography>
                    {isEndType && <Typography>{endDesc}</Typography>}
                    {isCanType && <Typography>{canBottleDesc}</Typography>}
                </TableCell>
                <TableCell>
                    <Typography>{item.coating}</Typography>
                </TableCell>
                <TableCell data-testid="leadtime-information">
                    <Typography data-leadtime={leadTime} data-testid="leadtime">
                        {`${leadTime} `}
                        <Trans i18nKey="weeks">Weeks</Trans>
                    </Typography>
                    <Typography data-testid="leadtime-date">{baseLeadTimeDisplay}</Typography>
                </TableCell>
                <TableCell
                    className={clsx(classes.alignCenter, {
                        [classes.requestedDateError]: reqDateBeforeFirstAvail
                    })}
                    data-testid="calendar-input"
                >
                    <Grid container>
                        <Grid container item alignItems="flex-start">
                            <Grid item xs={2}>
                                {reqDateBeforeFirstAvail && (
                                    <img
                                        className={classes.reqDateBeforeFirstAvailErr}
                                        src={process.env.PUBLIC_URL + '/assets/Error_Icon.svg'}
                                        alt="Error Icon"
                                    />
                                )}
                            </Grid>
                            <Grid item xs={10}>
                                <Input
                                    id={item.productSku}
                                    type="button"
                                    fullWidth
                                    disabled={disabled}
                                    onClick={onOpenCalendar}
                                    disableUnderline={true}
                                    value={selectedDate}
                                    className={classes.calendarInput}
                                    data-testid={`requested-date-${item.productSku}`}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="calendar modal trigger"
                                                onClick={onOpenCalendar}
                                                disabled={disabled}
                                            >
                                                {disabled ? disabledCalendarIcon : calendarIcon}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </Grid>
                        </Grid>
                        {reqDateBeforeFirstAvail && (
                            <Grid container>
                                <Typography
                                    className="helper-text"
                                    data-testid="early-requested-date-helper"
                                >
                                    <Trans i18nKey="chooseLaterDate">
                                        Please choose a later date
                                    </Trans>
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </TableCell>
                <TableCell
                    className={clsx(
                        {
                            [classes.feeError]: hasPalletQuantityError(palletQuantityState)
                        },
                        {
                            [classes.feeWarning]: palletQuantityState === 'feeWarning'
                        }
                    )}
                >
                    <Grid container alignContent="center">
                        {hasPalletQuantityError(palletQuantityState) && (
                            <img
                                src={process.env.PUBLIC_URL + '/assets/Error_Icon.svg'}
                                alt="Error Icon"
                            />
                        )}
                        {palletQuantityState === 'feeWarning' && (
                            <img
                                src={process.env.PUBLIC_URL + '/assets/Warning_Icon.svg'}
                                alt="Warning Icon"
                            />
                        )}
                        {!isSplit && (
                            <Grid item className={classes.inputContainer}>
                                <Input
                                    id={getPalletQuantityKey(item.productSku!)}
                                    disableUnderline={true}
                                    fullWidth
                                    disabled={disabled}
                                    type="text"
                                    className={classes.textField}
                                    value={purchaseQty}
                                    inputProps={{ 'data-testid': 'quantity-input' }}
                                    data-testid={`pallet-quantity-${item.productSku}`}
                                    onBlur={(event) =>
                                        handlePalletQuantityOnBlur(event.target.value)
                                    }
                                    onChange={(event) =>
                                        palletQuantityInputChange(event.target.value)
                                    }
                                />
                                <Typography>PL</Typography>
                            </Grid>
                        )}
                        {isSplit && (
                            <Grid
                                item
                                className={classes.inputContainer}
                                data-testid="split-quantity-sum"
                            >
                                <Typography className={classes.quantityText}>
                                    {totalSplitPalletQty} PL
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                    <Typography className="helper-text" data-testid="eaches-quantity">
                        {getEachesConversion(
                            isSplit ? totalSplitPalletQty : purchaseQty,
                            item.quantityPerPallet!
                        )}
                    </Typography>
                    {item.minimumOrderQuantity && item.minimumOrderQuantity > 0 && (
                        <Grid container>
                            <Typography className="helper-text" data-testid="minimum-order-helper">
                                <Trans i18nKey="minimumOrderIs">Minimum Order is</Trans>
                                {` ${item.minimumOrderQuantity} PL`}
                            </Typography>
                        </Grid>
                    )}
                    {hasMoqFees && (
                        <Grid container>
                            <Typography className="helper-text">
                                <Trans i18nKey="feeForOrders">Fees for orders</Trans>{' '}
                                {getQuantityFeeVerbiage()}
                            </Typography>
                        </Grid>
                    )}
                    {item.palletsRounded && (
                        <Alert
                            className={classes.informationalAlert}
                            icon={false}
                            data-testid="pallet-alert"
                        >
                            <Typography className={classes.informationAlertText}>
                                <Trans i18nKey="quantityRounded">
                                    Quantity rounded up to the nearest full pallet.
                                </Trans>
                            </Typography>
                        </Alert>
                    )}
                    {palletQuantityState === 'zeroError' && (
                        <Grid container>
                            <Typography
                                className="validation-error-text"
                                data-testid={`quantity-zero-error-${item.productSku}`}
                            >
                                <Trans i18nKey="palletQuantityZeroError">
                                    {enUS.palletQuantityZeroError}
                                </Trans>
                            </Typography>
                        </Grid>
                    )}
                </TableCell>
                <TableCell
                    className={clsx(
                        classes.splitContainer,
                        {
                            [classes.splitFeeError]: hasPalletQuantityError(palletQuantityState)
                        },
                        {
                            [classes.splitFeeWarning]: palletQuantityState === 'feeWarning'
                        }
                    )}
                >
                    <Grid container justify="center" alignContent="center">
                        <IconButton
                            data-testid="split-icon"
                            aria-label="split icon"
                            disableRipple
                            disableFocusRipple
                            disabled={markedRemoved}
                            onClick={onSplit}
                            className={classes.iconButton}
                        >
                            <CallSplitIcon className={classes.split} />
                            <Typography className={classes.splitText}>
                                <Trans i18nKey="split">Split</Trans>
                            </Typography>
                        </IconButton>
                    </Grid>
                </TableCell>
                <TableCell className={classes.alignCenter} data-testid="delete-icon">
                    <Button
                        type="button"
                        className={classes.trash}
                        disabled={disabled}
                        onClick={handleRemoveParentItem}
                        data-testid={`delete-product-btn-${item.productSku}`}
                    >
                        {disabled && (
                            <img
                                src={process.env.PUBLIC_URL + '/assets/DisabledTrashIcon.svg'}
                                alt="Disabled Trash Icon"
                            />
                        )}
                        {!disabled && (
                            <img
                                src={process.env.PUBLIC_URL + '/assets/Trash.svg'}
                                alt="Trash Icon"
                            />
                        )}
                    </Button>
                </TableCell>
            </TableRow>
            {subRows.length > 0 &&
                subRows.map((child, index) => {
                    return (
                        <MakeItBulkUploadReviewChildRow
                            key={index}
                            item={child}
                            childRowIndex={index}
                            styles={useStyles}
                            onRemoveItem={handleRemoveChildItem}
                            onQtyUpdate={handleQtyUpdate}
                            onBlurSetQty={handleQuantityOnBlur}
                            onSubRowSelectedDateChange={handleSubRowSelectedDateChange}
                        />
                    );
                })}
            <Modal
                open={calendarOpen}
                title={<Trans i18nKey="todayWithDate">Today: {{ today }}</Trans>}
                close={onCloseCalendar}
                closeIcon={true}
                maxWidth="xl"
            >
                <div className={classes.calendarDiv}>
                    <Grid item></Grid>
                    <SingleDaypickerModal
                        onCancel={onCloseCalendar}
                        onOk={handleOkClose}
                        selectDate={(newDate) => handleDateSelect(newDate)}
                        leadTime={0}
                        firstDay={firstDay}
                        calculateOutsideRange={true}
                    />
                </div>
            </Modal>
            <MakeItBulkProductIdModal
                open={productIdOpen}
                customerProductId={customerProductId!}
                candidateProductSkus={candidateProductSkus!}
                description={isCanType ? canBottleDesc : endDesc}
                onClose={onCloseProductId}
                onAcceptProductSku={handleProductSkuSelection}
            />
            <ConfirmationModal
                logo={false}
                title={
                    <Trans i18nKey="deleteLastProductModalTitle">
                        {enUS.deleteLastProductModalTitle}
                    </Trans>
                }
                subheader={
                    <Trans i18nKey="deleteLastProductModalSubheader">
                        {enUS.deleteLastProductModalSubheader}
                    </Trans>
                }
                description={
                    <Trans i18nKey="deleteLastProductModalBody">
                        {enUS.deleteLastProductModalBody}
                    </Trans>
                }
                continueText={<Trans i18nKey="yesDelete">{enUS.yesDelete}</Trans>}
                cancelText={<Trans i18nKey="no">{enUS.no}</Trans>}
                saveProgress={onConfirmRemoveLastProduct}
                onClose={() => setOpenDeleteLastProductModal(false)}
                onCancel={() => setOpenDeleteLastProductModal(false)}
                open={openDeleteLastProductModal}
            />
        </>
    );
};

export default MakeItBulkUploadReviewRow;
