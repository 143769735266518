import React, { ReactElement, useState, createRef, useEffect, useMemo } from 'react';
import { Grid, makeStyles, Container, CircularProgress, Hidden, Paper } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { Prompt, useHistory } from 'react-router';
import { useTranslation, Trans } from 'react-i18next';
import Button from '../../reusable/atoms/Button';
import { useForm, FormContext } from 'react-hook-form';
import { ltBlueGrey_8, successGreen, containerMaxWidth } from '../../../themes/globalConstants';
import { useTypedSelector } from '../../../store/reducers/reducer';
import HoverNavMenu from '../../reusable/molecules/HoverNavMenu';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import {
    saveFormData,
    resetFile,
    updateExistingFiles,
    resetGraphicsForm
} from '../../../store/actions/graphic-intake';
import { Graphics, Region } from '../../../store/reducers/graphic-intake';
import GraphicsService from '../../../utility/services/graphics-service';
import { setGraphicsID, getGraphic } from '../../../store/actions/graphic-intake';
import { useDispatch } from 'react-redux';
import GeneralInfo from './components/GeneralInfo';
import DesignInfo from './components/DesignInfo';
import ColourInfo from './components/ColourInfo';
import MarketInfo from './components/MarketInfo';
import ApprovalInfo from './components/ApprovalInfo';
import AdditionalRemarks from './components/AdditionalRemarks';
import UploadGraphics from '../GraphicsIntake/components/UploadGraphics';
import ProductQuality from './components/ProductQuality';
import { Activity, Persona } from '../../../utility/auth/useSecurity';
import { DefaultQueryParams } from '../../../utility/helpers/query-helpers';
import { updateAccounts } from '../../../store/actions/customer-context';
import AccountService from '../../../utility/services/account-service';
import { Account } from '../../../store/reducers/customer-context';
import CancelProgressModal from '../../reusable/molecules/CancelProgressModal';
import { RouterConfirmationOverride } from '../../../App';
import SaveConfirmedModal from '../../reusable/molecules/SaveConfirmedModal';
import { selectHasImpersonationBillTo } from '../../../store/selectors';
import ImpersonationWarning from '../../reusable/molecules/ImpersonationWarning';
import ErrorAlert from '../../reusable/atoms/ErrorAlert';
import { FlagTypes, GetFeatureFlag } from '../../../utility/helpers/feature-flag';
import GraphicsSubmittedModal from '../../reusable/molecules/GraphicsSubmittedModal';
import { getAccountAddress } from '../../../utility/helpers/address-helpers';
import ConfirmationModal from './../../reusable/molecules/ConfirmationModal';
import { stringFormatterReplace } from '../../../utility/helpers/formatters';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '3em'
    },
    formSection: {
        marginBottom: '2.5em'
    },
    error: {
        color: theme.palette.error.main,
        marginTop: '0.625em',
        fontSize: '16px'
    },
    success: {
        color: theme.palette.success.main,
        marginTop: '0.625em'
    },
    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '0.5em'
    },
    errorContainer: {
        marginBottom: '0.5em'
    },
    imperContainer: {
        paddingRight: '2em'
    },
    paper: {
        boxShadow: `0 3px 6px 0 ${ltBlueGrey_8}`,
        borderRadius: 4,
        padding: '2em',
        marginRight: '2em',
        position: 'sticky',
        top: '5em'
    },
    actionBtn: {
        height: '3.375em',
        width: '13.75em',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom',
        transition: 'all linear 150ms'
    },
    buttonContainer: {
        paddingBottom: '3em',
        maxWidth: containerMaxWidth
    },
    completedIcon: {
        fill: successGreen,
        width: 23,
        height: 23
    },
    formSectionContainer: {
        display: 'flex',
        flexDirection: 'column'
    }
}));

export default function EmeaGraphicsIntakePage(): ReactElement {
    const { id } = useParams<DefaultQueryParams>();
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const draftExpiration = moment().add(28, 'd').format('DD/MM/YYYY');
    const graphicsFileOptionalFeatureFlag = GetFeatureFlag(FlagTypes.GraphicsFileOptional);

    const methods = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur'
    });

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isSubmittingDraft, setIsSubmittingDraft] = useState<boolean>(false);
    const [openDraftModal, setOpenDraftModal] = useState<boolean>(false);
    const [submitDraftClicked, setSubmitDraftClicked] = useState<boolean>(false);
    const [attendProduction, setAttendProduction] = useState(false);
    const [bpaFree, setBpaFree] = useState(false);
    const [bpaniId, setBpaniId] = useState(false);
    const [proofSession, setProofSession] = useState(false);
    const [recycleMark, setRecycleMark] = useState(false);
    const [asiCertified, setAsiCertified] = useState(false);
    const [success, setSuccess] = useState(false);
    const [editDraft, setEditDraft] = useState(false);
    const [submitClicked, setSubmitClicked] = useState<boolean>(false);
    const [companyDropdown, setCompanyDropdown] = useState([] as Account[]);
    const [selectedAccount, setSelectedAccount] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [cancelOpen, setCancelOpen] = useState<boolean>(false);
    const [onDraftSaved, setOnDraftSaved] = useState<boolean>(false);
    const [clearForm, setClearForm] = useState<boolean>(false);
    const [allowNavigation, setAllowNavigation] = useState<boolean>(false);
    const [lastLocationPath, setLastLocationPath] = useState<string>('');
    const [fromCancel, setFromCancel] = useState<boolean>(false);
    const [toNavigate, setToNavigate] = useState<boolean>(false);
    const [cantSaveDraft, setCantSaveDraft] = useState<boolean>(false);
    const [showImpersonationWarning, setShowImpersonationWarning] = useState<boolean>(false);
    const [graphicsFileOptional, setGraphicsFileOptional] = useState<boolean>(false);

    const graphicIntake = useTypedSelector((state) => state.graphicIntake);

    const { accessToken, permissions } = useTypedSelector((state) => state.auth);
    const hasImpersonationBillTo = useTypedSelector<boolean>(selectHasImpersonationBillTo);
    const state = useTypedSelector((state) => state);

    const { isValid } = methods.formState;
    const isAttendProduction = methods.watch('attendProduction') === 'true';
    const isBpaFree = methods.watch('bpaFree') === 'true';
    const isBpaniId = methods.watch('bpaniId') === 'true';
    const isProofSession = methods.watch('proofSession') === 'true';
    const isRecycleMark = methods.watch('recycleMark') === 'true';
    const isAsiCertified = methods.watch('asiCertified') === 'true';
    const designName = methods.watch('designName');
    const customerSelected = methods.watch('customerSelected');
    const requestType = methods.watch('requestType');

    const fileErrorMessage = t(
        'uploadFileError',
        ' Please upload a valid zip file before submitting'
    );
    const apiErrorPrefix = t('intakeFailedToSave', 'Graphics intake form failed to submit, ');
    const formHasErrorsMessage = t(
        'resolveFormErrors',
        'Please resolve form errors to save a draft.'
    );
    const cantSaveDraftMessage = t(
        'cantSaveDraft',
        '“Company Name and ID” and “Design Name” required to save a draft..'
    );
    const networkErrorMessage = t(
        'networkError',
        'We are experiencing technical difficulties with your upload, please try again. If you continue to experience issues, files can be submitted to CORPGCS@ball.com for manual intake.'
    );

    const filterAndSetCompanyDropdown = (accounts: Account[]) => {
        let artMgmtPermission = permissions.find(
            (permission) => permission.personaId === Persona.ArtMgmt
        );
        if (artMgmtPermission) {
            setCompanyDropdown(
                accounts.filter((account: Account) =>
                    artMgmtPermission.accountIds.find((id: string) => id === account.accountId)
                )
            );
        }
    };

    useTranslation();

    useEffect(() => {
        if (id) {
            dispatch(getGraphic(id));
            setEditDraft(true);
        } else {
            dispatch(resetGraphicsForm());
        }
    }, [id, dispatch, setEditDraft]);

    useEffect(() => {
        setAttendProduction(isAttendProduction);
    }, [isAttendProduction]);

    useEffect(() => {
        setSelectedAccount(customerSelected);
    }, [customerSelected]);
    useEffect(() => {
        setBpaFree(isBpaFree);
    }, [isBpaFree]);

    useEffect(() => {
        setBpaniId(isBpaniId);
    }, [isBpaniId]);

    useEffect(() => {
        setProofSession(isProofSession);
    }, [isProofSession]);

    useEffect(() => {
        setRecycleMark(isRecycleMark);
    }, [isRecycleMark]);

    useEffect(() => {
        setAsiCertified(isAsiCertified);
    }, [isAsiCertified]);

    useEffect(() => {
        if (permissions) {
            if (state.customerContext.accounts.length === 0) {
                AccountService.getAccounts(state, false)
                    .then((response) => {
                        dispatch(updateAccounts(response.data.accounts));
                        filterAndSetCompanyDropdown(response.data.accounts);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                filterAndSetCompanyDropdown(state.customerContext.accounts);
            }
        }
    }, [permissions]);

    useEffect(() => {
        if (graphicsFileOptionalFeatureFlag && requestType) {
            setGraphicsFileOptional(requestType.toLowerCase() === 'existing');
        }
    }, [graphicsFileOptionalFeatureFlag, requestType]);

    const clearErrors = () => {
        setErrorMessage('');
        setCantSaveDraft(false);
        setShowImpersonationWarning(false);
    };

    const scrollToTop = () => {
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 10);
    };

    const resetForm = () => {
        // Reset the file content in state
        dispatch(resetFile());
        setCancelOpen(false);
        clearErrors();
        scrollToTop();
        setClearForm(!clearForm);
    };

    const onDeleteProgress = () => {
        setAllowNavigation(true);
        resetForm();
        if (lastLocationPath !== '') {
            history.push(lastLocationPath);
        }
        if (fromCancel) {
            history.push('/dashboard');
        }
    };

    const handleApiError = (error) => {
        let message;
        //Determine if the error is graphic upload timeout or other
        if (error.message === 'Response failed with status code 502') {
            message = t(
                'graphicUploadTimeout',
                'graphic upload timed out.  Please try again later.'
            );
            setErrorMessage(`${apiErrorPrefix}, ${message}`);
        } else if (error.message === 'Network Error') {
            setErrorMessage(networkErrorMessage);
        } else {
            //If other, send it to handle the error text to be displayed
            handleApiErrorText(error);
        }
        setIsSubmitting(false);
        setAllowNavigation(false);
    };

    //If more than one specific error/rule needs to be handled from the API,
    //this can be converted to a switch.
    const handleApiErrorText = (error) => {
        const errorData = error?.response?.data?.errors;
        let message;

        if (errorData) {
            if (errorData.SpecialtiesRule) {
                message = t(
                    'requiresSpecialty',
                    'you noted your product requires a special coat or specialty.  Please select your coating, finish or effect.'
                );
                setErrorMessage(`${apiErrorPrefix}, ${message}`);
            } else if (errorData.Submitter) {
                message = t('requiresSubmitter', 'please add a valid email address.');
                setErrorMessage(`${apiErrorPrefix}, ${message}`);
            } else if (errorData.BillToAddress) {
                message = errorData.BillToAddress[0];
                setErrorMessage(`${apiErrorPrefix}, ${message}`);
                // INFO: Fluent validation does not have a keyword for File Size Limits information, then we
            } else if (errorData[''][0].includes('Multipart body length limit')) {
                setErrorMessage(networkErrorMessage);
            } else {
                message = t('unknownError', 'an unknown error occurred.  Please try again later.');
                setErrorMessage(`${apiErrorPrefix}, ${message}`);
            }
        }
    };

    const onSaveDraft = () => {
        setOpenDraftModal(false);
        setSubmitDraftClicked(true);
        clearErrors();
        const formErrors = Object.keys(methods.errors);
        const emptyDesignName =
            designName === '' || designName === null || designName === undefined;
        const emptyCustomerName =
            selectedAccount === '' || selectedAccount === null || selectedAccount === undefined;
        if (emptyDesignName || emptyCustomerName) {
            setCantSaveDraft(true);
            setErrorMessage(cantSaveDraftMessage);
            setCancelOpen(false);
        } else if (formErrors && formErrors.length > 0) {
            setErrorMessage(formHasErrorsMessage);
            setCancelOpen(false);
        } else {
            setIsSubmittingDraft(true);
            setAllowNavigation(true);
            clearErrors();
            const graphics = graphicIntake.graphics;
            let data = methods.getValues();

            if (graphics && graphics.graphicsId) {
                data.graphicsId = graphics.graphicsId;
            }

            data.productFormulation =
                data.productFormulation !== '' ? data.productFormulation : undefined;
            data.region = Region.EU;
            data.attendProduction = attendProduction;
            data.bpaFree = bpaFree;
            data.bpaniId = bpaniId;
            data.proofSession = proofSession;
            data.recycleMark = recycleMark;
            data.asiCertified = asiCertified;

            if (data.customerSelected !== null) {
                data.customerName = data.customerSelected.name ?? '';
                data.customerAccount = data.customerSelected.accountId ?? '';
                data.shipToAddress = [getAccountAddress(data.customerSelected)];
            }

            data.approvers = [
                {
                    approverName: data.approverName,
                    approverEmail: data.approverEmail,
                    approverPhoneNumber: data.approverPhoneNumber
                }
            ];

            if (cancelOpen) {
                setCancelOpen(false);
            }

            if (!hasImpersonationBillTo) {
                GraphicsService.saveDraftGraphicsForm(data as unknown as Graphics, accessToken)
                    .then((response) => {
                        if (!graphics || !graphics.graphicsId) {
                            dispatch(setGraphicsID(response.data.graphicId));
                        }
                        if (
                            graphicIntake.images?.length ||
                            graphicIntake.graphics?.deletedFiles?.length
                        ) {
                            GraphicsService.submitGraphicsImage(
                                response.data.graphicId,
                                graphicIntake.images ?? [],
                                accessToken,
                                graphicIntake?.graphics?.deletedFiles ?? []
                            )
                                .then((imageResponse) => {
                                    dispatch(updateExistingFiles(imageResponse.data));
                                    setOnDraftSaved(true);
                                    setIsSubmittingDraft(false);
                                    if (toNavigate) {
                                        handleConfirmNavigation();
                                    }
                                })
                                .catch((error) => {
                                    handleApiError(error);
                                    setIsSubmittingDraft(false);
                                });
                        } else {
                            setOnDraftSaved(true);
                            setIsSubmittingDraft(false);
                            if (toNavigate) {
                                handleConfirmNavigation();
                            }
                        }
                    })
                    .catch((error) => {
                        handleApiError(error);
                        setIsSubmittingDraft(false);
                    })
                    .finally(() => {
                        setIsSubmittingDraft(false);
                    });
            } else {
                setIsSubmittingDraft(false);
                setShowImpersonationWarning(true);
            }
        }
    };

    useEffect(() => {
        if (allowNavigation && lastLocationPath !== '' && !isSubmitting) {
            history.push(lastLocationPath);
        }
    }, [history, allowNavigation, lastLocationPath, isSubmitting]);

    const handleBlockedNavigation = (location) => {
        setToNavigate(true);
        if (location !== history.location.pathname) setLastLocationPath(location);
        if (!allowNavigation) {
            setCancelOpen(true);
            return false;
        }
        dispatch(resetGraphicsForm());
        return true;
    };

    const handleConfirmNavigation = () => {
        if (lastLocationPath !== '') {
            setAllowNavigation(true);
            // Navigate to the previously blocked location
            history.push(lastLocationPath);
        }
    };

    const onCancel = () => {
        setCancelOpen(true);
        setFromCancel(true);
    };

    const onCloseConfirmation = () => {
        setCancelOpen(false);
        setToNavigate(false);
        setFromCancel(false);
    };

    const onDraftSavedClose = () => {
        setOnDraftSaved(false);
        setFromCancel(false);
    };

    const handleSubmitCapture = () => {
        setSubmitClicked(true);
        clearErrors();
        methods.triggerValidation();
        if (!isValid) {
            setErrorMessage(
                t(
                    'fillOutRequiredFields',
                    'All required fields must be completed prior to submitting.'
                )
            );
        }
    };

    const onSubmit = (data) => {
        setSubmitClicked(true);
        clearErrors();
        setIsSubmitting(true);

        const graphics = graphicIntake.graphics;

        //Make sure user has uploaded a valid file
        // image is optional for requestType 'existing'
        if (
            !graphicIntake.images?.length &&
            !graphicIntake?.graphics?.fileInfo?.length &&
            !graphicsFileOptional
        ) {
            setErrorMessage(fileErrorMessage);
            setIsSubmitting(false);
        } else {
            setAllowNavigation(true);
            if (graphics.graphicsId) {
                data.graphicsId = graphics.graphicsId;
            }
            //converting string values to booleans for API
            data.attendProduction = attendProduction;
            data.bpaFree = bpaFree;
            data.bpaniId = bpaniId;
            data.proofSession = proofSession;
            data.recycleMark = recycleMark;
            data.asiCertified = asiCertified;
            data.imageRequired = !graphicsFileOptional;
            data.shipToAddress = [];
            // set region
            data.region = Region.EU;

            if (data.customerSelected) {
                data.customerName = data.customerSelected.name;
                data.customerAccount = data.customerSelected.accountId;
                data.shipToAddress = [getAccountAddress(data.customerSelected)];
            }

            data.approvers = [
                {
                    approverName: data.approverName,
                    approverEmail: data.approverEmail,
                    approverPhoneNumber: data.approverPhoneNumber
                }
            ];

            if (!hasImpersonationBillTo) {
                GraphicsService.submitGraphicsForm(data as Graphics, accessToken)
                    .then((response) => {
                        data.graphicsId = response.data.graphicId;
                        dispatch(saveFormData(data as Graphics));
                        if (
                            graphicIntake.images?.length ||
                            graphicIntake.graphics?.deletedFiles?.length
                        ) {
                            GraphicsService.submitGraphicsImage(
                                response.data.graphicId,
                                graphicIntake.images ?? [],
                                accessToken,
                                graphicIntake?.graphics?.deletedFiles ?? []
                            )
                                .then((response) => {
                                    data.image = graphicIntake.images;
                                    dispatch(saveFormData(data as Graphics));
                                    setIsSubmitting(false);
                                    setAllowNavigation(true);
                                    setSuccess(true);
                                })
                                .catch((error) => {
                                    handleApiError(error);
                                    setIsSubmitting(false);
                                });
                        } else {
                            // for requestType 'Existing' without an image attachment
                            setIsSubmitting(false);
                            setAllowNavigation(true);
                            setSuccess(true);
                        }
                    })
                    .catch((error) => {
                        handleApiError(error);
                        setIsSubmitting(false);
                    });
            } else {
                setIsSubmitting(false);
                setShowImpersonationWarning(true);
            }
        }
    };

    const sections = useMemo(
        () => [
            {
                header: t('graphicsSubmissionInfo', 'General Information'),
                icon: false
            },
            {
                header: t('designInfo', 'Design Information'),
                icon: false
            },
            {
                header: t('colourInfo', 'Colour Information'),
                icon: false
            },
            {
                header: t('marketInfo', 'Market Information'),
                icon: false
            },
            {
                header: t('productQuality', 'Product Quality Specification'),
                icon: false
            },
            {
                header: t('approverInfo', 'For Approval'),
                icon: false
            },
            {
                header: t('generalComments', 'General Comments'),
                icon: false
            },
            {
                header: t('uploadGraphicsAndSubmit', 'Upload Graphics & Submit'),
                icon: false
            }
        ],
        [t]
    );

    const formSections = [
        {
            section: (
                <GeneralInfo
                    isEditDraft={editDraft}
                    graphicData={graphicIntake.graphics}
                    companyDropdown={companyDropdown}
                    cantSaveDraft={cantSaveDraft}
                />
            )
        },
        {
            section: (
                <DesignInfo
                    isEditDraft={editDraft}
                    graphicData={graphicIntake.graphics}
                    cantSaveDraft={cantSaveDraft}
                />
            )
        },
        { section: <ColourInfo isEditDraft={editDraft} graphicData={graphicIntake.graphics} /> },
        { section: <MarketInfo isEditDraft={editDraft} graphicData={graphicIntake.graphics} /> },
        {
            section: <ProductQuality isEditDraft={editDraft} graphicData={graphicIntake.graphics} />
        },
        { section: <ApprovalInfo isEditDraft={editDraft} graphicData={graphicIntake.graphics} /> },
        {
            section: (
                <AdditionalRemarks isEditDraft={editDraft} graphicData={graphicIntake.graphics} />
            )
        },
        {
            section: (
                <UploadGraphics
                    isEditDraft={editDraft}
                    graphicData={graphicIntake.graphics}
                    region={Region.EU}
                />
            )
        }
    ];
    const sectionRefs = sections.map(() => createRef<HTMLElement>());
    const header = <Trans i18nKey="formOverview">Form Overview</Trans>;
    const icon = (
        <img
            className={classes.completedIcon}
            src={process.env.PUBLIC_URL + '/assets/Confirmation_icon.svg'}
            alt="Confirmation Icon"
        />
    );

    useEffect(() => {
        if (sections[0].icon !== graphicIntake.graphicsInformationValid) {
            sections[0].icon = graphicIntake.graphicsInformationValid;
        }
        if (sections[1].icon !== graphicIntake.designInformationValid) {
            sections[1].icon = graphicIntake.designInformationValid;
        }
        if (sections[2].icon !== graphicIntake.colourInformationValid) {
            sections[2].icon = graphicIntake.colourInformationValid;
        }
        if (sections[3].icon !== graphicIntake.marketInformationValid) {
            sections[3].icon = graphicIntake.marketInformationValid;
        }
        if (sections[4].icon !== graphicIntake.productQualityValid) {
            sections[4].icon = graphicIntake.productQualityValid;
        }
        if (sections[5].icon !== graphicIntake.approverInfoValid) {
            sections[5].icon = graphicIntake.approverInfoValid;
        }
        if (sections[6].icon !== graphicIntake.additionalRemarksValid) {
            sections[6].icon = graphicIntake.additionalRemarksValid;
        }
        if (sections[7].icon !== graphicIntake.graphicsValid) {
            sections[7].icon = graphicIntake.graphicsValid;
        }
    }, [
        sections,
        graphicIntake.graphicsInformationValid,
        graphicIntake.designInformationValid,
        graphicIntake.colourInformationValid,
        graphicIntake.productQualityValid,
        graphicIntake.approverInfoValid,
        graphicIntake.additionalRemarksValid,
        graphicIntake.graphicsValid,
        graphicIntake.marketInformationValid
    ]);

    const footerActions = (
        <>
            <Grid container item xs={3} justify="space-between">
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="cancel-button"
                    onClick={onCancel}
                    className={classes.actionBtn}
                >
                    <Trans i18nKey="cancel">Cancel</Trans>
                </Button>
                <Button
                    type="button"
                    color="secondary"
                    variant="outlined"
                    data-testid="draft-button"
                    onClick={() => setOpenDraftModal(true)}
                    className={classes.actionBtn}
                    disabled={isSubmitting || isSubmittingDraft || showImpersonationWarning}
                >
                    <Trans i18nKey="saveProgressEmea">Save Progress</Trans>
                </Button>
            </Grid>
            <Grid container item xs={5} alignItems="center" justify="center">
                <ErrorAlert
                    errorMessage={errorMessage}
                    showError={(submitClicked || submitDraftClicked) && errorMessage !== ''}
                    dataTestId="validation-error"
                ></ErrorAlert>
                <ImpersonationWarning
                    showWarning={showImpersonationWarning}
                    warningType={submitDraftClicked ? 'SAVE' : 'SUBMIT'}
                />
            </Grid>
            <Grid container item xs={2}>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    data-testid="submit-button"
                    className={classes.actionBtn}
                    disabled={
                        isSubmitting || isSubmittingDraft || success || showImpersonationWarning
                    }
                    onClickCapture={handleSubmitCapture}
                    onClick={methods.handleSubmit(onSubmit)}
                >
                    <Trans i18nKey="submit">Submit</Trans>
                </Button>
            </Grid>
        </>
    );

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('submitGraphics', 'Submit Your Graphics'),
                description: '',
                thinBanner: true
            }}
            activity={Activity.GraphicsIntakeForm}
            shipTos={false}
            actionFooter={{
                footerAction: footerActions,
                justify: 'space-between',
                sticky: true
            }}
        >
            <FormContext {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
                    <Grid container data-testid="graphics-intake-page">
                        <Container className={classes.container}>
                            <Grid container item xs={12}>
                                <Grid item sm={4}>
                                    <Hidden xsDown>
                                        <Paper className={classes.paper}>
                                            <HoverNavMenu
                                                translatedHeader={header}
                                                readOnly={false}
                                                sections={sections
                                                    .map((section, index) => ({
                                                        ref: sectionRefs[index],
                                                        header: section.header,
                                                        icon: section.icon,
                                                        iconSrc: icon
                                                    }))
                                                    .filter((section) => section.header)}
                                            />
                                        </Paper>
                                    </Hidden>
                                </Grid>
                                <Grid
                                    container
                                    item
                                    xs={8}
                                    className={classes.formSectionContainer}
                                >
                                    {formSections.map((form, index) => {
                                        return (
                                            <section ref={sectionRefs[index]} key={index}>
                                                <Grid item xs={12} className={classes.formSection}>
                                                    {form.section}
                                                </Grid>
                                            </section>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        </Container>

                        <Container>
                            {(isSubmitting || isSubmittingDraft) && (
                                <Grid container item xs={12} className={classes.spinningLoader}>
                                    <CircularProgress />
                                </Grid>
                            )}
                        </Container>
                    </Grid>
                </form>
            </FormContext>
            <CancelProgressModal
                open={cancelOpen}
                saveProgress={onSaveDraft}
                onCancel={onDeleteProgress}
                onClose={onCloseConfirmation}
            />
            <SaveConfirmedModal
                title={<Trans i18nKey="graphicDraftSavedEmea">Graphic Intake Draft Saved</Trans>}
                open={onDraftSaved}
                onClose={onDraftSavedClose}
            />
            <GraphicsSubmittedModal
                title={<Trans i18nKey="designSubmitted">Design Development Form Submitted</Trans>}
                open={success}
                onClose={() => history.push('/')}
            />
            <ConfirmationModal
                open={openDraftModal}
                title={<Trans i18nKey="saveDraft">Save Draft</Trans>}
                subheader={stringFormatterReplace(
                    t(
                        'draftStoredUntil',
                        `Please note that your draft intake form and any files which you might have uploaded with this form will be stored by Ball on this platform until ${draftExpiration}. If you do not submit the form prior to this date to Ball, the form and files will no longer be available. A new submission and files will be required for Ball to start the design development.`
                    ),
                    draftExpiration
                )}
                saveProgress={onSaveDraft}
                onClose={() => setOpenDraftModal(false)}
                logo={false}
                continueText={<Trans i18nKey="save">Save</Trans>}
                cancelText={<Trans i18nKey="cancel">Cancel</Trans>}
                navigationLink=""
                onCancel={() => setOpenDraftModal(false)}
            />
            <Prompt
                when={!allowNavigation}
                message={(location, action) => {
                    if (action === 'POP') {
                        handleBlockedNavigation(location.pathname);
                        return RouterConfirmationOverride.PreventBrowserBack;
                    } else {
                        return handleBlockedNavigation(location.pathname);
                    }
                }}
            />
        </ProcessingPageTemplate>
    );
}
