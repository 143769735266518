import React, { useCallback } from 'react';
import { MakeItBulkReviewATMProvider } from '../../MakeItBulkReviewATM/context/MakeItBulkReviewATMContext';
import MakeItReviewOrderSection from '../../../reusable/molecules/MakeItReviewOrderProductGrouping/MakeItReviewOrderSection';
import { Grid } from '@material-ui/core';

interface Props {
    activeDate: any;
    selectedAccountId: any;
    grouping: any;
    expandAll: any;
    editingOrder: any;
    fromPlanItSummary: any;
    handleCopyButtonClicked: any;
    handleMoveButtonClicked: any;
    handleRemoveGroupButtonClicked: any;
}

const OrderSection = ({
    activeDate,
    selectedAccountId,
    grouping,
    expandAll,
    editingOrder,
    fromPlanItSummary,
    handleCopyButtonClicked,
    handleMoveButtonClicked,
    handleRemoveGroupButtonClicked
}: Props) => {
    const onCopyGroup = useCallback(() => {
        if (!editingOrder) handleCopyButtonClicked(grouping);
    }, []);

    const onMoveGroup = useCallback(() => {
        if (!editingOrder) handleMoveButtonClicked(grouping);
    }, []);

    const onRemoveGroup = useCallback(() => {
        if (!editingOrder) handleRemoveGroupButtonClicked(grouping);
    }, []);

    return (
        <MakeItBulkReviewATMProvider
            value={{
                activeDate,
                shipToId: parseInt(selectedAccountId!)
            }}
        >
            <Grid item xs={12}>
                <MakeItReviewOrderSection
                    makeItSpot={true}
                    order={grouping}
                    shipToId={parseInt(selectedAccountId!)}
                    isActive={grouping.isActive}
                    expand={expandAll}
                    showPrevPalletQty={editingOrder || fromPlanItSummary}
                    showCsvLineNumber={false}
                    planItToMakeIt={fromPlanItSummary}
                    showButtons={true}
                    onCopyGroup={onCopyGroup}
                    onMoveGroup={onMoveGroup}
                    onRemoveGroup={onRemoveGroup}
                    editingOrder={editingOrder}
                />
            </Grid>
        </MakeItBulkReviewATMProvider>
    );
};

export default React.memo(OrderSection);
