import React, { ChangeEvent, FocusEvent, useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { InputAdornment, TextField, makeStyles, IconButton, Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { ballBlue, dateOptionInvalidGray } from '../../../themes/globalConstants';
import {
    MakeItSummaryState,
    ProductionOrderSummary
} from '../../../store/reducers/make-it-summary';
import {
    setUseSummaryStoredSearch,
    setSummaryStoredSearch
} from '../../../store/actions/make-it-summary';
import { useTypedSelector } from '../../../store/reducers/reducer';
import Close from '@material-ui/icons/Close';

interface Props {
    onChange?: (e: ChangeEvent<HTMLInputElement>) => any;
    onBlur?: (e: FocusEvent<HTMLInputElement>) => any;
    onRowFilter: (rows: ProductionOrderSummary[]) => any;
    columns: any;
    rows: any[];
    inputProps?: { 'data-testid': string };
}

const useStyles = makeStyles(() => ({
    wrapper: {},
    searchInput: {
        marginRight: '10px',
        width: '250px',
        zIndex: 1,
        '& .MuiInputBase-formControl': {
            border: `1px solid ${dateOptionInvalidGray}`,
            borderRadius: '.125em',
            '&:before': {
                display: 'none'
            },
            '&:after': {
                display: 'none'
            },
            '& svg': {
                height: '.875em',
                width: '.875em',
                fill: ballBlue
            },
            '& .MuiIconButton-root': {
                '&:hover': {
                    backgroundColor: 'transparent !important'
                }
            },
            '& .MuiInput-input': {
                paddingLeft: '.25em'
            },
            // Manual override to force position 'end' on icon because position="end" attribute is not working.
            '& .MuiInputAdornment-root': {
                marginLeft: 8,
                marginRight: 0
            }
        }
    },
    clearButton: {
        position: 'relative',
        top: '1px'
    }
}));

const defaultInputProps = {
    'data-testid': 'summary-search-bar'
};

const SummarySearchBar = ({ onChange, onBlur, onRowFilter, rows, columns, inputProps }: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const timeout = useRef<any>();
    const [searchBarValue, setSearchBarValue] = useState<string>('');

    inputProps = { ...defaultInputProps, ...inputProps };

    const { storedSearch, useStoredSearch } = useTypedSelector<MakeItSummaryState>(
        (state) => state.makeItSummary
    );

    useEffect(() => {
        if (useStoredSearch) {
            // populate search with value stored in state
            setSearchBarValue(storedSearch || '');
            if (storedSearch) setSearchBarValue(storedSearch);
        }
        dispatch(setUseSummaryStoredSearch(false));
        dispatch(setSummaryStoredSearch(''));
    }, [dispatch]);

    // If leaving this page for order edit or order details path, store the search bar value
    useEffect(() => {
        return () => {
            if (
                history.location.pathname.includes('production-order') ||
                history.location.pathname.includes('delivery-order') ||
                history.location.pathname.includes('make-it-bulk-upload-po-review') ||
                history.location.pathname.includes('edit-shipments-summary') ||
                history.location.pathname.includes('plan-it-order') ||
                history.location.pathname.includes('plan-it-bulk-upload-review') ||
                history.location.pathname.includes('make-it-po-order')
            ) {
                dispatch(setSummaryStoredSearch(searchBarValue));
            }
        };
    }, [searchBarValue]);

    // Filter rows manually by iterating searchable columns and checking for matches
    const filterRowsBySearchValue = () => {
        if (!searchBarValue || !rows?.filter) {
            onRowFilter(rows);
            return;
        }

        const searchableColumns = columns.filter((col) => {
            return col.searchable;
        });
        const searchValueLowerCase = searchBarValue.toLowerCase();
        const filteredRows = rows.filter((row: ProductionOrderSummary) => {
            let searchMatch = false;
            searchableColumns.forEach((column) => {
                const columnValue = JSON.stringify(row[column.name]) ?? '';
                if (columnValue.toLowerCase().includes(searchValueLowerCase)) searchMatch = true;
            });
            return searchMatch;
        });

        onRowFilter(filteredRows);
    };

    useEffect(() => {
        // Debounce filter function for performance
        clearTimeout(timeout.current);
        const newTimeout = setTimeout(() => {
            filterRowsBySearchValue();
        }, 100);
        timeout.current = newTimeout;
    }, [searchBarValue, rows]);

    const internalOnChange = (event) => {
        setSearchBarValue(event.target.value);
        if (onChange) onChange(event);
    };

    return (
        <Grid container justify="flex-end">
            <TextField
                className={classes.searchInput}
                variant="standard"
                fullWidth
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon color="primary" />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="clear search"
                                onClick={() => setSearchBarValue('')}
                            >
                                {searchBarValue ? <Close className={classes.clearButton} /> : null}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                inputProps={inputProps}
                onChange={internalOnChange}
                onBlur={onBlur}
                value={searchBarValue}
            />
        </Grid>
    );
};

export default SummarySearchBar;
