import { Moment } from 'moment';
import { makeStyles } from '@material-ui/core';
import { ballBlue, lato } from '../../../../themes/globalConstants';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { loadDraftData, deleteDraft } from '../../../../store/actions/make-it-drafts';
import { AuthState } from '../../../../store/reducers/auth';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { DraftInformation, ProductionOrderDraft } from '../../../../store/reducers/make-it-drafts';
import { ProductionOrder } from '../../../../store/reducers/makeit-dashboard';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { updateDraftOrder } from '../../../../store/root-actions';
import { selectIsImpersonation } from '../../../../store/selectors';
import { hasPersonasByShipTo, Persona } from '../../../../utility/auth/useSecurity';
import { isLargeCustomerShipToId } from '../../../../utility/helpers/make-it-draft-helpers';
import AddWeekModal from '../../../reusable/molecules/AddWeekModal';
import ConfirmationModal from '../../../reusable/molecules/ConfirmationModal';
import MakeItDraftsSummaryOrderSection from './MakeItDraftsSummarySection';

type Props = {
    drafts: ProductionOrderDraft[];
};

const useStyles = makeStyles((theme) => ({
    emptyTab: {
        padding: '3em',
        minHeight: '250px',
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        color: ballBlue,
        fontFamily: lato,
        fontSize: '1.3em',
        fontWeight: 900
    }
}));

const MakeItDraftsSummaryTab = ({ drafts }: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const auth = useTypedSelector<AuthState>((state) => state.auth);

    const isImpersonation = useTypedSelector<boolean>(selectIsImpersonation);

    const [showDiscardModal, setShowDiscardModal] = useState<boolean>(false);
    const [showRestoreDraftModal, setShowDraftModal] = useState<boolean>(false);
    const [ordersToDelete, setOrdersToDelete] = useState<ProductionOrder[]>();
    const [ordersToRestore, setOrdersToRestore] = useState<ProductionOrder[]>();
    const [activeDraft, setActiveDraft] = useState<DraftInformation>();
    const [showImpersonationWarning, setShowImpersonationWarning] = useState<boolean>(false);
    const [shouldDeleteEntireDraft, setShouldDeleteEntireDraft] = useState<boolean>(false);

    const showCustomerPO = (draft: ProductionOrderDraft): boolean => {
        return !isLargeCustomerShipToId(String(draft.orders[0].shipToId), shipToAccounts);
    };

    const accountsWithMakeIt: string[] = useMemo(() => {
        if (shipToAccounts && auth.permissions) {
            return shipToAccounts
                .map((account) => account.accountId)
                .filter((accountId) =>
                    hasPersonasByShipTo(
                        [Persona.MakeItOnly, Persona.OrderFulfillment, Persona.AllAccess],
                        accountId,
                        auth.permissions!
                    )
                );
        }
        return [];
    }, [shipToAccounts, auth.permissions]);

    useEffect(() => {
        if (shipToAccounts) {
            dispatch(loadDraftData(shipToAccounts));
        }
    }, [shipToAccounts, dispatch]);

    const handleDiscardModal = (orders: ProductionOrder[], entireDraftSelected: boolean) => {
        setOrdersToDelete(orders);
        setShouldDeleteEntireDraft(entireDraftSelected);
        setShowDiscardModal(true);
    };

    const handleDeleteDraftOrder = () => {
        if (ordersToDelete && shipToAccounts && !isImpersonation) {
            dispatch(deleteDraft(ordersToDelete, shouldDeleteEntireDraft, shipToAccounts));
            setShowDiscardModal(false);
        } else if (isImpersonation) {
            setShowImpersonationWarning(true);
        }
    };

    const handleRestoreDraftsModal = (orders: ProductionOrder[], draft: DraftInformation) => {
        setActiveDraft(draft);
        setOrdersToRestore(orders);
        setShowDraftModal(true);
    };

    const handleRestoreDraftOrder = (week?: Moment) => {
        if (ordersToRestore && week && !isImpersonation && activeDraft) {
            ordersToRestore.forEach((order) => {
                order.lines.forEach((line) => {
                    line.requestedDate = week.format('YYYY-MM-DDTHH:mm:ss');
                });
            });
            dispatch(
                updateDraftOrder(activeDraft.name, Number(activeDraft.draftId), ordersToRestore)
            );
            history.push('/make-it-drafts-summary?status=openDrafts');
        } else if (isImpersonation) {
            setShowImpersonationWarning(true);
        }
        setShowDraftModal(false);
    };

    return (
        <>
            <ConfirmationModal
                title={
                    <Trans i18nKey="discardDraftAreYouSure">
                        Are you sure you want to discard your draft?
                    </Trans>
                }
                logo={false}
                subheader={
                    <Trans i18nKey="discardDraftWillBeDeleted">
                        Your draft order will be deleted.
                    </Trans>
                }
                cancelText={<Trans i18nKey="no">No</Trans>}
                continueText={<Trans i18nKey="yesDiscard">Yes, Discard</Trans>}
                saveProgress={handleDeleteDraftOrder}
                showImpersonationWarning={showImpersonationWarning}
                impersonationWarningType={'DISCARD'}
                onClose={() => setShowDiscardModal(false)}
                onCancel={() => setShowDiscardModal(false)}
                open={showDiscardModal}
            />
            {showRestoreDraftModal && (
                <AddWeekModal
                    onSubmit={handleRestoreDraftOrder}
                    existingWeeks={[]}
                    title={t('selectWeek', 'Select Week')}
                    confirmText={t('restore', 'Restore')}
                />
            )}
            {drafts &&
                drafts.length > 0 &&
                drafts.map((draft, index) => (
                    <MakeItDraftsSummaryOrderSection
                        index={index}
                        draft={draft}
                        accountsWithMakeIt={accountsWithMakeIt}
                        openDiscardModal={handleDiscardModal}
                        openRestoreDraftsModal={handleRestoreDraftsModal}
                        showCustomerPO={showCustomerPO(draft)}
                    />
                ))}
            {!drafts ||
                (drafts.length < 1 && (
                    <div className={classes.emptyTab}>
                        <Trans i18nKey="noDraftsFound">No Draft Orders Found</Trans>
                    </div>
                ))}
        </>
    );
};

export default MakeItDraftsSummaryTab;
