import React, { useState, useEffect, ReactNode } from 'react';
import { Grid, Typography, Button, makeStyles } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { brightGrey, boldWeight } from '../../../themes/globalConstants';
import { Trans, useTranslation } from 'react-i18next';
import Modal from './Modal';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { Region } from '../../../store/reducers/graphic-intake';

interface Props {
    title: ReactNode;
    open: boolean;
    onClose?: () => void;
}

const useStyles = makeStyles({
    modal: {
        '& .MuiPaper-root': {
            maxWidth: '40.5em'
        },
        '& .MuiDialog-paperScrollPaper': {
            borderRadius: 'unset',
            overflow: 'hidden'
        },
        '& .MuiDialogTitle-root': {
            justifyContent: 'center',
            marginLeft: '2.5em'
        }
    },
    content: {
        paddingLeft: '10%',
        paddingRight: '10%',
        textAlign: 'center',
        letterSpacing: '1px',
        color: brightGrey
    },
    text: {
        marginTop: 10,
        fontSize: 24,
        fontWeight: boldWeight
    },
    buttonLink: {
        textDecoration: 'none'
    },
    button: {
        height: 40,
        marginTop: 30,
        marginBottom: 60,
        fontSize: 14,
        fontWeight: 'normal',
        textTransform: 'capitalize'
    },
    successIcon: {
        height: 100,
        width: 100,
        marginTop: 20
    },
    buttonContainer: {
        marginTop: '1em'
    }
});
const GraphicsSubmittedModal = (props: Props) => {
    const classes = useStyles();
    const history = useHistory();
    const [open, setOpen] = useState(props.open);
    const { regionCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [viewMyInProgressGraphicsKey, setViewMyInProgressGraphicsKey] = useState<string>(
        'viewMyInProgressGraphics'
    );

    useEffect(() => {
        const region = regionCode === Region.EU ? 'Emea' : '';
        setViewMyInProgressGraphicsKey(`viewMyInProgressGraphics${region}`);
    }, [regionCode]);

    const closeConfirmationModal = () => {
        setOpen(false);
        if (props.onClose) {
            props.onClose();
        }
    };

    const onBackNavigation = () => {
        history.push('/');
    };

    useTranslation();

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    return (
        <Modal
            open={open}
            close={closeConfirmationModal}
            title={props.title}
            closeIcon={true}
            fullWidth={true}
            className={classes.modal}
        >
            {
                <Grid
                    container
                    justify="center"
                    className={classes.content}
                    data-testid="gfx-submit-modal"
                >
                    <Grid item>
                        <Typography>
                            <img
                                className={classes.successIcon}
                                src={process.env.PUBLIC_URL + '/assets/Confirmation_icon.svg'}
                                alt="Confirmation Icon"
                            />
                        </Typography>
                        <Typography className={classes.text}>
                            <Trans i18nKey="designHasBeenSubmittedSuccessfully">
                                Your design has been submitted successfully!
                            </Trans>
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        alignItems="center"
                        justify="center"
                        className={classes.buttonContainer}
                    >
                        <Grid item xs={5}>
                            <Button
                                type="button"
                                variant="outlined"
                                color="primary"
                                className={classes.button}
                                onClick={onBackNavigation}
                                data-testid="main-page-btn"
                            >
                                <Trans i18nKey="returnToMainPage">Return To Main Page</Trans>
                            </Button>
                        </Grid>
                        <Grid item xs={2} />
                        <Grid item xs={5}>
                            <Link to="/graphics-summary" className={classes.buttonLink}>
                                <Button
                                    type="button"
                                    variant="outlined"
                                    color="primary"
                                    className={classes.button}
                                    data-testid="summary-btn"
                                >
                                    <Trans i18nKey={viewMyInProgressGraphicsKey}>
                                        View Design Summary
                                    </Trans>
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Modal>
    );
};

export default GraphicsSubmittedModal;
