import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { blackWeight, red, white } from '../../../../themes/globalConstants';

const useStyles = makeStyles((theme) => ({
    legend: {
        background: white,
        padding: '12px',
        display: 'flex',
        borderRadius: '2px',
        '& ul': {
            margin: '0 0 0 1em',
            padding: 0,
            listStyleType: 'none',
            '& li': {
                display: 'flex',
                alignItems: 'center',
                gap: '4px'
            }
        }
    },
    agedBalanceColor: {
        color: red
    },
    icon: {
        width: '14px',
        marginRight: '2px'
    },
    dangerIcon: {
        width: '16px'
    },
    title: {
        fontWeight: blackWeight,
        color: theme.palette.secondary.main,
        padding: '0 0 0.5em 0'
    }
}));
const ScrapItLegend = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Grid className={classes.legend} data-testid='scrap-it-legend'>
            <Typography variant="subtitle2" className={classes.title}>
                {t('key', 'Key').toUpperCase()}
            </Typography>
            <ul>
                <li>
                    <img
                        src={process.env.PUBLIC_URL + '/assets/icon-clock-warning.svg'}
                        className={classes.icon}
                        alt="Aging Balance"
                    />
                    <Typography>{t('agingBalance', 'Aging Balance')}</Typography>
                </li>
                <li>
                    <img
                        src={process.env.PUBLIC_URL + '/assets/icon-clock-danger.svg'}
                        className={classes.dangerIcon}
                        alt="Aged Balance"
                    />

                    <Typography>{t('agedBalance', 'Aged Balance')}</Typography>
                </li>
            </ul>
        </Grid>
    );
};

export default ScrapItLegend;
