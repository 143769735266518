import { ShipToRoleAccess } from '../../models/role-access.model';
import { RoleAccessKey } from '../../models/role-access.model';

export enum LocationRoleAccessActions {
    UPDATE_ROLE_ACCESS = 'LOCATION_ROLE_ACCESS_UPDATE_ROLE_ACCESS',
    REVOKE_ALL_ROLE_ACCESS = 'LOCATION_ROLE_ACCESS_REVOKE_ALL_ROLE_ACCESS',
    GRANT_ALL_ROLE_ACCESS = 'LOCATION_ROLE_ACCESS_GRANT_ALL_ROLE_ACCESS',
    UPDATE_ACCOUNTS = 'LOCATION_ROLE_ACCESS_UPDATE_ACCOUNTS'
}

export const updateLocationRoleAccess = (
    accountId: string,
    role: RoleAccessKey,
    accessGranted: boolean,
    dispatch
) => {
    dispatch({
        type: LocationRoleAccessActions.UPDATE_ROLE_ACCESS,
        data: { accountId, role, accessGranted }
    });
};

export const revokeAllLocationRoleAccess = (
    accountIds: Array<string>,
    roleKey: RoleAccessKey,
    dispatch
) => {
    dispatch({
        type: LocationRoleAccessActions.REVOKE_ALL_ROLE_ACCESS,
        data: { accountIds, roleKey }
    });
};

export const grantAllLocationRoleAccess = (
    accountIds: Array<string>,
    roleKey: RoleAccessKey,
    dispatch
) => {
    dispatch({
        type: LocationRoleAccessActions.GRANT_ALL_ROLE_ACCESS,
        data: { accountIds, roleKey }
    });
};

export const updateLocationRoleAccessAccounts = (accounts: Array<ShipToRoleAccess>, dispatch) => {
    dispatch({
        type: LocationRoleAccessActions.UPDATE_ACCOUNTS,
        accounts
    });
};
