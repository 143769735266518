import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
    makeStyles,
    Typography,
    TableRow,
    TableCell,
    Grid,
    Input,
    IconButton,
    InputAdornment
} from '@material-ui/core';
import {
    ballLtGray,
    small,
    white,
    regularWeight,
    ballBlue,
    lightBlue,
    xs,
    shipRed,
    errorBackground,
    warningBorder,
    vanilla
} from '../../../../themes/globalConstants';
import Button from '../../../reusable/atoms/Button';
import { numericRegex } from '../../../../utility/helpers/formatters';
import {
    constructCanDescription,
    constructEndDescription,
    isProductCanType,
    isProductEndType,
    getGraphicIdAndVersion
} from '../../../../utility/helpers/order-helpers';
import { OrderProductTableRow } from '../../../../store/reducers/orders-dashboard';
import MakeItConfigChildRow from './MakeItConfigChildRow';
import Modal from '../../../reusable/molecules/Modal';
import moment from 'moment';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import clsx from 'clsx';
import {
    createLeadTimeDisplay,
    calculateLeadTime,
    setDateTimeToMidnight
} from '../../../../utility/helpers/date-helpers';
import SingleDaypickerModal from '../../../reusable/molecules/SingleDaypickerModal';
import {
    getEaches,
    getMakeItLeadTime,
    getMoqFeeCeiling,
    getMoqFeeFloor,
    resolveQuantity,
    getRequiredShipDate
} from '../../../../utility/helpers/production-order-helpers';
import QuantityInputCell from '../../../reusable/atoms/QuantityInputCell';
import { resolvePalletCount } from '../../../../utility/helpers/shipment-helpers';
import { MakeState } from '../../../../store/reducers/makeit-dashboard';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import CampaignPill from '../../../reusable/atoms/CampaignPill';

interface Props {
    item: OrderProductTableRow;
    onRemoveItem: (item: OrderProductTableRow) => void;
    onItemUpdates: (item: OrderProductTableRow) => void;
    warning: boolean;
    getPaginationRows: () => void;
}

const useStyles = makeStyles((theme) => ({
    tableRowContainer: {
        '& .helper-text': {
            fontWeight: `${regularWeight} !important`,
            fontSize: small
        },
        '& .MuiInputBase-input': {
            textAlign: 'right',
            paddingRight: '.25em'
        }
    },
    textField: {
        width: '6em',
        height: '1.5em',
        border: `1.8px solid ${ballLtGray}`,
        borderRadius: '.25em',
        marginRight: '0.5em',
        backgroundColor: white
    },
    alignCenter: {
        textAlign: 'center'
    },
    alignRight: {
        textAlign: 'right'
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    splitContainer: {
        borderLeft: 'none',
        paddingLeft: 0
    },
    split: {
        transform: 'rotate(90deg)',
        fill: ballBlue
    },
    splitText: {
        color: ballBlue,
        fontSize: xs
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    disabledText: {
        color: theme.palette.secondary.light
    },
    trash: {
        minWidth: 'unset'
    },
    hiddenCell: {
        borderLeft: 'none'
    },
    calendarDiv: {
        margin: '2em'
    },
    calendarInput: {
        border: `1.8px solid ${ballLtGray}`,
        borderRadius: 2,
        cursor: 'pointer'
    },
    calendarIcon: {
        color: ballBlue
    },
    blueBackground: {
        backgroundColor: lightBlue
    },
    feeError: {
        border: `1px solid ${shipRed}`,
        borderRight: 'none',
        backgroundColor: errorBackground
    },
    feeWarning: {
        border: `1px solid ${warningBorder}`,
        borderRight: 'none',
        backgroundColor: vanilla
    },
    splitFeeWarning: {
        border: `1px solid ${warningBorder}`,
        borderLeft: 'none',
        backgroundColor: vanilla
    },
    splitFeeError: {
        border: `1px solid ${shipRed}`,
        borderLeft: 'none',
        backgroundColor: errorBackground
    },
    parentRowTotals: {
        flexDirection: 'column',
        alignItems: 'flex-end',
        width: '100%'
    }
}));

export default function MakeItConfigRow({
    item,
    onRemoveItem,
    onItemUpdates,
    warning,
    getPaginationRows
}: Props) {
    const classes = useStyles();
    useTranslation();
    const { quantityUnit } = useTypedSelector<MakeState>((state) => state.makeItDashboard);

    const [purchaseQty, setPurchaseQty] = useState<number>(item.quantity || 0);
    const [inputQuantity, setInputQuantity] = useState<number>(resolveQuantity(quantityUnit, item));
    const [isSplit, setIsSplit] = useState<boolean>(false);
    const [totalQty, setTotalQty] = useState<number>(0); //total pallets
    const [totalQtyEaches, setTotalQtyEaches] = useState<number>(0);
    const [firstDay, setFirstDay] = useState<moment.Moment | null>(null);
    const [subRows, setSubrows] = useState<OrderProductTableRow[]>([]);
    const [daypickerDate, setDaypickerDate] = useState<string>('');
    const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
    const [counter, setCounter] = useState<number>(0);
    const [touchedInput, setTouchedInput] = useState<boolean>(item.modified || false);
    const canBottleDesc = constructCanDescription(item);
    const endDesc = constructEndDescription(item);
    const isEndType = isProductEndType(item.type);
    const isCanType = isProductCanType(item.type);
    const leadTime = item.leadTimeWeeks ?? getMakeItLeadTime(item);
    const baseLeadTimeDisplay = createLeadTimeDisplay(leadTime);
    const requiredShipDate = getRequiredShipDate(item.requestedDate);
    const leadTimeDisplay = item.requestedDate
        ? item.requestedDate
        : createLeadTimeDisplay(leadTime);
    const [selectedDate, setSelectedDate] = useState<string>(leadTimeDisplay);
    // Warning and Error logic
    const hasMoqFee = item.moqFees && item.moqFees.length > 0 ? true : false;
    const moqFeeFloor = getMoqFeeFloor(item.moqFees);
    const moqFeeCeiling = getMoqFeeCeiling(item.moqFees);

    const today = moment().format('MM/DD/YYYY');
    const calendarIcon = (
        <img
            className={classes.calendarIcon}
            src={process.env.PUBLIC_URL + '/assets/Calendar_Icon.svg'}
            alt="Calendar Icon"
        />
    );

    const onOpenCalendar = () => {
        setCalendarOpen(true);
    };

    const onCloseCalendar = () => {
        setCalendarOpen(false);
    };

    const handleOkClose = () => {
        setCalendarOpen(false);
        setSelectedDate(daypickerDate);

        if (daypickerDate !== item.requestedDate) {
            item.requestedDate = daypickerDate;
            onItemUpdates(item);
        }
    };

    // Removes main row from table
    const handleRemoveItem = () => {
        onRemoveItem(item);
    };

    // Updates total calculations for rows with subrows
    const handleQtyUpdate = () => {
        totalCalculations(subRows);
    };

    const handleSubRowSelectedDateChange = () => {
        onItemUpdates(item);
    };

    // Updates total for rows without subrows
    const updatePurchaseCount = (count: string) => {
        let numOfPallets = 0;
        if (numericRegex(count)) {
            const parsedCount = parseInt(count);
            if (!isNaN(parsedCount)) {
                numOfPallets = resolvePalletCount(
                    parsedCount,
                    item.quantityPerPallet!,
                    quantityUnit
                );
                setInputQuantity(parsedCount);
                setPurchaseQty(numOfPallets);
            } else {
                setInputQuantity(numOfPallets);
                setPurchaseQty(numOfPallets);
            }
        } else {
            setInputQuantity(numOfPallets);
            setPurchaseQty(numOfPallets);
        }
    };

    const handleQuantityOnBlur = () => {
        updateMinimumMet();
    };

    // Calculate total for rows with subrows
    const totalCalculations = (rows: OrderProductTableRow[]) => {
        let palletTotal = 0;
        let eachesTotal = 0;

        rows.forEach((item) => {
            if (item.quantity) {
                eachesTotal += item.eachesQuantity ?? 0;
                return (palletTotal += item.quantity);
            }
        });
        if (item.quantityPerPallet && quantityUnit === 'pallets') {
            setTotalQtyEaches(palletTotal * item.quantityPerPallet);
        } else {
            setTotalQtyEaches(eachesTotal);
        }
        setTotalQty(palletTotal);
    };

    // Validates whether or not minimum qty has been met
    const updateMinimumMet = () => {
        const quantity = purchaseQty === 0 ? totalQty : purchaseQty;

        if (item.minimumOrderQuantity) {
            const minValue = item.moqFees
                ? getMoqFeeFloor(item.moqFees)
                : item.minimumOrderQuantity;
            item.withinRange = minValue <= quantity && quantity < item.minimumOrderQuantity;
            item.minimumMet = quantity >= item.minimumOrderQuantity;
        } else {
            item.minimumMet = quantity !== null && quantity !== 0;
        }
        item.quantity = quantity;
        item.palletQuantity = quantity;
        item.eachesQuantity = getEaches(quantity, item.quantityPerPallet);
        item.modified = true;
        setTouchedInput(true);
        onItemUpdates(item);
    };

    const handleSplit = () => {
        // Create a new subrow object
        const newRow: OrderProductTableRow = {
            productId: item.productId,
            productSku: item.productSku,
            quantity: 0,
            splitOrder: true,
            leadTimeWeeks: item.leadTimeWeeks ?? getMakeItLeadTime(item),
            requestedDate: selectedDate,
            quantityPerPallet: item.quantityPerPallet,
            palletQuantity: 0,
            eachesQuantity: 0
        };

        let rows = [] as OrderProductTableRow[];

        // If the item doesn't have any subrows
        if (subRows.length === 0) {
            let firstRow = newRow;
            firstRow.index = counter;

            let secondRow = Object.assign({}, newRow);
            secondRow.index = firstRow.index + 1;
            // Update counter because of key logic on HTML
            setCounter(counter + 2);
            rows = [firstRow, secondRow];
            item.withinRange = false;
            item.minimumMet = false;
        } else if (subRows.length >= 2) {
            let row = Object.assign({}, newRow);
            row.index = counter + 1;
            rows = [...subRows, row];
            setCounter(counter + 1);
        }

        // Update item, local subrows, and isSplit
        item.subRows = rows;
        item.quantity = 0;
        item.requestedDate = selectedDate;

        setSubrows(rows);
        setIsSplit(true);
        onItemUpdates(item);
        //Maintain selected pagination
        getPaginationRows();
    };

    const handleRemoveChildItem = (rowToDelete: OrderProductTableRow) => {
        let updatedRows = [] as OrderProductTableRow[];
        if (subRows.length > 2) {
            updatedRows = subRows.filter((row) => {
                return row.index !== rowToDelete.index;
            });
        } else {
            setIsSplit(false);
            item.quantity = 0;
        }
        item.subRows = updatedRows;
        setSubrows(updatedRows);
        totalCalculations(updatedRows);
        onItemUpdates(item);
    };

    const handleDateSelect = (newDate: moment.Moment | null) => {
        const date = moment(newDate).format('MM/DD/YYYY');
        setDaypickerDate(date);
    };

    useEffect(() => {
        const today = moment();
        const leadTimeDay = calculateLeadTime(today, leadTime, 'weeks');
        const firstAvailableDay = setDateTimeToMidnight(leadTimeDay);
        setFirstDay(firstAvailableDay);
        // run only once while beginning
    }, []);

    useEffect(() => {
        if (!selectedDate) {
            setSelectedDate(leadTimeDisplay);
        }
    }, [leadTimeDisplay, selectedDate]);

    useEffect(() => {
        setTouchedInput(item.modified ?? false);
        if (item.requestedDate && item.quantity && (!item.subRows || item.subRows.length === 0)) {
            setSelectedDate(item.requestedDate);
            setPurchaseQty(item.quantity);
            setInputQuantity(resolveQuantity(quantityUnit, item));
        } else if (item.subRows && item.subRows.length > 0) {
            let palletTotal = 0;
            let eachesTotal = 0;

            item.subRows.forEach((item) => {
                if (item.quantity) {
                    eachesTotal += item.eachesQuantity ?? 0;
                    return (palletTotal += item.quantity);
                }
            });
            if (item.quantityPerPallet && quantityUnit === 'pallets') {
                setTotalQtyEaches(palletTotal * item.quantityPerPallet);
            } else {
                setTotalQtyEaches(eachesTotal);
            }
            setTotalQty(palletTotal);
            setPurchaseQty(0);
            setInputQuantity(0);
            setSubrows(item.subRows);
            setIsSplit(true);
            setCounter(item.subRows.length);
        }
    }, [item, quantityUnit]);

    useTranslation();

    return (
        <>
            <TableRow
                className={classes.tableRowContainer}
                data-testid={`product-${item.displayId}`}
            >
                <TableCell>
                    <Typography>{item.customerProductId ? item.customerProductId : "-"}</Typography>
                    <Typography>{item.displayId}</Typography>
                    <Typography>
                        {getGraphicIdAndVersion(item.type, item.graphicIdAndVersion)}
                    </Typography>
                </TableCell>
                <TableCell data-testid="product-description">
                    <Typography>{item.displayName}</Typography>
                    {isEndType && <Typography>{endDesc}</Typography>}
                    {isCanType && <Typography>{canBottleDesc}</Typography>}
                    {item.campaignTiming && <CampaignPill />}
                </TableCell>
                <TableCell>
                    <Typography>{item.coating}</Typography>
                </TableCell>
                <TableCell data-testid="leadtime-information">
                    <Typography data-leadtime={leadTime} data-testid="leadtime">
                        {`${leadTime} `}
                        <Trans i18nKey="weeks">Weeks</Trans>
                    </Typography>
                    <Typography data-testid="leadtime-date">{baseLeadTimeDisplay}</Typography>
                </TableCell>
                <TableCell className={classes.alignCenter} data-testid="calendar-input">
                    <Input
                        id={item.productSku}
                        type="button"
                        fullWidth
                        disabled={isSplit}
                        onClick={onOpenCalendar}
                        disableUnderline={true}
                        value={selectedDate}
                        className={classes.calendarInput}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="calendar modal trigger"
                                    onClick={onOpenCalendar}
                                    disabled={isSplit}
                                >
                                    {calendarIcon}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </TableCell>
                <TableCell data-testid="required-ship-date-information">
                    <Typography
                        data-required-ship-date={requiredShipDate}
                        data-testid="required-ship-date"
                    >
                        {`${requiredShipDate} `}
                    </Typography>
                </TableCell>
                <TableCell
                    className={clsx(
                        {
                            [classes.feeError]:
                                (touchedInput || warning) && !item.minimumMet && !item.withinRange
                        },
                        {
                            [classes.feeWarning]:
                                (touchedInput || warning) && !item.minimumMet && item.withinRange
                        }
                    )}
                >
                    <Grid container alignContent="center">
                        {(touchedInput || warning) && !item.minimumMet && item.withinRange && (
                            <img
                                src={process.env.PUBLIC_URL + '/assets/Warning_Icon.svg'}
                                alt="Warning Icon"
                            />
                        )}
                        {!isSplit && (
                            <Grid item className={classes.inputContainer}>
                                <QuantityInputCell
                                    quantity={inputQuantity}
                                    quantityPerPallet={item.quantityPerPallet!}
                                    quantityUnit={quantityUnit}
                                    onBlur={handleQuantityOnBlur}
                                    onChange={updatePurchaseCount}
                                    disabled={false}
                                    hasError={
                                        (touchedInput || warning) &&
                                        !item.minimumMet &&
                                        !item.withinRange
                                    }
                                />
                            </Grid>
                        )}
                        {isSplit && (
                            <Grid
                                item
                                className={clsx(classes.inputContainer, classes.parentRowTotals)}
                                data-testid="split-quantity-sum"
                            >
                                <Grid
                                    container
                                    justify={
                                        (touchedInput || warning) && !item.minimumMet
                                            ? 'space-between'
                                            : 'flex-end'
                                    }
                                >
                                    {(touchedInput || warning) &&
                                        !item.minimumMet &&
                                        !item.withinRange && (
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/assets/Error_Icon.svg'
                                                }
                                                alt="Error Icon"
                                            />
                                        )}
                                    {(touchedInput || warning) &&
                                        !item.minimumMet &&
                                        item.withinRange && (
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/assets/Warning_Icon.svg'
                                                }
                                                alt="Warning Icon"
                                            />
                                        )}
                                    <Typography>{totalQty.toLocaleString()} PL</Typography>
                                </Grid>
                                <Typography>{totalQtyEaches.toLocaleString()} ea.</Typography>
                            </Grid>
                        )}
                    </Grid>
                    {item.minimumOrderQuantity && item.minimumOrderQuantity > 0 && (
                        <Grid container>
                            <Typography className="helper-text" data-testid="minimum-order-helper">
                                <Trans i18nKey="minimumOrderIs">Minimum Order is</Trans>
                                {` ${item.minimumOrderQuantity} PL`}
                            </Typography>
                        </Grid>
                    )}
                    {hasMoqFee && (
                        <Grid container>
                            <Typography className="helper-text" data-testid="order-fee-helper">
                                <Trans i18nKey="feeForOrders">Fees for orders</Trans>{' '}
                                {` ${moqFeeFloor}-${moqFeeCeiling} PL`}
                            </Typography>
                        </Grid>
                    )}
                </TableCell>
                <TableCell
                    className={clsx(
                        classes.splitContainer,
                        {
                            [classes.splitFeeError]:
                                (touchedInput || warning) && !item.minimumMet && !item.withinRange
                        },
                        {
                            [classes.splitFeeWarning]:
                                (touchedInput || warning) && !item.minimumMet && item.withinRange
                        }
                    )}
                >
                    <Grid container justify="center" alignContent="center">
                        <IconButton
                            data-testid="split-icon"
                            aria-label="split icon"
                            onClick={handleSplit}
                            disableRipple
                            disableFocusRipple
                            className={classes.iconButton}
                        >
                            <CallSplitIcon className={classes.split} />
                            <Typography className={classes.splitText}>
                                <Trans i18nKey="split">Split</Trans>
                            </Typography>
                        </IconButton>
                    </Grid>
                </TableCell>
                <TableCell className={classes.alignCenter} data-testid="delete-icon">
                    <Button
                        type="button"
                        onClick={handleRemoveItem}
                        className={classes.trash}
                        disabled={isSplit}
                    >
                        {isSplit && (
                            <img
                                src={process.env.PUBLIC_URL + '/assets/DisabledTrashIcon.svg'}
                                alt="Disabled Trash Icon"
                            />
                        )}
                        {!isSplit && (
                            <img
                                src={process.env.PUBLIC_URL + '/assets/Trash.svg'}
                                alt="Trash Icon"
                            />
                        )}
                    </Button>
                </TableCell>
            </TableRow>
            {subRows.length > 0 &&
                subRows.map((child) => {
                    return (
                        <MakeItConfigChildRow
                            key={child.index}
                            item={child}
                            onRemoveItem={handleRemoveChildItem}
                            onQtyUpdate={handleQtyUpdate}
                            onBlurSetQty={handleQuantityOnBlur}
                            onSubRowSelectedDateChange={handleSubRowSelectedDateChange}
                            styles={useStyles}
                        />
                    );
                })}
            <Modal
                open={calendarOpen}
                title={<Trans i18nKey="todayWithDate">Today: {{ today }}</Trans>}
                secondaryTitle={
                    <Trans i18nKey="leadTimeForThisProduct">
                        Lead Time for this Product: {{ leadTime }} weeks
                    </Trans>
                }
                close={onCloseCalendar}
                closeIcon={true}
                maxWidth="xl"
            >
                <div className={classes.calendarDiv} data-testid="calendar-modal">
                    <SingleDaypickerModal
                        onCancel={onCloseCalendar}
                        onOk={handleOkClose}
                        selectDate={(newDate) => handleDateSelect(newDate)}
                        leadTime={leadTime}
                        firstDay={firstDay}
                    />
                </div>
            </Modal>
        </>
    );
}
