import React from 'react';
import { Link } from 'react-router-dom';
import { IconButton, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    button: {
        '&:hover': {
            backgroundColor: 'transparent'
        }
    }
}));

const MobileLogo = () => {
    const classes = useStyles();

    return (
        <Link to="/">
            <IconButton className={classes.button}>
                <img
                    src={process.env.PUBLIC_URL + '/assets/TheSource_Logo_Mobile.svg'}
                    alt="The Source Logo"
                    height="50"
                />
            </IconButton>
        </Link>
    );
};

export default MobileLogo;
