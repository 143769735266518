import { AppBar, Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import React, { ReactNode, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Prompt, useHistory } from 'react-router-dom';
import { CAPACITY_CHECK_CLEAR, PRODUCTS_CONFIGURED_CLEAR } from '../../store/actions/action-types';
import { Section } from '../../store/reducers/glp';
import { useTypedSelector } from '../../store/reducers/reducer';
import Button from '../reusable/atoms/Button';
import ErrorAlert from '../reusable/atoms/ErrorAlert';
import ActionFooter from '../reusable/molecules/ActionFooter';
import ConfirmationModal from '../reusable/molecules/ConfirmationModal';
import Footer from '../reusable/molecules/Footer';
import Header from '../reusable/molecules/Header';
import MobileFooter from '../reusable/molecules/MobileFooter';
import MobileHeader from '../reusable/molecules/MobileHeader';
import PageBanner from '../reusable/molecules/PageBanner';
import { RouterConfirmationOverride } from '../../App';
import { CapacityCheckState } from '../../store/reducers/capacity-checks';
import { large } from '../../themes/globalConstants';
import { Culture, RegionCultureState } from '../../store/reducers/region-culture';

export interface KBMaxConfig {
    key?: any;
    elementId: string;
    configurator: string;
    configuredProductId?: number;
    showFields?: boolean;
    showHeader?: boolean;
    showDrawer?: boolean;
    showConfigHeader?: boolean;
    showMove?: boolean;
    bindToForm?: string;
    loadStyle?: string;
    style?: any;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%'
    },
    mainContainer: {
        minHeight: 800
    },
    frenchSubmitButton: {
        height: '3.25em',
        marginTop: '1.875em',
        marginBottom: '3.75em',
        fontSize: large,
        fontWeight: 'normal',
        textTransform: 'capitalize',
        borderRadius: 'unset',
        width: '75%'
    }
}));

interface Props {
    children: ReactNode;
    kbMaxSettings?: KBMaxConfig;
    title?: string;
    banner?: Section;
}

export default function KBMaxTemplate({ children, title, banner }: Props) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const smScreens = useMediaQuery(theme.breakpoints.down('md'));
    const history = useHistory();
    const [exitModal, setExitModal] = useState(false);
    const [allowNavigation, setAllowNavigation] = useState<boolean>(false);
    const [lastLocationPath, setLastLocationPath] = useState<string>('');
    const [displayError, setDisplayError] = useState<boolean>(false);
    const [openCapacityCheckModal, setOpenCapacityCheckModal] = useState<boolean>(false);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const { t } = useTranslation();
    const { status } = useTypedSelector<CapacityCheckState>((state) => state.capacityCheck);

    const handleBlockedNavigation = (location) => {
        if (status === 'success') {
            return true;
        } else {
            setExitModal(true);
            if (location !== history.location.pathname) setLastLocationPath(location);
            if (!allowNavigation) {
                return false;
            }
            return true;
        }
    };

    const handleConfirmNavigation = () => {
        dispatch({ type: PRODUCTS_CONFIGURED_CLEAR });
        if (lastLocationPath !== '') {
            setAllowNavigation(true);
            // Navigate to the previously blocked location
            history.push(lastLocationPath);
        } else {
            setAllowNavigation(true);
            setExitModal(false);
            history.goBack();
        }
    };
    const handleOpenExitModal = () => {
        setExitModal(true);
    };

    const handleCloseExitModal = () => {
        setExitModal(false);
    };

    const onCloseCapacityCheckModal = () => {
        setOpenCapacityCheckModal(false);
    };

    useEffect(() => {
        if (status === 'success') {
            setOpenCapacityCheckModal(true);
        }
    }, [status]);

    const footerActions = (
        <>
            <Grid container item xs={4} justify="center">
                <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    data-testid="exit-button"
                    onClick={handleOpenExitModal}
                >
                    <Trans i18nKey="exit">Exit</Trans>
                </Button>
            </Grid>
        </>
    );

    useEffect(() => {
        if (allowNavigation && lastLocationPath !== '') {
            history.push(lastLocationPath);
        }
    }, [allowNavigation]);

    useEffect(() => {
        setDisplayError(status === 'error');
    }, [status]);

    useEffect(() => {
        return () => {
            // clear state and removing listener on page leave
            dispatch({ type: CAPACITY_CHECK_CLEAR });
        };
    }, [dispatch]);

    return (
        <div className={classes.root}>
            <AppBar position="sticky">
                {xsScreen ? <MobileHeader title={title} /> : <Header title={title} />}
            </AppBar>
            {banner && (
                <section>
                    <PageBanner {...banner} />
                </section>
            )}
            {displayError && (
                <Grid container xs={12}>
                    <ErrorAlert
                        showError={displayError}
                        errorMessage={t(
                            'submitATMError',
                            'The system was unable to process your changes.'
                        )}
                        dataTestId={'kbmax-save-order-error'}
                    />
                </Grid>
            )}
            <div className={classes.mainContainer}>{children}</div>
            <ConfirmationModal
                onClose={handleCloseExitModal}
                logo={false}
                title={<Trans i18nKey="exit">Exit</Trans>}
                continueText={<Trans i18nKey="yesExit">Yes, Exit</Trans>}
                cancelText={<Trans i18nKey="no">No</Trans>}
                open={exitModal}
                navigationLink=""
                onCancel={handleCloseExitModal}
                subheader={<Trans i18nKey="exitWarning">Are you sure you want to exit?</Trans>}
                saveProgress={handleConfirmNavigation}
            />
            <ConfirmationModal
                title={<Trans i18nKey="thankYou">Thank You</Trans>}
                open={openCapacityCheckModal}
                logo={true}
                subheader={
                    <Trans i18nKey="capacityCheckSuccessMessage">
                        Your request has been submitted! Reviews typically take 2-4 weeks. Someone
                        from our team will contact you once complete.
                    </Trans>
                }
                submitText={<Trans i18nKey="backToHome">Back to Home</Trans>}
                navigationLink="/"
                onClose={onCloseCapacityCheckModal}
                submitButtonClassName={
                    cultureCode === Culture.frFR ? classes.frenchSubmitButton : undefined
                }
            />
            <Prompt
                when={!allowNavigation}
                message={(location, action) => {
                    if (action === 'POP') {
                        handleBlockedNavigation(location.pathname);
                        return RouterConfirmationOverride.PreventBrowserBack;
                    } else {
                        return handleBlockedNavigation(location.pathname);
                    }
                }}
            />
            <ActionFooter footerAction={footerActions} justify="center" sticky={true} />
            {smScreens ? <MobileFooter /> : <Footer />}
        </div>
    );
}
