import React, { useState } from 'react';
import { Button, makeStyles, Grid, Menu } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { ballGray } from '../../../../themes/globalConstants';
import FreightFormula from '../../../reusable/atoms/FreightFormula';

interface Props {
    maxPallets: number;
    className?: any;
}

const useStyles = makeStyles((theme) => ({
    button: {
        padding: 0,
        float: 'right'
    },
    moreIcon: {
        width: '1.25em',
        height: '1.25em',
        marginLeft: '.5em',
        color: ballGray
    },
    wrapper: {
        '&:focus': {
            outline: 'none'
        },
        width: '55em',
        padding: '1.5em',
        textAlign: 'center'
    }
}));

const FreightFormulaDropdown = ({ maxPallets, className }: Props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div className={className}>
            <Button
                onClick={handleClick}
                aria-haspopup="true"
                className={classes.button}
                data-testid="user-menu"
            >
                <MoreHorizIcon className={classes.moreIcon} />
            </Button>
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <Grid alignItems="center" className={classes.wrapper}>
                    <FreightFormula maxPallets={maxPallets} />
                </Grid>
            </Menu>
        </div>
    );
};

export default FreightFormulaDropdown;
