import React from 'react';
import moment from 'moment';
import pdfStyles from '../../../../themes/pdfStyles';
import PdfPageTemplate from '../../../templates/PdfPageTemplate';
import { Document, Page, Text, View, BlobProvider, Image } from '@react-pdf/renderer';
import { isIE } from '../../../../utility/helpers/user-helpers';
import { useTranslation, Trans } from 'react-i18next';
import { formattedDateWithTimezone, getUserTimezone } from '../../../../utility/helpers/date-helpers';
import { PricingLine } from '../../../../store/reducers/pricing-breakdown';

interface Props {
    title: string;
    currency: string;
    effectiveDate: string;
    pricingData: PricingLine[];
    billToId: string;
    billToAddress: string;
    billToName: string;
    shipToId: string;
    shipToAddress: string;
    shipToName: string;
}

export default function PricingBreakdownPDF({
    title,
    currency,
    effectiveDate,
    pricingData,
    billToId,
    billToAddress,
    billToName,
    shipToId,
    shipToAddress,
    shipToName
}: Props) {
    const ballLogo = process.env.PUBLIC_URL + '/assets/Ball_RGB.jpg';
    const sourceLogo = process.env.PUBLIC_URL + '/assets/TheSource_Logo.png';
    const currentDateTimeStamp = `${formattedDateWithTimezone(moment(), 'MM/DD/YYYY hh:mm A')} ${getUserTimezone()}`;

    useTranslation();

    const pricingRows = pricingData.map((pricingRow, index) => {
        return (
            <>
                <View style={pdfStyles.divider8} />
                <View style={[pdfStyles.tableRow]} key={index} wrap={false}>
                    <View style={pdfStyles.borderLessTableColNarrow3}>
                        <Text style={[pdfStyles.tableCell2, pdfStyles.textAlignLeft]}>
                            {pricingRow.itemDescription}
                        </Text>
                    </View>
                    <View style={pdfStyles.borderLessTableColNarrow2}>
                        <Text style={[pdfStyles.tableCell2, pdfStyles.textAlignCenter]}>
                            {pricingRow.currentBasePriceFormat}
                        </Text>
                    </View>
                    <View style={pdfStyles.borderLessTableColNarrow2}>
                        <Text style={[pdfStyles.tableCell2, pdfStyles.textAlignCenter]}>
                            {pricingRow.newBasePriceFormat}
                        </Text>
                    </View>
                    <View style={pdfStyles.borderLessTableColNarrow2}>
                        <Text style={[pdfStyles.tableCell2, pdfStyles.textAlignCenter]}>
                            {pricingRow.freightChargeFromLocation}
                        </Text>
                    </View>
                    <View style={pdfStyles.borderLessTableColNarrow2}>
                        <Text style={[pdfStyles.tableCell2, pdfStyles.textAlignCenter]}>
                            {pricingRow.freightChargesFormat}
                        </Text>
                    </View>
                    <View style={pdfStyles.borderLessTableColNarrow7}>
                        <Text style={[pdfStyles.tableCell2, pdfStyles.textAlignCenter]}>
                            {pricingRow.newBasePriceWithFreightFormat}
                        </Text>
                    </View>
                </View>
            </>
        );
    });

    const PricingDocument = (
        <Document title={title}>
            <Page size="A4" style={pdfStyles.page} orientation={'portrait'}>

                {/* LOGOS */}
                <View style={pdfStyles.logoSection} fixed>
                    <Image src={sourceLogo} style={pdfStyles.sourceLogo} />
                    <Image src={ballLogo} style={pdfStyles.ballLogo} />
                </View>

                {/* DIVIDER */}
                <View style={pdfStyles.divider6} fixed />

                {/* TITLE AND DATE TIME STAMP */}
                <View style={[pdfStyles.flexSection3, pdfStyles.marginBottom10, pdfStyles.marginTop10]}>
                    <Text style={pdfStyles.header8Lower}>
                        <Trans i18nKey="priceSheet">Price Sheet</Trans>
                    </Text>
                    <View style={pdfStyles.tableRow3}>
                        <Text style={[pdfStyles.header2, pdfStyles.marginRight20]}>
                            <Trans i18nKey="dateTime">Date Time</Trans>
                        </Text>
                        <Text style={pdfStyles.header12}>
                            {currentDateTimeStamp}
                        </Text>
                    </View>
                </View>

                {/* BILL TO AND SHIP TO */}
                <View style={[pdfStyles.borderlessTable, pdfStyles.marginBottom10, pdfStyles.marginTop20]}>
                    <View style={pdfStyles.tableRow}>
                        <View style={[pdfStyles.borderLessTableColNarrow4]}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="billTo">Bill To</Trans>{':'}
                            </Text>
                        </View>
                        <View style={[pdfStyles.borderLessTableColWide4]}>
                            <Text style={[pdfStyles.header12, pdfStyles.uppercase]}>
                                {billToId}
                            </Text>
                            <Text style={[pdfStyles.header12, pdfStyles.uppercase]}>
                                {billToName}
                            </Text>
                            <Text style={[pdfStyles.header12, pdfStyles.uppercase]}>
                                {billToAddress}
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow4}>
                            <Text style={[pdfStyles.header2]}>
                                <Trans i18nKey="shipTo">Ship To</Trans>{':'}
                            </Text>
                        </View>
                        <View style={[pdfStyles.borderLessTableColWide4]}>
                            <Text style={[pdfStyles.header12, pdfStyles.uppercase]}>
                                {shipToId}
                            </Text>
                            <Text style={[pdfStyles.header12, pdfStyles.uppercase]}>
                                {shipToName}
                            </Text>
                            <Text style={[pdfStyles.header12, pdfStyles.uppercase]}>
                                {shipToAddress}
                            </Text>
                        </View>
                    </View>
                </View>

                {/* EFFECTIVE DATE and CURRENCY */}
                <View style={[pdfStyles.borderlessTable]}>
                    <View style={pdfStyles.tableRow}>
                        <View style={[pdfStyles.borderLessTableColNarrow4]}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="effectiveDate">Effective Date</Trans>
                            </Text>
                        </View>
                        <View style={[pdfStyles.borderLessTableColWide4]}>
                            <Text style={[pdfStyles.header12, pdfStyles.uppercase]}>
                                {effectiveDate}
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow4}>
                            <Text style={[pdfStyles.header2]}>
                                <Trans i18nKey="currency">Currency</Trans>
                            </Text>
                        </View>
                        <View style={[pdfStyles.borderLessTableColWide4]}>
                            <Text style={[pdfStyles.header12, pdfStyles.uppercase]}>
                                {currency}
                            </Text>
                        </View>
                    </View>
                </View>

                {/* PRICING QTY NOTE */}
                <View style={[pdfStyles.borderlessTable6, pdfStyles.marginBottom10]}>
                    <View style={pdfStyles.tableRow3}>
                        <View style={[pdfStyles.tableCell]}>
                            <Text style={pdfStyles.header12}>
                                <Trans i18nKey="pricingQtyNote">Prices are listed at a per thousand rate unless otherwise noted</Trans>
                            </Text>
                        </View>
                    </View>
                </View>

                {/* TABLE COLUMN NAMES */}
                <View style={[pdfStyles.borderlessTable, pdfStyles.marginTop20]}>
                    <View style={pdfStyles.tableRow}>
                        <View style={pdfStyles.borderLessTableColNarrow3}>
                            <Text style={[pdfStyles.header2, pdfStyles.textAlignLeft]}>
                                <Trans i18nKey="itemDescription">
                                    Item Description
                                </Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow2}>
                            <Text style={[pdfStyles.header2, pdfStyles.textAlignLeft]}>
                                <Trans i18nKey="currentBasePrice">Current Base Price</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow2}>
                            <Text style={[pdfStyles.header2, pdfStyles.textAlignLeft]}>
                                <Trans i18nKey="newBasePrice">New Base Price</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow2}>
                            <Text style={[pdfStyles.header2, pdfStyles.textAlignLeft]}>
                                <Trans i18nKey="freightChargeFromLocation">Freight Charge From Location</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow2}>
                            <Text style={[pdfStyles.header2, pdfStyles.textAlignLeft]}>
                                <Trans i18nKey="freightChargesAvailable">Freight Charges (if available)</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow7}>
                            <Text style={[pdfStyles.header2, pdfStyles.textAlignLeft]}>
                                <Trans i18nKey="newBaseWithFreight">New Base Price With Freight (if available)</Trans>
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.marginTop5}>
                        {pricingRows}
                    </View>
                </View>

                {/* DISCLAIMER AND PAGE NUMBERS */}
                <View style={[pdfStyles.fullWidth, pdfStyles.disclaimerFooter]} fixed>
                    <Text style={[pdfStyles.header2, pdfStyles.disclaimer]}>
                        <Trans i18nKey="pricingLetterDisclaimer">
                            This price sheet serves as notice of new pricing effective {{ effectiveDate }} and is not a guarantee of current or future supply. Your submission of an order to purchase products constitutes your acceptance of this updated pricing under Ball's current terms of sale.
                        </Trans>
                    </Text>
                    <Text
                        style={[pdfStyles.header2, pdfStyles.disclaimer]}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </View>
            </Page>
        </Document>
    );
    if (!isIE()) {
        return <PdfPageTemplate title={title}>{PricingDocument}</PdfPageTemplate>;
    } else {
        return (
            <BlobProvider document={PricingDocument}>
                {() => {
                    return null;
                }}
            </BlobProvider>
        );
    }
};