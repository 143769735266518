import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Trans } from 'react-i18next';
import { ballGray, medium } from '../../../themes/globalConstants';
import clsx from 'clsx';
import { InfoSubheaderItem } from '../../../store/reducers/glp';

interface Props {
    shipToName: string;
    shipToAddress: string;
    subheaderItems: InfoSubheaderItem[];
}

const useStyles = makeStyles((theme) => ({
    main: {
        marginBottom: '1.5em'
    },
    subtitle: {
        color: ballGray
    },
    item: {
        paddingLeft: '1em'
    },
    caps: {
        textTransform: 'uppercase'
    },
    capitalize: {
        textTransform: 'capitalize'
    },
    shipToText: {
        fontSize: medium,
        letterSpacing: '0.2'
    },
    subtext: {
        wordBreak: 'break-all'
    }
}));

const InfoSubheader = ({ shipToName, shipToAddress, subheaderItems }: Props) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.main}>
            <Grid container item xs={7}>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="shipTo">Ship To</Trans>
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography
                        className={clsx(classes.caps, classes.shipToText)}
                        data-testid="ship-to-name"
                    >
                        {shipToName}
                    </Typography>
                    <Typography
                        className={clsx(classes.capitalize, classes.shipToText)}
                        data-testid="ship-to-address"
                    >
                        {shipToAddress}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item xs={5} justify="flex-end">
                {subheaderItems.map((item, index) => (
                    <Grid item className={classes.item} key={index}>
                        <Typography
                            variant="subtitle2"
                            className={classes.subtitle}
                            data-testid="subtitle"
                        >
                            {item.subtitle}
                        </Typography>
                        <Typography variant="body1" data-testid="body">
                            {item.body}
                        </Typography>
                        {item.subtext && (
                            <Typography variant="body1" data-testid="subtext" className={classes.subtext}>
                                {item.subtext}
                            </Typography>
                        )}
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
};

export default InfoSubheader;
