import moment from 'moment';
import { getUtcMidnight } from '../../utility/helpers/date-helpers';
import {
    createProductRequestObj,
    formatLineInformation,
    getMatchingProductInformation,
    getRelatedProductLines,
    validateSkus
} from '../../utility/helpers/production-order-helpers';
import OrdersService, { Order_Status, ProductType } from '../../utility/services/orders-service';
import { AuthState } from '../reducers/auth';
import ProductService from '../../utility/services/product-service';
import { ProductionOrder, ProductionOrderLine } from '../reducers/makeit-dashboard';
import { OrderProductTableRow } from '../reducers/orders-dashboard';
import {
    EDIT_PRODUCTION_ORDER_ERROR,
    EDIT_PRODUCTION_ORDER_LOADING,
    EDIT_PRODUCTION_ORDER_SET_ORDER,
    EDIT_PRODUCTION_ORDER_RESET
} from './action-types';

export const setProductOrderForEdit = (id: number) => {
    return (dispatch, getState) => {
        dispatch({ type: EDIT_PRODUCTION_ORDER_LOADING });
        const auth: AuthState = getState().auth;

        OrdersService.getProductionOrder(getState(), id)
            .then((response) => {
                let order = response.data;
                let productRequestObj = createProductRequestObj(order.lines, undefined, [
                    order.shipToId.toString()
                ]);

                const productReq = { ...productRequestObj, accountId: order.shipToId.toString() };

                return Promise.all([
                    order,
                    ProductService.getMultipleProducts(getState, productReq),
                    OrdersService.getMakeItProducts(auth.accessToken, {
                        ShipToIds: [order.shipToId]
                    }),
                    OrdersService.getProductionOrderLinkedDeliveryOrders(
                        getState(),
                        order.productionOrderId!
                    )
                ]);
            })
            .then(([order, productApiResponse, orderApiResponse, linkedDeliveryOrderResponse]) => {
                const productApiProducts = productApiResponse?.data?.products;
                const orderApiProducts = orderApiResponse?.data?.products;
                const linkedDeliveryOrder = linkedDeliveryOrderResponse.data;
                order.linkedDeliveryOrder = linkedDeliveryOrder;
                let updatedLines: ProductionOrderLine[] = [];

                order.lines?.forEach((line) => {
                    // Get array of related lines by product SKU
                    const relatedProductLines = getRelatedProductLines(order.lines || [], line);
                    // See if the current line's product SKU has been processed already
                    const unprocessedSku = validateSkus(updatedLines, line);

                    if (
                        line.status !== Order_Status.Cancelled &&
                        line.status !== Order_Status.CancelledPending
                    ) {
                        if (unprocessedSku && relatedProductLines.length === 0) {
                            updatedLines.push({ ...(line as ProductionOrderLine) });
                        } else if (
                            relatedProductLines.length > 0 &&
                            !line.subRows &&
                            unprocessedSku
                        ) {
                            relatedProductLines.sort((prev, curr) =>
                                moment(prev.requestedDate).isSameOrBefore(
                                    moment(curr.requestedDate)
                                )
                                    ? -1
                                    : 1
                            );
                            updatedLines.push({
                                ...line,
                                requestedDate: relatedProductLines[0].requestedDate,
                                subRows:
                                    relatedProductLines.length > 1 ? relatedProductLines : undefined
                            });
                        }
                    }
                });

                updatedLines = updatedLines.map((line) => {
                    if (line.subRows) {
                        let originalRowRequestedDates: Date[] = [];
                        let totalPalletQuantity = 0;
                        // Set up data for each sub row
                        line.subRows.forEach((subRow) => {
                            originalRowRequestedDates.push(getUtcMidnight(subRow.requestedDate));
                            totalPalletQuantity += subRow.palletQuantity ?? 0;
                            formatLineInformation(subRow, linkedDeliveryOrder);
                            getMatchingProductInformation(
                                productApiProducts,
                                line,
                                line.productSize,
                                line.productType,
                                line.productShape
                            );
                            line = setProductInfo(orderApiProducts, line);
                            subRow.index = subRow.productionOrderLineId;
                        });

                        // Set up data on the parent line
                        formatLineInformation(line, linkedDeliveryOrder);
                        line.palletQuantity = totalPalletQuantity;
                        line.originalPalletQuantity = totalPalletQuantity;
                    } else {
                        formatLineInformation(line, linkedDeliveryOrder);
                        getMatchingProductInformation(
                            productApiProducts,
                            line,
                            line.productSize,
                            line.productType,
                            line.productShape
                        );
                        line = setProductInfo(orderApiProducts, line);
                    }
                    return line;
                });

                dispatch({
                    type: EDIT_PRODUCTION_ORDER_SET_ORDER,
                    productionOrderToEdit: { ...order, lines: updatedLines }
                });
            })
            .catch((error) => {
                dispatch({
                    type: EDIT_PRODUCTION_ORDER_ERROR,
                    error
                });
            });
    };
};
const setProductInfo = (products: OrderProductTableRow[], line: ProductionOrderLine) => {
    const matchingProduct = products.find((product) => product.productSku === line.productSku);
    let newLine = line;
    if (matchingProduct) {
        newLine = { ...(matchingProduct as ProductionOrderLine), ...line };
    }
    return newLine;
};

export const resetEditProductionOrder = () => {
    return (dispatch) => {
        dispatch({ type: EDIT_PRODUCTION_ORDER_RESET });
    };
};

export const updateEditProductionOrder = (updatedProductionOrder: ProductionOrder) => {
    return (dispatch) => {
        let canQuantityPallets = 0;
        let canQuantitySKUs = 0;
        let canQuantityEaches = 0;
        let endQuantityPallets = 0;
        let endQuantitySKUs = 0;
        let endQuantityEaches = 0;
        updatedProductionOrder.lines?.forEach((line: ProductionOrderLine, index) => {
            if (
                line.productType === ProductType.Cans &&
                line.palletQuantity &&
                line.isLineRemoved !== true
            ) {
                canQuantitySKUs++;
                canQuantityPallets += line.palletQuantity;
                canQuantityEaches += line.palletQuantity * line.quantityPerPallet!;
            } else if (
                line.productType === ProductType.Ends &&
                line.palletQuantity &&
                line.isLineRemoved !== true
            ) {
                endQuantitySKUs++;
                endQuantityPallets += line.palletQuantity;
                endQuantityEaches += line.palletQuantity * line.quantityPerPallet!;
            }
        });
        updatedProductionOrder = {
            ...updatedProductionOrder,
            canQuantityEaches,
            canQuantityPallets,
            canQuantitySKUs,
            endQuantityEaches,
            endQuantityPallets,
            endQuantitySKUs
        };
        dispatch({
            type: EDIT_PRODUCTION_ORDER_SET_ORDER,
            productionOrderToEdit: updatedProductionOrder
        });
    };
};
