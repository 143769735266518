import { Grid, FormHelperText, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
    medium,
    ltBlueGrey_34,
    xl,
    boldWeight,
    red,
    warningLabel
} from '../../../../themes/globalConstants';
import TextInput from '../../../reusable/molecules/TextInput';
import FormSection from '../../../reusable/molecules/FormSection';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { AuthState } from '../../../../store/reducers/auth';
import { EmeaGraphics } from '../../../../store/reducers/graphic-intake';
import { useFormContext, ErrorMessage, Controller } from 'react-hook-form';
import RadioInput from '../../../reusable/molecules/RadioInput';
import PhoneInput from '../../../reusable/molecules/PhoneInput';
import clsx from 'clsx';
import { isValidEmail } from '../../../../utility/helpers/user-helpers';

interface Props {
    isEditDraft: boolean;
    graphicData?: EmeaGraphics;
}

const useStyles = makeStyles((theme) => ({
    subsectionHeader: {
        fontSize: xl,
        fontWeight: boldWeight,
        color: theme.palette.info.dark,
        marginBottom: '2em'
    },
    label: {
        color: theme.palette.info.dark,
        fontSize: medium,
        marginBottom: '0.5em'
    },
    radioGroup: {
        marginTop: '1.5em',
        paddingLeft: '0.5em'
    },
    divider: {
        height: 5,
        backgroundColor: ltBlueGrey_34,
        margin: '1.5em 0'
    },
    helperText: {
        '& .MuiFormHelperText-root': {
            marginLeft: 0
        }
    },
    warningContainer: {
        display: 'flex',
        backgroundColor: warningLabel,
        margin: '1em 1.5em 1em 0.5em'
    },
    warningIconCtn: {
        textAlign: 'center'
    },
    warningIcon: {
        fill: red
    },
    warningLabel: {
        fontSize: medium,
        fontWeight: boldWeight
    },
    capitalize: {
        textTransform: 'capitalize'
    },
    errorText: {
        color: red
    }
}));

const ApprovalInfo = ({ isEditDraft, graphicData }: Props) => {
    const classes = useStyles();
    const { control, errors, setValue, getValues } = useFormContext();
    const { userInfo } = useTypedSelector<AuthState>((state) => state.auth);
    const { t } = useTranslation();

    const isRequired = <Trans i18nKey="required">Required</Trans>;

    useEffect(() => {
        if (isEditDraft && graphicData) {
            setValue('proofSession', graphicData.proofSession ? 'true' : 'false');
            setValue('attendProduction', graphicData.attendProduction ? 'true' : 'false');

            if (graphicData.approvers?.length) {
                setValue('approverName', graphicData.approvers[0].approverName);
                setValue('approverPhoneNumber', graphicData.approvers[0].approverPhoneNumber);
                setValue('approverEmail', graphicData.approvers[0].approverEmail);
            }
        }
    }, [isEditDraft, graphicData, setValue]);

    return (
        <FormSection
            testId={'approval-information-section'}
            sectionHeader={<Trans i18nKey="approvalSubInfo">For Approval</Trans>}
        >
            <Grid item xs={12}>
                <Typography className={classes.label} variant="body1">
                    <Trans i18nKey="forApprovalSubTitle">
                        A layout proof is always supplied by Ball for approval of the full layout
                        following amendments. Ball supplies a colour proof (Contract proof or
                        rollouts) for colour approval unless a clear production target is received.
                    </Trans>
                </Typography>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                    <RadioInput
                        styles={classes.radioGroup}
                        label={<Trans i18nKey="proofSession">Proof Session Required?</Trans>}
                        name="proofSession"
                        testId="proofSession"
                        control={control}
                        radioControls={[
                            { value: 'true', label: <Trans i18nKey="yes">Yes</Trans> },
                            { value: 'false', label: <Trans i18nKey="no">No</Trans> }
                        ]}
                        error={errors.proofSession}
                        errors={errors}
                        defaultValue="false"
                    />
                    {!errors.proofSession && (
                        <FormHelperText>
                            <Trans i18nKey="proofSessionHelper">
                                Select "Yes" to produce a proof can. It is possible to make a proof
                                can for your design prior to production. Note that additional
                                charges apply. Your commercial contact can update you on the latest
                                prices.
                            </Trans>
                        </FormHelperText>
                    )}
                </Grid>
                <Grid item xs={6}>
                    <RadioInput
                        styles={classes.radioGroup}
                        label={<Trans i18nKey="attendProduction">Attend Production?</Trans>}
                        name="attendProduction"
                        testId="attendProduction"
                        control={control}
                        rules={{ required: isRequired }}
                        radioControls={[
                            { value: 'true', label: <Trans i18nKey="yes">Yes</Trans> },
                            { value: 'false', label: <Trans i18nKey="no">No</Trans> }
                        ]}
                        error={errors.attendProduction}
                        errors={errors}
                        defaultValue="false"
                    />
                    {!errors.attendProduction && (
                        <FormHelperText>
                            <Trans i18nKey="attendProductionHelper">
                                Select "Yes" if you would like to sign off first production in our
                                plant. Attending production is only possible under certain
                                circumstances and after agreement to the Ball COS protocol. Refer to
                                your commercial representative for details.
                            </Trans>
                        </FormHelperText>
                    )}
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                    <TextInput
                        type="text"
                        label={
                            <Trans i18nKey="responsibleForApproval">Responsible for Approval</Trans>
                        }
                        name="approverName"
                        testId="approverName"
                        control={control}
                        rules={{ required: isRequired }}
                        error={errors.approverName}
                        errors={errors}
                        defaultValue={userInfo?.name}
                        styles={classes.helperText}
                        helperText={
                            <Trans i18nKey="approverNameHelper">
                                Specify the name of the person responsible for approval of artwork{' '}
                            </Trans>
                        }
                    />
                    <FormHelperText className={classes.errorText}>
                        {errors.approverName && errors.approverName.type === 'required' && (
                            <ErrorMessage
                                errors={errors}
                                name={'approverName'}
                                message={isRequired}
                            />
                        )}
                    </FormHelperText>
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                    <Controller
                        as={
                            <PhoneInput
                                label={<Trans i18nKey="phoneNumber">Phone Number</Trans>}
                                testId="approverPhoneNumber"
                                disableAreaCodes={true}
                                defaultCountry={'gb'}
                                defaultValue={getValues('approverPhoneNumber')}
                            />
                        }
                        rules={{
                            required: isRequired,
                            pattern: /^\+([0-9][ \-()]{0,2}){9,14}[0-9]$/
                        }}
                        name="approverPhoneNumber"
                        control={control}
                        error={errors.approverPhoneNumber}
                        errors={errors}
                        styles={clsx(classes.helperText, {
                            [classes.errorText]: errors.approverPhoneNumber
                        })}
                        defaultValue={userInfo?.PhoneNumber}
                    />
                    {!errors.approverPhoneNumber && (
                        <FormHelperText>
                            <Trans i18nKey="approverPhoneNumberHelper">
                                Specify the phone number for the person responsible for artwork
                                approval
                            </Trans>
                        </FormHelperText>
                    )}
                    <FormHelperText className={classes.errorText}>
                        {errors.approverPhoneNumber &&
                            errors.approverPhoneNumber.type === 'required' && (
                                <ErrorMessage
                                    errors={errors}
                                    name={'approverPhoneNumber'}
                                    message={isRequired}
                                />
                            )}
                        {errors.approverPhoneNumber &&
                            errors.approverPhoneNumber.type === 'pattern' && (
                                <ErrorMessage
                                    errors={errors}
                                    name={'approverPhoneNumber'}
                                    message={t('invalidPhoneNumber', 'Invalid Phone Number')}
                                />
                            )}
                    </FormHelperText>
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        type="text"
                        label={<Trans i18nKey="emailAddress">Email Address</Trans>}
                        name="approverEmail"
                        testId="approverEmail"
                        control={control}
                        rules={{
                            required: isRequired,
                            validate: {
                                isValidEmail: (value) => {
                                    return (
                                        isValidEmail(value) || (
                                            <Trans i18nKey="incorrectEmailAddress">
                                                Invalid Email Format
                                            </Trans>
                                        )
                                    );
                                }
                            }
                        }}
                        error={errors.approverEmail}
                        errors={errors}
                        defaultValue={userInfo?.email}
                        styles={classes.helperText}
                        helperText={
                            <Trans i18nKey="emeaApproverEmailHelper">
                                Specify the email address for the person responsible for artwork
                                approval
                            </Trans>
                        }
                    />
                    <FormHelperText className={classes.errorText}>
                        {errors.approverEmail && (
                            <ErrorMessage errors={errors} name={'approverEmail'} />
                        )}
                    </FormHelperText>
                </Grid>
            </Grid>
        </FormSection>
    );
};

export default ApprovalInfo;
