import React, { ReactElement, useMemo, useState, useEffect } from 'react';
import { AuthState } from '../../../store/reducers/auth';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { useParams, useHistory } from 'react-router';
import moment from 'moment';
import rootTheme from '../../../themes/rootTheme';
import { Trans, useTranslation } from 'react-i18next';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { Activity } from '../../../utility/auth/useSecurity';
import {
    Grid,
    makeStyles,
    Paper,
    Typography,
    TextareaAutosize,
    TextField
} from '@material-ui/core';
import { InputState, PageParams, InputErrorState } from './utilities/interfaces';
import {
    getDefaultErrorState,
    getErrorState,
    createCommunicationMessage,
    editCommunicationMessage
} from './utilities/helpers';
import { styles } from './components/styles';
import ToasterAlert, { SeverityState } from '../../reusable/molecules/ToasterAlert';
import CheckboxSection from './components/CheckboxSection';
import RadioGroupSection from './components/RadioGroupSection';
import DateSection from './components/DateSection';
import Footer from './components/Footer';
import NavigationConfirmation from './components/NavigationConfirmation';
import {
    CommunicationManagementState,
    DisplayPage,
    MessageType,
    RecipientType,
    Region
} from '../../../store/reducers/communication-management';
import { useDispatch } from 'react-redux';
import {
    loadCommunicationMessagesData,
    loadCommunicationSummaryData
} from '../../../store/actions/communications';

const initialInputState: InputState = {
    messageTitle: '',
    messageContent: '',
    startDate: moment().add(1, 'hour').set({ minute: 0 }),
    endDate: moment().add(1, 'hour').add(30, 'days').set({ minute: 0 }),
    messageTypeSelection: new Set<MessageType>(),
    displayPageSelection: new Set<DisplayPage>(),
    regionSelection: new Set<Region>(),
    recipientTypeSelection: RecipientType.Internal
};

export default function ConfigureCommunicationPage(): ReactElement {
    const { t } = useTranslation();
    const { communicationId } = useParams<PageParams>();
    const useStyles = makeStyles(styles(rootTheme) as any);
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    // Redux State ///////////////////////////////////////////////////////////////////////////////////

    const { userInfo, accessToken } = useTypedSelector<AuthState>((state) => state.auth);
    const { communications, loaded: communicationSummaryLoaded } =
        useTypedSelector<CommunicationManagementState>((state) => state.communicationManagement);

    // Local State ///////////////////////////////////////////////////////////////////////////////////

    const [inputState, setInputState] = useState<InputState>(initialInputState);
    const [submitButtonClicked, setSubmitButtonClicked] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [toastMessage, setToastMessage] = useState<string>('');
    const [toastMessageSeverity, setToastMessageSeverity] = useState<SeverityState>('info');

    // Computed Values ///////////////////////////////////////////////////////////////////////////////

    const communicationToEdit = useMemo(() => {
        return communications.find((c) => c.communicationId?.toString() === communicationId);
    }, [communicationId, communications]);

    const headerText = useMemo(() => {
        return communicationToEdit
            ? t('editMessage', 'Edit Message')
            : t('newMessage', 'New Message');
    }, [t, communicationToEdit]);

    const errorState = useMemo<InputErrorState>(() => {
        const errorState = getErrorState(inputState, communicationToEdit);

        if (!submitButtonClicked)
            return {
                ...getDefaultErrorState(),
                startDateErrorMessage: communicationToEdit ? '' : errorState.startDateErrorMessage,
                endDateErrorMessage: communicationToEdit ? '' : errorState.endDateErrorMessage
            };

        return errorState;
    }, [inputState, submitButtonClicked, communicationToEdit]);

    const disableSubmission = useMemo<boolean>(() => {
        return submitButtonClicked && errorState.anyErrorExists;
    }, [submitButtonClicked, errorState.anyErrorExists]);

    const charactersLeftMessage = useMemo<string>(() => {
        const charactersLeft = 5000 - inputState.messageContent.length;
        let message = t('charactersLeft', 'characters left');
        if (charactersLeft === 1) message = t('characterLeft', 'character left');

        return `${charactersLeft} ${message}`;
    }, [t, inputState.messageContent]);

    const submitterName = useMemo<string>(() => {
        return userInfo?.name || `${userInfo?.given_name} ${userInfo?.family_name}` || 'Unknown';
    }, [userInfo]);

    const submitterEmail = useMemo<string>(() => {
        return userInfo?.preferred_username || userInfo?.email || 'Unknown';
    }, [userInfo]);

    const isInitialState = useMemo<boolean>(() => {
        return inputState === initialInputState;
    }, [inputState]);

    // Event Triggers /////////////////////////////////////////////////////////////////////////////////

    useEffect(() => {
        // Load communication summary data if a communication ID is present in the route parameters
        if (communicationId && !communicationSummaryLoaded)
            dispatch(loadCommunicationSummaryData());

        // If a communication ID is present in the route parameters but couldn't be found in the summary, show an error
        if (communicationSummaryLoaded && communicationId && !communicationToEdit)
            showToastMessage(
                t('messageNotFound', `The message you're attempting to edit could not be found`),
                'error'
            );

        // If a communication message is found from the ID in the route parameters, preload the form with its details
        if (communicationSummaryLoaded && communicationToEdit)
            setInputState({
                messageTitle: communicationToEdit.title,
                messageContent: communicationToEdit.content,
                startDate: moment.utc(communicationToEdit.startDateTime),
                endDate: moment.utc(communicationToEdit.endDateTime),
                messageTypeSelection: new Set(
                    communicationToEdit.communicationMessageTypes.map((x) => x.messageType)
                ),
                displayPageSelection: new Set(
                    communicationToEdit.communicationDisplayPages.map((x) => x.displayPage)
                ),
                regionSelection: new Set(
                    communicationToEdit.communicationRegions.map((x) => x.region)
                ),
                recipientTypeSelection: communicationToEdit.recipientType
            });
    }, [dispatch, t, communicationId, communicationSummaryLoaded, communicationToEdit]);

    // Functions //////////////////////////////////////////////////////////////////////////////////////

    function toggleSelectionOption(
        selectionField: string,
        selection: any,
        option: any,
        value: boolean
    ) {
        const newSelection = new Set(selection);
        if (value) newSelection.add(option);
        else newSelection.delete(option);
        setInputState({ ...inputState, [selectionField]: newSelection });
    }

    async function handleSubmit() {
        setSubmitButtonClicked(true);
        const { anyErrorExists } = getErrorState(inputState, communicationToEdit);

        if (anyErrorExists) {
            showToastMessage(
                t('correctAllErrors', 'Please correct all errors before submitting'),
                'error'
            );
            return;
        }

        const submitOperation = communicationToEdit?.communicationId
            ? editCommunicationMessage(
                  accessToken,
                  communicationToEdit.communicationId,
                  inputState,
                  submitterName,
                  submitterEmail
              )
            : createCommunicationMessage(accessToken, inputState, submitterName, submitterEmail);

        setLoading(true);
        const successfullySubmitted = await submitOperation;
        if (successfullySubmitted) {
            showToastMessage(
                t('sucessfulSubmission', 'Your message has been submitted successfully'),
                'success'
            );
            dispatch(loadCommunicationMessagesData());
            resetForm();
            if (communicationToEdit)
                setTimeout(() => history.replace('/configure-communication'), 1500);
        } else {
            showToastMessage(
                t('errorOccurred', 'An error occurred while processing your request'),
                'error'
            );
        }

        setLoading(false);
    }

    function showToastMessage(message: string, severity: SeverityState) {
        setToastMessage('');
        setToastMessage(message);
        setToastMessageSeverity(severity);
    }

    function resetForm() {
        setSubmitButtonClicked(false);
        setInputState(initialInputState);
    }

    // Main Component //////////////////////////////////////////////////////////////////////////////////////

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('manageCommunication', 'Manage Communication'),
                description: '',
                thinBanner: true,
                disableSelect: true
            }}
            actionFooter={{
                footerAction: (
                    <Footer
                        disableSubmission={disableSubmission}
                        handleSubmit={handleSubmit}
                        allowReturn={isInitialState}
                    />
                ),
                justify: 'space-between',
                sticky: true
            }}
            activity={Activity.CommunicationManagement}
            loading={loading}
        >
            <Paper elevation={2} className={classes.paper}>
                {/* Title Header */}
                <Typography variant="h3" className={classes.title}>
                    {headerText}
                </Typography>

                {/* Message Type Selection Section */}
                <CheckboxSection
                    header={t('messageType', 'Message Type')}
                    options={[
                        MessageType.Banner,
                        MessageType.Popup
                        // MessageType.Email // This should be enabled when email feature comes online
                    ]}
                    selection={inputState.messageTypeSelection}
                    toggleSelection={(value, option) =>
                        toggleSelectionOption(
                            'messageTypeSelection',
                            inputState.messageTypeSelection,
                            option,
                            value
                        )
                    }
                    errorMessage={errorState.messageTypeErrorMessage}
                />

                {/* Name Input Section */}
                <Grid container spacing={3} className={classes.section}>
                    <Grid item xs={4}>
                        <TextField
                            label={<Trans i18nKey="messageName">Message Name</Trans>}
                            type="text"
                            fullWidth
                            value={inputState.messageTitle}
                            onChange={(event) => {
                                if (event.target.value.length <= 100)
                                    setInputState({
                                        ...inputState,
                                        messageTitle: event.target.value
                                    });
                            }}
                            error={!!errorState.messageTitleErrorMessage}
                            helperText={
                                errorState.messageTitleErrorMessage ? (
                                    <>{errorState.messageTitleErrorMessage}</>
                                ) : (
                                    <Trans i18nKey="messageNameHelper">
                                        Name this message for future reference
                                    </Trans>
                                )
                            }
                        />
                    </Grid>
                </Grid>

                {/* Date Input Section */}
                <DateSection
                    startDate={inputState.startDate}
                    startDateLabel={t('startDateTime', 'Start Date/Time')}
                    setStartDate={(value) => setInputState({ ...inputState, startDate: value })}
                    endDate={inputState.endDate}
                    endDateLabel={t('endDateTime', 'End Date/Time')}
                    setEndDate={(value) => setInputState({ ...inputState, endDate: value })}
                    startDateErrorMessage={errorState.startDateErrorMessage}
                    endDateErrorMessage={errorState.endDateErrorMessage}
                />

                {/* Message Content Section */}
                <Grid container spacing={2} align-items="flex-start" className={classes.section}>
                    <Grid item xs={12}>
                        <TextareaAutosize
                            aria-label="Message Content"
                            rowsMin={5}
                            placeholder={t('message', 'Message')}
                            value={inputState.messageContent}
                            onChange={(event: any) => {
                                if (event.target.value.length <= 5000)
                                    setInputState({
                                        ...inputState,
                                        messageContent: event.target.value
                                    });
                            }}
                            className={classes.textArea}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.charactersLeft}>
                            {charactersLeftMessage}
                        </Typography>
                    </Grid>
                    {errorState.messageContentErrorMessage && (
                        <Typography color="error" className={classes.error}>
                            {errorState.messageContentErrorMessage}
                        </Typography>
                    )}
                </Grid>

                {/* Page Display Selection Section */}
                <CheckboxSection
                    header={t('pagesToDisplay', 'Pages To Display Message')}
                    options={[
                        { label: 'Global Landing Page', value: DisplayPage.GlobalLandingPage },
                        { label: 'Dashboard', value: DisplayPage.Dashboard },
                        { label: 'Plan It Landing Page', value: DisplayPage.PlanItLandingPage },
                        { label: 'Make It Landing Page', value: DisplayPage.MakeItLandingPage },
                        { label: 'Ship It Landing Page', value: DisplayPage.ShipItLandingPage },
                        { label: 'Product Planning', value: DisplayPage.ProductPlanning },
                        { label: 'Payable Landing Page', value: DisplayPage.PayableLandingPage },
                        { label: 'Make It Drafts', value: DisplayPage.MakeItDrafts },
                        { label: 'Submit New Graphics', value: DisplayPage.SubmitNewGraphics },
                        { label: 'Submit New Liquid Test', value: DisplayPage.SubmitNewLiquidTest }
                    ]}
                    selection={inputState.displayPageSelection}
                    toggleSelection={(value, option) =>
                        toggleSelectionOption(
                            'displayPageSelection',
                            inputState.displayPageSelection,
                            option,
                            value
                        )
                    }
                    errorMessage={errorState.displayPagesErrorMessage}
                />

                {/* Region Selection Section */}
                <CheckboxSection
                    header={t('regions', 'Regions')}
                    options={[Region.NCA, Region.EMEA, Region.SA]}
                    selection={inputState.regionSelection}
                    toggleSelection={(value, option) =>
                        toggleSelectionOption(
                            'regionSelection',
                            inputState.regionSelection,
                            option,
                            value
                        )
                    }
                    errorMessage={errorState.regionsErrorMessage}
                />

                {/* User Type Selection Section */}
                <RadioGroupSection
                    header={t('internalExternalUsers', 'Internal/External Users')}
                    options={[RecipientType.Internal, RecipientType.External, RecipientType.All]}
                    labelOverrides={{ [RecipientType.All]: 'Both' }}
                    selection={inputState.recipientTypeSelection}
                    setSelection={(value) =>
                        setInputState({ ...inputState, recipientTypeSelection: value })
                    }
                />

                {/* Submitter Details Section */}
                <Grid container spacing={2} align-items="flex-start">
                    <Grid item xs={4}>
                        <TextField
                            disabled
                            type="text"
                            label={<Trans i18nKey="name">Name</Trans>}
                            defaultValue={submitterName || submitterEmail || 'Unknown'}
                            fullWidth
                            helperText={
                                <Trans i18nKey="submitterNameHelper">
                                    Name of person submitting this form
                                </Trans>
                            }
                            className={classes.displayInput}
                        />
                    </Grid>
                </Grid>
            </Paper>

            {/* Toast Popup */}
            <ToasterAlert
                key={toastMessage}
                adjustedPlacement="center"
                showAlert={!!toastMessage}
                message={toastMessage}
                severity={toastMessageSeverity}
            />

            {/* Navigation Confirmation */}
            <NavigationConfirmation requireConfirmation={!isInitialState} />
        </ProcessingPageTemplate>
    );
}
