import React from 'react';
import { Grid, Typography, makeStyles, Box } from '@material-ui/core';
import { ContentSubSection } from '../../../../store/reducers/content-page';
import ContentLink from './ContentLink';
import sanitizeHtml from 'sanitize-html-react';
import VideoPlayer from '../../../reusable/molecules/VideoPlayer';

//This section creates columns of content and align the columns in a row (horizontally)

interface Props {
    section: ContentSubSection;
    className?: any;
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'inline-block',
        marginBottom: '4em'
    },
    itemHeader: {
        fontWeight: 'bold',
        margin: '0.5em 0 0.5em 0'
    },
    itemText: {
        justifyContent: 'center',
        textAlign: 'center'
    },
    videoContainer: {
        position: 'relative',
        maxHeight: '25em',
        minHeight: '15em',
        maxWidth: '25em',
        margin: 'auto'
    },
    video: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
    },
    description: {
        marginBottom: '1em',
        maxHeight: '0px',
        overflowY: 'visible',
        textAlign: 'center',
        fontSize: 16,
        '& > ul': {
            paddingLeft: '1.25em'
        }
    }
}));

const HorizontalTemplateVideoBlock = (props: Props) => {
    const section = props.section;
    const links = props.section.links;
    const classes = useStyles();
    const embedLink = section.videoUrl.replace('/#/videos/', '/embed?id=');

    return (
        <Grid container item xs direction="row" justify="center">
            <Typography variant="h4" className={classes.itemHeader}>
                {section.header}
            </Typography>
            <Grid container item xs={12} justify="center" className={classes.root}>
                {section.videoUrl && (
                    <div className={classes.videoContainer}>
                        <Grid
                            container
                            item
                            xs={12}
                            className={classes.videoContainer}
                            justify="center"
                        >
                            <VideoPlayer embedLink={embedLink} />
                        </Grid>
                    </div>
                )}
                <Grid container item xs={12} className={section.image ? classes.itemText : ''}>
                    <Box>
                        <Typography
                            variant="h5"
                            component="p"
                            className={classes.description}
                            dangerouslySetInnerHTML={{ __html: sanitizeHtml(section.description) }}
                        ></Typography>
                        <ContentLink link={links ? links[0] : null} />
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default HorizontalTemplateVideoBlock;
