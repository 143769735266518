import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class DocumentsColumns {
    static getColumns = (culture: string, isArchived: boolean = false) => {
        return [
            createColumn('sortIndex', {
                searchable: false,
                sortable: false,
                filterable: false,
                visible: false,
                dataType: ColumnDataType.Numeric
            }),
            createColumn('documentId', {
                isKey: true,
                searchable: false,
                sortable: false,
                filterable: false,
                visible: false
            }),
            createColumn('documentName', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'documentName'),
                visible: true,
                sortable: true,
                filterable: true,
                searchable: true
            }),
            createColumn('approver', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'approver'),
                visible: true,
                sortable: true,
                filterable: true,
                searchable: true
            }),
            createColumn('approvalDateString', {
                dataType: ColumnDataType.Date,
                label: TranslationService.getTranslatedText(culture, 'approvalDate'),
                visible: true,
                searchable: true,
                sortable: true
            }),
            createColumn('effectiveDateString', {
                dataType: ColumnDataType.Date,
                label: TranslationService.getTranslatedText(culture, 'effectiveDate'),
                visible: true,
                searchable: true,
                sortable: true
            }),
            createColumn('expirationDateString', {
                dataType: ColumnDataType.Date,
                label: TranslationService.getTranslatedText(culture, 'expirationDate'),
                visible: false,
                searchable: true,
                sortable: true
            }),
            createColumn('actions', {
                label: TranslationService.getTranslatedText(culture, 'contractActions'),
                visible: true
            }),
            createColumn('needsAcknowledgement', {
                dataType: ColumnDataType.Boolean,
                visible: false
            }),
            createColumn('pdfUrl', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('billTo', {
                dataType: ColumnDataType.String,
                visible: false
            })
        ];
    };
}
