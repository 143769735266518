import React, { useState, useEffect } from 'react';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import StandardTable from '../../../reusable/molecules/StandardTable';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Trans } from 'react-i18next';
import { boldWeight } from '../../../../themes/globalConstants';
import { UnrecognizedProduct } from '../../../../store/reducers/manage-products';
import UnrecognizedProductsColumns from './UnrecognizedProductsColumns';
import UnrecognizedProductsRow from './UnrecognizedProductsRow';

interface Props {
    products: UnrecognizedProduct[];
    tab?: string;
}

const useStyles = makeStyles((theme) => ({
    boldText: {
        fontWeight: boldWeight
    },
    messageContainer: {
        width: '50%',
        padding: '12px 16px'
    },
    topBuffer: {
        position: 'relative',
        height: '0px',
        paddingTop: '20px',
        paddingLeft: '30px'
    }
}));

export default function UnrecognizedProductsTab({ products, tab }: Props) {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const [columns, setColumns] = useState<any>([]);

    useEffect(() => {
        setColumns(UnrecognizedProductsColumns.getColumns(cultureCode));
    }, [cultureCode]);

    const UnrecognizedProductsLine = ({ row }: { row: UnrecognizedProduct }) => {
        if (row) {
            return (
                <UnrecognizedProductsRow
                    key={row.customerProductId}
                    line={row as UnrecognizedProduct}
                />
            );
        } else {
            return null;
        }
    };

    return (
        <>
            {tab === 'Unrecognized' && (
                <div className={classes.topBuffer}>
                    <Grid
                        container
                        justify="flex-start"
                        alignItems="flex-start"
                        className={classes.messageContainer}
                    >
                        <Grid>
                            <Typography className={classes.boldText}>
                                <Trans i18nKey="productsNotRecognized">
                                    These products were included on a Plan It file but are not
                                    recognized by Ball.
                                </Trans>
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            )}
            <StandardTable
                gridName={'Manage Products' + tab}
                columns={columns}
                lines={products}
                rowComponent={UnrecognizedProductsLine}
                columnWidths={[33, 33, 33]}
            />
        </>
    );
}
