import { Grid, makeStyles, FormHelperText } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
    medium,
    ltBlueGrey_34,
    xl,
    boldWeight,
    red,
    warningLabel
} from '../../../../themes/globalConstants';
import { ErrorMessage, useFormContext } from 'react-hook-form';
import TextInput from '../../../reusable/molecules/TextInput';
import RadioInput from '../../../reusable/molecules/RadioInput';
import SelectInput from '../../../reusable/molecules/SelectInput';
import FormSection from '../../../reusable/molecules/FormSection';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { setFormSectionStatus } from '../../../../store/root-actions';
import { useDispatch } from 'react-redux';
import { GRAPHIC_INFORMATION_STATUS_UPDATE } from '../../../../store/actions/action-types';
import { EmeaGraphics } from '../../../../store/reducers/graphic-intake';
import { emeaBallSpecialFinishes } from '../../../../static-data/dropdown-lists';

interface Props {
    isEditDraft: boolean;
    graphicData?: EmeaGraphics;
    cantSaveDraft?: boolean;
}

const useStyles = makeStyles((theme) => ({
    subsectionHeader: {
        fontSize: xl,
        fontWeight: boldWeight,
        color: theme.palette.info.dark,
        marginBottom: '2em'
    },
    label: {
        color: theme.palette.info.dark,
        fontSize: medium,
        marginBottom: '0.5em'
    },
    radioGroup: {
        marginTop: '1.5em',
        paddingLeft: '0.5em'
    },
    divider: {
        height: 5,
        backgroundColor: ltBlueGrey_34,
        margin: '1.5em 0'
    },
    helperText: {
        '& .MuiFormHelperText-root': {
            marginLeft: 0
        }
    },
    warningContainer: {
        display: 'flex',
        backgroundColor: warningLabel,
        margin: '1em 1.5em 1em 0.5em'
    },
    warningIconCtn: {
        textAlign: 'center'
    },
    warningIcon: {
        fill: red
    },
    warningLabel: {
        fontSize: medium,
        fontWeight: boldWeight
    },
    capitalize: {
        textTransform: 'capitalize'
    },
    errorText: {
        color: red,
        paddingBottom: 0
    }
}));

const DesignInfo = ({ isEditDraft, graphicData, cantSaveDraft }: Props) => {
    const classes = useStyles();
    const [draftLoaded, setDraftLoaded] = useState<boolean>(false);
    const graphicIntake = useTypedSelector((state) => state.graphicIntake);
    const { control, errors, formState, watch, setValue, setError, getValues } = useFormContext();
    const { isValid } = formState;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const isRequired = <Trans i18nKey="required">Required</Trans>;

    const existingGraphic = watch('requestType') === 'Existing' || watch('requestType') === 'Promo';

    const allowOnlyNumbers = (count: string) => {
        count = count.replace(/[^0-9]/g, '');
        setValue('barcode', count);
    };

    useEffect(() => {
        if (graphicIntake && isValid !== graphicIntake.graphicsInformationValid) {
            dispatch(setFormSectionStatus(isValid, GRAPHIC_INFORMATION_STATUS_UPDATE));
        }
    }, [dispatch, isValid]);

    useEffect(() => {
        if (cantSaveDraft) {
            setError('designName', { type: 'required' });
        }
    }, [cantSaveDraft]);

    useEffect(() => {
        const graphicIsValid = graphicData?.requestName !== '';

        if (isEditDraft && graphicData && graphicIsValid) {
            setValue('requestType', graphicData.requestType);
            setValue('designPoNo', graphicData.designPoNo);
            setValue('existing', graphicData.existing);
            setValue('designName', graphicData.designName);
            setValue('newCustRefNum', graphicData.newCustRefNum);
            setValue('oldCustRefNum', graphicData.oldCustRefNum);
            setValue('barcode', graphicData.barcode);
            setValue('barcodeBarsColor', graphicData.barcodeBarsColor);
            setValue('barcodeInfillColor', graphicData.barcodeInfillColor);
            setValue('specialFinish', graphicData.specialFinish);
            setDraftLoaded(true);
        }
    }, [isEditDraft, graphicData, setValue]);

    return (
        <FormSection
            testId={'design-information-section'}
            sectionHeader={<Trans i18nKey="designSubInfo">Design Information</Trans>}
        >
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                    <RadioInput
                        styles={classes.radioGroup}
                        label={<Trans i18nKey="requestType">Type of Request</Trans>}
                        name="requestType"
                        testId="requestType"
                        control={control}
                        rules={{ required: isRequired }}
                        radioControls={[
                            {
                                value: 'New',
                                label: (
                                    <Trans i18nKey="requestTypeNew">New Design From Scratch</Trans>
                                )
                            },
                            {
                                value: 'Existing',
                                label: (
                                    <Trans i18nKey="requestTypeExisting">
                                        New Version on existing Design
                                    </Trans>
                                )
                            },
                            {
                                value: 'Promo',
                                label: (
                                    <Trans i18nKey="requestTypePromo">
                                        Promo Design – based on (but not replacing) existing Design
                                    </Trans>
                                )
                            }
                        ]}
                        error={errors.requestType}
                        errors={errors}
                        defaultValue="New"
                    />
                    {!errors.requestType && (
                        <FormHelperText>
                            <Trans i18nKey="requestTypeHelper">
                                Select what you would like to do{' '}
                            </Trans>
                        </FormHelperText>
                    )}
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                    <TextInput
                        type="text"
                        label={<Trans i18nKey="designPoNo">Design Development PO no.</Trans>}
                        name="designPoNo"
                        testId="designPoNo"
                        control={control}
                        rules={''}
                        error={errors.designPoNo}
                        errors={errors}
                        styles={classes.helperText}
                        helperText={
                            <Trans i18nKey="designPoNoHelper">
                                Customer purchase order number{' '}
                            </Trans>
                        }
                    />
                </Grid>
                {(existingGraphic || (isEditDraft && !draftLoaded)) && (
                    <Grid item xs={6}>
                        <TextInput
                            type="text"
                            label={
                                <Trans i18nKey="previousBallDesignNum">
                                    Previous Ball Design no.
                                </Trans>
                            }
                            name="existing"
                            testId="existing"
                            control={control}
                            rules={{ required: isRequired }}
                            error={errors.existing}
                            errors={errors}
                            styles={classes.helperText}
                            helperText={
                                <Trans i18nKey="existingHelper">Ball's previous design no. </Trans>
                            }
                        />
                        <FormHelperText className={classes.errorText}>
                            {errors.existing && errors.existing.type === 'required' && (
                                <ErrorMessage
                                    errors={errors}
                                    name={'existing'}
                                    message={isRequired}
                                />
                            )}
                        </FormHelperText>
                    </Grid>
                )}
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                    <TextInput
                        type="text"
                        label={<Trans i18nKey="designName">Design Name</Trans>}
                        name="designName"
                        testId="designName"
                        control={control}
                        rules={{ required: isRequired }}
                        error={errors.designName}
                        errors={errors}
                        styles={classes.helperText}
                        helperText={
                            <Trans i18nKey="designNameHelper">
                                Name this submission for future reference
                            </Trans>
                        }
                    />
                    <FormHelperText className={classes.errorText}>
                        {errors.designName && (
                            <ErrorMessage
                                errors={errors}
                                name={'designName'}
                                message={isRequired}
                            />
                        )}
                    </FormHelperText>
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                    <TextInput
                        type="text"
                        label={<Trans i18nKey="newCustRefNum">Customer Reference no.</Trans>}
                        name="newCustRefNum"
                        testId="newCustRefNum"
                        control={control}
                        rules={''}
                        error={errors.newCustRefNum}
                        errors={errors}
                        styles={classes.helperText}
                        helperText={
                            <Trans i18nKey="newCustRefNumHelper">New customer reference no. </Trans>
                        }
                        defaultValue=""
                    />
                </Grid>
                {(existingGraphic || (isEditDraft && !draftLoaded)) && (
                    <Grid item xs={6}>
                        <TextInput
                            type="text"
                            label={
                                <Trans i18nKey="oldCustRefNum">
                                    Previous Customer Reference no.
                                </Trans>
                            }
                            name="oldCustRefNum"
                            testId="oldCustRefNum"
                            control={control}
                            rules={{ required: isRequired }}
                            error={errors.oldCustRefNum}
                            errors={errors}
                            styles={classes.helperText}
                            defaultValue={getValues('oldCustRefNum')}
                            helperText={
                                <Trans i18nKey="oldCustRefNumHelper">
                                    Previous customer reference no.{' '}
                                </Trans>
                            }
                        />
                        <FormHelperText className={classes.errorText}>
                            {errors.oldCustRefNum && errors.oldCustRefNum.type === 'required' && (
                                <ErrorMessage
                                    errors={errors}
                                    name={'oldCustRefNum'}
                                    message={isRequired}
                                />
                            )}
                        </FormHelperText>
                    </Grid>
                )}
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                    <TextInput
                        type="text"
                        label={<Trans i18nKey="barcode">Barcode</Trans>}
                        name="barcode"
                        testId="barcode"
                        control={control}
                        rules={{ required: isRequired, pattern: /^[0-9\s]*$/ }}
                        error={errors.barcode}
                        errors={errors}
                        onBlur={(e) => allowOnlyNumbers(e[0].target.value)}
                        onChange={(e) => allowOnlyNumbers(e[0].target.value)}
                        styles={classes.helperText}
                        helperText={
                            <Trans i18nKey="barcodeHelper">
                                Enter the 8 or 13 digit EAN code or the 12 digit UPC code{' '}
                            </Trans>
                        }
                    />
                    <FormHelperText className={classes.errorText}>
                        {errors.barcode && errors.barcode.type === 'required' && (
                            <ErrorMessage errors={errors} name={'barcode'} message={isRequired} />
                        )}
                        {errors.barcode && errors.barcode.type === 'pattern' && (
                            <ErrorMessage
                                errors={errors}
                                name={'barcode'}
                                message={t('numericPatternError', 'Please enter a numeric value')}
                            />
                        )}
                    </FormHelperText>
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                    <TextInput
                        type="text"
                        label={<Trans i18nKey="barcodeBarsColor">Barcode Colour - Bars</Trans>}
                        name="barcodeBarsColor"
                        testId="barcodeBarsColor"
                        control={control}
                        rules={{ required: isRequired }}
                        error={errors.barcodeBarsColor}
                        errors={errors}
                        styles={classes.helperText}
                        helperText={
                            <Trans i18nKey="barcodeBarsColorHelper">
                                Enter the colour shade of the bars{' '}
                            </Trans>
                        }
                    />
                    <FormHelperText className={classes.errorText}>
                        {errors.barcodeBarsColor && errors.barcodeBarsColor.type === 'required' && (
                            <ErrorMessage
                                errors={errors}
                                name={'barcodeBarsColor'}
                                message={isRequired}
                            />
                        )}
                    </FormHelperText>
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        type="text"
                        label={<Trans i18nKey="barcodeInfillColor">Barcode Colour - Infill</Trans>}
                        name="barcodeInfillColor"
                        testId="barcodeInfillColor"
                        control={control}
                        rules={{ required: isRequired }}
                        error={errors.barcodeInfillColor}
                        errors={errors}
                        styles={classes.helperText}
                        helperText={
                            <Trans i18nKey="barcodeInfillColorHelper">
                                Enter the colour shade for the barcode background{' '}
                            </Trans>
                        }
                    />
                    <FormHelperText className={classes.errorText}>
                        {errors.barcodeInfillColor &&
                            errors.barcodeInfillColor.type === 'required' && (
                                <ErrorMessage
                                    errors={errors}
                                    name={'barcodeInfillColor'}
                                    message={isRequired}
                                />
                            )}
                    </FormHelperText>
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                    <SelectInput
                        label={<Trans i18nKey="specialFinish">Ball Special Finishes</Trans>}
                        name="specialFinish"
                        testId="specialFinish"
                        control={control}
                        rules={{ required: isRequired }}
                        error={errors.specialFinish}
                        errors={errors}
                        selectObj={emeaBallSpecialFinishes}
                        defaultValue="No Special finish (Design)"
                        helperText={
                            <Trans i18nKey="specialFinishHelper">
                                Select which of Ball’s Value added products you wish to add to your
                                layout. Note: An additional upcharge might apply.{' '}
                            </Trans>
                        }
                    />
                </Grid>
            </Grid>
        </FormSection>
    );
};

export default DesignInfo;
