import { ColumnDataType, createColumn } from 'tubular-common';
import { FlagTypes, GetFeatureFlag } from '../../../../utility/helpers/feature-flag';
import TranslationService from '../../../../utility/services/translation-service';

export default class ShipmentColumns {
    static getShipmentColumns = (culture: string) => {
        const cokeFeatureFlag = GetFeatureFlag(FlagTypes.Coke);

        return [
            createColumn('sequence', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'loadSeq')
            }),
            createColumn('customerProductId', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'customerProductId')
            }),
            createColumn('displayId', {
                dataType: ColumnDataType.String,
                label: `${TranslationService.getTranslatedText(culture, 'ball')} ${TranslationService.getTranslatedText(culture, 'productGraphicId')}`
            }),
            createColumn('displayName', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'descriptionCharacteristics')
            }),
            createColumn('description', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('palletQuantity', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'quantity')
            }),
            createColumn('releaseNumber', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('fillerLine', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('referenceNumber', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('deliveryInstructions', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('purchaseOrderNumber', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'customerPoNumber'),
                visible: !!cokeFeatureFlag
            }),
            createColumn('loadId', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('editLoadId', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('quantityPerPallet', {
                dataType: ColumnDataType.Numeric,
                visible: false
            })
        ];
    };
}
