import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import {
    boldWeight,
    blackWeight,
    ballBlue,
    dateOptionInvalidGray,
    xxl,
    medium,
    regularWeight
} from '../../../../themes/globalConstants';
import {
    ProductionOrderSummary,
    ClosedProductionOrderRow,
    MakeItSummaryState
} from '../../../../store/reducers/make-it-summary';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import MakeItClosedRow from './MakeItClosedRow';
import { Order_Status } from '../../../../utility/services/orders-service';
import {
    getFormattedDateStringForApplication,
    getCurrentDate
} from '../../../../utility/helpers/date-helpers';
import {
    formatStatus,
    orderStatusCodeFormatter,
    simpleFormatOrderQuantity
} from '../../../../utility/helpers/order-helpers';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import MakeItClosedColumns from './MakeItClosedColumns';
import TranslationService from '../../../../utility/services/translation-service';
import { useTranslation, Trans } from 'react-i18next';
import { usePrevious } from '../../../../utility/helpers/react-util';
import {
    getAddress,
    getName,
    getProdOrderType,
    setClosedColumn
} from '../../../../utility/helpers/summary-helpers';
import moment from 'moment';
import SummarySearchBar from '../../../reusable/atoms/SummarySearchBar';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { CSVLink } from 'react-csv';
import ReactPdf from '@react-pdf/renderer';
import StandardTable from '../../../reusable/molecules/StandardTable';

interface Props {
    showCustomerPOCol: boolean;
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: '1em'
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '1em'
    },
    title: {
        color: theme.palette.secondary.main,
        fontSize: xxl,
        fontWeight: boldWeight,
        paddingLeft: '0.625em'
    },
    tableWrapper: {
        '& .MuiTable-root': {
            tableLayout: 'fixed'
        },
        '& .MuiToolbar-root': {
            padding: 0,
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                },
                '&:first-of-type ': {
                    paddingLeft: 0
                }
            },
            '& > h2': {
                fontSize: '1.5em',
                fontWeight: boldWeight,
                color: theme.palette.secondary.main,
                paddingLeft: '0.625em'
            },
            '& .MuiInputBase-formControl': {
                border: `1px solid ${dateOptionInvalidGray}`,
                borderRadius: '.125em',
                '&:before': {
                    display: 'none'
                },
                '&:after': {
                    display: 'none'
                },
                '& svg': {
                    height: '.875em',
                    width: '.875em',
                    fill: ballBlue
                },
                '& .MuiIconButton-root': {
                    '&:hover': {
                        backgroundColor: 'transparent !important'
                    }
                },
                '& .MuiInput-input': {
                    paddingLeft: '.25em'
                }
            }
        },
        '& .MuiTableSortLabel-root': {
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em',
            width: '99%',
            justifyContent: 'space-between',
            '& .MuiSvgIcon-root': {
                fontSize: 14,
                opacity: '1'
            }
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                '&:nth-child(1)': {
                    width: '30%'
                },
                '&:nth-child(2)': {
                    width: '13%'
                },
                '&:nth-child(3)': {
                    width: '13%'
                },
                '&:nth-child(4)': {
                    width: '9%'
                },
                '&:nth-child(5)': {
                    width: '9%'
                },
                '&:nth-child(6)': {
                    width: '12%'
                },
                '&:nth-child(7)': {
                    width: '13%'
                },
                '&:nth-child(8)': {
                    width: '10%'
                }
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    },
    icon: {
        color: ballBlue
    },
    downloadWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

const pdfStyles = ReactPdf.StyleSheet.create({
    link: {
        fontSize: medium,
        fontWeight: regularWeight
    }
});

const MakeItClosedTab = ({ showCustomerPOCol }: Props) => {
    const classes = useStyles();
    const { shipToAccounts, selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { openOrders, openOrdersLoaded, closedOrders, closedOrdersLoaded } =
        useTypedSelector<MakeItSummaryState>((state) => state.makeItSummary);

    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [formattedClosedOrders, setFormattedClosedOrders] = useState(
        {} as ClosedProductionOrderRow[]
    );
    const [columns, setColumns] = useState<any>([]);
    const [filteredOrders, setFilteredOrders] = useState<ClosedProductionOrderRow[]>([]);

    const prevSelectedShipToId = usePrevious(selectedAccountId);

    const { t } = useTranslation();

    useEffect(() => {
        if (!formattedClosedOrders.length && closedOrdersLoaded && openOrdersLoaded) {
            formatClosedOrders(closedOrders);
        }
    }, [closedOrders, closedOrdersLoaded, openOrdersLoaded]);

    useEffect(() => {
        if (prevSelectedShipToId && prevSelectedShipToId !== selectedAccountId) {
            setFormattedClosedOrders([]);
        }
    }, [prevSelectedShipToId, selectedAccountId]);

    useEffect(() => {
        setColumns(MakeItClosedColumns.getMakeItClosedColumns(cultureCode, showCustomerPOCol));
    }, [cultureCode, showCustomerPOCol]);

    const formatClosedOrders = (unformattedOrders: ProductionOrderSummary[]) => {
        let formattedOrders: ClosedProductionOrderRow[] = [];
        unformattedOrders = setClosedDateIndex(unformattedOrders);
        unformattedOrders.sort(compareItems);
        unformattedOrders.forEach((order) => {
            let newOrder: ClosedProductionOrderRow;
            let orderStatusCode = orderStatusCodeFormatter(order.status) ?? '';
            newOrder = {
                ...order,
                productionOrderNumber: order.productionOrderNumber
                    ? order.productionOrderNumber.toString()
                    : '',
                ballPONumber: order.productionOrderNumber,
                shipToName: getName(order.productionOrderId, shipToAccounts, closedOrders),
                shipToAddress: getAddress(order.productionOrderId, shipToAccounts, closedOrders),
                closed: setClosedColumn(order),
                closedString: setClosedColumn(order),
                status: orderStatusCode
                    ? t(orderStatusCode, formatStatus(order.status))
                    : formatStatus(order.status),
                submittedDateString: getFormattedDateStringForApplication(
                    order.submittedDate?.toString()
                ),
                firstRequestedDateString: getFormattedDateStringForApplication(
                    order.firstRequestedDate?.toString()
                ),
                orderQuantityString: simpleFormatOrderQuantity(
                    cultureCode,
                    order.totalOrderQuantity,
                    order.totalOrderSKUs
                ),
                orderQuantityEachesString:
                    order.totalOrderQuantityEaches?.toLocaleString() + ' ea.',
                newProductionOrderNumber: getNewProductionOrderNumber(order),
                newProductionOrderId: getNewProductionOrderId(order),
                shipToProdOrderType: getProdOrderType(
                    order.productionOrderId,
                    shipToAccounts,
                    closedOrders
                )
            };
            formattedOrders.push(newOrder);
        });
        setFormattedClosedOrders(formattedOrders);
    };
    const setClosedDateIndex = (orders: ProductionOrderSummary[]) => {
        return orders
            .sort((orderA, orderB) => {
                let orderADate = setClosedColumn(orderA);
                let orderBDate = setClosedColumn(orderB);
                if (orderADate && orderBDate) {
                    orderADate = moment(orderADate);
                    orderBDate = moment(orderBDate);
                    return orderADate.isBefore(orderBDate)
                        ? 1
                        : orderADate.isSame(orderBDate)
                        ? 0
                        : -1;
                } else {
                    return orderADate ? -1 : orderBDate ? 1 : 0;
                }
            })
            .map((order, index) => {
                return { ...order, closedSortIndex: index };
            });
    };

    const compareItems = (
        orderA: ProductionOrderSummary,
        orderB: ProductionOrderSummary
    ): number => {
        const orderADate = moment(orderA.submittedDate);
        const orderBDate = moment(orderB.submittedDate);
        return orderADate.isBefore(orderBDate) ? 1 : orderADate.isSame(orderBDate) ? 0 : -1;
    };

    const getNewProductionOrderNumber = (order?: ProductionOrderSummary) => {
        if (order && order.productionOrderNumber && order.status === Order_Status.Cancelled) {
            let newOrder = openOrders.find(
                (o) =>
                    o.originalProductionOrderNumber ===
                    (order.productionOrderNumber ? +order.productionOrderNumber : null)
            );
            return newOrder?.productionOrderNumber.toString();
        }
        return;
    };

    const getNewProductionOrderId = (order?: ProductionOrderSummary) => {
        if (order && order.productionOrderNumber && order.status === Order_Status.Cancelled) {
            let newOrder = openOrders.find(
                (o) =>
                    o.originalProductionOrderNumber ===
                    (order.productionOrderNumber ? +order.productionOrderNumber : null)
            );
            return newOrder?.productionOrderId;
        }
        return;
    };

    const ClosedProductionOrderRow = ({ row }: { row: Partial<ClosedProductionOrderRow> }) => {
        if (row) {
            return (
                <MakeItClosedRow
                    order={row as ClosedProductionOrderRow}
                    key={row.productionOrderId}
                    showCustomerPOCol={showCustomerPOCol}
                />
            );
        } else {
            return null;
        }
    };

    const handleRowFilter = (filteredRows) => {
        setFilteredOrders(filteredRows);
    };

    interface CSVRow {
        ship_to_detail?: string;
        customer_po?: string;
        production_order_number?: string;
        submitted?: string;
        total_order_pallets?: number;
        total_order_eaches?: number;
        total_order_skus: number;
        status?: string;
        closed?: string;
    }

    const csvHeaders = [
        { label: t('shipTo', 'Ship to').toUpperCase(), key: 'ship_to_detail' },
        { label: t('customerPoNumber', 'CUSTOMER PO#').toUpperCase(), key: 'customer_po' },
        {
            label: t('ballProdOrderNumber', 'BALL PRODUCTION ORDER NUMBER').toUpperCase(),
            key: 'production_order_number'
        },
        { label: t('submitted', 'SUBMITTED').toUpperCase(), key: 'submitted' },
        {
            label: t('totalOrderQuantityPL', 'Total Order Quantity (PL)').toUpperCase(),
            key: 'total_order_pallets'
        },
        {
            label: t('totalOrderQuantityEA', 'Total Order Quantity (ea.)').toUpperCase(),
            key: 'total_order_eaches'
        },
        {
            label: t('totalOrderQuantitySKUS', 'Total Order Quantity (SKUs)').toUpperCase(),
            key: 'total_order_skus'
        },
        { label: t('status', 'STATUS').toUpperCase(), key: 'status' },
        { label: t('closed', 'CLOSED').toUpperCase(), key: 'closed' }
    ];

    const csvArray = () => {
        let rows: any[] = [];
        if (!Array.isArray(filteredOrders)) return rows;
        filteredOrders.map((order) => {
            const row: CSVRow = {
                ship_to_detail: `${order.shipToName}, ${order.shipToAddress}, #${order.shipToId}`,
                customer_po: order.customerProductionOrderId,
                production_order_number: order.productionOrderNumber || t('pending', 'Pending'),
                submitted: order.submittedDateString,
                total_order_pallets: order.totalOrderQuantity,
                total_order_eaches: order.totalOrderQuantityEaches,
                total_order_skus: order.totalOrderSKUs,
                status: order.status,
                closed: order.closedString
            };

            rows.push(row);
            return null;
        });
        return rows;
    };

    const csvContents = csvArray();

    return (
        <Paper elevation={2} className={classes.paper}>
            <div className={classes.titleContainer}>
                <Typography className={classes.title}>
                    {TranslationService.getTranslatedText(cultureCode, 'closedProductionOrders')}
                </Typography>
                <Grid className={classes.downloadWrapper}>
                    <CSVLink
                        className={
                            'MuiTypography-root MuiLink-root MuiLink-underlineAlways MuiTypography-colorPrimary'
                        }
                        style={pdfStyles.link}
                        underline="always"
                        data-testid="download-btn"
                        headers={csvHeaders}
                        data={csvContents}
                        filename={`Make it Summary - Closed Production Order -${getCurrentDate()}.csv`}
                    >
                        <Trans i18nKey="downloadCSV">Download .CSV</Trans>
                    </CSVLink>
                    <GetAppOutlinedIcon fontSize="small" className={classes.icon} />
                </Grid>
            </div>
            <Grid className={classes.tableWrapper}>
                <SummarySearchBar
                    rows={formattedClosedOrders}
                    columns={columns}
                    onRowFilter={handleRowFilter}
                />
                {formattedClosedOrders && (
                    <StandardTable
                        gridName="Closed Orders"
                        columns={columns}
                        lines={filteredOrders}
                        rowComponent={ClosedProductionOrderRow}
                        searchText={false}
                    />
                )}
            </Grid>
        </Paper>
    );
};

export default MakeItClosedTab;
