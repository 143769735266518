import ReCAPTCHA from 'react-google-recaptcha';
import React, { createRef, useEffect } from 'react';

interface Props {
    value: string;
    onVerifyCaptcha: (token) => void;
}

export const Recaptcha = ({ onVerifyCaptcha, value }: Props) => {
    const recaptchaRef = createRef<ReCAPTCHA>();

    useEffect(() => {
        if (!value && recaptchaRef.current.getValue()) {
            recaptchaRef.current.reset();
        }
    }, [recaptchaRef, value]);

    return (
        <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_CAPTCHA_KEY}
            onChange={onVerifyCaptcha}
        />
    );
};
