import React, { useEffect, useState } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import {
    ballBlue,
    black,
    blackWeight,
    boldWeight,
    dateOptionInvalidGray,
    small,
    xl
} from '../../../../themes/globalConstants';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { setValues, ToggleAll } from '../../../../utility/helpers/order-helpers';
import { paginationOptions } from '../../../../utility/helpers/grid-helpers';
import { PlanningAllocation } from '../models/PlanningAllocation';
import {
    TotalNDayRanges
} from '../../../../store/reducers/product-planning';
import { useDispatch } from 'react-redux';
import {
    updateCollapseAll
} from '../../../../store/actions/product-planning-atm';
import ProductPlanningATMSupplyColumns from './ProductPlanningATMSupplyColumns';
import ProductPlanningATMSupplyRow from './ProductPlanningATMSupplyRow';
import { selectStateByName } from '../../../../store/selectors';
import {
    unitTypesWithEaches,
    viewTypesWithCampaign,
    weekFilter
} from '../../../../utility/helpers/filter-helpers';
import { StateName } from '../../../../utility/helpers/state-helpers';
import ProductPlanningToolbar from './ProductPlanningToolbar';

interface Props {
    allocations: PlanningAllocation[];
    onViewFiltersClicked: (viewTypes: string[]) => void;
    onRangeFiltersClicked: (rangeTypes: number) => void;
    onUnitsFiltersClicked: (unitTypes: string[]) => void;
    selectedRange: number;
    stateName: StateName;
    currentViewTypes: string[];
    currentUnit: string;
}

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        width: '100%',
        marginBottom: '4em',
        '& .MuiToolbar-root': {
            padding: '0 0.125em',
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                }
            },
            '& ::-ms-clear': {
                display: 'none'
            },
            '& .MuiFormControl-root': {
                width: '14em !important'
            },
            '& .MuiInputBase-formControl': {
                border: `1px solid ${dateOptionInvalidGray}`,
                borderRadius: '.125em',
                '&:before': {
                    display: 'none'
                },
                '&:after': {
                    display: 'none'
                },
                '& svg': {
                    height: '.875em',
                    width: '.875em',
                    fill: ballBlue
                },
                '& .MuiIconButton-root': {
                    '&:hover': {
                        backgroundColor: 'transparent !important'
                    }
                },
                '& .MuiInput-input': {
                    paddingLeft: '.25em'
                }
            }
        },
        '& .MuiTableSortLabel-root': {
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: small,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            padding: '0 5px',
            '& .MuiSvgIcon-root': {
                fontSize: 14,
                opacity: '1',
                marginRight: '-0.375em'
            }
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none'
            }
        },
        '& .MuiTableCell-head': {
            '&:nth-child(n+2)': {
                textAlign: 'right',
                width: '7%',
                padding: '0 5px',
                lineHeight: '1rem'
            }
        },
        '& .MuiTablePagination-root': {
            '& .MuiTablePagination-caption': {
                marginLeft: '1em',
                fontWeight: boldWeight,
                color: black
            }
        }
    },
    titleStyle: {
        display: 'flex',
        padding: 0,
        margin: '1em 0 0 2em',
        '& h2': {
            fontSize: xl,
            fontWeight: blackWeight,
            color: theme.palette.info.dark
        }
    },
    subheaderStyle: {
        display: 'flex',
        padding: 0,
        margin: '0 0 1em 2em',
        '& h2': {
            color: theme.palette.info.dark
        }
    },
    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '0.5em',
        marginTop: '0.5em'
    }
}));

export default function ProductPlanningATMSupplyGrid({
    allocations,
    onViewFiltersClicked,
    onRangeFiltersClicked,
    onUnitsFiltersClicked,
    selectedRange,
    stateName,
    currentViewTypes,
    currentUnit
}: Props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [expandAll, setExpandAll] = useState<ToggleAll>({ toggle: false });
    const [columns, setColumns] = useState<any>([]);
    const [allocationsForGrid, setAllocationsForGrid] = useState<PlanningAllocation[]>();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { range } = useTypedSelector((state) => selectStateByName(state, stateName));

    const handleExpandAll = () => {
        setExpandAll({ toggle: true });
        dispatch(updateCollapseAll(true, stateName));
    };

    const handleCollapseAll = () => {
        setExpandAll({ toggle: false });
        dispatch(updateCollapseAll(false, stateName));
    };

    useEffect(() => {
        const longRangeStart = 9;
        const longRangeEnd = 17;
        const allocationsForTable: PlanningAllocation[] = [];
        allocations.forEach((allocation) => {
            const startCount = range === TotalNDayRanges.nineWeeks ? 0 : longRangeStart;
            const endCount = range === TotalNDayRanges.nineWeeks ? longRangeStart : longRangeEnd;
            allocation.weekAllocationQuantity?.forEach((quantity, index) => {
                if (index >= startCount && index < endCount) {
                    let weekTotal = 0;
                    allocation.products?.forEach((product) => {
                        weekTotal += product.productionBalance[index] - product.shipments[index];
                    });
                    const total = quantity ? weekTotal : -1;
                    allocation.weekTotal = total;
                    setValues(allocation, total, index);
                }
            });
            allocationsForTable.push(allocation);
        });
        setAllocationsForGrid(allocationsForTable);
    }, [allocations]);

    useEffect(() => {
        setColumns(
            ProductPlanningATMSupplyColumns.getProductPlanningATMSupplyColumns(
                cultureCode,
                selectedRange
            )
        );
    }, [cultureCode]);

    const CustomToolbarOptions = new ToolbarOptions({
        customItems: (
            <ProductPlanningToolbar
                stateName={stateName}
                onExpandAll={handleExpandAll}
                onCollapseAll={handleCollapseAll}
                onViewFiltersClicked={onViewFiltersClicked}
                onRangeFiltersClicked={onRangeFiltersClicked}
                onUnitsFiltersClicked={onUnitsFiltersClicked}
                viewFilterType={viewTypesWithCampaign}
                weekFilterType={weekFilter}
                unitType={unitTypesWithEaches}
                currentViewTypes={currentViewTypes}
                currentUnit={currentUnit}
            />
        ),
        advancePagination: false,
        exportButton: false,
        printButton: false,
        rowsPerPageOptions: paginationOptions.rowsPerPage,
        searchText: true
    });

    const CustomOrderRow = ({ row }: { row: PlanningAllocation }) => {
        return (
            <ProductPlanningATMSupplyRow
                allocation={row}
                expand={expandAll}
                stateName={stateName}
            />
        );
    };

    useEffect(() => {
        setColumns(
            ProductPlanningATMSupplyColumns.getProductPlanningATMSupplyColumns(
                cultureCode,
                selectedRange
            )
        );
    }, [cultureCode, selectedRange]);

    return (
        <Grid container className={classes.tableWrapper} data-testid="allocations-grid">
            {allocationsForGrid && (
                <DataGrid
                    columns={columns}
                    dataSource={allocationsForGrid}
                    gridName="ATM Supply Planning Grid"
                    rowComponent={CustomOrderRow}
                    toolbarOptions={CustomToolbarOptions}
                />
            )}
        </Grid>
    );
}
