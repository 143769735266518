import React, { useState, useEffect } from 'react';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { Button, makeStyles, Paper, Grid, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import {
    blackWeight,
    ballGray,
    ltBlueGrey_34,
    containerMaxWidth
} from '../../../themes/globalConstants';
import { useHistory } from 'react-router-dom';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { formatFromDatetime, formattedMTDate } from '../../../utility/helpers/date-helpers';
import moment from 'moment';
import DisableBackButton from '../../reusable/atoms/DisableBackButton';
import { Activity } from '../../../utility/auth/useSecurity';
import InfoSubheader from '../../reusable/molecules/InfoSubheader';
import { InfoSubheaderItem } from '../../../store/reducers/glp';
import { MakeState, ProductionOrder } from '../../../store/reducers/makeit-dashboard';
import CancelProductionOrderConfirmationShareLinks from './components/CancelProductionOrderConfirmationShareLinks';
import CancelProductionOrderConfirmationGrid from './components/CancelProductionOrderConfirmationGrid';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { usePrevious } from '../../../utility/helpers/react-util';
import { ProductType } from '../../../utility/services/orders-service';
import { getTotalQuantityAmounts } from '../../../utility/helpers/order-helpers';
import { formatNumberWithLocale } from '../../../utility/helpers/formatters';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: '3em',
        padding: '2em',
        borderRadius: 4,
        maxWidth: containerMaxWidth
    },
    header: {
        marginTop: '1em',
        marginBottom: '1.5em'
    },
    actionBtn: {
        borderRadius: 'unset'
    },
    title: {
        fontWeight: blackWeight,
        color: ballGray
    },
    subTitle: {
        color: ballGray,
        fontSize: '1em',
        fontWeight: blackWeight,
        marginTop: '.5em'
    },
    divider: {
        height: '0.32em',
        backgroundColor: ltBlueGrey_34,
        width: '100%',
        marginBottom: '1em'
    }
}));

const CancelProductionOrderConfirmation = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [shipToName, setShipToName] = useState<string>('');
    const [displayAddress, setDisplayAddress] = useState<string>('');
    const [subheaderItems, setSubheaderItems] = useState<InfoSubheaderItem[]>();
    const { shipToAccounts, selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { productionOrder } = useTypedSelector<MakeState>((state) => state.makeItDashboard);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const accountInformation =
        shipToAccounts &&
        shipToAccounts.filter((account) => account.accountId === selectedAccountId);
    const dashboardLink = '/dashboard';
    const history = useHistory();
    const prevCultureCode = usePrevious(cultureCode);

    DisableBackButton('/make-it-summary', '/cancel-production-order-confirmation');

    const handleDashboardNavigation = () => {
        history.push(dashboardLink);
    };

    const footerActions = (
        <Button
            type="button"
            color="primary"
            variant="contained"
            data-testid="dashboard-btn"
            className={classes.actionBtn}
            onClick={handleDashboardNavigation}
        >
            <Trans i18nKey="myDashboard">My Dashboard</Trans>
        </Button>
    );

    const getQuantityDisplayString = (type: string): String => {
        const quantityInformation = getTotalQuantityAmounts(type, [productionOrder]);
        return `${formatNumberWithLocale(
            cultureCode,
            quantityInformation.pallets
        )} PL/${formatNumberWithLocale(cultureCode, quantityInformation.skus)} ${
            quantityInformation.skus > 1 ? t('skus', 'SKUs') : t('sku', 'SKU')
        }`;
    };

    const createSubheader = (productionOrder: ProductionOrder) => {
        setSubheaderItems([
            {
                subtitle: t('cancelledDate', 'Cancelled Date'),
                body: formatFromDatetime(productionOrder.updateDate as string)
            },
            {
                subtitle: t('canQuantity', 'Can Quantity'),
                body: getQuantityDisplayString(ProductType.Cans),
                subtext: `${productionOrder.canQuantityEaches?.toLocaleString()} ea.`
            },
            {
                subtitle: t('endQuantity', 'End Quantity'),
                body: getQuantityDisplayString(ProductType.Ends),
                subtext: `${productionOrder.endQuantityEaches?.toLocaleString()} ea.`
            },
            {
                subtitle: t('ballOrderNumber', 'Ball Order #'),
                body: productionOrder.productionOrderNumber
            }
        ] as InfoSubheaderItem[]);
    };

    useEffect(() => {
        if (productionOrder) {
            if (cultureCode !== prevCultureCode || !subheaderItems?.length) {
                createSubheader(productionOrder);
            }
            if (!displayAddress && !shipToName) {
                if (accountInformation && accountInformation[0]) {
                    setShipToName(accountInformation[0].name);
                    if (accountInformation[0].address) {
                        const shipToAddress = accountInformation[0].address;
                        setDisplayAddress(
                            shipToAddress.address1?.trim() +
                                ', ' +
                                shipToAddress.city?.trim() +
                                ', ' +
                                shipToAddress.stateProvince?.trim() +
                                ' ' +
                                shipToAddress.postalCode
                        );
                    }
                }
            }
        }
    }, [accountInformation, cultureCode]);

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('makeIt', 'Make It'),
                description: t(
                    'cancellationReceived',
                    'Thank you. Your cancellation has been received'
                ).toLocaleUpperCase(),
                thinBanner: true,
                displayDropdown: false
            }}
            actionFooter={{
                footerAction: footerActions,
                justify: 'center',
                sticky: true
            }}
            activity={Activity.NewOpenProductionOrders}
        >
            <Paper elevation={2} className={classes.paper}>
                <Grid container spacing={2} alignItems="flex-start">
                    <Grid container alignItems="baseline" className={classes.header}>
                        <Grid container item xs={9} justify="flex-start">
                            <Typography variant="h3" className={classes.title}>
                                {t('customerPoNumber', 'Customer PO #')}{' '}
                                {productionOrder?.customerProductionOrderId
                                    ? productionOrder?.customerProductionOrderId
                                    : productionOrder?.productionOrderNumber}
                            </Typography>
                        </Grid>
                        <Grid container item xs={3} justify="flex-end">
                            {productionOrder && (
                                <CancelProductionOrderConfirmationShareLinks
                                    dateSubmitted={formattedMTDate(
                                        moment(productionOrder.submittedDate),
                                        'MM/DD/YYYY'
                                    )}
                                    productionOrder={productionOrder}
                                    shipToAddress={displayAddress}
                                    shipToName={shipToName}
                                />
                            )}
                        </Grid>
                        <Grid container item justify="flex-start">
                            <Typography variant="h3" className={classes.subTitle}>
                                {t('status', 'Status')}: {t('cancelled', 'Cancelled')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        {subheaderItems && (
                            <InfoSubheader
                                shipToName={shipToName}
                                shipToAddress={displayAddress}
                                subheaderItems={subheaderItems}
                            />
                        )}
                    </Grid>
                </Grid>
                <CancelProductionOrderConfirmationGrid items={productionOrder?.lines} />
            </Paper>
        </ProcessingPageTemplate>
    );
};

export default CancelProductionOrderConfirmation;
