import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Trans, useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid, makeStyles, Typography, TableRow, TableCell } from '@material-ui/core';
import {
    medium,
    lightBlue,
    inactiveTabBlue,
    boldWeight,
    small,
    blackWeight,
    successGreen
} from '../../../../themes/globalConstants';

import { useTypedSelector } from '../../../../store/reducers/reducer';
import { PlanningType, TotalNDayRanges } from '../../../../store/reducers/product-planning';
import { ToggleAll } from '../../../../utility/helpers/order-helpers';
import { AllocationProduct } from '../models/AllocationForTable';
import { PlanningAllocation } from '../models/PlanningAllocation';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { formatNumberWithLocale } from '../../../../utility/helpers/formatters';
import { wrapEachesForTableCell } from '../../../../utility/helpers/shipment-helpers';
import { updateCollapse } from '../../../../store/actions/product-planning-atm';
import { useDispatch } from 'react-redux';
import { usePrevious } from '../../../../utility/helpers/react-util';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import CampaignPill from '../../../reusable/atoms/CampaignPill';
import { selectIsPlanItOnly, selectStateByName } from '../../../../store/selectors';
import { StateName } from '../../../../utility/helpers/state-helpers';

interface Props {
    allocation: PlanningAllocation;
    expand: ToggleAll;
    stateName: StateName;
}

const useStyles = makeStyles((theme) => ({
    expand: {
        transform: 'rotate(-90deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: 'rotate(0)'
    },
    expandContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    standardRow: {
        '& > td:nth-child(n+2)': {
            textAlign: 'right'
        }
    },
    altRow: {
        backgroundColor: lightBlue,
        '& > td:nth-child(n+2)': {
            textAlign: 'right'
        }
    },
    hidden: {
        display: 'none'
    },
    display: {
        display: 'table-row'
    },
    borderlessCell: {
        borderLeft: 'none'
    },
    activeHeaderCell: {
        backgroundColor: inactiveTabBlue
    },
    activeRow: {
        boxShadow:
            '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 4px 3px 0px rgba(0,0,0,0.12)'
    },
    standardCell: {
        textAlign: 'left',
        position: 'relative'
    },
    snoSkuDescription: {
        fontWeight: blackWeight,
        fontSize: medium
    },
    snoSkuAttributes: {
        fontWeight: boldWeight,
        fontSize: small
    },
    weekTotal: {
        fontWeight: blackWeight,
        fontSize: medium
    },
    weekAllocation: {
        fontWeight: blackWeight,
        fontSize: small
    },
    linkWeekTotal: {
        fontWeight: boldWeight,
        fontSize: medium,
        textAlign: 'right'
    },
    productDescriptionHeading: {
        fontSize: small,
        fontWeight: blackWeight,
        textTransform: 'uppercase',
        color: theme.palette.secondary.main
    },
    productDescription: {
        fontWeight: blackWeight,
        fontSize: medium,
        marginTop: '0.75em'
    },
    productDetails: {
        marginLeft: '2em',
        fontWeight: boldWeight,
        fontSize: medium
    },
    icon: {
        width: '1.5em',
        height: '1.5em',
        position: 'absolute',
        left: '1em',
        top: '1em',
        '& + p': {
            paddingTop: '2em'
        }
    },
    completedIcon: {
        fill: successGreen
    }
}));

export default function ProductPlanningATMSupplyRow({ allocation, expand, stateName }: Props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [active, setActive] = useState<boolean>(allocation.isActive ?? false);
    const [weekRange, setWeekRange] = useState<number>(TotalNDayRanges.nineWeeks);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { range, quantityUnit } = useTypedSelector((state) =>
        selectStateByName(state, stateName)
    );
    const { selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const prevExpand = usePrevious(expand.toggle);
    const hasOnlyPlanItAccounts = useTypedSelector<boolean>(selectIsPlanItOnly);

    const longRangeStart = 9;
    const longRangeEnd = 17;

    const toggleExpand = () => {
        setActive(!active);
        allocation.isActive = !active;
        dispatch(updateCollapse(allocation, stateName));
    };

    useEffect(() => {
        if (prevExpand && expand.toggle !== prevExpand) {
            setActive(expand.toggle);
        }
    }, [expand]);

    useEffect(() => {
        setActive(allocation.isActive ?? false);
    }, [allocation.isActive]);

    useEffect(() => {
        if (weekRange !== range) {
            setWeekRange(range);
        }
    }, [range]);

    const getProductDescription = (product: AllocationProduct) => {
        const productDestination = product.destinations?.find(
            (dest) => String(dest.shipToId) === selectedAccountId
        );
        const productId = productDestination?.customerProductId ?? product.productSku;
        const graphicId = product.graphicId ? ` / ${product.graphicId}` : '';
        return `${product.productDescription} (${productId}${graphicId})`;
    };

    const getProductRows = (products: AllocationProduct[]) => {
        let tableRows: Array<any> = [];
        products.forEach((product, index) => {
            tableRows.push(
                <>
                    <TableRow
                        className={clsx(classes.altRow, classes.hidden, {
                            [classes.display]: active
                        })}
                        data-testid="product-description-row"
                    >
                        <TableCell
                            colSpan={1}
                            className={clsx(classes.borderlessCell, classes.activeHeaderCell)}
                            data-testid="product-description-header"
                        >
                            {index === 0 && (
                                <Typography className={classes.productDescriptionHeading}>
                                    {`${t('productDescription', 'Product Description')} (${t(
                                        'productGraphicId',
                                        'Product ID / Graphic ID'
                                    )})`}
                                </Typography>
                            )}
                            <Typography className={classes.productDescription}>
                                {getProductDescription(product)}
                            </Typography>
                        </TableCell>
                        {handleProductHeaderRows(
                            getQuantities(product.productionBalance, product.quantityPerPallet)
                        )}
                    </TableRow>
                    {!hasOnlyPlanItAccounts && (
                        <TableRow
                            className={clsx(classes.altRow, classes.hidden, {
                                [classes.display]: active
                            })}
                            data-testid="make-it-qty-row"
                        >
                            <TableCell
                                colSpan={1}
                                className={clsx(classes.borderlessCell, classes.activeHeaderCell)}
                                data-testid="make-it-qty-header"
                            >
                                <Typography className={classes.productDetails}>
                                    <Trans i18nKey="makeItQuantity">Make It Quantity</Trans>
                                </Typography>
                            </TableCell>
                            {handleWeeklyQuantities(
                                product,
                                PlanningType.MakeIt,
                                getQuantities(
                                    product.intervalPalletsProducedTotals,
                                    product.quantityPerPallet
                                ),
                                true
                            )}
                        </TableRow>
                    )}
                    <TableRow
                        className={clsx(classes.altRow, classes.hidden, {
                            [classes.display]: active
                        })}
                        data-testid="forecast-row"
                    >
                        <TableCell
                            colSpan={1}
                            className={clsx(classes.borderlessCell, classes.activeHeaderCell)}
                            data-testid="forecast-header"
                        >
                            <Typography className={classes.productDetails}>
                                <Trans i18nKey="forecast">Forecast</Trans>
                            </Typography>
                        </TableCell>
                        {handleWeeklyQuantities(
                            product,
                            PlanningType.Forecast,
                            getQuantities(
                                product.intervalPalletsProducedTotalsForecasts,
                                product.quantityPerPallet
                            ),
                            true
                        )}
                    </TableRow>
                </>
            );
        });
        return tableRows;
    };

    const handleProductHeaderRows = (quantities?: number[]) => {
        let styledTableCells: Array<any> = [];
        const startCount = weekRange === TotalNDayRanges.nineWeeks ? 0 : longRangeStart;
        const endCount = weekRange === TotalNDayRanges.nineWeeks ? longRangeStart : longRangeEnd;
        quantities?.forEach((quantity, index) => {
            if (index >= startCount && index < endCount) {
                styledTableCells.push(
                    <TableCell
                        className={clsx(classes.linkWeekTotal, classes.borderlessCell)}
                        data-testid="weekly-balance"
                    ></TableCell>
                );
            }
        });
        return styledTableCells;
    };

    const handleWeeklyAllocationQuantities = (quantities?: (number | undefined)[]) => {
        let styledTableCells: Array<any> = [];
        const startCount = weekRange === TotalNDayRanges.nineWeeks ? 0 : longRangeStart;
        const endCount = weekRange === TotalNDayRanges.nineWeeks ? longRangeStart : longRangeEnd;
        quantities?.forEach((quantity, index) => {
            if (index >= startCount && index < endCount) {
                let weekTotal = 0;
                allocation.products?.forEach((product) => {
                    weekTotal += product.intervalPalletsProducedTotals[index];
                });
                if (typeof quantity !== 'undefined') {
                    const supplyPlanMetState: 'under' | 'met' | 'over' =
                        weekTotal === quantity ? 'met' : weekTotal > quantity ? 'over' : 'under';
                    styledTableCells.push(
                        <TableCell
                            className={classes.standardCell}
                            data-testid={`quantity-cell-${index}`}
                        >
                            {supplyPlanMetState === 'met' && (
                                <img
                                    data-testid={`complete-icon-${index}`}
                                    className={clsx(classes.icon, classes.completedIcon)}
                                    src={process.env.PUBLIC_URL + '/assets/Confirmation_icon.svg'}
                                    alt="Confirmation Icon"
                                />
                            )}
                            {supplyPlanMetState === 'under' && (
                                <img
                                    data-testid={`warning-icon-${index}`}
                                    className={clsx(classes.icon)}
                                    src={process.env.PUBLIC_URL + '/assets/Warning_icon.svg'}
                                    alt="Warning Icon"
                                />
                            )}
                            {supplyPlanMetState === 'over' && (
                                <img
                                    data-testid={`error-icon-${index}`}
                                    className={clsx(classes.icon)}
                                    src={process.env.PUBLIC_URL + '/assets/Error_icon.svg'}
                                    alt="Error Icon"
                                />
                            )}
                            <Typography className={classes.weekTotal}>
                                {weekTotal.toLocaleString()} PL
                            </Typography>
                            <Typography className={classes.weekAllocation}>
                                of {quantity.toLocaleString()} PL
                            </Typography>
                        </TableCell>
                    );
                } else {
                    styledTableCells.push(
                        <TableCell
                            className={classes.standardCell}
                            data-testid={`quantity-cell-${index}`}
                        >
                            <Typography className={classes.weekTotal}>N/A</Typography>
                        </TableCell>
                    );
                }
            }
        });
        return styledTableCells;
    };

    const handleWeeklyQuantities = (
        product: AllocationProduct,
        planningType: PlanningType,
        quantities?: number[],
        isLink: boolean = false
    ) => {
        let styledTableCells: Array<any> = [];
        const startCount = weekRange === TotalNDayRanges.nineWeeks ? 0 : longRangeStart;
        const endCount = weekRange === TotalNDayRanges.nineWeeks ? longRangeStart : longRangeEnd;
        quantities?.forEach((quantity, index) => {
            const displayQuantity = Math.max(0, quantity);

            if (index >= startCount && index < endCount) {
                if (quantity === 0 || planningType === PlanningType.PalletTotal) {
                    styledTableCells.push(
                        <TableCell className={classes.standardCell} data-testid="weekly-balance">
                            <Typography
                                className={clsx(
                                    {
                                        [classes.weekTotal]: !isLink
                                    },
                                    {
                                        [classes.linkWeekTotal]: isLink
                                    }
                                )}
                            >
                                {quantityUnit === 'pallets'
                                    ? `${displayQuantity.toLocaleString()} PL`
                                    : wrapEachesForTableCell(
                                          formatNumberWithLocale(cultureCode, displayQuantity)
                                      )}
                            </Typography>
                        </TableCell>
                    );
                } else {
                    styledTableCells.push(
                        <TableCell className={classes.standardCell}>
                            {/* Linking to be reintroduced in a later ticket */}
                            {/* <Link
                                component="button"
                                underline="always"
                                data-testid="weekly-balance"
                                onClick={() =>
                                    onWeeklyLookup(product, index, planningType, quantity)
                                }
                            > */}
                            <Typography
                                className={clsx(
                                    {
                                        [classes.weekTotal]: !isLink
                                    },
                                    {
                                        [classes.linkWeekTotal]: isLink
                                    }
                                )}
                            >
                                {quantityUnit === 'pallets'
                                    ? `${displayQuantity.toLocaleString()} PL`
                                    : wrapEachesForTableCell(
                                          formatNumberWithLocale(cultureCode, displayQuantity)
                                      )}
                            </Typography>
                            {/* </Link> */}
                        </TableCell>
                    );
                }
            }
        });
        return styledTableCells;
    };

    const getQuantities = (palletQuantity: number[], quantityPerPallet?: number) => {
        if (quantityUnit === 'eaches' && quantityPerPallet) {
            return palletQuantity.map((quantity) => {
                return quantity * quantityPerPallet;
            });
        } else {
            return palletQuantity;
        }
    };

    useTranslation();

    return (
        <>
            <TableRow
                className={clsx(classes.standardRow, { [classes.activeRow]: active })}
                data-testid="product-planning-row"
            >
                <TableCell className={classes.standardCell} data-testid="sno-sku-description">
                    <Grid container>
                        <Grid item xs={10} className={classes.standardCell}>
                            <Typography className={classes.snoSkuDescription}>
                                {allocation.snoSkuDescription}
                            </Typography>
                            <Typography
                                className={classes.snoSkuAttributes}
                                data-testid="sno-sku-attributes"
                            >
                                {allocation.snoSkuAttributes}
                            </Typography>
                            {!!allocation.products &&
                                allocation.products.some((p) => p.campaignTiming) && (
                                    <CampaignPill />
                                )}
                        </Grid>
                        <Grid item xs={2} className={classes.expandContainer}>
                            <ExpandMoreIcon
                                className={clsx(classes.expand, {
                                    [classes.expandOpen]: active
                                })}
                                onClick={() => toggleExpand()}
                                color="primary"
                                data-testid="expand-icon"
                            />
                        </Grid>
                    </Grid>
                </TableCell>
                {handleWeeklyAllocationQuantities(allocation.weekAllocationQuantity)}
            </TableRow>
            {!!allocation.products && getProductRows(allocation.products)}
        </>
    );
}
