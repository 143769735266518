import { Checkbox, Grid, makeStyles, Typography } from '@material-ui/core';
import moment, { Moment } from 'moment';
import React from 'react';
import {
    addFormItemButtonBlue,
    ballGray,
    ballLtGray,
    blackWeight,
    small,
    white,
    xs
} from '../../../../themes/globalConstants';
import clsx from 'clsx';
import { Trans } from 'react-i18next';

interface Props {
    date: Moment;
    isSelected: boolean;
    handleClick: (week: Moment) => void;
    firstWeek?: boolean;
}

const useStyles = makeStyles((theme) => ({
    root: {
        color: ballGray,
        backgroundColor: white,
        display: 'flex',
        justifyContent: 'center',
        padding: '0.5em',
        border: `1px solid ${ballLtGray}`,
        alignItems: 'top',
        gap: '8px',
        cursor: 'pointer',
        '&:nth-of-type(n + 2)': {
            borderLeft: 'none'
        },
        '&:hover': {
            color: addFormItemButtonBlue,
            boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.2)',
            zIndex: 10,
            '& *': {
                fontWeight: blackWeight
            }
        }
    },
    guidedWidth: {
        width: '120px'
    },
    selected: {
        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.2)',
        color: addFormItemButtonBlue,
        zIndex: 10
    },
    blackWeight: {
        fontWeight: blackWeight
    },
    date: {
        width: '45px',
        textAlign: 'center',
        '& h3': {
            fontSize: '16px',
            marginBottom: '4px'
        },
        '& h4': {
            fontSize: '14px'
        }
    },
    dateOptionTag: {
        fontSize: small,
        padding: '0.75em',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        borderBottomLeftRadius: '0.25em',
        borderBottomRightRadius: '0.25em',
        border: `1px solid ${theme.palette.primary.main}`,
        '& p': {
            fontWeight: blackWeight,
            fontSize: xs
        }
    },
    iconContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '2px',
        gap: '0.25em'
    },
    metIcon: {
        margin: 'auto 0',
        alignSelf: 'center'
    }
}));

const GuidedWeekButton = ({ date, isSelected, handleClick, firstWeek }: Props) => {
    const classes = useStyles();

    const firstAvailableDate = moment().add(7, 'days').startOf('isoWeek').format('MM/DD/YYYY');

    return (
        <Grid container className={clsx(classes.guidedWidth)}>
            <Grid
                className={clsx(classes.root, {
                    [classes.selected]: isSelected
                })}
                onClick={() => handleClick(date)}
                item
                xs={12}
            >
                <Grid className={classes.iconContainer}>
                    <Checkbox
                        checked={isSelected}
                        color="primary"
                        data-testid="week-checkbox"
                        disabled={false}
                    ></Checkbox>
                </Grid>
                <Grid className={classes.date}>
                    <Typography
                        variant="h3"
                        className={clsx({ [classes.blackWeight]: isSelected })}
                    >
                        {date.format('MM/DD')}
                    </Typography>
                    <Typography
                        variant="h4"
                        className={clsx({ [classes.blackWeight]: isSelected })}
                    >
                        {date.format('YYYY')}
                    </Typography>
                </Grid>
            </Grid>
            {firstWeek && (
                <Grid container justify="space-between">
                    <Grid item xs={12}>
                        <Grid className={classes.dateOptionTag}>
                            <Grid>
                                <Typography data-testid="today-text">
                                    <Trans i18nKey="today">Today</Trans>:{' '}
                                    {moment.utc().format('MM/DD/YYYY')}
                                </Typography>
                            </Grid>
                            <Grid>
                                <Typography data-testid="first-available-text">
                                    <Trans i18nKey="firstAvailable">First Available</Trans>:{' '}
                                    {firstAvailableDate}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default GuidedWeekButton;
