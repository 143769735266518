import React from "react";
import { Trans } from "react-i18next";
import ConfirmationModal from "../../../reusable/molecules/ConfirmationModal";

interface Props {
    onDiscardChanges: () => void;
    onClose: () => void;
    open: boolean;
}

const EditProductionOrderDiscardModal = ({ onDiscardChanges, open, onClose }: Props) => {
    return (
        <ConfirmationModal
            title={<Trans i18nKey="changesWillNotBeSaved">Changes made will not be saved</Trans>}
            open={open}
            logo={false}
            subheader={
                <Trans i18nKey="areYouSureDiscard">
                    Are you sure you want to discard any changes?
                </Trans>
            }
            cancelText={<Trans i18nKey="no">No</Trans>}
            continueText={<Trans i18nKey="yesDiscard">Yes, Discard</Trans>}
            saveProgress={onDiscardChanges}
            onClose={onClose}
            onCancel={onClose}
            canSaveDraft={true}
        />
    )
}
export default EditProductionOrderDiscardModal;