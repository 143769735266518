import { makeStyles, Menu, MenuItem } from '@material-ui/core';
import React from 'react';
import { Trans } from 'react-i18next';

interface Props {
    onAddShipTo: () => void;
    onMenuBlur: () => void;
    anchorEl: HTMLElement | null;
    row: any;
}

const useStyles = makeStyles(() => ({
    menuWrapper: {
        '& .MuiPopover-paper': {
            marginTop: '3.2em',
            paddingTop: '0.5em',
            paddingBottom: '0.5em',
            marginRight: '2em',
            minWidth: '15em',
            borderTop: '1em'
        }
    }
}));

const ManageBillToLocationsContextMenu = ({ onAddShipTo, onMenuBlur, anchorEl, row }: Props) => {
    const classes = useStyles();
    return (
        <Menu
            className={classes.menuWrapper}
            keepMounted
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: -110,
                vertical: 'top'
            }}
            onBlur={onMenuBlur}
        >
            <MenuItem onClick={onAddShipTo} disabled={row.status === 'Inactive'}>
                <Trans i18nKey="addShipTo">Add Ship To</Trans>
            </MenuItem>
        </Menu>
    );
};

export default ManageBillToLocationsContextMenu;
