import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TableHead
} from '@material-ui/core';
import { Product } from '../../../../store/reducers/product-portfolio';
import { useTranslation, Trans } from 'react-i18next';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { regions } from '../../../../static-data/dropdown-lists';
import CurrentLeadTime from '../../../reusable/atoms/CurrentLeadTime';
import { currencyFormatter } from '../../../../utility/helpers/formatters';
import { getCampaignTiming } from '../../../../utility/helpers/content-helpers';

interface Props {
    item: Product;
}

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: '9.375em',
        marginBottom: '1em'
    },
    tableCell: {
        width: '50%'
    },
    tableHeader: {
        fontSize: 18,
        color: theme.palette.info.dark,
        fontWeight: 'bold'
    }
}));

const QuickLookTable = ({ item }: Props) => {
    const classes = useStyles();
    const { regionCode, cultureCode } = useTypedSelector<RegionCultureState>(
        (state) => state.regionCulture
    );
    const campaignTiming = item.campaignTiming ? getCampaignTiming(item.campaignTiming) : null;

    useTranslation();

    return (
        <TableContainer>
            {regionCode === regions[0].value ? (
                <>
                    <Table className={classes.table} size="small" aria-label="ordering table">
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <Typography className={classes.tableHeader}>
                                        <Trans i18nKey="ordering">Ordering</Trans>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {item.pricePerThousand && (
                                <TableRow>
                                    <TableCell colSpan={1} className={classes.tableCell}>
                                        <Trans i18nKey="pricePerThousand">Price per 1,000</Trans>
                                    </TableCell>
                                    <TableCell
                                        colSpan={1}
                                        align="left"
                                        data-testid="price-per-thousand"
                                    >
                                        {currencyFormatter(cultureCode, item.pricePerThousand)}
                                    </TableCell>
                                </TableRow>
                            )}
                            {item.quantityPerPallet && (
                                <TableRow>
                                    <TableCell colSpan={1} className={classes.tableCell}>
                                        <Trans i18nKey="quantityPerPallet">
                                            Quantity per Pallet
                                        </Trans>
                                    </TableCell>
                                    <TableCell
                                        colSpan={1}
                                        align="left"
                                        data-testid="quantity-per-pallet"
                                    >
                                        {item.quantityPerPallet.toLocaleString()} Cans
                                    </TableCell>
                                </TableRow>
                            )}
                            {item.minOrderQuantityPallets && (
                                <TableRow>
                                    <TableCell colSpan={1} className={classes.tableCell}>
                                        <Trans i18nKey="minOrder">Minimum Order</Trans>
                                    </TableCell>
                                    <TableCell
                                        colSpan={1}
                                        align="left"
                                        data-testid="minimum-order-quantity"
                                    >
                                        {item.minOrderQuantityPallets} Pallets
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <Table
                        className={classes.table}
                        size="small"
                        aria-label="design specifications table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="subtitle1" className={classes.tableHeader}>
                                        <Trans i18nKey="production">Production</Trans>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {item.currentLeadTime && (
                                <TableRow>
                                    <TableCell colSpan={1} className={classes.tableCell}>
                                        <Trans i18nKey="currentLeadTime">Current Lead Time</Trans>
                                    </TableCell>
                                    <TableCell
                                        colSpan={1}
                                        align="left"
                                        data-testid="current-lead-time"
                                    >
                                        <CurrentLeadTime
                                            leadTime={item.currentLeadTime as string}
                                            leadTimeColorOverride={item.leadTimeColorOverride}
                                        />
                                    </TableCell>
                                </TableRow>
                            )}
                            {item.ends && item.ends[0] && (
                                <TableRow>
                                    <TableCell colSpan={1} className={classes.tableCell}>
                                        <Trans i18nKey="compatibleEnds">Compatible Ends</Trans>
                                    </TableCell>
                                    <TableCell
                                        colSpan={1}
                                        align="left"
                                        data-testid="compatible-ends"
                                    >
                                        {item.ends.map((end) => end.label).join(', ')}
                                    </TableCell>
                                </TableRow>
                            )}
                            {item.campaignTiming && (
                                <TableRow>
                                    <TableCell colSpan={1} className={classes.tableCell}>
                                        <Trans i18nKey="limitedProductionRun">
                                            Limited Production Run
                                        </Trans>
                                    </TableCell>
                                    <TableCell
                                        colSpan={1}
                                        align="left"
                                        data-testid="campaign-timing"
                                    >
                                        {campaignTiming}{' '}
                                        <Trans i18nKey="timesPerYear">Times Per Year</Trans>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </>
            ) : (
                (regionCode === regions[1].value || regionCode === regions[2].value) && (
                    <Table
                        className={classes.table}
                        size="small"
                        aria-label="design specifications table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <Typography variant="subtitle1" className={classes.tableHeader}>
                                        <Trans i18nKey="canSpecification">Can Specification</Trans>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {item.ends && item.ends[0] && (
                                <TableRow>
                                    <TableCell colSpan={1} className={classes.tableCell}>
                                        <Trans i18nKey="endSize">End Size</Trans>
                                    </TableCell>
                                    <TableCell colSpan={1} align="left" data-testid="end-size">
                                        {item.ends && item.ends[0].diameter} mm
                                    </TableCell>
                                </TableRow>
                            )}
                            {item.bodyOutsideDiameterMM && (
                                <TableRow>
                                    <TableCell colSpan={1} className={classes.tableCell}>
                                        <Trans i18nKey="bodyOutsideDiameter">Body Diameter</Trans>
                                    </TableCell>
                                    <TableCell colSpan={1} align="left" data-testid="body-diameter">
                                        {item.bodyOutsideDiameterMM} mm
                                    </TableCell>
                                </TableRow>
                            )}
                            {item.designHeightMM && (
                                <TableRow>
                                    <TableCell colSpan={1} className={classes.tableCell}>
                                        <Trans i18nKey="designHeight">Height Closed</Trans>
                                    </TableCell>
                                    <TableCell colSpan={1} align="left" data-testid="design-height">
                                        {item.designHeightMM} mm
                                    </TableCell>
                                </TableRow>
                            )}
                            {item.flangedCanHeightMM && (
                                <TableRow>
                                    <TableCell colSpan={1} className={classes.tableCell}>
                                        <Trans i18nKey="flangedCanHeight">Flange</Trans>
                                    </TableCell>
                                    <TableCell colSpan={1} align="left" data-testid="flange">
                                        {item.flangedCanHeightMM} mm
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                )
            )}
        </TableContainer>
    );
};

export default QuickLookTable;
