import React, { useEffect, useState } from 'react';
import { makeStyles, Box, Tabs, Tab, Grid, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {
    addFormItemButtonBlue,
    black,
    blackWeight,
    inactiveTabBlue,
    ltBlueGrey,
    ballDrkBlue,
    lato,
    medium,
    small,
    white,
    xl
} from '../../../../themes/globalConstants';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { ContractAndDocument } from '../../../../store/reducers/contract-summary';
import TranslationService from '../../../../utility/services/translation-service';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import DocumentsTab from './DocumentsTab';
import { ContractSummaryState } from '../../../../store/reducers/contract-summary';
import { useQuery } from '../../../../utility/helpers/query-helpers';
import { ContractSummaryProvider } from '../context/ContractSummaryContext';
import {
    documentIsCurrentlyActive,
    documentIsPastExpiration
} from '../../../../utility/helpers/pricing-helpers';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

export enum TabStatus {
    'active' = 0,
    'archived' = 1
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: '1em',
        position: 'relative',
        '& h2': {
            fontSize: xl,
            fontFamily: lato,
            fontWeight: blackWeight,
            color: theme.palette.secondary.main
        }
    },
    tabPanel: {
        width: '100%'
    },
    counter: {
        width: '2em',
        height: '2em',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '0.5em',
        fontSize: small,
        fontWeight: blackWeight,
        backgroundColor: ltBlueGrey,
        color: ballDrkBlue
    },
    selectedCounter: {
        backgroundColor: addFormItemButtonBlue,
        color: white
    },
    tabs: {},
    tabLabel: {
        backgroundColor: inactiveTabBlue,
        height: '56px',
        borderTopLeftRadius: '0.625em',
        borderTopRightRadius: '0.625em',
        '&:not(:first-of-type)': {
            marginLeft: '8px'
        },
        padding: '0 3em',
        color: ballDrkBlue,
        textTransform: 'none',
        maxWidth: 'none'
    },
    tabLabelText: {
        fontSize: medium,
        fontWeight: blackWeight
    },
    selectedTab: {
        backgroundColor: white,
        color: black,
        boxShadow: '0px 0px 6px rgba(185, 211, 220, 0.8), inset 0px -3px 0px #009BBB'
    }
}));

const TabPanel = (props: TabPanelProps) => {
    const classes = useStyles();
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`documents-tabpanel-${index}`}
            aria-labelledby={`documents-tab-${index}`}
            {...other}
            className={classes.tabPanel}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
};

// This assigns props for accessibility purposes
const a11yProps = (index: any) => {
    return {
        id: `documents-tab-${index}`,
        'aria-controls': `documents-tabpanel-${index}`
    };
};

const DocumentsTabs = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const query = useQuery();
    const status = query.get('status');
    const [activeDocuments, setActiveDocuments] = useState<ContractAndDocument[]>([]);
    const [value, setValue] = useState<Number>(0);
    const { documents, loaded } = useTypedSelector<ContractSummaryState>(
        (state) => state.contractSummary
    );
    const { selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    useEffect(() => {
        if (loaded && documents && documents.length > 0) {
            const activeBillToDocuments: ContractAndDocument[] = [];
            const archivedBillToDocuments: ContractAndDocument[] = [];

            if (selectedAccountId) {
                const documentsForBillTo = documents.filter(
                    (document) => document.billTo === selectedAccountId
                );

                if (documentsForBillTo.length > 0) {
                    documentsForBillTo.forEach((document) => {
                        if (document.billTo === selectedAccountId) {
                            if (documentIsCurrentlyActive(document))
                                activeBillToDocuments.push(document);
                            if (documentIsPastExpiration(document))
                                archivedBillToDocuments.push(document);
                        }
                    });
                }
            }

            setActiveDocuments(activeBillToDocuments);
        }
    }, [dispatch, selectedAccountId, documents, loaded]);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        const val = TabStatus[newValue];
        window.history.replaceState('', '', `?status=${val}`);
        setValue(newValue);
    };

    function getValueFromStatus(status) {
        return (TabStatus as any)[status?.toLowerCase() || ''] || 0;
    }

    useEffect(() => {
        const value = getValueFromStatus(status);
        setValue(value);
    }, [status]);

    const TabLabel = (label: string, count: number, index: number) => {
        return (
            <Grid container justify="center" alignItems="center">
                <Typography className={classes.tabLabelText}>{label}</Typography>
                <Typography
                    component="span"
                    className={`${classes.counter} ${value === index && classes.selectedCounter}`}
                >
                    {count}
                </Typography>
            </Grid>
        );
    };

    return (
        <ContractSummaryProvider value={{ activeTab: value === 0 ? 'active' : 'archived' }}>
            <div className={classes.root} data-testid="contract-tabs">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="contract summary tabs"
                    className={classes.tabs}
                    scrollButtons="auto"
                    variant="scrollable"
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab
                        label={TabLabel(
                            TranslationService.getTranslatedText(cultureCode, 'activeDocuments'),
                            activeDocuments ? activeDocuments.length : 0,
                            0
                        )}
                        {...a11yProps(0)}
                        className={`${classes.tabLabel} ${value === 0 && classes.selectedTab}`}
                        value={0}
                        data-testid="active-documents-tab"
                    />
                    {/* <Tab
                        label={TabLabel(
                            TranslationService.getTranslatedText(cultureCode, 'archivedDocuments'),
                            archivedDocuments ? archivedDocuments.length : 0,
                            1
                        )}
                        {...a11yProps(1)}
                        className={`${classes.tabLabel} ${value === 1 && classes.selectedTab}`}
                        value={1}
                        data-testid="archived-documents-tab"
                    /> */}
                </Tabs>
                <TabPanel value={value} index={0}>
                    <DocumentsTab documents={activeDocuments} />
                </TabPanel>
                {/* <TabPanel value={value} index={1}>
                    <DocumentsTab documents={archivedDocuments} isArchived={true} />
                </TabPanel> */}
            </div>
        </ContractSummaryProvider>
    );
};

export default DocumentsTabs;
