import { Grid, Typography, makeStyles, Link, IconButton, Collapse } from '@material-ui/core';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { MakeItBulkOrder } from '../../../../store/reducers/makeit-bulk-upload';
import {
    ballDrkBlue,
    ballGray,
    blackWeight,
    small,
    white,
    xxl,
    ballBlue
} from '../../../../themes/globalConstants';
import clsx from 'clsx';
import MakeItBulkUploadReviewGrid from './MakeItBulkUploadReviewGrid';
import { enUS } from '../../../../utility/translations/locales/';
import ConfirmationModal from '../../../reusable/molecules/ConfirmationModal';
import { useDispatch } from 'react-redux';
import {
    markProductionOrderRemoved,
    undoMarkProductOrderRemoved
} from '../../../../store/root-actions';
import Button from '../../../reusable/atoms/Button';
import { MakeItBulkUploadReviewOrderSectionProvider } from '../context/MakeItBulkUploadReviewOrderSectionContext';
import ExpandLessOutlinedIcon from '@material-ui/icons/ExpandLessOutlined';

interface Props {
    order: MakeItBulkOrder;
}

const useStyles = makeStyles((theme) => ({
    main: {
        marginTop: '1em',
        marginBottom: '1em'
    },
    subtitle: {
        color: ballGray
    },
    item: {
        paddingLeft: '.75em'
    },
    poText: {
        fontSize: xxl,
        overflowWrap: 'break-word'
    },
    shipToText: {
        fontSize: small
    },
    action: {
        cursor: 'pointer'
    },
    trashAlign: {
        marginTop: '.9em',
        marginLeft: '.4em',
        textAlign: 'center'
    },
    border: {
        borderRight: `1px solid ${theme.palette.info.light}`
    },
    deleteBtn: {
        marginRight: '2em',
        color: theme.palette.error.main,
        border: `1px solid ${theme.palette.error.main}`,
        '&:hover': {
            color: white,
            backgroundColor: theme.palette.error.main
        }
    },
    sectionFooter: {
        marginTop: `1rem`
    },
    altDescriptionColor: {
        color: ballDrkBlue
    },
    dateDescriptionMain: {
        fontSize: xxl,
        fontWeight: blackWeight
    },
    arrow: {
        fill: ballBlue,
        transform: 'rotate(180deg)'
    },
    rotateArrow: {
        transform: 'rotate(0deg)'
    },
    toggleButton: {
        padding: '0 !important',
        '&:hover': {
            background: 'transparent'
        }
    },
    collapseToggleContainer: {
        display: 'flex'
    },
    collapse: {
        width: '100%'
    },
    poNumContainer: {
        paddingRight: '0.5em'
    }
}));

const MakeItBulkUploadReviewOrderSection = ({ order }: Props) => {
    const classes = useStyles();
    const [openDeleteOrderModal, setOpenDeleteOrderModal] = useState<boolean>(false);
    const [openDrawer, setOpenDrawer] = useState(true);
    const dispatch = useDispatch();

    function onConfirmMarkOrderRemoved() {
        setOpenDeleteOrderModal(false);
        setOpenDrawer(false);
        dispatch(markProductionOrderRemoved(order.customerProductionOrderId, order.shipToId));
    }

    function onUndoDeleteOrder() {
        setOpenDrawer(true);
        dispatch(undoMarkProductOrderRemoved(order.customerProductionOrderId, order.shipToId));
    }
    function CollapseToggle() {
        return (
            <IconButton
                aria-label="close"
                color="inherit"
                data-testid="order-section-collapse-toggle"
                className={classes.toggleButton}
                onClick={() => {
                    setOpenDrawer(!openDrawer);
                }}
            >
                <ExpandLessOutlinedIcon
                    fontSize="large"
                    className={clsx(classes.arrow, { [classes.rotateArrow]: openDrawer })}
                />
            </IconButton>
        );
    }

    function getOrderHeader(order: MakeItBulkOrder) {
        return (
            <>
                {' '}
                {order.markedRemoved ? (
                    <Grid item className={clsx(classes.item)}>
                        <Typography
                            className={clsx(
                                classes.dateDescriptionMain,
                                classes.altDescriptionColor
                            )}
                            data-testid={`order-deleted-${order.customerProductionOrderId}`}
                        >
                            <Trans i18nKey="orderDeleted">{enUS.orderDeleted}</Trans>
                        </Typography>
                    </Grid>
                ) : (
                    <>
                        <Grid
                            item
                            xs={4}
                            className={clsx(classes.item, classes.border, classes.poNumContainer)}
                            data-testid={'customer-po'}
                        >
                            <Typography variant="subtitle2" className={classes.subtitle}>
                                <Trans i18nKey="customerPoNumber">CUSTOMER PO #</Trans>
                            </Typography>
                            <Typography
                                className={classes.poText}
                                data-testid="ship-to-name"
                                variant="h4"
                            >
                                {order.customerProductionOrderId}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            className={clsx(classes.item, classes.border)}
                            data-testid={'ship-to'}
                        >
                            <Typography variant="subtitle2" className={classes.subtitle}>
                                <Trans i18nKey="shipTo">SHIP TO</Trans>
                            </Typography>
                            <Typography data-testid="ship-to-name" className={classes.shipToText}>
                                {order.shipToName}
                            </Typography>
                            <Typography
                                data-testid="ship-to-address"
                                className={classes.shipToText}
                            >
                                {order.shipToAddress}
                            </Typography>
                        </Grid>
                    </>
                )}
                {!order.markedRemoved && (
                    <Grid item className={clsx(classes.item, classes.collapseToggleContainer)}>
                        <CollapseToggle />
                    </Grid>
                )}
            </>
        );
    }

    return (
        <MakeItBulkUploadReviewOrderSectionProvider
            value={{ markedRemoved: !!order.markedRemoved, lineCount: order.lines.length }}
        >
            <Grid container className={classes.main}>
                <Grid container item xs={6} justify="flex-start">
                    {getOrderHeader(order)}
                </Grid>
                <Grid container item xs={6} justify="flex-end">
                    <Grid item xs={3} className={classes.item}>
                        <Typography variant="subtitle2" className={classes.subtitle}>
                            <Trans i18nKey="canQuantity">Can Quantity</Trans>
                        </Typography>
                        <Typography variant="body1" data-testid="can-quantity">
                            {`${order.canQuantityPallets?.toLocaleString()} PL / ${
                                order.canQuantitySKUs
                            } ${order.canQuantitySKUs!! > 1 ? ' SKUs' : ' SKU'}`}
                        </Typography>
                        <Typography className="helper-text">
                            {order.canQuantityEaches?.toLocaleString()} ea.
                        </Typography>
                    </Grid>
                    <Grid item xs={3} className={clsx(classes.item, classes.border)}>
                        <Typography variant="subtitle2" className={classes.subtitle}>
                            <Trans i18nKey="endQuantity">End Quantity</Trans>
                        </Typography>
                        <Typography variant="body1" data-testid="end-quantity">
                            {`${order.endQuantityPallets?.toLocaleString()} PL / ${
                                order.endQuantitySKUs
                            } ${order.endQuantitySKUs!! > 1 ? ' SKUs' : ' SKU'}`}
                        </Typography>
                        <Typography className="helper-text">
                            {order.endQuantityEaches?.toLocaleString()} ea.
                        </Typography>
                    </Grid>
                    {order.markedRemoved && (
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            data-testid={`undo-delete-order-btn-${order.customerProductionOrderId}`}
                            onClick={() => onUndoDeleteOrder()}
                        >
                            <Trans i18nKey="undo">Undo</Trans>
                        </Button>
                    )}
                    {!order.markedRemoved && (
                        <Grid item xs={1} className={classes.trashAlign}>
                            <Link
                                className={classes.action}
                                onClick={() => setOpenDeleteOrderModal(true)}
                                data-testid={`delete-order-${order.customerProductionOrderId}`}
                            >
                                <img
                                    src={process.env.PUBLIC_URL + '/assets/Trash.svg'}
                                    alt="Trash Icon"
                                    data-testid="trash-icon-order"
                                />
                            </Link>
                        </Grid>
                    )}
                </Grid>
                <Collapse in={openDrawer} className={classes.collapse}>
                    <MakeItBulkUploadReviewGrid items={order.lines} />
                </Collapse>

                <ConfirmationModal
                    logo={false}
                    title={
                        <Trans i18nKey="deleteOrderModalTitle">{enUS.deleteOrderModalTitle}</Trans>
                    }
                    subheader={
                        <Trans i18nKey="deleteOrderModalBody">{enUS.deleteOrderModalBody}</Trans>
                    }
                    continueText={<Trans i18nKey="yesDelete">{enUS.yesDelete}</Trans>}
                    cancelText={<Trans i18nKey="no">{enUS.no}</Trans>}
                    saveProgress={onConfirmMarkOrderRemoved}
                    onClose={() => setOpenDeleteOrderModal(false)}
                    onCancel={() => setOpenDeleteOrderModal(false)}
                    open={openDeleteOrderModal}
                />
            </Grid>
        </MakeItBulkUploadReviewOrderSectionProvider>
    );
};

export default MakeItBulkUploadReviewOrderSection;
