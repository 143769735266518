import React, { useEffect, useMemo, useState } from 'react';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import moment from 'moment';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import {
    boldWeight,
    blackWeight,
    dateOptionInvalidGray,
    ballBlue,
    small
} from '../../../../themes/globalConstants';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { ProductionOrder } from '../../../../store/reducers/makeit-dashboard';
import { BulkUploadMakeItState } from '../../../../store/reducers/makeit-bulk-upload';
import { ProductionOrderForTable } from '../../MakeItATMSummary/models/ProductionOrderForTable';
import PlanItColumns from './PlanItColumns';
import PlanItRow from './PlanItRow';
import { Activity, getSecurityLevel } from '../../../../utility/auth/useSecurity';
import { AuthState } from '../../../../store/reducers/auth';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { selectIsLargeCustomerAccount, selectIsMakeItAccount } from '../../../../store/selectors';
import SummarySearchBar from '../../../reusable/atoms/SummarySearchBar';
import { usePlanItSummaryContext } from '../context/PlanItSummaryContext';
import StandardTable from '../../../reusable/molecules/StandardTable';

interface Props {
    orders: ProductionOrder[];
    hideShareLinks?: boolean;
    isPrevious?: boolean;
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: '1em'
    },

    searchBar: {
        paddingTop: '1em'
    },

    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        padding: '1em'
    },
    tableWrapper: {
        '& .MuiPaper-root': {
            boxShadow: 'unset'
        },
        '& .MuiTable-root': {
            tableLayout: 'fixed'
        },
        '& .MuiToolbar-root': {
            padding: 0,
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                },
                '&:first-of-type ': {
                    paddingLeft: 0
                }
            },
            '& > h2': {
                fontSize: '1.5em',
                fontWeight: boldWeight,
                color: theme.palette.secondary.main,
                paddingLeft: '0.625em'
            },
            '& .MuiInputBase-formControl': {
                border: `1px solid ${dateOptionInvalidGray}`,
                borderRadius: '.125em',
                '&:before': {
                    display: 'none'
                },
                '&:after': {
                    display: 'none'
                },
                '& svg': {
                    height: '.875em',
                    width: '.875em',
                    fill: ballBlue
                },
                '& .MuiIconButton-root': {
                    '&:hover': {
                        backgroundColor: 'transparent !important'
                    }
                },
                '& .MuiInput-input': {
                    paddingLeft: '.25em'
                }
            }
        },
        '& .MuiTableSortLabel-root': {
            fontSize: small,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em',
            width: '99%',
            justifyContent: 'space-between',
            '& .MuiSvgIcon-root': {
                fontSize: 14,
                opacity: '1'
            }
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                fontSize: small,
                fontWeight: blackWeight,
                textTransform: 'uppercase',
                color: theme.palette.secondary.main,
                borderRadius: '0.25em',
                paddingLeft: '0.5em',
                justifyContent: 'space-between'
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    },
    icon: {
        color: ballBlue
    },
    downloadWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

const PlanItTab = ({ orders, hideShareLinks, isPrevious }: Props) => {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { products } = useTypedSelector<BulkUploadMakeItState>(
        (state) => state.bulkUploadMakeItState
    );
    const auth = useTypedSelector<AuthState>((state) => state.auth);
    const { selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const isLargeAccount = useTypedSelector<boolean>(selectIsLargeCustomerAccount);
    const spotCustomer = !isLargeAccount;
    const [columns, setColumns] = useState<any>([]);
    const [filteredOrdersForTable, setFilteredOrdersForTable] = useState<any>([]);
    const [ordersForTable, setOrdersForTable] = useState<ProductionOrderForTable[] | undefined>(
        undefined
    );
    const currentAccount = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    ).accounts?.find((account) => account.accountId === (selectedAccountId as string));
    const { activeTab } = usePlanItSummaryContext();
    const isMakeItAccount = useTypedSelector<boolean>(selectIsMakeItAccount);

    const securityLevel = useMemo(
        () =>
            getSecurityLevel(Activity.PlanItSummary, auth, {
                accountId: selectedAccountId as string
            }),
        [selectedAccountId, auth]
    );

    // A user can select copy plan it to make it if they have make it permissions
    const copyToMakeItSecurityLevel = useMemo(
        () =>
            getSecurityLevel(isLargeAccount ? Activity.MakeItBulkATM : Activity.MakeIt, auth, {
                accountId: selectedAccountId as string
            }),
        [selectedAccountId, auth, isLargeAccount]
    );

    useEffect(() => {
        if (orders && products?.length) {
            const formattedOrders: ProductionOrderForTable[] = [];
            orders.sort((a, b) => moment(b.atmWeekStart).diff(a.atmWeekStart));
            orders.forEach((order, index) => {
                let orderForTable: ProductionOrderForTable = {
                    ...order,
                    searchablePONumber: order.productionOrderId?.toString(),
                    totalOrderQuantityString: order.totalOrderQuantity.toString(),
                    totalOrderQuantityEachesString: order.totalOrderQuantityEaches?.toString(),
                    atmWeekStartString: moment(order.atmWeekStart).format('MM/DD/YYYY'),
                    atmWeekStartDate: moment(order.atmWeekStart).toDate(),
                    submittedDateString: moment(order.createDate).format('MM/DD/YYYY'),
                    sortIndex: index,
                    copyToMakeItChecked: false
                };
                formattedOrders.push(orderForTable);
            });
            setOrdersForTable(formattedOrders);
        } else {
            setOrdersForTable([]);
        }
    }, [orders, products]);

    useEffect(() => {
        const forecastTab = activeTab === 'forecasts';
        const columns = PlanItColumns.getColumns(
            cultureCode,
            forecastTab && isMakeItAccount,
            !!isPrevious,
            spotCustomer
        );

        setColumns(columns);
    }, [cultureCode, activeTab, isPrevious, spotCustomer]);

    const CurrentRow = ({ row }: { row: ProductionOrderForTable }) => {
        if (row) {
            return (
                <PlanItRow
                    order={row as ProductionOrderForTable}
                    key={row.productionOrderId}
                    securityLevel={securityLevel}
                    copyToMakeItSecurityLevel={copyToMakeItSecurityLevel}
                    currentAccount={currentAccount}
                />
            );
        } else {
            return null;
        }
    };

    const handleRowFilter = (filteredRows) => {
        setFilteredOrdersForTable(filteredRows);
    };

    return (
        <Paper elevation={2} className={classes.paper}>
            <Grid className={classes.tableWrapper}>
                <Grid className={classes.searchBar}>
                    <SummarySearchBar
                        rows={ordersForTable || []}
                        columns={columns}
                        onRowFilter={handleRowFilter}
                    />
                </Grid>

                <StandardTable
                    gridName="Plan It Forecast Orders"
                    columns={columns}
                    lines={filteredOrdersForTable}
                    rowComponent={CurrentRow}
                    itemsPerPage={10}
                    searchText={false}
                />
            </Grid>
        </Paper>
    );
};

export default PlanItTab;
