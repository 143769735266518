import React from 'react';
import { Typography, Grid, makeStyles, Box } from '@material-ui/core';
import { ContentSection, Image } from '../../../../store/reducers/content-page';
import { Dialog } from '@material-ui/core';
import PlayCircleOutlineRoundedIcon from '@material-ui/icons/PlayCircleOutlineRounded';
import { containerMaxWidth } from '../../../../themes/globalConstants';

const useStyles = makeStyles((theme) => ({
    container: {
        textAlign: 'center',
        marginBottom: '2em'
    },
    header: {
        fontWeight: 700
    },
    pointer: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    box: {
        margin: 0,
        padding: 0
    },
    iframe: {
        margin: 0,
        padding: 0,
        border: 0
    },
    playButton: {
        color: theme.palette.common.white,
        height: '8em',
        width: '8em',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer'
    },
    img: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            backgroundSize: 'contain'
        }
    },
    imageContainer: {
        display: 'inline-block',
        position: 'relative',
        maxWidth: containerMaxWidth,
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    }
}));

const SingleImageContentSection = (section: ContentSection) => {
    const classes = useStyles();
    const image = section.image as Image;
    const videoLink = section.links;
    const [open, setOpen] = React.useState(false);
    const hasVideo = !!(videoLink && videoLink[0]?.linkUrl);

    const openModal = () => {
        setOpen(true);
    };

    const closeModal = () => {
        setOpen(false);
    };

    return (
        <>
            <Grid container item spacing={3} className={classes.container}>
                {section.header && (
                    <Grid item xs={12}>
                        <Typography variant="h3">{section.header}</Typography>
                    </Grid>
                )}
                {section.description && (
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">{section.description}</Typography>
                    </Grid>
                )}
                <Grid item container xs={12} alignItems="center" justify="center">
                    {image && image.imageUrl && (
                        <div className={classes.imageContainer}>
                            <img
                                src={image.imageUrl}
                                alt={
                                    image.alternateText
                                        ? image.alternateText
                                        : section.header + ' image'
                                }
                                {...(hasVideo && {
                                    onClick: openModal,
                                    className: classes.pointer
                                })}
                                className={classes.img}
                            />
                            {hasVideo && (
                                <PlayCircleOutlineRoundedIcon
                                    onClick={openModal}
                                    className={classes.playButton}
                                />
                            )}
                        </div>
                    )}
                </Grid>
            </Grid>
            {videoLink && videoLink[0].linkUrl && (
                <Dialog open={open} onClose={closeModal} maxWidth="md">
                    <Box className={classes.box}>
                        <iframe
                            title={section.header}
                            src={videoLink[0].linkUrl}
                            width="640"
                            height="360"
                            allow="autoplay; fullscreen"
                            className={classes.iframe}
                        ></iframe>
                    </Box>
                </Dialog>
            )}
        </>
    );
};

export default SingleImageContentSection;
