import { TableRow, TableCell, Grid, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { PlanItBulkATMLineItem } from '../../../../store/reducers/planit-bulk-atm';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { blackWeight, regularWeight, small } from '../../../../themes/globalConstants';
import { formatNumberWithLocale } from '../../../../utility/helpers/formatters';
import clsx from 'clsx';
import { getGraphicIdAndVersion } from '../../../../utility/helpers/order-helpers';

interface Props {
    item: PlanItBulkATMLineItem;
}

const useStyles = makeStyles(() => ({
    tableRowContainer: {
        '& .helper-text': {
            fontWeight: `${regularWeight} !important`,
            fontSize: small
        }
    },
    bold: {
        fontWeight: blackWeight
    }
}));

const PlanItGuidedUploadConfirmationRow = ({ item }: Props) => {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    return (
        <>
            <TableRow
                className={classes.tableRowContainer}
                data-testid={`product-${item.displayId ?? item.productSku}`}
            >
                <TableCell data-testid="display-id">
                    <Grid container alignContent="center">
                        <Grid container>
                            <Typography>
                                <strong>{item.customerProductId ?? '-'}</strong>
                            </Typography>
                        </Grid>
                        <Grid container>
                            <Typography>
                                <strong>{item.productSku}</strong>
                            </Typography>
                        </Grid>
                        <Grid container>
                            <Typography>{item.graphicIdAndVersion || '-'}</Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell data-testid="product-description">
                    <Grid container alignContent="center">
                        <Grid container>
                            <Typography>{item.displayName ?? item.productName}</Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell data-testid="status">
                    <Typography className={classes.bold}>{item.productStatus}</Typography>
                </TableCell>
                <TableCell data-testid="coating">
                    <Grid container alignContent="center">
                        <Grid container>
                            <Typography>{item.coating ?? item.productCoating}</Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell data-testid="original-quantity">
                    <Grid container direction="column">
                        <Grid item>
                            <Typography
                                className={clsx(item.originalPalletQuantity && classes.bold)}
                            >
                                {formatNumberWithLocale(
                                    cultureCode,
                                    item.originalPalletQuantity ?? 0
                                )}{' '}
                                PL
                            </Typography>
                        </Grid>
                        {item.originalPalletQuantity !== undefined && (
                            <Grid item>
                                <Typography
                                    className="helper-text"
                                    data-testid="original-quantity-eaches"
                                >
                                    {formatNumberWithLocale(
                                        cultureCode,
                                        item.originalEachesQuantity ?? 0
                                    )}{' '}
                                    ea.
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </TableCell>
                <TableCell data-testid="pallet-quantity">
                    <Grid container alignContent="center">
                        <Grid container>
                            <Typography className={classes.bold}>
                                {!item.deleted && item.palletQuantity
                                    ? formatNumberWithLocale(cultureCode, item.palletQuantity)
                                    : 0}{' '}
                                PL
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography className="helper-text" data-testid="previous-quantity-eaches">
                            {formatNumberWithLocale(
                                cultureCode,
                                item.deleted ? 0 : item.eachesQuantity ?? 0
                            )}
                            {' ea.'}
                        </Typography>
                    </Grid>
                </TableCell>
            </TableRow>
        </>
    );
};

export default PlanItGuidedUploadConfirmationRow;
