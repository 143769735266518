import { makeStyles, Grid, Typography } from '@material-ui/core';
import Modal from '../../../reusable/molecules/Modal';
import { useState, ReactNode, useEffect } from 'react';
import React from 'react';
import Button from '../../../reusable/atoms/Button';
import { brightGrey, boldWeight, xl, large } from '../../../../themes/globalConstants';

interface Props {
    title: ReactNode;
    open: boolean;
    subheader?: ReactNode;
    continueText?: ReactNode;
    saveProgress?: () => void;
}

const useStyles = makeStyles({
    modal: {
        '& .MuiPaper-root': {
            maxWidth: '40.5em'
        },
        '& .MuiDialog-paperScrollPaper': {
            borderRadius: 'unset',
            overflow: 'hidden'
        },
        '& .MuiDialogTitle-root': {
            justifyContent: 'left'
        }
    },
    content: {
        textAlign: 'center',
        letterSpacing: '1px',
        color: brightGrey
    },
    textBuffer: {
        marginTop: '2em'
    },
    subsection: {
        fontSize: xl,
        fontWeight: boldWeight,
        letterSpacing: '0.13px',
        padding: '0 1.875em',
        textAlign: 'left'
    },
    button: {
        height: '2.5em',
        marginTop: '1.875em',
        marginBottom: '3.75em',
        fontSize: large,
        fontWeight: 'normal',
        textTransform: 'capitalize',
        borderRadius: 'unset'
    },
    buttonContainer: {
        marginTop: '1em'
    },
    contentWrapper: {
        width: '100%'
    }
});

const RequestSentModal = (props: Props) => {
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(props.open);

    const closeConfirmationModal = () => {
        setOpen(false);
    };

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    return (
        <>
            <Modal
                open={open}
                close={closeConfirmationModal}
                title={props.title}
                closeIcon={false}
                fullWidth={true}
                className={classes.modal}
                disableClosing={true}
            >
                {
                    <Grid container className={classes.content}>
                        <Grid item className={classes.contentWrapper} data-testid="confirm-modal">
                            {props.subheader && (
                                <Grid item className={classes.textBuffer}>
                                    <Typography
                                        className={classes.subsection}
                                        data-testid="subheader"
                                    >
                                        {props.subheader}
                                    </Typography>
                                </Grid>
                            )}
                            <Grid
                                container
                                item
                                xs={12}
                                className={classes.buttonContainer}
                                justify="center"
                            >
                                {props.continueText && (
                                    <Grid item xs={4}>
                                        <Button
                                            type="button"
                                            variant="contained"
                                            color="primary"
                                            data-testid="continue-btn"
                                            onClick={props.saveProgress}
                                            className={classes.button}
                                        >
                                            {props.continueText}
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Modal>
        </>
    );
};

export default RequestSentModal;
