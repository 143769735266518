import React from 'react';

const Star = ({ fill, stroke, className }) => (
    <svg
        fill={fill}
        stroke={stroke}
        strokeWidth="2px"
        className={className}
        height="50px"
        width="50px"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 53.867 53.867"
        xmlSpace="preserve"
    >
        <polygon
            points="26.934,1.318 35.256,18.182 53.867,20.887 40.4,34.013 43.579,52.549 26.934,43.798 
	10.288,52.549 13.467,34.013 0,20.887 18.611,18.182 "
        />
    </svg>
);

export default Star;
