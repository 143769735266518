import React from 'react';
import Modal from '../../reusable/molecules/Modal';
import { PlanItSummaryState } from '../../../store/reducers/plan-it-summary';
import PlanItTab from '../../pages/PlanItSummary/components/PlanItTab';
import { PlanItSummaryProvider } from '../../pages/PlanItSummary/context/PlanItSummaryContext';
import {
    Toolbar,
    Grid,
    Button,
    CircularProgress,
    makeStyles,
    Typography,
    Link
} from '@material-ui/core';
import { Trans } from 'react-i18next';
import { ProductionOrder } from '../../../store/reducers/makeit-dashboard';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { large, boldWeight } from '../../../themes/globalConstants';
import { useHistory } from 'react-router';

interface Props {
    openModal: boolean;
    onClose: () => void;
    onSubmit: () => void;
    dataLoaded: boolean;
    error: boolean;
    title: string;
    forecastOrders: ProductionOrder[];
}

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        padding: '1.5em'
    },
    modalButton: {
        height: 'auto',
        marginTop: '1.875em',
        marginBottom: '1.875em',
        fontSize: large,
        fontWeight: 'normal',
        textTransform: 'capitalize',
        borderRadius: 'unset',
        maxWidth: '85%',
        minWidth: '75%'
    },
    text: {
        marginTop: '0.625em',
        fontSize: '1rem',
        fontWeight: boldWeight,
        textAlign: 'left'
    },
    link: {
        fontWeight: boldWeight,
        fontSize: '1rem',
        marginRight: '0.25em',
        marginTop: '-0.25em'
    }
}));

export default function CopyToMakeItModal({
    openModal,
    onClose,
    dataLoaded,
    error,
    title,
    forecastOrders,
    onSubmit
}: Props) {
    const classes = useStyles();
    const history = useHistory();
    const { copyToMakeItOrders } = useTypedSelector<PlanItSummaryState>(
        (state) => state.planItSummary
    );

    const handlePlanItLinkClick = () => {
        history.push('/plan-it');
    };

    const modalFooterActions = (
        <Toolbar variant="regular">
            <Grid container item xs={12} alignItems="center" justify="flex-end">
                <Grid container item xs={2} justify="flex-end">
                    <Button
                        type="button"
                        variant="outlined"
                        color="secondary"
                        data-testid="cancel-btn"
                        className={classes.modalButton}
                        onClick={onClose}
                    >
                        <Trans i18nKey="cancel">Cancel</Trans>
                    </Button>
                </Grid>
                <Grid container item xs={2} justify="flex-end">
                    <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        data-testid="submit-order-btn"
                        className={classes.modalButton}
                        disabled={copyToMakeItOrders.length === 0}
                        onClick={onSubmit}
                    >
                        <Trans i18nKey="ok">Ok</Trans>
                    </Button>
                </Grid>
            </Grid>
        </Toolbar>
    );

    return (
        <Modal open={openModal} title={title} close={onClose} closeIcon={true} maxWidth={'md'}>
            {dataLoaded && (
                <PlanItSummaryProvider value={{ activeTab: 'forecasts' }}>
                    {forecastOrders.length > 0 ? (
                        <Grid container xs={12} className={classes.tableWrapper}>
                            <PlanItTab orders={forecastOrders} hideShareLinks={true} />
                        </Grid>
                    ) : (
                        <Grid container className={classes.tableWrapper}>
                            <Grid item xs={12}>
                                <Typography className={classes.text}>
                                    <Trans i18nKey="noEligiblePlanItRecords">
                                        No eligible Plan It records exists for the current location.
                                    </Trans>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.text}>
                                    <Link
                                        component="button"
                                        underline="none"
                                        onClick={handlePlanItLinkClick}
                                        className={classes.link}
                                        data-testid="plan-it-link"
                                    >
                                        <Trans i18nKey="visitPlanIt">Visit Plan It</Trans>
                                    </Link>
                                    <Trans i18nKey="toCreateOne">to create one.</Trans>
                                </Typography>
                            </Grid>
                        </Grid>
                    )}

                    {modalFooterActions}
                </PlanItSummaryProvider>
            )}
            {(!dataLoaded || error) && <CircularProgress />}
        </Modal>
    );
}
