import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    makeStyles,
    Typography,
    Grid,
    Paper,
    List,
    TextField,
    Toolbar,
    AppBar,
    RadioGroup
} from '@material-ui/core';
import {
    Account,
    AddressType,
    DeliverToAccount,
    SelectableAccount
} from '../../../store/reducers/customer-context';
import { useTranslation, Trans } from 'react-i18next';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import {
    selectCustomerAccount,
    getUserCustomerAccounts,
    setLastBillToId,
    setLastShipToId
} from '../../../store/actions/customer-context';
import { useDispatch } from 'react-redux';
import { Activity, Persona } from '../../../utility/auth/useSecurity';
import { white, large, medium, small, ballBlue } from '../../../themes/globalConstants';
import Link from '../atoms/Link';
import Modal from './Modal';
import Button from '../atoms/Button';
import AccountFilter from './AccountFilter';
import AccountListItem from './AccountListItem';
import { AuthState } from '../../../store/reducers/auth';
import AccountSelectionNameAddressDisplay from './AccountSelectionNameAddressDisplay';
import { loadState } from '../../../utility/helpers/sessionStorage';
import { ContractSummaryState } from '../../../store/reducers/contract-summary';
import { getPersonaAccounts } from '../../../utility/helpers/user-helpers';
import DeliverToAccounts from './DeliverToAccounts';
import OtherShipToAccounts from './OtherShipToAccounts';

interface Props {
    filter?: Activity;
    disableSelect?: boolean;
    selectionType?: 'billTo' | 'shipTo' | 'account';
}

const useStyles = makeStyles((theme) => ({
    singleSideText: {
        letterSpacing: 0.2,
        fontSize: medium,
        lineHeight: '1.2em'
    },
    multiplesSideText: {
        marginTop: '0.4em',
        letterSpacing: 0.2,
        fontSize: medium,
        lineHeight: '1.2em'
    },
    main: {
        backgroundColor: white,
        minWidth: '24em',
        minHeight: '4.6em',
        borderRadius: 2,
        cursor: 'pointer',
        padding: '0 0.5em'
    },
    modalContainer: {
        maxWidth: '40em',
        height: '25em',
        overflowY: 'scroll',
        overflowX: 'hidden'
    },
    address: {
        fontSize: small
    },
    links: {
        paddingBottom: '0.5em',
        fontSize: large
    },
    indicator: {
        width: '1em',
        height: '1em',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0.25em 0 0.5em 0.5em',
        padding: '0.25em 0.25em',
        backgroundColor: ballBlue,
        color: white
    },
    accountContainer: {
        marginTop: '2em',
        width: '35em',
        margin: 'auto'
    },
    AccountText: {
        marginLeft: '2em',
        fontSize: medium
    },
    searchField: {
        marginBottom: '0.5em'
    },
    list: {
        marginTop: '1.5em',
        minHeight: '20em'
    },
    btnContainer: {
        width: '30em',
        margin: 'auto',
        height: '3em'
    },
    btn: {
        width: '10em'
    },
    appBar: {
        top: 'auto',
        bottom: 0,
        width: '100%'
    },
    toolBar: {
        backgroundColor: white
    },
    textPadding: {
        padding: 6
    }
}));

export default function AccountSelection({ disableSelect, selectionType = 'account' }: Props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { accounts, selectedAccountId, lastShipToIdSelected, lastBillToIdSelected } =
        useTypedSelector<CustomerContextState>((state) => state.customerContext);
    const { requiresAcknowledgement } = useTypedSelector<ContractSummaryState>(
        (state) => state.contractSummary
    );
    const auth = useTypedSelector<AuthState>((state) => state.auth);
    const [open, setOpen] = useState<boolean>(false);
    const [radioValue, setRadioValue] = useState<string>('');
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [readOnly, setReadOnly] = useState<boolean>(false);
    const [selectedAccount, setSelectedAccount] = useState<Account | undefined>(undefined);
    const [sortedAccounts, setSortedAccounts] = useState<Account[]>([]);
    const [deliverToAccounts, setDeliverToAccounts] = useState<DeliverToAccount[]>([]);
    const [otherAccounts, setOtherAccounts] = useState<Account[]>([]);
    const { t } = useTranslation();

    const translationValues = useMemo(() => {
        if (selectionType === 'shipTo') {
            return {
                title: <Trans i18nKey="shipTo">ShipTo</Trans>,
                selection: <Trans i18nKey="selectShipTo">Select Ship To</Trans>,
                modalTitle: t('selectShipTo', 'Select Ship To'),
                modalCounter: <Trans i18nKey="shiptos">Ship-tos</Trans>,
                modalSearch: t('searchForShipTo', 'Search for Ship-to')
            };
        }
        if (selectionType === 'billTo') {
            return {
                title: <Trans i18nKey="billTo">Bill To</Trans>,
                selection: <Trans i18nKey="selectBillTo">Select Bill To</Trans>,
                modalTitle: t('selectBillTo', 'Select Bill To'),
                modalCounter: <Trans i18nKey="billTos">Bill-Tos</Trans>,
                modalSearch: t('searchForBillTo', 'Search for Bill-to')
            };
        }
        return {
            title: <Trans i18nKey="account">Account</Trans>,
            selection: <Trans i18nKey="selectAccount">Select Account</Trans>,
            modalTitle: t('selectAccount', 'Select Account'),
            modalCounter: <Trans i18nKey="accounts">Accounts</Trans>,
            modalSearch: t('searchForAccount', 'Search for Account')
        };
    }, [selectionType, t]);

    const copackerAccounts: string[] = useMemo(
        () =>
            getPersonaAccounts(auth.permissions ? auth.permissions : [], Persona.CoPacker).sort(
                (a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' })
            ),
        [auth]
    );

    //This changes the value based on radio selection
    const handleAccountChange = useCallback(
        (account: Account) => {
            setSearchQuery('');
            setRadioValue(account.accountId);

            const billToAddressTypes = [
                AddressType.CB,
                AddressType.CX,
                AddressType.CP,
                AddressType.CG
            ];
            const shipToAddressTypes = [AddressType.CS, AddressType.CX];
            if (selectedAccount) {
                billToAddressTypes.includes(selectedAccount.addressType) &&
                    dispatch(setLastBillToId(selectedAccount.accountId));
                shipToAddressTypes.includes(selectedAccount.addressType) &&
                    dispatch(setLastShipToId(selectedAccount.accountId));
            }

            dispatch(selectCustomerAccount(account.accountId));
        },
        [dispatch, selectedAccount]
    );

    //This changes the value based on radio selection/Select btn
    const handleSelect = useCallback(() => {
        if (accounts && accounts.length && radioValue) {
            const selectedAccount = accounts.find((account) => account.accountId === radioValue);
            if (selectedAccount) handleAccountChange(selectedAccount);
        }
        setOpen(false);
    }, [accounts, handleAccountChange, radioValue]);

    useEffect(() => {
        if (auth.permissions && !accounts?.length) {
            dispatch(getUserCustomerAccounts());
        }
    }, [dispatch, accounts, auth.permissions]);

    /** Load the list of Accounts to show in the modal list */
    useEffect(() => {
        if (accounts && accounts.length) {
            const filteredAccounts = accounts.filter((account) => {
                if (selectionType === 'billTo') {
                    return [
                        AddressType.CB,
                        AddressType.CX,
                        AddressType.CP,
                        AddressType.CG
                    ].includes(account.addressType);
                }
                if (selectionType === 'shipTo') {
                    return [AddressType.CS, AddressType.CX].includes(account.addressType);
                }
                return [
                    AddressType.CB,
                    AddressType.CX,
                    AddressType.CP,
                    AddressType.CG,
                    AddressType.CS
                ].includes(account.addressType);
            });
            setSortedAccounts(filteredAccounts.sort((a, b) => a.name.localeCompare(b.name)));
        }
    }, [accounts, selectionType]);

    /** Find the account information to show in the Selection */
    useEffect(() => {
        if (accounts && accounts.length && selectedAccountId) {
            const findAccount = accounts.find((account) => account.accountId === selectedAccountId);
            setSelectedAccount(findAccount);
        }
    }, [accounts, selectedAccountId]);

    /** If no account is selected, try to find one in the session storage or load the first account from permissions */
    useEffect(() => {
        if (!selectedAccountId && sortedAccounts && sortedAccounts.length) {
            const lastStoredSelectionId = loadState('selectedAccountId') as string;
            const accountFromLastStoredSelection = sortedAccounts.find(
                (account) => account.accountId === lastStoredSelectionId
            );
            handleAccountChange(accountFromLastStoredSelection || sortedAccounts[0]);
        }

        if (sortedAccounts && sortedAccounts.length === 1) setReadOnly(true);
    }, [sortedAccounts, handleAccountChange, selectedAccountId]);

    /** Change the Account to the lastSelected if the selection type is different than the actual selected account type */
    useEffect(() => {
        if (sortedAccounts && sortedAccounts.length && selectedAccount) {
            switch (selectionType) {
                case 'shipTo':
                    if (
                        [AddressType.CB, AddressType.CP, AddressType.CG].includes(
                            selectedAccount.addressType
                        )
                    ) {
                        let newSelection: Account | undefined;
                        if (lastShipToIdSelected) {
                            newSelection = sortedAccounts.find(
                                (account) => account.accountId === lastShipToIdSelected
                            );
                        } else {
                            newSelection = sortedAccounts[0];
                        }
                        if (newSelection) {
                            handleAccountChange(newSelection);
                        }
                    }
                    break;
                case 'billTo':
                    if (selectedAccount.addressType === AddressType.CS) {
                        let newSelection: Account | undefined;
                        if (lastBillToIdSelected) {
                            newSelection = sortedAccounts.find(
                                (account) => account.accountId === lastBillToIdSelected
                            );
                        } else {
                            newSelection = sortedAccounts[0];
                        }
                        if (newSelection) {
                            handleAccountChange(newSelection);
                        }
                    }
                    break;
                default:
                    break;
            }
        }
    }, [
        sortedAccounts,
        selectionType,
        selectedAccount,
        handleAccountChange,
        lastShipToIdSelected,
        lastBillToIdSelected
    ]);

    /** Logic to separate Copacker accounts from other accounts, only when ShipTo Selection */
    useEffect(() => {
        if (selectionType === 'shipTo' && sortedAccounts) {
            setOtherAccounts(
                sortedAccounts.filter((account) => !copackerAccounts.includes(account.accountId))
            );
            const copackerShipTos = sortedAccounts.filter((account) =>
                copackerAccounts.includes(account.accountId)
            );
            const deliverToAccountIds = copackerShipTos
                .map((account) => account.deliverToAccountId!)
                .filter((value, index, self) => self.indexOf(value) === index)
                .sort((a, b) =>
                    a.localeCompare(b, undefined, {
                        numeric: true,
                        sensitivity: 'base'
                    })
                );

            setDeliverToAccounts(
                deliverToAccountIds.map((deliverToAccountId) => {
                    const copackers = copackerShipTos.filter(
                        (account) => account.deliverToAccountId === deliverToAccountId
                    );
                    return {
                        deliverToAccountId,
                        copackers: copackers as SelectableAccount[],
                        address: copackers[0].address
                    };
                })
            );
        }
    }, [selectionType, sortedAccounts, copackerAccounts]);

    useTranslation();

    useEffect(() => {
        if (disableSelect) {
            setReadOnly(true);
        }
    }, [disableSelect]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        handleCancel();
    };

    const handleSearch = (query) => {
        setSearchQuery(query);
    };

    const bubbleRadioChange = (event) => {
        setRadioValue(event.currentTarget.value);
    };

    const handleCancel = () => {
        setOpen(false);
        setSearchQuery('');
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
            <Grid container item xs={11} direction="row" justify="flex-end">
                <Grid item className={classes.textPadding}>
                    <Typography
                        variant="subtitle2"
                        className={readOnly ? classes.singleSideText : classes.multiplesSideText}
                    >
                        {translationValues.title}
                    </Typography>
                </Grid>
                <Grid item>
                    {readOnly ? (
                        <AccountSelectionNameAddressDisplay
                            account={selectedAccount || null}
                            readOnly
                        />
                    ) : (
                        <Paper className={classes.main} onClick={handleOpen}>
                            {selectedAccount ? (
                                <AccountSelectionNameAddressDisplay account={selectedAccount} />
                            ) : null}
                            <Link
                                component="button"
                                underline="always"
                                className={classes.links}
                                data-testid="edit-account-links"
                                onClick={handleOpen}
                            >
                                {translationValues.selection}
                            </Link>
                        </Paper>
                    )}
                </Grid>
            </Grid>
            <Modal
                open={open}
                title={translationValues.modalTitle}
                close={handleClose}
                closeIcon={true}
                maxWidth="md"
            >
                <Grid container className={classes.modalContainer} data-testid="account-modal">
                    <Grid container className={classes.accountContainer}>
                        <Grid container item xs={6} justify="flex-start" alignItems="center">
                            <Typography variant="body2" className={classes.AccountText}>
                                {translationValues.modalCounter}
                            </Typography>
                            <Typography component="span" className={classes.indicator}>
                                {sortedAccounts.length || 0}
                            </Typography>
                        </Grid>
                        <Grid container item xs={6} justify="flex-end">
                            <TextField
                                variant="outlined"
                                onChange={(query) => handleSearch(query.target.value)}
                                placeholder={translationValues.modalSearch}
                                className={classes.searchField}
                                inputProps={{
                                    style: {
                                        padding: '0.5em',
                                        height: '1.5em',
                                        width: '12em'
                                    }
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <List className={classes.list}>
                                <RadioGroup
                                    name="account-radio"
                                    onChange={(value) => bubbleRadioChange(value)}
                                >
                                    {selectionType !== 'shipTo' &&
                                        sortedAccounts &&
                                        sortedAccounts.map((account, i) => (
                                            <AccountFilter
                                                data-testid={`account-filter${account.accountId}`}
                                                key={i}
                                                account={account as SelectableAccount}
                                                filterText={searchQuery}
                                                render={(account, words) => {
                                                    const warning: any = {};
                                                    if (
                                                        requiresAcknowledgement &&
                                                        requiresAcknowledgement.some(
                                                            ({ billToId }) =>
                                                                billToId === account.accountId
                                                        )
                                                    ) {
                                                        warning.showWarning = true;
                                                        warning.warningMessage = t(
                                                            'acknowledgementRequired',
                                                            'This Bill To has documents that require acknowledgment, please review.'
                                                        );
                                                    }
                                                    return (
                                                        <AccountListItem
                                                            account={account}
                                                            selected={
                                                                account.accountId === radioValue ??
                                                                undefined
                                                            }
                                                            {...warning}
                                                        />
                                                    );
                                                }}
                                                setVisibility={() => {}}
                                            />
                                        ))}
                                    {selectionType === 'shipTo' && (
                                        <>
                                            {!!deliverToAccounts.length && (
                                                <DeliverToAccounts
                                                    deliverToAccounts={deliverToAccounts}
                                                    filterText={searchQuery}
                                                    selectedAccountId={radioValue}
                                                />
                                            )}
                                            {!!otherAccounts.length && (
                                                <OtherShipToAccounts
                                                    accounts={otherAccounts as SelectableAccount[]}
                                                    filterText={searchQuery}
                                                    selectedAccountId={radioValue}
                                                    showHeader={!!deliverToAccounts.length}
                                                />
                                            )}
                                        </>
                                    )}
                                </RadioGroup>
                            </List>
                        </Grid>
                    </Grid>
                    <AppBar className={classes.appBar} position="sticky" elevation={10}>
                        <Toolbar className={classes.toolBar} variant="regular">
                            <Grid
                                container
                                spacing={2}
                                className={classes.btnContainer}
                                alignItems="center"
                                justify="flex-end"
                            >
                                <Grid container item justify="flex-end" xs={3}>
                                    <Button
                                        type="button"
                                        variant="outlined"
                                        color="secondary"
                                        data-testid="cancel-btn"
                                        onClick={handleCancel}
                                        className={classes.btn}
                                    >
                                        <Trans i18nKey="cancel">Cancel</Trans>
                                    </Button>
                                </Grid>
                                <Grid container item justify="flex-start" xs={3}>
                                    <Button
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        data-testid="select-btn"
                                        onClick={handleSelect}
                                        className={classes.btn}
                                    >
                                        <Trans i18nKey="select">Select</Trans>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                </Grid>
            </Modal>
        </Grid>
    );
}
