import React, { ReactNode } from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { black, boldWeight, errorBackground, shipRed } from '../../../themes/globalConstants';
import { Alert } from '@material-ui/lab';
import clsx from 'clsx';

interface Props {
    errorMessage: ReactNode;
    showError: boolean;
    dataTestId?: String;
    removeMargin?: boolean;
    children?: ReactNode;
    textCenter?: boolean;
}

const useStyles = makeStyles((theme) => ({
    errorCenter: {
        justifyContent: 'center !important',
    },
    error: {
        marginTop: '1em',
        width: '95%',
        border: `1px solid ${shipRed}`,
        borderRadius: '.125em',
        justifyContent: 'left',
        '& .MuiAlert-icon': {
            color: shipRed,
            display: 'flex',
            alignItems: 'start',
            padding: 0
        },
        '& .MuiAlert-message': {
            padding: 0,
            '& .MuiTypography-root': {
                color: black,
                fontWeight: boldWeight
            }
        },
        backgroundColor: errorBackground
    },
    errorWithoutMargin: {
        width: '95%',
        border: `1px solid ${shipRed}`,
        borderRadius: '.125em',
        justifyContent: 'left',
        '& .MuiAlert-icon': {
            color: shipRed,
            display: 'flex',
            alignItems: 'start'
        },
        backgroundColor: errorBackground
    }
}));

export default function ErrorAlert({
    errorMessage,
    showError,
    dataTestId,
    removeMargin = false,
    textCenter = false,
    children
}: Props) {
    const classes = useStyles();
    const errorIcon = (
        <img src={process.env.PUBLIC_URL + '/assets/Error_Icon.svg'} alt="Error Icon" />
    );

    return (
        <>
            {showError && (
                <Grid container item justify="center" xs={12}>
                    <Alert
                        icon={errorIcon}
                        severity="error"
                        className={clsx({
                            [classes.errorCenter]: textCenter,
                            [classes.error]: !removeMargin,
                            [classes.errorWithoutMargin]: removeMargin,
                        })}
                        data-testid={dataTestId ?? ''}
                    >
                        <Typography>{errorMessage}</Typography>
                        {children}
                    </Alert>
                </Grid>
            )}
        </>
    );
}
