import { Button, Container, Grid, Hidden, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
    ballGray,
    blackWeight,
    ltBlueGrey,
    ltBlueGrey_8,
    medium,
    xl,
    xxl
} from '../../../themes/globalConstants';
import { Activity } from '../../../utility/auth/useSecurity';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import clsx from 'clsx';
import moment from 'moment';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { ShipToLocationState } from '../../../store/reducers/ship-to-locations';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { submitShipToLocationAdd } from '../../../store/actions/ship-to-locations';
import ShipToAddSectionsNav from './components/ShipToAddSectionsNav';
import ShipToAddFormData from './components/ShipToAddFormData';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '3em'
    },
    formSection: {
        marginBottom: '2.5em',
        padding: '4em',
        boxShadow: `0 0 6px ${ltBlueGrey_8}`,
        borderRadius: 4,
        '& .sectionHeader': {
            fontSize: xl,
            fontWeight: blackWeight,
            color: ballGray,
            paddingBottom: '0.1rem',
            borderBottom: `1px solid ${ltBlueGrey}`,
            marginBottom: '1rem',
            marginTop: '2rem'
        },
        palletSpecs: {
            paddingTop: '8px'
        },
        '& .noPadding': {
            padding: 0
        },
        '& .lastRow': {
            paddingBottom: 0
        }
    },
    actionBtn: {
        height: '3.375em',
        width: '13.75em',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom',
        transition: 'all linear 150ms'
    },
    editBtn: {
        height: '3.375em',
        width: '8em',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom',
        transition: 'all linear 150ms'
    },
    formSectionContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    formHeader: {
        marginBottom: '1em',
        marginTop: '1em'
    },
    formHeaderText: {
        color: ballGray,
        fontWeight: blackWeight,
        fontSize: xxl,
        marginBottom: 0
    },
    formHeaderDate: {
        color: ballGray,
        fontWeight: blackWeight,
        fontSize: medium,
        marginTop: '0.75em'
    }
}));

const ShipToLocationAddReview = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const { newShipToLocation, submitNewShipToLocationSubmissionState } =
        useTypedSelector<ShipToLocationState>((state) => state.shipToLocations);
    const [billToId, setBillToId] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    useEffect(() => {
        if (newShipToLocation) {
            setBillToId(newShipToLocation.billToID);
        }
    }, [newShipToLocation]);

    const handleSubmit = useCallback(() => {
        setIsSubmitting(true);
        dispatch(submitShipToLocationAdd(setIsSubmitting));
    }, [dispatch]);

    useEffect(() => {
        if (submitNewShipToLocationSubmissionState === 'success') {
            history.push('/add-ship-to-confirmation');
        }
    }, [history, submitNewShipToLocationSubmissionState]);

    const footerActions = (
        <>
            <Grid container item xs={3} justify="space-between">
                {/* Cancel and Save Progress will be implemented in future stories */}
                {/* <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="cancel-button"
                    onClick={() => {}}
                    className={classes.actionBtn}
                    disabled={true}
                >
                    <Trans i18nKey="cancel">Cancel</Trans>
                </Button>

                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="draft-button"
                    onClick={() => {}}
                    className={classes.actionBtn}
                    disabled={true}
                >
                    <Trans i18nKey="saveProgress">Save Progress</Trans>
                </Button> */}
            </Grid>

            <Grid container item xs={2}>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    data-testid="submit-button"
                    className={classes.actionBtn}
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                >
                    <Trans i18nKey="submit">Submit</Trans>
                </Button>
            </Grid>
        </>
    );

    const handleEdit = () => {
        history.push(`/add-ship-to/${billToId}?from=review`);
    };

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('requestNewShipTo', 'Request New Ship To'),
                description: t('reviewAndSubmit', 'Review And Submit'),
                thinBanner: true
            }}
            activity={Activity.AddShipTo}
            loading={false}
            actionFooter={{
                footerAction: footerActions,
                justify: 'space-between',
                sticky: true
            }}
        >
            <Grid container data-testid="add-shipto-page">
                <Container className={classes.container}>
                    <Grid container item xs={12}>
                        <Grid item sm={4}>
                            <Hidden xsDown>
                                <ShipToAddSectionsNav />
                            </Hidden>
                        </Grid>
                        <Grid container item xs={8} className={classes.formSectionContainer}>
                            <Grid item xs={12}>
                                {newShipToLocation && (
                                    <Paper className={clsx(classes.formSection)}>
                                        <Grid container item xs={12} className={classes.formHeader}>
                                            <Grid item xs={6}>
                                                <Typography className={classes.formHeaderText}>
                                                    <Trans i18nKey="reviewAndSubmit">
                                                        Review and Submit
                                                    </Trans>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography className={classes.formHeaderDate}>
                                                    {moment().format('MM/DD/YYYY')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Button
                                                    type="button"
                                                    variant="outlined"
                                                    color="primary"
                                                    data-testid="edit-button"
                                                    onClick={handleEdit}
                                                    className={classes.editBtn}
                                                >
                                                    <Trans i18nKey="edit">Edit</Trans>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <ShipToAddFormData newShipToLocation={newShipToLocation} />
                                    </Paper>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </ProcessingPageTemplate>
    );
};

export default ShipToLocationAddReview;
