import { Section } from '../../../../store/reducers/glp';
import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: '0.8em',
        textTransform: 'uppercase'
    },
    description: {
        marginBottom: '0.8em'
    },
    section: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '4em'
        }
    }
}));

const MissionStatement = ({ description, paragraph }: Section) => {
    const classes = useStyles();

    return description ? (
        <section className={classes.section}>
            <Typography variant="h3" component="h1" className={classes.description}>
                {description}
            </Typography>
            <Typography variant="body1">{paragraph}</Typography>
        </section>
    ) : null;
};

export default MissionStatement;
