import React, { useState, useEffect, ReactNode, Dispatch, SetStateAction } from 'react';
import clsx from 'clsx';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import { useTranslation, Trans } from 'react-i18next';
import { Grid, Paper, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    buildDeliveryTimes,
    setDateTimeToMidnight,
    getFullFormattedDateTime,
    getSimpleFormattedTime,
    getSimpleFormattedDate,
    addTimezoneOffset
} from '../../../../utility/helpers/date-helpers';
import {
    DeliveryTime,
    ProductToShip,
    DeliveryShipment
} from '../../../../store/reducers/shipping-dashboard';
import Link from '../../../reusable/atoms/Link';
import { customerPoIsValid, isProductEndType } from '../../../../utility/helpers/order-helpers';
import ShipItConfigTruck from '../../ShipItConfiguration/components/ShipItConfigTruck';
import EditShipmentsConfigGrid from './EditShipmentsConfigGrid';
import EditShipmentsConfigDateModal from './EditShipmentsConfigDateModal';
import { Alert } from '@material-ui/lab';
import {
    canUpdateDesiredPallets,
    getLoadIndexByLoadId,
    getReferenceLoad,
    editShipmentUseStyles,
    renderTimeSelectDisclaimer,
    renderTimeSelect,
    checkPONumbers,
    getNewDateTime,
    getPalletsPerTruck
} from '../../../../utility/helpers/shipment-helpers';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { selectIsCustomerPickup, selectIsLargeCustomerAccount } from '../../../../store/selectors';

interface Props {
    shipment: DeliveryShipment;
    onDateUpdate?: (newDateTime: string, currentShipment: DeliveryShipment) => void;
    onShowProductGrid: () => void;
    onUpdateShipment: (shipment: DeliveryShipment) => void;
    isUniqueDateTime: (time: string, id: number) => boolean;
    onUpdatePallets: (
        product: ProductToShip,
        numOfPallets: number,
        palletDifference: number
    ) => void;
    isValidShipment?: Dispatch<SetStateAction<boolean>>;
    deleteProductUpdatePallets?: (product: ProductToShip) => void;
    hasCreditHold?: boolean;
    initialShipmentState?: DeliveryShipment;
    productsToEdit?: ProductToShip[];
    editShipToId?: number;
    isLargeCustomer: boolean;
    isCopacker: boolean;
}

export default function EditShipmentsConfigShipment({
    shipment,
    onDateUpdate,
    onShowProductGrid,
    onUpdateShipment,
    onUpdatePallets,
    isUniqueDateTime,
    isValidShipment,
    deleteProductUpdatePallets,
    hasCreditHold,
    initialShipmentState,
    productsToEdit,
    editShipToId,
    isLargeCustomer,
    isCopacker
}: Props) {
    const classes = editShipmentUseStyles();
    const { t } = useTranslation();

    const [isCanType, setIsCanType] = useState<boolean>(true);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [warningText, setWarningText] = useState<ReactNode>([]);
    const [showChangeWarning, setShowChangeWarning] = useState<boolean>(false);
    const isCustomerPickup = useTypedSelector<boolean>(selectIsCustomerPickup);
    const [calendarDate, setCalendarDate] = useState<string>('');
    const [modalDeliveryDate, setModalDeliveryDate] = useState<moment.Moment | null>(null);
    const [firstAvailableDate, setFirstAvailableDate] = useState<moment.Moment | null>(null);
    const [firstSelectableDateTime, setFirstSelectableDateTime] = useState<moment.Moment | null>(
        null
    );
    const [shipmentDeliveryDateAndTime, setShipmentDeliveryDateAndTime] = useState<string>();
    const [selectedTime, setSelectedTime] = useState<string>();
    const [originalShipmentDateAndTime, setOriginalShipmentDateAndTime] = useState<string>();
    const [currentShipment, setCurrentShipment] = useState<DeliveryShipment>({});
    const [deliveryTimeList, setDeliveryTimeList] = useState<DeliveryTime[]>([]);
    const [openDaypickerModal, setOpenDaypickerModal] = useState<boolean>(false);
    const [leadTime] = useState<number>(1);
    const [showDateTimeError, setShowDateTimeError] = useState<boolean>(false);
    const [dateTimeWarningText, setDateTimeWarningText] = useState([] as React.ReactNode);
    const [shipToId, setShipToId] = useState<number>();
    const [showAddProductButton, setShowAddProductButton] = useState<boolean>(false);
    const [availBalanceWarning, setAvailBalanceWarning] = useState<boolean | undefined>(undefined);

    const isLargeCustomerAccount = useTypedSelector<boolean>(selectIsLargeCustomerAccount);

    const maxPallets = getPalletsPerTruck(isLargeCustomer, isCopacker, isLargeCustomerAccount);

    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );

    const errorIcon = (
        <img src={process.env.PUBLIC_URL + '/assets/Error_Icon.svg'} alt="Error Icon" />
    );

    const allPoNumbersAreValid = () => {
        const somePoNumberIsInvalid = currentShipment?.loads
            ?.map((load) => load.purchaseOrderNumber)
            .some((poNumber) => !customerPoIsValid(poNumber ?? '', true));

        return !somePoNumberIsInvalid;
    };

    const isShipmentValid = () => {
        const validPONumberCheck = checkPONumbers(isLargeCustomer, currentShipment);
        const palletCountNotZero = currentShipment.palletCount !== 0;
        const hasShipmentDeliveryDateAndTime = !!shipmentDeliveryDateAndTime;
        const hasShipmentLoads = !!currentShipment?.loads?.length;
        const hasPalletCount = !!currentShipment.palletCount;
        let loadsWithoutPallets: ProductToShip[] = [];

        if (currentShipment.loads) {
            loadsWithoutPallets = currentShipment.loads.filter((load) => load.palletQuantity === 0);
        }

        let isValid =
            hasShipmentLoads &&
            hasPalletCount &&
            validPONumberCheck &&
            palletCountNotZero &&
            hasShipmentDeliveryDateAndTime &&
            !loadsWithoutPallets.length &&
            allPoNumbersAreValid();

        isValidShipment && isValidShipment(isValid);
    };

    const handleChangeTime = (event: React.ChangeEvent<{ value: unknown }>) => {
        setTime(String(event?.target?.value));
    };

    const setTime = (dateString: string) => {
        if (!dateString) return;

        const dateToCompare = `${getSimpleFormattedDate('', calendarDate)} ${getSimpleFormattedTime(
            '',
            dateString
        )}`;

        if (
            shipment.shipmentId &&
            isUniqueDateTime &&
            isUniqueDateTime(dateToCompare, shipment.shipmentId)
        ) {
            clearDateTimeError();
            const selectedTime = moment(dateString).format('HH:mm:ss');
            const selectedDate = moment(calendarDate).format('YYYY-MM-DDT');

            const newDateTime = getNewDateTime(selectedTime, selectedDate);
            setSelectedTime(selectedTime);
            setShipmentDeliveryDateAndTime(newDateTime);
            onDateUpdate && onDateUpdate(newDateTime, currentShipment);
        } else {
            setShipmentDeliveryDateAndTime(undefined);
            renderTimeSelect(
                classes,
                shipmentDeliveryDateAndTime,
                selectedTime,
                ExpandMoreIcon,
                handleChangeTime,
                deliveryTimeList
            );
            displayDateTimeError(
                t(
                    'deliveryOrderDayTimeError',
                    'Shipments on the same delivery order cannot have duplicate dates and times. Please modify the time chosen.'
                )
            );
        }
    };

    const clearDateTimeError = () => {
        setDateTimeWarningText(false);
        setShowDateTimeError(false);
    };

    const displayDateTimeError = (label: React.ReactNode) => {
        setDateTimeWarningText(label);
        setShowDateTimeError(true);
    };

    const handleRemoveProductFromShipment = (product: ProductToShip) => {
        let currentProduct = { ...product, palletsOrdered: 0 };
        let items = [...currentShipment.loads!];
        let itemIndex = items.findIndex((item) => item.editLoadId === currentProduct.editLoadId);

        if (deleteProductUpdatePallets) {
            deleteProductUpdatePallets(product);
        }

        if (itemIndex !== -1) {
            items.splice(itemIndex, 1);
            let totPallets = 0;
            let totEaches = 0;
            items?.map((load, index) => {
                const pallets = load.palletQuantity;
                const eaches = load.quantityPerPallet && pallets * load.quantityPerPallet;
                if (pallets && eaches) {
                    totPallets = totPallets + pallets;
                    totEaches += eaches;
                }
                if (load.productSku !== product.productSku) {
                    load.sequence = index + 1;
                }
                if (load.availablePallets !== undefined) {
                    load.availablePallets += product.palletQuantity;
                }
                return null;
            });

            setCurrentShipment({
                ...currentShipment,
                loads: items,
                palletCount: totPallets,
                shipmentQuantity: totPallets,
                shipmentQuantityEaches: totEaches
            });
            onUpdateShipment({
                ...currentShipment,
                loads: items,
                palletCount: totPallets,
                shipmentQuantity: totPallets,
                shipmentQuantityEaches: totEaches
            });
        }
    };

    const getTotalOrderedPallets = (product: ProductToShip, tempShipment: DeliveryShipment) => {
        let totalOrderedPallets = 0;

        tempShipment.loads?.map((load) => {
            if (load.editLoadId !== product.editLoadId) {
                totalOrderedPallets += load.palletQuantity;
            } else {
                totalOrderedPallets = totalOrderedPallets + product.palletQuantity;
            }
            return null;
        });

        return totalOrderedPallets;
    };

    const getTotalOrderedEaches = (product: ProductToShip, tempShipment: DeliveryShipment) => {
        let totalOrderedEaches = 0;

        tempShipment.loads?.map((load) => {
            let loadEaches = load.quantityPerPallet && load.palletQuantity * load.quantityPerPallet;
            let productEaches =
                product.quantityPerPallet && load.palletQuantity * product.quantityPerPallet;
            if (load.editLoadId !== product.editLoadId && loadEaches) {
                totalOrderedEaches += loadEaches;
            } else if (productEaches) {
                totalOrderedEaches += productEaches;
            }
            return null;
        });

        return totalOrderedEaches;
    };

    const checkPalletsWithCreditHold = (product: ProductToShip) => {
        let tempShipment = { ...currentShipment };

        if (initialShipmentState) {
            if (tempShipment && tempShipment.loads && initialShipmentState.loads) {
                const loadIndex = getLoadIndexByLoadId(product, tempShipment.loads);
                const referenceLoad = initialShipmentState.loads[loadIndex];

                //If attempted pallet increase, show warning
                if (product.palletQuantity > referenceLoad.palletQuantity) {
                    displayWarning(
                        t(
                            'creditHoldBlockPalletIncrease',
                            'There is a credit hold on your account.  You cannot increase the pallets on this order.'
                        )
                    );
                } else {
                    //Allow same or decreased pallet qty
                    checkPallets(product);
                }
            }
        } else {
            //If no initialShipmentState prop, short circuit and go directly to checkPallets method
            checkPallets(product);
        }
    };

    const checkPallets = (product: ProductToShip, canUpdatePallets?: boolean) => {
        let tempShipment = { ...currentShipment };
        let tempLoad = tempShipment.loads?.find((item) => item.editLoadId === product.editLoadId);
        let totalOrderedPallets = getTotalOrderedPallets(product, tempShipment);
        let totalOrderedEaches = getTotalOrderedEaches(product, tempShipment);
        const isEndType = isProductEndType(product.type);
        const loadIndex = tempShipment.loads && getLoadIndexByLoadId(product, tempShipment.loads);
        //If we have productsToEdit prop, use that as a reference for calculations
        //This allows us to compare input pallets to actual avail pallets, not just displayed avail pallets
        const referenceLoad = productsToEdit && getReferenceLoad(product, productsToEdit);
        const trueAvailPallets = referenceLoad
            ? referenceLoad.availablePallets
            : product.availablePallets;
        setAvailBalanceWarning(undefined);

        if (
            (totalOrderedPallets <= maxPallets || isEndType || isCustomerPickup) &&
            trueAvailPallets !== undefined
        ) {
            if (product.palletQuantity <= trueAvailPallets || canUpdatePallets) {
                clearWarning();
                // if editing, want to keep loadid so that we can properly track "edit" status
                if (tempLoad && tempLoad.loadId && tempLoad.editLoadId) {
                    product.loadId = tempLoad.loadId;
                    product.editLoadId = tempLoad.editLoadId;
                }
                tempShipment.loads?.splice(loadIndex as number, 1, product);
                setCurrentShipment({
                    ...tempShipment,
                    loads: tempShipment.loads,
                    palletCount: totalOrderedPallets,
                    shipmentQuantity: totalOrderedPallets,
                    shipmentQuantityEaches: totalOrderedEaches
                });
                onUpdateShipment({
                    ...tempShipment,
                    loads: tempShipment.loads,
                    palletCount: totalOrderedPallets,
                    shipmentQuantity: totalOrderedPallets,
                    shipmentQuantityEaches: totalOrderedEaches
                });

                if (product.palletQuantity <= 0) {
                    // If product pallet quantity is less than available pallets, but is zero, display warning
                    product.availablePallets = product.availablePallets! - product.palletQuantity;
                    tempShipment.loads?.splice(loadIndex as number, 1, product);
                    setCurrentShipment({
                        ...tempShipment,
                        loads: tempShipment.loads
                    });
                    onUpdateShipment({
                        ...tempShipment,
                        loads: tempShipment.loads
                    });
                    displayWarning(
                        t(
                            'palletQtyNotZero',
                            'Pallet quantity should not be 0.  Please update the quantity or remove the product before saving.'
                        )
                    );
                }
            } else {
                setAvailBalanceWarning(true);
                setCurrentShipment({
                    ...currentShipment
                });
                displayWarning(
                    t(
                        'notEnoughRemainingPallets',
                        'Maximum number of pallets exceeded for a product. Please update the quantities within the shipment before saving.'
                    )
                );
            }
        } else if (totalOrderedPallets > maxPallets && !isEndType) {
            setCurrentShipment({
                ...currentShipment
            });
            displayWarning(t('maximumNumberOfPalletsWarning', { truckPalletLimit: maxPallets }));
        }
    };

    const handleUpdateProduct = (product: ProductToShip, palletDifference?: number) => {
        const showInvalidPoNumberWarning = !allPoNumbersAreValid();
        const poWarningText = t(
            'poNumberWarning',
            'Please enter a Customer PO Number with no special characters.'
        );

        if (showInvalidPoNumberWarning) displayWarning(poWarningText);
        else if (warningText === poWarningText) clearWarning();

        if (hasCreditHold) {
            checkPalletsWithCreditHold(product);
        } else {
            if (palletDifference) {
                const canUpdatePallets = canUpdateDesiredPallets(product, palletDifference);
                checkPallets(product, canUpdatePallets);
            } else {
                let tempShipment = { ...currentShipment };
                let tempLoad = tempShipment.loads?.find(
                    (item) => item.editLoadId === product.editLoadId
                );
                const loadIndex =
                    tempShipment.loads && getLoadIndexByLoadId(product, tempShipment.loads);

                if (!showInvalidPoNumberWarning) clearWarning();
                // if editing, want to keep loadid so that we can properly track "edit" status
                if (tempLoad && tempLoad.loadId && tempLoad.editLoadId) {
                    product.loadId = tempLoad.loadId;
                    product.editLoadId = tempLoad.editLoadId;
                }
                tempShipment.loads?.splice(loadIndex as number, 1, product);

                setCurrentShipment({
                    ...tempShipment,
                    loads: tempShipment.loads
                });
                onUpdateShipment({
                    ...tempShipment,
                    loads: tempShipment.loads
                });
            }
        }
    };

    const clearWarning = () => {
        setWarningText(false);
        setShowWarning(false);
    };

    const displayWarning = (label: React.ReactNode) => {
        setWarningText(label);
        setShowWarning(true);
    };

    const handleDaypickerOpen = () => {
        setOpenDaypickerModal(true);
    };

    const handleDaypickerCancel = () => {
        setOpenDaypickerModal(false);

        //Reset date state
        const originalDeliveryDateFormatted =
            (shipment.deliveryDateTime && getSimpleFormattedDate('', shipment.deliveryDateTime)) ??
            '';
        setCalendarDate(originalDeliveryDateFormatted);

        const originalDeliveryDate = moment(shipment.deliveryDateTime);
        setModalDeliveryDate(originalDeliveryDate);
    };

    const handleDaypickerOk = () => {
        setOpenDaypickerModal(false);

        if (calendarDate && shipToId) {
            const dateToCompare = moment(calendarDate).format('MM/DD/YYYY hh:mm A');

            if (isUniqueDateTime && isUniqueDateTime(dateToCompare, shipment.shipmentId!)) {
                clearDateTimeError();
                let time = moment(calendarDate).format('hh:mm A');
                // Ensure time doesn't revert to before the first selectable dateTime
                if (moment(calendarDate).isBefore(firstSelectableDateTime)) {
                    const remainder = 30 - (firstSelectableDateTime.minute() % 30);
                    const timeRoundedUpToNextThirtyMinutes = firstSelectableDateTime
                        .clone()
                        .add(remainder, 'minutes');
                    time = timeRoundedUpToNextThirtyMinutes.format('LT');
                }

                const date = moment(calendarDate).format('YYYY-MM-DD');
                const newDateTime = getNewDateTime(time, date + ', ');
                setTime(newDateTime);
                setDeliveryTimeList([]);
                setShipmentDeliveryDateAndTime(newDateTime);
                onDateUpdate && onDateUpdate(newDateTime, currentShipment);
            } else {
                setShipmentDeliveryDateAndTime(undefined);
                displayDateTimeError(
                    t(
                        'deliveryOrderDayTimeError',
                        'Shipments on the same delivery order cannot have duplicate dates and times. Please modify the time chosen.'
                    )
                );
            }
        }
    };

    //Handles selection of day within daypicker
    const handleDateSelect = (newDate: moment.Moment | null) => {
        const originalDeliveryDate = shipment.deliveryDateTime && moment(shipment.deliveryDateTime);
        const updatedDeliveryDate =
            shipment.updatedDeliveryDate && moment(shipment.updatedDeliveryDate);
        const formattedDate = moment(newDate).format('MM/DD/YYYY');
        const beforeDeliveryDate =
            newDate?.isBefore(originalDeliveryDate) || newDate?.isBefore(updatedDeliveryDate);

        if (newDate) {
            if (beforeDeliveryDate) {
                setShowChangeWarning(true);
            } else {
                setShowChangeWarning(false);
            }
            setCalendarDate(formattedDate);
            setModalDeliveryDate(newDate);
        }
    };

    useEffect(() => {
        if (
            (currentShipment &&
                !isNaN(currentShipment.palletCount!) &&
                (currentShipment.palletCount as number) < maxPallets &&
                !hasCreditHold) ||
            (currentShipment.shipmentType === 'END' && !hasCreditHold) ||
            (isCustomerPickup && !hasCreditHold)
        ) {
            setShowAddProductButton(true);
        } else {
            setShowAddProductButton(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentShipment.palletCount, hasCreditHold, isCustomerPickup]);

    //Set shipToId to local state variable no matter which flow is linked
    useEffect(() => {
        if (shipToAccounts && editShipToId) {
            setShipToId(editShipToId);
        }
    }, [editShipToId, shipToAccounts]);

    //Calculate and set the first available delivery day for the daypicker
    useEffect(() => {
        const originalDeliveryDateTime = getFullFormattedDateTime('', shipment.deliveryDateTime);
        setOriginalShipmentDateAndTime(originalDeliveryDateTime);
        const transitTime = moment(shipment.deliveryDateTime).diff(
            moment(shipment.shipDateTime),
            'hours'
        );
        const today = moment();
        const tomorrowPlusTransitTime = today.add(24, 'hours').add(transitTime, 'hours');
        const adjustedTomorrowPlusTransitTime = moment(addTimezoneOffset(tomorrowPlusTransitTime));
        const firstAvailableDay = setDateTimeToMidnight(adjustedTomorrowPlusTransitTime);

        setFirstSelectableDateTime(adjustedTomorrowPlusTransitTime);
        setFirstAvailableDate(firstAvailableDay);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // On load, set delivery date and time to what's in the API data
    useEffect(() => {
        if (shipment.deliveryDateTime) {
            const deliveryDateTime = shipment.updatedDeliveryDate
                ? moment(shipment.updatedDeliveryDate)
                : moment(shipment.deliveryDateTime);

            setCalendarDate(getSimpleFormattedDate('', deliveryDateTime));
            setModalDeliveryDate(deliveryDateTime);
        }
    }, []);

    useEffect(() => {
        const isEndType = isProductEndType(shipment.shipmentType);
        isEndType ? setIsCanType(false) : setIsCanType(true);

        const updatedPalletCount = shipment.palletCount || shipment.shipmentQuantity;
        setCurrentShipment({
            ...shipment,
            palletCount: updatedPalletCount
        });
    }, [shipment]);

    useEffect(() => {
        if (shipmentDeliveryDateAndTime) {
            setDeliveryTimeList(
                buildDeliveryTimes(
                    moment(currentShipment.updatedDeliveryDate ?? currentShipment.deliveryDateTime),
                    firstSelectableDateTime
                )
            );
        } else if (!shipmentDeliveryDateAndTime && !showDateTimeError) {
            setDeliveryTimeList(
                buildDeliveryTimes(
                    moment(currentShipment.updatedDeliveryDate ?? currentShipment.deliveryDateTime),
                    firstSelectableDateTime
                )
            );
            setShipmentDeliveryDateAndTime(
                currentShipment.updatedDeliveryDate ?? currentShipment.deliveryDateTime
            );
            setSelectedTime(currentShipment.deliveryTime);
        }
        isShipmentValid();
    }, [currentShipment, shipmentDeliveryDateAndTime, firstSelectableDateTime]);

    useTranslation();

    return (
        <>
            <Paper className={classes.main}>
                <Grid container>
                    <Grid container item md={6}>
                        <Grid item xs={12} className={classes.dateDescription}>
                            <Typography
                                className={clsx(
                                    classes.dateDescriptionMain,
                                    classes.altDescriptionColor
                                )}
                            >
                                <>
                                    <Trans i18nKey="deliveryDate">Delivery Date</Trans>:{' '}
                                </>

                                {calendarDate}
                            </Typography>
                            {
                                <Link
                                    component="button"
                                    underline="always"
                                    onClick={handleDaypickerOpen}
                                    className={classes.changeDayLink}
                                >
                                    <Trans i18nKey="changeDay">Change Day</Trans>
                                </Link>
                            }
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            alignItems="baseline"
                            className={classes.originallyScheduled}
                        >
                            <Typography>
                                <Trans i18nKey="ShipmentOriginallyScheduled">
                                    Shipment Originally scheduled
                                </Trans>
                                : {originalShipmentDateAndTime}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item md={6}>
                        <Grid
                            container
                            item
                            xs={12}
                            justify="flex-end"
                            className={classes.timeSelect}
                        >
                            {renderTimeSelect(
                                classes,
                                shipmentDeliveryDateAndTime,
                                selectedTime,
                                ExpandMoreIcon,
                                handleChangeTime,
                                deliveryTimeList
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            {renderTimeSelectDisclaimer(classes)}
                        </Grid>
                    </Grid>
                </Grid>
                {showDateTimeError && (
                    <Grid container justify="center">
                        <Alert
                            icon={errorIcon}
                            severity="warning"
                            className={classes.dataTimeError}
                        >
                            <Typography>{dateTimeWarningText}</Typography>
                        </Alert>
                    </Grid>
                )}
                {currentShipment.loads && currentShipment.loads.length ? (
                    <Grid container>
                        {isCanType && !isCustomerPickup ? (
                            <>
                                <ShipItConfigTruck
                                    items={currentShipment}
                                    isLargeCustomer={isLargeCustomer}
                                    truckPalletLimit={maxPallets}
                                />
                                <Grid container item>
                                    <EditShipmentsConfigGrid
                                        shipmentId={currentShipment.shipmentId?.toString()}
                                        items={currentShipment.loads}
                                        onUpdatePallets={onUpdatePallets}
                                        onRemoveItem={handleRemoveProductFromShipment}
                                        onUpdateItem={handleUpdateProduct}
                                        warning={showWarning}
                                        warningText={warningText}
                                        maxPallets={maxPallets}
                                        availBalanceWarning={availBalanceWarning}
                                    />
                                </Grid>
                            </>
                        ) : (
                            <Grid container item>
                                <EditShipmentsConfigGrid
                                    shipmentId={currentShipment.shipmentId?.toString()}
                                    items={currentShipment.loads}
                                    onUpdatePallets={onUpdatePallets}
                                    onRemoveItem={handleRemoveProductFromShipment}
                                    onUpdateItem={handleUpdateProduct}
                                    warning={showWarning}
                                    warningText={warningText}
                                    maxPallets={maxPallets}
                                    availBalanceWarning={availBalanceWarning}
                                />
                            </Grid>
                        )}
                    </Grid>
                ) : (
                    <Grid container className={classes.preview}>
                        <Typography className={classes.previewText}>
                            <Trans i18nKey="addDeliveryInstructions">
                                Add a product from the list you created and enter the quantities
                                here. The cart will update to display the remaining pallets to load,
                                if any.
                            </Trans>
                        </Typography>
                    </Grid>
                )}
                <Grid container justify="flex-end" className={classes.actionsBar}>
                    <Grid item className={classes.shipmentBtnContainer}>
                        <Link
                            underline="always"
                            data-testid="add-product-link"
                            className={`${classes.addProductLink} ${
                                showAddProductButton ? 'enabled' : ''
                            }`}
                            onClick={onShowProductGrid}
                            disabled={!showAddProductButton}
                        >
                            {'+ '}
                            <Trans i18nKey="addProduct">add product</Trans>
                        </Link>
                    </Grid>
                </Grid>
            </Paper>
            <EditShipmentsConfigDateModal
                open={openDaypickerModal}
                onCancel={handleDaypickerCancel}
                onOk={handleDaypickerOk}
                firstDay={firstAvailableDate}
                highlightedDay={modalDeliveryDate}
                leadTime={leadTime}
                showChangeWarning={showChangeWarning}
                selectDate={(newDate) => handleDateSelect(newDate)}
            />
        </>
    );
}
