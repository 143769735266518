import React, { useEffect, useState } from 'react';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import {
    boldWeight,
    blackWeight,
    ballBlue,
    dateOptionInvalidGray,
    small
} from '../../../../themes/globalConstants';
import TranslationService from '../../../../utility/services/translation-service';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { generateKey } from '../../../../utility/helpers/order-helpers';
import UserAdminPendingRegistrationRow from './UserAdminPendingRegistrationRow';
import UserAdminPendingRegistration from './UserAdminPendingRegistrationColumn';
import { getPendingRoles } from '../../../../utility/helpers/user-admin-helpers';
import {
    PendingRegistrationTableRow,
    UserDashboardState
} from '../../../../store/reducers/user-admin';
import { formatUTCToDateDisplay } from '../../../../utility/helpers/date-helpers';
import SingleActionModal from '../../../reusable/molecules/SingleActionModal';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { hasAdminPersonaForAccount } from '../../../../utility/auth/useSecurity';
import { AuthState } from '../../../../store/reducers/auth';
import { getAdminAccounts } from '../../../../utility/helpers/admin-helpers';
import { CustomerContextState } from '../../../../store/reducers/customer-context';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: '1em'
    },
    tableWrapper: {
        '& .MuiToolbar-root': {
            padding: 0,
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                },
                '&:first-of-type ': {
                    paddingLeft: 0
                }
            },
            '& > h2': {
                fontSize: '1.5em',
                fontWeight: boldWeight,
                color: theme.palette.secondary.main,
                paddingLeft: '0.625em'
            },
            '& .MuiInputBase-formControl': {
                border: `1px solid ${dateOptionInvalidGray}`,
                borderRadius: '.125em',
                '&:before': {
                    display: 'none'
                },
                '&:after': {
                    display: 'none'
                },
                '& svg': {
                    height: '.875em',
                    width: '.875em',
                    fill: ballBlue
                },
                '& .MuiIconButton-root': {
                    '&:hover': {
                        backgroundColor: 'transparent !important'
                    }
                },
                '& .MuiInput-input': {
                    paddingLeft: '.25em'
                }
            }
        },
        '& .MuiTableSortLabel-root': {
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em',
            width: '99%',
            justifyContent: 'space-between',
            '& .MuiSvgIcon-root': {
                fontSize: 14,
                opacity: '1'
            }
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                '&:nth-child(1)': {
                    width: '30%'
                },
                '&:nth-child(2)': {
                    width: '30%'
                },
                '&:nth-child(3)': {
                    width: '20%'
                },
                '&:nth-child(4)': {
                    width: '20%',
                    fontSize: small,
                    fontWeight: blackWeight,
                    textTransform: 'uppercase',
                    color: theme.palette.secondary.main,
                    borderRadius: '0.25em',
                    paddingLeft: '0.5em',
                    justifyContent: 'space-between'
                }
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    }
}));

export default function UserAdminPendingRegistrationTab() {
    const classes = useStyles();
    const history = useHistory();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { pendingRegistrations } = useTypedSelector<UserDashboardState>(
        (state) => state.userAdminDashboard
    );
    const [columns, setColumns] = useState<any>([]);
    const [requestSubmittedModalOpen, setRequestSubmittedModalOpen] = useState<boolean>(false);
    const [formattedPendingRegistrations, setFormattedPendingRegistrations] = useState<
        PendingRegistrationTableRow[] | null
    >(null);
    const [userIsAdmin, setUserIsAdmin] = useState<boolean>(false);
    const { accounts, selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);

    const options = new ToolbarOptions({
        title: TranslationService.getTranslatedText(cultureCode, 'pendingRegistration'),
        advancePagination: false,
        exportButton: false,
        printButton: false,
        searchText: true
    });

    const dashboardLink = '/dashboard';

    const handleRequestSubmittedModalOpen = () => {
        setRequestSubmittedModalOpen(true);
    };

    const handleRequestSubmittedModalClose = () => {
        setRequestSubmittedModalOpen(false);
    };

    const onDiscardChanges = () => {
        history.push(dashboardLink);
    };

    useEffect(() => {
        if (permissions && selectedAccountId) {
            setUserIsAdmin(hasAdminPersonaForAccount(permissions, selectedAccountId));
        }
    }, [permissions, selectedAccountId]);

    useEffect(() => {
        if (accounts && permissions && pendingRegistrations && selectedAccountId) {
            const adminAccounts = getAdminAccounts(permissions, accounts);

            const rows: PendingRegistrationTableRow[] = [];
            pendingRegistrations
                .filter((pendingRegistration) =>
                    pendingRegistration.permissions.some(
                        (permission) => permission.accountId === selectedAccountId
                    )
                )
                .forEach((registration) => {
                    let formattedUser: PendingRegistrationTableRow = {
                        userName: registration.userName,
                        roles: getPendingRoles(registration.permissions, cultureCode),
                        createDate: formatUTCToDateDisplay(registration.createDate),
                        registrationId: registration.registrationId,
                        currentUserHasAdminPermission: adminAccounts.some((account) =>
                            registration.permissions.some(
                                (permission) => permission.accountId === account.accountId
                            )
                        )
                    };
                    rows.push(formattedUser);
                });
            setFormattedPendingRegistrations(rows);
        }
    }, [accounts, permissions, pendingRegistrations, cultureCode, selectedAccountId]);

    useEffect(() => {
        setColumns(UserAdminPendingRegistration.getUserAdminPendingColumns(cultureCode));
    }, [cultureCode]);

    const PendingRowComponent = ({ row }: { row: Partial<PendingRegistrationTableRow> }) => {
        return (
            <UserAdminPendingRegistrationRow
                pendingRegistration={row as PendingRegistrationTableRow}
                handleRequestSubmittedSuccess={handleRequestSubmittedModalOpen}
                hasAdminPersona={userIsAdmin}
            />
        );
    };

    return (
        <Paper elevation={2} className={classes.paper}>
            <Grid className={classes.tableWrapper} key={generateKey('user-admin-pending-tab')}>
                {formattedPendingRegistrations && (
                    <DataGrid
                        gridName="User Admin Pending Orders"
                        columns={columns}
                        dataSource={formattedPendingRegistrations}
                        rowComponent={PendingRowComponent}
                        toolbarOptions={options}
                    />
                )}
            </Grid>
            <SingleActionModal
                title={<Trans i18nKey="confirmation">Confirmation</Trans>}
                open={requestSubmittedModalOpen}
                subheader={
                    <Trans i18nKey="invitationSent">
                        Your invitation to join <i>The Source</i> has been sent.
                    </Trans>
                }
                actionButtonText={<Trans i18nKey="myDashboard">My Dashboard</Trans>}
                onAction={onDiscardChanges}
                onClose={handleRequestSubmittedModalClose}
            />
        </Paper>
    );
}
