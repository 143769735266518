import {
    LIQUID_INTAKE_SUMMARY_LOADING,
    LIQUID_INTAKE_SUMMARY_LOAD_COMPLETED,
    LIQUID_INTAKE_SUMMARY_LOAD_ERROR,
    LIQUID_INTAKE_SUMMARY_LOAD_OPEN
} from './action-types';
import { Account, AddressType } from '../reducers/customer-context';
import LiquidTestService from '../../utility/services/liquid-test-service';
import { IntakeStatus } from '../reducers/liquid-intake';
import moment from 'moment';
import { isPastDateWithin90Days } from '../../utility/helpers/date-helpers';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsights } from '../../utility/AppInsights';

const filterBillTos = (accounts: Account[]) => {
    let filteredAccountIds: string[] = [];

    let filteredAccountType = accounts.filter(
        (account) =>
            account.addressType === AddressType.CB ||
            account.addressType === AddressType.CX ||
            account.addressType === AddressType.CP ||
            account.addressType === AddressType.CG
    );

    filteredAccountType.forEach((account) => {
        filteredAccountIds.push(account.accountId);
    });

    return filteredAccountIds;
};

export const loadLiquidTests = (accounts: Account[], cultureCode: string) => {
    return (dispatch, getState) => {
        dispatch({ type: LIQUID_INTAKE_SUMMARY_LOADING });
        const { accessToken } = getState().auth;
        let filteredAccounts = filterBillTos(accounts);

        LiquidTestService.getLiquidTestsByBillTo({ billTos: filteredAccounts }, accessToken)
            .then((response) => {
                let allLiquidTests = response.data.intakes;

                allLiquidTests.forEach((liquidTest) => {
                    liquidTest.liquidTestId = liquidTest.id;
                });

                let openLiquidTests = allLiquidTests.filter(
                    (test) => test.status === IntakeStatus.ARS || test.status === IntakeStatus.RPT
                );
                let closedLiquidTests = allLiquidTests.filter(
                    (test) =>
                        (test.status === IntakeStatus.WRA ||
                            test.status === IntakeStatus.WRR ||
                            test.status === IntakeStatus.WAQ ||
                            test.status === IntakeStatus.NTR) &&
                        isPastDateWithin90Days(moment(test.createDate))
                );

                closedLiquidTests.forEach((closedIntake) => {
                    if (!closedIntake.statusUpdatedDateTime) {
                        closedIntake.statusUpdatedDateTime = closedIntake.createDate;
                    }
                });
                dispatch({ type: LIQUID_INTAKE_SUMMARY_LOAD_OPEN, liquidTests: openLiquidTests });
                dispatch({
                    type: LIQUID_INTAKE_SUMMARY_LOAD_COMPLETED,
                    liquidTests: closedLiquidTests
                });
            })
            .catch((error) => {
                const user = getState().auth?.userInfo?.email;
                if (appInsights && user) {
                    const appInsightsError = error.message + ' getLiquidTestsByBillTo-' + user;
                    appInsights.trackException({
                        error: new Error(appInsightsError),
                        severityLevel: SeverityLevel.Error
                    });
                }
                dispatch({
                    type: LIQUID_INTAKE_SUMMARY_LOAD_ERROR
                });
            });
    };
};
