import {
    PRODUCTION_ORDER_LOADING,
    PRODUCTION_ORDER_LOADED,
    PRODUCTION_ORDER_LOADING_ERROR,
    PRODUCTION_ORDER_DELIVERY_ORDER_LOADING,
    PRODUCTION_ORDER_DELIVERY_ORDER_LOADED,
    PRODUCTION_ORDER_DELIVERY_ORDER_LOADING_ERROR,
    PRODUCTION_ORDER_RESET_STATE
} from './action-types';
import { MakeItProductsRequest } from '../reducers/makeit-dashboard';
import {
    createProductRequestObj,
    getMakeItMatchingProductInformation,
    getMoqFee
} from '../../utility/helpers/production-order-helpers';
import { AuthState } from '../reducers/auth';
import OrdersService from '../../utility/services/orders-service';
import ProductService from '../../utility/services/product-service';
import { getTotalQuantityOrdered } from './make-it-summary';

export const resetProductionOrderState = () => {
    return (dispatch) => {
        dispatch({
            type: PRODUCTION_ORDER_RESET_STATE,
            productionOrder: null,
            linkedDeliveryOrder: null
        });
    };
};

export const loadProductionOrder = (productionOrderId: number) => {
    return async (dispatch, getState) => {
        dispatch({ type: PRODUCTION_ORDER_LOADING });
        const auth: AuthState = getState().auth;

        // Query production order from order-api
        const productionOrderResponse: any = await OrdersService.getProductionOrder(
            getState(),
            productionOrderId
        ).catch((error) => {
            dispatch({ type: PRODUCTION_ORDER_LOADING_ERROR, error });
            return;
        });

        if (productionOrderResponse) {
            const order = productionOrderResponse.data;
            const makeItProductRequest: MakeItProductsRequest = { ShipToIds: [order.shipToId] };

            // Query order-api for make-it products with this order's ship-to...
            // ...this will provide additional product data to be added to the order
            const makeItProductsResponse: any = await OrdersService.getMakeItProducts(
                auth.accessToken,
                makeItProductRequest
            ).catch((error) => {
                dispatch({ type: PRODUCTION_ORDER_LOADING_ERROR, error });
                return;
            });

            const products = makeItProductsResponse?.data?.products;
            const productRequest = {
                ...createProductRequestObj(products, [], [`${order.shipToId}`]),
                accountId: `${order.shipToId}`
            };

            // Query product-api for products found in the above order-api query...
            // ...this will provide full product details including minimum-order-quantity fees
            const multipleProductsResponse: any = await ProductService.getMultipleProducts(
                getState,
                productRequest
            ).catch((error) => {
                dispatch({ type: PRODUCTION_ORDER_LOADING_ERROR, error });
                return;
            });

            const multipleProductsResponseData = multipleProductsResponse?.data?.products;
            if (multipleProductsResponseData) {
                // Loop over products from order-api to find matches and add make-it product details
                products.forEach((product) => {
                    getMakeItMatchingProductInformation(
                        multipleProductsResponseData,
                        product,
                        product.size,
                        product.type,
                        product.shape
                    );
                });
            }

            // Create map of products by SKU for quick access
            // Calculate MOQ fees based on quantities of each product
            const productMap: Map<string, any> = new Map();
            let totalFees = 0;
            products.forEach((product) => {
                if (product.productSku) {
                    const fee = getMoqFee(getTotalQuantityOrdered(product, order), product.moqFees);
                    totalFees += fee ?? 0;
                    productMap.set(product.productSku, { ...product, fee });
                }
            });
            order.totalFees = totalFees;

            // Apply full product details and fees for each line item...
            order.lines.forEach((line) => {
                if (line.productSku) {
                    const product = productMap.get(line.productSku);
                    if (product) {
                        line.product = product;
                        line.quantityPerPallet = product.quantityPerPallet;
                        line.assessedFee = product.fee;
                    }
                }

                line.displayId = line.customerProductId ? line.customerProductId : line.productSku;
                line.displayName = line.customerProductName
                    ? line.customerProductName
                    : line.productName;
            });

            dispatch({
                type: PRODUCTION_ORDER_LOADED,
                order
            });
        }
    };
};

export const loadLinkedDeliveryOrders = (productionOrderId: number) => {
    return (dispatch, getState) => {
        dispatch({ type: PRODUCTION_ORDER_DELIVERY_ORDER_LOADING });

        OrdersService.getProductionOrderLinkedDeliveryOrders(getState(), productionOrderId)
            .then((deliveryOrder) => {
                const deliveryOrderData = deliveryOrder.data;
                dispatch({
                    type: PRODUCTION_ORDER_DELIVERY_ORDER_LOADED,
                    linkedDeliveryOrder: deliveryOrderData
                });
            })
            .catch((error) => {
                dispatch({
                    type: PRODUCTION_ORDER_DELIVERY_ORDER_LOADING_ERROR,
                    error
                });
            });
    };
};
