import React from 'react';
import {
    makeStyles,
    Grid,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Typography
} from '@material-ui/core';
import { Trans } from 'react-i18next';
import Image from '../../../reusable/atoms/Image';
import { Product } from '../../../../store/reducers/product-portfolio';
import Link from '../../../reusable/atoms/Link';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import { getEndIds } from '../../../../utility/helpers/query-helpers';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import CurrentLeadTime from '../../../reusable/atoms/CurrentLeadTime';
import { ballLtGray_3, boldWeight, aerosolProducts } from '../../../../themes/globalConstants';
import Button from '../../../reusable/atoms/Button';
import { Region } from '../../../../store/reducers/graphic-intake';
import clsx from 'clsx';
import { updateSelectedProduct } from '../../../../store/actions/product-portfolio';
import { useDispatch } from 'react-redux';

interface Props {
    details: Product;
    regionCode: string;
}

const useStyles = makeStyles((theme) => ({
    productImgContainer: {
        backgroundColor: ballLtGray_3,
        textAlign: 'center'
    },
    schematicImgContainer: {
        textAlign: 'center'
    },
    productImage: {
        height: '22em',
        backgroundSize: 'contain'
    },
    main: {
        [theme.breakpoints.down('xs')]: {
            marginBottom: '2em'
        }
    },
    labels: {
        fontSize: 11,
        fontWeight: boldWeight,
        color: theme.palette.secondary.main,
        textTransform: 'uppercase',
        letterSpacing: '1px'
    },
    capitalize: {
        textTransform: 'capitalize'
    },
    beverageText: {
        fontSize: 11
    },
    beverageIcon: {
        verticalAlign: 'middle'
    },
    contentCell: {
        borderLeft: '0px'
    },
    greatForIcon: {
        borderLeft: '0px',
        width: '50%'
    },
    contentMargin: {
        marginLeft: '8em'
    },
    downloadCell: {
        overflow: 'visible',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('sm')]: {
            width: '7em',
            padding: 0,
            margin: 0
        }
    },
    dataCell: {
        width: '50%'
    },
    link: {
        fontSize: 12
    },
    largerLinkText: {
        fontSize: 14
    },
    leftIcon: {
        marginLeft: 2,
        marginRight: '0.5em',
        fill: theme.palette.common.black,
        verticalAlign: 'bottom'
    },
    rightIcon: {
        marginLeft: 2,
        fill: theme.palette.common.black,
        verticalAlign: 'bottom'
    },
    buildButtonGrid: {
        paddingBottom: '1em'
    },
    buildButtonProductImageGrid: {
        marginTop: '-1em'
    },
    lowerTextWrapper: {
        marginTop: '1em'
    },
    textBlock: {
        marginBottom: '1.2em'
    }
}));

const ProductDetailsSidePanel = ({ details, regionCode }: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const hasBeverages = details.beverages && details.beverages.length > 0;
    const isNA = regionCode === Region.NA;
    const isSA = regionCode === Region.SA;
    const isEU = regionCode === Region.EU;

    const sixteenOzAlumitek = 'ATK_BTL_16_473_NA';

    const wineIcon = (
        <img
            className={classes.beverageIcon}
            src={process.env.PUBLIC_URL + '/assets/Wine_Icon.svg'}
            alt="Wine Icon"
        />
    );

    const isWine =
        isNA &&
        details.beverages?.some((bev) => bev.featureCode === 'WINE' && bev.recommended === true);
    const beverages = details.beverages
        ?.filter((result) => result.recommended)
        .map((bev) => bev.label)
        .join(', ');
    const compatibleEnds = details?.ends && details.ends?.map((end) => end.label).join(', ');
    const hasSchematicImage = details?.content?.schematicCanImage !== undefined;

    const handleEstimate = () => {
        dispatch(updateSelectedProduct(details));
        history.push('/compare-and-build');
    };

    return (
        <Grid item xs={12} sm={5} data-testid="product-lead-info">
            <Grid
                item
                className={
                    hasSchematicImage ? classes.schematicImgContainer : classes.productImgContainer
                }
            >
                <Image
                    src={details.content?.schematicCanImage || details.content?.canImage || ''} // this needs to remain in this order for hasSchematicImage to work as expected
                    alt="product image"
                    className={classes.productImage}
                />
                {!aerosolProducts.includes(details.productCode) && (
                    <Grid
                        className={clsx(classes.buildButtonGrid, {
                            [classes.buildButtonProductImageGrid]: !hasSchematicImage && !isSA
                        })}
                    >
                        <Button
                            type="button"
                            color="primary"
                            variant="outlined"
                            onClick={handleEstimate}
                        >
                            <Trans i18nKey={isNA ? 'buildAndEstimateNav' : 'build'}>
                                Build & Estimate
                            </Trans>
                        </Button>
                    </Grid>
                )}
            </Grid>
            <Grid item className={classes.main}>
                <Table size="medium" aria-label="product image table">
                    <TableBody>
                        {!aerosolProducts.includes(details.productCode) && (
                            <TableRow>
                                <TableCell className={classes.downloadCell}>
                                    <Typography className={classes.labels}>
                                        <Trans i18nKey="download">Download</Trans>
                                        {' /'}
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.contentCell}>
                                    <Grid
                                        container
                                        className={isNA && !isWine ? classes.contentMargin : ''}
                                    >
                                        {isNA && (
                                            <Grid item xs={12}>
                                                <Link
                                                    data-testid="can-spec-link-na"
                                                    className={classes.link}
                                                    underline="always"
                                                    url={
                                                        details.content?.canSpecificationDocument ||
                                                        ''
                                                    }
                                                    target="_blank"
                                                >
                                                    <Trans i18nKey="canSpecification">
                                                        Can Specification
                                                    </Trans>
                                                </Link>
                                                <PictureAsPdfOutlinedIcon
                                                    className={classes.leftIcon}
                                                />
                                            </Grid>
                                        )}

                                        {isNA && (
                                            <Grid item xs={12}>
                                                <Link
                                                    data-testid="dieline-doc-link-na"
                                                    className={classes.link}
                                                    underline="always"
                                                    url={details.content?.dielineDocument || ''}
                                                    target="_blank"
                                                >
                                                    <Trans i18nKey="dieLine">Die Line</Trans>
                                                </Link>
                                                <PictureAsPdfOutlinedIcon
                                                    className={classes.leftIcon}
                                                />
                                            </Grid>
                                        )}
                                        {isNA && details.content?.studioGuideDocument && (
                                            <Grid item xs={12}>
                                                <Link
                                                    data-testid="studio-guide-link-na"
                                                    className={classes.link}
                                                    underline="always"
                                                    url={details.content?.studioGuideDocument}
                                                    target="_blank"
                                                >
                                                    <Trans i18nKey="palletSpecifications">
                                                        Pallet Specifications
                                                    </Trans>
                                                </Link>
                                                <PictureAsPdfOutlinedIcon
                                                    className={classes.rightIcon}
                                                />
                                            </Grid>
                                        )}
                                        {isSA && (
                                            <Grid item>
                                                <Link
                                                    data-testid="can-spec-link-sa"
                                                    className={classes.link}
                                                    underline="always"
                                                    url={
                                                        details.content?.canSpecificationDocument ||
                                                        ''
                                                    }
                                                    target="_blank"
                                                >
                                                    <Trans i18nKey="canSpecification">
                                                        Can Specification
                                                    </Trans>
                                                </Link>
                                                <PictureAsPdfOutlinedIcon
                                                    className={classes.leftIcon}
                                                />
                                            </Grid>
                                        )}
                                        {isSA && (
                                            <Grid item>
                                                <Link
                                                    data-testid="dieline-doc-link-sa"
                                                    className={classes.link}
                                                    underline="always"
                                                    url={details.content?.dielineDocument || ''}
                                                    target="_blank"
                                                >
                                                    <Trans i18nKey="printGuide">Print Guide</Trans>
                                                </Link>
                                                <PictureAsPdfOutlinedIcon
                                                    className={classes.rightIcon}
                                                />
                                            </Grid>
                                        )}
                                        {isEU && (
                                            <>
                                                <Grid item>
                                                    <Link
                                                        data-testid="dieline-doc-link-eu"
                                                        className={classes.link}
                                                        underline="always"
                                                        url={details.content?.dielineDocument || ''}
                                                        target="_blank"
                                                    >
                                                        <Trans i18nKey="customerDesignGuide">
                                                            Customer Design Guide
                                                        </Trans>
                                                    </Link>
                                                    <PictureAsPdfOutlinedIcon
                                                        className={classes.leftIcon}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Link
                                                        data-testid="studio-guide-doc-eu"
                                                        className={classes.link}
                                                        underline="always"
                                                        url={
                                                            details.content?.studioGuideDocument ||
                                                            ''
                                                        }
                                                        target="_blank"
                                                    >
                                                        <Trans i18nKey="reproStudioGrid">
                                                            Repro Studio Grid
                                                        </Trans>
                                                    </Link>
                                                    <PictureAsPdfOutlinedIcon
                                                        className={classes.rightIcon}
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        )}
                        {hasBeverages && isNA ? (
                            <TableRow>
                                <TableCell>
                                    <Typography className={classes.labels}>
                                        <Trans i18nKey="greatFor">Great For</Trans> /
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.greatForIcon}>
                                    <Typography variant="body1">
                                        {isWine ? wineIcon : null}{' '}
                                        <Trans i18nKey="beverageCategoryWine">Wine</Trans>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ) : hasBeverages && isSA ? (
                            <TableRow>
                                <TableCell>
                                    <Typography className={classes.labels}>
                                        <Trans i18nKey="greatFor">Great For</Trans> /
                                    </Typography>
                                </TableCell>
                                <TableCell align="left" className={classes.contentCell}>
                                    <Typography variant="body1">{beverages}</Typography>
                                </TableCell>
                            </TableRow>
                        ) : null}
                    </TableBody>
                </Table>
                <Table size="medium" aria-label="product details table">
                    <TableBody>
                        {(isNA || isEU) && details.currentLeadTime && (
                            <TableRow>
                                <TableCell colSpan={1} className={classes.dataCell}>
                                    <Typography variant="body1">
                                        <Trans i18nKey="currentLeadTime">Current Lead Time</Trans>
                                    </Typography>
                                </TableCell>
                                <TableCell align="left" colSpan={1}>
                                    <CurrentLeadTime
                                        leadTime={details.currentLeadTime}
                                        leadTimeColorOverride={details.leadTimeColorOverride}
                                    />
                                </TableCell>
                            </TableRow>
                        )}
                        {isNA && details.fillingAvailability && (
                            <TableRow>
                                <TableCell colSpan={1}>
                                    <Typography variant="body1">
                                        <Trans i18nKey="fillingAvailability">
                                            Filling Availability
                                        </Trans>
                                    </Typography>
                                </TableCell>
                                <TableCell align="left" colSpan={1} className={classes.capitalize}>
                                    {details.fillingAvailability}
                                </TableCell>
                            </TableRow>
                        )}
                        {isNA && details.ends && details.ends.length > 0 && (
                            <TableRow>
                                <TableCell colSpan={1}>
                                    <Link
                                        data-testid="compatible-ends-na"
                                        underline="always"
                                        className={classes.largerLinkText}
                                        to="/product-ends"
                                        queryContent={getEndIds(details?.ends)}
                                        component={RouterLink}
                                    >
                                        <Trans i18nKey="compatibleEnds">Compatible Ends</Trans>
                                    </Link>
                                </TableCell>
                                <TableCell align="left" colSpan={1}>
                                    {compatibleEnds}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                {regionCode === Region.NA && details.productCode === sixteenOzAlumitek && (
                    <Grid item xs={10} className={classes.lowerTextWrapper}>
                        <Typography variant="body1" className={classes.textBlock}>
                            <Trans i18nKey={'leadTimesMayVarySixteenOzAlumitek'}>
                                *This product is currently available. Lead times for receiving your
                                first order may vary. Talk with your Sales Rep for additional
                                details.
                            </Trans>
                        </Typography>
                    </Grid>
                )}
                {regionCode === Region.NA && details.productCode !== sixteenOzAlumitek && (
                    <Grid item xs={10} className={classes.lowerTextWrapper}>
                        {details.productCopy && (
                            <Typography variant="body1" className={classes.textBlock}>
                                {details.productCopy}
                            </Typography>
                        )}
                        <Typography variant="body1" className={classes.textBlock}>
                            <Trans i18nKey={'leadTimesSubjectToChange'}>
                                *All lead times are estimates and subject to change.
                            </Trans>
                        </Typography>
                        <Typography variant="body1" className={classes.textBlock}>
                            <strong>
                                <Trans i18nKey={'currentBallCustomers'}>
                                    Current Ball Customers:
                                </Trans>
                            </strong>{' '}
                            <Trans i18nKey={'leadTimesProduction'}>
                                Production lead times for new graphics begin when graphics are
                                complete and approved.
                            </Trans>
                        </Typography>
                        {aerosolProducts.includes(details.productCode) && (
                            <Typography variant="body1" className={classes.textBlock}>
                                <strong>
                                    <Trans i18nKey={'aerosolSalesNotice'}>
                                        For Sales Inquiries, Please Contact:
                                    </Trans>
                                </strong>
                                <div>
                                    <div>
                                        <Trans i18nKey={'aerosolSalesContact1'}>
                                            Carlos Garcia
                                        </Trans>
                                    </div>
                                    <div>
                                        <Trans i18nKey={'aerosolSalesContact2'}>
                                            Sr. Sales Manager
                                        </Trans>
                                    </div>
                                    <div>
                                        <Trans i18nKey={'aerosolSalesContact3'}>
                                            M: +52-444-188-61-29
                                        </Trans>
                                    </div>
                                    <div>
                                        <Trans i18nKey={'aerosolSalesContact4'}>
                                            cgarcia2@ball.com
                                        </Trans>
                                    </div>
                                </div>
                            </Typography>
                        )}
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export default ProductDetailsSidePanel;
