import { Culture } from '../../store/reducers/region-culture';
import TranslationService from '../services/translation-service';

export const getTradeLineStatus = (
    tradeLineStatus: string,
    cultureCode: Culture
): string | undefined => {
    switch (tradeLineStatus) {
        case 'SUBMIT':
            return TranslationService.getTranslatedText(cultureCode, 'submitted');
    }
};
