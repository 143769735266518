import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class ScrapItRequestDetailsColumns {
    static getScrapItRequestDetailsColumns = (culture: string) => {
        return [
            createColumn('productDescription', {
                dataType: ColumnDataType.String,
                filterable: true,
                isKey: true,
                label: `${TranslationService.getTranslatedText(
                    culture,
                    'productDescription'
                )} (Ball ${TranslationService.getTranslatedText(
                    culture,
                    'productId'
                )} / ${TranslationService.getTranslatedText(culture, 'graphicId')})`,
                sortable: true,
                searchable: true
            }),
            createColumn('productSku', {
                dataType: ColumnDataType.String,
                filterable: true,
                sortable: true,
                searchable: true,
                visible: false
            }),
            createColumn('graphicIdAndVersion', {
                dataType: ColumnDataType.String,
                filterable: true,
                sortable: true,
                searchable: true,
                visible: false
            }),
            createColumn('customerProductId', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'customerProductId'),
                filterable: true,
                sortable: true,
                searchable: true,
                visible: true
            }),
            createColumn('coating', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'coating'),
                filterable: true,
                sortable: true,
                searchable: true,
                visible: true
            }),
            createColumn('quantity', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'quantityToScrap'),
                filterable: false,
                sortable: true,
                searchable: false,
                visible: true
            }),
            createColumn('eachesQuantity', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'requestedQuantityToTrade'),
                filterable: false,
                sortable: true,
                searchable: false,
                visible: false
            }),
            createColumn('status', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'requestStatus'),
                filterable: true,
                sortable: true,
                searchable: true,
                visible: true
            })
        ];
    };
}
