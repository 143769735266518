import React, { ReactElement } from 'react';
import { Typography } from '@material-ui/core';

interface Props {
    message: string;
}

export default function Error({ message }: Props): ReactElement {
    return <Typography data-testid="error-message">{message}</Typography>;
}
