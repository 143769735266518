import { ReactElement, useEffect, useState } from 'react';
import React from 'react';
import { Account, SelectableAccount } from '../../../store/reducers/customer-context';
import stemmer from 'stemmer';

interface Props {
    filterText: string;
    account: SelectableAccount;
    render: (account: Account, searchWords: string[]) => ReactElement;
    setVisibility: (account: SelectableAccount, visible: boolean) => void;
}

export function matches(value: string, filterValues: string[]): boolean {
    const match = filterValues.map((filterValue) => `(?=.*${filterValue})`).join('');
    const regex = new RegExp(`^${match}`, 'i');

    return !!value.match(regex);
}

export function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}

export default function AccountFilter({ filterText, account, render, setVisibility }: Props) {
    const [hasMatch, setHasMatch] = useState(true);
    const [filteredAccount, setFilteredAccount] = useState(account);
    const [searchWords, setSearchWords] = useState<string[]>([]);

    const debouncedFilterText = useDebounce(filterText, 250);

    useEffect(() => {
        let accountMatches = false;

        if (!debouncedFilterText?.length) {
            accountMatches = true;
            setFilteredAccount(account);
            setSearchWords([]);
        }

        const precompiledRegex = /[.*+?^${}()|[\]\\]/g;
        let filterValues = debouncedFilterText.replace(precompiledRegex, '\\$&').split(' ');
        filterValues = filterValues.map((value) => stemmer(value));
        setSearchWords(filterValues);

        if (matches(account.name, filterValues) || matches(account.accountId, filterValues)) {
            accountMatches = true;
            setFilteredAccount(account);
        }

        if (!accountMatches && account.subAccounts) {
            const matchingSubAccounts = account.subAccounts.filter(
                (subAccount) =>
                    matches(subAccount.name, filterValues) || matches(subAccount.name, filterValues)
            );

            if (matchingSubAccounts.length) {
                const newFilteredAccount: SelectableAccount = {
                    ...account,
                    subAccounts: matchingSubAccounts
                };
                accountMatches = true;
                setFilteredAccount(newFilteredAccount);
            }
        }
        setVisibility(account, accountMatches);
        setHasMatch(accountMatches);
    }, [debouncedFilterText, account]);

    return <>{hasMatch && render(filteredAccount, searchWords)}</>;
}
