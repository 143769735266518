import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, CircularProgress, Grid, makeStyles, Paper } from '@material-ui/core';
import { Activity } from '../../../utility/auth/useSecurity';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import LocationInformation from './components/LocationInformation';
import { useTypedSelector } from '../../../store/reducers/reducer';
import AccountService from '../../../utility/services/account-service';
import { LocationRoleAccessTable } from '../../reusable/molecules/LocationRoleAccessTable/LocationRoleAccessTable';
import { ShipToRoleAccess } from '../../reusable/molecules/LocationRoleAccessTable/models/role-access.model';
import { Account } from '../../../store/reducers/customer-context';
import { RequestAccessState } from '../../../store/reducers/request-access';
import {
    clearRequestAccounts,
    requestUserPermissions,
    setRequestAccounts
} from '../../../store/root-actions';
import { useDispatch } from 'react-redux';
import {
    REQUEST_ACCESS_LOADING,
    REQUEST_ACCESS_LOADING_ERROR
} from '../../../store/actions/action-types';
import ConfirmationModal from '../../reusable/molecules/ConfirmationModal';
import { useHistory } from 'react-router-dom';
import {
    useLocationRoleAccess,
    withLocationRoleAccessProvider
} from '../../reusable/molecules/LocationRoleAccessTable/context/LocationRoleAccessContext';
import { updateLocationRoleAccessAccounts } from '../../reusable/molecules/LocationRoleAccessTable/context/actions';
import RequestSentModal from './components/RequestSentModal';
import {
    hasAnyPermissions,
    getPermissionChangeItems
} from '../../../utility/helpers/user-admin-helpers';
import { AuthState } from '../../../store/reducers/auth';

const useStyles = makeStyles((theme) => ({
    actionBtn: {
        height: '3.375em',
        width: '13.75em',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom'
    },
    paper: {
        marginBottom: '1em',
        padding: '3em 3em 3em 3em'
    },
    container: {
        paddingTop: '3em'
    },
    formSection: {
        marginBottom: '2.5em'
    },
    formSectionContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '2em',
        marginBottom: '0.5em'
    },
    locationTableWrapper: {
        marginBottom: '1.5em',
        paddingBottom: '1em'
    }
}));

function RequestAccess() {
    const { t } = useTranslation();
    const classes = useStyles();
    const state = useTypedSelector((state) => state);
    const { userInfo } = useTypedSelector<AuthState>((state) => state.auth);
    const dispatch = useDispatch();
    const [idErrors, setIdErrors] = useState<boolean[]>([]);
    const [locationsVerified, setLocationsVerified] = useState<boolean>(false);
    const { requestAccounts, loading, requestComment } = useTypedSelector<RequestAccessState>(
        (state) => state.requestAccess
    );
    const [cancelOpen, setCancelOpen] = useState<boolean>(false);
    const [requestSubmittedOpen, setRequestSubmittedOpen] = useState<boolean>(false);
    const [requestSubmittedErrorOpen, setRequestSubmittedErrorOpen] = useState<boolean>(false);
    const history = useHistory();
    const dashboardLink = '/dashboard';
    const [locationRoleAccessState, locationRoleAccessDispatch] = useLocationRoleAccess();
    const [permissionsEdited, setPermissionsEdited] = useState<boolean>(false);

    const onOpenCancelModal = () => {
        setCancelOpen(true);
    };
    const onCloseCancelModal = () => {
        setCancelOpen(false);
    };
    const onDiscardChanges = () => {
        history.push(dashboardLink);
    };
    const onSubmit = () => {
        const accounts = locationRoleAccessState.accounts;
        const permissions = getPermissionChangeItems(accounts);
        dispatch(
            requestUserPermissions(
                userInfo!.preferred_username,
                permissions,
                () => {
                    setRequestSubmittedOpen(true);
                },
                () => {
                    setRequestSubmittedErrorOpen(true);
                },
                requestComment
            )
        );
    };

    useEffect(() => {
        dispatch(clearRequestAccounts());
    }, []);
    useEffect(() => {
        updateLocationRoleAccessAccounts(requestAccounts, locationRoleAccessDispatch);
    }, [requestAccounts, locationRoleAccessDispatch]);

    useEffect(() => {
        setPermissionsEdited(hasAnyPermissions(locationRoleAccessState.accounts));
    }, [locationRoleAccessState]);

    const formatAccounts = (accounts: Account[]) => {
        let formattedAccounts: ShipToRoleAccess[] = [];
        accounts.forEach((account) => {
            // if an account was present before an edit persist any made changes
            const savedAccount = requestAccounts.find(
                (requestAccount) => requestAccount.accountId === account.accountId
            );
            if (savedAccount) {
                formattedAccounts.push(savedAccount);
            } else {
                formattedAccounts.push(new ShipToRoleAccess(account));
            }
        });
        dispatch(setRequestAccounts(formattedAccounts));
    };

    const handleVerifyIds = (accountIds: string[]) => {
        setIdErrors([]);
        dispatch({ type: REQUEST_ACCESS_LOADING });
        AccountService.getAccounts(state, false, accountIds).then((response) => {
            const validAccounts = response.data.accounts
                .filter(
                    (account: Account) =>
                        account.addressType === 'CX' ||
                        account.addressType === 'CB' ||
                        account.addressType === 'CS'
                )
                .map((account: Account) => account);
            const idErrors = accountIds.map(
                (id) => !validAccounts.find((account) => account.accountId === id)
            );
            if (idErrors.includes(true)) {
                setIdErrors(idErrors);
                setLocationsVerified(false);
                dispatch({ type: REQUEST_ACCESS_LOADING_ERROR });
            } else {
                formatAccounts(validAccounts);
                setLocationsVerified(true);
            }
        });
    };

    const handleEditLocations = () => {
        setLocationsVerified(false);
    };

    const getHeader = (): string => t('requestAccess', 'Request Access');

    const footerActions = (
        <>
            <Grid container item xs={6} justify="center">
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="bottom-cancel-button"
                    onClick={onOpenCancelModal}
                    className={classes.actionBtn}
                >
                    <Trans i18nKey="cancel">Cancel</Trans>
                </Button>
            </Grid>
            <Grid container item xs={6} justify="center">
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    data-testid="submit-button"
                    className={classes.actionBtn}
                    onClick={onSubmit}
                    disabled={!permissionsEdited}
                >
                    <Trans i18nKey="requestAccess">Request Access</Trans>
                </Button>
            </Grid>
        </>
    );

    return (
        <>
            <ProcessingPageTemplate
                banner={{
                    header: getHeader(),
                    description: '',
                    thinBanner: true,
                    displayDropdown: false
                }}
                actionFooter={{
                    footerAction: footerActions,
                    justify: 'space-between',
                    sticky: true
                }}
                activity={Activity.OpenAccess}
            >
                <Grid
                    container
                    justify="space-between"
                    data-testid="request-access-page"
                    className={classes.container}
                >
                    <Grid container item xs={12}>
                        <Grid container item xs={12} className={classes.formSectionContainer}>
                            <Grid item xs={12} className={classes.formSection}>
                                <LocationInformation
                                    handleVerifyIds={handleVerifyIds}
                                    errors={idErrors}
                                    locationsVerified={locationsVerified}
                                    handleEditLocations={handleEditLocations}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    {locationsVerified && requestAccounts.length && (
                        <Paper elevation={2} className={classes.paper}>
                            <Grid container className={classes.locationTableWrapper}>
                                <Grid container item xs={12} justify="flex-start">
                                    <LocationRoleAccessTable requestAccess={true} />
                                </Grid>
                            </Grid>
                        </Paper>
                    )}
                </Grid>
                {loading && (
                    <Grid container item xs={12} className={classes.spinningLoader}>
                        <CircularProgress />
                    </Grid>
                )}
            </ProcessingPageTemplate>
            <RequestSentModal
                title={<Trans i18nKey="accessRequest">Access request</Trans>}
                open={requestSubmittedOpen}
                subheader={
                    <Trans i18nKey="requestAccessSuccess">
                        Your access request has been successfully submitted and will be reviewed by
                        the corresponding User Administrator(s).
                    </Trans>
                }
                continueText={<Trans i18nKey="myDashboard">My Dashboard</Trans>}
                saveProgress={onDiscardChanges}
            />
            <RequestSentModal
                title={<Trans i18nKey="accessRequest">Access request</Trans>}
                open={requestSubmittedErrorOpen}
                subheader={
                    <Trans i18nKey="requestAccessError">
                        No administrator assigned for the requested account(s)
                    </Trans>
                }
                continueText={<Trans i18nKey="myDashboard">My Dashboard</Trans>}
                saveProgress={onDiscardChanges}
            />
            <ConfirmationModal
                title={<Trans i18nKey="cancel">Cancel</Trans>}
                open={cancelOpen}
                logo={false}
                subheader={
                    <Trans i18nKey="areYouSureDiscard">
                        Are you sure you want to discard any changes?
                    </Trans>
                }
                cancelText={<Trans i18nKey="no">No</Trans>}
                continueText={<Trans i18nKey="yesDiscard">Yes, Discard</Trans>}
                saveProgress={onDiscardChanges}
                onClose={onCloseCancelModal}
                onCancel={onCloseCancelModal}
                data-testid="discard-changes-modal"
            />
        </>
    );
}

export default withLocationRoleAccessProvider(RequestAccess);
