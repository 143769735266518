import React, { ReactNode } from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { lato, latoBold } from '../../../themes/globalConstants';

interface Props {
    title: string;
    children?: ReactNode;
}

const StandardPDF = ({ children, title }: Props) => {
    const classes = StyleSheet.create({
        page: {
            padding: 50,
            fontFamily: lato,
            display: 'flex',
            paddingBottom: 60
        },
        pageHeader: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid #B9D3DC;'
        },
        footer: {
            position: 'absolute',
            bottom: '30px',
            color: 'black',
            fontSize: '8px',
            fontFamily: latoBold,
            textAlign: 'center',
            width: '100%',
            left: 50,
            textTransform: 'uppercase'
        }
    });
    const ballLogo = process.env.PUBLIC_URL + '/assets/Ball_RGB.jpg';
    const sourceLogo = process.env.PUBLIC_URL + '/assets/TheSource_Logo.png';
    return (
        <Document title={title}>
            <Page size="A4" style={classes.page} orientation={'portrait'}>
                <View style={[classes.pageHeader]}>
                    <Image
                        src={sourceLogo}
                        style={{ height: '32px', width: 'auto' }}
                        data-testid="source-logo"
                    />
                    <Image src={ballLogo} style={{ height: '20px', width: 'auto' }} />
                </View>

                {children}
                <View style={[classes.footer]} fixed>
                    <Text
                        render={({ pageNumber, totalPages }) =>
                            `PAGE ${pageNumber} OF ${totalPages}`
                        }
                        fixed
                    />
                </View>
            </Page>
        </Document>
    );
};

export default StandardPDF;
