import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class ManageProductsColumns {
    static getColumns = (culture: string, isLargeCustomerAccount: boolean) => {
        return [
            createColumn('productSku', {
                dataType: ColumnDataType.String,
                label: `${TranslationService.getTranslatedText(
                    culture,
                    'ballProductId'
                )} / ${TranslationService.getTranslatedText(culture, 'graphicId')}`,
                searchable: true,
                sortable: true,
                visible: false
            }),
            createColumn('productId', {
                dataType: ColumnDataType.Numeric,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('displayId', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'customerProductGraphicId'),
                visible: true,
                searchable: false,
                sortable: true
            }),
            createColumn('graphicIdAndVersion', {
                dataType: ColumnDataType.String,
                filterable: true,
                searchable: true,
                visible: false
            }),
            createColumn('graphicId', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            }),
            createColumn('graphicVersion', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('customerProductId', {
                dataType: ColumnDataType.String,
                searchable: true,
                sortable: true,
                visible: false
            }),
            createColumn('customerProductName', {
                dataType: ColumnDataType.String,
                searchable: false,
                visible: false,
                filterable: false
            }),
            createColumn('description', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('displayName', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'productDescription'),
                sortable: true,
                searchable: true
            }),
            createColumn('coating', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'coating'),
                filterable: true,
                sortable: true,
                searchable: true
            }),
            createColumn('leadTimeWeeks', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'leadTime'),
                sortable: true
            }),
            createColumn('leadTimeString', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('lockPeriodWeeks', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'makeItLockWindow'),
                sortable: true,
                visible: isLargeCustomerAccount
            }),
            createColumn('status', {
                label: TranslationService.getTranslatedText(culture, 'status'),
                searchable: true,
                filterable: true,
                sortable: true
            }),
            createColumn('actions', {
                label: TranslationService.getTranslatedText(culture, 'actions'),
                searchable: false,
                filterable: false,
                sortable: false
            }),
            createColumn('statusChanges', {
                visible: false,
                searchable: false,
                filterable: false,
                sortable: false
            }),
            createColumn('hasMatchingGraphicId', {
                dataType: ColumnDataType.Boolean,
                visible: false,
                searchable: false,
                filterable: false,
                sortable: false
            })
        ];
    };
}
