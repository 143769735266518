import React, { useState, useEffect } from 'react';
import { numericRegex } from '../../../../utility/helpers/formatters';
import { Trans, useTranslation } from 'react-i18next';
import {
    TableRow,
    TableCell,
    Typography,
    Grid,
    Input,
    Button,
    InputAdornment,
    IconButton
} from '@material-ui/core';
import Modal from '../../../reusable/molecules/Modal';
import moment from 'moment';
import clsx from 'clsx';
import { formatUTCToDateDisplay, getUtcMidnight } from '../../../../utility/helpers/date-helpers';
import SingleDaypickerModal from '../../../reusable/molecules/SingleDaypickerModal';
import { ProductionOrderLine } from '../../../../store/reducers/makeit-dashboard';
import { isWithinWeekRange } from '../../../../utility/helpers/production-order-helpers';
import { getEachesConversion } from '../../../../utility/helpers/shipment-helpers';

interface Props {
    item: ProductionOrderLine;
    onRemoveItem: (item: ProductionOrderLine) => void;
    onQtyUpdate: () => void;
    onBlurSetQty: () => void;
    onSubRowSelectedDateChange: () => void;
    styles: any;
    quantityPerPallet: number;
}

const EditProductionOrderSplitRow = ({
    item,
    onRemoveItem,
    onQtyUpdate,
    onBlurSetQty,
    onSubRowSelectedDateChange,
    styles,
    quantityPerPallet
}: Props) => {
    const classes = styles();
    const weekRange = 2;
    const [purchaseQty, setPurchaseQty] = useState(0);
    const [selectedDate, setSelectedDate] = useState<string>();
    const [daypickerDate, setDaypickerDate] = useState<string>();
    const [firstDay, setFirstDay] = useState<moment.Moment | null>(null);
    const [calendarOpen, setCalendarOpen] = useState(false);
    const [originalRequestedDate, setOriginalRequestedDate] = useState<string>('');
    const [hasLinkedDO, setHasLinkedDO] = useState<boolean>(false);
    const [withinDateRange, setWithinDateRange] = useState<boolean>(true);
    const [isRequestedDatePast, setIsRequestedDatePast] = useState<boolean>(false);

    const calendarIcon = (
        <img
            className={classes.calendarIcon}
            src={process.env.PUBLIC_URL + '/assets/Calendar_Icon.svg'}
            alt="Calendar Icon"
        />
    );

    const updatePurchaseCount = (count: string) => {
        const quantity = parseInt(count);
        if (numericRegex(count)) {
            if (!isNaN(quantity)) {
                setPurchaseQty(quantity);
                item.palletQuantity = quantity;
            } else {
                setPurchaseQty(0);
                item.palletQuantity = 0;
            }
            onQtyUpdate();
        }
    };

    const setQuantityOnBlur = () => {
        onBlurSetQty();
    };

    const handleRemoveItem = () => {
        onRemoveItem(item);
    };

    const onOpenCalendar = () => {
        if (hasLinkedDO || withinDateRange || isRequestedDatePast) {
            //prevents calendar from opening when it should be disabled
            return;
        }
        setCalendarOpen(true);
    };

    const onCloseCalendar = () => {
        setCalendarOpen(false);
    };

    const handleOkClose = () => {
        setCalendarOpen(false);
        setSelectedDate(daypickerDate);

        if (daypickerDate !== item.requestedDate) {
            item.requestedDate = daypickerDate;
            onSubRowSelectedDateChange();
        }
    };

    const handleDateSelect = (newDate: moment.Moment | null) => {
        const date = moment(newDate).format('MM/DD/YYYY');
        setDaypickerDate(date);
    };

    useEffect(() => {
        const firstAvailableDay = getUtcMidnight(item.originalRequestedDate);
        setFirstDay(firstAvailableDay);
    }, []);

    useEffect(() => {
        let originalDate = formatUTCToDateDisplay(moment().format());
        const today = moment();

        if (item.originalRequestedDate) {
            originalDate = formatUTCToDateDisplay(item.originalRequestedDate);
            setOriginalRequestedDate(originalDate);

            // Evaluate if requested date is in the past
            if (moment(item.originalRequestedDate).isSameOrBefore(today)) {
                setIsRequestedDatePast(true);
            }

            // Evaluate if requested date is within two weeks
            setWithinDateRange(
                isWithinWeekRange(moment(), getUtcMidnight(item.originalRequestedDate), weekRange)
            );
        }

        if (item.requestedDate !== item.originalRequestedDate) {
            setSelectedDate(formatUTCToDateDisplay(item.requestedDate));
        } else {
            if (item.originalRequestedDate) {
                setSelectedDate(originalDate);
            }
        }
    }, []);

    useEffect(() => {
        if (item.requestedDate) {
            setSelectedDate(formatUTCToDateDisplay(item.requestedDate));
        }
        if (item.palletQuantity) {
            setPurchaseQty(item.palletQuantity);
        }
        const disabledForLinkedDO =
            item.hasLinkedDeliveryOrder && !item.hasCancelledDeliveryOrder ? true : false;
        setHasLinkedDO(disabledForLinkedDO);
    }, [item]);

    useTranslation();

    return (
        <>
            <TableRow className={classes.tableRowContainer}>
                <TableCell className={classes.hiddenCell} />
                <TableCell className={classes.hiddenCell} />
                <TableCell className={classes.hiddenCell} />
                {item.originalRequestedDate && item.productionOrderLineId ? (
                    <TableCell>
                        <Typography>
                            {formatUTCToDateDisplay(item.originalRequestedDate)}
                        </Typography>
                    </TableCell>
                ) : (
                    <TableCell />
                )}
                <TableCell className={classes.alignCenter} data-testid="split-calendar-input">
                    <Input
                        id={item.productSku}
                        type="button"
                        fullWidth
                        onClick={onOpenCalendar}
                        disableUnderline={true}
                        disabled={hasLinkedDO || withinDateRange || isRequestedDatePast}
                        value={selectedDate}
                        className={classes.calendarInput}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="calendar modal trigger"
                                    disabled={hasLinkedDO || withinDateRange || isRequestedDatePast}
                                    onClick={onOpenCalendar}
                                >
                                    {calendarIcon}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </TableCell>
                {item.originalPalletQuantity ? (
                    <TableCell className={classes.blueBackground}>
                        <Typography>{item.originalPalletQuantity?.toLocaleString()} PL</Typography>
                    </TableCell>
                ) : (
                    <TableCell className={classes.blueBackground} />
                )}
                <TableCell className={classes.blueBackground}>
                    <Grid container alignContent="center" data-testid="split-quantity">
                        <Grid item className={classes.inputContainer}>
                            <Input
                                type="text"
                                fullWidth
                                className={classes.textField}
                                disableUnderline={true}
                                disabled={hasLinkedDO || withinDateRange || isRequestedDatePast}
                                onBlur={setQuantityOnBlur}
                                onChange={(e) => updatePurchaseCount(e.target.value)}
                                value={purchaseQty}
                            />
                            <Typography>PL</Typography>
                            <Grid item xs={12}>
                                <Typography>
                                    {getEachesConversion(purchaseQty, quantityPerPallet)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell className={clsx(classes.hiddenCell, classes.blueBackground)} />
                <TableCell
                    className={clsx(classes.alignCenter, classes.blueBackground)}
                    data-testid="split-delete-icon"
                >
                    <Button
                        type="button"
                        className={classes.trash}
                        disabled={hasLinkedDO || withinDateRange || isRequestedDatePast}
                        onClick={handleRemoveItem}
                    >
                        {hasLinkedDO || withinDateRange || isRequestedDatePast ? (
                            <img
                                src={process.env.PUBLIC_URL + '/assets/DisabledTrashIcon.svg'}
                                alt="Disabled Trash Icon"
                            />
                        ) : (
                            <img
                                src={process.env.PUBLIC_URL + '/assets/Trash.svg'}
                                alt="Delete Production Order Subrow"
                            />
                        )}
                    </Button>
                </TableCell>
            </TableRow>
            <Modal
                open={calendarOpen}
                title={
                    <Trans i18nKey="originalDate">
                        Original Requested Date: {{ originalRequestedDate }}
                    </Trans>
                }
                close={onCloseCalendar}
                closeIcon={true}
                maxWidth="xl"
            >
                <div className={classes.calendarDiv}>
                    <Grid item>
                        <Typography className={classes.subheader}>
                            <Trans i18nKey="dateMustBeAfterOriginalDate">
                                The date must be after the original requested date
                            </Trans>
                        </Typography>
                    </Grid>
                    <SingleDaypickerModal
                        onCancel={onCloseCalendar}
                        onOk={handleOkClose}
                        selectDate={(newDate) => handleDateSelect(newDate)}
                        leadTime={0}
                        firstDay={firstDay}
                        calculateOutsideRange={true}
                    />
                </div>
            </Modal>
        </>
    );
};

export default EditProductionOrderSplitRow;
