// JDE accepted values
const fillerLines = [
    '00',
    '1',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '2',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '3',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '39',
    '4',
    '40',
    '41',
    '42',
    '43',
    '44',
    '45',
    '46',
    '47',
    '48',
    '49',
    '5',
    '50',
    '51',
    '52',
    '53',
    '54',
    '55',
    '56',
    '57',
    '58',
    '59',
    '6',
    '60',
    '61',
    '62',
    '63',
    '64',
    '65',
    '66',
    '67',
    '68',
    '69',
    '7',
    '70',
    '71',
    '72',
    '73',
    '74',
    '75',
    '76',
    '77',
    '78',
    '79',
    '8',
    '80',
    '81',
    '82',
    '83',
    '84',
    '85',
    '86',
    '87',
    '88',
    '89',
    '9',
    '90',
    '91',
    '92',
    '93',
    '94',
    '95',
    '96',
    '97',
    '98',
    '99',
    'B01',
    'B11',
    'C01',
    'C02',
    'C03',
    'C06',
    'C07',
    'C1',
    'C14',
    'C15',
    'C18',
    'C19',
    'C1E',
    'C1W',
    'C2',
    'C21',
    'C3',
    'C4',
    'C5',
    'C6',
    'C7',
    'C8',
    'C9',
    'L1',
    'L2',
    'L20',
    'L3',
    'L35',
    'L37',
    'L4',
    'L45',
    'L5',
    'L55',
    'L61',
    'L62',
    'L65',
    'L80',
    'L85',
    'PLA',
    'SAV',
    'TRK',
    'XXX'
];

export default fillerLines;
