import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles, Typography, TableRow, TableCell, Grid } from '@material-ui/core';
import {
    black,
    blackWeight,
    boldWeight,
    errorBackground,
    informationAlert,
    ltBlueGrey_15,
    regularWeight,
    shipRed,
    small
} from '../../../../themes/globalConstants';
import { ProductToShip } from '../../../../store/reducers/shipping-dashboard';
import { Flags, FlagTypes } from '../../../../utility/helpers/feature-flag';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import ShipmentExpandableSection from '../../../reusable/molecules/ShipmentExpandableSection';
import {
    getEachesConversion,
    getFormattedSelectedQuantityUnit,
    isCustomerProductIdDuplicate
} from '../../../../utility/helpers/shipment-helpers';
import { Alert } from '@material-ui/lab';

interface Props {
    item: ProductToShip;
}

const useStyles = makeStyles((theme) => ({
    tableRowContainer: {
        '& p:first-of-type': {
            fontWeight: blackWeight
        },
        '&:hover': {
            backgroundColor: ltBlueGrey_15
        },
        '& > td': {
            padding: '1.250em'
        }
    },
    expand: {
        transform: 'rotate(0)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: 'rotate(180deg)'
    },
    eaches: {
        fontWeight: regularWeight,
        marginBottom: '.25em'
    },
    informationalAlert: {
        backgroundColor: informationAlert,
        borderRadius: '.125em',
        alignItems: 'center',
        width: '95%',
        maxHeight: '2em',
        textAlign: 'right'
    },
    informationAlertText: {
        fontWeight: boldWeight,
        fontSize: small,
        color: black
    },
    errorAlert: {
        border: `1px solid ${shipRed}`,
        backgroundColor: errorBackground
    },
    errorAlertText: {
        fontSize: small,
        color: black
    }
}));

export default function DeliveryBulkUploadRow({ item }: Props) {
    const classes = useStyles();
    const [active, setActive] = useState(false);
    const [showExpansionSection, setShowExpansionSection] = useState<boolean>(true);
    const uniqueProductId = useMemo(() => !isCustomerProductIdDuplicate(item), [item]);

    useTranslation();

    useEffect(() => {
        setActive(
            !!item.releaseNumber ||
                !!item.fillerLine ||
                !!item.referenceNumber ||
                !!item.deliveryInstructions
        );
        // Runs only once
    }, []);

    const toggleExpand = () => {
        setShowExpansionSection(!showExpansionSection);
    };

    return (
        <>
            <TableRow className={classes.tableRowContainer}>
                <TableCell data-testid="csv-row">
                    <Typography>{item.originalCsvLineNumber}</Typography>
                </TableCell>
                <TableCell data-testid="sequence">
                    <Typography>{item.sequence}</Typography>
                </TableCell>
                <TableCell
                    data-testid="user-supplied-product-id"
                    className={clsx({
                        [classes.errorAlert]: !uniqueProductId
                    })}
                >
                    <Grid container>
                        {!uniqueProductId && (
                            <img
                                src={process.env.PUBLIC_URL + '/assets/Error_Icon.svg'}
                                alt="Error Icon"
                            />
                        )}
                        <Typography> {item.userSuppliedProductId ?? item.displayId}</Typography>
                        {!uniqueProductId && (
                            <Typography className={classes.errorAlertText}>
                                <Trans>Duplicate Product IDs. Edit Shipment to correct.</Trans>
                            </Typography>
                        )}
                    </Grid>
                </TableCell>
                <TableCell data-testid="description">
                    <Typography>{item.name}</Typography>
                    <Typography>{item.description}</Typography>
                </TableCell>
                <TableCell data-testid="quantity">
                    <Grid container justify="center" direction="column" alignItems="flex-end">
                        <Typography>{`${item.palletQuantity} ${getFormattedSelectedQuantityUnit(
                            'pallets'
                        )}`}</Typography>
                        {uniqueProductId && (
                            <Typography className={classes.eaches}>
                                {getEachesConversion(item.palletQuantity, item.quantityPerPallet!)}
                            </Typography>
                        )}
                    </Grid>
                    {item.palletsRounded && (
                        <Grid container>
                            <Alert
                                className={classes.informationalAlert}
                                icon={false}
                                data-testid="pallet-alert"
                            >
                                <Typography className={classes.informationAlertText}>
                                    <Trans i18nKey="quantityRounded">
                                        Quantity rounded up to the nearest full pallet.
                                    </Trans>
                                </Typography>
                            </Alert>
                        </Grid>
                    )}
                </TableCell>
                <Flags authorizedFlags={[FlagTypes.Coke]}>
                    <TableCell data-testid="purchase-order-number">
                        <Typography>{item.purchaseOrderNumber}</Typography>
                    </TableCell>
                </Flags>
                <TableCell>
                    <ExpandMoreIcon
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: active && showExpansionSection
                        })}
                        onClick={toggleExpand}
                        color={active ? 'primary' : 'disabled'}
                    />
                </TableCell>
            </TableRow>
            {showExpansionSection && active ? (
                <ShipmentExpandableSection
                    active={showExpansionSection}
                    releaseNumber={item.releaseNumber ?? ''}
                    reference={item.referenceNumber ?? ''}
                    deliveryInstructions={item.deliveryInstructions ?? ''}
                    fillerLine={item.fillerLine ?? ''}
                    readOnly={true}
                    handleUpdateItem={() => {}}
                    updateOptionalField={() => {}}
                    handleSelectChange={() => {}}
                />
            ) : null}
        </>
    );
}
