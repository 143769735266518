import React, { Dispatch, SetStateAction, useEffect, useState, useMemo } from 'react';
import { makeStyles, Box, Tabs, Tab, Grid, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { CSVLink } from 'react-csv';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { getAccountAddress } from '../../../../utility/helpers/address-helpers';
import moment from 'moment';
import { getCurrentDate } from '../../../../utility/helpers/date-helpers';
import {
    addFormItemButtonBlue,
    black,
    ballBlue,
    blackWeight,
    inactiveTabBlue,
    ltBlueGrey,
    regularWeight,
    ballDrkBlue,
    lato,
    medium,
    small,
    white,
    xl
} from '../../../../themes/globalConstants';
import {
    Activity,
    getSecurityLevel,
    getATMSecurityLevel
} from '../../../../utility/auth/useSecurity';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import ReactPdf from '@react-pdf/renderer';
import { ProductionOrder } from '../../../../store/reducers/makeit-dashboard';
import TranslationService from '../../../../utility/services/translation-service';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import PlanItTab from '../../PlanItSummary/components/PlanItTab';
import { loadForecastOrders } from '../../../../store/actions/plan-it-summary';
import { PlanItSummaryState } from '../../../../store/reducers/plan-it-summary';
import { PlanItGuidedState } from '../../../../store/reducers/plan-it-guided';
import { useQuery } from '../../../../utility/helpers/query-helpers';
import { PlanItSummaryProvider } from '../context/PlanItSummaryContext';
import { Order_Status } from '../../../../utility/services/orders-service';
import { useTranslation, Trans } from 'react-i18next';
import { BulkUploadMakeItState } from '../../../../store/reducers/makeit-bulk-upload';
import { ProductionOrderForTable } from '../../MakeItATMSummary/models/ProductionOrderForTable';
import { AuthState } from '../../../../store/reducers/auth';
import { selectIsLargeCustomer } from '../../../../store/selectors';
import PlanItRow from './PlanItRow';
import { BulkUploadPlanItATMState } from '../../../../store/reducers/planit-bulk-atm';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

export enum TabStatus {
    'forecasts' = 0,
    'previousForecasts' = 1
}

interface Props {
    dashboardLoading: Dispatch<SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: '4.5em',
        position: 'relative',
        '& h2': {
            fontSize: xl,
            fontFamily: lato,
            fontWeight: blackWeight,
            color: theme.palette.secondary.main
        }
    },

    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        padding: '1em'
    },
    tabPanel: {
        width: '100%'
    },
    icon: {
        color: ballBlue
    },
    counter: {
        width: '2em',
        height: '2em',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '0.5em',
        fontSize: small,
        fontWeight: blackWeight,
        backgroundColor: ltBlueGrey,
        color: ballDrkBlue
    },
    selectedCounter: {
        backgroundColor: addFormItemButtonBlue,
        color: white
    },
    tabs: {},
    tabLabel: {
        backgroundColor: inactiveTabBlue,
        height: '56px',
        borderTopLeftRadius: '0.625em',
        borderTopRightRadius: '0.625em',
        '&:not(:first-of-type)': {
            marginLeft: '8px'
        },
        padding: '0 3em',
        color: ballDrkBlue,
        textTransform: 'none',
        maxWidth: 'none'
    },
    downloadWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    tabLabelText: {
        fontSize: medium,
        fontWeight: blackWeight
    },
    selectedTab: {
        backgroundColor: white,
        color: black,
        boxShadow: '0px 0px 6px rgba(185, 211, 220, 0.8), inset 0px -3px 0px #009BBB'
    }
}));

const TabPanel = (props: TabPanelProps) => {
    const classes = useStyles();
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`plan-it-tabpanel-${index}`}
            aria-labelledby={`plan-it-tab-${index}`}
            {...other}
            className={classes.tabPanel}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
};

// This assigns props for accessibility purposes
const a11yProps = (index: any) => {
    return {
        id: `plan-it-tab-${index}`,
        'aria-controls': `graphics-tabpanel-${index}`
    };
};

const pdfStyles = ReactPdf.StyleSheet.create({
    link: {
        fontSize: medium,
        fontWeight: regularWeight
    }
});

const PlanItTabs = ({ dashboardLoading }: Props) => {
    const classes = useStyles();
    const [filteredOrdersForTable, setFilteredOrdersForTable] = useState<any>([]);
    const { products } = useTypedSelector<BulkUploadMakeItState>(
        (state) => state.bulkUploadMakeItState
    );

    const dispatch = useDispatch();
    const query = useQuery();
    const status = query.get('status');
    const [ordersForTable, setOrdersForTable] = useState<ProductionOrderForTable[] | undefined>(
        undefined
    );
    // const { activeTab } = usePlanItSummaryContext();
    const auth = useTypedSelector<AuthState>((state) => state.auth);
    const [forecastOrders, setForecastOrders] = useState<ProductionOrder[]>([]);
    const [previousForecastOrders, setPreviousForecastOrders] = useState<ProductionOrder[]>([]);
    const [value, setValue] = useState<Number>(0);
    const { orders, forecastOrdersLoaded, error } = useTypedSelector<PlanItSummaryState>(
        (state) => state.planItSummary
    );
    // const [columns, setColumns] = useState<any>([]);
    const currentAccount = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    ).accounts?.find((account) => account.accountId === (selectedAccountId as string));
    const isLargeCustomer = useTypedSelector<boolean>(selectIsLargeCustomer);

    const newlySubmittedForecasts = useTypedSelector<PlanItGuidedState>(
        (state) => state.planItGuided.forecastOrders
    );
    const { submitState } = useTypedSelector<PlanItGuidedState>((state) => state.planItGuided);
    const { submitForecastOrdersSubmissionState } = useTypedSelector<BulkUploadPlanItATMState>(
        (state) => state.bulkUploadPlanIt
    );
    const { shipToAccounts, selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { t } = useTranslation();
    useEffect(() => {
        if (selectedAccountId) {
            dispatch(loadForecastOrders([parseInt(selectedAccountId)]));
        }
    }, [dispatch, selectedAccountId]);

    useEffect(() => {
        if (submitState === 'success' && selectedAccountId) {
            setValue(0);
            dispatch(loadForecastOrders([parseInt(selectedAccountId)]));
        }
    }, [dispatch, submitState, selectedAccountId, newlySubmittedForecasts]);

    useEffect(() => {
        if (submitForecastOrdersSubmissionState === 'success' && selectedAccountId) {
            setValue(0);
            dispatch(loadForecastOrders([parseInt(selectedAccountId)]));
        }
    }, [dispatch, submitForecastOrdersSubmissionState, selectedAccountId]);

    useEffect(() => {
        if (orders && forecastOrdersLoaded) {
            const activeOrders = orders.filter(
                (order) =>
                    moment(order.atmWeekStart).isSameOrAfter(moment()) &&
                    order.totalOrderQuantity > 0
            );
            const previousOrders = orders
                .filter(
                    (order) =>
                        moment(order.atmWeekStart).isBefore(moment()) ||
                        order.totalOrderQuantity === 0
                )
                .map((order) => ({
                    ...order,
                    forecastTableStatus: t(
                        order.lines.some((line) => line.status === Order_Status.Confirmed)
                            ? 'confirmed'
                            : 'cancelled'
                    )
                }));
            setForecastOrders([...activeOrders]);
            setPreviousForecastOrders(previousOrders);
        }
    }, [t, orders, forecastOrdersLoaded]);

    useEffect(() => {
        if (forecastOrdersLoaded || error !== undefined) {
            dashboardLoading(false);
        }
    }, [dashboardLoading, forecastOrdersLoaded, error]);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        const val = TabStatus[newValue];
        window.history.replaceState('', '', `?status=${val}`);
        setValue(newValue);
    };

    function getValueFromStatus(status) {
        return (TabStatus as any)[status?.toLowerCase() || ''] || 0;
    }

    useEffect(() => {
        const value = getValueFromStatus(status);
        setValue(value);
    }, [status]);

    const TabLabel = (label: string, count: number, index: number) => {
        return (
            <Grid container justify="center" alignItems="center">
                <Typography className={classes.tabLabelText}>{label}</Typography>
                <Typography
                    component="span"
                    className={`${classes.counter} ${value === index && classes.selectedCounter}`}
                >
                    {count}
                </Typography>
            </Grid>
        );
    };

    const securityLevel = useMemo(
        () =>
            getSecurityLevel(Activity.PlanItSummary, auth, {
                accountId: selectedAccountId as string
            }),
        [selectedAccountId, auth]
    );

    // A user can select copy plan it to make it if they have make it permissions
    const copyToMakeItSecurityLevel = useMemo(
        () =>
            getATMSecurityLevel(
                Activity.MakeItATMSummary,
                auth,
                { accountId: selectedAccountId as string },
                isLargeCustomer
            ),
        [selectedAccountId, auth, isLargeCustomer]
    );

    useEffect(() => {
        if (orders && products?.length) {
            const formattedOrders: ProductionOrderForTable[] = [];
            orders.sort((a, b) => moment(b.atmWeekStart).diff(a.atmWeekStart));
            orders.forEach((order, index) => {
                let orderForTable: ProductionOrderForTable = {
                    ...order,
                    searchablePONumber: order.productionOrderId?.toString(),
                    totalOrderQuantityString: order.totalOrderQuantity.toString(),
                    totalOrderQuantityEachesString: order.totalOrderQuantityEaches?.toString(),
                    atmWeekStartString: moment(order.atmWeekStart).format('MM/DD/YYYY'),
                    atmWeekStartDate: moment(order.atmWeekStart).toDate(),
                    submittedDateString: moment(order.createDate).format('MM/DD/YYYY'),
                    sortIndex: index,
                    copyToMakeItChecked: false
                };
                formattedOrders.push(orderForTable);
            });
            setOrdersForTable(formattedOrders);
        } else {
            setOrdersForTable([]);
        }
    }, [orders, products]);

    useEffect(() => {
        if (ordersForTable && value === 0) {
            const formattedForecast = ordersForTable.filter((ea1) =>
                forecastOrders.some((ea2) => ea1.productionOrderId === ea2.productionOrderId)
            );
            setFilteredOrdersForTable(formattedForecast);
        } else if (ordersForTable && value === 1) {
            const formattedForecast = ordersForTable.filter((ea1) =>
                previousForecastOrders.some(
                    (ea2) => ea1.productionOrderId === ea2.productionOrderId
                )
            );
            setFilteredOrdersForTable(formattedForecast);
        }
    }, [ordersForTable]);

    // useEffect(() => {
    // const forecastTab = activeTab === 'forecasts';
    // setColumns(
    //     PlanItColumns.getColumns(
    //         cultureCode,
    //         copyToMakeItSecurityLevel === SecurityLevel.Edit && forecastTab
    //     )
    // );
    // }, [cultureCode, activeTab, copyToMakeItSecurityLevel]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const CurrentRow = ({ row }: { row: ProductionOrderForTable }) => {
        if (row) {
            return (
                <PlanItRow
                    order={row as ProductionOrderForTable}
                    key={row.productionOrderId}
                    securityLevel={securityLevel}
                    copyToMakeItSecurityLevel={copyToMakeItSecurityLevel}
                    currentAccount={currentAccount}
                />
            );
        } else {
            return null;
        }
    };

    interface CSVRow {
        ship_to_detail?: string;
        forecast_order_number?: string;
        forecast_week?: string;
        total_quantity?: string;
        submitted?: string;
    }

    const csvHeaders = [
        { label: t('shipTo', 'Ship to').toUpperCase(), key: 'ship_to_detail' },
        {
            label: t('forecastOrderNumber', 'Forcast order number').toUpperCase(),
            key: 'forecast_order_number'
        },
        { label: t('forecastWeek', 'Forecast Week').toUpperCase(), key: 'forecast_week' },
        { label: t('totalQuantity', 'Total Quantity').toUpperCase(), key: 'total_quantity' },
        { label: t('submitted', 'SUBMITTED').toUpperCase(), key: 'submitted' }
    ];

    const csvArray = () => {
        let rows: any[] = [];
        if (!Array.isArray(filteredOrdersForTable) || shipToAccounts?.length === 0) return rows;
        const account = shipToAccounts?.find((account) => account.accountId === selectedAccountId);
        const address = account ? getAccountAddress(account) : undefined;
        filteredOrdersForTable.map((order) => {
            const row: CSVRow = {
                ship_to_detail: `${account?.name}, ${address}, #${account?.accountId}`,
                forecast_order_number: order.productionOrderId,
                forecast_week: order.atmWeekStartString,
                total_quantity: `${order.totalOrderQuantity} PL \n ${order.totalOrderQuantityEaches} ea`,
                submitted: order.submittedDateString
            };

            rows.push(row);
            return null;
        });

        return rows;
    };

    const csvContents = csvArray();

    return (
        <PlanItSummaryProvider
            value={{ activeTab: value === 0 ? 'forecasts' : 'previousForecasts' }}
        >
            <div className={classes.root} data-testid="plan-it-tabs">
                <div className={classes.titleContainer}>
                    <Grid className={classes.downloadWrapper}>
                        <CSVLink
                            className={
                                'MuiTypography-root MuiLink-root MuiLink-underlineAlways MuiTypography-colorPrimary'
                            }
                            style={pdfStyles.link}
                            underline="always"
                            data-testid="download-btn"
                            headers={csvHeaders}
                            data={csvContents}
                            filename={`Plan it Summary - ${getCurrentDate()}.csv`}
                        >
                            <Trans i18nKey="downloadCSV">Download .CSV</Trans>
                        </CSVLink>
                        <GetAppOutlinedIcon fontSize="small" className={classes.icon} />
                    </Grid>
                </div>

                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="plan it summary tabs"
                    className={classes.tabs}
                    scrollButtons="auto"
                    variant="scrollable"
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab
                        label={TabLabel(
                            TranslationService.getTranslatedText(cultureCode, 'forecasts'),
                            forecastOrders ? forecastOrders.length : 0,
                            0
                        )}
                        {...a11yProps(0)}
                        className={`${classes.tabLabel} ${value === 0 && classes.selectedTab}`}
                        value={0}
                        data-testid="forecasts-tab"
                    />
                    <Tab
                        label={TabLabel(
                            TranslationService.getTranslatedText(cultureCode, 'previousForecasts'),
                            previousForecastOrders ? previousForecastOrders.length : 0,
                            1
                        )}
                        {...a11yProps(1)}
                        className={`${classes.tabLabel} ${value === 1 && classes.selectedTab}`}
                        value={1}
                        data-testid="previous-forecasts-tab"
                    />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <PlanItTab orders={forecastOrders} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <PlanItTab orders={previousForecastOrders} isPrevious={true} />
                </TabPanel>
            </div>
        </PlanItSummaryProvider>
    );
};

export default PlanItTabs;
