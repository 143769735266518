import {
    LIQUID_INTAKE_DATA_LOADED,
    LIQUID_INTAKE_DATA_LOADING,
    LIQUID_INTAKE_DATA_LOADING_ERROR,
    LIQUID_INTAKE_DATA_RESET_STATE,
    LIQUID_INTAKE_SUBMITTED,
    LIQUID_INTAKE_SUBMITTING,
    LIQUID_INTAKE_SUBMIT_ERROR
} from '../actions/action-types';

export enum IntakeStatus {
    ARS = 'ARS',
    RPT = 'RPT',
    WRA = 'WRA',
    WRR = 'WRR',
    WAQ = 'WAQ',
    NTR = 'NTR'
}
export enum BeverageCategory {
    /* Old List - do not touch to avoid conflicts */
    Unknown = 'Unknown',
    BeerDomestic = 'BeerDomestic',
    BeerImport = 'BeerImport',
    Cider = 'Cider',
    Coffee = 'Coffee',
    Fmb = 'Fmb',
    Thc = 'Thc',
    Protein = 'Protein',
    Spirits = 'Spirits',
    Water = 'Water',
    Other = 'Other',
    Beer = 'Beer',
    IcedCoffee = 'IcedCoffee',
    IcedTea = 'IcedTea',
    Fab = 'Fab',
    HardLiquor = 'HardLiquor',
    Rtd = 'Rtd',
    /* New list */
    CoffeeDairy = 'CoffeeDairy',
    BeerCraft = 'BeerCraft',
    Csd = 'Csd',
    ClubSodaSeltzer = 'ClubSodaSeltzer',
    DomesticBeer = 'DomesticBeer',
    EnergyDrink = 'EnergyDrink',
    FabCider = 'FabCider',
    EuropenBeer = 'EuropenBeer',
    Juice = 'Juice',
    SportsDrink = 'SportsDrink',
    StillWater = 'StillWater',
    Tea = 'Tea',
    Wine = 'Wine',
    CarribeanBeer = 'CarribeanBeer',
    MexicanBeer = 'MexicanBeer'
}
export enum NewOrExistingStatus {
    Unknown = 'Unknown',
    New = 'New',
    Existing = 'Existing'
}
export enum BeverageContents {
    Unknown = 'Unknown',
    AddedSulfites = 'AddedSulfites',
    AceticAcid = 'AceticAcid',
    LacticAcid = 'LacticAcid',
    MalicAcid = 'MalicAcid',
    CalciumLactate = 'CalciumLactate',
    FoodDyes = 'FoodDyes'
}
export interface LiquidTestIntake {
    liquidTestId?: string;
    billTo?: number;
    companyName: string;
    beverageName?: string;
    projectName?: string;
    beverageDescription?: string;
    graphicID?: string;
    abvPercent: number;
    pH: number;
    isSimpleBeer?: boolean;
    refrigerationRequired?: boolean;
    concentrateSample?: boolean;
    throwRatio?: string;
    beverageCategory: BeverageCategory;
    newOrExisting?: NewOrExistingStatus;
    submitterEmail: string;
    submitterName: string;
    submitterPhoneNumber: string;
    beverageContentList?: BeverageContents[];
    id?: string;
    createDate?: string;
    testRequired?: boolean;
    status?: IntakeStatus;
    statusUpdatedDateTime?: string;
}

export interface LiquidTestIntakeState {
    loading: boolean;
    error: string;
    liquidTestIntake?: LiquidTestIntake;
    image?: File;
    fileName?: string;
    testRequired: boolean;
    submitting: boolean;
    submitted: boolean;
}

const initialState: LiquidTestIntakeState = {
    loading: false,
    error: '',
    liquidTestIntake: {
        companyName: '',
        abvPercent: 0,
        pH: 0,
        beverageCategory: BeverageCategory.Unknown,
        submitterEmail: '',
        submitterName: '',
        submitterPhoneNumber: ''
    },
    testRequired: false,
    submitting: false,
    submitted: false
};

const liquidIntake = (state: LiquidTestIntakeState = initialState, action: any) => {
    switch (action.type) {
        case LIQUID_INTAKE_DATA_LOADED:
            return {
                ...state,
                loaded: true,
                loading: false,
                liquidTestIntake: action.liquidTestIntake
            };
        case LIQUID_INTAKE_DATA_LOADING:
            return {
                ...state,
                loaded: false,
                loading: true,
                error: undefined
            };
        case LIQUID_INTAKE_DATA_LOADING_ERROR:
            return {
                ...state,
                loaded: true,
                loading: false,
                error: action.error
            };
        case LIQUID_INTAKE_DATA_RESET_STATE:
            return {
                ...initialState
            };
        case LIQUID_INTAKE_SUBMITTING:
            return {
                ...state,
                submitting: true
            };
        case LIQUID_INTAKE_SUBMITTED:
            return {
                ...state,
                liquidTestIntake: action.liquidtest,
                testRequired: action.testRequired,
                submitting: false,
                submitted: true
            };
        case LIQUID_INTAKE_SUBMIT_ERROR:
            return {
                ...state,
                error: action.error
            };
        default:
            return state;
    }
};

export default liquidIntake;
