import { makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { ScrapItLine } from '../../../../store/reducers/scrap-it-details';
import {
    ballBlack,
    boldWeight,
    ltBlueGrey_15,
    regularWeight,
    small,
    xs
} from '../../../../themes/globalConstants';
import { getScrapItLineStatus } from '../../../../utility/helpers/scrap-it-helpers';
interface Props {
    line: ScrapItLine;
}

const useStyles = makeStyles((theme) => ({
    tableRowContainer: {
        '& .helper-text': {
            fontWeight: `${regularWeight} !important`,
            fontSize: small
        },
        '&:hover': {
            backgroundColor: ltBlueGrey_15
        },
        '& > td': {
            padding: '1.250em'
        },
        '& .MuiInputBase-input': {
            textAlign: 'right',
            paddingRight: '.25em'
        },
        '& .MuiTableCell-root': {
            '&:not(:last-child)': {
                paddingLeft: '1.125em'
            }
        },
        '& .normal': {
            fontWeight: `${regularWeight} !important`,
            textAlign: 'right'
        }
    },
    boldText: {
        color: ballBlack,
        fontWeight: boldWeight
    },
    eachesText: {
        color: ballBlack,
        fontSize: xs
    },
    tableData: {
        color: ballBlack
    }
}));

export default function ScrapItRequestDetailsRow({ line }: Props) {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const getProductDescription = () => {
        return (
            <>
                <Typography className={classes.boldText}>{line.productDescription}</Typography>
                <Typography>{`(${line.productSku}${
                    line.graphicIdAndVersion ? `/${line.graphicIdAndVersion}` : ''
                })`}</Typography>
            </>
        );
    };

    return (
        <TableRow className={classes.tableRowContainer} data-testid={`line-${line.productSku}`}>
            <TableCell data-testid="product-description">{getProductDescription()}</TableCell>
            <TableCell data-testid="customer-product-id">
                <Typography className={classes.tableData}>{line.customerProductId}</Typography>
            </TableCell>
            <TableCell data-testid="coating">
                <Typography className={classes.tableData}>{line.coating}</Typography>
            </TableCell>

            <TableCell data-testid="quantity-to-scrap">
                <Typography className={classes.boldText}>
                    {line.quantity.toLocaleString()} PL
                </Typography>
                <Typography className={classes.eachesText}>
                    {line.eachesQuantity ? `${line.eachesQuantity.toLocaleString()} ea.` : ''}
                </Typography>
            </TableCell>
            <TableCell data-testid="request-status">
                <Typography className={classes.tableData}>
                    {getScrapItLineStatus(line.status, cultureCode)}
                </Typography>
            </TableCell>
        </TableRow>
    );
}
