import { makeStyles } from '@material-ui/core/styles';
import {
    boldWeight,
    blackWeight,
    ballBlue,
    dateOptionInvalidGray,
    white,
    ballGray,
    ballLtGray
} from '../../../../themes/globalConstants';
import React, { useEffect, useState } from 'react';
import { DataGrid, ToolbarOptions, TbRowProps } from 'tubular-react';
import { ColumnModel } from 'tubular-common';
import MakeItDraftsSummaryRow from './MakeItDraftsSummaryRow';
import { Typography, Grid } from '@material-ui/core';
import { Trans } from 'react-i18next';
import MakeItDraftsSummaryOrderSectionActions from './MakeItDraftsSummarySectionActions';
import {
    DraftInformation,
    ProductionOrderDraft,
    ProductionOrderDraftLine
} from '../../../../store/reducers/make-it-drafts';
import moment from 'moment';
import { formatProductionOrderDraftLines } from '../../../../utility/helpers/make-it-draft-helpers';
import { ProductionOrder } from '../../../../store/reducers/makeit-dashboard';
import { formattedDateWithTimezone } from '../../../../utility/helpers/date-helpers';
import MakeItDraftsColumns from './MakeItDraftsColumns';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { useMakeItDraftsContext } from '../context/MakeItDraftsSummaryContext';
import clsx from 'clsx';

interface Props {
    index: number;
    draft: ProductionOrderDraft;
    accountsWithMakeIt: string[];
    openDiscardModal: (orders: ProductionOrder[], entireDraftSelected: boolean) => void;
    openRestoreDraftsModal: (orders: ProductionOrder[], draft: DraftInformation) => void;
    showCustomerPO: boolean;
}

const useStyles = makeStyles((theme) => ({
    action: {
        cursor: 'pointer'
    },
    cardHeader: {
        paddingLeft: '1em',
        marginBottom: '1em'
    },
    subtitle: {
        color: ballGray
    },
    tableWrapper: {
        marginTop: '2em',
        background: white,
        padding: '2em 0',
        '&:last-of-type': {
            marginBottom: '2em'
        },
        '& .MuiPaper-root': {
            boxShadow: 'none'
        },
        '& .MuiToolbar-root': {
            display: 'none',
            padding: 0,
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                },
                '&:first-of-type ': {
                    paddingLeft: 0
                }
            },
            '& > h2': {
                fontSize: '1.5em',
                fontWeight: boldWeight,
                color: theme.palette.secondary.main,
                paddingLeft: '0.625em',
                fontFamily: 'Lato',
                minWidth: '12em'
            }
        },
        '& .MuiTable-root': {
            direction: 'rtl'
        },
        '& .MuiTableSortLabel-root': {
            fontSize: 12,
            fontWeight: blackWeight,
            paddingLeft: '0.5em',
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            width: '99%',
            justifyContent: 'space-between',
            '& .MuiSvgIcon-root': {
                margin: 0
            }
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableCell-root:first-child': {
            borderLeft: `1px solid ${ballLtGray}`
        },
        '& .MuiTableCell-root:last-child': {
            borderLeft: 'none',
            paddingLeft: '1em'
        },
        '& .MuiTableBody-root': {
            '& .MuiTableCell-root': {
                wordWrap: 'break-word',
                direction: 'ltr'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                direction: 'ltr',
                '&:nth-last-child(1)': {
                    width: '20%'
                },
                '&:nth-last-child(8)': {
                    border: 'none',
                    width: '8%'
                },
                '&:nth-last-child(9)': {
                    border: 'none',
                    width: '8%'
                }
            },
            '& .MuiTableCell-paddingCheckbox': {
                textAlign: 'center',
                width: 'auto',
                padding: '10px'
            }
        },
        '& .MuiInputBase-formControl': {
            border: `1px solid ${dateOptionInvalidGray}`,
            borderRadius: '.125em',
            '&:before': {
                display: 'none'
            },
            '&:after': {
                display: 'none'
            },
            '& svg': {
                height: '.875em',
                width: '.875em',
                fill: ballBlue
            },
            '& .MuiIconButton-root': {
                '&:hover': {
                    backgroundColor: 'transparent !important'
                }
            },
            '& .MuiInput-input': {
                paddingLeft: '.25em'
            }
        },
        '& .MuiTable-root:nth-of-type(2)': {
            display: 'none'
        }
    },
    firstTable: {
        marginTop: '0em'
    }
}));

const MakeItDraftsSummaryOrderSection = ({
    index,
    draft,
    accountsWithMakeIt,
    openDiscardModal,
    openRestoreDraftsModal,
    showCustomerPO
}: Props) => {
    const classes = useStyles();

    const [columns, setColumns] = useState<ColumnModel[]>([]);
    const [formattedLines, setFormattedLines] = useState<ProductionOrderDraftLine[]>([]);
    const { activeTab } = useMakeItDraftsContext();

    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    useEffect(() => {
        // Show customer PO field if spot customer
        setColumns(MakeItDraftsColumns.getColumns(cultureCode, showCustomerPO).reverse());
    }, [showCustomerPO, cultureCode]);

    useEffect(() => {
        if (draft.orders) {
            setFormattedLines(formatProductionOrderDraftLines(draft.orders));
        }
    }, [draft.orders]);

    const options = new ToolbarOptions({
        advancePagination: false,
        exportButton: false,
        printButton: false,
        searchText: false,
        enablePagination: false,
        itemsPerPage: draft.orders.length
    });

    const MakeItDraftsSummaryLine = (body: TbRowProps) => {
        const { row } = body;
        if (row) {
            return (
                <MakeItDraftsSummaryRow
                    key={row.productionOrderId}
                    line={row as any}
                    draftId={draft.draft.draftId}
                    canEdit={accountsWithMakeIt.includes(row.shipToId.toString())}
                    showCustomerPO={showCustomerPO}
                    {...body}
                />
            );
        } else {
            return null;
        }
    };

    return (
        <>
        <Grid
            container
            item
            className={clsx(index === 0 && classes.firstTable, classes.tableWrapper)}
        >
            <Grid container item xs={12} className={classes.cardHeader}>
                <Grid item xs={2}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="draftName">Draft Name</Trans>:
                    </Typography>
                    <Typography>{draft.draft.name}</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="createdBy">Created By</Trans>:
                    </Typography>
                    <Typography>{draft.draft.createdBy}</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="createdDate">Created Date</Trans>:
                    </Typography>
                    <Typography>
                        {formattedDateWithTimezone(
                            moment.utc(draft.draft.createDate),
                            'M/D/YYYY h:mm A'
                        )}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} data-draftId={draft.draft.draftId}>
                {columns && columns.length > 0 && (
                    <DataGrid
                        gridName={draft.draft.draftId}
                        columns={columns}
                        dataSource={formattedLines}
                        rowComponent={MakeItDraftsSummaryLine}
                        toolbarOptions={options}
                        rowSelectionEnabled={true}
                    />
                )}
            </Grid>
            <MakeItDraftsSummaryOrderSectionActions
                draftInformation={draft.draft}
                orders={draft.orders}
                accountsWithMakeIt={accountsWithMakeIt}
                openDiscardModal={openDiscardModal}
                openRestoreDraftsModal={openRestoreDraftsModal}
                activeTab={activeTab}
            />
        </Grid>

        </>
    );
};

export default MakeItDraftsSummaryOrderSection;
