import { useLocation } from 'react-router-dom';
import { ProductEnd, Product } from '../../store/reducers/product-portfolio';

export interface DefaultQueryParams {
    id: string;
}

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const getEndIds = (items?: ProductEnd[]) => {
    let ids: string[] = [];

    if (items && items.length) {
        ids = items.map((item) => item.endCode);
    }

    return { ids: ids };
};

export const getProductIds = (items: Product[]) => {
    let ids: string[] = [];

    if (items && items.length) {
        ids = items.map((item) => item.productCode);
    }

    return { ids: ids };
};

export const createQueryParam = (queryContent: any) => {
    let queryString = '?';
    for (const key of Object.keys(queryContent)) {
        queryString += `${key}=`;
        for (const val of queryContent[key]) {
            queryString += `${val},`;
        }
        queryString = queryString.slice(0, -1);
        queryString += '&';
    }
    return (queryString = queryString.slice(0, -1));
};

export const createAPIQueryParam = (queryContent: any) => {
    let queryString = '?';
    for (const key of Object.keys(queryContent)) {
        if (isIterable(queryContent[key])) {
            queryString += `${key}=`;
            for (const val of queryContent[key]) {
                queryString += `${val}&${key}=`;
            }
            const removeLength = (key.length + 2) * -1;
            queryString = queryString.slice(0, removeLength);
        } else {
            let keyVal = queryContent[key];
            queryString += `${key}=${keyVal}`;
        }
        queryString += '&';
    }
    return (queryString = queryString.slice(0, -1));
};

// https://stackoverflow.com/questions/18884249/checking-whether-something-is-iterable
function isIterable(obj) {
    // checks for null and undefined
    if (obj === null) {
        return false;
    }
    return typeof obj[Symbol.iterator] === 'function';
}
