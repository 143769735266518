import React, { useEffect, useState } from 'react';
import {
    Grid,
    makeStyles,
    Typography,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableBody
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Link from '../../../reusable/atoms/Link';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';
import { generateKey } from '../../../../utility/helpers/order-helpers';
import { getMondayOfWeekFormatted } from '../../../../utility/helpers/date-helpers';
import { medium, blackWeight, xl } from '../../../../themes/globalConstants';
import { formatNumberWithLocale } from '../../../../utility/helpers/formatters';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { ProductionOrderLine } from '../../../../store/reducers/makeit-dashboard';
import { enUS } from '../../../../utility/translations/locales';
import { ScrapItState } from '../../../../store/reducers/scrap-it';
import { ProductWithPallets } from '../../../../store/reducers/shipping-dashboard';
import { AgedStatus } from '../../../../utility/services/orders-service';

interface Props {
    activeProduct: ProductWithPallets;
}

const useStyles = makeStyles((theme) => ({
    main: {
        fontSize: medium,
        padding: '1em'
    },
    weekText: {
        marginLeft: '1em'
    },
    columnHeaders: {
        fontSize: xl,
        fontWeight: blackWeight
    },
    longValue: {
        overflowWrap: 'anywhere'
    },
    icon: {
        width: '1em',
        marginRight: '0.5em'
    }
}));

type POLineAged = {
    agedStatus: AgedStatus;
    agedQuantity: number;
} & ProductionOrderLine;

const ScrapItPOBalanceModal = ({ activeProduct }: Props) => {
    const classes = useStyles();
    const [poLines, setPoLines] = useState<POLineAged[]>([]);
    const { agedProducts } = useTypedSelector<ScrapItState>((state) => state.scrapItState);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { t } = useTranslation();

    const displayAgedIconStatus = (agedStatus?: AgedStatus) => {
        switch (agedStatus) {
            case AgedStatus.Aging:
                return (
                    <img
                        src={process.env.PUBLIC_URL + '/assets/icon-clock-warning.svg'}
                        className={classes.icon}
                        alt="Aging Balance"
                    />
                );
            case AgedStatus.Aged:
                return (
                    <img
                        src={process.env.PUBLIC_URL + '/assets/icon-clock-danger.svg'}
                        className={classes.icon}
                        alt="Aging Balance"
                    />
                );
            default:
                break;
        }
    };

    useEffect(() => {
        if (agedProducts) {
            const matchingProduct = agedProducts.find(
                (product) => product.productSku === activeProduct.productSku
            );
            if (matchingProduct) {
                // sort lines based on requested date
                let linkedOrderLines = [] as POLineAged[];
                matchingProduct.agedOrders.forEach((order) => {
                    order.agedOrder.lines?.forEach((line) => {
                        line.productionOrderId = order.agedOrder.productionOrderId;
                        line.productionOrderNumber = order.agedOrder.productionOrderNumber;
                        linkedOrderLines.push({
                            ...line,
                            agedStatus: order.agedStatus,
                            agedQuantity: order.agedQuantity,
                        });
                    });
                });
                linkedOrderLines.sort((a: POLineAged, b: POLineAged) => {
                    return (
                        parseInt(moment(a.requestedDate).format('X')) -
                        parseInt(moment(b.requestedDate).format('X'))
                    );
                });
                setPoLines(linkedOrderLines);
            }
        }
    }, [agedProducts, activeProduct]);

    return (
        <Grid container className={classes.main}>
            <Grid container item xs={12}>
                <Table
                    size="small"
                    aria-label="aged po table"
                    key={generateKey('aged po table')}
                    data-testid="scrap-it-aged-po-table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell
                                colSpan={2}
                                className={classes.columnHeaders}
                                data-testid="make-it-atm-orders"
                            >
                                <Trans i18nKey="productionOrders">Production Order(s)</Trans>
                            </TableCell>
                            <TableCell
                                colSpan={2}
                                className={classes.columnHeaders}
                                data-testid="requested-week"
                            >
                                <Trans i18nKey="requestedWeek">Requested Week</Trans>
                            </TableCell>
                            <TableCell
                                colSpan={1}
                                align="right"
                                className={classes.columnHeaders}
                                data-testid="quantity"
                            >
                                <Trans i18nKey="quantity">Quantity</Trans>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {poLines.length > 0 &&
                            poLines.map((linkedLine, index) => (
                                <TableRow key={'production-orders-table-row_' + index}>
                                    <TableCell colSpan={2}>
                                        <Link
                                            component={RouterLink}
                                            underline="always"
                                            to={`/make-it-po-order/${linkedLine.productionOrderId}`}
                                        >
                                            {linkedLine.productionOrderNumber ??
                                                t('pending', enUS.pending)}
                                        </Link>
                                    </TableCell>
                                    <TableCell colSpan={2}>
                                        {getMondayOfWeekFormatted(linkedLine.requestedDate!)}
                                    </TableCell>
                                    <TableCell align="right">
                                        {displayAgedIconStatus(linkedLine.agedStatus)}
                                        {linkedLine.agedQuantity &&
                                            formatNumberWithLocale(
                                                cultureCode,
                                                linkedLine.agedQuantity
                                            ) + ' PL'}
                                        <Typography variant="body1" className={classes.longValue}>
                                            {activeProduct.quantityPerPallet &&
                                                linkedLine.agedQuantity &&
                                                formatNumberWithLocale(
                                                    cultureCode,
                                                    linkedLine.agedQuantity *
                                                        activeProduct.quantityPerPallet
                                                ) + ' ea.'}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    );
};

export default ScrapItPOBalanceModal;
