import React, { ReactNode, useEffect, useState } from 'react';
import { Grid, Typography, makeStyles, useTheme, useMediaQuery } from '@material-ui/core';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Link from '../atoms/Link';
import {
    atmPurple,
    containerMaxWidth,
    playground,
    blackWeight
} from '../../../themes/globalConstants';
import clsx from 'clsx';
import { GetFeatureFlag, FlagTypes } from '../../../utility/helpers/feature-flag';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { shouldShowAtmBanner } from '../../../utility/helpers/banner-helpers';

interface Props {
    header: string;
    description?: string;
    imageUrl?: string;
    link?: string;
    linkContent?: ReactNode;
    buttonLink?: string;
    buttonContent?: ReactNode;
    buttonLinkTwo?: string;
    buttonContentTwo?: ReactNode;
    buttonIcon?: ReactNode;
    logoUrl?: string;
    targetString?: string;
    thinBanner?: boolean;
    kbMaxBanner?: boolean;
    center?: boolean;
}

const useStyles = makeStyles((theme) => ({
    banner: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '& h1': {
            color: theme.palette.common.white
        }
    },
    atmBanner: {
        backgroundColor: atmPurple,
        color: theme.palette.common.white,
        '& h1': {
            color: theme.palette.common.white
        }
    },
    playground: {
        backgroundColor: playground,
        color: theme.palette.common.white,
        '& h1': {
            color: theme.palette.common.white
        }
    },
    container: {
        maxWidth: containerMaxWidth,
        margin: 'auto',
        height: '16.875em',
        paddingLeft: '0.875em;'
    },
    thinContainer: {
        maxWidth: containerMaxWidth,
        marginLeft: '19.5em',
        height: '6.25em'
    },
    kbMaxContainer: {
        maxWidth: containerMaxWidth,
        margin: 'auto',
        height: '6.25em',
        padding: '0 1em 0 1.5em'
    },
    imageAnchor: {
        direction: 'rtl',
        paddingRight: '1em',
        height: '100%'
    },
    image: {
        maxWidth: '100%',
        height: '100%',
        objectFit: 'contain'
    },
    description: {
        marginTop: '1em',
        marginBottom: '1em',
        marginRight: '1.25em',
        [theme.breakpoints.down('xs')]: {
            marginTop: '1.5em'
        }
    },
    header: {
        marginTop: '0.5em',
        fontSize: 30
    },
    link: {
        fontSize: '0.75em',
        color: theme.palette.common.white,
        '& hr': {
            backgroundColor: theme.palette.common.white
        },
        '&:hover': {
            color: theme.palette.common.white
        }
    },
    secondLink: {
        fontSize: '1em',
        fontWeight: blackWeight,
        color: theme.palette.common.white,
        '& hr': {
            backgroundColor: theme.palette.common.white
        },
        '&:hover': {
            color: theme.palette.common.white
        }
    },
    linkContainer: {
        display: 'flex',
        marginTop: '1em',
        [theme.breakpoints.down('xs')]: {
            marginTop: '1.5em'
        }
    },
    linkIcon: {
        marginRight: '0.625em',
        '& svg': {
            fill: theme.palette.common.white
        }
    },
    logo: {
        maxWidth: '100%',
        maxHeight: '100%'
    },
    center: {
        justifyContent: 'center'
    }
}));

const PageBanner = ({
    header,
    description,
    imageUrl,
    link,
    linkContent,
    buttonLink,
    buttonContent,
    buttonLinkTwo,
    buttonContentTwo,
    buttonIcon,
    logoUrl,
    targetString,
    thinBanner,
    kbMaxBanner,
    center
}: Props) => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const isPlayground = GetFeatureFlag(FlagTypes.Playground);
    const [showATMBanner, setShowATMBanner] = useState<boolean>();
    const location = useLocation();
    const { regionCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { shipToAccounts, selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );

    useEffect(() => {
        setShowATMBanner(
            shouldShowAtmBanner(
                shipToAccounts || [],
                selectedAccountId || '',
                location.pathname,
                regionCode
            )
        );
    }, [shipToAccounts, selectedAccountId]);

    return (
        <div
            className={clsx(
                isPlayground
                    ? classes.playground
                    : showATMBanner
                    ? classes.atmBanner
                    : classes.banner
            )}
        >
            <Grid
                container
                item
                className={clsx({
                    [classes.center]: center,
                    [classes.kbMaxContainer]: kbMaxBanner,
                    [classes.thinContainer]: thinBanner,
                    [classes.container]: !thinBanner && !kbMaxBanner
                })}
                alignItems="center"
            >
                <Grid item xs={12} sm={6}>
                    {link && (
                        <Link
                            component={RouterLink}
                            to={link}
                            className={classes.link}
                            data-testid="header-link"
                            underline="hover"
                        >
                            {linkContent}
                        </Link>
                    )}
                    <Typography variant="h1" color="primary" className={classes.header}>
                        {header}
                    </Typography>
                    {description && (
                        <Typography variant="body1" className={classes.description}>
                            {description}
                        </Typography>
                    )}
                    {buttonLink && (
                        <div className={classes.linkContainer}>
                            {buttonIcon && <div className={classes.linkIcon}>{buttonIcon}</div>}
                            <Link
                                url={buttonLink}
                                underline="hover"
                                data-testid="header-button"
                                className={buttonLinkTwo ? classes.secondLink : classes.link}
                                target={targetString}
                            >
                                {buttonContent}
                            </Link>
                        </div>
                    )}
                    {buttonLinkTwo && (
                        <div className={classes.linkContainer}>
                            {buttonIcon && <div className={classes.linkIcon}>{buttonIcon}</div>}
                            <Link
                                url={buttonLinkTwo}
                                underline="hover"
                                data-testid="header-button"
                                className={classes.secondLink}
                                target={targetString}
                            >
                                {buttonContentTwo}
                            </Link>
                        </div>
                    )}
                </Grid>
                {/* Adds in Logo Url option for registration page */}
                {matches && !kbMaxBanner ? (
                    <Grid item xs={6} className={classes.imageAnchor}>
                        {imageUrl ? (
                            <img
                                className={classes.image}
                                src={imageUrl}
                                alt={header}
                                data-testid="header-image"
                            ></img>
                        ) : logoUrl ? (
                            <img
                                className={classes.logo}
                                src={logoUrl}
                                alt={header}
                                data-testid="ball-logo"
                            ></img>
                        ) : undefined}
                    </Grid>
                ) : undefined}
            </Grid>
        </div>
    );
};

export default PageBanner;
