import React, { ReactNode } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { boldWeight, medium } from '../../../themes/globalConstants';
import { useHistory } from 'react-router-dom';

interface Props {
    menuName: ReactNode;
    link: string;
}

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    button: {
        fontSize: medium,
        fontWeight: boldWeight,
        letterSpacing: '1px',
        textTransform: 'capitalize',
        color: theme.palette.common.black
    }
}));

const NavItem = ({ menuName, link }: Props) => {
    const classes = useStyles();
    const history = useHistory();

    const handleClick = () => {
        history.push(link);
    };

    return (
        <>
            <div className={classes.buttonContainer}>
                <Button
                    onClick={handleClick}
                    className={classes.button}
                    data-testid={`${menuName}-nav-btn`}
                >
                    {menuName}
                </Button>
            </div>
        </>
    );
};

export default NavItem;
