import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import Modal from '../../reusable/molecules/Modal';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { getGraphic } from '../../../store/actions/graphic-intake';
import GraphicsColorMatchingPDF from './components/GraphicsColorMatchingPDF';
import { Activity } from '../../../utility/auth/useSecurity';
import { DefaultQueryParams } from '../../../utility/helpers/query-helpers';

const GraphicsColorMatchingReadOnlyPage = () => {
    const { id } = useParams<DefaultQueryParams>();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const graphicIntake = useTypedSelector((state) => state.graphicIntake);
    const graphics = graphicIntake.graphics;

    const ballLogo = process.env.PUBLIC_URL + '/assets/Ball_RGB.jpg';
    const sourceLogo = process.env.PUBLIC_URL + '/assets/TheSource_Logo.png';

    const onClose = () => {
        window.close();
    };

    // need to grab graphic data since opening a new tab resets global state
    useEffect(() => {
        if (id) {
            dispatch(getGraphic(id));
        }
    }, [id, dispatch]);

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('submitGraphics', 'Submit Your Graphics'),
                description: '',
                thinBanner: true
            }}
            activity={Activity.GraphicsIntakeForm}
        >
            <Modal
                open={true}
                title="View Color Matching Information"
                close={onClose}
                closeIcon={true}
                fullScreen={true}
            >
                <div data-testId="graphics-color-matching-pdf">
                    <GraphicsColorMatchingPDF
                        title="Graphics Summary"
                        graphics={graphics}
                        sourceLogo={sourceLogo}
                        ballLogo={ballLogo}
                    />
                </div>
            </Modal>
        </ProcessingPageTemplate>
    );
};

export default GraphicsColorMatchingReadOnlyPage;
