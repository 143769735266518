import {
    Activity,
    filterShipToIdsByPermission,
    SecurityLevel
} from '../../utility/auth/useSecurity';
import OrdersService, { Order_Status } from '../../utility/services/orders-service';
import {
    SHIPPING_SUMMARY_LOAD_OPEN,
    SHIPPING_SUMMARY_LOAD_CLOSED,
    SHIPPING_SUMMARY_LOADING,
    SHIPPING_SUMMARY_UPDATED,
    SHIPPING_SUMMARY_LOADING_OPEN_ERROR,
    SHIPPING_SUMMARY_LOADING_CLOSED_ERROR
} from '../actions/action-types';
import { AuthState } from '../reducers/auth';
import { DeliveryOrder } from '../reducers/shipping-dashboard';

export const loadOpenDeliveryOrders = (accounts: number[]) => {
    return (dispatch, getState) => {
        dispatch({ type: SHIPPING_SUMMARY_LOADING });
        const auth: AuthState = getState().auth;
        OrdersService.getDeliveryOrders(auth.accessToken, {
            shipToIds: filterShipToIdsByPermission(
                auth,
                accounts,
                Activity.NewOpenDeliveryOrders,
                SecurityLevel.View
            ),
            statuses: [
                Order_Status.Open,
                Order_Status.Submit,
                Order_Status.EditedPending,
                Order_Status.Review
            ]
        })
            .then((response) => {
                dispatch({ type: SHIPPING_SUMMARY_LOAD_OPEN, orders: response.data.summaries });
            })
            .catch((error) => {
                dispatch({
                    type: SHIPPING_SUMMARY_LOADING_OPEN_ERROR,
                    error
                });
            });
    };
};

export const updateOpenDeliveryOrders = (deliveryOrder: DeliveryOrder) => {
    return (dispatch, getState) => {
        const state = getState();
        const openDeliveryOrders = state.shippingSummary.openDeliveryOrders.map((order) =>
            order.deliveryOrderId !== deliveryOrder.deliveryOrderId ? order : deliveryOrder
        );
        dispatch({
            type: SHIPPING_SUMMARY_UPDATED,
            orders: openDeliveryOrders
        });
    };
};

export const loadClosedDeliveryOrders = (accounts: number[]) => {
    return (dispatch, getState) => {
        dispatch({ type: SHIPPING_SUMMARY_LOADING });
        const auth: AuthState = getState().auth;
        OrdersService.getDeliveryOrders(auth.accessToken, {
            shipToIds: filterShipToIdsByPermission(
                auth,
                accounts,
                Activity.ClosedDeliveryOrders,
                SecurityLevel.View
            ),
            statuses: [
                Order_Status.Closed,
                Order_Status.Completed,
                Order_Status.Cancelled,
                Order_Status.CancelledPending
            ]
        })
            .then((response) => {
                dispatch({ type: SHIPPING_SUMMARY_LOAD_CLOSED, orders: response.data.summaries });
            })
            .catch((error) => {
                dispatch({
                    type: SHIPPING_SUMMARY_LOADING_CLOSED_ERROR,
                    error
                });
            });
    };
};
