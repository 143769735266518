import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
    withStyles,
    makeStyles,
    Typography,
    Accordion as MuiExpansionPanel,
    AccordionSummary as MuiExpansionPanelSummary,
    AccordionDetails
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { ballGray, blackWeight, xl } from '../../../../themes/globalConstants';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { AuthState } from '../../../../store/reducers/auth';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { isPersonaAccount } from '../../../../utility/helpers/user-helpers';
import { Persona } from '../../../../utility/auth/useSecurity';

const useStyles = makeStyles((theme) => ({
    cancellingOrdersText: {
        marginBottom: '1em'
    },
    container: {
        paddingRight: '1em'
    },
    details: {
        display: 'block',
        padding: 0
    },
    expandIcon: {
        color: theme.palette.primary.main,
        marginLeft: '1em',
        fontSize: 30
    },
    expandedIcon: {
        transform: 'rotate(180deg)'
    },
    title: {
        color: ballGray,
        fontWeight: blackWeight,
        fontSize: xl
    }
}));

const StyledExpansionPanel = withStyles((theme) => ({
    root: {
        boxShadow: 'none',
        borderRadius: '0',
        background: 'transparent',
        '&:before': {
            display: 'none'
        },
        '&$expanded': {
            minHeight: '4em',
            margin: 0
        }
    }
}))(MuiExpansionPanel);

const StyledExpansionPanelSummary = withStyles((theme) => ({
    root: {
        borderRadius: '0',
        padding: 0,
        minHeight: '4em',
        '&$expanded': {
            minHeight: '4em',
            margin: 0
        }
    },
    content: {
        alignItems: 'center'
    },
    expanded: {
        color: theme.palette.primary.main
    }
}))(MuiExpansionPanelSummary);

export default function EditShipmentsDisclaimerSection({ leadTime }) {
    const classes = useStyles();
    const [expand, setExpand] = useState<boolean | true>(true);
    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);
    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const [isCopacker, setIsCopacker] = useState<boolean>(false);

    useTranslation();

    useEffect(() => {
        if (permissions && shipToAccounts) {
            setIsCopacker(
                shipToAccounts.some((account) => {
                    return isPersonaAccount(permissions, Persona.CoPacker, account.accountId);
                })
            );
        }
    }, [permissions, shipToAccounts]);

    const handlePanelClick = () => {
        setExpand(!expand);
    };

    return (
        <StyledExpansionPanel
            expanded={expand}
            onChange={handlePanelClick}
            className={classes.container}
        >
            <StyledExpansionPanelSummary>
                <Typography variant="h3" className={classes.title}>
                    <Trans i18nKey="needToMakeChange">Need to make a change?</Trans>
                </Typography>
                <ExpandMoreIcon
                    className={clsx(classes.expandIcon, { [classes.expandedIcon]: expand })}
                />
            </StyledExpansionPanelSummary>
            <AccordionDetails className={classes.details}>
                <Typography variant="body1">
                    <Trans i18nKey="editShipments24Hours">
                        Up to 24 hours prior to shipment date and time, you may:
                    </Trans>
                </Typography>
                <ul>
                    <li>
                        <Typography variant="body1">
                            <Trans i18nKey="editShipmentsOption1">
                                Add/Remove a product/graphic version from the overall order or an
                                individual shipment
                            </Trans>
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                            <Trans i18nKey="editShipmentsOption2">
                                Update the quantity of a product for a shipment
                            </Trans>
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                            <Trans i18nKey="editShipmentsOption3">
                                Remove a shipment from a delivery order
                            </Trans>
                        </Typography>
                    </li>
                    {isCopacker ? (
                        <li>
                            <Typography variant="body1">
                                <Trans i18nKey="editShipmentsOption6">
                                    Update delivery order date / time within the allowable delivery
                                    window
                                </Trans>
                            </Typography>
                        </li>
                    ) : (
                        leadTime && (
                            <li>
                                <Typography variant="body1">
                                    <Trans i18nKey="editShipmentsOption7">
                                        Update a delivery order date or time
                                    </Trans>
                                </Typography>
                            </li>
                        )
                    )}
                    <li>
                        <Typography variant="body1">
                            <Trans i18nKey="editShipmentsOption5">
                                Update delivery instructions, release number, filler line, and
                                reference number
                            </Trans>
                        </Typography>
                    </li>
                </ul>
                <Typography variant="body1" className={classes.cancellingOrdersText}>
                    <Trans i18nKey="editShipmentsCancellingOrders">
                        Cancelling Orders - Cancellations must be completed at least 24 hours prior
                        to the earliest shipment date on the delivery order.
                    </Trans>
                </Typography>
                <Typography variant="body1">
                    <Trans i18nKey="editShipmentsContactRep">
                        Please contact a Ball sales representative if you need to request a change
                        different than those above.
                    </Trans>
                </Typography>
            </AccordionDetails>
        </StyledExpansionPanel>
    );
}
