import { Checkbox, makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Trans } from 'react-i18next';
import {
    disabledText,
    ltBlueGrey_15,
    regularWeight,
    small
} from '../../../../themes/globalConstants';
import { AddProductItem } from './models/AddProductItem';
import clsx from 'clsx';
interface Props {
    product: AddProductItem;
    onProductAddedChange: (productSku: string | undefined) => void;
}

const useStyles = makeStyles((theme) => ({
    tableRowContainer: {
        '& .helper-text': {
            fontWeight: `${regularWeight} !important`,
            fontSize: small
        },
        '&:hover': {
            backgroundColor: ltBlueGrey_15
        },
        '& > td': {
            padding: '1.250em'
        },
        '& .MuiInputBase-input': {
            textAlign: 'right',
            paddingRight: '.25em'
        },
        '& .MuiTableCell-root': {
            '&:not(:last-child)': {
                paddingLeft: '1.125em'
            }
        },
        '& .normal': {
            fontWeight: `${regularWeight} !important`,
            textAlign: 'right'
        }
    },
    displayId: {
        fontWeight: 'bold'
    },
    disabled: {
        color: disabledText
    },
}));

export default function AddProductsModalRow({ product, onProductAddedChange }: Props) {
    const classes = useStyles();
    const [isAddDisabled, setIsAddDisabled] = useState<boolean>(false);
    const [isChecked, setIsChecked] = useState<boolean>(false);

    useEffect(() => {
        setIsAddDisabled(product.addDisabled && product.addDisabled ? true : false);
        if (product.addDisabled || product.isAdded) {
            setIsChecked(true);
        } else {
            setIsChecked(false);
        }
    }, [product]);

    const onAddCheckboxChanged = () => {
        onProductAddedChange(product.productSku);
    };

    return (
        <TableRow
            className={classes.tableRowContainer}
            data-testid={`product-${product.displayId}`}
        >
            <TableCell data-testid="product-id">
                <Typography
                    className={clsx(classes.displayId, { [classes.disabled]: isAddDisabled })}
                >
                    {product.displayId}
                </Typography>
                <Typography className={clsx({ [classes.disabled]: isAddDisabled })}>
                    {product.graphicIdAndVersion}
                </Typography>
            </TableCell>
            <TableCell data-testid="display-name">
                <Typography className={clsx({ [classes.disabled]: isAddDisabled })}>
                    {product.displayName}
                </Typography>
            </TableCell>
            <TableCell data-testid="coating">
                <Typography className={clsx({ [classes.disabled]: isAddDisabled })}>
                    {product.coating}
                </Typography>
            </TableCell>
            <TableCell data-testid="lead-time-weeks">
                <Typography className={clsx({ [classes.disabled]: isAddDisabled })}>
                    {product.leadTimeWeeks && `${product.leadTimeWeeks} `}
                    <Trans i18nKey="weeks">Weeks</Trans>
                </Typography>
            </TableCell>
            <TableCell data-testid="product-status">
                <Typography className={clsx({ [classes.disabled]: isAddDisabled })}>
                    {product.status}
                </Typography>
            </TableCell>
            <TableCell data-testid="add-checkbox">
                <div style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <Checkbox
                        checked={isChecked}
                        onChange={onAddCheckboxChanged}
                        disabled={product.addDisabled}
                    />

                    <Typography className={clsx({ [classes.disabled]: isAddDisabled })}>
                        {product.addDisabled ?
                            <Trans i18nKey="added">
                                Added
                            </Trans> :
                            <Trans i18nKey="add" >
                                Add
                            </Trans>}
                    </Typography>
                </div>

            </TableCell>
        </TableRow>
    );
}
