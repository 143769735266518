import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import { ProductToShip, ShippingState } from '../../../../store/reducers/shipping-dashboard';
import ShipItConfigRow from './ShipItConfigRow';
import {
    blackWeight,
    ballGray,
    small,
    boldWeight,
    informationAlert,
    black
} from '../../../../themes/globalConstants';
import ShipItConfigColumns from './ShipItConfigColumns';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { changeAllExpandableLoads } from '../../../../store/actions/shipping-dashboard';
import { useDispatch } from 'react-redux';
import ShipItConfigToolbar from '../../../reusable/molecules/ShipItConfigToolbar';
import { Alert } from '@material-ui/lab';
import { Trans } from 'react-i18next';
import { enUS } from '../../../../utility/translations/locales';

interface Props {
    shipmentId?: string;
    items: ProductToShip[];
    onUpdateItem: (item: ProductToShip) => void;
    onRemoveItem: (item: ProductToShip) => void;
    warning?: boolean;
    warningText?: React.ReactNode;
    readOnly?: boolean;
    maxPallets?: number;
    availBalanceWarning?: boolean;
}

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        width: '100%',
        '& .MuiPaper-root': {
            boxShadow: 'unset'
        },
        '& .MuiToolbar-root': {
            display: 'flex',
            flexDirection: 'column',
            padding: '0 0.125em',
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                }
            }
        },
        '& .MuiTableSortLabel-root': {
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em'
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableCell-head': {
            color: ballGray,
            fontSize: small,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            '&:not(:first-of-type)': {
                borderLeft: 'unset'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                '&:nth-child(1)': {
                    width: '9%'
                },
                '&:nth-child(2)': {
                    width: '14%'
                },
                '&:nth-child(3)': {
                    width: '22%'
                },
                '&:nth-child(4)': {
                    width: '10%'
                },
                '&:nth-child(5)': {
                    width: '27%'
                },
                '&:nth-child(6)': {
                    width: '9%'
                },
                '&:nth-child(7)': {
                    width: '9%'
                }
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    },
    informationalAlert: {
        backgroundColor: informationAlert,
        borderRadius: '.125em',
        alignItems: 'center',
        maxHeight: '2em'
    },
    informationAlertText: {
        fontWeight: boldWeight,
        fontSize: small,
        color: black
    }
}));

export default function ShipItConfigGrid({
    shipmentId,
    items,
    onRemoveItem,
    onUpdateItem,
    warning = false,
    warningText,
    readOnly = false,
    maxPallets,
    availBalanceWarning
}: Props) {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const dispatch = useDispatch();
    const { quantityUnit } = useTypedSelector<ShippingState>((state) => state.shippingDashboard);

    const onCollapseAll = () => {
        if (shipmentId) {
            dispatch(changeAllExpandableLoads(shipmentId, false));
        }
    };
    const onExpandAll = () => {
        if (shipmentId) {
            dispatch(changeAllExpandableLoads(shipmentId, true));
        }
    };

    const CustomToolbarOptions = new ToolbarOptions({
        customItems: (
            <>
                <ShipItConfigToolbar
                    onCollapseAll={onCollapseAll}
                    onExpandAll={onExpandAll}
                    warning={warning}
                    warningText={warningText}
                    maxPallets={maxPallets}
                    availBalanceWarning={availBalanceWarning}
                />
                {quantityUnit === 'eaches' && (
                    <Alert
                        className={classes.informationalAlert}
                        icon={false}
                        data-testid="eaches-alert"
                    >
                        <Typography className={classes.informationAlertText}>
                            <Trans i18nKey="quantitiesRounded">{enUS.quantitiesRounded}</Trans>
                        </Typography>
                    </Alert>
                )}
            </>
        ),
        exportButton: false,
        printButton: false,
        advancePagination: false,
        enablePagination: false,
        searchText: false
    });

    const CustomerMakeItRow = ({ row }: { row: Partial<ProductToShip> }) => {
        let itemIndex = items.findIndex((item) => item.productSku === row.productSku);
        return (
            <ShipItConfigRow
                shipmentId={shipmentId}
                item={row as ProductToShip}
                onRemoveItem={onRemoveItem}
                sequence={itemIndex + 1}
                onUpdateItem={onUpdateItem}
                readOnly={readOnly}
            />
        );
    };

    return (
        <Grid container className={classes.tableWrapper}>
            <DataGrid
                columns={ShipItConfigColumns(cultureCode, false)}
                dataSource={items}
                gridName="Ship it Config Grid"
                rowComponent={CustomerMakeItRow}
                toolbarOptions={CustomToolbarOptions}
            />
        </Grid>
    );
}
