import ReactPdf from '@react-pdf/renderer';
import {
    ballBlue,
    ballGray,
    ltBlueGrey,
    ballDrkBlue,
    lato,
    blackWeight,
    ballLtGray,
    regularWeight,
    boldWeight
} from './globalConstants';

ReactPdf.Font.register({
    family: 'Lato',
    src: '../../../source/assets/Lato.ttf'
});

ReactPdf.Font.register({
    family: 'Lato Black',
    src: '../../../source/assets/Lato-Black.ttf'
});

ReactPdf.Font.register({
    family: 'Lato Bold',
    src: '../../../source/assets/Lato-Bold.ttf'
});

export const pdfUtilClasses = ReactPdf.StyleSheet.create({
    fontNormal: {
        fontFamily: 'Lato'
    },
    bold: {
        fontFamily: 'Lato Bold'
    },
    black: {
        fontFamily: 'Lato Black'
    },
    textSmall: {
        fontSize: '8px'
    },
    textNormal: {
        fontSize: '10px'
    },
    textLarge: {
        fontSize: '16px'
    },
    darkGray: {
        color: '#464646'
    },
    uppercase: {
        textTransform: 'uppercase'
    }
});

const pdfStyles = ReactPdf.StyleSheet.create({
    page: {
        padding: 50,
        font: lato,
        display: 'flex',
        paddingBottom: 60
    },
    pagePadding25: {
        padding: 25,
        font: lato,
        display: 'flex',
        paddingBottom: 60
    },
    section: {
        marginBottom: 10
    },
    flexSection: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start'
    },
    flexSection1: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'stretch',
        justifyContent: 'space-between'
    },
    flexSection2: {
        display: 'flex',

        justifyContent: 'flex-end'
    },
    flexSection3: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    image: {
        marginVertical: 15,
        marginHorizontal: 100
    },
    header: {
        color: ballBlue,
        fontSize: 20,
        fontWeight: 'extrabold',
        marginBottom: 10
    },
    header2: {
        color: ballGray,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        font: lato,
        fontSize: 8
    },
    header3: {
        color: ballGray,
        fontWeight: 'bold',
        font: lato,
        fontSize: 14,
        marginBottom: 12
    },
    header4: {
        color: ballGray,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        font: lato,
        fontSize: 8,
        textAlign: 'right'
    },
    header5: {
        color: 'black',
        fontSize: 20,
        fontWeight: 'extrabold',
        marginBottom: 10,
        textTransform: 'uppercase',
        font: lato
    },
    header6: {
        color: 'black',
        fontSize: 12,
        fontWeight: boldWeight,
        marginTop: 10,
        marginBottom: 5,
        textTransform: 'uppercase',
        font: lato
    },
    header7: {
        color: 'black',
        fontSize: 20,
        fontWeight: 'extrabold',
        marginBottom: 4,
        textTransform: 'uppercase',
        font: lato
    },
    header8: {
        color: 'black',
        fontSize: 12,
        fontWeight: 'extrabold',
        textTransform: 'uppercase',
        font: lato
    },
    header8Lower: {
        color: 'black',
        fontSize: 12,
        fontWeight: 'extrabold',
        font: lato
    },
    header9: {
        color: 'black',
        fontSize: 24,
        fontWeight: 'extrabold',
        textTransform: 'uppercase',
        font: lato
    },
    header10: {
        color: ballGray,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        font: lato,
        fontSize: 8,
        marginBottom: 2
    },
    header11: {
        color: ballGray,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        font: lato,
        fontSize: 10,
        marginBottom: 0,
        paddingBottom: 0
    },
    header12: {
        color: 'black',
        fontSize: 10,
        fontWeight: 'extrabold',
        font: lato
    },
    header13: {
        color: 'black',
        fontSize: 8,
        fontWeight: 'extrabold',
        font: lato
    },
    subtitle: {
        color: ballGray,
        marginBottom: 8,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: 8
    },
    subtitle2: {
        color: 'black',
        fontSize: 12,
        fontWeight: blackWeight,
        font: lato,
        marginTop: -5,
        marginBottom: 3
    },
    subtitle3: {
        color: 'black',
        fontSize: 10,
        fontWeight: blackWeight,
        font: lato,
        marginTop: -2
    },
    subtitle4: {
        color: 'black',
        fontSize: 10,
        fontWeight: blackWeight,
        font: lato,
        marginTop: -2,
        textAlign: 'right'
    },
    subtitle5: {
        color: 'black',
        fontSize: 12,
        fontWeight: blackWeight,
        font: lato,
        marginTop: -2,
        textAlign: 'right'
    },
    subtitle6: {
        color: 'black',
        fontSize: 12,
        fontWeight: blackWeight,
        font: lato,
        marginTop: -2
    },
    subtitle7: {
        color: 'black',
        fontSize: 8,
        fontWeight: blackWeight,
        font: lato,
        marginTop: -2
    },
    subtitle8: {
        color: 'black',
        fontSize: 10,
        fontWeight: boldWeight,
        font: lato,
        marginTop: -2
    },
    subtitle9: {
        color: 'extrabold',
        fontSize: 10,
        fontWeight: blackWeight,
        font: lato,
        marginTop: -2
    },
    subtitle10: {
        color: 'black',
        fontSize: 10,
        fontWeight: blackWeight,
        font: lato,
        marginTop: 2
    },
    subtitle14: {
        color: 'black',
        fontSize: 14,
        fontWeight: blackWeight,
        font: lato,
        marginTop: 2
    },
    subtitle15: {
        color: 'black',
        fontSize: 8,
        fontWeight: blackWeight,
        font: lato,
        textAlign: 'right'
    },
    sectionHeader: {
        fontWeight: 'extrabold',
        fontSize: 12,
        color: ballDrkBlue,
        marginBottom: 16,
        textDecoration: 'underline'
    },
    divider: {
        display: 'flex',
        height: 3,
        width: '100%',
        backgroundColor: ltBlueGrey,
        opacity: 0.34,
        marginTop: 4,
        marginBottom: 5
    },
    divider2: {
        display: 'flex',
        height: 3,
        width: '100%',
        backgroundColor: ballLtGray,
        opacity: 0.34,
        marginBottom: 2,
        marginTop: 4
    },
    divider25: {
        display: 'flex',
        height: 2,
        width: '100%',
        backgroundColor: ballLtGray,
        opacity: 0.34,
        marginBottom: 2,
        marginTop: 4
    },
    divider3: {
        display: 'flex',
        height: 4,
        width: '100%',
        backgroundColor: 'black',
        opacity: 0.34,
        marginBottom: 2,
        marginTop: 4
    },
    divider4: {
        display: 'flex',
        height: 1,
        width: '84%',
        backgroundColor: 'black',
        opacity: 0.34,
        marginBottom: 2,
        marginTop: 4
    },
    divider5: {
        display: 'flex',
        height: 2,
        width: '100%',
        opacity: 0.8,
        backgroundColor: 'black',
        marginBottom: 2,
        marginTop: 4
    },
    divider6: {
        display: 'flex',
        height: 3,
        width: '100%',
        backgroundColor: ltBlueGrey,
        opacity: 0.34,
        marginTop: 3,
        marginBottom: 5
    },
    divider7: {
        display: 'flex',
        height: 3,
        width: '100%',
        backgroundColor: ballLtGray,
        opacity: 0.34,
        marginBottom: 2,
        marginTop: 2
    },
    divider8: {
        display: 'flex',
        height: 3,
        width: '100%',
        backgroundColor: ballLtGray,
        opacity: 0.34,
        marginBottom: 2,
        marginTop: 10
    },
    dividerThinGray: {
        display: 'flex',
        height: 1,
        width: '125%',
        backgroundColor: ballLtGray,
        marginBottom: 2,
        marginTop: 4
    },
    dividerThinGray2: {
        display: 'flex',
        height: 1,
        width: '100%',
        backgroundColor: ballLtGray,
        marginBottom: 2,
        marginTop: 4
    },
    dividerGray: {
        display: 'flex',
        height: 1,
        width: '100%',
        backgroundColor: ballGray,
        marginBottom: 2,
        marginTop: 4
    },
    dividerThinBlack: {
        display: 'flex',
        height: 1,
        width: '100%',
        backgroundColor: 'black',
        marginBottom: 8,
        marginTop: 6
    },
    paragraph: {
        fontSize: 12,
        fontWeight: 'normal',
        marginBottom: 6
    },
    textOnlyField: {
        fontSize: 12,
        fontWeight: 'bold',
        marginTop: 10,
        width: '100%'
    },
    fieldLabel: {
        fontSize: 10,
        fontWeight: 'normal',
        marginBottom: 4
    },
    fieldLabel2: {
        fontSize: 10,
        fontWeight: 'normal'
    },
    field: {
        width: '50%',
        marginBottom: 12
    },
    fieldText: {
        fontSize: 10,
        fontWeight: 'normal'
    },
    table: {
        display: 'table' as any,
        width: 'auto',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    borderlessTable: {
        display: 'table' as any,
        width: 'auto'
    },
    borderlessTable2: {
        display: 'table' as any,
        width: 'auto',
        alignItems: 'flex-end'
    },
    borderlessTable3: {
        display: 'table' as any,
        width: '53%'
    },
    borderlessTable4: {
        display: 'table' as any,
        width: '45%'
    },
    borderlessTable5: {
        display: 'table' as any,
        width: 'auto',
        marginTop: 10
    },
    borderlessTable6: {
        display: 'table' as any,
        width: 'auto',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        margin: '15 0 25 0'
    },
    tableRow: {
        margin: 'auto',
        flexDirection: 'row'
    },
    tableRow2: {
        margin: 'auto',
        flexDirection: 'row',
        width: '84%',
        alignItems: 'flex-end'
    },

    tableRow3: {
        margin: 'auto',
        flexDirection: 'row',
        alignItems: 'flex-end'
    },
    tableCol: {
        padding: 2,
        width: '25%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableColWide: {
        padding: 2,
        width: '45%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableColNarrow: {
        padding: 2,
        width: '12%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    borderLessTableCol: {
        padding: 2,
        width: '25%',
        borderStyle: 'solid',
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    borderLessTableCol1: {
        padding: 2,
        width: '100%',
        borderStyle: 'solid',
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    borderLessTableColWide: {
        padding: 2,
        width: '50%',
        borderStyle: 'solid',
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    borderLessTableColWide2: {
        padding: 2,
        width: '70%',
        borderStyle: 'solid',
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        marginBottom: 3
    },
    borderLessTableColWide3: {
        padding: 2,
        width: '30%',
        borderStyle: 'solid',
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    borderLessTableColWide4: {
        padding: 2,
        width: '85%',
        borderStyle: 'solid',
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    borderLessTableColWide5: {
        padding: 2,
        width: '60%',
        borderStyle: 'solid',
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        marginBottom: 3
    },
    borderLessTableColWide6: {
        padding: 2,
        width: '55%',
        borderStyle: 'solid',
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        marginBottom: -7
    },
    borderLessTableColWide7: {
        padding: 2,
        width: '45%',
        borderStyle: 'solid',
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    borderLessTableColWide8: {
        padding: 2,
        width: '80%',
        borderStyle: 'solid',
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    borderLessTableColWide40: {
        padding: 2,
        width: '40%',
        borderStyle: 'solid',
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    fullWidth: {
        width: '90%'
    },
    borderLessTableColNarrow: {
        padding: 2,
        width: '15%',
        borderStyle: 'solid',
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        marginBottom: 8
    },
    borderLessTableColNarrowTitle: {
        padding: 2,
        width: '20%',
        borderStyle: 'solid',
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        marginBottom: 8
    },
    borderLessTableColNarrow2: {
        padding: 2,
        width: '15%',
        borderStyle: 'solid',
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        marginBottom: -7
    },
    borderLessTableColNarrow3: {
        padding: 2,
        width: '20%',
        borderStyle: 'solid',
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    borderLessTableColNarrow4: {
        padding: 2,
        width: '30%',
        borderStyle: 'solid',
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    borderLessTableColNarrow5: {
        padding: 2,
        width: '30%',
        borderStyle: 'solid',
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        marginBottom: -7
    },
    borderLessTableColNarrow35: {
        padding: 2,
        width: '35%',
        borderStyle: 'solid',
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        marginBottom: -7
    },
    borderLessTableColNarrow6: {
        padding: 2,
        width: '40%',
        borderStyle: 'solid',
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    borderLessTableColNarrow7: {
        padding: 2,
        width: '20%',
        borderStyle: 'solid',
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        marginBottom: -7
    },
    borderLessTableColNarrow8: {
        padding: 2,
        width: '15%',
        borderStyle: 'solid',
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    borderLessTableColNarrow9: {
        padding: 2,
        width: '10%',
        borderStyle: 'solid',
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        marginBottom: -7
    },
    borderLessTableColNarrow10: {
        padding: 2,
        width: '20%',
        borderStyle: 'solid',
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    borderLessTableColNarrow17: {
        padding: 2,
        width: '22%',
        borderStyle: 'solid',
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    borderLessTableColNarrow11: {
        padding: 2,
        width: '27%',
        borderStyle: 'solid',
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    borderLessTableColNarrow12: {
        padding: 2,
        width: '9%',
        borderStyle: 'solid',
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    borderLessTableColNarrow13: {
        padding: 2,
        width: '6%',
        borderStyle: 'solid',
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    borderLessTableColNarrow14: {
        padding: 2,
        width: '10%',
        borderStyle: 'solid',
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        marginBottom: 0
    },
    borderLessTableColNarrow25: {
        padding: 2,
        width: '25%',
        borderStyle: 'solid',
        borderWidth: 0,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCell: {
        margin: 'auto',
        marginTop: 5,
        fontSize: 10
    },
    tableCell2: {
        margin: 'auto',
        fontSize: 10,
        fontWeight: regularWeight
    },
    tableCell3: {
        margin: 'auto',
        fontSize: 10,
        fontWeight: regularWeight,
        textAlign: 'right'
    },
    tableCell4: {
        margin: 'auto',
        fontSize: 9,
        fontWeight: regularWeight
    },

    tableCell5: {
        margin: 'auto',
        fontSize: 8,
        fontWeight: regularWeight
    },
    tableCellHeader: {
        margin: 'auto',
        marginTop: 5,
        fontSize: 10,
        borderTopWidth: 0
    },
    pageHeader: {
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    },
    fieldValue: {
        fontSize: 12,
        fontWeight: 'black'
    },
    endAligned: {
        alignItems: 'baseline',
        marginBottom: 2
    },
    colorHeader: {
        fontSize: 30,
        color: ballBlue,
        fontWeight: 'extrabold',
        marginBottom: 30
    },
    colorSubHeader: {
        color: ballGray,
        fontSize: 10,
        marginTop: 60,
        marginBottom: 8,
        textTransform: 'uppercase'
    },
    shippingAddress: {
        marginTop: 50
    },
    shippingText: {
        marginBottom: 2
    },
    logoSection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'baseline'
    },
    ballLogo: {
        height: 45,
        width: 45
    },
    sourceLogo: {
        height: 45,
        width: 150
    },
    uppercase: {
        textTransform: 'uppercase'
    },
    footer: {
        position: 'absolute',
        left: '53%',
        bottom: 0,
        right: 0,
        marginBottom: 6
    },
    disclaimerFooter: {
        position: 'absolute',
        left: '10%',
        bottom: 0,
        right: 0,
        marginBottom: 6,
        width: '100%',
        height: '50px'
    },
    disclaimer: {
        textAlign: 'center',
        marginBottom: 2
    },
    textAlignRight: {
        textAlign: 'right'
    },
    textAlignLeft: {
        textAlign: 'left'
    },
    textAlignCenter: {
        textAlign: 'center'
    },
    marginRight3: {
        marginRight: 3
    },
    marginRight10: {
        marginRight: 10
    },

    marginRight20: {
        marginRight: 20
    },
    marginTop20: {
        marginTop: 20
    },
    marginTop10: {
        marginTop: 10
    },
    marginTop5: {
        marginTop: 5
    },
    marginTop3: {
        marginTop: 3
    },
    marginBottom5: {
        marginBottom: 5
    },
    marginBottom3: {
        marginBottom: 3
    },
    marginBottom10: {
        marginBottom: 10
    },
    marginLeft10: {
        marginLeft: 10
    },
    width10: {
        width: '10%'
    },
    width15: {
        width: '15%'
    },
    width20: {
        width: '20%'
    },
    width25: {
        width: '25%'
    },
    phHeader: {
        color: ballGray,
        fontWeight: 'bold',
        font: lato,
        fontSize: 8
    },
    russianPage: {
        fontFamily: 'Roboto',
        padding: 50,
        display: 'flex',
        paddingBottom: 60
    }
});

export default pdfStyles;
