import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class PendingPaymentColumns {
    static getPendingPaymentColumns = (culture: string) => {
        return [
            createColumn('customerProductionOrderId', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'purchaseOrderNumber'),
                filterable: true,
                sortable: true,
                searchable: true
            }),
            createColumn('productionOrderNumber', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'ballProdOrderNumber'),
                filterable: true,
                sortable: true,
                searchable: true
            }),
            createColumn('shipToId', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('shipToString', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'shipTo'),
                filterable: true,
                sortable: true,
                searchable: true
            }),
            createColumn('amount', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'invoiceAmount'),
                filterable: true,
                sortable: true,
                searchable: true
            }),
            createColumn('submittedDate', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'orderSubmittedDate'),
                filterable: true,
                sortable: true,
                searchable: true
            }),
            createColumn('paymentDueDate', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'paymentDueDate'),
                filterable: true,
                sortable: true,
                searchable: true
            }),
            createColumn('paymentSubmitted', {
                label: TranslationService.getTranslatedText(culture, 'paymentSubmitted')
            }),
            createColumn('status', {
                visible: false
            }),
            createColumn('customerProductionOrderId', {
                visible: false
            }),
            createColumn('invoiceId', {
                visible: false
            })
        ];
    };
}
