import { TableRow, TableCell, Typography, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '../../../reusable/atoms/Link';
import { boldWeight, medium } from '../../../../themes/globalConstants';
import { ScrapItRequest } from '../../../../store/reducers/scrap-it';

interface Props {
    line: ScrapItRequest;
}

const useStyles = makeStyles(() => ({
    name: {
        fontWeight: boldWeight,
        fontSize: medium,
        letterSpacing: 0.17
    }
}));

const ScrapItSummaryRow = ({ line }: Props) => {
    const classes = useStyles();

    return (
        <TableRow hover={true}>
            <TableCell data-testid={'scrap-it-request-id'}>
                <Grid container spacing={1}>
                    <Grid item className={classes.name}>
                        <Link
                            underline="none"
                            component={RouterLink}
                            to={`/scrap-it-request-details/${line.requestOrderNumber}`}
                        >
                            {line.requestOrderNumber}
                        </Link>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell data-testid={'quantity-to-scrap'}>
                <Typography className={classes.name}>{line.requestedQuantity} PL</Typography>
                <Typography>{line.quantityEaches} ea</Typography>
            </TableCell>
            <TableCell data-testid={'submitted-date'}>
                <Typography>{line.submittedDate}</Typography>
            </TableCell>
            <TableCell data-testid={'status'}>
                <Typography className={classes.name}>{line.status}</Typography>
            </TableCell>
        </TableRow>
    );
};

export default ScrapItSummaryRow;
