import React, { useEffect, useState } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { black, blackWeight, boldWeight, ltBlueGrey_34 } from '../../../../themes/globalConstants';
import { generateKey } from '../../../../utility/helpers/order-helpers';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import PlanItBulkReviewOrderSectionColumns from './PlanItBulkReviewOrderSectionColumns';
import clsx from 'clsx';
import {
    BulkUploadPlanItATMState,
    PlanItBulkATMLineItem
} from '../../../../store/reducers/planit-bulk-atm';
import PlanItBulkReviewOrderSectionRow from './PlanItBulkReviewOrderSectionRow';

interface Props {
    lines: PlanItBulkATMLineItem[];
    hasMultiplePersonas: boolean;
}

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        width: '100%',
        marginTop: '1em',
        '& .MuiToolbar-root': {
            minHeight: 0,
            padding: '0 0.125em',
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                }
            }
        },
        '& .MuiTableCell-head': {
            justifyContent: 'space-between',
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em'
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTablePagination-root': {
            '& .MuiTablePagination-caption': {
                marginLeft: '1em',
                fontWeight: boldWeight,
                color: black
            }
        },
        '& .MuiTableHead-root': {
            borderTop: '5px solid',
            borderTopColor: ltBlueGrey_34,
            '& th': {
                borderLeft: 'none',
                '&:nth-child(1)': {
                    width: '8%'
                },
                '&:nth-child(2)': {
                    width: '11%'
                },
                '&:nth-child(3)': {
                    width: '28%'
                },
                '&:nth-child(4)': {
                    width: '13%'
                },
                '&:nth-child(5)': {
                    width: '13%'
                },
                '&:nth-child(6)': {
                    width: '25%'
                }
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    },
    editAtmTableWrapper: {
        '& .MuiTableHead-root': {
            '& th': {
                '&:nth-child(1)': {
                    width: '6%'
                },
                '&:nth-child(2)': {
                    width: '11%'
                },
                '&:nth-child(3)': {
                    width: '18%'
                },
                '&:nth-child(4)': {
                    width: '8%'
                },
                '&:nth-child(5)': {
                    width: '8%'
                },
                '&:nth-child(6)': {
                    width: '20%'
                },
                '&:nth-child(7)': {
                    width: '15%'
                },
                '&:nth-child(8)': {
                    width: '14%'
                }
            }
        }
    },
    planItOnlyTableWrapper: {
        '& .MuiTableHead-root': {
            '& th': {
                '&:nth-child(1)': {
                    width: '15%'
                },
                '&:nth-child(2)': {
                    width: '25%'
                },
                '&:nth-child(3)': {
                    width: '15%'
                },
                '&:nth-child(4)': {
                    width: '20%'
                },
                '&:nth-child(5)': {
                    width: '20%'
                }
            }
        }
    },
    makeItGuidedTableWrapper: {
        '& .MuiTableHead-root': {
            '& th': {
                '&:nth-child(1)': {
                    width: '12%'
                },
                '&:nth-child(2)': {
                    width: '20%'
                },
                '&:nth-child(3)': {
                    width: '10%'
                },
                '&:nth-child(4)': {
                    width: '8%'
                },
                '&:nth-child(5)': {
                    width: '16%'
                },
                '&:nth-child(6)': {
                    width: '8%'
                },
                '&:nth-child(7)': {
                    width: '12%'
                },
                '&:nth-child(8)': {
                    width: '14%'
                }
            }
        }
    }
}));

const PlanItBulkReviewOrderSectionProducts = ({ lines, hasMultiplePersonas = false }: Props) => {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [activeLines, setActiveLines] = useState<PlanItBulkATMLineItem[]>([]);
    const [columns, setColumns] = useState<any>([]);
    const { isEdit } = useTypedSelector<BulkUploadPlanItATMState>(
        (state) => state.bulkUploadPlanIt
    );

    useEffect(() => {
        setColumns(
            PlanItBulkReviewOrderSectionColumns.getColumns(
                cultureCode,
                !isEdit,
                hasMultiplePersonas
            )
        );
    }, [cultureCode, isEdit, hasMultiplePersonas]);

    useEffect(() => {
        setActiveLines(lines.filter((line) => !line.deleted));
    }, [lines]);

    const CustomToolbarOptions = new ToolbarOptions({
        exportButton: false,
        printButton: false,
        enablePagination: false,
        searchText: false,
        itemsPerPage: lines.length
    });

    const ProductRow = ({ row }: { row: PlanItBulkATMLineItem }) => {
        return (
            <PlanItBulkReviewOrderSectionRow
                key={row.productSku}
                item={row}
                hasMultiplePersonas={hasMultiplePersonas}
            />
        );
    };

    const getAlternativeTableWrapper = () => {
        if (!isEdit && hasMultiplePersonas) {
            return classes.editAtmTableWrapper;
        } else if (!hasMultiplePersonas) {
            return classes.planItOnlyTableWrapper;
        } else {
            return classes.makeItGuidedTableWrapper;
        }
    };

    return (
        <Grid
            container
            className={clsx(classes.tableWrapper, getAlternativeTableWrapper())}
            key={generateKey('planit-bulk-review')}
            data-testid="plan-it-bulk-review-product-grid"
        >
            <DataGrid
                columns={columns}
                dataSource={activeLines}
                gridName="Plan It Bulk Review Grid"
                rowComponent={ProductRow}
                toolbarOptions={CustomToolbarOptions}
            />
        </Grid>
    );
};

export default PlanItBulkReviewOrderSectionProducts;
