import { makeStyles, Paper } from '@material-ui/core';
import React, { createRef, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import HoverNavMenu from '../../../reusable/molecules/HoverNavMenu';
import clsx from 'clsx';
import { ltBlueGrey_8, successGreen } from '../../../../themes/globalConstants';

const useStyles = makeStyles((theme) => ({
    paper: {
        boxShadow: `0 0 6px ${ltBlueGrey_8}`,
        borderRadius: 4,
        padding: '2em'
    },
    formNavContainer: {
        position: 'sticky',
        top: '5em',
        marginRight: '2em'
    },
    completedIcon: {
        fill: successGreen,
        width: 23,
        height: 23
    }
}));

const ShipToAddSectionsNav = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    const sections = useMemo(
        () => [
            {
                header: t('customerInformation', 'Customer Information')
            },
            {
                header: t('newShipToInformation', 'New Ship To Information')
            },
            {
                header: t('productInformation', 'Product Information')
            },
            {
                header: t('freightInformation', 'Freight Information')
            }
        ],
        [t]
    );
    const sectionRefs = sections.map(() => createRef<HTMLElement>());

    const header = <Trans i18nKey="formOverview">Form Overview</Trans>;

    const icon = (
        <img
            className={classes.completedIcon}
            src={process.env.PUBLIC_URL + '/assets/Confirmation_icon.svg'}
            alt="Confirmation Icon"
        />
    );

    return (
        <Paper className={clsx(classes.formNavContainer, classes.paper)}>
            <HoverNavMenu
                translatedHeader={header}
                readOnly={true}
                sections={sections
                    .map((section, index) => ({
                        ref: sectionRefs[index],
                        header: section.header,
                        icon: true,
                        iconSrc: icon
                    }))
                    .filter((section) => section.header)}
            />
        </Paper>
    );
};

export default ShipToAddSectionsNav;
