import React, { ReactNode } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Typography
} from '@material-ui/core/';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, Grid } from '@material-ui/core';
import { boldWeight, xl } from '../../../themes/globalConstants';

interface Props {
    title?: ReactNode;
    titleStyles?: any;
    titleIcon?: ReactNode;
    secondaryTitle?: ReactNode;
    subheader?: ReactNode;
    subheaderStyles?: string;
    children?: ReactNode;
    open: boolean;
    close: (boolean: any | null) => void;
    closeIcon?: boolean;
    iconPath?: string;
    fullScreen?: boolean;
    fullWidth?: boolean;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    className?: string;
    disableClosing?: boolean;
}

const useStyles = makeStyles((theme) => ({
    defaultMaxWidth: {
        maxWidth: '40.5em'
    },
    title: {
        fontSize: 24
    },
    btn: {
        margin: 0,
        paddingBottom: '0.5em',
        color: theme.palette.info.dark
    },
    dialogTitleWrapper: {
        backgroundColor: theme.palette.info.light,
        alignItems: 'center'
    },
    dialogTitle: {
        display: 'flex',
        padding: '1.188em 1.875em',
        '& h2': {
            fontSize: '1.5rem',
            fontWeight: boldWeight,
            color: theme.palette.info.dark
        }
    },
    dialogSecondaryTitle: {
        display: 'flex',
        padding: '1.188em 1.875em',
        '& h2': {
            fontSize: xl,
            fontWeight: boldWeight,
            color: theme.palette.info.dark
        }
    },
    dialogSubheader: {},
    dialogContent: {
        padding: 0
    }
}));

const Modal = (props: Props) => {
    const classes = useStyles(props);
    const includeCloseIcon = props.closeIcon;

    return (
        <Dialog
            open={props.open}
            onClose={props.close}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            fullScreen={props.fullScreen}
            fullWidth={props.fullWidth}
            maxWidth={props.maxWidth}
            className={props.className}
            disableBackdropClick={props.disableClosing ? props.disableClosing : false}
        >
            <Grid
                container
                justify="flex-end"
                alignItems="center"
                className={classes.dialogTitleWrapper}
            >
                <Grid item xs={props.secondaryTitle ? 6 : 11}>
                    <DialogTitle
                        className={props.titleStyles ? props.titleStyles : classes.dialogTitle}
                    >
                        {props.titleIcon}
                        {props.title}
                    </DialogTitle>
                    {props.subheader && (
                        <DialogTitle
                            className={
                                props.subheaderStyles
                                    ? props.subheaderStyles
                                    : classes.dialogSubheader
                            }
                        >
                            {props.subheader}
                        </DialogTitle>
                    )}
                </Grid>
                {props.secondaryTitle && (
                    <Grid item xs={5}>
                        <DialogTitle className={classes.dialogSecondaryTitle}>
                            {props.secondaryTitle}
                        </DialogTitle>
                    </Grid>
                )}
                <Grid item xs={1}>
                    <DialogActions>
                        {includeCloseIcon && (
                            <IconButton
                                className={classes.btn}
                                onClick={props.close}
                                data-testid="modal-close-btn"
                            >
                                <CloseIcon />
                            </IconButton>
                        )}
                        {props.iconPath && (
                            <Typography>
                                <img
                                    className={classes.btn}
                                    src={process.env.PUBLIC_URL + '/assets/' + props.iconPath}
                                    alt="Icon"
                                />
                            </Typography>
                        )}
                    </DialogActions>
                </Grid>
            </Grid>
            <DialogContent className={classes.dialogContent}>{props.children}</DialogContent>
        </Dialog>
    );
};

export default Modal;
