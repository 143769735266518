import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import {
    containerMaxWidth,
    blackWeight,
    medium,
    white,
    ballGray,
    ballBlue,
    boldWeight,
    vanilla,
    small
} from '../../../../themes/globalConstants';
import DeliveryBulkUploadGrid from './DeliveryBulkUploadGrid';
import { BulkUploadOrder } from '../../../../store/reducers/bulk-upload';
import Button from '../../../reusable/atoms/Button';
import Link from '../../../reusable/atoms/Link';
import { Link as RouterLink } from 'react-router-dom';
import {
    CustomerContextState,
    Account,
    ModeOfTransport
} from '../../../../store/reducers/customer-context';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { getAccountAddress } from '../../../../utility/helpers/address-helpers';
import AccountService from '../../../../utility/services/account-service';
import { AuthState } from '../../../../store/reducers/auth';
import { useDispatch } from 'react-redux';
import { undoDeleteShipment } from '../../../../store/actions/bulk-upload';

interface Props {
    orderInformation: BulkUploadOrder;
    deliveryOrderNumber: number;
    orderIndex: number;
    setShipmentIndex: Dispatch<SetStateAction<number>>;
    setOrderIndex: Dispatch<SetStateAction<number>>;
    setOpenDelete: Dispatch<SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: '3em 5em 2em 5em',
        padding: '2em',
        borderRadius: 4,
        maxWidth: containerMaxWidth
    },
    tableSection: {
        marginBottom: '3em',
        paddingBottom: '1em',
        width: '65em',
        margin: 'auto'
    },
    header: {
        marginTop: '1em',
        marginBottom: '3em'
    },
    actionBtn: {
        borderRadius: 'unset'
    },
    title: {
        fontWeight: blackWeight,
        color: ballGray,
        marginBottom: '0.5em'
    },
    warning: {
        padding: '.125em 1.5em .125em 2.125em',
        backgroundColor: vanilla,
        '& .MuiAlert-icon': {
            color: ballBlue
        }
    },
    alertText: {
        textAlign: 'center',
        fontSize: medium,
        fontWeight: boldWeight
    },
    subHeader: {
        marginBottom: '2em'
    },
    main: {
        paddingTop: '4.875em',
        width: '100%'
    },
    deliveryTitle: {
        fontWeight: blackWeight,
        fontSize: medium
    },
    actionsBar: {
        marginTop: '1.25em',
        padding: '0 1em'
    },
    deleteBtn: {
        marginRight: '2em',
        color: theme.palette.error.main,
        border: `1px solid ${theme.palette.error.main}`,
        '&:hover': {
            color: white,
            backgroundColor: theme.palette.error.main
        }
    },
    editBtn: {
        width: '11.5em'
    },
    subtitle: {
        color: ballGray
    },
    shipToText: {
        fontSize: small,
        letterSpacing: '0.2px'
    },
    cardDetailsWrapper: {
        '& .MuiGrid-item': {
            paddingLeft: '2em'
        }
    },
    cardSubheader: {
        color: ballGray
    }
}));

export default function DeliveryBulkUploadOrderSection({
    orderInformation,
    deliveryOrderNumber,
    orderIndex,
    setShipmentIndex,
    setOrderIndex,
    setOpenDelete
}: Props) {
    const classes = useStyles();
    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { accessToken } = useTypedSelector<AuthState>((state) => state.auth);
    const [shipToAddress, setShipToAddress] = useState<string>('');
    const [shipToName, setShipToName] = useState<string>('');
    const [shipToId, setShipToId] = useState<string>('');
    const [shipToAccount, setShipToAccount] = useState<Account>();

    const dispatch = useDispatch();

    const handleDelete = (shipmentIndex: number) => {
        setShipmentIndex(shipmentIndex);
        setOrderIndex(orderIndex);
        setOpenDelete(true);
    };

    const handleUndo = (shipmentIndex: number) => {
        dispatch(undoDeleteShipment(orderIndex, shipmentIndex));
    };

    useEffect(() => {
        if (orderInformation.shipToId && shipToAccounts && shipToAccounts.length > 0) {
            const shipToIdString = orderInformation.shipToId.toString();
            const shipToAccount = shipToAccounts.find(
                (account) => shipToIdString === account.accountId
            );
            if (shipToAccount) {
                setShipToName(shipToAccount.name);
                setShipToId(shipToAccount.accountId);
                setShipToAddress(getAccountAddress(shipToAccount));
                setShipToAccount(shipToAccount);
                if (shipToAccount.modeOfTransport === ModeOfTransport.CustomerPickUp) {
                    orderInformation.customerPickup = true;
                }
            } else {
                AccountService.getAccount(shipToIdString, false, accessToken)
                    .then((response) => {
                        const account = response.data.account;
                        setShipToName(account.name);
                        setShipToId(account.accountId);
                        setShipToAddress(getAccountAddress(account));
                        setShipToAccount(account);
                        if (account.modeOfTransport === ModeOfTransport.CustomerPickUp) {
                            orderInformation.customerPickup = true;
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }
    }, [accessToken, orderInformation, shipToAccounts]);

    return (
        <Paper elevation={2} className={classes.paper}>
            <Grid container spacing={2} alignItems="flex-start">
                <Grid container alignItems="baseline" className={classes.header}>
                    <Grid container item xs={12} justify="flex-start">
                        <Typography variant="h3" className={classes.title}>
                            <Trans i18nKey="deliveryOrderHeader">
                                Delivery Order {{ deliveryOrderNumber }} -{' '}
                                <i>Order Number Pending</i>
                            </Trans>
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={6}
                        justify="flex-start"
                        data-testid="shipto-information"
                    >
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" className={classes.subtitle}>
                                    <Trans i18nKey="shipTo">Ship To</Trans>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.shipToText}>{shipToName}</Typography>
                            <Typography className={classes.shipToText}>{shipToAddress}</Typography>
                            <Typography className={classes.shipToText}>#{shipToId}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {orderInformation.shipments &&
                orderInformation.shipments.map((shipment, index) => {
                    if (shipment.loads) {
                        return (
                            <Grid key={`shipment_table_${index}`}>
                                <Paper className={classes.tableSection} elevation={3}>
                                    <Grid container>
                                        <Grid container item>
                                            {shipToAccount && (
                                                <DeliveryBulkUploadGrid
                                                    accountInformation={shipToAccount}
                                                    loadInformation={shipment.loads}
                                                    shipmentInformation={shipment}
                                                    index={index}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        justify="flex-end"
                                        className={classes.actionsBar}
                                    >
                                        <Grid container item xs={6} justify="flex-end">
                                            {!shipment.isDeleted && (
                                                <>
                                                    <Button
                                                        type="button"
                                                        variant="outlined"
                                                        data-testid="delete-bulk-shipment-btn"
                                                        className={classes.deleteBtn}
                                                        onClick={() => handleDelete(index)}
                                                    >
                                                        <Trans i18nKey="deleteShipment">
                                                            Delete Shipment
                                                        </Trans>
                                                    </Button>
                                                    <Link
                                                        to={`/ship-it-bulk-upload-edit-shipment/${shipment.bulkShipmentId}`}
                                                        underline="none"
                                                        component={RouterLink}
                                                        data-testid="edit-bulk-shipment-link"
                                                    >
                                                        <Button
                                                            type="button"
                                                            variant="contained"
                                                            color="primary"
                                                            data-testid="edit-bulk-shipment-btn"
                                                            className={classes.editBtn}
                                                        >
                                                            <Trans i18nKey="editShipment">
                                                                Edit Shipment
                                                            </Trans>
                                                        </Button>
                                                    </Link>
                                                </>
                                            )}
                                            {shipment.isDeleted && (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    data-testid="undo-delete-bulk-shipment-btn"
                                                    className={classes.editBtn}
                                                    onClick={() => handleUndo(index)}
                                                >
                                                    <Trans i18nKey="undo">Undo</Trans>
                                                </Button>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        );
                    } else {
                        return null;
                    }
                })}
        </Paper>
    );
}
