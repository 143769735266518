import Axios from 'axios';
import { ShipToLocation } from '../../store/reducers/ship-to-locations';
import { createAPIQueryParam } from '../helpers/query-helpers';

export interface AccountsRequestForm {
    accountIds: string[];
    includeHierarchy?: boolean;
}

class AccountService {
    //Gets all accounts associated to the included Account IDs
    static getAccounts(state: any, includeHierarchy: boolean = false, accounts?: string[]) {
        const url = `${process.env.REACT_APP_ACCOUNT_API}/accounts`;
        const accessToken = state.auth.accessToken;
        const accountIds = state.auth.permissions.map((x) => x.accountIds.flat()).flat();
        const accountSet = new Set<string>(accounts ?? accountIds);

        const form: AccountsRequestForm = {
            accountIds: Array.from(accountSet),
            includeHierarchy: includeHierarchy
        };

        return Axios.post(url, form, AccountService.createConfig(accessToken));
    }

    static getUserAccounts(state: any) {
        const url = `${process.env.REACT_APP_ACCOUNT_API}/accounts`;
        const accessToken = state.auth.accessToken;
        return Axios.get(url, AccountService.createConfig(accessToken));
    }

    //Gets a specific account with its shipTo hierarchy
    static getAccount(accountId: any, includeHierarchy: boolean = false, accessToken: string) {
        const urlContent = createAPIQueryParam({ includeHierarchy });
        const baseUrl = `${process.env.REACT_APP_ACCOUNT_API}/accounts/${accountId}`;
        const url = baseUrl + urlContent;
        return Axios.get(url, AccountService.createConfig(accessToken));
    }

    static submitNewShipToLocation = (state: any, newShipToLocation: ShipToLocation) => {
        const url = `${process.env.REACT_APP_ACCOUNT_API}/accounts/shipTos`;
        const accessToken = state.auth.accessToken;

        return Axios.post(url, newShipToLocation, AccountService.createConfig(accessToken));
    };

    private static createConfig = (accessToken: string) => ({
        headers: { Authorization: `Bearer ${accessToken}` }
    });
}
export default AccountService;
