import React, { ReactNode } from 'react';

export interface DocumentState {
    activeTab: 'active' | 'archived';
}

export interface ProviderProps {
    children: ReactNode;
    value: DocumentState;
}

const ContractSummaryContext = React.createContext<DocumentState>({} as DocumentState);

function ContractSummaryProvider(props: ProviderProps) {
    const { children, value } = props;
    return (
        <ContractSummaryContext.Provider value={value}>
            {' '}
            {children}{' '}
        </ContractSummaryContext.Provider>
    );
}

const useContractSummaryContext = () => React.useContext(ContractSummaryContext);

export { ContractSummaryProvider, useContractSummaryContext };
