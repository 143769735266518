import { ColumnDataType, createColumn } from 'tubular-common';
import { FlagTypes, useFeatureFlag } from '../../../../utility/helpers/feature-flag';
import TranslationService from '../../../../utility/services/translation-service';

export default function ShipItConfigColumns(culture: string, optionalFields: boolean) {
    const cokeFeatureFlag = useFeatureFlag().find((flag) => flag.name === FlagTypes.Coke)?.isActive;

    return [
        createColumn('shipmentIndex', {
            dataType: ColumnDataType.Numeric,
            sortable: false,
            isKey: true,
            visible: false
        }),
        createColumn('sequence', {
            dataType: ColumnDataType.String,
            label: TranslationService.getTranslatedText(culture, 'loadSeq')
        }),
        createColumn('displayId', {
            dataType: ColumnDataType.String,
            label: TranslationService.getTranslatedText(culture, 'customerProductGraphicId')
        }),
        createColumn('displayName', {
            dataType: ColumnDataType.String,
            label: TranslationService.getTranslatedText(culture, 'descriptionCharacteristics')
        }),
        createColumn('palletQuantity', {
            dataType: ColumnDataType.Numeric,
            label: TranslationService.getTranslatedText(culture, 'quantity')
        }),
        createColumn('releaseNumber', {
            dataType: ColumnDataType.String,
            label: TranslationService.getTranslatedText(culture, 'releaseNumberPDF'),
            visible: optionalFields
        }),
        createColumn('fillerLine', {
            dataType: ColumnDataType.String,
            label: TranslationService.getTranslatedText(culture, 'fillerLineNumber'),
            visible: optionalFields
        }),
        createColumn('reference', {
            dataType: ColumnDataType.String,
            label: TranslationService.getTranslatedText(culture, 'referenceNumberPDF'),
            visible: optionalFields
        }),
        createColumn('purchaseOrderNumber', {
            dataType: ColumnDataType.String,
            label: TranslationService.getTranslatedText(culture, 'customerPoNumber'),
            visible: !!cokeFeatureFlag
        }),
        createColumn('deliveryInstructions', {
            dataType: ColumnDataType.String,
            label: TranslationService.getTranslatedText(culture, 'deliveryInstructions'),
            visible: optionalFields
        }),
        createColumn('productSku', {
            dataType: ColumnDataType.String,
            visible: false
        }),
        createColumn('name', {
            dataType: ColumnDataType.String,
            visible: false
        }),
        createColumn('graphicIdAndVersion', {
            dataType: ColumnDataType.String,
            visible: false
        }),
        createColumn('graphicId', {
            dataType: ColumnDataType.String,
            visible: false
        }),
        createColumn('size', {
            dataType: ColumnDataType.String,
            visible: false
        }),
        createColumn('unit', {
            dataType: ColumnDataType.String,
            visible: false
        }),
        createColumn('type', {
            dataType: ColumnDataType.String,
            visible: false
        }),
        createColumn('quantityPerPallet', {
            dataType: ColumnDataType.String,
            visible: false
        }),
        createColumn('leadTimeWeeks', {
            dataType: ColumnDataType.Numeric,
            visible: false
        }),
        createColumn('orderedPallets', {
            dataType: ColumnDataType.Numeric,
            visible: false
        }),
        createColumn('shape', {
            dataType: ColumnDataType.String,
            visible: false
        }),
        createColumn('neckDiameter', {
            dataType: ColumnDataType.String,
            visible: false
        }),
        createColumn('endProfile', {
            dataType: ColumnDataType.String,
            visible: false
        }),
        createColumn('tabColor', {
            dataType: ColumnDataType.String,
            visible: false
        }),
        createColumn('previousPalletTotals', {
            dataType: ColumnDataType.Numeric,
            visible: false
        }),
        createColumn('calculatedTotalsForPrevNDays', {
            dataType: ColumnDataType.Numeric,
            visible: false
        }),
        createColumn('description', {
            dataType: ColumnDataType.String,
            visible: false
        }),
        createColumn('customerProductId', {
            dataType: ColumnDataType.String,
            visible: false
        }),
        createColumn('userSuppliedProductId', {
            dataType: ColumnDataType.String,
            visible: false
        }),
        createColumn('customerProductName', {
            dataType: ColumnDataType.String,
            visible: false
        }),
        createColumn('quantityPerPallet', {
            dataType: ColumnDataType.Numeric,
            visible: false
        }),
        // temporary fix for the reference vs. referenceNumber issue
        createColumn('referenceNumber', {
            dataType: ColumnDataType.String,
            visible: false
        })
    ];
}
