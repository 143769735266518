import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import {
    blackWeight,
    ballGray,
    small
} from '../../../../themes/globalConstants';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import CancelProductionOrderConfirmationRow from './CancelProductionOrderConfirmationRow';
import CancelProductionOrderConfirmationColumns from './CancelProductionOrderConfirmationColumns';
import { ProductionOrderLine, ProductionOrderLineItem } from '../../../../store/reducers/makeit-dashboard';
import { generateKey } from '../../../../utility/helpers/order-helpers';

interface Props {
    items?: ProductionOrderLine[];
}

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        width: '100%',
        '& .MuiPaper-root': {
            boxShadow: 'unset'
        },
        '& .MuiToolbar-root': {
            minHeight: 25,
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                }
            }
        },
        '& .MuiTableSortLabel-root': {
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em'
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableCell-head': {
            color: ballGray,
            fontSize: small,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            '&:not(:first-of-type)': {
                borderLeft: 'unset'
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word',
            padding: '3px 10px'
        },
        '& .MuiTableHead-root': {
            '& th': {
                '&:nth-child(1)': {
                    width: '20%'
                },
                '&:nth-child(2)': {
                    width: '30%'
                },
                '&:nth-child(3)': {
                    width: '15%'
                },
                '&:nth-child(4)': {
                    width: '15%'
                },
                '&:nth-child(5)': {
                    width: '15%'
                }
            }
        }
    }
}));

export default function CancelProductionOrderConfirmationConfigGrid({
    items
}: Props) {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const CustomToolbarOptions = new ToolbarOptions({
        exportButton: false,
        printButton: false,
        advancePagination: false,
        enablePagination: false,
        searchText: false
    });

    const CancelProductionOrderRow = ({ row }: { row: Partial<ProductionOrderLineItem> }) => {
        if (row) {
            return (
                <CancelProductionOrderConfirmationRow
                    key={row.productionOrderLineId}
                    line={row as ProductionOrderLineItem}
                />
            );

        } else {
            return null;
        }

    };

    return (
        <Grid container className={classes.tableWrapper} key={generateKey('po-cancel-confirmation')} >
            {items && (<DataGrid
                columns={CancelProductionOrderConfirmationColumns.getCancelProductionOrderConfirmationColumns(cultureCode)}
                dataSource={items}
                gridName="Cancel Production Order Grid"
                rowComponent={CancelProductionOrderRow}
                toolbarOptions={CustomToolbarOptions}
            />
            )}
        </Grid>
    );
}
