import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import ManageCommunicationsColumns from './ManageCommunicationsTableColumns';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { StandardTableStyles } from '../../../reusable/molecules/StandardTable';
import ManageCommunicationsRow from './ManageCommunicationsTableRow';
import { CommunicationManagementState } from '../../../../store/reducers/communication-management';
import { Trans } from 'react-i18next';

export const communicationColumnsWidthPercentage = [15, 15, 20, 15, 15, 10, 10];

const useStyles = makeStyles((theme) => {
    return {
        ...StandardTableStyles(theme, communicationColumnsWidthPercentage),
        timeMessage: {
            color: 'rgba(0, 0, 0, 0.54) !important',
            position: 'absolute',
            paddingTop: '1rem',
            paddingLeft: '1rem'
        }
    };
});

export default function ManageCommunicationsTable() {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { loaded, communications } = useTypedSelector<CommunicationManagementState>(
        (state) => state.communicationManagement
    );
    const [tableData, setTableData] = useState<any[]>([]);
    const toolbarOptions = new ToolbarOptions({
        advancePagination: false,
        exportButton: false,
        printButton: false,
        enablePagination: true,
        searchText: true,
        itemsPerPage: 20
    });

    useEffect(() => {
        if (loaded && communications) {
            const commData = communications
                .map((communication) => {
                    const messageType = communication.communicationMessageTypes.reduce(
                        (acc, curr, index) => {
                            return index === 0 ? curr.messageType : `${acc}, ${curr.messageType}`;
                        },
                        ''
                    );
                    return {
                        ...communication,
                        createdBy: communication.submitterName,
                        messageType,
                        status: communication.status ? 'Active' : 'Inactive'
                    };
                })
                .sort((a, b) => {
                    return a.startDateTime < b.startDateTime ? -1 : 1;
                });
            setTableData(commData);
        }
    }, [loaded, communications]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Paper>
                    <Grid container>
                        <Typography className={classes.timeMessage}>
                            <Trans i18nKey="timeZoneHelper2">
                                All times displayed in local time
                            </Trans>
                        </Typography>
                        <Grid
                            container
                            item
                            xs={12}
                            direction="row-reverse"
                            className={classes.tableWrapper}
                        >
                            <DataGrid
                                columns={ManageCommunicationsColumns.getManageCommunicationsColumns(
                                    cultureCode
                                )}
                                rowComponent={ManageCommunicationsRow}
                                dataSource={tableData}
                                gridName={'manage-communications'}
                                toolbarOptions={toolbarOptions}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}
