import React from 'react';
import { Typography, Grid, makeStyles, useTheme, useMediaQuery } from '@material-ui/core';
import { ContentSection, ContentSubSection } from '../../../../store/reducers/content-page';
import HorizontalTemplateBlock from './HorizontalTemplateBlock';
import HorizontalTemplateVideoBlock from './HorizontalTemplateVideoBlock';
//This section creates columns of content and align the columns in a row (horizontally)

const useStyles = makeStyles((theme) => ({
    titleContainer: {
        textAlign: 'center',
        marginBottom: '0.875em'
    },
    subsectionContainer: {
        margin: 'auto',
        paddingTop: '0 !important'
    }
}));

const MultiItemHorizontalSection = (contentSection: ContentSection) => {
    const classes = useStyles();
    const theme = useTheme();
    const smScreens = useMediaQuery(theme.breakpoints.down('sm'));
    const subSections = contentSection.subsections as ContentSubSection[];

    const renderSubSections = subSections?.map(
        (contentSubSection: ContentSubSection, i: number) => {
            if(contentSubSection?.videoUrl) {
                return <HorizontalTemplateVideoBlock section={contentSubSection} key={i} />
            }
            return <HorizontalTemplateBlock section={contentSubSection} key={i} />;
        }
    );

    return (
        <Grid container item spacing={smScreens ? 0 : 8}>
            <Grid container item xs={12} spacing={3} className={classes.titleContainer}>
                {contentSection.header && (
                    <Grid item xs={12}>
                        <Typography variant="h3">{contentSection.header}</Typography>
                    </Grid>
                )}
                {contentSection.description && (
                    <Grid item xs={12}>
                        <Typography variant="body1" component="p">
                            {contentSection.description}
                        </Typography>
                    </Grid>
                )}
            </Grid>
            <Grid
                container
                item
                spacing={smScreens ? 0 : 6}
                className={classes.subsectionContainer}
            >
                {renderSubSections}
            </Grid>
        </Grid>
    );
};

export default MultiItemHorizontalSection;
