import { Grid, makeStyles, FormHelperText } from '@material-ui/core';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { red } from '../../../../themes/globalConstants';
import { ErrorMessage, useFormContext } from 'react-hook-form';
import TextInput from '../../../reusable/molecules/TextInput';
import CountrySelect from '../../../reusable/molecules/CountrySelect';
import FormSection from '../../../reusable/molecules/FormSection';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { Region } from '../../../../store/reducers/graphic-intake';
import { KeyValuePair } from '../../../../store/types';
import StateProvinceSelect from '../../../reusable/molecules/StateProvinceSelect';
import {
    getNACountrySelection,
    NCACountries,
    postalCodePattern
} from '../../../../utility/helpers/address-helpers';
import clsx from 'clsx';
import { OnboardingDashboardState } from '../../../../store/reducers/onboarding-dashboard';

interface Props {
    handleUpdateBillingCountry: (billingCounty: string) => void;
}

const useStyles = makeStyles((theme) => ({
    helperText: {
        '& .MuiFormHelperText-root': {
            marginLeft: 0
        }
    },
    errorText: {
        color: red
    }
}));

const OnboardBillingAddressSection = ({ handleUpdateBillingCountry }: Props) => {
    const classes = useStyles();
    const { control, errors, setValue } = useFormContext();
    useTranslation();
    const { billingAndShipping } = useTypedSelector<OnboardingDashboardState>(
        (state) => state.onboardingDashboardState
    );

    const [selectedCountry, setSelectedCountry] = useState<string>(
        billingAndShipping.billingAndShippingForm?.billingAddress?.country ?? NCACountries.USA
    );
    const [initialLoad, setInitialLoad] = useState<boolean>(true);

    const isRequired = <Trans i18nKey="required">Required</Trans>;

    const handleLoadBillCountry = (name: string, selectedCountry: KeyValuePair) => {
        if (!initialLoad) {
            setValue('billingAddress.state', '');
            updateBillingCountry(name, selectedCountry);
        } else {
            const billingCountry = getNACountrySelection(
                billingAndShipping.billingAndShippingForm?.billingAddress?.country ??
                    NCACountries.USA
            );
            updateBillingCountry(name, billingCountry);
        }
        setInitialLoad(false);
    };

    const updateBillingCountry = (name: string, country: KeyValuePair) => {
        setValue(name, country);
        setSelectedCountry(country.value);
        handleUpdateBillingCountry(country.value);
    };

    const handleLoadBillingState = (name: string, selectedState: KeyValuePair) => {
        setValue(name, selectedState);
    };

    return (
        <FormSection
            testId={'billing-address-section'}
            sectionHeader={<Trans i18nKey="billingAddress">Billing Address</Trans>}
        >
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                    <TextInput
                        type="text"
                        label={<Trans i18nKey="billMailingName">Bill Mailing Name</Trans>}
                        name="billingAddress.name"
                        testId="billingAddress.name"
                        control={control}
                        rules={{ required: isRequired }}
                        error={errors.billingAddress?.name}
                        errors={errors}
                        styles={classes.helperText}
                        inputProps={{
                            'data-testid': 'bill-address-name',
                            maxLength: 50
                        }}
                    />
                    <FormHelperText
                        className={clsx({ [classes.errorText]: !!errors.billingAddress?.name })}
                    >
                        {errors.billingAddress?.name?.type === 'required' && (
                            <ErrorMessage
                                errors={errors}
                                name={'billingAddress.name'}
                                message={isRequired}
                            />
                        )}
                        {!errors.billingAddress?.name && (
                            <Trans i18nKey="billMailingNameHelper">
                                Name of person who will be receiving communications
                            </Trans>
                        )}
                    </FormHelperText>
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6} data-testid="billingAddress.country">
                    <CountrySelect
                        defaultValue={NCACountries.USA}
                        name={'billingAddress.country'}
                        regionCode={Region.NA}
                        control={control}
                        rules={{ required: isRequired }}
                        error={errors.billingAddress?.country}
                        errors={errors}
                        handleLoadDraftApproverCountry={handleLoadBillCountry}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                    <TextInput
                        type="text"
                        label={<Trans i18nKey="billingAddressLine1">Billing Address Line 1</Trans>}
                        name="billingAddress.addressLine1"
                        testId="billingAddress.addressLine1"
                        control={control}
                        rules={{ required: isRequired }}
                        error={errors.billingAddress?.addressLine1}
                        errors={errors}
                        styles={classes.helperText}
                        helperText={
                            <Trans i18nKey="billingAddressLine1Helper">
                                Street Type, Street Name, House Number
                            </Trans>
                        }
                        inputProps={{
                            'data-testid': 'bill-address-line1',
                            maxLength: 40
                        }}
                    />
                    <FormHelperText className={classes.errorText}>
                        {errors.billingAddress?.addressLine1 &&
                            errors.billingAddress?.addressLine1.type === 'required' && (
                                <ErrorMessage
                                    errors={errors}
                                    name={'billingAddress.addressLine1'}
                                    message={isRequired}
                                />
                            )}
                    </FormHelperText>
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        type="text"
                        label={<Trans i18nKey="billingAddressLine2">Billing Address Line 2</Trans>}
                        name="billingAddress.addressLine2"
                        testId="billingAddress.addressLine2"
                        control={control}
                        rules={''}
                        error={errors.billingAddress?.addressLine2}
                        errors={errors}
                        styles={classes.helperText}
                        helperText={
                            <Trans i18nKey="billingAddressLine2Helper">
                                Neighborhood, Municipality
                            </Trans>
                        }
                        inputProps={{
                            'data-testid': 'bill-address-line2',
                            maxLength: 40
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        type="text"
                        label={<Trans i18nKey="billingAddressLine3">Billing Address Line 3</Trans>}
                        name="billingAddress.addressLine3"
                        testId="billingAddress.addressLine3"
                        control={control}
                        rules={''}
                        error={errors.billingAddress?.addressLine3}
                        errors={errors}
                        styles={classes.helperText}
                        inputProps={{
                            'data-testid': 'bill-address-line3',
                            maxLength: 40
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                    <TextInput
                        type="text"
                        label={<Trans i18nKey="city">City</Trans>}
                        name="billingAddress.city"
                        testId="billingAddress.city"
                        control={control}
                        rules={{ required: isRequired }}
                        error={errors.billingAddress?.city}
                        errors={errors}
                        styles={classes.helperText}
                        inputProps={{
                            'data-testid': 'bill-city',
                            maxLength: 25
                        }}
                    />
                    <FormHelperText className={classes.errorText}>
                        {errors.billingAddress?.city &&
                            errors.billingAddress?.city.type === 'required' && (
                                <ErrorMessage
                                    errors={errors}
                                    name={'billingAddress.city'}
                                    message={isRequired}
                                />
                            )}
                    </FormHelperText>
                </Grid>
                <Grid item xs={6}>
                    <StateProvinceSelect
                        defaultValue={NCACountries.USA}
                        name={'billingAddress.state'}
                        countryCode={selectedCountry}
                        control={control}
                        rules={{ required: isRequired }}
                        error={errors.billingAddress?.state}
                        errors={errors}
                        handleLoadStateProvince={handleLoadBillingState}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        type="text"
                        label={<Trans i18nKey="postalCode">Postal Code</Trans>}
                        name="billingAddress.zipCode"
                        testId="billingAddress.zipCode"
                        control={control}
                        rules={{
                            required: isRequired,
                            pattern: postalCodePattern
                        }}
                        error={errors.billingAddress?.zipCode}
                        errors={errors}
                        styles={classes.helperText}
                        inputProps={{
                            'data-testid': 'bill-zip-code',
                            maxLength: 11
                        }}
                    />
                    <FormHelperText className={classes.errorText}>
                        {errors.billingAddress?.zipCode &&
                            errors.billingAddress?.zipCode.type === 'required' && (
                                <ErrorMessage
                                    errors={errors}
                                    name={'billingAddress.zipCode'}
                                    message={isRequired}
                                />
                            )}
                    </FormHelperText>
                </Grid>
            </Grid>
        </FormSection>
    );
};

export default OnboardBillingAddressSection;
