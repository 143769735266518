import React, { useState } from 'react';
import { Grid, makeStyles, TableCell, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { regularWeight } from '../../../../themes/globalConstants';
import { StandardTableStyles } from '../../../reusable/molecules/StandardTable';
import { getAccountAddress } from '../../../../utility/helpers/address-helpers';
import StatusLabel from '../../ManageProducts/components/StatusLabel';
import Button from '../../../reusable/atoms/Button';
import clsx from 'clsx';
import ManageBillToLocationsContextMenu from './ManageBillToLocationsContextMenu';
import { useHistory } from 'react-router';

interface Props {
    row: any;
}

const useStyles = makeStyles((theme) => {
    return {
        ...StandardTableStyles(theme, [4, 5, 2, 1]),
        expandButton: {
            padding: '1em',
            minWidth: 0
        },
        regularWeight: {
            fontWeight: regularWeight,
            '& *': {
                fontWeight: regularWeight
            }
        },
        // This is button is hidden for now but will be used when features are added in the future
        shipToActionButton: {
            visibility: 'hidden'
        }
    };
});

export default function ManageLocationsTableRow({ row }: Props) {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState<boolean>(false);
    const [billToActionMenuAnchor, setBillToActionMenuAnchor] = useState<null | HTMLElement>(null);
    // const [shipToActionMenuAnchor, setShipToActionMenuAnchor] = useState<null | HTMLElement>(null);
    // const [actionMenuShipToId, setActionMenuShipToId] = useState<null | string>(null);

    const handleBillToActionMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setBillToActionMenuAnchor(event.currentTarget);
    };
    // const handleShipToActionMenuClick = (
    //     event: React.MouseEvent<HTMLElement>,
    //     shipToId: string
    // ) => {
    //     setShipToActionMenuAnchor(event.currentTarget);
    //     setActionMenuShipToId(shipToId);
    // };

    const handleAddShipTo = () => {
        setBillToActionMenuAnchor(null);
        history.push(`/add-ship-to/${row.accountId}`);
    };

    return (
        <>
            <TableRow>
                <TableCell data-testid="bill-to-location">
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                            {row.name} ({row.accountId})
                        </Grid>
                        <Grid item onClick={() => setExpanded(!expanded)}>
                            <Button
                                className={classes.expandButton}
                                data-testid="expand-collapse-button"
                            >
                                {expanded ? (
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/Chevron_Down.svg`}
                                        alt={`Collapse row`}
                                    />
                                ) : (
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/Chevron_Right.svg`}
                                        alt={`Expand row`}
                                    />
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell data-testid="address" className={classes.regularWeight}>
                    {getAccountAddress(row)}
                </TableCell>
                <TableCell data-testid="status">
                    <StatusLabel line={row} />
                </TableCell>
                <TableCell data-testid="actions">
                    <Grid container justify="center" alignItems="center">
                        <Button
                            onClick={handleBillToActionMenuClick}
                            data-testid="action-menu-button"
                        >
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/ellipsis.svg`}
                                alt={`Action menu for ${row.name}`}
                            />
                        </Button>
                    </Grid>
                </TableCell>
            </TableRow>
            {expanded && (
                <>
                    <TableRow className="detailRow" data-testid="detail-row-header">
                        <TableCell colSpan={4}>{t('shipTos', 'Ship Tos')}</TableCell>
                    </TableRow>
                    {row.shipToAccounts &&
                        row.shipToAccounts.map((shipTo) => (
                            <TableRow
                                className={clsx(classes.regularWeight) + ' detailRow'}
                                key={shipTo.accountId}
                            >
                                <TableCell data-testid="shipto-location">{shipTo.name}</TableCell>
                                <TableCell data-testid="shipto-address">
                                    {getAccountAddress(shipTo)}
                                </TableCell>
                                <TableCell data-testid="shipto-status">
                                    <StatusLabel line={shipTo} />
                                </TableCell>
                                <TableCell data-testid="shipto-actions">
                                    <Grid container justify="center" alignItems="center">
                                        <Button
                                            data-testid="shipto-action-menu-button"
                                            className={classes.shipToActionButton}
                                        >
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL + '/assets/ellipsis.svg'
                                                }
                                                alt={`Action menu for ${shipTo.name}`}
                                            />
                                        </Button>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        ))}
                </>
            )}
            <ManageBillToLocationsContextMenu
                onAddShipTo={handleAddShipTo}
                onMenuBlur={() => setBillToActionMenuAnchor(null)}
                anchorEl={billToActionMenuAnchor}
                row={row}
            ></ManageBillToLocationsContextMenu>
        </>
    );
}
