import React, { ReactNode, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Activity } from '../../../utility/auth/useSecurity';
import { Link as RouterLink } from 'react-router-dom';
import Link from '../../reusable/atoms/Link';
import DataValidationGrid, { DataValidation } from './components/DataValidationGrid';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { FlagTypes, useFeatureFlag } from '../../../utility/helpers/feature-flag';
import { DataValidationDisplayTemplate } from '../../templates/DataValidationDisplayTemplate';

const DeliveryBulkUploadValidationRules = () => {
    const { t } = useTranslation();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [culture, setCulture] = useState<string>();
    const [rules, setRules] = useState<DataValidation[]>();

    const cokeFeatureFlag = useFeatureFlag().find((flag) => flag.name === FlagTypes.Coke)?.isActive;

    const createData = (
        validationField?: ReactNode,
        validationFormat?: ReactNode,
        validationRule?: ReactNode,
        validationExample?: ReactNode,
        hide: boolean = false
    ) => {
        return { validationField, validationFormat, validationRule, validationExample, hide };
    };

    const shipToIdHeader = (
        <>
            <Trans i18nKey="shipToId">Ship To ID</Trans>
            {' ('}
            <Link
                to="/assigned-ship-tos/ship-it"
                target="_blank"
                component={RouterLink}
                underline="none"
            >
                <Trans i18nKey="viewAssignedShipTos">View Assigned Ship Tos</Trans>
            </Link>
            {')'}
        </>
    );

    useEffect(() => {
        if (!rules || cultureCode !== culture) {
            setCulture(cultureCode);
            setRules([
                createData(
                    shipToIdHeader,
                    t('integer', 'Integer'),
                    t('upTo8Digits', 'Up to 8 digits'),
                    '12345678'
                ),
                createData(
                    t('productId', 'Product ID'),
                    t('text', 'Text'),
                    t('upTo25Characters', 'Up to 25 characters'),
                    '12345678ab'
                ),
                createData(
                    t('quantityInPallets', 'Quantity (in Pallets)'),
                    t('integer', 'Integer'),
                    t('upTo6Digits', 'Up to 6 digits'),
                    '25'
                ),
                createData(
                    t('deliveryDate', 'Delivery Date'),
                    t('dateInputFormat', 'MM/DD/YYYY'),
                    '--',
                    '01/01/2021'
                ),
                createData(
                    t('deliveryTime', 'Delivery Time'),
                    '01:00 (' + t('twentyFourHourFormat', '24 hour format') + ')',
                    '--',
                    '01:00 (' + t('twentyFourHourFormat', '24 hour format') + ')'
                ),
                createData(
                    t('customerPoNumber', 'Customer PO #'),
                    t('text', 'Text'),
                    t('upTo25Characters', 'Up to 25 characters'),
                    'NZB06001',
                    !cokeFeatureFlag
                ),
                createData(
                    t('deliveryInstructions', 'Delivery Instructions'),
                    t('text', 'Text'),
                    t('upTo30Characters', 'Up to 30 characters'),
                    t('deliverToBackDoor', 'Deliver to back door')
                ),
                createData(
                    t('releaseNumber', 'Release Number'),
                    t('text', 'Text'),
                    t('upTo10Characters', 'Up to 10 characters'),
                    '1234567890'
                ),
                createData(
                    t('fillerLine', 'Filler Line Number'),
                    t('text', 'Text'),
                    t('upTo3Characters', 'Up to 3 characters'),
                    '3'
                ),
                createData(
                    t('referenceNumber', 'Reference Number'),
                    t('text', 'Text'),
                    t('upTo30Characters', 'Up to 30 characters'),
                    '12345'
                )
            ]);
        } else {
            rules.map((rule, index) => {
                return (rule.index = index);
            });
        }
    }, [rules, cultureCode, cokeFeatureFlag]);

    return (
        <DataValidationDisplayTemplate
            banner={{
                header: t('shipItBulkDataValidation', 'Ship It Bulk Upload Data Validation'),
                description: '',
                thinBanner: true,
                displayDropdown: false
            }}
            activity={Activity.ShipItBulkUpload}
            shipTos={false}
        >
            <DataValidationGrid title="dataValidationRules" rows={rules} />
        </DataValidationDisplayTemplate>
    );
};

export default DeliveryBulkUploadValidationRules;
