import {
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    Typography
} from '@material-ui/core';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import React from 'react';
import { Trans } from 'react-i18next';
import { Culture } from '../../store/reducers/region-culture';
import {
    DeliveryOrder,
    DeliveryShipment,
    DeliveryTime,
    ProductToShip,
    ProductWithPallets,
    QuantityUnit
} from '../../store/reducers/shipping-dashboard';
import {
    ballGray,
    ballDrkBlue,
    xxl,
    blackWeight,
    small,
    ballBlue,
    large,
    medium,
    boldWeight,
    inactiveTabBlue,
    dateOptionInvalidGray,
    white,
    shipRed,
    black,
    errorBackground,
    fullTruckPallets,
    fullATMTruckPallets
} from '../../themes/globalConstants';
import { formatNumberWithLocale } from './formatters';
import ReactPDF, { Text } from '@react-pdf/renderer';
import pdfStyles from '../../themes/pdfStyles';
import { formattedMTDate } from './date-helpers';
import { DeliveryOrderRow } from '../../store/reducers/shipping-summary';

export const getLoadIndexByLoadId = (product: ProductToShip, loads: ProductToShip[]) => {
    return loads.findIndex((load) => load.editLoadId === product.editLoadId);
};

export const getLoadIndexBySKU = (product: ProductToShip, loads: ProductToShip[]) => {
    return loads.findIndex((load) => load.productSku === product.productSku);
};

export const getLoadIndexBySequence = (product: ProductToShip, loads: ProductToShip[]) => {
    return loads.findIndex((load) => load.sequence === product.sequence);
};

export const getShipmentIndex = (currentShipmentId: string, tempShipments: DeliveryShipment[]) => {
    return tempShipments.findIndex(
        (shipment) => shipment.shipmentId?.toString() === currentShipmentId
    );
};

export const getCurrentLoad = (
    loadIndex: number,
    tempShipments: DeliveryShipment[],
    currentShipmentId: string
) => {
    const shipmentIndex = getShipmentIndex(currentShipmentId, tempShipments);
    const currentShipment = tempShipments[shipmentIndex];

    return currentShipment!.loads![loadIndex];
};

export const getReferenceLoad = (product: ProductToShip, productsToEdit: ProductToShip[]) => {
    return productsToEdit.find((productToEdit) => productToEdit.productSku === product.productSku);
};

export const getEaches = (product: ProductWithPallets) => {
    return product.availablePallets! * product.availableItemsPerPallet!;
};

export const getFormattedEaches = (product: ProductWithPallets, cultureCode: Culture): string => {
    return formatNumberWithLocale(cultureCode, getEaches(product));
};

export const getFormattedEachesFromNumber = (eaches: number, cultureCode: Culture): string => {
    return formatNumberWithLocale(cultureCode, eaches);
};

export const canUpdateDesiredPallets = (product: ProductToShip, palletDifference: number) => {
    let isAddingPallets = palletDifference < 0;
    let isRemovingPallets = palletDifference > 0;
    let hasEnoughRemainingPallets = product.availablePallets! >= Math.abs(palletDifference);

    return isAddingPallets ? hasEnoughRemainingPallets : isRemovingPallets;
};

export const sortLoadsBySequence = (products: ProductToShip[]) => {
    return products.sort((a, b) => b.sequence! - a.sequence!);
};

export const renderTimeSelect = (
    classes: any,
    shipmentDeliveryDateAndTime: string | undefined,
    selectedTime: string | undefined,
    ExpandMoreIcon: any,
    handleChangeTime: (event: React.ChangeEvent<{ value: unknown }>) => void,
    deliveryTimeList: DeliveryTime[]
) => {
    // Select closest matching shipment time from list of time selections
    let selectedValue = shipmentDeliveryDateAndTime;
    let timeSelectionMatchingShipmentTime = deliveryTimeList.find(
        (time) =>
            moment(time.time).format('hh:mm A') ===
            moment(shipmentDeliveryDateAndTime).format('hh:mm A')
    );
    if (timeSelectionMatchingShipmentTime?.time)
        selectedValue = moment(timeSelectionMatchingShipmentTime.time).format('MM/DD/YY hh:mm A');
    else if (deliveryTimeList[0]?.time)
        selectedValue = moment(deliveryTimeList[0].time).format('MM/DD/YY hh:mm A');

    selectedTime = moment(selectedValue).format('HH:mm:ss');

    return (
        <Grid container item md={5} className={classes.selectWrapper}>
            <Typography className={classes.dateDescriptionSub}>
                <Trans i18nKey="at">AT</Trans>
            </Typography>
            <FormControl>
                <InputLabel>
                    {shipmentDeliveryDateAndTime === undefined ? (
                        <Trans i18nKey="time">Time</Trans>
                    ) : (
                        selectedTime
                    )}
                </InputLabel>
                {deliveryTimeList.length && (
                    <Select
                        label="Time"
                        labelId="time-select"
                        id="time-select"
                        data-testid="time-select"
                        aria-label="Time"
                        IconComponent={ExpandMoreIcon}
                        className={classes.select}
                        onChange={handleChangeTime}
                        value={selectedValue}
                        MenuProps={{ classes: { paper: classes.timeSelect } }}
                    >
                        {deliveryTimeList.length &&
                            deliveryTimeList.map((time) => (
                                <MenuItem
                                    key={time.label}
                                    value={moment(time.time).format('MM/DD/YY hh:mm A')}
                                >
                                    {time.label}
                                </MenuItem>
                            ))}
                    </Select>
                )}
            </FormControl>
        </Grid>
    );
};

export const renderTimeSelectDisclaimer = (classes: any) => {
    return (
        <Grid container direction="row" justify="flex-end">
            <Grid item md={4} className={classes.selectWrapper}>
                <FormHelperText>
                    <Trans i18nKey="deliveryTimeZone">Select desired delivery time</Trans>
                </FormHelperText>
            </Grid>
        </Grid>
    );
};

export const checkPONumbers = (isLargeCustomer: boolean, currentShipment: DeliveryShipment) => {
    if (!isLargeCustomer) {
        return true;
    } else {
        let linesHavePO = true;
        if (currentShipment.loads) {
            currentShipment.loads.forEach((load) => {
                if (!load.purchaseOrderNumber) {
                    linesHavePO = false;
                }
            });
        }
        return linesHavePO;
    }
};

export const getNewDateTime = (selectedTime: string, selectedDate: string) => {
    return selectedDate + selectedTime;
};

export function getLastShipmentDate(deliveryOrder: DeliveryOrder) {
    const shipments = deliveryOrder.shipments;
    if (shipments && shipments.length) {
        let latestShipmentDate = moment();
        shipments.forEach((s) => {
            const deliveryDate = moment(s.updatedDeliveryDate || s.deliveryDateTime);
            if (deliveryDate.isAfter(latestShipmentDate)) {
                latestShipmentDate = deliveryDate;
            }
        });
        return latestShipmentDate;
    }
}

export const editShipmentUseStyles = makeStyles((theme) => ({
    main: {
        padding: '1.875em',
        width: '65em',
        marginBottom: '1.5em'
    },
    dateDescription: {
        color: ballGray,
        display: 'flex',
        alignItems: 'center'
    },
    altDescriptionColor: {
        color: ballDrkBlue
    },
    dateDescriptionMain: {
        fontSize: xxl,
        fontWeight: blackWeight
    },
    dateDescriptionSub: {
        margin: 'auto',
        fontSize: small,
        fontWeight: blackWeight,
        textTransform: 'uppercase',
        color: ballGray
    },
    dateOptionsWrapper: {
        marginLeft: '1.25em'
    },
    dateOption: {
        padding: '0.5em 1em',
        color: ballBlue,
        border: `1px solid ${ballBlue}`,
        flexGrow: 1,
        textAlign: 'center',
        '&:not(:last-child)': {
            marginRight: '0.125em'
        }
    },
    dateOptionDayName: {
        minWidth: '2em',
        fontSize: large,
        fontWeight: blackWeight
    },
    dateOptionDayNum: {
        fontSize: medium,
        fontWeight: boldWeight
    },
    firstDateOption: {
        borderTopLeftRadius: '0.250em',
        borderBottomLeftRadius: '0.250em'
    },
    lastDateOption: {
        borderTopRightRadius: '0.250em',
        borderBottomRightRadius: '0.250em'
    },
    invalidDate: {
        backgroundColor: inactiveTabBlue,
        border: 'unset',
        '& p': {
            color: dateOptionInvalidGray
        },
        '&:hover': {
            cursor: 'not-allowed'
        }
    },
    validDate: {
        height: 'inherit',
        transition: 'transform .2s',
        '&:hover': {
            overflow: 'visible',
            transform: 'scale(1.05)',
            boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.08)',
            cursor: 'pointer'
        }
    },
    selectedDate: {
        background: ballBlue,
        '& p': {
            color: white
        }
    },
    preview: {
        border: `1px solid ${theme.palette.primary.main}`,
        borderStyle: 'dashed',
        padding: '3em 0',
        marginTop: '3.750em',
        marginBottom: '2.250em',
        borderRadius: '0.5em'
    },
    previewText: {
        fontWeight: boldWeight,
        fontSize: medium,
        maxWidth: '31.250em',
        margin: 'auto',
        color: ballGray
    },
    selectWrapper: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiInputBase-root': {
            backgroundColor: theme.palette.common.white,
            border: '1px solid',
            borderColor: theme.palette.secondary.light,
            '&:before': {
                marginBottom: '-0.250em',
                border: 0
            },
            '&.Mui-focused': {
                '& .MuiSvgIcon-root': {
                    transform: 'rotate(-180deg)'
                }
            },
            '& .MuiSvgIcon-root': {
                fill: theme.palette.primary.main,
                transition: 'transform 200ms'
            }
        },
        '& .MuiSelect-root': {
            textAlign: 'left',
            backgroundColor: theme.palette.common.white,
            minWidth: '6.250em',
            display: 'flex',
            alignItems: 'center',
            fontSize: medium,
            fontWeight: blackWeight
        },
        '& .MuiFormHelperText-root': {
            marginLeft: '0.875em'
        },
        '& .MuiFormLabel-filled': {
            fontWeight: blackWeight
        },
        '& .MuiInputLabel-root': {
            fontSize: medium,
            fontWeight: blackWeight
        }
    },
    select: {
        '& ul': {
            maxHeight: '18.750em'
        }
    },
    actionsBar: {
        marginTop: '1.250em'
    },
    deleteBtn: {
        marginRight: '1.125em'
    },
    deliveryTitle: {
        fontWeight: blackWeight,
        fontSize: medium
    },
    editBtn: {
        float: 'right'
    },
    cardSubheader: {
        color: ballGray
    },
    cardDetailsWrapper: {
        '& .MuiGrid-item': {
            paddingLeft: '2.000em'
        }
    },
    shipmentBtnContainer: {
        '& button': {
            '&:not(:last-child)': {
                marginRight: '1.125em'
            }
        }
    },
    formula: {
        fontSize: small,
        marginTop: '0.750em'
    },
    removeMargin: {
        margin: 0
    },
    timeSelect: {
        maxHeight: '15em',
        top: '23.125em !important',
        marginRight: '2em'
    },
    addProductLink: {
        fontSize: small,
        '&.enabled': {
            color: ballDrkBlue,
            cursor: 'pointer'
        }
    },
    changeDayLink: {
        marginLeft: '1em'
    },
    originallyScheduled: {
        marginTop: '-0.75em'
    },
    dataTimeError: {
        marginTop: '1em',
        width: '100%',
        border: `1px solid ${shipRed}`,
        borderRadius: '.125em',
        '& .MuiAlert-icon': {
            color: shipRed,
            display: 'flex',
            alignItems: 'center'
        },
        '& .MuiAlert-message': {
            '& .MuiTypography-root': {
                color: black,
                fontWeight: boldWeight
            }
        },
        backgroundColor: errorBackground
    }
}));

export const wrapReferenceNumber = (refNum: string) => {
    let refNumChunks = refNum.match(/.{1,10}/g);
    return (
        <>
            {refNumChunks?.map((chunk: string, i: number) => {
                return (
                    <Text style={[pdfStyles.tableCell2]} key={i}>
                        {chunk}
                    </Text>
                );
            })}
        </>
    );
};

export const wrapReleaseNumber = (relNum: string) => {
    let relNumChunks = relNum.match(/.{1,6}/g);
    return (
        <>
            {relNumChunks?.map((chunk: string, i: number) => {
                return (
                    <Text style={[pdfStyles.tableCell2]} key={i}>
                        {chunk}
                    </Text>
                );
            })}
        </>
    );
};

export const wrapEaches = (textToWrap: string, styleOverride?: ReactPDF.Style) => {
    // if more than 4 characters will be on line before 'ea.', want 'ea.' on its own line to prevent breaks between 'e' and 'a' or 'a' and '.'
    let eaOnOwnLine = textToWrap.length % 8 > 4;
    // if 'ea.' will not be on its own line, we want to append 'ea.' to the string
    textToWrap = eaOnOwnLine ? textToWrap : textToWrap + ' ea.';

    // split into chunks of 8 characters
    let textChunks = textToWrap.match(/.{1,8}/g);
    const styleClass = styleOverride ? styleOverride : pdfStyles.tableCell2;
    return (
        <>
            {textChunks?.map((chunk: string, i: number) => {
                return (
                    <Text style={[styleClass]} key={i}>
                        {chunk}
                    </Text>
                );
            })}
            {eaOnOwnLine && <Text style={[styleClass]}>{'ea.'}</Text>}
        </>
    );
};

export const wrapEachesForTableCell = (textToWrap: string) => {
    // if more than 4 characters will be on line before 'ea.', want 'ea.' on its own line to prevent breaks between 'e' and 'a' or 'a' and '.'
    let eaOnOwnLine = textToWrap.length % 8 > 4;
    // if 'ea.' will not be on its own line, we want to append 'ea.' to the string
    textToWrap = eaOnOwnLine ? textToWrap : textToWrap + ' ea.';

    // split into chunks of 8 characters
    let textChunks = textToWrap.match(/.{1,8}/g);
    return (
        <>
            {textChunks?.map((chunk: string, i: number) => {
                return <div>{chunk}</div>;
            })}
            {eaOnOwnLine && <div>{'ea.'}</div>}
        </>
    );
};

export const resolvePalletCount = (
    quantity: number,
    quantityPerPallet: number,
    quantityUnit: QuantityUnit
) => {
    switch (quantityUnit) {
        case 'pallets':
            return quantity;
        case 'eaches':
            return getPalletsFromEaches(quantity, quantityPerPallet);
    }
};

/**
 * Gets display quantities for cell, given a quantity and selected quantity unit.
 * @param quantity
 * @param quantityPerPallet
 * @param quantityUnit
 * @returns [pallets, eaches]
 */
export const getProductCellQuantities = (
    quantity: number,
    quantityPerPallet: number,
    quantityUnit: QuantityUnit
): [number, number] => {
    switch (quantityUnit) {
        case 'pallets': {
            return [
                quantity,
                getConvertedQuantityUnitPallets(quantityUnit, quantity, quantityPerPallet) as number
            ];
        }
        case 'eaches': {
            return [
                getConvertedQuantityUnitPallets(
                    quantityUnit,
                    quantity,
                    quantityPerPallet
                ) as number,
                quantity
            ];
        }
    }
};

/**
 * Gets the rounded up pallet value, given an eaches value and quantity per pallet.
 * @param quantity
 * @param quantityPerPallet
 * @returns
 */
export const getPalletsFromEaches = (quantity: number, quantityPerPallet: number): number => {
    return Math.ceil(quantity / quantityPerPallet!);
};

/**
 * Formats the desired unit.
 *
 * @param quantity
 * @param quantityUnitLabel
 * @returns
 */
export const formatEachesOrPallets = (quantity: number, quantityUnitLabel: QuantityUnit) => {
    return `${Number(quantity).toLocaleString()} ${getFormattedSelectedQuantityUnit(
        quantityUnitLabel
    )}`;
};

/**
 * Gets the converted quantity unit value.
 * For example, a quantity unit of 'pallets' will return the equivalent eaches value, and vice versa.
 *
 * If converting to pallets, rounds quantity to nearest integer.
 * @param quantityUnit
 * @param quantity
 * @param quantityPerPallet
 * @param label Optional. Default=false.  If true, will include a unit label ('ea.' or 'PL') at the end of the returned value.
 * @returns
 */
export const getConvertedQuantityUnitPallets = (
    quantityUnit: QuantityUnit,
    quantity: number,
    quantityPerPallet: number,
    label?: boolean
) => {
    let newQuantity = 0;
    let quantityUnitLabel: QuantityUnit = 'eaches';
    switch (quantityUnit) {
        case 'pallets': {
            newQuantity = quantity * quantityPerPallet;
            break;
        }
        case 'eaches': {
            quantityUnitLabel = 'pallets';
            newQuantity = getPalletsFromEaches(quantity, quantityPerPallet!);
        }
    }
    if (label) {
        return formatEachesOrPallets(newQuantity, quantityUnitLabel);
    } else {
        return newQuantity;
    }
};

/**
 * Get formatted unit name, given the quantity unit.
 * @param quantityUnit
 * @returns
 */
export const getFormattedSelectedQuantityUnit = (quantityUnit: QuantityUnit): string => {
    switch (quantityUnit) {
        case 'pallets':
            return 'PL';
        case 'eaches':
            return 'ea.';
    }
};

/**
 * Returns total eaches given number of pallets and quantity per pallet.
 * @param palletQuantity
 * @param quantityPerPallet The number of eaches per pallet.
 * @param label Optional. Default=true.  If true, will include a unit label ('ea.') at the end of the returned value.
 * @returns
 */
export const getEachesConversion = (
    palletQuantity: number,
    quantityPerPallet: number,
    label: boolean = true
): string => {
    return `${getConvertedQuantityUnitPallets(
        'pallets',
        palletQuantity,
        quantityPerPallet,
        label
    )}`;
};

export const getFirstShipmentDeliveryDate = (shipments?: DeliveryShipment[]) => {
    if (shipments && shipments[0]) {
        return shipments[0].deliveryDateTime;
    } else {
        return '';
    }
};

export const getEarliestDeliveryDate = (shipments?: DeliveryShipment[]) => {
    if (shipments && shipments[0] && shipments[0].deliveryDateTime) {
        let earliestDeliveryDate = moment(shipments[0].deliveryDateTime);

        shipments.forEach((shipment) => {
            if (shipment.deliveryDateTime) {
                let currentDeliveryDate = moment(shipment.deliveryDateTime);
                if (earliestDeliveryDate.isAfter(currentDeliveryDate)) {
                    earliestDeliveryDate = currentDeliveryDate;
                }
            }
        });

        return earliestDeliveryDate;
    }
};

export const getConvertedShipmentDate = (
    order: DeliveryOrder,
    shipmentDate?: string | moment.Moment
) => {
    if (shipmentDate && shipmentDate !== '') {
        let convertedShipmentDate = moment(shipmentDate);
        return convertedShipmentDate;
    } else {
        // if shipment date/time has not been set by JDE yet, use 7 calendar days from
        // the earliest delivery date of the shipment on the delivery order
        let convertedShipmentDate = order.deliveryWeekDate;
        return moment(convertedShipmentDate).startOf('day').subtract(7, 'days');
    }
};

export const getFilteredDeliveryOrdersByDateRange = (
    deliveryOrders: DeliveryOrder[] | DeliveryOrderRow[],
    start: moment.Moment,
    end: moment.Moment | undefined
): DeliveryOrder[] => {
    let filteredOrders = deliveryOrders;

    if (end) {
        filteredOrders = filteredOrders.filter(
            (ord) =>
                ord.deliveryWeekDate.isSameOrAfter(start) &&
                ord.deliveryWeekDate.isSameOrBefore(end)
        );
    } else {
        filteredOrders = filteredOrders.filter((ord) => ord.deliveryWeekDate.isSameOrAfter(start));
    }

    return filteredOrders;
};

export const formatDeliveryWeek = (shipmentDeliveryDateTime?: any) => {
    // ship-it flow doesn't allow for delivery order to have shipments in multiple weeks
    // so, grab first shipment and use that week

    if (shipmentDeliveryDateTime) {
        return formattedMTDate(
            moment(shipmentDeliveryDateTime).startOf('isoWeek').add(1, 'days'),
            'MM/DD/YYYY'
        );
    } else {
        return '';
    }
};

export const getPalletsPerTruck = (
    isLargeCustomer: boolean,
    isCopacker: boolean,
    isLargeCustomerAccount: boolean
) => {
    let pallets = fullTruckPallets;
    if (!isCopacker && isLargeCustomer) {
        pallets = fullATMTruckPallets;
    } else if (isCopacker && isLargeCustomerAccount) {
        pallets = fullATMTruckPallets;
    }
    return pallets;
};

export const isCustomerProductIdDuplicate = (item: ProductToShip) => {
    return item.isCustomerProductIdDistinct === false;
};
