import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class PlanItColumns {
    static getColumns = (culture: string, canCopyToMakeIt: boolean, isPrevious?: boolean, isSpotCustomer?: boolean) => {
        return [
            createColumn('sortIndex', {
                searchable: false,
                sortable: false,
                filterable: false,
                visible: false,
                dataType: ColumnDataType.Numeric
            }),
            createColumn('atmWeekStartDate', {
                dataType: ColumnDataType.Date,
                label: TranslationService.getTranslatedText(culture, 'forecastWeek'),
                visible: true,
                sortable: true
            }),
            createColumn('customerProductionOrderId', {
                dataType: ColumnDataType.Date,
                label: TranslationService.getTranslatedText(culture, 'customerPoNumLong'),
                visible: isSpotCustomer,
                sortable: true
            }),
            createColumn('productionOrderId', {
                isKey: true,
                searchable: false,
                sortable: false,
                filterable: false,
                visible: false
            }),
            createColumn('searchablePONumber', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'forecastOrderNumber'),
                visible: true,
                sortable: true,
                filterable: true,
                searchable: true
            }),
            createColumn('atmWeekStart', {
                dataType: ColumnDataType.Date,
                visible: false
            }),
            createColumn('atmWeekStartString', {
                dataType: ColumnDataType.String,
                visible: false,
                filterable: true,
                searchable: true
            }),
            createColumn('totalOrderQuantity', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'totalQuantity'),
                filterable: true,
                sortable: true
            }),
            createColumn('totalOrderQuantityString', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            }),
            createColumn('totalOrderQuantityEaches', {
                dataType: ColumnDataType.Numeric,
                visible: false,
                filterable: false,
                sortable: false
            }),
            createColumn('totalOrderQuantityEachesString', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            }),
            createColumn('createDate', {
                dataType: ColumnDataType.Date,
                visible: false
            }),
            createColumn('submittedDateString', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'submitted'),
                filterable: true,
                sortable: true,
                searchable: true
            }),
            createColumn('forecastTableStatus', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'status'),
                filterable: true,
                sortable: true,
                searchable: true,
                visible: isPrevious
            }),
            createColumn('actions', {
                label: TranslationService.getTranslatedText(culture, 'actions'),
                visible: true
            }),
            createColumn('copyToMakeIt', {
                label: TranslationService.getTranslatedText(culture, 'copyToMakeIt'),
                visible: canCopyToMakeIt
            })
        ];
    };
}
