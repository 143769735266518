import { ReactElement, useEffect, useState } from 'react';
import { ContentSection } from '../../../../store/reducers/content-page';
import React from 'react';
import stemmer from 'stemmer';

interface Props {
    filterText: string;
    section: ContentSection;
    render: (section: ContentSection, searchWords: string[]) => ReactElement;
}

export function matches(value: string, filterValues: string[]): boolean {
    // create a string of a bunch of tokenized lookahead matches (?=.*steve)
    const match = filterValues.map((filterValue) => `(?=.*${filterValue})`).join('');
    const regex = new RegExp(`^${match}`, 'i');

    return !!value.match(regex);
}

export function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        // Set debouncedValue to value (passed in) after the specified delay
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}

export default function SectionFilter({ filterText, section, render }: Props) {
    const [hasMatch, setHasMatch] = useState(true);
    const [filteredSection, setFilteredSection] = useState(section);
    const [searchWords, setSearchWords] = useState<string[]>([]);

    // do not filter every time this is updated
    const debouncedFilterText = useDebounce(filterText, 250);

    useEffect(() => {
        var sectionMatches = false;

        // make the assumption with no filter text, we auto match
        if (!debouncedFilterText?.length) {
            sectionMatches = true;
            setFilteredSection(section);
            setSearchWords([]);
        }

        const precompiledRegex = /[.*+?^${}()|[\]\\]/g;
        let filterValues = debouncedFilterText.replace(precompiledRegex, '\\$&').split(' ');
        filterValues = filterValues.map(value => stemmer(value));
        setSearchWords(filterValues);

        // check if section header matches the input
        if (matches(section.header, filterValues)) {
            sectionMatches = true;
            setFilteredSection(section);
        }

        // check subsections
        if (!sectionMatches && section.subsections) {
            // filter subsections by description and header
            const matchingSubsections = section.subsections.filter(
                (subsection) =>
                    matches(subsection.header, filterValues) ||
                    matches(subsection.description, filterValues)
            );

            if (matchingSubsections.length) {
                const newFilteredSection: ContentSection = {
                    ...section,
                    subsections: matchingSubsections
                };
                sectionMatches = true;
                setFilteredSection(newFilteredSection);
            }
        }

        setHasMatch(sectionMatches);
    }, [debouncedFilterText, section]);

    return <>{hasMatch && render(filteredSection, searchWords)}</>;
}
