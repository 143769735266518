import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Trans } from 'react-i18next';
import ConfirmationModal from '../../../reusable/molecules/ConfirmationModal';
import { enUS } from '../../../../utility/translations/locales/en-US/en-US';

interface Props {
    openAdminUsersStatusModal: boolean;
    onClose: () => void;
    handleAdminUsersStatus: () => void;
    inActive: boolean;
}

const useStyles = makeStyles((theme) => ({
    modalDescription: {
        display: 'inline-block',
        marginTop: '1em'
    }
}));

export default function UserAdminUsersStatusModal({
    openAdminUsersStatusModal,
    onClose,
    handleAdminUsersStatus,
    inActive = false,
}: Props) {
    const classes = useStyles();

    return (
        <ConfirmationModal
            logo={false}
            title={
                inActive ?
                <Trans i18nKey="makeUserActive">{enUS.makeUserActive}</Trans>
                : <Trans i18nKey="makeUserInactive">{enUS.makeUserInactive}</Trans>
            }
            subheader={
                inActive ?
                    <Trans i18nKey="activeWarning">{enUS.activeWarning}</Trans>
                    : <Trans i18nKey="inactiveWarning">{enUS.inActiveWarning}</Trans>
            }
            description={
                <span className={classes.modalDescription}>
                    <Trans i18nKey="disableUserContinue">{enUS.disableUserContinue}</Trans>
                </span>
            }
            open={openAdminUsersStatusModal}
            continueText={<Trans i18nKey="yes">{enUS.yes}</Trans>}
            cancelText={<Trans i18nKey="cancel">{enUS.cancel}</Trans>}
            saveProgress={handleAdminUsersStatus}
            onClose={onClose}
            onCancel={onClose}
        />
    );
}
