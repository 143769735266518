import moment from 'moment';
import { UserPermission } from '../../store/reducers/auth';
import { Invoice } from '../../store/reducers/make-it-summary';
import { Persona } from '../auth/useSecurity';
import { formattedMTDate } from './date-helpers';

// determine the status of an overdue payment depending on the timeframe.
export const getPaymentStatus = (date: Date) => {
    if (moment().diff(date, 'days') > 0) {
        //payment is overdue.
        return 'overdue';
    } else if (moment().diff(date, 'days') <= 0 && moment().diff(date, 'days') > -7) {
        //payment is due this week.
        return 'dueThisWeek';
    } else if (moment().diff(date, 'days') > -moment().daysInMonth()) {
        //payment is due within a month.
        return 'dueThisMonth';
    } else {
        //payment is due past a month
        return 'duePastThisMonth';
    }
};

export const getRevisedAvailableDate = (invoice: any) => {
    // if the payment is late, display:
    // the earliest requested date on production order + (todays date - due date on invoice)
    if (invoice?.paymentDueDate) {
        let today = moment().startOf('day');
        let dueDate = moment(invoice?.paymentDueDate).startOf('day');
        if (dueDate.isBefore(today, 'day')) {
            let dayDifference = today.diff(dueDate, 'days');
            let earliestRequestedDate = moment(invoice?.paymentDueDate).add(4, 'weeks');
            let revisedAvailableDate = earliestRequestedDate.add(dayDifference, 'days');
            return formattedMTDate(revisedAvailableDate, 'MM/DD/YYYY');
        }
    } else {
        return '';
    }
};

export const getOrderFirstRequestedDate = (invoice: any) => {
    // payment due date is calculated as 4 weeks before the order first requested date
    // so, can calculate the other way around since we already have the paymentDueDate
    if (invoice.paymentDueDate) {
        let orderFirstRequestedDate = moment(invoice.paymentDueDate).add(4, 'weeks');
        return formattedMTDate(orderFirstRequestedDate, 'MM/DD/YYYY');
    } else {
        return '';
    }
};

export const getOrderRequestedDate = (order: any) => {
    // Get the first requested date from an order
    if (!!order.lines?.length) {
        let orderRequestedDate = moment(order.lines[0].requestedDate);
        return formattedMTDate(orderRequestedDate, 'MM/DD/YYYY');
    } else {
        return '';
    }
};

export const isInvoiceImpersonation = (invoice: Invoice, permissions: UserPermission[]) => {
    if (invoice.shipToId && permissions) {
        const shipToId = invoice.shipToId.toString();
        const impPersona = permissions.find(
            (permission) => permission.personaId === Persona.Impersonation
        );

        return !!impPersona && impPersona.accountIds.some((accountId) => accountId === shipToId);
    }
    return false;
};