import {
    TableRow,
    TableCell,
    Grid,
    makeStyles,
    Checkbox,
    Typography,
    Tooltip
} from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { TbRowProps } from 'tubular-react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setSelectedDraft } from '../../../../store/actions/make-it-drafts';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import {
    MakeItDraftsState,
    ProductionOrderDraftLine
} from '../../../../store/reducers/make-it-drafts';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import {
    ballLtGray,
    boldWeight,
    regularWeight,
    small,
    vanilla,
    warningBorder,
    yellow
} from '../../../../themes/globalConstants';
import { getLockedPeriodDays } from '../../../../utility/helpers/user-helpers';
import { getMondayOfWeek } from '../../MakeIt/utilities/make-it-utils';
import clsx from 'clsx';
import { stringFormatterReplace } from '../../../../utility/helpers/formatters';
import { formattedDateWithTimezone } from '../../../../utility/helpers/date-helpers';
import { useMakeItDraftsContext } from '../context/MakeItDraftsSummaryContext';

type Props = {
    line: ProductionOrderDraftLine;
    draftId: string;
    canEdit: boolean;
    showCustomerPO: boolean;
} & TbRowProps;

const useStyles = makeStyles(() => ({
    uneditableRow: {
        '& .MuiTableCell-body': {
            color: ballLtGray
        }
    },
    name: {
        fontWeight: regularWeight,
        letterSpacing: 0.17
    },
    checkboxCell: {
        textAlign: 'center',
        width: 'auto',
        position: 'relative'
    },
    tooltip: {
        maxWidth: 200,
        fontSize: '0.75em'
    },
    warningIcon: {
        fill: yellow,
        right: '1em',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)'
    },
    lockWindowWarningIcon: {
        marginRight: '0.5em'
    },
    warningText: {
        fontWeight: boldWeight,
        fontSize: small,
        marginTop: '0.5em'
    },
    lockWindowWarning: {
        border: `1px solid ${warningBorder}`,
        borderRight: 'none',
        backgroundColor: vanilla
    }
}));

const MakeItDraftsSummaryRow = ({
    line,
    columns,
    draftId,
    selection,
    rowIndex,
    canEdit,
    showCustomerPO
}: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { activeTab } = useMakeItDraftsContext();
    const { selectedDraft } = useTypedSelector<MakeItDraftsState>((state) => state.makeItDrafts);
    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );

    const [daysUntilLock, setDaysUntilLock] = useState<number>();
    const [showLockWarning, setShowLockWarning] = useState<boolean>(false);

    const handleCheckbox = () => {
        if (selection) {
            selection.toggleRowSelection(line[getColumnName()]);
        }
    };

    const getColumnName = (): string => {
        const toFind = columns.find((c) => c.isKey);
        return toFind ? toFind.name : '';
    };

    useEffect(() => {
        if (shipToAccounts?.length && line.shipToId) {
            const lockWindow: number = getLockedPeriodDays(
                shipToAccounts,
                line.shipToId.toString(),
                14
            );
            const lockDate = getMondayOfWeek(moment(line.requestedDate)).subtract(
                lockWindow,
                'days'
            );
            const daysUntilLock = lockDate.diff(moment().startOf('day'), 'days');
            setDaysUntilLock(daysUntilLock);
            if (daysUntilLock <= 7) {
                setShowLockWarning(true);
            }
        }
    }, [shipToAccounts, line]);

    useEffect(() => {
        // behavior only needs to update once per table
        if (rowIndex !== 0) {
            return;
        }

        if (selection) {
            if (selection.getSelectedCount() > 0 && selectedDraft !== draftId) {
                // Select draft
                dispatch(setSelectedDraft(draftId));

                // Add disabled from the "Select All" header checkbox of other tables
                document
                    .querySelectorAll<HTMLElement>(
                        `.MuiGrid-root:not([data-draftId="${draftId}"]) > div > table th .MuiCheckbox-root`
                    )
                    .forEach((a) => {
                        a.setAttribute('disabled', 'true');
                        a.classList.add('Mui-disabled', 'MuiIconButton-colorSecondary');
                    });
            } else if (selection.getSelectedCount() === 0 && selectedDraft === draftId) {
                // Draft is no longer selected
                dispatch(setSelectedDraft(''));

                // Remove disabled from the "Select All" header checkbox of other tables
                document
                    .querySelectorAll<HTMLElement>(
                        `.MuiGrid-root:not([data-draftId="${draftId}"]) > div > table th .MuiCheckbox-root`
                    )
                    .forEach((a) => {
                        a.removeAttribute('disabled');
                        a.classList.remove('Mui-disabled', 'MuiIconButton-colorSecondary');
                    });
            }
        }
    }, [selection, dispatch, draftId, rowIndex, selectedDraft, activeTab]);

    const isDisabled = useMemo(() => {
        if (activeTab === 'openDrafts') {
            return !canEdit || (selectedDraft !== '' && selectedDraft !== draftId);
        } else {
            return (
                !canEdit ||
                (selectedDraft !== '' &&
                    !(selection?.rowSelection[line[getColumnName()]] && canEdit))
            );
        }
    }, [activeTab, selection, canEdit, selectedDraft]);

    // Cells are in Right-To-Left order to get the checkbox column on the right side
    return (
        <TableRow
            hover={true}
            key={line.productionOrderId}
            className={!canEdit ? classes.uneditableRow : ''}
        >
            {selection && selection.rowSelection[line[getColumnName()]] !== undefined && (
                <TableCell className={classes.checkboxCell} data-testid={'draft-checkbox'}>
                    <Checkbox
                        checked={selection.rowSelection[line[getColumnName()]] && canEdit}
                        onChange={handleCheckbox}
                        value={selection.rowSelection[line[getColumnName()]]}
                        disabled={isDisabled}
                        data-draftId={draftId}
                        data-poId={line.productionOrderId}
                    />
                    {!canEdit && (
                        <Tooltip
                            title={
                                <>
                                    {t(
                                        'unauthorizedPoTooltip',
                                        'Not authorized to manage POs for this location'
                                    )}
                                </>
                            }
                            arrow
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                        >
                            <img
                                src={process.env.PUBLIC_URL + '/assets/Warning_Icon.svg'}
                                alt="Warning Icon"
                                className={classes.warningIcon}
                            />
                        </Tooltip>
                    )}
                </TableCell>
            )}
            <TableCell data-testid={'quantity-ends'}>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        {line.endQuantityPallets === 0 ? (
                            <Typography> - </Typography>
                        ) : (
                            <Typography>
                                {line.endQuantityPallets} PL ({line.endQuantitySKUs}{' '}
                                <Trans i18nKey="skus">SKUs</Trans>)
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell data-testid={'quantity-cans'}>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        {line.canQuantityPallets === 0 ? (
                            <Typography> - </Typography>
                        ) : (
                            <Typography>
                                {line.canQuantityPallets} PL ({line.canQuantitySKUs}{' '}
                                <Trans i18nKey="skus">SKUs</Trans>)
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell data-testid={'updated-date'}>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        {formattedDateWithTimezone(moment.utc(line.updateDate), 'M/D/YYYY h:mm A')}
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell data-testid={'updated-by'}>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        {line.updatedBy}
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell
                data-testid={'requested-week'}
                className={clsx({
                    [classes.lockWindowWarning]: showLockWarning && activeTab !== 'archivedDrafts'
                })}
            >
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        {showLockWarning &&
                            daysUntilLock &&
                            daysUntilLock <= 7 &&
                            activeTab !== 'archivedDrafts' && (
                                <img
                                    src={process.env.PUBLIC_URL + '/assets/Warning_Icon.svg'}
                                    alt="Warning Icon"
                                    className={classes.lockWindowWarningIcon}
                                />
                            )}
                        {getMondayOfWeek(moment.utc(line.requestedDate)).format('M/D/YYYY')}
                        {showLockWarning &&
                            daysUntilLock &&
                            daysUntilLock <= 7 &&
                            activeTab !== 'archivedDrafts' && (
                                <Typography
                                    className={classes.warningText}
                                    data-testid="lock-window-warning"
                                >
                                    {stringFormatterReplace(
                                        t(
                                            'lockWindowWarning',
                                            'This week will be locked for ordering in {0} days'
                                        ),
                                        daysUntilLock.toString()
                                    )}
                                </Typography>
                            )}
                    </Grid>
                </Grid>
            </TableCell>
            {showCustomerPO && (
                <TableCell data-testid={'customer-po-number'}>
                    <Grid container>
                        <Grid item xs={12} className={classes.name}>
                            {line.customerProductionOrderId ? (
                                <Typography> {line.customerProductionOrderId} </Typography>
                            ) : (
                                <Typography> - </Typography>
                            )}
                        </Grid>
                    </Grid>
                </TableCell>
            )}
            <TableCell data-testid={'ship-to-id'}>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        {line.shipToId}
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell data-testid={'ship-to-name'}>
                <Grid container spacing={1}>
                    <Grid item className={classes.name}>
                        {line.shipToName}
                    </Grid>
                </Grid>
            </TableCell>
        </TableRow>
    );
};

export default React.memo(MakeItDraftsSummaryRow, (prevProps, nextProps) => {
    return prevProps.selection?.getSelectedRows() === nextProps.selection?.getSelectedRows();
});
