import { ContractAndDocument as TermsAndConditionsDocument } from '../../store/reducers/contract-summary';
import moment from 'moment';
import { getCurrentDate } from './date-helpers';

export const documentIsCurrentlyActive = (document: TermsAndConditionsDocument): boolean => {
    const expirationDateHasNotPassed = moment(document.expirationDate).isAfter(moment());

    return expirationDateHasNotPassed;
};

export const documentIsPastExpiration = (document: TermsAndConditionsDocument): boolean => {
    const expirationDateHasPassed = moment(document.expirationDate).isBefore(moment());

    return expirationDateHasPassed;
};

export const documentIsApproved = (document: TermsAndConditionsDocument): boolean => {
    return !!document.approvalDate && !!document.approver;
};

export const checkAndAddSchemeToPdfUrls = (document: TermsAndConditionsDocument) => {
    let pdfUrl = document.pdfUrl;
    if (!pdfUrl?.includes('https://') && !pdfUrl?.includes('https://'))
        pdfUrl = `https://${pdfUrl}`;
    return pdfUrl;
};

export const getCurrentlyActiveDocuments = (documents: TermsAndConditionsDocument[]) => {
    const today = moment(getCurrentDate());
    const currentlyActive = documents.filter(
        (doc) =>
            moment(doc.effectiveDate).isSameOrBefore(today) &&
            moment(doc.expirationDate).isSameOrAfter(today)
    );

    return currentlyActive;
};

export const sortDocumentsByEffectiveDate = (
    documents: TermsAndConditionsDocument[]
): TermsAndConditionsDocument[] => {
    const sortedDocuments = documents.sort((a, b) => {
        return moment(a.effectiveDate).isBefore(b.effectiveDate) ? 0 : 1;
    });

    return sortedDocuments;
};

export const getEarliestDocumentFromArray = (
    documents: TermsAndConditionsDocument[]
): TermsAndConditionsDocument => {
    const sorted = sortDocumentsByEffectiveDate(documents);
    return sorted[0];
};
