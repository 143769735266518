import {
    PRODUCTION_ORDER_LOADING,
    PRODUCTION_ORDER_LOADED,
    PRODUCTION_ORDER_LOADING_ERROR,
    PRODUCTION_ORDER_DELIVERY_ORDER_LOADING,
    PRODUCTION_ORDER_DELIVERY_ORDER_LOADED,
    PRODUCTION_ORDER_DELIVERY_ORDER_LOADING_ERROR,
    PRODUCTION_ORDER_RESET_STATE
} from '../actions/action-types';

import { ProductionOrder } from './makeit-dashboard';

export interface ProductionOrderLinkedDeliveryOrder {
    deliveryOrderId?: number;
    productionOrderLineId?: number;
    status?: string;
    productSKU?: string;
    productDescription?: string;
    quantity?: number;
    deliveryDateTime?: string;
}

export interface LinkedDeliveryOrder {
    linkedDeliveryOrders?: ProductionOrderLinkedDeliveryOrder[];
}

export interface ProductionOrderDetailsState {
    productionOrder?: ProductionOrder;
    linkedDeliveryOrder?: LinkedDeliveryOrder;
    loading: boolean;
    error?: string;
}

const initialState: ProductionOrderDetailsState = {
    productionOrder: undefined,
    linkedDeliveryOrder: undefined,
    loading: false
};

const productionOrderDetails = (state = initialState, action: any) => {
    switch (action.type) {
        case PRODUCTION_ORDER_LOADING:
            return {
                ...state,
                loading: true,
            };
        case PRODUCTION_ORDER_LOADED:
            return {
                ...state,
                loading: false,
                productionOrder: action.order
            };
        case PRODUCTION_ORDER_LOADING_ERROR:
            return {
                ...state,
                details: undefined,
                loading: false,
                error: action.error,
            };
        case PRODUCTION_ORDER_DELIVERY_ORDER_LOADING:
            return { ...state, loaded: false, loading: true };
        case PRODUCTION_ORDER_DELIVERY_ORDER_LOADED:
            return {
                ...state,
                loaded: true,
                loading: false,
                linkedDeliveryOrder: action.linkedDeliveryOrder
            };
        case PRODUCTION_ORDER_DELIVERY_ORDER_LOADING_ERROR:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: action.error
            };
        case PRODUCTION_ORDER_RESET_STATE:
            return {
                ...state,
                productionOrder: action.productionOrder,
                linkedDeliveryOrder: action.linkedDeliveryOrder
            }
        default:
            return state;
    }
};

export default productionOrderDetails;