import {
    canadaStateProvs,
    mexicoStateProvs,
    naCountries,
    usStateProvs
} from '../../static-data/dropdown-lists';
import { Account, DeliverToAccount } from '../../store/reducers/customer-context';
export interface ShipToAccountInformation {
    shipToName: string;
    modeOfTransport: string;
    displayAddress: string;
}

export enum NCACountries {
    USA = 'US',
    Canada = 'CA',
    Mexico = 'MX'
}

export const getAccountAddress = (account: Account | DeliverToAccount) => {
    let address = account.address.address1 ? account.address.address1.trim() : '';
    if (account.address.address2) {
        address += account.address.address2.trim();
    }
    address += ', ';

    const city = account.address.city ? account.address.city.trim() + ', ' : '';
    const state = account.address.stateProvince ? account.address.stateProvince.trim() + ' ' : '';
    const postalCode = account.address.postalCode;

    const addressString = address + city + state + postalCode;

    return addressString;
};

export const getShippingInformation = (accountInformation: Account) => {
    let formattedInformation: ShipToAccountInformation = {
        shipToName: '',
        modeOfTransport: '',
        displayAddress: ''
    };
    formattedInformation.shipToName = accountInformation.name;
    formattedInformation.modeOfTransport = accountInformation.modeOfTransport;

    if (accountInformation.address) {
        const shipToAddress = accountInformation.address;
        formattedInformation.displayAddress =
            shipToAddress.address1?.trimEnd() +
            ', ' +
            shipToAddress.city +
            ', ' +
            shipToAddress.stateProvince +
            ' ' +
            shipToAddress.postalCode;
    }
    return formattedInformation;
};

/**
 * Returns object reference to assign for prepopulating <StateProvinceSelect/>.
 * @param stateValue Value of the state selection, usually from the back-end.
 * Example: 'CO' for Colorado
 * 'MB' for Manitoba
 * @param countryCode US, Mexico, or Canada
 *
 */
export const getStateProvSelection: (
    stateValue: string | undefined,
    countryCode: string
) => { value: string; name: string } | undefined = (stateValue, countryCode) => {
    switch (countryCode) {
        case NCACountries.USA:
            return usStateProvs.find((state) => state.value === stateValue);
        case NCACountries.Mexico:
            return mexicoStateProvs.find((state) => state.value === stateValue);
        case NCACountries.Canada:
            return canadaStateProvs.find((state) => state.value === stateValue);
    }
};

/**
 * Returns object reference to assign for prepopulating <StateProvinceSelect/>.
 * @param stateValue Value of the state selection, usually from the back-end.
 * Example: 'CO' for Colorado
 * 'MB' for Manitoba
 * @param countryCode US, Mexico, or Canada
 *
 */
export const getStateProvSelectionByName: (
    stateName: string | undefined,
    countryCode: string
) => { value: string; name: string } | undefined = (stateName, countryCode) => {
    switch (countryCode) {
        case NCACountries.USA:
            return usStateProvs.find((state) => state.name === stateName);
        case NCACountries.Mexico:
            return mexicoStateProvs.find((state) => state.name === stateName);
        case NCACountries.Canada:
            return canadaStateProvs.find((state) => state.name === stateName);
    }
};

/**
 * Returns object reference to assign for prepopulating <CountrySelect/>.
 * @param countryCode US, Mexico, or Canada
 *
 */
export function getNACountrySelection(countryCode: string) {
    return naCountries.find((country) => country.value === countryCode) ?? naCountries[7];
}

export const postalCodePattern = /^\d{5}([-\s]\d{4,5})?$|^[a-z]\d[a-z][-\s]\d[a-z]\d$/i;
