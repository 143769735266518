import {
    MakeItBulkATMLinesBySnoSku,
    MakeItBulkATMOrderByWeekAndSku
} from '../../../../store/reducers/makeit-bulk-atm';

/**
 * Do not display if a grouping has no lines or if all lines were deleted
 * Do display if any line has original quantity, because it was
 * an update rather than a new item
 * @param grouping
 * @returns
 */
export const checkGroupingShouldDisplay = (grouping: MakeItBulkATMLinesBySnoSku) => {
    return (
        (grouping.lines.length && grouping.totalPalletQuantity) ||
        grouping.lines.some((line) => line.originalPalletQuantity)
    );
};

export const filterOutDeletedOrders = (
    ordersBySnoSku: MakeItBulkATMOrderByWeekAndSku[]
): MakeItBulkATMOrderByWeekAndSku[] => {
    return ordersBySnoSku.map((order) => {
        order.linesBySnoSku = order.linesBySnoSku.filter((grouping) =>
            checkGroupingShouldDisplay(grouping)
        );

        order.linesBySnoSku.map((grouping) => {
            grouping.lines = grouping.lines.filter(
                (line) => line.originalPalletQuantity || (line.palletQuantity && !line.deleted)
            );
            return null;
        });
        return order;
    });
};
