import {
    PRODUCT_ESTIMATE_LOADING,
    PRODUCT_ESTIMATE_LOADED,
    PRODUCT_ESTIMATE_ERROR,
    PRODUCT_ESTIMATE_DETAILS_LOADED,
    PRODUCT_ESTIMATE_BANNER_LOADED
} from '../actions/action-types';
import { Product } from './product-portfolio';
import { Section } from './glp';

export interface EstimateDetails extends Product {
    annualQuantity?: number;
    includeEnds?: boolean;
}

export interface ProductEstimateDetailsState {
    details: EstimateDetails[];
    banner?: Section;
    loading: boolean;
    error?: string;
}

const initialState: ProductEstimateDetailsState = {
    details: [],
    loading: false
};

const productEstimate = (state = initialState, action: any) => {
    switch (action.type) {
        case PRODUCT_ESTIMATE_LOADING:
            return {
                ...state,
                loading: true
            };

        case PRODUCT_ESTIMATE_BANNER_LOADED:
            return {
                ...state,
                banner: action.banner
            };
        case PRODUCT_ESTIMATE_DETAILS_LOADED:
            return {
                ...state,
                loading: false,
                details: action.products
            };
        case PRODUCT_ESTIMATE_LOADED:
            return {
                ...state,
                loading: false
            };
        case PRODUCT_ESTIMATE_ERROR:
            return {
                ...state,
                details: undefined,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
};

export default productEstimate;
