import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { MakeItSummaryState } from '../../../../store/reducers/make-it-summary';
import StandardTable from '../../../reusable/molecules/StandardTable';
import {
    boldWeight,
    blackWeight,
    dateOptionInvalidGray,
    ballBlue,
    small,
    xxl,
    medium,
    regularWeight
} from '../../../../themes/globalConstants';
import {
    ProductionOrderSummary,
    OpenProductionOrderRow
} from '../../../../store/reducers/make-it-summary';
import MakeItOpenRow from './MakeItOpenRow';
import { CustomerContextState, PaymentTerms } from '../../../../store/reducers/customer-context';
import {
    getFormattedDateStringForApplication,
    getCurrentDate
} from '../../../../utility/helpers/date-helpers';
import MakeItOpenColumns from './MakeItOpenColumns';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import TranslationService from '../../../../utility/services/translation-service';
import MakeItDisclaimerSection from './MakeItDisclaimerSection';
import { hasPersonas, Persona } from '../../../../utility/auth/useSecurity';
import { AuthState } from '../../../../store/reducers/auth';
import { usePrevious } from '../../../../utility/helpers/react-util';
import {
    formatOrderQuantity,
    getAddress,
    getName,
    getProdOrderType
} from '../../../../utility/helpers/summary-helpers';
import SummarySearchBar from '../../../reusable/atoms/SummarySearchBar';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { CSVLink } from 'react-csv';
import ReactPdf from '@react-pdf/renderer';

interface Props {
    showCustomerPOCol: boolean;
}

const useStyles = makeStyles((theme) => ({
    searchBar: {
        float: 'right',
        marginRight: '10px',
        width: '250px',
        position: 'relative',
        bottom: '-47px',
        zIndex: 1
    },
    action: {
        cursor: 'pointer'
    },
    disclaimerSectionWrapper: {
        paddingLeft: '2em',
        marginBottom: '-1.25em'
    },
    paper: {
        marginBottom: '1em'
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '1em'
    },
    title: {
        color: theme.palette.secondary.main,
        fontSize: xxl,
        fontWeight: boldWeight,
        paddingLeft: '0.625em'
    },
    tableWrapper: {
        '& .MuiPaper-root': {
            boxShadow: 'unset'
        },
        '& .MuiTable-root': {
            tableLayout: 'fixed'
        },
        '& .MuiToolbar-root': {
            padding: 0,
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                },
                '&:first-of-type ': {
                    paddingLeft: 0
                }
            },
            '& > h2': {
                fontSize: '1.5em',
                fontWeight: boldWeight,
                color: theme.palette.secondary.main,
                paddingLeft: '0.625em'
            },
            '& .MuiInputBase-formControl': {
                border: `1px solid ${dateOptionInvalidGray}`,
                borderRadius: '.125em',
                '&:before': {
                    display: 'none'
                },
                '&:after': {
                    display: 'none'
                },
                '& svg': {
                    height: '.875em',
                    width: '.875em',
                    fill: ballBlue
                },
                '& .MuiIconButton-root': {
                    '&:hover': {
                        backgroundColor: 'transparent !important'
                    }
                },
                '& .MuiInput-input': {
                    paddingLeft: '.25em'
                }
            }
        },
        '& .MuiTableSortLabel-root': {
            fontSize: small,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em',
            width: '99%',
            justifyContent: 'space-between',
            '& .MuiSvgIcon-root': {
                fontSize: 14,
                opacity: '1'
            }
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                '&:nth-child(1)': {
                    width: '20%'
                },
                '&:nth-child(2)': {
                    width: '14%'
                },
                '&:nth-child(3)': {
                    width: '15%'
                },
                '&:nth-child(4)': {
                    width: '10%'
                },
                '&:nth-child(5)': {
                    width: '10%'
                },
                '&:nth-child(6)': {
                    width: '16%'
                },
                '&:nth-child(7)': {
                    width: '16%'
                },
                '&:nth-child(8)': {
                    width: '10%'
                },
                '&:nth-child(9)': {
                    width: '16%',
                    fontSize: small,
                    fontWeight: blackWeight,
                    textTransform: 'uppercase',
                    color: theme.palette.secondary.main,
                    borderRadius: '0.25em',
                    paddingLeft: '0.5em',
                    justifyContent: 'space-between'
                }
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    },
    icon: {
        color: ballBlue
    },
    downloadWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

const pdfStyles = ReactPdf.StyleSheet.create({
    link: {
        fontSize: medium,
        fontWeight: regularWeight
    }
});

const MakeItOpenTab = ({ showCustomerPOCol }: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { shipToAccounts, selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const auth = useTypedSelector<AuthState>((state) => state.auth);
    const { openOrders, openOrdersLoaded } = useTypedSelector<MakeItSummaryState>(
        (state) => state.makeItSummary
    );
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [formattedOpenOrders, setFormattedOpenOrders] = useState<OpenProductionOrderRow[]>([]);
    const [creditAccount, setCreditAccount] = useState<boolean>(false);
    const [columns, setColumns] = useState<any>([]);
    const [filteredOrders, setFilteredOrders] = useState<OpenProductionOrderRow[]>([]);
    const prevSelectedShipToId = usePrevious(selectedAccountId);

    const formatOpenOrders = useCallback(
        (unformattedOrders: ProductionOrderSummary[]) => {
            let formattedOrders: OpenProductionOrderRow[] = [];
            unformattedOrders.forEach((order) => {
                let newOrder: OpenProductionOrderRow;
                newOrder = {
                    ...order,
                    productionOrderNumber: order.productionOrderNumber
                        ? order.productionOrderNumber.toString()
                        : '',
                    searchablePONumber: order.productionOrderNumber
                        ? order.productionOrderNumber.toString()
                        : 'pending',
                    ballPONumber: order.productionOrderNumber,
                    shipToName: getName(order.productionOrderId, shipToAccounts, openOrders),
                    shipToAddress: getAddress(order.productionOrderId, shipToAccounts, openOrders),
                    submittedDateString: getFormattedDateStringForApplication(
                        order.submittedDate?.toString()
                    ),
                    firstRequestedDateString: getFormattedDateStringForApplication(
                        order.firstRequestedDate?.toString()
                    ),
                    orderQuantityString: formatOrderQuantity(
                        order.totalOrderQuantity,
                        order.totalOrderSKUs
                    ),
                    orderQuantityEachesString:
                        order.totalOrderQuantityEaches?.toLocaleString() + ' ea.',
                    shipToProdOrderType: getProdOrderType(
                        order.productionOrderId,
                        shipToAccounts,
                        openOrders
                    ),
                    requiredShipDateString: getFormattedDateStringForApplication(
                        order.requiredShipDate
                    ),
                    availableQuantityString: order.availableQuantity?.toString() + ' PL',
                    availableEachesString: order.availableEaches?.toLocaleString() + ' ea.'
                };
                formattedOrders.push(newOrder);
            });

            setFormattedOpenOrders(formattedOrders);
        },
        [openOrders, shipToAccounts]
    );
    useEffect(() => {
        if (!formattedOpenOrders.length && openOrdersLoaded) {
            formatOpenOrders(openOrders);
        }
    }, [formatOpenOrders, formattedOpenOrders.length, openOrders, openOrdersLoaded]);

    useEffect(() => {
        if (prevSelectedShipToId && prevSelectedShipToId !== selectedAccountId) {
            setFormattedOpenOrders([]);
        }
    }, [prevSelectedShipToId, selectedAccountId]);

    useEffect(() => {
        let isCoPacker = hasPersonas([Persona.CoPacker], auth);
        let showActionsColumn = creditAccount && !isCoPacker;
        setColumns(
            MakeItOpenColumns.getMakeItOpenColumns(
                cultureCode,
                showActionsColumn,
                showCustomerPOCol
            )
        );
    }, [cultureCode, creditAccount, showCustomerPOCol, auth]);

    useEffect(() => {
        if (shipToAccounts) {
            shipToAccounts.forEach((shipTo) => {
                if (shipTo.paymentTerms === PaymentTerms.Credit) {
                    setCreditAccount(true);
                }
            });
        }
    }, [shipToAccounts]);

    const OpenPORow = ({ row }: { row: Partial<OpenProductionOrderRow> }) => {
        if (row) {
            const lockedStatus = row.isLocked ? row.isLocked : false;
            return (
                <MakeItOpenRow
                    order={row as OpenProductionOrderRow}
                    isLocked={lockedStatus}
                    showCustomerPOCol={showCustomerPOCol}
                    key={row.productionOrderId}
                />
            );
        } else {
            return null;
        }
    };

    const handleRowFilter = (filteredRows) => {
        setFilteredOrders(filteredRows);
    };

    interface CSVRow {
        ship_to_detail?: string;
        customer_po?: string;
        production_order_number?: string;
        submitted?: string;
        required_ship_date?: string;
        total_order_pallets?: number;
        total_order_eaches?: number;
        total_order_skus: number;
        status?: string;
    }

    const csvHeaders = [
        { label: t('shipTo', 'Ship to').toUpperCase(), key: 'ship_to_detail' },
        { label: t('customerPoNumber', 'CUSTOMER PO#').toUpperCase(), key: 'customer_po' },
        {
            label: t('ballProdOrderNumber', 'BALL PRODUCTION ORDER NUMBER').toUpperCase(),
            key: 'production_order_number'
        },
        { label: t('submitted', 'SUBMITTED').toUpperCase(), key: 'submitted' },
        {
            label: t('requiredShipDate', 'Required Ship Date').toUpperCase(),
            key: 'required_ship_date'
        },
        {
            label: t('totalOrderQuantityPL', 'Total Order Quantity (PL)').toUpperCase(),
            key: 'total_order_pallets'
        },
        {
            label: t('totalOrderQuantityEA', 'Total Order Quantity (ea.)').toUpperCase(),
            key: 'total_order_eaches'
        },
        {
            label: t('totalOrderQuantitySKUS', 'Total Order Quantity (SKUs)').toUpperCase(),
            key: 'total_order_skus'
        },
        { label: t('status', 'STATUS').toUpperCase(), key: 'status' }
    ];

    const csvArray = () => {
        let rows: any[] = [];
        if (!Array.isArray(filteredOrders)) return rows;
        filteredOrders.map((order) => {
            const row: CSVRow = {
                ship_to_detail: `${order.shipToName}, ${order.shipToAddress}, #${order.shipToId}`,
                customer_po: order.customerProductionOrderId,
                production_order_number: order.productionOrderNumber || t('pending', 'Pending'),
                submitted: order.submittedDateString,
                required_ship_date: order.requiredShipDateString || 'N/A',
                total_order_pallets: order.totalOrderQuantity,
                total_order_eaches: order.totalOrderQuantityEaches,
                total_order_skus: order.totalOrderSKUs,
                status: order.status
            };

            rows.push(row);
            return null;
        });
        return rows;
    };

    const csvContents = csvArray();
    return (
        <Paper elevation={2} className={classes.paper}>
            <div className={classes.titleContainer}>
                <Typography className={classes.title}>
                    {TranslationService.getTranslatedText(cultureCode, 'openProductionOrders')}
                </Typography>
                <Grid className={classes.downloadWrapper}>
                    <CSVLink
                        className={
                            'MuiTypography-root MuiLink-root MuiLink-underlineAlways MuiTypography-colorPrimary'
                        }
                        style={pdfStyles.link}
                        underline="always"
                        data-testid="download-btn"
                        headers={csvHeaders}
                        data={csvContents}
                        filename={`Make it Summary - Open Production Order -${getCurrentDate()}.csv`}
                    >
                        <Trans i18nKey="downloadCSV">Download .CSV</Trans>
                    </CSVLink>
                    <GetAppOutlinedIcon fontSize="small" className={classes.icon} />
                </Grid>
            </div>
            <div className={classes.disclaimerSectionWrapper}>
                <MakeItDisclaimerSection />
            </div>
            <Grid className={classes.tableWrapper}>
                <SummarySearchBar
                    rows={formattedOpenOrders}
                    columns={columns}
                    onRowFilter={handleRowFilter}
                />
                {formattedOpenOrders && (
                    <StandardTable
                        gridName="Open Orders"
                        columns={columns}
                        lines={filteredOrders}
                        rowComponent={OpenPORow}
                        searchText={false}
                    />
                )}
            </Grid>
        </Paper>
    );
};

export default MakeItOpenTab;
