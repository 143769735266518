import React from 'react';
import { HeaderContent } from '../../../store/reducers/header-content';
import { useTypedSelector } from '../../../store/reducers/reducer';
import MailLink from '../atoms/MailLink';

interface Props {
    className?: string;
}

const ContactUsLink = ({ className }: Props) => {
    const headerContent = useTypedSelector<HeaderContent>((state) => state.headerContent);

    return (
        <MailLink messageType={'CONTACT'} className={className}>
            {headerContent.content.contactUs}
        </MailLink>
    );
};

export default ContactUsLink;
