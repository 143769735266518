import React, { useEffect, useState, useMemo } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import { black, blackWeight, boldWeight } from '../../../../themes/globalConstants';
import PlanItConfigRow from './PlanItConfigRow';
import { OrderProductTableRow } from '../../../../store/reducers/orders-dashboard';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import PlanItColumns from '../../PlanItGuided/components/PlanItColumns';
import ErrorAlert from '../../../reusable/atoms/ErrorAlert';
import { paginationOptions } from '../../../../utility/helpers/grid-helpers';
import PlanItConfigToolbar from './PlanItConfigToolbar';

interface Props {
    items: OrderProductTableRow[];
    onRemoveItem: (item: OrderProductTableRow) => void;
    onItemUpdates: (item: OrderProductTableRow) => void;
    onViewFiltersClick: (viewTypes: string[]) => void;
    warning: boolean;
    warningMessage: string;
    warningTranslation: string;
}

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        width: '100%',
        '& .MuiToolbar-root': {
            minHeight: 0,
            padding: '0 0.125em',
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                }
            }
        },
        '& .MuiTableSortLabel-root': {
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em'
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTablePagination-root': {
            '& .MuiTablePagination-caption': {
                marginLeft: '1em',
                fontWeight: boldWeight,
                color: black
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                '&:nth-child(1)': {
                    width: '12%'
                },
                '&:nth-child(2)': {
                    width: '30%'
                },
                '&:nth-child(4)': {
                    width: '10%'
                },
                '&:nth-child(5)': {
                    width: '15%'
                },
                '&:nth-child(6)': {
                    width: '10%'
                },
                '&:nth-child(7)': {
                    width: '12%'
                },
                '&:nth-child(8), &:nth-child(9)': {
                    borderLeft: 'none'
                }
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    }
}));

export default function PlanItConfigGrid({
    items,
    onRemoveItem,
    onItemUpdates,
    onViewFiltersClick,
    warning,
    warningMessage,
    warningTranslation
}: Props) {
    const classes = useStyles();
    const { t } = useTranslation();

    const [columns, setColumns] = useState<any>([]);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const columnsKey = useMemo(() => JSON.stringify(columns), [columns]);

    const CustomToolbarOptions = new ToolbarOptions({
        customItems: (
            <Grid container>
                <Grid container item xs={12}>
                    <ErrorAlert
                        showError={warning}
                        errorMessage={t(warningTranslation, warningMessage)}
                    />
                </Grid>
                <PlanItConfigToolbar onViewFiltersClicked={onViewFiltersClick} />
            </Grid>
        ),
        exportButton: false,
        printButton: false,
        advancePagination: false,
        searchText: false,
        rowsPerPageOptions: paginationOptions.rowsPerPage
    });

    const CustomerPlanItRow = ({ row }: { row: Partial<OrderProductTableRow> }) => {
        return (
            <PlanItConfigRow
                key={row.productSku}
                item={row}
                onRemoveItem={onRemoveItem}
                onItemUpdates={onItemUpdates}
            />
        );
    };

    useEffect(() => {
        setColumns(PlanItColumns.getPlanItColumns(cultureCode, true, 'CONFIG'));
    }, [cultureCode]);

    return (
        <Grid
            container
            className={classes.tableWrapper}
            key={columnsKey}
            data-testid="plan-it-config-data-grid"
        >
            <DataGrid
                columns={columns}
                dataSource={items}
                gridName="Plan It Config Grid"
                rowComponent={CustomerPlanItRow}
                toolbarOptions={CustomToolbarOptions}
            />
        </Grid>
    );
}
