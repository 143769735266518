import React from 'react';
import { makeStyles, Grid, Collapse, Typography } from '@material-ui/core';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import { DeliveryShipment, ProductToShip } from '../../../../store/reducers/shipping-dashboard';
import {
    blackWeight,
    ballGray,
    small,
    warningLabel,
    ballBlue,
    boldWeight
} from '../../../../themes/globalConstants';
import { generateKey } from '../../../../utility/helpers/order-helpers';
import { Alert } from '@material-ui/lab';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import EditShipmentsConfirmationConfigColumns from './EditShipmentsConfirmationConfigColumns';
import EditShipmentsConfirmationConfigRow from './EditShipmentsConfirmationConfigRow';

interface Props {
    items: ProductToShip[];
    shipment: DeliveryShipment;
    warning?: boolean;
    warningText?: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        width: '100%',
        '& .MuiPaper-root': {
            boxShadow: 'unset'
        },
        '& .MuiToolbar-root': {
            padding: '0 0.125em',
            marginTop: '-5em',
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                }
            }
        },
        '& .MuiTableSortLabel-root': {
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em'
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableCell-head': {
            color: ballGray,
            fontSize: small,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            '&:not(:first-of-type)': {
                borderLeft: 'unset'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                '&:nth-child(1)': {
                    width: '5%'
                },
                '&:nth-child(2)': {
                    width: '12.5%'
                },
                '&:nth-child(3)': {
                    width: '12.5%'
                },
                '&:nth-child(4)': {
                    width: '18%'
                },
                '&:nth-child(5)': {
                    width: '6.5%'
                },
                '&:nth-child(6)': {
                    width: '6.5%'
                },
                '&:nth-child(7)': {
                    width: '6.5%'
                },
                '&:nth-child(8)': {
                    width: '6.5%'
                },
                '&:nth-child(9)': {
                    width: '8%'
                },
                '&:nth-child(10)': {
                    width: '18%'
                }
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    },
    warningContainer: {
        marginTop: '1em',
        width: '100%',
        padding: '0.875em 0',
        borderRadius: '.125em',
        '& .MuiAlert-icon': {
            color: ballBlue,
            display: 'flex',
            alignItems: 'center'
        }
    },
    warning: {
        backgroundColor: warningLabel
    },
    warningText: {
        fontWeight: boldWeight
    }
}));

export default function EditShipmentsConfirmationConfigGrid({
    items,
    shipment,
    warning = false,
    warningText
}: Props) {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const CollapsibleHeader = () => {
        return (
            <>
                {warning && (
                    <Grid container justify="center">
                        <Collapse in={warning} className={classes.warningContainer}>
                            <Alert severity="warning" className={classes.warning}>
                                <Typography className={classes.warningText}>
                                    {warningText}
                                </Typography>
                            </Alert>
                        </Collapse>
                    </Grid>
                )}
            </>
        );
    };

    const CustomToolbarOptions = new ToolbarOptions({
        customItems: <CollapsibleHeader />,
        exportButton: false,
        printButton: false,
        advancePagination: false,
        enablePagination: false,
        searchText: false
    });

    const CustomerMakeItRow = ({ row }: { row: Partial<ProductToShip> }) => {
        let itemIndex = items.findIndex((item) => item.productSku === row.productSku);

        return (
            <EditShipmentsConfirmationConfigRow
                key={generateKey('product')}
                item={row as ProductToShip}
                sequence={itemIndex + 1}
            />
        );
    };

    return (
        <Grid container className={classes.tableWrapper}>
            <DataGrid
                columns={EditShipmentsConfirmationConfigColumns.getEditShipmentsConfirmationConfigColumns(
                    cultureCode
                )}
                dataSource={items}
                gridName="Ship it Config Grid"
                rowComponent={CustomerMakeItRow}
                toolbarOptions={CustomToolbarOptions}
            />
        </Grid>
    );
}
