import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class MakeItConversionReviewAddProductColumns {
    static getColumns = (culture: string) => {
        return [
            createColumn('displayId', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            }),
            createColumn('productGraphicId', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'productGraphicId'),
                sortable: true,
                filterable: true,
                searchable: true
            }),
            createColumn('displayName', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'productDescription'),
                sortable: true,
                filterable: true,
                searchable: true
            }),
            createColumn('coating', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'coating'),
                sortable: true,
                filterable: true,
                searchable: true
            }),
            createColumn('leadTime', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'leadTime'),
                sortable: true,
                filterable: true,
                searchable: true
            }),
            createColumn('', {
                dataType: ColumnDataType.String
            }),
            createColumn('productId', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('graphicIdAndVersion', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            }),
            createColumn('leadTimeWeeks', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('userSuppliedProductId', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productSku', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('leadTimeWeeks', {
                dataType: ColumnDataType.Numeric,
                visible: false
            })
        ];
    };
}
