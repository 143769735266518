import Axios from 'axios';
import { BillingAndShippingForm } from '../../store/reducers/onboarding-dashboard';
import { CreditApplication } from '../../store/reducers/onboarding-dashboard';
import { Culture } from '../../store/reducers/region-culture';
import { Persona } from '../auth/useSecurity';

export enum OnboardingProcessState {
    Unknown,
    Draft,
    Complete
}

export const mockOnboardingAccountId = '4442323';

export enum CertificateType {
    UNKNOWN = 'UNKNOWN',
    BLANKET = 'BLANKET',
    SINGLE = 'SINGLE'
}

export enum OnboardingStepState {
    UNKNOWN = 'UNKNOWN',
    DRAFT = 'DRAFT',
    COMPLETE = 'COMPLETE'
}

export enum OnboardingStepStatus {
    INCOMPLETE = 'INCOMPLETE',
    COMPLETE = 'COMPLETE'
}

export enum JoiningStatus {
    INCOMPLETE = 'INCOMPLETE',
    COMPLETE = 'COMPLETE'
}

export enum ProductEnhancement {
    BRE = 'BRE',
    WIDGET = 'WIDGET'
}

export interface OnboardingAccountStatus {
    accountId: string;
    termsStatus: OnboardingStepStatus;
    addressesStatus: OnboardingStepStatus;
    creditAppStatus: OnboardingStepStatus;
    taxAppStatus: OnboardingStepStatus;
    joiningStatus: OnboardingStepStatus;
    eightStepStatus: OnboardingStepStatus;
    completionDate?: Date;
    billToAccountId?: string;
    shipToAccountId?: string;
}

export interface TaxApplication {
    id?: string;
    accountId?: string;
    username?: string;
    state: OnboardingStepState;
    issuedToSeller?: Array<string>;
    certificateType: CertificateType;
    purchaseOrderNumber?: string;
    buyerInformation: {
        address: {
            name?: string;
            country?: string;
            addressLine1?: string;
            addressLine2?: string;
            addressLine3?: string;
            city?: string;
            state?: string;
            zipCode?: string;
        };
        registeredAs?: string[];
        description?: string;
        tangibleDescription?: string;
        stateRegistrations?: Array<{ state: string; registrationId: string }>;
    };
    eSignature: {
        certification?: boolean;
        firstName?: string;
        lastName?: string;
        date?: string;
        title?: string;
        email?: string;
        phoneNumber?: string;
    };
}

export interface Invitations {
    permissions: PermissionsRequest[];
}

export interface PermissionsRequest {
    username: string;
    personaToAdd: Persona;
    accountId: string;
}

export interface TermsAndConditionsSignature {
    language: string;
    version: string;
    accountId: string;
    accepted: boolean;
}

interface TermsAndConditionsResponse {
    language: string;
    version: string;
    title: string;
    fileLink: string;
}

export default class OnboardingService {
    static saveBillingAndShippingInformation = (
        form: BillingAndShippingForm,
        accessToken: string,
        formState: OnboardingStepState,
        noFile: boolean
    ) => {
        const axiosConfig = OnboardingService.createConfig(accessToken);
        form.state = formState;
        if (form.id) {
            // update existing record
            return Axios.put(`${process.env.REACT_APP_JOINING_API}/addresses?noFile=${noFile}`, form, axiosConfig);
        } else {
            // create new record
            return Axios.post(`${process.env.REACT_APP_JOINING_API}/addresses?noFile=${noFile}`, form, axiosConfig);
        }
    };

    static submitBillShipFile = (formId: string, file: File, accessToken: string) => {
        const formData = new FormData();
        formData.append('File', file[0], file[0].name);

        return Axios.post(
            `${process.env.REACT_APP_JOINING_API}/addresses/${formId}/file`,
            formData,
            OnboardingService.createMultipartConfig(accessToken)
        );
    };

    static submitCreditAppFile = (formId: string, file: File, accessToken: string) => {
        const formData = new FormData();
        formData.append('File', file[0], file[0].name);

        return Axios.post(
            `${process.env.REACT_APP_JOINING_API}/credit-applications/${formId}/file`,
            formData,
            OnboardingService.createMultipartConfig(accessToken)
        );
    };

    static saveCreditApplicationForm = (
        form: CreditApplication,
        accessToken: string,
        formState,
        regionCode: string
    ) => {
        const axiosConfig = OnboardingService.createConfigWithRegion(accessToken, regionCode);
        form.state = formState;
        if (form.id) {
            // update existing record
            return Axios.put(
                `${process.env.REACT_APP_JOINING_API}/credit-applications/`,
                form,
                axiosConfig
            );
        } else {
            // create new record
            return Axios.post(
                `${process.env.REACT_APP_JOINING_API}/credit-applications`,
                form,
                axiosConfig
            );
        }
    };

    static updateSalesAndUseTax = (
        request: TaxApplication,
        accessToken: string,
        regionCode: string
    ) => {
        return Axios.put<TaxApplication>(
            `${process.env.REACT_APP_JOINING_API}/tax-applications`,
            request,
            OnboardingService.createConfigWithRegion(accessToken, regionCode)
        );
    };

    static createSalesAndUseTax = (
        request: TaxApplication,
        accessToken: string,
        regionCode: string
    ) => {
        return Axios.post<TaxApplication>(
            `${process.env.REACT_APP_JOINING_API}/tax-applications`,
            request,
            OnboardingService.createConfigWithRegion(accessToken, regionCode)
        );
    };

    static getSalesAndUseTax = (accountId: string, userName: string, accessToken: string) => {
        return Axios.get<TaxApplication>(
            `${process.env.REACT_APP_JOINING_API}/tax-applications?AccountId=${accountId}&Username=${userName}`,
            OnboardingService.createConfig(accessToken)
        );
    };

    static getBillingAndShippingForm = (accountId: string, accessToken: string) => {
        return Axios.get<BillingAndShippingForm>(
            `${process.env.REACT_APP_JOINING_API}/addresses?AccountId=${accountId}`,
            OnboardingService.createConfig(accessToken)
        );
    };

    static getCreditApplication = (accountId: string, accessToken: string) => {
        return Axios.get<CreditApplication>(
            `${process.env.REACT_APP_JOINING_API}/credit-applications?accountId=${accountId}`,
            OnboardingService.createConfig(accessToken)
        );
    };

    static createPermissionInvitation = (
        request: Invitations,
        accessToken: string,
        regionCode: string
    ) => {
        return Axios.post<Invitations>(
            `${process.env.REACT_APP_JOINING_API}/permissions`,
            request,
            OnboardingService.createConfigWithRegion(accessToken, regionCode)
        );
    };

    static getOnboardingAccountStatus = (accessToken: string, accountId: string) => {
        return Axios.get<OnboardingAccountStatus>(
            `${process.env.REACT_APP_JOINING_API}/accounts/status?AccountId=${accountId}`,
            OnboardingService.createConfig(accessToken)
        );
    };

    static submitTermsAndConditionsSignature = (
        accessToken: string,
        payload: TermsAndConditionsSignature
    ) => {
        payload.language = OnboardingService.getLanguageFromCultureCode(payload.language);
        return Axios.post<TermsAndConditionsSignature>(
            `${process.env.REACT_APP_JOINING_API}/terms-conditions/signature`,
            payload,
            OnboardingService.createConfig(accessToken)
        );
    };
    static getTermsAndConditions = (accessToken: string, cultureCode: string) => {
        let language = OnboardingService.getLanguageFromCultureCode(cultureCode);

        return Axios.get<TermsAndConditionsResponse>(
            `${process.env.REACT_APP_JOINING_API}/terms-conditions?Language=${language}`,
            OnboardingService.createConfig(accessToken)
        ).then((resp) => resp.data);
    };

    private static getLanguageFromCultureCode = (cultureCode: string) => {
        switch (cultureCode) {
            case Culture.esES:
                return 'Spanish';
            case Culture.frFR:
                return 'French';
            default:
                return 'English';
        }
    };

    private static createConfig = (accessToken: string) => ({
        headers: { Authorization: `Bearer ${accessToken}` }
    });

    private static createConfigWithRegion = (accessToken: string, regionCode: string) => ({
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Accept-Region': `${regionCode}`
        }
    });

    private static createMultipartConfig = (accessToken: string) => ({
        headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'multipart/form-data' }
    });
}
