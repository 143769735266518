import React from 'react';
import { makeStyles, Typography, TableRow, TableCell, Grid } from '@material-ui/core';
import { ProductionOrderLine } from '../../../../store/reducers/makeit-dashboard';
import {
    blackWeight,
    ballLtGray,
    orange,
    boldWeight,
    medium,
    regularWeight
} from '../../../../themes/globalConstants';
import {
    constructPDFCanDescription,
    constructPDFEndDescription,
    getGraphicIdAndVersion
} from '../../../../utility/helpers/order-helpers';
import { useTranslation } from 'react-i18next';
import { getUtcMidnight } from '../../../../utility/helpers/date-helpers';
import { getEachesConversion } from '../../../../utility/helpers/shipment-helpers';

interface Props {
    line: ProductionOrderLine;
}

const useStyles = makeStyles((theme) => ({
    name: {
        fontWeight: boldWeight,
        fontSize: medium,
        letterSpacing: 0.17
    },
    tableRowContainer: {
        '& p:first-of-type': {
            fontWeight: blackWeight
        },
        '& .MuiInputBase-input': {
            textAlign: 'right',
            paddingRight: '.25em'
        }
    },
    textField: {
        border: `2px solid ${ballLtGray}`,
        borderRadius: '.125em',
        fontWeight: blackWeight
    },
    smalltextField: {
        width: '3.125em',
        height: '1.5em',
        marginRight: '0.5em'
    },
    alignCenter: {
        textAlign: 'center'
    },
    alignRight: {
        textAlign: 'right'
    },
    alignLeft: {
        '& .MuiInputBase-input': {
            textAlign: 'left',
            paddingLeft: '0.375em'
        }
    },
    paletteInputContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    addBtn: {
        padding: 0,
        height: '2.28em',
        width: '3.2em'
    },
    trash: {
        paddingRight: '0',
        minWidth: 'unset',
        '&:hover': {
            backgroundColor: 'white'
        },
        '& svg': {
            fill: orange
        }
    },
    colorBlock: {
        width: 16,
        height: 50
    },
    sequence: {
        marginLeft: '0.750em'
    },
    selectWrapper: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiInputBase-root': {
            backgroundColor: theme.palette.common.white,
            border: `2px solid ${ballLtGray}`,
            borderRadius: '.125em',
            '&:before': {
                border: 0
            },
            '&.Mui-focused': {
                '& .MuiSvgIcon-root': {
                    transform: 'rotate(-180deg)'
                }
            },
            '& .MuiSvgIcon-root': {
                fill: theme.palette.primary.main,
                transition: 'transform 200ms'
            }
        }
    },
    fillerLineSelect: {
        '& .MuiFilledInput-underline ': {
            padding: '0 !important',
            '& > input': {
                padding: '0.375em !important',
                fontWeight: blackWeight
            },
            '& .MuiInputAdornment-root': {
                marginTop: 0,
                cursor: 'pointer'
            }
        }
    },
    blackWeightItem: {
        fontWeight: blackWeight
    },
    regularWeightItem: {
        fontWeight: regularWeight
    },
    removedBlackWeightItem: {
        'text-decoration': 'line-through',
        fontWeight: blackWeight,
        opacity: '50%'
    },
    removedRegularWeightItem: {
        'text-decoration': 'line-through',
        fontWeight: regularWeight,
        opacity: '50%'
    }
}));

const EditProductionOrderConfirmationRow = ({ line }: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const getDesc = (line: ProductionOrderLine) => {
        return line.productType === 'END'
            ? constructPDFEndDescription(line)
            : constructPDFCanDescription(line);
    };

    const formatDate = (date?: string) => {
        if (date) {
            return getUtcMidnight(date).format('MM/DD/YYYY');
        } else {
            return '';
        }
    };

    const isDateChanged = (line: ProductionOrderLine) => {
        let isDateChanged = false;
        if (formatDate(line.originalRequestedDate) !== formatDate(line.requestedDate)) {
            isDateChanged = true;
        }
        return isDateChanged;
    };

    const isQuantityChanged = (line: ProductionOrderLine) => {
        let isQuantityChanged = false;
        if (line.palletQuantity !== line.originalPalletQuantity) {
            isQuantityChanged = true;
        }
        return isQuantityChanged;
    };

    return (
        <TableRow
            hover={true}
            key={line.productionOrderLineId}
            className={classes.tableRowContainer}
        >
            <TableCell>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        <Typography
                            key={'product-id-' + line.productionOrderLineId}
                            data-testid="display-id"
                            className={
                                line.isLineRemoved
                                    ? classes.removedBlackWeightItem
                                    : classes.blackWeightItem
                            }
                        >
                            {line.displayId}
                        </Typography>
                        <Typography
                            key={'graphic-id-' + line.productionOrderLineId}
                            data-testid="graphic-id"
                            className={
                                line.isLineRemoved
                                    ? classes.removedRegularWeightItem
                                    : classes.regularWeightItem
                            }
                        >
                            {getGraphicIdAndVersion(line.productType, line.productGraphicId)}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        <Typography
                            key={'product-desc-' + line.productionOrderLineId}
                            data-testid="display-name"
                            className={
                                line.isLineRemoved
                                    ? classes.removedBlackWeightItem
                                    : classes.blackWeightItem
                            }
                        >
                            {line.displayName}
                        </Typography>
                        <Typography
                            key={'product-characteristics-' + line.productionOrderLineId}
                            data-testid="description"
                            className={
                                line.isLineRemoved
                                    ? classes.removedRegularWeightItem
                                    : classes.regularWeightItem
                            }
                        >
                            {getDesc(line)}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        <Typography
                            key={'coating-' + line.productionOrderLineId}
                            data-testid="coating"
                            className={
                                line.isLineRemoved
                                    ? classes.removedBlackWeightItem
                                    : classes.blackWeightItem
                            }
                        >
                            {line.productCoating}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        <Typography
                            key={'product-date-' + line.productionOrderLineId}
                            data-testid="original-product-date"
                            className={
                                line.isLineRemoved
                                    ? classes.removedBlackWeightItem
                                    : classes.blackWeightItem
                            }
                        >
                            {line.productionOrderLineId
                                ? formatDate(line.originalRequestedDate)
                                : '-'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>

            <TableCell>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        <Typography
                            key={'product-date-' + line.productionOrderLineId}
                            data-testid="revised-product-date"
                            className={classes.blackWeightItem}
                        >
                            {line.isLineRemoved
                                ? t('removed', 'REMOVED')
                                : isDateChanged(line)
                                ? formatDate(line.requestedDate)
                                : '-'}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>

            <TableCell>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        <Typography
                            key={'product-quantity-' + line.productionOrderLineId}
                            data-testid="original-quantity"
                            className={
                                line.isLineRemoved
                                    ? classes.removedBlackWeightItem
                                    : classes.blackWeightItem
                            }
                        >
                            {line.productionOrderLineId
                                ? `${line.originalPalletQuantity?.toLocaleString()} PL`
                                : '-'}
                        </Typography>
                        <Typography>
                            {line.productionOrderLineId &&
                                getEachesConversion(
                                    line.originalPalletQuantity!,
                                    line.quantityPerPallet!
                                )}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        <Typography
                            key={'product-quantity-' + line.productionOrderLineId}
                            data-testid="revised-quantity"
                            className={
                                line.isLineRemoved
                                    ? classes.removedBlackWeightItem
                                    : classes.blackWeightItem
                            }
                        >
                            {isQuantityChanged(line) ? line.palletQuantity + ' PL' : '-'}
                        </Typography>
                        <Typography>
                            {isQuantityChanged(line) &&
                                getEachesConversion(line.palletQuantity!, line.quantityPerPallet!)}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
        </TableRow>
    );
};

export default EditProductionOrderConfirmationRow;
