import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { Trans } from 'react-i18next';
import { ballGray } from '../../../../themes/globalConstants';
import { ProductionOrder } from '../../../../store/reducers/makeit-dashboard';
import { orderStatusCodeFormatter } from '../../../../utility/helpers/order-helpers';
import { formatStatus } from '../../../../utility/helpers/order-helpers';
import { getFormattedDateStringForApplication } from '../../../../utility/helpers/date-helpers';
import { formatNumberWithLocale } from '../../../../utility/helpers/formatters';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';

interface Props {
    shipToAddress: string;
    shipToName: string;
    order: ProductionOrder | undefined;
    showCustomerPO: boolean;
}

const useStyles = makeStyles((theme) => ({
    main: {
        marginBottom: '1.5em'
    },
    subtitle: {
        color: ballGray
    },
    item: {
        paddingLeft: '1em'
    },
    createdBy: {
        marginTop: '1em',
        marginBottom: '.5em'
    }
}));

const ProductionOrderDetailsSubheader = (props: Props) => {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    return (
        <Grid container className={classes.main}>
            <Grid container item xs={5} justify="flex-start">
                <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="shipTo">Ship To</Trans>
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography className="classes.shipToText" data-testid="ship-to-name">
                        {props.shipToName}
                    </Typography>
                    <Typography className="classes.shipToText" data-testid="ship-to-address">
                        {props.shipToAddress}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item xs={7} justify="flex-end">
                <Grid item xs={2} className={classes.item}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="status">Status</Trans>
                    </Typography>
                    <Typography variant="body1" data-testid="status">
                        <Trans i18nKey={orderStatusCodeFormatter(props.order?.status)}>
                            {formatStatus(props.order?.status)}
                        </Trans>
                    </Typography>
                </Grid>
                <Grid item xs={2} className={classes.item}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="dateSubmitted">Date Submitted</Trans>
                    </Typography>
                    <Typography variant="body1" data-testid="date-submitted">
                        {getFormattedDateStringForApplication(props.order?.submittedDate)}
                    </Typography>
                </Grid>
                <Grid item xs={2} className={classes.item}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="canQuantity">Can Quantity</Trans>
                    </Typography>
                    <Typography variant="body1" data-testid="can-quantity">
                        {formatNumberWithLocale(cultureCode, props.order?.canQuantityPallets ?? 0) +
                            ' PL / ' +
                            props.order?.canQuantitySKUs +
                            ' SKU'}
                    </Typography>
                    <Typography variant="body1" data-testid="can-quantity-ea">
                        {formatNumberWithLocale(cultureCode, props.order?.canQuantityEaches ?? 0)}{' '}
                        ea.
                    </Typography>
                </Grid>
                <Grid item xs={2} className={classes.item}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="endQuantity">End Quantity</Trans>
                    </Typography>
                    <Typography variant="body1" data-testid="end-quantity">
                        {formatNumberWithLocale(cultureCode, props.order?.endQuantityPallets ?? 0) +
                            ' PL / ' +
                            props.order?.endQuantitySKUs +
                            ' SKU'}
                    </Typography>
                    <Typography variant="body1" data-testid="end-quantity-ea">
                        {formatNumberWithLocale(cultureCode, props.order?.endQuantityEaches ?? 0)}{' '}
                        ea.
                    </Typography>
                </Grid>
                {props.showCustomerPO && (
                    <Grid item xs={2} className={classes.item}>
                        <Typography variant="subtitle2" className={classes.subtitle}>
                            <Trans i18nKey="ballOrderNumber">Ball Order #</Trans>
                        </Typography>
                        <Typography variant="body1" data-testid="production-order-number">
                            {props.order?.productionOrderNumber}
                        </Typography>
                    </Grid>
                )}
            </Grid>
            <Grid container item xs={12} className={classes.createdBy}>
                <Grid item xs={2}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="createdBy">Created By</Trans>
                    </Typography>
                    <Typography variant="body1" data-testid="created-by">
                        {props.order?.createdBy === 'ETL'
                            ? 'Ball'
                            : props.order?.createdBy ?? props.order?.submittedBy}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ProductionOrderDetailsSubheader;
