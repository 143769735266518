import React, { useState, useEffect } from 'react';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { Button, makeStyles, Paper, Grid, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import {
    blackWeight,
    boldWeight,
    ballGray,
    vanilla,
    yellowPill,
    medium,
    containerMaxWidth
} from '../../../themes/globalConstants';
import { Alert } from '@material-ui/lab';
import { DeliveryOrder } from '../../../store/reducers/shipping-dashboard';
import { useHistory } from 'react-router-dom';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { formatFromDatetime } from '../../../utility/helpers/date-helpers';
import moment from 'moment';
import DisableBackButton from '../../reusable/atoms/DisableBackButton';
import { Activity } from '../../../utility/auth/useSecurity';
import EditShipmentsConfirmationGrid from './components/EditShipmentsConfirmationGrid';
import EditShipmentsShareLinks from './components/EditShipmentsShareLinks';
import { EditShipmentsState } from '../../../store/reducers/edit-shipments';
import InfoSubheader from '../../reusable/molecules/InfoSubheader';
import { InfoSubheaderItem } from '../../../store/reducers/glp';
import { formatStatus } from '../../../utility/helpers/order-helpers';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { usePrevious } from '../../../utility/helpers/react-util';
import { selectIsLargeCustomerAccount } from '../../../store/selectors';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: '3em',
        padding: '2em',
        borderRadius: 4,
        maxWidth: containerMaxWidth
    },
    header: {
        marginTop: '1em',
        marginBottom: '2em'
    },
    actionBtn: {
        borderRadius: 'unset'
    },
    title: {
        fontWeight: blackWeight,
        color: ballGray
    },
    warning: {
        padding: '.125em 1.5em .125em 2.125em',
        backgroundColor: vanilla,
        '& .MuiAlert-icon': {
            color: yellowPill
        }
    },
    alertText: {
        textAlign: 'center',
        fontSize: medium,
        fontWeight: boldWeight
    }
}));

const EditShipmentsConfirmation = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [shipToName, setShipToName] = useState('');
    const [displayAddress, setDisplayAddress] = useState('');
    const [deliveryOrder, setDeliveryOrder] = useState<DeliveryOrder>();
    const [subheaderItems, setSubheaderItems] = useState<InfoSubheaderItem[]>();
    const { shipToAccounts, selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { deliveryToEdit } = useTypedSelector<EditShipmentsState>((state) => state.editShipments);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const prevCultureCode = usePrevious(cultureCode);
    const accountInformation =
        shipToAccounts &&
        shipToAccounts.filter((account) => account.accountId === selectedAccountId);
    const dashboardLink = '/dashboard';
    const history = useHistory();
    const isLargeCustomerAccount = useTypedSelector<boolean>(selectIsLargeCustomerAccount);

    DisableBackButton('/edit-shipments-configuration', '/edit-shipments-confirmation');

    const handleDashboardNavigation = () => {
        history.push(dashboardLink);
    };

    const footerActions = (
        <Button
            type="button"
            color="primary"
            variant="contained"
            data-testid="dashboard-btn"
            className={classes.actionBtn}
            onClick={handleDashboardNavigation}
        >
            <Trans i18nKey="myDashboard">My Dashboard</Trans>
        </Button>
    );

    const renderShipments = deliveryOrder?.shipments?.map((shipment, i) => {
        return (
            <EditShipmentsConfirmationGrid
                key={shipment.id}
                shipmentIndex={i}
                currentShipment={shipment}
            />
        );
    });

    const createSubheader = (deliveryOrder: DeliveryOrder) => {
        setSubheaderItems([
            {
                subtitle: t('status', 'Status'),
                body: formatStatus(deliveryOrder.status)
            },
            {
                subtitle: t('revisedDate', 'Revised Date'),
                body: formatFromDatetime(deliveryOrder.deliveryWeekDate as string)
            },
            {
                subtitle: t('quantity', 'Quantity'),
                body: deliveryOrder.totalOrderQuantity + ' PL',
                subtext: `${Number(
                    deliveryOrder.canQuantityEaches || 0 + (deliveryOrder.endQuantityEaches || 0)
                ).toLocaleString()} ea.`
            },
            {
                subtitle: t('products', 'Products'),
                body:
                    deliveryOrder.totalOrderSkus +
                    ' ' +
                    (deliveryOrder.totalOrderSkus === 1 ? t('sku', 'SKU') : t('skus', 'SKUs'))
            }
        ] as InfoSubheaderItem[]);
    };

    useEffect(() => {
        if (deliveryToEdit && deliveryToEdit.deliveryOrder && !deliveryOrder) {
            setDeliveryOrder(deliveryToEdit.deliveryOrder);
        }
    }, [deliveryOrder, deliveryToEdit]);

    useEffect(() => {
        if (deliveryOrder) {
            if (cultureCode !== prevCultureCode || !subheaderItems?.length) {
                createSubheader(deliveryOrder);
            }
            if (!displayAddress && !shipToName) {
                if (accountInformation && accountInformation[0]) {
                    setShipToName(accountInformation[0].name);
                    if (accountInformation[0].address) {
                        const shipToAddress = accountInformation[0].address;
                        setDisplayAddress(
                            shipToAddress.address1?.trim() +
                                ', ' +
                                shipToAddress.city?.trim() +
                                ', ' +
                                shipToAddress.stateProvince?.trim() +
                                ' ' +
                                shipToAddress.postalCode
                        );
                    }
                }
            }
        }
    }, [accountInformation, cultureCode]);

    return (
        <ProcessingPageTemplate
            actionFooter={{
                footerAction: footerActions,
                justify: 'center',
                sticky: true
            }}
            activity={Activity.NewOpenDeliveryOrders}
            banner={{
                header: t('shipIt', 'Ship It'),
                description: t(
                    'editDeliveryOrderSubmitted',
                    'Thank You. Your changes have been received'
                ),
                thinBanner: true,
                displayDropdown: false,
                disableSelect: true
            }}
        >
            <Paper elevation={2} className={classes.paper}>
                <Grid container spacing={2} alignItems="flex-start">
                    <Grid container alignItems="baseline" className={classes.header}>
                        <Grid container item xs={3} justify="flex-start">
                            {deliveryOrder?.deliveryOrderNumber ? (
                                <Typography variant="h3" className={classes.title}>
                                    <Trans i18nKey="deliveryOrder">Delivery Order</Trans>
                                    {' #'}
                                    {deliveryOrder?.deliveryOrderNumber}
                                </Typography>
                            ) : (
                                <Typography variant="h3" className={classes.title}>
                                    <Trans i18nKey="deliveryOrder">Delivery Order</Trans>
                                    {' # '}
                                    <Trans i18nKey="pending">Pending</Trans>
                                </Typography>
                            )}
                        </Grid>
                        <Grid container item xs={6} justify="center">
                            {!isLargeCustomerAccount && (
                                <Alert
                                    severity="warning"
                                    className={classes.warning}
                                    data-testid="carrier-alert"
                                >
                                    <Trans
                                        i18nKey="deliveryConfirmationCarrierAlert"
                                        className={classes.alertText}
                                    >
                                        Carrier will be assigned 7 days prior to scheduled delivery
                                        date.
                                    </Trans>
                                </Alert>
                            )}
                        </Grid>
                        <Grid container item xs={3} justify="flex-end">
                            {deliveryOrder && (
                                <EditShipmentsShareLinks
                                    dateSubmitted={moment
                                        .utc(deliveryOrder.submittedDate)
                                        .local()
                                        .format('MM/DD/YYYY')}
                                    deliveryOrder={deliveryOrder}
                                    shipToAddress={displayAddress}
                                    shipToName={shipToName}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid container>
                        {subheaderItems && (
                            <InfoSubheader
                                shipToName={shipToName}
                                shipToAddress={displayAddress}
                                subheaderItems={subheaderItems}
                            />
                        )}
                    </Grid>
                </Grid>
                {renderShipments}
            </Paper>
        </ProcessingPageTemplate>
    );
};

export default EditShipmentsConfirmation;
