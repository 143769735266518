import { saveCapacityCheck } from "../../store/actions/capacity-checks";
import { saveConfiguredProduct, setConfigError } from "../../store/root-actions";
import { mapConfiguredProduct } from "./kbmax-helpers";

export function addKbMaxListener(config, dispatch): any {
    window.addEventListener('message', (e) => {
        switch (e.data.name) {
            case 'saveConfiguredProduct':
                if (config) {
                    config.saveProductAndSubmit(function (configuredProduct) {
                        if (configuredProduct && configuredProduct.products) {
                            const mappedProduct = mapConfiguredProduct(configuredProduct.products[0]);
                            dispatch(saveConfiguredProduct(mappedProduct));
                        }
                    });
                } else {
                    dispatch(setConfigError('config error'))
                }
                break;
            case 'capacityCheck':
                if (e.data.data && e.data.data.length > 0) {
                    const mappedCapacityCheck = JSON.parse(e.data.data);
                    dispatch(saveCapacityCheck(mappedCapacityCheck));
                }
                break;
            default:
                break
        }
    });
}
