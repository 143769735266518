export const ruRU = {
    // Reusable
    cans: 'Банки',
    view: 'Вид',
    design: 'Дизайн',
    filling: 'Розлив',
    canHandling: 'Обращение с банкой',
    regulations: 'Законодательство',
    labLiquid: 'Тестирование Напитков',
    sustainability: 'Устойчивое Развитие',
    faq: 'Вопросы и ответы',
    designInspiration: 'Вдохновение для Дизайна',
    backToAllProducts: 'Вернуться к Продуктам',
    shipment: 'Shipment',
    shipments: 'Shipments',
    sku: 'SKU',
    skus: 'SKUs',
    pending: 'В процессе ожидания',
    howCanWeHelp: 'Как мы можем Вам помочь?',
    new: 'Новый',
    existing: 'Существовать',
    unknown: 'Неизвестный',
    playground: 'Playground',

    // -- NAVIGATION -- //
    home: 'Домашняя страница',
    homepage: 'Моя Домашняя Страница',
    ourProcess: 'Наш Процесс',
    processOverview: 'Процесс Заказа Банки',
    labDashLiquid: 'Тестирование / Напитков',
    handling: 'Обращение с Банкой',
    regulatory: 'Законодательство',
    ourProducts: 'Наша Продукция',
    productPortfolio: 'Продуктовая Линейка',
    contactUs: 'Связаться с Нами',
    logout: 'Выйти',
    backToPortfolio: 'Вернуться к Продуктовой линейке',
    navigation: 'Навигация',
    dashboard: 'Панель инструментов',
    payables: 'Необходимо оплатить',
    discoverBall: 'Изучи Ball',
    productionOrderSummary: 'Заказ на производство',
    shipmentSummary: 'Заказ на отгрузку',
    paymentsSummary: 'Платежи',
    ourProcessLearningContent: 'Наш процесс и содержание обучающих материалов',
    howToGuides: 'Как управлять в the Source',
    submitGraphicMenu: 'Отправить дизайн',
    buildAndEstimateNav: 'Построить и оценить',
    buildNav: 'Построить',
    ballProductPortfolio: 'Ассортимент продукции Ball',
    admin: 'Admin',
    userAdmin: 'User Admin',
    manageUsers: 'Manage Users',
    manageLocations: 'Manage Locations',
    addNewUser: 'Добавить нового пользователя',
    confirmEmail: 'подтвердить Email',
    myAccount: 'My Account',
    pendingAccessRequests: 'Pending Access Requests',
    myAccess: 'My Access',
    allAccess: 'Полный доступ',
    locationRoleAccess: 'Местоположение и доступ к ролям',
    orderFulfillment: 'Выполнение заказа',
    procurementManagement: 'Управление закупками',
    forecastManagement: 'Управление прогнозированием',
    packagingManagement: 'Управление упаковкой',
    coPacker: 'Копакер-партнер',
    warehousingLogistics: 'Складские услуги/логистика',
    artManagement: 'Художественное руководство',
    marketingManagement: 'Управление маркетингом',
    accountAdmin: 'Администратор учетных записей',
    accountsPayableAccountsReceivable: 'Кредиторская/дебиторская задолженность',
    graphicsVendor: 'Поставщик графики',
    edit: 'Редактировать',
    graphics: 'Графика',
    selectedAccess: 'Выбранный доступ',
    graphicsIntakeForm: 'Форма захвата графики',
    draftGraphicsIntakeForms: 'Черновые формы захвата графики',
    inProgressGraphicsStatuses: 'Статусы графики в процессе выполнения',
    graphicsQuote: 'Предложение графики',
    graphicsProof: 'Графические подтверждения',
    graphicsStandardPilotCan: 'Стандарт графики (Pilot Can)',
    newOpenProductionOrders: 'Новые / открытые заказы на производство',
    draftProductionOrders: 'Проекты заказов на производство',
    closedProductionOrders: 'Закрытые заказы на производство',
    newOpenDeliveryOrders: 'Новые / открытые заказы на доставку',
    closedDeliveryOrders: 'Закрытые заказы на доставку',
    createNewUser: 'Создать новых пользователей',
    planItPlanItSummary: 'Планирование/Планирование Резюме',
    changesWillNotBeSaved: 'Внесенные изменения не будут сохранены',
    areYouSureDiscard: 'Вы уверены, что хотите отменить какие-либо изменения?',
    yesDiscard: 'Да, отменить',
    designSubmitted: 'Design Development Form Submitted',

    // -- WELCOME WIDGET -- //
    welcomeToTheSource: 'Добро пожаловать в <i>The Source</i>',
    welcomeWidgetDesc:
        '<i>The Source</i> будет вашим универсальным ресурсом для отправки дизайна. Со временем на этой странице появятся другие полезные инструменты, такие как «Выполнить» производственные заказы, «Доставка» заказа, Управление Продуктом и другие. Мы ценим Ваши отзывы и терпение.',
    readyToOrder: 'Вы готовы сделать заказ?',
    makeIt: 'Заказать',
    readyToSchedule: 'Вы готовы запланировать отгрузку?',
    shipIt: 'Отгрузить',

    // -- USER ADMIN WIDGET -- //
    userAdministration: 'User Administration',
    setupNewUser:
        'Setup a new user with the correct permissions and locations in <i>The Source</i>.',
    userAdminDashboard: 'User Admin Dashboard',
    viewPendingRequests:
        'View pending access requests, pending new user requests, and active users in <i>The Source</i>.',
    viewUserAdminDashboard: 'View User Admin Dashboard',

    // -- DASHBOARD -- //
    agedSku: 'Устаревшие номера SKU',
    agedSkus: 'Устаревшие номера SKUs',

    // -- FAQ Page -- //
    searchFAQs: 'Поиск часто задаваемые вопросы',
    searchFAQplaceholder: 'Банки, Бутылки, Др!',

    // -- QUICK VIEW -- //
    production: 'Производство',
    limitedProductionRun: 'Короткая Производственная Партия',
    seeFullProductDtls: 'Смотреть Подробнее о Продукте',
    timesPerYear: 'Раз в Год',

    // -- ATM PRODUCT FORECAST -- //
    atmProductForecastBannerTitle: 'Product Information',
    lockWindow: 'Lock Window',
    makeItLockWindow: 'Make It Lock Window',
    firstAvailableWeek: 'First Available Week',
    viewAtmProductForecast: 'View My Product Information',
    customerProductId: 'Customer Product Id',
    productInformationToday: '{{shipToId}} - Product Information as of {{today}}',
    ballProductId: 'Ball Product ID',

    // -- PRODUCT PORTFOLIO -- //
    all: 'Всё',
    bottles: 'Бутылки',
    ends: 'Крышки',
    end: 'Крышка',
    closures: 'Крышки для Бутылок',
    closure: 'Замыкание',
    lookingForInspiration: 'В поисках вдохновения',
    pallets: 'Поддоны',
    itemsPerPallet: 'Штук в Поддоне',
    lookingForSomething: 'В поисках чего-то нового?',
    sendUsYourComments: 'Пришлите нам свои комментарии',
    minOrderPallet: 'Минимальный заказ в Поддонах',
    cansPerPallet: 'Банок на Поддоне',
    quickLook: 'Краткий обзор',
    select: 'Выбрать',
    seeDetailsBtn: 'Смотреть Подробнее о Продукте',
    compareProducts: 'Сравнить Продукцию',
    pleaseSelectProducts: 'Пожалуйста, выберите продукт, чтобы получить цену',
    compare: 'Сравнить',
    myProjects: 'Мои проекты',
    belowAreYourSavedProjects: 'Ниже перечислены ваши проекты, сохраненные за последние 180 дней.',
    projectName: 'Название проекта',
    productCode: 'Код продукта',
    savedOn: 'Дата сохранения',
    viewProject: 'Посмотреть проект',

    // -- FACETS -- //
    filterBy: 'Сортировать По',
    clearAll: 'Удалить Всё',
    // Facet Category
    stylesCategory: 'Линейка Продукции',
    sizesCategory: 'Формат',
    endsCategory: 'Совместимость с крышкой',
    // Facet Tooltips
    stylesTooltip: 'Однотипные Продукты в одинаковых формах и форматах.',
    sizesTooltip: 'Варианты форматов для Вашего специального запроса.',
    featuresTooltip: 'Дополнительные Опции.',
    endsTooltip: 'Список крышек, которые подходят для различных Продуктов Болл.',
    style: 'Стиль',
    size: 'Формат',
    features: 'Характеристики',
    endTile: 'Крышки/Крышки для Бутылок',

    // -- PRODUCT DETAILS -- //
    requestEstBtn: 'Запросить цену',
    productDetails: 'Детали Продукта',
    printGuide: 'Руководство по Печати',
    reproStudioGrid: 'Размерные Сетки',
    customerDesignGuide: 'Руководство по дизайну',
    download: 'Загрузить',
    canSpecification: 'Спецификация Банки',
    palletSpecifications: 'Спецификация Поддона',
    greatFor: 'Подходит Для',
    specifications: 'Спецификации',
    currentLeadTime: 'Сроки выполнения',
    fillingAvailability: 'Доступность Розлива',
    compatibleEnds: 'Совместимые крышки',
    weeks: 'Недели',
    months: 'Месяцы',
    minimum: 'Минимум',
    productEnhancements: 'Product Enhancements', // Priya needs context
    compatibleFilling: 'Соответствующий Розлив',
    pasteurization: 'Пастеризация',
    retort: 'Автоклав',
    hotFill: 'Горячий Розлив',
    coldFill: 'Холодный Розлив',
    color: 'Цвет',
    aluminum: 'Алюминий',
    printingOptions: 'Варианты Печати: Банка с дизайном, Банки в Sleeve, Яркая Банк',
    // Ordering
    ordering: 'Заказ',
    pricePerThousand: 'Цена за 1,000',
    quantityPerPallet: 'Количество в Поддоне',
    minOrder: 'Минимальный Заказ',
    // Design Specs
    designSpecs: 'Спецификации Дизайна',
    circumference: 'Окружность',
    designLength: 'Длина Дизайна (длина окружности)',
    designHeight: 'Высота Дизайна',
    maxTALength: 'Максимальная площадь текста (Длина)',
    maxTAHeight: 'Максимальная площадь текста (Высота)',
    maximumColors: 'Максимальное количество цветов',
    underOverlap: 'Зона перекрытия',
    // Can Specs
    canSpecs: 'Спецификации Банок',
    endSize: 'Размер Крышек',
    bodyOutsideDiameter: 'Внешний Диаметр Банки',
    flangedCanHeight: 'Высота банки с фланцами',
    coldFillVolume: 'Объем заполнения при холодном розливе',
    retortFillVolume: 'Объем заполнения про автоклавировании',
    flangedCanWidth: 'Ширина Фланца',
    endDiameter: 'Диаметр Крышки',
    // Can Finishes and Effects
    canEffectsAndFeatures: 'Эффекты и свойства, доступные для этой банки',
    canFinishesAndEffects: 'Крышки для банок и Эффекты',
    inks: 'Чернила',
    overvarnish: 'Внешний Лак',
    effects: 'Эффекты',
    specialFeatures: 'Специальные Свойства',

    // -- END DETAILS -- //
    endDetails: 'Характеристики Крышек',
    endFinishesAndEffects: 'Крышки для банок и Эффекты',
    compatibleCans: 'Совместимые с Банками',
    endFeaturesAndEffects: 'Свойства Крышек и Эффекты',
    orderIndividually: 'Может заказывается отдельно',
    roppClosures: 'ROPP Крышки покупаются отдельно у сторонней компании',
    endsPack: 'Блок информации о Крышках',

    // -- PRODUCT COMPARISON -- //
    addProduct: 'Добавить Продукт',
    details: 'Детали',
    name: 'Имя',
    type: 'Тип',
    maxColors: 'Максимальное количество цветов',
    innovativeFeatures: 'Инновационные Свойства',
    leadTimes: 'Время выполнения заказа',
    currentFillingAvail: 'Актуальная доступность розлива',
    products: 'Продукция',
    addAnotherToCompare: 'Добавить Другой Продукт для Сравнения',
    removeSelection: 'Удалить Выбор',
    addToCompare: 'Добавить для сравнения',
    prodComparisonDesc:
        'Сравнивайте до трех разных продуктов одновременно, независимо от объема или стиля. Посмотрите, какие продукты предлагают',
    prodEstimateDesc:
        'Наш калькулятор позволит Вам лучше понять стоимость заказа Банок. При оценке стоимости помните о следующем',
    prodHasMinOrder: 'У каждого Продукта есть минимальный возможный заказ',
    orderInFullPallets: 'Оценка стоимости и заказ могут быть размещены только для полных поддонов',
    orderInFullTrucks:
        'Мы рекомендуем заказывать полные грузовики, большинство грузовиков вмещает около 20/26 поддонов. Загрузка менее полного грузовика потребует дополнительной оплаты.',
    productComparison: 'Сравнение продуктов',
    compareBuildEstimate: 'Сравнить, построить и оценить',
    compareBuild: 'Сравнить и построить',
    myProject: 'Мой проект',

    // -- CONTACT FORM -- //
    yourName: 'Ваше Имя',
    email: 'Email',
    yourCountry: 'Ваша страна, где будет осуществляться розлив',
    countryRequired: 'Country is required', // Need
    messageRequired: 'Message is required', // Need
    subjectLine: 'Описание',
    contactMessage: 'Сообщите, как мы можем помочь...',
    submit: 'Отправить',
    cancel: 'Удалить',
    sendUsANote: 'Напишите нам, какие новые продукты вы хотели бы видеть в будущем',
    msgSuccessful: 'Сообщение успешно отправлено.',
    msgFailed: 'Не удалось отправить сообщение. Повторите попытку еще раз.',
    sending: 'Отправка',
    close: 'Закрыть',
    contactReason: 'Причина Обращения',
    contact: 'У меня вопрос',
    support: 'Мне нужна помощь',

    // --- PRODUCT ESTIMATE -- //
    getAnEstimate: 'Получить стоимость Продукта',
    desiredAnnualQuantity: 'Желаемая годовая потребность',
    enterQuantity: 'Ввести количество',
    // wouldYouLikeEndsWithThisProduct: 'Вы хотели бы крышки вместе с банками?',
    remove: 'Удалить',
    enterValueGreaterThan: 'Пожалуйста, введите значение, выше 0',
    estimateFailedToSend: 'Не удалось отправить запрос на стоимость, попробуйте еще раз позже.',
    productEstInquiry: 'Запрос стоимости Продукта',
    submittingEstimate: 'Отправить информацию для оценки стоимости ниже',

    // -- CONFIRMATION POP UP -- //
    backToHome: 'Вернуться на домашнюю страницу',
    thankYou: 'Спасибо Вам',
    yourRequestHasBeenSubmitted: 'Ваш запрос был отправлен!',
    someoneFromOurTeamWillBeInTouchSoon: 'Наш менеджер скоро свяжется с Вами.',

    // -- REGISTRATION -- //
    registrationHeader: 'Поехали',
    registrationDescription:
        'Мы почти у цели, нам просто нужно немного информации о Вас, чтобы перейти на Портал клиентов Ball, где Вы сможете больше узнать о банках, о том, как сделать заказ и узнать стоимость.',
    region: 'Регион',
    language: 'Язык',
    aboutYourCompany: 'О Вашей компании',
    companyName: 'Название компании',
    country: 'Страна',
    stateProvince: 'Округ/область',
    city: 'Город',
    postalCode: 'Индекс',
    yearsInBusiness: 'Количество лет в бизнесе',
    numberOfEmployees: 'Количество сотрудников',
    estimatedAnnualRevenue: 'Примерный годовой доход',
    beverageInRetail: 'Ваши напитки уже продаются в розничных магазинах?',
    currentPackageType: 'Тип упаковки в настоящее время',
    beveragePackagingNeeds: 'Ваши потребности в упаковке',
    beverageCategory: 'Категория Напитков',
    productSize: 'Формат (размер) упаковки',
    expectedAnnualQuantity: 'Ожидаемое количество в год',
    canningLine: 'У Вас есть или Вы планируете приобретать баночную линию?',
    copackerName: 'Название Ко-пакера',
    fillingCountry: 'Страна розлива',
    fillingCity: 'Город розлива',
    additionalComments: 'Дополнительная информация (необязательно)',
    artworkComments: 'Комментарии к иллюстрациям (необязательно)',
    setupCustomerPortalAccount: 'Настройте свою учетную запись на портале клиентов',
    firstName: 'Имя',
    lastName: 'Фамилия',
    title: 'Title',
    jobTitle: 'Job Title',
    phoneNumber: 'Номер телефона',
    password: 'Пароль',
    confirmPassword: 'Подтвердить пароль',
    alreadyRegistered: 'Уже зарегистрированы?',
    accountCreated: 'This email account is already tied to The Source.',
    signIn: 'Войдите на Портал для Клиентов',
    signInConfirmation: 'Please Sign In to The Source and if needed, reset your password.',
    yes: 'да',
    no: 'нет',
    incorrectEmailAddress: 'Неверный формат электронной почты',
    atLeastEightLetters: 'Минимум 8 букв',
    atLeastOneCapitalLetter: 'Минимум одна заглавная буква',
    atLeastOneLowerCaseLetter: 'Минимум одна строчная буква',
    atLeastOneNumber: 'Минимум одно число',
    mustNotBeACommonPassword: 'Не должен быть распространенный пароль',
    passwordsShouldMatch: 'Пароли должны совпадать',
    year: 'год',
    printType: 'Хотите ли вы какой-либо из следующих типов банок?',
    iAgree: 'Я согласен с',
    termsOfUse: 'Условия использования и Политика Конфиденциальности',
    million: 'Миллион',
    newToBeverage: 'Новое в Напитках',
    selectSecurityQuestion: 'Вопрос',
    securityAnswer: 'Ответ',
    invalidSecurityAnswer: 'Ответ должен быть больше 5 символов',
    emailValidationError: 'Электронная почта не соответствует требуемому шаблону',
    passwordValidationError: 'Пароль не должен быть обычным паролем',
    securityAnswerValidationError:
        'Ответ на секретный вопрос слишком слабый, ответ не должен быть частью вопроса.',
    unknownValidationError:
        'При создании новой учетной записи произошла ошибка. Обратитесь в службу поддержки.',
    selectSecurityQuestionPrompt:
        'Выберите вопрос безопасности и ответьте на них, чтобы вы могли автоматически сбросить пароль, если у вас возникли проблемы с регистрацией в вашей учетной записи.',
    emailOptOut1:
        'By submitting this form, you are subscribing to receive the latest news and updates straight to your inbox.',
    emailOptOut2:
        'Ball Corporation may also contact you from time to time about our products and services as detailed in our',
    privacyPolicy: 'Privacy Policy',
    emailOptOut3: 'Please check this box if you’d rather not receive these emails.',

    // Registration Print Types
    brightCans: 'Яркие Банки',
    sleevedCans: 'Банки со Sleeve',
    printedCans: 'Банки с Дизайнами',

    // Registration Beverage Categories
    beverageCategoryBeer: 'Пиво',
    beverageCategoryBeerCraft: 'Пиво - Крафтовое',
    beverageCategoryBeerDomestic: 'Пиво - Национальные сорта',
    beverageCategoryBeerImport: 'Пиво - Импортное',
    beverageCategoryCsd: 'Газированные безалколгольные напитки (CSD)',
    beverageCategoryCider: 'Сидр',
    beverageCategoryCoffee: 'Кофе',
    beverageCategoryIcedCoffee: 'Холодный Кофе',
    beverageCategoryEnergyDrink: 'Энергетический напиток',
    beverageCategoryFab: 'Слабо алкогольные напитки (FAB)',
    beverageCategoryFmb: 'Ароматизированный солодовый напиток (FMB)',
    beverageCategoryHardLiquor: 'Крепкий ликёр',
    beverageCategoryThc: 'Конопля/CBD/THC',
    beverageCategoryJuice: 'Сок',
    beverageCategoryProtein: 'Протеин/на основе Молока',
    beverageCategoryRtd: 'Готовый к употреблению',
    beverageCategorySpirits: 'На основе Спирта',
    beverageCategorySportsDrink: 'Спортивные Напитки',
    beverageCategoryTea: 'Чай',
    beverageCategoryIcedTea: 'Холодный Чай',
    beverageCategoryWater: 'Вода',
    beverageCategoryWine: 'Вино',
    beverageCategoryHardSeltzer: 'Hard Seltzer',
    beverageCategoryOther: 'Другие',

    // Validation
    required: 'Требуется',

    // Registration Security Questions
    securityQuestionFood: 'Ваше самое нелюбимое в детстве блюдо?',
    securityQuestionStuffAnimal: 'Кличка Вашего первого домашнего питомца?',
    securityQuestionAward: 'За что Вы получили свою первую медаль или награду?',
    securityQuestionComputerGame: 'Ваша первая компьютерная игра?',
    securityQuestionMovieQuote: 'Ваша любимая киноцитата?',
    securityQuestionSportsTeam: 'Талисман первой спортивной команды, в которой Вы играли?',
    securityQuestionDessert: 'Любимый десерт Вашей бабушки?',
    securityQuestionCook: 'Какое блюдо Вы научились готовить первым?',
    securityQuestionDreamJob: 'О какой профессии Вы мечтали в детстве?',
    securityQuestionVacation: 'Где Вы провели свой лучший отпуск?',

    // -- ORDER SHIPPING -- //
    makeItLink: 'сделай это',
    shipItLink: 'отправить',
    notSeeingProduct: 'Не вижу Продукт?',
    checkStatusInProcess: 'Проверить статус обработки Дизайна.',
    share: 'поделиться:',
    print: 'Печать',
    shipTo: 'Адрес доставки',

    // BULK SHIP IT
    uploadShipItOrders: 'Загрузить заказы Ship It',
    mapShipItTemplateValues: 'Сопоставление значений шаблонов Ship It',
    updateShipItTemplateValues: 'Обновление значений шаблонов Ship It',

    // Graphic Intake - Vendor
    formOverview: 'Обзор формы',
    graphicsSubmissionInfo: 'Основная информация',
    productOptions: 'Варианты Продукции',
    colorMatchingOptional: 'Подбор цветов (необязятельно)',
    uploadGraphicsAndSubmit: 'Загрузить дизайн и отправить',
    submitGraphics: 'Отправить Ваш дизайн',
    graphicUploadTimeout:
        'Время для загрузки дизайна вышло. Пожалуйста, попробуйте загрузить позднее.',
    fillOutRequiredFields: 'Все поля должны быть заполнены перед отправкой.',
    uploadFileError: 'Пожалуйста, загрузите соответствующий zip file перед отгравкой',
    intakeFailedToSave: 'Ошибка загрузки Формы заказа на обработку дизайна',
    saveProgressEmea: 'Сохранить прогресс',
    graphicDraftSavedEmea: 'Форма захвата графики сохранена',
    promotionStartDatePrior:
        'Please ensure your promotion start date is prior to your promotion end date',
    promotionStartDateAfter: 'Please ensure your promotion date to be after your requested date',
    unknownError: 'an unknown error occurred.  Please try again later.',
    requestedByDate: 'Requested Date',
    requestedDateHelper: 'What is your requested date for your cans?',
    inRetailDate: 'In-Retail Date',
    shortTermStartDateHelper: 'Date when finished goods will be in retail',
    validInRetailDateError: 'Please make sure In-Retail date is after Requested Date',
    outOfRetailDate: 'Out-of-Retail Date',
    shortTermEndDate: 'Date when finished goods are estimated to be out of retail',
    validOutOfRetailError: 'Please make sure Out-Of-Retail date is after In-Retail date',
    iDontKnow: `I don't know`,
    typeOfGraphic: 'What type of graphic do you want to submit?',
    copyWarning:
        'You are about to submit a new graphic, leveraging information provided for a previous graphic. Please carefully review and update all fields to ensure accuracy for the new graphic.',
    revisionWarning:
        'You are about to submit a revision for an active graphic. Please carefully review and update all fields to ensure accuracy for the new graphic.',
    revisionToAnExistingGraphic: 'Is this a revision to an existing graphic?',
    existingGraphicName: 'Existing Graphic Name',
    revisionGraphicNameHelper: 'This is the name of the graphic you are revising',
    enterGraphicNumberOrName: 'Please enter a graphic number or name',
    existingGraphicNumber: 'Existing Graphic Number',
    revisionGraphicNumberHelper: 'This is the graphic number you are revising',
    newGraphicSubmissionName: 'New Graphic Submission Name',
    submissionNameHelper: 'Name this submission for future reference ',
    alphanumericPattern: 'Please type a name using alphanumerics',
    conversion: 'Is this a Hard or Soft conversion?',
    conversionTooltip:
        'A hard conversion will obsolete and completely replace the selected version of this graphic. A soft conversion will allow you to maintain the selected version and new version of this graphic.',
    shortTerm: 'Is this a promotion?',
    //graphicsContact: '',
    //customerSKUNo: '',
    //colorStandardsApprover: '',
    //colorMatchNote: '',
    //colorMatchNoteText: '',
    //canFinishes: '',
    //colorMatching: '',
    //dateCodeMatching: '',
    //colorMatch: '',
    graphicsContactInformation: 'Graphics Contact Information',
    customerNameHelper: 'Company Submitting on Behalf of',
    yourCompany: 'Ваша Компания',
    nameHelper: 'Имя сотрудника, отправляющего эту форму',
    phoneNumberHelper: 'Телефон сотрудника, отправляющего форму',
    emailAddress: 'E-Mail',
    submitterEmailHelper: 'Email сотрудника, отправляющего форму',
    colorStandards: 'Подтверждение цветовых стандартов',
    approverName: `Имя сотрудника, ответственного за утверждение`,
    approverNameHelper: 'Имя сотрудника, утверждающего стандраты',
    approverEmail: `Email сотрудника, ответственного за утверждение стандартов`,
    approverEmailHelper: 'Email сотрудника, утверждающего стандарты',
    emeaApproverEmailHelper:
        'Укажите email адрес лица (лиц), ответственного за утверждение. Ball проследит, чтобы указанные лица получили от нас дизайн на утверждение по почте',
    approverPhoneNumberHelper:
        'Укажите номер телефона лица (лиц), ответственного за утверждение. Это обеспечит быструю коммуникацию в процессе разработки дизайна',
    invalidPhoneNumber: 'Invalid Phone Number',
    address: 'Адрес',
    addressHelper: 'Индекс, страна, город, улица, дом ',
    hundredMaxLengthError: 'Убедитесь, что Вы ввели не более 100 символов',
    alphaNumericPatternError: 'Убедитесь, что поле буквенно-цифровое',
    numericPatternError: 'Пожалуйста, введите числовое значение',
    glnNumberError: 'Пожалуйста, введите 13-значное числовое значение',
    thirtyMaxLengthError: 'Пожалуйста, убедитесь, что Вы ввели не более 30 символов',
    fiftyMaxLengthError: 'Пожалуйста убедитесь, что Вы ввели не более 50 символов',
    customerGraphicsID: 'Номер заказчика',
    customerGraphicsIDHelper: 'Номер заказчика (Необязательно)',
    customerSKU: 'SKU номер Заказчика',
    customerSKUHelper: 'SKU номер заказчика (Необязательно)',
    upc: 'UPC # / EAN',
    upcHelper: 'Укажите полный номер UPC или код EAN, включая первые цифры (необязательно)',
    productCodeHelper: 'Формат продукта, на котором будет отображаться изображение',
    neckSize: 'Размер горла',
    neckSizeTooltip: 'Размер горла должен быть 206, если Болл ранее не сообщал об 209',
    lookingForDesignInspiration: 'Вдохновение для дизайна?',
    specialties: 'Ваш продукт требует специальные лаки или краски?',
    ink: 'Краски',
    inkHelper: 'Варианты красок, доступные для Продукта',
    effect: 'Эффекты',
    effectHelper: 'Варианты специальных эффетов, доступных для Продукта',
    overvarnishHelper: 'Варианты лаков, доступные для Продукта',
    doYouNeedColorMatching:
        'Вам необходим подбор цвета по существующей алюминиевой банке или по другому материалу?',
    colorMatchComment: 'Пожалуйста, опишите, какой цвет Вы хотите подобрать по Вашему материалу',
    comments: 'Комментарии',
    colorMatchingText:
        'Подбор цвета по существующим алюминиевым банкам (необязательно, чтобы они были изготовлены в Ball) или по другим печатным материалам (картонная упаковка, банка / этикетка для бутылок и т. д.). После заполнения заказа на обработку дизайна, Вам будет предоставлен документ, который нужно положить в посылку вместе с образцом соответствия цвета, и адрес, по которому Вы должны отправить образец по почте.',
    colorStandardText:
        'Ball предоставляет стандарт цвета для каждой нового дизайна, а также для дизайнов с изменением цвета',
    dateCode: 'Соответствие дата коду',
    dateCodeText:
        '9-значный буквенно-цифровой код, расположенный рядом со штрих-кодом UPC. Точное расположение зависит от размера банки и производителя.',
    dateCodeMinMaxError: 'Пожалуйста, убедитесь, что дата код состоит из 9 символов',
    invalidIntakeFile: 'Тип файла недействительный. Пожалуйста прикрепите .zip file.',
    onUploadFileError: '*Пожалуйста, загрузите правильный zip файл',
    uploadSection1: 'Все банки Ball будут производиться с глянцевым лаком, если не указано иное',
    uploadSection2:
        'Наличие специальных красок или лаков может быть ограничена; указание эффектов этой форме не гарантирует их наличие.',
    dragAndDropZipFileHere: 'Перетащите zip файл сюда или кликните на него',
    dragAndDropCsvFileHere: 'Перетащите csv файл сюда или кликните на него',
    dragAndDropFileHere: 'Перетащите файл сюда или кликните на него',
    dropHere: 'Перетащить файл',
    fileUploadedSuccessfully: 'Файл загружен успешно:',
    acceptedFileTypes:
        'Принятый тип файла: Zip-файл, который может включать файл TIFF и / или полный файл Illustrator с изображениями и шрифтами, предоставленными или преобразованными в контуры.',
    weRecommendPDF: 'Мы рекомендуем прикрепить PDF только для чтения в Ваш zip файл для просмотра.',
    acceptedFileTypesEmea: 'Accepted file types',
    weRecommendPDFEmea:
        'Note: .Zip files can include a TIFF and/or collected Illustrator file with images and fonts supplied or converted to outlines. We recommend including a read-only PDF in your collected zip file for viewing purposes only.',
    onUploadFileErrorEmea:
        '*One of the files you are trying to upload is not of an approved file type. Please remove and upload an approved file type (.pdf, .ai, .psd, .doc, .docx, .xls, .xlsx, .zip).',

    saveProgressAttachments: 'Приложенные документы не сохраняются при выборе СОХРАНИТЬ ПРОГРЕСС.',
    clearYourForm: 'Очистить Вашу форму',
    clearFormConfirmation: 'Вы уверены, что хотите очистить форму?',
    formInfoWillBeLost: 'Любая информация будет удалена.',
    yesClearForm: 'Да, очистить форму',
    excitedThankYou: 'Спасибо!',
    graphicsSubmitted: 'Ваш Дизайн принят на рассмотрение!',
    submissionText:
        'Наша команда рассмотрит Вашу заявку на соответствие требованиям и сообщит. Графический координатор свяжется с Вами в течение 2 рабочих дней.',
    subText: 'Your form is also available to view. Pleave save this for your records.',
    intakeForm: 'Intake Form:',
    pleasePrintColorMatchingForm: 'To print or download a copy of your color matching form, please',
    clickHere: 'click here.',
    startNewForm: 'Start New Form',
    backToDashboardEmea: 'Вернуться к Панели управления',
    graphicsColorSubmitted: 'Thank you for submitting your update!',
    submissionColorText:
        'Our team will review this update and a graphics specialist will be in touch with you shortly. If you would like to review your submission or reprint a color matching form, please',
    viewColorMatchingInfo: 'Посмотреть информацию о подборе цветов',
    graphicsInformationMessageIE:
        'Вы можете открыть общую информацию о дизайне или открыть в браузере.',
    colorMatchingInformationMessageIE:
        'Вы можете загрузить информацию о подборе цвета или открыть в браузере.',
    submissionType: 'Тип согласования',
    billTo: 'Выставить счет',
    approverAddress: `Адрес сотрудника, ответственного за утверждение`,
    approverCountry: `Страна сотрудника, ответственного за утверждение`,
    graphicsSummary: 'Обзор графики',
    shippingAddress: 'Адрес доставки',
    yourColorMatchingInfo: 'Информация о подборе цветов',
    pleasePrintThisCM: 'Пожалуйста, распечатайте и отправьте с Вашей формой подбора цветов.',
    submissionName: 'Имя сотрудника, отправившего дизайн в работу',
    graphicName: 'Graphic Name',
    colorMatchingDetails: 'Детали для подбора цветов',
    deliveryInstructionsMaxLength: 'Максимум 30 знаков',
    documentName: 'НАЗВАНИЕ ДОКУМЕНТА',
    documentNotUploaded: 'Нет загруженного документа',
    hard: 'Жесткий',
    soft: 'Мягкий',
    addAnotherApprover: 'Add Another Approver',

    // -- Unauthorized -- //
    unauthorized: 'Не авторизован',
    youAreNotAuthorized: 'Вы не авторизованы, чтобы посмотреть эту страницу',
    unknownActivity: 'this area',
    dontHaveAccess: "Looks like you don't have access to {{activityString}} for",
    deactivateReactivateUsers: 'Deactivate / Reactivate Users',
    makeItBulkATM: 'Make It Bulk ATM',
    makeItBulkUpload: 'Make It Bulk Upload',
    shipItBulkUpload: 'Ship It Bulk Upload',
    openAccess: 'Open Access',
    onboardingWizard: 'Onboarding Wizard',
    billingAndShipping: 'Billing And Shipping',

    // -- Summary Pages -- //
    customerPoNumber: 'Номер заказа (PO) покупателя#',
    status: 'Статус',
    createdBy: 'Создан',
    dateSubmitted: 'Дата Принятия',
    productGraphicId: 'Номер материала / Номер дизайна',
    graphicId: 'Номер дизайна',
    descriptionCharacteristics: 'Описание продукта / Характеристики',
    quantityOrdered: 'Количество заказа',
    requestedDate: 'Дата запроса',
    open: 'Открыть',
    closed: 'Закрытые',
    availableToShip: 'В наличии',
    cancelled: 'Удалить',
    completed: 'Завершить',
    productionOrderDetails: 'Детали производственного заказа',
    draftGraphicsFormsEmea: 'Черновые формы графики',
    draftGraphics: 'Черновые формы графики',

    // -- Date Pickers -- //
    legend: 'История',
    day: 'День',
    restrictedDate: 'Ограничение по <br/> дате доступности',
    dateFirstAvailable: 'Ближайшая доступная дата',
    availableReleaseDate: 'Дата выпуска',
    ok: 'Ok',

    // -- PRODUCT PLANNING -- //
    units: 'Units',
    expandAll: 'расширить все',
    collapseAll: 'Свернуть',
    thisWeek: 'This Week',
    weeks0to8: 'Weeks 0-8',
    weeks9to16: 'Weeks 9-16',
    productionBalance: 'Production Balance',
    weekOfWithDate: 'Week of {{date}}',
    productionOrders: 'Production Order(s)',
    ordersPendingPayment: 'Orders Pending Payment',
    originalRequestedDate: 'Original Requested Date',
    revisedAvailableDate: 'Revised Available Date',
    late: 'Late',
    deliveryDates: 'Delivery Date(s)',
    productPlanning: 'Product Planning',
    planning: 'Планирование',
    emptyString: '',
    graphicsID: 'Graphics ID',

    // -- More EMEA Graphics Intake -- //
    designInfo: 'Информация о дизайне',
    colourInfo: 'Информация о цвете',
    marketInfo: 'Информация о рынке',
    productQuality: 'Спецификация качества продукции',
    approverInfo: 'Для одобрения',
    // General Info
    graphicsSubInfo: 'Основная информация',
    companyId: 'Заказчик',
    customerAccountHelper: 'Название Вашей компании',
    contractFiller: 'Линия розлива',
    contractFillerHelper:
        'Завод, где осуществляется розлив. Заполните, если используются услуги розлива на стороне.',
    brandOwner: 'Владелец бренда',
    brandOwnerHelper: 'Владелец бренда. Заполните, если бренд принадлежит не Вашей компании',
    canSize: 'Размер жестяной банки',
    canSizeHelper: 'Выберите размер банки из списка',
    canType: 'Формат жестяной банки',
    canTypeHelper: 'Выберите тип банки и диаметр горла банки из списка',
    metalType: 'Металл',
    metalTypeHelper: 'Выберите тип металла - "алюминий" из списка. ',
    // Design Info
    designSubInfo: 'Информация о дизайне',
    graphicType: 'Тип запроса',
    requestTypeHelper:
        'Выберите "Новый дизайн", если данный дизайн не должен заменить один из существующих номеров дизайна в системе Ball. Выберите "Измененный дизайн" если данный дизайн должен заменить один из существующих номеров дизайна в системе Ball и является его продолжением.',
    requestTypeNew: 'новый дизайн',
    requestTypeExisting: 'изменение',
    requestTypePromo: 'Промо-дизайн - на основе (но не заменить) существующий дизайн',
    designPoNo: 'Номер заказа на покупку',
    designPoNoHelper:
        'Номер заказа, который Ball должен использовать, чтобы выставить счет за разработку дизайна согласно контракта.',
    designName: 'Название дизайна (новый)',
    designNameHelper:
        'Название дизайна, которое будет задано в системе и будет указываться до конца процесса (от разработки дизайна до розлива в банку)',
    previousSubmissions: 'Название дизайна (предыдущий)',
    previousBallDesignNum: 'Название дизайна (предыдущий)',
    existingHelper:
        'Если это версия дизайна, введите предыдущий номер дизайна Ball (этот номер вы можете видеть на PDF файле от Ball, который вы получали как часть процесса согласования дизайна). Это поможет нам идентифицировать дизайн, который должен быть заменен на новую версию',
    newCustRefNum: 'Ссылочный номер клиента (новый)',
    newCustRefNumHelper:
        'Введите Номер артикула, который вы используете, чтобы идентифицировать данный дизайн в Вашей организации',
    oldCustRefNum: 'Ссылочный номер клиента (предыдущий)',
    oldCustRefNumHelper:
        'Если это версия дизайна, то введите в это поле номер артикула Вашего предыдущего дизайна, известного Ball (это поможет идентифицировать верный дизайн, подлежащий изменению)',
    barcode: 'Штрих-код',
    barcodeHelper:
        'Введите 8 или 13 цифр EAN кода или 12 цифр UPC кода. Мы используем это, чтобы дополнительно проверить код в дизайне, чтобы избежать ошибок',
    barcodeBarsColor: 'Цвет штрих-кода - линии',
    barcodeBarsColorHelper:
        'Укажите цвет штрихов штрих-кода. Ball использует эту информацию для сравнения с дизайном',
    barcodeInfillColor: 'Цвет штрих-кода - фон',
    barcodeInfillColorHelper:
        'Укажите цвет фона штрих-кода. Ball использует эту информацию для сравнения с дизайном',
    specialFinish: 'Ball специальные покрития',
    specialFinishHelper:
        'Выберите какую технологию, Вы бы хотели использовать для данного дизайна. По умолчанию в этом поле стоит "Без специальных эффектов". Пожалуйста, обратите внимание: применяется наценка. Свяжитесь с Вашим менеджером Отдела продаж для уточнения актуальных цен на "Специальные эффекты"',
    requestType: 'Тип запроса',
    // Color Info
    colourSubInfo: 'Информация о цвете',
    overVarnish: 'Лак',
    overVarnishHelper:
        'Выберите лак, который необходимо нанести на этот дизайн. Стандартный лак - глянцевый. Пожалуйста, обратите внимание: на нестандартные варианты из этого списка применяется наценка. Свяжитесь с Вашим менеджером Отдела продаж для уточнения актуальных цен на нестандартный лак.',
    basecoatColour: 'Цвет базового покрытия (сталь)',
    basecoatColourHelper:
        'Базовое покрытие доступно только для стальных банок. По умолчанию в этом поле стоит "Без базового покрытия", так как оно не доступно для алюминиевых банок',
    specialInk: 'Специальные чернила Ball',
    specialInkHelper:
        'Выберите специальные краски, которые необходимо использовать на данный дизайн. По умолчанию в этом поле стоит "Без специальных красок". Пожалуйста, обратите внимание: применяется наценка. Свяжитесь с Вашим менеджером Отдела продаж для уточнения актуальных цен на специальные краски',
    colourTarget: 'Образец цвета',
    colourTargetHelper:
        'Обозначьте в этом поле, есть ли у вас цветовой образец, который Вы хотели бы использовать для подбора красок. Если необходимо, чтобы Ball подобрал краски по образцу банки, которая ранее была произведена одним из наших заводов или нашим конкурентом, важно сообщить нам об этом заранее для верного результата.',
    // Market Info
    marketSubInfo: 'Информация о рынке',
    distributeCountries: 'Страны на рынке произведений искусства',
    distributeCountriesHelperText:
        'Пожалуйста, выберите, в какой из следующих стран вы планируете продавать свои дизайны (выберите все подходящие варианты).',
    depositScheme: 'Депозитная схема',
    depositSchemeHelper:
        'Обозначьте, если Ваш дизайн будет продаваться на рынке страны, которая обязана указывать депозитную схему (выберите из выпадающего меню)',
    recycleMark: '«Металл повторно используется навсегда» символ на дизайне.',
    recycleMarkHelper:
        'Выберите "Да" если Вы хотели бы, чтобы Ball добавили специальный знак "Металл перерабатывается бесконечно" на Вашу банку. Используя этот знак, Вы сообщаете о концепции устойчивого развития для Вашего потребителя',
    glnNumber: 'GLN Номерклиента',
    glnNumberHelper:
        'Введите Ваш 13-значный код GLN в это поле. Если Ваш дизайн будет продаваться на немецком рынке, то Ball обязаны зарегистрировать ваш номер GLN в наших системах. Номер GLN предоставляется Немецкой Организацией Pfand после регистрации в их системе. Ball должны верифицировать эту регистрацию перед производством такого дизайна. (внесите "0" в это поле если ваш дизайн не предназначен для немецкого рынка)',
    asiCertified: 'Сертифицирован ли этот дизайн согласно ASI?',

    // Product Quality Info
    productQualitySubInfo: 'Спецификация качества продукции',
    productContent: 'Содержание продукта',
    productContentHelper:
        'Выберите из списка наиболее подходящую категорию для описания содержания вашего продукта. Эта информация будет использована для исследования рынка.',
    productFormulation: 'Формулировка продукта',
    productFormulationHelper:
        'Выберите из списка, содержит ли Ваш напиток в банке "новый" или "существующий" рецепт продукта. Мы используем эту информацию, чтобы установить нужно ли нам тестировать Вашу продукцию в банках перед производством в нашей лаборатории для контроля качества',
    refDesignNum: 'Если формулировка уже существует, укажите код дизайна',
    refDesignNumHelper:
        'Если Вы выбрали "Существующий рецепт" в поле выше, пожалуйста, обозначьте в этом поле Ваш номер артикула или наш номер дизайна. Это поможет нам идентифицировать, какие проведенные тесты относятся к данному дизайну',
    productPasteurisation: 'Пастеризация продукта',
    productPasteurisationHelper:
        'Выберите из списка, приблизительно, при какой температуре Ваш продукт пастеризуется. Это позволит Ball подготовить банку для вашего процесса',
    bpaFree: 'BPANI',
    bpaFreeHelper:
        'Выберите "Да" из списка, если вы собираетесь продавать данный дизайн в стране, для которой необходимо использовать Лак BPA-free. Несоблюдение этого закона влечет за собой существенные юридические штрафы для владельца бренда.',
    bpaniId: 'BPANI идентификация "N"',
    bpaniIdHelper:
        'Если Вы выбрали "Да" для "BPANI", подтвердите, если вы хотите указать это в вашем дизайне. Ball добавит "N" в дата-код для идентификации. Такой способ идентификации был согласован в индустрии производства банок',
    // Approval Info (For Approval)
    approvalSubInfo: 'Для одобрения',
    forApprovalSubTitle:
        'Укажите имя лица (лиц), ответственного за утверждение материалов по дизайну. Ball проконтролирует, чтобы указанные лица получили документы на утверждение',
    proofSession: 'запрос на тест банок (proofcan)',
    proofSessionHelper:
        'Возможно заказать пробные образцы банок для Вашего дизайна. Выберите "Да", если хотите использовать эту опцию. Применяются дополнительные наценки. Свяжитесь с Вашим Менеджером отдела продаж для уточнения актуальных цен для этой услуги. ',
    attendProduction: 'Посетить производство',
    attendProductionHelper:
        'Выберите "Да", если Вы хотели бы посетить первое производство Вашего дизайна на нашем заводе. Ваш графический координатор или представитель Отдела клиентского сервиса предоставит Вам протокол посещения запуска дизайна для большей информации.',
    responsibleForApproval: 'Ответственный за одобрение',

    // Additional Remarks
    generalCommentsSubInfo: 'Общие замечания',
    generalComments: 'Общие замечания',
    generalCommentsHelper: 'Введите общие комментарии, о которых вы хотели бы, чтобы Ball знал',
    additionalRemarksSubInfo: 'Дополнительные замечания',
    additionalRemarks: 'Дополнительные замечания',
    additionalRemarksHelper:
        'В это поле Вы можете внести дополнительные комментарии, о которых Вы хотели бы сообщить в Ball. Они будут учтены в процессе разработки Вашего дизайна и при производстве Ваших банок.',
    responsibleForCompletion: 'Ответственный',
    responsibleForCompletionHelper:
        'Контактное лицо, ответственное за информацию, внесенную в данную форму для Ball',

    // -- PRICING BREAKDOWN -- //
    downloadPDF: 'Download .PDF',
    effectiveDate: 'Effective Date',
    currency: 'Currency',
    itemDescription: 'Item Description',
    currentBasePrice: 'Current Base Price',
    newBasePrice: 'New Base Price',
    freightChargeFromLocation: 'Freight Charge From Location (if available)',
    freightChargesAvailable: 'Freight Charges (if available)',
    newBaseWithFreight: 'New Base Price With Freight (if available)',
    pricingLetterDisclaimer:
        'This price sheet serves as notice of new pricing effective {{ effectiveDate }} and is not a guarantee of current or future supply. Your submission of an order to purchase products constitutes your acceptance of this updated pricing under Ball’s current terms of sale.',
    pricingQtyNote: 'Prices are listed at a per thousand rate unless otherwise noted',
    priceSheet: 'Price Sheet',
    supplyPlan: 'Supply Plan',

    // -- KB MAX -- //
    errorProcessing: 'Произошла ошибка обработки.',
    exitWarning: 'Выйти?',
    yesExit: 'Да, Выход',
    exit: 'Выход',

    // -- NOTIFICATIONS -- //
    CAMPAIGN_ENDING:
        'Ordering for your {0} products will close on {1}.  The first available production date is {2}. ',
    CAMPAIGN_UPCOMING:
        'Ordering for your {0} products will begin on {1}.  Orders must be placed by {2} and the first available production date is {3}. ',
    CAMPAIGN_UPDATE:
        'Ordering information for your {0} products has changed.  The updated ordering window is: {1} - {2} and the first available production date is {3}. ',

    // -- MAKE IT DRAFTS SUMMARY -- //
    createdDate: 'Created Date',
    unauthorizedPoTooltip: 'Not authorized to manage POs for this location',
    discard: 'Discard',
    resumeOrder: 'Resume Order',

    // -- MANAGE PRODUCTS -- //
    manageProducts: 'Manage Products',
    productInformation: 'Product Information',
    activeProducts: 'Active Products',
    inactiveProducts: 'Inactive Products',
    previousForecast: 'Previous Forecast',
    copyToMakeIt: 'Copy To Make It',

    // Countries
    usa: 'США',
    eu: 'Европе',
    uk: 'Великобритания',
    germany: 'Германии',
    france: 'Франции',
    canada: 'Канаде',
    china: 'Китае',
    japan: 'Японии',
    southKorea: 'Южной Корее',
    ausNZ: 'Австралии / Новой Зеландии',
    australia: 'Австралии',
    nz: 'Новой Зеландии',
    mexico: 'Мексике',
    india: 'Индия',
    vietnam: 'Вьетнам',
    taiwan: 'Тайвань',
    russia: 'Россия',
    argentina: 'Аргентина',
    brazil: 'Бразилия',
    paraguay: 'Парагвай',
    uruguay: 'Уругвай',
    venezuela: 'Венесуэла',
    noCountryApplies: 'Ни одна из указанных стран не подает заявку',

    // -- User Admin -- //
    arAp: 'Кредиторская/дебиторская задолженность',
    artMgmt: 'Художественное руководство',
    forecastMgmt: 'Управление прогнозированием',
    marketingMgmt: 'Управление маркетингом',
    packagingMgmt: 'Управление упаковкой',
    procurementMgmt: 'Управление закупками',
    impersonation: 'Impersonation',
    addUser: 'Добавить пользователя',
    pendingRequests: 'Запросы на рассмотрении',
    pendingRegistration: 'Регистрация на рассмотрении',
    activeUsers: 'Активные пользователи',
    rolesLocations: 'Роли (количество местоположений)',
    actions: 'Действия',
    viewEdit: 'Просмотреть/редактировать',
    disable: 'Отключить',
    roles: 'Роль(-и)',
    sentOn: 'Отправлено в',
    resend: 'Отправить повторно',
    reviewRequest: 'Запрос на проверку',
    disableUserError: 'Системе не удалось отключить выбранного пользователя',
    disableUser: 'Отключить пользователя',
    disableUserWarning:
        'Вы собираетесь отключить для этого пользователя права доступа к учетной записи. Ему нужно будет создать новый запрос для восстановления разрешений. Продолжить?',
    yesDisable: 'Да, отключить',
    location: 'Местоположение',

    // -- GRAPHICS NAVIGATION -- //
    graphicsTitle: 'Rрафики',
    submitNewGraphicLink: 'Открыть новую форму графики',

    // -- GRAPHICS SUMMARY -- //
    recentlyCompletedEmea: 'Завершены',
    lastThreeSixtyFive: 'последние 365 дней',
    graphicNameAndIdEmea: 'Название и номер конструкции',
    completeIntakeForm: 'Полная форма захвата',
    availableActions: 'Доступные действия',
    learnAboutGraphics: 'Ознакомьтесь с графикой',
    graphicsProcessEmea:
        'В компании Ball разработан надежный процесс, обеспечивающий высочайшее качество и внимание к мелочам при создании и доставке продукта. Ниже приведена временная шкала, которая поможет Вам лучше понять наш процесс создания графики. Примечание: Если поставщик уже отправил нам вашу графику, вы будете уведомлены о том, когда ваша графика будет получена и когда она будет завершена.',
    graphicsNoteEmea:
        'Примечание: Если поставщик уже отправил нам вашу графику, вы будете уведомлены о том, когда ваша графика будет получена и когда она будет завершена.',
    newGraphicLink: 'Открыть новую форму графики',
    inProgress: 'Реализуется',
    inProgressGraphics: 'Реализуется графика',
    graphicSpecialistEmea: 'Координатор графики',
    graphicStatusHistory: 'История статусов графики',
    statusDate: 'Дата статуса',
    currentStatus: 'Текущий статус',
    inStatusSince: 'In Status Since',
    deleteYourDraftEmea: 'Удалите Ваши черновые варианты форм',
    graphicDeleteConfirmationEmea:
        'Вы уверены, что хотите удалить этот черновой вариант формы захвата графики?',
    graphicPermantlyGone: ' Форма будет удалена безвозвратно.',
    yesDelete: 'Да, удалить',
    noCancel: 'Нет, отменить',
    draftHasBeenSuccessfullySaved: 'Ваш черновой вариант формы захвата графики сохранен!',
    viewMyInProgressGraphicsEmea: 'Показать обзор графики',
    cancelSubmissionEmea: 'Отменить Ваше представление',
    intakeCancelConfirmationEmea: 'Вы уверены, что хотите отменить Ваш прогресс без сохранения?',
    changesPermanentlyGoneEmea:
        'Любые изменения, внесенные в Ваше представление, будут безвозвратно удалены.',
    deleteProgressEmea: 'Удалить прогресс',
    submitNewGraphic: 'Открыть новую форму графики',
    submitNewGraphicEmea: 'Открыть новую форму графики',
    selectBillTo: 'Выберите данные для выставления счетов',
    billTos: 'Данные для выставления счетов',

    // Graphics Status
    ART_REC: 'Artwork Received; Preparing your Quote',
    QOT_APPRVL: 'Quote Ready for Approval',
    PRF_INPRGRS: 'Creating Proofs',
    PRF_APPRVL: 'Proof Ready for Approval',
    PLT_SCHLD: 'Pilot Scheduled',
    PLT_APPRVL: 'Standard Ready for Approval',
    GRPHC_CMPL: 'Graphics Complete',
    noRecordsFound: 'No Records Found',

    // EMEA Graphics Status
    ARFC: 'Получена творческая работа; Проверка осуществимости',
    FCOH: 'Проверка осуществимости приостановлена',
    FCCI: 'Проверка осуществимости завершена; Требуется участие клиента – Итерация {0}',
    LDIP: 'Выполняется разработка макета',
    LDOH: 'Разработка макета приостановлена',
    LPRA: 'Пробный оттиск макета готов для утверждения',
    LAPP: 'Макет утвержден',
    CDIP: 'Выполняется разработка цветовой гаммы',
    CDOH: 'Разработка цветовой гаммы приостановлена',
    CTRA: 'Цветовая гамма готова для утверждения',
    CAPP: 'Цветовая гамма утверждена',
    PIPR: 'Выполняются пробные оттиски',
    PCRA: 'Контейнер макета готов для утверждения',
    DDCA: 'Разработка дизайна завершена',
    DDCC: 'Разработка дизайна завершена – Изменения в процессе ожидания',
    PAPP: 'Пробное производство банок утверждено',

    //Bulk upload errors
    EP100004:
        'Запрашиваемая дата недействительна или в недопустимом формате. Исправьте и укажите дату в формате ММ/ДД/ГГГГ.',

    // -- MAKE IT BULK UPLOAD -- //
    inactiveProductId: 'Этот продукт неактивен и не может быть заказан.',
    previousForecasts: 'Previous Forecasts',
    planItSummaryDashboardDesc:
        'View previously submitted order forecasts. Edit future order forecasts.'
};
