import {
    MAKEIT_DASHBOARD_LOADING,
    MAKEIT_DASHBOARD_LOADED,
    MAKEIT_DASHBOARD_LOADING_ERROR,
    MAKEIT_DASHBOARD_UPDATE_VIEW,
    MAKEIT_DASHBOARD_VIEW_ERROR,
    MAKEIT_DASHBOARD_UPDATE_RANGE,
    MAKEIT_ORDER_LOADING,
    MAKEIT_ORDER_LOADED,
    MAKEIT_ORDER_LOADING_ERROR,
    SAVE_MAKEIT_DASHBOARD_ORDER,
    MAKEIT_TOGGLE_ADD,
    MAKEIT_SAVE_ORDER_LIST,
    MAKEIT_SET_ORDER_LINES,
    MAKEIT_PRODUCTS_TO_ORDER_UPDATE,
    MAKEIT_DASHBOARD_NO_PRODUCTS,
    MAKEIT_SAVE_INPROGRESS,
    MAKEIT_RESET_STATE,
    MAKEIT_DASHBOARD_UPDATE_REQUESTED_DATE,
    MAKEIT_DASHBOARD_UPDATE_QUANTITY_UNIT,
    MAKEIT_DASHBOARD_UPDATE_PRODUCTS
} from '../actions/action-types';
import { PaymentTerms } from './customer-context';
import { Invoice } from './make-it-summary';
import { OrderProduct, OrderProductTableRow } from './orders-dashboard';
import { CampaignRun, MoqData, Product } from './product-portfolio';
import { QuantityUnit } from './shipping-dashboard';

export interface ProductCountInformation {
    canBtlCount: number;
    endCount: number;
    canBtlSkuCount: number;
    endSkuCount: number;
    canEachesCount?: number;
    endEachesCount?: number;
}

export interface MakeOrderItem {
    product: OrderProduct;
    quantityOrdered: number;
    requestedDate: string;
}

export interface MakeOrder {
    orderId?: string;
    customerPO?: number;
    shipToId?: number; // should be ship to interface
    submitDate?: string;
    canQuantityPallets?: number;
    canQuantitySKUs?: number;
    endQuantityPallets?: number;
    endQuantitySKUs?: number;
    orderName?: string;
    ordersList?: MakeOrderItem[];
}

export interface MakeItProductsRequest {
    ShipToIds: number[];
    Types?: string[];
    UsedWithinNMonths?: number;
    activeOnly?: boolean;
    WithAvailablePallets?: boolean;
    RequestedDate?: string[];
}

export interface ProductionOrderRequest {
    region: string;
    country: string;
    customerProductionOrderId: string;
    createdBy: string;
    status: string;
    submittedDate: string;
    submittedBy: string;
    lines: ProductionOrderLine[];
    shipToId: number;
    company: string;
    paymentTerms: PaymentTerms;
    updateDate?: string;
}

export interface ProductionOrders {
    orders: ProductionOrder[];
}

export interface ProductionOrder {
    productionOrderId?: number;
    productionOrderNumber: number;
    region: string;
    country: string;
    customerProductionOrderId: string;
    status: string;
    submittedDate?: string;
    firstRequestedDate?: string;
    submittedBy: string;
    createdBy: string;
    createDate: string;
    updateDate: string;
    updatedBy?: string;
    totalOrderQuantity: number;
    totalOrderSKUs: number;
    availableToShipQuantity: number;
    availableToShipSKUs: number;
    lines: ProductionOrderLine[];
    invoice?: Invoice;
    shipToId: number;
    shipToName?: string;
    name?: string;
    canQuantityPallets?: number;
    canQuantitySKUs?: number;
    endQuantityPallets?: number;
    endQuantitySKUs?: number;
    linkedDeliveryOrder?: LinkedDeliveryOrder;
    isLocked?: boolean;
    atmWeekStart?: string;
    totalOrderQuantityEaches?: number;
    endQuantityEaches?: number;
    canQuantityEaches?: number;
    totalFees?: number;
    isEditable?: boolean;
    isClosed?: boolean;
    orderExistsInWeek?: boolean;
}

export interface ProductionOrderLine extends ProductionOrderLineItem {
    customerProductStatus?: string;
    productionOrderId?: number;
    productionOrderNumber?: number;
    productionOrderLineNumber?: number;
    productId?: number;
    graphicId?: string;
    name?: string;
    size?: number;
    coating?: string;
    type?: string;
    unit?: string;
    shape?: string;
    neckDiameter?: number;
    endProfile?: string;
    shellColor?: string;
    tabColor?: string;
    index?: number;
    leadTimeWeeks?: number;
    description?: string;
    attributes?: string;
    moqFees?: MoqData[];
    minimumOrderQuantity?: number;
    minimumMet?: boolean;
    withinRange?: boolean;
    quantity?: number;
    modified?: boolean;
    originalRequestedDate?: string;
    originalPalletQuantity?: number;
    linkedDeliveryOrder?: LinkedDeliveryOrder;
    subRows?: ProductionOrderLine[];
    isMoreThanOriginalQty?: boolean;
    isLineRemoved?: boolean;
    hasLinkedDeliveryOrder?: boolean;
    hasCancelledDeliveryOrder?: boolean;
    retort?: string;
    specialtyInk?: string;
    basecoat?: string;
    shellGauge?: string;
    specialtyTab?: string;
    tabIndicator?: string;
    quantityPerPallet?: number;
    campaignRuns?: CampaignRun[];
    campaignTiming?: string;
    styleCode?: string;
    invalidRequestedDate?: boolean;
    requestShipToId?: string;
    fillRetort?: string;
    beverageCode?: string;
    beverageDescription?: string;
    includedInExistingForecast?: boolean;
}

export interface ProductionOrderLineItem {
    createDate?: string;
    palletQuantity?: number;
    productCoating?: string;
    productDescription?: string;
    productName?: string;
    productSize?: number;
    productSku?: string;
    productGraphicId?: string;
    productType?: string;
    productUnits?: string;
    productionOrderLineId?: number;
    requestedDate?: string;
    shortProductId?: string;
    splitOrder?: boolean;
    status?: string;
    updateDate?: string;
    productShape?: string;
    productNeckSize?: string;
    productTabColor?: string;
    productEndProfile?: string;
    customerProductId?: string;
    customerProductName?: string;
    displayName?: string;
    displayId?: string;
    graphicIdAndVersion?: string;
    product?: Product;
    assessedFee?: number;
    quantityPerPallet?: number;
    leadTimeWeeks?: number;
    overvarnish?: string;
    productStatus?: string;
    availableQuantity?: number;
    availableEaches?: number;
    requiredShipDate?: string;
}

export interface ProductionOrderLinkedDeliveryOrder {
    deliveryOrderId?: number;
    deliveryOrderNumber?: number;
    productionOrderLineId?: number;
    status?: string;
    productSKU?: string;
    productDescription?: string;
    quantity?: number;
    deliveryDateTime?: string;
}

export interface LinkedDeliveryOrder {
    linkedDeliveryOrders?: ProductionOrderLinkedDeliveryOrder[];
}

export interface ProductLeadTime {
    accountId: string;
    leadTimeWeeks: number;
    nextAvailableDate: string;
    referenceId: string;
    requestedDate: string;
    validateAvailableDate: string;
}

export interface MakeState {
    products?: OrderProductTableRow[];
    productsToOrder?: OrderProductTableRow[];
    orderLines?: ProductionOrderLine[];
    loading: boolean;
    loaded: boolean;
    error?: any;
    productionOrder?: ProductionOrder;
    makeOrder?: MakeOrder;
    range?: string[];
    view?: string[];
    shipToId?: string;
    linkedDeliveryOrder?: LinkedDeliveryOrder;
    productLeadTimes?: ProductLeadTime[];
    requestedDate: string;
    quantityUnit: QuantityUnit;
}

const initialState: MakeState = {
    products: [],
    loading: false,
    loaded: false,
    makeOrder: { orderName: '' },
    range: [''],
    view: [''],
    requestedDate: '',
    quantityUnit: 'pallets'
};

const makeItDashboard = (state = initialState, action: any): MakeState => {
    switch (action.type) {
        case MAKEIT_DASHBOARD_LOADED:
            return {
                ...state,
                loaded: true,
                loading: false,
                products: action.products,
                shipToId: action.shipToId
            };
        case MAKEIT_DASHBOARD_LOADING:
            return { ...state, loaded: false, loading: true, error: undefined };
        case MAKEIT_DASHBOARD_LOADING_ERROR:
            return { ...state, loaded: true, loading: false, error: action.error };
        case MAKEIT_DASHBOARD_UPDATE_VIEW:
            return { ...state, loaded: true, loading: false, view: action.view };
        case MAKEIT_DASHBOARD_VIEW_ERROR:
            return { ...state, loaded: false, loading: false, error: action.error };
        case MAKEIT_DASHBOARD_UPDATE_RANGE:
            return { ...state, loaded: true, loading: false, range: action.range };
        case MAKEIT_DASHBOARD_NO_PRODUCTS:
            return {
                ...state,
                loaded: true,
                loading: false,
                products: [],
                shipToId: action.shipToId
            };
        case MAKEIT_ORDER_LOADING:
            return { ...state, loaded: false, loading: true };
        case MAKEIT_ORDER_LOADED:
            return {
                ...state,
                loaded: true,
                loading: false,
                productionOrder: action.order
            };
        case MAKEIT_ORDER_LOADING_ERROR:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: action.error
            };
        case SAVE_MAKEIT_DASHBOARD_ORDER:
            return {
                ...state,
                productionOrder: action.productionOrder,
                error: false
            };
        case MAKEIT_TOGGLE_ADD:
            return {
                ...state,
                productsToOrder: action.productsToOrder,
                products: action.products,
                orderLines: action.orderLines
            };
        case MAKEIT_SAVE_ORDER_LIST:
            return {
                ...state,
                productsToOrder: action.productsToOrder,
                products: action.products,
                orderLines: action.orderLines
            };
        case MAKEIT_SET_ORDER_LINES:
            return {
                ...state,
                orderLines: action.orderLines
            };
        case MAKEIT_PRODUCTS_TO_ORDER_UPDATE:
            return {
                ...state,
                productsToOrder: action.productsToOrder
            };
        case MAKEIT_SAVE_INPROGRESS:
            return { ...state, error: undefined };
        case MAKEIT_RESET_STATE:
            return {
                ...state,
                loaded: action.loaded,
                productionOrder: action.productionOrder,
                productsToOrder: action.productsToOrder,
                products: action.products,
                orderLines: action.orderLines,
                requestedDate: action.requestedDate
            };
        case MAKEIT_DASHBOARD_UPDATE_REQUESTED_DATE:
            return {
                ...state,
                requestedDate: action.requestedDate
            };
        case MAKEIT_DASHBOARD_UPDATE_QUANTITY_UNIT:
            return {
                ...state,
                quantityUnit: action.quantityUnit,
                productsToOrder: action.productsToOrder
            };
        case MAKEIT_DASHBOARD_UPDATE_PRODUCTS:
            return {
                ...state,
                products: action.products
            };
        default:
            return state;
    }
};

export default makeItDashboard;
