import React, { ReactNode } from 'react';

export interface State {
    deliveryDateTime: string;
}

export interface ProviderProps {
    children: ReactNode;
    value: State;
}

const OrderContext = React.createContext<State>({} as State);

function EditShipmentConfigurationProvider(props: ProviderProps) {
    const { children, value } = props;
    return <OrderContext.Provider value={value}> {children} </OrderContext.Provider>;
}

const useEditShipmentConfiguration = () => React.useContext(OrderContext);

export { EditShipmentConfigurationProvider, useEditShipmentConfiguration };
