import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class MakeItReviewOrderSectionColumns {
    static getColumns = (
        culture: string,
        showCsvRowNumber: boolean,
        showPrevPalletQty: boolean,
        planItToMakeIt: boolean,
        isLargeCustomer: boolean,
        isDraft: boolean
    ) => {
        return [
            createColumn('displayId', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('originalCsvLineNumber', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'csvRowNumber'),
                visible: showCsvRowNumber
            }),
            createColumn('productGraphicId', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'customerProductGraphicId')
            }),
            createColumn('displayName', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'productDescription')
            }),
            createColumn('coating', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'coating')
            }),
            createColumn('leadTime', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'leadTime')
            }),
            createColumn('availableQuantity', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'currentAvailableBalance'),
                visible: isLargeCustomer && !isDraft
            }),
            createColumn('originalPalletQuantity', {
                dataType: ColumnDataType.Numeric,
                label: planItToMakeIt
                    ? TranslationService.getTranslatedText(culture, 'forecastedQuantity')
                    : TranslationService.getTranslatedText(culture, 'previousQuantitySubmitted'),
                visible: showPrevPalletQty
            }),
            createColumn('originalQuantity', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('palletQuantity', {
                dataType: ColumnDataType.Numeric,
                label:
                    showPrevPalletQty && !planItToMakeIt
                        ? TranslationService.getTranslatedText(culture, 'revisedQuantityToPurchase')
                        : TranslationService.getTranslatedText(culture, 'quantityToPurchase')
            }),
            createColumn('productId', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('graphicIdAndVersion', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('eachesQuantity', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('leadTimeWeeks', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('minimumOrderQuantity', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('moqFees', {
                visible: false
            }),
            createColumn('userSuppliedProductId', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('quantityPerPallet', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('palletsRounded', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('isCustomerProductIdDistinct', {
                dataType: ColumnDataType.Boolean,
                visible: false
            }),
            createColumn('candidateProductSkus', {
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('customerProductId', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productSku', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('campaignTiming', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('campaignRuns', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('', {
                dataType: ColumnDataType.String,
                visible: true
            }),
            createColumn('productStatus', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('status', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('hasLinkedDeliveryOrder', {
                dataType: ColumnDataType.Boolean,
                visible: false
            }),
            createColumn('isRequestedDateAfterLeadTime', {
                dataType: ColumnDataType.Boolean,
                visible: false
            })
        ];
    };
}
