import React, { useState } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import clsx from 'clsx';
import { makeStyles, Grid, Typography, Box } from '@material-ui/core';
import {
    ballLtGray,
    ballBlue,
    ltBlueGrey,
    ballLtBlue,
    paleGrey,
    boldWeight
} from '../../../themes/globalConstants';
import { useTranslation, Trans } from 'react-i18next';
import Button from '../atoms/Button';
import DayRangePicker from '../atoms/DayRangePicker';
import { Moment } from 'moment';
import moment from 'moment';
import Modal from './Modal';
interface Props {
    onSubmit: (weekToAdd?: Moment) => void;
    existingWeeks: Moment[];
    planIt?: boolean;
    title?: string;
    confirmText?: string;
}

const useStyles = makeStyles((theme) => ({
    modal: {
        '& .MuiDialog-paperScrollPaper': {
            borderRadius: 'unset',
            overflow: 'hidden'
        },
        '& .MuiDialogTitle-root': {
            justifyContent: 'start'
        },
        '& .MuiDialogContent-root': {
            padding: '1.875em'
        },
        '& .MuiPaper-elevation1': {
            boxShadow: 'none'
        }
    },
    datepickerHeader: {
        paddingBottom: 14,
        '& .MuiTypography-body1': {
            fontWeight: boldWeight,
            fontSize: '1em'
        }
    },
    legend: {
        height: '4.57em',
        width: '36.57em',
        border: `1px solid ${ltBlueGrey}`,
        borderRadius: 4
    },
    legendText: {
        marginLeft: '2em',
        color: ballBlue
    },
    dateLegendBox: {
        height: 55,
        width: 60,
        borderRadius: 4
    },
    weekText: {
        width: '100%',
        height: '100%',
        margin: '10%',
        marginTop: '25%'
    },
    restrictedBox: {
        backgroundColor: paleGrey,
        marginLeft: 0
    },
    firstAvailableBox: {
        backgroundColor: ballLtBlue
    },
    availableReleaseBox: {
        border: `1px solid ${ballLtGray}`
    },
    keyText: {
        marginLeft: '0.5em'
    },
    button: {
        height: 40,
        marginTop: 30,
        fontSize: 14,
        fontWeight: boldWeight,
        textTransform: 'uppercase',
        padding: '0 32px'
    },
    cancelButton: {
        marginRight: 20
    }
}));

const AddWeekModal = (props: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [dayToAdd, setDayToAdd] = useState<null | Moment>(null);

    const isOutsideRange = (date: Moment) => {
        return (
            moment().day(7).isAfter(date) ||
            props.existingWeeks.some(
                (w) => w.format('MM/DD/YYYY') === moment(date).isoWeekday(1).format('MM/DD/YYYY')
            )
        );
    };

    const onDateChange = (date: null | Moment) => {
        setDayToAdd(date === null ? date : moment(date).day(1));
    };

    const handleCancel = () => {
        props.onSubmit();
    };
    const handleSubmit = () => {
        props.onSubmit(dayToAdd ?? undefined);
    };

    return (
        <Modal
            open={true}
            close={handleCancel}
            title={props.title ?? t('addWeek', 'Add Week')}
            closeIcon={true}
            className={classes.modal}
            maxWidth="xl"
        >
            {
                <>
                    <Grid container justify="center" direction="column">
                        <Grid
                            item
                            container
                            justify="space-between"
                            className={classes.datepickerHeader}
                        >
                            <Grid item>
                                <Typography>
                                    {!props.planIt
                                        ? t(
                                              'selectWeekToAdd',
                                              'Select a week to add to your Make It: '
                                          )
                                        : t(
                                              'selectWeekToAddPlanIt',
                                              'Select a week to add to your Plan It: '
                                          )}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    {t('todayWithDate', { today: moment().format('MM/DD/YYYY') })}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <DayRangePicker
                                startDate={dayToAdd}
                                endDate={dayToAdd ? moment(dayToAdd).add(6, 'd') : null}
                                isOutsideRange={isOutsideRange}
                                onDateChange={onDateChange}
                            />
                        </Grid>
                        {/* LEGEND */}
                        <Grid container>
                            <Grid
                                container
                                item
                                xs={7}
                                alignItems="center"
                                spacing={0}
                                className={classes.legend}
                            >
                                <Grid item xs={2}>
                                    <Typography variant="subtitle2" className={classes.legendText}>
                                        <Trans i18nKey="legend">Legend</Trans>
                                    </Typography>
                                </Grid>
                                <Grid container item xs={1}>
                                    <Box
                                        className={clsx(
                                            classes.dateLegendBox,
                                            classes.restrictedBox
                                        )}
                                    >
                                        <Typography variant="body1" className={classes.weekText}>
                                            <Trans i18nKey="week">Week</Trans>
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="body1" className={classes.keyText}>
                                        <Trans i18nKey="restrictedDate"> Restricted Week </Trans>
                                    </Typography>
                                </Grid>
                                <Grid container item xs={1}>
                                    <Box
                                        className={clsx(
                                            classes.dateLegendBox,
                                            classes.firstAvailableBox
                                        )}
                                    >
                                        <Typography variant="body1" className={classes.weekText}>
                                            <Trans i18nKey="week">Week</Trans>
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="body1" className={classes.keyText}>
                                        <Trans i18nKey="selectedWeek">Selected Week</Trans>
                                    </Typography>
                                </Grid>
                                <Grid container item xs={1}>
                                    <Box
                                        className={clsx(
                                            classes.dateLegendBox,
                                            classes.availableReleaseBox
                                        )}
                                    >
                                        <Typography variant="body1" className={classes.weekText}>
                                            <Trans i18nKey="week">Week</Trans>
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="body1" className={classes.keyText}>
                                        <Trans i18nKey="availableReleaseDate">Available Week</Trans>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item xs={5} justify="flex-end" alignItems="flex-end">
                                <Grid item className={classes.cancelButton}>
                                    <Button
                                        type="button"
                                        variant="outlined"
                                        color="secondary"
                                        data-testid="cancel-btn"
                                        onClick={handleCancel}
                                        className={classes.button}
                                    >
                                        <Trans i18nKey="cancel">Cancel</Trans>
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        data-testid="ok-btn"
                                        onClick={handleSubmit}
                                        className={classes.button}
                                        disabled={dayToAdd === null || !dayToAdd.isValid}
                                    >
                                        {props.confirmText ? (
                                            <Typography>{props.confirmText}</Typography>
                                        ) : (
                                            <Trans i18nKey="add">Add</Trans>
                                        )}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }
        </Modal>
    );
};

export default AddWeekModal;
