import React from 'react';
import { ContentSection } from '../../../../store/reducers/content-page';
import verifyTemplateType from './TemplateCreator';

interface Props {
    sections: ContentSection[];
}

export default function ContentTemplate({ sections }: Props) {
    return (
        <>
            {sections.map((section, i) => {
                return verifyTemplateType(section.type, section, i);
            })}
        </>
    );
}
