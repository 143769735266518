import {
    Activity,
    filterShipToIdsByPermission,
    SecurityLevel
} from '../../utility/auth/useSecurity';
import OrdersService, { Order_Status } from '../../utility/services/orders-service';
import { AuthState } from '../reducers/auth';
import {
    MAKE_SUMMARY_LOAD_OPEN,
    MAKE_SUMMARY_LOAD_CLOSED,
    MAKE_SUMMARY_LOAD_DRAFT,
    MAKE_SUMMARY_LOAD_PENDING,
    MAKE_SUMMARY_ERROR,
    MAKE_SUMMARY_SET_SEARCH,
    MAKE_SUMMARY_USE_SEARCH
} from './action-types';

export const loadDraftProductionOrders = (accounts: number[]) => {
    return (dispatch, getState) => {
        const auth: AuthState = getState().auth;
        OrdersService.getProductionOrderSummaries(auth.accessToken, {
            shipToIds: filterShipToIdsByPermission(
                auth,
                accounts,
                Activity.DraftProductionOrders,
                SecurityLevel.View
            ),
            statuses: [Order_Status.Draft]
        })
            .then((response) => {
                dispatch({ type: MAKE_SUMMARY_LOAD_DRAFT, orders: response.data.summaries });
            })
            .catch((error) => {
                dispatch({ type: MAKE_SUMMARY_ERROR, error });
            });
    };
};

export const loadOpenProductionOrders = (accounts: number[]) => {
    return async (dispatch, getState) => {
        const auth: AuthState = getState().auth;

        // Query production orders from order-api
        const productionOrderResponse = await OrdersService.getProductionOrderSummaries(
            auth.accessToken,
            {
                shipToIds: filterShipToIdsByPermission(
                    auth,
                    accounts,
                    Activity.NewOpenProductionOrders,
                    SecurityLevel.View
                ),
                statuses: [Order_Status.Open, Order_Status.Submit, Order_Status.EditedPending]
            }
        ).catch((error) => {
            dispatch({ type: MAKE_SUMMARY_ERROR, error });
            return;
        });

        if (productionOrderResponse)
            dispatch({
                type: MAKE_SUMMARY_LOAD_OPEN,
                orders: productionOrderResponse.data.summaries
            });
    };
};

export const getTotalQuantityOrdered = (product: any, order: any): number => {
    let totalOrderQuantityForProduct = 0;
    order.lines?.forEach((line) => {
        if (line.productSku === product?.productSku)
            totalOrderQuantityForProduct += line.palletQuantity;
    });
    return totalOrderQuantityForProduct;
};

export const loadPendingPaymentOrders = (accounts: number[]) => {
    return (dispatch, getState) => {
        const auth: AuthState = getState().auth;
        OrdersService.getProductionOrderSummaries(auth.accessToken, {
            shipToIds: filterShipToIdsByPermission(
                auth,
                accounts,
                Activity.NewOpenProductionOrders,
                SecurityLevel.View
            ),
            statuses: [Order_Status.Payment_Delinquent],
            invoiceStatuses: [Order_Status.Payment_Delinquent]
        })
            .then((response) => {
                dispatch({ type: MAKE_SUMMARY_LOAD_PENDING, orders: response.data.summaries });
            })
            .catch((error) => {
                dispatch({ type: MAKE_SUMMARY_ERROR, error });
            });
    };
};

export const loadClosedProductionOrders = (accounts: number[]) => {
    return (dispatch, getState) => {
        const auth: AuthState = getState().auth;
        OrdersService.getProductionOrderSummaries(auth.accessToken, {
            shipToIds: filterShipToIdsByPermission(
                auth,
                accounts,
                Activity.ClosedProductionOrders,
                SecurityLevel.View
            ),
            statuses: [
                Order_Status.Closed,
                Order_Status.Completed,
                Order_Status.Cancelled,
                Order_Status.CancelledPending
            ],
            includeLastShipmentData: true
        })
            .then((response) => {
                dispatch({ type: MAKE_SUMMARY_LOAD_CLOSED, orders: response.data.summaries });
            })
            .catch((error) => {
                dispatch({ type: MAKE_SUMMARY_ERROR, error });
            });
    };
};

export const setSummaryStoredSearch = (searchValue: string) => {
    return (dispatch) => {
        dispatch({
            type: MAKE_SUMMARY_SET_SEARCH,
            searchValue
        });
    };
};

export const setUseSummaryStoredSearch = (useStoredSearch: boolean) => {
    return (dispatch) => {
        dispatch({
            type: MAKE_SUMMARY_USE_SEARCH,
            useStoredSearch
        });
    };
};
