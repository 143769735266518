import { Grid, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import {
    AdminUserTableRow,
    UserAdminUserPermissions,
    UserDashboardState
} from '../../../../store/reducers/user-admin';
import {
    boldWeight,
    blackWeight,
    ballBlue,
    dateOptionInvalidGray,
    small
} from '../../../../themes/globalConstants';
import TranslationService from '../../../../utility/services/translation-service';
import UserAdminUsersRow from './UserAdminUsersRow';
import UserAdminUsersColumns from './UserAdminUsersColumns';
import { getUserRoles } from '../../../../utility/helpers/user-admin-helpers';
import { hasAdminPersonaForAccount } from '../../../../utility/auth/useSecurity';
import { AuthState } from '../../../../store/reducers/auth';
import { getAdminAccounts } from '../../../../utility/helpers/admin-helpers';
import { CustomerContextState } from '../../../../store/reducers/customer-context';

interface Props {
    inActive?: boolean;
}

const useStyles = makeStyles((theme) => ({
    titleContainer: {
        padding: '1em'
    },
    paper: {
        marginBottom: '1em'
    },
    title: {
        fontSize: '1.5em',
        fontWeight: boldWeight,
        color: theme.palette.secondary.main,
        paddingLeft: '0.625em'
    },
    tableWrapper: {
        '& .MuiToolbar-root': {
            padding: 0,
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                },
                '&:first-of-type ': {
                    paddingLeft: 0
                }
            },
            '& > h2': {
                fontSize: '1.5em',
                fontWeight: boldWeight,
                color: theme.palette.secondary.main,
                paddingLeft: '0.625em'
            },
            '& .MuiInputBase-formControl': {
                border: `1px solid ${dateOptionInvalidGray}`,
                borderRadius: '.125em',
                '&:before': {
                    display: 'none'
                },
                '&:after': {
                    display: 'none'
                },
                '& svg': {
                    height: '.875em',
                    width: '.875em',
                    fill: ballBlue
                },
                '& .MuiIconButton-root': {
                    '&:hover': {
                        backgroundColor: 'transparent !important'
                    }
                },
                '& .MuiInput-input': {
                    paddingLeft: '.25em'
                }
            }
        },
        '& .MuiTableSortLabel-root': {
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em',
            width: '99%',
            justifyContent: 'space-between',
            '& .MuiSvgIcon-root': {
                fontSize: 14,
                opacity: '1'
            }
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                '&:nth-child(1)': {
                    width: '30%'
                },
                '&:nth-child(2)': {
                    width: '40%'
                },
                '&:nth-child(3)': {
                    width: '30%',
                    fontSize: small,
                    fontWeight: blackWeight,
                    textTransform: 'uppercase',
                    color: theme.palette.secondary.main,
                    borderRadius: '0.25em',
                    paddingLeft: '0.5em',
                    justifyContent: 'space-between'
                }
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    }
}));

const UserAdminUsersTab = ({ inActive = false }: Props) => {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    useTranslation();
    const [formattedAdminUsers, setFormattedAdminUsers] = useState<AdminUserTableRow[] | null>(
        null
    );
    const { activeUsers, inactiveUsers } = useTypedSelector<UserDashboardState>(
        (state) => state.userAdminDashboard
    );
    const [columns, setColumns] = useState<any>([]);
    const [userIsAdmin, setUserIsAdmin] = useState<boolean>(false);

    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);
    const { accounts, selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );

    useEffect(() => {
        if (permissions && selectedAccountId) {
            setUserIsAdmin(hasAdminPersonaForAccount(permissions, selectedAccountId));
        }
    }, [permissions]);

    useEffect(() => {
        if (permissions && accounts && activeUsers && inactiveUsers && selectedAccountId) {
            const sortUsersAndFilterByAccountId = (adminUsers: UserAdminUserPermissions[]) => {
                let sortedUsers = adminUsers
                    .sort((a, b) => a.userName.localeCompare(b.userName))
                    .filter((user) =>
                        user.permissions?.some((permission) =>
                            permission.accountIds.includes(selectedAccountId)
                        )
                    );

                // Hide users from Inactive tab if they're present in Active tab
                if (inActive) {
                    const activeUsersForAccountId = activeUsers.filter((user) =>
                        user.permissions?.some((permission) =>
                            permission.accountIds.includes(selectedAccountId)
                        )
                    );
                    const ActiveUsernamesForAccountId = activeUsersForAccountId.map(
                        (user) => user.userName
                    );
                    sortedUsers = sortedUsers.filter(
                        (user) => !ActiveUsernamesForAccountId.includes(user.userName)
                    );
                }

                const adminAccounts = getAdminAccounts(permissions, accounts);
                const usersForTable: AdminUserTableRow[] = [];
                sortedUsers.forEach((user) => {
                    const accountsWithUserPermissions: string[] = [];
                    user.permissions?.forEach((permission) => {
                        accountsWithUserPermissions.push(...permission.accountIds);
                    });

                    let formattedUser: AdminUserTableRow = {
                        userName: user.userName,
                        roles: getUserRoles(user.permissions, cultureCode),
                        currentUserHasAdminPermission: adminAccounts.some((account) =>
                            accountsWithUserPermissions.includes(account.accountId)
                        )
                    };
                    usersForTable.push(formattedUser);
                });
                return usersForTable;
            };

            if (inActive) {
                const inactiveUsersForTable = sortUsersAndFilterByAccountId(inactiveUsers);
                setFormattedAdminUsers(inactiveUsersForTable);
            } else {
                const activeUsersForTable = sortUsersAndFilterByAccountId(activeUsers);
                setFormattedAdminUsers(activeUsersForTable);
            }
        }
    }, [
        accounts,
        inActive,
        permissions,
        activeUsers,
        inactiveUsers,
        cultureCode,
        selectedAccountId
    ]);

    const options = new ToolbarOptions({
        title: inActive
            ? TranslationService.getTranslatedText(cultureCode, 'inactiveUsers')
            : TranslationService.getTranslatedText(cultureCode, 'activeUsers'),
        advancePagination: false,
        exportButton: false,
        printButton: false,
        searchText: true
    });

    const AdminUserTableItem = ({ row }: { row: Partial<AdminUserTableRow> }) => {
        if (row) {
            return (
                <UserAdminUsersRow
                    user={row as AdminUserTableRow}
                    inActive={inActive}
                    key={row.userName!}
                    hasAdminPersona={userIsAdmin}
                />
            );
        } else {
            return null;
        }
    };

    useEffect(() => {
        setColumns(UserAdminUsersColumns.getAdminUserColumns(cultureCode, userIsAdmin, inActive));
    }, [cultureCode, inActive, userIsAdmin]);

    return (
        <Paper elevation={2} className={classes.paper}>
            <Grid className={classes.tableWrapper}>
                {formattedAdminUsers && (
                    <DataGrid
                        gridName={inActive ? 'Inactive Users!' : 'Active Users!'}
                        columns={columns}
                        dataSource={formattedAdminUsers}
                        rowComponent={AdminUserTableItem}
                        toolbarOptions={options}
                    ></DataGrid>
                )}
            </Grid>
        </Paper>
    );
};

export default UserAdminUsersTab;
