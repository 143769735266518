import { ColumnDataType, createColumn, ColumnSortDirection } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class ManageCommunicationsColumns {
    static getManageCommunicationsColumns = (culture: string) => {
        return [
            createColumn('communicationId', {
                dataType: ColumnDataType.Numeric,
                isKey: true,
                visible: false,
            }),
            createColumn('messageType', {
                label: TranslationService.getTranslatedText(culture, 'messageType'),
                dataType: ColumnDataType.String,
                filterable: true,
                searchable: true,
                sortable: true
            }),
            createColumn('createdBy', {
                label: TranslationService.getTranslatedText(culture, 'createdBy'),
                dataType: ColumnDataType.String,
                filterable: true,
                searchable: true,
                sortable: true
            }),
            createColumn('title', {
                label: TranslationService.getTranslatedText(culture, 'title'),
                dataType: ColumnDataType.String,
                filterable: true,
                searchable: true,
                sortable: true
            }),
            createColumn('startDateTime', {
                label: TranslationService.getTranslatedText(culture, 'startDateTime'),
                dataType: ColumnDataType.String,
                filterable: true,
                searchable: true,
                sortable: true,
                sortOrder: 1,
                sortDirection: ColumnSortDirection.Ascending

            }),
            createColumn('endDateTime', {
                label: TranslationService.getTranslatedText(culture, 'endDateTime'),
                dataType: ColumnDataType.String,
                filterable: true,
                searchable: true,
                sortable: true
            }),
            createColumn('status', {
                searchable: true,
                filterable: true,
                sortable: true
            }),
            createColumn('actions', {
                label: TranslationService.getTranslatedText(culture, 'actions'),
                searchable: false,
                filterable: false,
                sortable: false
            })
        ];
    };
}
