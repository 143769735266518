import { Select, MenuItem, makeStyles, Typography, Grid, MenuProps } from '@material-ui/core';
import React, { Dispatch, SetStateAction } from 'react';
import { white, ballBlue, ballLtGray_3 } from '../../../themes/globalConstants';
import clsx from 'clsx';

interface Props {
    name: string;
    testId: string;
    selectObjects: string[];
    selected?: string;
    updateSelected: Dispatch<SetStateAction<string | undefined>>;
    className?: string;
}

const useStyles = makeStyles(() => ({
    inputSelect: {
        width: '90%',
        height: 'flex',
        borderBottom: `0.25em solid ${ballBlue}`,
        '& .MuiInputBase-input': {
            padding: '1em 1em 1em 1em'
        }
    },
    menu: {
        marginTop: '0.25em',
        '& ul': {
            marginRight: '-8em'
        },
        '& li': {
            width: '100%',
            boxSizing: 'border-box',
            '&:hover': {
                backgroundColor: ballLtGray_3
            }
        }
    },
    whiteBackground: {
        backgroundColor: white,
        '&.MuiListItem-root.Mui-selected': {
            backgroundColor: white
        },
        '&.Mui-focused': {
            backgroundColor: white
        },
        '&:hover': {
            backgroundColor: white
        }
    }
}));

const EmailSelectorDropdown = (props: Props) => {
    const classes = useStyles();

    const shipToMenuProperties = {
        classes: {
            paper: classes.menu
        },
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
        },
        getContentAnchorEl: null
    } as MenuProps;

    const handleChange = (event: any) => {
        props.updateSelected(event.target.value);
    };

    return (
        <Select
            inputProps={{ 'data-testid': props.testId }}
            className={clsx(classes.inputSelect, classes.whiteBackground, props.className)}
            value={props.selected ? props.selected : null}
            onChange={handleChange}
            MenuProps={shipToMenuProperties}
            disableUnderline
            disabled={!props.selectObjects || props.selectObjects.length === 0}
            label="Test"
        >
            {props.selectObjects.map((option) => (
                <MenuItem
                    key={option}
                    value={option}
                    data-testid="menu-option"
                    className={classes.whiteBackground}
                >
                    <Grid>
                        <Typography data-testid="email-name">{option}</Typography>
                    </Grid>
                </MenuItem>
            ))}
        </Select>
    );
};

export default EmailSelectorDropdown;
