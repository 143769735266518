import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class AddProductsModalColumns {
    static getTradeItProductsColumns = (culture: string) => {
        return [
            createColumn('sortIndex', {
                searchable: false,
                sortable: false,
                filterable: false,
                visible: false,
                dataType: ColumnDataType.Numeric
            }),
            createColumn('displayId', {
                dataType: ColumnDataType.String,
                filterable: true,
                isKey: true,
                label: `${TranslationService.getTranslatedText(
                    culture,
                    'productId'
                )} / ${TranslationService.getTranslatedText(culture, 'graphicId')}`,
                sortable: true,
                searchable: true,
                visible: true
            }),
            createColumn('graphicIdAndVersion', {
                dataType: ColumnDataType.String,
                filterable: true,
                sortable: true,
                searchable: true,
                visible: false
            }),
            createColumn('displayName', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'productDescription'),
                filterable: true,
                sortable: true,
                searchable: true,
                visible: true
            }),
            createColumn('coating', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'coating'),
                filterable: true,
                sortable: true,
                searchable: true
            }),
            createColumn('leadTimeWeeks', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'leadTime'),
                filterable: false,
                sortable: true,
                searchable: false
            }),
            createColumn('status', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'status'),
                filterable: true,
                sortable: true,
                searchable: true
            }),
            createColumn('addDisabled', {
                dataType: ColumnDataType.Boolean,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('isAdded', {
                dataType: ColumnDataType.Boolean,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('productSku', {
                dataType: ColumnDataType.String,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            })
        ];
    };
}
