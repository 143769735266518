import React from 'react';
import { NavigationalPageTemplate } from '../../templates/NavigationalPageTemplate';
import { Typography, Grid, Button, makeStyles } from '@material-ui/core';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { selectIsLargeCustomer, selectIsPlanItOnly } from '../../../store/selectors';
import { Culture, RegionCultureState } from '../../../store/reducers/region-culture';
import { Region } from '../../../store/reducers/graphic-intake';
import { useHistory } from 'react-router';
import { ballGray, blackWeight } from '../../../themes/globalConstants';
import TranslationService from '../../../utility/services/translation-service';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { getActivityTranslation } from '../../../utility/helpers/unauthorized-helpers';
import { Trans, useTranslation } from 'react-i18next';
import { Activity } from '../../../utility/auth/useSecurity';

const useStyles = makeStyles((theme) => ({
    unauthPageContentContainer: {
        minHeight: '25em'
    },
    actionBtn: {
        borderRadius: 'unset',
        margin: '1em',
        marginTop: '2.5em'
    },
    title: {
        color: ballGray,
        fontWeight: blackWeight,
        marginTop: '1.5em',
        textAlign: 'center'
    },
    location: {
        color: ballGray,
        fontWeight: blackWeight,
        marginTop: '1em',
        textAlign: 'center'
    },
    body: {
        color: ballGray,
        marginTop: '1.5em',
        textAlign: 'center'
    }
}));

const UnauthorizedPage = ({ location }) => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const { regionCode, cultureCode } = useTypedSelector<RegionCultureState>(
        (state) => state.regionCulture
    );
    const activity = location?.state?.activity;
    const { selectedAccountId, accounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );

    const banner = {
        header:
            TranslationService.getTranslatedText(cultureCode, 'unauthorized') ??
            TranslationService.getTranslatedText(Culture.enUS, 'unauthorized'),
        description:
            TranslationService.getTranslatedText(cultureCode, 'youAreNotAuthorized') ??
            TranslationService.getTranslatedText(Culture.enUS, 'youAreNotAuthorized'),
        imageUrl: ''
    };

    const onRequestAccessLinkClicked = () => {
        history.push('/request-access');
    };

    const onDashboardLinkClicked = () => {
        history.push('/dashboard');
    };

    const activityString = getActivityTranslation(activity, cultureCode);
    const selectedAccount = accounts?.find((account) => account.accountId === selectedAccountId);
    const showDetailedTitle =
        selectedAccount &&
        selectedAccount.name &&
        selectedAccount.accountId &&
        accounts &&
        accounts.length > 1;
    const shipToDisplayName = `${selectedAccount?.name} (${selectedAccount?.accountId})`;

    const isLargeCustomer = useTypedSelector<boolean>(selectIsLargeCustomer);
    const hasOnlyPlanItAccounts = useTypedSelector<boolean>(selectIsPlanItOnly);
    const canRequestAccess = !(
        (isLargeCustomer && hasOnlyPlanItAccounts) ||
        activity === Activity.TradeIt ||
        activity === Activity.ManageLocations ||
        activity === Activity.AddShipTo
    );
    return (
        <NavigationalPageTemplate banner={banner}>
            <Grid container justify="center" className={classes.unauthPageContentContainer}>
                {regionCode === Region.NA ? (
                    <Grid item xs={12}>
                        {showDetailedTitle && (
                            <>
                                <Typography variant="h3" className={classes.title}>
                                    <Trans i18nKey="dontHaveAccess">
                                        Looks like you don't have access to {{ activityString }} for
                                    </Trans>
                                </Typography>
                                <Typography variant="h3" className={classes.location}>
                                    {shipToDisplayName}
                                </Typography>
                            </>
                        )}
                        {!showDetailedTitle && (
                            <Typography variant="h3" className={classes.title}>
                                {t('requestAccessUnauthTitle', { area: activityString })}
                            </Typography>
                        )}
                        {canRequestAccess && (
                            <Typography variant="body2" className={classes.body}>
                                {TranslationService.getTranslatedText(
                                    cultureCode,
                                    'requestAccessUnauthBody'
                                )}
                                <i>The Source</i>:
                            </Typography>
                        )}
                        <Grid item container xs={12} justify="center">
                            {canRequestAccess && (
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    data-testid="request-access-link"
                                    onClick={onRequestAccessLinkClicked}
                                    className={classes.actionBtn}
                                >
                                    {TranslationService.getTranslatedText(
                                        cultureCode,
                                        'requestAccess'
                                    )}
                                </Button>
                            )}
                            <Button
                                type="submit"
                                color="primary"
                                variant="outlined"
                                data-testid="unauthorized-dashboard-link"
                                onClick={onDashboardLinkClicked}
                                className={classes.actionBtn}
                            >
                                {TranslationService.getTranslatedText(
                                    cultureCode,
                                    'returnToDashboard'
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid item>
                        <Typography variant="h2" data-testid="unauthorized-text">
                            {TranslationService.getTranslatedText(cultureCode, 'unauthorized') ??
                                TranslationService.getTranslatedText(Culture.enUS, 'unauthorized')}
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </NavigationalPageTemplate>
    );
};

export default UnauthorizedPage;
