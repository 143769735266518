import {
    Grid,
    Container,
    Hidden,
    Paper,
    CircularProgress,
    Button,
    makeStyles
} from '@material-ui/core';
import React, { createRef, ReactElement, useEffect, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { containerMaxWidth, ltBlueGrey_8 } from '../../../themes/globalConstants';
import { Activity } from '../../../utility/auth/useSecurity';
import {
    buildSavedFormArray,
    creditAppBuilder,
    defaultCreditFormState,
    redirectToNotFoundPage,
    searchErrorTree
} from '../../../utility/helpers/onboardHelpers';
import { enUS } from '../../../utility/translations/locales';
import HoverNavMenu from '../../reusable/molecules/HoverNavMenu';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import AdditionComments from './components/AdditionalComments';
import BillingAddress from './components/BillingAddress';
import CompanyInformationSection from './components/CompanyInformation';
import CreditApplicationHeader from './components/CreditApplicationHeader';
import CurrentFinancialStatement from './components/CurrentFinancialStatement';
import SupplierReferences from './components/SupplierReferences';
import CancelModal from '../../reusable/molecules/CancelModal';
import {
    CreditApplication,
    OnboardingDashboardState
} from '../../../store/reducers/onboarding-dashboard';
import { useTypedSelector } from '../../../store/reducers/reducer';
import OnboardingService, {
    OnboardingStepState
} from '../../../utility/services/onboarding-service';
import {
    ONBOARDING_CREDITAPP_DRAFT_SAVED,
    ONBOARDING_CREDITAPP_ERROR,
    ONBOARDING_CREDITAPP_SUBMIT_SUCCESS
} from '../../../store/actions/action-types';
import { useDispatch } from 'react-redux';
import SingleActionModal from '../../reusable/molecules/SingleActionModal';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { GraphicIntakeState } from '../../../store/reducers/graphic-intake';
import { appInsights } from '../../../utility/AppInsights';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { resetFile } from '../../../store/actions/graphic-intake';
import NotFoundPage from '../NotFound/NotFoundPage';
import ErrorAlert from '../../reusable/atoms/ErrorAlert';

const useStyles = makeStyles((theme) => ({
    paper: {
        boxShadow: `0 3px 6px 0 ${ltBlueGrey_8}`,
        borderRadius: 4,
        padding: '2em',
        marginRight: '2em',
        position: 'sticky',
        top: '5em'
    },
    container: {
        padding: '3em'
    },
    formSection: {
        marginBottom: '2.5em'
    },
    formSectionContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '0.5em'
    },
    error: {
        color: theme.palette.error.main,
        marginTop: '0.625em'
    },
    buttonContainer: {
        padding: '3em',
        maxWidth: containerMaxWidth
    },
    actionBtn: {
        height: '3.375em',
        width: '13.75em',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom',
        transition: 'all linear 150ms'
    }
}));

export default function CreditApplicationPage(): ReactElement {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [submitClicked, setSubmitClicked] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
    const { accessToken, userInfo } = useTypedSelector((state) => state.auth);
    const { creditApplication, accountStatus } = useTypedSelector<OnboardingDashboardState>(
        (state) => state.onboardingDashboardState
    );
    const { regionCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    // re-using graphics state so here "image" is the zip file
    const { images } = useTypedSelector<GraphicIntakeState>((state) => state.graphicIntake);
    const [openSaveModal, setOpenSaveModal] = useState<boolean>(false);
    const methods = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        defaultValues: creditApplication?.creditApplicationForm?.companyInformation?.headsOfBusiness
            ?.length
            ? buildSavedFormArray(
                  creditApplication.creditApplicationForm.companyInformation.headsOfBusiness
              )
            : defaultCreditFormState
    });

    const { dirty, isValid } = methods.formState;

    const onBackAfterSave = () => {
        history.push('/onboard');
    };

    const onSaveModalClose = () => {
        setOpenSaveModal(false);
    };

    const sections = [
        {
            header: t('creditApplicationTitle', enUS.creditApplicationTitle),
            icon: false
        },
        {
            header: t('billingAddress', enUS.billingAddress),
            icon: false
        },
        {
            header: t('companyInformation', enUS.companyInformation),
            icon: false
        },
        {
            header: t('supplierReferences', enUS.supplierReferences),
            icon: false
        },
        {
            header: t('additionalCommentsRequired', enUS.additionalCommentsRequired),
            icon: false
        },
        {
            header: t('currentFinancialStatement', enUS.currentFinancialStatement),
            icon: false
        }
    ];
    const sectionRefs = sections.map(() => createRef<HTMLElement>());

    const formSections = [
        {
            section: (
                <CreditApplicationHeader creditAppData={creditApplication.creditApplicationForm} />
            )
        },
        {
            section: (
                <BillingAddress
                    billingAddress={creditApplication.creditApplicationForm?.billingAddress}
                    actualLocation={creditApplication.creditApplicationForm?.actualLocation}
                />
            )
        },
        {
            section: (
                <CompanyInformationSection
                    companyInfo={creditApplication.creditApplicationForm?.companyInformation}
                />
            )
        },
        {
            section: (
                <SupplierReferences
                    supplierReferences={creditApplication.creditApplicationForm?.supplierReferences}
                />
            )
        },
        {
            section: (
                <AdditionComments
                    additionalComments={creditApplication.creditApplicationForm?.additionalComments}
                />
            )
        },
        {
            section: (
                <CurrentFinancialStatement
                    fileExplanation={creditApplication.creditApplicationForm?.fileExplanation}
                />
            )
        }
    ];

    // ensure the graphics file is clear to avoid weird state bugs
    useEffect(() => {
        dispatch(resetFile());
    }, []);

    useEffect(() => {
        if (success) {
            history.push('/onboard');
        }
    }, [success]);

    const onSubmit = (data) => {
        setIsSubmitting(true);

        const creditForm = data as CreditApplication;
        if (!images?.[0] && !creditForm.fileExplanation) {
            setIsSubmitting(false);
            setErrorMessage(
                t(
                    'missingFileAndExplanation',
                    'Required to upload zip file or provide an explanation'
                )
            );
            return;
        }

        creditForm.accountId = accountStatus.accountId;
        creditForm.id = creditApplication?.creditApplicationForm?.id;
        creditForm.hasSameRealAddress = data.hasSameRealAddress === 'true';
        creditForm.companyInformation!.headsOfBusiness = data.headsOfBusiness;

        if (creditForm.hasSameRealAddress) {
            creditForm.actualLocation = creditForm.billingAddress;
        }

        OnboardingService.saveCreditApplicationForm(
            creditForm,
            accessToken,
            OnboardingStepState.COMPLETE,
            regionCode
        )
            .then((response) => {
                const submittedForm = response.data as CreditApplication;
                dispatch({
                    type: ONBOARDING_CREDITAPP_SUBMIT_SUCCESS,
                    creditApplicationForm: submittedForm
                });

                if (!!images?.[0]) {
                    OnboardingService.submitCreditAppFile(submittedForm.id!, images[0], accessToken)
                        .then(() => {
                            setSuccess(true);
                        })
                        .catch((error) => {
                            setErrorMessage(error.message);
                            if (appInsights && userInfo) {
                                const appInsightsError =
                                    error.message +
                                    ' submitCreditApplicationFile-' +
                                    userInfo.email;
                                appInsights.trackException({
                                    error: new Error(appInsightsError),
                                    severityLevel: SeverityLevel.Error
                                });
                            }
                        });
                } else {
                    setSuccess(true);
                }
            })
            .catch((error) => {
                setErrorMessage(t('formSubmissionError', 'Submission Error'));
                dispatch({
                    type: ONBOARDING_CREDITAPP_ERROR,
                    error: error
                });
                if (appInsights && userInfo) {
                    const appInsightsError =
                        error.message + ' submitCreditApplication-' + userInfo.email;
                    appInsights.trackException({
                        error: new Error(appInsightsError),
                        severityLevel: SeverityLevel.Error
                    });
                }
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const onSaveDraft = () => {
        setIsSubmitting(true);

        let foundPatternError = false;

        for (let key of Object.keys(methods.errors)) {
            const error = methods.errors[key];
            if (searchErrorTree(error, 0, 3, ['pattern'])) {
                foundPatternError = true;
            } else {
                methods.clearError(key);
            }
        }

        if (foundPatternError) {
            setErrorMessage(t('invalidDataMessage', 'Invalid Data Error'));
            setIsSubmitting(false);
            return;
        }

        const data = methods.getValues();

        const creditAppForm = creditAppBuilder(data);

        creditAppForm.accountId = accountStatus.accountId;
        creditAppForm.id = creditApplication?.creditApplicationForm?.id;

        OnboardingService.saveCreditApplicationForm(
            creditAppForm,
            accessToken,
            OnboardingStepState.DRAFT,
            regionCode
        )
            .then((response) => {
                dispatch({
                    type: ONBOARDING_CREDITAPP_DRAFT_SAVED,
                    creditApplicationForm: response.data
                });
                setOpenSaveModal(true);
            })
            .catch((error) => {
                setErrorMessage(t('formSubmissionError', 'Submission Error'));
                dispatch({
                    type: ONBOARDING_CREDITAPP_ERROR,
                    error: error
                });
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const handleConfirmCancel = () => {
        history.push('/onboard');
    };

    const handleCloseCancelModal = () => {
        setOpenCancelModal(false);
    };

    const handleOpenCancelModal = () => {
        setOpenCancelModal(true);
    };
    const handleSubmitCapture = () => {
        setSubmitClicked(true);
        methods.triggerValidation();
        if (!isValid) {
            setErrorMessage(
                t(
                    'fillOutRequiredFields',
                    'All required fields must be completed prior to submitting.'
                )
            );
        }
    };

    const footerActions = (
        <>
            <Grid container item xs={2}>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="cancel-button"
                    onClick={handleOpenCancelModal}
                    className={classes.actionBtn}
                >
                    <Trans i18nKey="cancel">Cancel</Trans>
                </Button>
            </Grid>
            <Grid container item xs={2}>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="draft-button"
                    onClick={onSaveDraft}
                    className={classes.actionBtn}
                    disabled={isSubmitting || !dirty}
                >
                    <Trans i18nKey="saveProgress">Save Progress</Trans>
                </Button>
            </Grid>
            <Grid container item xs={6} alignItems="center" justify="center">
                <ErrorAlert
                    errorMessage={errorMessage}
                    showError={submitClicked && errorMessage !== ''}
                    dataTestId="validation-error"
                ></ErrorAlert>
            </Grid>
            <Grid container item xs={2}>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    data-testid="submit-button"
                    className={classes.actionBtn}
                    disabled={isSubmitting}
                    onClickCapture={handleSubmitCapture}
                    onClick={methods.handleSubmit(onSubmit)}
                >
                    <Trans i18nKey="submit">Submit</Trans>
                </Button>
            </Grid>
        </>
    );

    if (redirectToNotFoundPage(accountStatus)) {
        return <NotFoundPage />;
    }

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('accountSetup', 'Account Setup'),
                description: '',
                thinBanner: true
            }}
            activity={Activity.CreditApplication}
            actionFooter={{
                footerAction: footerActions,
                justify: 'space-between',
                sticky: true
            }}
            shipTos={false}
        >
            <FormContext {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
                    <Grid container data-testid="graphics-intake-page">
                        <Container className={classes.container}>
                            <Grid container item xs={12}>
                                <Grid item sm={4}>
                                    <Hidden xsDown>
                                        <Paper className={classes.paper}>
                                            <HoverNavMenu
                                                readOnly={false}
                                                sections={sections
                                                    .map((section, index) => ({
                                                        ref: sectionRefs[index],
                                                        header: section.header,
                                                        icon: section.icon
                                                    }))
                                                    .filter((section) => section.header)}
                                            />
                                        </Paper>
                                    </Hidden>
                                </Grid>
                                <Grid
                                    container
                                    item
                                    xs={8}
                                    className={classes.formSectionContainer}
                                >
                                    {formSections.map((form, index) => {
                                        return (
                                            <section ref={sectionRefs[index]} key={index}>
                                                <Grid item xs={12} className={classes.formSection}>
                                                    {form.section}
                                                </Grid>
                                            </section>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        </Container>
                        <Container>
                            {isSubmitting && (
                                <Grid container item xs={12} className={classes.spinningLoader}>
                                    <CircularProgress />
                                </Grid>
                            )}
                        </Container>
                    </Grid>
                </form>
            </FormContext>
            <CancelModal
                onConfirmCancel={handleConfirmCancel}
                onClose={handleCloseCancelModal}
                open={openCancelModal}
            />
            <SingleActionModal
                title={<Trans i18nKey="saveProgress">{enUS.saveProgress}</Trans>}
                open={openSaveModal}
                subheader={
                    <Trans i18nKey="saveProgressSecondaryText">
                        {enUS.saveProgressSecondaryText}
                    </Trans>
                }
                actionButtonText={
                    <Trans i18nKey="returnToDashboard">{enUS.returnToDashboard}</Trans>
                }
                onAction={onBackAfterSave}
                onClose={onSaveModalClose}
            />
        </ProcessingPageTemplate>
    );
}
