import React from 'react';
import { Typography, TableRow, TableCell } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { OrderProductTableRow } from '../../../store/reducers/orders-dashboard';
import StandardTable from './StandardTable';
import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../utility/services/translation-service';

interface Props {
    product: OrderProductTableRow;
}

const DeactivateProductModal = (props: Props) => {
    useTranslation();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const cols = [
        createColumn('productSku', {
            dataType: ColumnDataType.String,
            label: TranslationService.getTranslatedText(cultureCode, 'ballProductId')
        }),
        createColumn('graphicId', {
            dataType: ColumnDataType.String,
            label: TranslationService.getTranslatedText(cultureCode, 'graphicID')
        }),
        createColumn('description', {
            dataType: ColumnDataType.String,
            label: TranslationService.getTranslatedText(cultureCode, 'productDescription')
        })
    ];

    const ProductRow = ({ row }: { row: OrderProductTableRow }) => {
        if (row) {
            return (
                <TableRow>
                    <TableCell data-testid={'ball-product-id'}>
                        <Typography display="block">{row.productSku}</Typography>
                    </TableCell>
                    <TableCell data-testid={'graphic-id'}>
                        <Typography display="block">{row.graphicId}</Typography>
                    </TableCell>
                    <TableCell data-testid={'description-id'}>
                        <Typography display="block">{row.description}</Typography>
                    </TableCell>
                </TableRow>
            );
        } else {
            return null;
        }
    };

    return (
        <StandardTable
            gridName="Product Summary"
            columns={cols}
            lines={[props.product]}
            rowComponent={ProductRow}
            columnWidths={[8, 6, 16]}
            searchText={false}
            enablePagination={false}
            hideToolbar={true}
            testId="product-modal-static-datatable"
            className="modal-table"
        />
    );
};

export default DeactivateProductModal;
