import React, { useState } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import Link from '../../../reusable/atoms/Link';
import { Trans, useTranslation } from 'react-i18next';
import { ProductionOrder } from '../../../../store/reducers/makeit-dashboard';
import { medium, regularWeight, ballBlue } from '../../../../themes/globalConstants';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import Modal from '../../../reusable/molecules/Modal';
import ProductionOrderPDF from './ProductionOrderPDF';
import {
    LinkedDeliveryOrder,
    ProductionOrderLine,
    ProductionOrderLineItem
} from '../../../../store/reducers/makeit-dashboard';

interface Props {
    shipToName: string;
    shipToAddress: string;
    order: ProductionOrder | undefined;
    lines?: ProductionOrderLine[];
    linkedDeliveryOrder?: LinkedDeliveryOrder;
    showCustomerPO: boolean;
}

const useStyles = makeStyles((theme) => ({
    label: {
        textTransform: 'uppercase',
        fontSize: medium,
        paddingTop: '0.5em'
    },
    link: {
        fontSize: medium,
        fontWeight: regularWeight,
        width: '8.75em',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    icon: {
        color: ballBlue
    }
}));

const ProductionOrderShareLinks = (props: Props) => {
    const classes = useStyles();
    const [openPdf, setOpenPdf] = useState<boolean>(false);
    const { t } = useTranslation();

    const printForm = () => {
        setOpenPdf(true);
    };

    const onClose = () => {
        setOpenPdf(false);
    };

    useTranslation();

    return (
        <Grid container item xs={12} spacing={2} alignItems="center" justify="flex-end">
            <Grid item>
                <Typography variant="h6" className={classes.label}>
                    <Trans i18nKey="share">share</Trans>
                </Typography>
            </Grid>
            <Grid item>
                <Link
                    className={classes.link}
                    underline="always"
                    data-testid="print-btn"
                    onClick={printForm}
                >
                    <Trans i18nKey="print">Print</Trans>
                </Link>
                <PrintOutlinedIcon fontSize="small" className={classes.icon} />
            </Grid>
            <Modal
                open={openPdf}
                title={t('productionOrderDetails', 'Production Order Details')}
                close={onClose}
                closeIcon={true}
                fullScreen={true}
            >
                <ProductionOrderPDF
                    shipToAddress={props.shipToAddress}
                    shipToName={props.shipToName}
                    order={props.order}
                    title={t('productionOrderDetails', 'Production Order Details')}
                    lines={props.lines as ProductionOrderLineItem[]}
                    linkedDeliveryOrder={props.linkedDeliveryOrder}
                    showCustomerPO={props.showCustomerPO}
                />
            </Modal>
        </Grid>
    );
};

export default ProductionOrderShareLinks;
