import React, { useState, useEffect } from 'react';
import ScrapItSummaryColumns from './ScrapItSummaryColumns';
import ScrapItSummaryRow from './ScrapItSummaryRow';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import StandardTable from '../../../reusable/molecules/StandardTable';
import { ScrapItRequest } from '../../../../store/reducers/scrap-it';

interface Props {
    scrapItRequests: ScrapItRequest[];
}

export default function ScrapItSummaryTable({ scrapItRequests }: Props) {
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const [columns, setColumns] = useState<any>([]);

    useEffect(() => {
        setColumns(ScrapItSummaryColumns.getColumns(cultureCode));
    }, [cultureCode]);

    const ScrapItRequestsLine = ({ row }: { row: ScrapItRequest }) => {
        if (row) {
            return <ScrapItSummaryRow key={row.requestOrderNumber} line={row as ScrapItRequest} />;
        } else {
            return null;
        }
    };

    return (
        <StandardTable
            gridName="Scrap It Summary"
            columns={columns}
            columnWidths={[25, 25, 25, 25]}
            lines={scrapItRequests}
            rowComponent={ScrapItRequestsLine}
            itemsPerPage={10}
        />
    );
}
