import React from 'react';
import PdfPageTemplate from '../../../templates/PdfPageTemplate';
import { Document, Page, Text, View, Image, BlobProvider } from '@react-pdf/renderer';
import pdfStyles from '../../../../themes/pdfStyles';
import { Graphics } from '../../../../store/reducers/graphic-intake';
import { isIE } from '../../../../utility/helpers/user-helpers';
import { useTranslation, Trans } from 'react-i18next';
import { requestTypeFormatter } from '../../../../utility/helpers/graphic-helpers';
interface Props {
    title: string;
    graphics: Graphics;
    sourceLogo: string;
    ballLogo: string;
}

const GraphicsColorMatchingPDF = ({ title, graphics, sourceLogo, ballLogo }: Props) => {
    useTranslation();
    const myDoc = (
        <Document title={title}>
            <Page size="A4" style={pdfStyles.page} orientation={'portrait'}>
                <View style={pdfStyles.logoSection}>
                    <Image src={sourceLogo} style={pdfStyles.sourceLogo} />
                    <Image src={ballLogo} style={pdfStyles.ballLogo} />
                </View>
                <View style={pdfStyles.divider6} fixed />

                <View style={[pdfStyles.flexSection, pdfStyles.pageHeader]}>
                    <View>
                        <Text style={pdfStyles.header5}>
                            <Trans i18nKey="colorMatch">COLOR MATCH</Trans>
                        </Text>
                    </View>
                    <View>
                        <View>
                            <Text style={pdfStyles.fieldLabel}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="companyName">Company Name</Trans>
                                    {' / '}
                                    <Trans i18nKey="companyId">Company ID</Trans>
                                </Text>
                            </Text>
                        </View>
                        <View>
                            <Text style={pdfStyles.subtitle6}>
                                {graphics.customerName}
                                {' / '}
                                {graphics.customerAccount}
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={pdfStyles.divider6} fixed />

                <View style={pdfStyles.borderlessTable}>
                    <View style={pdfStyles.tableRow}>
                        <View style={pdfStyles.borderLessTableCol}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="submissionName">SUBMISSION NAME</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableCol}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="submissionType">SUBMISSION TYPE</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableCol}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="dateCode">DATE CODE MATCHING</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableCol}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="colorMatchingDetails">COLOR MATCHING DETAILS</Trans>
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.divider7} />
                    <View style={pdfStyles.tableRow}>
                        <View style={pdfStyles.borderLessTableCol}>
                            <Text style={pdfStyles.subtitle3}>{graphics.requestName}</Text>
                        </View>
                        <View style={pdfStyles.borderLessTableCol}>
                            <Text style={pdfStyles.subtitle3}>
                                <Trans i18nKey={requestTypeFormatter(graphics.requestType)}>
                                    {graphics.requestType}
                                </Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableCol}>
                            <Text style={pdfStyles.subtitle3}>
                                {graphics.dateCode !== '' ? graphics.dateCode : 'N/A'}
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableCol}>
                            <Text style={pdfStyles.subtitle3}>{graphics.colorComment}</Text>
                        </View>
                    </View>
                    <View style={pdfStyles.divider7} />
                </View>
                <View style={pdfStyles.borderlessTable}>
                    <View style={pdfStyles.tableRow}>
                        <View style={pdfStyles.borderLessTableCol}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="colorMatchNote">NOTE</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableCol}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="shippingAddress">Shipping Address</Trans>
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                        <View style={pdfStyles.borderLessTableCol}>
                            <Text style={pdfStyles.subtitle3}>
                                <Trans i18nKey="colorMatchNoteText">
                                    Please include this page along with your color matching object.
                                </Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColWide}>
                            <Text style={pdfStyles.subtitle3}>{'BALL CORP/BTIC'}</Text>
                            <Text style={pdfStyles.subtitle3}>{'ATTN: BALL GRAPHICS DEPT.'}</Text>
                            <Text style={pdfStyles.subtitle3}>{'9343 W. 108TH CIR.'}</Text>
                            <Text style={pdfStyles.subtitle3}>{'BROOMFIELD, CO 80021'}</Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );

    if (!isIE()) {
        return <PdfPageTemplate title={title}>{myDoc}</PdfPageTemplate>;
    } else {
        return (
            <BlobProvider document={myDoc}>
                {({ blob, url, loading, error }) => {
                    if (blob && window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob);
                    }
                    return null;
                }}
            </BlobProvider>
        );
    }
};
export default GraphicsColorMatchingPDF;
