import { Grid, makeStyles, Typography } from '@material-ui/core';
import React, { ReactElement, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setFileSection } from '../../../../store/root-actions';
import { boldWeight, medium, paleGrey, red, xl } from '../../../../themes/globalConstants';
import { enUS } from '../../../../utility/translations/locales';
import FileUpload from '../../../reusable/molecules/FileUpload';
import FormSection from '../../../reusable/molecules/FormSection';
import TextInput from '../../../reusable/molecules/TextInput';

interface Props {
    fileExplanation?: string;
}

const useStyles = makeStyles(() => ({
    errorText: {
        color: red
    },
    inputLine: {
        marginBottom: '1em'
    },
    explanationHelper: {
        marginBottom: '0.5em'
    },
    formSectionWrapper: {
        padding: '8px 8px 0px 8px'
    }
}));

const uploadStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: paleGrey,
        height: '11.5em',
        justifyContent: 'center',
        display: 'flex',
        cursor: 'pointer'
    },
    divContainer: {
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%'
    },
    invalidText: {
        color: 'red'
    },
    icon: {
        height: '1.875em',
        width: '1.4em',
        fill: theme.palette.secondary.main
    },
    text: {
        fontSize: xl,
        fontWeight: boldWeight,
        letterSpacing: '0.13px'
    },
    fileLabel: {
        fontSize: medium,
        marginTop: '1em',
        fontWeight: boldWeight
    },
    fileName: {
        marginTop: '1em',
        marginLeft: '0.5em'
    }
}));
export default function CurrentFinancialStatement({ fileExplanation }: Props): ReactElement {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { control, errors, setValue } = useFormContext();
    const [showUploadError, setShowUploadError] = useState<boolean>(false);
    const acceptedFileTypes = 'application/x-zip-compressed, application/zip';

    useEffect(() => {
        if (fileExplanation) {
            setValue('fileExplanation', fileExplanation);
        }
    }, [fileExplanation]);

    const storeFiles = (fileData) => {
        if (!fileData || fileData.length > 0) {
            setShowUploadError(false);
            dispatch(setFileSection(fileData));
        } else {
            setShowUploadError(true);
        }
    };

    return (
        <FormSection
            testId={'credit-app-header-section'}
            sectionHeader={
                <Trans i18nKey="currentFinancialStatement">{enUS.currentFinancialStatement}</Trans>
            }
        >
            <Grid xs={12} className={classes.formSectionWrapper}>
                <Grid xs={12} className={classes.inputLine}>
                    <Typography>
                        <Trans i18nKey="financialStatementHelper">
                            {enUS.financialStatementHelper}
                        </Trans>
                    </Typography>
                </Grid>
                <Grid container xs={12} className={classes.inputLine}>
                    <Grid xs={12}>
                        <FileUpload
                            styles={uploadStyles}
                            fileTypes={acceptedFileTypes}
                            name="requiredDocumentsUpload"
                            testId="required-documents-upload"
                            onUploadAttempt={storeFiles}
                            fileReset={false}
                            invalidMessage={t(
                                'invalidIntakeFile',
                                'This file type is invalid. Please attach a .zip file.'
                            )}
                        />
                    </Grid>
                    {showUploadError && (
                        <Grid
                            item
                            xs={12}
                            className={classes.errorText}
                            data-testid="file-upload-error"
                        >
                            <Typography>
                                <Trans i18nKey="onUploadFileError">
                                    *Please upload a valid zip file
                                </Trans>
                            </Typography>
                        </Grid>
                    )}
                </Grid>
                <Grid xs={12} className={classes.explanationHelper}>
                    <Typography>
                        <Trans i18nKey="explanationHelper">{enUS.explanationHelper}</Trans>
                    </Typography>
                </Grid>
                <Grid xs={12}>
                    <TextInput
                        type="text"
                        label={<Trans i18nKey="explanation">Explanation</Trans>}
                        name="fileExplanation"
                        testId="fileExplanation"
                        control={control}
                        rules={''}
                        error={errors.fileExplanation}
                        errors={errors}
                        inputProps={{
                            'data-testid': 'fileExplanation',
                            maxLength: 255
                        }}
                    />
                </Grid>
            </Grid>
        </FormSection>
    );
}
