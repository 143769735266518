import { makeStyles, Typography, Toolbar, Grid, Button } from '@material-ui/core';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Modal from '../../../reusable/molecules/Modal';
import { large } from '../../../../themes/globalConstants';

const useStyles = makeStyles((theme) => ({
    main: {
        margin: '2em'
    },
    tableWrapper: {
        padding: '1em 2em'
    },
    modalButton: {
        height: 'auto',
        marginTop: '1.875em',
        marginBottom: '1.875em',
        marginLeft: '1em',
        fontSize: large,
        fontWeight: 'normal',
        textTransform: 'capitalize',
        borderRadius: 'unset',
        maxWidth: '85%',
        minWidth: '8em'
    },
    textBlock: {
        padding: '1em 2em'
    },
    textSection: {
        marginTop: '1em'
    },
    titleIcon: {
        marginRight: '0.5em'
    }
}));

const TradeItCampaignModal = ({ open, onCancel, onOk }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const title = t('campaignProductAlert', 'Campaign Product Alert');
    const warningIcon = (
        <img
            className={classes.titleIcon}
            src={process.env.PUBLIC_URL + '/assets/InfoTriangle_Icon.svg'}
            alt="Warning Icon"
        />
    );

    const modalFooterActions = (
        <Toolbar variant="regular">
            <Grid container item xs={12} alignItems="center" justify="flex-end">
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="cancel-btn"
                    className={classes.modalButton}
                    onClick={onCancel}
                >
                    <Trans i18nKey="cancel">Cancel</Trans>
                </Button>
                <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    data-testid="submit-order-btn"
                    className={classes.modalButton}
                    onClick={onOk}
                >
                    <Trans i18nKey="accept">Ok</Trans>
                </Button>
            </Grid>
        </Toolbar>
    );

    const campaignTradeRiskSubstring = (
        <u>
            <Trans i18nKey="campaignTradeRiskEmphasis">
                inventory will not be replenished until the next planned campaign
            </Trans>
        </u>
    );

    return (
        <div className={classes.main}>
            <Modal
                title={title}
                titleIcon={warningIcon}
                open={open}
                close={onCancel}
                closeIcon={true}
                maxWidth="sm"
            >
                <Typography className={classes.textSection}>
                    <div className={classes.textBlock}>
                        <Trans i18nKey="campaignTradeNotification">
                            Please note that there are one or more campaign products included in
                            your request.
                        </Trans>
                    </div>
                    <div className={classes.textBlock}>
                        <Trans i18nKey="campaignTradeRisk">
                            If you make the decision to trade campaign cans causing a premature
                            stock-out, {campaignTradeRiskSubstring}.
                        </Trans>
                    </div>
                    <div className={classes.textBlock}>
                        <Trans i18nKey="campaignQuestionsPrompt">
                            If you have any questions, please reach out to your account
                            representative.
                        </Trans>
                    </div>
                    <div className={classes.textBlock}>
                        <b>
                            <Trans i18nKey="campaignContinuePrompt">Do you wish to continue?</Trans>
                        </b>
                    </div>
                </Typography>
                {modalFooterActions}
            </Modal>
        </div>
    );
};

export default TradeItCampaignModal;
