import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles, Container, Grid, Typography, Box, Divider } from '@material-ui/core';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import {
    boldWeight,
    ltBlueGrey_34,
    paleGrey,
    blackWeight,
    medium,
    ballBlue,
    ballGray,
    large
} from '../../../themes/globalConstants';
import FormSection from '../../reusable/molecules/FormSection';
import Button from '../../reusable/atoms/Button';
import { useHistory } from 'react-router-dom';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { Activity } from '../../../utility/auth/useSecurity';
import { LiquidTestIntakeState } from '../../../store/reducers/liquid-intake';
import Modal from '../../reusable/molecules/Modal';
import LiquidTestIntakeFormPDF from '../../reusable/molecules/LiquidTestIntakeFormPDF';

const useStyles = makeStyles((theme) => ({
    //Constrains the footer to the bottom of the page
    main: {
        minHeight: '90vh',
        '& .MuiPaper-root': {
            '&:not(:first-of-type)': {
                marginTop: '2.75em'
            }
        }
    },
    container: {
        padding: '3em'
    },
    previewWrapper: {
        backgroundColor: paleGrey,
        padding: '1.875em'
    },
    intakeFormText: {
        color: ballGray,
        fontSize: large,
        fontWeight: blackWeight,
        marginBottom: '1em'
    },
    noTestNeededText: {
        color: ballGray,
        fontSize: large,
        fontWeight: boldWeight,
        marginBottom: '3em',
        marginTop: '1em'
    },
    printInstructionsText: {
        fontSize: medium
    },
    divider: {
        backgroundColor: ltBlueGrey_34,
        height: 5,
        margin: '2em 0',
        width: '100%'
    },
    printIntakeBtn: {
        margin: '2em 0 0 0'
    },
    formSectionContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    icon: {
        color: ballBlue,
        fill: ballBlue,
        marginLeft: '1em',
        maxHeight: '2.5em'
    },
    liquidIntakeFormModal: {
        '& .MuiPaper-root': {
            minWidth: '53.750em',
            height: '100%'
        }
    }
}));

const LiquidTestIntakeConfirmation = () => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const [testRequired, setTestRequired] = useState<boolean>(true);
    const [printModalOpen, setPrintModalOpen] = useState<boolean>(false);
    const liquidIntake = useTypedSelector<LiquidTestIntakeState>((state) => state.liquidIntake);
    const graphicsIcon = `${process.env.PUBLIC_URL}/assets/Graphics_Icon.svg`;

    const getTestRequired = () => {
        // A liquid must meet all of the following criteria to have NO test required:
        //   1. Beverage Category of Beer, Beer – Craft, Beer – Domestic, or Beer – Import
        //   2. “Yes” chosen for question “Is this a simple four ingredient beer?”
        //   3. pH greater than or equal to 4.0
        // if (1 && 2 && 3) -> testRequired = false

        // this is being returned from the backend, so we can just use that
        if (liquidIntake.liquidTestIntake && liquidIntake.liquidTestIntake?.testRequired) {
            setTestRequired(liquidIntake.liquidTestIntake?.testRequired);
        }
    };

    const printIntakeFormClicked = () => {
        setPrintModalOpen(true);
    };

    const backToDashboard = () => {
        history.push('/dashboard');
    };

    const onClosePrintModal = () => {
        setPrintModalOpen(false);
    };

    useEffect(() => {
        if (liquidIntake && liquidIntake.liquidTestIntake) {
            getTestRequired();
            if (liquidIntake.testRequired) {
                setPrintModalOpen(true);
            }
        }
    }, [liquidIntake]);

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('liquidTesting', 'Liquid Testing'),
                description: '',
                thinBanner: true
            }}
            activity={Activity.LiquidTestIntakeForm}
            shipTos={false}
        >
            <Grid container data-testid="liquid-test-confirmation" className={classes.main}>
                <Container className={classes.container}>
                    <Grid container item xs={12}>
                        <Grid container item xs={12} className={classes.formSectionContainer}>
                            <Grid item xs={12}>
                                <FormSection
                                    testId={'liquid-test-success'}
                                    sectionHeader={
                                        <Trans i18nKey="liquidTestThankYou">
                                            Thank you for submitting your liquid test information
                                        </Trans>
                                    }
                                >
                                    <Grid item xs={12}>
                                        {!testRequired && (
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h3"
                                                    className={classes.noTestNeededText}
                                                >
                                                    <Trans i18nKey="noLiquidTestNeeded">
                                                        Based on this information, your liquid does
                                                        not need to be submitted for liquid testing.
                                                    </Trans>
                                                </Typography>
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <Box className={classes.previewWrapper}>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        variant="h3"
                                                        className={classes.intakeFormText}
                                                    >
                                                        <Trans i18nKey="liquidTestIntakeForm">
                                                            Liquid Test Intake Form
                                                        </Trans>
                                                        <img
                                                            className={classes.icon}
                                                            src={graphicsIcon}
                                                            alt="graphics-icon"
                                                        />
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        variant="body1"
                                                        className={classes.printInstructionsText}
                                                    >
                                                        {testRequired ? (
                                                            <Trans i18nKey="liquidTestIntakeFormPrintInstructions">
                                                                If you did not print the previously
                                                                displayed form, please print here
                                                                and include it when you mail your
                                                                liquid sample. You may also print or
                                                                download a copy for your records.
                                                            </Trans>
                                                        ) : (
                                                            <Trans i18nKey="liquidTestIntakeFormPrintInstructionsNoTest">
                                                                You may print or download your
                                                                intake form for your records here.
                                                            </Trans>
                                                        )}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        data-testid="print-download-button"
                                                        className={classes.printIntakeBtn}
                                                        onClick={printIntakeFormClicked}
                                                    >
                                                        <Trans i18nKey="printIntakeForm">
                                                            Print Intake Form
                                                        </Trans>
                                                    </Button>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider className={classes.divider} />
                                    </Grid>
                                    <Grid container item xs={12}>
                                        <Grid item xs={6}>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                data-testid="dashboard-button"
                                                onClick={backToDashboard}
                                            >
                                                <Trans i18nKey="backToDashboard">
                                                    Back To My Dashboard
                                                </Trans>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </FormSection>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <>
                <Modal
                    open={printModalOpen}
                    title={
                        <Trans i18nKey="printLiquidTestIntake">Print Liquid Test Intake Form</Trans>
                    }
                    close={onClosePrintModal}
                    closeIcon={true}
                    className={classes.liquidIntakeFormModal}
                >
                    {liquidIntake?.liquidTestIntake && (
                        <LiquidTestIntakeFormPDF
                            liquidIntake={liquidIntake.liquidTestIntake}
                            testRequired={testRequired}
                        />
                    )}
                </Modal>
            </>
        </ProcessingPageTemplate>
    );
};

export default LiquidTestIntakeConfirmation;
