import React, { ReactNode } from 'react';

export interface ForecastState {
    activeTab: 'forecasts' | 'previousForecasts';
}

export interface ProviderProps {
    children: ReactNode;
    value: ForecastState;
}

const PlanItSummaryContext = React.createContext<ForecastState>({} as ForecastState);

function PlanItSummaryProvider(props: ProviderProps) {
    const { children, value } = props;
    return (
        <PlanItSummaryContext.Provider value={value}> {children} </PlanItSummaryContext.Provider>
    );
}

const usePlanItSummaryContext = () => React.useContext(PlanItSummaryContext);

export { PlanItSummaryProvider, usePlanItSummaryContext };
