import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Trans } from 'react-i18next';
import ConfirmationModal from '../../../reusable/molecules/ConfirmationModal';
import { enUS } from '../../../../utility/translations/locales/en-US/en-US';

interface Props {
    openDisableUserModal: boolean;
    onClose: () => void;
    handleDisableUser: () => void;
}

const useStyles = makeStyles((theme) => ({
    modalDescription: {
        display: 'inline-block',
        marginTop: '1em'
    }
}));

export default function UserAdminDisableModal({
    openDisableUserModal,
    onClose,
    handleDisableUser
}: Props) {
    const classes = useStyles();

    return (
        <ConfirmationModal
            logo={false}
            title={<Trans i18nKey="disableUser">{enUS.disableUser}</Trans>}
            subheader={<Trans i18nKey="disableUserWarning">{enUS.disableUserWarning}</Trans>}
            description={
                <span className={classes.modalDescription}>
                    <Trans i18nKey="disableUserContinue">{enUS.disableUserContinue}</Trans>
                </span>
            }
            open={openDisableUserModal}
            continueText={<Trans i18nKey="yesDisable">{enUS.yesDisable}</Trans>}
            cancelText={<Trans i18nKey="cancel">{enUS.cancel}</Trans>}
            saveProgress={handleDisableUser}
            onClose={onClose}
            onCancel={onClose}
        />
    );
}
