import React, { useState } from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import Link from '../../../reusable/atoms/Link';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { ballBlue, medium, regularWeight } from '../../../../themes/globalConstants';
import Modal from '../../../reusable/molecules/Modal';
import ConfirmationPDF from './ConfirmationPDF';
import { CSVLink } from 'react-csv';
import ReactPdf from '@react-pdf/renderer';
import {
    ProductionOrderLine,
    ProductCountInformation
} from '../../../../store/reducers/makeit-dashboard';
import {
    constructCanDescription,
    constructEndDescription,
    getGraphicIdAndVersion,
    isProductCanType
} from '../../../../utility/helpers/order-helpers';
import { OrderProductTableRow } from '../../../../store/reducers/orders-dashboard';
import { getUtcMidnight } from '../../../../utility/helpers/date-helpers';

interface Props {
    dateSubmitted: string;
    customerPo: string;
    orderList: ProductionOrderLine[];
    countInformation: ProductCountInformation;
    shipToAddress: string;
    shipToName: string;
    shipToId: string;
}

const useStyles = makeStyles((theme) => ({
    label: {
        textTransform: 'uppercase',
        fontSize: medium
    },
    linkContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    link: {
        fontSize: medium,
        fontWeight: regularWeight,
        width: '8.75em',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    icon: {
        color: ballBlue
    }
}));

// CSVLink doesn't make good use of themes
const pdfStyles = ReactPdf.StyleSheet.create({
    link: {
        fontSize: medium,
        fontWeight: regularWeight,
        maxWidth: '8.75em'
    }
});

const ShareLinks = (props: Props) => {
    const classes = useStyles();
    const [openPdf, setOpenPdf] = useState(false);
    const { t } = useTranslation();

    const printForm = () => {
        setOpenPdf(true);
    };

    const onClose = () => {
        setOpenPdf(false);
    };

    interface CSVRow {
        customerPoNumber: string;
        shipToId: string;
        productId?: string;
        graphicID?: string;
        name: string;
        productDescription: string;
        coating?: string;
        quantityPL?: number;
        quantityEA?: number;
        requestedDate?: string;
    }

    const csvHeaders = [
        { label: 'CUSTOMER PO NUMBER', key: 'customerPoNumber' },
        { label: 'SHIP TO ID', key: 'shipToId' },
        { label: 'PRODUCT ID', key: 'productId' },
        { label: 'GRAPHIC ID', key: 'graphicID' },
        { label: 'PRODUCT NAME', key: 'name' },
        { label: 'PRODUCT DESCRIPTION CHARACTERISTICS', key: 'productDescription' },
        { label: 'COATING', key: 'coating' },
        { label: 'QUANTITY (PL)', key: 'quantityPL' },
        { label: 'QUANTITY (EA)', key: 'quantityEA' },
        { label: 'REQUESTED DATE', key: 'requestedDate' }
    ];

    const translateCSVHeaders = () => {
        csvHeaders.forEach((header) => {
            header.label = t(header.key, header.label).toUpperCase();
        });
        return csvHeaders;
    };

    const csvArray = () => {
        return props.orderList.map((order) => {
            const requestedDate = getUtcMidnight(order.requestedDate).format('MM/DD/YYYY');
            const description = isProductCanType(order.type)
                ? constructCanDescription(order as OrderProductTableRow)
                : constructEndDescription(order as OrderProductTableRow);

            const row: CSVRow = {
                customerPoNumber: props.customerPo,
                shipToId: props.shipToId,
                productId: order.displayId,
                graphicID: getGraphicIdAndVersion(order.type, order.graphicIdAndVersion),
                name: order.displayName ? order.displayName : '',
                productDescription: description,
                coating: order.coating,
                quantityPL: order.palletQuantity ?? undefined,
                quantityEA:
                    order.palletQuantity && order.quantityPerPallet
                        ? order.palletQuantity * order.quantityPerPallet
                        : undefined,
                requestedDate: requestedDate
            };
            return row;
        });
    };

    const csvContents = csvArray();

    return (
        <Grid container item xs={12} spacing={1} alignItems="center" justify="flex-end">
            <Grid item>
                <Typography variant="h6" className={classes.label}>
                    <Trans i18nKey="share">Share</Trans>
                </Typography>
            </Grid>
            <Grid item className={classes.linkContainer}>
                <Link
                    className={classes.link}
                    underline="always"
                    data-testid="print-btn"
                    onClick={printForm}
                >
                    <Trans i18nKey="print">Print</Trans>
                </Link>
                <PrintOutlinedIcon fontSize="small" className={classes.icon} />
            </Grid>
            <Grid item className={classes.linkContainer}>
                <CSVLink
                    className={
                        'MuiTypography-root MuiLink-root MuiLink-underlineAlways MuiTypography-colorPrimary'
                    }
                    style={pdfStyles.link}
                    underline="always"
                    data-testid="download-btn"
                    headers={translateCSVHeaders()}
                    data={csvContents}
                    filename={`${props.customerPo}.csv`}
                >
                    <Trans i18nKey="downloadCSV">Download .CSV</Trans>
                </CSVLink>
                <GetAppOutlinedIcon fontSize="small" className={classes.icon} />
            </Grid>
            <Modal
                open={openPdf}
                title={t('orderConfirmation', 'Order Confirmation')}
                close={onClose}
                closeIcon={true}
                fullScreen={true}
            >
                <ConfirmationPDF
                    title={t('orderConfirmation', 'Order Confirmation')}
                    dateSubmitted={props.dateSubmitted}
                    customerPo={props.customerPo}
                    orderList={props.orderList}
                    countInformation={props.countInformation}
                    shipToAddress={props.shipToAddress}
                    shipToName={props.shipToName}
                />
            </Modal>
        </Grid>
    );
};

export default ShareLinks;
