import { Grid, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import {
    boldWeight,
    blackWeight,
    ballBlue,
    dateOptionInvalidGray,
    small
} from '../../../../themes/globalConstants';
import TranslationService from '../../../../utility/services/translation-service';
import MyAccountPendingRequestsColumns from './MyAccountPendingRequestsColumns';
import { PendingRequestTableRow } from '../Models/PendingRequestTableRow';
import MyAccountPendingRequestsRow from './MyAccountPendingRequestsRow';
import { MyAccountDashboardState } from '../../../../store/reducers/user-admin';
import { getPendingRequestsRoles } from '../../../../utility/helpers/user-admin-helpers';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import SingleActionModal from '../../../reusable/molecules/SingleActionModal';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: '1em'
    },
    tableWrapper: {
        '& .MuiToolbar-root': {
            padding: 0,
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                },
                '&:first-of-type ': {
                    paddingLeft: 0
                }
            },
            '& > h2': {
                fontSize: '1.5em',
                fontWeight: boldWeight,
                color: theme.palette.secondary.main,
                paddingLeft: '0.625em'
            },
            '& .MuiInputBase-formControl': {
                border: `1px solid ${dateOptionInvalidGray}`,
                borderRadius: '.125em',
                '&:before': {
                    display: 'none'
                },
                '&:after': {
                    display: 'none'
                },
                '& svg': {
                    height: '.875em',
                    width: '.875em',
                    fill: ballBlue
                },
                '& .MuiIconButton-root': {
                    '&:hover': {
                        backgroundColor: 'transparent !important'
                    }
                },
                '& .MuiInput-input': {
                    paddingLeft: '.25em'
                }
            }
        },
        '& .MuiTableSortLabel-root': {
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em',
            width: '99%',
            justifyContent: 'space-between',
            '& .MuiSvgIcon-root': {
                fontSize: 14,
                opacity: '1'
            }
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                '&:nth-child(1)': {
                    width: '30%'
                },
                '&:nth-child(2)': {
                    width: '28%'
                },
                '&:nth-child(3)': {
                    width: '10%'
                },
                '&:nth-child(4)': {
                    width: '12%'
                },
                '&:nth-child(5)': {
                    width: '20%',
                    fontSize: small,
                    fontWeight: blackWeight,
                    textTransform: 'uppercase',
                    color: theme.palette.secondary.main,
                    borderRadius: '0.25em',
                    paddingLeft: '0.5em',
                    justifyContent: 'space-between'
                }
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    }
}));

const MyAccountPendingRequestsTab = () => {
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [columns, setColumns] = useState<any>([]);
    const classes = useStyles();
    useTranslation();
    const history = useHistory();
    const [requestSubmittedOpen, setRequestSubmittedOpen] = useState<boolean>(false);
    const dashboardLink = '/dashboard';
    const { permissionChanges } = useTypedSelector<MyAccountDashboardState>(
        (state) => state.myAccountDashboard
    );
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const [pendingRequestsForTable, setPendingRequestsForTable] = useState<
        PendingRequestTableRow[]
    >([]);

    const handleRequestSubmittedModalOpen = () => {
        setRequestSubmittedOpen(true);
    };

    const handleRequestSubmittedModalClose = () => {
        setRequestSubmittedOpen(false);
    };

    const onDiscardChanges = () => {
        history.push(dashboardLink);
    };

    useEffect(() => {
        if (permissionChanges) {
            const pendingRequests: PendingRequestTableRow[] = [];
            permissionChanges.forEach((permissionChange) => {
                let pendingRequest: PendingRequestTableRow = {
                    requestId: permissionChange.permissionChangeId,
                    accountId: permissionChange.accountId,
                    location: `${permissionChange.accountId} / ${permissionChange.accountCity}, ${permissionChange.accountStateProvince}`,
                    roles: getPendingRequestsRoles(permissionChange.personas, cultureCode),
                    dateSubmitted: moment(permissionChange.createDate).format('MM/DD/YYYY'),
                    latestSubmissionDate: permissionChange.lastSubmittedDate
                        ? moment(permissionChange.lastSubmittedDate).format('MM/DD/YYYY')
                        : '-'
                };
                pendingRequests.push(pendingRequest);
            });
            setPendingRequestsForTable(
                pendingRequests.sort((a, b) => a.accountId.localeCompare(b.accountId))
            );
            setIsLoaded(true);
        }
    }, [permissionChanges]);

    const options = new ToolbarOptions({
        title: TranslationService.getTranslatedText(cultureCode, 'pendingAccessRequests'),
        advancePagination: false,
        exportButton: false,
        printButton: false,
        searchText: true
    });

    const PendingRequestTableItem = ({ row }: { row: Partial<PendingRequestTableRow> }) => {
        if (row) {
            return (
                <MyAccountPendingRequestsRow
                    request={row as PendingRequestTableRow}
                    key={row.requestId!}
                    handleRequestSubmittedSuccess={handleRequestSubmittedModalOpen}
                />
            );
        } else {
            return null;
        }
    };

    useEffect(() => {
        setColumns(MyAccountPendingRequestsColumns.getPendingRequestsColumns(cultureCode));
    }, [cultureCode]);

    return (
        <Paper elevation={2} className={classes.paper}>
            <Grid className={classes.tableWrapper}>
                {pendingRequestsForTable && isLoaded && (
                    <DataGrid
                        gridName="pendingRequests"
                        columns={columns}
                        dataSource={pendingRequestsForTable}
                        rowComponent={PendingRequestTableItem}
                        toolbarOptions={options}
                    ></DataGrid>
                )}
            </Grid>
            <SingleActionModal
                title={<Trans i18nKey="accessRequest">Access request</Trans>}
                open={requestSubmittedOpen}
                subheader={
                    <Trans i18nKey="requestAccessSuccess">
                        Your access request has been successfully submitted and will be reviewed by
                        the corresponding User Administrator(s).
                    </Trans>
                }
                actionButtonText={<Trans i18nKey="myDashboard">My Dashboard</Trans>}
                onAction={onDiscardChanges}
                onClose={handleRequestSubmittedModalClose}
            />
        </Paper>
    );
};

export default MyAccountPendingRequestsTab;
