import React from 'react';
import Link from '../../../reusable/atoms/Link';
import { Link as ILink } from '../../../../store/reducers/content-page';
import { Link as RouterLink } from 'react-router-dom';
import ContactUsLink from '../../../reusable/molecules/ContactUsLink';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
    link?: ILink | null;
}

const useStyles = makeStyles((theme) => ({
    contactUsLink: {
        color: theme.palette.primary.main,
        fontWeight: 500,
        letterSpacing: 0.36,
        underline: 'none'
    }
}));

function processLinkUrl(link: ILink) {
    const linkIsFullyQualified =
        link.linkUrl?.includes('http://') || link.linkUrl?.includes('https://');
    const linkUrl =
        linkIsFullyQualified || !link.linkUrl ? link.linkUrl : '/content' + link.linkUrl;

    return { linkUrl, linkIsFullyQualified };
}

export default function ContentLink({ link }: Props) {
    const classes = useStyles();

    if (link?.action === 'Link') {
        const { linkUrl, linkIsFullyQualified } = processLinkUrl(link);

        if (linkIsFullyQualified)
            return (
                <Link
                    underline="none"
                    url={linkUrl}
                    target="_blank"
                    rel="noreferrer noopener"
                    data-testid={'content-link'}
                >
                    {link.linkText}
                </Link>
            );
        else
            return (
                <Link
                    underline="none"
                    component={RouterLink}
                    to={linkUrl}
                    data-testid={'content-link'}
                >
                    {link.linkText}
                </Link>
            );
    }

    if (link?.action === 'Download') {
        return (
            <Link
                underline="none"
                url={link.linkUrl}
                target="_blank"
                rel="noreferrer noopener"
                data-testid={'download-link'}
            >
                {link.linkText}
            </Link>
        );
    }

    if (link?.action === 'AppLink') {
        if (link.linkUrl === '/contact-us') {
            return <ContactUsLink className={classes.contactUsLink} />;
        } else {
            return (
                <Link
                    underline="none"
                    component={RouterLink}
                    to={link.linkUrl}
                    data-testid={'app-link'}
                >
                    {link.linkText}
                </Link>
            );
        }
    }

    return null;
}
