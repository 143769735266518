import React, { useState } from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import Link from '../../../reusable/atoms/Link';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import { ballBlue, medium, regularWeight } from '../../../../themes/globalConstants';
import Modal from '../../../reusable/molecules/Modal';
import { DeliveryOrder } from '../../../../store/reducers/shipping-dashboard';
import CancelDeliveryOrderConfirmationPDF from './CancelDeliveryOrderConfirmationPDF';

interface Props {
    dateSubmitted: string;
    deliveryOrder: DeliveryOrder;
    shipToAddress: string;
    shipToName: string;
}

const useStyles = makeStyles((theme) => ({
    label: {
        textTransform: 'uppercase',
        fontSize: medium,
        paddingTop: '0.5em'
    },
    link: {
        fontSize: medium,
        fontWeight: regularWeight,
        width: '8.75em',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    icon: {
        color: ballBlue
    }
}));

const CancelDeliveryOrderConfirmationShareLinks = (props: Props) => {
    const classes = useStyles();
    const [openPdf, setOpenPdf] = useState(false);
    const { t } = useTranslation();

    const printForm = () => {
        setOpenPdf(true);
    };

    const onClose = () => {
        setOpenPdf(false);
    };

    return (
        <Grid container item xs={12} spacing={2} alignItems="center" justify="flex-end">
            <Grid item>
                <Typography variant="h6" className={classes.label}>
                    <Trans i18nKey="share">share</Trans>
                </Typography>
            </Grid>
            <Grid item>
                <Link
                    className={classes.link}
                    underline="always"
                    data-testid="print-btn"
                    onClick={printForm}
                >
                    <Trans i18nKey="print">Print</Trans>
                </Link>
                <PrintOutlinedIcon fontSize="small" className={classes.icon} />
            </Grid>
            <Modal
                data-testid='confirmation-pdf-modal'
                open={openPdf}
                title={t('cancelDeliveryOrderConfirmation', 'Cancel Delivery Order Confirmation')}
                close={onClose}
                closeIcon={true}
                fullScreen={true}
            >
                <CancelDeliveryOrderConfirmationPDF
                    title={t(
                        'cancelDeliveryOrderConfirmation',
                        'Cancel Delivery Order Confirmation'
                    )}
                    deliveryOrder={props.deliveryOrder}
                    shipToAddress={props.shipToAddress}
                    shipToName={props.shipToName}
                />
            </Modal>
        </Grid>
    );
};

export default CancelDeliveryOrderConfirmationShareLinks;
