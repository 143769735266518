import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Activity } from '../../../utility/auth/useSecurity';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { enUS } from '../../../utility/translations/locales';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { selectIsLargeCustomer } from '../../../store/selectors';
import {
    BulkUploadPlanItATMState,
    InactiveProduct,
    UnrecognizedProduct
} from '../../../store/reducers/planit-bulk-atm';
import InactiveProductsTable from './components/InactiveProductsTable';
import UnrecognizedProductsTable from './components/UnrecognizedProductsTable';
import {
    formatNumberWithLocale,
    stringFormatterReplace
} from '../../../utility/helpers/formatters';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { boldWeight, orange } from '../../../themes/globalConstants';

const useStyles = makeStyles(() => ({
    tableContainer: {
        marginTop: '1.750em'
    },
    sectionContainer: {
        marginBottom: '2em'
    },
    header: {
        marginTop: '2em',
        marginBottom: '2.5em',
        fontWeight: boldWeight,
        color: orange,
        fontSize: '20px'
    },
    subHeader: {
        fontWeight: boldWeight,
        fontSize: '16px'
    },
    infoText: {
        marginBottom: '1.5em',
        marginTop: '1.5em'
    }
}));

export default function ProductAlerts() {
    const { t } = useTranslation();
    const isLargeCustomer = useTypedSelector<boolean>(selectIsLargeCustomer);
    const { unrecognizedProducts, inactiveProducts } = useTypedSelector<BulkUploadPlanItATMState>(
        (state) => state.bulkUploadPlanIt
    );
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [totalAlerts, setTotalAlerts] = useState<number>(0);
    const [filteredInactiveProducts, setFilteredInactiveProducts] = useState<InactiveProduct[]>([]);
    const [filteredUnrecognizedProducts, setFilteredUnrecognizedProducts] = useState<
        UnrecognizedProduct[]
    >([]);

    useEffect(() => {
        setTotalAlerts(
            (!!filteredUnrecognizedProducts ? filteredUnrecognizedProducts.length : 0) +
                (!!filteredInactiveProducts ? filteredInactiveProducts.length : 0)
        );
    }, [filteredUnrecognizedProducts, filteredInactiveProducts]);

    useEffect(() => {
        if (!!inactiveProducts) {
            let updatedInactiveProducts: InactiveProduct[] = [];
            inactiveProducts.forEach((product) => {
                if (
                    !updatedInactiveProducts.find(
                        (p) =>
                            p.productId === product.productId &&
                            p.shipToId === product.shipToId &&
                            p.lineNumber === product.lineNumber
                    )
                ) {
                    const foundProductId = updatedInactiveProducts.find(
                        (p) => p.shipToId === product.shipToId && p.productId === product.productId
                    );
                    if (!!foundProductId) {
                        const index = updatedInactiveProducts.indexOf(foundProductId);
                        updatedInactiveProducts[index].lineNumberString =
                            updatedInactiveProducts[index].lineNumber + ', ' + product.lineNumber;
                    } else {
                        product.lineNumberString = product.lineNumber.toString();
                        updatedInactiveProducts.push(product);
                    }
                }
            });
            setFilteredInactiveProducts([...updatedInactiveProducts]);
        }
        if (!!unrecognizedProducts) {
            let updatedUnrecognizedProducts: UnrecognizedProduct[] = [];
            unrecognizedProducts.forEach((product) => {
                // if statement prevents duplicates in multiweeks
                if (
                    !updatedUnrecognizedProducts.find(
                        (p) =>
                            p.productId === product.productId &&
                            p.shipToId === product.shipToId &&
                            p.lineNumber === product.lineNumber
                    )
                ) {
                    const foundProductId = updatedUnrecognizedProducts.find(
                        (p) => p.shipToId === product.shipToId && p.productId === product.productId
                    );
                    if (!!foundProductId) {
                        const index = updatedUnrecognizedProducts.indexOf(foundProductId);
                        updatedUnrecognizedProducts[index].lineNumberString =
                            updatedUnrecognizedProducts[index].lineNumber +
                            ', ' +
                            product.lineNumber;
                    } else {
                        product.lineNumberString = product.lineNumber.toString();
                        updatedUnrecognizedProducts.push(product);
                    }
                }
            });
            setFilteredUnrecognizedProducts([...updatedUnrecognizedProducts]);
        }
    }, [unrecognizedProducts, inactiveProducts]);

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('planIt', enUS.planIt),
                description: t('productAlerts', enUS.productAlerts).toUpperCase(),
                thinBanner: true,
                displayDropdown: false,
                shouldShowATMBanner: isLargeCustomer
            }}
            activity={Activity.PlanItBulkUpload}
            shipTos={false}
        >
            <Typography className={classes.header}>
                {stringFormatterReplace(
                    t('productAlertsFound', '({i}) Product Alerts Found in Uploaded File'),
                    `${formatNumberWithLocale(cultureCode, totalAlerts)}`
                )}
            </Typography>
            {filteredInactiveProducts.length > 0 && (
                <>
                    <Typography className={classes.subHeader}>
                        {'(' +
                            (!!filteredInactiveProducts ? filteredInactiveProducts.length : 0) +
                            ') '}
                        <Trans i18nKey="inactiveProductsIdentified">
                            Inactive Products Identified
                        </Trans>
                    </Typography>
                    <Typography className={classes.infoText}>
                        <Trans i18nKey="identifiedTheFollowingInactiveProducts">
                            We have identified the following inactive products included in your file
                        </Trans>
                        .
                    </Typography>
                    <Grid
                        data-testid="inactive-products-table"
                        item
                        xs={12}
                        className={classes.tableContainer}
                    >
                        <InactiveProductsTable
                            products={filteredInactiveProducts}
                        ></InactiveProductsTable>
                    </Grid>
                    <Typography className={classes.infoText}>
                        <Trans i18nKey="inactiveProductsMustBeActivated">
                            Inactive Products must be activated prior to creating a Make it. Please
                            reach out to your Ball contact to discuss how to activate products.
                        </Trans>
                    </Typography>
                </>
            )}
            {filteredUnrecognizedProducts.length > 0 && (
                <>
                    <Typography className={classes.subHeader}>
                        {'(' +
                            (!!filteredUnrecognizedProducts
                                ? filteredUnrecognizedProducts.length
                                : 0) +
                            ') '}
                        <Trans i18nKey="unrecognizedIdentified">
                            Unrecognized Products Identified
                        </Trans>
                    </Typography>
                    <Typography className={classes.infoText}>
                        <Trans i18nKey="unrecognizedCorrectIt">
                            One or more of the product IDs included in this upload is not
                            recognized. If a Product ID is incorrect, please go back to correct it
                            and re-upload the plan it.
                        </Trans>
                    </Typography>
                    <Typography className={classes.infoText}>
                        <Trans i18nKey="unrecognizedContinue">
                            If you wish to continue with unrecognized products included, fill out
                            the table below including the desired products you wish to add to your
                            forecast. The information will be sent to our team to review and
                            reconcile. You will receive a notification regarding the status of your
                            product. Please note that unrecognized products will not be available
                            for Make It or Ship It orders until a resolution is received.
                        </Trans>
                    </Typography>
                    <Grid
                        data-testid="unrecognized-products-table"
                        item
                        xs={12}
                        className={classes.tableContainer}
                    >
                        <UnrecognizedProductsTable
                            products={filteredUnrecognizedProducts}
                        ></UnrecognizedProductsTable>
                    </Grid>
                </>
            )}
        </ProcessingPageTemplate>
    );
}
