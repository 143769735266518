import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { Activity } from '../../../utility/auth/useSecurity';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { useDispatch } from 'react-redux';
import FormSection from '../../reusable/molecules/FormSection';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import {
    Grid,
    makeStyles,
    Button,
    Box,
    Typography,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import {
    large,
    blackWeight,
    regularWeight,
    medium,
    paleGrey
} from '../../../themes/globalConstants';
import { selectHasTermsAndConditionsAcknowledgmentAccess } from '../../../store/selectors';
import ConfirmationModal from '../../reusable/molecules/ConfirmationModal';
import {
    ContractAndDocument,
    ContractSummaryState
} from '../../../store/reducers/contract-summary';
import { acknowledgeTermsAndConditionsDocument } from '../../../store/actions/contract-summary';
import {
    getAcknowledgementFailed,
    getAcknowledgementSuccess
} from '../../../store/actions/contract-summary';
import ErrorAlert from '../../reusable/atoms/ErrorAlert';
import { AuthState } from '../../../store/reducers/auth';
import { CustomerContextState } from '../../../store/reducers/customer-context';

const useStyles = makeStyles((theme) => ({
    container: {
        marginBottom: '2.5em',
        marginTop: '2.5em'
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column'
    },
    boxItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: '1em 0',
        padding: '1em',
        backgroundColor: paleGrey,
        cursor: 'pointer'
    },
    innerText: {
        fontSize: large,
        letterSpacing: '0.21px',
        fontWeight: blackWeight,
        paddingLeft: '1=0.5em'
    },
    icon: {
        fontSize: '3em',
        color: theme.palette.secondary.main
    },
    agreementCheckedCheckbox: {
        marginTop: '1em'
    },
    checkboxLabel: {
        fontWeight: regularWeight,
        fontSize: medium
    },
    actionBtn: {
        height: '3.375em',
        width: '13.75em',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom'
    },
    footer: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    footerCancel: {
        justifyContent: 'flex-end'
    }
}));

const PricingContractAcknowledgement = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let { billToId, documentId } = useParams() as any;
    const history = useHistory();
    const classes = useStyles();
    const { control, handleSubmit, setValue, watch, register } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur'
    });
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [showError, setShowError] = useState<boolean>(false);
    const [document, setDocument] = useState<ContractAndDocument>();
    const { documents } = useTypedSelector<ContractSummaryState>((state) => state.contractSummary);
    const { accounts } = useTypedSelector<CustomerContextState>((state) => state.customerContext);
    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);

    const onConfirmationModal = () => {
        setModalOpen(true);
    };

    const offConfirmationModal = () => {
        setModalOpen(false);
    };

    const onCancel = () => {
        history.push('/terms-and-pricing');
    };

    useEffect(() => {
        if (billToId && documents) {
            const document = documents.find((doc) => {
                return doc.billTo === billToId && doc.documentId === documentId && !doc.isApproved;
            });

            if (document) {
                setDocument(document);
                setLoading(false);
            }
        }
    }, [billToId, documentId, documents]);

    const agreementChecked = watch('agreementChecked');
    const isAuthorized = useTypedSelector<boolean>(
        selectHasTermsAndConditionsAcknowledgmentAccess(billToId)
    );

    // Send user to unauthorized page if they don't meet the authorization requirements for the document's bill to
    useEffect(() => {
        if (accounts?.length && permissions?.length && isAuthorized === false)
            history.push('/unauthorized');
    }, [isAuthorized, accounts, permissions]);

    const handleAgreementChecked = () => setValue('agreementChecked', !agreementChecked);

    const onSubmit = async (data) => {
        if (!document) return;

        setLoading(true);
        setModalOpen(false);
        const response: any = await dispatch(acknowledgeTermsAndConditionsDocument(document));
        if (response.data && response.data.toString() === 'true') {
            // Timeout is neccessary because there is a delay in Salesforce for updating the record...
            // ...without the timeout, the next call to GetTermsAndConditions could show records prior to this approval
            setTimeout(async () => {
                await dispatch(getAcknowledgementSuccess());
                history.push('/terms-and-pricing');
            }, 1000);
        } else {
            await dispatch(
                getAcknowledgementFailed(
                    new Error(`The acknowledgement response is ${response.data}`)
                )
            );
            setShowError(true);
        }

        setLoading(false);
    };

    return (
        <>
            <ProcessingPageTemplate
                banner={{
                    header: t('contractAcknowledgement', 'Acknowledgement'),
                    description: '',
                    thinBanner: true,
                    displayDropdown: false
                }}
                activity={Activity.PricingContractAcknowledge}
                loading={loading}
            >
                <Grid container item xs={12} className={classes.container}>
                    <Grid item xs={12} className={classes.wrapper}>
                        <FormSection
                            testId={'accept-terms-conditions'}
                            sectionHeader={
                                <Trans i18nKey="acceptTermsAndConditions">
                                    Acknowledge Terms & Conditions
                                </Trans>
                            }
                        >
                            <Grid container item xs={12} direction="column">
                                <Grid item xs={6}>
                                    <Grid item xs={6}>
                                        <Box
                                            className={classes.boxItem}
                                            onClick={() => window.open(document?.pdfUrl, '_blank')}
                                        >
                                            <InsertDriveFileOutlinedIcon className={classes.icon} />
                                            <Typography className={classes.innerText}>
                                                {document?.documentName ? (
                                                    document?.documentName
                                                ) : (
                                                    <Trans i18nKey="termsandConditions">
                                                        Terms and Conditions
                                                    </Trans>
                                                )}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Controller
                                    as={
                                        <FormControlLabel
                                            className={classes.agreementCheckedCheckbox}
                                            control={
                                                <Checkbox
                                                    checked={agreementChecked}
                                                    onChange={handleAgreementChecked}
                                                    value={false}
                                                    color="primary"
                                                    data-testid="checkbox-accept-terms"
                                                />
                                            }
                                            label={
                                                <Typography className={classes.checkboxLabel}>
                                                    <Trans i18nKey="readAndAgree">
                                                        I have read and agree to all of the above
                                                        terms and conditions.
                                                    </Trans>
                                                </Typography>
                                            }
                                            inputRef={register}
                                        />
                                    }
                                    name="agreementChecked"
                                    control={control}
                                />
                            </Grid>
                        </FormSection>
                    </Grid>
                </Grid>

                <Grid container item xs={12} className={classes.footer}>
                    <Grid container item xs={2} className={classes.footerCancel}>
                        <Button
                            type="button"
                            variant="outlined"
                            color="secondary"
                            className={classes.actionBtn}
                            onClick={onCancel}
                        >
                            <Trans i18nKey="cancel">Cancel</Trans>
                        </Button>
                    </Grid>
                    <Grid container item xs={6}>
                        <ErrorAlert
                            removeMargin
                            errorMessage={t(
                                'requestError',
                                'The system was unable to process your request. Please try again later.'
                            )}
                            showError={showError}
                            dataTestId="acknowledge-terms-error"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            data-testid="acknowledge-submit-button"
                            className={classes.actionBtn}
                            onClick={onConfirmationModal}
                            disabled={!agreementChecked || !document}
                        >
                            <Trans i18nKey="acknowledge">Acknowledge</Trans>
                        </Button>
                    </Grid>
                </Grid>
            </ProcessingPageTemplate>
            <ConfirmationModal
                saveProgress={handleSubmit(onSubmit)}
                onClose={offConfirmationModal}
                logo={false}
                title={
                    <Trans i18nKey="pricingAcknowledgeConfirmationTitle">
                        Please confirm your acknowledgement of the updated terms to your account
                    </Trans>
                }
                subheader={
                    <Trans i18nKey="pricingAcknowledgeSubheader">
                        By selecting "Acknowledge", you hereby agree to the Terms & Conditions and
                        Pricing set by Ball Corporation for your selected account(s).
                    </Trans>
                }
                description={
                    <Trans i18nKey="pricingAcknowledgeQuestion">
                        Do you acknowledge the terms?
                    </Trans>
                }
                continueText={<Trans i18nKey="confirm">Confirm</Trans>}
                cancelText={<Trans i18nKey="cancel">Cancel</Trans>}
                open={modalOpen}
                navigationLink=""
                isPricingAcknowlege
                onCancel={offConfirmationModal}
            />
        </>
    );
};

export default PricingContractAcknowledgement;
