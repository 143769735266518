import {
    filterShipToIdsByPermission,
    Activity,
    SecurityLevel
} from '../../utility/auth/useSecurity';
import { findDistinctObjects } from '../../utility/helpers/axios-helpers';
import {
    createProductRequestObj,
    getMatchingProductInformation,
    getProductLeadTime
} from '../../utility/helpers/production-order-helpers';
import OrdersService, {
    UpdateProductInformationRequest
} from '../../utility/services/orders-service';
import ProductService, { LeadTimesResponse } from '../../utility/services/product-service';
import TranslationService from '../../utility/services/translation-service';
import { AuthState } from '../reducers/auth';
import { MakeItProductsRequest } from '../reducers/makeit-dashboard';
import { ProductStatusChangeCode, UnrecognizedProduct } from '../reducers/manage-products';
import { OrderProductTableRow } from '../reducers/orders-dashboard';
import {
    MANAGE_PRODUCTS_ERROR,
    MANAGE_PRODUCTS_LOADED,
    MANAGE_PRODUCTS_LOADING,
    MANAGE_PRODUCTS_RESET,
    MANAGE_PRODUCTS_UNRECOGNIZED_PRODUCT_CANCEL_UPDATES,
    MANAGE_PRODUCTS_UNRECOGNIZED_PRODUCT_SET_EXPANDED,
    MANAGE_PRODUCTS_UNRECOGNIZED_PRODUCT_UPDATE_QUANTITIES
} from './action-types';
import { getProductLeadTimes } from './makeit-dashboard';
import { Moment } from 'moment';

export const resetManageProductsState = () => {
    return (dispatch) => {
        dispatch({ type: MANAGE_PRODUCTS_RESET });
    };
};

export const loadProductInformation = () => {
    return (dispatch, getState) => {
        const state = getState();
        const auth: AuthState = state.auth;
        const shipToId = state.customerContext.selectedAccountId;
        const cultureCode = getState().regionCulture?.cultureCode || 'en-US';

        const requestObj: MakeItProductsRequest = {
            ShipToIds: filterShipToIdsByPermission(
                auth,
                [shipToId],
                Activity.ManageProducts,
                SecurityLevel.Edit
            )
        };

        let products: OrderProductTableRow[] = [];

        dispatch({ type: MANAGE_PRODUCTS_LOADING });

        OrdersService.getMakeItProducts(auth.accessToken, {
            ...requestObj,
            activeOnly: false
        })
            .then((response) => {
                const allProducts = response.data.products;
                products = findDistinctObjects(allProducts, 'productSku');
                let productRequest = createProductRequestObj(products, undefined, [shipToId]);
                return ProductService.getMultipleProducts(getState, {
                    ...productRequest,
                    accountId: shipToId
                });
            })
            .then((productApiResponse) => {
                return products.map((product) =>
                    getMatchingProductInformation(
                        productApiResponse.data.products,
                        product,
                        product.size,
                        product.type,
                        product.shape
                    )
                );
            })
            .then((products) => getProductLeadTimes(products, state))
            .then((leadTimes) => {
                products.forEach((product) => {
                    product.leadTimeWeeks = getProductLeadTime(
                        product,
                        leadTimes as LeadTimesResponse[]
                    );
                    product.leadTimeString =
                        product.leadTimeWeeks.toString() +
                        ' ' +
                        TranslationService.getTranslatedText(cultureCode, 'weeks');
                    const destination =
                        product.destinations &&
                        product.destinations.find(
                            (dest) =>
                                dest.shipToId.toString() === shipToId && dest.status === 'ACTIVE'
                        );
                    product.customerProductId = destination?.customerProductId;
                    product.status =
                        destination && product.status === 'ACTIVE' ? 'ACTIVE' : 'INACTIVE';
                    product.displayId = product.productId?.toString();
                });

                const activeProducts = products.filter((product) => product.status === 'ACTIVE');

                const inactiveProducts = products.filter(
                    (product) => product.status !== 'ACTIVE' || !product.status
                );

                // API dependencies will be added later to populate unrecognized products
                const unrecognizedProducts: UnrecognizedProduct[] = [];
                inactiveProducts.forEach((product) => {
                    const foundMatch = activeProducts.find(
                        (activeProduct) =>
                            !!product.graphicIdAndVersion &&
                            activeProduct.graphicIdAndVersion === product.graphicIdAndVersion
                    );
                    if (!!foundMatch) {
                        product.hasMatchingGraphicId = true;
                    }
                });

                dispatch({
                    type: MANAGE_PRODUCTS_LOADED,
                    activeProducts: activeProducts,
                    inactiveProducts: inactiveProducts,
                    unrecognizedProducts: unrecognizedProducts
                });
            })
            .catch((error) => {
                dispatch({
                    type: MANAGE_PRODUCTS_ERROR,
                    error
                });
            });
    };
};

export const setUnrecognizedExpandedProduct = (customerProductId: string) => {
    return (dispatch, getState) => {
        const state = getState();
        const unrecognizedProducts = state.manageProductsState.unrecognizedProducts;
        if (unrecognizedProducts) {
            const updatedUnrecognizedProducts = unrecognizedProducts.map((product) => {
                return {
                    ...product,
                    expanded:
                        product.customerProductId === customerProductId ? !product.expanded : false
                };
            });
            dispatch({
                type: MANAGE_PRODUCTS_UNRECOGNIZED_PRODUCT_SET_EXPANDED,
                unrecognizedProducts: updatedUnrecognizedProducts
            });
        }
    };
};

export const setUnrecognizedQuantities = (
    customerProductId: string,
    forecastId: number,
    quantity: number
) => {
    return (dispatch, getState) => {
        const state = getState();
        const unrecognizedProducts = state.manageProductsState.unrecognizedProducts;
        if (unrecognizedProducts) {
            const updatedUnrecognizedProducts = unrecognizedProducts.map((product) => {
                return {
                    ...product,
                    forecasts: product.forecasts.map((forecast) => {
                        return {
                            ...forecast,
                            updatedQuantity:
                                product.customerProductId === customerProductId &&
                                forecast.forecastId === forecastId
                                    ? quantity
                                    : forecast.updatedQuantity
                                    ? forecast.updatedQuantity
                                    : forecast.originalQuantity
                        };
                    })
                };
            });

            const updatedIsEdited = updatedUnrecognizedProducts
                .map((product) => product.forecasts)
                .flat()
                .some(
                    (forecast) =>
                        forecast.updatedQuantity &&
                        forecast.updatedQuantity !== forecast.originalQuantity
                );
            dispatch({
                type: MANAGE_PRODUCTS_UNRECOGNIZED_PRODUCT_UPDATE_QUANTITIES,
                unrecognizedProducts: updatedUnrecognizedProducts,
                isEdited: updatedIsEdited
            });
        }
    };
};

export const unrecognizedProductsCancelUpdates = () => {
    return (dispatch, getState) => {
        const state = getState();
        const unrecognizedProducts = state.manageProductsState.unrecognizedProducts;
        if (unrecognizedProducts) {
            const updatedUnrecognizedProducts = unrecognizedProducts.map((product) => {
                return {
                    ...product,
                    forecasts: product.forecasts.map((forecast) => {
                        return {
                            ...forecast,
                            updatedQuantity: undefined
                        };
                    })
                };
            });
            dispatch({
                type: MANAGE_PRODUCTS_UNRECOGNIZED_PRODUCT_CANCEL_UPDATES,
                unrecognizedProducts: updatedUnrecognizedProducts
            });
        }
    };
};

export const updateProductInformation = (
    productInformationUpdate: UpdateProductInformationRequest
) => {
    return (dispatch, getState) => {
        const state = getState();
        OrdersService.submitProductChange(productInformationUpdate, state)
            .then((response) => {
                dispatch(resetManageProductsState());
                dispatch(loadProductInformation());
            })
            .catch((error) => {
                dispatch({
                    type: MANAGE_PRODUCTS_ERROR,
                    error
                });
            });
    };
};

export const deactivateProduct = (productToDeactivate: OrderProductTableRow) => {
    return (dispatch, getState) => {
        const state = getState();
        const selectedAccount = state.customerContext.shipToAccounts.find(
            (a) => a.accountId === state.customerContext.selectedAccountId
        );

        OrdersService.updateProductStatus(state, {
            productId: productToDeactivate.productSku,
            shipToName: selectedAccount.name,
            shipToId: +selectedAccount.accountId,
            type: 'Deactivation'
        })
            .then((response) => {
                let activeProducts = getState().manageProductsState.activeProducts;
                let stateProduct = activeProducts.find(
                    (p) => p.productId === productToDeactivate.productId
                );
                stateProduct.statusChanges = stateProduct.statusChanges || [];
                stateProduct.statusChanges.push({
                    code: ProductStatusChangeCode.PSC00003
                });
                dispatch({
                    type: MANAGE_PRODUCTS_LOADED,
                    activeProducts: activeProducts,
                    inactiveProducts: getState().manageProductsState.inactiveProducts
                });
            })
            .catch((error) => {
                dispatch({
                    type: MANAGE_PRODUCTS_ERROR,
                    error
                });
            });
    };
};

export const activateProduct = (
    productToActivate: OrderProductTableRow,
    projectedOrderDate: Moment
) => {
    return (dispatch, getState) => {
        const state = getState();
        const selectedAccount = state.customerContext.shipToAccounts.find(
            (a) => a.accountId === state.customerContext.selectedAccountId
        );

        OrdersService.updateProductStatus(state, {
            productId: productToActivate.productSku,
            shipToName: selectedAccount.name,
            shipToId: +selectedAccount.accountId,
            projectedOrderDate: projectedOrderDate,
            type: 'Activation'
        })
            .then((response) => {
                let inactiveProducts = state.manageProductsState.inactiveProducts;
                let stateProduct = inactiveProducts.find(
                    (p) => p.productId === productToActivate.productId
                );
                stateProduct.statusChanges = stateProduct.statusChanges || [];
                stateProduct.statusChanges.push({
                    code: ProductStatusChangeCode.PSC00002
                });
                dispatch({
                    type: MANAGE_PRODUCTS_LOADED,
                    activeProducts: getState().manageProductsState.activeProducts,
                    inactiveProducts: inactiveProducts
                });
            })
            .catch((error) => {
                dispatch({
                    type: MANAGE_PRODUCTS_ERROR,
                    error
                });
            });
    };
};
