import React, { ReactElement } from 'react';
import WelcomePage from './WelcomePage';
import { useTranslation } from 'react-i18next';

interface Props {}

export default function Home(props: Props): ReactElement {
  // this is needed so that the translations flow into the child components
  useTranslation();

  return (
    <div className="App">
      <WelcomePage />
    </div>
  );
}
