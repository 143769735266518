import { Moment, unitOfTime } from 'moment';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import { Culture } from '../../store/reducers/region-culture';
import { DeliveryTime } from '../../store/reducers/shipping-dashboard';

export const mountainTimeZone = 'America/Denver';

export interface Weekday {
    date: moment.Moment;
    name: string;
    day: string;
}

// Used to return simple MM/DD/YYYY date format.
// This can be adjusted to use different localization formats if we pass in the selected culture from React state
export const getSimpleFormattedDate = (alternativeDisplay: string, date?: string) => {
    return date
        ? new Date(date).toLocaleDateString(Culture.enUS, {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric'
          })
        : alternativeDisplay;
};

// Used to return simple hh:mm A time format.
// This can be adjusted to use different localization formats if we pass in the selected culture from React state
export const getSimpleFormattedTime = (alternativeDisplay: string, dateTime?: string) => {
    return dateTime
        ? Intl.DateTimeFormat(Culture.enUS, {
              hour: 'numeric',
              minute: '2-digit',
              hour12: true
          }).format(new Date(dateTime))
        : alternativeDisplay;
};

// Used to return simple MM/DD/YYYY hh:mm A time format.
// This can be adjusted to use different localization formats if we pass in the selected culture from React state
export const getFullFormattedDateTime = (alternativeDisplay: string, dateTime?: string) => {
    return dateTime
        ? Intl.DateTimeFormat(Culture.enUS, {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
              hour: 'numeric',
              minute: '2-digit',
              hour12: true
          }).format(new Date(dateTime))
        : alternativeDisplay;
};

export const findEarliestShippingDate = (deliveryDate) => {
    return moment(deliveryDate).subtract(7, 'days');
};

export const getDaylightSavingsOffset = () => {
    const timezone = moment.tz.guess();

    const januaryOffset = moment.tz({ month: 0, day: 1 }, timezone).utcOffset();
    const juneOffset = moment.tz({ month: 5, day: 1 }, timezone).utcOffset();

    const adjustedOffset = Math.abs(januaryOffset - juneOffset);
    return adjustedOffset;
};

export const addTimezoneOffset = (dateTime?: string, addDstOffset: boolean = true) => {
    const momentdate = moment(dateTime);
    let offset = moment().local().utcOffset();

    if (addDstOffset) {
        const dstOffset = getTimezoneDiff(momentdate);
        offset += dstOffset;
    }

    const formatted = momentdate.add(-offset, 'minutes').format();
    return formatted;
};

export const subtractTimezoneOffset = (dateTime?: string, subtractDstOffset: boolean = true) => {
    const momentdate = moment(dateTime);
    let offset = moment().local().utcOffset();

    if (subtractDstOffset) {
        const dstOffset = getTimezoneDiff(momentdate);
        offset += dstOffset;
    }

    const formatted = momentdate.subtract(-offset, 'minutes').format();
    return formatted;
};

export const getTimezoneDiff = (dateTime: moment) => {
    const nowIsDaylightSavings = moment().isDST();
    const dateIsDaylightSavings = dateTime.isDST();

    const dstOffset = getDaylightSavingsOffset();

    if (nowIsDaylightSavings && !dateIsDaylightSavings) return dstOffset * -1;
    if (!nowIsDaylightSavings && dateIsDaylightSavings) return dstOffset;

    return 0;
};

export const getWeek = (weekNum: number) => {
    return moment()
        .startOf('week')
        .add(weekNum * 7 + 1, 'd')
        .format('L');
};

export const removeYearFromDate = (date: string) => {
    return date.replace(new RegExp('[^.]?' + moment(date).format('YYYY') + '.?'), '');
};

//Takes in date and subtracts 4 weeks for Payment Due
export const subtract4Weeks = (date: string) => {
    return moment(date).subtract(4, 'weeks').format('MM/DD/YYYY');
};

//Takes in a datetime string from the DB
//Then converts it to short date
export const formatFromDatetime = (date: string) => {
    const convertedDate = moment(date).toDate();
    return moment(convertedDate).format('MM/DD/YYYY');
};

//Takes in moment date and converts it to MM/DD/YYYY date
export const formatDate = (date: string | undefined) => {
    if (date) {
        return moment(date).format('MM/DD/YYYY');
    }
    return '';
};

//Takes in a date and converts it to short date
export const formatDateShort = (date: string) => {
    return moment(date).format('M/D/YYYY');
};

//Takes in a date and converts it to short date
export const formatDateAndTime = (date: string) => {
    if (date) {
        return moment(date).format('MM/DD/YYYY h:mm A');
    } else {
        return '';
    }
};

export const formatDateAndFullTime = (date: string) => {
    return moment(date).format('MM/DD/YYYY hh:mm A');
};

// converts a date string taking into account a user's timezone, adjusting for offest,
// and returning the new, timezone-adjusted moment object
// ETL data is as such: 'YYYY-MM-DDT00:00:00.0000000', so this helps us handle that
// so that the displayed date isn't showing the previous day
export const convertDateForApplication = (date: string) => {
    let momentDate = moment.utc(date);
    let isMidnight = isDateMidnight(momentDate);

    if (isMidnight) {
        return momentDate.add(-momentDate.local().utcOffset(), 'minutes');
    } else {
        return momentDate.local();
    }
};

export const getFormattedDateStringForApplication = (date?: string) => {
    if (date) {
        let momentDate = convertDateForApplication(date);
        return formatDate(momentDate);
    } else {
        return '';
    }
};

export const calculateLeadTime = (
    startDay: Moment,
    leadTime: number,
    increment: string,
    setToMonday?: boolean
): Moment => {
    let nextAvailableDay = startDay.add(leadTime, increment as unitOfTime.DurationConstructor);
    if (setToMonday) {
        nextAvailableDay = nextAvailableDay.day(1);
    }
    return nextAvailableDay;
};

export const setDateTimeToMidnight = (dayToSet: moment.Moment) => {
    return moment(dayToSet).hours(12).minutes(0).seconds(0).milliseconds(0);
};

export const isDateMidnight = (date: moment) => {
    return date.hours() === 0 && date.minutes() === 0 && date.seconds() === 0;
};

export const convertToMT = (date: moment) => {
    return date.tz(mountainTimeZone).format();
};

export const convertToMTSetMidNight = (date: moment) => {
    return date.tz(mountainTimeZone).hours(12).minutes(0).seconds(0).milliseconds(0).format();
};

export const convertTimeStampToMT = (date: moment) => {
    const dateToUpdate = moment(date);
    const now = moment.utc();
    const mountainTZOffset = moment.tz.zone(mountainTimeZone).utcOffset(now);
    const timezone = moment.tz.guess();
    const currentTZOffset = moment.tz.zone(timezone).utcOffset(now);
    const offset = mountainTZOffset - currentTZOffset;
    dateToUpdate.add(offset, 'minutes').format();
    return dateToUpdate.tz(mountainTimeZone);
};

export const formattedDateWithTimezone = (date: moment, format: string) => {
    const timezone = moment.tz.guess();
    return date.tz(timezone).format(format);
};

export const formattedMTDate = (date: moment, format: string) => {
    return date.tz(mountainTimeZone).format(format);
};

export const convertFromUTC = (date: moment, timezone: string = mountainTimeZone) => {
    return moment.utc(date).tz(timezone);
};

export const getUserTimezone = () => {
    return moment().tz(moment.tz.guess()).format('z');
};

export const formatUTCtoMT = (date: moment, format: string) => {
    const convertedDate = convertFromUTC(date);
    return convertedDate.format(format);
};

export const createLeadTimeDisplay = (leadTime: number) => {
    return moment().tz(mountainTimeZone).add(leadTime, 'weeks').format('MM/DD/YYYY');
};

export const getCurrentDate = () => {
    return moment().tz(mountainTimeZone).format('MM/DD/YYYY');
};

export const addDays = (date: string, days: number, removeYear: boolean = true) => {
    const result = moment(date).add(days, 'days').format('MM/DD/YYYY');
    return removeYear ? removeYearFromDate(result) : result;
};

export const buildWeekdays = (date: moment.Moment) => {
    const startOfWeekDate = moment(date);
    let weekdays: Weekday[] = [];

    let x = 0;
    for (x = 1; x < 8; x++) {
        let date = startOfWeekDate.startOf('week').add(x, 'd');
        weekdays.push({
            date: date.format(),
            name: date.format('ddd'),
            day: date.format('D')
        });
    }
    return weekdays;
};

export const isDateWithinTheWeek = (date: moment.Moment) => {
    return !(moment().diff(date, 'days') < 0 && moment().diff(date, 'days') > -7);
};

/**
 *
 * @param date - date we're testing
 * @param leadTimeInDays - lead time
 * @returns
 *      "before" means the date is before the lock period
 *      "in" means the date is within the lock period
 *      "after" means the date is after the lock period
 */
export const isDateWithinAtmLockPeriod = (
    date: moment.Moment,
    leadTimeInDays: number
): 'before' | 'in' | 'after' => {
    const now = moment().format();
    const dateInMT = convertTimeStampToMT(date).add(2, 'minutes'); // adding 2 minutes to avoid same day issues
    const lockPeriodStart = convertTimeStampToMT(now).day(1).startOf('day').add(1, 'minutes'); // previous Monday at 12:01am MT

    const lockPeriodEnd = convertTimeStampToMT(now)
        .add(leadTimeInDays + 7, 'days')
        .day(1)
        .startOf('day')
        .add(1, 'minutes'); // Monday at 12:01am MT at the end of the lock period

    if (lockPeriodStart.isAfter(dateInMT)) {
        // date is before lock period
        return 'before';
    } else if (dateInMT.isBefore(lockPeriodEnd)) {
        // date is within the lock period
        return 'in';
    } else {
        // date is after lock period end
        return 'after';
    }
};

export const isPastDateWithin90Days = (date: moment.Moment) => {
    return moment().diff(date, 'days') >= 0 && moment().diff(date, 'days') < 90;
};

export const isDateWithin24Hrs = (date: moment.Moment) => {
    const today = moment();
    const cutoff = 24;
    const shippingTodayOrBefore = moment(date).isSameOrBefore(today);
    const hoursUntilShipped = Math.abs(moment().diff(date, 'hours'));

    //Run a check to make sure date is not in the past or today
    if (shippingTodayOrBefore) {
        return true;
    } else {
        //Run a check to make sure date not within 24 hours of today
        if (hoursUntilShipped <= cutoff) {
            return true;
        } else {
            return false;
        }
    }
};

export const buildDeliveryTimes = (
    currentDate: moment.Moment,
    firstSelectableDateTime?: moment.Moment
) => {
    let deliveryTimes: DeliveryTime[] = [];
    // cloning the date to avoid changing the currentDate value directly
    let clonedDate = currentDate.clone();
    // Setting to the start of day to ensure that 12am - 11:30pm is displayed in the list for all flows/situations
    let intervalDate = moment(clonedDate).startOf('day');
    let addedLabels: string[] = [];

    while (intervalDate.isSame(currentDate, 'date')) {
        let deliveryTimeEntry = {
            time: intervalDate,
            label: intervalDate.format('LT')
        };

        // only push delivery times that aren't already in the array
        // this fixes the condition of DST ending and 1-2am occuring twice
        // also omit adding time selection if it's before the first selectable date time
        const timeIsAfterFirstAvailableDateTime =
            !firstSelectableDateTime || !intervalDate.isBefore(firstSelectableDateTime);
        if (!addedLabels.includes(deliveryTimeEntry.label) && timeIsAfterFirstAvailableDateTime) {
            addedLabels.push(deliveryTimeEntry.label);
            deliveryTimes.push(deliveryTimeEntry);
        }

        intervalDate = moment(intervalDate).add(30, 'm');
    }

    return deliveryTimes;
};

export const isDateSameOrAfter = (startingDate: string, endDate: string) => {
    if (startingDate && endDate) {
        const firstDate = moment(startingDate).startOf('day');
        const secondDate = moment(endDate).startOf('day');
        return firstDate.isSameOrAfter(secondDate);
    }
    return false;
};

export const getUtcMidnight = (date: string | undefined): moment.Moment => {
    return moment.utc(date).startOf('day');
};

export const formatUTCToDateDisplay = (date: string | undefined) => {
    const setMidnightDatetime = getUtcMidnight(date);
    return formatDate(setMidnightDatetime);
};

export const getFirstAvailableDate = (leadTimeWeeks: number | undefined): string => {
    const today = moment();
    const leadTimeDate = calculateLeadTime(today, leadTimeWeeks ?? 1, 'weeks')
        .startOf('isoWeek')
        .add(1, 'week');
    return setDateTimeToMidnight(leadTimeDate).format('MM/DD/YYYY');
};

export const formatForUsersTimezone = (utcDate: Date): string => {
    return `${moment(utcDate).format('MM/DD/YYYY h:mm A z')} ${moment()
        .tz(moment.tz.guess())
        .format('z')}`;
};

export const formatDateForEU = (date: Date): string => {
    return moment(date).format('DD/MM/YYYY h:mm A z');
};

export const getMondayOfWeekFormatted = (date: string): string => {
    const startWeek = moment(date).day(1);
    return formatDate(startWeek);
};
