import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { xxl, small, boldWeight } from '../../../../themes/globalConstants';
import {
    getSnoSkuDescription,
    getSnoSkuAttributes
} from '../../../../utility/helpers/make-it-bulk-helpers';
import { isProductCanType } from '../../../../utility/helpers/order-helpers';

interface Props {
    product: any;
    shipToId: number;
    shipToName?: string;
    shipToAddress?: string;
}

const useStyles = makeStyles((theme) => ({
    item: {
        paddingLeft: '.75em'
    },
    productDescriptionText: {
        fontSize: xxl,
        overflowWrap: 'break-word'
    },
    productDescriptionContainer: {
        paddingRight: '0.5em'
    },
    lesserAttributes: {
        fontWeight: boldWeight,
        fontSize: small
    }
}));

export const PlanItGuidedConfirmationSectionHeader = ({ product }: Props) => {
    const classes = useStyles();

    return (
        <>
            <Grid
                item
                xs={5}
                className={clsx(classes.item, classes.productDescriptionContainer)}
                data-testid="sno-sku-id"
            >
                <Typography
                    className={classes.productDescriptionText}
                    data-testid="sno-sku-description"
                    variant="h4"
                >
                    {product &&
                        getSnoSkuDescription(isProductCanType(product.productType), product)}
                </Typography>
                <Typography className={classes.lesserAttributes} data-testid="product-attributes">
                    {product && getSnoSkuAttributes(isProductCanType(product.productType), product)}
                </Typography>
            </Grid>
        </>
    );
};
