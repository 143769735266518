import { FormControl, FormHelperText, Grid, makeStyles, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import { Controller, ErrorMessage, useFieldArray, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { businessTypes, naCountries } from '../../../../static-data/dropdown-lists';
import { CompanyInformation } from '../../../../store/reducers/onboarding-dashboard';
import { red } from '../../../../themes/globalConstants';
import { stateDropdownSelector } from '../../../../utility/helpers/onboardHelpers';
import { enUS } from '../../../../utility/translations/locales';
import AddItemFormButton from '../../../reusable/atoms/AddItemFormButton';
import DatePicker from '../../../reusable/atoms/DatePicker';
import FormSection from '../../../reusable/molecules/FormSection';
import PhoneInput from '../../../reusable/molecules/PhoneInput';
import SelectInput from '../../../reusable/molecules/SelectInput';
import TextInput from '../../../reusable/molecules/TextInput';
import { isValidEmail } from '../../../../utility/helpers/user-helpers';

interface Props {
    companyInfo?: CompanyInformation;
}

const useStyles = makeStyles(() => ({
    headerList: {
        marginTop: '0.5em'
    },
    headerListItem: {
        marginTop: '5px'
    },
    helperText: {
        '& .MuiFormHelperText-root': {
            marginLeft: 0
        }
    },
    errorText: {
        color: red
    },
    inputLine: {
        marginBottom: '0.75em'
    },
    formSectionWrapper: {
        padding: '8px 8px 0px 8px'
    }
}));

export default function CompanyInformationSection({ companyInfo }: Props): ReactElement {
    const classes = useStyles();
    const { t } = useTranslation();
    const { control, errors, watch, setValue, setError, clearError, getValues } = useFormContext();
    const [stateDropdownList, setStateDropdownList] = useState<any[]>([]);
    const today = moment().startOf('day');

    const dateEstablished = watch('companyInformation.dateEstablished');
    const selectedCountry = watch('companyInformation.country');

    useEffect(() => {
        if (selectedCountry) {
            stateDropdownSelector(selectedCountry, setStateDropdownList);
        }
    }, [selectedCountry]);

    const isOutsideRange = (day) => {
        return day.isAfter(today);
    };

    const handleDateChange = (name: string, newDate: moment.Moment | null) => {
        setValue(name, newDate);

        !newDate ? setError(name, { type: 'required' }) : clearError(name);
    };

    const { fields, append } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: 'headsOfBusiness' // unique name for your Field Array
    });

    useEffect(() => {
        if (companyInfo) {
            const fieldName = 'companyInformation';
            setValue(`${fieldName}.contact`, companyInfo.contact);
            setValue(`${fieldName}.contactEmail`, companyInfo.contactEmail);
            setValue(`${fieldName}.country`, companyInfo.country);
            setValue(`${fieldName}.phoneNumber`, companyInfo.phoneNumber);
            setValue(`${fieldName}.incorporationState`, companyInfo.incorporationState);
            if (companyInfo.dateEstablished) {
                setValue(`${fieldName}.dateEstablished`, moment(companyInfo.dateEstablished));
            }
            setValue(
                `${fieldName}.businessType`,
                !companyInfo.businessType ||
                    companyInfo.businessType.toLocaleLowerCase() === 'unknown'
                    ? null
                    : companyInfo.businessType
            );
        }
    }, [companyInfo]);

    const isRequired = <Trans i18nKey="required">Required</Trans>;

    return (
        <FormSection
            testId={'credit-app-header-section'}
            sectionHeader={<Trans i18nKey="companyInformation">{enUS.companyInformation}</Trans>}
        >
            <Grid xs={12} className={classes.formSectionWrapper}>
                <Grid container xs={12} spacing={2} className={classes.inputLine}>
                    <Grid item xs={6}>
                        <TextInput
                            type="text"
                            label={
                                <Trans i18nKey="accountsPayableContact">
                                    Accounts Payable Contact
                                </Trans>
                            }
                            name="companyInformation.contact"
                            testId="companyInformation.contact"
                            control={control}
                            rules={{ required: isRequired }}
                            error={errors.companyInformation?.contact}
                            errors={errors}
                            styles={classes.helperText}
                            inputProps={{
                                'data-testid': 'companyInformation.contact',
                                maxLength: 50
                            }}
                        />
                        <FormHelperText className={classes.errorText}>
                            {errors.companyInformation?.contact?.type === 'required' && (
                                <ErrorMessage
                                    errors={errors}
                                    name={'companyInformation.contact'}
                                    message={isRequired}
                                />
                            )}
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            type="text"
                            label={
                                <Trans i18nKey="accountsPayableContactEmail">
                                    Accounts Payable Contact Email
                                </Trans>
                            }
                            name="companyInformation.contactEmail"
                            testId="companyInformation.contactEmail"
                            control={control}
                            rules={{
                                required: isRequired,
                                validate: {
                                    isValidEmail: (value) => {
                                        return (
                                            isValidEmail(value) || (
                                                <Trans i18nKey="incorrectEmailAddress">
                                                    Invalid Email Format
                                                </Trans>
                                            )
                                        );
                                    }
                                }
                            }}
                            error={errors.companyInformation?.contactEmail}
                            errors={errors}
                            styles={classes.helperText}
                            inputProps={{
                                'data-testid': 'companyInformation.contactEmail',
                                maxLength: 255
                            }}
                        />
                        <FormHelperText className={classes.errorText}>
                            {errors.companyInformation?.contactEmail && (
                                <ErrorMessage
                                    errors={errors}
                                    name={'companyInformation.contactEmail'}
                                />
                            )}
                        </FormHelperText>
                    </Grid>
                </Grid>
                <Grid container xs={12} className={classes.inputLine} spacing={2}>
                    <Grid item xs={6}>
                        <SelectInput
                            label={<Trans i18nKey="country">Country</Trans>}
                            name="companyInformation.country"
                            testId="companyInformation.country"
                            control={control}
                            rules={{ required: isRequired }}
                            error={errors.companyInformation?.country}
                            errors={errors}
                            styles={classes.helperText}
                            selectObj={naCountries}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            as={
                                <PhoneInput
                                    label={<Trans i18nKey="phoneNumber">Phone Number</Trans>}
                                    testId="companyInformation.phoneNumber"
                                    disableAreaCodes={true}
                                    defaultCountry={'us'}
                                    defaultValue={getValues('companyInformation.phoneNumber')}
                                />
                            }
                            name="companyInformation.phoneNumber"
                            control={control}
                            error={errors.companyInformation?.phoneNumber}
                            errors={errors}
                            rules={{
                                required: isRequired,
                                pattern: /^\+([0-9][ \-()]{0,2}){9,14}[0-9]$/
                            }}
                        />
                        <FormHelperText className={classes.errorText}>
                            {errors.companyInformation?.phoneNumber?.type === 'required' && (
                                <ErrorMessage
                                    errors={errors}
                                    name="companyInformation.phoneNumber"
                                    message={isRequired}
                                />
                            )}
                            {errors.companyInformation?.phoneNumber?.type === 'pattern' && (
                                <ErrorMessage
                                    errors={errors}
                                    name="companyInformation.phoneNumber"
                                    message={t('invalidPhoneNumber', 'Invalid Phone Number')}
                                />
                            )}
                        </FormHelperText>
                    </Grid>
                </Grid>
                <Grid container xs={12} className={classes.inputLine} spacing={2}>
                    <Grid item xs={6}>
                        <SelectInput
                            label={
                                <Trans i18nKey="stateOfIncorporation">State of Incorporation</Trans>
                            }
                            name="companyInformation.incorporationState"
                            testId="companyInformation.incorporationState"
                            control={control}
                            rules={{ required: isRequired }}
                            error={errors.companyInformation?.incorporationState}
                            errors={errors}
                            styles={classes.helperText}
                            selectObj={stateDropdownList}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl
                            fullWidth
                            error={errors.companyInformation?.dateEstablished}
                            data-testid="dateEstablished"
                        >
                            <Controller
                                as={
                                    <DatePicker
                                        keepOpen={false}
                                        isOutsideRange={isOutsideRange}
                                        numberOfMonths={1}
                                        placeholder={t('dateEstablished', 'Date Established')}
                                        onDateChange={handleDateChange}
                                        name="companyInformation.dateEstablished"
                                        isEditDraft={false}
                                        date={dateEstablished}
                                    />
                                }
                                control={control}
                                name={'companyInformation.dateEstablished'}
                                rules={{ required: isRequired }}
                                error={errors.companyInformation?.dateEstablished}
                                errors={errors}
                            />
                            {!errors.companyInformation?.dateEstablished && (
                                <FormHelperText>
                                    <Typography>XX/XX/XXXX</Typography>
                                </FormHelperText>
                            )}
                            {(errors.companyInformation?.dateEstablished?.type === 'required' ||
                                errors.companyInformation?.dateEstablished?.types?.type ===
                                    'required') && (
                                <FormHelperText>
                                    <ErrorMessage
                                        errors={errors}
                                        name={'companyInformation.dateEstablished'}
                                        message={isRequired}
                                    />
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container xs={12} className={classes.inputLine} spacing={2}>
                    <Grid item xs={6}>
                        <SelectInput
                            label={<Trans i18nKey="typeOfBusiness">Type of Business</Trans>}
                            name="companyInformation.businessType"
                            testId="companyInformation.businessType"
                            control={control}
                            rules={{ required: isRequired }}
                            error={errors.companyInformation?.businessType}
                            errors={errors}
                            styles={classes.helperText}
                            selectObj={businessTypes}
                        />
                    </Grid>
                    <Grid item xs={6} />
                </Grid>
                <Grid xs={12} className={classes.inputLine}>
                    <Typography>
                        <Trans i18nKey="companyInfoHelper">
                            Provide the information for your company officers, partners, or owner.
                        </Trans>
                    </Typography>
                </Grid>
                {fields.map((field, index) => (
                    <Fragment key={field.id}>
                        <Grid container xs={12} className={classes.inputLine} spacing={2}>
                            <Grid item xs={3}>
                                <TextInput
                                    type="text"
                                    label={<Trans i18nKey="firstName">First Name</Trans>}
                                    name={`headsOfBusiness.${index}.firstName`}
                                    testId="firstName"
                                    control={control}
                                    rules={{ required: isRequired }}
                                    error={
                                        errors.headsOfBusiness &&
                                        errors.headsOfBusiness[index]?.firstName
                                    }
                                    errors={errors}
                                    styles={classes.helperText}
                                    inputProps={{
                                        'data-testid': `headsOfBusiness.${index}.firstName`,
                                        maxLength: 50
                                    }}
                                />
                                <FormHelperText className={classes.errorText}>
                                    <ErrorMessage
                                        errors={errors}
                                        name={`headsOfBusiness.${index}.firstName`}
                                        message={isRequired}
                                    />
                                </FormHelperText>
                            </Grid>
                            <Grid item xs={3}>
                                <TextInput
                                    type="text"
                                    label={<Trans i18nKey="lastName">Last Name</Trans>}
                                    name={`headsOfBusiness.${index}.lastName`}
                                    testId="lastName"
                                    control={control}
                                    rules={{ required: isRequired }}
                                    error={
                                        errors.headsOfBusiness &&
                                        errors.headsOfBusiness[index]?.lastName
                                    }
                                    errors={errors}
                                    styles={classes.helperText}
                                    inputProps={{
                                        'data-testid': `headsOfBusiness.${index}.lastName`,
                                        maxLength: 50
                                    }}
                                />
                                <FormHelperText className={classes.errorText}>
                                    <ErrorMessage
                                        errors={errors}
                                        name={`headsOfBusiness.${index}.lastName`}
                                        message={isRequired}
                                    />
                                </FormHelperText>
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput
                                    type="text"
                                    label={<Trans i18nKey="title">Title</Trans>}
                                    name={`headsOfBusiness.${index}.title`}
                                    testId="title"
                                    control={control}
                                    rules={{ required: isRequired }}
                                    error={
                                        errors.headsOfBusiness &&
                                        errors.headsOfBusiness[index]?.title
                                    }
                                    errors={errors}
                                    styles={classes.helperText}
                                    inputProps={{
                                        'data-testid': `headsOfBusiness.${index}.title`,
                                        maxLength: 50
                                    }}
                                />
                                <FormHelperText className={classes.errorText}>
                                    <ErrorMessage
                                        errors={errors}
                                        name={`headsOfBusiness.${index}.title`}
                                        message={isRequired}
                                    />
                                </FormHelperText>
                            </Grid>
                        </Grid>
                    </Fragment>
                ))}
                <Grid xs={12}>
                    {fields?.length < 3 && (
                        <Grid item>
                            <AddItemFormButton
                                onClick={() => append({ firstName: '', lastName: '', title: '' })}
                                text={t('addAnother', 'Add Another')}
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </FormSection>
    );
}
