// Global css varibles

// Width
export const containerMaxWidth = '85em';

// Unicodes
export const listIcon = '\u2022';
export const mdash = '\u2014';

//Font
export const lato = 'Lato';
export const latoBold = 'Lato Bold';
export const latoBlack = 'Lato Black';

// Font Sizes
//10px
export const xs = '0.625rem';
//12px
export const small = '0.75rem';
//14px
export const medium = '0.875rem';
//16px
export const large = '1rem';
//18px
export const xl = '1.125rem';
//25px
export const xxl = '1.563rem';
//80px
export const xxxl = '5rem';

// Disabled
export const disabledGrey = '#8E9295';
export const disabledText = '#D8D8D8';

//ATM Banner
export const atmPurple = '#9063CD';
export const atmCircle = '#7d48c4';

// Font Weights
export const regularWeight = 400;
export const regularExtraWeight = 500;
export const boldWeight = 700;
export const blackWeight = 900;

// Timeouts
export const regularTimeout = 750;

// Colors
export const ltBlueGrey = '#B9D3DC';
export const ltBlueGrey_34 = 'rgba(185, 211, 220, 0.34)';
export const ltBlueGrey_15 = 'rgba(185, 211, 220, 0.15)';
export const ltBlueGrey_8 = 'rgba(185, 211, 220, 0.8)';
export const ltBlueGrey_3 = 'rgba(185, 211, 220, 0.3)';
export const ltBlueGrey_7 = 'rgba(185,211,220,0.7)';
export const ltBlueGrey_6 = 'rgba(185,211,220,0.6)';

export const white_5 = 'rgba(255, 255, 255, 0.5)';
export const white_75 = 'rgba(255, 255, 255, 0.75)';
export const black_2 = 'rgba(0,0,0,0.2)';
export const black_5 = 'rgba(0, 0, 0, 0.5)';
export const black_8 = 'rgba(0, 0, 0, 0.08)';

export const ballLtGray = '#D9D9D6';
export const ballLtGray_15 = 'rgba(217, 217, 214, 0.15)';
export const ballLtGray_3 = 'rgba(217, 217, 214, 0.3)';
export const ballLtGray_2 = 'rgba(217, 217, 214, 0.2)';

export const ballBlack = '#202020';
export const ballBlack_5 = 'rgba(32,32,32, 0.05)';

export const ballBlue = '#009BBC';
export const ballLtBlue = 'rgba(0, 155, 187, 0.2)';
export const ballGray = '#75787B';
export const ballGrayBlue = 'rgba(0, 98, 114, 0.5)';

export const ballGreen = '#83B341';

export const infoDark = '#1976D2';
export const infoMain = '#2196F3';

export const yellowPill = '#FFC300';
export const greenPill = '#008000';
export const redPill = '#FF0000';
export const visitedLink = '#643385';
export const ballDrkBlue = '#006272';
export const tealPill = '#006272';
export const ltYellowPill = '#FFD63533';
export const ltYellowPillText = '#6C5702';

export const playground = '#106921';
export const darkGreen = '#0E4518';

export const aliceBlue = '#F5F8FA';
export const ltAliceBlue = '#F9FBFC';
export const ivory = '#F3F3F2';
export const cerulean = '#007A9F';
export const silver = '#BDBDBD';
export const brightGrey = '#555659';
export const tooltipBackground = '#292929';
export const white = 'rgb(255, 255, 255)';
export const yellow = 'rgb(243,188,55)';
export const orange = '#E45628';
export const paleBlue = '#D5E5EA';
export const successGreen = '#83B341';
export const paleGrey = '#F1F1F1';
export const black = '#000000';
export const vanilla = '#FFF7D7';
export const red = '#FF0000';
export const warningLabel = '#FFF7D7';
export const warningBorder = '#ED9D19';
export const campaignLabel = '#D9E7EA';
export const campaignBorder = '#006272';
export const informationAlert = '#EEF5F7';
export const errorBackground = '#FADDD4';
export const tableGrey = '#DBDDE0';
export const yellowNoticeBackground = '#FFFBEE';
export const yellowNoticeBackgroundBorder = '#ED9D19';
export const cancelRed = '#D0021B';

export const inputBorderGray = '#9C9C9B';
export const datepickerHoverGray = '#363B3E';
export const datepickerDefaultGray = '#E4E7E7';
export const darkBorderGrey = '#979797';

export const inactiveTabBlue = '#EBF2F5';
export const lightBlue = '#F2F9FA';
export const brightBlue = ' #1852FF';
export const addFormItemButtonBlue = '#009BBB';

export const statusTrackerGray = '#CBCED2';
export const detailBackgroundGray = '#F5F9FA';
export const navBackgroundGray = '#F5F9FA91';

// shipment colors.
export const dateOptionInvalidGray = '#8E9295';
export const shipPurp = '#643385';
export const shipOrange = '#DE7C00';
export const shipYellow = '#FFD635';
export const shipGreen = '#83B341';
export const shipBlue = '#3160E0';
export const shipTeal = '#0CB6B2';
export const shipWine = '#9F1E54';
export const shipRed = '#E03C31';
export const shipAltOrange = '#EFAB15';
export const shipAltGreen = '#59CB85';
export const shipAltPurp = '#A454DA';
export const shipAltBlue = '#35A6F1';
export const shipAltTeal = '#0FE9E4';
export const shipLitePurp = '#AF96C0';
export const shipLiteYellow = '#FFECA2';
export const shipLiteOrange = '#EFC186';
export const shipLiteGreen = '#FFECA2';
export const shipLiteBlue = '#ADC0F3';
export const shipLiteWine = '#D194AE';
export const shipLiteRed = '#F1A7A2';
export const shipLiteAltOrange = '#F7D790';
export const shipLiteAltGreen = '#B0E6C5';
export const shipLiteAltPurp = '#D2ABED';
export const shipLiteAltBlue = '#9FD4F8';
export const shipLiteAltTeal = '#A3F7F5';
export const shipWarningBlue = '#DDEAEE';

//product management
export const toggleActivationBg = '#F7F7F7';

// status colors
export const statusLtGreen = 'rgba(89, 203, 133, 0.2)';
export const statusGreen = '#01501F';

// ship it configuration constants
export const freightFormula =
    '((25 xx Qty Per Pall\\et xx F\\reight Rate)/1000)-[#Pall\\et Shipp\\i\\ng xx ((Qty Per Pall\\et xx F\\reight Rate)/(1000))]';
export const atmFreightFormula =
    '((27 xx Qty Per Pall\\et xx F\\reight Rate)/1000)-[#Pall\\et Shipp\\i\\ng xx ((Qty Per Pall\\et xx F\\reight Rate)/(1000))]';
export const deliveryInstructionsMaxLength = 30;
export const fullTruckPallets = 25;
export const fullATMTruckPallets = 27;
export const shipItReferenceNumberMaxLength = 30;
export const shipItReleaseNumberMaxLength = 10;
export const shipItPONumberMaxLength = 25;
export const invalidAPIDate = '0001-01-01T00:00:00';
export const makeItCanBtlDefaultLeadTime = 12;
export const makeItEndsDefaultLeadTime = 4;
export const makeItRequestedShipDays = 30;

//Grid Names (for LocalStorage objects)
export const makeItGridName = 'Make It Grid';
export const shipItGridName = 'Ship It Grid';
export const makeItSummaryGridName = 'Make It Summary Grid';
export const planItGridName = 'Plan It Grid';

// Product Portfolio
export const aerosolProducts = [
    // 'BEBO',
    'BEBO2',
    'BEBO3',
    'ATB-E',
    'BEBO_BTL_8_250_NA',
    'BEBO_BTL_11_330_NA',
    'BEBO_BTL_15_440_NA',
    'BEBO2_BTL_8_250_NA',
    'BEBO2_BTL_11_330_NA',
    'BEBO2_BTL_12_355_NA',
    'BEBO3_BTL_8_250_NA',
    'BEBO3_BTL_11_330_NA',
    'BEBO3_BTL_12_355_NA',
    'BEBO3_BTL_16_473_NA',
    'ATBE_BTL_11_330_NA',
    'ATBE_BTL_12_355_NA',
    'ATBE_BTL_16_473_NA',
    'ATBE_BTL_18_532_NA',
    'ATBE_BTL_22_651_NA'
];
