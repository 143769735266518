import React from 'react';

interface Props {
    src: string;
    alt: string;
    className?: string;
}

const Image = (props: Props) => {
    return (
        <img src={props.src} alt={props.alt} className={props.className}></img>
    )
}

export default Image;