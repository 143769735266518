import React from 'react';
import {
    Grid,
    makeStyles,
    Typography,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableBody
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Link from '../../../reusable/atoms/Link';
import { Trans } from 'react-i18next';
import moment from 'moment';
import { LinkedProductionOrder } from '../../../../store/reducers/product-planning';
import { formattedMTDate, getWeek } from '../../../../utility/helpers/date-helpers';
import { Invoice } from '../../../../store/reducers/make-it-summary';
import { boldWeight, medium, red, xl } from '../../../../themes/globalConstants';
import {
    getOrderFirstRequestedDate,
    getOrderRequestedDate,
    getRevisedAvailableDate
} from '../../../../utility/helpers/pending-payment-helpers';
import { generateKey } from '../../../../utility/helpers/order-helpers';

interface Props {
    linkedPOs: LinkedProductionOrder;
    weekNumber: number;
}

const useStyles = makeStyles((theme) => ({
    main: {
        fontSize: medium,
        padding: '1em'
    },
    weekText: {
        marginLeft: '1em'
    },
    columnHeaders: {
        fontSize: xl,
        fontWeight: boldWeight
    },
    warningIcon: {
        color: red,
        marginRight: '.5em',
        verticalAlign: 'middle'
    },
    warningText: {
        color: red,
        marginLeft: '.5em'
    }
}));

const PendingPaymentModal = ({ linkedPOs, weekNumber }: Props) => {
    const classes = useStyles();
    const linkedProductionOrders = linkedPOs?.linkedProductionOrders;
    const linkedInvoices = linkedProductionOrders
        ?.map((po) => po.invoice)
        .filter((inv) => inv?.productionOrderId);
    const date = getWeek(weekNumber);

    const getPalletQuantity = (invoice?: Invoice) => {
        if (invoice?.productionOrderId) {
            let linkedPO = linkedProductionOrders?.find(
                (po) => po.productionOrderId === +invoice.productionOrderId
            );
            return linkedPO?.totalOrderQuantity;
        } else {
            return '';
        }
    };

    const getPaymentDueDateColumn = (invoice?: Invoice) => {
        if (invoice?.paymentDueDate) {
            let today = moment().startOf('day');
            let dueDate = moment(invoice?.paymentDueDate).startOf('day');
            if (dueDate.isBefore(today, 'day')) {
                return (
                    <Typography>
                        <img
                            src={process.env.PUBLIC_URL + '/assets/Error_Icon.svg'}
                            alt="Error Icon"
                            className={classes.warningIcon}
                        />
                        <span>
                            {formattedMTDate(moment(invoice?.paymentDueDate), 'MM/DD/YYYY')}
                        </span>
                        <span className={classes.warningText}>(Late)</span>
                    </Typography>
                );
            } else {
                return (
                    <Typography>
                        {formattedMTDate(moment(invoice?.paymentDueDate), 'MM/DD/YYYY')}
                    </Typography>
                );
            }
        }
        return '';
    };

    return (
        <Grid container className={classes.main}>
            <Grid item xs={12}>
                <Typography variant="body1" className={classes.weekText}>
                    <Trans i18nKey="weekOfWithDate">Week of {{ date }}</Trans>
                </Typography>
            </Grid>
            <Grid container item xs={12}>
                <Table
                    size="medium"
                    aria-label="pending payment table"
                    key={generateKey('pending-payment-table')}
                    data-testid="pending-payment-table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell
                                colSpan={2}
                                className={classes.columnHeaders}
                                align="left"
                                data-testid="production-orders"
                            >
                                <Trans i18nKey="productionOrders">Production Order(s)</Trans>
                            </TableCell>
                            <TableCell
                                colSpan={3}
                                className={classes.columnHeaders}
                                align="left"
                                data-testid="payment-due-date"
                            >
                                <Trans i18nKey="paymentDueDate">Payment Due Date</Trans>
                            </TableCell>
                            <TableCell
                                colSpan={2}
                                className={classes.columnHeaders}
                                align="left"
                                data-testid="original-requested-date"
                            >
                                <Trans i18nKey="originalRequestedDate">
                                    Original Requested Date
                                </Trans>
                            </TableCell>
                            <TableCell
                                colSpan={2}
                                className={classes.columnHeaders}
                                align="left"
                                data-testid="revised-available-date"
                            >
                                <Trans i18nKey="revisedAvailableDate">Revised Available Date</Trans>
                            </TableCell>
                            <TableCell
                                colSpan={1}
                                className={classes.columnHeaders}
                                align="right"
                                data-testid="pallets"
                            >
                                <Trans i18nKey="pallets">Pallets</Trans>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {linkedPOs &&
                            linkedProductionOrders &&
                            !!linkedInvoices?.length &&
                            linkedInvoices.map((invoice, index) => (
                                <TableRow key={index}>
                                    <TableCell colSpan={2} align="left">
                                        <Link
                                            component={RouterLink}
                                            underline="always"
                                            to={`/production-order/${invoice?.productionOrderId}`}
                                        >
                                            {invoice?.productionOrderNumber ?? (
                                                <Trans i18nKey="pending">Pending</Trans>
                                            )}
                                        </Link>
                                    </TableCell>
                                    <TableCell colSpan={3} align="left">
                                        {getPaymentDueDateColumn(invoice)}
                                    </TableCell>
                                    <TableCell colSpan={2} align="left">
                                        {getOrderFirstRequestedDate(invoice)}
                                    </TableCell>
                                    <TableCell colSpan={2} align="left">
                                        {getRevisedAvailableDate(invoice)}
                                    </TableCell>
                                    <TableCell align="right">
                                        {getPalletQuantity(invoice)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        {linkedPOs &&
                            linkedProductionOrders &&
                            !linkedInvoices?.length &&
                            linkedProductionOrders.map((order, index) => (
                                <TableRow key={index}>
                                    <TableCell colSpan={2} align="left">
                                        <Link
                                            component={RouterLink}
                                            underline="always"
                                            to={`/production-order/${order?.productionOrderId}`}
                                        >
                                            {order?.productionOrderNumber ?? (
                                                <Trans i18nKey="pending">Pending</Trans>
                                            )}
                                        </Link>
                                    </TableCell>
                                    <TableCell colSpan={3} align="left">
                                        TBD
                                    </TableCell>
                                    <TableCell colSpan={2} align="left">
                                        {getOrderRequestedDate(order)}
                                    </TableCell>
                                    <TableCell colSpan={2} align="left">
                                        TBD
                                    </TableCell>
                                    <TableCell align="right">{order.totalOrderQuantity}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    );
};
export default PendingPaymentModal;
