import {
    SHIPPING_SUMMARY_LOAD_OPEN,
    SHIPPING_SUMMARY_LOAD_CLOSED,
    SHIPPING_SUMMARY_LOADING_ERROR,
    SHIPPING_SUMMARY_LOADING,
    SHIPPING_SUMMARY_UPDATED,
    SHIPPING_SUMMARY_RESET,
    SHIPPING_SUMMARY_LOADING_CLOSED_ERROR,
    SHIPPING_SUMMARY_LOADING_OPEN_ERROR
} from '../actions/action-types';
import { DeliveryOrder } from './shipping-dashboard';

export interface Orders {
    summaries: DeliveryOrder[];
}

export interface ShippingSummaryState {
    openDeliveryOrders: DeliveryOrder[];
    openDeliveryOrdersLoaded: boolean;
    closedDeliveryOrders: DeliveryOrder[];
    closedDeliveryOrdersLoaded: boolean;
    loading: boolean;
    loaded: boolean;
    error?: any;
}

const initialState: ShippingSummaryState = {
    openDeliveryOrders: [],
    openDeliveryOrdersLoaded: false,
    closedDeliveryOrders: [],
    closedDeliveryOrdersLoaded: false,
    loading: false,
    loaded: false
};

export interface DeliveryOrderRow extends DeliveryOrder {
    shipToName?: string;
    shipToAddress?: string;
    shipmentDeliveryDateTime?: string;
    formattedShipmentDeliveryDateTime?: string;
    orderQuantityText?: string;
    orderQuantityContent?: any;
    pristineDeliveryOrder?: DeliveryOrder;
}

const shippingSummary = (
    state: ShippingSummaryState = initialState,
    action: { type: string; [x: string]: any }
): ShippingSummaryState => {
    switch (action.type) {
        case SHIPPING_SUMMARY_LOADING:
            return {
                ...state,
                loaded: false,
                loading: true,
                error: undefined,
                openDeliveryOrdersLoaded: false,
                closedDeliveryOrdersLoaded: false
            };
        case SHIPPING_SUMMARY_LOADING_ERROR:
            return { ...state, loaded: true, loading: false, error: action.error };
        case SHIPPING_SUMMARY_LOADING_OPEN_ERROR:
            return {
                ...state,
                loaded: true,
                loading: false,
                error: action.error,
                openDeliveryOrdersLoaded: true
            };
        case SHIPPING_SUMMARY_LOADING_CLOSED_ERROR:
            return {
                ...state,
                loaded: true,
                loading: false,
                error: action.error,
                closedDeliveryOrdersLoaded: true
            };
        case SHIPPING_SUMMARY_LOAD_OPEN:
            return { ...state, openDeliveryOrders: action.orders, openDeliveryOrdersLoaded: true };
        case SHIPPING_SUMMARY_UPDATED:
            return { ...state, openDeliveryOrders: action.orders };
        case SHIPPING_SUMMARY_LOAD_CLOSED:
            return {
                ...state,
                closedDeliveryOrders: action.orders,
                closedDeliveryOrdersLoaded: true
            };
        case SHIPPING_SUMMARY_RESET:
            return {
                ...initialState
            };
        default:
            return state;
    }
};

export default shippingSummary;
