import React from 'react';
import PdfPageTemplate from '../../../templates/PdfPageTemplate';
import { Document, Page, Text, View, BlobProvider, Image } from '@react-pdf/renderer';
import pdfStyles from '../../../../themes/pdfStyles';
import {
    formatFromDatetime,
    formatUTCToDateDisplay
} from '../../../../utility/helpers/date-helpers';
import { isIE } from '../../../../utility/helpers/user-helpers';
import { Trans, useTranslation } from 'react-i18next';
import { MakeItBulkOrder } from '../../../../store/reducers/makeit-bulk-upload';
import moment from 'moment';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { getDesc } from '../../../../utility/helpers/production-order-helpers';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { getAccountAddress } from '../../../../utility/helpers/address-helpers';
import {
    isProductCanType,
    getEachesForOrder,
    getTotalQuantityEaches
} from '../../../../utility/helpers/order-helpers';
import { ProductType } from '../../../../utility/services/orders-service';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { formatNumberWithLocale } from '../../../../utility/helpers/formatters';

interface Props {
    title: string;
    orders: MakeItBulkOrder[];
}

const MakeItBulkUploadConfirmationPDF = ({ title, orders }: Props) => {
    const ballLogo = process.env.PUBLIC_URL + '/assets/Ball_RGB.jpg';
    const sourceLogo = process.env.PUBLIC_URL + '/assets/TheSource_Logo.png';
    const { t } = useTranslation();
    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const getShipToName = (shipToId: number) => {
        if (shipToAccounts) {
            let shipTo = shipToAccounts.find((acc) => acc.accountId === shipToId.toString());
            return shipTo ? shipTo.name : '';
        } else {
            return '';
        }
    };

    const getShipToAddress = (shipToId: number) => {
        if (shipToAccounts) {
            let shipTo = shipToAccounts.find((acc) => acc.accountId === shipToId.toString());
            return shipTo ? getAccountAddress(shipTo) : '';
        } else {
            return '';
        }
    };

    const getTotalQuantity = (type: ProductType): String => {
        let pallets = 0;
        let skus = 0;
        if (isProductCanType(type)) {
            pallets = 0;
            skus = 0;
            orders?.forEach((order) => {
                pallets += order?.canQuantityPallets ? order.canQuantityPallets : 0;
                skus += order?.canQuantitySKUs ? order?.canQuantitySKUs : 0;
            });
        } else {
            pallets = 0;
            skus = 0;
            orders?.forEach((order) => {
                pallets += order?.endQuantityPallets ? order.endQuantityPallets : 0;
                skus += order?.endQuantitySKUs ? order?.endQuantitySKUs : 0;
            });
        }
        return `${pallets} PL/${skus} ${skus > 1 ? t('skus', 'SKUs') : t('sku', 'SKU')}`;
    };

    const lines = (order: MakeItBulkOrder) =>
        order.lines?.map((line, index) => {
            return (
                <>
                    <View style={[pdfStyles.tableRow]} key={index}>
                        <View style={pdfStyles.borderLessTableColNarrow3}>
                            <Text style={[pdfStyles.tableCell2]}>
                                {line.displayId}
                                {line.graphicIdAndVersion && ' / '}
                                {line.graphicIdAndVersion}
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColWide7}>
                            <Text style={[pdfStyles.tableCell2]}>
                                {line.displayName}
                                {' / '}
                                {getDesc(line)}
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow9}>
                            <Text style={[pdfStyles.tableCell2]}>{line.productCoating}</Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow2}>
                            <Text style={[pdfStyles.tableCell2]}>
                                {formatUTCToDateDisplay(line.requestedDate)}
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow9}>
                            <Text style={[pdfStyles.tableCell2, pdfStyles.textAlignRight]}>
                                {line.palletQuantity}
                                {' PL '}
                                {formatNumberWithLocale(
                                    cultureCode,
                                    line.palletQuantity && line.quantityPerPallet
                                        ? line.quantityPerPallet * line.palletQuantity
                                        : 0
                                )}{' '}
                                ea.
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.dividerThinGray} />
                </>
            );
        });

    const customerPoNum = (poNum: string) => {
        let poNumChunks = poNum.match(/.{1,10}/g);
        return (
            <>
                {poNumChunks?.map((chunk: string, i: number) => {
                    return (
                        <Text style={[pdfStyles.subtitle3]} key={i}>
                            {chunk}
                        </Text>
                    );
                })}
            </>
        );
    };

    const ordersList = orders.map((order) => {
        return (
            <>
                <View style={[pdfStyles.borderlessTable, pdfStyles.marginTop20]}>
                    <View style={[pdfStyles.tableRow, pdfStyles.marginBottom5]}>
                        <View style={[pdfStyles.borderLessTableColNarrow2]}>
                            <Text style={[pdfStyles.header2]}>
                                {t('poNumber', 'Customer PO #').toLocaleUpperCase()}
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColWide5}>
                            <Text style={pdfStyles.header2}>
                                {t('shipTo', 'Ship To').toLocaleUpperCase()}
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow3}>
                            <Text style={[pdfStyles.header2]}>
                                {t('canQuantity', 'Can Quantity').toLocaleUpperCase()}
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow2}>
                            <Text style={[pdfStyles.header2]}>
                                {t('endQuantity', 'End Quantity').toLocaleUpperCase()}
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                        <View style={pdfStyles.borderLessTableColNarrow2}>
                            {customerPoNum(order.customerProductionOrderId)}
                        </View>
                        <View style={pdfStyles.borderLessTableColWide5}>
                            <Text style={[pdfStyles.subtitle3]}>
                                {getShipToName(order.shipToId)}
                            </Text>
                            <Text style={[pdfStyles.subtitle3]}>
                                {getShipToAddress(order.shipToId)}
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow3}>
                            <Text style={[pdfStyles.subtitle3]}>
                                {`${order.canQuantityPallets?.toLocaleString()} PL / ${
                                    order.canQuantitySKUs
                                } ${order.canQuantitySKUs!! > 1 ? ' SKUs' : ' SKU'}`}
                            </Text>
                            <Text style={[pdfStyles.subtitle3]}>
                                {formatNumberWithLocale(
                                    cultureCode,
                                    getEachesForOrder(order, ProductType.Cans)
                                ) + ` ea.`}
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow2}>
                            <Text style={[pdfStyles.subtitle3]}>
                                {`${order.endQuantityPallets?.toLocaleString()} PL / ${
                                    order.endQuantitySKUs
                                } ${order.endQuantitySKUs!! > 1 ? ' SKUs' : ' SKU'}`}
                            </Text>
                            <Text style={[pdfStyles.subtitle3]}>
                                {formatNumberWithLocale(
                                    cultureCode,
                                    getEachesForOrder(order, ProductType.Ends)
                                ) + ` ea.`}
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={pdfStyles.paragraph}>
                    <Text></Text>
                </View>
                <View style={pdfStyles.borderlessTable}>
                    <View style={pdfStyles.tableRow}>
                        <View style={[pdfStyles.borderLessTableColNarrow3]}>
                            <Text style={[pdfStyles.header2]}>
                                {t(
                                    'productGraphicId',
                                    'PRODUCT ID / GRAPHIC ID'
                                ).toLocaleUpperCase()}
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColWide7}>
                            <Text style={pdfStyles.header2}>
                                {t('productDescription', 'PRODUCT DESCRIPTION').toLocaleUpperCase()}
                                /{t('characteristics', 'CHARACTERISTICS').toLocaleUpperCase()}
                            </Text>
                        </View>
                        <View style={[pdfStyles.borderLessTableColNarrow9]}>
                            <Text style={[pdfStyles.header2]}>
                                {t('coating', 'Coating').toLocaleUpperCase()}
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow2}>
                            <Text style={[pdfStyles.header2]}>
                                {t('requestedDate', 'Requested Date').toLocaleUpperCase()}
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow9}>
                            <Text style={[pdfStyles.header2]}>
                                {t('quantityOrdered', 'Quantity Ordered').toLocaleUpperCase()}
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.dividerThinGray} />
                    {lines(order)}
                </View>
                <View style={pdfStyles.paragraph}>
                    <Text></Text>
                </View>
            </>
        );
    });

    const myDoc = (
        <Document title={title}>
            <Page size="A4" style={pdfStyles.page} orientation={'portrait'}>
                <View style={pdfStyles.logoSection}>
                    <Image src={sourceLogo} style={pdfStyles.sourceLogo} />
                    <Image src={ballLogo} style={pdfStyles.ballLogo} />
                </View>
                <View style={pdfStyles.divider6} fixed />

                <View style={[pdfStyles.flexSection, pdfStyles.pageHeader]}>
                    <View>
                        <Text style={pdfStyles.header7}>
                            {t('makeIt', 'MAKE IT').toLocaleUpperCase()}
                        </Text>
                    </View>
                    <View>
                        <View>
                            <Text style={pdfStyles.fieldLabel}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="dateSubmitted">Date Submitted</Trans>
                                </Text>
                            </Text>
                        </View>
                        <View>
                            <View style={pdfStyles.subtitle6}>
                                <Text style={[pdfStyles.subtitle3]}>
                                    {formatFromDatetime(moment().format())}
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View style={pdfStyles.width15}></View>
                    <View style={pdfStyles.width15}></View>
                    <View>
                        <View>
                            <Text style={pdfStyles.fieldLabel}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="canQuantity">Can Quantity</Trans>
                                </Text>
                            </Text>
                        </View>
                        <View>
                            <View style={pdfStyles.subtitle6}>
                                <Text style={[pdfStyles.subtitle3]}>
                                    {getTotalQuantity(ProductType.Cans)}
                                </Text>
                                <Text style={[pdfStyles.subtitle3]}>
                                    {formatNumberWithLocale(
                                        cultureCode,
                                        getTotalQuantityEaches(ProductType.Cans, orders)
                                    ) + ` ea.`}
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View>
                        <View>
                            <Text style={pdfStyles.fieldLabel}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="endQuantity">End Quantity</Trans>
                                </Text>
                            </Text>
                        </View>
                        <View>
                            <View style={pdfStyles.subtitle6}>
                                <Text style={[pdfStyles.subtitle3]}>
                                    {getTotalQuantity(ProductType.Ends)}
                                </Text>
                                <Text style={[pdfStyles.subtitle3]}>
                                    {formatNumberWithLocale(
                                        cultureCode,
                                        getTotalQuantityEaches(ProductType.Ends, orders)
                                    ) + ` ea.`}
                                </Text>
                            </View>
                        </View>
                    </View>

                    <View style={pdfStyles.divider6} fixed />
                    {ordersList}
                </View>
                <View style={[pdfStyles.fullWidth, pdfStyles.footer]} fixed>
                    <Text
                        style={pdfStyles.header2}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </View>
            </Page>
        </Document>
    );

    if (!isIE()) {
        return <PdfPageTemplate title={title}>{myDoc}</PdfPageTemplate>;
    } else {
        return (
            <BlobProvider document={myDoc}>
                {({ blob, url, loading, error }) => {
                    if (blob && window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob);
                    }
                    return null;
                }}
            </BlobProvider>
        );
    }
};

export default MakeItBulkUploadConfirmationPDF;
