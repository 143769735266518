import React from 'react';
import { makeStyles, TableRow, TableCell, Grid, Button, Typography } from '@material-ui/core';
import {
    ballBlue,
    blackWeight,
    boldWeight,
    medium,
    red,
    yellowNoticeBackground,
    yellowNoticeBackgroundBorder
} from '../../../../themes/globalConstants';
import { PendingRequestTableRow } from '../../../../store/reducers/user-admin';
import { Trans } from 'react-i18next';
import Link from '../../../reusable/atoms/Link';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
    request: PendingRequestTableRow;
    hasAdminPersona: boolean;
}

const useStyles = makeStyles((theme) => ({
    userName: {
        fontWeight: boldWeight,
        fontSize: medium,
        letterSpacing: 0.17,
        textTransform: 'lowercase'
    },
    name: {
        fontWeight: boldWeight,
        fontSize: medium,
        letterSpacing: 0.17
    },
    editButton: {
        fontSize: '1em',
        '&:hover': {
            backgroundColor: 'transparent',
            color: ballBlue,
            fontWeight: blackWeight,
            outline: 'none'
        },
        '&:focus': {
            backgroundColor: 'transparent',
            color: ballBlue,
            fontWeight: blackWeight
        }
    },
    disableButton: {
        color: red,
        fontSize: '1em',
        '&:hover': {
            backgroundColor: 'transparent',
            color: red,
            fontWeight: blackWeight,
            outline: 'none'
        },
        '&:focus': {
            backgroundColor: 'transparent',
            color: red,
            fontWeight: blackWeight
        }
    },
    noAdminContainer: {
        backgroundColor: yellowNoticeBackground,
        borderWidth: 1,
        borderColor: yellowNoticeBackgroundBorder,
        borderStyle: 'solid'
    }
}));

export default function UserAdminPendingRequestsRow({ request, hasAdminPersona }: Props) {
    const classes = useStyles();

    return (
        <TableRow hover={true} key={request.requestId}>
            <TableCell data-testid={'user-name'}>
                <Grid container>
                    <Grid item xs={12} className={classes.userName}>
                        <Typography>{request.userName}</Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell data-testid={'roles'}>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        <Typography>{request.roles}</Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell data-testid={'location'}>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        <Typography>{request.accountName}</Typography>
                        <Typography>{request.locationAddress}</Typography>
                        <Typography>
                            {'#'}
                            {request.accountId}
                        </Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell data-testid={'sent-on'}>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        <Typography>{request.sentOn}</Typography>
                    </Grid>
                </Grid>
            </TableCell>

            {hasAdminPersona &&
                (request.currentUserHasAdminPermission ? (
                    <TableCell>
                        <Link
                            component={RouterLink}
                            to={`/user-admin-review-request/${request.requestId}`}
                            underline="none"
                        >
                            <Button
                                variant="text"
                                color="primary"
                                data-testid="view-request-button"
                                className={classes.editButton}
                            >
                                <Trans i18nKey="reviewRequest">Review Request</Trans>
                            </Button>
                        </Link>
                    </TableCell>
                ) : (
                    <TableCell className={classes.noAdminContainer}>
                        <Trans i18nKey="noAdminforLocation">
                            You are not an Admin for this Location.
                        </Trans>
                    </TableCell>
                ))}
        </TableRow>
    );
}
