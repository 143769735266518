import React from 'react';
import { Trans } from 'react-i18next';
import ConfirmationModal from './ConfirmationModal';

interface Props {
    onConfirmCancel: () => void;
    onClose: () => void;
    open: boolean;
}

const CancelModal = ({ open, onClose, onConfirmCancel }: Props) => {
    return (
        <ConfirmationModal
            saveProgress={onConfirmCancel}
            onClose={onClose}
            logo={false}
            title={<Trans i18nKey="cancel">Cancel</Trans>}
            subheader={<Trans i18nKey="confirmCancel">Are you sure you want to cancel?</Trans>}
            continueText={<Trans i18nKey="yesCancel">Yes, Cancel</Trans>}
            cancelText={<Trans i18nKey="no">No</Trans>}
            open={open}
            navigationLink=""
            onCancel={onClose}
        />
    );
};

export default CancelModal;
