import Axios, { AxiosRequestConfig } from 'axios';

export interface MessageData {
    fillCountry: string;
    fillCity?: string;
    region: string;
    email: string;
    name: string;
    subject: any;
    message: any;
}

export interface EstimateMessageData extends MessageData {
    products: EstimateProductDetails[];
}

export interface EstimateProductDetails {
    productCode: string;
    annualQuantity: number;
    // endsWithProduct: boolean;
}

export default class ContactService {
    static sendMessage = (requestPayload: any, axiosConfig?: AxiosRequestConfig) => {
        return Axios.post(
            `${process.env.REACT_APP_MESSAGE_API}/messages`,
            requestPayload,
            axiosConfig
        );
    };

    static sendHelpMessage = (requestPayload: any, captchaValue: string) => {
        return Axios.post(
            `${process.env.REACT_APP_MESSAGE_API}/messages/help`,
            requestPayload,
            {
                headers: { CAPTCHA_VALUE: captchaValue }
            }
        );
    };

    static sendEstimateMessage = (requestPayload: any, axiosConfig?: AxiosRequestConfig) => {
        return Axios.post(
            `${process.env.REACT_APP_MESSAGE_API}/messages/estimates`,
            requestPayload,
            axiosConfig
        );
    };

    static sendRegistrationConfirmationEmail(requestPayload: any, axiosConfig?: AxiosRequestConfig) {
        return Axios.post(
            `${process.env.REACT_APP_MESSAGE_API}/messages/confirmations`,
            requestPayload,
            axiosConfig
        ).catch(() => {
            // fire and forget, eat error
        });
    }
}
