import React from 'react';
import { Trans } from 'react-i18next';
import {
    BeverageCategory,
    BeverageContents,
    IntakeStatus,
    NewOrExistingStatus
} from '../../store/reducers/liquid-intake';
import { Culture } from '../../store/reducers/region-culture';
import TranslationService from '../services/translation-service';

export const getNewOrExistingStatus = (newOrExisting?: NewOrExistingStatus) => {
    if (newOrExisting) {
        switch (newOrExisting) {
            case NewOrExistingStatus.New:
                return <Trans i18nKey="newProduct">New Product</Trans>;
            case NewOrExistingStatus.Existing:
                return <Trans i18nKey="existingProduct">Existing Product</Trans>;
        }
    }
};

export const getBeverageCategory = (
    beverageCategory: BeverageCategory,
    cultureCode: Culture
): string | undefined => {
    switch (beverageCategory) {
        case BeverageCategory.Beer:
            return TranslationService.getTranslatedText(cultureCode, 'beverageCategoryBeer');
        case BeverageCategory.BeerCraft:
            return TranslationService.getTranslatedText(cultureCode, 'beverageCategoryBeerCraft');
        case BeverageCategory.BeerDomestic:
            return TranslationService.getTranslatedText(
                cultureCode,
                'beverageCategoryBeerDomestic'
            );
        case BeverageCategory.BeerImport:
            return TranslationService.getTranslatedText(cultureCode, 'beverageCategoryBeerImport');
        case BeverageCategory.Cider:
            return TranslationService.getTranslatedText(cultureCode, 'beverageCategoryCider');
        case BeverageCategory.Coffee:
            return TranslationService.getTranslatedText(cultureCode, 'beverageCategoryCoffee');
        case BeverageCategory.Csd:
            return TranslationService.getTranslatedText(cultureCode, 'beverageCategoryCsd');
        case BeverageCategory.EnergyDrink:
            return TranslationService.getTranslatedText(cultureCode, 'beverageCategoryEnergyDrink');
        case BeverageCategory.Fab:
            return TranslationService.getTranslatedText(cultureCode, 'beverageCategoryFab');
        case BeverageCategory.Fmb:
            return TranslationService.getTranslatedText(cultureCode, 'beverageCategoryFmb');
        case BeverageCategory.HardLiquor:
            return TranslationService.getTranslatedText(cultureCode, 'beverageCategoryHardLiquor');
        case BeverageCategory.IcedCoffee:
            return TranslationService.getTranslatedText(cultureCode, 'beverageCategoryIcedCoffee');
        case BeverageCategory.IcedTea:
            return TranslationService.getTranslatedText(cultureCode, 'beverageCategoryIcedTea');
        case BeverageCategory.Juice:
            return TranslationService.getTranslatedText(cultureCode, 'beverageCategoryJuice');
        case BeverageCategory.Other:
            return TranslationService.getTranslatedText(cultureCode, 'beverageCategoryOther');
        case BeverageCategory.Protein:
            return TranslationService.getTranslatedText(cultureCode, 'beverageCategoryProtein');
        case BeverageCategory.Rtd:
            return TranslationService.getTranslatedText(cultureCode, 'beverageCategoryRtd');
        case BeverageCategory.Spirits:
            return TranslationService.getTranslatedText(cultureCode, 'beverageCategorySpirits');
        case BeverageCategory.SportsDrink:
            return TranslationService.getTranslatedText(cultureCode, 'beverageCategorySportsDrink');
        case BeverageCategory.Tea:
            return TranslationService.getTranslatedText(cultureCode, 'beverageCategoryTea');
        case BeverageCategory.Thc:
            return TranslationService.getTranslatedText(cultureCode, 'beverageCategoryThc');
        case BeverageCategory.Unknown:
            return TranslationService.getTranslatedText(cultureCode, 'beverageCategoryOther');
        case BeverageCategory.Water:
            return TranslationService.getTranslatedText(cultureCode, 'beverageCategoryWater');
        case BeverageCategory.Wine:
            return TranslationService.getTranslatedText(cultureCode, 'beverageCategoryWine');
    }
};

export const getBeverageContents = (
    cultureCode: Culture,
    beverageContentList?: BeverageContents[]
): string | undefined => {
    if (beverageContentList) {
        const contents = beverageContentList.map((item) => {
            switch (item) {
                case BeverageContents.AceticAcid:
                    return TranslationService.getTranslatedText(cultureCode, 'aceticAcid');
                case BeverageContents.AddedSulfites:
                    return TranslationService.getTranslatedText(cultureCode, 'addedSulfites');
                case BeverageContents.CalciumLactate:
                    return TranslationService.getTranslatedText(cultureCode, 'calciumLactate');
                case BeverageContents.FoodDyes:
                    return TranslationService.getTranslatedText(cultureCode, 'foodDyes');
                case BeverageContents.LacticAcid:
                    return TranslationService.getTranslatedText(cultureCode, 'lacticAcid');
                case BeverageContents.MalicAcid:
                    return TranslationService.getTranslatedText(cultureCode, 'malicAcid');
                case BeverageContents.Unknown:
                    return TranslationService.getTranslatedText(cultureCode, 'unknown');
            }
            return null;
        });
        return contents.join(', ');
    }
};

export const getLiquidIntakeStatus = (
    cultureCode: Culture,
    status?: IntakeStatus
): string | undefined => {
    switch (status) {
        case IntakeStatus.ARS:
            return TranslationService.getTranslatedText(cultureCode, 'intakeStatusARS');
        case IntakeStatus.NTR:
            return TranslationService.getTranslatedText(cultureCode, 'intakeStatusNTR');
        case IntakeStatus.RPT:
            return TranslationService.getTranslatedText(cultureCode, 'intakeStatusRPT');
        case IntakeStatus.WAQ:
            return TranslationService.getTranslatedText(cultureCode, 'intakeStatusWAQ');
        case IntakeStatus.WRA:
            return TranslationService.getTranslatedText(cultureCode, 'intakeStatusWRA');
        case IntakeStatus.WRR:
            return TranslationService.getTranslatedText(cultureCode, 'intakeStatusWRR');
    }
};

export const getProjectNameBeverageName = (
    projectName: string | undefined,
    beverageName: string | undefined
): string | undefined => {
    if (projectName && beverageName) {
        return `${projectName}/${beverageName}`;
    } else {
        return projectName ? projectName : beverageName;
    }
};
