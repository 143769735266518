import { UserPermission } from '../../store/reducers/auth';
import { Account } from '../../store/reducers/customer-context';
import { Persona } from '../../utility/auth/useSecurity';
import { AddressType } from '../../store/reducers/customer-context';

/**
 * Returns a filtered list of accounts that the user has admin persona for.
 * @param permissions
 * @param accounts
 */
export function getAdminAccounts(permissions: Array<UserPermission>, accounts: Array<Account>) {
    const adminAcounts = permissions?.find((p) => p.personaId === Persona.AccountAdmin);
    const shipTos = accounts?.filter((account) =>
        adminAcounts?.accountIds.includes(account.accountId)
    );

    return shipTos;
}

export function isBillToAccount(account: Account) {
    return account.addressType === AddressType.CX || account.addressType === AddressType.CB;
}

export function isShipToAccount(account: Account) {
    return account.addressType === AddressType.CX || account.addressType === AddressType.CS;
}

export function isShipToAndBillToAccount(account: Account) {
    return account.addressType === AddressType.CX;
}
