import React, { useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { makeStyles, Typography, TableRow, TableCell } from '@material-ui/core';
import Button from '../../../reusable/atoms/Button';
import { blackWeight, ballLtGray } from '../../../../themes/globalConstants';
import { ProductWithPallets } from '../../../../store/reducers/shipping-dashboard';
import { getFormattedEaches } from '../../../../utility/helpers/shipment-helpers';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';

interface Props {
    item: ProductWithPallets;
    onAddItem: (item: ProductWithPallets, numberOfPallets: number, type: string) => void;
    disableAdd?: boolean;
}

const useStyles = makeStyles((theme) => ({
    tableRowContainer: {
        '& p:first-of-type': {
            fontWeight: blackWeight
        },
        '& .MuiInputBase-input': {
            textAlign: 'right',
            paddingRight: '.25em'
        },
        '& .MuiTableCell-root': {
            '&:not(:last-child)': {
                paddingLeft: '1.125em'
            }
        }
    },
    textField: {
        width: '4.25em',
        height: '1.5em',
        border: `2px solid ${ballLtGray}`,
        borderRadius: '.25em',
        marginRight: '0.5em',
        fontWeight: blackWeight
    },
    alignCenter: {
        textAlign: 'center'
    },
    alignRight: {
        textAlign: 'right'
    },
    paletteInputContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    addBtn: {
        padding: 0,
        height: '2.28em',
        width: '3.2em'
    },
    paddingLeft: {
        paddingLeft: '.5em'
    }
}));

export default function EditShipmentsAddProductRow({ item, onAddItem, disableAdd = false }: Props) {
    const classes = useStyles();
    const pallets = useMemo(() => item.inputPallets ?? 0, [item.inputPallets]);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const type = item.type!;
    const addItem = () => {
        onAddItem(item, pallets!, type);
    };

    useTranslation();

    return (
        <TableRow className={classes.tableRowContainer}>
            <TableCell>
                <Typography data-testid="product-sku">{item.displayId}</Typography>
                <Typography data-testid="graphic-id">{item.graphicId}</Typography>
            </TableCell>
            <TableCell>
                <Typography data-testid="display-name" variant="subtitle2">
                    {item.displayName}
                </Typography>
                <Typography data-testid="description">{item.description}</Typography>
            </TableCell>
            <TableCell className={classes.alignRight}>
                <Typography data-testid="available-pallets">
                    {Number(item.availablePallets).toLocaleString()}
                    {' PL'}
                </Typography>
                <Typography data-testid="available-items-per-pallet">
                    {`${getFormattedEaches(item, cultureCode)} ea.`}
                </Typography>
            </TableCell>
            <TableCell>
                <Button
                    type="button"
                    variant="outlined"
                    color="primary"
                    onClick={addItem}
                    disabled={item.availablePallets === 0 || disableAdd}
                    className={classes.addBtn}
                    data-testid="add-btn"
                >
                    <Trans i18nKey="add">add</Trans>
                </Button>
            </TableCell>
        </TableRow>
    );
}
