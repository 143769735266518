import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Grid, makeStyles, Paper } from '@material-ui/core';
import { Activity, hasAdminPersona } from '../../../utility/auth/useSecurity';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { useTypedSelector } from '../../../store/reducers/reducer';
import UserInformation from './components/UserInformation';
import { UserAdminState } from '../../../store/reducers/user-admin';
import { ShipToRoleAccess } from '../../reusable/molecules/LocationRoleAccessTable/models/role-access.model';
import {
    getTableUserPermissions,
    updateShipToRoleAccess
} from '../../../utility/helpers/user-admin-helpers';
import { LocationRoleAccessTable } from '../../reusable/molecules/LocationRoleAccessTable/LocationRoleAccessTable';
import { useDispatch } from 'react-redux';
import {
    clearUserPermissions,
    loadUserPermissions,
    updateUserPermissions
} from '../../../store/actions/user-admin';
import { AuthState } from '../../../store/reducers/auth';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import {
    getShipToAccounts,
    getUserCustomerAccounts
} from '../../../store/actions/customer-context';
import {
    withLocationRoleAccessProvider,
    useLocationRoleAccess
} from '../../reusable/molecules/LocationRoleAccessTable/context/LocationRoleAccessContext';
import { updateLocationRoleAccessAccounts } from '../../reusable/molecules/LocationRoleAccessTable/context/actions';
import { boldWeight } from '../../../themes/globalConstants';
import { useHistory } from 'react-router';
import ConfirmationModal from '../../reusable/molecules/ConfirmationModal';
import { enUS } from '../../../utility/translations/locales/en-US/en-US';
import WarningAlert from '../../reusable/atoms/WarningAlert';
import { getAdminAccounts } from '../../../utility/helpers/admin-helpers';

const useStyles = makeStyles((theme) => ({
    actionBtn: {
        height: '3.375em',
        width: '13.75em',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom'
    },
    container: {
        padding: '3em'
    },
    formSection: {
        marginTop: '1.5em'
    },
    formSectionContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    editBtnContainer: {
        marginTop: 15,
        marginRight: 25
    },
    paper: {
        marginBottom: '1em',
        padding: '3em 3em 0 3em'
    },
    titleContainer: {
        marginBottom: '1em'
    },
    title: {
        fontSize: '1.5em',
        fontWeight: boldWeight,
        color: theme.palette.secondary.main
    },
    locationTableWrapper: {
        marginBottom: '1.5em',
        paddingBottom: '1em'
    },
    alertContainer: {
        marginTop: '2em',
        width: '90%'
    }
}));

function UserAdminUserDetails() {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);
    const {
        userToEdit,
        permissions: adminPermissions,
        loaded,
        editable
    } = useTypedSelector<UserAdminState>((state) => state.userAdmin);
    const { accounts } = useTypedSelector<CustomerContextState>((state) => state.customerContext);
    const [accessAccounts, setAccessAccounts] = useState<Array<ShipToRoleAccess>>([]);
    const [addUserPermissionsLoaded, setAddUserPermissionsLoaded] = useState<boolean>(false);
    const [userLoaded, setUserLoaded] = useState<boolean>(loaded);
    const [readOnly, setReadOnly] = useState<boolean>(true);
    const [locationRoleAccessState, locationRoleAccessDispatch] = useLocationRoleAccess();
    const [userEdited, setUserEdited] = useState<boolean>(false);
    const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
    const [isUserAdmin, setIsUserAdmin] = useState<boolean>(false);
    const history = useHistory();

    const editClicked = () => {
        setReadOnly(!readOnly);
    };

    useEffect(() => {
        //clear on exit
        return () => {
            dispatch(clearUserPermissions());
        };
        // runs only once
    }, []);

    useEffect(() => {
        if (permissions) {
            const hasUserAdminPersona = hasAdminPersona(permissions);
            setIsUserAdmin(hasUserAdminPersona);

            if (!accounts?.length) {
                dispatch(getShipToAccounts());
            }
        }
    }, [dispatch, permissions, accounts]);

    useEffect(() => {
        if (permissions && !accounts?.length) {
            dispatch(getUserCustomerAccounts());
        }
    }, [permissions]);

    useEffect(() => {
        if (permissions && accounts && accounts.length && !accessAccounts.length) {
            const accts = accounts.map((account) => new ShipToRoleAccess(account));

            setAccessAccounts(accts);
        }
    }, [permissions, accounts]);

    useEffect(() => {
        if (!readOnly) {
            setUserEdited(true);
        }
    }, [locationRoleAccessState, readOnly]);

    const onCloseCancelModal = () => {
        setOpenCancelModal(false);
    };
    const onCancelRequest = () => {
        history.push(`/user-admin-dashboard?status=2`);
    };

    const onOpenCancelModal = () => {
        setOpenCancelModal(true);
    };

    const onSubmit = () => {
        if (accounts && permissions) {
            const roleAccessAccounts = locationRoleAccessState.accounts;
            const tableUserPermissions = getTableUserPermissions(roleAccessAccounts);

            const adminAccounts = getAdminAccounts(permissions, accounts);

            const permissionsToAdd = tableUserPermissions
                .map((tablePermission) => {
                    return {
                        ...tablePermission,
                        accountIds: tablePermission.accountIds.filter((account) =>
                            adminAccounts.map((acc) => acc.accountId).includes(account)
                        )
                    };
                })
                .filter((tablePermission) => tablePermission.accountIds.length > 0);

            dispatch(
                updateUserPermissions(userToEdit, permissionsToAdd, () => {
                    history.push(`/user-admin-dashboard?status=2`);
                })
            );
        }
    };

    useEffect(() => {
        if (loaded && accessAccounts.length && permissions && accounts && userLoaded) {
            const adminAccounts = getAdminAccounts(permissions, accounts);
            updateLocationRoleAccessAccounts(
                updateShipToRoleAccess(adminPermissions, accessAccounts, adminAccounts),
                locationRoleAccessDispatch
            );
            setAddUserPermissionsLoaded(true);
        }
    }, [
        adminPermissions,
        loaded,
        userLoaded,
        accessAccounts,
        locationRoleAccessDispatch,
        addUserPermissionsLoaded
    ]);

    useEffect(() => {
        updateLocationRoleAccessAccounts(accessAccounts, locationRoleAccessDispatch);
    }, [accessAccounts, locationRoleAccessDispatch]);

    useEffect(() => {
        if (!userLoaded) {
            dispatch(loadUserPermissions(userToEdit));
            setUserLoaded(true);
        }
    }, [dispatch, userLoaded, userToEdit]);

    const adminFooterActions = (
        <>
            <Grid item xs={2}>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="bottom-cancel-button"
                    onClick={onOpenCancelModal}
                    className={classes.actionBtn}
                >
                    <Trans i18nKey="cancel">Cancel</Trans>
                </Button>
            </Grid>
            {editable && (
                <Grid item xs={2}>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        data-testid="submit-button"
                        className={classes.actionBtn}
                        onClick={onSubmit}
                        disabled={!userEdited || !editable}
                    >
                        <Trans i18nKey="submit">Submit</Trans>
                    </Button>
                </Grid>
            )}
        </>
    );

    const nonAdminFooterActions = (
        <Grid item xs={2}>
            <Button
                type="button"
                variant="outlined"
                color="secondary"
                data-testid="bottom-back-button"
                onClick={onCancelRequest}
                className={classes.actionBtn}
            >
                <Trans i18nKey="back">Back</Trans>
            </Button>
        </Grid>
    );

    return (
        <>
            <ProcessingPageTemplate
                banner={{
                    header: t('viewEditUser', 'View/Edit User'),
                    description: '',
                    thinBanner: true,
                    displayDropdown: false
                }}
                actionFooter={{
                    footerAction: isUserAdmin ? adminFooterActions : nonAdminFooterActions,
                    justify: 'space-between',
                    sticky: true
                }}
                activity={Activity.UserAdminEditUser}
            >
                <Grid container justify="space-between">
                    {!isUserAdmin && (
                        <Grid
                            item
                            data-testid="new-user-added-success-alert"
                            className={classes.alertContainer}
                        >
                            <WarningAlert
                                warningMessage={t(
                                    'userAdminAccessRequired',
                                    'User Administrator permissions are required to make changes.'
                                )}
                                showWarning={!isUserAdmin}
                            />
                        </Grid>
                    )}
                    <Grid container item xs={12} className={classes.formSectionContainer}>
                        <Grid item xs={12} className={classes.formSection}>
                            {loaded && userToEdit && <UserInformation />}
                        </Grid>
                    </Grid>
                    {addUserPermissionsLoaded && (
                        <Paper elevation={2} className={classes.paper}>
                            <Grid container className={classes.locationTableWrapper}>
                                <Grid container item xs={12} justify="flex-start">
                                    <LocationRoleAccessTable readOnly={readOnly} />
                                    {isUserAdmin && readOnly && editable && (
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justify="flex-end"
                                            className={classes.editBtnContainer}
                                        >
                                            <Button
                                                type="button"
                                                color="primary"
                                                variant="contained"
                                                data-testid="edit-btn"
                                                className={classes.actionBtn}
                                                onClick={editClicked}
                                            >
                                                <Trans i18nKey="edit">Edit</Trans>
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Paper>
                    )}
                </Grid>
            </ProcessingPageTemplate>
            <ConfirmationModal
                logo={false}
                title={<Trans i18nKey="changesWillNotBeSaved">{enUS.changesWillNotBeSaved}</Trans>}
                subheader={<Trans i18nKey="areYouSureDiscard">{enUS.areYouSureDiscard}</Trans>}
                open={openCancelModal}
                continueText={<Trans i18nKey="yesDiscard">{enUS.yesDiscard}</Trans>}
                cancelText={<Trans i18nKey="no">{enUS.no}</Trans>}
                saveProgress={onCancelRequest}
                onCancel={onCloseCancelModal}
                onClose={onCloseCancelModal}
                data-testid="cancel-confirmation-modal"
            />
        </>
    );
}
export default withLocationRoleAccessProvider(UserAdminUserDetails);
