import React, { useEffect, useState } from 'react';

import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { Trans, useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { selectIsLargeCustomerAccount } from '../../../store/selectors';
import { useDispatch } from 'react-redux';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Activity, SecurityLevel } from '../../../utility/auth/useSecurity';
import { useParams } from 'react-router';
import { DefaultQueryParams, useQuery } from '../../../utility/helpers/query-helpers';
import { loadTradeItRequest } from '../../../store/actions/trade-it-details';
import { TRADEIT_REQUEST_DETAIL_RESET } from '../../../store/actions/action-types';
import { TradeRequestDetailState } from '../../../store/reducers/trade-it-details';
import { blackWeight } from '../../../themes/globalConstants';
import TradeRequestDetailsSubheader from './components/TradeRequestDetailsSubheader';
import TradeRequestDetailsGrid from './components/TradeRequestDetailsGrid';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '2em',
        borderRadius: 4,
        marginTop: '2em'
    },
    header: {
        marginTop: '1em',
        marginBottom: '2em'
    },
    title: {
        fontWeight: blackWeight,
        color: theme.palette.secondary.main,
        padding: '0 0 1em 0',
        textTransform: 'uppercase'
    }
}));

export default function TradeRequestDetails() {
    const { id } = useParams<DefaultQueryParams>();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const isLargeCustomerAccount = useTypedSelector<boolean>(selectIsLargeCustomerAccount);
    const query = useQuery();
    const shipToIds = query.get('shipToIds');
    const [requestShipToIds, setRequestShipToIds] = useState<string[] | undefined>(undefined);
    const { tradeRequest } = useTypedSelector<TradeRequestDetailState>(
        (state) => state.tradeItDetails
    );

    useEffect(() => {
        return () => {
            // clear state on page leave
            dispatch({ type: TRADEIT_REQUEST_DETAIL_RESET });
        };
    }, []);

    useEffect(() => {
        if (id && isLargeCustomerAccount && requestShipToIds && requestShipToIds.length === 2) {
            const tradeRequestId = parseInt(id);
            if (!isNaN(tradeRequestId)) {
                dispatch(loadTradeItRequest(tradeRequestId, requestShipToIds));
            }
        }
    }, [dispatch, id, requestShipToIds, isLargeCustomerAccount]);

    useEffect(() => {
        if (shipToIds) {
            const shipToIdsArray = JSON.parse(shipToIds);
            Array.isArray(shipToIdsArray) && setRequestShipToIds(shipToIdsArray);
        }
    }, [shipToIds]);

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('tradeIt', 'Trade It'),
                description: t('requestDetails', 'Request Details'),
                thinBanner: true,
                displayDropdown: false
            }}
            activity={Activity.TradeItDetails}
            restrictToSecurityLevel={SecurityLevel.View}
            loading={false}
        >
            <Grid container className={classes.paper} data-testid={'trade-request-details'}>
                {tradeRequest && (
                    <Grid container alignItems="flex-start">
                        <Grid container className={classes.header}>
                            <Grid container item xs={6} justify="flex-start">
                                <Typography variant="h3" className={classes.title}>
                                    <Trans i18nKey="tradeItRequest">Trade It Request</Trans>
                                    {' # '}
                                    {`${tradeRequest.tradeRequestId}`}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <TradeRequestDetailsSubheader />
                            <TradeRequestDetailsGrid
                                originalShipToId={tradeRequest.accountFrom}
                                receivingShipToId={tradeRequest.accountTo}
                            />
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </ProcessingPageTemplate>
    );
}
