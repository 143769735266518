import React from 'react';
import Modal from '../../../reusable/molecules/Modal';
import {
    AppBar,
    Grid,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography,
    Link
} from '@material-ui/core';
import Button from '../../../reusable/atoms/Button';
import { Trans, useTranslation } from 'react-i18next';
import { ballBlue, boldWeight, regularWeight, white } from '../../../../themes/globalConstants';
import { UnrecognizedProduct } from '../../../../store/reducers/planit-bulk-atm';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';

interface Props {
    open: boolean;
    onClose: () => void;
    onContinue: () => void;
    lines: UnrecognizedProduct[];
}

const useStyles = makeStyles((theme) => ({
    modalBtn: {
        height: '3.3em',
        width: '10em',
        marginRight: '3em'
    },
    buttonGrid: {
        paddingTop: '1em',
        paddingBottom: '1em'
    },
    modalContainer: {
        width: '30em',
        marginLeft: '2em',
        marginTop: '2em'
    },
    appBar: {
        top: 'auto',
        bottom: 0,
        width: '100%'
    },
    toolBar: {
        backgroundColor: white
    },
    modalText: {
        marginBottom: '1em',
        marginRight: '2em'
    },
    tableContainer: {
        marginBottom: '2em',
        height: '15em',
        overflowY: 'scroll',
        overflowX: 'hidden'
    },
    linkSpacer: {
        marginRight: '.25em'
    },
    linkText: {
        textDecoration: `underline ${ballBlue}`
    },
    productTable: {
        width: '60%'
    },
    tableText: {
        fontWeight: regularWeight
    },
    tableHeader: {
        fontWeight: boldWeight
    }
}));

const UnrecognizedProductsModal = ({ open, onClose, onContinue, lines }: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const modalTitle = t('productNotRecognized', 'Product Not Recognized');

    const productManagementLink = (
        <Link
            to={'/manage-products'}
            component={RouterLink}
            underline="none"
            data-testid="manage-products-link"
            className={clsx(classes.linkText, classes.linkSpacer)}
        >
            <Trans i18nKey="here">here</Trans>
        </Link>
    );
    const sortedUniqueProductIds = Array.from(
        new Set(lines.map((l) => l.productId).sort((a, b) => a.localeCompare(b)))
    );

    return (
        <Modal open={open} title={modalTitle} close={onClose} closeIcon={true} maxWidth="lg">
            <Grid container className={classes.modalContainer}>
                <Grid item>
                    <Typography variant="body1" className={classes.modalText}>
                        <Trans i18nKey="includedProductsNotRecognized">
                            One or more of the products included in this upload is not recognized.
                        </Trans>
                    </Typography>
                </Grid>
                <TableContainer className={classes.tableContainer}>
                    <Table
                        size="small"
                        aria-label="unrecognized products table"
                        className={classes.productTable}
                        data-testid="unrecognized-products-table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    className={classes.tableHeader}
                                    data-testid="product-id-column"
                                >
                                    <Trans i18nKey="prodID">Product ID</Trans>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedUniqueProductIds.map((id, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell className={classes.tableText}>{id}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Grid item>
                    <Typography variant="body1" className={classes.modalText}>
                        <Trans i18nKey="ifProductIncorrect">
                            If a product ID is incorrect, please go back to correct it and re-upload
                            the plan. Products not recognized by the Source will not be included in
                            this forecast.
                        </Trans>
                    </Typography>
                    <Typography variant="body1" className={classes.modalText}>
                        <Trans i18nKey="continueWithYourForecast">
                            To continue with your forecast without the unrecognized products, click
                            'Continue.'
                        </Trans>
                    </Typography>
                    <Typography variant="body1" className={classes.modalText}>
                        <Trans i18nKey="editProductsCheckStatus">
                            If you want to edit products or check the status of recently added
                            products, you can manage your products {productManagementLink}.
                        </Trans>
                    </Typography>
                </Grid>
            </Grid>
            <AppBar className={classes.appBar} position="sticky" elevation={10}>
                <Toolbar className={classes.toolBar} variant="regular">
                    <Grid container className={classes.buttonGrid} justify="flex-end">
                        <Button
                            color="secondary"
                            variant="outlined"
                            onClick={onContinue}
                            className={classes.modalBtn}
                            data-testid="continue-btn"
                        >
                            <Trans i18nKey="continue">Continue</Trans>
                        </Button>
                    </Grid>
                </Toolbar>
            </AppBar>
        </Modal>
    );
};

export default UnrecognizedProductsModal;
