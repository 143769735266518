import React, { useEffect, useState } from 'react';
import { Grid, Typography, makeStyles, Link, Paper, CircularProgress } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import {
    boldWeight,
    ballGray,
    black_5,
    blackWeight,
    xxxl,
    ballDrkBlue,
    large,
    ballGrayBlue
} from '../../../../themes/globalConstants';
import clsx from 'clsx';
import moment from 'moment';
import DashboardWidgetMetricSection from './DashboardWidgetMetricSection';
import { enUS } from '../../../../utility/translations/locales';
import DashboardWidgetMetricRow from './DashboardWidgetMetricRow';
import ShipItLinksDropdown from './ShipItLinksDropdown';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { useTypedSelector } from '../../../../store/reducers/reducer';
/*
import { DeliveryOrder } from '../../../../store/reducers/shipping-dashboard';
import { getFilteredDeliveryOrdersByDateRange } from '../../../../utility/helpers/shipment-helpers';
import { ShippingSummaryState } from '../../../../store/reducers/shipping-summary';
import { useDispatch } from 'react-redux';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { loadOpenDeliveryOrders } from '../../../../store/actions/shipping-summary';
import { usePrevious } from '../../../../utility/helpers/react-util';
import { SHIPPING_SUMMARY_LOADING, SHIPPING_SUMMARY_RESET } from '../../../../store/actions/action-types';
*/
import {
    ShipItDashboard,
    CustomerDashboardState
} from '../../../../store/reducers/customer-dashboard';

interface Props {
    dragHandleProps?: DraggableProvidedDragHandleProps;
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'relative',
        borderRadius: 4,
        padding: '2em 2em 3em 2em'
    },
    titleContainer: {
        color: ballGray,
        display: 'flex',
        alignItems: 'center',
        marginBottom: '2.125em'
    },
    title: {
        fontWeight: blackWeight,
        letterSpacing: 1
    },
    subHeaderDetails: {
        fontWeight: boldWeight,
        marginLeft: '0.75em',
        maxWidth: '160px'
    },
    iconText: {
        color: black_5,
        fontWeight: blackWeight,
        marginBottom: '1em',
        marginTop: '1em',
        marginLeft: '0.5em'
    },
    icon: {
        height: '1.25em'
    },
    widgetSectionHeader: {
        color: ballGray,
        fontWeight: blackWeight,
        letterSpacing: 1,
        marginBottom: '1.75em',
        textTransform: 'uppercase'
    },
    link: {
        fontSize: large,
        fontWeight: blackWeight,
        marginTop: '2em'
    },
    widgetNumber: {
        fontSize: xxxl,
        color: ballDrkBlue,
        position: 'relative',

        '&:hover::after': {
            content: '""',
            position: 'absolute',
            bottom: '1rem',
            left: '0',
            width: '100%',
            borderBottom: `1px solid ${ballDrkBlue}`
        }
    },
    shipmentsSubHeaderText: {
        color: ballGrayBlue,
        textTransform: 'uppercase'
    },
    numPalletsSubHeaderText: {
        color: black_5,
        fontWeight: boldWeight,
        marginBottom: '2em',
        textTransform: 'uppercase'
    },
    shipmentsSummaryDetailsContainer: {
        height: 100,
        marginTop: -7
    },
    shipmentsSummaryDetails: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    dragAndDropIcon: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: '1.75em'
    }
}));

interface FutureShipment {
    date: string;
    skus: number;
    shipments: number;
}

export default function ShipItDashboardWidget({ dragHandleProps }: Props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [shipItDashboard, setShipItDashboard] = useState<ShipItDashboard>();
    const [futureShipments, setFutureShipments] = useState<FutureShipment[]>([]);
    const [shipmentsSummaryPallets, setShipmentsSummaryPallets] = useState<number>(0);
    const [shipmentsSummaryShipments, setShipmentsSummaryShipments] = useState<number>(0);
    const { status, dashboardData } = useTypedSelector<CustomerDashboardState>(
        (state) => state.customerDashboard
    );

    const orderingIcon = `${process.env.PUBLIC_URL}/assets/Ship_It_Icon.svg`;
    const orderingIconText = 'Ordering Icon';
    useEffect(() => {
        if (status === 'success' && dashboardData?.shipItDashboard) {
            setShipItDashboard(dashboardData.shipItDashboard);
        }
    }, [status, dashboardData]);
    // Once data has been loaded, handle filtering and formatting for widget
    useEffect(() => {
        if (shipItDashboard?.items && shipItDashboard.items.length > 0) {
            const startDate = moment().startOf('day').startOf('isoWeek');

            const currentWeek = shipItDashboard.items.find((item) => item.week === 0);
            setShipmentsSummaryPallets(currentWeek?.pallets || 0);
            setShipmentsSummaryShipments(currentWeek?.openShipments || 0);

            const futureWeeks = shipItDashboard.items.filter((item) => item.week > 0);
            let newShipments = [] as FutureShipment[];

            futureWeeks.forEach((futureWeek) => {
                const weekStartDate = startDate.clone().add(futureWeek.week, 'week');
                newShipments.push({
                    date: weekStartDate.format('M/D'),
                    skus: futureWeek.productSkus,
                    shipments: futureWeek.openShipments
                } as FutureShipment);
            });

            setFutureShipments(newShipments);
        }
    }, [shipItDashboard]);

    return (
        <Paper elevation={2} className={classes.paper} data-testid="ship-it-dashboard-widget">
            {!!dragHandleProps && (
                <div {...dragHandleProps} data-testid="ship-it-drag-handle">
                    <img
                        className={classes.dragAndDropIcon}
                        src={`${process.env.PUBLIC_URL}/assets/Drag_And_Drop_Icon.svg`}
                        alt="Drag And Drop Icon"
                    />
                </div>
            )}
            <div className={classes.titleContainer}>
                <Typography variant="h3" className={classes.title}>
                    {t('shipItOrders', enUS.shipItOrders)}
                </Typography>
                <ShipItLinksDropdown />
            </div>
            {status === 'loading' || status === 'idle' ? (
                <Grid container alignItems="center" justify="center">
                    <CircularProgress />
                </Grid>
            ) : (
                <Grid container spacing={6}>
                    <Grid container item xs={7}>
                        <DashboardWidgetMetricSection
                            title={t('shipmentsSummary', enUS.shipmentsSummary)}
                            left={
                                <div>
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        className={classes.shipmentsSummaryDetailsContainer}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            className={classes.shipmentsSummaryDetails}
                                        >
                                            <div>
                                                <Link
                                                    to={'/ship-it-summary?startWeek=0'}
                                                    underline="none"
                                                    component={RouterLink}
                                                    data-testid="current-future-shipments-link"
                                                    className={classes.link}
                                                >
                                                    <Typography className={classes.widgetNumber}>
                                                        {shipmentsSummaryShipments.toLocaleString()}
                                                    </Typography>
                                                </Link>
                                            </div>
                                            <div>
                                                <Typography
                                                    className={clsx(
                                                        classes.subHeaderDetails,
                                                        classes.shipmentsSubHeaderText
                                                    )}
                                                >
                                                    {shipmentsSummaryShipments === 1 ? (
                                                        <Trans i18nKey="shipment">Shipment</Trans>
                                                    ) : (
                                                        <Trans i18nKey="shipments">Shipments</Trans>
                                                    )}
                                                </Typography>
                                                <Typography
                                                    className={clsx(
                                                        classes.subHeaderDetails,
                                                        classes.numPalletsSubHeaderText
                                                    )}
                                                >
                                                    <Trans i18nKey="numOfPallets">
                                                        No. of Pallets
                                                    </Trans>
                                                    : {shipmentsSummaryPallets.toLocaleString()}
                                                </Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container alignItems="center" justify="center">
                                        <img
                                            className={classes.icon}
                                            src={orderingIcon}
                                            alt={orderingIconText || `${orderingIconText} Icon`}
                                        />
                                        <Typography variant="body1" className={classes.iconText}>
                                            <Trans i18nKey="arrivingThisWeek">
                                                Arriving This Week
                                            </Trans>
                                        </Typography>
                                    </Grid>
                                </div>
                            }
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Grid item xs={12}>
                            <Typography variant="h5" className={classes.widgetSectionHeader}>
                                <Trans i18nKey="futureShipments">Future Shipments</Trans>
                            </Typography>
                        </Grid>
                        <Grid container direction="row">
                            <Grid item xs={12} container direction="column">
                                {futureShipments.map((shipment, index) => {
                                    let date = shipment.date;
                                    return (
                                        <DashboardWidgetMetricRow
                                            key={`shipment_${index}`}
                                            title={
                                                <Trans i18nKey="weekOfWithDate">
                                                    Week of {{ date }}
                                                </Trans>
                                            }
                                            skuCount={shipment.skus}
                                            secondItemCount={shipment.shipments}
                                            secondItemUnit={
                                                shipment.shipments === 1
                                                    ? t('shipment', 'Shipment')
                                                    : t('shipments', 'Shipments')
                                            }
                                            isLink={true}
                                            route={`/ship-it-summary?startWeek=${index + 1}`}
                                        />
                                    );
                                })}
                                <Link
                                    to={'/ship-it-summary?filterOrders=future'}
                                    underline="none"
                                    component={RouterLink}
                                    data-testid="future-shipments-link"
                                    className={classes.link}
                                >
                                    <Trans i18nKey="viewAllFutureShipments">
                                        View All Future Shipments
                                    </Trans>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Paper>
    );
}
