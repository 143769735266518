import { Grid, makeStyles } from '@material-ui/core';
import React, { ReactElement, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { enUS } from '../../../../utility/translations/locales';
import FormSection from '../../../reusable/molecules/FormSection';
import TextInput from '../../../reusable/molecules/TextInput';

interface Props {
    additionalComments?: string;
}

const useStyles = makeStyles(() => ({
    formSectionWrapper: {
        padding: '8px 8px 0px 8px'
    }
}));

export default function AdditionComments({ additionalComments }: Props): ReactElement {
    const { control, errors, setValue } = useFormContext();
    const classes = useStyles();

    useEffect(() => {
        if (additionalComments) {
            setValue('additionalComments', additionalComments);
        }
    }, [additionalComments]);

    return (
        <FormSection
            testId={'credit-app-header-section'}
            sectionHeader={
                <Trans i18nKey="additionalCommentsRequired">
                    {enUS.additionalCommentsRequired}
                </Trans>
            }
        >
            <Grid xs={12} className={classes.formSectionWrapper}>
                <TextInput
                    type="text"
                    label={<Trans i18nKey="comments">Comments</Trans>}
                    name="additionalComments"
                    testId="additionalComments"
                    control={control}
                    rules={''}
                    error={errors.additionalComments}
                    errors={errors}
                    inputProps={{
                        'data-testid': 'additionalComments',
                        maxLength: 255
                    }}
                />
            </Grid>
        </FormSection>
    );
}
