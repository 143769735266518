import React, { ReactElement, useEffect, useState } from 'react';
import {
    canadaStateProvs,
    mexicoStateProvs,
    usStateProvs
} from '../../../static-data/dropdown-lists';
import AutocompleteInput from './AutocompleteInput';
import { Trans, useTranslation } from 'react-i18next';
import { KeyValuePair } from '../../../store/types';
import { NCACountries } from '../../../utility/helpers/address-helpers';

interface Props {
    defaultValue?: string;
    countryCode: string;
    control: any;
    name: string;
    rules?: any;
    error?: boolean;
    errors?: any;
    styles?: any;
    helperText?: ReactElement;
    handleLoadStateProvince: (name: string, selectedStateProvince: KeyValuePair) => void;
    disabled?: boolean;
}

export const getStateProvinceList = (countryCode: string) => {
    switch (countryCode) {
        case NCACountries.Canada:
            return canadaStateProvs;
        case NCACountries.Mexico:
            return mexicoStateProvs;
        case NCACountries.USA:
            return usStateProvs;
        default:
            return usStateProvs;
    }
};

const StateProvinceSelect = (props: Props) => {
    const stateProvinceList = getStateProvinceList(props.countryCode);
    const [selectedStateProvince, setSelectedStateProvince] = useState<KeyValuePair>();
    useTranslation();

    const handleOnChange = (selectedOption: KeyValuePair) => {
        setSelectedStateProvince(selectedOption);
    };

    //load default value if necessary
    useEffect(() => {
        if (stateProvinceList && props.defaultValue) {
            let stateProv = stateProvinceList.find(
                (stateProv) => stateProv.value === props.defaultValue
            );
            if (stateProv) {
                setSelectedStateProvince(stateProv);
            }
        } else {
            setSelectedStateProvince(undefined);
        }
    }, [stateProvinceList, props.defaultValue]);

    useEffect(() => {
        if (selectedStateProvince) {
            props.handleLoadStateProvince(props.name, selectedStateProvince);
        }
    }, [selectedStateProvince]);

    return (
        <AutocompleteInput
            label={<Trans i18nKey="stateProvince">State/Province</Trans>}
            name={props.name}
            testId={props['data-testid'] || 'state-province-dropdown'}
            control={props.control}
            rules={props.rules}
            error={props.error}
            errors={props.errors}
            selectObj={stateProvinceList}
            value={selectedStateProvince}
            onChange={handleOnChange}
            helperText={props.helperText}
            disabled={props.disabled}
        />
    );
};

export default StateProvinceSelect;
