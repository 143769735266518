import React from 'react';
import moment from 'moment';
import { makeStyles, Typography, TableRow, TableCell, Link } from '@material-ui/core';
import {
    ballBlue,
    blackWeight,
    boldWeight,
    ltBlueGrey_34,
    medium
} from '../../../../themes/globalConstants';
import {
    ProductionOrderLineItem,
    ProductionOrderLinkedDeliveryOrder
} from '../../../../store/reducers/makeit-dashboard';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { formattedMTDate } from '../../../../utility/helpers/date-helpers';
import { Trans, useTranslation } from 'react-i18next';
import EachesConversion from '../../../reusable/atoms/EachesConversion';

interface Props {
    linkedDeliveryOrders?: ProductionOrderLinkedDeliveryOrder[];
    active: boolean;
    line?: ProductionOrderLineItem;
}

const useStyles = makeStyles((theme) => ({
    name: {
        fontWeight: boldWeight,
        fontSize: medium,
        letterSpacing: 0.17
    },
    expand: {
        transform: 'rotate(-90deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: 'rotate(0)'
    },
    expandContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    expandableSection: {
        '& :not(:last-child)': {
            borderBottom: `1px solid white`
        }
    },
    expandableSectionText: {
        padding: '7px'
    },
    standardRow: {
        '& > td:nth-child(n+4)': {
            textAlign: 'right'
        }
    },
    altRow: {
        backgroundColor: ltBlueGrey_34,
        '& > td': {
            borderBottomColor: 'white'
        },
        '& > td:nth-child(n+3)': {
            textAlign: 'right'
        }
    },
    hidden: {
        display: 'none'
    },
    display: {
        display: 'table-row'
    },
    borderlessCell: {
        borderLeft: 'unset'
    },
    activeCell: {
        backgroundColor: ltBlueGrey_34
    },
    activeRow: {
        boxShadow:
            '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 4px 3px 0px rgba(0,0,0,0.12)'
    },
    standardCell: {
        textAlign: 'left',
        '& :first-child': {
            fontWeight: blackWeight
        }
    },
    linkSpacer: {
        marginLeft: '.25em'
    },
    linkedDeliveryOrder: {
        textDecoration: `underline ${ballBlue}`
    },
    spacer: {
        marginLeft: '1em',
        marginRight: '1em'
    }
}));

const MakeItATMOrderExpandableSection = ({ linkedDeliveryOrders, active, line }: Props) => {
    const classes = useStyles();

    const formatDate = (date?: string) => {
        if (date) {
            return formattedMTDate(moment(date), 'MM/DD/YYYY');
        } else {
            return '';
        }
    };

    const getDeliveryOrderDateString = (date?: string) => {
        const receivedOn = t('receivedOn', 'Received On');
        const scheduledFor = t('scheduledFor', 'Scheduled For');
        const formattedDate = formatDate(date);

        if (moment(date).isBefore(moment(), 'day')) {
            return `${receivedOn}: ${formattedDate}`;
        } else if (moment(date).isSameOrAfter(moment(), 'day')) {
            return `${scheduledFor}: ${formattedDate}`;
        } else {
            return '';
        }
    };
    const { t } = useTranslation();

    const setExpandableSectionInfo = (
        linkedDeliveryOrders?: ProductionOrderLinkedDeliveryOrder[]
    ) => {
        return (
            <div className={classes.expandableSection}>
                {linkedDeliveryOrders?.map((order, index) => (
                    <Typography
                        className={classes.expandableSectionText}
                        key={'linked-order-' + order.deliveryOrderId + '-' + index}
                        data-testid={`linked-order-${index}`}
                    >
                        <Trans i18nKey="deliveryOrderNumber">Delivery Order #</Trans>:
                        <Link
                            className={clsx(classes.linkSpacer, classes.linkedDeliveryOrder)}
                            to={`/delivery-order/${order.deliveryOrderId}`}
                            component={RouterLink}
                            underline="none"
                        >
                            {order.deliveryOrderNumber ? (
                                order.deliveryOrderNumber
                            ) : (
                                <Trans i18nKey="pending">Pending</Trans>
                            )}
                        </Link>
                        <span className={classes.spacer}>|</span>
                        <Trans i18nKey="quantity">Quantity</Trans>:{' '}
                        {order?.quantity?.toLocaleString()} PL
                        {order?.quantity && line?.quantityPerPallet && (
                            <>
                                {' / '}
                                <EachesConversion
                                    palletQuantity={order?.quantity}
                                    quantityPerPallet={line?.quantityPerPallet}
                                    label={true}
                                />
                            </>
                        )}
                        <span className={classes.spacer}>|</span>
                        {getDeliveryOrderDateString(order.deliveryDateTime)}
                    </Typography>
                ))}
            </div>
        );
    };

    return (
        <TableRow
            className={clsx(classes.altRow, classes.hidden, {
                [classes.display]: active
            })}
        >
            <TableCell colSpan={12}>{setExpandableSectionInfo(linkedDeliveryOrders)}</TableCell>
        </TableRow>
    );
};

export default MakeItATMOrderExpandableSection;
