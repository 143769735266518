import React, { useState, useEffect } from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { useTranslation, Trans } from 'react-i18next';
import { blackWeight, ballGray } from '../../../themes/globalConstants';
import ShareLinks from './components/ShareLinks';
import ShipToSubheader from './components/ShipToSubheader';
import { useTypedSelector } from '../../../store/reducers/reducer';
import {
    MakeState,
    ProductionOrderLine,
    ProductCountInformation
} from '../../../store/reducers/makeit-dashboard';
import PaymentStatusHeader from '../../reusable/molecules/PaymentStatusHeader';
import { useHistory } from 'react-router-dom';
import Button from '../../reusable/atoms/Button';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { resetMakeItState } from '../../../store/actions/makeit-dashboard';
import { formatFromDatetime } from '../../../utility/helpers/date-helpers';
import { returnOrderCountInformation } from '../../../utility/helpers/order-helpers';
import { CustomerContextState, PaymentTerms } from '../../../store/reducers/customer-context';
import DisableBackButton from '../../reusable/atoms/DisableBackButton';
import { Activity } from '../../../utility/auth/useSecurity';
import MakeItConfirmationRow from './components/MakeItConfirmationRow';
import { OrderProductTableRow } from '../../../store/reducers/orders-dashboard';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import StandardTable from '../../reusable/molecules/StandardTable';
import MakeItConfirmationColumns from './components/MakeItConfirmationColumns';

const useStyles = makeStyles((theme) => ({
    main: {
        padding: '1.5em'
    },
    subheader: {
        fontWeight: blackWeight,
        color: ballGray
    },
    title: {
        fontWeight: blackWeight,
        color: theme.palette.secondary.main,
        padding: '0 0 1em 0'
    },
    subheaderContainer: {
        marginBottom: '1em'
    },
    btnWrapper: {
        margin: '2em 0'
    },
    actionBtn: {
        borderRadius: 'unset'
    },
    wrapper: {
        marginTop: '2em'
    }
}));

const MakeItOrderConfirmation = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { productsToOrder, orderLines, productionOrder } = useTypedSelector<MakeState>(
        (state) => state.makeItDashboard
    );
    const { shipToAccounts, selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const accountInformation =
        shipToAccounts &&
        shipToAccounts.filter((account) => account.accountId === selectedAccountId);

    const [shipToName, setShipToName] = useState('');
    const [shipToId, setShipToId] = useState('');
    const [displayAddress, setDisplayAddress] = useState('');
    const [countInformation, setCountInformation] = useState<ProductCountInformation>({
        canBtlCount: 0,
        endCount: 0,
        canBtlSkuCount: 0,
        endSkuCount: 0,
        canEachesCount: 0,
        endEachesCount: 0
    });
    const [orderList, setOrderList] = useState<ProductionOrderLine[]>([]);
    const [ciaCustomer, setCiaCustomer] = useState(false);

    const dashboardLink = '/dashboard';
    const minimumDateList =
        productsToOrder && productsToOrder.map((product) => moment(product.requestedDate));
    const minimumDate = minimumDateList ? moment.min(minimumDateList) : moment().format();
    const date = formatFromDatetime(moment().format());

    useTranslation();

    const handleDashboardNavigation = () => {
        dispatch(resetMakeItState());
        history.push(dashboardLink);
    };

    useEffect(() => {
        if (selectedAccountId && shipToAccounts) {
            shipToAccounts.forEach((shipTo) => {
                if (
                    shipTo.accountId === selectedAccountId &&
                    shipTo.paymentTerms === PaymentTerms.CIA
                ) {
                    setCiaCustomer(true);
                }
            });
        }
        // run only at beginning
    }, []);

    useEffect(() => {
        if (orderLines) {
            setOrderList(orderLines);
            if (orderLines.length > 0) {
                setCountInformation(returnOrderCountInformation(orderLines));
            }
        }
    }, [dispatch, orderLines]);

    useEffect(() => {
        if (accountInformation && accountInformation[0]) {
            setShipToName(accountInformation[0].name);
            setShipToId(accountInformation[0].accountId);
            if (accountInformation[0].address) {
                const shipToAddress = accountInformation[0].address;
                setDisplayAddress(
                    shipToAddress.address1?.trimEnd().toLowerCase() +
                        ', ' +
                        shipToAddress.city?.trimEnd().toLowerCase() +
                        ', ' +
                        shipToAddress.stateProvince +
                        ' ' +
                        shipToAddress.postalCode
                );
            }
        }
    }, [accountInformation]);

    DisableBackButton('/make-it-configuration', '/make-it-order-confirmation');

    const footerActions = (
        <Button
            type="button"
            color="primary"
            variant="contained"
            data-testid="dashboard-btn"
            className={classes.actionBtn}
            onClick={handleDashboardNavigation}
        >
            <Trans i18nKey="myDashboard">My Dashboard</Trans>
        </Button>
    );

    const toolbar = () => (
        <Grid container className={classes.main}>
            <Grid container item justify="space-between" alignItems="baseline">
                <Typography variant="h3" className={classes.title} data-testid="customer-po">
                    <Trans i18nKey="customerPO">Customer PO</Trans>
                    {' # '}
                    {`${productionOrder?.customerProductionOrderId}`}
                </Typography>
                <ShareLinks
                    dateSubmitted={date}
                    customerPo={productionOrder?.customerProductionOrderId ?? ''}
                    orderList={orderList}
                    countInformation={countInformation}
                    shipToAddress={displayAddress}
                    shipToName={shipToName}
                    shipToId={shipToId}
                />
            </Grid>
            <Grid container className={classes.subheaderContainer} justify="space-between">
                <ShipToSubheader
                    order={productionOrder}
                    countInformation={countInformation}
                    shipToAddress={displayAddress}
                    shipToName={shipToName}
                    shipToId={shipToId}
                />
            </Grid>
        </Grid>
    );

    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [columns, setColumns] = useState<any>([]);

    useTranslation();

    useEffect(() => {
        setColumns(MakeItConfirmationColumns.getMakeItConfirmationColumns(cultureCode));
    }, [cultureCode]);

    const ConfirmationRow = ({ row }: { row: Partial<OrderProductTableRow> }) => {
        return <MakeItConfirmationRow item={row} />;
    };

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('makeIt', 'Make It'),
                description: t('orderSubmitted', 'THANK YOU, YOUR ORDER HAS BEEN SUBMITTED'),
                thinBanner: true,
                displayDropdown: false
            }}
            actionFooter={{
                footerAction: footerActions,
                justify: 'center',
                sticky: true
            }}
            activity={Activity.MakeIt}
            shipTos={false}
        >
            {ciaCustomer && <PaymentStatusHeader minimumDate={moment(minimumDate).format()} />}

            <StandardTable
                columns={columns}
                lines={orderLines as ProductionOrderLine[]}
                gridName="Make It Confirmation Grid"
                rowComponent={ConfirmationRow}
                searchText={false}
                enablePagination={false}
                columnWidths={[20, 30, 10, 20, 20]}
                customToolbarItems={toolbar()}
                className={classes.wrapper}
            />
        </ProcessingPageTemplate>
    );
};

export default MakeItOrderConfirmation;
