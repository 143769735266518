import Axios, { AxiosRequestConfig } from 'axios';

export enum ErrorStatus {
    NoContent = 204,
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    InternalServer = 500,
    BadGateway = 502,
    ServiceUnavailable = 503,
    GatewayTimeout = 504
}

export const setAxiosDefaults = () => {
    // IE 11 was doing caching on all Axios calls, and this disables that
    Axios.defaults.headers['Pragma'] = 'no-cache';
};

export const getAxiosConfig = (state: any) => {
    const culture = state().regionCulture.cultureCode;
    const region = state().regionCulture.regionCode;
    const accessToken = state().auth?.accessToken;

    return createAxiosHeader(culture, region, accessToken);
};

export const createAxiosHeader = (culture: string, region: string, accessToken?: any) => {
    const headers = {
        'Accept-Language': culture,
        'Accept-Region': region,
        Authorization: accessToken ? `Bearer ${accessToken}` : ''
    };

    return {
        headers
    };
};

export const disableClientCachingForRequest = (requestConfig: AxiosRequestConfig) => {
    const currentCacheControlValue = requestConfig.headers['Cache-Control'];
    const newCacheControlValue = currentCacheControlValue
        ? `${currentCacheControlValue}, no-client-cache`
        : 'no-client-cache';

    requestConfig.headers = {
        ...requestConfig.headers,
        'Cache-Control': newCacheControlValue
    };
};

export function findDistinctObjects(unfilteredArray: any[], filteredProperty: any) {
    return unfilteredArray.filter((item, index, currentArray) => {
        return (
            currentArray.indexOf(
                currentArray.find(
                    (matchingItem) =>
                        matchingItem && matchingItem[filteredProperty] === item[filteredProperty]
                )
            ) === index
        );
    });
}
