import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { Grid, makeStyles, Button } from '@material-ui/core';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { useHistory } from 'react-router';
import { Activity, Persona } from '../../../utility/auth/useSecurity';
import { EditShipment, EditShipmentsState } from '../../../store/reducers/edit-shipments';
import { DefaultQueryParams } from '../../../utility/helpers/query-helpers';
import {
    DeliveryShipment,
    ProductToShip,
    ProductWithPallets
} from '../../../store/reducers/shipping-dashboard';
import EditShipmentsAddProductGrid from './components/EditShipmentsAddProductGrid';
import EditShipmentsConfigShipment from './components/EditShipmentsConfigShipment';
import EditShipmentsDisclaimerSection from '../EditShipmentsSummary/components/EditShipmentsDisclaimerSection';
import {
    updateEditedShipment,
    updateDeliveryDate,
    clearProductsToEdit,
    loadShipmentProducts,
    updateLastEditedShipmentId
} from '../../../store/actions/edit-shipments';
import ShipmentDiscardModal from '../../reusable/molecules/ShipmentDiscardModal';
import ShipmentFeeModal from '../../reusable/molecules/ShipmentFeeModal';
import {
    getDeliveryLeadTimeDays,
    isProductCanType,
    isProductEndType,
    productTypeHelper
} from '../../../utility/helpers/order-helpers';
import {
    CreditHold,
    CustomerContextState,
    PaymentTerms
} from '../../../store/reducers/customer-context';
import WarningAlert from '../../reusable/atoms/WarningAlert';
import { fullTruckPallets, warningLabel } from '../../../themes/globalConstants';
import {
    canUpdateDesiredPallets,
    getCurrentLoad,
    getLoadIndexByLoadId,
    getPalletsPerTruck,
    getReferenceLoad,
    getShipmentIndex
} from '../../../utility/helpers/shipment-helpers';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import { AuthState } from '../../../store/reducers/auth';
import { isPersonaAccount } from '../../../utility/helpers/user-helpers';
import { EditShipmentConfigurationProvider } from './context/EditShipmentConfigurationContext';
import {
    selectIsCustomerPickup,
    selectIsLargeCustomer,
    selectIsLargeCustomerAccount
} from '../../../store/selectors';

const useStyles = makeStyles((theme) => ({
    main: {
        padding: '0',
        marginTop: '2.750em'
    },
    btnWrapper: {
        '& button:last-child': {
            marginLeft: '1.25em'
        }
    },
    actionBtn: {
        borderRadius: 'unset'
    },
    shipmentWrapper: {
        margin: '1em auto 0 auto'
    },
    addProductGrid: {
        width: '54.75em',
        margin: '0 auto 3.75em auto',
        display: 'none'
    },
    showProductGrid: {
        display: 'block'
    },
    warningMessage: {
        width: '100%',
        margin: '0 1.3em 1.3em',
        padding: '1em',
        backgroundColor: warningLabel
    },
    warningWrapper: {
        margin: 'auto'
    }
}));

export default function EditShipmentsConfiguration() {
    const { id } = useParams<DefaultQueryParams>();
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const { productsToEdit, deliveryToEdit } = useTypedSelector<EditShipmentsState>(
        (state) => state.editShipments
    );
    const { selectedAccountId, shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);
    const [addProducts, setAddProducts] = useState<boolean>(false);
    const [shipment, setShipment] = useState<DeliveryShipment>({});
    const [feeModal, setFeeModal] = useState<boolean>(false);
    const [discardModal, setDiscardModal] = useState<boolean>(false);
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
    const [overMaximumPallets, setOverMaximumPallets] = useState<boolean>(false);
    const [initialShipmentState, setInitialShipmentState] = useState<DeliveryShipment>();
    const [hasCreditHold, setHasCreditHold] = useState<boolean>(false);
    const [isCopacker, setIsCopacker] = useState<boolean>(false);
    const [validShipment, setValidShipment] = useState<boolean>(false);
    const [dateChanged, setDateChanged] = useState<boolean>(false);
    const [leadTime, setLeadTime] = useState<number | undefined>();
    const isLargeCustomerAccount = useTypedSelector<boolean>(selectIsLargeCustomerAccount);
    const isLargeCustomer = useTypedSelector<boolean>(selectIsLargeCustomer);
    const isCustomerPickup = useTypedSelector<boolean>(selectIsCustomerPickup);
    const maxPallets = getPalletsPerTruck(isLargeCustomer, isCopacker, isLargeCustomerAccount);

    const validateShipment = () => {
        isProductCanType(shipment.shipmentType) &&
        shipment.shipmentQuantity &&
        shipment.shipmentQuantity < fullTruckPallets &&
        !isCustomerPickup &&
        (!isCopacker || (isLargeCustomerAccount && isCopacker))
            ? handleOpenFeeModal()
            : handleSubmit();
    };

    const getTempShipments = () => {
        const tempDeliveryToEdit: EditShipment = JSON.parse(JSON.stringify(deliveryToEdit));
        return tempDeliveryToEdit.deliveryOrder.shipments || [];
    };

    const handleSubmit = () => {
        let tempEditShipment = JSON.parse(JSON.stringify(deliveryToEdit));
        const shipmentIndex = tempEditShipment.deliveryOrder.shipments?.findIndex(
            (orderShipment) => orderShipment.shipmentId === shipment.shipmentId
        );

        if (shipmentIndex !== -1) {
            shipment.edited = true;

            if (shipment.loads && shipment.loads.length > 1) {
                shipment.loads?.map((load, index) => {
                    //If any product pallet quantity is 0, remove it from shipment
                    if (load.palletQuantity === 0) {
                        shipment.loads?.splice(index, 1);
                    }
                    return null;
                });
            }
            if (shipment.loads && shipment.loads.length < 1) {
                shipment.edited = false;
                shipment.cancelled = true;
            }
            if (
                shipment.updatedDeliveryDate &&
                moment(shipment.updatedDeliveryDate) !== moment(shipment.deliveryDateTime)
            ) {
                shipment.dateEditedBefore = true;
            }
        }
        tempEditShipment.deliveryOrder.shipments?.splice(shipmentIndex as number, 1, shipment);
        dispatch(updateEditedShipment(tempEditShipment));
        dispatch(clearProductsToEdit());
        dispatch(updateLastEditedShipmentId(`${shipment.shipmentId}`));
        history.push(`/edit-shipments-summary/${tempEditShipment.deliveryOrder.deliveryOrderId}`);
    };

    const handleDateUpdate = (newDateTime: string, currentShipment: DeliveryShipment) => {
        let tempShipmentToEdit = JSON.parse(JSON.stringify(deliveryToEdit));
        const tempShipments = tempShipmentToEdit.deliveryOrder.shipments;
        const shipmentIndex =
            tempShipments &&
            tempShipments.findIndex(
                (shipment) => shipment.shipmentId === currentShipment.shipmentId
            );
        if (shipmentIndex !== -1) {
            const updatedDeliveryDate = newDateTime;
            const deliveryTime = moment(newDateTime).format('hh:mm A');
            const edited = true;
            const newShipmentObj = {
                ...shipment,
                updatedDeliveryDate,
                deliveryTime,
                edited
            };

            setShipment(newShipmentObj);
            currentShipment.updatedDeliveryDate = updatedDeliveryDate;
            currentShipment.deliveryTime = deliveryTime;
            currentShipment.edited = edited;

            tempShipmentToEdit.deliveryOrder.shipments?.splice(
                shipmentIndex as number,
                1,
                currentShipment
            );
            const shipToId = tempShipmentToEdit.deliveryOrder.shipToId;

            checkHasCreditHold(shipToId);
            setDateChanged(true);
            dispatch(updateDeliveryDate(tempShipmentToEdit, newShipmentObj));
        }
    };

    const handleShipmentClear = (currentShipment: DeliveryShipment) => {
        let tempShipmentToClear = JSON.parse(JSON.stringify(deliveryToEdit));
        const tempShipments = tempShipmentToClear.deliveryOrder.shipments;
        const shipmentIndex = tempShipments!.findIndex(
            (shipment) => shipment.shipmentId! === currentShipment.shipmentId!
        );

        if (shipmentIndex !== -1 && initialShipmentState) {
            //Set current shipment to the pristine shipment from state
            currentShipment = initialShipmentState;
            currentShipment.updatedDeliveryDate = undefined;
            currentShipment.edited = false;
        }

        tempShipmentToClear.deliveryOrder.shipments?.splice(
            shipmentIndex as number,
            1,
            currentShipment
        );
        dispatch(updateEditedShipment(tempShipmentToClear));
    };

    const handleAddProductToShipment = (product: ProductWithPallets) => {
        let currentShipment = shipment as DeliveryShipment;
        const currentLoads = currentShipment.loads as ProductToShip[];

        if (currentLoads && currentLoads.length) {
            let itemIndex = currentLoads.findIndex(
                (load) => load.productSku === product.productSku
            );

            let newLoadId = currentLoads[currentLoads.length - 1].editLoadId! + 1;

            let newSequence = 0;
            if (currentShipment.loads) {
                newSequence = currentShipment.loads[currentShipment.loads.length - 1].sequence! + 1;
            }

            if (itemIndex === -1) {
                setShipment({
                    ...currentShipment,
                    loads: [
                        ...currentLoads,
                        {
                            ...product,
                            palletQuantity: 0,
                            sequence: newSequence,
                            editLoadId: newLoadId
                        }
                    ],
                    shipmentType: product.type
                });
            } else {
                setShipment({
                    ...currentShipment,
                    loads: [
                        ...currentLoads,
                        {
                            ...product,
                            palletQuantity: 0,
                            sequence: newSequence,
                            editLoadId: newLoadId
                        }
                    ],
                    shipmentType: product.type
                });
            }
        } else {
            setShipment({
                ...currentShipment,
                loads: [{ ...product, palletQuantity: 0, sequence: 1, editLoadId: 1 }],
                shipmentType: product.type
            });
        }
    };

    const handleShowProductGrid = () => {
        setAddProducts(true);
    };

    const handleUpdateShipment = (shipment: DeliveryShipment) => {
        setShipment(shipment);
    };

    const setPalletsForProduct = (product: ProductToShip, newAvailablePallets: number) => {
        let currentShipment = { ...shipment } as DeliveryShipment;
        const currentLoads = currentShipment.loads as ProductToShip[];

        if (currentShipment) {
            if (currentLoads && currentLoads.length) {
                //Find all indecies on shipment with productSku
                let productIndecies: number[] = [];
                currentLoads.forEach((load, index) => {
                    if (load.productSku === product.productSku) {
                        productIndecies.push(index);
                    }
                });

                if (productIndecies.length > 0) {
                    //Find the new total pallets on the shipment
                    let totalPallets = 0;
                    let totalEaches = 0;
                    currentLoads.map((load) => {
                        const pallets = load.palletQuantity;
                        const eaches = load.quantityPerPallet && pallets * load.quantityPerPallet;
                        if (pallets && eaches) {
                            totalPallets += pallets;
                            totalEaches += eaches;
                        }
                        return null;
                    });
                    //Set the available pallets for all relevant products
                    productIndecies.forEach((index) => {
                        currentLoads[index].availablePallets = newAvailablePallets;
                    });

                    //Set new values on shipment
                    setShipment({
                        ...currentShipment,
                        palletCount: totalPallets,
                        shipmentQuantity: totalPallets,
                        shipmentQuantityEaches: totalEaches
                    });
                }
            }
        }
    };

    // Prevent saving the shipment if the total pallet quantity is over the maximum
    useEffect(() => {
        let totalOrderedPallets = 0;
        shipment.loads?.map((load) => {
            totalOrderedPallets += load.palletQuantity;
            return null;
        });

        if (!isCustomerPickup && totalOrderedPallets > maxPallets) setOverMaximumPallets(true);
        else setOverMaximumPallets(false);
    }, [maxPallets, shipment, isCustomerPickup]);

    const updatePalletsForProduct = (
        product: ProductToShip,
        numOfPallets: number,
        palletDifference: number
    ) => {
        let currentProduct = {
            ...product,
            availablePallets: product.availablePallets,
            availableItemsPerPallet: product.availableItemsPerPallet
        };

        if (shipment && shipment.loads) {
            //Isolate loads & determine shipment type
            let tempLoads = shipment.loads;
            const isEndType = productTypeHelper(shipment.shipmentType) === 'END';

            //Find current load within shipment and reference load
            const loadIndex = getLoadIndexByLoadId(currentProduct, tempLoads);
            let currentLoad = shipment.loads[loadIndex];
            const referenceLoad = currentLoad && getReferenceLoad(currentLoad, productsToEdit);
            let totalOrderedPallets = 0;

            //Total all pallets in truck load
            shipment.loads?.map((load) => {
                if (load.productSku !== product.productSku) {
                    totalOrderedPallets += load.palletQuantity;
                } else {
                    totalOrderedPallets = totalOrderedPallets - palletDifference;
                }
                return null;
            });

            //If shipment is can type and orderedPallets > 25, skip over this and do not calculate pallet adjustments
            const canUpdatePallets = canUpdateDesiredPallets(product, palletDifference);
            if (!isEndType && totalOrderedPallets <= maxPallets) {
                if (loadIndex !== -1) {
                    let availablePallets = currentLoad.availablePallets;
                    let newAvailablePallets = 0;

                    if (availablePallets !== undefined) {
                        //If desired pallets are 0, disable save and show warning
                        if (numOfPallets === 0) {
                            setSubmitDisabled(true);
                            newAvailablePallets = availablePallets + palletDifference;
                            setPalletsForProduct(product, newAvailablePallets);
                        } else if (canUpdatePallets) {
                            setSubmitDisabled(false);
                            newAvailablePallets = availablePallets + palletDifference;
                            setPalletsForProduct(product, newAvailablePallets);
                        }

                        if (referenceLoad) {
                            referenceLoad.availablePallets = newAvailablePallets;
                        }
                    }
                }
            } else if (isEndType) {
                //Else if end type, calculate as normal w/o reference to truckload
                if (loadIndex !== -1) {
                    let availablePallets =
                        referenceLoad && referenceLoad.availablePallets
                            ? referenceLoad.availablePallets
                            : 0;
                    let newAvailablePallets = 0;

                    if (availablePallets !== undefined) {
                        //If desired pallets are 0, disable save and show warning
                        if (numOfPallets === 0) {
                            setSubmitDisabled(true);
                            newAvailablePallets = availablePallets + palletDifference;
                            setPalletsForProduct(product, availablePallets);
                        } else if (canUpdatePallets) {
                            setSubmitDisabled(false);
                            newAvailablePallets = availablePallets + palletDifference;
                            setPalletsForProduct(product, newAvailablePallets);
                        }
                        if (referenceLoad) {
                            referenceLoad.availablePallets = newAvailablePallets;
                        }
                    }
                }
            }
        }
    };

    const deleteProductUpdatePallets = (product: ProductToShip) => {
        let productToUpdate = productsToEdit.find((item) => item.productSku === product.productSku);

        let availablePallets = product.availablePallets ? product.availablePallets : 0;

        if (productToUpdate) {
            const newAvailablePallets = availablePallets + product.palletQuantity;
            productToUpdate.availablePallets = newAvailablePallets;
        }
    };

    //If account has a credit hold, bypass regular update pallet function and only allow pallet decrease from orig qty
    const creditHoldUpdatePallets = (
        product: ProductToShip,
        numOfPallets: number,
        palletDifference: number
    ) => {
        let tempShipments = getTempShipments();
        let shipmentIndex = getShipmentIndex(id, tempShipments);
        let tempLoads = tempShipments[shipmentIndex].loads || [];
        let loadIndex = tempLoads && getLoadIndexByLoadId(product, tempLoads);
        let currentLoad = getCurrentLoad(loadIndex, tempShipments, id);
        let referenceLoad = getReferenceLoad(product, productsToEdit);

        if (initialShipmentState && initialShipmentState.loads && referenceLoad) {
            //Find product on initial shipment state
            let initialLoadIndex = getLoadIndexByLoadId(product, initialShipmentState.loads);
            let initialPallets = initialShipmentState.loads[initialLoadIndex].palletQuantity;

            if (numOfPallets > initialPallets) {
                //Reset values in input and shipment quantity
                currentLoad.palletQuantity = initialPallets;
            } else {
                updatePalletsForProduct(product, numOfPallets, palletDifference);
            }
        }
    };

    //Before doing the updates, check for credit hold on account
    /**
     *
     * @param product
     * @param numOfPallets
     * @param palletDifference Difference between the previous pallet quantity and the new quantity value.
     *
     * Positive when the user provided a value LOWER than the previous value.  Negative when the user
     * provides a value HIGHER than the previous value.
     */
    const handleUpdatePallets = (
        product: ProductToShip,
        numOfPallets: number,
        palletDifference: number
    ) => {
        // Check for non-zero pallet difference.
        if (
            palletDifference !== 0 &&
            (isProductEndType(product.type) || numOfPallets <= maxPallets)
        ) {
            if (!hasCreditHold) {
                updatePalletsForProduct(product, numOfPallets, palletDifference);
            } else {
                creditHoldUpdatePallets(product, numOfPallets, palletDifference);
            }
            dispatch(
                loadShipmentProducts(
                    deliveryToEdit!.deliveryOrder.shipToId!.toString(),
                    moment(getDeliveryDate()).format('MM/DD/YYYY'),
                    shipment
                )
            );
        }
    };

    const checkAvailablePallets = (tempShipment: DeliveryShipment) => {
        //If Shipment Order has products(loads)
        let updatedShipment = JSON.parse(JSON.stringify(tempShipment));

        if (updatedShipment.loads) {
            let totalPallets = 0; // total pallets on the shipment
            updatedShipment.loads.map((productInShipment) => {
                //Get index for product on shipment
                const productIndex = updatedShipment!.loads!.findIndex((load) =>
                    load !== undefined ? load.editLoadId === productInShipment.editLoadId : false
                );

                // Find specific load with product
                const productLoad = productsToEdit.find((product) =>
                    product !== undefined
                        ? product.productSku === productInShipment.productSku
                        : false
                );

                // Account for no product load OR product load with available balance of zero.
                if (!productLoad && dateChanged) {
                    updatedShipment.loads![productIndex] = undefined;
                } else if (productIndex !== -1 && productIndex !== undefined) {
                    const desiredPallets = productInShipment.palletQuantity;
                    const availablePallets = productInShipment.availablePallets;

                    if (availablePallets !== undefined && desiredPallets !== undefined) {
                        if (availablePallets === 0) {
                            totalPallets += desiredPallets;
                        } else if (desiredPallets >= availablePallets) {
                            //Set desiredPallets to equal number of pallets available that day
                            totalPallets += availablePallets;
                            updatedShipment.loads![productIndex].palletQuantity = availablePallets;
                            updatedShipment.loads![productIndex].availablePallets = 0;
                        } else if (desiredPallets < availablePallets) {
                            // On first (page) load
                            updatedShipment.loads![productIndex].availablePallets =
                                availablePallets;
                            totalPallets += desiredPallets;
                        }
                    }
                }
                return null;
            });
            let filteredLoads = updatedShipment.loads.filter((load) => load !== undefined);
            updatedShipment.loads = filteredLoads;
            updatedShipment.palletCount = undefined;
            updatedShipment.shipmentQuantity = totalPallets;
        }
        return updatedShipment;
    };

    const calculatePalletTotals = (tempShipment: DeliveryShipment) => {
        const modifiedShipment = checkAvailablePallets(tempShipment);
        setShipment(modifiedShipment);
    };

    const handleOpenDiscardModal = () => {
        setDiscardModal(true);
    };

    const handleCloseDiscardModal = () => {
        setDiscardModal(false);
    };

    const handleOpenFeeModal = () => {
        setFeeModal(true);
    };

    const handleCloseFeeModal = () => {
        setFeeModal(false);
    };

    const handleDiscardChanges = () => {
        //Clear shipment data
        handleShipmentClear(shipment);
        dispatch(clearProductsToEdit());

        if (deliveryToEdit) {
            dispatch(updateLastEditedShipmentId(`${shipment.shipmentId}`));
            history.push(`/edit-shipments-summary/${deliveryToEdit.deliveryOrder.deliveryOrderId}`);
        } else {
            history.push('/ship-it-summary');
        }
    };

    const checkHasCreditHold = (shipToId?: number) => {
        if (shipToAccounts) {
            const selectedShipTo = shipToAccounts.find(
                (account) => account.accountId === shipToId?.toString()
            );

            // account has credit hold if it's a Credit account and Credit Hold is C1
            setHasCreditHold(
                !!selectedShipTo &&
                    selectedShipTo.paymentTerms === PaymentTerms.Credit &&
                    selectedShipTo.creditHold === CreditHold.C1
            );
        }
    };

    const checkUniqueDateTime = (dateTime: string, currentShipmentId: number) => {
        const shipments = deliveryToEdit && deliveryToEdit.deliveryOrder.shipments;
        let isUnique = true;
        if (shipments) {
            shipments.forEach((shipment) => {
                if (shipment.shipmentId !== currentShipmentId) {
                    let datetoFormat = shipment.updatedDeliveryDate ?? shipment.deliveryDateTime;
                    let dateToCompare = moment(datetoFormat).format('MM/DD/YYYY hh:mm A');
                    if (dateToCompare === dateTime) {
                        isUnique = false;
                    }
                }
            });
        }
        return isUnique;
    };

    // If browser back button clicked, set lastEditedShipmentId so the user will be returned to this shipment on the summary page.
    useEffect(() => {
        return () => {
            if (history.action === 'POP' && shipment?.shipmentId) {
                dispatch(updateLastEditedShipmentId(`${shipment.shipmentId}`));
            }
        };
    }, [dispatch, history, shipment]);

    useEffect(() => {
        if (selectedAccountId && shipToAccounts) {
            setLeadTime(getDeliveryLeadTimeDays(parseInt(selectedAccountId), shipToAccounts));

            if (permissions && deliveryToEdit?.deliveryOrder?.shipToId) {
                // check if user has co-packer permissions. If they do see if they're a co-packer for the Ship To this delivery is tied to.
                const coPackerCheck = isPersonaAccount(
                    permissions,
                    Persona.CoPacker,
                    deliveryToEdit.deliveryOrder.shipToId.toString()
                );
                setIsCopacker(!!coPackerCheck);
            }
        }
    }, [permissions, deliveryToEdit, selectedAccountId]);

    function getDeliveryDate() {
        return shipment.updatedDeliveryDate || shipment.deliveryDateTime;
    }
    // Changes to shipment, including date change
    useEffect(() => {
        if (deliveryToEdit) {
            const deliveryShipTo = deliveryToEdit.deliveryOrder.shipToId!;

            checkHasCreditHold(deliveryShipTo);
        }
    }, [shipment]);

    useEffect(() => {
        if (dateChanged) {
            // Execute only after the date change action is executed.
            calculatePalletTotals(shipment);
            setDateChanged(false);
        }
        // date change
    }, [productsToEdit]);

    // initial load, changes to deliveryToEdit (which includes date changes), changes to productsToEdit
    useEffect(() => {
        if (deliveryToEdit && id && productsToEdit) {
            let targetShipment: DeliveryShipment = shipment;
            let tempShipment = JSON.parse(
                JSON.stringify(
                    deliveryToEdit.deliveryOrder.shipments?.filter(
                        (shipment) => shipment.shipmentId?.toString() === id
                    )[0]
                )
            ) as DeliveryShipment;

            if (!shipment || shipment.shipmentId !== tempShipment.shipmentId) {
                targetShipment = tempShipment;
            }

            targetShipment.loads?.map((load) => {
                const tempLoad = productsToEdit.find(
                    (product) => product.productSku === load.productSku
                );

                if (tempLoad) {
                    load.displayName = tempLoad.displayName;
                    load.type = tempLoad.type;
                    load.availablePallets = tempLoad.availablePallets;
                } else {
                    load.availablePallets = load.availablePallets ?? 0;
                }
                return null;
            });
            setShipment(targetShipment);
        }
    }, [deliveryToEdit, id, productsToEdit, shipment]);

    useEffect(() => {
        //Before doing any calculations, save a copy of this shipment in local state
        //This is used for reverting on discard
        if (deliveryToEdit && id) {
            const index = deliveryToEdit.deliveryOrder!.shipments!.findIndex(
                (item) => item.shipmentId!.toString() === id
            );
            const shipmentToEdit = deliveryToEdit.deliveryOrder!.shipments![index];
            setInitialShipmentState(shipmentToEdit);

            const deliveryShipTo = deliveryToEdit.deliveryOrder.shipToId!;
            const deliveryDate =
                shipmentToEdit.updatedDeliveryDate || shipmentToEdit.deliveryDateTime!;

            dispatch(
                loadShipmentProducts(
                    deliveryShipTo.toString(),
                    moment(deliveryDate).format('MM/DD/YYYY'),
                    shipment
                )
            );
        }
        // Runs only once
    }, []);

    useTranslation();

    const footerActions = (
        <>
            <Grid container item xs={4} className={classes.btnWrapper}>
                <Button
                    type="button"
                    color="primary"
                    variant="outlined"
                    data-testid="discard-btn"
                    className={classes.actionBtn}
                    onClick={handleOpenDiscardModal}
                >
                    <Trans i18nKey="discardChanges">Discard Changes</Trans>
                </Button>
            </Grid>
            <Grid container item xs={4} justify="flex-end" className={classes.btnWrapper}>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    data-testid="save-btn"
                    className={classes.actionBtn}
                    onClick={validateShipment}
                    disabled={
                        !deliveryToEdit || submitDisabled || !validShipment || overMaximumPallets
                    }
                >
                    <Trans i18nKey="saveChanges">Save Changes</Trans>
                </Button>
            </Grid>
        </>
    );

    return (
        <EditShipmentConfigurationProvider
            value={{ deliveryDateTime: shipment.deliveryDateTime || '' }}
        >
            <ProcessingPageTemplate
                banner={{
                    header: t('shipIt', 'Ship It'),
                    description: t('editYourShipment', 'EDIT YOUR SHIPMENT').toLocaleUpperCase(),
                    thinBanner: true,
                    displayDropdown: false
                }}
                actionFooter={{
                    footerAction: footerActions,
                    justify: 'space-between',
                    sticky: true
                }}
                activity={Activity.NewOpenDeliveryOrders}
            >
                <Grid
                    container
                    spacing={2}
                    className={classes.main}
                    data-testid="edit-shipments-config-main"
                >
                    <Grid item>
                        <EditShipmentsDisclaimerSection leadTime={leadTime} />
                    </Grid>
                    {hasCreditHold && (
                        <Grid item className={classes.warningWrapper}>
                            <WarningAlert
                                warningMessage={t('creditHoldWarning', 'Credit Hold Warning')}
                                showWarning={hasCreditHold}
                                className={classes.warningMessage}
                            />
                        </Grid>
                    )}
                    <Grid item className={classes.shipmentWrapper}>
                        <EditShipmentsConfigShipment
                            shipment={shipment}
                            onDateUpdate={handleDateUpdate}
                            onShowProductGrid={handleShowProductGrid}
                            onUpdateShipment={handleUpdateShipment}
                            onUpdatePallets={handleUpdatePallets}
                            isUniqueDateTime={checkUniqueDateTime}
                            isValidShipment={setValidShipment}
                            deleteProductUpdatePallets={deleteProductUpdatePallets}
                            hasCreditHold={hasCreditHold}
                            initialShipmentState={initialShipmentState}
                            productsToEdit={productsToEdit}
                            editShipToId={deliveryToEdit?.deliveryOrder.shipToId}
                            isLargeCustomer={isLargeCustomer}
                            isCopacker={isCopacker}
                        />
                    </Grid>
                    <Grid
                        item
                        className={clsx(classes.addProductGrid, {
                            [classes.showProductGrid]: addProducts
                        })}
                    >
                        <EditShipmentsAddProductGrid
                            shipItItems={productsToEdit}
                            onAddItem={handleAddProductToShipment}
                            shipment={shipment}
                            isBulkUpload={false}
                        />
                    </Grid>
                    <ShipmentDiscardModal
                        open={discardModal}
                        onDiscardChanges={handleDiscardChanges}
                        onClose={handleCloseDiscardModal}
                        onCancel={handleCloseDiscardModal}
                    />
                    <ShipmentFeeModal
                        open={feeModal}
                        onAcceptFee={handleSubmit}
                        onClose={handleCloseFeeModal}
                        onClickNo={handleCloseFeeModal}
                        maxPallets={maxPallets}
                    />
                </Grid>
            </ProcessingPageTemplate>
        </EditShipmentConfigurationProvider>
    );
}
