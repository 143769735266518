import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import styled from 'styled-components';
import {
    ballGray,
    ballBlue,
    ballLtGray,
    ballLtGray_15,
    white,
    inputBorderGray,
    datepickerHoverGray,
    datepickerDefaultGray,
    lato
} from '../../../themes/globalConstants';
import { makeStyles } from '@material-ui/core';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import clsx from 'clsx';

interface Props {
    name: string;
    isEditDraft: boolean;
    date: moment.Moment | null;
    placeholder?: string;
    isOutsideRange?: (day: any) => boolean;
    availableDates?: string;
    keepOpen?: boolean;
    noBorder?: boolean;
    calendarInfo?: any;
    customInputIcon?: any;
    numberOfMonths?: number;
    onDateChange?: (name: string, date: moment.Moment | null) => void;
    readOnly?: boolean;
    onBlur?: (name: string) => void;
    firstDayOfWeek?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined;
    enableOutsideDays?: boolean;
}

const StyledDatePickerWrapper = styled.div`
    & .SingleDatePicker,
    .SingleDatePickerInput {
        width: 100%;
        .DateInput {
            max-width: 17.5em;
            width: 100%;

            .DateInput_input {
                ::placeholder {
                    color: #363b3e;
                }
                background-color: ${ballLtGray_15};
                border-bottom: 1px solid ${inputBorderGray};
                padding: 0.9em 0.7em 1.1em;
                font-size: 14px;
            }
        }

        .SingleDatePicker_picker {
            z-index: 100;
        }

        .SingleDatePickerInput__withBorder {
            border: 0;
            width: 100%;
        }

        .CalendarDay__selected_span {
            background: ${ballGray};
            color: ${white};
            border-bottom: 1px solid ${ballLtGray};
        }

        .CalendarDay__selected {
            background: ${ballBlue};
            border: 1px double ${ballBlue};
            color: ${white};
        }

        .CalendarDay__selected:hover {
            background: ${ballLtGray};
            color: ${datepickerHoverGray};
            border: 1px double ${ballBlue};
        }

        .CalendarDay__hovered_span:hover,
        .CalendarDay__hovered_span {
            background: ${ballLtGray};
        }

        .CalendarDay {
            font-family: ${lato};
            .CalendarDay_default {
                border: 1px solid ${datepickerDefaultGray};
                border-radius: 2px;
            }
        }

        .CalendarMonth_caption {
            font-family: ${lato};
        }

        .DayPicker_weekHeader_li {
            font-family: ${lato};
        }

        .DayPickerKeyboardShortcuts_show__bottomRight::before {
            border-top: 26px solid transparent;
            border-right: 33px solid ${ballBlue};
        }
    }
`;

const useStyles = makeStyles((theme) => ({
    navBtn: {
        position: 'absolute',
        top: 18,
        lineHeight: 0.78,
        borderRadius: 3,
        padding: '6px 9px',
        color: ballBlue,
        fontSize: '2rem'
    },
    prevIcon: {
        transform: 'rotate(90deg)',
        left: 22
    },
    nextIcon: {
        transform: 'rotate(270deg)',
        right: 22
    }
}));

const DatePicker = (props: Props) => {
    const [date, setDate] = useState<moment.Moment | null>(props.date ?? null);
    const [focused, setFocused] = useState<boolean | null>(null);
    const classes = useStyles();

    // Sets date to local state and any parent level methods
    const handleDateChange = (newDate: moment.Moment | null) => {
        setDate(newDate);
        if (props.onDateChange) {
            props.onDateChange(props.name, newDate);
        }
    };

    // Used to reset value of date picker for form reset
    useEffect(() => {
        if (props.date === null) {
            handleDateChange(props.date);
        } else if (props.date && !date) {
            handleDateChange(props.date);
        }
    }, [props.date]);

    useEffect(() => {
        if (props.onBlur && !focused) {
            props.onBlur(props.name);
        }
    }, [focused]);

    const prevIcon = <ExpandMoreOutlinedIcon className={clsx(classes.navBtn, classes.prevIcon)} />;
    const nextIcon = <ExpandMoreOutlinedIcon className={clsx(classes.navBtn, classes.nextIcon)} />;

    return (
        <StyledDatePickerWrapper>
            <SingleDatePicker
                placeholder={props.placeholder ? props.placeholder : ''}
                date={date}
                id={props.name}
                keepOpenOnDateSelect={props.keepOpen}
                navPrev={prevIcon}
                navNext={nextIcon}
                numberOfMonths={props.numberOfMonths}
                enableOutsideDays={props.enableOutsideDays}
                customInputIcon={props.customInputIcon}
                noBorder={props.noBorder}
                hideKeyboardShortcutsPanel={true}
                onDateChange={(newDate) => handleDateChange(newDate)}
                focused={focused}
                onFocusChange={({ focused }) => setFocused(focused)}
                isOutsideRange={props.isOutsideRange}
                readOnly={props.readOnly}
                firstDayOfWeek={props.firstDayOfWeek ?? 0}
            />
        </StyledDatePickerWrapper>
    );
};

export default DatePicker;
