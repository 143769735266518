import { CircularProgress, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Activity } from '../../../utility/auth/useSecurity';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import Button from '../../reusable/atoms/Button';
import { useTypedSelector } from '../../../store/reducers/reducer';
import {
    BulkUploadMakeItState,
    MakeItBulkOrder,
    MakeItBulkLineItem
} from '../../../store/reducers/makeit-bulk-upload';
import { boldWeight, containerMaxWidth, red, small } from '../../../themes/globalConstants';
import MakeItBulkUploadReviewOrderSection from './components/MakeItBulkUploadReviewOrderSection';
import { getInvalidLines } from '../../../utility/helpers/make-it-bulk-helpers';
import { useHistory } from 'react-router-dom';
import ConfirmationModal from '../../reusable/molecules/ConfirmationModal';
import { useDispatch } from 'react-redux';
import { resetMakeItBulkState, submitMakeItBulkUpload } from '../../../store/root-actions';
import { AuthState } from '../../../store/reducers/auth';
import OrdersService from '../../../utility/services/orders-service';
import { getAccountAddress } from '../../../utility/helpers/address-helpers';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import AccountService from '../../../utility/services/account-service';
import {
    getProductsBelowAbsoluteMinimum,
    validateMoqAmounts
} from '../../../utility/helpers/order-helpers';
import MakeItBulkFeeModal from '../../reusable/molecules/MakeItBulkFeeModal';
import ErrorAlert from '../../reusable/atoms/ErrorAlert';
import ImpersonationWarning from '../../reusable/molecules/ImpersonationWarning';
import { selectIsImpersonation } from '../../../store/selectors';

const useStyles = makeStyles((theme) => ({
    actionBtn: {
        height: '3.375em',
        width: '13.75em',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom'
    },
    subheaderPaper: {
        marginTop: '3em',
        padding: '1em',
        borderRadius: 2,
        maxWidth: containerMaxWidth
    },
    tablePaper: {
        marginTop: '.1em',
        padding: '1em',
        borderRadius: 4,
        maxWidth: containerMaxWidth
    },
    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0.5em 0'
    },
    error: {
        paddingTop: '1.5em'
    },
    actionBar: {
        margin: '1.5em 0',
        padding: '0'
    },
    errorMessage: {
        fontSize: small,
        fontWeight: boldWeight,
        color: red
    }
}));

export default function MakeItBulkUploadReview() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { accessToken } = useTypedSelector<AuthState>((state) => state.auth);
    const { orders, error } = useTypedSelector<BulkUploadMakeItState>(
        (state) => state.bulkUploadMakeItState
    );
    const { userInfo } = useTypedSelector<AuthState>((state) => state.auth);
    const [impersonationWarning, setImpersonationWarning] = useState<boolean>(false);
    const isImpersonationShipTo = useTypedSelector<boolean>(selectIsImpersonation);

    const [hasErrors, setHasErrors] = useState<boolean>(false);
    const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
    const [openBackModal, setOpenBackModal] = useState<boolean>(false);

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [displayServerError, setDisplayServerError] = useState<boolean>(false);
    const [tableInformation, setTableInformation] = useState<MakeItBulkLineItem[]>([]);
    const [acceptFee, setAcceptFee] = useState(false);
    const [openFeeModal, setOpenFeeModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [errorTranslation, setErrorTranslation] = useState<string>('');
    const [noActiveOrders, setNoActiveOrders] = useState<boolean>(true);

    const onCloseFeeModal = () => {
        setOpenFeeModal(false);
    };

    const onAcceptFee = () => {
        setAcceptFee(true);
    };

    const onSubmit = useCallback(() => {
        let productList: MakeItBulkLineItem[] = [];

        let filteredOrders = orders?.filter((order) => {
            if (!order.markedRemoved) {
                order.lines.forEach((line) => {
                    line.shipToId = order.shipToId;
                    line.shipToName = order.shipToName;
                    line.shipToAddress = order.shipToAddress;
                    productList.push(line);
                });
                return order;
            }
            return null;
        });

        setTableInformation(productList);

        let productsBelowAbsoluteMinimum = getProductsBelowAbsoluteMinimum(productList);

        if (validateMoqAmounts(productList, acceptFee) && filteredOrders) {
            setIsSubmitting(true);
            // remove lines that have been deleted
            filteredOrders.forEach((order) => {
                order.createdBy = userInfo?.preferred_username;
                let filteredLines = order.lines.filter((line) => !line.isLineRemoved);
                order.lines.splice(0, order.lines.length, ...filteredLines);
            });

            // remove orders that have had all lines deleted
            let remainingOrders = filteredOrders.filter((order) => order.lines.length > 0);

            if (isImpersonationShipTo) {
                setImpersonationWarning(true);
                setIsSubmitting(false);
            } else if (remainingOrders.length > 0) {
                let formattedOrders: MakeItBulkOrder[] = [];
                remainingOrders.forEach((order) => {
                    const formattedLines = OrdersService.createMakeItOrderlines(order.lines);
                    const updatedOrder = {
                        ...order,
                        lines: formattedLines
                    };
                    formattedOrders.push(updatedOrder);
                });
                dispatch(submitMakeItBulkUpload(formattedOrders));
            }
        } else {
            if (!acceptFee && productsBelowAbsoluteMinimum.length === 0) {
                setOpenFeeModal(true);
            }
        }
    }, [acceptFee, dispatch, isImpersonationShipTo, orders, userInfo?.preferred_username]);

    useEffect(() => {
        if (orders && orders.length) {
            orders.forEach((order) => {
                if (order.shipToId && shipToAccounts && shipToAccounts.length > 0) {
                    const shipToId = order.shipToId.toString();
                    const shipToAccount = shipToAccounts.find(
                        (account) => shipToId === account.accountId
                    );
                    if (shipToAccount) {
                        order.shipToName = shipToAccount.name;
                        order.shipToAddress = getAccountAddress(shipToAccount);
                    } else {
                        AccountService.getAccount(shipToId, false, accessToken)
                            .then((response) => {
                                const account = response.data.account;
                                order.shipToName = account.name;
                                order.shipToAddress = getAccountAddress(account);
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    }
                }
            });
        }
    }, [accessToken, orders, shipToAccounts]);

    const onBack = () => {
        setOpenBackModal(true);
    };

    const handleBackNavigation = () => {
        history.push('/make-it-bulk-upload?from=review');
    };

    const onClose = () => {
        if (openBackModal) {
            setOpenBackModal(false);
        } else {
            setOpenCancelModal(false);
        }
    };

    const onCancel = () => {
        setOpenCancelModal(true);
    };

    const handleCancel = () => {
        setOpenCancelModal(false);
        dispatch(resetMakeItBulkState());
        history.push('/make-it');
    };

    useEffect(() => {
        let hasLineErrors = false;
        if (orders) {
            for (let order of orders) {
                let invalidLines = getInvalidLines(order.lines);
                if (!order.markedRemoved && invalidLines.length > 0) {
                    hasLineErrors = true;
                    break;
                }
                for (let line of order.lines) {
                    if (line.childLines) {
                        let invalidChildLines = getInvalidLines(line.childLines, true);
                        if (!order.markedRemoved && invalidChildLines.length > 0) {
                            hasLineErrors = true;
                            break;
                        }
                    }
                }
            }
            setNoActiveOrders(!hasActiveOrders(orders));
        }
        setErrorMessage(
            'There are errors associated with one or more orders below. Please correct.'
        );
        setErrorTranslation('makeItBulkError');
        setHasErrors(hasLineErrors);
    }, [orders]);

    const hasActiveOrders = (orders: MakeItBulkOrder[]): boolean => {
        const activeOrders = orders.filter(
            (order) => order.markedRemoved === undefined || order.markedRemoved === false
        );
        return activeOrders.length > 0;
    };

    useEffect(() => {
        if (isSubmitting && orders) {
            setIsSubmitting(false);
            history.push('/make-it-bulk-upload-confirmation');
        }
    }, [history, isSubmitting, orders]);

    useEffect(() => {
        if (error) {
            setIsSubmitting(false);
            setDisplayServerError(true);
        } else {
            setDisplayServerError(false);
        }
    }, [error]);

    // if user accepts the MOQ fee we want to submit their order
    useEffect(() => {
        if (acceptFee) {
            onSubmit();
        }
    }, [acceptFee, onSubmit]);

    const footerActions = (
        <>
            <Grid container item xs={2} justify="center">
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="cancel-btn"
                    className={classes.actionBtn}
                    onClick={onCancel}
                >
                    <Trans i18nKey="cancel">Cancel</Trans>
                </Button>
            </Grid>
            <Grid container item xs={7}>
                <ImpersonationWarning 
                    showWarning={impersonationWarning} 
                    warningType={'SUBMIT'}
                />
            </Grid>
            <Grid container item xs={3} justify="flex-end">
                <Grid container item xs={4}>
                    <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        data-testid="back-btn"
                        onClick={onBack}
                        className={classes.actionBtn}
                    >
                        <Trans i18nKey="back">Back</Trans>
                    </Button>
                </Grid>
                <Grid container item xs={7} justify="flex-end">
                    <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        data-testid="submit-order-btn"
                        className={classes.actionBtn}
                        disabled={hasErrors || isSubmitting || noActiveOrders}
                        onClick={onSubmit}
                    >
                        <Trans i18nKey="submitOrders">Submit Order(s)</Trans>
                    </Button>
                </Grid>
            </Grid>
        </>
    );

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('makeIt', 'Make It'),
                description: t('reviewYourOrders', 'Review Your Order(s)').toUpperCase(),
                thinBanner: true,
                displayDropdown: false
            }}
            actionFooter={{
                footerAction: footerActions,
                justify: 'space-between',
                sticky: true
            }}
            activity={Activity.MakeItBulkUpload}
            shipTos={false}
        >
            <Grid container xs={12} className={classes.error}>
                <ErrorAlert
                    showError={hasErrors}
                    errorMessage={t(errorTranslation, errorMessage)}
                    dataTestId={'make-it-order-error'}
                />
            </Grid>
            {orders &&
                orders.map((order, index) => (
                    <Paper elevation={2} className={classes.subheaderPaper} key={index}>
                        <MakeItBulkUploadReviewOrderSection order={order} />
                    </Paper>
                ))}

            {isSubmitting && (
                <Grid container item xs={12} className={classes.spinningLoader}>
                    <CircularProgress />
                </Grid>
            )}
            {displayServerError && (
                <Grid container item xs={12} justify="center">
                    <Typography>
                        <Trans i18nKey="serverError">
                            A server error occured while submitting. Please try again.
                        </Trans>
                    </Typography>
                </Grid>
            )}
            {orders && noActiveOrders && (
                <Grid
                    container
                    item
                    xs={12}
                    alignItems="center"
                    justify="center"
                    className={classes.actionBar}
                >
                    <Typography className={classes.errorMessage}>
                        <Trans i18nKey="allOrdersDeleted">
                            All orders have either been deleted or removed. Please "undo" at least
                            one deleted order or upload a new order file to proceed.
                        </Trans>
                    </Typography>
                </Grid>
            )}
            <MakeItBulkFeeModal
                bulkProducts={tableInformation}
                isAtm={false}
                open={openFeeModal}
                onClose={onCloseFeeModal}
                onAcceptFee={onAcceptFee}
            />
            <ConfirmationModal
                saveProgress={handleCancel}
                onClose={onClose}
                logo={false}
                title={<Trans i18nKey="cancel">Cancel</Trans>}
                subheader={<Trans i18nKey="changesNotSaved">Your changes will not be saved</Trans>}
                description={
                    <Trans i18nKey="confirmCancel">Are you sure you want to cancel?</Trans>
                }
                continueText={<Trans i18nKey="yesCancel">Yes, Cancel</Trans>}
                cancelText={<Trans i18nKey="no">No</Trans>}
                open={openCancelModal}
                navigationLink=""
                onCancel={onClose}
            />
            <ConfirmationModal
                saveProgress={handleBackNavigation}
                onClose={onClose}
                logo={false}
                title={<Trans i18nKey="back">Back</Trans>}
                subheader={<Trans i18nKey="changesNotSaved">Your changes will not be saved</Trans>}
                description={
                    <Trans i18nKey="goBackConfirm">Are you sure you want to go back?</Trans>
                }
                continueText={<Trans i18nKey="yesGoBack">Yes, Go Back</Trans>}
                cancelText={<Trans i18nKey="no">No</Trans>}
                open={openBackModal}
                navigationLink=""
                onCancel={onClose}
            />
        </ProcessingPageTemplate>
    );
}
