import { TableRow, TableCell, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { PricingLine } from '../../../../store/reducers/pricing-breakdown';
import { boldWeight, medium } from '../../../../themes/globalConstants';
interface Props {
    line: PricingLine;
}

const useStyles = makeStyles(() => ({
    name: {
        fontWeight: boldWeight,
        fontSize: medium,
        letterSpacing: 0.17
    }
}));

const PricingBreakdownRow = ({ line }: Props) => {
    const classes = useStyles();

    return (
        <TableRow hover={true} key={line.itemDescription}>
            <TableCell data-testid={'description'}>
                <Grid container spacing={1}>
                    <Grid item className={classes.name}>
                        {line.itemDescription}
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell data-testid={'current-base-price'}>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        {line.currentBasePriceFormat}
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell data-testid={'new-base-price'}>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        {line.newBasePriceFormat}
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell data-testid={'freight-charges-location'}>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        {line.freightChargeFromLocation}
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell data-testid={'freight-charges'}>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        {line.freightChargesFormat}
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell data-testid={'new-bsae-with-freight'}>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        {line.newBasePriceWithFreightFormat}
                    </Grid>
                </Grid>
            </TableCell>
        </TableRow>
    );
};

export default PricingBreakdownRow;
