import {
    LIQUID_INTAKE_SUMMARY_LOADING,
    LIQUID_INTAKE_SUMMARY_LOAD_COMPLETED,
    LIQUID_INTAKE_SUMMARY_LOAD_ERROR,
    LIQUID_INTAKE_SUMMARY_LOAD_OPEN
} from '../actions/action-types';
import { LiquidTestIntake } from './liquid-intake';

export interface LiquidTestSummaryState {
    openLiquidTests: LiquidTestIntake[];
    recentlyCompletedLiquidTests: LiquidTestIntake[];
    loading: boolean;
}

const initialState: LiquidTestSummaryState = {
    openLiquidTests: [],
    recentlyCompletedLiquidTests: [],
    loading: false
};

const liquidTestSummary = (
    state: LiquidTestSummaryState = initialState,
    action: { type: string; [x: string]: any }
): LiquidTestSummaryState => {
    switch (action.type) {
        case LIQUID_INTAKE_SUMMARY_LOADING:
            return { ...state, loading: true };
        case LIQUID_INTAKE_SUMMARY_LOAD_OPEN:
            return { ...state, openLiquidTests: action.liquidTests, loading: false };
        case LIQUID_INTAKE_SUMMARY_LOAD_COMPLETED:
            return { ...state, recentlyCompletedLiquidTests: action.liquidTests, loading: false };
        case LIQUID_INTAKE_SUMMARY_LOAD_ERROR:
            return { ...state, loading: false };
        default:
            return state;
    }
};

export default liquidTestSummary;
