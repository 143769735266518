import { canadaStateProvs, mexicoStateProvs, usStateProvs } from '../../static-data/dropdown-lists';
import { CapacityCheck } from '../../store/reducers/capacity-checks';
import {
    Address,
    CompanyInformation,
    CompanyOfficer,
    CreditApplication,
    BillingAndShippingForm,
    SupplierReference,
    Importer,
    ShipType,
    AccountStatus,
    BillingAndShippingState
} from '../../store/reducers/onboarding-dashboard';
import { KeyValuePair } from '../../store/types';
import { OnboardingStepState, OnboardingStepStatus, ProductEnhancement } from '../services/onboarding-service';
import { getStateProvSelection, NCACountries } from './address-helpers';

export function stateDropdownSelector(
    country: string,
    setter: (value: React.SetStateAction<any[]>) => void
) {
    switch (country) {
        case NCACountries.USA:
            setter(usStateProvs);
            break;
        case NCACountries.Mexico:
            setter(mexicoStateProvs);
            break;
        case NCACountries.Canada:
            setter(canadaStateProvs);
            break;
        default:
            setter([]);
            break;
    }
}

export const defaultCreditFormState = {
    headsOfBusiness: [{ firstName: '', lastName: '', title: '' }]
};

export function buildSavedFormArray(savedArray: CompanyOfficer[]): any {
    return {
        headsOfBusiness: savedArray.map((entry) => {
            return {
                firstName: entry.firstName ? entry.firstName : '',
                lastName: entry.lastName ? entry.lastName : '',
                title: entry.title ? entry.title : ''
            };
        })
    };
}

export function getCountryAndState(country: string | KeyValuePair, state: string | KeyValuePair) {
    const countryState = { country: '', state: '' };

    if (typeof country === 'object') {
        countryState.country = country?.value ? country.value : '';
    } else {
        countryState.country = country ? country : '';
    }

    if (typeof state === 'object') {
        countryState.state = state?.value ? state.value : '';
    } else {
        countryState.state = state ? state : '';
    }

    return countryState;
}

export function getFormStateFromBillingAndShipping(billingAndShipping: BillingAndShippingForm) {
    const shippingCountry = billingAndShipping.shippingAddress?.country ?? NCACountries.USA;
    const billingCountry = billingAndShipping.billingAddress?.country ?? NCACountries.USA;

    const billingState = getStateProvSelection(
        billingAndShipping.billingAddress?.state,
        billingCountry
    );
    const shippingState = getStateProvSelection(
        billingAndShipping.shippingAddress?.state,
        shippingCountry
    );

    const form = {
        billingAddress: {
            ...billingAndShipping.billingAddress,
            state: billingState
        },
        shippingAddress: {
            ...billingAndShipping.shippingAddress,
            state: shippingState
        },
        shipType: billingAndShipping?.shippingInformation?.shipType?.toString(),
        palletConfiguration: billingAndShipping?.shippingInformation?.palletConfiguration,
        specialEquipment: billingAndShipping?.shippingInformation?.specialEquipment,
        cfdi:
            billingAndShipping?.idInformation?.cfdi === true
                ? 'Yes'
                : billingAndShipping?.idInformation?.cfdi === false
                    ? 'No'
                    : undefined,
        taxId: billingAndShipping?.idInformation?.taxId,
        entityType: billingAndShipping?.idInformation?.entityType?.toString(),
        importer: billingAndShipping?.idInformation?.importer?.toString(),
        shippingSameAsBillingAddress:
            billingAndShipping.billingAddress?.name === billingAndShipping.shippingAddress?.name
                ? 'Yes'
                : 'No'
    } as BillingAndShippingForm;
    return form;
}

export function billingAndShippingBuilder(data) {
    let shippingAddress: Address;
    const billingAddress = addressBuilder(data, 'billingAddress');
    if (data.shippingSameAsBillingAddress === 'Yes') {
        shippingAddress = billingAddress;
    } else {
        shippingAddress = addressBuilder(data, 'shippingAddress');
    }

    return getBillingShippingForm(billingAddress, shippingAddress, data);
}

export function getBillingShippingForm(billingAddress: Address, shippingAddress: Address, data) {
    const entityType = data.entityType === '' ? null : data.entityType;

    return {
        billingAddress: billingAddress,
        shippingAddress: shippingAddress,
        shippingInformation: {
            shipType:
                data.shipType !== '' && data.shipType !== undefined
                    ? data.shipType
                    : ShipType.Unknown,
            palletConfiguration: data.palletConfiguration,
            specialEquipment: data.specialEquipment
        },
        idInformation: {
            cfdi: data.cfdi === 'Yes' ? true : data.cfdi === 'No' ? false : undefined,
            taxId: data.taxId,
            entityType: entityType,
            importer: data.importer ?? Importer.Unknown
        }
    } as BillingAndShippingForm;
}

export function creditAppBuilder(formData): CreditApplication {
    let creditApp: CreditApplication = {};

    // Credit App Header Section
    creditApp.legalBusinessName = getField(formData, 'legalBusinessName');
    creditApp.businessName = getField(formData, 'businessName');
    creditApp.businessType = getField(formData, 'businessType');
    creditApp.monthlyCreditDesired = getField(formData, 'monthlyCreditDesired');

    creditApp.billingAddress = addressBuilder(formData, 'billingAddress');
    creditApp.actualLocation = addressBuilder(formData, 'actualLocation');

    creditApp.companyInformation = companyInfoBuilder(formData);

    creditApp.supplierReferences = supplierReferencesBuilder(formData); // we know there will be 3 of these

    creditApp.additionalComments = getField(formData, 'additionalComments');
    creditApp.fileExplanation = getField(formData, 'fileExplanation');

    return creditApp;
}

export function addressBuilder(formData, addressFieldName: string): Address {
    const address: Address = {};
    const state = getField(formData, `${addressFieldName}.state`);
    const country = getField(formData, `${addressFieldName}.country`);
    const countryAndState = getCountryAndState(country ?? '', state ?? '');

    address.name = getField(formData, `${addressFieldName}.name`);
    address.country = countryAndState.country;
    address.addressLine1 = getField(formData, `${addressFieldName}.addressLine1`);
    address.addressLine2 = getField(formData, `${addressFieldName}.addressLine2`);
    address.addressLine3 = getField(formData, `${addressFieldName}.addressLine3`);
    address.city = getField(formData, `${addressFieldName}.city`);
    address.state = countryAndState.state;
    address.zipCode = getField(formData, `${addressFieldName}.zipCode`);
    return address;
}

function companyInfoBuilder(formData): CompanyInformation {
    const companyInfo: CompanyInformation = {};
    const companySection = 'companyInformation';

    companyInfo.contact = getField(formData, `${companySection}.contact`);
    companyInfo.contactEmail = getField(formData, `${companySection}.contactEmail`);
    companyInfo.country = getField(formData, `${companySection}.country`);
    companyInfo.phoneNumber = getField(formData, `${companySection}.phoneNumber`);
    companyInfo.incorporationState = getField(formData, `${companySection}.incorporationState`);
    companyInfo.dateEstablished = getField(formData, `${companySection}.dateEstablished`);
    companyInfo.businessType = getField(formData, `${companySection}.businessType`);

    const headsOfBusiness: CompanyOfficer[] = [];
    const fieldName = 'headsOfBusiness';

    // we know there is a max of three
    for (let index = 0; index < 3; index++) {
        const firstName = getField(formData, `${fieldName}.${index}.firstName`);
        const lastName = getField(formData, `${fieldName}.${index}.lastName`);
        const title = getField(formData, `${fieldName}.${index}.title`);

        if (firstName || lastName || title) {
            headsOfBusiness.push({ firstName: firstName, lastName: lastName, title: title });
        }
    }

    companyInfo.headsOfBusiness = headsOfBusiness;

    return companyInfo;
}

function supplierReferencesBuilder(formData): SupplierReference[] {
    const supplierReferences: SupplierReference[] = [];

    const fieldName = 'supplierReferences';

    for (let index = 0; index < 3; index++) {
        // we know there will always be three
        const supplierReference: SupplierReference = {};

        supplierReference.firstName = getField(formData, `${fieldName}[${index}].firstName`);
        supplierReference.lastName = getField(formData, `${fieldName}[${index}].lastName`);
        supplierReference.address = getField(formData, `${fieldName}[${index}].address`);
        supplierReference.contact = getField(formData, `${fieldName}[${index}].contact`);
        supplierReference.contactEmail = getField(formData, `${fieldName}[${index}].contactEmail`);
        supplierReference.phoneNumber = getField(formData, `${fieldName}[${index}].phoneNumber`);
        supplierReference.faxNumber = getField(formData, `${fieldName}[${index}].faxNumber`);

        supplierReferences.push(supplierReference);
    }

    return supplierReferences;
}

function getField(formData, fieldName: string): string | undefined {
    return formData[fieldName] ? formData[fieldName] : undefined;
}

// recursively search an error object for certain error types
// return true if any of the specified error types are found else return false
// error should be the error object you're inspecting
// depth is the current depth within the tree
// maxDepth is the deepest level of the tree you'd like to search
// types are the error type(s) we're searching for
export function searchErrorTree(error, depth: number, maxDepth: number, types: string[]): boolean {
    if (!error) {
        return false;
    } else if (error.type) {
        return types.includes(error.type);
    } else if (depth === maxDepth) {
        return false;
    } else {
        for (let key of Object.keys(error)) {
            if (typeof error[key] === 'object') {
                const result = searchErrorTree(error[key], depth + 1, maxDepth, types);
                // if we find a matching type we're done
                if (result) {
                    return result;
                }
            }
        }
    }
    return false;
}

// Used in OnboardBillingAndShipping, CreditApplicationPage, and SalesAndUseTax
// to prevent user from accessing the page via URL when the Joining statuses and
// conditions don't allow it.
export function redirectToNotFoundPage(
    accountStatus: AccountStatus,
    billingAndShipping?: BillingAndShippingState
): boolean {
    if (!accountStatus.accountId || accountStatus.termsStatus !== OnboardingStepStatus.COMPLETE) {
        return true;
    }
    if (
        billingAndShipping &&
        billingAndShipping.state === OnboardingStepState.COMPLETE &&
        (billingAndShipping.billingAndShippingForm?.billingAddress?.country === 'CA' ||
            billingAndShipping.billingAndShippingForm?.billingAddress?.country === 'MX')
    ) {
        return true;
    }
    return false;
}
export const validateFileInformation = (fileData?: File[], maxFileSizeInBytes?: number) => {
    if (typeof fileData === 'undefined') {
        return 'undefined';
    } else if (fileData.length > 0) {
        if (!maxFileSizeInBytes || (maxFileSizeInBytes && fileData[0].size < maxFileSizeInBytes)) {
            return 'valid';
        } else {
            return 'file-size-error';
        }
    } else {
        return 'error';
    }
};

export function formatEndSize(endSize: string) {
    return endSize.replace(' - End', '');
};

export function getVarnishAndInkDisplay(product: CapacityCheck) {
    const varnish = product.varnish;
    const inkCoating = product && product.inksCoatings && product.inksCoatings.replace(/;/g, ', ');
    if (varnish && inkCoating) {
        return `${varnish} / ${inkCoating}`;
    } else if (varnish) {
        return varnish;
    } else if (inkCoating) {
        return inkCoating;
    }
};

export function getProductEnhancementDisplay(productEnhancement: string) {
    switch (productEnhancement) {
        case ProductEnhancement.BRE:
            return 'Ball Resealable End';
        case ProductEnhancement.WIDGET:
            return 'Widget Inside™';
        default:
            return productEnhancement;
    }
}