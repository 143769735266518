import moment from 'moment';
import { QuantityType, RowsOrColumns } from '../../utility/services/orders-service';
import {
    PLANIT_BULK_UPLOAD_ATM_LOADING,
    PLANIT_BULK_UPLOAD_ATM_LOADED,
    PLANIT_BULK_UPLOAD_RESET_STATE,
    PLANIT_BULK_ATM_SUBMIT_REVIEW,
    PLANIT_BULK_UPLOAD_ATM_UPDATE_PALLET_QUANTITY,
    PLANIT_BULK_ATM_SNOSKU_ORDER,
    PLANIT_BULK_ATM_DELETE_PRODUCT,
    PLANIT_BULK_ATM_ADD_PRODUCT,
    PLANIT_BULK_ATM_SUBMIT_ORDERS_SUCCESS,
    PLANIT_BULK_ATM_SUBMIT_ORDERS_LOADING,
    PLANIT_BULK_ATM_SUBMIT_ORDERS_ERROR,
    PLANIT_BULK_ATM_EDIT_ORDER_LOADED,
    PLANIT_BULK_ATM_EDIT_ORDER_LOADING,
    PLANIT_BULK_ATM_SUBMIT_DELTA_REVIEW,
    PLANIT_BULK_ATM_UPDATED_PRODUCT_SKU,
    PLANIT_BULK_ATM_EXISTING_ORDERS,
    PLANIT_BULK_UPLOAD_ATM_TEMPLATE_ERROR,
    PLANIT_BULK_UPLOAD_ATM_TEMPLATE_CLEAR,
    PLANIT_BULK_UPLOAD_REFRESH_STATE,
    PLANIT_BULK_UPLOAD_LOADING,
    PLANIT_BULK_UPLOAD_GET_PRODUCTS,
    PLANIT_BULK_UPLOAD_ERROR,
    PLANIT_BULK_UPLOAD_REMOVE_ORDER,
    PLANIT_BULK_UPLOAD_FILE,
    PLANIT_BULK_ATM_UPDATE_CUSTOMER_PO
} from '../actions/action-types';
import { TemplateMapping } from './bulk-upload';
import {
    MakeItBulkATMLineItem,
    MakeItBulkATMLinesBySnoSku,
    MakeItBulkATMOrder,
    MakeItBulkATMOrderByWeekAndSku,
    WeekQuantity
} from './makeit-bulk-atm';
import { MakeItLineError } from './makeit-bulk-upload';

export interface PlanItBulkATMLineItem extends MakeItBulkATMLineItem {
    destinations?: [
        {
            shipToId: number;
            customerProductId: string;
            customerProductName: string;
            status: string;
        }
    ];
    originalEachesQuantity?: number;
}

export interface PlanItBulkATMLinesBySnoSku extends MakeItBulkATMLinesBySnoSku {}

export interface PlanItBulkATMOrderByWeekAndSku extends MakeItBulkATMOrderByWeekAndSku {}

export interface PlanItBulkATMOrder extends MakeItBulkATMOrder {}

export interface PlanItLineError extends MakeItLineError {}

export interface UnrecognizedProduct {
    productId: string;
    shipToId: string;
    username: string;
    lineNumber: number;
    lineNumberString?: string;
    productName: string;
    productSize: string;
}

export interface InactiveProduct {
    productId: string;
    lineNumber: number;
    lineNumberString?: string;
    shipToId: number;
    productDescription?: string;
    forecastWeek: string;
}

export interface BulkUploadPlanItATMState {
    forecastTemplateMappings?: TemplateMapping[];
    loading: boolean;
    loaded: boolean;
    ordersLoaded: boolean;
    error?: any;
    templateError?: any;
    file?: File;
    forecastOrders?: PlanItBulkATMOrder[];
    existingOrders: PlanItBulkATMOrder[];
    products?: PlanItBulkATMLineItem[];
    forecastLineErrors?: MakeItLineError[];
    quantityType?: QuantityType;
    rowsOrColumns?: RowsOrColumns;
    weekQuantities?: WeekQuantity[];
    firstRowOfData?: number;
    ordersBySnoSku?: PlanItBulkATMOrderByWeekAndSku[];
    submitForecastOrdersSubmissionState: 'idle' | 'loading' | 'error' | 'success';
    isEdit?: boolean;
    weeks?: moment.Moment[];
    unrecognizedProducts?: UnrecognizedProduct[];
    inactiveProducts?: InactiveProduct[];
}

const initialState: BulkUploadPlanItATMState = {
    forecastTemplateMappings: undefined,
    loading: false,
    loaded: false,
    ordersLoaded: false,
    forecastOrders: undefined,
    ordersBySnoSku: undefined,
    submitForecastOrdersSubmissionState: 'idle',
    existingOrders: [],
    weeks: undefined,
    isEdit: false,
    unrecognizedProducts: undefined,
    inactiveProducts: undefined
};

const bulkUploadPlanIt = (
    state: BulkUploadPlanItATMState = initialState,
    action: { type: string; [x: string]: any }
): BulkUploadPlanItATMState => {
    switch (action.type) {
        case PLANIT_BULK_UPLOAD_RESET_STATE:
            return {
                ...initialState
            };
        case PLANIT_BULK_UPLOAD_ERROR:
            return { ...state, loaded: true, loading: false, error: action.error };
        case PLANIT_BULK_UPLOAD_LOADING:
            return { ...state, loaded: false, loading: true, error: undefined };
        case PLANIT_BULK_UPLOAD_REMOVE_ORDER:
            return {
                ...state,
                forecastOrders: undefined
            };
        case PLANIT_BULK_UPLOAD_GET_PRODUCTS:
            return {
                ...state,
                products: action.products,
                loaded: true,
                loading: false
            };
        case PLANIT_BULK_UPLOAD_ATM_LOADING:
            return { ...state, loaded: false, loading: true, error: undefined };
        case PLANIT_BULK_UPLOAD_ATM_LOADED:
            return {
                ...state,
                forecastTemplateMappings: action.mappings,
                quantityType: action.quantityType,
                rowsOrColumns: action.rowsOrColumns,
                weekQuantities: action.weekQuantities,
                firstRowOfData: action.firstRowOfData,
                loading: false,
                loaded: true,
                error: undefined,
                templateError: undefined
            };
        case PLANIT_BULK_ATM_SUBMIT_REVIEW:
            return {
                ...state,
                forecastOrders: action.forecastOrders,
                loading: false,
                loaded: true,
                forecastLineErrors: action.forecastLineErrors,
                error: undefined,
                weeks: action.weeks,
                isEdit: action.isEdit,
                unrecognizedProducts: action.unrecognizedProducts,
                inactiveProducts: action.inactiveProducts
            };
        case PLANIT_BULK_ATM_SUBMIT_DELTA_REVIEW:
            return {
                ...state,
                forecastOrders: action.forecastOrders,
                //Set orders loaded to true after bulk upload deltas merged
                ordersLoaded: true
            };
        case PLANIT_BULK_ATM_SNOSKU_ORDER:
            return {
                ...state,
                ordersBySnoSku: action.ordersBySnoSku
            };
        case PLANIT_BULK_UPLOAD_FILE:
            return {
                ...state,
                file: action.file
            };
        case PLANIT_BULK_UPLOAD_REFRESH_STATE:
            return {
                ...state,
                loading: false,
                loaded: false,
                ordersLoaded: false,
                forecastOrders: undefined,
                ordersBySnoSku: undefined,
                submitForecastOrdersSubmissionState: 'idle',
                existingOrders: [],
                forecastLineErrors: undefined,
                isEdit: false,
                unrecognizedProducts: undefined,
                inactiveProducts: undefined
            };
        case PLANIT_BULK_UPLOAD_ATM_UPDATE_PALLET_QUANTITY:
            return {
                ...state,
                forecastOrders: action.forecastOrders
            };
        case PLANIT_BULK_ATM_ADD_PRODUCT:
            return {
                ...state,
                forecastOrders: action.forecastOrders
            };
        case PLANIT_BULK_ATM_UPDATED_PRODUCT_SKU:
            return {
                ...state,
                forecastOrders: action.forecastOrders
            };
        case PLANIT_BULK_ATM_EXISTING_ORDERS:
            return {
                ...state,
                existingOrders: action.existingOrders
            };
        case PLANIT_BULK_ATM_DELETE_PRODUCT:
            return { ...state, forecastOrders: [...action.forecastOrders] };
        case PLANIT_BULK_ATM_SUBMIT_ORDERS_SUCCESS: {
            return {
                ...state,
                submitForecastOrdersSubmissionState: 'success',
                forecastOrders: action.forecastOrders,
                weeks: undefined
            };
        }
        case PLANIT_BULK_ATM_SUBMIT_ORDERS_LOADING: {
            return {
                ...state,
                submitForecastOrdersSubmissionState: 'loading'
            };
        }
        case PLANIT_BULK_ATM_SUBMIT_ORDERS_ERROR: {
            return {
                ...state,
                submitForecastOrdersSubmissionState: 'error',
                error: action.error
            };
        }
        case PLANIT_BULK_ATM_EDIT_ORDER_LOADING: {
            return {
                ...state,
                loading: true,
                loaded: false,
                submitForecastOrdersSubmissionState: 'idle'
            };
        }
        case PLANIT_BULK_ATM_EDIT_ORDER_LOADED: {
            return {
                ...state,
                forecastOrders: action.forecastOrders,
                products: action.products,
                loading: false,
                loaded: true,
                //Set orders loaded to true for edit flow
                ordersLoaded: true,
                isEdit: true
            };
        }
        case PLANIT_BULK_UPLOAD_ATM_TEMPLATE_ERROR: {
            return {
                ...state,
                templateError: action.error
            };
        }
        case PLANIT_BULK_UPLOAD_ATM_TEMPLATE_CLEAR: {
            return {
                ...state,
                templateError: undefined,
                error: undefined
            };
        }
        case PLANIT_BULK_ATM_UPDATE_CUSTOMER_PO: {
            return {
                ...state,
                forecastOrders: action.forecastOrders
            };
        }
        default:
            return state;
    }
};

export default bulkUploadPlanIt;
