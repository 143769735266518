import { Grid, Button } from '@material-ui/core';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Trans } from 'react-i18next';
import Link from '../../../reusable/atoms/Link';

interface Props {
    queryContent?: any;
}

const ProductDetailsSubheader = ({ queryContent }: Props) => {
    return (
        <Grid container data-testid="request-estimate-btn">
            <Grid item container xs={8}></Grid>
            <Grid item container xs={4} alignItems="center" justify="center">
                <Link
                    data-testid="request-link"
                    to={'/product-estimate'}
                    queryContent={queryContent}
                    underline="none"
                    component={RouterLink}
                >
                    <Button variant="outlined" color="primary">
                        <Trans i18nKey="requestEstBtn">Request an Estimate</Trans>
                    </Button>
                </Link>
            </Grid>
        </Grid>
    );
};

export default ProductDetailsSubheader;
