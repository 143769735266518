import { TableRow, TableCell, Typography, Grid, Checkbox, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { UnrecognizedProduct } from '../../../../store/reducers/planit-bulk-atm';
import { disabledText } from '../../../../themes/globalConstants';
import TextInput from '../../../reusable/molecules/TextInput';

interface Props {
    line: UnrecognizedProduct;
}

const useStyles = makeStyles((theme) => ({
    disabled: {
        color: disabledText
    }
}));

const UnrecognizedProductsRow = ({ line }: Props) => {
    const classes = useStyles();
    const form = useForm({ mode: 'onBlur', reValidateMode: 'onBlur' });
    const [selected, setSelected] = useState<boolean>(false);

    const handleCheckboxClick = () => {
        setSelected(!selected);
    };

    return (
        <TableRow hover={true}>
            <TableCell align="center">
                <Checkbox
                    checked={selected}
                    color="primary"
                    data-testid="unrecognized-product-checkbox"
                    onClick={handleCheckboxClick}
                    disabled={false}
                ></Checkbox>
            </TableCell>
            <TableCell data-testid={'csv-row'}>
                <Typography className={!selected ? classes.disabled : ''}>{line.lineNumberString}</Typography>
            </TableCell>
            <TableCell data-testid={'product-id'}>
                <Typography className={!selected ? classes.disabled : ''}>{line.productId}</Typography>
            </TableCell>
            <TableCell data-testid={'ship-to'}>
                <Typography className={!selected ? classes.disabled : ''}>{line.shipToId ?? ''}</Typography>
            </TableCell>
            <TableCell data-test-id={'product-name'}>
                <Grid container alignContent="center">
                    <Grid item>
                        <TextInput
                            type="text"
                            label={<></>}
                            testId="productInfo.name"
                            control={form.control}
                            name="productInfo.name"
                            readOnly={!selected}
                            defaultValue={line.productName ?? ''}
                        />
                    </Grid>
                </Grid>
            </TableCell>
            {/* Commenting Out the Following Until Future Story */}
            {/* <TableCell data-test-id={'product-size'}>
                <Grid container alignContent="center">
                    <Grid item>
                        <TextInput
                            type="text"
                            label={<></>}
                            testId="productInfo.size"
                            control={form.control}
                            name="productInfo.size"
                            readOnly={!selected}
                            defaultValue={line.productSize ?? ''}
                        />
                    </Grid>
                </Grid>
            </TableCell> */}
        </TableRow>
    );
};

export default UnrecognizedProductsRow;
