import React, { ReactNode, useEffect, useState } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { shouldShowAtmBanner } from '../../../utility/helpers/banner-helpers';
import Link from '../atoms/Link';
import {
    containerMaxWidth,
    boldWeight,
    playground,
    atmPurple
} from '../../../themes/globalConstants';
import { Activity } from '../../../utility/auth/useSecurity';
import { FlagTypes, GetFeatureFlag } from '../../../utility/helpers/feature-flag';
import { useTypedSelector } from '../../../store/reducers/reducer';
import clsx from 'clsx';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import AccountSelection from './AccountSelection';

interface Props {
    header: string;
    description?: string;
    link?: string;
    linkContent?: ReactNode;
    buttonLink?: string;
    buttonContent?: ReactNode;
    buttonIcon?: ReactNode;
    targetString?: string;
    thinBanner?: boolean;
    displayDropdown?: boolean;
    billToDropdown?: boolean;
    completeDropdown?: boolean;
    disableSelect?: boolean;
    filter?: Activity;
    shouldShowATMBanner?: boolean;
}

const useStyles = makeStyles((theme) => ({
    banner: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '& h1': {
            color: theme.palette.common.white
        }
    },
    atmBanner: {
        backgroundColor: atmPurple,
        color: theme.palette.common.white,
        '& h1': {
            color: theme.palette.common.white
        }
    },
    playgroundBanner: {
        backgroundColor: playground,
        color: theme.palette.common.white,
        '& h1': {
            color: theme.palette.common.white
        }
    },
    container: {
        maxWidth: containerMaxWidth,
        margin: 'auto',
        height: '16.875em',
        paddingLeft: '0.875em;'
    },
    thinContainer: {
        maxWidth: containerMaxWidth,
        margin: 'auto',
        height: '6.25em',
        padding: '0 1em 0 1.5em'
    },
    description: {
        marginTop: '0.375em',
        marginBottom: '0',
        marginRight: '1.25em',
        textTransform: 'capitalize',
        letterSpacing: 1,
        fontWeight: boldWeight,
        [theme.breakpoints.down('xs')]: {
            marginTop: '1.5em'
        }
    },
    header: {
        fontSize: 30
    },
    headerWithBanner: {
        fontSize: 30,
        maxWidth: 600
    },
    link: {
        fontSize: '0.75em',
        color: theme.palette.common.white,
        '& hr': {
            backgroundColor: theme.palette.common.white
        },
        '&:hover': {
            color: theme.palette.common.white
        }
    },
    linkContainer: {
        display: 'flex',
        marginTop: '1em',
        [theme.breakpoints.down('xs')]: {
            marginTop: '1.5em'
        }
    },
    linkIcon: {
        marginRight: '0.625em',
        '& svg': {
            fill: theme.palette.common.white
        }
    }
}));

const ProcessingPageBanner = ({
    header,
    description,
    link,
    linkContent,
    buttonLink,
    buttonContent,
    buttonIcon,
    targetString,
    thinBanner,
    displayDropdown,
    billToDropdown,
    completeDropdown,
    disableSelect,
    filter,
    shouldShowATMBanner
}: Props) => {
    const classes = useStyles();
    const isPlayground = GetFeatureFlag(FlagTypes.Playground);
    const [showATMBanner, setShowATMBanner] = useState<boolean>();
    const location = useLocation();
    const { regionCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { shipToAccounts, selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );

    useEffect(() => {
        if (!!shouldShowATMBanner) {
            setShowATMBanner(shouldShowATMBanner);
        } else {
            setShowATMBanner(
                shouldShowAtmBanner(
                    shipToAccounts || [],
                    selectedAccountId || '',
                    location.pathname,
                    regionCode
                )
            );
        }
    }, [shipToAccounts, selectedAccountId]);

    return (
        <div
            className={clsx(
                isPlayground
                    ? classes.playgroundBanner
                    : showATMBanner
                    ? classes.atmBanner
                    : classes.banner
            )}
        >
            <Grid
                container
                item
                className={thinBanner ? classes.thinContainer : classes.container}
                alignItems="center"
                justify="space-between"
            >
                <Grid item>
                    {link && (
                        <Link
                            component={RouterLink}
                            to={link}
                            className={classes.link}
                            data-testid="header-link"
                            underline="hover"
                        >
                            {linkContent}
                        </Link>
                    )}
                    <Typography
                        variant="h1"
                        color="primary"
                        data-testid={'processing-page-banner-title'}
                        className={displayDropdown ? classes.headerWithBanner : classes.header}
                    >
                        {header}
                    </Typography>
                    {description && (
                        <Typography variant="body1" className={classes.description}>
                            {description.toUpperCase()}
                        </Typography>
                    )}
                    {buttonLink && (
                        <div className={classes.linkContainer}>
                            {buttonIcon && <div className={classes.linkIcon}>{buttonIcon}</div>}
                            <Link
                                url={buttonLink}
                                underline="hover"
                                data-testid="header-button"
                                className={classes.link}
                                target={targetString}
                            >
                                {buttonContent}
                            </Link>
                        </div>
                    )}
                </Grid>
                {displayDropdown && (
                    <Grid item container xs={6} justify={'flex-end'}>
                        <AccountSelection
                            disableSelect={disableSelect ? disableSelect : false}
                            selectionType={
                                completeDropdown ? 'account' : billToDropdown ? 'billTo' : 'shipTo'
                            }
                        />
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default ProcessingPageBanner;
