import { naLanguages, euLanguages, saLanguages } from '../../static-data/dropdown-lists';
import { UserPermission } from '../../store/reducers/auth';
import { Account } from '../../store/reducers/customer-context';
import { Persona } from '../auth/useSecurity';
import { getBillToAccounts } from './graphic-helpers';

export const getLanguageCode = (region: string) => {
    switch (region) {
        case 'NA':
            return naLanguages[0].value;
        case 'EU':
            return euLanguages[0].value;
        case 'SA':
            return saLanguages[0].value;
        default:
            break;
    }
};

export const isIE = () => {
    return !!(window.document as any).documentMode;
};

export const isPersonaAccount = (
    permissions: UserPermission[],
    persona: Persona,
    selectedShipToId: string
) => {
    return permissions
        .find((permission) => permission.personaId === persona)
        ?.accountIds?.includes(selectedShipToId);
};

export const removePersonaAccounts = (permissions: UserPermission[], persona: Persona) => {
    return permissions.find((permission) => permission.personaId !== persona)?.accountIds ?? [];
};

export const getPersonaAccounts = (permissions: UserPermission[], persona: Persona) => {
    return permissions.find((permission) => permission.personaId === persona)?.accountIds ?? [];
};

// gets unique array of user's personas
export const getUserPersonas = (permissions: UserPermission[]) => {
    let personas = [] as Persona[];
    permissions.forEach((p) => {
        personas.push(p.personaId);
    });
    return personas;
};

export const isUserPersona = (permissions: UserPermission[], persona: Persona) => {
    return permissions.find((p) => p.personaId === persona) !== undefined;
};

export function getLockedPeriodDays(
    shipToAccounts: Account[],
    selectedShipToId: string,
    defaultLockedPeriod: number
) {
    const account = shipToAccounts.find((account) => account.accountId === selectedShipToId);
    return account?.lockedPeriodDays ?? defaultLockedPeriod;
}

export function hasBillToAdminAccounts(accounts: Account[], permissions: UserPermission[]) {
    // Filter out Bill To Accounts
    const billToAccounts = getBillToAccounts(accounts);

    // If user has any bill to accounts, then check permissions
    if (billToAccounts && billToAccounts.length > 0) {
        // Check to see if user has any bill to accounts with the ACC_ADM persona
        const hasAdminAccounts = billToAccounts.some((billTo) => {
            return isPersonaAccount(permissions, Persona.AccountAdmin, billTo.accountId);
        });

        return hasAdminAccounts;
    }
    return false;
}

export const isValidEmail = (email: string, removeBall: boolean = false): boolean => {
    if (removeBall)
        return /^[A-Za-z0-9._%+-]+@(?!ball.com)[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(email);
    return /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,}$/.test(email);
};
