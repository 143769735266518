import React from 'react';
import { Collapse, Grid, makeStyles, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import Button from '../atoms/Button';
import {
    ballBlue,
    boldWeight,
    warningLabel,
    ballGray,
    blackWeight
} from '../../../themes/globalConstants';
import { Alert } from '@material-ui/lab';
import FreightFormulaDropdown from '../../pages/EditShipmentsConfiguration/components/FreightFormulaDropdown';

interface Props {
    onExpandAll: () => void;
    onCollapseAll: () => void;
    warning?: boolean;
    warningText?: React.ReactNode;
    isEditShipment?: boolean;
    maxPallets?: number;
    availBalanceWarning?: boolean;
}

const useStyles = makeStyles((theme) => ({
    toolbarWrapper: {
        alignItems: 'center',
        '& .MuiGrid-container': {
            margin: 0
        }
    },
    btn: {
        padding: 0,
        minWidth: 'auto'
    },
    warningContainer: {
        marginTop: '1em',
        width: '100%',
        padding: '0.875em 0',
        borderRadius: '.125em',
        '& .MuiAlert-icon': {
            color: ballBlue,
            display: 'flex',
            alignItems: 'center'
        }
    },
    warning: {
        backgroundColor: warningLabel,
        '& .MuiAlert-message': {
            width: '100%'
        }
    },
    warningContents: {
        display: 'flex',
        alignItems: 'center'
    },
    freightDropdown: {
        order: 99,
        marginLeft: 'auto'
    },
    warningText: {
        fontWeight: boldWeight
    },
    additionalFields: {
        color: ballGray,
        fontWeight: blackWeight,
        textTransform: 'uppercase'
    }
}));

const ShipItConfigToolbar = ({
    onExpandAll,
    onCollapseAll,
    warning,
    warningText,
    isEditShipment,
    maxPallets,
    availBalanceWarning = false
}: Props) => {
    const classes = useStyles();

    const handleExpandAll = () => {
        onExpandAll();
    };

    const handleCollapseAll = () => {
        onCollapseAll();
    };

    useTranslation();

    return (
        <>
            <Grid container justify="center">
                {warning && !isEditShipment && (
                    <Grid
                        container
                        item
                        xs={12}
                        justify="flex-start"
                        alignItems="center"
                        spacing={2}
                    >
                        <Collapse in={warning} className={classes.warningContainer}>
                            <Alert severity="warning" className={classes.warning}>
                                <div className={classes.warningContents}>
                                    {!availBalanceWarning && maxPallets && (
                                        <FreightFormulaDropdown
                                            maxPallets={maxPallets}
                                            className={classes.freightDropdown}
                                        />
                                    )}
                                    <Typography
                                        className={classes.warningText}
                                        data-testid="shipment-warning"
                                    >
                                        {warningText}
                                    </Typography>
                                </div>
                            </Alert>
                        </Collapse>
                    </Grid>
                )}

                {warning && isEditShipment && (
                    <Grid
                        container
                        item
                        xs={12}
                        justify="flex-start"
                        alignItems="center"
                        spacing={2}
                    >
                        <Collapse in={warning} className={classes.warningContainer}>
                            {warningText}
                        </Collapse>
                    </Grid>
                )}
                <Grid container className={classes.toolbarWrapper}>
                    <Grid container item xs={12} justify="flex-end" alignItems="center" spacing={2}>
                        <Grid item>
                            <Typography variant="subtitle2" className={classes.additionalFields}>
                                <Trans i18nKey="additionalFields">Additional Fields</Trans>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                className={classes.btn}
                                color="primary"
                                onClick={handleExpandAll}
                                data-testid="expand-all"
                            >
                                <Trans i18nKey="expandAll">Expand All</Trans>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Typography>|</Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                className={classes.btn}
                                color="primary"
                                onClick={handleCollapseAll}
                                data-testid="collapse-all"
                            >
                                <Trans i18nKey="collapseAll">Collapse All</Trans>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default ShipItConfigToolbar;
