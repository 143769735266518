import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { TradeItProduct, TradeItRequestState } from '../../../../store/reducers/trade-it';
import TradeItProductsColumns from './TradeItProductsColumns';
import TradeItProductsRow from './TradeItProductsRow';
import StandardTable from '../../../reusable/molecules/StandardTable';

interface Props {
    originalShipToId: string;
    receivingShipToId: string;
}

export default function TradeItProductsGrid({ originalShipToId, receivingShipToId }: Props) {
    const [columns, setColumns] = useState<any>([]);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { products, loaded } = useTypedSelector<TradeItRequestState>(
        (state) => state.tradeItRequest
    );
    const [productsAvailableForTrade, setProductsAvailableForTrade] = useState<
        TradeItProduct[] | undefined
    >(undefined);

    useEffect(() => {
        setColumns(
            TradeItProductsColumns.getTradeItProductsColumns(
                cultureCode,
                originalShipToId,
                receivingShipToId
            )
        );
    }, [cultureCode]);

    useEffect(() => {
        if (loaded && products) {
            setProductsAvailableForTrade(products);
        }
    }, [products, loaded]);

    const CustomOrderRow = ({ row }: { row: TradeItProduct }) => {
        return <TradeItProductsRow product={row} />;
    };

    return (
        <Grid container data-testid="products-grid">
            {productsAvailableForTrade && (
                <StandardTable
                    gridName="Trade It Products Grid"
                    columns={columns}
                    lines={productsAvailableForTrade}
                    rowComponent={CustomOrderRow}
                    columnWidths={[16, 12, 15, 15, 16, 13, 13]}
                    itemsPerPage={10}
                />
            )}
        </Grid>
    );
}
