import {
    Button,
    Checkbox,
    CircularProgress,
    Container,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    TextareaAutosize,
    Typography
} from '@material-ui/core';
import React, { useEffect, useState, useRef } from 'react';
import { Controller, ErrorMessage, FormContext, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getUserCustomerAccounts } from '../../../store/actions/customer-context';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { Activity } from '../../../utility/auth/useSecurity';
import {
    getCompanyList,
    SelectInputDropdown,
    formatAccountDropdown
} from '../../../utility/helpers/graphic-helpers';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { Account, CustomerContextState } from '../../../store/reducers/customer-context';
import SelectInput from '../../reusable/molecules/SelectInput';
import TextInput from '../../reusable/molecules/TextInput';
import { containerMaxWidth, medium, red, regularWeight } from '../../../themes/globalConstants';
import FormSection from '../../reusable/molecules/FormSection';
import RadioInput from '../../reusable/molecules/RadioInput';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { regions } from '../../../static-data/dropdown-lists';
import { AuthState } from '../../../store/reducers/auth';
import { LiquidTestIntake } from '../../../store/reducers/liquid-intake';
import LiquidTestService from '../../../utility/services/liquid-test-service';
import { useHistory } from 'react-router-dom';
import LiquidTestIntakeCancelModal from './components/LiquidTestIntakeCancelModal';
import { saveLiquidTestResponse } from '../../../store/actions/liquid-intake';
import {
    LIQUID_INTAKE_SUBMITTING,
    LIQUID_INTAKE_SUBMIT_ERROR
} from '../../../store/actions/action-types';
import ErrorAlert from '../../reusable/atoms/ErrorAlert';
import { selectHasImpersonationBillTo } from '../../../store/selectors';
import ImpersonationWarning from '../../reusable/molecules/ImpersonationWarning';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '3em 3em 1em 3em'
    },
    buttonContainer: {
        paddingBottom: '3em',
        maxWidth: containerMaxWidth
    },
    buttons: {
        justifyContent: 'space-between',
        paddingLeft: '2.5em'
    },
    actionBtn: {
        height: '3.375em',
        width: '13.75em',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom'
    },
    label: {
        color: theme.palette.info.dark,
        fontSize: medium,
        marginBottom: '0.5em'
    },
    radioGroup: {
        marginTop: '1.5em'
    },
    helperText: {
        '& .MuiFormHelperText-root': {
            marginLeft: 0
        }
    },
    errorText: {
        color: red,
        paddingBottom: 0
    },
    textAreaWrapper: {
        display: 'block',
        '& .MuiFormHelperText-root.Mui-error': {
            margin: 0,
            padding: '0 14px'
        }
    },
    textArea: {
        width: '91%',
        padding: '18px 14px',
        fontSize: 14,
        border: '1px solid',
        borderColor: theme.palette.secondary.light,
        fontFamily: "'Lato', 'Helvetica', 'Arial', sans-serif",
        '&:focus': {
            outline: 'none',
            border: '1px solid',
            borderColor: theme.palette.primary.main
        }
    },
    checkboxLabel: {
        fontWeight: regularWeight,
        fontSize: medium
    },
    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '0.5em'
    },
    error: {
        color: theme.palette.error.main,
        marginTop: '0.625em'
    },
    apiError: {
        marginBottom: '1em'
    }
}));

export default function LiquidTestIntakePage() {
    const classes = useStyles();
    const { t } = useTranslation();
    const graphicIdCache = useRef<string>('');
    const beverageDescriptionCache = useRef<string>('');

    const methods = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur'
    });

    const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
    const isRequired = <Trans i18nKey="required">Required</Trans>;
    const beverageNameOrProjectNameRequired = (
        <Trans i18nKey="beverageNameOrProjectNameRequired">
            Beverage Name or Project Name Required
        </Trans>
    );
    const [billTo, setBillTo] = useState<SelectInputDropdown[]>([]);
    const { accounts } = useTypedSelector<CustomerContextState>((state) => state.customerContext);
    const [companyDropdown, setCompanyDropdown] = useState<Account[]>([]);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [submitClicked, setSubmitClicked] = useState<boolean>(false);
    const { regionCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { accessToken, userInfo, permissions } = useTypedSelector<AuthState>(
        (state) => state.auth
    );
    const dispatch = useDispatch();
    const history = useHistory();

    const yesNoControls = [
        { value: 'Yes', label: <Trans i18nKey="yes">Yes</Trans> },
        { value: 'No', label: <Trans i18nKey="no">No</Trans> }
    ];

    const newProductOrUpdateControls = [
        { value: 'New', label: <Trans i18nKey="newProduct">New Product</Trans> },
        { value: 'Existing', label: <Trans i18nKey="updatedRecipe">Updated Recipe</Trans> }
    ];

    const hasGraphicId = methods.watch('graphicIdFlag') === 'Yes';
    const isConcentrateSample = methods.watch('concentrateSample') === 'Yes';
    const [doNotKnowPH, setDoNotKnowPH] = useState<boolean>(false);
    const fourIngredientBeerReq =
        methods.watch('beverageCategory') === 'Beer' ||
        methods.watch('beverageCategory') === 'BeerCraft' ||
        methods.watch('beverageCategory') === 'DomesticBeer' ||
        methods.watch('beverageCategory') === 'EuropenBeer' ||
        methods.watch('beverageCategory') === 'CarribeanBeer' ||
        methods.watch('beverageCategory') === 'MexicanBeer';

    const [addedSulfites, setAddedSulfites] = useState<boolean>(false);
    const [aceticAcid, setAaceticAcid] = useState<boolean>(false);
    const [lacticAcid, setLacticAcid] = useState<boolean>(false);
    const [malicAcid, setMalicAcid] = useState<boolean>(false);
    const [calciumLactate, setCalciumLactate] = useState<boolean>(false);
    const [foodDyes, setFoodDyes] = useState<boolean>(false);
    const [beverageContentList, setBeverageContentList] = useState<string[]>([]);
    const [name, setName] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [phoneNumber, setPhoneNumber] = useState<string>();
    const [error, setError] = useState<boolean>(false);
    const { isValid } = methods.formState;
    const hasImpersonationBillTo = useTypedSelector<boolean>(selectHasImpersonationBillTo);

    const beverageName = methods.watch('beverageName');
    const projectName = methods.watch('projectName');

    useEffect(() => {
        if (beverageName || projectName) {
            methods.clearError('beverageName');
            methods.clearError('projectName');
        }
    }, [beverageName, projectName]);

    const validateBeverageNameProjectName = () => {
        if (methods.getValues('beverageName') || methods.getValues('projectName')) {
            return true;
        } else {
            return beverageNameOrProjectNameRequired;
        }
    };

    const handleDoNoKnowPH = () => {
        setDoNotKnowPH(!doNotKnowPH);
    };

    const handleAddedSulfites = () => {
        if (addedSulfites) {
            let itemIndex = beverageContentList.indexOf('AddedSulfites');
            let listToUpdate = [...beverageContentList];
            listToUpdate.splice(itemIndex, 1);
            setBeverageContentList(listToUpdate);
        } else {
            setBeverageContentList([...beverageContentList, 'AddedSulfites']);
        }
        setAddedSulfites(!addedSulfites);
    };

    const handleAceticAcid = () => {
        if (aceticAcid) {
            let itemIndex = beverageContentList.indexOf('AceticAcid');
            let listToUpdate = [...beverageContentList];
            listToUpdate.splice(itemIndex, 1);
            setBeverageContentList(listToUpdate);
        } else {
            setBeverageContentList([...beverageContentList, 'AceticAcid']);
        }
        setAaceticAcid(!aceticAcid);
    };

    const handleLacticAcid = () => {
        if (lacticAcid) {
            let itemIndex = beverageContentList.indexOf('LacticAcid');
            let listToUpdate = [...beverageContentList];
            listToUpdate.splice(itemIndex, 1);
            setBeverageContentList(listToUpdate);
        } else {
            setBeverageContentList([...beverageContentList, 'LacticAcid']);
        }
        setLacticAcid(!lacticAcid);
    };

    const handleMalicAcid = () => {
        if (malicAcid) {
            let itemIndex = beverageContentList.indexOf('MalicAcid');
            let listToUpdate = [...beverageContentList];
            listToUpdate.splice(itemIndex, 1);
            setBeverageContentList(listToUpdate);
        } else {
            setBeverageContentList([...beverageContentList, 'MalicAcid']);
        }
        setMalicAcid(!malicAcid);
    };

    const handleCalciumLactate = () => {
        if (calciumLactate) {
            let itemIndex = beverageContentList.indexOf('CalciumLactate');
            let listToUpdate = [...beverageContentList];
            listToUpdate.splice(itemIndex, 1);
            setBeverageContentList(listToUpdate);
        } else {
            setBeverageContentList([...beverageContentList, 'CalciumLactate']);
        }
        setCalciumLactate(!calciumLactate);
    };

    const handleFoodDyes = () => {
        if (foodDyes) {
            let itemIndex = beverageContentList.indexOf('FoodDyes');
            let listToUpdate = [...beverageContentList];
            listToUpdate.splice(itemIndex, 1);
            setBeverageContentList(listToUpdate);
        } else {
            setBeverageContentList([...beverageContentList, 'FoodDyes']);
        }
        setFoodDyes(!foodDyes);
    };

    const THROW_RATIO_MAX_LENGTH = 50;
    const BEVERAGE_NAME_MAX_LENGTH = 255;
    const PROJECT_NAME_MAX_LENGTH = 255;
    const GRAPHIC_ID_MAX_LENGTH = 255;
    const BEVERAGE_DESC_MAX_LENGTH = 500;
    const ABV_MAX_LENGTH = 6;
    const PH_MAX_LENGTH = 5;

    const onSubmit = (data) => {
        //want to skip unnecessary logic, we are not hitting API
        if (hasImpersonationBillTo) {
            setError(false);
            methods.clearError();
            return;
        }
        dispatch({ type: LIQUID_INTAKE_SUBMITTING });
        setError(false);
        methods.clearError();
        setIsSubmitting(true);

        let billToName = billTo.find((company) => company.value === data.billTo);
        data.companyName = billToName?.name;
        data.billTo = parseInt(data.billTo);
        data.submitterEmail = email;
        data.submitterName = name;
        data.submitterPhoneNumber = phoneNumber ? phoneNumber : null;

        data.beverageContentList = beverageContentList;

        if (doNotKnowPH) {
            data.pH = null;
        } else {
            data.pH = parseFloat(data.potentialOfHydrogen);
        }

        data.abvPercent = parseFloat(data.abvPercent);

        data.concentrateSample = data.concentrateSample === 'Yes' ? true : false;
        data.refrigerationRequired = data.refrigerationRequired === 'Yes' ? true : false;

        data.newOrExisting = data.newOrExisting === '' ? null : data.newOrExisting;

        if (fourIngredientBeerReq) {
            data.isSimpleBeer = data.isSimpleBeer === 'Yes' ? true : false;
        } else {
            data.isSimpleBeer = false;
        }

        LiquidTestService.submitLiquidTestForm(data as LiquidTestIntake, accessToken)
            .then((response) => {
                dispatch(saveLiquidTestResponse(response.data as LiquidTestIntake));
                setIsSubmitting(false);
                history.push('/liquid-test-confirmation');
            })
            .catch((error) => {
                setIsSubmitting(false);
                setError(true);
                dispatch({
                    type: LIQUID_INTAKE_SUBMIT_ERROR,
                    error
                });
            });
    };

    useEffect(() => {
        if (permissions) {
            if (accounts && accounts.length > 0) {
                setCompanyDropdown(getCompanyList(accounts));
            } else {
                dispatch(getUserCustomerAccounts());
            }
        }
    }, [dispatch, permissions, accounts]);

    useEffect(() => {
        if (companyDropdown?.length > 0) {
            const billTos = getCompanyList(companyDropdown);

            setBillTo(formatAccountDropdown(billTos));
        }
    }, [companyDropdown]);

    useEffect(() => {
        if (userInfo) {
            setName(userInfo.name);
            setEmail(userInfo.email);
            setPhoneNumber(userInfo.PhoneNumber);
        }
    }, [userInfo]);

    useEffect(() => {
        /* We must unregister pH field once the checkbox is clicked, so we can register it with the new
         * is required rule. Clear any errors potentially associated for re-validation.
         */
        methods.unregister('potentialOfHydrogen');
        methods.clearError('potentialOfHydrogen');
    }, [doNotKnowPH]);

    const onCancel = () => {
        setOpenCancelModal(true);
    };

    const handleCancel = () => {
        history.push('/dashboard');
    };

    const handleCloseCancelModal = () => {
        setOpenCancelModal(false);
    };

    const handleSubmitCapture = () => {
        setSubmitClicked(true);
    };

    // Prevent input if number's integer value exceeds max length or its decimal value exceeds max length
    const handleDecimalInputChange = (
        event,
        maxIntegerLength: number,
        maxDecimalLength: number
    ) => {
        const value = event[0]?.target?.value || '';

        if (value.includes('.')) {
            const integer = value.split('.')[0] || '';
            const decimal = value.split('.')[1] || '';
            return `${integer.slice(0, maxIntegerLength)}.${decimal.slice(0, maxDecimalLength)}`;
        } else {
            return value.slice(0, maxIntegerLength);
        }
    };

    // Cache input value in ref. If input value is too long, return cache instead.
    const preventExceedingMaxLength = (
        event,
        ref: React.MutableRefObject<string>,
        maxLength: number
    ) => {
        const value = event[0]?.target?.value || '';

        if (value.length > maxLength) {
            return ref.current;
        } else {
            ref.current = value;
            return value;
        }
    };

    return (
        <>
            <ProcessingPageTemplate
                banner={{
                    header: t('liquidTesting', 'Liquid Testing'),
                    description: '',
                    thinBanner: true
                }}
                activity={Activity.LiquidTestIntakeForm}
                shipTos={false}
            >
                <FormContext {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
                        <Grid container data-testid="liquid-test-intake-page">
                            <Container className={classes.container}>
                                <FormSection
                                    testId={'liquid-test-intake-section'}
                                    sectionHeader={
                                        <Trans i18nKey="beverageInformation">
                                            Beverage Information
                                        </Trans>
                                    }
                                >
                                    <Grid container item xs={12} spacing={2}>
                                        <Grid container item xs={12} spacing={2}>
                                            <Grid item xs={6} data-testid="bill-to">
                                                <SelectInput
                                                    label={
                                                        <Trans i18nKey="companyName">
                                                            Company Name
                                                        </Trans>
                                                    }
                                                    name="billTo"
                                                    testId="billToAddress"
                                                    control={methods.control}
                                                    rules={{ required: isRequired }}
                                                    error={methods.errors.billTo}
                                                    errors={methods.errors}
                                                    selectObj={billTo}
                                                    defaultValue={''}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography className={classes.label} variant="body1">
                                                <Trans i18nKey="beverageNameOrProjectName">
                                                    Please provide at least one of the following:
                                                </Trans>
                                            </Typography>
                                        </Grid>
                                        <Grid container item xs={12} spacing={2}>
                                            <Grid item xs={6}>
                                                <TextInput
                                                    type="text"
                                                    label={
                                                        <Trans i18nKey="beverageName">
                                                            Beverage Name
                                                        </Trans>
                                                    }
                                                    name="beverageName"
                                                    testId="beverage-name"
                                                    control={methods.control}
                                                    rules={{
                                                        validate: () =>
                                                            validateBeverageNameProjectName()
                                                    }}
                                                    error={methods.errors.beverageName}
                                                    errors={methods.errors}
                                                    styles={classes.helperText}
                                                    inputProps={{
                                                        'data-testid': 'beverage-name',
                                                        maxLength: BEVERAGE_NAME_MAX_LENGTH
                                                    }}
                                                />

                                                <FormHelperText
                                                    className={classes.errorText}
                                                    data-testid={
                                                        methods.errors.beverageName
                                                            ? 'beverage-name-error'
                                                            : ''
                                                    }
                                                >
                                                    <ErrorMessage
                                                        errors={methods.errors}
                                                        name="beverageName"
                                                    />
                                                </FormHelperText>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextInput
                                                    type="text"
                                                    label={
                                                        <Trans i18nKey="projectName">
                                                            Project Name
                                                        </Trans>
                                                    }
                                                    name="projectName"
                                                    testId="project-name"
                                                    control={methods.control}
                                                    rules={{
                                                        validate: () =>
                                                            validateBeverageNameProjectName()
                                                    }}
                                                    error={methods.errors.projectName}
                                                    errors={methods.errors}
                                                    styles={classes.helperText}
                                                    inputProps={{
                                                        'data-testid': 'project-name',
                                                        maxLength: PROJECT_NAME_MAX_LENGTH
                                                    }}
                                                />

                                                <FormHelperText
                                                    className={classes.errorText}
                                                    data-testid={
                                                        methods.errors.projectName
                                                            ? 'project-name-error'
                                                            : ''
                                                    }
                                                >
                                                    <ErrorMessage
                                                        errors={methods.errors}
                                                        name="projectName"
                                                    />
                                                </FormHelperText>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} spacing={2}>
                                        <Grid item xs={6}>
                                            <FormControl
                                                fullWidth
                                                error={methods.errors.graphicIdFlag}
                                            >
                                                <RadioInput
                                                    styles={classes.radioGroup}
                                                    label={
                                                        <Trans i18nKey="graphicIdAssociated">
                                                            Do you have a graphic ID associated with
                                                            this beverage?
                                                        </Trans>
                                                    }
                                                    name="graphicIdFlag"
                                                    testId="graphicIdFlag"
                                                    control={methods.control}
                                                    rules={{ required: isRequired }}
                                                    radioControls={yesNoControls}
                                                    error={methods.errors.graphicIdFlag}
                                                    errors={methods.errors}
                                                    defaultValue=""
                                                />
                                            </FormControl>
                                        </Grid>
                                        {hasGraphicId && (
                                            <Grid item xs={6}>
                                                <TextInput
                                                    type="number"
                                                    label={
                                                        <Trans i18nKey="graphicId">
                                                            Graphic ID
                                                        </Trans>
                                                    }
                                                    name="graphicID"
                                                    testId="graphicId"
                                                    control={methods.control}
                                                    rules={{
                                                        required: isRequired,
                                                        min: 0,
                                                        maxLength: GRAPHIC_ID_MAX_LENGTH
                                                    }}
                                                    error={methods.errors.graphicId}
                                                    errors={methods.errors}
                                                    styles={classes.helperText}
                                                    onChange={(event) =>
                                                        preventExceedingMaxLength(
                                                            event,
                                                            graphicIdCache,
                                                            GRAPHIC_ID_MAX_LENGTH
                                                        )
                                                    }
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                    <Grid container item xs={12} spacing={2}>
                                        <Grid item xs={6}>
                                            <FormControl
                                                fullWidth
                                                error={methods.errors.beverageCategory}
                                                data-testid="beverage-category"
                                            >
                                                <InputLabel>
                                                    <Trans i18nKey="beverageCategory">
                                                        Beverage Category
                                                    </Trans>
                                                </InputLabel>
                                                <Controller
                                                    as={
                                                        <Select
                                                            inputProps={{
                                                                'data-testid': 'beverageCategory'
                                                            }}
                                                        >
                                                            <MenuItem>&nbsp;</MenuItem>
                                                            {regions
                                                                .find((r) => r.value === regionCode)
                                                                ?.beverageCategories.map(
                                                                    (category) => (
                                                                        <MenuItem
                                                                            key={category.value}
                                                                            value={category.key}
                                                                        >
                                                                            <Trans
                                                                                i18nKey={
                                                                                    category.i18nKey
                                                                                }
                                                                            >
                                                                                category.value
                                                                            </Trans>
                                                                        </MenuItem>
                                                                    )
                                                                )}
                                                        </Select>
                                                    }
                                                    name="beverageCategory"
                                                    control={methods.control}
                                                    rules={{ required: isRequired }}
                                                />
                                                <FormHelperText
                                                    data-testid={
                                                        methods.errors.beverageCategory
                                                            ? 'beverage-cat-error'
                                                            : ''
                                                    }
                                                >
                                                    <ErrorMessage
                                                        errors={methods.errors}
                                                        name="beverageCategory"
                                                    />
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextInput
                                                type="text"
                                                label={
                                                    <Trans i18nKey="alcoholByVolumePercentage">
                                                        ABV%
                                                    </Trans>
                                                }
                                                name="abvPercent"
                                                testId="abvPercent"
                                                control={methods.control}
                                                rules={{
                                                    required: isRequired,
                                                    pattern:
                                                        /^(?:100(?:\.0{1,2}?)?|\d?\d(?:\.\d{1,2}?)?)$/
                                                }}
                                                inputProps={{
                                                    'data-testid': 'abvPercent',
                                                    maxLength: ABV_MAX_LENGTH
                                                }}
                                                onChange={(event) =>
                                                    handleDecimalInputChange(event, 3, 2)
                                                }
                                                error={methods.errors.abvPercent}
                                                errors={methods.errors}
                                                styles={classes.helperText}
                                            />
                                            <FormHelperText className={classes.errorText}>
                                                {methods.errors.abvPercent &&
                                                    methods.errors.abvPercent.type ===
                                                        'required' && (
                                                        <ErrorMessage
                                                            errors={methods.errors}
                                                            name={'abvPercent'}
                                                            message={isRequired}
                                                        />
                                                    )}
                                                {methods.errors.abvPercent &&
                                                    methods.errors.abvPercent.type ===
                                                        'pattern' && (
                                                        <ErrorMessage
                                                            errors={methods.errors}
                                                            name={'abvPercent'}
                                                            message={t(
                                                                'alcoholByVolumeHelper',
                                                                'ABV must be a number between 0 and 100'
                                                            )}
                                                        />
                                                    )}
                                            </FormHelperText>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextInput
                                                type="text"
                                                label={
                                                    <Trans i18nKey="potentialOfHydrogen">pH</Trans>
                                                }
                                                name="potentialOfHydrogen"
                                                testId="potentialOfHydrogen"
                                                control={methods.control}
                                                rules={{
                                                    required: !doNotKnowPH ? isRequired : false,
                                                    pattern:
                                                        /^(?:14(?:\.0{1,2}?)?|1[0-3](?:\.[0-9]{1,2}?)?|[0-9](?:\.[0-9]{1,2}?)?)$/
                                                }}
                                                inputProps={{
                                                    'data-testid': 'potentialOfHydrogen',
                                                    maxLength: PH_MAX_LENGTH
                                                }}
                                                onChange={(event) =>
                                                    handleDecimalInputChange(event, 2, 2)
                                                }
                                                error={
                                                    !doNotKnowPH
                                                        ? methods.errors.potentialOfHydrogen
                                                        : undefined
                                                }
                                                errors={methods.errors}
                                                readOnly={doNotKnowPH}
                                            />

                                            <FormHelperText className={classes.errorText}>
                                                {!doNotKnowPH &&
                                                    methods.errors.potentialOfHydrogen &&
                                                    methods.errors.potentialOfHydrogen.type ===
                                                        'required' && (
                                                        <ErrorMessage
                                                            errors={methods.errors}
                                                            name={'potentialOfHydrogen'}
                                                            message={isRequired}
                                                        />
                                                    )}
                                                {!doNotKnowPH &&
                                                    methods.errors.potentialOfHydrogen &&
                                                    methods.errors.potentialOfHydrogen.type ===
                                                        'pattern' && (
                                                        <ErrorMessage
                                                            errors={methods.errors}
                                                            name={'potentialOfHydrogen'}
                                                            message={t(
                                                                'potentialOfHydrogenHelper',
                                                                'pH must be a number between 0 and 14'
                                                            )}
                                                        />
                                                    )}
                                            </FormHelperText>

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={doNotKnowPH}
                                                        onChange={handleDoNoKnowPH}
                                                        name="doNotKnowPH"
                                                        color="primary"
                                                    />
                                                }
                                                label={
                                                    <Typography className={classes.checkboxLabel}>
                                                        <Trans i18nKey="doNotKnowPH">
                                                            I don't know my pH
                                                        </Trans>
                                                    </Typography>
                                                }
                                            />
                                        </Grid>
                                        {fourIngredientBeerReq && (
                                            <Grid container item xs={12} spacing={2}>
                                                <Grid item xs={6}>
                                                    <FormControl
                                                        fullWidth
                                                        error={methods.errors.fourIngredientBeer}
                                                    >
                                                        <RadioInput
                                                            styles={classes.radioGroup}
                                                            label={
                                                                <Trans i18nKey="fourIngredientBeer">
                                                                    Is this a simple four ingredient
                                                                    beer?
                                                                </Trans>
                                                            }
                                                            name="isSimpleBeer"
                                                            testId="fourIngredientBeer"
                                                            control={methods.control}
                                                            rules={{ required: isRequired }}
                                                            radioControls={yesNoControls}
                                                            error={methods.errors.isSimpleBeer}
                                                            errors={methods.errors}
                                                            defaultValue=""
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        )}
                                        <Grid container item xs={12} spacing={2}>
                                            <Grid item xs={6}>
                                                <FormControl
                                                    className={classes.textAreaWrapper}
                                                    error={methods.errors.beverageDescription}
                                                >
                                                    <Controller
                                                        as={
                                                            <TextareaAutosize
                                                                id="beverageDescription"
                                                                data-testid="beverage-description"
                                                                aria-label="beverageDescription"
                                                                rowsMin={4}
                                                                placeholder={t(
                                                                    'beverageDescription',
                                                                    'Beverage Description...'
                                                                )}
                                                                className={classes.textArea}
                                                            />
                                                        }
                                                        name="beverageDescription"
                                                        control={methods.control}
                                                        rules={{
                                                            min: 0,
                                                            maxLength: BEVERAGE_DESC_MAX_LENGTH
                                                        }}
                                                        onChange={(event) =>
                                                            preventExceedingMaxLength(
                                                                event,
                                                                beverageDescriptionCache,
                                                                BEVERAGE_DESC_MAX_LENGTH
                                                            )
                                                        }
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Grid container item xs={12} spacing={2}>
                                            <Grid item xs={6}>
                                                <FormControl
                                                    fullWidth
                                                    error={methods.errors.newProductOrUpdate}
                                                >
                                                    <RadioInput
                                                        styles={classes.radioGroup}
                                                        label={
                                                            <Trans i18nKey="newProductOrUpdate">
                                                                Is this a new product or an updated
                                                                recipe of an existing product?
                                                            </Trans>
                                                        }
                                                        name="newOrExisting"
                                                        testId="newProductOrUpdate"
                                                        control={methods.control}
                                                        radioControls={newProductOrUpdateControls}
                                                        defaultValue=""
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl
                                                    fullWidth
                                                    error={methods.errors.refrigerationRequired}
                                                >
                                                    <RadioInput
                                                        styles={classes.radioGroup}
                                                        label={
                                                            <Trans i18nKey="isRefrigerationRequired">
                                                                Is refrigeration required?
                                                            </Trans>
                                                        }
                                                        name="refrigerationRequired"
                                                        testId="refrigerationRequired"
                                                        control={methods.control}
                                                        radioControls={yesNoControls}
                                                        defaultValue=""
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={12} spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography
                                                    className={classes.label}
                                                    variant="body1"
                                                >
                                                    <Trans i18nKey="doesBeverageContain">
                                                        Does your beverage contain:
                                                    </Trans>
                                                </Typography>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={addedSulfites}
                                                            onChange={handleAddedSulfites}
                                                            name="addedSulfites"
                                                            value="AddedSulfites"
                                                            color="primary"
                                                            data-testid="addedSulfites"
                                                        />
                                                    }
                                                    label={
                                                        <Typography
                                                            className={classes.checkboxLabel}
                                                        >
                                                            <Trans i18nKey="addedSulfites">
                                                                Added Sulfites
                                                            </Trans>
                                                        </Typography>
                                                    }
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={aceticAcid}
                                                            onChange={handleAceticAcid}
                                                            name="aceticAcid"
                                                            value="AceticAcid"
                                                            color="primary"
                                                            data-testid="aceticAcid"
                                                        />
                                                    }
                                                    label={
                                                        <Typography
                                                            className={classes.checkboxLabel}
                                                        >
                                                            <Trans i18nKey="aceticAcid">
                                                                Acetic Acid
                                                            </Trans>
                                                        </Typography>
                                                    }
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={lacticAcid}
                                                            onChange={handleLacticAcid}
                                                            name="lacticAcid"
                                                            value="LacticAcid"
                                                            color="primary"
                                                            data-testid="lacticAcid"
                                                        />
                                                    }
                                                    label={
                                                        <Typography
                                                            className={classes.checkboxLabel}
                                                        >
                                                            <Trans i18nKey="lacticAcid">
                                                                Lactic Acid
                                                            </Trans>
                                                        </Typography>
                                                    }
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={malicAcid}
                                                            onChange={handleMalicAcid}
                                                            name="malicAcid"
                                                            value="MalicAcid"
                                                            color="primary"
                                                            data-testid="malicAcid"
                                                        />
                                                    }
                                                    label={
                                                        <Typography
                                                            className={classes.checkboxLabel}
                                                        >
                                                            <Trans i18nKey="malicAcid">
                                                                Malic Acid
                                                            </Trans>
                                                        </Typography>
                                                    }
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={calciumLactate}
                                                            onChange={handleCalciumLactate}
                                                            name="calciumLactate"
                                                            value="CalciumLactate"
                                                            color="primary"
                                                            data-testid="calciumLactate"
                                                        />
                                                    }
                                                    label={
                                                        <Typography
                                                            className={classes.checkboxLabel}
                                                        >
                                                            <Trans i18nKey="calciumLactate">
                                                                Calcium Lactate
                                                            </Trans>
                                                        </Typography>
                                                    }
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={foodDyes}
                                                            onChange={handleFoodDyes}
                                                            name="foodDyes"
                                                            value="FoodDyes"
                                                            color="primary"
                                                            data-testid="foodDyes"
                                                        />
                                                    }
                                                    label={
                                                        <Typography
                                                            className={classes.checkboxLabel}
                                                        >
                                                            <Trans i18nKey="foodDyes">
                                                                &#62;80 ppm Food Dyes
                                                            </Trans>
                                                        </Typography>
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={12} spacing={2}>
                                            <Grid item xs={6}>
                                                <Grid item xs={6}>
                                                    <FormControl
                                                        fullWidth
                                                        error={methods.errors.concentrateSample}
                                                    >
                                                        <RadioInput
                                                            styles={classes.radioGroup}
                                                            label={
                                                                <Trans i18nKey="isConcentrateSample">
                                                                    Is this a syrup sample?
                                                                </Trans>
                                                            }
                                                            name="concentrateSample"
                                                            testId="concentrateSample"
                                                            control={methods.control}
                                                            radioControls={yesNoControls}
                                                            defaultValue=""
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {isConcentrateSample && (
                                            <Grid container item xs={12} spacing={2}>
                                                <Grid item xs={6}>
                                                    <TextInput
                                                        type="text"
                                                        label={
                                                            <Trans i18nKey="throwRatio">
                                                                Throw Ratio
                                                            </Trans>
                                                        }
                                                        name="throwRatio"
                                                        testId="throw-ratio"
                                                        control={methods.control}
                                                        rules={{
                                                            min: 0,
                                                            maxLength: THROW_RATIO_MAX_LENGTH
                                                        }}
                                                        error={methods.errors.throwRatio}
                                                        errors={methods.errors}
                                                        styles={classes.helperText}
                                                        inputProps={{
                                                            'data-testid': 'throw-ratio',
                                                            maxLength: THROW_RATIO_MAX_LENGTH
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                </FormSection>
                            </Container>
                            <Container>
                                {isSubmitting && (
                                    <Grid container item xs={12} className={classes.spinningLoader}>
                                        <CircularProgress />
                                    </Grid>
                                )}
                                <Grid container item xs={12} alignItems="center">
                                    {!isValid && submitClicked && (
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            alignItems="center"
                                            justify="center"
                                        >
                                            <Typography
                                                className={classes.error}
                                                data-testid="required-fields-error"
                                            >
                                                <Trans i18nKey="fillOutRequiredFields">
                                                    All required fields must be completed prior to
                                                    submitting.
                                                </Trans>
                                            </Typography>
                                        </Grid>
                                    )}
                                    {submitClicked && hasImpersonationBillTo && isValid && (
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            alignItems="center"
                                            justify="center"
                                        >
                                            <Grid container item xs={8} alignItems="center">
                                                <ImpersonationWarning
                                                    showWarning={true}
                                                    warningType={'SUBMIT'}
                                                />
                                            </Grid>
                                        </Grid>
                                    )}

                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        alignItems="center"
                                        justify="center"
                                        className={classes.apiError}
                                    >
                                        <ErrorAlert
                                            showError={error}
                                            errorMessage={t(
                                                'formSubmissionError',
                                                'The system was unable to submit the form. Please try again later.'
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Container>
                            <Container className={classes.buttonContainer}>
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    alignItems="center"
                                    className={classes.buttons}
                                >
                                    <Grid item xs={2}>
                                        <Button
                                            type="button"
                                            variant="outlined"
                                            color="secondary"
                                            data-testid="cancel-button"
                                            onClick={onCancel}
                                            className={classes.actionBtn}
                                        >
                                            <Trans i18nKey="cancel">Cancel</Trans>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            data-testid="submit-button"
                                            className={classes.actionBtn}
                                            disabled={isSubmitting}
                                            onClickCapture={handleSubmitCapture}
                                        >
                                            <Trans i18nKey="submit">Submit</Trans>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Grid>
                    </form>
                </FormContext>
            </ProcessingPageTemplate>
            <LiquidTestIntakeCancelModal
                data-testid="cancel-modal"
                open={openCancelModal}
                onClose={handleCloseCancelModal}
                saveProgress={handleCancel}
                onCancel={handleCloseCancelModal}
            />
        </>
    );
}
