import React from 'react';
import { Grid, Typography, makeStyles, Box } from '@material-ui/core';
import { ContentSubSection } from '../../../../store/reducers/content-page';
import ContentLink from './ContentLink';
import sanitizeHtml from 'sanitize-html-react';

//This section creates columns of content and align the columns in a row (horizontally)

interface Props {
    section: ContentSubSection;
    className?: any;
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'inline-block',
        marginBottom: '4em'
    },
    itemHeader: {
        fontSize: 16,
        margin: '0.5em 0 0.5em 0'
    },
    itemText: {
        justifyContent: 'center',
        textAlign: 'center'
    },
    imgContainer: {
        maxHeight: '25em',
        minHeight: '15em',
        maxWidth: '25em',
        margin: 'auto'
    },
    img: {
        maxHeight: '100%',
        maxWidth: '100%',
        objectFit: 'contain',
        margin: 'auto',
        minWidth: '5em'
    },
    description: {
        marginBottom: '1em',
        '& > ul': {
            paddingLeft: '1.25em'
        }
    }
}));

const HorizontalTemplateBlock = (props: Props) => {
    const section = props.section;
    const links = props.section.links;
    const classes = useStyles();

    return (
        <Grid container item xs direction="row" justify="center">
            <Grid container item xs={12} justify="center" className={classes.root}>
                {section.image && (
                    <div className={classes.imgContainer}>
                        <Grid
                            container
                            item
                            xs={12}
                            className={classes.imgContainer}
                            justify="center"
                        >
                            <img
                                className={classes.img}
                                src={section.image.imageUrl}
                                alt={
                                    section.image.alternateText
                                        ? section.image.alternateText
                                        : section.header + ' image'
                                }
                            />
                        </Grid>
                    </div>
                )}
                <Grid container item xs={12} className={section.image ? classes.itemText : ''}>
                    <Box>
                        <Typography variant="h5" component="h1" className={classes.itemHeader}>
                            {section.header}
                        </Typography>
                        <Typography
                            variant="body1"
                            component="p"
                            className={classes.description}
                            dangerouslySetInnerHTML={{ __html: sanitizeHtml(section.description) }}
                        ></Typography>
                        <ContentLink link={links ? links[0] : null} />
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default HorizontalTemplateBlock;
