import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { AuthState } from '../../../store/reducers/auth';
import { ProductPortfolioState } from '../../../store/reducers/product-portfolio';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import clsx from 'clsx';

import KBMaxTemplate, { KBMaxConfig } from '../../templates/KBMaxTemplate';
import TranslationService from '../../../utility/services/translation-service';
import { getKBMaxRegion } from '../../../utility/helpers/kbmax-helpers';
import { addKbMaxListener } from '../../../utility/helpers/event-listener-helpers';
import { useDispatch } from 'react-redux';
import { Region } from '../../../store/reducers/graphic-intake';
declare global {
    interface Window {
        kbmax: any;
    }
}
const useStyles = makeStyles((theme) => ({
    iframeWrapper: {
        height: 800
    },
    hidden: {
        display: 'hidden'
    },
    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '0.5em'
    }
}));

interface Props {
    kbMaxSettings?: KBMaxConfig;
}

export default function KBMaxProductComparison({ kbMaxSettings }: Props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { userInfo } = useTypedSelector<AuthState>((state) => state.auth);
    const { results } = useTypedSelector<ProductPortfolioState>((state) => state.productPortfolio);
    const { regionCode, cultureCode } = useTypedSelector<RegionCultureState>(
        (state) => state.regionCulture
    );
    const [kbMaxConfig, setKBMaxConfig] = useState<any>(undefined);
    const [hasKbMaxListener, setHasKbMaxListener] = useState<boolean>(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (kbMaxConfig && results && userInfo && regionCode && cultureCode) {
            let configurationFields = {};
            const prefix = 'Field - (From Source)';
            const startingPoint = 'Compare';
            configurationFields[`${prefix} Locale`] = cultureCode;
            configurationFields[`${prefix} Region`] = getKBMaxRegion(regionCode);
            configurationFields[`${prefix} Starting Point`] = startingPoint;
            configurationFields[`${prefix} Name`] = userInfo.name;
            configurationFields[`${prefix} email`] = userInfo.email;
            configurationFields[`${prefix} Set Filtered Selections`] = true;
            configurationFields[`${prefix} Set UI Language`] = true;
            configurationFields[`${prefix} Show Get Estimate`] = false;

            let productNumber = 1;
            results.forEach((result) => {
                if (result.selected) {
                    configurationFields[`${prefix} Product Code ${productNumber++}`] =
                        result.productCode;
                }
            });
            kbMaxConfig.setFields(configurationFields);

            if (!hasKbMaxListener) {
                addKbMaxListener(kbMaxConfig, dispatch);
                setHasKbMaxListener(true);
            }

            setTimeout(() => {
                setIsLoaded(true);
            }, 5000);
        }
    }, [kbMaxConfig, results, userInfo, regionCode, cultureCode]);

    const [settings, setSettings] = useState<KBMaxConfig>({
        elementId: 'thesource-kbmax-product-comparison',
        configurator: regionCode === Region.NA ? '37' : '1',
        showFields: true,
        showConfigHeader: true,
        showHeader: false,
        showMove: false,
        bindToForm: '',
        showDrawer: false,
        style: { height: '800px' }
    });

    const [header] = useState<string>(TranslationService.getTranslatedText(cultureCode, 'productComparison'));

    useEffect(() => {
        let config: any;
        if (window.kbmax) {
            config = new window.kbmax.ConfiguratorEmbed({
                elementId: settings.elementId,
                configuratorId: settings.configurator,
                showFields: settings.showFields,
                showHeader: settings.showHeader,
                showConfigHeader: settings.showConfigHeader,
                showMove: settings.showMove,
                showDrawer: settings.showDrawer,
                bindToFormSelector: settings.bindToForm,
                loadStyle: settings.style
            });
            setKBMaxConfig(config);
        }

        return () => {
            // clear config and removing listener on page leave
            config = null;
            window.removeEventListener('message', (e) => { });
        };
    }, []);

    useEffect(() => {
        if (kbMaxSettings) {
            setSettings(kbMaxSettings);
        }
    }, [kbMaxSettings]);

    return (
        <KBMaxTemplate
            banner={{
                header: header,
                description: '',
                kbMaxBanner: true,
                displayDropdown: true
            }}
        >
            {!isLoaded && (
                <Grid
                    container
                    item
                    xs={12}
                    className={clsx(classes.spinningLoader, classes.iframeWrapper)}
                >
                    <CircularProgress />
                </Grid>
            )}
            <div
                className={clsx(classes.iframeWrapper, { [classes.hidden]: !isLoaded })}
                id={settings.elementId}
            />
        </KBMaxTemplate>
    );
}
