import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { enUS } from '../../../../../utility/translations/locales';
import OnboardingInfoTile from '../OnboardingInfoTile';

interface Props {
    disabled?: boolean;
}

const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: '1em'
    }
}));

export default function PlaceOrder({ disabled }: Props) {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Grid container>
            <Typography variant="h3" className={classes.header} data-testid="place-order-header">
                <Trans i18nKey="placeYourOrderHeader">${enUS.placeYourOrderHeader}</Trans>
            </Typography>
            <OnboardingInfoTile
                description={t('placeYourOrderText', enUS.placeYourOrderText)}
                stepDisabled={disabled}
                actionButton={
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        data-testid="place-order-button"
                    >
                        <Trans i18nKey="placeYourOrderAction">{enUS.placeYourOrderAction}</Trans>
                    </Button>
                }
            />
        </Grid>
    );
}
