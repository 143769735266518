import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { Grid, makeStyles } from '@material-ui/core';
import Link from '../../reusable/atoms/Link';
import Button from '../../reusable/atoms/Button';
import ScrapItGrid from './components/ScrapItGrid';
import ScrapItProductsOptions from './components/ScrapItProductsOptions';
import { ScrapItState } from '../../../store/reducers/scrap-it';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { getAgedProducts, resetScrapItProductState } from '../../../store/actions/scrap-it';
import { ltBlueGrey_34 } from '../../../themes/globalConstants';
import OrderCancelModal from '../../reusable/molecules/OrderCancelModal';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { Activity, SecurityLevel } from '../../../utility/auth/useSecurity';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { SCRAP_IT_UPDATE_VIEW } from '../../../store/actions/action-types';
import { isProductCanType, isProductEndType } from '../../../utility/helpers/order-helpers';
import { LocalStorage } from 'tubular-common';
import { getStringInputValue } from '../../../utility/helpers/grid-helpers';
import { getProductCellQuantities } from '../../../utility/helpers/shipment-helpers';
import QuantityUnitSelector from '../../reusable/molecules/QuantityUnitSelector';
import { QuantityUnitSelectorProvider } from '../../reusable/molecules/QuantityUnitSelectorContext';
import {
    loadShippingDashboard,
    shippingDashboardUpdatePallets,
    shippingDashboardUpdateView,
    updateProductToBeShipped
} from '../../../store/actions/shipping-dashboard';
import ShipItCheckout from '../ShipIt/components/ShipItCheckout';
import { ProductToShip, ProductWithPallets } from '../../../store/reducers/shipping-dashboard';
import { useQuery } from '../../../utility/helpers/query-helpers';

export interface CheckOutItem {
    product: ProductWithPallets;
    pallets: number;
}

const useStyles = makeStyles((theme) => ({
    dashboard: {
        padding: '0'
    },
    main: {
        padding: '0',
        marginTop: '1.75em'
    },
    side: {
        backgroundColor: ltBlueGrey_34,
        flexDirection: 'column',
        padding: '1em',
        marginTop: '.5em'
    },
    btnWrapper: {
        '& button:last-child': {
            marginLeft: '1.25em'
        }
    },
    actionBtn: {
        borderRadius: 'unset'
    },
    disabledLink: {
        pointerEvents: 'none',
        cursor: 'default'
    },
    quantityUnitSelector: {
        marginTop: '1em'
    }
}));

export default function ScrapIt() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const query = useQuery();
    const from = query.get('from');
    const fromReview = from === 'review';
    const { productsWithPallets, products, view, productsToBeScrapped, loading, quantityUnit } =
        useTypedSelector<ScrapItState>((state) => state.scrapItState);

    const { selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );

    const [open, setOpen] = useState<boolean>(false);
    const [checkOutList, setCheckOutList] = useState<CheckOutItem[]>([]);
    const [cansList, setCansList] = useState<CheckOutItem[]>([]);
    const [endsList, setEndsList] = useState<CheckOutItem[]>([]);
    const [totalPallets, setTotalPallets] = useState<number>(0);
    const [canPallets, setCanPallets] = useState<number>(0);
    const [endPallets, setEndPallets] = useState<number>(0);
    const [reloaded, setReloaded] = useState<boolean>(true);
    const [currentViewTypes, setCurrentViewTypes] = useState<string[]>(['']);
    const [shipToId, setShipToId] = useState<string | undefined>('');
    const [storage] = useState<any>(new LocalStorage('Scrap It Grid'));
    const [navToReviewLocked, setNavToReviewLocked] = useState<boolean>(true);
    const [productReviewUpdates, setProductReviewUpdates] = useState<ProductWithPallets[]>([]);

    const dashboardLink = '/dashboard';
    const scrapItReviewLink = '/scrap-it-review';

    const onCloseConfirmation = () => {
        setOpen(false);
    };

    const onConfirmedCancel = () => {
        handleBackNavigation();
        setOpen(false);
    };

    const handleBackNavigation = () => {
        history.push(dashboardLink);
        dispatch(resetScrapItProductState());
    };

    const onCancel = () => {
        setOpen(true);
    };

    useEffect(() => {
        if (products && products.length > 0) {
            selectedAccountId && dispatch(getAgedProducts([parseInt(selectedAccountId)]));
        }
    }, [selectedAccountId, products]);

    //Handles adding products to checkout
    const handleAddItem = (product: ProductWithPallets, numberOfPallets: number, type?: string) => {
        var newEndsList: CheckOutItem[];
        var newCansList: CheckOutItem[];
        var itemList: CheckOutItem[];

        const checkOutIndex = indexHelper(checkOutList, product);
        const isEndType = isProductEndType(type);
        const isCanType = isProductCanType(type);

        if (isEndType) {
            if (checkOutIndex !== -1) {
                let updatedEndsList = [...endsList];
                const index = indexHelper(updatedEndsList, product);
                const updatedPallets = updatedEndsList[index].pallets + numberOfPallets;
                updatedEndsList.splice(index, 1, {
                    product: product,
                    pallets: updatedPallets
                });

                setEndsList(updatedEndsList);
                updateCheckOutList(checkOutList, product, numberOfPallets);
                setEndPallets(endPallets + numberOfPallets);
            } else {
                newEndsList = [{ product: product, pallets: numberOfPallets }, ...endsList];
                setEndsList(newEndsList);
                itemList = [...cansList, ...newEndsList];
                setCheckOutList(itemList);
                setEndPallets(endPallets + numberOfPallets);
            }
        } else if (isCanType) {
            if (checkOutIndex !== -1) {
                let updatedCansList = [...cansList];
                const index = indexHelper(updatedCansList, product);
                const updatedPallets = updatedCansList[index].pallets + numberOfPallets;
                updatedCansList.splice(index, 1, {
                    product: product,
                    pallets: updatedPallets
                } as CheckOutItem);

                setCansList(updatedCansList);
                updateCheckOutList(checkOutList, product, numberOfPallets);
                setCanPallets(canPallets + numberOfPallets);
            } else {
                newCansList = [{ product: product, pallets: numberOfPallets }, ...cansList];
                setCansList(newCansList);
                itemList = [...newCansList, ...endsList];
                setCheckOutList(itemList);
                setCanPallets(canPallets + numberOfPallets);
            }
        }
        setTotalPallets(totalPallets + numberOfPallets);
    };

    //Handles adding pallets for table
    const handlePalletAdd = (product: ProductWithPallets, numberOfPallets: number) => {
        let currentProductPallets = {
            ...product,
            availablePallets: product.availablePallets,
            availableItemsPerPallet: product.availableItemsPerPallet,
            inputPallets: product.inputPallets,
            orderedPallets: product.orderedPallets
        };

        let itemIndex = productsWithPallets!.findIndex(
            (product) => product.productSku === currentProductPallets.productSku
        );

        if (itemIndex !== -1) {
            let requestedPallets = numberOfPallets;
            const availablePallets = product?.availablePallets;

            if (availablePallets) {
                const newOrderedPallets = product.orderedPallets! + requestedPallets;
                const newAvailablePallets = availablePallets - requestedPallets;
                const newAvailableItems = product.quantityPerPallet!;

                setPalletsForProduct(
                    product,
                    newAvailablePallets,
                    newAvailableItems,
                    newOrderedPallets
                );
            }
        }
    };

    const indexHelper = (list: any, item: any) => {
        let index = -1;
        index = list.findIndex((listItem) => listItem.product.productSku === item.productSku);
        return index;
    };

    const updateCheckOutList = (
        checkOutList: CheckOutItem[],
        product: ProductWithPallets,
        numberOfPallets: number
    ) => {
        let updatedCheckOutList = [...checkOutList];

        const checkOutIndex = indexHelper(checkOutList, product);
        const updatedPallets = updatedCheckOutList[checkOutIndex].pallets + numberOfPallets;

        updatedCheckOutList.splice(checkOutIndex, 1, {
            product: product,
            pallets: updatedPallets
        } as CheckOutItem);

        setCheckOutList(updatedCheckOutList);
    };

    const handleRemoveItem = (i: number, pallets: number, type?: string) => {
        var newEndsList: CheckOutItem[];
        var newCansList: CheckOutItem[];
        var itemList: CheckOutItem[];
        const isEndType = isProductEndType(type);
        const isCanType = isProductCanType(type);

        if (isCanType) {
            newCansList = [...cansList];
            newCansList.splice(i, 1);
            setCansList(newCansList);
            itemList = [...newCansList, ...endsList];
            setCheckOutList(itemList);
            setCanPallets(canPallets - pallets);
        } else if (isEndType) {
            newEndsList = [...endsList];
            newEndsList.splice(i, 1);
            setEndsList(newEndsList);
            itemList = [...cansList, ...newEndsList];
            setCheckOutList(itemList);
            setEndPallets(endPallets - pallets);
        }
        setTotalPallets(totalPallets - pallets);
    };

    const handleRemoveAll = () => {
        setCheckOutList([]);
        setCansList([]);
        setEndsList([]);
        setCanPallets(0);
        setEndPallets(0);
        setProductReviewUpdates([]);
    };

    const handleRemoveAllOfType = (type: string) => {
        const isEndType = isProductEndType(type);
        const isCanType = isProductCanType(type);
        handleSearchText();

        if (isCanType) {
            setCheckOutList(
                [...checkOutList].filter((item) => isProductEndType(item.product.type))
            );
            setCansList([]);
            setCanPallets(0);
        } else if (isEndType) {
            setCheckOutList(
                [...checkOutList].filter((item) => isProductCanType(item.product.type))
            );
            setEndsList([]);
            setEndPallets(0);
        }
    };

    const handlePalletRemove = (product: ProductWithPallets, numberOfPallets: number) => {
        handleSearchText();
        let pristinePallets;
        let currentProductPallets = {
            ...product,
            availablePallets: product.availablePallets,
            availableItemsPerPallet: product.availableItemsPerPallet,
            orderedPallets: product.orderedPallets
        };

        //Grab the pristine pallet value for this product to reset
        products?.map((pristineProduct) => {
            if (pristineProduct.productSku === currentProductPallets.productSku) {
                pristinePallets = pristineProduct.availablePallets;
            }
            return null;
        });

        let itemIndex = productsWithPallets!.findIndex(
            (product) => product.productSku === currentProductPallets.productSku
        );

        if (itemIndex !== -1) {
            const pallets = pristinePallets;
            const palletsOrdered = 0;

            if (pallets) {
                const restoredAvailableItems = product.quantityPerPallet!;

                setPalletsForProduct(
                    product,
                    pristinePallets,
                    restoredAvailableItems,
                    palletsOrdered
                );
            }
        }
    };

    const handlePalletRemoveAll = (productsArr: ProductWithPallets[]) => {
        handleSearchText();
        let updatedProductsWithPallets = [...productsWithPallets];
        let pristinePallets;

        productsArr.map((product) => {
            //Get pristine pallets for each product
            products?.map((pristineProduct) => {
                if (pristineProduct.productSku === product.productSku) {
                    pristinePallets = pristineProduct.availablePallets;
                }
                return null;
            });

            //Reset productsWithPallets with pristine pallets
            productsWithPallets.map((item, index) => {
                if (product.productSku === item.productSku) {
                    updatedProductsWithPallets.splice(index, 1, {
                        ...product,
                        availablePallets: pristinePallets,
                        availableItemsPerPallet: item.quantityPerPallet!,
                        orderedPallets: 0,
                        inputPallets: 0,
                        inputEaches: 0
                    });
                }
                return null;
            });
            dispatch(shippingDashboardUpdatePallets(updatedProductsWithPallets, true));
            return null;
        });
    };

    const setPalletsForProduct = (
        product: ProductWithPallets,
        newAvailablePallets: number,
        newAvailableItems: number,
        newOrderedPallets: number
    ) => {
        let updatedProductWithPallets = [...productsWithPallets];

        productsWithPallets?.map((item, index) => {
            if (product.productSku === item.productSku) {
                updatedProductWithPallets.splice(index, 1, {
                    ...product,
                    availablePallets: newAvailablePallets,
                    availableItemsPerPallet: newAvailableItems,
                    inputPallets: 0,
                    inputEaches: 0,
                    orderedPallets: newOrderedPallets
                });
            }
            return null;
        });

        dispatch(shippingDashboardUpdatePallets(updatedProductWithPallets, true));
    };

    const handleViewFilterClick = (viewType: string[]) => {
        setCurrentViewTypes(viewType);
    };

    //Push input values to state on blur
    const handleInputBlur = (product: ProductWithPallets, inputQuantity: number) => {
        handleSearchText();
        let updatedProductInputPallets = [...productsWithPallets];

        productsWithPallets?.map((item, index) => {
            if (product.productSku === item.productSku) {
                const [inputPallets, inputEaches] = getProductCellQuantities(
                    inputQuantity,
                    product.quantityPerPallet!,
                    quantityUnit
                );
                updatedProductInputPallets.splice(index, 1, {
                    ...product,
                    inputPallets: inputPallets,
                    inputEaches: inputEaches,
                    orderedPallets: item.orderedPallets
                });
            }
            return null;
        });

        dispatch(shippingDashboardUpdatePallets(updatedProductInputPallets, true));
    };

    const handleSearchText = () => {
        const gridElementValue = getStringInputValue('.MuiInputBase-input');
        storage.setTextSearch(gridElementValue);
    };

    const buildProductList = (productList: CheckOutItem[]) => {
        return productList.map((item) => {
            let numOfPalletsOrdered = item.pallets;
            let newPreviousDaysPalletTotals = item.product.previousPalletTotals?.map((total) => {
                return total < numOfPalletsOrdered ? total : numOfPalletsOrdered;
            });
            return {
                ...item.product,
                inputPallets: numOfPalletsOrdered,
                previousPalletTotals: newPreviousDaysPalletTotals,
                calculatedTotalsForPrevNDays: newPreviousDaysPalletTotals
            };
        });
    };

    const handleSubmit = () => {
        const cans = buildProductList(cansList);
        const ends = buildProductList(endsList);
        const productsArray = ends.concat(cans);
        dispatch(updateProductToBeShipped(productsArray as ProductToShip[], true));
    };

    useEffect(() => {
        if (checkOutList.length > 0) {
            setNavToReviewLocked(false);
        } else if (checkOutList.length === 0) {
            setNavToReviewLocked(true);
        }
    }, [checkOutList]);

    useEffect(() => {
        // Using stringify allows us to compare the length and contents of the arrays
        if (JSON.stringify(currentViewTypes) !== JSON.stringify(view)) {
            dispatch(shippingDashboardUpdateView(currentViewTypes, SCRAP_IT_UPDATE_VIEW, true));
        }
    }, [currentViewTypes]);

    useEffect(() => {
        if (selectedAccountId !== shipToId && !productsToBeScrapped.length) {
            setShipToId(selectedAccountId);
            dispatch(loadShippingDashboard(undefined, undefined, true));
        }

        // Clears the search input on Ship to change
        if (storage.getTextSearch() !== '') {
            storage.setTextSearch('');
        }
    }, [selectedAccountId]);

    //Remove all from checkout if ship to changes
    useEffect(() => {
        if (!fromReview) {
            if (selectedAccountId !== shipToId) {
                dispatch(resetScrapItProductState());
                setShipToId(selectedAccountId);
                handleRemoveAll();
                dispatch(loadShippingDashboard(undefined, undefined, true));
            } else {
                dispatch(resetScrapItProductState());
                handleRemoveAll();
            }
        } else {
            if (shipToId !== '' && selectedAccountId !== shipToId) {
                //If the user came from the review page, and then changes shipToId's, reset the state
                dispatch(resetScrapItProductState());
                setShipToId(selectedAccountId);
                handleRemoveAll();
                dispatch(loadShippingDashboard(undefined, undefined, true));
            } else if (shipToId === '') {
                //If the user has just navigated to the page, do not reset state but set shipToId
                setShipToId(selectedAccountId);
            } else {
                //If the user has just come back from the review page, update the cart here with potential changes made
                handleRemoveAll();
                setProductReviewUpdates(productsToBeScrapped);
            }
        }
    }, [dispatch, selectedAccountId, shipToId]);

    useEffect(() => {
        productReviewUpdates.forEach((product) => {
            handleAddItem(product, product.inputPallets ?? 0, product.type);
        });
    }, [productReviewUpdates]);

    useEffect(() => {
        const emptyItemLists = !cansList.length && !endsList.length;

        if (productsToBeScrapped.length && emptyItemLists && reloaded) {
            setReloaded(false);
            let tempProducts = productsToBeScrapped;
            let tempCheckoutList: CheckOutItem[] = [];
            let totalCanPallets = 0;
            let totalEndPallets = 0;
            tempProducts.map((product) => {
                const isEndType = isProductEndType(product.type);
                const isCanType = isProductCanType(product.type);

                if (product.previousPalletTotals) {
                    let tempCheckoutItem = {
                        product: product,
                        pallets:
                            product.previousPalletTotals[product.previousPalletTotals.length - 1]
                    } as CheckOutItem;
                    tempCheckoutList.push(tempCheckoutItem);
                    if (isCanType) {
                        totalCanPallets += tempCheckoutItem.pallets;
                    }
                    if (isEndType) {
                        totalEndPallets += tempCheckoutItem.pallets;
                    }
                }
                return null;
            });

            setCheckOutList(tempCheckoutList);
            setCansList(
                [...tempCheckoutList].filter(
                    (item) => item.product.type === 'CAN' || item.product.type === 'BOTTLE'
                )
            );
            setEndsList(
                [...tempCheckoutList].filter(
                    (item) => item.product.type === 'END' || item.product.type === 'CAP'
                )
            );
            setCanPallets(totalCanPallets);
            setEndPallets(totalEndPallets);
        }
    }, [productsToBeScrapped, checkOutList, cansList.length, endsList.length, reloaded]);

    useTranslation();

    const footerActions = (
        <>
            <Grid container item xs={4} className={classes.btnWrapper}>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="cancel-btn"
                    onClick={onCancel}
                    className={classes.actionBtn}
                >
                    <Trans i18nKey="cancel">Cancel</Trans>
                </Button>
            </Grid>
            <Grid container item xs={4} justify="flex-end" className={classes.btnWrapper}>
                <Button
                    type="button"
                    color="primary"
                    variant="outlined"
                    data-testid="back-btn"
                    className={classes.actionBtn}
                    onClick={handleBackNavigation}
                >
                    <Trans i18nKey="back">Back</Trans>
                </Button>
                <Link
                    to={scrapItReviewLink}
                    underline="none"
                    component={RouterLink}
                    data-testid="scrap-it-review-link"
                    className={navToReviewLocked ? classes.disabledLink : undefined}
                >
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        data-testid="submit-btn"
                        onClick={handleSubmit}
                        className={classes.actionBtn}
                        disabled={navToReviewLocked}
                    >
                        <Trans i18nKey="continue">Continue</Trans>
                    </Button>
                </Link>
            </Grid>
        </>
    );

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('scrapIt', 'Scrap It'),
                description: t('createRequest', 'Create Request').toLocaleUpperCase(),
                thinBanner: true,
                displayDropdown: true
            }}
            actionFooter={{
                footerAction: footerActions,
                justify: 'space-between',
                sticky: true
            }}
            activity={Activity.ScrapIt}
            loading={loading}
            restrictToSecurityLevel={SecurityLevel.Edit}
        >
            <Grid
                container
                spacing={2}
                alignItems="flex-start"
                className={classes.dashboard}
                data-testid="scrap-it-page"
            >
                <QuantityUnitSelectorProvider state={{ quantityUnit: 'pallets' }}>
                    <Grid container item md={9} className={classes.main}>
                        <ScrapItProductsOptions />
                        <QuantityUnitSelector
                            type={'ScrapIt'}
                            styles={classes.quantityUnitSelector}
                        />
                        <ScrapItGrid
                            scrapItItems={productsWithPallets as ProductWithPallets[]}
                            onAddItem={handleAddItem}
                            onPalletAdd={handlePalletAdd}
                            onViewFiltersClick={handleViewFilterClick}
                            onInputBlur={handleInputBlur}
                            onDeleteItem={() => {}}
                            columnWidths={[20, 9, 9, 12, 12, 12, 17]}
                            error={false}
                        />
                    </Grid>
                </QuantityUnitSelectorProvider>
                <Grid container item md={3} className={classes.side}>
                    <ShipItCheckout
                        products={checkOutList}
                        canPallets={canPallets}
                        endPallets={endPallets}
                        onRemoveAllOfType={(type) => handleRemoveAllOfType(type)}
                        onRemoveOrder={handleRemoveItem}
                        onPalletRemove={handlePalletRemove}
                        onPalletRemoveAll={handlePalletRemoveAll}
                        scrapIt={true}
                    />
                </Grid>
            </Grid>
            <OrderCancelModal
                open={open}
                saveProgress={onConfirmedCancel}
                navigationLink={dashboardLink}
                onClose={onCloseConfirmation}
                onCancel={onCloseConfirmation}
                scrapIt={true}
            />
        </ProcessingPageTemplate>
    );
}
