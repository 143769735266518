import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class ManageLocationsTableColumns {
    static getManageLocationsTableColumns = (culture: string) => {
        return [
            createColumn('name', {
                label: TranslationService.getTranslatedText(culture, 'billToLocation'),
                dataType: ColumnDataType.String,
                filterable: false,
                searchable: true,
                sortable: true
            }),
            createColumn('address', {
                label: TranslationService.getTranslatedText(culture, 'address'),
                dataType: ColumnDataType.String,
                filterable: false,
                searchable: true,
                sortable: true
            }),
            createColumn('status', {
                label: TranslationService.getTranslatedText(culture, 'status'),
                dataType: ColumnDataType.String,
                filterable: false,
                searchable: true,
                sortable: true
            }),
            createColumn('actions', {
                label: TranslationService.getTranslatedText(culture, 'actions'),
                filterable: false
            }),
            createColumn('accountId', {
                visible: false
            }),
            createColumn('shipToAccounts', {
                visible: false
            })
        ];
    };
}
