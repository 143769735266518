import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import { AuthState } from '../../../../store/reducers/auth';
import { loadDraftGraphics } from '../../../../store/root-actions';
import { EmeaGraphics, Graphics, Region } from '../../../../store/reducers/graphic-intake';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { GraphicDashboardState } from '../../../../store/reducers/graphic-dashboard';
import {
    boldWeight,
    blackWeight,
    small,
    ballBlue,
    dateOptionInvalidGray,
    medium
} from '../../../../themes/globalConstants';
import { generateKey } from '../../../../utility/helpers/order-helpers';
import GraphicsDraftRow from './GraphicsDraftRow';
import GraphicsService from '../../../../utility/services/graphics-service';
import { useTranslation } from 'react-i18next';
import GraphicsDraftColumns from './GraphicsDraftColumns';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import DeleteGraphicModal from '../../../reusable/molecules/DeleteGraphicModal';
import { InProgressGraphicForTable } from '../models/InProgressGraphicForTable';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: '1em'
    },
    tableWrapper: {
        '& .MuiPaper-root': {
            boxShadow: 'unset'
        },
        '& .MuiTable-root': {
            tableLayout: 'fixed'
        },
        '& .MuiToolbar-root': {
            padding: 0,
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                },
                '&:first-of-type ': {
                    paddingLeft: 0
                }
            },
            '& > h2': {
                fontSize: '1.5em',
                fontWeight: boldWeight,
                color: theme.palette.secondary.main,
                paddingLeft: '0.625em'
            },
            '& .MuiInputBase-formControl': {
                border: `1px solid ${dateOptionInvalidGray}`,
                borderRadius: '.125em',
                '&:before': {
                    display: 'none'
                },
                '&:after': {
                    display: 'none'
                },
                '& svg': {
                    height: '.875em',
                    width: '.875em',
                    fill: ballBlue
                },
                '& .MuiIconButton-root': {
                    '&:hover': {
                        backgroundColor: 'transparent !important'
                    }
                },
                '& .MuiInput-input': {
                    paddingLeft: '.25em'
                }
            }
        },
        '& .MuiTableSortLabel-root': {
            fontSize: small,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em',
            width: '99%',
            justifyContent: 'space-between',
            '& .MuiSvgIcon-root': {
                fontSize: medium,
                opacity: '1'
            }
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                '&:nth-child(1)': {
                    width: '25%'
                },
                '&:nth-child(2)': {
                    width: '75%',
                    fontSize: small,
                    fontWeight: blackWeight,
                    textTransform: 'uppercase',
                    color: theme.palette.secondary.main,
                    borderRadius: '0.25em',
                    paddingLeft: '0.5em',
                    justifyContent: 'space-between'
                }
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    }
}));

const GraphicsDraftTab = () => {
    const { accounts } = useTypedSelector<CustomerContextState>((state) => state.customerContext);
    const { draftGraphics } = useTypedSelector<GraphicDashboardState>(
        (state) => state.graphicDashboard
    );
    const { accessToken } = useTypedSelector<AuthState>((state) => state.auth);
    const { cultureCode, regionCode } = useTypedSelector<RegionCultureState>(
        (state) => state.regionCulture
    );
    const { selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );

    const [open, setOpen] = useState<boolean>(false);
    const [columns, setColumns] = useState<any>([]);
    const [graphicsForTable, setGraphicsForTable] = useState<InProgressGraphicForTable[]>([]);
    const [graphicToDelete, setGraphicToDelete] = useState<InProgressGraphicForTable>();
    const [filteredGraphics, setFilteredGraphics] = useState<Graphics[]>([]);

    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleDelete = (graphic: InProgressGraphicForTable) => {
        setOpen(true);
        setGraphicToDelete(graphic);
    };

    const onCloseConfirmation = () => {
        setOpen(false);
    };

    const onDeleteGraphic = () => {
        if (accounts && graphicToDelete) {
            if (open) {
                setOpen(false);
            }
            if (graphicToDelete.graphicsId) {
                GraphicsService.deleteGraphics(graphicToDelete.graphicsId, accessToken).then(() => {
                    dispatch(loadDraftGraphics(accounts));
                });
            }
        }
    };

    const options = new ToolbarOptions({
        title: t('draftGraphics', 'Draft Graphics'),
        advancePagination: false,
        exportButton: false,
        printButton: false
    });

    useEffect(() => {
        setColumns(GraphicsDraftColumns.getGraphicsDraftColumns(cultureCode, regionCode));
    }, [cultureCode, regionCode]);

    useEffect(() => {
        setFilteredGraphics(
            draftGraphics.filter((graphic) => graphic.billToAddress === selectedAccountId)
        );
    }, [selectedAccountId, draftGraphics]);

    useEffect(() => {
        const getSortedGraphics = (graphics: Graphics[]): Graphics[] | EmeaGraphics[] => {
            if (regionCode === Region.NA) {
                return graphics.sort((a, b) => (a.graphicsId! > b.graphicsId! && 1) || -1);
            } else if (regionCode === Region.EU) {
                const sortedGraphics = (graphics as EmeaGraphics[]).sort(
                    (a, b) => (a.designName! > b.designName! && 1) || -1
                );
                return sortedGraphics;
            }
            return graphics;
        };

        const graphics: InProgressGraphicForTable[] = [];
        const sortedGraphics = getSortedGraphics(filteredGraphics);
        sortedGraphics.forEach((draftGraphic, index) => {
            let graphic: InProgressGraphicForTable;
            graphic = { ...draftGraphic };
            if (regionCode === Region.EU) {
                const draftGraphicEMEA = draftGraphic as EmeaGraphics;
                graphic.requestName = draftGraphicEMEA.designName;
            }
            graphic.sortIndex = index;
            graphics.push(graphic);
        });

        setGraphicsForTable(graphics);
    }, [filteredGraphics, regionCode]);

    const DraftRowComponent = ({ row }: { row: Partial<InProgressGraphicForTable> }) => {
        if (row) {
            return (
                <GraphicsDraftRow
                    graphic={row as InProgressGraphicForTable}
                    onDeleteGraphic={handleDelete}
                    open={open}
                    setOpen={setOpen}
                    key={row.graphicsId}
                />
            );
        } else {
            return null;
        }
    };

    return (
        <Paper elevation={2} className={classes.paper}>
            <Grid className={classes.tableWrapper} key={generateKey('graphics-draft-tab')}>
                <DataGrid
                    gridName="Draft Graphics"
                    columns={columns}
                    dataSource={graphicsForTable}
                    rowComponent={DraftRowComponent}
                    toolbarOptions={options}
                />
                <DeleteGraphicModal
                    open={open}
                    deleteGraphic={onDeleteGraphic}
                    onClose={onCloseConfirmation}
                    closeModalBtn={true}
                />
            </Grid>
        </Paper>
    );
};

export default GraphicsDraftTab;
