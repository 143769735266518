import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class UnrecognizedProductsColumns {
    static getColumns = (culture: string) => {
        return [
            createColumn('customerProductId', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'customerProductId'),
                searchable: true,
                sortable: true
            }),
            createColumn('productName', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'productName'),
                searchable: true,
                filterable: true
            }),
            createColumn('size', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'productSizeAndShape'),
                filterable: true,
                searchable: true
            }),
            createColumn('unit', {
                dataType: ColumnDataType.String,
                filterable: true,
                searchable: true,
                visible: false
            }),
            createColumn('shape', {
                dataType: ColumnDataType.String,
                visible: false,
                filterable: true,
                searchable: true
            }),
            createColumn('neckDiameter', {
                dataType: ColumnDataType.String,
                filterable: true,
                searchable: true,
                visible: false
            }),
            createColumn('endProfile', {
                dataType: ColumnDataType.String,
                filterable: true,
                searchable: true,
                visible: false
            }),
            createColumn('type', {
                dataType: ColumnDataType.String,
                filterable: false,
                searchable: false,
                visible: false
            }),
            createColumn('forecasts', {
                dataType: ColumnDataType.String,
                filterable: false,
                searchable: false,
                visible: false
            }),
            createColumn('expanded', {
                dataType: ColumnDataType.Boolean,
                filterable: false,
                searchable: false,
                visible: false
            })
        ];
    };
}
