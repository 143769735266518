import React, { useEffect, useState } from 'react';

import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { Trans, useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { selectIsLargeCustomerAccount } from '../../../store/selectors';
import { useDispatch } from 'react-redux';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Activity, SecurityLevel } from '../../../utility/auth/useSecurity';
import { useParams } from 'react-router';
import { DefaultQueryParams } from '../../../utility/helpers/query-helpers';
import { blackWeight } from '../../../themes/globalConstants';
import { getScrapItRequest } from '../../../store/actions/scrap-it-details';
import { ScrapItRequestDetailsState } from '../../../store/reducers/scrap-it-details';
import { SCRAP_IT_REQUEST_DETAILS_RESET } from '../../../store/actions/action-types';
import ScrapItRequestDetailsSubheader from './components/ScrapItRequestDetailsSubheader';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { getShipToAccounts } from '../../../store/actions/customer-context';
import { AuthState } from '../../../store/reducers/auth';
import ScrapItRequestDetailsGrid from './components/ScrapItRequestDetailsGrid';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '2em',
        borderRadius: 4,
        marginTop: '2em'
    },
    header: {
        marginTop: '1em',
        marginBottom: '0.5em'
    },
    title: {
        fontWeight: blackWeight,
        color: theme.palette.secondary.main,
        padding: '0 0 1em 0'
    },
    subheader: {
        marginBottom: '0.5em'
    }
}));

export default function ScrapItRequestDetails() {
    const { id } = useParams<DefaultQueryParams>();
    const scrapItRequestId: string = id;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const isLargeCustomerAccount = useTypedSelector<boolean>(selectIsLargeCustomerAccount);
    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);
    const { loading, scrapItRequest } = useTypedSelector<ScrapItRequestDetailsState>(
        (state) => state.scrapItRequestDetails
    );
    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const [shipToName, setShipToName] = useState<string>('');
    const [accountId, setAccountId] = useState<string>('');
    const [shipToAddress, setDisplayAddress] = useState<string>('');
    const [shipToAssigned, setShipToAssigned] = useState<boolean>(false);

    const setShippingInformation = (accountInformation) => {
        if (accountInformation[0]) {
            setShipToName(accountInformation[0].name);
            setAccountId(accountInformation[0].accountId);
            if (accountInformation[0].address) {
                const shipToAddress = accountInformation[0].address;
                setDisplayAddress(
                    shipToAddress.address1?.trimEnd() +
                        ', ' +
                        shipToAddress.city +
                        ', ' +
                        shipToAddress.stateProvince +
                        ' ' +
                        shipToAddress.postalCode
                );
            }
            setShipToAssigned(true);
        }
    };

    useEffect(() => {
        return () => {
            // clear state on page leave
            dispatch({ type: SCRAP_IT_REQUEST_DETAILS_RESET });
        };
    }, []);

    useEffect(() => {
        if (permissions) {
            if (!shipToAccounts?.length) {
                dispatch(getShipToAccounts());
            }
        }
    }, [dispatch, permissions]);

    useEffect(() => {
        if (scrapItRequest && shipToAccounts) {
            setShippingInformation(
                shipToAccounts.filter(
                    (account) => account.accountId === scrapItRequest.shipToId.toString()
                )
            );
        }
    }, [scrapItRequest, shipToAccounts]);

    useEffect(() => {
        if (scrapItRequestId.length > 0 && isLargeCustomerAccount) {
            dispatch(getScrapItRequest(scrapItRequestId));
        }
    }, [dispatch, scrapItRequestId, isLargeCustomerAccount]);

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('scrapIt', 'Scrap It'),
                description: t('requestDetails', 'Request Details'),
                thinBanner: true,
                displayDropdown: false
            }}
            activity={Activity.ScrapItDetails}
            restrictToSecurityLevel={SecurityLevel.View}
            loading={loading}
        >
            <Grid container className={classes.paper} data-testid={'scrapit-request-details'}>
                {scrapItRequest && shipToAssigned && (
                    <Grid container alignItems="flex-start">
                        <Grid item className={classes.header} xs={6} justify="flex-start">
                            <Typography variant="h3" className={classes.title}>
                                <Trans i18nKey="scrapItRequest">Scrap It Request</Trans>
                                {' # '}
                                {`${scrapItRequest.scrapRequestId}`}
                            </Typography>
                        </Grid>

                        <Grid container className={classes.subheader}>
                            <ScrapItRequestDetailsSubheader
                                shipToAddress={shipToAddress}
                                shipToName={shipToName}
                                shipToId={accountId}
                                request={scrapItRequest}
                            />
                            <ScrapItRequestDetailsGrid scrapLines={scrapItRequest.scrapLines} />
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </ProcessingPageTemplate>
    );
}
