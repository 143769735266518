import { Button, Grid, makeStyles, Typography, Link } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
    BulkUploadMakeItATMState,
    MakeItBulkATMLineItem,
    MakeItBulkATMLinesBySnoSku,
    MakeItBulkATMOrderByWeekAndSku
} from '../../../store/reducers/makeit-bulk-atm';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { ballBlue, blackWeight, boldWeight, medium, small } from '../../../themes/globalConstants';
import { Activity } from '../../../utility/auth/useSecurity';
import {
    getWeekValidationState,
    isProductEndType,
    ToggleAll,
    validateMoqAmounts
} from '../../../utility/helpers/order-helpers';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import {
    addQuantitiesFromOtherOrders,
    evaluatePalletQuantityValid,
    getActiveProducts,
    getFeeStructure,
    groupOrdersBySnoSku,
    PalletQuantityState,
    regroupOrdersBySnoSku
} from '../../../utility/helpers/make-it-bulk-helpers';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import ConfirmationModal from '../../reusable/molecules/ConfirmationModal';
import { Prompt, useHistory, Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
    getAllocations,
    resetMakeItBulkATMState,
    saveOrdersBySnoSku,
    submitAtmOrders,
    submitDraftAsMakeIt,
    updateExpandAll
} from '../../../store/actions/makeit-bulk-atm';
import { DateState } from './components/MakeItBulkReviewATMDateTab';
import { getDateProperties, DateProperties } from '../../../utility/helpers/make-it-bulk-helpers';
import { MakeItBulkReviewATMProvider } from './context/MakeItBulkReviewATMContext';
import { FormattedWeek } from './models/FormattedWeek';
import ShipToDropdown from './components/ShipToDropdown';
import ValidationFilterMenu from '../../reusable/molecules/ValidationFilterMenu';
import { enUS } from '../../../utility/translations/locales';
import { FormattedAccount } from './models/FormattedAccount';
import { getAccountAddress } from '../../../utility/helpers/address-helpers';
import MakeItBulkFeeModal from '../../reusable/molecules/MakeItBulkFeeModal';
import ErrorAlert from '../../reusable/atoms/ErrorAlert';
import WeekSelectorDropdown from './components/WeekSelectorDropdown';
import WarningAlert from '../../reusable/atoms/WarningAlert';
import { isDateWithinAtmLockPeriod } from '../../../utility/helpers/date-helpers';
import { RouterConfirmationOverride } from '../../../App';
import { useQuery } from '../../../utility/helpers/query-helpers';
import { selectIsImpersonation, selectHasImpersonationShipTo } from '../../../store/selectors';
import ImpersonationWarning from '../../reusable/molecules/ImpersonationWarning';
import {
    BulkUploadMakeItState,
    MakeItBulkLineItem
} from '../../../store/reducers/makeit-bulk-upload';
import { setUseSummaryStoredSearch } from '../../../store/actions/make-it-summary';
import { Order_Status } from '../../../utility/services/orders-service';
import { OrderProductTableRow } from '../../../store/reducers/orders-dashboard';
import {
    getCampaignInformation,
    getMoqFeeCeiling,
    getMoqFeeFloor
} from '../../../utility/helpers/production-order-helpers';
import { CampaignRun } from '../../../store/reducers/product-portfolio';
import CurrentCampaignsModal from '../../reusable/molecules/CurrentCampaignsModal';
import SaveDraftModal from '../../reusable/molecules/SaveDraftModal';
import { MakeItATMSummaryState } from '../../../store/reducers/make-it-atm-summary';
import clsx from 'clsx';
import { resetMakeItBulkState } from '../../../store/root-actions';
import ActionButtonGroup from '../../reusable/atoms/ActionButtonGroup';
import MakeItReviewOrderSection from '../../reusable/molecules/MakeItReviewOrderProductGrouping/MakeItReviewOrderSection';
const useStyles = makeStyles((theme) => ({
    actionBtn: {
        height: '3.375em',
        width: '13.75em',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom'
    },
    dateSelectSection: {
        paddingTop: '1em'
    },
    viewAtmTitle: {
        fontWeight: blackWeight,
        color: theme.palette.secondary.main,
        textTransform: 'uppercase'
    },
    selector: {
        display: 'flex',
        marginTop: '1em'
    },
    btn: {
        padding: 0,
        minWidth: 'auto',
        fontSize: small,
        fontWeight: boldWeight,
        margin: '0 0.5em'
    },
    expandCollapseWrapper: {
        alignItems: 'center',
        color: ballBlue
    },
    shipToTextContainer: {
        margin: '0.5em 0 1.688em 0'
    },
    shipToWarningText: {
        fontSize: small,
        fontWeight: boldWeight
    },
    divider: {
        borderWidth: '1px 0 0 0',
        borderStyle: 'solid',
        borderColor: '#979797',
        marginTop: '3em',
        marginBottom: '3em'
    },
    linkSpacer: {
        marginRight: '.25em'
    },
    linkedPO: {
        textDecoration: `underline ${ballBlue}`
    },
    warningContainer: {
        marginBottom: '.5em'
    },
    subtitle: {
        color: theme.palette.secondary.main
    },
    viewCampaignsButton: {
        fontSize: medium,
        '&:hover': {
            backgroundColor: 'transparent',
            color: ballBlue,
            outline: 'none'
        },
        '&:focus': {
            backgroundColor: 'transparent',
            color: ballBlue
        }
    },
    errorDiv: {
        marginLeft: '-90px',
        marginTop: '-10px'
    }
}));

export default function MakeItBulkUploadReviewATM() {
    const query = useQuery();
    const from = query.get('from');
    const {
        atmOrders,
        ordersLoaded,
        allocations,
        submitAtmOrdersSubmissionState,
        error,
        isEdit,
        existingOrders,
        ordersBySnoSku,
        existingDrafts,
        draft,
        readonly
    } = useTypedSelector<BulkUploadMakeItATMState>((state) => state.bulkUploadMakeItATMState);
    const { orders } = useTypedSelector<MakeItATMSummaryState>((state) => state.makeItATMSummary);
    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { products } = useTypedSelector<BulkUploadMakeItState>(
        (state) => state.bulkUploadMakeItState
    );

    const [formattedWeekList, setFormattedWeekList] = useState<FormattedWeek[]>();
    const [fromGuided, setFromGuided] = useState<boolean>(false);
    const [fromBulk, setFromBulk] = useState<boolean>(from === 'bulk');
    const [fromSummary, setFromSummary] = useState<boolean>(from === 'summary');
    const [fromDraft, setFromDraft] = useState<boolean>(from === 'draft');
    const [activeDate, setActiveDate] = useState<DateProperties>({
        fullDate: '',
        week: '',
        year: ''
    });
    const [existingLinesBySnoSku, setExistingLinesBySnoSku] =
        useState<MakeItBulkATMOrderByWeekAndSku[]>();
    const [activeDateOrders, setActiveDateOrders] = useState<MakeItBulkATMOrderByWeekAndSku[]>();
    const [shipToAccountsList, setShipToAccountsList] = useState<FormattedAccount[]>([]);

    const [showErrors, setShowErrors] = useState<boolean>(false);
    const [showWarnings, setShowWarnings] = useState<boolean>(false);
    const [showCampaigns, setShowCampaigns] = useState<boolean>(false);
    const [showAll, setShowAll] = useState<boolean>(true);
    const [showLeadTimeWarning, setShowLeadTimeWarning] = useState<boolean>(false);

    const [selectedShipTo, setSelectedShipTo] = useState<string>();
    const [openBackModal, setOpenBackModal] = useState<boolean>(false);
    const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
    const [expandAll, setExpandAll] = useState<ToggleAll>({ toggle: false });
    const [orderHasErrors, setOrderHasErrors] = useState<boolean>(true);
    const [orderHasErrorsOrWarnings, setOrderHasErrorsOrWarnings] = useState<boolean>(false);
    const [weeksHasErrorsOrWarnings, setWeeksHasErrorsOrWarnings] = useState<boolean>(false);
    const [orderValidated, setOrderValidated] = useState<boolean>(false);
    const [tableInformation, setTableInformation] = useState<MakeItBulkATMLineItem[]>([]);
    const [acceptFee, setAcceptFee] = useState(false);
    const [openFeeModal, setOpenFeeModal] = useState(false);
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [allowNavigation, setAllowNavigation] = useState<boolean>(false);
    const [lastLocationPath, setLastLocationPath] = useState<string>('');
    const [openBlockedNavigationModal, setOpenBlockedNavigationModal] = useState<boolean>(false);
    const isImpersonation = useTypedSelector<boolean>(
        fromBulk ? selectHasImpersonationShipTo : selectIsImpersonation
    );
    const [showImpersonationWarning, setShowImpersonationWarning] = useState<boolean>(false);
    const [showSameWeekShipToWarning, setShowSameWeekShipToWarning] = useState(false);
    const [showSameWeekDraftsWarning, setShowSameWeekDraftsWarning] = useState(false);
    const [showViewCurrentCampaignsLink, setShowViewCurrentCampaignsLink] =
        useState<boolean>(false);
    const [campaignRuns, setCampaignRuns] = useState<CampaignRun[]>();
    const [campaignProducts, setCampaignProducts] = useState<OrderProductTableRow[]>();
    const [openViewCampaignsModal, setOpenViewCampaignsModal] = useState<boolean>(false);
    const [openSaveDraftModal, setOpenSaveDraftModal] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const onCancel = () => {
        setOpenCancelModal(true);
    };

    const onSaveDraft = () => {
        setOpenSaveDraftModal(true);
    };

    const onBack = () => {
        setOpenBackModal(true);
    };

    const onViewCampaigns = () => {
        setOpenViewCampaignsModal(true);
    };

    const onCloseCampaignsModal = () => {
        setOpenViewCampaignsModal(false);
    };

    const handleCancel = () => {
        setOpenCancelModal(false);
        dispatch(resetMakeItBulkATMState());
        history.push('/');
    };

    const handleShowErrors = () => {
        setShowWarnings(false);
        setShowErrors(true);
        setShowAll(false);
        setShowCampaigns(false);
    };

    const handleShowWarnings = () => {
        setShowWarnings(true);
        setShowAll(false);
        setShowErrors(false);
        setShowCampaigns(false);
    };

    const handleShowAll = () => {
        setShowErrors(false);
        setShowWarnings(false);
        setShowCampaigns(false);
        setShowAll(true);
    };

    const handleShowCampaigns = () => {
        setShowErrors(false);
        setShowWarnings(false);
        setShowCampaigns(true);
        setShowAll(false);
    };

    const onCloseFeeModal = () => {
        setOpenFeeModal(false);
    };

    const onAcceptFee = () => {
        setAcceptFee(true);
    };

    const handleBackNavigation = () => {
        let atmSummaryPath = '/make-it-po-summary';
        if (atmOrders && atmOrders[0]) {
            const isClosed =
                atmOrders[0].status === Order_Status.Cancelled ||
                atmOrders[0].status === Order_Status.CancelledPending ||
                atmOrders[0].status === Order_Status.Closed ||
                atmOrders[0].status === Order_Status.Completed;

            if (isClosed) atmSummaryPath += '?status=closed';
        }

        fromDraft
            ? history.push('/make-it-drafts-summary')
            : fromGuided
            ? history.push('/make-it?from=review')
            : !isEdit
            ? history.push('/make-it-bulk-upload?from=review')
            : history.push(atmSummaryPath);
    };

    useEffect(() => {
        return () => {
            //path will be make-it-bulk-upload-po-review when user changes language on this page
            if (
                !(
                    history.location.pathname.includes('make-it-bulk-upload-po-review') ||
                    history.location.pathname.includes('make-it-bulk-po-confirmation')
                )
            ) {
                dispatch(resetMakeItBulkState());
            }
        };
    }, [dispatch, history]);

    // If going back to the ATM Make-It Summary page, use the stored search to repopulate the search bar
    useEffect(() => {
        return () => {
            if (history.location.pathname.includes('make-it-po-summary')) {
                dispatch(setUseSummaryStoredSearch(true));
            }
        };
    }, [dispatch, history]);

    useEffect(() => {
        if (isEdit && atmOrders && existingOrders && atmOrders.length === 1) {
            // in edit mode, exclude the existing order
            const matchingOrders = existingOrders.filter(
                (order) => order.productionOrderId !== atmOrders[0].productionOrderId
            );
            if (matchingOrders.length > 0) {
                setShowSameWeekShipToWarning(true);
            }
        } else if (orders) {
            const matchingOrders = orders.filter(
                (order) => moment(order.atmWeekStart).format('MM/DD/YYYY') === activeDate.fullDate
            );
            if (matchingOrders.length > 0) {
                setShowSameWeekShipToWarning(true);
            }
        }
    }, [orders, activeDate, isEdit, atmOrders, existingOrders]);

    useEffect(() => {
        if (!fromDraft && existingDrafts?.length > 0) {
            setShowSameWeekDraftsWarning(true);
        }
    }, [existingDrafts, fromDraft]);

    const onClose = () => {
        if (openBackModal) {
            setOpenBackModal(false);
        } else if (openCancelModal) {
            setOpenCancelModal(false);
        } else {
            setOpenBlockedNavigationModal(false);
        }
    };

    const handleBlockedNavigation = (location) => {
        if (submitAtmOrdersSubmissionState === 'success') {
            return true;
        } else {
            if (location !== history.location.pathname) setLastLocationPath(location);
            if (!allowNavigation && !openCancelModal && !openBackModal) {
                setOpenBlockedNavigationModal(true);
                return false;
            }
            return true;
        }
    };

    const handleConfirmNavigation = () => {
        setOpenBlockedNavigationModal(false);

        if (lastLocationPath !== '') {
            setAllowNavigation(true);
            // Navigate to the previously blocked location
            setTimeout(() => {
                history.push(lastLocationPath);
            }, 100);
        }
    };

    const onCloseBlockedNavigationModal = () => {
        setOpenBlockedNavigationModal(false);
    };

    const onSubmit = useCallback(() => {
        setIsSubmitting(true);
        let productList: MakeItBulkATMLineItem[] = [];

        atmOrders?.forEach((atmOrder) => {
            atmOrder.lines.forEach((line) => {
                if (!line.deleted && line.palletQuantity && line.palletQuantity > 0) {
                    let accountInfo = shipToAccountsList.find(
                        (account) => account.account.accountId === atmOrder.shipToId.toString()
                    );
                    if (accountInfo) {
                        line.shipToId = accountInfo.account.accountId;
                        line.shipToName = accountInfo.account.name;
                        line.shipToAddress = getAccountAddress(accountInfo.account);
                        line.weekStart = atmOrder.weekStart;
                    }
                    productList.push(line);
                }
            });
        });

        setTableInformation(productList);

        if (validateMoqAmounts(productList, acceptFee) && ordersBySnoSku) {
            if (isImpersonation) {
                setShowImpersonationWarning(true);
                return;
            }
            if (draft) {
                dispatch(submitDraftAsMakeIt(setIsSubmitting));
            } else {
                dispatch(submitAtmOrders(setIsSubmitting));
            }
        } else {
            if (!acceptFee) {
                setOpenFeeModal(true);
                setIsSubmitting(false);
            }
        }
    }, [
        acceptFee,
        dispatch,
        isImpersonation,
        ordersBySnoSku,
        shipToAccountsList,
        atmOrders,
        draft
    ]);

    // If user accepts the MOQ fee we want to submit their order
    useEffect(() => {
        if (acceptFee) {
            onSubmit();
        }
    }, [acceptFee, onSubmit]);

    const handleExpandAll = () => {
        setExpandAll({ toggle: true });
        dispatch(updateExpandAll(false, activeDate.fullDate, selectedShipTo));
    };

    const handleCollapseAll = () => {
        setExpandAll({ toggle: false });
        dispatch(updateExpandAll(true, activeDate.fullDate, selectedShipTo));
    };

    const checkShouldRender = (order: MakeItBulkATMLinesBySnoSku, shipToId: number): Boolean => {
        const activeProducts = getActiveProducts(shipToId.toString(), products, order);
        if (
            (fromSummary || fromBulk) &&
            activeProducts.length > 0 &&
            isProductEndType(activeProducts[0].type)
        ) {
            return true;
        }

        switch (true) {
            case !order.lines.length && order.initiallyEmpty && activeProducts.length === 0:
                return false;
            case !order.lines.length && !order.allocationAmount:
                return false;
            case showAll:
                return true;
            case showErrors:
                return !!order.hasError;
            case showWarnings:
                return !!order.hasWarning;
            case showCampaigns:
                return order.lines.length > 0 && !!order.lines[0].campaignTiming;
            default:
                return true;
        }
    };

    const alreadyOrdered = (
        shipToId: number,
        weekStart: string,
        grouping: MakeItBulkATMLinesBySnoSku
    ): boolean => {
        if (existingLinesBySnoSku) {
            const targetLines = existingLinesBySnoSku
                .filter(
                    (order) =>
                        order.shipToId === shipToId &&
                        moment(order.weekStart).format('MM/DD/YYYY') ===
                            moment(weekStart).format('MM/DD/YYYY')
                )
                .map((order) => order.linesBySnoSku)
                .flat()
                .filter((line) => line.snoSku === grouping.snoSku && line.totalPalletQuantity > 0);
            return targetLines.length > 0;
        }
        return false;
    };

    const checkShouldRenderNoProductsSection = (order: MakeItBulkATMOrderByWeekAndSku) => {
        return order.linesBySnoSku
            .filter(
                (grouping) =>
                    grouping.initiallyEmpty &&
                    !alreadyOrdered(order.shipToId, order.weekStart, grouping)
            )
            .some((grouping) => checkShouldRender(grouping, order.shipToId));
    };

    /**
     * Evaluates validity of all orders/weeks for a shipToAccount .  Used for calculating validation state
     * of the shipToAccount.
     * @param shipToAccount
     * @returns
     */
    const getShipToValidationState = useCallback(
        (shipToAccount: FormattedAccount): DateState => {
            const accountValidityStates: DateState[] = [];

            if (ordersBySnoSku) {
                ordersBySnoSku.forEach((order) => {
                    if (order.shipToId.toString() === shipToAccount.account.accountId) {
                        accountValidityStates.push(order.state);
                    }
                });
            }
            switch (true) {
                case accountValidityStates.some((state) => state === 'error'):
                    return 'error';
                case accountValidityStates.some((state) => state === 'warning'):
                    return 'warning';
                default:
                    return 'valid';
            }
        },
        [ordersBySnoSku]
    );

    /**
     * Renders an order section
     * @param order
     * @param lines
     * @returns
     */
    const renderOrderSection = (order, grouping) => {
        const dataToBind = { ...grouping };
        if (fromBulk || fromGuided) {
            dataToBind.lines.forEach((x) => {
                x.originalPalletQuantity = undefined;
            });
        }
        return (
            checkShouldRender(grouping, order.shipToId) && (
                <MakeItBulkReviewATMProvider
                    value={{
                        activeDate,
                        shipToId: order.shipToId
                    }}
                >
                    <Grid item>
                        <MakeItReviewOrderSection
                            order={dataToBind}
                            shipToId={order.shipToId}
                            isActive={grouping.isActive}
                            expand={expandAll}
                            showPrevPalletQty={!fromBulk && !fromGuided && !fromDraft}
                            showCsvLineNumber={!isEdit}
                            isDraft={fromDraft}
                            planItToMakeIt={false}
                        />
                    </Grid>
                </MakeItBulkReviewATMProvider>
            )
        );
    };

    // update local state campaign products and campaign runs data
    const handleUpdateCampaigns = (products: OrderProductTableRow[] | MakeItBulkLineItem[]) => {
        if (selectedShipTo) {
            let campaignInformation = getCampaignInformation(products, selectedShipTo);

            // store unique campaign products
            setCampaignProducts(campaignInformation.filteredCampaignProducts);
            // store campaign products that have campaign runs associated to them
            setCampaignRuns(campaignInformation.updatedCampaigns);
        }
    };

    useEffect(() => {
        if (products) {
            handleUpdateCampaigns(products);
        }
    }, [products]);

    // when campaign runs or products are updated (possibly switching ship to), check
    // whether or not to show view current campaigns link
    useEffect(() => {
        const hasCampaignRuns = !!campaignRuns && campaignRuns.length > 0;
        const hasCampaignProducts = !!campaignProducts && campaignProducts.length > 0;
        setShowViewCurrentCampaignsLink(hasCampaignRuns || hasCampaignProducts);
    }, [campaignProducts, campaignRuns]);

    /**
     * Load the Ship To Accounts from the order - only include a single ship to once.
     * @when Triggers on initial page load, after shipToAccounts and atmOrders are in state.
     */
    useEffect(() => {
        if (atmOrders && shipToAccounts) {
            let accountsInOrder: FormattedAccount[] = [];
            atmOrders.forEach((order) => {
                let orderShipTo = order.shipToId;
                let account = shipToAccounts.find(
                    (account) => parseInt(account.accountId) === orderShipTo
                );

                let formattedAccount = {
                    account: account,
                    state: 'valid'
                } as FormattedAccount;

                if (
                    account &&
                    !accountsInOrder.find((accountInList) => accountInList.account === account)
                ) {
                    accountsInOrder.push(formattedAccount);
                }
            });
            setShipToAccountsList(accountsInOrder);
        }
    }, [atmOrders, shipToAccounts]);

    /**
     * Set the currently selected ship to as the first Account in the shipToAccountsList.
     * @when Trigger once on initial page load, after shipToAccountsList has been set from the above useEffect.
     */
    useEffect(() => {
        if (shipToAccountsList.length && !selectedShipTo) {
            setSelectedShipTo(shipToAccountsList[0].account.accountId);
        }
    }, [selectedShipTo, shipToAccountsList]);

    /**
     * Temporarily set formatted week list to undefined if it is already set.
     * This allows for the resetting of the selected date.
     * @when Trigger when selected ship to is changed.
     */
    useEffect(() => {
        if (formattedWeekList && formattedWeekList.length > 0) {
            setFormattedWeekList(undefined);
        }
        if (products) {
            handleUpdateCampaigns(products);
        }
        // need to set formattedWeekList as undefined when selectedShipTo
    }, [selectedShipTo]);

    /**
     * Create weeksList object for the MakeItBulkReviewATMDateTab, consisting of every week for a given order filtered by the
     * currently selectedShipTo.
     *
     * Also sort weeksList in chronological order.
     *
     * @when Trigger on load and whenever selectedShipTo is updated.
     * @deps atmOrders, selectedShipTo
     */
    useEffect(() => {
        if (ordersLoaded && atmOrders && !formattedWeekList && selectedShipTo) {
            let weeksList: string[] = [];
            atmOrders
                .filter((order) => order.shipToId === parseInt(selectedShipTo))
                .map((order) => order.weekStart)
                .forEach((weekStart) => {
                    if (!weeksList.includes(weekStart)) {
                        weeksList.push(weekStart);
                    }
                });
            let weekListAsDate: FormattedWeek[] = weeksList.map((week) => {
                return { weekAsDate: moment(week), state: 'valid' };
            });
            weekListAsDate.sort((a, b) => a.weekAsDate.valueOf() - b.weekAsDate.valueOf());

            // Ensure active date is reset (when a new ship to is selected)
            setActiveDate({
                fullDate: '',
                week: '',
                year: ''
            } as DateProperties);

            setFormattedWeekList(weekListAsDate);
        }
    }, [ordersLoaded, atmOrders, selectedShipTo, formattedWeekList]);

    /**
     * Set the active date to the first week of formattedWeekList.
     * @when Trigger whenever formattedWeekList is updated, which happens in the above useEffect after selectedShipTo is
     * changed.
     * @deps formattedWeekList
     */
    useEffect(() => {
        if (formattedWeekList && formattedWeekList.length && !activeDate.fullDate.length) {
            setActiveDate(getDateProperties(formattedWeekList[0].weekAsDate));
        }
    }, [activeDate.fullDate.length, formattedWeekList]);

    /**
     * Query for allocations.
     * @when Trigger only once on load, after formattedWeekList is set and allocations have not yet been set.
     * @deps formattedWeekList
     */
    useEffect(() => {
        if (atmOrders && formattedWeekList?.length && !allocations) {
            const lastDateIndex = formattedWeekList.length - 1;
            let startDate = formattedWeekList[0].weekAsDate.format('M/D/YYYY');
            let endDate = formattedWeekList[lastDateIndex].weekAsDate.format('M/D/YYYY');
            dispatch(getAllocations(startDate, endDate));
        }
    }, [atmOrders, formattedWeekList, allocations, dispatch]);

    /**
     * Group atmOrders by snoSku.
     * @when Trigger only once on load, after we have queried for allocations.
     * @deps atmOrders, allocations
     */
    useEffect(() => {
        if (atmOrders && allocations && ordersLoaded && existingLinesBySnoSku) {
            let ordersByWeekAndSku: MakeItBulkATMOrderByWeekAndSku[] = [];
            if (!ordersBySnoSku) {
                ordersByWeekAndSku = groupOrdersBySnoSku(atmOrders, allocations);
            } else {
                ordersByWeekAndSku = regroupOrdersBySnoSku(atmOrders, ordersBySnoSku, allocations);
            }
            let combinedOrdersBySnoSku: MakeItBulkATMOrderByWeekAndSku[] = [];
            atmOrders.forEach((atmOrder) => {
                const linesBySnoSkuForThisWeek = existingLinesBySnoSku
                    .filter(
                        (lines) =>
                            lines.shipToId === atmOrder.shipToId &&
                            moment(lines.weekStart).format('MM/DD/YYYY') ===
                                moment(atmOrder.weekStart).format('MM/DD/YYYY')
                    )
                    .map((line) => line.linesBySnoSku)
                    .flat();
                const ordersByWeekAndSkuForWeek = ordersByWeekAndSku.filter(
                    (lines) =>
                        moment(lines.weekStart).format('MM/DD/YYYY') ===
                            moment(atmOrder.weekStart).format('MM/DD/YYYY') &&
                        lines.shipToId === atmOrder.shipToId
                );
                const ordersWithTotals = addQuantitiesFromOtherOrders(
                    atmOrder,
                    ordersByWeekAndSkuForWeek ? ordersByWeekAndSkuForWeek : [],
                    linesBySnoSkuForThisWeek
                );
                combinedOrdersBySnoSku = combinedOrdersBySnoSku.concat(ordersWithTotals);
            });
            if ((fromBulk || fromSummary) && products) {
                // add empty groupings for all end type snoSkus when editing or from bulk upload
                combinedOrdersBySnoSku.forEach((order) => {
                    const endTypeSnoSkus = products
                        .filter(
                            (product) =>
                                isProductEndType(product.type) &&
                                product.destinations
                                    ?.map((dest) => dest.shipToId)
                                    .includes(order.shipToId)
                        )
                        .map((end) => end.snoSku)
                        .filter((value, index, self) => self.indexOf(value) === index);
                    endTypeSnoSkus.forEach((endTypeSnoSku) => {
                        if (endTypeSnoSku) {
                            // if it is not already included in the order
                            if (
                                !order.linesBySnoSku
                                    .map((line) => line.snoSku)
                                    .includes(endTypeSnoSku)
                            )
                                order.linesBySnoSku.push({
                                    allocationAmount: 0,
                                    isActive: false,
                                    lines: [],
                                    snoSku: endTypeSnoSku,
                                    totalPalletQuantity: 0,
                                    weekStart: order.weekStart,
                                    initiallyEmpty: false
                                });
                        }
                    });
                });
            }

            if (ordersBySnoSku) {
                // restore the isActive status from the existing ordersBySnoSku
                combinedOrdersBySnoSku.forEach((newOrder) => {
                    const targetOrder = ordersBySnoSku.find(
                        (order) =>
                            order.weekStart === newOrder.weekStart &&
                            order.shipToId === newOrder.shipToId
                    );
                    newOrder.linesBySnoSku.forEach((line) => {
                        const targetLine = targetOrder?.linesBySnoSku.find(
                            (existingLine) =>
                                existingLine.snoSku && existingLine.snoSku === line.snoSku
                        );
                        if (targetLine) {
                            line.isActive = targetLine.isActive;
                        }
                    });
                });
            }
            dispatch(saveOrdersBySnoSku(combinedOrdersBySnoSku));
        }
    }, [
        atmOrders,
        allocations,
        ordersLoaded,
        existingLinesBySnoSku,
        dispatch,
        fromBulk,
        fromSummary,
        products
    ]);

    useEffect(() => {
        if (existingOrders && allocations) {
            setExistingLinesBySnoSku(groupOrdersBySnoSku(existingOrders, allocations));
        }
    }, [existingOrders, allocations]);

    /**
     * Revalidate entire order.
     * @when Trigger once whenever ordersBySnoSku is updated.
     * @deps ordersBySnoSku
     */
    useEffect(() => {
        setOrderValidated(false);
        if (ordersBySnoSku) {
            ordersBySnoSku.forEach((orders) => {
                // exclude lines that are not in this order and have
                // totalPalletQuantiy > 0 because they are included in another order
                const linesToValidate = orders.linesBySnoSku.filter(
                    (line) => !(line.totalPalletQuantity > 0 && line.lines.length === 0)
                );
                orders.state = getWeekValidationState(linesToValidate);
            });
            setOrderValidated(true);
        }
    }, [ordersBySnoSku]);

    /**
     * Set validations on the dates if both formattedWeekList is set and orders are grouped by snoSku and have been validated.
     * @when Trigger when an update to an order is made OR the selected ship to is changed.
     * @deps formattedWeekList, ordersBySnoSku, orderValidated
     */
    useEffect(() => {
        if (
            formattedWeekList &&
            formattedWeekList.length &&
            ordersBySnoSku &&
            ordersBySnoSku.length &&
            orderValidated
        ) {
            setWeeksHasErrorsOrWarnings(false);
            formattedWeekList.forEach((formattedWeek) => {
                const orders = ordersBySnoSku?.find(
                    (order) =>
                        moment(order.weekStart).valueOf() === formattedWeek.weekAsDate.valueOf() &&
                        order.shipToId.toString() === selectedShipTo
                );
                if (orders) {
                    formattedWeek.state = orders.state;
                    if (formattedWeek.state !== 'valid') {
                        setWeeksHasErrorsOrWarnings(true);
                    }
                }
            });
        }
    }, [formattedWeekList, ordersBySnoSku, orderValidated, selectedShipTo]);

    /**
     * Set the disabled state submit button if the order has been validated.
     * @when Trigger once whenever ordersBySnoSku is updated and after order has been validated.
     * @deps ordersBySnoSku, orderValidated
     */
    useEffect(() => {
        if (ordersBySnoSku && ordersBySnoSku.length && orderValidated) {
            //Reset error state before re-validation.
            setOrderHasErrors(false);
            setOrderHasErrorsOrWarnings(false);
            let revalidatedAccountsList = shipToAccountsList.map((shipToAccount) => {
                let stateToUpdate = getShipToValidationState(shipToAccount);
                if (stateToUpdate !== 'valid') {
                    if (stateToUpdate === 'error') {
                        setOrderHasErrors(true);
                    }
                    setOrderHasErrorsOrWarnings(true);
                }
                shipToAccount.state = stateToUpdate;
                return shipToAccount;
            });
            setShipToAccountsList(revalidatedAccountsList);
            dispatch(saveOrdersBySnoSku(ordersBySnoSku));
        }
    }, [ordersBySnoSku, orderValidated, selectedShipTo, dispatch, getShipToValidationState]);

    useEffect(() => {
        const someActiveDateOrderHasAnInactiveProduct = activeDateOrders?.some((order) => {
            return order.linesBySnoSku.some((snoLine) => {
                return snoLine.lines.some((line) => {
                    return (line as any).inactiveProduct;
                });
            });
        });

        if (someActiveDateOrderHasAnInactiveProduct) setOrderHasErrors(true);
    }, [activeDateOrders]);

    /**
     * Filter ordersBySnoSku by the currently selected ship to and active date.
     * Last step before order information is displayed.
     * @when Trigger if orders have been grouped by snoSku, when the activeDate
     * or the selectedShipTo are changed.
     * @deps ordersBySnoSku, activeDate, selectedShipTo
     */
    useEffect(() => {
        if (ordersBySnoSku && ordersBySnoSku.length > 0 && activeDate && selectedShipTo) {
            const ordersBySnoSkuUpdate = JSON.parse(JSON.stringify(ordersBySnoSku));
            const activeOrders = ordersBySnoSkuUpdate.filter((week) => {
                return (
                    getDateProperties(moment(week.weekStart)).week === activeDate.week &&
                    week.shipToId === parseInt(selectedShipTo)
                );
            });

            let formattedOrders: Array<MakeItBulkATMLinesBySnoSku> = [];

            activeOrders.forEach((order) => {
                order.linesBySnoSku.forEach((productGroup) => {
                    const palletQuantityStates: PalletQuantityState[] = [];
                    let hasDuplicateProducts = false;
                    productGroup.lines.forEach((line) => {
                        if (line.minimumOrderQuantity && !line.deleted) {
                            palletQuantityStates.push(
                                evaluatePalletQuantityValid(
                                    line.palletQuantity,
                                    line.minimumOrderQuantity,
                                    getFeeStructure(line),
                                    getMoqFeeFloor(line.moqFees),
                                    getMoqFeeCeiling(line.moqFees)
                                )
                            );
                        }
                        if (!line.isCustomerProductIdDistinct) {
                            hasDuplicateProducts = true;
                        }
                    });

                    productGroup.hasError =
                        palletQuantityStates.some(
                            (state) =>
                                state === 'minimumOrderQuantityError' ||
                                state === 'undefinedQuantity'
                        ) || hasDuplicateProducts;
                    productGroup.hasWarning = palletQuantityStates.some(
                        (state) => state === 'feeWarning'
                    );
                    formattedOrders.push(productGroup);
                });
                order.linesBySnoSku = formattedOrders;
            });
            setActiveDateOrders(activeOrders);
        }
    }, [ordersBySnoSku, activeDate, selectedShipTo]);

    useEffect(() => {
        activeDateOrders?.forEach((order) => {
            order.linesBySnoSku.forEach((lineBySnoSku) => {
                const isWithinLockPeriod = lineBySnoSku.lines.some((line) => {
                    return (
                        isDateWithinAtmLockPeriod(
                            moment(activeDate.fullDate),
                            (line.leadTimeWeeks ? line.leadTimeWeeks : 1) * 7
                        ) !== 'after'
                    );
                });
                if (isEdit && isWithinLockPeriod) {
                    setShowLeadTimeWarning(true);
                }
            });
        });
    }, [activeDate, activeDateOrders, isEdit]);

    useEffect(() => {
        if (submitAtmOrdersSubmissionState === 'success') {
            history.push('/make-it-bulk-po-confirmation');
        }
    }, [history, submitAtmOrdersSubmissionState]);

    useEffect(() => {
        setFromGuided(from === 'guided');
        setFromBulk(from === 'bulk');
        setFromSummary(from === 'summary');
        setFromDraft(from === 'draft');
    }, [from]);

    const getLeadTimeWarning = () => {
        return (
            <Trans i18nKey="linkedDeliveryOrderOrLeadTimeWarning">
                One or more products cannot be edited because of lead times.
            </Trans>
        );
    };

    const makeItSummaryLink = (
        <Link
            to={'/make-it-po-summary'}
            component={RouterLink}
            underline="none"
            data-testid="make-it-summary-link"
            className={clsx(classes.linkedPO, classes.linkSpacer)}
        >
            Make It Summary
        </Link>
    );

    const makeItDraftsLink = (
        <Link
            to={'/make-it-drafts-summary'}
            component={RouterLink}
            underline="none"
            data-testid="make-it-drafts-link"
            className={clsx(classes.linkedPO, classes.linkSpacer)}
        >
            Make It Drafts
        </Link>
    );

    const getSameWeekShipTosWarning = (
        <Trans i18nKey="sameWeekShipTosWarning">
            There are additional Make It Order(s) for this week. Please see {makeItSummaryLink}
            page for details.
        </Trans>
    );
    const getSameWeekDraftsWarning = (
        <Trans i18nKey="sameWeekShipTosDraftsWarning">
            There are draft Make It Order(s) for this week. Please see {makeItDraftsLink} page for
            details.
        </Trans>
    );
    const getBallProdOrderAndWeek = () => {
        let ballProductionOrder = ' ' + t('pending', 'Pending');
        if (atmOrders && ordersLoaded) {
            if (atmOrders.length === 1 && atmOrders[0].productionOrderNumber) {
                ballProductionOrder = atmOrders[0].productionOrderNumber.toString();
            }
        }
        return `${t('ballProdOrder', 'Ball Production Order')} #${ballProductionOrder} ${t(
            'forTheWeekOf',
            'for the week of'
        )} ${activeDate.week}`;
    };

    const footerActions = (
        <>
            <Grid container item xs={4} justify="flex-start">
                <ActionButtonGroup>
                    <Button
                        type="button"
                        variant="outlined"
                        color="secondary"
                        data-testid="cancel-btn"
                        className={classes.actionBtn}
                        onClick={onCancel}
                        disabled={readonly}
                    >
                        <Trans i18nKey="cancel">Cancel</Trans>
                    </Button>
                    {(fromGuided || fromBulk || fromDraft) && (
                        <Button
                            type="button"
                            variant="outlined"
                            color="secondary"
                            data-testid="saveDraft-btn"
                            className={classes.actionBtn}
                            onClick={onSaveDraft}
                            disabled={readonly}
                        >
                            <Trans i18nKey="saveDraft">Save Draft</Trans>
                        </Button>
                    )}
                </ActionButtonGroup>
            </Grid>

            <Grid container item xs={5} className={classes.errorDiv}>
                <ErrorAlert
                    errorMessage={
                        <Trans i18nKey="submitATMError">
                            The system was unable to process your changes.
                        </Trans>
                    }
                    showError={error === 'submit' || error === 'submitDraft'}
                    dataTestId="submit-error"
                ></ErrorAlert>
                <ImpersonationWarning
                    showWarning={showImpersonationWarning}
                    warningType={'SUBMIT'}
                />
            </Grid>
            <Grid item xs={3} justify="flex-end" container>
                <ActionButtonGroup>
                    <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        data-testid="back-btn"
                        onClick={onBack}
                        className={classes.actionBtn}
                    >
                        <Trans i18nKey="back">Back</Trans>
                    </Button>

                    <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        data-testid="submit-order-btn"
                        className={classes.actionBtn}
                        disabled={orderHasErrors || isSubmitting || readonly}
                        onClick={onSubmit}
                    >
                        <Trans i18nKey="submitChanges">Submit Changes</Trans>
                    </Button>
                </ActionButtonGroup>
            </Grid>
        </>
    );

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('makeIt', 'Make It'),
                description: t('reviewOrder', enUS.reviewOrder).toUpperCase(),
                thinBanner: true,
                displayDropdown: false
            }}
            actionFooter={{
                footerAction: footerActions,
                justify: 'space-between',
                sticky: true
            }}
            activity={Activity.MakeItBulkATM}
            shipTos={false}
            loading={atmOrders ? !ordersBySnoSku?.length : false}
        >
            {ordersBySnoSku && ordersBySnoSku.length > 0 && (
                <Grid container item className={classes.dateSelectSection}>
                    <Grid
                        container
                        item
                        xs={12}
                        className={classes.warningContainer}
                        data-testid="warning-container"
                    >
                        <WarningAlert
                            showWarning={showLeadTimeWarning}
                            warningMessage={getLeadTimeWarning()}
                            dataTestId="lead-time-warning"
                        />
                        <WarningAlert
                            showWarning={showSameWeekShipToWarning}
                            warningMessage={getSameWeekShipTosWarning}
                            dataTestId="existing-order-warning"
                        />
                        <WarningAlert
                            showWarning={showSameWeekDraftsWarning}
                            warningMessage={getSameWeekDraftsWarning}
                            dataTestId="existing-draft-warning"
                        />
                    </Grid>
                    <Grid container item xs={4}>
                        <Grid item>
                            <Typography variant="subtitle2" className={classes.viewAtmTitle}>
                                {t('shipTo', 'Ship To')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={8}>
                        <Grid item>
                            <Typography variant="subtitle2" className={classes.viewAtmTitle}>
                                {t('atmWeekSelector', 'View ATM for the week of')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={4}
                        className={classes.selector}
                        data-testid="ship-to-selector"
                    >
                        <ShipToDropdown
                            name="shipToSelect"
                            testId="shipToSelect"
                            selectObjects={shipToAccountsList}
                            selected={selectedShipTo}
                            updateSelected={setSelectedShipTo}
                            showIcons={true}
                        />
                    </Grid>
                    {formattedWeekList && (
                        <Grid
                            container
                            item
                            xs={4}
                            className={classes.selector}
                            data-testid="dateBar"
                        >
                            <WeekSelectorDropdown
                                testId="weekSelect"
                                weeks={formattedWeekList}
                                selectedDate={activeDate}
                                onDateSelected={setActiveDate}
                                showIcons={true}
                            />
                        </Grid>
                    )}
                    <Grid container item xs={12}>
                        <Grid
                            container
                            xs={4}
                            alignItems="center"
                            item
                            className={classes.shipToTextContainer}
                        >
                            {orderHasErrorsOrWarnings && !isEdit && (
                                <Typography className={classes.shipToWarningText}>
                                    <Trans i18nKey="atmSelectorMessage">
                                        One or more Ship Tos have warnings or errors in them, please
                                        review.
                                    </Trans>
                                </Typography>
                            )}
                        </Grid>
                        <Grid
                            container
                            xs={4}
                            alignItems="center"
                            item
                            className={classes.shipToTextContainer}
                        >
                            {weeksHasErrorsOrWarnings && (
                                <Typography className={classes.shipToWarningText}>
                                    <Trans i18nKey="atmWeekSelectorMessage">
                                        {enUS.atmWeekSelectorMessage}
                                    </Trans>
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className={classes.selector}>
                        <Grid container item xs={9}>
                            <Typography
                                variant="h3"
                                className={classes.viewAtmTitle}
                                data-testid="current-selected-date"
                            >
                                {getBallProdOrderAndWeek()}
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            item
                            justify="flex-end"
                            className={classes.expandCollapseWrapper}
                            xs={3}
                        >
                            <Button
                                className={classes.btn}
                                color="primary"
                                onClick={handleExpandAll}
                                data-testid="expand-all"
                            >
                                <Trans i18nKey="expandAll">Expand All</Trans>
                            </Button>
                            <Typography>|</Typography>
                            <Button
                                className={classes.btn}
                                color="primary"
                                onClick={handleCollapseAll}
                                data-testid="collapse-all"
                            >
                                <Trans i18nKey="collapseAll">Collapse All</Trans>
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid>
                        {showViewCurrentCampaignsLink && (
                            <Typography variant="h5" className={classes.subtitle}>
                                <Trans i18nKey="lookingForCampaignSchedules">
                                    Looking for campaign schedules?
                                </Trans>
                                <Button
                                    variant="text"
                                    color="primary"
                                    data-testid="view-campaigns-button"
                                    className={classes.viewCampaignsButton}
                                    onClick={onViewCampaigns}
                                >
                                    <Trans i18nKey="viewCurrentUpcomingCampaigns">
                                        View Current & Upcoming Campaigns
                                    </Trans>
                                </Button>
                            </Typography>
                        )}
                    </Grid>
                    <Grid xs={12} data-testid="atm-orders">
                        <ValidationFilterMenu
                            onShowErrors={handleShowErrors}
                            onShowWarnings={handleShowWarnings}
                            onShowCampaigns={handleShowCampaigns}
                            onShowAll={handleShowAll}
                            showAll={showAll}
                            showErrors={showErrors}
                            showWarnings={showWarnings}
                        />
                        {activeDateOrders &&
                            activeDateOrders.map(
                                (order) =>
                                    order.linesBySnoSku && (
                                        <>
                                            {order.linesBySnoSku
                                                .filter((grouping) => !grouping.initiallyEmpty)
                                                .map((grouping) =>
                                                    renderOrderSection(order, grouping)
                                                )}
                                            {checkShouldRenderNoProductsSection(order) && (
                                                <>
                                                    <hr className={classes.divider} />
                                                    <Typography
                                                        variant="h3"
                                                        className={classes.viewAtmTitle}
                                                        data-testid="empty-products-section"
                                                    >
                                                        {t(
                                                            'noProductsOrdered',
                                                            'No products ordered for the following supply plans:'
                                                        )}
                                                    </Typography>
                                                    {order.linesBySnoSku
                                                        .filter(
                                                            (grouping) =>
                                                                grouping.initiallyEmpty &&
                                                                !alreadyOrdered(
                                                                    order.shipToId,
                                                                    order.weekStart,
                                                                    grouping
                                                                )
                                                        )
                                                        .map((grouping) =>
                                                            renderOrderSection(order, grouping)
                                                        )}
                                                </>
                                            )}
                                        </>
                                    )
                            )}
                    </Grid>
                </Grid>
            )}
            <MakeItBulkFeeModal
                atmProducts={tableInformation}
                isAtm={true}
                open={openFeeModal}
                onClose={onCloseFeeModal}
                onAcceptFee={onAcceptFee}
            />
            <ConfirmationModal
                data-testid="cancel-modal"
                saveProgress={handleCancel}
                onClose={onClose}
                logo={false}
                title={<Trans i18nKey="cancel">Cancel</Trans>}
                subheader={<Trans i18nKey="changesNotSaved">Your changes will not be saved</Trans>}
                description={
                    <Trans i18nKey="confirmCancel">Are you sure you want to cancel?</Trans>
                }
                continueText={<Trans i18nKey="yesCancel">Yes, Cancel</Trans>}
                cancelText={<Trans i18nKey="no">No</Trans>}
                open={openCancelModal}
                navigationLink=""
                onCancel={onClose}
                {...(fromBulk || fromGuided || fromDraft
                    ? {
                          onSave: onSaveDraft,
                          saveInputLabel: <Trans i18nKey="saveChanges">Save Changes</Trans>,
                          saveInput: false
                      }
                    : {})}
            />
            <ConfirmationModal
                data-testid="go-back-modal"
                saveProgress={handleBackNavigation}
                onClose={onClose}
                logo={false}
                title={<Trans i18nKey="back">Back</Trans>}
                subheader={<Trans i18nKey="changesNotSaved">Your changes will not be saved</Trans>}
                description={
                    <Trans i18nKey="goBackConfirm">Are you sure you want to go back?</Trans>
                }
                continueText={<Trans i18nKey="yesGoBack">Yes, Go Back</Trans>}
                cancelText={<Trans i18nKey="no">No</Trans>}
                open={openBackModal}
                navigationLink=""
                onCancel={onClose}
                {...(fromBulk || fromGuided || fromDraft
                    ? {
                          onSave: onSaveDraft,
                          saveInputLabel: <Trans i18nKey="saveChanges">Save Changes</Trans>,
                          saveInput: false
                      }
                    : {})}
            />
            <ConfirmationModal
                data-testid="confirm-exit-modal"
                saveProgress={handleConfirmNavigation}
                onClose={onClose}
                logo={false}
                title={<Trans i18nKey="exit">Exit</Trans>}
                subheader={
                    <Trans i18nKey="exitConfirm">Are you sure you want to exit the page?</Trans>
                }
                description={
                    <Trans i18nKey="changesNotSaved">Your changes will not be saved?</Trans>
                }
                continueText={<Trans i18nKey="yesExit">Yes, Exit</Trans>}
                cancelText={<Trans i18nKey="no">No</Trans>}
                open={openBlockedNavigationModal}
                navigationLink=""
                onCancel={onCloseBlockedNavigationModal}
                {...(fromBulk || fromGuided || fromDraft
                    ? {
                          onSave: onSaveDraft,
                          saveInputLabel: <Trans i18nKey="saveChanges">Save Changes</Trans>,
                          saveInput: false
                      }
                    : {})}
            />
            {campaignRuns && (
                <CurrentCampaignsModal
                    title={
                        <Trans i18nKey="currentUpcomingCampaigns">
                            Current & Upcoming Campaigns
                        </Trans>
                    }
                    open={openViewCampaignsModal}
                    onClose={onCloseCampaignsModal}
                    campaignRuns={campaignRuns}
                    campaignProducts={campaignProducts}
                />
            )}
            <SaveDraftModal
                data-testid="save-draft-modal"
                onClose={onClose}
                open={openSaveDraftModal}
                onCancel={onCloseBlockedNavigationModal}
                setClose={setOpenSaveDraftModal}
                setAllowNavigation={setAllowNavigation}
            />
            <Prompt
                when={!allowNavigation}
                message={(location, action) => {
                    if (action === 'POP') {
                        handleBlockedNavigation(location.pathname);
                        return RouterConfirmationOverride.PreventBrowserBack;
                    } else {
                        return handleBlockedNavigation(location.pathname);
                    }
                }}
            />
        </ProcessingPageTemplate>
    );
}
