import React from 'react';
import { QuantityUnit } from '../../../store/reducers/shipping-dashboard';

type Action = { type: 'TOGGLE_QUANTITY_UNITS_PALLETS' } | { type: 'TOGGLE_QUANTITY_UNITS_EACHES' };
type Dispatch = (action: Action) => void;
type State = { quantityUnit: QuantityUnit };
type QuantityUnitSelectorProviderProps = { children: React.ReactNode; state: State };

const QuantityUnitSelectorStateContext = React.createContext<State | undefined>(undefined);
const QuantityUnitSelectorDispatchContext = React.createContext<Dispatch | undefined>(undefined);

const quantityUnitSelectorReducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'TOGGLE_QUANTITY_UNITS_PALLETS': {
            return {
                quantityUnit: 'pallets'
            };
        }
        case 'TOGGLE_QUANTITY_UNITS_EACHES': {
            return {
                quantityUnit: 'eaches'
            };
        }
        default: {
            throw new Error(`Unhandled action type: ${(action as Action).type}`);
        }
    }
};
const QuantityUnitSelectorProvider = ({
    children,
    state
}: QuantityUnitSelectorProviderProps): JSX.Element => {
    const [initialState, dispatch] = React.useReducer(
        quantityUnitSelectorReducer,
        state || { quantityUnit: 'pallets' }
    );
    return (
        <QuantityUnitSelectorStateContext.Provider value={initialState}>
            <QuantityUnitSelectorDispatchContext.Provider value={dispatch}>
                {children}
            </QuantityUnitSelectorDispatchContext.Provider>
        </QuantityUnitSelectorStateContext.Provider>
    );
};
const useQuantityUnitSelectorState = (): State => {
    const context = React.useContext(QuantityUnitSelectorStateContext);
    if (context === undefined) {
        throw new Error('useProductSelectedState must be used within a ProductSelectedProvider');
    }
    return context;
};
const useQuantityUnitSelectorDispatch = (): Dispatch => {
    const context = React.useContext(QuantityUnitSelectorDispatchContext);
    if (context === undefined) {
        throw new Error(
            'useQuantityUnitSelectorDispatch must be used within a QuantityUnitSelectorProvider'
        );
    }
    return context;
};
export {
    QuantityUnitSelectorProvider,
    useQuantityUnitSelectorState,
    useQuantityUnitSelectorDispatch
};
