import { blackWeight } from '../../../../themes/globalConstants';

export const styles = (theme) => ({
    paper: {
        marginTop: '3em',
        padding: '2em',
        borderRadius: 4
    },
    title: {
        fontWeight: blackWeight,
        color: theme.palette.secondary.main,
        padding: '0 0 1em 0'
    },
    cancelContainer: {
        marginLeft: '2em'
    },
    submitContainer: {
        textAlign: 'right'
    },
    subHeader: {
        marginBottom: '-1em'
    },
    section: {
        marginBottom: '1.5em'
    },
    textArea: {
        width: '100% !important',
        boxSizing: 'border-box',
        padding: '18px 14px',
        fontSize: 14,
        border: '1px solid',
        borderColor: theme.palette.secondary.light,
        fontFamily: "'Lato', 'Helvetica', 'Arial', sans-serif",
        '&:focus': {
            outline: 'none',
            border: '1px solid',
            borderColor: theme.palette.primary.main
        }
    },
    displayInput: {
        '& input': {
            color: 'rgba(0,0,0,.87) !important'
        }
    },
    noSelect: {
        userSelect: 'none'
    },
    error: {
        fontSize: '.75em',
        marginTop: '-10px',
        marginBottom: '-20px',
        marginLeft: '0.5em'
    },
    charactersLeft: {
        marginTop: '-15px',
        marginBottom: '10px'
    }
});
