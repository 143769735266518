import {
    Button,
    CircularProgress,
    Collapse,
    Container,
    FormHelperText,
    Grid,
    makeStyles,
    Typography
} from '@material-ui/core';
import React, { ReactNode, useEffect, useState } from 'react';
import { ErrorMessage, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import {
    ballBlue,
    blackWeight,
    brightGrey,
    containerMaxWidth,
    disabledGrey,
    medium,
    orange,
    red
} from '../../../themes/globalConstants';
import clsx from 'clsx';
import FormSection from './FormSection';
import TextInput from './TextInput';
import RadioInput from './RadioInput';
import { BulkUploadFieldDefinition } from '../../pages/DeliveryBulkUpload/DeliveryBulkUpload';
import { AuthState } from '../../../store/reducers/auth';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { useDispatch } from 'react-redux';
import { modifyFirstLetter } from '../../../utility/helpers/formatters';
import ConfirmationModal from './ConfirmationModal';
import { QuantityType, RowsOrColumns } from '../../../utility/services/orders-service';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { enUS } from '../../../utility/translations/locales/en-US/en-US';
import { WeekQuantity } from '../../../store/reducers/makeit-bulk-atm';
import { GetFeatureFlag, FlagTypes } from '../../../utility/helpers/feature-flag';
import { TemplateMapping } from '../../../store/reducers/bulk-upload';

interface Props {
    hasTemplate: boolean;
    fieldDefinitions: BulkUploadFieldDefinition;
    templateData?: any;
    isEdit: boolean;
    setIsEdit: (boolean) => void;
    updateFieldDefinitions: (rowsOrColumns: RowsOrColumns) => void;
    onSubmitCreateMapping: (
        userName: string,
        mappings: TemplateMapping[],
        quantityType: QuantityType,
        rowsOrColumns?: RowsOrColumns,
        weekQuantities?: WeekQuantity[],
        firstRowOfData?: number
    ) => void;
    onSubmitUpdateMapping: (
        userName: string,
        mappings: TemplateMapping[],
        quantityType: QuantityType,
        rowsOrColumns?: RowsOrColumns,
        weekQuantities?: WeekQuantity[],
        firstRowOfData?: number
    ) => void;
    updateMappingText: string;
    createMappingText: string;
    quantityType?: QuantityType;
    rowsOrColumns?: RowsOrColumns;
    templateError: any;
    weekQuantities?: Array<WeekQuantity>;
    firstRowOfData?: number;
    onResetTemplateErrors: () => void;
    isPlanItBulk?: boolean;
}

const useStyles = makeStyles((theme) => ({
    section: {
        padding: '1em 0.5em'
    },
    description: {
        fontSize: medium,
        color: brightGrey
    },
    leftPadding: {
        paddingLeft: '0.5em'
    },
    formSection: {
        marginBottom: '2.5em',
        flex: '1 1 auto'
    },
    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '0.5em'
    },
    buttonContainer: {
        paddingTop: '1em',
        paddingBottom: '3em',
        maxWidth: containerMaxWidth
    },
    formSectionContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    collapse: {
        width: '100%'
    },
    fieldRow: {
        flex: '1 1 auto',
        padding: '0.5em 0'
    },
    errorText: {
        color: red
    },
    actionBtn: {
        height: '3.375em',
        width: '11.75em',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom'
    },
    atmRadioGroup: {
        marginTop: '1em',
        flexBasis: 0
    },
    eachesHelpText: {
        marginBottom: '1.25em',
        color: brightGrey
    },
    fieldSet: {
        border: 'none'
    },
    addWeekQuantityWrapper: {
        margin: '0.25em 0 0 0.25em'
    },
    addWeek: {
        backgroundColor: 'transparent',
        outline: 'none',
        fontWeight: blackWeight,
        fontSize: medium,
        textDecoration: 'underline',
        '&:hover': {
            color: ballBlue,
            cursor: 'pointer'
        }
    },
    addWeekDisabled: {
        backgroundColor: 'transparent',
        outline: 'none',
        fontWeight: blackWeight,
        fontSize: medium,
        textDecoration: 'underline',
        color: disabledGrey,
        '&:hover': {
            color: disabledGrey,
            cursor: 'not-allowed'
        }
    },
    removeWeek: {
        color: orange,
        marginLeft: '-1.5em',
        '&:hover': {
            color: orange,
            cursor: 'pointer'
        }
    },
    removeWeekDisabled: {
        marginLeft: '-1.5em',
        '&:hover': {
            cursor: 'not-allowed'
        }
    }
}));

const ATMBulkUploadMappingTemplate = ({
    hasTemplate,
    fieldDefinitions,
    templateData,
    isEdit,
    setIsEdit,
    updateFieldDefinitions,
    onSubmitCreateMapping,
    onSubmitUpdateMapping,
    updateMappingText,
    createMappingText,
    quantityType,
    rowsOrColumns,
    templateError,
    weekQuantities,
    firstRowOfData,
    onResetTemplateErrors,
    isPlanItBulk
}: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { userInfo } = useTypedSelector<AuthState>((state) => state.auth);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const { handleSubmit, errors, control, reset, getValues, setValue, watch } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur'
    });

    const watchQuantity = watch('quantityType', quantityType ?? QuantityType.Pallets);
    const watchRowsOrColumns = watch('rowsOrColumns', rowsOrColumns ?? RowsOrColumns.Rows);

    const showAtmColumnOption = GetFeatureFlag(FlagTypes.MakeItBulkUpload);

    const [showForm] = useState<boolean>(true);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [validationError, setValidationError] = useState<boolean>(false);
    const [open, setIsOpen] = useState<boolean>(false);
    const [header, setHeader] = useState<ReactNode>('');
    const [firstRowDefault, setFirstRowDefault] = useState<string>('');
    const [currentWeekQuantities, setCurrentWeekQuantities] = useState<WeekQuantity[]>(
        Array(isPlanItBulk ? 5 : 4).fill({ quantity: '' })
    );
    const isRequired = <Trans i18nKey="required">Required</Trans>;
    const weekIsRequired = (
        <Trans i18nKey="weekQuantityRequiredError">Required. Remove if not needed.</Trans>
    );
    const { t } = useTranslation();

    const onSubmit = (formData: any) => {
        setIsSubmitting(true);
        setValidationError(false);
        let data: any[] = [];
        let distinctFields = new Set<string>();
        Object.keys(formData.bulkUploadTemplate).forEach((fieldName) => {
            const value = formData.bulkUploadTemplate[fieldName];
            if (value && value !== '') {
                data.push({
                    fieldName: fieldName.charAt(0).toUpperCase() + fieldName.slice(1),
                    columnLetter: value.toUpperCase()
                });
                distinctFields.add(value.toUpperCase());
            }
        });
        let countOfDataObjects = data.length;

        // If user selects columns, need to add extra fields to the template mapping section
        if (watchRowsOrColumns === RowsOrColumns.Columns) {
            formData.weekQuantity.forEach((week, index) => {
                let weekNumber = index;
                data.push({
                    fieldName: `WeekNumber${++weekNumber}`,
                    columnLetter: week.quantity.toUpperCase()
                });
                distinctFields.add(week.quantity.toUpperCase());
                countOfDataObjects++;
            });
            distinctFields.add(formData.firstRowOfData);
            countOfDataObjects++;
        }
        const firstRowOfData = formData.firstRowOfData
            ? Number(formData.firstRowOfData)
            : undefined;
        const weekQuantities = formData.weekQuantity ? formData.weekQuantity : [];

        if (distinctFields.size === countOfDataObjects && userInfo) {
            hasTemplate
                ? dispatch(
                      onSubmitUpdateMapping(
                          userInfo.preferred_username,
                          data,
                          watchQuantity,
                          watchRowsOrColumns,
                          weekQuantities,
                          firstRowOfData
                      )
                  )
                : dispatch(
                      onSubmitCreateMapping(
                          userInfo.preferred_username,
                          data,
                          watchQuantity,
                          watchRowsOrColumns,
                          weekQuantities,
                          firstRowOfData
                      )
                  );
            setIsSubmitting(false);
            setIsEdit(false);
        } else {
            setIsSubmitting(false);
            setValidationError(true);
        }
    };

    const clearAllFields = () => {
        const data = getValues();
        Object.keys(data).forEach((field) => {
            setValue(field, '');
        });
        setCurrentWeekQuantities(Array(isPlanItBulk ? 5 : 4).fill({ quantity: '' }));
        setFirstRowDefault('');
    };

    const onClear = () => {
        if (!hasTemplate) {
            clearAllFields();
        } else {
            mapTemplateToForm();
        }
        setValidationError(false);
        reset();
    };

    const onClearAll = () => {
        clearAllFields();
        setValidationError(false);
        setIsEdit(true);
        dispatch(onResetTemplateErrors());
    };

    const onClose = () => {
        setIsOpen(false);
    };

    const onOpenReset = () => {
        setIsOpen(true);
    };

    const onReset = () => {
        setIsEdit(false);
        setIsOpen(false);
        onClear();
    };

    const onEdit = () => {
        setIsEdit(!isEdit);
    };

    const createSectionHeader = () => {
        if (!hasTemplate) {
            setHeader(createMappingText);
        } else {
            setHeader(updateMappingText);
        }
    };

    const mapTemplateToForm = () => {
        let weekQuantityCount = 0;
        Object.keys(templateData).forEach((field) => {
            const fieldName = modifyFirstLetter(templateData[field].fieldName);
            const columnLetter = templateData[field].columnLetter;
            let currentDataField = `bulkUploadTemplate[${fieldName}]`;

            if (fieldName.includes('weekNumber')) {
                currentDataField = `weekQuantity[${weekQuantityCount}].quantity`;
                weekQuantityCount++;
            }
            setValue(currentDataField, columnLetter);
        });
        setValue('quantityType', quantityType);
        setValue('rowsOrColumns', rowsOrColumns);
        setValue('firstRowOfData', firstRowOfData);
        setFirstRowDefault(firstRowOfData ? firstRowOfData.toString() : '');
        if (weekQuantities?.length) {
            setCurrentWeekQuantities(weekQuantities);
        }
    };

    const handleAddWeek = () => {
        let weekArray = [...currentWeekQuantities];
        weekArray.push({ quantity: '' });
        setCurrentWeekQuantities(weekArray);
    };

    const handleRemoveWeek = (index) => {
        let weekArray = [...currentWeekQuantities];
        weekArray = weekArray.filter((loc, i) => i !== index);
        setCurrentWeekQuantities(weekArray);
    };

    useEffect(() => {
        if (templateData && templateData.length > 0) {
            mapTemplateToForm();
            setIsSubmitting(false);
            setIsEdit(false);
        }
    }, [templateData]);

    useEffect(() => {
        if (templateError) {
            setIsEdit(true);
        }
    }, [templateError]);

    useEffect(() => {
        if (!hasTemplate) {
            setIsEdit(true);
        }
        createSectionHeader();
    }, [hasTemplate, cultureCode]);

    useEffect(() => {
        if (quantityType) {
            setValue('quantityType', quantityType);
        }
    }, [quantityType]);

    useEffect(() => {
        if (watchRowsOrColumns !== undefined) {
            updateFieldDefinitions(watchRowsOrColumns);
            if (
                watchRowsOrColumns === RowsOrColumns.Columns &&
                (!templateData || templateData.length === 0)
            ) {
                setCurrentWeekQuantities([
                    { quantity: '' },
                    { quantity: '' },
                    { quantity: '' },
                    { quantity: '' }
                ]);
            }
        }
    }, [watchRowsOrColumns]);

    return (
        <FormSection
            testId={'atm-bulk-upload-template-section'}
            sectionHeader={header}
            collapsible={true}
            showSectionOnLoad={!hasTemplate}
        >
            <Collapse in={showForm} className={classes.collapse}>
                <Grid container item xs={12}>
                    <Typography className={clsx(classes.description, classes.leftPadding)}>
                        <Trans i18nKey="makeitMapTemplateFieldsDescription">
                            Map required values to their corresponding column in your template. All
                            values below are required and should be mapped.
                        </Trans>
                    </Typography>
                </Grid>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <Grid container data-testid="bulk-upload-template">
                        <Container className={classes.section}>
                            <Grid container item xs={12}>
                                <Grid container item className={classes.formSectionContainer}>
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        spacing={2}
                                        className={classes.atmRadioGroup}
                                    >
                                        {showAtmColumnOption && (
                                            <Grid item xs={12}>
                                                <RadioInput
                                                    label={
                                                        <Trans i18nKey="atmWeekType">
                                                            ${enUS.atmWeekType}
                                                        </Trans>
                                                    }
                                                    name="rowsOrColumns"
                                                    testId="rowsOrColumns"
                                                    control={control}
                                                    stacked={false}
                                                    rules={{ required: isRequired }}
                                                    disabled={!isEdit}
                                                    radioControls={[
                                                        {
                                                            value: RowsOrColumns.Rows,
                                                            label: (
                                                                <Trans i18nKey="rows">Rows</Trans>
                                                            )
                                                        },
                                                        {
                                                            value: RowsOrColumns.Columns,
                                                            label: (
                                                                <Trans i18nKey="columns">
                                                                    Columns
                                                                </Trans>
                                                            )
                                                        }
                                                    ]}
                                                    error={errors.rowsOrColumns}
                                                    errors={errors}
                                                    defaultValue={
                                                        rowsOrColumns || RowsOrColumns.Rows
                                                    }
                                                />
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <RadioInput
                                                label={
                                                    <Trans i18nKey="atmQuantityType">
                                                        ${enUS.atmQuantityType}
                                                    </Trans>
                                                }
                                                name="quantityType"
                                                testId="quantityType"
                                                control={control}
                                                stacked={false}
                                                rules={{ required: isRequired }}
                                                disabled={!isEdit}
                                                radioControls={[
                                                    {
                                                        value: QuantityType.Pallets,
                                                        label: (
                                                            <Trans i18nKey="pallets">Pallets</Trans>
                                                        )
                                                    },
                                                    {
                                                        value: QuantityType.Eaches,
                                                        label: (
                                                            <Trans i18nKey="eaches">Eaches</Trans>
                                                        )
                                                    }
                                                ]}
                                                error={errors.quantityType}
                                                errors={errors}
                                                defaultValue={quantityType || QuantityType.Pallets}
                                            />
                                            {watchQuantity === QuantityType.Eaches && (
                                                <FormHelperText className={classes.eachesHelpText}>
                                                    <Trans i18nKey="quantityRounded">
                                                        {enUS.quantityRounded}
                                                    </Trans>
                                                </FormHelperText>
                                            )}
                                        </Grid>
                                    </Grid>
                                    {Object.keys(fieldDefinitions).map((fieldKey, index) => {
                                        const field = fieldDefinitions[fieldKey];
                                        const key = fieldKey + '_' + index;
                                        const name = `bulkUploadTemplate[${fieldKey}]`;

                                        if (!field.hide) {
                                            return (
                                                <Grid
                                                    container
                                                    item
                                                    xs={12}
                                                    key={key}
                                                    spacing={2}
                                                    justify="flex-start"
                                                    alignItems="center"
                                                    className={classes.fieldRow}
                                                >
                                                    <Grid item xs={3}>
                                                        <Typography>
                                                            <Trans
                                                                i18nKey={
                                                                    field.translationValue
                                                                        ? field.translationValue
                                                                        : fieldKey
                                                                }
                                                            >
                                                                {field.defaultLabel}
                                                            </Trans>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={1} />
                                                    <Grid item xs={3}>
                                                        <TextInput
                                                            type="text"
                                                            label={
                                                                <Trans i18nKey="columnLetter">
                                                                    Column Letter
                                                                </Trans>
                                                            }
                                                            ariaLabel={t(fieldKey)}
                                                            name={name}
                                                            testId={fieldKey}
                                                            control={control}
                                                            defaultValue={field.defaultValue}
                                                            rules={
                                                                field.required
                                                                    ? {
                                                                          required: isRequired,
                                                                          pattern: /^[a-zA-Z]{1,2}$/
                                                                      }
                                                                    : {
                                                                          pattern: /^[a-zA-Z]{1,2}$/
                                                                      }
                                                            }
                                                            error={
                                                                errors?.bulkUploadTemplate &&
                                                                errors.bulkUploadTemplate[fieldKey]
                                                            }
                                                            errors={errors}
                                                            readOnly={!isEdit}
                                                            data-testid={fieldKey}
                                                        />
                                                        {errors?.bulkUploadTemplate &&
                                                            errors.bulkUploadTemplate[fieldKey] && (
                                                                <FormHelperText
                                                                    className={classes.errorText}
                                                                >
                                                                    {errors.bulkUploadTemplate[
                                                                        fieldKey
                                                                    ] &&
                                                                        errors.bulkUploadTemplate[
                                                                            fieldKey
                                                                        ].type === 'required' && (
                                                                            <ErrorMessage
                                                                                errors={errors}
                                                                                name={name}
                                                                                message={isRequired}
                                                                            />
                                                                        )}
                                                                    {errors.bulkUploadTemplate[
                                                                        fieldKey
                                                                    ] &&
                                                                        errors.bulkUploadTemplate[
                                                                            fieldKey
                                                                        ].type === 'pattern' && (
                                                                            <ErrorMessage
                                                                                errors={errors}
                                                                                name={name}
                                                                                message={
                                                                                    <Trans i18nKey="columnPatternError">
                                                                                        Please enter
                                                                                        1-2 letters
                                                                                    </Trans>
                                                                                }
                                                                            />
                                                                        )}
                                                                </FormHelperText>
                                                            )}
                                                        {!field.required && (
                                                            <FormHelperText>
                                                                This field is not required
                                                            </FormHelperText>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            );
                                        }
                                        return null;
                                    })}
                                    {watchRowsOrColumns === RowsOrColumns.Columns && (
                                        <>
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                spacing={2}
                                                justify="flex-start"
                                                alignItems="center"
                                                className={classes.fieldRow}
                                            >
                                                <Grid item xs={3}>
                                                    <Typography>
                                                        <Trans i18nKey="firstRowOfData">
                                                            First Row of Data
                                                        </Trans>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={1} />
                                                <Grid item xs={3}>
                                                    <TextInput
                                                        type="number"
                                                        label={
                                                            <Trans i18nKey="firstRowOfData">
                                                                Row Number
                                                            </Trans>
                                                        }
                                                        ariaLabel={t('firstRowOfData')}
                                                        name="firstRowOfData"
                                                        testId="firstRowOfData"
                                                        control={control}
                                                        defaultValue={firstRowDefault}
                                                        rules={{
                                                            required: isRequired,
                                                            pattern: /^[0-9]{1,10}$/
                                                        }}
                                                        error={errors.firstRowOfData}
                                                        errors={errors}
                                                        readOnly={!isEdit}
                                                        inputProps={{
                                                            'data-testid': 'firstRowOfData',
                                                            min: 0
                                                        }}
                                                    />
                                                    {errors.firstRowOfData && (
                                                        <FormHelperText
                                                            className={classes.errorText}
                                                        >
                                                            {errors.firstRowOfData &&
                                                                errors.firstRowOfData.type ===
                                                                    'required' && (
                                                                    <ErrorMessage
                                                                        errors={errors}
                                                                        name={'firstRowOfData'}
                                                                        message={isRequired}
                                                                    />
                                                                )}
                                                            {errors.firstRowOfData &&
                                                                errors.firstRowOfData.type ===
                                                                    'pattern' && (
                                                                    <ErrorMessage
                                                                        errors={errors}
                                                                        name={'firstRowOfData'}
                                                                        message={
                                                                            <Trans i18nKey="columnNumberPatternError">
                                                                                Please enter a
                                                                                number
                                                                            </Trans>
                                                                        }
                                                                    />
                                                                )}
                                                        </FormHelperText>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            {currentWeekQuantities &&
                                                currentWeekQuantities.map((field, index) => {
                                                    const fieldName = `weekQuantity[${index}]`;
                                                    let weekNumber = index;
                                                    ++weekNumber;

                                                    return (
                                                        <Grid
                                                            container
                                                            item
                                                            xs={12}
                                                            key={index}
                                                            spacing={2}
                                                            justify="flex-start"
                                                            alignItems="center"
                                                            className={classes.fieldRow}
                                                        >
                                                            <Grid item xs={3}>
                                                                <Typography>
                                                                    <Trans i18nKey="weekNumberQuantity">
                                                                        Week {{ weekNumber }}{' '}
                                                                        Quantity
                                                                    </Trans>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={1} />
                                                            <Grid item xs={3}>
                                                                <TextInput
                                                                    type="text"
                                                                    label={
                                                                        <Trans i18nKey="columnLetter">
                                                                            Column Letter
                                                                        </Trans>
                                                                    }
                                                                    ariaLabel={t(
                                                                        'weekNumberQuantity',
                                                                        { weekNumber: weekNumber }
                                                                    )}
                                                                    name={`${fieldName}.quantity`}
                                                                    testId={`${fieldName}.quantity`}
                                                                    control={control}
                                                                    defaultValue={field.quantity}
                                                                    rules={{
                                                                        required:
                                                                            weekNumber === 1
                                                                                ? isRequired
                                                                                : weekIsRequired,
                                                                        pattern: /^[a-zA-Z]{1,2}$/
                                                                    }}
                                                                    error={
                                                                        errors.weekQuantity &&
                                                                        errors.weekQuantity[
                                                                            index
                                                                        ] &&
                                                                        errors.weekQuantity[index]
                                                                            .quantity
                                                                    }
                                                                    errors={errors}
                                                                    readOnly={!isEdit}
                                                                    data-testid={`${fieldName}.quantity`}
                                                                />
                                                                {errors.weekQuantity &&
                                                                    errors.weekQuantity[index] && (
                                                                        <FormHelperText
                                                                            className={
                                                                                classes.errorText
                                                                            }
                                                                        >
                                                                            {errors.weekQuantity &&
                                                                                errors.weekQuantity[
                                                                                    index
                                                                                ] &&
                                                                                errors.weekQuantity[
                                                                                    index
                                                                                ].quantity &&
                                                                                errors.weekQuantity[
                                                                                    index
                                                                                ].quantity.type ===
                                                                                    'required' && (
                                                                                    <ErrorMessage
                                                                                        errors={
                                                                                            errors
                                                                                        }
                                                                                        name={`${fieldName}.quantity`}
                                                                                        message={
                                                                                            weekIsRequired
                                                                                        }
                                                                                    />
                                                                                )}
                                                                            {errors.weekQuantity &&
                                                                                errors.weekQuantity[
                                                                                    index
                                                                                ] &&
                                                                                errors.weekQuantity[
                                                                                    index
                                                                                ].quantity &&
                                                                                errors.weekQuantity[
                                                                                    index
                                                                                ].quantity.type ===
                                                                                    'pattern' && (
                                                                                    <ErrorMessage
                                                                                        errors={
                                                                                            errors
                                                                                        }
                                                                                        name={`${fieldName}.quantity`}
                                                                                        message={
                                                                                            <Trans i18nKey="columnPatternError">
                                                                                                Please
                                                                                                enter
                                                                                                1-2
                                                                                                letters
                                                                                            </Trans>
                                                                                        }
                                                                                    />
                                                                                )}
                                                                        </FormHelperText>
                                                                    )}
                                                            </Grid>
                                                            {weekNumber !== 1 && (
                                                                <Grid
                                                                    container
                                                                    item
                                                                    xs={1}
                                                                    className={
                                                                        !isEdit
                                                                            ? classes.removeWeekDisabled
                                                                            : ''
                                                                    }
                                                                >
                                                                    <Button
                                                                        variant="text"
                                                                        color="primary"
                                                                        data-testid="remove-week-button"
                                                                        className={
                                                                            isEdit
                                                                                ? classes.removeWeek
                                                                                : ''
                                                                        }
                                                                        onClick={() =>
                                                                            handleRemoveWeek(index)
                                                                        }
                                                                        disabled={!isEdit}
                                                                    >
                                                                        {isEdit ? (
                                                                            <img
                                                                                src={
                                                                                    process.env
                                                                                        .PUBLIC_URL +
                                                                                    '/assets/Trash.svg'
                                                                                }
                                                                                alt="Delete Week Icon"
                                                                            />
                                                                        ) : (
                                                                            <img
                                                                                src={
                                                                                    process.env
                                                                                        .PUBLIC_URL +
                                                                                    '/assets/DisabledTrashIcon.svg'
                                                                                }
                                                                                alt="Disabled Trash Icon"
                                                                            />
                                                                        )}
                                                                    </Button>
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    );
                                                })}
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                spacing={2}
                                                alignItems="center"
                                                className={clsx(
                                                    [
                                                        classes.fieldRow,
                                                        classes.addWeekQuantityWrapper
                                                    ],
                                                    { [classes.addWeekDisabled]: !isEdit }
                                                )}
                                            >
                                                {isEdit ? (
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL +
                                                            '/assets/Circle_Add.svg'
                                                        }
                                                        alt="Enabled Add Week Icon"
                                                    />
                                                ) : (
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL +
                                                            '/assets/Circle_Add_Disabled.svg'
                                                        }
                                                        alt="Disabled Add Week Icon"
                                                    />
                                                )}
                                                <Button
                                                    variant="text"
                                                    color="primary"
                                                    data-testid="add-week-quantity-button"
                                                    className={classes.addWeek}
                                                    disabled={!isEdit}
                                                    onClick={() => handleAddWeek()}
                                                >
                                                    <Trans i18nKey="addAdditionalWeek">
                                                        Add Additional Week
                                                    </Trans>
                                                </Button>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </Container>
                        <Container>
                            {isSubmitting && (
                                <Grid container item xs={12} className={classes.spinningLoader}>
                                    <CircularProgress />
                                </Grid>
                            )}
                            {templateError && (
                                <Grid container item xs={12}>
                                    <Typography className={classes.errorText}>
                                        <Trans i18nKey="formSubmissionError">
                                            ${enUS.formSubmissionError}
                                        </Trans>
                                    </Typography>
                                </Grid>
                            )}
                            {validationError && (
                                <Grid container item xs={12}>
                                    <Typography className={classes.errorText}>
                                        <Trans i18nKey="multipleColumnValidationError">
                                            Column Letters cannot be duplicated. Please review your
                                            entries and resubmit.
                                        </Trans>
                                    </Typography>
                                </Grid>
                            )}
                        </Container>
                        <Container className={classes.buttonContainer}>
                            <Grid container item xs={12} alignItems="center" justify="flex-end">
                                {hasTemplate && isEdit && (
                                    <Grid item xs={3}>
                                        <Button
                                            type="button"
                                            variant="outlined"
                                            color="secondary"
                                            data-testid="reset-button"
                                            onClick={onOpenReset}
                                            className={classes.actionBtn}
                                        >
                                            <Trans i18nKey="reset">Reset</Trans>
                                        </Button>
                                    </Grid>
                                )}
                                {!hasTemplate && isEdit && (
                                    <Grid item xs={3}>
                                        <Button
                                            type="button"
                                            variant="outlined"
                                            color="secondary"
                                            data-testid="clear-button"
                                            onClick={onClear}
                                            className={classes.actionBtn}
                                        >
                                            <Trans i18nKey="clear">Clear</Trans>
                                        </Button>
                                    </Grid>
                                )}
                                {isEdit && (
                                    <Grid item xs={3}>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            data-testid="save-mapping-button"
                                            disabled={isSubmitting}
                                            className={classes.actionBtn}
                                        >
                                            <Trans i18nKey="saveMapping">Save Mapping</Trans>
                                        </Button>
                                    </Grid>
                                )}
                                {hasTemplate && !isEdit && (
                                    <>
                                        <Grid item xs={3}>
                                            <Button
                                                type="button"
                                                variant="outlined"
                                                color="secondary"
                                                data-testid="clear-all-button"
                                                onClick={onClearAll}
                                                className={classes.actionBtn}
                                            >
                                                <Trans i18nKey="clearAll">Clear All</Trans>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Button
                                                type="submit"
                                                color="primary"
                                                variant="contained"
                                                data-testid="edit-mapping-button"
                                                onClick={onEdit}
                                                className={classes.actionBtn}
                                            >
                                                <Trans i18nKey="editMapping">Edit Mapping</Trans>
                                            </Button>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Container>
                    </Grid>
                </form>
            </Collapse>
            <ConfirmationModal
                saveProgress={onReset}
                onClose={onClose}
                logo={false}
                title={<Trans i18nKey="resetMapping">Reset Your Mapping</Trans>}
                subheader={
                    <Trans i18nKey="makeItIntakeResetConfirmation">
                        Are you sure you want to reset? Any changes will be lost.
                    </Trans>
                }
                continueText={<Trans i18nKey="yesReset">Yes, Reset</Trans>}
                cancelText={<Trans i18nKey="no">No</Trans>}
                open={open}
                navigationLink=""
                onCancel={onClose}
            />
        </FormSection>
    );
};

export default ATMBulkUploadMappingTemplate;
