import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class EditProductionOrderConfirmationColumns {
    static getEditProductionOrderConfirmationColumns = (culture: string) => {
        return [
            createColumn('displayId', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'productGraphicId')
            }),
            createColumn('displayName', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'descriptionCharacteristics')
            }),
            createColumn('productionOrderLineId', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('productDescription', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productSku', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productName', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productCoating', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'coating')
            }),
            createColumn('originalRequestedDate', {
                dataType: ColumnDataType.Date,
                label: TranslationService.getTranslatedText(culture, 'originalRequestedDate')
            }),
            createColumn('requestedDate', {
                dataType: ColumnDataType.Date,
                label: TranslationService.getTranslatedText(culture, 'revisedRequestedDate')
            }),
            createColumn('originalPalletQuantity', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'originalQuantityOrdered')
            }),
            createColumn('palletQuantity', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'revisedQuantityOrdered')
            }),
            createColumn('productUnits', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productSize', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('productShape', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productNeckSize', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productGraphicId', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productTabColor', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productType', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productEndProfile', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('customerProductId', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('customerProductName', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('isLineRemoved', {
                dataType: ColumnDataType.Boolean,
                visible: false
            }),
            createColumn('quantityPerPallet', {
                dataType: ColumnDataType.Numeric,
                visible: false
            })
        ];
    };
}
