import {
    Button,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    Grid,
    makeStyles,
    Typography
} from '@material-ui/core';
import React, { ReactElement } from 'react';
import { Controller, ErrorMessage, FormContext, useFieldArray, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { OnboardingDashboardState } from '../../../store/reducers/onboarding-dashboard';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { submitInvitationRequest } from '../../../store/root-actions';
import {
    regularWeight,
    brightGrey,
    medium,
    black,
    blackWeight,
    orange,
    ballBlue,
    red,
    disabledGrey
} from '../../../themes/globalConstants';
import { Persona } from '../../../utility/auth/useSecurity';
import { NCACountries } from '../../../utility/helpers/address-helpers';
import { isValidEmail } from '../../../utility/helpers/user-helpers';
import { Invitations, OnboardingStepStatus } from '../../../utility/services/onboarding-service';
import { enUS } from '../../../utility/translations/locales';
import Modal from './Modal';
import TextInput from './TextInput';

interface Props {
    open: boolean;
    onClose: () => void;
    termsAndConditions?: boolean;
}

const useStyles = makeStyles((theme) => ({
    modal: {
        '& .MuiPaper-root': {
            maxWidth: '50em'
        },
        '& .MuiDialog-paperScrollPaper': {
            borderRadius: 'unset',
            overflow: 'hidden'
        },
        '& .MuiDialogTitle-root': {
            justifyContent: 'left'
        }
    },
    content: {
        letterSpacing: '1px',
        color: brightGrey,
        padding: '2em'
    },
    text: {
        fontSize: medium,
        fontWeight: regularWeight,
        color: black
    },
    disabledText: {
        fontSize: medium,
        fontWeight: regularWeight,
        color: disabledGrey
    },
    errorText: {
        color: red
    },
    inviteeWrapper: {
        margin: '1.5em 0em 1em 0em',
        width: '45em'
    },
    removeIcon: {
        color: orange,
        paddingTop: '2em',
        '&:hover': {
            color: orange,
            cursor: 'pointer'
        }
    },
    addAnother: {
        backgroundColor: 'transparent',
        outline: 'none',
        fontWeight: blackWeight,
        fontSize: medium,
        textDecoration: 'underline',
        '&:hover': {
            color: ballBlue,
            cursor: 'pointer'
        }
    },
    actionBtn: {
        height: '3.375em',
        width: '10em'
    }
}));

export default function OnboardingInviteUserModal({ open, onClose, termsAndConditions }: Props) {
    const { accountStatus, billingAndShipping } = useTypedSelector<OnboardingDashboardState>(
        (state) => state.onboardingDashboardState
    );
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const isRequired = <Trans i18nKey="required">Required</Trans>;
    const maxInvitees = 4;
    const defaultInvitees = [
        {
            email: '',
            termsAndConditions: false,
            billingAndShipping: false,
            salesAndUseTax: false,
            creditApplication: false,
            checkboxGroup: false
        }
    ];
    const methods = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        defaultValues: {
            invitees: defaultInvitees
        }
    });
    const control = methods.control;
    const { fields, remove, append } = useFieldArray({
        control,
        name: 'invitees'
    });

    const termsComplete = accountStatus.termsStatus === OnboardingStepStatus.COMPLETE;
    const billingComplete = accountStatus.addressesStatus === OnboardingStepStatus.COMPLETE;
    const taxComplete =
        accountStatus.taxAppStatus === OnboardingStepStatus.COMPLETE ||
        //if billing is already completed
        (billingComplete &&
            billingAndShipping?.billingAndShippingForm?.billingAddress &&
            //disable if billing country is Mexico or Canada
            (billingAndShipping.billingAndShippingForm.billingAddress.country ===
                NCACountries.Mexico ||
                billingAndShipping.billingAndShippingForm.billingAddress.country ===
                    NCACountries.Canada));
    const creditComplete = accountStatus.creditAppStatus === OnboardingStepStatus.COMPLETE;

    const validateCheckboxGroup = (index: number) => {
        return (
            !!methods.getValues(`invitees[${index}].termsAndConditions`) ||
            !!methods.getValues(`invitees[${index}].billingAndShipping`) ||
            !!methods.getValues(`invitees[${index}].salesAndUseTax`) ||
            !!methods.getValues(`invitees[${index}].creditApplication`)
        );
    };

    const updatedSelected = (index: number) => {
        //Remove anything selected if user deselects terms and conditions
        if (!termsComplete && methods.getValues(`invitees[${index}].termsAndConditions`)) {
            methods.setValue(`invitees[${index}].billingAndShipping`, false);
            methods.setValue(`invitees[${index}].salesAndUseTax`, false);
            methods.setValue(`invitees[${index}].creditApplication`, false);
        }
    };

    const handleCloseModal = () => {
        remove();
        methods.reset();
        onClose();
    };

    const onSubmit = (data) => {
        let inviteeRequest = { permissions: [] } as Invitations;
        data.invitees.forEach((invitee) => {
            if (invitee.termsAndConditions) {
                inviteeRequest.permissions.push({
                    username: invitee.email,
                    personaToAdd: Persona.JoiningTermsAndConditions,
                    accountId: accountStatus.accountId
                });
            }
            if (invitee.billingAndShipping) {
                inviteeRequest.permissions.push({
                    username: invitee.email,
                    personaToAdd: Persona.JoiningBillingAndShippingAddresses,
                    accountId: accountStatus.accountId
                });
            }
            if (invitee.salesAndUseTax) {
                inviteeRequest.permissions.push({
                    username: invitee.email,
                    personaToAdd: Persona.JoiningSalesAndUseTax,
                    accountId: accountStatus.accountId
                });
            }
            if (invitee.creditApplication) {
                inviteeRequest.permissions.push({
                    username: invitee.email,
                    personaToAdd: Persona.JoiningCreditApplication,
                    accountId: accountStatus.accountId
                });
            }
        });
        dispatch(submitInvitationRequest(inviteeRequest));
        onClose();
        if (termsAndConditions) {
            history.push('/onboard');
        }
    };

    methods.watch('invitees');

    return (
        <Modal
            open={open}
            close={handleCloseModal}
            title={t('inviteToHelpComplete', enUS.inviteToHelpComplete)}
            closeIcon={true}
            fullWidth={true}
            className={classes.modal}
        >
            <Grid
                container
                justify="flex-start"
                className={classes.content}
                data-testid="invite-user-onboarding-modal"
            >
                <Grid container item xs={12}>
                    <Typography className={classes.text} data-testid="subheader">
                        <Trans i18nKey="inviteModalHelperText">{enUS.inviteModalHelperText}</Trans>
                    </Typography>
                </Grid>
                <FormContext {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
                        {fields.map((field, index) => {
                            const fieldName = `invitees[${index}]`;
                            const termsChecked = !!methods.getValues(
                                `${fieldName}.termsAndConditions`
                            );
                            return (
                                <Grid key={field.id}>
                                    <Grid container className={classes.inviteeWrapper}>
                                        <Grid container item xs={11}>
                                            <TextInput
                                                type="text"
                                                label={<Trans i18nKey="email">Email</Trans>}
                                                name={`${fieldName}.email`}
                                                testId={`${fieldName}.email`}
                                                control={control}
                                                rules={{
                                                    required: isRequired,
                                                    validate: {
                                                        isValidEmail: (value) => {
                                                            return (
                                                                isValidEmail(value) || (
                                                                    <Trans i18nKey="incorrectEmailAddress">
                                                                        Invalid Email Format
                                                                    </Trans>
                                                                )
                                                            );
                                                        }
                                                    }
                                                }}
                                                error={
                                                    methods.errors.invitees &&
                                                    methods.errors.invitees[index] &&
                                                    (methods.errors.invitees[index]
                                                        .email as ReactElement)
                                                }
                                                errors={methods.errors}
                                                defaultValue={''}
                                            />
                                            <FormHelperText className={classes.errorText}>
                                                {methods.errors.invitees &&
                                                    methods.errors.invitees[index] &&
                                                    methods.errors.invitees[index].email && (
                                                        <ErrorMessage
                                                            errors={methods.errors}
                                                            name={`${fieldName}.email`}
                                                        />
                                                    )}
                                            </FormHelperText>
                                        </Grid>
                                        {fields.length > 1 && (
                                            <Grid container item xs={1}>
                                                <Button
                                                    variant="text"
                                                    color="primary"
                                                    data-testid="remove-approver-button"
                                                    className={classes.removeIcon}
                                                    onClick={() => remove(index)}
                                                >
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL +
                                                            '/assets/Trash.svg'
                                                        }
                                                        alt="Trash Icon"
                                                    />
                                                </Button>
                                            </Grid>
                                        )}
                                    </Grid>
                                    <Controller
                                        control={control}
                                        name={`${fieldName}.checkboxGroup`}
                                        error={
                                            methods.errors &&
                                            methods.errors.invitees &&
                                            methods.errors.invitees[index] &&
                                            methods.errors.invitees[index].checkboxGroup
                                        }
                                        errors={methods.errors}
                                        rules={{
                                            validate: () => validateCheckboxGroup(index)
                                        }}
                                        defaultValue={false}
                                        as={
                                            <>
                                                <FormControlLabel
                                                    control={
                                                        <Controller
                                                            as={<Checkbox color="primary" />}
                                                            name={`${fieldName}.termsAndConditions`}
                                                            type="checkbox"
                                                            control={control}
                                                            data-testid="termsAndConditions"
                                                            defaultValue={false}
                                                        />
                                                    }
                                                    label={
                                                        <Typography
                                                            className={
                                                                termsComplete
                                                                    ? classes.disabledText
                                                                    : classes.text
                                                            }
                                                        >
                                                            <Trans i18nKey="accountSetupTCTitle">
                                                                {`Terms & Conditions`}
                                                            </Trans>
                                                        </Typography>
                                                    }
                                                    disabled={termsComplete}
                                                    onClick={() => {
                                                        updatedSelected(index);
                                                    }}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Controller
                                                            as={<Checkbox color="primary" />}
                                                            name={`${fieldName}.billingAndShipping`}
                                                            type="checkbox"
                                                            control={control}
                                                            data-testid="billingAndShipping"
                                                            defaultValue={false}
                                                        />
                                                    }
                                                    label={
                                                        <Typography
                                                            className={
                                                                (
                                                                    termsComplete
                                                                        ? billingComplete
                                                                        : !termsChecked
                                                                )
                                                                    ? classes.disabledText
                                                                    : classes.text
                                                            }
                                                        >
                                                            <Trans i18nKey="billAndShipTitle">
                                                                {`Billing & Shipping`}
                                                            </Trans>
                                                        </Typography>
                                                    }
                                                    disabled={
                                                        termsComplete
                                                            ? billingComplete
                                                            : !termsChecked
                                                    }
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Controller
                                                            as={<Checkbox color="primary" />}
                                                            name={`${fieldName}.salesAndUseTax`}
                                                            type="checkbox"
                                                            control={control}
                                                            data-testid="salesAndUseTax"
                                                            defaultValue={false}
                                                        />
                                                    }
                                                    label={
                                                        <>
                                                            <Typography
                                                                className={
                                                                    (
                                                                        termsComplete
                                                                            ? taxComplete
                                                                            : !termsChecked
                                                                    )
                                                                        ? classes.disabledText
                                                                        : classes.text
                                                                }
                                                            >
                                                                <Trans i18nKey="salesAndUseTitle">
                                                                    {`Sales & Use Tax`}
                                                                </Trans>
                                                            </Typography>

                                                            <Typography
                                                                className={
                                                                    (
                                                                        termsComplete
                                                                            ? taxComplete
                                                                            : !termsChecked
                                                                    )
                                                                        ? classes.disabledText
                                                                        : classes.text
                                                                }
                                                            >
                                                                <Trans i18nKey="usOnly">
                                                                    {'(US Only)'}
                                                                </Trans>
                                                            </Typography>
                                                        </>
                                                    }
                                                    disabled={
                                                        termsComplete ? taxComplete : !termsChecked
                                                    }
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Controller
                                                            as={<Checkbox color="primary" />}
                                                            name={`${fieldName}.creditApplication`}
                                                            type="checkbox"
                                                            control={control}
                                                            data-testid="creditApplication"
                                                            defaultValue={false}
                                                        />
                                                    }
                                                    label={
                                                        <Typography
                                                            className={
                                                                (
                                                                    termsComplete
                                                                        ? creditComplete
                                                                        : !termsChecked
                                                                )
                                                                    ? classes.disabledText
                                                                    : classes.text
                                                            }
                                                        >
                                                            <Trans i18nKey="creditApplicationTitle">
                                                                {'Credit Application'}
                                                            </Trans>
                                                        </Typography>
                                                    }
                                                    disabled={
                                                        termsComplete
                                                            ? creditComplete
                                                            : !termsChecked
                                                    }
                                                />
                                            </>
                                        }
                                    />
                                    <FormHelperText className={classes.errorText}>
                                        {methods.errors.invitees &&
                                            methods.errors.invitees[index] &&
                                            methods.errors.invitees[index].checkboxGroup && (
                                                <ErrorMessage
                                                    errors={methods.errors}
                                                    name={`${fieldName}.checkboxGroup`}
                                                    message={
                                                        <Trans i18nKey="selectAtLeastOne">
                                                            Required to select at least one.
                                                        </Trans>
                                                    }
                                                />
                                            )}
                                    </FormHelperText>
                                </Grid>
                            );
                        })}
                        {fields.length < maxInvitees && (
                            <Grid container item>
                                <img
                                    src={process.env.PUBLIC_URL + '/assets/Circle_Add.svg'}
                                    alt="Add Icon"
                                />
                                <Button
                                    variant="text"
                                    color="primary"
                                    data-testid="add-another-button"
                                    className={classes.addAnother}
                                    onClick={() =>
                                        append({
                                            email: '',
                                            termsAndConditions: false,
                                            billingAndShipping: false,
                                            salesAndUseTax: false,
                                            creditApplication: false
                                        })
                                    }
                                >
                                    <Trans i18nKey="addAnother">Add another</Trans>
                                </Button>
                            </Grid>
                        )}
                        <Grid container className={classes.inviteeWrapper} justify="flex-end">
                            <Grid container item xs={3} justify="flex-end">
                                <Button
                                    type="button"
                                    variant="outlined"
                                    color="secondary"
                                    data-testid="cancel-btn"
                                    className={classes.actionBtn}
                                    onClickCapture={handleCloseModal}
                                >
                                    <Trans i18nKey="cancel">Cancel</Trans>
                                </Button>
                            </Grid>
                            <Grid container item xs={3} justify="flex-end">
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    data-testid="submit-button"
                                    className={classes.actionBtn}
                                >
                                    <Trans i18nKey="submit">Submit</Trans>
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </FormContext>
            </Grid>
        </Modal>
    );
}
