import React, { useEffect, useState } from 'react';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { AuthState } from '../../../store/reducers/auth';
import ContactService from '../../../utility/services/contact-service';
import { Redirect } from 'react-router';
import { CircularProgress } from '@material-ui/core';
import { NavigationalPageTemplate } from '../../templates/NavigationalPageTemplate';
import { createAxiosHeader } from '../../../utility/helpers/axios-helpers';

export default function RegistrationCallback() {
    const { userInfo, accessToken } = useTypedSelector<AuthState>((state) => state.auth);
    const [sentEmail, setSendEmail] = useState(false);

    useEffect(() => {
        if (userInfo?.email) {
            const axiosConfig = createAxiosHeader(
                userInfo.Custom_Language || 'en-US',
                userInfo.Custom_Region || 'NA',
                accessToken
            );
            const message = {
                messageType: 'REG_CONFIRM',
                email: userInfo.email,
                messageData: {
                    firstName: userInfo.given_name,
                    lastName: userInfo.family_name
                }
            };
            ContactService.sendRegistrationConfirmationEmail(message, axiosConfig);
            setSendEmail(true);
        }
    }, [userInfo, accessToken]);

    if (sentEmail) {
        return <Redirect to="/source/" />;
    } else {
        return (
            <NavigationalPageTemplate>
                <CircularProgress />
            </NavigationalPageTemplate>
        );
    }
}
