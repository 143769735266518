import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { makeStyles, Typography, TableRow, TableCell } from '@material-ui/core';
import Button from '../../../reusable/atoms/Button';
import {
    ballLtGray,
    blackWeight,
    regularTimeout,
    regularWeight
} from '../../../../themes/globalConstants';
import { formatNumberWithLocale, numericRegex } from '../../../../utility/helpers/formatters';
import { ProductWithPallets, ShippingState } from '../../../../store/reducers/shipping-dashboard';
import { getGraphicIdAndVersion } from '../../../../utility/helpers/order-helpers';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { resolvePalletCount } from '../../../../utility/helpers/shipment-helpers';
import QuantityInputCell from '../../../reusable/atoms/QuantityInputCell';
import { RegionCultureState } from './../../../../store/reducers/region-culture';
interface Props {
    item: ProductWithPallets;
    onAddItem: (item: ProductWithPallets, numberOfPallets: number, type: string) => void;
    onPalletAdd: (item: ProductWithPallets, numberOfPallets: number) => void;
    onInputBlur: (product: ProductWithPallets, numberOfInputPallets: number) => void;
    hasCreditHold: boolean;
}

const useStyles = makeStyles(() => ({
    tableRowContainer: {
        '& .MuiInputBase-input': {
            textAlign: 'right',
            paddingRight: '.25em'
        },
        '& .MuiTableCell-root': {
            '&:not(:last-child)': {
                paddingLeft: '1.125em'
            }
        },
        '& .normal': {
            fontWeight: `${regularWeight} !important`,
            textAlign: 'right'
        }
    },
    textField: {
        width: '4.25em',
        height: '1.5em',
        border: `2px solid ${ballLtGray}`,
        borderRadius: '.25em',
        marginRight: '0.5em'
    },
    alignCenter: {
        textAlign: 'center'
    },
    alignRight: {
        textAlign: 'right'
    },
    paletteInputContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    addBtn: {
        padding: 0,
        height: '2.28em',
        width: '3.2em'
    },
    paddingLeft: {
        paddingLeft: '.5em'
    },
    eachesInput: {
        flexDirection: 'column-reverse'
    },
    bold: {
        fontWeight: blackWeight
    }
}));

export default function ShipItRow({
    item,
    onAddItem,
    onPalletAdd,
    onInputBlur,
    hasCreditHold
}: Props) {
    const classes = useStyles();
    const { quantityUnit } = useTypedSelector<ShippingState>((state) => state.shippingDashboard);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [pallets, setPallets] = useState(item.inputPallets ? item.inputPallets : 0);
    const [quantity, setQuantity] = useState(resolveQuantity());
    const [tooManyPallets, setTooManyPallets] = useState(false);

    function resolveQuantity() {
        switch (quantityUnit) {
            case 'pallets':
                return item.inputPallets ? item.inputPallets : 0;
            case 'eaches':
                return item.inputEaches ? item.inputEaches : 0;
        }
    }

    const type = item.type!;
    const addItem = () => {
        onAddItem(item, pallets!, type);
        onPalletAdd(item, pallets!);
    };

    const handleQuantityInputChange = (count: string) => {
        if (numericRegex(count)) {
            const parsedCount = parseInt(count);
            if (!isNaN(parsedCount)) {
                const numOfPallets = resolvePalletCount(
                    parsedCount,
                    item.quantityPerPallet!,
                    quantityUnit
                );
                setQuantity(parsedCount);
                setPallets(numOfPallets);
            } else {
                setQuantity(0);
                setPallets(0);
            }
        } else {
            setQuantity(0);
            setPallets(0);
        }
        forceBlur();
    };

    const handleQuantityInputBlur = (count: string) => {
        let numOfPallets = parseInt(count);
        onInputBlur(item, numOfPallets);
    };

    //Forces input to blur after certain amt of time since last keyup
    const forceBlur = () => {
        document.querySelectorAll('#quantity-input').forEach((element) => {
            if (element === document.activeElement) {
                const el = element as HTMLElement;
                let timeout;

                element.addEventListener('keyup', (e) => {
                    clearTimeout(timeout);
                    timeout = setTimeout(() => {
                        return el.blur();
                    }, regularTimeout);
                });
            }
        });
    };
    const checkPallets = useMemo(
        () =>
            (item.availablePallets && pallets > item.availablePallets) ||
            item.availablePallets === 0
                ? true
                : false,
        [item.availablePallets, pallets]
    );
    useEffect(() => {
        setTooManyPallets(checkPallets);
    }, [checkPallets, pallets]);

    useTranslation();

    return (
        <TableRow
            className={classes.tableRowContainer}
            data-testid={`product-${item.productSku}`}
            data-pallet-quantity={`${item.availablePallets}`}
            data-can-type={`${item.type}`}
        >
            <TableCell data-testid="display-id">
                <Typography className={classes.bold}>
                    {item.customerProductId ? item.customerProductId : '-'}
                </Typography>
                <Typography className={classes.bold}>{item.displayId}</Typography>
                <Typography className={classes.bold}>
                    {getGraphicIdAndVersion(item.type, item.graphicIdAndVersion)}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography data-testid="display-name">{item.displayName}</Typography>
                <Typography data-testid="description">{item.description}</Typography>
            </TableCell>
            <TableCell>
                <Typography data-testid="coating">{item.coating}</Typography>
            </TableCell>
            {/* Top # should be pallets available to ship (from API/state) */}
            {/* bottom # should be available pallets * qty per pallet */}
            <TableCell className={classes.alignRight}>
                <Typography data-testid="pallets">
                    {formatNumberWithLocale(cultureCode, Number(item.availablePallets))}
                    {' PL'}
                </Typography>
                <Typography data-testid="eaches">
                    {formatNumberWithLocale(
                        cultureCode,
                        Number(item.availablePallets! * item.availableItemsPerPallet!)
                    )}
                    {' ea.'}
                </Typography>
            </TableCell>
            <TableCell data-testid="quantity-input">
                <QuantityInputCell
                    quantity={quantity}
                    quantityPerPallet={item.quantityPerPallet!}
                    quantityUnit={quantityUnit}
                    onBlur={handleQuantityInputBlur}
                    onChange={handleQuantityInputChange}
                    disabled={hasCreditHold}
                />
            </TableCell>
            <TableCell>
                <Button
                    type="button"
                    variant="outlined"
                    color="primary"
                    onClick={addItem}
                    disabled={pallets === 0 || tooManyPallets}
                    className={classes.addBtn}
                    data-testid="add-product-btn"
                >
                    <Trans i18nKey="add">add</Trans>
                </Button>
            </TableCell>
        </TableRow>
    );
}
