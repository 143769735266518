import {
    Checkbox,
    FormControlLabel,
    FormHelperText,
    Grid,
    makeStyles,
    TextField,
    Typography
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { Controller, ErrorMessage, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { red } from '../../../../themes/globalConstants';
import { enUS } from '../../../../utility/translations/locales';
import FormSection from '../../../reusable/molecules/FormSection';
import RadioInput from '../../../reusable/molecules/RadioInput';
import clsx from 'clsx';
import { salesAndUseTaxSellers } from '../utils/sales-and-use-tax-utilities';
import { OnboardingDashboardState } from '../../../../store/reducers/onboarding-dashboard';
import { useTypedSelector } from '../../../../store/reducers/reducer';

const useStyles = makeStyles(() => ({
    instructionsContainer: {
        '& p': {
            marginBottom: '1em'
        },
        '& h4': {
            width: '100%',
            marginBottom: '1em',
            marginTop: '1em'
        }
    },
    checkboxContainer: {
        marginBottom: '1em'
    },
    radioGroup: {
        marginTop: '1.5em',
        paddingLeft: '0.5em'
    },
    purchaseOrderNumberInput: {
        '& .MuiFilledInput-input': {
            padding: '10px 12px'
        }
    },
    errorText: {
        color: red
    },
    purchaseOrderNumberContainer: {
        position: 'relative',
        marginLeft: '0.25em'
    },
    errorProductOrderNumber: {
        position: 'absolute',
        left: 0
    }
}));

export default function SellerInformation() {
    const classes = useStyles();
    const sellers = salesAndUseTaxSellers;
    const { t } = useTranslation();
    const { salesAndUseTax } = useTypedSelector<OnboardingDashboardState>(
        (state) => state.onboardingDashboardState
    );
    const { control, errors, watch, register, setValue } = useFormContext();
    const watchCheckboxes = sellers.map((seller) => watch(seller.id, false));

    const handleChange = (event) => {
        const id = event.target.name;
        const value: boolean = event.target.checked;
        setValue(id, value);
    };

    const watchCertificateType = watch('certificateType');
    const isRequired = <Trans i18nKey="required">Required</Trans>;

    useEffect(() => {
        if (
            salesAndUseTax.salesAndUseTaxForm &&
            salesAndUseTax.salesAndUseTaxForm.issuedToSeller &&
            salesAndUseTax.salesAndUseTaxForm.issuedToSeller.length
        ) {
            for (let str of salesAndUseTax.salesAndUseTaxForm.issuedToSeller) {
                const match = sellers.find((seller) => seller.name === str);
                if (match) {
                    setValue(match.id, true);
                }
            }
        }
    }, [salesAndUseTax.salesAndUseTaxForm]);

    function validateAtLeastOneChecked() {
        const checkedValues = sellers.map((seller) => watch(seller.id));
        return checkedValues.includes(true);
    }

    return (
        <FormSection
            testId={'seller-information-section'}
            sectionHeader={
                <Trans i18nKey="sellerInformationAnchorText">
                    {enUS.sellerInformationAnchorText}
                </Trans>
            }
        >
            <Grid item xs={12} className={classes.instructionsContainer}>
                <Typography variant="body1">
                    <Trans i18nKey="sellerInformationCheckboxSubheader">
                        {enUS.sellerInformationCheckboxSubheader}
                    </Trans>
                </Typography>
                <Controller
                    as={
                        <>
                            <Grid container>
                                {sellers.map((seller, index) => (
                                    <Grid
                                        key={seller.id}
                                        item
                                        xs={6}
                                        className={classes.checkboxContainer}
                                    >
                                        <Controller
                                            as={
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={watchCheckboxes[index]}
                                                            onChange={handleChange}
                                                            value={seller.name}
                                                            color="primary"
                                                            data-testid={`checkbox-${seller.id}`}
                                                        />
                                                    }
                                                    label={`${seller.name}, ${seller.address}`}
                                                    inputRef={register}
                                                />
                                            }
                                            name={seller.id}
                                            control={control}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    }
                    rules={{
                        validate: () => validateAtLeastOneChecked()
                    }}
                    name="sellerInformationCheckboxGroup"
                    control={control}
                    errors={errors}
                    error={errors.sellerInformationCheckboxGroup}
                    ref={register}
                />
                {errors.sellerInformationCheckboxGroup && (
                    <FormHelperText className={classes.errorText}>
                        <ErrorMessage
                            errors={errors}
                            name="sellerInformationCheckboxGroup"
                            message={t('selectAtLeastOne', 'Required to select at least one.')}
                        />
                    </FormHelperText>
                )}
                <Grid container>
                    <Grid item>
                        <RadioInput
                            styles={classes.radioGroup}
                            label={
                                <Trans i18nKey="typeOfCertificateLabel">
                                    {enUS.typeOfCertificateLabel}
                                </Trans>
                            }
                            name="certificateType"
                            testId="certificateType"
                            control={control}
                            rules={{ required: isRequired }}
                            radioControls={[
                                {
                                    value: 'blanketCertificate',
                                    label: (
                                        <Trans i18nKey="blanketCertificateCheckbox">
                                            {enUS.blanketCertificateCheckbox}
                                        </Trans>
                                    )
                                },
                                {
                                    value: 'singlePurchaseCertificate',
                                    label: (
                                        <Grid container alignItems="center">
                                            <Trans i18nKey="singlePurchaseCheckbox">
                                                {enUS.singlePurchaseCheckbox}
                                            </Trans>
                                            <div className={classes.purchaseOrderNumberContainer}>
                                                <TextField
                                                    type="text"
                                                    name="purchaseOrderNumber"
                                                    className={classes.purchaseOrderNumberInput}
                                                    data-testid="purchaseOrderNumber"
                                                    error={!!errors.purchaseOrderNumber}
                                                    inputRef={register({
                                                        required:
                                                            watchCertificateType ===
                                                            'singlePurchaseCertificate'
                                                    })}
                                                    inputProps={{
                                                        maxLength: 255
                                                    }}
                                                />
                                                {errors.purchaseOrderNumber && (
                                                    <FormHelperText
                                                        className={clsx([
                                                            classes.errorText,
                                                            classes.errorProductOrderNumber
                                                        ])}
                                                    >
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name={'purchaseOrderNumber'}
                                                            message={t('required', enUS.required)}
                                                        />
                                                    </FormHelperText>
                                                )}
                                            </div>
                                        </Grid>
                                    )
                                }
                            ]}
                            error={errors.certificateType}
                            errors={errors}
                            defaultValue=""
                        />
                    </Grid>
                </Grid>
            </Grid>
        </FormSection>
    );
}
