import React, { ReactNode } from 'react';
import { Button as MaterialButton } from '@material-ui/core';

interface Props {
    children?: ReactNode;
    href?: string;
    className?: string;
    onClick?: any;
    type?: 'button' | 'submit' | null;
    color?: any;
    variant?: any;
    disabled?: boolean;
    endIcon?: any;
    startIcon?: any;
    size?: 'small' | 'medium' | 'large' | undefined;
    onClickCapture?: () => void;
    onMouseDown?: (event: any) => void;
}

const Button = (props: Props) => {
    return (
        <MaterialButton
            variant={props.variant}
            color={props.color}
            size={props.size}
            className={props.className}
            onClick={props.onClick}
            type={props.type || 'button'}
            disabled={props.disabled}
            endIcon={props.endIcon}
            startIcon={props.startIcon}
            data-testid={props['data-testid']}
            onClickCapture={props.onClickCapture}
            onMouseDown={props.onMouseDown}
        >
            {props.children}
        </MaterialButton>
    );
};

export default Button;
