import React, { useEffect, useState } from 'react';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import {
    EmeaGraphics,
    Graphics,
    GraphicsDetailStatus,
    Region
} from '../../../../store/reducers/graphic-intake';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { GraphicDashboardState } from '../../../../store/reducers/graphic-dashboard';
import {
    boldWeight,
    blackWeight,
    ballBlue,
    dateOptionInvalidGray,
    small,
    medium
} from '../../../../themes/globalConstants';
import { generateKey } from '../../../../utility/helpers/order-helpers';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import GraphicsCompletedColumns from './GraphicsCompletedColumns';
import GraphicsCompletedRow from './GraphicsCompletedRow';
import { useTranslation } from 'react-i18next';
import { InProgressGraphicForTable } from '../models/InProgressGraphicForTable';
import Modal from '../../../reusable/molecules/Modal';
import GraphicsStatusHistoryModal from './GraphicsStatusHistoryModal';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: '1em'
    },
    tableWrapper: {
        '& .MuiPaper-root': {
            boxShadow: 'unset'
        },
        '& .MuiTable-root': {
            tableLayout: 'fixed'
        },
        '& .MuiToolbar-root': {
            padding: 0,
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                },
                '&:first-of-type ': {
                    paddingLeft: 0
                }
            },
            '& > h2': {
                fontSize: '1.5em',
                fontWeight: boldWeight,
                color: theme.palette.secondary.main,
                paddingLeft: '0.625em'
            },
            '& .MuiInputBase-formControl': {
                border: `1px solid ${dateOptionInvalidGray}`,
                borderRadius: '.125em',
                '&:before': {
                    display: 'none'
                },
                '&:after': {
                    display: 'none'
                },
                '& svg': {
                    height: '.875em',
                    width: '.875em',
                    fill: ballBlue
                },
                '& .MuiIconButton-root': {
                    '&:hover': {
                        backgroundColor: 'transparent !important'
                    }
                },
                '& .MuiInput-input': {
                    paddingLeft: '.25em'
                }
            }
        },
        '& .MuiTableSortLabel-root': {
            fontSize: small,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em',
            width: '99%',
            justifyContent: 'space-between',
            '& .MuiSvgIcon-root': {
                fontSize: medium,
                opacity: '1'
            }
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                '&:nth-child(1)': {
                    width: '25%'
                },
                '&:nth-child(2)': {
                    width: '75%',
                    fontSize: small,
                    fontWeight: blackWeight,
                    textTransform: 'uppercase',
                    color: theme.palette.secondary.main,
                    borderRadius: '0.25em',
                    paddingLeft: '0.5em',
                    justifyContent: 'space-between'
                }
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    }
}));

const GraphicsCompletedTab = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { cultureCode, regionCode } = useTypedSelector<RegionCultureState>(
        (state) => state.regionCulture
    );
    const [columns, setColumns] = useState<any>([]);
    const [graphicsForTable, setGraphicsForTable] = useState<InProgressGraphicForTable[]>([]);
    const [statusHistoryModalOpen, setStatusHistoryModalOpen] = useState<boolean>(false);
    const [selectedStatusHistory, setselectedStatusHistory] = useState<
        GraphicsDetailStatus[] | undefined
    >(undefined);
    const { completedGraphics } = useTypedSelector<GraphicDashboardState>(
        (state) => state.graphicDashboard
    );

    const toolbarTitle =
        regionCode === Region.EU
            ? t('recentlyCompletedEmea', 'Recently Completed') +
              ' (' +
              t('lastThreeSixtyFive', 'Last 365 days') +
              ')'
            : t('recentlyCompleted', 'Recently Completed') +
              ' (' +
              t('lastNinety', 'Last 90 days') +
              ')';

    const options = new ToolbarOptions({
        title: toolbarTitle,
        advancePagination: false,
        exportButton: false,
        printButton: false
    });

    const CompletedRowComponent = ({ row }: { row: Partial<InProgressGraphicForTable> }) => {
        if (row) {
            return (
                <GraphicsCompletedRow
                    graphic={row as InProgressGraphicForTable}
                    key={row.graphicsId}
                    onStatusHistoryLookup={handleStatusHistoryLookupOpen}
                />
            );
        } else {
            return null;
        }
    };

    useEffect(() => {
        setColumns(GraphicsCompletedColumns.getGraphicsCompletedColumns(cultureCode, regionCode));
    }, [cultureCode, regionCode]);

    const handleStatusHistoryLookupOpen = (graphicsId: string | undefined) => {
        if (regionCode === Region.EU && graphicsId) {
            const selectedGraphic = completedGraphics.find(
                (graphic) => graphic.graphicsId === graphicsId
            );
            if (selectedGraphic) {
                setselectedStatusHistory((selectedGraphic as EmeaGraphics).graphicStatusHistory);
                setStatusHistoryModalOpen(true);
            }
        }
    };

    const handleStatusHistoryLookupClose = () => {
        setStatusHistoryModalOpen(false);
    };

    useEffect(() => {
        const getSortedGraphics = (): Graphics[] | EmeaGraphics[] => {
            if (regionCode === Region.NA) {
                return completedGraphics.sort((a, b) => (a.graphicsId! > b.graphicsId! && 1) || -1);
            } else if (regionCode === Region.EU) {
                return (completedGraphics as EmeaGraphics[]).sort(
                    (a, b) => (a.designName! > b.designName! && 1) || -1
                );
            }
            return completedGraphics;
        };

        const graphics: InProgressGraphicForTable[] = [];
        const sortedGraphics = getSortedGraphics();
        sortedGraphics.forEach((completedGraphic, index) => {
            let graphic: InProgressGraphicForTable;
            graphic = { ...completedGraphic };
            if (regionCode === Region.NA) {
                const completedGraphicNA = completedGraphic as Graphics;
                if (completedGraphicNA.displayStatus && completedGraphicNA.statusDate) {
                    graphic.statuses = [];
                    graphic.statuses.push({
                        displayStatus: completedGraphicNA.displayStatus,
                        iterationDate: completedGraphicNA.statusDate
                    });
                }
                graphic.searchableStatus = completedGraphicNA.displayStatus;
            } else if (regionCode === Region.EU) {
                const completedGraphicEMEA = completedGraphic as EmeaGraphics;
                graphic.requestName = completedGraphicEMEA.designName;
                graphic.graphicsNumber = completedGraphicEMEA.designId ?? t('pending', 'Pending');
                graphic.statuses = completedGraphicEMEA.graphicCurrentStatuses;
                graphic.searchableStatus = completedGraphicEMEA.graphicCurrentStatuses
                    ?.map((currentStatus) => currentStatus.displayStatus)
                    .join(' ');
            }
            if (!graphic.statuses?.length) graphic.statuses = [{ status: 'N/A', statusId: 0 }];
            graphic.sortIndex = index;
            graphics.push(graphic);
        });

        setGraphicsForTable(graphics);
    }, [completedGraphics, regionCode, t]);

    return (
        <Paper elevation={2} className={classes.paper}>
            <Grid className={classes.tableWrapper} key={generateKey('graphics-completed-tab')}>
                <DataGrid
                    gridName="Recently Completed Graphics"
                    columns={columns}
                    dataSource={graphicsForTable}
                    rowComponent={CompletedRowComponent}
                    toolbarOptions={options}
                ></DataGrid>
            </Grid>
            <Modal
                open={statusHistoryModalOpen}
                close={handleStatusHistoryLookupClose}
                title={t('graphicStatusHistory', 'Graphic Status History')}
                closeIcon={true}
                fullWidth={true}
                maxWidth="md"
            >
                {selectedStatusHistory && (
                    <GraphicsStatusHistoryModal
                        selectedStatusHistory={selectedStatusHistory}
                        onClose={handleStatusHistoryLookupClose}
                    />
                )}
            </Modal>
        </Paper>
    );
};

export default GraphicsCompletedTab;
