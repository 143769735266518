import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class UserAdminPendingRequestsColumns {
    static getPendingRequestsColumns = (culture: string, hasAdminPersona: boolean = false) => {
        return [
            createColumn('userName', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'email'),
                sortable: true,
                searchable: true
            }),
            createColumn('roles', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'roles'),
                visible: true
            }),
            createColumn('location', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'location'),
                sortable: true,
                visible: true,
                searchable: true
            }),
            createColumn('sentOn', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'sentOn'),
                sortable: true,
                visible: true,
                searchable: true
            }),
            createColumn('actions', {
                label: TranslationService.getTranslatedText(culture, 'actions'),
                visible: hasAdminPersona
            }),
            createColumn('accountId', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            }),
            createColumn('locationAddress', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            }),
            createColumn('accountName', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            }),
            createColumn('requestId', {
                dataType: ColumnDataType.Numeric,
                visible: false,
                searchable: false
            }),
            createColumn('currentUserHasAdminPermission', {
                dataType: ColumnDataType.Boolean,
                visible: false,
                searchable: false
            })

        ];
    };
}
