import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import {
    productPortfolioClearFacets,
    loadProductPortfolioFacets
} from '../../../../store/root-actions';
import Link from '../../../reusable/atoms/Link';
import Facet from './Facet';
import { ProductPortfolioState } from '../../../../store/reducers/product-portfolio';
import { RegionCultureState } from '../../../../store/reducers/region-culture';

const useStyles = makeStyles((theme) => ({
    container: {
        borderRadius: 1
    },
    header: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'space-between',
        height: '2.5em'
    },
    link: {
        padding: '0',
        cursor: 'pointer',
        fontSize: 14,
        textDecoration: 'underline',
        color: theme.palette.primary.main,
        '&:hover': {
            textDecoration: 'underline',
            color: theme.palette.primary.dark
        }
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '1em'
    },
    contentTitle: {
        textTransform: 'uppercase',
        color: theme.palette.secondary.main,
        fontSize: 14
    }
}));

export function shouldAutoExpand(facetCount: number, index: number) {
    return facetCount <= 3 || index <= 1;
}

const Facets = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { facets, initialResults } = useTypedSelector((state) => state.productPortfolio);
    const regionCulture = useTypedSelector<RegionCultureState>((store) => store.regionCulture);
    const productCulture = useTypedSelector<ProductPortfolioState>(
        (store) => store.productPortfolio
    );

    // Filter facet style selection to only those with products to display
    const filteredFacets = useMemo(() => {
        if (!(facets.styles && initialResults)) return facets;

        const allProducts = [...initialResults];
        const distinctStyleCodes = new Set(allProducts?.map((r) => r.style?.styleCode));
        const styles = facets.styles.filter((style) => {
            return distinctStyleCodes.has(style.facetCode);
        });
        const filteredFacets = { ...facets, styles };

        return filteredFacets;
    }, [initialResults, facets]);

    function getFacetSection(key: string) {
        return (
            filteredFacets[key] && (
                <Facet
                    translatedCategory={<Trans i18nKey={`${key}Category`}>{key}</Trans>}
                    category={key}
                    value={filteredFacets[key]}
                    key={`${regionCulture.cultureCode}${key}`}
                    defaultExpanded={true}
                    tooltip={<Trans i18nKey={`${key}Tooltip`}>Tooltip</Trans>}
                />
            )
        );
    }

    const facetSections = () => {
        return (
            <>
                {getFacetSection('sizes')}
                {getFacetSection('styles')}
                {getFacetSection('features')}
                {getFacetSection('ends')}
            </>
        );
    };

    useEffect(() => {
        if (regionCulture.regionCode !== productCulture.regionCode) {
            dispatch(
                loadProductPortfolioFacets(
                    regionCulture.regionCode,
                    regionCulture.cultureCode,
                    false
                )
            );
        }
    }, [
        dispatch,
        regionCulture.regionCode,
        regionCulture.cultureCode,
        productCulture.regionCode,
        productCulture.cultureCode
    ]);

    const handleClick = () => {
        dispatch(productPortfolioClearFacets());
    };

    useTranslation();

    return (
        <section>
            <Grid className={classes.container}>
                <Grid item className={classes.content}>
                    <Typography variant="subtitle2" className={classes.contentTitle}>
                        <Trans i18nKey="filterBy">Filter By</Trans>:
                    </Typography>
                    <Link className={classes.link} onClick={handleClick}>
                        <Trans i18nKey="clearAll">Clear All</Trans>
                    </Link>
                </Grid>
                <Grid item>{facetSections()}</Grid>
            </Grid>
        </section>
    );
};

export default Facets;
