import React from 'react';
import { RoleAccessKey, ShipToRoleAccess } from '../models/role-access.model';
import { LocationRoleAccessActions } from './actions';

type Action =
    | {
          type: LocationRoleAccessActions.UPDATE_ROLE_ACCESS;
          data: { accountId: string; role: RoleAccessKey; accessGranted: boolean };
      }
    | {
          type: LocationRoleAccessActions.UPDATE_ACCOUNTS;
          accounts: Array<ShipToRoleAccess>;
      }
    | {
          type: LocationRoleAccessActions.GRANT_ALL_ROLE_ACCESS;
          data: { accountIds: Array<string>; roleKey: RoleAccessKey };
      }
    | {
          type: LocationRoleAccessActions.REVOKE_ALL_ROLE_ACCESS;
          data: { accountIds: Array<string>; roleKey: RoleAccessKey };
      };
type Dispatch = (action: Action) => void;
type State = { accounts: Array<ShipToRoleAccess> };
type LocationRoleAccessProviderProps = { children: React.ReactNode; value?: State };

const LocationRoleAccessStateContext = React.createContext<State | undefined>(undefined);
const LocationRoleAccessDispatchContext = React.createContext<Dispatch | undefined>(undefined);

function locationRoleAccessReducer(state: State, action: Action) {
    switch (action.type) {
        case LocationRoleAccessActions.UPDATE_ROLE_ACCESS: {
            const accounts = [...state.accounts];
            const { role, accountId, accessGranted } = action.data;
            const account = accounts.find((a) => a.accountId === accountId);
            if (account) {
                account[role] = accessGranted;
            }
            return {
                ...state,
                accounts: accounts
            };
        }
        case LocationRoleAccessActions.GRANT_ALL_ROLE_ACCESS: {
            const accountIds = action.data.accountIds;
            const roleKey = action.data.roleKey;
            const accounts = [...state.accounts].map((account) => {
                if (accountIds.includes(account.accountId)) {
                    account[roleKey] = true;
                }
                return account;
            });
            return {
                ...state,
                accounts: accounts
            };
        }
        case LocationRoleAccessActions.REVOKE_ALL_ROLE_ACCESS: {
            const accountIds = action.data.accountIds;
            const roleKey = action.data.roleKey;
            const accounts = [...state.accounts].map((account) => {
                if (accountIds.includes(account.accountId)) {
                    account[roleKey] = false;
                }
                return account;
            });
            return {
                ...state,
                accounts: accounts
            };
        }
        case LocationRoleAccessActions.UPDATE_ACCOUNTS: {
            return {
                ...state,
                accounts: action.accounts
            };
        }
        default: {
            throw new Error(`Unhandled action type: ${(action as Action).type}`);
        }
    }
}
function LocationRoleAccessProvider({ children, value }: LocationRoleAccessProviderProps) {
    const [initialState, dispatch] = React.useReducer(
        locationRoleAccessReducer,
        value || { accounts: [] }
    );
    return (
        <LocationRoleAccessStateContext.Provider value={initialState}>
            <LocationRoleAccessDispatchContext.Provider value={dispatch}>
                {children}
            </LocationRoleAccessDispatchContext.Provider>
        </LocationRoleAccessStateContext.Provider>
    );
}
function useLocationRoleAccessState() {
    const context = React.useContext(LocationRoleAccessStateContext);
    if (context === undefined) {
        throw new Error(
            'useLocationRoleAccessState must be used within a LocationRoleAccessProvider'
        );
    }
    return context;
}
function useLocationRoleAccessDispatch() {
    const context = React.useContext(LocationRoleAccessDispatchContext);
    if (context === undefined) {
        throw new Error(
            'useLocationRoleAccessDispatch must be used within a LocationRoleAccessProvider'
        );
    }
    return context;
}
function useLocationRoleAccess(): [State, Dispatch] {
    return [useLocationRoleAccessState(), useLocationRoleAccessDispatch()];
}
const withLocationRoleAccessProvider = (Component) => (props) => (
    <LocationRoleAccessProvider>
        <Component {...props} />
    </LocationRoleAccessProvider>
);
export {
    LocationRoleAccessProvider,
    withLocationRoleAccessProvider,
    useLocationRoleAccessState,
    useLocationRoleAccessDispatch,
    useLocationRoleAccess
};
