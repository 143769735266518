import { ColumnDataType, createColumn } from 'tubular-common';
import { Region } from '../../../../store/reducers/graphic-intake';
import TranslationService from '../../../../utility/services/translation-service';

export default class GraphicsDraftColumns {
    static getGraphicsDraftColumns = (culture: string, region: string) => {
        return [
            createColumn('sortIndex', {
                searchable: false,
                sortable: false,
                filterable: false,
                visible: false,
                dataType: ColumnDataType.Numeric
            }),
            createColumn('graphicsId', {
                visible: false
            }),
            createColumn('requestName', {
                searchable: true,
                filterable: true,
                sortable: true,
                label:
                    region === Region.EU
                        ? TranslationService.getTranslatedText(culture, 'graphicName')
                        : TranslationService.getTranslatedText(culture, 'submissionName'),
                dataType: ColumnDataType.String
            }),
            createColumn('availableActions', {
                label:
                    region === Region.EU
                        ? TranslationService.getTranslatedText(culture, 'availableActions')
                        : TranslationService.getTranslatedText(culture, 'actions'),
                searchable: true,
                filterable: true,
                dataType: ColumnDataType.String
            })
        ];
    };
}
