import React from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../../store/root-actions';
import { useOktaAuth } from '@okta/okta-react';
import Link from '../atoms/Link';
import { HeaderContent } from '../../../store/reducers/header-content';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { AuthState } from '../../../store/reducers/auth';

interface Props {
    className?: string;
    position: string;
}

const LogoutLink = ({ className, position }: Props) => {
    const dispatch = useDispatch();
    const { authService } = useOktaAuth();
    const headerContent = useTypedSelector<HeaderContent>((state) => state.headerContent);
    const auth = useTypedSelector<AuthState>((state) => state.auth);

    const logoutAction = () => {
        dispatch(logout(authService, auth.accessToken));
    };

    return (
        <Link
            id={`logout_${position}`}
            underline="none"
            component="button"
            aria-label="profile-button"
            className={className}
            onClick={logoutAction}
        >
            {headerContent.content?.logout}
        </Link>
    );
};

export default LogoutLink;
