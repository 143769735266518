import {
    CONTENT_LOADING,
    CONTENT_LOADED,
    CONTENT_LOADING_ERROR
} from '../actions/action-types';

export interface ContentTemplate extends ContentBanner {
    type?: string;
    sections: ContentSection[];
    subPages?: ContentSubPage[];
}

export interface ContentSubPage extends ContentBanner {
    type?: string;
    sections: ContentSection[];
}

export interface ContentSection extends SectionBody {
    type: string;
    image?: Image;
    subsections?: ContentSubSection[];
}

export interface ContentSubSection extends SectionBody {}

interface SectionBody {
    header: string;
    description: string;
    paragraph?: string;
    paragraph1?: string;
    paragraph2?: string;
    links?: Link[];
    icon?: string;
    videoUrl?: any;
    image?: Image;
}

export interface ContentBanner {
    header: string;
    description: string;
    image?: Image;
    navigation?: Navigation;
    links?: Link[];
}

export interface Image {
    imageUrl: string;
    alternateText?: string;
    id?: string;
}

interface Navigation {
    link: Link;
}

export interface Link {
    linkText: string;
    linkUrl: string;
    destination?: string;
    icon?: string;
    action?: string;
}

export interface ContentTemplateState {
    loading: boolean;
    error: string;
    contentTemplates: ContentTemplate;
}

const initialState: ContentTemplateState = {
    loading: false,
    error: '',
    contentTemplates: {
        header: '',
        description: '',
        sections: [
            {
                type: '',
                header: '',
                description: ''
            }
        ],
        subPages: [
        ]
    }
};

const contentPage = (state: ContentTemplateState = initialState, action: any) => {
    switch (action.type) {
        case CONTENT_LOADED:
            return {
                ...state,
                loading: false,
                error: '',
                contentTemplates: action.contentTemplates
            };
        case CONTENT_LOADING:
            return { ...state, loading: true, error: '', contentTemplates: initialState.contentTemplates };
        case CONTENT_LOADING_ERROR:
            return { ...state, loading: false, error: action.error, contentTemplates: {} };
        default:
            return state;
    }
};

export default contentPage;
