import React from 'react';
import { DeliverToAccount } from '../../../store/reducers/customer-context';
import DeliverToAccountItem from './DeliverToAccountItem';

interface Props {
    deliverToAccounts: DeliverToAccount[];
    filterText: string;
    selectedAccountId?: string;
}

const DeliverToAccounts = ({ deliverToAccounts, filterText, selectedAccountId }: Props) => {
    return (
        <>
            {deliverToAccounts &&
                deliverToAccounts.map((account, i) => (
                    <DeliverToAccountItem
                        key={i}
                        deliverToAccount={account}
                        filterText={filterText}
                        selectedAccountId={selectedAccountId}
                    />
                ))}
        </>
    );
};

export default DeliverToAccounts;
