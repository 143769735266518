import { Grid, FormHelperText, makeStyles, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
    medium,
    ltBlueGrey_34,
    xl,
    boldWeight,
    red,
    warningLabel
} from '../../../../themes/globalConstants';
import TextInput from '../../../reusable/molecules/TextInput';
import SelectInput from '../../../reusable/molecules/SelectInput';
import FormSection from '../../../reusable/molecules/FormSection';
import { EmeaGraphics } from '../../../../store/reducers/graphic-intake';
import { useFormContext, ErrorMessage, Controller } from 'react-hook-form';
import { emeaCanSize, emeaCanType, emeaMetal } from '../../../../static-data/dropdown-lists';
import { Autocomplete } from '@material-ui/lab';
import { Account } from '../../../../store/reducers/customer-context';

interface Props {
    isEditDraft: boolean;
    graphicData?: EmeaGraphics;
    companyDropdown: Account[];
    cantSaveDraft?: boolean;
}

const useStyles = makeStyles((theme) => ({
    subsectionHeader: {
        fontSize: xl,
        fontWeight: boldWeight,
        color: theme.palette.info.dark,
        marginBottom: '2em'
    },
    label: {
        color: theme.palette.info.dark,
        fontSize: medium,
        marginBottom: '0.5em'
    },
    radioGroup: {
        marginTop: '1.5em',
        paddingLeft: '0.5em'
    },
    divider: {
        height: 5,
        backgroundColor: ltBlueGrey_34,
        margin: '1.5em 0'
    },
    helperText: {
        '& .MuiFormHelperText-root': {
            marginLeft: 0
        }
    },
    warningContainer: {
        display: 'flex',
        backgroundColor: warningLabel,
        margin: '1em 1.5em 1em 0.5em'
    },
    warningIconCtn: {
        textAlign: 'center'
    },
    warningIcon: {
        fill: red
    },
    warningLabel: {
        fontSize: medium,
        fontWeight: boldWeight
    },
    capitalize: {
        textTransform: 'capitalize'
    },
    errorText: {
        color: red
    }
}));

const GeneralInfo = ({ cantSaveDraft, isEditDraft, graphicData, companyDropdown }: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { control, errors, setValue, setError } = useFormContext();
    const [selectedCompany, setSelectedCompany] = useState<Account | null>(null);

    useEffect(() => {
        if (isEditDraft && graphicData) {
            if (graphicData.customerAccount)
                handleLoadDraftAccountInformation(graphicData.customerAccount);
            setValue('contractFiller', graphicData.contractFiller);
            setValue('brandOwner', graphicData.brandOwner);
            setValue('canSize', graphicData.canSize);
            setValue('canType', graphicData.canType);
            setValue('metalType', graphicData.metalType);
        }
    }, [isEditDraft, graphicData, companyDropdown, setValue]);

    const handleLoadDraftAccountInformation = (customerAccountId: string | undefined) => {
        let account = companyDropdown.find(
            (company) => company.accountId.toString() === customerAccountId?.toString()
        );

        if (account) {
            setSelectedCompany(account);
            setValue('customerSelected', account);
        }
    };
    useEffect(() => {
        if (cantSaveDraft) {
            setError('customerSelected', { type: 'required' });
        }
    }, [cantSaveDraft]);

    const handleUpdateAccountInformation = (event: any, updatedSelection: Account | null) => {
        setSelectedCompany(updatedSelection);
    };

    const isRequired = <Trans i18nKey="required">Required</Trans>;

    return (
        <FormSection
            testId={'general-information-section'}
            sectionHeader={<Trans i18nKey="graphicsSubInfo">General Information</Trans>}
        >
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                    <Controller
                        as={
                            <Autocomplete
                                id="customer-name"
                                options={companyDropdown}
                                getOptionLabel={(option) => {
                                    if (option) {
                                        return `${option.name} - ${option.accountId}`;
                                    }
                                    return '';
                                }}
                                value={selectedCompany}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t('companyId', 'Company Name and ID')}
                                    />
                                )}
                            />
                        }
                        onChange={([event, value]) => {
                            handleUpdateAccountInformation(event, value);
                            return value;
                        }}
                        name="customerSelected"
                        testId="customerSelected"
                        control={control}
                        error={errors.customerSelected}
                        errors={errors}
                        rules={{ required: isRequired }}
                        defaultValue={null}
                    />
                    {!errors.customerSelected && (
                        <FormHelperText>
                            <Trans i18nKey="customerAccountHelper">
                                Name of Company and ID that needs these graphics{' '}
                            </Trans>
                        </FormHelperText>
                    )}
                    <FormHelperText className={classes.errorText}>
                        {errors.customerSelected && (
                            <ErrorMessage
                                errors={errors}
                                name={'customerSelected'}
                                message={isRequired}
                            />
                        )}
                    </FormHelperText>
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                    <TextInput
                        type="text"
                        label={<Trans i18nKey="contractFiller">Contract Filler</Trans>}
                        name="contractFiller"
                        testId="contractFiller"
                        control={control}
                        rules={''}
                        error={errors.contractFiller}
                        errors={errors}
                        styles={classes.helperText}
                        helperText={
                            <Trans i18nKey="contractFillerHelper">
                                Select your Contract Filler location, if different than Ball's
                                trading partner{' '}
                            </Trans>
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        type="text"
                        label={<Trans i18nKey="brandOwner">Brand Owner</Trans>}
                        name="brandOwner"
                        testId="brandOwner"
                        control={control}
                        rules={''}
                        error={errors.brandOwner}
                        errors={errors}
                        defaultValue={''}
                        styles={classes.helperText}
                        helperText={
                            <Trans i18nKey="brandOwnerHelper">
                                Owner of the Brand, if different than Ball's trading partner{' '}
                            </Trans>
                        }
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                    <SelectInput
                        label={<Trans i18nKey="canSize">Can Size</Trans>}
                        name="canSize"
                        testId="canSize"
                        control={control}
                        rules={{ required: isRequired }}
                        error={errors.canSize}
                        errors={errors}
                        selectObj={emeaCanSize}
                        helperText={<Trans i18nKey="canSizeHelper">Select the can volume </Trans>}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SelectInput
                        label={<Trans i18nKey="canType">Can Type</Trans>}
                        name="canType"
                        testId="canType"
                        control={control}
                        rules={{ required: isRequired }}
                        error={errors.canType}
                        errors={errors}
                        selectObj={emeaCanType}
                        helperText={
                            <Trans i18nKey="canTypeHelper">
                                Select the type of can body and neck diameter{' '}
                            </Trans>
                        }
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                    <SelectInput
                        label={<Trans i18nKey="metalType">Metal</Trans>}
                        name="metalType"
                        testId="metalType"
                        control={control}
                        rules={{ required: isRequired }}
                        error={errors.metalType}
                        errors={errors}
                        selectObj={emeaMetal}
                        defaultValue="Aluminium"
                        helperText={
                            <Trans i18nKey="metalTypeHelper">Select the metal for the can</Trans>
                        }
                    />
                </Grid>
            </Grid>
        </FormSection>
    );
};

export default GeneralInfo;
