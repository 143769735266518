import React, { useState, useEffect } from 'react';
import ScrapItRow from './ScrapItRow';
import ScrapItToolbar from './ScrapItToolbar';
import { generateKey } from '../../../../utility/helpers/order-helpers';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { paginationOptions } from '../../../../utility/helpers/grid-helpers';
import ScrapItColumns from './ScrapItColumns';
import { ProductWithPallets } from '../../../../store/reducers/shipping-dashboard';
import StandardTable from '../../../reusable/molecules/StandardTable';
import { makeStyles } from '@material-ui/core';
import Modal from '../../../reusable/molecules/Modal';
import { xl, blackWeight } from '../../../../themes/globalConstants';
import { useTranslation } from 'react-i18next';
import { ScrapItState } from '../../../../store/reducers/scrap-it';
import ScrapItPOBalanceModal from './ScrapItPOBalanceModal';

interface Props {
    scrapItItems: ProductWithPallets[];
    onAddItem: (item: ProductWithPallets, pallets: number, type: string) => void;
    onPalletAdd: (item: ProductWithPallets, pallets: number) => void;
    onDeleteItem: (item: ProductWithPallets) => void;
    onViewFiltersClick: (viewTypes: string[]) => void;
    onInputBlur: (product: ProductWithPallets, numberOfInputPallets: number) => void;
    isReviewGrid?: boolean;
    className?: string;
    columnWidths?: number[];
    error: boolean;
}

const useStyles = makeStyles((theme) => ({
    titleStyle: {
        display: 'flex',
        padding: 0,
        margin: '1em 0 0 2em',
        '& h2': {
            fontSize: xl,
            fontWeight: blackWeight,
            color: theme.palette.info.dark
        }
    },
    subheaderStyle: {
        display: 'flex',
        padding: 0,
        margin: '0 0 1em 2em',
        '& h2': {
            color: theme.palette.info.dark
        }
    }
}));

export default function ScrapItGrid({
    scrapItItems,
    onAddItem,
    onPalletAdd,
    onDeleteItem,
    onViewFiltersClick,
    onInputBlur,
    isReviewGrid = false,
    className,
    columnWidths,
    error
}: Props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [columns, setColumns] = useState<any>([]);
    const [activeProduct, setActiveProduct] = useState<ProductWithPallets>();
    const [productionModalOpen, setProductionModalOpen] = useState<boolean>(false);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { agedProducts } = useTypedSelector<ScrapItState>((state) => state.scrapItState);
    const prodBalanceModalTitle = `${activeProduct?.displayName}`;

    const CustomerScrapItRow = ({ row }: { row: Partial<ProductWithPallets> }) => {
        return (
            <ScrapItRow
                key={generateKey('product')}
                item={row}
                onAddItem={onAddItem}
                onDeleteItem={onDeleteItem}
                onPalletAdd={onPalletAdd}
                onInputBlur={onInputBlur}
                onLookup={onLookup}
                isReviewGrid={isReviewGrid}
            />
        );
    };

    const createModalSubheader = (activeProduct: ProductWithPallets) => {
        return `Ball ${t('productId', 'Product ID')}/${t('graphicsID', 'Graphics ID')}: ${
            activeProduct.displayId
        }/${activeProduct.graphicIdAndVersion}`;
    };

    const handleProdModalClose = () => {
        setActiveProduct(undefined);
        setProductionModalOpen(false);
    };

    const onLookup = (product: ProductWithPallets) => {
        setProductionModalOpen(true);
        setActiveProduct(product);
    };

    useEffect(() => {
        setColumns(ScrapItColumns.getScrapItColumns(cultureCode, isReviewGrid));
    }, [cultureCode]);

    useEffect(() => {
        scrapItItems.sort((a, b) => ((a.status ?? 'Z') > (b.status ?? 'Z') ? 1 : -1));
    }, [scrapItItems]);

    return (
        <>
            <StandardTable
                className={className}
                columns={columns}
                lines={scrapItItems}
                gridName={'Scrap It Grid'}
                rowComponent={CustomerScrapItRow}
                customToolbarItems={
                    <ScrapItToolbar onViewFiltersClicked={onViewFiltersClick} error={error} />
                }
                searchText={!isReviewGrid}
                itemsPerPage={paginationOptions.rowsPerPage[0]}
                columnWidths={columnWidths}
            />
            {agedProducts && activeProduct && (
                <Modal
                    open={productionModalOpen}
                    close={handleProdModalClose}
                    title={prodBalanceModalTitle}
                    titleStyles={classes.titleStyle}
                    subheader={createModalSubheader(activeProduct)}
                    subheaderStyles={classes.subheaderStyle}
                    closeIcon={true}
                    fullWidth={true}
                    maxWidth={'sm'}
                >
                    <ScrapItPOBalanceModal activeProduct={activeProduct} />
                </Modal>
            )}
        </>
    );
}
