import React from 'react';
import { Trans } from 'react-i18next';
import ConfirmationModal from '../../../reusable/molecules/ConfirmationModal';

interface Props {
    onSubmitChanges: () => void;
    onClose: () => void;
    open: boolean;
}

const EditProductionOrderSubmitModal = ({ onSubmitChanges, open, onClose }: Props) => {
    return (
        <ConfirmationModal
            title={
                <Trans i18nKey="editProductionOrderSubmitChanges">
                    You are about to change your production order
                </Trans>
            }
            open={open}
            logo={false}
            subheader={
                <Trans i18nKey="editProcutionOrderConfirmSubmit">
                    Are you sure you want to save the changes to your production order?
                </Trans>
            }
            cancelText={<Trans i18nKey="no">No</Trans>}
            continueText={<Trans i18nKey="yesSave">Yes, Save</Trans>}
            saveProgress={onSubmitChanges}
            onClose={onClose}
            onCancel={onClose}
            canSaveDraft={true}
        />
    );
};
export default EditProductionOrderSubmitModal;
