import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { makeStyles, Box, Typography, Tabs, Tab, Grid } from '@material-ui/core';
import {
    addFormItemButtonBlue,
    ballDrkBlue,
    black,
    blackWeight,
    inactiveTabBlue,
    lato,
    ltBlueGrey,
    medium,
    small,
    white,
    xl
} from '../../../../themes/globalConstants';
import { useQuery } from '../../../../utility/helpers/query-helpers';
import useSecurity, { Activity, SecurityLevel } from '../../../../utility/auth/useSecurity';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { ShippingSummaryState } from '../../../../store/reducers/shipping-summary';
import {
    loadClosedDeliveryOrders,
    loadOpenDeliveryOrders
} from '../../../../store/actions/shipping-summary';
import { AuthState } from '../../../../store/reducers/auth';
import { useDispatch } from 'react-redux';
import ShipItOpenDeliveriesTab from './ShipItOpenDeliveriesTab';
import TranslationService from '../../../../utility/services/translation-service';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import ShipItClosedDeliveriesTab from './ShipItClosedDeliveriesTab';
import { clearEditShipmentsSummary } from '../../../../store/actions/edit-shipments';
import { EditShipmentsState } from '../../../../store/reducers/edit-shipments';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { usePrevious } from '../../../../utility/helpers/react-util';
import { getShipToAccounts } from '../../../../store/actions/customer-context';
import { TabPanelProps } from '../../../../utility/helpers/summary-helpers';
import { SHIPPING_SUMMARY_RESET } from '../../../../store/actions/action-types';

interface Props {
    setDashboardLoading: Dispatch<SetStateAction<boolean>>;
    dashboardLoading: boolean;
}
enum TabStatus {
    'open' = 0,
    'closed' = 1
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: '4.5em',
        position: 'relative',
        '& h2': {
            fontSize: xl,
            fontFamily: lato,
            fontWeight: blackWeight,
            color: theme.palette.secondary.main
        }
    },
    tabPanel: {
        width: '100%'
    },
    counter: {
        width: '2em',
        height: '2em',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '0.5em',
        fontSize: small,
        fontWeight: blackWeight,
        backgroundColor: ltBlueGrey,
        color: ballDrkBlue
    },
    selectedCounter: {
        backgroundColor: addFormItemButtonBlue,
        color: white
    },
    tabs: {},
    tabLabel: {
        backgroundColor: inactiveTabBlue,
        height: '56px',
        borderTopLeftRadius: '0.625em',
        borderTopRightRadius: '0.625em',
        '&:not(:first-of-type)': {
            marginLeft: '8px'
        },
        padding: '0 3em',
        color: ballDrkBlue,
        textTransform: 'none',
        maxWidth: 'none'
    },
    tabLabelText: {
        fontSize: medium,
        fontWeight: blackWeight
    },
    selectedTab: {
        backgroundColor: white,
        color: black,
        boxShadow: '0px 0px 6px rgba(185, 211, 220, 0.8), inset 0px -3px 0px #009BBB'
    }
}));

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    const classes = useStyles();

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`ship-it-tabpanel-${index}`}
            aria-labelledby={`ship-it-tab-${index}`}
            {...other}
            className={classes.tabPanel}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
};

const a11yProps = (index: any) => {
    return {
        id: `ship-it-tab-${index}`,
        'aria-controls': `shipping-tabpanel-${index}`
    };
};

const ShipItSummaryTabs = ({ setDashboardLoading, dashboardLoading }: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const query = useQuery();
    const status = query.get('status');
    const initialValue = getValueFromStatus(status);
    const [value, setValue] = useState<number>(initialValue);

    const {
        openDeliveryOrdersLoaded,
        closedDeliveryOrdersLoaded,
        closedDeliveryOrders,
        openDeliveryOrders,
        error
    } = useTypedSelector<ShippingSummaryState>((state) => state.shippingSummary);
    const { accessToken, permissions } = useTypedSelector<AuthState>((state) => state.auth);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { deliveryToEdit } = useTypedSelector<EditShipmentsState>((state) => state.editShipments);
    const { selectedAccountId, shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );

    const [openOrdersTotal, setOpenOrdersTotal] = useState<number>(openDeliveryOrders.length ?? 0);
    const [closedOrdersTotal, setClosedOrdersTotal] = useState<number>(
        closedDeliveryOrders.length ?? 0
    );

    const openDeliverySecurity = useSecurity(Activity.NewOpenDeliveryOrders);
    const closedDeliverySecurity = useSecurity(Activity.ClosedDeliveryOrders);
    const prevSelectedShipTo = usePrevious(selectedAccountId);

    // Handle tab selection change
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        const val = TabStatus[newValue];
        // Update query param so back button will return to same tab selection
        window.history.replaceState('', '', `?status=${val}`);
        setValue(newValue);
    };

    const TabLabel = (label: string, count: number, index: number) => {
        return (
            <Grid container justify="center" alignItems="center">
                <Typography className={classes.tabLabelText}>{label}</Typography>
                <Typography
                    component="span"
                    className={`${classes.counter} ${value === index && classes.selectedCounter}`}
                >
                    {count}
                </Typography>
            </Grid>
        );
    };

    useEffect(() => {
        if (permissions && !shipToAccounts?.length) {
            dispatch(getShipToAccounts());
        }
    }, [dispatch, shipToAccounts, permissions]);

    useEffect(() => {
        if ((openDeliveryOrdersLoaded && closedDeliveryOrdersLoaded) || error !== undefined) {
            setDashboardLoading(false);
            setClosedOrdersTotal(closedDeliveryOrders.length);
        }
    }, [openDeliveryOrdersLoaded, closedDeliveryOrdersLoaded, error]);

    useEffect(() => {
        if (deliveryToEdit) {
            dispatch(clearEditShipmentsSummary());
        }
    }, [deliveryToEdit]);

    function getValueFromStatus(status) {
        return (TabStatus as any)[status?.toLowerCase() || ''] || 0;
    }

    useEffect(() => {
        const value = getValueFromStatus(status);
        setValue(value);
    }, [status]);

    useEffect(() => {
        if (
            permissions?.length &&
            !dashboardLoading &&
            ((!openDeliveryOrdersLoaded && !closedDeliveryOrdersLoaded) || !prevSelectedShipTo) &&
            selectedAccountId
        ) {
            setDashboardLoading(true);
            dispatch(loadClosedDeliveryOrders([parseInt(selectedAccountId)]));
            dispatch(loadOpenDeliveryOrders([parseInt(selectedAccountId)]));
        }
    }, [dispatch, permissions]);

    // Reload Ship It Summary information only if the Selected Ship To has changed
    useEffect(() => {
        if (
            accessToken &&
            permissions?.length &&
            selectedAccountId &&
            prevSelectedShipTo &&
            prevSelectedShipTo !== selectedAccountId
        ) {
            setDashboardLoading(true);
            dispatch(loadClosedDeliveryOrders([parseInt(selectedAccountId)]));
            dispatch(loadOpenDeliveryOrders([parseInt(selectedAccountId)]));
        }
    }, [dispatch, accessToken, permissions, selectedAccountId]);

    useEffect(() => {
        return () => {
            // Reset summary page information on page leave
            dispatch({ type: SHIPPING_SUMMARY_RESET });
        };
        // Runs only once
    }, []);

    return (
        <div className={classes.root}>
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="ship it summary tabs"
                className={classes.tabs}
                scrollButtons="auto"
                variant="scrollable"
                indicatorColor="primary"
                textColor="primary"
            >
                <Tab
                    label={TabLabel(
                        TranslationService.getTranslatedText(cultureCode, 'openDeliveryOrders'),
                        openOrdersTotal || openDeliveryOrders?.length || 0,
                        0
                    )}
                    {...a11yProps(0)}
                    className={`${classes.tabLabel} ${value === 0 && classes.selectedTab}`}
                    disabled={openDeliverySecurity === SecurityLevel.None}
                    data-testid="open-delivery-order-tab-label"
                />
                <Tab
                    label={TabLabel(
                        TranslationService.getTranslatedText(cultureCode, 'closedDeliveryOrders'),
                        closedOrdersTotal || closedDeliveryOrders?.length || 0,
                        1
                    )}
                    {...a11yProps(1)}
                    className={`${classes.tabLabel} ${value === 1 && classes.selectedTab}`}
                    disabled={closedDeliverySecurity === SecurityLevel.None}
                    data-testid="closed-delivery-order-tab-label"
                />
            </Tabs>
            <TabPanel value={value} index={0} data-testid="open-delivery-order-tab">
                <ShipItOpenDeliveriesTab onFilteredOrdersChanged={setOpenOrdersTotal} />
            </TabPanel>
            <TabPanel value={value} index={1} data-testid="closed-delivery-order-tab">
                <ShipItClosedDeliveriesTab onFilteredOrdersChanged={setClosedOrdersTotal} />
            </TabPanel>
        </div>
    );
};

export default ShipItSummaryTabs;
