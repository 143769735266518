import {
    PLAN_IT_GUIDED_RESET,
    PLAN_IT_GUIDED_ADD_WEEK,
    PLAN_IT_GUIDED_SET_PRODUCTS,
    PLAN_IT_GUIDED_UPDATE_QUANTITY_UNIT,
    PLAN_IT_GUIDED_SUBMIT_LOADING,
    PLAN_IT_GUIDED_SUBMIT_SUCCESS,
    PLAN_IT_GUIDED_SUBMIT_ERROR,
    PLAN_IT_GUIDED_SET_PRODUCTS_EXISTING,
    PLAN_IT_GUIDED_SET_CUSTOMER_PO,
    PLAN_IT_GUIDED_INITIZLIZE_WEEKS,
    PLAN_IT_GUIDED_TOGGLE_SELECTED_WEEK
} from '../actions/action-types';
import { OrderProductTableRow } from './orders-dashboard';
import { PlanItBulkATMOrder } from './planit-bulk-atm';
import { QuantityUnit } from './shipping-dashboard';

export interface GuidedOrder {
    productsToOrder: OrderProductTableRow[];
    productionOrderId?: number;
    customerProductionOrderId?: string;
    week?: string;
}

export interface PlanItGuidedState {
    guidedOrders?: GuidedOrder[];
    productsToOrder: OrderProductTableRow[];
    quantityUnit: QuantityUnit;
    submitState: 'idle' | 'loading' | 'success' | 'error';
    forecastOrders?: PlanItBulkATMOrder[];
    productionOrderId?: number;
    weeks?: string[];
    selectedWeeks?: string[];
}

const initialState: PlanItGuidedState = {
    guidedOrders: [],
    productsToOrder: [],
    quantityUnit: 'pallets',
    submitState: 'idle',
    weeks: [],
    selectedWeeks: []
};

const planItGuided = (
    state: PlanItGuidedState = initialState,
    action: { type: string; [x: string]: any }
): PlanItGuidedState => {
    switch (action.type) {
        case PLAN_IT_GUIDED_ADD_WEEK:
            return { ...state, weeks: action.weeks };
        case PLAN_IT_GUIDED_SET_PRODUCTS:
            const serializedActionGuidedOrders = JSON.stringify(action.guidedOrders);
            const serializedStateGuidedOrders = JSON.stringify(state.guidedOrders);

            const shouldUpdateGuided =
                !!action.guidedOrders &&
                serializedActionGuidedOrders !== serializedStateGuidedOrders;

            return {
                ...state,
                productsToOrder: action.productsToOrder,
                guidedOrders: shouldUpdateGuided ? action.guidedOrders : state.guidedOrders
            };
        case PLAN_IT_GUIDED_SET_PRODUCTS_EXISTING:
            return { ...state, guidedOrders: action.guidedOrders };
        case PLAN_IT_GUIDED_SET_CUSTOMER_PO:
            return { ...state, guidedOrders: action.guidedOrders };
        case PLAN_IT_GUIDED_UPDATE_QUANTITY_UNIT:
            return {
                ...state,
                quantityUnit: action.quantityUnit,
                productsToOrder: action.productsToOrder
            };
        case PLAN_IT_GUIDED_RESET:
            return {
                guidedOrders: [],
                productsToOrder: [],
                quantityUnit: 'pallets',
                submitState: 'idle',
                weeks: [],
                selectedWeeks: []
            };
        case PLAN_IT_GUIDED_SUBMIT_LOADING:
            return {
                ...initialState,
                submitState: 'loading',
                guidedOrders: action.guidedOrders
            };
        case PLAN_IT_GUIDED_SUBMIT_SUCCESS:
            return {
                ...initialState,
                submitState: 'success',
                forecastOrders: action.forecastOrders,
                guidedOrders: action.guidedOrders
            };
        case PLAN_IT_GUIDED_SUBMIT_ERROR:
            return { ...initialState, submitState: 'error' };
        case PLAN_IT_GUIDED_INITIZLIZE_WEEKS:
            return {
                ...state,
                weeks: action.weeks
            };
        case PLAN_IT_GUIDED_TOGGLE_SELECTED_WEEK:
            return {
                ...state,
                selectedWeeks: action.selectedWeeks,
                guidedOrders: action.guidedOrders
            };
        default:
            return state;
    }
};

export default planItGuided;
