import moment from 'moment';
import CommunicationService, {
    CreateCommunicationRequest,
    UpdateCommunicationRequest
} from '../../../../utility/services/communication-service';
import { InputState, InputErrorState } from './interfaces';
import { Communication } from '../../../../store/reducers/communication-management';

// Input Error Processing //////////////////////////////////////////////////////////////////////////////////////

export function getDefaultErrorState(): InputErrorState {
    return {
        messageTypeErrorMessage: '',
        messageTitleErrorMessage: '',
        messageContentErrorMessage: '',
        displayPagesErrorMessage: '',
        regionsErrorMessage: '',
        startDateErrorMessage: '',
        endDateErrorMessage: '',
        anyErrorExists: false
    };
}

export function getErrorState(
    inputState: InputState,
    communicationToEdit: Communication | undefined = undefined
): InputErrorState {
    const inputErrorState = getDefaultErrorState();
    const startDateEdited =
        communicationToEdit &&
        Math.abs(
            moment.utc(communicationToEdit.startDateTime).diff(inputState.startDate, 'minutes')
        ) > 0;

    if (inputState.messageTypeSelection.size < 1)
        inputErrorState.messageTypeErrorMessage = 'Please select at least one message type';
    if (inputState.messageTitle.length < 1)
        inputErrorState.messageTitleErrorMessage = 'Please enter a message name';
    if (inputState.messageTitle.length > 100)
        inputErrorState.messageTitleErrorMessage = 'Name cannot exceed 100 characters';
    if (inputState.displayPageSelection.size < 1)
        inputErrorState.displayPagesErrorMessage = 'Please select at least one page';
    if (inputState.regionSelection.size < 1)
        inputErrorState.regionsErrorMessage = 'Please select at least one region';
    if (inputState.messageContent.length < 1)
        inputErrorState.messageContentErrorMessage = 'Please enter message content';
    if (inputState.messageContent.length > 5000)
        inputErrorState.messageContentErrorMessage = 'Message cannot exceed 5000 characters';
    if (
        inputState.startDate?.isBefore(moment.utc().add(-1, 'millisecond')) &&
        (!communicationToEdit || startDateEdited)
    )
        inputErrorState.startDateErrorMessage = 'Start date cannot be in the past';
    if (inputState.endDate?.isBefore(moment.utc().add(-1, 'millisecond')))
        inputErrorState.endDateErrorMessage = 'End date cannot be in the past';
    if (inputState.endDate?.isBefore(moment(inputState.startDate)?.add(1, 'minute')))
        inputErrorState.endDateErrorMessage = 'End date must be after start date';

    inputErrorState.anyErrorExists = Object.values(inputErrorState).some((value) => !!value);

    return inputErrorState;
}

// Create Communication Message //////////////////////////////////////////////////////////////////////////////////////

export async function createCommunicationMessage(
    accessToken: any,
    inputState: InputState,
    submitterName: string,
    submitterEmail: string
): Promise<boolean> {
    const request: CreateCommunicationRequest = buildCreateCommunicationRequest(
        inputState,
        submitterName,
        submitterEmail
    );

    const response: any = await CommunicationService.createCommunicationMessage(
        accessToken,
        request
    ).catch(() => {
        Promise.resolve(false);
    });

    if (response?.status === 201 && response?.data?.communicationId) {
        return Promise.resolve(true);
    }

    return Promise.resolve(false);
}

export async function editCommunicationMessage(
    accessToken: any,
    communicationId: number,
    inputState: InputState,
    submitterName: string,
    submitterEmail: string
): Promise<boolean> {
    const request: UpdateCommunicationRequest = {
        communicationId,
        ...buildCreateCommunicationRequest(inputState, submitterName, submitterEmail)
    };

    const response: any = await CommunicationService.updateCommunicationMessage(
        accessToken,
        request
    ).catch(() => {
        Promise.resolve(false);
    });

    if (response?.status === 201 && response?.data?.communicationId) {
        return Promise.resolve(true);
    }

    return Promise.resolve(false);
}

export function buildCreateCommunicationRequest(
    inputState: InputState,
    submitterName: string,
    submitterEmail: string
): CreateCommunicationRequest {
    return {
        title: inputState.messageTitle,
        content: inputState.messageContent,
        startDateTime: moment.utc(inputState.startDate),
        endDateTime: moment.utc(inputState.endDate),
        recipientType: inputState.recipientTypeSelection,
        submitterName,
        submitterEmail,
        status: true,
        communicationRegions: Array.from(inputState.regionSelection),
        communicationDisplayPages: Array.from(inputState.displayPageSelection),
        communicationMessageTypes: Array.from(inputState.messageTypeSelection)
    };
}
