import * as types from './action-types';
import ProductService, { ProductSearchResponse } from '../../utility/services/product-service';
import { getAerosolProducts } from '../../utility/helpers/product-helpers';

export const loadProductDetails = (productCode: string) => {
    return (dispatch: any, getState: any) => {
        dispatch({
            type: types.PRODUCT_DETAILS_LOADING
        });

        let productResponse: ProductSearchResponse = { products: [] };
        const aerosolProduct = getAerosolProducts().find(
            (product) => product.productCode === productCode
        );

        // Return local aerosol product data if found
        if (aerosolProduct) {
            dispatch({
                type: types.PRODUCT_DETAILS_LOADED,
                product: aerosolProduct
            });
            return;
        }

        return ProductService.getProduct(getState, productCode)
            .then((product) => {
                productResponse.products.push(product.data);
                return ProductService.getProductContent(productResponse, getState);
            })
            .then((content) => {
                dispatch({
                    type: types.PRODUCT_DETAILS_LOADED,
                    product: content[0]
                });
            })
            .catch((error) => {
                dispatch({
                    type: types.PRODUCT_DETAILS_ERROR,
                    error: 'Unable to load product details content due to the following: ' + error
                });
                // maybe content failed but product is ok
                if (productResponse) {
                    dispatch({
                        type: types.PRODUCT_DETAILS_LOADED,
                        product: productResponse.products[0]
                    });
                }
            });
    };
};
