import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import TradeRequestDetailsColumns from './TradeRequestDetailsColumns';

import StandardTable from '../../../reusable/molecules/StandardTable';
import TradeRequestDetailsRow from './TradeRequestDetailsRow';
import {
    TradeRequestDetailsLine,
    TradeRequestDetailState
} from '../../../../store/reducers/trade-it-details';

interface Props {
    originalShipToId: string;
    receivingShipToId: string;
}

export default function TradeRequestDetailsGrid({ originalShipToId, receivingShipToId }: Props) {
    const [columns, setColumns] = useState<any>([]);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { tradeRequest } = useTypedSelector<TradeRequestDetailState>(
        (state) => state.tradeItDetails
    );

    useEffect(() => {
        setColumns(
            TradeRequestDetailsColumns.getTradeRequestDetailsColumns(
                cultureCode,
                originalShipToId,
                receivingShipToId
            )
        );
    }, [cultureCode]);

    const CustomOrderRow = ({ row }: { row: TradeRequestDetailsLine }) => {
        return <TradeRequestDetailsRow line={row} />;
    };

    return (
        <Grid container data-testid="products-grid">
            {tradeRequest?.tradeLines && (
                <StandardTable
                    gridName="Trade Request Details Grid"
                    columns={columns}
                    lines={tradeRequest?.tradeLines}
                    rowComponent={CustomOrderRow}
                    columnWidths={[20, 12, 12, 11, 12, 12, 8]}
                    itemsPerPage={10}
                />
            )}
        </Grid>
    );
}
