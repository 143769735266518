import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
    makeStyles,
    Typography,
    TableRow,
    TableCell,
    Grid,
    Input,
    IconButton,
    InputAdornment
} from '@material-ui/core';
import {
    ballLtGray,
    small,
    white,
    regularWeight,
    ballBlue,
    lightBlue,
    xs,
    shipRed,
    errorBackground,
    warningBorder,
    vanilla,
    boldWeight,
    large,
    medium,
    black_2
} from '../../../../themes/globalConstants';
import Button from '../../../reusable/atoms/Button';
import { numericRegex } from '../../../../utility/helpers/formatters';
import {
    getDesc,
    isWithinWeekRange,
    getWithinRange,
    getEarliestDate,
    getMoqFeeFloor,
    getMoqFeeCeiling
} from '../../../../utility/helpers/production-order-helpers';
import { getGraphicIdAndVersion } from '../../../../utility/helpers/order-helpers';
import EditProductionOrderSplitRow from './EditProductionOrderSplitRow';
import Modal from '../../../reusable/molecules/Modal';
import moment from 'moment';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import clsx from 'clsx';
import SingleDaypickerModal from '../../../reusable/molecules/SingleDaypickerModal';
import { ProductionOrderLine } from '../../../../store/reducers/makeit-dashboard';
import { formatUTCToDateDisplay, getUtcMidnight } from '../../../../utility/helpers/date-helpers';
import Link from '../../../reusable/atoms/Link';
import { getEachesConversion } from '../../../../utility/helpers/shipment-helpers';

interface Props {
    item: ProductionOrderLine;
    onRemoveItem: (item: ProductionOrderLine) => void;
    onItemUpdates: (item: ProductionOrderLine) => void;
}

const useStyles = makeStyles((theme) => ({
    tableRowContainer: {
        '& .helper-text': {
            fontWeight: `${regularWeight} !important`,
            fontSize: small
        },
        '& .MuiInputBase-input': {
            textAlign: 'right',
            paddingRight: '.25em'
        }
    },
    textField: {
        width: '6em',
        height: '1.5em',
        border: `1.8px solid ${ballLtGray}`,
        borderRadius: '.25em',
        marginRight: '0.5em',
        backgroundColor: white
    },
    alignCenter: {
        textAlign: 'center'
    },
    alignRight: {
        textAlign: 'right'
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    splitContainer: {
        borderLeft: 'none',
        paddingLeft: 0
    },
    split: {
        transform: 'rotate(90deg)',
        fill: ballBlue
    },
    splitText: {
        color: ballBlue,
        fontSize: xs
    },
    iconButton: {
        padding: 0,
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    disabledText: {
        color: black_2,
        fill: black_2
    },
    trash: {
        minWidth: 'unset'
    },
    hiddenCell: {
        borderLeft: 'none'
    },
    calendarDiv: {
        margin: '2em'
    },
    calendarInput: {
        border: `1.8px solid ${ballLtGray}`,
        borderRadius: 2,
        cursor: 'pointer'
    },
    calendarIcon: {
        color: ballBlue
    },
    blueBackground: {
        backgroundColor: lightBlue
    },
    feeError: {
        border: `1px solid ${shipRed}`,
        borderRight: 'none',
        backgroundColor: errorBackground
    },
    feeWarning: {
        border: `1px solid ${warningBorder}`,
        borderRight: 'none',
        backgroundColor: vanilla
    },
    splitFeeWarning: {
        border: `1px solid ${warningBorder}`,
        borderLeft: 'none',
        backgroundColor: vanilla
    },
    splitFeeError: {
        border: `1px solid ${shipRed}`,
        borderLeft: 'none',
        backgroundColor: errorBackground
    },
    subheader: {
        fontSize: large,
        fontWeight: boldWeight,
        letterSpacing: '0.2px',
        marginBottom: '1em'
    },
    undo: {
        fontSize: small
    },
    link: {
        fontSize: medium,
        fontWeight: regularWeight,
        '&:hover': {
            cursor: 'pointer'
        }
    }
}));

const EditProductionOrderRow = ({ item, onRemoveItem, onItemUpdates }: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const weekRange = 2;

    const [purchaseQty, setPurchaseQty] = useState<number>(0);
    const [isSplit, setIsSplit] = useState<boolean>(false);
    const [totalQty, setTotalQty] = useState<number>(0);
    const [subRows, setSubrows] = useState<ProductionOrderLine[]>([]);
    const [selectedDate, setSelectedDate] = useState<string>();
    const [daypickerDate, setDaypickerDate] = useState<string>();
    const [calendarOpen, setCalendarOpen] = useState(false);
    const [counter, setCounter] = useState(0);
    const [originalRequestedDate, setOriginalRequestedDate] = useState<string>('');
    const [firstDay, setFirstDay] = useState<moment.Moment | null>(null);
    const [isRemoved, setIsRemoved] = useState<boolean>(false);
    const [withinDateRange, setWithinDateRange] = useState<boolean>(true);
    const [hasLinkedDO, setHasLinkedDO] = useState<boolean>(false);
    const [isRequestedDatePast, setIsRequestedDatePast] = useState<boolean>(false);

    const calendarIcon = (
        <img
            className={classes.calendarIcon}
            src={process.env.PUBLIC_URL + '/assets/Calendar_Icon.svg'}
            alt="Calendar Icon"
        />
    );

    const hasMoqFee = item.moqFees && item.moqFees.length > 0 ? true : false;
    const moqFeeFloor = getMoqFeeFloor(item.moqFees);
    const moqFeeCeiling = getMoqFeeCeiling(item.moqFees);

    const onOpenCalendar = () => {
        if (isSplit || isRemoved || hasLinkedDO || isRequestedDatePast || withinDateRange) {
            //prevents calendar from opening when it should be disabled
            return;
        }
        setCalendarOpen(true);
    };

    const onCloseCalendar = () => {
        setCalendarOpen(false);
    };

    const handleOkClose = () => {
        setCalendarOpen(false);
        setSelectedDate(daypickerDate);

        if (daypickerDate !== item.requestedDate) {
            item.requestedDate = daypickerDate;
            onItemUpdates(item);
        }
    };

    // Removes main row from table
    const handleRemoveItem = () => {
        item.isLineRemoved = true;
        item.isMoreThanOriginalQty = false;
        onRemoveItem(item);
        setIsRemoved(true);
    };

    const handleUndoDelete = () => {
        item.isLineRemoved = false;
        onItemUpdates(item);
        setIsRemoved(false);
    };

    // Updates total calculations for rows with subrows
    const handleQtyUpdate = () => {
        totalCalculations(subRows);
    };

    const handleSubRowSelectedDateChange = () => {
        onItemUpdates(item);
    };

    // Updates total for rows without subrows
    const updatePurchaseCount = (count: string) => {
        let quantity = 0;
        if (numericRegex(count)) {
            if (!isNaN(parseInt(count))) {
                quantity = parseInt(count);
            }
            setPurchaseQty(quantity);
        }
    };

    const handleQuantityOnBlur = () => {
        updateMinimumMet();
    };

    // Calculate total for rows with subrows
    const totalCalculations = (rows: ProductionOrderLine[]) => {
        let total = 0;
        rows.forEach((item) => {
            if (item.palletQuantity && !item.isLineRemoved) {
                return (total += item.palletQuantity);
            }
        });
        setTotalQty(total);
    };

    // Validates whether or not minimum qty has been met
    const updateMinimumMet = () => {
        const originalQty = item.originalPalletQuantity;
        const quantity = purchaseQty === 0 ? totalQty : purchaseQty;

        // if the line was not originally a split line
        if (item.minimumOrderQuantity) {
            const minValue = item.moqFees
                ? getMoqFeeFloor(item.moqFees)
                : item.minimumOrderQuantity;
            item.withinRange = getWithinRange(minValue, quantity, item.minimumOrderQuantity);
            item.minimumMet = quantity >= item.minimumOrderQuantity;
        } else {
            item.minimumMet = quantity !== null && quantity !== 0;
        }

        item.isMoreThanOriginalQty = !!originalQty && quantity > originalQty;

        item.palletQuantity = quantity;
        item.modified = true;
        onItemUpdates(item);
    };

    const handleSplit = () => {
        // Create a new subrow object
        const newRow: ProductionOrderLine = {
            productId: item.productId,
            productSku: item.productSku,
            originalRequestedDate: item.originalRequestedDate ?? selectedDate,
            palletQuantity: 0,
            splitOrder: true,
            requestedDate: selectedDate ?? item.originalRequestedDate
        };

        let rows = [] as ProductionOrderLine[];

        // If the item doesn't have any subrows
        if (subRows.length === 0) {
            let firstRow = newRow;
            firstRow.index = counter;

            let secondRow = Object.assign({}, newRow);
            secondRow.index = firstRow.index + 1;
            // Update counter because of key logic on HTML
            setCounter(counter + 2);
            rows = [firstRow, secondRow];
            item.withinRange = false;
            item.minimumMet = false;
        } else if (subRows.length >= 2) {
            let row = Object.assign({}, newRow);
            row.index = counter + 1;
            rows = [...subRows, row];
            setCounter(counter + 1);
        }
        // Update item, local subrows, and isSplit
        item.subRows = rows;
        item.palletQuantity = 0;
        item.requestedDate = selectedDate;
        item.splitOrder = true;

        setSubrows(rows);
        setIsSplit(true);
        onItemUpdates(item);
    };

    const handleRemoveChildItem = (rowToDelete: ProductionOrderLine) => {
        let updatedRows = [] as ProductionOrderLine[];
        if (subRows.length > 2) {
            subRows.forEach((row) => {
                if (row.index === rowToDelete.index) {
                    row.isLineRemoved = true;
                }
            });
            updatedRows = subRows.filter((row) => {
                if (!row.productionOrderLineId) {
                    return row.index !== rowToDelete.index;
                } else {
                    return row;
                }
            });
            item.palletQuantity = updatedRows
                .filter(({ isLineRemoved }) => !isLineRemoved)
                .reduce((sum, row) => sum + row.palletQuantity!, 0);
        } else {
            setIsSplit(false);
            item.palletQuantity = 0;
            item.splitOrder = false;
        }
        item.subRows = updatedRows;
        setSubrows(subRows);
        totalCalculations(updatedRows);
        onItemUpdates(item);
    };

    const handleDateSelect = (newDate: moment.Moment | null) => {
        const date = moment(newDate).format('MM/DD/YYYY');
        setDaypickerDate(date);
    };

    useEffect(() => {
        const firstAvailableDay = getUtcMidnight(item.originalRequestedDate);
        setFirstDay(firstAvailableDay);
    }, []);

    useEffect(() => {
        const disabledForLinkedDO =
            item.hasLinkedDeliveryOrder && !item.hasCancelledDeliveryOrder ? true : false;
        setIsRemoved(item.isLineRemoved ?? false);
        setHasLinkedDO(disabledForLinkedDO);
        const formattedOriginalDate = formatUTCToDateDisplay(item.originalRequestedDate);
        // Set Original Date for persisting
        if (item.originalRequestedDate) {
            setOriginalRequestedDate(formattedOriginalDate);
            const today = moment();
            if (moment(item.originalRequestedDate).isSameOrBefore(today)) {
                setIsRequestedDatePast(true);
            }
        }

        // Evaluate if requested date is within two weeks
        if (item.originalRequestedDate) {
            setWithinDateRange(
                isWithinWeekRange(moment(), getUtcMidnight(item.originalRequestedDate), weekRange)
            );
        }

        // Set other row related properties if there are no subrows
        if (!item.subRows || item.subRows.length === 0) {
            setPurchaseQty(item.palletQuantity ?? 0);
        } // Set row properties if there are split/sub rows
        else if (item.subRows && item.subRows.length > 0) {
            let originalRowRequestedDates: Date[] = [];
            let total = 0;
            item.subRows.forEach((row) => {
                if (row.originalRequestedDate) {
                    originalRowRequestedDates.push(getUtcMidnight(row.originalRequestedDate));
                }
                if (row.palletQuantity && !row.isLineRemoved) {
                    return (total += row.palletQuantity);
                }
            });

            // if we don't have originalRequestedDate set on the item, need to find the earliest date from the split rows
            if (!item.originalRequestedDate) {
                const earliestDate = getEarliestDate(originalRowRequestedDates);
                setWithinDateRange(isWithinWeekRange(moment(), earliestDate, weekRange));
            }
            item.palletQuantity = total;
            setSelectedDate(formattedOriginalDate);
            setTotalQty(total);
            setPurchaseQty(0);
            setSubrows(item.subRows);
            setIsSplit(true);
            setCounter(item.subRows.length);
        }

        //If there is a requested date, set state value to that
        if (item.requestedDate) {
            setSelectedDate(formatUTCToDateDisplay(item.requestedDate));
        } else {
            setSelectedDate(formattedOriginalDate);
        }
    }, [item]);

    const getCalendarModalTitle = () => {
        return t('originalRequestedDate', 'Original Requested Date') + ': ' + originalRequestedDate;
    };

    return (
        <>
            <TableRow className={classes.tableRowContainer}>
                <TableCell data-testid="edit-po-display-id">
                    <Typography>{item.displayId}</Typography>
                    <Typography>
                        {getGraphicIdAndVersion(item.productType, item.productGraphicId)}
                    </Typography>
                </TableCell>
                <TableCell data-testid="edit-po-product-description">
                    <Typography>{item.displayName}</Typography>
                    <Typography>{getDesc(item)}</Typography>
                </TableCell>
                <TableCell data-testid="edit-po-coating">
                    <Typography>{item.productCoating}</Typography>
                </TableCell>
                <TableCell data-testid="edit-po-original-requested-date">
                    <Typography>{originalRequestedDate}</Typography>
                </TableCell>
                <TableCell className={classes.alignCenter} data-testid="edit-po-calendar-input">
                    <Input
                        id={item.productSku}
                        type="button"
                        fullWidth
                        disabled={
                            isSplit ||
                            isRemoved ||
                            withinDateRange ||
                            hasLinkedDO ||
                            isRequestedDatePast
                        }
                        onClick={onOpenCalendar}
                        disableUnderline={true}
                        value={selectedDate}
                        className={classes.calendarInput}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="calendar modal trigger"
                                    onClick={onOpenCalendar}
                                    disabled={
                                        isSplit ||
                                        isRemoved ||
                                        hasLinkedDO ||
                                        withinDateRange ||
                                        isRequestedDatePast
                                    }
                                    className={clsx({
                                        [classes.disabledText]:
                                            isSplit ||
                                            isRemoved ||
                                            hasLinkedDO ||
                                            isRequestedDatePast ||
                                            withinDateRange
                                    })}
                                >
                                    {calendarIcon}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </TableCell>
                <TableCell data-testid="edit-po-original-pallet-quantity">
                    <Typography>{item.originalPalletQuantity?.toLocaleString()} PL</Typography>
                    <Typography>
                        {getEachesConversion(item.originalPalletQuantity!, item.quantityPerPallet!)}
                    </Typography>
                </TableCell>
                <TableCell
                    className={clsx(
                        {
                            [classes.feeError]:
                                (!item.minimumMet && !item.withinRange) ||
                                item.isMoreThanOriginalQty
                        },
                        {
                            [classes.feeWarning]:
                                !item.minimumMet && item.withinRange && !item.isMoreThanOriginalQty
                        }
                    )}
                >
                    <Grid container alignContent="center">
                        {((!item.minimumMet && !item.withinRange) ||
                            item.isMoreThanOriginalQty) && (
                            <img
                                src={process.env.PUBLIC_URL + '/assets/Error_Icon.svg'}
                                alt="Error Icon"
                            />
                        )}
                        {!item.minimumMet && item.withinRange && !item.isMoreThanOriginalQty && (
                            <img
                                src={process.env.PUBLIC_URL + '/assets/Warning_Icon.svg'}
                                alt="Warning Icon"
                            />
                        )}
                        {!isSplit && (
                            <Grid item className={classes.inputContainer}>
                                <Input
                                    type="text"
                                    fullWidth
                                    className={classes.textField}
                                    disableUnderline={true}
                                    disabled={
                                        withinDateRange ||
                                        isRemoved ||
                                        hasLinkedDO ||
                                        isRequestedDatePast
                                    }
                                    onBlur={handleQuantityOnBlur}
                                    onChange={(e) => updatePurchaseCount(e.target.value)}
                                    value={purchaseQty}
                                />
                                <Typography>PL</Typography>
                                <Typography>
                                    {getEachesConversion(purchaseQty, item.quantityPerPallet!)}
                                </Typography>
                            </Grid>
                        )}
                        {isSplit && (
                            <>
                                <Grid item className={classes.inputContainer}>
                                    <Typography>{totalQty} PL</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>
                                        {getEachesConversion(totalQty, item.quantityPerPallet!)}
                                    </Typography>
                                </Grid>
                            </>
                        )}
                    </Grid>
                    {item.isMoreThanOriginalQty && (
                        <Grid container>
                            <Typography className="helper-text">
                                <Trans i18nKey="lessThanOriginal">Must be less than original</Trans>
                            </Typography>
                        </Grid>
                    )}
                    {item.minimumOrderQuantity && item.minimumOrderQuantity > 0 && (
                        <Grid container>
                            <Typography className="helper-text">
                                <Trans i18nKey="minimumOrderIs">Minimum Order is</Trans>
                                {` ${item.minimumOrderQuantity} PL`}
                            </Typography>
                        </Grid>
                    )}
                    {hasMoqFee && (
                        <Grid container>
                            <Typography className="helper-text">
                                <Trans i18nKey="feeForOrders">Fees for orders</Trans>{' '}
                                {` ${moqFeeFloor}-${moqFeeCeiling} PL`}
                            </Typography>
                        </Grid>
                    )}
                </TableCell>
                <TableCell
                    className={clsx(
                        classes.splitContainer,
                        {
                            [classes.splitFeeError]:
                                (!item.minimumMet && !item.withinRange) ||
                                item.isMoreThanOriginalQty
                        },
                        {
                            [classes.splitFeeWarning]: !item.minimumMet && item.withinRange
                        }
                    )}
                >
                    <Grid container justify="center" alignContent="center">
                        <IconButton
                            data-testid="split-icon"
                            aria-label="split icon"
                            onClick={handleSplit}
                            disableRipple
                            disableFocusRipple
                            className={clsx(classes.iconButton, {
                                [classes.disabledText]:
                                    isRemoved ||
                                    hasLinkedDO ||
                                    withinDateRange ||
                                    isRequestedDatePast
                            })}
                            disabled={
                                isRemoved || hasLinkedDO || withinDateRange || isRequestedDatePast
                            }
                        >
                            <CallSplitIcon
                                className={clsx(classes.split, {
                                    [classes.disabledText]:
                                        isRemoved ||
                                        hasLinkedDO ||
                                        withinDateRange ||
                                        isRequestedDatePast
                                })}
                            />
                            <Typography
                                className={clsx(classes.splitText, {
                                    [classes.disabledText]:
                                        isRemoved ||
                                        hasLinkedDO ||
                                        withinDateRange ||
                                        isRequestedDatePast
                                })}
                            >
                                <Trans i18nKey="split">Split</Trans>
                            </Typography>
                        </IconButton>
                    </Grid>
                </TableCell>
                {isRemoved ? (
                    <TableCell className={classes.alignCenter} data-testid="undo-icon">
                        <Link
                            className={classes.link}
                            underline="always"
                            onClick={() => handleUndoDelete()}
                        >
                            <Trans i18nKey="undo">Undo</Trans>
                        </Link>
                    </TableCell>
                ) : (
                    <TableCell className={classes.alignCenter} data-testid="delete-icon">
                        <Button
                            type="button"
                            onClick={() => handleRemoveItem()}
                            className={classes.trash}
                            disabled={
                                isSplit || withinDateRange || hasLinkedDO || isRequestedDatePast
                            }
                        >
                            {isSplit || withinDateRange || hasLinkedDO || isRequestedDatePast ? (
                                <img
                                    src={process.env.PUBLIC_URL + '/assets/DisabledTrashIcon.svg'}
                                    alt="Disabled Trash Icon"
                                />
                            ) : (
                                <img
                                    src={process.env.PUBLIC_URL + '/assets/Trash.svg'}
                                    alt="Trash Icon"
                                />
                            )}
                        </Button>
                    </TableCell>
                )}
            </TableRow>
            {subRows.length > 0 &&
                subRows.map((child) => {
                    return (
                        <>
                            {!child.isLineRemoved && (
                                <EditProductionOrderSplitRow
                                    key={child.index}
                                    item={child}
                                    onRemoveItem={handleRemoveChildItem}
                                    onQtyUpdate={handleQtyUpdate}
                                    onBlurSetQty={handleQuantityOnBlur}
                                    onSubRowSelectedDateChange={handleSubRowSelectedDateChange}
                                    styles={useStyles}
                                    quantityPerPallet={item.quantityPerPallet!}
                                />
                            )}
                        </>
                    );
                })}
            <Modal
                open={calendarOpen}
                title={getCalendarModalTitle()}
                close={onCloseCalendar}
                closeIcon={true}
                maxWidth="xl"
            >
                <div className={classes.calendarDiv}>
                    <Grid item>
                        <Typography className={classes.subheader}>
                            <Trans i18nKey="dateMustBeAfterOriginalDate">
                                The date must be after the original requested date
                            </Trans>
                        </Typography>
                    </Grid>
                    <SingleDaypickerModal
                        onCancel={onCloseCalendar}
                        onOk={handleOkClose}
                        selectDate={(newDate) => handleDateSelect(newDate)}
                        leadTime={0}
                        firstDay={firstDay}
                        calculateOutsideRange={true}
                    />
                </div>
            </Modal>
        </>
    );
};

export default EditProductionOrderRow;
