import React, { useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';

import { makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { brightGrey } from '../../../themes/globalConstants';
import { updateShippingDashboardQuantityUnit } from '../../../store/actions/shipping-dashboard';
import { QuantityUnit } from '../../../store/reducers/shipping-dashboard';
import RadioInput from './RadioInput';
import { enUS } from '../../../utility/translations/locales/en-US/en-US';
import { shipmentQuantityUnits } from '../../../static-data/dropdown-lists';
import Tooltip from '../atoms/Tooltip';
import { useQuantityUnitSelectorDispatch } from './QuantityUnitSelectorContext';
import { updateMakeItGuidedQuantityUnit } from '../../../store/actions/makeit-dashboard';
import { updatePlanItGuidedQuantityUnit } from '../../../store/root-actions';

const useStyles = makeStyles(() => ({
    helperText: {
        '& .MuiFormHelperText-root': {
            marginLeft: 0
        }
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: brightGrey,

        '& .MuiFormLabel-root:first-of-type': {
            fontSize: '.875em'
        },

        '& .MuiFormGroup-root .MuiFormControlLabel-root:first-of-type': {
            marginLeft: '.5em'
        },

        '& .MuiFormControlLabel-label.MuiTypography-body1': {
            fontSize: '.75em',
            marginLeft: '-.5em'
        }
    }
}));

interface Props {
    type: 'MakeIt' | 'MakeItAtm' | 'ShipIt' | 'PlanIt' | 'ScrapIt';
    quantityUnit?: string;
    styles?: string;
}

export default function QuantityUnitSelector({ type, quantityUnit, styles }: Props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { control, watch } = useForm({
        defaultValues: { quantityUnit: quantityUnit ?? 'pallets' }
    });
    const formQuantityUnit = watch('quantityUnit');
    const quantityUnitSelectorDispatch = useQuantityUnitSelectorDispatch();

    useEffect(() => {
        if (type === 'ShipIt') {
            dispatch(updateShippingDashboardQuantityUnit(formQuantityUnit as QuantityUnit));
        } else if (type === 'MakeIt') {
            dispatch(updateMakeItGuidedQuantityUnit(formQuantityUnit as QuantityUnit));
        } else if (type === 'PlanIt') {
            dispatch(updatePlanItGuidedQuantityUnit(formQuantityUnit as QuantityUnit));
        } else if (type === 'ScrapIt') {
            dispatch(updateShippingDashboardQuantityUnit(formQuantityUnit as QuantityUnit, true));
        } else {
            quantityUnitSelectorDispatch(
                formQuantityUnit === 'pallets'
                    ? { type: 'TOGGLE_QUANTITY_UNITS_PALLETS' }
                    : { type: 'TOGGLE_QUANTITY_UNITS_EACHES' }
            );
        }
    }, [formQuantityUnit]);

    return (
        <div data-testid="quantity-unit-selector" className={styles}>
            <form>
                <RadioInput
                    styles={classes.radioGroup}
                    label={<Trans i18nKey="enterQuantityUnit">{enUS.enterQuantityUnit}</Trans>}
                    name="quantityUnit"
                    testId="quantityUnit"
                    control={control}
                    errors={{}}
                    radioControls={shipmentQuantityUnits.map((unit) => ({
                        value: unit.value,
                        label:
                            unit.value === 'eaches' ? (
                                <>
                                    <Trans i18nKey={unit.name.toLowerCase()}>{unit.name}</Trans>
                                    <Tooltip
                                        content={
                                            <Trans i18nKey={`eachesTooltip`}>
                                                {enUS.eachesTooltip}
                                            </Trans>
                                        }
                                        ariaLabel={`${unit.value}-tooltip`}
                                    />
                                </>
                            ) : (
                                <Trans i18nKey={unit.name.toLowerCase()}>{unit.name}</Trans>
                            )
                    }))}
                />
            </form>
        </div>
    );
}
