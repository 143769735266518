import React, { useState, useEffect } from 'react';
import { makeStyles, Box, Tabs, Tab, Grid, Typography } from '@material-ui/core';
import {
    addFormItemButtonBlue,
    ballBlue,
    ballDrkBlue,
    black,
    blackWeight,
    boldWeight,
    inactiveTabBlue,
    lato,
    ltBlueGrey,
    ltBlueGrey_6,
    medium,
    small,
    white,
    xl
} from '../../../../themes/globalConstants';
import { MakeItDraftsProvider } from '../context/MakeItDraftsSummaryContext';
import MakeItDraftsSummaryTab from './MakeItDraftsSummaryTab';
import TranslationService from '../../../../utility/services/translation-service';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { MakeItDraftsState, ProductionOrderDraft } from '../../../../store/reducers/make-it-drafts';
import moment from 'moment';
import WarningAlert from '../../../reusable/atoms/WarningAlert';
import { useTranslation } from 'react-i18next';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

export enum TabStatus {
    'openDrafts' = 0,
    'archivedDrafts' = 1
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: '4.5em',
        position: 'relative',
        '& h2': {
            fontSize: xl,
            fontFamily: lato,
            fontWeight: blackWeight,
            color: theme.palette.secondary.main
        }
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        padding: '1em'
    },
    tabPanel: {
        width: '100%'
    },
    icon: {
        color: ballBlue
    },
    counter: {
        width: '2em',
        height: '2em',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '0.5em',
        fontSize: small,
        fontWeight: blackWeight,
        backgroundColor: ltBlueGrey,
        color: ballDrkBlue
    },
    selectedCounter: {
        backgroundColor: addFormItemButtonBlue,
        color: white
    },
    tabLabel: {
        backgroundColor: inactiveTabBlue,
        height: '56px',
        borderTopLeftRadius: '0.625em',
        borderTopRightRadius: '0.625em',
        '&:not(:first-of-type)': {
            marginLeft: '8px'
        },
        padding: '0 3em',
        color: ballDrkBlue,
        textTransform: 'none',
        maxWidth: 'none'
    },
    downloadWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    tabLabelText: {
        fontSize: medium,
        fontWeight: blackWeight
    },
    selectedTab: {
        backgroundColor: white,
        color: black,
        boxShadow: '0px 0px 6px rgba(185, 211, 220, 0.8), inset 0px -3px 0px #009BBB'
    },
    informationalAlert: {
        marginLeft: 'auto',
        marginTop: '2em',
        borderRadius: '.125em',
        justifyContent: 'center',
        '& .MuiAlert-message': {
            '& .MuiTypography-root': {
                color: black,
                fontWeight: boldWeight
            }
        },
        backgroundColor: ltBlueGrey_6
    }
}));

const TabPanel = (props: TabPanelProps) => {
    const classes = useStyles();
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`plan-it-tabpanel-${index}`}
            aria-labelledby={`plan-it-tab-${index}`}
            {...other}
            className={classes.tabPanel}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
};

// This assigns props for accessibility purposes
const a11yProps = (index: any) => {
    return {
        id: `plan-it-tab-${index}`,
        'aria-controls': `graphics-tabpanel-${index}`
    };
};

const MakeItDraftsSummaryTabs = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [value, setValue] = useState<Number>(0);
    const [openDrafts, setOpenDrafts] = useState<ProductionOrderDraft[]>([]);
    const [archivedDrafts, setArchivedDrafts] = useState<ProductionOrderDraft[]>([]);

    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { drafts } = useTypedSelector<MakeItDraftsState>((state) => state.makeItDrafts);

    const TabLabel = (label: string, count: number, index: number) => {
        return (
            <Grid container justify="center" alignItems="center">
                <Typography className={classes.tabLabelText}>{label}</Typography>
                <Typography
                    component="span"
                    className={`${classes.counter} ${value === index && classes.selectedCounter}`}
                >
                    {count}
                </Typography>
            </Grid>
        );
    };

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        const val = TabStatus[newValue];
        window.history.replaceState('', '', `?status=${val}`);
        setValue(newValue);
    };

    const infoIcon = (
        <img src={process.env.PUBLIC_URL + '/assets/InfoTriangle_Icon.svg'} alt="Info Icon" />
    );

    useEffect(() => {
        if (!!drafts) {
            setOpenDrafts(
                drafts.map((draft) => ({
                    draft: draft.draft,
                    orders: draft.orders.filter((order) =>
                        moment(order.lines[0].requestedDate).isSameOrAfter(moment.now())
                    )
                }))
            );
            setArchivedDrafts(
                drafts.map((draft) => ({
                    draft: draft.draft,
                    orders: draft.orders.filter((order) =>
                        moment(order.lines[0].requestedDate).isBefore(moment.now())
                    )
                }))
            );
        }
    }, [drafts]);

    return (
        <MakeItDraftsProvider value={{ activeTab: value === 0 ? 'openDrafts' : 'archivedDrafts' }}>
            <WarningAlert
                warningMessage={
                    value === 0
                        ? t(
                              'draftDisplayWarning',
                              'Drafts will no longer show after the lock window begins'
                          )
                        : t(
                              'archiveDraftWarning',
                              'Archived drafts will expire and no longer show in archive after 30 days.'
                          )
                }
                showWarning={true}
                className={classes.informationalAlert}
                icon={infoIcon}
            />
            <div className={classes.root} data-testid="make-it-drafts-tabs">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="plan it summary tabs"
                    scrollButtons="auto"
                    variant="scrollable"
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab
                        label={TabLabel(
                            TranslationService.getTranslatedText(cultureCode, 'openDrafts'),
                            !!openDrafts
                                ? openDrafts.filter((draft) => draft.orders.length > 0).length
                                : 0,
                            0
                        )}
                        {...a11yProps(0)}
                        className={`${classes.tabLabel} ${value === 0 && classes.selectedTab}`}
                        value={0}
                        data-testid="open-drafts-tab"
                    />
                    <Tab
                        label={TabLabel(
                            TranslationService.getTranslatedText(cultureCode, 'archivedDrafts'),
                            !!archivedDrafts
                                ? archivedDrafts.filter((draft) => draft.orders.length > 0).length
                                : 0,
                            1
                        )}
                        {...a11yProps(1)}
                        className={`${classes.tabLabel} ${value === 1 && classes.selectedTab}`}
                        value={1}
                        data-testid="archived-drafts-tab"
                    />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <MakeItDraftsSummaryTab
                        drafts={openDrafts.filter((draft) => draft.orders.length > 0)}
                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <MakeItDraftsSummaryTab
                        drafts={archivedDrafts.filter((draft) => draft.orders.length > 0)}
                    />
                </TabPanel>
            </div>
        </MakeItDraftsProvider>
    );
};

export default MakeItDraftsSummaryTabs;
