import React from 'react';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { selectIsLargeCustomerAccount } from '../../../store/selectors';
import MakeItATM from './MakeItATM';
import MakeItSpot from './MakeItSpot';

const MakeIt = () => {
    const isLargeCustomer = useTypedSelector<boolean>(selectIsLargeCustomerAccount);

    return isLargeCustomer ? <MakeItATM /> : <MakeItSpot />;
};

export default MakeIt;
