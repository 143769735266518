export const esES = {
    // Reusable
    cans: 'Latas',
    view: 'Visualizar',
    design: 'Diseño',
    filling: 'Envase',
    canHandling: 'Manejo de Latas',
    regulations: 'Regulación',
    labLiquid: 'Laboratorio de Liquidos',
    sustainability: 'Sustentabilidad',
    faq: 'Preguntas frecuentes',
    designInspiration: 'Inspiración de Diseño',
    backToAllProducts: 'Volver a todos los Productos',
    selectShipTo: 'Seleccionar Envie a',
    new: 'Nuevo',
    existing: 'Existente',
    unknown: 'Desconocido',
    underReview: 'En revisión',
    complete: 'Completa',
    submitted: 'Enviado',
    cancelled: 'Cancelado',
    howCanWeHelp: '¿Cómo podemos ayudarlo?',

    // -- NAVIGATION -- //
    home: 'Incio',
    homepage: 'Mi Página de Inicio',
    ourProcess: 'Proceso',
    processOverview: 'Resumen de Proceso',
    labDashLiquid: 'Laboratorio / Líquido',
    handling: 'Manejo',
    regulatory: 'Regulación',
    ourProducts: 'Los Productos',
    productPortfolio: 'Catálogo de Productos',
    contactUs: 'Contáctenos',
    logout: 'Sair',
    navaigation: 'Navegación',
    backToPortfolio: 'Volver a Catálogo de Productos',
    dashboard: 'Tablero',
    payables: 'Cuentas por pagar',
    discoverBall: 'Descubre Ball',
    productionOrderSummary: 'Resumen de orden de producción',
    deliveryOrderSummary: 'Resumen del Pedido de Entrega',
    shipmentSummary: 'Resumen de envío',
    paymentsSummary: 'Resumen de pagos',
    ourProcessLearningContent: 'Nuestro proceso y contenido de aprendizaje',
    howToGuides: 'Guías para The Source',
    guided: 'Guiado',
    buildAndEstimateNav: 'Construir y estimar',
    buildNav: 'Construir',
    graphics: 'Gráficos',
    overview: 'Resumen',
    theProcess: 'El proceso',
    ballProductPortfolio: 'Portafolio de productos Ball',
    guidesLink: 'Guías prácticas',
    faqLink: 'FAQ',
    makeItSummary: 'Resumen de Make It',
    shipItSummary: 'Resumen de Ship It',
    scrapItRequests: 'Scrap It Requests',
    scrapItRequestOrderNumber: 'Scrap It Request Order #',
    submitNewGraphicLink: 'Envíe un gráfico nuevo',
    submitNewLiquidTestLink: 'Enviar nueva prueba de líquidos',
    submittedLiquidTests: 'Pruebas de líquidos enviadas',
    openLiquidTests: 'Pruebas abiertas de líquidos',
    admin: 'Administración',
    userAdmin: 'Administrador de Usuarios',
    manageUsers: 'Administrar usuarios',
    manageLocations: 'Administrar ubicaciones',
    addShipTo: 'Add Ship To',
    customerInformation: 'Customer Information',
    newShipToInformation: 'New Ship To Information',
    addNewUser: 'Añadir Nuevo Usuario',
    myAccount: 'Mi Cuenta',
    pendingAccessRequests: 'Solicitudes de Acceso Pendientes',
    myAccess: 'Mi Acceso',
    graphicsInProgress: 'Gráficos en Curso',
    exit: 'Salida',
    editUser: 'Editar Usuario',
    viewEditUser: 'Ver/Editar Usuario',
    lastLogin: 'Último Inicio de Sesión',
    authorizationToManufacture: 'Autorización para fabricar',
    comingSoon: '<i>Próximamente<i/>',
    makeItDrafts: 'Borradores de Make It',
    checkPendingRequests:
        'Comprobar las solicitudes pendientes y el registro y gestionar los usuarios activos.',
    monitorAndRequest: 'Supervisar y solicitar nuevas ubicaciones.',
    giveAccountAccess: 'Dar acceso a la cuenta a nuevos usuarios.',
    viewPricingInformation: 'Ver información de precios.',
    viewActiveAndInactiveProducts:
        'Ver productos activos e inactivos y administrar el estado de los productos.',
    buildSubmitUpdate: 'Construir, enviar y actualizar estimaciones de su pedido.',
    createMakeItOrders: 'Crear órdenes “Make It”.',
    scheduleOrders: 'Programar pedidos de entrega y recogida.',
    learnMoreGraphics:
        'Obtener más información acerca de la separación de colores, el envío de material gráfico y la preparación de archivos.',
    ensureHandling:
        'Asegurarse de que el equipo conoce cómo manipular las latas una vez que llegan.',
    whyTesting:
        '¿Por qué es fundamental hacer pruebas de compatibilidad a su bebida para el proceso de envasado?',
    bestCanningPractices: 'Mejores prácticas para el envasado de su líquido.',
    learnAboutRegulations: 'Aprender acerca de las regulaciones específicas de cada país.',
    helpPromoteSustainability:
        'Ayudándole a promover el esfuerzo de su marca en torno a lasustentabilidad.',

    // -- WELCOME WIDGET -- //
    welcomeToTheSource: 'Bienvenido a <i>The Source</i>',
    welcomeWidgetDesc:
        '<i>The Source</i> será su ventanilla única para la entrega de gráficos. Con el tiempo, esta página tendrá métricas útiles como Órdenes de producción "Make It", Órdenes de entrega "Ship It", Gestión de productos y más. Agradecemos sus comentarios y su paciencia mientras mejoramos su experiencia.',
    readyToOrder: '¿Listo para ordenar productos?',
    makeIt: 'Make It',
    readyToSchedule: '¿Listo para programar un envío?',
    shipIt: 'Ship It',
    shipItOnly: 'Ship It Solamente',

    // -- USER ADMIN WIDGET -- //
    userAdministration: 'Administración de Usuarios',
    setupNewUser:
        'Configurar un nuevo usuario con los permisos y ubicaciones correctos en <i>The Source</i>.',
    userAdminDashboard: 'Tablero de Administración de Usuarios',
    viewPendingRequests:
        'Configure un nuevo usuario con los permisos y ubicaciones correctos en <i>The Source</i>.',
    viewUserAdminDashboard: 'Ver Tablero de Administración de Usuarios',

    // -- GRAPHICS WIDGET -- //
    startGraphicsProcess:
        'Comience el proceso de los gráficos al cargar un nuevo gráfico para su producto.',
    newGraphicLink: 'Comenzar un gráfico nuevo',
    checkGraphicsProcess:
        'Revise en qué etapa del proceso se encuentran sus gráficos y si se requiere que usted realice alguna acción.',
    viewInProgressGraphics: 'Ver gráficos en progreso',

    // -- ORDERING WIDGET -- //
    viewProductPlanning: 'Vea planificación de producto',
    productPlanningTileDesc:
        'Vea balances de producción y envíos programados hasta dentro de dieciséis semanas.',
    makeProducts: 'Productos Make',
    makeProductsTileDesc:
        'Cree y actualice las órdenes Make It. Revise el estado de sus órdenes Make It.',
    viewEditMakeItOrders: 'Vea/Edite órdenes Make It',
    shipProducts: 'Productos Ship',
    shipProductsTileDesc:
        'Programar y actualizar órdenes Ship It y recogidas. Revise el estado de sus órdenes Ship It. ',
    viewEditShipItOrders: 'Ver/Editar órdenes Ship It',

    // -- DASHBOARD -- //
    makeItOrders: 'Órdenes de Make It',
    orderSummary: 'Resumen del órdenes',
    openOrders: 'Órdenes abiertas',
    agedBalance: 'Saldo antiguo',
    paymentsPastDue: 'Pagos pendientes vencidos',
    nextWeek: 'Semana próxima',
    twoToFourWeeks: 'En 2 a 4 semanas',
    fiveToEightWeeks: 'En 5 a 8 semanas',
    ninePlusWeeks: 'En más de 9 semanas',
    shipItOrders: 'Órdenes Ship It',
    shipmentsSummary: 'Resumen de envíos',
    arrivingThisWeek: 'Arribos esta semana',
    futureShipments: 'Envíos próximos',
    viewAllFutureShipments: 'Ver todos los envíos próximos',
    dataIsFiltered: 'Los datos están filtrados. Haga clic en el icono para borrar el filtro:',
    createNewShipItOrder: 'Crear nuevo pedido Ship It',
    createNewMakeItOrder: 'Crear nuevo pedido Make It',
    agedSku: 'SKU envejecidos',
    draftOrders: 'Draft Orders',
    agedSkus: 'Unidades de mantenimiento de existencias (SKU) antiguas',

    // -- FAQ Page -- //
    searchFAQs: 'Buscar preguntas frecuentes',
    searchFAQplaceholder: 'Cans, Bottles, etc!',

    // -- QUICK VIEW -- //
    production: 'Producción',
    limitedProductionRun: 'Producción Limitada',
    seeFullProductDtls: 'Ver todos los detalles del producto',
    timesPerYear: 'Veces al Año',

    // -- ATM PRODUCT FORECAST -- //
    atmProductForecastBannerTitle: 'Información del producto',
    lockWindow: 'Lock Window',
    makeItLockWindow: 'Ventana de Bloqueo Make It',
    firstAvailableWeek: 'First Available Week',
    viewAtmProductForecast: 'Ver la Información de mi producto',
    customerProductId: 'ID del producto del cliente',
    productInformationToday: '{{shipToId}} - Product Information as of {{today}}',
    ballProductId: 'ID del producto de Ball',

    // -- PRODUCT PORTFOLIO -- //
    leadTimesSubjectToChange:
        '*Todos los plazos de entrega son estimativos y están sujetos a cambios.',
    currentBallCustomers: 'Clientes actuales de Ball: ',
    leadTimesProduction:
        'Los plazos de entrega de producción para los gráficos nuevos comienzan una vez que estos estén completos y aprobados.',
    leadTimesMayVarySixteenOzAlumitek:
        'Este producto está disponible actualmente. Los plazos de entrega para recibir su primer pedido pueden variar. Hable con el representante de ventas para obtener más detalles.',
    all: 'Todos',
    bottles: 'Botellas',
    ends: 'Tapas',
    end: 'Tapa',
    lookingForInspiration: 'Buscando Inspiración',
    pallets: 'Palés',
    itemsPerPallet: 'Cuantidad por Palet',
    lookingForSomething: '¿No encuentras lo que necesitas?',
    sendUsYourComments: 'Cuéntanos qué estás buscando',
    minOrderPallet: 'Orden Mínima de Paletas',
    cansPerPallet: 'Latas Por Palet',
    quickLook: 'Vistazo rápido',
    select: 'Seleccionar',
    seeDetailsBtn: 'Ver Detalles Completos del Producto',
    compareProducts: 'Comparar Productos',
    pleaseSelectProducts: 'Seleccione productos para obtener un presupuesto.',
    endTile: 'Tapas',
    compare: 'Comparar',
    myProjects: 'Mis proyectos',
    belowAreYourSavedProjects:
        'A continuación, se muestran sus proyectos guardados en los últimos 180 días.',
    projectName: 'Nombre del proyecto',
    productCode: 'Código del producto',
    savedOn: 'Guardado el',
    viewProject: 'Ver proyecto',
    capacityCheckStatus: 'Estado de verificación de capacidad',
    dateRequested: 'Fecha de la solicitud',
    ropp38CappingText:
        'Product requires special capping. Please contact Silgan 765-983-9282 for pallet specifications.',

    // -- FACETS -- //
    filterBy: 'Filtrar Por',
    clearAll: 'Limpiar Todos',
    // Facet Category
    stylesCategory: 'Gama de productos',
    sizesCategory: 'Talla',
    featuresCategory: 'Mejoras de producto',
    endsCategory: 'Tapas Compatibles',
    // Facet Tooltips
    stylesTooltip: 'Gama de productos tooltip',
    sizesTooltip: 'Talla tooltip',
    featuresTooltip: 'Mejoras de producto tooltip',
    endsTooltip: 'Tapas Compatibles tooltip',
    style: 'Estilo',
    size: 'Tamaño',
    features: 'Caracteristicas',

    // -- PRODUCT DETAILS -- //
    requestEstBtn: 'Solicitar una Cotización',
    productDetails: 'Detalles del producto',
    printGuide: 'Guía de Impresión',
    customerDesignGuide: 'Guía de diseño del cliente',
    download: 'Descargar',
    canSpecification: 'Especificaciones de Lata',
    dieLine: 'Die Line',
    palletSpecifications: 'Especificaciones de Palet',
    greatFor: 'Ideal para',
    specifications: 'Especificaciones',
    currentLeadTime: 'Plazo de Producción',
    fillingAvailability: 'Disponibilidad de Envase',
    compatibleEnds: 'Tapas Compatibles',
    weeks: 'Semanas',
    months: 'Meses',
    minimum: 'Mínimo',
    productEnhancements: 'Mejora de Producto',
    compatibleFilling: 'Envase Compatible',
    pasteurization: 'Pasteurización',
    hotFill: 'Llenado en Caliente',
    coldFill: 'Llenado en Frio',
    color: 'Color',
    aluminum: 'Aluminio',
    printingOptions: 'Tipos de Impresión: Lata Impresa, Lata con Sleeve, Lata Brillante',
    // Ordering
    ordering: 'Ordenar',
    pricePerThousand: 'Precio por Millar',
    quantityPerPallet: 'Cantidad por Palet',
    minOrder: 'Orden Mínima',
    // Design Specs
    designSpecs: 'Especificaciones de Diseño',
    circumference: 'Circunferencia',
    designLength: 'Longitud de Diseño (Circunferencia)',
    designHeight: 'Altura de Diseño',
    maxTALength: 'Área de texto máxima (Longitud)',
    maxTAHeight: 'Área de texto máxima (Altura)',
    maximumColors: 'Número máximo de colores',
    windowLimitWidth: 'Límite de Ancho',
    windowLimitHeight: 'Límite de Altura',
    underOverlap: 'Área de superposición',
    // Can Specs
    canSpecs: 'Especificaciones de Lata',
    endSize: 'Tamaño de la Tapa',
    bodyOutsideDiameter: 'Diámetro exterior del cuerpo',
    flangedCanHeight: 'Altura de Pestaña',
    coldFillVolume: 'Volumen de Envase',
    flangedCanWidth: 'Ancho de Pestaña',
    endDiameter: 'Diámetro exterior del cuerpo',
    // Can Finishes and Effects
    canEffectsAndFeatures: 'Efectos y características disponibles para esta lata',
    canFinishesAndEffects: 'Efectos Especiales',
    inks: 'Tintas',
    overvarnish: 'Barnices',
    effects: 'Efectos',
    specialFeatures: 'Características Especiales',

    // -- END DETAILS -- //
    endDetails: 'Detalle de la Tapa',
    endFinishesAndEffects: 'Tapas Especiales',
    compatibleCans: 'Latas Compatibles',
    endFeaturesAndEffects: 'Tapas Especiales',
    orderIndividually: 'Se puede pedir individualmente',
    roppClosures: 'ROPP Closures se venden a través de terceros',
    endsPack: 'Paquete de Información de Tapas',

    // -- PRODUCT COMPARISON -- //
    addProduct: 'Añadir Producto',
    details: 'Detalles',
    name: 'Nombre',
    type: 'Tipo',
    maxColors: 'Max colores',
    innovativeFeatures: 'Características innovadoras',
    leadTimes: 'Tiempos de entrega',
    currentFillingAvail: 'Disponibilidad de llenado actual',
    products: 'Productos',
    addAnotherToCompare: 'Agregue Otro Producto para Comparar',
    removeSelection: 'Eliminar selección',
    addToCompare: 'Añadir a comparar',
    prodComparisonDesc:
        'Compare hasta tres productos diferentes a la vez, sin importar el volumen o el estilo. Vea qué productos ofrecen',
    prodEstimateDesc:
        'Nuestro estimador puede ayudarlo a comprender mejor el costo de ordenar latas con nosotros. Tenga en cuenta algunas cosas al crear su estimación',
    prodHasMinOrder: 'Cada producto tiene un pedido mínimo',
    orderInFullPallets:
        'Las estimaciones y los pedidos solo se pueden colocar en paletas completas',
    orderInFullTrucks:
        'Recomendamos ordenar en camiones completos, la mayoría de los camiones pueden caber en aproximadamente 25 paletas. Menos de la carga completa de camiones puede incurrir en cargos adicionales',
    productComparison: 'Comparación de productos',
    compareBuildEstimate: 'Comparar, construir y estimar',
    compareBuild: 'Comparar y construir',
    myProject: 'Mi proyecto',

    // -- CONTACT FORM -- //
    yourName: 'Su Nombre',
    email: 'Dirección de correo electrónico',
    yourCountry: 'Tu País de Envase',
    yourCity: 'Tu Ciudad de Envase',
    subjectLine: 'Linea de Asunto',
    contactMessage: 'Dinos como podemos ayudar...',
    submit: 'Enviar',
    cancel: 'Cancelar',
    saveDraft: 'Guardar Borrador',
    sendUsANote: '¿Cómo podemos ayudar?',
    msgSuccessful: 'Mensaje enviado con éxito',
    msgFailed: 'No se pudo enviar el mensaje. Vuelve a intentarlo en otro momento.',
    sending: 'Enviando',
    close: 'Cerrar',
    contactReason: 'Motivo de contacto',
    contact: 'Tengo una pregunta',
    support: 'Necesito ayuda',
    cantLogIn: 'No puedo iniciar sesión',

    // --- PRODUCT ESTIMATE -- //
    getAnEstimate: 'Obtener una cotización',
    desiredAnnualQuantity: 'Cantidad Anual Deseada',
    enterQuantity: 'Escribir Cantidad',
    // wouldYouLikeEndsWithThisProduct: '¿Te gustaría tapas con este producto?',
    remove: 'Eliminar',
    estimateFailedToSend: 'No se pudo enviar el cálculo, vuelva a intentarlo en otro momento',
    productEstInquiry: 'Consulta de Estimación de Producto',
    submittingEstimate: 'Enviar la información a continuación para un presupuesto',
    enterValueGreaterThan: 'Por favor, introduzca un valor mas que 0',

    // -- CONFIRMATION POP UP -- //
    backToHome: 'Volver al inicio',
    thankYou: 'Gracias',
    yourRequestHasBeenSubmitted: 'Su pedido ha sido enviado!',
    someoneFromOurTeamWillBeInTouchSoon:
        'Alguien de nuestro equipo se pondrá en contacto tan pronto.',

    // -- REGISTRATION -- //
    registrationHeader: 'Hagamos latas juntos',
    registrationDescEMEA:
        'Ya casi estamos allí, solo necesitamos un poco de información de usted para llevarlo al Portal del cliente de Ball, donde puede obtener más información sobre las latas y cómo hacer un pedido.',
    registrationDescription:
        'Ya casi estamos allí, solo necesitamos un poco de información de usted para llevarlo al Portal del cliente de Ball, donde puede obtener más información sobre las latas, cómo hacer un pedido y estimar los costos.',
    region: 'Región',
    language: 'Idioma',
    aboutYourCompany: 'Acerca de su empresa',
    companyName: 'Nombre de compañía',
    country: 'País',
    stateProvince: 'Estado/Provincia',
    city: 'Ciudad',
    postalCode: 'Código Postal',
    yearsInBusiness: 'Antiguedad de la empresa (años)',
    numberOfEmployees: 'Numero de Empleados',
    estimatedAnnualRevenue: 'Ventas Anuales Estimadas',
    beverageInRetail: '¿Actualmente comercializa alguna bebida?',
    currentPackageType: 'Tipo de empaque actual',
    beveragePackagingNeeds: 'Sus necesidades de empaque de bebidas',
    beverageCategory: 'Categoría de la bebida',
    productSize: 'Tamaño del producto',
    expectedAnnualQuantity: 'Cantidad Anual esperada',
    canningLine: '¿Tiene o planea comprar una línea de envase?',
    copackerName: 'Nombre del coenvasador',
    fillingCountry: 'País de Envase',
    fillingCity: 'Ciudad de Envase',
    additionalComments: 'Comentarios adicionales: (Opcional)',
    artworkComments: 'Comentarios de ilustraciones (Opcional)',
    setupCustomerPortalAccount: 'Configure su cuenta del Portal del Cliente',
    firstName: 'Nombre',
    lastName: 'Apellido',
    phoneNumber: 'Número de teléfono',
    password: 'Contraseña',
    confirmPassword: 'Confirmar contraseña',
    alreadyRegistered: '¿Ya registrado?',
    accountCreated: 'Este correo electrónico ya está vinculado a The Source.',
    signIn: 'Inicie Sesión en el Portal del Cliente',
    signInConfirmation: 'Inicie sesión en The Source y si es necesario, restablezca su contraseña.',
    yes: 'Sí',
    no: 'No',
    incorrectEmailAddress: 'Correo Electrónico es Incorrecto',
    atLeastEightLetters: 'Al menos 8 letras',
    atLeastOneCapitalLetter: 'Al menos una letra mayúscula',
    atLeastOneLowerCaseLetter: 'Al menos una letra minúscula',
    atLeastOneNumber: 'Al menos un numero',
    mustNotBeACommonPassword: 'No debe ser una contraseña común',
    passwordsShouldMatch: 'Las contraseñas deben coincidir!',
    year: 'año',
    printType: '¿Estás buscando Latas sin Impresión, Litografiadas, o Latas con Sleeves?',
    iAgree: 'Acepto los',
    termsOfUse: 'Términos de uso y las Políticas de privacidad',
    million: 'Millón',
    newToBeverage: 'Nuevo en Bebidas',
    title: 'Puesto',
    jobTitle: 'Job Title',
    selectSecurityQuestion: 'Pregunta',
    securityAnswer: 'Respuesta',
    invalidSecurityAnswer: 'La respuesta debe ser superior a 5 caracteres',
    emailValidationError: 'El correo electrónico no coincide con el patrón requerido',
    passwordValidationError: 'La contraseña no debe ser una contraseña común',
    securityAnswerValidationError:
        'La respuesta a la pregunta de seguridad es demasiado débil, la respuesta no debe ser parte de la pregunta.',
    unknownValidationError:
        'Hubo un error al crear una nueva cuenta de usuario. Comuníquese con la mesa de ayuda.',
    selectSecurityQuestionPrompt:
        'Seleccione una pregunta y una respuesta de seguridad para que pueda restablecer automáticamente su contraseña si tiene problemas para iniciar sesión en su cuenta.',
    emailOptOut1:
        'By submitting this form, you are subscribing to receive the latest news and updates straight to your inbox.',
    emailOptOut2:
        'Ball Corporation may also contact you from time to time about our products and services as detailed in our',
    privacyPolicy: 'Privacy Policy',
    emailOptOut3: 'Please check this box if you’d rather not receive these emails.',

    // Registration Print Types
    brightCans: 'Lisa',
    sleevedCans: 'Sleeve',
    printedCans: 'Litografada',

    // Registration Beverage Categories
    beverageCategoryBeer: 'Cerveza',
    beverageCategoryBeerCraft: 'Cerveza - Artesanal',
    beverageCategoryBeerDomestic: 'Cerveza - Nacional',
    beverageCategoryBeerImport: 'Cerveza - Importada',
    beverageCategoryCsd: 'Refresco gaseoso (CSD)',
    beverageCategoryCider: 'Sidra',
    beverageCategoryCoffee: 'Café',
    beverageCategoryIcedCoffee: 'Café helado',
    beverageCategoryEnergyDrink: 'Bebidas energizantes',
    beverageCategoryFab: 'FAB’s',
    beverageCategoryFmb: 'Bebida de malta aromatizada (FMB)',
    beverageCategoryHardLiquor: 'Destilado',
    beverageCategoryThc: 'Cáñamo / CBD / THC',
    beverageCategoryJuice: 'Jugo',
    beverageCategoryProtein: 'A base de proteína o leche',
    beverageCategoryRtd: 'Bebidas Mezcladas',
    beverageCategorySpirits: 'Bebidas espirituosas',
    beverageCategorySportsDrink: 'Bebidas deportivas',
    beverageCategoryTea: 'Té',
    beverageCategoryIcedTea: 'Te helado',
    beverageCategoryWater: 'Agua',
    beverageCategoryWine: 'Vino',
    beverageCategoryHardSeltzer: 'Hard Seltzer',
    beverageCategoryOther: 'Otro',

    // Registration Security Questions
    securityQuestionFood: '¿Cuál es la comida que menos te gustaba de niño?',
    securityQuestionStuffAnimal: '¿Cómo se llamaba tu primer peluche?',
    securityQuestionAward: '¿En qué ganaste tu primera medalla o premio?',
    securityQuestionComputerGame: '¿Cuál fue el primer juego de ordenador al que jugaste?',
    securityQuestionMovieQuote: '¿Cuál es tu cita cinematográfica favorita?',
    securityQuestionSportsTeam:
        '¿Cuál era la mascota del primer equipo deportivo en el que jugaste?',
    securityQuestionDessert: '¿Cuál era el postre favorito de tu abuela?',
    securityQuestionCook: '¿Qué fue lo primero que aprendiste a cocinar?',
    securityQuestionDreamJob: '¿Cuál era el trabajo de tus sueños cuando eras niño?',
    securityQuestionVacation: '¿Dónde pasaste tus vacaciones favoritas?',

    // Validation
    required: 'Necesario',

    // Graphic Intake - Vendor
    formOverview: 'Resumen del formulario',
    graphicsSubmissionInfo: 'Información de Envío de Gráficos',
    productOptions: 'Opciones de Producto',
    colorMatchingOptional: 'Coincidencia de Color (Opcional)',
    uploadGraphicsAndSubmit: 'Subir Gráficos y Enviar',
    submitGraphics: 'Envía tus Gráficos',
    graphicUploadTimeout:
        'se ha cronod de tiempo de espera de carga gráfica. Inténtelo de nuevo más tarde.',
    requiresSpecialty:
        'usted señaló que su producto requiere una capa especial o especialidad, por favor seleccione su recubrimiento, acabado o efecto. Inténtelo de nuevo más tarde.',
    requiresSubmitter: 'por favor agregue una dirección de correo electrónico válida.',
    fillOutRequiredFields: 'Todos los campos obligatorios deben completarse antes de enviar',
    dateErrors:
        'Asegúrese de que la fecha solicitada, la fecha de venta al por menor y la fecha de venta al por menor sean fechas válidas.',
    uploadFileError: 'Cargue un archivo zip válido antes de enviar',
    intakeFailedToSave: 'El formulario de admisión de gráficos no se pudo guardar',
    saveProgress: 'Guardar progreso',
    saveProgressEmea: 'Guardas los cambios',
    graphicDraftSaved: 'Borrador de Admisión Gráfica Guardado',
    graphicDraftSavedEmea: 'Se guardó el borrador del formulario de admisión de gráficos',
    promotionStartDatePrior:
        'Asegúrate de que la fecha de inicio de tu promoción sea anterior a la fecha de finalización de la promoción.',
    promotionStartDateAfter:
        'Por favor, asegúrese de que su fecha de promoción sea posterior a la fecha solicitada',
    unknownError:
        'une erreur inconnue s’est produite.  S’il vous plaît essayer à nouveau plus tard.',
    requestedByDate: 'Fecha solicitada',
    requestedDateHelper: 'Fecha solicitada para sus latas',
    inRetailDate: 'Fecha en el mercado',
    shortTermStartDateHelper: 'Fecha en que los productos terminados estarán en el mercado',
    validInRetailDateError:
        'Por favor, asegúrese de que la fecha en la venta al por menor es después de la fecha solicitada',
    outOfRetailDate: 'Fecha fuera de venta',
    shortTermEndDate: 'Fecha en que se estima que los productos terminados están fuera de venta',
    validOutOfRetailError:
        'Por favor, asegúrese de que la fecha fuera de venta al por menor es después de la fecha de venta al por menor',
    iDontKnow: 'No sé',
    typeOfGraphic: '¿Qué tipo de gráfico quieres enviar?',
    graphicType: 'Tipo Gráfico',
    blankForm: 'Formulario en blanco',
    replaceExistingGraphics: 'Reemplazar gráficos existentes',
    billToAddress: 'Facturación de la empresa',
    graphicYouAreUpdating: 'Gráfico que está actualizando',
    previousSubmissions: 'Presentaciones Anteriores',
    revisionToAnExistingGraphic: '¿Es una revisión de un gráfico existente?',
    existingGraphicName: 'Nombre Gráfico Existente',
    revisionGraphicNameHelper: 'Este es el nombre del gráfico que está revisando',
    enterGraphicNumberOrName: 'Introduzca un número gráfico o un nombre',
    existingGraphicNumber: 'Número gráfico existente',
    revisionGraphicNumberHelper: 'Este es el número gráfico que está revisando',
    newGraphicSubmissionName: 'Nuevo nombre de envío gráfico',
    submissionNameHelper: 'Asigne a este envío el nombre para futuras referencias',
    revisedSubmissionName: '¿Qué proyecto le gustaría revisar?',
    alphanumericPattern: 'Escriba un nombre usando alfanuméricos',
    conversion: '¿Es esta una conversión dura o blanda?',
    conversionTooltip:
        'Una conversión dura será obsoleta y reemplazará por completo la versión seleccionada de este gráfico. Una conversión blanda le permitirá mantener la versión seleccionada y la nueva versión de este gráfico.',
    shortTerm: '¿Es esta una promoción a corto plazo?',
    //graphicsContact: '',
    //customerSKUNo: '',
    //colorStandardsApprover: '',
    //colorMatchNote: '',
    //colorMatchNoteText: '',
    //canFinishes: '',
    //colorMatching: '',
    //dateCodeMatching: '',
    //colorMatch: '',
    graphicsContactInformation: 'Información de Contacto de Gráficos',
    customerNameHelper: 'Empresa que se Somete en Nombre de',
    yourCompany: 'Su Empresa',
    nameHelper: 'Nombre de la persona que envía este formulario',
    phoneNumberHelper: 'Número de teléfono de la persona que envía este formulario',
    emailAddress: 'Correo electrónico',
    submitterEmailHelper: 'Dirección de correo electrónico de la persona que envía este formulario',
    colorStandards: 'Información del Aprobador de Estándares de Color',
    approverName: 'Nombre del aprobador',
    approverNameHelper:
        'Especifique el nombre de la(s) persona(s( responsable(s) de la aprobación del diseño. Ball se asegurará de que estas personas reciban los documentos necesarios para su aprobación.',
    approverEmail: 'Correo electrónico del aprobador',
    approverEmailHelper: 'Dirección de correo electrónico del aprobador',
    approverPhoneNumberHelper:
        'Especifique el número de teléfono de la(s) persona(s) responsable(s) de la aprobación. Esto permite una comunicación rápida durante el proceso de desarrollo.',
    address: 'Dirección de envío del aprobador',
    addressHelper: 'Calle, ciudad, código postal',
    copyWarning:
        'Está a punto de enviar un nuevo gráfico, aprovechando la información proporcionada para un gráfico anterior. Revise y actualice cuidadosamente todos los campos para garantizar la precisión del nuevo gráfico.',
    revisionWarning:
        'Está a punto de enviar una revisión para un gráfico activo. Revise y actualice cuidadosamente todos los campos para garantizar la precisión del nuevo gráfico.',
    hundredMaxLengthError: 'Asegúrese de que el valor del campo es de 100 caracteres o menos',
    alphaNumericPatternError: 'Por favor, asegúrese de que el campo es alfanumérico',
    numericPatternError: 'Introduzca un valor numérico',
    glnNumberError: 'Ingrese un valor numérico de 13 dígitos',
    thirtyMaxLengthError: 'Asegúrese de que el valor del campo es de 30 caracteres o menos',
    fiftyMaxLengthError: 'Asegúrese de que el valor del campo es de 50 caracteres o menos',
    customerGraphicsID: 'Número de gráfico del cliente',
    customerGraphicsIDHelper: 'Número de  gráfico del cliente (opcional)',
    customerSKU: 'Número de SKU del cliente',
    customerSKUHelper: 'Número de SKU del cliente (opcional)',
    upc: 'UPC # / EAN',
    upcHelper:
        'Proporcione el número UPC completo o el código EAN, incluidos los dígitos iniciales (opcional)',
    productCodeHelper: 'Tamaño del producto en el que se ejecutará el gráfico',
    neckSize: 'Tamaño del cuello',
    neckSizeTooltip:
        'El tamaño del cuello debe ser 206 a menos que Ball haya autorizado previamente 209',
    lookingForDesignInspiration: '¿Buscas inspiración para el diseño?',
    specialties: '¿Su producto requiere recubrimientos o específicaciones especiales?',
    ink: 'Tinta',
    inkHelper: 'Opciones de tinta disponibles para este producto',
    effect: 'Efectos',
    effectHelper: 'Opciones de efectos disponibles para este producto',
    overvarnishHelper: 'Opciones de exceso de barniz disponibles para este producto',
    doYouNeedColorMatching:
        '¿Necesita un color que coincida con el aluminio existente u otro material?',
    colorMatchComment: 'Describa qué color le gustaría combinar en su garantía.',
    comments: 'Comentarios',
    colorMatchingText:
        'Es posible hacer coincidir los colores de las latas de aluminio existentes (no tiene que ser de Ball) o de otros materiales impresos (embalaje de cartón, lata / envoltura de botella / papel impreso, etc.). Al completar este formulario de admisión, se le proporcionará un documento imprimible para incluir en su paquete con su muestra de coincidencia de color y la dirección donde debe enviar su muestra por correo.',
    colorStandardText:
        'Ball proporciona un estándar de color para cada nueva etiqueta y todas las etiquetas vienen con cambios de color.',
    dateCode: 'Código de manufactura coincidente',
    dateCodeText:
        'Código de 9 o 10 dígitos ubicado cerca del código de barras UPC. La ubicación exacta variará según el tamaño de la lata y el fabricante.',
    dateCodeMinMaxError: 'Asegúrese de que el Código de fecha tenga 9 o 10 caracteres',
    invalidIntakeFile: 'Este tipo de archivo no es válido. Adjunte un archivo .zip',
    onUploadFileError: '*Por favor, cargue un archivo zip válido',
    onFileSizeUploadError: 'File must be below 12MB',
    uploadSection1:
        'Todas las latas de Ball se ejecutarán en un sustrato de aluminio con brillo sobre barniz a menos que se especifique lo contrario.',
    uploadSection2:
        'La disponibilidad de tintas o barnices especiales puede ser limitada; solicitar estos artículos en este formulario no garantiza la disponibilidad.',
    dragAndDropZipFileHere: 'Arrastre y suelte un archivo aquí o haga clic.',
    dragAndDropCsvFileHere: 'Arrastre y suelte un archivo aquí o haga clic.',
    dragAndDropFileHere: 'Arrastre y suelte un archivo aquí o haga clic.',
    dropHere: 'Suelte el archivo aquí',
    fileUploadedSuccessfully: 'Archivo cargado correctamente:',
    acceptedFileTypes:
        'Tipo de archivo aceptado: archivo Zip que puede incluir un archivo TIFF y/o de Illustrator recopilado con imágenes y fuentes suministradas o convertidas en contornos.',
    weRecommendPDF:
        'Recomendamos incluir un PDF de solo lectura en su archivo zip recopilado solo para fines de visualización.',
    acceptedFileTypesEmea: 'Accepted file types',
    weRecommendPDFEmea:
        'Note: .Zip files can include a TIFF and/or collected Illustrator file with images and fonts supplied or converted to outlines. We recommend including a read-only PDF in your collected zip file for viewing purposes only.',
    onUploadFileErrorEmea:
        '*One of the files you are trying to upload is not of an approved file type. Please remove and upload an approved file type (.pdf, .ai, .psd, .doc, .docx, .xls, .xlsx, .zip).',

    saveProgressAttachments:
        'No se guardan los documentos adjuntados cuando se selecciona «GUARDAR PROGRESO».',
    clearYourForm: 'Borrar su Formulario',
    clearFormConfirmation: '¿Está seguro de que desea borrar el formulario?',
    formInfoWillBeLost: 'Cualquier información se perderá.',
    yesClearForm: 'Sí, Forma Clara',
    excitedThankYou: '¡Gracias!',
    graphicsSubmitted: '¡Sus gráficos han sido enviados para su revisión!',
    submissionText:
        'Nuestro equipo revisará su envío para verificar su idoneidad y desarrollará una cotización. Un especialista en gráficos se pondrá en contacto dentro de 2 días hábiles.',
    subText:
        'Su formulario también está disponible para ver. Por favor guarde esto para sus registros.',
    intakeForm: 'Formulario de admisión: ',
    pleasePrintColorMatchingForm:
        'Para imprimir o descargar una copia de su formulario de coincidencia de color, por favor ',
    clickHere: 'haga clic aquí.',
    startNewForm: 'Iniciar nuevo formulario',
    backToDashboard: 'Volver a mi panel',
    backToDashboardEmea: 'Volver a mi tablero',
    graphicsColorSubmitted:
        'La información de resumen de gráficos está disponible para que la abra o descargue en su navegador.',
    submissionColorText:
        'Nuestro equipo revisará esta actualización y un especialista en gráficos se pondrá en contacto con usted en breve. Si desea revisar su envío o reimprimir un formulario de coincidencia de color, haga clic aquí.',
    viewColorMatchingInfo: 'Ver información de coincidencia de color',
    graphicsInformationMessageIE:
        'La información de resumen de gráficos está disponible para que la abra o descargue en su navegador.',
    colorMatchingInformationMessageIE:
        'Su información de coincidencia de color está disponible para que pueda abrirla o descargarla en su navegador.',
    companyId: 'ID de la compañía',
    submissionType: 'Tipo de envío',
    billTo: 'Cobrar a',
    approverAddress: `Dirección del Aprobador`,
    approverCountry: `País del Aprobador`,
    graphicsSummary: 'Resumen de Gráficos',
    graphicsSummaryEmea: 'Resumen de los gráficos',
    shippingAddress: 'Dirección de envío',
    yourColorMatchingInfo: 'Su información de coincidencia de color',
    pleasePrintThisCM:
        'Por favor, imprima esto e incluya en el envío con su objeto de coincidencia de color.',
    submissionName: 'Nombre del envío',
    graphicName: 'Graphic Name',
    colorMatchingDetails: 'Detalles de coincidencia de color',
    resolveFormErrors: 'Resuelva los errores de formulario para guardar un borrador.',
    cantSaveDraft: 'Nombre de envío necesario para guardar un borrador.',
    validEmailRequired: 'Se requiere un correo electrónico válido para guardar un borrador.',
    hard: 'Duro',
    soft: 'Suave',
    addAnotherApprover: 'Add Another Approver',

    // -- EMEA GRAPHICS INTAKE -- //
    // Form Overview
    designInfo: 'Informaciónes del diseño',
    colourInfo: 'Información Colores',
    marketInfo: 'Información del mercado',
    productQuality: 'Especificación de calidad del producto',
    approverInfo: 'La aprobacion del diseño',
    // General Info
    graphicsSubInfo: 'Información General',
    customerAccountHelper: 'Nombre del socio comercial de Ball',
    contractFiller: 'Rellanador',
    contractFillerHelper:
        'Envasador, complete sólo en caso de que el diseño no se haya especificado por el socio comercial de Ball',
    brandOwner: 'Propietario de la marca',
    brandOwnerHelper:
        'Propietario de la marca, complete solo si no se trata del  socio comercial de Ball',
    canSize: 'Tamaño de la lata',
    canSizeHelper: 'Seleccione el tamaño de lata de la lista desplegable',
    canType: 'Tipo de lata',
    canTypeHelper: 'Seleccione el tipo de lata y diámetro del cuello de la lista desplegable',
    metalType: 'Metal',
    metalTypeHelper:
        'Seleccione el metal de la lista desplegable. El metal común para las latas es el aluminio, el acero solo se puede suministrar en casos excepcionales.',
    // Design Info
    requestType: 'Tipo Gráfico',
    requestTypeHelper:
        'Seleccione "Nuevo diseño" si este arte final no reemplaza uno de sus diseños existentes con Ball. Seleccione "Cambio de diseño" si este arte final reemplaza uno de sus diseños existentes con Ball.',
    designSubInfo: 'Informaciónes del diseño',
    requestTypeNew: 'Nuevo diseño',
    requestTypeExisting: 'Cambio del diseño',
    requestTypePromo: 'Diseño promocional – basado en (pero no reemplazando) el diseño existente',
    designPoNo: 'Número de Pedido de diseño',
    designPoNoHelper:
        'Número de orden de compra para el cobro del desarrollo gráfico seegún contrato.',
    previousBallDesignNum: 'Presentaciones Anteriores',
    existingHelper:
        'Si se trata de cambio del diseño, ingrese el número de diseño anterior de Ball (este número aparece en el PDF de Ball que recibe como parte del proceso de aprobación). Esto nos ayuda a identificar el arte final  reemplazado por esta nueva versión.',
    designName: 'Nuevo Nombre del diseño',
    designNameHelper:
        'Nombre del diseño que se va a utliziar durante todo el proceso (desde su desarrrollo gráifico hasta su llenado)',
    newCustRefNum: 'Nuevo numero del diseño (Ball)',
    newCustRefNumHelper:
        'Ingrese su número de referencia para este arte final, el número que usa para identificar este diseño en su organización',
    oldCustRefNum: 'Antiguo numero del diseño (Ball)',
    oldCustRefNumHelper:
        'Si se trata de cambio del diseño, ingrese en este campo su número de referencia del arte final anteriormente desarollado por Ball (esto nos ayuda a identificar el arte final al que sustituye)',
    barcode: 'Código de barras (el numero)',
    barcodeHelper:
        'Ingrese los 8 ó 13 dígitos del código EAN o los 12 dígitos del código UPC. Utilizamos esto para comprobar que el arte final tiene el mismo código y evitar errores',
    barcodeBarsColor: 'Color del código de barras (Barras)',
    barcodeBarsColorHelper:
        'Ingrese el color para las barras. Ball usa esta información para comprobar que el arte final tiene el mismo color.',
    barcodeInfillColor: 'Color del código de barras (Fondo)',
    barcodeInfillColorHelper:
        'Ingrese el color para el fondo del código de barras. Ball usa esta información para comprobar que el arte final tiene el mismo color.',
    specialFinish: 'Acabados especiales Ball',
    specialFinishHelper:
        'Seleccione un efecto especial de acabado para su arte final. Por defecto, este campo está marcado como “no tiene un acabado especial“. Aviso importante: existen costes adicionales asociados a los acabados especales. Por favor, póngase en contacto con su representante comercial  para informarse de los precios asociados.',
    // Color Info
    colourSubInfo: 'Información Colores',
    overVarnish: 'Barniz (el tipo del acabado de la lata)',
    overVarnishHelper:
        'Seleccione el barniz que le gustaría que Ball aplicara a su diseño. El barniz estándar es brillo. Aviso importante: existen costes adicionales asociados a los acabados especales. Por favor, póngase en contacto con su representante comercial  para informarse de los precios asociados.',
    basecoatColour: 'Color base de la lata (de hojalata)',
    basecoatColourHelper:
        'El "basecoat"  solo está disponible para  latas de acero. Por defecto este campo está marcado como "Sin capa base", porque no está disponible en latas de aluminio.',
    specialInk: 'Tintas Especiales Ball',
    specialInkHelper:
        'Seleccione un efecto especial de tintas para su arte final.  De forma predeterminada, este campo está configurado como "sin acabado especial".  Aviso importante: se aplica un pago adicional. Por favor, póngase en contacto con gerente comercial de Ball para informarse de los precios actuales para valores añadidos.',
    colourTarget: 'Objetivo de color',
    colourTargetHelper:
        'Especifique en este campo si tiene un objetivo de color que le gustaría que usemos como referencia.  Si desea que Ball use una muestra de lata producida en el pasado por una de nuestras plantas o por nuestra competencia, es importante indicarlo  al principio del proceso para obtener el  resultado deseado.',
    // Market Info
    marketSubInfo: 'Información del mercado',
    distributeCountries: 'Gráficos de países para comercializar',
    distributeCountriesHelperText:
        'Seleccione en cuál de los siguientes países planea comercializar este diseño. (Seleccione todos los que corresponden)',
    depositScheme: 'Esquema del depósito',
    depositSchemeHelper:
        'Especifique si su arte final se comercializará en algún país que tiene un esquema de depósito (elija de la lista)',
    recycleMark:
        'El metal se recicla para siempre síbolo en el diseño. Disponible en varios idiomas.',
    recycleMarkHelper:
        'Seleccione "Sí" si desea que Ball agregue el logo  MRF a su lata. Al utilizar este logo, contribuye a promover la  infinita reciclabilidad del aluminio y proporciona un mensaje de sostenibilidad a su consumidor.',
    glnNumber: 'Número GLN del cliente',
    glnNumberHelper:
        'Ingrese su código GLN de 13 dígitos en este campo. Si su arte final incluye el logo del depósito Alemán, Ball está obligado a registrar su número GLN en nuestro sistema. El número GLN lo proporciona la "German Pfand Organization" al registrarse en su sistema. Ball tiene que verificar este registro antes de la producción de su arte final. (ingrese "0" en este campo en caso de que su arte final no esté destinado para el mercado alemán)',
    asiCertified: '¿Este diseño cuenta con una certificación ASI?',

    // Product Quality Info
    productQualitySubInfo: 'Especificación de calidad del producto',
    productContent: 'Contenido del producto',
    productContentHelper:
        'Seleccione de la lista la categoría que mejor identifique el contenido de su producto. Esta información se utiliza para estudios de mercado.',
    productFormulation: 'Fórmula del producto',
    productFormulationHelper:
        'Seleccione de la lista si el contenido de la lata corresponde a  una receta “nueva” o “existente”. Utilizamos esta información para determinar si necesitamos realizar un Test de Compatibilidad de Producto.',
    refDesignNum: 'Si corresponde, qué diseñoutilizar como referencia (código)?',
    refDesignNumHelper:
        'Si selecciona “Receta existente” en el campo anterior, especifique en este campo su número de referencia o nuestro número del diseño. Esto nos ayuda identificar los Test de Compatibilidad asociados a dicho producto. ',
    productPasteurisation: 'Pasteurización del producto',
    productPasteurisationHelper:
        'Seleccione de la lista a qué temperatura aproximadamente se pasteurizará su producto. Esto permite a Ball que trate las latas para su proceso.',
    bpaFree: 'BPANI?',
    bpaFreeHelper:
        'Seleccione SÍ si comercializará este diseño en un país que requiere el  uso de lacas BPA-free. El incumplimiento de esta legislación está sujeto a importantes sanciones legales  para el propietario de la marca.',
    bpaniId: 'Identificación N en el diseño?',
    bpaniIdHelper:
        'Si ha seleccionado "Si" para BPANI, confime si desea que apareceza en su diseño. Ball considerará "No" por defecto. Esta identificación ha sido acordada por la industria.',
    // Approval Info (For Approval)
    approvalSubInfo: 'La aprobacion del diseño',
    forApprovalSubTitle:
        'Ball siempre envía un PDF(Layout proof) para la aprobación del elementos del diseño.(los textos, el codigo de barras, los elementos de grafica...) Ball siempre envia muestras de colores (Rollouts / Contract proof) a menos que recibimos una muestra para utilizar como objetivo de producción',
    proofSession: 'Sesión de prueba necesaria?',
    proofSessionHelper:
        'Es posible producir una lata de  prueba. Seleccione "Sí" si desea utilizar esta opción. Existen costes asociados  a esta prueba.  Por favor, póngase en contacto con responsble comercial  para informarse de los precios de este servicio',
    attendProduction: 'Asistir al primer tiraje/ producción',
    attendProductionHelper:
        'Seleccione "Sí" si desea asistir a la primera producción. . Su coordinador de gráficos o representante de CF puede proporcionarle los detalles del procedimiento.',
    responsibleForApproval: 'Responsable de la aprobación',
    // Additional Remarks
    generalCommentsSubInfo: 'Observaciones generales',
    generalComments: 'Observaciones generales',
    generalCommentsHelper:
        'Ingrese los comentarios generales que le gustaría que Ball tuviera en cuenta',
    additionalRemarksSubInfo: 'Comentarios adicionales',
    additionalRemarks: 'Comentarios adicionales',
    additionalRemarksHelper:
        'En este campo puede añadir cualquier comentario que considere relevante para el correcto desarrollo de su arte final ',
    responsibleForCompletion: 'Responsable de llenar el formulario',
    responsibleForCompletionHelper:
        'Persona de contacto responsable del contenido de este formulario ',
    emeaApproverEmailHelper:
        'Especifique la(s) direccion(es) de correo electrónico de la(s) persona(s) responsable(s) de la aprobación. Ball se asegurará de que estas personas reciban los artes finales  por correo electrónico',

    // -- ORDER SHIPPING -- //
    deliveryInstructionsMaxLength: 'Máximo de 30 caracteres',

    // -- GRAPHICS SUMMARY -- //
    submitNewGraphic: 'Enviar nuevo gráfico',
    submitNewGraphicEmea: 'Completar un nuevo formulario de gráficos',
    draftGraphicsForms: 'Borrador de formularios gráficos',
    draftGraphicsFormsEmea: 'Borrador de los formularios de gráficos',
    draftGraphics: 'Borrador de gráficos',
    deleteYourDraft: 'Eliminar el Borrador Gráfico',
    deleteYourDraftEmea: 'Eliminar el borrador de su gráfico',
    graphicDeleteConfirmation:
        '¿Está seguro de que desea eliminar este borrador de formulario de admisión gráfica?',
    graphicDeleteConfirmationEmea:
        '¿Está seguro de que desea eliminar este borrador del formulario de admisión de gráficos?',
    graphicPermantlyGone: 'El formulario se eliminará de forma permanente.',
    learnAboutGraphics: 'Conozca nuestros gráficos',
    graphicsProcess:
        'En Ball tenemos un proceso confiable para asegurar que su producto sea creado y entregado con la más alta calidad y cuidado. A continuación se muestra una línea de tiempo para ayudarle a comprender mejor las etapas de nuestro proceso gráfico.',
    graphicsProcessEmea:
        'En Ball tenemos un proceso confiable para garantizar que su producto se crea y se entrega con la máxima calidad y cuidado. A continuación, se muestra una línea de tiempo para explicar mejor nuestro proceso de gráficos.',
    graphicsNote:
        'Nota: Si un proveedor ha enviado el gráfico, se le avisará cuando se reciba el gráfico y cuando se complete el gráfico.',
    graphicsNoteEmea:
        'Nota: Si un proveedor envió su gráfico, le avisaremos cuando lo recibamos y cuando esté terminado.',
    inProgress: 'En curso',
    inProgressGraphics: 'Gráficos en curso',
    recentlyCompleted: 'Recientemente realizado',
    recentlyCompletedEmea: 'Completado',
    lastNinety: 'En los últimos 90 días',
    lastThreeSixtyFive: 'Últimos 365 días',
    graphicNameAndId: 'Nombre Gráfico & ID',
    graphicNameAndIdEmea: 'Nombre e identificación del gráfico',
    completeIntakeForm: 'Formulario de admisión completo',
    graphicSpecialist: 'Especialista Gráfico',
    graphicSpecialistEmea: 'Coordinador gráfico',
    graphicStatusHistory: 'Historial del estado del gráfico',
    statusDate: 'Fecha del estado',
    currentStatus: 'Estado actual',
    inStatusSince: 'En estado desde',
    availableActions: 'Acciones disponibles',
    selectBillTo: 'Seleccionar Facturar a',
    billTos: 'Facturar a',
    designSubmitted: 'Design Development Form Submitted',

    // Graphics Status
    ART_REC: 'Obras de arte recibidas; Preparación de su cotización',
    QOT_APPRVL: 'Cotización lista para la aprobación',
    PRF_INPRGRS: 'Creación de pruebas',
    PRF_APPRVL: 'Prueba lista para la aprobación',
    PLT_SCHLD: 'Piloto programado',
    PLT_APPRVL: 'Estándar listo para la aprobación',
    GRPHC_CMPL: 'Gráficos completos',
    noRecordsFound: 'No Records Found',

    // EMEA Graphics Status
    ARFC: 'Se ha recibido el material gráfico; se está comprobando la viabilidad',
    FCOH: 'Verificación de viabilidad en espera',
    FCCI: 'Comprobación de viabilidad completada; se necesita la opinión del cliente – Iteración {0}',
    LDIP: 'Desarrollo del diseño en curso',
    LDOH: 'Desarrollo del diseño en curso',
    LPRA: 'Muestra del diseño lista para su aprobación',
    LAPP: 'Diseño aprobado',
    CDIP: 'Desarrollo del color en curso',
    CDOH: 'Desarrollo de color en curso',
    CTRA: 'Objetivo de color listo para su aprobación',
    CAPP: 'Colores aprobados',
    PIPR: 'Muestra en curso',
    PCRA: 'La lata de muestra está lista para su aprobación',
    DDCA: 'Desarrollo del diseño completo',
    DDCC: 'Desarrollo del diseño completo – Pendiente de cambios',
    PAPP: 'Lata de prueba aprobada',

    // -- PENDING PAYMENTS --//
    pendingPayment: 'Pago Pendiente',
    pendingPayments: 'Pagos pendientes',
    pendingPaymentsInvoiceEmail:
        'Se le han enviado facturas proforma para los siguientes pedidos. Al remitir un pago, consulte el número de pedido de Ball Production correspondiente. Nota: El envío de pagos luego de la fecha de vencimiento afectará la primera fecha disponible para su pedido.',
    pendingPaymentsSubmitPayments:
        'Para acelerar el procesamiento de un pago, haga clic en el botón "Haga clic si se envió" a continuación y proporcione un número de remesa (opcional) para su pago.',
    pendingPaymentsDeadlineWarning:
        'Tiene pagos que se acercan a la fecha límite o la superan, lo que afectará la fecha solicitada de su pedido si no se paga antes de la fecha de vencimiento. Si ha pagado, haga clic en "Haga clic si se envió" a continuación para notificarnos.',
    clickIfSubmitted: 'Haga Clic si se Envio',
    provideRemittanceNumber: 'Proporcione un número de pago',
    provideRemittance:
        'Proporcionar un número de pago puede acelerar el procesamiento de fondos a su cuenta.',
    remittanceNumber: 'Número de pago',
    optional: 'Opcional',
    confirmPayment: 'Confirmar Pago',
    paymentSubmittedMessage: 'Gracias por enviar su pago. Procesaremos su pago de inmediato.',
    paymentSubmitted: 'Pago Enviado',
    paymentOverDue: 'El pago está atrasado',
    paymentOverDueThisWeek: 'El pago vence esta semana',
    paymentOverDueThisMonth: 'El pago vence este mes',

    // -- MAKE IT -- //
    myDashboard: 'Mi Tablero',
    createProductionOrder: 'CREAR ORDEN DE PRODUCCIÓN',
    saveForLater: 'GUARDAR PARA MÁS ADELANTE',
    back: 'Espalda',
    setDatesQuantities: 'ESTABLECER FECHAS Y CANTIDADES',
    setQuantities: 'Introducir cantidades',
    adjustmentsToYourOrder:
        'Es posible que se hayan realizado ajustes en su pedido desde la última vez que guardó. Revise todos los productos y cantidades de su pedido antes de continuar.',
    failedToLoadDraft: 'No se pudo cargar el borrador del pedido',
    selectProducts: 'Seleccione productos para agregar a su pedido:',
    draftName: 'Nombre del borrador',
    continueEditing: 'Continuar editando',
    yourOrderHasBeenSaved: '¡Su pedido se ha guardado correctamente!',
    shipTo: 'Envie a',
    yourOrder: 'Su Pedido',
    search: 'Buscar',
    range: 'Rango',
    cansBottles: 'Latas / Botellas',
    agingBalance: 'Vencimiento del saldo',
    campaign: 'Campaña',
    campaignProducts: 'Productos de campaña',
    endsClosures: 'Tapas / Cierres',
    endClosures: 'Tapa / Cierres',
    closures: 'Cierres',
    closure: 'Clausura',
    orderedInLastSixMonths: 'Pedido en los últimos 6 meses',
    removeAll: 'Eliminar Todo',
    addInstructions:
        'Cree su pedido haciendo clic en el botón "AÑADIR" para cada producto que desee incluir.',
    qtyInstructions:
        'Ingresará cantidades y fechas solicitadas para los productos seleccionados en la siguiente pantalla.',
    cancelSubmission: 'Cancelar su envío',
    saveDraftTitle: 'Guardar Borrador',
    cancelSubmissionEmea: 'Cancelar su presentación',
    allInfoWillBeLost:
        '¿Seguro que desea cancelar su progreso sin guardar? Toda la información ingresada se perderá.',
    yesCancel: 'Sí, Cancelar',
    productId: 'Identificación del producto',
    graphicID: 'Identificacion del gráfico',
    productDescription: 'Descripción del producto',
    characteristics: 'Caracteristicas',
    coating: 'Revestimiento',
    add: 'Añadir',
    added: 'Añadido',
    makeItMinimumOrderQuantity: 'Make It Minimum Order Quantity',
    rowsPerPage: 'Filas por página',
    filter: 'Filtro',
    poNumber: 'Numero de orden de compra',
    poNumberHelper: 'Se requiere un número de orden de compra del cliente.',
    poNumberMaxLengthHelper:
        'El número de orden de compra del cliente debe tener 25 caracteres o menos.',
    poNumberPlaceholder: '25 caracteres o menos',
    addProducts: 'Añadir Productos',
    submitError: 'Se ha producido un error al guardar el pedido. Inténtelo de nuevo más tarde.',
    submitOrder: 'Enviar pedido',
    draftHasBeenSuccessfullySaved: '¡Su borrador se ha guardado correctamente!',
    viewMyInProgressGraphics: 'Ver resumen de gráficos',
    viewMyInProgressGraphicsEmea: 'Ver el resumen de los gráficos',
    enterDraftName:
        'Introduzca un nombre de borrador para su pedido de producción para guardarlo para más adelante.',
    draftOrderError:
        'El borrador del pedido no se ha podido guardar correctamente. Inténtelo de nuevo más tarde.',
    minimumOrderQuantities: 'Cantidades mínimas de pedido',
    feeWarning:
        'Una o más cantidades no cumplen con la cantidad mínima de pedido e incurrirán en una tarifa.',
    product: 'Producto',
    minimumOrderQuantity: 'Cantidad mínima de pedido',
    fee: 'Cuota',
    confirmFeeMessage: '¿Desea actualizar la cantidad o aceptar la tarifa?',
    editQuantity: 'Editar Cantidad',
    acceptFee: 'Aceptar Tarifa',
    today: 'Hoy',
    leadTimeForThisProduct: 'Tiempo de espera para este producto: {{leadTime}} semanas',
    minimumOrderIs: 'El pedido mínimo es',
    feeForOrders: 'Tarifa por pedidos',
    split: 'División',
    moqWarningMessage:
        'Una o más cantidades ingresadas no cumplen con la cantidad mínima de pedido.',
    orderSubmitted: 'GRACIAS, SU PEDIDO HA SIDO ENVIADO',
    ordersSubmitted: 'Gracias, su pedido ha sido enviado',
    customerPO: 'Orden de Compra del cliente',
    invoiceSent: 'Factura Enviada',
    cashReceived: 'Pago recibido',
    reactToScheduleShipment: 'Listo para programar el envío',
    proFormaInvoice:
        'Debería recibir su factura pro forma para este pedido <strong>dentro de 2-3 días hábiles</strong>.',
    proFormaInvoiceBulk:
        'Debería recibir su factura(s) pro forma para estes pedido(s) <strong>dentro de 2-3 días hábiles</strong>.',
    paymentDueImmediately:
        'El pago de la factura <strong>debe realizarse inmediatamente</strong> para asegurar la fecha solicitada para los productos en el pedido.',
    paymentDueImmediatelyBulk:
        'El pago de la factura(s) <strong>debe realizarse inmediatamente</strong> para asegurar la fecha solicitada para los productos en el pedido(s).',
    paymentFor:
        'El pago de <strong>la factura se vence {{paymentDue}}</strong> (cuatro semanas antes de la fecha más temprana solicitada).',
    paymentForBulk:
        'El pago de <strong>la factura(s) se vence {{paymentDue}}</strong> (cuatro semanas antes de la fecha más temprana solicitada).',
    paymentForBulkNoDate:
        'Payment for the invoice(s) is due four weeks before your earliest requested date.',
    share: 'Compartir:',
    print: 'Imprimir',
    downloadCSV: 'Descargar .CSV',
    canQuantity: 'Cantidad de lata',
    endQuantity: 'Cantidad de tapas',
    quantity: 'Cantidad',
    orderConfirmation: 'Confirmación del Pedido',
    leadTime: 'Tiempo de entrega',
    requiredShipDate: 'Fecha de Despacho Requerida',
    requestedAmount: 'Requested Amount',
    quantityToPurchase: 'Cantidad a comprar',
    purchaseOrderNumber: 'Número De Orden De Compra',
    shortenedPONumber: 'Número De Orden De Compra',
    ballProdOrderNumber: 'Número De Orden De Produccion De Ball',
    ballProdOrderLineNumber: 'Ball Production Order Line Number',
    ballProdOrder: 'Orden De Produccion De Ball',
    invoiceAmount: 'Monto De La Factura',
    orderRequestedDate: 'Pedido Fecha Solicitada',
    orderSubmittedDate: 'Fecha de envío del pedido',
    paymentDueDate: 'Fecha De Pago',
    zeroOrderQuantity:
        'La cantidad del pedido no puede ser 0. Introduzca una cantidad o elimine el producto del pedido.',
    zeroForecastQuantity:
        'Forecasted quantity cannot be 0. Please enter a quantity or remove the product from the forecast.',
    blankForecastQuantity:
        'Forecasted quantity cannot be blank. Please enter a quantity or remove the product from the forecast.',
    firstRequestedDate: 'Solicitado',
    cancelProductionOrder: 'Confirme que desea cancelar este pedido.',
    cancelProductionOrderSubHeader: '¿Está seguro de que desea cancelar Pedido #',
    cancelProductionOrderConfirmation: 'Are you sure you want to cancel Production Order #',
    viewCurrentUpcomingCampaigns: 'Ver Campañas Actuales o Próximas',
    currentUpcomingCampaigns: 'Campañas Actuales o Próximas',
    lookingForCampaignSchedules: '¿Está buscando horarios de campaña?',
    noCampaignsScheduled: 'No campaigns scheduled at this time',
    productSizeShape: 'Product (Size / Shape)',
    makeItOrderOpen: 'Make It Order Open',
    requestWindowOpen: 'Request Window Open',
    makeItOrderClosed: 'Make It Order Closed',
    requestWindowClosed: 'Request Window Closed',
    firstNumAvailableDate: '1st Available Date',
    newGraphicApprovalDeadline: 'New Graphic Approval Deadline',
    changeRequestedWeekWarning:
        'Changing your requested week will remove all products you have added to the order. Do you want to continue?',
    changeRequestedWeek: 'Change Requested Week',

    createAtmProductionOrder: 'Crear ATM (Autorización de fabricación)',
    addAtmProductsStep: 'Agregue productos para crear una ATM (Autorización de fabricación):',
    addAtmInstructions:
        'Cree su ATM (Autorización de fabricación) haciendo clic en el botón «Agregar» para cada producto que quiera incluir. Revisará su ATM en la siguiente pantalla.',
    enterQuantityUnit: 'Ingrese las cantidades en:',
    qtyAtmInstructions: 'Verá su ATM (autorización de fabricación) en la próxima pantalla.',
    impersonationWarningDiscard:
        'This work cannot be discarded. You are using an impersonation role that has view-only access in The Source.',
    impersonationWarningSave:
        'This work cannot be saved. You are using an impersonation role that has view-only access in The Source.',
    impersonationWarningSubmit:
        'Este trabajo no puede ser enviado. Usted está usando un rol de suplantación de identidad que tiene acceso de solo lectura en The Source.',
    makeItOrderExistsError:
        'Ya se ha realizado una orden Make It para esta semana. Edite la orden existente desde el Resumen de la orden para agregar productos o seleccionar una semana diferente.',
    available: 'disponible',
    noSupplyPlanAvailable: 'No existe ningún plan de suministro.',
    noWeeksSelected: 'No se seleccionó ninguna semana',
    addWeek: 'Agregar semana',
    reachOutToAccountManager:
        'Comuníquese con su administrador de cuentas para conocer su plan de suministro.',

    // -- Delivery Orders --//
    submittedByUser: 'Usuario',
    deliveryOrderNumber: 'Pedido de Entrega #',
    deliveryOrderDetails: 'Detalles del Pedido de Entrega',
    linkedProductionOrder: 'Orden de producción',
    linkedATMOrder: 'Make It (ATM) Order',
    shipmentPickupDate: 'Fecha de recogida',
    deliveryDate: 'Fecha de entrega',
    shipmentPickupTime: 'Hora de recogida',
    deliveryTime: 'Hora de entrega',
    shipmentShippedFrom: 'Enviado desde',
    shipmentCarrier: 'Empresa de transporte',
    deliveryOrderDates: 'Fechas del Pedido de Entrega',
    needToMakeChange: '¿Necesita hacer un cambio?',
    editShipments24Hours: 'Hasta 24 horas antes de la fecha y hora del envío, puede:',
    editShipmentsOption1:
        'Añadir/Quitar una versión de producto/gráfico del pedido global o de un envío individual',
    editShipmentsOption2: 'Actualizar la cantidad de un producto para un envío',
    editShipmentsOption3: 'Eliminar un envío de un pedido de entrega',
    editShipmentsOption4:
        'Actualizar la fecha u hora de un pedido de entrega siempre y cuando sea más de {{leadTime}} días a partir de la fecha actual',
    editShipmentsOption5:
        'Actualice las instrucciones de entrega, el número de liberación, la línea de relleno y el número de referencia',
    editShipmentsOption6: 'Update delivery order date / time within the allowable delivery window',
    editShipmentsOption7: 'Actualizar la fecha u hora de un pedido de entrega',
    editShipmentsCancellingOrders:
        'Cancelación de pedidos: las cancelaciones deben completarse al menos 24 horas antes de la fecha de envío más temprana en el pedido de entrega.',
    editShipmentsContactRep:
        'Póngase en contacto con un representante de ventas de Ball si necesita solicitar un cambio diferente de los anteriores.',
    yourDeliveryOrder: 'Su Pedido De Entrega',
    cancelDeliveryOrderConfirmation: 'Cancelar confirmación de pedido de entrega',
    skuCansBottles: '{{ skuCount }} SKU (Latas/Botellas)',
    shipmentNumber: 'Numero de envío',
    shipmentDate: 'Shipment Date',
    shipmentTime: 'Shipment Time',
    idNotAvailable: 'ID not available',

    // -- Unauthorized -- //
    unauthorized: 'Sin autorización',
    youAreNotAuthorized: 'No tiene autorización para ver esta página',
    unknownActivity: 'this area',
    dontHaveAccess: "Looks like you don't have access to {{activityString}} for",
    deactivateReactivateUsers: 'Deactivate / Reactivate Users',
    makeItBulkATM: 'Make It Bulk ATM',
    makeItBulkUpload: 'Make It Bulk Upload',
    shipItBulkUpload: 'Ship It Bulk Upload',
    openAccess: 'Open Access',
    onboardingWizard: 'Onboarding Wizard',
    billingAndShipping: 'Billing And Shipping',

    // -- SHIP IT -- //
    createDeliveryOrder: 'CREAR PEDIDO DE ENTREGA',
    selectDeliveryWk: 'Seleccione su semana de entrega',
    todayWithDate: 'Hoy: {{ today }}',
    chooseDates: 'Choose Dates',
    firstAvailable: 'Primero disponible',
    firstShipment: 'Su primer envío debe ser al menos {{ leadTime }} días a partir de hoy',
    selectProductsForDelivery: 'Añadir productos a su pedido de entrega',
    shipitAddInstructions: `Cree su pedido especificando el número de pallets para cada producto que desea incluir y luego haga clic en el botón "AGREGAR" para agregarlo a su pedido.`,
    configureTrucksInstructions:
        'Construirá sus envíos y seleccionará fechas y horas de entrega en su próxima pantalla',
    totalPallets: 'Pallets totales',
    estimatedTrucks: 'Camiones estimados necesarios:',
    trucksVarianceInstructions:
        'El número de camiones variará según el peso total de los tapas / cierres en la entrega.',
    buildYourShipments: 'CONSTRUYE TUS ENVÍOS',
    deliveryWeek: 'Semana de entrega',
    nextDeliveryWeek: 'Próxima semana de entrega',
    youDidntLoadAll: `No cargaste todas tus paletas`,
    remainingPalletsToBeOrdered:
        'Hay {{ remainingBalance }} palés sin asignar en su pedido. Si continúa, los palés sin cargar permanecerán sin liberarse y estarán disponibles para futuros pedidos de entrega.',
    continueBuildingShipments: 'Seguir creando envíos',
    deliveryDay: 'Dia de entrega',
    availableQuantToLoad: 'Cantidad disponible de {{ maxPalletsToLoad }} PL para cargar',
    availOutOfMax: '{{ availablePallets }} PL de {{ maxPallets }} PL',
    addShipment: 'AGREGAR ENVÍO',
    productsToShip: 'Productos para enviar',
    remainingProductsToLoad: 'Restante para cargar: {{ totalProductPallets }} PL',
    remainingEndsToLoad: 'Restante para cargar: {{ totalEndsPallets }} PL',
    palletsAvailableOn: 'Disponible el {{ date }}: {{ firstAvail }} PL de {{ firstAvailMax }} PL',
    viewBalancesByDay: 'Ver saldos por día',
    notEnoughRemainingPallets:
        'Se superó la cantidad máxima de palés para un producto. Actualice las cantidades dentro del envío antes de guardar.',
    maximumNumberOfPalletsWarning:
        'La cantidad máxima de palés para un envío es de {{truckPalletLimit}} PL. Actualice las cantidades dentro del envío antes de guardar.',
    freightWeightWarning:
        'Su tarifa de flete se basa en un camión completo con 25 palés. ¿Se enviará menos de un camión completo? Le enviaremos una factura por separado por el resto del flete. Puede calcular la cantidad que verá en su factura de la siguiente manera:',
    atmFreightWeightWarning:
        'Su tarifa de flete se basa en un camión completo (típicamente 25 palés). ¿Se enviará menos de un camión completo? Le enviaremos una factura por separado por el resto del flete.',
    shipmentFeeModalWarning:
        'Su tarifa de flete se basa en un camión completo. ¿Se enviará menos de un camión completo? Le enviaremos una factura por separado por el resto del flete. Así es como se calcularía la cantidad en base a 25 palés.',
    feesMayIncur: 'Se puede incurrir en pagos por envíos de menos de un camión completo',
    deliverOn: 'Entregar el',
    pickupOn: 'Recoger el',
    addDeliveryInstructions:
        'Agregue un producto de la lista que creó e ingrese las cantidades aquí. El carrito se actualizará para mostrar los pallets restantes para cargar, si corresponde.',
    deliveryInstructions: 'Instrucciones de entrega',
    time: 'Hora',
    at: 'En',
    arriving: 'Llegando',
    none: 'Aucun',
    done: 'Done',
    deliveryMountainTime:
        'El horario de entrega debe ingresarse en horario de montaña (Mountain Time, MT)',
    deliveryTimeZone: 'Select desired delivery time',
    pleaseEnterDeliveryInstructions: 'Ingrese las instrucciones de entrega para este envío.',
    deliveryOrderError:
        'Las instrucciones de entrega no se guardaron correctamente. Inténtelo de nuevo más tarde.',
    deliveryOrder: 'Su Orden de Entrega',
    deliveryOrderPending: '# de Orden de Entrega Pendiente',
    deliveryConfirmationCarrierAlert:
        'El transportista será asignado 7 días antes de la fecha de entrega programada.',
    incursFees: 'El envío de menos de {{truckPalletLimit}} PL incurre en una tarifa.',
    mayIncurFees: 'El envío de menos de {{truckPalletLimit}} PL puede incurrir en una tarifa.',
    mayIncurFeesTruckload: 'El envío de menos de un camión completo puede incurrir en un pago.',
    shipmentSize:
        'El tamaño de envío estándar para una orden de entrega es de 25 palés, pero se pueden permitir hasta 27 palés para algunos lugares.',
    buildShipments: 'CONSTRUYE TUS ENVÍOS',
    shipment: 'Envío',
    shipments: 'Envíos',
    sku: 'SKU',
    skus: 'SKUs',
    delete: 'Eliminar',
    selectedWeek: 'Semana Seleccionada',
    company: 'Empresa',
    availBalance: 'Saldo disponsible',
    numberOfPalletsToShip: 'Número de palets a enviar',
    releaseNumber: 'Número de liberación',
    releaseNumberPDF: 'Número de liberación',
    loadSeq: 'Secuencia de carga',
    fillerLineNumber: 'Línea de llenado #',
    referenceNumber: 'Número de referencia',
    referenceNumberPDF: 'Número de referencia',
    invalidBulkFile: 'Este tipo de archivo no es válido. Adjunte un archivo .csv.',
    invalidCsv: '*Cargue un archivo CSV válido',
    reviewOrder: 'Orden de Revisión',
    resetMapping: 'Restablecer Su Asignación',
    mappingPermanentlyGone: 'Cualquier cambio realizado en su mapeo desaparecerá permanentemente.',
    changesPermanentlyGone:
        'Se perderán permanentemente todos los cambios sobre lo que va a enviar.',
    changesPermanentlyGoneEmea:
        'Los cambios que haya hecho en su presentación se eliminarán de forma permanente.',
    deleteProgress: 'Borrar el progreso',
    deleteProgressEmea: 'Eliminar los cambios',
    yesReset: 'Sí, Restablecer',
    editMapping: 'Editar Asignación',
    changeDeliveryWeekWarning:
        'Cambiar su semana de entrega eliminará todos los productos que haya cargado en su pedido. ¿Desea continuar con el cambio de la semana de entrega?',
    deliveryOrderDayTimeError:
        'Los envíos en el mismo pedido de entrega no pueden tener fechas y horas duplicadas. Modifique la hora elegida.',
    changeDeliveryWeek: 'Change Delivery Week',
    changeDeliveryDayWarning:
        'Cambiar su día de entrega podría requerir alteraciones en su envío.  ¿Quieres continuar?',
    changeDeliveryDate: 'Fecha de entrega de la modificación',
    creditHoldWarning:
        'Se ha colocado una retención de crédito contra esta nave para. Los pedidos de entrega no se pueden realizar para este buque hasta que se haya resuelto la retención de crédito.',
    copackerCreditHoldWarning:
        'Esta ubicación de envío a está en espera. Comuníquese con su cliente para obtener una resolución.',
    dashboardCreditHoldWarning:
        'Los siguientes Envíos tienen una retención de crédito en su contra. Los pedidos de entrega no se pueden realizar contra estos Productos de Envío:',
    additionalFields: 'Campos Adicionales',

    // -- Summary Pages -- //
    customerPoNumber: 'Número de orden de compra del cliente',
    customerPoNumLong: 'Número de orden de compra del cliente',
    status: 'Estado',
    createdBy: 'Creado por',
    dateSubmitted: 'Fecha de envio',
    productGraphicId: 'ID producto / ID gráfico',
    graphicId: 'ID gráfico',
    descriptionCharacteristics: 'Descripción del producto / Caracteristicas',
    quantityOrdered: 'Cantidad pedida',
    quantityToDeliver: 'Cantidad a entregar',
    requestedDate: 'Fecha solicitada',
    open: 'Abierto',
    closed: 'Cerrado',
    availableToShip: 'Disponible para envío',
    completed: 'Completar',
    productionOrderDetails: 'Detalles de orden de producción',
    totalOrderQuantity: 'Cantidad total de pedido',
    openProductionOrders: 'Órdenes de producción abiertas',
    closedProductionOrders: 'Órdenes de producción cerradas',
    totalDeliveryQuantity: 'Cantidad total de entrega',
    draftProductionOrders: 'Borrador de órdenes de producción',
    draftOrderName: 'Nombre del pedido de borrador',
    lastSaved: 'Última Guardado',
    lastSavedBy: 'Last Saved By',
    actions: 'Acciones',
    openDeliveryOrders: 'Pedidos de entrega abiertos',
    closedDeliveryOrders: 'Pedidos de entrega cerrados',
    orderStatus: 'Estado del pedido',
    pending: 'Pendiente',
    editPending: 'Cambios pendientes',
    received: 'Pago recibido',
    reject: 'Rechazado',
    confirmed: 'Confirmado',
    shipConfirmed: 'Envío confirmado',
    draft: 'corriente de aire',
    delinquent: 'Pago adeudado',
    payable: 'En aprobación',
    cancelledPending: 'Cancelar pendientes',
    editUnderReview: 'Modificar bajo revisión',
    openEditReviewed: 'Abrir (cambios revisados)',
    openEditRejected: 'Abrir (cambios rechazados)',
    originallyScheduled: 'Originalmente programado',
    reviewStatus: 'Estado de la revisión',
    productName: 'Nombre del Producto',
    arrivalDate: 'Fecha de Llegada',
    arrivalTime: 'Hora de Llegada',
    lineStatus: 'Estado de la Línea',
    scheduledFor: 'Programado Para',
    receivedOn: 'Recibido En',
    arrivedOn: 'Llegado En',
    poOpenOrderInformation:
        'Hasta dos semanas antes de la primera fecha disponible puede eliminar un producto de la orden de producción, retrasar la primera fecha disponible para un producto o disminuir la cantidad de un producto.',
    poCannotBeEdited: `Si un producto tiene un pedido de entrega asociado, no podrá realizar modificaciones en el pedido de producción.`,
    edit: 'Editar',
    cancelOrder: 'Cancelar Pedido',
    goBack: 'Volver',

    // -- EDIT PRODUCTION ORDER -- //
    editProductionOrder: 'Editar Pedido de Producción',
    pleaseConfirmRemoveOrder: 'Por favor, confirme que desea eliminar este pedido',
    areYouSureYouWantToRemoveProduct:
        '¿Está seguro de que desea eliminar {{ productId }} de ID de producto de su pedido?',
    editRequestedDate: 'Editar Fecha Solicitada',
    originalRequestedQuantity: 'Cantidad Solicitada Original',
    editQuantityToPurchase: 'Editar Cantidad Solicitada para Comprar',
    lessThanOriginal: 'Debe ser menos que original',
    mustBeAfterOriginalDate: 'Debe ser después de la fecha original',
    dateMustBeAfterOriginalDate: 'La fecha debe ser posterior a la fecha original solicitada',
    cancelProductionOrderSubmitted: 'Gracias. Su cancelación ha sido enviada',
    cancelledDate: 'Fecha cancelada',
    productionOrder: 'Orden de producción',
    originalQuantityOrdered: 'Cantidad Original Pedida',
    revisedQuantityOrdered: 'Cantidad revisada pedida',
    revisedRequestedDate: 'Fecha solicitada revisada',
    editProductionOrderConfirmation: 'Editar confirmación de pedido de producción',
    editProductionOrderSubmitted: 'GRACIAS. SUS CAMBIOS HAN SIDO RECIBIDOS',
    dateRevised: 'Fecha de revisión',
    submitChanges: 'Presentar cambios',
    editProductionOrderSubmitChanges: 'Está a punto de cambiar su orden de producción',
    editProcutionOrderConfirmSubmit:
        '¿Está seguro de que desea guardar los cambios en su pedido de producción?',
    removed: 'QUITADO',
    inactiveProductError:
        'Ball SKU {{sku}} is inactive. You cannot submit changes until this is addressed with your Ball representative or removed from the order.',
    currentAvailableBalance: 'Current Available Balance',

    // -- Date Pickers -- //
    legend: 'Leyenda',
    day: 'Día',
    restrictedDate: 'Fecha <br/> Restringida',
    dateFirstAvailable: 'Primer fecha disponible',
    availableDate: 'Fecha disponible',
    ok: 'Okay',

    // -- PRODUCT PLANNING -- //
    units: 'Unidades',
    expandAll: 'Expandir todo',
    collapseAll: 'Contraer todo',
    thisWeek: 'Esta Semana',
    weeks0to8: 'Semanas 0-8',
    weeks9to16: 'Semanas 9-16',
    productionBalance: 'Balance de Producción',
    atmBalance: 'Saldo de ATM',
    weekOfWithDate: 'Semana de {{date}}',
    productionOrders: 'Orden(es) de producción',
    ordersPendingPayment: 'Pedidos pendientes de pago',
    originalRequestedDate: 'Fecha de vencimiento del pago',
    revisedAvailableDate: 'Fecha de solicitud original',
    late: 'Demora',
    deliveryDates: 'Fecha(s) de Entraga',
    productPlanning: 'Planificación de productos',
    emptyString: '',
    week: 'Semana',
    graphicsID: 'Número de Gráfico',
    ballOrderNumber: 'Número de Pedido de Ball',
    shipmentsExceededProduction: 'Los envíos excedieron los saldos de producción',
    transferProductionBalance: 'Solicitar un Trade It',
    displayUnits: 'Display Units',
    apply: 'Aplicar',
    agingBalances: 'Vencimiento del Saldo',
    agingBalanceIndicator: 'Indica un vencimiento de saldo',

    // -- TRADE IT -- //
    tradeIt: 'Trade It',
    createTradeRequest: 'Crear una solicitud del saldo comercial',
    tradeRequestSummary: 'Balance Trade Summary',
    selectYourShipTos: 'Seleccione los destinatarios del envío',
    originalShipTo: 'Destinatario original',
    receivingShipTo: 'Destinatario receptor',
    originalShipToContactEmail: 'Correo electrónico de contacto del destinatario original',
    receivingShipToContactEmail: 'Correo electrónico de contacto del destinatario receptor',
    selectProductsToTrade: 'Seleccione los productos para comercializar',
    makeYourShipToSelections:
        'Make your selections above to see products eligible for trades for the Ship Tos you select.',
    noTradeItProducts:
        'No products with available to trade balance from original ship and mapped to the receiving ship to.',
    willReceiveNotification:
        '* Recibirá una notificación una vez que se haya revisado la solicitud.',
    originalShipToCurrentProdBalance: 'Saldo actual',
    receivingShipToCurrentProdBalance: 'Saldo actual',
    quantityToTrade: 'Cantidad para comercializar (palés/carga de palés)',
    originalShipToNewProdBalance: 'Saldo nuevo',
    receivingShipToNewProdBalance: 'Saldo nuevo',
    quantityExceedsAvailableBalance: 'Cannot exceed Original Ship To available balance',
    submitRequest: 'Enviar solicitud',
    balanceTradeSubmitted: '¡Gracias! Su solicitud se ha enviado.',
    tradeItRequest: 'Nro. de la solicitud Trade It',
    originalShipToPreviousAvailableBalance: 'Previous Available Balance for Original Ship To #',
    receivingShipToPreviousAvailableBalance: 'Previous Available Balance for Receiving Ship To #',
    requestedQuantityToTrade: 'Requested Quantity to Trade',
    originalShipToUpdatedAvailableBalance: 'Updated Available Balance for Original Ship To #',
    receivingShipToUpdatedAvailableBalance: 'Updated Available Balance for Receiving Ship To #',
    originalShipToContact: 'Correo del destinatario original',
    receivingShipToContact: 'Correo del destinatario receptor',
    requestDetails: 'Request Details',
    weekOf: 'Semana de',
    requestStatus: 'Request Status',
    updatedBalance: 'Updated Balance',
    previousBalance: 'Previous Balance',
    requestedTradeQuantity: 'Requested Trade Quantity',
    campaignTradeNotification:
        'Tenga en cuenta que hay uno o más productos de campaña incluidos en su solicitud.',
    campaignTradeRisk:
        'Si toma la decisión de comercializar latas de campaña provocando que las existencias se agoten de manera prematura, <1>no se repondrá el inventario hasta la siguiente campaña planificada</1>.',
    campaignTradeRiskEmphasis:
        'no se repondrá el inventario hasta la siguiente campaña planificada',
    campaignQuestionsPrompt:
        'Si tiene alguna pregunta, comuníquese con el representante de su cuenta.',
    campaignContinuePrompt: '¿Desea continuar?',
    campaignProductAlert: 'Alerta de producto de campaña',
    accept: 'Aceptar',

    // -- DELIVERY ORDER PDF -- //
    total: 'Total',
    dateTime: 'Fecha / Hora',
    revised: 'Revisado',
    update: 'Actualizar',
    pickUp: 'Recoger',
    loadingMessage: 'Loading PDF. This may take up to a few minutes.',

    // -- EDIT SHIPMENTS SUMMARY -- //
    pleaseSelectProductsShipment: 'Seleccione los productos que desea agregar a su envío',
    changesWillNotBeSaved: 'Los cambios realizados no se guardarán',
    areYouSureDiscard: '¿Está seguro de que desea descartar algún cambio?',
    yesDiscard: 'Sí, Descartar',
    changeDeliveryOrderModalTitle: 'Está a punto de cambiar su pedido de entrega',
    changeDeliveryOrderModalConfirmationText:
        '¿Está seguro de que desea guardar los cambios en su pedido de entrega?',
    yesSave: 'Sí, Guardar',
    confirmCancelShipment: 'Confirme que desea cancelar este envío',
    areYouSureCancelShipment: '¿Está seguro de que desea cancelar este envío?',
    yesCancelShipment: 'Sí, Cancelar envío',
    feesWillBeIncurred: 'Se incurrirán en cargos por envíos inferiores a {{maxPallets}} palets',
    doYouWantToAccept: '¿Quieres aceptar la tarifa?',
    yesAcceptFee: 'Sí, acepte la tarifa',

    // -- EDIT SHIPMENTS -- //
    palletQtyNotZero:
        'La cantidad de palés no debe ser 0.  Actualice la cantidad o retire el producto antes de guardarlo.',
    changeDay: 'Día del Cambio',
    changeDeliveryDateWarning:
        'Cambiar la fecha de entrega podría requerir modificaciones en su envío',
    productsRemovedFromShipment:
        'Algunos productos o cantidades pueden no estar disponibles para su envío en esta fecha y serán eliminados de su envío.',
    cancelShipment: 'Cancelar Envío',
    revisedDate: 'Fecha revisada',
    editYourShipment: 'Edite su envío',
    shipmentCancelled: 'Envío cancelado',
    shipmentEdited: 'Envío editado',
    deliveryOrderConfirmation: 'Confirmación del pedido de entrega',
    shipmentsWithin24Hrs:
        'Los envíos programados para enviar en las próximas 24 horas no son editables',
    customerPickUp: 'Recogida de clientes',
    shipping: 'Envío',
    pleaseConfirmCancelOrder: 'Confirme que desea cancelar este pedido.',
    cancellationReceived: 'Gracias. Su cancelación ha sido recibida',
    editDeliveryOrderSubmitted: 'GRACIAS. SUS CAMBIOS HAN SIDO RECIBIDOS',
    errorProcessingShipment: 'There has been an error processing your edited Delivery Order.',

    // -- DELIVERY BULK UPLOAD --//
    mapTemplateFields: 'Campos de Plantilla de Mapa',
    uploadShipItOrders: 'Cargar su orden u órdenes Ship It',
    mapShipItTemplateValues: 'Mapear valores de plantilla Ship It',
    updateShipItTemplateValues: 'Actualizar valores de plantilla Ship It',
    mapTemplateFieldsDescription:
        'Asigne los valores necesarios a su columna correspondiente en la plantilla. Todos los valores necesarios deben asignarse, los campos opcionales se pueden dejar en blanco si no los está utilizando.',
    templateFieldMapping: 'Asignación de Campos de Plantilla',
    shipToId: 'Enviar a ID',
    columnLetter: 'Carta de Columna',
    columnLetterOptional: 'Carta de Columna (Opcional)',
    fillerLine: 'Número de Línea de Llenado',
    saveMapping: 'Guardar Asignación',
    uploadOrderFile: 'Cargar Archivo de Pedido',
    bulkUploadOverview: 'Descripción General de la Carga Masiva',
    mapTemplateValues: 'Valores de Plantilla de Mapa',
    columnPatternError: 'Por favor, introduzca 1-2 letras',
    bulkUpload: 'Carga Masiva',
    atmBulkUpload: 'ATM y Previsión de carga masiva',
    reset: 'Restablecer',
    uploadDeliveryOrder: 'Sube Su Pedido de Entrega',
    acceptedBulkFiles:
        'Por favor, cargue su archivo de .csv, asegurándose de que sus valores coincidan con su asignación.',
    lookUpShipTo: '¿Necesita Buscar un Envie a?',
    viewAssignedShipTos: 'Ver Los Tos de Envío Asignados',
    multipleColumnValidationError:
        'Las letras de columna no se pueden duplicar. Por favor revise sus entradas y vuelva a enviar.',
    mustSaveMapping: 'Debe guardar la asignación anterior para cargar un archivo.',
    documentName: 'NOMBRE DEL DOCUMENTO',
    documentNotUploaded: 'No se ha cargado ningún documento',
    removeFile: 'Eliminar archivo',
    confirmFileRemoval: 'Confirme que desea eliminar este archivo',
    areYouSureRemoveFile: '¿Está seguro de que desea eliminar este archivo?',
    yesRemove: 'Sí, Quitar',
    confirmCancel: '¿Estás seguro de que deseas cancelar?',
    sureYouWantToLeave: 'Are You Sure You Want To Leave?',
    removeProductTitle: 'Remove Product from Order',
    removeProductSubheader: 'Are you sure you want to remove this product from your order?',
    changeWeek: 'Change Week',
    chooseWeekMove: 'Choose a week to move this Product to',
    chooseWeekCopy: 'Choose a week to copy this Product to',
    confirmCancelNoQuestion: 'Estás seguro de que deseas cancelar',
    lostFile:
        'Cualquier archivo cargado o asignaciones no guardadas desaparecerán permanentemente.',
    deliveryOrderHeader:
        'Orden de Entrega {{deliveryOrderNumber}} - <i>Número de Pedido Pendiente</i>',
    deleteShipment: 'Eliminar Envío',
    editShipment: 'Editar Envío',
    delivery: 'Entrega',
    lowTruckPalletCountWarning: 'Esta entrega tiene una carga de camión inferior a la completa.',
    reviewYourShipments: 'Revise Sus Envíos',
    pleaseReviewShipmentDetails:
        'Por favor revise los detalles de sus envíos a continuación y realice cualquier edición necesaria antes de enviar su(s) pedido(s).',
    allOrdersDeleted:
        'All orders have either been deleted or removed. Please "undo" at least one deleted order or upload a new order file to proceed.',
    allShipmentsDeleted:
        'All shipments have been deleted. Please "undo" at least one deleted shipment or upload a new order file to proceed.',
    allProductsRemoved: 'All products have been removed. Please add products to proceed.',
    line: 'Línea',
    fileErrors: 'Errores de archivo',
    lineErrorHelper: 'Corrija los siguientes errores y vuelva a cargar el archivo.',
    shipmentDeleted: 'Envío eliminado',
    undo: 'Deshacer',
    yesDelete: 'Sí, Eliminar',
    noCancel: 'No, Cancelar',
    confirmDeleteShipment: '¿Está seguro de que desea eliminar este envío?',
    intakeCancelConfirmation: '¿Está seguro de que desea cancelar su progreso sin guardar?',
    intakeCancelConfirmationEmea: '¿Está seguro de que quiere salir sin guardar los cambios?',
    intakeSaveDraft:
        'Está a punto de guardar un borrador de su orden de producción, asigne un nombre a su borrador para acceder fácilmente a él más tarde. Una vez guardado, volverá al panel de control.',
    saveChanges: 'Guardar Cambios',
    discardChanges: 'Descartar Cambios',
    planningView: 'Vista de Planificación',
    changesNotSaved: 'Sus cambios no se guardarán.',
    sureYouWantToNavigateAway: 'Are you sure you want to navigate away?',
    goBackConfirm: '¿Seguro que quieres volver?',
    yesGoBack: 'Sí, vuelve',
    twentyFourHourFormat: 'Formato de 24 horas',
    quantityPL: 'Cantidad (PL)',
    quantityEA: 'Cantidad (EA)',

    // -- DELIVERY BULK UPLOAD VALIDATION RULES -- //
    shipItBulkDataValidation: 'Enviarla Validación Masiva de Datos de Carga',
    dataValidationRules: 'Reglas de Validación de Datos',
    field: 'Reglas',
    value: 'Valor',
    maxLength: 'Longitud Máxima',
    format: 'Formato',
    example: 'Ejemplo',
    quantityInPallets: 'Cantidad (en Paletas)',
    or: 'o',
    viewDataValidationRules: 'Ver Reglas de Validación de Datos',
    upTo8Digits: 'Hasta 8 dígitos',
    upTo15Digits: 'Hasta 15,4 dígitos',
    upTo3Characters: 'Hasta 3 caracteres',
    upTo10Characters: 'Hasta 10 caracteres',
    upTo25Characters: 'Hasta 25 caracteres',
    upTo24Characters: 'Hasta 24 caracteres',
    upTo30Characters: 'Hasta 30 caracteres',
    deliverToBackDoor: 'Entregar a la puerta trasera',
    dateInputFormat: 'MM/DD/AAAA',

    // -- DELIVERY BULK UPLOAD Errors -- //
    E000001: 'Enviar a ID es necesario y falta en esta línea. Por favor proporcione.',
    E000002: 'El ID del producto es necesario y falta en esta línea. Por favor proporcione.',
    E000003: 'La cantidad es necesaria y falta en esta línea. Por favor proporcione.',
    E000004: 'La fecha de entrega es necesaria y falta en esta línea. Por favor proporcione.',
    E000005: 'El tiempo de entrega es necesario y falta en esta línea. Por favor proporcione.',
    E100003: 'La cantidad debe ser un valor numérico menor o igual que 25.',
    E110001: 'La cantidad debe ser un valor numérico menor o igual que {0}.',
    E100004:
        'La fecha de entrega está en un formato incorrecto. Por favor, introduzca como MM/DD/AAAA',
    E100005:
        'El tiempo de entrega está en un formato incorrecto. Por favor, ingrese como HH:MM (24 horas de tiempo).',
    E100006:
        'Las instrucciones de entrega están en un formato incorrecto. El valor debe ser una cadena menor o igual que 30 caracteres.',
    E100007:
        'El número de versión está en un formato incorrecto. El valor debe ser una cadena menor o igual que 10 caracteres.',
    E100009:
        'El número de referencia está en un formato incorrecto. El valor debe ser una cadena menor o igual que 30 caracteres.',
    E100001:
        'Enviar a ID no válido. Compruebe la lista de los envíos asignados para obtener los ID de envío válidos.',
    E100013: 'La cantidad tiene un formato incorrecto. Introduzca hasta 6 dígitos.',
    E100014: 'La cantidad tiene un formato incorrecto. Introduzca hasta 12 dígitos.',
    E200001:
        'Enviar a ID no válido. Compruebe la lista de los envíos asignados para obtener los ID de envío válidos.',
    E200002: 'ID de producto no válido. Por favor, compruebe y corrija.',
    E100002: 'ID de producto no válido. Por favor, compruebe y corrija.',
    E200004:
        'El ID de producto introducido no se asigna al envío a introducido en esta línea. Cambie el Producto o Enviar a.',
    E300005:
        'No tiene permisos para enviar pedidos para este envío a. Elimine las filas con este archivo Enviar a.',
    E100008: 'Línea de relleno no válida. Por favor, compruebe y corrija.',
    E300004: 'El usuario no está asociado con este envío a',
    // Note: if you change the following translations be sure the format strings, {n}, are put in the right place of the new string
    E110002:
        'La fecha introducida es dentro de los {0} días de hoy. Por favor, introduzca una fecha que sea de al menos {0} días a partir de hoy.',
    E200006:
        'Las cantidades ingresadas en envíos para {1} en Envío a {2} para la semana del {3} superan el saldo disponible de {4}.',
    E200007:
        'Múltiples tipos de productos (lata vs. fin) en envío para Envío a {0} el {1} a las {2}. Mueva algunos productos a una Fecha/hora de entrega diferente para que cada Fecha/hora de Envío y Fecha/hora de Entrega solo tenga un tipo de producto.',
    E210005:
        'Cantidad introducida para Enviar a {0} en {1} es superior a {2} PL. Mueva algunos palés a una fecha/hora de entrega diferente para que cada fecha/hora de envío y entrega tenga un máximo de {2} PL.',

    // -- MAKE IT BULK UPLOAD Errors -- //
    EA000001: 'La cantidad tiene un formato incorrecto. Introduzca hasta 6 dígitos.',
    EA000002: 'La cantidad tiene un formato incorrecto. Introduzca hasta 12 dígitos.',
    EA000003: 'Ingrese una cantidad solicitada que sea al menos 0.',
    EA000004:
        'Requested Date is in a locked time period. Please select at least {0} for the lead time of {1} weeks.',
    EA000006:
        'Ya se ha realizado una orden Make It para esta semana y Ubicación de envío. Edite la orden existente desde el Resumen de la orden para agregar productos.',
    EA100001:
        'Este envío no está configurado para pedidos por cajero automático (autorización de fabricación). Por favor, elimine.',
    EP100007: 'This is not the correct template format for this customer',
    EP000001: 'El ID del Ship To es necesario y falta en esta línea. Complételo.',
    EP000002:
        'El número de orden de compra del cliente es obligatorio y falta en esta línea. Complételo.',
    EP000003: 'El ID del producto es necesario y falta en esta línea. Por favor proporcione.',
    EP000004: 'La fecha de pedido solicitada es obligatoria y falta en esta línea. Complétela.',
    EP000005: 'Quantity is required and missing from this line. Please provide.',
    EP100006: 'Introduzca las cantidades como un número entero.',
    E100023: 'Introduzca las cantidades como un número entero.',
    EP100001: 'El ID del Ship To tiene un formato incorrecto. Ingrese hasta 8 dígitos.',
    EP110001: 'El ID del Ship To tiene un formato incorrecto. Ingrese hasta 8 dígitos.',
    EP100002:
        'El número de orden de compra del cliente tiene un formato incorrecto. Ingrese texto de hasta 25 caracteres.',
    EP100003:
        'El ID del producto tiene un formato incorrecto. Ingrese texto de hasta 25 caracteres.',
    EP100004:
        'La fecha solicitada no es válida o está en un formato incorrecto. Por favor, verifique e introduzca en formato MM/DD/YYYY.',
    EP100005: 'La cantidad tiene un formato incorrecto. Ingrese hasta 6 dígitos.',
    EP110005: 'La cantidad tiene un formato incorrecto. Ingrese hasta 6 dígitos.',
    EP200001:
        'La fecha ingresada es anterior a la de hoy. Ingrese una fecha futura que tenga en cuenta el tiempo de entrega.',
    EP200002: 'Ingrese una cantidad mayor que 0 que represente el pedido mínimo.',
    EP200003: 'ID de producto no válido. Por favor, compruebe y corrija.',
    EP200004: 'ID de producto no válido. Por favor, compruebe y corrija.',
    EP200005:
        'El ID del producto ingresado no está asignado en el Ship To ingresado en esta línea. Cambie el producto o el Ship To.',
    EP300001:
        'Enviar a ID no válido. Compruebe la lista de los envíos asignados para obtener los ID de envío válidos.',
    EP400001:
        'No tiene permisos para enviar pedidos para este Ship To {0}. Elimine las filas con este Ship To del archivo.',
    EP500002:
        'La fecha solicitada está en un periodo de tiempo bloqueado. Seleccione una fecha posterior que tenga en cuenta el tiempo de entrega del producto.',
    EP100008: 'Este producto no está disponible en una ventana de campaña actual',
    EP100009:
        'La fecha solicitada es anterior a la primera fecha de entrega disponible para este producto. Tenga en cuenta el tiempo de entrega.',
    EP100010:
        'Your file has multiple Customer PO numbers for the following Week(s): {{weeks}}.  Please correct this error and upload again to submit your order.',

    // -- MAKE IT BULK UPLOAD -- //
    mapMakeItTemplateValues: 'Valores de la plantilla Map Make It',
    reviewYourOrders: 'Revise sus pedidos',
    acceptedMakeItBulkFiles:
        'Suba su archivo .csv y asegúrese de que sus valores coincidan con su mapeo.',
    uponSubmitCancelMessage:
        'Una vez enviado, cualquier producto previsto con anterioridad y no incluido de nuevo en la "Semana de previsiones" (Forecast Week) y en "Envío a" (Ship to) será cancelado.',
    removeShipToLocationsP1:
        'Si desea eliminar todas las ubicaciones de "Envío a" (Ship to) de una semana de previsiones, edite esos registros a través del ',
    removeShipToLocationsP2:
        ' (Plan It Summary) para cada "Envío a" y semana o inclúyalos en el archivo con una cantidad de 0.',
    productNotIncluded:
        'Producto no incluido en el archivo actualizado. Ingrese la cantidad a agregar.',
    yourOrders: 'Sus Pedidos',
    makeitMapTemplateFieldsDescription:
        'Asigne los valores necesarios a su columna correspondiente en la plantilla. Todos los siguientes valores son obligatorios.',
    uploadFile: 'Cargar archivo',
    uploadMakeItOrder: 'Cargue su pedido a Make It',
    uploadMakeItATMOrder: 'Upload Your ATM Order(s)',
    updateMakeItTemplateValues: 'Actualizar los valores de la plantilla Make It',
    submitOrders: 'Enviar pedido',
    needMoreInfo: '¿Necesita más información?',
    viewAssignedShipToLocations: 'Ver ubicaciones asignadas de Ship To',
    yourAssignedShipToLocations: 'Sus ubicaciones asignadas de Ship To',
    dateModified: 'FECHA DE MODIFICACIÓN',
    makeItIntakeResetConfirmation:
        '¿Está seguro de que desea restablecer? Cualquier cambio se perderá.',
    orderDeleted: `Pedido de Make It eliminado`,
    deleteOrderModalTitle: `Confirme que desea eliminar este pedido`,
    deleteOrderModalBody: `¿Está seguro de que desea eliminar este pedido?`,
    palletQuantityZeroError: `No puede ser 0. Actualícelo o elimínelo.`,
    deleteLastProductModalTitle: `Confirme que este pedido será eliminado`,
    deleteLastProductModalSubheader: `Eliminar todos los productos del pedido eliminará la orden de compra.`,
    deleteLastProductModalBody: `Confirme que desea eliminar.`,
    csvRowNumber: 'Fila .CSV',
    productIdModalTitle: 'Confirm Requested Product',
    confirmApplyAll: 'Confirm and Apply to All',
    productIdModalBody:
        'The customer product ID: <strong>{{ customerProductId }}</strong> you entered in your .csv file returned multiple products. Please choose the correct product for this order.',
    productIdDeliveryModalBody:
        'The customer product ID: <strong>{{ customerProductId }}</strong> you requested <strong>{{ requestedQuantity }} Pallets</strong> for in your .csv file returned multiple products. Please choose the correct product for this order.',
    chooseLaterDate: 'Elija una fecha posterior',
    makeItBulkError: 'Hay errores asociados con uno o más órdenes a continuación. Corríjalos.',
    reviewATMs: 'Revisar ATMs',
    editATM: 'Editar ATM',
    editATMsForecast: 'Editar ATMs y pronóstico',
    editForecast: 'Editar pronóstico',
    atmWeekSelector: 'Ver la semana de:',
    forecastWeekSelector: 'Vista la previsión para la semana del',
    noValidForecasts:
        'The upload did not result in any valid Plan It records. Please edit your file or use Plan It Guided.',
    orderForWk: 'Orden para la semana de:',
    forTheWeekOf: 'para la semana de',
    forecastForWk: 'Previsión para la semana del',
    editATMforWk: 'Editar ATM para la semana de',
    addPallet: 'Añada 1 palé para cumplir con su plan de suministro',
    addPallets: 'Añada {0} palés para cumplir con su plan de suministro',
    removePallet: 'Retire 1 palé para cumplir con su plan de suministro',
    campaignQuantitiesConfirmed:
        'Campaign Quantities will be confirmed by your Ball representative.',
    removePallets: 'Retire los palés {0} para cumplir con su plan de suministro',
    totalEnteredQuantity: 'Cantidad total introducida',
    of: 'de',
    quantityRounded: 'Cantidad redondeada al palé completo más cercano.',
    enterAQuantity: 'Por favor, introduzca una cantidad',
    leadTimeMet: 'This Product’s lead time has been met and cannot be ordered during this week',
    atmSelectorMessage:
        'Uno o más envíos tienen advertencias o errores en ellos; por favor, revise.',
    atmWeekSelectorMessage: 'Una o más semanas tienen advertencias o errores; por favor, revise.',
    previousQuantitySubmitted: 'Cantidad anterior enviada',
    atmChangesReceived: 'Gracias, se han recibido sus cambios.',
    atmSubmitted: 'Gracias, se han enviado su ATM',
    submitATMError: 'El sistema no pudo procesar sus cambios.',
    noProductsOrdered: 'No se han ordenado productos para los siguientes planes de abastecimiento:',
    eaches: 'Unidades',
    errorsOnly: 'Solo errores',
    warningsOnly: 'Solo advertencias',
    alertsOnly: 'Solo alertas',
    revisedQuantityToPurchase: 'Cantidad revisada a comprar',
    submitATMs: 'Enviar ATMs',
    productAttributes: 'Atributos del producto',
    atmWeekType: '¿Sus semanas se muestran en filas o columnas?',
    atmQuantityType: 'Sus cantidades en paletas o unidades?',
    prevQuantityPallets: 'Cantidad anterior enviada (PL)',
    prevQuantityEaches: 'Cantidad anterior enviada (EA)',
    revisedQuantityPallets: 'Cantidad revisada a comprar (PL)',
    revisedQuantityEaches: 'Cantidad revisada a comprar (EA)',
    quantityPallets: 'Cantidad a comprar (PL)',
    quantityEaches: 'Cantidad a comprar (EA)',
    currentAndUpcomingCampaigns: 'Current & Upcoming Campaigns',
    rows: 'Filas',
    columns: 'Columnas',
    firstRowOfData: 'Primera fila de datos',
    weekNumberQuantity: 'Semana {{ weekNumber }} Cantidad',
    weekQuantityRequiredError: 'Requerido. Remover si no es necesario.',
    addAdditionalWeek: 'Agregar Semana Adicional',
    weekNumberDate: 'Semana {{ weekNumber }} Fecha',
    enterWeekDatesBelow: 'POR FAVOR INTRODUZCA LAS FECHAS DE SUS SEMANAS A CONTINUACIÓN:',
    weekRequired: 'Requerido. Por favor, actualice o elimine una semana ya planificada.',
    forecastedDemand: 'Demanda prevista',
    reviewAtm: 'Revisar ATM (Autorización de fabricación)',
    availableReleaseDate: 'Semana Disponible',
    selectRequestedWk: 'Seleccione la semana solicitada',
    selectForecastWk: 'Seleccionar la semana(s) de estimación',
    inactiveProductId: 'Este producto está inactivo y no se puede pedir.',
    availableSupplyPlan: 'Plan de abastecimiento disponible',
    move: 'Mover',
    copy: 'Copiar',
    leadTimeExceedsSelected: 'El plazo de entrega excede la semana seleccionada',

    // -- MAKE IT BULK UPLOAD VALIDATION RULES -- //
    makeItBulkValidation: 'Validación de datos de carga masiva de Make It',
    orderRequestedWeek: 'Order Requested Week',
    doNotIncludeCommas: 'Do not include commas within your file.',
    quantityInEaches: 'Quantity (In Eaches)',
    upTo12Digits: 'Hasta 12 dígitos',
    integer: 'Entero',
    text: 'Texto',
    upTo6Digits: 'Hasta 6 dígitos',

    // -- Plan IT BULK UPLOAD VALIDATION RULES -- //
    planItBulkValidation: 'Plan It Bulk Upload Data Validation',
    productNotRecognized: 'Producto No Reconocido',
    includedProductsNotRecognized:
        'Uno o más de los productos incluidos en esta carga no se han reconocido.',
    here: 'here',
    ifProductIncorrect:
        'Si un ID del producto es incorrecto, retroceda para corregirlo y vuelva a cargar el plan. Los productos no reconocidos por la fuente no se incluirán en la estimación.',
    continueWithYourForecast:
        'Para continuar con su estimación sin los productos reconocidos, haga clic en “Continuar”.',
    editProductsCheckStatus:
        'Si desea editar productos o comprobar el estado de los productos recién agregados, puede administrarlos <1>aquí</1>.',
    prodID: 'ID del Producto',

    // -- PLAN IT TO MAKE IT CONVERSION-- //
    makeItFromPlanIt: 'Desde Make It hasta Plan It',
    conversionReviewOrder: 'Revisar pedido',
    makeItSummaryLink: 'Make It',
    noEligiblePlanItRecords: 'No eligible Plan It records exist for the current location.',
    visitPlanIt: 'Visit Plan It',
    toCreate: 'to create one.',
    makeItMoq: 'Cantidad mínima de pedido Make It',

    // -- MAKE IT ATM SUMMARY -- //
    atmSummary: 'Resumen de ATM',
    atmOrderNumber: 'Número de pedido del ATM',
    atmOrderNum: 'Pedido de ATM',
    atmWeek: 'Semana de ATM',
    totalQuantity: 'Cantidad total',
    playground: 'Playground',
    futureAtms: 'ATM Futuros',
    currentAtms: 'ATM Actuales',
    pastAtms: 'ATM Anteriores',
    underSupplyPlanWarning:
        'Está bajo el plan de suministro para uno o más grupos de productos en este ATM.',
    updateYourATM: 'Actualice su ATM',
    yourATM: 'Su ATM (Autorización de fabricación)',
    underSupplyPlanWarningProduct: "You're below the available supply plan for this product group",
    underSupplyPlanWarningProductPast:
        'You were below the available supply plan for this product group',
    orderWeek: 'Semana de orden',

    // -- MAKE IT BULK ATM DETAILS -- //
    requestedWeek: 'Semana solicitada',
    requestedWeekAndPONumber: 'Semana solicitada y número de pedido de compra (PO)',
    remainingInSupplyPlan: 'Permanencia en el plan de suministro',
    requestedQuantity: 'Cantidad requerida',

    // -- MAKE IT BULK ATM REVIEW -- //
    linkedDeliveryOrderOrLeadTimeWarning:
        'Uno o más productos no pueden ser editados debido a los plazos de entrega',
    makeItAtmDetails: 'Make It ATM Details',
    lineNumber: 'Número de línea #',
    sameWeekShipTosWarning:
        'Hay pedidos Make It adicionales para esta semana. Consulte la página de resumen de <1>Make It</1> para obtener más información.',
    sameWeekShipTosDraftsWarning:
        'There are draft Make It Order(s) for this week. Please see <1>Make It Drafts</1> page for details.',

    // -- ASSIGNED SHIP TOS -- //
    yourAssignedShipTos: 'Su envie a asignado',
    shipToName: 'Enviar a nombre',
    shipToAddress: 'Enviar a dirección',
    shipTos: 'Envie a',
    date: 'Fecha',
    availableBalanceDisclaimer: '*Saldo disponible basado en la semana de entrega seleccionada.',

    // -- User Admin -- //
    graphicsIntakeForm: 'Formulario de Admisión de Gráficos',
    draftGraphicsIntakeForms: 'Formularios de Admisión de Gráficos en Borrador',
    inProgressGraphicsStatuses: 'Estados de Gráficos en Curso',
    graphicsQuote: 'Cotización de Gráficos',
    graphicsProof: 'Prueba de Gráficos',
    graphicsStandardPilotCan: 'Estándar de Gráficos (Pilot Can)',
    recentlyCompletedGraphics: 'Gráficos Completados Recientemente',
    planning: 'Planificación',
    newOpenProductionOrders: 'Órdenes de Producción Nuevas/Abiertas',
    newOpenDeliveryOrders: 'Órdenes de Entrega Nuevas/Abiertas',
    draftDeliveryOrders: 'Borradores de Órdenes de Entrega',
    paymentsCIAOnly: 'Pagos (CIA Solamente)',
    orderPaymentStatus: 'Estado de Pago de Orden',
    orderPaymentFinancials: 'Finanzas de Pago de Pedidos',
    liquidTesting: 'Prueba de líquidos',
    liquidIntakeForm: 'Formulario de Admisión Líquida',
    warrantyLetter: 'Carta de Garantía',
    termsandConditions: 'Términos y Condiciones',
    temporarilyHideSKUs: 'Ocultar Temporalmente las SKU',
    manageBillTo: 'Administrar Bill-To',
    manageShipTo: 'Administrar Ship-To',
    customerProductIDMappingtoBallIDs: 'Asignación de ID de Producto de Cliente a Ball IDs',
    createNewUser: 'Crear Nuevos Usuarios',
    editpermissions: 'Editar Permisos',
    pricing: 'Precios',
    termsAndConditions: 'Contratos y Documentos',
    contractAcknowledgement: 'Reconocimiento del Contrato',
    pricingBreakdown: 'Desglose de Precios',
    disablePermissions: 'Deshabilitar Permisos',
    troubleshooting: 'Solución de Problemas',
    procurementManagement: 'Gestión de Aprovisionamientos',
    forecastManagement: 'Gestión de Previsiones',
    packagingManagement: 'Gestión de Embalajes',
    artManagement: 'Gestión de Elementos Artísticos',
    marketingManagement: 'Gestión de Marketing',
    accountsPayableAccountsReceivable: 'Contabilidad de Proveedores / Clientes',
    userAdministrator: 'Administrador de Cuenta',
    selectedAccess: 'Acceso Seleccionado',
    addUser: 'Añadir usuario',
    roles: 'Rol(es)',
    location: 'Ubicación',
    sentOn: 'Enviado',
    disable: 'Deshabilitar',
    resend: 'Reenviar',
    disableUser: 'Deshabilitar Usuario',
    disableUserWarning:
        'Está a punto de deshabilitar los permisos de este usuario en su cuenta. Deberá crear una nueva solicitud para recuperar los permisos.',
    disableUserContinue: '¿Seguro que desea continuar?',
    yesDisable: 'Sí, Deshabilitar',
    viewEdit: 'Ver/Editar',
    userInformation: 'Información del Usuario',
    confirmEmail: 'Confirmar Correo Electrónico',
    newUserEmailHelper: 'Correoelectrónico@dominio.com',
    locationRoleAccessTableInst: `Elija el rol o roles para dar acceso a este usuario por ubicación. Vea los permisos disponibles de un rol haciendo clic en el nombre del rol o`,
    locationRoleAccessTableRequest: `Elija el rol o roles para dar acceso a este usuario por ubicación. Vea los permisos disponibles de un rol haciendo clic en el nombre del rol o`,
    locationRoleAccessTableInstLink:
        'vea una comparación de todos los roles y sus permisos disponibles.',
    allAccess: 'Acceso Total',
    coPacker: 'Coenvasador',
    warehousingLogistics: 'Almacenamiento/Logística',
    acctAdmin: 'Administrador de Cuenta',
    acctPayableReceivable: 'Contabilidad de Proveedores/Clientes',
    graphicsVendor: 'Proveedor de Gráficos',
    pricingContact: 'Contacto para fijación de precios',
    rolesLocations: 'Roles (núm. de ubicaciones)',
    activeUsers: 'Usuarios Activos',
    accountAdmin: 'Administrador de Cuenta',
    arAp: 'Contabilidad de Proveedores/Clientes',
    artMgmt: 'Gestión de Elementos Artísticos',
    forecastMgmt: 'Gestión de Previsiones',
    marketingMgmt: 'Gestión de Marketing',
    orderFulfillment: 'Realización de la Orden',
    packagingMgmt: 'Gestión de Embalajes',
    procurementMgmt: 'Gestión de Aprovisionamientos',
    makeItOnly: 'Make It Only',
    planItOnly: 'Plan It Only',
    impersonation: 'Impersonation',
    roleAccessTableSearchPlaceholder: 'Search...',
    confirmChangesWillNotBeSaved:
        'Los cambios realizados no se guardarán. ¿Está seguro de que desea proceder?',
    disableUserError: 'El sistema no ha podido deshabilitar el usuario seleccionado',
    locationRoleAccess: 'Ubicación y Acceso a Rol',
    latestSubmissionDate: 'Fecha de Última Nueva Presentación',
    resubmit: 'Volver a presentar',
    cancelRequest: 'Cancelar solicitud',
    userAdminSuccessMessage: 'Los permisos de usuario se han actualizado',
    reviewAccessRequest: 'Revisar solicitud de acceso',
    approve: 'Aprobar',
    pendingRequests: 'Solicitudes Pendientes',
    pendingRegistration: 'Pendiente de Registro',
    reviewRequest: 'Solicitud de Revisión',
    roleComparison: 'The Source Comparación de Roles',
    permissionsFailedToUpdate:
        'Los cambios solicitados no se pudieron enviar debido a un error desconocido.',
    makeItQuantity: 'Cantidad de Make It',
    confirmSubmission: 'Confirm Submission',
    submitMakeItForFollowing: 'Está a punto de enviar un pedido de Make It para lo siguiente:',
    submitMakeItErrorWeeks:
        'Please note that the following week(s) are <1>not</1> included due to error(s):',
    areYouSureSubmit: '¿Está seguro de que desea enviar?',
    noCustomerPONumber: 'Sin orden de compra del cliente #',
    noCustomerPO: 'No Customer PO',
    minQtyNotMet: 'Minimum order quantity not met',
    productWithoutQuantity: 'Product without Quantity',
    multipleErrors: 'Múltiples errores',
    not: 'not',
    key: 'Clave',
    noSupplyPlan: 'Sin plan de abastecimiento',
    overSupplyPlan: 'Por encima del plan de abastecimiento',
    underSupplyPlan: 'Bajo el plan de abastecimiento',
    supplyPlanMet: 'Plan de abastecimiento cumplido',
    orderWeeks: 'Organizar semanas',
    // -- USER ADMIN REQUEST ACCESS -- //
    requestAccess: 'Solicitar Acceso',
    locationInformation: 'Información de Ubicaciones',
    addAnotherShipTo: 'Agregar otro Enviar a/Facturar a #',
    confirm: 'Confirmar',
    invalidIds:
        'Uno o más números de identificación no son válidos. Actualice o elimine estas ubicaciones para continuar.',
    shipToBillTo: 'Enviar a/Facturar a #',
    enterComment: 'Introducir comentarios',
    rejectAction: 'Rechazar',
    requestAccessUnauthTitle: 'Parece que todavía no tiene acceso a {{area}}',
    requestAccessUnauthBody:
        'Ningún problema; utilice el botón de abajo para solicitar acceso a su administrador para acceder a ',
    accessRequest: 'Solicitar Acceso',
    requestAccessSuccess: `Su solicitud de acceso se ha enviado correctamente y será revisada por los administradores de usuarios correspondientes.`,
    requestAccessError: 'No administrator assigned for the requested account(s)',
    confirmation: 'Confirmación',
    invitationSent: 'Su invitación a unirse a <i>The Source</i> ha sido enviada.',

    // -- PRICING BREAKDOWN -- //
    downloadPDF: 'Descargar .PDF',
    effectiveDate: 'Fecha de Entrada en Vigor',
    currency: 'Moneda',
    itemDescription: 'Descripción del Artículo',
    currentBasePrice: 'Precio Base Actual',
    newBasePrice: 'Precio Base Nuevo',
    freightCharges: 'Cargos de Flete',
    freightChargeFromLocation: 'Cargos de Flete Desde la Ubicación (si está disponible)',
    freightChargesAvailable: 'Cargos de Flete (si está disponible)',
    newBaseWithFreight: 'Precio Base Nuevo Incluido el Flete (si está disponible)',
    pricingLetterDisclaimer:
        'This price sheet serves as notice of new pricing effective {{ effectiveDate }} and is not a guarantee of current or future supply. Your submission of an order to purchase products constitutes your acceptance of this updated pricing under Ball’s current terms of sale.',
    pricingQtyNote: 'Prices are listed at a per thousand rate unless otherwise noted',
    priceSheet: 'Price Sheet',

    // -- KB MAX -- //
    errorProcessing: 'Ha habido un error de procesamiento.',
    exitWarning: '¿Seguro que desea salir?',
    yesExit: 'Sí, Salida',

    // -- LIQUID TESTING -- //
    liquidTestThankYou: 'Gracias por enviar la información de su prueba de líquidos',
    liquidTestIntakeForm: 'Formulario de admisión para la prueba de líquidos',
    liquidTestIntakeFormPrintInstructions:
        'Si no imprimió el formulario que se mostró anteriormente, imprímalo aquí e inclúyalo cuando envíe su muestra de líquidos. También puede imprimir o descargar una copia para sus registros.',
    liquidTestIntakeFormPrintInstructionsNoTest:
        'Puede imprimir o descargar su formulario de admisión para sus registros aquí.',
    noLiquidTestNeeded:
        'Según esta información, no es necesario que envíe su líquido para la prueba de líquidos.',
    aceticAcid: 'Ácido acético',
    addedSulfites: 'Sulfitos añadidos',
    calciumLactate: 'Lactato de calcio',
    foodDyes: '> 80 ppm de colorantes alimentarios',
    lacticAcid: 'Ácido láctico',
    malicAcid: 'Ácido málico',
    liquidTest: 'Prueba de líquidos',
    beverageInformation: 'Información sobre bebidas',
    alcoholByVolumePercentage: '% de ABV',
    potentialOfHydrogen: 'pH',
    fourIngredientBeerFlag: 'Cerveza de 4 ingredientes',
    beverageDescription: 'Descripción de la bebida',
    isRefrigerationRequired: '¿Necesita refrigeración?',
    concentrationSample: 'Muestra de Jarabe',
    beverageContains: 'La bebida contiene',
    instructions: 'Instrucciones',
    potentialOfHydrogenRange: 'pH must be a number between 0 and 14',
    liquidIntakeTestRequired:
        'Imprima este formulario e inclúyalo en el paquete en el que envía su muestra de líquidos. Enviar a la dirección de envío a la derecha.',
    liquidIntakeTestNotRequired:
        'Según esta información, no es necesario que envíe su líquido para la prueba de líquidos. Imprima este formulario e inclúyalo en el paquete en el que envía su muestra de líquidos. Enviar a la dirección de envío a la derecha.',
    newProduct: 'Nuevo producto',
    updatedRecipe: 'Receta actualizada',
    beverageNameOrProjectName: 'Proporcione al menos uno de los siguientes:',
    graphicIdAssociated: '¿Tiene una identificación gráfica asociada con esta bebida?',
    doNotKnowPH: 'No sé el pH',
    fourIngredientBeer: '¿Es esta una simple cerveza de 4 ingredientes?',
    newProductOrUpdate:
        '¿Es este un producto nuevo o una receta actualizada de un producto existente?',
    doesBeverageContain: 'Su bebida contiene:',
    isConcentrateSample: '¿Es esta una muestra de concentración?',
    throwRatio: 'Tasa de tiro',
    formSubmissionError:
        'El sistema no pudo enviar el formulario. Por favor, inténtelo de nuevo más tarde.',
    formSubmissionErrorContact:
        'The system was unable to submit the form. Please try again later, if the issue persists please contact your account representative.',
    submittingUser: 'Usuario que envía',
    submittedDate: 'Fecha de envío',
    testingRequired: 'Testing Required?',
    beverageName: 'Nombre de la bebida',
    liquidTestDocuments: 'Documentos de prueba de líquidos',
    liquidTestId: 'Liquid Test ID',
    printLiquidTestIntake: 'Imprimir formulario de admisión para la prueba de líquidos',
    packingSlip: 'Packing Slip',
    intakeStatusARS: 'Esperando recepción de muestra',
    intakeStatusNTR: 'No se requiere prueba',
    intakeStatusRPT: 'Recibido, prueba pendiente',
    intakeStatusWAQ: 'Garantía aprobada, pero con reservas',
    intakeStatusWRA: 'Garantía aprobada',
    intakeStatusWRR: 'Garantía rechazada, vuelva a enviar',
    printIntakeForm: 'Imprimir formulario de admisión',
    closedDate: 'Fecha de cierre',
    warrantyDetails:
        'Para obtener información sobre la garantía de las pruebas de líquidos aprobadas, consulte su Contrato o los Términos y condiciones.',
    beverageNameOrProjectNameRequired: 'Nombre de la bebida o nombre del proyecto requerido',
    phone: 'Teléfono',
    viewPrint: 'Ver /Imprima',
    submitNewTest: 'Enviar nueva prueba',
    alcoholByVolumeHelper: 'ABV debe ser un número entre 0 y 100',
    potentialOfHydrogenHelper: 'El pH debe ser un número entre 0 y 14',

    // -- TERMS AND CONDITIONS -- //
    acceptTermsAndConditions: 'Aceptar términos y condiciones',
    authorizedTermsAndConditions:
        '¿Está autorizado a aceptar términos y condiciones en nombre de su empresa?',
    readAndAgree: 'He leído y acepto todos los términos y condiciones antes mencionados.',
    cannotImportAlcohol: 'Ball no puede importar alcohol. Para contacto de soporte: ',
    adminNotified: 'Su administrador ha sido notificado de su respuesta.',

    // -- SALES AND USE TAX -- //
    returnToDashboard: 'Regresar al tablero',
    salesAndUseTaxBanner: 'Configuración de cuenta',
    instructionsAnchorText: 'Instrucciones',
    sellerInformationAnchorText: 'Información del vendedor',
    buyerInformationAnchorText: 'Información del comprador',
    eSignatureAnchorText: 'Firma electrónica',
    salesAndUseTaxSideHeader: 'Impuesto sobre ventas y uso',
    salesUseTaxInstructionsLinkText: 'Instrucciones específicas del estado',
    salesUseTaxInstructionsHeader:
        'Instrucciones sobre el Certificado uniforme de impuestos sobre ventas y uso',
    salesUseTaxInstructionsBody1: 'Para los clientes del vendedor:',
    salesUseTaxInstructionsBody2: `Para cumplir con la mayoría de los requisitos de la ley de impuestos sobre las ventas estatales y locales, el vendedor debe tener en los archivos un certificado de exención debidamente ejecutado de todos sus clientes que reclaman una exención del impuesto sobre las ventas. Si el vendedor no cuenta con este certificado, está obligado a cobrar el impuesto del estado en el que se proporciona la propiedad o el servicio.`,
    salesUseTaxInstructionsBody3: `Si el comprador tiene derecho a la exención del impuesto sobre las ventas, el comprador debe completar el certificado y enviarlo al vendedor lo antes posible. Si el comprador realiza una compra libre de impuestos por una razón que no se menciona en este formulario, el comprador debe enviar al vendedor su certificado o declaración especial.`,
    salesUseTaxInstructionsBody4: `Advertencia para el vendedor:`,
    salesUseTaxInstructionsBody5: `Para que el certificado sea aceptado de buena fe por parte del vendedor, el vendedor debe tener cuidado de que la propiedad o el servicio que se vende sea de un tipo que normalmente se vende al por mayor, se revende, se arrenda, se alquila o se incorpora como ingrediente o componente de un producto fabricado por el comprador y luego revendido en el curso habitual de su negocio. Un vendedor que no ejerza el debido cuidado podría ser considerado responsable del impuesto sobre ventas adeudado en algunos estados o ciudades. El uso indebido de este certificado por parte del vendedor, el arrendatario o su representante puede ser sancionado con una multa, encarcelamiento o la pérdida del derecho a emitir el certificado en algunos estados o ciudades.`,
    sellerInformationCheckboxSubheader: 'Emitido al vendedor (seleccione todo lo que corresponda):',
    typeOfCertificateLabel: 'Tipo de certificado',
    blanketCertificateCheckbox: 'Certificado general',
    singlePurchaseCheckbox:
        'El certificado de compra única se relaciona con la factura/orden de compra n.º:',
    nameOfFirmLabel: 'Nombre de la compañía',
    certifyBody: 'Certifico que:',
    buyerSubtext: 'Comprador',
    engagedAsRegisteredLabel: 'Est engagé comme enregistré:',
    wholesalerCheck: 'Vendedor mayorista',
    retailerCheck: 'Vendedor minorista',
    manufacturerCheck: 'Fabricante',
    sellerCheck: 'Vendedor (California)',
    lessorCheck: 'Arrendador',
    otherCheck: 'Otro (especifique)',
    registeredBody2: `y está registrado en los estados y las ciudades que se enumeran a continuación, dentro de los cuales su compañía nos entregaría compras y que dichas compras son para venta al por mayor, para reventa, ingredientes o componentes de un nuevo producto o servicio para revender, arrendar o alquilar en el curso normal del negocio. Estamos en el negocio de venta al por mayor, venta al por menor, fabricación, arrendamiento (alquiler) de lo siguiente:`,
    descriptionOfBusinessLabel: 'Descripción de negocio',
    descriptionOfPropertyLabel:
        'Descripción general de bienes tangibles o servicios sujetos a impuestos que se comprarán al vendedor',
    stateSelect: 'Seleccionar estado',
    registrationNumberLabel:
        'Registro estatal, permiso del vendedor o número de identificación del comprador (Opcional)',
    addAnotherStateLabel: 'Agregar otro estado',
    eSignatureHeader: 'Firma electrónica',
    eSigCertify:
        'Además, certifico que, si cualquier propiedad o servicio adquirido libre de impuestos es utilizado o consumido por la compañía de manera que esté sujeto a un Impuesto sobre ventas o uso, pagaremos el impuesto adeudado directamente a la autoridad fiscal correspondiente cuando la ley estatal así lo disponga o informaremos el vendedor para la facturación de impuestos adicionales. Este certificado será parte de cada pedido que le daremos en el futuro, a menos que se especifique lo contrario, y será válido hasta que lo cancelemos por escrito o sea revocado por la ciudad o el estado. Bajo pena de perjurio, juro o afirmo que la información en este formulario es verdadera y correcta en todos los asuntos importantes.',
    saveProgressText: 'Corrija o elimine los datos no válidos antes de guardar el borrador',

    // -- ONBOARDING WIZARD -- //
    saveProgressSecondaryText: 'El borrador se guardó correctamente',
    joinBallBanner: 'Únase a Ball',
    joiningBall: 'Unirse a Ball',
    step: 'Paso',
    gettingStarted: 'Introducción',
    gettingStartedText:
        'Nos alegra que quiera dar el siguiente paso con nosotros. Antes de que pueda crear sus productos, necesitaremos información adicional sobre usted.',
    congratulations: '¡Felicidades!',
    onboardingCongratulationsText:
        'Su cuenta fue configurada y está listo para comenzar a usar The Source. Antes de que pueda realizar su primer pedido, debe:',
    onboardingCongratulationsAdditionalText:
        'También puede agregar miembros de su empresa mediante la función User Admin (Administrador de usuarios) en el encabezado de arriba o',
    clickingHere: 'haciendo clic aquí',
    submitAGraphic: 'Enviar un gráfico',
    submitLiquidSample: 'Enviar una muestra de líquidos para su análisis',
    productSelectionHeader: 'Confirmed Product Selection and Supply Capacity',
    productSelectionText:
        '¡Buenas noticias! El producto que seleccionó está disponible y podemos continuar para configurarlo como cliente de Ball. Deberá completar los pasos a continuación y luego estará listo para realizar un pedido.',
    yourProducts: 'Su(s) producto(s)',
    completeStatus: 'Completado',
    accountSetupHeader: 'Configuración de cuenta',
    accountSetupTCTitle: 'Términos y Condiciones',
    accountSetupTCText:
        'Usted, o una persona autorizada, deberá aceptar los términos y las condiciones de Ball antes de continuar con el proceso de incorporación.',
    accountSetupReviewTCAction: 'Revise los términos y las condiciones',
    billAndShipTitle: 'Facturación y envío',
    billAndShipText:
        'Es importante que tengamos la información correcta de facturación y envío para su empresa.',
    billAndShipAction: 'Ingrese la información',
    salesAndUseTitle: 'Impuesto sobre ventas y uso',
    salesAndUseText:
        'Los clientes con sede en EE. UU. deben completar un formulario de impuestos sobre ventas y uso para el estado en el que operan.',
    salesAndUseAction: 'Ingrese la información',
    continue: 'Continuar',
    creditApplicationTitle: 'Solicitud de crédito',
    creditApplicationText:
        '¿Le interesa solicitar un crédito con Ball? Complete esta solicitud para iniciar el proceso.',
    creditApplicationAction: 'Iniciar solicitud',
    creditApplicationActionContinue: 'Continuar solicitud',
    productSetupHeader: 'Configuración del producto',
    graphicsTitle: 'Grafismo',
    graphicsText:
        'Aquí está la parte divertida! Inicie el proceso de gráficos rellenando el formulario de admisión de gráficos y enviando su obra de arte.',
    graphicsAction: 'Iniciar proceso de gráficos',
    liquidTitle: 'Liquid Testing',
    liquidText:
        'Es posible que su bebida deba pasar por pruebas de compatibilidad.  Para comenzar el proceso de prueba, por favor llene nuestro formulario en línea y envíe por correo su(s) muestra(s) líquida(s).',
    liquidAction: 'Iniciar proceso de prueba',
    placeYourOrderHeader: 'Realice su pedido',
    placeYourOrderText:
        '¡Ha llegado el momento! ¡Haga su pedido de su producto en su panel de control!',
    placeYourOrderAction: 'Make It',
    optionalStepText: 'Opcional',
    accountInformation: 'Información de cuenta',
    billingAddress: 'Dirección de facturación',
    shippingInformation: 'Información de envío',
    accountSetup: 'Configuración de cuenta',
    billMailingName: 'Nombre de correo de la factura',
    billingAddressLine1: 'Dirección de facturación, línea 1',
    billingAddressLine1Helper: 'Tipo de calle, nombre de la calle, número de la casa',
    billingAddressLine2: 'Dirección de facturación, línea 2 (Opcional)',
    billingAddressLine2Helper: 'Barrio, municipio',
    billingAddressLine3: 'Dirección de facturación, línea 3 (Opcional)',
    billMailingNameHelper: 'Nombre de la persona que recibirá las comunicaciones',
    shippingSameAsBillingAddress:
        '¿Su dirección de envío es la misma que su dirección de facturación?',
    shippingName: 'Nombre de envío',
    shippingAddressLine1: 'Adresse de livraison, ligne 1',
    shippingAddressLine1Helper: 'Tipo de calle, nombre de la calle, número de la casa',
    shippingAddressLine2: 'Adresse de livraison, ligne 2 (Opcional)',
    shippingAddressLine2Helper: 'Barrio, municipio',
    shippingAddressLine3: 'Adresse de livraison, ligne 3 (Opcional)',
    shippingNameHelper: 'Nombre de la persona que recibirá los envíos',
    shippedOrDelivered: '¿Necesita que le envíen sus latas o usted las recogerá?',
    palletConfiguration: 'Disposición de palés',
    anySpecialEquipmentNeeds: '¿Cualquier necesidad de equipo especial?',
    cfdi: 'CFDI',
    taxId: 'Identificación fiscal',
    legalEntityOrPrivateEntrepreneur: '¿Es una entidad jurídica o un emprendedor privado?',
    importerOfRecord: 'Importador de registro',
    customer: 'Cliente',
    requiredDocuments: 'Documentos requeridos',
    entrepreneur: 'Empresario',
    legalEntity: 'Entidad jurídica',
    reqDocInstructionHeader: 'Debe proporcionar los siguientes documentos:',
    legalEntReqDocInstruction1: 'Acuerdo de operación',
    legalEntReqDocInstruction2:
        'Carta de empoderamiento del representante legal (a veces, esta carta está dentro del acuerdo operativo)',
    legalEntReqDocInstruction3: 'Identificación del representante legal (credencial INE)',
    legalEntReqDocInstruction4: 'Registro SAT (documento del IRS de México)',
    legalEntReqDocInstruction5: 'Comprobante de domicilio de la empresa',
    entrepreneurReqDocInstruction1: 'Carta de situación del SAT (documento del IRS de México)',
    entrepreneurReqDocInstruction2: 'Tarjeta INE (Identificación oficial de México)',
    entrepreneurReqDocInstruction3:
        'Comprobante de domicilio (factura de teléfono o factura de servicios)',
    accountSetupFailedToSubmit: 'No se pudo enviar el formulario de configuración de cuenta',
    accountSetupCantSaveDraft: 'Error al guardar el borrador',
    invalidDataMessage: 'Corrija o elimine los datos no válidos antes de guardar el borrador',
    inviteToHelpComplete: 'Invite a alguien para que lo ayude a completar una tarea',
    inviteModalHelperText:
        'Puede invitar a otras personas a completar la información en el proceso de inscripción. Ingrese su correo electrónico a continuación y seleccione las áreas que desea que completen. Recibirán un correo electrónico para completar la sección asignada.',
    informationReceived: 'Vos informations ont été reçues et sont en cours de traitement.',
    usOnly: '(Solo EE. UU.)',
    selectAtLeastOne: 'Necesario para seleccionar al menos uno.',
    moreProductsThanShown:
        'More products are approved but not shown. Go to <1>My Projects</1> to learn more.',

    // -- CREDIT APPLICATION FORM -- //
    companyInformation: 'Información de la compañía',
    supplierReferences: 'Referencias de proveedores',
    additionalCommentsRequired: 'Comentarios adicionales',
    currentFinancialStatement: 'Estado financiero actual',
    creditAppListHeader: 'Para solicitar un crédito con Ball, debe proporcionar lo siguiente:',
    creditAppFirstLI:
        'Información de la empresa, que incluye, entre otros, la línea de crédito mensual deseada, la información de contacto del departamento de cuentas a pagar, las direcciones de facturación y ubicación real, los nombres y los cargos del propietario/funcionarios/socios de la compañía, etc.',
    creditAppSecondLI:
        '3 referencias de proveedores con montos iguales o superiores a la línea de crédito mensual deseada',
    creditAppThirdLI: 'Estado financiero actual que explique por qué no está presente',
    legalNameOfBusiness: 'Nombre legal de la compañía',
    businessName: 'Nombre de la compañía (si es diferente)',
    businessNameHelper: 'Si hay división, subsidiaria',
    typeOfBusiness: 'Tipo de negocio',
    monthlyLineOfCredit: 'Línea de crédito mensual deseada',
    billingCountry: 'País de facturación',
    differentRealAddress:
        '¿La dirección de la ubicación real es la misma que su dirección de facturación?',
    accountsPayableContact: 'Contacto de cuentas a pagar',
    accountsPayableContactEmail: 'Correo electrónico de contacto de cuentas a pagar',
    stateOfIncorporation: 'Estado de constitución',
    dateEstablished: 'Fecha de establecimiento',
    companyInfoHelper:
        'Proporcione la información para los funcionarios, socios o propietarios de su compañía.',
    supplierReferenceHelper:
        'Debe proporcionar tres (3) referencias con montos iguales o superiores a la línea de crédito solicitada.',
    arContact: 'Contacto de cuentas por cobrar',
    arEmail: 'Correo electrónico de cuentas por cobrar',
    faxNumber: 'Número de fax',
    financialStatementHelper:
        'Si guarda su progreso y sale del formulario, se le pedirá que cargue este archivo cuando regrese. El archivo no se guardará.',
    explanationHelper: 'Si no cargará un estado financiero actual, explique el motivo.',
    addAnother: 'Agregar otro',
    creditApplicationTitleText:
        '¿Le interesa solicitar un crédito con Ball? Complete esta solicitud para iniciar el proceso.',
    explanation: 'Explicación',
    actualMailingName: 'Nombre de correo de la ubicación real',
    actualCountry: 'País de ubicación real',
    actualAddressLine1: 'Dirección de ubicación real, línea 1',
    actualAddressLine2: 'Dirección de ubicación real, línea 2 (opcional)',
    actualAddressLine3: 'Dirección de ubicación real, línea 3 (opcional)',
    actualCity: 'Ciudad de ubicación real',
    actualStateProvince: 'Estado/provincia de ubicación real',
    actualPostalCode: 'Código postal de ubicación real',
    numericInput: 'Entrada monetaria no es válida',
    usePreviouslyEnteredBillingAddress: 'Use previously entered Billing Address?',

    // -- CAPACITY CHECKS -- //
    capacityCheckSuccessMessage:
        '¡Su solicitud ha sido enviada! Las revisiones suelen tardar entre 2 y 4 semanas. Una vez completada, alguien de nuestro equipo se comunicará con usted.',
    myProjectsCapacityCheckStatus: 'Mis proyectos / Estado de verificación de capacidad',

    // -- ATM PRODUCT PLANNING -- //
    supplyPlanAttributes: 'Atributos del plan de abastecimiento',
    supplyPlan: 'Plan de suministro',

    // -- NOTIFICATIONS -- //
    CAMPAIGN_ENDING:
        'El plazo de solicitud de {0} se cerrará el {1}. La primera fecha de producción disponible es {2}. ',
    CAMPAIGN_UPCOMING:
        'El plazo de solicitud de {0} comenzará el {1}. Las solicitudes deben realizarse antes del {2}, y la primera fecha de producción disponible es {3}. ',
    CAMPAIGN_UPDATE:
        'La información del plazo de solicitud para su producto, {0}, ha cambiado. El plazo de solicitud actualizado es: del {1} al {2} y la primera fecha de producción disponible es {3}. ',
    ALLOCATIONS: 'You have a new Supply plan for the week(s) of: {0}.  ',
    NEW_PRODUCT:
        'A new product has been added to your product portfolio: {0} - {1}.  You are now able to add this product to your orders. ',

    // -- MAKE IT DRAFTS SUMMARY -- //
    createdDate: 'Created Date',
    unauthorizedPoTooltip: 'Not authorized to manage POs for this location',
    discard: 'Discard',
    viewDraft: 'View Draft',
    archiveDraftWarning: 'Archived drafts will expire and no longer show in archive after 30 days.',
    resumeOrder: 'Resume Order',
    lockWindowWarning: 'This week will be locked for ordering in {0} days',
    draftDisplayWarning: 'Drafts will no longer show after the lock window begins',
    discardDraftAreYouSure: 'Are you sure you want to discard your draft?',
    discardDraftWillBeDeleted: 'Your draft order will be deleted.',
    openDrafts: 'Open Drafts',
    archivedDrafts: 'Archived Drafts',
    restoreDraft: 'Restore Draft',
    selectWeek: 'Select Week',
    restore: 'Restore',

    // -- MANAGE PRODUCTS -- //
    manageProducts: 'Administrar Productos',
    productInformation: 'Información del producto',
    activeProducts: 'Productos Activos',
    inactiveProducts: 'Productos Inactivos',
    unrecognizedProducts: 'Unrecognized Products',
    displayName: 'Nombre para mostrar',
    pendingDataChangeAndDeactivation: 'Pending Data Change & Deactivation',
    pendingDataChangeAndActivation: 'Pending Data Change & Activation',
    productSizeAndShape: 'Product Size/Shape',
    productsNotRecognized:
        'These products were included on a Plan It file but are not recognized by Ball.',
    requestDeactivation: 'Solicitar Desactivación',
    editProductInfo: 'Editar Información del Producto',
    assignedGraphicId:
        'Indica que el identificacion del gráfico está asignado a un producto activo actual',

    // Countries
    usa: 'EEUU',
    eu: 'Europa',
    uk: 'Reino Unido',
    germany: 'Alemania',
    france: 'Francia',
    canada: 'Canadá',
    china: 'China',
    japan: 'Japón',
    southKorea: 'Corea del Sur',
    ausNZ: 'Australia/Nueva Zelanda',
    australia: 'Australia',
    nz: 'Nueva Zelanda',
    mexico: 'México',
    india: 'India',
    vietnam: 'Vietnam',
    taiwan: 'Taiwan',
    russia: 'Russia',
    argentina: 'Argentina',
    brazil: 'Brasil',
    paraguay: 'Paraguay',
    uruguay: 'Uruguay',
    venezuela: 'Venezuela',
    noCountryApplies: 'Ninguno de los países especificados se aplica',
    planIt: 'Plan It',
    productAlerts: 'Product Alerts',
    forecastBulkUpload: 'Previsión de carga masiva',
    forecastCreateMappingText: 'Mapa de los valores de la plantilla de previsión',
    forecastUpdateMappingText: 'Actualización de los valores de la plantilla de previsión',
    forecastUploadSectionHeader: 'Cargar su previsión',
    forecast: 'Pronóstico',
    forecastWeek: 'Semana de previsiones',
    previousForecastedQuantity: 'Cantidad previamente prevista',
    revisedForecastedQuantity: 'Cantidad prevista revisada',
    reviewMy: 'Revisar mi',
    forecastOrderNumber: 'Número de pedido de previsión',
    forecastedQuantity: 'Cantidad prevista',
    viewMy: 'Vista mi',
    forecastDetails: 'Detalles del Pronóstico',
    forecastNum: 'Pronóstico #',
    summary: 'Resumen',
    inactive: 'Inactivo',
    active: 'Activo',
    forecastSummary: 'Resumen de Previsión',
    forecasts: 'Previsiones',
    planItSummary: 'Resumen de Plan It',
    planItPlanItSummary: 'Plan It / Resumen de Plan It',
    forecastedQuantityPL: 'Cantidad prevista (PL)',
    forecastedQuantityEA: 'Cantidad prevista (EA)',
    planItDashboardDesc:
        'Cree y envíe su pronóstico de pedidos. Actualice un pronóstico enviado previamente a través de la carga masiva.',
    submitPlanIt: 'Enviar un plan',
    createPlanItLink: 'Crear / Actualizar un plan',
    createAPlanIt: 'Crear un Plan It',
    createAMakeIt: 'Create a Make It',
    planItSummaryDashboardDesc:
        'Ver previsiones de pedidos enviadas anteriormente. Edite las previsiones de pedidos futuros.',
    viewPlanItSummary: 'Ver resumen del plan',
    selectProductsPlanIt: 'Agregue productos a su previsión:',
    selecteForecastWeeks: 'Seleccionar la semana(s) de estimación para continuar.',
    yourForecast: 'Su previsión',
    planItGuidedPrompt: '¿Desea enviar un "Plan It" sin cargar un archivo?',
    createForecast: 'Crear previsión',
    addInstructionsPlanIt: `Cree su previsión haciendo clic en el botón “ADD” (añadir) para cada producto que desee incluir`,
    submitForecast: 'Presentar pronóstico',
    thankYouForecast: 'Thank You, Your Forecast(s) have been submitted',
    productIncluded:
        'Producto incluido en estimaciones posteriores. Coloque la cantidad en 0 para eliminarlo.',
    planItGuided: '"Plan It" Guiado',

    // -- SCRAP IT -- //
    scrapIt: 'Scrap It',
    availablePallets: 'Palés disponibles',
    inputPallets: 'Introducir palés',
    createRequest: 'Crear solicitud',
    replicateForecast: 'Replicate Forecast',
    selectQuantity: 'Seleccione las cantidades de los saldos para descartar (Scrap)',
    yourRequest: 'Su solicitud',
    youCanReview: 'Puede revisar los productos seleccionados en la siguiente pantalla.',
    quantityToScrap: 'Quantity to Scrap',
    scrapItOrderNumber: 'Scrap It Request Order Number',
    totalAvailableBalance: 'Total Available Balance',
    cannotExceedAvailableBalance: 'No se puede exceder el saldo disponible',
    reviewScrapItRequest: 'Revisar la solicitud "Scrap It"',
    scrapItRequest: 'Scrap It Request',

    // -- PRODUCT STATUS CHANGES -- //
    PSC00001: 'Cambio de Datos Pendiente',
    PSC00002: 'Pending Activation',
    PSC00003: 'Pendiente de Desactivación',
    PSC00004: 'Pending Plate Change',

    // -- EDIT PRODUCT MODAL -- //
    editProductUpdateFields: 'Edit fields below to update product information.',
    previousForecasts: 'Previsiones anteriores',
    previousForecast: 'Previsiones anterior',
    copyToMakeIt: `Copiar a "Make It"`,

    // -- INACTIVE PRODUCTS MODAL -- //
    productAlertsFound: '({0}) Product Alerts Found in Uploaded File',
    inactiveProductsIdentified: 'Productos Inactivos Identificados',
    inactiveProductsSubheader:
        'We have identified inactive products on one or more of the forecasts you are trying to convert. These products will not be included on your Make it.  Please reach out to your Account Representative if you have questions.',
    proceedWithConversionText: 'Click continue to proceed with conversion.',
    identifiedTheFollowingInactiveProducts:
        'Hemos identificado los siguientes productos inactivos incluidos en su archivo:',
    csvLineNumber: 'Número de Línea',
    inactiveProductsMustBeActivated:
        'Los productos inactivos deben ser activados antes de la creación de Make It. Comuníquese con su contacto de Ball para discutir sobre cómo activar productos.',
    unrecognizedIdentified: 'Unrecognized Products Identified',
    unrecognizedCorrectIt:
        'One or more of the product IDs included in this upload is not recognized. If a Product ID is incorrect, please go back to correct it and re-upload the plan it.',
    unrecognizedContinue:
        'If you wish to continue with unrecognized products included, fill out the table below including the desired products you wish to add to your forecast. The information will be sent to our team to review and reconcile. You will receive a notification regarding the status of your product. Please note that unrecognized products will not be available for Make It or Ship It orders until a resolution is received.',

    // -- ADD WEEK MODAL -- //
    selectWeekToAdd: 'Seleccionar como semana para agregar a su "Make It"',
    selectWeekToAddPlanIt: 'Seleccionar como semana para agregar a su "Plan It"',

    // -- ADD SHIP TO -- //
    customerName: 'Customer Name',
    customerId: 'Customer ID',
    customerAddress: 'Customer Address',
    addressLine1: 'Address Line 1',
    addressLine2: 'Address Line 2 (Optional)',
    addressLine3: 'Address Line 3 (Optional)',
    vat: 'VAT',
    cfid: 'CFID',
    userAdminContact: 'User Admin Contact',
    userAdminPhoneNumber: 'User Admin Phone Number',
    userAdminEmail: 'User Admin Email',
    freightInformation: 'Freight Information',
    orderingInformation: 'Ordering Information',
    basecoat: 'Basecoat',
    tactile: 'Tactile',
    satin: 'Satin',
    laserTab: 'Laser Tab',
    punch: 'Punch',
    coloredTab: 'Colored Tab',
    coloredShell: 'Colored Shell',
    selectProductsShort: 'Select Products',
    anticipatedItems: 'Anticipated Items',
    addItem: 'Add Item',
    both: 'Both',
    specialtyPrinting: 'Specialty Printing (Optional)',
    specialtyTab: 'Specialty Tab (Optional)',
    ballItemNumberOrGraphicNumber: 'Ball Item Number/Graphic Number',
    ballItemNumberOrGraphicNumberOptional: 'Ball Item Number/Graphic Number (Optional)',
    labelName: 'Label Name',
    labelNameOptional: 'Label Name (Optional)',
    viewPalletSpecs: 'See pallet specifications on ',
    specialEquipmentTooltip: 'Roller bed truck, Pallet jack, etc.',
    specialEquipmentOptional: 'Special Equipment (Optional)',
    layersPerPallet: 'Layers per Pallet',
    canFreightManagement: 'Can Freight Management',
    endFreight: 'End Freight',
    canSpecifications: 'Can Specifications',
    blanket: 'Blanket',
    cashInAdvance: 'Cash-in-Advance (CIA)',
    estimatedAnnualVolume: 'Estimated Annual Volume',
    ballManaged: 'Ball Managed',
    customerNumber: 'Customer Number',
    specialtyPrintingReview: 'Specialty Printing',
    specialtyTabs: 'Specialty Tabs',
    specialtyEquipment: 'Specialty Equipment',
    requestNewShipTo: 'Request New Ship To',
    reviewAndSubmit: 'Review And Submit',
    shipToRequestSubmitted:
        'Your request to add a new Ship To location has been submitted. You will receive a notification once the request is reviewed.',
    shipToRequestSave: 'Your form is also available to view. Please save this for your records.',

    // -- FEEDBACK SURVEY -- //
    feedbackHeader: '¡Nos encantaría recibir sus comentarios!',
    satisfaction: 'En general, ¿qué tan satisfecho está con The Source?',
    enterScore: 'Por favor ingresa una puntuación',
    visitPurpose: '¿Cuál fue el propósito principal de su visita hoy?',
    selectOption: 'Seleccione una opción',
    'Learn about working with Ball': 'Saber cómo es el trabajo con Ball',
    'Get Design Inspiration': 'Obtener inspiración del diseño',
    'Investigate Lab and Liquid': 'Investigar Laboratorio y Líquido',
    'Explore Sustainability & Regulatory': 'Explorar Sostenibilidad y Regulaciones',
    'Learn about Handling & Filling': 'Aprender sobre Manejo y Llenado',
    'Access Can Specifications': 'Acceder a las Especificaciones de las latas',
    'Obtain Pricing Information': 'Obtener información de precios',
    Other: 'Otros',
    pleaseSpecify: 'Especifique:',
    suggestionLabel: '¿Podemos hacer algo más para mejorar su visita a The Source?',
    contactPreferenceLabel:
        'Si necesitamos aclaraciones sobre alguna de sus respuestas, ¿podemos ponernos en contacto con usted?',
    'Yes, I prefer a phone call': 'Sí, prefiero una llamada telefónica.',
    'Yes, I prefer email': 'Sí, prefiero el correo electrónico',
    'No, please do not contact me': 'No, por favor no me contacte.',
    selectContactPreference: 'Por favor seleccione una preferencia de contacto',
    nameLabel: 'Nombre',
    phoneLabel: 'Número de teléfono',
    emailLabel: 'Correo electrónico',
    emailError: 'Por favor introduzca una dirección de correo electrónico válida',
    closeButton: 'Cerrar',
    submitButton: 'Enviar',
    feedbackThankYou: 'Gracias por enviarnos sus comentarios',
    privacyPolicyMessage: 'Sus respuestas se utilizarán de acuerdo con nuestra',
    feedbackPrivacyPolicy: 'política de privacidad'
};
