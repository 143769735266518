import moment from 'moment';
import {
    Activity,
    filterShipToIdsByPermission,
    SecurityLevel
} from '../../utility/auth/useSecurity';
import { ErrorStatus, findDistinctObjects } from '../../utility/helpers/axios-helpers';
import {
    constructCanDescription,
    constructEndDescription,
    isProductEndType
} from '../../utility/helpers/order-helpers';
import { isCustomerProductIdDuplicate } from '../../utility/helpers/shipment-helpers';
import OrdersService, {
    BulkProductRequest,
    OrderType,
    Order_Status,
    ProductType,
    QuantityType
} from '../../utility/services/orders-service';
import { AuthState } from '../reducers/auth';
import {
    BulkUploadTemplateRequest,
    BulkUploadOrder,
    BulkUploadShipment,
    DeliveryLineError,
    TemplateMapping,
    LineErrorCode,
    BulkUploadDeliveryState
} from '../reducers/bulk-upload';
import { MakeItBulkOrder } from '../reducers/makeit-bulk-upload';
import {
    ProductToShip,
    ProductWithPallets,
    ShipItProductsRequest
} from '../reducers/shipping-dashboard';
import {
    BULK_UPLOAD_DELIVERY_ERROR,
    BULK_UPLOAD_DELIVERY_FILE,
    BULK_UPLOAD_DELIVERY_LOADED,
    BULK_UPLOAD_DELIVERY_SUBMIT_ORDERS,
    BULK_UPLOAD_DELIVERY_SUBMIT_REVIEW,
    BULK_UPLOAD_LOADING,
    BULK_UPLOAD_RESET_STATE,
    BULK_UPLOAD_PRODUCTS,
    BULK_UPLOAD_DELETE_SHIPMENT,
    BULK_UPLOAD_UPDATE_SHIPMENT,
    BULK_UPLOAD_PALLETS_LOADED,
    BULK_UPLOAD_PALLETS_ERROR,
    BULK_UPLOAD_PALLETS_LOADING,
    BULK_UPLOAD_REMOVE_ORDER
} from './action-types';

export const loadBulkUploadDelivery = (userName: string) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: BULK_UPLOAD_LOADING });
        OrdersService.getBulkUpload(state, userName, OrderType.Delivery)
            .then((response) => {
                dispatch({
                    type: BULK_UPLOAD_DELIVERY_LOADED,
                    mappings: response.data.mappings,
                    quantityType: response.data.quantityType
                });
            })
            .catch((error) => {
                if (error.response.status === ErrorStatus.NotFound) {
                    dispatch({ type: BULK_UPLOAD_DELIVERY_LOADED, mappings: [] });
                } else {
                    dispatch({
                        type: BULK_UPLOAD_DELIVERY_ERROR,
                        error
                    });
                }
            });
    };
};

export const loadBulkProductsWithPallets = (
    providedShipTo?: string,
    providedDeliveryDate?: string,
    orders?: BulkUploadOrder[]
) => {
    return (dispatch, getState) => {
        dispatch({
            type: BULK_UPLOAD_PALLETS_LOADING,
            loading: true
        });
        const accessToken = getState().auth.accessToken;
        const shipToId = providedShipTo
            ? providedShipTo
            : getState().customerContext.selectedAccountId;
        const selectedDate = providedDeliveryDate
            ? providedDeliveryDate
            : getState().shippingDashboard.selectedDeliveryDate;
        const queryDate = moment.parseZone(selectedDate).add(7, 'days').format();
        const viewState = getState().shippingDashboard.view;
        const rangeState = getState().shippingDashboard.range;

        const requestObj: ShipItProductsRequest = { ShipToIds: [shipToId] };
        if (!!viewState && viewState[0].length !== 0) {
            requestObj.Types = viewState;
        }
        if (!!rangeState && rangeState[0].length !== 0) {
            requestObj.UsedWithinNMonths = rangeState;
        }

        if (shipToId) {
            OrdersService.getShipItProducts(accessToken, requestObj, queryDate)
                .then((response) => {
                    let products = response.data.products.filter(
                        (product) => product.availablePallets && product.availablePallets > 0
                    );
                    const productsWithPallets = products.map((product) => {
                        const canBottleDesc = constructCanDescription(product);
                        const endDesc = constructEndDescription(product);
                        const isEndType = isProductEndType(product.type);
                        product.description = isEndType ? endDesc : canBottleDesc;
                        product.availableItemsPerPallet = product?.quantityPerPallet!;
                        product.orderedPallets = 0;
                        product.graphicIdAndVersion =
                            product.graphicId && product.graphicVersion
                                ? product.graphicId + '-' + product.graphicVersion
                                : '';

                        if (product.destinations) {
                            product.destinations?.forEach((destination) => {
                                if (destination.shipToId.toString() === shipToId) {
                                    product.customerProductId = destination.customerProductId;
                                    product.customerProductName = destination.customerProductName;
                                }
                            });
                        }
                        product.displayId = product.customerProductId
                            ? product.customerProductId
                            : product.productSku;
                        product.displayName = product.customerProductName
                            ? product.customerProductName
                            : product.name;
                        return null;
                    });

                    orders?.forEach((order) => {
                        if (order.shipments && order.shipToId?.toString() === shipToId) {
                            order.shipments.forEach((shipment) => {
                                if (!shipment.isDeleted) {
                                    shipment.loads?.forEach((load) => {
                                        let productWithPallets = products.find(
                                            (item) => item.productSku === load.productSku
                                        );
                                        if (
                                            productWithPallets &&
                                            productWithPallets.availablePallets
                                        ) {
                                            productWithPallets.availablePallets -=
                                                load.palletQuantity;
                                        }
                                    });
                                }
                            });
                        }
                    });

                    if (productsWithPallets.length) {
                        dispatch({
                            type: BULK_UPLOAD_PALLETS_LOADED,
                            products
                        });
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: BULK_UPLOAD_PALLETS_ERROR,
                        error
                    });
                });
        }
    };
};

export const updatedProductsWithPallets = (products: ProductWithPallets[]) => {
    return (dispatch, getState) => {
        dispatch({
            type: BULK_UPLOAD_PALLETS_LOADED,
            products
        });
    };
};

export const createBulkUploadDeliveryTemplate = (
    userName: string,
    mappings: TemplateMapping[],
    quantityType: QuantityType
) => {
    return (dispatch, getState) => {
        const { auth } = getState();
        dispatch({ type: BULK_UPLOAD_LOADING });
        const requestObj: BulkUploadTemplateRequest = {
            userName: userName,
            orderType: OrderType.Delivery,
            mappings: mappings,
            quantityType: quantityType
        };
        OrdersService.createBulkUploadDeliveryTemplate(auth.accessToken, requestObj)
            .then((response) => {
                dispatch({
                    type: BULK_UPLOAD_DELIVERY_LOADED,
                    mappings: response.data.mappings,
                    quantityType: response.data.quantityType
                });
            })
            .catch((error) => {
                dispatch({
                    type: BULK_UPLOAD_DELIVERY_ERROR,
                    error
                });
            });
    };
};

export const uploadDeliveryFile = (file?: File) => {
    return (dispatch) => {
        if (file) {
            dispatch({ type: BULK_UPLOAD_DELIVERY_FILE, file: file });
        } else {
            dispatch({ type: BULK_UPLOAD_DELIVERY_FILE, file: undefined });
        }
    };
};

export const reviewDeliveryBulkUpload = (userName: string, file: File | File[]) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: BULK_UPLOAD_LOADING });

        OrdersService.reviewDeliveryBulkUpload(state, userName, file)
            .then((response) => {
                let orders = [] as BulkUploadOrder[];
                let errors = [] as DeliveryLineError[];
                let auth: AuthState = getState().auth;

                if (response.data.lineErrors) {
                    errors = response.data.lineErrors.filter(
                        (error) => error.errorCode !== LineErrorCode.E200005
                    );
                }
                if (response.data.orders) {
                    orders = response.data.orders;
                    const shipToIds = getOrderShipToIds(orders);

                    if (shipToIds && shipToIds.length) {
                        dispatch(
                            getProductInformationForOrders(
                                auth,
                                shipToIds,
                                orders,
                                state.bulkUploadDelivery.quantityType,
                                errors
                            )
                        );
                    } else {
                        dispatch({
                            type: BULK_UPLOAD_DELIVERY_SUBMIT_REVIEW,
                            orders: orders,
                            lineErrors: errors
                        });
                    }
                } else {
                    dispatch({
                        type: BULK_UPLOAD_DELIVERY_SUBMIT_REVIEW,
                        orders: orders,
                        lineErrors: errors
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: BULK_UPLOAD_DELIVERY_ERROR,
                    error
                });
            });
    };
};

export const updateBulkUploadDeliveryTemplate = (
    userName: string,
    mappings: TemplateMapping[],
    quantityType: QuantityType
) => {
    return (dispatch, getState) => {
        const { auth } = getState();
        dispatch({ type: BULK_UPLOAD_LOADING });
        const requestObj: BulkUploadTemplateRequest = {
            userName: userName,
            orderType: OrderType.Delivery,
            mappings: mappings,
            quantityType: quantityType
        };
        OrdersService.updateBulkUploadDeliveryTemplate(auth.accessToken, requestObj)
            .then((response) => {
                dispatch({
                    type: BULK_UPLOAD_DELIVERY_LOADED,
                    mappings: response.data.mappings,
                    quantityType: response.data.quantityType
                });
            })
            .catch((error) => {
                dispatch({
                    type: BULK_UPLOAD_DELIVERY_ERROR,
                    error
                });
            });
    };
};

export const submitDeliveryBulkUpload = (orders: BulkUploadOrder[]) => {
    return (dispatch, getState) => {
        const state = getState();
        const products = state.bulkUploadDelivery.products;
        dispatch({ type: BULK_UPLOAD_LOADING });

        OrdersService.submitDeliveryBulkUpload(state.auth.accessToken, orders)
            .then((response) => {
                let submittedOrders = [] as BulkUploadOrder[];
                if (response.data.orders) {
                    submittedOrders = response.data.orders;
                    bulkUploadResponseHelper(
                        submittedOrders,
                        products,
                        state.bulkUploadDelivery.quantityType,
                        true
                    );
                    addUserProductIds(submittedOrders, orders);
                }
                dispatch({
                    type: BULK_UPLOAD_DELIVERY_SUBMIT_ORDERS,
                    orders: submittedOrders
                });
            })
            .catch((error) => {
                dispatch({
                    type: BULK_UPLOAD_DELIVERY_ERROR,
                    error
                });
            });
    };
};

export function getOrderShipToIds(orders: BulkUploadOrder[] | MakeItBulkOrder[]) {
    let shipToIdSet = new Set<number>();

    orders.forEach((order) => {
        if (order.shipToId) {
            shipToIdSet.add(order.shipToId);
        }
    });
    return Array.from(shipToIdSet);
}

export const getProductInformationForOrders = (
    auth: AuthState,
    shipToIds: number[],
    orders: BulkUploadOrder[],
    quantityType: QuantityType,
    errors: DeliveryLineError[]
) => {
    return (dispatch) => {
        let products = [] as ProductToShip[];

        const requestObj: BulkProductRequest = {
            shipToIds: filterShipToIdsByPermission(
                auth,
                shipToIds,
                Activity.NewOpenDeliveryOrders,
                SecurityLevel.View
            )
        };
        OrdersService.getBulkProducts(auth.accessToken, requestObj)
            .then((response) => {
                products = findDistinctObjects(response.data.products, 'productSku');
                dispatch({
                    type: BULK_UPLOAD_PRODUCTS,
                    products: products
                });
                bulkUploadResponseHelper(orders, products, quantityType, false);
                // for editing bulk shipments
                let loadId = 0;
                for (const order of orders) {
                    if (order.shipments) {
                        for (const shipment of order.shipments) {
                            if (shipment.loads) {
                                for (const load of shipment.loads) {
                                    load.editLoadId = ++loadId;
                                }
                            }
                        }
                    }
                }
            })
            .catch((error) => {
                dispatch({
                    type: BULK_UPLOAD_DELIVERY_ERROR,
                    error
                });
            })
            .finally(() => {
                dispatch({
                    type: BULK_UPLOAD_DELIVERY_SUBMIT_REVIEW,
                    orders: orders,
                    lineErrors: errors
                });
            });
    };
};

const bulkUploadResponseHelper = (
    orders: BulkUploadOrder[],
    products: any,
    quantityType: QuantityType,
    submitted: boolean
) => {
    let bulkShipmentId = 0;
    orders.forEach((order) => {
        order.status = submitted ? order.status : Order_Status.Draft;
        order.totalOrderQuantityEaches = 0;
        order.canQuantityEaches = 0;
        order.endQuantityEaches = 0;
        order.canQuantityPallets = 0;
        order.endQuantityPallets = 0;
        order.canQuantitySKUs = 0;
        order.endQuantitySKUs = 0;
        order.shipments?.map((shipment: BulkUploadShipment) => {
            let isEndType = false;
            shipment.shipToId = order.shipToId;
            shipment.isDeleted = false;
            shipment.bulkShipmentId = bulkShipmentId++;
            shipment.canQuantityPallets = 0;
            shipment.endQuantityPallets = 0;
            shipment.canQuantitySKUs = 0;
            shipment.endQuantitySKUs = 0;
            shipment.shipmentQuantity = 0;
            shipment.shipmentQuantityEaches = 0;
            shipment.loads?.map((load) => {
                if (!isCustomerProductIdDuplicate(load)) {
                    const currentProduct = products.find(
                        (product) => product.productSku === load.productSku
                    );
                    if (currentProduct) {
                        isEndType = isProductEndType(currentProduct.type);
                        load.name = currentProduct.name;
                        if (isProductEndType(currentProduct.type)) {
                            load.description = constructEndDescription(currentProduct);
                        } else {
                            load.description = constructCanDescription(currentProduct);
                        }
                        load.type = currentProduct.type;
                        load.quantityPerPallet = currentProduct.quantityPerPallet;
                        load.graphicId = currentProduct.graphicId;

                        if (quantityType === QuantityType.Eaches) {
                            load.palletsRounded =
                                load.eachesQuantity !==
                                load.palletQuantity! * currentProduct.quantityPerPallet;
                        }
                    }

                    if (load.palletQuantity) {
                        shipment.shipmentQuantity! += load.palletQuantity;
                        shipment.shipmentQuantityEaches! +=
                            load.palletQuantity * currentProduct.quantityPerPallet;
                        if (isEndType) {
                            shipment.endQuantityPallets! += load.palletQuantity;
                            shipment.endQuantitySKUs! += 1;
                            shipment.shipmentType = ProductType.Ends;
                            order.endQuantityEaches! +=
                                load.palletQuantity * currentProduct.quantityPerPallet;
                            order.endQuantityPallets! += load.palletQuantity;
                            order.endQuantitySKUs! += 1;
                        } else if (!isEndType) {
                            shipment.canQuantityPallets! += load.palletQuantity;
                            shipment.canQuantitySKUs! += 1;
                            shipment.shipmentType = ProductType.Cans;
                            order.canQuantityEaches! +=
                                load.palletQuantity * currentProduct.quantityPerPallet;
                            order.canQuantityPallets! += load.palletQuantity;
                            order.canQuantitySKUs! += 1;
                        }
                    }
                    load.displayId = load.customerProductId
                        ? load.customerProductId
                        : load.productSku;
                    load.displayName = load.customerProductName
                        ? load.customerProductName
                        : load.name;
                }
                shipment.deliveryTime = moment(shipment.deliveryDateTime).format('hh:mm A');
                shipment.originalDateTime = moment(shipment.deliveryDateTime).format(
                    'MM/DD/YYYY hh:mm A'
                );
                return null;
            });
            if (shipment.loads) {
                //extract type from first load in shipment, and set overall shipment type for future shipment editing/building
                let shipmentType = shipment.loads[0].type;
                shipment.shipmentType = shipmentType;
            }
            order.totalOrderQuantityEaches! += shipment.shipmentQuantityEaches;
            return null;
        });
    });
};

const addUserProductIds = (
    submittedOrders: BulkUploadOrder[],
    initialOrders: BulkUploadOrder[]
) => {
    submittedOrders.forEach((order, oindex) =>
        order.shipments?.forEach((shipment, sindex) => {
            shipment.loads?.forEach((load) => {
                const initialLoad = initialOrders![oindex].shipments![sindex].loads?.find(
                    (iload) => iload.productSku === load.productSku
                );
                load.userSuppliedProductId =
                    initialLoad?.userSuppliedProductId ??
                    initialLoad?.customerProductId ??
                    load.displayId;
            });
        })
    );
};

export const resetBulkUploadState = () => {
    return (dispatch) => {
        dispatch({
            type: BULK_UPLOAD_RESET_STATE
        });
    };
};

export const removeShipItBulkOrder = () => {
    return (dispatch) => {
        dispatch({
            type: BULK_UPLOAD_REMOVE_ORDER
        });
    };
};

export const handleDeleteShipment = (orderIndex: number, shipmentIndex: number) => {
    return (dispatch: any, getState: any) => {
        const { orders } = getState().bulkUploadDelivery;
        let updatedOrders = [...orders];

        updatedOrders[orderIndex].shipments[shipmentIndex].isDeleted = true;

        dispatch({
            type: BULK_UPLOAD_DELETE_SHIPMENT,
            orders: updatedOrders
        });
    };
};

export const undoDeleteShipment = (orderIndex: number, shipmentIndex: number) => {
    //This will change when edit is implemented
    return (dispatch: any, getState: any) => {
        const { orders } = getState().bulkUploadDelivery;
        let updatedOrders = [...orders];

        updatedOrders[orderIndex].shipments[shipmentIndex].isDeleted = false;

        dispatch({
            type: BULK_UPLOAD_DELETE_SHIPMENT,
            orders: updatedOrders
        });
    };
};

export const updateRevisedShipment = (shipmentToUpdate: BulkUploadShipment, shipmentId: string) => {
    return (dispatch: any, getState: any) => {
        const { orders } = getState().bulkUploadDelivery;
        let updatedOrders = JSON.parse(JSON.stringify(orders));

        updatedOrders.forEach((order) => {
            if (order.shipments) {
                let shipmentIndex = order.shipments.findIndex(
                    (shipment) => shipment.bulkShipmentId.toString() === shipmentId
                );
                if (shipmentIndex >= 0) {
                    //Reformatting shipment for bulk summary page
                    shipmentToUpdate.canQuantityPallets = 0;
                    shipmentToUpdate.endQuantityPallets = 0;
                    shipmentToUpdate.canQuantitySKUs = 0;
                    shipmentToUpdate.endQuantitySKUs = 0;

                    //Reformat status
                    order.shipments[shipmentIndex].loads.forEach((load, index) => {
                        if (shipmentToUpdate.loads && shipmentToUpdate.loads[index]) {
                            shipmentToUpdate.loads[index].status = load.status;
                        }
                    });

                    //Reformat load count and reference
                    shipmentToUpdate.loads?.forEach((load, index) => {
                        if (shipmentToUpdate.loads) {
                            if (shipmentToUpdate.shipmentType === 'CAN') {
                                shipmentToUpdate.canQuantityPallets! +=
                                    shipmentToUpdate.loads[index].palletQuantity;
                                shipmentToUpdate.canQuantitySKUs! += 1;
                            } else if (shipmentToUpdate.shipmentType === 'END') {
                                shipmentToUpdate.endQuantityPallets! +=
                                    shipmentToUpdate.loads[index].palletQuantity;
                                shipmentToUpdate.endQuantitySKUs! += 1;
                            }
                        }
                    });

                    order.shipments.splice(shipmentIndex as number, 1, shipmentToUpdate);
                }
            }
        });

        dispatch({
            type: BULK_UPLOAD_UPDATE_SHIPMENT,
            orders: updatedOrders
        });
    };
};

export const updateProductSku = (
    customerProductId: string,
    product: ProductWithPallets,
    shipmentId: string,
    isBulk: boolean,
    sequence?: number
) => {
    return (dispatch, getState) => {
        const { orders } = getState().bulkUploadDelivery as BulkUploadDeliveryState;
        let updatedOrders = JSON.parse(JSON.stringify(orders));
        updatedOrders?.forEach((order) => {
            const shipment: BulkUploadShipment = order.shipments?.find((shipment) =>
                isBulk
                    ? shipment.bulkShipmentId?.toString() === shipmentId
                    : shipment.shipmentId?.toString() === shipmentId
            );
            if (shipment) {
                shipment.shipmentType = product.type;
                const index = shipment.loads!.findIndex(
                    (load) =>
                        load.customerProductId === customerProductId &&
                        load.isCustomerProductIdDistinct === false &&
                        (sequence ? sequence === load.sequence : true)
                );
                if (index >= 0) {
                    const load = shipment.loads![index];
                    const availablePallets = getAvailableBalanceAmount(
                        product.availablePallets!,
                        load.palletQuantity
                    );
                    const quantity = getPalletQuantityAmount(
                        product.availablePallets!,
                        load.palletQuantity
                    );

                    shipment.loads![index] = {
                        ...shipment.loads![index],
                        ...product,
                        palletQuantity: quantity,
                        isCustomerProductIdDistinct: true,
                        candidateProductSkus: undefined,
                        originalCsvLineNumber: load.originalCsvLineNumber,
                        availablePallets: availablePallets,
                        status: load.status
                    } as ProductToShip;
                }
            }
        });
        dispatch({
            type: BULK_UPLOAD_UPDATE_SHIPMENT,
            orders: updatedOrders
        });
    };
};

function getAvailableBalanceAmount(availablePallets: number, palletQuantity: number) {
    if (palletQuantity > availablePallets) {
        return 0;
    } else {
        return availablePallets - palletQuantity;
    }
}

function getPalletQuantityAmount(availablePallets: number, palletQuantity: number) {
    if (palletQuantity > availablePallets) {
        return availablePallets;
    } else {
        return palletQuantity;
    }
}
