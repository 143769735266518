import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { makeStyles, Box, Tabs, Tab, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import {
    addFormItemButtonBlue,
    ballDrkBlue,
    black,
    blackWeight,
    inactiveTabBlue,
    lato,
    ltBlueGrey,
    medium,
    small,
    white,
    xl
} from '../../../../themes/globalConstants';
import { loadProductionOrdersATM } from '../../../../store/actions/make-it-atm-summary';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { MakeItATMSummaryState } from '../../../../store/reducers/make-it-atm-summary';
import { ProductionOrder } from '../../../../store/reducers/makeit-dashboard';
import TranslationService from '../../../../utility/services/translation-service';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { BulkUploadMakeItState } from '../../../../store/reducers/makeit-bulk-upload';
import { getAllocationsWithShipTos } from '../../../../store/root-actions';
import MakeItATMTab from './MakeItATMTab';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { getLockedPeriodDays } from '../../../../utility/helpers/user-helpers';
import { sortMakeItATMSummaryOrders } from '../utils/makeit-atm-summary-utils';
import { BulkUploadMakeItATMState } from '../../../../store/reducers/makeit-bulk-atm';
import { useQuery } from '../../../../utility/helpers/query-helpers';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

enum TabStatus {
    'open' = 0,
    'closed' = 1
}

interface Props {
    dashboardLoading: Dispatch<SetStateAction<boolean>>;
    selectedShipToId?: string;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: '4.5em',
        position: 'relative',
        '& h2': {
            fontSize: xl,
            fontFamily: lato,
            fontWeight: blackWeight,
            color: theme.palette.secondary.main
        }
    },
    tabPanel: {
        width: '100%'
    },
    counter: {
        width: '2em',
        height: '2em',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '0.5em',
        fontSize: small,
        fontWeight: blackWeight,
        backgroundColor: ltBlueGrey,
        color: ballDrkBlue
    },
    selectedCounter: {
        backgroundColor: addFormItemButtonBlue,
        color: white
    },
    tabs: {},
    tabLabel: {
        backgroundColor: inactiveTabBlue,
        height: '56px',
        borderTopLeftRadius: '0.625em',
        borderTopRightRadius: '0.625em',
        '&:not(:first-of-type)': {
            marginLeft: '8px'
        },
        padding: '0 3em',
        color: ballDrkBlue,
        textTransform: 'none',
        maxWidth: 'none'
    },
    tabLabelText: {
        fontSize: medium,
        fontWeight: blackWeight
    },
    selectedTab: {
        backgroundColor: white,
        color: black,
        boxShadow: '0px 0px 6px rgba(185, 211, 220, 0.8), inset 0px -3px 0px #009BBB'
    }
}));

const TabPanel = (props: TabPanelProps) => {
    const classes = useStyles();
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`make-it-atm-tabpanel-${index}`}
            aria-labelledby={`make-it-atm-tab-${index}`}
            {...other}
            className={classes.tabPanel}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
};

// This assigns props for accessibility purposes
const a11yProps = (index: any) => {
    return {
        id: `make-it-atm-tab-${index}`,
        'aria-controls': `graphics-tabpanel-${index}`
    };
};

const MakeItATMTabs = ({ dashboardLoading, selectedShipToId }: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const query = useQuery();
    const status = query.get('status');
    const initialValue = getValueFromStatus(status);
    const [value, setValue] = useState<Number>(initialValue);

    const { orders, atmOrdersLoaded, error } = useTypedSelector<MakeItATMSummaryState>(
        (state) => state.makeItATMSummary
    );
    const { products } = useTypedSelector<BulkUploadMakeItState>(
        (state) => state.bulkUploadMakeItState
    );
    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { allocationsLoadingState } = useTypedSelector<BulkUploadMakeItATMState>(
        (state) => state.bulkUploadMakeItATMState
    );
    const [openOrders, setOpenOrders] = useState<ProductionOrder[]>([]);
    const [closedOrders, setClosedOrders] = useState<ProductionOrder[]>([]);
    const [lockedPeriodDays, setLockedPeriodDays] = useState<number>(14);

    useEffect(() => {
        if (shipToAccounts?.length && selectedShipToId) {
            const lockedPeriods: number = getLockedPeriodDays(shipToAccounts, selectedShipToId, 14);
            setLockedPeriodDays(lockedPeriods);
        }
    }, [selectedShipToId, shipToAccounts]);

    useEffect(() => {
        if (selectedShipToId && products?.length) {
            dispatch(loadProductionOrdersATM([parseInt(selectedShipToId)]));
        }
    }, [selectedShipToId, products, dispatch]);

    useEffect(() => {
        if (orders && atmOrdersLoaded && products) {
            const sortedOrders = sortMakeItATMSummaryOrders(orders, lockedPeriodDays, products);

            setOpenOrders(sortedOrders.openOrders);
            setClosedOrders(sortedOrders.closedOrders);
        } else if (atmOrdersLoaded) {
            setOpenOrders([]);
            setClosedOrders([]);
        }
    }, [orders, lockedPeriodDays, atmOrdersLoaded, products]);

    useEffect(() => {
        if (atmOrdersLoaded || error !== undefined) {
            dashboardLoading(false);
        }
    }, [atmOrdersLoaded, dashboardLoading, error]);

    useEffect(() => {
        if (openOrders?.length && selectedShipToId && allocationsLoadingState !== 'loading') {
            let firstWeek = moment(openOrders[0].atmWeekStart);
            let lastWeek = moment(openOrders[0].atmWeekStart);

            for (let order of openOrders) {
                const currentWeek = moment(order.atmWeekStart);
                if (firstWeek.isAfter(currentWeek)) {
                    firstWeek = currentWeek;
                } else if (lastWeek.isBefore(currentWeek)) {
                    lastWeek = currentWeek;
                }
            }
            dispatch(
                getAllocationsWithShipTos(
                    firstWeek.format('M/D/YYYY'),
                    lastWeek.format('M/D/YYYY'),
                    [parseInt(selectedShipToId)]
                )
            );
        }
        // allocationsLoadingState has two variable like loading and success
        // first we call api when allocationsLoadingState is success/idle - it will run api
        // which will change allocationsLoadingState to loading - useeffect will run but doesn't excute it
        // then after getting api result allocationsLoadingState change to success and useeffect will run - which will create loop in it.
    }, [dispatch, openOrders, selectedShipToId]);

    const TabLabel = (label: string, count: number, index: number) => {
        return (
            <Grid container justify="center" alignItems="center">
                <Typography className={classes.tabLabelText}>{label}</Typography>
                <Typography
                    component="span"
                    className={`${classes.counter} ${value === index && classes.selectedCounter}`}
                >
                    {count}
                </Typography>
            </Grid>
        );
    };

    function getValueFromStatus(status) {
        return (TabStatus as any)[status?.toLowerCase() || ''] || 0;
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        const val = TabStatus[newValue];
        // Update query param so back button will return to same tab selection
        window.history.replaceState('', '', `?status=${val}`);
        setValue(newValue);
    };

    useEffect(() => {
        const value = getValueFromStatus(status);
        setValue(value);
    }, [status]);

    return (
        <div className={classes.root} data-testid="make-it-atm-tabs">
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="make it summary tabs"
                className={classes.tabs}
                scrollButtons="auto"
                variant="scrollable"
                indicatorColor="primary"
                textColor="primary"
            >
                <Tab
                    label={TabLabel(
                        TranslationService.getTranslatedText(cultureCode, 'openProductionOrders'),
                        openOrders ? openOrders.length : 0,
                        0
                    )}
                    {...a11yProps(0)}
                    className={`${classes.tabLabel} ${value === 0 && classes.selectedTab}`}
                    value={0}
                    data-testid="open-atms-tab"
                />
                <Tab
                    label={TabLabel(
                        TranslationService.getTranslatedText(cultureCode, 'closedProductionOrders'),
                        closedOrders ? closedOrders.length : 0,
                        1
                    )}
                    {...a11yProps(1)}
                    className={`${classes.tabLabel} ${value === 1 && classes.selectedTab}`}
                    value={1}
                    data-testid="closed-atms-tab"
                />
            </Tabs>
            <TabPanel value={value} index={0}>
                {openOrders && <MakeItATMTab orders={openOrders} tabType={'open'} />}
            </TabPanel>
            <TabPanel value={value} index={1}>
                {closedOrders && <MakeItATMTab orders={closedOrders} tabType={'closed'} />}
            </TabPanel>
        </div>
    );
};

export default MakeItATMTabs;
