import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import ReactRating from 'react-rating';
import clsx from 'clsx';
import { silver, yellow, black } from '../../../themes/globalConstants';
import Star from './Star';

interface Props {
    value: number;
    maxValue: number;
    name: string;
    dataTestId?: string;
    onClick: (value: number) => void;
}

const useStyles = makeStyles({
    root: {},
    star: {
        marginRight: '5px'
    },
    selectedStar: {
        strokeWidth: '3px'
    }
});

const StarRating = (props: Props) => {
    const classes = useStyles();

    const EmptyStarIcon = <Star fill={silver} stroke="none" className={classes.star}></Star>;

    const StarsArray = useMemo(() => {
        const FullStarIcon = <Star fill={yellow} stroke="none" className={classes.star}></Star>;
        const SelectedStar = (
            <Star
                fill={yellow}
                stroke={black}
                className={clsx(classes.star, classes.selectedStar)}
            ></Star>
        );
        const stars: JSX.Element[] = new Array<JSX.Element>();

        for (var i = 0; i < props.maxValue; i++) {
            if (i === props.value - 1) {
                stars.push(SelectedStar);
            } else {
                stars.push(FullStarIcon);
            }
        }
        return stars;
    }, [props.value, props.maxValue, classes]);

    return (
        <div className={classes.root}>
            <ReactRating
                initialRating={props.value}
                stop={props.maxValue}
                step={1}
                onClick={props.onClick}
                emptySymbol={EmptyStarIcon}
                fullSymbol={StarsArray}
            />
        </div>
    );
};

export default StarRating;
