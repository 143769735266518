import moment from 'moment';
import { Account, PaymentTerms } from '../../store/reducers/customer-context';
import { ProductionOrderSummary } from '../../store/reducers/make-it-summary';
import { Order_Status } from '../services/orders-service';
import { formattedMTDate } from './date-helpers';
import { getShipToName, getShipToAddress, getShipToProdOrderType } from './order-helpers';

export interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

export const getRevisedAvailableDate = (paymentDueDate: Date, string: boolean) => {
    // if the payment is late, display:
    // the earliest requested date on production order + (todays date - due date on invoice)

    if (moment(paymentDueDate).isBefore(moment(), 'day')) {
        let today = moment();
        let dueDate = moment(paymentDueDate);
        let dayDifference = today.diff(dueDate, 'days');
        let earliestRequestedDate = moment(paymentDueDate).add(4, 'weeks');
        let revisedAvailableDate = earliestRequestedDate.add(dayDifference, 'days');

        if (string) {
            return formattedMTDate(revisedAvailableDate, 'MM/DD/YYYY');
        } else {
            return revisedAvailableDate.format('YYYY-MM-DDTHH:mm:ssZ');
        }
    } else {
        return '';
    }
};

export function getShipToInformation(shipToAccounts: Account[], selectedShipToId: string) {
    return shipToAccounts.find((shipTo) => shipTo.accountId === selectedShipToId);
}

export function getFilteredAccount(shipToAccounts: Account[], selectedShipToId: string) {
    let filteredAccount = shipToAccounts.find(
        (shipToAccount) => parseInt(selectedShipToId) === +shipToAccount.accountId
    );
    return filteredAccount;
}

export function getCiaAccount(shipToAccounts: Account[], selectedShipToId: string) {
    const shipToAccount = getShipToInformation(shipToAccounts, selectedShipToId);
    return (shipToAccount && shipToAccount.paymentTerms === PaymentTerms.CIA) ?? false;
}

export function getName(
    productionOrderId?: number,
    shipToAccounts?: Account[],
    openOrders?: ProductionOrderSummary[]
) {
    if (shipToAccounts && openOrders) {
        return getShipToName(productionOrderId, shipToAccounts, openOrders);
    }
}

export function getAddress(
    productionOrderId?: number,
    shipToAccounts?: Account[],
    openOrders?: ProductionOrderSummary[]
) {
    if (shipToAccounts && openOrders) {
        return getShipToAddress(productionOrderId, shipToAccounts, openOrders);
    }
}

export function getProdOrderType(
    productionOrderId?: number,
    shipToAccounts?: Account[],
    openOrders?: ProductionOrderSummary[]
) {
    if (shipToAccounts && openOrders) {
        return getShipToProdOrderType(productionOrderId, shipToAccounts, openOrders);
    }
}

export function formatOrderQuantity(totalOrderQuantity?: number, totalOrderSKUs?: number) {
    let orderQuantityString = totalOrderQuantity + ' PL (';

    if (totalOrderSKUs === 1) {
        orderQuantityString += totalOrderSKUs + ' SKU)';
    } else {
        orderQuantityString += totalOrderSKUs + ' SKUs)';
    }

    return orderQuantityString;
}

export function setClosedColumn(order?: ProductionOrderSummary) {
    if (order && order.status !== Order_Status.Cancelled && order.lastShipmentDate) {
        return formattedMTDate(moment(order.lastShipmentDate), 'MM/DD/YYYY');
    }
    return '';
}
