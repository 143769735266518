import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Activity } from '../../../utility/auth/useSecurity';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import Button from '../../reusable/atoms/Button';
import { useTypedSelector } from '../../../store/reducers/reducer';
import {
    BulkUploadMakeItState,
    MakeItBulkLineItem,
    MakeItBulkOrder
} from '../../../store/reducers/makeit-bulk-upload';
import { ballGray, blackWeight, containerMaxWidth } from '../../../themes/globalConstants';
import MakeItBulkUploadConfirmationShareLinks from './components/MakeItBulkUploadConfirmationShareLinks';
import { formattedMTDate } from '../../../utility/helpers/date-helpers';
import moment from 'moment';
import { InfoSubheaderItem } from '../../../store/reducers/glp';
import MakeItBulkUploadConfirmationSubheader from './components/MakeItBulkUploadConfirmationSubheader';
import { useHistory } from 'react-router-dom';
import { CustomerContextState, PaymentTerms } from '../../../store/reducers/customer-context';
import MakeItBulkUploadConfirmationOrderSection from './components/MakeItBulkUploadConfirmationOrderSection';
import PaymentStatusHeader from '../../reusable/molecules/PaymentStatusHeader';
import { useDispatch } from 'react-redux';
import { resetMakeItBulkState } from '../../../store/root-actions';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { usePrevious } from '../../../utility/helpers/react-util';
import { ProductType } from '../../../utility/services/orders-service';
import {
    getTotalQuantityAmounts,
    getTotalQuantityEaches
} from '../../../utility/helpers/order-helpers';
import { formatNumberWithLocale } from '../../../utility/helpers/formatters';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: '3em',
        padding: '2em',
        borderRadius: 4,
        maxWidth: containerMaxWidth
    },
    header: {
        marginTop: '1em',
        marginBottom: '3em'
    },
    actionBtn: {
        borderRadius: 'unset'
    },
    title: {
        fontWeight: blackWeight,
        color: ballGray
    },
    subtitle: {
        fontWeight: blackWeight,
        color: ballGray
    },
    subheaderPaper: {
        marginTop: '3em',
        padding: '1em',
        borderRadius: 2,
        maxWidth: containerMaxWidth
    },
    tablePaper: {
        marginTop: '.1em',
        padding: '1em',
        borderRadius: 4,
        maxWidth: containerMaxWidth
    }
}));

export default function MakeItBulkUploadConfirmation() {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { orders } = useTypedSelector<BulkUploadMakeItState>(
        (state) => state.bulkUploadMakeItState
    );
    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const prevCultureCode = usePrevious(cultureCode);
    const [ciaCustomer, setCiaCustomer] = useState<boolean>(false);
    const [subheaderItems, setSubheaderItems] = useState<InfoSubheaderItem[]>();

    const getAllOrderLines = (orders?: MakeItBulkOrder[]) => {
        if (orders) {
            let lines = [] as MakeItBulkLineItem[];
            orders.forEach((ord) => {
                lines.concat(ord.lines);
            });
            return lines;
        }
    };

    const allOrderLines = getAllOrderLines(orders);
    const minimumDateList =
        allOrderLines && allOrderLines.map((line) => moment(line.requestedDate));
    const minimumDate = minimumDateList ? moment.min(minimumDateList) : moment().format();

    const handleDashboardNavigation = () => {
        dispatch(resetMakeItBulkState());
        history.push('/dashboard');
    };

    const footerActions = (
        <Button
            type="button"
            color="primary"
            variant="contained"
            data-testid="dashboard-btn"
            className={classes.actionBtn}
            onClick={handleDashboardNavigation}
        >
            <Trans i18nKey="myDashboard">My Dashboard</Trans>
        </Button>
    );

    const getQuantityDisplayString = useCallback(
        (type: string): String => {
            const quantityInformation = getTotalQuantityAmounts(type, orders);
            return `${formatNumberWithLocale(
                cultureCode,
                quantityInformation.pallets
            )} PL/${formatNumberWithLocale(cultureCode, quantityInformation.skus)} ${
                quantityInformation.skus > 1 ? t('skus', 'SKUs') : t('sku', 'SKU')
            }`;
        },
        [cultureCode, orders, t],
    );

    const createSubheader = useCallback(
        () => {
            setSubheaderItems([
                {
                    subtitle: t('dateSubmitted', 'Date Submitted'),
                    body: formattedMTDate(moment(), 'MM/DD/YYYY')
                },
                {
                    subtitle: t('canQuantity', 'Can Quantity'),
                    body: getQuantityDisplayString(ProductType.Cans),
                    subtext:
                        formatNumberWithLocale(
                            cultureCode,
                            getTotalQuantityEaches(ProductType.Cans, orders)
                        ) + ` ea.`
                },
                {
                    subtitle: t('endQuantity', 'End Quantity'),
                    body: getQuantityDisplayString(ProductType.Ends),
                    subtext:
                        formatNumberWithLocale(
                            cultureCode,
                            getTotalQuantityEaches(ProductType.Ends, orders)
                        ) + ` ea.`
                }
            ] as InfoSubheaderItem[]);
        },
        [cultureCode, getQuantityDisplayString, orders, t],
    );

    useEffect(() => {
        if (shipToAccounts) {
            shipToAccounts.forEach((shipTo) => {
                if (shipTo.paymentTerms === PaymentTerms.CIA) {
                    setCiaCustomer(true);
                }
            });
        }
    }, [shipToAccounts]);

    useEffect(() => {
        if (orders) {
            if (cultureCode !== prevCultureCode || !subheaderItems?.length) {
                createSubheader();
            }
        }
    }, [orders, cultureCode, prevCultureCode, subheaderItems?.length, createSubheader]);

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('makeIt', 'Make It'),
                description: t(
                    'ordersSubmitted',
                    'THANK YOU, YOUR ORDER(S) HAVE BEEN SUBMITTED'
                ).toLocaleUpperCase(),
                thinBanner: true,
                displayDropdown: false
            }}
            actionFooter={{
                footerAction: footerActions,
                justify: 'center',
                sticky: true
            }}
            activity={Activity.MakeItBulkUpload}
        >
            {ciaCustomer && (
                <PaymentStatusHeader minimumDate={moment(minimumDate).format()} isBulk={true} />
            )}
            <Paper elevation={2} className={classes.paper}>
                <Grid container spacing={2} alignItems="flex-start">
                    <Grid container alignItems="baseline" className={classes.header}>
                        <Grid container item xs={3} justify="flex-start">
                            <Typography variant="h3" className={classes.subtitle}>
                                <Trans i18nKey="yourOrders">Your Order(s)</Trans>
                            </Typography>
                        </Grid>
                        <Grid container item xs={6} justify="center"></Grid>
                        <Grid container item xs={3} justify="flex-end">
                            {orders && <MakeItBulkUploadConfirmationShareLinks orders={orders} />}
                        </Grid>
                    </Grid>
                    <Grid container>
                        {subheaderItems && (
                            <MakeItBulkUploadConfirmationSubheader
                                subheaderItems={subheaderItems}
                            />
                        )}
                    </Grid>
                </Grid>
                {orders &&
                    orders.map((order, index) => (
                        <Paper elevation={2} className={classes.subheaderPaper} key={index}>
                            <MakeItBulkUploadConfirmationOrderSection order={order} />
                        </Paper>
                    ))}
            </Paper>
        </ProcessingPageTemplate>
    );
}
