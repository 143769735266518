import {
    shipPurp,
    shipOrange,
    shipYellow,
    shipGreen,
    shipBlue,
    shipTeal,
    shipWine,
    shipRed,
    shipAltOrange,
    shipAltGreen,
    shipAltPurp,
    shipAltBlue,
    shipAltTeal,
    shipLitePurp,
    shipLiteYellow,
    shipLiteOrange,
    shipLiteGreen,
    shipLiteBlue,
    shipLiteWine,
    shipLiteRed,
    shipLiteAltOrange,
    shipLiteAltGreen,
    shipLiteAltPurp,
    shipLiteAltBlue,
    shipLiteAltTeal
} from '../../../../themes/globalConstants';

const shipItConfigColors = [
    shipPurp,
    shipOrange,
    shipYellow,
    shipGreen,
    shipBlue,
    shipTeal,
    shipWine,
    shipRed,
    shipAltOrange,
    shipAltGreen,
    shipAltPurp,
    shipAltBlue,
    shipAltTeal,
    shipLitePurp,
    shipLiteYellow,
    shipLiteOrange,
    shipLiteGreen,
    shipLiteBlue,
    shipLiteWine,
    shipLiteRed,
    shipLiteAltOrange,
    shipLiteAltGreen,
    shipLiteAltPurp,
    shipLiteAltBlue,
    shipLiteAltTeal
];

export default shipItConfigColors;
