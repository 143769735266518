import React, { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import AlertIcon from '@material-ui/icons/ReportProblem';
import {
    Button,
    Dialog,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextareaAutosize,
    Typography,
    makeStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import StarRating from '../../atoms/StarRating';
import { isValidEmail } from '../../../../utility/helpers/user-helpers';
import CustomerFeedbackService, {
    ContactPreference,
    SurveyResult
} from '../../../../utility/services/customer-feedback-service';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { styles } from './styles';
import rootTheme from '../../../../themes/rootTheme';

const useStyles = makeStyles(styles(rootTheme) as any);

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

const CustomerSurveyModal = ({ isOpen, onClose }: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { userInfo, accessToken } = useTypedSelector((state) => state.auth);

    // Form data
    const [score, setScore] = useState<number>(0);
    const [visitPurposeDropdownSelection, setVisitPurposeDropdownSelection] = useState<string>('');
    const [visitPurpose, setVisitPurpose] = useState<string>('');
    const [suggestions, setSuggestions] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [contactPreference, setContactPreference] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    // Component state
    const [formState, setFormState] = useState<'initial' | 'success'>('initial');
    const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    // Static data
    const visitPurposeMaxLength = 50;
    const suggestionsMaxLength = 5000;
    const nameMaxLength = 25;
    const phoneMaxLength = 50;
    const emailMaxLength = 100;

    const contactPreferenceOptions: { displayValue: string; value: ContactPreference }[] = [
        { displayValue: 'Yes, I prefer a phone call', value: ContactPreference.Phone },
        { displayValue: 'Yes, I prefer email', value: ContactPreference.Email },
        { displayValue: 'No, please do not contact me', value: ContactPreference.No_Contact }
    ];

    const visitPurposeOptions: string[] = [
        'Learn about working with Ball',
        'Get Design Inspiration',
        'Investigate Lab and Liquid',
        'Explore Sustainability & Regulatory',
        'Learn about Handling & Filling',
        'Access Can Specifications',
        'Obtain Pricing Information',
        'Other'
    ];

    // Memoized variables
    function getMaxCharacterMessage(maxLength: number, currentLength: number) {
        return `${currentLength}/${maxLength}`;
    }

    const visitPurposeCharactersLeft = useMemo(() => {
        return getMaxCharacterMessage(visitPurposeMaxLength, visitPurpose.length);
    }, [suggestions]);

    const suggestionsCharactersLeft = useMemo(() => {
        return getMaxCharacterMessage(suggestionsMaxLength, suggestions.length);
    }, [suggestions]);

    const nameCharactersLeft = useMemo(() => {
        return getMaxCharacterMessage(nameMaxLength, name.length);
    }, [name]);

    const phoneCharactersLeft = useMemo(() => {
        return getMaxCharacterMessage(phoneMaxLength, phone.length);
    }, [phone]);

    const emailCharactersLeft = useMemo(() => {
        return getMaxCharacterMessage(emailMaxLength, email.length);
    }, [email]);

    const emailIsValid: boolean = useMemo(() => {
        return isValidEmail(email) || contactPreference !== ContactPreference.Email;
    }, [email, contactPreference]);

    const scoreIsValid: boolean = useMemo(() => {
        return score >= 1 && score <= 5;
    }, [score]);

    const contactPreferenceIsValid: boolean = useMemo(() => {
        return Object.keys(ContactPreference).includes(contactPreference);
    }, [contactPreference]);

    const formHasErrors: boolean = useMemo(() => {
        return !(emailIsValid && scoreIsValid && contactPreferenceIsValid);
    }, [emailIsValid, scoreIsValid, contactPreferenceIsValid]);

    const submitDisabled: boolean = useMemo(() => {
        return formHasErrors && formSubmitted;
    }, [formHasErrors, formSubmitted]);

    const visitPurposeToSubmit: string = useMemo(() => {
        if (visitPurposeDropdownSelection === 'Other') return visitPurpose;

        return visitPurposeDropdownSelection;
    }, [visitPurpose, visitPurposeDropdownSelection]);

    // Functions
    async function handleSubmit() {
        setFormSubmitted(true);
        if (formHasErrors) return;

        const surveyResult: SurveyResult = {
            score,
            visitPurpose: visitPurposeToSubmit,
            suggestions,
            contactPreference: contactPreference as ContactPreference,
            name,
            phone,
            contactEmail: email,
            oktaEmail: userInfo?.email ? userInfo.email : email ?? ''
        };

        const response = await CustomerFeedbackService.submitFeedbackSurvey(
            accessToken,
            surveyResult
        ).catch(() => {
            setErrorMessage('An error has occurred, please try again.');
        });

        const success =
            response && response?.status === 201 && response?.data?.success.toString() === 'true';

        if (success) {
            setErrorMessage('');
            setFormState('success');
        } else {
            setErrorMessage('An error has occurred, please try again.');
        }
    }

    function handleClose() {
        onClose();

        // Reset state except for user-specific values after closing
        setScore(0);
        setVisitPurposeDropdownSelection('');
        setVisitPurpose('');
        setSuggestions('');
        setFormSubmitted(false);
        setErrorMessage('');
        setFormState('initial');
    }

    return (
        <Dialog
            open={isOpen}
            scroll="body"
            onClose={handleClose}
            aria-labelledby="Customer Survey Modal"
            aria-describedby="five feedback for The Source"
            maxWidth={'lg'}
        >
            <Grid container direction="column" className={classes.modal}>
                <CloseIcon className={classes.closeIcon} onClick={handleClose} />

                {/* Logo Image */}
                <img
                    src={process.env.PUBLIC_URL + '/assets/TheSource_Logo.svg'}
                    alt="The Source Logo"
                    className={classes.sourceLogo}
                />

                {/* Survey Form */}
                {formState === 'initial' && (
                    <Grid className={classes.form}>
                        {/* Survey Header */}
                        <Typography variant="h3" className={`${classes.header1}`}>
                            <Trans i18nKey="feedbackHeader">We'd love your feedback!</Trans>
                        </Typography>

                        {/* Survey Score */}
                        <div className={classes.formElement}>
                            <Typography className={classes.label}>
                                <label htmlFor="score">
                                    <Trans i18nKey="satisfaction">
                                        Overall, how satisfied are you with The Source?
                                    </Trans>
                                </label>
                            </Typography>
                            <div className={classes.scoreContainer}>
                                <StarRating
                                    maxValue={5}
                                    name="score"
                                    value={score}
                                    onClick={(newScore) => {
                                        setScore(newScore);
                                    }}
                                />
                            </div>
                            {!scoreIsValid && formSubmitted && (
                                <Typography className={classes.error}>
                                    <Trans i18nKey="enterScore">Please enter a score</Trans>
                                </Typography>
                            )}
                        </div>

                        {/* Visit Purpose */}
                        <div className={classes.formElement}>
                            <Typography className={classes.label}>
                                <label htmlFor="visitPurpose">
                                    <Trans i18nKey="visitPurpose">
                                        What was the main purpose for your visit today?
                                    </Trans>
                                </label>
                            </Typography>

                            <select
                                name="visitPurpose"
                                className={classes.input}
                                value={visitPurposeDropdownSelection}
                                onChange={(event) => {
                                    setVisitPurposeDropdownSelection(event.target.value);
                                }}
                            >
                                <option value="" selected disabled hidden>
                                    {t('selectOption', 'Please Select Option')}
                                </option>
                                {visitPurposeOptions.map((option) => (
                                    <option key={option} value={option} label={t(option, option)} />
                                ))}
                            </select>

                            {visitPurposeDropdownSelection === 'Other' && (
                                <div className={classes.visitPurpose}>
                                    <Typography
                                        className={`${classes.label} ${classes.sectionHeader}`}
                                    >
                                        <label htmlFor="visitPurposeText">
                                            <Trans i18nKey="pleaseSpecify">Please specify:</Trans>
                                        </label>
                                    </Typography>
                                    <input
                                        className={classes.input}
                                        type="text"
                                        name="visitPurposeText"
                                        value={visitPurpose}
                                        onChange={(event) => {
                                            if (event.target.value.length <= visitPurposeMaxLength)
                                                setVisitPurpose(event.target.value);
                                        }}
                                    />
                                    <Typography className={classes.charactersLeft}>
                                        {visitPurposeCharactersLeft}
                                    </Typography>
                                </div>
                            )}
                        </div>

                        {/* Suggestions */}
                        <div className={classes.formElement}>
                            <Typography className={classes.label}>
                                <label htmlFor="suggestions">
                                    <Trans i18nKey="suggestionLabel">
                                        Is there anything we can do to improve your visit to The
                                        Source?
                                    </Trans>
                                </label>
                            </Typography>
                            <TextareaAutosize
                                aria-label="Suggestions"
                                rowsMin={3}
                                value={suggestions}
                                onChange={(event: any) => {
                                    if (event.target.value.length <= suggestionsMaxLength)
                                        setSuggestions(event.target.value);
                                }}
                                className={classes.textArea}
                            />
                            <Typography className={classes.charactersLeft}>
                                {suggestionsCharactersLeft}
                            </Typography>
                        </div>

                        {/* Contact Preference */}
                        <div className={classes.formElement}>
                            <Typography className={classes.label}>
                                <label htmlFor="contactPreference">
                                    <Trans i18nKey="contactPreferenceLabel">
                                        If we need clarification on any of your responses, may we
                                        contact you?
                                    </Trans>
                                </label>
                            </Typography>
                            <RadioGroup
                                name="contactPreference"
                                value={contactPreference}
                                className={classes.noSelect}
                                onChange={(event: any) => setContactPreference(event.target.value)}
                            >
                                {contactPreferenceOptions.map((option) => (
                                    <FormControlLabel
                                        key={option.value}
                                        value={option.value}
                                        control={<Radio color="default" />}
                                        label={t(option.displayValue, option.displayValue)}
                                    />
                                ))}
                            </RadioGroup>
                            {!contactPreferenceIsValid && formSubmitted && (
                                <Typography className={classes.error}>
                                    <Trans i18nKey="selectContactPreference">
                                        Please select a contact preference
                                    </Trans>
                                </Typography>
                            )}
                        </div>

                        {/* Contact Inputs */}
                        {contactPreference &&
                            contactPreference !== ContactPreference.No_Contact && (
                                <div
                                    className={`${classes.formElement} ${classes.contactInputSection}`}
                                >
                                    <Typography className={classes.label}>
                                        <label htmlFor="name">
                                            <Trans i18nKey="nameLabel">Name:</Trans>
                                        </label>
                                    </Typography>
                                    <input
                                        className={classes.input}
                                        type="text"
                                        name="name"
                                        placeholder="Jane Smith"
                                        value={name}
                                        onChange={(event) => {
                                            if (event.target.value.length <= nameMaxLength)
                                                setName(event.target.value);
                                        }}
                                    />
                                    <Typography className={classes.charactersLeft}>
                                        {nameCharactersLeft}
                                    </Typography>
                                    {contactPreference === ContactPreference.Phone && (
                                        <>
                                            <Typography
                                                className={`${classes.label} ${classes.sectionHeader}`}
                                            >
                                                <label htmlFor="phone">
                                                    <Trans i18nKey="phoneLabel">
                                                        Phone number:
                                                    </Trans>
                                                </label>
                                            </Typography>
                                            <input
                                                className={classes.input}
                                                type="text"
                                                name="phone"
                                                placeholder="(111) 222-3333"
                                                value={phone}
                                                onChange={(event) => {
                                                    if (event.target.value.length <= phoneMaxLength)
                                                        setPhone(event.target.value);
                                                }}
                                            />
                                            <Typography className={classes.charactersLeft}>
                                                {phoneCharactersLeft}
                                            </Typography>
                                        </>
                                    )}
                                    {contactPreference === ContactPreference.Email && (
                                        <>
                                            <Typography
                                                className={`${classes.label} ${classes.sectionHeader}`}
                                            >
                                                <label htmlFor="email">
                                                    <Trans i18nKey="emailLabel">
                                                        Email address:
                                                    </Trans>
                                                </label>
                                            </Typography>
                                            <input
                                                className={classes.input}
                                                type="text"
                                                name="email"
                                                placeholder="example@example.com"
                                                value={email}
                                                onChange={(event) => {
                                                    if (event.target.value.length <= emailMaxLength)
                                                        setEmail(event.target.value);
                                                }}
                                            />
                                            {!emailIsValid && formSubmitted && (
                                                <Typography className={classes.error}>
                                                    <Trans i18nKey="emailError">
                                                        Please enter a valid email
                                                    </Trans>
                                                </Typography>
                                            )}
                                            <Typography className={classes.charactersLeft}>
                                                {emailCharactersLeft}
                                            </Typography>
                                        </>
                                    )}
                                </div>
                            )}

                        {/* Privacy Statement */}
                        <div className={classes.formElement}>
                            <Typography className={classes.privacyPolicy}>
                                <Trans i18nKey="privacyPolicyMessage">
                                    Your responses will be used in accordance with our
                                </Trans>{' '}
                                <a
                                    href="https://www.ball.com/na/additional-site-content/special-pages/privacy-statement/"
                                    target="_blank"
                                >
                                    <Trans i18nKey="feedbackPrivacyPolicy">privacy policy</Trans>
                                </a>
                            </Typography>
                        </div>

                        {/* Error Message */}
                        {errorMessage && (
                            <div className={classes.errorMessage}>
                                <AlertIcon />
                                <Typography className={`${classes.error} message`}>
                                    {errorMessage}
                                </Typography>
                            </div>
                        )}

                        {/* Buttons */}
                        <div className={classes.buttonContainer}>
                            <Button className={`${classes.button} close`} onClick={handleClose}>
                                <Trans i18nKey="closeButton">Close</Trans>
                            </Button>
                            <Button
                                className={`${classes.button} submit`}
                                onClick={handleSubmit}
                                disabled={submitDisabled}
                            >
                                <Trans i18nKey="submitButton">Submit</Trans>
                            </Button>
                        </div>
                    </Grid>
                )}

                {/* Success Message */}
                {formState === 'success' && (
                    <Grid className={classes.success}>
                        {/* Success Header */}
                        <Typography variant="h3" className={`${classes.header1}`}>
                            <Trans i18nKey="feedbackThankYou">
                                Thank You For Providing Feedback
                            </Trans>
                        </Typography>

                        {/* Close Button */}
                        <Button
                            className={`${classes.button} submit success`}
                            onClick={handleClose}
                        >
                            <Trans i18nKey="closeButton">Close</Trans>
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Dialog>
    );
};

export default CustomerSurveyModal;
