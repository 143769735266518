import {
    TRADEIT_REQUEST_DETAIL_LOADED,
    TRADEIT_REQUEST_DETAIL_LOADING,
    TRADEIT_REQUEST_DETAIL_LOADING_ERROR,
    TRADEIT_REQUEST_DETAIL_RESET
} from '../actions/action-types';

export interface TradeRequestDetailsLine {
    accountFromOriginalBalance: number;
    accountToOriginalBalance: number;
    productSku: string;
    quantity: number;
    status: string;
    displayName?: string;
    displayId?: string;
    graphicIdAndVersion?: string;
    quantityPerPallet?: number;
}
export interface TradeRequestDetails {
    tradeRequestId: string;
    accountFrom: string;
    accountTo: string;
    requestedAmount: string;
    submittedDate: string;
    accountFromOriginalBalance: number;
    accountToOriginalBalance: number;
    quantity?: number;
    tradeLines: TradeRequestDetailsLine[];
    fillerContactFrom?: string;
    fillerContactTo?: string;
    accountFromName?: string;
    accountToName?: string;
    accountFromAddress?: string;
    accountToAddress?: string;
    weekOf?: string;
}

export interface TradeRequestDetailState {
    tradeRequest?: TradeRequestDetails;
    loading: boolean;
    loaded: boolean;
    dataLoaded: boolean;
    error?: any;
}

const initialState: TradeRequestDetailState = {
    tradeRequest: undefined,
    loading: false,
    loaded: false,
    dataLoaded: false
};

const tradeItDetails = (
    state: TradeRequestDetailState = initialState,
    action: { type: string; [x: string]: any }
): TradeRequestDetailState => {
    switch (action.type) {
        case TRADEIT_REQUEST_DETAIL_LOADING:
            return { ...state, loading: true, loaded: false };
        case TRADEIT_REQUEST_DETAIL_LOADED:
            return {
                ...state,
                tradeRequest: action.tradeRequest,
                loading: false,
                dataLoaded: true,
                error: action.error
            };
        case TRADEIT_REQUEST_DETAIL_LOADING_ERROR:
            return {
                ...state,
                tradeRequest: undefined,
                loaded: true,
                dataLoaded: false,
                loading: false
            };
        case TRADEIT_REQUEST_DETAIL_RESET:
            return {
                ...state,
                tradeRequest: undefined,
                loading: false,
                loaded: false,
                dataLoaded: false
            };
        default:
            return state;
    }
};

export default tradeItDetails;
