import React from 'react';
import { Grid, TableCell, TableRow, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ProductInformationRow } from '../models/product-information-row.interface';
import CampaignPill from '../../../reusable/atoms/CampaignPill';
interface Props {
    item: ProductInformationRow;
    lockWindowWeeks: number;
    fromMakeIt: boolean;
    fromPlanIt: boolean;
}

const ProductInformationGridRow = ({ item, lockWindowWeeks, fromMakeIt, fromPlanIt }: Props) => {
    useTranslation();
    return (
        <>
            {item && (
                <TableRow key={'product-information-row' + item.productId}>
                    <TableCell>
                        <Typography data-testid="product-sku">{item.productSku}</Typography>
                    </TableCell>
                    <TableCell>
                        <Grid container>
                            <Typography data-testid="display-id">{item.displayId}</Typography>
                        </Grid>
                    </TableCell>
                    <TableCell>
                        <Typography data-testid="display-name">{item.displayName}</Typography>
                        <Typography data-testid="description">{item.description}</Typography>
                        {item.campaignTiming && (
                            <Typography data-testid="campaign-pill">
                                <CampaignPill />
                            </Typography>
                        )}
                    </TableCell>
                    <TableCell>
                        <Typography data-testid="coating">{item.coating}</Typography>
                    </TableCell>
                    {(fromMakeIt || fromPlanIt) && (
                        <TableCell>
                            <Typography data-testid="lead-time-weeks">
                                {item.leadTimeWeeksFormatted}
                            </Typography>
                        </TableCell>
                    )}
                    {fromMakeIt && (
                        <TableCell>
                            <Typography data-testid="lock-window-weeks">
                                {item.lockWindowWeeksFormatted}
                            </Typography>
                        </TableCell>
                    )}
                    {fromMakeIt && (
                        <TableCell>
                            <Typography data-testid="first-available-week">
                                {item.firstAvailableDate}
                            </Typography>
                        </TableCell>
                    )}
                </TableRow>
            )}
        </>
    );
};

export default ProductInformationGridRow;
