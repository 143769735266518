import {
    PRODINFO_PRODUCTS_LOADED,
    PRODINFO_PRODUCTS_LOADING,
    PRODINFO_PRODUCTS_LOADING_ERROR,
    PRODINFO_PRODUCTS_NO_PRODUCTS,
    PRODINFO_PRODUCTS_RESET_STATE,
    PRODINFO_PRODUCTS_UPDATE_VIEW
} from '../actions/action-types';
import { OrderProductTableRow } from './orders-dashboard';

export interface ProductInfoState {
    shipToId?: string;
    products?: OrderProductTableRow[];
    loading: boolean;
    loaded: boolean;
    error?: any;
    view?: string[];
}
const initialState: ProductInfoState = {
    shipToId: undefined,
    products: [],
    loading: false,
    loaded: false,
    view: [''],
    error: undefined
};

const productInformation = (state = initialState, action: any): ProductInfoState => {
    switch (action.type) {
        case PRODINFO_PRODUCTS_LOADED:
            return {
                ...state,
                loaded: true,
                loading: false,
                products: action.products,
                shipToId: action.shipToId
            };
        case PRODINFO_PRODUCTS_LOADING:
            return { ...state, loaded: false, loading: true, error: undefined };
        case PRODINFO_PRODUCTS_LOADING_ERROR:
            return { ...state, loaded: true, loading: false, error: action.error };
        case PRODINFO_PRODUCTS_UPDATE_VIEW:
            return { ...state, loaded: true, loading: false, view: action.view };
        case PRODINFO_PRODUCTS_NO_PRODUCTS:
            return {
                ...state,
                loaded: true,
                loading: false,
                products: [],
                shipToId: action.shipToId
            };
        case PRODINFO_PRODUCTS_RESET_STATE:
            return {
                ...state,
                products: [],
                loading: false,
                loaded: false,
                view: [''],
                error: undefined
            };
        default:
            return state;
    }
};

export default productInformation;
