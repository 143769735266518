import React from 'react';
import { Product } from '../../../../store/reducers/product-portfolio';
import { Grid, makeStyles, CardMedia, Typography } from '@material-ui/core';
import QuickLookTable from './QuickLookTable';
import Button from '../../../reusable/atoms/Button';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Link from '../../../reusable/atoms/Link';
import { useTranslation, Trans } from 'react-i18next';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { ballLtGray_2, aerosolProducts } from '../../../../themes/globalConstants';
import { Region } from '../../../../store/reducers/graphic-intake';
import { useDispatch } from 'react-redux';
import { updateSelectedProduct } from '../../../../store/actions/product-portfolio';

interface Props {
    product: Product;
}

const useStyles = makeStyles((theme) => ({
    linkWrapper: {
        display: 'flex',
        margin: '0.625em auto 2em auto',
        justifyContent: 'space-around',
        alignItems: 'center'
    },
    estimateBtn: {
        marginLeft: '0.625em'
    },
    content: {
        margin: 0,
        backgroundColor: ballLtGray_2
    },
    tablesWrapper: {
        backgroundColor: theme.palette.common.white
    },
    tables: {
        margin: '0.625em auto'
    },
    buildLink: {
        textTransform: 'capitalize'
    },
    image: {
        height: '18em',
        backgroundSize: 'contain',
        width: '100%'
    },
    lowerTextWrapper: {
        margin: '0.625em auto'
    },
    textBlock: {
        marginBottom: '1.2em'
    }
}));

const QuickLookDetails = ({ product }: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const detailsLink = `/product/${product.productCode}`;
    const estimateLink = `product-estimate?ids=${product.productCode}`;
    const { regionCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const sixteenOzAlumitek = 'ATK_BTL_16_473_NA';

    useTranslation();

    const handleEstimate = () => {
        dispatch(updateSelectedProduct(product));
        history.push('/compare-and-build');
    };

    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            className={classes.content}
        >
            <Grid container item xs={4}>
                <CardMedia
                    data-testid="product-image"
                    image={product.content?.canImage}
                    title={product.type}
                    className={classes.image}
                />
            </Grid>
            <Grid container item xs={8} className={classes.tablesWrapper}>
                <Grid item xs={10} className={classes.tables} data-testid="quick-look-table">
                    <QuickLookTable item={product} />
                </Grid>
                <Grid container item xs={10} className={classes.linkWrapper}>
                    <Link
                        to={detailsLink}
                        underline="none"
                        component={RouterLink}
                        data-testid="product-details-link"
                    >
                        <Button type="button" variant="outlined" color="primary">
                            <Trans i18nKey="seeDetailsBtn">See Full Product Details</Trans>
                        </Button>
                    </Link>

                    {(regionCode === Region.EU || regionCode === Region.SA) && (
                        <Link
                            to={estimateLink}
                            underline="none"
                            component={RouterLink}
                            data-testid="estimate-link"
                        >
                            <Button
                                type="button"
                                variant="outlined"
                                color="primary"
                                className={classes.estimateBtn}
                            >
                                <Trans i18nKey="getAnEstimate">Get an Estimate</Trans>
                            </Button>
                        </Link>
                    )}
                    {!aerosolProducts.includes(product.productCode) && (
                        <Link
                            component="button"
                            underline="always"
                            data-testid="build-estimate-button"
                            className={classes.buildLink}
                            onClick={handleEstimate}
                        >
                            <Trans
                                i18nKey={regionCode === Region.NA ? 'buildAndEstimateNav' : 'build'}
                            >
                                Build & Estimate
                            </Trans>
                        </Link>
                    )}
                </Grid>
                {regionCode === Region.NA && product.productCode === sixteenOzAlumitek && (
                    <Grid item xs={10} className={classes.lowerTextWrapper}>
                        <Typography variant="body1" className={classes.textBlock}>
                            <Trans i18nKey={'leadTimesMayVarySixteenOzAlumitek'}>
                                *This product is currently available. Lead times for receiving your
                                first order may vary. Talk with your Sales Rep for additional
                                details.
                            </Trans>
                        </Typography>
                    </Grid>
                )}
                {regionCode === Region.NA && product.productCode !== sixteenOzAlumitek && (
                    <Grid item xs={10} className={classes.lowerTextWrapper}>
                        {product.productCopy && (
                            <Typography variant="body1" className={classes.textBlock}>
                                {product.productCopy}
                            </Typography>
                        )}
                        <Typography variant="body1" className={classes.textBlock}>
                            <Trans i18nKey={'leadTimesSubjectToChange'}>
                                *All lead times are estimates and subject to change.
                            </Trans>
                        </Typography>
                        <Typography variant="body1" className={classes.textBlock}>
                            <strong>
                                <Trans i18nKey={'currentBallCustomers'}>
                                    Current Ball Customers:
                                </Trans>
                            </strong>{' '}
                            <Trans i18nKey={'leadTimesProduction'}>
                                Production lead times for new graphics begin when graphics are
                                complete and approved.
                            </Trans>
                        </Typography>
                        {aerosolProducts.includes(product.productCode) && (
                            <Typography variant="body1" className={classes.textBlock}>
                                <strong>
                                    <Trans i18nKey={'aerosolSalesNotice'}>
                                        For Sales Inquiries, Please Contact:
                                    </Trans>
                                </strong>
                                <div>
                                    <div>
                                        <Trans i18nKey={'aerosolSalesContact1'}>
                                            Carlos Garcia
                                        </Trans>
                                    </div>
                                    <div>
                                        <Trans i18nKey={'aerosolSalesContact2'}>
                                            Sr. Sales Manager
                                        </Trans>
                                    </div>
                                    <div>
                                        <Trans i18nKey={'aerosolSalesContact3'}>
                                            M: +52-444-188-61-29
                                        </Trans>
                                    </div>
                                    <div>
                                        <Trans i18nKey={'aerosolSalesContact4'}>
                                            cgarcia2@ball.com
                                        </Trans>
                                    </div>
                                </div>
                            </Typography>
                        )}
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export default QuickLookDetails;
