import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Box, Typography, Tabs, Tab, Grid, Button } from '@material-ui/core';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import useSecurity, { Activity, SecurityLevel } from '../../../../utility/auth/useSecurity';
import {
    small,
    blackWeight,
    inactiveTabBlue,
    lato,
    xl,
    white
} from '../../../../themes/globalConstants';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { useDispatch } from 'react-redux';
import { getUserCustomerAccounts } from '../../../../store/actions/customer-context';
import { Trans, useTranslation } from 'react-i18next';
import OpenLiquidTestTab from './OpenLiquidTestTab';
import { LiquidTestSummaryState } from '../../../../store/reducers/liquid-test-summary';
import { loadLiquidTests } from '../../../../store/actions/liquid-test-summary';
import ClosedLiquidTestTab from './ClosedLiquidTestTab';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: '4.5em',
        marginBottom: '4.5em',
        position: 'relative',
        '& h2': {
            fontSize: xl,
            fontFamily: lato,
            fontWeight: blackWeight,
            color: theme.palette.secondary.main
        }
    },
    appBar: {
        width: '75%'
    },
    tabPanel: {
        width: '100%'
    },
    panelHeader: {
        marginBottom: '1em'
    },
    counter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '0.5em',
        fontSize: small,
        fontWeight: blackWeight
    },
    tabs: {
        height: '3em'
    },
    tabLabel: {
        backgroundColor: inactiveTabBlue,
        borderTopLeftRadius: '0.625em',
        borderTopRightRadius: '0.625em',
        maxWidth: 'fit-content',
        '&:not(:first-of-type)': {
            marginLeft: '0.25em'
        }
    },
    tabLabelText: {
        fontSize: small,
        fontWeight: blackWeight
    },
    selectedTab: {
        backgroundColor: white
    },
    formLinkWrapper: {
        position: 'absolute',
        marginTop: '-4em',
        [theme.breakpoints.down('md')]: {
            position: 'inherit'
        }
    },
    formLinkIcon: {
        marginLeft: '0.25em',
        transform: 'rotate(270deg)'
    },
    newLiquidTestButton: {
        marginTop: '1.5em'
    }
}));

const TabPanel = (props: TabPanelProps) => {
    const classes = useStyles();
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`liquid-test-tabpanel-${index}`}
            aria-labelledby={`liquid-test-tab-${index}`}
            {...other}
            className={classes.tabPanel}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
};

//This assigns props for accessibility purposes
const a11yProps = (index: any) => {
    return {
        id: `liquid-test-tab-${index}`,
        'aria-controls': `liquid-test-tabpanel-${index}`
    };
};

const LiquidTestTabs = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const { openLiquidTests, recentlyCompletedLiquidTests } =
        useTypedSelector<LiquidTestSummaryState>((state) => state.liquidTestSummary);
    const { accounts } = useTypedSelector<CustomerContextState>((state) => state.customerContext);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [value, setValue] = useState<number>(0);
    const [liquidTestsLoaded, setLiquidTestsLoaded] = useState<boolean>(false);
    const [culture, setCulture] = useState<string>('');

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const submitNewClicked = () => {
        history.push('/liquid-test-intake');
    };

    const intakeSecurity = useSecurity(Activity.LiquidTestIntakeForm);
    const openTabSecurity = useSecurity(Activity.LiquidTestIntakeSummary);
    const recentlyCompletedTabSecurity = useSecurity(Activity.LiquidTestIntakeSummary);

    useEffect(() => {
        if (accounts && !accounts.length) {
            dispatch(getUserCustomerAccounts());
        }
    }, [accounts]);

    useEffect(() => {
        if (accounts && accounts.length > 0 && cultureCode) {
            if (!liquidTestsLoaded || cultureCode !== culture) {
                dispatch(loadLiquidTests(accounts, cultureCode));
                setLiquidTestsLoaded(true);
                setCulture(cultureCode);
            }
        }
    }, [accounts, liquidTestsLoaded, cultureCode, culture]);

    const TabLabel = (label: string, count: number) => {
        return (
            <Grid container justify="center" alignItems="center">
                <Typography className={classes.tabLabelText}>{label}</Typography>
                <Typography component="span" className={classes.counter}>
                    {'(' + count + ')'}
                </Typography>
            </Grid>
        );
    };

    const formButton = (
        <Grid
            container
            justify="flex-end"
            alignItems="center"
            spacing={2}
            className={classes.formLinkWrapper}
        >
            <Grid item className={classes.newLiquidTestButton}>
                <Button
                    type="button"
                    color="primary"
                    variant="outlined"
                    disabled={intakeSecurity !== SecurityLevel.Edit}
                    data-testid="intake-form-button"
                    onClick={submitNewClicked}
                >
                    <Trans i18nKey="submitNewTest">Submit New Test</Trans>
                </Button>
            </Grid>
        </Grid>
    );

    return (
        <div className={classes.root} data-testid="liquid-test-tabs">
            {formButton}
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="liquid test summary tabs"
                className={classes.tabs}
                scrollButtons="auto"
                variant="scrollable"
                indicatorColor="primary"
                textColor="primary"
            >
                <Tab
                    label={TabLabel(
                        t('openLiquidTests', 'Open Liquid Tests'),
                        openLiquidTests.length
                    )}
                    {...a11yProps(0)}
                    className={`${classes.tabLabel} ${value === 0 && classes.selectedTab}`}
                    disabled={openTabSecurity === SecurityLevel.None}
                    value={0}
                    data-testid="open-liquid-tests-tab"
                />
                <Tab
                    label={TabLabel(
                        t('recentlyCompleted', 'Recently Completed'),
                        recentlyCompletedLiquidTests.length
                    )}
                    {...a11yProps(1)}
                    className={`${classes.tabLabel} ${value === 1 && classes.selectedTab}`}
                    disabled={recentlyCompletedTabSecurity === SecurityLevel.None}
                    value={1}
                    data-testid="closed-liquid-tests-tab"
                />
            </Tabs>
            <TabPanel value={value} index={0}>
                <OpenLiquidTestTab />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ClosedLiquidTestTab />
            </TabPanel>
        </div>
    );
};

export default LiquidTestTabs;
