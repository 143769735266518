import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TableHead
} from '@material-ui/core';
import { Product } from '../../../../store/reducers/product-portfolio';
import { useTranslation, Trans } from 'react-i18next';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { currencyFormatter, precisionFormatter } from '../../../../utility/helpers/formatters';
import { Region } from '../../../../store/reducers/graphic-intake';

interface Props {
    details: Product;
    regionCode: string;
}

const useStyles = makeStyles((theme) => ({
    table: {
        marginBottom: '1.25em'
    },
    tableCell: {
        width: '50%'
    },
    contentHeader: {
        fontSize: 18,
        color: theme.palette.info.dark,
        fontWeight: 'bold'
    }
}));

const ProductDetailsTable = ({ details, regionCode }: Props) => {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const isNA = regionCode === Region.NA;
    const isSA = regionCode === Region.SA;
    const isEU = regionCode === Region.EU;
    const isWineCode = details.productCode === 'SLM_CN_8_250_NA_WN';
    const isSlimCanCode = details.productCode === 'SLM_CN_8_250_NA';

    function getValueDisplay(ozValue: number, mlValue: number) {
        if (isWineCode) {
            return `${ozValue}oz`
        } else if (isSlimCanCode) {
            return `${mlValue}ml`;
        } else {
            return `${ozValue}oz/${mlValue}ml`;
        }
    }

    useTranslation();

    return (
        <TableContainer>
            {isNA && (
                <Table className={classes.table} size="medium" aria-label="ordering table">
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={2}>
                                <Typography className={classes.contentHeader}>
                                    <Trans i18nKey="ordering">Ordering</Trans>
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={1} className={classes.tableCell}>
                                <Typography variant="body1">
                                    <Trans i18nKey="pricePerThousand">Price per 1,000</Trans>
                                </Typography>
                            </TableCell>
                            <TableCell align="left" colSpan={1}>
                                {details.pricePerThousand
                                    ? currencyFormatter(cultureCode, details.pricePerThousand)
                                    : 'N/A'}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={1}>
                                <Typography variant="body1">
                                    <Trans i18nKey="quantityPerPallet">Quantity per Pallet</Trans>
                                </Typography>
                            </TableCell>
                            <TableCell align="left" colSpan={1}>
                                <Typography data-testid="quantity-per-pallet" variant="body1">
                                    {details.quantityPerPallet
                                        ? details.quantityPerPallet.toLocaleString()
                                        : 'N/A'}{' '}
                                    <Trans i18nKey="cans">Cans</Trans>
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={1}>
                                <Typography variant="body1">
                                    <Trans i18nKey="minOrder">Minimum Order</Trans>
                                </Typography>
                            </TableCell>
                            <TableCell align="left" colSpan={1}>
                                <Typography
                                    data-testid="min-order-quantity-pallets"
                                    variant="body1"
                                >
                                    {details.minOrderQuantityPallets
                                        ? details.minOrderQuantityPallets
                                        : 'N/A'}{' '}
                                    <Trans i18nKey="pallets">Pallets</Trans>
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            )}
            <Table className={classes.table} size="medium" aria-label="design specifications table">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2}>
                            <Typography className={classes.contentHeader}>
                                <Trans i18nKey="designSpecs">Design Specifications</Trans>
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isNA && (
                        <TableRow>
                            <TableCell colSpan={1}>
                                <Typography variant="body1">
                                    <Trans i18nKey="windowLimitHeight">Window Limit (Height)</Trans>
                                </Typography>
                            </TableCell>
                            <TableCell align="left" colSpan={1}>
                                <Typography data-testid="window-height" variant="body1">
                                    {isNA
                                        ? `${precisionFormatter(
                                            cultureCode,
                                            'inch',
                                            details.windowHeightInches
                                        )}`
                                        : `${precisionFormatter(
                                            cultureCode,
                                            'millimeter',
                                            details.windowHeightMM
                                        )}`}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                    {isNA && (
                        <TableRow>
                            <TableCell colSpan={1} className={classes.tableCell}>
                                <Typography variant="body1">
                                    <Trans i18nKey="windowLimitWidth">Window Limit (Width)</Trans>
                                </Typography>
                            </TableCell>
                            <TableCell align="left" colSpan={1}>
                                <Typography data-testid="window-width" variant="body1">
                                    {isNA
                                        ? `${precisionFormatter(
                                            cultureCode,
                                            'inch',
                                            details.windowWidthInches
                                        )}`
                                        : `${precisionFormatter(
                                            cultureCode,
                                            'millimeter',
                                            details.windowWidthMM
                                        )}`}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                    {isEU && (
                        <TableRow>
                            <TableCell colSpan={1}>
                                <Typography variant="body1">
                                    <Trans i18nKey="circumference">Circumference</Trans>
                                </Typography>
                            </TableCell>
                            <TableCell align="left" colSpan={1}>
                                <Typography data-testid="design-width" variant="body1">
                                    {`${precisionFormatter(
                                        cultureCode,
                                        'millimeter',
                                        details.designWidthMM
                                    )}`}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                    {isSA && (
                        <TableRow>
                            <TableCell colSpan={1}>
                                <Typography variant="body1">
                                    <Trans i18nKey="designLength">
                                        Design Length (Circumference)
                                    </Trans>
                                </Typography>
                            </TableCell>
                            <TableCell align="left" colSpan={1}>
                                <Typography data-testid="design-width" variant="body1">
                                    {`${precisionFormatter(
                                        cultureCode,
                                        'millimeter',
                                        details.designWidthMM
                                    )}`}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                    {(isSA || isEU) && (
                        <TableRow>
                            <TableCell colSpan={1} className={classes.tableCell}>
                                <Typography variant="body1">
                                    <Trans i18nKey="designHeight">Design Height</Trans>
                                </Typography>
                            </TableCell>
                            <TableCell align="left" colSpan={1}>
                                <Typography data-testid="design-height" variant="body1">
                                    {`${precisionFormatter(
                                        cultureCode,
                                        'millimeter',
                                        details.designHeightMM
                                    )}`}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell colSpan={1}>
                            <Typography variant="body1">
                                <Trans i18nKey="maximumColors">Maximum Colors</Trans>
                            </Typography>
                        </TableCell>
                        <TableCell align="left" colSpan={1}>
                            <Typography data-testid="maximum-colors" variant="body1">
                                {details.maximumColors ? details.maximumColors : 'N/A'}
                            </Typography>
                        </TableCell>
                    </TableRow>

                    {/* Rows Only Show for EMEA and SA */}
                    {(isSA || isEU) && (
                        <TableRow>
                            <TableCell colSpan={1}>
                                <Typography variant="body1">
                                    <Trans i18nKey="maxTALength">Max Text Area (Length)</Trans>
                                </Typography>
                            </TableCell>
                            <TableCell align="left" colSpan={1}>
                                <Typography data-testid="max-text-width" variant="body1">
                                    {details.maxTextWidthMM
                                        ? `${details.maxTextWidthMM} mm`
                                        : 'N/A'}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                    {(isSA || isEU) && (
                        <TableRow>
                            <TableCell colSpan={1}>
                                <Typography variant="body1">
                                    <Trans i18nKey="maxTAHeight">Max Text Area (Height)</Trans>
                                </Typography>
                            </TableCell>
                            <TableCell align="left" colSpan={1}>
                                <Typography data-testid="max-text-height" variant="body1">
                                    {details.maxTextHeightMM
                                        ? `${details.maxTextHeightMM} mm`
                                        : 'N/A'}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                    {(isSA || isEU) && (
                        <TableRow>
                            <TableCell colSpan={1}>
                                <Typography variant="body1">
                                    <Trans i18nKey="underOverlap">Under/Overlap Area</Trans>
                                </Typography>
                            </TableCell>
                            <TableCell align="left" colSpan={1}>
                                <Typography data-testid="overlap-area-sa" variant="body1">
                                    {details.overlapArea ? `${details.overlapArea} mm` : 'N/A'}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>

            <Table className={classes.table} size="medium" aria-label="can specifications table">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2}>
                            <Typography className={classes.contentHeader}>
                                <Trans i18nKey="canSpecs">Can Specifications</Trans>
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={1} className={classes.tableCell}>
                            <Typography variant="body1">
                                <Trans i18nKey="bodyOutsideDiameter">Body Outside Diameter</Trans>
                            </Typography>
                        </TableCell>
                        <TableCell align="left" colSpan={1}>
                            <Typography data-testid="outside-diameter" variant="body1">
                                {isNA
                                    ? `${precisionFormatter(
                                        cultureCode,
                                        'inch',
                                        details.bodyOutsideDiameterInches
                                    )}`
                                    : `${precisionFormatter(
                                        cultureCode,
                                        'millimeter',
                                        details.bodyOutsideDiameterMM
                                    )}`}
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={1}>
                            <Typography variant="body1">
                                <Trans i18nKey="flangedCanHeight">Flanged Can Height</Trans>
                            </Typography>
                        </TableCell>
                        <TableCell align="left" colSpan={1}>
                            <Typography data-testid="flanged-can-height" variant="body1">
                                {isNA
                                    ? `${precisionFormatter(
                                        cultureCode,
                                        'inch',
                                        details.flangedCanHeightInches
                                    )}`
                                    : `${precisionFormatter(
                                        cultureCode,
                                        'millimeter',
                                        details.flangedCanHeightMM
                                    )}`}
                            </Typography>
                        </TableCell>
                    </TableRow>
                    {isNA && details.coldFillVolumeOz && details.coldFillVolumeML && (
                        <TableRow>
                            <TableCell colSpan={1}>
                                <Typography variant="body1">
                                    <Trans i18nKey="coldFillVolume">Cold Fill Volume</Trans>
                                </Typography>
                            </TableCell>
                            <TableCell align="left" colSpan={1}>
                                <Typography data-testid="cold-fill-volume" variant="body1">
                                    {getValueDisplay(details.coldFillVolumeOz, details.coldFillVolumeML)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                    {isNA && details.retortFillVolumeOz && details.retortFillVolumeML && (
                        <TableRow>
                            <TableCell colSpan={1}>
                                <Typography variant="body1">
                                    <Trans i18nKey="retortFillVolume">Retort Fill Volume</Trans>
                                </Typography>
                            </TableCell>
                            <TableCell align="left" colSpan={1}>
                                <Typography data-testid="retort-fill-volume" variant="body1">
                                    {getValueDisplay(details.retortFillVolumeOz, details.retortFillVolumeML)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                    {isEU && details.flangeWidth && (
                        <TableRow>
                            <TableCell colSpan={1}>
                                <Typography variant="body1">
                                    <Trans i18nKey="flangedCanWidth">Flange Width</Trans>
                                </Typography>
                            </TableCell>
                            <TableCell align="left" colSpan={1}>
                                <Typography data-testid="flange-width" variant="body1">
                                    {details.flangeWidth ? `${details.flangeWidth} mm` : 'N/A'}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                    {isEU && (
                        <TableRow>
                            <TableCell colSpan={1}>
                                <Typography variant="body1">
                                    <Trans i18nKey="endDiameter">End Diameter</Trans>
                                </Typography>
                            </TableCell>
                            <TableCell align="left" colSpan={1}>
                                <Typography data-testid="overlap-area-eu" variant="body1">
                                    {details.overlapArea ? `${details.overlapArea} mm` : 'N/A'}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ProductDetailsTable;
