import {
    MANAGE_PRODUCTS_LOADING,
    MANAGE_PRODUCTS_LOADED,
    MANAGE_PRODUCTS_ERROR,
    MANAGE_PRODUCTS_RESET,
    MANAGE_PRODUCTS_UNRECOGNIZED_PRODUCT_SET_EXPANDED,
    MANAGE_PRODUCTS_UNRECOGNIZED_PRODUCT_UPDATE_QUANTITIES,
    MANAGE_PRODUCTS_UNRECOGNIZED_PRODUCT_CANCEL_UPDATES
} from '../actions/action-types';
import { OrderProductTableRow } from './orders-dashboard';

export enum ProductStatusChangeCode {
    PSC00001 = 'PSC00001',
    PSC00002 = 'PSC00002',
    PSC00003 = 'PSC00003',
    PSC00004 = 'PSC00004'
}

export interface ManageProductsState {
    loaded: boolean;
    error?: any;
    activeProducts?: OrderProductTableRow[];
    inactiveProducts?: OrderProductTableRow[];
    unrecognizedProducts?: UnrecognizedProduct[];
    isEdited: boolean;
}

export interface UnrecognizedProductForecast {
    forecastId: number;
    forecastWeek: string;
    originalQuantity: number;
    updatedQuantity?: number;
}
export interface UnrecognizedProduct {
    customerProductId: string;
    productName?: string;
    size?: string;
    unit?: string;
    shape?: string;
    neckDiameter?: string;
    type?: string;
    endProfile?: string;
    forecasts?: UnrecognizedProductForecast[];
    expanded: boolean;
}

const initialState: ManageProductsState = {
    activeProducts: [],
    inactiveProducts: [],
    unrecognizedProducts: [],
    loaded: false,
    error: undefined,
    isEdited: false
};

const manageProductsState = (
    state: ManageProductsState = initialState,
    action: { type: string; [x: string]: any }
): ManageProductsState => {
    switch (action.type) {
        case MANAGE_PRODUCTS_LOADING:
            return {
                ...state,
                loaded: false,
                error: undefined,
                activeProducts: [],
                inactiveProducts: [],
                unrecognizedProducts: []
            };
        case MANAGE_PRODUCTS_LOADED:
            return {
                ...state,
                loaded: true,
                activeProducts: action.activeProducts,
                inactiveProducts: action.inactiveProducts,
                unrecognizedProducts: action.unrecognizedProducts
            };
        case MANAGE_PRODUCTS_ERROR:
            return { ...state, loaded: true, error: action.error };
        case MANAGE_PRODUCTS_RESET:
            return { ...initialState };
        case MANAGE_PRODUCTS_UNRECOGNIZED_PRODUCT_SET_EXPANDED:
            return { ...state, unrecognizedProducts: action.unrecognizedProducts };
        case MANAGE_PRODUCTS_UNRECOGNIZED_PRODUCT_UPDATE_QUANTITIES:
            return {
                ...state,
                unrecognizedProducts: action.unrecognizedProducts,
                isEdited: action.isEdited
            };
        case MANAGE_PRODUCTS_UNRECOGNIZED_PRODUCT_CANCEL_UPDATES:
            return { ...state, unrecognizedProducts: action.unrecognizedProducts, isEdited: false };
        default:
            return state;
    }
};

export default manageProductsState;
