import React, { RefObject, useState, useEffect, ReactNode, ReactElement } from 'react';
import { makeStyles, List, ListItem, Typography } from '@material-ui/core';
import Link from '../atoms/Link';
import throttle from 'lodash/fp/throttle';
import {
    large,
    medium,
    boldWeight,
    regularWeight,
    ltBlueGrey_3,
    small,
    blackWeight
} from '../../../themes/globalConstants';

interface SectionRef {
    ref: RefObject<HTMLElement>;
    header: ReactNode;
    icon?: boolean;
    iconSrc?: ReactElement;
}

interface Props {
    header?: ReactNode;
    translatedHeader?: ReactElement;
    sections: SectionRef[];
    readOnly?: boolean;
}

interface ScrollSpyProps {
    activeSectionDefault?: number;
    offsetPx?: number;
    sectionElementRefs?: RefObject<HTMLElement>[];
    throttleMs?: number;
}

const useScrollSpy = ({
    activeSectionDefault = 0,
    offsetPx = 0,
    sectionElementRefs = [],
    throttleMs = 100
}: ScrollSpyProps) => {
    const [activeSection, setActiveSection] = useState(activeSectionDefault);

    const handle = throttle(throttleMs, () => {
        let currentSectionId = activeSection;
        for (let i = 0; i < sectionElementRefs.length; i++) {
            const section = sectionElementRefs[i].current;
            // Needs to be a valid DOM Element
            if (!section || !(section instanceof HTMLElement)) continue;
            // GetBoundingClientRect returns values relative to viewport
            if (section.getBoundingClientRect().top + offsetPx < window.innerHeight / 2) {
                // set to active if it is above 50% of the view
                currentSectionId = i;
                continue;
            }
            // No need to continue loop, if last element has been detected
            break;
        }

        setActiveSection(currentSectionId);
    });

    useEffect(() => {
        window.addEventListener('scroll', handle);

        return () => {
            window.removeEventListener('scroll', handle);
        };
    }, [sectionElementRefs, offsetPx]);
    return activeSection;
};

const useStyles = makeStyles((theme) => ({
    menu: {
        position: 'sticky',
        top: '5em'
    },
    active: {
        color: `${theme.palette.primary.main} !important`,
        cursor: 'pointer',
        fontSize: large,
        fontWeight: boldWeight,
        letterSpacing: 0.33
    },
    headerText: {
        color: `${theme.palette.secondary.main} !important`,
        fontSize: small,
        fontWeight: blackWeight,
        letterSpacing: 1,
        textTransform: 'uppercase'
    },
    inactive: {
        color: `${theme.palette.info.dark} !important`,
        cursor: 'pointer',
        fontSize: medium,
        fontWeight: regularWeight,
        letterSpacing: 0.29
    },
    readOnly: {
        color: `${theme.palette.info.dark} !important`,
        fontSize: medium,
        fontWeight: regularWeight,
        letterSpacing: 0.29,
        opacity: 0.6
    },
    listItem: {
        paddingLeft: 0,
        paddingTop: '1em',
        paddingBottom: '1em',
        borderBottom: `1px solid ${ltBlueGrey_3}`,
        alignContent: 'center',
        justifyContent: 'space-between'
    }
}));

const HoverNavMenu = ({ sections, header, translatedHeader, readOnly }: Props) => {
    const headerOffset = -80; // height of fixed header
    const classes = useStyles();
    const scrollSection = useScrollSpy({
        sectionElementRefs: sections.map((section) => section.ref),
        offsetPx: headerOffset
    });
    const [activeSection, setActiveSection] = useState<number | null>(null);

    const scrollIntoView = (ref: RefObject<HTMLElement>) => {
        if (ref && ref.current) {
            ref.current.scrollIntoView();
            const scrollY = window.scrollY;
            if (scrollY) {
                // hack to avoid sticky header
                window.scroll(0, scrollY + headerOffset);
            }
            setTimeout(() => {
                // when scrolling happens, it will retrigger which item is "Active"
                // but we want it to highlight the item we just clicked on, so force it to be active
                setActiveSection(sections.findIndex((section) => section.ref === ref));
            });
        }
    };

    useEffect(() => {
        setActiveSection(scrollSection);
    }, [scrollSection]);

    return (
        <div className={classes.menu}>
            <List>
                {header && (
                    <ListItem className={classes.listItem}>
                        <Typography className={classes.headerText}>{header}</Typography>
                    </ListItem>
                )}
                {translatedHeader && (
                    <ListItem className={classes.listItem}>
                        <Typography className={classes.headerText}>{translatedHeader}</Typography>
                    </ListItem>
                )}
                {sections.map((section, index) => (
                    <ListItem key={section.header as string} className={classes.listItem}>
                        {readOnly ? (
                            <Typography variant="body1" className={classes.readOnly}>
                                {section.header}
                            </Typography>
                        ) : (
                            <Link
                                className={
                                    activeSection === index ? classes.active : classes.inactive
                                }
                                onClick={() => scrollIntoView(section.ref)}
                            >
                                {section.header}
                            </Link>
                        )}
                        {section.icon ? section.iconSrc : ''}
                    </ListItem>
                ))}
            </List>
        </div>
    );
};

export default HoverNavMenu;
