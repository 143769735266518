import React, { useState } from 'react';
import { makeStyles, Typography, TableRow, TableCell, Grid } from '@material-ui/core';
import { ltBlueGrey_34, medium } from '../../../../themes/globalConstants';
import { getGraphicIdAndVersion } from '../../../../utility/helpers/order-helpers';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { DeliveryOrderLinkedProductionOrder } from '../../../../store/reducers/shipping-dashboard';
import { OrderProduct } from '../../../../store/reducers/orders-dashboard';
import DeliveryOrderExpandableSection from './DeliveryOrderExpandableSection';
import { Flags, FlagTypes } from '../../../../utility/helpers/feature-flag';
import { getEachesConversion } from '../../../../utility/helpers/shipment-helpers';
interface Props {
    line: OrderProduct;
    linkedProductionOrder?: DeliveryOrderLinkedProductionOrder;
}

const useStyles = makeStyles((theme) => ({
    productDetailCell: {
        minWidth: '8em'
    },
    name: {
        fontSize: medium,
        letterSpacing: 0.17
    },
    expand: {
        transform: 'rotate(-90deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: 'rotate(0)'
    },
    expandContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    expandableSection: {
        maxHeight: '13.125em',
        overflowY: 'scroll'
    },
    standardRow: {
        '& > td:nth-child(n+4)': {
            textAlign: 'right'
        }
    },
    altRow: {
        backgroundColor: ltBlueGrey_34,
        '& > td': {
            borderBottomColor: 'white'
        }
    },
    hidden: {
        display: 'none'
    },
    display: {
        display: 'table-row'
    },
    borderlessCell: {
        borderLeft: 'unset'
    },
    activeCell: {
        backgroundColor: ltBlueGrey_34
    },
    activeRow: {
        boxShadow:
            '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 4px 3px 0px rgba(0,0,0,0.12)'
    }
}));

export default function ShipmentLoadDetailRow({ line, linkedProductionOrder }: Props) {
    const [active, setActive] = useState(false);
    const classes = useStyles();

    const toggleExpand = () => {
        setActive(!active);
    };

    useTranslation();

    let graphicIdAndVersion = getGraphicIdAndVersion(line.type, line.productGraphicId);

    return (
        <>
            <TableRow key={line.loadId}>
                <TableCell data-testid="product-id" className={classes.productDetailCell}>
                    <Grid container>
                        <Grid item xs={10} className={classes.name}>
                            <Typography>
                                <strong>
                                    {line.customerProductId ? line.customerProductId : '-'}
                                </strong>
                            </Typography>
                            <Typography key={'product-id-' + line.loadId} data-testid="display-id">
                                <strong>{line.productSku}</strong>
                            </Typography>
                            <Typography key={'graphic-id-' + line.loadId} data-testid="graphic-id">
                                {graphicIdAndVersion}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} className={classes.expandContainer}>
                            <ExpandMoreIcon
                                className={clsx(classes.expand, {
                                    [classes.expandOpen]: active
                                })}
                                onClick={toggleExpand}
                                color="primary"
                            />
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell data-testid="product-description">
                    <Grid container>
                        <Grid item xs={12} className={classes.name}>
                            <Typography key={'load-display-name-' + line.loadId}>
                                {line.displayName}
                            </Typography>
                            <Typography key={'load-desc-' + line.loadId}>
                                {line.description}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell data-testid="quantity">
                    <Grid container>
                        <Grid item xs={12} className={classes.name}>
                            <Typography key={'load-quantity-' + line.loadId}>
                                {line.palletQuantity?.toLocaleString()} PL
                            </Typography>
                            <Typography data-testid="line-quantity-eaches">
                                {line.palletQuantity &&
                                    line.quantityPerPallet &&
                                    getEachesConversion(
                                        line.palletQuantity,
                                        line.quantityPerPallet,
                                        true
                                    )}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell data-testid="release-number">
                    <Grid container>
                        <Grid item xs={12} className={classes.name}>
                            <Typography key={'load-release-' + line.loadId}>
                                {line.releaseNumber}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell data-testid="filler-line">
                    <Grid container>
                        <Grid item xs={12} className={classes.name}>
                            <Typography key={'load-filler-' + line.loadId}>
                                {line.fillerLine}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell data-testid="reference-number">
                    <Grid container>
                        <Grid item xs={12} className={classes.name}>
                            <Typography key={'load-reference-' + line.loadId}>
                                {line.referenceNumber}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <Flags authorizedFlags={[FlagTypes.Coke]}>
                    <TableCell data-testid="customer-production-order-number">
                        <Grid container>
                            <Grid item xs={12} className={classes.name}>
                                <Typography key={'customer-production-order-' + line.loadId}>
                                    {line.purchaseOrderNumber}
                                </Typography>
                            </Grid>
                        </Grid>
                    </TableCell>
                </Flags>
            </TableRow>
            <DeliveryOrderExpandableSection
                active={active}
                linkedProductionOrder={linkedProductionOrder}
                quantityPerPallet={line.quantityPerPallet}
                deliveryInstructions={line.deliveryInstructions}
            />
        </>
    );
}
