import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class DataValidationColumns {
    static getDataValidationColumns = (culture: string) => {
        return [
            createColumn('index', {
                dataType: ColumnDataType.Numeric,
                visible: false,
                searchable: false,
                sortable: false,
                filterable: false
            }),
            createColumn('validationField', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'value'),
                searchable: false,
                sortable: false,
                filterable: false
            }),
            createColumn('validationFormat', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'format'),
                searchable: false,
                sortable: false,
                filterable: false
            }),
            createColumn('validationRule', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'maxLength'),
                searchable: false,
                sortable: false,
                filterable: false
            }),
            createColumn('validationExample', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'example'),
                searchable: false,
                sortable: false,
                filterable: false
            })
        ];
    };
}
