import { Culture } from '../../store/reducers/region-culture';

import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/en';
import '@formatjs/intl-numberformat/locale-data/en-GB';
import '@formatjs/intl-numberformat/locale-data/es';
import '@formatjs/intl-numberformat/locale-data/pt';
import '@formatjs/intl-numberformat/locale-data/fr';
import '@formatjs/intl-numberformat/locale-data/de';

export const currencyFormatter = (cultureCode?, price?, currency?) => {
    return Intl.NumberFormat(cultureCode, {
        style: 'currency',
        currency: currency ?? 'USD'
    }).format(price);
};

export const precisionFormatter = (cultureCode?, units?, num?) => {
    if (!num) {
        return 'N/A';
    } else {
        if (typeof num === 'string') return num;

        return new Intl.NumberFormat(cultureCode, {
            style: 'unit',
            unit: units,
            maximumFractionDigits: 3
        } as any).format(num);
    }
};

export const numericRegex = (s: string) => {
    var rgx = /^[0-9]*\.?[0-9]*$/;
    return s.match(rgx);
};

export const modifyFirstLetter = (field: string) => {
    return field.charAt(0).toLowerCase() + field.slice(1);
};

// Usage: stringToFormat should have tokens {0}, {1}, ... {args.length-1} these tokens will be replaced by the cooresponding arguments in args
// for example if stringToFormat = '{0} plus {0} is {1}' and args = ['one', 'two']
// the result will be 'one plus one is two'
export const stringFormatterReplace = (stringToFormat: string, ...args: string[]): string => {
    for (let i = 0; i < args.length; i++) {
        stringToFormat = stringToFormat.replaceAll(`{${i}}`, args[i]);
    }
    return stringToFormat;
};

/**
 * Returns internationalized number formatted as a string.
 * @param locale The locale/culture code.
 * @param number
 */
export function formatNumberWithLocale(locale: Culture, number: number): string {
    return new Intl.NumberFormat(locale).format(number).replace(/\u202F/g, ' ');
}
