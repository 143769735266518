import { ColumnDataType, createColumn } from 'tubular-common';
import { Region } from '../../../../store/reducers/graphic-intake';
import TranslationService from '../../../../utility/services/translation-service';

export default class GraphicsInProgressColumns {
    static getGraphicsInProgressColumns = (culture: string, regionCode: string) => {
        const region = regionCode === Region.EU ? 'Emea' : '';
        const graphicNameAndIdKey = `graphicNameAndId${region}`;
        const graphicSpecialistKey = `graphicSpecialist${region}`;
        return [
            createColumn('sortIndex', {
                searchable: false,
                sortable: false,
                filterable: false,
                visible: false,
                dataType: ColumnDataType.Numeric
            }),
            createColumn('graphicsId', {
                isKey: true,
                visible: false,
                searchable: true
            }),
            createColumn('graphicsNumber', {
                searchable: true,
                filterable: false,
                visible: false
            }),
            createColumn('requestName', {
                searchable: true,
                filterable: true,
                sortable: true,
                label: TranslationService.getTranslatedText(culture, graphicNameAndIdKey),
                dataType: ColumnDataType.String
            }),
            createColumn('graphicSpecialist', {
                label: TranslationService.getTranslatedText(culture, graphicSpecialistKey),
                searchable: true,
                filterable: true,
                sortable: true
            }),
            createColumn('graphicSpecialistEmail', {
                searchable: true,
                filterable: false,
                visible: false
            }),
            createColumn('searchableStatus', {
                label:
                    regionCode === Region.EU
                        ? `${TranslationService.getTranslatedText(
                              culture,
                              'currentStatus'
                          )}/${TranslationService.getTranslatedText(culture, 'inStatusSince')}`
                        : TranslationService.getTranslatedText(culture, 'currentStatus'),
                visible: true,
                searchable: true,
                filterable: true,
                sortable: true
            }),
            createColumn('statuses', {
                visible: false,
                searchable: false,
                filterable: false,
                sortable: false
            }),
            createColumn('statusDate', {
                label: TranslationService.getTranslatedText(culture, 'inStatusSince'),
                visible: regionCode === Region.NA,
                searchable: regionCode === Region.NA,
                filterable: regionCode === Region.NA,
                sortable: regionCode === Region.NA
            }),
            createColumn('pendingICWeightStr', {
                label: TranslationService.getTranslatedText(culture, 'pendingICWeight'),
                visible: regionCode === Region.NA,
                searchable: regionCode === Region.NA,
                filterable: regionCode === Region.NA,
                sortable: regionCode === Region.NA
            })
        ];
    };
}
