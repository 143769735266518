import {
    COMMUNICATION_SUMMARY_LOADING,
    COMMUNICATION_SUMMARY_LOADED,
    COMMUNICATION_SUMMARY_ERROR,
    COMMUNICATION_MESSAGES_LOADING,
    COMMUNICATION_MESSAGES_LOADED,
    COMMUNICATION_MESSAGES_ERROR,
    COMMUNICATION_STATUS_UPDATE,
    COMMUNICATION_STATUS_UPDATE_ERROR,
    COMMUNICATION_STATUS_UPDATE_SUCCESS
} from './action-types';

import CommunicationService, {
    CommunicationSummaryRequest,
    UpdateCommunicationRequest
} from '../../utility/services/communication-service';

export const loadCommunicationSummaryData = (maxEndDateWeeks = 2) => {
    return (dispatch, getState) => {
        const { accessToken } = getState().auth;

        dispatch({ type: COMMUNICATION_SUMMARY_LOADING });

        CommunicationService.getCommunicationSummaryData(accessToken, {
            maxEndDateWeeks
        } as CommunicationSummaryRequest)
            .then((response) => {
                dispatch({
                    type: COMMUNICATION_SUMMARY_LOADED,
                    communications: response.data.communications
                });
            })
            .catch((error) => {
                dispatch({
                    type: COMMUNICATION_SUMMARY_ERROR,
                    error
                });
            });
    };
};

export const loadCommunicationMessagesData = () => {
    return (dispatch, getState) => {
        const { accessToken } = getState().auth;

        dispatch({ type: COMMUNICATION_MESSAGES_LOADING });

        CommunicationService.getCommunicationMessageData(accessToken)
            .then((response) => {
                dispatch({
                    type: COMMUNICATION_MESSAGES_LOADED,
                    communications: response.data.communications
                });
            })
            .catch((error) => {
                dispatch({
                    type: COMMUNICATION_MESSAGES_ERROR,
                    error
                });
            });
    };
};

export const updateCommunicationStatus = (communicationId: number, status: boolean) => {
    return (dispatch, getState) => {
        const { accessToken } = getState().auth;

        dispatch({ type: COMMUNICATION_STATUS_UPDATE });

        const updateRequest: UpdateCommunicationRequest = {
            communicationId,
            status
        };

        CommunicationService.updateCommunicationMessage(accessToken, updateRequest)
            .then((response) => {
                if (response?.status === 201 && response?.data?.communicationId) {
                    const communications = getState().communicationManagement.communications.map(
                        (communication) => {
                            if (communication.communicationId === communicationId) {
                                return {
                                    ...communication,
                                    status
                                };
                            }
                            return communication;
                        }
                    );
                    dispatch({
                        type: COMMUNICATION_STATUS_UPDATE_SUCCESS,
                        communications: communications
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch({
                    type: COMMUNICATION_STATUS_UPDATE_ERROR,
                    error
                });
            });
    };
};
