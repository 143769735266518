import React from 'react';
import { Product } from '../../../../store/reducers/product-portfolio';
import Checkbox from '../../../reusable/atoms/Checkbox';
import { useDispatch } from 'react-redux';
import Link from '../../../reusable/atoms/Link';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
    productPortfolioSelectProduct,
    updateSelectedProduct
} from '../../../../store/root-actions';
import {
    Card,
    CardHeader,
    CardMedia,
    CardContent,
    CardActions,
    Typography,
    makeStyles,
    List,
    ListItem,
    ListItemIcon,
    Grid
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation, Trans } from 'react-i18next';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { listIcon, ivory, visitedLink, aerosolProducts } from '../../../../themes/globalConstants';
import clsx from 'clsx';
import {
    useProductPortfolioDispatch,
    useProductPortfolioState
} from '../context/ProductPortfolioContext';
import { Region } from '../../../../store/reducers/graphic-intake';
import { getProductDescriptionForRegion } from '../../../../utility/helpers/product-helpers';
import { CAPACITY_CHECK_CLEAR_ERROR } from '../../../../store/actions/action-types';

interface Props {
    product: Product;
    productCount?: number;
    onQuickLook: (product) => void;
}

const wineHeight = '1.5em';

const useStyles = makeStyles((theme) => ({
    productTile: {
        '& .MuiCardHeader-root': {
            display: 'inline-flex'
        },
        '& .MuiCardHeader-action': {
            marginTop: -3,
            marginLeft: 10,
            height: 0
        },
        '& .MuiFormControl-root': {
            verticalAlign: 'middle',
            paddingRight: 16
        }
    },
    productImage: {
        height: '10em',
        backgroundSize: 'contain',
        backgroundColor: ivory
    },
    content: {
        paddingLeft: 0,
        minHeight: '3.5em',
        maxHeight: '5em',
        overflow: 'hidden'
    },
    SAContent: {
        maxHeight: '7em'
    },
    actionContainer: {
        margin: '0 0.5em 0.5em 0.5em'
    },
    productCardLinkContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    cardLink: {
        color: theme.palette.common.black,
        textTransform: 'capitalize',
        fontSize: 12,
        fontWeight: 500,
        textAlign: 'left'
    },
    quickLookLink: {
        marginTop: '-4px'
    },
    search: {
        color: theme.palette.secondary.light,
        position: 'relative',
        top: '3px',
        marginLeft: theme.spacing(1) / 2,
        verticalAlign: 'bottom'
    },
    activeLink: {
        '& .MuiTypography-body1': {
            textDecoration: 'underline',
            fontWeight: 700,
            fontSize: 12,
            letterSpacing: 0.94,
            '&:hover': {
                color: visitedLink
            }
        },
        '& .MuiFormControlLabel-label': {
            display: 'none'
        }
    },
    saIcon: {
        marginTop: '0.2em'
    },
    wineIcon: {
        width: '1.5em',
        height: wineHeight
    },
    wineGrid: {
        height: wineHeight
    }
}));

const ProductTile = ({ product, onQuickLook, productCount }: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const productPortfolioDispatch = useProductPortfolioDispatch();
    const { regionCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    useTranslation();

    const selectProduct = (selected) => {
        productPortfolioDispatch({
            type: selected ? 'INCREMENT_PRODUCT_SELECTED' : 'DECREMENT_PRODUCT_SELECTED'
        });
        dispatch(productPortfolioSelectProduct(product.productCode, selected));
    };
    const { hideKBMaxFunctionality } = useProductPortfolioState();

    const fullGridWidth = 12;

    const wineIcon = (
        <img
            src={process.env.PUBLIC_URL + '/assets/Wine_Icon.svg'}
            alt="Wine Icon"
            className={classes.wineIcon}
        />
    );

    const concatTitle = getProductDescriptionForRegion(product, regionCode);
    const title = <Typography variant="h5">{concatTitle}</Typography>;

    const productDetailsLink = `/product/${product.productCode}`;
    const ends = product.ends;
    const beverages = product.beverages;
    const isWine =
        regionCode === Region.NA &&
        beverages?.some((bev) => bev.featureCode === 'WINE' && bev.recommended === true);

    const handleEstimate = () => {
        dispatch({ type: CAPACITY_CHECK_CLEAR_ERROR });
        dispatch(updateSelectedProduct(product));
        history.push('/compare-and-build');
    };

    return (
        <Card variant="outlined" className={classes.productTile}>
            <Grid container justify="space-between" alignItems="center">
                <CardHeader title={title} />
                {!hideKBMaxFunctionality && (
                    <Checkbox
                        data-testid="select-product"
                        ariaLabel="select"
                        label={<Trans i18nKey="select">Select</Trans>}
                        placement="start"
                        size="small"
                        className={classes.activeLink}
                        checked={!!product.selected}
                        onChange={(value) => selectProduct(value)}
                    ></Checkbox>
                )}
            </Grid>
            <RouterLink to={productDetailsLink}>
                <CardMedia
                    data-testid="product-image"
                    component="div"
                    image={product.content?.canImage}
                    title={concatTitle}
                    className={classes.productImage}
                />
            </RouterLink>
            <CardContent
                className={clsx(
                    classes.content,
                    regionCode === Region.SA ? classes.SAContent : null
                )}
            >
                <Grid container alignItems="center" justify="flex-end" className={classes.wineGrid}>
                    {isWine ? <Grid item>{wineIcon}</Grid> : null}
                </Grid>
                {regionCode === Region.NA ? (
                    <List>
                        {product.minOrderQuantityPallets && (
                            <ListItem>
                                <ListItemIcon>
                                    <span>{listIcon}</span>
                                </ListItemIcon>
                                <Typography variant="body1">
                                    <Trans i18nKey="minOrderPallet">Minimum Order in Pallets</Trans>
                                    : {product.minOrderQuantityPallets}
                                </Typography>
                            </ListItem>
                        )}
                        {product.quantityPerPallet && (
                            <ListItem>
                                <ListItemIcon>
                                    <span>{listIcon}</span>
                                </ListItemIcon>
                                <Typography variant="body1">
                                    <Trans i18nKey="cansPerPallet">Cans Per Pallet</Trans>:{' '}
                                    {product.quantityPerPallet.toLocaleString()}
                                </Typography>
                            </ListItem>
                        )}
                    </List>
                ) : regionCode === Region.EU ? (
                    <List>
                        {ends && ends.length > 0 ? (
                            <ListItem>
                                <ListItemIcon>
                                    <span>{listIcon}</span>
                                </ListItemIcon>
                                <Typography variant="body1">
                                    <Trans i18nKey="compatibleEnds">Compatible Ends</Trans>:{' '}
                                    {ends.map((end) => end.label).join(', ')}
                                </Typography>
                            </ListItem>
                        ) : null}
                    </List>
                ) : regionCode === Region.SA ? (
                    <List>
                        {beverages && beverages.length > 0 ? (
                            <ListItem>
                                <Grid container>
                                    <Grid item xs={1}>
                                        <ListItemIcon className={classes.saIcon}>
                                            <span>{listIcon}</span>
                                        </ListItemIcon>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Typography variant="body1">
                                            <Trans i18nKey="greatFor">Great for</Trans>:{' '}
                                            {beverages
                                                ?.filter((result) => result.recommended)
                                                .map((bev) => bev.label)
                                                .join(', ')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </ListItem>
                        ) : null}
                        <ListItem>
                            <Grid container>
                                <Grid item xs={1}>
                                    <ListItemIcon className={classes.saIcon}>
                                        <span>{listIcon}</span>
                                    </ListItemIcon>
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography variant="body1">
                                        <Trans i18nKey="printingOptions">
                                            Printing Options: Printed Can, Sleeved Can, Bright Can
                                        </Trans>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ListItem>
                    </List>
                ) : null}
            </CardContent>
            <CardActions>
                <Grid container alignContent="center" className={classes.actionContainer}>
                    <Grid
                        container
                        item
                        xs={fullGridWidth}
                        className={classes.productCardLinkContainer}
                        justify="space-between"
                    >
                        {!aerosolProducts.includes(product.productCode) ? (
                            <Grid item>
                                <Link
                                    component="button"
                                    underline="always"
                                    data-testid="build-estimate-button"
                                    className={classes.cardLink}
                                    onClick={handleEstimate}
                                >
                                    <Trans
                                        i18nKey={
                                            regionCode === Region.NA
                                                ? 'buildAndEstimateNav'
                                                : 'buildNav'
                                        }
                                    >
                                        Build & Estimate
                                    </Trans>
                                </Link>
                            </Grid>
                        ) : (
                            <Grid item />
                        )}
                        <Grid item>
                            <Link
                                component="button"
                                underline="always"
                                data-testid="quick-look"
                                className={clsx(classes.cardLink, classes.quickLookLink)}
                                onClick={() => onQuickLook(product)}
                            >
                                <Trans i18nKey="quickLook">Quick Look</Trans>
                                <SearchIcon fontSize={'small'} className={classes.search} />
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    );
};

export default ProductTile;
