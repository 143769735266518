import React, { useEffect, useState } from 'react';
import PdfPageTemplate from '../../../templates/PdfPageTemplate';
import { Document, Page, Text, View, BlobProvider, Image } from '@react-pdf/renderer';
import pdfStyles from '../../../../themes/pdfStyles';
import { isIE } from '../../../../utility/helpers/user-helpers';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { getFormattedDateStringForApplication } from '../../../../utility/helpers/date-helpers';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { formatNumberWithLocale } from '../../../../utility/helpers/formatters';
import { isProductCanType, isProductEndType } from '../../../../utility/helpers/order-helpers';
import { ScrapItRequestResponse } from '../../../../utility/services/orders-service';

interface Props {
    shipToAddress: string;
    shipToName: string;
    shipToId: string;
    confirmation: ScrapItRequestResponse;
    title: string;
}
type DisplayTotals = {
    pallets: number;
    eaches: number;
};

const ScrapItConfirmationPDF = ({
    shipToAddress,
    shipToName,
    shipToId,
    confirmation,
    title
}: Props) => {
    const ballLogo = process.env.PUBLIC_URL + '/assets/Ball_RGB.jpg';
    const sourceLogo = process.env.PUBLIC_URL + '/assets/TheSource_Logo.png';
    const { shipToAccounts, selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );

    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [cansTotal, setCansTotal] = useState<DisplayTotals[]>([]);
    const [endsTotal, setEndsTotal] = useState<DisplayTotals[]>([]);

    useEffect(() => {
        let cansTotal: DisplayTotals[] = [];
        let endsTotal: DisplayTotals[] = [];
        confirmation.requestLines.map((product) => {
            if (isProductCanType(product.type)) {
                cansTotal.push({
                    pallets: product.inputPallets || 0,
                    eaches: product.inputEaches || 0
                });
            }
            if (isProductEndType(product.type)) {
                endsTotal.push({
                    pallets: product.inputPallets || 0,
                    eaches: product.inputEaches || 0
                });
            }
            return null;
        });
        setCansTotal(cansTotal);
        setEndsTotal(endsTotal);
    }, [confirmation]);
    const summary = (
        <>
            <View style={[pdfStyles.borderlessTable, pdfStyles.marginTop20]}>
                <View style={[pdfStyles.flexSection, pdfStyles.pageHeader]}>
                    <View style={[pdfStyles.divider25, pdfStyles.marginBottom10]} fixed />
                    <View>
                        <View>
                            <Text style={pdfStyles.header2} data-testid="header">
                                {'Scrap it Request'}
                            </Text>
                        </View>
                        <View>
                            <Text
                                style={[pdfStyles.subtitle14, pdfStyles.marginBottom3]}
                                data-testid="request-id"
                            >
                                #{confirmation.scrapRequestId}
                            </Text>
                        </View>
                    </View>
                    <View
                        style={[pdfStyles.borderLessTableColNarrow7, pdfStyles.textAlignRight]}
                        data-testid="date-submitted"
                    >
                        <View>
                            <Text style={pdfStyles.header2}>Date Submitted</Text>
                        </View>
                        <View>
                            <Text style={[pdfStyles.subtitle14, pdfStyles.marginBottom3]}>
                                {getFormattedDateStringForApplication(new Date().toISOString())}
                            </Text>
                        </View>
                    </View>
                    <View
                        style={[pdfStyles.borderLessTableColNarrow7, pdfStyles.textAlignRight]}
                        data-testid="can-quantity"
                    >
                        <View>
                            <Text style={pdfStyles.header2}>Can Quantity</Text>
                        </View>
                        <View>
                            <Text style={[pdfStyles.subtitle14]}>
                                {cansTotal.length > 0
                                    ? formatNumberWithLocale(
                                          cultureCode,
                                          cansTotal.reduce((prev, curr) => prev + curr.pallets, 0)
                                      ) +
                                      ' PL / ' +
                                      cansTotal.length +
                                      ' SKU'
                                    : '0 PL / 0 SKU'}
                            </Text>
                            <Text style={[pdfStyles.subtitle10, pdfStyles.marginBottom3]}>
                                {cansTotal.length > 0
                                    ? formatNumberWithLocale(
                                          cultureCode,
                                          cansTotal.reduce((prev, curr) => prev + curr.eaches, 0)
                                      ) + ' ea.'
                                    : '0 ea.'}
                            </Text>
                        </View>
                    </View>
                    <View
                        style={[pdfStyles.borderLessTableColNarrow7, pdfStyles.textAlignRight]}
                        data-testid="end-quantity"
                    >
                        <View>
                            <Text style={pdfStyles.header2}>End Quantity</Text>
                        </View>
                        <View>
                            <Text style={[pdfStyles.subtitle14, pdfStyles.marginBottom3]}>
                                {endsTotal.length > 0
                                    ? formatNumberWithLocale(
                                          cultureCode,
                                          endsTotal.reduce((prev, curr) => prev + curr.pallets, 0)
                                      ) +
                                      ' PL / ' +
                                      endsTotal.length +
                                      ' SKU'
                                    : '0 PL'}
                            </Text>
                            <Text style={[pdfStyles.subtitle10, pdfStyles.marginBottom3]}>
                                {endsTotal.length > 0
                                    ? formatNumberWithLocale(
                                          cultureCode,
                                          endsTotal.reduce((prev, curr) => prev + curr.eaches, 0)
                                      ) + ' ea.'
                                    : ''}
                            </Text>
                        </View>
                    </View>

                    <View style={[pdfStyles.divider25, pdfStyles.marginBottom10]} fixed />
                    <View
                        style={[pdfStyles.borderLessTableColWide, pdfStyles.textAlignLeft]}
                        data-testid="ship-to"
                    >
                        <View>
                            <Text style={[pdfStyles.header2, pdfStyles.marginBottom3]}>
                                Ship To:
                            </Text>
                        </View>
                        <View>
                            <Text style={[pdfStyles.header12, pdfStyles.marginBottom3]}>
                                {!!shipToAccounts
                                    ? shipToAccounts
                                          .filter((obj) => obj.accountId === selectedAccountId)
                                          .map((obj) => obj.name)
                                    : 'Invalid'}
                            </Text>
                        </View>
                        <View>
                            <Text style={[pdfStyles.subtitle7, pdfStyles.marginBottom3]}>
                                {!!shipToAccounts
                                    ? shipToAccounts
                                          .filter((obj) => obj.accountId === selectedAccountId)
                                          .map((obj) => obj.address.address1)
                                    : 'Invalid'}
                                ,
                                {!!shipToAccounts
                                    ? shipToAccounts
                                          .filter((obj) => obj.accountId === selectedAccountId)
                                          .map((obj) => obj.address.city)
                                    : 'Invalid'}
                                ,{' '}
                                {!!shipToAccounts
                                    ? shipToAccounts
                                          .filter((obj) => obj.accountId === selectedAccountId)
                                          .map((obj) => obj.address.stateProvince)
                                    : 'Invalid'}
                                ,{' '}
                                {!!shipToAccounts
                                    ? shipToAccounts
                                          .filter((obj) => obj.accountId === selectedAccountId)
                                          .map((obj) => obj.address.postalCode)
                                    : 'Invalid'}
                            </Text>
                        </View>
                        <View>
                            <Text style={[pdfStyles.subtitle7, pdfStyles.marginBottom3]}>
                                #{selectedAccountId}
                            </Text>
                        </View>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol, pdfStyles.textAlignLeft]} />
                </View>
            </View>
        </>
    );

    const tableHeaders = (
        <>
            <View style={[pdfStyles.borderlessTable, pdfStyles.marginTop20]}>
                <View style={[pdfStyles.flexSection, pdfStyles.pageHeader]}>
                    <View style={[pdfStyles.borderLessTableColNarrow10, pdfStyles.textAlignLeft]}>
                        <View>
                            <Text style={pdfStyles.header2}>Product Description</Text>
                        </View>
                    </View>
                    <View style={[pdfStyles.borderLessTableColNarrow8, pdfStyles.textAlignLeft]}>
                        <View>
                            <Text style={pdfStyles.header2}>Ball Product ID /</Text>
                            <Text style={pdfStyles.header2}>Graphic ID</Text>
                        </View>
                    </View>
                    <View style={[pdfStyles.borderLessTableColNarrow10, pdfStyles.textAlignLeft]}>
                        <View>
                            <Text style={pdfStyles.header2}>Customer</Text>
                            <Text style={pdfStyles.header2}>Product ID</Text>
                        </View>
                    </View>
                    <View style={[pdfStyles.borderLessTableColNarrow8, pdfStyles.textAlignLeft]}>
                        <View>
                            <Text style={pdfStyles.header2}>Coating</Text>
                        </View>
                    </View>
                    <View style={[pdfStyles.borderLessTableColNarrow8, pdfStyles.textAlignLeft]}>
                        <View>
                            <Text style={pdfStyles.header2}>Quantity to Scrap</Text>
                        </View>
                    </View>

                    <View style={[pdfStyles.dividerThinGray]} fixed />
                    <View style={[pdfStyles.flexSection, pdfStyles.marginBottom5]}></View>
                </View>
            </View>
        </>
    );

    const tableItems = confirmation.requestLines.map((line) => {
        return (
            <>
                <View style={[pdfStyles.borderlessTable]} data-testid="request-lines">
                    <View style={[pdfStyles.flexSection, pdfStyles.pageHeader]}>
                        <View
                            style={[pdfStyles.borderLessTableColNarrow10, pdfStyles.textAlignLeft]}
                        >
                            <View>
                                <Text style={pdfStyles.subtitle7}>{line.displayName}</Text>
                            </View>
                        </View>
                        <View
                            style={[pdfStyles.borderLessTableColNarrow8, pdfStyles.textAlignLeft]}
                        >
                            <View>
                                <Text style={pdfStyles.subtitle7}>
                                    {line.productSku} / {line.graphicIdAndVersion}
                                </Text>
                            </View>
                        </View>
                        <View
                            style={[pdfStyles.borderLessTableColNarrow10, pdfStyles.textAlignLeft]}
                        >
                            <View>
                                <Text style={pdfStyles.subtitle7}>
                                    {line.customerProductId ? line.customerProductId : '-'}
                                </Text>
                            </View>
                        </View>
                        <View
                            style={[pdfStyles.borderLessTableColNarrow8, pdfStyles.textAlignLeft]}
                        >
                            <View>
                                <Text style={pdfStyles.subtitle7}>{line.coating}</Text>
                            </View>
                        </View>
                        <View
                            style={[pdfStyles.borderLessTableColNarrow8, pdfStyles.textAlignLeft]}
                        >
                            <View>
                                <Text style={pdfStyles.subtitle7}>
                                    {Number(line.inputPallets).toLocaleString()}
                                    {' PL'} /
                                    {Number(
                                        line.inputPallets! * line.quantityPerPallet!
                                    ).toLocaleString()}
                                    {' ea.'}
                                </Text>
                            </View>
                        </View>

                        <View style={[pdfStyles.dividerThinGray, pdfStyles.marginBottom5]} fixed />
                    </View>
                </View>
            </>
        );
    });

    const myDoc = (
        <Document title={title}>
            <Page size="A4" style={pdfStyles.pagePadding25} orientation={'portrait'}>
                <View style={pdfStyles.logoSection}>
                    <Image src={sourceLogo} style={pdfStyles.sourceLogo} />
                    <Image src={ballLogo} style={pdfStyles.ballLogo} />
                </View>
                <View style={[pdfStyles.flexSection, pdfStyles.pageHeader]}>{summary}</View>
                <View style={[pdfStyles.flexSection, pdfStyles.pageHeader]}>{tableHeaders}</View>
                <View style={[pdfStyles.flexSection, pdfStyles.pageHeader]}>{tableItems}</View>
                <View style={[pdfStyles.fullWidth, pdfStyles.footer]} fixed>
                    <Text
                        style={pdfStyles.header2}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </View>
            </Page>
        </Document>
    );

    if (!isIE()) {
        return <PdfPageTemplate title={title}>{myDoc}</PdfPageTemplate>;
    } else {
        return (
            <BlobProvider document={myDoc}>
                {({ blob, url, loading, error }) => {
                    if (blob && window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
                        (window.navigator as any).msSaveOrOpenBlob(blob);
                    }
                    return null;
                }}
            </BlobProvider>
        );
    }
};

export default ScrapItConfirmationPDF;
