import * as types from './action-types';
import Axios, { AxiosRequestConfig } from 'axios';
import { getAxiosConfig } from '../../utility/helpers/axios-helpers';
import { GlpState } from '../reducers/glp';

export function loadGlobalLandingPageContent(region: string, language: string) {
    return (dispatch, getState) => {
        const glpState = getState().glp as GlpState;

        // only load once, and if we have already loaded the language, quick escape
        if (
            glpState.loading ||
            (glpState.loadedLanguage === language && glpState.loadedRegion === region)
        ) {
            return;
        }

        dispatch({
            type: types.GLP_LOADING
        });

        const axiosConfig: AxiosRequestConfig = getAxiosConfig(getState);

        // fetch mission statement
        const mission = Axios.get(
            `${process.env.REACT_APP_CONTENT_API}/content/glp/mission`,
            axiosConfig
        );

        mission.then((response) => {
            dispatch({
                mission: response.data?.section,
                type: types.GLP_LOADED_MISSION
            });
        });

        // fetch partnering with you
        const partnering = Axios.get(
            `${process.env.REACT_APP_CONTENT_API}/content/glp/partnering`,
            axiosConfig
        );

        partnering.then((response) => {
            dispatch({
                partnering: response.data?.section,
                type: types.GLP_LOADED_PARTNERING
            });
        });

        //fetch find products
        const findProducts = Axios.get(
            `${process.env.REACT_APP_CONTENT_API}/content/glp/find-products`,
            axiosConfig
        );

        findProducts.then((response) => {
            dispatch({
                findProducts: response.data?.section,
                type: types.GLP_LOADED_FIND_PRODUCTS
            });
        });

        //fetch banner
        const banner = Axios.get(
            `${process.env.REACT_APP_CONTENT_API}/content/glp/banner`,
            axiosConfig
        );

        banner.then((response) => {
            dispatch({
                banner: response.data?.section,
                type: types.GLP_LOADED_BANNER
            });
        });

        //fetch canContent
        const canContent = Axios.get(
            `${process.env.REACT_APP_CONTENT_API}/content/glp/about-cans`,
            axiosConfig
        );

        canContent.then((response) => {
            dispatch({
                canContent: response.data?.section,
                type: types.GLP_LOADED_CAN_CONTENT
            });
        });

        // add additional promises for other sections of GLP here...
        const promises = [mission, partnering, findProducts, banner, canContent];

        // when everything is loaded or an error occurs...
        return Promise.all(promises)
            .then(() => {
                dispatch({
                    type: types.GLP_LOADED,
                    region: region,
                    language: language
                });
            })
            .catch(() => {
                dispatch({
                    type: types.GLP_LOADING_ERROR,
                    error: 'Unable to load GLP Content'
                });
            });
    };
}
