import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import FreightFormula from '../atoms/FreightFormula';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import Modal from './Modal';
import Button from '../atoms/Button';
import { boldWeight, medium } from '../../../themes/globalConstants';

interface Props {
    open: boolean;
    onClose: () => void;
    onClickNo: () => void;
    onAcceptFee: () => void;
    maxPallets: number;
}

const useStyles = makeStyles((theme) => ({
    main: {
        '& .MuiPaper-root': {
            maxWidth: '50em'
        }
    },
    content: {
        padding: '2em'
    },
    subHeader: {
        '& p': {
            fontSize: medium,
            fontWeight: boldWeight,
            marginBottom: '1.5em'
        }
    },
    btnContainer: {
        '& button': {
            '&:last-of-type': {
                marginLeft: '1.5em'
            }
        }
    }
}));

export default function ShipmentFeeModal({
    open,
    onClose,
    onClickNo,
    onAcceptFee,
    maxPallets
}: Props) {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Modal
            open={open}
            close={onClose}
            title={t('feesMayIncur')}
            closeIcon={true}
            fullWidth={true}
            className={classes.main}
        >
            {
                <Grid container justify="center" className={classes.content}>
                    <Grid item>
                        <Grid item className={classes.subHeader}>
                            <Typography data-testid="subheader">
                                <Trans i18nKey="shipmentFeeModalWarning">
                                    Your freight rate is based on a full truckload (typically 25
                                    PL). Shipping less than a full truckload? We will send you a
                                    separate bill for the remainder of the freight. Here is how you would calculate the amount based on 25 pallets.
                                </Trans>
                            </Typography>
                            <FreightFormula maxPallets={maxPallets} />
                            <Typography>
                                <Trans i18nKey="doYouWantToAccept">
                                    Do you want to accept the fee?
                                </Trans>
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            justify="flex-end"
                            className={classes.btnContainer}
                        >
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    data-testid="no-btn"
                                    onClick={onClickNo}
                                >
                                    <Trans i18nKey="no">No</Trans>
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    data-testid="accept-btn"
                                    onClick={onAcceptFee}
                                >
                                    <Trans i18nKey="yesAcceptFee">Yes, Accept Fee</Trans>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Modal>
    );
}
