import Axios from 'axios';
import { CapacityCheckOption } from '../../store/reducers/capacity-checks';

// Spelling of 'Canceled' is to match Salesforce status value
export enum CapacityCheckStatus {
    Unknown = 'Unknown',
    Submitted = 'Submitted',
    UnderReview = 'UnderReview',
    Complete = 'Complete',
    Canceled = 'Canceled'
};

export default class CapacityCheckService {
    private static createConfig = (accessToken: string) => ({
        headers: { Authorization: `Bearer ${accessToken}` }
    });

    static getCapacityChecks = (accessToken: string) => {
        return Axios.get<any>(
            `${process.env.REACT_APP_JOINING_API}/capacity-checks`,
            CapacityCheckService.createConfig(accessToken)
        );
    };

    static saveCapacityCheck(capacityCheck: CapacityCheckOption[], accessToken: string) {
        return Axios.post(
            `${process.env.REACT_APP_JOINING_API}/capacity-checks`,
            { options: capacityCheck },
            CapacityCheckService.createConfig(accessToken)
        );
    };

    static cancelCapacityCheck(capacityCheckId: string, accessToken: string) {
        return Axios.delete(
            `${process.env.REACT_APP_JOINING_API}/capacity-checks/${capacityCheckId}`,
            CapacityCheckService.createConfig(accessToken)
        );
    };
}
