import { Grid, makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import {
    ballBlue,
    blackWeight,
    boldWeight,
    medium,
    red,
    yellowNoticeBackground,
    yellowNoticeBackgroundBorder
} from '../../../../themes/globalConstants';
import { Trans } from 'react-i18next';
import Button from '../../../reusable/atoms/Button';
import UserAdminDisableModal from './UserAdminDisableModal';
import { disableAdminUser, resendPendingRegistrationRequest } from '../../../../store/root-actions';
import { useDispatch } from 'react-redux';
import { PendingRegistrationTableRow } from '../../../../store/reducers/user-admin';

interface Props {
    pendingRegistration: PendingRegistrationTableRow;
    hasAdminPersona: boolean;
    handleRequestSubmittedSuccess: () => void;
}

const useStyles = makeStyles((theme) => ({
    name: {
        fontWeight: boldWeight,
        fontSize: medium,
        letterSpacing: 0.17
    },
    resendAction: {
        fontSize: '1em',
        padding: '0.25em',
        color: ballBlue,
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'transparent',
            color: ballBlue,
            fontWeight: blackWeight,
            outline: 'none'
        },
        '&:focus': {
            backgroundColor: 'transparent',
            color: ballBlue,
            fontWeight: blackWeight
        }
    },
    disableAction: {
        fontSize: '1em',
        padding: '0.25em',
        color: red,
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'transparent',
            color: red,
            fontWeight: blackWeight,
            outline: 'none'
        },
        '&:focus': {
            backgroundColor: 'transparent',
            color: red,
            fontWeight: blackWeight
        }
    },
    noAdminContainer: {
        backgroundColor: yellowNoticeBackground,
        borderWidth: 1,
        borderColor: yellowNoticeBackgroundBorder,
        borderStyle: 'solid'
    }
}));

const UserAdminPendingRegistrationRow = ({
    pendingRegistration,
    hasAdminPersona,
    handleRequestSubmittedSuccess
}: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [openDisableUserModal, setOpenDisableUserModal] = useState<boolean>(false);

    const onClose = () => {
        setOpenDisableUserModal(false);
    };

    const onOpen = () => {
        setOpenDisableUserModal(true);
    };

    const handleDisableUser = () => {
        setOpenDisableUserModal(false);
        if (pendingRegistration) {
            dispatch(disableAdminUser(pendingRegistration.userName));
        }
    };

    const handleResend = () => {
        dispatch(
            resendPendingRegistrationRequest(pendingRegistration.registrationId, () => {
                handleRequestSubmittedSuccess();
            })
        );
    };

    const actions = () => {
        return (
            <TableCell data-testid="pending-registration-actions">
                <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                        <Button
                            variant="text"
                            color="primary"
                            className={classes.resendAction}
                            data-testid="resend-btn"
                            onClick={handleResend}
                        >
                            <Trans i18nKey="resend">Resend</Trans>
                        </Button>
                        |
                        <Button
                            variant="text"
                            color="primary"
                            className={classes.disableAction}
                            data-testid="disable-btn"
                            onClick={onOpen}
                        >
                            <Trans i18nKey="disable">Disable</Trans>
                        </Button>
                    </Grid>
                </Grid>
            </TableCell>
        );
    };

    return (
        <>
            <TableRow hover={true} data-email={`${pendingRegistration.userName}`}>
                <TableCell data-testid="user-email">
                    <Grid container>
                        <Grid item xs={12} className={classes.name}>
                            <Typography>{pendingRegistration.userName}</Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell data-testid="roles">
                    <Grid container>
                        <Grid item xs={12} className={classes.name}>
                            <Typography>{pendingRegistration.roles}</Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell data-testid="sent-on">
                    <Grid container>
                        <Grid item xs={12} className={classes.name}>
                            <Typography>{pendingRegistration.createDate}</Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                {hasAdminPersona &&
                    (pendingRegistration.currentUserHasAdminPermission ? (
                        actions()
                    ) : (
                        <TableCell className={classes.noAdminContainer}>
                            <Trans i18nKey="noAdminforUser">
                                You are not the Admin for this User.
                            </Trans>
                        </TableCell>
                    ))}
            </TableRow>
            <UserAdminDisableModal
                openDisableUserModal={openDisableUserModal}
                onClose={onClose}
                handleDisableUser={handleDisableUser}
            />
        </>
    );
};

export default UserAdminPendingRegistrationRow;
