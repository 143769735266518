import {
    PRODUCT_DETAILS_LOADING,
    PRODUCT_DETAILS_LOADED,
    PRODUCT_DETAILS_ERROR,
} from '../actions/action-types';
import { Product } from './product-portfolio';

export interface ProductDetailsState {
    details?: Product;
    loading: boolean;
    error?: string;
}

const initialState: ProductDetailsState = {
    details: undefined,
    loading: false,
};

const productDetails = (state = initialState, action: any) => {
    switch (action.type) {
        case PRODUCT_DETAILS_LOADING:
            return {
                ...state,
                loading: true,
            };
        case PRODUCT_DETAILS_LOADED:
            return {
                ...state,
                loading: false,
                details: action.product,
            };
        case PRODUCT_DETAILS_ERROR:
            return {
                ...state,
                details: undefined,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
};

export default productDetails;
