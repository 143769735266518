import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ltBlueGrey_34 } from '../../../../themes/globalConstants';

const useStyles = makeStyles(() => ({
    divider: {
        boxSizing: 'border-box',
        width: '100%',
        marginTop: '2em',
        marginBottom: '2em',
        height: '5px',
        background: ltBlueGrey_34
    }
}));

export default function OnboardingSectionDivider() {
    const classes = useStyles();
    return <div className={classes.divider}></div>;
}
