import { Grid, Typography, makeStyles, Divider, Link, FormHelperText } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import {
    medium,
    ltBlueGrey_34,
    xl,
    boldWeight,
    small,
    mdash,
    red,
    aerosolProducts
} from '../../../../themes/globalConstants';
import { useFormContext, ErrorMessage } from 'react-hook-form';
import TextInput from '../../../reusable/molecules/TextInput';
import RadioInput from '../../../reusable/molecules/RadioInput';
import SelectInput from '../../../reusable/molecules/SelectInput';
import FormSection from '../../../reusable/molecules/FormSection';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { setFormSectionStatus } from '../../../../store/root-actions';
import { useDispatch } from 'react-redux';
import { ProductFeature } from '../../../../store/reducers/product-portfolio';
import ProductService from '../../../../utility/services/product-service';
import { createAxiosHeader } from '../../../../utility/helpers/axios-helpers';
import { PRODUCT_OPTION_STATUS_UPDATE } from '../../../../store/actions/action-types';
import { Graphics, Region } from '../../../../store/reducers/graphic-intake';
import { productListDataSort } from '../../../../utility/helpers/graphic-helpers';
import { naBeverageCategories } from '../../../../static-data/dropdown-lists';
import {
    emeaProductPasteurisation,
    ncaProductQualitySymbol
} from '../../../../static-data/dropdown-lists';

interface Props {
    isEditDraft: boolean;
    graphicData?: Graphics;
}

interface FeatureDropdown {
    key: number;
    name: string;
    value: string;
}

export interface ProductListData {
    volumeOz: number;
    volumeML: number;
    label: string;
    productCode: string;
    features: ProductFeature[];
}

interface ProductItem {
    index: number;
    name: string;
    value: string;
    productCode: string;
    features: ProductFeature[];
}

const useStyles = makeStyles((theme) => ({
    subsectionHeader: {
        fontSize: xl,
        fontWeight: boldWeight,
        color: theme.palette.info.dark,
        marginBottom: '1em'
    },
    label: {
        color: theme.palette.info.dark,
        fontSize: medium
    },
    radioGroup: {
        paddingLeft: '0.5em'
    },
    divider: {
        height: 5,
        backgroundColor: ltBlueGrey_34,
        margin: '1.5em 0'
    },
    helperText: {
        '& .MuiFormHelperText-root': {
            marginLeft: 0
        }
    },
    icon: {
        marginRight: '0.5em'
    },
    linkText: {
        fontSize: small
    },
    fieldLink: {
        fontSize: small,
        marginLeft: '0.75em'
    },
    errorText: {
        color: red
    }
}));

const ProductOptions = ({ isEditDraft, graphicData }: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { regionCode, cultureCode } = useTypedSelector((state) => state.regionCulture);
    const { accessToken } = useTypedSelector((state) => state.auth);
    const { userInfo } = useTypedSelector((state) => state.auth);
    const graphicIntake = useTypedSelector((state) => state.graphicIntake);
    const { control, errors, formState, watch, setValue, getValues } = useFormContext();
    const { isValid } = formState;
    const [beverageCategories, setBeverageCategories] = useState([] as FeatureDropdown[]);
    const [isNeckSize, setIsNeckSize] = useState(false);
    const [products, setProducts] = useState([] as ProductItem[]);
    const [inks, setInks] = useState([] as FeatureDropdown[]);
    const [overvarnishes, setOvervarnishes] = useState([] as FeatureDropdown[]);
    const [effects, setEffects] = useState([] as FeatureDropdown[]);

    const region = userInfo.Custom_Region ? userInfo.Custom_Region.toUpperCase() : Region.NA;
    const isRequired = <Trans i18nKey="required">Required</Trans>;
    const productCode = watch('productCode');
    const isSpecialties = watch('specialtiesStr') === 'true';
    const neckSize = [
        { value: '206', name: '206' },
        { value: '209', name: '209' }
    ];
    const canWithNeckSize = 'KNG_CN_24_710_NA';

    const tenOzSleekProductData: ProductListData = useMemo(
        () => ({
            volumeOz: 10,
            volumeML: 295,
            label: 'Sleek',
            productCode: 'SLK_CN_10_295_NA',
            features: []
        }),
        []
    );

    useEffect(() => {
        const loadProducts = () => {
            const header = createAxiosHeader(cultureCode, regionCode, accessToken);

            ProductService.getProducts({ types: [] }, header).then((response) => {
                let productResponse = response.data.products;

                // transform to ProductListData to allow for custom additions
                let productListData = productResponse.map((product) => {
                    return {
                        volumeOz: product.volumeOz,
                        volumeML: product.volumeML,
                        label: product.style?.label,
                        productCode: product.productCode,
                        features: product.features
                    };
                }) as ProductListData[];

                // customizations
                productListData.push(tenOzSleekProductData);

                if (regionCode === Region.NA) {
                    productListData.sort(productListDataSort);
                }

                let nonAerosolProducts = productListData.filter((product) => {
                    return !aerosolProducts.includes(product.productCode);
                });

                let mappedProducts = nonAerosolProducts.map((product, index) => {
                    if (aerosolProducts.includes(product.productCode)) return null;

                    let label = '';
                    if (regionCode === Region.NA) {
                        label =
                            product.volumeOz +
                            'oz/' +
                            product.volumeML +
                            'ml ' +
                            mdash +
                            ' ' +
                            product.label;
                        if (product.productCode.slice(-2) === 'WN') {
                            label += ' Wine Only';
                        }
                    } else if (regionCode === Region.EU) {
                        label = product.volumeML + 'ml' + mdash + product.label;
                    }
                    return {
                        index: index,
                        name: label,
                        value: product.productCode,
                        productCode: product.productCode,
                        features: product.features
                    };
                }) as ProductItem[];
                mappedProducts = mappedProducts.filter(
                    (product, index, self) =>
                        index === self.findIndex((t) => t.name === product.name)
                );
                return setProducts(mappedProducts);
            });
        };

        if (accessToken !== '') {
            loadProducts();
        }
    }, [accessToken, cultureCode, regionCode, tenOzSleekProductData]);

    // Check for 24oz product selection to display Neck Size dropdown
    useEffect(() => {
        if (productCode !== undefined) {
            const product = products.find((product) => product.value === productCode);
            setIsNeckSize(product?.productCode === canWithNeckSize);
            if (product && product.features) {
                setInks(filterFeature(product.features.filter((item) => item.type === 'INKS')));
                setEffects(
                    filterFeature(product.features.filter((item) => item.type === 'EFFECTS'))
                );
                setOvervarnishes(
                    filterFeature(product.features.filter((item) => item.type === 'VARNISH'))
                );
            }
        }
    }, [productCode, products]);

    const filterFeature = (features: ProductFeature[]) => {
        return features.map((feature, index) => {
            return {
                key: index,
                name: feature.label,
                value: feature.featureCode
            };
        });
    };

    useEffect(() => {
        if (naBeverageCategories) {
            const translatedCategories = naBeverageCategories.map((category, index) => {
                return {
                    key: index,
                    name: t(category.i18nKey, category.value),
                    value: category.value
                };
            });
            setBeverageCategories(translatedCategories);
        }
    }, [t]);

    useEffect(() => {
        if (graphicIntake && isValid !== graphicIntake.productOptionsValid) {
            dispatch(setFormSectionStatus(isValid, PRODUCT_OPTION_STATUS_UPDATE));
        }
    }, [dispatch, isValid, graphicIntake]);

    useEffect(() => {
        const getSelectedProductLabel = (productToLoad: string | undefined) => {
            let product = products.find((product) => product.productCode === productToLoad);
            return product ? product.productCode : '';
        };

        if (isEditDraft && graphicData && products.length) {
            setValue('customerGraphicsID', graphicData.customerGraphicsID);
            setValue('customerSKU', graphicData.customerSKU);
            setValue('upc', graphicData.upc);
            setValue('productPasteurisation', graphicData.productPasteurisation);
            if (graphicData.productCode) {
                //if the product was set for the draft, find it and set it
                let productSelected = getSelectedProductLabel(graphicData.productCode);
                setValue('productCode', productSelected);
                setIsNeckSize(graphicData.productCode === canWithNeckSize);
            }
            if (graphicData.neckSize) {
                setValue('neckSize', graphicData.neckSize);
            }
            setValue('specialtiesStr', graphicData.specialties?.toString());
            setValue('baseCoatRequiredStr', graphicData.baseCoatRequired?.toString());
        }
    }, [isEditDraft, graphicData, products, setValue]);

    useEffect(() => {
        const getSelectedInkLabel = (inkToLoad: string | undefined) => {
            let ink = inks.find((ink) => ink.value === inkToLoad);
            if (ink) {
                return ink.value;
            } else return '';
        };
        if (isEditDraft && graphicData && graphicData.ink && inks.length) {
            let ink = getSelectedInkLabel(graphicData.ink);
            setValue('ink', ink);
        }
    }, [inks, graphicData, isEditDraft, setValue]);

    useEffect(() => {
        const getSelectedEffectLabel = (effectToLoad: string | undefined) => {
            let effect = effects.find((effect) => effect.value === effectToLoad);
            if (effect) {
                return effect.value;
            } else return '';
        };
        if (isEditDraft && graphicData && graphicData.effect && effects.length) {
            let effect = getSelectedEffectLabel(graphicData.effect);
            setValue('effect', effect);
        }
    }, [effects, graphicData, isEditDraft, setValue]);

    useEffect(() => {
        const getSelectedOvervarnishLabel = (overvarnishToLoad: string | undefined) => {
            let overvarnish = overvarnishes.find(
                (overvarnish) => overvarnish.value === overvarnishToLoad
            );
            if (overvarnish) {
                return overvarnish.value;
            } else return '';
        };
        if (isEditDraft && graphicData && graphicData.overvarnish && overvarnishes.length) {
            let overvarnish = getSelectedOvervarnishLabel(graphicData.overvarnish);
            setValue('overvarnish', overvarnish);
        }
    }, [overvarnishes, graphicData, isEditDraft, setValue]);

    useEffect(() => {
        const getSelectedBeverageCategoryLabel = (categoryToLoad: string | undefined) => {
            let category = beverageCategories.find((category) => category.value === categoryToLoad);
            if (category) {
                return category.value;
            } else return '';
        };
        if (isEditDraft && graphicData && graphicData.beverageType && beverageCategories.length) {
            let category = getSelectedBeverageCategoryLabel(graphicData.beverageType);
            setValue('beverageType', category);
        }
    }, [beverageCategories, graphicData, isEditDraft, setValue]);

    return (
        <FormSection
            testId={'product-options-section'}
            sectionHeader={<Trans i18nKey="productOptions">Product Options</Trans>}
        >
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                    <TextInput
                        type="text"
                        label={<Trans i18nKey="customerGraphicsID">Customer Graphics ID</Trans>}
                        name="customerGraphicsID"
                        testId="customerGraphicsID"
                        error={errors.customerGraphicsID}
                        errors={errors}
                        control={control}
                        rules={{ min: 0, maxLength: 100, pattern: /^[a-zA-Z0-9'-\s]*$/ }}
                        styles={classes.helperText}
                        helperText={
                            <Trans i18nKey="customerGraphicsIDHelper">
                                <strong>Artwork Code (Optional):</strong> This is the code that is
                                printed on the can.
                            </Trans>
                        }
                    />
                    <FormHelperText className={classes.errorText}>
                        {errors.customerGraphicsID &&
                            errors.customerGraphicsID.type === 'maxLength' && (
                                <ErrorMessage
                                    errors={errors}
                                    name={'customerGraphicsID'}
                                    message={t(
                                        'hundredMaxLengthError',
                                        'Please make sure field value is 100 characters or less'
                                    )}
                                />
                            )}
                        {errors.customerGraphicsID &&
                            errors.customerGraphicsID.type === 'pattern' && (
                                <ErrorMessage
                                    errors={errors}
                                    name={'customerGraphicsID'}
                                    message={t(
                                        'alphaNumericPatternError',
                                        'Please make sure field is alphanumeric'
                                    )}
                                />
                            )}
                    </FormHelperText>
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        type="text"
                        label={<Trans i18nKey="customerSKUnumber">Customer SKU Number</Trans>}
                        name="customerSKU"
                        testId="customerSKU"
                        rules={{ min: 0, maxLength: 30 }}
                        control={control}
                        error={errors.customerSKU}
                        errors={errors}
                        styles={classes.helperText}
                        helperText={
                            <Trans i18nKey="customerSKUHelper">
                                <strong>Customer Material Number:</strong> This Material number will
                                not print on the can but is used to place orders in Ball's system.
                            </Trans>
                        }
                    />
                    <FormHelperText className={classes.errorText}>
                        {errors.customerSKU && errors.customerSKU.type === 'maxLength' && (
                            <ErrorMessage
                                errors={errors}
                                name={'customerSKU'}
                                message={t(
                                    'thirtyMaxLengthError',
                                    'Please make sure field value is 30 characters or less'
                                )}
                            />
                        )}
                    </FormHelperText>
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                    <TextInput
                        type="text"
                        label={<Trans i18nKey="upc">UPC # / EAN</Trans>}
                        name="upc"
                        testId="upc"
                        control={control}
                        rules={{ min: 0, maxLength: 50, pattern: /^[0-9'-\s]*$/ }}
                        error={errors.upc}
                        errors={errors}
                        styles={classes.helperText}
                        helperText={
                            <Trans i18nKey="upcHelper">
                                Provide the full UPC Number or EAN Code, including leading digits
                                (Optional)
                            </Trans>
                        }
                    />
                    <FormHelperText className={classes.errorText}>
                        {errors.upc && errors.upc.type === 'pattern' && (
                            <ErrorMessage
                                errors={errors}
                                name={'upc'}
                                message={t('numericPatternError', 'Please enter a numeric value')}
                            />
                        )}
                        {errors.upc && errors.upc.type === 'maxLength' && (
                            <ErrorMessage
                                errors={errors}
                                name={'upc'}
                                message={t(
                                    'fiftyMaxLengthError',
                                    'Please make sure field value is 50 characters or less'
                                )}
                            />
                        )}
                    </FormHelperText>
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6} data-testid="product-size">
                    <SelectInput
                        label={<Trans i18nKey="productSize">Product Size</Trans>}
                        name="productCode"
                        testId="productCode"
                        control={control}
                        rules={{ required: isRequired }}
                        error={errors.productCode}
                        errors={errors}
                        selectObj={products}
                        defaultValue={graphicData?.productCode}
                        helperText={
                            <Trans i18nKey="productCodeHelper">
                                Size of product the graphic will be run on
                            </Trans>
                        }
                    />
                </Grid>
                {regionCode === Region.NA && (
                    <Grid item xs={6} data-testid="beverage-category">
                        <SelectInput
                            label={<Trans i18nKey="beverageCategory">Beverage Category</Trans>}
                            name="beverageType"
                            testId="beverageType"
                            control={control}
                            rules={{ required: isRequired }}
                            error={errors.beverageType}
                            errors={errors}
                            selectObj={beverageCategories}
                            defaultValue={graphicData?.beverageType}
                        />
                    </Grid>
                )}

                {isNeckSize && (
                    <Grid item xs={6}>
                        <SelectInput
                            label={<Trans i18nKey="neckSize">Neck Size</Trans>}
                            name="neckSize"
                            testId="neckSize"
                            control={control}
                            rules={{ required: isRequired }}
                            error={errors.neckSize}
                            errors={errors}
                            selectObj={neckSize}
                            defaultValue={graphicData?.neckSize ? graphicData.neckSize : ''}
                            helperText={
                                <Trans i18nKey="neckSizeTooltip">
                                    Neck size should be 206 unless Ball has previously authorized
                                    209
                                </Trans>
                            }
                        />
                    </Grid>
                )}
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                    <SelectInput
                        label={
                            <Trans i18nKey="productPasteurization">Product Pasteurization</Trans>
                        }
                        name="productPasteurisation"
                        testId="productPasteurisation"
                        control={control}
                        error={errors.productPasteurisation}
                        errors={errors}
                        selectObj={emeaProductPasteurisation}
                        defaultValue=""
                        helperText={
                            <Trans i18nKey="productPasteurisationHelper">
                                Select the approximate temperature at which to pasteurise your
                                product. If your product requires Hot-Fill or Pasteurization please
                                select an option here. Special retort inks are different from
                                standard inks and will need to be used for your can production.
                            </Trans>
                        }
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                    <SelectInput
                        label={<Trans i18nKey="productQualitySymbol">Symbol</Trans>}
                        name="productQualitySymbol"
                        testId="productQualitySymbol"
                        control={control}
                        rules={{ required: isRequired }}
                        error={errors.productQualitySymbol}
                        errors={errors}
                        styles={classes.helperText}
                        selectObj={ncaProductQualitySymbol}
                        defaultValue="None"
                        helperText={
                            <Trans i18nKey="productSymbolContentHelper">
                                Artwork files requiring a separate set-up/version such as for Kosher
                                (K, CRC, KOF) or for Deposit (DEP) need to be submitted as separate
                                graphic projects through The Source with all necessary symbols
                                present on your artwork file. Ball will not be held responsible for
                                failing to set up an alternate version of your graphic file. If you
                                do not have a special symbol requirement for this graphic please
                                select 'None'
                            </Trans>
                        }
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider className={classes.divider} />
            </Grid>
            <Grid container item xs={12} justify="space-between">
                <Grid item xs={6}>
                    <Typography className={classes.subsectionHeader}>
                        <Trans i18nKey="canFinishesAndEffects">Can Finishes & Effects</Trans>
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <img
                        src={process.env.PUBLIC_URL + '/assets/DesignInspiration_Icon.svg'}
                        alt="Inspiration Icon"
                        className={classes.icon}
                    />
                    <Link
                        component={RouterLink}
                        to={`/content/${region}/Pages/Design-Inspiration`}
                        underline="always"
                        data-testid="header-button"
                        className={classes.linkText}
                        target="_blank"
                    >
                        <Trans i18nKey="lookingForDesignInspiration">
                            Looking for Design Inspiration?
                        </Trans>
                    </Link>
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                    <RadioInput
                        styles={classes.radioGroup}
                        label={
                            <Trans i18nKey="baseCoatRequired">
                                Does your product need basecoat ink?
                            </Trans>
                        }
                        name="baseCoatRequiredStr"
                        testId="baseCoatRequired"
                        control={control}
                        rules={{ required: isRequired }}
                        radioControls={[
                            { value: 'true', label: <Trans i18nKey="yes">Yes</Trans> },
                            { value: 'false', label: <Trans i18nKey="no">No</Trans> }
                        ]}
                        error={errors.baseCoatRequiredStr}
                        errors={errors}
                        defaultValue={'false'}
                        helperText={
                            <Trans i18nKey="baseCoatRequiredHelperText">
                                Please note: Basecoat availability is limited and subject to
                                approval, your Graphics representative will follow up.
                            </Trans>
                        }
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                    <RadioInput
                        styles={classes.radioGroup}
                        label={
                            <Trans i18nKey="specialties">
                                Does your product require specific coatings or specialties?
                            </Trans>
                        }
                        name="specialtiesStr"
                        testId="specialties"
                        control={control}
                        rules={{ required: isRequired }}
                        radioControls={[
                            { value: 'true', label: <Trans i18nKey="yes">Yes</Trans> },
                            { value: 'false', label: <Trans i18nKey="no">No</Trans> }
                        ]}
                        error={errors.specialtiesStr}
                        errors={errors}
                        defaultValue={''}
                    />
                </Grid>
            </Grid>

            {isSpecialties && productCode && inks.length > 0 && (
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={6}>
                        <SelectInput
                            label={<Trans i18nKey="ink">Ink</Trans>}
                            name="ink"
                            testId="ink"
                            control={control}
                            rules={''}
                            error={errors.ink}
                            errors={errors}
                            selectObj={inks}
                            defaultValue={getValues('ink')}
                            helperText={
                                <Trans i18nKey="inkHelper">
                                    Ink options available for this product
                                </Trans>
                            }
                        />
                    </Grid>
                </Grid>
            )}
            <Grid container item xs={12} spacing={2}>
                {isSpecialties && productCode && effects.length > 0 && (
                    <Grid item xs={6}>
                        <SelectInput
                            label={<Trans i18nKey="effect">Effect</Trans>}
                            name="effect"
                            testId="effect"
                            control={control}
                            rules={''}
                            error={errors.effect}
                            errors={errors}
                            selectObj={effects}
                            defaultValue={''}
                            helperText={
                                <Trans i18nKey="effectHelper">
                                    Effect options available for this product
                                </Trans>
                            }
                        />
                    </Grid>
                )}
                {isSpecialties && productCode && overvarnishes.length > 0 && (
                    <Grid item xs={6}>
                        <SelectInput
                            label={<Trans i18nKey="overvarnish">Overvarnish</Trans>}
                            name="overvarnish"
                            testId="overvarnish"
                            control={control}
                            rules={''}
                            error={errors.overvarnish}
                            errors={errors}
                            selectObj={overvarnishes}
                            defaultValue={''}
                            helperText={
                                <Trans i18nKey="overvarnishHelper">
                                    Overvarnish options available for this product
                                </Trans>
                            }
                        />
                    </Grid>
                )}
            </Grid>
        </FormSection>
    );
};
export default ProductOptions;
