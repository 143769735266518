import { Grid, Typography, makeStyles, Divider, FormHelperText } from '@material-ui/core';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
    medium,
    ltBlueGrey_34,
    xl,
    boldWeight,
    red,
    paleGrey
} from '../../../../themes/globalConstants';
import { ErrorMessage, useFormContext } from 'react-hook-form';
import TextInput from '../../../reusable/molecules/TextInput';
import RadioInput from '../../../reusable/molecules/RadioInput';
import FormSection from '../../../reusable/molecules/FormSection';
import { setFileSection } from '../../../../store/root-actions';
import { useDispatch } from 'react-redux';
import FileUpload from '../../../reusable/molecules/FileUpload';
import { NCACountries } from '../../../../utility/helpers/address-helpers';
import { EntityType, Importer, ShipType } from '../../../../store/reducers/onboarding-dashboard';
import { validateFileInformation } from '../../../../utility/helpers/onboardHelpers';

interface Props {
    billingCountry: string;
}

const useStyles = makeStyles((theme) => ({
    subsectionHeader: {
        fontSize: xl,
        fontWeight: boldWeight,
        color: theme.palette.info.dark
    },
    divider: {
        height: 5,
        backgroundColor: ltBlueGrey_34,
        margin: '1.5em 0'
    },
    helperText: {
        '& .MuiFormHelperText-root': {
            marginLeft: 0
        }
    },
    errorText: {
        color: red
    },
    sectionMargin: {
        margin: '2em 0'
    },
    error: {
        color: 'red'
    }
}));

const uploadStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: paleGrey,
        height: '11.5em',
        justifyContent: 'center',
        display: 'flex',
        cursor: 'pointer'
    },
    divContainer: {
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%'
    },
    invalidText: {
        color: 'red'
    },
    icon: {
        height: '1.875em',
        width: '1.4em',
        fill: theme.palette.secondary.main
    },
    text: {
        fontSize: xl,
        fontWeight: boldWeight,
        letterSpacing: '0.13px'
    },
    fileLabel: {
        fontSize: medium,
        marginTop: '1em',
        fontWeight: boldWeight
    },
    fileName: {
        marginTop: '1em',
        marginLeft: '0.5em'
    }
}));

const OnboardShippingInformationSection = ({ billingCountry }: Props) => {
    const classes = useStyles();
    const { control, errors, watch } = useFormContext();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [showUploadError, setShowUploadError] = useState<boolean>(false);
    const [showFileSizeError, setShowFileSizeError] = useState<boolean>(false);

    const isRequired = <Trans i18nKey="required">Required</Trans>;

    // Only accepting zip file types
    const acceptedFileTypes = 'application/x-zip-compressed, application/zip';

    const legalEntityType = watch('entityType');

    const isCanada = billingCountry === NCACountries.Canada;
    const isMexico = billingCountry === NCACountries.Mexico;
    const maxFileSizeInBytes = 12 * 1000000;

    const storeFiles = (fileData) => {
        const fileState = validateFileInformation(fileData, maxFileSizeInBytes);
        if (fileState === 'undefined' || fileState === 'valid') {
            setShowUploadError(false);
            setShowFileSizeError(false);
            dispatch(setFileSection(fileData));
        } else if (fileState === 'file-size-error') {
            setShowUploadError(false);
            setShowFileSizeError(true);
        } else {
            setShowFileSizeError(false);
            setShowUploadError(true);
        }
    };

    return (
        <FormSection
            testId={'shipping-information-section'}
            sectionHeader={<Trans i18nKey="shippingInformation">Shipping Information</Trans>}
        >
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={12} data-testid="shipped-or-delivered">
                    <RadioInput
                        label={
                            <Trans i18nKey="shippedOrDelivered">
                                Do you need your cans shipped to you or will you be picking them up?
                            </Trans>
                        }
                        name="shipType"
                        testId="shipType"
                        control={control}
                        rules={{ required: isRequired }}
                        radioControls={[
                            {
                                value: ShipType.Shipped,
                                label: <Trans i18nKey="shipped">Shipped</Trans>
                            },
                            {
                                value: ShipType.Pickup,
                                label: <Trans i18nKey="pickUp">Pick Up</Trans>
                            }
                        ]}
                        error={errors.shipType}
                        errors={errors}
                        defaultValue=""
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                    <TextInput
                        type="text"
                        label={<Trans i18nKey="palletConfiguration">Pallet Configuration</Trans>}
                        name="palletConfiguration"
                        testId="palletConfiguration"
                        control={control}
                        rules={''}
                        error={errors.palletConfiguration}
                        errors={errors}
                        styles={classes.helperText}
                        inputProps={{
                            'data-testid': 'pallet-configuration',
                            maxLength: 255
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                    <TextInput
                        type="text"
                        label={
                            <Trans i18nKey="anySpecialEquipmentNeeds">
                                Any Special Equipment Needs?
                            </Trans>
                        }
                        name="specialEquipment"
                        testId="specialEquipment"
                        control={control}
                        rules={''}
                        error={errors.specialEquipment}
                        errors={errors}
                        styles={classes.helperText}
                        inputProps={{
                            'data-testid': 'special-equipment',
                            maxLength: 255
                        }}
                    />
                </Grid>
            </Grid>
            {(isMexico || isCanada) && (
                <>
                    <Grid item xs={12}>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.subsectionHeader}>
                            <Trans i18nKey="identificationInformation">
                                Identification Information
                            </Trans>
                        </Typography>
                    </Grid>
                    {isMexico && (
                        <>
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={6}>
                                    <RadioInput
                                        label={<Trans i18nKey="cfdi">CFDI</Trans>}
                                        name="cfdi"
                                        testId="cfdi"
                                        control={control}
                                        rules={{ required: isRequired }}
                                        radioControls={[
                                            {
                                                value: 'Yes',
                                                label: <Trans i18nKey="yes">Yes</Trans>
                                            },
                                            { value: 'No', label: <Trans i18nKey="no">No</Trans> }
                                        ]}
                                        error={errors.cfdi}
                                        errors={errors}
                                        defaultValue=""
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        type="text"
                                        label={<Trans i18nKey="taxId">Shipping Name</Trans>}
                                        name="taxId"
                                        testId="taxId"
                                        control={control}
                                        rules={{ required: isRequired }}
                                        error={errors.taxId}
                                        errors={errors}
                                        styles={classes.helperText}
                                        inputProps={{
                                            'data-testid': 'tax-id',
                                            maxLength: 13
                                        }}
                                    />
                                    <FormHelperText className={classes.errorText}>
                                        {errors.taxId && errors.taxId.type === 'required' && (
                                            <ErrorMessage
                                                errors={errors}
                                                name={'taxId'}
                                                message={isRequired}
                                            />
                                        )}
                                    </FormHelperText>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={12}>
                                    <RadioInput
                                        label={
                                            <Trans i18nKey="legalEntityOrPrivateEntrepreneur">
                                                Are you a Legal Entity or Private Party
                                                Entrepreneur?
                                            </Trans>
                                        }
                                        name="entityType"
                                        testId="entityType"
                                        control={control}
                                        rules={{ required: isRequired }}
                                        radioControls={[
                                            {
                                                value: EntityType.Legal,
                                                label: (
                                                    <Trans i18nKey="legalEntity">
                                                        Legal Entity
                                                    </Trans>
                                                )
                                            },
                                            {
                                                value: EntityType.Entrepreneur,
                                                label: (
                                                    <Trans i18nKey="entrepreneur">
                                                        Entrepreneur
                                                    </Trans>
                                                )
                                            }
                                        ]}
                                        error={errors.entityType}
                                        errors={errors}
                                        defaultValue=""
                                    />
                                </Grid>
                            </Grid>
                        </>
                    )}
                    {isCanada && (
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={6}>
                                <RadioInput
                                    label={
                                        <Trans i18nKey="importerOfRecord">Importer Of Record</Trans>
                                    }
                                    name="importer"
                                    testId="importer"
                                    control={control}
                                    rules={{ required: isRequired }}
                                    radioControls={[
                                        {
                                            value: Importer.Ball,
                                            label: <Trans i18nKey="ball">Ball</Trans>
                                        },
                                        {
                                            value: Importer.Customer,
                                            label: <Trans i18nKey="customer">Customer</Trans>
                                        }
                                    ]}
                                    error={errors.importer}
                                    errors={errors}
                                    defaultValue=""
                                />
                                <FormHelperText className={classes.errorText}>
                                    {errors.importer && errors.importer.type === 'required' && (
                                        <ErrorMessage
                                            errors={errors}
                                            name={'importer'}
                                            message={isRequired}
                                        />
                                    )}
                                </FormHelperText>
                            </Grid>
                        </Grid>
                    )}
                </>
            )}
            {isMexico && legalEntityType && legalEntityType !== '' && (
                <>
                    <Grid item xs={12}>
                        <Divider className={classes.divider} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.subsectionHeader}>
                            <Trans i18nKey="requiredDocuments">Required Documents</Trans>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            <Trans i18nKey="reqDocInstructionHeader">
                                You must provide the following documents:
                            </Trans>
                        </Typography>
                    </Grid>
                    {legalEntityType === EntityType.Legal && (
                        <Grid item xs={12}>
                            <ul>
                                <li>
                                    <Typography variant="body1">
                                        <Trans i18nKey="legalEntReqDocInstruction1">
                                            Operating Agreement
                                        </Trans>
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body1">
                                        <Trans i18nKey="legalEntReqDocInstruction2">
                                            Legal Representative Empowerment Letter (if not included
                                            in Operating Agreement)
                                        </Trans>
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body1">
                                        <Trans i18nKey="legalEntReqDocInstruction3">
                                            Legal Representative ID
                                        </Trans>
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body1">
                                        <Trans i18nKey="legalEntReqDocInstruction4">
                                            SAT Registration
                                        </Trans>
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body1">
                                        <Trans i18nKey="legalEntReqDocInstruction5">
                                            Company Proof of Address
                                        </Trans>
                                    </Typography>
                                </li>
                            </ul>
                        </Grid>
                    )}
                    {legalEntityType === EntityType.Entrepreneur && (
                        <Grid item xs={12}>
                            <ul>
                                <li>
                                    <Typography variant="body1">
                                        <Trans i18nKey="entrepreneurReqDocInstruction1">
                                            SAT Situation Letter (Mexico IRS document)
                                        </Trans>
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body1">
                                        <Trans i18nKey="entrepreneurReqDocInstruction2">
                                            INE card (Mexico Official ID)
                                        </Trans>
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body1">
                                        <Trans i18nKey="entrepreneurReqDocInstruction3">
                                            Proof of address (phone bill or service bill)
                                        </Trans>
                                    </Typography>
                                </li>
                            </ul>
                        </Grid>
                    )}
                    <Grid item xs={12} className={classes.sectionMargin}>
                        <FileUpload
                            styles={uploadStyles}
                            fileTypes={acceptedFileTypes}
                            name="requiredDocumentsUpload"
                            testId="required-documents-upload"
                            onUploadAttempt={storeFiles}
                            fileReset={false}
                            enableDelete={true}
                            invalidMessage={t(
                                'invalidIntakeFile',
                                'This file type is invalid. Please attach a .zip file.'
                            )}
                        />
                    </Grid>
                    {showFileSizeError && (
                        <Grid
                            item
                            xs={12}
                            className={classes.error}
                            data-testid="file-size-upload-error"
                        >
                            <Typography>
                                <Trans i18nKey="onFileSizeUploadError">
                                    File must be below 12MB
                                </Trans>
                            </Typography>
                        </Grid>
                    )}
                    {showUploadError && (
                        <Grid
                            item
                            xs={12}
                            className={classes.error}
                            data-testid="file-upload-error"
                        >
                            <Typography>
                                <Trans i18nKey="onUploadFileError">
                                    *Please upload a valid zip file
                                </Trans>
                            </Typography>
                        </Grid>
                    )}
                </>
            )}
        </FormSection>
    );
};

export default OnboardShippingInformationSection;
