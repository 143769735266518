import React, { useState } from 'react';
import { Link } from '../../../store/reducers/glp';
import { Card, CardHeader, Icon, CardContent, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Image from '../atoms/Image';

interface Props {
    header: string;
    description?: string;
    links: Link[];
    styles: any;
}

const CanResearchTile = ({ header, description, links, styles }: Props) => {
    const classes = styles();
    const storageValue = localStorage.getItem(header) === 'true';
    const [visited, setVisited] = useState(storageValue);
    const [hovering, setHovering] = useState(false);
    const iconUrl =
        (links ? (hovering ? links[0].icon?.hoverUrl : links[0].icon?.defaultUrl) : '') || '';

    const handleClick = () => {
        if (!visited) {
            const setBool = !visited;
            setVisited(setBool);
            localStorage.setItem(header, setBool.toString());
        }
    };

    return (
        <section data-testid={header}>
            <div>
                <Card
                    elevation={0}
                    className={classes.root}
                    onMouseEnter={() => setHovering(true)}
                    onMouseLeave={() => setHovering(false)}
                >
                    <RouterLink
                        to={links ? `/content${links[0].destination}` : '/'}
                        onClick={handleClick}
                        className={classes.link}
                    >
                        <CardHeader
                            className={classes.cardHeader}
                            action={
                                <Icon aria-label="header-icon">
                                    {visited ? (
                                        <CheckCircleOutlineIcon
                                            className={classes.visitedIcon}
                                            data-testid="checked"
                                        />
                                    ) : (
                                            <RadioButtonUncheckedIcon
                                                className="header-icon"
                                                data-testid="unchecked"
                                            />
                                        )}
                                </Icon>
                            }
                        />
                        <CardContent className={classes.textFields}>
                            <Image src={iconUrl} alt={header} className={classes.icon} />
                            <Typography className="header-text">
                                {header}
                            </Typography>
                            {description && (
                                <Typography variant="body1" className={classes.description}>
                                    {description}
                                </Typography>
                            )}
                        </CardContent>
                    </RouterLink>
                </Card>
            </div>
        </section>
    );
};

export default CanResearchTile;
