import React, { useEffect, useState } from 'react';
import { makeStyles, Grid, Typography, Link } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import { Link as RouterLink } from 'react-router-dom';
import { ballBlue, black, blackWeight, boldWeight } from '../../../../themes/globalConstants';
import EditProductionOrderRow from './EditProductionOrderRow';
import WarningAlert from '../../../reusable/atoms/WarningAlert';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import EditProductionOrderColumns from './EditProductionOrderColumns';
import { generateKey } from '../../../../utility/helpers/order-helpers';
import { ProductionOrder, ProductionOrderLine } from '../../../../store/reducers/makeit-dashboard';
import clsx from 'clsx';
import ErrorAlert from '../../../reusable/atoms/ErrorAlert';

interface Props {
    lines: ProductionOrderLine[];
    order: ProductionOrder;
    onRemoveItem: (item: ProductionOrderLine) => void;
    onItemUpdates: (item: ProductionOrderLine) => void;
    warning: boolean;
    warningMessage: string;
    warningTranslation: string;
    deliveryOrderWarning: boolean;
}

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        width: '100%',
        '& .MuiToolbar-root': {
            minHeight: 0,
            padding: '0 0.125em',
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                }
            }
        },
        '& .MuiTableSortLabel-root': {
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em'
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTablePagination-root': {
            '& .MuiTablePagination-caption': {
                marginLeft: '1em',
                fontWeight: boldWeight,
                color: black
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                '&:nth-child(1)': {
                    width: '12%'
                },
                '&:nth-child(2)': {
                    width: '25%'
                },
                '&:nth-child(4)': {
                    width: '13%'
                },
                '&:nth-child(7)': {
                    width: '13%'
                },
                '&:nth-child(7), &:nth-child(8)': {
                    borderLeft: 'none'
                }
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word',
            padding: 8
        }
    },
    linkSpacer: {
        marginRight: '.25em'
    },
    spacer: {
        marginLeft: '1em',
        marginRight: '1em'
    },
    linkedPO: {
        textDecoration: `underline ${ballBlue}`
    }
}));

const EditProductionOrderGrid = ({
    lines,
    onRemoveItem,
    onItemUpdates,
    warning,
    order,
    warningMessage,
    warningTranslation,
    deliveryOrderWarning
}: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [columns, setColumns] = useState<any>([]);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const deliveryOrderTranslation = () => {
        return (
            <Typography>
                <Trans i18nKey="oneOrMoreDeliveryOrdersAttached">
                    One or more products cannot be edited because of tied delivery order(s). View
                    details for those tied delivery orders in the
                </Trans>{' '}
                <Link
                    to={`/production-order/${order?.productionOrderId}`}
                    component={RouterLink}
                    underline="none"
                    data-testid="production-order-link"
                    className={clsx(classes.linkedPO, classes.linkSpacer)}
                >
                    <Trans i18nKey="productionOrderDetails">Production Order Details</Trans>
                </Link>
            </Typography>
        );
    };

    useEffect(() => {
        setColumns(EditProductionOrderColumns.getEditProductionOrderColumns(cultureCode));
    }, [cultureCode]);

    const CustomToolbarOptions = new ToolbarOptions({
        customItems: (
            <Grid container>
                <Grid container item xs={12}>
                    <WarningAlert
                        showWarning={deliveryOrderWarning}
                        warningMessage={deliveryOrderTranslation()}
                    />
                </Grid>
                <Grid container xs={12} data-testid="warning-container">
                    <ErrorAlert
                        showError={warning}
                        errorMessage={t(warningTranslation, warningMessage)}
                    />
                </Grid>
            </Grid>
        ),
        exportButton: false,
        printButton: false,
        advancePagination: false,
        searchText: false
    });

    const EditPOTableRow = ({ row }: { row: Partial<ProductionOrderLine> }) => {
        return (
            <EditProductionOrderRow
                key={row.productionOrderLineId}
                item={row as ProductionOrderLine}
                onRemoveItem={onRemoveItem}
                onItemUpdates={onItemUpdates}
            />
        );
    };

    return (
        <Grid container className={classes.tableWrapper} key={generateKey('edit-po-table')}>
            {lines && (
                <DataGrid
                    columns={columns}
                    dataSource={lines}
                    gridName="Edit PO Grid"
                    rowComponent={EditPOTableRow}
                    toolbarOptions={CustomToolbarOptions}
                />
            )}
        </Grid>
    );
};

export default EditProductionOrderGrid;
