import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { makeStyles, Box, Typography, Tabs, Tab, Grid } from '@material-ui/core';
import {
    small,
    blackWeight,
    inactiveTabBlue,
    lato,
    xl,
    black,
    medium,
    white,
    addFormItemButtonBlue,
    ballDrkBlue,
    ltBlueGrey
} from '../../../../themes/globalConstants';
import UserAdminPendingRegistrationTab from './UserAdminPendingRegistrationTab';
import { useTranslation } from 'react-i18next';
import { enUS } from '../../../../utility/translations/locales';
import UserAdminUsersTab from './UserAdminUsersTab';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { UserDashboardState } from '../../../../store/reducers/user-admin';
import { useDispatch } from 'react-redux';
import { AuthState } from '../../../../store/reducers/auth';
import {
    getUserAdminPendingRegistrations,
    getUserAdminPendingRequests,
    loadAdminUsers
} from '../../../../store/root-actions';
import UserAdminPendingRequestsTab from './UserAdminPendingRequestsTab';
import { getUserCustomerAccounts } from '../../../../store/actions/customer-context';
import { CustomerContextState } from '../../../../store/reducers/customer-context';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

interface Props {
    dashboardLoading: Dispatch<SetStateAction<boolean>>;
    tabSelected?: UserAdminDashboardSelectedTab | string | null;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: '1em',
        position: 'relative',
        '& h2': {
            fontSize: xl,
            fontFamily: lato,
            fontWeight: blackWeight,
            color: theme.palette.secondary.main
        }
    },
    appBar: {
        width: '75%'
    },
    tabPanel: {
        width: '100%'
    },
    panelHeader: {
        marginBottom: '1em'
    },
    counter: {
        width: '2em',
        height: '2em',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '0.5em',
        fontSize: small,
        fontWeight: blackWeight,
        backgroundColor: ltBlueGrey,
        color: ballDrkBlue
    },
    selectedCounter: {
        backgroundColor: addFormItemButtonBlue,
        color: white
    },
    tabs: {},
    tabLabel: {
        backgroundColor: inactiveTabBlue,
        height: '56px',
        borderTopLeftRadius: '0.625em',
        borderTopRightRadius: '0.625em',
        '&:not(:first-of-type)': {
            marginLeft: '8px'
        },
        padding: '0 3em',
        color: ballDrkBlue,
        textTransform: 'none'
    },
    tabLabelText: {
        fontSize: medium,
        fontWeight: blackWeight
    },
    selectedTab: {
        backgroundColor: white,
        color: black,
        boxShadow: '0px 0px 6px rgba(185, 211, 220, 0.8), inset 0px -3px 0px #009BBB'
    },
    formLinkWrapper: {
        position: 'absolute',
        marginTop: '-4em',
        [theme.breakpoints.down('md')]: {
            position: 'inherit'
        }
    },
    formLinkIcon: {
        marginLeft: '0.25em'
    }
}));

const TabPanel = (props: TabPanelProps) => {
    const classes = useStyles();
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`user-admin-dashboard-tabpanel-${index}`}
            aria-labelledby={`user-admin-dashboard-tab-${index}`}
            {...other}
            className={classes.tabPanel}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
};

export enum UserAdminDashboardSelectedTab {
    PendingRequests = 0,
    PendingRegistration,
    ActiveUsers,
    InactiveUsers
}

export default function UserAdminDashboardTabs({ dashboardLoading, tabSelected }: Props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState<UserAdminDashboardSelectedTab>(
        resolveTabSelected(tabSelected)
    );
    const [tabCounter, setTabCounter] = useState({
        activeUsers: 0,
        inactiveUsers: 0,
        pendingRegistrations: 0,
        permissionChanges: 0
    });
    const { accessToken, permissions } = useTypedSelector<AuthState>((state) => state.auth);
    const dispatch = useDispatch();
    const { loading, activeUsers, inactiveUsers, pendingRegistrations, permissionChanges } =
        useTypedSelector<UserDashboardState>((state) => state.userAdminDashboard);
    const { selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );

    const TabLabel = (label: string, count: number, index: number) => {
        return (
            <Grid container justify="center" alignItems="center">
                <Typography className={classes.tabLabelText}>{label}</Typography>
                <Typography
                    component="span"
                    className={`${classes.counter} ${
                        selectedTab === index && classes.selectedCounter
                    }`}
                >
                    {count}
                </Typography>
            </Grid>
        );
    };

    function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
        setSelectedTab(newValue);
    }

    function a11yProps(index) {
        return {
            id: `user-admin-dashboard-tab-${index}`,
            'aria-controls': `user-admin-dashboard-tab-${index}`
        };
    }

    useEffect(() => {
        dashboardLoading(!!loading);

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    useEffect(() => {
        setSelectedTab(resolveTabSelected(tabSelected));
    }, [tabSelected]);

    function resolveTabSelected(value?: UserAdminDashboardSelectedTab | number | string | null) {
        const converted = value && !isNaN(+value) ? +value : 0;
        switch (converted) {
            case UserAdminDashboardSelectedTab.PendingRequests:
            case UserAdminDashboardSelectedTab.PendingRegistration:
            case UserAdminDashboardSelectedTab.ActiveUsers:
                return converted;
            default:
                return UserAdminDashboardSelectedTab.PendingRequests;
        }
    }

    useEffect(() => {
        if (accessToken && permissions?.length) {
            dispatch(getUserAdminPendingRequests());
            dispatch(getUserAdminPendingRegistrations());
            dispatch(loadAdminUsers());
            dispatch(getUserCustomerAccounts());
        }
    }, [dispatch, accessToken, permissions]);

    useEffect(() => {
        if (
            selectedAccountId &&
            activeUsers &&
            inactiveUsers &&
            pendingRegistrations &&
            permissionChanges
        ) {
            // Ignore users from Inactive user tab count if they're present in Active tab
            const activeUsersForAccountId = activeUsers.filter((user) =>
                user.permissions?.some((permission) =>
                    permission.accountIds.includes(selectedAccountId)
                )
            );
            const activeUsernames = activeUsersForAccountId.map((user) => user.userName);
            const filteredInactiveUsers = inactiveUsers.filter(
                (inactiveUser) => !activeUsernames.includes(inactiveUser.userName)
            );

            setTabCounter({
                activeUsers: activeUsers.filter((user) =>
                    user.permissions?.some((permission) =>
                        permission.accountIds.includes(selectedAccountId)
                    )
                ).length,
                inactiveUsers: filteredInactiveUsers.filter((user) =>
                    user.permissions?.some((permission) =>
                        permission.accountIds.includes(selectedAccountId)
                    )
                ).length,
                pendingRegistrations: pendingRegistrations.filter((pendingRegistration) =>
                    pendingRegistration.permissions?.some(
                        (permission) => permission.accountId === selectedAccountId
                    )
                ).length,
                permissionChanges: permissionChanges.filter(
                    (permissionChange) => permissionChange.accountId === selectedAccountId
                ).length
            });
        }
    }, [selectedAccountId, activeUsers, inactiveUsers, pendingRegistrations, permissionChanges]);

    return (
        <div className={classes.root} data-testid="user-admin-dashboard-tabs">
            <Tabs
                value={selectedTab}
                onChange={handleChange}
                aria-label="user admin dashboard tabs"
                className={classes.tabs}
                scrollButtons="auto"
                variant="scrollable"
                indicatorColor="primary"
                textColor="primary"
            >
                <Tab
                    label={TabLabel(
                        t('pendingRequests', `${enUS.pendingRequests}`),
                        tabCounter.permissionChanges,
                        0
                    )}
                    {...a11yProps(0)}
                    className={`${classes.tabLabel} ${selectedTab === 0 && classes.selectedTab}`}
                />
                <Tab
                    label={TabLabel(
                        t('pendingRegistration', `${enUS.pendingRegistration}`),
                        tabCounter.pendingRegistrations,
                        1
                    )}
                    {...a11yProps(1)}
                    className={`${classes.tabLabel} ${selectedTab === 1 && classes.selectedTab}`}
                />
                <Tab
                    label={TabLabel(
                        t('activeUsers', `${enUS.activeUsers}`),
                        tabCounter.activeUsers,
                        2
                    )}
                    {...a11yProps(2)}
                    className={`${classes.tabLabel} ${selectedTab === 2 && classes.selectedTab}`}
                />
                <Tab
                    label={TabLabel(
                        t('inactiveUsers', `${enUS.inactiveUsers}`),
                        tabCounter.inactiveUsers,
                        3
                    )}
                    {...a11yProps(3)}
                    className={`${classes.tabLabel} ${selectedTab === 3 && classes.selectedTab}`}
                />
            </Tabs>
            <TabPanel value={selectedTab} index={0}>
                <UserAdminPendingRequestsTab />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
                <UserAdminPendingRegistrationTab />
            </TabPanel>
            <TabPanel value={selectedTab} index={2}>
                <UserAdminUsersTab />
            </TabPanel>
            <TabPanel value={selectedTab} index={3}>
                <UserAdminUsersTab inActive={true} />
            </TabPanel>
        </div>
    );
}
