import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    makeStyles,
    Menu,
    Radio,
    RadioGroup,
    Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ballGray, boldWeight } from '../../../../themes/globalConstants';
import Button from '../../../reusable/atoms/Button';

interface Props {
    onMenuBlur: () => void;
    onUnitChange: (unit: string[]) => void;
    handleUpdateViews: (views: string[]) => void;
    anchorEl: HTMLElement | null;
    currentViewTypes?: string[];
    currentUnit: string;
}

const useStyles = makeStyles((theme) => ({
    menuWrapper: {
        '& .MuiPopover-paper': {
            marginTop: '2em',
            paddingTop: '0.5em',
            paddingBottom: '0.5em',
            paddingLeft: '1em',
            marginRight: '2em',
            minWidth: '15em',
            borderTop: '1em'
        }
    },
    checkboxLabel: {
        marginTop: '0.75em',
        color: ballGray
    },
    btn: {
        fontWeight: boldWeight,
        color: ballGray
    },
    applyBtn: {
        color: theme.palette.primary.main,
        fontWeight: boldWeight
    },
    sectionLabel: {
        textTransform: 'uppercase',
        marginTop: '0.75em',
        color: ballGray,
        fontWeight: boldWeight
    }
}));

const ProductPlanningFilterMenu = ({
    onMenuBlur,
    onUnitChange,
    handleUpdateViews,
    anchorEl,
    currentViewTypes,
    currentUnit
}: Props) => {
    const classes = useStyles();
    const [selectedViews, setSelectedViews] = useState<string[]>([]);
    const { t } = useTranslation();

    const handleResetClicked = () => {
        handleUpdateViews(['']);
        onMenuBlur();
    };

    const handleCheckbox = (value: string) => {
        if (!!selectedViews && !selectedViews.includes(value)) {
            const updatedViews = selectedViews.filter((view) => view !== '');
            updatedViews.push(value);
            setSelectedViews([...updatedViews]);
        } else if (!!selectedViews) {
            const index = selectedViews.indexOf(value);
            const updatedViews = selectedViews;
            updatedViews.splice(index, 1);
            if (updatedViews.length === 0) updatedViews.push('');
            setSelectedViews([...updatedViews]);
        }
    };

    const handleApplyClicked = () => {
        if (selectedViews.length > 0) {
            handleUpdateViews(selectedViews);
        }
        onMenuBlur();
    };

    const handleChange = (event, unit) => {
        if (unit !== null) {
            onUnitChange(unit);
        }
    };

    useEffect(() => {
        if (currentViewTypes) {
            setSelectedViews([...currentViewTypes]);
        }
    }, [currentViewTypes]);

    return (
        <Menu
            className={classes.menuWrapper}
            keepMounted
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: -110,
                vertical: 'top'
            }}
            onClose={onMenuBlur}
        >
            <Typography className={classes.sectionLabel}>
                <Trans i18nKey="view">View</Trans>
            </Typography>
            <Grid container item xs={12} justify="flex-start">
                <Checkbox
                    onClick={() => handleCheckbox('CAN')}
                    checked={selectedViews.includes('CAN')}
                    value={'CAN'}
                />
                <Typography className={classes.checkboxLabel}>
                    <Trans i18nKey="cansBottles">Cans/Bottles</Trans>
                </Typography>
            </Grid>
            <Grid container item xs={12} justify="flex-start">
                <Checkbox
                    onClick={() => handleCheckbox('END')}
                    checked={selectedViews.includes('END')}
                    value={'END'}
                />
                <Typography className={classes.checkboxLabel}>
                    <Trans i18nKey="endsClosures">Ends/Closures</Trans>
                </Typography>
            </Grid>
            <Grid container item xs={12} justify="flex-start">
                <Checkbox
                    onClick={() => handleCheckbox('CAMPAIGN')}
                    checked={selectedViews.includes('CAMPAIGN')}
                    value={'CAMPAIGN'}
                />
                <Typography className={classes.checkboxLabel}>
                    <Trans i18nKey="campaignProducts">Campaign Products</Trans>
                </Typography>
            </Grid>
            <Grid container item xs={12} justify="flex-start">
                <Checkbox
                    onClick={() => handleCheckbox('AGING')}
                    checked={selectedViews.includes('AGING')}
                    value={'AGING'}
                />
                <Typography className={classes.checkboxLabel}>
                    <Trans i18nKey="agingBalances">Aging Balances</Trans>
                </Typography>
            </Grid>
            <FormControl>
                <FormLabel id="radio-button-group-quantity-units" className={classes.sectionLabel}>
                    <Trans i18nKey="displayUnits">DISPLAY UNITS</Trans>
                </FormLabel>
                <RadioGroup
                    aria-labelledby="radio-button-group-quantity-units"
                    defaultValue={currentUnit}
                    name="radio-buttons-group"
                    onChange={handleChange}
                >
                    <FormControlLabel value="pallets" control={<Radio />} label={t('pallets', 'Pallets')} />
                    <FormControlLabel value="eaches" control={<Radio />} label={t('eaches', 'Eaches')} />
                </RadioGroup>
            </FormControl>
            <Grid container item xs={12} justify="space-between">
                <Button onClick={handleResetClicked} className={classes.btn}>
                    <Trans i18nKey="reset">Reset</Trans>
                </Button>
                <Button onClick={handleApplyClicked} disabled={selectedViews.length === 0} className={classes.applyBtn}>
                    <Trans i18nKey="apply">Apply</Trans>
                </Button>
            </Grid>
        </Menu>
    );
};

export default ProductPlanningFilterMenu;
