import {
    Grid,
    Typography,
    Button,
    makeStyles,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Modal from '../../../reusable/molecules/Modal';
import { formatDateAndFullTime } from '../../../../utility/helpers/date-helpers';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { cancelCapacityCheck, getCapacityCheckStatus } from '../../../../store/actions/capacity-checks';
import { boldWeight, large } from '../../../../themes/globalConstants';
import { CapacityCheckStatus } from '../../../../utility/services/capacity-check-service';
import { useDispatch } from 'react-redux';
import { CapacityCheckState } from '../../../../store/reducers/capacity-checks';
import ToasterAlert from '../../../reusable/molecules/ToasterAlert';
import { enUS } from '../../../../utility/translations/locales';

interface Props {
    open: boolean;
    onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
    tableHeader: {
        fontSize: 18,
        fontWeight: 'bold'
    },
    modalBody: {
        padding: '2em 1.875em'
    },
    viewProject: {
        fontSize: 12,
        cursor: 'pointer'
    },
    tableWrapper: {
        margin: '2em 0',
        '& .MuiTableCell-root': {
            fontSize: 12,
            fontWeight: 'normal'
        }
    },
    noBorder: {
        borderLeft: 'none'
    },
    alignRight: {
        textAlign: 'right'
    },
    action: {
        cursor: 'pointer',
        paddingRight: 0,
        minWidth: 'unset',
        '&:hover': {
            backgroundColor: 'white'
        }
    },
    subsection: {
        fontSize: large,
        fontWeight: boldWeight,
        letterSpacing: '0.2px',
        textAlign: 'left'
    }
}));

const ProjectsModal = (props: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { capacityChecks, status } = useTypedSelector<CapacityCheckState>((state) => state.capacityCheck);

    const [showError, setShowError] = useState<boolean>(false);

    const onCancelCapacityCheck = (opportunityId: string) => {
        dispatch(cancelCapacityCheck(opportunityId));
    };

    useEffect(() => {
        setShowError(status === 'error');
    }, [status]);

    return (
        <Modal
            open={props.open}
            close={props.onClose}
            title={<Trans i18nKey="myProjects">My Projects</Trans>}
            closeIcon={true}
            maxWidth="lg"
        >
            <Grid container className={classes.modalBody} data-testid="my-projects-modal">
                <Grid item>
                    <Typography className={classes.subsection}>
                        <Trans i18nKey="belowAreYourSavedProjects">
                            Below are your saved projects from the last 180 days.{' '}
                        </Trans>
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.tableWrapper}>
                    <TableContainer>
                        <Table aria-label="my projects table">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        colSpan={1}
                                        className={classes.noBorder}
                                        data-testid="project-name"
                                    >
                                        <Typography className={classes.tableHeader}>
                                            <Trans i18nKey="projectName">Project Name</Trans>
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        colSpan={1}
                                        className={classes.noBorder}
                                        data-testid="product-code"
                                    >
                                        <Typography className={classes.tableHeader}>
                                            <Trans i18nKey="productCode">Product Code</Trans>
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        colSpan={1}
                                        className={classes.noBorder}
                                        data-testid="capacity-check-status"
                                    >
                                        <Typography className={classes.tableHeader}>
                                            <Trans i18nKey="capacityCheckStatus">
                                                Capacity Check Status
                                            </Trans>
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        colSpan={1}
                                        className={classes.noBorder}
                                        data-testid="date-requested"
                                    >
                                        <Typography className={classes.tableHeader}>
                                            <Trans i18nKey="dateRequested">Date Requested</Trans>
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        colSpan={1}
                                        className={classes.noBorder}
                                        data-testid="cancel-capacity-check"
                                    />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {capacityChecks?.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell colSpan={1}>
                                            <Typography>
                                                {item.projectName}
                                            </Typography>
                                        </TableCell>
                                        <TableCell colSpan={1}>
                                            <Typography>
                                                {item.productName ?? item.endSize}
                                            </Typography>
                                        </TableCell>
                                        <TableCell colSpan={1}>
                                            <Typography>
                                                {t(getCapacityCheckStatus(item.status), '')}
                                            </Typography>
                                        </TableCell>
                                        <TableCell colSpan={1}>
                                            <Typography>
                                                {formatDateAndFullTime(item.submittedDate as string)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell colSpan={1}>
                                            {item.status !== CapacityCheckStatus.Canceled && (
                                                <Button
                                                    onClick={() => onCancelCapacityCheck(item.opportunityId)}
                                                    className={classes.action}
                                                    data-testid="delete-btn"
                                                >
                                                    <img
                                                        src={
                                                            process.env.PUBLIC_URL + '/assets/Trash.svg'
                                                        }
                                                        alt="Cancel Capacity Check Icon"
                                                        data-testid="capacity-trash-icon"
                                                    />
                                                </Button>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12} className={classes.alignRight}>
                    <Button variant="contained" color="primary" onClick={props.onClose}>
                        <Trans i18nKey="close">Close</Trans>
                    </Button>
                </Grid>
                <ToasterAlert
                    message={t('submitATMError', `${enUS.submitATMError}`)}
                    showAlert={showError}
                    dataTestId="capacity-check-cancel-error-alert"
                    severity={'error'}
                    adjustedPlacement={'center'}
                />
            </Grid>
        </Modal>
    );
};

export default ProjectsModal;
