import React, { useEffect, useState, useCallback } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { Activity, hasAdminPersonaForAccount } from '../../../utility/auth/useSecurity';
import { Trans, useTranslation } from 'react-i18next';
import UserAdminDashboardTabs from './components/UserAdminDashboardTabs';
import Button from '../../reusable/atoms/Button';
import { useHistory } from 'react-router-dom';
import { enUS } from '../../../utility/translations/locales';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { UserAdminState, UserDashboardState } from '../../../store/reducers/user-admin';
import { useQuery } from '../../../utility/helpers/query-helpers';
import ErrorAlert from '../../reusable/atoms/ErrorAlert';
import SuccessAlert from '../../reusable/atoms/SuccessAlert';
import WarningAlert from '../../reusable/atoms/WarningAlert';
import { useDispatch } from 'react-redux';
import { USER_ADMIN_ADD_USER_RESET_STATUS } from '../../../store/actions/action-types';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { getShipToAccounts } from '../../../store/actions/customer-context';

const useStyles = makeStyles((theme) => ({
    main: {
        padding: '0',
        marginTop: '1.750em'
    },
    addUserBtn: {
        borderRadius: 'unset',
        marginRight: '2em'
    },
    alertContainer: {
        marginTop: '2em',
        width: '90%'
    }
}));

export default function UserAdminDashboard() {
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedTab, setSelectedTab] = useState<string | null>(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
    const [isUserAdmin, setIsUserAdmin] = useState<boolean>(false);
    const { disableError } = useTypedSelector<UserDashboardState>(
        (state) => state.userAdminDashboard
    );
    const { addUserSubmitStatus } = useTypedSelector<UserAdminState>((state) => state.userAdmin);
    const { permissions } = useTypedSelector((state) => state.auth);
    const { accounts, selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );

    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const query = useQuery();
    const status = query.get('status');

    function onAddUser() {
        history.push('/add-new-user');
    }

    useEffect(() => {
        setSelectedTab(status);
    }, [status]);

    const clearSuccessMessage = useCallback(() => {
        setTimeout(() => {
            dispatch({ type: USER_ADMIN_ADD_USER_RESET_STATUS });
            setShowSuccessAlert(false);
        }, 5000);
    }, [dispatch]);

    useEffect(() => {
        if (addUserSubmitStatus === 'succeeded') {
            setShowSuccessAlert(true);
            clearSuccessMessage();
        }
    }, [addUserSubmitStatus, clearSuccessMessage]);

    useEffect(() => {
        if (permissions && selectedAccountId) {
            const hasUserAdminPersona = hasAdminPersonaForAccount(permissions, selectedAccountId);
            setIsUserAdmin(hasUserAdminPersona);

            if (!accounts?.length) {
                dispatch(getShipToAccounts());
            }
        }
    }, [dispatch, permissions, accounts, selectedAccountId]);

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('userAdmin', `${enUS.userAdmin}`),
                description: '',
                thinBanner: true,
                displayDropdown: true,
                completeDropdown: true
            }}
            activity={Activity.UserAdminDashboard}
            loading={loading}
        >
            <Grid container className={classes.main}>
                <Grid container justify="space-between">
                    {showSuccessAlert && (
                        <Grid
                            item
                            data-testid="new-user-added-success-alert"
                            className={classes.alertContainer}
                        >
                            <SuccessAlert
                                showSuccess={showSuccessAlert}
                                successMessage={t(
                                    'userAdminSuccessMessage',
                                    `${enUS.userAdminSuccessMessage}`
                                )}
                            />
                        </Grid>
                    )}
                    {!isUserAdmin ? (
                        <Grid
                            item
                            data-testid="new-user-added-success-alert"
                            className={classes.alertContainer}
                        >
                            <WarningAlert
                                warningMessage={t(
                                    'userAdminAccessRequired',
                                    'User Administrator permissions are required to make changes.'
                                )}
                                showWarning={!isUserAdmin}
                            />
                        </Grid>
                    ) : (
                        <Grid item>
                            <Button
                                type="button"
                                color="primary"
                                variant="outlined"
                                data-testid="add-user-btn"
                                onClick={onAddUser}
                                className={classes.addUserBtn}
                            >
                                <Trans i18nKey="addUser">${enUS.addUser}</Trans>
                            </Button>
                        </Grid>
                    )}
                </Grid>
                {disableError && (
                    <Grid
                        container
                        item
                        xs={12}
                        className={classes.alertContainer}
                        data-testid="disable-user-error"
                    >
                        <ErrorAlert
                            showError={true}
                            errorMessage={t('disableUserError', `${enUS.disableUserError}`)}
                        />
                    </Grid>
                )}
                <UserAdminDashboardTabs tabSelected={selectedTab} dashboardLoading={setLoading} />
            </Grid>
        </ProcessingPageTemplate>
    );
}
