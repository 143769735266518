import React, { useState } from 'react';
import { makeStyles, Tabs, Tab } from '@material-ui/core';
import TabPanel from '../atoms/TabPanel';
import { medium } from '../../../themes/globalConstants';

interface TabProps {
    header: string;
    content: any;
}

interface Props {
    tabs: TabProps[];
    className?: string;
}

const useStyles = makeStyles((theme) => ({
    tabStrip: {
        marginBottom: '4.25em',
        '& .MuiTab-root': {
            fontSize: medium,
            letterSpacing: 1,
            maxWidth: '25em'
        }
    }
}));

export default function ContentTabs({ className, tabs }: Props) {
    const classes = useStyles();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Tabs
                className={className ? className : classes.tabStrip}
                value={value}
                onChange={handleChange}
                data-testid="tabs"
                scrollButtons="auto"
                variant="scrollable"
                indicatorColor="primary"
                textColor="primary"
            >
                {tabs.map((tab, index) => (
                    <Tab label={tab.header} key={`${tab.header}-${index}`} />
                ))}
            </Tabs>
            {tabs.map((tab, index) => (
                <TabPanel
                    value={value}
                    index={index}
                    data-testid={`tab-panel-${index}`}
                    key={`tab-panel-${index}`}
                    label={tab.header}
                >
                    {tab.content}
                </TabPanel>
            ))}
        </>
    );
}
