import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
    CircularProgress,
    Grid,
    makeStyles,
    TextareaAutosize,
    Typography
} from '@material-ui/core';
import clsx from 'clsx';

import {
    boldWeight,
    brightGrey,
    large,
    red,
    successGreen,
    xl
} from '../../../../themes/globalConstants';
import rootTheme from '../../../../themes/rootTheme';

import Button from '../../../reusable/atoms/Button';
import Modal from '../../../reusable/molecules/Modal';

import { API_RESPONSE } from '../GraphicsIntakePage';

interface Props {
    onClose?: () => void;
    onContinue?: (message: string) => void;
    open: boolean;
    status: API_RESPONSE;
}

const useStyles = makeStyles({
    modal: {
        '& .MuiPaper-root': {
            maxWidth: '40.5em'
        },
        '& .MuiDialog-paperScrollPaper': {
            borderRadius: 'unset',
            overflow: 'hidden'
        },
        '& .MuiDialogTitle-root': {
            justifyContent: 'left'
        }
    },
    content: {
        textAlign: 'center',
        letterSpacing: '1px',
        color: brightGrey
    },
    textBuffer: {
        marginTop: '2em'
    },
    text: {
        marginTop: '0.625em',
        fontSize: '1.5rem',
        fontWeight: boldWeight,
        textAlign: 'left'
    },
    subsection: {
        fontSize: xl,
        fontWeight: boldWeight,
        letterSpacing: '0.13px',
        textAlign: 'left'
    },
    button: {
        height: 'auto',
        marginTop: '1em',
        fontSize: large,
        fontWeight: 'normal',
        textTransform: 'capitalize',
        borderRadius: 'unset',
        minWidth: '10em',
        marginLeft: '1em'
    },
    buttonContainer: {
        margin: 'auto',
        marginTop: '1em',
        width: 550
    },
    contentWrapper: {
        width: '100%',
        padding: '0 20px 30px 20px'
    },
    errorMessage: {
        color: red,
        marginTop: '0.25em'
    },
    successMessage: {
        color: successGreen,
        marginTop: '0.25em'
    },
    actionBar: {
        margin: '1.5em 0',
        padding: '0'
    },
    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '0.5em'
    },
    actionButtons: {
        display: 'flex'
    },
    textArea: {
        marginTop: '1em',
        width: '100% !important',
        boxSizing: 'border-box',
        padding: '18px 14px',
        fontSize: 14,
        border: '1px solid',
        borderColor: rootTheme.palette.secondary.light,
        fontFamily: "'Lato', 'Helvetica', 'Arial', sans-serif",
        '&:focus': {
            outline: 'none',
            border: '1px solid',
            borderColor: rootTheme.palette.primary.main
        }
    },
    charactersLeft: {
        marginTop: '-15px'
    },
    section: {
        marginBottom: '1.5em'
    }
});

const ShipToRequestModal = (props: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [requestMessage, setRequestMessage] = useState<string>('');

    const requestMessageMaxLength = 500;

    const onCloseModal = () => {
        if (props.onClose) props.onClose();
    };

    const onSaveRequestMessage = () => {
        setSubmitting(true);
        if (props.onContinue) props.onContinue(requestMessage);
    };

    const charactersLeftMessage = useMemo<string>(() => {
        const charactersLeft = requestMessageMaxLength - requestMessage.length;
        let message = t('charactersLeft', 'characters left');
        if (charactersLeft === 1) message = t('characterLeft', 'character left');

        return `${charactersLeft} ${message}`;
    }, [t, requestMessage]);

    const showResponseMessage = useMemo<boolean>(() => {
        return error || success;
    }, [error, success]);

    useEffect(() => {
        switch (props.status) {
            case API_RESPONSE.NONE:
                setSubmitting(false);
                break;
            case API_RESPONSE.PENDING:
                setSubmitting(true);
                break;
            case API_RESPONSE.SUCCESS:
                setSuccess(true);
                setSubmitting(false);
                break;
            case API_RESPONSE.ERROR:
                setError(true);
                setSubmitting(false);
                break;
            default:
                setSubmitting(false);
                setSuccess(false);
                setError(false);
                break;
        }
    }, [props.status]);

    return (
        <Modal
            open={props.open}
            close={onCloseModal}
            title={<Trans i18nKey="emptyShipToLocations">Empty Ship-To Location(s)</Trans>}
            closeIcon={false}
            fullWidth={true}
            className={clsx(classes.modal)}
            disableClosing={true}
        >
            {
                <Grid container className={classes.content}>
                    <Grid item className={classes.contentWrapper} data-testid="confirm-modal">
                        <Grid item className={classes.textBuffer}>
                            <Typography className={classes.subsection} data-testid="subheader">
                                <Trans i18nKey="noShipToLocation">
                                    You have not entered a Ship To Location in this draft. Click OK
                                    if you would like us to notify the customer.
                                </Trans>
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            spacing={2}
                            align-items="flex-start"
                            className={classes.section}
                        >
                            <Grid item xs={12}>
                                <TextareaAutosize
                                    aria-label="Message Content"
                                    data-testid="request-message"
                                    rowsMin={6}
                                    placeholder={t(
                                        'enterCustomMessage',
                                        'Enter a custom message...'
                                    )}
                                    value={requestMessage}
                                    onChange={(event: any) => {
                                        setRequestMessage(
                                            event.target.value.substring(0, requestMessageMaxLength)
                                        );
                                    }}
                                    className={classes.textArea}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    data-testid="characters-left-message"
                                    className={classes.charactersLeft}
                                >
                                    {charactersLeftMessage}
                                </Typography>
                            </Grid>
                        </Grid>

                        {submitting && (
                            <Grid item xs={12} alignItems="center" className={classes.actionBar}>
                                <Grid item xs={12} className={classes.spinningLoader}>
                                    <CircularProgress data-testId="submitting-spinner" />
                                </Grid>
                            </Grid>
                        )}
                        {!submitting && showResponseMessage && (
                            <Grid container>
                                <Grid item xs={12}>
                                    {error && (
                                        <Typography
                                            id="error-label"
                                            data-testid="error-label"
                                            className={classes.errorMessage}
                                        >
                                            <Trans i18nKey="requestShipToGraphicsError">
                                                Something went wrong while attempting to send your
                                                request. Please try again later.
                                            </Trans>
                                        </Typography>
                                    )}
                                    {success && (
                                        <Typography
                                            id="success-label"
                                            data-testid="success-label"
                                            className={classes.successMessage}
                                        >
                                            <Trans i18nKey="requestShipToGraphicsSuccess">
                                                Your request for the Ship To Location has been sent
                                                to the customer.
                                            </Trans>
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                        {!showResponseMessage ? (
                            <Grid
                                container
                                alignItems="center"
                                justify="flex-end"
                                className={clsx(classes.buttonContainer)}
                            >
                                <Grid item className={classes.actionButtons}>
                                    <Grid item>
                                        <Button
                                            type="button"
                                            variant="outlined"
                                            color="secondary"
                                            data-testid="close-btn"
                                            className={classes.button}
                                            onClick={onCloseModal}
                                            disabled={submitting}
                                        >
                                            <Trans i18nKey="noThanks">No Thanks</Trans>
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            type="button"
                                            variant="contained"
                                            color="primary"
                                            data-testid="submit-btn"
                                            className={classes.button}
                                            onClick={onSaveRequestMessage}
                                            disabled={submitting}
                                        >
                                            <Trans i18nKey="ok">OK</Trans>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid
                                container
                                alignItems="center"
                                justify="center"
                                className={clsx(classes.buttonContainer)}
                            >
                                <Grid item xs={12} className={classes.actionButtons}>
                                    <Grid item xs={12}>
                                        <Button
                                            type="button"
                                            variant="contained"
                                            color="primary"
                                            data-testid="ok-btn"
                                            className={classes.button}
                                            onClick={onCloseModal}
                                        >
                                            <Trans i18nKey="ok">OK</Trans>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            }
        </Modal>
    );
};

export default ShipToRequestModal;
