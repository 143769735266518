import { makeStyles, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import Modal from '../molecules/Modal';
import { useState, ReactNode, useEffect } from 'react';
import React from 'react';
import Button from '../atoms/Button';
import { brightGrey, boldWeight, xl, red, large } from '../../../themes/globalConstants';

interface Props {
    title?: ReactNode;
    open: boolean;
    logo?: boolean;
    continueText?: ReactNode;
    communications?: any;
    description?: ReactNode;
    messagesCallback;
}

const useStyles = makeStyles({
    modal: {
        whiteSpace: 'pre-wrap',
        '& .MuiPaper-root': {
            maxWidth: '40.5em'
        },
        '& .MuiDialog-paperScrollPaper': {
            borderRadius: 'unset',
            overflow: 'hidden'
        },
        '& .MuiDialogTitle-root': {
            justifyContent: 'left'
        },
        '& .MuiDialogTitle-root h2': {
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }
    },
    modalWidth: {
        '& .MuiPaper-root': {
            maxWidth: '55.5em'
        }
    },
    content: {
        textAlign: 'center',
        letterSpacing: '1px',
        color: brightGrey
    },
    textBuffer: {
        wordBreak: 'break-word',
        marginTop: '2em'
    },
    text: {
        marginTop: '0.5em',
        fontSize: '1.125rem',
        fontWeight: boldWeight,
        textAlign: 'left',
        color: 'black'
    },
    subsection: {
        fontSize: xl,
        fontWeight: boldWeight,
        letterSpacing: '0.13px',
        textAlign: 'left'
    },
    buttonLink: {
        textDecoration: 'none'
    },
    button: {
        height: 'auto',
        marginTop: '1.875em',
        fontSize: large,
        fontWeight: 'normal',
        textTransform: 'capitalize',
        borderRadius: 'unset',
        minWidth: '10em',
        marginLeft: '1em'
    },
    scrapItButton: {
        height: 'auto',
        marginTop: '1.875em',
        fontSize: large,
        fontWeight: 'normal',
        textTransform: 'capitalize',
        borderRadius: 'unset',
        minWidth: '10em',
        marginLeft: '16em'
    },
    successIcon: {
        height: '6.25em',
        width: '6.25em',
        marginTop: '1.25em'
    },
    buttonContainer: {
        marginTop: '.5em'
    },
    contentWrapper: {
        width: '100%',
        padding: '0 20px 30px 20px'
    },
    saveInput: {
        width: 500,
        margin: 'auto'
    },
    altBtnWrapper: {
        width: 550,
        margin: 'auto'
    },
    errorMessage: {
        color: red,
        marginTop: '0.25em'
    },
    actionBar: {
        margin: '1.5em 0',
        padding: '0'
    },
    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '0.5em'
    },
    actionButtons: {
        display: 'flex'
    }
});

const CommunicationMessagesModal = (props: Props) => {
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(props.open);

    const closeConfirmationModal = () => {
        props?.messagesCallback(props?.communications[messageIndex]);
        if (messageIndex > 0) {
            setMessageIndex(messageIndex - 1);
        } else setOpen(false);
    };

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const allMessages = props?.communications?.map((each) => each?.content);
    const allMessagesTitle = props?.communications?.map((each) => each?.title);
    const [messageIndex, setMessageIndex] = useState<number>(props?.communications?.length - 1);

    useEffect(() => {
        // This check handles a message being dismissed via banner when it's also displayed here as a modal
        if (allMessages && messageIndex >= 0 && !allMessages[messageIndex])
            setMessageIndex(messageIndex - 1);

        if (messageIndex < 0 && open) setOpen(false);
    }, [allMessages, messageIndex]);

    return (
        <>
            <Modal
                open={open}
                close={closeConfirmationModal}
                title={allMessagesTitle[messageIndex]}
                closeIcon={true}
                fullWidth={true}
                className={clsx(classes.modal)}
            >
                {
                    <Grid container className={classes.content}>
                        <Grid item className={classes.contentWrapper} data-testid="confirm-modal">
                            {props.logo && (
                                <Grid item>
                                    <Typography>
                                        <img
                                            className={classes.successIcon}
                                            src={
                                                process.env.PUBLIC_URL +
                                                '/assets/Confirmation_icon.svg'
                                            }
                                            alt="Confirmation Icon"
                                        />
                                    </Typography>
                                </Grid>
                            )}
                            <Grid item className={classes.textBuffer}>
                                <Typography className={classes.subsection} data-testid="subheader">
                                    {allMessages[messageIndex]}
                                </Typography>
                            </Grid>
                            <br />
                            {props.description && (
                                <Grid item>
                                    <Typography className={classes.text} data-testid="description">
                                        {props.description}
                                    </Typography>
                                </Grid>
                            )}
                            <Grid
                                item
                                xs={12}
                                alignItems="center"
                                container
                                justify="flex-end"
                                className={clsx(classes.buttonContainer)}
                            >
                                <Grid item className={undefined}>
                                    {props.continueText && (
                                        <Button
                                            type="button"
                                            variant="contained"
                                            color="primary"
                                            data-testid="continue-btn"
                                            onClick={() => {
                                                closeConfirmationModal();
                                            }}
                                            className={classes.button}
                                        >
                                            {props.continueText}
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Modal>
        </>
    );
};

export default CommunicationMessagesModal;
