import { PlanningAllocation } from '../../components/pages/ProductPlanningATM/models/PlanningAllocation';
import {
    PRODUCT_PLANNING_LOADING,
    PRODUCT_PLANNING_LOADED,
    PRODUCT_PLANNING_LOADING_ERROR,
    PRODUCT_PLANNING_UPDATE_RANGE,
    PRODUCT_PLANNING_LINKED_POS_LOADING,
    PRODUCT_PLANNING_LINKED_POS_LOADED,
    PRODUCT_PLANNING_SHIPPING_ITEM_LOADING,
    PRODUCT_PLANNING_SHIPPING_ITEM_LOADED,
    PRODUCT_PLANNING_SHIPPING_ITEM_LOADING_ERROR,
    PRODUCT_PLANNING_FILTER,
    PRODUCT_PLANNING_LINKED_PO_CLEARED,
    PRODUCT_PLANNING_SHIPPING_ITEM_CLEARED,
    PRODUCT_PLANNING_UPDATE_COLLAPSE,
    PRODUCT_PLANNING_UPDATE_QUANTITY_UNIT,
    PRODUCT_PLANNING_FROM_DASHBOARD_LINK,
    PRODUCT_PLANNING_RESET,
    PRODUCT_PLANNING_UPDATE_ALLOCATION_COLLAPSE
} from '../actions/action-types';

import { ProductionOrder } from './makeit-dashboard';
import { OrderProduct } from './orders-dashboard';
import { QuantityUnit } from './shipping-dashboard';

export enum TotalNDayRanges {
    nineWeeks = 63,
    seventeenWeeks = 119
}

export enum PlanningType {
    ProductionBalance,
    PalletTotal,
    Shipments,
    PendingPayments,
    Forecast,
    MakeIt,
    NetBalance,
    AvailableToTrade
}

export interface OrderDestination {
    shipToId: number;
    customerProductId?: string;
    customerProductName?: string;
}

export interface PendingPaymentPalletInfo {
    bucketQuantity: number;
    firstRequestedDateInBucket?: Date;
    bucketStartDate?: Date;
    bucketEndDate?: Date;
}

export interface OrderPlanningProduct extends OrderProduct {
    previousPalletTotals?: number[];
    previousPalletTotalsForecasts?: number[];
    previousProductionBalancePalletTotals?: number[];
    shipments: number[];
    productionBalance: number[];
    futureShipmentsTotal: number;
    intervalPalletsProducedTotals?: number[];
    intervalPalletsProducedTotalsForecasts?: number[];
    previousPendingPaymentPalletTotals?: PendingPaymentPalletInfo[];
    previousShipmentTotals?: number[];
    linkedProductionOrders?: ProductionOrder[];
    productDescription?: string;
    currentWeek?: number;
    weekOne?: number;
    weekTwo?: number;
    weekThree?: number;
    weekFour?: number;
    weekFive?: number;
    weekSix?: number;
    weekSeven?: number;
    weekEight?: number;
    weekNine?: number;
    weekTen?: number;
    weekEleven?: number;
    weekTweleve?: number;
    weekThirteen?: number;
    weekFourteen?: number;
    weekFifteen?: number;
    weekSixteen?: number;
    isActive?: boolean;
    isAged?: boolean;
}

export interface LinkedProductionOrderRequest {
    ShipToIds: number[];
    endDate: string[];
    statuses?: string[];
    paymentTerms: string[];
    prodOrderType?: string[];
}

export interface LinkedProductionOrder {
    product: OrderPlanningProduct;
    linkedProductionOrders?: ProductionOrder[];
}

export interface LinkedProductionOrder {
    product: OrderPlanningProduct;
    linkedProductionOrders?: ProductionOrder[];
}

export interface ShipmentDeliveryOrder {
    deliveryOrderId?: string;
    deliveryOrderNumber?: string;
    deliveryDateTime?: string;
    numberOfShipments?: number;
    palletQuantity?: number;
}

export interface ProductPlanningState {
    products?: OrderPlanningProduct[];
    linkedPOs?: LinkedProductionOrder;
    loading: boolean;
    loaded: boolean;
    range: number;
    view?: string[];
    units?: string[];
    error?: any;
    fromDashboardLink?: boolean;
    filteredProducts?: OrderPlanningProduct[];
    activeShippingItem?: ShipmentDeliveryOrder[];
    quantityUnit: QuantityUnit;
    copyToMakeItOrders?: ProductionOrder[];
    allocationsBySnoSku?: PlanningAllocation[];
}

const initialState: ProductPlanningState = {
    products: [],
    filteredProducts: [],
    range: TotalNDayRanges.nineWeeks,
    loading: false,
    loaded: false,
    activeShippingItem: undefined,
    quantityUnit: 'pallets',
    view: [''],
    copyToMakeItOrders: [],
    allocationsBySnoSku: []
};

const productPlanning = (state = initialState, action: any): ProductPlanningState => {
    switch (action.type) {
        case PRODUCT_PLANNING_LOADED:
            return {
                ...state,
                loaded: true,
                loading: false,
                products: action.products,
                filteredProducts: action.products,
                allocationsBySnoSku: action.allocationsBySnoSku
            };
        case PRODUCT_PLANNING_LOADING:
            return { ...state, loaded: false, loading: true, error: undefined };
        case PRODUCT_PLANNING_LOADING_ERROR:
            return { ...state, loaded: true, loading: false, error: action.error };
        case PRODUCT_PLANNING_UPDATE_RANGE:
            return { ...state, range: action.range };
        case PRODUCT_PLANNING_LINKED_POS_LOADING:
            return { ...state, loading: true, loaded: false };
        case PRODUCT_PLANNING_LINKED_POS_LOADED:
            return { ...state, loaded: true, loading: false, linkedPOs: action.linkedPOs };
        case PRODUCT_PLANNING_RESET:
            return {
                ...initialState
            };
        case PRODUCT_PLANNING_SHIPPING_ITEM_LOADING:
            return {
                ...state,
                loaded: false,
                loading: true,
                error: undefined,
                activeShippingItem: undefined
            };
        case PRODUCT_PLANNING_SHIPPING_ITEM_LOADED:
            return {
                ...state,
                loaded: true,
                loading: false,
                error: undefined,
                activeShippingItem: action.activeShippingItem
            };
        case PRODUCT_PLANNING_SHIPPING_ITEM_LOADING_ERROR:
            return {
                ...state,
                loaded: true,
                loading: false,
                error: action.error,
                activeShippingItem: undefined
            };
        case PRODUCT_PLANNING_FILTER:
            return {
                ...state,
                loaded: true,
                loading: false,
                error: undefined,
                filteredProducts: action.filteredProducts,
                view: action.view
            };

        case PRODUCT_PLANNING_SHIPPING_ITEM_CLEARED:
            return {
                ...state,
                loaded: true,
                loading: false,
                activeShippingItem: action.activeShippingItem
            };

        case PRODUCT_PLANNING_LINKED_PO_CLEARED:
            return {
                ...state,
                loaded: true,
                loading: false,
                linkedPOs: action.linkedPOs
            };

        case PRODUCT_PLANNING_UPDATE_COLLAPSE:
            return {
                ...state,
                products: action.products
            };

        case PRODUCT_PLANNING_UPDATE_ALLOCATION_COLLAPSE:
            return {
                ...state,
                allocationsBySnoSku: action.allocationsBySnoSku
            };

        case PRODUCT_PLANNING_UPDATE_QUANTITY_UNIT:
            return {
                ...state,
                quantityUnit: action.quantityUnit
            };

        case PRODUCT_PLANNING_FROM_DASHBOARD_LINK:
            return {
                ...state,
                fromDashboardLink: action.fromLink
            };

        default:
            return state;
    }
};

export default productPlanning;
