import React from 'react';
import { makeStyles, Typography, TableRow, TableCell, Grid, Link } from '@material-ui/core';
import { ClosedProductionOrderRow } from '../../../../store/reducers/make-it-summary';
import { Link as RouterLink } from 'react-router-dom';
import { boldWeight, medium } from '../../../../themes/globalConstants';
import { Trans } from 'react-i18next';
import { formatStatus, orderStatusCodeFormatter } from '../../../../utility/helpers/order-helpers';
import { resetProductionOrderState } from '../../../../store/actions/production-order-details';
import { useDispatch } from 'react-redux';
import { ProdOrderType } from '../../../../store/reducers/customer-context';

interface Props {
    order: ClosedProductionOrderRow;
    showCustomerPOCol: boolean;
    key?: number;
}

const useStyles = makeStyles((theme) => ({
    name: {
        fontWeight: boldWeight,
        fontSize: medium,
        letterSpacing: 0.17
    },
    newPOLink: {
        marginLeft: '.25em'
    }
}));

export default function MakeItClosedRow({ order, showCustomerPOCol }: Props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const resetLoadedOrder = () => {
        dispatch(resetProductionOrderState());
    };

    return (
        <>
            <TableRow hover={true} key={order.productionOrderId}>
                <TableCell>
                    <Grid container>
                        <Grid item xs={12} className={classes.name}>
                            <Typography
                                key={'ship-to-' + order.productionOrderId}
                                data-testid={'ship-to-name'}
                            >
                                {order.shipToName}
                            </Typography>
                            <Typography
                                key={'ship-to-address-' + order.productionOrderId}
                                data-testid={'ship-to-address'}
                            >
                                {order.shipToAddress}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                {showCustomerPOCol && (
                    <TableCell>
                        <Grid container>
                            <Grid item xs={12} className={classes.name}>
                                {order.shipToProdOrderType !==
                                    ProdOrderType.AuthorizationToManufacture && (
                                    <Typography data-testid={'customer-po-id'}>
                                        {order.customerProductionOrderId}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </TableCell>
                )}
                <TableCell>
                    <Grid container>
                        <Grid item xs={12} className={classes.name}>
                            <Link
                                to={`/production-order/${order.productionOrderId}`}
                                component={RouterLink}
                                underline="none"
                                onClick={resetLoadedOrder}
                            >
                                <Typography data-testid={'ball-po-number'}>
                                    {order.ballPONumber && !isNaN(order.ballPONumber)
                                        ? order.ballPONumber
                                        : ''}
                                </Typography>
                            </Link>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid container>
                        <Grid item xs={12} className={classes.name}>
                            {order.submittedDateString && (
                                <Typography data-testid={'submitted-date'}>
                                    {order.submittedDateString}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid container>
                        <Grid item xs={12} className={classes.name}>
                            {order.firstRequestedDateString && (
                                <Typography data-testid={'requested-date'}>
                                    {order.firstRequestedDateString}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid container>
                        <Grid item xs={12} className={classes.name}>
                            <Typography data-testid={'order-quantity'}>
                                {order.orderQuantityString}
                            </Typography>
                            <Typography data-testid={'order-eaches-quantity'}>
                                {order.orderQuantityEachesString}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid container>
                        <Grid item xs={12} className={classes.name}>
                            {!order.newProductionOrderNumber && (
                                <Typography data-testid={'status'}>
                                    <Trans i18nKey={orderStatusCodeFormatter(order.status)}>
                                        {order.status}
                                    </Trans>
                                </Typography>
                            )}
                            {order.newProductionOrderNumber && (
                                <>
                                    <Typography>
                                        <Trans i18nKey={orderStatusCodeFormatter(order.status)}>
                                            {formatStatus(order.status)}
                                        </Trans>{' '}
                                        /
                                    </Typography>
                                    <Typography data-testid={'new-po-number'}>
                                        <Trans i18nKey="replacedBy">Replaced By</Trans>:
                                        <Link
                                            to={`/production-order/${order.newProductionOrderId}`}
                                            component={RouterLink}
                                            underline="none"
                                            onClick={resetLoadedOrder}
                                            className={classes.newPOLink}
                                        >
                                            {order.newProductionOrderNumber}
                                        </Link>
                                    </Typography>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid container>
                        <Grid item xs={12} className={classes.name}>
                            <Typography>{order.closedString}</Typography>
                        </Grid>
                    </Grid>
                </TableCell>
            </TableRow>
        </>
    );
}
