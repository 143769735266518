import React, { ReactElement, ReactNode } from 'react';
import Header from '../reusable/molecules/Header';
import Footer from '../reusable/molecules/Footer';
import { makeStyles, Container, AppBar, useTheme, useMediaQuery } from '@material-ui/core';
import PageBanner from '../../components/reusable/molecules/PageBanner';
import SubheaderBar from '../reusable/atoms/SubheaderBar';
import MobileHeader from '../reusable/molecules/MobileHeader';
import MobileFooter from '../reusable/molecules/MobileFooter';
import { containerMaxWidth } from '../../themes/globalConstants';

interface Props {
    key?: any;
    title?: string;
    children?: ReactNode;
    subheader?: ReactNode;
    banner?: any;
    loading?: boolean;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    container: {
        maxWidth: containerMaxWidth,
        padding: 0
    },
    content: {
        padding: '2em 0.625em'
    },
    toolbar: theme.mixins.toolbar
}));

export function NavigationalPageTemplate({
    children,
    title,
    subheader,
    banner,
    loading
}: Props): ReactElement {
    const classes = useStyles();
    //Use different menu for small screens
    const theme = useTheme();
    const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const smScreens = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div className={classes.root}>
            <AppBar position="sticky">
                {xsScreen ? (
                    <MobileHeader title={title} loading={loading} />
                ) : (
                    <Header title={title} loading={loading} />
                )}
            </AppBar>
            {banner && (
                <section>
                    <PageBanner {...banner} />
                </section>
            )}
            {subheader && <SubheaderBar>{subheader}</SubheaderBar>}
            <Container className={classes.container}>
                <div className={classes.content}>{children}</div>
            </Container>
            {smScreens ? <MobileFooter /> : <Footer />}
        </div>
    );
}
