import moment from 'moment';
import { getMondayOfWeek } from '../../components/pages/MakeIt/utilities/make-it-utils';
import { Account, ProdOrderType } from '../../store/reducers/customer-context';
import { ProductionOrderDraftLine } from '../../store/reducers/make-it-drafts';
import { MakeItBulkATMOrder } from '../../store/reducers/makeit-bulk-atm';
import { ProductionOrder } from '../../store/reducers/makeit-dashboard';

export const formatProductionOrderDraftLines = (orders: ProductionOrder[]) => {
    return orders.map((order) => {
        return {
            ...order,
            requestedDate: order.lines[0].requestedDate
        } as ProductionOrderDraftLine;
    });
};

export const formatProductionOrdersAsBulkATMOrder = (
    orders: ProductionOrder[]
): { orders: MakeItBulkATMOrder[]; weeks: moment.Moment[] } => {
    let atmWeeks: moment.Moment[] = [];
    const formattedOrders = orders.map((order) => {
        const atmWeek = moment.utc(order.lines[0].requestedDate);
        atmWeeks.push(atmWeek);
        return {
            customerProductionOrderId: order.customerProductionOrderId,
            productionOrderId: order.productionOrderId,
            productionOrderNumber: order.productionOrderNumber,
            weekStart: getMondayOfWeek(atmWeek).format('MM/DD/YYYY'),
            shipToId: order.shipToId,
            lines: order.lines.map((line) => ({
                ...line,
                isCustomerProductIdDistinct: true
            })),
            canQuantityPallets: order.canQuantityPallets,
            canQuantitySKUs: order.canQuantitySKUs,
            canQuantityEaches: order.canQuantityEaches,
            endQuantityPallets: order.endQuantityPallets,
            endQuantitySKUs: order.endQuantitySKUs,
            endQuantityEaches: order.endQuantityEaches,
            createdBy: order.createDate,
            submittedBy: order.submittedBy,
            createDate: order.createDate,
            atmWeekStart: getMondayOfWeek(atmWeek).toString(),
            status: order.status
        } as MakeItBulkATMOrder;
    });

    // filter out duplicate weeks from the Moment[]
    atmWeeks = atmWeeks.filter((week, index) => {
        return atmWeeks.findIndex((valueFirstIndex) => week.isSame(valueFirstIndex)) === index;
    });

    return {
        orders: formattedOrders,
        weeks: atmWeeks
    };
};

export const isLargeCustomerShipToId = (shipToId: string, shipToAccounts: Account[] | undefined): boolean => {
    return !!shipToAccounts
        ?.filter((account) => account.prodOrderType === ProdOrderType.AuthorizationToManufacture)
        .some((account) => account.accountId.includes(shipToId));
};
