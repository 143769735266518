import React from 'react';
import { medium, blackWeight, xl } from '../../../themes/globalConstants';
import { useTranslation, Trans } from 'react-i18next';
import { ShipmentDeliveryOrder } from '../../../store/reducers/product-planning';
import { generateKey } from '../../../utility/helpers/order-helpers';
import {
    Grid,
    makeStyles,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Link
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { getWeek } from '../../../utility/helpers/date-helpers';
import { formatNumberWithLocale } from '../../../utility/helpers/formatters';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { RegionCultureState } from '../../../store/reducers/region-culture';

interface Props {
    deliveryOrders: ShipmentDeliveryOrder[];
    weekNumber: number;
    showEaches: boolean;
    quantityPerPallet?: number;
}

const useStyles = makeStyles((theme) => ({
    main: {
        fontSize: medium,
        padding: '1em'
    },
    weekText: {
        marginLeft: '1em'
    },
    columnHeaders: {
        fontSize: xl,
        fontWeight: blackWeight
    }
}));

const ShipmentsModal = ({ deliveryOrders, weekNumber, showEaches, quantityPerPallet }: Props) => {
    const classes = useStyles();
    const date = getWeek(weekNumber);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    useTranslation();

    return (
        <Grid className={classes.main}>
            <Grid item xs={12}>
                <Typography variant="body1" className={classes.weekText} data-testid="week-of-date">
                    <Trans i18nKey="weekOfWithDate">Week of </Trans>
                    {date}
                </Typography>
            </Grid>
            <Grid container item xs={12}>
                <Table
                    size="small"
                    aria-label="linked delivery orders table"
                    key={generateKey('delivery-orders-table')}
                    data-testid="linked-delivery-orders-table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.columnHeaders} data-testid="order-number">
                                <Trans i18nKey="deliveryOrder">Delivery Order</Trans>
                            </TableCell>
                            <TableCell className={classes.columnHeaders} data-testid="order-date">
                                <Trans i18nKey="deliveryOrderDates">Delivery Order Date(s)</Trans>
                            </TableCell>
                            <TableCell className={classes.columnHeaders} data-testid="shipments">
                                <Trans i18nKey="shipments">Shipments</Trans>
                            </TableCell>
                            <TableCell className={classes.columnHeaders} data-testid="pallets">
                                <Trans i18nKey="pallets">Pallets</Trans>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {deliveryOrders.map((row, i) => {
                            return (
                                <TableRow key={'deliveryOrders-table-row' + i}>
                                    <TableCell>
                                        <Link
                                            to={`/delivery-order/${row.deliveryOrderId}`}
                                            component={RouterLink}
                                            underline="always"
                                        >
                                            <Typography>
                                                {row.deliveryOrderNumber ?? row.deliveryOrderId}
                                            </Typography>
                                        </Link>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{row.deliveryDateTime}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{row.numberOfShipments}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{row.palletQuantity}</Typography>
                                        <Typography>
                                            {showEaches &&
                                                quantityPerPallet &&
                                                row.palletQuantity &&
                                                formatNumberWithLocale(
                                                    cultureCode,
                                                    row.palletQuantity * quantityPerPallet
                                                ) + ' ea.'}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    );
};

export default ShipmentsModal;
