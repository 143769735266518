import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import { ballGray } from '../../../../themes/globalConstants';
import {
    LinkedProductionOrders,
    DeliveryShipment
} from '../../../../store/reducers/shipping-dashboard';
import ShipmentLoadDetailGrid from './ShipmentLoadDetailGrid';
import { formatStatus, orderStatusCodeFormatter } from '../../../../utility/helpers/order-helpers';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { formatNumberWithLocale } from '../../../../utility/helpers/formatters';
import clsx from 'clsx';
import { getSimpleFormattedDate, getSimpleFormattedTime } from '../../../../utility/helpers/date-helpers';

interface Props {
    shipment: DeliveryShipment;
    index: number;
    modeOfTransport: string;
    linkedProductionOrders?: LinkedProductionOrders;
}

const useStyles = makeStyles((theme) => ({
    main: {
        marginBottom: '1.5em'
    },
    subtitle: {
        marginTop: '1.5em',
        color: ballGray
    },
    item: {
        paddingLeft: '1em'
    },
    shipmentData: {
        paddingBottom: '1em'
    },
    longValue: {
        overflowWrap: 'anywhere'
    },
    shipmentNumber: {
        fontSize: '1.25em'
    }
}));

const ShipmentDetails = (props: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const skuCount = props.shipment.shipmentSKUs;
    const deliveryDate = getSimpleFormattedDate('', props.shipment.deliveryDateTime);
    const deliveryTime = getSimpleFormattedTime('', props.shipment.deliveryDateTime);

    const shipmentDate = getSimpleFormattedDate(t('pending', 'Pending'), props.shipment?.shipDateTime);
    const shipTime = getSimpleFormattedTime(t('pending', 'Pending'), props.shipment?.shipDateTime);

    const getSkuCountText = () => {
        const skuText = skuCount === 1 ? 'SKU' : 'SKUs';
        const typeText = props.shipment.canQuantitySKUs
            ? t('cansBottles', 'Cans/Bottles')
            : t('endsClosures', 'Ends/Closures');
        return `${skuCount} ${skuText} (${typeText}) `;
    };

    return (
        <Grid container className={classes.main}>
            <Grid container item xs={12} justify="flex-start">
                <Grid item xs={12}>
                    <Typography
                        variant="subtitle2"
                        className={clsx(classes.subtitle, classes.shipmentNumber)}
                    >
                        <Trans i18nKey="shipment">Shipment</Trans> {props.shipment?.truckId}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item xs={12} justify="flex-start" className={classes.shipmentData}>
                <Grid item xs={2} className={classes.item} data-testid="pickup-date">
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="deliveryDate">Delivery Date</Trans>
                    </Typography>
                    <Typography variant="body1">
                        {deliveryDate}
                    </Typography>
                </Grid>
                <Grid item xs={2} className={classes.item} data-testid="pickup-time">
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="deliveryTime">Delivery Time</Trans>
                    </Typography>
                    <Typography variant="body1">{deliveryTime}</Typography>
                </Grid>
                <Grid item xs={1} className={classes.item} data-testid="status">
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="status">Status</Trans>
                    </Typography>
                    <Typography variant="body1">
                        <Trans i18nKey={orderStatusCodeFormatter(props.shipment.status)}>
                            {formatStatus(props.shipment.status)}
                        </Trans>
                    </Typography>
                </Grid>
                <Grid item xs={1} className={classes.item} data-testid="quantity">
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="quantity">Quantity</Trans>
                    </Typography>
                    <Typography variant="body1" data-testid="shipment-quantity-pallets">
                        {props.shipment.shipmentQuantity + ' PL'}
                    </Typography>
                    <Typography
                        variant="body1"
                        className={classes.longValue}
                        data-testid="shipment-quantity-eaches"
                    >
                        {props.shipment.shipmentQuantityEaches &&
                            formatNumberWithLocale(
                                cultureCode,
                                props.shipment.shipmentQuantityEaches
                            ) + ' ea.'}
                    </Typography>
                </Grid>
                <Grid item xs={2} className={classes.item} data-testid="products">
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="products">Products</Trans>
                    </Typography>
                    <Typography variant="body1">{getSkuCountText()}</Typography>
                </Grid>
            </Grid>
            <Grid container item xs={12} justify="flex-start" className={classes.shipmentData}>
                <Grid item xs={2} className={classes.item} data-testid="shipment-date">
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="shipmentDate">Shipment Date</Trans>
                    </Typography>
                    <Typography variant="body1">
                        {shipmentDate}
                    </Typography>
                </Grid>
                <Grid item xs={2} className={classes.item} data-testid="shipment-time">
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="shipmentTime">Shipment Time</Trans>
                    </Typography>
                    <Typography variant="body1">{shipTime}</Typography>
                </Grid>
                <Grid item xs={2} className={classes.item} data-testid="shipped-from">
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="shipmentShippedFrom">Shipped From</Trans>
                    </Typography>
                    <Typography variant="body1">{props.shipment.warehouseName}</Typography>
                </Grid>
                <Grid item xs={2} className={classes.item} data-testid="carrier">
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="shipmentCarrier">Carrier</Trans>
                    </Typography>
                    <Typography variant="body1">{props.shipment.carrier}</Typography>
                </Grid>
            </Grid>
            {props.shipment && (
                <ShipmentLoadDetailGrid
                    loads={props.shipment?.loads}
                    linkedProductionOrders={props.linkedProductionOrders}
                />
            )}
        </Grid>
    );
};

export default ShipmentDetails;
