import React from 'react';
import PdfPageTemplate from '../../../templates/PdfPageTemplate';
import { Document, Page, Text, View, BlobProvider, Image } from '@react-pdf/renderer';
import pdfStyles from '../../../../themes/pdfStyles';
import {
    formatFromDatetime,
    formattedDateWithTimezone,
    getSimpleFormattedDate,
    getSimpleFormattedTime
} from '../../../../utility/helpers/date-helpers';
import moment from 'moment';
import { DeliveryOrder } from '../../../../store/reducers/shipping-dashboard';
import { isIE } from '../../../../utility/helpers/user-helpers';
import { isProductEndType } from '../../../../utility/helpers/order-helpers';
import { Trans, useTranslation } from 'react-i18next';
import { wrapEaches, wrapReferenceNumber } from '../../../../utility/helpers/shipment-helpers';
import { formatNumberWithLocale } from '../../../../utility/helpers/formatters';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';

interface Props {
    title: string;
    deliveryOrder: DeliveryOrder;
    shipToAddress: string;
    shipToName: string;
}

const CancelDeliveryOrderConfirmationPDF = ({
    title,
    deliveryOrder,
    shipToAddress,
    shipToName
}: Props) => {
    const ballLogo = process.env.PUBLIC_URL + '/assets/Ball_RGB.jpg';
    const sourceLogo = process.env.PUBLIC_URL + '/assets/TheSource_Logo.png';
    const addressString = shipToName + ' ' + shipToAddress;
    const { t } = useTranslation();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const products = (shipment) =>
        shipment.loads?.map((product, index) => {
            const referenceNumber = product.referenceNumber
                ? wrapReferenceNumber(product.referenceNumber)
                : undefined;
            const shipmentEachesQuantity =
                product.palletQuantity && product.quantityPerPallet
                    ? product.palletQuantity * product.quantityPerPallet
                    : 0;
            const loadEaches = wrapEaches(
                formatNumberWithLocale(cultureCode, shipmentEachesQuantity)
            );

            return (
                <>
                    <View style={[pdfStyles.tableRow]} key={index}>
                        <View style={pdfStyles.borderLessTableColNarrow8}>
                            <Text style={[pdfStyles.tableCell2]}>
                                {product.displayId} / {product.graphicIdAndVersion}
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow11}>
                            <Text style={[pdfStyles.tableCell2]}>
                                {product.displayName} / {product.description}
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow13}>
                            <Text style={[pdfStyles.tableCell2]}>
                                {product.palletQuantity}
                                {' PL'}
                            </Text>
                            {loadEaches}
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow12}>
                            <Text style={[pdfStyles.tableCell2]}>{product.releaseNumber}</Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow12}>
                            <Text style={[pdfStyles.tableCell2]}>{product.fillerLine}</Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow12}>{referenceNumber}</View>
                        {!deliveryOrder.customerPickup && (
                            <View style={[pdfStyles.borderLessTableCol]}>
                                {shipment.deliveryInstructions &&
                                    shipment.deliveryInstructions !== '' ? (
                                    <Text style={pdfStyles.tableCell2}>
                                        {shipment.deliveryInstructions}
                                    </Text>
                                ) : (
                                    <Text style={pdfStyles.tableCell2}>{t('none', 'None')}</Text>
                                )}
                            </View>
                        )}
                    </View>
                    <View style={pdfStyles.divider25} />
                    <View style={[pdfStyles.tableRow]}>
                        <View style={pdfStyles.borderLessTableColNarrow8}></View>
                        <View style={pdfStyles.borderLessTableColWide4}>
                            <Text style={pdfStyles.tableCell4}>
                                {t('customerPoNumber', 'Customer PO #')}{' '}
                                {product.purchaseOrderNumber}
                            </Text>
                        </View>
                    </View>
                    {shipment.loads.length !== index + 1 && <View style={pdfStyles.dividerGray} />}
                </>
            );
        });

    const shipments = deliveryOrder.shipments?.map((shipment, index) => {
        const deliveryDate = getSimpleFormattedDate('', shipment.deliveryDateTime);
        const deliveryTime = getSimpleFormattedTime('', shipment.deliveryDateTime);
        const isEndType = isProductEndType(shipment.shipmentType);

        return (
            <>
                <View style={[pdfStyles.borderlessTable, pdfStyles.paragraph]}>
                    <View style={pdfStyles.tableRow}>
                        <View style={pdfStyles.borderLessTableColNarrow12}>
                            <Text style={pdfStyles.header10}>
                                <Trans i18nKey="shipment">SHIPMENT</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow9}>
                            <Text style={pdfStyles.header10}>
                                <Trans i18nKey="date">DATE</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow9}>
                            <Text style={pdfStyles.header10}>
                                <Trans i18nKey="time">TIME</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow2}>
                            <Text style={pdfStyles.header10}>
                                <Trans i18nKey="shipmentCarrier">CARRIER</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow5}>
                            <Text style={pdfStyles.header10}>
                                <Trans i18nKey="shipmentShippedFrom">SHIPPED FROM</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow2}>
                            <Text style={pdfStyles.header10}>
                                <Trans i18nKey="update">UPDATE</Trans>
                            </Text>
                        </View>
                        {shipment.shipmentType && isEndType ? (
                            <View style={pdfStyles.borderLessTableColNarrow2}>
                                <Text style={pdfStyles.header10}>
                                    <Trans i18nKey="endQuantity">END QUANTITY</Trans>
                                </Text>
                            </View>
                        ) : (
                            <View style={pdfStyles.borderLessTableColNarrow2}>
                                <Text style={pdfStyles.header10}>
                                    <Trans i18nKey="canQuantity">CAN QUANTITY</Trans>
                                </Text>
                            </View>
                        )}
                    </View>
                    <View style={pdfStyles.tableRow}>
                        <View style={pdfStyles.borderLessTableColNarrow12}>
                            <Text style={[pdfStyles.header9]}>{index + 1}</Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow9}>
                            <Text style={[pdfStyles.subtitle10]}>{deliveryDate}</Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow9}>
                            <Text style={[pdfStyles.subtitle10]}>
                                {deliveryTime}
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow2}>
                            <Text style={[pdfStyles.subtitle10]}>{shipment.carrier}</Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow5}>
                            <Text style={[pdfStyles.subtitle10]}>{shipment.warehouseName}</Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow2}>
                            <Text style={pdfStyles.subtitle10}>
                                <Trans i18nKey="cancelled">Cancelled</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow2}>
                            <Text style={[pdfStyles.subtitle10]}>
                                {shipment.shipmentQuantity} PL / {shipment.shipmentSKUs} SKU
                            </Text>
                            <Text style={[pdfStyles.subtitle10]}>
                                {shipment.shipmentQuantityEaches &&
                                    formatNumberWithLocale(
                                        cultureCode,
                                        shipment.shipmentQuantityEaches
                                    )}
                                {' ea.'}
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={[pdfStyles.borderlessTable, pdfStyles.paragraph]}>
                    <View style={pdfStyles.tableRow}>
                        <View style={pdfStyles.borderLessTableColNarrow8}>
                            <Text style={[pdfStyles.header2]}>
                                <Trans i18nKey="productGraphicId">PRODUCT ID / GRAPHIC ID</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow11}>
                            <Text style={[pdfStyles.header2]}>
                                <Trans i18nKey="descriptionCharacteristics">
                                    PRODUCT DESCRIPTION / CHARACTERISTICS
                                </Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow13}>
                            <Text style={[pdfStyles.header2]}>
                                <Trans i18nKey="quantity">QUANTITY</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow12}>
                            <Text style={[pdfStyles.header2]}>
                                <Trans i18nKey="releaseNumberPDF">RELEASE #</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow12}>
                            <Text style={[pdfStyles.header2]}>
                                <Trans i18nKey="fillerLineNumber">FILLER LINE #</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow12}>
                            <Text style={[pdfStyles.header2]}>
                                <Trans i18nKey="referenceNumberPDF">REFERENCE #</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableCol}>
                            <Text style={[pdfStyles.header2]}>
                                <Trans i18nKey="deliveryInstructions">DELIVERY INSTRUCTIONS</Trans>
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.dividerGray} />
                    {products(shipment)}
                </View>
            </>
        );
    });

    const myDoc = (
        <Document title={title}>
            <Page size="A4" style={pdfStyles.page} orientation={'landscape'}>
                <View style={pdfStyles.logoSection} fixed>
                    <Image src={sourceLogo} style={pdfStyles.sourceLogo} />
                    <Image src={ballLogo} style={pdfStyles.ballLogo} />
                </View>
                <View style={pdfStyles.divider} fixed />

                {/* Header */}
                <View style={[pdfStyles.flexSection, pdfStyles.pageHeader]}>
                    <View style={pdfStyles.borderLessTableColNarrowTitle}>
                        <Text style={[pdfStyles.header9]}>
                            <Trans i18nKey="shipIt">SHIP IT</Trans>
                        </Text>
                    </View>
                    <View style={pdfStyles.borderLessTableColWide8}>
                        <View style={[pdfStyles.flexSection, pdfStyles.pageHeader]}>
                            <View style={pdfStyles.borderLessTableColNarrow10}>
                                <Text style={[pdfStyles.fieldLabel2, pdfStyles.header2]}>
                                    <Trans i18nKey="deliveryOrder">Delivery Order</Trans>
                                </Text>
                            </View>
                            <View style={pdfStyles.borderLessTableColNarrow10}>
                                <Text style={[pdfStyles.fieldLabel2, pdfStyles.header2]}>
                                    <Trans i18nKey="dateSubmitted">Date Submitted</Trans>
                                </Text>
                            </View>
                            <View style={pdfStyles.borderLessTableColNarrow10}>
                                <Text style={[pdfStyles.fieldLabel2, pdfStyles.header2]}>
                                    <Trans i18nKey="revised">Revised</Trans>
                                </Text>
                            </View>
                            <View style={pdfStyles.borderLessTableColNarrow10}>
                                <Text style={[pdfStyles.fieldLabel2, pdfStyles.header2]}>
                                    <Trans i18nKey="canQuantity">Can Quantity</Trans>
                                </Text>
                            </View>
                            <View style={pdfStyles.borderLessTableColNarrow10}>
                                <Text style={[pdfStyles.fieldLabel2, pdfStyles.header2]}>
                                    <Trans i18nKey="endQuantity">End Quantity</Trans>
                                </Text>
                            </View>
                        </View>
                        <View style={[pdfStyles.flexSection, pdfStyles.pageHeader]}>
                            <View style={pdfStyles.borderLessTableColNarrow10}>
                                <Text style={[pdfStyles.header8]}>
                                    {deliveryOrder.deliveryOrderNumber}
                                </Text>
                            </View>
                            <View style={pdfStyles.borderLessTableColNarrow10}>
                                <Text style={[pdfStyles.header8]}>
                                    {formattedDateWithTimezone(
                                        moment(deliveryOrder.submittedDate),
                                        'MM/DD/YYYY'
                                    )}
                                </Text>
                            </View>
                            <View style={pdfStyles.borderLessTableColNarrow10}>
                                <Text style={[pdfStyles.header8]}>
                                    {formatFromDatetime(deliveryOrder.deliveryWeekDate as string)}
                                </Text>
                            </View>
                            <View style={pdfStyles.borderLessTableColNarrow10}>
                                <Text style={[pdfStyles.header8]}>
                                    {deliveryOrder.canQuantityPallets} PL /{' '}
                                    {deliveryOrder.canQuantitySKUs} SKU
                                </Text>
                                <Text style={[pdfStyles.header8Lower]}>
                                    {deliveryOrder.canQuantityEaches &&
                                        formatNumberWithLocale(
                                            cultureCode,
                                            deliveryOrder.canQuantityEaches
                                        )}
                                    {' ea.'}
                                </Text>
                            </View>
                            <View style={pdfStyles.borderLessTableColNarrow10}>
                                <Text style={[pdfStyles.header8]}>
                                    {deliveryOrder.endQuantityPallets} PL /{' '}
                                    {deliveryOrder.endQuantitySKUs} SKU
                                </Text>
                                <Text style={[pdfStyles.header8Lower]}>
                                    {deliveryOrder.endQuantityEaches &&
                                        formatNumberWithLocale(
                                            cultureCode,
                                            deliveryOrder.endQuantityEaches
                                        )}
                                    {' ea.'}
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={pdfStyles.divider} />

                {/* Address Info */}
                <View style={pdfStyles.borderlessTable}>
                    <View style={pdfStyles.tableRow}>
                        <View style={[pdfStyles.borderLessTableCol1]}>
                            <Text style={pdfStyles.header2}>SHIP TO</Text>
                        </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                        <View style={[pdfStyles.borderLessTableCol1]}>
                            <Text style={pdfStyles.subtitle9}>{addressString}</Text>
                        </View>
                    </View>
                </View>

                {/* Shipments */}
                {shipments}

                <View style={[pdfStyles.fullWidth, pdfStyles.footer]} fixed>
                    <Text
                        style={pdfStyles.header2}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </View>
            </Page>
        </Document>
    );

    if (!isIE()) {
        return <PdfPageTemplate title={title}>{myDoc}</PdfPageTemplate>;
    } else {
        return (
            <BlobProvider document={myDoc}>
                {({ blob, url, loading, error }) => {
                    if (blob && window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
                        (window.navigator as any).msSaveOrOpenBlob(blob);
                    }
                    return null;
                }}
            </BlobProvider>
        );
    }
};

export default CancelDeliveryOrderConfirmationPDF;
