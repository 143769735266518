import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class UserAdminUsersColumns {
    static getAdminUserColumns = (culture: string, hasAdminPersona: boolean = false, inactiveUsers: boolean = false) => {
        return [
            createColumn('userName', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'email'),
                sortable: true,
                searchable: true
            }),
            createColumn('roles', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'rolesLocations'),
                sortable: true,
                visible: true,
                searchable: true
            }),
            createColumn('currentUserHasAdminPermission', {
                dataType: ColumnDataType.Boolean,
                visible: false,
                searchable: false
            }),
            createColumn('actions', {
                label: TranslationService.getTranslatedText(culture, 'actions'),
                visible: hasAdminPersona || !inactiveUsers,
            })
        ];
    };
}
