import Axios from 'axios';
import { AccountWithParent } from '../../store/reducers/pricing-breakdown';
import { createAPIQueryParam } from '../helpers/query-helpers';
import { ContractAndDocument } from '../../store/reducers/contract-summary';

export interface PricingRequest {
    billToIds: String[];
}

export interface PricingDataByBillTo {
    billToId: string;
    pricingDataByAccounts: {
        responses: any[];
        account: AccountWithParent;
    }[];
}

export default class PricingService {
    private static createConfig = (accessToken: string) => ({
        headers: { Authorization: `Bearer ${accessToken}` }
    });

    static getPricingData = (accessToken: string, pricingRequest: PricingRequest) => {
        const requestUrl = `${process.env.REACT_APP_PRICING_API}/pricing/data${createAPIQueryParam(
            pricingRequest
        )}`;
        return Axios.get<PricingDataByBillTo[]>(
            requestUrl,
            PricingService.createConfig(accessToken)
        );
    };

    static getPricingTermsAndConditions = (
        accessToken: string,
        accountIds: string[],
        language: string = 'English',
        isShipToRequest: boolean = false
    ) => {
        const queryParams = createAPIQueryParam({ accountId: accountIds });
        const urlPath = isShipToRequest ? 'GetTermsAndConditions/shipto' : 'GetTermsAndConditions';
        const requestUrl = `${process.env.REACT_APP_PRICING_API}/pricing/contracts/${urlPath}${queryParams}&language=${language}`;
        return Axios.get(requestUrl, PricingService.createConfig(accessToken));
    };

    static acknowledgeTermsAndConditionsDocument = (
        accessToken: string,
        document: ContractAndDocument,
        contactEmail: string
    ) => {
        const docLink = document.pdfUrl?.replaceAll('http://', '').replaceAll('https://', '');
        const requestBody = {
            accNumber: document.billTo,
            conEmail: contactEmail,
            docId: document.documentId,
            docLink
        };
        const requestUrl = `${process.env.REACT_APP_PRICING_API}/pricing/contracts/CreateTermsAndConditions`;
        return Axios.put<boolean>(
            requestUrl,
            requestBody,
            PricingService.createConfig(accessToken)
        );
    };
}
