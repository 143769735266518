import React, { useEffect, useState } from 'react';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import {
    boldWeight,
    medium,
    blackWeight,
    ballBlue,
    dateOptionInvalidGray,
    xxl
} from '../../../../themes/globalConstants';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { useTranslation } from 'react-i18next';
import { generateKey } from '../../../../utility/helpers/order-helpers';
import { LiquidTestSummaryState } from '../../../../store/reducers/liquid-test-summary';
import { LiquidIntakeForTable } from '../models/LiquidIntakeForTable';
import moment from 'moment';
import ClosedLiquidTestColumns from './ClosedLiquidTestColumns';
import ClosedLiquidTestRow from './ClosedLiquidTestRow';
import { getProjectNameBeverageName } from '../../../../utility/helpers/liquid-intake-helpers';
import TranslationService from '../../../../utility/services/translation-service';

const useStyles = makeStyles((theme) => ({
    action: {
        cursor: 'pointer'
    },
    name: {
        fontWeight: boldWeight,
        fontSize: medium,
        letterSpacing: 0.17
    },
    paper: {
        marginBottom: '1em'
    },
    titleContainer: {
        paddingTop: '1em',
        paddingLeft: '0.625em'
    },
    title: {
        color: theme.palette.secondary.main,
        fontSize: xxl,
        fontWeight: boldWeight
    },
    warranty: {
        paddingTop: '0.75em'
    },
    tableWrapper: {
        '& .MuiToolbar-root': {
            padding: 0,
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                },
                '&:first-of-type ': {
                    paddingLeft: 0
                }
            },
            '& > h2': {
                fontSize: '1.5em',
                fontWeight: boldWeight,
                color: theme.palette.secondary.main,
                paddingLeft: '0.625em'
            },
            '& .MuiInputBase-formControl': {
                border: `1px solid ${dateOptionInvalidGray}`,
                borderRadius: '.125em',
                '&:before': {
                    display: 'none'
                },
                '&:after': {
                    display: 'none'
                },
                '& svg': {
                    height: '.875em',
                    width: '.875em',
                    fill: ballBlue
                },
                '& .MuiIconButton-root': {
                    '&:hover': {
                        backgroundColor: 'transparent !important'
                    }
                },
                '& .MuiInput-input': {
                    paddingLeft: '.25em'
                }
            }
        },
        '& .MuiTableSortLabel-root': {
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em',
            width: '99%',
            justifyContent: 'space-between',
            '& .MuiSvgIcon-root': {
                margin: 0
            }
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableCell-head': {
            '&:first-of-type ': {
                width: '25em'
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                '&:nth-child(1)': {
                    width: '24%'
                },
                '&:nth-child(2)': {
                    width: '8%'
                },
                '&:nth-child(3)': {
                    width: '17%'
                },
                '&:nth-child(4)': {
                    width: '14%'
                },
                '&:nth-child(5)': {
                    width: '10%'
                },
                '&:nth-child(6)': {
                    width: '10%'
                },
                '&:nth-child(7)': {
                    width: '17%'
                }
            }
        }
    }
}));

const LiquidTestTab = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const options = new ToolbarOptions({
        advancePagination: false,
        exportButton: false,
        printButton: false
    });
    const { recentlyCompletedLiquidTests } = useTypedSelector<LiquidTestSummaryState>(
        (state) => state.liquidTestSummary
    );
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const [columns, setColumns] = useState<any>([]);
    const [closedLiquidIntakesForTable, setClosedLiquidIntakesForTable] = useState<
        LiquidIntakeForTable[] | undefined
    >(undefined);

    useEffect(() => {
        setColumns(ClosedLiquidTestColumns.getClosedLiquidTestColumns(cultureCode));
    }, [cultureCode]);

    useEffect(() => {
        if (recentlyCompletedLiquidTests) {
            const closedIntakes: LiquidIntakeForTable[] = [];
            recentlyCompletedLiquidTests.sort((a, b) => moment(b.createDate).diff(a.createDate));
            recentlyCompletedLiquidTests.forEach((intake, index) => {
                let closedIntake: LiquidIntakeForTable = {
                    ...intake,
                    projectNameBeverageName: getProjectNameBeverageName(
                        intake.projectName,
                        intake.beverageName
                    ),

                    sortIndex: index
                };
                closedIntakes.push(closedIntake);
            });
            setClosedLiquidIntakesForTable(closedIntakes);
        }
    }, [recentlyCompletedLiquidTests]);

    const ClosedRowComponent = ({ row }: { row: Partial<LiquidIntakeForTable> }) => {
        if (row) {
            return (
                <ClosedLiquidTestRow
                    liquidTest={row as LiquidIntakeForTable}
                    key={row.liquidTestId}
                />
            );
        } else {
            return null;
        }
    };

    return (
        <Paper elevation={2} className={classes.paper}>
            <div className={classes.titleContainer}>
                <Typography className={classes.title}>
                    {t('recentlyCompleted', 'Recently Completed (Last 90 days)') +
                        ' (' +
                        t('lastNinety', 'Last 90 days') +
                        ')'}
                </Typography>
                <Typography variant="body1" className={classes.warranty}>
                    {TranslationService.getTranslatedText(cultureCode, 'warrantyDetails')}
                </Typography>
            </div>
            <Grid className={classes.tableWrapper} key={generateKey('closed-intakes-tab')}>
                {closedLiquidIntakesForTable && (
                    <DataGrid
                        gridName="Open Liquid Tests"
                        columns={columns}
                        dataSource={closedLiquidIntakesForTable}
                        rowComponent={ClosedRowComponent}
                        toolbarOptions={options}
                    ></DataGrid>
                )}
            </Grid>
        </Paper>
    );
};

export default LiquidTestTab;
