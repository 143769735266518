import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class MakeItBulkATMConfirmationOrderSectionColumns {
    static getColumns = (culture: string, isRevision: boolean) => {
        return [
            createColumn('displayId', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'customerProductGraphicId'),
                visible: true
            }),
            createColumn('graphicIdAndVersion', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('displayName', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'productDescription')
            }),
            createColumn('coating', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'coating')
            }),
            createColumn('originalPalletQuantity', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'previousQuantitySubmitted'),
                visible: isRevision
            }),
            createColumn('palletQuantity', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(
                    culture,
                    isRevision ? 'revisedQuantityToPurchase' : 'quantityToPurchase'
                ),
                visible: true
            }),
            createColumn('fee', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'fee')
            }),
            createColumn('moqFees', {
                visible: false
            }),
            createColumn('quantityPerPallet', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('deleted', {
                dataType: ColumnDataType.Boolean,
                visible: false
            }),
            createColumn('productSku', {
                dataType: ColumnDataType.String,
                visible: false
            })
        ];
    };
}
