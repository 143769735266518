import { loadState, saveState } from '../../utility/helpers/sessionStorage';
import {
    NOTIFICATIONS_LOADING,
    NOTIFICATIONS_LOADED,
    NOTIFICATIONS_ERROR,
    NOTIFICATIONS_RESET,
    NOTIFICATIONS_DISMISS_NOTIFICATION
} from '../actions/action-types';

export interface AccountNotification {
    notificationId: string;
    shipToId: string;
    type?: string;
    content?: { [key: string]: string };
    updateDate: string;
    createDate: string;
    username: string;
    acknowledged?: boolean;
    acknowledgedDate?: string;
    expirationDate: string;
}

export interface NotificationsState {
    notifications: { [shipToId: string]: AccountNotification[] };
    loading: boolean;
    loaded: boolean;
    notificationDataLoaded: boolean;
    error?: any;
    dismissedNotifications: string[];
}

const initialState: NotificationsState = {
    notifications: {},
    loading: false,
    loaded: false,
    notificationDataLoaded: false,
    dismissedNotifications: []
};

const notifications = (
    state: NotificationsState = initialState,
    action: { type: string; [x: string]: any }
): NotificationsState => {
    switch (action.type) {
        case NOTIFICATIONS_LOADING:
            return { ...state, loading: true, loaded: false };
        case NOTIFICATIONS_LOADED:
            let dismissed = loadState('dismissedNotifications') ?? [];
            return {
                ...state,
                loading: false,
                loaded: true,
                notificationDataLoaded: true,
                notifications: action.notifications,
                dismissedNotifications: dismissed
            };
        case NOTIFICATIONS_ERROR:
            return { ...state, loading: false, notificationDataLoaded: true, error: action.error };
        case NOTIFICATIONS_DISMISS_NOTIFICATION:
            dismissed = state.dismissedNotifications;
            dismissed.push(action.notificationId);
            saveState('dismissedNotifications', dismissed);
            return {
                ...state,
                dismissedNotifications: [...dismissed]
            };
        case NOTIFICATIONS_RESET:
            return { ...initialState };
        default:
            return state;
    }
};

export default notifications;
