import {
    COMMUNICATION_SUMMARY_LOADING,
    COMMUNICATION_SUMMARY_LOADED,
    COMMUNICATION_SUMMARY_ERROR,
    COMMUNICATION_SUMMARY_RESET,
    COMMUNICATION_STATUS_UPDATE,
    COMMUNICATION_STATUS_UPDATE_ERROR,
    COMMUNICATION_STATUS_UPDATE_SUCCESS
} from '../actions/action-types';

export enum RecipientType {
    All = 'All',
    External = 'External',
    Internal = 'Internal'
}

export enum Region {
    NCA = 'NCA',
    EMEA = 'EMEA',
    SA = 'SA'
}

export enum DisplayPage {
    GlobalLandingPage = 'GlobalLandingPage',
    Dashboard = 'Dashboard',
    PlanItLandingPage = 'PlanItLandingPage',
    MakeItLandingPage = 'MakeItLandingPage',
    ShipItLandingPage = 'ShipItLandingPage',
    ProductPlanning = 'ProductPlanning',
    PayableLandingPage = 'PayableLandingPage',
    MakeItDrafts = 'MakeItDrafts',
    SubmitNewGraphics = 'SubmitNewGraphics',
    SubmitNewLiquidTest = 'SubmitNewLiquidTest'
}

export enum MessageType {
    Banner = 'Banner',
    Popup = 'Popup',
    Email = 'Email'
}

export interface CommunicationRegion {
    communicationRegionId?: number;
    communicationId?: number;
    region: Region;
}

export interface CommunicationDisplayPage {
    communicationDisplayPageId?: number;
    communicationId?: number;
    displayPage: DisplayPage;
}

export interface CommunicationMessageType {
    communicationMessageTypeId?: number;
    communicationId?: number;
    messageType: MessageType;
}

export interface Communication {
    communicationId?: number;
    title: string;
    content: string;
    startDateTime: Date;
    endDateTime: Date;
    recipientType: RecipientType;
    submitterName: string;
    submitterEmail: string;
    status: boolean;
    createdDate: Date;
    updatedDate: Date;
    communicationRegions: CommunicationRegion[];
    communicationDisplayPages: CommunicationDisplayPage[];
    communicationMessageTypes: CommunicationMessageType[];
}

export interface CommunicationManagementState {
    communications: Communication[];
    loading: boolean;
    loaded: boolean;
    error?: any;
    success?: boolean;
}

const initialState: CommunicationManagementState = {
    communications: [],
    loading: false,
    loaded: false,
    error: undefined,
    success: undefined
};

const communicationManagement = (
    state: CommunicationManagementState = initialState,
    action: { type: string; [x: string]: any }
): CommunicationManagementState => {
    switch (action.type) {
        case COMMUNICATION_SUMMARY_LOADING:
            return { ...state, loading: true, loaded: false, error: undefined, success: undefined };
        case COMMUNICATION_SUMMARY_LOADED:
            return {
                ...state,
                communications: action.communications,
                loading: false,
                loaded: true
            };
        case COMMUNICATION_SUMMARY_ERROR:
            return {
                ...state,
                loading: false,
                loaded: true,
                error: action.error
            };
        case COMMUNICATION_STATUS_UPDATE:
            return {
                ...state,
                loading: true,
                loaded: false,
                error: undefined,
                success: undefined
            };

        case COMMUNICATION_STATUS_UPDATE_ERROR:
            return {
                ...state,
                loading: false,
                loaded: true,
                error: action.error,
                success: undefined
            };

        case COMMUNICATION_STATUS_UPDATE_SUCCESS:
            return {
                ...state,
                communications: action.communications,
                loading: false,
                loaded: true,
                error: undefined,
                success: true
            };
        case COMMUNICATION_SUMMARY_RESET:
            return { ...initialState };
        default:
            return state;
    }
};

export default communicationManagement;
