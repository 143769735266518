import React, { ReactElement, useState } from 'react';
import { Prompt, useHistory } from 'react-router';
import CancelModal from '../../../reusable/molecules/CancelModal';
import { RouterConfirmationOverride } from '../../../../App';

interface Props {
    requireConfirmation: boolean;
}

export default function NavigationConfirmation({ requireConfirmation }: Props): ReactElement {
    const history = useHistory();

    const [allowNavigation, setAllowNavigation] = useState<boolean>(false);
    const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false);
    const [lastBlockedNavigationPath, setLastBlockedNavigationPath] = useState<string>('');

    function confirmCancel() {
        setAllowNavigation(true);
        setTimeout(() => {
            lastBlockedNavigationPath === 'POP'
                ? history.goBack()
                : history.push(lastBlockedNavigationPath);
        });
    }

    function handleBlockedNavigation(location) {
        if (!allowNavigation) {
            setLastBlockedNavigationPath(location);
            setCancelModalOpen(true);
            return false;
        }

        return false;
    }

    return (
        <>
            <CancelModal
                open={cancelModalOpen}
                onConfirmCancel={confirmCancel}
                onClose={() => setCancelModalOpen(false)}
            />

            {/* Browser Navigation Handler */}
            <Prompt
                when={!allowNavigation && requireConfirmation}
                message={(location, action) => {
                    if (action === 'POP') {
                        handleBlockedNavigation('POP');
                        return RouterConfirmationOverride.PreventBrowserBack;
                    } else {
                        return handleBlockedNavigation(location.pathname);
                    }
                }}
            />
        </>
    );
}
