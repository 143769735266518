import { Grid, makeStyles, MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { boldWeight } from '../../../themes/globalConstants';
import Link from '../atoms/Link';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { Region } from '../../../store/reducers/graphic-intake';
import { hasPersonas, Persona } from '../../../utility/auth/useSecurity';
import { AuthState } from '../../../store/reducers/auth';
import { GetFeatureFlag, FlagTypes } from '../../../utility/helpers/feature-flag';
import NavBarMenu, { MenuSection } from './NavBarMenu';
import NavBarTile from './NavBarTile';

interface Props {
    hideKBMaxFunctionality: boolean;
}

const useStyles = makeStyles((theme) => ({
    menuItems: {
        overflow: 'visible',
        width: 'fit-content'
    },
    linkMenuItem: {
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    link: {
        fontSize: 11,
        fontWeight: boldWeight,
        color: theme.palette.secondary.main,
        letterSpacing: '1px',
        textTransform: 'uppercase'
    }
}));

const ProductsNav = ({ hideKBMaxFunctionality }: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [menuLinks, setMenuLinks] = useState<MenuSection[]>([]);
    const [menuTiles, setMenuTiles] = useState<JSX.Element[]>([]);
    const { regionCode, cultureCode } = useTypedSelector<RegionCultureState>(
        (state) => state.regionCulture
    );
    const auth = useTypedSelector<AuthState>((state) => state.auth);
    const buildEstimateIcon = '/Build_Estimate_Nav_Icon.svg';
    const productPortfolioIcon = '/Product_Portfolio_Nav_Icon.svg';
    const newGraphicIcon = '/Submit_New_Graphic_Nav_Icon.svg';
    const liquidTestIcon = '/Liquid_Test_Nav_Icon.svg';
    const canSpecsIcon = '/Can_Specs_Nav_Icon.svg';
    const liquidsFeatureFlag = GetFeatureFlag(FlagTypes.Liquids);

    useEffect(() => {
        // more complex than needs to be for now but laying groundwork for future changes
        let links: MenuSection[] = [];
        if (regionCode !== Region.SA) {
            links = [
                {
                    key: 'previousSubmissions',
                    name: t('previousSubmissions', 'Previous Submissions'),
                    items: []
                }
            ];

            if (regionCode === Region.NA) {
                links[0].items!.push(
                    <MenuItem
                        key="graphicsInProgress"
                        disableGutters={true}
                        className={clsx(classes.linkMenuItem, classes.menuItems)}
                    >
                        <Link
                            component={RouterLink}
                            to="/graphics-summary"
                            underline="hover"
                            aria-label="graphics in progress"
                            className={classes.link}
                            data-testid="graphics-in-progress-link"
                        >
                            <Trans i18nKey="inProgressGraphicsStatuses">
                                In Progress Graphics Statuses
                            </Trans>
                        </Link>
                    </MenuItem>
                );
            }
            if (regionCode === Region.NA && liquidsFeatureFlag) {
                links[0].items!.push(
                    <MenuItem
                        key="submittedLiquidTests"
                        disableGutters={true}
                        className={clsx(classes.linkMenuItem, classes.menuItems)}
                    >
                        <Link
                            component={RouterLink}
                            to="/liquid-test-summary"
                            underline="hover"
                            aria-label="submitted liquid tests"
                            className={classes.link}
                        >
                            <Trans i18nKey="submittedLiquidTests">Submitted Liquid Tests</Trans>
                        </Link>
                    </MenuItem>
                );
            }
        } else {
            // SA region
            links = [
                {
                    key: 'products',
                    name: t('products', 'products'),
                    items: []
                }
            ];
            links[0].items!.push(
                <MenuItem
                    key="ballProductPortfolio"
                    disableGutters={true}
                    className={clsx(classes.linkMenuItem, classes.menuItems)}
                >
                    <Link
                        component={RouterLink}
                        to="/product-portfolio"
                        underline="hover"
                        aria-label="product portfolio"
                        className={classes.link}
                        data-testid="product-portfolio-link"
                    >
                        <Trans i18nKey="ballProductPortfolio">Ball Product Portfolio</Trans>
                    </Link>
                </MenuItem>,
                <MenuItem
                    key="specifications"
                    disableGutters={true}
                    className={clsx(classes.linkMenuItem, classes.menuItems)}
                >
                    <Link
                        component={RouterLink}
                        to="/content/NA/Pages/Can-Specifications"
                        underline="hover"
                        aria-label="specifications"
                        className={classes.link}
                        data-testid="can-specifications-link"
                    >
                        <Trans i18nKey="specifications">Specifications</Trans>
                    </Link>
                </MenuItem>
            );
        }

        setMenuLinks(links);
        // reload links when language is changed
    }, [regionCode, cultureCode]);

    useEffect(() => {
        const tiles: JSX.Element[] = [];

        if (regionCode === Region.NA) {
            if (!hideKBMaxFunctionality) {
                tiles.push(
                    <Grid item xs={4} key={'compare-and-build'}>
                        <NavBarTile
                            link="/compare-and-build"
                            translationKey={
                                regionCode === Region.NA ? 'buildAndEstimateNav' : 'buildNav'
                            }
                            defaultText="buildAndEstimateNavProducts"
                            icon={buildEstimateIcon}
                            isProduct={false}
                            secondaryText="Compare products, create cans, and see pricing options."
                            secondaryTranslationKey="compareProducts"
                        />
                    </Grid>
                );
            }
            tiles.push(
                <Grid item xs={4} key={'capacity-checks'}>
                    <NavBarTile
                        link="/product-portfolio"
                        translationKey={'productPortfolio'}
                        defaultText="Product Portfolio"
                        icon={productPortfolioIcon}
                        isProduct={false}
                        secondaryText="View information about product offerings."
                        secondaryTranslationKey="viewInfoProductOfferings"
                    />
                </Grid>
            );
            if (
                hasPersonas(
                    [
                        Persona.AllAccess,
                        Persona.MarketingMgmt,
                        Persona.ProcurementMgmt,
                        Persona.ArtMgmt,
                        Persona.GraphicsVendor,
                        Persona.PackagingMgmt,
                        Persona.ForecastMgmt,
                        Persona.WarehousingLogistics,
                        Persona.CoPacker
                    ],
                    auth
                )
            ) {
                tiles.push(
                    <Grid item xs={4} key={'graphics-intake'}>
                        <NavBarTile
                            link="/graphics-intake"
                            translationKey="submitNewGraphicLink"
                            defaultText="Submit New Graphic"
                            icon={newGraphicIcon}
                            isProduct={false}
                            secondaryText="Complete graphics submission to set up a new product."
                            secondaryTranslationKey="completeGraphicsSubmission"
                        />
                    </Grid>
                );
            }
            if (liquidsFeatureFlag) {
                tiles.push(
                    <Grid item xs={4} key={'liquid-test-intake'}>
                        <NavBarTile
                            link="/liquid-test-intake"
                            translationKey="submitNewLiquidTestLink"
                            defaultText="Submit New Liquid Test"
                            icon={liquidTestIcon}
                            isProduct={false}
                            secondaryText="Input new beverage information for testing."
                            secondaryTranslationKey="inputeNewBeverage"
                        />
                    </Grid>
                );
            }
            tiles.push(
                <Grid item xs={4} key={'can-specs'}>
                    <NavBarTile
                        link="/content/NA/Pages/Can-Specifications"
                        translationKey="canSpecifications"
                        defaultText="Can Specifications"
                        icon={canSpecsIcon}
                        isProduct={false}
                        secondaryText="Download product specification and pallet specification documents for cans and ends."
                        secondaryTranslationKey="downloadProductSpecs"
                    />
                </Grid>
            );
        }
        setMenuTiles(tiles);
    }, [regionCode]);

    return (
        <NavBarMenu menuName={<Trans i18nKey="products">Products</Trans>} sections={menuLinks}>
            <Grid container>{menuTiles}</Grid>
        </NavBarMenu>
    );
};

export default ProductsNav;
