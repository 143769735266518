import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import MakeItTabs from './components/MakeItTabs';
import { Activity } from '../../../utility/auth/useSecurity';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { getShipToInformation } from '../../../utility/helpers/summary-helpers';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { CustomerContextState, ProdOrderType } from '../../../store/reducers/customer-context';

export default function MakeItSummary() {
    const history = useHistory();

    const [loading, setLoading] = useState<boolean>(false);
    const { t } = useTranslation();

    const { selectedAccountId, shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );

    useEffect(() => {
        if (selectedAccountId && shipToAccounts) {
            const shipToAccount = getShipToInformation(shipToAccounts, selectedAccountId);
            if (
                shipToAccount &&
                shipToAccount.prodOrderType === ProdOrderType.AuthorizationToManufacture
            ) {
                history.push('/make-it-po-summary');
            }
        }
    }, [history, selectedAccountId, shipToAccounts]);

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('makeIt', 'Make It'),
                description: t('productionOrderSummary', 'Production Order Summary').toUpperCase(),
                thinBanner: true,
                displayDropdown: true
            }}
            activity={Activity.NewOpenProductionOrders}
            loading={loading}
            onlyCheckSelectedLargeAccount={true}
            fallbackActivity={Activity.MakeItATMSummary}
            fallbackPath={'/make-it-po-summary'}
        >
            <Grid container data-testid="tabs-container">
                <MakeItTabs setDashboardLoading={setLoading} dashboardLoading={loading} />
            </Grid>
        </ProcessingPageTemplate>
    );
}
