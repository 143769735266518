import { Button, Grid, makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { InvoiceRow, MakeItSummaryState } from '../../../../store/reducers/make-it-summary';
import { Link as RouterLink } from 'react-router-dom';
import Link from '../../../reusable/atoms/Link';
import { resetProductionOrderState } from '../../../../store/actions/production-order-details';
import { Trans } from 'react-i18next';
import { ballBlue, blackWeight, boldWeight, xl } from '../../../../themes/globalConstants';
import ConfirmationModal from '../../../reusable/molecules/ConfirmationModal';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { useHistory } from 'react-router';
import { cancelPendingProductionOrder } from '../../../../store/actions/makeit-dashboard';

interface Props {
    invoice: InvoiceRow;
}

const useStyles = makeStyles((theme) => ({
    cancelButton: {
        fontSize: '1em',
        '&:hover': {
            backgroundColor: 'transparent',
            color: ballBlue,
            fontWeight: blackWeight,
            outline: 'none'
        },
        '&:focus': {
            backgroundColor: 'transparent',
            color: ballBlue,
            fontWeight: blackWeight
        }
    },
    subsection: {
        fontSize: xl,
        fontWeight: boldWeight,
        letterSpacing: '0.13px',
        textAlign: 'left'
    }
}));

const MakeItPendingPaymentRow = ({ invoice }: Props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const resetLoadedOrder = () => {
        dispatch(resetProductionOrderState());
    };
    const [cancelOpen, setCancelOpen] = useState<boolean>(false);
    const [cancelSubmit, setCancelSubmit] = useState<boolean>(false);
    const { pendingProdOrderCancelled } = useTypedSelector<MakeItSummaryState>(
        (state) => state.makeItSummary
    );

    useEffect(() => {
        if (pendingProdOrderCancelled === Number(invoice.productionOrderId)) {
            setCancelSubmit(false);
            history.push('/make-it-summary');
        }
    }, [history, invoice.productionOrderId, pendingProdOrderCancelled, invoice]);

    const onCancel = () => {
        if (invoice.productionOrderId) {
            setCancelOpen(true);
        }
    };

    const onClose = () => {
        setCancelOpen(false);
    };

    const formatCancelModalSubHeader = () => {
        return (
            <Typography className={classes.subsection}>
                <Trans i18nKey="cancelProductionOrderConfirmation">
                    Are you sure you want to cancel Production Order #
                </Trans>
                {invoice.searchableBallPONumber
                    ? invoice.searchableBallPONumber
                    : invoice.productionOrderId}{' '}
                {'?'}
            </Typography>
        );
    };

    const onCancelOrder = () => {
        if (invoice) {
            cancelOrderAndRedirect();
        }
    };

    const cancelOrderAndRedirect = () => {
        setCancelSubmit(true);
        dispatch(cancelPendingProductionOrder(Number(invoice.productionOrderId)));
    };

    return (
        <>
            <TableRow hover={true} key={invoice.productionOrderId}>
                <TableCell data-testid={'ship-to'}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography>{invoice.shipToName}</Typography>
                            <Typography>{invoice.shipToAddress}</Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell data-testid={'customer-po-id'}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography>{invoice.customerProductionOrderId}</Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell data-testid={'ball-po-number'}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Link
                                to={`/production-order/${invoice.productionOrderId}`}
                                component={RouterLink}
                                underline="none"
                                onClick={resetLoadedOrder}
                            >
                                <Typography>
                                    {invoice.searchableBallPONumber
                                        ? invoice.searchableBallPONumber
                                        : ''}
                                </Typography>
                            </Link>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell data-testid={'submitted-date'}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography>{invoice.submittedDateString}</Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell data-testid={'requested-date'}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography>{invoice.requestedDateString}</Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell data-testid={'payment-due-date'}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography>{invoice.paymentDueDateString}</Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell data-testid={'revised-available'}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography>{invoice.revisedAvailableDateString}</Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell data-testid={'order-quantity'}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography>{invoice.orderQuantityString}</Typography>
                            <Typography>{invoice.orderQuantityEachesString}</Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell data-testid="available-actions">
                    <Button
                        variant="text"
                        color="primary"
                        data-testid="edit-button"
                        className={classes.cancelButton}
                        onClick={() => onCancel()}
                    >
                        <Trans i18nKey="cancel">Cancel</Trans>
                    </Button>
                </TableCell>
            </TableRow>
            <ConfirmationModal
                title={
                    <Trans i18nKey="cancelProductionOrder">
                        Please confirm you want to cancel this order.
                    </Trans>
                }
                open={cancelOpen}
                logo={false}
                subheader={formatCancelModalSubHeader()}
                cancelText={<Trans i18nKey="no">No</Trans>}
                continueText={<Trans i18nKey="yesCancel">Yes, Cancel</Trans>}
                onClose={onClose}
                onCancel={onClose}
                saveProgress={onCancelOrder}
                showSpinner={cancelSubmit}
            />
        </>
    );
};

export default MakeItPendingPaymentRow;
