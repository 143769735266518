import React from 'react';
import { Grid } from '@material-ui/core';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { Activity } from '../../../utility/auth/useSecurity';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { useTranslation } from 'react-i18next';
import LiquidTestTabs from './components/LiquidTestTabs';
import { LiquidTestSummaryState } from '../../../store/reducers/liquid-test-summary';

const LiquidTestSummaryPage = () => {
    const { t } = useTranslation();
    const { loading } = useTypedSelector<LiquidTestSummaryState>(
        (state) => state.liquidTestSummary
    );
    return (
        <ProcessingPageTemplate
            loading={loading}
            banner={{
                header: t('liquidTesting', 'Liquid Testing'),
                description: '',
                thinBanner: true
            }}
            activity={Activity.LiquidTestIntakeSummary}
            shipTos={false}
        >
            <Grid container>
                <LiquidTestTabs />
            </Grid>
        </ProcessingPageTemplate>
    );
};

export default LiquidTestSummaryPage;
