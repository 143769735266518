import { makeStyles, Grid, Typography, TextField, CircularProgress } from '@material-ui/core';
import Modal from '../molecules/Modal';
import { useState, useEffect } from 'react';
import React from 'react';
import Button from '../atoms/Button';
import { useHistory } from 'react-router-dom';
import { brightGrey, boldWeight, xl, large } from '../../../themes/globalConstants';
import { Trans } from 'react-i18next';
import {
    BulkUploadMakeItATMState,
    MakeItBulkATMLineItem
} from '../../../store/reducers/makeit-bulk-atm';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { FormattedAccount } from '../../pages/MakeItBulkReviewATM/models/FormattedAccount';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { getAccountAddress } from '../../../utility/helpers/address-helpers';
import {
    createDraftOrder,
    resetMakeItBulkATMState,
    updateDraftOrder
} from '../../../store/actions/makeit-bulk-atm';
import { useDispatch } from 'react-redux';
import { AuthState } from '../../../store/reducers/auth';
import moment from 'moment';
import ImpersonationWarning from './ImpersonationWarning';
import { selectIsImpersonation } from '../../../store/selectors';

interface Props {
    open: boolean;
    onClose?: () => void;
    onCancel?: () => void;
    setClose: React.Dispatch<React.SetStateAction<boolean>>;
    setAllowNavigation: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles({
    modal: {
        '& .MuiPaper-root': {
            maxWidth: '40.5em'
        },
        '& .MuiDialog-paperScrollPaper': {
            borderRadius: 'unset',
            overflow: 'hidden'
        },
        '& .MuiDialogTitle-root': {
            justifyContent: 'left'
        }
    },
    content: {
        textAlign: 'center',
        letterSpacing: '1px',
        color: brightGrey
    },
    textBuffer: {
        marginTop: '2em'
    },
    text: {
        marginTop: '0.625em',
        fontSize: '1.5rem',
        fontWeight: boldWeight,
        textAlign: 'left'
    },
    subsection: {
        fontSize: xl,
        fontWeight: boldWeight,
        letterSpacing: '0.13px',
        padding: '0 1.875em',
        textAlign: 'left'
    },
    button: {
        height: 'auto',
        marginTop: '1.875em',
        fontSize: large,
        fontWeight: 'normal',
        textTransform: 'capitalize',
        borderRadius: 'unset',
        minWidth: '10em',
        marginLeft: '1em'
    },
    contentWrapper: {
        width: '100%'
    },
    actionButtons: {
        flexDirection: 'row',
        gap: '1em',
        margin: '1.5em 0',
        padding: '0 1.875em',
        textAlign: 'right'
    },
    progressColor: {
        color: 'white'
    }
});

const SaveDraftModal = (props: Props) => {
    const classes = useStyles();
    const { userInfo } = useTypedSelector<AuthState>((state) => state.auth);
    const [open, setOpen] = useState<boolean>(false);
    const [draftName, setDraftName] = useState<string>('');
    const [existingDraftId, setExistingDraftId] = useState<number>();
    const history = useHistory();
    const isImpersonation = useTypedSelector<boolean>(selectIsImpersonation);

    const { atmOrders, submitAtmOrderSaveDraftState, draft } =
        useTypedSelector<BulkUploadMakeItATMState>((state) => state.bulkUploadMakeItATMState);
    const [shipToAccountsList, setShipToAccountsList] = useState<FormattedAccount[]>([]);
    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const [showImpersonationWarning, setShowImpersonationWarning] = useState<boolean>(false);
    const dispatch = useDispatch();

    const closeSaveDraftModal = () => {
        setOpen(false);
        props.setClose(false);
    };

    const maxInputLength = 50;

    useEffect(() => {
        if (submitAtmOrderSaveDraftState === 'success') {
            props.setAllowNavigation(true);
            setTimeout(() => {
                history.push(
                    existingDraftId !== undefined ? '/make-it-drafts-summary' : '/dashboard'
                );
            }, 0);
            dispatch(resetMakeItBulkATMState());
        }
        if (submitAtmOrderSaveDraftState === 'error') {
            setOpen(false);
            props.setClose(false);
        }
    }, [submitAtmOrderSaveDraftState]);

    // look for an existing draft to set up draft name & existing draft info
    useEffect(() => {
        // check to see if we're modifying an existing draft
        let existingDraftNumber = Number(draft?.draftId);

        if (draft !== undefined && existingDraftNumber !== undefined) {
            setDraftName(draft.name);
            setExistingDraftId(existingDraftNumber);
        } else {
            if (draftName === '') {
                let createdBy = userInfo?.preferred_username ?? '';
                let createdDate = moment().format('MM/DD/YYYY HH:mm:ss');

                // truncate username to meet 50char max
                if (`${createdBy}: ${createdDate}`.length > maxInputLength) {
                    createdBy = createdBy.substring(0, maxInputLength - `: ${createdDate}`.length);
                }

                setDraftName(`${createdBy}: ${createdDate}`);
            }
        }
    }, [draft]);

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    /**
     * Load the Ship To Accounts from the order - only include a single ship to once.
     * @when Triggers on initial page load, after shipToAccounts and atmOrders are in state.
     */
    useEffect(() => {
        if (atmOrders && shipToAccounts) {
            let accountsInOrder: FormattedAccount[] = [];
            atmOrders.forEach((order) => {
                let orderShipTo = order.shipToId;
                let account = shipToAccounts.find(
                    (account) => parseInt(account.accountId) === orderShipTo
                );

                let formattedAccount = {
                    account: account,
                    state: 'valid'
                } as FormattedAccount;

                if (
                    account &&
                    !accountsInOrder.find((accountInList) => accountInList.account === account)
                ) {
                    accountsInOrder.push(formattedAccount);
                }
            });
            setShipToAccountsList(accountsInOrder);
        }
    }, [atmOrders]);

    const draftNameLabel = <Trans i18nKey="draftName">Draft Name</Trans>;

    const onSaveDraft = () => {
        if (!isImpersonation) {
            let productList: MakeItBulkATMLineItem[] = [];

            atmOrders?.forEach((atmOrder) => {
                atmOrder.lines.forEach((line) => {
                    if (!line.deleted && line.palletQuantity && line.palletQuantity > 0) {
                        let accountInfo = shipToAccountsList.find(
                            (account) => account.account.accountId === atmOrder.shipToId.toString()
                        );
                        if (accountInfo) {
                            line.shipToId = accountInfo.account.accountId;
                            line.shipToName = accountInfo.account.name;
                            line.shipToAddress = getAccountAddress(accountInfo.account);
                            line.weekStart = atmOrder.weekStart;
                        }
                        productList.push(line);
                    }
                });
            });

            if (existingDraftId !== undefined) {
                dispatch(updateDraftOrder(draftName, existingDraftId));
            } else {
                dispatch(createDraftOrder(draftName));
            }
        } else {
            setShowImpersonationWarning(true);
        }
    };

    return (
        <>
            <Modal
                open={open}
                close={closeSaveDraftModal}
                title={<Trans i18nKey="saveDraft">Save Draft</Trans>}
                closeIcon={true}
                fullWidth={true}
                className={classes.modal}
            >
                {
                    <Grid container className={classes.content}>
                        <Grid item className={classes.contentWrapper} data-testid="confirm-modal">
                            <Grid item className={classes.textBuffer}>
                                <Typography className={classes.subsection} data-testid="subheader">
                                    <Trans i18nKey="intakeSaveDraft">
                                        You are about to save a draft of your Production Order,
                                        please name your draft to easily access it later. Once
                                        saved, you will return to the dashboard.
                                    </Trans>
                                </Typography>
                            </Grid>
                            <Grid container item justify="center">
                                <Grid item xs={7}>
                                    <TextField
                                        id="save-input"
                                        data-testid="save-input"
                                        label={draftNameLabel}
                                        variant="filled"
                                        fullWidth
                                        margin="normal"
                                        name="name"
                                        aria-label="name"
                                        defaultValue={draftName}
                                        disabled={existingDraftId !== undefined}
                                        onChange={(e) => {
                                            setDraftName(e.target.value);
                                        }}
                                        inputProps={{ maxLength: maxInputLength }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item>
                                <ImpersonationWarning
                                    showWarning={showImpersonationWarning}
                                    warningType={'SAVE'}
                                />
                            </Grid>
                            <Grid
                                container
                                item
                                alignItems="flex-end"
                                justify="flex-end"
                                className={classes.actionButtons}
                            >
                                <Button
                                    type="button"
                                    variant="outlined"
                                    color="secondary"
                                    data-testid="cancel-btn"
                                    className={classes.button}
                                    onClick={closeSaveDraftModal}
                                >
                                    <Trans i18nKey="cancel">Cancel</Trans>
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    data-testid="submit-btn"
                                    disabled={
                                        submitAtmOrderSaveDraftState === 'loading'
                                            ? true
                                            : draftName
                                            ? false
                                            : true
                                    }
                                    className={classes.button}
                                    onClick={() => {
                                        onSaveDraft();
                                    }}
                                >
                                    {submitAtmOrderSaveDraftState === 'loading' ? (
                                        <CircularProgress
                                            className={classes.progressColor}
                                            size={'2rem'}
                                        />
                                    ) : (
                                        <Trans i18nKey="saveDraft">Save Draft</Trans>
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Modal>
        </>
    );
};

export default SaveDraftModal;
