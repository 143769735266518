import * as types from './action-types';
import Axios, { AxiosRequestConfig } from 'axios';
import { getAxiosConfig } from '../../utility/helpers/axios-helpers';

export const getContent = (id: any) => {
    return (dispatch: any, getState: any) => {
        dispatch({
            type: types.CONTENT_LOADING
        });

        let axiosConfig: AxiosRequestConfig = getAxiosConfig(getState);

        // fetch content pages
        Axios.get(`${process.env.REACT_APP_CONTENT_API}/content/pages?pagePath=${id}`, axiosConfig)
            .then((response) => {
                dispatch({
                    type: types.CONTENT_LOADED,
                    contentTemplates: response.data?.page
                });
            })
            .catch((error) => {
                dispatch({
                    type: types.CONTENT_LOADING_ERROR,
                    error: error.toString()
                });
            });
    };
};

export const setContent = (data) => {
    return (dispatch: any) => {
        dispatch({
            type: types.CONTENT_LOADED,
            contentTemplates: data
        });
    }
}