import React from 'react';
import ReactPDF, { View, Text } from '@react-pdf/renderer';
import { pdfUtilClasses } from '../../../themes/pdfStyles';

interface Props {
    columns: Array<string | { label: string; align: 'left' | 'right' | 'center' }>;
    rows: Array<Array<string | ReactPDF.View | string[]>>;
    columnWidths: Array<number>; // Array of percents
    colGap?: string;
    rightAlignLastColumn?: boolean;
}

const renderRow = (row: string | ReactPDF.View | string[]) => {
    if (typeof row === 'string') {
        return <Text style={[pdfUtilClasses.textNormal]}>{row}</Text>;
    } else if (Array.isArray(row)) {
        return row.map((textLine) => (
            <Text style={[pdfUtilClasses.textNormal, { width: '100%' }]}>{textLine}</Text>
        ));
    } else {
        return { row };
    }
};
const StandardTablePDF = ({
    columns,
    rows,
    columnWidths,
    colGap = '4px',
    rightAlignLastColumn = false
}: Props) => {
    if (rightAlignLastColumn && typeof columns[columns.length - 1] === 'string') {
        columns[columns.length - 1] = {
            label: columns[columns.length - 1] as string,
            align: 'right'
        };
    }
    return (
        <View>
            {/* HEADERS */}
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    borderBottom: '1px solid #ccc',
                    padding: '5px 0'
                }}
            >
                {columns.map((col, index) => (
                    <View
                        style={{
                            width: `${columnWidths[index]}%`,
                            textAlign: typeof col === 'object' ? col.align : 'left',
                            paddingRight: index < columns.length - 1 ? colGap : 0
                        }}
                    >
                        <Text
                            style={[
                                pdfUtilClasses.textSmall,
                                pdfUtilClasses.bold,
                                pdfUtilClasses.darkGray,
                                pdfUtilClasses.uppercase
                            ]}
                        >
                            {typeof col === 'object' ? col.label : col}
                        </Text>
                    </View>
                ))}
            </View>
            {/* ROWS */}
            {rows.map((row) => (
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        borderBottom: '1px solid #ccc',
                        padding: '5px 0'
                    }}
                >
                    {columns.map((col, index) => (
                        <View
                            style={{
                                width: `${columnWidths[index]}%`,
                                textAlign: typeof col === 'object' ? col.align : 'left',
                                paddingRight: index < columns.length - 1 ? colGap : 0
                            }}
                        >
                            {!!row[index] && renderRow(row[index])}
                        </View>
                    ))}
                </View>
            ))}
        </View>
    );
};

export default StandardTablePDF;
