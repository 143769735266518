import React, { ReactNode } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { productPortfolioUpdateView } from '../../../../store/root-actions';
import { ProductPortfolioState } from '../../../../store/reducers/product-portfolio';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import Button from '../../../reusable/atoms/Button';
import ButtonGroup from '../../../reusable/molecules/ButtonGroup';
import { useTranslation, Trans } from 'react-i18next';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { ViewType } from '../../../../utility/helpers/filter-helpers';
import { enUS } from '../../../../utility/translations/locales';
import { useProductPortfolioState } from '../context/ProductPortfolioContext';
import { ProductType } from '../../../../utility/services/orders-service';
import { createQueryParam } from '../../../../utility/helpers/query-helpers';
import { useHistory } from 'react-router';
import { Region } from '../../../../store/reducers/graphic-intake';

interface Props {
    children?: ReactNode;
    productCodes?: any;
    onOpenProjectsModal?: () => void;
    enableProjectsModal?: boolean;
}

const useStyles = makeStyles((theme) => ({
    subheaderBar: {
        backgroundColor: 'transparent',
        height: '3.2em',
        [theme.breakpoints.down('xs')]: {
            height: '6em'
        }
    },
    subheader: {
        textTransform: 'uppercase',
        fontSize: 14,
        [theme.breakpoints.up('sm')]: {
            marginRight: 0
        },
        [theme.breakpoints.down('xs')]: {
            marginRight: '0.5em'
        }
    },
    actionBtn: {
        marginRight: '0.5em',
        height: 'auto',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom',
        padding: '.5em 1em',
        fontSize: 14
    },
    germanActionBtn: {
        [theme.breakpoints.down('xs')]: {
            padding: '.5em 1em',
            marginLeft: 0
        }
    },
    infoText: {
        color: theme.palette.info.dark,
        margin: 0
    },
    compareBtn: {
        marginRight: `1rem`,
        fontSize: 14
    },
    projectsBtn: {
        marginRight: `1rem`,
        fontSize: 14
    }
}));

const viewTypes: ViewType[] = [
    {
        types: '',
        label: 'all'
    },
    {
        types: 'can',
        label: 'cans'
    },
    {
        types: ProductType.Bottles,
        label: 'bottles'
    },
    {
        types: 'ends',
        label: 'ends'
    }
];

const saViewTypes = viewTypes.filter((item) => item.types !== ProductType.Bottles);

const ViewSubheader = (props: Props) => {
    const classes = useStyles();
    const regionCulture = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const culture = regionCulture.culture;
    const { view } = useTypedSelector<ProductPortfolioState>((state) => state.productPortfolio);
    const regionCode = regionCulture.regionCode;
    const isEnglish = culture === 'English';
    const isGerman = culture === 'German';
    const dispatch = useDispatch();
    const history = useHistory();
    const { productsSelected, hideKBMaxFunctionality } = useProductPortfolioState();
    const isProducts = productsSelected > 0;
    useTranslation();

    const handleEstimate = () => {
        if (isProducts) {
            const queryString = createQueryParam(props.productCodes);
            history.push(`/product-estimate${queryString}`);
        }
    };

    const handleCompare = () => {
        if (isProducts) {
            history.push('/product-comparison');
        }
    };

    return (
        <Grid container justify="flex-start" alignItems="center" className={classes.subheaderBar}>
            <Grid item container xs={6} sm={6} md={6} alignItems="center" justify="space-between">
                <Grid
                    container
                    item
                    xs={!isEnglish ? 12 : 5}
                    sm={!isEnglish ? 3 : 2}
                    md={!isEnglish ? 2 : 1}
                >
                    <Typography variant="h6" component="h1" className={classes.subheader}>
                        <Trans i18nKey="view">View</Trans> /
                    </Typography>
                </Grid>
                <Grid container item xs={!isEnglish ? 12 : 7} sm={8} md={10}>
                    <ButtonGroup
                        views={regionCode === 'SA' ? saViewTypes : viewTypes}
                        defaultValue={view}
                        onViewChange={(newView) => dispatch(productPortfolioUpdateView(newView))}
                    />
                </Grid>
            </Grid>
            {!hideKBMaxFunctionality && (
                <Grid container item xs={6} sm={6} md={6} justify="flex-end">
                    <Button
                        color="primary"
                        variant={![2, 3].includes(productsSelected) ? 'contained' : 'outlined'}
                        data-testid="compareProductsBtn"
                        className={classes.compareBtn}
                        disabled={![2, 3].includes(productsSelected)}
                        onClick={handleCompare}
                    >
                        <Trans i18nKey="compare">{enUS.compare}</Trans>
                    </Button>
                    {regionCode === Region.NA ? (
                        <Button
                            color="primary"
                            variant={regionCode === Region.NA ? 'contained' : 'outlined'}
                            className={classes.projectsBtn}
                            data-testid="myProjectsBtn"
                            onClick={props.onOpenProjectsModal}
                            disabled={!props.enableProjectsModal}
                        >
                            <Trans i18nKey="myProjects">{enUS.myProjects}</Trans>
                        </Button>
                    ) : null}
                    {regionCode !== Region.NA && (
                        <Button
                            color="primary"
                            variant="contained"
                            className={isGerman ? classes.germanActionBtn : classes.actionBtn}
                            onClick={handleEstimate}
                            disabled={!isProducts}
                        >
                            <Trans i18nKey="getAnEstimate">Get an Estimate</Trans>
                        </Button>
                    )}
                </Grid>
            )}
        </Grid>
    );
};

export default ViewSubheader;
