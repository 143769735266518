import { Region } from "../../store/reducers/graphic-intake";
import { Culture } from "../../store/reducers/region-culture";

export const getMediaPath = (mediaPath: string) =>
    `${process.env.REACT_APP_CONTENT_API}/content/media?mediaPath=${mediaPath}`;

export const getFeatureContent = (featureCode: string) => {
    switch (featureCode) {
        case 'COLDFILL':
            return 'coldfill';
        case 'EYERIS':
            return 'eyeris';
        case 'PASTEURIZATION':
            return 'pasteurization';
        case 'HOTFILL':
            return 'hotfill';
        case 'BRE':
            return 'bre';
        case 'WIDGET':
            return 'widget';
        case 'DYNAMARK':
            return 'dynamark';
        case 'DYNAMARKHD':
            return 'dynamarkhd';
        default:
            break;
    }
}

export const getCampaignTiming = (timingCode: string) => {
    switch (timingCode) {
        case '2YEARLY':
            return '2';
        case '4YEARLY':
            return '4';
        case '6YEARLY':
            return '6';
        default:
            break;
    }
}

export const getSpanishCulture = (region: string) => {
    switch (region) {
        case Region.NA:
            return Culture.esMX;
        case Region.SA:
            return Culture.esVE;
        case Region.EU:
            return Culture.esES;
        default:
            break;
    }
}