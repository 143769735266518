import React, { useEffect, useState } from 'react';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import { Grid, makeStyles } from '@material-ui/core';
import { boldWeight, orange, blackWeight } from '../../../../themes/globalConstants';
import {
    LinkedDeliveryOrder,
    ProductionOrderLine,
    ProductionOrderLineItem
} from '../../../../store/reducers/makeit-dashboard';
import ProductionOrderLineRow from './ProductionOrderLineRow';
import ProductionOrderDetailsToolbar from './ProductionOrderDetailsToolbar';
import ProductionOrderDetailsColumns from './ProductionOrderDetailsColumns';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { Order_Status } from '../../../../utility/services/orders-service';

interface ExpandToggle {
    toggle: boolean;
}

interface Props {
    lines?: ProductionOrderLine[];
    linkedDeliveryOrder?: LinkedDeliveryOrder;
}

const useStyles = makeStyles((theme) => ({
    action: {
        cursor: 'pointer'
    },
    trashIcon: {
        fill: orange
    },
    tableWrapper: {
        '& .MuiToolbar-root': {
            padding: 0,
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                },
                '&:first-of-type ': {
                    paddingLeft: 0
                }
            },
            '& > h2': {
                fontSize: '1.5em',
                fontWeight: boldWeight,
                color: theme.palette.secondary.main,
                paddingLeft: '0.625em',
                fontFamily: 'Lato',
                minWidth: '12em'
            }
        },
        '& .MuiTableSortLabel-root': {
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em',
            width: '99%',
            justifyContent: 'space-between',
            '& .MuiSvgIcon-root': {
                margin: 0
            }
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                '&:nth-child(1)': {
                    width: '12%'
                },
                '&:nth-child(2)': {
                    width: '30%'
                },
                '&:nth-child(3)': {
                    width: '15%'
                },
                '&:nth-child(4)': {
                    width: '15%'
                },
                '&:nth-child(5)': {
                    width: '15%'
                },
                '&:nth-child(6)': {
                    width: '15%'
                },
                '&:nth-child(7)': {
                    width: '15%'
                }
            }
        },
        '& .MuiTable-root': {
            '&:last-child': {
                display: 'none'
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    }
}));

const ProductionOrderDetailsGrid = ({ lines, linkedDeliveryOrder }: Props) => {
    const classes = useStyles();
    const [expandAll, setExpandAll] = useState<ExpandToggle>({ toggle: false });
    const [collapseAll, setCollapseAll] = useState<ExpandToggle>({ toggle: false });
    const [columns, setColumns] = useState<any>([]);
    const [orderLines, setOrderLines] = useState<ProductionOrderLineItem[]>();

    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const handleExpandAll = () => {
        setCollapseAll({ toggle: false });
        setExpandAll({ toggle: true });
    };

    const handleCollapseAll = () => {
        setExpandAll({ toggle: false });
        setCollapseAll({ toggle: true });
    };

    const getFilteredDeliveryOrders = (productionOrderLineId?: number) => {
        if (linkedDeliveryOrder?.linkedDeliveryOrders?.length) {
            return linkedDeliveryOrder?.linkedDeliveryOrders?.filter(
                (order) => order.productionOrderLineId === productionOrderLineId && order.status !== Order_Status.Cancelled
            );
        } else {
            return;
        }
    };

    useEffect(() => {
        setColumns(ProductionOrderDetailsColumns.getProductionOrderDetailsColumns(cultureCode));
    }, [cultureCode]);

    useEffect(() => {
        if (lines && linkedDeliveryOrder) {
            setOrderLines(lines as ProductionOrderLineItem[]);
        }
    }, [lines, linkedDeliveryOrder]);

    const CustomToolbarOptions = new ToolbarOptions({
        customItems: (
            <ProductionOrderDetailsToolbar
                onExpandAll={handleExpandAll}
                onCollapseAll={handleCollapseAll}
            />
        ),
        advancePagination: false,
        exportButton: false,
        printButton: false,
        searchText: false
    });

    const ProductionOrderLineItemRow = ({ row }: { row: Partial<ProductionOrderLineItem> }) => {
        if (row) {
            let filteredDeliveryOrders = getFilteredDeliveryOrders(row.productionOrderLineId);
            return (
                <ProductionOrderLineRow
                    key={row.productionOrderLineId}
                    line={row as ProductionOrderLineItem}
                    linkedDeliveryOrders={filteredDeliveryOrders}
                    expand={expandAll}
                    collapse={collapseAll}
                />
            );
        } else {
            return null;
        }
    };

    return (
        <Grid className={classes.tableWrapper}>
            {orderLines && (
                <DataGrid
                    gridName="Production Order Lines"
                    columns={columns}
                    dataSource={orderLines}
                    rowComponent={ProductionOrderLineItemRow}
                    toolbarOptions={CustomToolbarOptions}
                />
            )}
        </Grid>
    );
};

export default ProductionOrderDetailsGrid;
