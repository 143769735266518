import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Trans } from 'react-i18next';
import { boldWeight, brightGrey } from '../../../../themes/globalConstants';
import Button from '../../../reusable/atoms/Button';
interface Props {
    onContinue: () => void;
    onCancel: () => void;
}

const useStyles = makeStyles({
    content: {
        textAlign: 'center',
        letterSpacing: '1px',
        color: brightGrey
    },
    text: {
        marginTop: 10,
        marginLeft: '1em',
        marginRight: '1em',
        fontSize: '1em',
        fontWeight: boldWeight
    },
    button: {
        height: 40,
        marginTop: 10,
        marginBottom: 25,
        fontSize: 16,
        textTransform: 'capitalize',
        borderRadius: 'unset'
    },
    buttonContainer: {
        marginTop: '1em'
    },
    contentWrapper: {
        width: '100%'
    }
});

const MakeItChangeRequestedWeekModal = ({ onContinue, onCancel }: Props) => {
    const classes = useStyles();
    return (
        <Grid container justify="center" className={classes.content}>
            <Grid item className={classes.contentWrapper}>
                <Grid item>
                    <Typography className={classes.text} data-testid="description">
                        <Trans i18nKey="changeRequestedWeekWarning">
                            Changing your requested week will remove all products you have added to
                            the order. Do you want to continue?
                        </Trans>
                    </Typography>
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    alignItems="center"
                    justify="center"
                    className={classes.buttonContainer}
                >
                    <Grid item xs={4}>
                        <Button
                            type="button"
                            variant="outlined"
                            className={classes.button}
                            data-testid="cancel-btn"
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            type="button"
                            variant="outlined"
                            color="primary"
                            data-testid="continue-btn"
                            className={classes.button}
                            onClick={onContinue}
                        >
                            Yes, Continue
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default MakeItChangeRequestedWeekModal;
