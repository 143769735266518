import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class ScrapItSummaryColumns {
    static getColumns = (culture: string) => {
        return [
            createColumn('requestOrderNumber', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'scrapItOrderNumber'),
                filterable: true,
                sortable: true,
                searchable: true
            }),
            createColumn('requestedQuantity', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'quantityToScrap'),
                filterable: true,
                sortable: true,
                searchable: true
            }),
            createColumn('quantityEaches', {
                dataType: ColumnDataType.String,
                visible: false,
                filterable: false,
                sortable: false,
                searchable: true
            }),
            createColumn('submittedDate', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'dateSubmitted'),
                filterable: true,
                searchable: true,
                sortable: true
            }),
            createColumn('status', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'status'),
                filterable: true,
                searchable: true,
                sortable: true
            })
        ];
    };
}
