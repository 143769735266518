import React from 'react';
import { Trans } from 'react-i18next';

import ConfirmationModal from './ConfirmationModal';
import { enUS } from '../../../utility/translations/locales';

interface Props {
    handleYesClick: () => void;
    handleClose: () => void;
    open: boolean;
    navigationLink?: string;
    handleNoClick?: () => void;
}

const AcceptChangesModal = ({ handleYesClick, open, navigationLink, handleClose, handleNoClick }: Props) => {
    return (
        <ConfirmationModal
            title={<Trans i18nKey="changesNotSaved">${enUS.changesNotSaved}</Trans>}
            open={open}
            logo={false}
            subheader={<Trans i18nKey="confirmChangesWillNotBeSaved">${enUS.confirmChangesWillNotBeSaved}</Trans>}
            cancelText={<Trans i18nKey="no">${enUS.no}</Trans>}
            continueText={<Trans i18nKey="yesDiscard">${enUS.yesDiscard}</Trans>}
            saveProgress={handleYesClick}
            navigationLink={navigationLink}
            onClose={handleClose}
            onCancel={handleNoClick}
            canSaveDraft={true}
        />
    );
};

export default AcceptChangesModal;
