import { EmeaGraphics, Graphics, GraphicsLoadStatus } from './graphic-intake';
import {
    GRAPHICS_DASHBOARD_LOADING,
    GRAPHICS_DASHBOARD_LOAD_COMPLETED,
    GRAPHICS_DASHBOARD_LOAD_DRAFT,
    GRAPHICS_DASHBOARD_LOAD_INPROGRESS,
    GRAPHICS_DASHBOARD_ERROR,
    GRAPHICS_DASHBOARD_LOADED
} from '../actions/action-types';

export interface GraphicDashboardState {
    draftGraphics: Graphics[];
    inProgressGraphics: Graphics[] | EmeaGraphics[];
    completedGraphics: Graphics[];
    loading: boolean;
    loaded: GraphicsLoadStatus;
    error: any;
}

const initialState: GraphicDashboardState = {
    draftGraphics: [],
    inProgressGraphics: [],
    completedGraphics: [],
    loading: false,
    loaded: { draft: false, inProgress: false, completed: false },
    error: {}
};

const graphicDashboard = (
    state: GraphicDashboardState = initialState,
    action: { type: string;[x: string]: any }
): GraphicDashboardState => {
    switch (action.type) {
        case GRAPHICS_DASHBOARD_LOADING:
            return { ...state, loading: true, loaded: { draft: false, inProgress: false, completed: false }, error: {} };
        case GRAPHICS_DASHBOARD_LOAD_COMPLETED:
            return { ...state, completedGraphics: action.graphics, loaded: { ...state.loaded, completed: true } };
        case GRAPHICS_DASHBOARD_LOAD_DRAFT:
            return { ...state, draftGraphics: action.graphics, loaded: { ...state.loaded, draft: true, } };
        case GRAPHICS_DASHBOARD_LOAD_INPROGRESS:
            return { ...state, inProgressGraphics: action.graphics, loaded: { ...state.loaded, inProgress: true } };
        case GRAPHICS_DASHBOARD_LOADED:
            return { ...state, loading: false, loaded: { draft: true, inProgress: true, completed: true } };
        case GRAPHICS_DASHBOARD_ERROR:
            return { ...state, loading: false, loaded: { draft: true, inProgress: true, completed: true }, error: action.error };
        default:
            return state;
    }
};

export default graphicDashboard;
