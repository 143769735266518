import React from 'react';
import PdfPageTemplate from '../../../templates/PdfPageTemplate';
import { Document, Page, Text, View, BlobProvider, Image } from '@react-pdf/renderer';
import pdfStyles from '../../../../themes/pdfStyles';
import { ProductionOrder } from '../../../../store/reducers/makeit-dashboard';
import { isIE } from '../../../../utility/helpers/user-helpers';
import { formattedMTDate, getFormattedDateStringForApplication, getUtcMidnight } from '../../../../utility/helpers/date-helpers';
import moment from 'moment';
import {
    LinkedDeliveryOrder,
    ProductionOrderLineItem
} from '../../../../store/reducers/makeit-dashboard';
import {
    orderStatusCodeFormatter,
    constructPDFCanDescription,
    constructPDFEndDescription,
    formatStatus,
    isProductEndType,
    getGraphicIdAndVersion
} from '../../../../utility/helpers/order-helpers';
import { useTranslation, Trans } from 'react-i18next';

interface Props {
    title: string;
    shipToName: string;
    shipToAddress: string;
    order: ProductionOrder | undefined;
    lines?: ProductionOrderLineItem[];
    linkedDeliveryOrder?: LinkedDeliveryOrder;
    showCustomerPO: boolean;
}

const ProductionOrderPDF = (props: Props) => {
    const ballLogo = process.env.PUBLIC_URL + '/assets/Ball_RGB.jpg';
    const sourceLogo = process.env.PUBLIC_URL + '/assets/TheSource_Logo.png';

    const getFilteredDeliveryOrders = (productionOrderLineId?: number) => {
        if (props.linkedDeliveryOrder?.linkedDeliveryOrders?.length) {
            return props.linkedDeliveryOrder?.linkedDeliveryOrders?.filter(
                (order) => order.productionOrderLineId === productionOrderLineId
            );
        } else {
            return;
        }
    };

    const getDesc = (line) => {
        return isProductEndType(line.productType)
            ? constructPDFEndDescription(line)
            : constructPDFCanDescription(line);
    };

    const isFuture = (day) => {
        if (moment().diff(day, 'days') <= 0) {
            return false;
        } else {
            return true;
        }
    };

    useTranslation();

    const deliveryOrders = (filteredOrders) =>
        filteredOrders?.map((order, index) => {
            return (
                <>
                    <View style={pdfStyles.divider4} />
                    <View style={pdfStyles.tableRow2}>
                        <View style={pdfStyles.borderLessTableColWide3}>
                            <Text style={pdfStyles.header2}>{order.deliveryOrderId}</Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow3}>
                            {!isFuture(order.deliveryDateTime) && (
                                <Text style={pdfStyles.header2}>
                                    {formattedMTDate(moment(order.deliveryDateTime), 'MM/DD/YYYY')}
                                </Text>
                            )}
                            {isFuture(order.deliveryDateTime) && (
                                <Text style={pdfStyles.header2}>-</Text>
                            )}
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow4}>
                            <Text style={pdfStyles.header2}>
                                {order?.quantity?.toLocaleString()}
                            </Text>
                        </View>
                        <View style={[pdfStyles.borderLessTableColNarrow3]}>
                            {isFuture(order.deliveryDateTime) && (
                                <Text style={pdfStyles.header2}>
                                    {formattedMTDate(moment(order.deliveryDateTime), 'MM/DD/YYYY')}
                                </Text>
                            )}
                            {!isFuture(order.deliveryDateTime) && (
                                <Text style={pdfStyles.header2}>-</Text>
                            )}
                        </View>
                    </View>
                </>
            );
        });

    const productTable = props.lines?.map((line, index) => {
        let filteredDeliveryOrders = getFilteredDeliveryOrders(line.productionOrderLineId);
        return (
            <>
                <View style={pdfStyles.borderlessTable}>
                    <View style={pdfStyles.tableRow}>
                        <View style={pdfStyles.borderLessTableColNarrow3}>
                            <Text style={pdfStyles.tableCell2}>
                                {line.displayId} /{' '}
                                {getGraphicIdAndVersion(line.productType, line.productGraphicId)}
                            </Text>
                        </View>
                        <View style={[pdfStyles.borderLessTableColWide5]}>
                            <Text style={pdfStyles.tableCell2}>{line.displayName}</Text>
                            <Text style={pdfStyles.tableCell2}>{getDesc(line)}</Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow2}>
                            <Text style={pdfStyles.tableCell2}>{line.productCoating}</Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow2}>
                            <Text style={pdfStyles.tableCell2}>{line.palletQuantity} PL</Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow2}>
                            <Text style={pdfStyles.tableCell2}>
                                {getUtcMidnight(line.requestedDate).format('MM/DD/YYYY')}
                            </Text>
                        </View>
                    </View>
                </View>
                {filteredDeliveryOrders && filteredDeliveryOrders.length > 0 && (
                    <View style={pdfStyles.borderlessTable2}>
                        <View style={pdfStyles.tableRow2}>
                            <View style={pdfStyles.borderLessTableColWide3}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="deliveryOrder">DELIVERY ORDER </Trans>#
                                </Text>
                            </View>
                            <View style={[pdfStyles.borderLessTableColNarrow3]}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="scheduledFor">SCHEDULED FOR</Trans>
                                </Text>
                            </View>
                            <View style={pdfStyles.borderLessTableColNarrow4}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="quantityToDeliver">QUANITITY TO DELIVER</Trans>
                                </Text>
                            </View>
                            <View style={pdfStyles.borderLessTableColNarrow3}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="receivedOn">RECEIVED ON</Trans>
                                </Text>
                            </View>
                        </View>
                        {deliveryOrders(filteredDeliveryOrders)}
                    </View>
                )}
                <View style={pdfStyles.divider5} />
            </>
        );
    });

    const myDoc = (
        <Document title={props.title}>
            <Page size="A4" style={pdfStyles.page} orientation={'landscape'}>
                <View style={pdfStyles.logoSection} fixed>
                    <Image src={sourceLogo} style={pdfStyles.sourceLogo} />
                    <Image src={ballLogo} style={pdfStyles.ballLogo} />
                </View>
                <View style={pdfStyles.divider} fixed />
                <View style={pdfStyles.borderlessTable}>
                    <View style={pdfStyles.tableRow}>
                        {props.showCustomerPO && (
                            <View style={pdfStyles.borderLessTableColNarrow4}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="customerPoNumber">Customer PO #</Trans>
                                </Text>
                            </View>
                        )}
                        {!props.showCustomerPO && (
                            <View style={pdfStyles.borderLessTableColNarrow4}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="ballOrderNumber">Ball Order #</Trans>
                                </Text>
                            </View>
                        )}
                        <View style={pdfStyles.borderLessTableColWide3}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="status">Status</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow5}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="cans">Cans</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow2}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="ends">Ends</Trans>
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                        {props.showCustomerPO && (
                            <View style={pdfStyles.borderLessTableColNarrow4}>
                                <Text style={pdfStyles.subtitle2}>
                                    {props.order?.customerProductionOrderId}
                                </Text>
                            </View>
                        )}
                        {!props.showCustomerPO && (
                            <View style={pdfStyles.borderLessTableColNarrow4}>
                                <Text style={pdfStyles.subtitle2}>
                                    {props.order?.productionOrderNumber}
                                </Text>
                            </View>
                        )}
                        <View style={pdfStyles.borderLessTableColWide3}>
                            <Text style={pdfStyles.subtitle2}>
                                <Trans i18nKey={orderStatusCodeFormatter(props.order?.status)}>
                                    {formatStatus(props.order?.status)}
                                </Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow5}>
                            <Text style={pdfStyles.subtitle2}>
                                {props.order?.canQuantityPallets +
                                    ' PL / ' +
                                    props.order?.canQuantitySKUs +
                                    ' SKU'}
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow2}>
                            <Text style={pdfStyles.subtitle2}>
                                {props.order?.endQuantityPallets +
                                    ' PL / ' +
                                    props.order?.endQuantitySKUs +
                                    ' SKU'}
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={pdfStyles.divider} />
                <View style={pdfStyles.borderlessTable}>
                    <View style={pdfStyles.tableRow}>
                        {props.showCustomerPO && (
                            <View style={pdfStyles.borderLessTableColNarrow3}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="ballOrderNumber">Ball Order #</Trans>
                                </Text>
                            </View>
                        )}
                        <View style={pdfStyles.borderLessTableColNarrow6}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="shipTo">Ship To</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow6}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="createdBy">Created By</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow2}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="dateSubmitted">Date Submitted</Trans>
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                        {props.showCustomerPO && (
                            <View style={pdfStyles.borderLessTableColNarrow3}>
                                <Text style={pdfStyles.subtitle3}>
                                    {props.order?.productionOrderNumber}
                                </Text>
                            </View>
                        )}
                        <View style={pdfStyles.borderLessTableColNarrow6}>
                            <Text style={[pdfStyles.subtitle3]}>{props.shipToName}</Text>
                            <Text style={[pdfStyles.subtitle3]}>{props.shipToAddress}</Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow6}>
                            <Text style={pdfStyles.subtitle3}>{props.order?.submittedBy}</Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow2}>
                            <Text style={pdfStyles.subtitle3}>
                                {getFormattedDateStringForApplication(props.order?.submittedDate)}
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={pdfStyles.borderlessTable}>
                    <View style={pdfStyles.tableRow}>
                        <View style={pdfStyles.borderLessTableColNarrow3}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="productGraphicId">Product Id / Graphic Id</Trans>
                            </Text>
                        </View>
                        <View style={[pdfStyles.borderLessTableColWide5]}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="descriptionCharacteristics">
                                    Product Description / Characteristics
                                </Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow2}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="coating">Coating</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow2}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="quantity">Quantity</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow2}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="requestedDate">Requested Date</Trans>
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.divider5} />
                    {productTable}
                </View>
                <View style={[pdfStyles.fullWidth, pdfStyles.footer]} fixed>
                    <Text
                        style={pdfStyles.header2}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </View>
            </Page>
        </Document>
    );

    if (!isIE()) {
        return <PdfPageTemplate title={props.title}>{myDoc}</PdfPageTemplate>;
    } else {
        return (
            <BlobProvider document={myDoc}>
                {({ blob, url, loading, error }) => {
                    if (blob && window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob);
                    }
                    return null;
                }}
            </BlobProvider>
        );
    }
};

export default ProductionOrderPDF;
