import React from 'react';

type Action = { type: 'INCREMENT_PRODUCT_SELECTED' } | { type: 'DECREMENT_PRODUCT_SELECTED' };
type Dispatch = (action: Action) => void;
type State = { productsSelected: number; hideKBMaxFunctionality: boolean };
type ProductPortfolioProviderProps = { children: React.ReactNode; state?: State };

const ProductPortfolioStateContext = React.createContext<State | undefined>(undefined);
const ProductPortfolioDispatchContext = React.createContext<Dispatch | undefined>(undefined);
function productPortfolioReducer(state: State, action: Action) {
    switch (action.type) {
        case 'INCREMENT_PRODUCT_SELECTED': {
            return {
                productsSelected: state.productsSelected + 1,
                hideKBMaxFunctionality: state.hideKBMaxFunctionality
            };
        }
        case 'DECREMENT_PRODUCT_SELECTED': {
            return {
                productsSelected: state.productsSelected - 1,
                hideKBMaxFunctionality: state.hideKBMaxFunctionality
            };
        }
        default: {
            throw new Error(`Unhandled action type: ${(action as Action).type}`);
        }
    }
}
function ProductPortfolioProvider({ children, state }: ProductPortfolioProviderProps) {
    const [initialState, dispatch] = React.useReducer(
        productPortfolioReducer,
        state || { productsSelected: 0, hideKBMaxFunctionality: false }
    );
    return (
        <ProductPortfolioStateContext.Provider value={initialState}>
            <ProductPortfolioDispatchContext.Provider value={dispatch}>
                {children}
            </ProductPortfolioDispatchContext.Provider>
        </ProductPortfolioStateContext.Provider>
    );
}
function useProductPortfolioState() {
    const context = React.useContext(ProductPortfolioStateContext);
    if (context === undefined) {
        throw new Error('useProductSelectedState must be used within a ProductSelectedProvider');
    }
    return context;
}
function useProductPortfolioDispatch() {
    const context = React.useContext(ProductPortfolioDispatchContext);
    if (context === undefined) {
        throw new Error('useProductSelectedDispatch must be used within a ProductSelectedProvider');
    }
    return context;
}
export { ProductPortfolioProvider, useProductPortfolioState, useProductPortfolioDispatch };
