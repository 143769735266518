import { Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ToggleAll } from '../../../../utility/helpers/order-helpers';
import { useTranslation } from 'react-i18next';
import { InfoSubheaderItem } from '../../../../store/reducers/glp';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { getAccountAddress } from '../../../../utility/helpers/address-helpers';
import { getFormattedEachesFromNumber } from '../../../../utility/helpers/shipment-helpers';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { formatNumberWithLocale } from '../../../../utility/helpers/formatters';
import { ProductType } from '../../../../utility/services/orders-service';
import PlanItGuidedConfirmationSubheader from './PlanItGuidedConfirmationSubheader';
import {
    PlanItBulkATMLineItem,
    PlanItBulkATMLinesBySnoSku
} from '../../../../store/reducers/planit-bulk-atm';
import { PlanItGuidedConfirmationSectionHeader } from './PlanItGuidedConfirmationSectionHeader';
import StandardTable from '../../../reusable/molecules/StandardTable';
import PlanItConfirmationColumns from './PlanItGuidedConfirmationColumns';
import PlanItGuidedUploadConfirmationRow from './PlanItGuidedConfirmationRow';

interface Props {
    order: PlanItBulkATMLinesBySnoSku;
    shipToId: number;
    expand: ToggleAll;
    isActive: boolean;
}

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '32px'
    },
    subheaderRoot: {
        padding: '24px 0',
        borderBottom: '5px solid rgba(185, 211, 220, 0.34)'
    },
    quantityContainer: {
        alignContent: 'center'
    }
}));

const PlanItGuidedConfirmationOrderSection = ({ order, shipToId, expand, isActive }: Props) => {
    const classes = useStyles();
    const product = order.lines[0];
    const [shipToName, setShipToName] = useState<string>('');
    const [shipToAddress, setShipToAddress] = useState<string>('');

    const { t } = useTranslation();
    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const getQuantity = (type: string): String => {
        if (product.productType !== type) {
            return '0 PL';
        }
        const pallets = order.totalPalletQuantity;
        const skus = order.lines.length;
        return `${formatNumberWithLocale(cultureCode, pallets)} PL/${skus} ${
            skus > 1 ? t('skus', 'SKUs') : t('sku', 'SKU')
        }`;
    };

    const getTotalEaches = () => {
        let totalEaches = 0;
        order.lines.forEach((line) => {
            if (line && line.palletQuantity && line.quantityPerPallet) {
                totalEaches += line.palletQuantity * line.quantityPerPallet;
            }
        });
        return totalEaches;
    };

    const createSubheader = () => {
        const totalEnteredQuantity = `${formatNumberWithLocale(
            cultureCode,
            order.totalPalletQuantity
        )} PL`;
        const canQuantity = getQuantity(ProductType.Cans);
        const endQuantity = getQuantity(ProductType.Ends);
        const totalEaches = getTotalEaches();
        const formattedEaches = `${getFormattedEachesFromNumber(totalEaches, cultureCode)} ea.`;

        return [
            {
                subtitle: t('totalEnteredQuantity', 'Total Entered Quantity'),
                body: totalEnteredQuantity,
                subtext: formattedEaches
            },
            {
                subtitle: t('canQuantity', 'Can Quantity'),
                body: canQuantity,
                subtext: canQuantity !== '0 PL' && formattedEaches
            },
            {
                subtitle: t('endQuantity', 'End Quantity'),
                body: endQuantity,
                subtext: endQuantity !== '0 PL' && formattedEaches
            }
        ] as InfoSubheaderItem[];
    };

    useEffect(() => {
        if (shipToAccounts && (!shipToName || !shipToAddress)) {
            const shipToAccount = shipToAccounts.find(
                (account) => String(shipToId) === account.accountId
            );
            if (shipToAccount) {
                setShipToName(shipToAccount.name);
                setShipToAddress(getAccountAddress(shipToAccount));
            }
        }
        //eslint-disable-next-line
    }, [shipToAccounts, shipToId]);

    const [columns, setColumns] = useState<any>([]);

    useEffect(() => {
        setColumns(PlanItConfirmationColumns.getPlanItConfigColumns(cultureCode));
    }, [cultureCode]);

    const ProductRow = ({ row }: { row: PlanItBulkATMLineItem }) => {
        return <PlanItGuidedUploadConfirmationRow key={row.productSku} item={row} />;
    };

    const SectionHeader = () => {
        return (
            <Grid container xs={12} className={classes.subheaderRoot}>
                <Grid container item xs={6} justify="flex-start">
                    <PlanItGuidedConfirmationSectionHeader
                        product={product}
                        shipToId={shipToId}
                        shipToName={shipToName}
                        shipToAddress={shipToAddress}
                    />
                </Grid>
                <Grid
                    container
                    item
                    xs={6}
                    justify="flex-end"
                    className={classes.quantityContainer}
                >
                    <PlanItGuidedConfirmationSubheader
                        subheaderItems={createSubheader()}
                        justify="flex-end"
                    />
                </Grid>
            </Grid>
        );
    };

    return (
        <StandardTable
            columns={columns}
            lines={order.lines}
            gridName="Plan It Guided Confirmation Grid"
            rowComponent={ProductRow}
            columnWidths={[12]}
            searchText={false}
            customToolbarItems={SectionHeader()}
            className={classes.root}
            enablePagination={false}
        />
    );
};

export default PlanItGuidedConfirmationOrderSection;
