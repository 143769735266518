import React, { useState, useEffect, ReactNode } from 'react';
import {
    Grid,
    Typography,
    Button,
    makeStyles,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead
} from '@material-ui/core';
import { brightGrey, boldWeight } from '../../../themes/globalConstants';
import { Trans, useTranslation } from 'react-i18next';
import Modal from '../molecules/Modal';
import { CampaignRun } from '../../../store/reducers/product-portfolio';
import { generateKey } from '../../../utility/helpers/order-helpers';
import { formatDate } from '../../../utility/helpers/date-helpers';
import { OrderProductTableRow } from '../../../store/reducers/orders-dashboard';
import { getShapeSizeDescForOrderProduct } from '../../../utility/helpers/production-order-helpers';
import moment from 'moment';
import { getMakeItFirstAvailableDate } from '../../pages/MakeIt/utilities/make-it-utils';

interface Props {
    title: ReactNode;
    open: boolean;
    onClose: () => void;
    campaignRuns: CampaignRun[];
    campaignProducts?: OrderProductTableRow[];
}

const useStyles = makeStyles({
    modal: {
        '& .MuiPaper-root': {
            maxWidth: '52em'
        },
        '& .MuiDialog-paperScrollPaper': {
            borderRadius: 'unset',
            overflow: 'hidden'
        }
    },
    smallModal: {
        '& .MuiPaper-root': {
            maxWidth: '32em'
        },
        '& .MuiDialog-paperScrollPaper': {
            borderRadius: 'unset',
            overflow: 'hidden'
        }
    },
    content: {
        textAlign: 'center',
        letterSpacing: '1px',
        color: brightGrey
    },
    button: {
        height: 40,
        marginTop: 30,
        marginBottom: 30,
        fontSize: 14,
        fontWeight: boldWeight,
        textTransform: 'uppercase'
    },
    productTable: {
        marginTop: '1.5em'
    },
    productTableHeader: {
        '& p': {
            fontWeight: boldWeight
        },
        '& th': {
            border: 'none'
        }
    }
});

const CurrentCampaignsModal = ({ title, open, onClose, campaignRuns, campaignProducts }: Props) => {
    const classes = useStyles();
    const [modalOpen, setModalOpen] = useState(open);
    const [hasCampaignRuns, setHasCampaignRuns] = useState<boolean>(false);

    const closeConfirmationModal = () => {
        setModalOpen(false);
        if (onClose) {
            onClose();
        }
    };

    const firstAvailableDate = (campaignRun: CampaignRun): string => {
        if (moment(campaignRun.orderStartDate).isBefore(moment())) {
            return getMakeItFirstAvailableDate(campaignRun.leadTimeWeeks * 7).toString();
        }
        return campaignRun.firstAvailableOrderDate;
    };

    useTranslation();

    useEffect(() => {
        setHasCampaignRuns(campaignRuns && campaignRuns.length > 0);
    }, [campaignRuns]);

    useEffect(() => {
        setModalOpen(open);
    }, [open]);

    return (
        <Modal
            open={modalOpen}
            close={closeConfirmationModal}
            title={title}
            closeIcon={true}
            fullWidth={true}
            className={hasCampaignRuns ? classes.modal : classes.smallModal}
        >
            <Grid
                container
                justify="center"
                className={classes.content}
                data-testid="campaigns-modal"
            >
                <Grid item>
                    <Grid item>
                        {hasCampaignRuns && (
                            <Table
                                size="small"
                                aria-label="campaign run table"
                                key={generateKey('campaign-run-table')}
                                className={classes.productTable}
                            >
                                <TableHead className={classes.productTableHeader}>
                                    <TableRow>
                                        <TableCell>
                                            <Typography>
                                                <Trans i18nKey="productSizeShape">
                                                    Product (Size / Shape)
                                                </Trans>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>
                                                <Trans i18nKey="requestWindowOpen">
                                                    Request Window Open
                                                </Trans>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>
                                                <Trans i18nKey="requestWindowClosed">
                                                    Request Window Closed
                                                </Trans>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>
                                                <Trans i18nKey="leadTime">Lead Time</Trans>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>
                                                <Trans i18nKey="firstNumAvailableDate">
                                                    1st Available Date
                                                </Trans>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>
                                                <Trans i18nKey="newGraphicApprovalDeadline">
                                                    New Graphic Approval Deadline
                                                </Trans>
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {campaignRuns.map((campaignRun, i) => {
                                        return (
                                            <TableRow key={'campaign-run-row' + i}>
                                                <TableCell>
                                                    <Typography>
                                                        {campaignRun.productDescription}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>
                                                        {formatDate(campaignRun.orderStartDate)}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>
                                                        {formatDate(campaignRun.orderEndDate)}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>
                                                        {campaignRun.leadTimeWeeks}{' '}
                                                        <Trans i18nKey="weeks">Weeks</Trans>
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>
                                                        {formatDate(
                                                            firstAvailableDate(campaignRun)
                                                        )}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>
                                                        {campaignRun.orderStartDate
                                                            ? moment(campaignRun.orderStartDate)
                                                                  .subtract(14, 'days')
                                                                  .format('MM/DD/YYYY')
                                                            : ''}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        )}
                        {!hasCampaignRuns && campaignProducts && (
                            <Table
                                size="small"
                                aria-label="campaign product table"
                                key={generateKey('campaign-product-table')}
                                className={classes.productTable}
                            >
                                <TableHead className={classes.productTableHeader}>
                                    <TableRow>
                                        <TableCell>
                                            <Typography>
                                                <Trans i18nKey="productSizeShape">
                                                    Product (Size / Shape)
                                                </Trans>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography> </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {campaignProducts.map((campaignProduct, i) => {
                                        return (
                                            <TableRow key={'campaign-product-row' + i}>
                                                <TableCell>
                                                    <Typography>
                                                        {getShapeSizeDescForOrderProduct(
                                                            campaignProduct
                                                        )}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>
                                                        <Trans i18nKey="noCampaignsSheduled">
                                                            No campaigns scheduled at this time
                                                        </Trans>
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        )}
                    </Grid>
                    <Grid container item xs={12} alignItems="flex-end" justify="flex-end">
                        <Grid item xs={2}>
                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={closeConfirmationModal}
                                data-testid="close-btn"
                            >
                                <Trans i18nKey="close">Close</Trans>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default CurrentCampaignsModal;
