import { Region } from "../../store/reducers/graphic-intake";
import { ProductConfiguration } from "../../store/reducers/product-configurations";

export const mapConfiguredProduct = (product: any) => {
    return {
        configuredProductId: product.id,
        name: product.name,
        lastModifiedDate: product.modifiedDate,
        productCode: product.sku

    } as ProductConfiguration;
}

export const getKBMaxRegion = (region: string) => {
    switch (region) {
        case Region.NA:
            return 'NCA';
        case Region.EU:
            return 'EMEA';
        case Region.SA:
            return 'SA';
        default:
            return 'NCA';
    }
}