import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import {
    boldWeight,
    blackWeight,
    dateOptionInvalidGray,
    ballBlue,
    small,
    medium,
    regularWeight
} from '../../../../themes/globalConstants';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { ProductionOrder } from '../../../../store/reducers/makeit-dashboard';
import MakeItATMColumns from './MakeItATMColumns';
import MakeItATMRow from './MakeItATMRow';
import { ProductionOrderForTable } from '../models/ProductionOrderForTable';
import moment from 'moment';
import { getCurrentDate } from '../../../../utility/helpers/date-helpers';
import { BulkUploadMakeItState } from '../../../../store/reducers/makeit-bulk-upload';
import { BulkUploadMakeItATMState } from '../../../../store/reducers/makeit-bulk-atm';
import { calculateIsBelowAllocation } from '../utils/makeit-atm-summary-utils';
import { GetFeatureFlag, FlagTypes } from '../../../../utility/helpers/feature-flag';
import SummarySearchBar from '../../../reusable/atoms/SummarySearchBar';
import { getAccountAddress } from '../../../../utility/helpers/address-helpers';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { CSVLink } from 'react-csv';
import ReactPdf from '@react-pdf/renderer';
import StandardTable from '../../../reusable/molecules/StandardTable';

interface Props {
    orders: ProductionOrder[];
    tabType: 'open' | 'closed';
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: '1em'
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        padding: '1em'
    },
    tableWrapper: {
        '& .MuiPaper-root': {
            boxShadow: 'unset'
        },
        '& .MuiTable-root': {
            tableLayout: 'fixed'
        },
        '& .MuiToolbar-root': {
            padding: 0,
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                },
                '&:first-of-type ': {
                    paddingLeft: 0
                }
            },
            '& > h2': {
                fontSize: '1.5em',
                fontWeight: boldWeight,
                color: theme.palette.secondary.main,
                paddingLeft: '0.625em'
            },
            '& .MuiInputBase-formControl': {
                border: `1px solid ${dateOptionInvalidGray}`,
                borderRadius: '.125em',
                '&:before': {
                    display: 'none'
                },
                '&:after': {
                    display: 'none'
                },
                '& svg': {
                    height: '.875em',
                    width: '.875em',
                    fill: ballBlue
                },
                '& .MuiIconButton-root': {
                    '&:hover': {
                        backgroundColor: 'transparent !important'
                    }
                },
                '& .MuiInput-input': {
                    paddingLeft: '.25em'
                }
            }
        },
        '& .MuiTableSortLabel-root': {
            fontSize: small,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em',
            width: '99%',
            justifyContent: 'space-between',
            '& .MuiSvgIcon-root': {
                fontSize: 14,
                opacity: '1'
            }
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                '&:nth-child(5)': {
                    fontSize: small,
                    fontWeight: blackWeight,
                    textTransform: 'uppercase',
                    color: theme.palette.secondary.main,
                    borderRadius: '0.25em',
                    paddingLeft: '0.5em',
                    justifyContent: 'space-between'
                }
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    },
    icon: {
        color: ballBlue
    },
    downloadWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

const pdfStyles = ReactPdf.StyleSheet.create({
    link: {
        fontSize: medium,
        fontWeight: regularWeight
    }
});

const MakeItATMTab = ({ orders, tabType }: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const displayBelowAllocationAlert = false; // adding this in relation to CP-4162 for MVP release

    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { products } = useTypedSelector<BulkUploadMakeItState>(
        (state) => state.bulkUploadMakeItState
    );
    const { allocations } = useTypedSelector<BulkUploadMakeItATMState>(
        (state) => state.bulkUploadMakeItATMState
    );
    const [columns, setColumns] = useState<any>([]);
    const [filteredOrdersForTable, setFilteredOrdersForTable] = useState<any>([]);
    const [ordersForTable, setOrdersForTable] = useState<ProductionOrderForTable[] | undefined>(
        undefined
    );
    const { shipToAccounts, selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );

    // const options = new ToolbarOptions({
    //     advancePagination: false,
    //     exportButton: false,
    //     printButton: false,
    //     searchText: false
    // });

    const hideEditFeatureFlag = GetFeatureFlag(FlagTypes.HideEdit) ?? false;

    useEffect(() => {
        if (orders && (tabType === 'closed' || (allocations && products?.length))) {
            const formattedOrders: ProductionOrderForTable[] = [];

            orders = setClosedDateIndex(orders);
            orders.sort(compareItems);
            orders.forEach((order, index) => {
                let orderForTable: ProductionOrderForTable = {
                    ...order,
                    sortIndex: index,
                    atmWeekStartString: moment(order.atmWeekStart).format('MM/DD/YYYY'),
                    searchablePONumber:
                        order.productionOrderNumber?.toString() ??
                        order.productionOrderId?.toString(),
                    submittedDateString: moment(order.createDate).format('MM/DD/YYYY'),
                    isBelowAllocation:
                        tabType === 'open'
                            ? calculateIsBelowAllocation(order, allocations!, products!) &&
                              order.isEditable &&
                              displayBelowAllocationAlert
                            : false,
                    totalSKUsString: order.totalOrderSKUs.toString(),
                    lastUpdatedDateString: moment(order.updateDate).format('MM/DD/YYYY')
                };
                formattedOrders.push(orderForTable);
            });
            setOrdersForTable(formattedOrders);
        } else {
            setOrdersForTable([]);
        }
    }, [orders, allocations, products]);

    useEffect(() => {
        setColumns(MakeItATMColumns.getColumns(cultureCode, tabType, hideEditFeatureFlag));
    }, [cultureCode, hideEditFeatureFlag, tabType]);

    const CurrentRow = ({ row }: { row: ProductionOrderForTable }) => {
        if (row) {
            return (
                <MakeItATMRow
                    order={row as ProductionOrderForTable}
                    tabType={tabType}
                    key={row.productionOrderId}
                    hideEdit={hideEditFeatureFlag}
                />
            );
        } else {
            return null;
        }
    };

    const compareItems = (
        itemA: ProductionOrderForTable,
        itemB: ProductionOrderForTable
    ): number => {
        const itemADate = moment(itemA.atmWeekStart);
        const itemBDate = moment(itemB.atmWeekStart);

        if (itemADate.isBefore(itemBDate)) {
            return 1;
        } else if (itemADate.isSame(itemBDate)) {
            if (itemA.productionOrderNumber < itemB.productionOrderNumber) {
                return 1;
            } else if (itemA.productionOrderNumber === itemB.productionOrderNumber) {
                return 0;
            } else {
                return -1;
            }
        } else {
            return -1;
        }
    };

    const setClosedDateIndex = (orders: ProductionOrderForTable[]) => {
        return orders
            .sort((orderA, orderB) => {
                const orderADate = moment(orderA.lastUpdatedDateString);
                const orderBDate = moment(orderB.lastUpdatedDateString);

                return orderADate.isBefore(orderBDate) ? 1 : orderADate.isSame(orderBDate) ? 0 : -1;
            })
            .map((order, index) => {
                return { ...order, lastUpdatedDateSortIndex: index };
            });
    };

    const handleRowFilter = (filteredRows) => {
        setFilteredOrdersForTable(filteredRows);
    };

    interface CSVRow {
        ship_to_detail?: string;
        customer_po?: string;
        production_order_number?: string;
        submitted?: string;
        required_ship_date?: string;
        total_order_pallets?: number;
        total_order_eaches?: number;
        total_order_skus: number;
        status?: string;
    }

    const csvHeaders = [
        { label: t('shipTo', 'Ship to').toUpperCase(), key: 'ship_to_detail' },
        { label: t('customerPoNumber', 'CUSTOMER PO#').toUpperCase(), key: 'customer_po' },
        {
            label: t('ballProdOrderNumber', 'BALL PRODUCTION ORDER NUMBER').toUpperCase(),
            key: 'production_order_number'
        },
        { label: t('submitted', 'SUBMITTED').toUpperCase(), key: 'submitted' },
        {
            label: t('requiredShipDate', 'Required Ship Date').toUpperCase(),
            key: 'required_ship_date'
        },
        {
            label: t('totalOrderQuantityPL', 'Total Order Quantity (PL)').toUpperCase(),
            key: 'total_order_pallets'
        },
        {
            label: t('totalOrderQuantityEA', 'Total Order Quantity (ea.)').toUpperCase(),
            key: 'total_order_eaches'
        },
        {
            label: t('totalOrderQuantitySKUS', 'Total Order Quantity (SKUs)').toUpperCase(),
            key: 'total_order_skus'
        },
        { label: t('status', 'STATUS').toUpperCase(), key: 'status' }
    ];

    const csvArray = () => {
        let rows: any[] = [];
        if (!Array.isArray(filteredOrdersForTable) || shipToAccounts?.length === 0) return rows;
        const account = shipToAccounts?.find((account) => account.accountId === selectedAccountId);
        const address = account ? getAccountAddress(account) : undefined;
        filteredOrdersForTable.map((order) => {
            const row: CSVRow = {
                ship_to_detail: `${account?.name}, ${address}, #${account?.accountId}`,
                customer_po: order.customerProductionOrderId || 'N/A',
                production_order_number: order.productionOrderNumber || t('pending', 'Pending'),
                submitted: order.submittedDateString,
                required_ship_date: order.requiredShipDateString || 'N/A',
                total_order_pallets: order.totalOrderQuantity,
                total_order_eaches: order.totalOrderQuantityEaches,
                total_order_skus: order.totalOrderSKUs,
                status: order.status
            };

            rows.push(row);
            return null;
        });
        return rows;
    };

    const csvContents = csvArray();

    return (
        <Paper elevation={2} className={classes.paper}>
            <div className={classes.titleContainer}>
                <Grid className={classes.downloadWrapper}>
                    <CSVLink
                        className={
                            'MuiTypography-root MuiLink-root MuiLink-underlineAlways MuiTypography-colorPrimary'
                        }
                        style={pdfStyles.link}
                        underline="always"
                        data-testid="download-btn"
                        headers={csvHeaders}
                        data={csvContents}
                        filename={`Make it Summary - ${
                            tabType === 'open' ? 'Open' : 'Closed'
                        } Production Orders - ${getCurrentDate()}.csv`}
                    >
                        <Trans i18nKey="downloadCSV">Download .CSV</Trans>
                    </CSVLink>
                    <GetAppOutlinedIcon fontSize="small" className={classes.icon} />
                </Grid>
            </div>
            <Grid className={classes.tableWrapper}>
                <SummarySearchBar
                    rows={ordersForTable || []}
                    columns={columns}
                    onRowFilter={handleRowFilter}
                />
                {ordersForTable && (
                    <StandardTable
                        gridName="Current Orders"
                        columns={columns}
                        lines={filteredOrdersForTable}
                        rowComponent={CurrentRow}
                        searchText={false}
                    />
                )}
            </Grid>
        </Paper>
    );
};

export default MakeItATMTab;
