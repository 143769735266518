import React, { ReactNode } from 'react';
import { Grid, makeStyles, SvgIconTypeMap } from '@material-ui/core';
import { black, blackWeight, disabledGrey, paleGrey } from '../../../themes/globalConstants';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

/**
 * Example usage:
 * <IconLink
 *      icon={
 *           <img
 *              src={process.env.PUBLIC_URL + '/assets/Paper_Icon.svg'}
 *              alt="Paper Icon"
 *           />
 *       }
 *      url="..."
 *      text={t('instructionsLinkText', enUS.instructionsLinkText)}
 *      className={classes.icon}
 *  />
 *
 * OR
 *
 *  * <IconLink
 *      SvgIcon={InsertDriveFileOutlinedIcon}
 *      url="..."
 *      text={t('instructionsLinkText', enUS.instructionsLinkText)}
 *      className={classes.icon}
 *  />
 */
interface Props {
    text: string;
    url: string;
    svgIcon?: ReactNode;
    SvgIcon?: OverridableComponent<SvgIconTypeMap>;
    className?: string;
}

const useStyles = makeStyles(() => ({
    anchor: {
        textDecoration: 'none'
    },
    container: {
        backgroundColor: paleGrey,
        padding: '1.25em 2.5em 1.25em 1.5em',
        flexWrap: 'nowrap'
    },
    text: {
        color: black,
        fontSize: '.875em',
        fontWeight: blackWeight,
        marginLeft: '1.25em',
        whiteSpace: 'nowrap'
    },
    icon: {
        '& svg': {
            color: disabledGrey,
            width: '1.625em',
            height: '2.1875em'
        }
    }
}));

export default function IconLink({ SvgIcon, svgIcon, text, url, className }: Props) {
    const iconClasses = useStyles();

    if (SvgIcon && svgIcon) {
        throw new Error('Expected SvgIcon or svgIcon set on Icon Link component!  Both are set!');
    }

    return (
        <Grid container className={className}>
            <a href={url} className={iconClasses.anchor} target="_blank" rel="noreferrer noopener" data-testid="icon-link">
                <Grid container className={iconClasses.container} alignItems="center">
                    <Grid item className={iconClasses.icon}>
                        {!!svgIcon && svgIcon}
                        {!!SvgIcon && <SvgIcon />}
                    </Grid>
                    <Grid item className={iconClasses.text}>
                        {text}
                    </Grid>
                </Grid>
            </a>
        </Grid>
    );
}
