import React, { useState, useEffect } from 'react';
import { OrderProductTableRow } from '../../../../store/reducers/orders-dashboard';
import ManageProductsColumns from './ManageProductsColumns';
import ManageProductsRow from './ManageProductsRow';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import StandardTable from '../../../reusable/molecules/StandardTable';
import { selectIsLargeCustomerAccount } from '../../../../store/selectors';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Trans } from 'react-i18next';
import {
    boldWeight,
    yellowNoticeBackground,
    yellowNoticeBackgroundBorder
} from '../../../../themes/globalConstants';

interface Props {
    products: OrderProductTableRow[];
    lockPeriodWeeks: number;
    tab?: string;
}

const useStyles = makeStyles((theme) => ({
    boldText: {
        fontWeight: boldWeight
    },
    clockIcon: {
        width: '5%',
        marginRight: '3.75%',
        marginTop: '0.5%',
        float: 'left'
    },
    clockIconContainer: {
        backgroundColor: yellowNoticeBackground,
        borderWidth: 1,
        borderColor: yellowNoticeBackgroundBorder,
        borderStyle: 'solid',
        width: '40%',
        padding: '8px 16px'
    },

    warningContainer: {
        position: 'relative',
        height: '0px',
        paddingTop: '20px',
        paddingLeft: '30px'
    }
}));

export default function ManageProductsTab({ products, lockPeriodWeeks, tab }: Props) {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const [columns, setColumns] = useState<any>([]);

    const isLargeCustomerAccount = useTypedSelector(selectIsLargeCustomerAccount);

    useEffect(() => {
        setColumns(ManageProductsColumns.getColumns(cultureCode, isLargeCustomerAccount));
    }, [cultureCode, isLargeCustomerAccount]);

    const ManageProductsLine = ({ row }: { row: OrderProductTableRow }) => {
        if (row) {
            return (
                <ManageProductsRow
                    tab={tab}
                    key={row.productId?.toString()}
                    lockPeriodWeeks={lockPeriodWeeks}
                    line={row as OrderProductTableRow}
                />
            );
        } else {
            return null;
        }
    };

    return (
        <>
            {tab === 'Inactive' && (
                <div className={classes.warningContainer}>
                    <Grid
                        container
                        justify="flex-start"
                        alignItems="flex-start"
                        className={classes.clockIconContainer}
                    >
                        <img
                            data-testid="info-icon-yellow"
                            className={classes.clockIcon}
                            src={process.env.PUBLIC_URL + '/assets/Info_Icon_yellow.svg'}
                            alt="Graphic ID assigned"
                        />
                        <Grid>
                            <Typography className={classes.boldText}>
                                <Trans i18nKey="assignedGraphicId">
                                    Indicates Graphic ID is assigned to a current active product
                                </Trans>
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            )}
            <StandardTable
                gridName={'Manage Products' + tab}
                columns={columns}
                lines={products}
                rowComponent={ManageProductsLine}
                columnWidths={
                    isLargeCustomerAccount ? [13, 32, 10, 10, 10, 17, 8] : [13, 38, 10, 8, 15, 8]
                }
            />
        </>
    );
}
