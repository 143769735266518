import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class ClosedLiquidTestColumns {
    static getClosedLiquidTestColumns = (culture: string) => {
        return [
            createColumn('sortIndex', {
                searchable: false,
                sortable: false,
                filterable: false,
                visible: false,
                dataType: ColumnDataType.Numeric
            }),
            createColumn('liquidTestId', {
                isKey: true,
                searchable: false,
                sortable: false,
                filterable: false,
                visible: false
            }),
            createColumn('projectNameBeverageName', {
                searchable: true,
                filterable: true,
                sortable: true,
                label: `${TranslationService.getTranslatedText(
                    culture,
                    'projectName'
                )}/${TranslationService.getTranslatedText(culture, 'beverageName')}`,
                dataType: ColumnDataType.String
            }),
            createColumn('graphicID', {
                searchable: true,
                filterable: true,
                sortable: true,
                label: TranslationService.getTranslatedText(culture, 'graphicID'),
                dataType: ColumnDataType.String
            }),
            createColumn('liquidTestDocuments', {
                searchable: false,
                filterable: false,
                sortable: true,
                label: TranslationService.getTranslatedText(culture, 'liquidTestDocuments')
            }),
            createColumn('beverageCategory', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'beverageCategory'),
                searchable: true,
                filterable: true,
                sortable: true
            }),
            createColumn('statusUpdatedDateTime', {
                label: TranslationService.getTranslatedText(culture, 'closedDate'),
                searchable: true,
                filterable: true,
                sortable: true,
                dataType: ColumnDataType.Date
            }),

            createColumn('submitterEmail', {
                label: TranslationService.getTranslatedText(culture, 'submittingUser'),
                searchable: true,
                filterable: true,
                sortable: true
            }),
            createColumn('status', {
                label: TranslationService.getTranslatedText(culture, 'status'),
                searchable: true,
                filterable: true,
                sortable: true
            }),
            createColumn('testRequired', {
                dataType: ColumnDataType.Boolean,
                visible: false
            }),
            createColumn('beverageName', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('projectName', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('companyName', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('billTo', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('newOrExisting', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('id', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('abvPercent', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('pH', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('isSimpleBeer', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('beverageDescription', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('refrigerationRequired', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('concentrateSample', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('throwRatio', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('beverageContentList', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('createDate', {
                dataType: ColumnDataType.String,
                visible: false
            })
        ];
    };
}
