import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class PlanItBulkReviewOrderSectionColumns {
    static getColumns = (culture: string, isBulkUpload: boolean, hasMultiplePersonas: boolean) => {
        return [
            createColumn('displayId', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('originalCsvLineNumber', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'csvRowNumber'),
                visible: isBulkUpload
            }),
            createColumn('productGraphicId', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'productGraphicId')
            }),
            createColumn('displayName', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'productDescription')
            }),
            createColumn('productStatus', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'status')
            }),
            createColumn('coating', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'coating')
            }),
            createColumn('minimumOrderQuantity', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'makeItMinimumOrderQuantity')
            }),
            createColumn('moqFees', {
                dataType: ColumnDataType.Numeric,
                visible:false
            }),
            createColumn('leadTime', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'leadTime'),
                visible: hasMultiplePersonas
            }),
            createColumn('originalPalletQuantity', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'previousForecastedQuantity'),
                visible: true
            }),
            createColumn('originalQuantity', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('palletQuantity', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'revisedForecastedQuantity')
            }),
            createColumn('productId', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('graphicIdAndVersion', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('eachesQuantity', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('leadTimeWeeks', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('minimumOrderQuantity', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('userSuppliedProductId', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('quantityPerPallet', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('palletsRounded', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('isCustomerProductIdDistinct', {
                dataType: ColumnDataType.Boolean,
                visible: false
            }),
            createColumn('candidateProductSkus', {
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('customerProductId', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productSku', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('notIncludedInCsv', {
                dataType: ColumnDataType.Boolean,
                visible: false
            }),
            createColumn('', {
                dataType: ColumnDataType.String,
                visible: true
            })
        ];
    };
}
