import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { Trans } from "react-i18next";
import { boldWeight, tealPill, white } from "../../../themes/globalConstants";
import clsx from 'clsx';

type Props = {
    styles?: string;
}

const useStyles = makeStyles((theme) => ({
    campaignPill: {
        fontWeight: boldWeight,
        padding: '0 1em',
        color: white,
        background: tealPill,
        borderRadius: '1em',
        display: 'inline-block',
        marginTop: '0.5em',
        height: '1.5em'
    },
    campaignPillText: {
        padding: '1.5em 0'
    }
}));
const CampaignPill = ({ styles }: Props) => {
    const classes = useStyles();
    return (
        <Typography data-testid="campaign-pill" className={clsx(styles, classes.campaignPill)}>
            <Trans i18nKey="campaign" className={classes.campaignPillText}>Campaign</Trans>
        </Typography>
    );
};
export default CampaignPill;
