import React, { useState, useEffect } from 'react';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { Button, makeStyles, Paper, Grid, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import {
    blackWeight,
    boldWeight,
    ballGray,
    vanilla,
    yellowPill,
    medium,
    containerMaxWidth
} from '../../../themes/globalConstants';
import { Alert } from '@material-ui/lab';
import { ShippingState } from '../../../store/reducers/shipping-dashboard';
import DeliverySubheader from './components/DeliverySubheader';
import { useHistory } from 'react-router-dom';
import ShipmentConfirmationSection from './components/ShipmentConfirmationSection';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { formatFromDatetime } from '../../../utility/helpers/date-helpers';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import ShipItShareLinks from './components/ShipItShareLinks';
import DisableBackButton from '../../reusable/atoms/DisableBackButton';
import { Activity } from '../../../utility/auth/useSecurity';
import { selectIsLargeCustomerAccount } from '../../../store/selectors';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: '3em',
        padding: '2em',
        borderRadius: 4,
        maxWidth: containerMaxWidth
    },
    header: {
        marginTop: '1em',
        marginBottom: '2em'
    },
    actionBtn: {
        borderRadius: 'unset'
    },
    title: {
        fontWeight: blackWeight,
        color: ballGray,
        marginTop: '.25em'
    },
    warning: {
        padding: '.125em 1.5em .125em 2.125em',
        backgroundColor: vanilla,
        '& .MuiAlert-icon': {
            color: yellowPill
        }
    },
    alertText: {
        fontSize: medium,
        fontWeight: boldWeight
    }
}));

const ShipItConfirmation = () => {
    const classes = useStyles();
    const [shipToName, setShipToName] = useState('');
    const [displayAddress, setDisplayAddress] = useState('');
    const [shipToId, setShipToId] = useState('');
    const { shipToAccounts, selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { deliveryOrder } = useTypedSelector<ShippingState>((state) => state.shippingDashboard);
    const accountInformation =
        shipToAccounts &&
        shipToAccounts.filter((account) => account.accountId === selectedAccountId);
    const dashboardLink = '/dashboard';
    const history = useHistory();
    const { t } = useTranslation();
    const isLargeCustomerAccount = useTypedSelector<boolean>(selectIsLargeCustomerAccount);

    DisableBackButton('/ship-it-configuration', '/ship-it-confirmation');

    const date = formatFromDatetime(moment().format());

    const handleDashboardNavigation = () => {
        history.push(dashboardLink);
    };

    const footerActions = (
        <Button
            type="button"
            color="primary"
            variant="contained"
            data-testid="dashboard-btn"
            className={classes.actionBtn}
            onClick={handleDashboardNavigation}
        >
            <Trans i18nKey="myDashboard">My Dashboard</Trans>
        </Button>
    );

    const renderShipments = deliveryOrder?.shipments?.map((shipment, i) => {
        return (
            <ShipmentConfirmationSection
                key={shipment.id}
                shipmentIndex={i}
                currentShipment={shipment}
            />
        );
    });

    useEffect(() => {
        if (deliveryOrder) {
            if (accountInformation && accountInformation[0]) {
                setShipToName(accountInformation[0].name);
                setShipToId(accountInformation[0].accountId);
                if (accountInformation[0].address) {
                    const shipToAddress = accountInformation[0].address;
                    setDisplayAddress(
                        shipToAddress.address1?.trimEnd() +
                            ', ' +
                            shipToAddress.city +
                            ', ' +
                            shipToAddress.stateProvince +
                            ' ' +
                            shipToAddress.postalCode
                    );
                }
            }
        }
    }, [accountInformation, deliveryOrder]);

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('shipIt', 'Ship It'),
                description: t('orderSubmitted', 'THANK YOU, YOUR ORDER HAS BEEN SUBMITTED'),
                thinBanner: true,
                displayDropdown: false
            }}
            actionFooter={{
                footerAction: footerActions,
                justify: 'center',
                sticky: true
            }}
            activity={Activity.NewOpenDeliveryOrders}
            shipTos={false}
        >
            <Paper elevation={2} className={classes.paper} data-testid="shipit-conf-page">
                <Grid container alignItems="flex-start">
                    <Grid container className={classes.header}>
                        <Grid container item xs={3} justify="flex-start">
                            <Typography variant="h3" className={classes.title}>
                                <Trans i18nKey="deliveryOrderPending">
                                    Delivery Order # Pending
                                </Trans>
                            </Typography>
                        </Grid>
                        <Grid container item xs={6} justify="center">
                            {!isLargeCustomerAccount && (
                                <Alert
                                    severity="warning"
                                    className={classes.warning}
                                    data-testid="carrier-alert"
                                >
                                    <Trans
                                        i18nKey="deliveryConfirmationCarrierAlert"
                                        className={classes.alertText}
                                    >
                                        Carrier will be assigned 7 days prior to scheduled delivery
                                        date.
                                    </Trans>
                                </Alert>
                            )}
                        </Grid>
                        <Grid container item xs={3} justify="flex-end">
                            {deliveryOrder && (
                                <ShipItShareLinks
                                    dateSubmitted={date}
                                    deliveryOrder={deliveryOrder}
                                    shipToAddress={displayAddress}
                                    shipToName={shipToName}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid container>
                        <DeliverySubheader
                            order={deliveryOrder}
                            shipToAddress={displayAddress}
                            shipToName={shipToName}
                            shipToId={shipToId}
                        />
                    </Grid>
                </Grid>
                {renderShipments}
            </Paper>
        </ProcessingPageTemplate>
    );
};

export default ShipItConfirmation;
