import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { medium, ballBlue, regularWeight } from '../../../../themes/globalConstants';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { MakeItBulkATMOrder } from '../../../../store/reducers/makeit-bulk-atm';
import { getFormattedDateStringForApplication } from '../../../../utility/helpers/date-helpers';
import { enUS } from '../../../../utility/translations/locales';
import { CSVLink } from 'react-csv';
import ReactPdf from '@react-pdf/renderer';

interface Props {
    shipToName: string;
    shipToAddress: string;
    atmProductionOrder: MakeItBulkATMOrder;
}

const useStyles = makeStyles((theme) => ({
    label: {
        textTransform: 'uppercase',
        fontSize: medium
    },
    icon: {
        color: ballBlue
    },
    linkContainer: {
        display: 'flex',
        alignItems: 'center'
    }
}));

const pdfStyles = ReactPdf.StyleSheet.create({
    link: {
        fontSize: medium,
        fontWeight: regularWeight,
        maxWidth: '8.75em'
    }
});

const PlanItOrderDetailsShareLinks = (props: Props) => {
    const classes = useStyles();

    useTranslation();

    const { t } = useTranslation();

    useTranslation();

    interface CSVRow {
        shipToId?: number;
        forecastWeek?: string;
        productId?: string;
        productDescription?: string;
        coating?: string;
        forecastedQuantityPL?: number;
        forecastedQuantityEA?: number;
    }

    const csvHeaders = [
        { label: 'SHIP TO ID', key: 'shipToId' },
        { label: 'FORECAST WEEK', key: 'forecastWeek' },
        { label: 'PRODUCT ID', key: 'productId' },
        { label: 'PRODUCT DESCRIPTION', key: 'productDescription' },
        { label: 'COATING', key: 'coating' },
        { label: 'FORECASTED QUANTITY (PL)', key: 'forecastedQuantityPL' },
        { label: 'FORECASTED QUANTITY (EA)', key: 'forecastedQuantityEA' }
    ];

    const translateCSVHeaders = () => {
        csvHeaders.forEach((header) => {
            header.label = t(header.key, header.label).toUpperCase();
        });
        return csvHeaders;
    };

    const csvArray = () => {
        let rows: any[] = [];
        props.atmProductionOrder.lines?.map((line) => {
            const eachesAmount =
                line.quantityPerPallet && line.palletQuantity
                    ? line.quantityPerPallet * line.palletQuantity
                    : 0;
            const row: CSVRow = {
                shipToId: props.atmProductionOrder.shipToId,
                forecastWeek: getFormattedDateStringForApplication(
                    props.atmProductionOrder.weekStart
                ),
                productId: line.displayId,
                productDescription: line.displayName,
                coating: line.coating,
                forecastedQuantityPL: line.palletQuantity,
                forecastedQuantityEA: eachesAmount
            };
            rows.push(row);
            return null;
        });
        return rows;
    };

    const csvContents = csvArray();

    return (
        <Grid container item xs={12} spacing={1} alignItems="baseline" justify="flex-end">
            <Grid item data-testid="share-btn">
                <Typography variant="h6" className={classes.label}>
                    <Trans i18nKey="share">share</Trans>
                </Typography>
            </Grid>
            <Grid item className={classes.linkContainer}>
                <CSVLink
                    className={
                        'MuiTypography-root MuiLink-root MuiLink-underlineAlways MuiTypography-colorPrimary'
                    }
                    style={pdfStyles.link}
                    underline="always"
                    data-testid="download-btn"
                    headers={translateCSVHeaders()}
                    uFEFF={false}
                    data={csvContents}
                    filename={`${props.atmProductionOrder.shipToId}_${t(
                        'forecast',
                        enUS.forecast
                    )}_${getFormattedDateStringForApplication(
                        props.atmProductionOrder.weekStart
                    )}.csv`}
                >
                    <Trans i18nKey="downloadCSV">Download .CSV</Trans>
                </CSVLink>
                <GetAppOutlinedIcon fontSize="small" className={classes.icon} />
            </Grid>
        </Grid>
    );
};

export default PlanItOrderDetailsShareLinks;
