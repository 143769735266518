import React, { ReactNode } from 'react';

export interface OrderState {
    markedRemoved: boolean;
    lineCount: number;
}

export interface ProviderProps {
    children: ReactNode;
    value: OrderState;
}

const OrderContext = React.createContext<OrderState>({} as OrderState);

function MakeItBulkUploadReviewOrderSectionProvider(props: ProviderProps) {
    const { children, value } = props;
    return <OrderContext.Provider value={value}> {children} </OrderContext.Provider>;
}

const useMakeItBulkUploadReviewOrderSection = () => React.useContext(OrderContext);

export { MakeItBulkUploadReviewOrderSectionProvider, useMakeItBulkUploadReviewOrderSection };
