import React from "react";
import { Trans } from "react-i18next";
import { ProductionOrderLine } from "../../../../store/reducers/makeit-dashboard";
import ConfirmationModal from "../../../reusable/molecules/ConfirmationModal";

interface Props {
    onRemoveProduct: (line: ProductionOrderLine) => void;
    onClose: () => void;
    open: boolean;
    line?: ProductionOrderLine;
}

const RemoveProductFromOrderModal = ({ onRemoveProduct, open, onClose, line }: Props) => {
    const productId = line?.displayId;

    return (
        <>
            { line && (
                <ConfirmationModal
                    title={<Trans i18nKey="pleaseConfirmRemoveOrder">Please confirm you want to remove this order</Trans>}
                    open={open}
                    logo={false}
                    subheader={
                        <Trans i18nKey="areYouSureYouWantToRemoveProduct">
                            Are you sure you want to remove Product ID {{ productId }} from your order?
                </Trans>
                    }
                    cancelText={<Trans i18nKey="no">No</Trans>}
                    continueText={<Trans i18nKey="yesRemove">Yes, Remove</Trans>}
                    saveProgress={() => onRemoveProduct(line)}
                    onClose={onClose}
                    onCancel={onClose}
                    canSaveDraft={true}
                />
            )}
        </>
    )
}
export default RemoveProductFromOrderModal;