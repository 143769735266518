import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class MakeItConfigColumns {
    static getMakeItConfigColumns = (
        culture: string,
        sortable: boolean,
        isConfirmation: boolean,
        isBulk = false
    ) => {
        return [
            createColumn('productSku', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('originalCsvLineNumber', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'csvRowNumber'),
                filterable: false,
                sortable: sortable,
                searchable: false,
                visible: isBulk
            }),
            createColumn('productId', {
                dataType: ColumnDataType.Numeric,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('displayId', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'customerProductGraphicId'),
                filterable: true,
                sortable: sortable,
                searchable: false
            }),
            createColumn('graphicIdAndVersion', {
                dataType: ColumnDataType.String,
                filterable: true,
                sortable: sortable,
                searchable: true,
                visible: false
            }),
            createColumn('graphicId', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('graphicVersion', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('displayName', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'descriptionCharacteristics'),
                filterable: true,
                sortable: sortable,
                searchable: false
            }),
            createColumn('description', {
                dataType: ColumnDataType.String,
                filterable: false,
                sortable: sortable,
                searchable: false,
                visible: false
            }),
            createColumn('name', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('size', {
                dataType: ColumnDataType.Numeric,
                filterable: true,
                sortable: sortable,
                searchable: false,
                visible: false
            }),
            createColumn('unit', {
                dataType: ColumnDataType.String,
                filterable: true,
                sortable: sortable,
                searchable: false,
                visible: false
            }),
            createColumn('shape', {
                dataType: ColumnDataType.String,
                filterable: true,
                sortable: sortable,
                searchable: false,
                visible: false
            }),
            createColumn('neckDiameter', {
                dataType: ColumnDataType.String,
                filterable: true,
                sortable: sortable,
                searchable: false,
                visible: false
            }),
            createColumn('endProfile', {
                dataType: ColumnDataType.String,
                filterable: true,
                sortable: sortable,
                searchable: false,
                visible: false
            }),
            createColumn('tabColor', {
                dataType: ColumnDataType.String,
                filterable: true,
                sortable: sortable,
                searchable: false,
                visible: false
            }),
            createColumn('type', {
                dataType: ColumnDataType.String,
                filterable: true,
                sortable: sortable,
                searchable: false,
                visible: false
            }),
            createColumn('coating', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'coating'),
                filterable: true,
                sortable: sortable,
                searchable: false
            }),
            createColumn('productCoating', {
                dataType: ColumnDataType.String,
                filterable: false,
                sortable: sortable,
                searchable: false,
                visible: false
            }),
            createColumn('leadTime', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'leadTime'),
                filterable: true,
                sortable: sortable,
                searchable: false,
                visible: !isConfirmation
            }),
            createColumn('requestedDate', {
                dataType: ColumnDataType.DateTime,
                label: TranslationService.getTranslatedText(culture, 'requestedDate'),
                filterable: true,
                sortable: sortable,
                searchable: false
            }),
            createColumn('requiredShipDate', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'requiredShipDate'),
                filterable: true,
                sortable: sortable,
                searchable: false
            }),
            createColumn('quantityPerPallet', {
                dataType: ColumnDataType.Numeric,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('minimumOrderQuantity', {
                dataType: ColumnDataType.Numeric,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('purchaseQty', {
                dataType: ColumnDataType.Numeric,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('leadTimeWeeks', {
                dataType: ColumnDataType.Numeric,
                label: ' ',
                filterable: true,
                sortable: sortable,
                searchable: false,
                visible: false
            }),
            createColumn('minimumMet', {
                dataType: ColumnDataType.Boolean,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('quantity', {
                dataType: ColumnDataType.Numeric,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('palletQuantity', {
                dataType: ColumnDataType.Numeric,
                label: isConfirmation
                    ? TranslationService.getTranslatedText(culture, 'quantityOrdered')
                    : TranslationService.getTranslatedText(culture, 'quantityToPurchase'),
                filterable: false,
                sortable: sortable,
                searchable: false,
                visible: true
            }),
            createColumn('eachesQuantity', {
                dataType: ColumnDataType.Numeric,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('withinRange', {
                dataType: ColumnDataType.Boolean,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('subRows', {
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('childLines', {
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('addDisabled', {
                dataType: ColumnDataType.Boolean,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('customerProductId', {
                dataType: ColumnDataType.String,
                filterable: false,
                sortable: sortable,
                searchable: true,
                visible: false
            }),
            createColumn('customerProductName', {
                dataType: ColumnDataType.String,
                filterable: false,
                sortable: sortable,
                searchable: true,
                visible: false
            }),
            createColumn('firstAvailableDate', {
                dataType: ColumnDataType.String,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('customerProductionOrderId', {
                dataType: ColumnDataType.String,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('shipToId', {
                dataType: ColumnDataType.Numeric,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('isCustomerProductIdDistinct', {
                dataType: ColumnDataType.Boolean,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('candidateProductSkus', {
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('modified', {
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('palletsRounded', {
                visible: false
            }),
            createColumn('campaignTiming', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('campaignRuns', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('moqFees', {
                visible: false
            })
        ];
    };
}
