import React, { useEffect, useState } from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { blackWeight, ballGray, ballDrkBlue, xxl, large } from '../../../themes/globalConstants';
import { DeliveryShipment } from '../../../store/reducers/shipping-dashboard';
import { getFullFormattedDateTime } from '../../../utility/helpers/date-helpers';
import { Trans } from 'react-i18next';
import clsx from 'clsx';
import { formatEachesOrPallets } from '../../../utility/helpers/shipment-helpers';
import { ProductType } from '../../../utility/services/orders-service';

interface Props {
    currentShipment: DeliveryShipment;
    shipmentIndex: number;
    title: any;
    shipmentNumber?: number;
    subtitle?: any;
    edited?: boolean;
    cancelled?: boolean;
}

const useStyles = makeStyles((theme) => ({
    main: {
        paddingTop: '3em',
        width: '100%'
    },
    cardSubheader: {
        color: ballGray
    },
    dateDescription: {
        color: ballGray,
        display: 'flex',
        alignItems: 'center'
    },
    altDescriptionColor: {
        color: ballDrkBlue
    },
    dateDescriptionMain: {
        fontSize: xxl,
        fontWeight: blackWeight
    },
    cardDetailsWrapper: {
        '& .MuiGrid-item': {
            paddingLeft: '2em'
        }
    },
    shipmentSubtitle: {
        fontSize: large
    }
}));

export default function ShipmentTableSubheader({
    currentShipment,
    title,
    shipmentNumber,
    subtitle,
    edited,
    cancelled
}: Props) {
    const classes = useStyles();
    const [isCanType, setIsCanType] = useState<boolean>(true);

    useEffect(() => {
        currentShipment.shipmentType && currentShipment.shipmentType === ProductType.Ends
            ? setIsCanType(false)
            : setIsCanType(true);
        // Runs only once
    }, []);

    const titleDayAndTime = getFullFormattedDateTime('', title.value);
    const subtitleDayAndTime = Date.parse(subtitle.value)
        ? getFullFormattedDateTime('', subtitle.value)
        : subtitle.value;

    return (
        <>
            <Grid container item className={classes.main}>
                <Grid container item md={7} className={classes.dateDescription}>
                    {edited && !cancelled && (
                        <>
                            <Grid item xs={12}>
                                <Typography
                                    className={clsx(
                                        classes.dateDescriptionMain,
                                        classes.altDescriptionColor
                                    )}
                                >
                                    <Trans i18nKey="shipmentEdited">Shipment Edited</Trans>
                                    {', '}
                                    {title.key}
                                    {': '}
                                    {titleDayAndTime}
                                </Typography>
                            </Grid>
                            {shipmentNumber && (
                                <Grid item xs={12}>
                                    <Typography className={classes.shipmentSubtitle}>
                                        <Trans i18nKey="shipment">Shipment</Trans>
                                        {': '}
                                        {shipmentNumber}
                                    </Typography>
                                </Grid>
                            )}
                            {subtitle && (
                                <Grid item xs={12}>
                                    <Typography className={classes.shipmentSubtitle}>
                                        {subtitle.key}
                                        {': '}
                                        {subtitleDayAndTime}
                                    </Typography>
                                </Grid>
                            )}
                        </>
                    )}
                    {cancelled && (
                        <>
                            <Grid item xs={12}>
                                <Typography
                                    className={clsx(
                                        classes.dateDescriptionMain,
                                        classes.altDescriptionColor
                                    )}
                                >
                                    <Trans i18nKey="shipmentCancelled">Shipment Cancelled</Trans>
                                </Typography>
                            </Grid>
                            {shipmentNumber && (
                                <Grid item xs={12}>
                                    <Typography className={classes.shipmentSubtitle}>
                                        <Trans i18nKey="shipment">Shipment</Trans>
                                        {': '}
                                        {shipmentNumber}
                                    </Typography>
                                </Grid>
                            )}
                            {subtitle && (
                                <Grid item xs={12}>
                                    <Typography className={classes.shipmentSubtitle}>
                                        {subtitle.key}
                                        {': '}
                                        {subtitleDayAndTime}
                                    </Typography>
                                </Grid>
                            )}
                        </>
                    )}
                    {!cancelled && !edited && (
                        <>
                            <Grid item xs={12}>
                                <Typography
                                    className={clsx(
                                        classes.dateDescriptionMain,
                                        classes.altDescriptionColor
                                    )}
                                >
                                    {title.key}
                                    {': '}
                                    {titleDayAndTime}
                                </Typography>
                            </Grid>
                            {shipmentNumber && (
                                <Grid item xs={12}>
                                    <Typography className={classes.shipmentSubtitle}>
                                        <Trans i18nKey="shipment">Shipment</Trans>
                                        {': '}
                                        {shipmentNumber}
                                    </Typography>
                                </Grid>
                            )}
                            {subtitle && (
                                <Grid item xs={12}>
                                    <Typography className={classes.shipmentSubtitle}>
                                        {subtitle.key}
                                        {': '}
                                        {subtitleDayAndTime}
                                    </Typography>
                                </Grid>
                            )}
                        </>
                    )}
                </Grid>
                <Grid container item md={5} className={classes.cardDetailsWrapper}>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2" className={classes.cardSubheader}>
                            <Trans i18nKey="type">Type</Trans>
                        </Typography>
                        <Typography>
                            {isCanType ? (
                                <Trans i18nKey="cansBottles">Cans/Bottles</Trans>
                            ) : (
                                <Trans i18nKey="endClosures">End/Closures</Trans>
                            )}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2" className={classes.cardSubheader}>
                            <Trans i18nKey="quantity">Quantity</Trans>
                        </Typography>
                        <Typography data-testid="pallet-quantity">
                            {currentShipment.shipmentQuantity} PL
                        </Typography>
                        <Typography className="helper-text" data-testid="eaches-quantity">
                            {currentShipment.shipmentQuantityEaches &&
                                formatEachesOrPallets(
                                    currentShipment.shipmentQuantityEaches,
                                    'eaches'
                                )}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2" className={classes.cardSubheader}>
                            <Trans i18nKey="products">Products</Trans>
                        </Typography>
                        <Typography>{currentShipment.shipmentSKUs}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
