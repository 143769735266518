import { makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AuthState } from '../../../store/reducers/auth';
import { OnboardingDashboardState } from '../../../store/reducers/onboarding-dashboard';
import { useTypedSelector } from '../../../store/reducers/reducer';
import {
    fetchAccountStatus,
    fetchBillingAndShippingForm,
    fetchCreditApplication,
    fetchSalesAndUseTaxApp,
    resetAccountStatus
} from '../../../store/root-actions';
import { lato } from '../../../themes/globalConstants';
import { Activity } from '../../../utility/auth/useSecurity';
import { JoiningStatus, OnboardingStepStatus } from '../../../utility/services/onboarding-service';
import { enUS } from '../../../utility/translations/locales';
import ToasterAlert from '../../reusable/molecules/ToasterAlert';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import OnboardingWizardStepper from './components/OnboardingWizardStepper';

const useStyles = makeStyles(() => ({
    onboard: {
        fontFamily: lato,
        marginBottom: '4em'
    }
}));

export default function OnboardingDashboard() {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { accountStatus, inviteSuccess } = useTypedSelector<OnboardingDashboardState>(
        (state) => state.onboardingDashboardState
    );
    const auth = useTypedSelector<AuthState>((state) => state.auth);

    useEffect(() => {
        if (auth.permissions) {
            dispatch(fetchAccountStatus());
        }
        return () => {
            dispatch(resetAccountStatus());
        };
    }, [auth.permissions]);

    useEffect(() => {
        if (auth.userInfo && accountStatus) {
            switch (accountStatus.status) {
                case 'success':
                    if (accountStatus.taxAppStatus === OnboardingStepStatus.INCOMPLETE) {
                        dispatch(fetchSalesAndUseTaxApp());
                    }
                    if (accountStatus.creditAppStatus === OnboardingStepStatus.INCOMPLETE) {
                        dispatch(fetchCreditApplication());
                    }
                    //Always attempt to load billing & shipping
                    dispatch(fetchBillingAndShippingForm());
                    break;
                default:
                    break;
            }
        }
    }, [auth.userInfo, accountStatus.status]);

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('joiningBall', enUS.joiningBall),
                description: '',
                thinBanner: true,
                displayDropdown: false
            }}
            activity={Activity.OnboardingWizard}
            shipTos={false}
        >
            <div data-testid="onboarding-dashboard" className={classes.onboard}>
                <OnboardingWizardStepper
                    processComplete={accountStatus.joiningStatus === JoiningStatus.COMPLETE}
                />
            </div>
            <ToasterAlert
                message={t('inviteUserSuccess', `${enUS.inviteUserSuccess}`)}
                showAlert={inviteSuccess}
                dataTestId="new-user-added-success-alert"
                severity={'success'}
            />
        </ProcessingPageTemplate>
    );
}
