import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { makeStyles, Box, Typography, Tabs, Tab, Grid } from '@material-ui/core';
import { small, blackWeight, inactiveTabBlue, lato, xl } from '../../../../themes/globalConstants';
import { useTranslation } from 'react-i18next';
import { enUS } from '../../../../utility/translations/locales';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { useDispatch } from 'react-redux';
import { AuthState } from '../../../../store/reducers/auth';
import MyAccountMyAccessTab from './MyAccountMyAccessTab';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { getUserCustomerAccounts } from '../../../../store/actions/customer-context';
import MyAccountPendingRequestsTab from './MyAccountPendingRequestsTab';
import { getPermissionChangesForUser } from '../../../../store/root-actions';
import { MyAccountDashboardState } from '../../../../store/reducers/user-admin';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

interface Props {
    dashboardLoading: Dispatch<SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: '3.5em',
        position: 'relative',
        '& h2': {
            fontSize: xl,
            fontFamily: lato,
            fontWeight: blackWeight,
            color: theme.palette.secondary.main
        }
    },
    appBar: {
        width: '75%'
    },
    tabPanel: {
        width: '100%'
    },
    panelHeader: {
        marginBottom: '1em'
    },
    counter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '0.5em',
        fontSize: small,
        fontWeight: blackWeight
    },
    tabs: {
        height: '3em'
    },
    tabLabel: {
        backgroundColor: inactiveTabBlue,
        borderTopLeftRadius: '0.625em',
        borderTopRightRadius: '0.625em',
        maxWidth: 'fit-content',
        '&:not(:first-of-type)': {
            marginLeft: '0.25em'
        }
    },
    tabLabelText: {
        fontSize: small,
        fontWeight: blackWeight
    },
    formLinkWrapper: {
        position: 'absolute',
        marginTop: '-4em',
        [theme.breakpoints.down('md')]: {
            position: 'inherit'
        }
    },
    formLinkIcon: {
        marginLeft: '0.25em'
    }
}));

const TabPanel = (props: TabPanelProps) => {
    const classes = useStyles();
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`my-account-dashboard-tabpanel-${index}`}
            aria-labelledby={`my-account-dashboard-tab-${index}`}
            {...other}
            className={classes.tabPanel}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
};

export default function MyAccountDashboardTabs({ dashboardLoading }: Props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [value, setValue] = useState<Number>(0);
    const { accessToken, permissions } = useTypedSelector<AuthState>((state) => state.auth);
    const [locations, setLocations] = useState<string[]>([]);
    const { accounts } = useTypedSelector<CustomerContextState>((state) => state.customerContext);
    const { permissionChanges } = useTypedSelector<MyAccountDashboardState>(
        (state) => state.myAccountDashboard
    );

    useEffect(() => {
        dispatch(getPermissionChangesForUser());
    }, []);

    useEffect(() => {
        if (permissions && !accounts?.length) {
            dispatch(getUserCustomerAccounts());
        }
    }, [dispatch, accessToken, permissions]);

    useEffect(() => {
        if (permissions && accounts) {
            const accountIds = Array.from(
                new Set(
                    Array.prototype.concat(
                        ...permissions.map((permission) => permission.accountIds)
                    )
                )
            );
            setLocations(
                accountIds.filter((accountId) =>
                    accounts.map((a) => a.accountId).includes(accountId)
                )
            );
        }
    }, [permissions, accounts]);

    const TabLabel = (label: string, count: number) => {
        return (
            <Grid container justify="center" alignItems="center">
                <Typography className={classes.tabLabelText}>{label}</Typography>
                <Typography component="span" className={classes.counter}>
                    {'(' + count + ')'}
                </Typography>
            </Grid>
        );
    };

    function handleChange(event: React.ChangeEvent<{}>, newValue: number) {
        setValue(newValue);
    }

    function a11yProps(index) {
        return {
            id: `user-admin-dashboard-tab-${index}`,
            'aria-controls': `user-admin-dashboard-tab-${index}`
        };
    }

    useEffect(() => {
        // add check for data loading from API
        dashboardLoading(false);
    }, []);

    return (
        <div className={classes.root} data-testid="my-account-dashboard-tabs">
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="my account dashboard tabs"
                className={classes.tabs}
                scrollButtons="auto"
                variant="scrollable"
                indicatorColor="primary"
                textColor="primary"
            >
                <Tab
                    label={TabLabel(
                        t('myAccess', `${enUS.myAccess}`),
                        locations ? locations.length : 0
                    )}
                    {...a11yProps(0)}
                    className={classes.tabLabel}
                />
                <Tab
                    label={TabLabel(
                        t('pendingAccessRequests', `${enUS.pendingAccessRequests}`),
                        permissionChanges ? permissionChanges.length : 0
                    )}
                    {...a11yProps(2)}
                    className={classes.tabLabel}
                ></Tab>
            </Tabs>
            <TabPanel value={value} index={0}>
                <MyAccountMyAccessTab />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <MyAccountPendingRequestsTab />
            </TabPanel>
        </div>
    );
}
