import React, { ReactNode } from 'react';

export interface State {
    isATM: boolean;
}

export interface ProviderProps {
    children: ReactNode;
    value: State;
}

const DeliveryOrderATMContext = React.createContext<State>({} as State);

function DeliveryOrderDetailsATMProvider(props: ProviderProps) {
    const { children, value } = props;
    return (
        <DeliveryOrderATMContext.Provider value={value}>
            {' '}
            {children}{' '}
        </DeliveryOrderATMContext.Provider>
    );
}

const useDeliveryOrderDetailsATM = () => React.useContext(DeliveryOrderATMContext);

export { DeliveryOrderDetailsATMProvider, useDeliveryOrderDetailsATM };
