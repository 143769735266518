import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { blackWeight, xl } from '../../../../themes/globalConstants';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import ProductPlanningATMColumns from './ProductPlanningATMColumns';
import { ToggleAll } from '../../../../utility/helpers/order-helpers';
import ProductPlanningATMRow from './ProductPlanningATMRow';
import { PlanningAllocation } from '../models/PlanningAllocation';
import {
    OrderPlanningProduct,
    PlanningType,
    ProductPlanningState
} from '../../../../store/reducers/product-planning';
import { ProductPlanningATMState } from '../../../../store/reducers/product-planning-atm';
import { useTranslation } from 'react-i18next';
import { AllocationProduct } from '../models/AllocationForTable';
import { useDispatch } from 'react-redux';
import {
    clearActiveShippingItem,
    clearLinkedPOs,
    getProductionOrdersForProduct
} from '../../../../store/actions/product-planning';
import Modal from '../../../reusable/molecules/Modal';
import ProductPlanningATMProductionBalanceModal from './ProductPlanningATMProductionBalanceModal';
import {
    getDeliveryOrdersForProduct,
    updateCollapseAllProducts,
    updateCollapseProduct
} from '../../../../store/actions/product-planning-atm';
import ShipmentsModal from '../../../reusable/molecules/ShipmentsModal';
import {
    unitTypesWithEaches,
    viewTypesWithCampaign,
    weekFilter
} from '../../../../utility/helpers/filter-helpers';
import { StateName } from '../../../../utility/helpers/state-helpers';
import { selectIsCIACustomer, selectIsLargeCustomerAccount } from '../../../../store/selectors';
import { Order_Status } from '../../../../utility/services/orders-service';
import StandardTable from '../../../reusable/molecules/StandardTable';
import PendingPaymentModal from '../../ProductPlanning/components/PendingPaymentModal';
import ProductionBalanceModal from '../../ProductPlanning/components/ProductionBalanceModal';
import { Activity } from '../../../../utility/auth/useSecurity';
import ProductPlanningToolbar from './ProductPlanningToolbar';

interface Props {
    allocations: PlanningAllocation[];
    onViewFiltersClicked: (viewTypes: string[]) => void;
    onRangeFiltersClicked: (rangeTypes: number) => void;
    onUnitsFiltersClicked: (unitTypes: string[]) => void;
    currentViewTypes: string[];
    currentUnit: string;
    selectedRange: number;
    stateName: StateName;
    products: OrderPlanningProduct[];
}

const useStyles = makeStyles((theme) => ({
    titleStyle: {
        display: 'flex',
        padding: 0,
        margin: '1em 0 0 2em',
        '& h2': {
            fontSize: xl,
            fontWeight: blackWeight,
            color: theme.palette.info.dark
        }
    },
    subheaderStyle: {
        display: 'flex',
        padding: 0,
        margin: '0 0 1em 2em',
        '& h2': {
            color: theme.palette.info.dark
        }
    },
    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '0.5em',
        marginTop: '0.5em'
    }
}));

export default function ProductPlanningATMGrid({
    products,
    allocations,
    onViewFiltersClicked,
    onRangeFiltersClicked,
    onUnitsFiltersClicked,
    selectedRange,
    stateName,
    currentViewTypes,
    currentUnit
}: Props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [expandAll, setExpandAll] = useState<ToggleAll>({ toggle: false });
    const [columns, setColumns] = useState<any>([]);
    const [activeItem, setActiveItem] = useState<AllocationProduct | OrderPlanningProduct>();
    const [productionModalOpen, setProductionModalOpen] = useState<boolean>(false);
    const [shipmentModalOpen, setShipmentModalOpen] = useState<boolean>(false);
    const [pendingModalOpen, setPendingModalOpen] = useState<boolean>(false);
    const [activeWeek, setActiveWeek] = useState<number>(0);
    const [exceededAllocation, setExceededAllocation] = useState<boolean>(false);
    const isLargeCustomer = useTypedSelector<boolean>(selectIsLargeCustomerAccount);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { deliveryOrdersForProduct } = useTypedSelector<ProductPlanningATMState>(
        (state) => state.productPlanningATM
    );
    const { linkedPOs } = useTypedSelector<ProductPlanningState>((state) => state.productPlanning);
    const isCiaAccount = useTypedSelector<boolean>(selectIsCIACustomer);
    const prodBalanceModalTitle = `${activeItem?.displayName} - ${activeItem?.description}`;
    const productIdLabel = t('productId', 'Product ID');
    const graphicsIdLabel = t('graphicsID', 'Graphics ID');
    const productId = `${productIdLabel}: ${activeItem?.displayId}`;
    const graphicId = `${graphicsIdLabel}: ${activeItem?.graphicIdAndVersion}`;
    const modalSubheader = `${productId}     ${graphicId}`;

    const handleExpandAll = () => {
        setExpandAll({ toggle: true });
        dispatch(updateCollapseAllProducts(true, stateName));
    };

    const handleCollapseAll = () => {
        setExpandAll({ toggle: false });
        dispatch(updateCollapseAllProducts(false, stateName));
    };

    const handleProdModalClose = () => {
        setActiveItem(undefined);
        setProductionModalOpen(false);
        dispatch(clearLinkedPOs());
    };

    const closeShipmentsLook = () => {
        setActiveItem(undefined);
        setShipmentModalOpen(false);
        dispatch(clearActiveShippingItem());
    };

    const handlePendingModalClose = () => {
        setActiveItem(undefined);
        setPendingModalOpen(false);
        dispatch(clearLinkedPOs());
    };

    const onATMWeeklyLookup = (
        allocationProduct: AllocationProduct | OrderPlanningProduct,
        weekNumber: number,
        quantityType: PlanningType,
        quantity: number = 0
    ) => {
        switch (quantityType) {
            case PlanningType.ProductionBalance:
                setProductionModalOpen(true);
                setActiveItem(allocationProduct);
                setActiveWeek(weekNumber);
                setExceededAllocation(quantity < 0);
                if (allocationProduct) {
                    dispatch(getProductionOrdersForProduct(weekNumber, allocationProduct));
                    dispatch(updateCollapseProduct(allocationProduct, stateName));
                }
                break;
            case PlanningType.Shipments:
                setShipmentModalOpen(true);
                setActiveItem(allocationProduct);
                setActiveWeek(weekNumber);
                if (allocationProduct && allocationProduct.productSku) {
                    dispatch(
                        getDeliveryOrdersForProduct(
                            weekNumber,
                            allocationProduct.productSku,
                            Activity.PlanningPageATM
                        )
                    );
                    dispatch(updateCollapseProduct(allocationProduct, stateName));
                }
                break;
        }
    };

    const onWeeklyLookup = (
        product: AllocationProduct | OrderPlanningProduct,
        weekNumber: number,
        quantityType: PlanningType
    ) => {
        switch (quantityType) {
            case PlanningType.PalletTotal:
                break;
            case PlanningType.ProductionBalance:
                setProductionModalOpen(true);
                setActiveItem(product);
                setActiveWeek(weekNumber);
                if (product) {
                    dispatch(getProductionOrdersForProduct(weekNumber, product));
                    dispatch(updateCollapseProduct(product, stateName));
                }
                break;
            case PlanningType.PendingPayments:
                setPendingModalOpen(true);
                setActiveItem(product);
                setActiveWeek(weekNumber);
                if (product) {
                    const statuses = [Order_Status.Payment_Delinquent, Order_Status.Submit];
                    dispatch(getProductionOrdersForProduct(weekNumber, product, statuses));
                    dispatch(updateCollapseProduct(product, stateName));
                }
                break;
            case PlanningType.Shipments:
                setShipmentModalOpen(true);
                setActiveItem(product);
                setActiveWeek(weekNumber);
                if (product && product.productSku) {
                    dispatch(
                        getDeliveryOrdersForProduct(
                            weekNumber,
                            product.productSku,
                            Activity.PlanningPage
                        )
                    );
                    dispatch(updateCollapseProduct(product, stateName));
                }
                break;
        }
    };

    useEffect(() => {
        setColumns(
            ProductPlanningATMColumns.getProductPlanningATMColumns(cultureCode, selectedRange)
        );
    }, [cultureCode]);

    const CustomToolbarItems = (
        <ProductPlanningToolbar
            stateName={stateName}
            onExpandAll={handleExpandAll}
            onCollapseAll={handleCollapseAll}
            onViewFiltersClicked={onViewFiltersClicked}
            onRangeFiltersClicked={onRangeFiltersClicked}
            onUnitsFiltersClicked={onUnitsFiltersClicked}
            viewFilterType={viewTypesWithCampaign}
            weekFilterType={weekFilter}
            unitType={unitTypesWithEaches}
            currentViewTypes={currentViewTypes}
            currentUnit={currentUnit}
        />
    );

    const CustomOrderRow = ({ row }: { row: AllocationProduct | OrderPlanningProduct }) => {
        return (
            <ProductPlanningATMRow
                product={row}
                expand={expandAll}
                onWeeklyLookup={isLargeCustomer ? onATMWeeklyLookup : onWeeklyLookup}
                stateName={stateName}
            />
        );
    };

    const gridProducts = useMemo(() => {
        if (isLargeCustomer) {
            return allocations.flatMap<AllocationProduct>((allo) => allo.products ?? []);
        } else {
            return products ?? [];
        }
    }, [allocations, products, isLargeCustomer]);

    useEffect(() => {
        setColumns(
            ProductPlanningATMColumns.getProductPlanningATMColumns(cultureCode, selectedRange)
        );
    }, [cultureCode, selectedRange]);

    return (
        <>
            <StandardTable
                columns={columns}
                lines={gridProducts}
                gridName="Product Planning Production Balance Grid"
                rowComponent={CustomOrderRow}
                customToolbarItems={CustomToolbarItems}
                columnWidths={[30]}
                itemsPerPage={10}
                memoize
            />
            {/* Production Order Balance Modal */}
            <Modal
                open={productionModalOpen}
                close={handleProdModalClose}
                title={prodBalanceModalTitle}
                titleStyles={classes.titleStyle}
                subheader={modalSubheader}
                subheaderStyles={classes.subheaderStyle}
                closeIcon={true}
                fullWidth={true}
                maxWidth={isCiaAccount ? 'md' : 'sm'}
            >
                {isLargeCustomer
                    ? linkedPOs &&
                      activeItem && (
                          <ProductPlanningATMProductionBalanceModal
                              linkedPOs={linkedPOs}
                              weekNumber={activeWeek}
                              quantityPerPallet={activeItem?.quantityPerPallet}
                              exceededAllocation={exceededAllocation}
                          />
                      )
                    : linkedPOs &&
                      activeItem && (
                          <ProductionBalanceModal
                              linkedPOs={linkedPOs}
                              weekNumber={activeWeek}
                              isCiaAccount={isCiaAccount}
                              quantityPerPallet={activeItem?.quantityPerPallet}
                          />
                      )}
            </Modal>
            {/* Shipments Modal */}
            <Modal
                open={shipmentModalOpen}
                close={closeShipmentsLook}
                title={prodBalanceModalTitle}
                titleStyles={classes.titleStyle}
                subheader={modalSubheader}
                subheaderStyles={classes.subheaderStyle}
                closeIcon={true}
                fullWidth={true}
                maxWidth="md"
            >
                {deliveryOrdersForProduct && (
                    <ShipmentsModal
                        deliveryOrders={deliveryOrdersForProduct}
                        weekNumber={activeWeek}
                        showEaches={false}
                    />
                )}
            </Modal>
            {/* Pending Payment Modal */}
            <Modal
                open={pendingModalOpen}
                close={handlePendingModalClose}
                title={prodBalanceModalTitle}
                titleStyles={classes.titleStyle}
                subheader={modalSubheader}
                subheaderStyles={classes.subheaderStyle}
                closeIcon={true}
                fullWidth={true}
                maxWidth="md"
            >
                {linkedPOs && <PendingPaymentModal linkedPOs={linkedPOs} weekNumber={activeWeek} />}
            </Modal>
        </>
    );
}
