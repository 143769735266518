import React, { useState } from 'react';
import {
    Grid,
    Typography,
    Button,
    makeStyles,
    TableRow,
    TableCell,
    FormHelperText
} from '@material-ui/core';
import { brightGrey, boldWeight, medium, red } from '../../../themes/globalConstants';
import { Trans, useTranslation } from 'react-i18next';
import Modal from './Modal';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { OrderProductTableRow } from '../../../store/reducers/orders-dashboard';
import StandardTable from './StandardTable';
import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../utility/services/translation-service';
import TextInput from './TextInput';
import { ErrorMessage, useForm } from 'react-hook-form';
import { UpdateProductInformationRequest } from '../../../utility/services/orders-service';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { FlagTypes, GetFeatureFlag } from '../../../utility/helpers/feature-flag';
interface Props {
    product: OrderProductTableRow;
    onClose?: (
        editSubmitted: boolean,
        productInformation?: UpdateProductInformationRequest
    ) => void;
}

const useStyles = makeStyles((theme) => ({
    modal: {
        '& .MuiPaper-root': {
            maxWidth: '40.5em'
        },
        '& .MuiDialog-paperScrollPaper': {
            borderRadius: 'unset',
            overflow: 'hidden'
        },
        '& .MuiDialogTitle-root': {
            justifyContent: 'start'
        },
        '& .MuiDialogContent-root': {
            padding: '1.875em'
        },
        '& .MuiPaper-elevation1': {
            boxShadow: 'none'
        }
    },
    content: {
        paddingLeft: '10%',
        paddingRight: '10%',
        textAlign: 'center',
        letterSpacing: '1px',
        color: brightGrey
    },
    text: {
        marginTop: 10,
        fontSize: 24,
        fontWeight: boldWeight
    },
    button: {
        height: 40,
        marginTop: 30,
        fontSize: 14,
        fontWeight: boldWeight,
        textTransform: 'uppercase',
        width: '100%',
        marginLeft: 32
    },
    button_spacer: {
        height: 40,
        marginTop: 30,
        fontSize: 14,
        fontWeight: boldWeight,
        textTransform: 'uppercase',
        width: '100%',
        marginLeft: 48
    },
    successIcon: {
        height: 100,
        width: 100,
        marginTop: 20
    },
    buttonContainer: {
        marginTop: '1em'
    },
    label: {
        color: theme.palette.info.dark,
        fontSize: medium,
        marginBottom: '1.143em',
        marginTop: '2.857em'
    },
    errorText: {
        color: red
    }
}));

const EditProductModal = (props: Props) => {
    const { errors, control, getValues } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange'
    });
    const { t } = useTranslation();
    useTranslation();
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const cols = [
        createColumn('productSku', {
            dataType: ColumnDataType.String,
            label: TranslationService.getTranslatedText(cultureCode, 'ballProductId')
        }),
        createColumn('graphicId', {
            dataType: ColumnDataType.String,
            label: TranslationService.getTranslatedText(cultureCode, 'graphicID')
        }),
        createColumn('description', {
            dataType: ColumnDataType.String,
            label: TranslationService.getTranslatedText(cultureCode, 'productDescription')
        })
    ];
    const [open, setOpen] = useState(true);
    const { selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const editProductNameFeatureFlag = GetFeatureFlag(FlagTypes.EditProductName);

    const handleCloseModal = () => {
        setOpen(false);
        if (!!props.onClose) {
            props.onClose(false);
        }
    };

    const handleSubmitModal = () => {
        if (!errors.product && selectedAccountId) {
            setOpen(false);
            if (!!props.onClose) {
                const updatedValues = getValues();
                props.onClose(true, {
                    productId: props.product.productSku,
                    newProductId: updatedValues.customerProductId,
                    newProductName: !editProductNameFeatureFlag
                        ? props.product.customerProductName
                        : updatedValues.customerProductName,
                    shipToId: parseInt(selectedAccountId)
                });
            }
        }
    };

    const EditProductLine = ({ row }: { row: OrderProductTableRow }) => {
        if (row) {
            return (
                <TableRow>
                    <TableCell data-testid={'ball-product-id'}>
                        <Typography display="block">{row.productSku}</Typography>
                    </TableCell>
                    <TableCell data-testid={'graphic-id'}>
                        <Typography display="block">{row.graphicId}</Typography>
                    </TableCell>
                    <TableCell data-testid={'description-id'}>
                        <Typography display="block">{row.description}</Typography>
                    </TableCell>
                </TableRow>
            );
        } else {
            return null;
        }
    };

    return (
        <Modal
            open={open}
            close={handleCloseModal}
            title={t('editProductInformation', 'Edit Product Information')}
            closeIcon={true}
            fullWidth={true}
            className={classes.modal}
        >
            {
                <>
                    <StandardTable
                        gridName="Edit Product"
                        columns={cols}
                        lines={[props.product]}
                        rowComponent={EditProductLine}
                        columnWidths={[8, 6, 16]}
                        searchText={false}
                        enablePagination={false}
                        hideToolbar={true}
                        testId="edit-product-modal-static-datatable"
                        className="modal-table"
                    />
                    <Grid item xs={12}>
                        <Typography className={classes.label} variant="body1">
                            <Trans i18nKey="editProductUpdateFields">
                                Edit fields below to update product information.
                            </Trans>
                        </Typography>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextInput
                                type="text"
                                label={
                                    <Trans i18nKey="customerProductId">Customer Product ID</Trans>
                                }
                                name="customerProductId"
                                testId="customerProductId"
                                control={control}
                                rules={{ required: true }}
                                errors={errors}
                                error={errors.product?.customerProductId}
                                inputProps={{
                                    'data-testid': 'customerProductId.input',
                                    maxLength: 100
                                }}
                                defaultValue={props.product.customerProductId}
                            />
                            <FormHelperText className={classes.errorText}>
                                {errors.product?.customerProductId && (
                                    <ErrorMessage
                                        errors={errors}
                                        name={`customerProductId`}
                                        message={t('required')}
                                    />
                                )}
                            </FormHelperText>
                        </Grid>
                        {editProductNameFeatureFlag && (
                            <Grid item xs={6}>
                                <TextInput
                                    type="text"
                                    label={<Trans i18nKey="productName">Product Name</Trans>}
                                    name="customerProductName"
                                    testId="customerProductName"
                                    control={control}
                                    rules={{ required: true }}
                                    errors={errors}
                                    error={errors.product?.customerProductName}
                                    inputProps={{
                                        'data-testid': 'customerProductName.input',
                                        maxLength: 100
                                    }}
                                    defaultValue={props.product.customerProductName}
                                />
                                <FormHelperText className={classes.errorText}>
                                    {errors.product?.customerProductName && (
                                        <ErrorMessage
                                            errors={errors}
                                            name={`customerProductName`}
                                            message={t('required')}
                                        />
                                    )}
                                </FormHelperText>
                            </Grid>
                        )}
                    </Grid>
                    <Grid container item xs={12} alignItems="stretch" justify="flex-end">
                        <Grid item xs={3}>
                            <Button
                                type="button"
                                variant="outlined"
                                color="secondary"
                                className={classes.button}
                                onClick={handleCloseModal}
                                data-testid="cancel-btn"
                            >
                                <Trans i18nKey="cancel">Cancel</Trans>
                            </Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                className={classes.button_spacer}
                                onClick={handleSubmitModal}
                                data-testid="submit-btn"
                                disabled={!!errors.product}
                            >
                                <Trans i18nKey="submit">Submit</Trans>
                            </Button>
                        </Grid>
                        <Grid item xs={1}></Grid>
                    </Grid>
                </>
            }
        </Modal>
    );
};

export default EditProductModal;
