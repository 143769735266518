import React, { ReactNode } from 'react';

export interface DraftSummaryState {
    activeTab: 'openDrafts' | 'archivedDrafts';
}

export interface ProviderProps {
    children: ReactNode;
    value: DraftSummaryState;
}

const MakeItDraftsContext = React.createContext<DraftSummaryState>({} as DraftSummaryState);

function MakeItDraftsProvider(props: ProviderProps) {
    const { children, value } = props;
    return (
        <MakeItDraftsContext.Provider value={value}> {children} </MakeItDraftsContext.Provider>
    );
}

const useMakeItDraftsContext = () => React.useContext(MakeItDraftsContext);

export { MakeItDraftsProvider, useMakeItDraftsContext };
