import React from "react";
import { Trans } from "react-i18next";
import ConfirmationModal from "./ConfirmationModal";

interface Props {
    onCancelShipment: () => void;
    onClose: () => void;
    open: boolean;
    onClickNo?: () => void;
}

export default function ShipmentCancelModal({ onCancelShipment, open, onClose, onClickNo }: Props) {
    return (
        <ConfirmationModal
            title={<Trans i18nKey="confirmCancelShipment">Please confirm you want to cancel this shipment</Trans>}
            open={open}
            logo={false}
            subheader={
                <Trans i18nKey="areYouSureCancelShipment">
                    Are you sure you want to cancel this shipment?
                </Trans>
            }
            cancelText={<Trans i18nKey="no">No</Trans>}
            continueText={<Trans i18nKey="yesCancel">Yes, Cancel</Trans>}
            saveProgress={onCancelShipment}
            onClose={onClose}
            onCancel={onClickNo}
            canSaveDraft={true}
        />
    )
}
