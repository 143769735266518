import { Button, Container, Grid, Hidden, makeStyles, Paper, Typography } from '@material-ui/core';
import Modal from '../../reusable/molecules/Modal';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
    ballBlack,
    ballGray,
    blackWeight,
    ltBlueGrey,
    ltBlueGrey_8,
    medium,
    regularExtraWeight,
    xl,
    xxl
} from '../../../themes/globalConstants';
import { Activity } from '../../../utility/auth/useSecurity';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import clsx from 'clsx';
import moment from 'moment';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { ShipToLocationState } from '../../../store/reducers/ship-to-locations';
import { useHistory } from 'react-router';
import ShipToAddSectionsNav from '../ShipToLocationAddReview/components/ShipToAddSectionsNav';
import ShipToAddFormData from '../ShipToLocationAddReview/components/ShipToAddFormData';
import Link from '../../reusable/atoms/Link';
import { Link as RouterLink } from 'react-router-dom';
import ShipToLocationAddConfirmationPDF from './components/ShipToLocationAddConfirmationPDF';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '3em'
    },
    formSection: {
        marginBottom: '2.5em',
        padding: '4em',
        boxShadow: `0 0 6px ${ltBlueGrey_8}`,
        borderRadius: 4,
        '& .sectionHeader': {
            fontSize: xl,
            fontWeight: blackWeight,
            color: ballGray,
            paddingBottom: '0.1rem',
            borderBottom: `1px solid ${ltBlueGrey}`,
            marginBottom: '1rem',
            marginTop: '2rem'
        },
        palletSpecs: {
            paddingTop: '8px'
        },
        '& .noPadding': {
            padding: 0
        },
        '& .lastRow': {
            paddingBottom: 0
        }
    },
    actionBtn: {
        height: '3.375em',
        width: '13.75em',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom',
        transition: 'all linear 150ms'
    },
    printBtn: {
        marginTop: '1.5rem',
        height: '3.375em',
        width: '13em',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom',
        transition: 'all linear 150ms'
    },
    formSectionContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    formHeader: {
        marginBottom: '1em',
        marginTop: '1em'
    },
    formHeaderText: {
        color: ballGray,
        fontWeight: blackWeight,
        fontSize: xxl,
        marginBottom: 0
    },
    formHeaderMsgPrimary: {
        marginTop: '2rem',
        color: ballBlack,
        fontWeight: blackWeight,
        fontSize: xl,
        marginBottom: 0
    },
    formHeaderMsgSecondary: {
        marginTop: '1.5rem',
        color: ballBlack,
        fontWeight: regularExtraWeight,
        fontSize: medium,
        marginBottom: 0
    },
    formHeaderDate: {
        color: ballGray,
        fontWeight: blackWeight,
        fontSize: medium,
        marginTop: '0.75em',
        textAlign: 'right'
    },
    dashboardBtn: {
        width: '16em',
        marginTop: '2em'
    }
}));

const ShipToLocationAddConfirmation = () => {
    const { t } = useTranslation();
    const [openPdf, setOpenPdf] = useState(false);
    const classes = useStyles();
    const history = useHistory();
    const { newShipToLocation } = useTypedSelector<ShipToLocationState>(
        (state) => state.shipToLocations
    );

    const handleOpenPdf = () => {
        if (newShipToLocation) {
            setOpenPdf(true);
        }
    };

    const handleClosePdf = () => {
        setOpenPdf(false);
    };

    const backToDashboard = () => {
        history.push('/dashboard');
    };

    const footerActions = (
        <Grid container justify="center" item xs={12}>
            <Grid item xs={2}>
                <Link component={RouterLink} to="/dashboard" underline="none">
                    <Button
                        variant="outlined"
                        color="primary"
                        data-testid="dashboard-button"
                        className={classes.dashboardBtn}
                        onClick={backToDashboard}
                    >
                        <Trans i18nKey="backToDashboard">Back to My Dashboard</Trans>
                    </Button>
                </Link>
            </Grid>
        </Grid>
    );

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('requestNewShipTo', 'Request New Ship To'),
                description: t('confirmation', 'Confirmation'),
                thinBanner: true
            }}
            activity={Activity.AddShipTo}
            loading={false}
            actionFooter={{
                footerAction: footerActions,
                justify: 'center',
                sticky: true
            }}
        >
            <Grid container data-testid="add-shipto-page">
                <Container className={classes.container}>
                    <Grid container item xs={12}>
                        <Grid item sm={4}>
                            <Hidden xsDown>
                                <ShipToAddSectionsNav />
                            </Hidden>
                        </Grid>
                        <Grid container item xs={8} className={classes.formSectionContainer}>
                            <Grid item xs={12}>
                                {newShipToLocation && (
                                    <Paper className={clsx(classes.formSection)}>
                                        <Grid container item xs={12} className={classes.formHeader}>
                                            <Grid item xs={12}>
                                                <Typography className={classes.formHeaderText}>
                                                    <Trans i18nKey="thankYou">Thank You</Trans>!
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography
                                                    className={classes.formHeaderMsgPrimary}
                                                >
                                                    <Trans i18nKey="shipToRequestSubmitted">
                                                        Your request to add a new Ship To location
                                                        has been submitted. You will receive a
                                                        notification once the request is reviewed.
                                                    </Trans>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography
                                                    className={classes.formHeaderMsgSecondary}
                                                >
                                                    <Trans i18nKey="shipToRequestSave">
                                                        Your form is also available to view. Please
                                                        save this for your records.
                                                    </Trans>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid item xs={2}>
                                                    <Button
                                                        type="button"
                                                        variant="outlined"
                                                        color="primary"
                                                        data-testid="print-download-button"
                                                        onClick={handleOpenPdf}
                                                        className={classes.printBtn}
                                                    >
                                                        <Trans i18nKey="print">Print</Trans>/
                                                        <Trans i18nKey="download">Download</Trans>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            justify="space-between"
                                            xs={12}
                                            className={classes.formHeader}
                                        >
                                            <Grid item xs={6}>
                                                <Typography className={classes.formHeaderText}>
                                                    <Trans i18nKey="summary">Summary</Trans>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography className={classes.formHeaderDate}>
                                                    {moment().format('MM/DD/YYYY')}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <ShipToAddFormData newShipToLocation={newShipToLocation} />
                                    </Paper>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <Modal
                open={openPdf}
                title={t('requestNewShipTo', 'Request New Ship To')}
                close={handleClosePdf}
                closeIcon={true}
                fullScreen={true}
            >
                <ShipToLocationAddConfirmationPDF
                    title={t('requestNewShipTo', 'Request New Ship To')}
                    newShipToLocation={newShipToLocation!}
                />
            </Modal>
        </ProcessingPageTemplate>
    );
};

export default ShipToLocationAddConfirmation;
