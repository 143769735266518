import React from 'react';
import { Trans } from 'react-i18next';
import { enUS } from '../../../../utility/translations/locales/en-US/en-US';
import ConfirmationModal from '../../../reusable/molecules/ConfirmationModal';

interface Props {
    open: boolean;
    onClose?: () => void;
    saveProgress?: () => void;
    onCancel?: () => void;
}

export default function LiquidTestIntakeCancelModal({
    open,
    onClose,
    saveProgress,
    onCancel
}: Props) {
    return (
        <ConfirmationModal
            logo={false}
            title={<Trans i18nKey="cancel">{enUS.cancel}</Trans>}
            subheader={<Trans i18nKey="confirmCancel">{enUS.confirmCancel}</Trans>}
            open={open}
            continueText={<Trans i18nKey="yesCancel">{enUS.yesCancel}</Trans>}
            cancelText={<Trans i18nKey="no">{enUS.no}</Trans>}
            saveProgress={saveProgress}
            onCancel={onCancel}
            onClose={onClose}
            data-testid="cancel-modal"
        />
    );
}
