import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { ViewType } from '../../../utility/helpers/filter-helpers';
import { useTranslation, Trans } from 'react-i18next';
import { visitedLink } from '../../../themes/globalConstants';

interface Props {
    views: ViewType[];
    subheader?: string;
    key?: number;
    type?: string;
    label?: string;
    value?: any;
    className?: any;
    viewRangeToggleGroup?: string;
    exclusive?: boolean;
    onViewChange?: (string: any | null) => void;
}

const StyledToggleBtn = withStyles((theme) => ({
    root: {
        padding: 0,
        margin: '0 2em 0 2em',
        border: 0,
        textTransform: 'capitalize',
        color: theme.palette.primary.main,
        fontSize: 16,
        fontWeight: 700,
        '&:hover': {
            outline: 'none',
            backgroundColor: 'transparent',
            fontWeight: 900,
            color: visitedLink
        },
        '&:focus': {
            backgroundColor: 'transparent',
            fontWeight: 900,
            color: visitedLink
        },
        '&$selected': {
            backgroundColor: 'transparent',
            fontWeight: 900,
            color: visitedLink,
            '&:focus': {
                backgroundColor: 'transparent',
                fontWeight: 900,
                color: visitedLink
            },
            '&:hover': {
                backgroundColor: 'transparent',
                fontWeight: 900,
                color: visitedLink
            }
        },
        [theme.breakpoints.down('xs')]: {
            margin: '0 0.5em 0 0'
        }
    },
    selected: {}
}))(ToggleButton);

const StyledToggleGroup = withStyles((theme) => ({
    root: {
        backgroundColor: 'transparent'
    }
}))(ToggleButtonGroup);

const ViewRangeButtonGroup = (props: Props) => {
    const [selectedView, setSelectedView] = React.useState<string | null>('all');
    const views = props.views;

    const handleChange = (event, view) => {
        if (view !== null) {
            setSelectedView(view);
            if (props.onViewChange) {
                let selectedView = views?.filter((value) => value.label === view);
                props.onViewChange(selectedView[0].types);
            }
        }
    };

    useEffect(() => {
        if (props.value) {
            setSelectedView(props.value);
        }
    }, [props.value]);

    useTranslation();

    return (
        <StyledToggleGroup
            value={selectedView}
            onChange={handleChange}
            exclusive
            aria-label="views sorter"
            data-testid={props.viewRangeToggleGroup}
        >
            {views?.map((value, index) => (
                <StyledToggleBtn
                    key={index}
                    value={value.label}
                    aria-label={value.label}
                    className={props.className}
                    disableRipple
                    disableFocusRipple
                    data-testid={value}
                >
                    <Trans i18nKey={value.label}>{value.label}</Trans>
                </StyledToggleBtn>
            ))}
        </StyledToggleGroup>
    );
};

export default ViewRangeButtonGroup;
