import Axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { getAxiosConfig } from '../helpers/axios-helpers';
import { End } from '../../store/reducers/product-end-details';
import { Product, ProductVolume } from '../../store/reducers/product-portfolio';

export interface EndSearchResponse {
    ends: End[];
}

export interface ProductSearchResponse {
    products: Product[];
}

export default class EndService {
    static getBanner(state: any, url: string) {
        const axiosConfig: AxiosRequestConfig = getAxiosConfig(state);
        const banner = Axios.get(url, axiosConfig);
        return banner;
    }

    static getEnds(state: any, accountId?: string): Promise<AxiosResponse<EndSearchResponse>> {
        const axiosConfig: AxiosRequestConfig = getAxiosConfig(state);
        const urlContent = accountId ? `?accountId=${accountId}` : '';
        const baseUrl = `${process.env.REACT_APP_PRODUCT_API}/ends`;
        const url = baseUrl + urlContent;
        const ends = Axios.get<EndSearchResponse>(url, axiosConfig);
        return ends;
    }

    static getCompatibleProducts(endResponse: EndSearchResponse, state: any) {
        const axiosConfig: AxiosRequestConfig = getAxiosConfig(state);
        //pull end code values from ends array to send to product api
        const requestObj = {
            endCodes: endResponse.ends?.map((end) => end.endCode)
        };
        //Call product API to pull compatible products for each endCode
        const compatibleProducts = Axios.post<ProductSearchResponse>(
            `${process.env.REACT_APP_PRODUCT_API}/products/`,
            requestObj,
            axiosConfig
        );
        return compatibleProducts;
    }

    static assignCompatibleProducts(endsArray: End[], products: Product[]) {
        if (endsArray) {
            endsArray.forEach((end) => {
                let compatibleArray = products?.filter((product) =>
                    product?.ends?.some((productEnd) => productEnd.endCode === end.endCode)
                );
                end.compatibleProducts = compatibleArray;

                //Group compatible sizes by their style using volumeML and volumeOz
                if (compatibleArray) {
                    compatibleArray.forEach((product) => {
                        let styleCodeKey: any;

                        //Create our own volume object from API data
                        let productVolumeObject = {
                            productCode: product.productCode,
                            style: product.style?.label!,
                            volumeML: product.volumeML,
                            volumeOz: product.volumeOz,
                            prettySizes: `${product.volumeOz}oz/${product.volumeML}ml`,
                            prettyOz: `${product.volumeOz}oz`,
                            prettyML: `${product.volumeML}ml`
                        };

                        if (end.compatibleSizesByStyle === undefined) {
                            end.compatibleSizesByStyle = new Map<string, ProductVolume[]>();
                        }

                        styleCodeKey = product.style?.label;
                        let endCompatibleSizes = end?.compatibleSizesByStyle;
                        let containsKey = endCompatibleSizes.has(styleCodeKey);

                        if (containsKey) {
                            let sizesForStyle = endCompatibleSizes.get(styleCodeKey);
                            const haveThisSizeAlready = sizesForStyle?.some(
                                (size) =>
                                    size.volumeML === product?.volumeML ||
                                    size.volumeOz === product?.volumeOz
                            );

                            if (!haveThisSizeAlready) {
                                if (endCompatibleSizes) {
                                    endCompatibleSizes[styleCodeKey]?.push(productVolumeObject);
                                    if (sizesForStyle) {
                                        sizesForStyle?.push(productVolumeObject);
                                        endCompatibleSizes.set(styleCodeKey, sizesForStyle!);
                                    }
                                }
                            }
                        } else {
                            let sizesForThisStyle: ProductVolume[] = [productVolumeObject];
                            endCompatibleSizes.set(styleCodeKey, sizesForThisStyle);
                        }
                    });
                }
            });

            const compatibleProductsAssigned = new Promise(function (resolve, reject) {
                resolve(endsArray);
            });

            return compatibleProductsAssigned;
        }
    }

    //If there are query params from product details, sort ends so those show first
    static sortEndsArray(endsArray: End[], queryParams: string[]) {
        if (endsArray) {
            endsArray.forEach((end, index) => {
                if (queryParams.find((endCode) => endCode === end.endCode)) {
                    endsArray.splice(index, 1);
                    endsArray.unshift(end);
                }
            });

            const endsArraySorted = new Promise(function (resolve, reject) {
                resolve(endsArray);
            });

            return endsArraySorted;
        }
    }
}
