export const isEmployee = (groups) => {
    return !!groups?.find((g) => g === 'Employees');
};

export const parseClaims = (accessToken?: string | undefined) => {
    const claims = {
        isEmployee: false
    };

    const claimString = accessToken?.split('.')[1];
    if (!claimString) {
        return claims;
    }

    try {
        // parse token
        const claimObject = JSON.parse(atob(claimString));
        // get groups
        const groups = claimObject?.Group as string[];

        // set any claims
        claims.isEmployee = isEmployee(groups);

        return claims;
    } catch (SyntaxError) {
        // happens from bad base64 string, so return defaults
        return claims;
    }
};
