import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
    withStyles,
    makeStyles,
    Typography,
    Accordion as MuiExpansionPanel,
    AccordionSummary as MuiExpansionPanelSummary,
    AccordionDetails,
    Grid
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { ballGray, blackWeight, xl } from '../../../../themes/globalConstants';

const useStyles = makeStyles((theme) => ({
    cancellingOrdersText: {
        marginBottom: '1em'
    },
    container: {
        paddingRight: '1em'
    },
    details: {
        display: 'block',
        padding: 0
    },
    expandIcon: {
        color: theme.palette.primary.main,
        marginLeft: '1em',
        fontSize: 30
    },
    expandedIcon: {
        transform: 'rotate(180deg)'
    },
    title: {
        color: ballGray,
        fontWeight: blackWeight,
        fontSize: xl
    },
    buffer: {
        marginTop: '1em'
    }
}));

const StyledExpansionPanel = withStyles((theme) => ({
    root: {
        boxShadow: 'none',
        borderRadius: '0',
        background: 'transparent',
        '&:before': {
            display: 'none'
        },
        '&$expanded': {
            minHeight: '4em',
            margin: 0
        }
    }
}))(MuiExpansionPanel);

const StyledExpansionPanelSummary = withStyles((theme) => ({
    root: {
        borderRadius: '0',
        padding: 0,
        minHeight: '4em',
        '&$expanded': {
            minHeight: '4em',
            margin: 0
        }
    },
    content: {
        alignItems: 'center'
    },
    expanded: {
        color: theme.palette.primary.main
    }
}))(MuiExpansionPanelSummary);

const MakeItDisclaimerSection = () => {
    const classes = useStyles();
    const [expand, setExpand] = useState<boolean>(true);

    useTranslation();

    const handlePanelClick = () => {
        setExpand(!expand);
    };

    return (
        <StyledExpansionPanel
            expanded={expand}
            onChange={handlePanelClick}
            className={classes.container}
            id="production-order-expansion-panel"
        >
            <StyledExpansionPanelSummary>
                <Typography variant="h3" className={classes.title}>
                    <Trans i18nKey="needToMakeChange">Need to make a change?</Trans>
                </Typography>
                <ExpandMoreIcon
                    className={clsx(classes.expandIcon, { [classes.expandedIcon]: expand })}
                />
            </StyledExpansionPanelSummary>
            <AccordionDetails className={classes.details}>
                <Grid item xs={10}>
                    <Typography variant="body1">
                        <Trans i18nKey="poOpenOrderInformation">
                            Up to two weeks prior to the first available date you may remove a product from the production order,
                            delay the first available date for a product, or decrease the quantity of a product.
                        </Trans>
                    </Typography>
                </Grid>
                <Grid item xs={10} className={classes.buffer}>
                    <Typography variant="body1" className={classes.cancellingOrdersText}>
                        <Trans i18nKey="poCannotBeEdited">
                            If a product has an associated delivery order, you won't be able to make edits to the production order.
                        </Trans>
                    </Typography>
                </Grid>
            </AccordionDetails>
        </StyledExpansionPanel>
    );
}
export default MakeItDisclaimerSection;