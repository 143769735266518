import React from 'react';
import { Section } from '../../../../store/reducers/glp';
import { makeStyles } from '@material-ui/core';
import Link from '../../../reusable/atoms/Link';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    list: {
        listStyle: 'none',
        marginLeft: 0,
        paddingLeft: 0,
        [theme.breakpoints.down('sm')]: {
            marginTop: '2em'
        }
    },
    listItem: {
        marginBottom: '1.88em',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 0
        }
    }
}));

const PartneringWithYou = ({ links }: Section) => {
    const classes = useStyles();

    return links ? (
        <section>
            <ul className={classes.list} data-testid="partnering-links-list">
                {links?.map((link, index) =>
                    link.linkText ? (
                        <li key={index} className={classes.listItem}>
                            <Link
                                to={`/content${link.destination}`}
                                component={RouterLink}
                                underline="hover"
                                aria-label={link.linkText}
                            >
                                {link.linkText}
                            </Link>
                        </li>
                    ) : null
                )}
            </ul>
        </section>
    ) : null;
};

export default PartneringWithYou;
