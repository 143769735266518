import { TableRow, TableCell, Typography, makeStyles, Grid, Button, Link } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ballLtGray, boldWeight } from '../../../../themes/globalConstants';
import { UnrecognizedProduct } from '../../../../store/reducers/manage-products';
import { getSnoSkuDescription } from '../../../../utility/helpers/make-it-bulk-helpers';
import { isProductCanType } from '../../../../utility/helpers/order-helpers';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { numericRegex } from '../../../../utility/helpers/formatters';
import {
    setUnrecognizedExpandedProduct,
    setUnrecognizedQuantities
} from '../../../../store/root-actions';

interface Props {
    line: UnrecognizedProduct;
}

const useStyles = makeStyles((theme) => ({
    actionCell: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    expandButton: {
        padding: '1em',
        minWidth: 0
    },
    forecastTable: {
        width: '100%'
    },
    labelCell: {
        width: '33.3%',
        height: '3rem',
        borderBottomColor: ballLtGray,
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderRightColor: ballLtGray,
        borderRightWidth: '1px',
        borderRightStyle: 'solid'
    },
    tableRow: {
        '& td:last-child': {
            borderRightStyle: 'none'
        }
    },
    qtyTableRow: {
        '& td': {
            borderBottomStyle: 'none'
        },
        '& td:last-child': {
            borderRightStyle: 'none'
        }
    },
    dataCell: {
        height: '3rem',
        borderBottomColor: ballLtGray,
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderRightColor: ballLtGray,
        borderRightWidth: '1px',
        borderRightStyle: 'solid'
    },

    labelText: {
        marginLeft: '2em'
    },
    dataText: {
        marginLeft: '1em'
    },
    qtyInput: {
        marginLeft: '1em',
        maxWidth: '3.5rem',
        borderColor: ballLtGray
    },
    qtyUnit: {
        fontWeight: boldWeight,
        paddingLeft: '0.25rem'
    }
}));

const UnrecognizedProductsRow = ({ line }: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [quantities, setQuantities] = useState<number[] | undefined>(undefined);

    const getForecastId = (index) => {
        if (line.forecasts && line.forecasts[index]) {
            return (
                <td className={classes.dataCell}>
                    <Link
                        to={`/plan-it-order/${line.forecasts[index].forecastId}`}
                        component={RouterLink}
                        underline="none"
                        className={classes.dataText}
                        data-testid={`forecast-id-link-${index}`}
                    >
                        {line.forecasts[index].forecastId}
                    </Link>
                </td>
            );
        } else {
            return <td></td>;
        }
    };
    const getForecastWeek = (index) => {
        if (line.forecasts && line.forecasts[index]) {
            return (
                <td className={classes.dataCell} data-testid={`forecast-week-${index}`}>
                    <span className={classes.dataText}>
                        {moment(line.forecasts[index].forecastWeek).format('MM/DD/YYYY')}
                    </span>
                </td>
            );
        } else {
            return <td></td>;
        }
    };
    const getUpdatedQuantity = (event) => {
        let updatedQuantity = 0;
        if (numericRegex(event.target.value)) {
            const parsedCount = parseInt(event.target.value);
            if (!isNaN(parsedCount)) {
                updatedQuantity = parsedCount;
            }
        }
        return updatedQuantity;
    };
    const handleQuantityInputBlur = (event) => {
        if (event) {
            const updatedIndex = parseInt(event.target.name);
            const updatedQuantity = getUpdatedQuantity(event);
            if (line.forecasts && line.forecasts[updatedIndex]) {
                const forecastId = line.forecasts[updatedIndex].forecastId;
                dispatch(
                    setUnrecognizedQuantities(line.customerProductId, forecastId, updatedQuantity)
                );
            }
        }
    };

    const handleQuantityInputChange = (event) => {
        if (event) {
            const updatedIndex = parseInt(event.target.name);
            const updatedQuantity = getUpdatedQuantity(event);
            const updatedQuantities = quantities?.map((qty, index) => {
                return index === updatedIndex ? updatedQuantity : qty;
            });
            setQuantities(updatedQuantities);
        }
    };

    const getForecastQuantity = (index) => {
        if (
            line.forecasts &&
            line.forecasts[index] &&
            quantities &&
            quantities[index] !== undefined
        ) {
            return (
                <td className={classes.dataCell} data-testid={`forecast-quantity-${index}`}>
                    <input
                        name={index}
                        type="text"
                        onBlur={handleQuantityInputBlur}
                        onChange={handleQuantityInputChange}
                        className={classes.qtyInput}
                        value={quantities[index]}
                    />
                    <span className={classes.qtyUnit}>PL</span>
                </td>
            );
        } else {
            return <td></td>;
        }
    };
    const handleExpandedClicked = () => {
        dispatch(setUnrecognizedExpandedProduct(line.customerProductId));
    };

    useEffect(() => {
        if (line && line.forecasts) {
            const quantities = line.forecasts.map((forecast) =>
                forecast.updatedQuantity ? forecast.updatedQuantity : forecast.originalQuantity
            );
            setQuantities(quantities);
        }
    }, [line]);

    return (
        <>
            <TableRow hover={true}>
                <TableCell data-testid={'customer-product-id'}>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                            <Typography>{line.customerProductId}</Typography>
                        </Grid>
                        <Grid item onClick={handleExpandedClicked}>
                            <Button
                                className={classes.expandButton}
                                data-testid="expand-collapse-button"
                            >
                                {line.expanded ? (
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/Chevron_Down.svg`}
                                        alt={`Collapse row`}
                                    />
                                ) : (
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/Chevron_Right.svg`}
                                        alt={`Expand row`}
                                    />
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell data-testid={'product-name'}>
                    <Typography>{line.productName}</Typography>
                </TableCell>
                <TableCell data-testid={'product-description'}>
                    <Typography>
                        {getSnoSkuDescription(isProductCanType(line.type), line)}
                    </Typography>
                </TableCell>
            </TableRow>
            {line.expanded && (
                <TableRow className="forecastTableRow">
                    <TableCell colSpan={3} data-testid={'unrecognized-product-details'}>
                        <table className={classes.forecastTable}>
                            <tr className={classes.tableRow}>
                                <td className={classes.labelCell}>
                                    <span className={classes.labelText}>Forecast ID</span>
                                </td>
                                {[0, 1, 2, 3, 4, 5, 6, 7].map((index) => getForecastId(index))}
                            </tr>
                            <tr className={classes.tableRow}>
                                <td className={classes.labelCell}>
                                    <span className={classes.labelText}>Forecast Week</span>
                                </td>
                                {[0, 1, 2, 3, 4, 5, 6, 7].map((index) => getForecastWeek(index))}
                            </tr>
                            <tr className={classes.qtyTableRow}>
                                <td className={classes.labelCell}>
                                    <span className={classes.labelText}>Forecast Quantity</span>
                                </td>
                                {[0, 1, 2, 3, 4, 5, 6, 7].map((index) =>
                                    getForecastQuantity(index)
                                )}
                            </tr>
                        </table>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

export default UnrecognizedProductsRow;
