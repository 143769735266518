import * as types from './action-types';
import AccountService from '../../utility/services/account-service';
import { Account } from '../reducers/customer-context';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsights } from '../../utility/AppInsights';
import { loadState } from '../../utility/helpers/sessionStorage';

export const selectCustomerAccount = (accountId: string) => ({
    type: types.CUSTOMER_SELECT_ACCOUNT,
    selectedAccountId: accountId
});

export const setLastShipToId = (accountId: string) => ({
    type: types.CUSTOMER_LAST_SHIP_TO,
    lastShipToIdSelected: accountId
});

export const setLastBillToId = (accountId: string) => ({
    type: types.CUSTOMER_LAST_BILL_TO,
    lastBillToIdSelected: accountId
});

export const updateAccounts = (accounts: Account[]) => ({
    type: types.CUSTOMER_LOADED,
    accounts: accounts
});

//Gets all accounts associated to a user with Hierarchies
export const getShipToAccounts = () => {
    return (dispatch, getState) => {
        if (getState()?.customerContext?.loading) {
            return;
        }
        dispatch({ type: types.CUSTOMER_SHIP_TOS_LOADING });

        return Promise.all([
            AccountService.getAccounts(getState()),
            AccountService.getUserAccounts(getState())
        ])
            .then(([accountShipTo, adminAccountResponse]) => {
                const accounts = accountShipTo.data?.accounts || [];
                const shipTos = accounts?.filter(
                    (account) =>
                        (account.addressType === 'CS' || account.addressType === 'CX') &&
                        account.status === 'Active'
                );
                const adminAccounts = adminAccountResponse.data?.accounts?.map((account) => {
                    return {
                        ...account,
                        shipToAccounts: shipTos.filter(
                            (shipTo) => shipTo.soldToAccount === account.accountId
                        )
                    };
                }) || [];

                if (shipTos?.length !== 0) {
                    dispatch({
                        type: types.CUSTOMER_SHIP_TOS_LOADED,
                        accounts: accounts,
                        shipToAccounts: shipTos,
                        adminAccounts: adminAccounts
                    });
                    dispatch(getDefaultShipTo(getState(), shipTos));
                } else if (accounts?.length > 0) {
                    dispatch({
                        type: types.CUSTOMER_LOADED,
                        accounts: accounts
                    });
                } else {
                    dispatch({ type: types.CUSTOMER_NO_SHIP_TOS_LOADED });
                }
            })
            .catch((error) => {
                dispatch({
                    type: types.CUSTOMER_SHIP_TOS_LOADING_ERROR,
                    error: error.message
                });
                const user = getState().auth?.userInfo?.email;
                if (appInsights && user) {
                    const appInsightsError = error.message + ' getShipToAccounts-' + user;
                    appInsights.trackException({
                        error: new Error(appInsightsError),
                        severityLevel: SeverityLevel.Error
                    });
                }
            });
    };
};

// If no selectedShipTo, defaults to first account in ShipTo array
export const getDefaultShipTo = (state: any, shipTos: any) => {
    return (dispatch) => {
        if (state.customerContext.selectedAccountId === '') {
            const lastStoredSelection = loadState('selectedAccountId');
            const accountFromLastStoredSelection = shipTos.find(
                (account) => account.accountId === lastStoredSelection
            );
            const selectedShipTo =
                accountFromLastStoredSelection?.accountId || shipTos[0].accountId;
            dispatch(selectCustomerAccount(selectedShipTo));
        }
    };
};

//Gets all accounts associated to a user without shipTo hierarchies
export const getUserCustomerAccounts = () => {
    return (dispatch, getState) => {
        dispatch({ type: types.CUSTOMER_LOADING });

        return AccountService.getAccounts(getState())
            .then((customers) => {
                const customerAccounts = customers.data.accounts;
                dispatch({
                    type: types.CUSTOMER_LOADED,
                    accounts: customerAccounts
                });
            })
            .catch((error) => {
                dispatch({
                    type: types.CUSTOMER_LOADING_ERROR,
                    error: error.message
                });
            });
    };
};
