import React, { useState } from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import Link from '../../../reusable/atoms/Link';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { ballBlue, medium, regularWeight } from '../../../../themes/globalConstants';
import Modal from '../../../reusable/molecules/Modal';
import { CSVLink } from 'react-csv';
import ReactPdf from '@react-pdf/renderer';
import { addTimezoneOffset, getSimpleFormattedDate, getSimpleFormattedTime } from '../../../../utility/helpers/date-helpers';
import { DeliveryOrder } from '../../../../store/reducers/shipping-dashboard';
import ShipItConfirmationPDF from './ShipItConfirmationPDF';
import {
    constructCanDescription,
    constructEndDescription,
    isProductEndType
} from '../../../../utility/helpers/order-helpers';

interface Props {
    dateSubmitted: string;
    deliveryOrder: DeliveryOrder;
    shipToAddress: string;
    shipToName: string;
}

const useStyles = makeStyles((theme) => ({
    label: {
        textTransform: 'uppercase',
        fontSize: medium,
        paddingTop: '0.5em'
    },
    link: {
        fontSize: medium,
        fontWeight: regularWeight,
        width: '8.75em',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    icon: {
        color: ballBlue
    }
}));

const pdfStyles = ReactPdf.StyleSheet.create({
    link: {
        fontSize: medium,
        fontWeight: regularWeight,
        width: '8.75em'
    }
});

const ShipItShareLinks = (props: Props) => {
    const classes = useStyles();
    const [openPdf, setOpenPdf] = useState(false);
    const { t } = useTranslation();

    const printForm = () => {
        setOpenPdf(true);
    };

    const onClose = () => {
        setOpenPdf(false);
    };

    interface CSVRow {
        arr_date?: string;
        arr_time?: string;
        load_seq?: number;
        product_id?: string;
        graphic_id?: string;
        product_name?: string;
        descr: string;
        quantity_ordered?: number;
        eaches_ordered?: number;
        release_number?: string;
        filler_line?: string;
        reference_number?: string;
        po_number?: string;
        shipToId?: number;
        customer_po?: string;
    }

    const csvHeaders = [
        { label: t('deliveryDate', 'Delivery Date').toUpperCase(), key: 'arr_date' },
        { label: t('deliveryTime', 'Delivery Time').toUpperCase(), key: 'arr_time' },
        { label: t('loadSeq', 'LOAD SEQ').toUpperCase(), key: 'load_seq' },
        { label: t('productId', 'PRODUCT ID').toUpperCase(), key: 'product_id' },
        { label: t('graphicID', 'GRAPHIC ID').toUpperCase(), key: 'graphic_id' },
        { label: t('productName', 'PRODUCT NAME').toUpperCase(), key: 'product_name' },
        {
            label: t('descriptionCharacteristics', 'PRODUCT DESC / CHARACTERISTICS').toUpperCase(),
            key: 'descr'
        },
        { label: t('quantityPL', 'QUANTITY (PL)').toUpperCase(), key: 'quantity_ordered' },
        { label: t('quantityEA', 'QUANTITY (EA)').toUpperCase(), key: 'eaches_ordered' },
        { label: t('releaseNumberPDF', 'RELEASE NUMBER').toUpperCase(), key: 'release_number' },
        { label: t('fillerLineNumber', 'FILLER LINE #').toUpperCase(), key: 'filler_line' },
        {
            label: t('referenceNumberPDF', 'REFERENCE NUMBER').toUpperCase(),
            key: 'reference_number'
        },
        { label: t('shipToId', 'SHIP TO ID').toUpperCase(), key: 'shipToId' },
        { label: t('customerPoNumber', 'CUSTOMER PO#').toUpperCase(), key: 'customer_po' }
    ];

    const csvArray = () => {
        let rows : any[] = [];
        props.deliveryOrder.shipments?.map((shipment) => {
            shipment.loads?.map((load) => {
                const seq = shipment.loads?.indexOf(load);
                const canBottleDesc = constructCanDescription(load);
                const endDesc = constructEndDescription(load);
                const isEndType = isProductEndType(load.type);
                const adjustedDateTime = addTimezoneOffset(shipment.deliveryDateTime);
                const eachesAmount =
                    load.quantityPerPallet && load.palletQuantity
                        ? load.quantityPerPallet * load.palletQuantity
                        : 0;
                const row: CSVRow = {
                    arr_date: getSimpleFormattedDate('', adjustedDateTime),
                    arr_time: getSimpleFormattedTime('', adjustedDateTime),
                    load_seq: seq! + 1,
                    product_id: load.displayId,
                    graphic_id: load.graphicIdAndVersion,
                    product_name: load.displayName,
                    descr: isEndType ? endDesc : canBottleDesc,
                    quantity_ordered: load.palletQuantity,
                    eaches_ordered: eachesAmount,
                    release_number: load.releaseNumber,
                    filler_line: load.fillerLine,
                    reference_number: load.reference,
                    shipToId: props.deliveryOrder.shipToId,
                    customer_po: load.purchaseOrderNumber
                };
                rows.push(row);
                return null;
            });
            return null;
        });
        return rows;
    };

    const csvContents = csvArray();

    return (
        <Grid container item xs={12} spacing={2} alignItems="center" justify="flex-end">
            <Grid item data-testid="share-btn">
                <Typography variant="h6" className={classes.label}>
                    <Trans i18nKey="share">Share</Trans>
                </Typography>
            </Grid>
            <Grid item>
                <Link
                    className={classes.link}
                    underline="always"
                    data-testid="print-btn"
                    onClick={printForm}
                >
                    <Trans i18nKey="print">Print</Trans>
                </Link>
                <PrintOutlinedIcon fontSize="small" className={classes.icon} />
            </Grid>
            <Grid item>
                <CSVLink
                    className={
                        'MuiTypography-root MuiLink-root MuiLink-underlineAlways MuiTypography-colorPrimary'
                    }
                    style={pdfStyles.link}
                    underline="always"
                    data-testid="download-btn"
                    headers={csvHeaders}
                    data={csvContents}
                    filename={`${props.deliveryOrder.deliveryOrderId}.csv`}
                >
                    <Trans i18nKey="downloadCSV">Download .CSV</Trans>
                </CSVLink>
                <GetAppOutlinedIcon fontSize="small" className={classes.icon} />
            </Grid>
            <Modal
                open={openPdf}
                title={t('orderConfirmation', 'Order Confirmation')}
                close={onClose}
                closeIcon={true}
                fullScreen={true}
            >
                <ShipItConfirmationPDF
                    title={t('orderConfirmation', 'Order Confirmation')}
                    deliveryOrder={props.deliveryOrder}
                    shipToAddress={props.shipToAddress}
                    shipToName={props.shipToName}
                />
            </Modal>
        </Grid>
    );
};

export default ShipItShareLinks;
