import React, { useState, useEffect } from 'react';
import TradeRequestSummaryColumns from './TradeRequestSummaryColumns';
import TradeRequestSummaryRow from './TradeRequestSummaryRow';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import StandardTable from '../../../reusable/molecules/StandardTable';
import { TradeRequest } from '../../../../store/reducers/trade-it-summary';

interface Props {
    tradeRequests: TradeRequest[];
}

export default function TradeRequestSummaryTable({ tradeRequests }: Props) {
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const [columns, setColumns] = useState<any>([]);

    const columnWidths = [15, 30, 30, 15, 10];

    useEffect(() => {
        setColumns(TradeRequestSummaryColumns.getColumns(cultureCode));
    }, [cultureCode]);

    const TradeRequestsLine = ({ row }: { row: TradeRequest }) => {
        if (row) {
            return <TradeRequestSummaryRow key={row.tradeRequestId} line={row as TradeRequest} />;
        } else {
            return null;
        }
    };

    return (
        <StandardTable
            gridName="Trade Request Summary"
            columns={columns}
            columnWidths={columnWidths}
            lines={tradeRequests}
            rowComponent={TradeRequestsLine}
            itemsPerPage={10}
        />
    );
}
