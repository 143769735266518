import React from 'react';
import { Section } from '../../../../store/reducers/glp';
import {
    Grid,
    Typography,
    GridList,
    GridListTile,
    makeStyles,
    useMediaQuery,
    useTheme
} from '@material-ui/core';
import CanResearchTile from '../../../reusable/molecules/CanResearchTile';
import { ballGreen, black_8 } from '../../../../themes/globalConstants';

const useStyles = makeStyles((theme) => ({
    subtitle2: {
        lineHeight: '1em',
        color: theme.palette.secondary.main,
        margin: '3.125em 0 0.750em 0',
        letterSpacing: '0.86px',
        height: '1em'
    },
    header: {
        color: theme.palette.info.dark,
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: '0.625em'
    },
    gridList: {
        width: '45em',
        overflowY: 'inherit'
    },
    tile: {
        overflow: 'visible',
        position: 'inherit'
    },
    chevrons: {
        margin: '2em 0'
    }
}));

const tileStyles = makeStyles((theme) => ({
    root: {
        height: '15rem',
        borderRadius: '1px',
        transition: 'transform .2s',
        '& .header-icon': {
            fill: theme.palette.secondary.light
        },
        //Hover State
        '&:hover': {
            transform: 'scale(1.1)',
            overflow: 'visible',
            boxShadow: `0 0 10px 0 ${black_8}`,
            '& .header-text': {
                color: theme.palette.primary.main
            },
            '& .header-icon': {
                fill: ballGreen
            }
        },
        '& .header-text': {
            fontSize: 14,
            fontWeight: 'bold'
        }
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.secondary.main
    },
    cardHeader: {
        height: '0'
    },
    visitedIcon: {
        fill: ballGreen
    },
    icon: {
        height: '3.5em',
        width: '3.5em'
    },
    textFields: {
        padding: '0.625em',
        textAlign: 'center'
    },
    description: {
        marginTop: '0.625em',
        color: theme.palette.info.dark
    }
}));

const CanResearchSection = ({ header, description, subsections }: Section) => {
    const classes = useStyles();
    const theme = useTheme();
    //Set columns for different screen sizes
    const xs = useMediaQuery(theme.breakpoints.down('xs'));
    const sm = useMediaQuery(theme.breakpoints.only('sm'));
    let columns = xs ? 1 : sm ? 2 : 3;

    return header ? (
        <Grid container>
            <Grid item xs={12} container justify="center">
                <Typography variant="h3" component="h1" className={classes.header}>
                    {header}
                </Typography>
            </Grid>
            <Grid item container xs={12} justify="center">
                <Grid item>
                    <img
                        className={classes.chevrons}
                        src={process.env.PUBLIC_URL + '/assets/GLP_Chevrons_Down.svg'}
                        alt="Double Chevron"
                    />
                </Grid>
            </Grid>
            <Grid item md={1} />
            <Grid container item xs={12} md={10} justify="center">
                <GridList cellHeight="auto" cols={columns} className={classes.gridList} spacing={1}>
                    {subsections.map((subsection, subsectionIndex) => (
                        <GridListTile
                            key={subsectionIndex}
                            cols={1}
                            classes={{ tile: classes.tile }}
                        >
                            <CanResearchTile
                                links={subsection.links}
                                description={subsection.description}
                                header={subsection.header}
                                styles={tileStyles}
                                key={subsectionIndex}
                            />
                        </GridListTile>
                    ))}
                </GridList>
            </Grid>
            <Grid item md={1} />
            <Grid item md={12} />
        </Grid>
    ) : null;
};

export default CanResearchSection;
