import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { makeStyles, Typography, TableRow, TableCell, Grid, Button } from '@material-ui/core';
import { small, regularWeight, boldWeight, blackWeight } from '../../../../themes/globalConstants';
import { MakeItBulkLineItem } from '../../../../store/reducers/makeit-bulk-upload';

interface Props {
    item: MakeItBulkLineItem;
    addDisabled: boolean;
    handleAddSelectedProduct: (item: MakeItBulkLineItem) => void;
}

const useStyles = makeStyles((theme) => ({
    tableRowContainer: {
        '& .helper-text': {
            fontWeight: `${regularWeight} !important`,
            fontSize: small
        },
        '& .validation-error-text': {
            fontWeight: `${boldWeight} !important`,
            fontSize: small
        },
        '& .MuiInputBase-input': {
            textAlign: 'right',
            paddingRight: '.25em'
        }
    },
    bold: {
        fontWeight: blackWeight
    },
    addBtn: {
        padding: 0,
        height: '2.28em',
        width: '3.2em'
    }
}));

const MakeItConversionReviewAddProductRow = ({
    item,
    addDisabled,
    handleAddSelectedProduct
}: Props) => {
    const classes = useStyles();

    useTranslation();

    return (
        <>
            <TableRow
                className={classes.tableRowContainer}
                data-testid={`product-${item.displayId}`}
            >
                <TableCell data-testid="display-id">
                    <Grid container alignContent="center">
                        <Grid container>
                            <Typography className={classes.bold}>{item.displayId}</Typography>
                        </Grid>
                        <Grid container>
                            <Typography>{item.graphicIdAndVersion}</Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell data-testid="product-description">
                    <Typography className={classes.bold}>{item.displayName}</Typography>
                </TableCell>
                <TableCell data-testid="coating">
                    <Typography className={classes.bold}>{item.coating}</Typography>
                </TableCell>
                <TableCell data-testid="leadtime-information">
                    <Typography
                        data-leadtime={item.leadTimeWeeks}
                        data-testid="leadtime"
                        className={classes.bold}
                    >
                        {`${item.leadTimeWeeks} `}
                        {item.leadTimeWeeks === 1 ? (
                            <Trans i18nKey="week">Week</Trans>
                        ) : (
                            <Trans i18nKey="weeks">Weeks</Trans>
                        )}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        onClick={() => handleAddSelectedProduct(item)}
                        className={classes.addBtn}
                        data-testid="addproduct-btn"
                        disabled={addDisabled}
                    >
                        <Trans i18nKey="add">Add</Trans>
                    </Button>
                </TableCell>
            </TableRow>
        </>
    );
};

export default MakeItConversionReviewAddProductRow;
