import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { ReactElement } from 'react';
import { ErrorMessage, Controller } from 'react-hook-form';
import { KeyValuePair } from '../../../store/types';

interface Props {
    label: ReactElement;
    name: string;
    testId: string;
    control: any;
    selectObj: any;
    rules?: any;
    error?: boolean;
    errors?: any;
    styles?: any;
    helperText?: ReactElement;
    defaultValue?: string;
    disabled?: boolean;
    value?: KeyValuePair;
    onChange: (value: KeyValuePair) => void;
}

const AutocompleteInput = (props: Props) => {
    const error = props.error !== undefined;

    return (
        <FormControl fullWidth error={error} className={props.styles}>
            <Controller
                as={
                    <Autocomplete
                        data-testid={props.testId}
                        options={props.selectObj}
                        getOptionLabel={(option) => {
                            if (option) {
                                return `${option.name}`;
                            }
                            return '';
                        }}
                        value={props.value}
                        renderInput={(params) => (
                            <TextField {...params} label={props.label} error={error} />
                        )}
                    />
                }
                onChange={([event, value]) => {
                    props.onChange(value);
                    return value;
                }}
                disabled={props.disabled}
                name={props.name}
                control={props.control}
                rules={props.rules}
                defaultValue={props.defaultValue ? props.defaultValue : ''}
            />
            {!error && props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
            <FormHelperText>
                <ErrorMessage errors={props.errors} name={props.name} />
            </FormHelperText>
        </FormControl>
    );
};

export default AutocompleteInput;
