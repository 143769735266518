import React, { useState, useEffect } from 'react';
import InactiveProductsColumns from './InactiveProductsColumns';
import InactiveProductsRow from './InactiveProductsRow';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import StandardTable from '../../../reusable/molecules/StandardTable';
import { InactiveProduct } from '../../../../store/reducers/planit-bulk-atm';

interface Props {
    products: InactiveProduct[];
}

export default function InactiveProductsTable({ products }: Props) {
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const [columns, setColumns] = useState<any>([]);

    const columnWidths = [15, 30, 15, 40];

    useEffect(() => {
        setColumns(InactiveProductsColumns.getColumns(cultureCode));
    }, [cultureCode]);

    const ProductAlertsLine = ({ row }: { row: InactiveProduct }) => {
        if (row) {
            return <InactiveProductsRow key={row.lineNumber} line={row as InactiveProduct} />;
        } else {
            return null;
        }
    };

    return (
        <StandardTable
            gridName="Inactive Products"
            columns={columns}
            columnWidths={columnWidths}
            lines={products}
            rowComponent={ProductAlertsLine}
            itemsPerPage={10}
        />
    );
}
