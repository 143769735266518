import ConfirmationModal from "./ConfirmationModal";
import React, { ReactNode } from "react";
import { Trans } from "react-i18next";

interface Props {
    open: boolean;
    orderName?: string;
    onClose: () => void;
    saveProgress?: () => void;
    saveInputLabel?: ReactNode;
    saveDraft: (name: string) => void;
    error: boolean;
}

const SaveModal = ({ saveProgress, open, onClose, orderName, saveInputLabel, saveDraft, error }: Props) => {
    return (
        <ConfirmationModal
            title={<Trans i18nKey="saveForLater">Save For Later</Trans>}
            open={open}
            logo={false}
            subheader={
                <Trans i18nKey="enterDraftName">Please enter a draft name for your production order to save it for later.</Trans>
            }
            cancelText={<Trans i18nKey="continueEditing">Continue Editing</Trans>}
            continueText={<Trans i18nKey="saveForLater">Save For Later</Trans>}
            saveProgress={saveProgress}
            saveDraft={saveDraft}
            onClose={onClose}
            saveInput={true}
            saveInputLabel={saveInputLabel}
            saveInputDefaultValue={orderName || ''}
            error={error}
            errorLabel={<Trans i18nKey="draftOrderError">Draft order failed to save properly. Please try again later.</Trans>}
        />
    )
}

export default SaveModal;