import React, { Dispatch, SetStateAction, useState } from 'react';
import { Select, MenuItem, makeStyles, Typography, Grid, MenuProps } from '@material-ui/core';
import {
    shipRed,
    successGreen,
    white,
    ballBlue,
    ballLtGray_3,
    large,
    medium,
    boldWeight
} from '../../../../themes/globalConstants';
import clsx from 'clsx';
import {
    DateProperties,
    getDateProperties
} from '../../../../utility/helpers/make-it-bulk-helpers';
import { FormattedWeek } from '../models/FormattedWeek';

interface Props {
    testId: string;
    weeks: FormattedWeek[];
    onDateSelected: Dispatch<SetStateAction<DateProperties>>;
    selectedDate: DateProperties;
    showIcons: boolean;
    customerPONumber?: string;
    showCustomerPoNumber?: boolean;
}

const useStyles = makeStyles(() => ({
    inputSelect: {
        width: '90%',
        height: 'flex',
        borderBottom: `0.25em solid ${ballBlue}`,

        '& .MuiInputBase-input': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            padding: '0em 1em 0.25em 3em'
        }
    },
    menu: {
        marginTop: '0.125em',
        '& ul': {
            marginRight: '-8em'
        },
        '& li': {
            paddingLeft: '3em',
            height: '68px',
            '&:hover': {
                backgroundColor: ballLtGray_3
            }
        }
    },
    clearBackground: {
        backgroundColor: white,
        '&.MuiListItem-root.Mui-selected': {
            backgroundColor: white
        },
        '&.Mui-focused': {
            backgroundColor: white
        },
        '&:hover': {
            backgroundColor: white
        }
    },
    icon: {
        width: '1.5em',
        height: '1.5em',
        position: 'absolute',
        left: '1em'
    },
    errorIcon: {
        fill: shipRed,
        width: '1.5em',
        height: '1.5em',
        position: 'absolute',
        left: '1em'
    },
    completedIcon: {
        fill: successGreen
    },
    dateOptionMain: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        '& p': {
            fontWeight: boldWeight
        }
    },
    dateOptionWeek: {
        fontSize: medium,
        lineHeight: 1.25
    },
    dateOptionYear: {
        fontSize: large,
        lineHeight: 1.25
    }
}));

const WeekSelectorDropdown = ({
    testId,
    weeks,
    onDateSelected,
    selectedDate,
    showIcons: showValidation,
    customerPONumber,
    showCustomerPoNumber
}: Props) => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const shipToMenuProperties = {
        classes: {
            paper: classes.menu
        },
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
        },
        getContentAnchorEl: null
    } as MenuProps;

    const handleChange = (event: any) => {
        const week = weeks.find(
            (week) => week.weekAsDate.format('MM/DD/YYYY') === event.target.value
        );
        if (week) {
            const dateProperties = getDateProperties(week.weekAsDate);
            onDateSelected(dateProperties);
        }
    };

    const handleOpen = (event: any) => {
        setIsOpen(true);
    };

    const handleClose = (event: any) => {
        setIsOpen(false);
    };

    function getFormattedComponent() {
        return weeks.map((week, index) => {
            const dateProperties = getDateProperties(week.weekAsDate);

            return (
                <MenuItem
                    key={index}
                    value={week.weekAsDate.format('MM/DD/YYYY')}
                    data-testid="menu-option"
                    className={classes.clearBackground}
                >
                    {(showValidation && week.state === 'valid' && (
                        <img
                            data-testid="complete-icon"
                            className={clsx(classes.icon, classes.completedIcon)}
                            src={process.env.PUBLIC_URL + '/assets/Confirmation_icon.svg'}
                            alt="Confirmation Icon"
                        />
                    )) ||
                        (week.state === 'warning' && (
                            <img
                                data-testid="warning-icon"
                                className={clsx(classes.icon)}
                                src={process.env.PUBLIC_URL + '/assets/Warning_icon.svg'}
                                alt="Warning Icon"
                            />
                        )) ||
                        (week.state === 'error' && (
                            <img
                                data-testid="error-icon"
                                className={classes.errorIcon}
                                src={process.env.PUBLIC_URL + '/assets/Error_Icon.svg'}
                                alt="Error Icon"
                            />
                        ))}
                    {week.hasAlert && week.state !== 'warning' && (
                        <img
                            data-testid="info-icon"
                            className={classes.icon}
                            src={process.env.PUBLIC_URL + '/assets/InfoTriangle_Icon.svg'}
                            alt="Information Icon"
                        />
                    )}
                    <Grid
                        container
                        item
                        className={clsx(classes.dateOptionMain)}
                        data-testid={`date-option-${index}`}
                        data-value={`${dateProperties.fullDate}`}
                    >
                        <Typography className={classes.dateOptionWeek}>
                            {dateProperties.fullDate}{' '}
                            {showCustomerPoNumber && !isOpen ? ': ' + customerPONumber : ''}
                        </Typography>
                    </Grid>
                </MenuItem>
            );
        });
    }

    return (
        <Select
            inputProps={{ 'data-testid': testId }}
            className={clsx(classes.inputSelect, classes.clearBackground)}
            value={selectedDate ? selectedDate.fullDate : null}
            onChange={handleChange}
            MenuProps={shipToMenuProperties}
            disableUnderline
            onOpen={handleOpen}
            onClose={handleClose}
        >
            {getFormattedComponent()}
        </Select>
    );
};

export default WeekSelectorDropdown;
