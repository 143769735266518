import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { enUS } from '../../../../utility/translations/locales';
import IconLink from '../../../reusable/atoms/IconLink';
import FormSection from '../../../reusable/molecules/FormSection';
import { getMediaPath } from '../../../../utility/helpers/content-helpers';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { useTypedSelector } from '../../../../store/reducers/reducer';

const useStyles = makeStyles(() => ({
    instructionsContainer: {
        '& p': {
            marginBottom: '1em'
        },
        '& h4': {
            width: '100%',
            marginBottom: '1em',
            marginTop: '1em'
        }
    },
    icon: {
        marginTop: '2em'
    }
}));

export default function Instructions() {
    const classes = useStyles();
    const { t } = useTranslation();
    const { regionCode, cultureCode } = useTypedSelector<RegionCultureState>(
        (state) => state.regionCulture
    );

    return (
        <FormSection
            testId={'instructions-section'}
            sectionHeader={
                <Trans i18nKey="salesAndUseTaxSideHeader">{enUS.salesAndUseTaxSideHeader}</Trans>
            }
        >
            <Grid item xs={12} className={classes.instructionsContainer}>
                <Typography variant="h4">
                    <Trans i18nKey="salesUseTaxInstructionsHeader">
                        {enUS.salesUseTaxInstructionsHeader}
                    </Trans>
                </Typography>
                <Typography variant="body1">
                    <Trans i18nKey="salesUseTaxInstructionsBody1">
                        {enUS.salesUseTaxInstructionsBody1}
                    </Trans>
                </Typography>
                <Typography variant="body1">
                    <Trans i18nKey="salesUseTaxInstructionsBody2">
                        {enUS.salesUseTaxInstructionsHeader}
                    </Trans>
                </Typography>
                <Typography variant="body1">
                    <Trans i18nKey="salesUseTaxInstructionsBody3">
                        {enUS.salesUseTaxInstructionsBody3}
                    </Trans>
                </Typography>
                <Typography variant="body1">
                    <Trans i18nKey="salesUseTaxInstructionsBody4">
                        {enUS.salesUseTaxInstructionsBody4}
                    </Trans>
                </Typography>
                <Typography variant="body1">
                    <Trans i18nKey="salesUseTaxInstructionsBody5">
                        {enUS.salesUseTaxInstructionsBody5}
                    </Trans>
                </Typography>
                <IconLink
                    svgIcon={
                        <img
                            src={process.env.PUBLIC_URL + '/assets/Paper_Icon.svg'}
                            alt="PDF Icon"
                        />
                    }
                    url={getMediaPath(
                        `/CustomerPortalCMS/media/customer-portal-media/Regions/${regionCode}/PDF%20Library/Sales%20and%20Use%20Tax/salesandusetaxstaterules_${regionCode}_${cultureCode}.pdf`
                    )}
                    text={t(
                        'salesUseTaxInstructionsLinkText',
                        enUS.salesUseTaxInstructionsLinkText
                    )}
                    className={classes.icon}
                />
            </Grid>
        </FormSection>
    );
}
