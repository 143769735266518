import React, { useEffect, useState } from 'react';
import {
    makeStyles,
    Grid,
    Typography,
    Divider,
    List,
    ListItem,
    ListItemIcon
} from '@material-ui/core';
import { End } from '../../../../store/reducers/product-end-details';
import { useTranslation, Trans } from 'react-i18next';
import { listIcon, ltBlueGrey_15, black_5, medium } from '../../../../themes/globalConstants';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { currencyFormatter } from '../../../../utility/helpers/formatters';
import { getMediaPath } from '../../../../utility/helpers/content-helpers';
import Link from '../../../reusable/atoms/Link';

interface Props {
    end: End;
    regionCode: string;
    queryContent?: any;
}

const useStyles = makeStyles((theme) => ({
    active: {
        opacity: '100%',
        width: '100%'
    },
    inactive: {
        width: '100%',
        //Set text opacity to 50%
        color: black_5
    },
    headers: {
        fontSize: 18,
        margin: '1em 0 0.5em 0.6em',
        fontWeight: 700,
        border: 0
    },
    contentContainer: {
        minHeight: '30em',
        maxHeight: '35em',
        marginBottom: '5em',
        borderRight: `1px solid ${theme.palette.secondary.light}`,
        [theme.breakpoints.only('xs')]: {
            borderLeft: `1px solid ${theme.palette.secondary.light}`,
            margin: 'auto'
        }
    },
    tileContainer: {
        [theme.breakpoints.only('xs')]: {
            margin: 'auto',
            marginBottom: '4em'
        }
    },
    mainDivider: {
        height: 5,
        backgroundColor: ltBlueGrey_15,
        marginBottom: '0.5em'
    },
    subheaders: {
        fontSize: medium,
        margin: '0 0 1em 1em'
    },
    compatibleContainer: {
        height: '8em'
    },
    listText: {
        fontSize: medium,
        margin: 0
    },
    compatibleList: {
        marginTop: '0.5em'
    },
    compatibleListItem: {
        paddingLeft: '1em'
    },
    featureContainer: {
        height: '17em',
        paddingBottom: '.5em'
    },
    featureList: {
        marginTop: '0.5em'
    },
    roppContainer: {
        height: '10em'
    },
    price: {
        fontSize: medium,
        marginLeft: '1em',
        marginBottom: '1em'
    },
    roppDisclaimer: {
        fontSize: 12,
        fontWeight: 500,
        marginLeft: '1em'
    },
    pdfLink: {
        fontSize: 12,
        marginLeft: '1.5em'
    },
    specificationsLinkContainer: {
        marginBottom: '0.25em'
    },
    ropp38Text: {
        fontSize: medium,
        marginLeft: '0.5em',
        marginRight: '0.5em'
    }
}));

const EndDetailsTile = ({ end, regionCode, queryContent }: Props) => {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [pdfUrl, setPdfUrl] = useState<string>('/');
    const [palletSpecificationLink, setPalletSpecificationLink] = useState<string>('/');
    const isThirdParty = end.thirdParty;
    const isNA = regionCode === 'NA';
    const isES = cultureCode === 'es-ES';
    const hasQueryContent = queryContent.length > 0;
    const isActive = queryContent.filter((compatibleEndCode) => compatibleEndCode === end.endCode);
    const coloredShells = end.features
        .filter((feature) => feature.type === 'CLRSHL')
        .map((prop) => prop.label)
        .join(', ');
    useTranslation();

    const ropp38Code = 'ROPP_38_NA';
    const lug38Code = 'LUG_38_NA';

    //Check for TAB/TABCOLOR featureCode
    const hasColorTabs = end.features.filter((feature) => feature.featureCode === 'COLORTAB');
    //If no colors available, display 'no stocked colors available' text
    const noColorsAvailable = end.features
        .filter((feature) => feature.type === 'TABCLR' && feature.featureCode === 'NONEAVBLE')
        .map((prop) => prop.label);

    //If it has colors available
    const tabColors =
        hasColorTabs &&
        end.features
            .filter((feature) => feature.type === 'TABCLR' && feature.featureCode !== 'NONEAVBLE')
            .map((color) => color.label)
            .join(', ');
    //For the main features list, filter out tabclr/clrshl types and colortab code
    const filteredFeatures = end.features.filter(
        (feature) =>
            feature.type !== 'TABCLR' &&
            feature.type !== 'CLRSHL' &&
            feature.featureCode !== 'COLORTAB'
    );

    const getEndLabel = (code: string) => {
        if (code === ropp38Code) {
            return <Trans i18nKey="closure">Closure</Trans>;
        } else if (code === lug38Code) {
            return '';
        } else {
            return <Trans i18nKey="end">End</Trans>;
        }
    };

    useEffect(() => {
        if (regionCode === 'NA') {
            setPdfUrl(
                getMediaPath(
                    `/CustomerPortalCMS/media/customer-portal-media/Regions/${regionCode}/PDF%20Library/End%20Specs/${end.endCode}.pdf`
                )
            );
            setPalletSpecificationLink(
                getMediaPath(
                    `/CustomerPortalCMS/media/customer-portal-media/Regions/${regionCode}/PDF%20Library/End%20Pallet%20Specs/${end.endCode}.pdf`
                )
            );
        }
    }, [end, regionCode]);

    return (
        <Grid
            container
            item
            xs={10}
            sm={4}
            className={classes.tileContainer}
            data-testid="end-details-tile"
        >
            <div className={isActive[0] || !hasQueryContent ? classes.active : classes.inactive}>
                <Grid item xs={12} data-testid="tile-header">
                    {isES ? (
                        <Typography variant="h5" component="h2" className={classes.headers}>
                            {getEndLabel(end.endCode)}
                            {' ' + end.label}
                            {isThirdParty && '*'}
                        </Typography>
                    ) : (
                        <Typography variant="h5" component="h2" className={classes.headers}>
                            {end.label + ' '}
                            {getEndLabel(end.endCode)}
                            {isThirdParty && '*'}
                        </Typography>
                    )}
                    <Divider className={classes.mainDivider} />
                </Grid>
                <Grid item xs={12}>
                    {isNA ? (
                        <Typography variant="h5" component="h2" className={classes.price}>
                            <Trans i18nKey="pricePerThousand">Price per 1,000</Trans>:{' '}
                            {end.pricePerThousand && end.endCode !== ropp38Code
                                ? currencyFormatter(cultureCode, end.pricePerThousand)
                                : 'N/A'}
                        </Typography>
                    ) : null}
                </Grid>
                <Grid container className={classes.contentContainer}>
                    <Grid item xs={12}>
                        <Typography variant="h5" component="h2" className={classes.subheaders}>
                            <Trans i18nKey="compatibleCans">Compatible Cans</Trans>
                        </Typography>
                        <Divider />
                    </Grid>
                    <Grid container className={classes.compatibleContainer}>
                        <Grid item xs={12} className={classes.compatibleContainer}>
                            {end?.compatibleSizesByStyle ? (
                                <List className={classes.compatibleList}>
                                    {Array.from(end.compatibleSizesByStyle.keys()).map(
                                        (compatibleStyleKey, index) => (
                                            <ListItem
                                                className={classes.compatibleListItem}
                                                key={index}
                                            >
                                                <ListItemIcon>
                                                    <span>{listIcon}</span>
                                                </ListItemIcon>
                                                <Typography
                                                    variant="body1"
                                                    className={classes.listText}
                                                >
                                                    {compatibleStyleKey}{' '}
                                                    {end?.compatibleSizesByStyle
                                                        ?.get(compatibleStyleKey)
                                                        ?.map((size) =>
                                                            isNA ? size.prettySizes : size.prettyML
                                                        )
                                                        .join(', ')}
                                                </Typography>
                                            </ListItem>
                                        )
                                    )}
                                </List>
                            ) : null}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {!isThirdParty && end.features.length > 0 && (
                            <>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    className={classes.subheaders}
                                >
                                    <Trans i18nKey="endFeaturesAndEffects">
                                        End Features and Effects
                                    </Trans>
                                </Typography>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                            </>
                        )}
                    </Grid>
                    {isThirdParty ? (
                        <Grid item xs={12} className={classes.featureContainer}>
                            <Typography
                                variant="h5"
                                component="h2"
                                className={classes.roppDisclaimer}
                            >
                                *{' '}
                                <Trans i18nKey="roppClosures">
                                    ROPP Closures are sold via 3rd Party
                                </Trans>
                            </Typography>
                        </Grid>
                    ) : (
                        <Grid item xs={12} className={classes.featureContainer}>
                            {end.features.length > 0 ? (
                                <List className={classes.featureList}>
                                    {filteredFeatures.map((feature, index) => (
                                        <ListItem key={index}>
                                            <ListItemIcon>
                                                <span>{listIcon}</span>
                                            </ListItemIcon>
                                            <Typography
                                                variant="body1"
                                                className={classes.listText}
                                            >
                                                {feature.label}
                                            </Typography>
                                        </ListItem>
                                    ))}
                                    {coloredShells && (
                                        <ListItem>
                                            <ListItemIcon>
                                                <span>{listIcon}</span>
                                            </ListItemIcon>
                                            <Typography
                                                variant="body1"
                                                className={classes.listText}
                                            >
                                                Colored Shells: {coloredShells}
                                            </Typography>
                                        </ListItem>
                                    )}
                                    {noColorsAvailable && noColorsAvailable.length > 0 && (
                                        <ListItem>
                                            <ListItemIcon>
                                                <span>{listIcon}</span>
                                            </ListItemIcon>

                                            <Typography
                                                variant="body1"
                                                className={classes.listText}
                                            >
                                                Color Tab: {noColorsAvailable}
                                            </Typography>
                                        </ListItem>
                                    )}
                                    {tabColors && (
                                        <ListItem>
                                            <ListItemIcon>
                                                <span>{listIcon}</span>
                                            </ListItemIcon>

                                            <Typography
                                                variant="body1"
                                                className={classes.listText}
                                            >
                                                Color Tab: {tabColors}
                                            </Typography>
                                        </ListItem>
                                    )}
                                </List>
                            ) : null}
                        </Grid>
                    )}

                    {isNA && !isThirdParty && (
                        <Grid item xs={12} className={classes.specificationsLinkContainer}>
                            <Link
                                underline="always"
                                className={classes.pdfLink}
                                data-testid="pdf-link"
                                url={pdfUrl}
                                target="_blank"
                            >
                                {end.label + ' '}
                                <Trans i18nKey="specifications">Specifications</Trans>
                            </Link>
                        </Grid>
                    )}
                    {isNA && end.endCode !== ropp38Code && end.endCode !== lug38Code && (
                        <Grid item xs={12}>
                            <Link
                                underline="always"
                                className={classes.pdfLink}
                                data-testid="specifications-link"
                                url={palletSpecificationLink}
                                target="_blank"
                            >
                                <Trans i18nKey="palletSpecification">Pallet Specification</Trans>
                            </Link>
                        </Grid>
                    )}
                    {isNA && end.endCode === ropp38Code && (
                        <Grid item xs={12}>
                            <Typography variant="body1" className={classes.ropp38Text}>
                                <Trans i18nKey="ropp38CappingText">
                                    Product requires special capping. Please contact Silgan
                                    765-983-9282 for pallet specifications.
                                </Trans>
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </div>
        </Grid>
    );
};

export default EndDetailsTile;
