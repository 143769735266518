import React from 'react';
import {
    makeStyles,
    Grid,
    Box,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ThemeProvider,
    createMuiTheme
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { subtract4Weeks } from '../../../utility/helpers/date-helpers';
import StatusTracker from './StatusTracker';
import StatusTrackerMarker from './StatusTrackerMarker';
import { ltBlueGrey_34, listIcon, black, white } from '../../../themes/globalConstants';
import moment from 'moment';

interface Props {
    minimumDate?: string;
    isBulk?: boolean;
}

const statusTheme = createMuiTheme({
    overrides: {
        MuiListItemIcon: {
            root: {
                color: black,
                minWidth: 0,
                marginRight: 7,
                fontSize: 18
            }
        }
    }
});

const useStyles = makeStyles((theme) => ({
    main: {
        margin: '2em 0',
        justifyContent: 'space-between'
    },
    infoBox: {
        width: '21em',
        backgroundColor: ltBlueGrey_34,
        padding: '1em 0.5em 1em',
        borderRadius: 4
    },
    text: {
        paddingTop: 2
    },
    statusTrackerWrapper: {
        width: '58em',
        backgroundColor: white,
        paddingLeft: '1.250em',
        paddingRight: '1.250em'
    }
}));

const PaymentStatusHeader = ({ minimumDate = undefined, isBulk = false }: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const today = moment();
    const paymentDue = !!minimumDate ? subtract4Weeks(minimumDate) : undefined;
    const dueImmediately = !! paymentDue ? moment(paymentDue).isSameOrBefore(today) : false;
    useTranslation();

    const markerList: StatusTrackerMarker[] = [
        {
            icon: true,
            active: true,
            label: t('submitted', 'Submitted')
        },
        {
            active: false,
            label: t('invoiceSent', 'Invoice Sent')
        },
        {
            active: false,
            label: t('cashReceived', 'Cash Received')
        },
        {
            active: false,
            label: t('reactToScheduleShipment', 'Ready to Schedule Shipment')
        }
    ];

    const paymentDueSection = () => {
        if (dueImmediately) {
            if (isBulk) {
                return (
                    <Typography variant="body1">
                        <Trans i18nKey="paymentDueImmediatelyBulk">
                            Payment for the invoice(s) is <strong>due immediately</strong> to
                            secure the requested date for the product(s) on the
                            order(s).
                        </Trans>
                    </Typography>
                )
            } else {
                return (
                    <Typography variant="body1">
                        <Trans i18nKey="paymentDueImmediately">
                            Payment for the invoice is <strong>due immediately</strong> to
                            secure the requested date for the product(s) on the
                            order.
                        </Trans>
                    </Typography>
                )
            }
        } else {
            if (isBulk) {
                if (!!paymentDue) {
                    return (
                        <Typography variant="body1">
                            <Trans i18nKey="paymentForBulk">
                                Payment for the <strong>invoice(s) is due {paymentDue}</strong> (four
                                weeks before your earliest requested date)
                            </Trans>
                        </Typography>
                    )
                } else {
                    return (
                        <Typography variant="body1">
                            <Trans i18nKey="paymentForBulkNoDate">
                                Payment for the invoice(s) is due four weeks before your earliest requested date.
                            </Trans>
                        </Typography>
                    )
                }
                
            } else {
                return (
                    <Typography variant="body1">
                        <Trans i18nKey="paymentFor">
                            Payment for the <strong>invoice is due {paymentDue}</strong> (four
                            weeks before your earliest requested date)
                        </Trans>
                    </Typography>
                )
            }
        }
    };

    return (
        <Grid container className={classes.main}>
            <Grid item className={classes.statusTrackerWrapper} data-testid="status-tracker">
                <StatusTracker markerList={markerList}/>
            </Grid>
            <Grid item>
                <Box className={classes.infoBox} data-testid="info-box">
                    <List>
                        <ListItem alignItems="flex-start">
                            <ThemeProvider theme={statusTheme}>
                                <ListItemIcon>
                                    <span>{listIcon}</span>
                                </ListItemIcon>
                            </ThemeProvider>
                            <ListItemText className={classes.text} data-testid="invoice-text">
                                <Typography variant="body1">
                                    {!isBulk ? (
                                        <Trans i18nKey="proFormaInvoice">
                                            You should receive your pro forma invoice for this order
                                            <strong>within 2-3 business days</strong>.
                                        </Trans>
                                    ) : (
                                        <Trans i18nKey="proFormaInvoiceBulk">
                                            You should receive your pro forma invoice(s) for the order(s)
                                            <strong>within 2-3 business days</strong>.
                                        </Trans>
                                    )}
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem alignItems="flex-start">
                            <ThemeProvider theme={statusTheme}>
                                <ListItemIcon>
                                    <span>{listIcon}</span>
                                </ListItemIcon>
                            </ThemeProvider>
                            <ListItemText className={classes.text} data-testid="payment-text">
                                <Typography variant="body1">
                                    {paymentDueSection()}
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Box>
            </Grid>
        </Grid>
    );
};

export default PaymentStatusHeader;
