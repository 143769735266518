import Axios from 'axios';
import { createAPIQueryParam } from '../helpers/query-helpers';

export interface NotificationRequest {
    shipToIds: String[];
};

export interface NotificationResponse {
    accountId: string,
    notifications: any[]
};

export default class NotificationService {
    private static createConfig = (accessToken: string) => ({
        headers: { Authorization: `Bearer ${accessToken}` }
    });

    static getNotificationData = (accessToken: string, notificationRequest: NotificationRequest) => {
        const requestUrl = `${process.env.REACT_APP_MESSAGE_API}/notifications${createAPIQueryParam(notificationRequest)}`;
        return Axios.get<NotificationResponse[]>(requestUrl, NotificationService.createConfig(accessToken));
    };
}