import React, { ReactNode } from 'react';
import { DateProperties } from '../../../../utility/helpers/make-it-bulk-helpers';

export interface State {
    activeDate: DateProperties;
    shipToId: number;
}

export interface ProviderProps {
    children: ReactNode;
    value: State;
}

const OrderATMContext = React.createContext<State>({} as State);

function MakeItConversionReviewProvider(props: ProviderProps) {
    const { children, value } = props;
    return <OrderATMContext.Provider value={value}> {children} </OrderATMContext.Provider>;
}

const useMakeItConversionReview = () => React.useContext(OrderATMContext);

export { MakeItConversionReviewProvider, useMakeItConversionReview };
