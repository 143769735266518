import React from 'react';
import { TableCell, TableRow, Typography } from '@material-ui/core';
import { formatDate } from '../../../../utility/helpers/date-helpers';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';

interface Props {
    campaign?: any;
    hasCampaignRuns?: boolean;
}

const MakeItBulkCampaignRow = ({ campaign, hasCampaignRuns }: Props) => {
    useTranslation();
    return (
        <>
            {hasCampaignRuns && campaign && (
                <TableRow key={'campaign-run-row' + campaign.campaignRunId}>
                    <TableCell>
                        <Typography data-testid={'product-description'}>
                            {campaign.productDescription}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography data-testid={'order-start-date'}>
                            {formatDate(campaign.orderStartDate)}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography data-testid={'order-end-date'}>
                            {formatDate(campaign.orderEndDate)}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography data-testid={'weeks'}>
                            {campaign.leadTimeWeeks} <Trans i18nKey="weeks">Weeks</Trans>
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography data-testid={'first-available-order-date'}>
                            {formatDate(campaign.firstAvailableOrderDate)}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography data-testid={'new-graphic-approval-deadline'}>
                            {campaign.orderStartDate
                                ? moment(campaign.orderStartDate)
                                      .subtract(14, 'days')
                                      .format('MM/DD/YYYY')
                                : ''}
                        </Typography>
                    </TableCell>
                </TableRow>
            )}
            {!hasCampaignRuns && campaign && (
                <TableRow key={'campaign-product-row' + campaign.productSku}>
                    <TableCell>
                        <Typography data-testid={'product-description'}>
                            {campaign.productDescription}
                        </Typography>
                    </TableCell>

                    <TableCell colSpan={5}>
                        <Typography data-testid={'no-campaigns-scheduled'}>
                            <Trans i18nKey="noCampaignsScheduled">
                                No campaigns scheduled at this time
                            </Trans>
                        </Typography>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

export default MakeItBulkCampaignRow;
