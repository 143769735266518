import { LIQUID_INTAKE_SUBMITTED } from '../actions/action-types';
import { LiquidTestIntake } from '../reducers/liquid-intake';

export const saveLiquidTestResponse = (data: LiquidTestIntake) => {
    return (dispatch: any) => {
        let testRequired = data.testRequired;
        dispatch({
            type: LIQUID_INTAKE_SUBMITTED,
            liquidtest: data,
            testRequired: testRequired
        });
    };
};
