import { Grid, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { PendingRequestTableRow, UserDashboardState } from '../../../../store/reducers/user-admin';
import {
    boldWeight,
    blackWeight,
    ballBlue,
    dateOptionInvalidGray,
    small
} from '../../../../themes/globalConstants';
import TranslationService from '../../../../utility/services/translation-service';
import UserAdminPendingRequestRow from './UserAdminPendingRequestsRow';
import UserAdminPendingRequestsColumns from './UserAdminPendingRequestsColumns';
import { getPendingRequestsRoles } from '../../../../utility/helpers/user-admin-helpers';
import {
    getShippingInformation,
    ShipToAccountInformation
} from '../../../../utility/helpers/address-helpers';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import moment from 'moment';
import { AuthState } from '../../../../store/reducers/auth';
import { getAdminAccounts } from '../../../../utility/helpers/admin-helpers';
import { hasAdminPersonaForAccount } from '../../../../utility/auth/useSecurity';

const useStyles = makeStyles((theme) => ({
    titleContainer: {
        padding: '1em'
    },
    paper: {
        marginBottom: '1em'
    },
    title: {
        fontSize: '1.5em',
        fontWeight: boldWeight,
        color: theme.palette.secondary.main,
        paddingLeft: '0.625em'
    },
    tableWrapper: {
        '& .MuiToolbar-root': {
            padding: 0,
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                },
                '&:first-of-type ': {
                    paddingLeft: 0
                }
            },
            '& > h2': {
                fontSize: '1.5em',
                fontWeight: boldWeight,
                color: theme.palette.secondary.main,
                paddingLeft: '0.625em'
            },
            '& .MuiInputBase-formControl': {
                border: `1px solid ${dateOptionInvalidGray}`,
                borderRadius: '.125em',
                '&:before': {
                    display: 'none'
                },
                '&:after': {
                    display: 'none'
                },
                '& svg': {
                    height: '.875em',
                    width: '.875em',
                    fill: ballBlue
                },
                '& .MuiIconButton-root': {
                    '&:hover': {
                        backgroundColor: 'transparent !important'
                    }
                },
                '& .MuiInput-input': {
                    paddingLeft: '.25em'
                }
            }
        },
        '& .MuiTableSortLabel-root': {
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em',
            width: '99%',
            justifyContent: 'space-between',
            '& .MuiSvgIcon-root': {
                fontSize: 14,
                opacity: '1'
            }
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                '&:nth-child(1)': {
                    width: '20%'
                },
                '&:nth-child(2)': {
                    width: '20%',
                    fontSize: small,
                    fontWeight: blackWeight,
                    textTransform: 'uppercase',
                    color: theme.palette.secondary.main,
                    borderRadius: '0.25em',
                    paddingLeft: '0.5em',
                    justifyContent: 'space-between'
                },
                '&:nth-child(3)': {
                    width: '30%'
                },
                '&:nth-child(4)': {
                    width: '10%'
                },
                '&:nth-child(5)': {
                    width: '15%',
                    fontSize: small,
                    fontWeight: blackWeight,
                    textTransform: 'uppercase',
                    color: theme.palette.secondary.main,
                    borderRadius: '0.25em',
                    paddingLeft: '0.5em',
                    justifyContent: 'space-between'
                }
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    }
}));

export default function UserAdminPendingRequestsTab() {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [pendingRequestsForTable, setPendingRequestsForTable] = useState<
        PendingRequestTableRow[]
    >([]);
    const { permissionChanges } = useTypedSelector<UserDashboardState>(
        (state) => state.userAdminDashboard
    );

    const [columns, setColumns] = useState<any>([]);
    const [userIsAdmin, setUserIsAdmin] = useState<boolean>(false);
    useTranslation();
    const { accounts, selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const getLocation = useCallback(
        (accountId: string): ShipToAccountInformation | undefined => {
            if (accounts) {
                const filteredAccount = accounts.find((account) => account.accountId === accountId);
                if (filteredAccount) {
                    const locationInfo = getShippingInformation(
                        filteredAccount
                    ) as ShipToAccountInformation;
                    return locationInfo;
                }
            }
        },
        [accounts]
    );

    useEffect(() => {
        if (accounts && permissions && permissionChanges && selectedAccountId) {
            const adminAccounts = getAdminAccounts(permissions, accounts);

            const pendingRequests: PendingRequestTableRow[] = [];
            permissionChanges
                .filter((permissionChange) => permissionChange.accountId === selectedAccountId)
                .forEach((permissionChange) => {
                    const accountLocation = getLocation(permissionChange.accountId);
                    if (accountLocation) {
                        let pendingRequest: PendingRequestTableRow = {
                            requestId: permissionChange.permissionChangeId,
                            accountId: permissionChange.accountId,
                            userName: permissionChange.userName,
                            sentOn: moment(permissionChange.createDate).format('MM/DD/YYYY'),
                            accountName: accountLocation.shipToName,
                            locationAddress: accountLocation.displayAddress,
                            roles: getPendingRequestsRoles(permissionChange.personas, cultureCode),
                            currentUserHasAdminPermission: adminAccounts.some(
                                (account) => account.accountId === permissionChange.accountId
                            )
                        };
                        pendingRequests.push(pendingRequest);
                    }
                });
            setPendingRequestsForTable(
                pendingRequests.sort((a, b) => a.sentOn.localeCompare(b.sentOn))
            );
            setIsLoaded(true);
        }
    }, [accounts, cultureCode, getLocation, permissions, permissionChanges, selectedAccountId]);

    const options = new ToolbarOptions({
        title: TranslationService.getTranslatedText(cultureCode, 'pendingRequests'),
        advancePagination: false,
        exportButton: false,
        printButton: false,
        searchText: true
    });

    const pendingRequestTableItem = ({ row }: { row: Partial<PendingRequestTableRow> }) => {
        if (row) {
            return (
                <UserAdminPendingRequestRow
                    request={row as PendingRequestTableRow}
                    hasAdminPersona={userIsAdmin}
                />
            );
        } else {
            return null;
        }
    };

    useEffect(() => {
        if (permissions && selectedAccountId) {
            setUserIsAdmin(hasAdminPersonaForAccount(permissions, selectedAccountId));
        }
    }, [permissions]);

    useEffect(() => {
        setColumns(
            UserAdminPendingRequestsColumns.getPendingRequestsColumns(cultureCode, userIsAdmin)
        );
    }, [cultureCode, userIsAdmin]);

    return (
        <Paper elevation={2} className={classes.paper}>
            <Grid className={classes.tableWrapper}>
                {pendingRequestsForTable && isLoaded && (
                    <DataGrid
                        gridName="Pending Requests"
                        columns={columns}
                        dataSource={pendingRequestsForTable}
                        rowComponent={pendingRequestTableItem}
                        toolbarOptions={options}
                    />
                )}
            </Grid>
        </Paper>
    );
}
