import React, { useEffect, useState } from 'react';
import { TableCell, TableRow, Typography, makeStyles } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
    black,
    blackWeight,
    ltBlueGrey_15,
    makeItCanBtlDefaultLeadTime,
    makeItEndsDefaultLeadTime,
    regularWeight,
    small
} from '../../../../themes/globalConstants';
import Button from '../../../reusable/atoms/Button';
import { OrderProductTableRow } from '../../../../store/reducers/orders-dashboard';
import { enUS } from '../../../../utility/translations/locales';
import {
    getGraphicIdAndVersion,
    isProductCanType
} from '../../../../utility/helpers/order-helpers';
import QuantityInputCell from '../../../reusable/atoms/QuantityInputCell';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { numericRegex } from '../../../../utility/helpers/formatters';
import { isDateWithinAtmLockPeriod } from '../../../../utility/helpers/date-helpers';
import { getProductCellQuantities } from '../../../../utility/helpers/shipment-helpers';
import { selectIsLargeCustomerAccount } from '../../../../store/selectors';
import { useQuantityUnitSelectorState } from '../../../reusable/molecules/QuantityUnitSelectorContext';
import { MakeState } from '../../../../store/reducers/makeit-dashboard';
import CampaignPill from '../../../reusable/atoms/CampaignPill';

interface Props {
    item: OrderProductTableRow;
    onAddItem: (
        item: OrderProductTableRow,
        addDisabled: boolean,
        removeAll: boolean,
        quantity: number | undefined
    ) => void;
    getPaginationRows: () => void;
    onInputBlur: (product: OrderProductTableRow, quantity: number) => void;
}

const useStyles = makeStyles((theme) => ({
    tableRowContainer: {
        '&:hover': {
            backgroundColor: ltBlueGrey_15
        },
        '& > td': {
            padding: '1.250em'
        },
        '& .MuiInputBase-input': {
            textAlign: 'right',
            paddingRight: '.25em'
        },
        '& .MuiTableCell-root': {
            '&:not(:last-child)': {
                paddingLeft: '1.125em'
            }
        },
        '& .normal': {
            fontWeight: `${regularWeight} !important`,
            textAlign: 'right'
        }
    },
    addBtn: {
        height: '2.28em',
        width: '3.2em'
    },
    addBtnMessage: {
        color: black,
        fontStyle: 'italic',
        fontWeight: regularWeight
    },
    snoSkuAttributes: {
        fontSize: small
    },
    bold: {
        fontWeight: blackWeight
    },
    centered: {
        textAlign: 'center'
    }
}));

export default function MakeItRow({ item, onAddItem, getPaginationRows, onInputBlur }: Props) {
    const classes = useStyles();
    useTranslation();
    const { requestedDate } = useTypedSelector<MakeState>((state) => state.makeItDashboard);
    const { quantityUnit } = useQuantityUnitSelectorState();

    const [quantity, setQuantity] = useState<number | undefined>(undefined);
    const [quantityPallets, setQuantityPallets] = useState<number | undefined>(undefined);
    const isLargeCustomer = useTypedSelector<boolean>(selectIsLargeCustomerAccount);

    const addItem = () => {
        if (isLargeCustomer) {
            onAddItem(item, false, false, item.palletQuantity);
        } else {
            onAddItem(item, true, false, undefined);
        }

        //When we add an item, we retrieve selected pagination rows so we can persist them
        getPaginationRows();
    };

    useEffect(() => {
        setQuantity(quantityUnit === 'pallets' ? item.palletQuantity : item.eachesQuantity);
    }, [item, quantityUnit]);

    const handleQuantityInputBlur = () => {
        onInputBlur(item, quantityPallets ?? 0);
    };

    const handleQuantityInputChange = (count: string) => {
        let updatedQuantity: number | undefined = undefined;
        if (numericRegex(count)) {
            const parsedCount = parseInt(count);
            if (!isNaN(parsedCount)) {
                updatedQuantity = parsedCount;
            }
        }
        if (typeof updatedQuantity !== 'undefined') {
            const [inputPallets] = getProductCellQuantities(
                updatedQuantity,
                item.quantityPerPallet!,
                quantityUnit
            );
            setQuantityPallets(inputPallets);
        } else {
            setQuantityPallets(updatedQuantity);
        }
        setQuantity(updatedQuantity);
    };
    const isBeforeLeadTime = (): boolean => {
        let leadTime = item.leadTimeWeeks;
        if (!leadTime) {
            leadTime = isProductCanType(item.type)
                ? makeItCanBtlDefaultLeadTime
                : makeItEndsDefaultLeadTime;
        }
        const period = isDateWithinAtmLockPeriod(requestedDate, leadTime * 7);
        return period !== 'after';
    };

    const addButtonDisabled = () => {
        const disabled: boolean = isLargeCustomer
            ? typeof quantity === 'undefined'
            : !!item.addDisabled;
        return disabled;
    };

    const exceedsLeadTime = isLargeCustomer && isBeforeLeadTime();

    return (
        <TableRow
            className={classes.tableRowContainer}
            data-testid={`product-${item.displayId}`}
            data-can-type={item.type}
        >
            <TableCell data-testid="graphic-version-cell">
                <Typography className={classes.bold}>{item.customerProductId ? item.customerProductId : "-"}</Typography>
                <Typography className={classes.bold}>{item.displayId}</Typography>
                <Typography>
                    {getGraphicIdAndVersion(item.type, item.graphicIdAndVersion)}
                </Typography>
            </TableCell>
            {isLargeCustomer && (
                <TableCell data-testid="lead-time-cell">
                    <Typography>
                        {item.leadTimeWeeks} <Trans i18nKey="weeks">Weeks</Trans>
                    </Typography>
                </TableCell>
            )}
            <TableCell data-testid="display-name-cell">
                <Typography>{item.displayName}</Typography>
            </TableCell>
            <TableCell data-testid="description-cell">
                {isLargeCustomer ? (
                    <>
                        <Typography className={clsx({[classes.bold]: (item.snoSkuAttributes ?? '').length > 0 })}>{item.snoSkuDescription}</Typography>
                        <Typography
                            className={classes.snoSkuAttributes}
                            data-testid="sno-sku-attributes"
                        >
                            {item.snoSkuAttributes}
                        </Typography>
                    </>
                ) : (
                    <Typography>{item.description}</Typography>
                )}
                {item.campaignTiming && <CampaignPill />}
            </TableCell>
            <TableCell data-testid="coating-cell">
                <Typography>{item.coating}</Typography>
            </TableCell>
            {isLargeCustomer && (
                <TableCell data-testid="quantity-input">
                    <QuantityInputCell
                        quantity={quantity}
                        quantityPerPallet={item.quantityPerPallet!}
                        quantityUnit={quantityUnit}
                        onBlur={handleQuantityInputBlur}
                        onChange={handleQuantityInputChange}
                        palletsLimit={6}
                        eachesLimit={12}
                        showEmptyCellOnUndefined={true}
                    />
                </TableCell>
            )}
            <TableCell className={clsx({
                [classes.centered]: !exceedsLeadTime
            })}>
                {exceedsLeadTime ? (
                    <span
                        data-testid="lead-time-exceeds-selected"
                        className={classes.addBtnMessage}
                    >
                        <Trans i18nKey="leadTimeExceedsSelected">
                            {enUS.leadTimeExceedsSelected}
                        </Trans>
                    </span>
                ) : (
                    <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        onClick={addItem}
                        className={classes.addBtn}
                        data-testid="addproduct-btn"
                        disabled={addButtonDisabled()}
                    >
                        <Trans i18nKey="add">Add</Trans>
                    </Button>
                )}
            </TableCell>
        </TableRow>
    );
}
