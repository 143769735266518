import React from "react";
import { Trans } from "react-i18next";
import ConfirmationModal from "./ConfirmationModal";

interface Props {
    onDiscardChanges: () => void;
    onClose: () => void;
    open: boolean;
    onCancel?: () => void;
}

export default function ShipmentDiscardModal({ onDiscardChanges, open, onClose, onCancel }: Props) {
    return (
        <ConfirmationModal
            title={<Trans i18nKey="changesWillNotBeSaved">Changes made will not be saved</Trans>}
            open={open}
            logo={false}
            subheader={
                <Trans i18nKey="areYouSureDiscard">
                    Are you sure you want to discard any changes?
                </Trans>
            }
            cancelText={<Trans i18nKey="no">No</Trans>}
            continueText={<Trans i18nKey="yesDiscard">Yes, Discard</Trans>}
            saveProgress={onDiscardChanges}
            onClose={onClose}
            onCancel={onCancel}
            canSaveDraft={true}
        />
    )
}
