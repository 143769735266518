import React, { useEffect, useState, useMemo } from 'react';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { Grid, makeStyles, Typography, Paper, Button } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { blackWeight, ballGray, containerMaxWidth } from '../../../themes/globalConstants';
import { useHistory, useParams } from 'react-router-dom';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { AuthState } from '../../../store/reducers/auth';
import { useDispatch } from 'react-redux';
import { getShipToAccounts } from '../../../store/actions/customer-context';
import { Activity, getATMSecurityLevel, SecurityLevel } from '../../../utility/auth/useSecurity';
import { DefaultQueryParams } from '../../../utility/helpers/query-helpers';
import { MakeItATMOrderDetailsState } from '../../../store/reducers/atm-production-order-details';
import {
    loadATMLinkedDeliveryOrders,
    loadATMProductionOrder
} from '../../../store/actions/atm-production-order-details';
import moment from 'moment';
import {
    MakeItBulkATMOrder,
    MakeItBulkATMOrderByWeekAndSku
} from '../../../store/reducers/makeit-bulk-atm';
import { getAllocationsWithShipTos } from '../../../store/actions/makeit-bulk-atm';
import { loadMakeItBulkProducts } from '../../../store/actions/makeit-bulk-upload';
import { BulkUploadMakeItState } from '../../../store/reducers/makeit-bulk-upload';
import PlanItOrderDetailsSubheader from './components/PlanItOrderDetailsSubheader';
import PlanItOrderDetailsGrid from './components/PlanItOrderDetailsGrid';
import {
    DateProperties,
    getDateProperties,
    groupOrdersBySnoSku
} from '../../../utility/helpers/make-it-bulk-helpers';
import PlanItOrderDetailsShareLinks from './components/PlanItOrderDetailsShareLinks';
import { setUseSummaryStoredSearch } from '../../../store/actions/make-it-summary';
import { selectIsLargeCustomer, selectIsLargeCustomerAccount } from '../../../store/selectors';
import { copyFromForecast } from '../../../store/actions/makeit-conversion-atm';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: '3em',
        padding: '2em',
        borderRadius: 4,
        maxWidth: containerMaxWidth
    },
    header: {
        marginTop: '1em',
        marginBottom: '2em'
    },
    subheader: {
        marginBottom: '1em'
    },
    title: {
        fontWeight: blackWeight,
        color: ballGray
    },
    actionBtn: {
        borderRadius: 'unset'
    }
}));

const PlanItOrderDetails = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const { id } = useParams<DefaultQueryParams>();
    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { atmProductionOrder } = useTypedSelector<MakeItATMOrderDetailsState>(
        (state) => state.atmProductionOrderDetails
    );
    const { products } = useTypedSelector<BulkUploadMakeItState>(
        (state) => state.bulkUploadMakeItState
    );
    const [activeDate, setActiveDate] = useState<DateProperties>({
        fullDate: '',
        week: '',
        year: ''
    });
    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);
    const [shipToName, setShipToName] = useState<string>('');
    const [accountId, setAccountId] = useState<string>('');
    const [shipToAddress, setDisplayAddress] = useState<string>('');
    const [ordersBySnoSku, setOrdersBySnoSku] = useState<MakeItBulkATMOrderByWeekAndSku[]>();
    const auth = useTypedSelector<AuthState>((state) => state.auth);
    const isLargeCustomer = useTypedSelector<boolean>(selectIsLargeCustomer);
    const isLargeCustomerAccount = useTypedSelector<boolean>(selectIsLargeCustomerAccount);

    const handleDashboardNavigation = () => {
        history.push('/dashboard');
    };

    const handleCopyToMakeItClick = () => {
        dispatch(
            copyFromForecast(
                [atmProductionOrder?.productionOrderId] as number[],
                atmProductionOrder?.shipToId as number
            )
        );
        history.push('/makeit-conversion-review?from=details');
    };

    // A user can select copy plan it to make it if they have make it permissions
    const copyToMakeItSecurityLevel = useMemo(
        () =>
            getATMSecurityLevel(
                Activity.MakeItATMSummary,
                auth,
                { accountId: atmProductionOrder?.shipToId.toString() || '' },
                isLargeCustomer
            ),
        [auth, isLargeCustomer, atmProductionOrder]
    );

    const currentAccount = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    ).accounts?.find((account) => account.accountId === atmProductionOrder?.shipToId.toString());

    // Is the forecast week in the lock window?
    const copyToMakeItDisabled =
        currentAccount && currentAccount.lockedPeriodDays
            ? moment(atmProductionOrder?.atmWeekStart).isSameOrBefore(
                  moment().add(currentAccount.lockedPeriodDays, 'days')
              )
            : moment(atmProductionOrder?.atmWeekStart).isSameOrBefore(moment().add(14, 'days'));
    const showCopyToMakeIt =
        copyToMakeItSecurityLevel === SecurityLevel.Edit &&
        atmProductionOrder?.productionOrderId &&
        isLargeCustomerAccount;

    // If going back to the Plan-It Summary page, use the stored search to repopulate the search bar
    useEffect(() => {
        return () => {
            if (history.location.pathname.includes('plan-it-summary')) {
                dispatch(setUseSummaryStoredSearch(true));
            }
        };
    }, [dispatch, history]);

    useEffect(() => {
        if (!products && shipToAccounts && shipToAccounts.length > 0) {
            const shipToIds = shipToAccounts.map((account) => {
                return account.accountId;
            });
            dispatch(loadMakeItBulkProducts(shipToIds, true, true, false));
        }
    }, [products, shipToAccounts]);

    useEffect(() => {
        if (atmProductionOrder) {
            setActiveDate(getDateProperties(moment(atmProductionOrder.weekStart)));
            if (shipToAccounts) {
                setShippingInformation(
                    shipToAccounts.filter(
                        (account) => account.accountId === atmProductionOrder.shipToId.toString()
                    )
                );
            }
        }
    }, [atmProductionOrder, shipToAccounts]);

    useEffect(() => {
        if (atmProductionOrder && accountId && activeDate && activeDate.fullDate !== '') {
            let startDate = moment(activeDate.fullDate).format('M/D/YYYY');
            let endDate = moment(activeDate.fullDate).format('M/D/YYYY');
            dispatch(getAllocationsWithShipTos(startDate, endDate, [Number(accountId)]));
        }
    }, [dispatch, atmProductionOrder, activeDate, accountId]);

    useEffect(() => {
        if (atmProductionOrder) {
            let orderAsATMBulkOrder = [atmProductionOrder] as unknown as MakeItBulkATMOrder[];
            let groupedOrdersBySnoSku = groupOrdersBySnoSku(orderAsATMBulkOrder, []);
            setOrdersBySnoSku(groupedOrdersBySnoSku);
        }
    }, [atmProductionOrder]);

    useEffect(() => {
        if (id && products) {
            dispatch(loadATMProductionOrder(Number(id)));
            dispatch(loadATMLinkedDeliveryOrders(Number(id)));
        }
    }, [dispatch, id, products]);

    useEffect(() => {
        if (permissions) {
            if (!shipToAccounts?.length) {
                dispatch(getShipToAccounts());
            }
        }
    }, [dispatch, shipToAccounts, permissions, accountId]);

    const setShippingInformation = (accountInformation) => {
        if (accountInformation[0]) {
            setShipToName(accountInformation[0].name);
            setAccountId(accountInformation[0].accountId);
            if (accountInformation[0].address) {
                const shipToAddress = accountInformation[0].address;
                setDisplayAddress(
                    shipToAddress.address1?.trimEnd() +
                        ', ' +
                        shipToAddress.city +
                        ', ' +
                        shipToAddress.stateProvince +
                        ' ' +
                        shipToAddress.postalCode
                );
            }
        }
    };

    const footerActions = (
        <>
            <Grid item>
                <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    data-testid="dashboard-btn"
                    className={classes.actionBtn}
                    onClick={handleDashboardNavigation}
                >
                    <Trans i18nKey="dashboard">Dashboard</Trans>
                </Button>
            </Grid>
        </>
    );

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('planIt', 'Plan It'),
                description: t('forecastDetails', 'Forecast Details'),
                thinBanner: true
            }}
            actionFooter={{
                footerAction: footerActions,
                justify: 'center',
                sticky: true
            }}
            activity={Activity.PlanItOrderDetails}
        >
            <Paper elevation={2} className={classes.paper}>
                <Grid container spacing={2} align-items="flex-start">
                    <Grid container alignItems="center" className={classes.header}>
                        <Grid container item xs={6} justify="flex-start">
                            <Typography variant="h3" className={classes.title}>
                                <Trans i18nKey="forecastNum">Forecast #</Trans>{' '}
                                {atmProductionOrder?.productionOrderId}
                            </Typography>
                        </Grid>
                        <Grid container item xs={6} alignItems="center" justify="flex-end">
                            <Grid item xs={7}>
                                {atmProductionOrder && (
                                    <PlanItOrderDetailsShareLinks
                                        shipToAddress={shipToAddress}
                                        shipToName={shipToName}
                                        atmProductionOrder={atmProductionOrder}
                                    />
                                )}
                            </Grid>
                            <Grid item>
                                {showCopyToMakeIt && (
                                    <Button
                                        type="button"
                                        variant="outlined"
                                        data-testid="copy-to-make-it-button"
                                        onClick={handleCopyToMakeItClick}
                                        disabled={copyToMakeItDisabled}
                                    >
                                        {/* Translation to be added in separate story */}
                                        <Trans i18nKey="copyToMakeIt">Copy To Make It</Trans>
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.subheader}>
                        {atmProductionOrder && (
                            <PlanItOrderDetailsSubheader
                                shipToAddress={shipToAddress}
                                shipToName={shipToName}
                                shipToId={accountId}
                                order={atmProductionOrder}
                            />
                        )}
                    </Grid>
                </Grid>
                {ordersBySnoSku &&
                    ordersBySnoSku[0] &&
                    ordersBySnoSku[0].linesBySnoSku?.map((linesBySnoSkuItem) => {
                        if (linesBySnoSkuItem.lines.length > 0) {
                            return <PlanItOrderDetailsGrid linesBySnoSku={linesBySnoSkuItem} />;
                        }
                        return null;
                    })}
            </Paper>
        </ProcessingPageTemplate>
    );
};

export default PlanItOrderDetails;
