import { esES } from "../translations/locales/es-ES/es-ES";
import { frFR } from "../translations/locales/fr-FR/fr-FR";
import { enUS } from "../translations/locales/en-US/en-US";
import { deDE } from "../translations/locales/de-DE/de-DE";
import { enGB } from "../translations/locales/en-GB/en-GB";
import { ptBR } from "../translations/locales/pt-BR/pt-BR";
import { ruRU } from "../translations/locales/ru-RU/ru-RU";
import { esMX } from "../translations/locales/es-MX/es-MX";
import { Culture } from "../../store/reducers/region-culture";


export default class TranslationService {
    static getTranslatedText = (culture, key) => {
        switch (culture) {
            case Culture.enUS:
                return enUS[key];
            case Culture.esES:
                return esES[key];
            case Culture.frFR:
                return frFR[key];
            case Culture.enGB:
                return enGB[key];
            case Culture.ptBR:
                return ptBR[key];
            case Culture.ruRU:
                return ruRU[key];
            case Culture.deDE:
                return deDE[key];
            case Culture.esMX:
                return esMX[key];
            default:
                return enUS[key];
        }
    };
}
