import React from 'react';
import { Trans } from 'react-i18next';

import ConfirmationModal from './ConfirmationModal';
import { enUS } from '../../../utility/translations/locales';

interface Props {
    saveProgress: () => void;
    onClose: () => void;
    open: boolean;
    navigationLink: string;
    onCancel?: () => void;
    scrapIt?: boolean;
}

const OrderCancelModal = ({ saveProgress, open, navigationLink, onClose, onCancel, scrapIt }: Props) => {
    return (
        <ConfirmationModal
            title={<Trans i18nKey="cancelSubmission">Cancel Your Submission</Trans>}
            open={open}
            logo={false}
            subheader={<Trans i18nKey="allInfoWillBeLost">${enUS.allInfoWillBeLost}</Trans>}
            cancelText={<Trans i18nKey="no">No</Trans>}
            continueText={<Trans i18nKey="yesCancel">Yes, Cancel</Trans>}
            saveProgress={saveProgress}
            navigationLink={navigationLink}
            onClose={onClose}
            onCancel={onCancel}
            canSaveDraft={true}
            scrapIt={!!scrapIt}
        />
    );
};

export default OrderCancelModal;
