import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import KBMaxTemplate from '../../templates/KBMaxTemplate';
import clsx from 'clsx';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { AuthState } from '../../../store/reducers/auth';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { getKBMaxRegion } from '../../../utility/helpers/kbmax-helpers';
import { ProductsConfigurationState } from '../../../store/reducers/product-configurations';
import { addKbMaxListener } from '../../../utility/helpers/event-listener-helpers';
import { useDispatch } from 'react-redux';
import { Region } from '../../../store/reducers/graphic-intake';

declare global {
    interface Window {
        kbmax: any;
    }
}

const useStyles = makeStyles((theme) => ({
    iframeWrapper: {
        height: 800
    },
    hidden: {
        display: 'hidden'
    },
    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '0.5em'
    }
}));

export interface KBMaxConfig {
    elementId: string;
    configurator: string;
    configuredProductId?: number;
    showFields?: boolean;
    showHeader?: boolean;
    showDrawer?: boolean;
    showConfigHeader?: boolean;
    showMove?: boolean;
    bindToForm?: string;
    loadStyle?: string;
    style?: any;
}

interface Props {
    kbMaxSettings?: KBMaxConfig;
}

export default function KBMaxMyProject({ kbMaxSettings }: Props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { userInfo } = useTypedSelector<AuthState>((state) => state.auth);
    const { regionCode, cultureCode } = useTypedSelector<RegionCultureState>(
        (state) => state.regionCulture
    );
    const { configuredProduct } = useTypedSelector<ProductsConfigurationState>(
        (state) => state.productsConfiguration
    );
    const [kbMaxConfig, setKBMaxConfig] = useState<any>(undefined);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [hasKbMaxListener, setHasKbMaxListener] = useState<boolean>(false);

    const [settings, setSettings] = useState<KBMaxConfig>({
        elementId: 'thesource-kbmax-myproject',
        configurator: regionCode === Region.NA ? '37' : '1',
        showConfigHeader: true,
        showHeader: false,
        showDrawer: false,
        showMove: false,
        bindToForm: '',
        loadStyle: 'none',
        style: { height: '800px' }
    });
    const [header] = useState<string>(t('myProject', 'My Project'));

    useEffect(() => {
        if (kbMaxConfig && userInfo && regionCode && cultureCode) {
            let configurationFields = {};
            const prefix = 'Field - (From Source)';
            configurationFields[`${prefix} Locale`] = cultureCode;
            configurationFields[`${prefix} Region`] = getKBMaxRegion(regionCode);
            configurationFields[`${prefix} Name`] = userInfo.name;
            configurationFields[`${prefix} email`] = userInfo.email;
            configurationFields[`${prefix} Set Filtered Selections`] = true;
            configurationFields[`${prefix} Set UI Language`] = true;
            configurationFields[`${prefix} Show Save CTA`] = false;
            configurationFields[`${prefix} Show Get Estimate`] = false;

            kbMaxConfig.setFields(configurationFields);

            if (!hasKbMaxListener) {
                addKbMaxListener(kbMaxConfig, dispatch);
                setHasKbMaxListener(true);
            }

            setTimeout(() => {
                setIsLoaded(true);
            }, 5000);
        }
    }, [kbMaxConfig, userInfo, regionCode, cultureCode]);

    useEffect(() => {
        let config: any;
        if (window.kbmax && configuredProduct) {
            config = new window.kbmax.ConfiguratorEmbed({
                elementId: settings.elementId,
                configuratorId: settings.configurator,
                configuredProductId: configuredProduct.configuredProductId,
                showFields: settings.showFields,
                showHeader: settings.showHeader,
                showConfigHeader: settings.showConfigHeader,
                showMove: settings.showMove,
                showDrawer: settings.showDrawer,
                bindToFormSelector: settings.bindToForm,
                loadStyle: settings.style
            });
            setKBMaxConfig(config);
        }

        return () => {
            // clear config and removing listener on page leave
            config = null;
            window.removeEventListener('message', (e) => { });
        };
    }, []);

    useEffect(() => {
        if (kbMaxSettings) {
            setSettings(kbMaxSettings);
        }
    }, [kbMaxSettings]);

    return (
        <KBMaxTemplate
            banner={{
                header: header,
                description: '',
                kbMaxBanner: true,
                displayDropdown: true
            }}
        >
            {!isLoaded && (
                <Grid
                    container
                    item
                    xs={12}
                    className={clsx(classes.spinningLoader, classes.iframeWrapper)}
                >
                    <CircularProgress />
                </Grid>
            )}
            <div
                className={clsx(classes.iframeWrapper, { [classes.hidden]: !isLoaded })}
                id={settings.elementId}
            />
        </KBMaxTemplate>
    );
}
