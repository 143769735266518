import Axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import {
    Product,
    ProductPortfolioState,
    ProductContent
} from '../../store/reducers/product-portfolio';
import { createAxiosHeader, getAxiosConfig } from '../helpers/axios-helpers';

export interface ProductSearchRequest {
    productCodes?: string[];
    types?: string[];
    styles?: string[];
    endCodes?: string[];
    features?: string[];
    sizes?: string[];
}

export interface ProductSearchResponse {
    products: Product[];
}

export interface LeadTimesRequest {
    accountId: string; // account ID for the product
    basecoat: string; // {BC, NBC, null}
    ink: string; // Special Ink Type, or null
    neckDiameter: string; // End/Neck diameter
    overvarnish: string; // OverVarnish type, or null
    referenceId: string; // user supplied reference, match with productSku
    requestedDate?: string; // the requested date to validate
    retort: string; // {RETORT, null}
    size: string; // Product Size: Either volume in OZ or ML, without the decimal
    styleCode: string; // style code for the product
    type: string; // Type of Product. E.G. Can. Bottle. Etc...
}

interface LeadTimesResponseWrapper {
    leadTimeResponses: LeadTimesResponse[];
}
export interface LeadTimesResponse {
    accountId: string;
    leadTimeWeeks: number;
    nextAvailableDate: string;
    referenceId: string;
    requestedDate: string;
    validateAvailableDate: string;
}

export default class ProductService {
    private static serializeRequestObjQueryString = (obj: any) => {
        let queryParams: Array<any> = [];
        for (let key in obj)
            if (obj.hasOwnProperty(key)) {
                if (obj[key]) {
                    queryParams.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
                }
            }
        return queryParams.join('&');
    };
    static getBanner(state: any, url: string) {
        const axiosConfig: AxiosRequestConfig = getAxiosConfig(state);
        const banner = Axios.get(url, axiosConfig);
        return banner;
    }

    static getProduct(state: any, productCode): Promise<AxiosResponse<Product>> {
        const axiosConfig: AxiosRequestConfig = getAxiosConfig(state);
        const product = Axios.get<Product>(
            `${process.env.REACT_APP_PRODUCT_API}/products/${productCode}`,
            axiosConfig
        );
        return product;
    }

    static createProductRequest(state: ProductPortfolioState) {
        const { view, selectedFacets } = state;
        const viewTypes = view ? [view] : [];
        return { types: viewTypes, ...selectedFacets };
    }

    static getSelectedProducts(state: ProductPortfolioState) {
        const existingResults = state.results as Product[];
        return (
            existingResults
                ?.filter((result) => result.selected)
                .map((result) => result.productCode) || []
        );
    }

    static getMultipleProducts(
        state: any,
        requestObj
    ): Promise<AxiosResponse<ProductSearchResponse>> {
        const axiosConfig: AxiosRequestConfig = getAxiosConfig(state);
        const products = Axios.post<ProductSearchResponse>(
            `${process.env.REACT_APP_PRODUCT_API}/products/`,
            requestObj,
            axiosConfig
        );
        return products;
    }

    static getLeadTimes(
        accessToken: string,
        regionCode: string,
        cultureCode: string,
        requestObject: LeadTimesRequest
    ): Promise<LeadTimesResponse> {
        const urlReqObj = ProductService.serializeRequestObjQueryString(requestObject);
        return Axios.get<LeadTimesResponse>(
            `${process.env.REACT_APP_PRODUCT_API}/leadTimes?${urlReqObj}`,
            createAxiosHeader(cultureCode, regionCode, accessToken)
        ).then((resp) => resp.data);
    }

    static getLeadTimesBatch(
        accessToken: string,
        regionCode: string,
        cultureCode: string,
        requestObject: LeadTimesRequest[]
    ) {
        const axiosConfig = createAxiosHeader(cultureCode, regionCode, accessToken);
        return Axios.post<LeadTimesResponseWrapper>(
            `${process.env.REACT_APP_PRODUCT_API}/leadTimes/searches`,
            { leadTimeRequests: requestObject },
            axiosConfig
        ).then((resp) => resp.data.leadTimeResponses);
    }

    static getProducts(requestObj, header: any): Promise<AxiosResponse<ProductSearchResponse>> {
        const axiosConfig: AxiosRequestConfig = header;
        const products = Axios.post<ProductSearchResponse>(
            `${process.env.REACT_APP_PRODUCT_API}/products/`,
            requestObj,
            axiosConfig
        );
        return products;
    }

    static getProductContent(productResponse: ProductSearchResponse, state: any) {
        const axiosConfig: AxiosRequestConfig = getAxiosConfig(state);
        // Pull productCode values from products array to send to content API
        const productCodes: string[] = [];

        if (productResponse.products) {
            productResponse.products.forEach((product) => {
                const content = ContentCache.GetContent(
                    axiosConfig.headers['Accept-Language'],
                    axiosConfig.headers['Accept-Region'],
                    product.productCode
                );

                if (content) {
                    product.content = content;
                } else {
                    // need to fetch
                    productCodes.push(product.productCode);
                }
            });
        }

        if (!productCodes.length) {
            // cache got everything, return it
            return Promise.resolve(productResponse.products);
        }

        // need to get content
        const requestObj = {
            productCodes: productCodes
        };

        // Call content API to pull data for each productCode
        const productContentResponse = Axios.post(
            `${process.env.REACT_APP_CONTENT_API}/content/products/`,
            requestObj,
            axiosConfig
        );

        // Add content data to each product and store in product.content
        return productContentResponse.then((response) => {
            if (response.data) {
                response.data.forEach((content) => {
                    ContentCache.CacheContent(
                        axiosConfig.headers['Accept-Language'],
                        axiosConfig.headers['Accept-Region'],
                        content.productCode,
                        content
                    );

                    const product = productResponse.products.find(
                        (pr) => pr.productCode === content.productCode
                    );

                    if (product) {
                        product.content = content;
                    }
                });
            }
            // Return only the product information
            return productResponse.products;
        });
    }
}

class ContentCache {
    static _cache: any = {};

    static GetContent(
        cultureCode: string,
        regionCode: string,
        productCode: string
    ): ProductContent {
        return this._cache[this.CreateKey(cultureCode, regionCode, productCode)];
    }

    static CacheContent(
        cultureCode: string,
        regionCode: string,
        productCode: string,
        content: ProductContent
    ) {
        this._cache[this.CreateKey(cultureCode, regionCode, productCode)] = content;
    }

    private static CreateKey(cultureCode: string, regionCode: string, productCode: string) {
        return `${cultureCode}-${regionCode}:${productCode}`;
    }
}
