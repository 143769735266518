import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class MakeItBulkCampaignColumns {
    static getCampaignColumns = (culture: string) => {
        return [
            createColumn('productDescription', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'productSizeShape'),
                sortable: true,
                filterable: true,
                searchable: true
            }),
            createColumn('orderStartDate', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'requestWindowOpen'),
                sortable: true,
                filterable: true,
                searchable: true
            }),
            createColumn('orderEndDate', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'requestWindowClosed'),
                sortable: true,
                filterable: true,
                searchable: true
            }),
            createColumn('leadTimeWeeks', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'leadTime'),
                sortable: true,
                filterable: true,
                searchable: true
            }),
            createColumn('firstAvailableOrderDate', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'firstAvailableDate'),
                sortable: true,
                filterable: true,
                searchable: true
            }),
            createColumn('hasCampaignRuns', {
                dataType: ColumnDataType.Boolean,
                visible: false,
                sortable: false,
                filterable: false,
                searchable: false
            }),
            createColumn('newGraphicApprovalDeadline', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'newGraphicApprovalDeadline'),
                visible: true,
                sortable: true,
                filterable: true,
                searchable: true
            })
        ];
    };
}
