import moment from 'moment';
import OrdersService, {
    GetDraftsRequest,
    Order_Status
} from '../../utility/services/orders-service';
import {
    MAKE_IT_DRAFTS_LOADING,
    MAKE_IT_DRAFTS_LOADED,
    MAKE_IT_DRAFTS_ERROR,
    MAKE_IT_DRAFTS_CHANGE_SELECTION,
    MAKEIT_BULK_ATM_EXISTING_ORDERS,
    MAKEIT_BULK_ATM_DRAFT_ORDER_ERROR,
    MAKEIT_BULK_ATM_DRAFT_ORDER_LOADED
} from '../actions/action-types';
import { Account } from '../reducers/customer-context';
import { ProductionOrder } from '../reducers/makeit-dashboard';
import { DraftInformation } from '../reducers/make-it-drafts';
import { MakeItBulkATMOrder, ForecastStatus } from '../reducers/makeit-bulk-atm';
import { bulkUploadResponseHelper } from './makeit-bulk-atm';

export const loadDraftData = (accounts: Account[]) => {
    return (dispatch, getState) => {
        const state = getState();
        const { accessToken } = state.auth;
        const region = state.regionCulture.regionCode;
        const culture = state.regionCulture.cultureCode;
        const accounts = state.customerContext.accounts;

        dispatch({ type: MAKE_IT_DRAFTS_LOADING });
        OrdersService.getDrafts(
            accessToken,
            {
                shipToIds: accounts.map((account) => account.accountId)
            } as GetDraftsRequest,
            culture,
            region
        )
            .then((response) => {
                const activeAccountsIds = accounts
                    .filter((account) => account.status === 'Active')
                    .map((account) => account.accountId);

                const activeDrafts = response.data.filter((draft) => {
                    const shipToIds = draft.orders.map((order) => order.shipToId);
                    return shipToIds.some(
                        (shipToId) => activeAccountsIds.indexOf(shipToId.toString()) >= 0
                    );
                });
                activeDrafts.forEach((draft) => {
                    draft.orders = draft.orders.filter(
                        (order) => activeAccountsIds.indexOf(order.shipToId.toString()) >= 0
                    );
                });
                dispatch({
                    type: MAKE_IT_DRAFTS_LOADED,
                    drafts: activeDrafts
                        .sort((a, b) =>
                            // Sort newest to oldest
                            moment(b.draft.createDate).diff(moment(a.draft.createDate))
                        )
                        .map((draft) => {
                            return {
                                ...draft,
                                createDate: moment.utc(draft.draft.createDate)
                            };
                        })
                });
            })
            .catch((error) => {
                dispatch({ type: MAKE_IT_DRAFTS_ERROR, error });
            });
    };
};
export const setSelectedDraft = (selectedDraft) => {
    return (dispatch) => {
        dispatch({
            type: MAKE_IT_DRAFTS_CHANGE_SELECTION,
            selectedDraft: selectedDraft
        });
    };
};

export const deleteDraft = (
    orders: ProductionOrder[],
    deleteEntireDraft = false,
    accounts: Account[]
) => {
    return (dispatch, getState) => {
        const state = getState();
        const { accessToken } = state.auth;

        dispatch({ type: MAKE_IT_DRAFTS_LOADING });

        if (deleteEntireDraft) {
            const draftId = state.makeItDrafts.selectedDraft;
            // delete the entire draft
            OrdersService.deleteDraft(accessToken, draftId)
                .then(() => {
                    // reload draft data
                    dispatch(loadDraftData(accounts));

                    // clear the selected draft so other draft groupings are selectable
                    dispatch(setSelectedDraft(''));
                })
                .catch((error) => {
                    dispatch({ type: MAKE_IT_DRAFTS_ERROR, error });
                });
        } else {
            // delete selected production orders
            const apiCalls = orders.map((order) => {
                return OrdersService.deleteProductionOrder(accessToken, order.productionOrderId);
            });

            Promise.all(apiCalls)
                .then(() => {
                    // reload draft data
                    dispatch(loadDraftData(accounts));

                    // clear the selected draft so other draft groupings are selectable
                    dispatch(setSelectedDraft(''));
                })
                .catch((error) => {
                    dispatch({ type: MAKE_IT_DRAFTS_ERROR, error });
                });
        }
    };
};
export const resumeATMDraftOrder = (
    draftInformation: DraftInformation,
    orders: MakeItBulkATMOrder[],
    weeks: moment.Moment[],
    readonly: boolean = false
) => {
    return (dispatch, getState) => {
        const state = getState();
        const products = state.bulkUploadMakeItState.products;

        const sortedCsvAtmOrders: MakeItBulkATMOrder[] = orders.sort((a, b) =>
            moment(a.weekStart).diff(moment(b.weekStart))
        );
        const startWeek = moment(sortedCsvAtmOrders[0].weekStart).format('M/D/YYYY');
        const endWeek = moment(sortedCsvAtmOrders[sortedCsvAtmOrders.length - 1].weekStart).format(
            'M/D/YYYY'
        );

        bulkUploadResponseHelper(orders, products);

        dispatch({
            type: MAKEIT_BULK_ATM_DRAFT_ORDER_LOADED,
            atmOrders: orders,
            lineErrors: [],
            weeks,
            draft: draftInformation,
            readonly: readonly
        });

        const shipToIds = sortedCsvAtmOrders.reduce((prev, curr) => {
            if (!prev.includes(curr.shipToId)) {
                prev.push(curr.shipToId);
            }
            return prev;
        }, [] as Array<number>);

        OrdersService.getExistingOrders(state, startWeek, endWeek, shipToIds, ForecastStatus.ATM)
            .then((resp) => {
                if (resp) {
                    const existingOrders = JSON.parse(JSON.stringify(resp));
                    existingOrders.forEach((order) => {
                        order.weekStart = order.atmWeekStart;
                        order.lines = order.lines.map((line) => {
                            const matchingProduct = products.find(
                                (prod) => prod.productSku === line.productSku!
                            );
                            return {
                                ...line,
                                weekStart: order.atmWeekStart,
                                snoSku: matchingProduct?.snoSku
                            };
                        });
                    });

                    dispatch({
                        type: MAKEIT_BULK_ATM_EXISTING_ORDERS,
                        existingOrders: existingOrders.filter(
                            (order) => order.status !== Order_Status.Draft
                        ),
                        existingDrafts: existingOrders.filter(
                            (order) => order.status === Order_Status.Draft
                        )
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: MAKEIT_BULK_ATM_DRAFT_ORDER_ERROR,
                    error
                });
            });
    };
};
