import {
    PRICING_BREAKDOWN_LOADING,
    PRICING_BREAKDOWN_LOADED,
    PRICING_BREAKDOWN_ERROR,
    PRICING_BREAKDOWN_RESET
} from "../actions/action-types";
import { Account } from "./customer-context";

export interface AccountWithParent extends Account {
    subAccounts?: Account[];
    parentAccount?: Account;
}
export interface PricingLine {
    itemDescription: string;
    currentBasePrice: number;
    newBasePrice: number;
    freightChargeFromLocation?: string;
    freightCharges?: number;
    newBasePriceWithFreight?: number;
    currentBasePriceFormat: string;
    newBasePriceFormat: string;
    freightChargesFormat?: string;
    newBasePriceWithFreightFormat?: string;
    effectiveDate?: string;
    shipToDescription?: string;
    currency?: string;
};
export interface PricingBreakdownState {
    pricingShipTos: AccountWithParent[];
    pricingData: { [shipToId: string]: PricingLine[] };
    loading: boolean;
    loaded: boolean;
    pricingDataLoaded: boolean;
    error?: any;
};

const initialState: PricingBreakdownState = {
    pricingShipTos: [],
    pricingData: {},
    loading: false,
    loaded: false,
    pricingDataLoaded: false
};

const pricingBreakdown = (
    state: PricingBreakdownState = initialState,
    action: { type: string;[x: string]: any }
): PricingBreakdownState => {
    switch (action.type) {
        case PRICING_BREAKDOWN_LOADING:
            return { ...state, loading: true, loaded: false };
        case PRICING_BREAKDOWN_LOADED:
            return { ...state, loading: false, loaded: true, pricingDataLoaded: true, pricingData: action.pricingData, pricingShipTos: action.pricingShipTos };
        case PRICING_BREAKDOWN_ERROR:
            return { ...state, loading: false, pricingDataLoaded: true, error: action.error };
        case PRICING_BREAKDOWN_RESET:
            return { ...initialState }
        default:
            return state;
    }
};

export default pricingBreakdown;