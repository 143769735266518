import {
    Button,
    CircularProgress,
    Collapse,
    Container,
    FormHelperText,
    Grid,
    makeStyles,
    Typography
} from '@material-ui/core';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { ErrorMessage, useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { brightGrey, containerMaxWidth, medium, red } from '../../../themes/globalConstants';
import clsx from 'clsx';
import FormSection from './FormSection';
import TextInput from './TextInput';
import { BulkUploadFieldDefinition } from '../../pages/DeliveryBulkUpload/DeliveryBulkUpload';
import { AuthState } from '../../../store/reducers/auth';
import { useTypedSelector } from '../../../store/reducers/reducer';
import {
    createBulkUploadDeliveryTemplate,
    createMakeItBulkUploadTemplate,
    updateBulkUploadDeliveryTemplate,
    updateMakeItBulkUploadTemplate
} from '../../../store/root-actions';
import { useDispatch } from 'react-redux';
import { modifyFirstLetter } from '../../../utility/helpers/formatters';
import ConfirmationModal from './ConfirmationModal';
import { OrderType, QuantityType } from '../../../utility/services/orders-service';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import RadioInput from './RadioInput';
import { enUS } from '../../../utility/translations/locales/en-US/en-US';
import { BulkUploadDeliveryState } from '../../../store/reducers/bulk-upload';
import { BulkUploadMakeItState } from '../../../store/reducers/makeit-bulk-upload';

interface Props {
    hasTemplate: boolean;
    fieldDefinitions: BulkUploadFieldDefinition;
    templateData?: any;
    isEdit: boolean;
    templateType: OrderType;
    setIsEdit: (boolean) => void;
}

const useStyles = makeStyles((theme) => ({
    section: {
        padding: '1em 0.5em'
    },
    description: {
        fontSize: medium,
        color: brightGrey
    },
    leftPadding: {
        paddingLeft: '0.5em'
    },
    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '0.5em'
    },
    buttonContainer: {
        paddingTop: '1em',
        paddingBottom: '3em',
        maxWidth: containerMaxWidth
    },
    formSectionContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    collapse: {
        width: '100%'
    },
    fieldRow: {
        flex: '1 1 auto',
        padding: '0.5em 0'
    },
    errorText: {
        color: red
    },
    actionBtn: {
        height: '3.375em',
        width: '11.75em',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom'
    },
    radioGroup: {
        marginTop: '1em'
    },
    eachesHelpText: {
        marginBottom: '1.25em',
        color: brightGrey
    }
}));

const BulkUploadMappingTemplate = ({
    hasTemplate,
    fieldDefinitions,
    templateData,
    templateType,
    isEdit,
    setIsEdit
}: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { quantityType } = useTypedSelector<BulkUploadDeliveryState>(
        (state) => state.bulkUploadDelivery
    );
    const { makeItQuantityType } = useTypedSelector<BulkUploadMakeItState>(
        (state) => state.bulkUploadMakeItState
    );
    const { userInfo } = useTypedSelector<AuthState>((state) => state.auth);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const { handleSubmit, errors, control, reset, getValues, setValue, watch } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur'
    });

    const watchQuantity = watch('quantityType', quantityType ?? QuantityType.Pallets);
    const watchMakeItQuantity = watch('quantityType', makeItQuantityType ?? QuantityType.Pallets);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [validationError, setValidationError] = useState<boolean>(false);
    const [open, setIsOpen] = useState<boolean>(false);
    const [isShipItMapping] = useState<boolean>(templateType === OrderType.Delivery);
    const [isMakeItMapping] = useState<boolean>(templateType === OrderType.Production);
    const [header, setHeader] = useState<ReactNode>('');
    const isRequired = <Trans i18nKey="required">Required</Trans>;

    const onSubmit = (formData: any) => {
        setIsSubmitting(true);
        setValidationError(false);
        let data: any[] = [];
        let distinctFields = new Set<string>();
        Object.keys(formData.bulkUploadTemplate).forEach((fieldName) => {
            const value = formData.bulkUploadTemplate[fieldName];
            if (value && value !== '') {
                data.push({ fieldName: fieldName, columnLetter: value.toUpperCase() });
                distinctFields.add(value.toUpperCase());
            }
        });

        if (distinctFields.size === data.length && userInfo) {
            if (isShipItMapping) {
                hasTemplate
                    ? dispatch(
                          updateBulkUploadDeliveryTemplate(
                              userInfo.preferred_username,
                              data,
                              watchQuantity
                          )
                      )
                    : dispatch(
                          createBulkUploadDeliveryTemplate(
                              userInfo.preferred_username,
                              data,
                              watchQuantity
                          )
                      );
            } else if (isMakeItMapping) {
                hasTemplate
                    ? dispatch(
                          updateMakeItBulkUploadTemplate(
                              userInfo.preferred_username,
                              data,
                              watchMakeItQuantity
                          )
                      )
                    : dispatch(
                          createMakeItBulkUploadTemplate(
                              userInfo.preferred_username,
                              data,
                              watchMakeItQuantity
                          )
                      );
            }
            setIsSubmitting(false);
            setIsEdit(false);
        } else {
            setIsSubmitting(false);
            setValidationError(true);
        }
    };

    const clearAllFields = () => {
        const data = getValues();
        Object.keys(data).forEach((field) => {
            setValue(field, '');
        });
    };

    const onClear = () => {
        if (!hasTemplate) {
            clearAllFields();
        } else {
            mapTemplateToForm();
        }
        setValidationError(false);
        reset();
    };

    const onClearAll = () => {
        clearAllFields();
        setValidationError(false);
        setIsEdit(true);
    };

    const onClose = () => {
        setIsOpen(false);
    };

    const onOpenReset = () => {
        setIsOpen(true);
    };

    const onReset = () => {
        setIsEdit(false);
        setIsOpen(false);
        onClear();
    };

    const onEdit = () => {
        setIsEdit(!isEdit);
    };

    const createSectionHeader = useCallback(() => {
        if (isShipItMapping && !hasTemplate) {
            setHeader(<Trans i18nKey="mapShipItTemplateValues">Map Ship It Template Values</Trans>);
        } else if (isShipItMapping && hasTemplate) {
            setHeader(
                <Trans i18nKey="updateShipItTemplateValues">Update Ship It Template Values</Trans>
            );
        } else if (isMakeItMapping && !hasTemplate) {
            setHeader(<Trans i18nKey="mapMakeItTemplateValues">Map Make It Template Values</Trans>);
        } else if (isMakeItMapping && hasTemplate) {
            setHeader(
                <Trans i18nKey="updateMakeItTemplateValues">Update Make It Template Values</Trans>
            );
        }
    }, [hasTemplate, isMakeItMapping, isShipItMapping]);

    const mapTemplateToForm = useCallback(() => {
        Object.keys(templateData).forEach((field) => {
            const fieldName = modifyFirstLetter(templateData[field].fieldName);
            const columnLetter = templateData[field].columnLetter;
            const currentDataField = `bulkUploadTemplate[${fieldName}]`;
            setValue(currentDataField, columnLetter);
            setValue('quantityType', isShipItMapping ? quantityType : makeItQuantityType);
        });
    }, [isShipItMapping, makeItQuantityType, quantityType, setValue, templateData]);

    useEffect(() => {
        if (templateData && templateData.length > 0) {
            mapTemplateToForm();
            setIsSubmitting(false);
            setIsEdit(false);
        }
    }, [templateData, mapTemplateToForm, setIsEdit]);

    useEffect(() => {
        if (!hasTemplate) {
            setIsEdit(true);
        }
        createSectionHeader();
    }, [hasTemplate, cultureCode, createSectionHeader, setIsEdit]);

    useEffect(() => {
        if (quantityType && isShipItMapping) {
            setValue('quantityType', quantityType);
        } else if (makeItQuantityType && !isShipItMapping) {
            setValue('quantityType', makeItQuantityType);
        } else {
            setValue('quantityType', QuantityType.Pallets);
        }
    }, [quantityType, makeItQuantityType, isShipItMapping, setValue]);

    return (
        <FormSection
            testId={'bulk-upload-template-section'}
            sectionHeader={header}
            collapsible={true}
            showSectionOnLoad={!hasTemplate}
        >
            <Collapse in className={classes.collapse}>
                <Grid container item xs={12}>
                    {isShipItMapping ? (
                        <Typography className={classes.description}>
                            <Trans i18nKey="mapTemplateFieldsDescription">
                                Map required values to their corresponding column in your template.
                                All required values must be mapped, optional fields can be left
                                blank if you are not using them.
                            </Trans>
                        </Typography>
                    ) : (
                        <Typography className={clsx(classes.description, classes.leftPadding)}>
                            <Trans i18nKey="makeitMapTemplateFieldsDescription">
                                Map required values to their corresponding column in your template.
                                All values below are required and should be mapped.
                            </Trans>
                        </Typography>
                    )}
                </Grid>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <Grid container data-testid="bulk-upload-template">
                        <Container className={classes.section}>
                            <Grid container item xs={12}>
                                <Grid container item className={classes.formSectionContainer}>
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        spacing={2}
                                        justify="flex-start"
                                        className={clsx(classes.radioGroup, classes.fieldRow)}
                                    >
                                        <Grid item xs={12}>
                                            <RadioInput
                                                label={
                                                    <Trans i18nKey="atmQuantityType">
                                                        ${enUS.atmQuantityType}
                                                    </Trans>
                                                }
                                                name="quantityType"
                                                testId="quantityType"
                                                control={control}
                                                stacked={false}
                                                rules={{ required: isRequired }}
                                                disabled={!isEdit}
                                                radioControls={[
                                                    {
                                                        value: QuantityType.Pallets,
                                                        label: (
                                                            <Trans i18nKey="pallets">Pallets</Trans>
                                                        )
                                                    },
                                                    {
                                                        value: QuantityType.Eaches,
                                                        label: (
                                                            <Trans i18nKey="eaches">Eaches</Trans>
                                                        )
                                                    }
                                                ]}
                                                error={errors.quantityType}
                                                errors={errors}
                                                defaultValue={
                                                    isShipItMapping
                                                        ? quantityType || QuantityType.Pallets
                                                        : makeItQuantityType || QuantityType.Pallets
                                                }
                                            />
                                            {isShipItMapping
                                                ? watchQuantity === QuantityType.Eaches
                                                : watchMakeItQuantity === QuantityType.Eaches && (
                                                      <FormHelperText
                                                          className={classes.eachesHelpText}
                                                      >
                                                          <Trans i18nKey="quantityRounded">
                                                              {enUS.quantityRounded}
                                                          </Trans>
                                                      </FormHelperText>
                                                  )}
                                        </Grid>
                                    </Grid>
                                    {Object.keys(fieldDefinitions).map((fieldKey, index) => {
                                        const field = fieldDefinitions[fieldKey];
                                        const key = fieldKey + '_' + index;
                                        const name = `bulkUploadTemplate[${fieldKey}]`;
                                        if (field.required && !field.hide) {
                                            return (
                                                <Grid
                                                    container
                                                    item
                                                    xs={12}
                                                    key={key}
                                                    spacing={2}
                                                    justify="flex-start"
                                                    alignItems="center"
                                                    className={classes.fieldRow}
                                                >
                                                    <Grid item xs={3}>
                                                        <Typography>
                                                            <Trans
                                                                i18nKey={
                                                                    field.translationValue
                                                                        ? field.translationValue
                                                                        : fieldKey
                                                                }
                                                            >
                                                                {field.defaultLabel}
                                                            </Trans>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={1} />
                                                    <Grid item xs={3}>
                                                        <TextInput
                                                            type="text"
                                                            label={
                                                                <Trans i18nKey="columnLetter">
                                                                    Column Letter
                                                                </Trans>
                                                            }
                                                            name={name}
                                                            testId={fieldKey}
                                                            control={control}
                                                            defaultValue={field.defaultValue}
                                                            rules={{
                                                                required: isRequired,
                                                                pattern: /^[a-zA-Z]{1,2}$/
                                                            }}
                                                            error={
                                                                errors?.bulkUploadTemplate &&
                                                                errors.bulkUploadTemplate[fieldKey]
                                                            }
                                                            errors={errors}
                                                            readOnly={!isEdit}
                                                            data-testid={fieldKey}
                                                        />
                                                        {errors?.bulkUploadTemplate &&
                                                            errors.bulkUploadTemplate[fieldKey] && (
                                                                <FormHelperText
                                                                    className={classes.errorText}
                                                                >
                                                                    {errors.bulkUploadTemplate[
                                                                        fieldKey
                                                                    ] &&
                                                                        errors.bulkUploadTemplate[
                                                                            fieldKey
                                                                        ].type === 'required' && (
                                                                            <ErrorMessage
                                                                                errors={errors}
                                                                                name={name}
                                                                                message={isRequired}
                                                                            />
                                                                        )}
                                                                    {errors.bulkUploadTemplate[
                                                                        fieldKey
                                                                    ] &&
                                                                        errors.bulkUploadTemplate[
                                                                            fieldKey
                                                                        ].type === 'pattern' && (
                                                                            <ErrorMessage
                                                                                errors={errors}
                                                                                name={name}
                                                                                message={
                                                                                    <Trans i18nKey="columnPatternError">
                                                                                        Please enter
                                                                                        1-2 letters
                                                                                    </Trans>
                                                                                }
                                                                            />
                                                                        )}
                                                                </FormHelperText>
                                                            )}
                                                    </Grid>
                                                </Grid>
                                            );
                                        } else if (!field.hide) {
                                            return (
                                                <Grid
                                                    container
                                                    item
                                                    xs={12}
                                                    key={key}
                                                    spacing={2}
                                                    justify="flex-start"
                                                    alignItems="center"
                                                    className={classes.fieldRow}
                                                >
                                                    <Grid item xs={3}>
                                                        <Typography>
                                                            <Trans
                                                                i18nKey={
                                                                    field.translationValue
                                                                        ? field.translationValue
                                                                        : fieldKey
                                                                }
                                                            >
                                                                {field.defaultLabel}
                                                            </Trans>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={1} />
                                                    <Grid item xs={4}>
                                                        <TextInput
                                                            type="text"
                                                            label={
                                                                <Trans i18nKey="columnLetterOptional">
                                                                    Column Letter (Optional)
                                                                </Trans>
                                                            }
                                                            name={name}
                                                            testId={fieldKey}
                                                            control={control}
                                                            defaultValue={field.defaultValue}
                                                            rules={{ pattern: /^[a-zA-Z]{1,2}$/ }}
                                                            error={
                                                                errors?.bulkUploadTemplate &&
                                                                errors.bulkUploadTemplate[fieldKey]
                                                            }
                                                            errors={errors}
                                                            readOnly={!isEdit}
                                                            data-testid={fieldKey}
                                                        />
                                                        {errors?.bulkUploadTemplate &&
                                                            errors.bulkUploadTemplate[fieldKey] && (
                                                                <FormHelperText
                                                                    className={classes.errorText}
                                                                >
                                                                    {errors.bulkUploadTemplate[
                                                                        fieldKey
                                                                    ] &&
                                                                        errors.bulkUploadTemplate[
                                                                            fieldKey
                                                                        ].type === 'pattern' && (
                                                                            <ErrorMessage
                                                                                errors={errors}
                                                                                name={name}
                                                                                message={
                                                                                    <Trans i18nKey="columnPatternError">
                                                                                        Please enter
                                                                                        1-2 letters
                                                                                    </Trans>
                                                                                }
                                                                            />
                                                                        )}
                                                                </FormHelperText>
                                                            )}
                                                    </Grid>
                                                </Grid>
                                            );
                                        }
                                        return null;
                                    })}
                                </Grid>
                            </Grid>
                        </Container>
                        <Container>
                            {isSubmitting && (
                                <Grid container item xs={12} className={classes.spinningLoader}>
                                    <CircularProgress />
                                </Grid>
                            )}
                            {validationError && (
                                <Grid container item xs={12}>
                                    <Typography className={classes.errorText}>
                                        <Trans i18nKey="multipleColumnValidationError">
                                            Column Letters cannot be duplicated. Please review your
                                            entries and resubmit.
                                        </Trans>
                                    </Typography>
                                </Grid>
                            )}
                        </Container>
                        <Container className={classes.buttonContainer}>
                            <Grid container item xs={12} alignItems="center" justify="flex-end">
                                {hasTemplate && isEdit && (
                                    <Grid item xs={3}>
                                        <Button
                                            type="button"
                                            variant="outlined"
                                            color="secondary"
                                            data-testid="reset-button"
                                            onClick={onOpenReset}
                                            className={classes.actionBtn}
                                        >
                                            <Trans i18nKey="reset">Reset</Trans>
                                        </Button>
                                    </Grid>
                                )}
                                {!hasTemplate && isEdit && isMakeItMapping && (
                                    <Grid item xs={3}>
                                        <Button
                                            type="button"
                                            variant="outlined"
                                            color="secondary"
                                            data-testid="clear-button"
                                            onClick={onClear}
                                            className={classes.actionBtn}
                                        >
                                            <Trans i18nKey="clear">Clear</Trans>
                                        </Button>
                                    </Grid>
                                )}
                                {!hasTemplate && isEdit && isShipItMapping && (
                                    <Grid item xs={3}>
                                        <Button
                                            type="button"
                                            variant="outlined"
                                            color="secondary"
                                            data-testid="clear-button"
                                            onClick={onClear}
                                            className={classes.actionBtn}
                                        >
                                            <Trans i18nKey="cancel">Cancel</Trans>
                                        </Button>
                                    </Grid>
                                )}
                                {isEdit && (
                                    <Grid item xs={3}>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            data-testid="save-mapping-button"
                                            disabled={isSubmitting}
                                            className={classes.actionBtn}
                                        >
                                            <Trans i18nKey="saveMapping">Save Mapping</Trans>
                                        </Button>
                                    </Grid>
                                )}
                                {hasTemplate && !isEdit && (
                                    <>
                                        <Grid item xs={3}>
                                            <Button
                                                type="button"
                                                variant="outlined"
                                                color="secondary"
                                                data-testid="clear-all-button"
                                                onClick={onClearAll}
                                                className={classes.actionBtn}
                                            >
                                                <Trans i18nKey="clearAll">Clear All</Trans>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Button
                                                type="submit"
                                                color="primary"
                                                variant="contained"
                                                data-testid="edit-mapping-button"
                                                onClick={onEdit}
                                                className={classes.actionBtn}
                                            >
                                                <Trans i18nKey="editMapping">Edit Mapping</Trans>
                                            </Button>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Container>
                    </Grid>
                </form>
            </Collapse>
            <ConfirmationModal
                saveProgress={onReset}
                onClose={onClose}
                logo={false}
                title={<Trans i18nKey="resetMapping">Reset Your Mapping</Trans>}
                subheader={
                    <Trans i18nKey="makeItIntakeResetConfirmation">
                        Are you sure you want to reset? Any changes will be lost.
                    </Trans>
                }
                continueText={<Trans i18nKey="yesReset">Yes, Reset</Trans>}
                cancelText={<Trans i18nKey="no">No</Trans>}
                open={open}
                navigationLink=""
                onCancel={onClose}
            />
        </FormSection>
    );
};

export default BulkUploadMappingTemplate;
