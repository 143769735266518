import { QuantityType } from '../../utility/services/orders-service';
import {
    TRADE_IT_BALANCES_LOADED,
    TRADE_IT_BALANCES_LOADING,
    TRADE_IT_BALANCES_LOADING_ERROR,
    TRADE_IT_BALANCES_RESET,
    TRADE_IT_EMAILS_LOADING,
    TRADE_IT_EMAILS_LOADING_ERROR,
    TRADE_IT_EMAILS_RESET,
    TRADE_IT_REQUEST_SUBMITTED,
    TRADE_IT_TO_EMAILS_RESET,
    TRADE_IT_UPDATE_QUANTITY_TO_TRADE
} from '../actions/action-types';
import { MakeItBulkLineItem } from './makeit-bulk-upload';
import { TRADE_IT_EMAILS_LOADED, TRAADE_IT_FROM_EMAILS_RESET } from './../actions/action-types';

export interface TradeItRequestLine {
    productSku: string;
    quantity: number;
    quantityUnits: QuantityType;
    accountFromOriginalBalance: number;
    accountToOriginalBalance: number;
}
export interface TradeItRequest {
    tradeRequestId?: number;
    accountFrom: string;
    accountTo: string;
    weekOf?: string;
    fillerContactFrom: string;
    fillerContactTo: string;
    requestLines: TradeItRequestLine[];
    requestedBy?: string;
    submittedDate?: string;
}
export interface TradeItProduct extends MakeItBulkLineItem {
    originalShipToProductionBalance?: number;
    receivingShipToProductionBalance?: number;
    quantityToTrade?: number;
}
export interface TradeItRequestState {
    products: TradeItProduct[];
    loading: boolean;
    loaded: boolean;
    error?: any;
    submittedRequest?: TradeItRequest;
    submitted: boolean;
    emailsLoading: boolean;
    emailsLoaded: boolean;
    fromEmails: string[];
    toEmails: string[];
    emailError?: any;
}

const initialEmailState: any = {
    emailsLoaded: false,
    emailsLoading: false,
    fromEmails: [],
    toEmails: [],
    emailError: undefined
};

const initialBalancesState: any = {
    products: [],
    loading: false,
    loaded: false,
    error: undefined,
    submitted: false
};

const initialState: TradeItRequestState = {
    submittedRequest: undefined,
    ...initialBalancesState,
    ...initialEmailState
};

const tradeItRequest = (
    state: TradeItRequestState = initialState,
    action: { type: string; [x: string]: any }
): TradeItRequestState => {
    switch (action.type) {
        case TRADE_IT_BALANCES_LOADING:
            return { ...state, loading: true, loaded: false };
        case TRADE_IT_BALANCES_LOADED:
            return { ...state, loading: false, loaded: true, products: action.products };
        case TRADE_IT_BALANCES_LOADING_ERROR:
            return { ...state, loading: false, error: action.error };
        case TRADE_IT_BALANCES_RESET:
            return {
                ...state,
                ...initialBalancesState
            };
        case TRADE_IT_UPDATE_QUANTITY_TO_TRADE:
            return { ...state, products: action.products };
        case TRADE_IT_REQUEST_SUBMITTED:
            return { ...state, submittedRequest: action.submittedRequest, submitted: true };
        case TRADE_IT_EMAILS_LOADING:
            return { ...state, emailsLoaded: false, emailsLoading: true, emailError: undefined };
        case TRADE_IT_EMAILS_LOADING_ERROR:
            return { ...state, emailsLoading: false, emailsLoaded: false };
        case TRADE_IT_EMAILS_LOADED:
            return {
                ...state,
                toEmails: action.toEmails,
                fromEmails: action.fromEmails,
                emailError: undefined,
                emailsLoading: false,
                emailsLoaded: true
            };
        case TRADE_IT_EMAILS_RESET:
            return { ...state, ...initialEmailState };
        case TRADE_IT_TO_EMAILS_RESET:
            return { ...state, toEmails: [] };
        case TRAADE_IT_FROM_EMAILS_RESET:
            return { ...state, fromEmails: [] };
        default:
            return state;
    }
};

export default tradeItRequest;
