import React, { ReactElement, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { Activity } from '../../../utility/auth/useSecurity';
import ManageCommunicationsTable from './components/ManageCommunicationsTable';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { CommunicationManagementState } from '../../../store/reducers/communication-management';
import { loadCommunicationSummaryData } from '../../../store/actions/communications';
import ToasterAlert, { SeverityState } from '../../reusable/molecules/ToasterAlert';

const useStyles = makeStyles((theme) => ({
    spacer: {
        height: '1.5em'
    },
    actionBtn: {
        height: '3.375em',
        width: '13.75em',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom'
    },
    main: {
        padding: '0',
        marginTop: '1.750em',
        marginBottom: '1.750em'
    },
    actionsWrapper: {
        alignItems: 'center',
        marginBottom: '1.750em'
    }
}));

export default function ManageCommunicationsPage(): ReactElement {
    const { loaded, loading, error, success } = useTypedSelector<CommunicationManagementState>(
        (state) => state.communicationManagement
    );

    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const [toastMessage, setToastMessage] = useState<string>('');
    const [toastCount, setToastCount] = useState<number>(0);
    const [toastMessageSeverity, setToastMessageSeverity] = useState<SeverityState>('info');

    const onAddMessageClick = () => {
        history.push('/configure-communication');
    };

    useEffect(() => {
        dispatch(loadCommunicationSummaryData());
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!loaded && !loading) {
            dispatch(loadCommunicationSummaryData());
        }
    }, [dispatch, loaded, loading]);

    useEffect(() => {
        const showToastMessage = (message: string, severity: SeverityState) => {
            setToastMessage(message);
            setToastMessageSeverity(severity);
            setToastCount((prev) => prev + 1);
        };
        if (loaded && success) {
            showToastMessage(
                t('sucessfulMessageUpdate', 'Your message has been updated'),
                'success'
            );
        }
    }, [t, loaded, success]);

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('manageCommunication', 'Manage Communication'),
                description: '',
                thinBanner: true
            }}
            activity={Activity.CommunicationManagement}
            loading={loading}
            errorMessage={error ? 'An error has occurred. Please try again.' : ''}
        >
            <Grid
                container
                className={classes.main}
                justify="space-between"
                data-testid="product-plan"
            >
                <Grid container item md={12} className={classes.actionsWrapper}>
                    <Grid container item xs={12} justify="flex-start">
                        <Button
                            type="button"
                            color="primary"
                            variant="outlined"
                            data-testid="add-messagen-button"
                            className={classes.actionBtn}
                            onClick={onAddMessageClick}
                        >
                            <Trans i18nKey="addMessage">Add Message</Trans>
                        </Button>
                    </Grid>
                </Grid>
                <Grid container>
                    <ManageCommunicationsTable />
                </Grid>
            </Grid>

            {/* Toast Popup */}
            <ToasterAlert
                key={toastCount}
                adjustedPlacement="center"
                showAlert={!!toastMessage}
                message={toastMessage}
                severity={toastMessageSeverity}
            />
        </ProcessingPageTemplate>
    );
}
