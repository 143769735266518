import React, { useState, useEffect, useCallback } from 'react';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { Trans, useTranslation } from 'react-i18next';
import { Activity } from '../../../utility/auth/useSecurity';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import Button from '../../reusable/atoms/Button';
import { useHistory } from 'react-router-dom';
import { blackWeight } from '../../../themes/globalConstants';
import { selectIsImpersonation, selectIsLargeCustomerAccount } from '../../../store/selectors';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { ScrapItState } from '../../../store/reducers/scrap-it';
import ScrapItGrid from '../ScrapIt/components/ScrapItGrid';
import { useDispatch } from 'react-redux';
import { getProductCellQuantities } from '../../../utility/helpers/shipment-helpers';
import { updateProductToBeShipped } from '../../../store/actions/shipping-dashboard';
import { SCRAP_IT_UPDATE_VIEW } from '../../../store/actions/action-types';
import { ProductToShip } from '../../../store/reducers/shipping-dashboard';
import OrderCancelModal from '../../reusable/molecules/OrderCancelModal';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { resetScrapItProductState, submitScrapIt } from '../../../store/root-actions';
import ImpersonationWarning from '../../reusable/molecules/ImpersonationWarning';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '4.5em'
    },
    viewAtmTitle: {
        fontWeight: blackWeight,
        color: theme.palette.secondary.main,
        textTransform: 'capitalize'
    },
    actionBtn: {
        height: '3.375em',
        width: '13.75em',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom'
    },
    noProductsText: {
        fontWeight: blackWeight,
        textAlign: 'center',
        marginTop: '2em'
    }
}));

export default function ScrapItReview() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const isLargeCustomerAccount = useTypedSelector<boolean>(selectIsLargeCustomerAccount);
    const isImpersonation = useTypedSelector<boolean>(selectIsImpersonation);
    const [showImpersonationWarning, setShowImpersonationWarning] = useState<boolean>(false);
    const { productsToBeScrapped, quantityUnit, submitScrapItState, view } =
        useTypedSelector<ScrapItState>((state) => state.scrapItState);
    const [open, setOpen] = useState<boolean>(false);
    const { selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const [error, setError] = useState<boolean>(false);
    const [currentViewTypes, setCurrentViewTypes] = useState([''] as string[]);
    const [filteredViewProducts, setFilteredViewProducts] = useState<ProductToShip[]>();

    const handleInputBlur = (product: any, inputQuantity: number) => {
        let updatedProductsToBeScrapped = [...productsToBeScrapped];

        productsToBeScrapped?.forEach((item, index) => {
            if (item.productSku === product.productSku) {
                const [inputPallets, inputEaches] = getProductCellQuantities(
                    inputQuantity,
                    product.quantityPerPallet!,
                    quantityUnit
                );
                updatedProductsToBeScrapped.splice(index, 1, {
                    ...product,
                    inputPallets: inputPallets,
                    inputEaches: inputEaches,
                    orderedPallets: item.orderedPallets
                });
            }
        });

        dispatch(updateProductToBeShipped(updatedProductsToBeScrapped, true));
    };

    const handleDeleteItem = (product: any) => {
        let updatedProductsToBeScrapped = [...productsToBeScrapped];

        const index = productsToBeScrapped?.findIndex((p) => p.productSku === product.productSku);
        updatedProductsToBeScrapped.splice(index, 1);

        dispatch(updateProductToBeShipped(updatedProductsToBeScrapped, true));
    };

    const onCloseConfirmation = () => {
        setOpen(false);
    };

    const onConfirmedCancel = () => {
        handleCancelNavigation();
        dispatch(resetScrapItProductState());
        setOpen(false);
    };

    const handleCancelNavigation = () => {
        history.push('/dashboard');
    };

    useEffect(() => {
        if (submitScrapItState === 'success') {
            history.push('/scrap-it-confirmation');
        }
    }, [submitScrapItState, history]);

    useEffect(() => {
        // Using stringify allows us to compare the length and contents of the arrays
        if (JSON.stringify(currentViewTypes) !== JSON.stringify(view)) {
            dispatch({ type: SCRAP_IT_UPDATE_VIEW, view: currentViewTypes });
        }
    }, [currentViewTypes]);

    const handleViewFilterClick = useCallback(
        (viewType: string[]) => {
            setCurrentViewTypes(viewType);
            if (productsToBeScrapped) {
                if (viewType[0] === '') {
                    setFilteredViewProducts(productsToBeScrapped);
                } else {
                    const updatedProducts = productsToBeScrapped.filter((product) =>
                        viewType.includes(product.type!)
                    );
                    setFilteredViewProducts(updatedProducts);
                }
            }
        },
        [productsToBeScrapped]
    );

    useEffect(() => {
        handleViewFilterClick(currentViewTypes);
    }, [productsToBeScrapped]);

    const handleSubmit = () => {
        if (!isImpersonation) {
            if (!!productsToBeScrapped && !!selectedAccountId) {
                let error = false;
                productsToBeScrapped.forEach((product) => {
                    if (!!product.inputPallets) {
                        if (product.inputPallets === 0) {
                            error = true;
                        }
                    } else {
                        error = true;
                    }
                });
                if (error) {
                    setError(true);
                } else {
                    dispatch(submitScrapIt(selectedAccountId));
                }
            } else {
                setError(true);
            }
        } else {
            setShowImpersonationWarning(true);
        }
    };

    const onCancel = () => {
        setOpen(true);
    };

    const footerActions = (
        <Grid container justify="space-between" alignItems="center">
            <Grid container item xs={1}>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="cancel-btn"
                    className={classes.actionBtn}
                    onClick={onCancel}
                >
                    <Trans i18nKey="cancel">Cancel</Trans>
                </Button>
            </Grid>
            <Grid container item xs={7}>
                <ImpersonationWarning
                    showWarning={showImpersonationWarning}
                    warningType={'SUBMIT'}
                    noMarginTop={true}
                />
            </Grid>
            <Grid container item xs={3} justify="flex-end">
                <Grid container item xs={7}>
                    <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        data-testid="back-btn"
                        onClick={() => history.push('/scrap-it?from=review')}
                        className={classes.actionBtn}
                    >
                        <Trans i18nKey="back">Back</Trans>
                    </Button>
                </Grid>
                <Grid container item xs={5} justify="flex-end">
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        data-testid="submit-btn"
                        className={classes.actionBtn}
                        onClick={() => handleSubmit()}
                        disabled={false}
                    >
                        <Trans i18nKey="submit">Submit</Trans>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('scrapIt', 'Scrap It').toLocaleUpperCase(),
                description: t('reviewRequest', 'Review Request').toLocaleUpperCase(),
                thinBanner: true,
                displayDropdown: true,
                disableSelect: true,
                shouldShowATMBanner: isLargeCustomerAccount
            }}
            actionFooter={{
                footerAction: footerActions,
                justify: 'space-between',
                sticky: true
            }}
            shipTos={true}
            activity={Activity.ScrapIt}
        >
            <Grid container xs={12} className={classes.root}>
                <Typography
                    variant="h3"
                    className={classes.viewAtmTitle}
                    data-testid="current-selected-date"
                >
                    <Trans i18nKey={'reviewScrapItRequest'}>Review Scrap It Request</Trans>
                </Typography>
            </Grid>
            <ScrapItGrid
                scrapItItems={filteredViewProducts ?? []}
                onAddItem={() => {}}
                onDeleteItem={handleDeleteItem}
                onPalletAdd={() => {}}
                onViewFiltersClick={handleViewFilterClick}
                onInputBlur={handleInputBlur}
                isReviewGrid={true}
                className={classes.root}
                columnWidths={[20, 15, 15, 15, 15, 15]}
                error={error}
            />
            {!productsToBeScrapped.length && (
                <Typography className={classes.noProductsText}>
                    <Trans i18nKey={'allProductsRemoved'}>
                        All products have been removed. Please add products to proceed.
                    </Trans>
                </Typography>
            )}
            <OrderCancelModal
                open={open}
                saveProgress={onConfirmedCancel}
                navigationLink={'/dashboard'}
                onClose={onCloseConfirmation}
                onCancel={onCloseConfirmation}
                scrapIt={true}
            />
        </ProcessingPageTemplate>
    );
}
