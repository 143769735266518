import React, { useEffect, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import {
    statusLtGreen,
    statusGreen,
    ballLtGray_3,
    ballGray
} from '../../../../themes/globalConstants';
import { useTranslation } from 'react-i18next';
import { OrderProductTableRow } from '../../../../store/reducers/orders-dashboard';
import clsx from 'clsx';

interface Props {
    line: OrderProductTableRow;
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'inline-block',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0.5em 1.5em',
        borderRadius: '25px',
        textTransform: 'capitalize',
        marginTop: '0.5em',
        '&:first-child': {
            marginTop: '0'
        }
    },
    active: {
        backgroundColor: statusLtGreen,
        color: statusGreen
    },
    inactive: {
        backgroundColor: ballLtGray_3,
        color: ballGray
    }
}));
const StatusLabel = ({ line }: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [displayStatus, setDisplayStatus] = useState<string>('');

    useEffect(() => {
        if (!!line.status) {
            setDisplayStatus(t(line.status!.toLowerCase(), line.status!.toLowerCase()));
        }
    }, [line]);

    return (
        <>
            <Typography
                className={clsx(
                    classes.root,
                    { [classes.active]: line.status === 'ACTIVE' },
                    { [classes.inactive]: line.status === 'INACTIVE' }
                )}
            >
                {displayStatus}
            </Typography>
        </>
    );
};

export default StatusLabel;
