import React from 'react';
import { NavigationalPageTemplate } from '../../templates/NavigationalPageTemplate';
import { Typography, Grid } from '@material-ui/core';

export default function NotFoundPage() {
    const banner = {
        header: 'Not found',
        description: 'The page you are trying to reach was not found',
        imageUrl: ''
    };
    return (
        <NavigationalPageTemplate banner={banner}>
            <Grid container justify="center">
                <Grid item data-testid="page-not-found">
                    <Typography variant="h2">Page Not Found</Typography>
                </Grid>
            </Grid>
        </NavigationalPageTemplate>
    );
}
