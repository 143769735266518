import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class MakeItConversionConfirmationOrderSectionColumns {
    static getColumns = (culture: string) => {
        return [
            createColumn('displayId', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('graphicIdAndVersion', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productGraphicId', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'productGraphicId')
            }),
            createColumn('displayName', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'productDescription')
            }),
            createColumn('coating', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'coating')
            }),
            createColumn('palletQuantity', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'quantityToPurchase')
            }),
            createColumn('fee', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'fee')
            }),
            createColumn('moqFees', {
                visible: false
            }),
            createColumn('quantityPerPallet', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('deleted', {
                dataType: ColumnDataType.Boolean,
                visible: false
            })
        ];
    };
}
