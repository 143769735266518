import { Grid, Link, makeStyles, TableCell, TableRow } from '@material-ui/core';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import {
    ballBlue,
    blackWeight,
    boldWeight,
    medium,
    orange,
    regularWeight
} from '../../../../themes/globalConstants';
import { formatDate } from '../../../../utility/helpers/date-helpers';
import {
    getBeverageCategory,
    getLiquidIntakeStatus
} from '../../../../utility/helpers/liquid-intake-helpers';
import LiquidTestIntakeFormPDF from '../../../reusable/molecules/LiquidTestIntakeFormPDF';
import Modal from '../../../reusable/molecules/Modal';
import { LiquidIntakeForTable } from '../models/LiquidIntakeForTable';

interface Props {
    liquidTest: LiquidIntakeForTable;
}

const useStyles = makeStyles((theme) => ({
    action: {
        cursor: 'pointer'
    },
    divider: {
        margin: '0 0.5em'
    },
    link: {
        fontSize: medium,
        fontWeight: regularWeight,
        marginLeft: '1em',
        width: '8.75em',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    name: {
        fontWeight: boldWeight,
        fontSize: medium,
        letterSpacing: 0.17
    },
    id: {
        fontWeight: regularWeight,
        fontSize: medium,
        letterSpacing: 0.17
    },
    trashIcon: {
        fill: orange
    },
    normalWeight: {
        fontWeight: regularWeight
    },
    printButton: {
        fontSize: '1em',
        marginLeft: '2.5em',
        '&:hover': {
            backgroundColor: 'transparent',
            color: ballBlue,
            fontWeight: blackWeight,
            outline: 'none'
        },
        '&:focus': {
            backgroundColor: 'transparent',
            color: ballBlue,
            fontWeight: blackWeight
        }
    }
}));

const ClosedLiquidTestRow = ({ liquidTest }: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [printModalOpen, setPrintModalOpen] = useState<boolean>(false);

    const onPrint = () => {
        setPrintModalOpen(true);
    };
    const onClosePrintModal = () => {
        setPrintModalOpen(false);
    };

    const getLiquidTestDocuments = (liquidTest?: any) => {
        return (
            <>
                {t('printIntakeForm', 'Intake Form')}
                <Link
                    className={classes.link}
                    underline="always"
                    data-testid="print-button"
                    onClick={() => onPrint()}
                >
                    <Trans i18nKey="viewPrint">View / Print</Trans>
                </Link>
            </>
        );
    };

    const getClosedDate = (closedDate?: string) => {
        if (closedDate) {
            return formatDate(closedDate);
        } else {
            return '';
        }
    };

    return (
        <>
            <TableRow hover={true} key={liquidTest.liquidTestId}>
                <TableCell>
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            className={classes.normalWeight}
                            data-testid="beverage-name"
                        >
                            {liquidTest.projectNameBeverageName}
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell className={classes.normalWeight} data-testid="graphic-id">
                    {liquidTest.graphicID}
                </TableCell>
                <TableCell className={classes.normalWeight} data-testid="documents">
                    {getLiquidTestDocuments(liquidTest)}
                </TableCell>
                <TableCell className={classes.normalWeight} data-testid="beverage-category">
                    {getBeverageCategory(liquidTest.beverageCategory, cultureCode)}
                </TableCell>
                <TableCell className={classes.normalWeight} data-testid="closed-date">
                    {getClosedDate(liquidTest.statusUpdatedDateTime)}
                </TableCell>

                <TableCell className={classes.normalWeight} data-testid="submitting-user">
                    {liquidTest.submitterEmail}
                </TableCell>
                <TableCell className={classes.normalWeight} data-testid="status">
                    {getLiquidIntakeStatus(cultureCode, liquidTest.status)}
                </TableCell>
            </TableRow>
            <Modal
                open={printModalOpen}
                title={<Trans i18nKey="printLiquidTestIntake">Print Liquid Test Intake Form</Trans>}
                close={onClosePrintModal}
                closeIcon={true}
                fullScreen={true}
            >
                {liquidTest && (
                    <LiquidTestIntakeFormPDF
                        liquidIntake={liquidTest}
                        testRequired={
                            liquidTest.testRequired !== undefined ? liquidTest.testRequired : false
                        }
                    />
                )}
            </Modal>
        </>
    );
};

export default ClosedLiquidTestRow;
