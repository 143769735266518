import React from 'react';
import { AccordionDetails, makeStyles } from '@material-ui/core';
import Checkbox from '../../../reusable/atoms/Checkbox';
import { useDispatch } from 'react-redux';
import { productPortfolioUpdateFacet } from '../../../../store/root-actions';
import { SelectedFacet } from '../../../../store/reducers/product-portfolio';

const useStyles = makeStyles({
    root: {
        padding: '0 .25em',
        margin: '0'
    },
    btn: {
        padding: '0.25em'
    }
});

const CheckboxFilterPanelDetails = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleUpdate = (event: any, name: string, code: string) => {
        const filter = { category: props.category, value: [{ facetCode: code, label: name, selected: event }] } as SelectedFacet;
        dispatch(productPortfolioUpdateFacet(filter));
    };

    return props.value.map((filter, index) => {
        return (
            <AccordionDetails key={index} className={classes.root}>
                <Checkbox
                    classes={{ root: classes.btn }}
                    checked={filter.selected}
                    label={filter.label || ""}
                    ariaLabel={filter.name}
                    onChange={(event) => handleUpdate(event, filter.label, filter.facetCode)}
                />
            </AccordionDetails>
        )
    });
}

export default CheckboxFilterPanelDetails;