import moment from 'moment';
import {
    Activity,
    filterShipToIdsByPermission,
    SecurityLevel
} from '../../utility/auth/useSecurity';
import { getAccountAddress } from '../../utility/helpers/address-helpers';
import OrdersService from '../../utility/services/orders-service';
import {
    TRADE_REQUEST_SUMMARY_LOADING,
    TRADE_REQUEST_SUMMARY_LOADED,
    TRADE_REQUEST_SUMMARY_LOADING_ERROR
} from '../actions/action-types';
import { Account } from '../reducers/customer-context';
import { TradeRequest } from '../reducers/trade-it-summary';

export const loadTradeRequests = (accounts: Account[]) => {
    return (dispatch, getState) => {
        const state = getState();
        const { accessToken } = state.auth;
        const region = state.regionCulture.regionCode;
        const culture = state.regionCulture.cultureCode;
        const shipToIds = filterShipToIdsByPermission(
            state.auth,
            accounts.map((account) => parseInt(account.accountId)),
            Activity.TradeItSummary,
            SecurityLevel.Edit
        );

        dispatch({ type: TRADE_REQUEST_SUMMARY_LOADING });
        OrdersService.getTradeRequestSummary(accessToken, shipToIds, culture, region)
            .then((response) => {
                let tradeRequests: TradeRequest[] = [];
                response.data.forEach((item) => {
                    let request: TradeRequest = {
                        accountFrom: item.accountFrom.toString(),
                        accountTo: item.accountTo.toString(),
                        submittedDate: moment(item.submittedDate).format('MM/DD/YYYY').toString(),
                        requestedAmount: item.requestedAmount.toString() + ' PL',
                        quantityEaches: item.quantityEaches.toString(),
                        quantitySkus: item.quantitySkus.toString(),
                        tradeRequestId: item.tradeRequestId.toString(),
                        accountFromAddress: !!item.addressFrom
                            ? getAccountAddress({ address: item.addressFrom } as Account)
                            : '',
                        accountToAddress: !!item.addressTo
                            ? getAccountAddress({ address: item.addressTo } as Account)
                            : '',
                        accountFromName: item.nameFrom ?? '',
                        accountToName: item.nameTo ?? ''
                    };
                    tradeRequests.push(request);
                });
                dispatch({
                    type: TRADE_REQUEST_SUMMARY_LOADED,
                    tradeRequests: tradeRequests
                });
            })
            .catch((error) => {
                dispatch({ type: TRADE_REQUEST_SUMMARY_LOADING_ERROR, error });
            });
    };
};
