import { FormHelperText, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { ErrorMessage, useFieldArray, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { red } from '../../../../themes/globalConstants';
import { enUS } from '../../../../utility/translations/locales';
import FormSection from '../../../reusable/molecules/FormSection';
import TextInput from '../../../reusable/molecules/TextInput';
import StateProvinceSelect from '../../../reusable/molecules/StateProvinceSelect';
import { KeyValuePair } from '../../../../store/types';
import AddItemFormButton from '../../../reusable/atoms/AddItemFormButton';
import clsx from 'clsx';
import RadioInput from '../../../reusable/molecules/RadioInput';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { Address, OnboardingDashboardState } from '../../../../store/reducers/onboarding-dashboard';
import { OnboardingStepStatus } from '../../../../utility/services/onboarding-service';
import { postalCodePattern } from '../../../../utility/helpers/address-helpers';
import Button from '../../../reusable/atoms/Button';

const useStyles = makeStyles(() => ({
    buyerInformationContainer: {
        '& p': {
            marginBottom: '1em'
        },
        '& h4': {
            width: '100%',
            marginBottom: '1em',
            marginTop: '1em'
        }
    },
    radioGroup: {
        marginTop: '1.5em',
        paddingLeft: '0.5em'
    },
    purchaseOrderNumberInput: {
        '& .MuiFilledInput-input': {
            padding: '10px 12px'
        }
    },
    errorText: {
        color: red
    },
    purchaseOrderNumberContainer: {
        position: 'relative',
        marginLeft: '0.25em'
    },
    errorProductOrderNumber: {
        position: 'absolute',
        left: 0
    },
    helperText: {
        '& .MuiFormHelperText-root': {
            marginLeft: 0
        }
    }
}));

export default function BuyerInformation() {
    const classes = useStyles();
    const { t } = useTranslation();
    const { control, errors, watch, setValue, register, reset } = useFormContext();
    const selectedCountry = 'US';
    const isRequired = <Trans i18nKey="required">Required</Trans>;

    const usePreviouslyEnteredBillingAddress = watch('usePreviouslyEnteredBillingAddress');
    const [disableBillingAddress, setDisableBillingAddress] = useState<boolean>(false);
    const [buyerInfoState, setBuyerInfoState] = useState<string | undefined>('');

    const signatureFirstName = watch('signatureFirstName');
    const signatureLastName = watch('signatureLastName');
    const signatureTitle = watch('signatureTitle');
    const signatureEmail = watch('signatureEmail');
    const signatureDate = watch('signatureDate');
    const purchaseOrderNumber = watch('purchaseOrderNumber');
    const stateArray = watch('stateArray');

    const { billingAndShipping, accountStatus } = useTypedSelector<OnboardingDashboardState>(
        (state) => state.onboardingDashboardState
    );

    const { fields, append, remove } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: 'stateArray' // unique name for your Field Array
    });

    const handleLoadBillingState = (name: string, selectedState: KeyValuePair) => {
        setValue(name, selectedState);
    };

    const registeredTypes: Array<KeyValuePair> = [
        {
            value: 'wholesaler',
            name: t('wholesalerCheck', enUS.wholesalerCheck)
        },
        {
            value: 'retailer',
            name: t('retailerCheck', enUS.retailerCheck)
        },
        {
            value: 'manufacturer',
            name: t('manufacturerCheck', enUS.manufacturerCheck)
        },
        {
            value: 'seller',
            name: t('sellerCheck', enUS.sellerCheck)
        },
        {
            value: 'lessor',
            name: t('lessorCheck', enUS.lessorCheck)
        },
        {
            value: 'other',
            name: t('otherCheck', enUS.otherCheck)
        }
    ];

    const watchIsRegisteredAs = watch('isRegisteredAs');

    const setAddressFields = (address: Address | undefined) => {
        if (address) {
            setValue('nameOfFirm', address.name);
            setValue('buyerAddressLine1', address.addressLine1);
            setValue('buyerAddressLine2', address.addressLine2);
            setValue('buyerAddressLine3', address.addressLine3);
            setValue('buyerCity', address.city);
            setValue('buyerState', address.state);
            setValue('buyerZipcode', address.zipCode);
        }
    };

    useEffect(() => {
        if (!disableBillingAddress && usePreviouslyEnteredBillingAddress === 'true') {
            setDisableBillingAddress(true);
            if (billingAndShipping.billingAndShippingForm?.billingAddress) {
                reset({
                    nameOfFirm: billingAndShipping.billingAndShippingForm?.billingAddress.name,
                    buyerAddressLine1:
                        billingAndShipping.billingAndShippingForm?.billingAddress.addressLine1,
                    buyerAddressLine2:
                        billingAndShipping.billingAndShippingForm?.billingAddress.addressLine2,
                    buyerAddressLine3:
                        billingAndShipping.billingAndShippingForm?.billingAddress.addressLine3,
                    buyerCity: billingAndShipping.billingAndShippingForm?.billingAddress.city,
                    buyerZipcode: billingAndShipping.billingAndShippingForm?.billingAddress.zipCode,
                    signatureFirstName: signatureFirstName,
                    signatureLastName: signatureLastName,
                    signatureTitle: signatureTitle,
                    signatureEmail: signatureEmail,
                    signatureDate: signatureDate,
                    purchaseOrderNumber: purchaseOrderNumber,
                    stateArray: stateArray
                });
                setBuyerInfoState(billingAndShipping.billingAndShippingForm?.billingAddress?.state);
            }
        } else if (disableBillingAddress && usePreviouslyEnteredBillingAddress === 'false') {
            setDisableBillingAddress(false);
            setAddressFields({
                name: '',
                addressLine1: '',
                addressLine2: '',
                addressLine3: '',
                city: '',
                state: '',
                zipCode: ''
            });
            setBuyerInfoState(undefined);
        }
    }, [usePreviouslyEnteredBillingAddress]);

    return (
        <FormSection
            testId={'buyer-information-section'}
            sectionHeader={
                <Trans i18nKey="buyerInformationAnchorText">
                    {enUS.buyerInformationAnchorText}
                </Trans>
            }
        >
            <Grid item container xs={12} spacing={2} className={classes.buyerInformationContainer}>
                {accountStatus.addressesStatus === OnboardingStepStatus.COMPLETE && (
                    <Grid container xs={12}>
                        <Grid item xs={12}>
                            <RadioInput
                                styles={classes.radioGroup}
                                label={
                                    <Trans i18nKey="usePreviouslyEnteredBillingAddress">
                                        Use previously entered Billing Address?
                                    </Trans>
                                }
                                name="usePreviouslyEnteredBillingAddress"
                                testId="usePreviouslyEnteredBillingAddress"
                                control={control}
                                radioControls={[
                                    { value: 'true', label: <Trans i18nKey="yes">Yes</Trans> },
                                    { value: 'false', label: <Trans i18nKey="no">No</Trans> }
                                ]}
                                defaultValue=""
                            />
                        </Grid>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Typography variant="body1">
                        <Trans i18nKey="certifyBody">{enUS.certifyBody}</Trans>
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        type="text"
                        label={<Trans i18nKey="nameOfFirmLabel">{enUS.nameOfFirmLabel}</Trans>}
                        name="nameOfFirm"
                        testId="nameOfFirm"
                        control={control}
                        rules={{ required: isRequired, pattern: /^[a-zA-Z0-9'-\s]*$/ }}
                        error={errors.nameOfFirm}
                        errors={errors}
                        styles={classes.helperText}
                        readOnly={disableBillingAddress}
                        helperText={<Trans i18nKey="buyerSubtext">{enUS.buyerSubtext}</Trans>}
                        inputProps={{ maxLength: 50 }}
                    />
                    <FormHelperText className={classes.errorText}>
                        {errors.nameOfFirm && (
                            <ErrorMessage errors={errors} name="nameOfFirm" message={isRequired} />
                        )}
                    </FormHelperText>
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        type="text"
                        label={
                            <Trans i18nKey="billingAddressLine1">{enUS.billingAddressLine1}</Trans>
                        }
                        name="buyerAddressLine1"
                        testId="buyerAddressLine1"
                        control={control}
                        rules={{ required: isRequired }}
                        error={errors.buyerAddressLine1}
                        errors={errors}
                        inputProps={{ maxLength: 40 }}
                        readOnly={disableBillingAddress}
                    />
                    <FormHelperText className={classes.errorText}>
                        {errors.buyerAddressLine1 && (
                            <ErrorMessage
                                errors={errors}
                                name="buyerAddressLine1"
                                message={isRequired}
                            />
                        )}
                    </FormHelperText>
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        type="text"
                        label={
                            <Trans i18nKey="billingAddressLine2">{enUS.billingAddressLine2}</Trans>
                        }
                        name="buyerAddressLine2"
                        testId="buyerAddressLine2"
                        control={control}
                        error={errors.buyerAddressLine2}
                        errors={errors}
                        inputProps={{ maxLength: 40 }}
                        readOnly={disableBillingAddress}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        type="text"
                        label={
                            <Trans i18nKey="billingAddressLine3">{enUS.billingAddressLine3}</Trans>
                        }
                        name="buyerAddressLine3"
                        testId="buyerAddressLine3"
                        control={control}
                        error={errors.buyerAddressLine3}
                        errors={errors}
                        inputProps={{ maxLength: 40 }}
                        readOnly={disableBillingAddress}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        type="text"
                        label={<Trans i18nKey="city">{enUS.city}</Trans>}
                        name="buyerCity"
                        testId="buyerCity"
                        control={control}
                        rules={{ required: isRequired }}
                        error={errors.buyerCity}
                        errors={errors}
                        inputProps={{ maxLength: 25 }}
                        readOnly={disableBillingAddress}
                    />
                    <FormHelperText className={classes.errorText}>
                        {errors.buyerCity && (
                            <ErrorMessage errors={errors} name="buyerCity" message={isRequired} />
                        )}
                    </FormHelperText>
                </Grid>
                <Grid item xs={6}>
                    <StateProvinceSelect
                        defaultValue={buyerInfoState}
                        name="buyerState"
                        countryCode={selectedCountry}
                        control={control}
                        rules={{ required: isRequired }}
                        error={errors.buyerState}
                        errors={errors}
                        handleLoadStateProvince={handleLoadBillingState}
                        disabled={disableBillingAddress}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        type="text"
                        label={<Trans i18nKey="postalCode">{enUS.postalCode}</Trans>}
                        name="buyerZipcode"
                        testId="buyerZipcode"
                        control={control}
                        rules={{
                            required: isRequired,
                            pattern: postalCodePattern
                        }}
                        error={errors.buyerZipcode}
                        errors={errors}
                        inputProps={{ maxLength: 11 }}
                        readOnly={disableBillingAddress}
                    />
                    <FormHelperText className={classes.errorText}>
                        {errors.buyerZipcode && errors.buyerZipcode.type === 'required' && (
                            <ErrorMessage
                                errors={errors}
                                name="buyerZipcode"
                                message={isRequired}
                            />
                        )}
                    </FormHelperText>
                </Grid>

                <Grid container item xs={12}>
                    <RadioInput
                        styles={classes.radioGroup}
                        label={
                            <Trans i18nKey="engagedAsRegisteredLabel">
                                {enUS.engagedAsRegisteredLabel}
                            </Trans>
                        }
                        name="isRegisteredAs"
                        testId="isRegisteredAs"
                        control={control}
                        rules={{ required: isRequired }}
                        radioControls={registeredTypes.map((type) => ({
                            value: type.value,
                            label:
                                type.value === 'other' ? (
                                    <Grid container alignItems="center">
                                        {type.name}
                                        <div className={classes.purchaseOrderNumberContainer}>
                                            <TextField
                                                type="text"
                                                name="other"
                                                className={classes.purchaseOrderNumberInput}
                                                data-testid="other"
                                                error={errors.other}
                                                inputRef={register({
                                                    required: watchIsRegisteredAs === 'other'
                                                })}
                                                inputProps={{
                                                    maxLength: 255
                                                }}
                                            />
                                            {errors.other && (
                                                <FormHelperText
                                                    className={clsx([
                                                        classes.errorText,
                                                        classes.errorProductOrderNumber
                                                    ])}
                                                >
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={'other'}
                                                        message={isRequired}
                                                    />
                                                </FormHelperText>
                                            )}
                                        </div>
                                    </Grid>
                                ) : (
                                    type.name
                                )
                        }))}
                        error={errors.isRegisteredAs}
                        errors={errors}
                        defaultValue=""
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1">
                        <Trans i18nKey="registeredBody2">{enUS.registeredBody2}</Trans>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        type="text"
                        label={
                            <Trans i18nKey="descriptionOfBusinessLabel">
                                {enUS.descriptionOfBusinessLabel}
                            </Trans>
                        }
                        name="descriptionOfBusiness"
                        testId="descriptionOfBusiness"
                        control={control}
                        rules={{ required: isRequired }}
                        error={errors.descriptionOfBusiness}
                        errors={errors}
                        inputProps={{
                            maxLength: 255
                        }}
                    />
                    {errors.descriptionOfBusiness && (
                        <FormHelperText className={classes.errorText}>
                            <ErrorMessage
                                errors={errors}
                                name={'descriptionOfBusiness'}
                                message={isRequired}
                            />
                        </FormHelperText>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        type="text"
                        label={
                            <Trans i18nKey="descriptionOfPropertyLabel">
                                {enUS.descriptionOfPropertyLabel}
                            </Trans>
                        }
                        name="descriptionOfProperty"
                        testId="descriptionOfProperty"
                        control={control}
                        rules={{ required: isRequired }}
                        error={errors.descriptionOfProperty}
                        errors={errors}
                    />
                    {errors.descriptionOfProperty && (
                        <FormHelperText className={classes.errorText}>
                            <ErrorMessage
                                errors={errors}
                                name={'descriptionOfProperty'}
                                message={isRequired}
                            />
                        </FormHelperText>
                    )}
                </Grid>
                {fields.map((field, index) => {
                    const fieldName = `stateArray[${index}]`;
                    return (
                        <Fragment key={field.id}>
                            <Grid item xs={fields.length > 1 ? 5 : 6}>
                                <StateProvinceSelect
                                    data-testid={`array-state-select-${index}`}
                                    defaultValue={fields[index].state?.value}
                                    name={`${fieldName}.state`}
                                    countryCode={selectedCountry}
                                    control={control}
                                    rules={{ required: isRequired }}
                                    error={
                                        errors.stateArray &&
                                        errors.stateArray[index] &&
                                        errors.stateArray[index].state
                                    }
                                    errors={errors}
                                    handleLoadStateProvince={handleLoadBillingState}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput
                                    defaultValue={fields[index].registrationNumber}
                                    type="text"
                                    label={
                                        <Trans i18nKey="registrationNumberLabel">
                                            {enUS.registrationNumberLabel}
                                        </Trans>
                                    }
                                    name={`${fieldName}.registrationNumber`}
                                    testId={`array-registrationNumber-${index}`}
                                    control={control}
                                    error={
                                        errors.stateArray &&
                                        errors.stateArray[index] &&
                                        errors.stateArray[index].registrationNumber
                                    }
                                    errors={errors}
                                />
                            </Grid>
                            {fields.length > 1 && (
                                <Grid
                                    container
                                    item
                                    xs={1}
                                    alignItems="center"
                                >
                                    <Button
                                        variant="text"
                                        color="primary"
                                        data-testid="remove-state-button"
                                        onClick={() => remove(index)}
                                    >
                                        <img
                                            src={process.env.PUBLIC_URL + '/assets/Trash.svg'}
                                            alt="Trash Icon"
                                        />
                                    </Button>
                                </Grid>
                            )}
                        </Fragment>
                    );
                })}
                {fields.length < 6 && (
                    <Grid item>
                        <AddItemFormButton
                            onClick={() => {
                                append({ state: '', registrationNumber: '' });
                            }}
                            text={t('addAnotherStateLabel', enUS.addAnotherStateLabel)}
                        />
                    </Grid>
                )}
            </Grid>
        </FormSection>
    );
}
