import { Grid, makeStyles, TableCell, TableRow, Tooltip, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { Region } from '../../../../store/reducers/graphic-intake';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { boldWeight, medium, orange, regularWeight } from '../../../../themes/globalConstants';
import { formatDate, formatDateForEU } from '../../../../utility/helpers/date-helpers';
import { InProgressGraphicForTable } from '../models/InProgressGraphicForTable';
import clsx from 'clsx';
import Link from '../../../reusable/atoms/Link';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
    graphic: InProgressGraphicForTable;
    onStatusHistoryLookup: (graphicsId: string | undefined) => void;
}

const useStyles = makeStyles((theme) => ({
    action: {
        cursor: 'pointer'
    },
    divider: {
        margin: '0 0.5em'
    },
    name: {
        fontWeight: boldWeight,
        fontSize: medium,
        letterSpacing: 0.17
    },
    id: {
        fontWeight: regularWeight,
        fontSize: medium,
        letterSpacing: 0.17
    },
    trashIcon: {
        fill: orange
    },
    normalWeight: {
        fontWeight: regularWeight
    },
    emeaCells: {
        verticalAlign: 'top'
    },
    pointer: {
        cursor: 'pointer'
    },
    requestNameColumn: {
        maxWidth: 200
    },
    requestNameTooltip: {
        maxWidth: 500
    }
}));

const GraphicsInProgressRow = ({ graphic, onStatusHistoryLookup }: Props) => {
    const classes = useStyles();
    const { regionCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const getStatusDate = (statusDate?: Date) => {
        if (statusDate) {
            return formatDate(statusDate.toString());
        } else {
            return '';
        }
    };

    const getEmeaStatusDetails = () => {
        let tableCell: Array<ReactNode> = [];
        if (graphic.statuses?.length) {
            graphic.statuses.forEach((currentStatus, index) => {
                if (currentStatus.iterationNumber && currentStatus.iterationNumber >= 0) {
                    tableCell.push(
                        <Grid item xs={9} key={`${currentStatus.displayStatus}-${index}`}>
                            <Link
                                component="button"
                                underline="always"
                                data-testid={`status-history-button-${index}`}
                                alignLeft={true}
                                onClick={() => {
                                    onStatusHistoryLookup(graphic.graphicsId);
                                }}
                            >
                                <Typography>{currentStatus.displayStatus}</Typography>
                            </Link>
                        </Grid>
                    );
                } else {
                    tableCell.push(
                        <Grid item xs={9} key={`${currentStatus.displayStatus}-${index}`}>
                            <Typography>{currentStatus.displayStatus}</Typography>
                        </Grid>
                    );
                }
                tableCell.push(
                    <Grid item xs={3} key={`${currentStatus.iterationDate}-${index}`}>
                        <Typography>
                            {currentStatus.iterationDate
                                ? formatDateForEU(currentStatus.iterationDate)
                                : ''}
                        </Typography>
                    </Grid>
                );
            });
        }
        return tableCell;
    };

    return (
        <>
            <TableRow hover={true} key={graphic.graphicsId}>
                <TableCell
                    className={clsx(classes.requestNameColumn, {
                        [classes.emeaCells]: regionCode === Region.EU
                    })}
                >
                    <Grid container>
                        <Tooltip
                            title={graphic.requestName || ''}
                            classes={{ tooltip: classes.requestNameTooltip }}
                            disableHoverListener={
                                !!graphic.requestName && graphic.requestName.length <= 50
                            }
                        >
                            <Grid item xs={12} data-testid="request-name">
                                {regionCode === Region.EU && (
                                    <Link
                                        className={classes.pointer}
                                        underline="always"
                                        component={RouterLink}
                                        to={`/emea-graphics-pdf/${graphic.graphicsId}`}
                                        target="_blank"
                                    >
                                        <Typography className={classes.name} noWrap>
                                            {graphic.requestName}
                                        </Typography>
                                    </Link>
                                )}
                                {regionCode !== Region.EU && (
                                    <Typography className={classes.name} noWrap>
                                        {graphic.requestName}
                                    </Typography>
                                )}
                            </Grid>
                        </Tooltip>
                        <Grid
                            item
                            xs={12}
                            className={classes.normalWeight}
                            data-testid="graphics-id"
                        >
                            {graphic.graphicsNumber}
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell className={clsx({ [classes.emeaCells]: regionCode === Region.EU })}>
                    {graphic.graphicSpecialist || graphic.graphicSpecialistEmail ? (
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                className={classes.normalWeight}
                                data-testid="specialist"
                            >
                                {graphic.graphicSpecialist}
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                className={classes.normalWeight}
                                data-testid="specialist-email"
                            >
                                {graphic.graphicSpecialistEmail}
                            </Grid>
                        </Grid>
                    ) : (
                        regionCode === Region.NA && (
                            <Grid container>
                                <Grid item xs={12} className={classes.normalWeight}>
                                    Ball{' '}
                                    <Trans i18nKey="graphicSpecialist">Graphic Specialist</Trans>
                                </Grid>
                                <Grid item xs={12} className={classes.normalWeight}>
                                    CORPGCS@ball.com
                                </Grid>
                            </Grid>
                        )
                    )}
                </TableCell>
                {regionCode === Region.NA && (
                    <>
                        <TableCell className={classes.normalWeight} data-testid="status">
                            {graphic.statuses &&
                                graphic.statuses.length > 0 &&
                                graphic.statuses[0].displayStatus}
                        </TableCell>
                        <TableCell className={classes.normalWeight} data-testid="status-date">
                            {graphic.statuses &&
                                graphic.statuses.length > 0 &&
                                getStatusDate(graphic.statuses[0].iterationDate)}
                        </TableCell>
                        <TableCell className={classes.normalWeight} data-testid="pending-ic-weight">
                            {graphic.pendingICWeightStr}
                        </TableCell>
                    </>
                )}
                {regionCode === Region.EU && (
                    <>
                        <TableCell className={classes.normalWeight} data-testid="status">
                            <Grid container justify="space-between">
                                {getEmeaStatusDetails()}
                            </Grid>
                        </TableCell>
                    </>
                )}
            </TableRow>
        </>
    );
};

export default GraphicsInProgressRow;
