import { ColumnDataType, ColumnSortDirection, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class ProductInformationColumns {
    static getProductInformationColumns = (
        culture: string,
        fromMakeIt: boolean,
        fromPlanIt: boolean
    ) => {
        return [
            createColumn('leadTimeWeeks', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'leadTime'),
                sortable: true,
                filterable: true,
                searchable: false,
                visible: false,
                sortDirection: ColumnSortDirection.Ascending
            }),
            createColumn('productSku', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'ballProductId'),
                sortable: true,
                filterable: true,
                searchable: true
            }),
            createColumn('displayId', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'customerProductId'),
                sortable: true,
                filterable: true,
                searchable: true
            }),
            createColumn('displayName', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'productDescription'),
                sortable: true,
                filterable: true,
                searchable: true
            }),
            createColumn('coating', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'coating'),
                sortable: true,
                filterable: true,
                searchable: true
            }),
            createColumn('leadTimeWeeksFormatted', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'leadTime'),
                visible: fromMakeIt || fromPlanIt,
                sortable: true,
                filterable: true,
                searchable: true
            }),
            createColumn('lockWindowWeeksFormatted', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'lockWindow'),
                visible: fromMakeIt,
                sortable: true,
                filterable: true,
                searchable: true
            }),
            createColumn('firstAvailableDate', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'firstAvailableWeek'),
                visible: fromMakeIt,
                sortable: true,
                filterable: true,
                searchable: false
            }),
            createColumn('description', {
                dataType: ColumnDataType.String,
                sortable: true,
                filterable: true,
                searchable: true,
                visible: false
            }),
            createColumn('type', {
                dataType: ColumnDataType.String,
                sortable: false,
                filterable: true,
                searchable: true,
                visible: false
            }),
            createColumn('campaignTiming', {
                dataType: ColumnDataType.String,
                visible: false
            })
        ];
    };
}
