import {
    NOTIFICATIONS_LOADING,
    NOTIFICATIONS_LOADED,
    NOTIFICATIONS_ERROR,
    NOTIFICATIONS_DISMISS_NOTIFICATION
} from '../actions/action-types';
import { Account } from '../reducers/customer-context';
import NotificationService, {
    NotificationRequest
} from '../../utility/services/notification-service';

export const dismissNotification = (notificationId: string, accountId: string) => {
    return (dispatch, getState) => {
        dispatch({
            type: NOTIFICATIONS_DISMISS_NOTIFICATION,
            notificationId,
            accountId
        });
    };
};
export const loadNotificationData = (accounts: Account[]) => {
    return (dispatch, getState) => {
        const { accessToken } = getState().auth;
        const shipToIds = accounts.map((account) => account.accountId);

        dispatch({ type: NOTIFICATIONS_LOADING });

        NotificationService.getNotificationData(accessToken, {
            shipToIds: shipToIds
        } as NotificationRequest)
            .then((response) => {
                let notifications = response.data.reduce((obj, item) => {
                    return {
                        ...obj,
                        [item.accountId]: item.notifications.map((n) => {
                            return {
                                ...n,
                                content: JSON.parse(n.content)
                            };
                        })
                    };
                }, {});
                dispatch({
                    type: NOTIFICATIONS_LOADED,
                    notifications
                });
            })
            .catch((error) => {
                dispatch({
                    type: NOTIFICATIONS_ERROR,
                    error
                });
            });
    };
};
