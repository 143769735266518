import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setRequestComment } from '../../../../store/root-actions';
import { Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { xxl, blackWeight } from '../../../../themes/globalConstants';

const useStyles = makeStyles((theme) => ({
    sectionMargin: {
        marginTop: '2em'
    },
    sectionHeader: {
        fontSize: xxl,
        letterSpacing: '0.21px',
        color: theme.palette.secondary.main,
        fontWeight: blackWeight,
        marginBottom: '0.5em'
    }
}));

export default function RequestAccessCommentSection() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    const [comment, setComment] = useState<string>('');
    const maxCommentLength = 50;

    return (
        <Grid container data-testid="comments-section" className={classes.sectionMargin}>
            <Grid container item xs={12}>
                <Typography className={classes.sectionHeader}>
                    <Trans i18nKey="additionalComments">Additional Comments (Optional)</Trans>
                </Typography>
            </Grid>
            <Grid container item xs={5}>
                <TextField
                    data-testid="request-comment"
                    label={`${t('enterComment', 'Enter Comment')}`}
                    value={comment}
                    fullWidth
                    onChange={(e) => setComment(e.target.value)}
                    onBlur={(e) => dispatch(setRequestComment(e.target.value))}
                    inputProps={{ maxLength: maxCommentLength }}
                />
            </Grid>
        </Grid>
    );
}
