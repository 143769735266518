export const AllPagesMapping: any = {
    GlobalLandingPage: [],
    SubmitNewLiquidTest: ['LiquidTestIntakeForm', 'LiquidTestIntakeSummary'],
    MakeItLandingPage: [
        'MakeItATMSummary',
        'MakeItBulkATM',
        'MakeItBulkUpload',
        'MakeIt',
        'ClosedProductionOrders',
        'ForecastProductInformation',
        'NewOpenProductionOrders'
    ],
    ShipItLandingPage: [
        'AddShipTo',
        'ShipItBulkUpload',
        'ClosedDeliveryOrders',
        'DraftDeliveryOrders',
        'NewOpenDeliveryOrders'
    ],
    PayableLandingPage: ['OrderPaymentFinancials'],
    MakeItDrafts: ['DraftProductionOrders'],
    Dashboard: ['Dashboard'],
    PlanItLandingPage: ['PlanItBulkUpload', 'PlanItOrderDetails', 'PlanItSummary'],
    ProductPlanning: ['PlanningPage', 'PlanningPageATM'],
    SubmitNewGraphics: [
        'DraftGraphicsIntakeForm',
        'GraphicsIntakeForm',
        'GraphicsProof',
        'GraphicsQuote',
        'GraphicsStandard',
        'GraphicsSummary',
        'RecentlyCompletedGraphics'
    ]
};
