import React, { useState, useEffect } from 'react';
import { useForm, Controller, ErrorMessage } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import {
    Grid,
    makeStyles,
    TextField,
    TextareaAutosize,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Recaptcha } from '../../reusable/molecules/Recaptcha';
import ContactService from '../../../utility/services/contact-service';
import Button from '../atoms/Button';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { AuthState } from '../../../store/reducers/auth';
import { regions } from '../../../static-data/dropdown-lists';
import { ballBlack_5, ballGreen } from '../../../themes/globalConstants';
import { createAxiosHeader } from '../../../utility/helpers/axios-helpers';
import { isValidEmail } from '../../../utility/helpers/user-helpers';
import { AxiosRequestConfig } from 'axios';
import clsx from 'clsx';

interface Props {
    messageType: string;
    onClose: () => void;
}

interface IContactForm {
    messageType: string;
    fillCountry: string;
    region: string;
    language: string;
    email: string;
    messageData: {
        name: string;
        email: string;
        subject: string;
        message: string;
    };
}

const useStyles = makeStyles((theme) => ({
    form: {
        backgroundColor: ballBlack_5,
        blur: 10
    },
    fields: {
        textAlign: 'center',
        marginTop: 48
    },
    formField: {
        marginTop: 0,
        marginBottom: 20,
        '& .MuiInputBase-root': {
            backgroundColor: theme.palette.common.white,
            border: '1px solid',
            borderColor: theme.palette.secondary.light,
            '&:before': {
                display: 'none'
            }
        },
        '& .MuiFormLabel-filled': {
            fontWeight: 700
        }
    },
    textAreaWrapper: {
        display: 'block',
        '& .MuiFormHelperText-root.Mui-error': {
            margin: 0,
            padding: '0 14px'
        }
    },
    textArea: {
        width: '91%',
        padding: '18px 14px',
        fontSize: 14,
        border: '1px solid',
        borderColor: theme.palette.secondary.light,
        fontFamily: "'Lato', 'Helvetica', 'Arial', sans-serif",
        '&:focus': {
            outline: 'none',
            border: '1px solid',
            borderColor: theme.palette.primary.main
        }
    },
    btn: {
        width: 100,
        height: 40,
        marginTop: 30,
        marginBottom: 60
    },
    close: {
        width: 100,
        height: 40,
        marginBottom: 60
    },
    select: {
        '& .MuiInputBase-root': {
            backgroundColor: theme.palette.common.white,
            border: '1px solid',
            borderColor: theme.palette.secondary.light,
            '&:before': {
                marginBottom: -3,
                border: 0
            },
            '&.Mui-focused': {
                '& .MuiSvgIcon-root': {
                    transform: 'rotate(-180deg)'
                }
            },
            '& .MuiSvgIcon-root': {
                fill: theme.palette.primary.main,
                transition: 'transform 200ms'
            }
        },
        '& .MuiSelect-root': {
            textAlign: 'left',
            backgroundColor: theme.palette.common.white
        },
        '& .MuiFormHelperText-root': {
            marginLeft: 14
        },
        '& .MuiFormLabel-filled': {
            fontWeight: 700
        }
    },
    reasonSelect: {
        marginBottom: 20
    },
    countrySelect: {
        marginBottom: 20
    },
    subjectField: {
        '& .MuiInputBase-root': {
            border: '1px solid',
            borderColor: theme.palette.secondary.light,
            '&:before': {
                display: 'none'
            }
        }
    },
    border: {
        border: '1px solid',
        borderColor: theme.palette.secondary.light
    },
    submitMessage: {
        maxWidth: '12.5em',
        margin: '1.875em auto 1.25em auto'
    },
    pending: {
        maxWidth: '12.5em',
        margin: '1.875em auto 3.75em auto'
    },
    success: {
        color: ballGreen
    },
    failure: {
        color: theme.palette.error.main
    }
}));

const ContactForm = (props: Props) => {
    const classes = useStyles();
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [pending, setPending] = useState(false);
    const [disabledContactTypes, setDisabledContactTypes] = useState<string[]>([]);
    const [askForEmail, setAskForEmail] = useState(false);
    const { errors, handleSubmit, control, register, unregister, watch, setValue } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur'
    });
    const { userInfo, accessToken, authenticated } = useTypedSelector<AuthState>(
        (state) => state.auth
    );
    const captchaToken = watch('captchaToken') as string;
    const regionCulture = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { t } = useTranslation();

    const isRequired = <Trans i18nKey="required">Required</Trans>;

    //Set value of Reason Select on load
    useEffect(() => {
        setValue('reason', props.messageType);
    }, [props.messageType, setValue]);

    useEffect(() => {
        // Require Captcha if the user is not authenticated
        if (!authenticated) register({ name: 'captchaToken' }, { required: true });
        else unregister('captchaToken');
    }, [register, authenticated]);

    useEffect(() => {
        if (authenticated) {
            setDisabledContactTypes(['LOGIN']);
            setAskForEmail(false);
        } else {
            setDisabledContactTypes(['CONTACT', 'SUPPORT']);
            setAskForEmail(true);
        }
    }, [authenticated]);

    const onVerifyCaptcha = (token) => {
        setValue('captchaToken', token);
    };

    const onSubmit = (data: any) => {
        const contactRequest = {
            messageType: data.reason,
            fillCountry: data.country || 'NONE', //country-less templates are keyed by NONE in message API
            region: regionCulture.regionCode,
            language: regionCulture.cultureCode,
            email: data.email || (userInfo?.email as string),
            messageData: {
                name: data.name,
                email: data.email || (userInfo?.email as string),
                subject: data.subject,
                message: data.message
            }
        } as IContactForm;

        setPending(true);
        if (authenticated && accessToken) {
            const axiosConfig: AxiosRequestConfig = createAxiosHeader(
                regionCulture?.cultureCode,
                regionCulture?.regionCode,
                accessToken
            );
            ContactService.sendMessage(contactRequest, axiosConfig)
                .then((res) => {
                    setSuccess(true);
                })
                .catch(() => {
                    setError(true);
                })
                .finally(() => {
                    setPending(false);
                });
        } else {
            ContactService.sendHelpMessage(contactRequest, data.captchaValue)
                .then((res) => {
                    setSuccess(true);
                })
                .catch(() => {
                    setError(true);
                })
                .finally(() => {
                    setPending(false);
                });
        }
    };

    const contactTypes = [
        { key: 'CONTACT', value: 'CONTACT', text: t('contact') },
        { key: 'SUPPORT', value: 'SUPPORT', text: t('support') },
        { key: 'LOGIN', value: 'LOGIN', text: t('cantLogIn') }
    ];

    const defaultValue = props.messageType;
    const watchContact = watch('reason') === 'CONTACT';

    return (
        <form
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off"
            className={classes.form}
        >
            <Grid container justify="center">
                <Grid item xs={6} className={classes.fields}>
                    <FormControl
                        fullWidth
                        error={errors.reason}
                        className={clsx(classes.select, classes.reasonSelect)}
                    >
                        <InputLabel>
                            <Trans i18nKey="contactReason"></Trans>
                        </InputLabel>
                        <Controller
                            as={
                                <Select
                                    labelId="contact-reason-select"
                                    id="contact-reason-select"
                                    data-testid="contact-reason-select"
                                    aria-label="Reason for Contact"
                                    IconComponent={() => <ExpandMoreIcon />}
                                >
                                    <MenuItem>&nbsp;</MenuItem>
                                    {contactTypes
                                        .filter((contactType) => {
                                            return !disabledContactTypes.includes(contactType.key);
                                        })
                                        .map((contactType) => (
                                            <MenuItem
                                                key={contactType.key}
                                                value={contactType.value}
                                            >
                                                {contactType.text}
                                            </MenuItem>
                                        ))}
                                </Select>
                            }
                            defaultValue={defaultValue}
                            name="reason"
                            control={control}
                            rules={{ required: true }}
                        />
                        <FormHelperText>
                            {errors.reason && 'Contact reason is required'}
                        </FormHelperText>
                    </FormControl>
                    <TextField
                        id="contact-form-name"
                        data-testid="contact-form-name"
                        label={<Trans i18nKey="yourName">Your Name</Trans>}
                        variant="filled"
                        fullWidth
                        margin="normal"
                        className={classes.formField}
                        name="name"
                        aria-label="name"
                        inputRef={register({ required: true })}
                        error={errors.name}
                        helperText={errors?.name && 'Name is required'}
                    />
                    {askForEmail && (
                        <TextField
                            id="contact-form-email"
                            data-testid="contact-form-email"
                            aria-label="Email"
                            label={<Trans i18nKey="email">Email</Trans>}
                            variant="filled"
                            fullWidth
                            margin="normal"
                            className={classes.formField}
                            name="email"
                            type="email"
                            inputRef={register({
                                required: isRequired,
                                validate: {
                                    isValidEmail: (value) => {
                                        return (
                                            isValidEmail(value) || (
                                                <Trans i18nKey="incorrectEmailAddress">
                                                    Invalid Email Format
                                                </Trans>
                                            )
                                        );
                                    }
                                }
                            })}
                            error={errors.email}
                            helperText={<ErrorMessage errors={errors} name="email" />}
                        />
                    )}

                    {watchContact && (
                        <FormControl
                            fullWidth
                            error={errors.country}
                            className={clsx(classes.select, classes.countrySelect)}
                        >
                            <InputLabel>
                                {<Trans i18nKey="yourCountry">Your Country of Fill</Trans>}
                            </InputLabel>
                            <Controller
                                as={
                                    <Select
                                        labelId="country-select"
                                        id="country-select"
                                        data-testid="country-select"
                                        aria-label="Your Country of Fill"
                                        IconComponent={() => <ExpandMoreIcon />}
                                    >
                                        <MenuItem>&nbsp;</MenuItem>
                                        {regions
                                            .find((r) => r.value === regionCulture.regionCode)
                                            ?.countries.map((c) => (
                                                <MenuItem key={c.value} value={c.value}>
                                                    {c.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                }
                                defaultValue={''}
                                name="country"
                                control={control}
                                rules={{ required: true }}
                            />
                            <FormHelperText>
                                {errors.country && (
                                    <Trans i18nKey="countryRequired">Country is required</Trans>
                                )}
                            </FormHelperText>
                        </FormControl>
                    )}
                    <TextField
                        id="contact-form-subject-line"
                        data-testid="contact-form-subject-line"
                        aria-label="Subject Line"
                        label={<Trans i18nKey="subjectLine">Subject Line</Trans>}
                        variant="filled"
                        fullWidth
                        margin="normal"
                        className={classes.formField + ' ' + classes.subjectField}
                        name="subject"
                        inputRef={register({ required: true })}
                        error={errors.subject}
                        helperText={errors?.subject && 'Subject is required'}
                    />
                    <FormControl className={classes.textAreaWrapper} error={errors.message}>
                        <Controller
                            as={
                                <TextareaAutosize
                                    id="contact-form-context"
                                    data-testid="contact-form-context"
                                    aria-label="contact message"
                                    rowsMin={4}
                                    placeholder={t('contactMessage', 'Tell us how we can help...')}
                                    className={classes.textArea}
                                />
                            }
                            name="message"
                            control={control}
                            rules={{ required: true }}
                        />
                        <FormHelperText>
                            {errors.message && (
                                <Trans i18nKey="messageRequired">A Message is required</Trans>
                            )}
                        </FormHelperText>
                    </FormControl>
                    {!authenticated && (
                        <Grid item xs={12}>
                            <Recaptcha onVerifyCaptcha={onVerifyCaptcha} value={captchaToken} />
                        </Grid>
                    )}
                    {/** Submit Button **/}
                    {!error && !success && !pending && (
                        <Button
                            type="submit"
                            variant="outlined"
                            color="primary"
                            className={classes.btn}
                        >
                            <Trans i18nKey="submit">Submit</Trans>
                        </Button>
                    )}
                    {/* Status Message */}
                    {success ? (
                        <Typography className={classes.submitMessage + ' ' + classes.success}>
                            <Trans i18nKey="msgSuccessful">Message Successfully Sent.</Trans>
                        </Typography>
                    ) : pending ? (
                        <Typography className={classes.pending}>
                            <Trans i18nKey="sending">Sending</Trans>
                        </Typography>
                    ) : error ? (
                        <Typography className={classes.submitMessage + ' ' + classes.failure}>
                            <Trans i18nKey="msgFailed">
                                Message failed to send, please try again at a different time.
                            </Trans>
                        </Typography>
                    ) : null}
                    {/* Close Button on Error/Success */}
                    {(success || error) && (
                        <Button
                            onClick={props.onClose}
                            type="button"
                            variant="contained"
                            color="primary"
                            className={classes.close}
                        >
                            <Trans i18nKey="close">Close</Trans>
                        </Button>
                    )}
                </Grid>
            </Grid>
        </form>
    );
};

export default ContactForm;
