import React from 'react';
import { makeStyles, Typography, TableRow, TableCell, Grid, Button } from '@material-ui/core';
import {
    ballBlue,
    blackWeight,
    boldWeight,
    medium,
    warningBorder,
    vanilla,
    xl,
    black,
    small
} from '../../../../themes/globalConstants';
import { Trans, useTranslation } from 'react-i18next';
import { ProductionOrderForTable } from '../models/ProductionOrderForTable';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { formatNumberWithLocale } from '../../../../utility/helpers/formatters';
import { MakeItATMSummaryState } from '../../../../store/reducers/make-it-atm-summary';
import { useDispatch } from 'react-redux';
import { MAKEIT_BULK_ATM_EDIT_ORDER_LOADING } from '../../../../store/actions/action-types';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import Link from '../../../reusable/atoms/Link';
import { Link as RouterLink } from 'react-router-dom';
import { loadEditATMOrder } from '../../../../store/root-actions';
import { BulkUploadMakeItState } from '../../../../store/reducers/makeit-bulk-upload';
import { resetATMProductionOrderState } from '../../../../store/actions/atm-production-order-details';
import { resetAllocationsState } from '../../../../store/root-actions';
import { formatStatus, orderStatusCodeFormatter } from '../../../../utility/helpers/order-helpers';
import { Order_Status } from '../../../../utility/services/orders-service';
import { enUS } from '../../../../utility/translations/locales';
import moment from 'moment';

interface Props {
    order: ProductionOrderForTable;
    tabType: 'open' | 'closed';
    hideEdit: boolean;
}

const useStyles = makeStyles((theme) => ({
    name: {
        fontWeight: boldWeight,
        fontSize: medium,
        letterSpacing: 0.17
    },
    editButton: {
        fontSize: '1em',
        '&:hover': {
            backgroundColor: 'transparent',
            color: ballBlue,
            fontWeight: blackWeight,
            outline: 'none'
        },
        '&:focus': {
            backgroundColor: 'transparent',
            color: ballBlue,
            fontWeight: blackWeight
        }
    },
    subsection: {
        fontSize: xl,
        fontWeight: boldWeight,
        letterSpacing: '0.13px',
        textAlign: 'left'
    },
    allocationWarning: {
        border: `1px solid ${warningBorder}`,
        borderRight: 'none',
        backgroundColor: vanilla
    },
    updateATM: {
        color: black,
        fontSize: small,
        maxHeight: '2em',
        paddingLeft: '0',
        textDecoration: 'underline'
    },
    italic: {
        fontStyle: 'italic'
    },
    editPending: {
        fontWeight: boldWeight,
        marginLeft: '1em'
    }
}));

export default function MakeItATMRow({ order, tabType, hideEdit }: Props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { orders } = useTypedSelector<MakeItATMSummaryState>((state) => state.makeItATMSummary);
    const history = useHistory();
    const { products } = useTypedSelector<BulkUploadMakeItState>(
        (state) => state.bulkUploadMakeItState
    );

    const resetLoadedOrder = () => {
        dispatch(resetATMProductionOrderState());
        dispatch(resetAllocationsState());
    };

    useTranslation();

    const handleEditClick = () => {
        resetLoadedOrder();
        if (orders && products) {
            dispatch({ type: MAKEIT_BULK_ATM_EDIT_ORDER_LOADING });
            const orderToEdit = orders.find(
                (ord) => ord.productionOrderId === order.productionOrderId
            );
            const otherOrdersSameWeek = orders.filter(
                (order) =>
                    moment(order.atmWeekStart).isSame(moment(orderToEdit?.atmWeekStart), 'day') &&
                    order.productionOrderId !== orderToEdit?.productionOrderId
            );
            if (orderToEdit) {
                dispatch(loadEditATMOrder(orderToEdit, otherOrdersSameWeek, products));
                history.push('/make-it-bulk-upload-po-review?from=summary');
            }
        }
    };

    const formatOrderQuantity = (
        quantityPallets?: number,
        quantityEaches?: number,
        quantitySKUs?: number
    ) => {
        return (
            <Grid item xs={12} className={classes.name}>
                {typeof quantityPallets !== 'undefined' && (
                    <Typography>
                        {formatNumberWithLocale(cultureCode, quantityPallets)} PL
                        {typeof quantitySKUs !== 'undefined' &&
                            (quantitySKUs > 1
                                ? ` (${formatNumberWithLocale(cultureCode, quantitySKUs)} SKUs)`
                                : ` (${formatNumberWithLocale(cultureCode, quantitySKUs)} SKU)`)}
                    </Typography>
                )}

                {typeof quantityEaches !== 'undefined' && (
                    <Typography>
                        {formatNumberWithLocale(cultureCode, quantityEaches)} ea.
                    </Typography>
                )}
            </Grid>
        );
    };

    const actions = () => {
        if (tabType === 'open' && !hideEdit) {
            return (
                <TableCell>
                    {!order.isEditable ||
                    order.status === Order_Status.Submit ? null : order.status !==
                      Order_Status.EditedPending ? (
                        <Button
                            variant="text"
                            color="primary"
                            data-testid="edit-button"
                            className={classes.editButton}
                            onClick={handleEditClick}
                            disabled={false}
                        >
                            <Trans i18nKey="edit">Edit</Trans>
                        </Button>
                    ) : (
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography
                                    data-testid={'edit-pending-action'}
                                    className={classes.editPending}
                                >
                                    <Trans i18nKey={'editPending'}>{enUS.editPending}</Trans>
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </TableCell>
            );
        }
    };

    const displayClosedTabItems = () => {
        if (tabType === 'closed') {
            return (
                <>
                    <TableCell data-testid={'order-status'}>
                        <Grid container>
                            <Grid item xs={12} className={classes.name}>
                                <Typography data-testid={'status'}>
                                    <Trans i18nKey={orderStatusCodeFormatter(order.status)}>
                                        {formatStatus(order.status)}
                                    </Trans>
                                </Typography>
                            </Grid>
                        </Grid>
                    </TableCell>
                    <TableCell data-testid={'last-updated-date'}>
                        <Grid container>
                            <Grid item xs={12} className={classes.name}>
                                {order.lastUpdatedDateString}
                            </Grid>
                        </Grid>
                    </TableCell>
                </>
            );
        }
    };

    return (
        <TableRow hover={true} key={order.productionOrderId}>
            <TableCell data-testid={'atm-week-start'}>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        {order.atmWeekStartString}
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell
                data-testid={'production-order-id'}
                className={clsx({
                    [classes.allocationWarning]: order.isBelowAllocation
                })}
            >
                <Grid container xs={12} spacing={1}>
                    {order.isBelowAllocation && (
                        <Grid item>
                            <img
                                data-testid="warning-icon"
                                src={process.env.PUBLIC_URL + '/assets/Warning_Icon.svg'}
                                alt="Warning Icon"
                            />
                        </Grid>
                    )}
                    <Grid item className={classes.name}>
                        <Link
                            underline="none"
                            component={RouterLink}
                            to={'/make-it-po-order/' + order.productionOrderId}
                            onClick={resetLoadedOrder}
                            className={clsx({
                                [classes.italic]: !order.productionOrderNumber
                            })}
                        >
                            {order.productionOrderNumber ? (
                                order.searchablePONumber
                            ) : (
                                <Trans i18nKey="pending">Pending</Trans>
                            )}
                        </Link>
                    </Grid>
                </Grid>
                {order.isBelowAllocation && (
                    <Grid container xs={12}>
                        <Typography>
                            <Trans i18nKey="underSupplyPlanWarning">
                                You're under the supply plan for one or more product groups in this
                                ATM.
                            </Trans>
                        </Typography>
                        <Button
                            variant="text"
                            color="primary"
                            data-testid="edit-button"
                            className={classes.updateATM}
                            onClick={handleEditClick}
                            disabled={false}
                        >
                            <Typography>
                                <Trans i18nKey="updateYourATM">Update your ATM</Trans>
                            </Typography>
                        </Button>
                    </Grid>
                )}
            </TableCell>

            <TableCell data-testid={'order-quantity'}>
                <Grid container>
                    {order.canQuantityPallets === 0 ? (
                        <Typography> - </Typography>
                    ) : (
                        formatOrderQuantity(
                            order.canQuantityPallets,
                            order.canQuantityEaches,
                            order.canQuantitySKUs
                        )
                    )}
                </Grid>
            </TableCell>
            <TableCell data-testid={'order-quantity-ea'}>
                <Grid container>
                    {order.endQuantityPallets === 0 ? (
                        <Typography> - </Typography>
                    ) : (
                        formatOrderQuantity(
                            order.endQuantityPallets,
                            order.endQuantityEaches,
                            order.endQuantitySKUs
                        )
                    )}
                </Grid>
            </TableCell>
            <TableCell data-testid={'submitted-date'}>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        {order.submittedDateString}
                    </Grid>
                </Grid>
            </TableCell>
            {actions()}
            {displayClosedTabItems()}
        </TableRow>
    );
}
