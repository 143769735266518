import React, { useEffect, useState } from 'react';
import { makeStyles, Typography, TableRow, TableCell, Grid, Tooltip } from '@material-ui/core';
import {
    ltBlueGrey_34,
    medium,
    yellow,
    warningLabel,
    redPill,
    yellowPill
} from '../../../../themes/globalConstants';
import { currencyFormatter, formatNumberWithLocale } from '../../../../utility/helpers/formatters';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import {
    ProductionOrderLineItem,
    ProductionOrderLinkedDeliveryOrder
} from '../../../../store/reducers/makeit-dashboard';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import ProductionOrderExpandableSection from './ProductionOrderExpandableSection';
import {
    orderStatusCodeFormatter,
    formatStatus,
    getGraphicIdAndVersion,
    getRequiredShipDateWarning
} from '../../../../utility/helpers/order-helpers';
import { useTranslation, Trans } from 'react-i18next';
import { getDesc } from '../../../../utility/helpers/production-order-helpers';
import { getUtcMidnight } from '../../../../utility/helpers/date-helpers';

interface ToggleAll {
    toggle: boolean;
}

interface Props {
    line: ProductionOrderLineItem;
    linkedDeliveryOrders?: ProductionOrderLinkedDeliveryOrder[];
    expand: ToggleAll;
    collapse: ToggleAll;
}

const useStyles = makeStyles((theme) => ({
    name: {
        fontSize: medium,
        letterSpacing: 0.17
    },
    expand: {
        transform: 'rotate(-90deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: 'rotate(0)'
    },
    expandContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    expandableSection: {
        maxHeight: '13.125em',
        overflowY: 'scroll'
    },
    standardRow: {
        '& > td:nth-child(n+4)': {
            textAlign: 'right'
        }
    },
    altRow: {
        backgroundColor: ltBlueGrey_34,
        '& > td': {
            borderBottomColor: 'white'
        }
    },
    hidden: {
        display: 'none'
    },
    display: {
        display: 'table-row'
    },
    borderlessCell: {
        borderLeft: 'unset'
    },
    activeCell: {
        backgroundColor: ltBlueGrey_34
    },
    activeRow: {
        boxShadow:
            '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 4px 3px 0px rgba(0,0,0,0.12)'
    },
    warningIcon: {
        fill: yellow,
        marginRight: '15px'
    },
    ballPoNumberContainer: {
        display: 'flex'
    },
    warningCell: {
        borderRadius: '.125em',
        backgroundColor: warningLabel
    },
    feeToolTip: {
        maxWidth: 200,
        fontSize: '0.75em'
    },
    cgRed: {
        color: redPill
    },
    goldenPoppy: {
        color: yellowPill
    }
}));

const ProductionOrderLineRow = ({ line, linkedDeliveryOrders, expand, collapse }: Props) => {
    const classes = useStyles();
    const [active, setActive] = useState<boolean>(false);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const toggleExpand = () => {
        setActive(!active);
    };

    useEffect(() => {
        if (expand.toggle) {
            setActive(true);
        }
    }, [expand]);

    useEffect(() => {
        if (collapse.toggle) {
            setActive(false);
        }
    }, [collapse]);

    const formatDate = (date?: string) => {
        if (date) {
            return getUtcMidnight(date).format('MM/DD/YYYY');
        } else {
            return '';
        }
    };

    useTranslation();
    const showWarning = (line.assessedFee || 0) > 0;
    const currencyFormattedFee: string = currencyFormatter(cultureCode, line.assessedFee);
    const requiredShipDateWarning: boolean | undefined = getRequiredShipDateWarning(
        line.requiredShipDate
    );

    return (
        <>
            <TableRow hover={true} key={line.productionOrderLineId}>
                <TableCell>
                    <Grid container>
                        <Grid item xs={10} className={classes.name}>
                            <Typography>
                                <strong>
                                    {line.customerProductId ? line.customerProductId : '-'}
                                </strong>
                            </Typography>
                            <Typography
                                key={'product-id-' + line.productionOrderLineId}
                                data-testid="display-id"
                            >
                                <strong>{line.displayId}</strong>
                            </Typography>
                            <Typography
                                key={'graphic-id-' + line.productionOrderLineId}
                                data-testid="graphic-id"
                            >
                                {getGraphicIdAndVersion(line.productType, line.productGraphicId)}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} className={classes.expandContainer}>
                            <ExpandMoreIcon
                                className={clsx(classes.expand, {
                                    [classes.expandOpen]: active
                                })}
                                onClick={toggleExpand}
                                color="primary"
                            />
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell className={showWarning ? classes.warningCell : ''}>
                    <Grid container justify="space-between">
                        <div className={classes.name}>
                            <Typography
                                key={'product-desc-' + line.productionOrderLineId}
                                data-testid="display-name"
                            >
                                {line.displayName}
                            </Typography>
                            <Typography
                                key={'product-characteristics-' + line.productionOrderLineId}
                                data-testid="description"
                            >
                                {getDesc(line)}
                            </Typography>
                        </div>
                        {showWarning && (
                            <Tooltip
                                title={`The quantity entered for this product does not meet the minimum order quantity. Fee applied to this product: ${currencyFormattedFee}`}
                                arrow
                                placement="right"
                                classes={{ tooltip: classes.feeToolTip }}
                            >
                                <img
                                    src={process.env.PUBLIC_URL + '/assets/Warning_Icon.svg'}
                                    alt="Warning Icon"
                                    className={classes.warningIcon}
                                />
                            </Tooltip>
                        )}
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid container>
                        <Grid item xs={12} className={classes.name}>
                            <Typography
                                key={'coating-' + line.productionOrderLineId}
                                data-testid="coating"
                            >
                                {line.productCoating}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid container>
                        <Grid item xs={12} className={classes.name}>
                            <Typography
                                key={'product-quantity-' + line.productionOrderLineId}
                                data-testid="quantity"
                            >
                                {formatNumberWithLocale(cultureCode, line.palletQuantity ?? 0)} PL
                            </Typography>
                            {line.quantityPerPallet && line.palletQuantity && (
                                <Typography
                                    key={'product-quantity-ea-' + line.productionOrderLineId}
                                    data-testid="quantity-ea"
                                >
                                    {formatNumberWithLocale(
                                        cultureCode,
                                        line.palletQuantity * line.quantityPerPallet
                                    )}{' '}
                                    ea.
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid container>
                        <Grid item xs={12} className={classes.name}>
                            <Typography
                                key={'product-date-' + line.productionOrderLineId}
                                data-testid="product-date"
                            >
                                {formatDate(line.requestedDate)}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell data-testid={'required-ship-date'}>
                    <Grid container>
                        <Grid item xs={12} className={classes.name}>
                            {line.requiredShipDate ? (
                                <>
                                    <Typography
                                        className={
                                            requiredShipDateWarning !== undefined
                                                ? requiredShipDateWarning
                                                    ? classes.goldenPoppy
                                                    : classes.cgRed
                                                : undefined
                                        }
                                    >
                                        {formatDate(line.requiredShipDate)}
                                    </Typography>
                                    <Typography>
                                        {formatNumberWithLocale(
                                            cultureCode,
                                            line.availableQuantity ?? 0
                                        )}{' '}
                                        PL
                                    </Typography>
                                    <Typography>
                                        {formatNumberWithLocale(
                                            cultureCode,
                                            line.availableEaches ?? 0
                                        )}{' '}
                                        ea.
                                    </Typography>
                                </>
                            ) : (
                                <Typography>N/A</Typography>
                            )}
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid container>
                        <Grid item xs={12} className={classes.name}>
                            <Typography
                                key={'product-status-' + line.productionOrderLineId}
                                data-testid="product-status"
                            >
                                <Trans i18nKey={orderStatusCodeFormatter(line.status)}>
                                    {formatStatus(line.status)}
                                </Trans>
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
            </TableRow>
            <ProductionOrderExpandableSection
                active={active}
                line={line}
                linkedDeliveryOrders={linkedDeliveryOrders}
            />
        </>
    );
};

export default ProductionOrderLineRow;
