import React from 'react';
import PdfPageTemplate from '../../../templates/PdfPageTemplate';
import { Document, Page, Text, View, BlobProvider, Image } from '@react-pdf/renderer';
import pdfStyles from '../../../../themes/pdfStyles';
import { formatFromDatetime } from '../../../../utility/helpers/date-helpers';
import { isIE } from '../../../../utility/helpers/user-helpers';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { getDesc } from '../../../../utility/helpers/production-order-helpers';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { getAccountAddress } from '../../../../utility/helpers/address-helpers';
import { isProductCanType } from '../../../../utility/helpers/order-helpers';
import { ProductType } from '../../../../utility/services/orders-service';
import {
    MakeItBulkATMLineItem,
    MakeItBulkATMLinesBySnoSku,
    MakeItBulkATMOrderByWeekAndSku
} from '../../../../store/reducers/makeit-bulk-atm';
import {
    getSnoSkuAttributes,
    getSnoSkuDescription
} from '../../../../utility/helpers/make-it-bulk-helpers';
import { formatNumberWithLocale } from '../../../../utility/helpers/formatters';
import { RegionCultureState } from '../../../../store/reducers/region-culture';

interface Props {
    title: string;
    orders: MakeItBulkATMOrderByWeekAndSku[];
}

const MakeItConversionConfirmationPDF = ({ title, orders }: Props) => {
    const ballLogo = process.env.PUBLIC_URL + '/assets/Ball_RGB.jpg';
    const sourceLogo = process.env.PUBLIC_URL + '/assets/TheSource_Logo.png';

    const { t } = useTranslation();
    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const getShipToName = (shipToId: number) => {
        if (shipToAccounts) {
            let shipTo = shipToAccounts.find((acc) => acc.accountId === shipToId.toString());
            return shipTo ? shipTo.name : '';
        } else {
            return '';
        }
    };

    const getShipToAddress = (shipToId: number) => {
        if (shipToAccounts) {
            let shipTo = shipToAccounts.find((acc) => acc.accountId === shipToId.toString());
            return shipTo ? getAccountAddress(shipTo) : '';
        } else {
            return '';
        }
    };

    const getTotalQuantitySnoSku = (lines: MakeItBulkATMLineItem[], type: ProductType) => {
        let pallets = 0;
        lines?.forEach((line) => {
            if (line.type === type) {
                pallets += line.palletQuantity ? line.palletQuantity : 0;
            }
        });
        return pallets;
    };

    const getTotalQuantitySnoSkuFormatted = (lines: MakeItBulkATMLineItem[], type: ProductType) => {
        const quantity = getTotalQuantitySnoSku(lines, type);
        return formatNumberWithLocale(cultureCode, quantity);
    };

    const getTotalEachesSnoSku = (lines: MakeItBulkATMLineItem[], type: ProductType) => {
        let eaches = 0;
        lines?.forEach((line) => {
            if (line.type === type) {
                eaches +=
                    line.palletQuantity && line.quantityPerPallet
                        ? line.palletQuantity * line.quantityPerPallet
                        : 0;
            }
        });
        return eaches;
    };
    const getTotalEachesSnoSkuFormatted = (lines: MakeItBulkATMLineItem[], type: ProductType) => {
        const quantity = getTotalEachesSnoSku(lines, type);
        return formatNumberWithLocale(cultureCode, quantity);
    };

    const getTotalQuantityATM = (lines: MakeItBulkATMLinesBySnoSku[], type: ProductType) => {
        let pallets = 0;
        lines?.forEach((line) => {
            pallets += line.lines ? getTotalQuantitySnoSku(line.lines, type) : 0;
        });
        return pallets;
    };

    const getTotalQuantityATMFormatted = (
        lines: MakeItBulkATMLinesBySnoSku[],
        type: ProductType
    ) => {
        const quantity = getTotalQuantityATM(lines, type);
        return formatNumberWithLocale(cultureCode, quantity);
    };

    const getTotalEachesATM = (lines: MakeItBulkATMLinesBySnoSku[], type: ProductType) => {
        let eaches = 0;
        lines?.forEach((line) => {
            eaches += line.lines ? getTotalEachesSnoSku(line.lines, type) : 0;
        });
        return eaches;
    };

    const getTotalEachesATMFormatted = (lines: MakeItBulkATMLinesBySnoSku[], type: ProductType) => {
        const quantity = getTotalEachesATM(lines, type);
        return formatNumberWithLocale(cultureCode, quantity);
    };

    const getATMSkus = (lines: MakeItBulkATMLinesBySnoSku[], type: ProductType) => {
        let skus = 0;
        lines.forEach((line) => {
            if (line.lines[0].type === type) {
                skus += line.lines.length;
            }
        });
        return skus;
    };

    const sortByWeek = (orders: MakeItBulkATMOrderByWeekAndSku[]) => {
        orders.sort((a, b) => moment(a.weekStart).valueOf() - moment(b.weekStart).valueOf());
        return orders;
    };

    const lines = (order: MakeItBulkATMOrderByWeekAndSku) =>
        order.linesBySnoSku?.map((lines, index) => {
            return (
                <>
                    <View key={index} style={[pdfStyles.marginBottom10, pdfStyles.borderlessTable]}>
                        <View
                            style={[
                                pdfStyles.borderlessTable,
                                pdfStyles.marginTop10,
                                pdfStyles.marginBottom10
                            ]}
                        >
                            <View style={[pdfStyles.flexSection, pdfStyles.pageHeader]}>
                                <View>
                                    <View>
                                        <Text style={pdfStyles.fieldLabel}>
                                            <Text style={pdfStyles.header2}>
                                                {t(
                                                    'productAttributes',
                                                    'Product Attributes'
                                                ).toLocaleUpperCase()}
                                            </Text>
                                        </Text>
                                    </View>
                                    <View>
                                        <Text
                                            style={[pdfStyles.subtitle10, pdfStyles.marginBottom3]}
                                        >
                                            {getSnoSkuDescription(
                                                isProductCanType(lines.lines[0].type),
                                                lines.lines[0]
                                            )}
                                        </Text>
                                        <Text style={[pdfStyles.subtitle7]}>
                                            {getSnoSkuAttributes(
                                                isProductCanType(lines.lines[0].type),
                                                lines.lines[0]
                                            )}
                                        </Text>
                                    </View>
                                </View>
                                <View style={pdfStyles.width20}></View>
                                <View
                                    style={[
                                        pdfStyles.borderLessTableColNarrow11,
                                        pdfStyles.textAlignRight
                                    ]}
                                >
                                    <View>
                                        <Text>
                                            <Text style={pdfStyles.header2}>
                                                {t(
                                                    'totalEnteredQuantity',
                                                    'Total Entered Quantity'
                                                ).toLocaleUpperCase()}
                                            </Text>
                                        </Text>
                                    </View>
                                    <View>
                                        <Text
                                            style={[pdfStyles.subtitle10, pdfStyles.marginBottom3]}
                                        >
                                            {formatNumberWithLocale(
                                                cultureCode,
                                                getTotalQuantitySnoSku(
                                                    lines.lines,
                                                    ProductType.Cans
                                                ) +
                                                    getTotalQuantitySnoSku(
                                                        lines.lines,
                                                        ProductType.Ends
                                                    )
                                            ) + ' PL'}

                                            {lines.allocationAmount &&
                                                ' of ' +
                                                    formatNumberWithLocale(
                                                        cultureCode,
                                                        lines.allocationAmount
                                                    ) +
                                                    ' PL'}
                                        </Text>
                                        <Text style={pdfStyles.subtitle7}>
                                            {formatNumberWithLocale(
                                                cultureCode,
                                                getTotalEachesSnoSku(
                                                    lines.lines,
                                                    ProductType.Ends
                                                ) +
                                                    getTotalEachesSnoSku(
                                                        lines.lines,
                                                        ProductType.Cans
                                                    )
                                            ) + ` ea.`}
                                        </Text>
                                    </View>
                                </View>

                                <View
                                    style={[
                                        pdfStyles.borderLessTableColNarrow2,
                                        pdfStyles.textAlignRight
                                    ]}
                                >
                                    <View>
                                        <Text style={pdfStyles.header2}>
                                            <Trans i18nKey="canQuantity">Can Quantity</Trans>
                                        </Text>
                                    </View>
                                    <View>
                                        <Text
                                            style={[pdfStyles.subtitle10, pdfStyles.marginBottom3]}
                                        >
                                            {getTotalQuantitySnoSkuFormatted(
                                                lines.lines,
                                                ProductType.Cans
                                            ) + ' PL'}
                                            {getTotalQuantitySnoSku(lines.lines, ProductType.Cans) >
                                                0 && ' / '}
                                            {lines.lines.length > 1 &&
                                                getTotalQuantitySnoSku(
                                                    lines.lines,
                                                    ProductType.Cans
                                                ) > 0 &&
                                                lines.lines.length + ' SKUS'}
                                            {lines.lines.length === 1 &&
                                                getTotalQuantitySnoSku(
                                                    lines.lines,
                                                    ProductType.Cans
                                                ) > 0 &&
                                                '1 SKU'}
                                        </Text>
                                        <Text style={pdfStyles.subtitle7}>
                                            {getTotalQuantitySnoSku(lines.lines, ProductType.Cans) >
                                                0 &&
                                                getTotalEachesSnoSkuFormatted(
                                                    lines.lines,
                                                    ProductType.Cans
                                                ) + ` ea.`}
                                        </Text>
                                    </View>
                                </View>
                                <View
                                    style={[
                                        pdfStyles.borderLessTableColNarrow2,
                                        pdfStyles.textAlignRight
                                    ]}
                                >
                                    <View>
                                        <Text style={pdfStyles.header2}>
                                            <Trans i18nKey="endQuantity">End Quantity</Trans>
                                        </Text>
                                    </View>
                                    <View>
                                        <Text
                                            style={[pdfStyles.subtitle10, pdfStyles.marginBottom3]}
                                        >
                                            {getTotalQuantitySnoSkuFormatted(
                                                lines.lines,
                                                ProductType.Ends
                                            ) + ` PL`}
                                            {getTotalQuantitySnoSku(lines.lines, ProductType.Ends) >
                                                0 && ' / '}
                                            {lines.lines.length > 1 &&
                                                getTotalQuantitySnoSku(
                                                    lines.lines,
                                                    ProductType.Ends
                                                ) > 0 &&
                                                lines.lines.length + ' SKUS'}
                                            {lines.lines.length === 1 &&
                                                getTotalQuantitySnoSku(
                                                    lines.lines,
                                                    ProductType.Ends
                                                ) > 0 &&
                                                '1 SKU'}
                                        </Text>
                                        <Text style={[pdfStyles.subtitle7]}>
                                            {getTotalQuantitySnoSku(lines.lines, ProductType.Ends) >
                                                0 &&
                                                getTotalEachesSnoSkuFormatted(
                                                    lines.lines,
                                                    ProductType.Ends
                                                ) + ` ea.`}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                        <View
                            style={[
                                pdfStyles.tableRow3,
                                pdfStyles.marginBottom5,
                                pdfStyles.marginTop10
                            ]}
                        >
                            <View style={[pdfStyles.borderLessTableColNarrow25]}>
                                <Text style={[pdfStyles.header2]}>
                                    {t(
                                        'productGraphicId',
                                        'PRODUCT ID / GRAPHIC ID'
                                    ).toLocaleUpperCase()}
                                </Text>
                            </View>
                            <View style={pdfStyles.borderLessTableColWide40}>
                                <Text style={pdfStyles.header2}>
                                    {t(
                                        'productDescription',
                                        'PRODUCT DESCRIPTION'
                                    ).toLocaleUpperCase()}
                                </Text>
                            </View>
                            <View style={[pdfStyles.borderLessTableColNarrow14]}>
                                <Text style={[pdfStyles.header2]}>
                                    {t('coating', 'Coating').toLocaleUpperCase()}
                                </Text>
                            </View>
                            <View
                                style={[
                                    pdfStyles.borderLessTableColNarrow11,
                                    pdfStyles.textAlignRight
                                ]}
                            >
                                <Text style={[pdfStyles.header2]}>
                                    {t(
                                        'quantityToPurchase',
                                        'Quantity To Purchase'
                                    ).toLocaleUpperCase()}
                                </Text>
                            </View>
                        </View>

                        <View style={pdfStyles.dividerThinGray2} />
                        {lines.lines.map((line) => {
                            return (
                                <View>
                                    <View style={[pdfStyles.tableRow, pdfStyles.marginBottom5]}>
                                        <View style={pdfStyles.borderLessTableColNarrow25}>
                                            <Text style={[pdfStyles.tableCell5]}>
                                                {line.displayId}
                                                {line.graphicIdAndVersion && ' / '}
                                                {line.graphicIdAndVersion}
                                            </Text>
                                        </View>
                                        <View style={pdfStyles.borderLessTableColWide40}>
                                            <Text style={[pdfStyles.tableCell5]}>
                                                {line.displayName}
                                                {' / '}
                                                {getDesc(line)}
                                            </Text>
                                        </View>
                                        <View style={pdfStyles.borderLessTableColNarrow9}>
                                            <Text style={[pdfStyles.tableCell5]}>
                                                {line.productCoating}
                                            </Text>
                                        </View>
                                        <View style={pdfStyles.borderLessTableColNarrow11}>
                                            <Text
                                                style={[
                                                    pdfStyles.tableCell5,
                                                    pdfStyles.textAlignRight
                                                ]}
                                            >
                                                {line.palletQuantity &&
                                                    formatNumberWithLocale(
                                                        cultureCode,
                                                        line.palletQuantity
                                                    )}
                                                {' PL'}
                                            </Text>
                                            <Text
                                                style={[
                                                    pdfStyles.tableCell5,
                                                    pdfStyles.textAlignRight
                                                ]}
                                            >
                                                {line.palletQuantity && line.quantityPerPallet
                                                    ? formatNumberWithLocale(
                                                          cultureCode,
                                                          line.palletQuantity *
                                                              line.quantityPerPallet
                                                      ) + ' ea.'
                                                    : ''}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={pdfStyles.dividerThinGray2} />
                                </View>
                            );
                        })}
                    </View>
                </>
            );
        });

    const ordersList = sortByWeek(orders).map((order) => {
        return (
            <>
                <View style={[pdfStyles.borderlessTable, pdfStyles.marginTop20]}>
                    <View style={[pdfStyles.flexSection, pdfStyles.pageHeader]}>
                        <View style={[pdfStyles.divider6, pdfStyles.marginBottom10]} fixed />
                        <View style={[pdfStyles.fieldLabel]}>
                            <Text>
                                {t('ballProdOrder', 'Ball Production Order')}: #
                                {order.productionOrderNumber ?? t('pending', 'Pending')}
                            </Text>
                        </View>
                        <View style={[pdfStyles.divider6, pdfStyles.marginBottom10]} fixed />
                        <View style={pdfStyles.marginRight3}>
                            <Text style={[pdfStyles.header7]}>
                                {t('makeIt', 'Make It').toLocaleUpperCase()}
                            </Text>
                        </View>
                        <View style={pdfStyles.marginRight3}>
                            <View>
                                <Text style={pdfStyles.fieldLabel}>
                                    <Text style={pdfStyles.header2}>
                                        {t('orderWeek', 'Order Week').toLocaleUpperCase()}
                                    </Text>
                                </Text>
                            </View>
                            <View>
                                <Text style={[pdfStyles.subtitle6]}>
                                    {formatFromDatetime(order.weekStart)}
                                </Text>
                            </View>
                        </View>
                        <View>
                            <View>
                                <Text style={pdfStyles.fieldLabel}>
                                    <Text style={pdfStyles.header2}>
                                        <Trans i18nKey="dateRevised">Date Revised</Trans>
                                    </Text>
                                </Text>
                            </View>
                            <View>
                                <Text style={[pdfStyles.subtitle6]}>
                                    {formatFromDatetime(moment().format())}
                                </Text>
                            </View>
                        </View>
                        <View style={pdfStyles.width15}></View>
                        <View
                            style={[pdfStyles.borderLessTableColNarrow7, pdfStyles.textAlignRight]}
                        >
                            <View>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="canQuantity">Can Quantity</Trans>
                                </Text>
                            </View>
                            <View>
                                <Text style={[pdfStyles.subtitle14, pdfStyles.marginBottom3]}>
                                    {getTotalQuantityATMFormatted(
                                        order.linesBySnoSku,
                                        ProductType.Cans
                                    ) + ` PL`}
                                    {getATMSkus(order.linesBySnoSku, ProductType.Cans) > 0 &&
                                        ' / ' + getATMSkus(order.linesBySnoSku, ProductType.Cans)}
                                    {getATMSkus(order.linesBySnoSku, ProductType.Cans) > 1 &&
                                        ' SKUS'}
                                    {getATMSkus(order.linesBySnoSku, ProductType.Cans) === 1 &&
                                        ' SKU'}
                                </Text>
                                <Text style={pdfStyles.subtitle7}>
                                    {getTotalQuantityATM(order.linesBySnoSku, ProductType.Cans) >
                                        0 &&
                                        getTotalEachesATMFormatted(
                                            order.linesBySnoSku,
                                            ProductType.Cans
                                        ) + ` ea.`}
                                </Text>
                            </View>
                        </View>
                        <View
                            style={[pdfStyles.borderLessTableColNarrow7, pdfStyles.textAlignRight]}
                        >
                            <View>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="endQuantity">End Quantity</Trans>
                                </Text>
                            </View>
                            <View>
                                <Text style={[pdfStyles.subtitle14, pdfStyles.marginBottom3]}>
                                    {getTotalQuantityATMFormatted(
                                        order.linesBySnoSku,
                                        ProductType.Ends
                                    ) + ` PL`}
                                    {getATMSkus(order.linesBySnoSku, ProductType.Ends) > 0 &&
                                        ' / ' + getATMSkus(order.linesBySnoSku, ProductType.Ends)}
                                    {getATMSkus(order.linesBySnoSku, ProductType.Ends) > 1 &&
                                        ' SKUS'}
                                    {getATMSkus(order.linesBySnoSku, ProductType.Ends) === 1 &&
                                        ' SKU'}
                                </Text>
                                <Text style={pdfStyles.subtitle7}>
                                    {getTotalQuantityATM(order.linesBySnoSku, ProductType.Ends) >
                                        0 &&
                                        getTotalEachesATMFormatted(
                                            order.linesBySnoSku,
                                            ProductType.Ends
                                        ) + ` ea.`}
                                </Text>
                            </View>
                        </View>
                        <View style={[pdfStyles.divider6, pdfStyles.marginTop10]} fixed />
                    </View>
                </View>

                <View style={pdfStyles.borderlessTable}>
                    <View style={[pdfStyles.tableRow]}>
                        <View>
                            <View style={[pdfStyles.tableRow]}>
                                <View style={pdfStyles.borderLessTableCol1}>
                                    <Text style={pdfStyles.header2}>
                                        {t('shipTo', 'Ship To').toLocaleUpperCase()}
                                    </Text>
                                </View>
                            </View>

                            <View style={[pdfStyles.tableRow, pdfStyles.marginBottom5]}>
                                <View style={pdfStyles.borderLessTableCol1}>
                                    <Text style={[pdfStyles.subtitle3]}>
                                        {getShipToName(order.shipToId)}
                                    </Text>
                                    <Text style={[pdfStyles.subtitle3]}>
                                        {getShipToAddress(order.shipToId)}
                                    </Text>
                                    <Text style={[pdfStyles.subtitle3]}>#{order.shipToId}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
                {lines(order)}
                <View style={pdfStyles.paragraph}>
                    <Text></Text>
                </View>
            </>
        );
    });

    const myDoc = (
        <Document title={title}>
            <Page size="A4" style={pdfStyles.pagePadding25} orientation={'portrait'}>
                <View style={pdfStyles.logoSection}>
                    <Image src={sourceLogo} style={pdfStyles.sourceLogo} />
                    <Image src={ballLogo} style={pdfStyles.ballLogo} />
                </View>

                <View style={[pdfStyles.flexSection, pdfStyles.pageHeader]}>{ordersList}</View>
                <View style={[pdfStyles.fullWidth, pdfStyles.footer]} fixed>
                    <Text
                        style={pdfStyles.header2}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </View>
            </Page>
        </Document>
    );

    if (!isIE()) {
        return <PdfPageTemplate title={title}>{myDoc}</PdfPageTemplate>;
    } else {
        return (
            <BlobProvider document={myDoc}>
                {({ blob, url, loading, error }) => {
                    //@ts-ignore
                    if (blob && window.navigator && window.navigator.msSaveOrOpenBlob) {
                        //@ts-ignore
                        window.navigator.msSaveOrOpenBlob(blob);
                    }
                    return null;
                }}
            </BlobProvider>
        );
    }
};

export default MakeItConversionConfirmationPDF;
