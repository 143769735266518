import { InputBaseComponentProps, TextField } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { Controller, EventFunction } from 'react-hook-form';

interface Props {
    type: string;
    label: ReactElement;
    name: string;
    testId: string;
    rules?: any;
    control: any;
    error?: ReactElement;
    errors?: any;
    helperText?: ReactElement;
    styles?: any;
    multiline?: boolean;
    defaultValue?: string;
    onBlur?: EventFunction;
    onChange?: EventFunction;
    readOnly?: boolean;
    inputProps?: InputBaseComponentProps;
    ariaLabel?: string;
}

const TextInput = (props: Props) => {
    const error = props.error !== undefined;
    return (
        <Controller
            as={
                <TextField
                    type={props.type}
                    inputProps={{
                        ...props.inputProps,
                        'data-testid': props.testId,
                        'aria-label': props.ariaLabel
                    }}
                />
            }
            className={props.styles ?? ''}
            control={props.control}
            fullWidth
            multiline={props.multiline}
            label={props.label}
            name={props.name}
            disabled={props.readOnly}
            rules={props.rules}
            error={error}
            onBlur={props.onBlur}
            onChange={props.onChange}
            helperText={error ? '' : props.helperText}
            defaultValue={props.defaultValue || ''}
        />
    );
};

export default TextInput;
