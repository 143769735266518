import React from 'react';
import Link from '../../../reusable/atoms/Link';
import { Link as RouterLink } from 'react-router-dom';
import {
    Card,
    CardHeader,
    CardMedia,
    CardContent,
    CardActions,
    Typography,
    makeStyles,
    List,
    ListItem,
    ListItemIcon,
    Grid
} from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { listIcon, ivory } from '../../../../themes/globalConstants';

interface Props {}

const useStyles = makeStyles((theme) => ({
    endImage: {
        height: '10em',
        backgroundSize: '50%',
        backgroundColor: ivory
    },
    content: {
        paddingLeft: 0,
        minHeight: '3.5em',
        maxHeight: '5em',
        overflow: 'hidden'
    },
    actionContainer: {
        margin: '0 0.5em 0.5em 0.5em'
    },
    detailsLink: {
        color: theme.palette.common.black,
        textTransform: 'capitalize',
        fontSize: 12,
        fontWeight: 500
    },
    saIcon: {
        marginTop: '0.2em'
    },
    tileContainer: {
        height: '99%'
    }
}));

const EndTile = (props: Props) => {
    const classes = useStyles();
    const { regionCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    useTranslation();

    const title = <Trans i18nKey="endTile">Ends/Closures</Trans>;

    return (
        <Card variant="outlined" className={classes.tileContainer} data-testid="end-tile">
            <CardHeader title={title} />
            <RouterLink to="/product-ends">
                <CardMedia
                    data-testid="end-image"
                    className={classes.endImage}
                    title="Ends/Closures"
                    image="/source/assets/end.png"
                />
            </RouterLink>
            <CardContent className={classes.content}>
                {regionCode && ['NA', 'SA'].find((r) => r === regionCode) ? (
                    <List>
                        <ListItem>
                            <Grid container>
                                <Grid item xs={1}>
                                    <ListItemIcon>
                                        <span>{listIcon}</span>
                                    </ListItemIcon>
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography variant="body1">
                                        <Trans i18nKey="minOrderPallet">
                                            Minimum Order in Pallets
                                        </Trans>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ListItem>
                        <ListItem>
                            <Grid container>
                                <Grid item xs={1}>
                                    <ListItemIcon className={classes.saIcon}>
                                        <span>{listIcon}</span>
                                    </ListItemIcon>
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography variant="body1">
                                        <Trans i18nKey="orderIndividually">
                                            Can be ordered individually
                                        </Trans>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ListItem>
                    </List>
                ) : null}
            </CardContent>
            <CardActions>
                <Grid container alignContent="center" className={classes.actionContainer}>
                    <Grid container item xs={12} justify="flex-start" alignItems="center">
                        <Link
                            component={RouterLink}
                            to="/product-ends"
                            underline="always"
                            data-testid="end-details"
                            className={classes.detailsLink}
                        >
                            <Trans i18nKey="endDetails">End Details</Trans>
                        </Link>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    );
};

export default EndTile;
