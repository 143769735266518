/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { makeStyles, Typography, TableRow, TableCell, Grid, Input } from '@material-ui/core';
import {
    ballLtGray,
    small,
    white,
    regularWeight,
    shipRed,
    errorBackground,
    warningBorder,
    vanilla,
    boldWeight,
    blackWeight,
    medium,
    informationAlert,
    black
} from '../../../../themes/globalConstants';
import clsx from 'clsx';
import {
    getMoqFeeCeiling,
    getMoqFeeFloor
} from '../../../../utility/helpers/production-order-helpers';
import {
    evaluatePalletQuantityValid,
    getFeeStructure,
    getPalletQuantityKey,
    PalletQuantityState
} from '../../../../utility/helpers/make-it-bulk-helpers';
import { formatNumberWithLocale, numericRegex } from '../../../../utility/helpers/formatters';
import { useDispatch } from 'react-redux';
import { MakeItBulkATMLineItem } from '../../../../store/reducers/makeit-bulk-atm';
import Button from '../../../reusable/atoms/Button';
import moment from 'moment';
import { Alert } from '@material-ui/lab';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { isDateWithinAtmLockPeriod } from '../../../../utility/helpers/date-helpers';
import { useMakeItConversionReview } from '../context/MakeItConversionReviewContext';
import {
    deleteProductFromOrder,
    updatePalletQuantity
} from '../../../../store/actions/makeit-conversion-atm';
import { ProductStatus } from '../../../../utility/services/orders-service';

interface Props {
    item: MakeItBulkATMLineItem;
}

const useStyles = makeStyles((theme) => ({
    tableRowContainer: {
        '& .helper-text': {
            fontWeight: `${regularWeight} !important`,
            fontSize: small
        },
        '& .validation-error-text': {
            fontWeight: `${boldWeight} !important`,
            fontSize: small
        },
        '& .MuiInputBase-input': {
            textAlign: 'right',
            paddingRight: '.25em'
        }
    },
    textField: {
        width: '7em',
        height: '1.5em',
        border: `1.8px solid ${ballLtGray}`,
        borderRadius: '.25em',
        margin: '0 0.5em 0 0.5em',
        backgroundColor: white,
        fontWeight: blackWeight
    },
    alignCenter: {
        textAlign: 'center'
    },
    bold: {
        fontWeight: blackWeight
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    trash: {
        minWidth: 'unset'
    },
    feeError: {
        border: `1px solid ${shipRed}`,
        borderRight: 'none',
        backgroundColor: errorBackground
    },
    inactiveProduct: {
        border: `1px solid ${shipRed}`,
        backgroundColor: errorBackground
    },
    feeWarning: {
        border: `1px solid ${warningBorder}`,
        borderRight: 'none',
        backgroundColor: vanilla
    },
    alertBackground: {
        backgroundColor: informationAlert
    },
    pallet: {
        fontSize: medium
    },
    eachesCountPadding: {
        paddingRight: '6.5em'
    },
    informationalAlert: {
        backgroundColor: informationAlert,
        borderRadius: '.125em',
        alignItems: 'center',
        width: '95%',
        maxHeight: '2em'
    },
    informationAlertText: {
        fontWeight: boldWeight,
        fontSize: small,
        color: black
    },
    originalQuantityPallets: {
        fontWeight: blackWeight
    },
    alertText: {
        fontSize: small,
        fontStyle: 'italic'
    }
}));

const MakeItConversionReviewOrderSectionRow = ({ item }: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [purchaseQty, setPurchaseQty] = useState<number | undefined>(undefined);
    const [disabled, setDisabled] = useState<boolean>(false);
    const leadTime = item.leadTimeWeeks ?? 1;
    // Warning and Error logic
    const hasMoqFees = item.moqFees && item.moqFees.length > 0;
    const moqFeeFloor = getMoqFeeFloor(item.moqFees);
    const moqFeeCeiling = getMoqFeeCeiling(item.moqFees);
    const [palletQuantityState, setPalletQuantityState] = useState<PalletQuantityState>(undefined);
    const { activeDate, shipToId } = useMakeItConversionReview();
    const [hasBlankQuantity, setHasBlankQuantity] = useState<boolean>(false);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const eachesValue =
        item.palletQuantity && item.quantityPerPallet
            ? item.palletQuantity * item.quantityPerPallet
            : 0;
    const originalEachesValue =
        item.originalPalletQuantity && item.quantityPerPallet
            ? item.originalPalletQuantity * item.quantityPerPallet
            : 0;
    const [isBeforeLeadTime, setIsBeforeLeadTime] = useState<boolean>(false);

    useEffect(() => {
        if (item && item.palletQuantity !== undefined) {
            if (
                (item.productStatus === ProductStatus.Inactive ||
                    isDateWithinAtmLockPeriod(
                        moment(activeDate.fullDate),
                        (item.leadTimeWeeks ? item.leadTimeWeeks : 1) * 7
                    ) !== 'after') &&
                item.palletQuantity !== 0
            ) {
                dispatch(updatePalletQuantity(activeDate, shipToId, 0, item));
            } else {
                setPurchaseQty(!isNaN(item.palletQuantity) ? item.palletQuantity : undefined);
            }

            setHasBlankQuantity(isNaN(item.palletQuantity));

            setPalletQuantityState(
                evaluatePalletQuantityValid(
                    item.palletQuantity,
                    item.minimumOrderQuantity!,
                    getFeeStructure(item),
                    moqFeeFloor,
                    moqFeeCeiling
                )
            );
        } else {
            // added item and quantity is still blank
            setHasBlankQuantity(true);
        }

        setIsBeforeLeadTime(
            isDateWithinAtmLockPeriod(
                moment(activeDate.fullDate),
                (item.leadTimeWeeks ? item.leadTimeWeeks : 1) * 7
            ) !== 'after'
        );

        setDisabled(
            isDateWithinAtmLockPeriod(
                moment(activeDate.fullDate),
                (item.leadTimeWeeks ? item.leadTimeWeeks : 1) * 7
            ) !== 'after' || item.productStatus === ProductStatus.Inactive
        );
    }, [item]);

    const handleRemoveParentItem = () => {
        dispatch(deleteProductFromOrder(shipToId, activeDate.fullDate, item.productSku!));
    };

    const setQuantityAndMOQValues = (quantity: number | undefined) => {
        if (item.minimumOrderQuantity && quantity) {
            const minValue = item.moqFees
                ? getMoqFeeFloor(item.moqFees)
                : item.minimumOrderQuantity;
            item.withinRange = minValue <= quantity && quantity < item.minimumOrderQuantity;
            item.minimumMet = quantity >= item.minimumOrderQuantity;
        } else {
            item.minimumMet = quantity !== null && quantity !== undefined && quantity !== 0;
        }
        item.palletQuantity = quantity;
    };

    const palletQuantityInputChange = (count) => {
        if (count === '') {
            setPurchaseQty(undefined);
            return;
        }
        let quantity = 0;
        if (numericRegex(count)) {
            if (!isNaN(parseInt(count))) {
                quantity = parseInt(count);
            }
            setPurchaseQty(!isNaN(quantity) ? quantity : undefined);
        }
    };

    const handlePalletQuantityOnBlur = (value) => {
        const quantity = parseInt(value);
        setQuantityAndMOQValues(!isNaN(quantity) ? quantity : undefined);
        dispatch(updatePalletQuantity(activeDate, shipToId, quantity, item));
    };

    useTranslation();

    function getQuantityFeeVerbiage() {
        return `${moqFeeFloor}-${moqFeeCeiling} PL`;
    }

    function hasPalletQuantityError(palletQuantityState: PalletQuantityState): boolean {
        switch (palletQuantityState) {
            case 'minimumOrderQuantityError':
                return true;
            default:
                return false;
        }
    }

    return (
        <TableRow className={classes.tableRowContainer} data-testid={`product-${item.displayId}`}>
            <TableCell data-testid="display-id">
                <Grid container alignContent="center">
                    <Grid container>
                        <Typography className={classes.bold}>{item.displayId}</Typography>
                    </Grid>
                    <Grid container>
                        <Typography>{item.graphicIdAndVersion}</Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell
                data-testid="product-description"
                className={clsx({
                    [classes.inactiveProduct]: item.productStatus === ProductStatus.Inactive
                })}
            >
                <Grid container>
                    {item.productStatus === ProductStatus.Inactive && (
                        <Grid item>
                            <img
                                data-testid="error-icon-product"
                                src={process.env.PUBLIC_URL + '/assets/Error_Icon.svg'}
                                alt="Error Icon"
                            />
                        </Grid>
                    )}
                    <Grid container>
                        <Typography className={classes.bold}>{item.displayName}</Typography>
                    </Grid>
                    {item.productStatus === ProductStatus.Inactive && (
                        <Grid container className={classes.inputContainer}>
                            <Typography
                                className="helper-text"
                                data-testid="inactive-product-message"
                            >
                                <Trans i18nKey="productIsInactive">
                                    This product is inactive and cannot be ordered.
                                </Trans>
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </TableCell>
            <TableCell
                data-testid="leadtime-information"
                className={clsx({
                    [classes.alertBackground]: isBeforeLeadTime
                })}
            >
                {isBeforeLeadTime && (
                    <img
                        data-testid="info-icon"
                        src={process.env.PUBLIC_URL + '/assets/InfoTriangle_Icon.svg'}
                        alt="Information Icon"
                    />
                )}
                <Typography
                    data-leadtime={leadTime}
                    data-testid="leadtime"
                    className={classes.bold}
                >
                    {`${leadTime} `}
                    {leadTime === 1 ? (
                        <Trans i18nKey="week">Week</Trans>
                    ) : (
                        <Trans i18nKey="weeks">Weeks</Trans>
                    )}
                </Typography>
                {isBeforeLeadTime && (
                    <Typography className={classes.alertText}>
                        <Trans i18nKey="leadTimeExceedsSelected">
                            Lead time exceeds selected week
                        </Trans>
                    </Typography>
                )}
            </TableCell>
            <TableCell data-testid="coating">
                <Typography className={classes.bold}>{item.coating}</Typography>
            </TableCell>
            <TableCell data-testid="original-quantity">
                <Grid container direction="column">
                    <Grid item>
                        <Typography className={classes.originalQuantityPallets}>
                            {formatNumberWithLocale(cultureCode, item.originalPalletQuantity || 0)}{' '}
                            PL
                        </Typography>
                    </Grid>
                    {item.originalPalletQuantity !== undefined && (
                        <Grid item>
                            <Typography
                                className="helper-text"
                                data-testid="original-quantity-eaches"
                            >
                                {formatNumberWithLocale(cultureCode, originalEachesValue)}
                                {' ea.'}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </TableCell>
            <TableCell
                className={clsx(
                    {
                        [classes.feeError]:
                            hasPalletQuantityError(palletQuantityState) || hasBlankQuantity
                    },
                    {
                        [classes.feeWarning]: palletQuantityState === 'feeWarning'
                    },
                    {
                        [classes.alertBackground]: isBeforeLeadTime
                    }
                )}
            >
                <Grid container>
                    {(hasPalletQuantityError(palletQuantityState) || hasBlankQuantity) && (
                        <Grid item>
                            <img
                                data-testid="error-icon"
                                src={process.env.PUBLIC_URL + '/assets/Error_Icon.svg'}
                                alt="Error Icon"
                            />
                        </Grid>
                    )}
                    {palletQuantityState === 'feeWarning' && (
                        <Grid item>
                            <img
                                data-testid="warning-icon"
                                src={process.env.PUBLIC_URL + '/assets/Warning_Icon.svg'}
                                alt="Warning Icon"
                            />
                        </Grid>
                    )}
                    <Grid item className={classes.inputContainer}>
                        <Input
                            id={getPalletQuantityKey(item.productSku!)}
                            disableUnderline={true}
                            fullWidth
                            disabled={disabled}
                            type="text"
                            className={classes.textField}
                            value={purchaseQty !== undefined ? purchaseQty : ''}
                            inputProps={{ 'data-testid': 'quantity-input' }}
                            data-testid={`pallet-quantity-${item.productSku}`}
                            onBlur={(event) => handlePalletQuantityOnBlur(event.target.value)}
                            onChange={(event) => palletQuantityInputChange(event.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <Typography className={classes.pallet}>PL</Typography>
                    </Grid>
                </Grid>
                <Grid container justify="flex-end" className={classes.eachesCountPadding}>
                    <Typography className="helper-text" data-testid="eaches-quantity">
                        {formatNumberWithLocale(cultureCode, eachesValue)}
                        {' ea.'}
                    </Typography>
                </Grid>
                {item.minimumOrderQuantity && item.minimumOrderQuantity > 0 ? (
                    <Grid container className={classes.inputContainer}>
                        <Typography className="helper-text" data-testid="minimum-order-helper">
                            <Trans i18nKey="minimumOrderIs">Minimum Order is</Trans>
                            {` ${item.minimumOrderQuantity} PL`}
                        </Typography>
                    </Grid>
                ) : undefined}
                {hasMoqFees && (
                    <Grid container className={classes.inputContainer}>
                        <Typography className="helper-text" data-testid="fee-warning">
                            <Trans i18nKey="feeForOrders">Fees for orders</Trans>{' '}
                            {getQuantityFeeVerbiage()}
                        </Typography>
                    </Grid>
                )}

                {item.palletsRounded && (
                    <Grid container>
                        <Alert
                            className={classes.informationalAlert}
                            icon={false}
                            data-testid="pallet-alert"
                        >
                            <Typography className={classes.informationAlertText}>
                                <Trans i18nKey="quantityRounded">
                                    Quantity rounded up to the nearest full pallet.
                                </Trans>
                            </Typography>
                        </Alert>
                    </Grid>
                )}
                {hasBlankQuantity && (
                    <Grid container>
                        <Typography className="helper-text" data-testid="blank-quantity-error">
                            <Trans i18nKey="enterAQuantity">Please enter a quantity.</Trans>
                        </Typography>
                    </Grid>
                )}
            </TableCell>
            <TableCell className={classes.alignCenter} data-testid="delete-icon">
                <Button
                    type="button"
                    className={classes.trash}
                    disabled={disabled}
                    onClick={handleRemoveParentItem}
                    data-testid={`delete-product-btn-${item.productSku}`}
                >
                    {disabled && (
                        <img
                            src={process.env.PUBLIC_URL + '/assets/DisabledTrashIcon.svg'}
                            alt="Disabled Trash Icon"
                        />
                    )}
                    {!disabled && (
                        <img src={process.env.PUBLIC_URL + '/assets/Trash.svg'} alt="Trash Icon" />
                    )}
                </Button>
            </TableCell>
        </TableRow>
    );
};

export default MakeItConversionReviewOrderSectionRow;
