import React from 'react';
import { Grid } from '@material-ui/core';
import GraphicsTabs from './components/GraphicsTabs';
import SubheaderPanel from './components/SubheaderPanel';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { Activity } from '../../../utility/auth/useSecurity';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { GraphicDashboardState } from '../../../store/reducers/graphic-dashboard';
import { useTranslation } from 'react-i18next';

const GraphicsDashboardPage = () => {
    const { t } = useTranslation();
    const { loading } = useTypedSelector<GraphicDashboardState>((state) => state.graphicDashboard);

    return (
        <ProcessingPageTemplate
            loading={loading}
            banner={{
                header: t('graphicsSummary', 'Graphics Summary'),
                description: '',
                thinBanner: true,
                displayDropdown: true,
                billToDropdown: true
            }}
            activity={Activity.GraphicsSummary}
            shipTos={false}
        >
            <Grid container>
                <SubheaderPanel />
                <GraphicsTabs />
            </Grid>
        </ProcessingPageTemplate>
    );
};

export default GraphicsDashboardPage;
