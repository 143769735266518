import React, { ReactNode } from 'react';
import { makeStyles, Grid, Typography, GridJustification } from '@material-ui/core';
import { warningLabel, black, boldWeight, warningBorder } from '../../../themes/globalConstants';
import { Alert } from '@material-ui/lab';
import FreightFormulaDropdown from '../../pages/EditShipmentsConfiguration/components/FreightFormulaDropdown';

interface Props {
    warningMessage: ReactNode;
    showWarning: boolean;
    className?: string;
    children?: ReactNode;
    justify?: GridJustification;
    useFormulaDropdown?: boolean;
    maxPallets?: number;
    icon?: ReactNode;
    dataTestId?: string;
}

const useStyles = makeStyles((theme) => ({
    warningContainer: {
        width: '95%',
        border: `1px solid ${warningBorder}`,
        borderRadius: '2px',
        justifyContent: 'left',
        padding: '16px',
        '& .MuiAlert-icon': {
            color: warningBorder,
            display: 'flex',
            alignItems: 'start',
            padding: 0
        },
        '& .MuiAlert-message': {
            padding: 0,
            '& .MuiTypography-root': {
                color: black,
                fontWeight: boldWeight
            }
        },
        backgroundColor: warningLabel
    }
}));

export default function WarningAlert({
    children,
    warningMessage,
    useFormulaDropdown,
    showWarning,
    className,
    justify = 'center',
    maxPallets,
    icon,
    dataTestId
}: Props) {
    const classes = useStyles();
    const warningIcon = (
        <img src={process.env.PUBLIC_URL + '/assets/Warning_Icon.svg'} alt="Warning Icon" />
    );

    return (
        <>
            {showWarning && (
                <Grid container item justify={justify} xs={12}>
                    <Alert
                        icon={icon ?? warningIcon}
                        severity="warning"
                        className={className ?? classes.warningContainer}
                        data-testid={dataTestId ?? 'warning-alert-test-id'}
                    >
                        {useFormulaDropdown && maxPallets ? (
                            <FreightFormulaDropdown maxPallets={maxPallets} />
                        ) : null}
                        <Typography>{warningMessage}</Typography>
                        {children}
                    </Alert>
                </Grid>
            )}
        </>
    );
}
