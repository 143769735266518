import { makeStyles, Menu, MenuItem } from '@material-ui/core';
import React from 'react';
import { Trans } from 'react-i18next';

interface Props {
    onEditCommunication: () => void;
    onEnableCommunication: () => void;
    onDisableCommunication: () => void;
    onMenuBlur: () => void;
    anchorEl: HTMLElement | null;
    row: any;
}

const useStyles = makeStyles(() => ({
    menuWrapper: {
        '& .MuiPopover-paper': {
            marginTop: '3.2em',
            paddingTop: '0.5em',
            paddingBottom: '0.5em',
            marginRight: '2em',
            minWidth: '15em',
            borderTop: '1em'
        }
    }
}));

const ManageCommunicationsContextMenu = ({ onEditCommunication, onEnableCommunication, onDisableCommunication, onMenuBlur, anchorEl, row }: Props) => {
    const classes = useStyles();
    return (
        <Menu
            className={classes.menuWrapper}
            keepMounted
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: -110,
                vertical: 'top'
            }}
            onBlur={onMenuBlur}
        >
            <MenuItem onClick={onEditCommunication}>
                <Trans i18nKey="editMessage">Edit Message</Trans>
            </MenuItem>
            {row.status === 'Inactive' ? (
                <MenuItem onClick={onEnableCommunication} disabled={row.status === 'Active'}>
                    <Trans i18nKey="enableMessage">Enable Message</Trans>
                </MenuItem>
            ) : (
                <MenuItem onClick={onDisableCommunication} disabled={row.status === 'Inactive'}>
                    <Trans i18nKey="disableMessage">Disable Message</Trans>
                </MenuItem>

            )}
        </Menu>
    );
};

export default ManageCommunicationsContextMenu;
