import React, { useState, useEffect } from 'react';
import Link from '../../../reusable/atoms/Link';
import { ProductFeature } from '../../../../store/reducers/product-portfolio';
import { makeStyles, Grid } from '@material-ui/core';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { getMediaPath, getFeatureContent } from '../../../../utility/helpers/content-helpers';
import { medium, regularWeight } from '../../../../themes/globalConstants';

interface Props {
    feature: ProductFeature;
}

const useStyles = makeStyles((theme) => ({
    featureContent: {
        height: '9em',
        width: '9em',
        margin: '0 1.250em 2em 0'
    },
    featureImg: {
        height: '6em',
        width: '100%',
        // width: '10em',
        objectFit: 'cover',
        margin: '0 0 0.875em 0'
    },
    linkText: {
        fontSize: medium,
        fontWeight: regularWeight,
        width: '8.75em'
    }
}));

const ProductFeatureContent = ({ feature }: Props) => {
    const { regionCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const defaultImage = process.env.PUBLIC_URL + '/assets/default-image.jpg';
    const [imageUrl, setImageUrl] = useState(defaultImage);
    const [pdfUrl, setPdfUrl] = useState('/');
    let type =
        getFeatureContent(feature.featureCode) ?? feature.label.toLowerCase().replace(/\s+/g, '');

    useEffect(() => {
        setImageUrl(
            getMediaPath(
                `/CustomerPortalCMS/media/customer-portal-media/Image%20Library/Features/${type}.PNG`
            )
        );
        setPdfUrl(
            getMediaPath(
                `/CustomerPortalCMS/media/customer-portal-media/Regions/${regionCode}/PDF%20Library/Features/${type}.pdf`
            )
        );
    }, [type, regionCode]);

    const handleImgError = () => {
        if (imageUrl.substr(imageUrl.length - 3).toLocaleLowerCase() !== 'jpg') {
            setImageUrl(
                getMediaPath(
                    `/CustomerPortalCMS/media/customer-portal-media/Image%20Library/Features/${type}.jpg`
                )
            );
        } else {
            setImageUrl(defaultImage);
        }
    };

    const classes = useStyles();

    return (
        <>
            <Grid container item xs={12} justify="flex-start" className={classes.featureContent}>
                <img
                    data-testid="feature-image"
                    src={imageUrl}
                    onError={handleImgError}
                    alt="Cannot load source"
                    className={classes.featureImg}
                />
                <Link
                    data-testid="pdf-link"
                    underline="always"
                    className={classes.linkText}
                    url={pdfUrl}
                    target="_blank"
                >
                    {feature.label}
                </Link>
            </Grid>
        </>
    );
};

export default ProductFeatureContent;
