import {
    PRICING_BREAKDOWN_LOADING,
    PRICING_BREAKDOWN_LOADED,
    PRICING_BREAKDOWN_ERROR
} from '../actions/action-types';
import { Account } from '../reducers/customer-context';
import PricingService, { PricingRequest } from '../../utility/services/pricing-service';
import { isBillToAccount } from '../../utility/helpers/admin-helpers';
import { AccountWithParent } from '../reducers/pricing-breakdown';
import { Persona } from '../../utility/auth/useSecurity';

export const loadPricingData = (accounts: Account[]) => {
    return (dispatch, getState) => {
        const { accessToken, permissions } = getState().auth;
        const billToIds = accounts
            .filter((account) => isBillToAccount(account))
            .map((account) => account.accountId);

        let billToWithPermissions: string[] = [];

        if (billToIds?.length && permissions?.length) {
            permissions.forEach((permission) => {
                if (
                    permission.personaId === Persona.AccountAdmin ||
                    permission.personaId === Persona.PricingContact
                ) {
                    permission.accountIds.forEach((accountId) => {
                        if (
                            billToIds.includes(accountId) &&
                            !billToWithPermissions.includes(accountId)
                        ) {
                            billToWithPermissions.push(accountId);
                        }
                    });
                }
            });
        }

        dispatch({ type: PRICING_BREAKDOWN_LOADING });
        PricingService.getPricingData(accessToken, {
            billToIds: billToWithPermissions
        } as PricingRequest)
            .then((response) => {
                let pricingData = {};
                let shipTos = [] as AccountWithParent[];

                response.data.forEach((billToPricing) => {
                    billToPricing.pricingDataByAccounts.forEach((shipToPricing) => {
                        shipTos.push(shipToPricing.account);

                        pricingData[shipToPricing.account.accountId] = shipToPricing.responses.map(
                            (item) => {
                                return {
                                    ...item,
                                    currentBasePrice: parseFloat(item.currentBasePrice),
                                    newBasePrice: parseFloat(item.newBasePrice),
                                    freightChargeFromLocation:
                                        item.freightChargeFromLocation ?? '-',
                                    freightCharges: item.freightCharges
                                        ? parseFloat(item.freightCharges)
                                        : undefined,
                                    newBasePriceWithFreight: item.newBasePriceWithFreight
                                        ? parseFloat(item.newBasePriceWithFreight)
                                        : undefined
                                };
                            }
                        );
                    });
                });

                dispatch({
                    type: PRICING_BREAKDOWN_LOADED,
                    pricingData: pricingData,
                    pricingShipTos: shipTos
                });
            })
            .catch((error) => {
                dispatch({ type: PRICING_BREAKDOWN_ERROR, error });
            });
    };
};
