import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { makeStyles, Typography, TableRow, TableCell, Grid, Input, Link } from '@material-ui/core';
import {
    ballLtGray,
    small,
    white,
    regularWeight,
    shipRed,
    errorBackground,
    warningBorder,
    vanilla,
    boldWeight,
    blackWeight,
    medium,
    informationAlert,
    black,
    disabledGrey
} from '../../../../themes/globalConstants';
import clsx from 'clsx';
import {
    getMoqFeeCeiling,
    getMoqFeeFloor
} from '../../../../utility/helpers/production-order-helpers';
import {
    evaluatePalletQuantityValid,
    getFeeStructure,
    getPalletQuantityKey,
    PalletQuantityState
} from '../../../../utility/helpers/make-it-bulk-helpers';
import { formatNumberWithLocale, numericRegex } from '../../../../utility/helpers/formatters';
import { useDispatch } from 'react-redux';
import {
    deleteProductFromAtmOrder,
    updateMakeItBulkATMItemProductSku,
    updatePalletQuantityATM
} from '../../../../store/actions/makeit-bulk-atm';
import { MakeItBulkATMLineItem } from '../../../../store/reducers/makeit-bulk-atm';
import Button from '../../atoms/Button';
import moment from 'moment';
import { Alert } from '@material-ui/lab';
import MakeItBulkProductIdModal from '../MakeItBulkProductIdModal';
import { MakeItBulkLineItem } from '../../../../store/reducers/makeit-bulk-upload';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import {
    getFirstAvailableDate,
    isDateWithinAtmLockPeriod
} from '../../../../utility/helpers/date-helpers';
import { OrderProductTableRow } from '../../../../store/reducers/orders-dashboard';
import { ProductStatus } from '../../../../utility/services/orders-service';
import { useMakeItBulkReviewATM } from '../../../pages/MakeItBulkReviewATM/context/MakeItBulkReviewATMContext';
import { getLineIsWithinCampaign } from '../../../pages/MakeIt/utilities/make-it-utils';
import { selectIsLargeCustomerAccount } from '../../../../store/selectors';

interface Props {
    item: MakeItBulkATMLineItem;
    showPrevPalletQty: boolean;
    showCsvLineNumber: boolean;
    isDraft: boolean;
    handleGroupRemovalDisable?: () => void;
}

const useStyles = makeStyles((theme) => ({
    tableRowContainer: {
        '& .helper-text': {
            fontWeight: `${regularWeight} !important`,
            fontSize: small
        },
        '& .validation-error-text': {
            fontWeight: `${boldWeight} !important`,
            fontSize: small
        },
        '& .MuiInputBase-input': {
            textAlign: 'right',
            paddingRight: '.25em'
        }
    },
    textField: {
        height: '1.5em',
        border: `1.8px solid ${ballLtGray}`,
        borderRadius: '.25em',
        backgroundColor: white,
        fontWeight: blackWeight
    },
    alignCenter: {
        textAlign: 'center'
    },
    bold: {
        fontWeight: blackWeight
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    quantityInput: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    trash: {
        minWidth: 'unset'
    },
    feeError: {
        border: `1px solid ${shipRed}`,
        borderRight: 'none',
        backgroundColor: errorBackground
    },
    feeWarning: {
        border: `1px solid ${warningBorder}`,
        borderRight: 'none',
        backgroundColor: vanilla
    },
    dupSkuError: {
        border: `1px solid ${shipRed}`,
        backgroundColor: errorBackground
    },
    inactiveProductError: {
        border: `1px solid ${shipRed}`,
        backgroundColor: errorBackground
    },
    pallet: {
        fontSize: medium
    },
    eachesCountPadding: {
        paddingRight: '6.5em'
    },
    informationalAlert: {
        backgroundColor: informationAlert,
        borderRadius: '.125em',
        alignItems: 'center',
        width: '95%',
        maxHeight: '2em'
    },
    informationAlertText: {
        fontWeight: boldWeight,
        fontSize: small,
        color: black
    },
    originalQuantityPallets: {
        fontWeight: blackWeight
    },
    link: {
        fontSize: small,
        fontWeight: regularWeight,
        '&:hover': {
            cursor: 'pointer'
        }
    },
    informational: {
        backgroundColor: informationAlert
    },
    informationalText: {
        fontStyle: 'italic',
        fontSize: small,
        fontWeight: boldWeight
    },
    disabledQuantity: {
        color: disabledGrey
    },
    campaignPending: {
        color: black,
        fontStyle: 'italic',
        fontWeight: regularWeight
    }
}));

const MakeItReviewOrderSectionRow = ({
    item,
    showPrevPalletQty,
    showCsvLineNumber,
    isDraft,
    handleGroupRemovalDisable
}: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [purchaseQty, setPurchaseQty] = useState<number | undefined>(undefined);
    const [disabled, setDisabled] = useState<boolean>(false);
    // Warning and Error logic
    const hasMoqFees = item.moqFees && item.moqFees.length > 0;
    const moqFeeFloor = getMoqFeeFloor(item.moqFees);
    const moqFeeCeiling = getMoqFeeCeiling(item.moqFees);
    const [palletQuantityState, setPalletQuantityState] = useState<PalletQuantityState>(undefined);
    const { activeDate, shipToId } = useMakeItBulkReviewATM();
    const [hasBlankQuantity, setHasBlankQuantity] = useState<boolean>(false);
    const [productIdOpen, setProductIdOpen] = useState<boolean>(false);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const eachesValue =
        item.palletQuantity && item.quantityPerPallet
            ? item.palletQuantity * item.quantityPerPallet
            : 0;
    const originalEachesValue =
        item.originalPalletQuantity && item.quantityPerPallet
            ? item.originalPalletQuantity * item.quantityPerPallet
            : 0;
    const [availableQuantityPalletsValue, setAvailableQuantityPalletsValue] = useState<number>(0);
    const [availableQuantityEachesValue, setAvailableQuantityEachesValue] = useState<number>(0);
    const uniqueProductId = !!item.isCustomerProductIdDistinct;
    const customerProductId = item.customerProductId;
    const candidateProductSkus = item.candidateProductSkus;
    const isCampaignAndOutsideOrderingWindow = !getLineIsWithinCampaign(
        item as OrderProductTableRow
    );
    const isInactiveProduct = item.productStatus
        ? item.productStatus === ProductStatus.Inactive
        : item.status === ProductStatus.Inactive;

    const isLargeCustomer = useTypedSelector<boolean>(selectIsLargeCustomerAccount);
    const [campaignPending, setCampaignPending] = useState<boolean>(false);

    const setQuantityAndMOQValues = useCallback(
        (quantity: number | undefined) => {
            if (item.minimumOrderQuantity && quantity) {
                const minValue = item.moqFees
                    ? getMoqFeeFloor(item.moqFees)
                    : item.minimumOrderQuantity;
                item.withinRange = minValue <= quantity && quantity < item.minimumOrderQuantity;
                item.minimumMet = quantity >= item.minimumOrderQuantity;
            } else {
                item.minimumMet = quantity !== null && quantity !== undefined && quantity !== 0;
            }
            item.palletQuantity = quantity;
        },
        [item]
    );

    const handlePalletQuantityOnBlur = useCallback(
        (value) => {
            const quantity = parseInt(value);
            setQuantityAndMOQValues(!isNaN(quantity) ? quantity : undefined);
            dispatch(updatePalletQuantityATM(activeDate, shipToId, quantity, item));
        },
        [dispatch, activeDate, item, setQuantityAndMOQValues, shipToId]
    );

    const assignAvailableBalance = useCallback(
        (requestedQuantity: number | undefined) => {
            const currentPurchaseQty = requestedQuantity ?? 0;
            const availableBalance = (item.availableQuantity ?? 0) + currentPurchaseQty;
            setAvailableQuantityPalletsValue(availableBalance);
            setAvailableQuantityEachesValue((item.quantityPerPallet ?? 0) * availableBalance);
        },
        [item.availableQuantity, item.quantityPerPallet]
    );

    useEffect(() => {
        if (!!item.campaignTiming) {
            setCampaignPending(item.leadTimeWeeks === undefined);
        }
    }, [item]);

    useEffect(() => {
        if (item && item.palletQuantity !== undefined) {
            setPurchaseQty(!isNaN(item.palletQuantity) ? item.palletQuantity : undefined);
            setHasBlankQuantity(isNaN(item.palletQuantity));
            setPalletQuantityState(
                evaluatePalletQuantityValid(
                    item.palletQuantity,
                    item.minimumOrderQuantity!,
                    getFeeStructure(item),
                    moqFeeFloor,
                    moqFeeCeiling
                )
            );
        } else {
            // added item and quantity is still blank
            setHasBlankQuantity(true);
        }
        if (showCsvLineNumber) {
            setDisabled(
                isDateWithinAtmLockPeriod(
                    moment(activeDate.fullDate),
                    (item.leadTimeWeeks ? item.leadTimeWeeks : 1) * 7
                ) !== 'after' || isCampaignAndOutsideOrderingWindow
            );
        }
        if (isInactiveProduct) {
            if (item.palletQuantity !== 0) {
                palletQuantityInputChange('0');
                handlePalletQuantityOnBlur('0');
            }
            setDisabled(true);
        }
        if (!isLargeCustomer && !!activeDate) {
            if (
                moment(getFirstAvailableDate(item.leadTimeWeeks ?? 1)).isAfter(
                    moment(activeDate.fullDate)
                )
            ) {
                item.isRequestedDateAfterLeadTime = true;
                if (item.palletQuantity !== 0) {
                    handlePalletQuantityOnBlur('0');
                    palletQuantityInputChange('0');
                }
            }
        }
    }, [
        item,
        showCsvLineNumber,
        isLargeCustomer,
        activeDate,
        handlePalletQuantityOnBlur,
        isCampaignAndOutsideOrderingWindow,
        isInactiveProduct,
        moqFeeCeiling,
        moqFeeFloor
    ]);

    useEffect(() => {
        assignAvailableBalance(purchaseQty);
    }, [assignAvailableBalance, purchaseQty]);

    useEffect(() => {
        if (handleGroupRemovalDisable) {
            if (disabled || item.hasLinkedDeliveryOrder || item.isRequestedDateAfterLeadTime) {
                handleGroupRemovalDisable();
            }
        }
    }, [handleGroupRemovalDisable, disabled, item]);

    const handleRemoveParentItem = () => {
        let dateWeek = moment.utc(activeDate.fullDate).startOf('day').format();
        dateWeek = dateWeek.substring(0, dateWeek.length - 1); // Match format to weekStart date
        dispatch(deleteProductFromAtmOrder(item.productSku!, dateWeek, shipToId));
    };

    const palletQuantityInputChange = (count) => {
        if (count === '') {
            setPurchaseQty(undefined);
            return;
        }
        let quantity = 0;
        if (numericRegex(count)) {
            if (!isNaN(parseInt(count))) {
                quantity = parseInt(count);
            }
            setPurchaseQty(!isNaN(quantity) ? quantity : undefined);
        }
    };

    const onOpenProductId = () => {
        setProductIdOpen(true);
    };

    const onCloseProductId = () => {
        setProductIdOpen(false);
    };

    useTranslation();

    function getQuantityFeeVerbiage() {
        return `${moqFeeFloor}-${moqFeeCeiling} PL`;
    }

    function hasPalletQuantityError(palletQuantityState: PalletQuantityState): boolean {
        switch (palletQuantityState) {
            case 'minimumOrderQuantityError':
                return true;
            default:
                return false;
        }
    }

    function handleProductSkuSelection(product: MakeItBulkLineItem) {
        //Set Pallet Quantity
        let palletQuantity = item.palletQuantity ? item.palletQuantity : 0;
        if (item.originalQuantity && product.quantityPerPallet) {
            palletQuantity = parseInt(item.originalQuantity) / product.quantityPerPallet;
        }
        dispatch(
            updateMakeItBulkATMItemProductSku(
                activeDate,
                shipToId,
                item.originalCsvLineNumber!,
                product,
                palletQuantity
            )
        );
    }

    return (
        <>
            <TableRow
                className={classes.tableRowContainer}
                data-testid={`product-${item.displayId}`}
            >
                {showCsvLineNumber && (
                    <TableCell data-testid="csv-line">
                        <Grid container alignContent="center">
                            <Grid container>
                                <Typography className={classes.bold}>
                                    {item.originalCsvLineNumber}
                                </Typography>
                            </Grid>
                        </Grid>
                    </TableCell>
                )}
                <TableCell
                    data-testid="display-id"
                    className={clsx({
                        [classes.dupSkuError]: !uniqueProductId,
                        [classes.inactiveProductError]: isInactiveProduct
                    })}
                >
                    {uniqueProductId && !isInactiveProduct ? (
                        <Grid container alignContent="center">
                            <Grid container>
                                <Typography className={classes.bold}>
                                    {item.customerProductId ? item.customerProductId : '-'}
                                </Typography>
                            </Grid>
                            <Grid container>
                                <Typography className={classes.bold}>{item.displayId}</Typography>
                            </Grid>
                            <Grid container>
                                <Typography>{item.graphicIdAndVersion}</Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container alignContent="center">
                            <Grid container>
                                <img
                                    src={process.env.PUBLIC_URL + '/assets/Error_Icon.svg'}
                                    alt="Error Icon"
                                />
                                <Typography>{item.displayId}</Typography>
                            </Grid>
                            {!uniqueProductId && (
                                <Grid container>
                                    <Typography
                                        className="helper-text"
                                        data-testid="dup-product-id-helper"
                                    >
                                        <Trans i18nKey="dupProductId">
                                            Duplicate Product IDs found
                                        </Trans>
                                    </Typography>
                                </Grid>
                            )}
                            {!uniqueProductId && (
                                <Grid container>
                                    <Link
                                        className={classes.link}
                                        underline="always"
                                        data-testid="dup-product-id-link"
                                        onClick={onOpenProductId}
                                    >
                                        <Typography className="helper-text">
                                            <Trans i18nKey="clickToChooseCorrect">
                                                Click to choose the correct one.
                                            </Trans>
                                        </Typography>
                                    </Link>
                                </Grid>
                            )}
                            {isInactiveProduct && (
                                <Grid container>
                                    <Typography
                                        className="helper-text"
                                        data-testid="inactive-product-id-helper"
                                    >
                                        <Trans i18nKey="inactiveProductId">
                                            This product is inactive and cannot be ordered.
                                        </Trans>
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    )}
                </TableCell>
                <TableCell
                    data-testid="product-description"
                    className={clsx({ [classes.informational]: item.hasLinkedDeliveryOrder })}
                >
                    <Grid container alignContent="center">
                        {item.hasLinkedDeliveryOrder && (
                            <Grid item xs={12}>
                                <img
                                    src={process.env.PUBLIC_URL + '/assets/InfoTriangle_Icon.svg'}
                                    alt="Informational Alert Icon"
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Typography className={classes.bold}>{item.displayName}</Typography>
                        </Grid>
                        {item.hasLinkedDeliveryOrder && (
                            <Grid item xs={12}>
                                <Typography
                                    className={classes.informationalText}
                                    data-testid="linked-shipment-helper"
                                >
                                    <Trans i18nKey="linkedShipmentHelper">
                                        Associated shipment. See Make It Details.
                                    </Trans>
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </TableCell>
                <TableCell data-testid="coating">
                    <Typography className={classes.bold}>{item.coating}</Typography>
                </TableCell>
                <TableCell
                    data-testid="leadtime-information"
                    className={clsx({ [classes.informational]: item.isRequestedDateAfterLeadTime })}
                >
                    {campaignPending ? (
                        <Grid container alignContent="center">
                            <Typography className={classes.campaignPending}>
                                <Trans i18nKey="campaignPending">Campaign Date Pending</Trans>
                            </Typography>
                        </Grid>
                    ) : (
                        <Grid container alignContent="center">
                            {item.isRequestedDateAfterLeadTime && (
                                <Grid item xs={12}>
                                    <img
                                        src={
                                            process.env.PUBLIC_URL + '/assets/InfoTriangle_Icon.svg'
                                        }
                                        alt="Informational Alert Icon"
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Typography
                                    data-leadtime={item.leadTimeWeeks}
                                    data-testid="leadtime"
                                    className={classes.bold}
                                >
                                    {`${item.leadTimeWeeks} `}
                                    {item.leadTimeWeeks === 1 ? (
                                        <Trans i18nKey="week">Week</Trans>
                                    ) : (
                                        <Trans i18nKey="weeks">Weeks</Trans>
                                    )}
                                </Typography>
                            </Grid>
                            {item.isRequestedDateAfterLeadTime && (
                                <Grid item xs={12}>
                                    <Typography
                                        className={classes.informationalText}
                                        data-testid="lead-time-error"
                                    >
                                        <Trans i18nKey="leadTimeExceedsSelected">
                                            Lead time exceeds selected week
                                        </Trans>
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    )}
                </TableCell>
                {isLargeCustomer && !isDraft && (
                    <TableCell data-testid="available-quantity">
                        <Grid container direction="column">
                            <Grid item>
                                <Typography className={classes.originalQuantityPallets}>
                                    {formatNumberWithLocale(
                                        cultureCode,
                                        availableQuantityPalletsValue
                                    )}{' '}
                                    PL
                                </Typography>
                            </Grid>
                            {item.availableQuantity !== undefined && (
                                <Grid item>
                                    <Typography
                                        className="helper-text"
                                        data-testid="available-quantity-eaches"
                                    >
                                        {formatNumberWithLocale(
                                            cultureCode,
                                            availableQuantityEachesValue
                                        )}
                                        {' ea.'}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </TableCell>
                )}
                {showPrevPalletQty && (
                    <TableCell data-testid="original-quantity">
                        <Grid container direction="column">
                            <Grid item>
                                <Typography className={classes.originalQuantityPallets}>
                                    {formatNumberWithLocale(
                                        cultureCode,
                                        item.originalPalletQuantity || 0
                                    )}{' '}
                                    PL
                                </Typography>
                            </Grid>
                            {item.originalPalletQuantity !== undefined && (
                                <Grid item>
                                    <Typography
                                        className="helper-text"
                                        data-testid="original-quantity-eaches"
                                    >
                                        {formatNumberWithLocale(cultureCode, originalEachesValue)}
                                        {' ea.'}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </TableCell>
                )}
                <TableCell
                    className={clsx({
                        [classes.feeError]:
                            hasPalletQuantityError(palletQuantityState) || hasBlankQuantity,
                        [classes.feeWarning]: palletQuantityState === 'feeWarning',
                        [classes.disabledQuantity]:
                            disabled ||
                            item.isRequestedDateAfterLeadTime ||
                            item.hasLinkedDeliveryOrder
                    })}
                >
                    <Grid container alignContent="center">
                        {(hasPalletQuantityError(palletQuantityState) || hasBlankQuantity) && (
                            <Grid item xs={12}>
                                <img
                                    data-testid="error-icon"
                                    src={process.env.PUBLIC_URL + '/assets/Error_Icon.svg'}
                                    alt="Error Icon"
                                />
                            </Grid>
                        )}
                        {palletQuantityState === 'feeWarning' && (
                            <Grid item xs={12}>
                                <img
                                    data-testid="warning-icon"
                                    src={process.env.PUBLIC_URL + '/assets/Warning_Icon.svg'}
                                    alt="Warning Icon"
                                />
                            </Grid>
                        )}
                        <Grid container justify="flex-end" spacing={1}>
                            <Grid item xs={9}>
                                <Input
                                    id={getPalletQuantityKey(item.productSku!)}
                                    disableUnderline={true}
                                    disabled={
                                        disabled ||
                                        item.isRequestedDateAfterLeadTime ||
                                        item.hasLinkedDeliveryOrder ||
                                        campaignPending
                                    }
                                    type="text"
                                    className={classes.textField}
                                    value={purchaseQty !== undefined ? purchaseQty : ''}
                                    inputProps={{ 'data-testid': 'quantity-input' }}
                                    data-testid={`pallet-quantity-${item.productSku}`}
                                    onBlur={(event) =>
                                        handlePalletQuantityOnBlur(event.target.value)
                                    }
                                    onChange={(event) =>
                                        palletQuantityInputChange(event.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Typography className={classes.pallet}>PL</Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-end">
                            <Typography className="helper-text" data-testid="eaches-quantity">
                                {formatNumberWithLocale(cultureCode, eachesValue)}
                                {' ea.'}
                            </Typography>
                        </Grid>
                        {item.minimumOrderQuantity && item.minimumOrderQuantity > 0 && (
                            <Grid item xs={12}>
                                <Typography
                                    className="helper-text"
                                    data-testid="minimum-order-helper"
                                >
                                    <Trans i18nKey="minimumOrderIs">Minimum Order is</Trans>
                                    {` ${item.minimumOrderQuantity} PL`}
                                </Typography>
                            </Grid>
                        )}
                        {hasMoqFees && (
                            <Grid item xs={12}>
                                <Typography className="helper-text" data-testid="fee-warning">
                                    <Trans i18nKey="feeForOrders">Fees for orders</Trans>{' '}
                                    {getQuantityFeeVerbiage()}
                                </Typography>
                            </Grid>
                        )}
                        {item.palletsRounded && (
                            <Grid item xs={12}>
                                <Alert
                                    className={classes.informationalAlert}
                                    icon={false}
                                    data-testid="pallet-alert"
                                >
                                    <Typography className={classes.informationAlertText}>
                                        <Trans i18nKey="quantityRounded">
                                            Quantity rounded up to the nearest full pallet.
                                        </Trans>
                                    </Typography>
                                </Alert>
                            </Grid>
                        )}
                        {hasBlankQuantity && (
                            <Grid item xs={12}>
                                <Typography
                                    className="helper-text"
                                    data-testid="blank-quantity-error"
                                >
                                    <Trans i18nKey="enterAQuantity">Please enter a quantity.</Trans>
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </TableCell>
                <TableCell align="center" data-testid="delete-icon">
                    <Button
                        type="button"
                        className={classes.trash}
                        disabled={
                            disabled ||
                            item.hasLinkedDeliveryOrder ||
                            item.isRequestedDateAfterLeadTime
                        }
                        onClick={handleRemoveParentItem}
                        data-testid={`delete-product-btn-${item.productSku}`}
                    >
                        {(disabled ||
                            item.hasLinkedDeliveryOrder ||
                            item.isRequestedDateAfterLeadTime) && (
                            <img
                                src={process.env.PUBLIC_URL + '/assets/DisabledTrashIcon.svg'}
                                alt="Disabled Trash Icon"
                            />
                        )}
                        {!(
                            disabled ||
                            item.hasLinkedDeliveryOrder ||
                            item.isRequestedDateAfterLeadTime
                        ) && (
                            <img
                                src={process.env.PUBLIC_URL + '/assets/Trash.svg'}
                                alt="Trash Icon"
                            />
                        )}
                    </Button>
                </TableCell>
            </TableRow>
            <MakeItBulkProductIdModal
                open={productIdOpen}
                customerProductId={customerProductId!}
                candidateProductSkus={candidateProductSkus!}
                onClose={onCloseProductId}
                isAtm={true}
                onAcceptProductSku={handleProductSkuSelection}
            />
        </>
    );
};

export default MakeItReviewOrderSectionRow;
