import React, { ReactNode, useEffect, useState } from 'react';
import { blackWeight, boldWeight, small } from '../../../../themes/globalConstants';
import { Grid, makeStyles } from '@material-ui/core';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import TranslationService from '../../../../utility/services/translation-service';
import DataValidationRow from './DataValidationRow';
import { generateKey } from '../../../../utility/helpers/order-helpers';
import DataValidationColumns from './DataValidationColumns';

export interface DataValidation {
    index?: number;
    validationField?: ReactNode;
    validationFormat?: ReactNode;
    validationRule?: ReactNode;
    validationExample?: ReactNode;
    hide?: boolean;
}

interface Props {
    title: string;
    rows?: DataValidation[];
}

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        width: '100%',
        marginTop: '3em',
        padding: '2em',
        borderRadius: 4,
        '& .MuiToolbar-root': {
            minHeight: 0,
            padding: '0 0.125em',
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                }
            },
            '& > h2': {
                fontSize: '1.5em',
                fontWeight: boldWeight,
                color: theme.palette.secondary.main,
                paddingLeft: '0.4em',
                fontFamily: 'Lato',
                minWidth: '12em'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                width: '25%',
                fontSize: small,
                fontWeight: blackWeight,
                textTransform: 'uppercase',
                color: theme.palette.secondary.main,
                paddingLeft: '1em'
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    }
}));

const DataValidationRule = ({ row }: { row: Partial<DataValidation> }) => {
    if (row) {
        return <DataValidationRow row={row as DataValidation} />;
    } else {
        return null;
    }
};

const DataValidationGrid = ({ title, rows }: Props) => {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [columns, setColumns] = useState<any>([]);

    const options = new ToolbarOptions({
        title: TranslationService.getTranslatedText(cultureCode, title),
        advancePagination: false,
        exportButton: false,
        printButton: false,
        searchText: false,
        enablePagination: false
    });

    useEffect(() => {
        setColumns(DataValidationColumns.getDataValidationColumns(cultureCode));
    }, [cultureCode]);

    return (
        <Grid className={classes.tableWrapper} key={generateKey('data-validation-grid')}>
            {rows && (
                <DataGrid
                    gridName="Data Validation Grid"
                    columns={columns}
                    dataSource={rows}
                    rowComponent={DataValidationRule}
                    toolbarOptions={options}
                />
            )}
        </Grid>
    );
};

export default DataValidationGrid;
