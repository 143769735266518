import React, { useEffect, useState } from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import { Trans, useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
    ballGray,
    ballDrkBlue,
    xxl,
    blackWeight,
    boldWeight,
    medium,
    vanilla,
    regularWeight,
    black,
    fullTruckPallets
} from '../../../../themes/globalConstants';
import { BulkUploadShipment } from '../../../../store/reducers/bulk-upload';
import {
    Account,
    CustomerContextState,
    ModeOfTransport
} from '../../../../store/reducers/customer-context';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { AuthState } from '../../../../store/reducers/auth';
import { Persona } from '../../../../utility/auth/useSecurity';
import WarningAlert from '../../../reusable/atoms/WarningAlert';
import { isPersonaAccount } from '../../../../utility/helpers/user-helpers';
import {
    getFormattedSelectedQuantityUnit,
    getPalletsPerTruck
} from '../../../../utility/helpers/shipment-helpers';
import { selectIsLargeCustomer, selectIsLargeCustomerAccount } from '../../../../store/selectors';
import { enUS } from '../../../../utility/translations/locales';

interface Props {
    currentShipment: BulkUploadShipment;
    shipmentIndex: number;
    accountInformation: Account;
}

const useStyles = makeStyles((theme) => ({
    main: {
        padding: '1em 0.5em 1.5em 0.5em',
        width: '100%'
    },
    cardSubheader: {
        color: ballGray
    },
    dateDescription: {
        color: ballGray,
        display: 'flex',
        alignItems: 'center'
    },
    altDescriptionColor: {
        color: ballDrkBlue
    },
    dateDescriptionMain: {
        fontSize: xxl,
        fontWeight: blackWeight
    },
    dateDescriptionSubheader: {
        fontSize: medium,
        fontWeight: regularWeight
    },
    subheaderColor: {
        color: black,
        opacity: '40%'
    },
    cardDetailsWrapper: {
        '& .MuiGrid-item': {
            paddingLeft: '1em'
        }
    },
    warningContainer: {
        width: '98%'
    },
    warning: {
        padding: '.125em 0',
        backgroundColor: vanilla,
        justifyContent: 'center',
        marginLeft: '1em'
    },
    warningText: {
        textAlign: 'center',
        fontSize: medium,
        fontWeight: boldWeight
    }
}));

export default function DeliveryBulkSubheader({
    currentShipment,
    shipmentIndex,
    accountInformation
}: Props) {
    const classes = useStyles();
    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);
    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { t } = useTranslation();
    const [isCanType, setIsCanType] = useState<boolean>(true);
    const [truckWarning, setTruckWarning] = useState<boolean>(false);
    const [isCopacker, setIsCopacker] = useState<boolean>(false);
    const titleDayAndTime = moment(currentShipment.deliveryDateTime).format('MM/DD/YYYY h:mm A');
    const deliveryTransportType =
        !accountInformation.modeOfTransport ||
        accountInformation.modeOfTransport === ModeOfTransport.Delivery;
    const [itemsEachesTotal, setItemsEachesTotal] = useState<number>(0);
    const isLargeCustomerAccount = useTypedSelector<boolean>(selectIsLargeCustomerAccount);
    const isLargeCustomer = useTypedSelector<boolean>(selectIsLargeCustomer);
    const maxPallets = getPalletsPerTruck(isLargeCustomer, isCopacker, isLargeCustomerAccount);

    const getQuantityString = () => {
        let quantityString = `${
            isCanType ? currentShipment.canQuantityPallets : currentShipment.endQuantityPallets
        } PL`;
        if (!isNaN(itemsEachesTotal)) {
            quantityString = `${quantityString} / ${Number(
                itemsEachesTotal
            ).toLocaleString()} ${getFormattedSelectedQuantityUnit('eaches')}`;
        }
        return quantityString;
    };

    useEffect(() => {
        if (currentShipment.loads && currentShipment.loads?.length) {
            const eachesTotal = currentShipment.loads.reduce((prev, curr) => {
                return (prev += curr.palletQuantity * curr.quantityPerPallet!);
            }, 0);
            setItemsEachesTotal(eachesTotal);
        } else {
            setItemsEachesTotal(0);
        }
    }, [currentShipment.loads]);

    useEffect(() => {
        if (currentShipment.shipmentType && currentShipment.shipmentType === 'END') {
            setIsCanType(false);
        } else {
            setIsCanType(true);
        }
        if (
            isCanType &&
            currentShipment.canQuantityPallets &&
            currentShipment.canQuantityPallets < fullTruckPallets &&
            deliveryTransportType
        ) {
            setTruckWarning(true);
        } else {
            setTruckWarning(false);
        }
        // Runs only once
    }, []);

    useEffect(() => {
        if (shipToAccounts && shipToAccounts.length > 0 && permissions) {
            // check to see if the user is a co-packer on the selected ship to
            const isCopackerCheck = isPersonaAccount(
                permissions,
                Persona.CoPacker,
                accountInformation.accountId
            );
            setIsCopacker(!!isCopackerCheck);
        }
    }, [shipToAccounts, permissions, accountInformation.accountId]);

    return (
        <Grid container>
            <Grid
                container
                item
                xs={12}
                className={classes.main}
                key={`bulk_shipment_subheader_${shipmentIndex}`}
            >
                <Grid container item md={7} className={classes.dateDescription}>
                    <Grid item xs={12}>
                        {currentShipment.isDeleted && (
                            <Typography
                                className={clsx(
                                    classes.dateDescriptionMain,
                                    classes.altDescriptionColor
                                )}
                            >
                                <Trans i18nKey="shipmentDeleted">Shipment Deleted</Trans>
                            </Typography>
                        )}
                        <Typography
                            className={clsx(
                                {
                                    [classes.dateDescriptionMain]: !currentShipment.isDeleted
                                },
                                {
                                    [classes.altDescriptionColor]: !currentShipment.isDeleted
                                },
                                {
                                    [classes.dateDescriptionSubheader]: currentShipment.isDeleted
                                },
                                {
                                    [classes.subheaderColor]: currentShipment.isDeleted
                                }
                            )}
                            data-testid={'arrival-time'}
                        >
                            <Trans i18nKey="deliveryDate">Delivery Date</Trans>
                            {': '}
                            {titleDayAndTime}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item md={5} className={classes.cardDetailsWrapper}>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2" className={classes.cardSubheader}>
                            <Trans i18nKey="type">Type</Trans>
                        </Typography>
                        <Typography>
                            {isCanType ? (
                                <Trans i18nKey="cansBottles">Cans/Bottles</Trans>
                            ) : (
                                <Trans i18nKey="endsClosures">Ends/Closures</Trans>
                            )}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2" className={classes.cardSubheader}>
                            <Trans i18nKey="products">Products</Trans>
                        </Typography>
                        <Typography data-testid={'product-quantity'}>
                            {currentShipment.loads?.length}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle2" className={classes.cardSubheader}>
                            <Trans i18nKey="quantity">Quantity</Trans>
                        </Typography>
                        <Typography data-testid={'pallet-quantity'}>
                            {getQuantityString()}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            {!isCopacker && (
                <Grid container item xs={12} data-testid="ltl-warning">
                    <WarningAlert
                        showWarning={truckWarning}
                        useFormulaDropdown={true}
                        maxPallets={maxPallets}
                        warningMessage={t('atmFreightWeightWarning', enUS.atmFreightWeightWarning)}
                    ></WarningAlert>
                </Grid>
            )}
        </Grid>
    );
}
