/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../store/reducers/reducer';
import {
    ballBlue,
    black,
    blackWeight,
    boldWeight,
    red,
    small,
    white
} from '../../../themes/globalConstants';
import { Activity } from '../../../utility/auth/useSecurity';
import { ToggleAll } from '../../../utility/helpers/order-helpers';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import {
    groupOrdersBySnoSku,
    regroupOrdersBySnoSku
} from '../../../utility/helpers/make-it-bulk-helpers';
import { CustomerContextState, ProdOrderType } from '../../../store/reducers/customer-context';
import ConfirmationModal from '../../reusable/molecules/ConfirmationModal';
import { Prompt, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
    refreshPlanItBulkState,
    resetPlanItBulkState,
    saveOrdersBySnoSku,
    submitForecastOrders,
    updateExpandAll,
    updatePlanItCustomerProductionOrderNumber
} from '../../../store/actions/planit-bulk-atm';
import { getDateProperties, DateProperties } from '../../../utility/helpers/make-it-bulk-helpers';
import { FormattedWeek } from '../MakeItBulkReviewATM/models/FormattedWeek';
import ShipToDropdown from '../MakeItBulkReviewATM/components/ShipToDropdown';
import ValidationFilterMenu from '../../reusable/molecules/ValidationFilterMenu';
import { enUS } from '../../../utility/translations/locales';
import { FormattedAccount } from '../MakeItBulkReviewATM/models/FormattedAccount';
import ErrorAlert from '../../reusable/atoms/ErrorAlert';
import WeekSelectorDropdown from '../MakeItBulkReviewATM/components/WeekSelectorDropdown';
import { RouterConfirmationOverride } from '../../../App';
import { DateState } from '../MakeItBulkReviewATM/components/MakeItBulkReviewATMDateTab';
import { MakeItBulkReviewATMProvider } from '../MakeItBulkReviewATM/context/MakeItBulkReviewATMContext';
import {
    BulkUploadPlanItATMState,
    PlanItBulkATMLinesBySnoSku,
    PlanItBulkATMOrderByWeekAndSku
} from '../../../store/reducers/planit-bulk-atm';
import PlanItBulkReviewOrderSection from './components/PlanItBulkReviewOrderSection';
import {
    selectIsImpersonation,
    selectIsLargeCustomer,
    selectIsLargeCustomerAccount,
    selectIsPlanItOnly
} from '../../../store/selectors';
import ImpersonationWarning from '../../reusable/molecules/ImpersonationWarning';
import { useQuery } from '../../../utility/helpers/query-helpers';
import { setUseSummaryStoredSearch } from '../../../store/actions/make-it-summary';
import AddProductsModal from '../../reusable/molecules/AddProductsModal/AddProductsModal';

const useStyles = makeStyles((theme) => ({
    actionBtn: {
        height: '3.375em',
        width: '13.75em',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom'
    },
    dateSelectSection: {
        paddingTop: '40px'
    },
    viewAtmTitle: {
        fontWeight: blackWeight,
        color: theme.palette.secondary.main,
        textTransform: 'capitalize'
    },
    dropdownSelector: {
        display: 'flex'
    },
    selector: {
        display: 'flex',
        marginTop: '1em'
    },
    btn: {
        padding: 0,
        minWidth: 'auto',
        fontSize: small,
        fontWeight: boldWeight,
        margin: '0 0.5em'
    },
    expandCollapseWrapper: {
        alignItems: 'center',
        color: ballBlue
    },
    shipToTextContainer: {
        margin: '0.5em 0 1.688em 0'
    },
    shipToWarningText: {
        fontSize: small,
        fontWeight: boldWeight
    },
    addBtn: {
        marginTop: '19px'
    },
    fieldInput: {
        width: '360px',
        height: '100%',
        marginRight: '0.25em',
        marginBottom: '1em',

        '& .MuiInputBase-root': {
            border: '1px solid',
            borderColor: theme.palette.secondary.light,
            backgroundColor: white,
            height: '100%',
            padding: '0.5em',
            '&:before': {
                display: 'none'
            }
        }
    },
    poLabel: {
        color: black,
        fontSize: '18px',
        fontWeight: 'bold',
        marginTop: '2em',
        marginBottom: '.5em'
    },
    actionBar: {
        margin: '1.5em 0',
        padding: '0'
    },
    errorMessage: {
        fontSize: 12,
        fontWeight: boldWeight,
        color: red
    }
}));

export default function PlanItBulkReview() {
    const query = useQuery();
    const from = query.get('from');
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const {
        forecastOrders,
        ordersLoaded,
        submitForecastOrdersSubmissionState,
        error,
        isEdit,
        loaded,
        unrecognizedProducts
    } = useTypedSelector<BulkUploadPlanItATMState>((state) => state.bulkUploadPlanIt);
    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );

    const hasOnlyPlanItAccounts = useTypedSelector<boolean>(selectIsPlanItOnly);

    const [fromSummary, setFromSummary] = useState<boolean>(from === 'summary');
    const [formattedWeekList, setFormattedWeekList] = useState<FormattedWeek[]>();
    const [activeDate, setActiveDate] = useState<DateProperties>({
        fullDate: '',
        week: '',
        year: ''
    });
    const [ordersBySnoSku, setOrdersBySnoSku] = useState<PlanItBulkATMOrderByWeekAndSku[]>();
    const [activeDateOrders, setActiveDateOrders] = useState<PlanItBulkATMOrderByWeekAndSku[]>();
    const [shipToAccountsList, setShipToAccountsList] = useState<FormattedAccount[]>([]);

    const [showErrors, setShowErrors] = useState<boolean>(false);
    const [showWarnings, setShowWarnings] = useState<boolean>(false);
    const [showAll, setShowAll] = useState<boolean>(true);

    const [selectedShipTo, setSelectedShipTo] = useState<string>();
    const [openBackModal, setOpenBackModal] = useState<boolean>(false);
    const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
    const [expandAll, setExpandAll] = useState<ToggleAll>({ toggle: false });
    const [orderHasErrors, setOrderHasErrors] = useState<boolean>(true);
    const [orderHasWarnings, setOrderHasWarnings] = useState<boolean>(true);
    const [weeksHasErrors, setWeeksHasErrors] = useState<boolean>(false);
    const [orderValidated, setOrderValidated] = useState<boolean>(false);

    const [allowNavigation, setAllowNavigation] = useState<boolean>(false);
    const [lastLocationPath, setLastLocationPath] = useState<string>('');
    const [openBlockedNavigationModal, setOpenBlockedNavigationModal] = useState<boolean>(false);
    const isImpersonation = useTypedSelector<boolean>(selectIsImpersonation);
    const [showImpersonationWarning, setShowImpersonationWarning] = useState<boolean>(false);

    const [showCSVWarning, setShowCSVWarning] = useState<boolean>(false);

    const [showCampaigns, setShowCampaigns] = useState<boolean>(false);

    const [showAlerts, setShowAlerts] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const isLargeCustomer = useTypedSelector<boolean>(selectIsLargeCustomer);
    const isLargeCustomerAccount = useTypedSelector<boolean>(selectIsLargeCustomerAccount);
    const [addProductsOpen, setAddProductsOpen] = useState(false);
    const poNumberMaxLength = 25;
    const [poNumber, setPONumber] = useState<string>('');
    const [showPONumber, setShowPONumber] = useState<boolean>(false);
    const [showNoValidForecastsError, setShowNoValidForecastsError] = useState<boolean>(false);

    const onCancel = () => {
        setOpenCancelModal(true);
    };

    const onBack = () => {
        setOpenBackModal(true);
    };

    const handleCancel = () => {
        setOpenCancelModal(false);
        dispatch(resetPlanItBulkState());
        if (fromSummary) history.push('/plan-it-summary');
        else history.push('/');
    };

    const handleShowErrors = () => {
        setShowErrors(true);
        setShowAll(false);
        setShowAlerts(false);
        setShowCampaigns(false);
        setShowWarnings(false);
    };

    const handleShowWarnings = () => {
        setShowErrors(false);
        setShowAll(false);
        setShowAlerts(false);
        setShowCampaigns(false);
        setShowWarnings(true);
    };

    const handleShowAll = () => {
        setShowErrors(false);
        setShowAll(true);
        setShowAlerts(false);
        setShowCampaigns(false);
        setShowWarnings(false);
    };

    const handleShowCampaigns = () => {
        setShowErrors(false);
        setShowCampaigns(true);
        setShowAlerts(false);
        setShowAll(false);
        setShowWarnings(false);
    };

    const handleShowAlerts = () => {
        setShowErrors(false);
        setShowCampaigns(false);
        setShowAlerts(true);
        setShowAll(false);
        setShowWarnings(false);
    };

    const handleBackNavigation = () => {
        if (fromSummary) {
            history.push('/plan-it-summary');
        } else {
            dispatch(refreshPlanItBulkState());
            history.push('/plan-it-bulk-upload');
        }
    };

    // If going back to the Plan-It Summary page, use the stored search to repopulate the search bar
    useEffect(() => {
        return () => {
            if (history.location.pathname.includes('plan-it-summary')) {
                dispatch(setUseSummaryStoredSearch(true));
            }
        };
    }, [history]);

    const onClose = () => {
        if (openBackModal) {
            setOpenBackModal(false);
        } else if (openCancelModal) {
            setOpenCancelModal(false);
        } else {
            setOpenBlockedNavigationModal(false);
        }
    };

    const handleBlockedNavigation = (location) => {
        if (submitForecastOrdersSubmissionState === 'success') {
            return true;
        } else {
            if (location !== history.location.pathname) setLastLocationPath(location);
            if (!allowNavigation && !openCancelModal && !openBackModal) {
                setOpenBlockedNavigationModal(true);
                return false;
            }
            return true;
        }
    };

    const handleConfirmNavigation = () => {
        setOpenBlockedNavigationModal(false);

        if (lastLocationPath !== '') {
            setAllowNavigation(true);
            // Navigate to the previously blocked location
            setTimeout(() => {
                history.push(lastLocationPath);
            }, 100);
        }
    };

    const onCloseBlockedNavigationModal = () => {
        setOpenBlockedNavigationModal(false);
    };

    const onSubmit = () => {
        if (isImpersonation) {
            setShowImpersonationWarning(true);
        } else {
            setIsSubmitting(true);
            dispatch(submitForecastOrders(setIsSubmitting));
        }
    };

    const handleExpandAll = () => {
        setExpandAll({ toggle: true });
        dispatch(updateExpandAll(false, activeDate.fullDate, selectedShipTo));
    };

    const handleCollapseAll = () => {
        setExpandAll({ toggle: false });
        dispatch(updateExpandAll(true, activeDate.fullDate, selectedShipTo));
    };

    const checkShouldRender = (order: PlanItBulkATMLinesBySnoSku): Boolean => {
        switch (true) {
            case showErrors:
                return !!order.hasError;
            case showWarnings:
                return !!order.hasWarning;
            case showCampaigns:
                return order.lines.length > 0 && !!order.lines[0].campaignTiming;
            case showAlerts:
                return (
                    order.lines.length > 0 &&
                    !!order.lines[0].notIncludedInCsv &&
                    order.lines[0].notIncludedInCsv
                );
            default:
                return true;
        }
    };

    const getWeekValidationState = (ordersGroup: PlanItBulkATMLinesBySnoSku[]): DateState => {
        if (ordersGroup.some((order) => getOrderHasError(order))) {
            return 'error';
        } else if (ordersGroup.some((order) => getOrderHasWarning(order))) {
            return 'warning';
        } else {
            return 'valid';
        }
    };

    /**
     * Evaluates validity of order within given week.  Used for calculating validation state
     * of the week based on isCustomerProductIdDistinct.
     * @param order
     * @returns
     */
    const getOrderHasError = (order: PlanItBulkATMLinesBySnoSku): Boolean => {
        let hasError = false;
        if (order.lines) {
            hasError = order.lines.some(
                (line) => !line.deleted && !line.isCustomerProductIdDistinct
            );
        }
        return hasError;
    };

    /**
     * Evaluates validity of order within given week.  Used for calculating validation state
     * of the week based on palletQuantity.
     * @param order
     * @returns
     */
    const getOrderHasWarning = (order: PlanItBulkATMLinesBySnoSku): Boolean => {
        let hasError = false;
        if (order.lines) {
            hasError = order.lines.some(
                (line) => !line.deleted && line.palletQuantity === undefined
            );
        }
        return hasError;
    };

    /**
     * Evaluates validity of all orders/weeks for a shipToAccount .  Used for calculating validation state
     * of the shipToAccount.
     * @param shipToAccount
     * @returns
     */
    const getShipToValidationState = (shipToId: string): DateState => {
        let hasError = false;
        let hasWarning = false;
        if (ordersBySnoSku) {
            hasError = ordersBySnoSku.some(
                (order) => order.shipToId.toString() === shipToId && order.state === 'error'
            );
        }
        if (ordersBySnoSku) {
            hasWarning = ordersBySnoSku.some(
                (order) => order.shipToId.toString() === shipToId && order.state === 'warning'
            );
        }
        return hasError ? 'error' : hasWarning ? 'warning' : 'valid';
    };

    /**
     * Renders an order section
     * @param order
     * @param lines
     * @returns
     */
    const renderOrderSection = (order, grouping) => {
        return (
            checkShouldRender(grouping) && (
                <MakeItBulkReviewATMProvider
                    value={{
                        activeDate,
                        shipToId: order.shipToId
                    }}
                >
                    <Grid item>
                        <PlanItBulkReviewOrderSection
                            order={grouping}
                            shipToId={order.shipToId}
                            isActive={grouping.isActive}
                            expand={expandAll}
                            hasMultiplePersonas={!hasOnlyPlanItAccounts}
                        />
                    </Grid>
                </MakeItBulkReviewATMProvider>
            )
        );
    };

    /**
     * Load the Ship To Accounts from the order - only include a single ship to once.
     * @when Triggers on initial page load, after shipToAccounts and atmOrders are in state.
     */
    useEffect(() => {
        if (forecastOrders && shipToAccounts && !shipToAccountsList.length) {
            let accountsInOrder: FormattedAccount[] = [];
            forecastOrders.forEach((order) => {
                let orderShipTo = order.shipToId;
                let account = shipToAccounts.find(
                    (account) => parseInt(account.accountId) === orderShipTo
                );

                let formattedAccount = {
                    account: account,
                    state: 'valid'
                } as FormattedAccount;
                if (
                    account &&
                    !accountsInOrder.find((accountInList) => accountInList.account === account)
                ) {
                    accountsInOrder.push(formattedAccount);
                }
            });
            setShipToAccountsList(accountsInOrder);
        }
    }, [shipToAccounts]);

    /**
     * Set the currently selected ship to as the first Account in the shipToAccountsList.
     * @when Trigger once on initial page load, after shipToAccountsList has been set from the above useEffect.
     */
    useEffect(() => {
        if (shipToAccountsList.length && !selectedShipTo) {
            setSelectedShipTo(shipToAccountsList[0].account.accountId);
        }
    }, [shipToAccountsList]);

    /**
     * Temporarily set formatted week list to undefined if it is already set.
     * This allows for the resetting of the selected date.
     * @when Trigger when selected ship to is changed.
     */
    useEffect(() => {
        if (formattedWeekList && formattedWeekList.length > 0) {
            setFormattedWeekList(undefined);
        }
    }, [selectedShipTo]);

    /**
     * Create weeksList object for the MakeItBulkReviewATMDateTab, consisting of every week for a given order filtered by the
     * currently selectedShipTo.
     *
     * Also sort weeksList in chronological order.
     *
     * @when Trigger on load and whenever selectedShipTo is updated.
     * @deps atmOrders, selectedShipTo
     */
    useEffect(() => {
        if (ordersLoaded && forecastOrders && !formattedWeekList && selectedShipTo) {
            let weekListAsDate: FormattedWeek[] = forecastOrders
                .filter((order) => order.shipToId === parseInt(selectedShipTo))
                .map((order) => ({
                    weekAsDate: moment(order.weekStart),
                    state: 'valid',
                    hasAlert: order.lines.some((l) => l.notIncludedInCsv)
                }));
            weekListAsDate.sort((a, b) => a.weekAsDate.valueOf() - b.weekAsDate.valueOf());
            // Ensure active date is reset (when a new ship to is selected)
            setActiveDate({
                fullDate: '',
                week: '',
                year: ''
            } as DateProperties);

            setFormattedWeekList(weekListAsDate);
        }
    }, [forecastOrders, selectedShipTo, formattedWeekList]);

    /**
     * Set the active date to the first week of formattedWeekList.
     * @when Trigger whenever formattedWeekList is updated, which happens in the above useEffect after selectedShipTo is
     * changed.
     * @deps formattedWeekList
     */
    useEffect(() => {
        if (formattedWeekList && formattedWeekList.length && !activeDate.fullDate.length) {
            setActiveDate(getDateProperties(formattedWeekList[0].weekAsDate));
        }
    }, [formattedWeekList]);

    /**
     * Group atmOrders by snoSku.
     * @when Trigger only once on load.
     * @deps atmOrders, ordersLoaded
     */
    useEffect(() => {
        if (forecastOrders && !ordersBySnoSku && ordersLoaded) {
            setOrdersBySnoSku(groupOrdersBySnoSku(forecastOrders, []));
        } else if (forecastOrders && ordersBySnoSku && ordersLoaded) {
            const newOrders = regroupOrdersBySnoSku(forecastOrders, ordersBySnoSku, []);
            setOrdersBySnoSku(newOrders);
            dispatch(saveOrdersBySnoSku(newOrders));
        }
    }, [forecastOrders, ordersLoaded]);

    /**
     * Revalidate entire order.
     * @when Trigger once whenever ordersBySnoSku is updated.
     * @deps ordersBySnoSku
     */
    useEffect(() => {
        setOrderValidated(false);
        if (ordersBySnoSku) {
            ordersBySnoSku.forEach((orders) => {
                orders.state = getWeekValidationState(orders.linesBySnoSku);
            });
            setOrderValidated(true);
        }
    }, [ordersBySnoSku]);

    /**
     * Set validations on the dates if both formattedWeekList is set and orders are grouped by snoSku and have been validated.
     * @when Trigger when an update to an order is made OR the selected ship to is changed.
     * @deps formattedWeekList, ordersBySnoSku, orderValidated
     */
    useEffect(() => {
        if (
            formattedWeekList &&
            formattedWeekList.length &&
            ordersBySnoSku &&
            ordersBySnoSku.length &&
            orderValidated
        ) {
            setWeeksHasErrors(false);
            formattedWeekList.forEach((formattedWeek) => {
                const orders = ordersBySnoSku?.find(
                    (order) =>
                        moment(order.weekStart).valueOf() === formattedWeek.weekAsDate.valueOf() &&
                        order.shipToId.toString() === selectedShipTo
                );
                if (orders) {
                    formattedWeek.state = orders.state;
                    if (formattedWeek.state !== 'valid') {
                        setWeeksHasErrors(true);
                    }
                }
            });
        }
    }, [formattedWeekList, ordersBySnoSku, orderValidated]);

    /**
     * Set the disabled state submit button if the order has been validated.
     * @when Trigger once whenever ordersBySnoSku is updated and after order has been validated.
     * @deps ordersBySnoSku, orderValidated
     */
    useEffect(() => {
        if (ordersBySnoSku && ordersBySnoSku.length && orderValidated) {
            //Reset error state before re-validation.
            setOrderHasErrors(false);
            setOrderHasWarnings(false);
            let revalidatedAccountsList = shipToAccountsList.map((shipToAccount) => {
                let stateToUpdate = getShipToValidationState(shipToAccount.account.accountId);
                if (stateToUpdate !== 'valid') {
                    if (stateToUpdate === 'error') {
                        setOrderHasErrors(true);
                    }
                    if (stateToUpdate === 'warning') {
                        setOrderHasWarnings(true);
                    }
                }
                shipToAccount.state = stateToUpdate;
                return shipToAccount;
            });
            setShipToAccountsList(revalidatedAccountsList);
            dispatch(saveOrdersBySnoSku(ordersBySnoSku));
        }
    }, [ordersBySnoSku, orderValidated, selectedShipTo]);

    /**
     * Filter ordersBySnoSku by the currently selected ship to and active date.
     * Last step before order information is displayed.
     * @when Trigger if orders have been grouped by snoSku, when the activeDate
     * or the selectedShipTo are changed.
     * @deps ordersBySnoSku, activeDate, selectedShipTo
     */
    useEffect(() => {
        if (ordersBySnoSku && ordersBySnoSku.length > 0 && activeDate && selectedShipTo) {
            const ordersBySnoSkuUpdate = JSON.parse(JSON.stringify(ordersBySnoSku));
            const activeOrders = ordersBySnoSkuUpdate.filter((week) => {
                return (
                    getDateProperties(moment(week.weekStart)).week === activeDate.week &&
                    week.shipToId === parseInt(selectedShipTo)
                );
            });

            if (!fromSummary) {
                activeOrders.forEach((order) => {
                    order.linesBySnoSku.forEach((lineBySnoSku) =>
                        lineBySnoSku.lines.forEach((line) => {
                            if (line.notIncludedInCsv === true) {
                                setShowCSVWarning(true);
                                return;
                            }
                        })
                    );
                });
            }

            let formattedOrders: Array<PlanItBulkATMLinesBySnoSku> = [];

            activeOrders.forEach((order) => {
                order.linesBySnoSku.forEach((productGroup) => {
                    productGroup.hasError = productGroup.lines.some(
                        (line) =>
                            line.minimumOrderQuantity &&
                            !line.deleted &&
                            !line.isCustomerProductIdDistinct
                    );
                    productGroup.hasWarning = productGroup.lines.some(
                        (line) =>
                            line.minimumOrderQuantity &&
                            !line.deleted &&
                            line.palletQuantity === undefined
                    );
                    formattedOrders.push(productGroup);
                });
                order.linesBySnoSku = formattedOrders;
            });
            setActiveDateOrders(activeOrders);
        }
    }, [ordersBySnoSku, activeDate, selectedShipTo]);

    useEffect(() => {
        if (forecastOrders && activeDate && selectedShipTo) {
            const activeForecastOrder = forecastOrders.filter((order) => {
                return (
                    getDateProperties(moment(order.weekStart)).week === activeDate.week &&
                    order.shipToId === parseInt(selectedShipTo)
                );
            });
            // there should be only one forecast for each week/ship to combination
            // but some older data may violate this restriction
            if (
                showPONumber &&
                activeForecastOrder.length === 1 &&
                activeForecastOrder[0].customerProductionOrderId
            ) {
                setPONumber(activeForecastOrder[0].customerProductionOrderId);
            } else {
                setPONumber('');
            }
        }
    }, [forecastOrders, activeDate, selectedShipTo]);

    useEffect(() => {
        if (shipToAccounts && selectedShipTo) {
            const isSpotCustomer =
                shipToAccounts.filter(
                    (shipTo) =>
                        shipTo.accountId === selectedShipTo &&
                        shipTo.prodOrderType === ProdOrderType.AuthorizationToManufacture
                ).length > 0
                    ? false
                    : true;
            setShowPONumber(isSpotCustomer);
        } else {
            setShowPONumber(false);
        }
    }, [selectedShipTo, shipToAccounts]);

    useEffect(() => {
        if (submitForecastOrdersSubmissionState === 'success') {
            history.push('/plan-it-bulk-upload-confirmation');
        }
    }, [submitForecastOrdersSubmissionState]);

    useEffect(() => {
        setFromSummary(from === 'summary');
    }, [from]);

    const footerActions = (
        <Grid container justify="space-between">
            <Grid container item xs={1}>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="cancel-btn"
                    className={classes.actionBtn}
                    onClick={onCancel}
                >
                    <Trans i18nKey="cancel">Cancel</Trans>
                </Button>
            </Grid>
            <Grid container item xs={7}>
                <ErrorAlert
                    errorMessage={
                        <Trans i18nKey="submitATMError">
                            The system was unable to process your changes.
                        </Trans>
                    }
                    showError={error === 'submit'}
                    dataTestId="submit-error"
                ></ErrorAlert>
                <ImpersonationWarning
                    showWarning={showImpersonationWarning}
                    warningType={'SUBMIT'}
                />
            </Grid>
            <Grid container item xs={3} justify="flex-end">
                <Grid container item xs={7}>
                    <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        data-testid="back-btn"
                        onClick={onBack}
                        className={classes.actionBtn}
                    >
                        <Trans i18nKey="back">Back</Trans>
                    </Button>
                </Grid>
                <Grid container item xs={5} justify="flex-end">
                    <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        data-testid="submit-order-btn"
                        className={classes.actionBtn}
                        disabled={orderHasErrors || orderHasWarnings || isSubmitting}
                        onClick={onSubmit}
                    >
                        <Trans i18nKey="submitChanges">Submit Changes</Trans>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );

    const onAddProductsOpen = () => {
        setAddProductsOpen(true);
    };

    const onAddProductsClose = () => {
        setAddProductsOpen(false);
    };

    const updatePoNumber = () => {
        if (activeDate && selectedShipTo) {
            dispatch(
                updatePlanItCustomerProductionOrderNumber(
                    poNumber,
                    activeDate.fullDate,
                    parseInt(selectedShipTo)
                )
            );
        }
    };

    useEffect(() => {
        if (
            loaded &&
            forecastOrders?.length === 0 &&
            unrecognizedProducts &&
            unrecognizedProducts?.length > 0
        ) {
            setShowNoValidForecastsError(true);
        } else {
            setShowNoValidForecastsError(false);
        }
    }, [forecastOrders, loaded, unrecognizedProducts]);

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('planIt', 'Plan It'),
                description: t('editForecast', 'Edit Forecast').toUpperCase(),
                thinBanner: true,
                displayDropdown: false,
                shouldShowATMBanner: fromSummary ? isLargeCustomerAccount : isLargeCustomer
            }}
            actionFooter={{
                footerAction: footerActions,
                justify: 'space-between',
                sticky: true
            }}
            activity={Activity.PlanItBulkUpload}
            shipTos={false}
            loading={
                showNoValidForecastsError ? false : forecastOrders ? !ordersBySnoSku?.length : false
            }
        >
            {showNoValidForecastsError && (
                <Grid
                    container
                    item
                    xs={12}
                    alignItems="center"
                    justify="center"
                    className={classes.actionBar}
                >
                    <Typography
                        className={classes.errorMessage}
                        data-testid="no-valid-forecasts-error"
                    >
                        <Trans i18nKey="noValidForecasts">
                            The upload did not result in any valid Plan It records. Please edit your
                            file or use Plan It Guided.
                        </Trans>
                    </Typography>
                </Grid>
            )}
            {ordersBySnoSku && ordersBySnoSku.length > 0 && (
                <Grid container item xs={12} className={classes.dateSelectSection}>
                    <Grid container item xs={4} className={classes.addBtn}>
                        <Typography variant="subtitle2" className={classes.viewAtmTitle}>
                            {t('shipTo', 'Ship To').toUpperCase()}
                        </Typography>
                    </Grid>
                    <Grid container item xs={4} className={classes.addBtn}>
                        <Typography variant="subtitle2" className={classes.viewAtmTitle}>
                            {t(
                                'forecastWeekSelector',
                                'View Forecast for the week of'
                            ).toUpperCase()}
                        </Typography>
                    </Grid>
                    <Grid container item xs={4} className={classes.addBtn} justify="flex-end">
                        <Button
                            type="button"
                            color="primary"
                            variant="outlined"
                            data-testid="add-products-btn"
                            className={classes.actionBtn}
                            onClick={onAddProductsOpen}
                        >
                            <Trans i18nKey="addProducts">Add Products</Trans>
                        </Button>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={4}
                        className={classes.dropdownSelector}
                        data-testid="ship-to-selector"
                    >
                        <ShipToDropdown
                            name="shipToSelect"
                            testId="shipToSelect"
                            selectObjects={shipToAccountsList}
                            selected={selectedShipTo}
                            updateSelected={setSelectedShipTo}
                            showIcons={false}
                            showCsvWarning={showCSVWarning}
                        />
                    </Grid>
                    {formattedWeekList && (
                        <Grid
                            container
                            item
                            xs={4}
                            className={classes.dropdownSelector}
                            data-testid="dateBar"
                        >
                            <WeekSelectorDropdown
                                testId="weekSelect"
                                weeks={formattedWeekList}
                                selectedDate={activeDate}
                                onDateSelected={setActiveDate}
                                showIcons={false}
                            />
                        </Grid>
                    )}

                    <Grid container item xs={12}>
                        <Grid
                            container
                            item
                            xs={4}
                            alignItems="center"
                            className={classes.shipToTextContainer}
                        >
                            {((orderHasErrors && !isEdit) || showCSVWarning) && (
                                <Typography className={classes.shipToWarningText}>
                                    <Trans i18nKey="atmSelectorMessage">
                                        One or more Ship Tos have errors in them, please review.
                                    </Trans>
                                </Typography>
                            )}
                        </Grid>
                        <Grid
                            container
                            item
                            xs={4}
                            alignItems="center"
                            className={classes.shipToTextContainer}
                        >
                            {weeksHasErrors && (
                                <Typography className={classes.shipToWarningText}>
                                    <Trans i18nKey="atmWeekSelectorMessage">
                                        {enUS.atmWeekSelectorMessage}
                                    </Trans>
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container className={classes.selector}>
                        <Grid container item xs={6}>
                            <Typography
                                variant="h3"
                                className={classes.viewAtmTitle}
                                data-testid="current-selected-date"
                            >
                                {t('forecastForWk', 'Forecast for the week of').toUpperCase()}{' '}
                                {activeDate.week}
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            item
                            justify="flex-end"
                            className={classes.expandCollapseWrapper}
                            xs={6}
                        >
                            <Button
                                className={classes.btn}
                                color="primary"
                                onClick={handleExpandAll}
                                data-testid="expand-all"
                            >
                                <Trans i18nKey="expandAll">Expand All</Trans>
                            </Button>
                            <Typography>|</Typography>
                            <Button
                                className={classes.btn}
                                color="primary"
                                onClick={handleCollapseAll}
                                data-testid="collapse-all"
                            >
                                <Trans i18nKey="collapseAll">Collapse All</Trans>
                            </Button>
                        </Grid>
                    </Grid>
                    {showPONumber && (
                        <Grid container alignItems="center">
                            <Typography className={classes.poLabel}>
                                <Trans i18nKey="customerPoNumLong">Customer PO Number</Trans>
                            </Typography>
                            <Grid container>
                                <TextField
                                    label=""
                                    variant="standard"
                                    onChange={(control) => setPONumber(control.target.value)}
                                    onBlur={updatePoNumber}
                                    className={classes.fieldInput}
                                    disabled={activeDateOrders === undefined}
                                    inputProps={{
                                        maxLength: poNumberMaxLength,
                                        'data-testid': 'po-number-input'
                                    }}
                                    value={poNumber}
                                    placeholder={t('poNumberPlaceholder', '25 characters or less')}
                                />
                            </Grid>
                        </Grid>
                    )}
                    <Grid xs={12} item data-testid="atm-orders">
                        <ValidationFilterMenu
                            onShowErrors={handleShowErrors}
                            onShowWarnings={handleShowWarnings}
                            onShowCampaigns={handleShowCampaigns}
                            onShowAll={handleShowAll}
                            onShowAlerts={handleShowAlerts}
                            showAll={showAll}
                            showErrors={showErrors}
                            showWarnings={showWarnings}
                            showAlerts={showAlerts}
                            showCampaigns={showCampaigns}
                        />
                        {activeDateOrders &&
                            activeDateOrders.map(
                                (order) =>
                                    order.linesBySnoSku && (
                                        <>
                                            {order.linesBySnoSku
                                                .filter((grouping) => !grouping.initiallyEmpty)
                                                .map((grouping) =>
                                                    renderOrderSection(order, grouping)
                                                )}
                                        </>
                                    )
                            )}
                    </Grid>
                </Grid>
            )}
            <ConfirmationModal
                data-testid="cancel-modal"
                saveProgress={handleCancel}
                onClose={onClose}
                logo={false}
                title={<Trans i18nKey="cancel">Cancel</Trans>}
                subheader={<Trans i18nKey="changesNotSaved">Your changes will not be saved</Trans>}
                description={
                    <Trans i18nKey="confirmCancel">Are you sure you want to cancel?</Trans>
                }
                continueText={<Trans i18nKey="yesCancel">Yes, Cancel</Trans>}
                cancelText={<Trans i18nKey="no">No</Trans>}
                open={openCancelModal}
                navigationLink=""
                onCancel={onClose}
            />
            <ConfirmationModal
                data-testid="go-back-modal"
                saveProgress={handleBackNavigation}
                onClose={onClose}
                logo={false}
                title={<Trans i18nKey="back">Back</Trans>}
                subheader={<Trans i18nKey="changesNotSaved">Your changes will not be saved</Trans>}
                description={
                    <Trans i18nKey="goBackConfirm">Are you sure you want to go back?</Trans>
                }
                continueText={<Trans i18nKey="yesGoBack">Yes, Go Back</Trans>}
                cancelText={<Trans i18nKey="no">No</Trans>}
                open={openBackModal}
                navigationLink=""
                onCancel={onClose}
            />
            <ConfirmationModal
                data-testid="confirm-exit-modal"
                saveProgress={handleConfirmNavigation}
                onClose={onClose}
                logo={false}
                title={<Trans i18nKey="exit">Exit</Trans>}
                subheader={
                    <Trans i18nKey="exitConfirm">Are you sure you want to exit the page?</Trans>
                }
                description={
                    <Trans i18nKey="changesNotSaved">Your changes will not be saved?</Trans>
                }
                continueText={<Trans i18nKey="yesExit">Yes, Exit</Trans>}
                cancelText={<Trans i18nKey="no">No</Trans>}
                open={openBlockedNavigationModal}
                navigationLink=""
                onCancel={onCloseBlockedNavigationModal}
            />
            <Prompt
                when={!allowNavigation}
                message={(location, action) => {
                    if (action === 'POP') {
                        handleBlockedNavigation(location.pathname);
                        return RouterConfirmationOverride.PreventBrowserBack;
                    } else {
                        return handleBlockedNavigation(location.pathname);
                    }
                }}
            />
            <AddProductsModal
                open={addProductsOpen}
                onClose={onAddProductsClose}
                selectedShipTo={selectedShipTo}
                activeDate={activeDate}
            />
        </ProcessingPageTemplate>
    );
}
