import Axios from 'axios';
import { LiquidTestIntake } from '../../store/reducers/liquid-intake';
import { createAPIQueryParam } from '../helpers/query-helpers';

interface LiquidTestLookup {
    billTos?: string[];
}

export default class LiquidTestService {
    static submitLiquidTestForm = (form: LiquidTestIntake, accessToken: string) => {
        const axiosConfig = LiquidTestService.createConfig(accessToken);
        return Axios.post(`${process.env.REACT_APP_LIQUID_API}/liquids`, form, axiosConfig);
    };

    static getLiquidTestsByBillTo = (request: LiquidTestLookup, accessToken: string) => {
        let queryString = createAPIQueryParam(request);
        return Axios.get(
            `${process.env.REACT_APP_LIQUID_API}/liquids${queryString}`,
            LiquidTestService.createConfig(accessToken)
        );
    };

    private static createConfig = (accessToken: string) => ({
        headers: { Authorization: `Bearer ${accessToken}` }
    });
}
