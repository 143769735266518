import { PlanningAllocation } from '../../components/pages/ProductPlanningATM/models/PlanningAllocation';
import { TotalNDayRanges } from '../../store/reducers/product-planning';
import { ProductType } from '../services/orders-service';

export interface ViewType {
    types?: string | string[] | number;
    label?: string;
}

export interface ArrayFilterType {
    types?: string[];
    label?: string;
}

export interface NumberFilterType {
    types?: number;
    label?: string;
}

export enum FilterType {
    View = 'View',
    Range = 'Range',
    Unit = 'Unit'
}

export const viewTypes: ViewType[] = [
    {
        types: [''],
        label: 'all'
    },
    {
        types: ['CAN', 'BOTTLE'],
        label: 'cansBottles'
    },
    {
        types: ['END', 'CAP'],
        label: 'endsClosures'
    }
];

export const viewTypesWithCampaign: ViewType[] = [
    {
        types: [''],
        label: 'all'
    },
    {
        types: ['CAN', 'BOTTLE'],
        label: 'cansBottles'
    },
    {
        types: ['END', 'CAP'],
        label: 'endsClosures'
    },
    {
        types: ['CAMPAIGN'],
        label: 'campaignProducts'
    }
];

export const viewTypesCampaignOnly: ViewType[] = [
    {
        types: [''],
        label: 'all'
    },
    {
        types: ['CAMPAIGN'],
        label: 'campaignProducts'
    }
];

export const viewTypesCampaignAlertsOnly: ViewType[] = [
    {
        types: [''],
        label: 'all'
    },
    {
        types: ['CAMPAIGN'],
        label: 'campaignProducts'
    },
    {
        types: ['ALERTS'],
        label: 'alertsOnly'
    }
];

export const viewTypesCampaignWarningsAlertsOnly: ViewType[] = [
    {
        types: [''],
        label: 'all'
    },
    {
        types: ['CAMPAIGN'],
        label: 'campaignProducts'
    },
    {
        types: ['WARNINGS'],
        label: 'warningsOnly'
    },
    {
        types: ['ALERTS'],
        label: 'alertsOnly'
    }
];

export const statusTypes: ViewType[] = [
    {
        types: ['ACTIVE'],
        label: 'active'
    },
    {
        types: ['INACTIVE'],
        label: 'inactive'
    }
];

export const rangeTypes: ArrayFilterType[] = [
    {
        types: [''],
        label: 'all'
    },
    {
        types: ['6'],
        label: 'orderedInLastSixMonths'
    }
];

export const unitTypes: ArrayFilterType[] = [
    {
        types: ['pallet'],
        label: 'pallets'
    }
];

export const unitTypesWithEaches: ArrayFilterType[] = [
    {
        types: ['pallet'],
        label: 'pallets'
    },
    {
        types: ['eaches'],
        label: 'eaches'
    }
];

export const weekFilter: NumberFilterType[] = [
    {
        types: TotalNDayRanges.nineWeeks,
        label: 'weeks0to8'
    },
    {
        types: TotalNDayRanges.seventeenWeeks,
        label: 'weeks9to16'
    }
];

export const getViewLabel = (view, types: ViewType[] = viewTypesWithCampaign) => {
    const currentView = types.find((type) => type.types![0] === view[0]);
    return currentView && currentView.label ? currentView.label : '';
};

export const getRangeLabel = (range) => {
    const currentRange = rangeTypes.find((type) => type.types === range);
    return currentRange && currentRange.label ? currentRange.label : '';
};

export const getSelectedRangeLabel = (range) => {
    const defaultSelectedRange = weekFilter.find((defaultRange) => defaultRange.types === range);
    return defaultSelectedRange && defaultSelectedRange.label ? defaultSelectedRange.label : '' 
};

export const getFilteredAllocations = (
    viewTypes: string[],
    allocations: PlanningAllocation[],
    products: any
) => {
    // MATCH PRODUCT INFORMATION
    const allocationsToFilter = allocations.map<PlanningAllocation>((ap) => ({
        ...ap,
        products: ap.products?.map((product) => {
            const match = products.find((p) => product.productId === p.productId);
            return { ...product, ...match };
        })
    }));

    let filteredAllocations: any[] = [];
    if (!!allocationsToFilter) {
        if (viewTypes.length === 1 && viewTypes[0] === '') {
            filteredAllocations = allocationsToFilter;
        } else {
            viewTypes.forEach((filter) => {
                let currentlyFilteredAllocations: any[] = [];
                if (filter === 'CAMPAIGN') {
                    currentlyFilteredAllocations = allocationsToFilter.map((allo) => {
                        return (
                            !!allo.products &&
                            allo.products.some((p) => p.campaignTiming) && {
                                ...allo,
                                products: allo.products.filter((product) => product.campaignTiming)
                            }
                        );
                    });
                } else if (filter === 'AGING') {
                    currentlyFilteredAllocations = allocationsToFilter.map((allo) => {
                        return (
                            !!allo.products &&
                            allo.products.some((p) => p.isAged) && {
                                ...allo,
                                products: allo.products.filter((product) => product.isAged)
                            }
                        );
                    });
                } else if (filter === 'CAN') {
                    currentlyFilteredAllocations = allocationsToFilter.map((allo) => {
                        return (
                            !!allo.products &&
                            allo.products.some((p) => p.type === ProductType.Cans) && {
                                ...allo,
                                products: allo.products.filter(
                                    (product) => product.type === ProductType.Cans
                                )
                            }
                        );
                    });
                } else if (filter === 'END') {
                    currentlyFilteredAllocations = allocationsToFilter.map((allo) => {
                        return (
                            !!allo.products &&
                            allo.products.some((p) => p.type === ProductType.Ends) && {
                                ...allo,
                                products: allo.products.filter(
                                    (product) => product.type === ProductType.Ends
                                )
                            }
                        );
                    });
                }
                // remove duplicates and rows evaluated to 'false' from mapping
                filteredAllocations = filteredAllocations.concat(
                    currentlyFilteredAllocations.filter(
                        (p) => !filteredAllocations.includes(p) && p
                    )
                );
            });
        }
    }

    return filteredAllocations;
};
