import { TableRow, TableCell, Typography, Grid, Button, makeStyles } from '@material-ui/core';
import React from 'react';
import { TradeRequest } from '../../../../store/reducers/trade-it-summary';
import { Link as RouterLink } from 'react-router-dom';
import { boldWeight, medium, blackWeight } from '../../../../themes/globalConstants';
import { useTranslation } from 'react-i18next';

interface Props {
    line: TradeRequest;
}

const useStyles = makeStyles(() => ({
    name: {
        fontWeight: boldWeight,
        fontSize: medium,
        letterSpacing: 0.17
    },
    editButton: {
        fontSize: '1em'
    },
    boldText: {
        fontWeight: blackWeight
    }
}));

const TradeRequestRow = ({ line }: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    
    return (
        <TableRow hover={true}>
            <TableCell data-testid={'trade-request-id'}>
                <Grid container spacing={1}>
                    <Grid item className={classes.name}>
                        <Button
                            variant="text"
                            color="primary"
                            data-testid="po-number-button"
                            className={classes.editButton}
                            component={RouterLink}
                            to={`/trade-it-request/${line.tradeRequestId}?shipToIds=[${line.accountFrom},${line.accountTo}]`}
                        >
                            {line.tradeRequestId}
                        
                        </Button>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell data-testid={'account-from'}>
                <Typography className={classes.boldText}>{line.accountFromName}</Typography>
                <Typography>{line.accountFromAddress}</Typography>
                <Typography>#{line.accountFrom}</Typography>
            </TableCell>
            <TableCell data-testid={'account-to'}>
                <Typography className={classes.boldText}>{line.accountToName}</Typography>
                <Typography>{line.accountToAddress}</Typography>
                <Typography>#{line.accountTo}</Typography>
            </TableCell>
            <TableCell data-testid={'requested-amount'}>
                <Typography className={classes.boldText}>
                    {line.requestedAmount} ({line.quantitySkus}{' '}
                    {line.quantitySkus === '1' ? t('sku', 'SKU') : t('skus', 'SKUs')})
                </Typography>
                <Typography>
                    {line.quantityEaches} ea.
                </Typography>
            </TableCell>
            <TableCell data-testid={'submitted-date'}>
                <Typography>{line.submittedDate}</Typography>
            </TableCell>
        </TableRow>
    );
};

export default TradeRequestRow;
