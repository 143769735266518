import { makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { boldWeight } from '../../../../themes/globalConstants';
import { ShipToLocation } from '../../../../store/reducers/ship-to-locations';
import {
    customerTypeOptions,
    freightManagementOptions
} from '../../../../static-data/dropdown-lists';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { getAccountAddress } from '../../../../utility/helpers/address-helpers';

interface Props {
    newShipToLocation: ShipToLocation;
}

const useStyles = makeStyles((theme) => ({
    boldText: {
        fontWeight: boldWeight
    },
    spacer: {
        height: '1.5em'
    }
}));

const ShipToAddFormData = ({ newShipToLocation }: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { adminAccounts, adminAccountsLoaded } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const [billToName, setBillToName] = useState<string>('');
    const [billToAddress, setBillToAddress] = useState<string>('');
    const [newLocationAddress, setNewLocationAddress] = useState<string>('');
    const [canFreightManagement, setCanFreightManagement] = useState<string | undefined>(undefined);
    const [endFreightManagement, setEndFreightManagement] = useState<string | undefined>(undefined);
    const [customerType, setCustomerType] = useState<string | undefined>(undefined);
    const [specialtyTab, setSpecialtyTab] = useState<string>('');
    const [specialtyPrinting, setSpecialtyPrinting] = useState<string>('');

    const getAnticipatedItems = () => {
        let anticipatedItems: Array<any> = [];

        newShipToLocation?.items.forEach((item) => {
            anticipatedItems.push(
                <Typography>
                    {item.itemName}-{item.itemID}
                </Typography>
            );
        });

        return anticipatedItems;
    };

    useEffect(() => {
        if (adminAccounts && adminAccountsLoaded && newShipToLocation) {
            const matchingBillTo = adminAccounts?.find(
                (acc) => acc.accountId === newShipToLocation.billToID
            );
            if (matchingBillTo) {
                setBillToName(matchingBillTo.name);
                setBillToAddress(getAccountAddress(matchingBillTo));
            }
        }
    }, [adminAccounts, adminAccountsLoaded, newShipToLocation]);

    useEffect(() => {
        if (newShipToLocation) {
            let address =
                `${newShipToLocation.newShipToAddress1} ${newShipToLocation.newShipToAddress2} ${newShipToLocation.newShipToAddress3} ${newShipToLocation.newShipToCity} ${newShipToLocation.newShipToStateProvince} ${newShipToLocation.newShipToPostalCode}`.trim();

            setNewLocationAddress(address);
            if (newShipToLocation.canFreight) {
                const canFreightMgmt = freightManagementOptions.find(
                    (option) => option.value === newShipToLocation.canFreight
                );
                setCanFreightManagement(canFreightMgmt?.i18nKey);
            }
            if (newShipToLocation.endFreight) {
                const endFreightMgmt = freightManagementOptions.find(
                    (option) => option.value === newShipToLocation.endFreight
                );
                setEndFreightManagement(endFreightMgmt?.i18nKey);
            }
            if (newShipToLocation.customerType) {
                const custType = customerTypeOptions.find(
                    (option) => option.value === newShipToLocation.customerType
                );
                setCustomerType(custType?.i18nKey);
            }

            const specialtyTabValues: string[] = [];
            if (newShipToLocation.laserTab) {
                specialtyTabValues.push(t('laserTab', 'Laser Tab'));
            }
            if (newShipToLocation.punch) {
                specialtyTabValues.push(t('punch', 'Punch'));
            }
            if (newShipToLocation.coloredTab) {
                specialtyTabValues.push(t('coloredTab', 'Colored Tab'));
            }
            if (newShipToLocation.coloredShell) {
                specialtyTabValues.push(t('coloredShell', 'Colored Shell'));
            }
            setSpecialtyTab(specialtyTabValues.join(', '));
            const specialtyPrintingValues: string[] = [];
            if (newShipToLocation.basecoat) {
                specialtyPrintingValues.push(t('basecoat', 'Basecoat'));
            }
            if (newShipToLocation.retort) {
                specialtyPrintingValues.push(t('retort', 'Retort'));
            }
            if (newShipToLocation.tactile) {
                specialtyPrintingValues.push(t('tactile', 'Tactile'));
            }
            if (newShipToLocation.satin) {
                specialtyPrintingValues.push(t('satin', 'Satin'));
            }
            setSpecialtyPrinting(specialtyPrintingValues.join(', '));
        }
    }, [newShipToLocation, freightManagementOptions, customerTypeOptions]);

    return (
        <>
            <Typography className="sectionHeader">
                <Trans i18nKey="customerInformation">Customer Information</Trans>
            </Typography>
            <Typography className={classes.boldText}>{billToName}</Typography>
            <Typography>
                <Trans i18nKey="customerNumber">Customer Number</Trans>:{' '}
                {newShipToLocation.billToID}
            </Typography>
            <Typography>{billToAddress}</Typography>

            <Typography className="sectionHeader">
                <Trans i18nKey="newShipToInformation">New Ship To Information</Trans>
            </Typography>
            <Typography className={classes.boldText}>{newShipToLocation.newShipToName}</Typography>
            <Typography>
                <Trans i18nKey="customerType">Customer Type</Trans>:{' '}
                <Trans i18nKey={customerType}>{customerType}</Trans>
            </Typography>
            <Typography>{newLocationAddress}</Typography>
            {newShipToLocation.cfid && (
                <Typography>
                    <Trans i18nKey="cfid">CFID</Trans>: {newShipToLocation.cfid}
                </Typography>
            )}
            {newShipToLocation.taxID && (
                <Typography>
                    <Trans i18nKey="taxId">Tax ID</Trans>: {newShipToLocation.taxID}
                </Typography>
            )}
            {newShipToLocation.vatid && (
                <Typography>
                    <Trans i18nKey="vat">VAT</Trans>: {newShipToLocation.vatid}
                </Typography>
            )}
            <div className={classes.spacer}></div>
            <Typography>
                <Trans i18nKey="estimatedAnnualVolume">Estimated Annual Volume</Trans>:{' '}
                {newShipToLocation.annualVolume}
            </Typography>
            <div className={classes.spacer}></div>
            <Typography className={classes.boldText}>
                <Trans i18nKey="userAdmin">User Admin</Trans>:{' '}
            </Typography>
            <Typography>{newShipToLocation.userAdminName}</Typography>
            <Typography>{newShipToLocation.userAdminPhone}</Typography>
            <Typography>{newShipToLocation.userAdminEmail}</Typography>

            <Typography className="sectionHeader">
                <Trans i18nKey="productInformation">Product Information</Trans>
            </Typography>
            <Typography>
                {newShipToLocation.productSelection === 'cans' ? (
                    <>
                        <Trans i18nKey={'cans'}>Cans</Trans>/
                        <Trans i18nKey={'bottles'}>Bottles</Trans>
                    </>
                ) : newShipToLocation.productSelection === 'ends' ? (
                    <Trans i18nKey={'ends'}>Ends</Trans>
                ) : newShipToLocation.productSelection === 'both' ? (
                    <>
                        <Trans i18nKey={'cans'}>Cans</Trans>/
                        <Trans i18nKey={'bottles'}>Bottles</Trans>,{' '}
                        <Trans i18nKey={'ends'}>Ends</Trans>
                    </>
                ) : (
                    ''
                )}
            </Typography>
            <Typography>
                <Trans i18nKey="specialtyPrintingReview">Specialty Printing</Trans>:{' '}
                {specialtyPrinting}
            </Typography>
            <Typography>
                <Trans i18nKey="specialtyTabs">Specialty Tabs</Trans>: {specialtyTab}
            </Typography>
            <div className={classes.spacer}></div>
            <Typography>
                <Trans i18nKey="anticipatedItems">Anticipated Items</Trans>: {getAnticipatedItems()}
            </Typography>

            <Typography className="sectionHeader">
                <Trans i18nKey="freightInformation">Freight Information</Trans>
            </Typography>
            <Typography>
                <Trans i18nKey="canFreightManagement">Can Freight Management</Trans>:{' '}
                <Trans i18nKey={canFreightManagement}>{canFreightManagement}</Trans>
            </Typography>
            <Typography>
                <Trans i18nKey="endFreight">End Freight</Trans>:{' '}
                <Trans i18nKey={endFreightManagement}>{endFreightManagement}</Trans>
            </Typography>
            <Typography>
                <Trans i18nKey="specialtyEquipment">Specialty Equipment</Trans>:{' '}
                {newShipToLocation.specialEquipment}
            </Typography>
            <Typography>
                <Trans i18nKey="layersPerPallet">Layers per Pallet</Trans>:{' '}
                {newShipToLocation.layersPerPallet}
            </Typography>
        </>
    );
};

export default ShipToAddFormData;
