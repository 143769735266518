import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class TradeItProductsColumns {
    static getTradeItProductsColumns = (culture: string, originalShipToId, receivingShipToId) => {
        return [
            createColumn('displayName', {
                dataType: ColumnDataType.String,
                filterable: true,
                isKey: true,
                label: `${TranslationService.getTranslatedText(
                    culture,
                    'productDescription'
                )} (${TranslationService.getTranslatedText(
                    culture,
                    'ballProductId'
                )} / ${TranslationService.getTranslatedText(culture, 'graphicId')})`,
                sortable: true,
                searchable: true
            }),
            createColumn('campaignTiming', {
                dataType: ColumnDataType.String,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('productSku', {
                dataType: ColumnDataType.String,
                filterable: true,
                sortable: true,
                searchable: true,
                visible: false
            }),
            createColumn('graphicIdAndVersion', {
                dataType: ColumnDataType.String,
                filterable: true,
                sortable: true,
                searchable: true,
                visible: false
            }),
            createColumn('displayId', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'customerProductId'),
                filterable: true,
                sortable: true,
                searchable: true
            }),
            createColumn('originalShipToProductionBalance', {
                dataType: ColumnDataType.Numeric,
                label: `#${originalShipToId} ${TranslationService.getTranslatedText(
                    culture,
                    'originalShipToCurrentProdBalance'
                )}`,
                filterable: false,
                sortable: true,
                searchable: false
            }),
            createColumn('receivingShipToProductionBalance', {
                dataType: ColumnDataType.Numeric,
                label: `#${receivingShipToId} ${TranslationService.getTranslatedText(
                    culture,
                    'receivingShipToCurrentProdBalance'
                )}`,
                filterable: false,
                sortable: true,
                searchable: false
            }),
            createColumn('quantityToTrade', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'quantityToTrade'),
                filterable: false,
                sortable: true,
                searchable: false
            }),
            createColumn('originalShipToNewProductionBalance', {
                dataType: ColumnDataType.Numeric,
                label: `#${originalShipToId} ${TranslationService.getTranslatedText(
                    culture,
                    'originalShipToNewProdBalance'
                )}`,
                filterable: false,
                sortable: true,
                searchable: false
            }),
            createColumn('receivingShipToNewProductionBalance', {
                dataType: ColumnDataType.Numeric,
                label: `#${receivingShipToId} ${TranslationService.getTranslatedText(
                    culture,
                    'receivingShipToNewProdBalance'
                )}`,
                filterable: false,
                sortable: true,
                searchable: false
            }),
            createColumn('quantityPerPallet', {
                dataType: ColumnDataType.Numeric,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            })
        ];
    };
}
