import React, { useState, useEffect } from 'react';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { Button, makeStyles, Paper, Grid, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import {
    blackWeight,
    boldWeight,
    ballGray,
    vanilla,
    ballBlue,
    medium,
    ltBlueGrey_34,
    containerMaxWidth
} from '../../../themes/globalConstants';
import { useHistory } from 'react-router-dom';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { formatFromDatetime, formattedMTDate } from '../../../utility/helpers/date-helpers';
import moment from 'moment';
import DisableBackButton from '../../reusable/atoms/DisableBackButton';
import { Activity } from '../../../utility/auth/useSecurity';
import InfoSubheader from '../../reusable/molecules/InfoSubheader';
import { InfoSubheaderItem } from '../../../store/reducers/glp';
import { ProductionOrder } from '../../../store/reducers/makeit-dashboard';
import EditProductionOrderConfirmationShareLinks from './components/EditProductionOrderConfirmationShareLinks';
import EditProductionOrderConfirmationGrid from './components/EditProductionOrderConfirmationGrid';
import { resetEditProductionOrder } from '../../../store/root-actions';
import { useDispatch } from 'react-redux';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { usePrevious } from '../../../utility/helpers/react-util';
import { getTotalQuantityAmounts } from '../../../utility/helpers/order-helpers';
import { ProductType } from '../../../utility/services/orders-service';
import { formatNumberWithLocale } from '../../../utility/helpers/formatters';
import { EditProductionOrderState } from '../../../store/reducers/edit-production-order';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: '3em',
        padding: '2em',
        borderRadius: 4,
        maxWidth: containerMaxWidth
    },
    header: {
        marginTop: '1em',
        marginBottom: '3em'
    },
    actionBtn: {
        borderRadius: 'unset'
    },
    title: {
        fontWeight: blackWeight,
        color: ballGray
    },
    subTitle: {
        color: ballGray,
        fontSize: '1em',
        fontWeight: blackWeight,
        marginTop: '.5em'
    },
    warning: {
        padding: '.125em 1.5em .125em 2.125em',
        backgroundColor: vanilla,
        '& .MuiAlert-icon': {
            color: ballBlue
        }
    },
    alertText: {
        textAlign: 'center',
        fontSize: medium,
        fontWeight: boldWeight
    },
    divider: {
        height: '0.32em',
        backgroundColor: ltBlueGrey_34,
        width: '100%',
        marginBottom: '1em'
    }
}));

const EditProductionOrderConfirmation = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [shipToName, setShipToName] = useState<string>('');
    const [displayAddress, setDisplayAddress] = useState<string>('');
    const [subheaderItems, setSubheaderItems] = useState<InfoSubheaderItem[]>();
    const { shipToAccounts, selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );

    const { productionOrderToEdit } = useTypedSelector<EditProductionOrderState>(
        (state) => state.editProductionOrder
    );
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const prevCultureCode = usePrevious(cultureCode);

    const accountInformation =
        shipToAccounts &&
        shipToAccounts.filter((account) => account.accountId === selectedAccountId);
    const dashboardLink = '/dashboard';
    const history = useHistory();

    DisableBackButton('/make-it-summary', '/edit-production-order-confirmation');

    const handleDashboardNavigation = () => {
        dispatch(resetEditProductionOrder());
        history.push(dashboardLink);
    };

    const footerActions = (
        <Button
            type="button"
            color="primary"
            variant="contained"
            data-testid="dashboard-btn"
            className={classes.actionBtn}
            onClick={handleDashboardNavigation}
        >
            <Trans i18nKey="myDashboard">My Dashboard</Trans>
        </Button>
    );

    const getQuantity = (type: string): String => {
        const quantityInformation = getTotalQuantityAmounts(type, [productionOrderToEdit]);
        return `${formatNumberWithLocale(
            cultureCode,
            quantityInformation.pallets
        )} PL/${formatNumberWithLocale(cultureCode, quantityInformation.skus)} ${
            quantityInformation.skus > 1 ? t('skus', 'SKUs') : t('sku', 'SKU')
        }`;
    };

    const createSubheader = (productionOrder: ProductionOrder) => {
        setSubheaderItems([
            {
                subtitle: t('dateRevised', 'Date Revised'),
                body: formatFromDatetime(productionOrder.updateDate as string)
            },
            {
                subtitle: t('canQuantity', 'Can Quantity'),
                body: getQuantity(ProductType.Cans),
                subtext: `${productionOrderToEdit?.canQuantityEaches?.toLocaleString()} ea.`
            },
            {
                subtitle: t('endQuantity', 'End Quantity'),
                body: getQuantity(ProductType.Ends),
                subtext: `${productionOrderToEdit?.endQuantityEaches?.toLocaleString()} ea.`
            },
            {
                subtitle: t('ballOrderNumber', 'Ball Order #'),
                body: productionOrder.productionOrderNumber
            }
        ] as InfoSubheaderItem[]);
    };

    useEffect(() => {
        if (productionOrderToEdit) {
            if (cultureCode !== prevCultureCode || !subheaderItems?.length) {
                createSubheader(productionOrderToEdit);
            }
            if (!displayAddress && !shipToName) {
                if (accountInformation && accountInformation[0]) {
                    setShipToName(accountInformation[0].name);
                    if (accountInformation[0].address) {
                        const shipToAddress = accountInformation[0].address;
                        setDisplayAddress(
                            shipToAddress.address1?.trim() +
                                ', ' +
                                shipToAddress.city?.trim() +
                                ', ' +
                                shipToAddress.stateProvince?.trim() +
                                ' ' +
                                shipToAddress.postalCode
                        );
                    }
                }
            }
        }
    }, [accountInformation, cultureCode]);

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('makeIt', 'Make It'),
                description: t(
                    'editProductionOrderSubmitted',
                    'THANK YOU. YOUR CHANGES HAVE BEEN RECEIVED'
                ),
                thinBanner: true,
                displayDropdown: false
            }}
            actionFooter={{
                footerAction: footerActions,
                justify: 'center',
                sticky: true
            }}
            activity={Activity.NewOpenProductionOrders}
        >
            <Paper elevation={2} className={classes.paper}>
                <Grid container spacing={2} alignItems="flex-start">
                    <Grid container alignItems="baseline" className={classes.header}>
                        <Grid container item xs={9} justify="flex-start">
                            <Typography variant="h3" className={classes.title}>
                                {t('customerPoNumber', 'Customer PO #')}{' '}
                                {productionOrderToEdit?.customerProductionOrderId
                                    ? productionOrderToEdit?.customerProductionOrderId
                                    : productionOrderToEdit?.productionOrderNumber}
                            </Typography>
                        </Grid>
                        <Grid container item xs={3} justify="flex-end">
                            {productionOrderToEdit && (
                                <EditProductionOrderConfirmationShareLinks
                                    dateSubmitted={formattedMTDate(
                                        moment(productionOrderToEdit.submittedDate),
                                        'MM/DD/YYYY'
                                    )}
                                    productionOrder={productionOrderToEdit}
                                    shipToAddress={displayAddress}
                                    shipToName={shipToName}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid container>
                        {subheaderItems && (
                            <InfoSubheader
                                shipToName={shipToName}
                                shipToAddress={displayAddress}
                                subheaderItems={subheaderItems}
                            />
                        )}
                    </Grid>
                </Grid>
                <EditProductionOrderConfirmationGrid items={productionOrderToEdit?.lines} />
            </Paper>
        </ProcessingPageTemplate>
    );
};

export default EditProductionOrderConfirmation;
