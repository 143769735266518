import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useTranslation, Trans } from 'react-i18next';
import { Grid, makeStyles, Typography, Button } from '@material-ui/core';
import { CheckOutItem } from '../ShipIt';
import {
    blackWeight,
    large,
    ltBlueGrey_34,
    orange,
    medium,
    ballDrkBlue,
    xl,
    boldWeight,
    regularWeight,
    black
} from '../../../../themes/globalConstants';
import { ProductWithPallets } from '../../../../store/reducers/shipping-dashboard';
import { isProductCanType, isProductEndType } from '../../../../utility/helpers/order-helpers';
import {
    getConvertedQuantityUnitPallets,
    getFormattedSelectedQuantityUnit
} from '../../../../utility/helpers/shipment-helpers';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { formatNumberWithLocale } from '../../../../utility/helpers/formatters';

interface Props {
    products: CheckOutItem[];
    canPallets: number;
    endPallets: number;
    onRemoveAllOfType: (type: string) => void;
    onRemoveOrder: (index: number, pallets: number, type: string) => void;
    onPalletRemove: (product: ProductWithPallets, pallets: number) => void;
    onPalletRemoveAll: (products: ProductWithPallets[]) => void;
    scrapIt?: boolean;
}

const useStyles = makeStyles((theme) => ({
    checkoutCansContainer: {
        padding: '1.5em 0.5em 0.875em 0.5em',
        backgroundColor: 'white',
        marginBottom: '.125em',
        borderTopRightRadius: '0.250em',
        borderTopLeftRadius: '0.250em',
        '&:first-child': {
            marginTop: '2.25em'
        }
    },
    checkoutEndsContainer: {
        padding: '0.875em 0.5em',
        backgroundColor: 'white',
        marginTop: '.875em',
        marginBottom: '.125em',
        borderRadius: '0.250em'
    },
    title: {
        fontWeight: blackWeight,
        color: theme.palette.secondary.main,
        marginBottom: '0.875em'
    },
    checkoutItem: {
        padding: '0.875em 0.5em',
        backgroundColor: 'white',
        marginBottom: '.125em',
        flexWrap: 'nowrap'
    },
    checkoutText: {
        width: '13em',
        wordWrap: 'break-word'
    },
    counterWrapper: {
        padding: '0.75em 0',
        width: '100%'
    },
    counterTitle: {
        fontSize: large,
        fontWeight: blackWeight,
        marginRight: '.25em',
        display: 'flex',
        alignItems: 'center'
    },
    counter: {
        width: '1.5em',
        height: '1.5em',
        borderRadius: '50%',
        backgroundColor: ltBlueGrey_34,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '.5em'
    },
    hidden: {
        display: 'none'
    },
    trash: {
        paddingRight: 0,
        minWidth: 'unset',
        '&:hover': {
            backgroundColor: 'white'
        },
        '& svg': {
            fill: orange
        }
    },
    orderPalletContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    orderPallet: {
        fontSize: medium,
        fontWeight: blackWeight,
        textAlign: 'right',
        wordBreak: 'break-all'
    },
    orderEaches: {
        fontWeight: regularWeight
    },
    totalContainer: {
        padding: '0.875em 0.5em',
        backgroundColor: 'white',
        marginBottom: '1.125em',
        borderBottomRightRadius: '0.250em',
        borderBottomLeftRadius: '0.250em'
    },
    totalPalletsContainer: {
        color: ballDrkBlue,
        marginBottom: '1em',
        '& > p': {
            fontSize: xl,
            fontWeight: blackWeight
        }
    },
    removeAllBtn: {
        paddingRight: '0.375em',
        paddingLeft: '0.375em',
        height: '2.28em'
    },
    preview: {
        border: `1px solid ${theme.palette.primary.main}`,
        borderStyle: 'dashed',
        padding: '10em 1.875em',
        textAlign: 'center'
    },
    previewText: {
        fontWeight: boldWeight,
        fontSize: medium,
        '&:last-child': {
            marginTop: '0.5em'
        }
    },
    productListWrapper: {
        maxHeight: '19.500em',
        overflowY: 'auto'
    },
    quantityDisplay: {
        display: 'flex',
        flexDirection: 'column'
    },
    totalQuantityEaches: {
        display: 'flex',
        flexDirection: 'column',

        '& p:nth-of-type(1)': {
            fontSize: xl,
            fontWeight: blackWeight
        },

        '& p': {
            textAlign: 'right'
        }
    },
    canEachesTotal: {
        fontSize: '.75rem',
        color: black,
        fontWeight: regularWeight
    }
}));

export default function ShipItCheckout({
    products,
    canPallets,
    endPallets,
    onRemoveAllOfType,
    onRemoveOrder,
    onPalletRemove,
    onPalletRemoveAll,
    scrapIt
}: Props) {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [cansList, setCansList] = useState([] as CheckOutItem[]);
    const [endsList, setEndsList] = useState([] as CheckOutItem[]);
    const [cansEachesTotal, setCansEachesTotal] = useState<number>(0);
    const [endsEachesTotal, setEndsEachesTotal] = useState<number>(0);

    useEffect(() => {
        if (products.length > 0) {
            const cansList = products.filter((can) =>
                isProductCanType(can.product.type)
            ) as CheckOutItem[];
            const endsList = products.filter((end) =>
                isProductEndType(end.product.type)
            ) as CheckOutItem[];
            setCansList(cansList);
            setEndsList(endsList);
        }
    }, [products]);

    useEffect(() => {
        if (cansList && cansList.length) {
            const eachesTotal = cansList.reduce((prev, curr) => {
                prev += curr.pallets * curr.product.quantityPerPallet!;
                return prev;
            }, 0);
            setCansEachesTotal(eachesTotal);
        } else {
            setCansEachesTotal(0);
        }
    }, [cansList]);

    useEffect(() => {
        if (endsList && endsList.length) {
            const eachesTotal = endsList.reduce((prev, curr) => {
                prev += curr.pallets * curr.product.quantityPerPallet!;
                return prev;
            }, 0);
            setEndsEachesTotal(eachesTotal);
        } else {
            setEndsEachesTotal(0);
        }
    }, [endsList]);

    const handleRemoveType = (type: string) => {
        const isEndType = isProductEndType(type);
        const isCanType = isProductCanType(type);

        if (isEndType) {
            if (endsList) {
                let productsArr: any[] = [];
                endsList.map((end) => productsArr.push(end.product));
                onPalletRemoveAll(productsArr);
            }
        } else if (isCanType) {
            if (cansList) {
                let productsArr: any[] = [];
                cansList.map((can) => productsArr.push(can.product));
                onPalletRemoveAll(productsArr);
            }
        }
        onRemoveAllOfType(type);
    };

    const handleRemoveOneItem = ({
        index,
        pallets,
        type,
        product
    }: {
        index: number;
        pallets: number;
        type: string;
        product: ProductWithPallets;
    }) => {
        onRemoveOrder(index, pallets, type);
        onPalletRemove(product, pallets);
    };

    useTranslation();

    return (
        <Grid container item data-testid="checkout">
            {products.length === 0 ? (
                <Grid
                    container
                    className={classes.checkoutCansContainer}
                    data-testid="empty-products-container"
                >
                    <Grid item xs={12}>
                        {!scrapIt ? (
                            <Typography variant="h3" className={classes.title}>
                                <Trans i18nKey="yourDeliveryOrder">Your Delivery Order</Trans>
                            </Typography>
                        ) : (
                            <Typography variant="h3" className={classes.title}>
                                <Trans i18nKey="yourRequest">Your Request</Trans>
                            </Typography>
                        )}
                    </Grid>
                    <Grid container item xs={12} className={classes.preview}>
                        <Typography className={classes.previewText}>
                            <Trans i18nKey="shipitAddInstructions">
                                Build your order by specifying the number of pallets for each
                                product you'd like to include and then clicking the "ADD" button to
                                add to your order.
                            </Trans>
                        </Typography>
                        <Typography className={classes.previewText}>
                            {!scrapIt ? (
                                <Trans i18nKey="configureTrucksInstructions">
                                    You will configure specific trucks for your order on the next
                                    screen.
                                </Trans>
                            ) : (
                                <Trans i18nKey="youCanReview">
                                    You can review selected products on the next screen.
                                </Trans>
                            )}
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
                <>
                    <Grid
                        container
                        className={classes.checkoutCansContainer}
                        data-testid="cans-container"
                    >
                        <Grid item xs={12}>
                            {!scrapIt ? (
                                <Typography variant="h3" className={classes.title}>
                                    <Trans i18nKey="yourDeliveryOrder">Your Delivery Order</Trans>
                                </Typography>
                            ) : (
                                <Typography variant="h3" className={classes.title}>
                                    <Trans i18nKey="yourRequest">Your Request</Trans>
                                </Typography>
                            )}
                        </Grid>
                        <Grid
                            container
                            item
                            xs={6}
                            alignItems="center"
                            className={classes.counterWrapper}
                        >
                            <Typography className={classes.counterTitle}>
                                <Trans i18nKey="cans">Cans</Trans> /{' '}
                            </Typography>
                            <Typography className={classes.counterTitle}>
                                <Trans i18nKey="bottles">Bottles</Trans>
                                <Typography
                                    component="span"
                                    variant="subtitle2"
                                    className={classes.counter}
                                >
                                    {cansList.length}
                                </Typography>
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={6}
                            justify="flex-end"
                            alignContent="center"
                            className={clsx({ [classes.hidden]: cansList.length === 0 })}
                        >
                            <Button
                                type="button"
                                variant="outlined"
                                size="small"
                                onClick={() => handleRemoveType('CAN')}
                                className={classes.removeAllBtn}
                                data-testid="remove-all-cans"
                            >
                                <Trans i18nKey="removeAll">Remove All</Trans>
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        className={classes.productListWrapper}
                        data-testid="cans-checkout"
                    >
                        {cansList &&
                            cansList.map((can, i) => {
                                return (
                                    <Grid
                                        container
                                        item
                                        key={i}
                                        className={classes.checkoutItem}
                                        justify="space-between"
                                        data-testid={`product-${can.product.productSku}`}
                                        data-pallets={can.pallets}
                                    >
                                        <Grid item className={classes.checkoutText} xs={7}>
                                            <Typography variant="h6">
                                                {can.product.displayId}
                                            </Typography>
                                            <Typography variant="subtitle2">
                                                {can.product.displayName}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            className={classes.orderPalletContainer}
                                            xs={5}
                                            justify="flex-end"
                                        >
                                            <div className={classes.quantityDisplay}>
                                                <Typography
                                                    className={classes.orderPallet}
                                                    data-testid="cans-pallet-count"
                                                >
                                                    {formatNumberWithLocale(
                                                        cultureCode,
                                                        can.pallets
                                                    )}{' '}
                                                    PL
                                                </Typography>
                                                <Typography
                                                    className={clsx([
                                                        classes.orderPallet,
                                                        classes.orderEaches
                                                    ])}
                                                    data-testid="cans-pallet-count"
                                                >
                                                    {formatNumberWithLocale(
                                                        cultureCode,
                                                        +getConvertedQuantityUnitPallets(
                                                            'pallets',
                                                            can.pallets,
                                                            can.product.quantityPerPallet!
                                                        )
                                                    )}
                                                    {' ' +
                                                        getFormattedSelectedQuantityUnit('eaches')}
                                                </Typography>
                                            </div>
                                            <Button
                                                type="button"
                                                className={classes.trash}
                                                onClick={() => {
                                                    handleRemoveOneItem({
                                                        index: i,
                                                        pallets: can.pallets,
                                                        type: can.product.type!,
                                                        product: can.product
                                                    });
                                                }}
                                            >
                                                <img
                                                    src={
                                                        process.env.PUBLIC_URL + '/assets/Trash.svg'
                                                    }
                                                    alt="Trash Icon"
                                                />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                    </Grid>
                    <Grid container item className={classes.totalContainer}>
                        <Grid
                            container
                            item
                            justify="space-between"
                            className={classes.totalPalletsContainer}
                        >
                            <Typography>
                                <Trans i18nKey="totalPallets">Total Pallets</Trans>
                            </Typography>
                            <div className={classes.totalQuantityEaches}>
                                <Typography data-testid="can-pallet-total">
                                    {formatNumberWithLocale(cultureCode, canPallets)}{' '}
                                    {getFormattedSelectedQuantityUnit('pallets')}
                                </Typography>
                                <Typography
                                    className={classes.canEachesTotal}
                                    data-testid="can-eaches-total"
                                >
                                    {`${formatNumberWithLocale(
                                        cultureCode,
                                        Number(cansEachesTotal)
                                    )} ${getFormattedSelectedQuantityUnit('eaches')}`}
                                </Typography>
                            </div>
                        </Grid>
                        {!scrapIt && (
                            <Grid container item justify="space-between" alignItems="center">
                                <Typography>
                                    <Trans i18nKey="estimatedTrucks">
                                        Estimated trucks needed:
                                    </Trans>
                                </Typography>
                                <Typography data-testid="can-trucks-needed">
                                    {formatNumberWithLocale(cultureCode, canPallets / 25)}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                    <Grid
                        container
                        className={classes.checkoutCansContainer}
                        data-testid="ends-container"
                    >
                        <Grid
                            container
                            item
                            xs={6}
                            alignItems="center"
                            className={classes.counterWrapper}
                        >
                            <Typography className={classes.counterTitle}>
                                <Trans i18nKey="ends">Ends</Trans> /{' '}
                            </Typography>
                            <Typography className={classes.counterTitle}>
                                <Trans i18nKey="closures">Closures</Trans>
                                <Typography
                                    component="span"
                                    variant="subtitle2"
                                    className={classes.counter}
                                >
                                    {endsList.length}
                                </Typography>
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={6}
                            justify="flex-end"
                            alignContent="center"
                            className={clsx({ [classes.hidden]: endsList.length === 0 })}
                        >
                            <Button
                                type="button"
                                variant="outlined"
                                size="small"
                                onClick={() => handleRemoveType('END')}
                                className={classes.removeAllBtn}
                                data-testid="remove-all-ends"
                            >
                                <Trans i18nKey="removeAll">Remove All</Trans>
                            </Button>
                        </Grid>
                    </Grid>
                    {/* ends */}
                    <Grid
                        container
                        className={classes.productListWrapper}
                        data-testid="ends-checkout"
                    >
                        {endsList &&
                            endsList.map((end, i) => {
                                return (
                                    <Grid
                                        container
                                        item
                                        key={i}
                                        className={classes.checkoutItem}
                                        justify="space-between"
                                        data-testid={`product-${end.product.productSku}`}
                                        data-pallets={end.pallets}
                                    >
                                        <Grid item className={classes.checkoutText}>
                                            <Typography variant="h6">
                                                {end.product.displayId}
                                            </Typography>
                                            <Typography variant="subtitle2">
                                                {end.product.displayName}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            className={classes.orderPalletContainer}
                                            xs={5}
                                            justify="flex-end"
                                        >
                                            <div className={classes.quantityDisplay}>
                                                <Typography
                                                    className={classes.orderPallet}
                                                    data-testid="ends-pallet-count"
                                                >
                                                    {formatNumberWithLocale(
                                                        cultureCode,
                                                        end.pallets
                                                    )}{' '}
                                                    PL
                                                </Typography>
                                                <Typography
                                                    className={clsx([
                                                        classes.orderPallet,
                                                        classes.orderEaches
                                                    ])}
                                                    data-testid="ends-pallet-count"
                                                >
                                                    {formatNumberWithLocale(
                                                        cultureCode,
                                                        +getConvertedQuantityUnitPallets(
                                                            'pallets',
                                                            end.pallets,
                                                            end.product.quantityPerPallet!
                                                        )
                                                    )}
                                                    {' ' +
                                                        getFormattedSelectedQuantityUnit('eaches')}
                                                </Typography>
                                            </div>
                                            <Button
                                                type="button"
                                                className={classes.trash}
                                                onClick={() => {
                                                    handleRemoveOneItem({
                                                        index: i,
                                                        pallets: end.pallets,
                                                        type: end.product.type!,
                                                        product: end.product
                                                    });
                                                }}
                                            >
                                                <img
                                                    src={
                                                        process.env.PUBLIC_URL + '/assets/Trash.svg'
                                                    }
                                                    alt="Trash Icon"
                                                />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                    </Grid>
                    <Grid container item className={classes.totalContainer}>
                        <Grid
                            container
                            item
                            justify="space-between"
                            className={classes.totalPalletsContainer}
                        >
                            <Typography>
                                <Trans i18nKey="totalPallets">Total Pallets</Trans>
                            </Typography>
                            <div className={classes.totalQuantityEaches}>
                                <Typography data-testid="end-pallet-total">
                                    {formatNumberWithLocale(cultureCode, endPallets)}{' '}
                                    {getFormattedSelectedQuantityUnit('pallets')}
                                </Typography>
                                <Typography
                                    className={classes.canEachesTotal}
                                    data-testid="end-eaches-total"
                                >
                                    {`${formatNumberWithLocale(
                                        cultureCode,
                                        Number(endsEachesTotal)
                                    )} ${getFormattedSelectedQuantityUnit('eaches')}`}
                                </Typography>
                            </div>
                        </Grid>
                        {!scrapIt && (
                            <Grid container item>
                                <Grid item xs={10}>
                                    <Typography>
                                        <Trans i18nKey="trucksVarianceInstructions">
                                            Number of trucks will vary by total weight of
                                            ends/closures in delivery.
                                        </Trans>
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </>
            )}
        </Grid>
    );
}
