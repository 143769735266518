import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import moment from 'moment';
import { Moment } from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ballGray, ballLtGray, blackWeight, large, xs } from '../../../../themes/globalConstants';
import MakeItSpotWeekButton, { AllocationState } from './MakeItSpotWeekButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { white, addFormItemButtonBlue, boldWeight } from './../../../../themes/globalConstants';
import { AddCircleOutlineOutlined } from '@material-ui/icons';
import AddWeekModal from '../../../reusable/molecules/AddWeekModal';
import { useDispatch } from 'react-redux';
import { addSpotOrder } from '../../../../store/actions/makeit-bulk-atm';
import { enUS } from '../../../../utility/translations/locales';
import GuidedWeekButton from './GuidedWeekButton';
import { BulkUploadMakeItATMState } from './../../../../store/reducers/makeit-bulk-atm';
import { useTypedSelector } from '../../../../store/reducers/reducer';

interface AllocationStateForWeek {
    week: string;
    state: AllocationState;
}
interface Props {
    weeks: Moment[];
    selectedWeek: Moment;
    selectedWeeks?: string[];
    onWeekClick: (week: Moment) => void;
    onWeekAdded?: (week: Moment) => void;
    allocationStateByWeek: AllocationStateForWeek[];
    showAddWeek: boolean;
    guided?: boolean;
}

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: blackWeight,
        color: theme.palette.secondary.main,
        padding: '0 0 0.5em 0'
    },
    button: {
        border: `1px solid ${ballLtGray}`,
        maxWidth: '3em',
        minWidth: '3em'
    },
    noWeeks: {
        backgroundColor: white,
        padding: '1em 2em',
        '& .MuiTypography-body1': {
            fontSize: large,
            color: ballGray,
            fontStyle: 'italic'
        }
    },
    addWeek: {
        '& .MuiTypography-body1': {
            fontSize: xs,
            textTransform: 'capitalize',
            color: addFormItemButtonBlue,
            textDecoration: 'underline',
            marginLeft: '0.25em'
        },
        '& .MuiSvgIcon-root': {
            fontSize: xs,
            color: addFormItemButtonBlue,
            fontWeight: boldWeight
        }
    },
    stepNum: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        borderRadius: '50%',
        width: '1.875em',
        height: '1.875em',
        fontSize: large,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: blackWeight,
        marginTop: '0.25em'
    },
    stepWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1em'
    },
    stepTitle: {
        paddingLeft: '0.5em',
        fontWeight: blackWeight,
        color: theme.palette.secondary.main
    },
    topMargin: {
        marginTop: '1em'
    }
}));

const OrderWeekSelector = ({
    weeks,
    selectedWeek,
    selectedWeeks,
    onWeekClick,
    onWeekAdded,
    allocationStateByWeek,
    showAddWeek,
    guided
}: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const DISPLAY_WEEKS_MAX = !guided ? 6 : 5;
    const [startIndex, setStartIndex] = useState<number>(0);
    const [displayWeeks, setDisplayWeeks] = useState<Moment[]>(
        weeks ? weeks.slice(startIndex, DISPLAY_WEEKS_MAX + startIndex) : []
    );
    const [addWeekOpen, setAddWeekOpen] = useState<boolean>(false);
    const { readonly } = useTypedSelector<BulkUploadMakeItATMState>(
        (state) => state.bulkUploadMakeItATMState
    );

    if (weeks) {
        const newDisplayWeeks = weeks.slice(startIndex, DISPLAY_WEEKS_MAX + startIndex);
        if (displayWeeks.some((dw) => !newDisplayWeeks.includes(dw))) {
            setDisplayWeeks(newDisplayWeeks);
        }
    }
    const handleLeftClick = () => {
        const newStart = startIndex === 0 ? 0 : startIndex - 1;
        setStartIndex(newStart);
    };

    const handleRightClick = () => {
        const newStart =
            weeks.length - (DISPLAY_WEEKS_MAX + startIndex) > 0 ? startIndex + 1 : startIndex;
        setStartIndex(newStart);
    };

    const headingText = useMemo(() => {
        if (weeks.length <= DISPLAY_WEEKS_MAX) {
            return t('orderWeeks', 'Order Weeks').toUpperCase();
        } else {
            return `${t('orderWeeks', 'Order Weeks').toUpperCase()} (${weeks.length} ${t(
                'available',
                'Available'
            ).toUpperCase()})`;
        }
    }, [weeks, t]);

    useEffect(() => {
        if (weeks) {
            setDisplayWeeks(weeks.slice(startIndex, DISPLAY_WEEKS_MAX + startIndex));
        }
    }, [weeks, startIndex]);

    const onAddWeekClosed = (week?: Moment) => {
        setAddWeekOpen(false);
        if (week) {
            if (!guided) {
                dispatch(addSpotOrder(week));
            }
            if (onWeekAdded) {
                onWeekAdded(week);
            }
        }
    };

    return (
        <>
            <Grid container>
                {!guided && (
                    <Typography variant="subtitle2" className={classes.title} data-testid="heading">
                        {headingText}
                    </Typography>
                )}
                {guided && (
                    <Grid item className={classes.stepWrapper}>
                        <Typography component="span" className={classes.stepNum}>
                            1
                        </Typography>
                        <Typography variant="h3" className={classes.stepTitle}>
                            <Trans i18nKey="selectForecastWk">{enUS.selectForecastWk}</Trans>
                        </Typography>
                    </Grid>
                )}
                <Grid container alignItems="flex-start">
                    {weeks.length === 0 && (
                        <Grid item className={classes.noWeeks}>
                            <Typography>{t('noWeeksSelected', 'No weeks selected')}</Typography>
                        </Grid>
                    )}
                    {weeks.length > DISPLAY_WEEKS_MAX && (
                        <Button
                            data-testid="left-btn"
                            className={`${classes.button} ${classes.topMargin}`}
                            onClick={() => handleLeftClick()}
                            disabled={startIndex === 0}
                        >
                            <ChevronLeftIcon />
                        </Button>
                    )}
                    {!guided
                        ? displayWeeks.map((week) => (
                              <MakeItSpotWeekButton
                                  key={week.utc().format('MM/DD/YYYY')}
                                  date={week}
                                  isSelected={
                                      !selectedWeeks
                                          ? selectedWeek.isSame(week, 'day')
                                          : selectedWeeks.filter((wk) =>
                                                moment(wk).isSame(week, 'day')
                                            ).length > 0
                                  }
                                  handleClick={onWeekClick}
                                  allocationState={
                                      allocationStateByWeek.find((a) =>
                                          moment(a.week).isSame(week, 'week')
                                      )?.state ?? []
                                  }
                              />
                          ))
                        : displayWeeks.map((week) => (
                              <GuidedWeekButton
                                  key={week.utc().format('MM/DD/YYYY')}
                                  date={week}
                                  isSelected={
                                      !selectedWeeks
                                          ? selectedWeek.isSame(week, 'day')
                                          : selectedWeeks.filter((wk) =>
                                                moment(wk).isSame(week, 'day')
                                            ).length > 0
                                  }
                                  handleClick={onWeekClick}
                                  firstWeek={displayWeeks.length > 0 && week === displayWeeks[0]}
                              />
                          ))}
                    {weeks.length > DISPLAY_WEEKS_MAX && (
                        <Button
                            data-testid="right-btn"
                            className={`${classes.button} ${classes.topMargin}`}
                            onClick={() => handleRightClick()}
                            disabled={startIndex + DISPLAY_WEEKS_MAX === weeks.length}
                        >
                            <ChevronRightIcon />
                        </Button>
                    )}
                    {showAddWeek && (
                        <Grid item className={classes.topMargin}>
                            <Button
                                className={classes.addWeek}
                                onClick={() => setAddWeekOpen(true)}
                                disabled={readonly}
                            >
                                <AddCircleOutlineOutlined />
                                <Typography>{t('addWeek', 'Add Week')}</Typography>
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            {addWeekOpen && (
                <AddWeekModal
                    onSubmit={onAddWeekClosed}
                    existingWeeks={weeks}
                    planIt={guided}
                ></AddWeekModal>
            )}
        </>
    );
};

export default OrderWeekSelector;
