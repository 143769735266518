import {
    LinkedDeliveryOrder,
    ProductionOrder,
    ProductionOrderLine,
    ProductionOrderLineItem
} from '../../store/reducers/makeit-dashboard';
import {
    constructCanDescription,
    constructEndDescription,
    constructPDFCanDescription,
    constructPDFEndDescription,
    constructShortPDFCanDescription,
    constructShortPDFEndDescription,
    isProductCanType,
    isProductEndType
} from './order-helpers';
import {
    makeItCanBtlDefaultLeadTime,
    makeItEndsDefaultLeadTime,
    makeItRequestedShipDays
} from '../../themes/globalConstants';

import moment from 'moment-timezone/builds/moment-timezone-with-data';
import { getUtcMidnight, addDays, getCurrentDate } from './date-helpers';
import { CampaignRun, MoqData, Product } from '../../store/reducers/product-portfolio';
import { Order_Status, ProductType } from '../services/orders-service';
import { OrderProduct, OrderProductTableRow } from '../../store/reducers/orders-dashboard';
import { LeadTimesResponse } from '../services/product-service';
import { End } from '../../store/reducers/product-end-details';
import { MakeItBulkLineItem } from '../../store/reducers/makeit-bulk-upload';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsights } from '../AppInsights';
import { QuantityUnit } from '../../store/reducers/shipping-dashboard';
import { ForecastStatus } from '../../store/reducers/makeit-bulk-atm';

export const getDesc = (line: ProductionOrderLineItem) => {
    return line.productType === ProductType.Ends
        ? constructPDFEndDescription(line)
        : constructPDFCanDescription(line);
};

export const getShapeSizeDescForOrderProduct = (line: OrderProduct | MakeItBulkLineItem) => {
    return line.type === ProductType.Ends
        ? constructShortPDFEndDescription(line)
        : constructShortPDFCanDescription(line);
};

export const createProductRequestObj = (products, currentlyAdded, shipToIds: string[]) => {
    // This if() statement prevents continual loading loop if no products
    if (products.length > 0) {
        let types = new Set<string>();
        let volumes = new Set<number>();
        let styles = new Set<string>();

        products.forEach((product) => {
            const canBottleDesc = constructCanDescription(product);
            const endDesc = constructEndDescription(product);
            const isEndType = isProductEndType(product.type);
            product.description = isEndType ? endDesc : canBottleDesc;

            if (product.size) {
                volumes.add(product.size);
            }
            if (product.shape) {
                styles.add(product.shape);
            }
            if (product.type) {
                types.add(product.type);
            }

            if (currentlyAdded && currentlyAdded.length > 0) {
                currentlyAdded.forEach((item) => {
                    if (item.productSku === product.productSku) {
                        product.addDisabled = true;
                    }
                });
            }
            if (product.destinations) {
                product.destinations?.forEach((destination) => {
                    shipToIds.forEach((shipToId) => {
                        if (destination.shipToId.toString() === shipToId) {
                            product.customerProductId = destination.customerProductId;
                            product.customerProductName = destination.customerProductName;
                        }
                    });
                });
            }
            product.displayId = product.productSku;
            product.displayName = product.customerProductName
                ? product.customerProductName
                : product.name;
            product.graphicIdAndVersion =
                product.graphicId && product.graphicVersion
                    ? product.graphicId + '-' + product.graphicVersion
                    : '';
        });

        return {
            types: Array.from(types),
            volumes: Array.from(volumes),
            styles: Array.from(styles)
        };
    }
};

export const isWithinWeekRange = (currentDate: moment, dateToEvaluate: moment, range: number) => {
    return moment(dateToEvaluate).isBefore(currentDate.add(range, 'weeks'), 'day');
};

export const getWithinRange = (
    minimumRangeValue: number,
    currentQuantity: number,
    minimumOrderQuantity: number
) => {
    return minimumRangeValue <= currentQuantity && currentQuantity < minimumOrderQuantity;
};

export const getCustomerProductInformation = (product: any, shipToId: number) => {
    let customerProductInformation = {
        customerProductId: undefined,
        customerProductName: undefined,
        status: undefined
    };

    if (product.destinations) {
        product.destinations?.forEach((destination) => {
            if (destination.shipToId === shipToId) {
                customerProductInformation.customerProductId =
                    destination.customerProductId || undefined;
                customerProductInformation.customerProductName =
                    destination.customerProductName || undefined;
                customerProductInformation.status = destination.status || undefined;
            }
        });
    }

    return customerProductInformation;
};

export const getProductsWithFees = (products: ProductionOrderLine[]) => {
    return products.filter((product) => {
        let absoluteMinimum: number | undefined = undefined;
        if ((product.moqFees && product.moqFees.length > 0) || product.minimumOrderQuantity) {
            absoluteMinimum =
                product.moqFees && product.moqFees.length > 0
                    ? getMoqFeeFloor(product.moqFees)
                    : product.minimumOrderQuantity;
        }
        return (
            !product.palletQuantity ||
            product.palletQuantity === 0 ||
            (absoluteMinimum && product.palletQuantity < absoluteMinimum) ||
            (product.originalPalletQuantity &&
                product.palletQuantity! > product.originalPalletQuantity)
        );
    });
};

export const getOrderLines = (product, splitLines, graphicIdAndVersion, productDescription) => {
    let orderLines: ProductionOrderLine[] = [];
    splitLines.forEach((line) => {
        const quantity = line.quantity ? line.quantity : line.palletQuantity;
        const requestedDateInformation = getRequestedDate(line);
        orderLines.push({
            ...product,
            productionOrderLineId: line.productionOrderLineId ?? undefined,
            originalPalletQuantity: line.originalPalletQuantity ?? product.originalPalletQuantity,
            originalRequestedDate: line.originalRequestedDate ?? product.originalRequestedDate,
            isLineRemoved: line.isLineRemoved ?? undefined,
            palletQuantity: quantity,
            splitOrder: true,
            requestedDate: requestedDateInformation.requestedDate,
            invalidRequestedDate: requestedDateInformation.invalidRequestedDate,
            graphicIdAndVersion: graphicIdAndVersion,
            description: productDescription
        });
    });
    return orderLines;
};

export function getRequestedDate(line: ProductionOrderLine) {
    const leadTime = getMakeItLeadTime(line);
    const baseLeadTime = moment().add(leadTime, 'weeks');
    const utcBaseLeadTime = getUtcMidnight(baseLeadTime);
    const requestedDate = getUtcMidnight(line.requestedDate);
    const invalidRequestedDate = moment(requestedDate).isBefore(utcBaseLeadTime);
    const requestedDateInformation = {
        requestedDate: requestedDate,
        invalidRequestedDate: invalidRequestedDate
    };
    return requestedDateInformation;
}

export function createAppInsightsError(
    userEmail: string,
    invalidRequestedDates: ProductionOrderLine[],
    shipToId: string,
    productionOrderId?: number
) {
    if (appInsights) {
        let appInsightsErrorMessage = `submitMakeItOrder-${userEmail}-shipToId-${shipToId}-`;

        invalidRequestedDates.forEach((line) => {
            appInsightsErrorMessage += `productSKU-${line.productSku}-requestedDate-${line.requestedDate}`;
        });

        if (productionOrderId) {
            appInsightsErrorMessage += `-productionOrderId-${productionOrderId}`;
        }
        let appInsightsError = new Error();
        appInsightsError.name = 'EUIPO10000';
        appInsightsError.message = appInsightsErrorMessage;

        appInsights.trackException({
            error: appInsightsError,
            severityLevel: SeverityLevel.Error
        });
    }
}

export function getRelatedProductLines(lines: ProductionOrderLine[], line: ProductionOrderLine) {
    let relatedProductLines: ProductionOrderLine[] = [];
    if (lines.length > 1) {
        relatedProductLines = lines.filter(
            (currentLine) =>
                currentLine.productSku === line.productSku &&
                currentLine.status !== Order_Status.Cancelled &&
                currentLine.status !== Order_Status.CancelledPending
        ) as ProductionOrderLine[];
    }
    return relatedProductLines;
}

export function validateSkus(lines: ProductionOrderLine[], line: ProductionOrderLine) {
    return lines.filter((currentLine) => currentLine.productSku === line.productSku).length === 0;
}

export function getEarliestDate(dates) {
    return dates.reduce((prev, curr) => {
        return prev < curr ? prev : curr;
    });
}

export function getMatchingProductInformation(
    products: Product[],
    line: ProductionOrderLine | OrderProductTableRow,
    size?: number,
    type?: string,
    shape?: string,
    ends?: End[],
    regionCode?: string
): ProductionOrderLine | OrderProductTableRow {
    return tryMatchProductInformation(products, line, size, type, shape, ends, regionCode)[0];
}

function tryMatchProductInformation(
    products: Array<Product>,
    line: ProductionOrderLine | OrderProductTableRow,
    size?: number,
    type?: string,
    shape?: string,
    ends?: Array<End>,
    regionCode?: string
): [ProductionOrderLine | OrderProductTableRow, Product | undefined] {
    let matchingProduct = products.find(
        (item) =>
            (item.volumeOz === size || item.volumeML === size) &&
            item.type.toUpperCase() === type &&
            item.style?.styleCode === shape
    );

    // If we have a match between both APIs then we update
    // the lead time and MOQ information
    if (matchingProduct) {
        line.leadTimeWeeks = matchingProduct.leadTimeWeeks;
        line.minimumOrderQuantity = getMinimumOrderQuantity(line, matchingProduct);
        line.moqFees = matchingProduct.moqFees;
        line.campaignRuns = matchingProduct.campaignRuns;
        line.campaignTiming = matchingProduct.campaignTiming;
        line.overvarnish = matchingProduct.overVarnish;
        line.fillRetort =
            matchingProduct.retortFillVolumeML || matchingProduct.retortFillVolumeOz
                ? 'RETORT'
                : undefined;
        if (matchingProduct.requestShipToId) {
            line.requestShipToId = matchingProduct.requestShipToId;
        }
    } else {
        line.minimumOrderQuantity = getMinimumOrderQuantity(line);
        let matchingEnd: End | undefined = undefined;
        if (isProductEndType(line.type)) {
            matchingEnd = ends?.find(
                (e) => e.endCode === `${line.shape}_${line.size}_${regionCode}`
            );
        }
        if (matchingEnd) {
            line.leadTimeWeeks = matchingEnd.leadTimeWeeks;
        } else {
            line.leadTimeWeeks = getMakeItLeadTime(line);
        }
    }

    return [line, matchingProduct];
}

export function getProductLeadTime(line: OrderProductTableRow, leadTimes: LeadTimesResponse[]) {
    const matchingProduct = leadTimes.find((product) => product.referenceId === line.productSku);
    if (matchingProduct && matchingProduct.leadTimeWeeks) {
        return matchingProduct.leadTimeWeeks;
    } else {
        return line.type === ProductType.Cans || line.type === ProductType.Bottles
            ? makeItCanBtlDefaultLeadTime
            : makeItEndsDefaultLeadTime;
    }
}

export function getMakeItMatchingProductInformation(
    products: Product[],
    line: OrderProductTableRow,
    size?: number,
    type?: string,
    shape?: string
) {
    let [orderProductTableRowLine, matchingProduct] = tryMatchProductInformation(
        products,
        line,
        size,
        type,
        shape
    ) as [OrderProductTableRow, Product | undefined];

    if (matchingProduct && matchingProduct.campaignTiming) {
        orderProductTableRowLine.campaignTiming = matchingProduct.campaignTiming;
        orderProductTableRowLine.campaignRuns = matchingProduct.campaignRuns;
    }

    return orderProductTableRowLine;
}

export function formatLineInformation(
    line: ProductionOrderLine,
    linkedDeliveryOrder: LinkedDeliveryOrder
) {
    line.originalPalletQuantity = line.palletQuantity;
    line.originalRequestedDate = line.requestedDate;
    line.displayId = line.productSku;
    line.displayName = line.customerProductName ? line.customerProductName : line.productName;

    line.minimumMet = true;
    line.withinRange = true;

    if (linkedDeliveryOrder.linkedDeliveryOrders) {
        linkedDeliveryOrder.linkedDeliveryOrders.forEach((deliveryOrder) => {
            if (deliveryOrder.productionOrderLineId === line.productionOrderLineId) {
                line.hasLinkedDeliveryOrder = true;
            }
            if (deliveryOrder.status === Order_Status.Cancelled) {
                line.hasCancelledDeliveryOrder = true;
            }
        });
    }

    return line;
}

export function getMakeItLeadTime(line) {
    if (line.leadTimeWeeks) {
        return line.leadTimeWeeks;
    } else {
        return (line.leadTimeWeeks =
            line.type === ProductType.Cans || line.type === ProductType.Bottles
                ? makeItCanBtlDefaultLeadTime
                : makeItEndsDefaultLeadTime);
    }
}

export const getRequiredShipDate = (requestDate?: string) => {
    return requestDate
        ? addDays(requestDate, makeItRequestedShipDays, false)
        : addDays(getCurrentDate(), makeItRequestedShipDays, false);
};

export function getCampaigns(products: MakeItBulkLineItem[]): {
    updatedCampaigns: CampaignRun[];
    campaignProducts: ProductionOrderLine[];
} {
    const today = moment();
    let updatedCampaigns = [] as CampaignRun[];
    let newCampaignProducts = [] as ProductionOrderLine[];
    let campaignRunIds: number[] = [];

    products.forEach((product) => {
        if (product.campaignTiming && product.status === 'ACTIVE') {
            // filter products on description because we only want to show one row per description
            // filtering by product sku or otherwise may show multiple rows with the same product description
            let newCampaignProduct = newCampaignProducts.find(
                (p) => p.description === product.description
            );
            if (!newCampaignProduct) {
                //might need to add getShapeSize method here for description
                product.productDescription = getShapeSizeDescForOrderProduct(product);
                newCampaignProducts.push(product);
            }

            product.campaignRuns?.forEach((campaign) => {
                let endDate = moment(campaign.orderEndDate).startOf('day');
                let todayDate = today.startOf('day');
                if (
                    endDate.isSameOrAfter(todayDate) &&
                    campaignRunIds.indexOf(campaign.campaignRunId) === -1
                ) {
                    let newCampaign = campaign;
                    newCampaign.productDescription = getShapeSizeDescForOrderProduct(product);
                    updatedCampaigns.push(newCampaign);
                    campaignRunIds.push(newCampaign.campaignRunId);
                }
            });
        }
    });

    return { updatedCampaigns: updatedCampaigns, campaignProducts: newCampaignProducts };
}

export function resetItemQuantities(item: OrderProductTableRow) {
    return {
        ...item,
        quantity: 0,
        palletQuantity: 0,
        eachesQuantity: 0
    };
}

export function resolveQuantity(quantityUnit: QuantityUnit, item: OrderProductTableRow) {
    switch (quantityUnit) {
        case 'pallets':
            return item.palletQuantity ?? 0;
        case 'eaches':
            return item.eachesQuantity ?? 0;
        default:
            return 0;
    }
}

export function getEaches(palletQuantity: number, quantityPerPallet?: number) {
    return quantityPerPallet ? palletQuantity * quantityPerPallet : 1;
}

export function getOrderExistsInWeek(requestedDate: string, orders: ProductionOrder[]): boolean {
    const existingOrdersExistsInWeek = orders.filter((order) => {
        const orderIsNotForecast = (order as any).atmOrderType !== ForecastStatus.Forecast;
        const orderIsActive =
            order.status !== Order_Status.Cancelled && order.status !== Order_Status.Draft;

        const orderWeekStartIsOnRequestedDate = moment(order.atmWeekStart).isSame(
            moment(requestedDate),
            'day'
        );

        return orderIsNotForecast && orderIsActive && orderWeekStartIsOnRequestedDate;
    });
    return existingOrdersExistsInWeek.length > 0;
}

export function getCampaignInformation(
    products: OrderProductTableRow[] | MakeItBulkLineItem[],
    shipToId: string
) {
    let updatedCampaigns = [] as CampaignRun[];
    let filteredCampaignProducts = [] as OrderProductTableRow[];
    const today = moment();

    products.forEach((product) => {
        if (
            product.campaignTiming &&
            product.destinations &&
            product.destinations.filter(
                (destination) => destination.shipToId.toString() === shipToId
            ).length > 0
        ) {
            // filter products on description because we only want to show one row per description
            // filtering by product sku or otherwise may show multiple rows with the same product description
            let duplicateCampaignProduct = filteredCampaignProducts.find(
                (p) => p.description === product.description
            );
            if (!duplicateCampaignProduct) {
                filteredCampaignProducts.push(product);
            }

            product.campaignRuns?.forEach((campaign) => {
                let endDate = moment(campaign.orderEndDate).startOf('day');
                let todayDate = today.startOf('day');
                let isUniqueCampaignRun =
                    updatedCampaigns.findIndex(
                        (x) => x.campaignRunId === campaign.campaignRunId
                    ) === -1;
                if (endDate.isSameOrAfter(todayDate) && isUniqueCampaignRun) {
                    let newCampaign = campaign;
                    newCampaign.productDescription = getShapeSizeDescForOrderProduct(product);
                    updatedCampaigns.push(newCampaign);
                }
            });
        }
    });

    return {
        updatedCampaigns: updatedCampaigns,
        filteredCampaignProducts: filteredCampaignProducts
    };
}

export function getMoqFeeFloor(moqFees: MoqData[] | undefined): number {
    return moqFees ? Math.min(...moqFees.map((p) => p.palletFloor)) : 0;
}

export function getMoqFeeCeiling(moqFees: MoqData[] | undefined): number {
    return moqFees ? Math.max(...moqFees.map((p) => p.palletCeiling)) : 0;
}

export function getMoqFee(palletQuantity?: number, moqFees?: MoqData[]): number | undefined {
    let feeValue: number | undefined = undefined;
    if (palletQuantity && moqFees && moqFees.length > 0) {
        const moqFee = moqFees.find(
            (f) => f.palletFloor <= palletQuantity && f.palletCeiling >= palletQuantity
        );
        feeValue = moqFee ? moqFee.fee : undefined;
    }
    return feeValue;
}

const getMinimumOrderQuantity = (
    line: ProductionOrderLine | OrderProductTableRow,
    matchingProduct?: Product
): number | undefined => {
    const productCharacteristicMoq = 25;
    const isMoqFromProductCharacteristic =
        isProductCanType(line.type) && getMoqFromProductCharacteristics(line);
    let minimumOrderQuantity: number | undefined = undefined;
    if (matchingProduct) {
        if (matchingProduct.isAccountMOQ) {
            // highest priority is account level MOQ if it is less than MOQ based on product characteristics
            if (
                isMoqFromProductCharacteristic &&
                productCharacteristicMoq < matchingProduct.minOrderQuantityPallets
            ) {
                minimumOrderQuantity = productCharacteristicMoq;
            } else {
                minimumOrderQuantity = matchingProduct.minOrderQuantityPallets;
            }
        } else if (isMoqFromProductCharacteristic) {
            // second priority is MOQ based on product characteristics, if applicable
            minimumOrderQuantity = productCharacteristicMoq;
        } else {
            // third priority is MOQ from the product API
            // based on customerType, custom MOQ for product, or the default
            minimumOrderQuantity = matchingProduct.minOrderQuantityPallets;
        }
    } else if (isMoqFromProductCharacteristic) {
        // if there is no matching product from the product API
        // then the app can only set MOQ based on product characteristics, if applicable
        minimumOrderQuantity = productCharacteristicMoq;
    }
    return minimumOrderQuantity;
};

const getMoqFromProductCharacteristics = (
    line: ProductionOrderLine | OrderProductTableRow
): boolean => {
    if (
        (line.beverageCode && ['TB1', 'TB2', 'TB3'].includes(line.beverageCode)) ||
        (line.retort && line.retort.toUpperCase() === 'RETORT') ||
        (line.tabIndicator &&
            ['WDGBNI', 'WDGISO', 'WDGNI2', 'WDGQ11', 'WDISN2', 'WID', 'WIDGET'].includes(
                line.tabIndicator.toUpperCase()
            ))
    ) {
        return true;
    }
    return false;
};
