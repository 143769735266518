import React from 'react';
import { Grid } from '@material-ui/core';

interface TabPanelProps {
    children: React.ReactNode;
    index: number;
    value: number;
    label: string;
}

export default function TabPanel ({children, index, value, label, ...other}: TabPanelProps) {
    return (
        <Grid container
            role="tabpanel"
            hidden={value !== index}
            aria-labelledby={label}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </Grid>
    );
}