import React, { ReactElement, ReactNode, useState, useEffect } from 'react';
import Header from '../reusable/molecules/Header';
import Footer from '../reusable/molecules/Footer';
import { Section } from '../../store/reducers/glp';
import { Grid, makeStyles, AppBar, useTheme, useMediaQuery, Container } from '@material-ui/core';
import MobileHeader from '../reusable/molecules/MobileHeader';
import MobileFooter from '../reusable/molecules/MobileFooter';
import { ltBlueGrey_15, containerMaxWidth } from '../../themes/globalConstants';
import ProcessingPageBanner from '../reusable/molecules/ProcessingPageBanner';
import ActionFooter from '../reusable/molecules/ActionFooter';
import LoadingIndicator from '../reusable/atoms/LoadingIndicator';
import { AuthState } from '../../store/reducers/auth';
import { CustomerContextState } from '../../store/reducers/customer-context';
import { useTypedSelector } from '../../store/reducers/reducer';
import {
    Activity,
    getATMSecurityLevel,
    getSecurityLevel,
    isATMActivity,
    SecurityLevel
} from '../../utility/auth/useSecurity';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getShipToAccounts } from '../../store/actions/customer-context';
import { loadState } from '../../utility/helpers/sessionStorage';
import { clearSecurityLevel, setSecurityLevel } from '../../store/actions/security-level';
import { selectIsLargeCustomer, selectIsLargeCustomerAccount } from '../../store/selectors';
import ToasterAlert from '../reusable/molecules/ToasterAlert';
import { CommunicationMessagesDisplayBannerModal } from '../reusable/CommunicationsMessagesDisplayBannerModal';

interface Props {
    key?: any;
    title?: string;
    children?: ReactNode;
    activity: Activity;
    restrictToSecurityLevel?: SecurityLevel;
    banner?: Section;
    actionFooter?: any;
    loading?: boolean;
    shipTos?: boolean;
    onlyCheckSelectedLargeAccount?: boolean;
    fallbackActivity?: Activity;
    fallbackPath?: string;
    errorMessage?: string;
}

const useStyles = makeStyles((theme) => ({
    //Sets the blue background to the entire width of the screen
    root: {
        width: '100%',
        height: '100%',
        backgroundColor: ltBlueGrey_15
    },
    mainContainer: {
        maxWidth: containerMaxWidth,
        minHeight: 800
    }
}));

export function ProcessingPageTemplate({
    children,
    activity,
    title,
    banner,
    actionFooter,
    loading,
    restrictToSecurityLevel,
    shipTos = true,
    onlyCheckSelectedLargeAccount = false,
    fallbackActivity,
    fallbackPath,
    errorMessage
}: Props): ReactElement {
    const classes = useStyles();
    const theme = useTheme();
    const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const smScreens = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const history = useHistory();
    const { selectedAccountId, shipToAccounts, loaded } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const securityContext = {
        accountId: selectedAccountId as string
    };
    const auth = useTypedSelector<AuthState>((state) => state.auth);
    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);
    const [permissionsCheck, setPermissionsCheck] = useState(false);
    const [showError, setShowError] = useState(false);
    const isLargeCustomer = useTypedSelector<boolean>(
        onlyCheckSelectedLargeAccount ? selectIsLargeCustomerAccount : selectIsLargeCustomer
    );
    
    const isAuthorizedForActivity = (activity: Activity, personas: string | undefined) => {
        let currentSecurityLevel: SecurityLevel = SecurityLevel.None;
        if (isATMActivity(activity) && shipToAccounts && shipToAccounts?.length > 0) {
            currentSecurityLevel = getATMSecurityLevel(
                activity,
                auth,
                securityContext,
                isLargeCustomer
            );
        } else {
            currentSecurityLevel = getSecurityLevel(activity, auth, securityContext);
        }

        dispatch(setSecurityLevel(activity, currentSecurityLevel, personas));
        let authorized = currentSecurityLevel !== SecurityLevel.None;

        if (restrictToSecurityLevel) {
            authorized = currentSecurityLevel === restrictToSecurityLevel;
        }

        return authorized;
    };

    useEffect(() => {
        return () => {
            dispatch(clearSecurityLevel());
        };
    }, []);

    useEffect(() => {
        setShowError(true);
    }, [errorMessage]);

    useEffect(() => {
        if (shipTos && permissions?.length && !shipToAccounts?.length) {
            dispatch(getShipToAccounts());
        }
    }, [dispatch, permissions, shipToAccounts, shipTos]);

    useEffect(() => {
        if (permissions) {
            if (permissions.length) {
                // If no account ID, see if one is in local storage
                if (!securityContext.accountId) {
                    var accountId = loadState('selectedAccountId');

                    if (accountId) {
                        securityContext.accountId = accountId;
                    }
                }
                const personas = auth.permissions
                    ?.map((permission) => permission.personaId)
                    .join(', ');

                if (!isAuthorizedForActivity(activity, personas)) {
                    if (
                        fallbackActivity &&
                        fallbackPath &&
                        isAuthorizedForActivity(fallbackActivity, personas)
                    ) {
                        history.push(fallbackPath);
                    } else {
                        history.push('/unauthorized', { activity });
                    }
                }
            }
            const invalidUserOnPage =
                (shipToAccounts && shipToAccounts.length === 0) ||
                !shipToAccounts ||
                permissions.length === 0;

            // Permissions are checked when accounts are loaded if ShipTos are required
            if (((shipTos && shipToAccounts?.length) || !shipTos || invalidUserOnPage) && loaded) {
                setPermissionsCheck(true);
                if (
                    invalidUserOnPage === true &&
                    activity !== Activity.OpenAccess &&
                    permissions.length === 0
                ) {
                    history.push('/unauthorized');
                }
            }
        } else {
            dispatch(clearSecurityLevel());
        }
    }, [dispatch, permissions, selectedAccountId, shipToAccounts, loaded]);

    return (
        <div className={classes.root}>
            <AppBar position="sticky">
                {xsScreen ? <MobileHeader title={title} /> : <Header title={title} />}
            </AppBar>

            {banner && (
                <section>
                    <ProcessingPageBanner {...banner} filter={activity} />
                </section>
            )}
            {loading ? <LoadingIndicator /> : null}
            <CommunicationMessagesDisplayBannerModal activity={activity} />
            {permissionsCheck ? (
                <Container className={classes.mainContainer}>
                    <ToasterAlert
                        message={errorMessage}
                        showAlert={showError}
                        severity="error"
                        adjustedPlacement="center"
                    />
                    <div>{children}</div>
                </Container>
            ) : null}
            {actionFooter && <ActionFooter {...actionFooter} />}
            {smScreens ? <MobileFooter /> : <Footer />}
        </div>
    );
}
