import React, { useEffect, useState } from 'react';
import { makeStyles, Grid, MenuItem } from '@material-ui/core';
import NavMenu from './NavMenu';
import { useTranslation, Trans } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import Link from '../atoms/Link';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { GlpState } from '../../../store/reducers/glp';
import CanResearchTile from './CanResearchTile';
import NavTile from './NavTile';
import { useDispatch } from 'react-redux';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { loadGlobalLandingPageContent } from '../../../store/actions/glp';
import Logo from '../atoms/Logo';
import Modal from '../molecules/Modal';
import ContactForm from '../molecules/ContactForm';
import {
    ballGreen,
    black_8,
    boldWeight,
    containerMaxWidth,
    medium
} from '../../../themes/globalConstants';
import { Persona, hasPersonas } from '../../../utility/auth/useSecurity';
import { Region } from '../../../store/reducers/graphic-intake';
import { AuthState } from '../../../store/reducers/auth';
import clsx from 'clsx';
import NavItem from './NavItem';
import { selectIsOnlyGraphicVendor } from '../../../store/selectors';

interface Props {
    loading?: boolean;
}

const useStyles = makeStyles((theme) => ({
    appBar: {
        marginTop: '2em'
    },
    homeButton: {
        marginRight: theme.spacing(1)
    },
    container: {
        maxWidth: containerMaxWidth,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    title: {
        flexGrow: 1,
        display: 'block'
    },
    contentTitle: {
        fontSize: 13,
        fontWeight: boldWeight,
        letterSpacing: 1,
        color: theme.palette.secondary.main
    },
    link: {
        fontSize: 11,
        fontWeight: boldWeight,
        color: theme.palette.secondary.main,
        letterSpacing: '1px',
        textTransform: 'uppercase'
    },
    modal: {
        '& .MuiPaper-root': {
            maxWidth: '40.5em'
        },
        '& .MuiDialog-paperScrollPaper': {
            borderRadius: 'unset',
            overflow: 'hidden'
        },
        '& .MuiDialogTitle-root': {
            justifyContent: 'center',
            marginLeft: '2.5em'
        }
    },
    menuItems: {
        overflow: 'visible',
        width: 'fit-content'
    },
    linkMenuItem: {
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        alignItems: 'center'
    },
    button: {
        fontSize: medium,
        fontWeight: boldWeight,
        letterSpacing: '1px',
        textTransform: 'capitalize',
        color: theme.palette.common.black
    }
}));

const tileStyles = makeStyles((theme) => ({
    root: {
        height: '15em',
        transition: 'transform .4s',
        borderRight: `1px solid ${theme.palette.info.light}`,
        '& .header-icon': {
            fill: theme.palette.secondary.light
        },
        //Hover State
        '&:hover': {
            transform: 'scale(1.1)',
            overflow: 'visible',
            boxShadow: `0 0 10px 0 ${black_8}`,
            '& .header-text': {
                color: theme.palette.primary.main
            },
            '& .header-icon': {
                fill: ballGreen
            }
        },
        '& .header-text': {
            fontSize: 13,
            fontWeight: 'bold',
            letterSpacing: 1,
            color: theme.palette.secondary.main
        }
    },
    link: {
        textDecoration: 'none'
    },
    cardHeader: {
        height: '0'
    },
    visitedIcon: {
        fill: ballGreen
    },
    textFields: {
        textAlign: 'center'
    },
    mediaIcon: {
        marginBottom: 10
    }
}));

const NavBar = ({ loading }: Props) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const { regionCode, cultureCode } = useTypedSelector<RegionCultureState>(
        (state) => state.regionCulture
    );
    const { permissions } = useTypedSelector((state) => state.auth);

    const auth = useTypedSelector<AuthState>((state) => state.auth);
    const glpState = useTypedSelector<GlpState>((state) => state.glp);
    const hasOnlyVendorPermissions = useTypedSelector<boolean>(selectIsOnlyGraphicVendor);

    const [faqLink, setFaqLink] = useState<string>(`/content/${regionCode}/Pages/FAQ`);
    const [designLink, setDesignLink] = useState<string>(
        `/source/content/${regionCode}/Pages/Design-Inspiration`
    );
    const [processLink, setProcessLink] = useState<string>('/');
    const [open, setOpen] = useState<boolean>(false);
    const [isVendor, setIsVendor] = useState<boolean>(true);
    const [showJoiningBall, setShowJoiningBall] = useState<boolean>(false);

    const content = glpState.canContent;
    const partneringLinks = glpState.partnering?.links;
    const bannerLink = glpState.banner?.links;
    const bottleLink =
        regionCode === Region.EU
            ? '/product-portfolio?category=styles&codes=ATK,IMP&label=Alumi,Impact'
            : '/product-portfolio?category=styles&codes=ATK&label=Alumi';
    const closeContactForm = () => {
        setOpen(false);
    };

    const openContactForm = () => {
        setOpen(true);
    };

    useTranslation();

    useEffect(() => {
        if (
            !glpState.canContent ||
            regionCode !== glpState.loadedRegion ||
            cultureCode !== glpState.loadedLanguage
        ) {
            dispatch(loadGlobalLandingPageContent(regionCode, cultureCode));
        }
        if (permissions) {
            const hasVendorPersona = hasPersonas([Persona.GraphicsVendor], auth);
            setIsVendor(hasVendorPersona);

            const hasJoiningBallPerson = hasPersonas(
                [
                    Persona.JoiningAdmin,
                    Persona.JoiningBillingAndShippingAddresses,
                    Persona.JoiningCreditApplication,
                    Persona.JoiningSalesAndUseTax,
                    Persona.JoiningTermsAndConditions
                ],
                auth
            );
            setShowJoiningBall(hasJoiningBallPerson);
        }
    }, [
        dispatch,
        glpState.canContent,
        regionCode,
        cultureCode,
        glpState.loadedRegion,
        glpState.loadedLanguage,
        permissions,
        auth
    ]);

    useEffect(() => {
        if (partneringLinks && partneringLinks.length > 0) {
            partneringLinks.forEach((link) => {
                if (link.destination && link.destination.includes('FAQ')) {
                    setFaqLink('/content' + link.destination);
                } else if (link.destination && link.destination.includes('Design-Inspiration')) {
                    setDesignLink('/content' + link.destination);
                }
            });
        }
        if (bannerLink && bannerLink.length > 0) {
            setProcessLink(`/content${bannerLink[0].destination?.replace('%', '')}`);
        }
    }, [dispatch, bannerLink, processLink, partneringLinks]);

    const ncaMenuItems = [
        <MenuItem
            key="portfolio"
            disableGutters={true}
            className={clsx(classes.linkMenuItem, classes.menuItems)}
        >
            <Link
                component={RouterLink}
                to="/product-portfolio"
                underline="hover"
                aria-label="product portfolio"
                className={classes.link}
            >
                <Trans i18nKey="ballProductPortfolio">Ball Product Portfolio</Trans>
            </Link>
        </MenuItem>,
        <MenuItem
            key="build"
            disableGutters={true}
            className={clsx(classes.linkMenuItem, classes.menuItems)}
        >
            <Link
                component={RouterLink}
                to="/compare-and-build"
                underline="hover"
                aria-label="build and estimate"
                className={classes.link}
            >
                <Trans i18nKey="buildAndEstimateNav">Build & Estimate</Trans>
            </Link>
        </MenuItem>
    ];
    const regionalMenuItems = [
        <MenuItem
            key="portfolio"
            disableGutters={true}
            className={clsx(classes.linkMenuItem, classes.menuItems)}
        >
            <Link
                component={RouterLink}
                to="/product-portfolio"
                underline="hover"
                aria-label="product portfolio"
                className={classes.link}
            >
                <Trans i18nKey="ballProductPortfolio">Ball Product Portfolio</Trans>
            </Link>
        </MenuItem>,
        <MenuItem
            key="estimate"
            disableGutters={true}
            className={clsx(classes.linkMenuItem, classes.menuItems)}
        >
            <Link
                component={RouterLink}
                to="/product-portfolio"
                underline="hover"
                aria-label="get an estimate"
                className={classes.link}
            >
                <Trans i18nKey="getAnEstimate">Get an Estimate</Trans>
            </Link>
        </MenuItem>
    ];

    return (
        <>
            <Grid container item alignItems="center">
                <Logo />
                {(!isVendor || !hasOnlyVendorPermissions) && (
                    <>
                        {/* HOME */}
                        <NavMenu
                            menuName={<Trans i18nKey="home">Home</Trans>}
                            menuItems={[
                                <MenuItem
                                    key="homepage"
                                    disableGutters={true}
                                    className={clsx(classes.linkMenuItem, classes.menuItems)}
                                >
                                    <Link
                                        component={RouterLink}
                                        to="/"
                                        underline="hover"
                                        aria-label="homepage"
                                        className={classes.link}
                                    >
                                        <Trans i18nKey="homepage">My Home</Trans>
                                    </Link>
                                </MenuItem>,
                                <MenuItem
                                    key="canSchool"
                                    disableGutters={true}
                                    className={clsx(classes.linkMenuItem, classes.menuItems)}
                                >
                                    <Link
                                        component={RouterLink}
                                        to="/content/NA/Pages/Can-school"
                                        underline="hover"
                                        aria-label="can school"
                                        className={classes.link}
                                    >
                                        <Trans i18nKey="canSchool">Can School</Trans>
                                    </Link>
                                </MenuItem>
                            ]}
                        >
                            <Grid container>
                                <Grid item xs={4}>
                                    <NavTile
                                        link={designLink}
                                        translationKey="designInspiration"
                                        defaultText="Design Inspiration"
                                        source="Inspiration_Icon.png?ext=.png"
                                        isProduct={false}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <NavTile
                                        link={faqLink}
                                        translationKey="faq"
                                        defaultText="Frequently Asked Questions"
                                        source="FAQ_Icon.png?ext=.png"
                                        isProduct={false}
                                    />
                                </Grid>
                                <Grid item xs={4} onClick={openContactForm}>
                                    <NavTile
                                        translationKey="contactUs"
                                        defaultText="Contact Us"
                                        source="Contact_Us_Icon.png?ext=.png"
                                        isProduct={false}
                                    />
                                </Grid>
                            </Grid>
                        </NavMenu>

                        {/* OUR PROCESS */}
                        <NavMenu
                            menuName={<Trans i18nKey="ourProcess">Our Process</Trans>}
                            menuItems={[
                                <MenuItem
                                    key="overview"
                                    disableGutters={true}
                                    className={clsx(classes.linkMenuItem, classes.menuItems)}
                                >
                                    <Link
                                        component={RouterLink}
                                        to={processLink}
                                        underline="hover"
                                        aria-label="process overview"
                                        className={classes.link}
                                    >
                                        <Trans i18nKey="processOverview">Process Overview</Trans>
                                    </Link>
                                </MenuItem>
                            ]}
                        >
                            <Grid container>
                                {content &&
                                    content.subsections.map((subsection, subsectionIndex) => (
                                        <Grid item xs={2} key={subsection.header + subsectionIndex}>
                                            <CanResearchTile
                                                links={subsection.links}
                                                header={subsection.header}
                                                styles={tileStyles}
                                                key={subsectionIndex}
                                            />
                                        </Grid>
                                    ))}
                            </Grid>
                        </NavMenu>

                        {/* OUR PRODUCTS */}
                        <NavMenu
                            menuName={<Trans i18nKey="ourProducts">Our Products</Trans>}
                            menuItems={regionCode === Region.NA ? ncaMenuItems : regionalMenuItems}
                        >
                            <Grid container>
                                {regionCode !== Region.SA && (
                                    <Grid item xs={2}>
                                        <NavTile
                                            link="/product-portfolio?category=styles&codes=SLM&label=Slim"
                                            defaultText="Slim"
                                            source={`Navbar/${regionCode}_slim.png`}
                                            isProduct={true}
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={2}>
                                    <NavTile
                                        link="/product-portfolio?category=styles&codes=SLK&label=Sleek"
                                        defaultText="Sleek"
                                        source={`Navbar/${regionCode}_sleek.png`}
                                        isProduct={true}
                                    />
                                </Grid>
                                {regionCode === Region.SA && (
                                    <Grid item xs={2}>
                                        <NavTile
                                            link="/product-portfolio?category=styles&codes=XSLK&label=X-Sleek"
                                            defaultText="X-Sleek"
                                            source={`Navbar/${regionCode}_xsleek.png`}
                                            isProduct={true}
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={2}>
                                    <NavTile
                                        link="/product-portfolio?category=styles&codes=STD&label=Standard"
                                        defaultText="Standard"
                                        source={`Navbar/${regionCode}_standard.png`}
                                        isProduct={true}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <NavTile
                                        link="/product-portfolio?category=styles&codes=KNG&label=King"
                                        defaultText="King"
                                        source={`Navbar/${regionCode}_king.png`}
                                        isProduct={true}
                                    />
                                </Grid>
                                {regionCode !== Region.SA && (
                                    <Grid item xs={2}>
                                        <NavTile
                                            link={bottleLink}
                                            defaultText="Bottles"
                                            source={`Navbar/${regionCode}_bottles.png`}
                                            isProduct={true}
                                        />
                                    </Grid>
                                )}
                                {regionCode === Region.NA && (
                                    <Grid item xs={2}>
                                        <NavTile
                                            link="/product-portfolio?category=styles&codes=CAP&label=CapCan"
                                            defaultText="Cap Can"
                                            source={`Navbar/${regionCode}_cap_can.png`}
                                            isProduct={true}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </NavMenu>
                        {showJoiningBall && regionCode === Region.NA && (
                            <NavItem
                                menuName={<Trans i18nKey="joiningBall">Joining Ball</Trans>}
                                link="/onboard"
                            ></NavItem>
                        )}
                    </>
                )}
            </Grid>

            <Modal
                open={open}
                close={closeContactForm}
                title={<Trans i18nKey="howCanWeHelp">How Can We Help?</Trans>}
                closeIcon={true}
                fullWidth={true}
                className={classes.modal}
            >
                {<ContactForm messageType={'CONTACT'} onClose={closeContactForm} />}
            </Modal>
        </>
    );
};

export default NavBar;
