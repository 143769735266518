import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles, Typography, TableRow, TableCell, Grid } from '@material-ui/core';
import {
    small,
    regularWeight,
    warningLabel,
    blackWeight,
    informationAlert
} from '../../../../themes/globalConstants';
import Button from '../../../reusable/atoms/Button';
import { formatNumberWithLocale, numericRegex } from '../../../../utility/helpers/formatters';
import {
    constructCanDescription,
    constructEndDescription,
    isProductCanType,
    isProductEndType,
    getGraphicIdAndVersion,
    constructCanSubDescription,
    constructEndSubDescription
} from '../../../../utility/helpers/order-helpers';
import { OrderProductTableRow } from '../../../../store/reducers/orders-dashboard';
import {
    getEaches,
    getMoqFeeFloor,
    resolveQuantity
} from '../../../../utility/helpers/production-order-helpers';
import QuantityInputCell from '../../../reusable/atoms/QuantityInputCell';
import { resolvePalletCount } from '../../../../utility/helpers/shipment-helpers';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import CampaignPill from '../../../reusable/atoms/CampaignPill';
import { PlanItGuidedState } from '../../../../store/reducers/plan-it-guided';
import clsx from 'clsx';
import { getMoqFeeCeiling } from './../../../../utility/helpers/production-order-helpers';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { getPlanItProductStatus } from '../../../../utility/helpers/plan-it-helpers';

interface Props {
    item: OrderProductTableRow;
    onRemoveItem: (item: OrderProductTableRow) => void;
    onItemUpdates: (item: OrderProductTableRow) => void;
}

const useStyles = makeStyles((theme) => ({
    tableRowContainer: {
        '& .helper-text': {
            fontWeight: `${regularWeight} !important`,
            fontSize: small
        },
        '& .MuiInputBase-input': {
            textAlign: 'right',
            paddingRight: '.25em'
        }
    },
    alignCenter: {
        textAlign: 'center'
    },
    cellMainText: {
        fontWeight: blackWeight
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    warningInputContainer: {
        backgroundColor: warningLabel
    },
    warningText: {
        fontSize: small,
        fontStyle: 'italic'
    },
    warningBackground: {
        backgroundColor: informationAlert
    },
    trash: {
        minWidth: 'unset'
    }
}));

export default function PlanItConfigRow({ item, onRemoveItem, onItemUpdates }: Props) {
    const classes = useStyles();
    useTranslation();
    const { quantityUnit } = useTypedSelector<PlanItGuidedState>((state) => state.planItGuided);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [purchaseQty, setPurchaseQty] = useState<number | undefined>(item.quantity);
    const [inputQuantity, setInputQuantity] = useState<number | undefined>(
        item.quantity && resolveQuantity(quantityUnit, item)
    );
    const [, setTouchedInput] = useState<boolean>(item.modified || false);
    const canBottleDesc = constructCanDescription(item);
    const endDesc = constructEndDescription(item);
    const isEndType = isProductEndType(item.type);
    const isCanType = isProductCanType(item.type);
    const hasMoqFee = item.moqFees && item.moqFees.length > 0 ? true : false;

    // Removes main row from table
    const handleRemoveItem = () => {
        onRemoveItem(item);
    };

    // Updates total for rows without subrows
    const updatePurchaseCount = (count: string) => {
        let numOfPallets = 0;
        if (numericRegex(count)) {
            const parsedCount = parseInt(count);
            if (!isNaN(parsedCount)) {
                numOfPallets = resolvePalletCount(
                    parsedCount,
                    item.quantityPerPallet!,
                    quantityUnit
                );
                setInputQuantity(parsedCount);
                setPurchaseQty(numOfPallets);
            } else {
                setInputQuantity(numOfPallets);
                setPurchaseQty(numOfPallets);
            }
        } else {
            setInputQuantity(numOfPallets);
            setPurchaseQty(numOfPallets);
        }
    };

    useEffect(() => {
        setTouchedInput(item.modified ?? false);
        setPurchaseQty(item.quantity);
        setInputQuantity(item.quantity && resolveQuantity(quantityUnit, item));
    }, [item, quantityUnit]);

    useTranslation();

    const handleQuantityOnBlur = () => {
        const quantity = purchaseQty;
        item.quantity = quantity;
        item.palletQuantity = quantity;
        item.eachesQuantity = getEaches(quantity ?? 0, item.quantityPerPallet);
        item.modified = true;
        setTouchedInput(true);
        onItemUpdates(item);
    };

    return (
        <TableRow className={classes.tableRowContainer} data-testid={`product-${item.displayId}`}>
            <TableCell>
                <Typography>{item.customerProductId ? item?.customerProductId : "-"}</Typography>
                <Typography>{item.displayId}</Typography>
                <Typography>
                    {getGraphicIdAndVersion(item.type, item.graphicIdAndVersion)}
                </Typography>
            </TableCell>
            <TableCell
                data-testid="product-description"
                className={clsx({
                    [classes.warningBackground]: item.includedInExistingForecast
                })}
            >
                {item.includedInExistingForecast && (
                    <img
                        data-testid="info-icon"
                        src={process.env.PUBLIC_URL + '/assets/InfoTriangle_Icon.svg'}
                        alt="Information Icon"
                    />
                )}
                <Typography>{item.displayName}</Typography>
                {item.campaignTiming && <CampaignPill />}
                {item.includedInExistingForecast && (
                    <Typography className={classes.warningText}>
                        <Trans i18nKey="productIncluded">
                            Product included on previous forecast. Set quantity to 0 to remove.
                        </Trans>
                    </Typography>
                )}
            </TableCell>
            <TableCell data-testid="product-attributes">
                {isEndType && (
                    <>
                        <Typography>{endDesc}</Typography>
                        <Typography>{constructEndSubDescription(item)}</Typography>
                    </>
                )}
                {isCanType && (
                    <>
                        <Typography>{canBottleDesc}</Typography>
                        <Typography>{constructCanSubDescription(item)}</Typography>
                    </>
                )}
            </TableCell>
            <TableCell>
                <Typography>{getPlanItProductStatus(item.status)}</Typography>
                {/* Add the translations check per status */}
            </TableCell>
            <TableCell>
                <Typography>{item.coating}</Typography>
            </TableCell>
            <TableCell>
                {item.minimumOrderQuantity && (
                    <Typography className={classes.cellMainText}>
                        <Trans i18nKey="minOrder">Minimum Order</Trans> {item.minimumOrderQuantity}
                        PL
                    </Typography>
                )}
                {hasMoqFee && (
                    <Typography>
                        <Trans i18nKey="feesForOrders">Fees for orders </Trans>{' '}
                        {getMoqFeeFloor(item.moqFees)} - {getMoqFeeCeiling(item.moqFees)}
                    </Typography>
                )}
                {!item.minimumOrderQuantity && (
                    <Typography className={classes.cellMainText}>-</Typography>
                )}
            </TableCell>
            <TableCell data-testid="original-quantity">
                <Grid container direction="column">
                    <Grid item>
                        <Typography className={classes.cellMainText}>
                            {formatNumberWithLocale(cultureCode, item.originalPalletQuantity ?? 0)}{' '}
                            PL
                        </Typography>
                    </Grid>
                    {item.originalPalletQuantity !== undefined && (
                        <Grid item>
                            <Typography
                                className="helper-text"
                                data-testid="original-quantity-eaches"
                            >
                                {formatNumberWithLocale(
                                    cultureCode,
                                    item.originalEachesQuantity ?? 0
                                )}{' '}
                                ea.
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </TableCell>
            <TableCell
                className={clsx({
                    [classes.warningInputContainer]: inputQuantity === undefined,
                    [classes.warningBackground]: item.includedInExistingForecast
                })}
            >
                <Grid container alignContent="center">
                    <Grid item className={classes.inputContainer}>
                        <QuantityInputCell
                            quantity={inputQuantity}
                            quantityPerPallet={item.quantityPerPallet!}
                            quantityUnit={quantityUnit}
                            onBlur={handleQuantityOnBlur}
                            onChange={updatePurchaseCount}
                            showEmptyCellOnUndefined={true}
                            disabled={false}
                            hasWarning={inputQuantity === undefined}
                        />
                    </Grid>
                    {inputQuantity === undefined && (
                        <Grid item>
                            <Typography className="helper-text" data-testid="blank-quantity-error">
                                <Trans i18nKey="enterAQuantity">Please enter a quantity.</Trans>
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </TableCell>

            <TableCell className={classes.alignCenter} data-testid="delete-icon">
                <Button type="button" onClick={handleRemoveItem} className={classes.trash}>
                    <img src={process.env.PUBLIC_URL + '/assets/Trash.svg'} alt="Trash Icon" />
                </Button>
            </TableCell>
        </TableRow>
    );
}
