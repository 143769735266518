import React, { useState } from 'react';
import {
    makeStyles,
    Typography,
    TableRow,
    TableCell,
    Grid,
    Button,
    Checkbox
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import Modal from '../../../reusable/molecules/Modal';
import moment, { Moment } from 'moment';
import { boldWeight, medium } from '../../../../themes/globalConstants';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { formatNumberWithLocale } from '../../../../utility/helpers/formatters';
import { BulkUploadMakeItState } from '../../../../store/reducers/makeit-bulk-upload';
import { ProductionOrderForTable } from '../../MakeItATMSummary/models/ProductionOrderForTable';
import { PlanItSummaryState } from '../../../../store/reducers/plan-it-summary';
import {
    loadEditForecastOrder,
    resetPlanItBulkState
} from '../../../../store/actions/planit-bulk-atm';
import { PlanItBulkATMLineItem } from '../../../../store/reducers/planit-bulk-atm';
import { SecurityLevel } from '../../../../utility/auth/useSecurity';
import { Account } from '../../../../store/reducers/customer-context';
import { usePlanItSummaryContext } from '../context/PlanItSummaryContext';
import { updateCopyToMakeItOrders } from '../../../../store/actions/plan-it-summary';
import { selectIsLargeCustomerAccount, selectIsMakeItAccount } from '../../../../store/selectors';
import DaypickerRangeModal from '../../../reusable/molecules/DaypickerRangeModal';
import { copyPreviousForecast } from '../../../../store/root-actions';

interface Props {
    order: ProductionOrderForTable;
    securityLevel: SecurityLevel;
    copyToMakeItSecurityLevel: SecurityLevel;
    currentAccount: Account | undefined;
}

const useStyles = makeStyles(() => ({
    name: {
        fontWeight: boldWeight,
        fontSize: medium,
        letterSpacing: 0.17
    },
    editButton: {
        fontSize: '1em'
    },
    calendarDiv: {
        margin: '2em'
    }
}));

export default function PlanItRow({
    order,
    securityLevel,
    copyToMakeItSecurityLevel,
    currentAccount
}: Props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { orders, copyToMakeItOrders } = useTypedSelector<PlanItSummaryState>(
        (state) => state.planItSummary
    );
    const { products } = useTypedSelector<BulkUploadMakeItState>(
        (state) => state.bulkUploadMakeItState
    );
    const isLargeCustomerAccount = useTypedSelector(selectIsLargeCustomerAccount);
    const { activeTab } = usePlanItSummaryContext();
    const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
    const [daypickerDate, setDaypickerDate] = useState<string>('');
    const [startDate, setStartDate] = useState<Moment>(moment());
    const [endDate, setEndDate] = useState<Moment>(moment());
    const isMakeItAccount = useTypedSelector<boolean>(selectIsMakeItAccount);

    // Forecast week start is on or after this coming monday
    const isEditable = moment(order.atmWeekStart)
        .startOf('day')
        .isSameOrAfter(moment().day(1).add(1, 'week').startOf('day'));
    // Is the forecast week in the lock window?
    const copyToMakeItDisabled =
        (currentAccount && currentAccount.lockedPeriodDays
            ? moment(order.atmWeekStart).isSameOrBefore(
                  moment().add(currentAccount.lockedPeriodDays, 'days')
              )
            : moment(order.atmWeekStart).isSameOrBefore(moment().add(14, 'days'))) ||
        copyToMakeItSecurityLevel !== SecurityLevel.Edit;

    const handleCopyToMakeItClick = () => {
        dispatch(updateCopyToMakeItOrders(order));
    };

    const resetLoadedOrder = () => {
        dispatch(resetPlanItBulkState());
    };

    useTranslation();

    const handleEditClick = () => {
        resetLoadedOrder();
        if (orders && products) {
            const orderToEdit = orders.find(
                (ord) => ord.productionOrderId === order.productionOrderId
            );
            if (orderToEdit) {
                dispatch(loadEditForecastOrder(orderToEdit, products as PlanItBulkATMLineItem[]));
                history.push('/plan-it-bulk-upload-review?from=summary');
            }
        }
    };

    const handleReplicateForecast = () => {
        setCalendarOpen(true);
    };

    const onCloseCalendar = () => {
        setCalendarOpen(false);
    };

    const handleOkClose = () => {
        if (!!orders) {
            const matchingOrder = orders.find(
                (x) => x.productionOrderId === order.productionOrderId
            );
            if (!!matchingOrder) {
                dispatch(copyPreviousForecast(matchingOrder, daypickerDate));
                setCalendarOpen(false);
            }
        }
    };

    const handleDateRangeChange = (startDate, endDate) => {
        setStartDate(startDate);
        setEndDate(endDate);
        handleDateSelect(startDate);
    };

    const handleDateSelect = (newDate: moment.Moment | null) => {
        const date = moment(newDate).format('MM/DD/YYYY');
        setDaypickerDate(date);
    };

    const calculateOutsideRange = (day) => {
        const today = moment();
        return day.isBefore(today.add(1, 'weeks').startOf('isoWeek'));
    };

    const formatOrderQuantity = (
        totalOrderQuantity?: number,
        totalOrderQuantityEaches?: number
    ) => {
        return (
            <>
                {totalOrderQuantity !== undefined && (
                    <Typography className={classes.name}>
                        {formatNumberWithLocale(cultureCode, totalOrderQuantity)} PL
                    </Typography>
                )}

                {totalOrderQuantityEaches !== undefined && (
                    <Typography>
                        {formatNumberWithLocale(cultureCode, totalOrderQuantityEaches)} ea
                    </Typography>
                )}
            </>
        );
    };

    const actions = () => {
        return (
            <>
                {activeTab === 'previousForecasts' && (
                    <TableCell align="center">
                        {order.totalOrderQuantity !== undefined && (
                            <Button
                                variant="text"
                                color="primary"
                                data-testid="replicate-forecast-button"
                                className={classes.editButton}
                                onClick={handleReplicateForecast}
                                disabled={!order.totalOrderQuantity}
                            >
                                <Trans i18nKey="replicateForecast">Replicate Forecast</Trans>
                            </Button>
                        )}
                    </TableCell>
                )}
                {isEditable && activeTab === 'forecasts' && (
                    <TableCell align="center">
                        <Button
                            variant="text"
                            color="primary"
                            data-testid="edit-button"
                            className={classes.editButton}
                            onClick={handleEditClick}
                            disabled={securityLevel !== SecurityLevel.Edit}
                        >
                            <Trans i18nKey="edit">Edit</Trans>
                        </Button>
                    </TableCell>
                )}
                {activeTab === 'forecasts' && isMakeItAccount && (
                    <TableCell align="center">
                        <Checkbox
                            checked={
                                !!copyToMakeItOrders.find(
                                    (o) => o.productionOrderId === order.productionOrderId
                                )
                            }
                            color="primary"
                            data-testid="copy-to-make-it-button"
                            onClick={handleCopyToMakeItClick}
                            disabled={isLargeCustomerAccount && copyToMakeItDisabled}
                        ></Checkbox>
                    </TableCell>
                )}
            </>
        );
    };

    return (
        <TableRow hover={true} key={order.productionOrderId}>
            <TableCell data-testid={'forecast-week-start'}>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        {order.atmWeekStartString}
                    </Grid>
                </Grid>
            </TableCell>
            {isLargeCustomerAccount === false && (
                <TableCell data-testid={'customer-po-number'}>
                    <Grid container>
                        <Grid item xs={12} className={classes.name}>
                            {order.customerProductionOrderId ?? (
                                <Trans i18nKey="noCustomerPONumber">No Customer PO #</Trans>
                            )}
                        </Grid>
                    </Grid>
                </TableCell>
            )}
            <TableCell data-testid={'forecast-order-id'}>
                <Grid container spacing={1}>
                    <Grid item className={classes.name}>
                        <Button
                            variant="text"
                            color="primary"
                            data-testid="po-number-button"
                            className={classes.editButton}
                            component={RouterLink}
                            to={'/plan-it-order/' + order.productionOrderId}
                            disabled={securityLevel !== SecurityLevel.Edit}
                        >
                            {order.searchablePONumber}
                        </Button>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell data-testid={'order-quantity'}>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        {formatOrderQuantity(
                            order.totalOrderQuantity,
                            order.totalOrderQuantityEaches
                        )}
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell data-testid={'submitted-date'}>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        {order.submittedDateString}
                    </Grid>
                </Grid>
            </TableCell>
            {order.forecastTableStatus !== undefined && (
                <TableCell data-testid={'forecastTableStatus'}>
                    <Typography>{order.forecastTableStatus ?? ''}</Typography>
                </TableCell>
            )}
            {actions()}
            <Modal
                open={calendarOpen}
                title={<Trans i18nKey="chooseDates">Choose Dates</Trans>}
                close={onCloseCalendar}
                closeIcon={true}
                maxWidth="xl"
            >
                <div className={classes.calendarDiv} data-testid="calendar-modal">
                    <DaypickerRangeModal
                        startDate={startDate}
                        endDate={endDate}
                        onCancel={onCloseCalendar}
                        onOk={handleOkClose}
                        isOutsideRange={calculateOutsideRange}
                        onDateChange={(startDate, endDate) =>
                            handleDateRangeChange(startDate, endDate)
                        }
                        disableSubmit={daypickerDate === ''}
                    />
                </div>
            </Modal>
        </TableRow>
    );
}
