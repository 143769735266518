import { ShipToRoleAccess } from '../../components/reusable/molecules/LocationRoleAccessTable/models/role-access.model';
import {
    REQUEST_ACCESS_CLEARED,
    REQUEST_ACCESS_LOADING,
    REQUEST_ACCESS_LOADING_ERROR,
    REQUEST_ACCESS_SET_ACCOUNTS,
    REQUEST_ACCESS_SET_COMMENT,
    REQUEST_ACCESS_SUBMIT_REQUEST_ERROR,
    REQUEST_ACCESS_SUBMIT_REQUEST_SUCCESS
} from '../actions/action-types';

export interface RequestAccessState {
    loading: boolean;
    loaded: boolean;
    requestAccounts: ShipToRoleAccess[];
    requestComment: string;
}

export const initialState: RequestAccessState = {
    loading: false,
    loaded: false,
    requestAccounts: [],
    requestComment: ''
};

const requestAccess = (
    state: RequestAccessState = initialState,
    action: any
): RequestAccessState => {
    switch (action.type) {
        case REQUEST_ACCESS_CLEARED:
            return {
                loading: false,
                loaded: false,
                requestAccounts: [],
                requestComment: ''
            };
        case REQUEST_ACCESS_LOADING:
            return {
                ...state,
                loading: true
            };
        case REQUEST_ACCESS_LOADING_ERROR:
            return {
                ...state,
                loading: false
            };
        case REQUEST_ACCESS_SET_ACCOUNTS:
            return {
                ...state,
                loading: false,
                loaded: true,
                requestAccounts: action.accounts
            };
        case REQUEST_ACCESS_SET_COMMENT:
            return {
                ...state,
                requestComment: action.comment
            };
        case REQUEST_ACCESS_SUBMIT_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case REQUEST_ACCESS_SUBMIT_REQUEST_ERROR:
            return {
                ...state
            };
        default:
            return state;
    }
};

export default requestAccess;
