import React, { useEffect, useState } from 'react';
import { makeStyles, Box, Grid } from '@material-ui/core';
import StatusTrackerMarker from './StatusTrackerMarker';
import { ltBlueGrey } from '../../../themes/globalConstants';

interface Props {
    markerList: StatusTrackerMarker[];
}

const useStyles = makeStyles((theme) => ({
    box: {
        width: '100%',
        height: '8em',
        padding: '1em 0',
        borderRadius: '0.250em',
        display: 'flex',
        alignItems: 'center'
    },
    container: {
        position: 'relative'
    },
    statusBar: {
        position: 'absolute',
        zIndex: 0,
        height: '1.25em',
        backgroundColor: ltBlueGrey,
        opacity: 0.5,
        marginTop: '0.125em'
    },
    markerContainer: {
        zIndex: 1
    }
}));

const StatusTracker = ({ markerList }: Props) => {
    const classes = useStyles();
    const [statusBarStyles, setStatusBarStyles] = useState({});

    const createMarkers = markerList?.map((marker, index) => {
        const currentMarker =
            markerList.length === index + 1 ? { ...marker, lastMarker: true } : marker;

        return (
            <Grid item id={`marker-${index + 1}`} key={`marker-key-${index}`}>
                <StatusTrackerMarker {...currentMarker} />
            </Grid>
        );
    });

    const calculateBarStyles = (markerList: StatusTrackerMarker[]) => {
        let statusLeftOffset = 0;
        let statusBarWidth = 0;

        if (markerList.length) {
            const firstMarker = document.getElementById('marker-1')?.getBoundingClientRect();
            const lastMarker = document
                .getElementById(`marker-${markerList.length}`)
                ?.getBoundingClientRect();

            if (firstMarker && lastMarker) {
                statusBarWidth =
                    lastMarker.left -
                    firstMarker.left +
                    lastMarker.width / 2 -
                    firstMarker.width / 2;
                statusLeftOffset = firstMarker.width / 2;
            }

            setStatusBarStyles({
                width: statusBarWidth,
                marginLeft: statusLeftOffset
            });
        }
    };

    useEffect(() => {
        calculateBarStyles(markerList);
    }, []);

    return (
        <Box className={classes.box}>
            <Grid container className={classes.container}>
                <Grid container className={classes.statusBar} style={statusBarStyles}></Grid>
                <Grid container justify="space-between" className={classes.markerContainer}>
                    {createMarkers}
                </Grid>
            </Grid>
        </Box>
    );
};

export default StatusTracker;
