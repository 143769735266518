import React, { useState } from 'react';
import { Grid, Button, makeStyles } from '@material-ui/core';
import {
    boldWeight
} from '../../../themes/globalConstants';
import { Trans, useTranslation } from 'react-i18next';
import Modal from './Modal';
import { OrderProductTableRow } from '../../../store/reducers/orders-dashboard';
import ProductStatusToggle from './ProductStatusToggle';
import ProductStatusToggleConfirmation from './ProductStatusToggleConfirmation';
import SmallProductInfoSummaryTable from './SmallProductInfoSummaryTable';

interface Props {
    product: OrderProductTableRow;
    onClose?: (deactivate: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
    modal: {
        '& .MuiPaper-root': {
            maxWidth: '40.5em'
        },
        '& .MuiDialog-paperScrollPaper': {
            borderRadius: 'unset',
            overflow: 'hidden'
        },
        '& .MuiDialogTitle-root': {
            justifyContent: 'start'
        },
        '& .MuiDialogContent-root': {
            padding: '1.875em'
        },
        '& .MuiPaper-elevation1': {
            boxShadow: 'none'
        }
    },
    button: {
        height: 40,
        marginTop: 30,
        fontSize: 14,
        fontWeight: boldWeight,
        textTransform: 'uppercase',
        width: '100%'
    },
    cancelButton: {
        marginRight: 20
    },
    statusSwitchContainer: {
        marginTop: 40
    },
    confirmationMessageContainer: {
        marginTop: 16,
        '& .MuiTypography-body1': {
            fontWeight: boldWeight
        }
    }
}));

const DeactivateProductModal = (props: Props) => {
    const { t } = useTranslation();
    useTranslation();
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const [checked, setChecked] = useState(true);
    const [toastConfirmed, setToastConfirmed] = useState<undefined | boolean>(undefined);

    const switchHandler = (checked: boolean) => {
        setToastConfirmed(undefined);
        setChecked(checked);
    };

    const toggleHandler = (toastIsConfirmed: boolean) => {
        setToastConfirmed(toastIsConfirmed);
    };

    const handleCloseModal = () => {
        setOpen(false);
        if (!!props.onClose) {
            props.onClose(false);
        }
    };

    const handleSubmitModal = () => {
        if (props.onClose) {
            props.onClose(true);
        }
    };

    return (
        <Modal
            open={open}
            close={handleCloseModal}
            title={t('requestDeactivation', 'Request Deactivation')}
            closeIcon={true}
            fullWidth={true}
            className={classes.modal}
        >
            {
                <>
                    <SmallProductInfoSummaryTable
                        product={props.product}
                    ></SmallProductInfoSummaryTable>
                    <Grid container justify="center" className={classes.statusSwitchContainer}>
                        <Grid item xs={11}>
                            <ProductStatusToggle
                                isActivate={false}
                                onChange={switchHandler}
                            ></ProductStatusToggle>
                        </Grid>
                    </Grid>
                    {!checked && (
                        <Grid
                            container
                            justify="center"
                            className={classes.confirmationMessageContainer}
                        >
                            <Grid container alignItems="center" item xs={10}>
                                <ProductStatusToggleConfirmation
                                    isActivate={false}
                                    onChange={toggleHandler}
                                ></ProductStatusToggleConfirmation>
                            </Grid>
                        </Grid>
                    )}
                    <Grid container alignItems="flex-end" justify="flex-end">
                        <Grid item className={classes.cancelButton}>
                            <Button
                                type="button"
                                variant="outlined"
                                color="secondary"
                                className={classes.button}
                                onClick={handleCloseModal}
                                data-testid="cancel-btn"
                            >
                                <Trans i18nKey="cancel">Cancel</Trans>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={handleSubmitModal}
                                data-testid="submit-btn"
                                disabled={!toastConfirmed}
                            >
                                <Trans i18nKey="submit">Submit</Trans>
                            </Button>
                        </Grid>
                    </Grid>
                </>
            }
        </Modal>
    );
};

export default DeactivateProductModal;
