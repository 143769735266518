import React, { useEffect, useState } from 'react';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import { Grid, makeStyles } from '@material-ui/core';
import { boldWeight, blackWeight } from '../../../../themes/globalConstants';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import {
    BulkUploadMakeItATMState,
    MakeItBulkATMLineItem,
    MakeItBulkATMLinesBySnoSku
} from '../../../../store/reducers/makeit-bulk-atm';
import MakeItATMOrderDetailsToolbar from './MakeItATMOrderDetailsToolbar';
import MakeItATMOrderDetailsColumns from './MakeItATMOrderDetailsColumns';
import MakeItATMOrderDetailsLineRow from './MakeItATMOrderDetailsLineRow';
import { isProductCanType } from '../../../../utility/helpers/order-helpers';
import {
    DateProperties,
    getSnoSkuAttributes,
    getSnoSkuDescription
} from '../../../../utility/helpers/make-it-bulk-helpers';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { getLockedPeriodDays } from '../../../../utility/helpers/user-helpers';
import { isDateWithinAtmLockPeriod } from '../../../../utility/helpers/date-helpers';

interface ExpandToggle {
    toggle: boolean;
}
interface Props {
    linesBySnoSku?: MakeItBulkATMLinesBySnoSku;
    weekStart: DateProperties;
}

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        marginTop: '2em',
        '& .MuiToolbar-root': {
            padding: 0,
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                },
                '&:first-of-type ': {
                    paddingLeft: 0
                }
            },
            '& > h2': {
                fontSize: '1.5em',
                fontWeight: boldWeight,
                color: theme.palette.secondary.main,
                paddingLeft: '0.625em',
                fontFamily: 'Lato',
                minWidth: '12em'
            }
        },
        '& .MuiTableSortLabel-root': {
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em',
            width: '99%',
            justifyContent: 'space-between',
            '& .MuiSvgIcon-root': {
                margin: 0
            }
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                '&:nth-child(1)': {
                    width: '10%'
                },
                '&:nth-child(2)': {
                    width: '12%'
                },
                '&:nth-child(3)': {
                    width: '28%'
                },
                '&:nth-child(4)': {
                    width: '10%'
                },
                '&:nth-child(5)': {
                    width: '15%'
                },
                '&:nth-child(6)': {
                    width: '10%',
                    fontSize: 12,
                    fontWeight: blackWeight,
                    textTransform: 'uppercase',
                    color: theme.palette.secondary.main,
                    borderRadius: '0.25em',
                    paddingLeft: '0.5em',
                    justifyContent: 'space-between',
                    '& .MuiSvgIcon-root': {
                        margin: 0
                    }
                },
                '&:nth-child(7)': {
                    width: '15%'
                }
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    }
}));

const MakeItATMOrderDetailsGrid = ({ linesBySnoSku, weekStart }: Props) => {
    const classes = useStyles();
    const [columns, setColumns] = useState<any>([]);
    const [orderLines, setOrderLines] = useState<MakeItBulkATMLineItem[]>();
    const [attributeDescription, setAttributeDescription] = useState<string>('');
    const [secondaryAttributes, setSecondaryAttributes] = useState<string>('');
    const [allocationAmount, setAllocationAmount] = useState<number>(0);
    const [expandAll, setExpandAll] = useState<ExpandToggle>({ toggle: false });
    const [isEditable, setIsEditable] = useState<boolean>(false);

    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { allocations } = useTypedSelector<BulkUploadMakeItATMState>(
        (state) => state.bulkUploadMakeItATMState
    );
    const { selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );

    useEffect(() => {
        if (shipToAccounts?.length && selectedAccountId) {
            const lockedPeriod: number = getLockedPeriodDays(shipToAccounts, selectedAccountId, 14);
            const timeFrame = isDateWithinAtmLockPeriod(weekStart.fullDate, lockedPeriod);
            setIsEditable(timeFrame === 'after');
        }
    }, [selectedAccountId, shipToAccounts, weekStart.fullDate]);

    useEffect(() => {
        setColumns(MakeItATMOrderDetailsColumns.getMakeItATMOrderDetailsColumns(cultureCode));
    }, [cultureCode]);

    useEffect(() => {
        if (linesBySnoSku) {
            setOrderLines(linesBySnoSku.lines);
            setAttributeDescription(
                linesBySnoSku.lines && linesBySnoSku.lines[0]
                    ? getSnoSkuDescription(
                          isProductCanType(linesBySnoSku.lines[0].productType),
                          linesBySnoSku.lines[0]
                      )
                    : ''
            );
            setSecondaryAttributes(
                linesBySnoSku.lines && linesBySnoSku.lines[0]
                    ? getSnoSkuAttributes(
                          isProductCanType(linesBySnoSku.lines[0].productType),
                          linesBySnoSku.lines[0]
                      )
                    : ''
            );
            setAllocationAmount(
                linesBySnoSku.allocationAmount && linesBySnoSku.totalPalletQuantity
                    ? linesBySnoSku.allocationAmount - linesBySnoSku.totalPalletQuantity
                    : 0
            );
        }
    }, [linesBySnoSku, allocations]);

    const handleExpandAll = () => {
        setExpandAll({ toggle: true });
    };

    const handleCollapseAll = () => {
        setExpandAll({ toggle: false });
    };

    const CustomToolbarOptions = new ToolbarOptions({
        customItems: (
            <MakeItATMOrderDetailsToolbar
                attributeDescription={attributeDescription}
                secondaryAttributes={secondaryAttributes}
                remainingBalance={allocationAmount}
                onExpandAll={handleExpandAll}
                onCollapseAll={handleCollapseAll}
                isEditable={isEditable}
            />
        ),
        advancePagination: false,
        exportButton: false,
        printButton: false,
        searchText: false
    });

    const MakeItATMOrderRow = ({ row }: { row: Partial<MakeItBulkATMLineItem> }) => {
        if (row) {
            return (
                <MakeItATMOrderDetailsLineRow
                    key={row.productionOrderLineId}
                    line={row as MakeItBulkATMLineItem}
                    expand={expandAll}
                />
            );
        } else {
            return null;
        }
    };

    return (
        <Grid className={classes.tableWrapper}>
            {orderLines && (
                <DataGrid
                    gridName="ATM Production Order Lines"
                    columns={columns}
                    dataSource={orderLines}
                    rowComponent={MakeItATMOrderRow}
                    toolbarOptions={CustomToolbarOptions}
                />
            )}
        </Grid>
    );
};

export default MakeItATMOrderDetailsGrid;
