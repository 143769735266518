import moment from 'moment';
import {
    getSnoSkuAttributes,
    getSnoSkuDescription
} from '../../utility/helpers/make-it-bulk-helpers';
import { PlanningAllocation } from '../../components/pages/ProductPlanningATM/models/PlanningAllocation';
import {
    Activity,
    filterShipToIdsByPermission,
    SecurityLevel
} from '../../utility/auth/useSecurity';
import { getWeek } from '../../utility/helpers/date-helpers';
import {
    constructCanDescription,
    constructEndDescription,
    isProductCanType,
    isProductEndType
} from '../../utility/helpers/order-helpers';
import OrdersService from '../../utility/services/orders-service';
import { AuthState } from '../reducers/auth';
import { SnoSkuAllocationsByWeek } from '../reducers/makeit-bulk-atm';
import { OrderPlanningProduct, ShipmentDeliveryOrder } from '../reducers/product-planning';
import * as types from './action-types';
import { StateName } from '../../utility/helpers/state-helpers';
import { AllocationProduct } from '../../components/pages/ProductPlanningATM/models/AllocationForTable';

export const loadATMProductPlanning = (
    weekRange: number,
    allocations: SnoSkuAllocationsByWeek[]
) => {
    return (dispatch, getState) => {
        dispatch({ type: types.PRODUCT_PLANNING_ATM_LOADING });
        const state = getState();
        const currentWeek = getWeek(0);
        const requestedDate = moment(currentWeek).add(weekRange, 'days').format('MM/DD/YYYY');
        const fromDashboardLink = state.productPlanning?.fromDashboardLink;
        const shipToId = state.customerContext.selectedAccountId;

        const account = state.customerContext.shipToAccounts.find(
            (account) => account.accountId === state.customerContext.selectedAccountId
        );

        const auth: AuthState = getState().auth;
        OrdersService.getProductPlanning(getState(), {
            ShipToIds: filterShipToIdsByPermission(
                auth,
                [state.customerContext.selectedAccountId],
                Activity.PlanningPageATM,
                SecurityLevel.View
            ),
            WithAvailablePallets: true,
            IntervalLengthDays: 7,
            RequestedDate: [requestedDate],
            TotalsForPreviousNDays: weekRange,
            PaymentTerms: [account.paymentTerms]
        })
            .then((response) => {
                let allocationsBySnoSku = [] as PlanningAllocation[];
                let uniqueSnoSkus = [] as string[];
                response.data.products.forEach((product) => {
                    // set some product data
                    const canBottleDesc = constructCanDescription(product);
                    const endDesc = constructEndDescription(product);
                    const isEndType = isProductEndType(product.type);
                    product.description = isEndType ? endDesc : canBottleDesc;
                    product.isActive = product.isAged && fromDashboardLink ? true : false;
                    product.graphicIdAndVersion =
                        product.graphicId && product.graphicVersion
                            ? product.graphicId + '-' + product.graphicVersion
                            : '';
                    if (product.destinations) {
                        product.destinations?.forEach((destination) => {
                            if (destination.shipToId.toString() === shipToId) {
                                product.displayId = destination.customerProductId
                                    ? destination.customerProductId
                                    : product.productSku;
                                product.displayName = destination.customerProductName
                                    ? destination.customerProductName
                                    : product.name;
                            }
                        });
                    }
                    matchAllocationsBySnoSku(
                        allocationsBySnoSku,
                        product,
                        allocations,
                        uniqueSnoSkus
                    );
                });
                sortProductsAlphabetically(allocationsBySnoSku);
                dispatch({
                    type: types.PRODUCT_PLANNING_ATM_LOADED,
                    allocationsBySnoSku: allocationsBySnoSku
                });
            })
            .catch((error) => {
                dispatch({
                    type: types.PRODUCT_PLANNING_ATM_LOADING_ERROR,
                    error
                });
            });
    };
};

export const matchAllocationsBySnoSku = (
    allocationsBySnoSku: PlanningAllocation[],
    product: any,
    allocations: SnoSkuAllocationsByWeek[],
    uniqueSnoSkus: string[]
) => {
    if (product.snoSku) {
        // keep track of unique sno skus, as we will group products based on sno sku
        // if we've seen the current product's snosku before, add its information its matching allocationsBySnoSku array
        if (uniqueSnoSkus.includes(product.snoSku)) {
            allocationsBySnoSku
                .find((alloc) => alloc.snoSku.toString() === product.snoSku)
                ?.products?.push({
                    ...product,
                    productId: product.productId,
                    productDescription: product.displayName,
                    quantityPerPallet: product.quantityPerPallet,
                    productionBalance: product.previousProductionBalancePalletTotals ?? [],
                    shipments: product.previousShipmentTotals ?? []
                });
        } else {
            // if we have not seen the current product's snosku before, add sno sku to uniqueSnoSkus array to track
            uniqueSnoSkus.push(product.snoSku);
            // then, look through each week and match allocation quantity to each product for that specific week
            let allocByWeek = [] as (number | undefined)[];
            for (let i = 0; i <= 17; i++) {
                // Allocation for Ends are temporarily not enforced. See CP-4839
                let matchingAllocation = allocations.find(
                    (all) =>
                        all.snoSku === product.snoSku &&
                        isProductCanType(product.type) &&
                        moment(all.week).format('MM/DD/YYYY') === getWeek(i)
                );
                allocByWeek.push(matchingAllocation ? matchingAllocation.quantity : undefined);
            }

            // "groom" data to match AllocationProduct interface
            let newProduct = {
                ...product,
                productDescription: product.displayName,
                productionBalance: product.previousProductionBalancePalletTotals ?? [],
                shipments: product.previousShipmentTotals ?? []
            };
            // create new PlanningAllocation and add it toe the allocationsBySnoSku array, which we will eventually return
            let newAllocation = {
                snoSku: Number(product.snoSku),
                snoSkuDescription: getSnoSkuDescription(isProductCanType(product.type), product),
                snoSkuAttributes: getSnoSkuAttributes(isProductCanType(product.type), product),
                weekAllocationQuantity: allocByWeek,
                products: [newProduct]
            } as PlanningAllocation;
            allocationsBySnoSku.push(newAllocation);
        }
    }
};

export const sortProductsAlphabetically = (planningAllocation: PlanningAllocation[]) => {
    planningAllocation.forEach((productGroup) => {
        const sortedProducts = productGroup.products?.sort((a, b) => {
            if (a.productDescription < b.productDescription) {
                return -1;
            }
            if (a.productDescription > b.productDescription) {
                return 1;
            }
            return 0;
        });
        productGroup.products = sortedProducts;
    });
};

export const productPlanningUpdateRange = (range: number) => {
    return (dispatch: any) => {
        dispatch({
            type: types.PRODUCT_PLANNING_ATM_UPDATE_RANGE,
            range: range
        });
    };
};

export const productPlanningUpdateViewFilter = (viewTypes: string[]) => {
    return (dispatch: any) => {
        dispatch({
            type: types.PRODUCT_PLANNING_ATM_UPDATE_FILTER,
            view: viewTypes
        });
    };
};

export const getDeliveryOrdersForProduct = (
    weekNumber: number,
    productSku: string,
    activity: Activity
) => {
    return (dispatch, getState) => {
        dispatch({ type: types.PRODUCT_PLANNING_ATM_DELIVERY_ORDERS_LOADING });
        const state = getState();
        const shipToId = state.customerContext.selectedAccountId;
        const auth: AuthState = getState().auth;
        const startDate = moment()
            .add(weekNumber * 7, 'days')
            .startOf('isoWeek')
            .format('MM/DD/YYYY');
        const endDate = moment()
            .add(weekNumber * 7, 'days')
            .endOf('isoWeek')
            .format('MM/DD/YYYY');
        const paymentTerms = state.customerContext.shipToAccounts.find(
            (shipTo) => shipTo.accountId === shipToId
        ).paymentTerms;
        OrdersService.getOrderShipments(getState(), productSku, {
            ShipToIds: filterShipToIdsByPermission(auth, [shipToId], activity, SecurityLevel.View),
            startDate: [startDate],
            endDate: [endDate],
            paymentTerms: [paymentTerms]
        })
            .then((response) => {
                const deliveryOrders: ShipmentDeliveryOrder[] = [];
                if (productSku && response.data.orders) {
                    response.data.orders.forEach((deliveryOrder) => {
                        if (deliveryOrder.shipments.length > 0) {
                            deliveryOrder.shipments.forEach((shipment) => {
                                if (shipment.loads.length > 0) {
                                    shipment.loads.forEach((load) => {
                                        if (load.productSku === productSku) {
                                            const deliveryOrderByDate = deliveryOrders.filter(
                                                (shipmentDeliverOrderItem) =>
                                                    shipmentDeliverOrderItem.deliveryOrderId ===
                                                        deliveryOrder.deliveryOrderId &&
                                                    shipmentDeliverOrderItem.deliveryDateTime ===
                                                        moment(shipment.deliveryDateTime).format(
                                                            'MM/DD/YYYY'
                                                        )
                                            );
                                            if (deliveryOrderByDate.length > 0) {
                                                deliveryOrderByDate[0].numberOfShipments
                                                    ? (deliveryOrderByDate[0].numberOfShipments += 1)
                                                    : (deliveryOrderByDate[0].numberOfShipments = 1);
                                                deliveryOrderByDate[0].palletQuantity +=
                                                    load.palletQuantity;
                                            } else {
                                                deliveryOrders.push({
                                                    deliveryOrderId: deliveryOrder.deliveryOrderId,
                                                    deliveryOrderNumber:
                                                        deliveryOrder.deliveryOrderNumber,
                                                    numberOfShipments: 1,
                                                    deliveryDateTime: moment(
                                                        shipment.deliveryDateTime
                                                    ).format('MM/DD/YYYY'),
                                                    palletQuantity: load.palletQuantity
                                                });
                                            }
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
                dispatch({
                    type: types.PRODUCT_PLANNING_ATM_DELIVERY_ORDERS_LOADED,
                    deliveryOrdersForProduct: deliveryOrders
                });
            })
            .catch((error) => {
                dispatch({
                    type: types.PRODUCT_PLANNING_ATM_DELIVERY_ORDERS_ERROR,
                    error
                });
            });
    };
};

export const productPlanningToggleQuantityUnit = () => {
    return (dispatch: any, getState) => {
        const existingQuantityUnit = getState().productPlanningATM.quantityUnit;
        dispatch({
            type: types.PRODUCT_PLANNING_ATM_UPDATE_QUANTITY_UNIT,
            quantityUnit: existingQuantityUnit === 'pallets' ? 'eaches' : 'pallets'
        });
    };
};

export const updateCollapse = (currentAllocation: PlanningAllocation, stateName: StateName) => {
    return (dispatch, getState) => {
        const { allocationsBySnoSku } = getState()[stateName];
        const allocationsToUpdate = [...allocationsBySnoSku] as PlanningAllocation[];

        allocationsToUpdate.forEach((allocation) => {
            if (allocation.snoSku === currentAllocation.snoSku) {
                allocation.isActive = !allocation.isActive;
            }
        });

        handleUpdateAllocationsDispatch(allocationsToUpdate, dispatch, stateName);
    };
};

export const updateCollapseProduct = (
    allocationProduct: AllocationProduct | OrderPlanningProduct,
    stateName: StateName
) => {
    return (dispatch, getState) => {
        const { allocationsBySnoSku } = getState()[stateName];
        const allocationsToUpdate = [...allocationsBySnoSku] as PlanningAllocation[];

        allocationsToUpdate
            .flatMap((pa) => pa.products)
            .forEach((ap) => {
                if (ap?.displayId === allocationProduct.displayId) {
                    ap!.isActive = allocationProduct.isActive;
                } else {
                    ap!.isActive = false;
                }
            });

        handleUpdateAllocationsDispatch(allocationsToUpdate, dispatch, stateName);
    };
};

export const updateCollapseAll = (collapsed: boolean, stateName: StateName) => {
    return (dispatch, getState) => {
        const { allocationsBySnoSku } = getState()[stateName];
        const allocationsToUpdate = [...allocationsBySnoSku] as PlanningAllocation[];

        allocationsToUpdate.forEach((allocation) => {
            allocation.isActive = collapsed;
        });

        handleUpdateAllocationsDispatch(allocationsToUpdate, dispatch, stateName);
    };
};

export const updateCollapseAllProducts = (collapsed: boolean, stateName: StateName) => {
    return (dispatch, getState) => {
        const { allocationsBySnoSku } = getState()[stateName];
        const allocationsToUpdate = [...allocationsBySnoSku] as PlanningAllocation[];

        allocationsToUpdate.forEach((allocation) => {
            allocation.products?.forEach((product) => {
                product.isActive = collapsed;
            });
        });

        dispatch({
            type: types.PRODUCT_PLANNING_ATM_UPDATE_COLLAPSE,
            allocationsBySnoSku: allocationsToUpdate
        });
    };
};

const handleUpdateAllocationsDispatch = (
    allocationsToUpdate: PlanningAllocation[],
    dispatch: any,
    stateName: StateName
) => {
    if (stateName === 'productPlanningATM') {
        dispatch({
            type: types.PRODUCT_PLANNING_ATM_UPDATE_COLLAPSE,
            allocationsBySnoSku: allocationsToUpdate
        });
    } else {
        dispatch({
            type: types.PRODUCT_PLANNING_UPDATE_ALLOCATION_COLLAPSE,
            allocationsBySnoSku: allocationsToUpdate
        });
    }
};

export const resetProductPlanningATM = () => {
    return (dispatch) => {
        dispatch({
            type: types.PRODUCT_PLANNING_ATM_RESET
        });
    };
};
