import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import {
    Typography,
    Grid,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Button,
    makeStyles
} from '@material-ui/core';
import PublishRoundedIcon from '@material-ui/icons/PublishRounded';
import clsx from 'clsx';
import { useTranslation, Trans } from 'react-i18next';
import {
    orange,
    tableGrey,
    ballLtBlue,
    boldWeight,
    small,
    regularWeight,
    medium
} from '../../../themes/globalConstants';
import ConfirmationModal from './ConfirmationModal';
import moment from 'moment';
import { FileInfo } from '../../../store/reducers/graphic-intake';

interface Props {
    fileTypes: string;
    styles: any;
    name: string;
    testId: string;
    invalidMessage?: string;
    fileReset: boolean;
    clickCapture?: () => void;
    onUploadAttempt: (fileData: any) => void;
    enableDelete?: boolean;
    currentFile?: File | File[];
    multi?: boolean;
    maxFiles?: number;
    existingFiles?: FileInfo[];
    onExistingFileDelete?: (index: number) => void;
    onFileReset?: () => void;
}

const tableStyles = makeStyles((theme) => ({
    tableCell: {
        border: '1px solid',
        borderColor: tableGrey,
        marginBottom: '1em'
    },
    nameColumn: {
        width: '55%'
    },
    dateColumn: {
        width: '30%'
    },
    trashColumn: {
        width: '15%',
        align: 'center'
    },
    header: {
        backgroundColor: ballLtBlue
    },
    headerText: {
        fontWeight: boldWeight,
        fontSize: small
    },
    fileText: {
        fontWeight: regularWeight,
        fontSize: medium
    },
    trash: {
        fill: orange
    }
}));

export default function FileUpload({
    fileTypes,
    styles,
    name,
    testId,
    invalidMessage,
    fileReset,
    enableDelete,
    clickCapture,
    onUploadAttempt,
    currentFile,
    multi,
    maxFiles,
    existingFiles,
    onExistingFileDelete,
    onFileReset
}: Props) {
    const classes = styles();
    const table = tableStyles();
    const [files, setFiles] = useState<File[]>([]);
    const trashIcon = process.env.PUBLIC_URL + '/assets/Trash.svg';
    const [indexToDelete, setIndexToDelete] = useState<number | undefined>(undefined);
    const [existingIndexToDelete, setExistingIndextoDelete] = useState<number | undefined>();
    const getFormattedDateTime = (dateMs: number) => {
        return moment(dateMs).format('MM/DD/YYYY h:mm A');
    };

    //Pass validation up using parent validation function props
    const validate = (acceptedFiles) => {
        onUploadAttempt(acceptedFiles);
    };

    const handleDrop = (acceptedFiles: File[]) => {
        if (multi) {
            const newFiles: File[] = [
                ...files,
                ...(maxFiles
                    ? acceptedFiles.slice(
                          0,
                          maxFiles - (files.length + (existingFiles?.length ?? 0))
                      )
                    : acceptedFiles)
            ];
            setFiles(newFiles);
            validate(newFiles);
        } else {
            if (acceptedFiles.length > 0) {
                const newFiles: File[] = [acceptedFiles[0]];
                setFiles(newFiles);
                validate(newFiles);
            } else {
                setFiles([]);
                validate([]);
            }
        }
    };
    useTranslation();

    const setInstructions = () => {
        switch (name) {
            case 'artworkUpload':
                return (
                    <Trans i18nKey="dragAndDropZipFileHere">
                        Drag and drop a zip file here or click
                    </Trans>
                );
            case 'deliveryOrderUpload':
            case 'makeItOrderUpload':
                return (
                    <Trans i18nKey="dragAndDropCsvFileHere">
                        Drag and drop a csv file here or click
                    </Trans>
                );
            default:
                return (
                    <Trans i18nKey="dragAndDropFileHere">Drag and drop a file here or click</Trans>
                );
        }
    };

    const setConfirmTitle = () => {
        return <Trans i18nKey="removeFile">Remove File</Trans>;
    };

    const setConfirmSubHeader = () => {
        return (
            <Trans i18nKey="areYouSureRemoveFile">Are you sure you want to remove this file?</Trans>
        );
    };

    const onResetFile = () => {
        const newFiles = [...files];
        newFiles.splice(indexToDelete!, 1);
        setFiles(newFiles);
        setIndexToDelete(undefined);
        onUploadAttempt(newFiles);
    };

    const onResetExistingFile = () => {
        if (onExistingFileDelete !== undefined) {
            onExistingFileDelete(existingIndexToDelete!);
        }
        setExistingIndextoDelete(undefined);
    };

    const onClose = () => {
        setIndexToDelete(undefined);
        setExistingIndextoDelete(undefined);
    };

    useEffect(() => {
        if (files && fileReset) {
            setFiles([]);
            onFileReset?.();
        }
    }, [fileReset]);

    useEffect(() => {
        if (currentFile) {
            setFiles(currentFile instanceof File ? [currentFile] : currentFile);
        }
    }, [currentFile]);

    return (
        <>
            <Table size="small" aria-label="file upload table" className={table.tableCell}>
                <TableBody>
                    <TableRow className={table.header}>
                        <TableCell className={table.nameColumn}>
                            <Typography className={table.headerText} data-testid="document-name">
                                <Trans i18nKey="documentName">DOCUMENT NAME</Trans>
                            </Typography>
                        </TableCell>
                        <TableCell className={table.dateColumn}>
                            <Typography className={table.headerText} data-testid="date-modified">
                                <Trans i18nKey="dateModified">DATE MODIFIED</Trans>
                            </Typography>
                        </TableCell>
                        {enableDelete && <TableCell className={table.trashColumn} />}
                    </TableRow>
                    {!files?.length && !existingFiles?.length && (
                        <TableRow key={'file-upload-no-docs'}>
                            <TableCell className={table.headerText}>
                                <Typography className={table.fileText} data-testid="no-document">
                                    <Trans i18nKey="documentNotUploaded">
                                        No document uploaded
                                    </Trans>
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                    {!!existingFiles?.length &&
                        existingFiles.map((existingFileInfo, index) => (
                            <TableRow key={`existing-file-upload-table-row-${index}`}>
                                <TableCell className={table.headerText}>
                                    {existingFileInfo.originalFileName && (
                                        <Typography className={table.fileText}>
                                            {existingFileInfo.originalFileName}
                                        </Typography>
                                    )}
                                </TableCell>
                                <TableCell className={table.dateColumn}>
                                    <Typography className={table.fileText}>
                                        {getFormattedDateTime(
                                            moment(existingFileInfo.submitDate).valueOf()
                                        )}
                                    </Typography>
                                </TableCell>
                                {enableDelete && (
                                    <TableCell className={table.trashColumn}>
                                        <Button
                                            type="button"
                                            onClick={() => setExistingIndextoDelete(index)}
                                            data-testid="delete-file-btn"
                                        >
                                            <img
                                                className={table.trash}
                                                src={trashIcon}
                                                alt="Trash Icon"
                                                data-testid={'delete-file'}
                                            />
                                        </Button>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    {files.map((file, index) => (
                        <TableRow key={`file-upload-table-row-${index}`}>
                            <TableCell className={table.headerText}>
                                {file.name && (
                                    <Typography className={table.fileText}>{file.name}</Typography>
                                )}
                            </TableCell>
                            <TableCell className={table.dateColumn}>
                                <Typography className={table.fileText}>
                                    {getFormattedDateTime(file.lastModified)}
                                </Typography>
                            </TableCell>
                            {enableDelete && (
                                <TableCell className={table.trashColumn}>
                                    <Button
                                        type="button"
                                        onClick={() => setIndexToDelete(index)}
                                        data-testid="delete-file-btn"
                                    >
                                        <img
                                            className={table.trash}
                                            src={trashIcon}
                                            alt="Trash Icon"
                                            data-testid={'delete-file'}
                                        />
                                    </Button>
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Dropzone
                onDrop={handleDrop}
                accept={fileTypes}
                disabled={
                    maxFiles !== undefined &&
                    (files?.length ?? 0) + (existingFiles?.length ?? 0) === maxFiles
                }
            >
                {({
                    getRootProps,
                    getInputProps,
                    isDragActive,
                    isDragAccept,
                    isDragReject,
                    fileRejections
                }) => {
                    return (
                        <section className={classes.container}>
                            <div
                                {...getRootProps({ className: classes.divContainer })}
                                onClickCapture={clickCapture}
                            >
                                <input
                                    data-testid={testId}
                                    type="file"
                                    name={name}
                                    {...getInputProps()}
                                />
                                {!isDragActive &&
                                    (files.length > 0 || fileRejections.length === 0) && (
                                        <>
                                            <PublishRoundedIcon className={classes.icon} />
                                            <Typography className={classes.text}>
                                                {setInstructions()}
                                            </Typography>
                                        </>
                                    )}
                                {isDragActive && isDragAccept && (
                                    <Typography className={classes.text}>
                                        <Trans i18nKey="dropHere">Drop file here</Trans>
                                    </Typography>
                                )}
                                {((isDragActive && isDragReject) ||
                                    (!isDragActive && fileRejections.length > 0)) && (
                                    <Grid item xs={12} className={classes.divContainer}>
                                        <Typography
                                            className={clsx(classes.text, classes.invalidText)}
                                        >
                                            {invalidMessage}
                                        </Typography>
                                    </Grid>
                                )}
                            </div>
                        </section>
                    );
                }}
            </Dropzone>
            {
                <ConfirmationModal
                    saveProgress={indexToDelete !== undefined ? onResetFile : onResetExistingFile}
                    onClose={onClose}
                    logo={false}
                    title={setConfirmTitle()}
                    subheader={setConfirmSubHeader()}
                    continueText={<Trans i18nKey="yesRemove">Yes, Remove</Trans>}
                    cancelText={<Trans i18nKey="no">No</Trans>}
                    open={indexToDelete !== undefined || existingIndexToDelete !== undefined}
                    navigationLink=""
                    onCancel={onClose}
                />
            }
        </>
    );
}
