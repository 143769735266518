import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { TRADE_IT_BALANCES_RESET } from '../../../store/actions/action-types';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { TradeItRequestState } from '../../../store/reducers/trade-it';
import { blackWeight } from '../../../themes/globalConstants';
import { Activity, SecurityLevel } from '../../../utility/auth/useSecurity';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import TradeItConfirmationGrid from './components/TradeItConfirmationGrid';
import TradeItConfirmationSubheader from './components/TradeItConfirmationSubheader';
import TradeItConfirmationShareLinks from './components/TradeItConfirmationShareLinks';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '2em',
        borderRadius: 4,
        marginTop: '2em'
    },
    main: {
        padding: '0'
    },
    title: {
        fontWeight: blackWeight,
        color: theme.palette.secondary.main,
        padding: '0 0 0.25em 0',
        textTransform: 'uppercase'
    },
    notificationMessage: {
        fontWeight: blackWeight,
        padding: '0 0 1.5em 0',
    },
    header: {
        marginTop: '1em',
        marginBottom: '2em'
    },
    tableWrapper: {
        marginTop: '1.5em'
    }
}));
export default function TradeItConfirmation() {
    const { t } = useTranslation();
    const classes = useStyles();
    const { submittedRequest, submitted } = useTypedSelector<TradeItRequestState>(
        (state) => state.tradeItRequest
    );
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch({ type: TRADE_IT_BALANCES_RESET });
        };
    }, []);

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('tradeIt', 'Trade It'),
                description: t(
                    'balanceTradeSubmitted',
                    'Thank you, your balance trade request has been submitted.'
                ),
                thinBanner: true,
                displayDropdown: false
            }}
            activity={Activity.TradeIt}
            restrictToSecurityLevel={SecurityLevel.Edit}
            shipTos={false}
            loading={false}
        >
            <Paper elevation={2} className={classes.paper}>
                <Grid container alignItems="flex-start">
                    <Grid container className={classes.header}>
                        <Grid container item xs={6} justify="flex-start">
                            <Typography variant="h3" className={classes.title}>
                                <Trans i18nKey="tradeItRequest">Trade It Request</Trans>
                                {' # '}
                                {`${submittedRequest?.tradeRequestId}`}
                            </Typography>
                        </Grid>
                        <Grid container item xs={6} justify="flex-end">
                            <TradeItConfirmationShareLinks />
                        </Grid>
                    </Grid>
                    <Grid>
                        <Typography className={classes.notificationMessage}>
                            <Trans i18nKey="willReceiveNotification">
                                * You will receive a notification once the request has been reviewed.
                            </Trans>
                        </Typography>
                    </Grid>
                    <Grid container>
                        <TradeItConfirmationSubheader />
                        <Grid container item xs={12}>
                            <Grid className={classes.tableWrapper}>
                                {submittedRequest && submitted && (
                                    <TradeItConfirmationGrid
                                        originalShipToId={submittedRequest.accountFrom}
                                        receivingShipToId={submittedRequest.accountTo}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </ProcessingPageTemplate>
    );
}
