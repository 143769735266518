import { makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { TradeRequestDetailsLine } from '../../../../store/reducers/trade-it-details';
import { ltBlueGrey_15, regularWeight, small } from '../../../../themes/globalConstants';
import { getTradeLineStatus } from '../../../../utility/helpers/trade-it-helpers';
interface Props {
    line: TradeRequestDetailsLine;
}

const useStyles = makeStyles((theme) => ({
    tableRowContainer: {
        '& .helper-text': {
            fontWeight: `${regularWeight} !important`,
            fontSize: small
        },
        '&:hover': {
            backgroundColor: ltBlueGrey_15
        },
        '& > td': {
            padding: '1.250em'
        },
        '& .MuiInputBase-input': {
            textAlign: 'right',
            paddingRight: '.25em'
        },
        '& .MuiTableCell-root': {
            '&:not(:last-child)': {
                paddingLeft: '1.125em'
            }
        },
        '& .normal': {
            fontWeight: `${regularWeight} !important`,
            textAlign: 'right'
        }
    }
}));

export default function TradeRequestDetailsRow({ line }: Props) {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [quantityToTradeEaches, setQuantityToTradeEaches] = useState<number | undefined>(
        undefined
    );
    const [originalShipToProductionBalanceEaches, setOriginalShipToProductionBalanceEaches] =
        useState<number | undefined>(undefined);
    const [receivingShipToProductionBalanceEaches, setReceivingShipToProductionBalanceEaches] =
        useState<number | undefined>(undefined);
    const [originalShipToProductionBalanceUpdated, setOriginalShipToProductionBalanceUpdated] =
        useState<number | undefined>(undefined);
    const [receivingShipToProductionBalanceUpdated, setReceivingShipToProductionBalanceUpdated] =
        useState<number | undefined>(undefined);
    const [
        originalShipToProductionBalanceEachesUpdated,
        setOriginalShipToProductionBalanceEachesUpdated
    ] = useState<number | undefined>(undefined);
    const [
        receivingShipToProductionBalanceEachesUpdated,
        setReceivingShipToProductionBalanceEachesUpdated
    ] = useState<number | undefined>(undefined);

    useEffect(() => {
        if (line) {
            setOriginalShipToProductionBalanceEaches(
                line.accountFromOriginalBalance && line.quantityPerPallet
                    ? line.accountFromOriginalBalance * line.quantityPerPallet
                    : undefined
            );
            setReceivingShipToProductionBalanceEaches(
                line.accountToOriginalBalance && line.quantityPerPallet
                    ? line.accountToOriginalBalance * line.quantityPerPallet
                    : undefined
            );
            setQuantityToTradeEaches(
                line.quantity && line.quantityPerPallet
                    ? line.quantity * line.quantityPerPallet
                    : undefined
            );
            if (line.quantity && line.accountFromOriginalBalance) {
                const calculatedOriginalShipToProductionBalance =
                    line.accountFromOriginalBalance - line.quantity;
                setOriginalShipToProductionBalanceUpdated(
                    calculatedOriginalShipToProductionBalance >= 0
                        ? calculatedOriginalShipToProductionBalance
                        : undefined
                );
                setReceivingShipToProductionBalanceUpdated(
                    line.accountToOriginalBalance
                        ? line.accountToOriginalBalance + line.quantity
                        : line.quantity
                );
            }
        }
    }, [line]);

    useEffect(() => {
        if (originalShipToProductionBalanceUpdated !== undefined && line.quantityPerPallet) {
            setOriginalShipToProductionBalanceEachesUpdated(
                originalShipToProductionBalanceUpdated * line.quantityPerPallet
            );
        } else {
            setOriginalShipToProductionBalanceEachesUpdated(undefined);
        }
    }, [line, originalShipToProductionBalanceUpdated]);

    useEffect(() => {
        if (receivingShipToProductionBalanceUpdated && line.quantityPerPallet) {
            setReceivingShipToProductionBalanceEachesUpdated(
                receivingShipToProductionBalanceUpdated * line.quantityPerPallet
            );
        } else {
            setReceivingShipToProductionBalanceEachesUpdated(undefined);
        }
    }, [line, receivingShipToProductionBalanceUpdated]);

    const getProductDescription = () => {
        return `${line.displayName} (${line.displayId ? line.displayId : line.productSku}${
            line.graphicIdAndVersion ? `/${line.graphicIdAndVersion}` : ''
        })`;
    };

    return (
        <TableRow className={classes.tableRowContainer} data-testid={`line-${line.productSku}`}>
            <TableCell data-testid="product-description">
                <Typography>{getProductDescription()}</Typography>
            </TableCell>
            <TableCell data-testid="original-product-balance">
                <Typography>{line.accountFromOriginalBalance?.toLocaleString()} PL</Typography>
                <Typography className="helper-text">
                    {originalShipToProductionBalanceEaches
                        ? `${originalShipToProductionBalanceEaches.toLocaleString()} ea.`
                        : ''}
                </Typography>
            </TableCell>
            <TableCell data-testid="receiving-product-balance">
                <Typography>{line.accountToOriginalBalance?.toLocaleString()} PL</Typography>
                <Typography className="helper-text">
                    {receivingShipToProductionBalanceEaches
                        ? `${receivingShipToProductionBalanceEaches.toLocaleString()} ea.`
                        : ''}
                </Typography>
            </TableCell>
            <TableCell data-testid="requested-quantity">
                <Typography>{line.quantity?.toLocaleString()} PL</Typography>
                <Typography className="helper-text">
                    {quantityToTradeEaches ? `${quantityToTradeEaches.toLocaleString()} ea.` : ''}
                </Typography>
            </TableCell>
            <TableCell data-testid="original-product-balance-updated">
                <>
                    <Typography>
                        {originalShipToProductionBalanceUpdated?.toLocaleString()} PL
                    </Typography>
                    <Typography className="helper-text">
                        {originalShipToProductionBalanceEachesUpdated !== undefined
                            ? `${originalShipToProductionBalanceEachesUpdated.toLocaleString()} ea.`
                            : ''}
                    </Typography>
                </>
            </TableCell>
            <TableCell data-testid="receiving-product-balance-updated">
                <>
                    <Typography>
                        {receivingShipToProductionBalanceUpdated?.toLocaleString()} PL
                    </Typography>
                    <Typography className="helper-text">
                        {receivingShipToProductionBalanceEachesUpdated
                            ? `${receivingShipToProductionBalanceEachesUpdated.toLocaleString()} ea.`
                            : ''}
                    </Typography>
                </>
            </TableCell>
            <TableCell data-testid="request-status">
                <Typography>{getTradeLineStatus(line.status, cultureCode)}</Typography>
            </TableCell>
        </TableRow>
    );
}
