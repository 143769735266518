import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class EditProductionOrderColumns {
    static getEditProductionOrderColumns = (culture: string) => {
        return [
            createColumn('productionOrderLineId', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('displayId', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'productGraphicId'),
                filterable: true,
                sortable: true,
                searchable: false
            }),
            createColumn('displayName', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'descriptionCharacteristics'),
                filterable: true,
                sortable: true,
                searchable: false
            }),
            createColumn('productCoating', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'coating'),
                filterable: true,
                sortable: true,
                searchable: false
            }),
            createColumn('requestedDate', {
                dataType: ColumnDataType.Date,
                label: TranslationService.getTranslatedText(culture, 'originalRequestedDate'),
                filterable: true,
                sortable: true,
                searchable: false
            }),
            createColumn('originalRequestedDate', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'editRequestedDate'),
                filterable: true,
                sortable: true,
                searchable: false
            }),
            createColumn('quantityPerPallet', {
                dataType: ColumnDataType.Numeric,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('minimumOrderQuantity', {
                dataType: ColumnDataType.Numeric,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('originalPalletQuantity', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'originalRequestedQuantity'),
                filterable: true,
                sortable: true,
                searchable: false
            }),
            createColumn('purchaseQty', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'editQuantityToPurchase'),
                filterable: true,
                sortable: true,
                searchable: false
            }),
            createColumn('minimumMet', {
                dataType: ColumnDataType.Boolean,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('quantity', {
                dataType: ColumnDataType.Numeric,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('withinRange', {
                dataType: ColumnDataType.Boolean,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('subRows', {
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('addDisabled', {
                dataType: ColumnDataType.Boolean,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('customerProductId', {
                dataType: ColumnDataType.String,
                filterable: false,
                sortable: true,
                searchable: true,
                visible: false
            }),
            createColumn('customerProductName', {
                dataType: ColumnDataType.String,
                filterable: false,
                sortable: true,
                searchable: true,
                visible: false
            }),
            createColumn('productId', {
                dataType: ColumnDataType.Numeric,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('graphicId', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('graphicVersion', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productDescription', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productSku', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productName', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('graphicIdAndVersion', {
                dataType: ColumnDataType.String,
                filterable: true,
                sortable: true,
                searchable: true,
                visible: false
            }),
            createColumn('productUnits', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productSize', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('productShape', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productNeckSize', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productGraphicId', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productTabColor', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productType', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productEndProfile', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('customerProductId', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('customerProductName', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('customerProductName', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('palletQuantity', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('isMoreThanOriginalQty', {
                dataType: ColumnDataType.Boolean,
                visible: false
            }),
            createColumn('isLineRemoved', {
                dataType: ColumnDataType.Boolean,
                visible: false
            }),
            createColumn('hasLinkedDeliveryOrder', {
                dataType: ColumnDataType.Boolean,
                visible: false
            }),
            createColumn('splitOrder', {
                dataType: ColumnDataType.Boolean,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('hasCancelledDeliveryOrder', {
                dataType: ColumnDataType.Boolean,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('moqFees', {
                visible: false
            })
        ];
    };
}
