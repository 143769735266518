import {
    Grid,
    FormHelperText,
    makeStyles,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    FormControl,
    InputLabel
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
    medium,
    ltBlueGrey_34,
    xl,
    boldWeight,
    red,
    warningLabel
} from '../../../../themes/globalConstants';
import TextInput from '../../../reusable/molecules/TextInput';
import SelectInput from '../../../reusable/molecules/SelectInput';
import FormSection from '../../../reusable/molecules/FormSection';
import { EmeaGraphics } from '../../../../store/reducers/graphic-intake';
import { useFormContext, ErrorMessage, Controller } from 'react-hook-form';
import RadioInput from '../../../reusable/molecules/RadioInput';
import {
    emeaCountriestoMarketArtwork,
    emeaDepositScheme
} from '../../../../static-data/dropdown-lists';

interface Props {
    isEditDraft: boolean;
    graphicData?: EmeaGraphics;
}

const useStyles = makeStyles((theme) => ({
    subsectionHeader: {
        fontSize: xl,
        fontWeight: boldWeight,
        color: theme.palette.info.dark,
        marginBottom: '2em'
    },
    label: {
        color: theme.palette.info.dark,
        fontSize: medium,
        marginBottom: '0.5em'
    },
    radioGroup: {
        marginTop: '1.5em',
        paddingLeft: '0.5em'
    },
    divider: {
        height: 5,
        backgroundColor: ltBlueGrey_34,
        margin: '1.5em 0'
    },
    helperText: {
        '& .MuiFormHelperText-root': {
            marginLeft: 0
        }
    },
    warningContainer: {
        display: 'flex',
        backgroundColor: warningLabel,
        margin: '1em 1.5em 1em 0.5em'
    },
    warningIconCtn: {
        textAlign: 'center'
    },
    warningIcon: {
        fill: red
    },
    warningLabel: {
        fontSize: medium,
        fontWeight: boldWeight
    },
    capitalize: {
        textTransform: 'capitalize'
    },
    errorText: {
        color: red
    }
}));

const MarketInfo = ({ isEditDraft, graphicData }: Props) => {
    const classes = useStyles();
    const [draftLoaded, setDraftLoaded] = useState<boolean>(false);
    const { control, errors, watch, setValue } = useFormContext();
    const { t } = useTranslation();
    const selectedCountries = watch('distributeCountries') as string[];

    const isRequired = <Trans i18nKey="required">Required</Trans>;
    const isGermanDeposit = watch('depositScheme') === 'German - DPG';

    useEffect(() => {
        const graphicIsValid = graphicData?.requestName !== '';

        if (isEditDraft && graphicData && graphicIsValid) {
            if (graphicData.distributeCountries?.length)
                setValue('distributeCountries', graphicData.distributeCountries);
            setValue('depositScheme', graphicData.depositScheme);
            setValue('recycleMark', graphicData.recycleMark?.toString());
            setValue('glnNumber', graphicData.glnNumber);
            setValue('asiCertified', graphicData.asiCertified?.toString());
            setDraftLoaded(true);
        }
    }, [isEditDraft, graphicData, setValue]);

    return (
        <FormSection
            testId={'market-information-section'}
            sectionHeader={<Trans i18nKey="marketSubInfo">Market Information</Trans>}
        >
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                    <FormControl fullWidth error={errors.distributeCountries}>
                        <InputLabel>
                            <Trans i18nKey="distributeCountries">Countries to Market Artwork</Trans>
                        </InputLabel>
                        <Controller
                            as={
                                <Select
                                    MenuProps={{ variant: 'menu' }}
                                    inputProps={{
                                        'data-testid': 'distributeCountries'
                                    }}
                                    multiple
                                    renderValue={(selected) => (selected as string[]).join(', ')}
                                >
                                    {emeaCountriestoMarketArtwork.map((country) => (
                                        <MenuItem key={country.name} value={country.name}>
                                            <Checkbox
                                                checked={
                                                    selectedCountries?.indexOf(country.name) > -1
                                                }
                                            />
                                            <ListItemText
                                                primary={t(country.i18nKey, country.name)}
                                            />
                                        </MenuItem>
                                    ))}
                                </Select>
                            }
                            name="distributeCountries"
                            control={control}
                            defaultValue={[]}
                            errors={errors}
                            rules={{
                                required: isRequired,
                                validate: {
                                    hasAtLeastOne: (value) => {
                                        return value?.length || isRequired;
                                    }
                                }
                            }}
                            onChange={(event) => {
                                const value = event[0]?.target?.value;
                                if (!Array.isArray(value)) return selectedCountries;
                                return value;
                            }}
                        />
                        {!errors.distributeCountries && (
                            <FormHelperText>
                                <Trans i18nKey="distributeCountriesHelperText">
                                    Select the countries in which you plan to market the artwork
                                </Trans>
                            </FormHelperText>
                        )}
                        <FormHelperText>
                            <ErrorMessage errors={errors} name="distributeCountries" />
                        </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <SelectInput
                        label={<Trans i18nKey="depositScheme">Deposit Scheme</Trans>}
                        name="depositScheme"
                        testId="depositScheme"
                        control={control}
                        rules={{ required: isRequired }}
                        error={errors.depositScheme}
                        errors={errors}
                        styles={classes.helperText}
                        selectObj={emeaDepositScheme}
                        defaultValue="Not Applicable"
                        helperText={
                            <Trans i18nKey="depositSchemeHelper">
                                Specify if artwork will be marketed in country eligible for deposit
                                scheme{' '}
                            </Trans>
                        }
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                    <RadioInput
                        styles={classes.radioGroup}
                        label={
                            <Trans i18nKey="recycleMark">
                                Metals Recycle Forever Mark on Design?
                            </Trans>
                        }
                        name="recycleMark"
                        testId="recycleMark"
                        control={control}
                        rules={{ required: isRequired }}
                        radioControls={[
                            { value: 'true', label: <Trans i18nKey="yes">Yes</Trans> },
                            { value: 'false', label: <Trans i18nKey="no">No</Trans> }
                        ]}
                        error={errors.recycleMark}
                        errors={errors}
                        defaultValue="true"
                    />
                    {!errors.recycleMark && (
                        <FormHelperText>
                            <Trans i18nKey="recycleMarkHelper">
                                Select "No" if you do not want Ball to add the MRF logo on your
                                design
                            </Trans>
                        </FormHelperText>
                    )}
                </Grid>
                {(isGermanDeposit || (isEditDraft && !draftLoaded)) && (
                    <Grid item xs={6}>
                        <TextInput
                            type="text"
                            label={<Trans i18nKey="glnNumber">Customer GLN Number</Trans>}
                            name="glnNumber"
                            testId="glnNumber"
                            control={control}
                            rules={{ required: isRequired, pattern: /^[0-9\s]{13}$/ }}
                            error={errors.glnNumber}
                            errors={errors}
                            styles={classes.helperText}
                            helperText={
                                <Trans i18nKey="glnNumberHelper">
                                    Enter your 13 digit GLN code{' '}
                                </Trans>
                            }
                        />
                        <FormHelperText className={classes.errorText}>
                            {errors.glnNumber && errors.glnNumber.type === 'required' && (
                                <ErrorMessage
                                    errors={errors}
                                    name={'glnNumber'}
                                    message={isRequired}
                                />
                            )}
                            {errors.glnNumber && errors.glnNumber.type === 'pattern' && (
                                <ErrorMessage
                                    errors={errors}
                                    name={'glnNumber'}
                                    message={t(
                                        'glnNumberError',
                                        'Please enter a 13 digit numeric value'
                                    )}
                                />
                            )}
                        </FormHelperText>
                    </Grid>
                )}
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                    <RadioInput
                        styles={classes.radioGroup}
                        label={<Trans i18nKey="asiCertified">Is this design ASI certified?</Trans>}
                        name="asiCertified"
                        testId="asiCertified"
                        control={control}
                        rules={{ required: isRequired }}
                        radioControls={[
                            { value: 'true', label: <Trans i18nKey="yes">Yes</Trans> },
                            { value: 'false', label: <Trans i18nKey="no">No</Trans> }
                        ]}
                        error={errors.asiCertified}
                        errors={errors}
                        defaultValue="false"
                    />
                    {!errors.asiCertified && (
                        <FormHelperText>
                            <Trans i18nKey="asiCertifiedHelper">
                                Select “Yes” if this design is ASI certified
                            </Trans>
                        </FormHelperText>
                    )}
                </Grid>
            </Grid>
        </FormSection>
    );
};

export default MarketInfo;
