import React, { useState } from 'react';
import { makeStyles, TableRow, TableCell, Grid, Button, Typography } from '@material-ui/core';
import {
    ballBlue,
    blackWeight,
    boldWeight,
    medium,
    red,
    yellowNoticeBackground,
    yellowNoticeBackgroundBorder
} from '../../../../themes/globalConstants';
import { AdminUserTableRow, UserDashboardState } from '../../../../store/reducers/user-admin';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { loadViewEditUser, setAllUserPermissionsStatus } from '../../../../store/root-actions';
import UserAdminUsersStatusModal from './UserAdminUsersStatusModal';
import { AuthState } from '../../../../store/reducers/auth';
import { CustomerContextState } from '../../../../store/reducers/customer-context';

interface Props {
    key?: any;
    inActive?: boolean;
    user: AdminUserTableRow;
    hasAdminPersona: boolean;
}

const useStyles = makeStyles((theme) => ({
    name: {
        fontWeight: boldWeight,
        fontSize: medium,
        letterSpacing: 0.17
    },
    button: {
        fontSize: '1em',
        '&:hover': {
            backgroundColor: 'transparent',
            color: ballBlue,
            fontWeight: blackWeight,
            outline: 'none'
        },
        '&:focus': {
            backgroundColor: 'transparent',
            color: ballBlue,
            fontWeight: blackWeight
        }
    },
    disableButton: {
        color: red,
        fontSize: '1em',
        '&:hover': {
            backgroundColor: 'transparent',
            color: red,
            fontWeight: blackWeight,
            outline: 'none'
        },
        '&:focus': {
            backgroundColor: 'transparent',
            color: red,
            fontWeight: blackWeight
        }
    },
    noAdminContainer: {
        backgroundColor: yellowNoticeBackground,
        borderWidth: 1,
        borderColor: yellowNoticeBackgroundBorder,
        borderStyle: 'solid'
    }
}));

export default function UserAdminUsersRow({ user, inActive = false, hasAdminPersona }: Props) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [openStatusUserModal, setOpenStatusUserModal] = useState<boolean>(false);
    const { activeUsers, inactiveUsers } = useTypedSelector<UserDashboardState>(
        (state) => state.userAdminDashboard
    );
    const { userInfo } = useTypedSelector<AuthState>((state) => state.auth);
    const { selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );

    const handleAdminUsersStatus = () => {
        setOpenStatusUserModal(false);
        if (user && selectedAccountId) {
            const status = inActive ? 1 : 0;
            dispatch(setAllUserPermissionsStatus(user.userName, status, selectedAccountId));
        }
    };

    const userIsSelf = (user): Boolean => {
        return user.userName.toLowerCase() === userInfo?.preferred_username?.toLowerCase();
    };

    const actions = (user: AdminUserTableRow) => {
        const viewAndEdit = () => {
            if (hasAdminPersona && user.currentUserHasAdminPermission)
                return <Trans i18nKey="viewEdit">View/Edit</Trans>;
            return <Trans i18nKey="view">View</Trans>;
        };

        if (!hasAdminPersona && inActive) return;

        return (
            <TableCell>
                <Button
                    variant="text"
                    color="primary"
                    data-testid="view-edit-button"
                    className={classes.button}
                    onClick={onViewEditUser}
                >
                    {viewAndEdit()}
                </Button>
                {!userIsSelf(user) && hasAdminPersona && (
                    <>
                        {' | '}
                        <Button
                            variant="text"
                            color="primary"
                            data-testid="active-inactive-button"
                            className={classes.button}
                            onClick={() => setOpenStatusUserModal(true)}
                        >
                            {inActive ? (
                                <Trans i18nKey="makeUserActive">Make User Active</Trans>
                            ) : (
                                <Trans i18nKey="makeUserInactive">Make User Inactive</Trans>
                            )}
                        </Button>
                    </>
                )}
            </TableCell>
        );
    };

    const onViewEditUser = () => {
        if (user && activeUsers && !inActive) {
            let selectedUser = activeUsers.find(
                (activeUser) => activeUser.userName === user.userName
            );

            if (selectedUser) {
                dispatch(
                    loadViewEditUser(
                        selectedUser.userName,
                        selectedUser.permissions,
                        selectedUser.firstName,
                        selectedUser.lastName,
                        selectedUser.lastLogin,
                        true
                    )
                );
                history.push('/user-admin-user-details');
            }
        } else if (inactiveUsers) {
            let selectedUser = inactiveUsers.find(
                (inactiveUser) => inactiveUser.userName === user.userName
            );

            if (selectedUser) {
                dispatch(
                    loadViewEditUser(
                        selectedUser.userName,
                        selectedUser.permissions,
                        selectedUser.firstName,
                        selectedUser.lastName,
                        selectedUser.lastLogin,
                        false
                    )
                );
                history.push('/user-admin-user-details');
            }
        }
    };

    return (
        <>
            <TableRow hover={true} key={user.userName}>
                <TableCell>
                    <Grid container>
                        <Grid item xs={12} className={classes.name}>
                            <Typography>{user.userName}</Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid container>
                        <Grid item xs={12} className={classes.name}>
                            <Typography>{user.roles}</Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                {actions(user)}
            </TableRow>
            <UserAdminUsersStatusModal
                openAdminUsersStatusModal={openStatusUserModal}
                onClose={() => setOpenStatusUserModal(false)}
                handleAdminUsersStatus={handleAdminUsersStatus}
                inActive={inActive}
            />
        </>
    );
}
