import React, { Dispatch, SetStateAction } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { makeStyles, Grid } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import Button from '../atoms/Button';
import WeekSelectorDropdown from '../../pages/MakeItBulkReviewATM/components/WeekSelectorDropdown';
import { FormattedWeek } from '../../pages/MakeItBulkReviewATM/models/FormattedWeek';
import { DateProperties } from '../../../utility/helpers/make-it-bulk-helpers';

interface Props {
    onCancel: () => void;
    onOk: () => void;
    disableSubmit?: boolean;
    testId: string;
    weeks: FormattedWeek[];
    onDateSelected: Dispatch<SetStateAction<DateProperties>>;
    selectedDate: DateProperties;
    showIcons: boolean;
    showCsvWarning?: boolean;
}

const useStyles = makeStyles((theme) => ({
    btnContainer: {
        paddingRight: '0.7em',
        marginTop: '1em'
    },
    dropdownContainer: {
        marginBottom: '1em'
    },
    cancelBtn: {
        width: '6.43em',
        height: '2.86em'
    },
    okBtn: {
        width: '6.43em',
        height: '2.86em'
    }
}));

const WeekSelectorDropdownModal = (props: Props) => {
    const classes = useStyles();
    useTranslation();

    return (
        <div data-testid="week-selector">
            <div className={classes.dropdownContainer}>
                <WeekSelectorDropdown
                    testId={props.testId}
                    weeks={props.weeks}
                    selectedDate={props.selectedDate}
                    onDateSelected={props.onDateSelected}
                    showIcons={props.showIcons}
                />
            </div>
            <Grid container>
                <Grid
                    container
                    item
                    xs={8}
                    justify="flex-end"
                    alignItems="center"
                    spacing={1}
                    className={classes.btnContainer}
                >
                    <Grid item xs={2}>
                        <Button
                            type="button"
                            variant="outlined"
                            color="secondary"
                            data-testid="cancel-btn"
                            onClick={props.onCancel}
                            className={classes.cancelBtn}
                        >
                            <Trans i18nKey="cancel">Cancel</Trans>
                        </Button>
                    </Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={2}>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            data-testid="ok-btn"
                            onClick={props.onOk}
                            className={classes.okBtn}
                            disabled={!!props.disableSubmit ? props.disableSubmit : false}
                        >
                            <Trans i18nKey="ok">Ok</Trans>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default WeekSelectorDropdownModal;
