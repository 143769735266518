import GraphicsService from '../../utility/services/graphics-service';
import {
    GRAPHICS_DASHBOARD_LOAD_COMPLETED,
    GRAPHICS_DASHBOARD_LOAD_DRAFT,
    GRAPHICS_DASHBOARD_LOAD_INPROGRESS,
    GRAPHICS_DASHBOARD_LOADING,
    GRAPHICS_DASHBOARD_ERROR,
    GRAPHICS_DASHBOARD_LOADED
} from './action-types';
import {
    EmeaGraphics,
    EmeaGraphicsStatus,
    GraphicsDetailStatus,
    GraphicsState,
    GraphicsStatus
} from '../reducers/graphic-intake';
import { Account, AddressType } from '../reducers/customer-context';
import { appInsights } from '../../utility/AppInsights';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import TranslationService from '../../utility/services/translation-service';
import { stringFormatterReplace } from '../../utility/helpers/formatters';
import { AuthState } from '../reducers/auth';
import moment from 'moment';

export const filterBillTos = (accounts: Account[]) => {
    let filteredAccountIds: string[] = [];

    let filteredAccountType = accounts.filter(
        (account) =>
            account.addressType === AddressType.CB ||
            account.addressType === AddressType.CX ||
            account.addressType === AddressType.CP ||
            account.addressType === AddressType.CG
    );

    filteredAccountType.forEach((account) => {
        filteredAccountIds.push(account.accountId);
    });

    return filteredAccountIds;
};

export const emeaGraphicsStatusFormatter = (
    cultureCode: string,
    statusCode: string,
    iterationNumber?: number
) => {
    if (statusCode === EmeaGraphicsStatus.FeasibilityCheckComplete) {
        let statusText = TranslationService.getTranslatedText(
            cultureCode,
            EmeaGraphicsStatus.FeasibilityCheckComplete
        );
        return stringFormatterReplace(
            statusText,
            iterationNumber ? iterationNumber.toString() : '1'
        );
    } else {
        return TranslationService.getTranslatedText(cultureCode, statusCode);
    }
};

/**
 * Statuses from ESKO should be based on GraphicsStatus enum
 * And the statuses HOLD and CANC, will have additional information (date and the detail) as follows:
 *      - HOLD_YYYY-MM-DD_Customer Requested
 *      - CANC_YYYY-MM-DD_Artwork Not Approved
 * The formatter function will handle the 3 fields o get the translation correctly
 */
export const graphicsStatusFormatter = (
    cultureCode: string,
    status: string,
    graphicCurrentStatuses?: GraphicsDetailStatus
) => {
    if (Object.values(GraphicsStatus).includes(status as GraphicsStatus)) {
        const translatedText = TranslationService.getTranslatedText(cultureCode, status) || status;

        const date = graphicCurrentStatuses?.iterationDate;
        const statusDetail = graphicCurrentStatuses?.status || '';
        const formattedDate = date ? moment(date, 'YYYY-MM-DD').format('YYYY/MM/DD') : '';

        return stringFormatterReplace(translatedText, formattedDate, statusDetail);
    } else {
        return 'N/A';
    }
};

export const loadDraftGraphics = (accounts: Account[]) => {
    return (dispatch, getState) => {
        const { regionCode } = getState().regionCulture;
        dispatch({ type: GRAPHICS_DASHBOARD_LOADING });
        const { userInfo, accessToken } = getState().auth;
        let filteredAccounts = filterBillTos(accounts);

        GraphicsService.getGraphicsByStateAndShipTo(
            { Username: [userInfo.email], AccountIds: filteredAccounts },
            accessToken,
            regionCode
        )
            .then((response) => {
                let allGraphics = response.data;
                let draftState = GraphicsState[GraphicsState.Draft];
                let draftGraphics = allGraphics.filter((graphic) => graphic.state === draftState);
                dispatch({ type: GRAPHICS_DASHBOARD_LOAD_DRAFT, graphics: draftGraphics });
            })
            .catch((error) => {
                dispatch({ type: GRAPHICS_DASHBOARD_ERROR, error: error });
            });
    };
};

export const loadGraphics = (accounts: Account[], cultureCode: string) => {
    return (dispatch, getState) => {
        dispatch({ type: GRAPHICS_DASHBOARD_LOADING });
        const { userInfo, accessToken }: AuthState = getState().auth;
        const { regionCode } = getState().regionCulture;
        let filteredAccounts = filterBillTos(accounts);

        if (!filteredAccounts.length) {
            dispatch({ type: GRAPHICS_DASHBOARD_LOAD_DRAFT, graphics: [] });
            dispatch({
                type: GRAPHICS_DASHBOARD_LOAD_INPROGRESS,
                graphics: []
            });
            dispatch({ type: GRAPHICS_DASHBOARD_LOAD_COMPLETED, graphics: [] });
            dispatch({ type: GRAPHICS_DASHBOARD_LOADED });
        } else {
            const email = userInfo?.email.toLowerCase().includes('@ball.com')
                ? userInfo?.preferred_username
                : userInfo?.email || '';

            GraphicsService.getGraphicsByStateAndShipTo(
                { Username: [email], AccountIds: filteredAccounts },
                accessToken,
                regionCode
            )
                .then((response) => {
                    let allGraphics = response.data;

                    allGraphics.forEach((graphic) => {
                        if (graphic.status) {
                            graphic.displayStatus = graphicsStatusFormatter(
                                cultureCode,
                                graphic.status,
                                graphic.graphicCurrentStatuses?.[0]
                            );
                        }
                        if (graphic.graphicCurrentStatuses) {
                            graphic.graphicCurrentStatuses?.forEach((statusDetail) => {
                                if (statusDetail.status) {
                                    statusDetail.displayStatus = emeaGraphicsStatusFormatter(
                                        cultureCode,
                                        statusDetail.status,
                                        statusDetail.iterationNumber
                                    );
                                }
                            });
                        }
                        if ((graphic as EmeaGraphics).graphicStatusHistory) {
                            (graphic as EmeaGraphics).graphicStatusHistory?.forEach(
                                (statusDetail) => {
                                    if (statusDetail.status) {
                                        statusDetail.displayStatus = emeaGraphicsStatusFormatter(
                                            cultureCode,
                                            statusDetail.status,
                                            statusDetail.iterationNumber
                                        );
                                    }
                                }
                            );
                        }
                    });

                    let draftGraphics = allGraphics.filter(
                        (graphic) =>
                            graphic.state?.toString() === GraphicsState[GraphicsState.Draft]
                    );
                    let inProgressGraphics = allGraphics.filter(
                        (graphic) =>
                            graphic.state === GraphicsState[GraphicsState.InProgress] &&
                            !graphic.status?.includes('CANC')
                    );
                    let completedGraphics = allGraphics.filter(
                        (graphic) =>
                            graphic.state === GraphicsState[GraphicsState.Completed] ||
                            graphic.status?.includes('CANC')
                    );

                    dispatch({ type: GRAPHICS_DASHBOARD_LOAD_DRAFT, graphics: draftGraphics });
                    dispatch({
                        type: GRAPHICS_DASHBOARD_LOAD_INPROGRESS,
                        graphics: inProgressGraphics
                    });
                    dispatch({
                        type: GRAPHICS_DASHBOARD_LOAD_COMPLETED,
                        graphics: completedGraphics
                    });
                    dispatch({ type: GRAPHICS_DASHBOARD_LOADED });
                })
                .catch((error) => {
                    dispatch({ type: GRAPHICS_DASHBOARD_ERROR, error: error });
                });
        }
    };
};

export const deleteDraftGraphics = (graphicId: string) => {
    return (dispatch, getState) => {
        dispatch({ type: GRAPHICS_DASHBOARD_LOADING });
        const { accessToken } = getState().auth;

        GraphicsService.deleteGraphics(graphicId, accessToken)
            .then((response) => {})
            .catch((error) => {
                const user = getState().auth?.userInfo?.email;
                if (appInsights && user) {
                    const appInsightsError = error.message + ' deleteDraftGraphics-' + user;
                    appInsights.trackException({
                        error: new Error(appInsightsError),
                        severityLevel: SeverityLevel.Error
                    });
                }
            });
    };
};
