import React, { ReactNode } from 'react';

export interface State {
    selectedTab: 'Active' | 'Inactive' | 'Unrecognized';
}

export interface ProviderProps {
    children: ReactNode;
    value: State;
}

const ManageProductsContext = React.createContext<State>({} as State);

function ManageProductsProvider(props: ProviderProps) {
    const { children, value } = props;
    return (
        <ManageProductsContext.Provider value={value}>{children}</ManageProductsContext.Provider>
    );
}

const useManageProductsContext = () => React.useContext(ManageProductsContext);

export { ManageProductsProvider, useManageProductsContext };
