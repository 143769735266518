import { AuthState } from '../reducers/auth';
import {
    CONTRACT_SUMMARY_LOADING,
    CONTRACT_SUMMARY_LOADED,
    CONTRACT_SUMMARY_ERROR,
    CONTRACT_ACKNOWLEDGEMENT_ERROR,
    CONTRACT_ACKNOWLEDGEMENT_SUCCEEDED
} from './action-types';
import PricingService from '../../../src/utility/services/pricing-service';
import { ContractAndDocument } from '../reducers/contract-summary';
import {
    checkAndAddSchemeToPdfUrls,
    documentIsApproved,
    documentIsCurrentlyActive
} from '../../utility/helpers/pricing-helpers';

export const loadContractsAndDocuments = (accounts: string[], isShipToRequest: boolean = false) => {
    return async (dispatch, getState) => {
        const auth: AuthState = getState().auth;

        dispatch({ type: CONTRACT_SUMMARY_LOADING });

        PricingService.getPricingTermsAndConditions(
            auth.accessToken,
            accounts,
            'English',
            isShipToRequest
        )
            .then((response) => {
                const documents = response.data;
                documents.forEach((doc) => {
                    doc.pdfUrl = checkAndAddSchemeToPdfUrls(doc);
                    doc.isApproved = documentIsApproved(doc);
                    doc.isActive = documentIsCurrentlyActive(doc);
                });
                const requiresAcknowledgement = documents
                    .filter((document) => document.isActive && !document.isApproved)
                    .map((document) => ({ billToId: document.billTo }));

                dispatch({ type: CONTRACT_SUMMARY_LOADED, documents, requiresAcknowledgement });
            })
            .catch((error) => {
                dispatch({ type: CONTRACT_SUMMARY_ERROR, error });
            });
    };
};

export const clearContractsAndDocuments = () => {
    return async (dispatch, getState) => {
        const documents = [];
        dispatch({ type: CONTRACT_SUMMARY_LOADED, documents });
    };
};

export const acknowledgeTermsAndConditionsDocument = (document: ContractAndDocument) => {
    return async (dispatch, getState) => {
        const auth: AuthState = getState().auth;

        return PricingService.acknowledgeTermsAndConditionsDocument(
            auth.accessToken,
            document,
            auth.userInfo?.email ?? ''
        );
    };
};

export const getAcknowledgementSuccess = () => ({
    type: CONTRACT_ACKNOWLEDGEMENT_SUCCEEDED
});

export const getAcknowledgementFailed = (error) => ({
    type: CONTRACT_ACKNOWLEDGEMENT_ERROR,
    error
});
