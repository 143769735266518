import React, { useEffect, useState } from 'react';
import NoteIcon from '@material-ui/icons/Note';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles, Box, Typography, Tabs, Tab, Grid } from '@material-ui/core';
import Link from '../../../reusable/atoms/Link';
import Button from '../../../reusable/atoms/Button';
import GraphicsDraftTab from './GraphicsDraftTab';
import GraphicsCompletedTab from './GraphicsCompletedTab';
import GraphicsInProgressTab from './GraphicsInProgressTab';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { GraphicDashboardState } from '../../../../store/reducers/graphic-dashboard';
import { useQuery } from '../../../../utility/helpers/query-helpers';
import useSecurity, { Activity, SecurityLevel } from '../../../../utility/auth/useSecurity';
import {
    small,
    blackWeight,
    inactiveTabBlue,
    lato,
    xl,
    black,
    medium,
    white
} from '../../../../themes/globalConstants';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { loadGraphics } from '../../../../store/actions/graphic-dashboard';
import { useDispatch } from 'react-redux';
import { getUserCustomerAccounts } from '../../../../store/actions/customer-context';
import { GRAPHICS_DATA_RESET_STATE } from '../../../../store/actions/action-types';
import { Trans, useTranslation } from 'react-i18next';
import { Flags, FlagTypes } from '../../../../utility/helpers/feature-flag';
import { Region } from '../../../../store/reducers/graphic-intake';
import { usePrevious } from '../../../../utility/helpers/react-util';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
interface GraphicsCounts {
    drafts: number;
    inProgress: number;
    recentlyCompleted: number;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: '4.5em',
        marginBottom: '4.5em',
        position: 'relative',
        '& h2': {
            fontSize: xl,
            fontFamily: lato,
            fontWeight: blackWeight,
            color: theme.palette.secondary.main
        }
    },
    appBar: {
        width: '75%'
    },
    tabPanel: {
        width: '100%'
    },
    panelHeader: {
        marginBottom: '1em'
    },
    counter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '0.5em',
        fontSize: small,
        fontWeight: blackWeight
    },
    tabs: {
        height: '3em'
    },
    tabLabel: {
        backgroundColor: inactiveTabBlue,
        borderTopLeftRadius: '0.625em',
        borderTopRightRadius: '0.625em',
        maxWidth: 'fit-content',
        '&:not(:first-of-type)': {
            marginLeft: '0.25em'
        },
        paddingLeft: '2em',
        paddingRight: '2em',
        color: black,
        textTransform: 'none'
    },
    tabLabelText: {
        fontSize: medium,
        fontWeight: blackWeight
    },
    selectedTab: {
        backgroundColor: white
    },
    formLinkWrapper: {
        position: 'absolute',
        marginTop: '-4em',
        [theme.breakpoints.down('md')]: {
            position: 'inherit'
        }
    },
    formLinkIcon: {
        marginLeft: '0.25em',
        transform: 'rotate(270deg)'
    },
    newGraphicButton: {
        marginTop: '1.5em'
    }
}));

const TabPanel = (props: TabPanelProps) => {
    const classes = useStyles();
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`graphics-tabpanel-${index}`}
            aria-labelledby={`graphics-tab-${index}`}
            {...other}
            className={classes.tabPanel}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
};

//This assigns props for accessibility purposes
const a11yProps = (index: any) => {
    return {
        id: `graphics-tab-${index}`,
        'aria-controls': `graphics-tabpanel-${index}`
    };
};

const GraphicsTabs = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { inProgressGraphics, completedGraphics, draftGraphics } =
        useTypedSelector<GraphicDashboardState>((state) => state.graphicDashboard);
    const graphicIntake = useTypedSelector((state) => state.graphicIntake);
    const { accounts } = useTypedSelector<CustomerContextState>((state) => state.customerContext);
    const { regionCode, cultureCode } = useTypedSelector<RegionCultureState>(
        (state) => state.regionCulture
    );
    const { selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const [value, setValue] = useState<number>(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const query = useQuery();
    const status = query.get('status');

    const intakeSecurity = useSecurity(Activity.GraphicsIntakeForm);
    const draftIntakeSecurity = useSecurity(Activity.DraftGraphicsIntakeForm);
    const inProgressSecurity = useSecurity(Activity.InProgressGraphicsStatuses);
    const recentlyCompletedGraphicsSecurity = useSecurity(Activity.RecentlyCompletedGraphics);
    const [submitNewGraphicKey, setSubmitNewGraphicKey] = useState<string>('submitNewGraphic');
    const [recentlyCompletedKey, setRecentlyCompletedKey] = useState<string>('recentlyCompleted');
    const [draftGraphicsFormsKey, setDraftGraphicsFormsKey] =
        useState<string>('draftGraphicsForms');
    const [graphicsCounts, setGraphicsCounts] = useState<GraphicsCounts>({
        inProgress: 0,
        recentlyCompleted: 0,
        drafts: 0
    });
    const prevSelectedAccount = usePrevious(selectedAccountId);

    useEffect(() => {
        switch (status) {
            case 'InProgress':
                setValue(0);
                break;

            case 'Completed':
                setValue(1);
                break;

            case 'Draft':
                setValue(2);
                break;
        }
    }, [status]);

    useEffect(() => {
        // Prevents defaulting to a tab that is disabled for the user.
        const tabSecurity = [
            inProgressSecurity,
            recentlyCompletedGraphicsSecurity,
            draftIntakeSecurity
        ];
        for (let i = 0; i < tabSecurity.length; i++) {
            if (tabSecurity[i] !== SecurityLevel.None) {
                setValue(i);
                break;
            }
        }
    }, [inProgressSecurity, recentlyCompletedGraphicsSecurity, draftIntakeSecurity]);

    useEffect(() => {
        if (accounts && !accounts.length) {
            dispatch(getUserCustomerAccounts());
        }
    }, [dispatch, accounts]);

    useEffect(() => {
        setGraphicsCounts({
            inProgress: inProgressGraphics.filter(
                (graphic) =>
                    graphic.billToAddress === selectedAccountId ||
                    graphic.customerAccount === selectedAccountId
            ).length,
            recentlyCompleted: completedGraphics.filter(
                (graphic) =>
                    graphic.billToAddress === selectedAccountId ||
                    graphic.customerAccount === selectedAccountId
            ).length,
            drafts: draftGraphics.filter(
                (graphic) =>
                    graphic.billToAddress === selectedAccountId ||
                    graphic.customerAccount === selectedAccountId
            ).length
        });
    }, [selectedAccountId, regionCode, draftGraphics, inProgressGraphics, completedGraphics]);

    useEffect(() => {
        if (
            selectedAccountId &&
            (!prevSelectedAccount ||
                (prevSelectedAccount && prevSelectedAccount !== selectedAccountId)) &&
            accounts &&
            accounts.length &&
            cultureCode
        ) {
            const currentAccount = accounts.find(
                (account) => account.accountId === selectedAccountId
            );
            if (currentAccount) dispatch(loadGraphics([currentAccount], cultureCode));
        }
    }, [dispatch, accounts, selectedAccountId, cultureCode, prevSelectedAccount]);

    useEffect(() => {
        if (graphicIntake.graphics) {
            dispatch({ type: GRAPHICS_DATA_RESET_STATE });
        }
    }, [dispatch, graphicIntake.graphics]);

    useEffect(() => {
        const region = regionCode === Region.EU ? 'Emea' : '';
        setSubmitNewGraphicKey(`submitNewGraphic${region}`);
        setRecentlyCompletedKey(`recentlyCompleted${region}`);
        setDraftGraphicsFormsKey(`draftGraphicsForms${region}`);
    }, [regionCode]);

    const TabLabel = (label: string, count: number) => {
        return (
            <Grid container justify="center" alignItems="center">
                <Typography className={classes.tabLabelText}>{label}</Typography>
                <Typography component="span" className={classes.counter}>
                    {'(' + count + ')'}
                </Typography>
            </Grid>
        );
    };

    const formButton = (
        <Grid
            container
            justify="flex-end"
            alignItems="center"
            spacing={2}
            className={classes.formLinkWrapper}
        >
            <Grid item className={classes.newGraphicButton}>
                <Link
                    to={regionCode === Region.EU ? '/emea-graphics-intake' : '/graphics-intake'}
                    underline="none"
                    component={RouterLink}
                    data-testid="intake-form-button"
                    disabled={intakeSecurity !== SecurityLevel.Edit}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={intakeSecurity !== SecurityLevel.Edit}
                    >
                        <Trans i18nKey={submitNewGraphicKey}>Submit New Graphic</Trans>
                        <NoteIcon className={classes.formLinkIcon} />
                    </Button>
                </Link>
            </Grid>
        </Grid>
    );

    return (
        <Flags
            authorizedFlags={[FlagTypes.GraphicsSummary]}
            renderOn={() => (
                <div className={classes.root} data-testid="graphics-tabs">
                    {formButton}
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="graphics dashboard tabs"
                        className={classes.tabs}
                        scrollButtons="auto"
                        variant="scrollable"
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab
                            label={TabLabel(
                                t('inProgress', 'In Progress'),
                                graphicsCounts.inProgress
                            )}
                            {...a11yProps(0)}
                            className={`${classes.tabLabel} ${value === 0 && classes.selectedTab}`}
                            disabled={inProgressSecurity === SecurityLevel.None}
                            data-testid={'inProgressTab'}
                        />
                        <Tab
                            label={TabLabel(
                                t(recentlyCompletedKey, 'Recently Completed'),
                                graphicsCounts.recentlyCompleted
                            )}
                            {...a11yProps(1)}
                            className={`${classes.tabLabel} ${value === 1 && classes.selectedTab}`}
                            disabled={recentlyCompletedGraphicsSecurity === SecurityLevel.None}
                            data-testid={'completedTab'}
                        />
                        <Tab
                            label={TabLabel(
                                t(draftGraphicsFormsKey, 'Draft Graphics Forms'),
                                graphicsCounts.drafts
                            )}
                            {...a11yProps(2)}
                            className={`${classes.tabLabel} ${value === 2 && classes.selectedTab}`}
                            disabled={draftIntakeSecurity === SecurityLevel.None}
                            data-testid={'draftTab'}
                        />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <GraphicsInProgressTab />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <GraphicsCompletedTab />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <GraphicsDraftTab />
                    </TabPanel>
                </div>
            )}
            renderOff={() => (
                <div className={classes.root} data-testid="graphics-tabs">
                    {formButton}
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="graphics dashboard tabs"
                        className={classes.tabs}
                        scrollButtons="auto"
                        variant="scrollable"
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab
                            label={TabLabel(
                                t(draftGraphicsFormsKey, 'Draft Graphics Forms'),
                                draftGraphics.length
                            )}
                            {...a11yProps(2)}
                            className={classes.tabLabel}
                            disabled={draftIntakeSecurity === SecurityLevel.None}
                            data-testid={'draftTab'}
                        />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <GraphicsDraftTab />
                    </TabPanel>
                </div>
            )}
        />
    );
};

export default GraphicsTabs;
