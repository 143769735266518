import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import {
    lato,
    ltBlueGrey,
    ballBlue,
    datepickerDefaultGray,
    paleGrey,
    ballLtBlue,
    addFormItemButtonBlue
} from '../../../themes/globalConstants';
import { useTranslation } from 'react-i18next';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import { DayPickerRangeController, FocusedInputShape } from 'react-dates';
import clsx from 'clsx';
import styled from 'styled-components';
import moment from 'moment';
import { Moment } from 'moment';

const useStyles = makeStyles((theme) => ({
    navBtn: {
        position: 'absolute',
        top: 18,
        lineHeight: 0.78,
        borderRadius: 3,
        padding: '6px 9px',
        color: ballBlue,
        fontSize: '2rem'
    },
    prevIcon: {
        transform: 'rotate(90deg)',
        left: 22
    },
    nextIcon: {
        transform: 'rotate(270deg)',
        right: 22
    },
    selectedDay: {
        backgroundColor: ballLtBlue,
        color: 'black',
        height: '100%',
        width: '85%'
    },
    standardDay: {
        color: 'black',
        height: '100%',
        width: '100%',
        paddingTop: '.3em'
    },
    dayNumber: {
        text: lato,
        paddingTop: '.8em'
    }
}));

const StyledWrapper = styled.div`
    & .DayPicker,
    .DayPicker_weekHeaders {
        font-family: ${lato};
    }

    .DayPicker_transitionContainer {
        border: 0.5px solid ${ltBlueGrey};
        border-radius: 4px;
        margin-bottom: 2em;
    }

    td.CalendarDay__selected {
        background: ${ballLtBlue};
        color: #202020;
        border-top: 2px solid ${addFormItemButtonBlue};
        border-bottom: 2px solid ${addFormItemButtonBlue};
    }

    td.CalendarDay__selected:first-child {
        border-left: 2px solid ${addFormItemButtonBlue};
        border-radius: 2px 0 0 2px;
    }

    td.CalendarDay__selected:last-child {
        border-right: 2px solid ${addFormItemButtonBlue};
        border-radius: 0 2px 2px 0;
    }

    td.CalendarDay__selected_span {
        background: ${ballLtBlue};
        color: #202020;
        border-top: 2px solid ${addFormItemButtonBlue};
        border-bottom: 2px solid ${addFormItemButtonBlue};
    }

    .CalendarDay__hovered_span:hover,
    .CalendarDay__hovered_span {
        background: ${ballBlue};
    }

    .CalendarDay__blocked_out_of_range {
        background-color: ${paleGrey};
    }

    .CalendarDay__highlighted_calendar {
        background: ${ballLtBlue};
        border: 2px solid ${ballBlue} !important;
    }import { addFormItemButtonBlue } from './../../../themes/globalConstants';


    .CalendarDay {
        font-family: ${lato};
    }

    .CalendarDay__default {
        border: none;
    }

    .CalendarDay__outside {
        color: ${datepickerDefaultGray};
        border: none;
    }
`;

type Props = {
    startDate: Moment | null;
    endDate: Moment | null;
    autoFocusEndDate?: boolean;
    endDateOffset?: () => void;
    isOutsideRange: (day: any) => boolean;
    firstMonthVisible?: () => Moment;
    initialDateRange?: Moment[];
    onDateChange?: (startDate: Moment, endDate: Moment) => void;
    numMonths?: number;
    daySize?: number;
};

//Omit certain props to appease the almighty daypicker
type DaypickerProps = Omit<Props, 'onDateChange'>;

const DayRangePicker = (props: Props, daypickerProps: DaypickerProps) => {
    const classes = useStyles();
    useTranslation();
    const [focusedInput, setFocusedInput] = useState<FocusedInputShape>('startDate');

    const handleDateChange = ({ startDate, endDate }: any) => {
        if (props.onDateChange) {
            props.onDateChange(startDate, endDate);
        }
    };

    // set the default selected value on modal open
    useEffect(() => {
        const initialInput = { startDate: props.startDate, endDate: props.endDate };
        handleDateChange(initialInput);
    }, []);

    const handleFocusChange = (focusedInput) => {
        setFocusedInput(!focusedInput ? 'startDate' : focusedInput);
    };

    //End Date Offset to always end selection on Sunday/EoW
    const getEndDateOffset = (defaultCalendarStart) => {
        return moment(defaultCalendarStart).endOf('isoWeek');
    };

    //Start Date Offset to always begin selection on Monday/SoW regardless of which day is clicked
    const getStartDateOffset = (defaultCalendarStart) => {
        return moment(defaultCalendarStart).startOf('isoWeek');
    };

    const prevIcon = <ExpandMoreOutlinedIcon className={clsx(classes.navBtn, classes.prevIcon)} />;
    const nextIcon = <ExpandMoreOutlinedIcon className={clsx(classes.navBtn, classes.nextIcon)} />;

    return (
        <StyledWrapper>
            <DayPickerRangeController
                {...daypickerProps}
                navPrev={prevIcon}
                navNext={nextIcon}
                horizontalMonthPadding={38}
                daySize={props.daySize ?? 55}
                numberOfMonths={props.numMonths ?? 2}
                firstDayOfWeek={1}
                enableOutsideDays
                hideKeyboardShortcutsPanel={true}
                startDate={props.startDate}
                endDate={props.endDate}
                focusedInput={focusedInput || 'startDate'}
                startDateOffset={getStartDateOffset}
                endDateOffset={getEndDateOffset}
                isOutsideRange={props.isOutsideRange}
                initialVisibleMonth={props.firstMonthVisible}
                onFocusChange={(focusedInput) => handleFocusChange(focusedInput)}
                onDatesChange={(date) => handleDateChange(date)}
            />
        </StyledWrapper>
    );
};

export default DayRangePicker;
