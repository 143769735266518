import React, { useEffect, useState } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { black, blackWeight, boldWeight, ltBlueGrey_34 } from '../../../../themes/globalConstants';
import { generateKey } from '../../../../utility/helpers/order-helpers';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import MakeItReviewOrderSectionColumns from './MakeItReviewOrderSectionColumns';
import MakeItReviewOrderSectionRow from './MakeItReviewOrderSectionRow';
import { MakeItBulkATMLineItem } from '../../../../store/reducers/makeit-bulk-atm';
import clsx from 'clsx';
import { selectIsLargeCustomerAccount } from '../../../../store/selectors';

interface Props {
    lines: MakeItBulkATMLineItem[];
    showPrevPalletQty: boolean;
    showCsvLineNumber: boolean;
    planItToMakeIt: boolean;
    isDraft: boolean;
    handleGroupRemovalDisable?: () => void;
}

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        width: '100%',
        marginTop: '1em',
        '& .MuiToolbar-root': {
            minHeight: 0,
            padding: '0 0.125em',
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                }
            }
        },
        '& .MuiTableCell-head': {
            justifyContent: 'space-between',
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em'
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTablePagination-root': {
            '& .MuiTablePagination-caption': {
                marginLeft: '1em',
                fontWeight: boldWeight,
                color: black
            }
        },
        '& .MuiTableHead-root': {
            borderTop: '5px solid',
            borderTopColor: ltBlueGrey_34
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    },
    editAtmTableWrapper: {
        '& .MuiTableHead-root': {
            '& th': {
                '&:nth-child(1)': {
                    width: '6%'
                },
                '&:nth-child(2)': {
                    width: '15%'
                },
                '&:nth-child(3)': {
                    width: '25%'
                },
                '&:nth-child(4)': {
                    width: '10%'
                },
                '&:nth-child(5)': {
                    width: '10%'
                },
                '&:nth-child(6)': {
                    width: '15%'
                },
                '&:nth-child(7)': {
                    width: '8%'
                }
            }
        }
    },
    makeItGuidedTableWrapper: {
        '& .MuiTableHead-root': {
            '& th': {
                '&:nth-child(1)': {
                    width: '15%'
                },
                '&:nth-child(2)': {
                    width: '35%'
                },
                '&:nth-child(3)': {
                    width: '10%'
                },
                '&:nth-child(4)': {
                    width: '10%'
                },
                '&:nth-child(5)': {
                    width: '15%'
                },
                '&:nth-child(6)': {
                    width: '10%'
                }
            }
        }
    },
    makeItGuidedTableWrapperLarge: {
        '& .MuiTableHead-root': {
            '& th': {
                '&:nth-child(1)': {
                    width: '15%'
                },
                '&:nth-child(2)': {
                    width: '30%'
                },
                '&:nth-child(3)': {
                    width: '10%'
                },
                '&:nth-child(4)': {
                    width: '10%'
                },
                '&:nth-child(5)': {
                    width: '10%'
                },
                '&:nth-child(6)': {
                    width: '15%'
                },
                '&:nth-child(7)': {
                    width: '10%'
                }
            }
        }
    },
    makeItGuidedTableWrapperCsvLarge: {
        '& .MuiTableHead-root': {
            '& th': {
                '&:nth-child(1)': {
                    width: '6%'
                },
                '&:nth-child(2)': {
                    width: '15%'
                },
                '&:nth-child(3)': {
                    width: '26%'
                },
                '&:nth-child(4)': {
                    width: '10%'
                },
                '&:nth-child(5)': {
                    width: '10%'
                },
                '&:nth-child(6)': {
                    width: '10%'
                },
                '&:nth-child(7)': {
                    width: '13%'
                },
                '&:nth-child(8)': {
                    width: '10%'
                }
            }
        }
    },
    makeItGuidedTableWrapperPrevQtyLarge: {
        '& .MuiTableHead-root': {
            '& th': {
                '&:nth-child(1)': {
                    width: '14%'
                },
                '&:nth-child(2)': {
                    width: '28%'
                },
                '&:nth-child(3)': {
                    width: '9%'
                },
                '&:nth-child(4)': {
                    width: '9%'
                },
                '&:nth-child(5)': {
                    width: '9%'
                },
                '&:nth-child(6)': {
                    width: '9%'
                },
                '&:nth-child(7)': {
                    width: '13%'
                },
                '&:nth-child(8)': {
                    width: '9%'
                }
            }
        }
    }
}));

const MakeItReviewOrderSectionProducts = ({
    lines,
    showPrevPalletQty,
    showCsvLineNumber,
    planItToMakeIt,
    isDraft,
    handleGroupRemovalDisable
}: Props) => {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [activeLines, setActiveLines] = useState<MakeItBulkATMLineItem[]>([]);
    const [columns, setColumns] = useState<any>([]);
    const isLargeCustomer = useTypedSelector<boolean>(selectIsLargeCustomerAccount);

    useEffect(() => {
        setColumns(
            MakeItReviewOrderSectionColumns.getColumns(
                cultureCode,
                showCsvLineNumber,
                showPrevPalletQty,
                planItToMakeIt,
                isLargeCustomer,
                isDraft
            )
        );
    }, [
        cultureCode,
        showCsvLineNumber,
        showPrevPalletQty,
        planItToMakeIt,
        isDraft,
        isLargeCustomer
    ]);

    useEffect(() => {
        setActiveLines(lines.filter((line) => !line.deleted));
    }, [lines]);

    const CustomToolbarOptions = new ToolbarOptions({
        exportButton: false,
        printButton: false,
        enablePagination: false,
        searchText: false,
        itemsPerPage: lines.length
    });

    const ProductRow = ({ row }: { row: MakeItBulkATMLineItem }) => {
        return (
            <MakeItReviewOrderSectionRow
                key={row.productSku}
                item={row}
                showPrevPalletQty={showPrevPalletQty}
                showCsvLineNumber={showCsvLineNumber}
                isDraft={isDraft}
                handleGroupRemovalDisable={handleGroupRemovalDisable}
            />
        );
    };

    return (
        <Grid
            container
            className={clsx(
                classes.tableWrapper,
                !isLargeCustomer
                    ? showCsvLineNumber
                        ? classes.editAtmTableWrapper
                        : classes.makeItGuidedTableWrapper
                    : showCsvLineNumber
                    ? classes.makeItGuidedTableWrapperCsvLarge
                    : showPrevPalletQty
                    ? classes.makeItGuidedTableWrapperPrevQtyLarge
                    : !isDraft
                    ? classes.makeItGuidedTableWrapperLarge
                    : classes.makeItGuidedTableWrapper
            )}
            key={generateKey('makeit-bulk-atm-review')}
            data-testid="make-it-bulk-review-atm-product-grid"
        >
            <DataGrid
                columns={columns}
                dataSource={activeLines}
                gridName="Make It Bulk Review Grid"
                rowComponent={ProductRow}
                toolbarOptions={CustomToolbarOptions}
            />
        </Grid>
    );
};

export default MakeItReviewOrderSectionProducts;
