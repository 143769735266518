import React from 'react';
import { TableCell, TableRow, Typography, makeStyles } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
    black,
    blackWeight,
    ltBlueGrey_15,
    regularWeight,
    small
} from '../../../../themes/globalConstants';
import Button from '../../../reusable/atoms/Button';
import { OrderProductTableRow } from '../../../../store/reducers/orders-dashboard';
import {
    constructCanDescription,
    constructCanSubDescription,
    constructEndDescription,
    constructEndSubDescription,
    getGraphicIdAndVersion,
    isProductEndType
} from '../../../../utility/helpers/order-helpers';
import CampaignPill from '../../../reusable/atoms/CampaignPill';
import { getPlanItProductStatus } from '../../../../utility/helpers/plan-it-helpers';

interface Props {
    item: OrderProductTableRow;
    onAddItem: (item: OrderProductTableRow, addDisabled: boolean, removeAll: boolean) => void;
    getPaginationRows: () => void;
}

const useStyles = makeStyles((theme) => ({
    tableRowContainer: {
        '&:hover': {
            backgroundColor: ltBlueGrey_15
        },
        '& > td': {
            padding: '1.250em'
        },
        '& .MuiInputBase-input': {
            textAlign: 'right',
            paddingRight: '.25em'
        },
        '& .MuiTableCell-root': {
            '&:not(:last-child)': {
                paddingLeft: '1.125em'
            }
        },
        '& .normal': {
            fontWeight: `${regularWeight} !important`,
            textAlign: 'right'
        }
    },
    addBtn: {
        height: '2.28em',
        width: '3.2em'
    },
    campaignPending: {
        color: black,
        fontStyle: 'italic',
        fontWeight: regularWeight
    },
    snoSkuAttributes: {
        fontSize: small
    },
    productAttributes: {
        '& span': {
            marginRight: '0.5em',
            fontSize: '0.9em'
        }
    },
    productMainAttribute: {
        fontSize: '1em'
    },
    capitalize: {
        textTransform: 'capitalize'
    },
    bold: {
        fontWeight: blackWeight
    },
    centered: {
        textAlign: 'center'
    }
}));

export default function PlanItRow({ item, onAddItem, getPaginationRows }: Props) {
    const classes = useStyles();
    useTranslation();

    const addItem = () => {
        onAddItem(item, true, false);

        //When we add an item, we retrieve selected pagination rows so we can persist them
        getPaginationRows();
    };

    return (
        <TableRow
            className={classes.tableRowContainer}
            data-testid={`product-${item.displayId}`}
            data-can-type={item.type}
        >
            <TableCell data-testid="graphic-version-cell">
                <Typography className={classes.bold}>{item.customerProductId ? item.customerProductId : "-"}</Typography>
                <Typography className={classes.bold}>{item.displayId}</Typography>
                <Typography>
                    {getGraphicIdAndVersion(item.type, item.graphicIdAndVersion)}
                </Typography>
            </TableCell>
            <TableCell data-testid="display-name-cell">
                <Typography>{item.displayName}</Typography>
            </TableCell>
            <TableCell data-testid="description-cell" className={classes.productAttributes}>
                {isProductEndType(item.type) ? (
                    <>
                        <Typography
                            className={clsx(classes.productMainAttribute, {
                                [classes.bold]: constructEndSubDescription(item).length > 0
                            })}
                        >
                            {constructEndDescription(item)}
                        </Typography>
                        <Typography>{constructEndSubDescription(item)}</Typography>
                    </>
                ) : (
                    <>
                        <Typography className={classes.productMainAttribute}>
                            {constructCanDescription(item)}
                        </Typography>
                        <Typography>{constructCanSubDescription(item)}</Typography>
                    </>
                )}
                {item.campaignTiming && <CampaignPill />}
            </TableCell>
            <TableCell data-testid="coating-cell">
                <Typography>{item.coating}</Typography>
            </TableCell>
            <TableCell data-testid="status-cell" className={classes.capitalize}>
                <Typography>{getPlanItProductStatus(item.status)}</Typography>
            </TableCell>
            <TableCell
                className={clsx({
                    [classes.centered]: !item.outsideCampaign
                })}
            >
                {item.outsideCampaign ? (
                    <Typography className={classes.campaignPending}>
                        <Trans i18nKey="campaignPending">Campaign Date Pending</Trans>
                    </Typography>
                ) : (
                    <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        onClick={addItem}
                        className={classes.addBtn}
                        data-testid="addproduct-btn"
                        disabled={!!item.addDisabled}
                    >
                        <Trans i18nKey="add">Add</Trans>
                    </Button>
                )}
            </TableCell>
        </TableRow>
    );
}
