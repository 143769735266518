import React, { useState } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import Link from '../../../reusable/atoms/Link';
import { Trans, useTranslation } from 'react-i18next';
import Modal from '../../../reusable/molecules/Modal';
import { medium, regularWeight, ballBlue } from '../../../../themes/globalConstants';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import { CSVLink } from 'react-csv';
import ReactPDF from '@react-pdf/renderer';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { TabStatus } from './ManageProductsTabs';
import ManageProductsPDF from './ManageProductsPDF';
import { ManageProductsState } from '../../../../store/reducers/manage-products';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { selectIsLargeCustomerAccount } from '../../../../store/selectors';
import { getSnoSkuDescription } from '../../../../utility/helpers/make-it-bulk-helpers';
import { isProductCanType } from '../../../../utility/helpers/order-helpers';
import UnrecognizedProductsPDF from './UnrecognizedProductsPDF';

interface ManageProductsShareLinksProps {
    tabStatus: TabStatus;
    lockPeriodWeeks: number;
}

const useStyles = makeStyles((theme) => ({
    label: {
        textTransform: 'uppercase',
        fontSize: medium,
        paddingTop: '0.5em'
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        marginTop: '0.5em'
    },
    link: {
        fontSize: medium,
        fontWeight: regularWeight,
        width: '8.75em',
        '&:hover': {
            cursor: 'pointer'
        },
        color: ballBlue
    },
    icon: {
        color: ballBlue
    }
}));
const pdfStyles = ReactPDF.StyleSheet.create({
    link: {
        fontSize: medium,
        fontWeight: regularWeight
    }
});

const ManageProductsShareLinks = (props: ManageProductsShareLinksProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { tabStatus, lockPeriodWeeks } = props;
    const [openPdf, setOpenPdf] = useState<boolean>(false);
    const { activeProducts, inactiveProducts, unrecognizedProducts } =
        useTypedSelector<ManageProductsState>((state) => state.manageProductsState);
    const isLargeCustomer = useTypedSelector<boolean>(selectIsLargeCustomerAccount);
    const { selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );

    const printForm = () => {
        setOpenPdf(true);
    };

    const onClose = () => {
        setOpenPdf(false);
    };

    interface CSVRow {
        ship_to_id?: string;
        ball_product_id?: string;
        graphic_id?: string;
        customer_product_id?: string;
        display_name?: string;
        product_description?: string;
        coating?: string;
        lead_time?: string;
        make_it_lock_window?: string;
        status?: string;
    }

    const csvHeaders =
        tabStatus === TabStatus.unrecognized
            ? [
                  { label: t('shipToId', 'Ship To ID').toUpperCase(), key: 'ship_to_id' },
                  {
                      label: t('customerProductId', 'Customer Product ID').toUpperCase(),
                      key: 'customer_product_id'
                  },
                  { label: t('productName', 'Product Name').toUpperCase(), key: 'display_name' },
                  {
                      label: t('productSizeAndShape', 'Product Size/Shape').toUpperCase(),
                      key: 'product_description'
                  }
              ]
            : [
                  { label: t('shipToId', 'Ship To ID').toUpperCase(), key: 'ship_to_id' },
                  {
                      label: t('productId', 'Ball Product ID').toUpperCase(),
                      key: 'ball_product_id'
                  },
                  { label: t('graphicId', 'Graphic ID').toUpperCase(), key: 'graphic_id' },
                  {
                      label: t('customerProductId', 'Customer Product ID').toUpperCase(),
                      key: 'customer_product_id'
                  },
                  { label: t('displayName', 'Display Name').toUpperCase(), key: 'display_name' },
                  {
                      label: t('productDescription', 'Product Description').toUpperCase(),
                      key: 'product_description'
                  },
                  { label: t('coating', 'Coating').toUpperCase(), key: 'coating' },
                  { label: t('leadTime', 'Lead Time').toUpperCase(), key: 'lead_time' },

                  { label: t('status', 'Status').toUpperCase(), key: 'status' }
              ];

    if (isLargeCustomer) {
        const obj1 = {
            label: t('makeItLockWindow', 'Make It Lock Window').toUpperCase(),
            key: 'make_it_lock_window'
        };
        csvHeaders.splice(8, 0, obj1);
    }

    const getProductStatus = (product) => {
        if (!product.statusChanges || product.statusChanges.length === 0) {
            return product.status;
        } else {
            var statuses = product.statusChanges.map((statusChange) =>
                t(statusChange.code, statusChange.description).toUpperCase()
            ) as string[];
            return statuses.join(', ');
        }
    };

    const csvArray = () => {
        let rows: any[] = [];
        let selectedProducts: any[] = [];

        switch (tabStatus) {
            case TabStatus.active:
                selectedProducts = activeProducts ?? [];
                break;
            case TabStatus.inactive:
                selectedProducts = inactiveProducts ?? [];
                break;
            case TabStatus.unrecognized:
                selectedProducts = unrecognizedProducts ?? [];
                break;
        }

        const makeItLock = !!lockPeriodWeeks ? lockPeriodWeeks.toString() + ' Weeks' : 'None';

        tabStatus === TabStatus.unrecognized
            ? selectedProducts.forEach((product) => {
                  const row: CSVRow = {
                      ship_to_id: selectedAccountId,
                      customer_product_id: product.customerProductId,
                      display_name: product.productName,
                      product_description: getSnoSkuDescription(
                          isProductCanType(product.type),
                          product
                      )
                  };
                  rows.push(row);
              })
            : isLargeCustomer
            ? selectedProducts.forEach((product) => {
                  const row: CSVRow = {
                      ship_to_id: selectedAccountId,
                      ball_product_id: product.productSku,
                      graphic_id: product.graphicIdAndVersion ?? product.graphicId,
                      customer_product_id: product.customerProductId,
                      display_name: product.displayName,
                      product_description: product.description,
                      coating: product.coating,
                      lead_time: product.leadTimeString,
                      make_it_lock_window: makeItLock,
                      status: getProductStatus(product)
                  };
                  rows.push(row);
              })
            : selectedProducts.forEach((product) => {
                  const row: CSVRow = {
                      ship_to_id: selectedAccountId,
                      ball_product_id: product.productSku,
                      graphic_id: product.graphicIdAndVersion ?? product.graphicId,
                      customer_product_id: product.customerProductId,
                      display_name: product.displayName,
                      product_description: product.description,
                      coating: product.coating,
                      lead_time: product.leadTimeString,
                      status: getProductStatus(product)
                  };
                  rows.push(row);
              });

        return rows;
    };

    const csvContents = csvArray();

    return (
        <Grid container item xs={12} spacing={2} alignItems="center" justify="flex-end">
            <Grid item>
                <Typography variant="h6" className={classes.label}>
                    <Trans i18nKey="share">share</Trans>
                </Typography>
            </Grid>
            <Grid item className={classes.iconContainer}>
                <Link
                    className={classes.link}
                    underline="always"
                    data-testid="print-btn"
                    onClick={printForm}
                >
                    <Trans i18nKey="print">Print</Trans>
                </Link>
                <PrintOutlinedIcon fontSize="small" className={classes.icon} />
            </Grid>
            <Grid item className={classes.iconContainer}>
                <CSVLink
                    className={
                        'MuiTypography-root MuiLink-root MuiLink-underlineAlways MuiTypography-colorPrimary'
                    }
                    style={pdfStyles.link}
                    underline="always"
                    data-testid="csv-download-btn"
                    headers={csvHeaders}
                    data={csvContents}
                    filename={`products.csv`}
                >
                    <Typography>
                        <Trans i18nKey="downloadCSV">Download .CSV</Trans>
                    </Typography>
                </CSVLink>
                <GetAppOutlinedIcon fontSize="small" className={classes.icon} />
            </Grid>
            <Modal
                open={openPdf}
                title={t('productInformation', 'Product Information')}
                close={onClose}
                closeIcon={true}
                fullScreen={true}
            >
                {tabStatus === TabStatus.unrecognized ? (
                    <UnrecognizedProductsPDF
                        title={t('unrecognizedProducts', 'Unrecognized Products')}
                        content={csvContents}
                    />
                ) : (
                    <ManageProductsPDF
                        title={t('productInformation', 'Product Information')}
                        content={csvContents}
                        tabStat={tabStatus}
                        lockPeriodWeeks={lockPeriodWeeks}
                    />
                )}
            </Modal>
        </Grid>
    );
};

export default ManageProductsShareLinks;
