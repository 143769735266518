import Axios, { AxiosRequestConfig } from 'axios';

interface CacheEntry {
    timeStamp: number;
    response: any;
}

const cacheSeconds = 10;
const requestUrlBlacklistRegex = /^.{0}$/; // No URL pattern currently blacklisted
const shouldLogSuppressedCalls: boolean =
    process.env.REACT_APP_CLIENT_NETWORK_CACHING_LOGS?.toString() === 'true';
let totalKilobytesReduced: number = 0;
let totalPreventedCalls: number = 0;
const cache: Map<string, CacheEntry> = new Map();
const pendingDuplicateRequests: Map<string, Function[]> = new Map();

// Interceptors /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const handleRequest = (request) => {
    const cacheKey = getCacheKey(request);

    // Use cached value for recently made requests instead of requesting again
    if (cache.has(cacheKey) && shouldSuppressRequest(request)) {
        const cacheEntry = cache.get(cacheKey);
        const cacheEntryTimeStamp = cacheEntry?.timeStamp ?? Number.MAX_VALUE;
        const lastValidTimestamp = getLastValidTimestamp();
        if (cacheEntryTimeStamp > lastValidTimestamp) {
            const cachedResponse = cacheEntry?.response ?? {};
            cachedResponse.headers.cached = 'client cache';

            logSuppressedCall(cacheKey, request, 'cache');

            // Reject this request so the cached value can be returned in the error handler
            return Promise.reject(cachedResponse);
        } else {
            cache.delete(cacheKey);
        }
    }

    // Prevent requests that are already pending from being requested again
    if (shouldSuppressRequest(request) && pendingDuplicateRequests.has(cacheKey)) {
        logSuppressedCall(cacheKey, request, 'duplicate');

        // Reject this request so the response can be resolved in the error handler
        return Promise.reject(request);
    }

    // Set a value for pendingDuplicateRequests so that subsequent requests for the same URL won't be repeated until this one is resolved
    if (shouldSuppressRequest(request)) pendingDuplicateRequests.set(cacheKey, []);

    return request;
};

const handleResponse = (response) => {
    const cacheKey = getCacheKey(response.config);

    // Add cache entry for response if valid
    if (responseIsValidForCaching(response)) {
        const newCacheEntry: CacheEntry = {
            timeStamp: new Date().getTime(),
            response
        };
        cache.set(cacheKey, newCacheEntry);
    }

    // Resolve any pending responses associated with this URL
    pendingDuplicateRequests.get(cacheKey)?.forEach((resolve) => {
        resolve(response);
    });
    pendingDuplicateRequests.delete(cacheKey);

    return response;
};

// Cached or duplicate requests are sent to the error handler
// ...this prevents the request from getting sent over the network
// ...and allows the requests to be resolved with local data
const handleError = async (error) => {
    const cacheKey = getCacheKey(error);

    // Return cached value instead of treating as error
    if (error.headers?.cached === 'client cache') {
        logKilobytesSaved(error);
        return Promise.resolve(error);
    }

    // Wait for pending duplicate requests to resolve, then return response
    if (pendingDuplicateRequests.has(cacheKey)) {
        const response: any = await new Promise((resolve) => {
            pendingDuplicateRequests.get(cacheKey)?.push(resolve);
        });

        logKilobytesSaved(response);
        return Promise.resolve(response);
    }

    // If a cache key can't be resolved for the error, all pending requests need to be cleared to ensure subsequent requests aren't suppressed...
    // ...this will occur when normal Axios errors happen, rather than requests being sent to the error handler for suppression.
    if (cacheKey == '{}') pendingDuplicateRequests.clear();

    // All other errors are rejected as normal errors.
    return Promise.reject(error);
};

const AddInterceptorsToAxios = () => {
    Axios.interceptors.request.use(handleRequest);
    Axios.interceptors.response.use(handleResponse, handleError);
};

// Helpers //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const getLastValidTimestamp = (): number => {
    const currentTimestamp = new Date().getTime();
    return currentTimestamp - cacheSeconds * 1000;
};

const isInMethodTypes = (request: AxiosRequestConfig, methodTypes: string[]): boolean => {
    return methodTypes.some(
        (methodType) => request.method?.toUpperCase() === methodType.toUpperCase()
    );
};

const getCacheKey = (request: AxiosRequestConfig): string => {
    let key: any = { url: request.url };
    if (isInMethodTypes(request, ['POST'])) {
        const data = typeof request.data === 'string' ? request.data : JSON.stringify(request.data);
        key = { ...key, data };
    }

    return JSON.stringify(key);
};

const shouldSuppressRequest = (request: AxiosRequestConfig): boolean => {
    const cacheControlHeader = request.headers['Cache-Control'] ?? '';
    const cachingDisabled = cacheControlHeader.includes('no-client-cache');
    const acceptableMethodType = isInMethodTypes(request, ['GET', 'POST']);
    const urlIsBlacklistedForCaching = request.url?.match(requestUrlBlacklistRegex);

    return acceptableMethodType && !cachingDisabled && !urlIsBlacklistedForCaching;
};

const responseIsValidForCaching = (response: any): boolean => {
    const hasValidStatusCode = (response.status ?? 0) === 200;
    return hasValidStatusCode;
};

const logKilobytesSaved = (response: any) => {
    if (!shouldLogSuppressedCalls) return;

    const responseData = response['data'] ?? {};
    const totalBytes = JSON.stringify(responseData).length;

    totalKilobytesReduced += totalBytes / 1024;
    totalPreventedCalls++;
    console.debug(
        `KB saved after preventing ${totalPreventedCalls} calls: \n${totalKilobytesReduced}`
    );
};

const logSuppressedCall = (
    key: string,
    request: AxiosRequestConfig,
    reasonCode: 'cache' | 'duplicate'
) => {
    const reason =
        reasonCode === 'cache'
            ? `cached in last ${cacheSeconds} seconds`
            : 'a duplicate call was already pending';

    if (shouldLogSuppressedCalls) {
        console.debug({
            suppressedNetworkRequest: request,
            key: JSON.parse(key),
            reason
        });
    }
};

export default AddInterceptorsToAxios;
