import { createMuiTheme } from '@material-ui/core/styles';
import {
    ltBlueGrey_34,
    visitedLink,
    ballLtGray_15,
    dateOptionInvalidGray,
    ballDrkBlue,
    infoMain
} from './globalConstants';

// Colors
const ballBlue = '#009BBC';
const ballTeal = '#126573';
const ballLtGray = '#D9D9D6';
const ballGray = '#75787B';
const ballDrkGray = '#363B3E';
const ballBlack = '#202020';
const white = '#FFFFFF';
const error = '#F44336';

// Font Family
// Define primary and backup fonts
const fonts = "'Lato', 'Helvetica', 'Arial', sans-serif";

// Font Weights
const regular = 400;
const bold = 700;
const black = 900;

// Letting spacing
const header = 5;

const rootTheme = createMuiTheme({
    palette: {
        primary: {
            //light: calculated from palette.primary.main,
            main: ballBlue,
            dark: ballTeal
            //contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            light: ballLtGray,
            main: ballGray
        },
        info: {
            light: ltBlueGrey_34,
            main: infoMain,
            dark: ballBlack
        },
        error: {
            main: error
        }
    },
    typography: {
        fontFamily: fonts,
        //Banner Header
        h1: {
            fontSize: 40,
            fontWeight: black,
            letterSpacing: header,
            textTransform: 'uppercase'
        },
        // Secondary Banner Header
        h2: {
            fontSize: 30,
            fontWeight: black,
            letterSpacing: header,
            textTransform: 'uppercase'
        },
        h3: {
            fontSize: 25,
            fontWeight: regular
        },
        h4: {
            fontSize: 20,
            fontWeight: regular
        },
        // Content Box Headers
        h5: {
            fontSize: 14,
            fontWeight: bold
        },
        //Header: Name
        h6: {
            fontSize: 11,
            fontWeight: bold,
            color: ballGray,
            letterSpacing: 0.86
        },
        //Banner Subtitle
        subtitle1: {
            fontSize: 14,
            letterSpacing: 0.5
        },
        subtitle2: {
            fontSize: 12,
            fontWeight: black,
            textTransform: 'uppercase'
        },
        body1: {
            fontSize: 14,
            fontWeight: regular
        },
        body2: {
            fontSize: 14,
            fontWeight: bold,
            letterSpacing: 0.07
        },
        button: {
            fontSize: 12,
            fontWeight: black
        }
    },
    //Default: 8px scaling factor, can be changed if needed
    //Usage: 'theme.spacing(2)' = '8 * 2' or 16px spacing
    //Example: 'padding: theme.spacing(1, 2)' = '8px 16px' padding
    //theme.spacing() can take up to 4 arguments
    spacing: 8,
    props: {
        MuiButtonBase: {
            disableRipple: true
        },
        MuiButton: {
            disableElevation: true
        },
        MuiList: {
            disablePadding: true,
            dense: true
        },
        MuiListItem: {
            dense: true
        },
        MuiInputLabel: {
            variant: 'filled'
        },
        MuiTextField: {
            variant: 'filled'
        },
        MuiSelect: {
            variant: 'filled'
        },
        MuiRadio: {
            color: 'primary'
        }
    },
    overrides: {
        MuiButton: {
            text: {
                fontFamily: fonts,
                fontWeight: black,
                textTransform: 'uppercase'
            },
            //Style Guide: PRIMARY
            //Styles applied if variant=contained and color=primary
            containedPrimary: {
                borderRadius: 0,
                backgroundColor: ballBlue,
                color: white,
                height: 40,
                textTransform: 'uppercase',
                '&:hover': {
                    backgroundColor: ballDrkBlue
                },
                '&:disabled': {
                    backgroundColor: dateOptionInvalidGray,
                    color: white
                }
            },
            //Styles applied if variant=contained and color=secondary
            containedSecondary: {
                backgroundColor: '#000',
                color: white,
                height: 40,
                borderRadius: 0,
                '&:disabled': {
                    backgroundColor: dateOptionInvalidGray,
                    color: white
                }
            },
            //Styles applied if variant=outline, default
            outlined: {
                borderRadius: 0,
                backgroundColor: white,
                color: ballBlack,
                height: 40,
                textTransform: 'uppercase',
                '&:hover': {
                    backgroundColor: ballBlue,
                    backgroundPosition: 'left bottom',
                    color: white
                }
            },
            //Style Guide: TERTIARY
            //Styles applied if variant=outlined and color=primary
            outlinedPrimary: {
                borderRadius: 0,
                height: 40,
                '&:hover': {
                    backgroundColor: ballBlue,
                    backgroundPosition: 'left bottom',
                    color: white
                },
                '&:disabled': {
                    border: `1px solid ${dateOptionInvalidGray}`,
                    color: dateOptionInvalidGray
                }
            },
            //Style Guide: SECONDARY / 'CANCEL'
            //Styles applied if variant=outlined and color=secondary
            outlinedSecondary: {
                borderRadius: 0,
                height: 40,
                border: `1px solid #000`,
                color: '#000',
                '&:hover': {
                    border: '1px solid white',
                    backgroundPosition: 'left bottom',
                    color: white,
                    backgroundColor: '#000'
                },
                '&:disabled': {
                    border: `1px solid ${dateOptionInvalidGray}`,
                    color: dateOptionInvalidGray
                }
            },
            //Style Guide: DESTRUCTIVE / 'Delete'
            //Styles applied if size=small and variant=outlined
            outlinedSizeSmall: {
                color: error,
                height: 40,
                borderRadius: 0,
                border: `1px solid ${error}`,
                '&:hover': {
                    color: white,
                    backgroundColor: error
                }
            },
            //Styles applied if variant=text and color=primary
            textPrimary: {
                color: ballBlue,
                textTransform: 'capitalize',
                fontWeight: black,
                height: 40,
                textDecoration: 'none',
                '&:hover': {
                    color: visitedLink,
                    textDecoration: 'underline',
                    outline: 'none',
                    backgroundColor: 'transparent'
                },
                '&:focused': {
                    color: visitedLink
                }
            }
        },
        MuiButtonGroup: {
            //Styles applied if ButtonGroup variant="text"
            //if color=primary and variant=text, textPrimary styles will also apply
            groupedTextHorizontal: {
                padding: 0,
                margin: 0,
                border: 0,
                '&:not(:last-child)': {
                    borderRight: 0
                },
                '&:active': {
                    color: visitedLink,
                    textDecoration: 'underline'
                }
            }
        },
        MuiLink: {
            //Ball Blue link with no underline
            underlineNone: {
                color: ballBlue,
                fontFamily: fonts,
                fontWeight: bold,
                letterSpacing: 0.36,
                underline: 'none'
            },
            //Link with underline hover animation
            underlineHover: {
                color: ballDrkGray,
                fontFamily: fonts,
                fontWeight: bold,
                letterSpacing: 0.36,
                underline: 'none',
                paddingBottom: '0.625em'
            },
            //Ball Blue link with underline and purple on visited
            underlineAlways: {
                color: ballBlue,
                fontFamily: fonts,
                fontWeight: bold,
                letterSpacing: 0.94,
                underline: 'always',
                '&:hover': {
                    color: visitedLink
                },
                '&:visited': {
                    color: visitedLink
                },
                '&:active': {
                    color: visitedLink
                }
            }
        },
        MuiList: {
            dense: {
                padding: 0,
                margin: 0,
                fontSize: 12
            }
        },
        MuiListItemIcon: {
            root: {
                color: ballBlue,
                minWidth: 0,
                marginRight: 5,
                fontSize: 16
            }
        },
        MuiTable: {
            root: {
                border: 'none',
                borderCollapse: 'collapse'
            }
        },
        MuiTableHead: {
            root: {
                borderBottom: '5px solid',
                borderBottomColor: ltBlueGrey_34
            }
        },
        MuiTableCell: {
            root: {
                borderLeft: '1px solid',
                borderLeftColor: ballLtGray,
                borderBottom: '1px solid',
                borderBottomColor: ballLtGray,
                '&:first-child': {
                    borderLeft: 'none'
                },
                padding: '10px'
            }
        },
        MuiFilledInput: {
            root: {
                backgroundColor: ballLtGray_15,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                '&$disabled': {
                    backgroundColor: ballLtGray_15,
                    borderBottomStyle: 'none'
                }
            }
        },
        MuiFormLabel: {
            root: {
                color: ballDrkGray
            }
        },
        MuiSelect: {
            icon: {
                color: ballBlue
            }
        }
    }
});

export default rootTheme;
