import {
    Activity,
    filterShipToIdsByPermission,
    SecurityLevel
} from '../../utility/auth/useSecurity';
import moment from 'moment';
import OrdersService, { ScrapItRequestResponse } from '../../utility/services/orders-service';
import { ScrapItRequest, ScrapItSubmissionProduct } from '../reducers/scrap-it';
import { ProductToShip } from '../reducers/shipping-dashboard';
import * as types from './action-types';
import { shippingDashboardUpdatePallets } from './shipping-dashboard';

export const resetScrapItProductState = () => {
    return (dispatch) => {
        dispatch({
            type: types.SCRAP_IT_RESET_PRODUCT_STATE,
            products: [],
            productsWithPallets: [],
            productsToBeScrapped: [],
            deliveryOrder: {}
        });
    };
};

export const submitScrapIt = (shipToId: string) => {
    return (dispatch, getState) => {
        dispatch({ type: types.SCRAP_IT_SUBMIT_LOADING });
        const state = getState();
        const accessToken = state.auth.accessToken;
        const username = state.auth.userInfo.preferred_username;
        const productsToScrap = state.scrapItState.productsToBeScrapped as ProductToShip[];
        let requestLines: ScrapItSubmissionProduct[] = [];

        productsToScrap.forEach((product) => {
            requestLines.push({
                productSku: product.productSku!,
                requestedQuantity: product.inputPallets!,
                quantityUnits: 'PALLETS',
                status: 'SUBMIT',
                approvedAmount: product.inputPallets!
            });
        });

        const payload = {
            accountId: shipToId,
            requestedBy: username,
            requestLines: requestLines
        };

        OrdersService.submitScrapIt(accessToken, payload)
            .then((response) => {
                const res = response.data;
                let lines = productsToScrap.map((product) => {
                    const line = res.requestLines.filter(
                        (line) => line.productSku === product.productSku
                    );
                    if (line.length > 0) {
                        return { ...product, ...line[0] };
                    }
                    return undefined;
                });
                let scrapItConfirmation = { ...res, requestLines: lines };
                dispatch({
                    type: types.SCRAP_IT_SUBMIT_SUCCESS,
                    scrapItConfirmation: scrapItConfirmation as ScrapItRequestResponse
                });
            })
            .catch((error) => {
                dispatch({ type: types.SCRAP_IT_SUBMIT_ERROR, error: error });
            });
    };
};

export const getAgedProducts = (accounts: number[]) => {
    return (dispatch, getState) => {
        const state = getState();
        const payload = {
            shipToIds: filterShipToIdsByPermission(
                state.auth,
                accounts,
                Activity.ScrapIt,
                SecurityLevel.View
            )
        };
        const products = state.scrapItState.products;

        OrdersService.getAgedProducts(state, payload)
            .then((response) => {
                let productSkus = response.data.productSkus;
                if (products && products.length > 0 && productSkus) {
                    let productsWithAgedQuantities = products.map((product) => {
                        const matchingProduct = productSkus.find(
                            (item) => item.productSku === product.productSku
                        );
                        return {
                            ...product,
                            agedPalletQuantity: matchingProduct?.skuAgedQuantity ?? 0
                        };
                    });
                    dispatch(shippingDashboardUpdatePallets(productsWithAgedQuantities, true));
                }

                dispatch({
                    type: types.SCRAP_IT_LOAD_AGED_PRODUCTS,
                    agedProducts: productSkus
                });
            })
            .catch((error) => {
                dispatch({ type: types.SCRAP_IT_SUBMIT_ERROR, error: error });
            });
    };
};

export const loadScrapItRequests = (shipToId: number) => {
    return (dispatch, getState) => {
        const state = getState();
        const { accessToken } = state.auth;
        const region = state.regionCulture.regionCode;
        const culture = state.regionCulture.cultureCode;
        OrdersService.getScrapItRequests(accessToken, [shipToId], culture, region)
            .then((response) => {
                let scrapItRequests: ScrapItRequest[] = [];
                response.data.forEach((item) => {
                    let request: ScrapItRequest = {
                        requestOrderNumber: item.requestOrderNumber.toString(),
                        requestedQuantity: item.requestedQuantity.toString(),
                        submittedDate: moment.utc(item.submittedDate).format('MM/DD/YYYY'),
                        status: 'Submitted',
                        quantityEaches: item.quantityEaches.toString()
                    };
                    scrapItRequests.push(request);
                });
                dispatch({ type: types.SCRAP_IT_SUMMARY_LOADED, scrapItRequests: scrapItRequests });
            })
            .catch((error) => {
                dispatch({ type: types.SCRAP_IT_SUMMARY_ERROR, error });
            });
    };
};
