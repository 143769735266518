import React, { useEffect, useState } from 'react';
import {
    ballBlue,
    blackWeight,
    boldWeight,
    containerMaxWidth,
    dateOptionInvalidGray
} from '../../../../themes/globalConstants';
import { Grid, makeStyles } from '@material-ui/core';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { generateKey } from '../../../../utility/helpers/order-helpers';
import ProductInformationColumns from './ProductInformationColumns';
import ProductInformationGridRow from './ProductInformationGridRow';
import { paginationOptions } from '../../../../utility/helpers/grid-helpers';
import { ProductInformationRow } from '../models/product-information-row.interface';
import ProductInformationToolbar from './ProductInformationToolbar';

interface Props {
    data: Array<ProductInformationRow>;
    lockPeriodWeeks: number;
    fromMakeIt: boolean;
    fromPlanIt: boolean;
    onViewFiltersClicked: (viewTypes: string[]) => void;
}

const useStyles = makeStyles((theme) => ({
    action: {
        cursor: 'pointer'
    },
    tableWrapper: {
        // marginTop: '3em',
        padding: '2em',
        borderRadius: 4,
        maxWidth: containerMaxWidth,
        '& .MuiToolbar-root': {
            padding: 0,
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                },
                '&:first-of-type ': {
                    paddingLeft: 0
                }
            },
            '& > h2': {
                fontSize: '1.5em',
                fontWeight: boldWeight,
                color: theme.palette.secondary.main,
                paddingLeft: '0.625em',
                fontFamily: 'Lato',
                minWidth: '12em'
            }
        },
        '& .MuiInputBase-formControl': {
            border: `1px solid ${dateOptionInvalidGray}`,
            borderRadius: '.125em',
            '&:before': {
                display: 'none'
            },
            '&:after': {
                display: 'none'
            },
            '& svg': {
                height: '.875em',
                width: '.875em',
                fill: ballBlue
            },
            '& .MuiIconButton-root': {
                '&:hover': {
                    backgroundColor: 'transparent !important'
                }
            },
            '& .MuiInput-input': {
                paddingLeft: '.25em'
            }
        },
        '& .MuiTableSortLabel-root': {
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em',
            width: '99%',
            justifyContent: 'space-between',
            '& .MuiSvgIcon-root': {
                fontSize: 14,
                opacity: '1'
            }
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                width: '20%',
                '&:nth-child(1)': {
                    width: '14%'
                },
                '&:nth-child(2)': {
                    width: '14%'
                },
                '&:nth-child(3)': {
                    width: '24%'
                },
                '&:nth-child(4)': {
                    width: '14%'
                },
                '&:nth-child(5)': {
                    width: '10%'
                },
                '&:nth-child(6)': {
                    width: '10%'
                },
                '&:nth-child(7)': {
                    width: '14%'
                }
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    }
}));

const ProductInformationGrid = ({
    data,
    lockPeriodWeeks,
    fromMakeIt,
    fromPlanIt,
    onViewFiltersClicked
}: Props) => {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [columns, setColumns] = useState<any>([]);

    const options = new ToolbarOptions({
        customItems: <ProductInformationToolbar onViewFiltersClicked={onViewFiltersClicked} />,
        exportButton: false,
        printButton: false,
        advancePagination: false,
        rowsPerPageOptions: paginationOptions.rowsPerPage
    });

    const ForecastRow = ({ row }: { row: ProductInformationRow }) => {
        return (
            <ProductInformationGridRow
                item={row}
                key={row.productId}
                lockWindowWeeks={lockPeriodWeeks}
                fromMakeIt={fromMakeIt}
                fromPlanIt={fromPlanIt}
            />
        );
    };

    useEffect(() => {
        setColumns(
            ProductInformationColumns.getProductInformationColumns(
                cultureCode,
                fromMakeIt,
                fromPlanIt
            )
        );
    }, [cultureCode, fromMakeIt, fromPlanIt]);

    return (
        <Grid className={classes.tableWrapper} key={generateKey('atm-product-information')}>
            <DataGrid
                gridName="Product Information"
                columns={columns}
                dataSource={data}
                rowComponent={ForecastRow}
                toolbarOptions={options}
            />
        </Grid>
    );
};

export default ProductInformationGrid;
