import React, { ReactNode, useState } from 'react';
import Link from './Link';
import { makeStyles } from '@material-ui/core';
import Modal from '../molecules/Modal';
import ContactForm from '../molecules/ContactForm';
import { useTranslation, Trans } from 'react-i18next';

interface Props {
    children: ReactNode;
    messageType: string;
    className?: string;
}

const useStyles = makeStyles({
    modal: {
        '& .MuiPaper-root': {
            maxWidth: '40.5em'
        },
        '& .MuiDialog-paperScrollPaper': {
            borderRadius: 'unset',
            overflow: 'hidden'
        },
        '& .MuiDialogTitle-root': {
            justifyContent: 'center',
            marginLeft: '2.5em'
        }
    }
});

const MailLink = (props: Props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const closeContactForm = () => {
        setOpen(false);
    };

    const openContactForm = () => {
        setOpen(true);
    };
    useTranslation();

    return (
        <>
            <Modal
                open={open}
                close={closeContactForm}
                title={<Trans i18nKey="howCanWeHelp">How Can We Help?</Trans>}
                closeIcon={true}
                fullWidth={true}
                className={classes.modal}
            >
                {<ContactForm messageType={props.messageType} onClose={closeContactForm} />}
            </Modal>
            <Link
                data-testid="contact"
                underline="none"
                className={props.className}
                onClick={openContactForm}
            >
                {props.children}
            </Link>
        </>
    );
};

export default MailLink;
