import React, { ReactNode, useEffect, useState } from 'react';
import { makeStyles, Grid, Typography, IconButton, Link } from '@material-ui/core';
import { black, boldWeight, shipWarningBlue, ballBlue } from '../../../../themes/globalConstants';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import { AccountNotification } from '../../../../store/reducers/notifications';
import { Trans, useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { stringFormatterReplace } from '../../../../utility/helpers/formatters';
import { Link as RouterLink } from 'react-router-dom';
import {
    formatNotificationContent,
    notificationActionToPage,
    notificationActionToTranslationKey
} from '../../../../utility/helpers/notification-helpers';

interface Props {
    notification: AccountNotification;
    warningMessage: ReactNode;
    children?: ReactNode;
    dismiss: (notificationId: string) => void;
}

const useStyles = makeStyles((theme) => ({
    warningContainer: {
        marginTop: '1em',
        width: '100%',
        '& .MuiAlert-icon': {
            color: '#009BBB',
            display: 'flex',
            alignItems: 'center'
        },
        '& .MuiAlert-message': {
            flexGrow: '100',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& .MuiTypography-root': {
                color: black,
                fontWeight: boldWeight,
                display: 'inline'
            },
            '& .MuiLink-root': {
                textDecoration: 'underline',
                color: ballBlue,
                '&:hover': {
                    textDecoration: 'underline'
                }
            }
        },
        backgroundColor: shipWarningBlue
    },
    btn: {
        color: theme.palette.info.dark
    }
}));

export default function DashboardNotification({
    notification,
    children,
    warningMessage,
    dismiss
}: Props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const regionCulture = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [notificationMessage, setNotificationMessage] = useState('');
    const warningIcon = (
        <img src={process.env.PUBLIC_URL + '/assets/InfoTriangle_Icon.svg'} alt="Warning Icon" />
    );

    const handleClick = () => {
        dismiss(notification.notificationId);
    };

    useEffect(() => {
        if (notification && notification.type && notification.content) {
            setNotificationMessage(
                stringFormatterReplace(
                    t(
                        notification.type.toUpperCase(),
                        'Ordering for your {0} products will begin on {1}.  Orders must be placed by {2} and the first available production date is {3}.'
                    ),
                    ...formatNotificationContent(notification.type, notification.content)
                )
            );
        }
    }, [notification.content, regionCulture]);

    return (
        <>
            <Grid container item xs={12}>
                <Alert
                    icon={warningIcon}
                    severity="warning"
                    className={classes.warningContainer}
                    data-testid="dashboard-notification"
                >
                    <Typography>
                        {notificationMessage}
                        {notification.type && (
                            <Link
                                component={RouterLink}
                                to={notificationActionToPage(notification.type)}
                            >
                                <Trans
                                    i18nKey={notificationActionToTranslationKey(notification.type)}
                                ></Trans>
                            </Link>
                        )}
                    </Typography>
                    <IconButton
                        className={classes.btn}
                        onClick={handleClick}
                        data-testid="modal-close-btn"
                    >
                        <CloseIcon />
                    </IconButton>
                </Alert>
            </Grid>
        </>
    );
}
