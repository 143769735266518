import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class MakeItOpenColumns {
    static getMakeItOpenColumns = (
        culture: string,
        showActionsColumn: boolean,
        showCustomerPOCol: boolean
    ) => {
        return [
            createColumn('productionOrderId', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('shipToName', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'shipTo'),
                sortable: true,
                filterable: true,
                searchable: true
            }),
            createColumn('customerProductionOrderId', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'customerPoNumber'),
                filterable: true,
                sortable: true,
                searchable: true,
                visible: showCustomerPOCol
            }),
            createColumn('ballPONumber', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'ballProdOrderNumber'),
                filterable: true,
                sortable: true
            }),
            createColumn('searchablePONumber', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            }),
            createColumn('productionOrderNumber', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            }),
            createColumn('submittedDate', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'submitted'),
                sortable: true,
                filterable: true
            }),
            createColumn('firstRequestedDate', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'firstRequestedDate'),
                sortable: true,
                filterable: true
            }),
            createColumn('submittedDateString', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            }),
            createColumn('firstRequestedDateString', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            }),
            createColumn('requiredShipDateString', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'requiredShipDate'),
                filterable: true,
                sortable: true,
                searchable: false
            }),
            createColumn('availableQuantityString', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: false
            }),
            createColumn('availableEachesString', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: false
            }),
            createColumn('totalOrderQuantity', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'totalOrderQuantity'),
                filterable: true,
                sortable: true,
                searchable: false
            }),
            createColumn('orderQuantityEachesString', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            }),
            createColumn('status', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'status'),
                sortable: true,
                searchable: true
            }),
            createColumn('actions', {
                label: TranslationService.getTranslatedText(culture, 'actions'),
                visible: showActionsColumn
            }),
            createColumn('totalOrderSKUs', {
                dataType: ColumnDataType.Numeric,
                visible: false,
                searchable: false
            }),
            createColumn('shipToId', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('shipToAddress', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            }),
            createColumn('orderQuantityString', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            }),
            createColumn('isLocked', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: false
            }),
            createColumn('shipToProdOrderType', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: false
            }),
            createColumn('totalFees', {
                dataType: ColumnDataType.Numeric,
                visible: false,
                searchable: false
            }),
            createColumn('searchTerms', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            })
        ];
    };
}
