import { Grid, makeStyles, MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { boldWeight } from '../../../themes/globalConstants';
import Link from '../atoms/Link';
import { Region } from '../../../store/reducers/graphic-intake';
import NavBarMenu, { MenuSection } from './NavBarMenu';
import NavBarTile from './NavBarTile';

interface Props {
    baseContentLink: string;
    hideKBMaxFunctionality: boolean;
}

const useStyles = makeStyles((theme) => ({
    menuItems: {
        overflow: 'visible',
        width: 'fit-content',
        lineHeight: '0.9'
    },
    linkMenuItem: {
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    link: {
        fontSize: 11,
        fontWeight: boldWeight,
        color: theme.palette.secondary.main,
        letterSpacing: '1px',
        textTransform: 'uppercase'
    },
    sustainabilityIcon: {
        marginTop: '15%',
        marginBottom: '0.625em',
        maxHeight: '4em'
    },
    fillingIcon: {
        marginTop: '15%',
        marginBottom: '0.625em',
        maxHeight: '3em'
    }
}));
const DiscoverBallNav = ({ baseContentLink, hideKBMaxFunctionality }: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { regionCode, cultureCode } = useTypedSelector<RegionCultureState>(
        (state) => state.regionCulture
    );
    const graphicsLink = baseContentLink + 'Graphics';
    const graphicsIcon = '/Graphics_Nav_Icon.svg';
    const fillingLink = baseContentLink + 'Filling';
    const fillingIcon = '/Filling_Nav_Icon.svg';
    const labLink = baseContentLink + 'Lab-Liquid-Testing';
    const labIcon = '/Liquid_Test_Nav_Icon.svg';
    const sustainabilityLink = baseContentLink + 'Sustainability';
    const sustainabilityIcon = '/Sustainability_Nav_Icon.svg';
    const handlingLink = baseContentLink + 'Handling';
    const handlingIcon = '/Handling_Nav_Icon.svg';
    const regulatoryLink = baseContentLink + 'Regulations';
    const regulatoryIcon = 'Regulatory_Nav_Icon.svg';

    const [menuLinks, setMenuLinks] = useState<MenuSection[]>([]);

    useEffect(() => {
        const links: MenuSection[] = [
            {
                key: 'generalInformation',
                name: t('generalInformation', 'General Information'),
                items: []
            }
        ];
        if (regionCode === Region.EU || regionCode === Region.SA) {
            links[0].items!.push(
                <MenuItem
                    key="getAnEstimate"
                    disableGutters={true}
                    className={clsx(classes.linkMenuItem, classes.menuItems)}
                >
                    <Link
                        component={RouterLink}
                        to="product-portfolio"
                        underline="hover"
                        aria-label="get an estimate"
                        className={classes.link}
                    >
                        <Trans i18nKey="getAnEstimate">Get an Estimate</Trans>
                    </Link>
                </MenuItem>
            );
        }

        links[0].items!.push(
            <MenuItem
                key="overview"
                disableGutters={true}
                className={clsx(classes.linkMenuItem, classes.menuItems)}
            >
                <Link
                    component={RouterLink}
                    to="/welcome"
                    underline="hover"
                    aria-label="overview"
                    className={classes.link}
                    data-testid={'link-overview'}
                >
                    <Trans i18nKey="overview">Overview</Trans>
                </Link>
            </MenuItem>,
            <MenuItem
                key="ourProcess"
                disableGutters={true}
                className={clsx(classes.linkMenuItem, classes.menuItems)}
            >
                <Link
                    component={RouterLink}
                    to="/content/NA/Pages/Our-Process"
                    underline="hover"
                    aria-label="our process"
                    className={classes.link}
                >
                    <Trans i18nKey="theProcess">The process</Trans>
                </Link>
            </MenuItem>
        );
        links[0].items!.push(
            <MenuItem
                key="howToGuides"
                disableGutters={true}
                className={clsx(classes.linkMenuItem, classes.menuItems)}
            >
                <Link
                    component={RouterLink}
                    to="/content/NA/Pages/Help"
                    underline="hover"
                    aria-label="how to guides"
                    className={classes.link}
                >
                    <Trans i18nKey="guidesLink">How To Guides</Trans>
                </Link>
            </MenuItem>,
            <MenuItem
                key="canSchool"
                disableGutters={true}
                className={clsx(classes.linkMenuItem, classes.menuItems)}
            >
                <Link
                    component={RouterLink}
                    to="/content/NA/Pages/Can-school"
                    underline="hover"
                    aria-label="can school"
                    className={classes.link}
                >
                    <Trans i18nKey="canSchool">Can School</Trans>
                </Link>
            </MenuItem>,
            <MenuItem
                key="faq"
                disableGutters={true}
                className={clsx(classes.linkMenuItem, classes.menuItems)}
            >
                <Link
                    component={RouterLink}
                    to="/content/NA/Pages/FAQ"
                    underline="hover"
                    aria-label="Frequently Asked Questions"
                    className={classes.link}
                >
                    <Trans i18nKey="faq">Frequently Asked Questions</Trans>
                </Link>
            </MenuItem>
        );
        setMenuLinks(links);
        // reload links when language changes
    }, [cultureCode]);

    return (
        <NavBarMenu
            menuName={<Trans i18nKey="discoverBall">Discover Ball</Trans>}
            sections={menuLinks}
        >
            <Grid container>
                <Grid item xs={4}>
                    <NavBarTile
                        link={graphicsLink}
                        translationKey="graphics"
                        defaultText="Graphics"
                        icon={graphicsIcon}
                        isProduct={false}
                        secondaryText="Learn more about color separation, artwork submission, and file preparation."
                        secondaryTranslationKey="learnMoreGraphics"
                    />
                </Grid>
                <Grid item xs={4}>
                    <NavBarTile
                        link={labLink}
                        translationKey="labDashLiquid"
                        defaultText="Lab / Liquid"
                        icon={labIcon}
                        isProduct={false}
                        secondaryText="Why testing your beverage for compatibility is critical for the canning process."
                        secondaryTranslationKey="whyTesting"
                    />
                </Grid>
                <Grid item xs={4}>
                    <NavBarTile
                        link={fillingLink}
                        translationKey="filling"
                        defaultText="Filling"
                        icon={fillingIcon}
                        iconClassName={classes.fillingIcon}
                        isProduct={false}
                        secondaryText="Best practices for canning your liquid."
                        secondaryTranslationKey="bestCanningPractices"
                    />
                </Grid>
                <Grid item xs={4}>
                    <NavBarTile
                        link={handlingLink}
                        translationKey="handling"
                        defaultText="Handling"
                        icon={handlingIcon}
                        isProduct={false}
                        secondaryText="Ensure your team knows how to handle your cans once they've arrived."
                        secondaryTranslationKey="ensureHandling"
                    />
                </Grid>
                <Grid item xs={4}>
                    <NavBarTile
                        link={sustainabilityLink}
                        translationKey="sustainability"
                        defaultText="Sustainability"
                        icon={sustainabilityIcon}
                        iconClassName={classes.sustainabilityIcon}
                        isProduct={false}
                        secondaryText="Helping you promote your brand's effort around sustainability."
                        secondaryTranslationKey="helpPromoteSustainability"
                    />
                </Grid>

                <Grid item xs={4}>
                    <NavBarTile
                        link={regulatoryLink}
                        translationKey="regulatory"
                        defaultText="Regulatory"
                        icon={regulatoryIcon}
                        isProduct={false}
                        secondaryText="Learn about country specific regulations."
                        secondaryTranslationKey="learnAboutRegulations"
                    />
                </Grid>
            </Grid>
        </NavBarMenu>
    );
};

export default DiscoverBallNav;
