import React from 'react';
import ContentTemplate from '../../components/pages/ContentPage/templates/ContentTemplate';
import { ContentSubPage } from '../../store/reducers/content-page';

export const createContentTabs = (contents: ContentSubPage[]) => {
    let tabs: any[] = [];
    contents.forEach((content: ContentSubPage) => {
        let tab = {
            header: content.header,
            content: ( <ContentTemplate sections={content.sections} /> )
        }
        tabs.push(tab);
    });
    return tabs;
}
