import React from 'react';
import { Section } from '../../../store/reducers/glp';
import { Grid, Typography, makeStyles, Hidden } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Link from './Link';
import {
    containerMaxWidth,
    white_75,
    white_5,
    cerulean,
    playground,
    darkGreen,
    atmPurple,
    atmCircle
} from '../../../themes/globalConstants';
import { GetFeatureFlag, FlagTypes } from '../../../utility/helpers/feature-flag';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { selectIsLargeCustomer } from '../../../store/selectors';
import clsx from 'clsx';
import { AuthState } from '../../../store/reducers/auth';
import { hasPersonas, Persona } from '../../../utility/auth/useSecurity';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { Region } from '../../../store/reducers/graphic-intake';

const useStyles = makeStyles((theme) => ({
    banner: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        margin: 0
    },
    atmBanner: {
        backgroundColor: atmPurple,
        color: theme.palette.common.white,
        '& h1': {
            color: theme.palette.common.white
        }
    },
    atmCircle: {
        width: '3.125em',
        height: '3.125em',
        borderRadius: '50%',
        background: atmCircle,
        textAlign: 'center',
        verticalAlign: 'middle',
        lineHeight: '3.125em',
        display: 'block'
    },
    playground: {
        backgroundColor: playground,
        color: theme.palette.primary.contrastText,
        margin: 0
    },
    container: {
        maxWidth: containerMaxWidth,
        paddingLeft: '0.875em',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    imageAnchor: {
        overflow: 'visible',
        direction: 'rtl'
    },
    image: {
        maxHeight: '30em'
    },
    homeButton: {
        marginRight: theme.spacing(1),
        '& button': {
            paddingLeft: 0
        }
    },
    headerContainer: {
        zIndex: 1
    },
    header: {
        marginTop: '1.5em'
    },
    steps: {
        marginTop: '1.25em',
        alignItems: 'baseline'
    },
    description: {
        marginTop: '1.25em'
    },
    step: {
        marginTop: '0.5em',
        textTransform: 'uppercase',
        color: theme.palette.primary.contrastText,
        fontSize: 11,
        fontWeight: 900,
        letterSpacing: '0.89px'
    },
    link: {
        marginTop: '3.375em',
        marginBottom: '3em'
    },
    firstChevron: {
        color: white_5
    },
    secondChevron: {
        color: white_75,
        marginLeft: '-0.65em'
    },
    circle: {
        width: '3.125em',
        height: '3.125em',
        borderRadius: '50%',
        background: cerulean,
        textAlign: 'center',
        verticalAlign: 'middle',
        lineHeight: '3.125em',
        display: 'block'
    },
    playgroundCircle: {
        width: '3.125em',
        height: '3.125em',
        borderRadius: '50%',
        background: darkGreen,
        textAlign: 'center',
        verticalAlign: 'middle',
        lineHeight: '3.125em',
        display: 'block'
    },
    circleText: {
        fontSize: 16,
        letterSpacing: '1px'
    },
    headerLink: {
        color: theme.palette.common.white,
        fontSize: 18,
        '& hr': {
            backgroundColor: theme.palette.common.white
        },
        '&:hover': {
            color: theme.palette.common.white
        }
    }
}));

const Banner = ({ image, description, header, subsections, links }: Section) => {
    const classes = useStyles();
    const pad = (number) => {
        return `0${number}`;
    };
    const { regionCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const isPlayground = GetFeatureFlag(FlagTypes.Playground);
    const isLargeCustomer = useTypedSelector<boolean>(selectIsLargeCustomer);
    const auth = useTypedSelector<AuthState>((state) => state.auth);
    const isCoPacker = hasPersonas([Persona.CoPacker], auth);
    const showATMBanner = !isCoPacker && isLargeCustomer && regionCode !== Region.EU;

    return description ? (
        <section
            aria-label="banner"
            className={clsx(
                isPlayground
                    ? classes.playground
                    : showATMBanner
                    ? classes.atmBanner
                    : classes.banner
            )}
        >
            <Grid container className={classes.container}>
                <Grid item container xs={12} md={8} className={classes.headerContainer}>
                    <Grid item xs={12} md={8}>
                        <Typography
                            variant="h1"
                            aria-label="banner-header"
                            id="banner-header"
                            className={classes.header}
                        >
                            {header}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Typography
                            variant="subtitle1"
                            component="h2"
                            id="banner-description"
                            className={classes.description}
                        >
                            {description}
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} className={classes.steps} spacing={3}>
                        {subsections.map((subsection, index) => (
                            <Grid md={3} sm={6} key={index} item container>
                                <Grid item container xs={12} alignItems="center">
                                    <Grid item xs={6}>
                                        <Typography
                                            variant="subtitle2"
                                            className={classes.circleText}
                                        >
                                            <span
                                                className={clsx(
                                                    isPlayground
                                                        ? classes.playgroundCircle
                                                        : showATMBanner
                                                        ? classes.atmCircle
                                                        : classes.circle
                                                )}
                                            >
                                                {pad(index + 1)}
                                            </span>
                                        </Typography>
                                    </Grid>
                                    {index < subsections.length - 1 && (
                                        <Grid item xs={6}>
                                            <KeyboardArrowRightIcon
                                                className={classes.firstChevron}
                                            />
                                            <KeyboardArrowRightIcon
                                                className={classes.secondChevron}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        component="h1"
                                        className={classes.step}
                                        aria-label="subsection-header"
                                    >
                                        {subsection.header}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item xs={12} className={classes.link}>
                        {links && (
                            <Link
                                underline="hover"
                                to={`/content${links[0].destination?.replace('%', '')}`}
                                data-testid="learn-our-process"
                                component={RouterLink}
                                className={classes.headerLink}
                            >
                                {links[0].linkText}
                            </Link>
                        )}
                    </Grid>
                </Grid>
                <Hidden smDown>
                    <Grid item container xs={4} className={classes.imageAnchor}>
                        <img
                            src={image?.imageUrl}
                            alt={header}
                            data-testid="header-image"
                            className={classes.image}
                        ></img>
                    </Grid>
                </Hidden>
            </Grid>
        </section>
    ) : null;
};

export default Banner;
