import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class MakeItConfirmationColumns {
    static getMakeItConfirmationColumns = (culture: string) => {
        return [
            createColumn('productId', {
                dataType: ColumnDataType.String,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('displayId', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'customerProductGraphicId'),
                filterable: true,
                sortable: true,
                searchable: false
            }),
            createColumn('graphicIdAndVersion', {
                dataType: ColumnDataType.String,
                filterable: true,
                sortable: true,
                searchable: false,
                visible: false
            }),
            createColumn('displayName', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'descriptionCharacteristics'),
                filterable: true,
                sortable: true,
                searchable: false
            }),
            createColumn('productSku', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('name', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('size', {
                dataType: ColumnDataType.Numeric,
                filterable: true,
                sortable: true,
                searchable: false,
                visible: false
            }),
            createColumn('coating', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'coating'),
                filterable: true,
                sortable: true,
                searchable: false
            }),
            createColumn('requestedDate', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'requestedDate'),
                filterable: true,
                sortable: true,
                searchable: false
            }),
            createColumn('quantityPerPallet', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('palletQuantity', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'quantityOrdered'),
                filterable: true,
                sortable: true,
                searchable: false
            }),
            createColumn('type', {
                dataType: ColumnDataType.String,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('unit', {
                dataType: ColumnDataType.String,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('shape', {
                dataType: ColumnDataType.String,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('neckDiameter', {
                dataType: ColumnDataType.String,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('endProfile', {
                dataType: ColumnDataType.String,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('tabColor', {
                dataType: ColumnDataType.String,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('customerProductId', {
                dataType: ColumnDataType.String,
                filterable: false,
                sortable: true,
                searchable: true,
                visible: false
            }),
            createColumn('customerProductName', {
                dataType: ColumnDataType.String,
                filterable: false,
                sortable: true,
                searchable: true,
                visible: false
            })
        ];
    };
}
