import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class MakeItClosedColumns {
    static getMakeItClosedColumns = (culture: string, showCustomerPOCol: boolean) => {
        return [
            createColumn('productionOrderId', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('shipToName', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'shipTo'),
                sortable: true,
                filterable: true,
                searchable: true
            }),

            createColumn('customerProductionOrderId', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'customerPoNumber'),
                filterable: true,
                sortable: true,
                searchable: true,
                visible: showCustomerPOCol
            }),
            createColumn('ballPONumber', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'ballProdOrderNumber'),
                filterable: true,
                sortable: true
            }),
            createColumn('productionOrderNumber', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            }),
            createColumn('submittedDate', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'submitted'),
                filterable: true,
                sortable: true
            }),
            createColumn('submittedDateString', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            }),
            createColumn('firstRequestedDate', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'firstRequestedDate'),
                filterable: true,
                sortable: true
            }),
            createColumn('firstRequestedDateString', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            }),
            createColumn('totalOrderQuantity', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'totalOrderQuantity'),
                filterable: true,
                sortable: true,
                searchable: false
            }),
            createColumn('status', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'status'),
                sortable: true,
                searchable: true
            }),
            createColumn('closedSortIndex', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'closed'),
                sortable: true,
                filterable: true
            }),
            createColumn('closed', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('closedString', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            }),
            createColumn('totalOrderSKUs', {
                dataType: ColumnDataType.Numeric,
                visible: false,
                searchable: false
            }),
            createColumn('shipToId', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('shipToAddress', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            }),
            createColumn('originalProductionOrderNumber', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: false
            }),
            createColumn('orderQuantityString', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            }),
            createColumn('orderQuantityEachesString', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            }),
            createColumn('newProductionOrderNumber', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            }),
            createColumn('newProductionOrderId', {
                dataType: ColumnDataType.Numeric,
                visible: false,
                searchable: false
            }),
            createColumn('shipToProdOrderType', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: false
            }),
            createColumn('searchTerms', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            })
        ];
    };
}
