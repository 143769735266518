import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { OnboardingDashboardState } from '../../../../../store/reducers/onboarding-dashboard';
import { useTypedSelector } from '../../../../../store/reducers/reducer';
import { OnboardingStepState } from '../../../../../utility/services/onboarding-service';
import { enUS } from '../../../../../utility/translations/locales';
import OnboardingInfoTile from '../OnboardingInfoTile';
import Link from '../../../../reusable/atoms/Link';
import { black, boldWeight, informationAlert, medium } from '../../../../../themes/globalConstants';
import { hasPersonas, Persona } from '../../../../../utility/auth/useSecurity';
import { AuthState } from '../../../../../store/reducers/auth';
import OnboardingInviteUserModal from '../../../../reusable/molecules/OnboardingInviteUserModal';
import OnboardingSectionCompleted from '../OnboardingSectionCompleted';
import { NCACountries } from '../../../../../utility/helpers/address-helpers';
import { Alert } from '@material-ui/lab';

interface Props {
    disabled?: boolean;
}

const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: '1em'
    },
    tileContainer: {
        marginBottom: '2em'
    },
    link: {
        fontSize: medium,
        textDecoration: 'underline'
    },
    informationalAlert: {
        backgroundColor: informationAlert,
        borderRadius: '.125em',
        alignItems: 'center',
        width: 'flex',
        maxHeight: '2em',
        marginTop: '1em'
    },
    informationAlertText: {
        fontWeight: boldWeight,
        fontSize: medium,
        color: black
    }
}));

export default function AccountSetup({ disabled }: Props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const auth = useTypedSelector<AuthState>((state) => state.auth);
    const [termsComplete, setTermsComplete] = useState<boolean>(false);
    const [disableTaxForm, setDisableTaxForm] = useState<boolean>(false);
    const [salesAndUseTaxState, setSalesAndUseTaxState] = useState<OnboardingStepState>(
        OnboardingStepState.UNKNOWN
    );
    const [billingAndShippingState, setBillingAndShippingState] = useState<OnboardingStepState>(
        OnboardingStepState.UNKNOWN
    );
    const [creditAppState, setCreditAppState] = useState<OnboardingStepState>(
        OnboardingStepState.UNKNOWN
    );

    const { termsAndConditions, salesAndUseTax, billingAndShipping, creditApplication } =
        useTypedSelector<OnboardingDashboardState>((state) => state.onboardingDashboardState);

    const [hasTermsPersona, setHasTermsPersona] = useState<boolean>(false);
    const [hasBillingPersona, setHasBillingPersona] = useState<boolean>(false);
    const [hasTaxPersona, setHasTaxPersona] = useState<boolean>(false);
    const [hasCreditPersona, setHasCreditPersona] = useState<boolean>(false);
    const [hasJoiningAdminPersona, setHasJoiningAdminPersona] = useState<boolean>(false);
    const [openInviteUser, setOpenInviteUser] = useState<boolean>(false);

    useEffect(() => {
        setTermsComplete(termsAndConditions.state === OnboardingStepState.COMPLETE);
    }, [termsAndConditions.state]);

    useEffect(() => {
        setBillingAndShippingState(billingAndShipping.state);
        if (
            billingAndShipping.state === OnboardingStepState.COMPLETE &&
            billingAndShipping?.billingAndShippingForm?.billingAddress &&
            (billingAndShipping.billingAndShippingForm.billingAddress.country ===
                NCACountries.Mexico ||
                billingAndShipping.billingAndShippingForm.billingAddress.country ===
                    NCACountries.Canada)
        ) {
            setDisableTaxForm(true);
        }
    }, [billingAndShipping]);

    useEffect(() => {
        setSalesAndUseTaxState(salesAndUseTax.state);
    }, [salesAndUseTax.state]);

    useEffect(() => {
        setCreditAppState(creditApplication.state);
    }, [creditApplication.state]);

    const onBillAndShipButtonClick = () => {
        history.push('/onboard/billing-and-shipping');
    };

    const reviewTermsAndConditions = () => {
        history.push('/terms-and-conditions');
    };

    const onSalesAndUseTaxButtonClick = () => {
        history.push('/onboard/sales-and-use-tax');
    };

    const openInviteUserModal = () => {
        setOpenInviteUser(true);
    };

    const closeInviteUserModal = () => {
        setOpenInviteUser(false);
    };

    useEffect(() => {
        setHasJoiningAdminPersona(hasPersonas([Persona.JoiningAdmin], auth));
        setHasTermsPersona(
            hasPersonas([Persona.JoiningAdmin, Persona.JoiningTermsAndConditions], auth)
        );
        setHasBillingPersona(
            hasPersonas([Persona.JoiningAdmin, Persona.JoiningBillingAndShippingAddresses], auth)
        );
        setHasTaxPersona(hasPersonas([Persona.JoiningAdmin, Persona.JoiningSalesAndUseTax], auth));
        setHasCreditPersona(
            hasPersonas([Persona.JoiningAdmin, Persona.JoiningCreditApplication], auth)
        );
    }, [auth]);

    return (
        <>
            <Grid container>
                <Grid container item xs={6} justify="flex-start">
                    <Typography
                        variant="h3"
                        className={classes.header}
                        data-testid="account-setup-header"
                    >
                        <Trans i18nKey="accountSetupHeader">{enUS.accountSetupHeader}</Trans>
                    </Typography>
                </Grid>
                <Grid container item xs={6} justify="flex-end">
                    {hasJoiningAdminPersona && (
                        <Link
                            underline="none"
                            component="button"
                            data-testid="invite-to-help-complete"
                            className={classes.link}
                            onClick={openInviteUserModal}
                            disabled={disabled}
                        >
                            <Trans i18nKey="inviteToHelpComplete">
                                {enUS.inviteToHelpComplete}
                            </Trans>
                        </Link>
                    )}
                </Grid>
            </Grid>
            <Grid container className={classes.tileContainer}>
                <Grid item>
                    <OnboardingInfoTile
                        title={t('accountSetupTCTitle', enUS.accountSetupTCTitle)}
                        description={t('accountSetupTCText', enUS.accountSetupTCText)}
                        stepDisabled={disabled || termsComplete || !hasTermsPersona}
                        actionButton={
                            termsComplete ? (
                                <OnboardingSectionCompleted />
                            ) : (
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    data-testid="account-setup-button"
                                    onClick={() => {
                                        reviewTermsAndConditions();
                                    }}
                                >
                                    <Trans i18nKey="accountSetupReviewTCAction">
                                        {enUS.accountSetupReviewTCAction}
                                    </Trans>
                                </Button>
                            )
                        }
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <OnboardingInfoTile
                        title={t('billAndShipTitle', enUS.billAndShipTitle)}
                        description={t('billAndShipText', enUS.billAndShipText)}
                        stepDisabled={disabled || !termsComplete || !hasBillingPersona}
                        actionButton={
                            billingAndShippingState === OnboardingStepState.COMPLETE ? (
                                <OnboardingSectionCompleted submitted={true} />
                            ) : (
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    data-testid="bill-and-ship-button"
                                    onClick={onBillAndShipButtonClick}
                                >
                                    {billingAndShippingState === OnboardingStepState.DRAFT ? (
                                        <Trans i18nKey="continue">{enUS.continue}</Trans>
                                    ) : (
                                        <Trans i18nKey="billAndShipAction">
                                            {enUS.billAndShipAction}
                                        </Trans>
                                    )}
                                </Button>
                            )
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <OnboardingInfoTile
                        title={t('salesAndUseTitle', enUS.salesAndUseTitle)}
                        description={t('salesAndUseText', enUS.salesAndUseText)}
                        stepDisabled={
                            disabled || !termsComplete || disableTaxForm || !hasTaxPersona
                        }
                        actionButton={
                            salesAndUseTaxState === OnboardingStepState.COMPLETE ? (
                                <OnboardingSectionCompleted submitted={true} />
                            ) : (
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    data-testid="sales-and-use-button"
                                    onClick={onSalesAndUseTaxButtonClick}
                                >
                                    {salesAndUseTaxState === OnboardingStepState.DRAFT ? (
                                        <Trans i18nKey="continue">{enUS.continue}</Trans>
                                    ) : (
                                        <Trans i18nKey="salesAndUseAction">
                                            {enUS.salesAndUseAction}
                                        </Trans>
                                    )}
                                </Button>
                            )
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <OnboardingInfoTile
                        title={t('creditApplicationTitle', enUS.creditApplicationTitle)}
                        description={t('creditApplicationTitleText', enUS.creditApplicationText)}
                        stepDisabled={disabled || !termsComplete || !hasCreditPersona}
                        optional={true}
                        actionButton={
                            creditAppState === OnboardingStepState.COMPLETE ? (
                                <OnboardingSectionCompleted submitted={true} />
                            ) : (
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    data-testid="review-tc-button"
                                    onClick={() => {
                                        history.push('/onboard/credit-application');
                                    }}
                                >
                                    {creditAppState === OnboardingStepState.DRAFT ? (
                                        <Trans i18nKey="creditApplicationActionContinue">
                                            {enUS.creditApplicationActionContinue}
                                        </Trans>
                                    ) : (
                                        <Trans i18nKey="creditApplicationAction">
                                            {enUS.creditApplicationAction}
                                        </Trans>
                                    )}
                                </Button>
                            )
                        }
                    />
                </Grid>
                {termsComplete &&
                    billingAndShippingState === OnboardingStepState.COMPLETE &&
                    (salesAndUseTaxState === OnboardingStepState.COMPLETE || disableTaxForm) &&
                    creditAppState === OnboardingStepState.COMPLETE && (
                        <Grid item>
                            <Alert
                                className={classes.informationalAlert}
                                icon={false}
                                data-testid="information-received"
                            >
                                <Typography className={classes.informationAlertText}>
                                    <Trans i18nKey="informationReceived">
                                        Your information has been received and is being processed.
                                    </Trans>
                                </Typography>
                            </Alert>
                        </Grid>
                    )}
            </Grid>
            <OnboardingInviteUserModal open={openInviteUser} onClose={closeInviteUserModal} />
        </>
    );
}
