import React, { useEffect, useMemo, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import {
    makeStyles,
    Grid,
    TextField,
    Container,
    Typography,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    RadioGroup,
    Radio,
    FormLabel,
    FormHelperText,
    FormControlLabel,
    Checkbox,
    ListItemText,
    CircularProgress
} from '@material-ui/core';
import OktaAuth from '@okta/okta-auth-js';
import PageBanner from '../../reusable/molecules/PageBanner';
import { useForm, Controller, ErrorMessage } from 'react-hook-form';
import Button from '../../reusable/atoms/Button';
import Link from '../../reusable/atoms/Link';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import SubheaderBar from '../../reusable/atoms/SubheaderBar';
import { Recaptcha } from '../../reusable/molecules/Recaptcha';
import { AxiosError } from 'axios';
import {
    regions,
    numberOfEmployees,
    yearsInBusiness,
    packageType,
    printType
} from '../../../static-data/dropdown-lists';
import RegexPasswordHelperText from './components/RegexPasswordHelperText';
import { useQuery } from '../../../utility/helpers/query-helpers';
import UserService from '../../../utility/services/user-service';
import { Trans, useTranslation } from 'react-i18next';
import { containerMaxWidth, medium } from '../../../themes/globalConstants';
import PhoneInput from '../../reusable/molecules/PhoneInput';
import TranslationService from '../../../utility/services/translation-service';
import { Culture } from '../../../store/reducers/region-culture';
import clsx from 'clsx';
import { CompleteForwardedRegistrationRequest } from '../../../utility/services/user-service';
import { Region } from '../../../store/reducers/graphic-intake';
import StateProvinceSelect from '../../reusable/molecules/StateProvinceSelect';
import { KeyValuePair } from '../../../store/types';
import { postalCodePattern } from '../../../utility/helpers/address-helpers';
import { isValidEmail } from '../../../utility/helpers/user-helpers';
import InfoIcon from '@material-ui/icons/Info';
import PasswordHelperText from './components/PasswordHelperText';

interface Props {
    issuer: string;
    redirectUri: string;
    clientId: string;
}

const useStyles = makeStyles((theme) => ({
    container: {
        maxWidth: containerMaxWidth,
        padding: '0 0.5em'
    },
    link: { fontSize: medium },
    error: { color: theme.palette.error.main },
    spinningLoader: { flexDirection: 'column', alignItems: 'center', marginBottom: '0.5em' },
    russianText: { fontSize: '0.812rem' },
    emailOptOutContainer: { display: 'flex' },
    checkbox: { position: 'relative', top: '1px', marginRight: '8px' },
    formWithIconWrapper: { display: 'flex' },
    formIcon: { position: 'relative', top: '17px', left: '7px', cursor: 'pointer' }
}));

const cleanupLabel = (label) => {
    return label?.replace('< ', '<').replace('> ', '>');
};

export default function RegistrationPage(config: Props) {
    const { register, handleSubmit, errors, setValue, control, watch } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur'
    });
    const [userCountry, setUserCountry] = useState('us');
    const [userExists, setUserExists] = useState(false);
    const [error, setError] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [apiError, setApiError] = useState<'password' | 'email' | 'security' | 'unknown' | null>(
        null
    );
    const [forwardedRegistration, setForwardedRegistration] = useState(false);
    const [forwardedRegistrationId, setForwardedRegistrationId] = useState<number | null>(null);
    const query = useQuery();
    const { authService, authState } = useOktaAuth();
    const history = useHistory();
    const classes = useStyles();
    const { i18n, t } = useTranslation();
    const region = watch('region') as string;
    const language = watch('language') as string;
    const country = watch('country') as string;
    const firstName = watch('firstName') as string;
    const lastName = watch('lastName') as string;
    const email = watch('email') as string;
    const password = watch('password') as string;
    const captchaToken = watch('captchaToken') as string;
    const currentPackageType = watch('currentPackageType') as string[];
    const productSize = watch('productSize') as string[];
    const beverageInRetail = watch('beverageInRetail') === 'Yes';
    const noCanningLine = watch('canningLine') === 'No';
    const passwordPattern = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).{8,}$');
    const isNewsletter = query.get('ref')?.localeCompare('newsletter') === 0;
    const isEmail = query.get('ref')?.localeCompare('email') === 0;

    const registrationDesc =
        region === Region.EU && language === Culture.esES
            ? 'registrationDescEMEA'
            : 'registrationDescription';
    const isRequired = (
        <Trans i18nKey="required" i18n={i18n}>
            Required
        </Trans>
    );

    const onVerifyCaptcha = (token) => {
        setValue('captchaToken', token);
    };

    const onResetCaptcha = () => {
        setValue('captchaToken', null);
    };

    const resetFields = (fields: any) => {
        fields.forEach((field) => {
            for (let name in field) {
                setValue([
                    {
                        [name]: field[name]
                    }
                ]);
            }
        });
    };

    const passwordContainsDisallowedValue = useMemo(() => {
        const emailSections = email?.split(/(\.|,|-|_|#|@)/) ?? [];
        const disallowedValues = [...emailSections, firstName, lastName];

        return disallowedValues.some((value) => {
            if (!value || !password) return false;

            return value.length > 3 && password.toLowerCase().includes(value.toLowerCase());
        });
    }, [password, email, firstName, lastName]);

    useEffect(() => {
        // if someone is authenticated, take them to the home page
        if (authState.isAuthenticated) {
            history.push('/');
        }
    }, [authState]);

    // change language when they select one since this doesn't have header
    useEffect(() => {
        if (language) {
            i18n.changeLanguage(language);
        }
    }, [language, i18n]);

    useEffect(() => {
        register({ name: 'captchaToken' }, { required: isRequired });
        // If hash is provided as param, user is attempting to complete their registration from email.
        if (isEmail) {
            const hash = query.get('referenceId');
            if (hash) {
                setForwardedRegistration(true);
                initForwardedRegistration(hash);
            }
        }
    }, []);

    useEffect(() => {
        resetFields([
            { productSize: [] },
            { language: '' },
            { country: region === Region.NA ? 'United States' : '' },
            { estimatedAnnualRevenue: '' },
            { fillingCountry: '' },
            { expectedAnnualQuantity: '' },
            { beverageCategory: '' },
            { fillingCity: '' }
        ]);
    }, [region]);

    // On country change, change default phone country
    useEffect(() => {
        if (country) {
            const defaultCountry = regions
                .find((r) => r.value === region)
                ?.countries.find((c) => c.name === country)
                ?.value.toLowerCase() as string;
            setUserCountry(defaultCountry);
            setValue('stateProvince', undefined);
        }
    }, [country, region]);

    const onSubmit = (data) => {
        setSubmitting(true);
        if (forwardedRegistration) {
            completeForwardedRegistration(data);
        } else {
            const user = getUser(data);

            const lead = isNewsletter
                ? undefined
                : {
                      // basic fields
                      firstName: data.firstName,
                      lastName: data.lastName,
                      email: data.email,
                      primaryPhone: data.phone,
                      region: regions.find((r) => r.value === data.region)
                          ?.salesforceValue as string,
                      language: regions
                          .find((r) => r.value === data.region)
                          ?.languages.find((lang) => lang.value === data.language)
                          ?.salesforceValue as string,
                      company: data.companyName,

                      // lead fields
                      street: data.street,
                      areaOfInterest: 'Beverage Packaging',
                      beverageCategory: data.beverageCategory,
                      city: data.city,
                      state: region === Region.NA ? data.stateProvince.value : undefined,
                      country: data.country,
                      postalCode: data.postalCode,
                      additionalComments: data.comments,
                      canningLine: data.canningLine,
                      packageType: data.currentPackageType,
                      hasBeverageRetail: data.beverageInRetail,
                      yearInBusiness: data.yearsInBusiness,
                      numberOfEmployees: data.numberOfEmployees,
                      estimatedAnnualRevenue: data.estimatedAnnualRevenue,
                      expectedQuantity: data.expectedAnnualQuantity,
                      size: data.productSize,
                      coPackerName: data.coPackerName,
                      fillingCity:
                          region === Region.SA || region === Region.NA
                              ? data.fillingCity
                              : undefined,
                      fillingCountry:
                          region === Region.SA || region === Region.NA
                              ? data.fillingCountry
                              : undefined,
                      printType: region === Region.SA ? data.printType : undefined,
                      title: data.title,
                      emailOptOut: data.emailOptOut
                  };

            UserService.register(user, data.captchaToken, lead)
                .then(() => {
                    // once complete with registration, sign in
                    const oktaAuth = new OktaAuth({
                        issuer: config.issuer,
                        redirectUri: config.redirectUri,
                        clientId: config.clientId
                    });

                    oktaAuth
                        .signIn({ username: data.email, password: data.password })
                        .then((res) => {
                            authService.setFromUri('/source/registration-callback');
                            authService.redirect({ sessionToken: res.sessionToken });
                        });
                })
                .catch(handleRegistrationError);
        }
    };

    function getUser(data: any) {
        return {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            primaryPhone: data.phone,
            login: data.email,
            region: data.region,
            language: data.language,
            company: data.companyName,
            password: data.password,
            securityQuestion: TranslationService.getTranslatedText(language, data.securityQuestion),
            securityAnswer: data.securityAnswer
        };
    }

    function handleRegistrationError(err: AxiosError) {
        onResetCaptcha();
        setSubmitting(false);
        if (err.response) {
            if (err.response?.status === 409) {
                // user already exists
                setUserExists(true);
            } else if (err.response?.status === 400) {
                // parse out response for meaningful message
                const {
                    response: { data }
                } = err;

                if (data.errorCauses.hasOwnProperty('email')) {
                    setApiError('email');
                } else if (data.errorCauses.hasOwnProperty('password')) {
                    setApiError('password');
                } else if (data.errorCauses.hasOwnProperty('securityAnswer')) {
                    setApiError('security');
                } else {
                    setApiError('unknown');
                }
            } else {
                // unknown error
                setError(true);
            }
        } else {
            // unknown error
            setError(true);
        }
    }

    function completeForwardedRegistration(data: any) {
        const user = getUser(data);
        user.company = null;

        if (forwardedRegistrationId) {
            const payload: CompleteForwardedRegistrationRequest = {
                user: { ...user }
            };

            UserService.completeForwardedRegistration(
                forwardedRegistrationId,
                payload,
                data.captchaToken
            )
                .then(() => authenticateUser(user.email, user.password))
                .catch(handleRegistrationError);
        }
    }

    function authenticateUser(username: string, password: string) {
        const oktaAuth = new OktaAuth({
            issuer: config.issuer,
            redirectUri: config.redirectUri,
            clientId: config.clientId
        });

        return oktaAuth.signIn({ username, password }).then((res) => {
            authService.setFromUri('/source/registration-callback');
            authService.redirect({ sessionToken: res.sessionToken });
        });
    }

    function initForwardedRegistration(hash: string) {
        UserService.getRegistration(hash)
            .then((resp) => {
                setValue('email', resp.userName);
                setForwardedRegistrationId(resp.registrationId);
            })
            .catch((err) => {
                // 404 handling
                history.push('/');
            });
    }

    function handleSelectState(name: string, selectedState: KeyValuePair) {
        setValue(name, selectedState);
    }

    const countryCode = country === 'Mexico' ? 'MX' : country === 'Canada' ? 'CA' : 'US';
    const clearApiError = () => {
        if (apiError) {
            setApiError(null);
        }
    };

    return (
        <div data-testid="registration-page">
            <PageBanner
                header={t('registrationHeader', "Let's Get the Ball Rolling")}
                description={t(
                    `${registrationDesc}`,
                    'Before you can get access to estimates, find inspiration and learn about our products, we need a little bit of infromation from you to get you to the Ball Customer Portal.'
                )}
                logoUrl={process.env.PUBLIC_URL + '/assets/TheSource_Logo_Registration.png'}
            />
            <SubheaderBar>
                <Typography component="div">
                    <Trans i18nKey="alreadyRegistered">Already registered?</Trans>
                    &nbsp;
                    <Link
                        component={RouterLink}
                        to="/login"
                        underline="none"
                        className={classes.link}
                        data-testid="login-link"
                    >
                        <Trans i18nKey="signIn">Sign in to the Customer Portal</Trans>
                    </Link>
                </Typography>
            </SubheaderBar>
            <Container className={classes.container}>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <Grid container spacing={2}>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={errors.region}
                                    data-testid="select-region"
                                >
                                    <InputLabel>
                                        <Trans i18nKey="region">Region</Trans>
                                    </InputLabel>
                                    <Controller
                                        as={
                                            <Select inputProps={{ 'data-testid': 'region' }}>
                                                <MenuItem>&nbsp;</MenuItem>
                                                {regions.map((eachRegion) => (
                                                    <MenuItem
                                                        key={eachRegion.value}
                                                        value={eachRegion.value}
                                                    >
                                                        {eachRegion.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        }
                                        name="region"
                                        control={control}
                                        rules={{ required: isRequired }}
                                        defaultValue={query.get('region') ?? ''}
                                    />
                                    <FormHelperText>
                                        <ErrorMessage errors={errors} name="region" />
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={errors.language}
                                    data-testid="select-language"
                                >
                                    <InputLabel>
                                        <Trans i18nKey="language">Language</Trans>
                                    </InputLabel>
                                    <Controller
                                        as={
                                            <Select inputProps={{ 'data-testid': 'language' }}>
                                                <MenuItem>&nbsp;</MenuItem>
                                                {regions
                                                    .find((r) => r.value === region)
                                                    ?.languages.map((lang) => (
                                                        <MenuItem
                                                            key={lang.value}
                                                            value={lang.value}
                                                            className={clsx({
                                                                [classes.russianText]:
                                                                    lang.value === Culture.ruRU
                                                            })}
                                                        >
                                                            {lang.name}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        }
                                        name="language"
                                        defaultValue=""
                                        control={control}
                                        rules={{ required: isRequired }}
                                    />
                                    <FormHelperText
                                        data-testid={errors.language ? 'language-error' : ''}
                                    >
                                        <ErrorMessage errors={errors} name="language" />
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}></Grid>
                        </Grid>
                        {forwardedRegistration === false && (
                            <>
                                <Grid item xs={12}>
                                    <Typography variant="h3">
                                        <Trans i18nKey="aboutYourCompany">About your Company</Trans>
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label={<Trans i18nKey="companyName">Company Name</Trans>}
                                        name="companyName"
                                        data-testid="companyName"
                                        inputRef={register({ required: isRequired })}
                                        error={errors?.companyName}
                                        helperText={
                                            <ErrorMessage errors={errors} name="companyName" />
                                        }
                                        inputProps={{ 'data-testid': 'company-name' }}
                                    />
                                </Grid>
                                {!isNewsletter && (
                                    <>
                                        <Grid container item xs={12} spacing={2}>
                                            {region === Region.NA && (
                                                <Grid item xs={6}>
                                                    <TextField
                                                        fullWidth
                                                        label={
                                                            <Trans i18nKey="street">Street</Trans>
                                                        }
                                                        name="street"
                                                        data-testid="street"
                                                        inputRef={register({
                                                            required: isRequired
                                                        })}
                                                        inputProps={{ maxLength: 25 }}
                                                        error={errors?.street}
                                                        helperText={
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="street"
                                                            />
                                                        }
                                                    ></TextField>
                                                </Grid>
                                            )}
                                            <Grid item xs={3}>
                                                <FormControl
                                                    fullWidth
                                                    error={errors.country}
                                                    data-testid="select-country"
                                                >
                                                    <InputLabel>
                                                        <Trans i18nKey="country">Country</Trans>
                                                    </InputLabel>
                                                    <Controller
                                                        as={
                                                            <Select
                                                                inputProps={{
                                                                    'data-testid': 'country'
                                                                }}
                                                            >
                                                                <MenuItem>&nbsp;</MenuItem>
                                                                {regions
                                                                    .find((r) => r.value === region)
                                                                    ?.countries.map((country) => (
                                                                        <MenuItem
                                                                            key={country.value}
                                                                            value={country.name}
                                                                        >
                                                                            <Trans
                                                                                i18nKey={
                                                                                    country.name
                                                                                }
                                                                            >
                                                                                {country.name}
                                                                            </Trans>
                                                                        </MenuItem>
                                                                    ))}
                                                            </Select>
                                                        }
                                                        name="country"
                                                        control={control}
                                                        rules={{ required: isRequired }}
                                                    />
                                                    <FormHelperText
                                                        data-testid={
                                                            errors.country ? 'country-error' : ''
                                                        }
                                                    >
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="country"
                                                        />
                                                    </FormHelperText>
                                                </FormControl>
                                            </Grid>
                                            {region !== Region.NA && (
                                                <Grid item xs={2}>
                                                    <TextField
                                                        fullWidth
                                                        label={<Trans i18nKey="city">City</Trans>}
                                                        name="city"
                                                        data-testid="city"
                                                        inputRef={register({
                                                            required: isRequired
                                                        })}
                                                        inputProps={{ maxLength: 25 }}
                                                        error={errors?.city}
                                                        helperText={
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="city"
                                                            />
                                                        }
                                                    ></TextField>
                                                </Grid>
                                            )}
                                        </Grid>
                                        <Grid container item xs={12} spacing={2}>
                                            {region === Region.NA && (
                                                <>
                                                    <Grid item xs={3}>
                                                        <StateProvinceSelect
                                                            name={'stateProvince'}
                                                            data-testid="stateProvince"
                                                            countryCode={countryCode}
                                                            control={control}
                                                            rules={{ required: isRequired }}
                                                            error={errors.stateProvince}
                                                            errors={errors}
                                                            handleLoadStateProvince={
                                                                handleSelectState
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <TextField
                                                            fullWidth
                                                            label={
                                                                <Trans i18nKey="city">City</Trans>
                                                            }
                                                            name="city"
                                                            data-testid="city"
                                                            inputRef={register({
                                                                required: isRequired
                                                            })}
                                                            inputProps={{ maxLength: 25 }}
                                                            error={errors?.city}
                                                            helperText={
                                                                <ErrorMessage
                                                                    errors={errors}
                                                                    name="city"
                                                                />
                                                            }
                                                        ></TextField>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <TextField
                                                            fullWidth
                                                            label={
                                                                <Trans i18nKey="postalCode">
                                                                    Postal Code
                                                                </Trans>
                                                            }
                                                            name="postalCode"
                                                            data-testid="postalCode"
                                                            inputRef={register({
                                                                required: isRequired,
                                                                pattern: postalCodePattern
                                                            })}
                                                            inputProps={{
                                                                maxLength: 11
                                                            }}
                                                            error={errors?.postalCode}
                                                            helperText={
                                                                <ErrorMessage
                                                                    errors={errors}
                                                                    name="postalCode"
                                                                />
                                                            }
                                                        ></TextField>
                                                    </Grid>
                                                </>
                                            )}
                                        </Grid>
                                        <Grid container item xs={12} spacing={2}>
                                            <Grid item xs={3}>
                                                <FormControl
                                                    fullWidth
                                                    error={errors.yearsInBusiness}
                                                    data-testid="years-in-business"
                                                >
                                                    <InputLabel>
                                                        <Trans i18nKey="yearsInBusiness">
                                                            Years in Business
                                                        </Trans>
                                                    </InputLabel>
                                                    <Controller
                                                        as={
                                                            <Select
                                                                inputProps={{
                                                                    'data-testid': 'yearsInBusiness'
                                                                }}
                                                            >
                                                                <MenuItem>&nbsp;</MenuItem>
                                                                {yearsInBusiness.map((years) => (
                                                                    <MenuItem
                                                                        key={years}
                                                                        value={years}
                                                                    >
                                                                        {cleanupLabel(
                                                                            years?.replace(
                                                                                'year',
                                                                                t('year', 'year')
                                                                            )
                                                                        )}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        }
                                                        name="yearsInBusiness"
                                                        control={control}
                                                        rules={{ required: isRequired }}
                                                    />
                                                    <FormHelperText
                                                        data-testid={
                                                            errors.yearsInBusiness
                                                                ? 'years-error'
                                                                : ''
                                                        }
                                                    >
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="yearsInBusiness"
                                                        />
                                                    </FormHelperText>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControl
                                                    fullWidth
                                                    error={errors.numberOfEmployees}
                                                    data-testid="number-employees"
                                                >
                                                    <InputLabel>
                                                        <Trans i18nKey="numberOfEmployees">
                                                            Number Of Employees
                                                        </Trans>
                                                    </InputLabel>
                                                    <Controller
                                                        as={
                                                            <Select
                                                                inputProps={{
                                                                    'data-testid':
                                                                        'numberOfEmployees'
                                                                }}
                                                            >
                                                                <MenuItem>&nbsp;</MenuItem>
                                                                {numberOfEmployees.map((c) => (
                                                                    <MenuItem key={c} value={c}>
                                                                        {cleanupLabel(c)}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        }
                                                        name="numberOfEmployees"
                                                        control={control}
                                                        rules={{ required: isRequired }}
                                                    />
                                                    <FormHelperText
                                                        data-testid={
                                                            errors.numberOfEmployees
                                                                ? 'number-employees-error'
                                                                : ''
                                                        }
                                                    >
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="numberOfEmployees"
                                                        />
                                                    </FormHelperText>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControl
                                                    fullWidth
                                                    error={errors.estimatedAnnualRevenue}
                                                    data-testid="est-annual-revenue"
                                                >
                                                    <InputLabel>
                                                        <Trans i18nKey="estimatedAnnualRevenue">
                                                            Estimated Annual Revenue
                                                        </Trans>
                                                    </InputLabel>
                                                    <Controller
                                                        as={
                                                            <Select
                                                                inputProps={{
                                                                    'data-testid':
                                                                        'estimatedAnnualRevenue'
                                                                }}
                                                            >
                                                                <MenuItem>&nbsp;</MenuItem>
                                                                {regions
                                                                    .find((r) => r.value === region)
                                                                    ?.annualRevenue.map(
                                                                        (revenue) => (
                                                                            <MenuItem
                                                                                key={revenue}
                                                                                value={revenue}
                                                                            >
                                                                                {cleanupLabel(
                                                                                    revenue
                                                                                        .replace(
                                                                                            'Million',
                                                                                            t(
                                                                                                'million',
                                                                                                'million'
                                                                                            )
                                                                                        )
                                                                                        .replace(
                                                                                            'New to Beverage',
                                                                                            t(
                                                                                                'newToBeverage',
                                                                                                'newToBeverage'
                                                                                            )
                                                                                        )
                                                                                )}
                                                                            </MenuItem>
                                                                        )
                                                                    )}
                                                            </Select>
                                                        }
                                                        name="estimatedAnnualRevenue"
                                                        control={control}
                                                        rules={{ required: isRequired }}
                                                    />
                                                    <FormHelperText
                                                        data-testid={
                                                            errors.estimatedAnnualRevenue
                                                                ? 'est-revenue-error'
                                                                : ''
                                                        }
                                                    >
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="estimatedAnnualRevenue"
                                                        />
                                                    </FormHelperText>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth error={errors.beverageInRetail}>
                                                <FormLabel>
                                                    <Trans i18nKey="beverageInRetail">
                                                        Do you currently have a beverage in retail?
                                                    </Trans>
                                                </FormLabel>
                                                <Controller
                                                    as={
                                                        <RadioGroup
                                                            row
                                                            data-testid="beverageInRetail"
                                                        >
                                                            <FormControlLabel
                                                                value="Yes"
                                                                control={<Radio />}
                                                                label={
                                                                    <Trans i18nKey="yes">Yes</Trans>
                                                                }
                                                            />
                                                            <FormControlLabel
                                                                value="No"
                                                                control={<Radio />}
                                                                label={
                                                                    <Trans i18nKey="no">No</Trans>
                                                                }
                                                            />
                                                        </RadioGroup>
                                                    }
                                                    name="beverageInRetail"
                                                    control={control}
                                                    rules={{ required: isRequired }}
                                                />
                                                <FormHelperText
                                                    data-testid={
                                                        errors.beverageInRetail
                                                            ? 'beverage-retail-error'
                                                            : ''
                                                    }
                                                >
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="beverageInRetail"
                                                    />
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        {beverageInRetail && (
                                            <Grid item xs={12}>
                                                <Grid item xs={3}>
                                                    <FormControl
                                                        fullWidth
                                                        error={errors.currentPackageType}
                                                        data-testid="current-package-type"
                                                    >
                                                        <InputLabel>
                                                            <Trans i18nKey="currentPackageType">
                                                                Current Package Type
                                                            </Trans>
                                                        </InputLabel>
                                                        <Controller
                                                            as={
                                                                <Select
                                                                    MenuProps={{ variant: 'menu' }}
                                                                    inputProps={{
                                                                        'data-testid':
                                                                            'currentPackageType'
                                                                    }}
                                                                    multiple
                                                                    renderValue={(selected) =>
                                                                        (selected as string[]).join(
                                                                            ', '
                                                                        )
                                                                    }
                                                                >
                                                                    {packageType.map(
                                                                        (eachPackageType) => (
                                                                            <MenuItem
                                                                                key={
                                                                                    eachPackageType
                                                                                }
                                                                                value={
                                                                                    eachPackageType
                                                                                }
                                                                            >
                                                                                <Checkbox
                                                                                    checked={
                                                                                        currentPackageType?.indexOf(
                                                                                            eachPackageType
                                                                                        ) > -1
                                                                                    }
                                                                                />
                                                                                <ListItemText
                                                                                    primary={
                                                                                        eachPackageType
                                                                                    }
                                                                                />
                                                                            </MenuItem>
                                                                        )
                                                                    )}
                                                                </Select>
                                                            }
                                                            name="currentPackageType"
                                                            control={control}
                                                            rules={{
                                                                required: isRequired,
                                                                validate: {
                                                                    hasAtLeastOne: (value) => {
                                                                        return (
                                                                            value?.length ||
                                                                            isRequired
                                                                        );
                                                                    }
                                                                }
                                                            }}
                                                            defaultValue={[]}
                                                        />
                                                        <FormHelperText>
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="currentPackageType"
                                                            />
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <Typography variant="h3">
                                                <Trans i18nKey="beveragePackagingNeeds">
                                                    Your Beverage Packaging Needs
                                                </Trans>
                                            </Typography>
                                        </Grid>
                                        <Grid container item xs={12} spacing={2}>
                                            <Grid item xs={3}>
                                                <FormControl
                                                    fullWidth
                                                    error={errors.beverageCategory}
                                                    data-testid="beverage-category"
                                                >
                                                    <InputLabel>
                                                        <Trans i18nKey="beverageCategory">
                                                            Beverage Category
                                                        </Trans>
                                                    </InputLabel>
                                                    <Controller
                                                        as={
                                                            <Select
                                                                inputProps={{
                                                                    'data-testid':
                                                                        'beverageCategory'
                                                                }}
                                                            >
                                                                <MenuItem>&nbsp;</MenuItem>
                                                                {regions
                                                                    .find((r) => r.value === region)
                                                                    ?.beverageCategories.map(
                                                                        (category) => (
                                                                            <MenuItem
                                                                                key={category.value}
                                                                                value={
                                                                                    category.value
                                                                                }
                                                                            >
                                                                                <Trans
                                                                                    i18nKey={
                                                                                        category.i18nKey
                                                                                    }
                                                                                >
                                                                                    category.value
                                                                                </Trans>
                                                                            </MenuItem>
                                                                        )
                                                                    )}
                                                            </Select>
                                                        }
                                                        name="beverageCategory"
                                                        control={control}
                                                        rules={{ required: isRequired }}
                                                    />
                                                    <FormHelperText
                                                        data-testid={
                                                            errors.beverageCategory
                                                                ? 'beverage-cat-error'
                                                                : ''
                                                        }
                                                    >
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="beverageCategory"
                                                        />
                                                    </FormHelperText>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControl
                                                    fullWidth
                                                    error={errors.productSize}
                                                    data-testid="product-size"
                                                >
                                                    <InputLabel>
                                                        <Trans i18nKey="productSize">
                                                            Product Size
                                                        </Trans>
                                                    </InputLabel>
                                                    <Controller
                                                        as={
                                                            <Select
                                                                MenuProps={{ variant: 'menu' }}
                                                                inputProps={{
                                                                    'data-testid': 'productSize'
                                                                }}
                                                                multiple
                                                                renderValue={(selected) =>
                                                                    (selected as string[]).join(
                                                                        ', '
                                                                    )
                                                                }
                                                            >
                                                                {regions
                                                                    .find((r) => r.value === region)
                                                                    ?.productSize.map((size) => (
                                                                        <MenuItem
                                                                            key={size}
                                                                            value={size}
                                                                        >
                                                                            <Checkbox
                                                                                checked={
                                                                                    productSize?.indexOf(
                                                                                        size
                                                                                    ) > -1
                                                                                }
                                                                            />
                                                                            <ListItemText
                                                                                primary={size}
                                                                            />
                                                                        </MenuItem>
                                                                    ))}
                                                            </Select>
                                                        }
                                                        name="productSize"
                                                        control={control}
                                                        defaultValue={[]}
                                                        rules={{
                                                            required: isRequired,
                                                            validate: {
                                                                hasAtLeastOne: (value) => {
                                                                    return (
                                                                        value?.length || isRequired
                                                                    );
                                                                }
                                                            }
                                                        }}
                                                    />
                                                    <FormHelperText
                                                        data-testid={
                                                            errors.productSize
                                                                ? 'product-size-error'
                                                                : ''
                                                        }
                                                    >
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="productSize"
                                                        />
                                                    </FormHelperText>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <div className={classes.formWithIconWrapper}>
                                                    <FormControl
                                                        fullWidth
                                                        error={errors.expectedAnnualQuantity}
                                                        data-testid="expected-annual-quantity"
                                                    >
                                                        <InputLabel>
                                                            <Trans i18nKey="expectedAnnualQuantity">
                                                                Expected Annual Quantity
                                                            </Trans>
                                                        </InputLabel>
                                                        <Controller
                                                            as={
                                                                <Select
                                                                    inputProps={{
                                                                        'data-testid':
                                                                            'expectedAnnualQuantity'
                                                                    }}
                                                                >
                                                                    <MenuItem>&nbsp;</MenuItem>
                                                                    {regions
                                                                        .find(
                                                                            (r) =>
                                                                                r.value === region
                                                                        )
                                                                        ?.expectedAnnualQuantity.map(
                                                                            (quantity) => (
                                                                                <MenuItem
                                                                                    key={quantity}
                                                                                    value={quantity}
                                                                                >
                                                                                    {cleanupLabel(
                                                                                        quantity.replace(
                                                                                            'cans',
                                                                                            t(
                                                                                                'cans',
                                                                                                'cans'
                                                                                            )
                                                                                        )
                                                                                    )}
                                                                                </MenuItem>
                                                                            )
                                                                        )}
                                                                </Select>
                                                            }
                                                            name="expectedAnnualQuantity"
                                                            control={control}
                                                            rules={{ required: isRequired }}
                                                        />

                                                        <FormHelperText
                                                            data-testid={
                                                                errors.expectedAnnualQuantity
                                                                    ? 'expected-quantity-error'
                                                                    : ''
                                                            }
                                                        >
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="expectedAnnualQuantity"
                                                            />
                                                        </FormHelperText>
                                                    </FormControl>
                                                    <span title="Please enter the total estimated number of cans you wish to purchase during a 12-month period.">
                                                        <InfoIcon
                                                            className={classes.formIcon}
                                                            fontSize="small"
                                                        />
                                                    </span>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        {region === Region.SA && (
                                            <Grid item container spacing={2} xs={12}>
                                                <Grid item xs={6}>
                                                    <FormControl fullWidth error={errors.printType}>
                                                        <InputLabel>
                                                            <Trans i18nKey="printType">
                                                                Would you like any of the following
                                                                can types?
                                                            </Trans>
                                                        </InputLabel>
                                                        <Controller
                                                            as={
                                                                <Select
                                                                    inputProps={{
                                                                        'data-testid': 'printType'
                                                                    }}
                                                                >
                                                                    <MenuItem>
                                                                        <Trans i18nKey="no">
                                                                            No
                                                                        </Trans>
                                                                    </MenuItem>
                                                                    {printType.map((pt) => (
                                                                        <MenuItem
                                                                            key={pt.name}
                                                                            value={pt.name}
                                                                        >
                                                                            <Trans
                                                                                i18nKey={pt.i18nKey}
                                                                            >
                                                                                {pt.name}
                                                                            </Trans>
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            }
                                                            name="printType"
                                                            control={control}
                                                        />
                                                        <FormHelperText>
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="printType"
                                                            />
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <FormControl fullWidth error={errors.canningLine}>
                                                <FormLabel>
                                                    <Trans i18nKey="canningLine">
                                                        Do you own or plan to purchase a canning
                                                        line?
                                                    </Trans>
                                                </FormLabel>
                                                <Controller
                                                    as={
                                                        <RadioGroup row data-testid="canningLine">
                                                            <FormControlLabel
                                                                value="Yes"
                                                                control={<Radio />}
                                                                label={
                                                                    <Trans i18nKey="yes">Yes</Trans>
                                                                }
                                                            />
                                                            <FormControlLabel
                                                                value="No"
                                                                control={<Radio />}
                                                                label={
                                                                    <Trans i18nKey="no">No</Trans>
                                                                }
                                                            />
                                                        </RadioGroup>
                                                    }
                                                    name="canningLine"
                                                    control={control}
                                                    rules={{ required: isRequired }}
                                                />
                                                <FormHelperText
                                                    data-testid={
                                                        errors.canningLine
                                                            ? 'canning-line-error'
                                                            : ''
                                                    }
                                                >
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="canningLine"
                                                    />
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        {noCanningLine && region === Region.SA && (
                                            <Grid container item xs={12} spacing={2}>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        fullWidth
                                                        type="text"
                                                        label={
                                                            <Trans i18nKey="copackerName">
                                                                Co-Packer Name
                                                            </Trans>
                                                        }
                                                        name="copackerName"
                                                        inputRef={register({
                                                            required: isRequired
                                                        })}
                                                        error={errors?.copackerName}
                                                        helperText={
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="copackerName"
                                                            />
                                                        }
                                                        inputProps={{
                                                            'data-testid': 'copacker-name'
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                        <Grid item container xs={12} spacing={2}>
                                            <Grid item xs={3}>
                                                <FormControl
                                                    fullWidth
                                                    error={errors.fillingCountry}
                                                    data-testid="filling-country"
                                                >
                                                    <InputLabel>
                                                        <Trans i18nKey="fillingCountry">
                                                            Filling Country
                                                        </Trans>
                                                    </InputLabel>
                                                    <Controller
                                                        as={
                                                            <Select>
                                                                <MenuItem>&nbsp;</MenuItem>
                                                                {regions
                                                                    .find((r) => r.value === region)
                                                                    ?.countries.map((c) => (
                                                                        <MenuItem
                                                                            key={c.value}
                                                                            value={c.name}
                                                                        >
                                                                            <Trans i18nKey={c.name}>
                                                                                {c.name}
                                                                            </Trans>
                                                                        </MenuItem>
                                                                    ))}
                                                            </Select>
                                                        }
                                                        name="fillingCountry"
                                                        control={control}
                                                        rules={{ required: isRequired }}
                                                    />
                                                    <FormHelperText
                                                        data-testid={
                                                            errors.fillingCountry
                                                                ? 'filling-country-error'
                                                                : ''
                                                        }
                                                    >
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="fillingCountry"
                                                        />
                                                    </FormHelperText>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    fullWidth
                                                    type="text"
                                                    label={
                                                        <Trans i18nKey="fillingCity">
                                                            Filling City
                                                        </Trans>
                                                    }
                                                    name="fillingCity"
                                                    inputRef={register({ required: isRequired })}
                                                    error={errors?.fillingCity}
                                                    helperText={
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="fillingCity"
                                                        />
                                                    }
                                                    inputProps={{
                                                        'data-testid': 'filling-city',
                                                        maxLength: 25
                                                    }}
                                                    data-testid="filling-city"
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={9}>
                                            <TextField
                                                fullWidth
                                                multiline
                                                rowsMax={4}
                                                type="text"
                                                label={
                                                    <Trans i18nKey="additionalComments">
                                                        Additional Comments (Optional)
                                                    </Trans>
                                                }
                                                data-testid="comments"
                                                name="comments"
                                                inputRef={register()}
                                            />
                                        </Grid>
                                    </>
                                )}
                            </>
                        )}
                        <Grid item xs={12}>
                            <Typography variant="h3">
                                <Trans i18nKey="setupCustomerPortalAccount">
                                    Setup Your Customer Portal Account
                                </Trans>
                            </Typography>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    label={<Trans i18nKey="firstName">First Name</Trans>}
                                    name="firstName"
                                    data-testid="firstName"
                                    inputRef={register({ required: isRequired })}
                                    error={errors?.firstName}
                                    helperText={<ErrorMessage errors={errors} name="firstName" />}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    label={<Trans i18nKey="lastName">Last Name</Trans>}
                                    name="lastName"
                                    data-testid="lastName"
                                    inputRef={register({ required: isRequired })}
                                    error={errors?.lastName}
                                    helperText={<ErrorMessage errors={errors} name="lastName" />}
                                />
                            </Grid>
                            {region === Region.NA && (
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        label={<Trans i18nKey="jobTitle">Job Title</Trans>}
                                        name="title"
                                        data-testid="title"
                                        inputRef={register({ required: isRequired })}
                                        error={errors?.title}
                                        helperText={<ErrorMessage errors={errors} name="title" />}
                                    />
                                </Grid>
                            )}
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    label={<Trans i18nKey="email">Email</Trans>}
                                    name="email"
                                    inputProps={{
                                        'data-testid': 'email'
                                    }}
                                    InputLabelProps={
                                        forwardedRegistration === true
                                            ? {
                                                  shrink: true
                                              }
                                            : undefined
                                    }
                                    inputRef={register({
                                        required: isRequired,
                                        validate: {
                                            isValidEmail: (value) => {
                                                return (
                                                    isValidEmail(value, true) || (
                                                        <Trans i18nKey="incorrectEmailAddress">
                                                            Invalid Email Format
                                                        </Trans>
                                                    )
                                                );
                                            }
                                        }
                                    })}
                                    disabled={forwardedRegistration === true}
                                    error={errors?.email}
                                    helperText={<ErrorMessage errors={errors} name="email" />}
                                    onChange={clearApiError}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Controller
                                    as={
                                        <PhoneInput
                                            label={
                                                <Trans i18nKey="phoneNumber">Phone Number</Trans>
                                            }
                                            testId="registration-phone-input"
                                            disableAreaCodes={true}
                                            defaultCountry={!isNewsletter ? userCountry : undefined}
                                        />
                                    }
                                    name="phone"
                                    control={control}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    type="password"
                                    label={<Trans i18nKey="password">Password</Trans>}
                                    name="password"
                                    data-testid="password"
                                    inputRef={register({
                                        required: isRequired,
                                        pattern: passwordPattern,
                                        validate: () => !passwordContainsDisallowedValue
                                    })}
                                    error={errors?.password}
                                    helperText={
                                        <ul>
                                            <RegexPasswordHelperText
                                                helpText={
                                                    <Trans i18nKey="atLeastEightLetters">
                                                        At least 8 letters
                                                    </Trans>
                                                }
                                                pattern=".{8,}"
                                                value={password}
                                                testId="password-eight-letters"
                                            ></RegexPasswordHelperText>
                                            <RegexPasswordHelperText
                                                helpText={
                                                    <Trans i18nKey="atLeastOneCapitalLetter">
                                                        At least one capital letter
                                                    </Trans>
                                                }
                                                pattern="[A-Z]+"
                                                value={password}
                                                testId="password-capital"
                                            ></RegexPasswordHelperText>
                                            <RegexPasswordHelperText
                                                helpText={
                                                    <Trans i18nKey="atLeastOneLowerCaseLetter">
                                                        At least one lowercase letter
                                                    </Trans>
                                                }
                                                pattern="[a-z]+"
                                                value={password}
                                                testId="password-lowercase"
                                            ></RegexPasswordHelperText>
                                            <RegexPasswordHelperText
                                                helpText={
                                                    <Trans i18nKey="atLeastOneNumber">
                                                        At least one number
                                                    </Trans>
                                                }
                                                pattern="[0-9]+"
                                                value={password}
                                                testId="password-number"
                                            ></RegexPasswordHelperText>
                                            <PasswordHelperText
                                                helpText={
                                                    <Trans i18nKey="noDisallowedValue">
                                                        Doesn't include name or part of email
                                                    </Trans>
                                                }
                                                invalid={passwordContainsDisallowedValue}
                                                testId="password-disallowed-value"
                                            ></PasswordHelperText>
                                        </ul>
                                    }
                                    onChange={clearApiError}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    type="password"
                                    label={
                                        <Trans i18nKey="confirmPassword">Confirm Password</Trans>
                                    }
                                    name="confirmPassword"
                                    inputRef={register({
                                        validate: {
                                            matchesPreviousPassword: (value) => {
                                                return (
                                                    password === value || (
                                                        <Trans i18nKey="passwordsShouldMatch">
                                                            Passwords must match
                                                        </Trans>
                                                    )
                                                );
                                            }
                                        }
                                    })}
                                    error={errors?.confirmPassword}
                                    helperText={
                                        <ErrorMessage errors={errors} name="confirmPassword" />
                                    }
                                    inputProps={{ 'data-testid': 'confirm-password' }}
                                    data-testid="confirm-password"
                                    onChange={clearApiError}
                                />
                            </Grid>
                        </Grid>
                        {(apiError === 'email' || apiError === 'password') && (
                            <Grid item xs={12}>
                                <Typography className={classes.error}>
                                    {apiError === 'email' ? (
                                        <Trans i18nKey="emailValidationError">
                                            Email doesn’t match required pattern
                                        </Trans>
                                    ) : null}
                                    {apiError === 'password' ? (
                                        <Trans i18nKey="passwordValidationError">
                                            Password must not be common password
                                        </Trans>
                                    ) : null}
                                </Typography>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Typography variant="h5">
                                <Trans i18nKey="selectSecurityQuestionPrompt">
                                    Select a security question and answer so you may automatically
                                    reset your password if you have problems signing in to your
                                    account.
                                </Trans>
                            </Typography>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                    error={errors.securityQuestion}
                                    data-testid="security-question"
                                >
                                    <InputLabel>
                                        <Trans i18nKey="selectSecurityQuestion">
                                            Select a Security Question
                                        </Trans>
                                    </InputLabel>
                                    <Controller
                                        as={
                                            <Select
                                                inputProps={{
                                                    'data-testid': 'securityQuestion'
                                                }}
                                            >
                                                <MenuItem>&nbsp;</MenuItem>
                                                {regions
                                                    .find(
                                                        (r) =>
                                                            r.value ===
                                                            (region ? region : Region.NA)
                                                    )
                                                    ?.securityQuestions.map((category) => (
                                                        <MenuItem
                                                            key={category.value}
                                                            value={category.i18nKey}
                                                            data-testid="menu-option"
                                                        >
                                                            <Trans i18nKey={category.i18nKey}>
                                                                category.value
                                                            </Trans>
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        }
                                        name="securityQuestion"
                                        control={control}
                                        rules={{ required: isRequired }}
                                    />
                                    <FormHelperText
                                        data-testid={
                                            errors.securityQuestion ? 'security-question-error' : ''
                                        }
                                    >
                                        <ErrorMessage errors={errors} name="securityQuestion" />
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    label={<Trans i18nKey="securityAnswer">Security Answer</Trans>}
                                    name="securityAnswer"
                                    data-testid="securityAnswer"
                                    inputRef={register({
                                        required: isRequired,
                                        minLength: {
                                            value: 6,
                                            message: (
                                                <Trans i18nKey="invalidSecurityAnswer">
                                                    Security Answer must be greater than 5
                                                    characters
                                                </Trans>
                                            )
                                        }
                                    })}
                                    error={errors?.securityAnswer}
                                    helperText={
                                        <ErrorMessage errors={errors} name="securityAnswer" />
                                    }
                                    onChange={clearApiError}
                                />
                            </Grid>
                        </Grid>
                        {apiError === 'security' && (
                            <Grid item xs={12}>
                                <Typography className={classes.error}>
                                    <Trans i18nKey="securityAnswerValidationError">
                                        The security question answer is too weak, answer must not be
                                        part of the question.
                                    </Trans>
                                </Typography>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Typography component="div">
                                <input
                                    type="checkbox"
                                    name="terms"
                                    placeholder="Terms and Conditions"
                                    ref={register({ required: isRequired })}
                                    data-testid="terms-checkbox"
                                    className={classes.checkbox}
                                />
                                <label htmlFor="terms">
                                    <Trans i18nKey="iAgree">I Agree to the</Trans>&nbsp;
                                    <Link
                                        url="https://www.ball.com/na/additional-site-content/special-pages/privacy-statement"
                                        underline="none"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        data-testid="ballHome"
                                    >
                                        <Trans i18nKey="termsOfUse">
                                            Terms of Use and Privacy Policies
                                        </Trans>
                                    </Link>
                                </label>
                                {errors.terms && (
                                    <p
                                        className={classes.error}
                                        data-testid={errors.terms ? 'terms-error' : ''}
                                    >
                                        <ErrorMessage errors={errors} name="terms" />
                                    </p>
                                )}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Recaptcha onVerifyCaptcha={onVerifyCaptcha} value={captchaToken} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component="div">
                                <div className={classes.emailOptOutContainer}>
                                    <input
                                        type="checkbox"
                                        name="emailOptOut"
                                        ref={register}
                                        data-testid="terms-emailOptOut"
                                        className={classes.checkbox}
                                    />
                                    <label htmlFor="emailOptOut">
                                        <Trans i18nKey="emailOptOut1">
                                            By submitting this form, you are subscribing to receive
                                            the latest news and updates straight to your inbox.
                                        </Trans>
                                        <br />
                                        <Trans i18nKey="emailOptOut2">
                                            Ball Corporation may also contact you from time to time
                                            about our products and services as detailed in our
                                        </Trans>
                                        &nbsp;
                                        <Link
                                            url="https://www.ball.com/na/additional-site-content/special-pages/privacy-statement"
                                            underline="none"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <Trans i18nKey="privacyPolicy">Privacy Policy</Trans>
                                        </Link>
                                        .&nbsp;
                                        <br />
                                        <Trans i18nKey="emailOptOut3">
                                            Please check this box if you’d rather not receive these
                                            emails.
                                        </Trans>
                                        &nbsp;
                                    </label>
                                </div>
                                {errors.emailOptOut && (
                                    <p
                                        className={classes.error}
                                        data-testid={errors.terms ? 'emailOptOut-error' : ''}
                                    >
                                        <ErrorMessage errors={errors} name="emailOptOut" />
                                    </p>
                                )}
                            </Typography>
                        </Grid>
                        {submitting && (
                            <Grid container item xs={12} className={classes.spinningLoader}>
                                <CircularProgress />
                            </Grid>
                        )}
                        <Grid
                            container
                            item
                            xs={12}
                            spacing={2}
                            alignItems="center"
                            justify="flex-start"
                        >
                            <Grid item>
                                <Button
                                    type="submit"
                                    variant="outlined"
                                    color="primary"
                                    disabled={submitting}
                                    data-testid={'submit-btn'}
                                >
                                    <Trans i18nKey="submit">Submit</Trans>
                                </Button>
                            </Grid>
                            <Grid item>
                                <Link url="https://ball.com" underline="none">
                                    <Trans i18nKey="cancel">Cancel</Trans>
                                </Link>
                            </Grid>
                        </Grid>
                        {apiError === 'unknown' && (
                            <Grid xs={12}>
                                <Typography className={classes.error}>
                                    <Trans i18nKey="unknownValidationError">
                                        There was an error creating a new user account. Please
                                        contact the help desk.
                                    </Trans>
                                </Typography>
                            </Grid>
                        )}
                        {(userExists || error) && (
                            <Grid xs={12}>
                                <Typography className={classes.error}>
                                    <Trans i18nKey="accountCreated">
                                        This email account is already tied to The Source.
                                    </Trans>
                                    &nbsp;
                                    <Link
                                        component={RouterLink}
                                        to="/login"
                                        underline="none"
                                        className={classes.link}
                                    >
                                        <Trans i18nKey="signInConfirmation">
                                            Please Sign In to The Source and if needed, reset your
                                            password.
                                        </Trans>
                                    </Link>
                                </Typography>
                            </Grid>
                        )}
                        <Grid item xs={12}></Grid>
                    </Grid>
                </form>
            </Container>
        </div>
    );
}
