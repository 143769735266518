import {
    OrderType,
    Order_Status,
    QuantityType,
    RowsOrColumns
} from '../../utility/services/orders-service';
import {
    BULK_UPLOAD_LOADING,
    BULK_UPLOAD_DELIVERY_ERROR,
    BULK_UPLOAD_DELIVERY_LOADED,
    BULK_UPLOAD_DELIVERY_SUBMIT_REVIEW,
    BULK_UPLOAD_DELIVERY_FILE,
    BULK_UPLOAD_DELIVERY_SUBMIT_ORDERS,
    BULK_UPLOAD_RESET_STATE,
    BULK_UPLOAD_PRODUCTS,
    BULK_UPLOAD_DELETE_SHIPMENT,
    BULK_UPLOAD_UPDATE_SHIPMENT,
    BULK_UPLOAD_PALLETS_LOADED,
    BULK_UPLOAD_PALLETS_ERROR,
    BULK_UPLOAD_PALLETS_LOADING,
    BULK_UPLOAD_REMOVE_ORDER
} from '../actions/action-types';
import { WeekQuantity } from './makeit-bulk-atm';
import { DeliveryShipment, ProductToShip, ProductWithPallets } from './shipping-dashboard';

export interface BulkUploadOrder {
    deliveryOrderId?: number;
    status?: Order_Status;
    region?: string;
    createdBy?: string;
    shipments?: BulkUploadShipment[];
    shipToId?: number;
    totalOrderQuantity?: number;
    totalOrderSkus?: number;
    canQuantityPallets?: number;
    canQuantitySKUs?: number;
    endQuantityPallets?: number;
    endQuantitySKUs?: number;
    submittedDate?: string;
    customerPickup?: boolean;
    canQuantityEaches?: number;
    endQuantityEaches?: number;
    totalOrderQuantityEaches?: number;
}

export enum LineErrorCode {
    E000001 = 'E000001',
    E000002 = 'E000002',
    E000003 = 'E000003',
    E000004 = 'E000004',
    E000005 = 'E000005',
    E100001 = 'E100001',
    E100002 = 'E100002',
    E100003 = 'E100003',
    E100004 = 'E100004',
    E100005 = 'E100005',
    E100006 = 'E100006',
    E100007 = 'E100007',
    E100008 = 'E100008',
    E100009 = 'E100009',
    E110001 = 'E110001',
    E110002 = 'E110002',
    E200001 = 'E200001',
    E200002 = 'E200002',
    E200004 = 'E200004',
    E200005 = 'E200005',
    E200006 = 'E200006',
    E200007 = 'E200007',
    E210005 = 'E210005',
    E300005 = 'E300005',
    E300004 = 'E300004',
    E100023 = 'E100023',
    E100013 = 'E100013',
    E100014 = 'E100014'
}

export interface BulkUploadShipment extends DeliveryShipment {
    isDeleted: boolean;
    bulkShipmentId: number;
    shipToId?: number;
}

export interface BulkUploadDeliveryState {
    deliveryMappings?: TemplateMapping[];
    loading: boolean;
    loaded: boolean;
    error?: any;
    orders?: BulkUploadOrder[];
    lineErrors?: DeliveryLineError[];
    file?: File;
    productsWithPallets: ProductWithPallets[];
    products?: ProductToShip[];
    submittedOrders?: BulkUploadOrder[];
    productsLoading: boolean;
    quantityType?: QuantityType;
}

export interface BulkUploadTemplateRequest {
    userName: string;
    orderType: OrderType;
    mappings: TemplateMapping[];
    quantityType?: QuantityType;
    rowsOrColumns?: RowsOrColumns;
    weekQuantities?: WeekQuantity[];
    firstRowOfData?: number;
}

export interface BulkUploadDeliveryReviewRequest {
    userName: string;
    csvData: FormData;
}

export const initialState: BulkUploadDeliveryState = {
    deliveryMappings: undefined,
    lineErrors: undefined,
    productsWithPallets: [],
    orders: undefined,
    loading: false,
    loaded: false,
    productsLoading: false
};

export interface TemplateMapping {
    fieldName?: string;
    columnLetter?: string;
}

export interface DeliveryLineError {
    lineNumber?: number;
    shipToId?: number;
    deliveryDateTime?: string;
    productId?: string;
    availableBalance?: number;
    propertyName?: string;
    errorMessage?: string;
    attemptedValue?: any;
    severity?: string;
    errorCode?: string;
    leadTimeDays?: string;
    maxShipmentPallets?: number;
}

const bulkUploadDelivery = (
    state: BulkUploadDeliveryState = initialState,
    action: { type: string; [x: string]: any }
): BulkUploadDeliveryState => {
    switch (action.type) {
        case BULK_UPLOAD_LOADING:
            return { ...state, loaded: false, loading: true, error: undefined };
        case BULK_UPLOAD_DELIVERY_ERROR:
            return { ...state, loaded: true, loading: false, error: action.error };
        case BULK_UPLOAD_DELIVERY_LOADED:
            return {
                ...state,
                deliveryMappings: action.mappings,
                quantityType: action.quantityType,
                loading: false,
                error: undefined
            };
        case BULK_UPLOAD_PRODUCTS:
            return {
                ...state,
                products: action.products,
                loading: false,
                error: undefined
            };
        case BULK_UPLOAD_DELIVERY_FILE:
            return { ...state, file: action.file };
        case BULK_UPLOAD_DELIVERY_SUBMIT_REVIEW:
            return {
                ...state,
                orders: action.orders,
                loading: false,
                lineErrors: action.lineErrors,
                error: undefined
            };
        case BULK_UPLOAD_DELIVERY_SUBMIT_ORDERS:
            return {
                ...state,
                submittedOrders: action.orders,
                quantityType: action.quantityType,
                loading: false,
                error: undefined
            };
        case BULK_UPLOAD_RESET_STATE:
            return {
                ...initialState
            };
        case BULK_UPLOAD_REMOVE_ORDER:
            return {
                ...state,
                orders: undefined,
                submittedOrders: undefined
            };
        case BULK_UPLOAD_DELETE_SHIPMENT:
            return {
                ...state,
                orders: action.orders
            };
        case BULK_UPLOAD_UPDATE_SHIPMENT:
            return {
                ...state,
                orders: action.orders
            };
        case BULK_UPLOAD_PALLETS_LOADING:
            return {
                ...state,
                productsLoading: true
            };
        case BULK_UPLOAD_PALLETS_LOADED:
            return {
                ...state,
                productsWithPallets: action.products,
                productsLoading: false
            };
        case BULK_UPLOAD_PALLETS_ERROR:
            return {
                ...state,
                error: action.error,
                productsLoading: false
            };
        default:
            return state;
    }
};

export default bulkUploadDelivery;
