import { Grid, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { AuthState } from '../../../../store/reducers/auth';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { MakeItBulkOrder } from '../../../../store/reducers/makeit-bulk-upload';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import {
    ballDrkBlue,
    ballGray,
    blackWeight,
    small,
    white,
    xxl
} from '../../../../themes/globalConstants';
import { getAccountAddress } from '../../../../utility/helpers/address-helpers';
import AccountService from '../../../../utility/services/account-service';
import clsx from 'clsx';
import MakeItBulkUploadConfirmationGrid from './MakeItBulkUploadConfirmationGrid';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsights } from '../../../../utility/AppInsights';
import { ProductType } from '../../../../utility/services/orders-service';
import { formatNumberWithLocale } from '../../../../utility/helpers/formatters';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { getEachesForOrder } from '../../../../utility/helpers/order-helpers';

interface Props {
    order: MakeItBulkOrder;
}

const useStyles = makeStyles((theme) => ({
    main: {
        marginTop: '1em',
        marginBottom: '1em'
    },
    subtitle: {
        color: ballGray
    },
    item: {
        paddingLeft: '.75em'
    },
    poText: {
        fontSize: xxl,
        overflowWrap: 'break-word'
    },
    shipToText: {
        fontSize: small
    },
    action: {
        cursor: 'pointer'
    },
    trashAlign: {
        marginTop: '.9em',
        marginLeft: '.4em',
        textAlign: 'center'
    },
    border: {
        borderRight: `1px solid ${theme.palette.info.light}`
    },
    deleteBtn: {
        marginRight: '2em',
        color: theme.palette.error.main,
        border: `1px solid ${theme.palette.error.main}`,
        '&:hover': {
            color: white,
            backgroundColor: theme.palette.error.main
        }
    },
    sectionFooter: {
        marginTop: `1rem`
    },
    altDescriptionColor: {
        color: ballDrkBlue
    },
    dateDescriptionMain: {
        fontSize: xxl,
        fontWeight: blackWeight
    },
    poNumContainer: {
        paddingRight: '0.5em'
    }
}));

const MakeItBulkUploadConfirmationOrderSection = ({ order }: Props) => {
    const classes = useStyles();
    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { accessToken, userInfo } = useTypedSelector<AuthState>((state) => state.auth);
    const [shipToName, setShipToName] = useState<string>('');
    const [shipToAddress, setShipToAddress] = useState<string>('');
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    useEffect(() => {
        if (order.shipToId && shipToAccounts && shipToAccounts.length > 0) {
            const shipToId = order.shipToId.toString();
            const shipToAccount = shipToAccounts.find((account) => shipToId === account.accountId);
            if (shipToAccount) {
                setShipToName(shipToAccount.name);
                setShipToAddress(getAccountAddress(shipToAccount));
            } else {
                AccountService.getAccount(shipToId, false, accessToken)
                    .then((response) => {
                        const account = response.data.account;
                        setShipToName(account.name);
                        setShipToAddress(getAccountAddress(account));
                    })
                    .catch((error) => {
                        if (appInsights && userInfo) {
                            const user = userInfo.email;
                            const appInsightsError =
                                error.message + ' makeItBulkUploadGetAccount-' + user;
                            appInsights.trackException({
                                error: new Error(appInsightsError),
                                severityLevel: SeverityLevel.Error
                            });
                        }
                    });
            }
        }
    }, [accessToken, order, shipToAccounts, userInfo]);

    function getOrderHeader(order: MakeItBulkOrder) {
        return (
            <>
                <Grid
                    item
                    xs={5}
                    className={clsx(classes.item, classes.border, classes.poNumContainer)}
                    data-testid={'customer-po'}
                >
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="customerPoNumber">CUSTOMER PO #</Trans>
                    </Typography>
                    <Typography className={classes.poText} data-testid="ship-to-name" variant="h4">
                        {order.customerProductionOrderId}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={6}
                    className={clsx(classes.item, classes.border)}
                    data-testid={'ship-to'}
                >
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="shipTo">SHIP TO</Trans>
                    </Typography>
                    <Typography data-testid="ship-to-name" className={classes.shipToText}>
                        {shipToName}
                    </Typography>
                    <Typography data-testid="ship-to-address" className={classes.shipToText}>
                        {shipToAddress}
                    </Typography>
                </Grid>
            </>
        );
    }

    return (
        <Grid container className={classes.main}>
            <Grid container item xs={6} justify="flex-start">
                {getOrderHeader(order)}
            </Grid>
            <Grid container item xs={6} justify="flex-end">
                <Grid item xs={4} className={classes.item}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="canQuantity">Can Quantity</Trans>
                    </Typography>
                    <Typography variant="body1" data-testid="can-quantity">
                        {`${order.canQuantityPallets?.toLocaleString()} PL / ${
                            order.canQuantitySKUs
                        } ${order.canQuantitySKUs!! > 1 ? ' SKUs' : ' SKU'}`}
                    </Typography>
                    <Typography>
                        {formatNumberWithLocale(
                            cultureCode,
                            getEachesForOrder(order, ProductType.Cans)
                        ) + ` ea.`}
                    </Typography>
                </Grid>
                <Grid item xs={3} className={clsx(classes.item)}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="endQuantity">End Quantity</Trans>
                    </Typography>
                    <Typography variant="body1" data-testid="end-quantity">
                        {`${order.endQuantityPallets?.toLocaleString()} PL / ${
                            order.endQuantitySKUs
                        } ${order.endQuantitySKUs!! > 1 ? ' SKUs' : ' SKU'}`}
                    </Typography>
                    <Typography>
                        {formatNumberWithLocale(
                            cultureCode,
                            getEachesForOrder(order, ProductType.Ends)
                        ) + ` ea.`}
                    </Typography>
                </Grid>
            </Grid>
            <MakeItBulkUploadConfirmationGrid items={order.lines} />
        </Grid>
    );
};

export default MakeItBulkUploadConfirmationOrderSection;
