import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class MakeItATMColumns {
    static getColumns = (
        culture: string,
        tabType: 'open' | 'closed',
        hideEditFeatureFlag: boolean
    ) => {
        return [
            createColumn('sortIndex', {
                searchable: false,
                sortable: false,
                filterable: false,
                visible: false,
                dataType: ColumnDataType.Numeric
            }),
            createColumn('atmWeekStart', {
                dataType: ColumnDataType.Date,
                label: TranslationService.getTranslatedText(culture, 'orderWeek'),
                searchable: false,
                sortable: true,
                filterable: true,
            }),
            createColumn('productionOrderId', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('atmWeekStartString', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            }),
            createColumn('searchablePONumber', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'ballProdOrderNumber'),
                visible: true,
                sortable: true,
                filterable: true,
                searchable: true
            }),
            createColumn('productionOrderNumber', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('canQuantityPallets', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'canQuantity'),
                filterable: true,
                sortable: true
            }),
            createColumn('canQuantityEaches', {
                dataType: ColumnDataType.Numeric,
                visible: false,
                filterable: false,
                sortable: false
            }),
            createColumn('canQuantitySKUs', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('endQuantityPallets', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'endQuantity'),
                filterable: true,
                sortable: true
            }),
            createColumn('endQuantityEaches', {
                dataType: ColumnDataType.Numeric,
                visible: false,
                filterable: false,
                sortable: false
            }),
            createColumn('endQuantitySKUs', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('createDate', {
                dataType: ColumnDataType.Date,
                label: TranslationService.getTranslatedText(culture, 'submitted'),
                filterable: true,
                sortable: true,
                searchable: false
            }),
            createColumn('submittedDateString', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            }),
            createColumn('actions', {
                label: TranslationService.getTranslatedText(culture, 'actions'),
                sortable: true,
                visible: tabType === 'open' && !hideEditFeatureFlag
            }),
            createColumn('status', {
                label: TranslationService.getTranslatedText(culture, 'status'),
                dataType: ColumnDataType.String,
                visible: tabType === 'closed',
                sortable: true,
                filterable: true,
                searchable: true
            }),
            createColumn('lastUpdatedDateSortIndex', {
                label: TranslationService.getTranslatedText(culture, 'closed'),
                dataType: ColumnDataType.Numeric,
                visible: tabType === 'closed',
                sortable: true,
                filterable: false,
                searchable: false
            }),
            createColumn('lastUpdatedDateString', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            }),
            createColumn('isBelowAllocation', {
                dataType: ColumnDataType.Boolean,
                visible: false
            }),
            createColumn('productionOrderNumber', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('productionOrderLineNumber', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('isEditable', {
                dataType: ColumnDataType.Boolean,
                visible: false
            }),
            createColumn('tabType', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('searchTerms', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            })
        ];
    };
}
