import {
    MakeItBulkATMLineItem,
    MakeItBulkATMLinesBySnoSku,
    MakeItBulkATMOrder,
    MakeItBulkATMOrderByWeekAndSku,
    SnoSkuAllocationsByWeek,
    SnoSkuPalletQuantity
} from '../../store/reducers/makeit-bulk-atm';
import { MakeItBulkLineItem } from '../../store/reducers/makeit-bulk-upload';
import moment, { Moment } from 'moment';
import { getMakeItLeadTime, getMoqFeeCeiling, getMoqFeeFloor } from './production-order-helpers';
import { isProductEndType } from './order-helpers';

export type PalletQuantityState =
    | 'valid'
    | 'minimumOrderQuantityError'
    | 'zeroError'
    | 'feeWarning'
    | 'undefinedQuantity'
    | undefined;

export type AllocationQuantityState =
    | 'valid'
    | 'belowAllocationWarning'
    | 'aboveAllocationError'
    | undefined;

export type AgedQuantityState = 'aged' | 'aging' | 'none' | undefined;
export type ChildLinePalletQuantityState = 'valid' | 'zeroError' | undefined;
export type FeeStructure = 'hasFees' | 'none';

export function getPalletQuantityKey(key: string | number): string {
    return `product[${key}].palletQuantity`;
}

export function validateIsInteger(quantity: any) {
    return !!parseInt(quantity);
}

export function validateGreaterThanZero(quantity: any): boolean {
    quantity = parseInt(quantity);
    return quantity && quantity > 0;
}

export function validateFeeWarning(
    quantity: any,
    feeStructure: FeeStructure,
    moqFeeFloor?: number,
    moqFeeCeiling?: number
): boolean {
    quantity = parseInt(quantity);
    if (quantity && feeStructure === 'hasFees' && moqFeeFloor && moqFeeCeiling) {
        return !(moqFeeFloor <= quantity && quantity <= moqFeeCeiling);
    } else {
        return true;
    }
}

export function validatePalletMinimumOrder(quantity: any, minimumOrderQuantity: number) {
    if (!minimumOrderQuantity) {
        return true;
    }
    quantity = parseInt(quantity);
    return quantity && quantity >= minimumOrderQuantity;
}

export const evaluatePalletQuantityValid = (
    quantity: any,
    minimumOrderQuantity: number,
    feeStructure: FeeStructure,
    moqFeeFloor?: number,
    moqFeeCeiling?: number
): PalletQuantityState => {
    let state: PalletQuantityState = 'valid';
    if (!validateFeeWarning(quantity, feeStructure, moqFeeFloor, moqFeeCeiling)) {
        return 'feeWarning';
    }
    if (quantity === undefined) {
        return 'undefinedQuantity';
    }
    if (!validateGreaterThanZero(quantity)) {
        return 'zeroError';
    }
    if (
        !validateIsInteger(quantity) ||
        !validatePalletMinimumOrder(quantity, minimumOrderQuantity)
    ) {
        return 'minimumOrderQuantityError';
    }
    return state;
};

export const evaluateAllocationQuantityValid = (
    order: MakeItBulkATMLinesBySnoSku
): AllocationQuantityState => {
    if (!!order.lines.length && order.lines.length > 0) {
        if (!!order.lines[0].type) {
            if (isProductEndType(order.lines[0].type)) {
                return 'valid';
            }
        }
    }
    if (order.allocationAmount !== undefined) {
        if (order.totalPalletQuantity < order.allocationAmount) {
            return 'belowAllocationWarning';
        }
        if (order.totalPalletQuantity > order.allocationAmount) {
            return 'aboveAllocationError';
        }
    }
    return 'valid';
};

export const evaluateChildLinePalletQuantityValid: (
    quantity: any
) => ChildLinePalletQuantityState = (quantity) => {
    let state: ChildLinePalletQuantityState = 'valid';
    if (!validateGreaterThanZero(quantity)) {
        return 'zeroError';
    }
    return state;
};

export const getFeeStructure: (MakeItBulkLineItem) => FeeStructure = (item) => {
    const hasFees = item.moqFees && item.moqFees.length > 0;
    switch (true) {
        case hasFees:
            return 'hasFees';
        default:
            return 'none';
    }
};

export function validateLineRequestDate(line: MakeItBulkLineItem) {
    return !moment(line.requestedDate).isBefore(line.firstAvailableDate);
}

/**
 * Evaluates validity of lines' pallet quantity and request dates.
 * @param lines
 */
export function getInvalidLines(
    lines: Array<MakeItBulkLineItem>,
    childLines?: boolean
): Array<MakeItBulkLineItem> {
    return lines.filter((line) => {
        let evalPalQty = evaluatePalletQuantityValid(
            line.palletQuantity,
            line.minimumOrderQuantity!,
            getFeeStructure(line),
            getMoqFeeFloor(line.moqFees),
            getMoqFeeCeiling(line.moqFees)
        );

        let validRequestedDate = false;
        if (!line.splitOrder) {
            validRequestedDate = !validateLineRequestDate(line);
        }

        if (
            validRequestedDate ||
            (!childLines && evalPalQty === 'minimumOrderQuantityError') ||
            evalPalQty === 'zeroError' ||
            (!childLines && !line.isCustomerProductIdDistinct)
        ) {
            return true;
        }
        return false;
    });
}

export const handleSplit = (
    item: MakeItBulkLineItem,
    subRows: MakeItBulkLineItem[],
    counter: number,
    selectedDate?: string
) => {
    // Create a new subrow object
    const newRow: MakeItBulkLineItem = {
        productId: item.productId,
        productSku: item.productSku,
        palletQuantity: 0,
        splitOrder: true,
        leadTimeWeeks: item.leadTimeWeeks ?? getMakeItLeadTime(item),
        requestedDate: selectedDate,
        firstAvailableDate: item.firstAvailableDate,
        quantityPerPallet: item.quantityPerPallet
    };

    let rows = [] as MakeItBulkLineItem[];
    let palletQuantity: number = 0;
    // If the item doesn't have any subrows
    if (subRows.length === 0) {
        let firstRow = newRow;
        firstRow.index = counter;

        let secondRow = Object.assign({}, newRow);
        secondRow.index = firstRow.index + 1;
        // Update counter because of key logic on HTML
        counter += 2;
        rows = [firstRow, secondRow];
    } else if (subRows.length >= 2) {
        let row = Object.assign({}, newRow);
        row.index = counter + 1;
        rows = [...subRows, row];
        rows.forEach((row) => {
            palletQuantity += row.palletQuantity ?? 0;
        });
        counter += 1;
    }

    return {
        childLines: rows,
        palletQuantity: palletQuantity,
        requestedDate: selectedDate,
        counter: counter
    };
};

/**
 * Called only once on initial load
 * @param orders
 * @param allocations
 * @returns
 */
export const groupOrdersBySnoSku = (
    orders: MakeItBulkATMOrder[],
    allocations: SnoSkuAllocationsByWeek[]
) => {
    return orders.map((order) => {
        let uniqueSnoSkus: string[] = [];
        let allocationsForWeekShipTo: SnoSkuAllocationsByWeek[] = allocations.filter(
            (allocation) =>
                allocation.shipToId === order.shipToId &&
                allocation.ignoreAllocation === false &&
                moment(allocation.week).format('MM/DD/YYYY') ===
                    moment(order.weekStart).format('MM/DD/YYYY')
        );

        order.lines.forEach((line) => {
            if (!uniqueSnoSkus.includes(line.snoSku)) {
                uniqueSnoSkus.push(line.snoSku);
            }
        });

        let linesBySnoSku: MakeItBulkATMLinesBySnoSku[] = uniqueSnoSkus.map((snoSku) => {
            const matchingAllocationIndex = allocationsForWeekShipTo.findIndex(
                (allocation) => allocation.snoSku === snoSku
            );
            // Remove match so we can keep track of empty allocations
            let matchingAllocation: SnoSkuAllocationsByWeek | undefined = undefined;
            if (matchingAllocationIndex >= 0) {
                matchingAllocation = allocationsForWeekShipTo.splice(matchingAllocationIndex, 1)[0];
            }

            return {
                snoSku: snoSku,
                lines: Array<MakeItBulkATMLineItem>(),
                weekStart: order.weekStart,
                isActive: true,
                totalPalletQuantity: getOtherOrdersWeeklyPalletQuantity(
                    snoSku,
                    order.otherOrdersWeeklyPalletQuantityBySnoSku
                ),
                allocationAmount: matchingAllocation ? matchingAllocation.quantity : undefined
            };
        });

        order.lines.forEach((line) => {
            let orderItem = linesBySnoSku.find((lineBySku) => lineBySku.snoSku === line.snoSku);
            line.supplyPlanWeeks = allocations
                .filter(
                    (allocation) =>
                        allocation.snoSku === line.snoSku &&
                        allocation.shipToId === order.shipToId &&
                        allocation.quantity > 0
                )
                .map((allocation) => allocation.week);

            if (orderItem) {
                orderItem.lines.push(line);
                orderItem.totalPalletQuantity +=
                    !line.deleted && line.palletQuantity ? line.palletQuantity : 0;
            }
        });

        // append empty product groups with allocations
        // exclude if totalPalletQuantity > 0 because the product is included in another
        // prod order for the same week
        allocationsForWeekShipTo.forEach((allocation) => {
            const otherOrdersWeeklyPalletQuantity = getOtherOrdersWeeklyPalletQuantity(
                allocation.snoSku,
                order.otherOrdersWeeklyPalletQuantityBySnoSku
            );
            if (allocation.quantity > 0 && otherOrdersWeeklyPalletQuantity === 0) {
                linesBySnoSku.push({
                    snoSku: allocation.snoSku,
                    lines: [],
                    weekStart: allocation.week,
                    isActive: true,
                    totalPalletQuantity: otherOrdersWeeklyPalletQuantity,
                    allocationAmount: allocation.quantity,
                    initiallyEmpty: true
                });
            }
        });

        return {
            weekStart: order.weekStart,
            shipToId: order.shipToId,
            linesBySnoSku: linesBySnoSku,
            state: 'valid',
            productionOrderId: order.productionOrderId,
            productionOrderNumber: order.productionOrderNumber
        };
    }) as MakeItBulkATMOrderByWeekAndSku[];
};

/**
 * Returns palletQuantity for a snoSku
 * from other orders within the same atmWeekStart
 * which are aggregated in otherOrdersWeeklyPalletQuantityBySnoSku
 * @param snoSku
 * @param otherOrdersWeeklyPalletQuantityBySnoSku
 */
const getOtherOrdersWeeklyPalletQuantity = (
    snoSku: string,
    otherOrdersWeeklyPalletQuantityBySnoSku: SnoSkuPalletQuantity[] | undefined
): number => {
    let otherOrdersWeeklyPalletQuantity = 0;
    const snoSkuPalletQuantity = otherOrdersWeeklyPalletQuantityBySnoSku?.filter(
        (qtyBySnoSku) => qtyBySnoSku.snoSku === snoSku
    );
    if (snoSkuPalletQuantity?.length === 1) {
        otherOrdersWeeklyPalletQuantity = snoSkuPalletQuantity[0].palletQuantity;
    }
    return otherOrdersWeeklyPalletQuantity;
};

/**
 * Called any time atmOrders object is updated
 * Checks for any differences in objects and updates, while
 * maintaining sno sku specific data
 * @param atmOrders
 * @param ordersBySnoSku
 */
export const regroupOrdersBySnoSku = (
    atmOrders: MakeItBulkATMOrder[],
    ordersBySnoSku: MakeItBulkATMOrderByWeekAndSku[],
    allocations: SnoSkuAllocationsByWeek[]
) => {
    let ordersBySnoSkuToUpdate = JSON.parse(
        JSON.stringify(ordersBySnoSku)
    ) as MakeItBulkATMOrderByWeekAndSku[];
    const matchingSnoSkus: string[] = [];
    atmOrders.forEach((atmOrder) => {
        const matchingOrderBySnoSkuIndex = ordersBySnoSkuToUpdate.findIndex(
            (orderSku) =>
                atmOrder.weekStart === orderSku.weekStart && atmOrder.shipToId === orderSku.shipToId
        );
        if (matchingOrderBySnoSkuIndex > -1) {
            const matchingOrderBySnoSku = ordersBySnoSkuToUpdate[matchingOrderBySnoSkuIndex];
            clearOrderLinesAndPalletTotal(matchingOrderBySnoSku);
            atmOrder.lines.forEach((line) => {
                const matchingLinesBySnoSkuIndex = matchingOrderBySnoSku?.linesBySnoSku.findIndex(
                    (lineSku) => lineSku.snoSku === line.snoSku
                );
                if (matchingLinesBySnoSkuIndex !== -1) {
                    matchingOrderBySnoSku.linesBySnoSku[matchingLinesBySnoSkuIndex].lines.push(
                        line
                    );
                    let otherOrdersWeeklyPalletQuantity = 0;
                    // include the snosku pallet quantity from other orders only once
                    if (!matchingSnoSkus.includes(line.snoSku)) {
                        matchingSnoSkus.push(line.snoSku);
                        const initialLinesLength =
                            ordersBySnoSku[matchingOrderBySnoSkuIndex].linesBySnoSku[
                                matchingLinesBySnoSkuIndex
                            ].lines.length;
                        otherOrdersWeeklyPalletQuantity =
                            initialLinesLength &&
                            getOtherOrdersWeeklyPalletQuantity(
                                line.snoSku,
                                atmOrder.otherOrdersWeeklyPalletQuantityBySnoSku
                            );
                    }
                    matchingOrderBySnoSku.linesBySnoSku[
                        matchingLinesBySnoSkuIndex
                    ].totalPalletQuantity +=
                        !line.deleted && line.palletQuantity
                            ? line.palletQuantity + otherOrdersWeeklyPalletQuantity
                            : otherOrdersWeeklyPalletQuantity;
                } else {
                    // in case there is a matching allocation with zero quantity
                    const matchingAllocation = allocations.find(
                        (allocation) =>
                            allocation.snoSku === line.snoSku &&
                            allocation.week === atmOrder.weekStart &&
                            allocation.shipToId === atmOrder.shipToId &&
                            allocation.ignoreAllocation === false
                    );
                    const otherOrdersWeeklyPalletQuantity = getOtherOrdersWeeklyPalletQuantity(
                        line.snoSku,
                        atmOrder.otherOrdersWeeklyPalletQuantityBySnoSku
                    );
                    let newLineBySnoSku = {
                        snoSku: line.snoSku,
                        lines: [line],
                        weekStart: line.weekStart,
                        isActive: true,
                        totalPalletQuantity:
                            !line.deleted && line.palletQuantity
                                ? line.palletQuantity + otherOrdersWeeklyPalletQuantity
                                : otherOrdersWeeklyPalletQuantity,
                        allocationAmount: matchingAllocation?.quantity
                    } as MakeItBulkATMLinesBySnoSku;
                    matchingOrderBySnoSku?.linesBySnoSku.push(newLineBySnoSku);
                }
            });
        }
    });

    let filteredOrder = ordersBySnoSkuToUpdate.map((order) => {
        let filteredLines = order.linesBySnoSku.map((lineBySnoSku) => {
            return {
                ...lineBySnoSku,
                lines: lineBySnoSku.lines.filter((line) => line.snoSku === lineBySnoSku.snoSku)
            };
        });
        return { ...order, linesBySnoSku: filteredLines };
    });

    return filteredOrder;
};

const clearOrderLinesAndPalletTotal = (orderBySnoSku: MakeItBulkATMOrderByWeekAndSku) => {
    orderBySnoSku.linesBySnoSku.forEach((order) => {
        if (order.lines.length > 0) {
            order.totalPalletQuantity = 0;
            order.lines = [];
        }
    });
};

export interface DateProperties {
    fullDate: string;
    week: string;
    year: string;
}

export interface AtmOrderQuantities {
    canPallets: number;
    canSkus: number;
    canEaches: number;
    endPallets: number;
    endSkus: number;
    endEaches: number;
}

export const getDateProperties = (fullDate: Moment) => {
    const week = moment(fullDate).format('MM/DD');
    const year = moment(fullDate).format('YYYY');

    return {
        fullDate: fullDate.format('MM/DD/YYYY'),
        week: week,
        year: year
    } as DateProperties;
};

export const formatProductAttributes = (attributes: any[]) => {
    const definedAttributes = attributes.filter(
        (attribute) => attribute !== undefined && attribute !== ''
    );
    let description: string = '';
    definedAttributes.forEach((attr, index) => {
        description = `${description}${attr}`;
        if (index + 1 < definedAttributes.length) {
            description = `${description} / `;
        }
    });
    return description;
};

export const getSnoSkuDescription = (isCanType: boolean, product: any) => {
    return isCanType
        ? formatProductAttributes([product?.unit, product?.shape, product?.neckDiameter])
        : formatProductAttributes([product?.size, product?.endProfile]);
};

export const getSnoSkuAttributes = (isCanType: boolean, product: any) => {
    return isCanType
        ? formatProductAttributes([
              getBasecoat(product?.basecoat),
              product?.specialtyInk,
              product?.retort
          ])
        : formatProductAttributes([
              product?.shellGauge,
              getShellCollor(product?.shellColor),
              getTabIndicator(product?.tabIndicator),
              product?.retort
          ]);
};

export const getBasecoat = (basecoat?: string) => {
    return basecoat === 'BC' ? 'Basecoat' : undefined;
};

export const getTabIndicator = (tabIndicator?: string) => {
    switch (tabIndicator) {
        case 'CLRTAB':
        case 'CLRSHELL':
        case 'CLRSHTB':
            return 'Color';
        default:
            return undefined;
    }
};

export const getShellCollor = (shellColor?: string) => {
    switch (shellColor) {
        case '200':
        case '202':
        case '204':
        case '206':
        case '209':
        case '300':
            return 'Silver, Gold';
        case 'BLK':
            return 'Black';
        case 'BLU':
            return 'Blue';
        case 'BRZ':
            return 'Bronze';
        case 'FUS':
            return 'Fiusha';
        case 'GLD':
            return 'Gold';
        case 'GRN':
            return 'Green';
        case 'LMG':
            return 'Lime Green';
        case 'ORA':
            return 'Orange';
        case 'RED':
            return 'Red';
        case '28M':
        case '38M':
        case 'SLV':
            return 'Silver';
        case 'TEL':
            return 'Teal';
        case 'VIO':
            return 'Violet';
        case 'WHT':
            return 'White';
        case 'YEL':
            return 'Yellow';
        default:
            return undefined;
    }
};

export const getActiveProducts = (
    shipToId: string,
    products?: MakeItBulkLineItem[],
    order?: MakeItBulkATMLinesBySnoSku
) => {
    let activeProducts: MakeItBulkLineItem[] = [];
    if (products && order) {
        activeProducts = products.filter((product) => {
            if (
                product.snoSku === order.snoSku &&
                product.destinations &&
                product.destinations.filter(
                    (destination) => destination.shipToId.toString() === shipToId
                ).length > 0
            ) {
                return product;
            } else {
                return undefined;
            }
        });
    }
    return activeProducts;
};

export const addQuantitiesFromOtherOrders = (
    atmOrder: MakeItBulkATMOrder | undefined,
    products: MakeItBulkATMOrderByWeekAndSku[],
    existingProducts?: MakeItBulkATMLinesBySnoSku[]
): MakeItBulkATMOrderByWeekAndSku[] => {
    return products.map((product) => {
        return {
            ...product,
            linesBySnoSku: product.linesBySnoSku.map((line) => {
                const matchingExistingProducts = existingProducts?.filter(
                    (p) => p.snoSku === line.snoSku
                );
                const existingTotalPalletQuantity =
                    matchingExistingProducts && matchingExistingProducts.length > 0
                        ? matchingExistingProducts.reduce(
                              (quantity, product) => quantity + product.totalPalletQuantity,
                              0
                          )
                        : 0;

                const matchingNewProducts = atmOrder?.lines.filter((o) => o.snoSku === line.snoSku);

                const initialValue = 0;
                const newTotalPalletQuantity =
                    matchingNewProducts &&
                    matchingNewProducts.reduce((previousValue, currentValue) => {
                        return (
                            previousValue +
                            (currentValue.palletQuantity && !currentValue.deleted
                                ? currentValue.palletQuantity
                                : 0)
                        );
                    }, initialValue);
                return {
                    ...line,
                    totalPalletQuantity: existingTotalPalletQuantity + newTotalPalletQuantity!
                };
            })
        };
    });
};

export const getAgingQuantityState = () => {};
