import React, { useEffect, useState } from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import Link from '../../../reusable/atoms/Link';
import { CSVLink } from 'react-csv';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { ballBlue, small, medium, regularWeight } from '../../../../themes/globalConstants';
import Modal from '../../../reusable/molecules/Modal';
import moment from 'moment';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { TradeItRequestState } from '../../../../store/reducers/trade-it';
import TradeItConfirmationPDF from './TradeItConfirmationPDF';

const useStyles = makeStyles((theme) => ({
    label: {
        textTransform: 'uppercase',
        fontSize: small,
        paddingTop: '0.5em'
    },
    printLink: {
        fontSize: medium,
        fontWeight: regularWeight,
        width: '8.75em',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    link: {
        fontSize: small,
        fontWeight: regularWeight,
        width: '8.75em',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    icon: {
        color: ballBlue
    },
    iconContainer: {
        display: 'flex',
        marginTop: '0.75em'
    }
}));

const TradeItConfirmationShareLinks = () => {
    const classes = useStyles();
    const { submittedRequest, products } = useTypedSelector<TradeItRequestState>(
        (state) => state.tradeItRequest
    );
    const [openPdf, setOpenPdf] = useState(false);
    const [csvHeaders, setCSVHeaders] = useState([] as any[]);
    const [fileName, setFileName] = useState('trade-it-request.csv');
    const { t } = useTranslation();

    const printForm = () => {
        setOpenPdf(true);
    };

    const onClose = () => {
        setOpenPdf(false);
    };

    useEffect(() => {
        if (submittedRequest) {
            setFileName(
                'trade-it-request-' +
                    moment(submittedRequest.submittedDate).format('MM-DD-YYYY') +
                    '.csv'
            );
            setCSVHeaders([
                {
                    label: t(
                        'tradeItRequestOrderNumber',
                        'Trade It Request Order Number'
                    ).toUpperCase(),
                    key: 'requestNumber'
                },
                {
                    label: t('originalShipTo', 'Original Ship To').toUpperCase(),
                    key: 'accountFrom'
                },
                {
                    label: t('receivingShipTo', 'Receiving Ship To').toUpperCase(),
                    key: 'accountTo'
                },
                {
                    label: t(
                        'originalShipToContactEmail',
                        'Original Ship To Contact Email'
                    ).toUpperCase(),
                    key: 'accountFromContact'
                },
                {
                    label: t(
                        'receivingShipToContactEmail',
                        'Receiving Ship To Contact Email'
                    ).toUpperCase(),
                    key: 'accountToContact'
                },
                { label: t('submittedDate', 'Submitted Date').toUpperCase(), key: 'submittedDate' },
                { label: t('productId', 'Ball Product ID').toUpperCase(), key: 'ballProductId' },
                {
                    label: t('customerProductId', 'Customer Product ID').toUpperCase(),
                    key: 'customerProductId'
                },
                {
                    label:
                        submittedRequest.accountFrom +
                        ' ' +
                        t('previousBalance', 'Previous Balance').toUpperCase(),
                    key: 'accountFromOriginalBalance'
                },
                {
                    label:
                        submittedRequest.accountTo +
                        ' ' +
                        t('previousBalance', 'Previous Balance').toUpperCase(),
                    key: 'accountToOriginalBalance'
                },
                {
                    label: t('requestedTradeQuantity', 'Requested Trade Quantity').toUpperCase(),
                    key: 'quantity'
                },
                {
                    label:
                        submittedRequest.accountFrom +
                        ' ' +
                        t('updatedBalance', 'Updated Balance').toUpperCase(),
                    key: 'accountFromNewBalance'
                },
                {
                    label:
                        submittedRequest.accountTo +
                        ' ' +
                        t('updatedBalance', 'Updated Balance').toUpperCase(),
                    key: 'accountToNewBalance'
                }
            ]);
        }
    }, [submittedRequest]);

    interface CSVRow {
        requestNumber?: number;
        accountFrom?: string;
        accountTo?: string;
        accountFromContact?: string;
        accountToContact?: string;
        submittedDate?: string;
        ballProductId?: string;
        customerProductId?: string;
        accountFromOriginalBalance?: string;
        accountToOriginalBalance?: string;
        quantity?: string;
        accountFromNewBalance?: string;
        accountToNewBalance?: string;
    }

    const csvArray = () => {
        let rows: any[] = [];
        if (!!submittedRequest) {
            submittedRequest?.requestLines.forEach((product) => {
                let displayId = '-';
                if (!!products) {
                    displayId =
                        products.find((prod) => prod.productSku === product.productSku)
                            ?.displayId ?? '-';
                }
                const accountFromNewBalance = product.accountFromOriginalBalance - product.quantity;
                const accountToNewBalance = product.accountToOriginalBalance + product.quantity;
                const row: CSVRow = {
                    requestNumber: submittedRequest.tradeRequestId,
                    accountFrom: submittedRequest.accountFrom,
                    accountTo: submittedRequest.accountTo,
                    accountFromContact: submittedRequest.fillerContactFrom,
                    accountToContact: submittedRequest.fillerContactTo,
                    submittedDate: moment(submittedRequest.submittedDate).format('MM/DD/YYYY'),
                    ballProductId: product.productSku,
                    customerProductId: displayId,
                    accountFromOriginalBalance: product.accountFromOriginalBalance + ' PL',
                    accountToOriginalBalance: product.accountToOriginalBalance + ' PL',
                    quantity: product.quantity + ' PL',
                    accountFromNewBalance: accountFromNewBalance + ' PL',
                    accountToNewBalance: accountToNewBalance + ' PL'
                };
                rows.push(row);
            });
        }

        return rows;
    };

    const csvContents = csvArray();

    return (
        <Grid container item xs={12} spacing={2} justify="flex-end">
            <Grid item data-testid="share-btn">
                <Typography variant="h6" className={classes.label}>
                    <Trans i18nKey="share">share</Trans>
                </Typography>
            </Grid>
            <Grid item>
                <Link
                    className={classes.printLink}
                    underline="always"
                    data-testid="print-btn"
                    onClick={printForm}
                >
                    <Trans i18nKey="print">Print</Trans>
                </Link>
                <PrintOutlinedIcon fontSize="small" className={classes.icon} />
            </Grid>
            <Grid className={classes.iconContainer}>
                <CSVLink
                    className={classes.link}
                    underline="always"
                    data-testid="csv-download-btn"
                    headers={csvHeaders}
                    data={csvContents}
                    filename={fileName}
                >
                    <Typography>
                        <Trans i18nKey="downloadCSV">Download .CSV</Trans>
                    </Typography>
                </CSVLink>
                <GetAppOutlinedIcon fontSize="small" className={classes.icon} />
            </Grid>
            <Modal
                open={openPdf}
                title={t('tradeIt', 'Trade It')}
                close={onClose}
                closeIcon={true}
                fullScreen={true}
            >
                <TradeItConfirmationPDF title={t('tradeIt', 'Trade It')} />
            </Modal>
        </Grid>
    );
};

export default TradeItConfirmationShareLinks;
