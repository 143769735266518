import React, { useEffect, useState } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import NavMenu from './NavMenu';
import { useTranslation, Trans } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import Link from '../atoms/Link';
import Logo from '../atoms/Logo';
import NavTile from './NavTile';
import { boldWeight, containerMaxWidth, medium } from '../../../themes/globalConstants';
import Button from '../atoms/Button';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { Persona, hasPersonas } from '../../../utility/auth/useSecurity';
import { useDispatch } from 'react-redux';
import { AuthState } from '../../../store/reducers/auth';
import { CustomerContextState, PaymentTerms } from '../../../store/reducers/customer-context';
import { getShipToAccounts } from '../../../store/actions/customer-context';
import DiscoverBallNav from './DiscoverBallNav';
import ProductsNav from './ProductsNav';
import SiteAdminNav from './SiteAdminNav';
import OrderingNav from './OrderingNav';
import UserAdminNav from './UserAdminNav';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { Region } from '../../../store/reducers/graphic-intake';
import NavItem from './NavItem';
import {
    selectHasOrderingMakePermissions,
    selectHasPricingBillTo,
    selectIsOnlyGraphicVendor
} from '../../../store/selectors';

const useStyles = makeStyles((theme) => ({
    appBar: {
        marginTop: '2em'
    },
    homeButton: {
        marginRight: theme.spacing(1)
    },
    container: {
        maxWidth: containerMaxWidth,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    title: {
        flexGrow: 1,
        display: 'block'
    },
    contentTitle: {
        fontSize: 13,
        fontWeight: boldWeight,
        letterSpacing: 1,
        color: theme.palette.secondary.main
    },
    link: {
        fontSize: 11,
        fontWeight: boldWeight,
        color: theme.palette.secondary.main,
        letterSpacing: '1px',
        textTransform: 'uppercase'
    },
    modal: {
        '& .MuiPaper-root': {
            maxWidth: '40.5em'
        },
        '& .MuiDialog-paperScrollPaper': {
            borderRadius: 'unset',
            overflow: 'hidden'
        },
        '& .MuiDialogTitle-root': {
            justifyContent: 'center',
            marginLeft: '2.5em'
        }
    },
    menuItems: {
        overflow: 'visible',
        width: 'fit-content'
    },
    linkMenuItem: {
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    homeBtn: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
    },
    dashboardLink: {
        fontSize: medium,
        fontWeight: boldWeight,
        letterSpacing: '1px',
        textTransform: 'capitalize',
        color: theme.palette.common.black
    },
    button: {
        fontSize: medium,
        fontWeight: boldWeight,
        letterSpacing: '1px',
        textTransform: 'capitalize',
        color: theme.palette.common.black
    }
}));

const CustomerNavBar = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    useTranslation();

    const paymentIcon = '/Payment_Icon.svg';
    const baseContentLink = `${process.env.PUBLIC_URL}/content/NA/Pages/`;
    const isPricingCustomer = useTypedSelector<boolean>(selectHasPricingBillTo);
    const hasOnlyVendorPermissions = useTypedSelector<boolean>(selectIsOnlyGraphicVendor);

    const auth = useTypedSelector<AuthState>((state) => state.auth);
    const { permissions } = useTypedSelector((state) => state.auth);
    const { accounts, shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { regionCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const [ciaAccount, setCiaAccount] = useState<boolean>(false);
    const [isVendor, setIsVendor] = useState(false);
    const [isUserAdmin, setIsUserAdmin] = useState<boolean>(false);
    const [showJoiningBall, setShowJoiningBall] = useState<boolean>(false);
    const [hasCommMgtPersona, setHasCommMgtPersona] = useState<boolean>(false);
    //Hiding certain functionality for Capitol Release
    const hideKBMaxFunctionality = false;
    const hasOrderingMakePermissions = useTypedSelector<boolean>(selectHasOrderingMakePermissions);

    useEffect(() => {
        if (permissions) {
            const hasVendorPersona = hasPersonas([Persona.GraphicsVendor], auth);
            setIsVendor(hasVendorPersona);

            const hasUserAdminPersona = hasPersonas([Persona.AccountAdmin], auth);
            setIsUserAdmin(hasUserAdminPersona);

            const isCommMgtPersona = hasPersonas([Persona.CommunicationManagement], auth);
            setHasCommMgtPersona(isCommMgtPersona);

            const hasJoiningBallPerson = hasPersonas(
                [
                    Persona.JoiningAdmin,
                    Persona.JoiningBillingAndShippingAddresses,
                    Persona.JoiningCreditApplication,
                    Persona.JoiningSalesAndUseTax,
                    Persona.JoiningTermsAndConditions
                ],
                auth
            );
            setShowJoiningBall(hasJoiningBallPerson);

            if (!accounts?.length) {
                dispatch(getShipToAccounts());
            }
        }
    }, [dispatch, auth, permissions, accounts]);

    useEffect(() => {
        if (shipToAccounts) {
            for (let shipTo of shipToAccounts) {
                if (shipTo.paymentTerms === PaymentTerms.CIA) {
                    setCiaAccount(true);
                    return;
                }
            }
        }

        setCiaAccount(false);
    }, [shipToAccounts]);

    return (
        <Grid container item>
            <Logo />
            {(!isVendor || !hasOnlyVendorPermissions) && (
                <>
                    {/* DASHBOARD */}
                    <Button className={classes.homeBtn} data-testid="dashboard-link">
                        <Link
                            component={RouterLink}
                            to="/dashboard"
                            underline="none"
                            aria-label="customer dashboard"
                            className={classes.dashboardLink}
                        >
                            <Trans i18nKey="dashboard">Dashboard</Trans>
                        </Link>
                    </Button>
                    {/* ORDERING */}
                    {regionCode === Region.NA && <OrderingNav />}
                    {/* PRODUCTS */}
                    <ProductsNav hideKBMaxFunctionality={hideKBMaxFunctionality} />
                    {/* PAYABLES */}
                    {ciaAccount && regionCode === Region.NA && (
                        <NavMenu menuName={<Trans i18nKey="payables">Payables</Trans>}>
                            <Grid container>
                                <Grid item xs={4}>
                                    <NavTile
                                        link="/pending-payments"
                                        translationKey="paymentsSummary"
                                        defaultText="Payments Summary"
                                        icon={paymentIcon}
                                        isProduct={false}
                                    />
                                </Grid>
                            </Grid>
                        </NavMenu>
                    )}
                    {/* USER ADMIN */}
                    {(isPricingCustomer || hasOrderingMakePermissions || !isVendor) &&
                        regionCode === Region.NA && (
                            <UserAdminNav isUserAdmin={isUserAdmin} isVendor={isVendor} />
                        )}
                    {/* DISCOVER BALL/GLP */}
                    <DiscoverBallNav
                        baseContentLink={baseContentLink}
                        hideKBMaxFunctionality={hideKBMaxFunctionality}
                    />
                    {showJoiningBall && regionCode === Region.NA && (
                        <NavItem
                            menuName={<Trans i18nKey="joiningBall">Joining Ball</Trans>}
                            link="/onboard"
                        />
                    )}
                    {/* SITE ADMIN */}
                    {hasCommMgtPersona && <SiteAdminNav />}
                </>
            )}
        </Grid>
    );
};

export default CustomerNavBar;
