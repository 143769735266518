import React from 'react';
import { Trans } from 'react-i18next';

export const getPlanItProductStatus = (productStatus?: string) => {
    switch (productStatus?.toUpperCase()) {
        case 'ACTIVE':
            return <Trans i18nKey="active">Active</Trans>;
        case 'INACTIVE':
            return <Trans i18nKey="inactive">Inactive</Trans>;
        default:
            return productStatus;
    }
};
