import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { Activity, hasPersonas, Persona } from '../../../utility/auth/useSecurity';
import { AuthState } from '../../../store/reducers/auth';
import UserService from '../../../utility/services/user-service';
import { appInsights } from '../../../utility/AppInsights';
import {
    Button,
    Grid,
    makeStyles,
    Paper,
    Typography,
    FormControl,
    FormControlLabel,
    Checkbox,
    Container,
    CircularProgress
} from '@material-ui/core';
import {
    ballGray,
    boldWeight,
    xxl,
    black,
    regularWeight,
    medium,
    containerMaxWidth,
    lato
} from '../../../themes/globalConstants';
import RadioInput from '../../reusable/molecules/RadioInput';
import { FormContext, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import CancelModal from '../../reusable/molecules/CancelModal';
import SingleActionModal from '../../reusable/molecules/SingleActionModal';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import OnboardingInviteUserModal from '../../reusable/molecules/OnboardingInviteUserModal';
import OnboardingService, {
    TermsAndConditionsSignature
} from '../../../utility/services/onboarding-service';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { getJoiningAccount } from '../../../store/root-actions';
import { enUS } from '../../../utility/translations/locales/en-US/en-US';
import IconLink from '../../../components/reusable/atoms/IconLink';

const useStyles = makeStyles((theme) => ({
    paper: {
        borderRadius: 4,
        marginTop: '2em',
        padding: '2em',
        fontFamily: lato
    },
    subHeader: {
        color: ballGray,
        fontWeight: boldWeight,
        fontSize: xxl
    },
    paragraph: {
        color: black,
        fontWeight: regularWeight,
        fontSize: medium
    },
    actionBtn: {
        height: '3.375em',
        width: '13.75em',
        backgroundSize: '200% 100%'
    },
    radioGroup: {
        marginTop: '2.438em'
    },
    checkboxLabel: {
        fontWeight: regularWeight,
        fontSize: medium
    },
    buttons: {
        justifyContent: 'space-between',
        paddingLeft: '2.5em'
    },
    buttonContainer: {
        padding: '3em 0em 3em 0em',
        maxWidth: containerMaxWidth
    },
    termsContainer: {
        padding: '2.5em 0em 2.5em 0em'
    }
}));

export default function TermsAndConditions() {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const auth = useTypedSelector<AuthState>((state) => state.auth);
    const methods = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur'
    });

    const [hasReadAndAgreed, setHasReadAndAgreed] = useState<boolean>(false);
    const isAuthorized = methods.watch('authorizedToSign');
    const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
    const [openUnauthorizedModal, setOpenUnauthorizedModal] = useState<boolean>(false);
    const [hasJoiningAdminPersona, setHasJoiningAdminPersona] = useState<boolean>(false);
    const [openInviteUser, setOpenInviteUser] = useState<boolean>(false);
    const { accessToken, userInfo, permissions } = useTypedSelector<AuthState>(
        (state) => state.auth
    );
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [tcLink, setTcLink] = useState<string>('');
    const [tcStatus, setTcStatus] = useState<'idle' | 'loading' | 'submitting' | 'error'>('idle');
    const [tcVersion, setTcVersion] = useState<string>('');

    const handleConfirmCancel = () => {
        history.push('/onboard');
    };

    const handleCloseCancelModal = () => {
        setOpenCancelModal(false);
    };

    const handleOpenCancelModal = () => {
        setOpenCancelModal(true);
    };

    const handleHasAgreed = () => {
        setHasReadAndAgreed(!hasReadAndAgreed);
    };

    const onSubmit = (data) => {
        if (isAuthorized === 'false') {
            UserService.sendDeletePermissionEmail(accessToken)
                .then(() => {
                    setOpenUnauthorizedModal(true);
                })
                .catch((error) => {
                    if (appInsights && userInfo) {
                        const user = userInfo.email;
                        const appInsightsError =
                            error.message +
                            ' termsAndConditionsNotAuthorizedSendAdminEmail-' +
                            user;
                        appInsights.trackException({
                            error: new Error(appInsightsError),
                            severityLevel: SeverityLevel.Error
                        });
                    }
                });
        } else if (
            isAuthorized === 'true' &&
            hasReadAndAgreed &&
            permissions?.length &&
            tcVersion
        ) {
            setTcStatus('submitting');
            const payload: TermsAndConditionsSignature = {
                language: cultureCode,
                version: tcVersion,
                accountId: getJoiningAccount(permissions),
                accepted: true
            };
            OnboardingService.submitTermsAndConditionsSignature(accessToken, payload)
                .then(() => {
                    history.push('/onboard');
                })
                .catch((error) => {
                    setTcStatus('error');
                });
        }
    };

    const handleCloseUnauthorizedModal = () => {
        setOpenUnauthorizedModal(false);
    };

    const closeInviteUserModal = () => {
        setOpenInviteUser(false);
    };

    useEffect(() => {
        setHasJoiningAdminPersona(hasPersonas([Persona.JoiningAdmin], auth));
    }, [auth]);

    useEffect(() => {
        if (tcStatus === 'idle' && auth.accessToken) {
            setTcStatus('loading');
            (async () => {
                const termsAndConditionsData = await OnboardingService.getTermsAndConditions(
                    auth.accessToken,
                    cultureCode
                ).then((resp) => resp);

                setTcLink(termsAndConditionsData.fileLink);
                setTcStatus('idle');
                setTcVersion(termsAndConditionsData.version);
            })();
        }
    }, [auth, cultureCode]);

    useEffect(() => {
        if (isAuthorized === 'false' && hasJoiningAdminPersona) {
            setOpenInviteUser(true);
        }
    }, [isAuthorized]);

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('accountSetupHeader', 'Account Setup'),
                description: '',
                thinBanner: true,
                displayDropdown: false
            }}
            activity={Activity.TermsAndConditions}
            shipTos={false}
        >
            <FormContext {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
                    <Paper elevation={2} className={classes.paper}>
                        <Typography variant="h4" className={classes.subHeader}>
                            <Trans i18nKey="acceptTermsAndConditions">
                                {`Acknowledge Terms & Conditions`}
                            </Trans>
                        </Typography>
                        <FormControl fullWidth>
                            <RadioInput
                                styles={classes.radioGroup}
                                label={
                                    <Trans i18nKey="authorizedTermsAndConditions">
                                        Are you authorized to accept terms and conditions on behalf
                                        of your company?
                                    </Trans>
                                }
                                name="authorizedToSign"
                                testId="authorizedToSign"
                                control={methods.control}
                                radioControls={[
                                    { value: 'true', label: <Trans i18nKey="yes">Yes</Trans> },
                                    { value: 'false', label: <Trans i18nKey="no">No</Trans> }
                                ]}
                                defaultValue={''}
                            />
                        </FormControl>
                        <Container className={classes.termsContainer}>
                            {tcLink && (
                                <IconLink
                                    svgIcon={
                                        <img
                                            src={process.env.PUBLIC_URL + '/assets/Paper_Icon.svg'}
                                            alt="PDF Icon"
                                        />
                                    }
                                    url={tcLink}
                                    text={t('termsandConditions', enUS.termsandConditions)}
                                />
                            )}
                            {!tcLink && <CircularProgress />}
                        </Container>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={hasReadAndAgreed}
                                    onChange={handleHasAgreed}
                                    name="readAndAgree"
                                    value="ReadAndAgree"
                                    color="primary"
                                    data-testid="readAndAgree"
                                />
                            }
                            label={
                                <Typography className={classes.checkboxLabel}>
                                    <Trans i18nKey="readAndAgree">
                                        I have read and agree to all of the above terms and
                                        conditions.
                                    </Trans>
                                </Typography>
                            }
                            disabled={isAuthorized === 'false' || isAuthorized === undefined}
                        />
                    </Paper>
                    <Container className={classes.buttonContainer}>
                        <Grid
                            container
                            item
                            xs={12}
                            alignItems="center"
                            className={classes.buttons}
                        >
                            <Grid item xs={2}>
                                <Button
                                    type="button"
                                    variant="outlined"
                                    color="secondary"
                                    data-testid="cancel-button"
                                    onClick={handleOpenCancelModal}
                                    className={classes.actionBtn}
                                >
                                    <Trans i18nKey="cancel">Cancel</Trans>
                                </Button>
                            </Grid>
                            <Grid container item xs={2} alignItems="center">
                                {tcStatus === 'submitting' && (
                                    <Grid container item xs={2}>
                                        <CircularProgress />
                                    </Grid>
                                )}
                            </Grid>
                            <Grid item xs={2}>
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    data-testid="submit-button"
                                    className={classes.actionBtn}
                                    disabled={
                                        !tcLink ||
                                        !isAuthorized ||
                                        (isAuthorized === 'true' && !hasReadAndAgreed)
                                    }
                                >
                                    <Trans i18nKey="submit">Submit</Trans>
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </form>
            </FormContext>
            <CancelModal
                onConfirmCancel={handleConfirmCancel}
                onClose={handleCloseCancelModal}
                open={openCancelModal}
            />
            <SingleActionModal
                title={<Trans i18nKey="thankYou">Thank You</Trans>}
                open={openUnauthorizedModal}
                subheader={
                    <Trans i18nKey="adminNotified">
                        Your administrator has been notified of your response.
                    </Trans>
                }
                actionButtonText={<Trans i18nKey="myDashboard">Return to Dashboard</Trans>}
                onAction={handleConfirmCancel}
                onClose={handleCloseUnauthorizedModal}
            />
            <OnboardingInviteUserModal
                open={openInviteUser}
                onClose={closeInviteUserModal}
                termsAndConditions={true}
            />
        </ProcessingPageTemplate>
    );
}
