import React from 'react';
import Modal from '../../../reusable/molecules/Modal';
import { Grid, Typography, makeStyles, CircularProgress } from '@material-ui/core';
import Button from '../../../reusable/atoms/Button';
import { Trans, useTranslation } from 'react-i18next';
import { boldWeight, xl } from '../../../../themes/globalConstants';
import { selectIsImpersonation } from '../../../../store/selectors';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import ImpersonationWarning from '../../../reusable/molecules/ImpersonationWarning';

interface Props {
    open: boolean;
    submitting: boolean;
    onClose: () => void;
    onContinue: () => void;
    deliveryOrderNumber?: string;
}

const useStyles = makeStyles((theme) => ({
    confirmationText: {
        fontWeight: boldWeight,
        color: theme.palette.info.dark,
        fontSize: xl,
        marginBottom: '2em',
        marginLeft: '2em',
        marginTop: '2em'
    },
    buttonGrid: {
        paddingBottom: '2em'
    },
    modalBtn: {
        height: '3.3em',
        width: '10em',
        marginRight: '3em'
    },
    actionBar: {
        margin: '1.5em 0',
        padding: '0'
    },
    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '0.5em'
    },
    cancelBtn: {
        height: '3.3em',
        width: '10em',
        marginRight: '3em'
    },
    impersonationWarning: {
        marginLeft: '2em',
        marginBottom: '2em'
    }
}));

const ShipItSummaryCancelDeliveryOrderModal = ({
    open,
    submitting,
    onClose,
    onContinue,
    deliveryOrderNumber
}: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const isImpersonation = useTypedSelector<boolean>(selectIsImpersonation);
    let modalTitle = t('pleaseConfirmCancelOrder', 'Please confirm you want to cancel this order.');

    const onCancel = () => {
        onClose();
    };

    const getDeliveryOrderNumber = (deliveryOrderNumber?: string) => {
        return (!!deliveryOrderNumber && deliveryOrderNumber !== '0') ? deliveryOrderNumber : t('pending', 'Pending');
    };

    return (
        <Modal
            open={open}
            close={onClose}
            title={modalTitle}
            maxWidth="md"
            closeIcon={true}
            fullWidth={false}
        >
            <Grid container>
                {submitting && (
                    <Grid container item xs={12} alignItems="center" className={classes.actionBar}>
                        <Grid container item xs={12} className={classes.spinningLoader}>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                )}
                <Grid container item justify="flex-start" xs={12}>
                    <Typography align="center" className={classes.confirmationText}>
                        <Trans i18nKey="confirmCancelNoQuestion">
                            Are you sure you want to cancel
                        </Trans>{' '}
                        <Trans i18nKey="deliveryOrderNumber">Delivery Order #</Trans>{' '}
                        {getDeliveryOrderNumber(deliveryOrderNumber)}?
                    </Typography>
                </Grid>
                {isImpersonation && (
                    <Grid 
                        container 
                        item 
                        justify="flex-start" 
                        xs={10} 
                        className={classes.impersonationWarning}
                        data-testid={`impersonation-warning`}
                    >
                        <ImpersonationWarning 
                            showWarning={isImpersonation} 
                            warningType={'SUBMIT'}
                        />
                    </Grid>
                )}
                <Grid container className={classes.buttonGrid} justify="flex-end">
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={onCancel}
                        className={classes.modalBtn}
                        disabled={submitting}
                        data-testid="no-btn"
                    >
                        <Trans i18nKey="no">No</Trans>
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={onContinue}
                        className={classes.cancelBtn}
                        disabled={submitting || isImpersonation}
                        data-testid="yes-btn"
                    >
                        <Trans i18nKey="yesCancel">Yes, Cancel</Trans>
                    </Button>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default ShipItSummaryCancelDeliveryOrderModal;
