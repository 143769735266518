import { Activity, SecurityLevel } from '../../utility/auth/useSecurity';
import {
    SECURITY_LEVEL_CLEARED,
    SECURITY_LEVEL_SET,
    SECURITY_LEVEL_SET_PERSONAS
} from '../actions/action-types';

export interface SecurityLevelState {
    activity?: Activity;
    securityLevel?: SecurityLevel;
    personas?: string;
}

const initialState: SecurityLevelState = {
    activity: undefined,
    securityLevel: SecurityLevel.None,
    personas: undefined
};

const securityLevel = (state = initialState, action: any) => {
    switch (action.type) {
        case SECURITY_LEVEL_SET:
            return {
                ...state,
                activity: Activity[action.activity],
                securityLevel: SecurityLevel[action.securityLevel],
                personas: action.personas
            };
        case SECURITY_LEVEL_CLEARED:
            return {
                ...state,
                activity: Activity[action.activity],
                securityLevel: SecurityLevel[action.securityLevel],
                personas: action.personas
            };
        case SECURITY_LEVEL_SET_PERSONAS:
            return {
                ...state,
                personas: action.personas
            };
        default:
            return state;
    }
};

export default securityLevel;
