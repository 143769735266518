import { Activity } from '../auth/useSecurity';
import { Culture } from '../../store/reducers/region-culture';
import TranslationService from '../services/translation-service';

export const getActivityTranslation = (activity: Activity, cultureCode: Culture) => {
    switch (activity) {
        case Activity.GraphicsIntakeForm:
            return TranslationService.getTranslatedText(cultureCode, 'graphicsIntakeForm');
        case Activity.DraftGraphicsIntakeForm:
            return TranslationService.getTranslatedText(cultureCode, 'draftGraphicsIntakeForms');
        case Activity.InProgressGraphicsStatuses:
            return TranslationService.getTranslatedText(cultureCode, 'inProgressGraphicsStatuses');
        case Activity.GraphicsQuote:
            return TranslationService.getTranslatedText(cultureCode, 'graphicsQuote');
        case Activity.GraphicsProof:
            return TranslationService.getTranslatedText(cultureCode, 'graphicsProof');
        case Activity.GraphicsStandard:
            return TranslationService.getTranslatedText(cultureCode, 'graphicsStandardPilotCan');
        case Activity.RecentlyCompletedGraphics:
            return TranslationService.getTranslatedText(cultureCode, 'recentlyCompletedGraphics');
        case Activity.GraphicsSummary:
            return TranslationService.getTranslatedText(cultureCode, 'graphicsSummary');
        case Activity.NewOpenProductionOrders:
            return TranslationService.getTranslatedText(cultureCode, 'newOpenProductionOrders');
        case Activity.DraftProductionOrders:
            return TranslationService.getTranslatedText(cultureCode, 'draftProductionOrders');
        case Activity.ClosedProductionOrders:
            return TranslationService.getTranslatedText(cultureCode, 'closedProductionOrders');
        case Activity.NewOpenDeliveryOrders:
            return TranslationService.getTranslatedText(cultureCode, 'newOpenDeliveryOrders');
        case Activity.DraftDeliveryOrders:
            return TranslationService.getTranslatedText(cultureCode, 'draftDeliveryOrders');
        case Activity.ClosedDeliveryOrders:
            return TranslationService.getTranslatedText(cultureCode, 'closedDeliveryOrders');
        case Activity.PlanningPage:
            return TranslationService.getTranslatedText(cultureCode, 'planning');
        case Activity.OrderPaymentFinancials:
            return TranslationService.getTranslatedText(cultureCode, 'orderPaymentFinancials');
        case Activity.CreateNewUser:
            return TranslationService.getTranslatedText(cultureCode, 'createNewUser');
        case Activity.ModifyPermissions:
            return TranslationService.getTranslatedText(cultureCode, 'editpermissions');
        case Activity.Troubleshooting:
            return TranslationService.getTranslatedText(cultureCode, 'troubleshooting');
        case Activity.Dashboard:
            return TranslationService.getTranslatedText(cultureCode, 'dashboard');
        case Activity.UserAdminAddUser:
            return TranslationService.getTranslatedText(cultureCode, 'addUser');
        case Activity.RequestAccess:
            return TranslationService.getTranslatedText(cultureCode, 'requestAccess');
        case Activity.UserAdminDashboard:
            return TranslationService.getTranslatedText(cultureCode, 'userAdminDashboard');
        case Activity.UserAdminEditUser:
            return TranslationService.getTranslatedText(cultureCode, 'editUser');
        case Activity.UserAdminReviewRequest:
            return TranslationService.getTranslatedText(cultureCode, 'reviewRequest');
        case Activity.LiquidTestIntakeForm:
            return TranslationService.getTranslatedText(cultureCode, 'liquidIntakeForm');
        case Activity.LiquidTestIntakeSummary:
            return TranslationService.getTranslatedText(cultureCode, 'submittedLiquidTests');
        case Activity.TermsAndConditions:
            return TranslationService.getTranslatedText(cultureCode, 'termsandConditions');
        case Activity.SalesAndUseTax:
            return TranslationService.getTranslatedText(cultureCode, 'salesAndUseTaxSideHeader');
        case Activity.CreditApplication:
            return TranslationService.getTranslatedText(cultureCode, 'creditApplicationTitle');
        case Activity.MakeItATMSummary:
            return TranslationService.getTranslatedText(cultureCode, 'makeItSummary');
        case Activity.MakeIt:
            return TranslationService.getTranslatedText(cultureCode, 'makeIt');
        case Activity.MakeItBulkUpload:
        case Activity.MakeItBulkATM:
            return TranslationService.getTranslatedText(cultureCode, 'bulkUpload');
        case Activity.PlanningPageATM:
            return TranslationService.getTranslatedText(cultureCode, 'planning');
        default:
            return TranslationService.getTranslatedText(cultureCode, 'unknownActivity');
    }
};
