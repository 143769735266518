import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { Activity } from '../../../utility/auth/useSecurity';
import { useTranslation } from 'react-i18next';
import MakeItDraftsSummaryTabs from './components/MakeItDraftsSummaryTabs';
import { MakeItDraftsState } from '../../../store/reducers/make-it-drafts';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { MAKE_IT_DRAFTS_RESET } from '../../../store/actions/action-types';
import { useDispatch } from 'react-redux';
import { BulkUploadMakeItState } from '../../../store/reducers/makeit-bulk-upload';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { loadMakeItBulkProducts } from '../../../store/actions/makeit-bulk-upload';
import { selectIsLargeCustomerAccount } from '../../../store/selectors';
import { loadProductionOrdersATM } from '../../../store/actions/make-it-atm-summary';

export default function MakeItDraftsSummary() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { loading } = useTypedSelector<MakeItDraftsState>((state) => state.makeItDrafts);
    const { selectedAccountId, shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { products, loading: bulkUploadLoading } = useTypedSelector<BulkUploadMakeItState>(
        (state) => state.bulkUploadMakeItState
    );
    const isLargeCustomer = useTypedSelector<boolean>(selectIsLargeCustomerAccount);

    useEffect(() => {
        return () => {
            dispatch({ type: MAKE_IT_DRAFTS_RESET });
        };
    }, [dispatch]);

    useEffect(() => {
        if (selectedAccountId && products?.length && isLargeCustomer) {
            dispatch(loadProductionOrdersATM([parseInt(selectedAccountId)]));
        }
    }, [selectedAccountId, products, isLargeCustomer, dispatch]);

    // load products for allocation calculations
    useEffect(() => {
        if (!products && shipToAccounts?.length && !bulkUploadLoading) {
            const shipToIds = shipToAccounts.map((account) => {
                return account.accountId;
            });
            dispatch(loadMakeItBulkProducts(shipToIds, true, false, false));
        }
    }, [dispatch, bulkUploadLoading, products, shipToAccounts]);

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('makeIt', 'Make It'),
                description: t('draftProductionOrders', 'Draft Production Orders'),
                thinBanner: true,
                displayDropdown: false
            }}
            activity={Activity.DraftProductionOrders}
            loading={loading}
            onlyCheckSelectedLargeAccount={true}
        >
            <Grid container data-testid="tabs-container">
                <MakeItDraftsSummaryTabs />
            </Grid>
        </ProcessingPageTemplate>
    );
}
