import React from 'react';
import {
    makeStyles,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Typography,
    TableBody
} from '@material-ui/core';
import { Activity, SecurityLevel } from '../../../utility/auth/useSecurity';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { boldWeight, containerMaxWidth, ltBlueGrey_3 } from '../../../themes/globalConstants';
import { generateKey } from '../../../utility/helpers/order-helpers';
import { userAdminRolesTableHeaders, userAdminRolesTableRows } from './components/user-admin-roles';
import clsx from 'clsx';
import TranslationService from '../../../utility/services/translation-service';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { useTypedSelector } from '../../../store/reducers/reducer';

const useStyles = makeStyles((theme) => ({
    actionBtn: {
        height: '3.375em',
        width: '13.75em',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom'
    },
    paper: {
        marginTop: '3em',
        padding: '2em',
        borderRadius: 4,
        maxWidth: containerMaxWidth
    },
    container: {
        padding: '3em'
    },
    formSection: {
        marginBottom: '2.5em'
    },
    formSectionContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    productTableHeader: {
        '& p': {
            fontWeight: boldWeight
        }
    },
    rotatedHeaderContainer: {
        width: 40
    },
    rotatedHeaderContainerWide: {
        width: 260
    },
    rotatedHeaderContent: {
        width: 175
    },
    tableWrapper: {
        marginTop: 115,
        width: '100%',
        '& .MuiTableCell-root': {
            borderLeft: 'none',
            borderBottom: 'none'
        },
        '& .MuiTableHead-root': {
            borderBottom: 'none',
            '& th': {
                transform: 'rotate(305deg)',
                whiteSpace: 'break-spaces'
            }
        }
    },
    subheader: {
        fontWeight: boldWeight
    },
    subheaderRow: {
        borderBottom: '5px solid ' + ltBlueGrey_3,
        height: 55,
        verticalAlign: 'bottom'
    },
    nonSubheaderRow: {
        borderTop: '1px solid ' + ltBlueGrey_3
    }
}));

export default function UserAdminRolesAndAccess() {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((store) => store.regionCulture);

    const getSecurityLevelString = (access: SecurityLevel) => {
        switch (access) {
            case SecurityLevel.Edit:
                return TranslationService.getTranslatedText(cultureCode, 'edit');
            case SecurityLevel.View:
                return TranslationService.getTranslatedText(cultureCode, 'view');
            case SecurityLevel.None:
            default:
                return '';
        }
    };

    return (
        <ProcessingPageTemplate
            banner={{
                header: TranslationService.getTranslatedText(cultureCode, 'roleComparison'),
                description: '',
                thinBanner: true,
                displayDropdown: false
            }}
            activity={Activity.OpenAccess}
        >
            <Table
                size="small"
                aria-label="roles and access table"
                key={generateKey('roles-and-access-table')}
                className={classes.tableWrapper}
                data-testid="roles-and-access-table"
            >
                <TableHead className={classes.productTableHeader}>
                    <TableRow>
                        <TableCell>
                            <div className={classes.rotatedHeaderContainerWide}>
                                <div className={classes.rotatedHeaderContent}> </div>
                            </div>
                        </TableCell>
                        {userAdminRolesTableHeaders.map((header, i) => {
                            return (
                                <TableCell>
                                    <div className={classes.rotatedHeaderContainer}>
                                        <div className={classes.rotatedHeaderContent}>
                                            {TranslationService.getTranslatedText(
                                                cultureCode,
                                                header.headerKey
                                            )}
                                        </div>
                                    </div>
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {userAdminRolesTableRows.map((row, i) => {
                        return (
                            <TableRow
                                key={'user-admin-roles-table-row-' + row.activity + i}
                                className={clsx(
                                    { [classes.nonSubheaderRow]: !row.isSubheader },
                                    { [classes.subheaderRow]: row.isSubheader }
                                )}
                            >
                                <TableCell>
                                    <Typography
                                        className={clsx({ [classes.subheader]: row.isSubheader })}
                                    >
                                        {TranslationService.getTranslatedText(
                                            cultureCode,
                                            row.activityKey
                                        )}
                                    </Typography>
                                </TableCell>
                                {row.accessLevels.map((accessLevel, i) => {
                                    return (
                                        <TableCell>
                                            <Typography>
                                                {getSecurityLevelString(accessLevel)}
                                            </Typography>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </ProcessingPageTemplate>
    );
}
