import { Activity, Persona, SecurityLevel, hasAccess } from '../../../utility/auth/useSecurity';
import { Collapse, Grid, IconButton, Typography, makeStyles } from '@material-ui/core';
import {
    MAKEIT_BULK_UPLOAD_GET_PRODUCTS,
    MAKEIT_BULK_UPLOAD_REFRESH_STATE,
    MAKEIT_DASHBOARD_UPDATE_RANGE,
    MAKEIT_DASHBOARD_UPDATE_VIEW,
    MAKEIT_PRODUCTS_TO_ORDER_UPDATE
} from '../../../store/actions/action-types';
import {
    MakeState,
    ProductionOrder,
    ProductionOrderLine
} from '../../../store/reducers/makeit-dashboard';

import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import {
    blackWeight,
    ltBlueGrey_34,
    makeItGridName,
    ballBlue,
    medium,
    large
} from '../../../themes/globalConstants';
import {
    createATMOrder,
    loadMakeDashboard,
    makeItDashboardUpdateRange,
    makeItDashboardUpdateView,
    resetMakeItState,
    saveDraftMakeItOrder,
    setRequestedMakeItWeek,
    updateOrderList,
    updateProductList,
    updateProductPalletInput
} from '../../../store/actions/makeit-dashboard';

import { Alert } from '@material-ui/lab';
import { AuthState } from '../../../store/reducers/auth';
import Button from '../../reusable/atoms/Button';
import CloseIcon from '@material-ui/icons/Close';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { DefaultQueryParams, useQuery } from '../../../utility/helpers/query-helpers';
import { LocalStorage } from 'tubular-common';
import CurrentCampaignsModal from '../../reusable/molecules/CurrentCampaignsModal';
import { CampaignRun } from '../../../store/reducers/product-portfolio';
import MakeItCheckout from './components/MakeItCheckout';
import MakeItGrid from './components/MakeItGrid';
import OrderCancelModal from '../../reusable/molecules/OrderCancelModal';
import { OrderProductTableRow } from '../../../store/reducers/orders-dashboard';
import OrdersService from '../../../utility/services/orders-service';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import SaveConfirmedModal from '../../reusable/molecules/SaveConfirmedModal';
import SaveModal from '../../reusable/molecules/SaveModal';
import { createLeadTimeDisplay } from '../../../utility/helpers/date-helpers';
import {
    getCampaignInformation,
    getMakeItLeadTime
} from '../../../utility/helpers/production-order-helpers';
import { productTypeHelper } from '../../../utility/helpers/order-helpers';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { getStringInputValue } from '../../../utility/helpers/grid-helpers';
import { selectIsLargeCustomerAccount } from '../../../store/selectors';
import { enUS } from '../../../utility/translations/locales';
import QuantityUnitSelector from '../../reusable/molecules/QuantityUnitSelector';
import { QuantityUnitSelectorProvider } from '../../reusable/molecules/QuantityUnitSelectorContext';
import { getProductCellQuantities } from '../../../utility/helpers/shipment-helpers';
import { BulkUploadMakeItATMState } from '../../../store/reducers/makeit-bulk-atm';
import { resetAllocationsState } from '../../../store/root-actions';
import MakeItRequestOptions from './components/MakeItRequestOptions';
import { loadProductionOrdersATM } from '../../../store/actions/make-it-atm-summary';
import { selectIsBillToAccount } from '../../../store/selectors';
import {
    clearContractsAndDocuments,
    loadContractsAndDocuments
} from '../../../store/actions/contract-summary';
import { ContractSummaryState } from '../../../store/reducers/contract-summary';
import NotificationBanner from '../../reusable/molecules/NotificationBanner';
import { getCurrentlyActiveDocuments } from '../../../utility/helpers/pricing-helpers';

const useStyles = makeStyles((theme) => ({
    dashboard: {
        padding: '0'
    },
    main: {
        padding: '0',
        marginTop: '1.750em'
    },
    side: {
        backgroundColor: ltBlueGrey_34,
        flexDirection: 'column',
        padding: '1em',
        marginTop: '0.5em'
    },
    actionBar: {
        margin: '1.5em 0',
        padding: '0'
    },
    btnWrapper: {
        '& button:last-child': {
            marginLeft: '1.25em'
        }
    },
    actionBtn: {
        borderRadius: 'unset'
    },
    bulkUploadBtn: {
        borderRadius: 'unset',
        marginRight: '2em'
    },
    title: {
        fontWeight: blackWeight,
        color: theme.palette.secondary.main,
        padding: '0 0 0.5em 0'
    },
    subtitle: {
        color: theme.palette.secondary.main
    },
    warningContainer: {
        width: '100%'
    },
    viewCampaignsButton: {
        fontSize: medium,
        '&:hover': {
            backgroundColor: 'transparent',
            color: ballBlue,
            outline: 'none'
        },
        '&:focus': {
            backgroundColor: 'transparent',
            color: ballBlue
        }
    },
    stepNum: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        borderRadius: '50%',
        width: '1.875em',
        height: '1.875em',
        fontSize: large,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: blackWeight,
        marginTop: '0.25em'
    },
    stepWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    stepTitle: {
        paddingLeft: '0.5em',
        fontWeight: blackWeight,
        color: theme.palette.secondary.main
    },
    addProducts: {
        marginBottom: '5em',
        height: '6em'
    },
    quantityUnitSelector: {
        marginTop: '1em'
    },
    linkSpacer: {
        marginRight: '.25em'
    },
    linkedPO: {
        textDecoration: `underline ${ballBlue}`
    }
}));

export default function MakeIt() {
    const { id } = useParams<DefaultQueryParams>();
    const query = useQuery();
    const from = query.get('from');
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [enableSaveDraft] = useState(false);
    const [pageError, setPageError] = useState(false);
    const [editDraft, setEditDraft] = useState(false);
    const [onDraftSaved, setOnDraftSaved] = useState(false);
    const [openSaveModal, setOpenSaveModal] = useState(false);
    const [warning, setWarning] = useState(false);
    const [order, setOrder] = useState({} as ProductionOrder);
    const [checkOutList, setCheckOutList] = useState([] as OrderProductTableRow[]);
    const [paginationReset, setPaginationReset] = useState<boolean>(false);
    const [currentViewTypes, setCurrentViewTypes] = useState([''] as string[]);
    const [currentRangeTypes, setCurrentRangeTypes] = useState([''] as string[]);
    const [showMakeItBulkUpload, setShowMakeItBulkUpload] = useState<boolean>(false);
    const [openViewCampaignsModal, setOpenViewCampaignsModal] = useState<boolean>(false);
    const [storage] = useState<any>(new LocalStorage(makeItGridName));
    const [lockedPeriodDays, setLockedPeriodDays] = useState<number>(14);
    const [showViewCurrentCampaignsLink, setShowViewCurrentCampaignsLink] =
        useState<boolean>(false);
    const [campaignRuns, setCampaignRuns] = useState<CampaignRun[]>();
    const [campaignProducts, setCampaignProducts] = useState<OrderProductTableRow[]>();
    const [filteredViewProducts, setFilteredViewProducts] = useState<OrderProductTableRow[]>();
    const updateProductCache = useRef<OrderProductTableRow>();
    const {
        products,
        productionOrder,
        productsToOrder,
        error,
        shipToId,
        view,
        range,
        loading,
        requestedDate
    } = useTypedSelector<MakeState>((state) => state.makeItDashboard);
    const { selectedAccountId, shipToAccounts, accounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { allocations } = useTypedSelector<BulkUploadMakeItATMState>(
        (state) => state.bulkUploadMakeItATMState
    );
    const { accessToken } = useTypedSelector((state) => state.auth);
    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);
    const auth = useTypedSelector<AuthState>((state) => state.auth);
    const isLargeCustomer = useTypedSelector<boolean>(selectIsLargeCustomerAccount);
    const dashboardLink = '/dashboard';
    const makeItBulkUploadLink = '/make-it-bulk-upload';

    const backFromATMReview = useMemo(() => from === 'review', [from]);
    const backFromGuidedConfiguration = useMemo(() => from === 'configuration', [from]);
    const { documents } = useTypedSelector<ContractSummaryState>((state) => state.contractSummary);
    const pricingLoading = useTypedSelector<boolean>((state) => state.contractSummary.loading);
    const isBillToAccount = useTypedSelector<boolean>(selectIsBillToAccount);

    useEffect(() => {
        const account = accounts?.find((acc) => acc.accountId === selectedAccountId);

        if (selectedAccountId && account && (account?.listCustomer || !isBillToAccount)) {
            dispatch(loadContractsAndDocuments([selectedAccountId], !isBillToAccount));
        } else {
            dispatch(clearContractsAndDocuments());
        }
    }, [selectedAccountId, isBillToAccount, accounts]);

    const someCurrentlyActiveDocumentIsUnapproved = useMemo(() => {
        const currentlyActiveDocuments = getCurrentlyActiveDocuments(documents);
        const someCurrentlyActiveDocumentIsUnapproved = currentlyActiveDocuments.some(
            (d) => !d.isApproved
        );

        return someCurrentlyActiveDocumentIsUnapproved;
    }, [documents]);

    const onCloseConfirmation = () => {
        setOpen(false);
    };

    const onConfirmedCancel = () => {
        handleBackNavigation();
        setOpen(false);
    };

    const onCloseSaveModal = () => {
        setOpenSaveModal(false);
    };

    const onCancel = () => {
        setOpen(true);
    };

    const onSaveOrder = () => {
        setOpenSaveModal(true);
    };

    const onViewCampaigns = () => {
        setOpenViewCampaignsModal(true);
    };

    const onCloseCampaignsModal = () => {
        setOpenViewCampaignsModal(false);
    };

    const handleBackNavigation = () => {
        dispatch(resetMakeItState());
        history.push(dashboardLink);
    };

    const onMakeItBulkClicked = () => {
        history.push(makeItBulkUploadLink);
    };

    const handleSaveOrder = (orderName: string) => {
        let order = { ...productionOrder } as ProductionOrder;
        order.name = orderName;
        const orderLines = OrdersService.createMakeItOrderlines(checkOutList);
        dispatch(updateOrderList(checkOutList));
        dispatch(saveDraftMakeItOrder(order, orderLines));
    };

    const onDraftSavedClose = () => {
        setOnDraftSaved(false);
    };

    const handleAddItem = async (
        itemAdded: OrderProductTableRow,
        addDisabled: boolean,
        removeAll: boolean,
        quantity?: number
    ) => {
        // Wait for handleInputBlur to finish and cache its change in updateProductCache
        await new Promise((r) => setTimeout(r, 200));

        // Quantity may be undefined if item quantity hasn't yet propagated to redux...
        // ...If so, get from the local cache instead then clear the cache
        const cachedQuantity = updateProductCache.current?.palletQuantity;
        if (updateProductCache.current?.productSku === itemAdded.productSku && cachedQuantity) {
            quantity = cachedQuantity;
            updateProductCache.current = undefined;
        }

        handleSearchText();
        let item: OrderProductTableRow | undefined;
        if (isLargeCustomer) {
            const updatedProducts = getUpdatedProducts(itemAdded, quantity);
            item = updatedProducts?.find((product) => product.productSku === itemAdded.productSku);
        } else {
            item = products?.find((product) => product.productSku === itemAdded.productSku);
        }
        if (item) {
            item.requestedDate = createLeadTimeDisplay(
                item.leadTimeWeeks ?? getMakeItLeadTime(item)
            );
            let productList: OrderProductTableRow[] = [];
            if (isLargeCustomer) {
                const matchedItem = checkOutList.find(
                    (checkOutListItem) => checkOutListItem.productSku === item!.productSku
                );
                if (matchedItem) {
                    const updatedPalletQuantity = matchedItem.palletQuantity! + quantity!;
                    productList = [
                        ...checkOutList.filter(
                            (checkOutListItem) =>
                                checkOutListItem.productSku !== matchedItem.productSku
                        ),
                        { ...matchedItem, palletQuantity: updatedPalletQuantity }
                    ];
                } else {
                    productList = [item, ...checkOutList];
                }
            } else {
                productList = [item, ...checkOutList];
            }
            setCheckOutList(productList);
            dispatch(updateProductList(addDisabled, removeAll, item));
            dispatch(updateOrderList(productList));
        }
    };

    const getUpdatedProducts = (
        itemAdded: OrderProductTableRow,
        inputQuantity: number | undefined
    ): OrderProductTableRow[] | undefined => {
        let updatedProducts: OrderProductTableRow[] | undefined;
        if (products) {
            updatedProducts = [...products];
            products?.map((item, index) => {
                if (itemAdded.productSku === item.productSku) {
                    if (typeof inputQuantity !== 'undefined') {
                        const [inputPallets, inputEaches] = getProductCellQuantities(
                            inputQuantity,
                            itemAdded.quantityPerPallet!,
                            'pallets'
                        );
                        updatedProducts!.splice(index, 1, {
                            ...itemAdded,
                            palletQuantity: inputPallets,
                            eachesQuantity: inputEaches
                        });
                    }
                }
                return null;
            });
        }
        return updatedProducts;
    };

    const handleRemoveAll = (itemList: OrderProductTableRow[]) => {
        handleSearchText();
        itemList.forEach((item: OrderProductTableRow) => {
            dispatch(updateProductList(false, false, item));
        });
        setCheckOutList(
            [...checkOutList].filter(
                (product) => productTypeHelper(product.type) !== productTypeHelper(itemList[0].type)
            )
        );
    };

    const handleRemoveItem = (item: OrderProductTableRow) => {
        handleSearchText();
        dispatch(updateProductList(false, false, item));
        setCheckOutList(
            [...checkOutList].filter((product) => product.productSku !== item.productSku)
        );
    };

    //Push input values to state on blur
    const handleInputBlur = async (product: OrderProductTableRow, inputQuantity: number) => {
        // Wait briefly before performing this logic, which triggers a rerender...
        // ...this allows a click of the "Add" button to be registered properly
        await new Promise((r) => setTimeout(r, 200));

        const updatedProducts = products?.map((item, index) => {
            if (product.productSku === item.productSku) {
                const [inputPallets, inputEaches] = getProductCellQuantities(
                    inputQuantity,
                    product.quantityPerPallet!,
                    'pallets'
                );
                const updatedItem = {
                    ...item,
                    palletQuantity: inputPallets,
                    eachesQuantity: inputEaches
                };

                updateProductCache.current = { ...updatedItem };
                setTimeout(() => {
                    // Clear this cached value after a short time...
                    //...It should only last long enough to be handled by an immediate "Add" click
                    updateProductCache.current = undefined;
                }, 300);

                return updatedItem;
            }

            return item;
        });

        dispatch(updateProductPalletInput(updatedProducts ?? []));
    };
    const validateProducts = useCallback(() => {
        if (order && products) {
            const orderList: OrderProductTableRow[] = [];

            order.lines?.map((orderItem: ProductionOrderLine) => {
                const orderProduct: ProductionOrderLine = orderItem;
                const matchedProductList = products.filter(
                    (product) =>
                        product.productSku === orderProduct.productSku &&
                        product.graphicId === orderProduct.graphicId
                );

                // if orders product isn't avaiable, show warning
                matchedProductList.length
                    ? orderList.push(matchedProductList[0])
                    : setWarning(true);
                return null;
            });

            setCheckOutList(orderList);
        }
    }, [order, products]);

    const handleSubmitClick = () => {
        const forwardNav = isLargeCustomer
            ? '/make-it-bulk-upload-po-review?from=guided'
            : editDraft
            ? `/make-it-configuration/${id}`
            : '/make-it-configuration';
        if (isLargeCustomer) {
            dispatch(createATMOrder(checkOutList));
            dispatch({
                type: MAKEIT_BULK_UPLOAD_GET_PRODUCTS,
                products: products
            });
        } else {
            dispatch(updateOrderList(checkOutList));
        }
        history.push(forwardNav);
    };

    const handleViewFilterClick = useCallback(
        (viewType: string[]) => {
            setCurrentViewTypes(viewType);
            if (products) {
                if (viewType[0] === '') {
                    setFilteredViewProducts(products);
                } else if (viewType[0] === 'CAMPAIGN') {
                    const updatedProducts = products.filter((product) => product.campaignTiming);
                    setFilteredViewProducts(updatedProducts);
                } else {
                    const updatedProducts = products.filter((product) =>
                        viewType.includes(product.type!)
                    );
                    setFilteredViewProducts(updatedProducts);
                }
            }
        },
        [products]
    );

    const handleRangeFilterClick = (rangeTypes: string[]) => {
        setCurrentRangeTypes(rangeTypes);
    };

    //This is used to reset the cascadePaginationReset to false, so it doesn't continue to reset
    const handleResetPagination = () => {
        setPaginationReset(false);
    };

    // save tubular search text in storage
    const handleSearchText = () => {
        const gridElementValue = getStringInputValue('.MuiInputBase-input');
        storage.setTextSearch(gridElementValue);
    };

    // update local state campaign products and campaign runs data
    const handleUpdateCampaigns = useCallback(
        (products: OrderProductTableRow[]) => {
            if (selectedAccountId) {
                let campaignInformation = getCampaignInformation(products, selectedAccountId);

                // store unique campaign products
                setCampaignProducts(campaignInformation.filteredCampaignProducts);
                // store campaign products that have campaign runs associated to them
                setCampaignRuns(campaignInformation.updatedCampaigns);
            }
        },

        [selectedAccountId]
    );

    const handleDateUpdate = () => {
        setCheckOutList([]);
        dispatch({ type: MAKEIT_PRODUCTS_TO_ORDER_UPDATE, productsToOrder: undefined });
    };

    useEffect(() => {
        if (selectedAccountId && products?.length && isLargeCustomer) {
            //for large customers, Make It Summary needed to determine
            //if there is an existing order for the selected week
            dispatch(loadProductionOrdersATM([parseInt(selectedAccountId)]));
        }
    }, [selectedAccountId, products, isLargeCustomer, dispatch]);

    useEffect(() => {
        handleViewFilterClick(currentViewTypes);
    }, [currentViewTypes, handleViewFilterClick, products]);

    // when campaign runs or products are updated (possibly switching ship to), check
    // whether or not to show view current campaigns link
    useEffect(() => {
        const hasCampaignRuns = !!campaignRuns && campaignRuns.length > 0;
        const hasCampaignProducts = !!campaignProducts && campaignProducts.length > 0;
        setShowViewCurrentCampaignsLink(hasCampaignRuns || hasCampaignProducts);
    }, [campaignProducts, campaignRuns]);
    useEffect(() => {
        if (productsToOrder && productsToOrder.length > 0) {
            setCheckOutList(productsToOrder);
        }
    }, [productsToOrder]);

    useEffect(() => {
        if (!backFromATMReview && !backFromGuidedConfiguration) {
            setCheckOutList([]);
        }
    }, [backFromATMReview, backFromGuidedConfiguration]);

    useEffect(() => {
        if (products) {
            handleUpdateCampaigns(products);
        }
    }, [dispatch, handleUpdateCampaigns, products, productsToOrder]);

    useEffect(() => {
        // If Ship To ID changes, requery the products
        if (selectedAccountId !== shipToId && !error) {
            if (!!requestedDate) {
                const date = requestedDate;
                dispatch(resetMakeItState());
                setCheckOutList([]);
                dispatch(loadMakeDashboard());
                setCurrentViewTypes(['']);
                dispatch(setRequestedMakeItWeek(moment(date, 'MM/DD/YYYY')));
                dispatch(makeItDashboardUpdateView([''], MAKEIT_DASHBOARD_UPDATE_VIEW));
            } else {
                dispatch(resetMakeItState());
                setCheckOutList([]);
                dispatch(loadMakeDashboard());
                setCurrentViewTypes(['']);
                dispatch(makeItDashboardUpdateView([''], MAKEIT_DASHBOARD_UPDATE_VIEW));
            }
        }

        if (selectedAccountId === shipToId && products?.length === 0) {
            dispatch(loadMakeDashboard());
        }

        //Make sure selectedAccountId and shipToId are both defined
        //Then, if selectedAccountId changes, reset pagination rows to default and reset search
        if (selectedAccountId && shipToId && selectedAccountId !== shipToId) {
            setPaginationReset(true);
            storage.setTextSearch('');
        }

        if (permissions?.length && selectedAccountId) {
            let context = { accountId: selectedAccountId };
            let showMakeItBulk = hasAccess(
                [Persona.AllAccess, Persona.OrderFulfillment, Persona.MakeItOnly],
                auth,
                context
            );
            setShowMakeItBulkUpload(showMakeItBulk);
        }
    }, [
        selectedAccountId,
        permissions,
        shipToId,
        error,
        products?.length,
        dispatch,
        storage,
        auth,
        requestedDate
    ]);

    useEffect(() => {
        if (!error && openSaveModal) {
            onCloseSaveModal();
            setOnDraftSaved(true);
        }
    }, [error, openSaveModal]);

    //This is a call for a draft
    useEffect(() => {
        dispatch({ type: MAKEIT_BULK_UPLOAD_REFRESH_STATE });
        if (!backFromATMReview && !backFromGuidedConfiguration) {
            dispatch({ type: MAKEIT_PRODUCTS_TO_ORDER_UPDATE, productsToOrder: undefined });
        }
        if (id) {
            OrdersService.getProductionOrder(accessToken, +id)
                .then((response) => {
                    setOrder(response.data);
                    setEditDraft(true);
                    validateProducts();
                })
                .catch((error) => {
                    setPageError(true);
                });
        }
        // reset search on page load
        if (!storage.name) {
            storage.setGridName(makeItGridName);
            storage.setTextSearch('');
        }
    }, [
        accessToken,
        backFromATMReview,
        backFromGuidedConfiguration,
        dispatch,
        id,
        storage,
        validateProducts
    ]);

    useEffect(() => {
        // Using stringify allows us to compare the length and contents of the arrays
        if (JSON.stringify(currentViewTypes) !== JSON.stringify(view)) {
            dispatch(makeItDashboardUpdateView(currentViewTypes, MAKEIT_DASHBOARD_UPDATE_VIEW));
        }
        if (JSON.stringify(currentRangeTypes) !== JSON.stringify(range)) {
            dispatch(makeItDashboardUpdateRange(currentRangeTypes, MAKEIT_DASHBOARD_UPDATE_RANGE));
        }
    }, [currentViewTypes, currentRangeTypes, view, range, dispatch]);

    useEffect(() => {
        if (shipToAccounts?.length && selectedAccountId) {
            const account = shipToAccounts.find(
                (account) => account.accountId === selectedAccountId
            );
            setLockedPeriodDays(account?.lockedPeriodDays ?? 14);
        }
    }, [selectedAccountId, shipToAccounts]);

    useEffect(() => {
        if (allocations) {
            dispatch(resetAllocationsState());
        }
    }, [allocations, dispatch]);

    useTranslation();

    const footerActions = (
        <>
            <Grid container item xs={2} className={classes.btnWrapper}>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="cancel-btn"
                    onClick={onCancel}
                    className={classes.actionBtn}
                >
                    <Trans i18nKey="cancel">Cancel</Trans>
                </Button>
                {enableSaveDraft && (
                    <Button
                        type="submit"
                        variant="outlined"
                        data-testid="save-btn"
                        onClick={onSaveOrder}
                        className={classes.actionBtn}
                    >
                        <Trans i18nKey="saveForLater">Save For Later</Trans>
                    </Button>
                )}
            </Grid>
            {someCurrentlyActiveDocumentIsUnapproved ? (
                <Grid container item xs={7}>
                    <NotificationBanner
                        notificationMessage={t(
                            'pricingWarningText',
                            `Terms & Conditions require acknowledgement for one or more bill to's on your account. Orders cannot not be placed until new Terms & Conditions have been acknowledged. Users within your organization with the 'Account Admin' access/permission have the ability to acknowledge new Terms and Conditions within The Source via the 'Admin' tab.`
                        )}
                        notificationType={'WARNING'}
                    />
                </Grid>
            ) : null}
            <Grid container item xs={3} justify="flex-end" className={classes.btnWrapper}>
                <Button
                    type="button"
                    color="primary"
                    variant="outlined"
                    data-testid="back-btn"
                    className={classes.actionBtn}
                    onClick={handleBackNavigation}
                >
                    <Trans i18nKey="back">Back</Trans>
                </Button>
                <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    data-testid="mk-submit-btn"
                    className={classes.actionBtn}
                    onClick={handleSubmitClick}
                    disabled={checkOutList.length === 0 || someCurrentlyActiveDocumentIsUnapproved}
                >
                    <Trans i18nKey="setDatesQuantities">Set Dates & Quantities</Trans>
                </Button>
            </Grid>
        </>
    );

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('makeIt', 'Make It'),
                description: t('createProductionOrder', 'CREATE PRODUCTION ORDER'),
                thinBanner: true,
                displayDropdown: true
            }}
            actionFooter={{
                footerAction: footerActions,
                justify: 'space-between',
                sticky: true
            }}
            loading={loading || pricingLoading}
            activity={Activity.MakeIt}
            restrictToSecurityLevel={SecurityLevel.Edit}
        >
            <Grid
                container
                spacing={2}
                alignItems="flex-start"
                className={classes.dashboard}
                data-testid={isLargeCustomer ? 'make-atm' : 'make-it'}
            >
                <QuantityUnitSelectorProvider state={{ quantityUnit: 'pallets' }}>
                    <Grid container item md={9} className={classes.main}>
                        <Grid container item>
                            <Collapse in={warning} className={classes.warningContainer}>
                                <Alert
                                    severity="warning"
                                    action={
                                        <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                                setWarning(false);
                                            }}
                                        >
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                >
                                    <Trans i18nKey="adjustmentsToYourOrder">
                                        Adjustments may have been made to your order since you last
                                        saved. Please review all products and quantities in your
                                        order before proceeding.
                                    </Trans>
                                </Alert>
                            </Collapse>
                            <Collapse in={pageError} className={classes.warningContainer}>
                                <Alert
                                    severity="error"
                                    action={
                                        <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                                setPageError(false);
                                            }}
                                        >
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                >
                                    <Trans i18nKey="failedToLoadDraft">
                                        Failed to load draft order.
                                    </Trans>
                                </Alert>
                            </Collapse>
                        </Grid>
                        {showMakeItBulkUpload && (
                            <Grid container item xs={12} justify="flex-end">
                                <Button
                                    type="button"
                                    color="primary"
                                    variant="outlined"
                                    data-testid="make-it-bulk-btn"
                                    onClick={onMakeItBulkClicked}
                                    className={classes.bulkUploadBtn}
                                >
                                    <Trans i18nKey="bulkUpload">Bulk Upload</Trans>
                                </Button>
                            </Grid>
                        )}
                        {isLargeCustomer && (
                            <Grid item container className={classes.addProducts}>
                                <MakeItRequestOptions
                                    onDateUpdate={handleDateUpdate}
                                    itemsInCart={checkOutList.length}
                                    lockedWindowDays={lockedPeriodDays}
                                    dateLimit={4}
                                    hideSubtext={true}
                                    selectedShipToId={selectedAccountId}
                                />
                            </Grid>
                        )}

                        <Grid container item justify="space-between" alignItems="baseline">
                            <Grid container item xs={9}>
                                {!isLargeCustomer && (
                                    <Typography variant="h3" className={classes.title}>
                                        <Trans i18nKey="selectProducts">
                                            Add products to your production order:
                                        </Trans>
                                    </Typography>
                                )}
                                {isLargeCustomer && (
                                    <Grid item className={classes.stepWrapper}>
                                        <Typography
                                            component="span"
                                            className={classes.stepNum}
                                            data-testid="step-number"
                                        >
                                            2
                                        </Typography>
                                        <Typography
                                            variant="h3"
                                            className={classes.stepTitle}
                                            data-testid="step-title"
                                        >
                                            <Trans i18nKey="selectProducts">
                                                {enUS.selectProducts}
                                            </Trans>
                                        </Typography>
                                    </Grid>
                                )}
                                {showViewCurrentCampaignsLink && (
                                    <Typography variant="h5" className={classes.subtitle}>
                                        <Trans i18nKey="lookingForCampaignSchedules">
                                            Looking for campaign schedules?
                                        </Trans>
                                        <Button
                                            variant="text"
                                            color="primary"
                                            data-testid="view-campaigns-button"
                                            className={classes.viewCampaignsButton}
                                            onClick={onViewCampaigns}
                                        >
                                            <Trans i18nKey="viewCurrentUpcomingCampaigns">
                                                View Current & Upcoming Campaigns
                                            </Trans>
                                        </Button>
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                        {isLargeCustomer && (
                            <QuantityUnitSelector
                                type={'MakeItAtm'}
                                styles={classes.quantityUnitSelector}
                            />
                        )}
                        <MakeItGrid
                            makeItItems={filteredViewProducts as OrderProductTableRow[]}
                            onAddItem={handleAddItem}
                            onViewFiltersClick={handleViewFilterClick}
                            onRangeFiltersClick={handleRangeFilterClick}
                            onInputBlur={handleInputBlur}
                            resetPagination={paginationReset}
                            onResetPagination={handleResetPagination}
                            storage={storage}
                        />
                    </Grid>
                    <Grid container item md={3} className={classes.side}>
                        <MakeItCheckout
                            isLargeCustomer={isLargeCustomer}
                            products={checkOutList}
                            onRemoveAll={handleRemoveAll}
                            onRemoveOrder={handleRemoveItem}
                        />
                    </Grid>
                </QuantityUnitSelectorProvider>
            </Grid>
            <OrderCancelModal
                open={open}
                saveProgress={onConfirmedCancel}
                navigationLink={dashboardLink}
                onClose={onCloseConfirmation}
                onCancel={onCloseConfirmation}
            />
            <SaveModal
                open={openSaveModal}
                saveDraft={handleSaveOrder}
                onClose={onCloseSaveModal}
                orderName={productionOrder && productionOrder.name ? productionOrder.name : ''}
                saveInputLabel={<Trans i18nKey="draftName">Draft Name</Trans>}
                error={error}
            />
            <SaveConfirmedModal
                title={<Trans i18nKey="makeDraftSaved">Save For Later</Trans>}
                open={onDraftSaved}
                onClose={onDraftSavedClose}
                onClear={handleBackNavigation}
                closeModalBtn={true}
                closeModalBtnLabel={<Trans i18nKey="continueEditing">Continue Editing</Trans>}
                successMessage={
                    <Trans i18nKey="yourOrderHasBeenSaved">
                        Your order has been saved successfully!
                    </Trans>
                }
            />
            {campaignRuns && (
                <CurrentCampaignsModal
                    title={
                        <Trans i18nKey="currentUpcomingCampaigns">
                            Current & Upcoming Campaigns
                        </Trans>
                    }
                    open={openViewCampaignsModal}
                    onClose={onCloseCampaignsModal}
                    campaignRuns={campaignRuns}
                    campaignProducts={campaignProducts}
                />
            )}
        </ProcessingPageTemplate>
    );
}
