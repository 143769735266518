import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Trans } from 'react-i18next';
import { lato } from '../../../../themes/globalConstants';
import { enUS } from '../../../../utility/translations/locales';

interface Props {
    submitted?: boolean;
}

const useStyles = makeStyles(() => ({
    text: {
        fontWeight: 'bold',
        fontFamily: lato
    },
    completeIcon: {
        marginRight: '0.25em',
        height: '1.5em'
    }
}));

export default function OnboardingSectionCompleted({ submitted }: Props) {
    const classes = useStyles();
    return (
        <Grid container alignItems="center">
            <img
                className={classes.completeIcon}
                alt="step complete"
                src={process.env.PUBLIC_URL + '/assets/Confirmation_Icon.svg'}
            />
            <Typography className={classes.text}>
                {submitted ? (
                    <Trans i18nKey="submitted">${enUS.submitted}</Trans>
                ) : (
                    <Trans i18nKey="completeStatus">${enUS.completeStatus}</Trans>
                )}
            </Typography>
        </Grid>
    );
}
