import React, { useState } from 'react';
import { makeStyles, Typography, TableRow, TableCell, Grid, Link } from '@material-ui/core';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { getAccountAddress } from '../../../../utility/helpers/address-helpers';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { ballBlue, blackWeight, boldWeight, medium, red } from '../../../../themes/globalConstants';
import {
    DeliveryOrderRow,
    ShippingSummaryState
} from '../../../../store/reducers/shipping-summary';
import {
    formatStatus,
    orderStatusCodeFormatter,
    updateDeliveryOrderWithProductInfo,
    deliveryOrderEditShouldBeDisabledBasedOnReviewStatus
} from '../../../../utility/helpers/order-helpers';
import { Trans } from 'react-i18next';
import Button from '../../../reusable/atoms/Button';
import moment from 'moment';
import { DeliveryOrder } from '../../../../store/reducers/shipping-dashboard';
import OrdersService, { Order_Status } from '../../../../utility/services/orders-service';
import { useDispatch } from 'react-redux';
import ShipItSummaryCancelDeliveryOrderModal from './ShipItSummaryCancelDeliveryOrderModal';
import {
    updateDeliveryOrder,
    updateDeliveryOrderProductsUpdateError,
    updateDeliveryOrderUpdateError
} from '../../../../store/actions/shipping-dashboard';
import { AuthState } from '../../../../store/reducers/auth';

interface Props {
    order: DeliveryOrderRow;
    pristineDeliveryOrder?: DeliveryOrder;
    showEditCancel: boolean;
}

const useStyles = makeStyles((theme) => ({
    name: {
        fontWeight: boldWeight,
        fontSize: medium,
        letterSpacing: 0.17
    },
    editButton: {
        fontSize: '1em',
        '&:hover': {
            backgroundColor: 'transparent',
            color: ballBlue,
            fontWeight: blackWeight,
            outline: 'none'
        },
        '&:focus': {
            backgroundColor: 'transparent',
            color: ballBlue,
            fontWeight: blackWeight
        }
    },
    cancelOrderButton: {
        color: red,
        fontSize: '1em',
        '&:hover': {
            backgroundColor: 'transparent',
            color: red,
            fontWeight: blackWeight,
            outline: 'none'
        },
        '&:focus': {
            backgroundColor: 'transparent',
            color: red,
            fontWeight: blackWeight
        }
    },
    cancelDeliveryOrderModalText: {
        margin: '2em'
    }
}));

export default function ShipItOpenDeliveriesRow({
    order,
    pristineDeliveryOrder,
    showEditCancel
}: Props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { accessToken } = useTypedSelector<AuthState>((state) => state.auth);
    const history = useHistory();
    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { openDeliveryOrders } = useTypedSelector<ShippingSummaryState>(
        (state) => state.shippingSummary
    );
    const [openCancelDeliveryOrder, setOpenCancelDeliveryOrder] = useState(false);
    const [submitting, setSubmitting] = useState<boolean>(false);

    const onCloseCancelDeliveryOrder = () => {
        setOpenCancelDeliveryOrder(false);
    };

    const formatShipToString = (deliveryOrderId?: number) => {
        if (shipToAccounts && openDeliveryOrders) {
            let filteredOrder = openDeliveryOrders.find(
                (ord) => deliveryOrderId === ord.deliveryOrderId
            );
            if (filteredOrder) {
                let filteredAccount = shipToAccounts.find(
                    (shipToAccount) => order.shipToId === +shipToAccount.accountId
                );
                if (filteredAccount) {
                    return (
                        <>
                            <Typography key={'ship-to-' + deliveryOrderId} data-testid="shipto-id">
                                {filteredAccount.name}
                            </Typography>
                            <Typography
                                key={'ship-to-address-' + deliveryOrderId}
                                data-testid="shipto-address"
                            >
                                {getAccountAddress(filteredAccount)}
                            </Typography>
                        </>
                    );
                }
            }
        }
    };

    const formatDeliveryOrderNumber = (deliveryOrderNumber?: string, deliveryOrderId?: number) => {
        if (deliveryOrderNumber && deliveryOrderNumber !== '0') {
            return (
                <>
                    <Link
                        to={`/delivery-order/${deliveryOrderId}`}
                        component={RouterLink}
                        underline="none"
                    >
                        {deliveryOrderNumber}
                    </Link>
                </>
            );
        } else {
            return (
                <em>
                    <Link
                        to={`/delivery-order/${deliveryOrderId}`}
                        component={RouterLink}
                        underline="none"
                    >
                        <Trans i18nKey="pending">Pending</Trans>
                    </Link>
                </em>
            );
        }
    };

    const actions = (order: DeliveryOrderRow) => {
        if (showEditCancel) {
            return (
                <TableCell>
                    <Button
                        variant="text"
                        color="primary"
                        data-testid="edit-button"
                        className={classes.editButton}
                        onClick={() => onEdit(order.deliveryOrderId)}
                        disabled={isEditDisabled(order)}
                    >
                        <Trans i18nKey="edit">Edit</Trans>
                    </Button>
                    <Button
                        type="button"
                        color="primary"
                        variant="text"
                        data-testid="cancel-order-btn"
                        className={classes.cancelOrderButton}
                        onClick={() => onCancel()}
                        disabled={isCancelDisabled(order)}
                    >
                        <Trans i18nKey="cancelOrder">Cancel Order</Trans>
                    </Button>
                </TableCell>
            );
        }
    };

    const isEditDisabled = (order: DeliveryOrderRow) => {
        if (order.isLocked) {
            return true;
        } else if (deliveryOrderEditShouldBeDisabledBasedOnReviewStatus(order)) {
            return true;
        } else if (order.latestShipmentDate) {
            let currentDate = moment();
            let latestShipDate = moment(order.latestShipmentDate);
            let hourDiff = latestShipDate.diff(currentDate, 'hours');
            return hourDiff < 24;
        } else {
            return true;
        }
    };

    const onEdit = (deliveryOrderId?: number) => {
        if (deliveryOrderId) {
            history.push(`/edit-shipments-summary/${order.deliveryOrderId}`);
        }
    };

    const isCancelDisabled = (order: DeliveryOrderRow) => {
        if (order.isLocked) {
            return true;
        } else if (deliveryOrderEditShouldBeDisabledBasedOnReviewStatus(order)) {
            return true;
        } else {
            if (order.earliestShipmentDate) {
                let currentDate = moment();
                let earliestShipDate = moment(order.earliestShipmentDate);
                let hourDiff = earliestShipDate.diff(currentDate, 'hours');
                return hourDiff < 24;
            } else {
                return true;
            }
        }
    };

    const onCancel = () => {
        setOpenCancelDeliveryOrder(true);
    };

    const handleSubmitChanges = () => {
        if (pristineDeliveryOrder) {
            setSubmitting(true);
            let cancelledDeliveryOrder = pristineDeliveryOrder;
            cancelledDeliveryOrder.status = Order_Status.CancelledPending;
            OrdersService.updateShipmentDeliveryOrder(accessToken, cancelledDeliveryOrder)
                .then((response) => {
                    let updatedDeliveryOrder = { ...response.data };

                    OrdersService.getDeliveryOrderProducts(accessToken, [
                        updatedDeliveryOrder.shipToId as number
                    ])
                        .then((response) => {
                            const availableProducts = response.data.products;
                            updatedDeliveryOrder = updateDeliveryOrderWithProductInfo(
                                updatedDeliveryOrder,
                                availableProducts
                            );
                            dispatch(updateDeliveryOrder(updatedDeliveryOrder));
                            history.push('/cancel-delivery-order-confirmation');
                        })
                        .catch((error) => {
                            setSubmitting(false);
                            dispatch(updateDeliveryOrderProductsUpdateError(error));
                        });
                })
                .catch((error) => {
                    setSubmitting(false);
                    dispatch(updateDeliveryOrderUpdateError(error));
                });
        }
    };

    return (
        <>
            <TableRow
                hover={true}
                key={order.deliveryOrderId}
                data-testid={`${order.deliveryOrderId}-order-row`}
            >
                <TableCell>
                    <Grid container>
                        <Grid item xs={12} className={classes.name} data-testid="ship-to-info">
                            {formatShipToString(order.deliveryOrderId)}
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            className={classes.name}
                            data-testid="delivery-order-number"
                        >
                            <Typography>
                                {formatDeliveryOrderNumber(
                                    order.deliveryOrderNumber,
                                    order.deliveryOrderId
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid container>
                        <Grid item xs={12} className={classes.name} data-testid="order-status">
                            <Typography key={'product-status-' + order.deliveryOrderId}>
                                <Trans
                                    i18nKey={orderStatusCodeFormatter(
                                        formatStatus(order.status, order.reviewStatus)
                                    )}
                                >
                                    {formatStatus(order.status, order.reviewStatus)}
                                </Trans>
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid container>
                        <Grid item xs={12} className={classes.name} data-testid="submitted-date">
                            <Typography>{order.formattedSubmittedDate}</Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid container>
                        <Grid item xs={12} className={classes.name} data-testid="delivery-date">
                            <Typography>{order.formattedShipmentDeliveryDateTime}</Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid container>
                        <Grid item xs={12} className={classes.name} data-testid="order-quantity">
                            <Typography>{order.orderQuantityContent}</Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                {actions(order)}
            </TableRow>
            <ShipItSummaryCancelDeliveryOrderModal
                deliveryOrderNumber={order.deliveryOrderNumber}
                open={openCancelDeliveryOrder}
                onClose={onCloseCancelDeliveryOrder}
                onContinue={handleSubmitChanges}
                submitting={submitting}
            />
        </>
    );
}
