import { Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { TradeItRequestState } from '../../../../store/reducers/trade-it';
import { ballGray, blackWeight, medium, small } from '../../../../themes/globalConstants';
import { isProductEndType } from '../../../../utility/helpers/order-helpers';
import clsx from 'clsx';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    subtitle: {
        textTransform: 'uppercase',
        fontWeight: blackWeight,
        color: theme.palette.secondary.main
    },
    rightText: {
        textAlign: 'right'
    },
    typeLabel: {
        textTransform: 'uppercase',
        color: ballGray,
        fontSize: small,
        textAlign: 'right'
    },
    shipToName: {
        fontSize: medium,
        letterSpacing: '0.2',
        textTransform: 'uppercase'
    },
    subheaderBody: {
        fontSize: medium,
        letterSpacing: '0.2'
    },
    eaches: {
        color: ballGray,
        fontSize: small,
        textAlign: 'right'
    },
    spacer: {
        marginTop: '1em'
    }
}));
export default function TradeItConfirmationSubheader() {
    const classes = useStyles();
    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { submittedRequest, products } = useTypedSelector<TradeItRequestState>(
        (store) => store.tradeItRequest
    );
    const [accountFromName, setAccountFromName] = useState('');
    const [accountFromAddress, setAccountFromAddress] = useState('');
    const [accountToName, setAccountToName] = useState('');
    const [accountToAddress, setAccountToAddress] = useState('');
    const [requestCanSkus, setRequestCanSkus] = useState(2);
    const [requestCanPallets, setRequestCanPallets] = useState(0);
    const [requestEndSkus, setRequestEndSkus] = useState(0);
    const [requestEndPallets, setRequestEndPallets] = useState(0);
    const [requestCanEaches, setRequestCanEaches] = useState(0);
    const [requestEndEaches, setRequestEndEaches] = useState(0);

    useEffect(() => {
        if (shipToAccounts && submittedRequest) {
            const accountFrom = shipToAccounts.find(
                (account) => account.accountId === submittedRequest.accountFrom
            );
            const accountTo = shipToAccounts.find(
                (account) => account.accountId === submittedRequest.accountTo
            );
            if (accountFrom) {
                setAccountFromName(accountFrom.name);
                if (accountFrom.address) {
                    const accountFromAddress = accountFrom.address;
                    setAccountFromAddress(
                        accountFromAddress.address1?.trimEnd() +
                            ', ' +
                            accountFromAddress.city +
                            ', ' +
                            accountFromAddress.stateProvince +
                            ' ' +
                            accountFromAddress.postalCode
                    );
                }
            }
            if (accountTo) {
                setAccountToName(accountTo.name);
                if (accountTo.address) {
                    const accountToAddress = accountTo.address;
                    setAccountToAddress(
                        accountToAddress.address1?.trimEnd() +
                            ', ' +
                            accountToAddress.city +
                            ', ' +
                            accountToAddress.stateProvince +
                            ' ' +
                            accountToAddress.postalCode
                    );
                }
            }
        }
    }, [shipToAccounts, submittedRequest]);

    useEffect(() => {
        let canSkus = 0;
        let canPallets = 0;
        let endSkus = 0;
        let endPallets = 0;
        let canEaches = 0;
        let endEaches = 0;
        if (products && submittedRequest) {
            submittedRequest.requestLines.forEach((line) => {
                const requestedProduct = products.find(
                    (product) => product.productSku === line.productSku
                );
                if (requestedProduct) {
                    if (isProductEndType(requestedProduct.type)) {
                        endSkus += 1;
                        endPallets += line.quantity;
                        endEaches += requestedProduct.quantityPerPallet
                            ? line.quantity * requestedProduct.quantityPerPallet
                            : 0;
                    } else {
                        canSkus += 1;
                        canPallets += line.quantity;
                        canEaches += requestedProduct.quantityPerPallet
                            ? line.quantity * requestedProduct.quantityPerPallet
                            : 0;
                    }
                }
            });
        }
        setRequestCanSkus(canSkus);
        setRequestCanPallets(canPallets);
        setRequestEndSkus(endSkus);
        setRequestEndPallets(endPallets);
        setRequestCanEaches(canEaches);
        setRequestEndEaches(endEaches);
    }, [products, submittedRequest]);

    return (
        <>
            <Grid container>
                <Grid container xs={7} justify="flex-start"></Grid>
                <Grid container xs={5} justify="flex-end">
                    <Grid item xs={4}>
                        <Typography className={classes.typeLabel}>
                            <Trans i18nKey="cans">Cans</Trans>
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography className={classes.typeLabel}>
                            <Trans i18nKey="ends">Ends</Trans>
                        </Typography>
                    </Grid>
                    <Grid item xs={4}></Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid container xs={7} justify="flex-start">
                    <Grid item xs={6}>
                        <Typography variant="h5" className={classes.subtitle}>
                            <Trans i18nKey="originalShipTo">Original Ship To</Trans>
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h5" className={classes.subtitle}>
                            <Trans i18nKey="receivingShipTo">Receiving Ship To</Trans>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container xs={5} justify="flex-end">
                    <Grid item xs={4}>
                        <Typography className={clsx(classes.subtitle, classes.rightText)}>
                            {`${requestCanPallets} PL`}
                            {requestCanSkus > 0 ? ` / ${requestCanSkus} SKU` : ''}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography
                            variant="h5"
                            className={clsx(classes.subtitle, classes.rightText)}
                        >
                            {`${requestEndPallets} PL`}
                            {requestEndSkus > 0 ? ` / ${requestEndSkus} SKU` : ''}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography
                            variant="h5"
                            className={clsx(classes.subtitle, classes.rightText)}
                        >
                            <Trans i18nKey="dateSubmitted">Date Submitted</Trans>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid container xs={7} justify="flex-start">
                    <Grid item xs={6}>
                        <Typography className={classes.shipToName}>{accountFromName}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.shipToName}>{accountToName}</Typography>
                    </Grid>
                    <Grid item xs={6}></Grid>
                </Grid>
                <Grid container xs={5} justify="flex-end">
                    <Grid item xs={4}>
                        <Typography className={clsx(classes.eaches, classes.rightText)}>
                            {`${requestCanEaches.toLocaleString()} ea`}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography className={clsx(classes.eaches, classes.rightText)}>
                            {`${requestEndEaches.toLocaleString()} ea`}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography className={clsx(classes.subheaderBody, classes.rightText)}>
                            {submittedRequest?.submittedDate &&
                                moment(submittedRequest.submittedDate).format('MM/DD/YYYY')}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid container xs={7} justify="flex-start">
                    <Grid item xs={6}>
                        <Typography className={classes.subheaderBody}>
                            {accountFromAddress}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.subheaderBody}>
                            {accountToAddress}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}></Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid container xs={7} justify="flex-start">
                    <Grid item xs={6}>
                        <Typography className={classes.subheaderBody}>
                            {`#${submittedRequest?.accountFrom}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.subheaderBody}>
                            {`#${submittedRequest?.accountTo}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}></Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid container xs={7} justify="flex-start" className={classes.spacer}>
                    <Grid item xs={6}>
                        <Typography variant="h5" className={classes.subtitle}>
                            <Trans i18nKey="originalShipToContact">Original Ship To Contact</Trans>
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h5" className={classes.subtitle}>
                            <Trans i18nKey="receivingShipToContact">
                                Receiving Ship To Contact
                            </Trans>
                        </Typography>
                    </Grid>
                    <Grid item xs={6}></Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid container xs={7} justify="flex-start">
                    <Grid item xs={6}>
                        <Typography className={classes.subheaderBody}>
                            {`${submittedRequest?.fillerContactFrom}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.subheaderBody}>
                            {`${submittedRequest?.fillerContactTo}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}></Grid>
                </Grid>
            </Grid>
        </>
    );
}
