import React, { useEffect } from 'react';
import PdfPageTemplate from '../../../templates/PdfPageTemplate';
import { Document, Page, Text, Image, View, BlobProvider } from '@react-pdf/renderer';
import pdfStyles from '../../../../themes/pdfStyles';
import { isIE } from '../../../../utility/helpers/user-helpers';
import { useTranslation, Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DefaultQueryParams } from '../../../../utility/helpers/query-helpers';
import { useDispatch } from 'react-redux';
import { getGraphic } from '../../../../store/actions/graphic-intake';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { Font } from '@react-pdf/renderer';
import { Culture, RegionCultureState } from '../../../../store/reducers/region-culture';
import Header from '../../../reusable/molecules/Header';
import { EmeaGraphics } from '../../../../store/reducers/graphic-intake';
import NotFoundPage from '../../NotFound/NotFoundPage';

const EmeaGraphicsPDF = () => {
    const ballLogo = process.env.PUBLIC_URL + '/assets/Ball_RGB.jpg';
    const sourceLogo = process.env.PUBLIC_URL + '/assets/TheSource_Logo.png';
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { id } = useParams<DefaultQueryParams>();
    const graphic: EmeaGraphics = useTypedSelector(
        (state) => state.graphicIntake.graphics,
        () => false
    );
    const { cultureCode } = useTypedSelector<RegionCultureState>(
        (state) => state.regionCulture,
        () => false
    );

    useEffect(() => {
        if (id) dispatch(getGraphic(id));
    }, [id, dispatch]);

    // Prevent word hyphenation
    const hyphenationCallback = (word) => [word];
    Font.registerHyphenationCallback(hyphenationCallback);

    const yesOrNo = (value: boolean | undefined) => {
        if (value) return <Trans i18nKey="yes">Yes</Trans>;
        else return <Trans i18nKey="no">No</Trans>;
    };

    const pdf = (
        <Document title={t('graphics', 'Graphics')}>
            <Page
                size="A4"
                style={cultureCode === Culture.ruRU ? pdfStyles.russianPage : pdfStyles.page}
                orientation={'portrait'}
            >
                <View style={pdfStyles.logoSection}>
                    <Image src={sourceLogo} style={pdfStyles.sourceLogo} />
                    <Image src={ballLogo} style={pdfStyles.ballLogo} />
                </View>
                <View style={pdfStyles.divider6} />

                <View style={[pdfStyles.flexSection, pdfStyles.pageHeader]}>
                    <View>
                        <Text style={pdfStyles.header5}>
                            <Trans i18nKey="graphics">GRAPHICS</Trans>
                        </Text>
                    </View>
                    <View>
                        <View>
                            <Text style={pdfStyles.fieldLabel}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="companyName">Company Name</Trans>
                                    {' / '}
                                    <Trans i18nKey="companyId">Company ID</Trans>
                                </Text>
                            </Text>
                        </View>
                        <View>
                            <Text style={pdfStyles.fieldText}>
                                {graphic?.customerName}
                                {' / '}
                                {graphic?.customerAccount}
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={pdfStyles.divider6} />
                <View style={pdfStyles.tableRow}>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.header2}>
                            <Trans i18nKey="contractFiller">Contract Filler</Trans>
                        </Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.header2}>
                            <Trans i18nKey="brandOwner">Brand Owner</Trans>
                        </Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.header2}>
                            <Trans i18nKey="canSize">Can Size</Trans>
                        </Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.header2}>
                            <Trans i18nKey="canType">Can Type</Trans>
                        </Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.header2}>
                            <Trans i18nKey="metalType">Metal</Trans>
                        </Text>
                    </View>
                </View>
                <View style={pdfStyles.tableRow}>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.subtitle8}>{graphic?.contractFiller}</Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.subtitle8}>{graphic?.brandOwner}</Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.subtitle8}>{graphic?.canSize}</Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.subtitle8}>{graphic?.canType}</Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.subtitle8}>{graphic?.metalType}</Text>
                    </View>
                </View>

                <View style={[pdfStyles.tableRow, pdfStyles.marginTop5]}>
                    <View style={[pdfStyles.borderLessTableCol1]}>
                        <Text style={pdfStyles.header6}>
                            <Trans i18nKey="designSubInfo">DESIGN INFORMATION</Trans>
                        </Text>
                    </View>
                </View>
                <View style={pdfStyles.tableRow}>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.header2}>
                            <Trans i18nKey="requestType">Type of Request</Trans>
                        </Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.header2}>
                            <Trans i18nKey="designPoNo">Design Development PO no.</Trans>
                        </Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.header2}>
                            <Trans i18nKey="previousBallDesignNum">Previous Ball Design no.</Trans>
                        </Text>
                    </View>
                </View>
                <View style={pdfStyles.dividerThinGray2} />
                <View style={pdfStyles.tableRow}>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.subtitle8}>{graphic?.requestType}</Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.subtitle8}>{graphic?.designPoNo}</Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.subtitle8}>{graphic?.existing}</Text>
                    </View>
                </View>
                <View style={pdfStyles.dividerThinGray2} />
                <View style={[pdfStyles.tableRow, pdfStyles.marginTop5]}>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.header2}>
                            <Trans i18nKey="designName">Design Name</Trans>
                        </Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.header2}>
                            <Trans i18nKey="newCustRefNum">Customer Reference no.</Trans>
                        </Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.header2}>
                            <Trans i18nKey="specialFinish">Ball Special Finishes</Trans>
                        </Text>
                    </View>
                </View>
                <View style={pdfStyles.dividerThinGray2} />
                <View style={pdfStyles.tableRow}>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.subtitle8}>{graphic?.designName}</Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.subtitle8}>{graphic?.newCustRefNum}</Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.subtitle8}>{graphic?.specialFinish}</Text>
                    </View>
                </View>
                <View style={pdfStyles.dividerThinGray2} />
                <View style={[pdfStyles.tableRow, pdfStyles.marginTop5]}>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.header2}>
                            <Trans i18nKey="barcode">Barcode</Trans>
                        </Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.header2}>
                            <Trans i18nKey="barcodeBarsColor">Barcode Colour - Bars</Trans>
                        </Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.header2}>
                            <Trans i18nKey="barcodeInfillColor">Barcode Colour - Infill</Trans>
                        </Text>
                    </View>
                </View>
                <View style={pdfStyles.dividerThinGray2} />
                <View style={pdfStyles.tableRow}>

                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.subtitle8}>{graphic?.barcode}</Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.subtitle8}>{graphic?.barcodeBarsColor}</Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.subtitle8}>{graphic?.barcodeInfillColor}</Text>
                    </View>
                </View>
                <View style={pdfStyles.dividerThinGray2} />
                <View style={[pdfStyles.tableRow, pdfStyles.marginTop5]}>
                    <View style={[pdfStyles.borderLessTableCol1]}>
                        <Text style={pdfStyles.header6}>
                            <Trans i18nKey="colourSubInfo">COLOUR INFORMATION</Trans>
                        </Text>
                    </View>
                </View>
                <View style={pdfStyles.tableRow}>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.header2}>
                            <Trans i18nKey="overVarnish">Over Varnish</Trans>
                        </Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.header2}>
                            <Trans i18nKey="specialInk">Ball Special Ink</Trans>
                        </Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.header2}>
                            <Trans i18nKey="colourTarget">Colour Target</Trans>
                        </Text>
                    </View>
                </View>
                <View style={pdfStyles.dividerThinGray2} />
                <View style={pdfStyles.tableRow}>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.subtitle8}>{graphic?.overvarnish}</Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.subtitle8}>{graphic?.specialInk}</Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.subtitle8}>{graphic?.colourTarget}</Text>
                    </View>
                </View>
                <View style={pdfStyles.dividerThinGray2} />

                <View style={[pdfStyles.tableRow, pdfStyles.marginTop5]}>
                    <View style={[pdfStyles.borderLessTableCol1]}>
                        <Text style={pdfStyles.header6}>
                            <Trans i18nKey="marketSubInfo">MARKET INFORMATION</Trans>
                        </Text>
                    </View>
                </View>
                <View style={pdfStyles.tableRow}>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.header2}>
                            <Trans i18nKey="distributeCountries">Countries to Market Artwork</Trans>
                        </Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.header2}>
                            <Trans i18nKey="depositScheme">Deposit Scheme</Trans>
                        </Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.header2}>
                            <Trans i18nKey="recycleMark">
                                Metals Recycle Forever Mark on Design?
                            </Trans>
                        </Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.header2}>
                            <Trans i18nKey="asiCertified">Is this design ASI certified?</Trans>
                        </Text>
                    </View>
                </View>
                <View style={pdfStyles.dividerThinGray2} />
                <View style={pdfStyles.tableRow}>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.subtitle8}>
                            {graphic?.distributeCountries?.join(', ')}
                        </Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.subtitle8}>{graphic?.depositScheme}</Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.subtitle8}>{yesOrNo(graphic?.recycleMark)}</Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.subtitle8}>{yesOrNo(graphic?.asiCertified)}</Text>
                    </View>
                </View>
                {(graphic?.glnNumber && (
                    <>
                        <View style={pdfStyles.dividerThinGray2} />
                        <View style={pdfStyles.tableRow}>
                            <View style={[pdfStyles.borderLessTableCol]}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="glnNumber">Customer GLN Number</Trans>
                                </Text>
                            </View>
                        </View>
                        <View style={pdfStyles.dividerThinGray2} />
                        <View style={pdfStyles.tableRow}>
                            <View style={[pdfStyles.borderLessTableCol]}>
                                <Text style={pdfStyles.subtitle8}>
                                    {graphic?.glnNumber}
                                </Text>
                            </View>
                        </View>
                        <View style={pdfStyles.dividerThinGray2} />
                    </>
                ))}

                <View style={[pdfStyles.tableRow, pdfStyles.marginTop5]}>
                    <View style={[pdfStyles.borderLessTableCol1]}>
                        <Text style={pdfStyles.header6}>
                            <Trans i18nKey="productQualitySubInfo">
                                PRODUCT QUALITY SPECIFICATION
                            </Trans>
                        </Text>
                    </View>
                </View>
                <View style={pdfStyles.tableRow}>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.header2}>
                            <Trans i18nKey="productContent">Product Content</Trans>
                        </Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.header2}>
                            <Trans i18nKey="productFormulation">Product Formulation</Trans>
                        </Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.header2}>
                            <Trans i18nKey="productPasteurisation">Product Pasteurisation</Trans>
                        </Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.header2}>
                            <Trans i18nKey="refDesignNum">Design no. to Reference</Trans>
                        </Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.header2}>
                            <Trans i18nKey="bpaFree">BPANI</Trans>
                        </Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.header2}>
                            <Trans i18nKey="bpaniId">BPANI Identification on Design?</Trans>
                        </Text>
                    </View>
                </View>
                <View style={pdfStyles.dividerThinGray2} />
                <View style={pdfStyles.tableRow}>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.subtitle8}>{graphic?.productContent}</Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.subtitle8}>{graphic?.productFormulation}</Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.subtitle8}>{graphic?.productPasteurisation}</Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.subtitle8}>{graphic?.refDesignNum}</Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.subtitle8}>{yesOrNo(graphic?.bpaFree)}</Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol]}>
                        <Text style={pdfStyles.subtitle8}>{yesOrNo(graphic?.bpaniId)}</Text>
                    </View>
                </View>
                <View style={pdfStyles.dividerThinGray2} />

                <View wrap={false}>
                    <View style={[pdfStyles.tableRow, pdfStyles.marginTop5]}>
                        <View style={[pdfStyles.borderLessTableCol1]}>
                            <Text style={pdfStyles.header6}>
                                <Trans i18nKey="approvalSubInfo">FOR APPROVAL</Trans>
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                        <View style={[pdfStyles.borderLessTableCol]}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="proofSession">Proof Session Required?</Trans>
                            </Text>
                        </View>
                        <View style={[pdfStyles.borderLessTableCol]}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="attendProduction">Attend Production?</Trans>
                            </Text>
                        </View>
                        <View style={[pdfStyles.borderLessTableCol]}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="responsibleForApproval">
                                    Responsible for Approval
                                </Trans>
                            </Text>
                        </View>
                        <View style={[pdfStyles.borderLessTableCol]}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="phoneNumber">Phone Number</Trans>
                            </Text>
                        </View>
                        <View style={[pdfStyles.borderLessTableCol]}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="emailAddress">Email Address</Trans>
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.dividerThinGray2} />
                    <View style={pdfStyles.tableRow}>
                        <View style={[pdfStyles.borderLessTableCol]}>
                            <Text style={pdfStyles.subtitle8}>
                                {yesOrNo(graphic?.proofSession)}
                            </Text>
                        </View>
                        <View style={[pdfStyles.borderLessTableCol]}>
                            <Text style={pdfStyles.subtitle8}>
                                {yesOrNo(graphic?.attendProduction)}
                            </Text>
                        </View>
                        <View style={[pdfStyles.borderLessTableCol]}>
                            <Text style={pdfStyles.subtitle8}>
                                {graphic?.approvers && graphic?.approvers.length
                                    ? graphic?.approvers[0].approverName
                                    : ''}
                            </Text>
                        </View>
                        <View style={[pdfStyles.borderLessTableCol]}>
                            <Text style={pdfStyles.subtitle8}>
                                {graphic?.approvers && graphic?.approvers.length
                                    ? graphic?.approvers[0].approverPhoneNumber
                                    : ''}
                            </Text>
                        </View>
                        <View style={[pdfStyles.borderLessTableCol]}>
                            <Text style={pdfStyles.subtitle8}>
                                {' '}
                                {graphic?.approvers && graphic?.approvers.length
                                    ? graphic?.approvers[0].approverEmail
                                    : ''}
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.dividerThinGray2} />
                </View>

                <View wrap={false}>
                    <View style={[pdfStyles.tableRow, pdfStyles.marginTop5]}>
                        <View style={[pdfStyles.borderLessTableCol1]}>
                            <Text style={pdfStyles.header6}>
                                <Trans i18nKey="generalCommentsSubInfo">GENERAL COMMENTS</Trans>
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                        <View style={[pdfStyles.borderLessTableCol1]}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="generalComments">General Comments</Trans>
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.dividerThinGray2} />
                    <View style={pdfStyles.tableRow}>
                        <View style={[pdfStyles.borderLessTableCol1]}>
                            <Text style={pdfStyles.subtitle8}>{graphic?.additionalRemarks}</Text>
                        </View>
                    </View>
                    <View style={pdfStyles.dividerThinGray2} />
                    <View style={pdfStyles.tableRow}>
                        <View style={[pdfStyles.borderLessTableCol1]}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="responsibleForCompletion">
                                    Responsible for Completion
                                </Trans>
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.dividerThinGray2} />
                    <View style={pdfStyles.tableRow}>
                        <View style={[pdfStyles.borderLessTableCol1]}>
                            <Text style={pdfStyles.subtitle8}>{graphic?.email}</Text>
                        </View>
                    </View>
                    <View style={pdfStyles.dividerThinGray2} />
                </View>
                {graphic?.fileComment && (
                    <View wrap={false}>
                        <View style={[pdfStyles.tableRow, pdfStyles.marginTop5]}>
                            <View style={[pdfStyles.borderLessTableCol1]}>
                                <Text style={pdfStyles.header6}>
                                    <Trans i18nKey="uploadGraphicsAndSubmit">UPLOAD GRAPHIC & SUBMIT</Trans>
                                </Text>
                            </View>
                        </View>
                        <View style={pdfStyles.tableRow}>
                            <View style={[pdfStyles.borderLessTableCol1]}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="artworkComments">Artwork Comments</Trans>
                                </Text>
                            </View>
                        </View>
                        <View style={pdfStyles.dividerThinGray2} />
                        <View style={pdfStyles.tableRow}>
                            <View style={[pdfStyles.borderLessTableCol1]}>
                                <Text style={pdfStyles.subtitle8}>{graphic?.fileComment}</Text>
                            </View>
                        </View>
                        <View style={pdfStyles.dividerThinGray2} />
                    </View>
                )}
            </Page>
        </Document>
    );

    if (graphic === null) {
        return <NotFoundPage />;
    }

    if (!isIE()) {
        return (
            <>
                <Header title="Graphic PDF" loading={false} />
                <PdfPageTemplate title={t('graphics', 'Graphics')} hideBanner>
                    {pdf}
                </PdfPageTemplate>
            </>
        );
    } else {
        return (
            <>
                <Header title="Graphic PDF" loading={false} />
                <BlobProvider document={pdf}>
                    {({ blob, url, loading, error }) => {
                        if (blob && window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(blob);
                        }
                        return null;
                    }}
                </BlobProvider>
            </>
        );
    }
};

export default EmeaGraphicsPDF;
