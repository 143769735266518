import React, { useState } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import Link from '../../../reusable/atoms/Link';
import { Trans, useTranslation } from 'react-i18next';
import { medium, regularWeight, ballBlue } from '../../../../themes/globalConstants';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import Modal from '../../../reusable/molecules/Modal';
import DeliveryOrderPDF from './DeliveryOrderPDF';
import {
    DeliveryOrder,
    LinkedProductionOrders
} from '../../../../store/reducers/shipping-dashboard';
import {
    getSimpleFormattedDate,
    getSimpleFormattedTime
} from '../../../../utility/helpers/date-helpers';
import { CSVLink } from 'react-csv';
import ReactPDF from '@react-pdf/renderer';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';

interface Props {
    shipToName: string;
    shipToAddress: string;
    order: DeliveryOrder;
    linkedDeliveryOrder?: LinkedProductionOrders;
}

const useStyles = makeStyles((theme) => ({
    label: {
        textTransform: 'uppercase',
        fontSize: medium,
        paddingTop: '0.5em'
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        marginTop: '0.5em'
    },
    link: {
        fontSize: medium,
        fontWeight: regularWeight,
        width: '8.75em',
        '&:hover': {
            cursor: 'pointer'
        },
        color: ballBlue
    },
    icon: {
        color: ballBlue
    }
}));
const pdfStyles = ReactPDF.StyleSheet.create({
    link: {
        fontSize: medium,
        fontWeight: regularWeight
    }
});

const DeliveryOrderShareLinks = ({
    shipToName,
    shipToAddress,
    order,
    linkedDeliveryOrder
}: Props) => {
    const classes = useStyles();
    const [openPdf, setOpenPdf] = useState(false);
    const { t } = useTranslation();

    const printForm = () => {
        setOpenPdf(true);
    };

    const onClose = () => {
        setOpenPdf(false);
    };

    interface CSVRow {
        shipment_number?: number;
        arr_date?: string;
        arr_time?: string;
        load_seq?: number;
        product_id?: string;
        graphic_id?: string;
        product_name?: string;
        descr: string;
        quantity_ordered?: number;
        eaches_ordered?: number;
        release_number?: string;
        filler_line?: string;
        reference_number?: string;
        po_number?: string;
        shipToId?: number;
        customer_po?: string;
    }

    const csvHeaders = [
        { label: t('shipmentNumber', 'Shipment #').toUpperCase(), key: 'shipment_number' },
        { label: t('deliveryDate', 'Delivery Date').toUpperCase(), key: 'arr_date' },
        { label: t('deliveryDate', 'Delivery Date').toUpperCase(), key: 'arr_time' },
        { label: t('loadSeq', 'LOAD SEQ').toUpperCase(), key: 'load_seq' },
        { label: t('productId', 'PRODUCT ID').toUpperCase(), key: 'product_id' },
        { label: t('graphicID', 'GRAPHIC ID').toUpperCase(), key: 'graphic_id' },
        { label: t('productName', 'PRODUCT NAME').toUpperCase(), key: 'product_name' },
        {
            label: t('descriptionCharacteristics', 'PRODUCT DESC / CHARACTERISTICS').toUpperCase(),
            key: 'descr'
        },
        { label: t('quantityPL', 'QUANTITY (PL)').toUpperCase(), key: 'quantity_ordered' },
        { label: t('quantityEA', 'QUANTITY (EA)').toUpperCase(), key: 'eaches_ordered' },
        { label: t('releaseNumberPDF', 'RELEASE NUMBER').toUpperCase(), key: 'release_number' },
        { label: t('fillerLineNumber', 'FILLER LINE #').toUpperCase(), key: 'filler_line' },
        {
            label: t('referenceNumberPDF', 'REFERENCE NUMBER').toUpperCase(),
            key: 'reference_number'
        },
        { label: t('shipToId', 'SHIP TO ID').toUpperCase(), key: 'shipToId' },
        { label: t('customerPoNumber', 'CUSTOMER PO#').toUpperCase(), key: 'customer_po' }
    ];

    const csvArray = () => {
        let rows : any[] = [];
        order.shipments?.map((shipment) => {
            shipment.loads?.map((load) => {
                const seq = shipment.loads?.indexOf(load);
                const eachesAmount =
                    load.quantityPerPallet && load.palletQuantity
                        ? load.quantityPerPallet * load.palletQuantity
                        : 0;
                const row: CSVRow = {
                    shipment_number: shipment.truckId,
                    arr_date: getSimpleFormattedDate('', shipment.deliveryDateTime),
                    arr_time: getSimpleFormattedTime('', shipment.deliveryDateTime),
                    load_seq: seq! + 1,
                    product_id: load.displayId,
                    graphic_id: load.productGraphicId,
                    product_name: load.displayName,
                    descr: load.description ?? '',
                    quantity_ordered: load.palletQuantity,
                    eaches_ordered: eachesAmount,
                    release_number: load.releaseNumber,
                    filler_line: load.fillerLine,
                    reference_number: load.referenceNumber,
                    shipToId: order.shipToId,
                    customer_po: load.purchaseOrderNumber
                };
                rows.push(row);
                return null;
            });
            return null;
        });
        return rows;
    };

    const csvContents = csvArray();

    return (
        <Grid container item xs={12} spacing={2} alignItems="center" justify="flex-end">
            <Grid item>
                <Typography variant="h6" className={classes.label}>
                    <Trans i18nKey="share">share</Trans>
                </Typography>
            </Grid>
            <Grid item className={classes.iconContainer}>
                <Link
                    className={classes.link}
                    underline="always"
                    data-testid="print-btn"
                    onClick={printForm}
                >
                    <Typography>
                        <Trans i18nKey="print">Print</Trans>
                    </Typography>
                </Link>
                <PrintOutlinedIcon fontSize="small" className={classes.icon} />
            </Grid>
            <Grid item className={classes.iconContainer}>
                <CSVLink
                    className={
                        'MuiTypography-root MuiLink-root MuiLink-underlineAlways MuiTypography-colorPrimary'
                    }
                    style={pdfStyles.link}
                    underline="always"
                    data-testid="download-btn"
                    headers={csvHeaders}
                    data={csvContents}
                    filename={`${order.deliveryOrderId}.csv`}
                >
                    <Typography>
                        <Trans i18nKey="downloadCSV">Download .CSV</Trans>
                    </Typography>
                </CSVLink>
                <GetAppOutlinedIcon fontSize="small" className={classes.icon} />
            </Grid>
            <Modal
                open={openPdf}
                title={t('deliveryOrderDetails', 'Delivery Order Details')}
                close={onClose}
                closeIcon={true}
                fullScreen={true}
            >
                <DeliveryOrderPDF
                    shipToAddress={shipToAddress}
                    shipToName={shipToName}
                    order={order}
                    title={t('deliveryOrderDetails', 'Delivery Order Details')}
                    linkedDeliveryOrder={linkedDeliveryOrder}
                />
            </Modal>
        </Grid>
    );
};

export default DeliveryOrderShareLinks;
