import moment from 'moment';
import {
    MakeItBulkATMLineItem,
    MakeItBulkATMOrder,
    SnoSkuAllocationsByWeek
} from '../../../../store/reducers/makeit-bulk-atm';
import { MakeItBulkLineItem } from '../../../../store/reducers/makeit-bulk-upload';
import { ProductionOrder, ProductionOrderLine } from '../../../../store/reducers/makeit-dashboard';
import { isDateWithinAtmLockPeriod } from '../../../../utility/helpers/date-helpers';
import { isProductCanType, isProductEndType } from '../../../../utility/helpers/order-helpers';
import {
    AtmOrderQuantities,
    DateProperties,
    getDateProperties,
    groupOrdersBySnoSku
} from '../../../../utility/helpers/make-it-bulk-helpers';
import { Order_Status } from '../../../../utility/services/orders-service';

export function atmBulkOrderBuilder(
    order: ProductionOrder,
    products: MakeItBulkLineItem[]
): MakeItBulkATMOrder {
    const atmOrder: MakeItBulkATMOrder = {
        customerProductionOrderId: order.customerProductionOrderId,
        productionOrderId: order.productionOrderId,
        weekStart: order.atmWeekStart ?? '', // ATM Week Start will always be defined for ATM Orders
        shipToId: order.shipToId,
        lines: [],
        canQuantityPallets: order.canQuantityPallets ?? 0,
        canQuantitySKUs: order.canQuantitySKUs ?? 0,
        canQuantityEaches: order.canQuantityEaches,
        endQuantityPallets: order.endQuantityPallets ?? 0,
        endQuantitySKUs: order.endQuantitySKUs ?? 0,
        endQuantityEaches: order.endQuantityEaches
    };

    order.lines?.forEach((line) => {
        const matchingProduct = products.find((product) => product.productSku === line.productSku);

        if (matchingProduct?.snoSku) {
            const atmLine: MakeItBulkATMLineItem = {
                ...line,
                snoSku: matchingProduct.snoSku,
                deleted: false,
                requestedDate: line.requestedDate ?? '',
                // filling with default data for typing
                originalCsvLineNumber: 0,
                userSuppliedProductId: '',
                isCustomerProductIdDistinct: true
            };

            atmOrder.lines.push(atmLine);
        }
    });

    return atmOrder;
}

export function calculateIsBelowAllocation(
    order: ProductionOrder,
    allocations: SnoSkuAllocationsByWeek[],
    products: MakeItBulkLineItem[]
): boolean {
    const sortedOrder = groupOrdersBySnoSku([atmBulkOrderBuilder(order, products)], allocations);

    for (let productGroup of sortedOrder[0].linesBySnoSku) {
        if (
            productGroup.allocationAmount &&
            productGroup.allocationAmount > productGroup.totalPalletQuantity
        ) {
            return true;
        }
    }
    return false;
}

export function sortMakeItATMSummaryOrders(
    orders: ProductionOrder[],
    lockedPeriodDays: number,
    products: MakeItBulkLineItem[]
) {
    const openOrders: ProductionOrder[] = [];
    const closedOrders: ProductionOrder[] = [];

    orders.forEach((order) => {
        let atmOrder = setProductAndAggregateData(order, products, lockedPeriodDays);
        switch (atmOrder.status) {
            case Order_Status.Cancelled:
            case Order_Status.CancelledPending:
            case Order_Status.Closed:
            case Order_Status.Completed:
                atmOrder.isClosed = true;
                closedOrders.push(atmOrder);
                break;
            default:
                atmOrder.isClosed = false;
                openOrders.push(atmOrder);
        }
    });

    return {
        openOrders: openOrders,
        closedOrders: closedOrders
    };
}

export function setProductAndAggregateData(
    order: ProductionOrder,
    products: MakeItBulkLineItem[],
    lockedPeriodDays: number
) {
    const atmOrder: ProductionOrder = {
        ...order,
        lines: []
    };
    const timeFrame = isDateWithinAtmLockPeriod(order.atmWeekStart, lockedPeriodDays);
    atmOrder.isEditable = timeFrame === 'after';

    order.lines?.forEach((line) => {
        const matchingProduct = products.find((product) => product.productSku === line.productSku);
        let atmLine: ProductionOrderLine = {
            ...line,
            requestedDate: line.requestedDate ?? ''
        };

        if (matchingProduct) {
            atmLine.quantityPerPallet = matchingProduct.quantityPerPallet ?? 1;
        }
        atmOrder.lines?.push(atmLine);
    });

    return atmOrder;
}

export function getEachesTotal(line: ProductionOrderLine) {
    return line.palletQuantity && line.quantityPerPallet
        ? line.palletQuantity * line.quantityPerPallet
        : 0;
}

export const getOrderQuantities = (
    activeDate: DateProperties,
    atmOrders?: MakeItBulkATMOrder[],
    selectedShipTo?: string
): AtmOrderQuantities => {
    let quantities = {
        canPallets: 0,
        canSkus: 0,
        canEaches: 0,
        endPallets: 0,
        endSkus: 0,
        endEaches: 0
    } as AtmOrderQuantities;

    let filteredOrders = atmOrders?.filter((ord) => {
        return (
            selectedShipTo &&
            getDateProperties(moment(ord.weekStart)).week === activeDate.week &&
            ord.shipToId === parseInt(selectedShipTo)
        );
    });
    filteredOrders?.forEach((order) => {
        order.lines.forEach((line) => {
            if (line.palletQuantity && line.quantityPerPallet) {
                if (isProductEndType(line.type)) {
                    quantities.endPallets += line.palletQuantity;
                    quantities.endEaches += line.palletQuantity * line.quantityPerPallet;
                    quantities.endSkus++;
                }
                if (isProductCanType(line.type)) {
                    quantities.canPallets += line.palletQuantity;
                    quantities.canEaches += line.palletQuantity * line.quantityPerPallet;
                    quantities.canSkus++;
                }
            }
        });
    });
    return quantities;
};
