import React from 'react';
import { makeStyles, TableRow, TableCell, Grid, Typography } from '@material-ui/core';
import { boldWeight, medium } from '../../../../themes/globalConstants';
import { MyAccessTableRow } from '../Models/MyAccessTableRow';

interface Props {
    location: MyAccessTableRow;
    key: string;
}

const useStyles = makeStyles((theme) => ({
    name: {
        fontWeight: boldWeight,
        fontSize: medium,
        letterSpacing: 0.17
    }
}));

export default function MyAccountMyAccessRow({ location, key }: Props) {
    const classes = useStyles();

    return (
        <TableRow hover={true} key={key}>
            <TableCell data-testid="location">
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        <Typography>{location.location}</Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell data-testid="address">
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        <Typography>{location.address}</Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell data-testid="roles">
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        <Typography>{location.roles}</Typography>
                    </Grid>
                </Grid>
            </TableCell>
        </TableRow>
    );
}
