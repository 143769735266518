import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import moment from 'moment';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import {
    boldWeight,
    blackWeight,
    dateOptionInvalidGray,
    ballBlue,
    small
} from '../../../../themes/globalConstants';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import DocumentsColumns from './DocumentsColumns';
import DocumentsRow from './DocumentsRow';
import SummarySearchBar from '../../../reusable/atoms/SummarySearchBar';
import { useContractSummaryContext } from '../context/ContractSummaryContext';
import StandardTable from '../../../reusable/molecules/StandardTable';
import { ContractAndDocument } from '../../../../store/reducers/contract-summary';
import { DocumentForTable } from '../models/DocumentForTable';

interface Props {
    documents: ContractAndDocument[];
    hideShareLinks?: boolean;
    isArchived?: boolean;
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: '1em'
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        padding: '1em'
    },
    tableWrapper: {
        '& .MuiPaper-root': {
            boxShadow: 'unset'
        },
        '& .MuiTable-root': {
            tableLayout: 'fixed'
        },
        '& .MuiToolbar-root': {
            padding: 0,
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                },
                '&:first-of-type ': {
                    paddingLeft: 0
                }
            },
            '& > h2': {
                fontSize: '1.5em',
                fontWeight: boldWeight,
                color: theme.palette.secondary.main,
                paddingLeft: '0.625em'
            },
            '& .MuiInputBase-formControl': {
                border: `1px solid ${dateOptionInvalidGray}`,
                borderRadius: '.125em',
                '&:before': {
                    display: 'none'
                },
                '&:after': {
                    display: 'none'
                },
                '& svg': {
                    height: '.875em',
                    width: '.875em',
                    fill: ballBlue
                },
                '& .MuiIconButton-root': {
                    '&:hover': {
                        backgroundColor: 'transparent !important'
                    }
                },
                '& .MuiInput-input': {
                    paddingLeft: '.25em'
                }
            }
        },
        '& .MuiTableSortLabel-root': {
            fontSize: small,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em',
            width: '99%',
            justifyContent: 'space-between',
            '& .MuiSvgIcon-root': {
                fontSize: 14,
                opacity: '1'
            }
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                fontSize: small,
                fontWeight: blackWeight,
                textTransform: 'uppercase',
                color: theme.palette.secondary.main,
                borderRadius: '0.25em',
                paddingLeft: '0.5em',
                paddingTop: '0.5em',
                paddingBottom: '0.5em',
                justifyContent: 'space-between'
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        },
        paddingTop: '1em'
    },
    icon: {
        color: ballBlue
    },
    downloadWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

const DocumentTab = ({ documents, isArchived }: Props) => {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const [columns, setColumns] = useState<any>([]);
    const [filteredDocumentsForTable, setFilteredDocumentsForTable] = useState<any>([]);
    const [documentsForTable, setDocumentsForTable] = useState<DocumentForTable[] | undefined>(
        undefined
    );
    const { activeTab } = useContractSummaryContext();

    useEffect(() => {
        if (documents) {
            const formattedDocuments: DocumentForTable[] = [];
            documents.sort((a, b) => moment(b.approvalDate).diff(a.approvalDate));
            documents.forEach((document) => {
                let documentForTable: DocumentForTable = {
                    ...document,
                    approvalDateString: document.approvalDate
                        ? moment(document.approvalDate).format('MM/DD/YYYY')
                        : '',
                    effectiveDateString: moment(document.effectiveDate).format('MM/DD/YYYY'),
                    expirationDateString: moment(document.expirationDate).format('MM/DD/YYYY'),
                    needsAcknowledgement: !document.approvalDate
                };
                formattedDocuments.push(documentForTable);
            });

            setDocumentsForTable(formattedDocuments);
        } else {
            setDocumentsForTable([]);
        }
    }, [documents]);

    useEffect(() => {
        setColumns(DocumentsColumns.getColumns(cultureCode, !!isArchived));
    }, [cultureCode, activeTab, isArchived]);

    const CurrentRow = ({ row }: { row: DocumentForTable }) => {
        if (row) {
            return <DocumentsRow document={row as DocumentForTable} key={row.documentId} />;
        } else {
            return null;
        }
    };

    const handleRowFilter = (filteredRows) => {
        setFilteredDocumentsForTable(filteredRows);
    };

    return (
        <Paper elevation={2} className={classes.paper}>
            <Grid className={classes.tableWrapper}>
                <SummarySearchBar
                    rows={documentsForTable || []}
                    columns={columns}
                    onRowFilter={handleRowFilter}
                />

                <StandardTable
                    gridName="Contract And Documents"
                    columns={columns}
                    lines={filteredDocumentsForTable}
                    rowComponent={CurrentRow}
                    itemsPerPage={10}
                    searchText={false}
                    memoize={false}
                />
            </Grid>
        </Paper>
    );
};

export default DocumentTab;
