import React from 'react';
import Draggable, { DraggableStyleProps } from './Draggable';
import { DragAndDropItem } from './models';
export interface DraggableListProps {
    items: Array<DragAndDropItem>;
    styleConfig: DraggableStyleProps;
    disabled?: boolean;
}

export const DraggableList = React.memo(({ items, styleConfig, disabled }: DraggableListProps) => {
    return (
        <>
            {items.map((item: DragAndDropItem, index: number) => (
                <Draggable
                    disabled={disabled}
                    item={item}
                    index={index}
                    key={item.id}
                    styleConfig={styleConfig}
                />
            ))}
        </>
    );
});
