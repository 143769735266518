import React from 'react';
import { Grid, Typography, makeStyles, Box } from '@material-ui/core';
import { ContentSubSection } from '../../../../store/reducers/content-page';
import ContentLink from './ContentLink';
import sanitizeHtml from 'sanitize-html-react';

//This section creates rows of content and stacks the rows vertically

interface Props {
    section: ContentSubSection;
    className?: any;
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '4em'
    },
    itemHeader: {
        fontSize: 16,
        marginBottom: '0.5em'
    },
    img: {
        objectFit: 'contain',
        maxWidth: '21em',
        maxHeight: '100%',
        margin: 'auto',
        [theme.breakpoints.down('xs')]: {
            marginBottom: '2em'
        }
    },
    contentBox: {
        marginLeft: '2em'
    },
    description: {
        marginBottom: '1em'
    }
}));

const VerticalTemplateBlock = (props: Props) => {
    const section = props.section;
    const links = props.section.links;
    const classes = useStyles();

    return (
        <Grid
            container
            item
            xs={12}
            justify="flex-start"
            alignItems="flex-start"
            direction="row"
            className={classes.root}
        >
            <Grid item xs={12} md={3}>
                <div>
                    {section.image && (
                        <Grid container item xs={12}>
                            <img
                                className={classes.img}
                                src={section.image.imageUrl}
                                alt={
                                    section.image.alternateText
                                        ? section.image.alternateText
                                        : section.header + ' image'
                                }
                            />
                        </Grid>
                    )}
                </div>
            </Grid>
            <Grid container item xs={12} md={9} alignItems="center">
                <Box className={classes.contentBox}>
                    <Typography variant="h5" gutterBottom className={classes.itemHeader}>
                        {section.header}
                    </Typography>
                    <Typography
                        variant="body1"
                        component="p"
                        className={classes.description}
                        dangerouslySetInnerHTML={{ __html: sanitizeHtml(section.description) }}
                    ></Typography>
                    <ContentLink link={links ? links[0] : null} />
                </Box>
            </Grid>
        </Grid>
    );
};

export default VerticalTemplateBlock;
