import React, { ReactNode } from 'react';
import ReactPDF, { View } from '@react-pdf/renderer';

interface Props {
    justify?: 'flex-start' | 'flex-end' | 'space-between' | 'center';
    children?: ReactNode;
    style?: ReactPDF.Style;
}

const StandardPDFFlex = ({
    justify = 'flex-start',
    children = undefined,
    style = undefined
}: Props) => {
    return (
        <View
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: justify,
                ...style
            }}
        >
            {children}
        </View>
    );
};

export default StandardPDFFlex;
