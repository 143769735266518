import AccountService from '../../utility/services/account-service';
import { ShipToLocation } from '../reducers/ship-to-locations';
import {
    LOCATION_MGMT_SHIPTO_ADD_ERROR,
    LOCATION_MGMT_SHIPTO_ADD_RESET,
    LOCATION_MGMT_SHIPTO_ADD_REVIEW,
    LOCATION_MGMT_SHIPTO_ADD_SUBMIT,
    LOCATION_MGMT_SHIPTO_ADD_SUBMITTING
} from './action-types';

export const reviewShipToAddForm = (newShipToLocation) => {
    return (dispatch: any) => {
        dispatch({
            type: LOCATION_MGMT_SHIPTO_ADD_REVIEW,
            newShipToLocation
        });
    };
};

export const resetShipToAddForm = () => {
    return (dispatch: any) => {
        dispatch({
            type: LOCATION_MGMT_SHIPTO_ADD_RESET
        });
    };
};

export const submitShipToLocationAdd = (callback: Function) => {
    return (dispatch, getState) => {
        const state = getState();
        const newShipToLocation = state.shipToLocations.newShipToLocation as ShipToLocation;

        if (newShipToLocation) {
            dispatch({
                type: LOCATION_MGMT_SHIPTO_ADD_SUBMITTING
            });

            AccountService.submitNewShipToLocation(state, newShipToLocation)
                .then((response) => {
                    const updatedNewShipToLocation = response.data.shipToRequest;

                    dispatch({
                        type: LOCATION_MGMT_SHIPTO_ADD_SUBMIT,
                        newShipToLocation: updatedNewShipToLocation
                    });
                    callback(false);
                })
                .catch((error) => {
                    dispatch({
                        type: LOCATION_MGMT_SHIPTO_ADD_ERROR,
                        error
                    });
                    callback(false);
                });
        }
    };
};
