import {
    TRADE_REQUEST_SUMMARY_LOADED,
    TRADE_REQUEST_SUMMARY_LOADING,
    TRADE_REQUEST_SUMMARY_LOADING_ERROR,
    TRADE_REQUEST_SUMMARY_RESET
} from '../actions/action-types';

export interface TradeRequest {
    tradeRequestId: string;
    accountFrom: string;
    accountTo: string;
    requestedAmount: string;
    quantityEaches: string;
    quantitySkus: string;
    submittedDate: string;
    accountFromName: string;
    accountToName: string;
    accountFromAddress: string;
    accountToAddress: string;
}

export interface TradeRequestSummaryState {
    tradeRequests: TradeRequest[];
    loading: boolean;
    loaded: boolean;
    dataLoaded: boolean;
    error?: any;
}

const initialState: TradeRequestSummaryState = {
    tradeRequests: [],
    loading: false,
    loaded: false,
    dataLoaded: false
};

const tradeItSummary = (
    state: TradeRequestSummaryState = initialState,
    action: { type: string; [x: string]: any }
): TradeRequestSummaryState => {
    switch (action.type) {
        case TRADE_REQUEST_SUMMARY_LOADING:
            return { ...state, loading: true, loaded: false };
        case TRADE_REQUEST_SUMMARY_LOADING_ERROR:
            return { ...state, loading: false, dataLoaded: true, error: action.error };
        case TRADE_REQUEST_SUMMARY_LOADED:
            return {
                ...state,
                tradeRequests: action.tradeRequests,
                loaded: true,
                dataLoaded: true,
                loading: false
            };
        case TRADE_REQUEST_SUMMARY_RESET:
            return {
                ...state,
                tradeRequests: [],
                loading: false,
                loaded: false,
                dataLoaded: false
            };
        default:
            return state;
    }
};

export default tradeItSummary;
