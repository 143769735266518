import React from 'react';
import {
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { generateKey } from '../../../../utility/helpers/order-helpers';
import { boldWeight, ltBlueGrey_3 } from '../../../../themes/globalConstants';
import { SecurityLevel } from '../../../../utility/auth/useSecurity';
import clsx from 'clsx';
import { UserAdminRoleRow } from '../../../pages/UserAdminRolesAndAccess/components/user-admin-roles';

interface Props {
    rows: UserAdminRoleRow[];
    securityLevelIndex: number;
}

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(1)
    },
    table: {
        maxWidth: '27.5em',
        '& .MuiTableHead-root': {
            borderBottom: '3px solid ' + ltBlueGrey_3
        },
        '& .MuiTableCell-root': {
            borderLeft: 'none',
            borderRight: 'none',
            borderBottom: 'none'
        },
        '& .MuiTableCell-sizeSmall': {
            padding: '2px 24px 2px 16px'
        }
    },
    tableHeader: {
        '& p': {
            fontWeight: boldWeight
        }
    },
    headerCell: {
        verticalAlign: 'bottom'
    },
    accessLevelCell: {
        textAlign: 'right'
    },
    subheader: {
        fontWeight: boldWeight
    },
    subheaderRow: {
        borderBottom: '3px solid ' + ltBlueGrey_3,
        height: 30,
        verticalAlign: 'bottom'
    },
    nonSubheaderRow: {
        borderTop: '1px solid ' + ltBlueGrey_3
    }
}));

export default function LocationRoleAccessTooltip({ rows, securityLevelIndex }: Props) {
    const classes = useStyles();
    const { t } = useTranslation();

    const getSecurityLevelString = (access: SecurityLevel) => {
        switch (access) {
            case SecurityLevel.Edit:
                return t('edit', 'Edit');
            case SecurityLevel.View:
                return t('view', 'View');
            default:
                return '';
        }
    };

    return (
        <Table
            size="small"
            aria-label="role access tooltip table"
            key={generateKey('role-access-tooltip')}
            className={classes.table}
            data-testid="role-access-tooltip-table"
        >
            <TableHead className={classes.tableHeader}>
                <TableRow>
                    <TableCell className={classes.headerCell}>
                        <Typography>
                            <Trans i18nKey="graphics">Graphics</Trans>
                        </Typography>
                    </TableCell>
                    <TableCell className={classes.accessLevelCell}>
                        <Typography>
                            <Trans i18nKey="selectedAccess">Selected Access</Trans>
                        </Typography>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row, i) => {
                    return (
                        <>
                            <TableRow
                                key={'role-access-tooltip-table-row' + i}
                                className={clsx(
                                    { [classes.nonSubheaderRow]: !row.isSubheader },
                                    { [classes.subheaderRow]: row.isSubheader }
                                )}
                            >
                                <TableCell>
                                    <Typography
                                        className={clsx({ [classes.subheader]: row.isSubheader })}
                                    >
                                        <Trans i18nKey={row.activityKey}>{row.activity}</Trans>
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.accessLevelCell}>
                                    {!row.isSubheader && (
                                        <>
                                            {row.accessLevels[securityLevelIndex] !==
                                            SecurityLevel.None ? (
                                                <Typography>
                                                    {getSecurityLevelString(
                                                        row.accessLevels[securityLevelIndex]
                                                    )}
                                                </Typography>
                                            ) : (
                                                <Typography>--</Typography>
                                            )}
                                        </>
                                    )}
                                </TableCell>
                            </TableRow>
                        </>
                    );
                })}
            </TableBody>
        </Table>
    );
}
