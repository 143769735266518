import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class MakeItDraftsColumns {
    static getColumns = (culture: string, showCustomerPO: boolean) => {
        return [
             createColumn('productionOrderId', {
                dataType: ColumnDataType.String,
                visible: false,
                filterable: false,
                sortable: true,
                searchable: false,
                isKey: true
            }),
            createColumn('draftId', {
                dataType: ColumnDataType.String,
                visible: false,
                filterable: false,
                sortable: true,
                searchable: false,
                isKey: false
            }),
            createColumn('shipToName', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'shipToName'),
                filterable: false,
                sortable: true,
                searchable: false,
                isKey: false
            }),
            createColumn('shipToId', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'shipToId'),
                filterable: false,
                sortable: true,
                searchable: false,
                isKey: false
            }),
            createColumn('customerProductionOrderId', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'customerPoNumber'),
                sortable: true,
                searchable: false,
                isKey: false,
                visible: showCustomerPO
            }),
            createColumn('requestedDate', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'requestedWeek'),
                filterable: false,
                sortable: true,
                searchable: false,
                isKey: false
            }),
            createColumn('updatedBy', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'lastSavedBy'),
                filterable: false,
                sortable: true,
                searchable: false,
                isKey: false
            }),
            createColumn('updateDate', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'lastSaved'),
                filterable: false,
                sortable: true,
                searchable: false,
                isKey: false
            }),
            createColumn('canQuantityPallets', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'canQuantity'),
                filterable: false,
                sortable: true,
                searchable: false,
                isKey: false
            }),
            createColumn('canQuantitySKUs', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('endQuantityPallets', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'endQuantity'),
                filterable: false,
                sortable: true,
                searchable: false,
                isKey: false
            }),
            createColumn('endQuantitySKUs', {
                dataType: ColumnDataType.Numeric,
                visible: false
            })
        ];
    };
}
