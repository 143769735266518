import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { Activity } from '../../../utility/auth/useSecurity';
import { CampaignRun } from '../../../store/reducers/product-portfolio';
import { loadMakeItBulkProducts } from '../../../store/root-actions';
import { ProductionOrderLine } from '../../../store/reducers/makeit-dashboard';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import {
    BulkUploadMakeItState,
    MakeItBulkLineItem
} from '../../../store/reducers/makeit-bulk-upload';

import { getCampaigns } from '../../../utility/helpers/production-order-helpers';
import { DataValidationDisplayTemplate } from '../../templates/DataValidationDisplayTemplate';
import MakeItBulkCampaignGrid from './components/MakeItBulkCampaignGrid';
import { selectIsLargeCustomer } from '../../../store/selectors';
import moment from 'moment';

const MakeItBulkCampaigns = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [campaignRuns, setCampaignRuns] = useState<CampaignRun[]>();
    const [campaignProducts, setCampaignProducts] = useState<ProductionOrderLine[]>();
    const [hasCampaignRuns, setHasCampaignRuns] = useState<boolean>(false);
    const { products, loading } = useTypedSelector<BulkUploadMakeItState>(
        (state) => state.bulkUploadMakeItState
    );
    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const isLargeCustomer = useTypedSelector<boolean>(selectIsLargeCustomer);

    const handleUpdateCampaigns = useCallback(
        (products: MakeItBulkLineItem[]) => {
            let campaignData = getCampaigns(products);
            // store unique campaign products
            setCampaignProducts(campaignData.campaignProducts);
            // store campaign products that have campaign runs associated to them
            let updatedCampaignRuns = campaignData.updatedCampaigns;
            if (isLargeCustomer) {
                /*
                    // -- Calculate first available date -- //
                    For this example, use make it order open date of 10/9/21
                    If today’s date is outside of the ordering window (before 10/9/21) then First Available is calculating from the Order First Date field
                        - 10/9/21 → week of 10/4/21
                        - Add full 8 week buffer & next Monday (+1 week) is available → 12/6/21
                    If today’s date is inside the ordering window (on or after 10/9) then First Available is calculating from today as a Monday
                        - 10/12/21 → Week of 10/11/21
                        - Add full 8 week buffer & next Monday (+1 week) is available → 12/13/21
                */
                updatedCampaignRuns.forEach((campaign) => {
                    let today = moment().startOf('day');
                    let orderOpenDate = moment(campaign.orderStartDate).startOf('day');
                    if (today.isBefore(orderOpenDate)) {
                        campaign.firstAvailableOrderDate = moment(
                            campaign.firstAvailableOrderDate
                        ).format('MM/DD/YYYY');
                    } else {
                        campaign.firstAvailableOrderDate = today
                            .startOf('isoWeek')
                            .add(campaign.leadTimeWeeks + 1, 'week')
                            .format('MM/DD/YYYY');
                    }
                });
            }
            setCampaignRuns(updatedCampaignRuns);
        },
        [isLargeCustomer]
    );

    useEffect(() => {
        if (!products && shipToAccounts && shipToAccounts.length > 0 && !loading) {
            const shipToIds = shipToAccounts.map((account) => {
                return account.accountId;
            });
            dispatch(loadMakeItBulkProducts(shipToIds, true, true, false));
        }
    }, [dispatch, loading, products, shipToAccounts]);

    useEffect(() => {
        if (products) {
            handleUpdateCampaigns(products);
        }
    }, [dispatch, handleUpdateCampaigns, products]);

    useEffect(() => {
        if (campaignRuns && campaignRuns.length > 0) {
            setHasCampaignRuns(true);
        } else {
            setHasCampaignRuns(false);
        }
    }, [campaignRuns]);

    return (
        <DataValidationDisplayTemplate
            shipTos={false}
            banner={{
                header: t('currentUpcomingCampaigns', 'Current & Upcoming Campaigns'),
                description: '',
                thinBanner: true,
                displayDropdown: false
            }}
            activity={Activity.PlanItBulkUpload}
        >
            <MakeItBulkCampaignGrid
                campaignRuns={hasCampaignRuns ? campaignRuns : campaignProducts}
                hasCampaignRuns={hasCampaignRuns}
            />
        </DataValidationDisplayTemplate>
    );
};

export default MakeItBulkCampaigns;
