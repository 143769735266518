import React, { ReactElement, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { CommunicationMessagesState } from '../../store/reducers/communication-messages';
import CommunicationMessagesModal from '../reusable/molecules/CommunicationMessagesModal';
import CommunicationMessagesBanner from '../reusable/molecules/CommunicationMessagesBanner';
import { RegionCultureState } from '../../store/reducers/region-culture';
import { getKBMaxRegion } from '../../utility/helpers/kbmax-helpers';
import { Communication, MessageType } from '../../store/reducers/communication-management';
import { AllPagesMapping } from '../templates/PagesMapping';
import { Trans } from 'react-i18next';
import moment from 'moment';
import { useTypedSelector } from '../../store/reducers/reducer';
import { Activity } from '../../utility/auth/useSecurity';
import { loadState, saveState } from '../../utility/helpers/localStorage';

interface Props {
    activity?: Activity;
}

function getDismissedMessages(): Communication[] {
    let dismissedMessages = loadState('Dismissed Messages');
    if (!dismissedMessages) {
        dismissedMessages = [];

        // Reset dismissed messages if in unparseable state
        saveState('Dismissed Messages', []);
    }

    return dismissedMessages;
}

export function CommunicationMessagesDisplayBannerModal({ activity }: Props): ReactElement {
    const { regionCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { communications, loaded: communicationSummaryLoaded } =
        useTypedSelector<CommunicationMessagesState>((state) => state.communicationMessages);

    const [dismissedMessages, setDismissedMessages] = useState<Communication[]>(
        getDismissedMessages()
    );

    const filteredMessages = useMemo(() => {
        const filteredMessages = communications.filter((communication) => {
            return !dismissedMessages.some(
                (dismissedMessage) =>
                    dismissedMessage?.communicationId === communication?.communicationId
            );
        });

        return filteredMessages;
    }, [communications, dismissedMessages]);

    const currentDateMessages = useMemo(() => {
        return filteredMessages?.filter((each) =>
            moment.utc().isBetween(moment.utc(each?.startDateTime), moment.utc(each?.endDateTime))
        );
    }, [filteredMessages]);

    const regionWiseMessages = useMemo(() => {
        return currentDateMessages?.filter((eachMessage) =>
            eachMessage?.communicationRegions?.some(
                (hasRegion) => hasRegion?.region === getKBMaxRegion(regionCode)
            )
        );
    }, [currentDateMessages]);

    const pagesWiseMessages = useMemo(() => {
        if (activity) {
            let messages = regionWiseMessages?.filter((eachmessage) =>
                eachmessage?.communicationDisplayPages?.some((each) =>
                    AllPagesMapping[each?.displayPage].includes(Activity[activity])
                )
            );
            return messages;
        } else {
            let messages = regionWiseMessages?.filter((eachmessage) =>
                eachmessage?.communicationDisplayPages?.some(
                    (each) => each?.displayPage === 'GlobalLandingPage'
                )
            );
            return messages;
        }
    }, [regionWiseMessages]);

    const modalMessages = useMemo(() => {
        return pagesWiseMessages?.filter((eachMessage) =>
            eachMessage?.communicationMessageTypes?.some(
                (messageType) => messageType?.messageType === MessageType.Popup
            )
        );
    }, [pagesWiseMessages]);

    const bannerMessages = useMemo(() => {
        const bannerMessages = pagesWiseMessages?.filter((eachMessage) =>
            eachMessage?.communicationMessageTypes?.some(
                (messageType) => messageType?.messageType === MessageType.Banner
            )
        );
        
        return bannerMessages;
    }, [pagesWiseMessages]);

    const messagesCallback = (data) => {
        if (!data) return;

        let messagesDismissed = [...dismissedMessages];
        messagesDismissed.push(data);
        saveState('Dismissed Messages', messagesDismissed);

        setDismissedMessages(getDismissedMessages());
    };

    return (
        <div>
            {pagesWiseMessages?.length ? (
                <Grid item xs={12}>
                    <CommunicationMessagesBanner
                        communications={bannerMessages}
                        notificationType="INFO"
                        messagesCallback={messagesCallback}
                    />
                    <CommunicationMessagesModal
                        logo={false}
                        description={<Trans i18nKey="messageok">Click OK to continue</Trans>}
                        continueText={'OK'}
                        open={modalMessages?.length ? true : false}
                        communications={modalMessages}
                        messagesCallback={messagesCallback}
                    />
                </Grid>
            ) : null}
        </div>
    );
}
