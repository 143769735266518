import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { Trans } from 'react-i18next';
import {
    ballGray,
    blackWeight,
    boldWeight,
    regularWeight,
    small
} from '../../../../themes/globalConstants';
import { getFormattedDateStringForApplication } from '../../../../utility/helpers/date-helpers';
import { getFormattedEachesFromNumber } from '../../../../utility/helpers/shipment-helpers';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { formatNumberWithLocale } from '../../../../utility/helpers/formatters';
import { ScrapItRequestDetails } from '../../../../store/reducers/scrap-it-details';

interface Props {
    shipToAddress: string;
    shipToName: string;
    shipToId: string;
    request: ScrapItRequestDetails;
}

const useStyles = makeStyles((theme) => ({
    main: {
        marginBottom: '1.5em'
    },
    subtitle: {
        color: ballGray
    },
    item: {
        paddingLeft: '1em'
    },
    shipToName: {
        color: ballGray,
        fontWeight: blackWeight
    },
    shipToAddress: {
        color: ballGray,
        fontWeight: regularWeight,
        fontSize: small
    },
    requestData: {
        color: ballGray,
        fontWeight: boldWeight
    },
    eachesData: {
        color: ballGray,
        fontWeight: regularWeight,
        fontSize: small
    }
}));

const ScrapItRequestDetailsSubheader = ({
    shipToAddress,
    shipToId,
    shipToName,
    request
}: Props) => {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const formatOrderQuantity = (quantity?: number, SKUs?: number) => {
        if (SKUs === 1) {
            return (
                <Typography className={classes.requestData}>
                    {formatNumberWithLocale(cultureCode, quantity || 0)} PL / {SKUs}{' '}
                    <Trans i18nKey="sku">SKU</Trans>
                </Typography>
            );
        } else {
            return (
                <Typography className={classes.requestData}>
                    {formatNumberWithLocale(cultureCode, quantity || 0)} PL / {SKUs}{' '}
                    <Trans i18nKey="skus">SKUs</Trans>
                </Typography>
            );
        }
    };

    return (
        <Grid container className={classes.main}>
            <Grid container item xs={5} justify="flex-start">
                <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="shipTo">Ship To</Trans>
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        variant="body1"
                        className={classes.shipToName}
                        data-testid="ship-to-name"
                    >
                        {shipToName}
                    </Typography>
                    <Typography
                        variant="body1"
                        className={classes.shipToAddress}
                        data-testid="ship-to-address"
                    >
                        {shipToAddress}
                    </Typography>
                    <Typography
                        variant="body1"
                        className={classes.shipToAddress}
                        data-testid="ship-to-id"
                    >
                        #{shipToId}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item xs={7} justify="flex-end">
                <Grid item xs={2} className={classes.item}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="dateSubmitted">Date Submitted</Trans>
                    </Typography>
                    <Typography
                        variant="body1"
                        className={classes.requestData}
                        data-testid="date-submitted"
                    >
                        {getFormattedDateStringForApplication(request?.submittedDate)}
                    </Typography>
                </Grid>
                <Grid item xs={2} className={classes.item}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="canQuantity">Can Quantity</Trans>
                    </Typography>
                    <Typography variant="body1" data-testid="can-quantity">
                        {formatOrderQuantity(request.canPallets, request.canSkus)}
                    </Typography>
                    <Typography
                        variant="body1"
                        className={classes.eachesData}
                        data-testid="can-eaches"
                    >
                        {`${getFormattedEachesFromNumber(request.canEaches, cultureCode)} ea.`}
                    </Typography>
                </Grid>
                <Grid item xs={2} className={classes.item}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="endQuantity">End Quantity</Trans>
                    </Typography>
                    <Typography variant="body1" data-testid="end-quantity">
                        {formatOrderQuantity(request.endPallets, request.endSkus)}
                    </Typography>
                    <Typography
                        variant="body1"
                        className={classes.eachesData}
                        data-testid="end-eaches"
                    >
                        {`${getFormattedEachesFromNumber(request.endEaches, cultureCode)} ea.`}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ScrapItRequestDetailsSubheader;
