import React, { useEffect } from 'react';
import { ColumnSortDirection } from 'tubular-common';
import { DataGrid, TbRowProps, ToolbarOptions } from 'tubular-react';
import { ColumnModel } from 'tubular-common';
import { makeStyles, Grid } from '@material-ui/core';
import {
    boldWeight,
    ballGray,
    small,
    blackWeight,
    dateOptionInvalidGray,
    ballBlue,
    regularWeight,
    large,
    ballBlack,
    detailBackgroundGray
} from '../../../themes/globalConstants';
import { generateKey } from '../../../utility/helpers/order-helpers';
import { loadState, saveState } from '../../../utility/helpers/sessionStorage';

export const StandardTableStyles = (theme, columnWidths: number[] = []) => ({
    tableWrapper: {
        fontWeight: regularWeight,
        '& table': {
            tableLayout: 'fixed'
        },
        '& .MuiToolbar-root': {
            padding: 0,
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                },

                '&:first-of-type ': {
                    paddingLeft: 0
                }
            },
            '& > h2': {
                fontSize: '1.5em',
                fontWeight: boldWeight,
                color: theme.palette.secondary.main,
                paddingLeft: '0.625em',
                fontFamily: 'Lato',
                minWidth: '12em'
            }
        },
        '& .MuiTableCell-head': {
            color: ballGray,
            fontSize: small,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            '&:not(:first-of-type)': {
                borderLeft: 'unset'
            }
        },
        '& .MuiTableSortLabel-root': {
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em'
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                fontSize: '12px',
                borderLeft: 'none',
                ...columnWidths.reduce((ret, width, index) => {
                    ret[`&:nth-child(${index + 1})`] = {
                        width: `${width}%`
                    };
                    return ret;
                }, {})
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word',
            padding: '26px 16px',
            boxSizing: 'border-box'
        },
        '& .detailRow .MuiTableCell-root': {
            padding: '16px',
            backgroundColor: detailBackgroundGray,
            '&:first-child': {
                paddingLeft: '32px'
            }
        },
        '& .forecastTableRow .MuiTableCell-root': {
            fontWeight: regularWeight,
            padding: '0',
            backgroundColor: detailBackgroundGray
        },
        '& .MuiInputBase-formControl': {
            border: `1px solid ${dateOptionInvalidGray}`,
            borderRadius: '.125em',
            '&:before': {
                display: 'none'
            },
            '&:after': {
                display: 'none'
            },
            '& svg': {
                height: '.875em',
                width: '.875em',
                fill: ballBlue
            },
            '& .MuiIconButton-root': {
                '&:hover': {
                    backgroundColor: 'transparent !important'
                }
            },
            '& .MuiInput-input': {
                paddingLeft: '.25em'
            }
        },
        '& .MuiToolbar-regular': {
            minHeight: '0'
        },
        '&.modal-table': {
            '& .MuiTableHead-root th': {
                fontWeight: boldWeight,
                fontSize: large,
                color: ballBlack,
                textTransform: 'none',
                padding: '0.5em'
            },
            '& .MuiTableBody-root td': {
                padding: '0.75em'
            }
        }
    },
    noToolbar: {
        '& div': {
            '& div': {
                height: '0px'
            }
        }
    }
});
interface Props {
    gridName: string;
    columns: ColumnModel[];
    columnWidths?: number[];
    rowComponent: React.FunctionComponent<TbRowProps>;
    lines: any[];
    enablePagination?: boolean;
    itemsPerPage?: number;
    searchText?: boolean;
    customToolbarItems?: React.ReactNode;
    className?: string;
    hideToolbar?: boolean;
    testId?: string;
    memoize?: boolean;
    identifier?: string;
}
const StandardTable = ({
    gridName,
    columns,
    rowComponent,
    lines,
    columnWidths = [],
    enablePagination = true,
    itemsPerPage = 50,
    searchText = true,
    customToolbarItems = undefined,
    className = '',
    hideToolbar = false,
    testId = undefined
}: Props) => {
    const classes = makeStyles((theme) => StandardTableStyles(theme, columnWidths))();

    const toolbarOptions = new ToolbarOptions({
        advancePagination: false,
        exportButton: false,
        printButton: false,
        enablePagination: enablePagination,
        searchText: searchText,
        itemsPerPage: itemsPerPage,
        customItems: customToolbarItems
    });

    // Load saved columns from session storage and apply sort persistence
    useEffect(() => {
        // Load columns from session storage based on gridName value
        const sessionColumns = loadState(`${gridName}-Columns`);
        if (sessionColumns) {
            // Find the column with a sortDirection value
            const sortedSessionColumn = sessionColumns.find(
                (column) =>
                    column.sortDirection === ColumnSortDirection.Descending ||
                    column.sortDirection === ColumnSortDirection.Ascending
            );
            // If saved sorted column found, find the current column that matches it by name...
            // and apply the sort direction to it
            if (sortedSessionColumn) {
                const matchingColumn = columns.find(
                    (column) => column.name === sortedSessionColumn.name
                );
                if (matchingColumn)
                    matchingColumn.sortDirection = sortedSessionColumn.sortDirection;
            }
        }
    }, [columns, gridName]);

    const captureColumnState = () => {
        saveState(`${gridName}-Columns`, columns);
    };

    return (
        <Grid
            className={`${classes.tableWrapper} ${className} ${
                !!hideToolbar ? classes.noToolbar : ''
            }`}
            key={generateKey(gridName)}
            data-testid={testId}
        >
            <div
                onClick={captureColumnState}
                onKeyDown={captureColumnState}
                role="grid"
                tabIndex={0}
            >
                <DataGrid
                    gridName={gridName}
                    columns={columns}
                    dataSource={lines}
                    rowComponent={rowComponent}
                    toolbarOptions={toolbarOptions}
                    onRowClick={captureColumnState}
                />
            </div>
        </Grid>
    );
};

export default React.memo(StandardTable, (prevProps, nextProps) => {
    if (!nextProps.memoize) return false;
    if (prevProps.lines.length === nextProps.lines.length) {
        const identifier = nextProps.identifier;
        if (!!identifier) {
            const hasDifferentLines = !!nextProps.lines.find(
                (line, index) => line[identifier] !== prevProps.lines[index][identifier]
            );
            if (hasDifferentLines) return false;
        }
        return true; // props are equal
    }
    return false; // props are not equal -> update the component
});
