import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class MyAccountPendingRequestsColumns {
    static getPendingRequestsColumns = (culture: string) => {
        return [
            createColumn('location', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'location'),
                visible: true,
                sortable: true,
                searchable: true
            }),
            createColumn('roles', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'roles'),
                visible: true,
                sortable: true,
                searchable: true
            }),
            createColumn('dateSubmitted', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'dateSubmitted'),
                visible: true,
                sortable: true,
                searchable: true
            }),
            createColumn('latestSubmissionDate', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'latestSubmissionDate'),
                visible: true,
                sortable: true,
                searchable: true
            }),
            createColumn('actions', {
                label: TranslationService.getTranslatedText(culture, 'actions'),
                visible: true
            }),
            createColumn('requestId', {
                visible: false
            })
        ];
    };
}
