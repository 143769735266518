import React from 'react';
import { Document, Page, Text, Image, View, BlobProvider, Link } from '@react-pdf/renderer';
import { useTranslation, Trans } from 'react-i18next';
import moment from 'moment';
import pdfStyles from '../../../themes/pdfStyles';
import PdfPageTemplate from '../../templates/PdfPageTemplate';
import { isIE } from '../../../utility/helpers/user-helpers';
import { LiquidTestIntake } from '../../../store/reducers/liquid-intake';
import {
    getBeverageCategory,
    getBeverageContents,
    getNewOrExistingStatus
} from '../../../utility/helpers/liquid-intake-helpers';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { RegionCultureState } from '../../../store/reducers/region-culture';

interface Props {
    liquidIntake: LiquidTestIntake;
    testRequired: boolean;
}

const LiquidTestIntakeFormPDF = ({ liquidIntake, testRequired }: Props) => {
    const { t } = useTranslation();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const title = t('liquidTest', 'Liquid Test');
    const ballLogo = process.env.PUBLIC_URL + '/assets/Ball_RGB.jpg';
    const sourceLogo = process.env.PUBLIC_URL + '/assets/TheSource_Logo.png';

    const formatDate = (date?: string) => {
        if (date) {
            return moment(date).format('MM/DD/YYYY');
        } else {
            return '';
        }
    };

    const getYesNoResponse = (fieldValue: boolean | undefined) => {
        if (fieldValue !== undefined) {
            if (fieldValue) {
                return t('yes', 'Yes');
            } else {
                return t('no', 'No');
            }
        }
    };

    const myDoc = (
        <Document title={title}>
            <Page size="A4" style={pdfStyles.page} orientation={'portrait'}>
                <View style={pdfStyles.logoSection}>
                    <Image src={sourceLogo} style={pdfStyles.sourceLogo} />
                    <Image src={ballLogo} style={pdfStyles.ballLogo} />
                </View>
                <View style={pdfStyles.divider6} fixed />

                <View style={[pdfStyles.flexSection, pdfStyles.pageHeader]}>
                    <View>
                        <Text style={pdfStyles.header5}>
                            <Trans i18nKey="liquidTest">Liquid Test</Trans>
                        </Text>
                    </View>
                    <View>
                        <View>
                            <Text style={pdfStyles.fieldLabel}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="companyName">Company Name</Trans>
                                    {' / '}
                                    <Trans i18nKey="companyId">Company ID</Trans>
                                </Text>
                            </Text>
                        </View>
                        <View>
                            <Text style={pdfStyles.fieldText}>
                                {liquidIntake.companyName}
                                {' / '}
                                {liquidIntake.billTo}
                            </Text>
                        </View>
                    </View>
                    <View>
                        <View>
                            <Text style={pdfStyles.fieldLabel}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="dateSubmitted">Date Submitted</Trans>
                                </Text>
                            </Text>
                        </View>
                        <View>
                            <Text style={pdfStyles.fieldText}>
                                {formatDate(liquidIntake.createDate)}
                            </Text>
                        </View>
                    </View>
                </View>

                <View style={pdfStyles.divider6} fixed />
                <View style={pdfStyles.borderlessTable}>
                    <View style={pdfStyles.tableRow}>
                        <View style={[pdfStyles.borderLessTableCol]}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="submissionType">Submission Type</Trans>
                            </Text>
                        </View>
                        <View style={[pdfStyles.borderLessTableColWide3]}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="projectName">Project Name</Trans>
                            </Text>
                        </View>

                        <View style={[pdfStyles.borderLessTableColWide3]}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="beverageName">Beverage Name</Trans>
                            </Text>
                        </View>

                        <View style={[pdfStyles.borderLessTableColNarrow8]}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="graphicId">Graphic ID</Trans>
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                        <View style={[pdfStyles.borderLessTableCol]}>
                            <Text style={pdfStyles.subtitle8}>
                                {getNewOrExistingStatus(liquidIntake.newOrExisting)}
                            </Text>
                        </View>

                        <View style={[pdfStyles.borderLessTableColWide3]}>
                            <Text style={pdfStyles.subtitle8}>{liquidIntake.projectName}</Text>
                        </View>
                        <View style={[pdfStyles.borderLessTableColWide3]}>
                            <Text style={pdfStyles.subtitle8}>{liquidIntake.beverageName}</Text>
                        </View>
                        <View style={[pdfStyles.borderLessTableColNarrow8]}>
                            <Text style={pdfStyles.subtitle8}>
                                {liquidIntake.graphicID ? (
                                    liquidIntake.graphicID
                                ) : (
                                    <Trans i18nKey="none">None</Trans>
                                )}
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={pdfStyles.borderlessTable}>
                    <View style={pdfStyles.tableRow}>
                        <View style={[pdfStyles.borderLessTableColWide4]}>
                            <Text style={pdfStyles.header6}>
                                <Trans i18nKey="beverageInformation">Beverage Information</Trans>
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                        <View style={[pdfStyles.borderLessTableColNarrow7]}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="beverageCategory">Beverage Category</Trans>
                            </Text>
                        </View>
                        <View style={[pdfStyles.borderLessTableColNarrow9]}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="alcoholByVolumePercentage">ABV%</Trans>
                            </Text>
                        </View>
                        <View style={[pdfStyles.borderLessTableColNarrow9]}>
                            <Text style={pdfStyles.phHeader}>
                                <Trans i18nKey="potentialOfHydrogen">pH</Trans>
                            </Text>
                        </View>
                        <View style={[pdfStyles.borderLessTableColNarrow7]}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="fourIngredientBeerFlag">4 Ingredient Beer</Trans>
                            </Text>
                        </View>
                        <View style={[pdfStyles.borderLessTableColNarrow6]}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="beverageDescription">Beverage Description</Trans>
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.divider7} />
                    <View style={pdfStyles.tableRow}>
                        <View style={[pdfStyles.borderLessTableColNarrow7]}>
                            <Text style={pdfStyles.subtitle8}>
                                {getBeverageCategory(liquidIntake.beverageCategory, cultureCode)}
                            </Text>
                        </View>

                        <View style={[pdfStyles.borderLessTableColNarrow9]}>
                            <Text style={pdfStyles.subtitle8}>{liquidIntake.abvPercent}</Text>
                        </View>
                        <View style={[pdfStyles.borderLessTableColNarrow9]}>
                            <Text style={pdfStyles.subtitle8}>{liquidIntake.pH}</Text>
                        </View>
                        <View style={[pdfStyles.borderLessTableColNarrow7]}>
                            <Text style={pdfStyles.subtitle8}>
                                {getYesNoResponse(liquidIntake.isSimpleBeer)}
                            </Text>
                        </View>
                        <View style={[pdfStyles.borderLessTableColNarrow6]}>
                            <Text style={pdfStyles.subtitle8}>
                                {liquidIntake.beverageDescription}
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.divider7} />
                </View>
                <View style={pdfStyles.borderlessTable5}>
                    <View style={pdfStyles.tableRow}>
                        <View style={[pdfStyles.borderLessTableColNarrow7]}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="isRefrigerationRequired">
                                    Is refrigeration required?
                                </Trans>
                            </Text>
                        </View>
                        <View style={[pdfStyles.borderLessTableColNarrow8]}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="concentrationSample">Syrup Sample</Trans>
                            </Text>
                        </View>
                        <View style={[pdfStyles.borderLessTableColNarrow8]}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="throwRatio">Throw Ratio</Trans>
                            </Text>
                        </View>
                        <View style={[pdfStyles.borderLessTableColNarrow6]}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="beverageContains">Beverage Contains</Trans>
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.divider7} />
                    <View style={pdfStyles.tableRow}>
                        <View style={[pdfStyles.borderLessTableColNarrow7]}>
                            <Text style={pdfStyles.subtitle8}>
                                {getYesNoResponse(liquidIntake.refrigerationRequired)}
                            </Text>
                        </View>

                        <View style={[pdfStyles.borderLessTableColNarrow8]}>
                            <Text style={pdfStyles.subtitle8}>
                                {getYesNoResponse(liquidIntake.concentrateSample)}
                            </Text>
                        </View>
                        <View style={[pdfStyles.borderLessTableColNarrow8]}>
                            <Text style={pdfStyles.subtitle8}>{liquidIntake.throwRatio}</Text>
                        </View>
                        <View style={[pdfStyles.borderLessTableColNarrow6]}>
                            <Text style={pdfStyles.subtitle8}>
                                {getBeverageContents(cultureCode, liquidIntake.beverageContentList)}
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.divider7} />
                </View>
                <View style={pdfStyles.borderlessTable5}>
                    <View style={pdfStyles.tableRow}>
                        <View style={pdfStyles.borderLessTableCol}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="instructions">Instructions</Trans>
                            </Text>
                        </View>
                        {testRequired && (
                            <View style={pdfStyles.borderLessTableCol}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="shippingAddress">Shipping Address</Trans>
                                </Text>
                            </View>
                        )}
                    </View>
                    <View style={pdfStyles.tableRow}>
                        <View style={pdfStyles.borderLessTableCol}>
                            <Text style={pdfStyles.subtitle3}>
                                {testRequired && (
                                    <Trans i18nKey="liquidIntakeTestRequired">
                                        Please print this form and include it in the package in
                                        which you ship your liquid sample. Ship to shipping address
                                        to the right.
                                    </Trans>
                                )}
                                {!testRequired && (
                                    <Trans i18nKey="liquidIntakeTestNotRequired">
                                        Based on the information provided, your liquid does not need
                                        to be submitted for liquid testing. Please print this form
                                        for your records.
                                    </Trans>
                                )}
                            </Text>
                        </View>
                        {testRequired && (
                            <View style={pdfStyles.borderLessTableColWide2}>
                                <Text style={pdfStyles.subtitle3}>
                                    {'BALL TECHNOLOGY AND INNOVATION CENTER'}
                                </Text>
                                <Text style={pdfStyles.subtitle3}>
                                    {'PRODUCT COMPATIBILITY TESTING/CHEMISTRY LAB'}
                                </Text>
                                <Text style={pdfStyles.subtitle3}>{'9343 W. 108TH CIR.'}</Text>
                                <Text style={pdfStyles.subtitle3}>{'WESTMINSTER, CO 80021'}</Text>
                                <Text style={[pdfStyles.subtitle3, pdfStyles.marginTop10]}><Trans i18nKey="phone">Phone</Trans>{': (303) 460-5246'}</Text>
                            </View>
                        )}
                    </View>
                    <View style={pdfStyles.divider6} fixed/>
                    <Text style={pdfStyles.subtitle3}>
                        <Trans i18nKey="cannotImportAlcohol">Ball cannot import alcohol. For support contact: </Trans>
                        <Link src="mailto:ball@greenworldwide.com">ball@greenworldwide.com</Link>
                    </Text>
                </View>
            </Page>
        </Document>
    );

    if (!isIE()) {
        return <PdfPageTemplate title={title}>{myDoc}</PdfPageTemplate>;
    } else {
        return (
            <BlobProvider document={myDoc}>
                {({ blob, url, loading, error }) => {
                    if (blob && window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob);
                    }
                    return null;
                }}
            </BlobProvider>
        );
    }
};

export default LiquidTestIntakeFormPDF;
