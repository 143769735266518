import { Culture } from "../../store/reducers/region-culture";
import { deDETubular } from "../translations/locales/de-DE/deDe-Tubular";
import { enGBTubular } from "../translations/locales/en-GB/enGB-Tubular";
import { enUSTubular } from "../translations/locales/en-US/enUS-Tubular";
import { esESTubular } from "../translations/locales/es-ES/esES-Tubular";
import { frFRTubular } from "../translations/locales/fr-FR/frFR-Tubular";
import { ptBRTubular } from "../translations/locales/pt-BR/ptBR-Tubular";
import { ruRUTubular } from "../translations/locales/ru-RU/ruRU-Tubular";

export interface PaginationType {
    rowsPerPage: number[];
};

export const paginationOptions: PaginationType = {
    rowsPerPage: [10, 20, 50, 100]
};

export const defaultPaginationRows = 10;

export const getGridElementValue = (identifier: string) => {
    const element = document.querySelectorAll(identifier);
    const value = element[0].textContent;

    if (value) {
        const intValue = parseInt(value);
        return intValue;
    } else {
        //If value is undefined, return 0
        return 0;
    }
};

export const getStringInputValue = (identifier: string) => {
    const element = document.querySelectorAll(identifier) as any;
    return element && element[0] ? element[0].value : '';
};

export function getLanguageFile(cultureCode: string) {
    switch (cultureCode) {
        case Culture.enUS:
            return enUSTubular;
        case Culture.frFR:
            return frFRTubular;
        case Culture.enGB:
            return enGBTubular;
        case Culture.ptBR:
            return ptBRTubular;
        case Culture.ruRU:
            return ruRUTubular;
        case Culture.esES:
            return esESTubular;
        case Culture.deDE:
            return deDETubular;
        default:
            return enUSTubular;
    }
}