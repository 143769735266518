import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { blackWeight, large } from '../../../../themes/globalConstants';

const useStyles = makeStyles((theme) => ({
    stepNum: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        borderRadius: '50%',
        width: '1.875em',
        height: '1.875em',
        fontSize: large,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: blackWeight,
        marginTop: '0.25em'
    },
    stepWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    stepTitle: {
        paddingLeft: '0.5em',
        fontWeight: blackWeight,
        color: theme.palette.secondary.main
    }
}));

export default function ShipItProductsOptions() {
    const classes = useStyles();

    useTranslation();

    return (
        <Grid container justify="space-between" alignItems="flex-end">
            <Grid item className={classes.stepWrapper}>
                <Typography component="span" className={classes.stepNum} data-testid="step-number">
                    2
                </Typography>
                <Typography variant="h3" className={classes.stepTitle} data-testid="step-title">
                    <Trans i18nKey="selectProductsForDelivery">
                        Add products to your delivery order:
                    </Trans>
                </Typography>
            </Grid>
        </Grid>
    );
}
