import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { Activity } from '../../../utility/auth/useSecurity';
import Button from '../../reusable/atoms/Button';
import moment from 'moment';
import {
    blackWeight,
    boldWeight,
    red,
    containerMaxWidth,
    small,
    xl
} from '../../../themes/globalConstants';
import { DefaultQueryParams } from '../../../utility/helpers/query-helpers';
import MakeItDisclaimerSection from '../MakeItSummary/components/MakeItDisclaimerSection';
import RemoveProductFromOrderModal from './components/RemoveProductFromOrderModal';
import { useTypedSelector } from '../../../store/reducers/reducer';
import {
    resetEditProductionOrder,
    setProductOrderForEdit,
    updateEditProductionOrder
} from '../../../store/actions/edit-production-order';
import EditProductionOrderGrid from './components/EditProductionOrderGrid';
import { ProductionOrder, ProductionOrderLine } from '../../../store/reducers/makeit-dashboard';
import EditProductionOrderSubheader from './components/EditProductionOrderSubheader';
import EditProductionOrderDiscardModal from './components/EditProductionOrderDiscardModal';
import { selectCustomerAccount } from '../../../store/actions/customer-context';
import { CustomerContextState, PaymentTerms } from '../../../store/reducers/customer-context';
import EditProductionOrderSubmitModal from './components/EditProductionOrderSubmitModal';
import FeeModal from '../MakeItConfiguration/components/FeeModal';
import { OrderProductTableRow } from '../../../store/reducers/orders-dashboard';
import OrdersService, { Order_Status } from '../../../utility/services/orders-service';
import ConfirmationModal from '../../reusable/molecules/ConfirmationModal';
import {
    getProductsWithFees,
    isWithinWeekRange
} from '../../../utility/helpers/production-order-helpers';
import {
    MAKEIT_DASHBOARD_LOADING_ERROR,
    MAKEIT_SAVE_INPROGRESS,
    SAVE_MAKEIT_DASHBOARD_ORDER
} from '../../../store/actions/action-types';
import { AuthState } from '../../../store/reducers/auth';
import ImpersonationWarning from '../../reusable/molecules/ImpersonationWarning';
import { selectIsImpersonation } from '../../../store/selectors';
import { setUseSummaryStoredSearch } from '../../../store/actions/make-it-summary';
import { validateMoqAmounts } from '../../../utility/helpers/order-helpers';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: '3em',
        padding: '2em',
        borderRadius: 4,
        maxWidth: containerMaxWidth
    },
    dashboard: {
        padding: '0'
    },
    main: {
        padding: '0'
    },
    actionBar: {
        margin: '1.5em 0',
        padding: '0'
    },
    actionBtn: {
        borderRadius: 'unset'
    },
    cancelBtn: {
        borderRadius: 'unset',
        float: 'right'
    },
    title: {
        fontWeight: blackWeight,
        color: theme.palette.secondary.main,
        padding: '0 0 1em 0'
    },
    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '0.5em'
    },
    errorMessage: {
        fontSize: small,
        fontWeight: boldWeight,
        color: red
    },
    subheader: {
        margin: '1.5em 0'
    },
    subsection: {
        fontSize: xl,
        fontWeight: boldWeight,
        letterSpacing: '0.13px',
        textAlign: 'left'
    }
}));

const EditProductionOrder = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const weekRange = 2;
    const { accessToken } = useTypedSelector<AuthState>((state) => state.auth);

    const { t } = useTranslation();
    const { id } = useParams<DefaultQueryParams>();
    const { permissions } = useTypedSelector((state) => state.auth);
    const { productionOrderToEdit, loading } = useTypedSelector(
        (state) => state.editProductionOrder
    );
    const { shipToAccounts, selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );

    const [submit, setSubmit] = useState<boolean>(false);
    const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
    const [lineToDelete, setLineToDelete] = useState<ProductionOrderLine>();
    const [openSubmitChangesModal, setOpenSubmitChangesModal] = useState<boolean>(false);
    const [openDiscardChangesModal, setOpenDiscardChangesModal] = useState<boolean>(false);
    const [warning, setWarning] = useState<boolean>(false);
    const [submitError, setSubmitError] = useState<boolean>(false);
    const [warningText, setWarningText] = useState<string>('');
    const [deliveryOrderWarning, setDeliveryOrderWarning] = useState<boolean>(false);
    const [warningTranslation, setWarningTranslation] = useState<string>('');
    const [tableInformation, setTableInformation] = useState<ProductionOrderLine[]>([]);
    const [hasNotBeenEdited, setHasNotBeenEdited] = useState<boolean>(true);
    const [openFeeModal, setOpenFeeModal] = useState<boolean>(false);
    const [acceptFee, setAcceptFee] = useState<boolean>(false);
    const [cancelOpen, setCancelOpen] = useState<boolean>(false);
    const [cancelOrderButtonDisabled, setCancelOrderButtonDisabled] = useState<boolean>(true);
    const [cancelSubmit, setCancelSubmit] = useState<boolean>(false);
    const [pristineProductionOrder, setPristineProductionOrder] = useState<ProductionOrder>();
    const [impersonationWarning, setImpersonationWarning] = useState<boolean>(false);
    const [showCancelWarning, setShowCancelWarning] = useState<boolean>(false);
    const isImpersonation = useTypedSelector<boolean>(selectIsImpersonation);

    const onDiscardChanges = () => {
        setOpenDiscardChangesModal(true);
    };

    const handleCloseSubmitModal = () => {
        setOpenSubmitChangesModal(false);
    };

    const handleCloseDiscardModal = () => {
        setOpenDiscardChangesModal(false);
    };

    const handleConfirmDiscardChanges = () => {
        setOpenDiscardChangesModal(false);
        dispatch(resetEditProductionOrder());
        history.push('/make-it-summary');
    };

    const handleRemoveItem = (line: ProductionOrderLine) => {
        setLineToDelete(line);
        setOpenRemoveModal(true);
    };

    const onCloseFeeModal = () => {
        setOpenFeeModal(false);
    };

    const onAcceptFee = () => {
        setAcceptFee(true);
        setOpenSubmitChangesModal(true);
    };

    const handleCloseRemoveModal = () => {
        setOpenRemoveModal(false);
    };

    const handleRemoveProduct = (line: ProductionOrderLine) => {
        if (lineToDelete) {
            handleItemUpdates(lineToDelete);
        }
        setHasNotBeenEdited(false);

        handleCloseRemoveModal();
    };

    const getCancelOrderButtonDisabled = (lines: ProductionOrderLine[]) => {
        let disableCancelButton = false;
        lines.forEach((line) => {
            const today = moment();
            if (isWithinWeekRange(today, moment(line.originalRequestedDate), weekRange)) {
                disableCancelButton = true;
            }
        });
        setCancelOrderButtonDisabled(disableCancelButton);
    };

    const handleItemUpdates = (line: ProductionOrderLine) => {
        let subRowHasNoPallets = false;
        setWarning(false);

        if (line.subRows && line.subRows.length) {
            line.subRows.forEach((product) => {
                if (product.palletQuantity === 0) {
                    subRowHasNoPallets = true;
                }
            });
        }

        if (
            //line has subrows
            ((line.subRows && line.subRows.length) ||
                //pallet quantity is not the original
                line.palletQuantity !== line.originalPalletQuantity ||
                //requested date is not the original
                line.requestedDate !== line.originalRequestedDate) &&
            //entire line has at least some pallets
            line.palletQuantity !== 0 &&
            //line has subrows, and all of them have allocated pallets
            !subRowHasNoPallets
        ) {
            setHasNotBeenEdited(false);
        }

        const updatedTableInformation: ProductionOrderLine[] = tableInformation.filter(
            (i) => i.productionOrderLineId !== line.productionOrderLineId
        );

        let updatedLines = [...updatedTableInformation, line];
        setTableInformation(updatedLines);
        productionOrderToEdit.lines = updatedLines;
        dispatch(updateEditProductionOrder(productionOrderToEdit));
    };

    // If going back to the Make-It Summary page, use the stored search to repopulate the search bar
    useEffect(() => {
        return () => {
            if (history.location.pathname.includes('make-it-summary')) {
                dispatch(setUseSummaryStoredSearch(true));
            }
        };
    }, [history]);

    const handleSubmit = () => {
        setSubmit(true);
        setWarning(false);
        setOpenSubmitChangesModal(false);

        const updatedTableInformation: ProductionOrderLine[] = tableInformation.filter(
            (i) => !i.isLineRemoved
        );

        let splitLines = OrdersService.createMakeItOrderlines(updatedTableInformation);

        //set productionOrderLineID to undefined for all po lines of same lineId after the first
        let previousLineId: number = splitLines[0].productionOrderLineId!;
        splitLines.forEach((line, index) => {
            if (index !== 0 && line.splitOrder && line.productionOrderLineId === previousLineId) {
                //track previous lineId and set current id to undefined
                previousLineId = line.productionOrderLineId;
                line.productionOrderLineId = undefined;
            } else {
                //we still want to track the previous lineId
                previousLineId = line.productionOrderLineId!;
            }
        });

        let productionOrderToSubmit = JSON.parse(JSON.stringify(productionOrderToEdit));
        productionOrderToSubmit.lines = splitLines.filter((line) => !line.isLineRemoved);
        if (!isImpersonation) {
            OrdersService.updateMakeItOrder(productionOrderToSubmit, accessToken)
                .then((response) => {
                    setSubmitError(false);
                    productionOrderToEdit.lines = splitLines;
                    productionOrderToEdit.canQuantityPallets = response.data.canQuantityPallets;
                    productionOrderToEdit.endQuantityPallets = response.data.endQuantityPallets;
                    productionOrderToEdit.canQuantitySKUs = response.data.canQuantitySKUs;
                    productionOrderToEdit.endQuantitySKUs = response.data.endQuantitySKUs;
                    history.push('/edit-production-order-confirmation');
                })
                .catch((error) => {
                    setSubmitError(true);
                })
                .finally(() => {
                    setSubmit(false);
                });
        } else {
            setSubmit(false);
            setImpersonationWarning(true);
        }
    };

    const handleSubmitButtonPressed = () => {
        let productsBelowAbsMinOrAboveOriginal = getProductsWithFees(tableInformation);

        if (
            productionOrderToEdit &&
            validateMoqAmounts(tableInformation, acceptFee) &&
            validateRequestedQuantity(tableInformation)
        ) {
            setOpenSubmitChangesModal(true);
        } else {
            if (!acceptFee && productsBelowAbsMinOrAboveOriginal.length === 0) {
                setOpenFeeModal(true);
            } else {
                const zeroOrderQuantiy = productionOrderToEdit.lines.find(
                    (product) =>
                        (!product.palletQuantity || product.palletQuantity < 1) &&
                        !product.isLineRemoved
                );
                const belowMinimumOrderQuantity = productionOrderToEdit.lines.find(
                    (product) => !product.minimumMet && !product.isLineRemoved
                );
                if (zeroOrderQuantiy) {
                    setWarning(true);
                    setWarningTranslation('zeroOrderQuantity');
                    setWarningText(
                        'Order quantity cannot be 0. Please enter a quantity or remove the product from the order.'
                    );
                } else if (belowMinimumOrderQuantity) {
                    setWarning(true);
                    setWarningTranslation('moqWarningMessage');
                    setWarningText(
                        'One or more quantities entered do not meet the minimum order quantity'
                    );
                }
            }
        }
    };

    const validateRequestedQuantity = (products: ProductionOrderLine[]) => {
        let counter = 0;
        products.forEach((product) => {
            if (
                product.palletQuantity &&
                product.originalPalletQuantity &&
                product.palletQuantity > product.originalPalletQuantity &&
                !product.isLineRemoved
            ) {
                counter++;
            }
        });
        return counter === 0;
    };

    const formatCancelModalSubHeader = () => {
        if (productionOrderToEdit) {
            if (
                productionOrderToEdit.productionOrderNumber &&
                productionOrderToEdit.productionOrderNumber !== 0
            ) {
                return (
                    <Typography className={classes.subsection}>
                        <Trans i18nKey="cancelProductionOrderSubHeader">
                            Are you sure you want to cancel Order #
                        </Trans>
                        {productionOrderToEdit.productionOrderNumber} {'?'}
                    </Typography>
                );
            } else {
                return (
                    <Typography className={classes.subsection}>
                        <Trans i18nKey="cancelProductionOrderSubHeader">
                            Are you sure you want to cancel Order #
                        </Trans>{' '}
                        <Trans i18nKey="pending">Pending</Trans> {'?'}
                    </Typography>
                );
            }
        }
    };

    const openCancelModal = () => {
        setShowCancelWarning(isImpersonation);
        setCancelOpen(true);
    };

    const onClose = () => {
        setCancelOpen(false);
        setShowCancelWarning(false);
    };

    const onCancelOrder = () => {
        if (pristineProductionOrder) {
            cancelOrderAndRedirect();
        }
    };

    const cancelOrderAndRedirect = () => {
        setCancelSubmit(true);

        if (!isImpersonation) {
            dispatch({ type: MAKEIT_SAVE_INPROGRESS });

            if (pristineProductionOrder) {
                pristineProductionOrder.status = Order_Status.CancelledPending;
                OrdersService.updateMakeItOrder(pristineProductionOrder, accessToken)
                    .then((response) => {
                        dispatch({
                            type: SAVE_MAKEIT_DASHBOARD_ORDER,
                            productionOrder: pristineProductionOrder
                        });
                        history.push('/cancel-production-order-confirmation');
                    })
                    .catch((error) => {
                        setCancelSubmit(false);
                        dispatch({
                            type: MAKEIT_DASHBOARD_LOADING_ERROR,
                            error
                        });
                    });
            }
        } else {
            setCancelSubmit(false);
        }
    };

    useEffect(() => {
        const convertedId = Number(id);
        if (
            id &&
            permissions &&
            (!productionOrderToEdit || productionOrderToEdit?.productionOrderId !== convertedId)
        ) {
            dispatch(setProductOrderForEdit(convertedId));
        }
    }, [dispatch, id, permissions]);

    useEffect(() => {
        if (productionOrderToEdit) {
            setDeliveryOrderWarning(false);
            if (!pristineProductionOrder) {
                setPristineProductionOrder(productionOrderToEdit);
            }
            if (selectedAccountId !== productionOrderToEdit.shipToId.toString()) {
                dispatch(selectCustomerAccount(productionOrderToEdit.shipToId.toString()));
            }
            if (shipToAccounts) {
                shipToAccounts.forEach((shipTo) => {
                    if (
                        shipTo.accountId === productionOrderToEdit.shipToId.toString() &&
                        shipTo.paymentTerms === PaymentTerms.CIA
                    ) {
                        // reroute CIA customers to unauth page since they shouldn't be here
                        // safeguards against someone manually inputting the URL
                        history.push('/unauthorized');
                    }
                });
            }
            if (productionOrderToEdit.lines) {
                setTableInformation(productionOrderToEdit.lines);
                getCancelOrderButtonDisabled(productionOrderToEdit.lines);
            }
            if (productionOrderToEdit?.linkedDeliveryOrder?.linkedDeliveryOrders?.length > 0) {
                productionOrderToEdit.lines.forEach((product) => {
                    if (productionOrderToEdit?.linkedDeliveryOrder?.linkedDeliveryOrders) {
                        const deliveryOrders =
                            productionOrderToEdit.linkedDeliveryOrder.linkedDeliveryOrders.filter(
                                (deliveryOrder) => deliveryOrder.productSKU === product.productSku
                            );
                        if (deliveryOrders.length > 0 && !product.hasCancelledDeliveryOrder) {
                            setDeliveryOrderWarning(true);
                        }
                    }
                });
            }
        }
    }, [dispatch, productionOrderToEdit, shipToAccounts]);

    // if user accepts the MOQ fee we want to submit their order
    useEffect(() => {
        if (acceptFee) {
            handleSubmitButtonPressed();
        }
    }, [acceptFee]);

    // Resetting edit production order state on page leave
    useEffect(() => {
        return () => {
            if (
                history.location.pathname !== '/edit-production-order-confirmation' &&
                history.location.pathname !== '/cancel-production-order-confirmation'
            ) {
                dispatch(resetEditProductionOrder());
            }
        };
    }, []);

    const footerActions = (
        <>
            <Grid item xs={2}>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="cancel-po-changes-button"
                    onClick={() => onDiscardChanges()}
                >
                    <Trans i18nKey="discardChanges">Discard Changes</Trans>
                </Button>
            </Grid>
            <Grid container item xs={7}>
                <ImpersonationWarning showWarning={impersonationWarning} warningType={'SUBMIT'} />
            </Grid>
            <Grid item xs={2}>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    data-testid="submit-po-changes-button"
                    className={classes.actionBtn}
                    onClick={() => handleSubmitButtonPressed()}
                    disabled={hasNotBeenEdited}
                >
                    <Trans i18nKey="submitChanges">Submit Changes</Trans>
                </Button>
            </Grid>
        </>
    );

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('makeIt', 'Make It'),
                description: t('editProductionOrder', 'Edit Production Order').toUpperCase(),
                thinBanner: true,
                displayDropdown: true,
                disableSelect: true
            }}
            actionFooter={{
                footerAction: footerActions,
                justify: 'space-between',
                sticky: true
            }}
            activity={Activity.NewOpenProductionOrders}
            loading={loading}
            onlyCheckSelectedLargeAccount={true}
        >
            <Paper elevation={2} className={classes.paper}>
                <Grid container spacing={2} align-items="flex-start">
                    <Grid container spacing={2} className={classes.dashboard}>
                        <Grid container item xs={12} className={classes.main}>
                            <Grid container item xs={12}>
                                <Grid item xs={8}>
                                    <MakeItDisclaimerSection />
                                </Grid>
                                <Grid item xs={4}>
                                    <Button
                                        type="submit"
                                        color="secondary"
                                        variant="outlined"
                                        data-testid="cancel-order-button"
                                        className={classes.cancelBtn}
                                        disabled={cancelOrderButtonDisabled || deliveryOrderWarning}
                                        onClick={() => openCancelModal()}
                                    >
                                        <Trans i18nKey="cancelOrder">Cancel Order</Trans>
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid container className={classes.subheader}>
                                <EditProductionOrderSubheader order={productionOrderToEdit} />
                            </Grid>
                            {productionOrderToEdit && (
                                <EditProductionOrderGrid
                                    lines={tableInformation ? tableInformation : []}
                                    onRemoveItem={handleRemoveItem}
                                    onItemUpdates={handleItemUpdates}
                                    deliveryOrderWarning={deliveryOrderWarning}
                                    order={productionOrderToEdit}
                                    warning={warning}
                                    warningMessage={warningText}
                                    warningTranslation={warningTranslation}
                                />
                            )}
                        </Grid>
                    </Grid>
                    {submit && (
                        <Grid
                            container
                            item
                            xs={12}
                            alignItems="center"
                            className={classes.actionBar}
                        >
                            <Grid container item xs={12} className={classes.spinningLoader}>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Paper>
            {submitError && (
                <Grid
                    container
                    item
                    xs={12}
                    alignItems="center"
                    justify="center"
                    className={classes.actionBar}
                >
                    <Typography className={classes.errorMessage}>
                        <Trans i18nKey="submitError">
                            There was an error saving your Make It order. Please try again later.
                        </Trans>
                    </Typography>
                </Grid>
            )}
            <FeeModal
                products={tableInformation as OrderProductTableRow[]}
                open={openFeeModal}
                onClose={onCloseFeeModal}
                onAcceptFee={onAcceptFee}
            />
            <EditProductionOrderDiscardModal
                onDiscardChanges={handleConfirmDiscardChanges}
                onClose={handleCloseDiscardModal}
                open={openDiscardChangesModal}
            />
            <EditProductionOrderSubmitModal
                onSubmitChanges={handleSubmit}
                onClose={handleCloseSubmitModal}
                open={openSubmitChangesModal}
            />
            <RemoveProductFromOrderModal
                open={openRemoveModal}
                onRemoveProduct={handleRemoveProduct}
                onClose={handleCloseRemoveModal}
                line={lineToDelete}
            />
            <ConfirmationModal
                title={
                    <Trans i18nKey="cancelProductionOrder">
                        Please confirm you want to cancel this order.
                    </Trans>
                }
                open={cancelOpen}
                logo={false}
                subheader={formatCancelModalSubHeader()}
                cancelText={<Trans i18nKey="no">No</Trans>}
                continueText={<Trans i18nKey="yesCancel">Yes, Cancel</Trans>}
                disableContinue={isImpersonation}
                onClose={onClose}
                onCancel={onClose}
                saveProgress={onCancelOrder}
                showSpinner={cancelSubmit}
                showImpersonationWarning={showCancelWarning}
                impersonationWarningType={'SUBMIT'}
            />
        </ProcessingPageTemplate>
    );
};

export default EditProductionOrder;
