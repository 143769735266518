import { CircularProgress, Grid, Link, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    ballDrkBlue,
    ballGray,
    ballGrayBlue,
    blackWeight,
    black_5,
    boldWeight,
    large,
    white,
    xxxl
} from '../../../../themes/globalConstants';
import DashboardWidgetMetricSection from './DashboardWidgetMetricSection';
import DashboardWidgetMetric from './DashboardWidgetMetric';
import DashboardWidgetMetricRow from './DashboardWidgetMetricRow';
import { Trans, useTranslation } from 'react-i18next';
import { enUS } from '../../../../utility/translations/locales';
import { AuthState } from '../../../../store/reducers/auth';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import {
    CustomerDashboardState,
    MakeItDashboard
} from '../../../../store/reducers/customer-dashboard';
import {
    hasPendingPaymentPersonas,
    hasProductPlanningPersonas
} from '../../../../utility/helpers/dashboard-helpers';
import { TotalNDayRanges } from '../../../../store/reducers/product-planning';
import MakeItLinksDropdown from './MakeItLinksDropdown';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { productPlanningFromDashboardLink } from '../../../../store/root-actions';
import { selectIsCIACustomer, selectIsLargeCustomerAccount } from '../../../../store/selectors';

interface Props {
    dragHandleProps?: DraggableProvidedDragHandleProps;
}

const useStyles = makeStyles((theme) => ({
    paper: {
        borderRadius: 4,
        padding: '2em',
        position: 'relative'
    },
    titleContainer: {
        color: ballGray,
        display: 'flex',
        alignItems: 'center',
        marginBottom: '2.125em'
    },
    title: {
        fontWeight: blackWeight,
        letterSpacing: 1
    },
    moreIcon: {
        width: '1.25em',
        height: '1.25em',
        marginLeft: '.5em'
    },
    widgetSectionHeader: {
        fontWeight: blackWeight,
        color: ballGray,
        letterSpacing: 1,
        textTransform: 'uppercase',
        marginBottom: '1.75em'
    },
    dragAndDropIcon: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: '1.75em'
    },
    link: {
        fontSize: large,
        fontWeight: blackWeight
    },
    agedBalanceDetails: {
        display: 'flex',
        alignItems: 'center'
    },
    widgetNumber: {
        fontSize: xxxl,
        fontWeight: 500,
        lineHeight: 1,
        color: ballDrkBlue,
        marginBottom: '.75rem',
        textDecoration: 'none',
        position: 'relative',
        borderBottom: `1px solid ${white}`,

        '&:hover': {
            borderBottom: `1px solid ${ballDrkBlue}`
        }
    },
    subHeaderDetails: {
        fontWeight: boldWeight,
        marginLeft: '0.75em',
        maxWidth: '160px'
    },
    agedBalanceSubHeaderText: {
        color: ballGrayBlue,
        textTransform: 'uppercase'
    },
    numPalletsSubHeaderText: {
        color: black_5,
        fontWeight: boldWeight,
        textTransform: 'uppercase',
        marginBottom: '4px'
    },
    clockIcon: {
        height: '1.25em'
    },
    iconText: {
        color: black_5,
        fontWeight: blackWeight,
        marginLeft: '0.5em'
    },
    agedBalanceContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: '1em 0 2em'
    }
}));

const WeekEnum = {
    '1': 'nextWeek',
    '4': 'twoToFourWeeks',
    '8': 'fiveToEightWeeks',
    '16': 'ninePlusWeeks'
};

export default function MakeItDashboardWidget({ dragHandleProps }: Props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const { status, dashboardData } = useTypedSelector<CustomerDashboardState>(
        (state) => state.customerDashboard
    );
    const [pendingPaymentAccess, setPendingPaymentAccess] = useState<boolean>(false);
    const [productPlanningAccess, setProductPlanningAccess] = useState<boolean>(false);
    const [openOrders, setOpenOrders] = useState<number>(0);
    const auth = useTypedSelector<AuthState>((state) => state.auth);
    const [makeItDashboard, setMakeItDashboard] = useState<MakeItDashboard>();
    const [futureOrders, setFutureOrders] = useState({});
    const dispatch = useDispatch();
    const isCiaCustomer = useTypedSelector(selectIsCIACustomer);
    const isLargeCustomer = useTypedSelector<boolean>(selectIsLargeCustomerAccount);

    const largeCustomerLink = () => {
        return isLargeCustomer ? 'po-' : '';
    };

    useEffect(() => {
        if (status === 'success' && dashboardData?.makeItDashboard) {
            setMakeItDashboard(dashboardData.makeItDashboard);
        }
    }, [status, dashboardData]);

    // Once data has been loaded, handle filtering and formatting for widget
    useEffect(() => {
        if (makeItDashboard?.items && makeItDashboard.items.length > 0) {
            const currentWeek = makeItDashboard.items.find((item) => item.week === 0);
            setOpenOrders(currentWeek?.openOrders || 0);

            const futureInformation = makeItDashboard.items
                .filter((item) => item.week > 0)
                .reduce((acc, item) => {
                    return {
                        ...acc,
                        [WeekEnum[item.week]]: {
                            ...item,
                            title: t(WeekEnum[item.week], enUS[item.week])
                        }
                    };
                }, {});
            setFutureOrders(futureInformation);
        }

        setPendingPaymentAccess(hasPendingPaymentPersonas(auth));
        setProductPlanningAccess(hasProductPlanningPersonas(auth));
    }, [makeItDashboard, auth, t]);

    return (
        <Paper elevation={2} className={classes.paper} data-testid="make-it-dashboard-widget">
            {!!dragHandleProps && (
                <div {...dragHandleProps} data-testid="make-it-drag-handle">
                    <img
                        className={classes.dragAndDropIcon}
                        src={`${process.env.PUBLIC_URL}/assets/Drag_And_Drop_Icon.svg`}
                        alt="Drag And Drop Icon"
                    />
                </div>
            )}
            <div className={classes.titleContainer}>
                <Typography variant="h3" className={classes.title}>
                    {t('makeItOrders', enUS.makeItOrders)}
                </Typography>
                <MakeItLinksDropdown isLargeCustomer={isLargeCustomer} />
            </div>
            {status === 'loading' || status === 'idle' ? (
                <Grid container alignItems="center" justify="center">
                    <CircularProgress />
                </Grid>
            ) : (
                <Grid container spacing={6}>
                    <Grid item xs={isCiaCustomer ? 12 : 7}>
                        <Grid container direction="row">
                            <Grid item md={isCiaCustomer ? 6 : 12}>
                                <DashboardWidgetMetricSection
                                    title={t('orderSummary', enUS.orderSummary)}
                                    left={
                                        <DashboardWidgetMetric
                                            quantity={openOrders}
                                            link={`/make-it-${largeCustomerLink()}summary`}
                                            subheader={t('openOrders', enUS.openOrders)}
                                            subheaderIconUrl={`${process.env.PUBLIC_URL}/assets/Open_Orders_Icon.svg`}
                                        />
                                    }
                                    center={
                                        isLargeCustomer ? (
                                            <div>
                                                <Grid item data-testid="draft-orders-widget">
                                                    <DashboardWidgetMetric
                                                        quantity={makeItDashboard?.draftOrders ?? 0}
                                                        link={'make-it-drafts-summary'}
                                                        subheader={t(
                                                            'draftOrders',
                                                            enUS.draftOrders
                                                        )}
                                                        subheaderIconUrl={`${process.env.PUBLIC_URL}/assets/Draft_Orders_Icon.svg`}
                                                    />
                                                </Grid>
                                            </div>
                                        ) : undefined
                                    }
                                    right={
                                        <Grid className={classes.agedBalanceContainer}>
                                            <Grid item className={classes.agedBalanceDetails}>
                                                <Link
                                                    to={`/${largeCustomerLink()}product-planning?expand=1`}
                                                    underline="none"
                                                    component={RouterLink}
                                                    data-testid="product-planning-dashboard-link"
                                                    className={classes.link}
                                                    onClick={() =>
                                                        dispatch(
                                                            productPlanningFromDashboardLink(true)
                                                        )
                                                    }
                                                >
                                                    <Typography className={classes.widgetNumber}>
                                                        {makeItDashboard?.agedProducts ?? 0}
                                                    </Typography>
                                                </Link>

                                                <div>
                                                    <Typography
                                                        className={clsx(
                                                            classes.subHeaderDetails,
                                                            classes.agedBalanceSubHeaderText
                                                        )}
                                                    >
                                                        {(makeItDashboard?.agedProducts ?? 0) ===
                                                        1 ? (
                                                            <Trans i18nKey="agedSku">
                                                                Aged SKU
                                                            </Trans>
                                                        ) : (
                                                            <Trans i18nKey="agedSkus">
                                                                Aged SKUs
                                                            </Trans>
                                                        )}
                                                    </Typography>
                                                    <Typography
                                                        className={clsx(
                                                            classes.subHeaderDetails,
                                                            classes.numPalletsSubHeaderText
                                                        )}
                                                    >
                                                        <Trans i18nKey="numOfPallets">
                                                            No. of Pallets
                                                        </Trans>
                                                        :{' '}
                                                        {(
                                                            makeItDashboard?.agedProductsPalletQuantity ??
                                                            0
                                                        ).toLocaleString()}{' '}
                                                        PL
                                                    </Typography>
                                                </div>
                                            </Grid>
                                            <Grid container alignItems="center">
                                                <img
                                                    className={classes.clockIcon}
                                                    src={`${process.env.PUBLIC_URL}/assets/icon-clock-dashboard.svg`}
                                                    alt={`Aged Products Icon`}
                                                />
                                                <Typography
                                                    variant="body1"
                                                    className={classes.iconText}
                                                >
                                                    <Trans i18nKey="agedBalance">
                                                        Aged Balance
                                                    </Trans>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                />
                            </Grid>
                            {isCiaCustomer && (
                                <Grid item md={6} data-testid="pending-payments-widget">
                                    <DashboardWidgetMetricSection
                                        title={t('pendingPayments', enUS.pendingPayments)}
                                        left={
                                            <DashboardWidgetMetric
                                                quantity={makeItDashboard?.pastDuePayments || 0}
                                                subheader={t(
                                                    'paymentsPastDue',
                                                    enUS.paymentsPastDue
                                                )}
                                                subheaderIconUrl={`${process.env.PUBLIC_URL}/assets/Red_Exclamation_Icon.svg`}
                                                type="orange"
                                                link="/pending-payments?type=pending"
                                                enableLink={pendingPaymentAccess}
                                            />
                                        }
                                        center={
                                            <DashboardWidgetMetric
                                                quantity={makeItDashboard?.approachingPayments || 0}
                                                subheader={t(
                                                    'approachingPayments',
                                                    enUS.approachingPayments
                                                )}
                                                subheaderIconUrl={`${process.env.PUBLIC_URL}/assets/Pending_Payments_Icon.svg`}
                                                type="yellow"
                                                link="/pending-payments?type=approaching"
                                                enableLink={pendingPaymentAccess}
                                            />
                                        }
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>

                    <Grid item xs={isCiaCustomer ? 12 : 5}>
                        <Typography variant="h5" className={classes.widgetSectionHeader}>
                            <Trans i18nKey="productionBalance">{enUS.productionBalance}</Trans>
                        </Typography>
                        {futureOrders && Object.keys(futureOrders).length === 4 && (
                            <Grid
                                container
                                direction="row"
                                spacing={isCiaCustomer ? 10 : undefined}
                            >
                                <Grid item md={isCiaCustomer ? 6 : 12} container direction="column">
                                    <DashboardWidgetMetricRow
                                        title={futureOrders[WeekEnum['1']].title}
                                        skuCount={futureOrders[WeekEnum['1']].productSkus}
                                        secondItemCount={futureOrders[WeekEnum['1']].pallets}
                                        secondItemUnit={'PL'}
                                        isLink={productPlanningAccess}
                                        route={`/${largeCustomerLink()}product-planning?range=${
                                            TotalNDayRanges.nineWeeks
                                        }`}
                                    />
                                    <DashboardWidgetMetricRow
                                        title={futureOrders[WeekEnum['4']].title}
                                        skuCount={futureOrders[WeekEnum['4']].productSkus}
                                        secondItemCount={futureOrders[WeekEnum['4']].pallets}
                                        secondItemUnit={'PL'}
                                        isLink={productPlanningAccess}
                                        route={`/${largeCustomerLink()}product-planning?range=${
                                            TotalNDayRanges.nineWeeks
                                        }`}
                                    />
                                </Grid>
                                <Grid item md={isCiaCustomer ? 6 : 12} container direction="column">
                                    <DashboardWidgetMetricRow
                                        title={futureOrders[WeekEnum['8']].title}
                                        skuCount={futureOrders[WeekEnum['8']].productSkus}
                                        secondItemCount={futureOrders[WeekEnum['8']].pallets}
                                        secondItemUnit={'PL'}
                                        isLink={productPlanningAccess}
                                        route={`/${largeCustomerLink()}product-planning?range=${
                                            TotalNDayRanges.nineWeeks
                                        }`}
                                    />
                                    <DashboardWidgetMetricRow
                                        title={futureOrders[WeekEnum['16']].title}
                                        skuCount={futureOrders[WeekEnum['16']].productSkus}
                                        secondItemCount={futureOrders[WeekEnum['16']].pallets}
                                        secondItemUnit={'PL'}
                                        isLink={productPlanningAccess}
                                        route={`/${largeCustomerLink()}product-planning?range=${
                                            TotalNDayRanges.seventeenWeeks
                                        }`}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            )}
        </Paper>
    );
}
