import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Grid, makeStyles, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import { ContentSection, ContentSubSection, Image } from '../../../../store/reducers/content-page';
import AccordionSection from './AccordionSection';
import Link from '../../../reusable/atoms/Link';
import { ltBlueGrey, ltBlueGrey_15 } from '../../../../themes/globalConstants';

interface Props {
    section: ContentSection;
    orientation?: string;
}

const useStyles = makeStyles((theme) => ({
    container: {
        margin: '1.875em 0',
        '&:nth-child(even)': {
            background: ltBlueGrey_15
        },
        [theme.breakpoints.down('md')]: {
            paddingLeft: '0.5em',
            marginBottom: '6em'
        }
    },
    header: {
        alignItems: 'baseline',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'space-between'
        }
    },
    imgAlign: {
        order: 2
    },
    img: {
        width: '100%'
    },
    smallImg: {
        marginBottom: '2em'
    },
    arrowContainer: {
        marginTop: '3.625em'
    },
    firstArrow: {
        color: ltBlueGrey,
        fontSize: '2em'
    },
    secondArrow: {
        marginTop: '-0.625em',
        color: theme.palette.primary.main,
        marginBottom: '0.625em',
        fontSize: '2em'
    },
    collapseContainer: {
        [theme.breakpoints.down('xs')]: {
            marginTop: '1em'
        }
    },
    link: {
        padding: '0',
        cursor: 'pointer',
        fontSize: '0.750em',
        textDecoration: 'underline',
        color: theme.palette.primary.main,
        marginLeft: '1.625em',
        '&:hover': {
            textDecoration: 'underline',
            color: theme.palette.primary.dark
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
            marginRight: '0.625em'
        }
    }
}));

const Accordion = (props: Props) => {
    const classes = useStyles();
    const contentSection = props.section;
    const image = contentSection.image as Image;
    const subSections = contentSection.subsections as ContentSubSection[];
    const orientation = props.orientation;
    const columnWidth: number = orientation ? 9 : 12;
    const theme = useTheme();
    const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const smSpacing = useMediaQuery(theme.breakpoints.down('md'));
    const smScreens = useMediaQuery(theme.breakpoints.down('sm'));
    const [collapseAll, setCollapseAll] = useState(false);

    const renderSubSections = subSections?.map((contentSubSection: ContentSubSection, i: number) => {
        return <AccordionSection section={contentSubSection} key={i} collapse={collapseAll} />;
    });

    const imageAlignment = () => {
        if (orientation === 'right') {
            return true;
        }
    };

    const handleCollapseAll = () => {
        setCollapseAll(!collapseAll);
    };

    useTranslation();

    return (
        <Grid
            container
            item
            spacing={smSpacing ? 0 : 5}
            justify="center"
            className={classes.container}
        >
            {image && image.imageUrl && !xsScreen && (
                <Grid
                    item
                    xs={3}
                    className={smScreens ? classes.smallImg : imageAlignment() && classes.imgAlign}
                >
                    <img
                        src={image.imageUrl}
                        alt={
                            image.alternateText
                                ? image.alternateText
                                : contentSection.header + ' image'
                        }
                        className={classes.img}
                    />
                </Grid>
            )}
            <Grid item xs={12} md={columnWidth as any}>
                <Grid item container xs={12} className={classes.header}>
                    <Grid item>
                        <Typography variant="h3">{contentSection.header}</Typography>
                    </Grid>
                    <Grid item className={classes.collapseContainer}>
                        <Link data-testid="accordion-handle-collapse" className={classes.link} onClick={handleCollapseAll}>
                            <Trans i18nKey="collapseAll">Collapse All</Trans>
                        </Link>
                    </Grid>
                </Grid>
                {renderSubSections}
            </Grid>
        </Grid>
    );
};

export default Accordion;
