import {
    PRODUCTS_CONFIGURATIONS_LOADING,
    PRODUCTS_CONFIGURED_ERROR,
    PRODUCTS_CONFIGURED_LOADING,
    PRODUCTS_CONFIGURED_SAVED,
    PRODUCTS_CONFIGURATIONS_LOADED,
    PRODUCTS_CONFIGURED_CLEAR
} from '../actions/action-types';

export interface ProductConfiguration {
    configuredProductId: number;
    lastModifiedDate: string;
    name: string;
    productCode: string;
}

export interface ProductsConfigurationState {
    products: ProductConfiguration[];
    configuredProduct?: ProductConfiguration;
    loading?: boolean;
    loaded?: boolean;
    error?: any;
}

const initialState: ProductsConfigurationState = {
    products: [],
    configuredProduct: undefined,
    loading: false,
    loaded: false,
    error: undefined
};

const productsConfiguration = (state = initialState, action: any): ProductsConfigurationState => {
    switch (action.type) {
        case PRODUCTS_CONFIGURATIONS_LOADING:
            return {
                ...state,
                loading: true,
                loaded: false
            };
        case PRODUCTS_CONFIGURATIONS_LOADED:
            return {
                ...state,
                loaded: true,
                loading: false,
                products: action.products
            };
        case PRODUCTS_CONFIGURED_LOADING:
            return {
                ...state,
                loading: true,
                loaded: false,
                error: undefined
            };
        case PRODUCTS_CONFIGURED_ERROR:
            return {
                ...state,
                loading: false,
                loaded: true,
                error: action.error
            };
        case PRODUCTS_CONFIGURED_SAVED:
            return {
                ...state,
                loaded: true,
                loading: false,
                configuredProduct: action.configuredProduct
            };
        case PRODUCTS_CONFIGURED_CLEAR:
            return {
                ...initialState
            };
        default:
            return state;
    }
};

export default productsConfiguration;
