import { Select, MenuItem, makeStyles, Typography, Grid, MenuProps } from '@material-ui/core';
import React, { Dispatch, SetStateAction } from 'react';
import {
    shipRed,
    small,
    successGreen,
    white,
    ballBlue,
    ballLtGray_3,
    boldWeight
} from '../../../../themes/globalConstants';
import { getAccountAddress } from '../../../../utility/helpers/address-helpers';
import clsx from 'clsx';
import { FormattedAccount } from '../models/FormattedAccount';

interface Props {
    name: string;
    testId: string;
    selectObjects: FormattedAccount[];
    selected: string | undefined;
    updateSelected: Dispatch<SetStateAction<string | undefined>>;
    showIcons: boolean;
    className?: string;
    showCsvWarning?: boolean;
}

const useStyles = makeStyles(() => ({
    shipToText: {
        fontSize: small
    },
    shipToName: {
        fontSize: '14px'
    },
    boldText: {
        fontWeight: boldWeight
    },
    inputSelect: {
        width: '90%',
        height: 'flex',
        borderBottom: `0.25em solid ${ballBlue}`,
        '& .MuiInputBase-input': {
            padding: '1em 1em 1em 3em'
        }
    },
    menu: {
        marginTop: '0.25em',
        '& ul': {
            marginRight: '-8em'
        },
        '& li': {
            width: '100%',
            paddingLeft: '3em',
            boxSizing: 'border-box',
            '&:hover': {
                backgroundColor: ballLtGray_3
            }
        }
    },
    clearBackground: {
        backgroundColor: white,
        '&.MuiListItem-root.Mui-selected': {
            backgroundColor: white
        },
        '&.Mui-focused': {
            backgroundColor: white
        },
        '&:hover': {
            backgroundColor: white
        }
    },
    icon: {
        width: '1.5em',
        height: '1.5em',
        position: 'absolute',
        left: '16px',
        top: '50%',
        transform: 'translateY(-50%)'
    },
    errorIcon: {
        fill: shipRed,
        width: '1.5em',
        height: '1.5em',
        position: 'absolute',
        left: '16px',
        top: '50%',
        transform: 'translateY(-50%)'
    },
    completedIcon: {
        fill: successGreen
    }
}));

const ShipToDropdown = (props: Props) => {
    const classes = useStyles();

    const shipToMenuProperties = {
        classes: {
            paper: classes.menu
        },
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
        },
        getContentAnchorEl: null
    } as MenuProps;

    const handleChange = (event: any) => {
        props.updateSelected(event.target.value);
    };

    return (
        <Select
            inputProps={{ 'data-testid': props.testId }}
            className={clsx(classes.inputSelect, classes.clearBackground, props.className)}
            value={props.selected ? props.selected : null}
            onChange={handleChange}
            MenuProps={shipToMenuProperties}
            disableUnderline
        >
            {props.selectObjects.map((option) => (
                <MenuItem
                    key={option.account.name}
                    value={option.account.accountId}
                    data-testid="menu-option"
                    className={classes.clearBackground}
                >
                    {props.showIcons && option.state === 'valid' && (
                        <img
                            data-testid="complete-icon"
                            className={clsx(classes.icon, classes.completedIcon)}
                            src={process.env.PUBLIC_URL + '/assets/Confirmation_icon.svg'}
                            alt="Confirmation Icon"
                        />
                    )}{' '}
                    {props.showIcons && option.state === 'warning' && (
                        <img
                            data-testid="warning-icon"
                            className={clsx(classes.icon)}
                            src={process.env.PUBLIC_URL + '/assets/Warning_icon.svg'}
                            alt="Warning Icon"
                        />
                    )}{' '}
                    {props.showIcons && option.state === 'error' && (
                        <img
                            data-testid="complete-icon"
                            className={classes.errorIcon}
                            src={process.env.PUBLIC_URL + '/assets/Error_Icon.svg'}
                            alt="Error Icon"
                        />
                    )}
                    {props.showCsvWarning && option.state !== 'warning' && (
                        <img
                            data-testid="info-icon"
                            className={classes.icon}
                            src={process.env.PUBLIC_URL + '/assets/InfoTriangle_Icon.svg'}
                            alt="Information Icon"
                        />
                    )}
                    <Grid>
                        <Typography
                            data-testid="ship-to-name"
                            className={clsx(classes.shipToName, classes.boldText)}
                        >
                            {option.account.name}
                        </Typography>
                        <Typography data-testid="ship-to-address" className={classes.shipToText}>
                            {getAccountAddress(option.account)}
                        </Typography>
                        <Typography data-testid="ship-to-id" className={classes.shipToText}>
                            #{option.account.accountId}
                        </Typography>
                    </Grid>
                </MenuItem>
            ))}
        </Select>
    );
};

export default ShipToDropdown;
