import React, { useState, useEffect } from 'react';
import { Grid, Typography, makeStyles, Divider } from '@material-ui/core';
import { Trans } from 'react-i18next';
import {
    DeliveryShipment,
    ShipmentEditStatus
} from '../../../../store/reducers/shipping-dashboard';
import {
    addTimezoneOffset,
    getFullFormattedDateTime
} from '../../../../utility/helpers/date-helpers';
import {
    ballGray,
    ballDrkBlue,
    xxl,
    blackWeight,
    medium,
    ltBlueGrey_34
} from '../../../../themes/globalConstants';
import clsx from 'clsx';
import EditShipmentsConfirmationConfigGrid from './EditShipmentsConfirmationConfigGrid';

interface Props {
    currentShipment: DeliveryShipment;
    shipmentIndex: number;
}

const useStyles = makeStyles(() => ({
    main: {
        width: '100%'
    },
    cardSubheader: {
        color: ballGray
    },
    dateDescription: {
        color: ballGray,
        display: 'flex',
        alignItems: 'center'
    },
    altDescriptionColor: {
        color: ballDrkBlue
    },
    dateDescriptionMain: {
        fontSize: xxl,
        fontWeight: blackWeight
    },
    deliveryTitle: {
        fontWeight: blackWeight,
        fontSize: medium
    },
    actionsBar: {
        marginTop: '1.25em'
    },
    shipmentEditedStatusText: {
        color: ballGray,
        fontSize: medium,
        textTransform: 'none'
    },
    divider: {
        height: '0.32em',
        backgroundColor: ltBlueGrey_34,
        width: '100%',
        margin: '.5em 0em .5em 0em'
    }
}));

const EditShipmentsConfirmationGrid = ({ currentShipment, shipmentIndex }: Props) => {
    const classes = useStyles();
    const [isCanType, setIsCanType] = useState(true);

    useEffect(() => {
        currentShipment.shipmentType &&
        currentShipment.shipmentType.toLowerCase() === 'ends/closures'
            ? setIsCanType(false)
            : setIsCanType(true);
        // Runs only once
    }, []);
    const adjustedDateTime = addTimezoneOffset(currentShipment.deliveryDateTime);
    const shipmentDayAndTime = getFullFormattedDateTime('', adjustedDateTime);

    return (
        <>
            <Grid className={classes.main} container item>
                <Divider className={classes.divider} />
                <Grid container item md={7}>
                    {currentShipment.shipmentEditStatus === ShipmentEditStatus.Edited && (
                        <Grid item md={12} className={classes.dateDescription}>
                            <Typography
                                className={clsx(
                                    classes.dateDescriptionMain,
                                    classes.altDescriptionColor
                                )}
                                data-testid="edited-text"
                            >
                                <Trans i18nKey="shipment">Shipment</Trans>{' '}
                                <Trans i18nKey="edited">Edited</Trans>
                                {', '}
                                <Trans i18nKey="deliveryDate">Delivery Date</Trans>
                                {': '}
                                {shipmentDayAndTime}
                            </Typography>
                        </Grid>
                    )}
                    {currentShipment.shipmentEditStatus === ShipmentEditStatus.Cancelled && (
                        <>
                            <Grid item md={12}>
                                <Typography
                                    className={clsx(
                                        classes.dateDescriptionMain,
                                        classes.altDescriptionColor
                                    )}
                                    data-testid="cancelled-text"
                                >
                                    <Trans i18nKey="shipment">Shipment</Trans>{' '}
                                    <Trans i18nKey="cancelled">Cancelled</Trans>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    <Trans i18nKey="deliveryDate">Delivery Date</Trans>
                                    {': '}
                                    {shipmentDayAndTime}
                                </Typography>
                            </Grid>
                        </>
                    )}
                    {currentShipment.shipmentEditStatus === ShipmentEditStatus.Unedited && (
                        <Grid item md={12}>
                            <Typography
                                className={clsx(
                                    classes.dateDescriptionMain,
                                    classes.altDescriptionColor
                                )}
                            >
                                <Trans i18nKey="deliveryDate">Delivery Date</Trans>
                                {': '}
                                {shipmentDayAndTime}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
                <Grid container item md={5} justify="flex-end">
                    <Grid item xs={3}>
                        <Typography variant="subtitle2" className={classes.cardSubheader}>
                            <Trans i18nKey="type">Type</Trans>
                        </Typography>
                        <Typography>
                            {isCanType ? (
                                <Trans i18nKey="cansBottles">Cans/Bottles</Trans>
                            ) : (
                                <Trans i18nKey="endClosures">End/Closures</Trans>
                            )}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="subtitle2" className={classes.cardSubheader}>
                            <Trans i18nKey="quantity">Quantity</Trans>
                        </Typography>
                        <Typography>{currentShipment.shipmentQuantity} PL</Typography>
                        <Typography>
                            {Number(currentShipment.shipmentQuantityEaches).toLocaleString()} ea.
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="subtitle2" className={classes.cardSubheader}>
                            <Trans i18nKey="products">Products</Trans>
                        </Typography>
                        <Typography>{currentShipment.shipmentSKUs}</Typography>
                    </Grid>
                </Grid>
                <Divider className={classes.divider} />
            </Grid>
            <Grid container>
                <Grid container item>
                    {currentShipment.loads && currentShipment.loads.length && (
                        <EditShipmentsConfirmationConfigGrid
                            items={currentShipment.loads}
                            shipment={currentShipment}
                        />
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default EditShipmentsConfirmationGrid;
