import { makeStyles, Grid, Typography, CircularProgress, TextField } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { blackWeight, boldWeight, large, red, small, white } from '../../../themes/globalConstants';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import OrderCancelModal from '../../reusable/molecules/OrderCancelModal';
import Button from '../../reusable/atoms/Button';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { MakeState, ProductionOrder } from '../../../store/reducers/makeit-dashboard';
import MakeItConfigGrid from './components/MakeItConfigGrid';
import { OrderProductTableRow } from '../../../store/reducers/orders-dashboard';
import { useHistory } from 'react-router';
import {
    saveSubmittedProductionOrder,
    updateOrderLineState,
    updateProductList,
    updateProductsToOrder,
    resetMakeItState,
    saveDraftMakeItOrder
} from '../../../store/actions/makeit-dashboard';
import OrdersService from '../../../utility/services/orders-service';
import FeeModal from './components/FeeModal';
import SaveModal from '../../reusable/molecules/SaveModal';
import SaveConfirmedModal from '../../reusable/molecules/SaveConfirmedModal';
import { Activity, SecurityLevel } from '../../../utility/auth/useSecurity';
import { DefaultQueryParams } from '../../../utility/helpers/query-helpers';
import {
    customerPoIsValid,
    getProductsBelowAbsoluteMinimum,
    validateMoqAmounts
} from '../../../utility/helpers/order-helpers';
import { createAppInsightsError } from '../../../utility/helpers/production-order-helpers';
import { AuthState } from '../../../store/reducers/auth';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { selectIsImpersonation } from '../../../store/selectors/index';
import QuantityUnitSelector from '../../reusable/molecules/QuantityUnitSelector';
import { QuantityUnitSelectorProvider } from '../../reusable/molecules/QuantityUnitSelectorContext';
import ImpersonationWarning from '../../reusable/molecules/ImpersonationWarning';

const useStyles = makeStyles((theme) => ({
    dashboard: {
        padding: '0'
    },
    main: {
        padding: '0',
        marginTop: '2.750em'
    },
    content: {
        marginBottom: '1em'
    },
    actionBar: {
        margin: '1.5em 0',
        padding: '0'
    },
    btnWrapper: {
        '& button:last-child': {
            marginLeft: '1.25em'
        }
    },
    actionBtn: {
        borderRadius: 'unset'
    },
    title: {
        fontWeight: blackWeight,
        color: theme.palette.secondary.main,
        padding: '0 0 1em 0'
    },
    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '0.5em'
    },
    fieldLabel: {
        fontSize: large,
        fontWeight: blackWeight,
        textTransform: 'uppercase'
    },
    fieldInput: {
        width: '85%',
        '& .MuiInputBase-root': {
            border: '1px solid',
            borderColor: theme.palette.secondary.light,
            backgroundColor: white,
            height: '2em',
            padding: '0.5em',
            '&:before': {
                display: 'none'
            }
        }
    },
    fieldHelper: {
        fontSize: small,
        fontWeight: boldWeight
    },
    errorMessage: {
        fontSize: 12,
        fontWeight: boldWeight,
        color: red
    },
    quantityUnitSelector: {
        marginBottom: '1em'
    }
}));

const MakeItConfiguration = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams<DefaultQueryParams>();
    const { t } = useTranslation();

    const state = useTypedSelector((state) => state);
    const { productsToOrder, productionOrder, error, quantityUnit } = useTypedSelector<MakeState>(
        (state) => state.makeItDashboard
    );
    const { userInfo } = useTypedSelector<AuthState>((state) => state.auth);
    const { selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );

    const [filteredViewProducts, setFilteredViewProducts] = useState<OrderProductTableRow[]>();
    const [currentViewTypes, setCurrentViewTypes] = useState([''] as string[]);

    const [enableSaveDraft] = useState<boolean>(false);
    const [poNumber, setPONumber] = useState<string>('');
    const [poError, setPOError] = useState<boolean>(false);
    const [warning, setWarning] = useState<boolean>(false);
    const [warningText, setWarningText] = useState<string>('');
    const [warningTranslation, setWarningTranslation] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);
    const [draft, setDraft] = useState<boolean>(false);
    const [submit, setSubmit] = useState<boolean>(false);
    const [submitError, setSubmitError] = useState<boolean>(false);
    const [openFeeModal, setOpenFeeModal] = useState<boolean>(false);
    const [acceptFee, setAcceptFee] = useState<boolean>(false);
    const [onDraftSaved, setOnDraftSaved] = useState<boolean>(false);
    const [openSaveModal, setOpenSaveModal] = useState<boolean>(false);
    const [impersonationWarning, setImpersonationWarning] = useState<boolean>(false);
    const isImpersonation = useTypedSelector<boolean>(selectIsImpersonation);

    // page state of productsToOrder
    const [tableInformation, setTableInformation] = useState<OrderProductTableRow[]>(
        [] as OrderProductTableRow[]
    );
    const [editDraft, setEditDraft] = useState<boolean>(false);
    const backLink = editDraft
        ? `/make-it/${id}?from=configuration`
        : '/make-it?from=configuration';
    const dashboardLink = '/dashboard';
    const poNumberMaxLength = 25;

    const onCloseConfirmation = () => {
        setOpen(false);
    };

    const onSaveDraft = () => {
        setOpenSaveModal(true);
    };

    const onCancel = () => {
        setOpen(true);
    };

    const onCloseFeeModal = () => {
        setOpenFeeModal(false);
    };

    const onAcceptFee = () => {
        setAcceptFee(true);
    };

    const onCloseSaveModal = () => {
        setOpenSaveModal(false);
    };

    const onDraftSavedClose = () => {
        setOnDraftSaved(false);
    };

    const onConfirmedCancel = () => {
        dispatch(resetMakeItState());
        history.push(dashboardLink);
    };

    const handleSaveOrder = (orderName: string) => {
        setDraft(true);
        let order = { ...productionOrder } as ProductionOrder;
        order.name = orderName;
        order.customerProductionOrderId = poNumber;
        const orderLines = OrdersService.createMakeItOrderlines(tableInformation);
        dispatch(updateProductsToOrder(tableInformation));
        dispatch(saveDraftMakeItOrder(order, orderLines));
        setDraft(false);
    };

    const savePO = (value: string) => {
        setPONumber(value);
        let order = { ...productionOrder } as ProductionOrder;
        order.customerProductionOrderId = value;
        dispatch(saveSubmittedProductionOrder(order));
    };

    const handleBackNavigation = () => {
        savePO(poNumber);
        dispatch(updateProductsToOrder(tableInformation));
        history.push(backLink);
    };

    const handleSubmit = useCallback(
        () => {
            let productsBelowAbsoluteMinimum = getProductsBelowAbsoluteMinimum(tableInformation);

            dispatch(updateProductsToOrder(tableInformation));

            let order = { ...productionOrder } as ProductionOrder;

            if (poNumber.trim() !== '') {
                setPOError(false);
                if (poNumber && poNumber !== '') {
                    order.customerProductionOrderId = poNumber;
                }

                if (productsToOrder && validateMoqAmounts(tableInformation, acceptFee)) {
                    setSubmit(true);
                    setWarning(false);

                    let productionOrderId: number;
                    const orderLines = OrdersService.createMakeItOrderlines(tableInformation);
                    const invalidRequestedDates = orderLines.filter(
                        (line) => line.invalidRequestedDate
                    );
                    dispatch(updateOrderLineState(orderLines));

                    if (!isImpersonation) {
                        OrdersService.submitMakeItOrder(
                            order.customerProductionOrderId,
                            orderLines,
                            state
                        )
                            .then((response) => {
                                setSubmitError(false);
                                dispatch(saveSubmittedProductionOrder(response.data));
                                productionOrderId = response.data.productionOrderId;
                                history.push('/make-it-order-confirmation');
                            })
                            .catch((error) => {
                                setSubmitError(true);
                            })
                            .finally(() => {
                                if (
                                    userInfo &&
                                    selectedAccountId &&
                                    invalidRequestedDates.length > 0
                                ) {
                                    createAppInsightsError(
                                        userInfo.email,
                                        invalidRequestedDates,
                                        selectedAccountId,
                                        productionOrderId
                                    );
                                }
                                setSubmit(false);
                            });
                    } else {
                        setSubmit(false);
                        setImpersonationWarning(true);
                    }
                } else {
                    if (!acceptFee && productsBelowAbsoluteMinimum.length === 0) {
                        setOpenFeeModal(true);
                    } else {
                        const zeroOrderQuantiy = tableInformation.find(
                            (product) => !product.quantity || product.quantity < 1
                        );
                        if (zeroOrderQuantiy) {
                            setWarningTranslation('zeroOrderQuantity');
                            setWarningText(
                                'Order quantity cannot be 0. Please enter a quantity or remove the product from the order.'
                            );
                        } else {
                            setWarningTranslation('moqWarningMessage');
                            setWarningText(
                                'One or more quantities entered do not meet the minimum order quantity'
                            );
                        }
                        setWarning(true);
                    }
                }
            } else {
                setPOError(true);
            }
        },
        // state here

        [
            acceptFee,
            dispatch,
            history,
            isImpersonation,
            poNumber,
            productionOrder,
            productsToOrder,
            selectedAccountId,
            tableInformation,
            userInfo
        ]
    );

    const handleItemUpdates = (item: OrderProductTableRow) => {
        setWarning(false);
        const updatedTableInformation = tableInformation.filter(
            (i) => i.productSku !== item.productSku
        );
        setTableInformation([...updatedTableInformation, item]);
        dispatch(updateProductsToOrder([...updatedTableInformation, item]));
    };

    const handleRemoveItem = (item: OrderProductTableRow) => {
        if (tableInformation) {
            const updatedTableInformation: OrderProductTableRow[] = tableInformation.filter(
                (product) => product.productSku !== item.productSku
            );
            setTableInformation(updatedTableInformation);
            dispatch(updateProductList(false, false, item));
        }
    };

    const handleViewFilterClick = useCallback(
        (viewType: string[]) => {
            setCurrentViewTypes(viewType);
            if (productsToOrder) {
                if (viewType[0] === '') {
                    setFilteredViewProducts(productsToOrder);
                } else if (viewType[0] === 'CAMPAIGN') {
                    const updatedProducts = productsToOrder.filter(
                        (product) => product.campaignTiming
                    );
                    setFilteredViewProducts(updatedProducts);
                }
            }
        },
        [productsToOrder]
    );

    useEffect(() => {
        if (id) {
            setEditDraft(true);
        }
    }, [id]);

    useEffect(() => {
        if (productsToOrder) {
            setTableInformation(productsToOrder);
            handleViewFilterClick(currentViewTypes);
        }
    }, [productsToOrder, currentViewTypes, handleViewFilterClick]);

    useEffect(() => {
        if (productionOrder?.customerProductionOrderId) {
            setPONumber(productionOrder.customerProductionOrderId);
        }
    }, [productionOrder]);

    useEffect(() => {
        if (!error && openSaveModal) {
            onCloseSaveModal();
            setOnDraftSaved(true);
        }
    }, [error, openSaveModal]);

    // if user accepts the MOQ fee we want to submit their order
    useEffect(() => {
        if (acceptFee) {
            handleSubmit();
        }
    }, [acceptFee, handleSubmit]);

    const customerPoInputIsValid = useMemo(() => {
        return poNumber && customerPoIsValid(poNumber);
    }, [poNumber]);

    const showInvalidCharactersWarning = useMemo(() => {
        return (
            poNumber && poNumber.length > 0 && !poNumber.match(/^[a-zA-Z0-9\/_\-\:. ',#()+&;=]*$/)
        );
    }, [poNumber]);

    const footerActions = (
        <>
            <Grid container item xs={2} className={classes.btnWrapper}>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="cancel-btn"
                    onClick={onCancel}
                    className={classes.actionBtn}
                >
                    <Trans i18nKey="cancel">Cancel</Trans>
                </Button>
                {enableSaveDraft && (
                    <Button
                        type="submit"
                        variant="outlined"
                        data-testid="save-btn"
                        onClick={onSaveDraft}
                        className={classes.actionBtn}
                        disabled={draft}
                    >
                        <Trans i18nKey="saveForLater">Save For Later</Trans>
                    </Button>
                )}
            </Grid>
            <Grid container item xs={7}>
                <ImpersonationWarning showWarning={impersonationWarning} warningType={'SUBMIT'} />
            </Grid>
            <Grid container item xs={3} justify="flex-end" className={classes.btnWrapper}>
                <Button
                    type="button"
                    color="primary"
                    variant="outlined"
                    data-testid="back-btn"
                    onClick={handleBackNavigation}
                    className={classes.actionBtn}
                >
                    <Trans i18nKey="back">Back</Trans>
                </Button>
                <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    data-testid="submit-order-btn"
                    className={classes.actionBtn}
                    onClick={handleSubmit}
                    disabled={!productsToOrder?.length || submit || !customerPoInputIsValid}
                >
                    <Trans i18nKey="submitOrder">Submit Order</Trans>
                </Button>
            </Grid>
        </>
    );

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('makeIt', 'Make It'),
                description: t('setDatesQuantities', 'Set Dates & Quantities'),
                thinBanner: true,
                displayDropdown: true,
                disableSelect: true
            }}
            actionFooter={{
                footerAction: footerActions,
                justify: 'space-between',
                sticky: true
            }}
            activity={Activity.MakeIt}
            restrictToSecurityLevel={SecurityLevel.Edit}
        >
            <Grid container spacing={2} className={classes.dashboard}>
                <QuantityUnitSelectorProvider state={{ quantityUnit: quantityUnit }}>
                    <Grid container item xs={12} className={classes.main}>
                        <Grid container item xs={3}>
                            <Typography className={classes.fieldLabel}>
                                <Trans i18nKey="customerPoNumLong">Customer PO Number</Trans>
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            alignItems="center"
                            justify="space-between"
                            className={classes.content}
                        >
                            <Grid item xs={3}>
                                <TextField
                                    label=""
                                    onChange={(control) => setPONumber(control.target.value)}
                                    variant="standard"
                                    defaultValue={productionOrder?.customerProductionOrderId ?? ''}
                                    className={classes.fieldInput}
                                    inputProps={{
                                        maxLength: poNumberMaxLength,
                                        'data-testid': 'po-number-input'
                                    }}
                                    value={poNumber}
                                    error={!customerPoInputIsValid}
                                    helperText={
                                        showInvalidCharactersWarning && (
                                            <Trans i18nKey="poNumberWarning">
                                                Please enter a Customer PO Number with no special
                                                characters
                                            </Trans>
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={4} data-testid={'po-description'}>
                                <Typography className={classes.fieldHelper}>
                                    <Trans i18nKey="poNumberHelper">A PO Number is required.</Trans>{' '}
                                    <Trans i18nKey="poNumberMaxLengthHelper">
                                        PO Numbers must be 25 characters or less.
                                    </Trans>
                                </Typography>
                            </Grid>
                            <Grid item xs={2} />
                            <Grid container item xs={3} justify="flex-end">
                                <Button
                                    type="button"
                                    color="primary"
                                    variant="outlined"
                                    data-testid="add-products-btn"
                                    className={classes.actionBtn}
                                    onClick={handleBackNavigation}
                                >
                                    <Trans i18nKey="addProducts">Add Products</Trans>
                                </Button>
                            </Grid>
                        </Grid>
                        <QuantityUnitSelector
                            type={'MakeIt'}
                            quantityUnit={quantityUnit}
                            styles={classes.quantityUnitSelector}
                        />
                        <MakeItConfigGrid
                            items={filteredViewProducts ? filteredViewProducts : []}
                            onRemoveItem={handleRemoveItem}
                            onItemUpdates={handleItemUpdates}
                            onViewFiltersClick={handleViewFilterClick}
                            warning={warning}
                            warningMessage={warningText}
                            warningTranslation={warningTranslation}
                            poError={poError}
                        />
                    </Grid>
                </QuantityUnitSelectorProvider>
            </Grid>
            {(draft || submit) && (
                <Grid container item xs={12} alignItems="center" className={classes.actionBar}>
                    <Grid container item xs={12} className={classes.spinningLoader}>
                        <CircularProgress />
                    </Grid>
                </Grid>
            )}
            {submitError && (
                <Grid
                    container
                    item
                    xs={12}
                    alignItems="center"
                    justify="center"
                    className={classes.actionBar}
                >
                    <Typography className={classes.errorMessage} data-testid="po-number-error">
                        <Trans i18nKey="submitError">
                            There was an error saving your Make It order. Please try again later.
                        </Trans>
                    </Typography>
                </Grid>
            )}
            {productsToOrder && !productsToOrder.length && (
                <Grid
                    container
                    item
                    xs={12}
                    alignItems="center"
                    justify="center"
                    className={classes.actionBar}
                >
                    <Typography
                        className={classes.errorMessage}
                        data-testid="all-products-removed-message"
                    >
                        <Trans i18nKey="allProductsRemoved">
                            All products have been removed. Please add products to proceed.
                        </Trans>
                    </Typography>
                </Grid>
            )}
            <FeeModal
                products={tableInformation}
                open={openFeeModal}
                onClose={onCloseFeeModal}
                onAcceptFee={onAcceptFee}
            />
            <OrderCancelModal
                open={open}
                saveProgress={onConfirmedCancel}
                navigationLink={dashboardLink}
                onClose={onCloseConfirmation}
                onCancel={onCloseConfirmation}
                data-testid={'order-cancel-modal'}
            />
            <SaveModal
                open={openSaveModal}
                saveDraft={handleSaveOrder}
                onClose={onCloseSaveModal}
                orderName={productionOrder && productionOrder.name ? productionOrder.name : ''}
                saveInputLabel={<Trans i18nKey="draftName">Draft Name</Trans>}
                error={error}
            />
            <SaveConfirmedModal
                title={<Trans i18nKey="saveForLater">Save For Later</Trans>}
                open={onDraftSaved}
                onClose={onDraftSavedClose}
                closeModalBtn={true}
                closeModalBtnLabel={<Trans i18nKey="continueEditing">Continue Editing</Trans>}
                successMessage={
                    <Trans i18nKey="yourOrderHasBeenSaved">
                        Your order has been saved successfully!
                    </Trans>
                }
            />
        </ProcessingPageTemplate>
    );
};

export default MakeItConfiguration;
