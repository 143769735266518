import { Grid, makeStyles, Button, FormHelperText } from '@material-ui/core';
import React, { useState } from 'react';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { Trans, useTranslation } from 'react-i18next';
import { useForm, ErrorMessage } from 'react-hook-form';
import TextInput from '../../../reusable/molecules/TextInput';
import FormSection from '../../../reusable/molecules/FormSection';
import { medium, red } from '../../../../themes/globalConstants';
import AcceptChangesModal from '../../../reusable/molecules/AcceptChangesModal';
import { isValidEmail } from '../../../../utility/helpers/user-helpers';
import { AuthState } from '../../../../store/reducers/auth';

interface Props {
    handleVerifyUser: (userEmail: string) => void;
    handleEditClick: () => void;
    permissionsLoaded: boolean;
}

const useStyles = makeStyles((theme) => ({
    label: {
        color: theme.palette.info.dark,
        fontSize: medium
    },
    helperText: {
        '& .MuiFormHelperText-root': {
            marginLeft: 0
        }
    },
    errorText: {
        color: red
    },
    btnContainer: {
        margin: 'auto',
        height: '3em'
    },
    btn: {
        width: '15em'
    },
    confirmBtnText: {
        lineHeight: 1.4
    }
}));

const UserInformation = ({ handleVerifyUser, permissionsLoaded, handleEditClick }: Props) => {
    const { errors, control, watch } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur'
    });
    const classes = useStyles();
    const { i18n } = useTranslation();
    const [openAcceptChangesModal, setOpenAcceptChangesModal] = useState<boolean>(false);
    const { userInfo } = useTypedSelector<AuthState>((state) => state.auth);

    const isRequired = (
        <Trans i18nKey="required" i18n={i18n}>
            Required
        </Trans>
    );

    const onVerifyUser = () => {
        if (userEmail && !errors.email) {
            handleVerifyUser(userEmail);
        }
    };

    const handleEdit = () => {
        setOpenAcceptChangesModal(false);
        handleEditClick();
    };

    const openModal = () => {
        setOpenAcceptChangesModal(true);
    };

    const closeAcceptChangesModal = () => {
        setOpenAcceptChangesModal(false);
    };

    const userEmail: string = watch('email') || '';

    return (
        <>
            <FormSection
                testId={'user-information-form'}
                sectionHeader={<Trans i18nKey="userInformation">User Information</Trans>}
            >
                <Grid container item xs={12}>
                    <Grid item xs={6}>
                        <TextInput
                            type="text"
                            label={<Trans i18nKey="emailAddress">Email Address</Trans>}
                            name="email"
                            testId="user-email"
                            control={control}
                            rules={{
                                required: isRequired,
                                validate: {
                                    isValidEmail: (value) => {
                                        if (
                                            value.toLowerCase() === userInfo?.email.toLowerCase() ||
                                            value.toLowerCase() ===
                                                userInfo?.preferred_username.toLowerCase()
                                        )
                                            return (
                                                <Trans i18nKey="cannotAddSelf">
                                                    You cannot add yourself as a new user
                                                </Trans>
                                            );

                                        return (
                                            isValidEmail(value) || (
                                                <Trans i18nKey="incorrectEmailAddress">
                                                    Invalid Email Format
                                                </Trans>
                                            )
                                        );
                                    }
                                }
                            }}
                            error={errors.email}
                            defaultValue={userEmail}
                            styles={classes.helperText}
                            helperText={
                                <Trans i18nKey="newUserEmailHelper">Email@domain.com</Trans>
                            }
                        />
                        <FormHelperText className={classes.errorText}>
                            {errors.email && <ErrorMessage errors={errors} name={'email'} />}
                        </FormHelperText>
                    </Grid>
                </Grid>
                <Grid container item justify="flex-end" xs={12}>
                    {permissionsLoaded && (
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            data-testid="edit-btn"
                            onClick={openModal}
                            className={classes.btn}
                        >
                            <Trans i18nKey="edit">Edit</Trans>
                        </Button>
                    )}
                    {!permissionsLoaded && (
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            data-testid="confirm-btn"
                            onClick={onVerifyUser}
                            className={classes.btn}
                            disabled={userEmail.length === 0 || errors.email}
                        >
                            <Trans className={classes.confirmBtnText} i18nKey="confirmEmail">
                                Confirm Email
                            </Trans>
                        </Button>
                    )}
                </Grid>
            </FormSection>
            <AcceptChangesModal
                data-testid="userinfo-accept-changes"
                open={openAcceptChangesModal}
                handleYesClick={handleEdit}
                handleNoClick={closeAcceptChangesModal}
                handleClose={closeAcceptChangesModal}
            />
        </>
    );
};
export default UserInformation;
