import { SET_AUTH, SET_AUTH_TOKEN, SET_PERMISSIONS } from '../actions/action-types';
import { parseClaims } from '../../utility/auth/auth-utilities';
import { hasOnlyJoiningPersonas, Persona } from '../../utility/auth/useSecurity';

export interface Permissions {
    permissions: UserPermission[];
    isActive?: boolean;
}

export interface UserPermission {
    personaId: Persona;
    accountIds: string[];
}
export interface UserPermissionDto {
    userName: string;
    persona: Persona;
    accountId: string;
    status: 1 | 0;
}

export interface AuthState {
    hasAuthenticated?: boolean;
    authenticated: boolean;
    userInfo: UserInfo | null;
    accessToken: string;
    isEmployee: boolean | undefined;
    isCustomer: boolean | undefined;
    permissions: UserPermission[] | null;
}

export const initialState: AuthState = {
    hasAuthenticated: false,
    authenticated: false,
    userInfo: null,
    accessToken: '',
    isEmployee: undefined,
    isCustomer: undefined,
    permissions: null
};

const auth = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_AUTH: {
            const { isEmployee } = parseClaims(action.accessToken);
            return {
                ...state,
                hasAuthenticated: action.authenticated ? true : state.hasAuthenticated,
                authenticated: action.authenticated,
                userInfo: action.userInfo,
                accessToken: action.accessToken,
                isEmployee
            };
        }
        case SET_AUTH_TOKEN: {
            const { isEmployee } = parseClaims(action.accessToken);
            return {
                ...state,
                accessToken: action.accessToken,
                isEmployee
            };
        }
        case SET_PERMISSIONS: {
            let isCustomer = !!action.permissions.length;
            if (!!action.permissions.length) {
                isCustomer = !hasOnlyJoiningPersonas(action.permissions);
            }
            return {
                ...state,
                isCustomer,
                permissions: action.permissions
            };
        }

        default:
            return state;
    }
};

export default auth;
