import {
    MAKEIT_CONVERSION_ORDER_ADD_PRODUCT,
    MAKEIT_CONVERSION_ORDER_COPY_FORECAST,
    MAKEIT_CONVERSION_ORDER_DELETE_PRODUCT,
    MAKEIT_CONVERSION_ORDER_EXISTING_ORDERS,
    MAKEIT_CONVERSION_ORDER_LOADED,
    MAKEIT_CONVERSION_ORDER_LOADING,
    MAKEIT_CONVERSION_ORDER_LOADING_ERROR,
    MAKEIT_CONVERSION_ORDER_LOAD_ALLOCATIONS,
    MAKEIT_CONVERSION_ORDER_LOAD_ALLOCATIONS_ERROR,
    MAKEIT_CONVERSION_ORDER_LOAD_ALLOCATIONS_LOADING,
    MAKEIT_CONVERSION_ORDER_PRODUCTS_ERROR,
    MAKEIT_CONVERSION_ORDER_PRODUCTS_LOADED,
    MAKEIT_CONVERSION_ORDER_PRODUCTS_LOADING,
    MAKEIT_CONVERSION_ORDER_UPDATE_PALLET_QUANTITY,
    MAKEIT_CONVERSION_RESET_STATE,
    MAKEIT_CONVERSION_SUBMIT_ERROR,
    MAKEIT_CONVERSION_SUBMIT_LOADING,
    MAKEIT_CONVERSION_SUBMIT_SUCCESS,
    MAKEIT_CONVERSION_UPDATE_ORDER_BY_SNO_SKU
} from '../actions/action-types';
import {
    MakeItBulkATMOrder,
    MakeItBulkATMOrderByWeekAndSku,
    SnoSkuAllocationsByWeek
} from './makeit-bulk-atm';
import { MakeItBulkLineItem } from './makeit-bulk-upload';

export interface MakeItConversionATMState {
    copyFromProductionOrderIds?: number[];
    copyFromShipToId?: number;
    orderLoading: boolean;
    orderLoaded: boolean;
    error?: any;
    atmOrders?: MakeItBulkATMOrder[];
    existingOrders?: MakeItBulkATMOrder[];
    existingDrafts?: MakeItBulkATMOrder[];
    products?: MakeItBulkLineItem[];
    ordersBySnoSku?: MakeItBulkATMOrderByWeekAndSku[];
    allocationsLoadingState?: 'idle' | 'loading' | 'error' | 'success';
    productsLoadingState?: 'idle' | 'loading' | 'error' | 'success';
    allocations?: SnoSkuAllocationsByWeek[];
    isEdit?: boolean;
    submitLoadingState?: 'idle' | 'loading' | 'error' | 'success';
}

const initialState: MakeItConversionATMState = {
    copyFromProductionOrderIds: undefined,
    copyFromShipToId: undefined,
    orderLoading: false,
    orderLoaded: false,
    atmOrders: undefined,
    existingOrders: undefined,
    existingDrafts: undefined,
    products: undefined,
    allocations: undefined,
    allocationsLoadingState: 'idle',
    productsLoadingState: 'idle',
    isEdit: false,
    submitLoadingState: 'idle',
    ordersBySnoSku: undefined
};

const makeItConversionATMState = (
    state: MakeItConversionATMState = initialState,
    action: { type: string; [x: string]: any }
): MakeItConversionATMState => {
    switch (action.type) {
        case MAKEIT_CONVERSION_ORDER_COPY_FORECAST:
            return {
                ...state,
                copyFromProductionOrderIds: action.copyFromProductionOrderIds,
                copyFromShipToId: action.copyFromShipToId
            };
        case MAKEIT_CONVERSION_ORDER_LOADING:
            return { ...state, orderLoaded: false, orderLoading: true, error: undefined };
        case MAKEIT_CONVERSION_ORDER_LOADED:
            return { ...state, atmOrders: action.atmOrders };
        case MAKEIT_CONVERSION_ORDER_EXISTING_ORDERS:
            return {
                ...state,
                existingOrders: action.existingOrders,
                existingDrafts: action.existingDrafts,
                orderLoaded: true
            };
        case MAKEIT_CONVERSION_ORDER_LOADING_ERROR:
            return { ...state };
        case MAKEIT_CONVERSION_ORDER_LOAD_ALLOCATIONS_LOADING:
            return {
                ...state,
                allocationsLoadingState: 'loading'
            };
        case MAKEIT_CONVERSION_ORDER_LOAD_ALLOCATIONS:
            return {
                ...state,
                allocationsLoadingState: 'success',
                allocations: action.allocations
            };
        case MAKEIT_CONVERSION_ORDER_LOAD_ALLOCATIONS_ERROR:
            return {
                ...state,
                allocationsLoadingState: 'error',
                error: action.error
            };
        case MAKEIT_CONVERSION_ORDER_PRODUCTS_LOADING:
            return {
                ...state,
                productsLoadingState: 'loading'
            };
        case MAKEIT_CONVERSION_ORDER_PRODUCTS_LOADED:
            return {
                ...state,
                productsLoadingState: 'success',
                products: action.products
            };
        case MAKEIT_CONVERSION_ORDER_PRODUCTS_ERROR:
            return {
                ...state,
                productsLoadingState: 'error',
                error: action.error
            };
        case MAKEIT_CONVERSION_ORDER_UPDATE_PALLET_QUANTITY:
            return {
                ...state,
                atmOrders: action.atmOrders
            };
        case MAKEIT_CONVERSION_ORDER_DELETE_PRODUCT:
            return {
                ...state,
                atmOrders: action.atmOrders
            };
        case MAKEIT_CONVERSION_ORDER_ADD_PRODUCT:
            return {
                ...state,
                atmOrders: action.atmOrders
            };
        case MAKEIT_CONVERSION_RESET_STATE:
            return {
                ...state,
                copyFromProductionOrderIds: undefined,
                copyFromShipToId: undefined,
                orderLoading: false,
                orderLoaded: false,
                atmOrders: undefined,
                existingOrders: undefined,
                existingDrafts: undefined,
                products: undefined,
                allocations: undefined,
                allocationsLoadingState: 'idle',
                productsLoadingState: 'idle',
                isEdit: false,
                submitLoadingState: 'idle',
                error: undefined
            };
        case MAKEIT_CONVERSION_SUBMIT_ERROR:
            return {
                ...state,
                error: action.error,
                submitLoadingState: 'error'
            };
        case MAKEIT_CONVERSION_SUBMIT_LOADING:
            return {
                ...state,
                submitLoadingState: 'loading'
            };
        case MAKEIT_CONVERSION_SUBMIT_SUCCESS:
            return {
                ...state,
                submitLoadingState: 'success'
            };
        case MAKEIT_CONVERSION_UPDATE_ORDER_BY_SNO_SKU:
            return {
                ...state,
                ordersBySnoSku: action.ordersBySnoSku
            };
        default:
            return state;
    }
};

export default makeItConversionATMState;
