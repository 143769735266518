import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import ShipItSummaryTabs from './components/ShipItSummaryTabs';
import { Activity } from '../../../utility/auth/useSecurity';
import { useTranslation } from 'react-i18next';

const ShipItSummary = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const { t } = useTranslation();

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('shipIt', 'Ship It'),
                description: t('deliveryOrderSummary', 'Delivery Order Summary').toUpperCase(),
                thinBanner: true,
                displayDropdown: true
            }}
            activity={Activity.NewOpenDeliveryOrders}
            loading={loading}
        >
            <Grid container>
                <ShipItSummaryTabs setDashboardLoading={setLoading} dashboardLoading={loading} />
            </Grid>
        </ProcessingPageTemplate>
    );
};

export default ShipItSummary;
