import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { makeStyles, Box, Typography, Tabs, Tab, Grid } from '@material-ui/core';
import {
    small,
    blackWeight,
    inactiveTabBlue,
    lato,
    xl,
    black,
    medium,
    white,
    ballDrkBlue,
    addFormItemButtonBlue,
    ltBlueGrey
} from '../../../../themes/globalConstants';
import { useQuery } from '../../../../utility/helpers/query-helpers';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import TranslationService from '../../../../utility/services/translation-service';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { TabPanelProps } from '../../../../utility/helpers/summary-helpers';
import { ManageProductsState } from '../../../../store/reducers/manage-products';
import ManageProductsTab from './ManageProductsTab';
import ManageProductsShareLinks from './ManageProductsShareLinks';
import { ManageProductsProvider } from '../context/ManageProductsContext';
import UnrecognizedProductsTab from './UnrecognizedProductsTab';
import { FlagTypes, GetFeatureFlag } from '../../../../utility/helpers/feature-flag';
interface Props {
    setDashboardLoading: Dispatch<SetStateAction<boolean>>;
    dashboardLoading: boolean;
    lockPeriodWeeks: number;
    toggleFooterActions: (show: boolean) => void;
}

export enum TabStatus {
    'active' = 0,
    'inactive' = 1,
    'unrecognized' = 2
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: '4.5em',
        position: 'relative',
        '& h2': {
            fontSize: xl,
            fontFamily: lato,
            fontWeight: blackWeight,
            color: theme.palette.secondary.main
        }
    },
    tabPanel: {
        width: '100%',
        boxShadow: '0px 0px 6px rgba(185, 211, 220, 0.8)',
        backgroundColor: white
    },
    counter: {
        width: '2em',
        height: '2em',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '0.5em',
        fontSize: small,
        fontWeight: blackWeight,
        backgroundColor: ltBlueGrey,
        color: ballDrkBlue
    },
    selectedCounter: {
        backgroundColor: addFormItemButtonBlue,
        color: white
    },
    tabs: {},
    tabLabel: {
        backgroundColor: inactiveTabBlue,
        height: '56px',
        borderTopLeftRadius: '0.625em',
        borderTopRightRadius: '0.625em',
        '&:not(:first-of-type)': {
            marginLeft: '8px'
        },
        padding: '0 3em',
        color: ballDrkBlue,
        textTransform: 'none'
    },
    tabLabelText: {
        fontSize: medium,
        fontWeight: blackWeight
    },
    selectedTab: {
        backgroundColor: white,
        color: black,
        boxShadow: '0px 0px 6px rgba(185, 211, 220, 0.8), inset 0px -3px 0px #009BBB'
    }
}));

const TabPanel = (props: TabPanelProps) => {
    const classes = useStyles();
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`manage-products-tabpanel-${index}`}
            aria-labelledby={`manage-products-tab-${index}`}
            {...other}
            className={classes.tabPanel}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
};

//This assigns props for accessibility purposes
const a11yProps = (index: any) => {
    return {
        id: `manage-products-tab-${index}`,
        'aria-controls': `manage-products-tabpanel-${index}`
    };
};

const ManageProductsTabs = ({
    setDashboardLoading,
    lockPeriodWeeks,
    toggleFooterActions
}: Props) => {
    const classes = useStyles();
    const query = useQuery();
    const status = query.get('status');
    const initialValue = getValueFromStatus(status);
    const [value, setValue] = useState<number>(initialValue);
    const { error, activeProducts, inactiveProducts, unrecognizedProducts } =
        useTypedSelector<ManageProductsState>((state) => state.manageProductsState);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const unrecognizedProductsTabFeatureFlag = GetFeatureFlag(FlagTypes.UnrecognizedProductsTab);

    // Check to see if the page content is loaded
    useEffect(() => {
        // set data
        setDashboardLoading(false);
    }, [error, setDashboardLoading]);

    // Handle tab selection change
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        const val = TabStatus[newValue];
        // Update query param so back button will return to same tab selection
        window.history.replaceState('', '', `?status=${val}`);
        setValue(newValue);
    };

    function getValueFromStatus(status) {
        return (TabStatus as any)[status?.toLowerCase() || ''] || 0;
    }

    useEffect(() => {
        const value = getValueFromStatus(status);
        setValue(value);
    }, [status]);

    useEffect(() => {
        if (value) {
            toggleFooterActions(value === 2);
        }
    }, [value]);

    // Generate Tab Label
    const TabLabel = (label: string, count: number, index: number) => {
        return (
            <Grid container justify="center" alignItems="center">
                <Typography component="span" className={classes.tabLabelText}>
                    {label}
                </Typography>
                <Typography
                    component="span"
                    className={`${classes.counter} ${value === index && classes.selectedCounter}`}
                >
                    {count}
                </Typography>
            </Grid>
        );
    };

    return (
        <div className={classes.root} data-testid="manage-products-tabs">
            <ManageProductsProvider
                value={{
                    selectedTab: value === 0 ? 'Active' : value === 1 ? 'Inactive' : 'Unrecognized'
                }}
            >
                <ManageProductsShareLinks lockPeriodWeeks={lockPeriodWeeks} tabStatus={value} />
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="manage products tabs"
                    className={classes.tabs}
                    scrollButtons="auto"
                    variant="scrollable"
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab
                        label={TabLabel(
                            TranslationService.getTranslatedText(cultureCode, 'activeProducts'),
                            activeProducts ? activeProducts.length : 0,
                            0
                        )}
                        {...a11yProps(0)}
                        className={`${classes.tabLabel} ${value === 0 && classes.selectedTab}`}
                        value={0}
                    />
                    <Tab
                        label={TabLabel(
                            TranslationService.getTranslatedText(cultureCode, 'inactiveProducts'),
                            inactiveProducts ? inactiveProducts.length : 0,
                            1
                        )}
                        {...a11yProps(1)}
                        className={`${classes.tabLabel} ${value === 1 && classes.selectedTab}`}
                        value={1}
                    />
                    {unrecognizedProductsTabFeatureFlag && (
                        <Tab
                            label={TabLabel(
                                TranslationService.getTranslatedText(
                                    cultureCode,
                                    'unrecognizedProducts'
                                ),
                                unrecognizedProducts ? unrecognizedProducts.length : 0,
                                2
                            )}
                            {...a11yProps(2)}
                            className={`${classes.tabLabel} ${value === 2 && classes.selectedTab}`}
                            value={2}
                        />
                    )}
                </Tabs>

                <TabPanel value={value} index={0} key="0">
                    {!!activeProducts && (
                        <ManageProductsTab
                            products={activeProducts}
                            lockPeriodWeeks={lockPeriodWeeks}
                            tab="Active"
                        />
                    )}
                </TabPanel>
                <TabPanel value={value} index={1} key="1">
                    {!!inactiveProducts && (
                        <ManageProductsTab
                            products={inactiveProducts}
                            lockPeriodWeeks={lockPeriodWeeks}
                            tab="Inactive"
                        />
                    )}
                </TabPanel>
                {unrecognizedProductsTabFeatureFlag && (
                    <TabPanel value={value} index={2} key="2">
                        {!!unrecognizedProducts && (
                            <UnrecognizedProductsTab
                                products={unrecognizedProducts}
                                tab="Unrecognized"
                            />
                        )}
                    </TabPanel>
                )}
            </ManageProductsProvider>
        </div>
    );
};

export default ManageProductsTabs;
