import React from 'react';
import { DeliveryOrder } from '../../../../store/reducers/shipping-dashboard';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Trans } from 'react-i18next';
import { ballGray, medium, small } from '../../../../themes/globalConstants';
import { formatFromDatetime } from '../../../../utility/helpers/date-helpers';
import { formatNumberWithLocale } from '../../../../utility/helpers/formatters';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';

interface Props {
    order?: DeliveryOrder;
    shipToName: string;
    shipToAddress: string;
    shipToId: string;
}

const useStyles = makeStyles((theme) => ({
    subtitle: {
        color: ballGray
    },
    item: {
        paddingLeft: '1em'
    },
    caps: {
        textTransform: 'uppercase'
    },
    shipToText: {
        fontSize: medium,
        letterSpacing: '0.2'
    },
    eaches: {
        fontSize: small
    }
}));

const DeliverySubheader = ({ order, shipToAddress, shipToName, shipToId }: Props) => {
    const classes = useStyles();
    const date = formatFromDatetime(order?.submittedDate as string);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    return (
        <Grid container>
            <Grid container item xs={7} justify="flex-start">
                <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="shipTo">Ship To</Trans>
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography className="classes.shipToText" data-testid="ship-to-name">
                        {shipToName}
                    </Typography>
                    <Typography className="classes.shipToText" data-testid="ship-to-address">
                        {shipToAddress}
                    </Typography>
                    <Typography className="classes.shipToText" data-testid="ship-to-id">
                        #{shipToId}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item xs={5} justify="flex-end">
                <Grid item xs={2} className={classes.item}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="status">Status</Trans>
                    </Typography>
                    <Typography variant="body1" data-testid="status">
                        {order?.status}
                    </Typography>
                </Grid>
                <Grid item xs={3} className={classes.item}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="date">Date</Trans>
                    </Typography>
                    <Typography variant="body1" data-testid="date">
                        {date}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="quantity">Quantity</Trans>
                    </Typography>
                    <Typography variant="body1" data-testid="quantity-pallets">
                        {order?.totalOrderQuantity + ' PL'}
                    </Typography>
                    <Typography
                        variant="body1"
                        data-testid="quantity-eaches"
                        className={classes.eaches}
                    >
                        {order?.totalOrderQuantityEaches &&
                            formatNumberWithLocale(cultureCode, order.totalOrderQuantityEaches) +
                                ' ea.'}
                    </Typography>
                </Grid>
                <Grid item xs={2} className={classes.item}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="products">Products</Trans>
                    </Typography>
                    <Typography variant="body1" data-testid="skus">
                        {order?.totalOrderSkus}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DeliverySubheader;
