import { salesAndUseTaxSellers } from '../../components/pages/SalesAndUseTax/utils/sales-and-use-tax-utilities';
import { Persona } from '../../utility/auth/useSecurity';
import { ErrorStatus } from '../../utility/helpers/axios-helpers';
import { formatDate } from '../../utility/helpers/date-helpers';
import { convertCapacityCheckProductCodeNCA } from '../../utility/helpers/product-helpers';
import CapacityCheckService, {
    CapacityCheckStatus
} from '../../utility/services/capacity-check-service';
import OnboardingService from '../../utility/services/onboarding-service';
import JoiningService, {
    TaxApplication,
    CertificateType,
    OnboardingStepState,
    OnboardingStepStatus,
    Invitations
} from '../../utility/services/onboarding-service';
import ProductService, { ProductSearchResponse } from '../../utility/services/product-service';
import { UserPermission } from '../reducers/auth';
import { CapacityCheck } from '../reducers/capacity-checks';
import {
    ONBOARDING_SALESANDUSETAX_BACKAFTERSAVE,
    ONBOARDING_SALESANDUSETAX_CANCEL,
    ONBOARDING_SALESANDUSETAX_CONTINUEEDIT,
    ONBOARDING_SALESANDUSETAX_SAVEPROGRESS_ERROR,
    ONBOARDING_SALESANDUSETAX_SAVEPROGRESS_LOADING,
    ONBOARDING_SALESANDUSETAX_SAVEPROGRESS_SUCCESS,
    ONBOARDING_SALESANDUSETAX_SUBMIT_ERROR,
    ONBOARDING_SALESANDUSETAX_SUBMIT_LOADING,
    ONBOARDING_SALESANDUSETAX_SUBMIT_SUCCESS,
    ONBOARDING_ACCOUNTSTATUS_RESET,
    ONBOARDING_CREDITAPP_LOADING,
    ONBOARDING_ACCOUNTSTATUS_LOADING,
    ONBOARDING_ACCOUNTSTATUS_ERROR,
    ONBOARDING_ACCOUNTSTATUS_SUCCESS,
    ONBOARDING_SALESANDUSETAX_FETCH_LOADING,
    ONBOARDING_SALESANDUSETAX_FETCH_ERROR,
    ONBOARDING_SALESANDUSETAX_FETCH_SUCCESS,
    ONBOARDING_CREDITAPP_DRAFT_LOADED,
    ONBOARDING_CREDITAPP_ERROR,
    ONBOARDING_BILLINGANDSHIPPING_ERROR,
    ONBOARDING_BILLINGANDSHIPPING_LOADING,
    ONBOARDING_BILLINGANDSHIPPING_DRAFT_LOADED,
    ONBOARDING_INVITATION_SUCCESS,
    ONBOARDING_INVITATION_RESET,
    CAPACITY_CHECKS_LOADING,
    CAPACITY_CHECKS_LOADED,
    ONBOARDING_LOAD_CAPACITY_CHECKS_ERROR
} from './action-types';
import { productPortfolioUpdateResults } from './product-portfolio';

const getSalesAndUseTaxSubmitSuccess = (response) => {
    return (dispatch) => {
        dispatch({
            type: ONBOARDING_SALESANDUSETAX_SUBMIT_SUCCESS,
            salesAndUseTaxForm: response
        });
    };
};

const getSalesAndUseTaxSubmitLoading = () => {
    return (dispatch) => {
        dispatch({
            type: ONBOARDING_SALESANDUSETAX_SUBMIT_LOADING
        });
    };
};

const getSalesAndUseTaxSubmitError = (error) => {
    return (dispatch) => {
        dispatch({
            type: ONBOARDING_SALESANDUSETAX_SUBMIT_ERROR,
            error: error
        });
    };
};

const getSalesAndUseTaxSaveProgressSuccess = () => {
    return (dispatch) => {
        dispatch({
            type: ONBOARDING_SALESANDUSETAX_SAVEPROGRESS_SUCCESS
        });
    };
};

const getSalesAndUseTaxSaveProgressLoading = () => {
    return (dispatch) => {
        dispatch({
            type: ONBOARDING_SALESANDUSETAX_SAVEPROGRESS_LOADING
        });
    };
};

const getSalesAndUseTaxSaveProgressError = (error) => {
    return (dispatch) => {
        dispatch({
            type: ONBOARDING_SALESANDUSETAX_SAVEPROGRESS_ERROR,
            error: error
        });
    };
};

export const submitSalesAndUseTax = (form: any) => {
    getSalesAndUseTaxSubmitLoading();
    return (dispatch, getState) => {
        const state = getState();
        const regionCode = state.auth.userInfo.Custom_Region;

        const payload: TaxApplication = getSalesAndUseTaxApplicationFromForm(state, form, false);
        OnboardingService.createSalesAndUseTax(payload, state.auth.accessToken, regionCode)
            .then((response) => {
                dispatch(getSalesAndUseTaxSubmitSuccess(response.data));
            })
            .catch((error) => {
                dispatch(getSalesAndUseTaxSubmitError(error));
            });
    };
};

export const resetAccountStatus = () => {
    return (dispatch) => {
        dispatch({ type: ONBOARDING_ACCOUNTSTATUS_RESET });
    };
};

export const saveProgressSalesAndUseTax = (form: any) => {
    return (dispatch, getState) => {
        dispatch(getSalesAndUseTaxSaveProgressLoading());
        const state = getState();
        const regionCode = state.auth.userInfo.Custom_Region;
        const formId = state.onboardingDashboardState.salesAndUseTax.salesAndUseTaxForm?.id;

        let payload: TaxApplication = getSalesAndUseTaxApplicationFromForm(state, form, true);

        const isUpdate = state.onboardingDashboardState.salesAndUseTax.salesAndUseTaxForm && formId;

        if (isUpdate) {
            payload = {
                ...payload,
                id: formId
            };
        }

        const saveProgress = isUpdate
            ? JoiningService.updateSalesAndUseTax
            : JoiningService.createSalesAndUseTax;

        saveProgress(payload, state.auth.accessToken, regionCode)
            .then(() => {
                dispatch(getSalesAndUseTaxSaveProgressSuccess());
            })
            .catch((err) => dispatch(getSalesAndUseTaxSaveProgressError(err)));
    };
};

export const cancelSalesAndUseTax = () => {
    return (dispatch) => {
        dispatch({
            type: ONBOARDING_SALESANDUSETAX_CANCEL
        });
    };
};
export const backAfterSaveSalesAndUseTax = () => {
    return (dispatch) => {
        dispatch({
            type: ONBOARDING_SALESANDUSETAX_BACKAFTERSAVE
        });
    };
};
export const continueEditingSalesAndUseTax = () => {
    return (dispatch) => {
        dispatch({
            type: ONBOARDING_SALESANDUSETAX_CONTINUEEDIT
        });
    };
};

export const getOnboardingAccountStatusLoading = () => {
    return (dispatch) => {
        dispatch({
            type: ONBOARDING_ACCOUNTSTATUS_LOADING
        });
    };
};

export const getOnboardingAccountStatusError = (error) => {
    return (dispatch) => {
        dispatch({
            type: ONBOARDING_ACCOUNTSTATUS_ERROR,
            error
        });
    };
};

export const getOnboardingAccountStatusSuccess = (response) => {
    return (dispatch) => {
        dispatch({
            type: ONBOARDING_ACCOUNTSTATUS_SUCCESS,
            accountStatus: response
        });
    };
};

const getSalesAndUseTaxFetchLoading = () => {
    return (dispatch) => {
        dispatch({
            type: ONBOARDING_SALESANDUSETAX_FETCH_LOADING
        });
    };
};

const getSalesAndUseTaxFetchError = (error) => {
    return (dispatch) => {
        dispatch({
            type: ONBOARDING_SALESANDUSETAX_FETCH_ERROR,
            error
        });
    };
};

const getSalesAndUseTaxFetchSuccess = (response) => {
    return (dispatch) => {
        dispatch({
            type: ONBOARDING_SALESANDUSETAX_FETCH_SUCCESS,
            salesAndUseTaxForm: response
        });
    };
};

export const fetchAccountStatus = () => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(getOnboardingAccountStatusLoading());
        const joiningAccountId = getJoiningAccount(state.auth.permissions);

        JoiningService.getOnboardingAccountStatus(state.auth.accessToken, joiningAccountId)
            .then((resp) => {
                if (resp.status === ErrorStatus.NoContent) {
                    dispatch(
                        getOnboardingAccountStatusSuccess({
                            accountId: joiningAccountId,
                            addressesStatus: OnboardingStepStatus.INCOMPLETE,
                            taxAppStatus: OnboardingStepStatus.INCOMPLETE,
                            creditAppStatus: OnboardingStepStatus.INCOMPLETE,
                            termsStatus: OnboardingStepStatus.INCOMPLETE,
                            joiningStatus: OnboardingStepStatus.INCOMPLETE,
                            eightStepStatus: OnboardingStepStatus.INCOMPLETE
                        })
                    );
                } else {
                    dispatch(getOnboardingAccountStatusSuccess(resp.data));
                }
            })
            .catch((err) => {
                dispatch(getOnboardingAccountStatusError(err));
            });
    };
};

export const fetchSalesAndUseTaxApp = () => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(getSalesAndUseTaxFetchLoading());

        JoiningService.getSalesAndUseTax(
            state.onboardingDashboardState.accountStatus.accountId,
            state.auth.userInfo.email,
            state.auth.accessToken
        )
            .then((resp) => {
                if (resp.status === ErrorStatus.NoContent) {
                    dispatch(getSalesAndUseTaxFetchSuccess(undefined));
                } else {
                    dispatch(getSalesAndUseTaxFetchSuccess(resp.data));
                }
            })
            .catch((err) => {
                dispatch(getSalesAndUseTaxFetchError(err));
            });
    };
};

const getBillingAndShippingFormLoading = () => {
    return (dispatch) => {
        dispatch({
            type: ONBOARDING_BILLINGANDSHIPPING_LOADING
        });
    };
};

const getBillingAndShippingFormError = (error) => {
    return (dispatch) => {
        dispatch({
            type: ONBOARDING_BILLINGANDSHIPPING_ERROR,
            error
        });
    };
};

const getBillingAndShippingFormSuccess = (response) => {
    return (dispatch) => {
        dispatch({
            type: ONBOARDING_BILLINGANDSHIPPING_DRAFT_LOADED,
            billingAndShippingForm: response
        });
    };
};

export const fetchBillingAndShippingForm = () => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(getBillingAndShippingFormLoading());

        JoiningService.getBillingAndShippingForm(
            state.onboardingDashboardState.accountStatus.accountId,
            state.auth.accessToken
        )
            .then((resp) => {
                if (resp.status === ErrorStatus.NoContent) {
                    dispatch(getBillingAndShippingFormSuccess(undefined));
                } else {
                    dispatch(getBillingAndShippingFormSuccess(resp.data));
                }
            })
            .catch((err) => {
                dispatch(getBillingAndShippingFormError(err));
            });
    };
};

export const fetchCreditApplication = () => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({
            type: ONBOARDING_CREDITAPP_LOADING
        });

        JoiningService.getCreditApplication(
            state.onboardingDashboardState.accountStatus.accountId,
            state.auth.accessToken
        )
            .then((response) => {
                if (response.status === ErrorStatus.NoContent) {
                    dispatch({
                        type: ONBOARDING_CREDITAPP_DRAFT_LOADED,
                        creditApplicationForm: undefined
                    });
                } else {
                    dispatch({
                        type: ONBOARDING_CREDITAPP_DRAFT_LOADED,
                        creditApplicationForm: response.data
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: ONBOARDING_CREDITAPP_ERROR,
                    error
                });
            });
    };
};

export const submitInvitationRequest = (request: Invitations) => {
    return (dispatch, getState) => {
        const state = getState();
        JoiningService.createPermissionInvitation(
            request,
            state.auth.accessToken,
            state.regionCulture.regionCode
        )
            .then(() => {
                dispatch({
                    type: ONBOARDING_INVITATION_SUCCESS
                });
            })
            .catch((error) => {
                dispatch({
                    type: ONBOARDING_CREDITAPP_ERROR,
                    error
                });
            })
            .finally(() => {
                dispatch({
                    type: ONBOARDING_INVITATION_RESET
                });
            });
    };
};

export const loadCompletedCapacityChecks = () => {
    return (dispatch, getState) => {
        dispatch({
            type: CAPACITY_CHECKS_LOADING
        });

        let productResponse: ProductSearchResponse;
        const productRequest = { types: [] };

        ProductService.getMultipleProducts(getState, productRequest)
            .then((products) => {
                productResponse = products.data;
                return ProductService.getProductContent(productResponse, getState);
            })
            .then((response) => {
                const products = response;
                dispatch(productPortfolioUpdateResults(products));
                return CapacityCheckService.getCapacityChecks(getState().auth.accessToken);
            })
            .then((response) => {
                let formattedCapacityChecks: CapacityCheck[] = [];
                if (response.data.capacityChecks) {
                    formattedCapacityChecks = response.data.capacityChecks
                        .filter(
                            (capacityCheck) => capacityCheck.status === CapacityCheckStatus.Complete
                        )
                        .map((capacityCheck) => {
                            if (capacityCheck.endSize) {
                                return capacityCheck;
                            } else {
                                capacityCheck.productCode = convertCapacityCheckProductCodeNCA(
                                    capacityCheck.productName
                                );

                                return capacityCheck;
                            }
                        });
                }
                dispatch({
                    type: CAPACITY_CHECKS_LOADED,
                    capacityChecks: formattedCapacityChecks
                });
            })
            .catch((error) => {
                dispatch({
                    type: ONBOARDING_LOAD_CAPACITY_CHECKS_ERROR,
                    error
                });
            });
    };
};

const resolveCertificateType = (formValue: string) => {
    switch (formValue) {
        case 'blanketCertificate':
            return CertificateType.BLANKET;
        case 'singlePurchaseCertificate':
            return CertificateType.SINGLE;
        default:
            return CertificateType.UNKNOWN;
    }
};

const getStateRegistrationsDraft = (form: any) => {
    let stateArrayCounter = 0;
    let state = form[`stateArray.${stateArrayCounter}.state`];
    let registrationId = form[`stateArray.${stateArrayCounter}.registrationNumber`];
    const stateRegistrations: Array<{ state: string; registrationId: string }> = [];
    while (state || registrationId) {
        stateRegistrations.push({
            state: state && state.value ? state.value : '',
            registrationId: registrationId
        });

        stateArrayCounter++;
        state = form[`stateArray.${stateArrayCounter}.state`];
        registrationId = form[`stateArray.${stateArrayCounter}.registrationNumber`];
    }

    return stateRegistrations;
};

const getStateRegistrationsSubmit = (stateArray: any[]) => {
    return stateArray.map((reg) => {
        return { state: reg.state.name, registrationId: reg.registrationNumber };
    });
};

const getIssuedToSellerArray = (taxApp): string[] => {
    return salesAndUseTaxSellers.filter((seller) => taxApp[seller.id]).map((seller) => seller.name);
};

const getSalesAndUseTaxApplicationFromForm = (state: any, form: any, isDraft: boolean) => {
    const accountId = getJoiningAccount(state.auth.permissions);
    const payload: TaxApplication = {
        accountId: accountId,
        state: isDraft ? OnboardingStepState.DRAFT : OnboardingStepState.COMPLETE,
        issuedToSeller: getIssuedToSellerArray(form),
        certificateType: resolveCertificateType(form.certificateType),
        purchaseOrderNumber:
            resolveCertificateType(form.certificateType) === CertificateType.SINGLE
                ? form.purchaseOrderNumber
                : '',
        buyerInformation: {
            address: {
                name: form.nameOfFirm,
                country: 'US',
                addressLine1: form.buyerAddressLine1,
                addressLine2: form.buyerAddressLine2,
                addressLine3: form.buyerAddressLine3,
                city: form.buyerCity,
                state: form.buyerState.name,
                zipCode: form.buyerZipcode
            },
            registeredAs: [form.isRegisteredAs === 'other' ? form.other : form.isRegisteredAs],
            description: form.descriptionOfBusiness,
            tangibleDescription: form.descriptionOfProperty,
            stateRegistrations: isDraft
                ? getStateRegistrationsDraft(form)
                : getStateRegistrationsSubmit(form.stateArray)
        },
        eSignature: {
            certification: form.acceptTerms,
            firstName: form.signatureFirstName,
            lastName: form.signatureLastName,
            date: formatDate(form.signatureDate),
            title: form.signatureTitle,
            email: form.signatureEmail,
            phoneNumber: form.signaturePhoneNumber
        }
    };

    return payload;
};

export const joiningPersonas = [
    Persona.JoiningAdmin,
    Persona.JoiningTermsAndConditions,
    Persona.JoiningSalesAndUseTax,
    Persona.JoiningBillingAndShippingAddresses,
    Persona.JoiningCreditApplication
];

export function getJoiningAccount(permissions: UserPermission[]) {
    const joiningAccount = permissions.filter((permission) =>
        joiningPersonas.includes(permission.personaId)
    );
    if (joiningAccount?.length) {
        return joiningAccount[0].accountIds[0];
    } else {
        return '';
    }
}
