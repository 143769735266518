import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation, Trans } from 'react-i18next';
import {
    Grid,
    makeStyles,
    Typography,
    Button,
    Popover,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from '@material-ui/core';
import { ProductToShip, DeliveryShipment } from '../../../../store/reducers/shipping-dashboard';
import {
    blackWeight,
    large,
    small,
    ballBlue,
    boldWeight,
    ballGray,
    lato
} from '../../../../themes/globalConstants';
import moment from 'moment';
import { generateKey } from '../../../../utility/helpers/order-helpers';
import { getEachesConversion } from '../../../../utility/helpers/shipment-helpers';

interface Props {
    products: ProductToShip[];
    onAddProduct: (item: ProductToShip) => void;
    enableAdd: boolean;
    onAddShipment: () => void;
    shipments: DeliveryShipment[];
    deliveryDateIndex: number;
    deliveryDate: string;
    leadTime: number;
}

interface PalletQuantityByDay {
    date: string;
    availablePallets: number;
    maxPallets: number;
}

const useStyles = makeStyles((theme) => ({
    shipList: {
        maxWidth: '18.750em',
        margin: 'auto'
    },
    checkoutContainer: {
        padding: '0.875em 0.5em',
        backgroundColor: 'white',
        marginBottom: '.125em'
    },
    checkoutCansContainer: {
        borderTopRightRadius: '0.250em',
        borderTopLeftRadius: '0.250em',
        '&:first-child': {
            marginTop: '2.25em'
        }
    },
    checkoutEndsContainer: {
        marginTop: '.875em',
        borderRadius: '0.250em'
    },
    checkoutButtonContainer: {
        marginTop: '2.5em',
        marginBottom: '.875em'
    },
    title: {
        fontWeight: blackWeight,
        color: theme.palette.secondary.main,
        marginBottom: '1.25em'
    },
    checkoutItem: {
        padding: '0.875em 0.5em',
        backgroundColor: 'white',
        '&:not(:last-of-type)': {
            marginBottom: '.125em'
        }
    },
    checkoutText: {
        width: '9em',
        wordWrap: 'break-word'
    },
    productTypeTitle: {
        fontSize: large,
        fontWeight: blackWeight,
        marginRight: '.25em'
    },
    hidden: {
        display: 'none'
    },
    mediumLabel: {
        fontSize: large
    },
    addBtn: {
        marginLeft: '0.625em',
        padding: '0.375em 0.625em',
        minWidth: '2.250em',
        height: '2.28em'
    },
    addContainer: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
            fontWeight: blackWeight
        }
    },
    availOn: {
        fontSize: small,
        marginTop: '0.75em'
    },
    popover: {
        pointerEvents: 'none',
        padding: '1.5em',
        marginLeft: '0.750em',
        marginTop: '-1.75em'
    },
    paper: {
        padding: theme.spacing(1)
    },
    viewBalances: {
        color: ballBlue,
        textDecoration: 'underline',
        fontSize: small,
        cursor: 'pointer'
    },
    productTable: {
        margin: '1.875em',
        maxWidth: '25.5em'
    },
    productTableHeader: {
        '& p': {
            fontWeight: boldWeight
        }
    },
    availableBalance: {
        fontFamily: lato,
        color: ballGray,
        fontSize: small,
        fontWeight: blackWeight,
        letterSpacing: 1,
        textTransform: 'uppercase'
    }
}));

export default function ShipItConfigProductsToShip({
    products,
    onAddProduct,
    enableAdd,
    onAddShipment,
    shipments,
    deliveryDateIndex,
    deliveryDate,
    leadTime
}: Props) {
    const classes = useStyles();
    const [productsList, setProductsList] = useState([] as ProductToShip[]);
    const [endsList, setEndsList] = useState([] as ProductToShip[]);
    const [totalProductPallets, setTotalProductPallets] = useState(0);
    const [totalEndsPallets, setTotalEndsPallets] = useState(0);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [openedPopOverId, setOpenedPopOverId] = useState(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>, id: any) => {
        setOpenedPopOverId(id);
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setOpenedPopOverId(null);
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const handleAddProduct = (item: ProductToShip) => {
        onAddProduct(item);
    };

    const calculateTotalPallets = (productList: ProductToShip[]) => {
        let total = 0;
        productList.map((product) => {
            total += calculateRemainingPallets(product);
            return null;
        });
        return total;
    };

    const calculateMaxPallets = (product: ProductToShip) => {
        return product.previousPalletTotals ? product.previousPalletTotals.slice(-1)[0] : 0;
    };

    const calculateRemainingPallets = (product: ProductToShip, dateIndex = -1) => {
        return product.calculatedTotalsForPrevNDays
            ? product.calculatedTotalsForPrevNDays.slice(dateIndex)[0]
            : 0;
    };

    const createFirstAvailable = (product: ProductToShip) => {
        const firstAvailableShipmentDate = moment().add(leadTime, 'days').startOf('day');
        if (product && product.previousPalletTotals) {
            for (let x = 0; x < product.previousPalletTotals.length; x++) {
                if (product.previousPalletTotals[x] !== 0) {
                    let tempDate = moment(deliveryDate).add(x, 'd');

                    if (tempDate.isSameOrAfter(firstAvailableShipmentDate)) {
                        return {
                            date: tempDate.format('MM/DD'),
                            availablePallets: product.previousPalletTotals[x],
                            maxPallets: calculateMaxPallets(product)
                        };
                    }
                }
            }
        }
    };

    const createDateTable = (product: ProductToShip) => {
        let rows: PalletQuantityByDay[] = [];
        let maxPalletsToLoad: number = 0;
        const firstAvailableShipmentDate = moment().add(leadTime, 'days').startOf('day');

        if (product.previousPalletTotals) {
            product.previousPalletTotals?.map((pallets, x) => {
                const currentDayOfWeek = moment(deliveryDate).add(x, 'd');
                const formatedDate = currentDayOfWeek.format('MM/DD/YY');

                rows.push({
                    date: formatedDate,
                    availablePallets:
                        pallets !== 0 && currentDayOfWeek.isSameOrAfter(firstAvailableShipmentDate)
                            ? pallets
                            : 0,
                    maxPallets: calculateMaxPallets(product)
                } as PalletQuantityByDay);
                return null;
            });
        }

        maxPalletsToLoad = rows[rows.length - 1].maxPallets;

        return (
            <Table
                size="small"
                aria-label="product availablility table"
                key={generateKey('product-table')}
                className={classes.productTable}
            >
                <TableHead className={classes.productTableHeader}>
                    <TableRow>
                        <TableCell>
                            <Typography>
                                <Trans i18nKey="deliveryDay">Delivery Day</Trans>
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography>
                                <Trans i18nKey="availableQuantToLoad">
                                    Available Quantity Out of {{ maxPalletsToLoad }} PL to Load
                                </Trans>
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, i) => {
                        const availablePallets =
                            row.availablePallets !== 0 ? row.availablePallets : null;
                        const maxPallets = row.availablePallets !== 0 ? row.maxPallets : null;

                        return (
                            <TableRow key={'product-table-row' + i}>
                                <TableCell>
                                    <Typography>{row.date}</Typography>
                                </TableCell>
                                <TableCell>
                                    {row.availablePallets !== 0 ? (
                                        <Typography>
                                            <Trans i18nKey="availOutOfMax">
                                                {{ availablePallets }} PL out of {{ maxPallets }} PL
                                            </Trans>
                                        </Typography>
                                    ) : (
                                        <Typography>-</Typography>
                                    )}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    };

    useEffect(() => {
        if (products.length > 0) {
            const canList = products.filter(
                (product) => product.type === 'CAN' || product.type === 'BOTTLE'
            ) as ProductToShip[];
            const endList = products.filter(
                (product) => product.type === 'END' || product.type === 'CAP'
            ) as ProductToShip[];

            setProductsList(canList);
            setEndsList(endList);
            setTotalProductPallets(calculateTotalPallets(canList));
            setTotalEndsPallets(calculateTotalPallets(endList));
        }
    }, [products, shipments]);

    useTranslation();

    return (
        <Grid container item className={classes.shipList}>
            {products.length > 0 && (
                <>
                    <Grid
                        container
                        className={clsx(classes.checkoutContainer, classes.checkoutButtonContainer)}
                    >
                        <Grid item xs={12}>
                            <Button
                                type="button"
                                color="secondary"
                                variant="outlined"
                                data-testid="add-shipment-btn"
                                disabled={!enableAdd}
                                onClick={onAddShipment}
                            >
                                <Trans i18nKey="addShipment">Add Shipment</Trans>
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        className={clsx(classes.checkoutContainer, classes.checkoutCansContainer)}
                    >
                        <Grid item xs={12}>
                            <Typography variant="h3" className={classes.title}>
                                <Trans i18nKey="productsToShip">Products to Ship</Trans>
                            </Typography>
                        </Grid>
                        <Grid container item>
                            <Typography className={classes.productTypeTitle}>
                                <Trans i18nKey="cansBottles">Cans/Bottles</Trans>
                            </Typography>
                        </Grid>
                        <Grid container item>
                            <Typography className={classes.mediumLabel}>
                                <Trans i18nKey="remainingProductsToLoad">
                                    Remaining to load: {{ totalProductPallets }} PL
                                </Trans>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        {productsList &&
                            productsList.map((item, i) => {
                                const availablePalletBalance = calculateRemainingPallets(
                                    item,
                                    deliveryDateIndex
                                );
                                const firstAvailable = createFirstAvailable(
                                    item
                                ) as PalletQuantityByDay;
                                const date = firstAvailable.date;
                                const firstAvail = firstAvailable.availablePallets;
                                const firstAvailMax = firstAvailable.maxPallets;

                                return (
                                    <Grid
                                        container
                                        item
                                        key={'cans-list-item-row' + i}
                                        className={classes.checkoutItem}
                                        justify="space-between"
                                        data-testid={`product-${item.displayId}`}
                                        data-pallets={availablePalletBalance}
                                    >
                                        <Grid
                                            container
                                            item
                                            justify="space-between"
                                            alignItems="center"
                                        >
                                            <Grid item className={classes.checkoutText}>
                                                <Typography variant="h6">
                                                    {item.displayId}
                                                </Typography>
                                                <Typography variant="subtitle2">
                                                    {item.displayName}
                                                </Typography>
                                            </Grid>
                                            <Grid item className={classes.addContainer}>
                                                <Button
                                                    type="button"
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={() => {
                                                        handleAddProduct(item);
                                                    }}
                                                    disabled={item.addDisabled}
                                                    className={classes.addBtn}
                                                    data-testid="add-btn"
                                                >
                                                    <Trans i18nKey="add">add</Trans>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid container item>
                                            <Typography className={classes.availOn}>
                                                <span className={classes.availableBalance}>
                                                    {`${availablePalletBalance} PL / ${getEachesConversion(
                                                        availablePalletBalance,
                                                        item.quantityPerPallet!
                                                    )}`}
                                                </span>
                                                <Trans i18nKey="palletsAvailableOn">
                                                    Available on {{ date }}: {{ firstAvail }} PL of{' '}
                                                    {{ firstAvailMax }} PL
                                                </Trans>
                                            </Typography>
                                            <Typography
                                                key={'cans-list-data-table-trigger' + i}
                                                className={classes.viewBalances}
                                                aria-owns={
                                                    open
                                                        ? `mouse-over-popover-cans-list-${i}`
                                                        : undefined
                                                }
                                                aria-haspopup="true"
                                                onMouseEnter={(e) =>
                                                    handlePopoverOpen(
                                                        e,
                                                        `mouse-over-popover-cans-list-${i}`
                                                    )
                                                }
                                                onMouseLeave={handlePopoverClose}
                                            >
                                                <Trans i18nKey="viewBalancesByDay">
                                                    View balances by day
                                                </Trans>
                                            </Typography>
                                            <Popover
                                                id={`mouse-over-popover-cans-list-${i}`}
                                                className={classes.popover}
                                                classes={{
                                                    paper: classes.paper
                                                }}
                                                open={
                                                    openedPopOverId ===
                                                    `mouse-over-popover-cans-list-${i}`
                                                }
                                                anchorEl={anchorEl}
                                                anchorOrigin={{
                                                    vertical: 'center',
                                                    horizontal: 'right'
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left'
                                                }}
                                                onClose={handlePopoverClose}
                                                disableRestoreFocus
                                            >
                                                {createDateTable(item)}
                                            </Popover>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                    </Grid>
                    <Grid
                        container
                        className={clsx(classes.checkoutContainer, classes.checkoutEndsContainer)}
                    >
                        <Grid container item>
                            <Typography className={classes.productTypeTitle}>
                                <Trans i18nKey="endsClosures">Ends/Closures</Trans>
                            </Typography>
                        </Grid>
                        <Grid container item>
                            <Typography className={classes.mediumLabel}>
                                <Trans i18nKey="remainingEndsToLoad">
                                    Remaining to load: {{ totalEndsPallets }} PL
                                </Trans>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        {endsList &&
                            endsList.map((item, i) => {
                                const availablePalletBalance = calculateRemainingPallets(
                                    item,
                                    deliveryDateIndex
                                );
                                const firstAvailable = createFirstAvailable(
                                    item
                                ) as PalletQuantityByDay;
                                const date = firstAvailable.date;
                                const firstAvail = firstAvailable.availablePallets;
                                const firstAvailMax = firstAvailable.maxPallets;

                                return (
                                    <Grid
                                        container
                                        item
                                        key={'ends-list-item-row' + i}
                                        className={classes.checkoutItem}
                                        justify="space-between"
                                        data-pallets={`${availablePalletBalance}`}
                                        data-testid={`product-${item.productSku}`}
                                    >
                                        <Grid
                                            container
                                            item
                                            justify="space-between"
                                            alignItems="center"
                                        >
                                            <Grid item className={classes.checkoutText}>
                                                <Typography variant="h6">
                                                    {item.displayId}
                                                </Typography>
                                                <Typography variant="subtitle2">
                                                    {item.displayName}
                                                </Typography>
                                            </Grid>
                                            <Grid item className={classes.addContainer}>
                                                <Button
                                                    type="button"
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={() => {
                                                        handleAddProduct(item);
                                                    }}
                                                    disabled={item.addDisabled}
                                                    className={classes.addBtn}
                                                    data-testid="add-btn"
                                                >
                                                    <Trans i18nKey="add">add</Trans>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid container item>
                                            <Typography className={classes.availOn}>
                                                <div className={classes.availableBalance}>
                                                    {`${availablePalletBalance} PL / ${getEachesConversion(
                                                        availablePalletBalance,
                                                        item.quantityPerPallet!
                                                    )}`}
                                                </div>
                                                <Trans i18nKey="palletsAvailableOn">
                                                    Available on {{ date }}: {{ firstAvail }} PL of{' '}
                                                    {{ firstAvailMax }} PL
                                                </Trans>
                                            </Typography>
                                            <Typography
                                                key={'ends-list-data-table-trigger' + i}
                                                className={classes.viewBalances}
                                                aria-owns={
                                                    open
                                                        ? `mouse-over-popover-ends-list-${i}`
                                                        : undefined
                                                }
                                                aria-haspopup="true"
                                                onMouseEnter={(e) =>
                                                    handlePopoverOpen(
                                                        e,
                                                        `mouse-over-popover-ends-list-${i}`
                                                    )
                                                }
                                                onMouseLeave={handlePopoverClose}
                                            >
                                                <Trans i18nKey="viewBalancesByDay">
                                                    View balances by day
                                                </Trans>
                                            </Typography>
                                            <Popover
                                                id={`mouse-over-popover-ends-list-${i}`}
                                                className={classes.popover}
                                                classes={{
                                                    paper: classes.paper
                                                }}
                                                open={
                                                    openedPopOverId ===
                                                    `mouse-over-popover-ends-list-${i}`
                                                }
                                                anchorEl={anchorEl}
                                                anchorOrigin={{
                                                    vertical: 'center',
                                                    horizontal: 'right'
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left'
                                                }}
                                                onClose={handlePopoverClose}
                                                disableRestoreFocus
                                            >
                                                {createDateTable(item)}
                                            </Popover>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                    </Grid>
                </>
            )}
        </Grid>
    );
}
