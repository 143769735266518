import {
    CUSTOMER_DASHBOARD_ERROR,
    CUSTOMER_DASHBOARD_LOADING,
    CUSTOMER_DASHBOARD_RESET,
    CUSTOMER_DASHBOARD_CACHE_SAVE,
    CUSTOMER_DASHBOARD_SINGLE_CACHE_RESET,
    CUSTOMER_DASHBOARD_MAKEIT_WIDGET_ORDERS,
    CUSTOMER_DASHBOARD_MAKEIT_WIDGET_INVOICES,
    CUSTOMER_DASHBOARD_MAKEIT_WIDGET_AGED_BALANCE,
    CUSTOMER_DASHBOARD_MAKEIT_WIDGET_PRODUCT_BALANCE,
    CUSTOMER_DASHBOARD_RESET_STATUS,
    CUSTOMER_DASHBOARD_LOADED
} from '../actions/action-types';
import { Invoice } from './make-it-summary';
import { ProductionOrderSummary } from './make-it-summary';

export interface MakeItDashboardWidget {
    openOrders?: ProductionOrderSummary[];
    agedBalance?: string[];
    pendingInvoices?: Invoice[];
    pastDueInvoices?: Invoice[];
    approachingInvoices?: Invoice[];
    nextWeek?: any;
    twoToFourWeeks?: any;
    fiveToEightWeeks?: any;
    ninePlusWeeks?: any;
}

export interface MakeItDashboardItem {
    week: number;
    openOrders: number;
    productSkus: number;
    pallets: number;
}

export interface MakeItDashboard {
    items: MakeItDashboardItem[];
    agedProducts: number;
    agedProductsPalletQuantity: number;
    pastDuePayments: number;
    approachingPayments: number;
    draftOrders: number;
}
export interface ShipItDashboardItem {
    week: number;
    openShipments: number;
    productSkus: number;
    pallets: number;
}

export interface ShipItDashboard {
    items: ShipItDashboardItem[];
}

export interface Dashboard {
    makeItDashboard: MakeItDashboard;
    shipItDashboard: ShipItDashboard;
}
interface CachedDashboard {
    shipToId: string;
    dateLimit: Date;
    data: Dashboard;
}

export interface CustomerDashboardState {
    status: 'idle' | 'loading' | 'error' | 'success';
    dashboardData?: Dashboard;
    makeItDashboardWidget?: MakeItDashboardWidget;
    cachedDashboard: CachedDashboard[];
}

const initialState: CustomerDashboardState = {
    status: 'idle',
    makeItDashboardWidget: undefined,
    dashboardData: undefined,
    cachedDashboard: []
};

const customerDashboard = (
    state: CustomerDashboardState = initialState,
    action: { type: string; [x: string]: any }
): CustomerDashboardState => {
    switch (action.type) {
        case CUSTOMER_DASHBOARD_LOADING:
            return {
                ...state,
                status: 'loading',
                makeItDashboardWidget: undefined,
                dashboardData: undefined
            };
        case CUSTOMER_DASHBOARD_MAKEIT_WIDGET_ORDERS:
            return {
                ...state,
                status: 'success',
                makeItDashboardWidget: {
                    ...state.makeItDashboardWidget,
                    openOrders: action.openOrders
                }
            };
        case CUSTOMER_DASHBOARD_MAKEIT_WIDGET_AGED_BALANCE:
            return {
                ...state,
                status: 'success',
                makeItDashboardWidget: {
                    ...state.makeItDashboardWidget,
                    agedBalance: action.agedBalance
                }
            };
        case CUSTOMER_DASHBOARD_MAKEIT_WIDGET_INVOICES:
            return {
                ...state,
                status: 'success',
                makeItDashboardWidget: {
                    ...state.makeItDashboardWidget,
                    pendingInvoices: action.pendingInvoices,
                    pastDueInvoices: action.pastDueInvoices,
                    approachingInvoices: action.approachingInvoices
                }
            };
        case CUSTOMER_DASHBOARD_MAKEIT_WIDGET_PRODUCT_BALANCE:
            return {
                ...state,
                status: 'success',
                makeItDashboardWidget: {
                    ...state.makeItDashboardWidget,
                    nextWeek: action.nextWeek,
                    twoToFourWeeks: action.twoToFourWeeks,
                    fiveToEightWeeks: action.fiveToEightWeeks,
                    ninePlusWeeks: action.ninePlusWeeks
                }
            };
        case CUSTOMER_DASHBOARD_ERROR:
            return {
                ...state,
                status: 'error'
            };
        case CUSTOMER_DASHBOARD_RESET_STATUS:
            return {
                ...state,
                status: 'idle'
            };
        case CUSTOMER_DASHBOARD_RESET:
            return {
                ...initialState
            };
        case CUSTOMER_DASHBOARD_LOADED:
            return {
                ...state,
                status: 'success',
                dashboardData: {
                    makeItDashboard: action.dashboard.makeItDashboard,
                    shipItDashboard: action.dashboard.shipItDashboard
                }
            };
        case CUSTOMER_DASHBOARD_CACHE_SAVE:
            let newCachedDashboard = state.cachedDashboard;

            if (action.shipToId && action.dashboard) {
                const index = newCachedDashboard.findIndex((cd) => cd.shipToId === action.shipToId);
                if (index >= 0) {
                    newCachedDashboard[index].data = action.dashboard;
                    newCachedDashboard[index].dateLimit = action.dateLimit;
                } else {
                    newCachedDashboard.push({
                        shipToId: action.shipToId,
                        data: action.dashboard,
                        dateLimit: action.dateLimit
                    });
                }
            }
            return {
                ...state,
                cachedDashboard: newCachedDashboard
            };
        case CUSTOMER_DASHBOARD_SINGLE_CACHE_RESET:
            return {
                ...state,
                cachedDashboard: state.cachedDashboard.filter(
                    (cd) => cd.shipToId !== action.shipToId
                )
            };
        default:
            return state;
    }
};

export default customerDashboard;
