import React, { useEffect, useState } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { black, blackWeight, boldWeight, ltBlueGrey_34 } from '../../../../themes/globalConstants';
import { generateKey } from '../../../../utility/helpers/order-helpers';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import MakeItConversionConfirmationOrderSectionColumns from './MakeItConversionConfirmationOrderSectionColumns';
import MakeItConversionConfirmationOrderSectionRow from './MakeItConversionConfirmationOrderSectionRow';
import { MakeItBulkATMLineItem } from '../../../../store/reducers/makeit-bulk-atm';

interface Props {
    lines: MakeItBulkATMLineItem[];
}

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        width: '100%',
        marginTop: '1em',
        '& .MuiToolbar-root': {
            minHeight: 0,
            padding: '0 0.125em',
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                }
            }
        },
        '& .MuiTableCell-head': {
            justifyContent: 'space-between',
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em'
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTablePagination-root': {
            '& .MuiTablePagination-caption': {
                marginLeft: '1em',
                fontWeight: boldWeight,
                color: black
            }
        },
        '& .MuiTableHead-root': {
            borderTop: '5px solid',
            borderTopColor: ltBlueGrey_34,
            '& th': {
                borderLeft: 'none',
                '&:nth-child(1)': {
                    width: '20%'
                },
                '&:nth-child(2)': {
                    width: '35%'
                },
                '&:nth-child(3)': {
                    width: '15%'
                },
                '&:nth-child(4)': {
                    width: '20%'
                },
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    }
}));

const MakeItConversionConfirmationOrderSectionGrid = ({ lines }: Props) => {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [columns, setColumns] = useState<any>([]);

    useEffect(() => {
        setColumns(MakeItConversionConfirmationOrderSectionColumns.getColumns(cultureCode));
    }, [cultureCode]);

    const CustomToolbarOptions = new ToolbarOptions({
        exportButton: false,
        printButton: false,
        enablePagination: false,
        searchText: false
    });

    const ProductRow = ({ row }: { row: MakeItBulkATMLineItem }) => {
        return <MakeItConversionConfirmationOrderSectionRow key={row.productSku} item={row} />;
    };

    return (
        <Grid
            container
            className={classes.tableWrapper}
            key={generateKey('makeit-bulk-atm-confirmation')}
            data-testid="make-it-bulk-confirmation-atm-product-grid"
        >
            <DataGrid
                columns={columns}
                dataSource={lines}
                gridName="Make It Bulk Confirmation Grid"
                rowComponent={ProductRow}
                toolbarOptions={CustomToolbarOptions}
            />
        </Grid>
    );
};

export default MakeItConversionConfirmationOrderSectionGrid;
