import { useEffect } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { DragAndDropItem } from '../../../reusable/molecules/DragAndDrop/models';
import MakeItDashboardWidget from '../components/MakeItDashboardWidget';
import ShipItDashboardWidget from '../components/ShipItDashboardWidget';

export const customerDashboardDragAndDropItems: (
    showMakeItWidget: boolean,
    showShipItWidget: boolean,
    isCiaCustomer: boolean,
    isLargeCustomer: boolean
) => Array<DragAndDropItem> = (
    showMakeItWidget,
    showShipItWidget,
    isCiaCustomer,
    isLargeCustomer
) => {
    let list: Array<DragAndDropItem> = [];
    if (showMakeItWidget) {
        list.push({
            id: 'makeit',
            component: MakeItDashboardWidget
        });
    }
    if (showShipItWidget) {
        list.push({
            id: 'shipit',
            component: ShipItDashboardWidget
        });
    }
    return list;
};

export interface DragAndDropPosition {
    name: string;
}

export function useDragAndDrop<Type extends DragAndDropItem>(
    items: Array<Type>,
    setItems: React.Dispatch<React.SetStateAction<Type[]>>,
    positions: Array<DragAndDropPosition>,
    onReorder: (arg: Array<Type>) => any
): [(result: DropResult) => void, (items: Array<Type>, hasCiaCustomerChanged?: boolean) => void] {
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    function onDragEnd(result: DropResult) {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const reordered = reorder(
            items,
            result.source.index,
            result.destination.index
        ) as Array<Type>;

        onReorder(reordered.map((tile) => tile.id));
    }

    const updateListItems = (dragAndDropItems: Array<Type>, hasCiaCustomerChanged?: boolean) => {
        const modified = dragAndDropItems.length !== items.length;
        const newItems: Array<Type> = [];

        let sorted = false;

        if (positions.length) {
            positions.forEach((position, endIndex) => {
                const startIndex = dragAndDropItems.findIndex((tile) => tile.id === position.name);
                if (startIndex !== -1) {
                    if (startIndex !== endIndex) {
                        sorted = true;
                    }

                    newItems.push(dragAndDropItems[startIndex]);
                }
            });
        } else {
            dragAndDropItems.forEach((item) => {
                newItems.push(item);
            });
        }

        if (sorted || modified || hasCiaCustomerChanged) {
            setItems(newItems);
        }
    };

    useEffect(() => {
        if (positions && positions.length) {
            updateListItems(items);
        }
    }, [positions]);

    return [onDragEnd, updateListItems];
}
