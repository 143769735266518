import { Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Activity } from '../../../utility/auth/useSecurity';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import Button from '../../reusable/atoms/Button';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { ballBlue, blackWeight, boldWeight, small } from '../../../themes/globalConstants';
import { formattedMTDate } from '../../../utility/helpers/date-helpers';
import moment from 'moment';
import { InfoSubheaderItem } from '../../../store/reducers/glp';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { usePrevious } from '../../../utility/helpers/react-util';
import { FormattedWeek } from '../MakeItBulkReviewATM/models/FormattedWeek';
import {
    DateProperties,
    getDateProperties,
    groupOrdersBySnoSku
} from '../../../utility/helpers/make-it-bulk-helpers';
import { ToggleAll } from '../../../utility/helpers/order-helpers';
import { getFormattedEachesFromNumber } from '../../../utility/helpers/shipment-helpers';
import { formatNumberWithLocale } from '../../../utility/helpers/formatters';
import { FormattedAccount } from '../MakeItBulkReviewATM/models/FormattedAccount';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { getOrderQuantities } from '../MakeItATMSummary/utils/makeit-atm-summary-utils';
import {
    PlanItBulkATMLinesBySnoSku,
    PlanItBulkATMOrderByWeekAndSku
} from '../../../store/reducers/planit-bulk-atm';
import { filterOutDeletedOrders } from '../MakeItBulkATMConfirmation/utils/mib-atm-confirmation-utils';
import ShipToDropdown from '../MakeItBulkReviewATM/components/ShipToDropdown';
import WeekSelectorDropdown from '../MakeItBulkReviewATM/components/WeekSelectorDropdown';
import PlanItBulkUploadConfirmationOrderSection from './components/PlanItGuidedConfirmationOrderSection';
import PlanItGuidedConfirmationShareLinks from './components/PlanItGuidedConfirmationShareLinks';
import { PlanItGuidedState } from '../../../store/reducers/plan-it-guided';
import { resetPlanItGuidedState } from '../../../store/root-actions';
import { selectIsLargeCustomerAccount } from '../../../store/selectors';

const useStyles = makeStyles((theme) => ({
    actionBtn: {
        borderRadius: 'unset'
    },
    summarySection: {
        flexWrap: 'nowrap'
    },
    pageHeader: {
        padding: '40px 0'
    },
    viewAtmTitle: {
        fontWeight: blackWeight,
        color: theme.palette.secondary.main,
        textTransform: 'capitalize'
    },
    btn: {
        padding: 0,
        minWidth: 'auto',
        fontSize: small,
        fontWeight: boldWeight,
        margin: '0 0.5em'
    },
    expandCollapseWrapper: {
        alignItems: 'center',
        color: ballBlue
    },
    selector: {
        display: 'flex',
        marginTop: '1em'
    }
}));

export default function PlanItGuidedConfirmation() {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { forecastOrders } = useTypedSelector<PlanItGuidedState>((state) => state.planItGuided);
    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [formattedWeekList, setFormattedWeekList] = useState<FormattedWeek[]>();
    const [activeDate, setActiveDate] = useState<DateProperties>({
        fullDate: '',
        week: '',
        year: ''
    });
    const [ordersBySnoSku, setOrdersBySnoSku] = useState<PlanItBulkATMOrderByWeekAndSku[]>();

    const [subheaderItems, setSubheaderItems] = useState<InfoSubheaderItem[]>();
    const [activeDateOrders, setActiveDateOrders] = useState<PlanItBulkATMOrderByWeekAndSku[]>();
    const [shipToAccountsList, setShipToAccountsList] = useState<FormattedAccount[]>([]);
    const [expandAll] = useState<ToggleAll>({ toggle: false });
    const [selectedShipTo, setSelectedShipTo] = useState<string>();
    const prevCultureCode = usePrevious(cultureCode);
    const prevActiveDate = usePrevious(activeDate);
    const [poNumber, setPONumber] = useState<string>(t('noCustomerPO', 'No Customer PO'));
    const isLargeCustomer = useTypedSelector<boolean>(selectIsLargeCustomerAccount);

    const handleDashboardNavigation = () => {
        dispatch(resetPlanItGuidedState());
        history.push('/dashboard');
    };

    const createSubheader = (activeDate: DateProperties, selectedShipTo?: string) => {
        let values = getOrderQuantities(activeDate, forecastOrders, selectedShipTo);
        setSubheaderItems([
            {
                subtitle: t('dateRevised', 'Date Revised'),
                body: formattedMTDate(moment(), 'MM/DD/YYYY')
            },
            {
                subtitle: t('canQuantity', 'Can Quantity'),
                body: `${formatNumberWithLocale(cultureCode, values.canPallets)} PL/${
                    values.canSkus
                } ${values.canSkus > 1 ? t('skus', 'SKUs') : t('sku', 'SKU')}`,
                subtext: `${getFormattedEachesFromNumber(values.canEaches, cultureCode)} ea.`
            },
            {
                subtitle: t('endQuantity', 'End Quantity'),
                body: `${formatNumberWithLocale(cultureCode, values.endPallets)} PL/${
                    values.endSkus
                } ${values.endSkus > 1 ? t('skus', 'SKUs') : t('sku', 'SKU')}`,
                subtext: `${getFormattedEachesFromNumber(values.endEaches, cultureCode)} ea.`
            }
        ] as InfoSubheaderItem[]);
    };

    /**
     * Load the Ship To Accounts from the order - only include a single ship to once.
     * @when Triggers on initial page load, after shipToAccounts and forecastOrders are in state.
     */
    useEffect(() => {
        if (forecastOrders && shipToAccounts) {
            let accountsInOrder: FormattedAccount[] = [];
            forecastOrders.forEach((order) => {
                let orderShipTo = order.shipToId;
                let account = shipToAccounts.find(
                    (account) => parseInt(account.accountId) === orderShipTo
                );

                let formattedAccount = {
                    account: account,
                    state: 'valid'
                } as FormattedAccount;

                if (
                    account &&
                    !accountsInOrder.find((accountInList) => accountInList.account === account)
                ) {
                    accountsInOrder.push(formattedAccount);
                }
            });
            setShipToAccountsList(accountsInOrder);
        }
    }, [shipToAccounts, forecastOrders]);

    useEffect(() => {
        return () => {
            if (history.location.pathname !== '/plan-it-confirmation') {
                dispatch(resetPlanItGuidedState());
            }
        };
    }, [dispatch, history.location.pathname]);

    /**
     * Set the currently selected ship to as the first Account in the shipToAccountsList.
     * @when Trigger once on initial page load, after shipToAccountsList has been set from the above useEffect.
     */
    useEffect(() => {
        if (shipToAccountsList.length && !selectedShipTo) {
            setSelectedShipTo(shipToAccountsList[0].account.accountId);
        }
    }, [shipToAccountsList, selectedShipTo]);

    /**
     * Filter ordersBySnoSku by the currently selected ship to and active date.
     * Last step before order information is displayed.
     * @when Trigger if orders have been grouped by snoSku, when the activeDate
     * or the selectedShipTo are changed.
     * @deps ordersBySnoSku, activeDate, selectedShipTo
     */
    useEffect(() => {
        if (ordersBySnoSku && ordersBySnoSku.length > 0 && activeDate && selectedShipTo) {
            const ordersBySnoSkuUpdate = JSON.parse(JSON.stringify(ordersBySnoSku));
            const activeOrders = ordersBySnoSkuUpdate.filter((week) => {
                return (
                    getDateProperties(moment.utc(week.weekStart)).week === activeDate.week &&
                    week.shipToId === parseInt(selectedShipTo)
                );
            });

            let formattedOrders: Array<PlanItBulkATMLinesBySnoSku> = [];

            activeOrders.forEach((order) => {
                order.linesBySnoSku.forEach((productGroup) => {
                    let hasDuplicateProducts = false;
                    productGroup.lines.forEach((line) => {
                        if (!line.isCustomerProductIdDistinct) {
                            hasDuplicateProducts = true;
                        }
                    });

                    productGroup.hasError = hasDuplicateProducts;
                    formattedOrders.push(productGroup);
                });
                order.linesBySnoSku = formattedOrders;
            });
            setActiveDateOrders(activeOrders);
            if (
                cultureCode !== prevCultureCode ||
                !subheaderItems?.length ||
                activeDate !== prevActiveDate
            ) {
                createSubheader(activeDate, selectedShipTo);
            }
        }
        // eslint-disable-next-line
    }, [ordersBySnoSku, activeDate, selectedShipTo]);

    useEffect(() => {
        if (forecastOrders && activeDate && selectedShipTo) {
            const activeForecastOrder = forecastOrders.filter((order) => {
                return (
                    //getDateProperties(moment(order.weekStart)).week === activeDate.week &&
                    moment.utc(order.atmWeekStart).format('MM/DD/YYYY') === activeDate.fullDate &&
                    order.shipToId === parseInt(selectedShipTo)
                );
            });
            // there should be only one forecast for each week/ship to combination
            // but some older data may violate this restriction
            if (
                activeForecastOrder.length === 1 &&
                activeForecastOrder[0].customerProductionOrderId
            ) {
                setPONumber(
                    activeForecastOrder[0].customerProductionOrderId.length > 0
                        ? activeForecastOrder[0].customerProductionOrderId
                        : t('noCustomerPO', 'No Customer PO')
                );
            } else {
                setPONumber('');
            }
        }
    }, [forecastOrders, activeDate, selectedShipTo]);

    /**
     * Group forecastOrders by snoSku.
     * @when Trigger only once on load
     * @deps forecastOrders
     */
    useEffect(() => {
        if (forecastOrders) {
            let groupedOrders = groupOrdersBySnoSku(
                forecastOrders,
                []
            ) as PlanItBulkATMOrderByWeekAndSku[];

            setOrdersBySnoSku(groupedOrders);
        }
    }, [forecastOrders, dispatch]);

    useEffect(() => {
        if (forecastOrders && selectedShipTo) {
            if (
                cultureCode !== prevCultureCode ||
                !subheaderItems?.length ||
                activeDate !== prevActiveDate
            ) {
                createSubheader(activeDate, selectedShipTo);
            }
            let weeksList: string[] = [];
            forecastOrders
                .filter((order) => order.shipToId === parseInt(selectedShipTo))
                .map((order) => order.weekStart)
                .forEach((weekStart) => {
                    if (!weeksList.includes(weekStart)) {
                        weeksList.push(weekStart);
                    }
                });
            let weekListAsDate: FormattedWeek[] = weeksList.map((week) => {
                return { weekAsDate: moment.utc(week), state: 'valid' };
            });

            // Ensure active date is reset (when a new ship to is selected)
            setActiveDate({
                fullDate: '',
                week: '',
                year: ''
            } as DateProperties);

            weekListAsDate.sort((a, b) => a.weekAsDate.valueOf() - b.weekAsDate.valueOf());
            setFormattedWeekList(weekListAsDate);
        }
        // eslint-disable-next-line
    }, [forecastOrders, cultureCode, selectedShipTo]);

    useEffect(() => {
        if (formattedWeekList && formattedWeekList.length && !activeDate.fullDate.length) {
            setActiveDate(getDateProperties(formattedWeekList[0].weekAsDate));
        }
        // eslint-disable-next-line
    }, [formattedWeekList]);

    const footerActions = (
        <Button
            type="button"
            color="primary"
            variant="contained"
            data-testid="dashboard-btn"
            className={classes.actionBtn}
            onClick={handleDashboardNavigation}
        >
            <Trans i18nKey="myDashboard">My Dashboard</Trans>
        </Button>
    );

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('planIt', 'Plan It'),
                description: t(
                    'editProductionOrderSubmitted',
                    'Thank you, your changes have been received.'
                ).toLocaleUpperCase(),
                thinBanner: true,
                displayDropdown: false
            }}
            actionFooter={{
                footerAction: footerActions,
                justify: 'center',
                sticky: true
            }}
            activity={Activity.PlanItBulkUpload}
        >
            <Grid container item className={classes.pageHeader}>
                <Grid container item xs={8}>
                    <Grid item xs={6}>
                        <Typography variant="subtitle2" className={classes.viewAtmTitle}>
                            {t('shipTo', 'Ship To').toUpperCase()}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="subtitle2" className={classes.viewAtmTitle}>
                            {t('forecastWeek', 'Forecast Week').toUpperCase()}
                            {!isLargeCustomer ? `/${t('poNumber', 'PO Number').toUpperCase()}` : ''}
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        xs={6}
                        container
                        className={classes.selector}
                        data-testid="ship-to-selector"
                    >
                        <ShipToDropdown
                            name="shipToSelect"
                            testId="shipToSelect"
                            selectObjects={shipToAccountsList}
                            selected={selectedShipTo}
                            updateSelected={setSelectedShipTo}
                            showIcons={false}
                        />
                    </Grid>
                    {formattedWeekList && (
                        <Grid
                            container
                            item
                            xs={6}
                            className={classes.selector}
                            data-testid="dateBar"
                        >
                            <WeekSelectorDropdown
                                testId="weekSelect"
                                weeks={formattedWeekList}
                                selectedDate={activeDate}
                                onDateSelected={setActiveDate}
                                showIcons={false}
                                customerPONumber={isLargeCustomer ? undefined : poNumber}
                                showCustomerPoNumber={isLargeCustomer ? false : !!poNumber}
                            />
                        </Grid>
                    )}
                </Grid>
                <Grid justify="flex-end" xs={4}>
                    {ordersBySnoSku && (
                        <PlanItGuidedConfirmationShareLinks atmOrdersBySnoSku={ordersBySnoSku} />
                    )}
                </Grid>
            </Grid>
            <Grid xs={12}>
                <Typography
                    variant="h3"
                    className={classes.viewAtmTitle}
                    data-testid="current-selected-date"
                >
                    {t('forecastForWk', 'Forecast for the week of').toUpperCase()} {activeDate.week}
                </Typography>
                {activeDateOrders &&
                    filterOutDeletedOrders(activeDateOrders).map((order) =>
                        order.linesBySnoSku.map((grouping) => {
                            return (
                                <PlanItBulkUploadConfirmationOrderSection
                                    order={grouping}
                                    shipToId={order.shipToId}
                                    expand={expandAll}
                                    isActive={grouping.isActive}
                                />
                            );
                        })
                    )}
            </Grid>
        </ProcessingPageTemplate>
    );
}
