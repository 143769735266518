import React, { ReactNode, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Activity } from '../../../utility/auth/useSecurity';
import DataValidationGrid, { DataValidation } from './components/DataValidationGrid';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { DataValidationDisplayTemplate } from '../../templates/DataValidationDisplayTemplate';
import { selectIsLargeCustomer } from '../../../store/selectors';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { medium } from '../../../themes/globalConstants';

const useStyles = makeStyles((theme) => ({
    message: {
        textAlign: 'center',
        fontSize: medium
    }
}));

const PlanItBulkUploadValidationRules = () => {
    const { t } = useTranslation();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [culture, setCulture] = useState<string>();
    const [isATM, setIsATM] = useState<boolean>(false);
    const [rules, setRules] = useState<DataValidation[]>();
    const isLargeCustomer = useTypedSelector<boolean>(selectIsLargeCustomer);
    const classes = useStyles();

    const createData = (
        validationField?: ReactNode,
        validationFormat?: ReactNode,
        validationRule?: ReactNode,
        validationExample?: ReactNode
    ) => {
        return { validationField, validationFormat, validationRule, validationExample };
    };

    useEffect(() => {
        if (!rules || cultureCode !== culture || isLargeCustomer !== isATM) {
            setCulture(cultureCode);
            setIsATM(isLargeCustomer);
            let rulesArray = [
                createData(
                    t('shipToId', 'Ship to ID'),
                    t('integer', 'Integer'),
                    t('upTo8Digits', 'Up to 8 digits'),
                    '133735'
                ),
                createData(
                    t('customerPoNumber', 'Customer PO #'),
                    t('text', 'Text'),
                    t('upTo25Characters', 'Up to 25 characters'),
                    'GOATHL-051520'
                ),
                createData(
                    t('productId', 'Product ID'),
                    t('text', 'Text'),
                    t('upTo25Characters', 'Up to 25 characters'),
                    '1411691'
                ),
                createData(
                    t('forecastWeek', 'Forecast Week'),
                    t('dateInputFormat', 'MM/DD/YYYY'),
                    '--',
                    isLargeCustomer ? '7/19/2021' : '12/12/2021'
                ),
                createData(
                    t('quantityInPallets', 'Quantity (in Pallets)'),
                    t('integer', 'Integer'),
                    t('upTo6Digits', 'Up to 6 digits'),
                    '25'
                ),
                createData(
                    t('quantityInEaches', 'Quantity (In Eaches)'),
                    t('integer', 'Integer'),
                    t('upTo12Digits', 'Up to 12 digits'),
                    '133450650'
                )
            ];
            if (isLargeCustomer) {
                //remove Customer PO #
                rulesArray.splice(1, 1);
            }
            setRules(rulesArray);
        } else {
            rules.map((rule, index) => {
                return (rule.index = index);
            });
        }
    }, [rules, cultureCode, isLargeCustomer]);

    return (
        <DataValidationDisplayTemplate
            banner={{
                header: t('planItBulkValidation', 'Plan It Bulk Upload Data Validation'),
                description: '',
                thinBanner: true,
                displayDropdown: false
            }}
            activity={Activity.PlanItBulkUpload}
            shipTos={false}
        >
            <DataValidationGrid title="dataValidationRules" rows={rules} />
            <Grid>
                <Typography className={classes.message}>
                    <Trans i18nKey="doNotIncludeCommas">
                        Do not include commas within your file.
                    </Trans>
                </Typography>
            </Grid>
        </DataValidationDisplayTemplate>
    );
};

export default PlanItBulkUploadValidationRules;
