import React, { ReactElement } from 'react';
import rootTheme from '../../../../themes/rootTheme';
import { useTranslation } from 'react-i18next';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { styles } from './styles';
import Checkbox from '../../../reusable/atoms/Checkbox';

interface Props {
    header: string;
    options: any[];
    selection: Set<any>;
    toggleSelection: (value, selection) => void;
    errorMessage?: string;
}

export default function CheckboxSection({
    header,
    options,
    selection,
    toggleSelection,
    errorMessage
}: Props): ReactElement {
    const useStyles = makeStyles(styles(rootTheme) as any);
    const classes = useStyles();
    const { t } = useTranslation();

    function getLabel(option: any): string {
        const optionValue: string = option.value || option;
        return t(optionValue, option.label || option);
    }

    return (
        <Grid container spacing={2} align-items="flex-start" className={classes.section}>
            <Grid item xs={12}>
                <Typography variant="h5" className={classes.subHeader}>
                    {header}
                </Typography>
            </Grid>
            <Grid item xs={12} className={classes.noSelect}>
                {options.map((option) => (
                    <Checkbox
                        key={option.label || option}
                        checked={selection.has(option.value || option)}
                        label={getLabel(option)}
                        ariaLabel={`${option} Checkbox`}
                        onChange={(value) => toggleSelection(value, option.value || option)}
                    />
                ))}
            </Grid>
            {errorMessage && (
                <Typography color="error" className={classes.error}>
                    {errorMessage}
                </Typography>
            )}
        </Grid>
    );
}
