import React, { useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { InvoiceRow } from '../../../../store/reducers/make-it-summary';
import makeItPendingPaymentColumns from './MakeItPendingPaymentColumns';
import MakeItPendingPaymentRow from './MakeItPendingPaymentRow';
import StandardTable from '../../../reusable/molecules/StandardTable';
import {
    ballBlue,
    blackWeight,
    boldWeight,
    dateOptionInvalidGray,
    small
} from '../../../../themes/globalConstants';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { CustomerContextState, PaymentTerms } from '../../../../store/reducers/customer-context';
import SummarySearchBar from '../../../reusable/atoms/SummarySearchBar';

interface Props {
    formattedInvoices: InvoiceRow[];
}

const useStyles = makeStyles((theme) => ({
    searchBar: {
        height: '0px',
        float: 'right',
        marginRight: '10px',
        width: '250px',
        position: 'relative',
        bottom: '-20px',
        zIndex: 1
    },
    tableWrapper: {
        '& .MuiTable-root': {
            tableLayout: 'fixed'
        },
        '& .MuiToolbar-root': {
            padding: 0,
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                },
                '&:first-of-type ': {
                    paddingLeft: 0
                }
            },
            '& > h2': {
                fontSize: '1.5em',
                fontWeight: boldWeight,
                color: theme.palette.secondary.main,
                paddingLeft: '0.625em'
            },
            '& .MuiInputBase-formControl': {
                border: `1px solid ${dateOptionInvalidGray}`,
                borderRadius: '.125em',
                '&:before': {
                    display: 'none'
                },
                '&:after': {
                    display: 'none'
                },
                '& svg': {
                    height: '.875em',
                    width: '.875em',
                    fill: ballBlue
                },
                '& .MuiIconButton-root': {
                    '&:hover': {
                        backgroundColor: 'transparent !important'
                    }
                },
                '& .MuiInput-input': {
                    paddingLeft: '.25em'
                }
            }
        },
        '& .MuiTableSortLabel-root': {
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em',
            width: '99%',
            justifyContent: 'space-between',
            '& .MuiSvgIcon-root': {
                fontSize: 14,
                opacity: '1'
            }
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                '&:nth-child(1)': {
                    width: '18%'
                },
                '&:nth-child(2)': {
                    width: '10%'
                },
                '&:nth-child(3)': {
                    width: '11%'
                },
                '&:nth-child(4)': {
                    width: '8%'
                },
                '&:nth-child(5)': {
                    width: '11%'
                },
                '&:nth-child(6)': {
                    width: '10%'
                },
                '&:nth-child(7)': {
                    width: '11%'
                },
                '&:nth-child(8)': {
                    width: '13%'
                },
                '&:nth-child(9)': {
                    width: '8%',
                    fontSize: small,
                    fontWeight: blackWeight,
                    textTransform: 'uppercase',
                    color: theme.palette.secondary.main,
                    borderRadius: '0.25em',
                    paddingLeft: '0.5em',
                    justifyContent: 'space-between'
                }
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    }
}));

const MakeItPendingPaymentTab = ({ formattedInvoices }: Props) => {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [columns, setColumns] = useState<any>([]);
    const [filteredOrders, setFilteredOrders] = useState<InvoiceRow[]>([]);
    const { shipToAccounts, selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const [ciaAccount, setCiaAccount] = useState<boolean>(false);

    useEffect(() => {
        if (shipToAccounts) {
            const selectedAccount = shipToAccounts.find(
                (account) => account.accountId === selectedAccountId
            );
            if (selectedAccount) {
                setCiaAccount(selectedAccount.paymentTerms === PaymentTerms.CIA);
            }
        }
    }, [selectedAccountId, shipToAccounts]);

    useEffect(() => {
        let showActionsColumn = ciaAccount;
        setColumns(
            makeItPendingPaymentColumns.getMakeItPendingPaymentColumns(
                cultureCode,
                showActionsColumn
            )
        );
    }, [cultureCode, ciaAccount]);

    const PendingPaymentsRow = ({ row }: { row: Partial<InvoiceRow> }) => {
        if (row) {
            return (
                <MakeItPendingPaymentRow invoice={row as InvoiceRow} key={row.productionOrderId} />
            );
        } else {
            return null;
        }
    };

    const handleRowFilter = (filteredRows) => {
        setFilteredOrders(filteredRows);
    };

    return (
        <Grid className={classes.tableWrapper}>
            <div className={classes.searchBar}>
                <SummarySearchBar
                    rows={formattedInvoices}
                    columns={columns}
                    onRowFilter={handleRowFilter}
                />
            </div>
            {formattedInvoices && (
                <StandardTable
                    gridName="Pending Payments"
                    columns={columns}
                    lines={filteredOrders}
                    rowComponent={PendingPaymentsRow}
                    searchText={false}
                />
            )}
        </Grid>
    );
};
export default MakeItPendingPaymentTab;
