import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles, Typography, TableRow, TableCell } from '@material-ui/core';
import { ProductToShip } from '../../../../store/reducers/shipping-dashboard';
import { Flags, FlagTypes } from '../../../../utility/helpers/feature-flag';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { EditShipmentsState } from '../../../../store/reducers/edit-shipments';
import { useDispatch } from 'react-redux';
import { updateExpandableLoad } from '../../../../store/actions/edit-shipments';
import ShipmentExpandableSection from '../../../reusable/molecules/ShipmentExpandableSection';
import { getEachesConversion } from '../../../../utility/helpers/shipment-helpers';
import { blackWeight } from '../../../../themes/globalConstants';

interface Props {
    shipmentId?: string;
    item: ProductToShip;
}

const useStyles = makeStyles((theme) => ({
    expand: {
        transform: 'rotate(0)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: 'rotate(180deg)'
    },
    bold: {
        fontWeight: blackWeight
    }
}));

export default function ShipmentRow({ shipmentId, item }: Props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [active, setActive] = useState(false);
    const { expandableLoads } = useTypedSelector<EditShipmentsState>(
        (state) => state.editShipments
    );
    const [releaseNumber, setReleaseNumber] = useState<string>('');
    const [fillerLine, setFillerLine] = useState<string>('');
    const [referenceNumber, setReferenceNumber] = useState<string>('');
    const [deliveryInstructions, setDeliveryInstructions] = useState<string>('');
    const [showExpansionSection, setShowExpansionSection] = useState<boolean>(true);

    useEffect(() => {
        if (expandableLoads) {
            const expandableLoad = expandableLoads?.find((l) => l.loadId === item.editLoadId);
            if (expandableLoad) {
                setActive(expandableLoad.expanded);
            } else {
                if (shipmentId && item) {
                    dispatch(
                        updateExpandableLoad({
                            shipmentId: shipmentId,
                            expanded: true,
                            loadId: item.editLoadId
                        })
                    );
                }
            }
        }
    }, [dispatch, expandableLoads, item, shipmentId]);

    const toggleExpand = () => {
        if (showExpansionSection) {
            dispatch(
                updateExpandableLoad({
                    shipmentId: shipmentId,
                    expanded: !active,
                    loadId: item.editLoadId
                })
            );
        }
    };
    useEffect(() => {
        if (item.releaseNumber !== undefined) {
            setReleaseNumber(item.releaseNumber);
        }
        if (item.fillerLine !== undefined) {
            setFillerLine(item.fillerLine);
        }
        if (item.referenceNumber !== undefined) {
            setReferenceNumber(item.referenceNumber);
        }
        if (item.deliveryInstructions !== undefined) {
            setDeliveryInstructions(item.deliveryInstructions);
        }
        if (item) {
            setShowExpansionSection(
                !!(
                    item.releaseNumber ||
                    item.fillerLine ||
                    item.reference ||
                    item.deliveryInstructions
                )
            );
        }
    }, [item]);

    useTranslation()
    
    return (
        <>
            <TableRow key={item.loadId}>
                <TableCell align="center">
                    <Typography data-testid="load-seq">{item.sequence}</Typography>
                </TableCell>
                <TableCell>
                    <Typography data-testid="customer-product-id">
                        {item.customerProductId || (
                            <Trans
                                i18nKey="idNotAvailable"
                            >
                                ID not available
                            </Trans>
                        )}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography data-testid="display-id">{item.displayId}</Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="subtitle2" data-testid="display-name">
                        {item.displayName}
                    </Typography>
                    <Typography data-testid="description">{item.description}</Typography>
                </TableCell>
                <TableCell>
                    <Typography data-testid="pallet-qty" className={classes.bold}>
                        {' '}
                        {`${item.palletQuantity} PL`}
                    </Typography>
                    <Typography data-testid="eaches-qty" className="helper-text">
                        {item.palletQuantity &&
                            item.quantityPerPallet &&
                            getEachesConversion(item.palletQuantity, item.quantityPerPallet, true)}
                    </Typography>
                </TableCell>
                <Flags authorizedFlags={[FlagTypes.Coke]}>
                    <TableCell>
                        <Typography data-testid="purchase-order-number">
                            {' '}
                            {item.purchaseOrderNumber}
                        </Typography>
                    </TableCell>
                </Flags>
                <TableCell>
                    <ExpandMoreIcon
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: active && showExpansionSection
                        })}
                        data-testid="expand-button"
                        onClick={toggleExpand}
                        color={showExpansionSection ? 'primary' : 'disabled'}
                    />
                </TableCell>
            </TableRow>
            {showExpansionSection ? (
                <ShipmentExpandableSection
                    active={active}
                    releaseNumber={releaseNumber}
                    reference={referenceNumber}
                    deliveryInstructions={deliveryInstructions}
                    fillerLine={fillerLine}
                    readOnly={true}
                    handleUpdateItem={() => {}}
                    updateOptionalField={() => {}}
                    handleSelectChange={() => {}}
                />
            ) : null}
        </>
    );
}
