import React, { ReactElement, ReactNode, useState, useEffect } from 'react';
import Header from '../reusable/molecules/Header';
import Footer from '../reusable/molecules/Footer';
import { Section } from '../../store/reducers/glp';
import { makeStyles, AppBar, useTheme, useMediaQuery, Container } from '@material-ui/core';
import MobileHeader from '../reusable/molecules/MobileHeader';
import MobileFooter from '../reusable/molecules/MobileFooter';
import { ltBlueGrey_15, containerMaxWidth } from '../../themes/globalConstants';
import ProcessingPageBanner from '../reusable/molecules/ProcessingPageBanner';
import ActionFooter from '../reusable/molecules/ActionFooter';
import LoadingIndicator from '../reusable/atoms/LoadingIndicator';
import { AuthState } from '../../store/reducers/auth';
import { CustomerContextState } from '../../store/reducers/customer-context';
import { useTypedSelector } from '../../store/reducers/reducer';
import { Activity, getSecurityLevel, SecurityLevel } from '../../utility/auth/useSecurity';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getShipToAccounts } from '../../store/actions/customer-context';
import { loadState } from '../../utility/helpers/sessionStorage';
import { clearSecurityLevel, setSecurityLevel } from '../../store/actions/security-level';

interface Props {
    key?: any;
    title?: string;
    children: ReactNode;
    activity: Activity;
    restrictToSecurityLevel?: SecurityLevel;
    banner?: Section;
    actionFooter?: any;
    loading?: boolean;
    shipTos?: boolean;
}

const useStyles = makeStyles((theme) => ({
    //Sets the blue background to the entire width of the screen
    root: {
        width: '100%',
        height: '100%',
        backgroundColor: ltBlueGrey_15
    },
    mainContainer: {
        maxWidth: containerMaxWidth,
        minHeight: 800
    }
}));

export function DataValidationDisplayTemplate({
    children,
    activity,
    title,
    banner,
    actionFooter,
    loading,
    restrictToSecurityLevel,
    shipTos = true
}: Props): ReactElement {
    const classes = useStyles();
    const theme = useTheme();
    const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const smScreens = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useDispatch();
    const { selectedAccountId, shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const securityContext = {
        accountId: selectedAccountId as string
    };
    const auth = useTypedSelector<AuthState>((state) => state.auth);
    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);
    const [hasPermission, setHasPermission] = useState(false);
    const [permissionsCheck, setPermissionsCheck] = useState(false);

    useEffect(() => {
        return () => {
            dispatch(clearSecurityLevel());
        };
    }, []);

    useEffect(() => {
        if (shipTos && permissions?.length && !shipToAccounts?.length) {
            dispatch(getShipToAccounts());
        }
    }, [dispatch, permissions, shipToAccounts, shipTos]);

    useEffect(() => {
        if (permissions && selectedAccountId) {
            if (permissions.length) {
                // If no account ID, see if one is in local storage
                if (!securityContext.accountId) {
                    var accountId = loadState('selectedAccountId');

                    if (accountId) {
                        securityContext.accountId = accountId;
                    }
                }
                const personas = auth.permissions
                    ?.map((permission) => permission.personaId)
                    .join(', ');
                const currentSecurityLevel = getSecurityLevel(activity, auth, securityContext);

                dispatch(setSecurityLevel(activity, currentSecurityLevel, personas));
                let authorized = currentSecurityLevel !== SecurityLevel.None;

                if (restrictToSecurityLevel) {
                    authorized = currentSecurityLevel === restrictToSecurityLevel;
                    setHasPermission(authorized);
                } else {
                    setHasPermission(authorized);
                }
            }
            // Permissions are checked when accounts are loaded if ShipTos are required
            if ((shipTos && shipToAccounts?.length) || !shipTos) {
                setPermissionsCheck(true);
            }
        } else {
            dispatch(clearSecurityLevel());
        }
    }, [dispatch, permissions, selectedAccountId]);

    return (
        <div className={classes.root}>
            <AppBar position="sticky">
                {xsScreen ? <MobileHeader title={title} /> : <Header title={title} />}
            </AppBar>
            {banner && (
                <section>
                    <ProcessingPageBanner {...banner} />
                </section>
            )}
            {loading ? <LoadingIndicator /> : null}
            {permissionsCheck ? (
                hasPermission ? (
                    <Container className={classes.mainContainer}>
                        <div>{children}</div>
                    </Container>
                ) : (
                    <Redirect to="/unauthorized" />
                )
            ) : null}
            {actionFooter && <ActionFooter {...actionFooter} />}
            {smScreens ? <MobileFooter /> : <Footer />}
        </div>
    );
}
