import { AxiosResponse } from 'axios';
import { invalidAPIDate } from '../../themes/globalConstants';
import {
    Activity,
    filterShipToIdsByPermission,
    SecurityLevel,
    ValidationMethod
} from '../../utility/auth/useSecurity';
import { ErrorStatus, findDistinctObjects } from '../../utility/helpers/axios-helpers';
import { getFirstAvailableDate } from '../../utility/helpers/date-helpers';
import {
    constructPDFCanDescription,
    constructPDFEndDescription,
    isProductEndType
} from '../../utility/helpers/order-helpers';
import {
    createProductRequestObj,
    getMatchingProductInformation,
    getMoqFeeFloor,
    getProductLeadTime
} from '../../utility/helpers/production-order-helpers';
import EndService from '../../utility/services/end-service';
import OrdersService, {
    OrderType,
    ProductType,
    QuantityType,
    BulkProductRequest
} from '../../utility/services/orders-service';
import ProductService, {
    LeadTimesResponse,
    ProductSearchResponse
} from '../../utility/services/product-service';
import { BulkUploadTemplateRequest, TemplateMapping } from '../reducers/bulk-upload';
import {
    MakeItBulkLineItem,
    MakeItBulkOrder,
    MakeItLineError
} from '../reducers/makeit-bulk-upload';
import { End } from '../reducers/product-end-details';
import { Product } from '../reducers/product-portfolio';
import {
    MAKEIT_BULK_UPLOAD_LOADING,
    MAKEIT_BULK_UPLOAD_LOADED,
    MAKEIT_BULK_UPLOAD_ERROR,
    MAKEIT_BULK_UPLOAD_RESET_STATE,
    MAKEIT_BULK_UPLOAD_GET_PRODUCTS,
    MAKEIT_BULK_SUBMIT_REVIEW,
    MAKEIT_BULK_UPLOAD_FILE,
    MAKEIT_BULK_UPDATE_PALLET_QUANTITY,
    MAKEIT_BULK_UPDATED_REQUESTED_DATE,
    MAKEIT_BULK_UPDATED_PRODUCT_SKU,
    MAKEIT_BULK_UPDATED_LINE_ITEM,
    MAKEIT_BULK_DELETE_ORDER,
    MAKEIT_BULK_UNDO_DELETE_ORDER,
    MAKEIT_BULK_UPLOAD_SUBMIT,
    MAKEIT_BULK_MARK_ORDER_REMOVED,
    MAKEIT_BULK_DELETE_PRODUCT,
    MAKEIT_BULK_UPLOAD_REMOVE_ORDER,
    MAKEIT_BULK_UPLOAD_REFRESH_STATE
} from './action-types';
import { getOrderShipToIds } from './bulk-upload';
import { getProductLeadTimes } from './makeit-dashboard';

export interface EndsForShipToId {
    shipToId: string;
    ends: End[];
}

export interface ProductInformationResponse {
    type?: string;
    error?: any;
    products?: MakeItBulkLineItem[];
}

export const loadBulkUploadMakeIt = (userName: string) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: MAKEIT_BULK_UPLOAD_LOADING });
        OrdersService.getBulkUpload(state, userName, OrderType.Production)
            .then((response) => {
                dispatch({
                    type: MAKEIT_BULK_UPLOAD_LOADED,
                    mappings: response.data.mappings,
                    quantityType: response.data.quantityType
                });
            })
            .catch((error) => {
                if (error.response.status === ErrorStatus.NotFound) {
                    dispatch({ type: MAKEIT_BULK_UPLOAD_LOADED, mappings: [] });
                } else {
                    dispatch({
                        type: MAKEIT_BULK_UPLOAD_ERROR,
                        error
                    });
                }
            });
    };
};

export const createMakeItBulkUploadTemplate = (
    userName: string,
    mappings: TemplateMapping[],
    quantityType: QuantityType
) => {
    return (dispatch, getState) => {
        const { auth } = getState();
        dispatch({ type: MAKEIT_BULK_UPLOAD_LOADING });
        const requestObj: BulkUploadTemplateRequest = {
            userName: userName,
            orderType: OrderType.Production,
            mappings: mappings,
            quantityType: quantityType
        };
        OrdersService.createBulkUploadDeliveryTemplate(auth.accessToken, requestObj)
            .then((response) => {
                dispatch({
                    type: MAKEIT_BULK_UPLOAD_LOADED,
                    mappings: response.data.mappings,
                    quantityType: response.data.quantityType
                });
            })
            .catch((error) => {
                dispatch({
                    type: MAKEIT_BULK_UPLOAD_ERROR,
                    error
                });
            });
    };
};

export const updateMakeItBulkUploadTemplate = (
    userName: string,
    mappings: TemplateMapping[],
    quantityType: QuantityType
) => {
    return (dispatch, getState) => {
        const { auth } = getState();
        dispatch({ type: MAKEIT_BULK_UPLOAD_LOADING });
        const requestObj: BulkUploadTemplateRequest = {
            userName: userName,
            orderType: OrderType.Production,
            mappings: mappings,
            quantityType: quantityType
        };
        OrdersService.updateBulkUploadDeliveryTemplate(auth.accessToken, requestObj)
            .then((response) => {
                dispatch({
                    type: MAKEIT_BULK_UPLOAD_LOADED,
                    mappings: response.data.mappings,
                    quantityType: response.data.quantityType
                });
            })
            .catch((error) => {
                dispatch({
                    type: MAKEIT_BULK_UPLOAD_ERROR,
                    error
                });
            });
    };
};

export const resetMakeItBulkState = () => {
    return (dispatch) => {
        dispatch({
            type: MAKEIT_BULK_UPLOAD_RESET_STATE
        });
    };
};

export const refreshMakeItBulkState = () => {
    return (dispatch) => {
        dispatch({ type: MAKEIT_BULK_UPLOAD_REFRESH_STATE });
    };
};

export const removeMakeItBulkOrder = () => {
    return (dispatch) => {
        dispatch({
            type: MAKEIT_BULK_UPLOAD_REMOVE_ORDER
        });
    };
};

// pass loadLeadTimes = false if you don't need leadTimes data on products to save time and API calls
export const loadMakeItBulkProducts = (
    shipToIds: string[],
    loadLeadTimes: boolean = true,
    isPlanItFlow: boolean = false,
    activeProductsOnly: boolean = true
) => {
    return (dispatch, getState) => {
        dispatch({
            type: MAKEIT_BULK_UPLOAD_LOADING
        });
        getProductInformation(
            getState,
            shipToIds,
            loadLeadTimes,
            isPlanItFlow,
            activeProductsOnly
        ).then((response) => {
            if (response && response.type === 'success') {
                //Save product information to state
                dispatch({
                    type: MAKEIT_BULK_UPLOAD_GET_PRODUCTS,
                    products: response.products
                });
            } else if (response && response.type === 'error') {
                // Save error to state
                dispatch({
                    type: MAKEIT_BULK_UPLOAD_ERROR,
                    error: response.error
                });
            }
        });
    };
};

const getMultipleProductsForShipToIds = (state: any, requestObj, shipToIds: string[]) => {
    const promises: Promise<AxiosResponse<ProductSearchResponse>>[] = [];
    shipToIds.forEach((shipToId) => {
        promises.push(
            ProductService.getMultipleProducts(state, {
                ...requestObj,
                accountId: shipToId
            }).then((response) => {
                if (response.data.products) {
                    response.data.products.forEach((product) => {
                        product.requestShipToId = shipToId;
                    });
                }
                return response;
            })
        );
    });
    return Promise.all(promises);
};

const getEndsForShipToIds = (state: any, shipToIds: string[]): EndsForShipToId[] => {
    const apiEnds: EndsForShipToId[] = [];
    shipToIds.forEach((shipToId) => {
        EndService.getEnds(state, shipToId).then((response) => {
            apiEnds.push({
                shipToId: shipToId,
                ends: response.data.ends
            });
        });
    });
    return apiEnds;
};

/**
 * Combines product data with data from the line.
 * @param line
 * @param matchingProduct
 */
function combineProductData(line, matchingProduct) {
    return { ...line, ...matchingProduct };
}

export const reviewMakeItBulkUpload = (userName: string, file: File | File[]) => {
    return (dispatch, getState) => {
        const state = getState();

        dispatch({ type: MAKEIT_BULK_UPLOAD_LOADING });

        OrdersService.reviewMakeItBulkUpload(state, userName, false, file)
            .then((response) => {
                let orders = [] as MakeItBulkOrder[];
                let errors = [] as MakeItLineError[];

                if (response.data.lineErrors) {
                    errors = response.data.lineErrors;
                }

                if (response.data.orders) {
                    orders = (response.data.orders as Array<MakeItBulkOrder>).map((order) => {
                        order.lines = order.lines.reduce((prev, curr) => {
                            curr.customerProductionOrderId = order.customerProductionOrderId;
                            prev.push(curr);
                            return prev;
                        }, [] as Array<MakeItBulkLineItem>);
                        return order;
                    });
                    const shipToIds = getOrderShipToIds(orders);
                    if (shipToIds && shipToIds.length) {
                        dispatch(getProductInformationForMakeItOrders(shipToIds, orders, errors));
                    } else {
                        dispatch({
                            type: MAKEIT_BULK_SUBMIT_REVIEW,
                            orders: orders,
                            lineErrors: errors
                        });
                    }
                } else {
                    dispatch({
                        type: MAKEIT_BULK_SUBMIT_REVIEW,
                        orders: orders,
                        lineErrors: errors
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: MAKEIT_BULK_UPLOAD_ERROR,
                    error
                });
            });
    };
};

export const getProductInformationForMakeItOrders = (
    shipToIds: number[],
    orders: MakeItBulkOrder[],
    errors: MakeItLineError[]
) => {
    return (dispatch, getState) => {
        let shipToIdsString = shipToIds.map((shipToId) => shipToId.toString());

        getProductInformation(getState, shipToIdsString).then((response) => {
            if (response && response.type === 'success') {
                //Save product information to state
                dispatch({
                    type: MAKEIT_BULK_UPLOAD_GET_PRODUCTS,
                    products: response.products
                });

                if (response.products) {
                    // Format order information
                    bulkUploadResponseHelper(orders, response.products);
                }

                // Save order information to state
                dispatch({
                    type: MAKEIT_BULK_SUBMIT_REVIEW,
                    orders: orders,
                    lineErrors: errors
                });
            } else if (response && response.type === 'error') {
                // Save error to state
                dispatch({
                    type: MAKEIT_BULK_UPLOAD_ERROR,
                    error: response.error
                });
            }
        });
    };
};

export const submitMakeItBulkUpload = (orders: MakeItBulkOrder[]) => {
    return (dispatch, getState) => {
        const state = getState();
        const products = state.bulkUploadMakeItState.products as MakeItBulkLineItem[];

        dispatch({ type: MAKEIT_BULK_UPLOAD_LOADING });

        OrdersService.submitMakeItBulkUpload(state, orders)
            .then((response) => {
                let submittedOrders: MakeItBulkOrder[] = [];
                if (response.data?.orders) {
                    submittedOrders = response.data.orders;
                    bulkUploadResponseHelper(submittedOrders, products);
                }

                dispatch({
                    type: MAKEIT_BULK_UPLOAD_SUBMIT,
                    orders: submittedOrders
                });
            })
            .catch((error) => {
                dispatch({
                    type: MAKEIT_BULK_UPLOAD_ERROR,
                    error
                });
            });
    };
};

const bulkUploadResponseHelper = (orders: MakeItBulkOrder[], products: MakeItBulkLineItem[]) => {
    orders.forEach((order) => {
        order.lines.forEach((line: MakeItBulkLineItem, index) => {
            order.lines[index].shipToId = order.shipToId;
            if (line.userSuppliedProductId || line.productSku) {
                let idToMatch = line.candidateProductSkus
                    ? line.candidateProductSkus[0]
                    : line.productSku;
                let matchingProduct = products.find((product) => product.productSku === idToMatch);

                if (matchingProduct) {
                    order.lines[index].firstAvailableDate = getFirstAvailableDate(
                        matchingProduct.leadTimeWeeks
                    );

                    if (matchingProduct.minimumOrderQuantity && line.palletQuantity) {
                        const minValue = matchingProduct.moqFees
                            ? getMoqFeeFloor(matchingProduct.moqFees)
                            : matchingProduct.minimumOrderQuantity;
                        line.withinRange =
                            minValue <= line.palletQuantity &&
                            line.palletQuantity < matchingProduct.minimumOrderQuantity;
                        line.minimumMet =
                            line.palletQuantity >= matchingProduct.minimumOrderQuantity;
                    } else {
                        line.minimumMet = line.palletQuantity !== null && line.palletQuantity !== 0;
                    }

                    // Original Quantity is only there on duplicate product ids when eaches is selected
                    if (line.originalQuantity) {
                        line.eachesQuantity = parseInt(line.originalQuantity);
                        line.palletQuantity = Math.ceil(
                            line.eachesQuantity / matchingProduct.quantityPerPallet!
                        );
                    }
                    // Initially set pallets rounded warning if dup product id (og quantity)
                    line.palletsRounded =
                        !!line.originalQuantity ||
                        (!!line.eachesQuantity &&
                            !!line.palletQuantity &&
                            line.eachesQuantity % matchingProduct.quantityPerPallet! !== 0);

                    // Combine product data from both APIs
                    if (line.splitOrder) {
                        order.lines[index] = combineProductData(line, matchingProduct);
                        line.childLines?.forEach((childLine, childIndex) => {
                            line.childLines![childIndex].firstAvailableDate =
                                line.firstAvailableDate;
                            line.childLines![childIndex] = combineProductData(
                                childLine,
                                matchingProduct
                            );
                        });
                    } else {
                        order.lines[index] = combineProductData(line, matchingProduct);
                    }
                }
                const canBottleDesc = constructPDFCanDescription(line);
                const endDesc = constructPDFEndDescription(line);
                const isEndType = isProductEndType(line.productType);
                line.displayId = line.productSku;
                line.displayName = line.productName;
                line.description = isEndType ? endDesc : canBottleDesc;
                line.graphicIdAndVersion = line.productGraphicId;
            }

            // If a line comes back with subrows (aka split rows) then an invalid date value is sent back from the API
            // Default the parent row requested date to the firstAvailable date per current patterns
            if (order.lines[index].requestedDate === invalidAPIDate) {
                order.lines[index].requestedDate = order.lines[index].firstAvailableDate;
            }
        });
    });
    bulkUploadUpdateOrderQuantities(orders);
};

export const bulkUploadUpdateOrderQuantities = (orders: MakeItBulkOrder[]) => {
    orders.forEach((order, orderIndex) => {
        let canQuantityPallets = 0;
        let canQuantitySKUs = 0;
        let canQuantityEaches = 0;
        let endQuantityPallets = 0;
        let endQuantitySKUs = 0;
        let endQuantityEaches = 0;
        order.lines.forEach((line: MakeItBulkLineItem) => {
            if (line.userSuppliedProductId || line.productSku) {
                if (line.type === ProductType.Cans && line.palletQuantity) {
                    canQuantitySKUs++;
                    canQuantityPallets += line.palletQuantity;
                    canQuantityEaches += line.palletQuantity * line.quantityPerPallet!;
                } else if (line.type === ProductType.Ends && line.palletQuantity) {
                    endQuantitySKUs++;
                    endQuantityPallets += line.palletQuantity;
                    endQuantityEaches += line.palletQuantity * line.quantityPerPallet!;
                }
            }
        });

        orders[orderIndex] = {
            ...orders[orderIndex],
            canQuantityPallets,
            canQuantitySKUs,
            canQuantityEaches,
            endQuantityPallets,
            endQuantitySKUs,
            endQuantityEaches
        };
    });
};

export const uploadMakeItFile = (file?: File) => {
    return (dispatch) => {
        if (file) {
            dispatch({ type: MAKEIT_BULK_UPLOAD_FILE, file: file });
        } else {
            dispatch({ type: MAKEIT_BULK_UPLOAD_FILE, file: undefined });
        }
    };
};

export function markProductionOrderRemoved(customerProductionOrderId: string, shipToId: number) {
    return (dispatch, getState) => {
        const state = getState();
        const orders = state.bulkUploadMakeItState.orders as MakeItBulkOrder[];
        const order = orders!.find(
            (order) =>
                order.customerProductionOrderId === customerProductionOrderId! &&
                order.shipToId === shipToId
        );
        order!.markedRemoved = true;
        dispatch({ type: MAKEIT_BULK_MARK_ORDER_REMOVED, orders });
    };
}

export function deleteProductionOrder(customerProductionOrderId: string, shipToId: number) {
    return (dispatch, getState) => {
        const state = getState();
        let orders = state.bulkUploadMakeItState.orders as MakeItBulkOrder[];
        orders = orders.filter(
            (order) =>
                order.customerProductionOrderId !== customerProductionOrderId ||
                order.shipToId !== shipToId
        );
        dispatch({ type: MAKEIT_BULK_DELETE_ORDER, orders });
    };
}

/**
 * Removes the product from a product order.  Optionally can remove a subrow (split) at the provided index.
 * @param customerProductionOrderId
 * @param productSku
 * @param subrowOptions Optional.  Provides an index at which a split subrow is to be removed.
 */
export function deleteProductFromOrder(
    customerProductionOrderId: string,
    productSku: string,
    shipToId: number,
    subrowOptions?: { childLineIndex: number }
) {
    return (dispatch, getState) => {
        const state = getState();
        const orders = state.bulkUploadMakeItState.orders as MakeItBulkOrder[];
        const order = orders!.find(
            (order) =>
                order.customerProductionOrderId === customerProductionOrderId! &&
                order.shipToId === shipToId
        ) as MakeItBulkOrder;
        if (subrowOptions) {
            const line = order.lines.find(
                (line) => line.productSku === productSku
            ) as MakeItBulkLineItem;
            handleDeleteChildLine(line, subrowOptions.childLineIndex);
        } else {
            order.lines = order.lines.filter((line) => line.productSku !== productSku);
        }
        bulkUploadUpdateOrderQuantities(orders);
        dispatch({ type: MAKEIT_BULK_DELETE_PRODUCT, orders });
    };
}

function handleDeleteChildLine(line: MakeItBulkLineItem, childLineIndex: number) {
    line.childLines?.splice(childLineIndex, 1);

    // If the line has only one child line, roll up the child line into the parent.
    if (line.childLines?.length === 1) {
        line.childLines = [];
        line.palletQuantity = 0;
    }
}

export function undoMarkProductOrderRemoved(customerProductionOrderId: string, shipToId: number) {
    return (dispatch, getState) => {
        const state = getState();
        const orders = state.bulkUploadMakeItState.orders as MakeItBulkOrder[];
        const order = orders!.find(
            (order) =>
                order.customerProductionOrderId === customerProductionOrderId! &&
                order.shipToId === shipToId
        );
        order!.markedRemoved = false;
        bulkUploadUpdateOrderQuantities(orders);
        dispatch({ type: MAKEIT_BULK_UNDO_DELETE_ORDER, orders });
    };
}

export function updatePalletQuantity(
    customerProductionOrderId: string,
    productSku: string,
    item: MakeItBulkLineItem
) {
    return (dispatch, getState) => {
        const state = getState();
        let ordersToUpdate = state.bulkUploadMakeItState.orders as MakeItBulkOrder[];
        if (ordersToUpdate) {
            const orderIndex = ordersToUpdate.findIndex(
                (order) =>
                    order.customerProductionOrderId === customerProductionOrderId &&
                    order.shipToId === item.shipToId
            );
            const lineIndex = ordersToUpdate[orderIndex].lines.findIndex(
                (line) => line.productSku === productSku
            );
            if (lineIndex !== -1) {
                ordersToUpdate[orderIndex].lines.splice(lineIndex, 1, item);
            }
            bulkUploadUpdateOrderQuantities(ordersToUpdate);
            dispatch({
                type: MAKEIT_BULK_UPDATE_PALLET_QUANTITY,
                orders: ordersToUpdate
            });
        }
    };
}

export function updateMakeItBulkItemRequestedDate(
    customerProductionOrderId?: string,
    productSku?: string,
    requestedDate?: string,
    shipToId?: number
) {
    return (dispatch, getState) => {
        const state = getState();
        const orders = state.bulkUploadMakeItState.orders as MakeItBulkOrder[];
        if (customerProductionOrderId && productSku && requestedDate && shipToId) {
            const lineItem = orders!
                .find(
                    (order) =>
                        order.customerProductionOrderId === customerProductionOrderId &&
                        order.shipToId === shipToId
                )
                ?.lines.find((line) => line.productSku === productSku);
            if (lineItem) {
                lineItem.requestedDate = requestedDate;
            }
        }
        dispatch({
            type: MAKEIT_BULK_UPDATED_REQUESTED_DATE,
            orders: orders
        });
    };
}

export function updateMakeItBulkItemProductSku(
    customerProductionOrderId?: string,
    productId?: string,
    product?: MakeItBulkLineItem,
    shipToId?: number
) {
    return (dispatch, getState) => {
        const state = getState();
        const orders = state.bulkUploadMakeItState.orders as MakeItBulkOrder[];
        if (customerProductionOrderId && product?.productSku && productId && shipToId) {
            const lineItem = orders!
                .find(
                    (order) =>
                        order.customerProductionOrderId === customerProductionOrderId &&
                        order.shipToId === shipToId
                )
                ?.lines.find((line) => line.customerProductId === productId);
            if (lineItem) {
                lineItem.productSku = product?.productSku;
                lineItem.graphicId = product?.graphicId;
                lineItem.graphicIdAndVersion = product?.graphicIdAndVersion;
                lineItem.displayName = product?.displayName;
                lineItem.quantityPerPallet = product?.quantityPerPallet;
                lineItem.isCustomerProductIdDistinct = true;
                if (lineItem.originalQuantity && product.quantityPerPallet) {
                    const originalQuantity = parseInt(lineItem.originalQuantity);
                    lineItem.palletQuantity = Math.ceil(
                        originalQuantity / product.quantityPerPallet
                    );
                    lineItem.palletsRounded = originalQuantity % product.quantityPerPallet !== 0;
                }
            }
        }
        dispatch({
            type: MAKEIT_BULK_UPDATED_PRODUCT_SKU,
            orders: orders
        });
    };
}

export function handleChildLineUpdates(item: MakeItBulkLineItem) {
    return (dispatch, getState) => {
        const state = getState();
        const orders = state.bulkUploadMakeItState.orders as MakeItBulkOrder[];
        if (item.customerProductionOrderId && item.productSku) {
            let lineItem = orders!
                .find(
                    (order) =>
                        order.customerProductionOrderId === item.customerProductionOrderId &&
                        order.shipToId === item.shipToId
                )
                ?.lines.find((line) => line.productSku === item.productSku);
            if (lineItem) {
                lineItem.childLines = item.childLines;
                lineItem.splitOrder = true;
                lineItem.palletQuantity = item.palletQuantity;
                lineItem.withinRange = item.withinRange;
                lineItem.minimumMet = item.minimumMet;
                lineItem.requestedDate = item.requestedDate;
                lineItem.palletsRounded = false;
            }
        }
        bulkUploadUpdateOrderQuantities(orders);
        dispatch({
            type: MAKEIT_BULK_UPDATED_LINE_ITEM,
            orders: orders
        });
    };
}

export function getProductInformation(
    state,
    shipToIds: string[],
    loadLeadTimes: boolean = true,
    isPlanItFlow: boolean = false,
    activeProductsOnly: boolean = true
) {
    let products: any[] = [];
    let shipToIdsNumber = shipToIds.map((shipToId) => parseInt(shipToId));
    const endsForShipToIds = getEndsForShipToIds(state, shipToIds);
    const regionCode = state().regionCulture.regionCode;

    const requestObj: BulkProductRequest = {
        shipToIds: filterShipToIdsByPermission(
            state().auth,
            shipToIdsNumber,
            Activity.NewOpenProductionOrders,
            SecurityLevel.View
        ),
        activeOnly: activeProductsOnly
    };

    // Since this is reused across both Make It and Plan It, adding a check to make sure
    // we're accounting for Plan It Only persona for ship to accounts
    if (isPlanItFlow) {
        let shipToIds = new Set<number>();
        const planItShipTos = filterShipToIdsByPermission(
            state().auth,
            shipToIdsNumber,
            Activity.PlanItSummary,
            SecurityLevel.View,
            ValidationMethod.byAccess
        );

        // if there are accounts with Plan It personas, filter these out to prevent duplicates
        if (planItShipTos?.length > 0) {
            planItShipTos.forEach((shipToId) => shipToIds.add(shipToId));
            requestObj.shipToIds = Array.from(shipToIds);
        }
    }
    return OrdersService.getBulkProducts(state().auth.accessToken, requestObj)
        .then((response) => {
            products = findDistinctObjects(response.data.products, 'productSku');
            if (products.length > 0) {
                let productRequest = createProductRequestObj(products, undefined, shipToIds);
                return getMultipleProductsForShipToIds(state, productRequest, shipToIds);
            }
        })
        .then((responses) => {
            const responseData: {
                destination: Number;
                products: Product[];
                ends: End[] | undefined;
            }[] = [];
            responses?.forEach((response, index) => {
                responseData.push({
                    destination: Number(shipToIds[index]),
                    products: response.data.products,
                    ends: endsForShipToIds.find((e) => e.shipToId === shipToIds[index])?.ends
                });
            });

            if (responseData) {
                // Loop over products from order-api to find matches
                products.forEach((product) => {
                    const apiProducts = responseData.find((r) =>
                        product.destinations.map(({ shipToId }) => shipToId).includes(r.destination)
                    )?.products;

                    const apiEnds = responseData.find((r) =>
                        product.destinations.map(({ shipToId }) => shipToId).includes(r.destination)
                    )?.ends;

                    if (apiProducts) {
                        getMatchingProductInformation(
                            apiProducts,
                            product,
                            product.size,
                            product.type,
                            product.shape,
                            apiEnds,
                            regionCode
                        );
                    }
                });
            }

            return Promise.resolve(products);
        })
        .then(async (products) => {
            if (loadLeadTimes) {
                const leadTimes = await getProductLeadTimes(products, state());
                if (leadTimes) {
                    products.forEach((product) => {
                        product.leadTimeWeeks = getProductLeadTime(
                            product,
                            leadTimes as LeadTimesResponse[]
                        );
                    });
                }
            }
            return { type: 'success', products: products } as ProductInformationResponse;
        })
        .catch((error) => {
            return { type: 'error', error: error } as ProductInformationResponse;
        });
}
