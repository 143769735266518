import React, { useState } from 'react';
import { Grid, Typography, Button, makeStyles } from '@material-ui/core';
import {
    boldWeight,
    small,
    ltBlueGrey,
    ballDrkBlue,
    blackWeight
} from '../../../themes/globalConstants';
import { Trans, useTranslation } from 'react-i18next';
import Modal from './Modal';
import { OrderProductTableRow } from '../../../store/reducers/orders-dashboard';
import { paleGrey } from './../../../themes/globalConstants';
import ProductStatusToggle from './ProductStatusToggle';
import ProductStatusToggleConfirmation from './ProductStatusToggleConfirmation';
import SmallProductInfoSummaryTable from './SmallProductInfoSummaryTable';
import moment, { Moment } from 'moment';
import DayRangePicker from '../atoms/DayRangePicker';

interface Props {
    product: OrderProductTableRow;
    onClose?: (projectedOrderDate?: Moment) => void;
}

const useStyles = makeStyles((theme) => ({
    modal: {
        '& .MuiPaper-root': {
            maxWidth: '55em'
        },
        '& .MuiDialog-paperScrollPaper': {
            borderRadius: 'unset',
            overflow: 'hidden'
        },
        '& .MuiDialogTitle-root': {
            justifyContent: 'start'
        },
        '& .MuiDialogContent-root': {
            padding: '1.875em'
        },
        '& .MuiPaper-elevation1': {
            boxShadow: 'none'
        }
    },
    button: {
        height: 40,
        marginTop: 30,
        fontSize: 14,
        fontWeight: boldWeight,
        textTransform: 'uppercase',
        padding: '0 32px'
    },
    cancelButton: {
        marginRight: 20
    },
    confirmationMessageContainer: {
        marginTop: 16,
        '& .MuiTypography-body1': {
            fontWeight: boldWeight
        }
    },
    toggleContainer: {
        paddingLeft: 24
    },
    datepickerHeader: {
        marginTop: 40,
        paddingBottom: 16,
        '& .MuiTypography-body1': {
            fontWeight: boldWeight,
            fontSize: '1em'
        }
    },
    legend: {
        marginBottom: 24,
        padding: 8,
        border: `1px solid ${ltBlueGrey}`,
        borderRadius: 4,
        fontSize: small,
        '& .legendTitle .MuiTypography-body1': {
            textTransform: 'uppercase',
            color: ballDrkBlue,
            fontWeight: blackWeight,
            fontSize: small,
            letterSpacing: 1
        },
        '& .restrictedDayKey': {
            backgroundColor: paleGrey,
            padding: 12,
            borderRadius: 4
        },
        '& .availableDayKey': {
            padding: 10,
            border: `2px solid ${paleGrey}`,
            borderRadius: 4
        }
    }
}));

const ActivateProductModal = (props: Props) => {
    const { t } = useTranslation();
    useTranslation();
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const [checked, setChecked] = useState(false);
    const [toastConfirmed, setToastConfirmed] = useState<undefined | boolean>(undefined);
    const [date, setDate] = useState<Moment>(moment().add(7, 'd').day(1));

    const switchHandler = (checked: boolean) => {
        setToastConfirmed(undefined);
        setChecked(checked);
    };

    const toggleHandler = (toastIsConfirmed: boolean) => {
        setToastConfirmed(toastIsConfirmed);
    };

    const handleCloseModal = () => {
        setOpen(false);
        if (!!props.onClose) {
            props.onClose();
        }
    };

    const handleSubmitModal = () => {
        if (props.onClose) {
            props.onClose(date);
        }
    };

    const isOutsideRange = (date: Moment) => {
        return moment().day(7).isAfter(date);
    };

    const onDateChange = (date: Moment) => {
        setDate(moment(date).day(1));
    };
    return (
        <Modal
            open={open}
            close={handleCloseModal}
            title={t('requestActivation', 'Request Activation')}
            closeIcon={true}
            fullWidth={true}
            className={classes.modal}
        >
            {
                <>
                    <SmallProductInfoSummaryTable
                        product={props.product}
                    ></SmallProductInfoSummaryTable>
                    <Grid container>
                        <Grid
                            item
                            container
                            justify="space-between"
                            xs={6}
                            className={classes.datepickerHeader}
                        >
                            <Typography>
                                {t('selectProjectedOrderDate', 'Select Projected Order Date: ')}
                            </Typography>
                            <Typography>
                                {t('todayWithDate', { today: moment().format('MM/DD/YYYY') })}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}></Grid>
                    </Grid>
                    <Grid container>
                        <Grid container item xs={6}>
                            <Grid item xs={12}>
                                <DayRangePicker
                                    startDate={date}
                                    endDate={moment(date).add(6, 'd')}
                                    isOutsideRange={isOutsideRange}
                                    numMonths={1}
                                    daySize={45}
                                    onDateChange={onDateChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={6}
                            className={classes.toggleContainer}
                            direction="column"
                        >
                            <Grid
                                item
                                container
                                className={classes.legend}
                                justify="space-evenly"
                                alignItems="center"
                            >
                                <Grid item className="legendTitle">
                                    <Typography>{t('legend', 'legend')}</Typography>
                                </Grid>
                                <Grid item className="restrictedDayKey">
                                    <Typography>{t('day', 'Day')}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography>
                                        {t('restrictedDays', 'Restricted Days')}
                                    </Typography>
                                </Grid>
                                <Grid item className="availableDayKey">
                                    <Typography>{t('day', 'Day')}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography>{t('availableDays', 'Available Days')}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <ProductStatusToggle
                                    isActivate={true}
                                    onChange={switchHandler}
                                ></ProductStatusToggle>
                            </Grid>
                            {checked && (
                                <Grid
                                    item
                                    container
                                    className={classes.confirmationMessageContainer}
                                >
                                    <ProductStatusToggleConfirmation
                                        isActivate={true}
                                        onChange={toggleHandler}
                                    ></ProductStatusToggleConfirmation>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container alignItems="flex-end" justify="flex-end">
                        <Grid item className={classes.cancelButton}>
                            <Button
                                type="button"
                                variant="outlined"
                                color="secondary"
                                className={classes.button}
                                onClick={handleCloseModal}
                                data-testid="cancel-btn"
                            >
                                <Trans i18nKey="cancel">Cancel</Trans>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={handleSubmitModal}
                                data-testid="submit-btn"
                                disabled={!toastConfirmed}
                            >
                                <Trans i18nKey="submit">Submit</Trans>
                            </Button>
                        </Grid>
                    </Grid>
                </>
            }
        </Modal>
    );
};

export default ActivateProductModal;
