import React, { useEffect, useState } from 'react';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import clsx from 'clsx';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Grid, makeStyles } from '@material-ui/core';
import Modal from '../../../reusable/molecules/Modal';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import {
    EmeaGraphics,
    Graphics,
    GraphicsDetailStatus,
    Region
} from '../../../../store/reducers/graphic-intake';
import { GraphicDashboardState } from '../../../../store/reducers/graphic-dashboard';
import {
    boldWeight,
    medium,
    blackWeight,
    ballBlue,
    dateOptionInvalidGray
} from '../../../../themes/globalConstants';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { generateKey } from '../../../../utility/helpers/order-helpers';
import GraphicsInProgressRow from './GraphicsInProgressRow';
import GraphicsInProgressColumns from './GraphicsInProgressColumns';
import { InProgressGraphicForTable } from '../models/InProgressGraphicForTable';
import GraphicsStatusHistoryModal from './GraphicsStatusHistoryModal';

const useStyles = makeStyles((theme) => ({
    action: {
        cursor: 'pointer'
    },
    name: {
        fontWeight: boldWeight,
        fontSize: medium,
        letterSpacing: 0.17
    },
    tableWrapper: {
        '& .MuiToolbar-root': {
            padding: 0,
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                },
                '&:first-of-type ': {
                    paddingLeft: 0
                }
            },
            '& > h2': {
                fontSize: '1.5em',
                fontWeight: boldWeight,
                color: theme.palette.secondary.main,
                paddingLeft: '0.625em'
            },
            '& .MuiInputBase-formControl': {
                border: `1px solid ${dateOptionInvalidGray}`,
                borderRadius: '.125em',
                '&:before': {
                    display: 'none'
                },
                '&:after': {
                    display: 'none'
                },
                '& svg': {
                    height: '.875em',
                    width: '.875em',
                    fill: ballBlue
                },
                '& .MuiIconButton-root': {
                    '&:hover': {
                        backgroundColor: 'transparent !important'
                    }
                },
                '& .MuiInput-input': {
                    paddingLeft: '.25em'
                }
            }
        },
        '& .MuiTableSortLabel-root': {
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em',
            width: '99%',
            justifyContent: 'space-between',
            '& .MuiSvgIcon-root': {
                margin: 0
            }
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableCell-head': {
            '&:first-of-type ': {
                width: '25em'
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                '&:nth-child(1)': {
                    width: '35%'
                },
                '&:nth-child(2)': {
                    width: '20%'
                },
                '&:nth-child(3)': {
                    width: '15%'
                },
                '&:nth-child(4)': {
                    width: '15%'
                },
                '&:nth-child(5)': {
                    width: '15%'
                }
            }
        }
    },
    tableWrapperEmea: {
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                '&:nth-child(1)': {
                    width: '30%'
                },
                '&:nth-child(2)': {
                    width: '30%'
                },
                '&:nth-child(3)': {
                    width: '40%'
                }
            }
        }
    }
}));

const GraphicsInProgressTab = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const options = new ToolbarOptions({
        title: t('inProgressGraphics', 'In Progress Graphics'),
        advancePagination: false,
        exportButton: false,
        printButton: false
    });
    const { inProgressGraphics } = useTypedSelector<GraphicDashboardState>(
        (state) => state.graphicDashboard
    );
    const { cultureCode, regionCode } = useTypedSelector<RegionCultureState>(
        (state) => state.regionCulture
    );

    const [columns, setColumns] = useState<any>([]);
    const [graphicsForTable, setGraphicsForTable] = useState<InProgressGraphicForTable[]>([]);
    const [statusHistoryModalOpen, setStatusHistoryModalOpen] = useState<boolean>(false);
    const [selectedStatusHistory, setselectedStatusHistory] = useState<GraphicsDetailStatus[]>([]);

    useEffect(() => {
        setColumns(GraphicsInProgressColumns.getGraphicsInProgressColumns(cultureCode, regionCode));
    }, [cultureCode, regionCode]);

    const handleStatusHistoryLookupOpen = (graphicsId: string | undefined) => {
        if (regionCode === Region.EU && graphicsId) {
            const selectedGraphic = inProgressGraphics.find(
                (graphic) => graphic.graphicsId === graphicsId
            );
            if (selectedGraphic) {
                if ((selectedGraphic as EmeaGraphics).graphicStatusHistory) {
                    setselectedStatusHistory(
                        (selectedGraphic as EmeaGraphics).graphicStatusHistory!
                    );
                } else {
                    setselectedStatusHistory([]);
                }
                setStatusHistoryModalOpen(true);
            }
        }
    };

    const handleStatusHistoryLookupClose = () => {
        setStatusHistoryModalOpen(false);
    };

    useEffect(() => {
        const getSortedGraphics = (): Graphics[] | EmeaGraphics[] => {
            if (regionCode === Region.NA) {
                return inProgressGraphics.sort((a, b) =>
                    moment(a.statusDate).diff(moment(b.statusDate))
                );
            } else if (regionCode === Region.EU) {
                return (inProgressGraphics as EmeaGraphics[]).sort((left, right) => {
                    let leftdate = left.createdDate;
                    let rightdate = right.createdDate;
                    if (left.graphicCurrentStatuses) {
                        const leftlist = left.graphicCurrentStatuses.sort((a, b) =>
                            moment(b.iterationDate).diff(a.iterationDate)
                        );
                        leftdate = leftlist[0].iterationDate;
                    }
                    if (right.graphicCurrentStatuses) {
                        const rightlist = right.graphicCurrentStatuses.sort((a, b) =>
                            moment(b.iterationDate).diff(a.iterationDate)
                        );
                        rightdate = rightlist[0].iterationDate;
                    }

                    return moment(leftdate).diff(moment(rightdate));
                });
            }
            return inProgressGraphics;
        };

        const graphics: InProgressGraphicForTable[] = [];
        const sortedGraphics = getSortedGraphics();
        sortedGraphics.forEach((inProgressGraphic, index) => {
            let graphic: InProgressGraphicForTable;
            graphic = { ...inProgressGraphic };
            if (regionCode === Region.NA) {
                const inProgressGraphicNA = inProgressGraphic as Graphics;
                if (inProgressGraphicNA.displayStatus && inProgressGraphicNA.statusDate) {
                    graphic.statuses = [];
                    graphic.statuses.push({
                        displayStatus: inProgressGraphicNA.displayStatus,
                        iterationDate: inProgressGraphicNA.statusDate
                    });
                }
                graphic.searchableStatus = inProgressGraphicNA.displayStatus;
                switch (inProgressGraphicNA.pendingICWeight) {
                    case true:
                        graphic.pendingICWeightStr = t('yes', 'Yes');
                        break;
                    case false:
                        graphic.pendingICWeightStr = t('no', 'No');
                        break;
                    default:
                        graphic.pendingICWeightStr = t('na', 'N/A');
                        break;
                }
            } else if (regionCode === Region.EU) {
                const inProgressGraphicEMEA = inProgressGraphic as EmeaGraphics;
                graphic.requestName = inProgressGraphicEMEA.designName;
                graphic.graphicsNumber = inProgressGraphicEMEA.designId ?? t('pending', 'Pending');
                graphic.graphicSpecialist = inProgressGraphicEMEA.graphicCoordinatorName ?? '';
                graphic.graphicSpecialistEmail =
                    inProgressGraphicEMEA.graphicCoordinatorEmail ?? '';
                if (inProgressGraphicEMEA.graphicCurrentStatuses) {
                    graphic.statuses = inProgressGraphicEMEA.graphicCurrentStatuses?.sort((a, b) =>
                        moment(b.iterationDate).diff(a.iterationDate)
                    );
                } else {
                    const status = inProgressGraphicEMEA.status?.toString() || 'UNDER_REVIEW';
                    graphic.statuses = [
                        {
                            displayStatus: t(status, 'Design Submitted'),
                            iterationDate: inProgressGraphicEMEA.createdDate,
                            iterationNumber: 0,
                            status: inProgressGraphicEMEA.status
                        } as GraphicsDetailStatus
                    ];
                }
                graphic.searchableStatus = inProgressGraphicEMEA.graphicCurrentStatuses
                    ?.map((currentStatus) => currentStatus.displayStatus)
                    .join(' ');
            }
            graphic.sortIndex = index;
            graphics.push(graphic);
        });
        setGraphicsForTable(graphics);
    }, [inProgressGraphics, regionCode, t]);

    const InProgressRowComponent = ({ row }: { row: InProgressGraphicForTable }) => {
        if (row) {
            return (
                <GraphicsInProgressRow
                    graphic={row as InProgressGraphicForTable}
                    key={row.graphicsId}
                    onStatusHistoryLookup={handleStatusHistoryLookupOpen}
                />
            );
        } else {
            return null;
        }
    };

    return (
        <>
            <Grid
                className={clsx(classes.tableWrapper, {
                    [classes.tableWrapperEmea]: regionCode === Region.EU
                })}
                key={generateKey('inprogress-graphics-tab')}
            >
                <DataGrid
                    gridName="In Progress Graphics"
                    columns={columns}
                    dataSource={graphicsForTable}
                    rowComponent={InProgressRowComponent}
                    toolbarOptions={options}
                ></DataGrid>
            </Grid>
            <Modal
                open={statusHistoryModalOpen}
                close={handleStatusHistoryLookupClose}
                title={t('graphicStatusHistory', 'Graphic Status History')}
                closeIcon={true}
                fullWidth={true}
                maxWidth="md"
            >
                {selectedStatusHistory && (
                    <GraphicsStatusHistoryModal
                        selectedStatusHistory={selectedStatusHistory}
                        onClose={handleStatusHistoryLookupClose}
                    />
                )}
            </Modal>
        </>
    );
};

export default GraphicsInProgressTab;
