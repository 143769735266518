import {
    SCRAP_IT_REQUEST_DETAILS_ERROR,
    SCRAP_IT_REQUEST_DETAILS_LOADED,
    SCRAP_IT_REQUEST_DETAILS_LOADING,
    SCRAP_IT_REQUEST_DETAILS_RESET
} from '../actions/action-types';

export interface ScrapItLine {
    productSku: string;
    productGraphicId: string;
    productGraphicVersion: string;
    quantity: number;
    eachesQuantity?: number;
    status: string;
    coating: string;
    productDescription: string;
    customerProductId: string;
    graphicIdAndVersion: string;
}

export interface ScrapItRequestDetails {
    scrapRequestId: string;
    submittedDate: string;
    shipToId: number;
    quantity: number;
    scrapLines: ScrapItLine[];
    canPallets: number;
    canSkus: number;
    canEaches: number;
    endPallets: number;
    endSkus: number;
    endEaches: number;
}

export interface ScrapItRequestDetailsState {
    scrapItRequest?: ScrapItRequestDetails;
    loading: boolean;
    loaded: boolean;
    error?: any;
}

const initialState: ScrapItRequestDetailsState = {
    scrapItRequest: undefined,
    loading: false,
    loaded: false,
    error: undefined
};

const scrapItRequestDetails = (
    state: ScrapItRequestDetailsState = initialState,
    action: { type: string; [x: string]: any }
): ScrapItRequestDetailsState => {
    switch (action.type) {
        case SCRAP_IT_REQUEST_DETAILS_LOADING:
            return { ...state, loading: true, loaded: false };
        case SCRAP_IT_REQUEST_DETAILS_ERROR:
            return { ...state, loading: false, error: action.error };
        case SCRAP_IT_REQUEST_DETAILS_LOADED:
            return {
                ...state,
                scrapItRequest: action.scrapItRequest,
                loaded: true,
                loading: false
            };
        case SCRAP_IT_REQUEST_DETAILS_RESET:
            return {
                ...state,
                scrapItRequest: undefined,
                loading: false,
                loaded: false
            };
        default:
            return state;
    }
};

export default scrapItRequestDetails;
