import React from 'react';
import { Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import { isProductCanType } from '../../../utility/helpers/order-helpers';
import {
    getSnoSkuDescription,
    getSnoSkuAttributes
} from '../../../utility/helpers/make-it-bulk-helpers';
import { isProductEndType } from '../../../utility/helpers/order-helpers';
import { ballBlue, ballGray, boldWeight, small, xxl } from '../../../themes/globalConstants';
import clsx from 'clsx';
import { Trans } from 'react-i18next';
import ExpandLessOutlinedIcon from '@material-ui/icons/ExpandLessOutlined';
import CampaignPill from '../atoms/CampaignPill';
import moment from 'moment';

interface Props {
    product: any;
    shipToId: number;
    shipToName?: string;
    shipToAddress?: string;
    handleToggleExpand: () => void;
    openDrawer?: boolean;
    showCampaignPill?: boolean;
}

const useStyles = makeStyles((theme) => ({
    item: {
        paddingLeft: '.75em'
    },
    productDescriptionText: {
        fontSize: xxl,
        overflowWrap: 'break-word'
    },
    shipToText: {
        fontSize: small
    },
    border: {
        borderRight: `1px solid ${theme.palette.info.light}`
    },
    productDescriptionContainer: {
        paddingRight: '0.5em'
    },
    lesserAttributes: {
        fontWeight: boldWeight,
        fontSize: small
    },
    subtitle: {
        color: ballGray
    },
    collapseToggleContainer: {
        display: 'flex'
    },
    campaignPillContainer: {
        display: 'flex'
    },
    arrow: {
        fill: ballBlue,
        transform: 'rotate(180deg)'
    },
    rotateArrow: {
        transform: 'rotate(0deg)'
    },
    toggleButton: {
        '&:hover': {
            background: 'transparent'
        }
    },
    supplyPlanWeeks: {
        textTransform: 'uppercase',
        fontStyle: 'italic',
        fontSize: small,
        fontWeight: boldWeight,
        color: ballGray
    }
}));

export const MakeItBulkATMOrderSectionHeader = ({
    product,
    shipToId,
    shipToName,
    shipToAddress,
    handleToggleExpand,
    openDrawer,
    showCampaignPill = false
}: Props) => {
    const classes = useStyles();
    const availableSupplyPlans: string[] | undefined =
        product && !isProductEndType(product.type) && product.hasOwnProperty('supplyPlanWeeks')
            ? product.supplyPlanWeeks
            : undefined;

    function CollapseToggle() {
        return (
            <IconButton
                aria-label="close"
                color="inherit"
                data-testid="order-section-atm-collapse-toggle"
                className={classes.toggleButton}
                onClick={handleToggleExpand}
            >
                <ExpandLessOutlinedIcon
                    fontSize="large"
                    className={clsx(classes.arrow, { [classes.rotateArrow]: openDrawer })}
                />
            </IconButton>
        );
    }

    return (
        <>
            {' '}
            <Grid
                item
                xs={5}
                className={clsx(classes.item, classes.border, classes.productDescriptionContainer)}
                data-testid="sno-sku-id"
            >
                <Typography
                    className={classes.productDescriptionText}
                    data-testid="sno-sku-description"
                    variant="h4"
                >
                    {product && getSnoSkuDescription(isProductCanType(product.type), product)}
                </Typography>
                <Typography className={classes.lesserAttributes} data-testid="product-attributes">
                    {product && getSnoSkuAttributes(isProductCanType(product.type), product)}
                </Typography>
            </Grid>
            {shipToName && shipToAddress && (
                <Grid
                    item
                    xs={6}
                    className={clsx(classes.item, classes.border)}
                    data-testid={'ship-to'}
                >
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="shipTo">SHIP TO</Trans>
                    </Typography>
                    <Typography data-testid="ship-to-name" className={classes.shipToText}>
                        {shipToName}
                    </Typography>
                    <Typography data-testid="ship-to-address" className={classes.shipToText}>
                        {shipToAddress}
                    </Typography>
                    <Typography data-testid="ship-to-id" className={classes.shipToText}>
                        #{shipToId}
                    </Typography>
                </Grid>
            )}
            {showCampaignPill && product && product.campaignTiming && (
                <Grid
                    container
                    item
                    xs={4}
                    justify="center"
                    className={classes.campaignPillContainer}
                >
                    <CampaignPill />
                </Grid>
            )}
            <Grid item xs={1} className={classes.collapseToggleContainer}>
                <CollapseToggle />
            </Grid>
            <Grid item xs={12}>
                {availableSupplyPlans && availableSupplyPlans.length > 0 && (
                    <Typography data-testid="supply-plan-week" className={classes.supplyPlanWeeks}>
                        <Trans i18nKey="availableSupplyPlan">Available Supply Plan</Trans>:{' '}
                        {availableSupplyPlans
                            .map((supplyPlanWeek) => moment(supplyPlanWeek).format('MM/DD'))
                            .join(', ')}
                    </Typography>
                )}
            </Grid>
        </>
    );
};
