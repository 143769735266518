import { ContentSection } from '../../../../store/reducers/content-page';
import MultiItemVerticalSection from '../components/MultiItemVerticalSection';
import React from 'react';
import MultiItemHorizontalSection from '../components/MultiItemHorizontalSection';
import SingleImageContentSection from '../components/SingleImageContentSection';
import Accordion from '../components/Accordion';
import SingleVideoContentSection from '../components/SingleVideoContentSection';

const verifyTemplateType = (type: string, section: ContentSection, i: number) => {
    switch (type) {
        case 'TextOnlyItemType':
            break;
        case 'VerticalMultiImageType':
            return <MultiItemVerticalSection {...section} key={i} />;
        case 'HorizontalMultiImageType':
            return <MultiItemHorizontalSection {...section} key={i} />;
        case 'MultiImageItemType':
        case 'MultiColumnTextOnly':
            return <MultiItemHorizontalSection {...section} key={i} />;
        case 'SingleImageType':
            return <SingleImageContentSection {...section} key={i} />;
        case 'SingleVideoType':
            return <SingleVideoContentSection {...section} key={i} />
        case 'TextOnlyAccordionType':
            return <Accordion section={section} key={i} />;
        case 'RightVisualAccordionType':
            return <Accordion section={section} orientation={'right'} key={i} />;
        case 'LeftVisualAccordionType':
            return <Accordion section={section} orientation={'left'} key={i} />;
        default:
            break;
    }
};

export default verifyTemplateType;
