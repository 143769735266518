import React, { useEffect, useRef, useState } from 'react';
import Modal from '../Modal';
import { AppBar, Grid, makeStyles, Toolbar } from '@material-ui/core';
import Button from '../../atoms/Button';
import { Trans, useTranslation } from 'react-i18next';
import { DateProperties } from '../../../../utility/helpers/make-it-bulk-helpers';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import {
    BulkUploadPlanItATMState,
    PlanItBulkATMLineItem,
    PlanItBulkATMOrder
} from '../../../../store/reducers/planit-bulk-atm';
import { AddProductItem } from './models/AddProductItem';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import AddProductsModalColumns from './AddProductsModalColumns';
import AddProductsModalRow from './AddProductsModalRow';
import { addProductToForecastOrder } from '../../../../store/actions/planit-bulk-atm';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import {
    ballBlue,
    ballGray,
    blackWeight,
    boldWeight,
    dateOptionInvalidGray,
    regularWeight,
    small,
    white
} from '../../../../themes/globalConstants';
import { MakeState } from '../../../../store/reducers/makeit-dashboard';
import { PlanItGuidedState } from '../../../../store/reducers/plan-it-guided';
import {
    addProductPlanItConfiguration,
    addProductToAtmOrder
} from '../../../../store/root-actions';
import { MakeItBulkLineItem } from '../../../../store/reducers/makeit-bulk-upload';
import { BulkUploadMakeItATMState } from '../../../../store/reducers/makeit-bulk-atm';

interface Props {
    open: boolean;
    onClose: () => void;
    selectedShipTo?: string;
    activeDate?: DateProperties;
    fromPlanItGuided?: boolean;
    fromMakeIt?: boolean;
}

const columnWidths = [21, 23, 9, 20, 12, 15];

const useStyles = makeStyles((theme) => ({
    modalBtn: {
        height: '3.3em',
        width: '10em',
        marginRight: '1em'
    },
    modalBtn2: {
        height: '3.3em',
        width: '10em',
        marginRight: '0em'
    },
    actionBar: {
        margin: '1.5em 0',
        padding: '0'
    },
    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '0.5em'
    },
    buttonGrid: {
        paddingTop: '1em',
        paddingBottom: '1em'
    },
    modalContainer: {
        paddingRight: '0.5em',
        height: '25em',
        overflowY: 'scroll',
        overflowX: 'hidden',
        width: '60em',
        marginLeft: '2em',
        marginTop: '2em'
    },
    appBar: {
        top: 'auto',
        bottom: 0,
        width: '100%'
    },
    toolBar: {
        backgroundColor: white
    },
    tableWrapper: {
        fontWeight: regularWeight,
        '& .MuiToolbar-root': {
            padding: 0,
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                },
                '&:first-of-type ': {
                    paddingLeft: 0
                }
            },
            '& > h2': {
                fontSize: '1.5em',
                fontWeight: boldWeight,
                color: theme.palette.secondary.main,
                paddingLeft: '0.625em',
                fontFamily: 'Lato',
                minWidth: '12em'
            }
        },
        '& .MuiTableCell-head': {
            color: ballGray,
            fontSize: small,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            '&:not(:first-of-type)': {
                borderLeft: 'unset'
            }
        },
        '& .MuiTableSortLabel-root': {
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em'
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                fontSize: '12px',
                borderLeft: 'none',
                ...columnWidths.reduce((ret, width, index) => {
                    ret[`&:nth-child(${index + 1})`] = {
                        width: `${width}%`
                    };
                    return ret;
                }, {})
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word',
            padding: '16px',
            fontSize: '14px'
        },
        '& .MuiInputBase-formControl': {
            border: `1px solid ${dateOptionInvalidGray}`,
            borderRadius: '.125em',
            '&:before': {
                display: 'none'
            },
            '&:after': {
                display: 'none'
            },
            '& svg': {
                height: '.875em',
                width: '.875em',
                fill: ballBlue
            },
            '& .MuiIconButton-root': {
                '&:hover': {
                    backgroundColor: 'transparent !important'
                }
            },
            '& .MuiInput-input': {
                paddingLeft: '.25em'
            }
        }
    }
}));

const AddProductsModal = ({
    open,
    onClose,
    selectedShipTo,
    activeDate,
    fromPlanItGuided = false,
    fromMakeIt = false
}: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const modalTitle = t('addProducts', 'Add Products');
    const [columns, setColumns] = useState<any>([]);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { products, forecastOrders } = useTypedSelector<BulkUploadPlanItATMState>(
        (state) => state.bulkUploadPlanIt
    );
    const dashboardProducts = useTypedSelector<MakeState>(
        (state) => state.makeItDashboard
    ).products;

    const { guidedOrders } = useTypedSelector<PlanItGuidedState>((state) => state.planItGuided);
    const { atmOrders } = useTypedSelector<BulkUploadMakeItATMState>(
        (state) => state.bulkUploadMakeItATMState
    );
    const [displayProducts, setDisplayProducts] = useState<AddProductItem[] | undefined>(undefined);
    const [addButtonEnabled, setAddButtonEnabled] = useState<boolean>(false);
    const [selectedOrders, setSelectedOrders] = useState<PlanItBulkATMOrder[] | undefined>(
        undefined
    );
    const [modalScrollTop, setModalScrollTop] = useState<number>(0);

    const modalContainerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (selectedShipTo && activeDate && products && forecastOrders && !fromPlanItGuided) {
            const orders = forecastOrders.filter(
                (order) =>
                    order.shipToId === parseInt(selectedShipTo) &&
                    moment(order.weekStart).format('MM/DD/YYYY') === activeDate.fullDate
            );
            setSelectedOrders(orders);
        }
    }, [selectedShipTo, activeDate, products, forecastOrders, fromPlanItGuided]);

    // sort ACTIVE products first then INACTIVE
    const sortAvailableProducts = (products: AddProductItem[]): AddProductItem[] => {
        products.sort(
            (a, b) =>
                a.displayId!.localeCompare(b.displayId!) &&
                (a.status ?? 'INACTIVE').localeCompare(b.status ?? 'INACTIVE')
        );
        products.forEach((product, index) => {
            product.sortIndex = index;
        });
        return [...products];
    };

    // set up display products for plan it bulk review
    useEffect(() => {
        if (!(fromPlanItGuided || fromMakeIt)) {
            const getAvailableProducts = () => {
                const productsForTable: AddProductItem[] = [];
                if (products && selectedShipTo && selectedOrders) {
                    const productsForShipTo = products.filter(
                        (product) =>
                            product.destinations &&
                            product.destinations.filter(
                                (destination) => destination.shipToId.toString() === selectedShipTo
                            ).length > 0
                    );
                    if (selectedOrders) {
                        const orderedProducts = selectedOrders
                            .map((order) => order.lines)
                            .flat()
                            .filter((lines) => !lines.deleted)
                            .map((line) => line.productSku);
                        productsForShipTo.forEach((product) => {
                            const destination = product.destinations?.find(
                                (d) =>
                                    d.shipToId === parseInt(selectedShipTo) && d.status === 'ACTIVE'
                            );
                            productsForTable.push({
                                ...product,
                                shipToId: parseInt(selectedShipTo),
                                status:
                                    product.status === 'ACTIVE' && destination
                                        ? 'ACTIVE'
                                        : 'INACTIVE',
                                addDisabled:
                                    product.productSku &&
                                    orderedProducts.includes(product.productSku)
                                        ? true
                                        : false
                            });
                        });
                    }
                }
                setDisplayProducts(sortAvailableProducts(productsForTable));
            };

            if (selectedOrders) {
                getAvailableProducts();
            }
        }
    }, [selectedOrders, products, selectedShipTo, fromPlanItGuided, fromMakeIt]);

    //set up display products for plan it guided
    useEffect(() => {
        if (fromPlanItGuided) {
            const getAvailableProducts = () => {
                const productsForTable: AddProductItem[] = [];
                if (dashboardProducts && selectedShipTo && guidedOrders) {
                    const productsForShipTo: PlanItBulkATMLineItem[] = dashboardProducts
                        .filter(
                            (product) =>
                                product.destinations &&
                                product.destinations.filter(
                                    (destination) =>
                                        destination.shipToId.toString() === selectedShipTo
                                ).length > 0
                        )
                        .map((product) => {
                            return {
                                ...product,
                                userSuppliedProductId: '',
                                isCustomerProductIdDistinct: true,
                                deleted: false
                            } as PlanItBulkATMLineItem;
                        });
                    if (guidedOrders) {
                        const orderedProducts = guidedOrders
                            .filter((order) => order.week === activeDate?.fullDate)
                            .map((order) => order.productsToOrder)
                            .flat()
                            .map((product) => product.productSku);
                        productsForShipTo.forEach((product) => {
                            const destination = product.destinations?.find(
                                (d) =>
                                    d.shipToId === parseInt(selectedShipTo) && d.status === 'ACTIVE'
                            );
                            productsForTable.push({
                                ...product,
                                shipToId: parseInt(selectedShipTo),
                                status:
                                    product.status === 'ACTIVE' && destination
                                        ? 'ACTIVE'
                                        : 'INACTIVE',
                                addDisabled:
                                    product.productSku &&
                                    orderedProducts.includes(product.productSku)
                                        ? true
                                        : false
                            });
                        });
                    }
                }
                setDisplayProducts(sortAvailableProducts(productsForTable));
            };

            if (guidedOrders) {
                getAvailableProducts();
            }
        }
    }, [dashboardProducts, selectedShipTo, fromPlanItGuided, guidedOrders, activeDate]);

    //set up display products for plan it guided or make it
    useEffect(() => {
        if (fromMakeIt) {
            const getAvailableProducts = () => {
                const productsForTable: AddProductItem[] = [];
                if (dashboardProducts && selectedShipTo && atmOrders) {
                    const productsForShipTo: PlanItBulkATMLineItem[] = dashboardProducts
                        .filter(
                            (product) =>
                                product.destinations &&
                                product.destinations.filter(
                                    (destination) =>
                                        destination.shipToId.toString() === selectedShipTo
                                ).length > 0
                        )
                        .map((product) => {
                            return {
                                ...product,
                                userSuppliedProductId: '',
                                isCustomerProductIdDistinct: true,
                                deleted: false
                            } as PlanItBulkATMLineItem;
                        });
                    if (atmOrders) {
                        const orderedProducts = atmOrders
                            .filter(
                                (order) =>
                                    moment(order.weekStart).format('MM/DD/YYYY') ===
                                    activeDate?.fullDate
                            )
                            .map((order) => order.lines)
                            .flat()
                            .filter((lines) => lines.deleted !== true)
                            .map((product) => product.productSku);
                        productsForShipTo.forEach((product) => {
                            const destination = product.destinations?.find(
                                (d) =>
                                    d.shipToId === parseInt(selectedShipTo) && d.status === 'ACTIVE'
                            );
                            productsForTable.push({
                                ...product,
                                shipToId: parseInt(selectedShipTo),
                                status:
                                    product.status === 'ACTIVE' && destination
                                        ? 'ACTIVE'
                                        : 'INACTIVE',
                                addDisabled:
                                    product.productSku &&
                                    orderedProducts.includes(product.productSku)
                                        ? true
                                        : false
                            });
                        });
                    }
                }
                setDisplayProducts(sortAvailableProducts(productsForTable));
            };

            if (fromMakeIt) {
                getAvailableProducts();
            }
        }
    }, [dashboardProducts, selectedShipTo, fromMakeIt, atmOrders, activeDate]);

    useEffect(() => {
        setColumns(AddProductsModalColumns.getTradeItProductsColumns(cultureCode));
    }, [cultureCode]);

    useEffect(() => {
        if (displayProducts) {
            setAddButtonEnabled(displayProducts.filter((product) => product.isAdded).length > 0);
            if(modalContainerRef.current) modalContainerRef.current.scrollTop = modalScrollTop;
        }
    }, [displayProducts, modalScrollTop]);

    const handleOnProductAddedChange = (productSku: string | undefined) => {
        if (displayProducts) {
            const updatedDisplayProducts = displayProducts.map((product) => {
                if (product.productSku === productSku) {
                    return { ...product, isAdded: product.isAdded ? !product.isAdded : true };
                } else {
                    return product;
                }
            });
            if(modalContainerRef.current) setModalScrollTop(modalContainerRef.current.scrollTop);
            setDisplayProducts(updatedDisplayProducts);
        }
    };

    const CustomOrderRow = ({ row }: { row: AddProductItem }) => {
        return (
            <AddProductsModalRow product={row} onProductAddedChange={handleOnProductAddedChange} />
        );
    };

    const handleCancel = () => {
        if (displayProducts) {
            const updatedDisplayProducts = displayProducts.map((product) => {
                return { ...product, isAdded: false };
            });
            setDisplayProducts(updatedDisplayProducts);
        }
        onClose();
    };

    const handleAdd = () => {
        if (activeDate && selectedShipTo && displayProducts) {
            const addedProducts: PlanItBulkATMLineItem[] = displayProducts
                .filter((product) => product.isAdded === true)
                .map((line) => line as PlanItBulkATMLineItem);
            if (fromPlanItGuided) {
                // for plan it guided
                addedProducts.forEach((addedProduct) => {
                    let item = dashboardProducts?.find(
                        (product) => product.productSku === addedProduct.productSku
                    );
                    if (item) {
                        dispatch(
                            addProductPlanItConfiguration(
                                { ...item, requestedDate: activeDate.fullDate },
                                activeDate.fullDate
                            )
                        );
                    }
                });
            } else if (fromMakeIt) {
                // for make it
                addedProducts.forEach((addedProduct) => {
                    dispatch(
                        addProductToAtmOrder(
                            activeDate,
                            parseInt(selectedShipTo),
                            addedProduct as MakeItBulkLineItem
                        )
                    );
                });
            } else {
                // for plan it bulk review
                addedProducts.forEach((addedProduct) => {
                    dispatch(
                        addProductToForecastOrder(
                            activeDate,
                            parseInt(selectedShipTo),
                            addedProduct
                        )
                    );
                });
            }
        }

        onClose();
    };

    const toolbarOptions = new ToolbarOptions({
        advancePagination: false,
        exportButton: false,
        printButton: false,
        enablePagination: true,
        itemsPerPage: 10,
    });

    return (
        <Modal open={open} title={modalTitle} close={handleCancel} closeIcon={true} maxWidth="xl">
            <Grid container className={classes.modalContainer} ref={modalContainerRef}>
                <Grid item data-testid="products-grid" className={classes.tableWrapper}>
                    {displayProducts && (
                        <DataGrid
                            gridName={'Trade It Products Grid'}
                            columns={columns}
                            dataSource={displayProducts}
                            rowComponent={CustomOrderRow}
                            toolbarOptions={toolbarOptions}
                        />
                    )}
                </Grid>
            </Grid>
            <AppBar className={classes.appBar} position="sticky" elevation={10}>
                <Toolbar className={classes.toolBar} variant="regular">
                    <Grid container className={classes.buttonGrid} justify="flex-end" >
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleCancel}
                            className={classes.modalBtn}
                            data-testid="cancel-btn"
                        >
                            <Trans i18nKey="cancel">Cancel</Trans>
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleAdd}
                            className={classes.modalBtn2}
                            data-testid="add-btn"
                            disabled={!addButtonEnabled}
                        >
                            <Trans i18nKey="add">Add</Trans>
                        </Button>
                    </Grid>
                </Toolbar>
            </AppBar>
        </Modal>
    );
};

export default AddProductsModal;
