import React from 'react';
import { makeStyles, TableRow, TableCell, Grid, Button } from '@material-ui/core';
import { useHistory } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';
import Link from '../../../reusable/atoms/Link';
import { boldWeight, medium } from '../../../../themes/globalConstants';
import { useContractSummaryContext } from '../context/ContractSummaryContext';
import { DocumentForTable } from '../models/DocumentForTable';
import { selectHasTermsAndConditionsAcknowledgmentAccess } from '../../../../store/selectors';
import { useTypedSelector } from '../../../../store/reducers/reducer';

interface Props {
    document: DocumentForTable;
}

const useStyles = makeStyles(() => ({
    name: {
        fontWeight: boldWeight,
        fontSize: medium,
        letterSpacing: 0.17
    },
    acknowledgeButton: {
        fontSize: '1em'
    },
    calendarDiv: {
        margin: '2em'
    }
}));

export default function DocumentsRow({ document }: Props) {
    const classes = useStyles();
    const history = useHistory();
    const { activeTab } = useContractSummaryContext();
    const isAuthorized = useTypedSelector<boolean>(
        selectHasTermsAndConditionsAcknowledgmentAccess(document.billTo)
    );

    useTranslation();

    const handleAcknowledgeClick = () => {
        history.push(`/acknowledge-terms/${document.billTo}/${document.documentId}`);
    };

    const actions = () => {
        return (
            <TableCell align="left">
                {activeTab === 'active' && document.needsAcknowledgement && (
                    <Button
                        variant="text"
                        color="primary"
                        data-testid="acknowledge-button"
                        className={classes.acknowledgeButton}
                        onClick={handleAcknowledgeClick}
                        disabled={isAuthorized === false}
                    >
                        <Trans i18nKey="acknowledge">Acknowledge</Trans>
                    </Button>
                )}
            </TableCell>
        );
    };

    return (
        <TableRow hover={true} key={document.documentId}>
            <TableCell data-testid={'document-name'}>
                <Grid container spacing={1}>
                    <Grid item className={classes.name}>
                        <Link underline="none" url={document.pdfUrl} target="_blank">
                            {document.documentName}
                        </Link>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell data-testid={'document-approver'}>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        {document.approver}
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell data-testid={'document-approval-date'}>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        {document.approvalDateString}
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell data-testid={'document-effective-date'}>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        {document.effectiveDateString}
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell data-testid={'document-expiration-date'}>
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        {document.expirationDateString}
                    </Grid>
                </Grid>
            </TableCell>
            {actions()}
        </TableRow>
    );
}
