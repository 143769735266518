import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class InactiveProductsColumns {
    static getColumns = (culture: string) => {
        return [
            createColumn('lineNumberString', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'csvRowNumber'),
                sortable: true,
                filterable: true,
                searchable: true
            }),
            createColumn('productId', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'productId'),
                filterable: true,
                sortable: true,
                searchable: true
            }),
            createColumn('shipToId', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'shipTo'),
                filterable: true,
                sortable: true,
                searchable: true
            }),
            createColumn('productDescription', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'productDescription'),
                filterable: true,
                sortable: true,
                searchable: true
            })
        ];
    };
}
