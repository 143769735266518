import React, { useState, useEffect, useCallback } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { Activity } from '../../../utility/auth/useSecurity';
import { useTranslation } from 'react-i18next';
import DocumentsTabs from './components/DocumentsTabs';
import NotificationBanner, { NotificationProps } from '../../reusable/molecules/NotificationBanner';
import { AddressType, CustomerContextState } from '../../../store/reducers/customer-context';
import { loadContractsAndDocuments } from '../../../store/actions/contract-summary';
import SuccessAlert from '../../reusable/atoms/SuccessAlert';
import ErrorAlert from '../../reusable/atoms/ErrorAlert';
import { enUS } from '../../../utility/translations/locales';
import { ContractSummaryState } from '../../../store/reducers/contract-summary';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { selectHasTermsAndConditionsSummaryAccess } from '../../../store/selectors';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import {
    CONTRACT_ACKNOWLEDGEMENT_ERROR,
    CONTRACT_ACKNOWLEDGEMENT_RESET
} from '../../../store/actions/action-types';
import { AuthState } from '../../../store/reducers/auth';

const useStyles = makeStyles((theme) => ({
    successAlertContainer: {
        margin: '0 auto',
        marginTop: '5px',
        width: '75%'
    },
    errorAlertContainer: {
        margin: '0 auto',
        marginTop: '5px',
        width: '75%'
    }
}));

export default function ContractSummary() {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
    const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [notification, setNotification] = useState<NotificationProps | null>(null);
    const { loading, error, requiresAcknowledgement, acknowledgementStatus } =
        useTypedSelector<ContractSummaryState>((state) => state.contractSummary);
    const { accounts } = useTypedSelector<CustomerContextState>((state) => state.customerContext);
    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);

    const clearSuccessMessage = useCallback(() => {
        setTimeout(() => {
            dispatch({ type: CONTRACT_ACKNOWLEDGEMENT_RESET });
            setShowSuccessAlert(false);
        }, 5000);
    }, [dispatch]);

    const clearErrorMessage = useCallback(() => {
        setTimeout(() => {
            dispatch({ type: CONTRACT_ACKNOWLEDGEMENT_ERROR });
            setShowErrorAlert(false);
        }, 5000);
    }, [dispatch]);

    const isAuthorized = useTypedSelector<boolean>(selectHasTermsAndConditionsSummaryAccess);

    // Send user to unauthorized page if they don't meet the authorization requirements
    useEffect(() => {
        if (accounts?.length && permissions?.length && isAuthorized === false)
            history.push('/unauthorized');
    }, [isAuthorized, accounts, permissions]);

    useEffect(() => {
        if (!error && accounts && accounts.length > 0) {
            const billToAccounts = accounts
                .filter(
                    (account) =>
                        account.listCustomer &&
                        (account.addressType === AddressType.CB ||
                            account.addressType === AddressType.CX ||
                            account.addressType === AddressType.CP ||
                            account.addressType === AddressType.CG)
                )
                .map((account) => account.accountId);
            dispatch(loadContractsAndDocuments(billToAccounts));
        }
    }, [dispatch, accounts, error]);

    useEffect(() => {
        if (requiresAcknowledgement && requiresAcknowledgement.length > 0) {
            setNotification({
                notificationType: 'WARNING',
                notificationMessage: t(
                    'acknowledgementsRequired',
                    'One or more Bill Tos have documents that require acknowledgment, please review.'
                )
            });
            setShowNotification(true);
        }
    }, [requiresAcknowledgement, t]);

    useEffect(() => {
        if (acknowledgementStatus === 'succeeded') {
            setShowSuccessAlert(true);
            clearSuccessMessage();
        }
        if (acknowledgementStatus === 'failed') {
            setShowErrorAlert(true);
            clearErrorMessage();
        }
    }, [acknowledgementStatus, clearSuccessMessage, clearErrorMessage]);

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('termsAndConditions', 'Terms and Conditions'),
                description: t('contractSummary', 'Summary').toUpperCase(),
                thinBanner: true,
                displayDropdown: true,
                billToDropdown: true
            }}
            actionFooter={{
                justify: 'space-between',
                sticky: true
            }}
            activity={Activity.ContractSummary}
            loading={loading}
        >
            {showNotification && notification && (
                <Grid item xs={12}>
                    <NotificationBanner
                        notificationMessage={notification.notificationMessage}
                        notificationType={notification.notificationType}
                    />
                </Grid>
            )}
            <Grid container data-testid="contract-summary">
                <Grid container justify="space-between">
                    <Grid
                        item
                        data-testid="acknowledgement-success-alert"
                        className={classes.successAlertContainer}
                    >
                        {showSuccessAlert && (
                            <SuccessAlert
                                showSuccess={true}
                                successMessage={t(
                                    'acknowledgementSuccessMessage',
                                    `${enUS.acknowledgementSuccessMessage}`
                                )}
                            />
                        )}
                    </Grid>

                    <Grid
                        item
                        data-testid="acknowledgement-error-alert"
                        className={classes.errorAlertContainer}
                    >
                        <ErrorAlert
                            showError={showErrorAlert}
                            errorMessage={t(
                                'acknowledgementErrorMessage',
                                `${enUS.acknowledgementErrorMessage}`
                            )}
                        />
                    </Grid>
                </Grid>

                <DocumentsTabs />
            </Grid>
        </ProcessingPageTemplate>
    );
}
