import React from 'react';
import { Typography, Grid, makeStyles } from '@material-ui/core';
import { ContentSection, ContentSubSection } from '../../../../store/reducers/content-page';
import VerticalTemplateBlock from './VerticalTemplateBlock';

//This section creates rows of content and stacks the rows vertically

const useStyles = makeStyles((theme) => ({
    titleContainer: {
        textAlign: 'center',
        marginTop: '4em'
    },
    subsectionContainer: {
        margin: 'auto',
        paddingTop: '0 !important'
    }
}));
const MultiItemVerticalSection = (contentSection: ContentSection) => {
    const classes = useStyles();
    const subSections = contentSection.subsections as ContentSubSection[];
    const renderSubsections = subSections?.map((section: ContentSubSection, i: number) => {
        return <VerticalTemplateBlock section={section} />;
    });

    return (
        <Grid container spacing={3}>
            <Grid container item xs={12} spacing={3} className={classes.titleContainer}>
                <Grid item xs={12}>
                    <Typography variant="h3">{contentSection.header}</Typography>
                </Grid>
                {contentSection.description && (
                    <Grid item xs={12}>
                        <Typography variant="body1" component="p">
                            {contentSection.description}
                        </Typography>
                    </Grid>
                )}
            </Grid>
            <Grid container item className={classes.subsectionContainer}>
                {renderSubsections}
            </Grid>
        </Grid>
    );
};

export default MultiItemVerticalSection;
