import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Trans } from 'react-i18next';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { blackWeight, medium } from '../../../../themes/globalConstants';
import clsx from 'clsx';
import moment from 'moment';
import { TradeRequestDetailState } from '../../../../store/reducers/trade-it-details';

const useStyles = makeStyles((theme) => ({
    subtitle: {
        textTransform: 'uppercase',
        fontWeight: blackWeight,
        color: theme.palette.secondary.main
    },
    rightText: {
        textAlign: 'right'
    },
    shipToName: {
        fontSize: medium,
        letterSpacing: '0.2',
        textTransform: 'uppercase'
    },
    subheaderBody: {
        fontSize: medium,
        letterSpacing: '0.2'
    },
    spacer: {
        marginTop: '1em'
    }
}));
export default function TradeRequestDetailsSubheader() {
    const classes = useStyles();
    const { tradeRequest } = useTypedSelector<TradeRequestDetailState>(
        (state) => state.tradeItDetails
    );

    return (
        <>
            <Grid container>
                <Grid container xs={9} justify="flex-start">
                    <Grid item xs={5}>
                        <Typography variant="h5" className={classes.subtitle}>
                            <Trans i18nKey="receivingShipTo">Receiving Ship To</Trans>
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant="h5" className={classes.subtitle}>
                            <Trans i18nKey="originalShipTo">Original Ship To</Trans>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container xs={3} justify="flex-end">
                    <Grid item xs={5}>
                        <Typography
                            variant="h5"
                            className={clsx(classes.subtitle, classes.rightText)}
                        >
                            <Trans i18nKey="dateSubmitted">Date Submitted</Trans>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container xs={9} justify="flex-start">
                    <Grid item xs={5}>
                        <Typography className={classes.shipToName} data-testid="account-to-name">
                            {tradeRequest?.accountToName}
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography className={classes.shipToName} data-testid="account-from-name">
                            {tradeRequest?.accountFromName}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container xs={3} justify="flex-end">
                    <Grid item xs={5}>
                        <Typography
                            className={clsx(classes.subheaderBody, classes.rightText)}
                            data-testid="submitted-date"
                        >
                            {tradeRequest?.submittedDate &&
                                moment(tradeRequest.submittedDate).format('MM/DD/YYYY')}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container xs={9} justify="flex-start">
                    <Grid item xs={5}>
                        <Typography
                            className={classes.subheaderBody}
                            data-testid="account-to-address"
                        >
                            {tradeRequest?.accountToAddress}
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography
                            className={classes.subheaderBody}
                            data-testid="account-from-address"
                        >
                            {tradeRequest?.accountFromAddress}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container xs={9} justify="flex-start">
                    <Grid item xs={5}>
                        <Typography className={classes.subheaderBody} data-testid="account-to-id">
                            {`#${tradeRequest?.accountTo}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography className={classes.subheaderBody} data-testid="account-from-id">
                            {`#${tradeRequest?.accountFrom}`}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container xs={9} justify="flex-start" className={classes.spacer}>
                    <Grid item xs={5}>
                        <Typography variant="h5" className={classes.subtitle}>
                            <Trans i18nKey="receivingShipToContact">
                                Receiving Ship To Contact
                            </Trans>
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant="h5" className={classes.subtitle}>
                            <Trans i18nKey="originalShipToContact">Original Ship To Contact</Trans>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container xs={9} justify="flex-start">
                    <Grid item xs={5}>
                        <Typography
                            className={classes.subheaderBody}
                            data-testid="account-to-contact"
                        >
                            {`${tradeRequest?.fillerContactTo}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography
                            className={classes.subheaderBody}
                            data-testid="account-from-contact"
                        >
                            {`${tradeRequest?.fillerContactFrom}`}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
