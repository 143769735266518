import { DeliveryOrder, LinkedProductionOrders } from './shipping-dashboard';
import {
    DELIVERY_ORDER_LOADING,
    DELIVERY_ORDER_LOADED,
    DELIVERY_ORDER_LOADING_ERROR,
    DELIVERY_ORDER_LINKED_PRODUCTION_ORDER_LOADING,
    DELIVERY_ORDER_LINKED_PRODUCTION_ORDER_LOADED,
    DELIVERY_ORDER_LINKED_PRODUCTION_ORDER_ERROR
} from '../actions/action-types';

export interface DeliveryOrderDetailsState {
    deliveryOrder?: DeliveryOrder;
    loading: boolean;
    error?: string;
    linkedProductionOrders?: LinkedProductionOrders;
}

const initialState: DeliveryOrderDetailsState = {
    deliveryOrder: undefined,
    loading: false,
    linkedProductionOrders: undefined
};

const deliveryOrderDetails = (state = initialState, action: any) => {
    switch (action.type) {
        case DELIVERY_ORDER_LOADING:
            return {
                ...state,
                loading: true,
                deliveryOrder: undefined
            };
        case DELIVERY_ORDER_LOADED:
            return {
                ...state,
                loading: false,
                deliveryOrder: action.deliveryOrder
            };
        case DELIVERY_ORDER_LOADING_ERROR:
            return {
                ...state,
                details: undefined,
                loading: false,
                error: action.error
            };
        case DELIVERY_ORDER_LINKED_PRODUCTION_ORDER_LOADING:
            return {
                ...state,
                loading: true
            };
        case DELIVERY_ORDER_LINKED_PRODUCTION_ORDER_LOADED:
            return {
                ...state,
                loading: false,
                linkedProductionOrders: action.linkedProductionOrders
            };
        case DELIVERY_ORDER_LINKED_PRODUCTION_ORDER_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
};

export default deliveryOrderDetails;
