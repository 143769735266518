import { FormControl, FormHelperText, InputLabel, Select, MenuItem } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { ErrorMessage, Controller } from 'react-hook-form';

interface Props {
    label: ReactElement;
    name: string;
    testId: string;
    control: any;
    selectObj: any;
    rules?: any;
    error?: boolean;
    errors?: any;
    styles?: any;
    helperText?: ReactElement;
    defaultValue?: string;
    disabled?: boolean
}

const SelectInput = (props: Props) => {
    const error = props.error !== undefined;
    return (
        <FormControl fullWidth error={error} className={props.styles}>
            <InputLabel>
                {props.label}
            </InputLabel>
            <Controller
                as={
                    <Select
                        inputProps={{ 'data-testid': props.testId }}
                    >
                        <MenuItem value="">&nbsp;</MenuItem>
                        {props.selectObj.map(
                            (option) =>
                                <MenuItem
                                    key={option.value}
                                    value={option.value}
                                    data-testid="menu-option"
                                >
                                    {option.name}
                                </MenuItem>
                        )}
                    </Select>
                }
                disabled={props.disabled}
                name={props.name}
                control={props.control}
                rules={props.rules}
                defaultValue={props.defaultValue ? props.defaultValue : ""}
            />
            {!error && props.helperText && (
                <FormHelperText>
                    {props.helperText}
                </FormHelperText>
            )}
            <FormHelperText data-testid={error ? `${props.name}-error` : ""}>
                <ErrorMessage errors={props.errors} name={props.name} />
            </FormHelperText>
        </FormControl>
    );
}

export default SelectInput;