import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class TradeRequestDetailsColumns {
    static getTradeRequestDetailsColumns = (
        culture: string,
        originalShipToId,
        receivingShipToId
    ) => {
        return [
            createColumn('displayName', {
                dataType: ColumnDataType.String,
                filterable: true,
                isKey: true,
                label: `${TranslationService.getTranslatedText(
                    culture,
                    'productDescription'
                )} (${TranslationService.getTranslatedText(
                    culture,
                    'productId'
                )} / ${TranslationService.getTranslatedText(culture, 'graphicId')})`,
                sortable: true,
                searchable: true
            }),
            createColumn('productSku', {
                dataType: ColumnDataType.String,
                filterable: true,
                sortable: true,
                searchable: true,
                visible: false
            }),
            createColumn('graphicIdAndVersion', {
                dataType: ColumnDataType.String,
                filterable: true,
                sortable: true,
                searchable: true,
                visible: false
            }),
            createColumn('displayId', {
                dataType: ColumnDataType.String,
                filterable: true,
                sortable: true,
                searchable: true,
                visible: false
            }),
            createColumn('accountFromOriginalBalance', {
                dataType: ColumnDataType.Numeric,
                label: `#${originalShipToId} ${TranslationService.getTranslatedText(
                    culture,
                    'previousBalance'
                )}`,
                filterable: false,
                sortable: true,
                searchable: false
            }),
            createColumn('accountToOriginalBalance', {
                dataType: ColumnDataType.Numeric,
                label: `#${receivingShipToId} ${TranslationService.getTranslatedText(
                    culture,
                    'previousBalance'
                )}`,
                filterable: false,
                sortable: true,
                searchable: false
            }),
            createColumn('quantity', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'requestedQuantityToTrade'),
                filterable: false,
                sortable: true,
                searchable: false
            }),
            createColumn('originalShipToNewProductionBalance', {
                dataType: ColumnDataType.Numeric,
                label: `#${originalShipToId} ${TranslationService.getTranslatedText(
                    culture,
                    'updatedBalance'
                )}`,
                filterable: false,
                sortable: true,
                searchable: false
            }),
            createColumn('receivingShipToNewProductionBalance', {
                dataType: ColumnDataType.Numeric,
                label: `#${receivingShipToId} ${TranslationService.getTranslatedText(
                    culture,
                    'updatedBalance'
                )}`,
                filterable: false,
                sortable: true,
                searchable: false
            }),
            createColumn('quantityPerPallet', {
                dataType: ColumnDataType.Numeric,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('status', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'requestStatus'),
                filterable: true,
                sortable: true,
                searchable: true,
                visible: true
            })
        ];
    };
}
