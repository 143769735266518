import { TableRow, TableCell, Grid, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { MakeItBulkATMLineItem } from '../../../../store/reducers/makeit-bulk-atm';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { blackWeight, regularWeight, small } from '../../../../themes/globalConstants';
import { currencyFormatter, formatNumberWithLocale } from '../../../../utility/helpers/formatters';
import { getFeeStructure } from '../../../../utility/helpers/make-it-bulk-helpers';
import { getMoqFee } from '../../../../utility/helpers/production-order-helpers';

interface Props {
    item: MakeItBulkATMLineItem;
}

const useStyles = makeStyles((theme) => ({
    tableRowContainer: {
        '& .helper-text': {
            fontWeight: `${regularWeight} !important`,
            fontSize: small
        }
    },
    bold: {
        fontWeight: blackWeight
    }
}));

const MakeItConversionConfirmationOrderSectionRow = ({ item }: Props) => {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const revisedEachesValue =
        item.palletQuantity && item.quantityPerPallet
            ? item.palletQuantity * item.quantityPerPallet
            : 0;
    const moqFee = getMoqFee(item.palletQuantity, item.moqFees) ?? 0;

    const getFee = () => {
        const fee = getFeeStructure(item);
        switch (true) {
            case fee === 'hasFees' && moqFee > 0:
                return currencyFormatter(cultureCode, moqFee);
            default:
                return '-';
        }
    };

    return (
        <>
            <TableRow
                className={classes.tableRowContainer}
                data-testid={`product-${item.displayId}`}
            >
                <TableCell data-testid="display-id">
                    <Grid container alignContent="center">
                        <Grid container>
                            <Typography className={classes.bold}>{item.displayId}</Typography>
                        </Grid>
                        <Grid container>
                            <Typography>{item.graphicIdAndVersion}</Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell data-testid="product-description">
                    <Grid container alignContent="center">
                        <Grid container>
                            <Typography className={classes.bold}>{item.displayName}</Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell data-testid="coating">
                    <Grid container alignContent="center">
                        <Grid container>
                            <Typography className={classes.bold}>{item.coating}</Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell data-testid="pallet-quantity">
                    <Grid container alignContent="center">
                        <Grid container>
                            <Typography className={classes.bold}>
                                {!item.deleted && item.palletQuantity
                                    ? formatNumberWithLocale(cultureCode, item.palletQuantity)
                                    : 0}{' '}
                                PL
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography className="helper-text" data-testid="previous-quantity-eaches">
                            {item.deleted
                                ? 0
                                : formatNumberWithLocale(cultureCode, Number(revisedEachesValue))}
                            {' ea.'}
                        </Typography>
                    </Grid>
                </TableCell>
                <TableCell data-testid="fee">
                    <Grid container alignContent="center">
                        {getFee()}
                    </Grid>
                </TableCell>
            </TableRow>
        </>
    );
};

export default MakeItConversionConfirmationOrderSectionRow;
