import {
    MAKE_SUMMARY_ERROR,
    MAKE_SUMMARY_LOAD_DRAFT,
    MAKE_SUMMARY_LOAD_OPEN,
    MAKE_SUMMARY_LOAD_CLOSED,
    MAKE_SUMMARY_LOAD_PENDING,
    MAKE_SUMMARY_LOADING,
    MAKE_SUMMARY_RESET,
    MAKE_SUMMARY_PENDING_CANCEL_REQUESTED,
    MAKE_SUMMARY_PENDING_CANCEL_COMPLETED,
    MAKE_SUMMARY_PENDING_CANCEL_ERROR,
    MAKE_SUMMARY_SET_SEARCH,
    MAKE_SUMMARY_USE_SEARCH
} from '../actions/action-types';
import { ProductionOrder } from './makeit-dashboard';
import { Product } from './product-portfolio';

export interface ProductOrderLine {
    productionOrderLineId: string;
    productId: string;
    productSku?: string;
    shortProductId: string;
    status: string;
    quantity: number;
    requestedDate: Date;
    splitOrder: boolean;
    createDate: Date;
    updateDate?: Date;
    assessedFee?: number;
    product?: Product;
    productEndProfile?: string;
    productGraphicId?: string;
    productShape?: string;
}

//Statuses will be PMT_D, PMT_R, CANC
export interface Invoice {
    invoiceId: string;
    dataWarehouseId?: string;
    remittanceNumber?: string;
    amount: number;
    paymentDueDate: Date;
    status: string;
    productionOrderId: string;
    submittedDate?: Date;
    shipToId: number;
    shipToString?: string;
    customerProductionOrderId: string;
    productionOrderNumber: number;
}

export interface InvoiceRow {
    invoiceId: string;
    dataWarehouseId?: string;
    remittanceNumber?: string;
    amount: number;
    paymentDueDate: Date;
    paymentDueDateString: string;
    status: string;
    productionOrderId: string;
    submittedDate?: Date;
    submittedDateString?: string;
    shipToId: number;
    shipToName?: string;
    shipToAddress?: string;
    customerProductionOrderId: string;
    productionOrderNumber: string;
    ballPONumber?: number;
    searchableBallPONumber?: string;
    totalOrderQuantity?: number;
    totalOrderSKUs?: number;
    orderQuantityString?: string;
    orderQuantityEachesString?: string;
    revisedAvailableDate?: string;
    revisedAvailableDateString?: string;
    requestedDate?: string;
    requestedDateString?: string;
}

export interface ProductionOrderSummaryResponse {
    summaries: ProductionOrderSummary[];
}

export interface ProductionOrderSummary {
    availableToShipQuantity: number;
    availableToShipSKUs: number;
    canQuantityEaches: number;
    canQuantityPallets: number;
    canQuantitySKUs: number;
    createDate: Date;
    customerProductionOrderId: string;
    endQuantityEaches: number;
    endQuantityPallets: number;
    endQuantitySKUs: number;
    firstRequestedDate?: Date;
    invoiceStatus?: string;
    isLocked: boolean;
    lastShipmentDate?: Date;
    productionOrderId: number;
    productionOrderNumber: number;
    searchTerms?: string;
    shipToId: number;
    status: string;
    submittedDate: Date;
    totalOrderQuantity: number;
    totalOrderQuantityEaches?: number;
    totalOrderSKUs: number;
    totalShipments?: number;
    updateDate: Date;
    originalProductionOrderNumber?: number;
    invoiceId?: number;
    invoicePaymentDueDate?: Date;
    invoiceAmount?: number;
    invoiceRemittanceNumber?: string;
    lastSaved?: string;
    atmWeekStart?: Date;
    atmOrderType?: string;
    requiredShipDate?: string;
    availableQuantity?: number;
    availableEaches?: number;
}

export interface ProductionOrderRow {
    shipToName?: string;
    shipToAddress?: string;
    productionOrderId?: number;
    searchablePONumber?: string;
    productionOrderNumber?: string;
    ballPONumber?: number;
    customerProductionOrderId?: string;
    status?: string;
    region?: string;
    country?: string;
    submittedDate?: Date;
    submittedBy?: string;
    createdBy?: string;
    createDate: Date;
    updateDate?: Date;
    totalOrderQuantity: number;
    totalOrderQuantityEaches?: number;
    totalOrderSKUs: number;
    availableToShipQuantity: number;
    availableToShipSKUs: number;
    shipToId: number;
    shipToProdOrderType?: string;
    totalFees?: number;
}

export interface OpenProductionOrderRow extends ProductionOrderRow {
    lines?: ProductOrderLine[];
    invoice?: Invoice;
    lastSaved?: string;
    orderQuantityString?: string;
    orderQuantityEachesString?: string;
    submittedDateString?: string;
    firstRequestedDateString?: string;
    isLocked?: boolean;
    requiredShipDateString?: string;
    availableQuantityString?: string;
    availableEachesString?: string;
}

export interface ClosedProductionOrderRow extends ProductionOrderRow {
    originalProductionOrderNumber?: number;
    newProductionOrderNumber?: string;
    newProductionOrderId?: number;
    lines?: ProductOrderLine[];
    lastSaved?: string;
    closed?: string;
    orderQuantityString?: string;
    orderQuantityEachesString?: string;
    submittedDateString?: string;
    firstRequestedDateString?: string;
    closedString?: string;
    closedSortIndex?: number;
}

export interface LastShipmentDateRequest {
    productionOrderIds?: number[];
}

export interface LastShipmentDate {
    productionOrderId: number;
    lastShipmentDate?: string;
}

export interface LastShipmentDates {
    dates: LastShipmentDate[];
}
export interface Orders {
    orders: ProductionOrder[];
}

export interface MakeItSummaryState {
    openOrders: ProductionOrderSummary[];
    openOrdersLoaded: boolean;
    closedOrders: ProductionOrderSummary[];
    closedOrdersLoaded: boolean;
    draftOrders: ProductionOrderSummary[];
    draftOrdersLoaded: boolean;
    pendingOrders: ProductionOrderSummary[];
    pendingOrdersLoaded: boolean;
    loaded: boolean;
    error?: any;
    pendingProdOrderCancelled?: number;
    storedSearch?: string;
    useStoredSearch: boolean;
}

const initialState: MakeItSummaryState = {
    openOrders: [],
    closedOrders: [],
    draftOrders: [],
    pendingOrders: [],
    loaded: false,
    error: undefined,
    openOrdersLoaded: false,
    closedOrdersLoaded: false,
    draftOrdersLoaded: false,
    pendingOrdersLoaded: false,
    pendingProdOrderCancelled: undefined,
    storedSearch: '',
    useStoredSearch: false
};

const makeItSummary = (
    state: MakeItSummaryState = initialState,
    action: { type: string; [x: string]: any }
): MakeItSummaryState => {
    switch (action.type) {
        case MAKE_SUMMARY_LOADING:
            return {
                ...state,
                loaded: false,
                error: undefined,
                openOrdersLoaded: false,
                closedOrdersLoaded: false,
                draftOrdersLoaded: false,
                pendingOrdersLoaded: false
            };
        case MAKE_SUMMARY_LOAD_DRAFT:
            return { ...state, draftOrders: action.orders, draftOrdersLoaded: true };
        case MAKE_SUMMARY_ERROR:
            return {
                ...state,
                error: action.error,
                loaded: true
            };
        case MAKE_SUMMARY_LOAD_OPEN:
            return { ...state, openOrders: action.orders, openOrdersLoaded: true };
        case MAKE_SUMMARY_LOAD_CLOSED:
            return { ...state, closedOrders: action.orders, closedOrdersLoaded: true };
        case MAKE_SUMMARY_LOAD_PENDING:
            return { ...state, pendingOrders: action.orders, pendingOrdersLoaded: true };
        case MAKE_SUMMARY_PENDING_CANCEL_REQUESTED:
            return { ...state, pendingProdOrderCancelled: undefined };
        case MAKE_SUMMARY_PENDING_CANCEL_COMPLETED:
            return {
                ...state,
                pendingProdOrderCancelled: action.productionOrderId
            };
        case MAKE_SUMMARY_PENDING_CANCEL_ERROR:
            return {
                ...state,
                error: action.error,
                pendingProdOrderCancelled: undefined
            };
        case MAKE_SUMMARY_SET_SEARCH:
            return {
                ...state,
                storedSearch: action.searchValue
            };
        case MAKE_SUMMARY_USE_SEARCH:
            return {
                ...state,
                useStoredSearch: action.useStoredSearch
            };
        case MAKE_SUMMARY_RESET:
            return {
                ...initialState,
                storedSearch: state.storedSearch,
                useStoredSearch: state.useStoredSearch
            };
        default:
            return state;
    }
};

export default makeItSummary;
