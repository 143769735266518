import React, { useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import ViewFilterToolbar from '../../../reusable/molecules/ViewFilterToolbar';
import { viewTypes, getViewLabel } from '../../../../utility/helpers/filter-helpers';
import { ScrapItState } from '../../../../store/reducers/scrap-it';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import ErrorAlert from '../../../reusable/atoms/ErrorAlert';
import { useTranslation } from 'react-i18next';

interface Props {
    onViewFiltersClicked: (viewType: string[]) => void;
    error: boolean;
}

const useStyles = makeStyles((theme) => ({
    main: {
        borderTop: 'none',
        borderRadius: 'unset'
    },
    toolbarWrapper: {
        padding: '1.125em 0',
        alignItems: 'center',
        '& .MuiGrid-container': {
            margin: 0
        }
    },
    filtersWrapper: {
        display: 'flex',
        alignItems: 'baseline',
        marginTop: '1em'
    }
}));

export default function ScrapItToolbar({
    onViewFiltersClicked, error
}: Props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const { view } = useTypedSelector<ScrapItState>((state) => state.scrapItState);
    const [viewLabel, setViewLabel] = useState<string>('');

    useEffect(() => {
        setViewLabel(getViewLabel(view));
    }, [view]);

    return (
        <Grid container className={classes.toolbarWrapper} justify="space-between">
            <ErrorAlert showError={error} errorMessage={t('zeroForecastQuantity', 'Forecasted quantity cannot be 0. Please enter a quantity or remove the product from the forecast.')}/>
            <Grid item xs={12} className={classes.filtersWrapper}>
                <ViewFilterToolbar
                    viewTypes={viewTypes}
                    viewChange={(newView) => onViewFiltersClicked(newView)}
                    testId={'view-btn-grp'}
                    value={viewLabel}
                />
            </Grid>
        </Grid>
    );
}
