import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography, TableRow, TableCell, Grid, Input } from '@material-ui/core';
import Button from '../../../reusable/atoms/Button';
import shipItConfigColors from './ShipItConfigColors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ProductToShip, ShippingState } from '../../../../store/reducers/shipping-dashboard';
import { numericRegex } from '../../../../utility/helpers/formatters';
import {
    constructCanDescription,
    constructEndDescription,
    isProductCanType,
    isProductEndType,
    getGraphicIdAndVersion
} from '../../../../utility/helpers/order-helpers';
import {
    ballLtGray,
    orange,
    shipItReferenceNumberMaxLength,
    shipItReleaseNumberMaxLength,
    shipItPONumberMaxLength,
    deliveryInstructionsMaxLength
} from '../../../../themes/globalConstants';
import { Flags, FlagTypes } from '../../../../utility/helpers/feature-flag';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { useDispatch } from 'react-redux';
import { updateExpandableLoad } from '../../../../store/actions/shipping-dashboard';
import ShipmentExpandableSection from '../../../reusable/molecules/ShipmentExpandableSection';
import QuantityInputCell from '../../../reusable/atoms/QuantityInputCell';

interface Props {
    shipmentId?: string;
    item: ProductToShip;
    onUpdateItem: (item: ProductToShip) => void;
    onRemoveItem: (item: ProductToShip) => void;
    sequence: number;
    readOnly?: boolean;
}

const useStyles = makeStyles((theme) => ({
    tableRowContainer: {
        '& .MuiInputBase-input': {
            textAlign: 'right',
            paddingRight: '.25em'
        }
    },
    textField: {
        border: `2px solid ${ballLtGray}`,
        borderRadius: '.125em',
        width: '100%'
    },
    smalltextField: {
        width: '3.125em',
        height: '1.5em',
        marginRight: '0.5em'
    },
    alignLeft: {
        '& .MuiInputBase-input': {
            textAlign: 'left',
            paddingLeft: '0.375em'
        }
    },
    paletteInputContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    trash: {
        paddingRight: '0',
        minWidth: 'unset',
        '&:hover': {
            backgroundColor: 'white'
        },
        '& svg': {
            fill: orange
        }
    },
    colorBlock: {
        width: 16,
        height: 50
    },
    sequence: {
        marginLeft: '0.750em'
    },
    expand: {
        transform: 'rotate(0)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: 'rotate(180deg)'
    }
}));

export default function ShipItConfigRow({
    shipmentId,
    item,
    onRemoveItem,
    sequence,
    onUpdateItem,
    readOnly = false
}: Props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [palletQuantity, setPalletQuantity] = useState(0);
    const [releaseNumber, setReleaseNumber] = useState('');
    const [deliveryInstructions, setDeliveryInstructions] = useState('');
    const [purchaseOrderNumber, setPONumber] = useState<string>('');
    const [fillerLine, setFillerLine] = useState('');
    const [reference, setReference] = useState('');
    const canBottleDesc = constructCanDescription(item);
    const endDesc = constructEndDescription(item);
    const isEndType = isProductEndType(item.type);
    const isCanType = isProductCanType(item.type);
    const [active, setActive] = useState(false);
    const { expandableLoads } = useTypedSelector<ShippingState>((state) => state.shippingDashboard);
    const [showExpansionSection, SetShowExpansionSection] = useState<boolean>(true);

    useEffect(() => {
        if (expandableLoads) {
            const expandableLoad = expandableLoads?.find(
                (l) => l.displayId === item.displayId && l.shipmentId === shipmentId
            );
            if (expandableLoad) {
                setActive(expandableLoad.expanded);
            } else {
                if (shipmentId && item) {
                    dispatch(
                        updateExpandableLoad({
                            shipmentId: shipmentId,
                            displayId: item.displayId,
                            expanded: false
                        })
                    );
                }
            }
        }
    }, [dispatch, expandableLoads, item, shipmentId]);

    const toggleExpand = () => {
        if (showExpansionSection) {
            dispatch(
                updateExpandableLoad({
                    shipmentId: shipmentId,
                    displayId: item.displayId,
                    expanded: !active
                })
            );
        }
    };

    const updatePalletCount = (count: string) => {
        const numOfPallets = parseInt(count);
        if (numericRegex(count)) {
            !isNaN(numOfPallets) ? setPalletQuantity(numOfPallets) : setPalletQuantity(0);
        }
    };

    const updateOptionalField = (fieldValue: string, fieldName: string) => {
        if (fieldName === 'releaseNumber') {
            setReleaseNumber(fieldValue.slice(0, shipItReleaseNumberMaxLength));
        } else if (fieldName === 'reference') {
            setReference(fieldValue.slice(0, shipItReferenceNumberMaxLength));
        } else if (fieldName === 'purchaseOrderNumber') {
            setPONumber(fieldValue.slice(0, shipItPONumberMaxLength));
        } else if (fieldName === 'deliveryInstructions') {
            setDeliveryInstructions(fieldValue.slice(0, deliveryInstructionsMaxLength));
        }
    };

    const handleSelectChange = (fieldValue: string) => {
        setFillerLine(fieldValue);
    };

    const handleRemoveItem = () => {
        onRemoveItem(item);
    };

    const handleUpdateItem = (fieldValue: string, fieldName: string) => {
        if (fieldName === 'palletQuantity') {
            const numOfPallets = parseInt(fieldValue);
            let currentProduct: ProductToShip = {
                ...item,
                [fieldName]: numOfPallets,
                sequence: sequence
            };

            if (numericRegex(fieldValue)) {
                !isNaN(numOfPallets) ? setPalletQuantity(numOfPallets) : setPalletQuantity(0);
                !isNaN(numOfPallets) && onUpdateItem(currentProduct);
            }
        } else {
            let currentProduct: ProductToShip = { ...item, [fieldName]: fieldValue };
            onUpdateItem(currentProduct);
        }
    };

    useEffect(() => {
        if (item.palletQuantity !== undefined) {
            setPalletQuantity(item.palletQuantity);
        }
        if (item.releaseNumber !== undefined) {
            setReleaseNumber(item.releaseNumber);
        }
        if (item.fillerLine !== undefined) {
            setFillerLine(item.fillerLine);
        }
        if (item.reference !== undefined) {
            setReference(item.reference);
        }
        if (item.purchaseOrderNumber !== undefined) {
            setPONumber(item.purchaseOrderNumber);
        }
        if (item.deliveryInstructions !== undefined) {
            setDeliveryInstructions(item.deliveryInstructions);
        }
        if (item) {
            SetShowExpansionSection(
                !readOnly ||
                    !!(
                        item.releaseNumber ||
                        item.fillerLine ||
                        item.reference ||
                        item.deliveryInstructions
                    )
            );
        }
    }, [item, readOnly]);

    useTranslation();

    return (
        <>
            <TableRow
                className={classes.tableRowContainer}
                data-testid={`product-${item.productSku}`}
            >
                <TableCell data-testid="sequence">
                    <Grid container alignItems="center">
                        {readOnly ? (
                            <Grid item className={classes.sequence}>
                                <Typography>{sequence}</Typography>
                            </Grid>
                        ) : (
                            <>
                                <Grid
                                    item
                                    className={classes.colorBlock}
                                    style={{
                                        backgroundColor: `${shipItConfigColors[sequence - 1]}`
                                    }}
                                />
                                <Grid item className={classes.sequence}>
                                    <Typography>{sequence}</Typography>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </TableCell>
                <TableCell>
                    <Typography>{item.customerProductId ? item.customerProductId : "-"}</Typography>
                    <Typography data-testid="product-sku">{item.displayId}</Typography>
                    <Typography data-testid="graphic-id">
                        {getGraphicIdAndVersion(item.type, item.graphicIdAndVersion)}
                    </Typography>
                </TableCell>
                <TableCell data-testid="product-description">
                    <Typography>{item.displayName}</Typography>
                    {isEndType && <Typography>{endDesc}</Typography>}
                    {isCanType && <Typography>{canBottleDesc}</Typography>}
                </TableCell>
                <TableCell>
                    <Grid container alignContent="center">
                        <Grid item className={classes.paletteInputContainer}>
                            {readOnly ? (
                                <Typography>{palletQuantity} PL</Typography>
                            ) : (
                                <>
                                    <QuantityInputCell
                                        quantity={palletQuantity}
                                        quantityPerPallet={item.quantityPerPallet!}
                                        quantityUnit="pallets"
                                        onBlur={(value) =>
                                            handleUpdateItem(value, 'palletQuantity')
                                        }
                                        onChange={updatePalletCount}
                                    />
                                </>
                            )}
                        </Grid>
                    </Grid>
                </TableCell>
                <Flags authorizedFlags={[FlagTypes.Coke]}>
                    <TableCell data-testid="po-number">
                        <Grid item>
                            {readOnly ? (
                                <Typography>{purchaseOrderNumber}</Typography>
                            ) : (
                                <Input
                                    type="text"
                                    data-testid="po-num-input"
                                    className={clsx(classes.textField, classes.alignLeft)}
                                    disableUnderline={true}
                                    name="purchaseOrderNumber"
                                    onBlur={(e) => handleUpdateItem(e.target.value, e.target.name)}
                                    onChange={(e) =>
                                        updateOptionalField(e.target.value, e.target.name)
                                    }
                                    value={purchaseOrderNumber}
                                />
                            )}
                        </Grid>
                    </TableCell>
                </Flags>
                <TableCell>
                    <ExpandMoreIcon
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: active && showExpansionSection
                        })}
                        onClick={toggleExpand}
                        color={showExpansionSection ? 'primary' : 'disabled'}
                    />
                </TableCell>
                {!readOnly && (
                    <TableCell>
                        <Button
                            type="button"
                            className={classes.trash}
                            onClick={handleRemoveItem}
                            data-testid="trash-icon"
                        >
                            <img
                                src={process.env.PUBLIC_URL + '/assets/Trash.svg'}
                                alt="Trash Icon"
                            />
                        </Button>
                    </TableCell>
                )}
            </TableRow>
            {showExpansionSection ? (
                <ShipmentExpandableSection
                    active={active}
                    releaseNumber={releaseNumber}
                    reference={reference}
                    deliveryInstructions={deliveryInstructions}
                    fillerLine={fillerLine}
                    readOnly={readOnly}
                    handleUpdateItem={handleUpdateItem}
                    updateOptionalField={updateOptionalField}
                    handleSelectChange={handleSelectChange}
                />
            ) : null}
        </>
    );
}
