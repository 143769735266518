import React, { ReactNode, ReactElement } from 'react';
import { Link as MaterialLink, makeStyles, Divider } from '@material-ui/core';
import { createQueryParam } from '../../../utility/helpers/query-helpers';
import { disabledGrey } from '../../../themes/globalConstants';
import clsx from 'clsx';
// To use an Internal Link:
// import { Link as RouterLink } from 'react-router-dom
// use 'to' props with redirect (ie '/product-portfolio)
// use component={RouterLink}

// To use External Link:
// use 'url' props for the href
// use 'target="_blank"', 'rel="noreferrer noopener"' Props

// To simulate a button:
// use component="button"
// use onClick for onClick function

interface Props {
    url?: string;
    target?: string;
    rel?: string;
    to?: string;
    id?: string;
    component?: any;
    className?: string;
    underline?: any;
    children?: ReactNode;
    queryContent?: any;
    disabled?: boolean;
    alignLeft?: boolean;
    onClick?: (any) => void;
    smallDivider?: boolean;
    displayBlock?: boolean;
}

const useStyles = makeStyles(
    (theme) => ({
        //If underline=hover, apply these specific styles
        underlineHover: {
            '&:hover': {
                color: theme.palette.primary.main,
                textDecoration: 'none'
            },
            '&:hover $divider': {
                width: '100%',
                transform: 'scaleX(1)',
                transformOrigin: '0% 100%',
                transition: 'width 150ms linear'
            }
        },
        disabled: {
            color: disabledGrey,
            cursor: `not-allowed`,
            textDecoration: `none`,
            '&:hover': {
                color: disabledGrey
            }
        },
        alignLeft: {
            textAlign: 'left'
        },
        flexDiv: {
            display: 'inline-block'
        },
        divider: {
            backgroundColor: theme.palette.primary.main,
            marginTop: '0.625em',
            width: '1.25em',
            textAlign: 'left'
        },
        smallDivider: {
            marginTop: '0.1em'
        },
        block: { display: 'block' }
    }),
    { name: 'MuiLink' }
);

const Link = (props: Props): ReactElement => {
    const classes = useStyles();
    const includeDivider = props.underline === 'hover';
    let queryString = '';

    // Iterates over any future content we might need to create for query strings
    if (props.queryContent) {
        queryString = createQueryParam(props.queryContent);
    }

    function handleOnClick(e) {
        if (!!props.disabled) {
            e.preventDefault();
        } else if (props.onClick) {
            props.onClick(e);
        }
    }

    return (
        <div className={`${classes.flexDiv} ${props.displayBlock ? classes.block : ''}`}>
            <MaterialLink
                id={props.id}
                underline={props.underline}
                className={`${props.className} ${props.disabled ? classes.disabled : ''} ${
                    props.alignLeft ? classes.alignLeft : ''
                }`}
                href={props.url}
                component={props.component}
                onClick={handleOnClick}
                target={props.target}
                rel={props.rel}
                to={props.to + queryString}
                data-testid={props['data-testid']}
            >
                {props.children}
                {includeDivider && (
                    <Divider
                        className={clsx(classes.divider, {
                            [classes.smallDivider]: props.smallDivider
                        })}
                    />
                )}
            </MaterialLink>
        </div>
    );
};

export default Link;
