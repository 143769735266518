import React from 'react';
import MuiPhoneNumber from 'material-ui-phone-number';
import { makeStyles } from '@material-ui/core';
import { red } from '../../../themes/globalConstants';
import clsx from 'clsx';

interface Props {
    label?: React.ReactNode;
    name?: string;
    testId?: string;
    defaultCountry?: string;
    disableAreaCodes?: boolean;
    disableCountryCode?: boolean;
    onlyCountries?: string[];
    onChange?: (data: any) => void;
    defaultValue?: string;
    error?: boolean;
    onBlur?: () => any;
}

const useStyles = makeStyles((theme) => ({
    input: {
        '& .MuiInputBase-root': {
            height: '3.95em'
        }
    },
    error: {
        '& .MuiFilledInput-underline:after': {
            transform: 'scaleX(1)',
            borderBottomColor: red
        },

        '& .MuiFormLabel-root': {
            color: red
        }
    }
}));

const PhoneInput = (props: Props) => {
    const classes = useStyles();

    return (
        <MuiPhoneNumber
            fullWidth
            variant="filled"
            label={props.label}
            name={props.name}
            data-testid={props.testId}
            defaultCountry={props.defaultCountry}
            disableAreaCodes={props.disableAreaCodes}
            disableCountryCode={props.disableCountryCode}
            onlyCountries={props.onlyCountries}
            inputClass={clsx(classes.input, { [classes.error]: props.error })}
            onChange={props.onChange}
            defaultValue={props.defaultValue}
            value={props.defaultValue}
            onBlur={props.onBlur}
        />
    );
};

export default PhoneInput;
