import React, { createRef, useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
    makeStyles,
    Container,
    Grid,
    Hidden,
    Paper,
    Typography,
    Box,
    Divider
} from '@material-ui/core';
import HoverNavMenu from '../../reusable/molecules/HoverNavMenu';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import {
    successGreen,
    boldWeight,
    ltBlueGrey_8,
    ltBlueGrey_34,
    xl,
    paleGrey,
    blackWeight,
    medium
} from '../../../themes/globalConstants';
import FormSection from '../../reusable/molecules/FormSection';
import Button from '../../reusable/atoms/Button';
import Link from '../../reusable/atoms/Link';
import { Link as RouterLink } from 'react-router-dom';
import Modal from '../../reusable/molecules/Modal';
import GraphicsIntakeFormPDF from './components/GraphicsIntakeFormPDF';
import { useTypedSelector } from '../../../store/reducers/reducer';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import GraphicsColorMatchingPDF from '../GraphicsColorMatchingReadOnly/components/GraphicsColorMatchingPDF';
import { isIE } from '../../../utility/helpers/user-helpers';
import { Activity } from '../../../utility/auth/useSecurity';

const useStyles = makeStyles((theme) => ({
    //Constrains the footer to the bottom of the page
    main: {
        minHeight: '90vh',
        '& .MuiPaper-root': {
            '&:not(:first-of-type)': {
                marginTop: '2.75em'
            }
        }
    },
    container: {
        padding: '3em'
    },
    paper: {
        boxShadow: `0 3px 6px 0 ${ltBlueGrey_8}`,
        borderRadius: 4,
        padding: '2em',
        marginRight: '2em',
        position: 'sticky',
        top: '5em'
    },
    completedIcon: {
        fill: successGreen,
        width: 23,
        height: 23,
        opacity: 0.6
    },
    bodyHeader: {
        fontSize: xl,
        fontWeight: boldWeight,
        color: theme.palette.info.dark,
        marginTop: '2em'
    },
    bodyText: {
        fontSize: xl,
        margin: '0.5em 0 2em 0'
    },
    bodyColorText: {
        fontSize: xl,
        margin: '2em 0 0.5em 0'
    },
    bodySubtext: {
        fontWeight: boldWeight,
        marginBottom: '2em'
    },
    previewWrapper: {
        backgroundColor: paleGrey,
        padding: '1.875em',
        display: 'flex'
    },
    previewText: {
        fontSize: medium,
        fontWeight: blackWeight,
        marginLeft: '1.25em'
    },
    divider: {
        height: 5,
        backgroundColor: ltBlueGrey_34,
        width: '100%'
    },
    btn: {
        width: '10em',
        margin: '2em 0'
    },
    printBtn: {
        marginRight: '2em'
    },
    dashboardBtn: {
        width: '16em',
        marginTop: '2em'
    },
    colorLink: {
        color: theme.palette.primary.main,
        fontWeight: blackWeight,
        cursor: 'pointer'
    },
    colorMatchingModal: {
        '& .MuiPaper-root': {
            minWidth: '53.750em',
            height: '100%'
        }
    },
    formSectionContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    ieMessage: {
        padding: '3em'
    }
}));

const GraphicsIntakeSuccessPage = () => {
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(false);
    const [openColorMatching, setOpenColorMatching] = useState<boolean>(false);
    const header = <Trans i18nKey="formOverview">Form Overview</Trans>;
    const { t } = useTranslation();
    const graphicIntake = useTypedSelector((state) => state.graphicIntake);
    const graphics = graphicIntake.graphics;
    const ballLogo = process.env.PUBLIC_URL + '/assets/Ball_RGB.jpg';
    const sourceLogo = process.env.PUBLIC_URL + '/assets/TheSource_Logo.png';

    const icon = (
        <img
            className={classes.completedIcon}
            src={process.env.PUBLIC_URL + '/assets/Confirmation_icon.svg'}
            alt="Confirmation Icon"
        />
    );

    const sections = [
        {
            header: t('graphicsSubmissionInfo', 'Graphics Submission Information'),
            icon: true
        },
        {
            header: t('productOptions', 'Product Options'),
            icon: true
        },
        {
            header: t('colorMatchingOptional', 'Color Matching'),
            icon: true
        },
        {
            header: t('uploadGraphicsAndSubmit', 'Upload Graphics & Submit'),
            icon: true
        }
    ];

    const sectionRefs = sections.map(() => createRef<HTMLElement>());

    const printOrDownloadForm = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onOpenColorMatching = () => {
        setOpenColorMatching(true);
    };

    const onCloseColorMatching = () => {
        setOpenColorMatching(false);
    };

    useEffect(() => {
        if (graphics.colorMatching) {
            setOpenColorMatching(true);
        }
    }, [graphics.colorMatching]);

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('submitGraphics', 'Submit Your Graphics'),
                description: '',
                thinBanner: true
            }}
            activity={Activity.GraphicsIntakeForm}
            shipTos={false}
        >
            <Grid container data-testid="graphics-intake-success" className={classes.main}>
                <Container className={classes.container}>
                    <Grid container item xs={12}>
                        <Grid item sm={4}>
                            <Hidden xsDown>
                                <Paper className={classes.paper}>
                                    <HoverNavMenu
                                        translatedHeader={header}
                                        readOnly={true}
                                        sections={sections
                                            .map((section, index) => ({
                                                ref: sectionRefs[index],
                                                header: section.header,
                                                icon: section.icon,
                                                iconSrc: icon
                                            }))
                                            .filter((section) => section.header)}
                                    />
                                </Paper>
                            </Hidden>
                        </Grid>
                        <Grid container item xs={8} className={classes.formSectionContainer}>
                            <Grid item xs={12}>
                                <FormSection
                                    testId={'graphics-success'}
                                    sectionHeader={
                                        <Trans i18nKey="excitedThankYou">Thank You!</Trans>
                                    }
                                >
                                    <Grid item xs={12}>
                                        <Typography variant="body1" className={classes.bodyHeader}>
                                            <Trans i18nKey="graphicsSubmitted">
                                                Your Graphics have been submitted for review!
                                            </Trans>
                                        </Typography>
                                        <Typography variant="body1" className={classes.bodyText}>
                                            <Trans i18nKey="submissionText">
                                                Our team will review your submission for suitability
                                                and develop a quote. A graphic specialist will be in
                                                touch within 2 business days.
                                            </Trans>
                                        </Typography>
                                        <Typography variant="body1" className={classes.bodySubtext}>
                                            <Trans i18nKey="subText">
                                                Your form is also available to view. Pleave save
                                                this for your records.
                                            </Trans>
                                        </Typography>
                                        <Grid item xs={6}>
                                            <Box className={classes.previewWrapper}>
                                                <PictureAsPdfIcon />
                                                <Typography className={classes.previewText}>
                                                    <Trans i18nKey="intakeForm">Intake Form:</Trans>{' '}
                                                    {graphics.requestName}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12}>
                                        <Grid item xs={6}>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                data-testid="print-download-button"
                                                className={classes.btn}
                                                onClick={printOrDownloadForm}
                                            >
                                                <Trans i18nKey="printOrDownload">
                                                    Print/Download
                                                </Trans>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    {graphics.colorMatching && (
                                        <Grid container item>
                                            <Typography
                                                variant="body1"
                                                className={classes.bodyColorText}
                                            >
                                                <Trans i18nKey="pleasePrintColorMatchingForm">
                                                    To print or download a copy of your color
                                                    matching form, please
                                                </Trans>{' '}
                                                <Link
                                                    className={classes.colorLink}
                                                    onClick={onOpenColorMatching}
                                                >
                                                    <Trans i18nKey="clickHere">click here.</Trans>
                                                </Link>
                                            </Typography>
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <Divider className={classes.divider} />
                                    </Grid>
                                    <Grid container item xs={12}>
                                        <Grid item xs={2}>
                                            <Link
                                                component={RouterLink}
                                                to="/graphics-intake"
                                                underline="none"
                                            >
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    data-testid="newform-button"
                                                    className={classes.dashboardBtn}
                                                >
                                                    <Trans i18nKey="startNewForm">
                                                        Start New Form
                                                    </Trans>
                                                </Button>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12}>
                                        <Grid item xs={2}>
                                            <Link
                                                component={RouterLink}
                                                to="/graphics-summary"
                                                underline="none"
                                            >
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    data-testid="dashboard-button"
                                                    className={classes.dashboardBtn}
                                                    onClick={printOrDownloadForm}
                                                >
                                                    <Trans i18nKey="backToDashboard">
                                                        Back to My Dashboard
                                                    </Trans>
                                                </Button>
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </FormSection>
                            </Grid>
                            {graphics.requestType === 'revision' && (
                                <Grid item xs={12}>
                                    <FormSection
                                        testId={'graphics-success-color-matching'}
                                        sectionHeader={
                                            <Trans i18nKey="graphicsColorSubmitted">
                                                Thank you for submitting your update!
                                            </Trans>
                                        }
                                    >
                                        <Grid container item>
                                            <Typography
                                                variant="body1"
                                                className={classes.bodyColorText}
                                            >
                                                <Trans i18nKey="submissionColorText">
                                                    Our team will review this update and a graphics
                                                    specialist will be in touch with you shortly. If
                                                    you would like to review your submission or
                                                    reprint a color matching form, please{' '}
                                                </Trans>
                                                <Link
                                                    className={classes.colorLink}
                                                    onClick={onOpenColorMatching}
                                                >
                                                    <Trans i18nKey="clickHere">click here.</Trans>
                                                </Link>
                                            </Typography>
                                        </Grid>
                                        <Grid container item xs={12}>
                                            <Grid item xs={2}>
                                                <Link
                                                    component={RouterLink}
                                                    to="/graphics-summary"
                                                    underline="none"
                                                >
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        data-testid="dashboard-button"
                                                        className={classes.dashboardBtn}
                                                        onClick={printOrDownloadForm}
                                                    >
                                                        <Trans i18nKey="backToDashboard">
                                                            Back to My Dashboard
                                                        </Trans>
                                                    </Button>
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    </FormSection>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            {!isIE() ? (
                <>
                    <Modal
                        open={open}
                        title="Graphics Summary"
                        close={onClose}
                        closeIcon={true}
                        fullScreen={true}
                    >
                        <GraphicsIntakeFormPDF
                            title={t('graphicsSummary', 'Graphics Summary')}
                            graphics={graphics}
                            sourceLogo={sourceLogo}
                            ballLogo={ballLogo}
                        />
                    </Modal>
                    <Modal
                        open={openColorMatching}
                        title={
                            <Trans i18nKey="viewColorMatchingInfo">
                                View Color Matching Information
                            </Trans>
                        }
                        close={onCloseColorMatching}
                        closeIcon={true}
                        className={classes.colorMatchingModal}
                    >
                        <GraphicsColorMatchingPDF
                            title={t('graphicsSummary', 'Graphics Summary')}
                            graphics={graphics}
                            sourceLogo={sourceLogo}
                            ballLogo={ballLogo}
                        />
                    </Modal>
                </>
            ) : (
                <>
                    <Modal
                        open={open}
                        title={t('graphicsSummary', 'Graphics Summary')}
                        close={onClose}
                        closeIcon={true}
                        maxWidth={'md'}
                    >
                        <Typography variant="h4" className={classes.ieMessage}>
                            <Trans i18nKey="graphicsInformationMessageIE">
                                Your Graphics Summary Information is available for you to open or
                                download within your browser.
                            </Trans>
                        </Typography>
                        <GraphicsIntakeFormPDF
                            title={t('graphicsSummary', 'Graphics Summary')}
                            graphics={graphics}
                            sourceLogo={sourceLogo}
                            ballLogo={ballLogo}
                        />
                    </Modal>
                    <Modal
                        open={openColorMatching}
                        title="View Color Matching Information"
                        close={onCloseColorMatching}
                        closeIcon={true}
                        maxWidth={'md'}
                    >
                        <Typography variant="h4" className={classes.ieMessage}>
                            <Trans i18nKey="colorMatchingInformationMessageIE">
                                Your Color Matching Information is available for you to open or
                                download within your browser.
                            </Trans>
                        </Typography>
                        <GraphicsColorMatchingPDF
                            title={t('graphicsSummary', 'Graphics Summary')}
                            graphics={graphics}
                            sourceLogo={sourceLogo}
                            ballLogo={ballLogo}
                        />
                    </Modal>
                </>
            )}
        </ProcessingPageTemplate>
    );
};

export default GraphicsIntakeSuccessPage;
