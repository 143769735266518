import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ballGray, lato, ltBlueGrey } from '../../../../themes/globalConstants';
import { Trans } from 'react-i18next';

interface Props {
    step: string;
}

const useStyles = makeStyles(() => ({
    circle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: 175,
        height: 175,
        border: 'solid',
        borderRadius: '50%',
        borderWidth: 3,
        borderColor: ltBlueGrey
    },
    stepLabel: {
        fontFamily: lato,
        fontSize: '1.125em',
        fontWeight: 900,
        color: ballGray,
        textTransform: 'uppercase'
    },
    step: {
        fontFamily: lato,
        fontSize: '3.75em',
        fontWeight: 500,
        color: '#202020',
        lineHeight: 1
    }
}));

export default function OnboardingWizardStepLabel({ step }: Props) {
    const classes = useStyles();
    return (
        <div className={classes.circle}>
            <div className={classes.stepLabel}>
                <Trans i18nKey="step">Step</Trans>
            </div>
            <div className={classes.step}>{step}</div>
        </div>
    );
}
