import { Grid, Typography, makeStyles, Button, CircularProgress, Link } from '@material-ui/core';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Prompt, useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { MAKEIT_CONVERSION_UPDATE_ORDER_BY_SNO_SKU } from '../../../store/actions/action-types';
import { setFeesMOQData } from '../../../store/actions/makeit-bulk-atm';
import {
    loadAllocationsForOrderConversion,
    loadForecastForOrderConversion,
    loadProductsForOrderConversion,
    resetMakeItConversionATMState,
    submitMakeItConversion
} from '../../../store/actions/makeit-conversion-atm';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { selectIsImpersonation } from '../../../store/selectors';
import ImpersonationWarning from '../../reusable/molecules/ImpersonationWarning';
import {
    MakeItBulkATMLineItem,
    MakeItBulkATMLinesBySnoSku,
    MakeItBulkATMOrderByWeekAndSku
} from '../../../store/reducers/makeit-bulk-atm';
import { MakeItBulkLineItem } from '../../../store/reducers/makeit-bulk-upload';
import { MakeItConversionATMState } from '../../../store/reducers/makeit-conversion-atm';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { ballBlue, blackWeight, boldWeight, medium, small } from '../../../themes/globalConstants';
import { Activity } from '../../../utility/auth/useSecurity';
import { getAccountAddress } from '../../../utility/helpers/address-helpers';
import {
    addQuantitiesFromOtherOrders,
    DateProperties,
    evaluatePalletQuantityValid,
    getActiveProducts,
    getDateProperties,
    getFeeStructure,
    groupOrdersBySnoSku,
    PalletQuantityState
} from '../../../utility/helpers/make-it-bulk-helpers';
import {
    getWeekValidationState,
    ToggleAll,
    validateMoqAmounts
} from '../../../utility/helpers/order-helpers';
import { useQuery } from '../../../utility/helpers/query-helpers';
import WarningAlert from '../../reusable/atoms/WarningAlert';
import ErrorAlert from '../../reusable/atoms/ErrorAlert';
import ConfirmationModal from '../../reusable/molecules/ConfirmationModal';
import MakeItBulkFeeModal from '../../reusable/molecules/MakeItBulkFeeModal';
import ValidationFilterMenu from '../../reusable/molecules/ValidationFilterMenu';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { DateState } from '../MakeItBulkReviewATM/components/MakeItBulkReviewATMDateTab';
import ShipToDropdown from '../MakeItBulkReviewATM/components/ShipToDropdown';
import WeekSelectorDropdown from '../MakeItBulkReviewATM/components/WeekSelectorDropdown';
import { FormattedAccount } from '../MakeItBulkReviewATM/models/FormattedAccount';
import { FormattedWeek } from '../MakeItBulkReviewATM/models/FormattedWeek';
import MakeItConversionReviewOrderSection from './components/MakeItConversionReviewOrderSection';
import { MakeItConversionReviewProvider } from './context/MakeItConversionReviewContext';
import clsx from 'clsx';
import {
    getCampaignInformation,
    getMoqFeeCeiling,
    getMoqFeeFloor
} from '../../../utility/helpers/production-order-helpers';
import { RouterConfirmationOverride } from '../../../App';
import { OrderProductTableRow } from '../../../store/reducers/orders-dashboard';
import { CampaignRun } from '../../../store/reducers/product-portfolio';
import CurrentCampaignsModal from '../../reusable/molecules/CurrentCampaignsModal';
import { formatDate, isDateWithinAtmLockPeriod } from '../../../utility/helpers/date-helpers';

const useStyles = makeStyles((theme) => ({
    dateSelectSection: {
        paddingTop: '1em'
    },
    viewAtmTitle: {
        fontWeight: blackWeight,
        color: theme.palette.secondary.main,
        textTransform: 'capitalize'
    },
    selector: {
        display: 'flex',
        marginTop: '1em'
    },
    btn: {
        padding: 0,
        minWidth: 'auto',
        fontSize: small,
        fontWeight: boldWeight,
        margin: '0 0.5em'
    },
    actionBtn: {
        height: '3.375em',
        width: '13.75em',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom'
    },
    expandCollapseWrapper: {
        alignItems: 'center',
        color: ballBlue
    },
    divider: {
        borderWidth: '1px 0 0 0',
        borderStyle: 'solid',
        borderColor: '#979797',
        marginTop: '3em',
        marginBottom: '3em'
    },
    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0.5em 0'
    },
    leadTimeWarning: {
        marginBottom: '.5em'
    },
    linkSpacer: {
        marginRight: '.25em'
    },
    linkedPO: {
        textDecoration: `underline ${ballBlue}`
    },
    errorDiv: {
        marginLeft: '-90px',
        marginTop: '-10px'
    },
    subtitle: {
        color: theme.palette.secondary.main
    },
    viewCampaignsButton: {
        fontSize: medium,
        '&:hover': {
            backgroundColor: 'transparent',
            color: ballBlue,
            outline: 'none'
        },
        '&:focus': {
            backgroundColor: 'transparent',
            color: ballBlue
        }
    }
}));

const MakeItConversionReview = () => {
    const query = useQuery();
    const from = query.get('from');
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        copyFromProductionOrderIds,
        copyFromShipToId,
        atmOrders,
        allocations,
        products,
        existingOrders,
        submitLoadingState,
        error,
        ordersBySnoSku,
        existingDrafts
    } = useTypedSelector<MakeItConversionATMState>((state) => state.makeItConversionATMState);
    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const [shipToAccountsList, setShipToAccountsList] = useState<FormattedAccount[]>([]);
    const [formattedWeekList, setFormattedWeekList] = useState<FormattedWeek[]>();
    const [activeDate, setActiveDate] = useState<DateProperties>({
        fullDate: '',
        week: '',
        year: ''
    });
    const [expandAll, setExpandAll] = useState<ToggleAll>({ toggle: false });
    const [showAlerts, setShowAlerts] = useState<boolean>(false);
    const [showErrors, setShowErrors] = useState<boolean>(false);
    const [showWarnings, setShowWarnings] = useState<boolean>(false);
    const [showAll, setShowAll] = useState<boolean>(true);
    const [activeDateOrders, setActiveDateOrders] = useState<MakeItBulkATMOrderByWeekAndSku[]>();
    const [selectedShipTo, setSelectedShipTo] = useState<string>();
    const [pageLoading, setPageLoading] = useState<boolean | undefined>(true);
    const [orderValidated, setOrderValidated] = useState<boolean>(false);
    const [acceptFee, setAcceptFee] = useState<boolean>(false);
    const [openFeeModal, setOpenFeeModal] = useState<boolean>(false);
    const [tableInformation, setTableInformation] = useState<MakeItBulkATMLineItem[]>([]);
    const [orderHasErrors, setOrderHasErrors] = useState<boolean>(false);
    const [openBackModal, setOpenBackModal] = useState<boolean>(false);
    const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
    const history = useHistory();
    const [fromDetails, setFromDetails] = useState<boolean>(from === 'details');
    const [fromSummary, setFromSummary] = useState<boolean>(from === 'summary');
    const [fromProductPlanning, setFromProductPlanning] = useState<boolean>(
        from === 'productplanning'
    );
    const [showSameWeekShipToWarning, setShowSameWeekShipToWarning] = useState(false);
    const [showSameWeekDraftsWarning, setShowSameWeekDraftsWarning] = useState(false);
    const [existingLinesBySnoSku, setExistingLinesBySnoSku] =
        useState<MakeItBulkATMLinesBySnoSku[]>();
    const [showImpersonationWarning, setShowImpersonationWarning] = useState<boolean>(false);
    const isImpersonation = useTypedSelector<boolean>(selectIsImpersonation);
    const [allowNavigation, setAllowNavigation] = useState<boolean>(false);
    const [openBlockedNavigationModal, setOpenBlockedNavigationModal] = useState<boolean>(false);
    const [lastLocationPath, setLastLocationPath] = useState<string>('');
    const [campaignRuns, setCampaignRuns] = useState<CampaignRun[]>();
    const [campaignProducts, setCampaignProducts] = useState<OrderProductTableRow[]>();
    const [showViewCurrentCampaignsLink, setShowViewCurrentCampaignsLink] =
        useState<boolean>(false);
    const [openViewCampaignsModal, setOpenViewCampaignsModal] = useState<boolean>(false);
    const { selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const [showCampaigns, setShowCampaigns] = useState<boolean>(false);

    const onCloseFeeModal = () => {
        setOpenFeeModal(false);
    };

    const onAcceptFee = () => {
        setAcceptFee(true);
    };

    useEffect(() => {
        return () => {
            dispatch(resetMakeItConversionATMState());
        };
    }, [dispatch]);

    // When the selected week is updated or the existing orders for the weeks to copy is retrieved,
    // Show the same week ship to warning if any of the existing orders (make its) are for the currently selected week
    useEffect(() => {
        setShowSameWeekShipToWarning(
            !!existingOrders &&
                existingOrders.filter((o) => formatDate(o.atmWeekStart) === activeDate.fullDate)
                    .length > 0
        );
    }, [existingOrders, activeDate]);

    // When the selected week is updated or the existing orders for the weeks to copy is retrieved,
    // Show the same week draft warning if any of the existing draft make its are for the currently selected week
    useEffect(() => {
        setShowSameWeekDraftsWarning(
            !!existingDrafts &&
                existingDrafts.filter((o) => formatDate(o.atmWeekStart) === activeDate.fullDate)
                    .length > 0
        );
    }, [existingDrafts, activeDate]);

    // When existing orders and allocations load, set existing order lines in state
    useEffect(() => {
        if (existingOrders && allocations) {
            const existingOrdersBySnoSkuLines = groupOrdersBySnoSku(existingOrders, allocations)
                .map((o) => o.linesBySnoSku)
                .flat();
            setExistingLinesBySnoSku(existingOrdersBySnoSkuLines);
        }
    }, [existingOrders, allocations]);

    // When the ship to is first set, load available products
    useEffect(() => {
        if (copyFromShipToId) {
            dispatch(loadProductsForOrderConversion(copyFromShipToId));
        }
    }, [copyFromShipToId, dispatch]);

    // When the plan its to copy and products available are loaded, set the forecast order in state
    useEffect(() => {
        if (copyFromProductionOrderIds && products) {
            dispatch(loadForecastForOrderConversion(copyFromProductionOrderIds));
        }
    }, [copyFromProductionOrderIds, products, dispatch]);

    useEffect(() => {
        if (atmOrders && !allocations) {
            const weeks = atmOrders
                .filter((o) => o !== undefined)
                .map((o) => moment(o.atmWeekStart));
            const firstWeek = moment.min(weeks).format('MM-DD-YYYY');
            const lastWeek = moment.max(weeks).format('MM-DD-YYYY');
            dispatch(loadAllocationsForOrderConversion(firstWeek, lastWeek, atmOrders[0].shipToId));
        }
    }, [atmOrders, allocations, dispatch]);

    useEffect(() => {
        if (atmOrders && (!formattedWeekList || formattedWeekList.length === 0)) {
            setFormattedWeekList(
                atmOrders
                    .filter((o) => o.atmWeekStart !== undefined)
                    .map((o) => ({ weekAsDate: moment(o.atmWeekStart), state: 'valid' }))
                    .sort((a, b) => a.weekAsDate.diff(b.weekAsDate)) as FormattedWeek[]
            );
        }
    }, [atmOrders]);

    useEffect(() => {
        if (formattedWeekList && formattedWeekList.filter((w) => w.weekAsDate !== undefined)) {
            setActiveDate(getDateProperties(formattedWeekList![0].weekAsDate));
        }
    }, [formattedWeekList]);

    useEffect(() => {
        if (atmOrders && allocations && products && existingLinesBySnoSku) {
            const ordersByWeekAndSnoSku = atmOrders
                .map((order) =>
                    addQuantitiesFromOtherOrders(
                        order,
                        groupOrdersBySnoSku([order], allocations),
                        existingLinesBySnoSku.filter((line) => line.weekStart === order.weekStart)
                    )
                )
                .flat();
            dispatch({
                type: MAKEIT_CONVERSION_UPDATE_ORDER_BY_SNO_SKU,
                ordersBySnoSku: ordersByWeekAndSnoSku
            });
        }
    }, [atmOrders, allocations, products, existingLinesBySnoSku, dispatch]);

    useEffect(() => {
        if (shipToAccounts && selectedAccountId) {
            const selectedAccount = shipToAccounts.find((a) => a.accountId === selectedAccountId);
            if (selectedAccount) {
                setShipToAccountsList([{ account: selectedAccount, state: 'valid' }]);
                setSelectedShipTo(selectedAccountId);
            }
        }
    }, [shipToAccounts, selectedAccountId]);

    useEffect(() => {
        setOrderValidated(false);
        if (ordersBySnoSku) {
            ordersBySnoSku.forEach((orders) => {
                orders.state = getWeekValidationState(orders.linesBySnoSku);
            });
            setOrderValidated(true);
            setPageLoading(false);
        }
    }, [ordersBySnoSku]);

    /**
     * Filter ordersBySnoSku by the currently selected ship to and active date.
     * Last step before order information is displayed.
     * @when Trigger if orders have been grouped by snoSku, when the activeDate
     * or the selectedShipTo are changed.
     * @deps ordersBySnoSku, activeDate, selectedShipTo
     */
    useEffect(() => {
        if (ordersBySnoSku && ordersBySnoSku.length > 0 && activeDate && selectedShipTo) {
            const ordersBySnoSkuUpdate = JSON.parse(JSON.stringify(ordersBySnoSku));

            const activeOrders = ordersBySnoSkuUpdate.filter((week) => {
                return (
                    getDateProperties(moment(week.weekStart)).week === activeDate.week &&
                    week.shipToId === parseInt(selectedShipTo)
                );
            });

            let formattedOrders: Array<MakeItBulkATMLinesBySnoSku> = [];
            setOrderHasErrors(false);

            activeOrders.forEach((order) => {
                order.linesBySnoSku.forEach((productGroup) => {
                    const palletQuantityStates: PalletQuantityState[] = [];
                    productGroup.lines.forEach((line) => {
                        if (line.minimumOrderQuantity && !line.deleted) {
                            palletQuantityStates.push(
                                evaluatePalletQuantityValid(
                                    line.palletQuantity,
                                    line.minimumOrderQuantity,
                                    getFeeStructure(line),
                                    getMoqFeeFloor(line.moqFees),
                                    getMoqFeeCeiling(line.moqFees)
                                )
                            );
                        }
                    });

                    productGroup.hasError = palletQuantityStates.some(
                        (state) =>
                            state === 'minimumOrderQuantityError' || state === 'undefinedQuantity'
                    );
                    productGroup.hasWarning = palletQuantityStates.some(
                        (state) => state === 'feeWarning'
                    );

                    if (productGroup.allocationAmount !== undefined) {
                        if (productGroup.totalPalletQuantity < productGroup.allocationAmount) {
                            productGroup.hasWarning = true;
                        }
                        if (productGroup.totalPalletQuantity > productGroup.allocationAmount) {
                            productGroup.hasError = true;
                        }
                    }

                    if (productGroup.hasError) {
                        setOrderHasErrors(true);
                    }

                    formattedOrders.push(productGroup);
                });
                order.linesBySnoSku = formattedOrders;
            });
            setActiveDateOrders(activeOrders);
        }
    }, [ordersBySnoSku, activeDate, selectedShipTo]);

    /**
     * Set validations on the dates if both formattedWeekList is set and orders are grouped by snoSku and have been validated.
     * @when Trigger when an update to an order is made OR the selected ship to is changed.
     * @deps formattedWeekList, ordersBySnoSku, orderValidated
     */
    useEffect(() => {
        if (
            formattedWeekList &&
            formattedWeekList.length &&
            ordersBySnoSku &&
            ordersBySnoSku.length &&
            orderValidated
        ) {
            formattedWeekList.forEach((formattedWeek) => {
                const orders = ordersBySnoSku?.find(
                    (order) =>
                        moment(order.weekStart).valueOf() === formattedWeek.weekAsDate.valueOf() &&
                        order.shipToId.toString() === selectedShipTo
                );
                if (orders) {
                    formattedWeek.state = orders.state;
                }
            });
        }
    }, [formattedWeekList, ordersBySnoSku, orderValidated, selectedShipTo]);

    /**
     * Evaluates validity of all orders/weeks for a shipToAccount .  Used for calculating validation state
     * of the shipToAccount.
     * @param shipToAccount
     * @returns
     */
    const getShipToValidationState = useCallback(
        (shipToAccount: FormattedAccount): DateState => {
            const accountValidityStates: DateState[] = [];

            if (ordersBySnoSku) {
                ordersBySnoSku.forEach((order) => {
                    if (order.shipToId.toString() === shipToAccount.account.accountId) {
                        accountValidityStates.push(order.state);
                    }
                });
            }
            switch (true) {
                case accountValidityStates.some((state) => state === 'error'):
                    return 'error';
                case accountValidityStates.some((state) => state === 'warning'):
                    return 'warning';
                default:
                    return 'valid';
            }
        },
        [ordersBySnoSku]
    );

    useEffect(() => {
        if (ordersBySnoSku && ordersBySnoSku.length && orderValidated) {
            let revalidatedAccountsList = shipToAccountsList.map((shipToAccount) => {
                let stateToUpdate = getShipToValidationState(shipToAccount);
                shipToAccount.state = stateToUpdate;
                return shipToAccount;
            });
            setShipToAccountsList(revalidatedAccountsList);
        }
    }, [ordersBySnoSku, orderValidated, selectedShipTo, getShipToValidationState]);

    useEffect(() => {
        setFromDetails(from === 'details');
        setFromSummary(from === 'summary');
        setFromProductPlanning(from === 'productplanning');
    }, [from]);

    useEffect(() => {
        if (submitLoadingState === 'success') {
            history.push('/make-it-conversion-confirmation');
        }
    }, [submitLoadingState, history]);

    // update local state campaign products and campaign runs data
    const handleUpdateCampaigns = useCallback(
        (products: MakeItBulkLineItem[]) => {
            if (selectedAccountId) {
                let campaignInformation = getCampaignInformation(products, selectedAccountId);

                // store unique campaign products
                setCampaignProducts(campaignInformation.filteredCampaignProducts);
                // store campaign products that have campaign runs associated to them
                setCampaignRuns(campaignInformation.updatedCampaigns);
            }
        },

        [selectedAccountId]
    );

    useEffect(() => {
        if (products) {
            handleUpdateCampaigns(products);
        }
    }, [handleUpdateCampaigns, products]);

    useEffect(() => {
        const hasCampaignRuns = !!campaignRuns && campaignRuns.length > 0;
        const hasCampaignProducts = !!campaignProducts && campaignProducts.length > 0;
        setShowViewCurrentCampaignsLink(hasCampaignRuns || hasCampaignProducts);
    }, [campaignProducts, campaignRuns]);

    const onViewCampaigns = () => {
        setOpenViewCampaignsModal(true);
    };

    const onCloseCampaignsModal = () => {
        setOpenViewCampaignsModal(false);
    };

    const alreadyOrdered = (grouping: MakeItBulkATMLinesBySnoSku): boolean => {
        return existingLinesBySnoSku
            ? existingLinesBySnoSku.filter(
                  (line) => line.snoSku === grouping.snoSku && line.totalPalletQuantity > 0
              ).length > 0
            : false;
    };

    const handleExpandAll = () => {
        setExpandAll({ toggle: true });
        ordersBySnoSku?.forEach((order) => {
            order.linesBySnoSku.forEach((line) => {
                line.isActive = true;
            });
        });
    };

    const handleCollapseAll = () => {
        setExpandAll({ toggle: false });
        ordersBySnoSku?.forEach((order) => {
            order.linesBySnoSku.forEach((line) => {
                line.isActive = false;
            });
        });
    };
    const handleShowAlerts = () => {
        setShowWarnings(false);
        setShowErrors(false);
        setShowAll(false);
        setShowAlerts(true);
        setShowCampaigns(false);
    };
    const handleShowCampaigns = () => {
        setShowErrors(false);
        setShowCampaigns(true);
        setShowAlerts(false);
        setShowAll(false);
        setShowWarnings(false);
    };

    const handleShowErrors = () => {
        setShowWarnings(false);
        setShowErrors(true);
        setShowAll(false);
        setShowAlerts(false);
        setShowCampaigns(false);
    };

    const handleShowWarnings = () => {
        setShowWarnings(true);
        setShowAll(false);
        setShowErrors(false);
        setShowAlerts(false);
        setShowCampaigns(false);
    };

    const handleShowAll = () => {
        setShowErrors(false);
        setShowWarnings(false);
        setShowAll(true);
        setShowAlerts(false);
        setShowCampaigns(false);
    };

    const onCancel = () => {
        setOpenCancelModal(true);
    };

    const onBack = () => {
        setOpenBackModal(true);
    };

    const onClose = () => {
        if (openBackModal) {
            setOpenBackModal(false);
        } else if (openCancelModal) {
            setOpenCancelModal(false);
        } else {
            setOpenBlockedNavigationModal(false);
        }
    };

    const handleCancel = () => {
        setOpenCancelModal(false);
        dispatch(resetMakeItConversionATMState());
        history.push('/');
    };

    const handleBackNavigation = () => {
        setOpenBackModal(false);
        dispatch(resetMakeItConversionATMState());
        const planItId = atmOrders ? atmOrders[0].productionOrderId : undefined;
        if (fromDetails && planItId) {
            history.push(`${/plan-it-order/}${planItId}`);
        } else if ((fromDetails && !planItId) || fromSummary) {
            history.push('/plan-it-summary');
        } else if (fromProductPlanning) {
            history.push('/product-planning');
        } else {
            history.push('/');
        }
    };

    const handleBlockedNavigation = (location) => {
        if (submitLoadingState === 'success') {
            return true;
        } else {
            if (location !== history.location.pathname) setLastLocationPath(location);
            if (!allowNavigation && !openCancelModal && !openBackModal) {
                setOpenBlockedNavigationModal(true);
                return false;
            }
            return true;
        }
    };

    const handleConfirmNavigation = () => {
        setOpenBlockedNavigationModal(false);
        if (lastLocationPath !== '') {
            setAllowNavigation(true);
            // Navigate to the previously blocked location
            setTimeout(() => {
                history.push(lastLocationPath);
            }, 100);
        }
    };

    const onSubmit = useCallback(() => {
        let productList: MakeItBulkATMLineItem[] = [];

        ordersBySnoSku?.forEach((atmOrder) => {
            atmOrder.linesBySnoSku.forEach((snoSkuLine) => {
                snoSkuLine.lines.forEach((line) => {
                    if (!line.deleted && line.palletQuantity && line.palletQuantity > 0) {
                        let accountInfo = shipToAccountsList.find(
                            (account) => account.account.accountId === atmOrder.shipToId.toString()
                        );
                        if (accountInfo) {
                            line.shipToId = accountInfo.account.accountId;
                            line.shipToName = accountInfo.account.name;
                            line.shipToAddress = getAccountAddress(accountInfo.account);
                            line.weekStart = atmOrder.weekStart;
                        }
                        if (line.minimumMet === undefined) {
                            const matchingProduct = products?.find(
                                (product) => product.productSku === line.productSku
                            );
                            line = setFeesMOQData(matchingProduct, line);
                        }
                        productList.push(line);
                    }
                });
            });
        });

        setTableInformation(productList);

        // Filter out zeroed lines before storing in state
        if (allocations && products && atmOrders) {
            atmOrders.forEach((order) => {
                order.lines = order.lines.filter((l) => l.palletQuantity && l.palletQuantity > 0);
            });
            dispatch({
                type: MAKEIT_CONVERSION_UPDATE_ORDER_BY_SNO_SKU,
                ordersBySnoSku: groupOrdersBySnoSku(atmOrders, allocations)
            });
        }

        if (validateMoqAmounts(productList, acceptFee) && ordersBySnoSku) {
            if (isImpersonation) {
                setShowImpersonationWarning(true);
                return;
            }
            dispatch(submitMakeItConversion(ordersBySnoSku));
        } else if (!acceptFee) {
            setOpenFeeModal(true);
        }
    }, [
        acceptFee,
        allocations,
        atmOrders,
        dispatch,
        isImpersonation,
        ordersBySnoSku,
        products,
        shipToAccountsList
    ]);

    // Once the fee is accepted we submit the order
    useEffect(() => {
        if (acceptFee) {
            onSubmit();
        }
    }, [acceptFee, onSubmit]);

    const orderHasAlerts = (order: MakeItBulkATMLinesBySnoSku): Boolean => {
        let hasAlerts = false;
        if (order && order.lines) {
            hasAlerts = order.lines.some((line) => {
                return (
                    isDateWithinAtmLockPeriod(
                        moment(activeDate.fullDate),
                        (line.leadTimeWeeks ? line.leadTimeWeeks : 1) * 7
                    ) !== 'after'
                );
            });
        }
        return hasAlerts;
    };

    const checkShouldRender = (order: MakeItBulkATMLinesBySnoSku, shipToId: number): Boolean => {
        const activeProducts = getActiveProducts(
            shipToId.toString(),
            products as MakeItBulkLineItem[],
            order
        );

        switch (true) {
            case !order.lines.length && order.initiallyEmpty && activeProducts.length === 0:
                return false;
            case !order.lines.length && !order.allocationAmount:
                return false;
            case showAll:
                return true;
            case showErrors:
                return !!order.hasError;
            case showWarnings:
                return !!order.hasWarning;
            case showAlerts:
                return !!orderHasAlerts(order);
            case showCampaigns:
                return order.lines.length > 0 && !!order.lines[0].campaignTiming;
            default:
                return true;
        }
    };

    const checkShouldRenderNoProductsSection = (order: MakeItBulkATMOrderByWeekAndSku) => {
        return order.linesBySnoSku
            .filter((grouping) => grouping.initiallyEmpty && !alreadyOrdered(grouping))
            .some((grouping) => checkShouldRender(grouping, order.shipToId));
    };

    const renderOrderSection = (order, grouping) => {
        return (
            checkShouldRender(grouping, order.shipToId) && (
                <MakeItConversionReviewProvider
                    value={{
                        activeDate,
                        shipToId: order.shipToId
                    }}
                >
                    <Grid item>
                        <MakeItConversionReviewOrderSection
                            order={grouping}
                            shipToId={order.shipToId}
                            isActive={grouping.isActive}
                            expand={expandAll}
                        />
                    </Grid>
                </MakeItConversionReviewProvider>
            )
        );
    };

    const makeItSummaryLink = (
        <Link
            to={'/make-it-po-summary'}
            component={RouterLink}
            underline="none"
            data-testid="make-it-summary-link"
            className={clsx(classes.linkedPO, classes.linkSpacer)}
        >
            <Trans i18nKey="makeItSummaryLink">Make It Summary</Trans>
        </Link>
    );

    const makeItDraftsLink = (
        <Link
            to={'/make-it-drafts-summary'}
            component={RouterLink}
            underline="none"
            data-testid="make-it-drafts-link"
            className={clsx(classes.linkedPO, classes.linkSpacer)}
        >
            Make It Drafts
        </Link>
    );

    const getSameWeekShipTosWarning = (
        <Typography>
            <Trans i18nKey="sameWeekShipTosWarning">
                There are additional Make It Order(s) for this week. Please see {makeItSummaryLink}
                page for details.
            </Trans>
        </Typography>
    );

    const getSameWeekDraftsWarning = (
        <Typography>
            <Trans i18nKey="sameWeekShipTosDraftsWarning">
                There are draft Make It Order(s) for this week. Please see {makeItDraftsLink} page
                for details.
            </Trans>
        </Typography>
    );

    const footerActions = (
        <>
            <Grid container item xs={2}>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="cancel-btn"
                    className={classes.actionBtn}
                    onClick={onCancel}
                >
                    <Trans i18nKey="cancel">Cancel</Trans>
                </Button>
            </Grid>
            <Grid container item xs={7} className={classes.errorDiv}>
                <ErrorAlert
                    errorMessage={
                        <Trans i18nKey="submitATMError">
                            The system was unable to process your changes.
                        </Trans>
                    }
                    showError={error === 'submit'}
                    dataTestId="submit-error"
                ></ErrorAlert>
                <ImpersonationWarning
                    showWarning={showImpersonationWarning}
                    warningType={'SUBMIT'}
                />
            </Grid>
            <Grid container item xs={3} justify="flex-end">
                <Grid container item xs={7}>
                    <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        data-testid="back-btn"
                        onClick={onBack}
                        className={classes.actionBtn}
                    >
                        <Trans i18nKey="back">Back</Trans>
                    </Button>
                </Grid>
                <Grid container item xs={5} justify="flex-end">
                    <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        data-testid="submit-order-btn"
                        className={classes.actionBtn}
                        disabled={submitLoadingState === 'loading' || orderHasErrors}
                        onClick={onSubmit}
                    >
                        <Trans i18nKey="submit">Submit</Trans>
                    </Button>
                </Grid>
            </Grid>
        </>
    );

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('makeItFromPlanIt', 'Make It From Plan It'),
                description: t('conversionReviewOrder', 'Review Order').toUpperCase(),
                thinBanner: true,
                displayDropdown: false
            }}
            actionFooter={{
                footerAction: footerActions,
                justify: 'space-between',
                sticky: true
            }}
            activity={Activity.MakeItBulkATM}
            shipTos={false}
            loading={pageLoading}
        >
            {submitLoadingState === 'loading' && (
                <Grid container item xs={12} className={classes.spinningLoader}>
                    <CircularProgress />
                </Grid>
            )}
            {submitLoadingState !== 'loading' && atmOrders && (
                <Grid container item xs={12} className={classes.dateSelectSection}>
                    <Grid
                        container
                        item
                        xs={12}
                        className={classes.leadTimeWarning}
                        data-testid="lead-time-warning"
                    >
                        <WarningAlert
                            showWarning={showSameWeekShipToWarning}
                            warningMessage={getSameWeekShipTosWarning}
                        />
                        <WarningAlert
                            showWarning={showSameWeekDraftsWarning}
                            warningMessage={getSameWeekDraftsWarning}
                        />
                    </Grid>
                    <Grid container item xs={4}>
                        <Typography variant="subtitle2" className={classes.viewAtmTitle}>
                            {t('shipTo', 'Ship To').toUpperCase()}
                        </Typography>
                    </Grid>
                    <Grid container item xs={8}>
                        <Typography variant="subtitle2" className={classes.viewAtmTitle}>
                            {t('weekOf', 'Week of').toUpperCase()}
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={4}
                        className={classes.selector}
                        data-testid="ship-to-selector"
                    >
                        <ShipToDropdown
                            name="shipToSelect"
                            testId="shipToSelect"
                            selectObjects={shipToAccountsList}
                            selected={selectedShipTo}
                            updateSelected={setSelectedShipTo}
                            showIcons={true}
                        />
                    </Grid>
                    {formattedWeekList && (
                        <Grid
                            container
                            item
                            xs={4}
                            className={classes.selector}
                            data-testid="dateBar"
                        >
                            <WeekSelectorDropdown
                                testId="weekSelect"
                                weeks={formattedWeekList}
                                selectedDate={activeDate}
                                onDateSelected={setActiveDate}
                                showIcons={true}
                            />
                        </Grid>
                    )}
                    <Grid container xs={12} className={classes.selector}>
                        <Grid container item xs={9}>
                            <Typography
                                variant="h3"
                                className={classes.viewAtmTitle}
                                data-testid="current-selected-date"
                            >
                                {`${t('ballProdOrder', 'Ball Production Order')} # ${t(
                                    'pending',
                                    'Pending'
                                )}`}
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            item
                            justify="flex-end"
                            className={classes.expandCollapseWrapper}
                            xs={3}
                        >
                            <Button
                                className={classes.btn}
                                color="primary"
                                onClick={handleExpandAll}
                                data-testid="expand-all"
                            >
                                <Trans i18nKey="expandAll">Expand All</Trans>
                            </Button>
                            <Typography>|</Typography>
                            <Button
                                className={classes.btn}
                                color="primary"
                                onClick={handleCollapseAll}
                                data-testid="collapse-all"
                            >
                                <Trans i18nKey="collapseAll">Collapse All</Trans>
                            </Button>
                        </Grid>
                    </Grid>
                    {showViewCurrentCampaignsLink && (
                        <Grid xs={12} data-testid="atm-orders">
                            <Typography variant="h5" className={classes.subtitle}>
                                <Trans i18nKey="lookingForCampaignSchedules">
                                    Looking for campaign schedules?
                                </Trans>
                                <Button
                                    variant="text"
                                    color="primary"
                                    data-testid="view-campaigns-button"
                                    className={classes.viewCampaignsButton}
                                    onClick={onViewCampaigns}
                                >
                                    <Trans i18nKey="viewCurrentUpcomingCampaigns">
                                        View Current & Upcoming Campaigns
                                    </Trans>
                                </Button>
                            </Typography>
                        </Grid>
                    )}
                    <Grid xs={12} data-testid="atm-orders">
                        <ValidationFilterMenu
                            onShowErrors={handleShowErrors}
                            onShowWarnings={handleShowWarnings}
                            onShowAll={handleShowAll}
                            onShowAlerts={handleShowAlerts}
                            onShowCampaigns={handleShowCampaigns}
                            showAll={showAll}
                            showErrors={showErrors}
                            showWarnings={showWarnings}
                            showAlerts={showAlerts}
                        />
                        {activeDateOrders &&
                            activeDateOrders.map(
                                (order) =>
                                    order.linesBySnoSku && (
                                        <>
                                            {order.linesBySnoSku
                                                .filter((grouping) => !grouping.initiallyEmpty)
                                                .map((grouping) =>
                                                    renderOrderSection(order, grouping)
                                                )}
                                            {checkShouldRenderNoProductsSection(order) && (
                                                <>
                                                    <hr className={classes.divider} />
                                                    <Typography
                                                        variant="h3"
                                                        className={classes.viewAtmTitle}
                                                        data-testid="empty-products-section"
                                                    >
                                                        {t(
                                                            'noProductsOrdered',
                                                            'No products ordered for the following supply plans:'
                                                        )}
                                                    </Typography>
                                                    {order.linesBySnoSku
                                                        .filter(
                                                            (grouping) => grouping.initiallyEmpty
                                                        )
                                                        .map((grouping) =>
                                                            renderOrderSection(order, grouping)
                                                        )}
                                                </>
                                            )}
                                        </>
                                    )
                            )}
                    </Grid>
                </Grid>
            )}
            <ConfirmationModal
                data-testid="cancel-modal"
                saveProgress={handleCancel}
                onClose={onClose}
                logo={false}
                title={<Trans i18nKey="cancel">Cancel</Trans>}
                subheader={<Trans i18nKey="changesNotSaved">Your changes will not be saved</Trans>}
                description={
                    <Trans i18nKey="confirmCancel">Are you sure you want to cancel?</Trans>
                }
                continueText={<Trans i18nKey="yesCancel">Yes, Cancel</Trans>}
                cancelText={<Trans i18nKey="no">No</Trans>}
                open={openCancelModal}
                navigationLink=""
                onCancel={onClose}
            />
            <ConfirmationModal
                data-testid="go-back-modal"
                saveProgress={handleBackNavigation}
                onClose={onClose}
                logo={false}
                title={<Trans i18nKey="back">Back</Trans>}
                subheader={<Trans i18nKey="changesNotSaved">Your changes will not be saved</Trans>}
                description={
                    <Trans i18nKey="goBackConfirm">Are you sure you want to go back?</Trans>
                }
                continueText={<Trans i18nKey="yesGoBack">Yes, Go Back</Trans>}
                cancelText={<Trans i18nKey="no">No</Trans>}
                open={openBackModal}
                navigationLink=""
                onCancel={onClose}
            />
            <ConfirmationModal
                data-testid="confirm-exit-modal"
                saveProgress={handleConfirmNavigation}
                onClose={onClose}
                logo={false}
                title={<Trans i18nKey="exit">Exit</Trans>}
                subheader={<Trans i18nKey="changesNotSaved">Your changes will not be saved</Trans>}
                description={
                    <Trans i18nKey="exitConfirm">Are you sure you want to exit the page?</Trans>
                }
                continueText={<Trans i18nKey="yesExit">Yes, Exit</Trans>}
                cancelText={<Trans i18nKey="no">No</Trans>}
                open={openBlockedNavigationModal}
                navigationLink=""
                onCancel={onClose}
            />
            <MakeItBulkFeeModal
                atmProducts={tableInformation}
                isAtm={true}
                open={openFeeModal}
                onClose={onCloseFeeModal}
                onAcceptFee={onAcceptFee}
            />
            <Prompt
                when={!allowNavigation}
                message={(location, action) => {
                    if (action === 'POP') {
                        handleBlockedNavigation(location.pathname);
                        return RouterConfirmationOverride.PreventBrowserBack;
                    } else {
                        return handleBlockedNavigation(location.pathname);
                    }
                }}
            />
            {campaignRuns && (
                <CurrentCampaignsModal
                    title={
                        <Trans i18nKey="currentUpcomingCampaigns">
                            Current & Upcoming Campaigns
                        </Trans>
                    }
                    open={openViewCampaignsModal}
                    onClose={onCloseCampaignsModal}
                    campaignRuns={campaignRuns}
                    campaignProducts={campaignProducts}
                />
            )}
        </ProcessingPageTemplate>
    );
};

export default MakeItConversionReview;
