import React, { useEffect, useState } from 'react';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { ColumnModel } from 'tubular-common';
import { Trans, useTranslation } from 'react-i18next';
import { blackWeight, ballGray, containerMaxWidth } from '../../../themes/globalConstants';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { AuthState } from '../../../store/reducers/auth';
import { useDispatch } from 'react-redux';
import { getShipToAccounts } from '../../../store/actions/customer-context';
import { Activity } from '../../../utility/auth/useSecurity';
import {
    getShippingInformation,
    ShipToAccountInformation
} from '../../../utility/helpers/address-helpers';
import { ScrapItState } from '../../../store/reducers/scrap-it';
import ScrapItConfirmationSubheader from './components/ScrapItConfirmationSubheader';
import StandardTable from '../../reusable/molecules/StandardTable';
import ScrapItConfirmationColumns from './components/ScrapItConfirmationColumns';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { ProductToShip } from '../../../store/reducers/shipping-dashboard';
import ScrapItConfirmationRow from './components/ScrapItConfirmationRow';
import ScrapItConfirmationShareLinks from './components/ScrapItConfirmationShareLinks';
import { resetScrapItProductState } from '../../../store/root-actions';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: '3em',
        borderRadius: 4,
        maxWidth: containerMaxWidth
    },
    header: {
        marginTop: '1em',
        marginBottom: '2em'
    },
    subheader: {
        marginBottom: '1em'
    },
    title: {
        fontWeight: blackWeight,
        color: ballGray
    },
    actionBtn: {
        borderRadius: 'unset'
    },
    toolbarWrapper: {
        padding: '2em',
        alignItems: 'center'
    }
}));

const ScrapItConfirmation = () => {
    const { scrapItConfirmation } = useTypedSelector<ScrapItState>((state) => state.scrapItState);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [columns, setColumns] = useState<ColumnModel[]>([]);
    const [shipToId, setShipToId] = useState<string>('');
    const [shipToName, setShipToName] = useState<string>('');
    const [shipToAddress, setDisplayAddress] = useState<string>('');
    const { shipToAccounts, selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const classes = useStyles();
    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);

    useEffect(() => {
        if (permissions && !shipToAccounts?.length) {
            dispatch(getShipToAccounts());
        }
    }, [dispatch, shipToAccounts, permissions]);

    useEffect(() => {
        return () => {
            dispatch(resetScrapItProductState());
        };
    }, []);

    useEffect(() => {
        if (scrapItConfirmation && selectedAccountId && shipToAccounts) {
            const filteredAccount = shipToAccounts.find(
                (account) => account.accountId === selectedAccountId
            );
            if (filteredAccount) {
                const information = getShippingInformation(
                    filteredAccount
                ) as ShipToAccountInformation;
                setShipToId(filteredAccount.accountId);
                setShipToName(information.shipToName.toString());
                setDisplayAddress(information.displayAddress);
            }
        }
    }, [dispatch, scrapItConfirmation, shipToAccounts]);

    useEffect(() => {
        setColumns(ScrapItConfirmationColumns.getScrapItColumns(cultureCode));
    }, [cultureCode]);

    const CustomToolbar = () => (
        <Grid container spacing={2} align-items="flex-start" className={classes.toolbarWrapper}>
            <Grid container alignItems="baseline" className={classes.header}>
                <Grid container item xs={7} justify="flex-start">
                    {scrapItConfirmation?.scrapRequestId ? (
                        <Typography variant="h3" className={classes.title}>
                            <Trans i18nKey="scrapItRequestOrderNumber">
                                Scrap It Request Order #
                            </Trans>
                            {scrapItConfirmation?.scrapRequestId}
                        </Typography>
                    ) : (
                        <Typography variant="h3" className={classes.title}>
                            <Trans i18nKey="scrapItRequestOrderNumber">
                                Scrap It Request Order #
                            </Trans>
                            <Trans i18nKey="pending">Pending</Trans>
                        </Typography>
                    )}
                </Grid>
                <Grid container item xs={5} justify="flex-end">
                    <ScrapItConfirmationShareLinks
                        scrapItResponse={scrapItConfirmation}
                        shipToAddress={shipToAddress}
                        shipToName={shipToName}
                        data-testid="scrap-it-share-links"
                    />
                </Grid>
            </Grid>
            <Grid container>
                {scrapItConfirmation && (
                    <ScrapItConfirmationSubheader
                        shipToAddress={shipToAddress}
                        shipToName={shipToName}
                        shipToId={shipToId}
                        confirmation={scrapItConfirmation}
                        data-testid="scrap-it-subheader"
                    />
                )}
            </Grid>
        </Grid>
    );

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('scrapIt', 'Scrap It'),
                description: t('atmChangesReceived', 'Thank you, your changes have been received.'),
                thinBanner: true
            }}
            activity={Activity.ScrapIt}
        >
            {scrapItConfirmation && (
                <StandardTable
                    lines={scrapItConfirmation.requestLines}
                    columns={columns}
                    columnWidths={[35, 25, 20, 20]}
                    gridName={'Scrap It Confirmation Grid'}
                    rowComponent={({ row }: { row: ProductToShip }) => (
                        <ScrapItConfirmationRow key={row.displayId} item={row} />
                    )}
                    customToolbarItems={<CustomToolbar />}
                    searchText={false}
                    className={classes.paper}
                    itemsPerPage={10}
                />
            )}
        </ProcessingPageTemplate>
    );
};

export default ScrapItConfirmation;
