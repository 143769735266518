import { Product, SelectedFacet } from '../reducers/product-portfolio';
import Axios, { AxiosRequestConfig } from 'axios';
import * as types from './action-types';
import { getAxiosConfig } from '../../utility/helpers/axios-helpers';
import ProductService, { ProductSearchResponse } from '../../utility/services/product-service';
import { ViewType } from '../../utility/helpers/filter-helpers';
import { getAerosolProducts } from '../../utility/helpers/product-helpers';

export const productPortfolioUpdateResults = (results) => {
    const filteredResults = results.filter((result) => result.productPortfolio);

    return {
        type: types.PRODUCT_UPDATE_RESULTS,
        results: filteredResults
    };
};

// Adds updates product.selected state to Product Portfolio state
export const productPortfolioSelectProduct = (productCode: any, selected: boolean) => {
    return {
        type: types.PRODUCT_SELECTED,
        productCode: productCode,
        selected: selected
    };
};

// Updates selected facets from Product Portfolio filter
export const productPortfolioUpdateFacet = (selectedFacet: SelectedFacet) => {
    return (dispatch: any) => {
        dispatch({
            type: types.PRODUCT_UPDATE_FACET,
            facet: selectedFacet
        });

        dispatch(loadProductPortfolioDetails());
    };
};

// Clears all selected facets from Product Portfolio filter
export const productPortfolioClearFacets = () => {
    return (dispatch: any) => {
        dispatch({
            type: types.PRODUCT_CLEAR_FACETS
        });

        dispatch(loadProductPortfolioDetails());
    };
};

// Loads products based on all/can/bottles/ends from Product Portfolio subheader
export const productPortfolioUpdateView = (view: ViewType) => {
    return (dispatch: any) => {
        dispatch({
            type: types.PRODUCT_UPDATE_VIEW,
            view: view
        });
        dispatch(loadProductPortfolioDetails());
    };
};

// When a user selects the Build & Estimate button the product is saved in state for KB Max
export const updateSelectedProduct = (product: Product) => {
    return (dispatch: any) => {
        dispatch({
            type: types.PRODUCT_UPDATE_SELECTED_PRODUCT,
            selectedProduct: product
        });
    };
};

// When clear the selected product from state
export const clearSelectedProduct = () => {
    return (dispatch: any) => {
        dispatch({
            type: types.PRODUCT_CLEAR_SELECTED_PRODUCT
        });
    };
};

// Initial load of facet values for Product Portfolio filter
export const loadProductPortfolioFacets = (
    region: string,
    culture: string,
    isNavigation: boolean,
    selectedFacet?: SelectedFacet
) => {
    return (dispatch: any, getState: any) => {
        dispatch({
            type: types.PRODUCT_LOADING_FACETS
        });
        const axiosConfig: AxiosRequestConfig = getAxiosConfig(getState);

        // fetch product filters
        return Axios.get(`${process.env.REACT_APP_PRODUCT_API}/facets`, axiosConfig)
            .then((response) => {
                response.data.styles.push({ facetCode: 'IEB', label: 'Impact Extruded Bottle' });
                dispatch({
                    type: types.PRODUCT_LOAD_FACETS,
                    facets: response.data
                });
                dispatch({
                    type: types.PRODUCT_SET_CULTURE,
                    regionCode: region,
                    cultureCode: culture
                });
            })
            .catch((error) => {
                dispatch({
                    type: types.PRODUCT_LOAD_FACETS_ERROR,
                    error: error
                });
            });
    };
};

export const productPortfolioNavSearch = (selectedFacet: SelectedFacet) => {
    return (dispatch: any, getState: any) => {
        if (Object.keys(getState().productPortfolio.facets).length === 0) {
            const regionCulture = getState().regionCulture;
            return dispatch(
                loadProductPortfolioFacets(
                    regionCulture.regionCode,
                    regionCulture.cultureCode,
                    true,
                    selectedFacet
                )
            ).then(() => {
                dispatch(productPortfolioUpdateFacet(selectedFacet));
            });
        } else {
            dispatch({
                type: types.PRODUCT_CLEAR_FACETS
            });
            dispatch(productPortfolioUpdateFacet(selectedFacet));
        }
    };
};

const productSizeRangeCodeIsSelected = (product, selectedFacets) => {
    const sizeRanges: string[] = [];
    if (Array.isArray(product.sizeRange.sizeRangeCode))
        product.sizeRange.sizeRangeCode.forEach((size) => {
            sizeRanges.push(size);
        });
    else sizeRanges.push(product.sizeRange.sizeRangeCode);

    const sizeRangeCodeMatches = sizeRanges.some((sizeRangeCode) =>
        selectedFacets.sizes?.includes(sizeRangeCode)
    );

    return sizeRangeCodeMatches;
};

export const loadProductPortfolioDetails = () => {
    return (dispatch: any, getState: any) => {
        const productPortfolioState = getState().productPortfolio;
        const productRequest = ProductService.createProductRequest(productPortfolioState);

        let productResponse: ProductSearchResponse;

        // get the existing selected items so they can potentially be reselected with new results
        const selectedItems = ProductService.getSelectedProducts(productPortfolioState);

        return ProductService.getMultipleProducts(getState, productRequest)
            .then((products) => {
                productResponse = products.data;

                // keep selected products selected
                if (products?.data?.products) {
                    for (const product of products.data.products) {
                        if (
                            selectedItems.find((productCode) => productCode === product.productCode)
                        ) {
                            product.selected = true;
                        }
                    }

                    dispatch(productPortfolioUpdateResults(products.data.products));
                }

                return ProductService.getProductContent(productResponse, getState);
            })
            .then((response) => {
                const products = response;

                const aerosolProducts = getAerosolProducts();

                const selectedFacets = productPortfolioState.selectedFacets;
                const filteredAerosolProducts = aerosolProducts.filter((product) => {
                    const sizeRangeCodeSelected = productSizeRangeCodeIsSelected(
                        product,
                        selectedFacets
                    );
                    return (
                        (selectedFacets.styles?.includes(product.style?.styleCode) ||
                            !selectedFacets.styles) &&
                        (sizeRangeCodeSelected || !selectedFacets.sizes) &&
                        (productPortfolioState.view.toLowerCase() === product.type.toLowerCase() ||
                            !productPortfolioState.view)
                    );
                });

                dispatch(productPortfolioUpdateResults([...products, ...filteredAerosolProducts]));
            })
            .catch((error) => {
                dispatch({
                    type: types.PRODUCT_LOADING_ERROR,
                    error: 'Unable to load products content: ' + error
                });

                // maybe content failed but product is ok
                if (productResponse) {
                    dispatch(productPortfolioUpdateResults(productResponse.products));
                }
            });
    };
};

export const loadProductPortfolioBanner = () => {
    return (dispatch: any, getState: any) => {
        const bannerUrl = `${process.env.REACT_APP_CONTENT_API}/content/product-header`;
        const banner = ProductService.getBanner(getState, bannerUrl);
        const region = getState()?.regionCulture?.regionCode;
        const culture = getState()?.regionCulture?.cultureCode;

        return banner.then((response) => {
            dispatch({
                banner: response.data?.section,
                type: types.PRODUCT_LOADED_BANNER
            });

            dispatch({
                type: types.PRODUCT_SET_CULTURE,
                regionCode: region,
                cultureCode: culture
            });
        });
    };
};

export const loadProductPortfolioPageContent = () => {
    return (dispatch: any, getState: any) => {
        dispatch({
            type: types.PRODUCT_LOADING
        });

        let productResponse: ProductSearchResponse;
        const productRequest = ProductService.createProductRequest(getState().productPortfolio);

        const banner = dispatch(loadProductPortfolioBanner());

        const productPortfolioDetails = ProductService.getMultipleProducts(
            getState,
            productRequest
        );
        productPortfolioDetails
            .then((products) => {
                productResponse = products.data;
                return ProductService.getProductContent(productResponse, getState);
            })
            .then((response) => {
                const products = response;
                const aerosolProducts = getAerosolProducts();
                dispatch(productPortfolioUpdateResults([...products, ...aerosolProducts]));
            });

        const promises = [banner, productPortfolioDetails];
        // when everything is loaded or an error occurs
        return Promise.all(promises)
            .then(() => {
                dispatch({
                    type: types.PRODUCT_PORTFOLIO_CONTENT_LOADED
                });
            })
            .catch((error) => {
                dispatch({
                    type: types.PRODUCT_PORTFOLIO_CONTENT_ERROR,
                    error: 'Unable to load product details content due to the following: ' + error
                });
            });
    };
};
