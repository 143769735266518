import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { containerMaxWidth } from '../../../themes/globalConstants';
import { enUS } from '../../../utility/translations/locales';
import PageBanner from '../../reusable/molecules/PageBanner';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '1em'
    },
    content: {
        minHeight: '80vh',
        maxHeight: '200vh',
        minWidth: '35em',
        maxWidth: containerMaxWidth
    }
}));

export default function CustomerServiceSurvey() {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div data-testid="customer-service-survey-page">
            <PageBanner
                header={t('customerSatisfactionSurvey', enUS.customerSatisfactionSurvey)}
                description={''}
                thinBanner={true}
                kbMaxBanner={true}
                center={true}
            />
            <div className={classes.container}>
                <div id="customer-service-survey" className={classes.content} />
            </div>
        </div>
    );
}
