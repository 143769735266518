import {
    MAKE_IT_DRAFTS_LOADING,
    MAKE_IT_DRAFTS_LOADED,
    MAKE_IT_DRAFTS_ERROR,
    MAKE_IT_DRAFTS_CHANGE_SELECTION,
    MAKE_IT_DRAFTS_RESET
} from '../actions/action-types';
import { ProductionOrder } from './makeit-dashboard';

export interface ProductionOrderDraftLine extends ProductionOrder {
    requestedDate: string;
}
export interface DraftInformation {
    draftId: string;
    name: string;
    createdBy: string;
    createDate: string;
}
export interface ProductionOrderDraft {
    draft: DraftInformation;
    orders: ProductionOrder[];
}
export interface MakeItDraftsState {
    drafts: ProductionOrderDraft[];
    loading: boolean;
    loaded: boolean;
    draftsDataLoaded: boolean;
    selectedDraft: string;
    error?: any;
}

const initialState: MakeItDraftsState = {
    drafts: [],
    loading: false,
    loaded: false,
    draftsDataLoaded: false,
    selectedDraft: ''
};

const makeItDrafts = (
    state: MakeItDraftsState = initialState,
    action: { type: string; [x: string]: any }
): MakeItDraftsState => {
    switch (action.type) {
        case MAKE_IT_DRAFTS_LOADING:
            return { ...state, loading: true, loaded: false };
        case MAKE_IT_DRAFTS_CHANGE_SELECTION:
            return { ...state, selectedDraft: action.selectedDraft };
        case MAKE_IT_DRAFTS_ERROR:
            return { ...state, loading: false, draftsDataLoaded: true, error: action.error };
        case MAKE_IT_DRAFTS_LOADED:
            return { ...state, drafts: action.drafts, loaded: true, draftsDataLoaded: true, loading: false };
        case MAKE_IT_DRAFTS_RESET:
            return { ...state, drafts: [], loading: false, loaded: false, draftsDataLoaded: false, selectedDraft: '' };
        default:
            return state;
    }
};

export default makeItDrafts;