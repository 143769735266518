import React, { useState, useEffect } from 'react';
import { makeStyles, Paper, Grid, Typography } from '@material-ui/core';
import { Trans } from 'react-i18next';
import {
    blackWeight,
    boldWeight,
    ballGray,
    vanilla,
    yellowPill,
    medium,
    containerMaxWidth
} from '../../../../themes/globalConstants';
import { Alert } from '@material-ui/lab';
import DeliverySubheader from '../../ShipItConfirmation/components/DeliverySubheader';
import ShipmentConfirmationSection from '../../ShipItConfirmation/components/ShipmentConfirmationSection';
import DeliveryBulkConfirmationLinks from './DeliveryBulkConfirmationLinks';
import { BulkUploadOrder } from '../../../../store/reducers/bulk-upload';
import { getAccountAddress } from '../../../../utility/helpers/address-helpers';
import { AuthState } from '../../../../store/reducers/auth';
import { CustomerContextState, ModeOfTransport } from '../../../../store/reducers/customer-context';
import AccountService from '../../../../utility/services/account-service';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { selectIsLargeCustomerAccount } from '../../../../store/selectors';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: '3em',
        padding: '2em',
        borderRadius: 4,
        maxWidth: containerMaxWidth
    },
    header: {
        marginTop: '1em',
        marginBottom: '2em'
    },
    actionBtn: {
        borderRadius: 'unset'
    },
    title: {
        fontWeight: blackWeight,
        color: ballGray,
        marginTop: '.25em'
    },
    warning: {
        padding: '.125em 1.5em .125em 2.125em',
        backgroundColor: vanilla,
        '& .MuiAlert-icon': {
            color: yellowPill
        }
    },
    alertText: {
        fontSize: medium,
        fontWeight: boldWeight
    }
}));

interface Props {
    orderInformation: BulkUploadOrder;
    deliveryOrderNumber: number;
}

const DeliveryBulkUploadConfirmationSection = ({
    orderInformation,
    deliveryOrderNumber
}: Props) => {
    const classes = useStyles();
    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const isLargeCustomerAccount = useTypedSelector<boolean>(selectIsLargeCustomerAccount);
    const [shipToAddress, setShipToAddress] = useState<string>('');
    const [shipToName, setShipToName] = useState<string>('');
    const [shipToId, setShipToId] = useState<string>('');
    const { accessToken } = useTypedSelector<AuthState>((state) => state.auth);
    const renderShipments = orderInformation?.shipments?.map((shipment, i) => {
        return (
            <ShipmentConfirmationSection
                key={shipment.id}
                shipmentIndex={i}
                currentShipment={shipment}
            />
        );
    });

    useEffect(() => {
        if (orderInformation.shipToId && shipToAccounts && shipToAccounts.length > 0) {
            const shipToIdString = orderInformation.shipToId.toString();
            const shipToAccount = shipToAccounts.find(
                (account) => shipToIdString === account.accountId
            );
            if (shipToAccount) {
                setShipToName(shipToAccount.name);
                setShipToId(shipToIdString);
                setShipToAddress(getAccountAddress(shipToAccount));
                if (shipToAccount.modeOfTransport === ModeOfTransport.CustomerPickUp) {
                    orderInformation.customerPickup = true;
                }
            } else {
                AccountService.getAccount(shipToIdString, false, accessToken)
                    .then((response) => {
                        const account = response.data.account;
                        setShipToName(account.name);
                        setShipToId(account.accountId);
                        setShipToAddress(getAccountAddress(account));
                        if (account.modeOfTransport === ModeOfTransport.CustomerPickUp) {
                            orderInformation.customerPickup = true;
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }
    }, [accessToken, orderInformation, shipToAccounts]);

    return (
        <Paper elevation={2} className={classes.paper} data-testid="shipit-conf-page">
            <Grid container alignItems="flex-start">
                <Grid container className={classes.header}>
                    <Grid container item xs={3} justify="flex-start">
                        <Typography variant="h3" className={classes.title}>
                            <Trans i18nKey="deliveryOrderHeader">
                                Delivery Order {{ deliveryOrderNumber }} -{' '}
                                <i>Order Number Pending</i>
                            </Trans>
                        </Typography>
                    </Grid>
                    <Grid container item xs={6} justify="center">
                        {!isLargeCustomerAccount && (
                            <Alert
                                severity="warning"
                                className={classes.warning}
                                data-testid="carrier-alert"
                            >
                                <Trans
                                    i18nKey="deliveryConfirmationCarrierAlert"
                                    className={classes.alertText}
                                >
                                    Carrier will be assigned 7 days prior to scheduled delivery
                                    date.
                                </Trans>
                            </Alert>
                        )}
                    </Grid>
                    <Grid
                        container
                        item
                        xs={3}
                        justify="flex-end"
                        data-testid="confirmation-page-links"
                    >
                        <DeliveryBulkConfirmationLinks
                            deliveryOrder={orderInformation}
                            shipToName={shipToName}
                            shipToAddress={shipToAddress}
                        />
                    </Grid>
                </Grid>
                <Grid container data-testid="confirmation-page-subheader">
                    <DeliverySubheader
                        order={orderInformation}
                        shipToAddress={shipToAddress}
                        shipToName={shipToName}
                        shipToId={shipToId}
                    />
                </Grid>
            </Grid>
            {renderShipments}
        </Paper>
    );
};

export default DeliveryBulkUploadConfirmationSection;
