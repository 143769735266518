import React, { useState } from 'react';
import { ProductPortfolioState, Product } from '../../../../store/reducers/product-portfolio';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { GridList, GridListTile, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import ProductTile from './ProductTile';
import AssistTile from './AssistTile';
import QuickLookDetails from './QuickLookDetails';
import Modal from '../../../reusable/molecules/Modal';
import EndTile from './EndTile';
import ContactForm from '../../../reusable/molecules/ContactForm';
import { black_8 } from '../../../../themes/globalConstants';
import { Trans, useTranslation } from 'react-i18next';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { getProductDescriptionForRegion } from '../../../../utility/helpers/product-helpers';

interface Props {
    productCount?: number;
}

const useStyles = makeStyles((theme) => ({
    grid: {
        width: '100%',
        overflow: 'visible'
    },
    wrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        '& .MuiGridListTile-tile': {
            overflow: 'visible'
        },
        '& .MuiPaper-root': {
            height: 'inherit',
            transition: 'transform .2s',
            '&:hover': {
                transform: 'scale(1.05)',
                overflow: 'visible',
                boxShadow: `0 0 10px 0 ${black_8}`
            }
        }
    },
    modal: {
        '& .MuiDialog-paperScrollPaper': {
            borderRadius: 'unset',
            overflow: 'hidden'
        }
    },
    contactFormTitle: {
        '& .MuiDialogTitle-root': {
            justifyContent: 'center',
            marginLeft: '2.5em'
        }
    }
}));

const ProductGrid = (props: Props) => {
    const classes = useStyles();
    const theme = useTheme();
    const { results, loaded, selectedFacets } = useTypedSelector<ProductPortfolioState>(
        (state) => state.productPortfolio
    );
    const { regionCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [open, setOpen] = useState(false);
    const [contactForm, setContactForm] = useState(false);
    const [activeItem, setActiveItem] = useState<Product>();

    const xs = useMediaQuery(theme.breakpoints.down('xs'));
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));
    const columns = xs ? 1 : mdDown ? 2 : 3;

    const quickLook = (product) => {
        setOpen(true);
        setActiveItem(product);
    };

    const closeQuickLook = () => {
        setOpen(false);
        setContactForm(false);
    };

    const openContactForm = () => {
        setContactForm(true);
        setOpen(true);
    };
    useTranslation();
    const title = (
        <Trans i18nKey="sendUsANote">
            Send us a note on what new products you would like to see in the future
        </Trans>
    );
    const productModalTitle = activeItem
        ? getProductDescriptionForRegion(activeItem, regionCode)
        : '';
    const modalTitle = contactForm ? title : productModalTitle;

    const hideEnds = selectedFacets.styles?.length === 1 && selectedFacets.styles[0] === 'IEB';

    return (
        <div className={classes.wrapper}>
            <GridList
                cellHeight={'auto'}
                className={classes.grid}
                cols={columns}
                spacing={xs ? 10 : 20}
            >
                {results?.map((product, index) => (
                    <GridListTile key={product.productCode} cols={1}>
                        <ProductTile
                            product={product}
                            productCount={props.productCount}
                            onQuickLook={quickLook}
                        />
                    </GridListTile>
                ))}
                {loaded && !hideEnds && (
                    <GridListTile key={'end-tile'} cols={1}>
                        <EndTile />
                    </GridListTile>
                )}
                {loaded && (
                    <GridListTile key={'assist-tile'} cols={1}>
                        <AssistTile onLinkClick={openContactForm} />
                    </GridListTile>
                )}
            </GridList>
            <Modal
                open={open}
                close={closeQuickLook}
                title={modalTitle}
                closeIcon={true}
                fullWidth={true}
                maxWidth="md"
                className={classes.modal + ' ' + (contactForm && classes.contactFormTitle)}
            >
                {contactForm ? (
                    <ContactForm messageType={'CONTACT'} onClose={closeQuickLook} />
                ) : (
                    activeItem && <QuickLookDetails product={activeItem} />
                )}
            </Modal>
        </div>
    );
};

export default ProductGrid;
