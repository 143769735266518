import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { ballLtGray_3, lato } from '../../../../themes/globalConstants';
import { CapacityCheck } from '../../../../store/reducers/capacity-checks';
import { Product } from '../../../../store/reducers/product-portfolio';
import { getProductDescriptionForRegion } from '../../../../utility/helpers/product-helpers';
import { Region } from '../../../../store/reducers/graphic-intake';
import {
    formatEndSize,
    getProductEnhancementDisplay,
    getVarnishAndInkDisplay
} from '../../../../utility/helpers/onboardHelpers';

interface Props {
    product: CapacityCheck;
    matchingProduct?: Product;
}

const useStyles = makeStyles((theme) => ({
    productTile: {
        marginBottom: '2em'
    },
    propertyList: {
        margin: 0,
        fontFamily: lato,
        paddingLeft: '1.75em',
        lineHeight: '1.5',
        '& li': {
            fontSize: theme.typography.body1.fontSize
        },
        width: '14em'
    },
    imageBackground: {
        height: '4em',
        width: '4em',
        backgroundColor: ballLtGray_3,
        display: 'flex'
    },
    productImage: {
        maxHeight: '3.5em',
        maxWidth: '3.5em',
        margin: 'auto'
    }
}));

export default function OnboardingWizardProductTile({ product, matchingProduct }: Props) {
    const classes = useStyles();
    const isEndType = !!product.endSize;
    const productImage = isEndType
        ? process.env.PUBLIC_URL + '/source/assets/end.png'
        : matchingProduct?.content?.canImage;

    return (
        <Grid container className={classes.productTile} alignItems="center">
            <Grid item className={classes.imageBackground}>
                <img className={classes.productImage} alt="ball product" src={productImage} />
            </Grid>
            <Grid item>
                {isEndType ? (
                    <ul className={classes.propertyList}>
                        {product.endSize && <li>{formatEndSize(product.endSize)}</li>}
                        {product.endColor && <li>{product.endColor}</li>}
                        {product.tabColor && <li>{product.tabColor}</li>}
                    </ul>
                ) : (
                    <ul className={classes.propertyList}>
                        <li>
                            {matchingProduct &&
                                getProductDescriptionForRegion(matchingProduct, Region.NA)}
                        </li>
                        <li>{getVarnishAndInkDisplay(product)}</li>
                        {product.productEnhancement && (
                            <li>{getProductEnhancementDisplay(product.productEnhancement)}</li>
                        )}
                    </ul>
                )}
            </Grid>
        </Grid>
    );
}
