import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { Trans } from 'react-i18next';
import Link from '../atoms/Link';
import {
    ballBlue,
    ballGray,
    black,
    errorBackground,
    shipRed,
    shipWarningBlue,
    warningBorder,
    warningLabel,
    campaignBorder,
    campaignLabel
} from '../../../themes/globalConstants';
import clsx from 'clsx';

interface Props {
    onShowErrors: () => void;
    onShowWarnings: () => void;
    onShowAlerts?: () => void;
    onShowAll: () => void;
    onShowCampaigns?: () => void;
    showErrors: boolean;
    showWarnings: boolean;
    showAlerts?: boolean;
    showCampaigns?: boolean;
    showAll: boolean;
}

const useStyles = makeStyles((theme) => ({
    allLink: {
        color: black
    },
    filterMenuLabel: {
        textTransform: 'uppercase',
        fontSize: '0.75em',
        color: ballGray,
        fontWeight: 'bold',
        paddingRight: '0.625em'
    },
    filterMenuItem: {
        padding: '0.5em',
        borderRadius: '0.25em',
        fontSize: '0.75em',
        lineHeight: '0.9375em',
        cursor: 'pointer',
        '&:hover': {
            color: black
        },

        '& img': {
            verticalAlign: 'text-bottom',
            paddingRight: '0.25em',
            height: '1em'
        }
    },
    alertLink: {
        color: black,
        '&.active': {
            backgroundColor: shipWarningBlue,
            border: `1px solid ${ballBlue}`
        }
    },
    errorLink: {
        color: black,
        '&.active': {
            backgroundColor: errorBackground,
            border: `1px solid ${shipRed}`
        }
    },
    warningLink: {
        color: black,
        '&.active': {
            backgroundColor: warningLabel,
            border: `1px solid ${warningBorder}`
        }
    },
    campaignLink: {
        color: black,
        '&.active': {
            backgroundColor: campaignLabel,
            border: `1px solid ${campaignBorder}`
        }
    }
}));

const ValidationFilterMenu = (props: Props) => {
    const classes = useStyles();

    return (
        <Grid container justify="flex-start">
            <Typography className={clsx(classes.filterMenuLabel)}>
                <Trans i18nKey="view">View</Trans>
                {' /'}
            </Typography>
            <Link
                underline="always"
                data-testid="view-all-link"
                className={clsx(classes.filterMenuItem, classes.allLink)}
                onClick={props.onShowAll}
            >
                <Trans i18nKey="all">All</Trans>
            </Link>
            <Link
                underline="always"
                data-testid="view-all-link"
                className={clsx(
                    classes.filterMenuItem,
                    classes.errorLink,
                    props.showErrors && `active`
                )}
                onClick={props.onShowErrors}
            >
                <img src="/source/assets/Error_Icon.svg" alt="Error Icon"></img>
                <Trans i18nKey="errorsOnly">Errors Only</Trans>
            </Link>
            {props.onShowAlerts && (
                <Link
                    underline="always"
                    data-testid="view-all-link"
                    className={clsx(
                        classes.filterMenuItem,
                        classes.alertLink,
                        props.showAlerts && `active`
                    )}
                    onClick={props.onShowAlerts}
                >
                    <img
                        data-testid="alerts-icon"
                        src="/source/assets/InfoTriangle_Icon.svg"
                        alt="Alerts Icon"
                    ></img>
                    <Trans i18nKey="alertsOnly">Alerts Only</Trans>
                </Link>
            )}
            {props.onShowWarnings && (
                <Link
                    underline="always"
                    data-testid="view-all-link"
                    className={clsx(
                        classes.filterMenuItem,
                        classes.warningLink,
                        props.showWarnings && `active`
                    )}
                    onClick={props.onShowWarnings}
                >
                    <img
                        data-testid="warning-icon"
                        src="/source/assets/Warning_icon.svg"
                        alt="Warning Icon"
                    ></img>
                    <Trans i18nKey="warningsOnly">Warnings Only</Trans>
                </Link>
            )}

            {props.onShowCampaigns && (
                <Link
                    underline="always"
                    data-testid="view-all-link"
                    className={clsx(
                        classes.filterMenuItem,
                        classes.campaignLink,
                        props.showCampaigns && `active`
                    )}
                    onClick={props.onShowCampaigns}
                >
                    <Trans i18nKey="campaignProducts"> Campaign Products</Trans>
                </Link>
            )}
        </Grid>
    );
};

export default ValidationFilterMenu;
