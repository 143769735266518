/* eslint-disable jsx-a11y/no-onchange */
import React, { ReactNode } from 'react';
import { KeyValuePair } from '../../../store/types';
import {
    makeStyles,
    Radio,
    FormControl,
    FormControlLabel,
    RadioGroup,
    FormLabel
} from '@material-ui/core';
import {
    small,
    ltBlueGrey,
    medium,
    boldWeight,
    blackWeight
} from '../../../themes/globalConstants';
import clsx from 'clsx';
import { Trans } from 'react-i18next';

interface Props {
    label: ReactNode;
    dropdownContent: Array<KeyValuePair>;
    actionType: string;
    defaultValue: string;
    aria: string;
    disabled?: boolean;
    onChange: (type: any | null, value: any | null) => void;
}

const useStyles = makeStyles((theme) => ({
    control: {
        display: 'block',
        marginTop: '1em'
    },
    label: {
        fontSize: small,
        color: `${ltBlueGrey} !important`,
        fontWeight: blackWeight,
        letterSpacing: 1,
        textTransform: 'uppercase',
        marginTop: '.25em',
        marginBottom: '.25em',
        paddingLeft: 16,
        paddingRight: 16,
    },
    radio: {
        fontSize: medium,
        letterSpacing: 0.17,
        textAlign: 'left',
        marginLeft: 0,
        color: theme.palette.secondary.main,
        paddingLeft: 16,
        paddingRight: 16,
        '& .MuiFormControlLabel-label': {
            width: '100%',
            fontWeight: boldWeight
        },
        '& .MuiRadio-root': {
            paddingTop: 7,
            paddingBottom: 7
        },
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
        }
    },
    selected: {
        color: theme.palette.primary.main
    }
}));

const HeaderDropdown = (props: Props) => {
    const classes = useStyles();
    const options = props.dropdownContent.map((value, index) => (
        <FormControlLabel
            control={<Radio />}
            label={value.name}
            className={clsx(classes.radio, {
                [classes.selected]: value.value === props.defaultValue
            })}
            data-testid={'dropdown_item_' + index}
            key={index}
            labelPlacement="start"
            value={value.value}
            disabled={props.disabled}
        />
    ));

    const handleChange = (type: any, value: any) => {
        props.onChange(type, value);
    };

    return (
        <FormControl component="fieldset" className={classes.control} size="small">
            <FormLabel component="legend" className={classes.label}>
                <Trans i18nKey={props.label?.toString().toLowerCase()}>{props.label}</Trans>
            </FormLabel>
            <RadioGroup
                data-testid={'radio-' + props.aria}
                value={props.defaultValue}
                aria-label={props.aria}
                onChange={(e) => handleChange(props.actionType, e.target.value)}
            >
                {options}
            </RadioGroup>
        </FormControl>
    );
};

export default HeaderDropdown;
