import React, { useEffect, useState } from 'react';
import {
    Grid,
    makeStyles,
    Typography,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableBody
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Link from '../../../reusable/atoms/Link';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';
import { generateKey } from '../../../../utility/helpers/order-helpers';
import { LinkedProductionOrder } from '../../../../store/reducers/product-planning';
import { getMondayOfWeekFormatted, getWeek } from '../../../../utility/helpers/date-helpers';
import { medium, blackWeight, xl } from '../../../../themes/globalConstants';
import { formatNumberWithLocale } from '../../../../utility/helpers/formatters';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { ProductionOrderLine } from '../../../../store/reducers/makeit-dashboard';
import { enUS } from '../../../../utility/translations/locales';

interface Props {
    linkedPOs: LinkedProductionOrder;
    weekNumber: number;
    quantityPerPallet?: number;
    exceededAllocation?: boolean;
}

const useStyles = makeStyles((theme) => ({
    main: {
        fontSize: medium,
        padding: '1em'
    },
    weekText: {
        marginLeft: '1em'
    },
    columnHeaders: {
        fontSize: xl,
        fontWeight: blackWeight
    },
    longValue: {
        overflowWrap: 'anywhere'
    }
}));

const ProductPlanningATMProductionBalanceModal = ({
    linkedPOs,
    weekNumber,
    quantityPerPallet,
    exceededAllocation
}: Props) => {
    const classes = useStyles();
    const date = getWeek(weekNumber);
    const [poLines, setPoLines] = useState<ProductionOrderLine[]>([]);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { t } = useTranslation();

    useEffect(() => {
        if (linkedPOs) {
            // sort lines based on requested date
            let linkedOrderLines = [] as ProductionOrderLine[];
            linkedPOs.linkedProductionOrders?.forEach((linkedPO) => {
                linkedPO.lines?.forEach((line) => {
                    line.productionOrderId = linkedPO.productionOrderId;
                    line.productionOrderNumber = linkedPO.productionOrderNumber;
                    linkedOrderLines.push(line);
                });
            });
            linkedOrderLines.sort((a: ProductionOrderLine, b: ProductionOrderLine) => {
                return (
                    parseInt(moment(a.requestedDate).format('X')) -
                    parseInt(moment(b.requestedDate).format('X'))
                );
            });
            setPoLines(linkedOrderLines);
        }
    }, [linkedPOs]);

    return (
        <Grid container className={classes.main}>
            <Grid item xs={12}>
                <Typography variant="body1" className={classes.weekText}>
                    <Trans i18nKey="weekOfWithDate">Week of {{ date }}</Trans>
                </Typography>
            </Grid>
            <Grid container item xs={12}>
                <Table
                    size="small"
                    aria-label="production order table"
                    key={generateKey('production-orders-table')}
                    data-testid="atm-production-balance-table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell
                                colSpan={2}
                                className={classes.columnHeaders}
                                data-testid="make-it-atm-orders"
                            >
                                <Trans i18nKey="productionOrders">Production Order(s)</Trans>
                            </TableCell>
                            <TableCell
                                colSpan={2}
                                className={classes.columnHeaders}
                                data-testid="requested-week"
                            >
                                <Trans i18nKey="requestedWeek">Requested Week</Trans>
                            </TableCell>
                            <TableCell
                                colSpan={1}
                                align="right"
                                className={classes.columnHeaders}
                                data-testid="quantity"
                            >
                                <Trans i18nKey="quantity">Quantity</Trans>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {poLines.length === 0 && exceededAllocation && (
                            <TableRow key={'production-orders-table-row_0'}>
                                <TableCell colSpan={5}>
                                    <Trans i18nKey="shipmentsExceededProduction">
                                        Shipments exceeded production balances
                                    </Trans>
                                </TableCell>
                            </TableRow>
                        )}
                        {poLines.length === 0 && !exceededAllocation && (
                            <TableRow key={'production-orders-table-row_0'}>
                                <TableCell colSpan={5}>
                                    <Trans i18nKey="noProductBalances">
                                        No product balances remaining for this week
                                    </Trans>
                                </TableCell>
                            </TableRow>
                        )}
                        {poLines.length > 0 &&
                            poLines.map((linkedLine, index) => (
                                <TableRow key={'production-orders-table-row_' + index}>
                                    <TableCell colSpan={2}>
                                        <Link
                                            component={RouterLink}
                                            underline="always"
                                            to={`/make-it-po-order/${linkedLine.productionOrderId}`}
                                        >
                                            {linkedLine.productionOrderNumber ??
                                                t('pending', enUS.pending)}
                                        </Link>
                                    </TableCell>
                                    <TableCell colSpan={2}>
                                        {getMondayOfWeekFormatted(linkedLine.requestedDate!)}
                                    </TableCell>
                                    <TableCell align="right">
                                        {linkedLine.palletQuantity &&
                                            formatNumberWithLocale(
                                                cultureCode,
                                                linkedLine.palletQuantity
                                            ) + ' PL'}
                                        <Typography variant="body1" className={classes.longValue}>
                                            {quantityPerPallet &&
                                                linkedLine.palletQuantity &&
                                                formatNumberWithLocale(
                                                    cultureCode,
                                                    linkedLine.palletQuantity * quantityPerPallet
                                                ) + ' ea.'}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    );
};

export default ProductPlanningATMProductionBalanceModal;
