import React, { useState, useEffect } from 'react';
import PdfPageTemplate from '../../../templates/PdfPageTemplate';
import { Document, Page, Text, View, BlobProvider, Image } from '@react-pdf/renderer';
import pdfStyles from '../../../../themes/pdfStyles';
import { isIE } from '../../../../utility/helpers/user-helpers';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { isProductEndType } from '../../../../utility/helpers/order-helpers';
import { TradeItRequestState } from '../../../../store/reducers/trade-it';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { formatNumberWithLocale } from '../../../../utility/helpers/formatters';

interface TradeItDisplayLine {
    originalCustomerId: string,
    receivingCustomerId: string,
    productDescription: string,
    customerProductId: string,
    quantityToTradePaletts: number,
    quantityToTradeEaches: number,
    previousBalanceFromPallets: number,
    previousBalanceFromEaches: number,
    previousBalanceToPallets: number,
    previousBalanceToEaches: number
}

interface Props {
    title: string
}

const TradeItConfirmationPDF = ({ title }: Props) => {
    const ballLogo = process.env.PUBLIC_URL + '/assets/Ball_RGB.jpg';
    const sourceLogo = process.env.PUBLIC_URL + '/assets/TheSource_Logo.png';
    const { t } = useTranslation();
    const { products, submittedRequest } = useTypedSelector<TradeItRequestState>((state) => state.tradeItRequest);
    const { shipToAccounts } = useTypedSelector<CustomerContextState>((state) => state.customerContext);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const [accountFromName, setAccountFromName] = useState('');
    const [accountFromAddress, setAccountFromAddress] = useState('');
    const [accountToName, setAccountToName] = useState('');
    const [accountToAddress, setAccountToAddress] = useState('');
    const [requestCanSkus, setRequestCanSkus] = useState(2);
    const [requestCanPallets, setRequestCanPallets] = useState(0);
    const [requestEndSkus, setRequestEndSkus] = useState(0);
    const [requestEndPallets, setRequestEndPallets] = useState(0);
    const [requestCanEaches, setRequestCanEaches] = useState(0);
    const [requestEndEaches, setRequestEndEaches] = useState(0);
    const [tradeItLines, setTradeItLines] = useState<TradeItDisplayLine[]>([]);

    useEffect(() => {
        if (shipToAccounts && submittedRequest) {
            const accountFrom = shipToAccounts.find(
                (account) => account.accountId === submittedRequest.accountFrom
            );
            const accountTo = shipToAccounts.find(
                (account) => account.accountId === submittedRequest.accountTo
            );
            if (accountFrom) {
                setAccountFromName(accountFrom.name);
                if (accountFrom.address) {
                    const accountFromAddress = accountFrom.address;
                    setAccountFromAddress(
                        accountFromAddress.address1?.trimEnd() +
                            ', ' +
                            accountFromAddress.city +
                            ', ' +
                            accountFromAddress.stateProvince +
                            ' ' +
                            accountFromAddress.postalCode
                    );
                }
            }
            if (accountTo) {
                setAccountToName(accountTo.name);
                if (accountTo.address) {
                    const accountToAddress = accountTo.address;
                    setAccountToAddress(
                        accountToAddress.address1?.trimEnd() +
                            ', ' +
                            accountToAddress.city +
                            ', ' +
                            accountToAddress.stateProvince +
                            ' ' +
                            accountToAddress.postalCode
                    );
                }
            }
        }
    }, [shipToAccounts, submittedRequest]);

    useEffect(() => {
        let canSkus = 0;
        let canPallets = 0;
        let endSkus = 0;
        let endPallets = 0;
        let canEaches = 0;
        let endEaches = 0;
        let tradeItDisplayLines: TradeItDisplayLine[] = [];

        if (products && submittedRequest) {
            submittedRequest.requestLines.forEach((line) => {
                const requestedProduct = products.find(
                    (product) => product.productSku === line.productSku
                );
                if (requestedProduct) {
                    if (isProductEndType(requestedProduct.type)) {
                        endSkus += 1;
                        endPallets += line.quantity;
                        endEaches += requestedProduct.quantityPerPallet
                            ? line.quantity * requestedProduct.quantityPerPallet
                            : 0;
                    } else {
                        canSkus += 1;
                        canPallets += line.quantity;
                        canEaches += requestedProduct.quantityPerPallet
                            ? line.quantity * requestedProduct.quantityPerPallet
                            : 0;
                    }
                    
                    let tradeItDisplayLine: TradeItDisplayLine = {
                        originalCustomerId: submittedRequest.accountFrom,
                        receivingCustomerId: submittedRequest.accountTo,
                        productDescription: `${requestedProduct.displayName} (${requestedProduct.productSku}${
                            requestedProduct.graphicIdAndVersion ? `/${requestedProduct.graphicIdAndVersion}` : ''})`,
                        customerProductId: requestedProduct.displayId ?? '',
                        quantityToTradePaletts: requestedProduct.quantityToTrade ?? 0,
                        quantityToTradeEaches: (requestedProduct.quantityToTrade ?? 0) * (requestedProduct.quantityPerPallet ?? 0),
                        previousBalanceFromPallets: requestedProduct.originalShipToProductionBalance ?? 0,
                        previousBalanceFromEaches: (requestedProduct.originalShipToProductionBalance ?? 0) * (requestedProduct.quantityPerPallet ?? 0),
                        previousBalanceToPallets: (requestedProduct.receivingShipToProductionBalance ?? 0),
                        previousBalanceToEaches: (requestedProduct.receivingShipToProductionBalance ?? 0) * (requestedProduct.quantityPerPallet ?? 0),
                    }
                    tradeItDisplayLines.push(tradeItDisplayLine);
                }
            });
        }
        setRequestCanSkus(canSkus);
        setRequestCanPallets(canPallets);
        setRequestEndSkus(endSkus);
        setRequestEndPallets(endPallets);
        setRequestCanEaches(canEaches);
        setRequestEndEaches(endEaches);
        setTradeItLines(tradeItDisplayLines);
    }, [products, submittedRequest]);

    const summary = (
            <>
                <View style={[pdfStyles.borderlessTable, pdfStyles.marginTop20]}>
                    <View style={[pdfStyles.flexSection, pdfStyles.pageHeader]}>
                        <View style={[pdfStyles.divider25, pdfStyles.marginBottom10]} fixed />
                        <View>
                            <View>
                                <Text style={pdfStyles.header2}>
                                    {t('tradeItRequest', 'Trade It Request').toLocaleUpperCase()}
                                    {' # '}
                                </Text>
                            </View>
                            <View>
                                <Text style={[pdfStyles.subtitle14, pdfStyles.marginBottom3]}>
                                    {`${submittedRequest?.tradeRequestId}`}
                                </Text>
                            </View>
                        </View>
                        <View
                            style={[pdfStyles.borderLessTableColNarrow7, pdfStyles.textAlignRight]}
                        >
                            <View>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="canQuantity">Can Quantity</Trans>
                                </Text>
                            </View>
                            <View>
                                <Text style={[pdfStyles.subtitle14, pdfStyles.marginBottom3]}>
                                    {formatNumberWithLocale(cultureCode, requestCanPallets) + ` PL / ` + formatNumberWithLocale(cultureCode, requestCanSkus) + 'SKU'}
                                </Text>
                                <Text style={pdfStyles.subtitle7}>
                                    {formatNumberWithLocale(cultureCode, requestCanEaches) + ' ea'}
                                </Text>
                            </View>
                        </View>
                        <View
                            style={[pdfStyles.borderLessTableColNarrow7, pdfStyles.textAlignRight, pdfStyles.marginBottom5]}
                        >
                            <View>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="endQuantity">End Quantity</Trans>
                                </Text>
                            </View>
                            <View>
                                <Text style={[pdfStyles.subtitle14, pdfStyles.marginBottom3]}>
                                    {formatNumberWithLocale(cultureCode, requestEndPallets) + ` PL / ` + formatNumberWithLocale(cultureCode, requestEndSkus) + 'SKU'}
                                </Text>
                                <Text style={pdfStyles.subtitle7}>
                                    {formatNumberWithLocale(cultureCode, requestEndEaches) + ' ea'}
                                </Text>
                            </View>
                        </View>
                        <View style={[pdfStyles.divider25, pdfStyles.marginBottom10]} fixed />
                        <View style={[pdfStyles.borderLessTableCol, pdfStyles.textAlignLeft]}>
                            <View>
                                <Text style={[pdfStyles.header2, pdfStyles.marginBottom3]}>
                                    {t('originalShipTo', 'Original Ship To').toLocaleUpperCase()}
                                </Text>
                            </View>
                            <View style={[pdfStyles.marginBottom10]}>
                                <Text style={[pdfStyles.subtitle15, pdfStyles.textAlignLeft]}>
                                    {`${accountFromName}`}
                                </Text>
                                <Text style={[pdfStyles.subtitle15, pdfStyles.textAlignLeft]}>
                                    {`${accountFromAddress}`}
                                </Text>
                                <Text style={[pdfStyles.subtitle15, pdfStyles.textAlignLeft]}>
                                    #{submittedRequest?.accountFrom ?? ''}
                                </Text>
                            </View>
                            <View style={[pdfStyles.marginTop10]}>
                                <Text style={[pdfStyles.header2, pdfStyles.marginBottom3]}>
                                    {t('originalShipToContact', 'Original Ship To Contact').toLocaleUpperCase()}
                                </Text>
                            </View>
                            <View>
                                <Text style={[pdfStyles.subtitle15, pdfStyles.textAlignLeft]}>
                                    {`${submittedRequest?.fillerContactFrom}`}
                                </Text>
                            </View>
                        </View>
                        <View style={[pdfStyles.borderLessTableCol, pdfStyles.textAlignLeft]}>
                            <View>
                                <Text style={[pdfStyles.header2, pdfStyles.marginBottom3]}>
                                    {t('receivingShipTo', 'Receiving Ship To').toLocaleUpperCase()}
                                </Text>
                            </View>
                            <View style={[pdfStyles.marginBottom10]}>
                                <Text style={[pdfStyles.subtitle15, pdfStyles.textAlignLeft]}>
                                    {`${accountToName}`}
                                </Text>
                                <Text style={[pdfStyles.subtitle15, pdfStyles.textAlignLeft]}>
                                    {`${accountToAddress}`}
                                </Text>
                                <Text style={[pdfStyles.subtitle15, pdfStyles.textAlignLeft]}>
                                    #{submittedRequest?.accountTo ?? ''}
                                </Text>
                            </View>
                            <View style={[pdfStyles.marginTop10]}>
                                <Text style={[pdfStyles.header2, pdfStyles.marginBottom3]}>
                                    {t('receivinglShipToContact', 'Receiving Ship To Contact').toLocaleUpperCase()}
                                </Text>
                            </View>
                            <View>
                                <Text style={[pdfStyles.subtitle15, pdfStyles.textAlignLeft]}>
                                    {`${submittedRequest?.fillerContactTo}`}
                                </Text>
                            </View>
                        </View>
                        <View style={[pdfStyles.borderLessTableCol, pdfStyles.textAlignLeft]} />
                        <View style={[pdfStyles.borderLessTableCol, pdfStyles.textAlignRight]}>
                            <View>
                                <Text style={[pdfStyles.header2, pdfStyles.marginBottom3]}>
                                    {t('dateSubmitted', 'Date Submitted').toLocaleUpperCase()}
                                </Text>
                            </View>
                            <View>
                                <Text style={[pdfStyles.subtitle15, pdfStyles.textAlignRight]}>
                                    {submittedRequest?.submittedDate && moment(submittedRequest.submittedDate).format('MM/DD/YYYY')}
                                </Text>
                            </View>
                        </View>

                    </View>
                </View>
            </>
    );

    const tableHeaders = (
        <>
            <View style={[pdfStyles.borderlessTable, pdfStyles.marginTop20]}>
                <View style={[pdfStyles.flexSection, pdfStyles.pageHeader]}>
                    <View
                        style={[pdfStyles.borderLessTableCol, pdfStyles.textAlignLeft]}
                    >
                        <View>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="productDescription">Product Description</Trans>
                            </Text>
                        </View>
                        <View>
                            <Text style={pdfStyles.header2}>
                                (<Trans i18nKey="productGraphicId">Product ID / Graphic ID</Trans>)
                            </Text>
                        </View>
                    </View>
                    <View
                        style={[pdfStyles.borderLessTableCol, pdfStyles.textAlignLeft]}
                    >
                        <View>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="customerProductId">Customer Product ID</Trans>
                            </Text>
                        </View>
                    </View>
                    <View
                        style={[pdfStyles.borderLessTableColWide, pdfStyles.textAlignRight]}
                    >
                        <View>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="requestedQuantityToTrade">Requested Quantity To Trade</Trans>
                            </Text>
                        </View>
                    </View>
                    <View style={[pdfStyles.divider5]} fixed />
                </View>
            </View>
        </>
    );

    const tableItems = tradeItLines.map((line) => {
        return (
            <>
                <View style={[pdfStyles.borderlessTable, pdfStyles.marginTop5]}>
                    <View style={[pdfStyles.flexSection, pdfStyles.pageHeader]}>
                        <View
                            style={[pdfStyles.borderLessTableCol, pdfStyles.textAlignLeft]}
                        >
                            <View>
                                <Text style={pdfStyles.subtitle7}>
                                    {line.productDescription}
                                </Text>
                            </View>
                        </View>
                        <View
                            style={[pdfStyles.borderLessTableCol, pdfStyles.textAlignLeft]}
                        >
                            <View>
                                <Text style={pdfStyles.subtitle7}>
                                {line.customerProductId}
                                </Text>
                            </View>
                        </View>
                        <View
                            style={[pdfStyles.borderLessTableColWide, pdfStyles.textAlignRight]}
                        >
                            <View>
                                <Text style={pdfStyles.subtitle7}>
                                    {formatNumberWithLocale(cultureCode, line.quantityToTradePaletts)} PL
                                </Text>
                            </View>
                            <View>
                                <Text style={pdfStyles.header10}>
                                    {formatNumberWithLocale(cultureCode, line.quantityToTradeEaches)} ea
                                </Text>
                            </View>
                        </View>
                        <View style={[pdfStyles.dividerThinGray2, pdfStyles.marginBottom5, pdfStyles.marginTop5]} fixed />
                        <View style={[pdfStyles.flexSection, pdfStyles.marginLeft10]}>
                            <View style={[pdfStyles.borderLessTableColNarrow3, pdfStyles.textAlignLeft]}>
                                <View>
                                    <Text style={pdfStyles.header2}>
                                        <Trans i18nKey="originalShipToProductionBalance">Previous Production Balance For Original Ship To</Trans>
                                        {' #' + line.originalCustomerId}
                                    </Text>
                                </View>
                            </View>
                            <View style={[pdfStyles.borderLessTableColNarrow3, pdfStyles.textAlignLeft]}>
                                <View>
                                    <Text style={pdfStyles.header2}>
                                        <Trans i18nKey="receivingShipToProductionBalance">Previous Production Balance For Receiving Ship To</Trans>
                                        {' #' + line.receivingCustomerId}
                                    </Text>
                                </View>
                            </View>
                            <View style={[pdfStyles.borderLessTableColNarrow3, pdfStyles.textAlignLeft]}>
                                <View>
                                    <Text style={pdfStyles.header2}>
                                        <Trans i18nKey="requestedQuantityToTrade">Requested Quantity To Trade</Trans>
                                    </Text>
                                </View>
                            </View>
                            <View style={[pdfStyles.borderLessTableColNarrow3, pdfStyles.textAlignLeft]}>
                                <View>
                                    <Text style={pdfStyles.header2}>
                                        <Trans i18nKey="originalShipToNewProductionBalance">Updated Production Balance For Original Ship To</Trans>
                                        {' #' + line.originalCustomerId}
                                    </Text>
                                </View>
                            </View>
                            <View style={[pdfStyles.borderLessTableColNarrow3, pdfStyles.textAlignLeft]}>
                                <View>
                                    <Text style={pdfStyles.header2}>
                                        <Trans i18nKey="receivingShipToNewProductionBalance">Updated Production Balance For Receiving Ship To</Trans>
                                        {' #' + line.receivingCustomerId}
                                    </Text>
                                </View>
                            </View>
                            <View style={[pdfStyles.dividerThinGray2, pdfStyles.marginBottom10, pdfStyles.marginTop5]} fixed />
                            <View style={[pdfStyles.borderLessTableColNarrow3, pdfStyles.textAlignLeft]}>
                                <View>
                                    <Text style={[pdfStyles.subtitle7, pdfStyles.marginBottom3]}>
                                        {formatNumberWithLocale(cultureCode, line.previousBalanceFromPallets)} PL
                                    </Text>
                                    <Text style={pdfStyles.subtitle7}>
                                        {formatNumberWithLocale(cultureCode, line.previousBalanceFromEaches)} ea
                                    </Text>
                                </View>
                            </View>
                            <View style={[pdfStyles.borderLessTableColNarrow3, pdfStyles.textAlignLeft]}>
                                <View>
                                    <Text style={[pdfStyles.subtitle7, pdfStyles.marginBottom3]}>
                                        {formatNumberWithLocale(cultureCode, line.previousBalanceToPallets)} PL
                                    </Text>
                                    <Text style={pdfStyles.subtitle7}>
                                        {formatNumberWithLocale(cultureCode, line.previousBalanceToEaches)} ea
                                    </Text>
                                </View>
                            </View>
                            <View style={[pdfStyles.borderLessTableColNarrow3, pdfStyles.textAlignLeft]}>
                                <View>
                                    <Text style={[pdfStyles.subtitle7, pdfStyles.marginBottom3]}>
                                        {formatNumberWithLocale(cultureCode, line.quantityToTradePaletts)} PL
                                    </Text>
                                    <Text style={pdfStyles.subtitle7}>
                                        {formatNumberWithLocale(cultureCode, line.quantityToTradeEaches)} ea
                                    </Text>
                                </View>
                            </View>
                            <View style={[pdfStyles.borderLessTableColNarrow3, pdfStyles.textAlignLeft]}>
                                <View>
                                    <Text style={[pdfStyles.subtitle7, pdfStyles.marginBottom3]}>
                                        {formatNumberWithLocale(cultureCode, line.previousBalanceFromPallets - line.quantityToTradePaletts)} PL
                                    </Text>
                                    <Text style={pdfStyles.subtitle7}>
                                        {formatNumberWithLocale(cultureCode, line.previousBalanceFromEaches - line.quantityToTradeEaches)} ea
                                    </Text>
                                </View>
                            </View>
                            <View style={[pdfStyles.borderLessTableColNarrow3, pdfStyles.textAlignLeft]}>
                                <View>
                                    <Text style={[pdfStyles.subtitle7, pdfStyles.marginBottom3]}>
                                        {formatNumberWithLocale(cultureCode, line.previousBalanceToPallets + line.quantityToTradePaletts)} PL
                                    </Text>
                                    <Text style={pdfStyles.subtitle7}>
                                        {formatNumberWithLocale(cultureCode, line.previousBalanceToEaches + line.quantityToTradeEaches)} ea
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <View style={pdfStyles.marginBottom10} />
                        <View style={[pdfStyles.divider5, pdfStyles.marginBottom10]} fixed />
                    </View>
                </View>
            </>
        );
    });

    const myDoc = (
        <Document title={title}>
            <Page size="A4" style={pdfStyles.pagePadding25} orientation={'portrait'}>
                <View style={pdfStyles.logoSection}>
                    <Image src={sourceLogo} style={pdfStyles.sourceLogo} />
                    <Image src={ballLogo} style={pdfStyles.ballLogo} />
                </View>
                <View style={[pdfStyles.flexSection, pdfStyles.pageHeader]}>{summary}</View>
                <View style={[pdfStyles.flexSection, pdfStyles.pageHeader]}>{tableHeaders}</View>
                <View style={[pdfStyles.flexSection, pdfStyles.pageHeader]}>{tableItems}</View>
                <View style={[pdfStyles.fullWidth, pdfStyles.footer]} fixed>
                    <Text
                        style={pdfStyles.header2}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </View>
            </Page>
        </Document>
    );

    if (!isIE()) {
        return <PdfPageTemplate title={title}>{myDoc}</PdfPageTemplate>;
    } else {
        return (
            <BlobProvider document={myDoc}>
                {({ blob, url, loading, error }) => {
                    if (blob && window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob);
                    }
                    return null;
                }}
            </BlobProvider>
        );
    }
};

export default TradeItConfirmationPDF;
