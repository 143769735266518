import React, { useState } from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import Link from '../../../reusable/atoms/Link';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import Modal from '../../../reusable/molecules/Modal';
import ReactPdf from '@react-pdf/renderer';
import moment from 'moment';
import PlanItBulkUploadConfirmationPDF from './PlanItBulkUploadConfirmationPDF';

import { ballBlue, small, regularWeight } from '../../../../themes/globalConstants';
import { CSVLink } from 'react-csv';
import { filterOutDeletedOrders } from '../../MakeItBulkATMConfirmation/utils/mib-atm-confirmation-utils';
import {
    PlanItBulkATMOrderByWeekAndSku
} from '../../../../store/reducers/planit-bulk-atm';
import { getFormattedDateStringForApplication } from '../../../../utility/helpers/date-helpers';
import { enUS } from '../../../../utility/translations/locales';

interface Props {
    atmOrdersBySnoSku: PlanItBulkATMOrderByWeekAndSku[];
}

const useStyles = makeStyles((theme) => ({
    label: {
        textTransform: 'uppercase',
        fontSize: small,
        paddingTop: '0.5em'
    },
    link: {
        fontSize: small,
        fontWeight: regularWeight,
        width: '8.75em',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    icon: {
        color: ballBlue
    }
}));

const pdfStyles = ReactPdf.StyleSheet.create({
    link: {
        fontSize: small,
        fontWeight: regularWeight,
        width: '8.75em'
    }
});

const PlanItBulkUploadConfirmationShareLinks = ({ atmOrdersBySnoSku }: Props) => {
    const classes = useStyles();
    const [openPdf, setOpenPdf] = useState(false);
    const { t } = useTranslation();
    const createDate = moment().format('MM-DD-YYYY');

    const printForm = () => {
        setOpenPdf(true);
    };

    const onClose = () => {
        setOpenPdf(false);
    };

    interface CSVRow {
        shipToId?: number;
        forecastWeek?: string;
        productId?: string;
        productDescription?: string;
        coating?: string;
        forecastedQuantityPL?: number;
        forecastedQuantityEA?: number;
    }

    const csvHeaders = [
        { label: 'SHIP TO ID', key: 'shipToId' },
        { label: 'FORECAST WEEK', key: 'forecastWeek' },
        { label: 'PRODUCT ID', key: 'productId' },
        { label: 'PRODUCT DESCRIPTION', key: 'productDescription' },
        { label: 'COATING', key: 'coating' },
        { label: 'FORECASTED QUANTITY (PL)', key: 'forecastedQuantityPL' },
        { label: 'FORECASTED QUANTITY (EA)', key: 'forecastedQuantityEA' }
    ];

    const translateCSVHeaders = () => {
        csvHeaders.forEach((header) => {
            header.label = t(header.key, header.label).toUpperCase();
        });
        return csvHeaders;
    };

    const csvArray = () => {
        let csvRows: CSVRow[] = [];
        atmOrdersBySnoSku.forEach((order) => {
            order.linesBySnoSku.forEach((lineBySnoSku) => {
                lineBySnoSku.lines.forEach((line) => {
                    const eachesAmount =
                        line.quantityPerPallet && line.palletQuantity
                            ? line.quantityPerPallet * line.palletQuantity
                            : 0;
                    const row: CSVRow = {
                        shipToId: order.shipToId,
                        forecastWeek: getFormattedDateStringForApplication(order.weekStart),
                        productId: line.displayId,
                        productDescription: line.displayName,
                        coating: line.coating,
                        forecastedQuantityPL: line.palletQuantity,
                        forecastedQuantityEA: eachesAmount
                    };
                    csvRows.push(row);
                });
            });
        });
        return csvRows;
    };

    const csvContents = csvArray();

    return (
        <Grid container item xs={12} spacing={1} justify="flex-end">
            <Grid item data-testid="share-btn">
                <Typography variant="h6" className={classes.label}>
                    <Trans i18nKey="share">share</Trans>
                </Typography>
            </Grid>
            <Grid item>
                <Link
                    className={classes.link}
                    underline="always"
                    data-testid="print-btn"
                    onClick={printForm}
                >
                    <Trans i18nKey="print">Print</Trans>
                </Link>
                <PrintOutlinedIcon fontSize="small" className={classes.icon} />
            </Grid>
            <Grid item>
                <CSVLink
                    className={
                        'MuiTypography-root MuiLink-root MuiLink-underlineAlways MuiTypography-colorPrimary'
                    }
                    style={pdfStyles.link}
                    underline="always"
                    data-testid="download-btn"
                    headers={translateCSVHeaders()}
                    data={csvContents}
                    filename={`${t('forecast', enUS.forecast)}_${createDate}.csv`}
                >
                    <Trans i18nKey="downloadCSV">Download .CSV</Trans>
                </CSVLink>
                <GetAppOutlinedIcon fontSize="small" className={classes.icon} />
            </Grid>
            <Modal
                open={openPdf}
                title={t('planIt', 'Plan It')}
                close={onClose}
                closeIcon={true}
                fullScreen={true}
            >
                <PlanItBulkUploadConfirmationPDF
                    title={t('planIt', 'Plan It')}
                    orders={filterOutDeletedOrders(atmOrdersBySnoSku)}
                />
            </Modal>
        </Grid>
    );
};

export default PlanItBulkUploadConfirmationShareLinks;
