import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
    Collapse,
    FormControl,
    FormHelperText,
    Grid,
    Link,
    makeStyles,
    Typography,
    Input,
    InputAdornment,
    IconButton
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import FormSection from '../../../reusable/molecules/FormSection';
import {
    boldWeight,
    brightGrey,
    medium,
    paleGrey,
    large,
    xl,
    red,
    black,
    blackWeight,
    ballBlue,
    ballLtGray
} from '../../../../themes/globalConstants';
import FileUpload from '../../../reusable/molecules/FileUpload';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { Alert } from '@material-ui/lab';
import {
    LineErrorCode,
    MakeItBulkLineItem,
    MakeItLineError
} from '../../../../store/reducers/makeit-bulk-upload';
import { stringFormatterReplace } from '../../../../utility/helpers/formatters';
import { Link as RouterLink } from 'react-router-dom';
import { WeekQuantity } from '../../../../store/reducers/makeit-bulk-atm';
import { RowsOrColumns } from '../../../../utility/services/orders-service';
import { Controller, ErrorMessage, useFormContext } from 'react-hook-form';
import moment from 'moment';
import clsx from 'clsx';
import { ProductionOrderLine } from '../../../../store/reducers/makeit-dashboard';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { getCampaigns } from '../../../../utility/helpers/production-order-helpers';
import DaypickerRangeModal from '../../../reusable/molecules/DaypickerRangeModal';
import Modal from '../../../reusable/molecules/Modal';
import { formatDate } from '../../../../utility/helpers/date-helpers';
import { selectIsLargeCustomerAccount } from '../../../../store/selectors';
import { enUS } from '../../../../utility/translations/locales';
import { Moment } from 'moment';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import TranslationService from '../../../../utility/services/translation-service';

interface Props {
    setIsValidFile: (boolean) => void;
    isEdit: boolean;
    headerText: string;
    onFileLoad: (any) => void;
    lineErrors?: Array<MakeItLineError>;
    products?: Array<ProductionOrderLine>;
    weekQuantities?: Array<WeekQuantity>;
    rowsOrColumns?: RowsOrColumns;
    weeks?: Array<Moment>;
    file?: File;
    isPlanIt?: boolean;
}

const useStyles = makeStyles((theme) => ({
    sectionMargin: {
        margin: '2em 0'
    },
    label: {
        color: brightGrey,
        fontSize: medium,
        padding: '0.5em'
    },
    description: {
        fontSize: medium,
        color: brightGrey
    },
    linksWrapper: {
        fontWeight: boldWeight,
        color: black,
        marginTop: '.5em',
        marginLeft: '.5em'
    },
    error: {
        color: red
    },
    lineErrorGrid: {
        color: black,
        paddingBottom: '0.25em'
    },
    lineErrorHelper: {
        color: red,
        paddingBottom: '0.75em'
    },
    lineErrorMessage: {
        color: red,
        fontSize: large,
        fontWeight: boldWeight,
        letterSpacing: 0
    },
    warningIconRed: {
        '& .MuiAlert-icon': {},
        minWidth: '95%'
    },
    collapse: {
        width: '100%'
    },
    weekHeader: {
        color: brightGrey,
        fontWeight: blackWeight,
        letterSpacing: 0,
        lineHeight: 4,
        textTransform: 'uppercase',
        marginLeft: '0.5em',
        marginTop: '1.5em'
    },
    errorText: {
        color: red
    },
    calendarIcon: {
        color: ballBlue
    },
    calendarDiv: {
        margin: '2em'
    },
    calendarInput: {
        '& .Mui-disabled': { cursor: 'pointer', color: black },
        border: `1.8px solid ${ballLtGray}`,
        borderRadius: 2,
        paddingLeft: '0.3em'
    },
    dateBox: {
        width: '105%'
    },
    linkSpacer: {
        marginRight: '.25em'
    },
    linkedPO: {
        textDecoration: `underline ${ballBlue}`
    }
}));

const uploadStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: paleGrey,
        height: '11.5em',
        justifyContent: 'center',
        display: 'flex',
        cursor: 'pointer'
    },
    divContainer: {
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%'
    },
    invalidText: {
        color: 'red'
    },
    icon: {
        height: '1.875em',
        width: '1.4em',
        fill: theme.palette.secondary.main
    },
    text: {
        fontSize: xl,
        fontWeight: boldWeight,
        letterSpacing: '0.13px'
    }
}));

const UploadMakeItOrder = ({
    setIsValidFile,
    isEdit,
    headerText,
    onFileLoad,
    lineErrors,
    products,
    weekQuantities,
    rowsOrColumns,
    weeks,
    file,
    isPlanIt
}: Props) => {
    const classes = useStyles();
    const twoWeeks = moment().day(1).startOf('day').add(21, 'day').startOf('day');
    const twoWeeksEnd = moment(twoWeeks).add(6, 'days');

    const [fileData, setFileData] = useState<File | undefined>();
    const [hideForm, setHideForm] = useState<boolean>(isEdit);
    const [showUploadError, setShowUploadError] = useState<boolean>(false);
    const [hasLineErrors, setHasLineErrors] = useState<boolean>(false);
    const [lineErrorElements, setLineErrorElements] = useState<JSX.Element[]>([]);
    const [campaignProducts, setCampaignProducts] = useState<ProductionOrderLine[]>();
    const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
    const [openWeekFieldName, setOpenWeekFieldName] = useState<string>('');
    const [openStartDate, setOpenStartDate] = useState<string>('');
    const [openEndDate, setOpenEndDate] = useState<string>('');
    const [lockedPeriodStart, setLockedPeriodStart] = useState<moment.Moment>(twoWeeks);
    const [lockedPeriodEnd, setLockedPeriodEnd] = useState<moment.Moment>(twoWeeksEnd);
    const [firstDateSet, setFirstDateSet] = useState<boolean>(false);
    const { setValue, triggerValidation, errors, control, getValues } = useFormContext();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const today = formatDate(moment().toString());

    const { selectedAccountId, shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const isLargeCustomer = useTypedSelector<boolean>(selectIsLargeCustomerAccount);

    const calendarIcon = (
        <img
            className={classes.calendarIcon}
            src={process.env.PUBLIC_URL + '/assets/Calendar_Icon.svg'}
            alt="Calendar Icon"
        />
    );
    const acceptedFileTypes =
        '.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values';
    const isRequired = (
        <Trans i18nKey="weekRequired">Required. Please update or remove a mapped week.</Trans>
    );

    const isOutsideRange = (day) => {
        day = moment(day);
        return day.isBefore(lockedPeriodStart.startOf('day'));
    };

    const handleCalendarOpen = (index: number) => {
        setCalendarOpen(true);
        setOpenWeekFieldName(`weekDate[${index}].date`);
        const start = getValues(`weekDate[${index}].date`);
        if (start !== '') {
            setOpenStartDate(start);
            const end = moment(start).add(6, 'days').format('MM/DD/YYYY');
            setOpenEndDate(end);
        }
    };

    const handleCalendarClose = () => {
        setCalendarOpen(false);
        setOpenWeekFieldName('');
        setOpenStartDate('');
        setOpenEndDate('');
    };
    const handleCalendarOk = () => {
        const newDate =
            openStartDate !== '' ? openStartDate : formatDate(lockedPeriodStart.toString());
        setValue(openWeekFieldName, newDate);
        triggerValidation(openWeekFieldName);
        if (!firstDateSet && openWeekFieldName === 'weekDate[0].date') {
            setSequentialDates(newDate);
            setFirstDateSet(true);
        }
        handleCalendarClose();
    };
    const handleDateRangeChange = (startDate, endDate) => {
        const formattedDate = formatDate(startDate);
        setOpenStartDate(formattedDate);
        setOpenEndDate(formatDate(endDate));
    };

    const setSequentialDates = (firstDate: string) => {
        for (let i = 1; i < weekQuantities!.length; i++) {
            const nextDate = moment(firstDate).add(i, 'weeks').format('MM/DD/YYYY');
            setValue(`weekDate[${i}].date`, nextDate);
        }
    };

    const storeFiles = (fileData) => {
        if (fileData === undefined) {
            setFileData(fileData);
            setIsValidFile(false);
            setShowUploadError(false);
            dispatch(onFileLoad(fileData));
        } else if (fileData.length > 0) {
            setFileData(fileData);
            setIsValidFile(true);
            setShowUploadError(false);
            dispatch(onFileLoad(fileData));
        } else {
            setShowUploadError(true);
        }
        // reset line errors on file upload/removal
        setHasLineErrors(false);
        setLineErrorElements([]);
    };

    const handleUpdateCampaigns = (products: MakeItBulkLineItem[]) => {
        let campaignData = getCampaigns(products);
        // store unique campaign products
        setCampaignProducts(campaignData.campaignProducts);
    };

    const makeItSummaryLink = useMemo(
        () => (
            <Link
                to={'/make-it-po-summary'}
                component={RouterLink}
                underline="none"
                data-testid="make-it-summary-link"
                className={clsx(classes.linkedPO, classes.linkSpacer)}
            >
                Order Summary
            </Link>
        ),
        [classes.linkSpacer, classes.linkedPO]
    );

    const getTranslatedErrorMessages = useCallback(
        (error: MakeItLineError) => {
            const linePrefix = `${t('line', 'Line')} ${error.lineNumber} - `;
            switch (error.errorCode) {
                case LineErrorCode.EP400001:
                    return (
                        linePrefix +
                        stringFormatterReplace(
                            TranslationService.getTranslatedText(cultureCode, error.errorCode!),
                            `${error.attemptedValue}`
                        )
                    );
                case LineErrorCode.EA000004:
                    return (
                        linePrefix +
                        stringFormatterReplace(
                            TranslationService.getTranslatedText(cultureCode, error.errorCode!),
                            `${moment.utc(error.firstAvailableDate!).format('MM/DD/YYYY')}`,
                            `${Math.ceil(error.leadTimeWeeks!)}`
                        )
                    );
                case LineErrorCode.EA000006:
                    return (
                        linePrefix +
                        TranslationService.getTranslatedText(cultureCode, error.errorCode!)
                    );
                case LineErrorCode.EP100010:
                    return t(
                        error.errorCode!,
                        `Your file has multiple Customer PO numbers for the following Week(s): ${error.attemptedValue.join(
                            ','
                        )}.  Please correct this error and upload again to submit your order.`,
                        { weeks: error.attemptedValue.join(',') }
                    );
                default:
                    return (
                        (error.lineNumber !== undefined ? linePrefix : '') +
                        TranslationService.getTranslatedText(cultureCode, error.errorCode!)
                    );
            }
        },
        [cultureCode, makeItSummaryLink]
    );

    useEffect(() => {
        if (shipToAccounts && shipToAccounts.length > 0 && !isPlanIt) {
            const lockWindows = shipToAccounts.map((account) => {
                return account.lockedPeriodDays ? account.lockedPeriodDays : 0;
            });
            const days = Math.max(...lockWindows) > 0 ? Math.max(...lockWindows) + 7 : 21;
            const start = moment().day(1).startOf('day').add(days, 'day').startOf('day');
            setLockedPeriodStart(start);
            setLockedPeriodEnd(moment(start).add(6, 'days'));
        } else if (isPlanIt) {
            const start = moment().add(1, 'week').startOf('week').day(1);
            setLockedPeriodStart(start);
            setLockedPeriodEnd(moment(start).add(6, 'days'));
        }
    }, [isPlanIt, shipToAccounts]);

    useEffect(() => {
        if (products) {
            handleUpdateCampaigns(products);
        }
    }, [dispatch, products]);

    useEffect(() => {
        let errors = lineErrors;
        if (errors && errors.length > 0) {
            setHasLineErrors(true);
            setLineErrorElements(
                errors.map((error) => (
                    <Grid container item xs={12} className={classes.lineErrorGrid}>
                        <Alert severity="error" className={classes.warningIconRed}>
                            <Typography data-testid="line-error">
                                {getTranslatedErrorMessages(error)}
                            </Typography>
                        </Alert>
                    </Grid>
                ))
            );
        }
    }, [classes.lineErrorGrid, classes.warningIconRed, getTranslatedErrorMessages, lineErrors]);

    useEffect(() => {
        setHideForm(!isEdit);
    }, [isEdit]);

    useEffect(() => {
        if (file && !fileData) {
            setFileData(file);
            setIsValidFile(true);
        }
    }, [file, fileData, setIsValidFile]);

    useEffect(() => {
        if (weeks) {
            weeks.forEach((week, index) => {
                setValue(`weekDate[${index}].date`, week);
            });
        }
    }, [setValue, weeks]);

    return (
        <>
            <FormSection testId={'upload-section'} sectionHeader={headerText}>
                {isEdit && (
                    <Grid container item xs={12}>
                        <Typography className={classes.description}>
                            <Trans i18nKey="mustSaveMapping">
                                You must save your mapping above in order to upload a file.
                            </Trans>
                        </Typography>
                    </Grid>
                )}
                <Collapse in={hideForm} className={classes.collapse}>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>
                            <Trans i18nKey="acceptedMakeItBulkFiles">
                                Please upload your .csv file, making sure your values match with
                                your mapping.
                            </Trans>
                        </Typography>
                    </Grid>
                    {isPlanIt && (
                        <Grid item xs={12}>
                            <Typography className={classes.label}>
                                <Trans i18nKey="uponSubmitCancelMessage">
                                    Upon submitting, any products previously forecasted and not
                                    included again for the Forecast Week and Ship to will be
                                    canceled.
                                </Trans>
                            </Typography>
                        </Grid>
                    )}
                    {isPlanIt && (
                        <Grid item xs={12}>
                            <Typography className={classes.label}>
                                <Trans i18nKey="removeShipToLocationsP1">
                                    If you would like to remove full Ship To locations from a
                                    forecast week, please edit those records via
                                </Trans>
                                <Link
                                    component={RouterLink}
                                    underline="none"
                                    target="_blank"
                                    to="/plan-it-summary"
                                >
                                    <Trans i18nKey="planItSummary">Plan It Summary</Trans>
                                </Link>
                                <Trans i18nKey="removeShipToLocationsP2">
                                    for each ship to and week or include those in the file with a 0
                                    quantity.
                                </Trans>
                            </Typography>
                        </Grid>
                    )}
                    <Grid container item xs={12}>
                        <Typography className={classes.linksWrapper}>
                            <Trans i18nKey="needMoreInfo">Need more information?</Trans>
                        </Typography>
                    </Grid>
                    <Grid container item xs={12}>
                        <Typography className={classes.linksWrapper}>
                            <Link
                                to={`/assigned-ship-tos/${isPlanIt ? 'plan-it' : 'make-it'}`}
                                target="_blank"
                                component={RouterLink}
                                underline="none"
                            >
                                <Trans i18nKey="viewAssignedShipToLocations">
                                    View Your Assigned Ship To Locations
                                </Trans>
                            </Link>{' '}
                            <Trans i18nKey="or">or</Trans>{' '}
                            <Link
                                to={
                                    isPlanIt
                                        ? '/plan-it-bulk-data-validation'
                                        : '/make-it-bulk-data-validation'
                                }
                                target="_blank"
                                component={RouterLink}
                                underline="none"
                            >
                                <Trans i18nKey="viewDataValidationRules">
                                    View Data Validation Rules
                                </Trans>
                            </Link>
                            {campaignProducts && (
                                <>
                                    {' '}
                                    <Trans i18nKey="or">or</Trans>{' '}
                                    <Link
                                        to="/make-it-bulk-campaigns"
                                        target="_blank"
                                        component={RouterLink}
                                        underline="none"
                                    >
                                        <Trans i18nKey="viewCurrentUpcomingCampaigns">
                                            View Current & Upcoming Campaigns
                                        </Trans>
                                    </Link>
                                </>
                            )}
                            {isLargeCustomer && !isPlanIt && (
                                <>
                                    {' '}
                                    <Trans i18nKey="or">or</Trans>{' '}
                                    <Link
                                        to={`/product-information?from=make-it&selectedShipTo=${selectedAccountId}`}
                                        target="_blank"
                                        component={RouterLink}
                                        underline="none"
                                    >
                                        <Trans i18nKey="viewAtmProductForecast">
                                            {enUS.viewAtmProductForecast}
                                        </Trans>
                                    </Link>
                                </>
                            )}
                            {isPlanIt && (
                                <>
                                    {' '}
                                    <Trans i18nKey="or">or</Trans>{' '}
                                    <Link
                                        to={`/product-information?from=plan-it&selectedShipTo=${selectedAccountId}`}
                                        target="_blank"
                                        component={RouterLink}
                                        underline="none"
                                    >
                                        <Trans i18nKey="viewAtmProductForecast">
                                            {enUS.viewAtmProductForecast}
                                        </Trans>
                                    </Link>
                                </>
                            )}
                        </Typography>
                    </Grid>
                    {rowsOrColumns === RowsOrColumns.Columns &&
                        weekQuantities &&
                        weekQuantities.length > 0 &&
                        (isLargeCustomer || isPlanIt) && (
                            <Grid container item xs={12}>
                                <Grid item xs={12}>
                                    <Typography className={classes.weekHeader}>
                                        <Trans i18nKey="enterWeekDatesBelow">
                                            Please enter the dates of your weeks below:
                                        </Trans>
                                    </Typography>
                                </Grid>
                                <Grid container item xs={12} spacing={4} justify="flex-start">
                                    {weekQuantities.map((week, index) => {
                                        // if the name of this field is changed it must also be changed in the submit function in MakeItBulkUpload.tsx
                                        let weekNumber = index;
                                        ++weekNumber;
                                        const fieldName = `weekDate[${index}]`;
                                        return (
                                            <Grid item xs={3} key={index}>
                                                <FormControl
                                                    fullWidth
                                                    error={
                                                        errors.weekDate &&
                                                        errors.weekDate[index] &&
                                                        errors.weekDate[index].date
                                                    }
                                                    data-testid={`${fieldName}.date`}
                                                    className={classes.dateBox}
                                                >
                                                    <Controller
                                                        as={
                                                            <Input
                                                                fullWidth
                                                                onClick={() =>
                                                                    handleCalendarOpen(index)
                                                                }
                                                                disableUnderline={true}
                                                                disabled={true}
                                                                className={classes.calendarInput}
                                                                placeholder={t('weekNumberDate', {
                                                                    weekNumber: weekNumber
                                                                })}
                                                                aria-label={t('weekNumberDate', {
                                                                    weekNumber: weekNumber
                                                                })}
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            aria-label="calendar modal trigger"
                                                                            onClick={() =>
                                                                                handleCalendarOpen(
                                                                                    index
                                                                                )
                                                                            }
                                                                        >
                                                                            {calendarIcon}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }
                                                            />
                                                        }
                                                        error={
                                                            errors.weekDate &&
                                                            errors.weekDate[index] &&
                                                            errors.weekDate[index].date
                                                        }
                                                        errors={errors}
                                                        styles={clsx({
                                                            [classes.errorText]:
                                                                errors[`${fieldName}`] &&
                                                                errors[`${fieldName}`][index]?.date
                                                        })}
                                                        defaultValue={''}
                                                        control={control}
                                                        name={`${fieldName}.date`}
                                                        rules={{ required: isRequired }}
                                                    />
                                                    {errors.weekDate &&
                                                        errors.weekDate[index] &&
                                                        errors.weekDate[index].date && (
                                                            <FormHelperText>
                                                                <ErrorMessage
                                                                    errors={errors}
                                                                    name={`${fieldName}.date`}
                                                                />
                                                            </FormHelperText>
                                                        )}
                                                </FormControl>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        )}
                    <Grid item xs={12} className={classes.sectionMargin}>
                        <FileUpload
                            styles={uploadStyles}
                            fileTypes={acceptedFileTypes}
                            name="makeItOrderUpload"
                            testId="makeItOrder-upload"
                            enableDelete={true}
                            onUploadAttempt={storeFiles}
                            fileReset={false}
                            invalidMessage={t(
                                'invalidBulkFile',
                                'This file type is invalid. Please attach a .csv file.'
                            )}
                            currentFile={file}
                        />
                    </Grid>
                    {showUploadError && (
                        <Grid item xs={12} className={classes.error}>
                            <Typography>
                                <Trans i18nKey="invalidCsv">*Please upload a valid csv file</Trans>
                            </Typography>
                        </Grid>
                    )}
                    {hasLineErrors && (
                        <Grid>
                            <Grid container item xs={12}>
                                <Typography className={classes.lineErrorMessage}>
                                    <Trans i18nKey="fileErrors">File Errors</Trans>
                                </Typography>
                            </Grid>
                            <Grid container item xs={12} className={classes.lineErrorHelper}>
                                <Typography data-testid="correction-text">
                                    <Trans i18nKey="lineErrorHelper">
                                        Please correct the following errors and re-upload your file.
                                    </Trans>
                                </Typography>
                            </Grid>
                            {lineErrorElements}
                        </Grid>
                    )}
                </Collapse>
            </FormSection>
            <Modal
                open={calendarOpen}
                title={<Trans i18nKey="todayWithDate">Today: {{ today }}</Trans>}
                close={handleCalendarClose}
                closeIcon={true}
                maxWidth="xl"
                data-testid="atm-week-picker-modal"
            >
                <div className={classes.calendarDiv}>
                    <DaypickerRangeModal
                        startDate={openStartDate !== '' ? moment(openStartDate) : lockedPeriodStart}
                        endDate={openEndDate !== '' ? moment(openEndDate) : lockedPeriodEnd}
                        onCancel={handleCalendarClose}
                        onOk={handleCalendarOk}
                        isOutsideRange={isOutsideRange}
                        onDateChange={(startDate, endDate) =>
                            handleDateRangeChange(startDate, endDate)
                        }
                    />
                </div>
            </Modal>
        </>
    );
};

export default UploadMakeItOrder;
