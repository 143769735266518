import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import {
    Button,
    CircularProgress,
    Container,
    Grid,
    makeStyles,
    Typography,
    Link
} from '@material-ui/core';
import { Activity } from '../../../utility/auth/useSecurity';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { AuthState } from '../../../store/reducers/auth';
import { TemplateMapping } from '../../../store/reducers/bulk-upload';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { getShipToAccounts } from '../../../store/actions/customer-context';
import ConfirmationModal from '../../reusable/molecules/ConfirmationModal';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { RowsOrColumns } from '../../../utility/services/orders-service';
import { enUS } from '../../../utility/translations/locales';
import { BulkUploadFieldDefinition } from '../DeliveryBulkUpload/DeliveryBulkUpload';
import { FormContext, useForm } from 'react-hook-form';
import moment from 'moment';
import UploadMakeItOrder from '../MakeItBulkUpload/components/UploadMakeItOrder';
import {
    BulkUploadPlanItATMState,
    InactiveProduct,
    UnrecognizedProduct
} from '../../../store/reducers/planit-bulk-atm';
import {
    createPlanItBulkUploadATMTemplate,
    loadBulkUploadPlanItATM,
    loadPlanItBulkProducts,
    refreshPlanItBulkState,
    removeMakeItBulkOrder,
    resetPlanItBulkState,
    resetTemplateError,
    reviewPlanItBulkUploadATM,
    updatePlanItBulkUploadATMTemplate,
    uploadPlanItFile
} from '../../../store/actions/planit-bulk-atm';
import ATMBulkUploadMappingTemplate from '../../reusable/molecules/ATMBulkUploadMappingTemplate';
import { selectIsLargeCustomer } from '../../../store/selectors';
import { MakeItLineError } from '../../../store/reducers/makeit-bulk-upload';
import { FlagTypes, GetFeatureFlag } from '../../../utility/helpers/feature-flag';
import UnrecognizedProductsModal from './components/UnrecognizedProductsModal';
import InactiveProductsModal from './components/InactiveProductsModal';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '3em',
        display: 'flex',
        justifyContent: 'center'
    },
    linkContainer: {
        padding: '0 3em',
        display: 'flex',
        justifyContent: 'center'
    },
    topLink: {
        marginTop: '3em',
        justifyContent: 'right'
    },
    formSection: {
        marginBottom: '2.5em',
        flex: '1 1 auto'
    },
    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '0.5em'
    },
    actionBtn: {
        height: '3.375em',
        width: '13.75em',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom'
    },
    formSectionContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    link: {
        textDecoration: 'underline',
        marginLeft: '.25em'
    }
}));

export default function PlanItBulkUpload() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const { userInfo } = useTypedSelector<AuthState>((state) => state.auth);
    const {
        error,
        file,
        firstRowOfData,
        forecastLineErrors,
        forecastOrders,
        forecastTemplateMappings,
        loading,
        products,
        quantityType,
        rowsOrColumns,
        templateError,
        weekQuantities,
        weeks,
        unrecognizedProducts,
        inactiveProducts
    } = useTypedSelector<BulkUploadPlanItATMState>((state) => state.bulkUploadPlanIt);
    const { accounts, shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [templateData, setTemplateData] = useState<TemplateMapping[]>([]);
    const [isValidFile, setIsValidFile] = useState<boolean>(false);
    const [hasTemplate, setHasTemplate] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(hasTemplate);
    const [openCancel, setOpenCancel] = useState<boolean>(false);
    const [displayServerError, setDisplayServerError] = useState<boolean>(false);
    const [filteredForecastLineErrors, setFilteredForecastLineErrors] = useState<MakeItLineError[]>(
        []
    );
    const [openUnrecognizedProductsModal, setOpenUnrecognizedProductsModal] =
        useState<boolean>(false);
    const [openInactiveProductsModal, setOpenInactiveProductsModal] = useState<boolean>(false);
    const [initialFieldDefinitionsSet, setInitialFieldDefinitionsSet] = useState<boolean>(false);
    const [fieldDefinitions, setFieldDefinitions] = useState<BulkUploadFieldDefinition>();
    const isLargeCustomer = useTypedSelector<boolean>(selectIsLargeCustomer);
    const showProductAlertsPage = GetFeatureFlag(FlagTypes.ProductAlerts) ?? false;
    const [unrecognizedProductsForTable, setUnrecognizedProductsForTable] = useState<
        UnrecognizedProduct[]
    >([]);
    const [inactiveProductsForTable, setInactiveProductsForTable] = useState<InactiveProduct[]>([]);

    const methods = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur'
    });

    const onSubmit = () => {
        if (rowsOrColumns === RowsOrColumns.Columns) {
            methods.triggerValidation().then(() => {
                if (!methods.errors.weekDate) {
                    const weeks = extractDatesFromFormFields();
                    handleFileProcessingColumns(weeks);
                }
            });
        } else {
            handleFileProcessing();
        }
    };

    const handleUnrecognizedProductsClose = () => {
        setOpenUnrecognizedProductsModal(false);
    };

    const handleUnrecognizedProductsContinue = () => {
        setOpenUnrecognizedProductsModal(false);
        if (inactiveProducts && inactiveProducts.length > 0) {
            setInactiveProductsForTable(inactiveProducts);
            setOpenInactiveProductsModal(true);
        } else {
            history.push('/plan-it-bulk-upload-review');
        }
    };

    const handleInactiveProductsClose = () => {
        setOpenInactiveProductsModal(false);
    };

    const handleInactiveProductsContinue = () => {
        setOpenInactiveProductsModal(false);
        history.push('/plan-it-bulk-upload-review');
    };

    // very tightly coupled with how we pull in the form data but not sure how else to handle bc of limitations of react-hook-form
    const extractDatesFromFormFields = (): moment.Moment[] => {
        const formData = methods.getValues();
        const weeks: moment.Moment[] = [];
        let index = 0;
        while (true) {
            const currentWeek = formData[`weekDate[${index++}].date`];
            if (currentWeek) {
                weeks.push(currentWeek);
            } else {
                break;
            }
        }
        return weeks;
    };

    const handleFileProcessing = () => {
        if (file && userInfo) {
            dispatch(reviewPlanItBulkUploadATM(userInfo!.preferred_username, file!));
            setIsSubmitting(true);
        }
    };

    const handleFileProcessingColumns = (weeks: moment.Moment[]) => {
        if (file && userInfo) {
            dispatch(reviewPlanItBulkUploadATM(userInfo!.preferred_username, file!, weeks));
            setIsSubmitting(true);
        }
    };

    const onOpenCancelModal = () => {
        setOpenCancel(true);
    };

    const onCancel = () => {
        setOpenCancel(false);
        dispatch(resetPlanItBulkState());
        history.push('/');
    };

    const onClose = () => {
        setOpenCancel(false);
    };

    const setEditState = (edit: boolean) => {
        setIsEdit(edit);
    };

    const updateFieldDefinitions = (rowsOrColumns: RowsOrColumns) => {
        if (rowsOrColumns === RowsOrColumns.Columns) {
            setFieldDefinitions({
                shipToId: { defaultLabel: 'Ship To ID', defaultValue: '', required: true },
                productId: { defaultLabel: 'Product ID', defaultValue: '', required: true },
                ...(showProductAlertsPage && {
                    productName: {
                        defaultLabel: enUS.productName,
                        defaultValue: '',
                        required: false,
                        translationValue: 'productName'
                    }
                })
            });
        } else {
            setFieldDefinitions({
                shipToId: { defaultLabel: 'Ship To ID', defaultValue: '', required: true },
                productId: { defaultLabel: 'Product ID', defaultValue: '', required: true },
                requestedDate: {
                    defaultLabel: enUS.forecastWeek,
                    defaultValue: '',
                    required: true,
                    translationValue: 'forecastWeek'
                },
                quantity: {
                    defaultLabel: enUS.quantity,
                    defaultValue: '',
                    required: true,
                    translationValue: 'quantity'
                },
                ...(showProductAlertsPage && {
                    productName: {
                        defaultLabel: enUS.productName,
                        defaultValue: '',
                        required: false,
                        translationValue: 'productName'
                    }
                })
            });
        }
    };

    useEffect(() => {
        if (shipToAccounts && shipToAccounts.length) {
            if (!forecastTemplateMappings && userInfo) {
                dispatch(loadBulkUploadPlanItATM(userInfo.preferred_username));
            }
            if (forecastTemplateMappings) {
                setHasTemplate(forecastTemplateMappings.length > 0);
                setTemplateData(forecastTemplateMappings);
            }
        }
    }, [dispatch, userInfo, shipToAccounts, forecastTemplateMappings]);

    useEffect(() => {
        if (shipToAccounts && shipToAccounts.length > 0 && !initialFieldDefinitionsSet) {
            const defaultRowsOrColumns = rowsOrColumns ?? RowsOrColumns.Rows;
            updateFieldDefinitions(defaultRowsOrColumns);
            setInitialFieldDefinitionsSet(true);
        }
    }, [shipToAccounts, forecastTemplateMappings]);

    useEffect(() => {
        if (!products && shipToAccounts && shipToAccounts.length > 0 && !loading) {
            const shipToIds = shipToAccounts.map((account) => {
                return account.accountId;
            });
            dispatch(loadPlanItBulkProducts(shipToIds));
        }
    }, [products, shipToAccounts]);

    useEffect(() => {
        // omit invalid product ID errors; they are handled as unrecognized products
        const forecastErrors: MakeItLineError[] | undefined = forecastLineErrors?.filter(
            (err) => err.errorCode !== 'EP200003'
        );
        setFilteredForecastLineErrors(forecastErrors ?? []);
    }, [forecastLineErrors]);

    useEffect(() => {
        if (
            forecastOrders &&
            (!inactiveProducts || inactiveProducts.length === 0) &&
            (!unrecognizedProducts || unrecognizedProducts.length === 0) &&
            (!forecastLineErrors || forecastLineErrors.length === 0) &&
            isSubmitting
        ) {
            history.push('/plan-it-bulk-upload-review');
        }
        setIsSubmitting(false);
    }, [forecastOrders, forecastLineErrors, inactiveProducts, unrecognizedProducts]);

    useEffect(() => {
        if (permissions && !accounts?.length) {
            dispatch(getShipToAccounts());
        }
    }, [dispatch, accounts, permissions]);

    useEffect(() => {
        if (error) {
            setIsSubmitting(false);
            setDisplayServerError(true);
        } else {
            setDisplayServerError(false);
        }
    }, [error]);

    useEffect(() => {
        if (
            showProductAlertsPage &&
            ((unrecognizedProducts && unrecognizedProducts.length > 0) ||
                (inactiveProducts && inactiveProducts.length > 0))
        ) {
            history.push('/product-alerts');
        } else {
            if (unrecognizedProducts && unrecognizedProducts.length > 0) {
                setUnrecognizedProductsForTable(unrecognizedProducts);
                setOpenUnrecognizedProductsModal(true);
            } else if (inactiveProducts && inactiveProducts.length > 0) {
                setInactiveProductsForTable(inactiveProducts);
                setOpenInactiveProductsModal(true);
            }
        }
    }, [unrecognizedProducts, inactiveProducts, showProductAlertsPage]);

    //always remove orders in state on first load, if they exist
    useEffect(() => {
        dispatch(refreshPlanItBulkState());

        if (forecastOrders && forecastOrders.length) {
            dispatch(removeMakeItBulkOrder());
        }
    }, []);

    const planItGuidedLink = (
        <>
            <Container className={classes.linkContainer}>
                <Grid container item xs={8} className={classes.topLink}>
                    <Typography>
                        <Trans i18nKey="planItGuidedPrompt">
                            Do you want to submit a Plan It without a file upload?
                        </Trans>
                        <Link
                            to={'/plan-it'}
                            component={RouterLink}
                            underline="none"
                            data-testid="plan-it-guided-link"
                            className={classes.link}
                        >
                            <Trans i18nKey="planItGuided">Plan It Guided</Trans>
                        </Link>
                    </Typography>
                </Grid>
            </Container>
        </>
    );

    const footerActions = (
        <>
            <Grid item xs={2}>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="bottom-cancel-button"
                    onClick={onOpenCancelModal}
                    className={classes.actionBtn}
                >
                    <Trans i18nKey="cancel">Cancel</Trans>
                </Button>
            </Grid>
            <Grid item xs={2}>
                <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    data-testid="upload-button"
                    className={classes.actionBtn}
                    onClick={onSubmit}
                    disabled={!hasTemplate || !isValidFile || isSubmitting || !products}
                >
                    <Trans i18nKey="uploadFile">Upload File</Trans>
                </Button>
            </Grid>
        </>
    );

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('planIt', enUS.planIt),
                description: t('forecastBulkUpload', enUS.forecastBulkUpload),
                thinBanner: true,
                displayDropdown: false,
                shouldShowATMBanner: isLargeCustomer
            }}
            actionFooter={{
                footerAction: footerActions,
                justify: 'space-between',
                sticky: true
            }}
            activity={Activity.PlanItBulkUpload}
            shipTos={false}
            loading={loading}
        >
            <Grid container justify="space-between" data-testid="make-it-bulk-upload-page">
                {planItGuidedLink}
                <Container className={classes.container}>
                    <Grid container item xs={8} className={classes.formSectionContainer}>
                        <Grid
                            item
                            xs={12}
                            className={classes.formSection}
                            data-testid="make-it-mapping-template"
                        >
                            <Grid item xs={12} className={classes.formSection}>
                                {fieldDefinitions && (
                                    <ATMBulkUploadMappingTemplate
                                        hasTemplate={hasTemplate}
                                        templateData={templateData}
                                        fieldDefinitions={fieldDefinitions}
                                        isEdit={isEdit}
                                        setIsEdit={setEditState}
                                        updateFieldDefinitions={updateFieldDefinitions}
                                        onSubmitCreateMapping={createPlanItBulkUploadATMTemplate}
                                        onSubmitUpdateMapping={updatePlanItBulkUploadATMTemplate}
                                        createMappingText={t(
                                            'forecastCreateMappingText',
                                            enUS.forecastCreateMappingText
                                        )}
                                        updateMappingText={t(
                                            'forecastUpdateMappingText',
                                            enUS.forecastUpdateMappingText
                                        )}
                                        quantityType={quantityType}
                                        rowsOrColumns={rowsOrColumns}
                                        weekQuantities={weekQuantities}
                                        templateError={templateError}
                                        firstRowOfData={firstRowOfData}
                                        onResetTemplateErrors={resetTemplateError}
                                        isPlanItBulk={true && showProductAlertsPage}
                                    />
                                )}
                            </Grid>
                        </Grid>
                        <FormContext {...methods}>
                            <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
                                <Grid data-testid="make-it-file-upload">
                                    <Grid>
                                        <UploadMakeItOrder
                                            isEdit={isEdit}
                                            setIsValidFile={setIsValidFile}
                                            headerText={t(
                                                'forecastUploadSectionHeader',
                                                enUS.forecastUploadSectionHeader
                                            )}
                                            onFileLoad={uploadPlanItFile}
                                            file={file}
                                            lineErrors={filteredForecastLineErrors}
                                            products={products}
                                            rowsOrColumns={rowsOrColumns}
                                            weekQuantities={weekQuantities}
                                            weeks={weeks}
                                            isPlanIt={true}
                                        />
                                    </Grid>
                                </Grid>
                            </form>
                        </FormContext>
                    </Grid>
                </Container>
                {displayServerError && (
                    <Grid container item xs={12} justify="center">
                        <Typography>
                            <Trans i18nKey="pleaseReviewCsvData">
                                There has been an error with processing the CSV data. Please double
                                check the column types are set correctly.
                            </Trans>
                        </Typography>
                    </Grid>
                )}
                {isSubmitting ||
                    (isValidFile && !products && (
                        <Grid container item xs={12} className={classes.spinningLoader}>
                            <CircularProgress />
                        </Grid>
                    ))}
            </Grid>
            <ConfirmationModal
                saveProgress={onCancel}
                onClose={onClose}
                logo={false}
                title={<Trans i18nKey="cancelSubmission">Cancel Submissions</Trans>}
                subheader={<Trans i18nKey="confirmCancel">Are you sure you want to cancel?</Trans>}
                description={
                    <Trans i18nKey="lostFile">
                        Any file uploaded or unsaved mappings will be permanently gone.
                    </Trans>
                }
                continueText={<Trans i18nKey="yesCancel">Yes, Cancel</Trans>}
                cancelText={<Trans i18nKey="no">No</Trans>}
                open={openCancel}
                navigationLink=""
                onCancel={onClose}
            />
            <UnrecognizedProductsModal
                open={openUnrecognizedProductsModal}
                onClose={handleUnrecognizedProductsClose}
                onContinue={handleUnrecognizedProductsContinue}
                lines={unrecognizedProductsForTable}
            />
            <InactiveProductsModal
                open={openInactiveProductsModal}
                onClose={handleInactiveProductsClose}
                onContinue={handleInactiveProductsContinue}
                lines={inactiveProductsForTable}
            />
        </ProcessingPageTemplate>
    );
}
