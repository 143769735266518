import * as types from './action-types';
import EndService, { EndSearchResponse } from '../../utility/services/end-service';

export function loadEndDetailsPageContent(region: string, language: string, queryParams: string[]) {
    return (dispatch, getState) => {
        dispatch({
            type: types.PRODUCT_END_DETAILS_LOADING
        });

        const bannerUrl = `${process.env.REACT_APP_CONTENT_API}/content/banners/end-banner`;
        let endResponse: EndSearchResponse;

        //fetch banner
        const banner = EndService.getBanner(getState, bannerUrl);
        banner.then((response) => {
            dispatch({
                banner: response.data?.section,
                type: types.PRODUCT_END_LOADED_BANNER
            });
        });

        //fetch end details
        const endDetailsContent = EndService.getEnds(getState);

        endDetailsContent
            .then((end) => {
                endResponse = end?.data;
                return EndService.getCompatibleProducts(endResponse, getState);
            })
            .then((compatibleResponse) => {
                const products = compatibleResponse?.data?.products;
                const endsArray = endResponse?.ends;
                return EndService.assignCompatibleProducts(endsArray, products);
            })
            .then((endsArray: any) => {
                if (queryParams.length > 0) {
                    return EndService.sortEndsArray(endsArray, queryParams);
                } else {
                    return endsArray;
                }
            })
            .then((endsArray) => {
                dispatch({
                    type: types.PRODUCT_END_LOADED_CONTENT,
                    endContent: endsArray
                });
            });

        // can add additional promises for other sections of Product End Details here
        const promises = [banner, endDetailsContent];

        //when everything is loaded or an error occurs...
        return Promise.all(promises)
            .then(() => {
                dispatch({
                    type: types.PRODUCT_END_DETAILS_LOADED
                });
            })
            .catch(() => {
                dispatch({
                    type: types.PRODUCT_END_DETAILS_ERROR,
                    error: 'Unable to load Product End Details Content'
                });
            });
    };
}
