import Axios from 'axios';
import { createAPIQueryParam } from '../helpers/query-helpers';
import {
    Communication,
    DisplayPage,
    MessageType,
    RecipientType,
    Region
} from '../../store/reducers/communication-management';
import { Moment } from 'moment';

export interface CommunicationSummaryRequest {
    maxEndDateWeeks?: number;
}

export interface CommunicationResponse {
    communications: Communication[];
}

export interface CreateCommunicationRequest {
    title: string;
    content: string;
    startDateTime: Moment;
    endDateTime: Moment;
    recipientType: RecipientType;
    submitterName: string;
    submitterEmail: string;
    status: boolean;
    communicationRegions: Region[];
    communicationDisplayPages: DisplayPage[];
    communicationMessageTypes: MessageType[];
}

export interface UpdateCommunicationRequest {
    communicationId: number;
    title?: string;
    content?: string;
    startDateTime?: Moment;
    endDateTime?: Moment;
    recipientType?: RecipientType;
    submitterName?: string;
    submitterEmail?: string;
    status?: boolean;
    communicationRegions?: Region[];
    communicationDisplayPages?: DisplayPage[];
    communicationMessageTypes?: MessageType[];
}

export default class CommunicationService {
    private static createConfig = (accessToken: string) => ({
        headers: { Authorization: `Bearer ${accessToken}` }
    });

    static getCommunicationSummaryData = (
        accessToken: string,
        summaryRequest: CommunicationSummaryRequest
    ) => {
        const requestUrl = `${
            process.env.REACT_APP_MESSAGE_API
        }/communication/summary${createAPIQueryParam(summaryRequest)}`;
        return Axios.get<CommunicationResponse>(
            requestUrl,
            CommunicationService.createConfig(accessToken)
        );
    };

    static getCommunicationMessageData = (accessToken: string) => {
        const requestUrl = `${process.env.REACT_APP_MESSAGE_API}/communication/messages`;
        return Axios.get<CommunicationResponse>(
            requestUrl,
            CommunicationService.createConfig(accessToken)
        );
    };

    static createCommunicationMessage = (
        accessToken: string,
        request: CreateCommunicationRequest
    ) => {
        const requestUrl = `${process.env.REACT_APP_MESSAGE_API}/communication/message`;
        return Axios.post<any>(requestUrl, request, CommunicationService.createConfig(accessToken));
    };

    static updateCommunicationMessage = (
        accessToken: string,
        request: UpdateCommunicationRequest
    ) => {
        const requestUrl = `${process.env.REACT_APP_MESSAGE_API}/communication/message`;
        return Axios.patch<any>(
            requestUrl,
            request,
            CommunicationService.createConfig(accessToken)
        );
    };
}
