import React from 'react';
import Modal from '../../../reusable/molecules/Modal';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import Button from '../../../reusable/atoms/Button';
import { Trans, useTranslation } from 'react-i18next';
import { boldWeight, ballLtGray_2, xl } from '../../../../themes/globalConstants';

interface Props {
    open: boolean;
    onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
    confirmationText: {
        fontWeight: boldWeight,
        color: theme.palette.info.dark,
        fontSize: xl
    },

    grayBox: {
        backgroundColor: ballLtGray_2,
        width: '19.21em',
        height: '14.93em',
        margin: '2em',
        paddingTop: '2em'
    },

    imageGrid: {
        height: '.1em'
    },

    textGrid: {
        height: '1em'
    },

    buttonGrid: {
        paddingBottom: '5.4em',
        height: '4em',
        width: '20.21em',
        margin: 'auto'
    },

    successIcon: {
        height: '3.57em',
        width: '3.57em',
        margin: '0.36em'
    },

    modalButton: {
        width: '14.03em',
        height: '3.3em'
    }
}));

const PaymentSubmittedConfirmationModal = ({ open, onClose }: Props) => {
    const classes = useStyles();

    const onCancel = () => {
        onClose();
    };

    const { t } = useTranslation();

    return (
        <Modal
            open={open}
            close={onClose}
            title={t('thankYou', 'Thank You')}
            maxWidth="md"
            closeIcon={true}
            fullWidth={true}
        >
            <Grid container>
                <Grid container item justify="center" className={classes.grayBox} xs={12}>
                    <Grid container item justify="center" className={classes.imageGrid}>
                        <Typography data-testid="confirmation-icon">
                            <img
                                className={classes.successIcon}
                                src={process.env.PUBLIC_URL + '/assets/Confirmation_icon.svg'}
                                alt="Confirmation Icon"
                            />
                        </Typography>
                    </Grid>
                    <Grid container item justify="center" xs={7} className={classes.textGrid}>
                        <Typography
                            data-testid="payment-submitted-message"
                            align="center"
                            className={classes.confirmationText}
                        >
                            <Trans i18nKey="paymentSubmittedMessage">
                                Thank you for submitting your payment. We will process your payment
                                shortly.
                            </Trans>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className={classes.buttonGrid}>
                    <Grid container item justify="center" xs={12}>
                        <Button
                            data-testid="close-button"
                            variant="contained"
                            color="primary"
                            onClick={onCancel}
                            className={classes.modalButton}
                        >
                            <Trans i18nKey="close">Close</Trans>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default PaymentSubmittedConfirmationModal;
