import React, { useEffect, useState } from 'react';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import { Grid, makeStyles } from '@material-ui/core';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import {
    boldWeight,
    medium,
    blackWeight,
    ballBlue,
    dateOptionInvalidGray
} from '../../../../themes/globalConstants';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { useTranslation } from 'react-i18next';
import { generateKey } from '../../../../utility/helpers/order-helpers';
import OpenLiquidTestColumns from './OpenLiquidTestColumns';
import { LiquidTestSummaryState } from '../../../../store/reducers/liquid-test-summary';
import OpenLiquidTestRow from './OpenLiquidTestRow';
import { LiquidIntakeForTable } from '../models/LiquidIntakeForTable';
import moment from 'moment';
import { getProjectNameBeverageName } from '../../../../utility/helpers/liquid-intake-helpers';

const useStyles = makeStyles((theme) => ({
    action: {
        cursor: 'pointer'
    },
    name: {
        fontWeight: boldWeight,
        fontSize: medium,
        letterSpacing: 0.17
    },
    tableWrapper: {
        '& .MuiToolbar-root': {
            padding: 0,
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                },
                '&:first-of-type ': {
                    paddingLeft: 0
                }
            },
            '& > h2': {
                fontSize: '1.5em',
                fontWeight: boldWeight,
                color: theme.palette.secondary.main,
                paddingLeft: '0.625em'
            },
            '& .MuiInputBase-formControl': {
                border: `1px solid ${dateOptionInvalidGray}`,
                borderRadius: '.125em',
                '&:before': {
                    display: 'none'
                },
                '&:after': {
                    display: 'none'
                },
                '& svg': {
                    height: '.875em',
                    width: '.875em',
                    fill: ballBlue
                },
                '& .MuiIconButton-root': {
                    '&:hover': {
                        backgroundColor: 'transparent !important'
                    }
                },
                '& .MuiInput-input': {
                    paddingLeft: '.25em'
                }
            }
        },
        '& .MuiTableSortLabel-root': {
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em',
            width: '99%',
            justifyContent: 'space-between',
            '& .MuiSvgIcon-root': {
                margin: 0
            }
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableCell-head': {
            '&:first-of-type ': {
                width: '25em'
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                '&:nth-child(1)': {
                    width: '24%'
                },
                '&:nth-child(2)': {
                    width: '8%'
                },
                '&:nth-child(3)': {
                    width: '17%'
                },
                '&:nth-child(4)': {
                    width: '14%'
                },
                '&:nth-child(5)': {
                    width: '10%'
                },
                '&:nth-child(6)': {
                    width: '10%'
                },
                '&:nth-child(7)': {
                    width: '17%'
                }
            }
        }
    }
}));

const LiquidTestTab = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const options = new ToolbarOptions({
        title: t('openLiquidTests', 'Open Liquid Tests'),
        advancePagination: false,
        exportButton: false,
        printButton: false
    });
    const { openLiquidTests } = useTypedSelector<LiquidTestSummaryState>(
        (state) => state.liquidTestSummary
    );
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const [columns, setColumns] = useState<any>([]);
    const [openLiquidIntakesForTable, setOpenLiquidIntakesForTable] = useState<
        LiquidIntakeForTable[] | undefined
    >(undefined);

    useEffect(() => {
        setColumns(OpenLiquidTestColumns.getOpenLiquidTestColumns(cultureCode));
    }, [cultureCode]);

    useEffect(() => {
        if (openLiquidTests) {
            const openIntakes: LiquidIntakeForTable[] = [];
            openLiquidTests.sort((a, b) => moment(b.createDate).diff(a.createDate));
            openLiquidTests.forEach((intake, index) => {
                let openIntake: LiquidIntakeForTable = {
                    ...intake,
                    projectNameBeverageName: getProjectNameBeverageName(
                        intake.projectName,
                        intake.beverageName
                    ),
                    sortIndex: index
                };
                openIntakes.push(openIntake);
            });
            setOpenLiquidIntakesForTable(openIntakes);
        }
    }, [openLiquidTests]);

    const OpenRowComponent = ({ row }: { row: Partial<LiquidIntakeForTable> }) => {
        if (row) {
            return (
                <OpenLiquidTestRow
                    liquidTest={row as LiquidIntakeForTable}
                    key={row.liquidTestId}
                />
            );
        } else {
            return null;
        }
    };

    return (
        <Grid className={classes.tableWrapper} key={generateKey('liquid-tests-tab')}>
            {openLiquidIntakesForTable && (
                <DataGrid
                    gridName="Open Liquid Tests"
                    columns={columns}
                    dataSource={openLiquidIntakesForTable}
                    rowComponent={OpenRowComponent}
                    toolbarOptions={options}
                ></DataGrid>
            )}
        </Grid>
    );
};

export default LiquidTestTab;
