import React, { useState } from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import Link from '../../../reusable/atoms/Link';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import { ballBlue, medium, regularWeight } from '../../../../themes/globalConstants';
import Modal from '../../../reusable/molecules/Modal';
import ReactPdf from '@react-pdf/renderer';
import MakeItBulkUploadConfirmationPDF from './MakeItBulkUploadConfirmationPDF';
import { MakeItBulkOrder } from '../../../../store/reducers/makeit-bulk-upload';
import { CSVLink } from 'react-csv';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { getGraphicIdAndVersion } from '../../../../utility/helpers/order-helpers';
import { formatUTCToDateDisplay } from '../../../../utility/helpers/date-helpers';
import moment from 'moment';

interface Props {
    orders: MakeItBulkOrder[];
}

const useStyles = makeStyles((theme) => ({
    label: {
        textTransform: 'uppercase',
        fontSize: medium,
        paddingTop: '0.5em'
    },
    link: {
        fontSize: medium,
        fontWeight: regularWeight,
        width: '8.75em',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    icon: {
        color: ballBlue
    }
}));

const pdfStyles = ReactPdf.StyleSheet.create({
    link: {
        fontSize: medium,
        fontWeight: regularWeight,
        width: '8.75em'
    }
});

const MakeItBulkUploadConfirmationShareLinks = ({ orders }: Props) => {
    const classes = useStyles();
    const [openPdf, setOpenPdf] = useState(false);
    const { t } = useTranslation();
    const createDate = moment().format('MM/DD/YYYY-hh.mm.ss');

    const printForm = () => {
        setOpenPdf(true);
    };

    const onClose = () => {
        setOpenPdf(false);
    };

    interface CSVRow {
        customerPoNumber?: string;
        shipToId: string;
        productId?: string;
        graphicID?: string;
        productDescription: string;
        characteristics: string;
        coating?: string;
        requestedDate?: string;
        quantityPL?: number;
        quantityEA?: number;
    }

    const csvHeaders = [
        { label: 'CUSTOMER PO #', key: 'customerPoNumber' },
        { label: 'SHIP TO ID', key: 'shipToId' },
        { label: 'PRODUCT ID', key: 'productId' },
        { label: 'GRAPHIC ID', key: 'graphicID' },
        { label: 'PRODUCT DESCRIPTION', key: 'productDescription' },
        { label: 'CHARACTERISTICS', key: 'characteristics' },
        { label: 'COATING', key: 'coating' },
        { label: 'REQUESTED DATE', key: 'requestedDate' },
        { label: 'Quantity (PL)', key: 'quantityPL' },
        { label: 'Quantity (EA)', key: 'quantityEA' }
    ];

    const translateCSVHeaders = () => {
        csvHeaders.forEach((header) => {
            header.label = t(header.key, header.label).toUpperCase();
        });
        return csvHeaders;
    };

    const csvArray = () => {
        let csvRows: CSVRow[] = [];
        orders.forEach((order) => {
            order.lines.forEach((line) => {
                const row: CSVRow = {
                    customerPoNumber: order.customerProductionOrderId,
                    shipToId: order.shipToId.toString(),
                    productId: line.displayId,
                    graphicID: getGraphicIdAndVersion(line.type, line.graphicIdAndVersion),
                    productDescription: line.displayName ? line.displayName : '',
                    characteristics: line.description ? line.description : '',
                    coating: line.productCoating,
                    requestedDate: formatUTCToDateDisplay(line.requestedDate),
                    quantityPL: line.palletQuantity ?? undefined,
                    quantityEA:
                        line.palletQuantity && line.quantityPerPallet
                            ? line.palletQuantity * line.quantityPerPallet
                            : undefined
                };
                csvRows.push(row);
            });
        });
        return csvRows;
    };

    const csvContents = csvArray();

    return (
        <Grid container item xs={12} spacing={2} alignItems="center" justify="flex-end">
            <Grid item>
                <Typography variant="h6" className={classes.label}>
                    <Trans i18nKey="share">share</Trans>
                </Typography>
            </Grid>
            <Grid item>
                <Link
                    className={classes.link}
                    underline="always"
                    data-testid="print-btn"
                    onClick={printForm}
                >
                    <Trans i18nKey="print">Print</Trans>
                </Link>
                <PrintOutlinedIcon fontSize="small" className={classes.icon} />
            </Grid>
            <Grid item>
                <CSVLink
                    className={
                        'MuiTypography-root MuiLink-root MuiLink-underlineAlways MuiTypography-colorPrimary'
                    }
                    style={pdfStyles.link}
                    underline="always"
                    data-testid="download-btn"
                    headers={translateCSVHeaders()}
                    data={csvContents}
                    filename={`Make It Order_${createDate}.csv`}
                >
                    <Trans i18nKey="downloadCSV">Download .CSV</Trans>
                </CSVLink>
                <GetAppOutlinedIcon fontSize="small" className={classes.icon} />
            </Grid>
            <Modal
                open={openPdf}
                title={t('makeIt', 'Make It')}
                close={onClose}
                closeIcon={true}
                fullScreen={true}
            >
                <MakeItBulkUploadConfirmationPDF title={t('makeIt', 'Make It')} orders={orders} />
            </Modal>
        </Grid>
    );
};

export default MakeItBulkUploadConfirmationShareLinks;
