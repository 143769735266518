import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class PlanItColumns {
    static getPlanItColumns = (
        culture: string,
        sortable = true,
        type: 'GUIDED' | 'CONFIG' | 'CONFIRMATION'
    ) => {
        return [
            createColumn('sortIndex', {
                searchable: false,
                sortable: false,
                filterable: false,
                visible: false,
                dataType: ColumnDataType.Numeric
            }),
            createColumn('productSku', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productId', {
                dataType: ColumnDataType.Numeric,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('displayId', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'customerProductGraphicId'),
                filterable: true,
                sortable: sortable,
                searchable: true
            }),
            createColumn('graphicIdAndVersion', {
                dataType: ColumnDataType.String,
                filterable: true,
                sortable: sortable,
                searchable: true,
                visible: false
            }),
            createColumn('graphicId', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('graphicVersion', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('displayName', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'productDescription'),
                filterable: true,
                sortable: sortable,
                searchable: false
            }),
            createColumn('description', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'productAttributes'),
                filterable: false,
                sortable: sortable,
                searchable: false,
                visible: true
            }),
            createColumn('name', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('size', {
                dataType: ColumnDataType.Numeric,
                filterable: true,
                sortable: sortable,
                searchable: false,
                visible: false
            }),
            createColumn('unit', {
                dataType: ColumnDataType.String,
                filterable: true,
                sortable: sortable,
                searchable: false,
                visible: false
            }),
            createColumn('shape', {
                dataType: ColumnDataType.String,
                filterable: true,
                sortable: sortable,
                searchable: false,
                visible: false
            }),
            createColumn('neckDiameter', {
                dataType: ColumnDataType.String,
                filterable: true,
                sortable: sortable,
                searchable: false,
                visible: false
            }),
            createColumn('endProfile', {
                dataType: ColumnDataType.String,
                filterable: true,
                sortable: sortable,
                searchable: false,
                visible: false
            }),
            createColumn('tabColor', {
                dataType: ColumnDataType.String,
                filterable: true,
                sortable: sortable,
                searchable: false,
                visible: false
            }),
            createColumn('type', {
                dataType: ColumnDataType.String,
                filterable: true,
                sortable: sortable,
                searchable: false,
                visible: false
            }),
            createColumn('status', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'status'),
                filterable: true,
                sortable: sortable,
                searchable: false,
                visible: type === 'CONFIG'
            }),
            createColumn('coating', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'coating'),
                filterable: true,
                sortable: sortable,
                searchable: false
            }),
            createColumn('moq', {
                dataType: ColumnDataType.String,
                label:
                    TranslationService.getTranslatedText(culture, 'makeItMoq') ??
                    'Make It Minimum Order Quantity',
                filterable: true,
                sortable: sortable,
                searchable: false,
                visible: type === 'CONFIG'
            }),
            createColumn('status', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'status'),
                filterable: true,
                sortable: sortable,
                searchable: false,
                visible: type !== 'CONFIG'
            }),
            createColumn('productCoating', {
                dataType: ColumnDataType.String,
                filterable: false,
                sortable: sortable,
                searchable: false,
                visible: false
            }),
            createColumn('requestedDate', {
                dataType: ColumnDataType.DateTime,
                label: TranslationService.getTranslatedText(culture, 'forecastWeek'),
                filterable: true,
                sortable: sortable,
                searchable: false,
                visible: type === 'CONFIRMATION'
            }),
            createColumn('quantityPerPallet', {
                dataType: ColumnDataType.Numeric,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('minimumOrderQuantity', {
                dataType: ColumnDataType.Numeric,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('purchaseQty', {
                dataType: ColumnDataType.Numeric,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('leadTimeWeeks', {
                dataType: ColumnDataType.Numeric,
                label: ' ',
                filterable: true,
                sortable: sortable,
                searchable: false,
                visible: false
            }),
            createColumn('minimumMet', {
                dataType: ColumnDataType.Boolean,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('quantity', {
                dataType: ColumnDataType.Numeric,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('originalPalletQuantity', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'previousForecastedQuantity'),
                filterable: false,
                sortable: sortable,
                searchable: true,
                visible: type === 'CONFIG'
            }),
            createColumn('originalEachesQuantity', {
                dataType: ColumnDataType.Numeric,
                filterable: false,
                sortable: false,
                searchable: true,
                visible: false
            }),
            createColumn('palletQuantity', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'revisedForecastedQuantity'),
                filterable: false,
                sortable: sortable,
                searchable: false,
                visible: type !== 'GUIDED'
            }),
            createColumn('eachesQuantity', {
                dataType: ColumnDataType.Numeric,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('withinRange', {
                dataType: ColumnDataType.Boolean,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('includedInExistingForecast', {
                dataType: ColumnDataType.Boolean,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('subRows', {
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('childLines', {
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('addDisabled', {
                dataType: ColumnDataType.Boolean,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('customerProductId', {
                dataType: ColumnDataType.String,
                filterable: false,
                sortable: sortable,
                searchable: true,
                visible: false
            }),
            createColumn('customerProductName', {
                dataType: ColumnDataType.String,
                filterable: false,
                sortable: sortable,
                searchable: true,
                visible: false
            }),
            createColumn('firstAvailableDate', {
                dataType: ColumnDataType.String,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('customerProductionOrderId', {
                dataType: ColumnDataType.String,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('shipToId', {
                dataType: ColumnDataType.Numeric,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('isCustomerProductIdDistinct', {
                dataType: ColumnDataType.Boolean,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('candidateProductSkus', {
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('modified', {
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('palletsRounded', {
                visible: false
            }),
            createColumn('campaignTiming', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('campaignRuns', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('moqFees', {
                visible: false
            }),
            createColumn('retort', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('specialtyInk', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('basecoat', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('shellColor', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('shellGauge', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('campaignTiming', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('outsideCampaign', {
                dataType: ColumnDataType.Boolean,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
        ];
    };
}
