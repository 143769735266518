import React from 'react';
import Modal from '../../../reusable/molecules/Modal';
import {
    AppBar,
    Grid,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography
} from '@material-ui/core';
import Button from '../../../reusable/atoms/Button';
import { Trans, useTranslation } from 'react-i18next';
import { ballBlue, boldWeight, regularWeight, white } from '../../../../themes/globalConstants';
import { InactiveProduct } from '../../../../store/reducers/planit-bulk-atm';

interface Props {
    open: boolean;
    onClose: () => void;
    onContinue: () => void;
    lines: InactiveProduct[];
}

const useStyles = makeStyles((theme) => ({
    modalBtn: {
        height: '3.3em',
        width: '10em',
        marginRight: '3em'
    },
    modalContainer: {
        width: '50em',
        marginLeft: '2em',
        marginTop: '2em'
    },
    appBar: {
        top: 'auto',
        bottom: 0,
        width: '100%'
    },
    toolBar: {
        backgroundColor: white
    },
    modalText: {
        marginBottom: '1em',
        marginRight: '2em'
    },
    tableContainer: {
        marginBottom: '2em',
        height: '15em',
        overflowY: 'scroll',
        overflowX: 'hidden'
    },
    linkSpacer: {
        marginRight: '.25em'
    },
    linkText: {
        textDecoration: `underline ${ballBlue}`
    },
    productTable: {
        width: '90%'
    },
    tableText: {
        fontWeight: regularWeight
    },
    tableHeader: {
        fontWeight: boldWeight
    }
}));

const InactiveProductsModal = ({ open, onClose, onContinue, lines }: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const modalTitle = t('inactiveProductsIdentified', 'Inactive Products Identified');

    return (
        <Modal open={open} title={modalTitle} close={onClose} closeIcon={true} maxWidth="xl">
            <Grid container className={classes.modalContainer}>
                <Grid item>
                    <Typography variant="body1" className={classes.modalText}>
                        <Trans i18nKey="identifiedTheFollowingInactiveProducts">
                            We have identified the following inactive products included in your file
                        </Trans>
                        {':'}
                    </Typography>
                </Grid>
                <TableContainer className={classes.tableContainer}>
                    <Table
                        size="medium"
                        aria-label="inactive products table"
                        className={classes.productTable}
                        data-testid="inactive-products-table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    className={classes.tableHeader}
                                    data-testid="line-number-column"
                                >
                                    <Trans i18nKey="csvLineNumber">Line Number</Trans>
                                </TableCell>
                                <TableCell
                                    className={classes.tableHeader}
                                    data-testid="shipto-id-column"
                                >
                                    <Trans i18nKey="shipTo">Ship To</Trans>
                                </TableCell>
                                <TableCell
                                    className={classes.tableHeader}
                                    data-testid="product-id-column"
                                >
                                    <Trans i18nKey="productId">Product ID</Trans>
                                </TableCell>
                                <TableCell
                                    className={classes.tableHeader}
                                    data-testid="product-description-column"
                                >
                                    <Trans i18nKey="productDescription">Product Description</Trans>
                                </TableCell>
                                <TableCell
                                    className={classes.tableHeader}
                                    data-testid="forecast-week-column"
                                >
                                    <Trans i18nKey="forecastWeek">Forecast Week</Trans>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {lines
                                .sort((a, b) => a.lineNumber - b.lineNumber)
                                .map((line, i) => {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell className={classes.tableText}>
                                                {line.lineNumber}
                                            </TableCell>
                                            <TableCell className={classes.tableText}>
                                                {line.shipToId}
                                            </TableCell>
                                            <TableCell className={classes.tableText}>
                                                {line.productId}
                                            </TableCell>
                                            <TableCell className={classes.tableText}>
                                                {line.productDescription}
                                            </TableCell>
                                            <TableCell className={classes.tableText}>
                                                {line.forecastWeek}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Grid item>
                    <Typography variant="body1" className={classes.modalText}>
                        <Trans i18nKey="inactiveProductsMustBeActivated">
                            Inactive Products must be activated prior to creating a Make it. Please
                            reach out to your Ball contact to discuss how to activate products.
                        </Trans>
                    </Typography>
                </Grid>
            </Grid>
            <AppBar className={classes.appBar} position="sticky" elevation={10}>
                <Toolbar className={classes.toolBar} variant="regular">
                    <Grid container item xs={12} justify="flex-end">
                        <Grid item xs={3}>
                            <Button
                                type="button"
                                variant="outlined"
                                color="secondary"
                                className={classes.modalBtn}
                                onClick={onClose}
                                data-testid="cancel-btn"
                            >
                                <Trans i18nKey="cancel">Cancel</Trans>
                            </Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                className={classes.modalBtn}
                                onClick={onContinue}
                                data-testid="continue-btn"
                            >
                                <Trans i18nKey="continue">Continue</Trans>
                            </Button>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </Modal>
    );
};

export default InactiveProductsModal;
