import React, { createRef, ReactNode } from 'react';
import { ContentSection } from '../../../../store/reducers/content-page';
import verifyTemplateType from './TemplateCreator';
import HoverNavMenu from '../../../reusable/molecules/HoverNavMenu';
import { Grid, Hidden } from '@material-ui/core';

interface Props {
    header: ReactNode;
    sections: ContentSection[];
}

export default function LeftNavContentTemplate({ sections, header }: Props) {
    const sectionRefs = sections.map(() => createRef<HTMLElement>());

    return (
        <Grid container item xs={12}>
            <Hidden xsDown>
                <Grid item sm={3}>
                    <HoverNavMenu
                        header={header}
                        sections={sections
                            .map((section, index) => ({
                                ref: sectionRefs[index],
                                header: section.header
                            }))
                            .filter((section) => section.header)}
                    />
                </Grid>
            </Hidden>
            <Grid item sm={9}>
                {sections.map((section, i) => {
                    return (
                        <section ref={sectionRefs[i]} key={i}>
                            {verifyTemplateType(section.type, section, i)}
                        </section>
                    );
                })}
            </Grid>
        </Grid>
    );
}
