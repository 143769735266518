import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import { useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import {
    makeStyles,
    Grid,
    Paper,
    Typography,
    Select,
    MenuItem,
    Button,
    FormControl,
    InputLabel,
    FormHelperText
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    buildWeekdays,
    buildDeliveryTimes,
    Weekday,
    getFullFormattedDateTime
} from '../../../../utility/helpers/date-helpers';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import {
    DeliveryTime,
    ProductToShip,
    DeliveryShipment,
    ShippingState
} from '../../../../store/reducers/shipping-dashboard';
import ShipItConfigGrid from './ShipItConfigGrid';
import ShipItConfigTruck from './ShipItConfigTruck';
import {
    blackWeight,
    ballGray,
    boldWeight,
    ballBlue,
    small,
    large,
    medium,
    white,
    inactiveTabBlue,
    dateOptionInvalidGray,
    xxl,
    ballDrkBlue,
    errorBackground,
    shipRed,
    black,
    fullTruckPallets
} from '../../../../themes/globalConstants';
import {
    customerPoIsValid,
    isProductCanType,
    isProductEndType
} from '../../../../utility/helpers/order-helpers';
import {
    toggleAddDisabled,
    updateProductToBeShipped,
    updateShipments
} from '../../../../store/actions/shipping-dashboard';
import ShipItConfigChangeDayModal from './ShipItConfigChangeDayModal';
import Modal from '../../../reusable/molecules/Modal';
import { Alert } from '@material-ui/lab';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { getPalletsPerTruck } from '../../../../utility/helpers/shipment-helpers';
import { selectIsLargeCustomerAccount } from '../../../../store/selectors';
import { Moment } from 'moment';

interface Props {
    shipment: DeliveryShipment;
    shipmentIndex: number;
    onDeleteShipment: (index: number) => void;
    onCompleteShipment: (shipment: DeliveryShipment, shipmentIndex: number) => void;
    onEditShipment: (shipment: DeliveryShipment, shipmentIndex: number, dateIndex: number) => void;
    shipments: DeliveryShipment[];
    onSelectDeliveryDate: (
        dateIndex: number,
        shipmentIndex: number,
        loads?: ProductToShip[]
    ) => void;
    onDeleteProduct: (shipment: DeliveryShipment, shipmentIndex: number) => void;
    customerPickUp: boolean;
    isCopacker: boolean;
    leadTime: number;
    isLargeCustomer: boolean;
}

const useStyles = makeStyles((theme) => ({
    main: {
        padding: '1.875em',
        width: '100%',
        marginBottom: '2.75em'
    },
    dateDescription: {
        color: ballGray,
        display: 'flex',
        alignItems: 'center'
    },
    altDescriptionColor: {
        color: ballDrkBlue
    },
    dateDescriptionMain: {
        fontSize: xxl,
        fontWeight: blackWeight
    },
    dateDescriptionSub: {
        margin: 'auto',
        fontSize: small,
        fontWeight: blackWeight,
        textTransform: 'uppercase',
        color: ballGray
    },
    dateOptionsWrapper: {
        marginLeft: '1.25em'
    },
    dateOption: {
        padding: '0.5em 1em',
        color: ballBlue,
        border: `1px solid ${ballBlue}`,
        flexGrow: 1,
        textAlign: 'center',
        '&:not(:last-child)': {
            marginRight: '0.125em'
        }
    },
    dateOptionDayName: {
        minWidth: '2em',
        fontSize: large,
        fontWeight: blackWeight
    },
    dateOptionDayNum: {
        fontSize: medium,
        fontWeight: boldWeight
    },
    firstDateOption: {
        borderTopLeftRadius: '0.250em',
        borderBottomLeftRadius: '0.250em'
    },
    lastDateOption: {
        borderTopRightRadius: '0.250em',
        borderBottomRightRadius: '0.250em'
    },
    invalidDate: {
        backgroundColor: inactiveTabBlue,
        border: 'unset',
        '& p': {
            color: dateOptionInvalidGray
        },
        '&:hover': {
            cursor: 'not-allowed'
        }
    },
    validDate: {
        height: 'inherit',
        transition: 'transform .2s',
        '&:hover': {
            overflow: 'visible',
            transform: 'scale(1.05)',
            boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.08)',
            cursor: 'pointer'
        }
    },
    selectedDate: {
        background: ballBlue,
        '& p': {
            color: white
        }
    },
    preview: {
        border: `1px solid ${theme.palette.primary.main}`,
        borderStyle: 'dashed',
        padding: '3em 0',
        marginTop: '3.750em',
        marginBottom: '2.250em',
        borderRadius: '0.5em'
    },
    previewText: {
        fontWeight: boldWeight,
        fontSize: medium,
        maxWidth: '31.250em',
        margin: 'auto',
        color: ballGray
    },
    selectWrapper: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiInputBase-root': {
            backgroundColor: theme.palette.common.white,
            border: '1px solid',
            borderColor: theme.palette.secondary.light,
            '&:before': {
                marginBottom: '-0.250em',
                border: 0
            },
            '&.Mui-focused': {
                '& .MuiSvgIcon-root': {
                    transform: 'rotate(-180deg)'
                }
            },
            '& .MuiSvgIcon-root': {
                fill: theme.palette.primary.main,
                transition: 'transform 200ms'
            }
        },
        '& .MuiSelect-root': {
            textAlign: 'left',
            backgroundColor: theme.palette.common.white,
            minWidth: '6.250em',
            display: 'flex',
            alignItems: 'center',
            fontSize: medium,
            fontWeight: blackWeight
        },
        '& .MuiFormHelperText-root': {
            marginLeft: '0.875em'
        },
        '& .MuiFormLabel-filled': {
            fontWeight: blackWeight
        },
        '& .MuiInputLabel-root': {
            fontSize: medium,
            fontWeight: blackWeight
        }
    },
    select: {
        '& ul': {
            maxHeight: '18.750em'
        }
    },
    actionsBar: {
        marginTop: '1.250em'
    },
    editBtn: {
        float: 'right'
    },
    cardSubheader: {
        color: ballGray
    },
    cardDetailsWrapper: {
        '& .MuiGrid-item': {
            paddingLeft: '2.000em'
        }
    },
    shipmentBtnContainer: {
        '& button': {
            '&:not(:last-child)': {
                marginRight: '1.125em'
            }
        }
    },
    removeMargin: {
        margin: 0
    },
    timeSelect: {
        maxHeight: '15em',
        top: '23.125em !important'
    },
    dataTimeError: {
        marginTop: '1em',
        width: '100%',
        border: `1px solid ${shipRed}`,
        borderRadius: '.125em',
        '& .MuiAlert-icon': {
            color: shipRed,
            display: 'flex',
            alignItems: 'center'
        },
        '& .MuiAlert-message': {
            '& .MuiTypography-root': {
                color: black,
                fontWeight: boldWeight
            }
        },
        backgroundColor: errorBackground
    }
}));

const ShipItConfigShipment = ({
    shipment,
    shipmentIndex,
    onDeleteShipment,
    onCompleteShipment,
    onEditShipment,
    shipments,
    onSelectDeliveryDate,
    onDeleteProduct,
    customerPickUp,
    isCopacker,
    leadTime,
    isLargeCustomer
}: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { productsToBeShipped, selectedDeliveryDate, shipmentsOrder } =
        useTypedSelector<ShippingState>((state) => state.shippingDashboard);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const isLargeCustomerAccount = useTypedSelector<boolean>(selectIsLargeCustomerAccount);

    const maxPallets = getPalletsPerTruck(isLargeCustomer, isCopacker, isLargeCustomerAccount);
    const deliveryWeek = moment(selectedDeliveryDate);
    const errorIcon = (
        <img src={process.env.PUBLIC_URL + '/assets/Error_Icon.svg'} alt="Error Icon" />
    );

    const [isEdit, setIsEdit] = useState<boolean>(true);
    const [isCanType, setIsCanType] = useState<boolean>(true);
    const [allowEdit, setAllowEdit] = useState<boolean>(false);
    const [revertable, setRevertable] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [warningText, setWarningText] = useState([] as React.ReactNode);
    const [validShipment, setValidShipment] = useState<boolean>(false);
    const [deliveryTimeZoneString, setDeliveryTimeZoneString] = useState<string>('');
    const [shipmentDateAndTime, setShipmentDateAndTime] = useState<string>('');
    const [selectedDateOptionIndex, setSelectedDateOptionIndex] = useState<number>(-1);
    const [tempDateIndex, setTempDateIndex] = useState<number>(-1);
    const [showDateTimeError, setShowDateTimeError] = useState<boolean>(false);
    const [dateTimeWarningText, setDateTimeWarningText] = useState([] as React.ReactNode);
    const [storedShipment, setStoredShipment] = useState({} as DeliveryShipment);
    const [currentShipment, setCurrentShipment] = useState({} as DeliveryShipment);
    const [deliveryTimeList, setDeliveryTimeList] = useState([] as DeliveryTime[]);
    const [openChangeDayWarningModal, setOpenChangeDayWarningModal] = useState<boolean>(false);
    const [availBalanceWarning, setAvailBalanceWarning] = useState<boolean | undefined>(undefined);

    const allPoNumbersAreValid = () => {
        const somePoNumberIsInvalid = currentShipment?.loads
            ?.map((load) => load.purchaseOrderNumber)
            .some((poNumber) => !customerPoIsValid(poNumber ?? '', true));

        return !somePoNumberIsInvalid;
    };

    const isShipmentValid = () => {
        let isValid =
            currentShipment.loads &&
            currentShipment.loads.length &&
            currentShipment.palletCount &&
            checkPONumbers() &&
            currentShipment.palletCount !== 0 &&
            shipmentDateAndTime &&
            allPoNumbersAreValid() &&
            !showDateTimeError
                ? true
                : false;

        setValidShipment(isValid);
    };

    const checkPONumbers = () => {
        if (!isLargeCustomer) {
            return true;
        } else {
            let linesHavePO = true;
            if (currentShipment.loads) {
                currentShipment.loads.forEach((load) => {
                    if (!load.purchaseOrderNumber) {
                        linesHavePO = false;
                    }
                });
            }
            return linesHavePO;
        }
    };

    //Triggers on date change if no products currently in load
    const handleSelectDateNoLoads = (dateIndex: number) => {
        const loads = currentShipment.loads && currentShipment.loads;
        clearWarning();
        setSelectedDateOptionIndex(dateIndex);
        onSelectDeliveryDate(dateIndex, shipmentIndex, loads);
    };

    const handleChangeDayModalContinue = () => {
        const loads = currentShipment.loads && currentShipment.loads;

        setOpenChangeDayWarningModal(false);
        setSelectedDateOptionIndex(tempDateIndex);
        onSelectDeliveryDate(tempDateIndex, shipmentIndex, loads);
    };

    const handleChangeDayWarning = () => {
        setOpenChangeDayWarningModal(true);
    };

    const handleChangeDayModalClose = () => {
        setOpenChangeDayWarningModal(false);
        setTempDateIndex(-1);
    };

    //Triggers first on change date click
    const selectDeliveryDate = (dateIndex: number) => {
        const loads = currentShipment.loads && currentShipment.loads;

        //If shipment has products in it and is before currently selected date, show warning
        if (loads && loads.length > 0 && dateIndex < selectedDateOptionIndex) {
            handleChangeDayWarning();
            //Set temp date index here so we can pass it to the modal if 'continue'
            //or reset to original date selected if 'cancel'
            setTempDateIndex(dateIndex);
        } else {
            setSelectedDateOptionIndex(dateIndex);
            handleSelectDateNoLoads(dateIndex);
        }
    };

    const isUniqueDateTime = (selectedMoment: Moment) => {
        const dateComparingFormat = 'MM/DD/YYYY h:mm A';
        const selectedDate = selectedMoment.format(dateComparingFormat);

        for (let shipmentIndex = 0; shipmentIndex < shipments.length; shipmentIndex++) {
            let dateToCompare = moment(shipments[shipmentIndex].deliveryDateTime).format(
                dateComparingFormat
            );

            if (dateToCompare === selectedDate) {
                return false;
            }
        }
        return true;
    };

    const handleChangeTime = (event: React.ChangeEvent<{ value: unknown }>) => {
        let selectedDate = moment(event.target.value as Date);

        if (isUniqueDateTime(selectedDate)) {
            clearDateTimeError();
            setShipmentDateAndTime(event.target.value as any);
        } else {
            setShipmentDateAndTime('');
            renderTimeSelect();
            displayDateTimeError(
                t(
                    'deliveryOrderDayTimeError',
                    'Shipments on the same delivery order cannot have duplicate dates and times. Please modify the time chosen.'
                )
            );
        }
    };

    const clearDateTimeError = () => {
        setDateTimeWarningText(false);
        setShowDateTimeError(false);
    };

    const displayDateTimeError = (label: React.ReactNode) => {
        setDateTimeWarningText(label);
        setShowDateTimeError(true);
    };

    const renderDateSelector = () => {
        let days: React.ReactNode[] = [];
        let weekdays: Weekday[] = buildWeekdays(deliveryWeek);
        const firstAvailableDay = moment().add(leadTime, 'days').startOf('day');

        for (let x = 0; x < 7; x++) {
            const isValidDate = moment(weekdays[x].date)
                .startOf('day')
                .isSameOrAfter(firstAvailableDay);
            const isSelectedDate = selectedDateOptionIndex === x;
            days.push(
                <Grid
                    item
                    key={`date-option-${x}`}
                    className={clsx(
                        classes.dateOption,
                        { [classes.firstDateOption]: x === 0 },
                        { [classes.lastDateOption]: x === 6 },
                        { [classes.invalidDate]: !isValidDate },
                        { [classes.validDate]: isValidDate && !isSelectedDate },
                        { [classes.selectedDate]: isSelectedDate }
                    )}
                    onClick={() => {
                        if (isValidDate && !isSelectedDate) {
                            selectDeliveryDate(x);
                            setDeliveryTimeList(buildDeliveryTimes(moment(weekdays[x].date)));
                        }
                    }}
                    data-testid={isValidDate ? 'valid-date' : 'invalid-date'}
                >
                    <Typography className={classes.dateOptionDayName}>
                        {weekdays[x].name}
                    </Typography>
                    <Typography className={classes.dateOptionDayNum}>{weekdays[x].day}</Typography>
                </Grid>
            );
        }

        return days;
    };

    const renderTimeSelect = () => {
        return (
            <Grid item md={3} className={classes.selectWrapper}>
                <Typography className={classes.dateDescriptionSub}>
                    <Trans i18nKey="at">AT</Trans>
                </Typography>
                <FormControl>
                    <InputLabel>{<Trans i18nKey="time">Time</Trans>}</InputLabel>
                    <Select
                        label="Time"
                        labelId="time-select"
                        id="time-select"
                        data-testid="time-select"
                        aria-label="Time"
                        IconComponent={ExpandMoreIcon}
                        disabled={selectedDateOptionIndex === -1}
                        className={classes.select}
                        onChange={handleChangeTime}
                        value={shipmentDateAndTime}
                        MenuProps={{ classes: { paper: classes.timeSelect } }}
                    >
                        <MenuItem>&nbsp;</MenuItem>
                        {deliveryTimeList.length &&
                            deliveryTimeList.map((time) => (
                                <MenuItem
                                    key={time.label}
                                    value={moment(time.time).format('MM/DD/YY h:mm A')}
                                >
                                    {time.label}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Grid>
        );
    };

    const renderTimeSelectDisclaimer = () => {
        return (
            <Grid container direction="row" justify="flex-end">
                <Grid item md={2} className={classes.selectWrapper}>
                    <FormHelperText>{deliveryTimeZoneString}</FormHelperText>
                </Grid>
            </Grid>
        );
    };

    const handleRemoveProductFromShipment = (product: ProductToShip) => {
        let currentProduct = { ...product, palletsOrdered: 0 };
        let items = [...currentShipment.loads!];
        let itemIndex = items.findIndex((item) => item.productSku === currentProduct.productSku);

        if (itemIndex !== -1) {
            items.splice(itemIndex, 1);
            let totPallets = 0;
            items?.map((load) => {
                const pallets = load.palletQuantity;
                if (pallets) {
                    totPallets = totPallets + pallets;
                }
                return null;
            });

            if (currentShipment.loads && currentShipment.loads.length) {
                setCurrentShipment({ ...currentShipment, loads: items, palletCount: totPallets });
            } else {
                setCurrentShipment({
                    ...currentShipment,
                    loads: items,
                    palletCount: totPallets,
                    shipmentType: undefined
                });
            }
            onDeleteProduct(
                { ...currentShipment, loads: items, palletCount: totPallets },
                shipmentIndex
            );
        }

        resetRemainingPalletsForProduct(product);
    };

    const handleUpdateProduct = (product: ProductToShip) => {
        const isEndType = isProductEndType(product.type);
        let totalOrderedPallets = 0;
        let loadIndex = currentShipment.loads?.findIndex(
            (item) => item.productSku === product.productSku
        );
        let shippedIndex = productsToBeShipped.findIndex(
            (item) => item.productSku === product.productSku
        );
        let updatedProductsToBeShipped = [...productsToBeShipped];
        let itemToBeUpdated = { ...productsToBeShipped[shippedIndex] };
        let totPalletsInAllShipments =
            calculatePalletsOnOtherShipments(product) + product.palletQuantity;
        let newCalculatedPalletList = [...(itemToBeUpdated.previousPalletTotals as number[])];
        let totalWithoutCurrentPallet =
            itemToBeUpdated.previousPalletTotals![selectedDateOptionIndex] -
            calculatePalletsOnOtherShipments(product);
        setAvailBalanceWarning(undefined);

        currentShipment.loads?.map((load) => {
            if (load.productSku !== product.productSku) {
                totalOrderedPallets += load.palletQuantity;
            } else {
                totalOrderedPallets = totalOrderedPallets + product.palletQuantity;
            }
            return null;
        });

        const showInvalidPoNumberWarning = !allPoNumbersAreValid();
        const poWarningText = t(
            'poNumberWarning',
            'Please enter a Customer PO Number with no special characters.'
        );

        if (showInvalidPoNumberWarning) displayWarning(poWarningText);
        else if (warningText === poWarningText) clearWarning();

        if (totalOrderedPallets <= maxPallets || isEndType || customerPickUp) {
            if (product.palletQuantity <= totalWithoutCurrentPallet) {
                if (!showInvalidPoNumberWarning) clearWarning();
                currentShipment.loads?.splice(loadIndex as number, 1, product);

                newCalculatedPalletList.map((pallets, i) => {
                    let remainingPallets = pallets - totPalletsInAllShipments;

                    remainingPallets = remainingPallets < 0 ? 0 : remainingPallets;
                    newCalculatedPalletList.splice(i, 1, remainingPallets);
                    return null;
                });
                setCurrentShipment({
                    ...currentShipment,
                    loads: currentShipment.loads,
                    palletCount: totalOrderedPallets
                });

                if (itemToBeUpdated.calculatedTotalsForPrevNDays) {
                    itemToBeUpdated = {
                        ...itemToBeUpdated,
                        calculatedTotalsForPrevNDays: newCalculatedPalletList
                    };
                    updatedProductsToBeShipped.splice(shippedIndex, 1, itemToBeUpdated);
                    dispatch(updateProductToBeShipped(updatedProductsToBeShipped));
                }
            } else {
                // pallet ordered exceeded remaining pallets
                setAvailBalanceWarning(true);
                displayWarning(
                    t(
                        'notEnoughRemainingPallets',
                        'Maximum number of pallets exceeded for a product. Please update the quantities within the shipment before saving.'
                    )
                );
            }
        } else {
            // pallet ordered exceeded maximum truck capacity
            displayWarning(t('maximumNumberOfPalletsWarning', { truckPalletLimit: maxPallets }));
        }
    };

    const resetCalculatedPalletTotals = (product: ProductToShip) => {
        const shippedIndex = productsToBeShipped.findIndex(
            (item) => item.productSku === product.productSku
        );
        const totPalletsInOtherShipments = calculatePalletsOnOtherShipments(product);
        let updatedProductsToBeShipped = [...productsToBeShipped];
        let itemToBeUpdated = { ...productsToBeShipped![shippedIndex] };
        let calculatedPalletList = [...(itemToBeUpdated.previousPalletTotals as number[])];

        if (shippedIndex !== -1) {
            calculatedPalletList.map((pallets, i) => {
                let remainingPallets = pallets - totPalletsInOtherShipments;
                remainingPallets = remainingPallets < 0 ? 0 : remainingPallets;
                calculatedPalletList.splice(i, 1, remainingPallets);
                return null;
            });

            if (itemToBeUpdated.calculatedTotalsForPrevNDays) {
                itemToBeUpdated = {
                    ...itemToBeUpdated,
                    calculatedTotalsForPrevNDays: calculatedPalletList
                };

                updatedProductsToBeShipped.splice(shippedIndex, 1, itemToBeUpdated);
                dispatch(updateProductToBeShipped(updatedProductsToBeShipped));
            }
        }
    };

    const checkAvailablePallets = () => {
        if (shipmentsOrder) {
            let shipmentIndex = shipmentsOrder.findIndex(
                (order) => order.id === currentShipment.id
            );
            let updatedShipmentsOrder = { ...shipmentsOrder[shipmentIndex] };
            let shipmentsArr = [...(shipmentsOrder as DeliveryShipment[])];

            //If Shipment Order has products(loads)
            if (updatedShipmentsOrder.loads) {
                updatedShipmentsOrder.loads.map((productInShipment) => {
                    const productIndex = updatedShipmentsOrder!.loads!.findIndex(
                        (load) => load.productSku === productInShipment.productSku
                    );
                    const productToShipIndex = productsToBeShipped.findIndex(
                        (shippingProduct) =>
                            shippingProduct.productSku === productInShipment.productSku
                    );
                    const previousCalculatedPalletTotals =
                        productsToBeShipped[productToShipIndex].calculatedTotalsForPrevNDays;
                    let itemToBeUpdated = { ...productsToBeShipped[productToShipIndex] };

                    if (productIndex !== -1) {
                        const currentShipment = shipmentsOrder[shipmentIndex];
                        const availablePallets =
                            previousCalculatedPalletTotals &&
                            previousCalculatedPalletTotals[selectedDateOptionIndex];
                        const availablePalletsByDay =
                            currentShipment!.loads![productIndex!].calculatedTotalsForPrevNDays![
                                selectedDateOptionIndex
                            ];
                        const desiredPallets = productInShipment.palletQuantity;

                        if (availablePallets !== undefined && desiredPallets) {
                            switch (desiredPallets !== 0) {
                                case desiredPallets > availablePalletsByDay &&
                                    availablePalletsByDay !== 0:
                                    //Set desiredPallets to equal number of pallets available that day
                                    productInShipment.palletQuantity = availablePalletsByDay;
                                    updatedShipmentsOrder.loads?.splice(
                                        productIndex as number,
                                        1,
                                        productInShipment
                                    );
                                    break;

                                //If desired pallets are more than available pallets by day because available pallets by day is 0
                                case desiredPallets > availablePalletsByDay &&
                                    availablePalletsByDay === 0:
                                    //Remove product from shipment and reset calculated pallet totals
                                    updatedShipmentsOrder.loads?.splice(productIndex as number, 1);
                                    resetCalculatedPalletTotals(itemToBeUpdated);
                                    break;

                                case desiredPallets < availablePalletsByDay:
                                    break;

                                case desiredPallets === availablePalletsByDay:
                                    break;

                                case desiredPallets === 0:
                                    break;
                            }
                        } else if (availablePallets === 0 || availablePallets === undefined) {
                            //Remove product from shipment and reset calculated pallet totals
                            updatedShipmentsOrder.loads?.splice(productIndex as number, 1);
                            resetCalculatedPalletTotals(itemToBeUpdated);
                        }

                        shipmentsArr.splice(shipmentIndex, 1, updatedShipmentsOrder);
                        dispatch(updateShipments([...shipmentsArr]));
                    }
                    return null;
                });
            }
        }
    };

    const calculatePalletsOnOtherShipments = (product: ProductToShip) => {
        let palletTally = 0;
        shipments.map((filterShipments) => {
            filterShipments.loads?.map((load) => {
                if (product.productSku === load.productSku && filterShipments.id !== shipment.id) {
                    palletTally = palletTally + load.palletQuantity;
                }
                return null;
            });
            return null;
        });
        return palletTally;
    };

    const handleCompleteShipment = () => {
        let tempLoads: ProductToShip[] = [];
        let savedShipment = {
            ...currentShipment,
            deliveryDateTime: shipmentDateAndTime,
            deliveryDateIndex: selectedDateOptionIndex
        };
        const palletsLessThanRequired =
            savedShipment.palletCount && savedShipment.palletCount! < maxPallets;
        const savedShipmentCanType =
            savedShipment.loads && isProductCanType(savedShipment.loads[0].type);

        clearWarning();

        savedShipment.loads?.map((product, i) => {
            if (product.palletQuantity !== 0) {
                tempLoads.push(product);
            } else {
                handleRemoveProductFromShipment(product);
            }
            return null;
        });

        savedShipment = { ...savedShipment, loads: tempLoads };

        if (palletsLessThanRequired && !customerPickUp && savedShipmentCanType) {
            displayFreightFormula(savedShipment);
        }

        setIsEdit(false);
        setRevertable(false);
        onCompleteShipment(savedShipment, shipmentIndex);
    };

    const handleEditShipment = () => {
        let savedShipment = { ...currentShipment, saved: false };
        setStoredShipment(savedShipment);
        onEditShipment(savedShipment, shipmentIndex, savedShipment.deliveryDateIndex!);
        setIsEdit(true);
        setRevertable(true);
        dispatch(toggleAddDisabled(currentShipment));
    };

    const handleDeleteShipment = () => {
        clearWarning();
        setShipmentDateAndTime('');
        setSelectedDateOptionIndex(-1);
        onDeleteShipment(shipmentIndex);
        resetRemainingPalletsForProducts();
    };

    const resetRemainingPalletsForProducts = () => {
        let updatedProductsToBeShipped = [...productsToBeShipped];

        currentShipment.loads?.map((item) => {
            productsToBeShipped.map((product, i) => {
                if (product.productSku === item.productSku) {
                    let newPalletList: number[] = [];
                    let currentCalcTotals = product.previousPalletTotals;
                    let totPalletsInAllShipments = calculatePalletsOnOtherShipments(product);

                    if (currentCalcTotals) {
                        currentCalcTotals.map((pallets) => {
                            let remainingPallets = pallets - totPalletsInAllShipments;

                            remainingPallets = remainingPallets < 0 ? 0 : remainingPallets;
                            newPalletList.push(remainingPallets);
                            return null;
                        });
                        updatedProductsToBeShipped.splice(i, 1, {
                            ...product,
                            palletQuantity: Math.abs(item.palletQuantity - product.palletQuantity),
                            previousPalletTotals: product.previousPalletTotals,
                            calculatedTotalsForPrevNDays: newPalletList
                        });
                    }
                }
                return null;
            });
            return null;
        });

        dispatch(updateProductToBeShipped(updatedProductsToBeShipped));
    };

    const resetRemainingPalletsForProduct = (product: ProductToShip) => {
        let updatedProductsToBeShipped = [...productsToBeShipped];

        productsToBeShipped.map((item, i) => {
            if (product.productSku === item.productSku) {
                let newPalletList: number[] = [];
                let itemToBeUpdated = { ...item };
                let currentCalcTotals = itemToBeUpdated.previousPalletTotals;
                let totPalletsInAllShipments = calculatePalletsOnOtherShipments(itemToBeUpdated);

                if (currentCalcTotals) {
                    currentCalcTotals.map((pallets) => {
                        let remainingPallets = pallets - totPalletsInAllShipments;

                        remainingPallets = remainingPallets < 0 ? 0 : remainingPallets;
                        newPalletList.push(remainingPallets);
                        return null;
                    });
                    updatedProductsToBeShipped.splice(i, 1, {
                        ...product,
                        palletQuantity: Math.abs(item.palletQuantity - product.palletQuantity),
                        previousPalletTotals: itemToBeUpdated.previousPalletTotals,
                        calculatedTotalsForPrevNDays: newPalletList,
                        releaseNumber: '',
                        fillerLine: '',
                        reference: ''
                    });
                }
            }
            return null;
        });

        dispatch(updateProductToBeShipped(updatedProductsToBeShipped));
    };

    const handleRevertShipment = () => {
        clearWarning();
        setIsEdit(false);
        setRevertable(false);

        if (storedShipment.palletCount && storedShipment.palletCount < maxPallets) {
            displayFreightFormula(storedShipment);
        }

        updateProductListOnRevert(currentShipment, storedShipment);
        onCompleteShipment(storedShipment, shipmentIndex);
        setCurrentShipment({ ...storedShipment });
    };

    const updateProductListOnRevert = (
        currentLoads: DeliveryShipment,
        storedLoads: DeliveryShipment
    ) => {
        let updatedProductsToBeShipped = [...productsToBeShipped];

        currentLoads.loads?.map((currentLoad) => {
            productsToBeShipped.map((item, i) => {
                if (currentLoad.productSku === item.productSku) {
                    let newPalletList: number[] = [];
                    let itemToBeUpdated = { ...item };
                    let currentCalcTotals = itemToBeUpdated.previousPalletTotals;
                    let totPalletsInAllShipments =
                        calculatePalletsOnOtherShipments(itemToBeUpdated);

                    if (currentCalcTotals) {
                        currentCalcTotals.map((pallets) => {
                            let remainingPallets = pallets - totPalletsInAllShipments;

                            remainingPallets = remainingPallets < 0 ? 0 : remainingPallets;
                            newPalletList.push(remainingPallets);
                            return null;
                        });
                        updatedProductsToBeShipped.splice(i, 1, {
                            ...currentLoad,
                            palletQuantity: Math.abs(
                                item.palletQuantity - currentLoad.palletQuantity
                            ),
                            previousPalletTotals: itemToBeUpdated.previousPalletTotals,
                            calculatedTotalsForPrevNDays: newPalletList
                        });
                    }
                }
                return null;
            });
            return null;
        });

        storedLoads.loads?.map((storedLoad) => {
            let shippedIndex = productsToBeShipped.findIndex(
                (item) => item.productSku === storedLoad.productSku
            );
            let itemToBeUpdated = { ...productsToBeShipped[shippedIndex] };
            let totPalletsInAllShipments =
                calculatePalletsOnOtherShipments(storedLoad) + storedLoad.palletQuantity;
            let newCalculatedPalletList = [...(itemToBeUpdated.previousPalletTotals as number[])];

            newCalculatedPalletList.map((pallets, i) => {
                let remainingPallets = pallets - totPalletsInAllShipments;

                remainingPallets = remainingPallets < 0 ? 0 : remainingPallets;
                newCalculatedPalletList.splice(i, 1, remainingPallets);
                return null;
            });

            if (itemToBeUpdated.calculatedTotalsForPrevNDays) {
                itemToBeUpdated = {
                    ...itemToBeUpdated,
                    calculatedTotalsForPrevNDays: newCalculatedPalletList
                };
                updatedProductsToBeShipped.splice(shippedIndex, 1, itemToBeUpdated);
            }
            return null;
        });

        dispatch(updateProductToBeShipped(updatedProductsToBeShipped));
    };

    const clearWarning = () => {
        setWarningText(false);
        setShowWarning(false);
    };

    const displayWarning = (label: React.ReactNode) => {
        setWarningText(label);
        setShowWarning(true);
    };

    const displayFreightFormula = (activeShipment: DeliveryShipment) => {
        if (
            activeShipment.palletCount &&
            activeShipment.palletCount < fullTruckPallets &&
            (!isCopacker || (isCopacker && isLargeCustomerAccount))
        ) {
            let warning: React.ReactNode[] = [];
            warning.push(t('atmFreightWeightWarning'));
            displayWarning(warning);
        }
    };

    useEffect(() => {
        if (cultureCode) {
            setDeliveryTimeZoneString(t('deliveryTimeZone', 'Select desired delivery time'));
        }
    }, [cultureCode, t]);

    useEffect(() => {
        if (selectedDateOptionIndex !== -1) {
            checkAvailablePallets();
        }
    }, [selectedDateOptionIndex]);

    useEffect(() => {
        setShipmentDateAndTime(getFullFormattedDateTime('', shipment.deliveryDateTime));
        // Runs only once
    }, []);

    useEffect(() => {
        const isEndType = isProductEndType(shipment.shipmentType);
        isEndType ? setIsCanType(false) : setIsCanType(true);

        setCurrentShipment({ ...shipment, palletCount: currentShipment.palletCount || 0 });
        setIsEdit(!shipment.saved);
        // CurrentShipment.palletcount keep changing
    }, [shipment]);

    useEffect(() => {
        if (shipments.length) {
            for (let x = 0; x < shipments.length; x++) {
                if (shipments[x].saved === undefined || !shipments[x].saved) {
                    setAllowEdit(false);
                    break;
                } else {
                    setAllowEdit(true);
                }
            }
        }
    }, [shipments]);

    useEffect(() => {
        isShipmentValid();
    }, [currentShipment, shipmentDateAndTime]);

    return (
        <>
            {isEdit && (
                <Paper className={classes.main}>
                    <Grid container>
                        <Grid container item md={9}>
                            <Grid item md={2} className={classes.dateDescription}>
                                <Typography
                                    className={clsx(
                                        classes.dateDescriptionSub,
                                        classes.removeMargin
                                    )}
                                >
                                    <Trans i18nKey="deliverOn">Deliver On</Trans>
                                </Typography>
                            </Grid>
                            <Grid item md={9} className={classes.dateOptionsWrapper}>
                                <Grid container>{renderDateSelector()}</Grid>
                            </Grid>
                        </Grid>
                        {renderTimeSelect()}
                        {renderTimeSelectDisclaimer()}
                    </Grid>
                    {showDateTimeError && (
                        <Grid container justify="center">
                            <Alert
                                icon={errorIcon}
                                severity="warning"
                                className={classes.dataTimeError}
                            >
                                <Typography>{dateTimeWarningText}</Typography>
                            </Alert>
                        </Grid>
                    )}
                    {currentShipment.loads && currentShipment.loads.length ? (
                        <Grid container>
                            {isCanType && !customerPickUp ? (
                                <>
                                    <ShipItConfigTruck
                                        items={currentShipment}
                                        isLargeCustomer={isLargeCustomer}
                                        truckPalletLimit={maxPallets}
                                    />
                                    <Grid container item>
                                        <ShipItConfigGrid
                                            shipmentId={currentShipment.id}
                                            items={currentShipment.loads}
                                            onRemoveItem={handleRemoveProductFromShipment}
                                            onUpdateItem={handleUpdateProduct}
                                            warning={showWarning}
                                            warningText={warningText}
                                            maxPallets={maxPallets}
                                            availBalanceWarning={availBalanceWarning}
                                        />
                                    </Grid>
                                </>
                            ) : (
                                <Grid container item>
                                    <ShipItConfigGrid
                                        shipmentId={currentShipment.id}
                                        items={currentShipment.loads}
                                        onRemoveItem={handleRemoveProductFromShipment}
                                        onUpdateItem={handleUpdateProduct}
                                        warning={showWarning}
                                        warningText={warningText}
                                        maxPallets={maxPallets}
                                        availBalanceWarning={availBalanceWarning}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    ) : (
                        <Grid container className={classes.preview}>
                            <Typography className={classes.previewText}>
                                <Trans i18nKey="addDeliveryInstructions">
                                    Add a product from the list you created and enter the quantities
                                    here. The cart will update to display the remaining pallets to
                                    load, if any.
                                </Trans>
                            </Typography>
                        </Grid>
                    )}
                    <Grid container justify="flex-end" className={classes.actionsBar}>
                        <Grid item className={classes.shipmentBtnContainer}>
                            <Button
                                type="button"
                                variant="outlined"
                                size="small"
                                data-testid="delete-shipment-btn"
                                onClick={handleDeleteShipment}
                            >
                                <Trans i18nKey="delete">delete</Trans>
                            </Button>
                            {revertable && (
                                <Button
                                    type="button"
                                    variant="outlined"
                                    data-testid="cancel-btn"
                                    onClick={handleRevertShipment}
                                >
                                    <Trans i18nKey="cancel">cancel</Trans>
                                </Button>
                            )}
                            <Button
                                type="button"
                                color="primary"
                                variant="contained"
                                data-testid="done-btn"
                                disabled={!validShipment}
                                onClick={handleCompleteShipment}
                            >
                                <Trans i18nKey="done">done</Trans>
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            )}
            {!isEdit && isShipmentValid && (
                <Paper className={classes.main}>
                    <Grid container item justify="space-between">
                        <Grid item md={7} className={classes.dateDescription}>
                            <Typography
                                className={clsx(
                                    classes.dateDescriptionMain,
                                    classes.altDescriptionColor
                                )}
                            >
                                <>
                                    <Trans i18nKey="deliveryDate">Delivery Date</Trans>:{' '}
                                </>
                                {shipmentDateAndTime}
                            </Typography>
                        </Grid>
                        <Grid container item md={5} className={classes.cardDetailsWrapper}>
                            <Grid item xs={4}>
                                <Typography variant="subtitle2" className={classes.cardSubheader}>
                                    <Trans i18nKey="type">Type</Trans>
                                </Typography>
                                <Typography>
                                    {isCanType ? (
                                        <Trans i18nKey="cansBottles">Cans/Bottles</Trans>
                                    ) : (
                                        <Trans i18nKey="endClosures">End/Closures</Trans>
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="subtitle2" className={classes.cardSubheader}>
                                    <Trans i18nKey="quantity">Quantity</Trans>
                                </Typography>
                                <Typography>{currentShipment.palletCount} PL</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="subtitle2" className={classes.cardSubheader}>
                                    <Trans i18nKey="products">Products</Trans>
                                </Typography>
                                <Typography>{currentShipment.loads?.length}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid container item>
                            {currentShipment.loads && currentShipment.loads.length && (
                                <ShipItConfigGrid
                                    shipmentId={currentShipment.id}
                                    items={currentShipment.loads}
                                    onRemoveItem={handleRemoveProductFromShipment}
                                    onUpdateItem={handleUpdateProduct}
                                    readOnly={true}
                                    warning={showWarning}
                                    warningText={warningText}
                                    maxPallets={maxPallets}
                                    availBalanceWarning={availBalanceWarning}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-end" className={classes.actionsBar}>
                        <Grid item xs={6}>
                            <Button
                                type="button"
                                color="primary"
                                variant="contained"
                                data-testid="edit-btn"
                                onClick={handleEditShipment}
                                disabled={!allowEdit}
                                className={classes.editBtn}
                            >
                                <Trans i18nKey="edit">edit</Trans>
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            )}
            <Modal
                open={openChangeDayWarningModal}
                title={t('changeDeliveryDate', 'Change Delivery Date')}
                close={handleChangeDayModalClose}
                closeIcon={true}
                maxWidth={'sm'}
            >
                <ShipItConfigChangeDayModal
                    onCancel={handleChangeDayModalClose}
                    onContinue={handleChangeDayModalContinue}
                />
            </Modal>
        </>
    );
};

export default ShipItConfigShipment;
