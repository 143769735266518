import React from 'react';
import { makeStyles } from '@material-ui/core';
import Link from './Link';
import Button from './Button';
import { boldWeight, small } from '../../../themes/globalConstants';

interface Props {
    className?: any;
}

const useStyles = makeStyles((theme) => ({
    button: {
        textTransform: 'none',
        fontWeight: boldWeight,
        color: theme.palette.common.white,
        fontSize: small,
        '&:hover': {
            backgroundColor: 'transparent'
        }
    }
}));

const FooterLogo = (props: Props) => {
    const classes = useStyles();
    const logoIcon = (
        <img
            src={process.env.PUBLIC_URL + '/assets/Ball_Logo_White.png'}
            alt="The Source Logo"
            height="24"
            width="24"
        />
    );

    return (
        <Link
            url="https://ball.com"
            underline="none"
            target="_blank"
            rel="noopener noreferrer"
            data-testid="ballHome"
        >
            <Button className={classes.button} startIcon={logoIcon}>
                Ball.com
            </Button>
        </Link>
    );
};

export default FooterLogo;
