import React from 'react';
import {
    makeStyles,
    ListItem,
    ListItemIcon,
    Radio,
    Divider,
    FormControlLabel
} from '@material-ui/core';
import { Account } from '../../../store/reducers/customer-context';
import { ballLtGray, ballBlue, warningLabel } from '../../../themes/globalConstants';
import AccountRadioLabel from './AccountRadioLabel';
import clsx from 'clsx';

interface Props {
    account: Account;
    showAddress?: boolean;
    includeDivider?: boolean;
    selected?: boolean;
    showWarning?: boolean;
    warningMessage?: string;
}

const useStyles = makeStyles((theme) => ({
    listItem: {
        paddingBottom: '0'
    },
    listItemWarning: {
        paddingBottom: '0',
        backgroundColor: warningLabel
    },
    radio: {
        marginBottom: '0.5em',
        color: ballLtGray,
        fontSize: 20
    },
    radioOneLine: {
        marginBottom: '0'
    },
    selected: {
        color: ballBlue
    },
    listDivider: {
        color: ballLtGray,
        marginLeft: '2em'
    }
}));

const AccountListItem = ({
    account,
    showAddress = true,
    includeDivider = true,
    selected,
    showWarning = false,
    warningMessage = ''
}: Props) => {
    const classes = useStyles();

    const targetAccount = account.accountId;

    return (
        <>
            <ListItem
                key={targetAccount}
                dense
                className={showWarning ? classes.listItemWarning : classes.listItem}
            >
                <ListItemIcon>
                    <FormControlLabel
                        value={targetAccount}
                        control={<Radio />}
                        checked={selected}
                        label={
                            <AccountRadioLabel
                                name={account.name}
                                accountId={account.accountId}
                                address1={account.address.address1}
                                city={account.address.city}
                                state={account.address.stateProvince}
                                postalCode={account.address.postalCode}
                                country={account.address.country}
                                showAddress={showAddress}
                                warningMessage={warningMessage}
                                showWarning={showWarning}
                            />
                        }
                        className={clsx(classes.radio, { [classes.radioOneLine]: !showAddress })}
                    />
                </ListItemIcon>
            </ListItem>
            {includeDivider && (
                <Divider variant="fullWidth" component="li" className={classes.listDivider} />
            )}
        </>
    );
};

export default AccountListItem;
