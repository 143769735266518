import { makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { DeliverToAccount, SelectableAccount } from '../../../store/reducers/customer-context';
import { blackWeight, paleGrey } from '../../../themes/globalConstants';
import { getAccountAddress } from '../../../utility/helpers/address-helpers';
import AccountFilter from './AccountFilter';
import AccountListItem from './AccountListItem';

interface Props {
    deliverToAccount: DeliverToAccount;
    filterText: string;
    selectedAccountId?: string;
}

const useStyles = makeStyles((theme) => ({
    copackerTitle: {
        fontWeight: blackWeight,
        color: theme.palette.secondary.main,
        backgroundColor: paleGrey,
        paddingLeft: '0.5em',
        textTransform: 'uppercase'
    }
}));

const DeliverToAccountItem = ({ deliverToAccount, filterText, selectedAccountId }: Props) => {
    const classes = useStyles();

    const [selectableAccounts, setSelectableAccounts] = useState<SelectableAccount[]>([]);
    const [anyVisible, setAnyVisible] = useState<boolean>(true);

    const setVisibility = (account: SelectableAccount, visible: boolean) => {
        const accountToEdit = selectableAccounts.find(
            (selectableAccount) => selectableAccount.accountId === account.accountId
        );
        if (accountToEdit) {
            accountToEdit.hasMatch = visible;
            setSelectableAccounts((selectableAccounts) => [
                ...selectableAccounts.filter(
                    (selectableAccount) => selectableAccount.accountId !== accountToEdit.accountId
                ),
                accountToEdit
            ]);
        }
    };

    useEffect(() => {
        const hasMatches = selectableAccounts
            .map((account) => account.hasMatch)
            .filter((value, index, self) => self.indexOf(value) === index);
        setAnyVisible(hasMatches.includes(true));
    }, [selectableAccounts]);

    useEffect(() => {
        if (deliverToAccount.copackers) {
            deliverToAccount.copackers.forEach((account) => {
                account.hasMatch = true;
                setSelectableAccounts((accounts) => [...accounts, account]);
            });
        }
    }, [deliverToAccount]);

    const copackers = (deliverToAccount: DeliverToAccount) => {
        return deliverToAccount.copackers.map((copacker, i) => {
            let selectableAccount = copacker as SelectableAccount;
            return (
                <AccountFilter
                    key={i}
                    account={selectableAccount}
                    filterText={filterText}
                    render={(account, words) => (
                        <AccountListItem
                            account={copacker}
                            showAddress={false}
                            includeDivider={
                                i === deliverToAccount.copackers.length - 1 ? false : true
                            }
                            selected={
                                selectedAccountId
                                    ? copacker.accountId === selectedAccountId
                                    : undefined
                            }
                        />
                    )}
                    setVisibility={setVisibility}
                />
            );
        });
    };

    return (
        <>
            {anyVisible && (
                <Typography className={classes.copackerTitle} data-testid={'copacker-title'}>
                    <Trans i18nKey="copacker">COPACKER</Trans>

                    {' ('}
                    {deliverToAccount.deliverToAccountId}
                    {') - '}
                    {getAccountAddress(deliverToAccount)}
                </Typography>
            )}
            {copackers(deliverToAccount)}
        </>
    );
};

export default DeliverToAccountItem;
