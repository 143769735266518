import { Grid, FormHelperText, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import {
    medium,
    ltBlueGrey_34,
    xl,
    boldWeight,
    red,
    warningLabel
} from '../../../../themes/globalConstants';
import TextInput from '../../../reusable/molecules/TextInput';
import SelectInput from '../../../reusable/molecules/SelectInput';
import FormSection from '../../../reusable/molecules/FormSection';
import { EmeaGraphics } from '../../../../store/reducers/graphic-intake';
import { useFormContext, ErrorMessage } from 'react-hook-form';
import RadioInput from '../../../reusable/molecules/RadioInput';
import {
    emeaProductContent,
    emeaProductFormulation,
    emeaProductPasteurisation
} from '../../../../static-data/dropdown-lists';

interface Props {
    isEditDraft: boolean;
    graphicData?: EmeaGraphics;
}

const useStyles = makeStyles((theme) => ({
    subsectionHeader: {
        fontSize: xl,
        fontWeight: boldWeight,
        color: theme.palette.info.dark,
        marginBottom: '2em'
    },
    label: {
        color: theme.palette.info.dark,
        fontSize: medium,
        marginBottom: '0.5em'
    },
    radioGroup: {
        marginTop: '1.5em',
        paddingLeft: '0.5em'
    },
    divider: {
        height: 5,
        backgroundColor: ltBlueGrey_34,
        margin: '1.5em 0'
    },
    helperText: {
        '& .MuiFormHelperText-root': {
            marginLeft: 0
        }
    },
    warningContainer: {
        display: 'flex',
        backgroundColor: warningLabel,
        margin: '1em 1.5em 1em 0.5em'
    },
    warningIconCtn: {
        textAlign: 'center'
    },
    warningIcon: {
        fill: red
    },
    warningLabel: {
        fontSize: medium,
        fontWeight: boldWeight
    },
    capitalize: {
        textTransform: 'capitalize'
    },
    errorText: {
        color: red
    }
}));

const ProductQuality = ({ isEditDraft, graphicData }: Props) => {
    const classes = useStyles();
    const [draftLoaded, setDraftLoaded] = useState<boolean>(false);
    const [prevBpani, setPrevBpani] = useState<string | undefined>();
    const { control, errors, watch, setValue } = useFormContext();

    const isRequired = <Trans i18nKey="required">Required</Trans>;
    const isExistingRecipe = watch('productFormulation') === 'Existing';
    const bpani = watch('bpaFree') as string;
    const selectedContries = watch('distributeCountries') as string[];

    useEffect(() => {
        if (!isEditDraft) {
            if (bpani === 'true') {
                setValue('bpaniId', 'true');
            } else if (bpani === 'false') {
                setValue('bpaniId', 'false');
            }
        } else {
            if (draftLoaded && prevBpani !== bpani) {
                setPrevBpani(bpani);
                if (bpani === 'true') {
                    setValue('bpaniId', 'true');
                } else if (bpani === 'false') {
                    setValue('bpaniId', 'false');
                }
            }
        }
    }, [bpani]);

    useEffect(() => {
        const graphicIsValid = graphicData?.requestName !== '';

        if (isEditDraft && graphicData && graphicIsValid) {
            setValue('productContent', graphicData.productContent);
            setValue('productFormulation', graphicData.productFormulation);
            setValue('productPasteurisation', graphicData.productPasteurisation);
            setValue('refDesignNum', graphicData.refDesignNum);
            setValue('bpaFree', graphicData.bpaFree?.toString());
            setValue('bpaniId', graphicData.bpaniId?.toString());
            setPrevBpani(graphicData.bpaFree?.toString());
            setDraftLoaded(true);
        }
    }, [isEditDraft, graphicData, setValue]);

    useEffect(() => {
        if (selectedContries?.indexOf('France') > -1) {
            setValue('bpaFree', 'true');
        }
    }, [selectedContries]);

    return (
        <FormSection
            testId={'product-quality-section'}
            sectionHeader={
                <Trans i18nKey="productQualitySubInfo">Product Quality Specification</Trans>
            }
        >
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                    <SelectInput
                        label={<Trans i18nKey="productContent">Product Content</Trans>}
                        name="productContent"
                        testId="productContent"
                        control={control}
                        rules={{ required: isRequired }}
                        error={errors.productContent}
                        errors={errors}
                        styles={classes.helperText}
                        selectObj={emeaProductContent}
                        helperText={
                            <Trans i18nKey="productContentHelper">
                                Select to identify the category of your product content{' '}
                            </Trans>
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <SelectInput
                        label={<Trans i18nKey="productFormulation">Product Formulation</Trans>}
                        name="productFormulation"
                        testId="productFormulation"
                        control={control}
                        rules={{ required: isRequired }}
                        error={errors.productFormulation}
                        errors={errors}
                        styles={classes.helperText}
                        selectObj={emeaProductFormulation}
                        helperText={
                            <Trans i18nKey="productFormulationHelper">
                                Determine if the product content is a New or Existing product recipe{' '}
                            </Trans>
                        }
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                    <SelectInput
                        label={
                            <Trans i18nKey="productPasteurisation">Product Pasteurisation</Trans>
                        }
                        name="productPasteurisation"
                        testId="productPasteurisation"
                        control={control}
                        rules={{ required: isRequired }}
                        error={errors.productPasteurisation}
                        errors={errors}
                        selectObj={emeaProductPasteurisation}
                        helperText={
                            <Trans i18nKey="productPasteurisationHelper">
                                Select the approximate temperature at which to pasteurise your
                                product. If your product requires Hot-Fill or Pasteurization please
                                select an option here. Special retort inks are different from
                                standard inks and will need to be used for your can production.{' '}
                            </Trans>
                        }
                    />
                </Grid>
                {(isExistingRecipe || (isEditDraft && !draftLoaded)) && (
                    <Grid item xs={6}>
                        <TextInput
                            type="text"
                            label={<Trans i18nKey="refDesignNum">Design no. to Reference</Trans>}
                            name="refDesignNum"
                            testId="refDesignNum"
                            control={control}
                            error={errors.refDesignNum}
                            errors={errors}
                            rules={{ required: isRequired }}
                            styles={classes.helperText}
                            helperText={
                                <Trans i18nKey="refDesignNumHelper">
                                    For Existing - specify your article number of the Ball design
                                    number{' '}
                                </Trans>
                            }
                            defaultValue=""
                        />
                        <FormHelperText className={classes.errorText}>
                            {errors.refDesignNum && errors.refDesignNum.type === 'required' && (
                                <ErrorMessage
                                    errors={errors}
                                    name={'refDesignNum'}
                                    message={isRequired}
                                />
                            )}
                        </FormHelperText>
                    </Grid>
                )}
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={6}>
                    <RadioInput
                        styles={classes.radioGroup}
                        label={<Trans i18nKey="bpaFree">BPANI</Trans>}
                        name="bpaFree"
                        testId="bpaFree"
                        control={control}
                        rules={{ required: isRequired }}
                        radioControls={[
                            { value: 'true', label: <Trans i18nKey="yes">Yes</Trans> },
                            { value: 'false', label: <Trans i18nKey="no">No</Trans> }
                        ]}
                        error={errors.bpaFree}
                        errors={errors}
                        defaultValue=""
                    />
                    {!errors.bpaFree && (
                        <FormHelperText>
                            <Trans i18nKey="bpaFreeHelper">
                                Select "Yes" if this design is marketed in a country requiring BPA
                                free lacquer.
                            </Trans>
                        </FormHelperText>
                    )}
                </Grid>
                <Grid item xs={6}>
                    <RadioInput
                        styles={classes.radioGroup}
                        label={<Trans i18nKey="bpaniId">BPANI Identification on Design?</Trans>}
                        name="bpaniId"
                        testId="bpaniId"
                        control={control}
                        rules={{ required: isRequired }}
                        defaultValue="false"
                        radioControls={[
                            { value: 'true', label: <Trans i18nKey="yes">Yes</Trans> },
                            { value: 'false', label: <Trans i18nKey="no">No</Trans> }
                        ]}
                        error={errors.bpaniId}
                        errors={errors}
                    />
                    {!errors.bpaniId && (
                        <FormHelperText>
                            <Trans i18nKey="bpaniIdHelper">
                                To identify your design as BPANI, Ball will add a "N" in the
                                datecode box on the artwork. This is to conform to the agreement in
                                the can making industry on BPANI identification.
                            </Trans>
                        </FormHelperText>
                    )}
                </Grid>
            </Grid>
        </FormSection>
    );
};

export default ProductQuality;
