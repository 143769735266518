import React, { useEffect, useState } from 'react';
import CustomerSurveyModal from './CustomerSurveyModal';

const CustomerSurvey = () => {
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

    useEffect(() => {
        // Search for the WalkMe tag every second for 30 seconds, cancel the search if found...
        // ...when found, add a click listener that will start searching for the 'Leave Feedback' button when the WalkMe tag is clicked
        repeatForDuration(
            (cancelSearch) => {
                const walkmeEntryElement = document.getElementById('walkme-player');

                const listenerAdded = addListenerToElement(
                    walkmeEntryElement,
                    'click',
                    addClickListenerToWalkmeFeedbackButton
                );
                if (listenerAdded) cancelSearch();
            },
            1000,
            30000
        );
    }, []);

    const addClickListenerToWalkmeFeedbackButton = () => {
        // Search for the WalkMe Feedback button every 500 ms for 5 seconds, cancel the search if found
        repeatForDuration(
            (cancelSearch: Function) => {
                const walkmeLauncherDivs = document.querySelectorAll(
                    '#walkme-footer div.walkme-icon-image-div.walkme-launcher-image-div'
                );
                const leaveFeedbackButton = Array.from(walkmeLauncherDivs.values()).find(
                    (div) => (div as HTMLElement).innerText === 'Leave Feedback'
                );

                const listenerAdded = addListenerToElement(
                    leaveFeedbackButton,
                    'click',
                    handleOpen
                );
                if (listenerAdded) cancelSearch();
            },
            200,
            4000
        );
    };

    const repeatForDuration = (
        operation: (cancelFunction: Function) => void,
        repeatInterval: number,
        duration: number
    ) => {
        let interval;
        const cancelFunction = () => {
            clearInterval(interval);
        };
        interval = setInterval(() => operation(cancelFunction), repeatInterval);
        setTimeout(() => clearInterval(interval), duration);
    };

    const addListenerToElement = (
        element: HTMLElement | Element | undefined | null,
        event: string,
        routine: () => void
    ): boolean => {
        if (!element) return false;

        // Use 'listener' attribute to signify whether the listener has already been added
        const listenerAlreadyAdded = element.getAttribute('listener') === 'true';
        if (!listenerAlreadyAdded) {
            element.addEventListener(event, routine);
            element.setAttribute('listener', 'true');
        }

        return true;
    };

    const handleOpen = () => {
        setModalIsOpen(true);
    };

    const handleClose = () => {
        setModalIsOpen(false);
    };

    return <CustomerSurveyModal isOpen={modalIsOpen} onClose={handleClose} />;
};

export default CustomerSurvey;
