import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { 
    black,
    boldWeight, 
    ltBlueGrey_6, 
    xxl, 
    xs
} from '../../../themes/globalConstants';
import WarningAlert from '../atoms/WarningAlert';
import clsx from 'clsx';

interface Props {
    showWarning: boolean;
    warningType: 'DISCARD' | 'SAVE' | 'SUBMIT';
    noMarginTop?: boolean;
}

const useStyles = makeStyles(() => ({
    informationalAlert: {
        width: '95%',
        borderRadius: '.125em',
        justifyContent: 'center',
        '& .MuiAlert-message': {
            '& .MuiTypography-root': {
                color: black,
                fontWeight: boldWeight
            }
        },
        backgroundColor: ltBlueGrey_6,
        alignItems: 'center'
    },
    warningIcon: {
        height: xxl,
        width: xxl
    },
    marginTop: {
        marginTop: xs
    }
}));

/** Example Implementation
 * 
 * <Grid container item xs={7}>
        <ImpersonationWarning showWarning={impersonationWarning} />
    </Grid>
 */
const ImpersonationWarning = ({ showWarning, warningType, noMarginTop }: Props) => {
    useTranslation();
    const classes = useStyles();

    const infoIcon = (
        <img src={process.env.PUBLIC_URL + '/assets/InfoTriangle_Icon.svg'}
            alt="Info Icon"
            className={classes.warningIcon}
        />
    );

    switch (warningType.toUpperCase()) {
        case 'DISCARD':
            return (
                <WarningAlert
                    warningMessage={<Trans i18nKey="impersonationWarningDiscard">
                    This work cannot be discarded. You are using an impersonation role that has
                    view-only access in The Source.
                    </Trans>}
                    showWarning={showWarning}
                    className={classes.informationalAlert}
                    icon={infoIcon}
                />
            );
        case 'SAVE': 
            return (
                <WarningAlert
                    warningMessage={<Trans i18nKey="impersonationWarningSave">
                    This work cannot be saved.  You are using an impersonation role that has 
                    view-only access in The Source
                    </Trans>}
                    showWarning={showWarning}
                    className={classes.informationalAlert}
                    icon={infoIcon}
                />
            );
        case 'SUBMIT':
            return (
                <WarningAlert
                    warningMessage={<Trans i18nKey="impersonationWarningSubmit">
                    This work cannot be submitted. You are using an impersonation role that has
                    view-only access in The Source.
                    </Trans>}
                    showWarning={showWarning}
                    className={classes.informationalAlert}
                    icon={infoIcon}
                />
            );
    }

    return (
        <WarningAlert
            warningMessage={<Trans i18nKey="impersonationWarningSubmit">
            This work cannot be submitted. You are using an impersonation role that has
            view-only access in The Source.
            </Trans>}
            showWarning={showWarning}
            className={clsx(classes.informationalAlert, { [classes.marginTop]: !noMarginTop })}
            icon={infoIcon}
        />
    );
};

export default ImpersonationWarning;
