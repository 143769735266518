import { Grid, makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { TradeItProduct } from '../../../../store/reducers/trade-it';
import {
    errorBackground,
    ltBlueGrey_15,
    regularWeight,
    shipRed,
    small,
    blackWeight
} from '../../../../themes/globalConstants';
import { numericRegex } from '../../../../utility/helpers/formatters';
import QuantityInputCell from '../../../reusable/atoms/QuantityInputCell';
import clsx from 'clsx';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateQuantityToTrade } from '../../../../store/actions/trade-it';
import CampaignPill from '../../../reusable/atoms/CampaignPill';

interface Props {
    product: TradeItProduct;
}

const useStyles = makeStyles((theme) => ({
    tableRowContainer: {
        '& .helper-text': {
            fontWeight: `${regularWeight} !important`,
            fontSize: small
        },
        '&:hover': {
            backgroundColor: ltBlueGrey_15
        },
        '& > td': {
            padding: '1.250em'
        },
        '& .MuiInputBase-input': {
            textAlign: 'right',
            paddingRight: '.25em'
        },
        '& .MuiTableCell-root': {
            '&:not(:last-child)': {
                paddingLeft: '1.125em'
            }
        },
        '& .normal': {
            fontWeight: `${regularWeight} !important`,
            textAlign: 'right'
        }
    },
    quantityError: {
        border: `1px solid ${shipRed}`,
        borderRight: 'none',
        backgroundColor: errorBackground
    },
    errorIcon: {
        marginRight: '0.25em'
    },
    boldText: {
        fontWeight: blackWeight
    }
}));

export default function TradeItProductsRow({ product }: Props) {
    const classes = useStyles();
    const quantityUnit = 'pallets';
    const dispatch = useDispatch();
    const maximumQuantityDigits = 6;
    const [originalShipToProductionBalanceEaches, setOriginalShipToProductionBalanceEaches] =
        useState<number | undefined>(undefined);
    const [receivingShipToProductionBalanceEaches, setReceivingShipToProductionBalanceEaches] =
        useState<number | undefined>(undefined);
    const [quantity, setQuantity] = useState<number | undefined>(undefined);
    //const [quantityPallets, setQuantityPallets] = useState<number | undefined>(undefined);
    const [originalShipToProductionBalanceUpdated, setOriginalShipToProductionBalanceUpdated] =
        useState<number | undefined>(undefined);
    const [receivingShipToProductionBalanceUpdated, setReceivingShipToProductionBalanceUpdated] =
        useState<number | undefined>(undefined);
    const [
        originalShipToProductionBalanceEachesUpdated,
        setOriginalShipToProductionBalanceEachesUpdated
    ] = useState<number | undefined>(undefined);
    const [
        receivingShipToProductionBalanceEachesUpdated,
        setReceivingShipToProductionBalanceEachesUpdated
    ] = useState<number | undefined>(undefined);
    const [quantityError, setQuantityError] = useState<boolean>(false);
    const [touchedInput, setTouchedInput] = useState<boolean>(false);

    useEffect(() => {
        if (product) {
            setQuantity(product.quantityToTrade ? product.quantityToTrade : undefined);
            setOriginalShipToProductionBalanceEaches(
                product.originalShipToProductionBalance && product.quantityPerPallet
                    ? product.originalShipToProductionBalance * product.quantityPerPallet
                    : undefined
            );
            setReceivingShipToProductionBalanceEaches(
                product.receivingShipToProductionBalance && product.quantityPerPallet
                    ? product.receivingShipToProductionBalance * product.quantityPerPallet
                    : undefined
            );
        }
    }, [product]);

    useEffect(() => {
        if (originalShipToProductionBalanceUpdated !== undefined && product.quantityPerPallet) {
            setOriginalShipToProductionBalanceEachesUpdated(
                originalShipToProductionBalanceUpdated * product.quantityPerPallet
            );
        } else {
            setOriginalShipToProductionBalanceEachesUpdated(undefined);
        }
    }, [product, originalShipToProductionBalanceUpdated]);

    useEffect(() => {
        if (receivingShipToProductionBalanceUpdated && product.quantityPerPallet) {
            setReceivingShipToProductionBalanceEachesUpdated(
                receivingShipToProductionBalanceUpdated * product.quantityPerPallet
            );
        } else {
            setReceivingShipToProductionBalanceEachesUpdated(undefined);
        }
    }, [product, receivingShipToProductionBalanceUpdated]);

    useEffect(() => {
        if (product && product.originalShipToProductionBalance) {
            if (quantity) {
                setTouchedInput(true);
                setQuantityError(quantity > product.originalShipToProductionBalance);
                const calculatedOriginalShipToProductionBalance =
                    product.originalShipToProductionBalance - quantity;
                setOriginalShipToProductionBalanceUpdated(
                    calculatedOriginalShipToProductionBalance >= 0
                        ? calculatedOriginalShipToProductionBalance
                        : undefined
                );
                setReceivingShipToProductionBalanceUpdated(
                    product.receivingShipToProductionBalance
                        ? product.receivingShipToProductionBalance + quantity
                        : quantity
                );
            }
        }
        if (!quantity) {
            setTouchedInput(false);
        }
    }, [quantity, product]);

    const handleQuantityInputBlur = () => {
        if (product.productSku) {
            dispatch(updateQuantityToTrade(product.productSku, quantity));
        }
    };

    const handleQuantityInputChange = (count: string) => {
        if (count.length === 0) {
            setQuantity(undefined);
        } else if (count.length <= maximumQuantityDigits) {
            let updatedQuantity: number | undefined = undefined;
            if (numericRegex(count)) {
                const parsedCount = parseInt(count);
                if (!isNaN(parsedCount)) {
                    updatedQuantity = parsedCount;
                }
            }
            if (updatedQuantity !== 0) {
                setQuantity(updatedQuantity);
            }
        }
    };

    const getProductDescription = () => {
        return (
            <>
            <Typography className={classes.boldText}> {product.displayName} </Typography> 
            <Typography>{product.productSku} {product.graphicIdAndVersion ? product.graphicIdAndVersion : '' }</Typography>
            </>
        )
    };

    return (
        <TableRow
            className={classes.tableRowContainer}
            data-testid={`product-${product.productSku}`}
        >
            <TableCell data-testid="product-description">
                <Typography>{getProductDescription()}</Typography>
                {!!product.campaignTiming && <CampaignPill />}
            </TableCell>
            <TableCell data-testid="customer-product-id">
                <Typography>{product.displayId}</Typography>
            </TableCell>
            <TableCell data-testid="original-product-balance">
            <Typography className={clsx({[classes.boldText]: originalShipToProductionBalanceEaches})}>
                    {product.originalShipToProductionBalance?.toLocaleString()} PL
                </Typography>
                <Typography className="helper-text">
                    {originalShipToProductionBalanceEaches
                        ? `${originalShipToProductionBalanceEaches.toLocaleString()} ea.`
                        : ''}
                </Typography>
            </TableCell>
            <TableCell data-testid="receiving-product-balance">
            <Typography className={clsx({[classes.boldText]: receivingShipToProductionBalanceEaches})}>
                    {product.receivingShipToProductionBalance?.toLocaleString()} PL
                </Typography>
                <Typography className="helper-text">
                    {receivingShipToProductionBalanceEaches
                        ? `${receivingShipToProductionBalanceEaches.toLocaleString()} ea.`
                        : ''}
                </Typography>
            </TableCell>
            <TableCell
                data-testid="quantity-input"
                className={clsx({ [classes.quantityError]: touchedInput && quantityError })}
            >
                <Grid container item justify="flex-start" alignContent="center">
                    <Grid item>
                        <QuantityInputCell
                            quantity={quantity}
                            quantityPerPallet={product.quantityPerPallet!}
                            quantityUnit={quantityUnit}
                            onBlur={handleQuantityInputBlur}
                            onChange={handleQuantityInputChange}
                            palletsLimit={6}
                            eachesLimit={12}
                            showEmptyCellOnUndefined={true}
                            hasError={touchedInput && quantityError}
                        />
                    </Grid>
                </Grid>
                {touchedInput && quantityError && (
                    <Grid container>
                        <Typography className="helper-text" data-testid="maximum-quantity-error">
                            <Trans i18nKey="quantityExceedsAvailableBalance">
                                Cannot exceed Original Ship To production balance
                            </Trans>
                        </Typography>
                    </Grid>
                )}
            </TableCell>
            <TableCell data-testid="original-product-balance-updated">
                {touchedInput && !quantityError && (
                    <>
                        <Typography className={clsx({[classes.boldText]: originalShipToProductionBalanceEachesUpdated})}>
                            {originalShipToProductionBalanceUpdated?.toLocaleString()} PL
                        </Typography>
                        <Typography className="helper-text">
                            {originalShipToProductionBalanceEachesUpdated !== undefined
                                ? `${originalShipToProductionBalanceEachesUpdated.toLocaleString()} ea.`
                                : ''}
                        </Typography>
                    </>
                )}
            </TableCell>
            <TableCell data-testid="receiving-product-balance-updated">
                {touchedInput && !quantityError && (
                    <>
                        <Typography className={clsx({[classes.boldText]: receivingShipToProductionBalanceEachesUpdated})}>
                            {receivingShipToProductionBalanceUpdated?.toLocaleString()} PL
                        </Typography>
                        <Typography className="helper-text">
                            {receivingShipToProductionBalanceEachesUpdated
                                ? `${receivingShipToProductionBalanceEachesUpdated.toLocaleString()} ea.`
                                : ''}
                        </Typography>
                    </>
                )}
            </TableCell>
        </TableRow>
    );
}
