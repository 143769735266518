import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid, makeStyles } from '@material-ui/core';
import { getContent } from '../../../store/actions/content-page';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { ContentTemplateState } from '../../../store/reducers/content-page';
import Error from '../../reusable/atoms/Error';
import { NavigationalPageTemplate } from '../../templates/NavigationalPageTemplate';
import FaqPageTemplate from './templates/FaqPageTemplate';
import ContentTemplate from './templates/ContentTemplate';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import TabbedPageTemplate from './templates/TabbedPageTemplate';
import LeftNavContentTemplate from './templates/LeftNavContentTemplate';

const useStyles = makeStyles((theme) => ({
    defaultStyles: {
        '& a': {
            color: theme.palette.primary.main
        }
    }
}));

const ContentPage = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const { cultureCode } = useTypedSelector<RegionCultureState>((store) => store.regionCulture);
    const { loading, error, contentTemplates } = useTypedSelector<ContentTemplateState>(
        (store) => store.contentPage
    );
    const classes = useStyles();

    useEffect(() => {
        // refetch on culture changing
        const contentId = params[0] && params[0] !== 'source' ? params[0] : params[1];
        dispatch(getContent(contentId));
    }, [dispatch, params, cultureCode]);

    const createBanner = () => {
        const content = contentTemplates;
        let banner = {} as any;

        if (error) {
            banner.header = 'Error';
            banner.description = error;
        } else {
            banner.header = content.header;
            banner.description = content.description;
        }

        if (content?.image && content.image.imageUrl) {
            banner.imageUrl = content.image.imageUrl;
        }

        if (content?.navigation && content.navigation.link) {
            banner.link = content.navigation.link.linkUrl;
            banner.linkContent = content.navigation.link.linkText;
        }

        if (content?.links && content.links.length !== 0) {
            banner.buttonLink = `/content${content.links[0].linkUrl}`;
            banner.buttonContent = content.links[0].linkText;
            if (content.links.length > 1) {
                banner.buttonLinkTwo = `${content.links[1].linkUrl}`;
                banner.buttonContentTwo = content.links[1].linkText;
            }
        }

        return banner;
    };

    const getPageTemplate = () => {
        switch (contentTemplates?.type) {
            case 'FAQPage':
                return <FaqPageTemplate sections={contentTemplates.sections} />;
            case 'SlidePage':
                return (
                    <LeftNavContentTemplate
                        sections={contentTemplates.sections}
                        header={contentTemplates.header}
                    />
                );
            case 'TabbedPage':
                return (
                    contentTemplates.subPages && (
                        <TabbedPageTemplate subPages={contentTemplates.subPages} />
                    )
                );
            default:
                return <ContentTemplate sections={contentTemplates.sections} />;
        }
    };

    return (
        <NavigationalPageTemplate banner={createBanner()} loading={loading}>
            <Grid container spacing={10} className={classes.defaultStyles}>
                <Grid item xs={12}>
                    {error ? <Error message={error} /> : getPageTemplate()}
                </Grid>
            </Grid>
        </NavigationalPageTemplate>
    );
};

export default ContentPage;
