import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { blackWeight } from '../../../../themes/globalConstants';
import ScrapItLegend from './ScrapItLegend';

const useStyles = makeStyles((theme) => ({
    stepWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    stepTitle: {
        fontWeight: blackWeight,
        color: theme.palette.secondary.main
    }
}));

export default function ScrapItProductsOptions() {
    const classes = useStyles();

    useTranslation();

    return (
        <Grid container justify="space-between" alignItems="flex-start">
            <Grid item className={classes.stepWrapper}>
                <Typography variant="h3" className={classes.stepTitle} data-testid="step-title">
                    <Trans i18nKey="selectQuantity">Select quantity from balances to scrap:</Trans>
                </Typography>
            </Grid>
            <Grid item>
                <ScrapItLegend />
            </Grid>
        </Grid>
    );
}
