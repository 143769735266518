import React, { ReactElement, useState, createRef, useEffect } from 'react';
import { Grid, makeStyles, Container, CircularProgress, Hidden, Paper } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import Button from '../../reusable/atoms/Button';
import { useForm, FormContext } from 'react-hook-form';
import { ltBlueGrey_8, successGreen } from '../../../themes/globalConstants';
import { useTypedSelector } from '../../../store/reducers/reducer';
import HoverNavMenu from '../../reusable/molecules/HoverNavMenu';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Activity } from '../../../utility/auth/useSecurity';
import OnboardBillingAddressSection from './components/OnboardBillingAddressSection';
import OnboardShippingAddressSection from './components/OnboardShippingAddressSection';
import OnboardShippingInformationSection from './components/OnboardShippingInformationSection';
import CancelModal from '../../reusable/molecules/CancelModal';
import OnboardingService, {
    OnboardingStepState
} from '../../../utility/services/onboarding-service';
import { appInsights } from '../../../utility/AppInsights';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import {
    billingAndShippingBuilder,
    getBillingShippingForm,
    getCountryAndState,
    getFormStateFromBillingAndShipping,
    redirectToNotFoundPage,
    searchErrorTree
} from '../../../utility/helpers/onboardHelpers';
import SingleActionModal from '../../reusable/molecules/SingleActionModal';
import { enUS } from '../../../utility/translations/locales';
import { ONBOARDING_BILLINGANDSHIPPING_DRAFT_SAVED } from '../../../store/actions/action-types';
import {
    Address,
    BillingAndShippingForm,
    OnboardingDashboardState
} from '../../../store/reducers/onboarding-dashboard';
import { defaultFormValues } from './utils/billing-and-shipping-utils';
import { GraphicIntakeState } from '../../../store/reducers/graphic-intake';
import { NCACountries } from '../../../utility/helpers/address-helpers';
import NotFoundPage from '../NotFound/NotFoundPage';
import ErrorAlert from '../../reusable/atoms/ErrorAlert';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '3em'
    },
    formSection: {
        marginBottom: '2.5em'
    },
    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '0.5em'
    },
    paper: {
        boxShadow: `0 3px 6px 0 ${ltBlueGrey_8}`,
        borderRadius: 4,
        padding: '2em',
        marginRight: '2em',
        position: 'sticky',
        top: '5em'
    },
    actionBtn: {
        height: '3.375em',
        width: '13.75em',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom'
    },
    completedIcon: {
        fill: successGreen,
        width: 23,
        height: 23
    },
    formSectionContainer: {
        display: 'flex',
        flexDirection: 'column'
    }
}));

export default function OnboardBillingAndShipping(): ReactElement {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [success, setSuccess] = useState<boolean>(false);
    const [submitClicked, setSubmitClicked] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [billingCountry, setBillingCountry] = useState<string>('');
    const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
    const [openSaveModal, setOpenSaveModal] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const { userInfo, accessToken } = useTypedSelector((state) => state.auth);
    const { billingAndShipping, accountStatus } = useTypedSelector<OnboardingDashboardState>(
        (state) => state.onboardingDashboardState
    );

    // re-using graphics state so here "image" is the zip file
    const { images } = useTypedSelector<GraphicIntakeState>((state) => state.graphicIntake);

    const methods = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        defaultValues: billingAndShipping.billingAndShippingForm
            ? getFormStateFromBillingAndShipping(billingAndShipping.billingAndShippingForm)
            : defaultFormValues
    });

    const { isValid, dirty } = methods.formState;

    useEffect(() => {
        if (success) {
            history.push('/onboard');
        }
    }, [success]);

    const onSubmit = (data) => {
        setIsSubmitting(true);
        setErrorMessage('');

        // Only send 'true' to API if we are not sending a file
        const noFile = !!images?.length ? false : true;
        let billForm: BillingAndShippingForm;
        let billingAddress: Address = data.billingAddress;
        let shippingAddress: Address = data.shippingAddress;

        const billCountryState = getCountryAndState(
            data.billingAddress!.country!,
            data.billingAddress!.state!
        );

        billingAddress.country = billCountryState.country;
        billingAddress.state = billCountryState.state;

        if (billingAddress.country === NCACountries.Mexico && !images?.length) {
            setErrorMessage(
                t('uploadFileError', 'Please upload a valid zip file before submitting')
            );
            setIsSubmitting(false);
            return;
        }

        // Shipping Address Information
        if (data.shippingSameAsBillingAddress === 'Yes') {
            shippingAddress = billingAddress;
        } else {
            const shipCountryState = getCountryAndState(
                shippingAddress!.country!,
                shippingAddress!.state!
            );
            shippingAddress!.country = shipCountryState.country;
            shippingAddress!.state = shipCountryState.state;
        }

        billForm = getBillingShippingForm(billingAddress, shippingAddress, data);
        billForm.id = billingAndShipping?.billingAndShippingForm?.id;
        billForm.accountId = accountStatus.accountId;

        OnboardingService.saveBillingAndShippingInformation(
            billForm,
            accessToken,
            OnboardingStepState.COMPLETE,
            noFile
        )
            .then((response) => {
                const submittedForm = response.data as BillingAndShippingForm;
                dispatch({
                    type: ONBOARDING_BILLINGANDSHIPPING_DRAFT_SAVED,
                    billingAndShippingForm: submittedForm
                });

                if (submittedForm.billingAddress?.country === NCACountries.Mexico) {
                    OnboardingService.submitBillShipFile(submittedForm.id!, images![0], accessToken)
                        .then((response) => {
                            setSuccess(true);
                        })
                        .catch((error) => {
                            setErrorMessage(t('formSubmissionError', 'Submission Error'));
                            if (appInsights && userInfo) {
                                const user = userInfo.email;
                                const appInsightsError =
                                    error.message +
                                    ' submitBillingAndShippingInformationFile-' +
                                    user;
                                appInsights.trackException({
                                    error: new Error(appInsightsError),
                                    severityLevel: SeverityLevel.Error
                                });
                            }
                        });
                } else {
                    setSuccess(true);
                }
            })
            .catch((error) => {
                setErrorMessage(t('formSubmissionError', 'Submission Error'));
                if (appInsights && userInfo) {
                    const user = userInfo.email;
                    const appInsightsError =
                        error.message + ' submitBillingAndShippingInformation-' + user;
                    appInsights.trackException({
                        error: new Error(appInsightsError),
                        severityLevel: SeverityLevel.Error
                    });
                }
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const onSaveDraft = () => {
        clearErrors();
        let foundPatternError = false;
        // Since we don't save files with the draft flow,
        // setting this to the default of 'false'
        const noFile = false;

        for (let key of Object.keys(methods.errors)) {
            const error = methods.errors[key];
            if (searchErrorTree(error, 0, 3, ['pattern'])) {
                foundPatternError = true;
            }
        }

        if (foundPatternError) {
            setErrorMessage(
                t(
                    'invalidDraftFormData',
                    ' Please correct or remove any invalid data before saving your draft.'
                )
            );
        } else {
            let data = methods.getValues();
            const formData = billingAndShippingBuilder(data);
            formData.id = billingAndShipping?.billingAndShippingForm?.id;
            formData.accountId = accountStatus.accountId;

            OnboardingService.saveBillingAndShippingInformation(
                formData,
                accessToken,
                OnboardingStepState.DRAFT,
                noFile
            )
                .then((response) => {
                    dispatch({
                        type: ONBOARDING_BILLINGANDSHIPPING_DRAFT_SAVED,
                        billingAndShippingForm: response.data
                    });
                    setOpenSaveModal(true);
                })
                .catch((error) => {
                    setErrorMessage(t('accountSetupCantSaveDraft', 'Failed to save draft.'));
                    if (appInsights && userInfo) {
                        const user = userInfo.email;
                        const appInsightsError =
                            error.message + ' saveBillingAndShippingInformation-' + user;
                        appInsights.trackException({
                            error: new Error(appInsightsError),
                            severityLevel: SeverityLevel.Error
                        });
                    }
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        }
    };

    const onBackAfterSave = () => {
        history.push('/onboard');
    };

    const onSaveModalClose = () => {
        setOpenSaveModal(false);
    };

    const handleConfirmCancel = () => {
        history.push('/onboard');
    };

    const handleCloseCancelModal = () => {
        setOpenCancelModal(false);
    };

    const handleOpenCancelModal = () => {
        setOpenCancelModal(true);
    };

    const handleSubmitCapture = () => {
        setSubmitClicked(true);
        methods.triggerValidation();
        if (!isValid) {
            setErrorMessage(
                t(
                    'fillOutRequiredFields',
                    'All required fields must be completed prior to submitting.'
                )
            );
        }
    };

    const clearErrors = () => {
        setErrorMessage('');
    };

    const handleUpdateBillingCountry = (billingCountry: string) => {
        setBillingCountry(billingCountry);
    };

    const sections = [
        {
            header: t('billingAddress', 'Billing Address'),
            icon: false
        },
        {
            header: t('shippingAddress', 'Shipping Address'),
            icon: false
        },
        {
            header: t('shippingInformation', 'Shipping Information'),
            icon: false
        }
    ];

    const formSections = [
        {
            section: (
                <OnboardBillingAddressSection
                    handleUpdateBillingCountry={handleUpdateBillingCountry}
                />
            )
        },
        {
            section: <OnboardShippingAddressSection />
        },
        {
            section: <OnboardShippingInformationSection billingCountry={billingCountry} />
        }
    ];

    const sectionRefs = sections.map(() => createRef<HTMLElement>());
    const header = <Trans i18nKey="accountInformation">Account Information</Trans>;
    const icon = (
        <img
            className={classes.completedIcon}
            src={process.env.PUBLIC_URL + '/assets/Confirmation_icon.svg'}
            alt="Confirmation Icon"
        />
    );

    const footerActions = (
        <>
            <Grid container item xs={2}>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="cancel-button"
                    onClick={handleOpenCancelModal}
                    className={classes.actionBtn}
                >
                    <Trans i18nKey="cancel">Cancel</Trans>
                </Button>
            </Grid>
            <Grid container item xs={2}>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="draft-button"
                    onClick={onSaveDraft}
                    className={classes.actionBtn}
                    disabled={isSubmitting || !dirty}
                >
                    <Trans i18nKey="saveProgress">Save Progress</Trans>
                </Button>
            </Grid>
            <Grid container item xs={6} alignItems="center" justify="center">
                <ErrorAlert
                    errorMessage={errorMessage}
                    showError={submitClicked && errorMessage !== ''}
                    dataTestId="validation-error"
                ></ErrorAlert>
            </Grid>
            <Grid container item xs={2}>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    data-testid="submit-button"
                    className={classes.actionBtn}
                    disabled={isSubmitting}
                    onClickCapture={handleSubmitCapture}
                    onClick={methods.handleSubmit(onSubmit)}
                >
                    <Trans i18nKey="submit">Submit</Trans>
                </Button>
            </Grid>
        </>
    );

    if (redirectToNotFoundPage(accountStatus)) {
        return <NotFoundPage />;
    }

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('accountSetup', 'Account Setup'),
                description: '',
                thinBanner: true
            }}
            activity={Activity.BillingAndShipping}
            actionFooter={{
                footerAction: footerActions,
                justify: 'space-between',
                sticky: true
            }}
            shipTos={false}
        >
            {userInfo && (
                <FormContext {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
                        <Grid container data-testid="onboard-billing-and-shipping-page">
                            <Container className={classes.container}>
                                <Grid container item xs={12}>
                                    <Grid item sm={4}>
                                        <Hidden xsDown>
                                            <Paper className={classes.paper}>
                                                <HoverNavMenu
                                                    translatedHeader={header}
                                                    readOnly={false}
                                                    sections={sections
                                                        .map((section, index) => ({
                                                            ref: sectionRefs[index],
                                                            header: section.header,
                                                            icon: section.icon,
                                                            iconSrc: icon
                                                        }))
                                                        .filter((section) => section.header)}
                                                />
                                            </Paper>
                                        </Hidden>
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        xs={8}
                                        className={classes.formSectionContainer}
                                    >
                                        {formSections.map((form, index) => {
                                            return (
                                                <section ref={sectionRefs[index]} key={index}>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className={classes.formSection}
                                                    >
                                                        {form.section}
                                                    </Grid>
                                                </section>
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                            </Container>
                            <Container>
                                {isSubmitting && (
                                    <Grid container item xs={12} className={classes.spinningLoader}>
                                        <CircularProgress />
                                    </Grid>
                                )}
                            </Container>
                        </Grid>
                    </form>
                </FormContext>
            )}
            <CancelModal
                onConfirmCancel={handleConfirmCancel}
                onClose={handleCloseCancelModal}
                open={openCancelModal}
            />
            <SingleActionModal
                title={<Trans i18nKey="saveProgress">{enUS.saveProgress}</Trans>}
                open={openSaveModal}
                subheader={
                    <Trans i18nKey="saveProgressSecondaryText">
                        {enUS.saveProgressSecondaryText}
                    </Trans>
                }
                actionButtonText={
                    <Trans i18nKey="returnToDashboard">{enUS.returnToDashboard}</Trans>
                }
                onAction={onBackAfterSave}
                onClose={onSaveModalClose}
            />
        </ProcessingPageTemplate>
    );
}
