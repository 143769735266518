import React, { ReactElement, useEffect, useState } from 'react';
import {
    Grid,
    Typography,
    makeStyles,
    Divider,
    Container,
    TextField,
    FormControl,
    FormLabel,
    FormHelperText,
    IconButton,
    CircularProgress,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core';
import { NavigationalPageTemplate } from '../../templates/NavigationalPageTemplate';
import { useTranslation, Trans } from 'react-i18next';
import { loadProductEstimatePageContent } from '../../../store/root-actions';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../store/reducers/reducer';
import {
    ProductEstimateDetailsState,
    EstimateDetails
} from '../../../store/reducers/product-estimate';
import { useQuery } from '../../../utility/helpers/query-helpers';
import Button from '../../reusable/atoms/Button';
import Image from '../../reusable/atoms/Image';
import { useForm, ErrorMessage, Controller } from 'react-hook-form';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { createAxiosHeader } from '../../../utility/helpers/axios-helpers';
import { AxiosRequestConfig } from 'axios';
import ConfirmationModal from '../../reusable/molecules/ConfirmationModal';
import ContactService, {
    EstimateMessageData,
    EstimateProductDetails
} from '../../../utility/services/contact-service';
import {
    containerMaxWidth,
    mdash,
    ballLtGray_2,
    ltBlueGrey_3
} from '../../../themes/globalConstants';
import { useHistory } from 'react-router';
import { regions } from '../../../static-data/dropdown-lists';
import { Region } from '../../../store/reducers/graphic-intake';
import { ProductPortfolioState } from '../../../store/reducers/product-portfolio';

const useStyles = makeStyles((theme) => ({
    productContainer: {
        padding: '0.5em 0.5em 2.5em 0.5em'
    },
    actionBtn: {
        height: '3.375em',
        width: '13.75em',
        marginRight: '2em',
        background: `linear-gradient(to left, transparent 50%, ${theme.palette.primary.main} 50%)`,
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom'
    },
    container: {
        maxWidth: containerMaxWidth,
        padding: 0
    },
    fieldSet: {
        border: 'none'
    },
    content: {
        display: 'flex'
    },
    imgContainer: {
        height: '13.75em',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: ballLtGray_2
    },
    image: {
        height: '11em'
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        letterSpacing: '1px',
        color: theme.palette.info.dark,
        marginTop: '0.5em'
    },
    formContent: {
        display: 'flex',
        marginTop: '2em'
    },
    fieldLabel: {
        fontSize: 14,
        fontWeight: 'bold',
        letterSpacing: '1px',
        color: theme.palette.info.dark,
        marginBottom: '0.8em'
    },
    textField: {
        marginRight: '3em'
    },
    textInput: {
        marginTop: '0.8em'
    },
    removeSection: {
        display: 'flex',
        justifyContent: 'right'
    },
    removeIcon: {
        fill: theme.palette.secondary.main
    },
    separator: {
        backgroundColor: ltBlueGrey_3,
        height: 1,
        margin: '2.5em 0'
    },
    error: {
        color: theme.palette.error.main,
        marginTop: '0.625em'
    },
    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '0.5em'
    },
    cityCountryContainer: {
        marginBottom: '4em'
    },
    country: {
        marginBottom: '1em'
    }
}));

export default function ProductEstimate(): ReactElement {
    const classes = useStyles();
    const dispatch = useDispatch();
    const query = useQuery();
    const history = useHistory();
    const ids = query.get('ids')?.split(',') || [];
    const { register, handleSubmit, errors, control } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur'
    });
    let { banner, details, loading } = useTypedSelector<ProductEstimateDetailsState>(
        (state) => state.productEstimate
    );
    const productCulture = useTypedSelector<ProductPortfolioState>(
        (store) => store.productPortfolio
    );
    const { userInfo, accessToken } = useTypedSelector((state) => state.auth);
    const { regionCode, cultureCode } = useTypedSelector<RegionCultureState>(
        (state) => state.regionCulture
    );
    const [formDetails, setFormDetails] = useState([] as EstimateDetails[]);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [submit, setSubmit] = useState(false);
    const isRequired = <Trans i18nKey="required">Required</Trans>;
    useTranslation();

    useEffect(() => {
        if (ids || regionCode !== productCulture.regionCode) {
            dispatch(loadProductEstimatePageContent(ids));
        }
    }, [dispatch, regionCode, productCulture]);

    useEffect(() => {
        setFormDetails(details);
    }, [details]);

    const onCloseConfirmation = () => {
        history.push('/product-portfolio');
    };

    const onSubmit = (data) => {
        if (success) {
            setSuccess(false);
        }
        setSubmit(true);
        let products = [] as EstimateProductDetails[];

        // Creates a product obj for the array and matches the productCode to each form obj
        data.estimateItems.forEach((item, index) => {
            const product = formDetails[index];
            products.push({
                productCode: product.productCode,
                annualQuantity: item.annualQuantity
                // endsWithProduct: item.endsWithProduct
            });
        });

        const contactRequest = {
            messageType: 'ESTIMATE',
            region: regionCode,
            email: userInfo.email,
            name: userInfo.name,
            fillCountry: data.country,
            subject: 'Product Estimate Inquiry',
            message: 'Submitting below information for an estimate',
            products: products
        } as EstimateMessageData;

        if (regionCode === Region.SA) contactRequest.fillCity = data.city;

        const axiosConfig: AxiosRequestConfig = createAxiosHeader(
            cultureCode,
            regionCode,
            accessToken
        );

        ContactService.sendEstimateMessage(contactRequest, axiosConfig)
            .then((response) => {
                setSuccess(true);
            })
            .catch((contactServiceError) => {
                setError(true);
            })
            .finally(() => {
                setSubmit(false);
            });
    };

    const removeItem = (productCode: string) => {
        details = formDetails.filter((item) => item.productCode !== productCode);
        setFormDetails(details);
    };

    const showAnnualQuantityInputAsDropdown: boolean = regionCode === Region.EU;

    return (
        <NavigationalPageTemplate
            banner={{
                header: banner?.header || '',
                description: banner?.description,
                imageUrl: banner?.image?.imageUrl || '',
                link: '/product-portfolio',
                linkContent: <Trans i18nKey="backToPortfolio">Back to Portfolio</Trans>
            }}
            loading={loading}
        >
            <Grid container data-testid="product-estimate-page">
                <Grid item xs={12} className={classes.productContainer}>
                    <Typography variant="h3" component="h1">
                        <Trans i18nKey="products">Products</Trans>
                    </Typography>
                </Grid>
                <Container className={classes.container}>
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                        {formDetails &&
                            formDetails.map((product, index) => {
                                const fieldName = `estimateItems[${index}]`;
                                return (
                                    <fieldset
                                        name={fieldName}
                                        key={fieldName}
                                        className={classes.fieldSet}
                                    >
                                        <Grid item container data-testid="estimate-form">
                                            <Grid item className={classes.content} xs={12}>
                                                <Grid item xs={3} className={classes.imgContainer}>
                                                    <Image
                                                        src={product.content?.canImage || ''}
                                                        alt={
                                                            `${product.style?.label} image` ||
                                                            'product image'
                                                        }
                                                        className={classes.image}
                                                    />
                                                </Grid>
                                                <Grid item xs={1} />
                                                <Grid item xs={9}>
                                                    <Grid item>
                                                        <Typography className={classes.title}>
                                                            {`${product.volumeML}ml ${mdash} ${product.style?.label}`}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item className={classes.formContent}>
                                                        {showAnnualQuantityInputAsDropdown && (
                                                            <Grid item xs={4}>
                                                                <FormControl
                                                                    fullWidth
                                                                    error={
                                                                        errors?.estimateItems &&
                                                                        errors.estimateItems[
                                                                            index
                                                                        ] &&
                                                                        errors.estimateItems[index]
                                                                            .annualQuantity
                                                                    }
                                                                >
                                                                    <InputLabel>
                                                                        <Trans i18nKey="desiredAnnualQuantity">
                                                                            Desired Annual Quantity
                                                                        </Trans>
                                                                    </InputLabel>
                                                                    <Controller
                                                                        as={
                                                                            <Select>
                                                                                <MenuItem>
                                                                                    &nbsp;
                                                                                </MenuItem>
                                                                                {regions
                                                                                    .find(
                                                                                        (r) =>
                                                                                            r.value ===
                                                                                            regionCode
                                                                                    )
                                                                                    ?.expectedAnnualQuantity.map(
                                                                                        (
                                                                                            quantity
                                                                                        ) => (
                                                                                            <MenuItem
                                                                                                key={
                                                                                                    quantity
                                                                                                }
                                                                                                value={
                                                                                                    quantity
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    quantity
                                                                                                }
                                                                                            </MenuItem>
                                                                                        )
                                                                                    )}
                                                                            </Select>
                                                                        }
                                                                        name={`${fieldName}.annualQuantity`}
                                                                        control={control}
                                                                        rules={{
                                                                            required: isRequired
                                                                        }}
                                                                    />
                                                                    <FormHelperText>
                                                                        <ErrorMessage
                                                                            errors={errors}
                                                                            name={`${fieldName}.annualQuantity`}
                                                                        />
                                                                    </FormHelperText>
                                                                </FormControl>
                                                            </Grid>
                                                        )}
                                                        {!showAnnualQuantityInputAsDropdown && (
                                                            <Grid
                                                                item
                                                                xs={4}
                                                                className={classes.textField}
                                                            >
                                                                <FormLabel
                                                                    className={classes.fieldLabel}
                                                                >
                                                                    <Trans i18nKey="desiredAnnualQuantity">
                                                                        Desired Annual Quantity
                                                                    </Trans>
                                                                </FormLabel>
                                                                <TextField
                                                                    aria-label="annual quantity field"
                                                                    className={classes.textInput}
                                                                    fullWidth
                                                                    type="number"
                                                                    label={
                                                                        <Trans i18nKey="enterQuantity">
                                                                            Enter Quantity
                                                                        </Trans>
                                                                    }
                                                                    name={`${fieldName}.annualQuantity`}
                                                                    inputRef={register({
                                                                        required: isRequired,
                                                                        validate: {
                                                                            hasValueGreaterThanZero:
                                                                                (value) => {
                                                                                    return (
                                                                                        value >
                                                                                            0 || (
                                                                                            <Trans i18nKey="enterValueGreaterThan">
                                                                                                Please
                                                                                                enter
                                                                                                a
                                                                                                value
                                                                                                greater
                                                                                                than
                                                                                                0
                                                                                            </Trans>
                                                                                        )
                                                                                    );
                                                                                }
                                                                        }
                                                                    })}
                                                                    error={
                                                                        errors?.estimateItems &&
                                                                        errors.estimateItems[
                                                                            index
                                                                        ] &&
                                                                        errors.estimateItems[index]
                                                                            .annualQuantity
                                                                    }
                                                                    helperText={
                                                                        <ErrorMessage
                                                                            errors={errors}
                                                                            name={`${fieldName}.annualQuantity`}
                                                                        />
                                                                    }
                                                                    variant="filled"
                                                                />
                                                            </Grid>
                                                        )}
                                                        {/* Leaving this commented out, along with associated translations, for potential future use */}
                                                        {/* <Grid item xs={5}>
                                                            <FormControl error={errors.endsWithProduct} data-testid="ends-with-product">
                                                                <FormLabel className={classes.fieldLabel}>
                                                                    <Trans i18nKey="wouldYouLikeEndsWithThisProduct">
                                                                        Would you like ends with this product?
                                                                    </Trans>
                                                                </FormLabel>
                                                                <RadioGroup
                                                                    data-testid="ends-with-product"
                                                                    row
                                                                    aria-label="ends with product"
                                                                    defaultValue="Yes"
                                                                    name={`${fieldName}.endsWithProduct`}
                                                                >
                                                                    <FormControlLabel
                                                                        value="Yes"
                                                                        control={<Radio />}
                                                                        label={<Trans i18nKey="yes">Yes</Trans>}
                                                                    />
                                                                    <FormControlLabel
                                                                        value="No"
                                                                        control={<Radio />}
                                                                        label={<Trans i18nKey="no">No</Trans>}
                                                                    />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </Grid> */}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {formDetails.length > 1 && (
                                                <Grid
                                                    container
                                                    item
                                                    xs={12}
                                                    alignItems="center"
                                                    justify="flex-end"
                                                    data-testid="remove-icon"
                                                >
                                                    <Typography variant="h5">
                                                        <Trans i18nKey="remove">Remove</Trans>
                                                    </Typography>
                                                    <IconButton
                                                        aria-label="remove icon"
                                                        className={classes.removeIcon}
                                                        onClick={(e) =>
                                                            removeItem(product.productCode)
                                                        }
                                                    >
                                                        <DeleteOutlinedIcon />
                                                    </IconButton>
                                                </Grid>
                                            )}
                                            <Grid item xs={12}>
                                                <Divider className={classes.separator} />
                                            </Grid>
                                        </Grid>
                                    </fieldset>
                                );
                            })}
                        {submit && (
                            <Grid container item xs={12} className={classes.spinningLoader}>
                                <CircularProgress />
                            </Grid>
                        )}
                        <div className={classes.cityCountryContainer}>
                            <Grid
                                container
                                item
                                xs={12}
                                alignItems="center"
                                justify="center"
                                className={classes.country}
                            >
                                <Grid item xs={2}>
                                    <FormLabel className={classes.fieldLabel}>
                                        <Trans i18nKey="yourCountry">Your Fill Country</Trans>
                                    </FormLabel>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth error={errors.country}>
                                        <InputLabel>
                                            <Trans i18nKey="country">Country</Trans>
                                        </InputLabel>
                                        <Controller
                                            as={
                                                <Select inputProps={{ 'data-testid': 'country' }}>
                                                    <MenuItem>&nbsp;</MenuItem>
                                                    {regions
                                                        .find((r) => r.value === regionCode)
                                                        ?.countries.map((country) => (
                                                            <MenuItem
                                                                key={country.value}
                                                                value={country.value}
                                                            >
                                                                {country.name}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            }
                                            name="country"
                                            control={control}
                                            rules={{ required: isRequired }}
                                        />
                                        <FormHelperText>
                                            <ErrorMessage errors={errors} name="country" />
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {regionCode === Region.SA && (
                                <Grid container item xs={12} alignItems="center" justify="center">
                                    <Grid item xs={2}>
                                        <FormLabel className={classes.fieldLabel}>
                                            <Trans i18nKey="yourCity">Your City of Fill</Trans>
                                        </FormLabel>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl fullWidth error={errors.city}>
                                            <TextField
                                                aria-label="city"
                                                className={classes.textInput}
                                                fullWidth
                                                label={<Trans i18nKey="city">City</Trans>}
                                                name="city"
                                                inputRef={register({ required: isRequired })}
                                                error={errors.city}
                                            />
                                            <FormHelperText>
                                                <ErrorMessage errors={errors} name="city" />
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            )}
                        </div>
                        <Grid container item xs={12} alignItems="center" justify="center">
                            <Grid item>
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="outlined"
                                    data-testid="submit-button"
                                    className={classes.actionBtn}
                                    disabled={submit || success}
                                >
                                    <Trans i18nKey="submit">Submit</Trans>
                                </Button>
                            </Grid>
                        </Grid>
                        {error && (
                            <Grid container item xs={12} alignItems="center" justify="center">
                                <Typography className={classes.error}>
                                    <Trans i18nKey="estimateFailedToSend">
                                        Estimate failed to send, please try again later.
                                    </Trans>
                                </Typography>
                            </Grid>
                        )}
                    </form>
                </Container>
                <ConfirmationModal
                    title={<Trans i18nKey="thankYou">Thank You</Trans>}
                    open={success}
                    logo={true}
                    subheader={
                        <>
                            <Trans i18nKey="yourRequestHasBeenSubmitted">
                                Your request has been submitted!
                            </Trans>{' '}
                            <Trans i18nKey="someoneFromOurTeamWillBeInTouchSoon">
                                Someone from our team will be in touch soon.
                            </Trans>
                        </>
                    }
                    submitText={<Trans i18nKey="backToHome">Back to Home</Trans>}
                    navigationLink="/"
                    onClose={onCloseConfirmation}
                />
            </Grid>
        </NavigationalPageTemplate>
    );
}
