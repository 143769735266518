import React, { useState } from 'react';
import {
    makeStyles,
    Typography,
    TableRow,
    TableCell,
    Grid,
    Input,
    TextField,
    InputAdornment
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import {
    ballGray,
    ballLtGray,
    black,
    boldWeight,
    ltBlueGrey_34,
    white
} from '../../../themes/globalConstants';
import fillerLines from '../../pages/ShipItConfiguration/components/FillerLineList';
import { Autocomplete } from '@material-ui/lab';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
    releaseNumber: string;
    fillerLine: string;
    reference: string;
    deliveryInstructions: string;
    active: boolean;
    readOnly: boolean;
    handleUpdateItem: (fieldValue: string, fieldName: string) => void;
    updateOptionalField: (fieldValue: string, fieldName: string) => void;
    handleSelectChange: (fieldValue: string) => void;
    referenceFieldName?: string;
}

const useStyles = makeStyles((theme) => ({
    altRow: {
        backgroundColor: ltBlueGrey_34,
        '& > td': {
            borderBottomColor: 'white'
        },
        '& > td:nth-child(n+3)': {
            textAlign: 'right'
        }
    },
    hidden: {
        display: 'none'
    },
    display: {
        display: 'table-row'
    },
    subtitle: {
        color: ballGray
    },
    optionalFieldName: {
        color: ballGray,
        textTransform: 'uppercase',
        fontSize: '0.75em'
    },
    optionalFieldValue: {
        color: black,
        fontWeight: boldWeight,
        fontSize: '0.75em',
        textTransform: 'uppercase'
    },
    textField: {
        border: `2px solid ${ballLtGray}`,
        borderRadius: '.125em',
        width: '90%',
        backgroundColor: white
    },
    alignLeft: {
        '& .MuiInputBase-input': {
            textAlign: 'left',
            paddingLeft: '0.375em'
        }
    },
    fillerLineSelect: {
        '& .MuiFilledInput-underline ': {
            padding: '0 !important',
            '& > input': {
                padding: '0.375em !important'
            },
            '& .MuiInputAdornment-root': {
                marginTop: 0,
                cursor: 'pointer'
            }
        }
    },
    selectWrapper: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiInputBase-root': {
            backgroundColor: theme.palette.common.white,
            border: `2px solid ${ballLtGray}`,
            borderRadius: '.125em',
            '&:before': {
                border: 0
            },
            '&.Mui-focused': {
                '& .MuiSvgIcon-root': {
                    transform: 'rotate(-180deg)'
                }
            },
            '& .MuiSvgIcon-root': {
                fill: theme.palette.primary.main,
                transition: 'transform 200ms'
            }
        }
    },
    autoComplete: {
        width: '90%'
    }
}));

export default function ShipmentExpandableSection({
    releaseNumber,
    fillerLine,
    reference,
    deliveryInstructions,
    active,
    readOnly,
    handleUpdateItem,
    updateOptionalField,
    handleSelectChange,
    referenceFieldName = 'reference'
}: Props) {
    const classes = useStyles();
    const [fillerOpen, setFillerOpen] = useState<boolean>(false);
    const sortFillerLines = Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
    const { t } = useTranslation();

    const getReadOnlyFields = (): JSX.Element | undefined => {
        return (
            <>
                {releaseNumber && (
                    <>
                        <span className={classes.optionalFieldName}>
                            {t('releaseNumberPDF', 'Release #')}
                        </span>
                        <span
                            className={classes.optionalFieldValue}
                            data-testid="readonly-relase-number"
                        >
                            {' '}
                            {releaseNumber}
                        </span>
                    </>
                )}

                {releaseNumber && (fillerLine || reference || deliveryInstructions) && (
                    <span className={classes.optionalFieldName}> | </span>
                )}
                {fillerLine && (
                    <>
                        <span className={classes.optionalFieldName}>
                            {t('fillerLineNumber', 'Filler Line #')}
                        </span>
                        <span
                            className={classes.optionalFieldValue}
                            data-testid="readonly-filler-line"
                        >
                            {' '}
                            {fillerLine}
                        </span>
                    </>
                )}
                {fillerLine && (reference || deliveryInstructions) && (
                    <span className={classes.optionalFieldName}> | </span>
                )}
                {reference && (
                    <>
                        <span className={classes.optionalFieldName}>
                            {t('referenceNumberPDF', 'Reference #')}
                        </span>
                        <span
                            className={classes.optionalFieldValue}
                            data-testid="readonly-reference-number"
                        >
                            {' '}
                            {reference}
                        </span>
                    </>
                )}

                {reference && deliveryInstructions && (
                    <span className={classes.optionalFieldName}> | </span>
                )}
                {deliveryInstructions && (
                    <>
                        <span className={classes.optionalFieldName}>
                            {t('deliveryInstructions', 'Delivery Instructions')}
                        </span>
                        <span
                            className={classes.optionalFieldValue}
                            data-testid="readonly-delivery-instructions"
                        >
                            {' '}
                            {deliveryInstructions}
                        </span>
                    </>
                )}
            </>
        );
    };

    return (
        <>
            {!readOnly && (
                <TableRow
                    className={clsx(classes.altRow, classes.hidden, {
                        [classes.display]: active
                    })}
                >
                    <TableCell colSpan={7}>
                        <Grid container justify="flex-end">
                            <Grid item xs={2}>
                                <Typography variant="subtitle2" className={classes.subtitle}>
                                    <Trans i18nKey="releaseNumberPDF">Release #</Trans>
                                </Typography>
                                <Input
                                    type="text"
                                    data-testid="release-num-input"
                                    className={clsx(classes.textField, classes.alignLeft)}
                                    disableUnderline={true}
                                    name="releaseNumber"
                                    onBlur={(e) => handleUpdateItem(e.target.value, e.target.name)}
                                    onChange={(e) =>
                                        updateOptionalField(e.target.value, e.target.name)
                                    }
                                    value={releaseNumber}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="subtitle2" className={classes.subtitle}>
                                    <Trans i18nKey="fillerLineNumber">Filler Line #</Trans>
                                </Typography>
                                <Grid
                                    className={classes.selectWrapper}
                                    onFocus={() => setFillerOpen(true)}
                                    onBlur={() => setFillerOpen(false)}
                                >
                                    <Autocomplete
                                        id="filler-line-select"
                                        className={classes.autoComplete}
                                        options={fillerLines.sort(sortFillerLines.compare)}
                                        getOptionLabel={(line) => line}
                                        onChange={(e: any, value: string) => {
                                            handleSelectChange(value);
                                            handleUpdateItem(value, 'fillerLine');
                                        }}
                                        data-testid="filler-line-input"
                                        value={fillerLine}
                                        disableClearable
                                        open={fillerOpen}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                name="fillerLine"
                                                aria-label="Filler Line Number"
                                                className={classes.fillerLineSelect}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <InputAdornment position="start">
                                                            <ExpandMoreIcon />
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="subtitle2" className={classes.subtitle}>
                                    <Trans i18nKey="referenceNumberPDF">Reference #</Trans>
                                </Typography>
                                <Input
                                    type="text"
                                    data-testid="reference-num-input"
                                    className={clsx(classes.textField, classes.alignLeft)}
                                    disableUnderline={true}
                                    name={referenceFieldName}
                                    onBlur={(e) => handleUpdateItem(e.target.value, e.target.name)}
                                    onChange={(e) =>
                                        updateOptionalField(e.target.value, e.target.name)
                                    }
                                    value={reference}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="subtitle2" className={classes.subtitle}>
                                    <Trans i18nKey="deliveryInstructions">
                                        Delivery Instructions
                                    </Trans>
                                </Typography>
                                <Input
                                    type="text"
                                    data-testid="delivery-instructions-input"
                                    className={clsx(classes.textField, classes.alignLeft)}
                                    disableUnderline={true}
                                    name="deliveryInstructions"
                                    onBlur={(e) => handleUpdateItem(e.target.value, e.target.name)}
                                    onChange={(e) =>
                                        updateOptionalField(e.target.value, e.target.name)
                                    }
                                    value={deliveryInstructions}
                                />
                            </Grid>
                        </Grid>
                    </TableCell>
                </TableRow>
            )}
            {readOnly && (
                <TableRow
                    className={clsx(classes.altRow, classes.hidden, {
                        [classes.display]: active
                    })}
                >
                    <TableCell colSpan={7}>
                        <Grid container justify="flex-start">
                            <Grid item xs={12}>
                                <Typography>{getReadOnlyFields()}</Typography>
                            </Grid>
                        </Grid>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
}
