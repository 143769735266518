import {
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormHelperText,
    makeStyles
} from '@material-ui/core';
import React, { ReactElement, useState } from 'react';
import { ErrorMessage, Controller } from 'react-hook-form';
import Tooltip from '../atoms/Tooltip';
import clsx from 'clsx';
import { ballBlack, brightGrey } from '../../../themes/globalConstants';

interface Props {
    label: ReactElement;
    name: string;
    testId: string;
    control: any;
    rules?: any;
    radioControls: any;
    error?: boolean;
    errors?: any;
    styles?: any;
    defaultValue?: string;
    tooltip?: ReactElement;
    stacked?: boolean;
    disabled?: boolean;
    sideLabel?: boolean;
    small?: boolean;
    enableBlank?: boolean;
    helperText?: ReactElement;
}

const useStyles = makeStyles((theme) => ({
    standard: {
        color: brightGrey,
        '& .MuiFormGroup-root': {
            color: brightGrey
        },
        '& .MuiFormLabel-root': {
            color: ballBlack
        }
    },
    stacked: {
        '& .MuiFormControlLabel-root': {
            '&:not(:last-child)': {
                paddingBottom: '0.25em'
            }
        },

        '& .MuiRadio-root': {
            padding: '0',
            paddingRight: '0.25em'
        }
    },
    sideLabel: {
        '&.MuiFormControl-root': {
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 0
        },
        '& .MuiFormLabel-root': {
            paddingRight: '16px'
        }
    },
    small: {
        '& .MuiSvgIcon-root': {
            fontSize: 14
        }
    }
}));

const RadioInput = (props: Props) => {
    const styles = useStyles();
    const error = props.error !== undefined;
    const [oldValue, setOldValue] = useState(props.defaultValue);

    const handleClick = (event: any) => {
        if (props.enableBlank) {
            if (oldValue === event.target.value) {
                props.control.setValue(props.name, '');
                setOldValue('');
            } else {
                props.control.setValue(props.name, event.target.value);
                setOldValue(event.target.value);
            }
        } else {
            props.control.setValue(props.name, event.target.value);
        }
    };

    return (
        <FormControl
            fullWidth
            error={error}
            className={clsx(props.styles, {
                [styles.stacked]: props.stacked,
                [styles.sideLabel]: props.sideLabel,
                [styles.small]: props.small,
                [styles.standard]: true
            })}
        >
            <FormLabel>
                {props.label}
                {props.tooltip && (
                    <Tooltip content={props.tooltip} ariaLabel={`${props.name}-tooltip`} />
                )}
            </FormLabel>
            <Controller
                as={
                    <RadioGroup row={!props.stacked} data-testid={props.testId}>
                        {props.radioControls.map((radioOption) => {
                            return (
                                <FormControlLabel
                                    key={radioOption.value}
                                    value={radioOption.value}
                                    control={
                                        <Radio
                                            disabled={props.disabled}
                                            data-testid={`${props.testId}-${radioOption.value}`}
                                            onClick={handleClick}
                                        />
                                    }
                                    label={radioOption.label}
                                />
                            );
                        })}
                    </RadioGroup>
                }
                name={props.name}
                control={props.control}
                rules={props.rules}
                defaultValue={props.defaultValue}
            />
            {!error && props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
            <FormHelperText data-testid={error ? `${props.name}-error` : ''}>
                <ErrorMessage errors={props.errors} name={props.name} />
            </FormHelperText>
        </FormControl>
    );
};

export default RadioInput;
