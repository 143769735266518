import { Grid, makeStyles, Collapse, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
    boldWeight,
    blackWeight,
    large,
    black,
    successGreen,
    medium,
    xl,
    lato
} from '../../../../themes/globalConstants';
import PlanItBulkReviewOrderSectionProducts from './PlanItBulkReviewOrderSectionProducts';
import { ToggleAll } from '../../../../utility/helpers/order-helpers';
import { useDispatch } from 'react-redux';
import { updateExpand } from '../../../../store/actions/planit-bulk-atm';
import { MakeItBulkATMOrderSectionHeader } from '../../../reusable/molecules/MakeItBulkATMOrderSectionHeader';
import {
    PlanItBulkATMLineItem,
    PlanItBulkATMLinesBySnoSku
} from '../../../../store/reducers/planit-bulk-atm';

interface Props {
    order: PlanItBulkATMLinesBySnoSku;
    shipToId: number;
    isActive: boolean;
    expand: ToggleAll;
    hasMultiplePersonas: boolean;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginTop: '1em',
        position: 'relative',
        '& h2': {
            fontSize: xl,
            fontFamily: lato,
            fontWeight: blackWeight,
            color: theme.palette.secondary.main
        }
    },
    collapse: {
        width: '100%'
    },
    quantityContainer: {
        alignContent: 'center'
    },
    quantityTitleBlack: {
        fontWeight: blackWeight,
        color: theme.palette.secondary.main
    },
    quantityBlack: {
        fontWeight: blackWeight,
        color: black
    },
    quantityTitleBold: {
        fontWeight: boldWeight,
        color: black
    },
    quantityBold: {
        fontWeight: boldWeight,
        color: black
    },
    largeFont: {
        fontSize: large
    },
    mediumFont: {
        fontSize: medium
    },
    icon: {
        width: '2em',
        height: '1.8em',
        position: 'absolute'
    },
    completedIcon: {
        fill: successGreen
    },
    addProductGrid: {
        width: '54.75em',
        margin: '0 auto 0 auto',
        display: 'none',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    showProductGrid: {
        display: 'block'
    },
    subheaderPaper: {
        marginTop: '2em',
        padding: '1em',
        borderRadius: 2,
        width: '100%'
    }
}));

const PlanItBulkReviewOrderSection = ({
    order,
    shipToId,
    isActive,
    expand,
    hasMultiplePersonas = false
}: Props) => {
    const classes = useStyles();
    const [openDrawer, setOpenDrawer] = useState<boolean>();
    const dispatch = useDispatch();
    const [product, setProduct] = useState<PlanItBulkATMLineItem>();

    const handleToggleExpand = () => {
        dispatch(updateExpand(shipToId, order.snoSku, order.weekStart));
        setOpenDrawer(!openDrawer);
    };

    useEffect(() => {
        if (expand.toggle) {
            setOpenDrawer(true);
        } else if (!expand.toggle) {
            setOpenDrawer(false);
        }
    }, [expand]);

    useEffect(() => {
        setOpenDrawer(isActive);
    }, [isActive]);

    useEffect(() => {
        if (order.lines.length) {
            setProduct(order.lines[0]);
        }
    }, [order]);

    return (
        <Grid container>
            <Paper
                elevation={2}
                className={classes.subheaderPaper}
                key={`${shipToId}-${order.snoSku}`}
                data-testid="atm-order-section"
            >
                <Grid container xs={12}>
                    <Grid container item xs={5} justify="flex-start">
                        <MakeItBulkATMOrderSectionHeader
                            product={product}
                            shipToId={shipToId}
                            handleToggleExpand={handleToggleExpand}
                            openDrawer={openDrawer}
                            showCampaignPill={true}
                        />
                    </Grid>
                    <Grid
                        container
                        item
                        xs={7}
                        justify="flex-end"
                        className={classes.quantityContainer}
                    ></Grid>
                </Grid>
                <Collapse
                    in={openDrawer}
                    className={classes.collapse}
                    data-testid="collapse-section"
                >
                    <Grid>
                        <PlanItBulkReviewOrderSectionProducts
                            lines={order.lines}
                            hasMultiplePersonas={hasMultiplePersonas}
                        />
                    </Grid>
                </Collapse>
            </Paper>
        </Grid>
    );
};

export default PlanItBulkReviewOrderSection;
