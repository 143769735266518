import React, { ReactNode } from 'react';
import { BottomNavigation, makeStyles, Grid } from '@material-ui/core';
import { containerMaxWidth } from '../../../themes/globalConstants';

interface Props {
    footerAction: ReactNode;
    justify?: 'center' | 'flex-start' | 'flex-end' | 'space-around' | 'space-between';
    sticky?: boolean;
}

const useStyles = makeStyles((theme) => ({
    footer: {
        marginTop: '3em',
        width: '100%'
    },
    maxWidth: {
        maxWidth: containerMaxWidth,
        paddingRight: '1.25em'
    },
    stickyFooter: {
        marginTop: '3em',
        width: '100%',
        position: 'sticky',
        bottom: 0,
        zIndex: 100,
        boxShadow: `rgba(0, 0, 0, 0.3) 0px 1px 5px 0px`,
        padding: '0.4em 0 0.4em 0',
        height: 'auto'
    }
}));

const ActionFooter = ({ footerAction, justify, sticky }: Props) => {
    const classes = useStyles();

    return (
        <BottomNavigation className={sticky ? classes.stickyFooter : classes.footer}>
            <Grid container justify={justify} alignItems="center" className={classes.maxWidth}>
                {footerAction}
            </Grid>
        </BottomNavigation>
    );
};

export default ActionFooter;
