import React from 'react';
import { makeStyles, Typography, TableRow, TableCell } from '@material-ui/core';
import { blackWeight } from '../../../../themes/globalConstants';
import { OrderProductTableRow } from '../../../../store/reducers/orders-dashboard';
import { getUtcMidnight } from '../../../../utility/helpers/date-helpers';
import {
    constructCanDescription,
    constructEndDescription,
    getGraphicIdAndVersion,
    isProductCanType,
    isProductEndType
} from '../../../../utility/helpers/order-helpers';

interface Props {
    item: OrderProductTableRow;
}

const useStyles = makeStyles((theme) => ({
    tableRowContainer: {
        '& p:first-of-type': {
            fontWeight: blackWeight
        }
    }
}));

export default function MakeItConfirmationRow({ item }: Props) {
    const classes = useStyles();
    const canBottleDesc = constructCanDescription(item);
    const endDesc = constructEndDescription(item);
    const requestedDate = getUtcMidnight(item.requestedDate).format('MM/DD/YYYY');
    const isEndType = isProductEndType(item.type);
    const isCanType = isProductCanType(item.type);

    return (
        <TableRow className={classes.tableRowContainer} data-testid={`product-${item.displayId}`}>
            <TableCell>
                <Typography>{item.customerProductId ? item.customerProductId : "-"}</Typography>
                <Typography data-testid="display-id">{item.displayId}</Typography>
                <Typography data-testid="graphic-id">
                    {getGraphicIdAndVersion(item.type, item.graphicIdAndVersion)}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography data-testid="display-name">{item.displayName}</Typography>
                {isEndType && <Typography data-testid="end-desc">{endDesc}</Typography>}
                {isCanType && <Typography data-testid="can-desc">{canBottleDesc}</Typography>}
            </TableCell>
            <TableCell>
                <Typography data-testid="coating">{item.coating}</Typography>
            </TableCell>
            <TableCell>
                <Typography data-testid="requested-date">{requestedDate}</Typography>
            </TableCell>
            <TableCell>
                <Typography data-testid="quantity-ordered">
                    {item.palletQuantity?.toLocaleString()}
                    {' PL'}
                </Typography>
                {item.quantityPerPallet && item.palletQuantity && <Typography data-testid="quantity-ordered-eaches">
                    {(item.quantityPerPallet * item.palletQuantity).toLocaleString()}
                    {' ea.'}
                </Typography>}
            </TableCell>
        </TableRow>
    );
}
