import { OrderProductTableRow } from '../../../../store/reducers/orders-dashboard';
import moment, { unitOfTime, Moment } from 'moment';
import { convertTimeStampToMT } from '../../../../utility/helpers/date-helpers';

const isoWeek: string = 'isoWeek';

export function getLineIsWithinCampaign(line: OrderProductTableRow) {
    if (line.campaignTiming) {
        if (line.campaignRuns && line.campaignRuns.length) {
            const today = moment().startOf('day');

            return line.campaignRuns.some((campaignRun) => {
                const start = moment(campaignRun.orderStartDate).startOf('day');
                const end = moment(campaignRun.orderEndDate).startOf('day');

                return today.isBetween(start, end, undefined, '[]');
            });
        } else {
            return false;
        }
    }

    return true;
}

/**
 * Gets the Monday for the week of the given date.
 * If the date is Sunday, it will return the Monday six days ago, as Monday is
 * treated as the start of the week.
 * @param date
 * @returns
 */
export function getMondayOfWeek(date: Moment): Moment {
    return date.startOf(isoWeek as unitOfTime.StartOf);
}

/**
 * Returns lead time first available date.  Default start date is today.  Another date can be provided as the start date, if needed.
 * @param leadTimeInDays Ship To lead time.
 * @param startDate Optional.  Defaults to today.
 * @returns
 */
export function getMakeItFirstAvailableDate(
    leadTimeInDays: number,
    startDate: Moment = moment()
): Moment {
    const mondayOfStartDate = getMondayOfWeek(convertTimeStampToMT(startDate));

    // Get first available date.
    const lockPeriodEnd = mondayOfStartDate
        .add(leadTimeInDays + 7, 'days')
        .day(1)
        .startOf('day')
        .add(1, 'minute');

    return lockPeriodEnd;
}
