import React, { useState, useEffect } from 'react';
import { makeStyles, Typography, Grid, Paper } from '@material-ui/core';
import { blackWeight, containerMaxWidth, xxl } from '../../../../themes/globalConstants';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { getMediaPath } from '../../../../utility/helpers/content-helpers';
import { Trans, useTranslation } from 'react-i18next';
import { Flags } from '../../../../utility/helpers/feature-flag';
import { Region } from '../../../../store/reducers/graphic-intake';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: '2em',
        marginTop: '3em',
        borderRadius: 4,
        maxWidth: containerMaxWidth
    },
    header: {
        fontSize: xxl,
        fontWeight: blackWeight,
        color: theme.palette.secondary.main
    },
    details: {
        display: 'block'
    },
    expandIcon: {
        marginLeft: '1em',
        fontSize: 30
    },
    expandedIcon: {
        transform: 'rotate(180deg)'
    },
    description: {
        width: '50%',
        marginTop: '1em'
    },
    imageContainer: {
        marginTop: '1em'
    },
    timelineImg: {
        width: '100%',
        height: '100%'
    }
}));

const SubheaderPanel = () => {
    const classes = useStyles();
    const [imageUrl, setImageUrl] = useState<string>('');
    const { regionCode, cultureCode } = useTypedSelector<RegionCultureState>(
        (state) => state.regionCulture
    );
    const [graphicsProcessKey, setGraphicsProcessKey] = useState<string>('graphicsProcess');
    const [graphicsNoteKey, setGraphicsNoteKey] = useState<string>('graphicsNote');

    useTranslation();

    useEffect(() => {
        // Handle inconsistencies in Kentico file paths for EMEA
        const imagePath =
            regionCode === Region.EU
                ? 'Regions/EU/Image%20Library/General/Graphics'
                : 'Image%20Library';
        const regionPath = regionCode === Region.EU ? 'EMEA' : regionCode;
        const culturePath = ['es-ES','es-MX'].includes(cultureCode) ? 'es_ES' : cultureCode;

        setImageUrl(
            getMediaPath(
                `/CustomerPortalCMS/media/customer-portal-media/${imagePath}/graphics_timeline_${regionPath}_${culturePath}.jpg?ext=.jpg`
            )
        );

        const region = regionCode === Region.EU ? 'Emea' : '';
        setGraphicsNoteKey(`graphicsNote${region}`);
        setGraphicsProcessKey(`graphicsProcess${region}`);
    }, [regionCode, cultureCode]);

    return (
        <Flags
            authorizedFlags={['GraphicsSummary']}
            renderOn={() => (
                <Paper
                    elevation={2}
                    className={classes.root}
                    data-testid={'graphics-subheader-panelqq'}
                >
                    <Typography className={classes.header}>
                        <Trans i18nKey={'learnAboutGraphics'}>Learn About Our Graphics</Trans>
                    </Typography>
                    <Grid item xs={10} className={classes.description}>
                        <Typography variant="body1" data-testid="graphics-process-text">
                            <Trans i18nKey={graphicsProcessKey}>
                                At Ball we have a dependable process for ensuring that your product
                                is created and delivered with the highest quality and care. Below is
                                a timeline to better help you understand the stages of our graphics
                                process.
                            </Trans>
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} className={classes.description}>
                        <Typography variant="body1" data-testid="graphics-process-note">
                            <Trans i18nKey={graphicsNoteKey}>
                                Note: If a vendor submitted your graphic, you will be alerted when
                                your graphic is received and when your graphic is completed.
                            </Trans>
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} className={classes.imageContainer}>
                        <img
                            data-testid="feature-image"
                            src={imageUrl}
                            alt="Cannot load source"
                            className={classes.timelineImg}
                        />
                    </Grid>
                </Paper>
            )}
        />
    );
};

export default SubheaderPanel;
