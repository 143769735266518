import {
    PRODUCT_UPDATE_FACET,
    PRODUCT_UPDATE_SELECTED_PRODUCT,
    PRODUCT_UPDATE_RESULTS,
    PRODUCT_UPDATE_VIEW,
    PRODUCT_CLEAR_FACETS,
    PRODUCT_SELECTED,
    PRODUCT_LOADED,
    PRODUCT_LOADING,
    PRODUCT_LOADING_ERROR,
    PRODUCT_LOAD_FACETS,
    PRODUCT_SET_CULTURE,
    PRODUCT_LOAD_FACETS_ERROR,
    PRODUCT_LOADED_BANNER,
    PRODUCT_LOADING_FACETS,
    PRODUCT_PORTFOLIO_CONTENT_LOADED,
    PRODUCT_PORTFOLIO_CONTENT_ERROR,
    PRODUCT_CLEAR_SELECTED_PRODUCT
} from '../actions/action-types';
import { ViewType } from '../../utility/helpers/filter-helpers';

export interface Facets {
    styles?: Facet[];
    sizes?: Facet[];
    features?: Facet[];
    ends?: Facet[];
}

export interface Facet {
    facetCode: string;
    label: string;
    selected?: boolean;
}

export interface SelectedFacet {
    category: string;
    value: Facet[];
}

export interface SelectedFacets {
    styles?: string[];
    sizes?: string[];
    features?: string[];
    ends?: string[];
}

export interface ProductFilter {
    category: string;
    name: string;
    selected: boolean;
}

export interface ProductStyle {
    styleCode: string;
    label: string;
    sequence: number;
    products: Product[];
}

export interface ProductSizeRange {
    sizeRangeCode: string;
    label: string;
    sequence: number;
    sizeRangeRegions: string;
}

export interface ProductContent {
    description?: string;
    headerImage?: string;
    // used only for can specification (NCA and SA)
    canSpecificationDocument?: string;
    // used for dielines (NCA), custom design guide (EMEA), and print guides (SA)
    dielineDocument?: string;
    // used only for repro studio guide (EMEA)
    studioGuideDocument?: string;
    canImage?: string;
    productCode?: string;
    schematicCanImage?: string;
}

export interface ProductFeature {
    featureCode: string;
    type: string;
    category: string;
    label: string;
    sequence: number;
    productPortfolio: boolean;
}

export interface Beverage {
    featureCode: string;
    type: string;
    category: string;
    label: string;
    sequence: number;
    recommended: boolean;
}

export interface ProductEnd {
    endCode: string;
    regionCode?: string;
    label: string;
    diameter: number;
    sequence: number;
    features: EndFeature[];
}

export interface EndFeature {
    featureCode: string;
    type: string;
    category: string;
    label: string;
    sequence: number;
    recommended: boolean;
}

export interface ProductVolume {
    productCode: string;
    style: string;
    volumeOz: number;
    volumeML: number;
    prettySizes?: string;
    prettyOz?: string;
    prettyML?: string;
}

export interface CampaignRun {
    campaignRunId: number;
    productCode?: string;
    productDescription?: string;
    orderStartDate: string;
    orderEndDate: string;
    firstAvailableOrderDate: string;
    leadTimeWeeks: number;
}

export interface MoqData {
    palletFloor: number;
    palletCeiling: number;
    fee: number;
}

export interface Product {
    productSku?: string;
    beverages?: Beverage[];
    bodyOutsideDiameterInches: number;
    bodyOutsideDiameterMM?: number;
    campaignRuns?: CampaignRun[];
    campaignTiming?: string;
    coldFillVolumeML?: number;
    coldFillVolumeOz?: number;
    content?: ProductContent;
    currentLeadTime?: string;
    designHeightInches?: number;
    designHeightMM?: number;
    designWidthInches?: number;
    designWidthMM?: number;
    endDiameter?: number;
    ends?: ProductEnd[];
    features?: ProductFeature[];
    fillingAvailability?: string;
    flangedCanHeightInches: number;
    flangedCanHeightMM?: number;
    flangeWidth?: number;
    isAccountMOQ?: boolean;
    leadTimeColorOverride?: string;
    leadTimeWeeks?: number;
    maximumColors: number;
    maxTextHeightInches?: number;
    maxTextHeightMM?: number;
    maxTextWidthInches?: number;
    maxTextWidthMM?: number;
    minOrderQuantityPallets: number;
    moqFees?: MoqData[];
    overlapArea?: number;
    overVarnish?: string;
    pricePerThousand?: number;
    productCode: string;
    quantityPerPallet: number;
    quantityPerTruck: number;
    requestShipToId?: string;
    retortFillVolumeML?: number;
    retortFillVolumeOz?: number;
    selected?: boolean;
    sequence: number;
    sizeRange?: ProductSizeRange;
    style?: ProductStyle;
    type: string;
    volumeML: number;
    volumeOz: number;
    windowHeightInches?: number;
    windowHeightMM?: number;
    windowWidthInches?: number;
    windowWidthMM?: number;
    description?: string;
    productCopy?: string;
}

export interface ProductPortfolioState {
    view?: ViewType;
    selectedFacets: SelectedFacets;
    selectedProduct?: Product; // for Estimate & Build
    initialResults: Product[] | undefined;
    results: Product[];
    facets: Facets;
    showQuickLook: boolean;
    productDetails?: Product;
    regionCode?: string;
    cultureCode?: string;
    error?: string;
    banner?: Section;
    loading: boolean;
    loaded?: boolean;
}

export interface Section {
    header: string;
    description: string;
    summary?: string;
    paragraph?: string;
    links?: Link[];
    image?: Image;
    icon?: Icon;
    [x: string]: any;
}

export interface Link {
    linkText: string;
    linkUrl?: string;
    destination?: string;
    icon?: Icon;
}

export interface Image {
    imageUrl: string;
    alternateText: string;
}

export interface Icon {
    defaultUrl: string;
    hoverUrl: string;
}

const initialState: ProductPortfolioState = {
    view: '' as ViewType,
    selectedFacets: {},
    selectedProduct: undefined,
    initialResults: undefined,
    results: [],
    facets: {},
    showQuickLook: false,
    regionCode: '',
    cultureCode: '',
    banner: { header: '', description: '' },
    loading: false
};

const productPortfolio = (state = initialState, action: any) => {
    switch (action.type) {
        case PRODUCT_LOADED_BANNER:
            return {
                ...state,
                banner: action.banner
            };

        case PRODUCT_UPDATE_FACET:
            let newSelectedFacets = {};
            let selectedFacets = { ...state.selectedFacets };
            let facet = state.facets[action.facet.category];
            let facetType = action.facet.category;
            let newSelectedFacetList: any[] = [];

            action.facet.value.forEach((actionFacet, facetIndex) => {
                for (let index = 0; index < facet.length; index++) {
                    if (facet[index].facetCode === action.facet.value[facetIndex].facetCode) {
                        facet[index].selected = action.facet.value[facetIndex].selected;

                        const selectedFacetCategory = selectedFacets[action.facet.category];

                        if (selectedFacetCategory) {
                            if (selectedFacetCategory.indexOf(facet[index].facetCode) === -1) {
                                newSelectedFacetList = [
                                    ...selectedFacetCategory,
                                    facet[index].facetCode
                                ];
                            } else {
                                newSelectedFacetList = [...selectedFacetCategory];
                                newSelectedFacetList.splice(
                                    selectedFacetCategory.indexOf(facet[index].facetCode),
                                    1
                                );
                            }
                        } else {
                            newSelectedFacetList.push(facet[index].facetCode);
                        }

                        if (newSelectedFacetList.length === 0) {
                            newSelectedFacets = { ...selectedFacets };
                            delete newSelectedFacets[facetType];
                        } else {
                            newSelectedFacets = {
                                ...selectedFacets,
                                [facetType]: newSelectedFacetList
                            };
                        }
                    }
                }
            });

            return {
                ...state,
                selectedFacets: newSelectedFacets
            };
        case PRODUCT_UPDATE_SELECTED_PRODUCT:
            return {
                ...state,
                selectedProduct: action.selectedProduct,
                loaded: true,
                loading: false
            };
        case PRODUCT_CLEAR_SELECTED_PRODUCT:
            return {
                ...state,
                selectedProduct: undefined,
                loaded: true,
                loading: false
            };
        case PRODUCT_UPDATE_RESULTS:
            return {
                ...state,
                initialResults: !!state.initialResults ? state.initialResults : action.results,
                results: action.results,
                loaded: true,
                loading: false
            };
        case PRODUCT_UPDATE_VIEW:
            return {
                ...state,
                view: action.view
            };
        case PRODUCT_CLEAR_FACETS:
            let currentFacets = Object.assign({}, state.facets);
            // eslint-disable-next-line array-callback-return
            Object.keys(currentFacets).forEach((key) => {
                const category = currentFacets[key];
                if (category) {
                    for (let index = 0; index < category.length; index++) {
                        category[index].selected = false;
                    }
                }
            });

            return {
                ...state,
                selectedFacets: {},
                facets: currentFacets
            };

        case PRODUCT_SELECTED:
            const product = state.results?.find((p) => p.productCode === action.productCode);
            if (product) {
                product.selected = action.selected;
            }
            return { ...state };

        case PRODUCT_LOADED:
            return {
                ...state,
                loading: false,
                error: undefined,
                productContent: action.product
            };

        case PRODUCT_LOADING:
            return {
                ...state,
                loading: true,
                loaded: false
            };

        case PRODUCT_LOADING_ERROR:
            return {
                ...state,
                loading: false,
                loaded: undefined,
                error: action.error
            };

        case PRODUCT_LOADING_FACETS:
            return {
                ...state,
                loading: true
            };

        case PRODUCT_LOAD_FACETS:
            Object.keys(action.facets).forEach((key) => {
                // remove empty facets
                if (!action.facets[key]?.length) {
                    delete action.facets[key];
                } else {
                    if (state.selectedFacets[key]) {
                        state.selectedFacets[key].forEach((selectedFacet) => {
                            let newFacet = action.facets[key].find(
                                (f) => f.facetCode === selectedFacet
                            );

                            if (newFacet) {
                                newFacet.selected = true;
                            }
                        });
                    }
                }
            });

            return {
                ...state,
                facets: action.facets
            };

        case PRODUCT_SET_CULTURE:
            return {
                ...state,
                regionCode: action.regionCode,
                cultureCode: action.cultureCode,
                loading: false
            };

        case PRODUCT_LOAD_FACETS_ERROR:
            return {
                ...state,
                facets: {},
                error: action.error,
                loading: false
            };
        case PRODUCT_PORTFOLIO_CONTENT_LOADED:
            return {
                ...state,
                loading: false
            };
        case PRODUCT_PORTFOLIO_CONTENT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
};

export default productPortfolio;
