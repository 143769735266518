import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { Grid, makeStyles, Typography, Link } from '@material-ui/core';
import { useTypedSelector } from '../../../store/reducers/reducer';
import {
    loadATMProductPlanning,
    productPlanningToggleQuantityUnit,
    productPlanningUpdateRange,
    productPlanningUpdateViewFilter,
    resetProductPlanningATM
} from '../../../store/actions/product-planning-atm';
import { loadMakeDashboard } from '../../../store/actions/makeit-dashboard';
import {
    Activity,
    getSecurityLevel,
    hasPersonasByShipTo,
    Persona,
    SecurityLevel
} from '../../../utility/auth/useSecurity';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import Button from '../../reusable/atoms/Button';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { AuthState } from '../../../store/reducers/auth';
import { ProductPlanningATMState } from '../../../store/reducers/product-planning-atm';
import { ProductPlanningState, TotalNDayRanges } from '../../../store/reducers/product-planning';
import moment from 'moment';
import { getAllocationsWithShipTos } from '../../../store/actions/makeit-bulk-atm';
import { BulkUploadMakeItATMState } from '../../../store/reducers/makeit-bulk-atm';
import { selectIsCopackerAccount, selectIsLargeCustomerAccount } from '../../../store/selectors';
import { PlanningAllocation } from './models/PlanningAllocation';
import { getFilteredAllocations } from '../../../utility/helpers/filter-helpers';
import { ProductionOrder } from '../../../store/reducers/makeit-dashboard';
import {
    boldWeight,
    medium,
    yellowNoticeBackground,
    yellowNoticeBackgroundBorder
} from '../../../themes/globalConstants';
import { FlagTypes, GetFeatureFlag } from '../../../utility/helpers/feature-flag';
import ProductPlanningATMTabs from './components/ProductPlanningATMTabs';
import { useQuery } from '../../../utility/helpers/query-helpers';
import { PlanItSummaryState } from '../../../store/reducers/plan-it-summary';
import { loadForecastOrders } from '../../../store/actions/plan-it-summary';
import { loadMakeItBulkProducts } from '../../../store/actions/makeit-bulk-upload';
import { PLAN_IT_SUMMARY_CLEAR_COPY_TO_MAKE_IT_ORDERS } from '../../../store/actions/action-types';
import CopyToMakeItModal from '../../reusable/molecules/CopyToMakeItModal';
import { productPlanningFromDashboardLink } from '../../../store/root-actions';
import { copyFromForecast } from '../../../store/actions/makeit-conversion-atm';

const useStyles = makeStyles((theme) => ({
    main: {
        padding: '0',
        marginTop: '1.750em'
    },
    btnWrapper: {
        '& button:nth-child(n + 1)': {
            marginLeft: '1em'
        }
    },
    actionBtn: {
        borderRadius: 'unset',
        padding: '0 32px'
    },
    actionsWrapper: {
        alignItems: 'center',
        marginBottom: '1.750em'
    },
    boldText: {
        fontWeight: boldWeight
    },
    clockIcon: {
        width: '5%',
        marginRight: '3.75%',
        marginTop: '0.5%',
        float: 'left'
    },
    clockIconContainer: {
        backgroundColor: yellowNoticeBackground,
        borderWidth: 1,
        borderColor: yellowNoticeBackgroundBorder,
        borderStyle: 'solid',
        width: '30%',
        padding: '8px 16px'
    },
    link: {
        fontSize: medium,
        marginLeft: '1em'
    }
}));

export default function ProductPlanningATM() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const query = useQuery();
    const filteredRange = query.get('range');
    const makeItLink = '/make-it';
    const shipItLink = '/ship-it';
    const scrapItLink = '/scrap-it';
    const [hasPOView, setHasPOView] = useState<boolean>(false);
    const [hasDOView, setHasDOView] = useState<boolean>(false);
    const [forecastOrders, setForecastOrders] = useState<ProductionOrder[]>([]);
    const [openConvertPlanItModal, setOpenConvertPlanItModal] = useState<boolean>(false);
    const [shipToIdCache, setShipToIdCache] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [filteredAllocations, setFilteredAllocations] = useState<PlanningAllocation[]>([]);
    const isCopacker = useTypedSelector<boolean>(selectIsCopackerAccount);
    const { products } = useTypedSelector<ProductPlanningState>((state) => state.productPlanning);
    const { orders, forecastOrdersLoaded, error } = useTypedSelector<PlanItSummaryState>(
        (state) => state.planItSummary
    );
    const [viewFilter, setViewFilter] = useState<string[]>(['']);
    const [showClockIcon, setShowClockIcon] = useState<boolean>(false);
    const showScrapItLink = GetFeatureFlag(FlagTypes.ScrapIt) ?? false;

    const isLargeCustomerAccount = useTypedSelector<boolean>(selectIsLargeCustomerAccount);
    const { selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { allocationsBySnoSku, range, view, quantityUnit } =
        useTypedSelector<ProductPlanningATMState>((state) => state.productPlanningATM);
    const { fromDashboardLink } = useTypedSelector<ProductPlanningState>(
        (state) => state.productPlanning
    );
    const { allocations } = useTypedSelector<BulkUploadMakeItATMState>(
        (state) => state.bulkUploadMakeItATMState
    );
    const auth = useTypedSelector<AuthState>((state) => state.auth);
    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const [showTradeItLink, setShowTradeItLink] = useState<boolean>(false);
    const showTradeItOption = GetFeatureFlag(FlagTypes.TradeIt) ?? false;

    const securityContext = {
        accountId: selectedAccountId as string
    };

    const { copyToMakeItOrders } = useTypedSelector<PlanItSummaryState>(
        (state) => state.planItSummary
    );

    const handleNavigation = (destination: string) => {
        history.push(destination);
    };

    const handleViewFiltersClicked = (viewTypes: string[]) => {
        if (viewTypes !== view && allocationsBySnoSku.length) {
            dispatch(productPlanningUpdateViewFilter(viewTypes));
            const filteredAlloc = getFilteredAllocations(viewTypes, allocationsBySnoSku, products);
            setFilteredAllocations(filteredAlloc);
        }
    };

    const handleRangeFiltersClicked = (selectedRange: number) => {
        if (range !== selectedRange) {
            dispatch(productPlanningUpdateRange(selectedRange));
        }
    };

    const handleUnitsFiltersClicked = () => {
        dispatch(productPlanningToggleQuantityUnit());
    };

    const handlePermissions = () => {
        // Checks for view/none level permissions for Production Orders
        const poSecurityLevel = getSecurityLevel(
            Activity.NewOpenProductionOrders,
            auth,
            securityContext
        );
        setHasPOView(
            SecurityLevel.None === poSecurityLevel || SecurityLevel.View === poSecurityLevel
        );

        // Checks for view/none level permissions for Delivery Orders
        const doSecurityLevel = getSecurityLevel(
            Activity.NewOpenDeliveryOrders,
            auth,
            securityContext
        );
        setHasDOView(
            SecurityLevel.None === doSecurityLevel || SecurityLevel.View === doSecurityLevel
        );
    };

    useEffect(() => {
        setViewFilter(view);
    }, [view]);

    useEffect(() => {
        if (products) {
            const filteredAlloc = getFilteredAllocations(viewFilter, allocationsBySnoSku, products);
            setFilteredAllocations(filteredAlloc);
        }
    }, [products]);

    const accountsWithMakeIt: string[] = useMemo(() => {
        if (shipToAccounts && auth.permissions) {
            return shipToAccounts
                .map((account) => account.accountId)
                .filter((accountId) =>
                    hasPersonasByShipTo(
                        [Persona.MakeItOnly, Persona.OrderFulfillment, Persona.AllAccess],
                        accountId,
                        auth.permissions!
                    )
                );
        }
        return [];
    }, [shipToAccounts, auth.permissions]);

    useEffect(() => {
        if (selectedAccountId) {
            dispatch(loadMakeDashboard());
        }
    }, [selectedAccountId]);

    useEffect(() => {
        if (selectedAccountId && accountsWithMakeIt) {
            setShowTradeItLink(
                showTradeItOption && accountsWithMakeIt.includes(selectedAccountId.toString())
            );
            dispatch(loadMakeDashboard());
        }
    }, [selectedAccountId, accountsWithMakeIt]);

    useEffect(() => {
        if (filteredRange) {
            handleRangeFiltersClicked(parseInt(filteredRange));
        }
    }, []);

    useEffect(() => {
        return () => {
            if (!openConvertPlanItModal) {
                dispatch(resetProductPlanningATM());
            }
            if (fromDashboardLink) {
                dispatch(productPlanningFromDashboardLink(false));
            }
        };
    }, []);

    useEffect(() => {
        if (!!filteredAllocations && filteredAllocations.length > 0) {
            setShowClockIcon(
                filteredAllocations.some(
                    (alloc) => !!alloc.products?.some((product) => product.isAged)
                )
            );
        }
    }, [filteredAllocations]);

    useEffect(() => {
        if (selectedAccountId !== shipToIdCache && selectedAccountId) {
            if (!isLargeCustomerAccount || isCopacker) {
                history.push('/product-planning');
            }

            let startDate = moment().startOf('isoWeek').format('M/D/YYYY');
            let endDate = moment().add(17, 'weeks').startOf('isoWeek').format('M/D/YYYY');
            dispatch(
                getAllocationsWithShipTos(startDate, endDate, [Number(selectedAccountId)], false)
            );
            setLoading(true);
            dispatch(productPlanningUpdateViewFilter(['']));
        }
    }, [selectedAccountId]);

    useEffect(() => {
        if (selectedAccountId !== shipToIdCache && allocations && selectedAccountId) {
            dispatch(loadATMProductPlanning(TotalNDayRanges.seventeenWeeks, allocations));
        }
    }, [allocations]);

    useEffect(() => {
        if (
            allocationsBySnoSku?.length &&
            selectedAccountId !== shipToIdCache &&
            selectedAccountId
        ) {
            setShipToIdCache(selectedAccountId);
            setLoading(false);
        }
        allocationsBySnoSku?.length &&
            setFilteredAllocations(getFilteredAllocations(view, allocationsBySnoSku, products));
    }, [allocationsBySnoSku]);

    useEffect(() => {
        if (auth.permissions && auth.permissions.length) {
            handlePermissions();
        }
    }, [auth, selectedAccountId]);
    useEffect(() => {
        if (selectedAccountId) {
            dispatch(loadForecastOrders([parseInt(selectedAccountId)]));
            dispatch(loadMakeItBulkProducts([selectedAccountId], true, true, false));
        }
    }, [selectedAccountId]);

    useEffect(() => {
        if (orders && forecastOrdersLoaded) {
            const activeOrders = orders.filter((order) =>
                moment(order.atmWeekStart).isSameOrAfter(moment())
            );
            setForecastOrders(activeOrders);
        }
    }, [orders]);

    const onClose = () => {
        dispatch({ type: PLAN_IT_SUMMARY_CLEAR_COPY_TO_MAKE_IT_ORDERS });
        setOpenConvertPlanItModal(false);
    };

    const handlePlanItLinkClick = () => {
        setOpenConvertPlanItModal(true);
    };

    const onSubmit = () => {
        dispatch(
            copyFromForecast(
                copyToMakeItOrders.map((o) => o.productionOrderId) as number[],
                Number(selectedAccountId)
            )
        );
        history.push('/makeit-conversion-review?from=productplanning');
        onClose();
    };

    useTranslation();

    const planItToMakeItLink = (
        <Link
            component="button"
            underline="none"
            className={classes.link}
            onClick={handlePlanItLinkClick}
            data-testid="plan-it-conversion-link"
        >
            <Trans i18nKey="copyToMakeIt">Copy To Make It</Trans>
        </Link>
    );

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('productPlanning', 'Product Planning'),
                description: t('emptyString', ''),
                thinBanner: true,
                displayDropdown: true
            }}
            activity={Activity.PlanningPageATM}
            loading={loading}
            fallbackActivity={Activity.PlanningPage}
            fallbackPath={'/product-planning'}
        >
            <Grid
                container
                className={classes.actionsWrapper}
                justify="space-between"
                data-testid="product-plan-atm"
            >
                <Grid container item md={12} className={classes.main}>
                    <Grid container item xs={12} justify="flex-end" className={classes.btnWrapper}>
                        <Button
                            type="button"
                            color="primary"
                            variant="outlined"
                            data-testid="make-it-button"
                            className={classes.actionBtn}
                            onClick={() => handleNavigation(makeItLink)}
                            disabled={hasPOView}
                        >
                            <Trans i18nKey="makeIt">Make It</Trans>
                        </Button>
                        <Button
                            type="button"
                            color="primary"
                            variant="outlined"
                            data-testid="ship-it-button"
                            className={classes.actionBtn}
                            onClick={() => handleNavigation(shipItLink)}
                            disabled={hasDOView}
                        >
                            <Trans i18nKey="shipIt">Ship It</Trans>
                        </Button>
                        {showScrapItLink && (
                            <Button
                                type="button"
                                color="primary"
                                variant="outlined"
                                data-testid="scrap-it-button"
                                className={classes.actionBtn}
                                onClick={() => handleNavigation(scrapItLink)}
                                disabled={hasPOView}
                            >
                                <Trans i18nKey="scrapIt">Scrap It</Trans>
                            </Button>
                        )}
                    </Grid>
                </Grid>
                <Grid container item md={12} className={classes.main}>
                    <Grid container item xs={12} justify="flex-end">
                        {showTradeItLink && (
                            <Link
                                to={'/trade-it'}
                                component={RouterLink}
                                underline="none"
                                data-testid="trade-it-link"
                                className={classes.link}
                            >
                                <Trans i18nKey="transferProductionBalance">
                                    Request A Trade It
                                </Trans>
                            </Link>
                        )}
                        {planItToMakeItLink}
                    </Grid>
                </Grid>
                {showClockIcon && (
                    <Grid
                        container
                        justify="flex-start"
                        alignItems="flex-start"
                        className={classes.clockIconContainer}
                    >
                        <img
                            data-testid="aging-balance-icon"
                            className={classes.clockIcon}
                            src={process.env.PUBLIC_URL + '/assets/icon-clock.png'}
                            alt="Aging Balance"
                        />
                        <Grid>
                            <Typography className={classes.boldText}>
                                <Trans i18nKey="agingBalanceIndicator">
                                    Indicates an aging balance
                                </Trans>
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                <Grid container>
                    <ProductPlanningATMTabs
                        allocations={filteredAllocations}
                        onViewFiltersClicked={handleViewFiltersClicked}
                        onRangeFiltersClicked={handleRangeFiltersClicked}
                        onUnitsFiltersClicked={handleUnitsFiltersClicked}
                        selectedRange={range}
                        currentViewTypes={view}
                        currentUnit={quantityUnit}
                    />
                </Grid>
            </Grid>
            <CopyToMakeItModal
                openModal={openConvertPlanItModal}
                title={t('copyToMakeIt', 'Copy To Make It')}
                onClose={onClose}
                onSubmit={onSubmit}
                error={error}
                dataLoaded={forecastOrdersLoaded}
                forecastOrders={forecastOrders}
            />
        </ProcessingPageTemplate>
    );
}
