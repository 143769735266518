import React, { useEffect, useState } from 'react';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import { Grid, makeStyles } from '@material-ui/core';
import { boldWeight, orange, blackWeight } from '../../../../themes/globalConstants';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import {
    BulkUploadMakeItATMState,
    MakeItBulkATMLineItem,
    MakeItBulkATMLinesBySnoSku
} from '../../../../store/reducers/makeit-bulk-atm';
import PlanItOrderDetailsLineRow from './PlanItOrderDetailsLineRow';
import PlanItOrderDetailsToolbar from './PlanItOrderDetailsToolbar';
import PlanItOrderDetailsColumns from './PlanItOrderDetailsColumns';
import { isProductCanType } from '../../../../utility/helpers/order-helpers';
import {
    getSnoSkuAttributes,
    getSnoSkuDescription,
} from '../../../../utility/helpers/make-it-bulk-helpers';

interface Props {
    linesBySnoSku?: MakeItBulkATMLinesBySnoSku;
}

const useStyles = makeStyles((theme) => ({
    action: {
        cursor: 'pointer'
    },
    trashIcon: {
        fill: orange
    },
    tableWrapper: {
        marginTop: '2em',
        '& .MuiToolbar-root': {
            padding: 0,
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                },
                '&:first-of-type ': {
                    paddingLeft: 0
                }
            },
            '& > h2': {
                fontSize: '1.5em',
                fontWeight: boldWeight,
                color: theme.palette.secondary.main,
                paddingLeft: '0.625em',
                fontFamily: 'Lato',
                minWidth: '12em'
            }
        },
        '& .MuiTableSortLabel-root': {
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em',
            width: '99%',
            justifyContent: 'space-between',
            '& .MuiSvgIcon-root': {
                margin: 0
            }
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                '&:nth-child(1)': {
                    width: '17%'
                },
                '&:nth-child(2)': {
                    width: '30%'
                },
                '&:nth-child(3)': {
                    width: '10%'
                },
                '&:nth-child(4)': {
                    width: '13%'
                },
                '&:nth-child(5)': {
                    width: '15%'
                },
                '&:nth-child(6)': {
                    width: '15%'
                },

            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    }
}));

const PlanItOrderDetailsGrid = ({ linesBySnoSku }: Props) => {
    const classes = useStyles();
    const [columns, setColumns] = useState<any>([]);
    const [orderLines, setOrderLines] = useState<MakeItBulkATMLineItem[]>();
    const [attributeDescription, setAttributeDescription] = useState<string>('');
    const [secondaryAttributes, setSecondaryAttributes] = useState<string>('');
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { allocations } = useTypedSelector<BulkUploadMakeItATMState>(
        (state) => state.bulkUploadMakeItATMState
    );

    useEffect(() => {
        setColumns(PlanItOrderDetailsColumns.getPlanItOrderDetailsColumns(cultureCode));
    }, [cultureCode]);

    useEffect(() => {
        if (linesBySnoSku) {
            setOrderLines(linesBySnoSku.lines);
            setAttributeDescription(
                linesBySnoSku.lines && linesBySnoSku.lines[0]
                    ? getSnoSkuDescription(
                        isProductCanType(linesBySnoSku.lines[0].productType),
                        linesBySnoSku.lines[0]
                    )
                    : ''
            );
            setSecondaryAttributes(
                linesBySnoSku.lines && linesBySnoSku.lines[0]
                    ? getSnoSkuAttributes(
                        isProductCanType(linesBySnoSku.lines[0].productType),
                        linesBySnoSku.lines[0]
                    )
                    : ''
            );
        }
    }, [linesBySnoSku, allocations]);

    const CustomToolbarOptions = new ToolbarOptions({
        customItems: (
            <PlanItOrderDetailsToolbar
                attributeDescription={attributeDescription}
                secondaryAttributes={secondaryAttributes}
            />
        ),
        advancePagination: false,
        exportButton: false,
        printButton: false,
        searchText: false
    });

    const PlanItOrderRow = ({ row }: { row: Partial<MakeItBulkATMLineItem> }) => {
        if (row) {
            return (
                <PlanItOrderDetailsLineRow
                    key={row.productionOrderLineId}
                    line={row as MakeItBulkATMLineItem}
                />
            );
        } else {
            return null;
        }
    };

    return (
        <Grid className={classes.tableWrapper}>
            {orderLines && (
                <DataGrid
                    gridName="Plan It Order Lines"
                    columns={columns}
                    dataSource={orderLines}
                    rowComponent={PlanItOrderRow}
                    toolbarOptions={CustomToolbarOptions}
                />
            )}
        </Grid>
    );
};

export default PlanItOrderDetailsGrid;
