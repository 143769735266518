import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import { small, boldWeight } from '../../../themes/globalConstants';
import ViewRangeButtonGroup from './ViewRangeButtonGroup';
import { ArrayFilterType, NumberFilterType } from '../../../utility/helpers/filter-helpers';

interface Props {
    rangeTypes: ArrayFilterType[] | NumberFilterType[];
    rangeChange: (dispatch: any) => void;
    testId?: string;
    value?: string;
    multiSelect?: boolean;
}

const useStyles = makeStyles((theme) => ({
    main: {
        borderTop: 'none',
        borderRadius: 'unset'
    },
    toolbarWrapper: {
        padding: '1.125em 0',
        alignItems: 'center',
        '& .MuiGrid-container': {
            margin: 0
        }
    },
    toolbarLabel: {
        fontSize: small,
        textTransform: 'uppercase',
        marginRight: '0.25em'
    },

    btnGroupWrapper: {
        paddingLeft: '0 !important'
    },
    btn: {
        padding: 0,
        minWidth: 'auto',
        fontSize: small,
        fontWeight: boldWeight,
        margin: '0 1em',
        '&:first-child': {
            marginLeft: 0
        }
    },
    width: {
        minWidth: 'max-content'
    },
    multiSelectWidth: {
        minWidth: 'max-content',
        marginLeft: '38em !important'
    }
}));

const RangeFilterToolbar = (props: Props) => {
    const classes = useStyles();
    const value = props.value ?? undefined;

    useTranslation();

    return (
        <Grid container item spacing={0} className={props.multiSelect ? classes.multiSelectWidth : classes.width}>
            <Grid item>
                <Typography variant="h6" className={classes.toolbarLabel}>
                    <Trans i18nKey="range">Range</Trans> /
                </Typography>
            </Grid>
            <Grid item className={classes.btnGroupWrapper}>
                <ViewRangeButtonGroup
                    views={props.rangeTypes}
                    onViewChange={props.rangeChange}
                    className={classes.btn}
                    viewRangeToggleGroup={props.testId}
                    exclusive={false}
                    value={value}
                />
            </Grid>
        </Grid>
    );
};

export default RangeFilterToolbar;
