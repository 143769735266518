import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { enGB } from './utility/translations/locales/en-GB/en-GB';
import { enUS } from './utility/translations/locales/en-US/en-US';
import { esES } from './utility/translations/locales/es-ES/es-ES';
import { frFR } from './utility/translations/locales/fr-FR/fr-FR';
import { ptBR } from './utility/translations/locales/pt-BR/pt-BR';
import { deDE } from './utility/translations/locales/de-DE/de-DE';
import { ruRU } from './utility/translations/locales/ru-RU/ru-RU';
import { esMX } from './utility/translations/locales/es-MX/es-MX';
import { esVE } from './utility/translations/locales/es-VE/es-VE';

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            'en-US': {
                translation: enUS
            },
            'en-GB': {
                translation: enGB
            },
            'es-ES': {
                translation: esES
            },
            'fr-FR': {
                translation: frFR
            },
            'pt-BR': {
                translation: ptBR
            },
            'de-DE': {
                translation: deDE
            },
            'ru-RU': {
                translation: ruRU
            },
            'es-MX': {
                translation: esMX
            },
            'es-VE': {
                translation: esVE
            }
        },

        lng: 'en-US',
        fallbackLng: 'en-US',
        debug: process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',
        ns: ['translation'],
        defaultNS: 'translation',

        keySeparator: false,

        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        }
    });

export default i18n;
