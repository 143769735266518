import {
    COMMUNICATION_MESSAGES_LOADING,
    COMMUNICATION_MESSAGES_LOADED,
    COMMUNICATION_MESSAGES_ERROR,
    COMMUNICATION_MESSAGES_RESET
} from '../actions/action-types';

import { Communication } from './communication-management';

export interface CommunicationMessagesState {
    communications: Communication[];
    loading: boolean;
    loaded: boolean;
    error?: any;
}

const initialState: CommunicationMessagesState = {
    communications: [],
    loading: false,
    loaded: false,
    error: undefined
};

const communications = (
    state: CommunicationMessagesState = initialState,
    action: { type: string;[x: string]: any }
): CommunicationMessagesState => {
    switch (action.type) {
        case COMMUNICATION_MESSAGES_LOADING:
            return { ...state, loading: true, loaded: false };
        case COMMUNICATION_MESSAGES_LOADED:
            return {
                ...state,
                communications: action.communications,
                loading: false,
                loaded: true,
            };
        case COMMUNICATION_MESSAGES_ERROR:
            return {
                ...state,
                loading: false,
                loaded: true,
                error: action.error

            };
        case COMMUNICATION_MESSAGES_RESET:
            return { ...initialState };
        default:
            return state;
    }
};

export default communications;
