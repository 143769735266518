import React, { useEffect, useState } from 'react';
import { PricingLine } from '../../../../store/reducers/pricing-breakdown';
import PricingBreakdownRow from './PricingBreakdownRow';
import PricingBreakdownToolbar from './PricingBreakdownToolbar';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    boldWeight,
    orange,
    blackWeight,
    ballBlue,
    dateOptionInvalidGray
} from '../../../../themes/globalConstants';
import PricingBreakdownColumns from './PricingBreakdownColumns';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { generateKey } from '../../../../utility/helpers/order-helpers';
import { Account } from '../../../../store/reducers/customer-context';
import { formatPricingBreakdownData } from '../../../../utility/helpers/pricing-breakdown-helpers';

interface Props {
    items: PricingLine[];
    currency: string;
    selectedShipTo: Account;
    selectedBillTo: Account;
    effectiveDate?: string;
}

const useStyles = makeStyles((theme) => ({
    action: {
        cursor: 'pointer'
    },
    trashIcon: {
        fill: orange
    },
    tableWrapper: {
        marginTop: '2em',
        '& .MuiToolbar-root': {
            padding: 0,
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                },
                '&:first-of-type ': {
                    paddingLeft: 0
                }
            },
            '& > h2': {
                fontSize: '1.5em',
                fontWeight: boldWeight,
                color: theme.palette.secondary.main,
                paddingLeft: '0.625em',
                fontFamily: 'Lato',
                minWidth: '12em'
            }
        },
        '& .MuiTableSortLabel-root': {
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em',
            width: '99%',
            justifyContent: 'space-between',
            '& .MuiSvgIcon-root': {
                margin: 0
            }
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                '&:nth-child(1)': {
                    width: '40%'
                },
                '&:nth-child(2)': {
                    width: '10%'
                },
                '&:nth-child(3)': {
                    width: '10%'
                },
                '&:nth-child(4)': {
                    width: '20%'
                },
                '&:nth-child(5)': {
                    width: '10%'
                },
                '&:nth-child(6)': {
                    width: '10%'
                }
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        },
        '& .MuiInputBase-formControl': {
            border: `1px solid ${dateOptionInvalidGray}`,
            borderRadius: '.125em',
            '&:before': {
                display: 'none'
            },
            '&:after': {
                display: 'none'
            },
            '& svg': {
                height: '.875em',
                width: '.875em',
                fill: ballBlue
            },
            '& .MuiIconButton-root': {
                '&:hover': {
                    backgroundColor: 'transparent !important'
                }
            },
            '& .MuiInput-input': {
                paddingLeft: '.25em'
            }
        }
    }
}));

const PricingBreakdownGrid = ({
    items,
    currency,
    selectedBillTo,
    selectedShipTo,
    effectiveDate
}: Props) => {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const [formattedItems, setFormattedItems] = useState<PricingLine[]>(items);
    const [columns, setColumns] = useState<any>([]);

    const options = new ToolbarOptions({
        advancePagination: false,
        exportButton: false,
        printButton: false,
        searchText: true,
        itemsPerPage: 50,
        customItems: (
            <PricingBreakdownToolbar
                items={items}
                selectedBillTo={selectedBillTo}
                selectedShipTo={selectedShipTo}
                effectiveDate={effectiveDate}
                currency={currency}
            />
        )
    });

    useEffect(() => {
        setColumns(PricingBreakdownColumns.getColumns(cultureCode));
    }, [cultureCode]);

    useEffect(() => {
        if (items) {
            const updatedItems = formatPricingBreakdownData(items, cultureCode, currency);
            setFormattedItems(updatedItems);
        }
    }, [items, cultureCode]);

    const PricingBreakdownLine = ({ row }: { row: PricingLine }) => {
        if (row) {
            return <PricingBreakdownRow key={row.itemDescription} line={row as PricingLine} />;
        } else {
            return null;
        }
    };

    return (
        <Grid className={classes.tableWrapper} key={generateKey('pricing-breakdown-grid')}>
            <DataGrid
                gridName="Pricing Breakdown Lines"
                columns={columns}
                dataSource={formattedItems}
                rowComponent={PricingBreakdownLine}
                toolbarOptions={options}
            />
        </Grid>
    );
};

export default PricingBreakdownGrid;
