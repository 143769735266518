import { OrderProduct, OrderProductTableRow } from '../../store/reducers/orders-dashboard';
import {
    ProductionOrderLine,
    ProductCountInformation,
    ProductionOrderLineItem
} from '../../store/reducers/makeit-dashboard';
import { Order_Status, ProductType } from '../services/orders-service';
import TranslationService from '../services/translation-service';
import {
    DeliveryOrder,
    DeliveryShipment,
    LinkedProductionOrders,
    ProductToShip,
    ReviewedOrderStatus,
    ReviewStatus
} from '../../store/reducers/shipping-dashboard';
import { ProductionOrderSummary } from '../../store/reducers/make-it-summary';
import { Account } from '../../store/reducers/customer-context';
import { getAccountAddress } from './address-helpers';
import { OrderPlanningProduct } from '../../store/reducers/product-planning';
import { MakeItBulkLineItem, MakeItBulkOrder } from '../../store/reducers/makeit-bulk-upload';
import React from 'react';
import { Typography } from '@material-ui/core';
import { formatNumberWithLocale } from './formatters';
import { Culture } from '../../store/reducers/region-culture';
import { PlanningAllocation } from '../../components/pages/ProductPlanningATM/models/PlanningAllocation';
import { MakeItBulkATMLinesBySnoSku } from '../../store/reducers/makeit-bulk-atm';
import { DateState } from '../../components/pages/MakeItBulkReviewATM/components/MakeItBulkReviewATMDateTab';
import {
    AllocationQuantityState,
    evaluateAllocationQuantityValid,
    evaluatePalletQuantityValid,
    getFeeStructure,
    getShellCollor,
    PalletQuantityState
} from './make-it-bulk-helpers';
import { getMoqFeeCeiling, getMoqFeeFloor } from './production-order-helpers';
import moment from 'moment';

export const canBtlCodeFormatter = (code: string, size: number = -1) => {
    const standardSizes = [12, 16, 20, 375, 500, 568, 355, 127, 375, 473, 192, 568];

    const kingSizes = [24, 25, 710, 254, 750, 32, 950];

    if (code === 'STD') {
        if (standardSizes.indexOf(size) !== -1) {
            return 'Standard';
        } else if (kingSizes.indexOf(size) !== -1) {
            return 'King';
        } else {
            return code;
        }
    } else {
        switch (code) {
            case 'SK4':
            case 'SSK':
            case 'SLK':
                return 'Sleek';
            case 'TRM':
            case 'SLM':
                return 'Slim';
            case 'KNG':
                return 'King';
            case 'ATB':
            case 'ATK':
                return 'Alumi-Tek';
            case 'CCN':
            case 'CAP':
                return 'Cap-Can';
            case 'ALB':
                return 'Silhouette';
            case 'CSB':
            case 'CTR':
                return 'Contour';
            case 'SQT':
            case 'SSQ':
                return 'n/a';
            case 'TLL':
                return 'Tall';
            case 'WNK':
                return 'Sam';
            default:
                return code;
        }
    }
};

export const endsCodeFormatter = (code: string) => {
    switch (code) {
        case 'B64':
            return 'B64';
        case 'BRE':
            return 'BRE';
        case 'BUS':
            return '200DIA US STANDARD';
        case 'CAP':
            return 'CAPCAN CAP';
        case 'CDE':
            return 'CDL-E';
        case 'CDL':
            return 'CDL';
        case 'CDW':
            return 'CDL-W';
        case 'DOM':
            return 'CAPCAN DOME';
        case 'OLO':
            return 'OLOF';
        case 'OSR':
            return 'OFFSET RIVET';
        default:
            return code;
    }
};

export const tabColorsFormatter = (code: string) => {
    switch (code) {
        case 'BLK':
        case 'BLK803':
            return 'BLACK TAB';
        case 'BLU285':
            return 'BLUE CAP PMS285';
        case 'BLU294':
        case 'BLU316':
            return 'DARK BLUE TAB';
        case 'BLU301':
        case 'BLU327':
            return 'ROYAL BLUE TAB';
        case 'BLU325':
        case 'BLU632':
            return 'LIGHT BLUE TAB';
        case 'BLU432':
            return 'TECATE MED BLUE (ALCOA)';
        case 'BRN727':
            return 'BROWN TAB';
        case 'CTB119':
            return 'TAB COLD ACTIVATED BLUE';
        case 'FUS215':
        case 'FUS753':
            return 'FUSCHIA TAB';
        case 'GLD544':
            return 'GOLD TAB';
        case 'GRN377':
        case 'GRN432':
            return 'LIME GREEN TAB';
        case 'GRN423':
            return 'DARK GREEN HEINEKIN';
        case 'GRN440':
            return 'HABITAT GREEN TAB';
        case 'ORG125':
        case 'ORG566':
            return 'LIGHT ORANGE TAB';
        case 'ORG167':
            return 'ORANGE TAB';
        case 'ORG612':
            return 'ORANGE (DARK) TAB';
        case 'PUR211':
        case 'PUR267':
            return 'PURPLE TAB';
        case 'RED187':
        case 'RED726':
            return 'RED TAB';
        case 'RED705':
            return 'TECATE RED (ALCOA)';
        case 'RED738':
            return 'CRIMSON TAB';
        case 'REDS61':
            return 'BUD RED TAB';
        case 'SIL':
            return 'SILVER TAB';
        case 'YEL555':
            return 'YELLOW TAB';
        case 'YEL573':
            return 'DBL YELLOW TAB';
        default:
            return code;
    }
};

export const orderStatusCodeFormatter = (status: string | undefined) => {
    switch (status) {
        case Order_Status.Draft:
            return 'draft';
        case Order_Status.Submit:
            return 'submitted';
        case Order_Status.Reject:
            return 'reject';
        case Order_Status.Accounts_Payable:
            return 'payable';
        case Order_Status.Payment_Delinquent:
            return 'delinquent';
        case Order_Status.Payment_Remitted:
            return 'received';
        case Order_Status.Confirmed:
            return 'confirmed';
        case Order_Status.Shp_C:
            return 'shipConfirmed';
        case Order_Status.Open:
            return 'open';
        case Order_Status.Completed:
            return 'completed';
        case Order_Status.Closed:
            return 'closed';
        case Order_Status.Cancelled:
            return 'cancelled';
        case Order_Status.EditedPending:
            return 'editPending';
        case Order_Status.Unknown:
            return 'unknown';
        case Order_Status.CancelledPending:
            return 'cancelledPending';
        case Order_Status.Review:
            return 'editUnderReview';
        case ReviewedOrderStatus.UnderReview:
            return 'editUnderReview';
        case ReviewedOrderStatus.OpenEditReviewed:
            return 'openEditReviewed';
        case ReviewedOrderStatus.OpenEditRejected:
            return 'openEditRejected';
    }
};

export const formatStatus = (status?: string, reviewStatus?: string) => {
    switch (status) {
        case Order_Status.Open:
            if (reviewStatus === ReviewStatus.New) return 'Edit Under Review';
            if (reviewStatus === ReviewStatus.Approved) return 'Open (Edit Reviewed)';
            if (reviewStatus === ReviewStatus.Rejected) return 'Open (Edit Rejected)';
            return 'Open';
        case Order_Status.Submit:
            return 'Submitted';
        case Order_Status.Reject:
            return 'Rejected';
        case Order_Status.Closed:
            return 'Closed';
        case Order_Status.Cancelled:
            return 'Cancelled';
        case Order_Status.CancelledPending:
            if (
                reviewStatus === ReviewStatus.New ||
                reviewStatus === ReviewStatus.Approved ||
                reviewStatus === ReviewStatus.Rejected
            )
                return 'Edit Under Review';
            return 'Cancelled Pending';
        case Order_Status.EditedPending:
            if (
                reviewStatus === ReviewStatus.New ||
                reviewStatus === ReviewStatus.Approved ||
                reviewStatus === ReviewStatus.Rejected
            )
                return 'Edit Under Review';
            return 'Edit Pending';
        case Order_Status.Completed:
            return 'Complete';
        case Order_Status.Confirmed:
            return 'Confirmed';
        case Order_Status.Shp_C:
            return 'Shipment Confirmed';
        case Order_Status.Payment_Delinquent:
            return 'Payment Due';
        case Order_Status.Payment_Remitted:
            return 'Payment Received';
        case Order_Status.Review:
            return 'Edit Under Review';
        case Order_Status.Unknown:
            return 'Unknown';
        default:
            return '';
    }
};

export const deliveryOrderEditShouldBeDisabledBasedOnReviewStatus = (order: DeliveryOrder) => {
    // Get review status from complete order or summary of order
    const reviewStatus = order.statusReview?.status || order.reviewStatus || '';

    // Disable if...
    // Order status is EditedPending, CancelledPending, or Submit
    // AND Review Status is Approved or Rejected
    return (
        [
            Order_Status.EditedPending.toString(),
            Order_Status.CancelledPending.toString(),
            Order_Status.Submit.toString()
        ].includes(order.status ?? '') &&
        [ReviewStatus.Approved.toString(), ReviewStatus.Rejected.toString()].includes(reviewStatus)
    );
};

const buildProductDescription = (descriptions: string[]) => {
    let tempDesc = '';

    for (let x = 0; x < descriptions.length; x++) {
        tempDesc += descriptions[x];
    }

    return tempDesc;
};

export const constructCanDescription = (item: OrderProductTableRow | MakeItBulkLineItem) => {
    let descriptions = [
        item.unit ? `${item.unit.toLowerCase()}` : '',
        item.shape ? ` / ${canBtlCodeFormatter(item.shape, item.size)}` : '',
        item.neckDiameter ? ` / ${item.neckDiameter}` : ''
    ];

    return buildProductDescription(descriptions);
};

export const constructCanSubDescription = (item: OrderProductTableRow | MakeItBulkLineItem) => {
    let descriptions = [
        item.basecoat && item.basecoat.toUpperCase() === 'BC' ? `Basecoat` : '',
        item.specialtyInk ? `${item.specialtyInk}` : '',
        item.retort ? `Retort` : ''
    ];

    return descriptions.filter((i) => i.length > 0).join(' / ');
};

export const constructEndDescription = (item: OrderProductTableRow | MakeItBulkLineItem) => {
    const shellColor = item.shellColor ? getShellCollor(item.shellColor) : undefined;
    let descriptions = [
        item.unit ? `${item.unit.toLowerCase()}` : '',
        item.endProfile ? ` / ${endsCodeFormatter(item.endProfile)}` : '',
        shellColor ? ` / ${shellColor} Shell` : '',
        item.tabColor ? ` / ${tabColorsFormatter(item.tabColor)}` : ''
    ];

    return buildProductDescription(descriptions);
};

export const constructEndSubDescription = (item: OrderProductTableRow | MakeItBulkLineItem) => {
    let descriptions = [
        item.shellGauge ? `${item.shellGauge}` : '',
        item.tabColor ? `${item.tabColor}` : '',
        item.retort ? `Retort` : ''
    ];

    return descriptions.filter((i) => i.length > 0).join(' / ');
};

export const constructShortPDFCanDescription = (line: OrderProduct | ProductionOrderLine) => {
    let descriptions = [
        line.unit ? `${line.unit.toLowerCase()}` : '',
        line.shape ? ` / ${canBtlCodeFormatter(line.shape, line.size)}` : ''
    ];

    return buildProductDescription(descriptions);
};

export const constructShortPDFEndDescription = (line: OrderProduct | ProductionOrderLine) => {
    let descriptions = [
        line.unit ? `${line.unit.toLowerCase()}` : '',
        line.endProfile ? ` / ${endsCodeFormatter(line.endProfile)}` : ''
    ];

    return buildProductDescription(descriptions);
};

export const constructPDFCanDescription = (line: ProductionOrderLineItem) => {
    let descriptions = [
        line.productUnits ? `${line.productUnits.toLowerCase()}` : '',
        line.productShape ? ` / ${canBtlCodeFormatter(line.productShape, line.productSize)}` : '',
        line.productNeckSize ? ` / ${line.productNeckSize}` : ''
    ];

    return buildProductDescription(descriptions);
};

export const constructPDFEndDescription = (line: ProductionOrderLineItem) => {
    let descriptions = [
        line.productUnits ? `${line.productUnits.toLowerCase()}` : '',
        line.productEndProfile ? ` / ${endsCodeFormatter(line.productEndProfile)}` : '',
        line.productTabColor ? ` / ${tabColorsFormatter(line.productTabColor)}` : ''
    ];

    return buildProductDescription(descriptions);
};

export const leadTimeFilter = (item: OrderProductTableRow) => {
    let currentLeadTime = '';
    let duration = '';
    let weekSelection = 0;

    switch (item.leadTime) {
        case '4TO8WKS':
            currentLeadTime = '4 - 8 ';
            duration = 'Weeks';
            weekSelection = 4;
            return {
                currentLeadTime: currentLeadTime,
                duration: duration,
                weekSelection: weekSelection
            };
        case '8TO12WKS':
            currentLeadTime = '8 - 12 ';
            duration = 'Weeks';
            weekSelection = 8;
            return {
                currentLeadTime: currentLeadTime,
                duration: duration,
                weekSelection: weekSelection
            };
        case '12PLSWKS':
            currentLeadTime = '12+ ';
            duration = 'Weeks';
            weekSelection = 12;
            return {
                currentLeadTime: currentLeadTime,
                duration: duration,
                weekSelection: weekSelection
            };
        case '26PLSWKS':
            currentLeadTime = '26+ ';
            duration = 'Weeks';
            weekSelection = 26;
            return {
                currentLeadTime: currentLeadTime,
                duration: duration,
                weekSelection: weekSelection
            };
        case 'MIN12MOS':
            currentLeadTime = 'Minimum 12 ';
            duration = 'Months';
            weekSelection = 24;
            return {
                currentLeadTime: currentLeadTime,
                duration: duration,
                weekSelection: weekSelection
            };
        default:
            break;
    }
};

export const generateKey = (prefix: string) => {
    return `${prefix}_${new Date().getTime()}`;
};

export const returnOrderCountInformation = (lines: ProductionOrderLine[]) => {
    let canBtlSku = new Set<string>();
    let endSku = new Set<string>();
    let end = 0;
    let canBtl = 0;
    let canEaches = 0;
    let endEaches = 0;
    lines.forEach((lineItem) => {
        const isEndType = isProductEndType(lineItem.type);
        if (isEndType) {
            end += lineItem.palletQuantity!;
            endSku.add(lineItem.productSku!);
            endEaches += lineItem.palletQuantity! * lineItem.quantityPerPallet!;
        } else {
            canBtl += lineItem.palletQuantity!;
            canBtlSku.add(lineItem.productSku!);
            canEaches += lineItem.palletQuantity! * lineItem.quantityPerPallet!;
        }
    });
    return {
        canBtlCount: canBtl,
        endCount: end,
        canBtlSkuCount: Array.from(canBtlSku).length,
        endSkuCount: Array.from(endSku).length,
        canEachesCount: canEaches,
        endEachesCount: endEaches
    } as ProductCountInformation;
};

export const productTypeHelper = (productType) => {
    if (isProductEndType(productType)) {
        return 'END';
    } else if (isProductCanType(productType)) {
        return 'CAN';
    } else {
        return null;
    }
};

export const isProductEndType = (productType) => {
    return productType === 'END' || productType === 'CAP' || productType === 'Ends/Closures';
};

export const isProductCanType = (productType) => {
    return productType === 'CAN' || productType === 'BOTTLE' || productType === 'Cans/Bottles';
};

export const getGraphicIdAndVersion = (type?: string, graphicIdAndVersion?: string) => {
    if (!type || isProductEndType(type)) {
        return '-';
    } else {
        return graphicIdAndVersion;
    }
};

export const getSearchableOrderQuantityString = (
    culture: string,
    totalShipments?: number,
    totalOrderQuantity?: number,
    totalOrderSKUs?: number,
    totalEaches?: number
) => {
    return `${totalShipments} ${TranslationService.getTranslatedText(
        culture,
        totalShipments === 1 ? 'shipment' : 'shipments'
    )} (${totalOrderSKUs} ${TranslationService.getTranslatedText(
        culture,
        totalOrderSKUs === 1 ? 'sku' : 'skus'
    )} / ${totalOrderQuantity}PL / ${
        totalEaches && formatNumberWithLocale(culture as Culture, totalEaches)
    } ea.)`;
};

export const formatOrderQuantity = (
    culture: string,
    totalShipments?: number,
    totalOrderQuantity?: number,
    totalOrderSKUs?: number,
    totalEaches?: number
) => {
    let orderQuantityDisplay = (
        <>
            <Typography>
                {totalShipments}{' '}
                {TranslationService.getTranslatedText(
                    culture,
                    totalShipments === 1 ? 'shipment' : 'shipments'
                )}{' '}
                ({totalOrderSKUs}{' '}
                {TranslationService.getTranslatedText(
                    culture,
                    totalOrderSKUs === 1 ? 'sku' : 'skus'
                )}{' '}
                /{' '}
                {totalOrderQuantity &&
                    formatNumberWithLocale(culture as Culture, totalOrderQuantity)}
                PL /
            </Typography>
            <Typography>
                {totalEaches && formatNumberWithLocale(culture as Culture, totalEaches)} ea.)
            </Typography>
        </>
    );
    return orderQuantityDisplay;
};

export const getFilteredProductionOrders = (
    loadId?: number,
    linkedProdOrder?: LinkedProductionOrders
) => {
    if (loadId && linkedProdOrder?.loadsToLinkedOrders) {
        return linkedProdOrder?.loadsToLinkedOrders[loadId];
    } else {
        return undefined;
    }
};

export const getShipToName = (
    productionOrderId?: number,
    shipToAccounts?: Account[],
    productionOrders?: ProductionOrderSummary[]
) => {
    if (shipToAccounts && productionOrders) {
        let filteredOrder = productionOrders.find(
            (ord) => productionOrderId === ord.productionOrderId
        );
        let filteredAccount = shipToAccounts.find(
            (shipToAccount) => filteredOrder && filteredOrder.shipToId === +shipToAccount.accountId
        );

        if (filteredAccount) {
            return filteredAccount.name;
        }
    }
};

export const getShipToAddress = (
    productionOrderId?: number,
    shipToAccounts?: Account[],
    productionOrders?: ProductionOrderSummary[]
) => {
    if (shipToAccounts && productionOrders) {
        let filteredOrder = productionOrders.find(
            (ord) => productionOrderId === ord.productionOrderId
        );
        let filteredAccount = shipToAccounts.find(
            (shipToAccount) => filteredOrder && filteredOrder.shipToId === +shipToAccount.accountId
        );

        if (filteredAccount) {
            return getAccountAddress(filteredAccount);
        }
    }
};

export const getShipToProdOrderType = (
    productionOrderId?: number,
    shipToAccounts?: Account[],
    productionOrders?: ProductionOrderSummary[]
) => {
    if (shipToAccounts && productionOrders) {
        let filteredOrder = productionOrders.find(
            (ord) => productionOrderId === ord.productionOrderId
        );
        let filteredAccount = shipToAccounts.find(
            (shipToAccount) => filteredOrder && filteredOrder.shipToId === +shipToAccount.accountId
        );

        if (filteredAccount) {
            return filteredAccount.prodOrderType;
        }
    }
};

export const setValues = (
    product: OrderPlanningProduct | PlanningAllocation,
    palletTotal: number,
    index: number
) => {
    switch (index) {
        case 0:
            return (product.currentWeek = palletTotal);
        case 1:
            return (product.weekOne = palletTotal);
        case 2:
            return (product.weekTwo = palletTotal);
        case 3:
            return (product.weekThree = palletTotal);
        case 4:
            return (product.weekFour = palletTotal);
        case 5:
            return (product.weekFive = palletTotal);
        case 6:
            return (product.weekSix = palletTotal);
        case 7:
            return (product.weekSeven = palletTotal);
        case 8:
            return (product.weekEight = palletTotal);
        case 9:
            return (product.weekNine = palletTotal);
        case 10:
            return (product.weekTen = palletTotal);
        case 11:
            return (product.weekEleven = palletTotal);
        case 12:
            return (product.weekTweleve = palletTotal);
        case 13:
            return (product.weekThirteen = palletTotal);
        case 14:
            return (product.weekFourteen = palletTotal);
        case 15:
            return (product.weekFifteen = palletTotal);
        case 16:
            return (product.weekSixteen = palletTotal);
        default:
            return product;
    }
};

export interface ToggleAll {
    toggle: boolean;
}

export const updateDeliveryOrderWithProductInfo = (
    deliveryOrder: DeliveryOrder,
    availableProducts: any
) => {
    deliveryOrder.shipments?.map((shipment: DeliveryShipment) => {
        shipment.canQuantityPallets = 0;
        shipment.endQuantityPallets = 0;
        shipment.canQuantitySKUs = 0;
        shipment.endQuantitySKUs = 0;

        shipment.loads?.map((load: ProductToShip) => {
            load.originalPalletQuantity = load.palletQuantity;
            let tempProduct = availableProducts.find((item) => item.productSku === load.productSku);

            if (tempProduct) {
                const isEndType = isProductEndType(tempProduct.type);
                load.type = tempProduct.type;
                if (isEndType) {
                    load.description = constructEndDescription(tempProduct);
                    shipment.endQuantityPallets! += load.palletQuantity;
                    shipment.endQuantitySKUs! += 1;
                    shipment.shipmentType = 'END';
                } else {
                    load.description = constructCanDescription(tempProduct);
                    shipment.canQuantityPallets! += load.palletQuantity;
                    shipment.canQuantitySKUs! += 1;
                    shipment.shipmentType = 'CAN';
                }
                load.editLoadId = load.loadId;
                tempProduct.graphicIdAndVersion =
                    tempProduct.graphicId && tempProduct.graphicVersion
                        ? tempProduct.graphicId + '-' + tempProduct.graphicVersion
                        : '';
                const destination =
                    tempProduct.destinations &&
                    tempProduct.destinations.find(
                        (dest) => dest.shipToId === deliveryOrder.shipToId
                    );
                load.customerProductId = destination?.customerProductId;

                load.displayId = `${load.productSku} / ${load.productGraphicId}`;
                load.displayName = destination?.customerProductName
                    ? destination.customerProductName
                    : tempProduct.name;
            } else {
                load.editLoadId = load.loadId;
                load.displayName = load.customerProductName;
            }
            return null;
        });
        return null;
    });

    return deliveryOrder;
};

export const simpleFormatOrderQuantity = (
    culture: string,
    totalOrderQuantity?: number,
    totalOrderSKUs?: number
) => {
    let orderQuantityString = totalOrderQuantity + ' PL (';

    if (totalOrderSKUs === 1) {
        orderQuantityString +=
            totalOrderSKUs + ' ' + TranslationService.getTranslatedText(culture, 'sku') + ')';
    } else if (totalOrderSKUs && totalOrderSKUs > 1) {
        orderQuantityString +=
            totalOrderSKUs + ' ' + TranslationService.getTranslatedText(culture, 'skus') + ')';
    } else {
        return '';
    }

    return orderQuantityString;
};

export const getProductsBelowAbsoluteMinimum = (productList: any[]) => {
    let filteredList = productList.filter((product) => {
        let absoluteMinimum: number | undefined = undefined;
        if (product.moqFees?.length > 0 || product.minimumOrderQuantity) {
            absoluteMinimum =
                product.moqFees?.length > 0
                    ? getMoqFeeFloor(product.moqFees)
                    : product.minimumOrderQuantity;
        }
        return (
            !product.palletQuantity ||
            product.palletQuantity === 0 ||
            (absoluteMinimum && product.palletQuantity < absoluteMinimum)
        );
    });
    return filteredList;
};

export const validateMoqAmounts = (products: any[], acceptFee: boolean) => {
    let counter = 0;
    products.forEach((product) => {
        if (acceptFee) {
            // No fees associated with product and MOQ still not met
            if (!product.moqFees && !product.minimumMet) {
                counter++;
            }
            // Fee accepted, but entered quantity is not in range and doesn't meet MOQ
            if (product.moqFees && !product.minimumMet && !product.withinRange) {
                counter++;
            }
        } else {
            // Fee not accepted so all products need to go through validation
            if (!product.minimumMet) {
                counter++;
            }
        }
    });
    return counter === 0;
};

export const getAccounts = (state: any) => {
    const accountIds = state.auth.permissions
        .map((permission) => permission.accountIds.flat())
        .flat();
    const accounts = accountIds.map((account) => +account);
    const accountSet = new Set<number>(accounts);

    return Array.from(accountSet);
};

export const getDeliveryLeadTimeDays = (
    shipToId: number,
    accounts: Account[]
): number | undefined => {
    const selectedAccount = accounts.find((account) => parseInt(account.accountId) === shipToId);
    return selectedAccount?.deliveryLeadTimeDays;
};

export function getTotalQuantityAmounts(type: string, orders?: any[]) {
    let pallets = 0;
    let skus = 0;
    if (type === ProductType.Cans) {
        pallets = 0;
        skus = 0;
        orders?.forEach((order) => {
            pallets += order.canQuantityPallets || 0;
            skus += order?.canQuantitySKUs || 0;
        });
    } else {
        pallets = 0;
        skus = 0;
        orders?.forEach((order) => {
            pallets += order?.endQuantityPallets || 0;
            skus += order?.endQuantitySKUs || 0;
        });
    }
    return {
        pallets: pallets,
        skus: skus
    };
}

export function getEachesForOrder(order: any, type: string) {
    let total = 0;

    if (isProductCanType(type)) {
        order.lines?.forEach((line) => {
            if (isProductCanType(line.productType)) {
                return line.quantityPerPallet && line.palletQuantity
                    ? (total += line.quantityPerPallet * line.palletQuantity)
                    : (total += 0);
            } else {
                return '';
            }
        });
    } else {
        order.lines?.forEach((line) => {
            if (isProductEndType(line.productType)) {
                return line.quantityPerPallet && line.palletQuantity
                    ? (total += line.quantityPerPallet * line.palletQuantity)
                    : (total += 0);
            } else {
                return '';
            }
        });
    }

    return total;
}

export function getTotalQuantityEaches(type: string, orders?: MakeItBulkOrder[]) {
    let total = 0;
    orders?.forEach((order) => {
        total += getEachesForOrder(order, type);
    });
    return total;
}

/**
 *
 * @param orders Orders grouped by week and snoSku.
 * @returns
 */
export const getWeekValidationState: (
    ordersGroup: Array<MakeItBulkATMLinesBySnoSku>
) => DateState = (orders) => {
    const orderValidationStates: Array<DateState> = [];
    orders.forEach((order) => {
        let isEndType = false;
        if (order.lines.length > 0) {
            isEndType = isProductEndType(order.lines[0].type);
        }
        const orderState = isEndType ? 'valid' : getOrderValidationState(order);
        orderValidationStates.push(orderState);
    });
    switch (true) {
        case orderValidationStates.some((state) => state === 'error'):
            return 'error';
        case orderValidationStates.some((state) => state === 'warning'):
            return 'warning';
        default:
            return 'valid';
    }
};

/**
 * Evaluates validity of order within given week.  Used for calculating validation state
 * of the week.
 * @param order
 * @returns
 */
const getOrderValidationState = (order: MakeItBulkATMLinesBySnoSku | undefined): DateState => {
    const palletQuantityStates: PalletQuantityState[] = [];
    const allocationQuantityStates: AllocationQuantityState[] = [];
    let hasDuplicateProducts = false;

    if (order && order.lines) {
        order.lines.forEach((line) => {
            if (!line.deleted) {
                palletQuantityStates.push(
                    evaluatePalletQuantityValid(
                        line.palletQuantity,
                        line.minimumOrderQuantity ?? 0,
                        getFeeStructure(line),
                        getMoqFeeFloor(line.moqFees),
                        getMoqFeeCeiling(line.moqFees)
                    )
                );
                if (!line.isCustomerProductIdDistinct) {
                    hasDuplicateProducts = true;
                }
            }
        });
        if (order.allocationAmount !== undefined) {
            allocationQuantityStates.push(evaluateAllocationQuantityValid(order));
        }
    }
    switch (true) {
        case palletQuantityStates.some((state) => state === 'minimumOrderQuantityError') ||
            allocationQuantityStates.some((state) => state === 'aboveAllocationError') ||
            palletQuantityStates.some((state) => state === 'undefinedQuantity') ||
            hasDuplicateProducts:
            return 'error';
        case palletQuantityStates.some((state) => state === 'feeWarning') ||
            allocationQuantityStates.some((state) => state === 'belowAllocationWarning'):
            return 'warning';
        default:
            return 'valid';
    }
};

export const getRequiredShipDateWarning = (date?: string) => {
    if (date) {
        const today = moment();
        const givenDate = moment(date);
        if (givenDate.diff(today, 'days') <= 0) return false;
        if (givenDate.diff(today, 'days') <= 10) return true;
    }
    return undefined;
};

export const customerPoIsValid = (poNumber: string, isDelivery = false): boolean => {
    if (isDelivery) return !!poNumber.match(/^[a-zA-Z0-9\/_\-\:. ',#()+&;=]{0,25}$/);

    return !!poNumber.match(/^[a-zA-Z0-9\/_\-\:. ',#()+&;=]{1,25}$/);
};
