import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { blackWeight, white } from '../../../../themes/globalConstants';

const useStyles = makeStyles((theme) => ({
    legend: {
        background: white,
        padding: '12px',
        display: 'flex',
        borderRadius: '2px',
        '& ul': {
            margin: '0 0 0 1em',
            padding: 0,
            listStyleType: 'none',
            '& li': {
                display: 'flex',
                alignItems: 'center',
                gap: '4px'
            }
        },
        width: 'fit-content',
        marginLeft: 'auto'
    },
    icon: {
        width: '12px',
        height: '12px'
    },
    title: {
        fontWeight: blackWeight,
        color: theme.palette.secondary.main,
        padding: '0 0 0.5em 0'
    },
}));
const MakeItSpotLegend = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Grid className={classes.legend}>
            <Typography variant="subtitle2" className={classes.title}>
                {t('key', 'Key').toUpperCase()}
            </Typography>
            <ul>
                <li>
                    <img
                        src={process.env.PUBLIC_URL + '/assets/Allocation_None.svg'}
                        alt="No Supply Plan"
                    />
                    <Typography>{t('noSupplyPlan', 'No Supply Plan')}</Typography>
                </li>
                <li>
                    <img
                        src={process.env.PUBLIC_URL + '/assets/Allocation_Over.svg'}
                        alt="Over Supply Plan"
                    />

                    <Typography>{t('overSupplyPlan', 'Over Supply Plan')}</Typography>
                </li>
                <li>
                    <img
                        src={process.env.PUBLIC_URL + '/assets/Allocation_Under.svg'}
                        alt="Under Supply Plan"
                    />

                    <Typography>{t('underSupplyPlan', 'Under Supply Plan')}</Typography>
                </li>
                <li>
                    <img
                        src={process.env.PUBLIC_URL + '/assets/Allocation_Met.svg'}
                        alt="Supply Plan Met"
                    />
                    <Typography>{t('supplyPlanMet', 'Supply Plan Met')}</Typography>
                </li>
            </ul>
        </Grid>
    );
};

export default MakeItSpotLegend;
