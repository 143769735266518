import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { Trans } from 'react-i18next';
import { ballGray } from '../../../../themes/globalConstants';
import { getFormattedDateStringForApplication } from '../../../../utility/helpers/date-helpers';
import { MakeItBulkATMOrder } from '../../../../store/reducers/makeit-bulk-atm';
import { getFormattedEachesFromNumber } from '../../../../utility/helpers/shipment-helpers';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { formatNumberWithLocale } from '../../../../utility/helpers/formatters';
import { formatStatus, orderStatusCodeFormatter } from '../../../../utility/helpers/order-helpers';

interface Props {
    shipToAddress: string;
    shipToName: string;
    shipToId: string;
    order: MakeItBulkATMOrder;
}

const useStyles = makeStyles(() => ({
    main: {
        marginBottom: '1.5em'
    },
    subtitle: {
        color: ballGray
    },
    item: {
        paddingLeft: '1em'
    },
    createdBy: {
        marginTop: '1em',
        marginBottom: '.5em'
    }
}));

const MakeItATMOrderDetailsSubheader = (props: Props) => {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const formatOrderQuantity = (quantity?: number, totalOrderSKUs?: number) => {
        if (totalOrderSKUs === 1) {
            return (
                <Typography>
                    {formatNumberWithLocale(cultureCode, quantity || 0)} PL / {totalOrderSKUs}{' '}
                    <Trans i18nKey="sku">SKU</Trans>
                </Typography>
            );
        } else {
            return (
                <Typography>
                    {formatNumberWithLocale(cultureCode, quantity || 0)} PL / {totalOrderSKUs}{' '}
                    <Trans i18nKey="skus">SKUs</Trans>
                </Typography>
            );
        }
    };

    return (
        <Grid container className={classes.main}>
            <Grid container item xs={5} justify="flex-start">
                <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="shipTo">Ship To</Trans>
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography className="classes.shipToText" data-testid="ship-to-name">
                        {props.shipToName}
                    </Typography>
                    <Typography className="classes.shipToText" data-testid="ship-to-address">
                        {props.shipToAddress}
                    </Typography>
                    <Typography className="classes.shipToText" data-testid="ship-to-id">
                        #{props.shipToId}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item xs={7} justify="flex-end">
                <Grid item xs={2} className={classes.item}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="status">Status</Trans>
                    </Typography>
                    <Typography variant="body1" data-testid="status">
                        <Trans i18nKey={orderStatusCodeFormatter(props.order?.status)}>
                            {formatStatus(props.order?.status)}
                        </Trans>
                    </Typography>
                </Grid>
                <Grid item xs={2} className={classes.item}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="dateSubmitted">Date Submitted</Trans>
                    </Typography>
                    <Typography variant="body1" data-testid="date-submitted">
                        {getFormattedDateStringForApplication(props.order?.createDate)}
                    </Typography>
                </Grid>
                <Grid item xs={2} className={classes.item}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="canQuantity">Can Quantity</Trans>
                    </Typography>
                    <Typography variant="body1" data-testid="can-quantity">
                        {formatOrderQuantity(
                            props.order?.canQuantityPallets,
                            props.order?.canQuantitySKUs
                        )}
                    </Typography>
                    {props.order?.canQuantityEaches ? (
                        <Typography variant="body1" data-testid="can-eaches">
                            {`${getFormattedEachesFromNumber(
                                props.order?.canQuantityEaches,
                                cultureCode
                            )} ea.`}
                        </Typography>
                    ) : (
                        <Typography variant="body1" data-testid="can-eaches">
                            {`0 ea.`}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={2} className={classes.item}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="endQuantity">End Quantity</Trans>
                    </Typography>
                    <Typography variant="body1" data-testid="end-quantity">
                        {formatOrderQuantity(
                            props.order?.endQuantityPallets,
                            props.order?.endQuantitySKUs
                        )}
                    </Typography>
                    {props.order?.endQuantityEaches ? (
                        <Typography variant="body1" data-testid="end-eaches">
                            {`${getFormattedEachesFromNumber(
                                props.order?.endQuantityEaches,
                                cultureCode
                            )} ea.`}
                        </Typography>
                    ) : (
                        <Typography variant="body1" data-testid="end-eaches">
                            {`0 ea.`}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={2} className={classes.item}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="requestedWeek">Requested Week</Trans>
                    </Typography>
                    <Typography variant="body1" data-testid="requested-week">
                        {getFormattedDateStringForApplication(props.order.weekStart)}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.createdBy}>
                <Grid item xs={2}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="createdBy">Created By</Trans>
                    </Typography>
                    <Typography variant="body1" data-testid="created-by">
                        {props.order?.createdBy === 'ETL'
                            ? 'Ball'
                            : props.order?.createdBy ?? props.order?.submittedBy}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default MakeItATMOrderDetailsSubheader;
