import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class makeItPendingPaymentColumns {
    static getMakeItPendingPaymentColumns = (culture: string, showActionsColumn: boolean) => {
        return [
            createColumn('shipToName', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'shipTo'),
                sortable: true,
                filterable: true,
                searchable: true
            }),
            createColumn('invoiceId', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: false
            }),
            createColumn('customerProductionOrderId', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'customerPoNumber'),
                filterable: true,
                sortable: true,
                searchable: true
            }),
            createColumn('ballPONumber', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'ballProdOrderNumber'),
                sortable: true
            }),
            createColumn('searchableBallPONumber', {
                dataType: ColumnDataType.String,
                visible: false,
                filterable: true,
                searchable: true
            }),
            createColumn('productionOrderNumber', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('submittedDate', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'submitted'),
                sortable: true
            }),
            createColumn('submittedDateString', {
                dataType: ColumnDataType.String,
                visible: false,
                filterable: true,
                searchable: true
            }),
            createColumn('requestedDate', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'firstRequestedDate'),
                sortable: true
            }),
            createColumn('requestedDateString', {
                dataType: ColumnDataType.String,
                visible: false,
                filterable: true,
                searchable: true
            }),
            createColumn('paymentDueDate', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'paymentDueDate'),
                sortable: true
            }),
            createColumn('paymentDueDateString', {
                dataType: ColumnDataType.String,
                visible: false,
                filterable: true,
                searchable: true
            }),
            createColumn('revisedAvailableDate', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'revisedAvailableDate'),
                sortable: true
            }),
            createColumn('revisedAvailableDateString', {
                dataType: ColumnDataType.String,
                visible: false,
                filterable: true,
                searchable: true
            }),
            createColumn('shipToId', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('shipToAddress', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            }),
            createColumn('totalOrderQuantity', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'totalOrderQuantity'),
                sortable: true
            }),
            createColumn('actions', {
                label: TranslationService.getTranslatedText(culture, 'actions'),
                visible: showActionsColumn
            }),
            createColumn('productionOrderId', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('totalOrderQuantity', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('totalOrderSKUs', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('orderQuantityString', {
                dataType: ColumnDataType.String,
                visible: false,
                filterable: true,
                searchable: true
            }),
            createColumn('orderQuantityEachesString', {
                dataType: ColumnDataType.String,
                visible: false,
                filterable: true,
                searchable: true
            })
        ];
    };
}
