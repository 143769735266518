import { CapacityCheckStatus } from '../../utility/services/capacity-check-service';
import {
    CAPACITY_CHECKS_LOADING,
    CAPACITY_CHECK_LOADING,
    CAPACITY_CHECK_ERROR,
    CAPACITY_CHECK_SAVED,
    CAPACITY_CHECK_CLEAR,
    CAPACITY_CHECKS_CLEAR,
    CAPACITY_CHECKS_LOADED,
    CAPACITY_CHECK_CLEAR_ERROR,
    CAPACITY_CHECKS_ERROR
} from '../actions/action-types';

export interface CapacityCheck {
    opportunityId: string;
    projectName: string;
    productName: string;
    submittedDate: string;
    status: CapacityCheckStatus;
    varnish?: string;
    inksCoatings?: string;
    beverages?: string;
    fillingTypes?: string;
    productCode?: string;
    endColor?: string;
    endSize?: string;
    tabColor?: string;
    productEnhancement?: string;
}

export interface CapacityCheckOption {
    ballId: string;
    qty: number;
    capacityOptionId: string;
}

export interface CapacityCheckState {
    capacityChecks: any[];
    capacityCheck?: any;
    loading?: boolean;
    loaded?: boolean;
    error?: any;
    status: 'idle' | 'loading' | 'error' | 'success';
}

const initialState: CapacityCheckState = {
    capacityChecks: [],
    capacityCheck: undefined,
    loading: false,
    loaded: false,
    error: undefined,
    status: 'idle'
};

const capacityCheck = (state = initialState, action: any): CapacityCheckState => {
    switch (action.type) {
        case CAPACITY_CHECKS_LOADED:
            return {
                ...state,
                loading: false,
                loaded: true,
                capacityChecks: action.capacityChecks
            };
        case CAPACITY_CHECKS_LOADING:
        case CAPACITY_CHECK_LOADING:
            return {
                ...state,
                loading: true,
                loaded: false,
                error: undefined,
                status: 'loading'
            };
        case CAPACITY_CHECKS_ERROR:
        case CAPACITY_CHECK_ERROR:
            return {
                ...state,
                loading: false,
                loaded: true,
                error: action.error,
                status: 'error'
            };
        case CAPACITY_CHECK_SAVED:
            return {
                ...state,
                loaded: true,
                loading: false,
                error: undefined,
                capacityCheck: action.capacityCheck,
                status: 'success'
            };
        case CAPACITY_CHECK_CLEAR_ERROR:
            return {
                ...state,
                error: undefined,
                status: 'idle'
            };
        case CAPACITY_CHECK_CLEAR:
        case CAPACITY_CHECKS_CLEAR:
            return {
                ...initialState
            };
        default:
            return state;
    }
};

export default capacityCheck;
