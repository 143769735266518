import React, { ReactNode } from 'react';
import { Grid, makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import { blackWeight, medium, regularWeight, ballBlue } from '../../../../themes/globalConstants';
import { InProgressGraphicForTable } from '../models/InProgressGraphicForTable';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { Region } from '../../../../store/reducers/graphic-intake';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { Link as RouterLink } from 'react-router-dom';
import Link from '../../../reusable/atoms/Link';

interface Props {
    graphic: InProgressGraphicForTable;
    onStatusHistoryLookup: (graphicsId: string | undefined) => void;
}

const useStyles = makeStyles((theme) => ({
    bold: {
        fontWeight: blackWeight,
        fontSize: medium,
        letterSpacing: 0.17
    },
    regular: {
        fontWeight: regularWeight,
        fontSize: medium,
        letterSpacing: 0.17
    },
    emeaStatus: {
        color: ballBlue,
        textDecoration: 'underline'
    },
    pointer: {
        cursor: 'pointer'
    }
}));

const GraphicsCompletedRow = ({ graphic, onStatusHistoryLookup }: Props) => {
    const classes = useStyles();
    const { regionCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const getEmeaStatusDetails = () => {
        let tableCell: Array<ReactNode> = [];
        if (graphic.statuses?.length) {
            graphic.statuses.forEach((currentStatus, index) => {
                tableCell.push(
                    <Grid item xs={9} key={`${currentStatus.displayStatus}-${index}`}>
                        <Link
                            component="button"
                            underline="always"
                            data-testid={`status-history-button-${index}`}
                            alignLeft={true}
                            onClick={() => {
                                onStatusHistoryLookup(graphic.graphicsId);
                            }}
                        >
                            <Typography>{currentStatus.displayStatus}</Typography>
                        </Link>
                    </Grid>
                );
            });
        }
        return tableCell;
    };

    return (
        <TableRow hover={true} key={graphic.graphicsId}>
            <TableCell data-testid="graphic-name-id">
                <Grid container>
                    <Grid item xs={12} className={classes.bold}>
                        {regionCode === Region.EU && (
                            <Link
                                className={classes.pointer}
                                underline="always"
                                component={RouterLink}
                                to={`/emea-graphics-pdf/${graphic.graphicsId}`}
                                target="_blank"
                            >
                                {graphic.requestName}
                            </Link>
                        )}
                        {regionCode !== Region.EU && graphic.requestName}
                    </Grid>
                    <Grid item xs={12} className={classes.regular}>
                        {graphic.graphicsNumber}
                    </Grid>
                </Grid>
            </TableCell>
            {regionCode === Region.NA && (
                <>
                    <TableCell data-testid="graphic-status">
                        {graphic.statuses &&
                            graphic.statuses.length > 0 &&
                            graphic.statuses[0].displayStatus}
                    </TableCell>
                </>
            )}
            {regionCode === Region.EU && (
                <>
                    <TableCell data-testid="graphic-status">
                        <Grid container justify="space-between">
                            {getEmeaStatusDetails()}
                        </Grid>
                    </TableCell>
                </>
            )}
        </TableRow>
    );
};

export default GraphicsCompletedRow;
