import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class closedDeliveryOrderColumns {
    static getClosedDeliveryOrderColumns = (culture: string) => {
        return [
            createColumn('deliveryOrderId', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('shipToName', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'shipTo'),
                sortable: true,
                filterable: true,
                searchable: true
            }),
            createColumn('deliveryOrderNumber', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'deliveryOrderNumber'),
                sortable: true,
                searchable: false
            }),
            createColumn('deliveryOrderNumberString', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            }),
            createColumn('status', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'status'),
                sortable: true
            }),
            createColumn('reviewStatus', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: false
            }),
            createColumn('shipmentDeliveryDateTime', {
                dataType: ColumnDataType.String,
                label: 'Delivery Week',
                sortable: true,
                filterable: true
            }),
            createColumn('formattedShipmentDeliveryDateTime', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            }),
            createColumn('orderQuantityText', {
                dataType: ColumnDataType.String,
                label: 'Total Delivery Quantity',
                sortable: true,
                searchable: true
            }),
            createColumn('orderQuantityContent', {
                dataType: ColumnDataType.String,
                sortable: true,
                searchable: false,
                visible: false
            }),
            createColumn('totalDeliveryQuantity', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('shipToId', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('shipToAddress', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            }),
            createColumn('totalShipments', {
                dataType: ColumnDataType.Numeric,
                visible: false,
                searchable: false
            }),
            createColumn('totalOrderQuantity', {
                dataType: ColumnDataType.Numeric,
                visible: false,
                searchable: false
            }),
            createColumn('totalOrderSkus', {
                dataType: ColumnDataType.Numeric,
                visible: false,
                searchable: false
            }),
            createColumn('searchTerms', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true
            })
        ];
    };
}
