import React, { ReactElement, useMemo, useState } from 'react';
import moment from 'moment';
import { Grid, makeStyles, TableCell, TableRow } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Trans } from 'react-i18next';

import Button from '../../../reusable/atoms/Button';
import Link from '../../../reusable/atoms/Link';
import StatusLabel from '../../ManageProducts/components/StatusLabel';
import ManageCommunicationsContextMenu from './ManageCommunicationsContextMenu';
import ConfirmationModal from '../../../reusable/molecules/ConfirmationModal';
import { regularWeight } from '../../../../themes/globalConstants';
import { StandardTableStyles } from '../../../reusable/molecules/StandardTable';
import { communicationColumnsWidthPercentage } from './ManageCommunicationsTable';
import { updateCommunicationStatus } from '../../../../store/actions/communications';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { Region } from '../../../../store/reducers/graphic-intake';

interface Props {
    row: any;
}

const useStyles = makeStyles((theme) => {
    return {
        ...StandardTableStyles(theme, communicationColumnsWidthPercentage),
        regularWeight: {
            fontWeight: regularWeight,
            '& *': {
                fontWeight: regularWeight
            }
        },
        titleLink: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            display: 'inline-block',
            width: '-webkit-fill-available'
        }
    };
});

export default function ManageCommunicationsTableRow({ row }: Props) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [communicationActionMenuAnchor, setCommunicationActionMenuAnchor] =
        useState<null | HTMLElement>(null);
    const [messageStatusUpdateModal, setMessageStatusUpdateModal] = useState<boolean>(false);
    const [modalTitle, setModalTitle] = useState<ReactElement>();
    const [modalSubheader, setModalSubheader] = useState<ReactElement>();
    const [updatedStatus, setUpdatedStatus] = useState<boolean>(
        row.status === 'Active' ? false : true
    );
    const { regionCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { dateFormat, timeFormat } = useMemo(() => {
        return {
            dateFormat: regionCode === Region.NA ? 'MM/DD/YYYY' : 'DD/MM/YYYY',
            timeFormat: regionCode === Region.NA ? 'hh:mm A' : 'HH:mm'
        };
    }, [regionCode]);

    const onOpenMessageStatusUpdateModal = (status: boolean) => {
        if (status) {
            setModalTitle(<Trans i18nKey="makeMessageActive">Make Message Active</Trans>);
            setModalSubheader(
                <Trans i18nKey="makeMessageActiveConfirmation">
                    You are about to enable this message.
                    <br />
                    Are you sure you want to continue?
                </Trans>
            );
        } else {
            setModalTitle(<Trans i18nKey="makeMessageInactive">Make Message Inactive</Trans>);
            setModalSubheader(
                <Trans i18nKey="makeMessageInactiveConfirmation">
                    You are about to disable this message.
                    <br />
                    Are you sure you want to continue?
                </Trans>
            );
        }
        setUpdatedStatus(status);
        setMessageStatusUpdateModal(true);
    };
    const onCloseMessageStatusUpdateModal = () => {
        setMessageStatusUpdateModal(false);
    };

    const handleCommunicationActionMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setCommunicationActionMenuAnchor(event.currentTarget);
    };

    const handleEditMessage = () => {
        setCommunicationActionMenuAnchor(null);
        history.push(`/configure-communication/${row.communicationId}`);
    };

    const handleStatusChangeMessage = () => {
        setCommunicationActionMenuAnchor(null);
        dispatch(updateCommunicationStatus(row.communicationId, updatedStatus));
        setMessageStatusUpdateModal(false);
    };

    return (
        <>
            <TableRow>
                <TableCell data-testid="messageType">{row.messageType}</TableCell>
                <TableCell data-testid="createdBy" className={classes.regularWeight}>
                    {row.createdBy}
                </TableCell>
                <TableCell data-testid="title">
                    <Link
                        to={`/configure-communication/${row.communicationId}`}
                        underline="none"
                        component={RouterLink}
                        className={classes.titleLink}
                        displayBlock
                    >
                        {row.title}
                    </Link>
                </TableCell>
                <TableCell data-testid="startDateTime" className={classes.regularWeight}>
                    {moment.utc(row.startDateTime).local().format(dateFormat)}
                    <br />
                    {moment.utc(row.startDateTime).local().format(timeFormat)}
                </TableCell>
                <TableCell data-testid="endDateTime" className={classes.regularWeight}>
                    {moment.utc(row.endDateTime).local().format(dateFormat)}
                    <br />
                    {moment.utc(row.endDateTime).local().format(timeFormat)}
                </TableCell>
                <TableCell data-testid="status">
                    <StatusLabel line={row} />
                </TableCell>
                <TableCell data-testid="actions">
                    <Grid container justify="center" alignItems="center">
                        <Button
                            onClick={handleCommunicationActionMenuClick}
                            data-testid="action-menu-button"
                        >
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/ellipsis.svg`}
                                alt={`Action menu for ${row.communicationId}`}
                            />
                        </Button>
                    </Grid>
                </TableCell>
            </TableRow>
            <ManageCommunicationsContextMenu
                onEditCommunication={() => handleEditMessage()}
                onEnableCommunication={() => onOpenMessageStatusUpdateModal(true)}
                onDisableCommunication={() => onOpenMessageStatusUpdateModal(false)}
                onMenuBlur={() => setCommunicationActionMenuAnchor(null)}
                anchorEl={communicationActionMenuAnchor}
                row={row}
            ></ManageCommunicationsContextMenu>

            <ConfirmationModal
                title={modalTitle}
                open={messageStatusUpdateModal}
                logo={false}
                subheader={modalSubheader}
                cancelText={<Trans i18nKey="cancel">Cancel</Trans>}
                continueText={<Trans i18nKey="yes">Yes</Trans>}
                saveProgress={() => handleStatusChangeMessage()}
                onClose={onCloseMessageStatusUpdateModal}
                onCancel={onCloseMessageStatusUpdateModal}
                data-testid="update-message-status-modal"
            />
        </>
    );
}
