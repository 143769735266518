import React from 'react';
import ContentTabs from '../../../reusable/molecules/ContentTabs';
import { ContentSubPage } from '../../../../store/reducers/content-page';
import { createContentTabs } from '../../../../utility/helpers/tab-helpers';

interface Props {
    subPages: ContentSubPage[];
}

export default function TabbedPageTemplate({ subPages }: Props) {
    return (
        <ContentTabs tabs={createContentTabs(subPages)} />
    );
}
