import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import {
    boldWeight,
    blackWeight,
    ballBlue,
    dateOptionInvalidGray,
    small,
    medium,
    regularWeight
} from '../../../../themes/globalConstants';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { getAccountAddress } from '../../../../utility/helpers/address-helpers';
import {
    DeliveryOrderRow,
    ShippingSummaryState
} from '../../../../store/reducers/shipping-summary';
import { DeliveryOrder } from '../../../../store/reducers/shipping-dashboard';
import ShipItOpenDeliveriesRow from './ShipItOpenDeliveriesRow';
import openDeliveryOrderColumns from './OpenDeliveryOrderColumns';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import {
    getFormattedDateStringForApplication,
    getCurrentDate
} from '../../../../utility/helpers/date-helpers';
import moment from 'moment';
import {
    formatOrderQuantity,
    getDeliveryLeadTimeDays,
    getSearchableOrderQuantityString
} from '../../../../utility/helpers/order-helpers';
import TranslationService from '../../../../utility/services/translation-service';
import EditShipmentsDisclaimerSection from '../../EditShipmentsSummary/components/EditShipmentsDisclaimerSection';
import { useQuery } from '../../../../utility/helpers/query-helpers';
import {
    formatDeliveryWeek,
    getConvertedShipmentDate,
    getFilteredDeliveryOrdersByDateRange
} from '../../../../utility/helpers/shipment-helpers';
import { usePrevious } from '../../../../utility/helpers/react-util';
import ClearFilterWarning from '../../../reusable/molecules/ClearFilterWarning';
import { useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import SummarySearchBar from '../../../reusable/atoms/SummarySearchBar';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { CSVLink } from 'react-csv';
import ReactPdf from '@react-pdf/renderer';
import StandardTable from '../../../reusable/molecules/StandardTable';

interface Props {
    onFilteredOrdersChanged: (numOrders: number) => void;
}

const useStyles = makeStyles((theme) => ({
    disclaimerSectionWrapper: {
        paddingLeft: '2em',
        marginBottom: '-1.25em'
    },
    paper: {
        marginBottom: '1em'
    },
    title: {
        fontSize: '1.5em',
        fontWeight: boldWeight,
        color: theme.palette.secondary.main,
        paddingLeft: '0.625em'
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '1em'
    },
    tableWrapper: {
        '& .MuiPaper-root': {
            boxShadow: 'unset'
        },
        '& .MuiToolbar-root': {
            padding: 0,
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                },
                '&:first-of-type ': {
                    paddingLeft: 0
                }
            },
            '& > h2': {
                fontSize: '1.5em',
                fontWeight: boldWeight,
                color: theme.palette.secondary.main,
                paddingLeft: '0.625em'
            },
            '& .MuiInputBase-formControl': {
                border: `1px solid ${dateOptionInvalidGray}`,
                borderRadius: '.125em',
                '&:before': {
                    display: 'none'
                },
                '&:after': {
                    display: 'none'
                },
                '& svg': {
                    height: '.875em',
                    width: '.875em',
                    fill: ballBlue
                },
                '& .MuiIconButton-root': {
                    '&:hover': {
                        backgroundColor: 'transparent !important'
                    }
                },
                '& .MuiInput-input': {
                    paddingLeft: '.25em'
                }
            }
        },
        '& .MuiTableSortLabel-root': {
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em',
            width: '99%',
            justifyContent: 'space-between',
            '& .MuiSvgIcon-root': {
                fontSize: 14,
                opacity: '1'
            }
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                '&:nth-child(1)': {
                    width: '29%'
                },
                '&:nth-child(2)': {
                    width: '12%'
                },
                '&:nth-child(3)': {
                    width: '10%'
                },
                '&:nth-child(4)': {
                    width: '8%'
                },
                '&:nth-child(5)': {
                    width: '8%'
                },
                '&:nth-child(6)': {
                    width: '18%'
                },
                '&:nth-child(7)': {
                    width: '15%',
                    fontSize: small,
                    fontWeight: blackWeight,
                    textTransform: 'uppercase',
                    color: theme.palette.secondary.main,
                    borderRadius: '0.25em',
                    paddingLeft: '0.5em',
                    justifyContent: 'space-between'
                }
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    },
    icon: {
        color: ballBlue
    },
    downloadWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

const pdfStyles = ReactPdf.StyleSheet.create({
    link: {
        fontSize: medium,
        fontWeight: regularWeight
    }
});

const ShipItOpenDeliveriesTab = ({ onFilteredOrdersChanged }: Props) => {
    const classes = useStyles();
    const query = useQuery();
    const history = useHistory();
    const { t } = useTranslation();
    const showEditCancel = true;

    // startWeek (optional) is a number n from 0-4
    // when n = 0-4, show delivery orders for week of currentWeek+n (note: Monday to Sunday week)
    // “Week of currentWeek+n”
    // Ex: today is 7/21 aka week of 7/19 (Monday), so n = 1 would show order for the week of 7/26
    const startWeek = query.get('startWeek');
    // when filterOrders='future', show all future delivery orders
    const filterOrders = query.get('filterOrders');

    const [columns, setColumns] = useState<any>([]);
    const [formattedOpenOrders, setFormattedOpenOrders] = useState<DeliveryOrderRow[]>([]);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [leadTime, setLeadTime] = useState<number>();
    const [showClearFilterWarning, setShowClearFilterWarning] = useState<boolean>(false);
    const [filteredOrders, setFilteredOrders] = useState<DeliveryOrderRow[]>([]);

    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { shipToAccounts, selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { openDeliveryOrders } = useTypedSelector<ShippingSummaryState>(
        (state) => state.shippingSummary
    );

    const prevSelectedShipTo = usePrevious(selectedAccountId);

    useEffect(() => {
        if (selectedAccountId && shipToAccounts) {
            setLeadTime(getDeliveryLeadTimeDays(parseInt(selectedAccountId), shipToAccounts));
        }
    }, [selectedAccountId, shipToAccounts]);

    useEffect(() => {
        if (!formattedOpenOrders.length || !isLoaded || prevSelectedShipTo !== selectedAccountId) {
            let week = startWeek ? parseInt(startWeek) : undefined;
            formatOpenOrders(openDeliveryOrders, week);
        }
    }, [openDeliveryOrders, shipToAccounts, startWeek, selectedAccountId]);

    const getShipToName = (deliveryOrderId?: number) => {
        if (shipToAccounts && openDeliveryOrders) {
            let filteredOrders = openDeliveryOrders.filter(
                (ord) => deliveryOrderId === ord.deliveryOrderId
            );
            const order = filteredOrders[0];
            let filteredAccounts = shipToAccounts.filter(
                (shipToAccount) => order.shipToId === +shipToAccount.accountId
            );

            if (filteredAccounts[0]) {
                return filteredAccounts[0].name;
            }
        }
    };

    const getShipToAddress = (deliveryOrderId?: number) => {
        if (shipToAccounts && openDeliveryOrders) {
            let filteredOrders = openDeliveryOrders.filter(
                (ord) => deliveryOrderId === ord.deliveryOrderId
            );
            const order = filteredOrders[0];
            let filteredAccounts = shipToAccounts.filter(
                (shipToAccount) => order.shipToId === +shipToAccount.accountId
            );

            if (filteredAccounts[0]) {
                return getAccountAddress(filteredAccounts[0]);
            }
        }
    };

    const formatOpenOrders = (unformattedOrders: DeliveryOrder[], startWeek?: number) => {
        let formattedOrders: DeliveryOrderRow[] = [];
        unformattedOrders.forEach((order) => {
            if (order.shipToId && order.shipToId.toString() === selectedAccountId) {
                let newOrder: DeliveryOrderRow;
                let formattedDeliveryWeek = formatDeliveryWeek(order?.deliveryWeekDate);
                newOrder = {
                    ...order,
                    shipToName: getShipToName(order.deliveryOrderId),
                    shipToAddress: getShipToAddress(order.deliveryOrderId),
                    deliveryWeekDate: moment(order?.deliveryWeekDate)
                        .startOf('day')
                        .startOf('isoWeek'),
                    shipmentDeliveryDateTime:
                        moment(formattedDeliveryWeek).format('YYYY-MM-DDTHH:mm:ssZ'),
                    earliestShipmentDate: getConvertedShipmentDate(
                        order,
                        order.earliestShipmentDate
                    ),
                    latestShipmentDate: getConvertedShipmentDate(order, order.latestShipmentDate),
                    formattedShipmentDeliveryDateTime: formattedDeliveryWeek,
                    orderQuantityText: getSearchableOrderQuantityString(
                        cultureCode,
                        order.totalShipments,
                        order.totalOrderQuantity,
                        order.totalOrderSkus,
                        order.totalOrderQuantityEaches
                    ),
                    orderQuantityContent: formatOrderQuantity(
                        cultureCode,
                        order.totalShipments,
                        order.totalOrderQuantity,
                        order.totalOrderSkus,
                        order.totalOrderQuantityEaches
                    ),
                    formattedSubmittedDate: getFormattedDateStringForApplication(
                        order.submittedDate
                    ),
                    deliveryOrderNumber: order.deliveryOrderNumber
                        ? order.deliveryOrderNumber
                        : '0',
                    deliveryOrderNumberString: order.deliveryOrderNumber
                        ? order.deliveryOrderNumber.toString()
                        : 'pending',
                    pristineDeliveryOrder: order
                };
                formattedOrders.push(newOrder);
            }
        });
        if (startWeek !== undefined) {
            setShowClearFilterWarning(true);
            let startDate = moment().startOf('day').startOf('isoWeek').add(startWeek, 'week');
            let endDate = moment()
                .endOf('day')
                .startOf('isoWeek')
                .add(startWeek, 'week')
                .add(6, 'days');
            formattedOrders = getFilteredDeliveryOrdersByDateRange(
                formattedOrders,
                startDate,
                endDate
            );
        }
        if (filterOrders === 'future') {
            setShowClearFilterWarning(true);
            formattedOrders = getFilteredDeliveryOrdersByDateRange(
                formattedOrders,
                moment().startOf('day').startOf('isoWeek'),
                undefined
            );
        }
        setFormattedOpenOrders(formattedOrders);
        onFilteredOrdersChanged(formattedOrders.length);
        setIsLoaded(true);
    };

    const handleClearFilter = () => {
        history.push('/ship-it-summary');
    };

    const ToolbarCustomItems = (
        <ClearFilterWarning
            showWarning={showClearFilterWarning}
            warningText={t('dataIsFiltered', 'Data is filtered. Click icon to clear filter: ')}
            handleClearFilter={handleClearFilter}
        />
    );

    const OpenProductionOrderRow = ({ row }: { row: DeliveryOrderRow }) => {
        if (row) {
            return (
                <ShipItOpenDeliveriesRow
                    order={row as DeliveryOrderRow}
                    pristineDeliveryOrder={row.pristineDeliveryOrder}
                    key={row.deliveryOrderId}
                    showEditCancel={showEditCancel}
                />
            );
        } else {
            return null;
        }
    };

    useEffect(() => {
        setColumns(
            openDeliveryOrderColumns.getOpenDeliveryOrderColumns(cultureCode, showEditCancel)
        );
    }, [cultureCode, showEditCancel]);

    const handleRowFilter = (filteredRows) => {
        setFilteredOrders(filteredRows);
    };

    interface CSVRow {
        ship_to_detail?: string;
        delivery_order_number?: string;
        submitted?: string;
        next_delivery_week?: string;
        total_delivery_quantity?: string;
        status?: string;
    }

    const csvHeaders = [
        { label: t('shipTo', 'Ship to').toUpperCase(), key: 'ship_to_detail' },
        {
            label: t('deliveryOrderNumber', 'Delivery Order #').toUpperCase(),
            key: 'delivery_order_number'
        },
        { label: t('status', 'STATUS').toUpperCase(), key: 'status' },
        { label: t('submitted', 'SUBMITTED').toUpperCase(), key: 'submitted' },
        {
            label: t('nextDeliveryWeek', 'Next Delivery Week').toUpperCase(),
            key: 'next_delivery_week'
        },
        {
            label: t('totalDeliveryQuantity', 'Total Delivery Quantity').toUpperCase(),
            key: 'total_delivery_quantity'
        }
    ];
    const csvArray = () => {
        let rows: any[] = [];
        if (!Array.isArray(filteredOrders) || filteredOrders.length === 0) return rows;
        filteredOrders.map((order) => {
            const row: CSVRow = {
                ship_to_detail: `${order.shipToName}, ${order.shipToAddress}, #${order.shipToId}`,
                delivery_order_number: order.deliveryOrderNumberString?.toUpperCase(),
                submitted: order.formattedSubmittedDate,
                next_delivery_week: order.formattedShipmentDeliveryDateTime,
                total_delivery_quantity: order.orderQuantityText,
                status: order.status
            };

            rows.push(row);
            return null;
        });
        return rows;
    };
    const csvContents = csvArray();

    return (
        <Paper elevation={2} className={classes.paper}>
            <div className={classes.titleContainer}>
                <Typography className={classes.title}>
                    {TranslationService.getTranslatedText(cultureCode, 'openDeliveryOrders')}
                </Typography>
                <Grid className={classes.downloadWrapper}>
                    <CSVLink
                        className={
                            'MuiTypography-root MuiLink-root MuiLink-underlineAlways MuiTypography-colorPrimary'
                        }
                        style={pdfStyles.link}
                        underline="always"
                        data-testid="download-btn"
                        headers={csvHeaders}
                        data={csvContents}
                        filename={`Ship it Summary - Open Delivery Order -${getCurrentDate()}.csv`}
                    >
                        <Trans i18nKey="downloadCSV">Download .CSV</Trans>
                    </CSVLink>
                    <GetAppOutlinedIcon fontSize="small" className={classes.icon} />
                </Grid>
            </div>
            {showEditCancel && (
                <div
                    className={classes.disclaimerSectionWrapper}
                    data-testid="edit-shipments-disclaimer"
                >
                    <EditShipmentsDisclaimerSection leadTime={leadTime} />
                </div>
            )}
            <SummarySearchBar
                rows={formattedOpenOrders || []}
                columns={columns}
                onRowFilter={handleRowFilter}
            />
            {formattedOpenOrders && isLoaded && (
                <Grid
                    container
                    className={classes.tableWrapper}
                    data-testid="open-delivery-order-grid"
                >
                    <StandardTable
                        gridName="Open Orders"
                        columns={columns}
                        lines={filteredOrders}
                        rowComponent={OpenProductionOrderRow}
                        searchText={false}
                        customToolbarItems={ToolbarCustomItems}
                    />
                </Grid>
            )}
        </Paper>
    );
};

export default ShipItOpenDeliveriesTab;
