import React, { useEffect, useState } from 'react';
import { Grid, Input, makeStyles } from '@material-ui/core';
import { QuantityUnit } from '../../../store/reducers/shipping-dashboard';
import { ballLtGray, white } from '../../../themes/globalConstants';
import {
    getConvertedQuantityUnitPallets,
    getFormattedSelectedQuantityUnit
} from '../../../utility/helpers/shipment-helpers';
import { formatNumberWithLocale } from '../../../utility/helpers/formatters';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { RegionCultureState } from '../../../store/reducers/region-culture';

interface Props {
    quantity?: number;
    quantityUnit: QuantityUnit;
    quantityPerPallet: number;
    onBlur: (string) => void;
    onChange: (string) => void;
    disabled?: boolean;
    eachesLimit?: number;
    palletsLimit?: number;
    showEmptyCellOnUndefined?: boolean;
    hasError?: boolean;
    hasWarning?: boolean;
}

const useStyles = makeStyles(() => ({
    textField: {
        width: '7.5em',
        marginLeft: 'auto',
        height: '1.5em',
        border: `2px solid ${ballLtGray}`,
        borderRadius: '.25em',
        backgroundColor: white
    },
    paletteInputContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '4px',
        flexGrow: 1
    },
    unitLabel: {
        minWidth: '1.25em',
        minHeight: '1.5em',
        marginLeft: '0.5em;'
    }
}));

export default function QuantityInputCell({
    quantityUnit,
    quantityPerPallet,
    quantity,
    onBlur,
    onChange,
    disabled,
    eachesLimit,
    palletsLimit,
    showEmptyCellOnUndefined = false,
    hasError = false,
    hasWarning = false
}: Props) {
    const classes = useStyles();
    const [quantityValue, setQuantityValue] = useState<number | undefined | string>(undefined);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    useEffect(() => {
        if (typeof quantity === 'undefined' && showEmptyCellOnUndefined) {
            setQuantityValue('');
        } else {
            setQuantityValue(quantity);
        }
    }, [quantity]);

    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        // allow only numeric characters
        if (event.charCode < 48 || event.charCode > 57) {
            event.preventDefault();
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (
            (palletsLimit &&
                quantityUnit === 'pallets' &&
                event.target.value.length > palletsLimit) ||
            (eachesLimit && quantityUnit === 'eaches' && event.target.value.length > eachesLimit)
        ) {
            event.preventDefault();
        } else {
            onChange(event.target.value);
        }
    };

    return (
        <Grid container justify="flex-end" alignContent="center" direction="column">
            <Grid item className={classes.paletteInputContainer}>
                {hasError && (
                    <img
                        src={process.env.PUBLIC_URL + '/assets/Error_Icon.svg'}
                        alt="Error Icon"
                        style={{ marginRight: 'auto' }}
                    />
                )}
                {hasWarning && (
                    <img
                        src={process.env.PUBLIC_URL + '/assets/Warning_Icon.svg'}
                        alt="Warning Icon"
                        style={{ marginRight: 'auto' }}
                    />
                )}
                {quantityUnit === 'pallets' && (
                    <Input
                        id="quantity-input"
                        inputProps={{
                            'data-testid': 'quantity-input-pallets'
                        }}
                        type="text"
                        className={classes.textField}
                        disableUnderline={true}
                        onBlur={(e) => onBlur(e.target.value)}
                        onChange={(e) => handleChange(e)}
                        onKeyPress={(e) => handleKeyPress(e)}
                        value={quantityValue}
                        disabled={disabled}
                    />
                )}
                {quantityUnit === 'eaches' && (
                    <span data-testid="converted-quantity-display">
                        {typeof quantity !== 'undefined'
                            ? formatNumberWithLocale(
                                  cultureCode,
                                  Number(
                                      getConvertedQuantityUnitPallets(
                                          'eaches',
                                          quantity,
                                          quantityPerPallet
                                      )
                                  )
                              )
                            : ''}
                    </span>
                )}
                <span className={classes.unitLabel}>
                    {getFormattedSelectedQuantityUnit('pallets')}
                </span>
            </Grid>
            <Grid item className={classes.paletteInputContainer}>
                {quantityUnit === 'eaches' && (
                    <Input
                        id="quantity-input"
                        inputProps={{
                            'data-testid': 'quantity-input-eaches'
                        }}
                        type="text"
                        className={classes.textField}
                        disableUnderline={true}
                        onBlur={(e) => onBlur(e.target.value)}
                        onChange={(e) => handleChange(e)}
                        onKeyPress={(e) => handleKeyPress(e)}
                        value={quantityValue}
                        disabled={disabled}
                    />
                )}

                {quantityUnit === 'pallets' && (
                    <span data-testid="converted-quantity-display">
                        {typeof quantity !== 'undefined'
                            ? formatNumberWithLocale(
                                  cultureCode,
                                  Number(
                                      getConvertedQuantityUnitPallets(
                                          'pallets',
                                          quantity,
                                          quantityPerPallet
                                      )
                                  )
                              )
                            : ''}
                    </span>
                )}
                <span className={classes.unitLabel}>
                    {getFormattedSelectedQuantityUnit('eaches')}
                </span>
            </Grid>
        </Grid>
    );
}
