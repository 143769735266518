import React from 'react';
import { getEachesConversion } from '../../../utility/helpers/shipment-helpers';

/**
 * Example Usage:
 * <EachesConversion palletQuantity={4} quantityPerPallet={120} />
 */

interface Props {
    palletQuantity: number;
    quantityPerPallet: number;
    label?: boolean;
}

export default function EachesConversion({
    palletQuantity,
    quantityPerPallet,
    label = true
}: Props) {
    return <>{getEachesConversion(palletQuantity, quantityPerPallet, label)}</>;
}
