import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getShipToAccounts } from '../../../store/actions/customer-context';
import { AuthState } from '../../../store/reducers/auth';
import {
    CustomerContextState,
    FormattedShipToAccount,
    Account as ShipToAccount
} from '../../../store/reducers/customer-context';
import { useTypedSelector } from '../../../store/reducers/reducer';
import {
    Activity,
    filterShipToIdsByPermission,
    SecurityLevel
} from '../../../utility/auth/useSecurity';
import ShipToAddressGrid from './components/ShipToAddressGrid';
import { getAccountAddress } from '../../../utility/helpers/address-helpers';
import { useParams } from 'react-router';
import { DefaultQueryParams } from '../../../utility/helpers/query-helpers';
import { DataValidationDisplayTemplate } from '../../templates/DataValidationDisplayTemplate';

const ShipToAddressList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { accounts, shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const auth = useTypedSelector<AuthState>((state) => state.auth);
    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);
    const [formattedShipToAccounts, setFormattedShipToAccounts] = useState<
        undefined | FormattedShipToAccount[]
    >(undefined);

    const { id } = useParams<DefaultQueryParams>();

    const permission =
        id === 'make-it'
            ? Activity.NewOpenProductionOrders
            : id === 'plan-it'
            ? Activity.PlanItBulkUpload
            : Activity.NewOpenDeliveryOrders;

    const formatShipToAccounts = (unformattedShipTos: ShipToAccount[]) => {
        let formattedShipToList: FormattedShipToAccount[] = [];
        unformattedShipTos.forEach((account) => {
            let accountRow = {
                ...account,
                sortableAccountId: parseInt(account.accountId),
                formattedAddress: getAccountAddress(account)
            };
            formattedShipToList.push(accountRow);
        });
        setFormattedShipToAccounts(formattedShipToList);
    };

    const getAccounts = () => {
        let allAccounts: number[] = [];
        if (auth.permissions) {
            const accountIds = auth.permissions.map((x) => x.accountIds.flat()).flat();
            const accounts = accountIds.map((x) => +x);
            allAccounts = accounts;
        }
        return allAccounts;
    };

    useEffect(() => {
        if (permissions && !accounts?.length) {
            dispatch(getShipToAccounts());
        }
    }, [dispatch, accounts, permissions]);

    useEffect(() => {
        if (
            shipToAccounts !== undefined &&
            shipToAccounts.length > 0 &&
            formattedShipToAccounts === undefined
        ) {
            let permissionsBasedShipToAccounts: number[];
            let accounts = getAccounts();
            if (accounts) {
                permissionsBasedShipToAccounts = filterShipToIdsByPermission(
                    auth,
                    accounts,
                    permission,
                    SecurityLevel.View
                );
                let filteredShipToAccounts: ShipToAccount[] = [];
                shipToAccounts.forEach((shipToAccount) => {
                    let accountFound = permissionsBasedShipToAccounts.find(
                        (account) => account.toString() === shipToAccount.accountId
                    );
                    if (accountFound) {
                        filteredShipToAccounts.push(shipToAccount);
                    }
                });
                formatShipToAccounts(filteredShipToAccounts);
            }
        }
    }, [shipToAccounts, formattedShipToAccounts]);

    return (
        <DataValidationDisplayTemplate
            banner={{
                header: t('yourAssignedShipToLocations', 'Your Assigned Ship To Locations'),
                description: '',
                thinBanner: true,
                displayDropdown: false
            }}
            activity={permission}
            shipTos={false}
        >
            <ShipToAddressGrid shipToAccounts={formattedShipToAccounts ?? []} />
        </DataValidationDisplayTemplate>
    );
};

export default ShipToAddressList;
