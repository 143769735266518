import { Grid, Typography, makeStyles, TextareaAutosize } from '@material-ui/core';
import React, { useEffect, useState, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { medium, paleBlue, paleGrey, xl, boldWeight } from '../../../../themes/globalConstants';
import FileUpload from '../../../reusable/molecules/FileUpload';
import FormSection from '../../../reusable/molecules/FormSection';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { removeExistingFile, setFileSection } from '../../../../store/root-actions';
import { useDispatch } from 'react-redux';
import { Region } from '../../../../store/reducers/graphic-intake';
import { Graphics } from '../../../../store/reducers/graphic-intake';
import { Controller, useFormContext } from 'react-hook-form';

interface Props {
    region: string;
    isEditDraft: boolean;
    graphicData?: Graphics;
}

const useStyles = makeStyles((theme) => ({
    sectionMargin: {
        margin: '2em 0'
    },
    label: {
        color: theme.palette.info.dark,
        fontSize: medium,
        padding: '0.5em'
    },
    uploadContainer: {
        margin: '2em 0 1em 0'
    },
    bottomContainer: {
        backgroundColor: paleBlue,
        padding: '2em',
        marginTop: '1em'
    },
    textAreaMargin: {
        margin: '0 0'
    },
    textArea: {
        width: '100%',
        boxSizing: 'border-box',
        padding: '18px 14px',
        fontSize: 14,
        border: '1px solid',
        borderColor: theme.palette.secondary.light,
        fontFamily: "'Lato', 'Helvetica', 'Arial', sans-serif",
        '&:focus': {
            outline: 'none',
            border: '1px solid',
            borderColor: theme.palette.primary.main
        }
    },
    error: {
        color: 'red'
    }
}));

const uploadStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: paleGrey,
        height: '11.5em',
        justifyContent: 'center',
        display: 'flex',
        cursor: 'pointer'
    },
    divContainer: {
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%'
    },
    invalidText: {
        color: 'red'
    },
    icon: {
        height: '1.875em',
        width: '1.4em',
        fill: theme.palette.secondary.main
    },
    text: {
        fontSize: xl,
        fontWeight: boldWeight,
        letterSpacing: '0.13px'
    },
    fileLabel: {
        fontSize: medium,
        marginTop: '1em',
        fontWeight: boldWeight
    },
    fileName: {
        marginTop: '1em',
        marginLeft: '0.5em'
    }
}));

const UploadGraphics = ({ region, isEditDraft, graphicData }: Props) => {
    const classes = useStyles();
    const graphicIntake = useTypedSelector((state) => state.graphicIntake);

    const [files, setFiles] = useState<File[]>();
    const [isFileReset, setIsFileReset] = useState<boolean>(false);
    const [showUploadError, setShowUploadError] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { control, setValue } = useFormContext();

    const COMMENT_MAX_LENGTH = 500;

    const storeFiles = (fileData) => {
        if (fileData === undefined) {
            setFiles(fileData);
            setShowUploadError(false);
            dispatch(setFileSection(fileData));
        } else if (fileData.length > 0) {
            setFiles(fileData);
            setShowUploadError(false);
            dispatch(setFileSection(fileData));
        } else {
            if (files) {
                // clear out previous selection
                setFiles(undefined);
                dispatch(setFileSection(undefined));
            }
            setShowUploadError(true);
        }
    };

    let acceptedFileTypes: string;
    let invalidFileMessage: string | undefined;
    if (region !== Region.EU) {
        // Only accepting zip file types for NA
        acceptedFileTypes = 'application/x-zip-compressed, application/zip';
        invalidFileMessage = t(
            'invalidIntakeFile',
            'This file type is invalid. Please attach a .zip file.'
        );
    } else {
        // react-dropzone's drag operation doesn't recognize file extensions and
        // validates only using mime type. But the mime type for Adobe photoshop
        // is not recognized. Therefore, the invalidFileMessage is not used for EMEA.
        // onUploadFileErrorEmea will display below the control for invalid files
        acceptedFileTypes = '.ai, .doc, .docx, .pdf, .psd, .xls, .xlsx, .zip';
        invalidFileMessage = undefined;
    }

    useEffect(() => {
        if (isEditDraft && graphicData) {
            setValue('fileComment', graphicData.fileComment);
        }
    }, [isEditDraft, graphicData, setValue]);

    useEffect(() => {
        if (files && files.length > 0 && !graphicIntake.images?.length) {
            setIsFileReset(true);
        }
    }, [graphicIntake.images]);

    const onFileReset = () => {
        setIsFileReset(false);
    };

    // Cache comment value in ref. If input value is too long, return cache instead.
    const comment = useRef<string>('');
    const handleCommentChange = (event) => {
        const value = event[0]?.target?.value || '';
        if (value.length >= COMMENT_MAX_LENGTH) {
            return comment.current;
        } else {
            comment.current = value;
            return value;
        }
    };

    const onRemoveExisting = (index: number) => {
        dispatch(removeExistingFile(index));
    };
    return (
        <FormSection
            testId={'upload-section'}
            sectionHeader={
                <Trans i18nKey="uploadGraphicsAndSubmit">Upload Graphics & Submit</Trans>
            }
        >
            {region !== Region.EU ? (
                <>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>
                            <Trans i18nKey="acceptedFileTypes">
                                Accepted file type: Zip file that can include a TIFF and/or
                                collected Illustrator file with images and fonts supplied or
                                converted to outlines.
                            </Trans>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>
                            <Trans i18nKey="weRecommendPDF">
                                We recommend including a read-only PDF in your collected zip file
                                for viewing purposes only.
                            </Trans>
                        </Typography>
                    </Grid>
                </>
            ) : (
                <>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>
                            <Trans i18nKey="acceptedFileTypesEmea">Accepted file types</Trans>:
                        </Typography>
                        <ul>
                            <li>
                                <Typography className={classes.label}>
                                    Adobe Acrobat PDF (.pdf)
                                </Typography>
                            </li>
                            <li>
                                <Typography className={classes.label}>
                                    Adobe Illustrator (.ai)
                                </Typography>
                            </li>
                            <li>
                                <Typography className={classes.label}>
                                    Adobe Photoshop (.psd)
                                </Typography>
                            </li>
                            <li>
                                <Typography className={classes.label}>
                                    Word (.doc, .docx)
                                </Typography>
                            </li>
                            <li>
                                <Typography className={classes.label}>
                                    Excel (.xls, .xlsx)
                                </Typography>
                            </li>
                            <li>
                                <Typography className={classes.label}>.Zip</Typography>
                            </li>
                        </ul>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.label}>
                            <Trans i18nKey="weRecommendPDFEmea">
                                Note: .Zip files can include a TIFF and/or collected Illustrator
                                file with images and fonts supplied or converted to outlines. We
                                recommend including a read-only PDF in your collected zip file for
                                viewing purposes only.
                            </Trans>
                        </Typography>
                    </Grid>
                </>
            )}
            {region !== Region.EU && (
                <Grid item xs={12}>
                    <Typography className={classes.label}>
                        <Trans i18nKey="saveProgressAttachments">
                            Attached documents are not saved when 'SAVE PROGRESS' is selected.
                        </Trans>
                    </Typography>
                </Grid>
            )}
            <Grid item xs={12} className={classes.uploadContainer}>
                <FileUpload
                    styles={uploadStyles}
                    fileTypes={acceptedFileTypes}
                    name={region !== Region.EU ? 'artworkUpload' : 'artworkUploadEmea'}
                    testId="artwork-upload"
                    onUploadAttempt={storeFiles}
                    enableDelete={region !== Region.SA}
                    fileReset={isFileReset}
                    invalidMessage={invalidFileMessage}
                    multi={region === Region.EU}
                    maxFiles={region === Region.EU ? 5 : undefined}
                    existingFiles={graphicIntake.graphics?.fileInfo}
                    onExistingFileDelete={onRemoveExisting}
                    onFileReset={onFileReset}
                />
            </Grid>
            <Grid item xs={12} className={classes.textAreaMargin}>
                <Controller
                    as={
                        <TextareaAutosize
                            name="fileComment"
                            data-testid="graphics-file-comment"
                            aria-label="File Comment"
                            rowsMin={4}
                            placeholder={
                                region === Region.EU
                                    ? t('artworkComments', 'Artwork Comments (Optional)')
                                    : t('additionalComments', 'Additional Comments (Optional)')
                            }
                            className={classes.textArea}
                        />
                    }
                    name="fileComment"
                    control={control}
                    onChange={handleCommentChange}
                />
            </Grid>
            {showUploadError && (
                <Grid item xs={12} className={classes.error} data-testid="file-upload-error">
                    <Typography>
                        {region !== Region.EU ? (
                            <Trans i18nKey="onUploadFileError">
                                *Please upload a valid zip file
                            </Trans>
                        ) : (
                            <Trans i18nKey="onUploadFileErrorEmea">
                                *One of the files you are trying to upload is not of an approved
                                file type. Please remove and upload an approved file type (.pdf,
                                .ai, .psd, .doc, .docx, .xls, .xlsx, .zip).
                            </Trans>
                        )}
                    </Typography>
                </Grid>
            )}
            <Grid item xs={12} className={classes.bottomContainer}>
                <Grid item xs={12}>
                    <Typography className={classes.label}>
                        <Trans i18nKey="uploadSection1">
                            All Ball Cans will be run on an Aluminum Substrate with a Gloss Over
                            Varnish unless otherwise specified.
                        </Trans>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.label}>
                        <Trans i18nKey="uploadSection2">
                            Availability of special inks or varnishes may be limited; requesting
                            these items on this form does not guarantee availability.
                        </Trans>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.label}>
                        <Trans i18nKey="uploadSection3">
                            Please verify that all information provided on this intake form for your
                            graphic is accurate prior to submission. Ball Corporation will not be
                            held responsible for any mistakes, omissions or inaccurate information
                            provided as a result of this file submission.
                        </Trans>
                    </Typography>
                </Grid>
            </Grid>
        </FormSection>
    );
};

export default UploadGraphics;
