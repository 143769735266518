import React from 'react';
import { Trans } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { yellowPill, redPill, greenPill } from '../../../themes/globalConstants';

interface Props {
    leadTime: string;
    leadTimeColorOverride: string | undefined;
}

const useStyles = makeStyles((theme) => ({
    greenDot: {
        backgroundColor: greenPill,
        height: '0.875em',
        width: '0.875em',
        borderRadius: '50%',
        display: 'inline-flex',
        marginLeft: '0.375em'
    },
    yellowDot: {
        backgroundColor: yellowPill,
        height: '0.875em',
        width: '0.875em',
        borderRadius: '50%',
        display: 'inline-flex',
        marginLeft: '0.375em'
    },
    redDot: {
        backgroundColor: redPill,
        height: '0.875em',
        width: '0.875em',
        borderRadius: '50%',
        display: 'inline-flex',
        marginLeft: '0.375em'
    }
}));

const CurrentLeadTime = ({ leadTime, leadTimeColorOverride }: Props) => {
    const classes = useStyles();
    let pillClass = '';
    let currentLeadTime = 'N/A';
    let duration = '';
    let min = '';
    let hasColorOverride = false;
    
    if (leadTimeColorOverride) {
        leadTimeColorOverride = leadTimeColorOverride.toUpperCase();
    }
    switch (leadTimeColorOverride) {
        case 'RED':
            pillClass = classes.redDot;
            hasColorOverride = true;
            break;
        case 'GREEN':
            pillClass = classes.greenDot;
            hasColorOverride = true;
            break;
        case 'YELLOW':
            pillClass = classes.yellowDot;
            hasColorOverride = true;
            break;
        default:
            break;
    }

    switch (leadTime) {
        case '4TO8WKS':
            pillClass = hasColorOverride ? pillClass : classes.greenDot;
            currentLeadTime = '4 - 8 ';
            duration = 'Weeks';
            break;
        case '8TO12WKS':
            pillClass = hasColorOverride ? pillClass : classes.yellowDot;
            currentLeadTime = '8 - 12 ';
            duration = 'Weeks';
            break;
        case '12PLSWKS':
            pillClass = hasColorOverride ? pillClass : classes.redDot;
            currentLeadTime = '12+ ';
            duration = 'Weeks';
            break;
        case '26PLSWKS':
            pillClass = hasColorOverride ? pillClass : classes.redDot;
            currentLeadTime = '26+ ';
            duration = 'Weeks';
            break;
        case 'MIN12MOS':
            pillClass = hasColorOverride ? pillClass : classes.redDot;
            currentLeadTime = ' 12 ';
            min = 'Minimum';
            duration = 'Months';
            break;
        default:
            break;
    }

    return (
        <>
            {min.length !== 0 && <Trans i18nKey={min.toLowerCase()}>{min}</Trans>}
            {currentLeadTime} <Trans i18nKey={duration.toLowerCase()}>{duration}</Trans>
            <span className={pillClass}></span>
        </>
    );
};

export default CurrentLeadTime;
