import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import Button from '../../../reusable/atoms/Button';
import { small } from '../../../../themes/globalConstants';

interface Props {
    onExpandAll: () => void;
    onCollapseAll: () => void;
}

const useStyles = makeStyles((theme) => ({
    main: {
        borderTop: 'none',
        borderRadius: 'unset'
    },
    toolbarWrapper: {
        alignItems: 'center',
        '& .MuiGrid-container': {
            margin: 0
        }
    },
    toolbarLabel: {
        fontSize: small,
        textTransform: 'uppercase'
    },
    btn: {
        padding: 0,
        minWidth: 'auto'
    }
}));

const ProductionOrderDetailsToolbar = ({ onExpandAll, onCollapseAll }: Props) => {
    const classes = useStyles();

    const handleExpandAll = () => {
        onExpandAll();
    };

    const handleCollapseAll = () => {
        onCollapseAll();
    };

    useTranslation();

    return (
        <Grid container className={classes.toolbarWrapper}>
            <Grid container item xs={11} justify="flex-end" alignItems="center" spacing={2}>
                <Grid item>
                    <Button
                        className={classes.btn}
                        color="primary"
                        onClick={handleExpandAll}
                        data-testid="expand-all"
                    >
                        <Trans i18nKey="expandAll">Expand All</Trans>
                    </Button>
                </Grid>
                <Grid item>
                    <Typography>|</Typography>
                </Grid>
                <Grid item>
                    <Button
                        className={classes.btn}
                        color="primary"
                        onClick={handleCollapseAll}
                        data-testid="collapse-all"
                    >
                        <Trans i18nKey="collapseAll">Collapse All</Trans>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ProductionOrderDetailsToolbar;
