import React, { useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { small, boldWeight, ballGray } from '../../../../themes/globalConstants';
import { useTranslation } from 'react-i18next';
import { viewTypesCampaignOnly, getViewLabel } from '../../../../utility/helpers/filter-helpers';
import ViewFilterToolbar from '../../../reusable/molecules/ViewFilterToolbar';
import { MakeState } from '../../../../store/reducers/makeit-dashboard';
import { useTypedSelector } from '../../../../store/reducers/reducer';

interface Props {
    onViewFiltersClicked: (viewType: string[]) => void;
}

const useStyles = makeStyles((theme) => ({
    main: {
        borderTop: 'none',
        borderRadius: 'unset'
    },
    toolbarWrapper: {
        padding: '1.125em 0',
        alignItems: 'center',
        '& .MuiGrid-container': {
            margin: 0
        }
    },
    filter: {
        alignItems: 'center'
    },
    filterInput: {
        width: '15.750em'
    },
    btn: {
        padding: 0,
        minWidth: 'auto',
        fontSize: small,
        fontWeight: boldWeight,
        margin: '0 0.5em'
    },
    filtersWrapper: {
        display: 'flex',
        alignItems: 'baseline'
    },
    filterLabel: {
        color: ballGray,
        textAlign: 'right'
    }
}));

export default function MakeItConfigToolbar({ onViewFiltersClicked }: Props) {
    const classes = useStyles();
    const { view } = useTypedSelector<MakeState>((state) => state.makeItDashboard);
    const [viewLabel, setViewLabel] = useState<string>('');

    useEffect(() => {
        setViewLabel(getViewLabel(view));
    }, [view]);

    useTranslation();

    return (
        <Grid container className={classes.toolbarWrapper} justify="space-between">
            <Grid item xs={12} className={classes.filtersWrapper}>
                <ViewFilterToolbar
                    viewTypes={viewTypesCampaignOnly}
                    viewChange={(newView) => onViewFiltersClicked(newView)}
                    testId={'makeit-views'}
                    value={viewLabel}
                />
            </Grid>
        </Grid>
    );
}
