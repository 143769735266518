import { CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CapacityCheck, CapacityCheckState } from '../../../../../store/reducers/capacity-checks';
import { ProductPortfolioState } from '../../../../../store/reducers/product-portfolio';
import { useTypedSelector } from '../../../../../store/reducers/reducer';
import { blackWeight, medium } from '../../../../../themes/globalConstants';
import { enUS } from '../../../../../utility/translations/locales';
import OnboardingSectionCompleted from '../OnboardingSectionCompleted';
import OnboardingWizardProductTile from '../OnboardingWizardProductTile';
import Link from '../../../../reusable/atoms/Link';

const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: '1em'
    },
    description: {
        marginBottom: '1em'
    },
    subheader: {
        fontWeight: blackWeight,
        marginBottom: '0.5em'
    },
    productList: {
        display: 'flex'
    },
    spinningLoader: {
        flexDirection: 'column',
        marginTop: '0.5em'
    },
    moreProductsText: {
        marginBottom: '2em'
    },
    link: {
        fontSize: medium,
        textDecoration: 'underline'
    }
}));

export default function ProductSelection() {
    const { capacityChecks, loading, loaded } = useTypedSelector<CapacityCheckState>(
        (state) => state.capacityCheck
    );
    const { results } = useTypedSelector<ProductPortfolioState>((state) => state.productPortfolio);
    const [maxProductsArray, setMaxProductsArray] = useState<CapacityCheck[]>([]);
    const classes = useStyles();
    const history = useHistory();

    const viewMyProjects = () => {
        history.push('/product-portfolio');
    };

    useEffect(() => {
        const reducedCapacityChecks: CapacityCheck[] = [];
        const arrayMaxLength = 6;
        if (capacityChecks && capacityChecks.length) {
            for (let i = 0; i < arrayMaxLength && i < capacityChecks.length; i++) {
                reducedCapacityChecks.push(capacityChecks[i]);
            }
            setMaxProductsArray(reducedCapacityChecks);
        }
    }, [capacityChecks]);

    return (
        <>
            <Typography
                variant="h3"
                className={classes.header}
                data-testid="product-selection-header"
            >
                <Trans i18nKey="productSelectionHeader">${enUS.productSelectionHeader}</Trans>
            </Typography>
            <Typography variant="body1" className={classes.description}>
                <Trans i18nKey="productSelectionText">${enUS.productSelectionText}</Trans>
            </Typography>
            <Typography className={classes.subheader}>
                <Trans i18nKey="yourProducts">${enUS.yourProducts}</Trans>
                {':'}
            </Typography>
            {loading && (
                <Grid container item xs={12} className={classes.spinningLoader}>
                    <CircularProgress />
                </Grid>
            )}
            {loaded && (
                <>
                    <Grid container className={classes.productList}>
                        {maxProductsArray.map((product) => {
                            let matchingProduct = results.find(
                                (result) => result.productCode === product.productCode
                            );
                            return (
                                <Grid item xs={4}>
                                    <OnboardingWizardProductTile
                                        product={product}
                                        matchingProduct={matchingProduct}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                    {capacityChecks.length > 6 && (
                        <Typography
                            className={classes.moreProductsText}
                            data-testid="more-products-than-shown"
                        >
                            <Trans i18nKey="moreProductsThanShown">
                                More products are approved but not shown. Go to
                                <Link
                                    underline="none"
                                    component="button"
                                    data-testid="invite-to-help-complete"
                                    className={classes.link}
                                    onClick={viewMyProjects}
                                >
                                    My Projects
                                </Link>
                                to learn more.
                            </Trans>
                        </Typography>
                    )}
                    <OnboardingSectionCompleted />
                </>
            )}
        </>
    );
}
