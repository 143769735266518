import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class UserAdminPendingRegistration {
    static getUserAdminPendingColumns = (culture: string, hasAdminPersona: boolean = false) => {
        return [
            createColumn('userName', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'email'),
                filterable: true,
                sortable: true,
                searchable: true
            }),
            createColumn('roles', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'roles'),
                filterable: true,
                sortable: true,
                searchable: true
            }),
            createColumn('createDate', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'sentOn'),
                filterable: true,
                sortable: true,
                searchable: true
            }),
            createColumn('actions', {
                label: TranslationService.getTranslatedText(culture, 'actions'),
                visible: hasAdminPersona
            }),
            createColumn('registrationId', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('currentUserHasAdminPermission', {
                dataType: ColumnDataType.Boolean,
                visible: false,
                searchable: false
            })
        ];
    };
}
