import React, { useState } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { medium, regularWeight, ballBlue, disabledGrey } from '../../../../themes/globalConstants';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import Modal from '../../../reusable/molecules/Modal';
import { CSVLink } from 'react-csv';
import ReactPdf from '@react-pdf/renderer';
import {
    MakeItBulkATMLinesBySnoSku,
    MakeItBulkATMOrder
} from '../../../../store/reducers/makeit-bulk-atm';

import { formattedDateWithTimezone } from '../../../../utility/helpers/date-helpers';
import moment from 'moment';
import { getDesc } from '../../../../utility/helpers/production-order-helpers';

interface Props {
    shipToName: string;
    shipToAddress: string;
    atmProductionOrder: MakeItBulkATMOrder;
    lines?: MakeItBulkATMLinesBySnoSku[];
}

const useStyles = makeStyles((theme) => ({
    label: {
        textTransform: 'uppercase',
        fontSize: medium,
        paddingTop: '0.5em'
    },
    icon: {
        color: ballBlue
    },
    iconDisabled: {
        color: disabledGrey
    }
}));

const pdfStyles = ReactPdf.StyleSheet.create({
    link: {
        fontSize: medium,
        fontWeight: regularWeight,
        width: '8.75em'
    },
    linkDisabled: {
        color: disabledGrey,
        pointerEvents: 'none',
        fontSize: medium,
        fontWeight: regularWeight,
        width: '8.75em'
    }
});

const MakeItATMOrderDetailsShareLinks = (props: Props) => {
    const classes = useStyles();
    const [openPdf, setOpenPdf] = useState<boolean>(false);
    const { t } = useTranslation();

    const onClose = () => {
        setOpenPdf(false);
    };

    useTranslation();

    interface CSVRow {
        requestedWeek?: string;
        shipToId?: number;
        characteristics?: string;
        productId?: string;
        supplyPlan?: number;
        lineNumber?: number;
        ballOrderNumber?: number;
        quantityPallets?: number;
        quantityEaches?: number;
    }

    const csvHeaders = [
        { label: t('requestedWeek', 'Requested Week').toUpperCase(), key: 'requestedWeek' },
        { label: t('shipToId', 'Ship to ID').toUpperCase(), key: 'shipToId' },
        {
            label: t('characteristics', 'Characteristics').toUpperCase(),
            key: 'characteristics'
        },

        { label: t('supplyPlan', 'Supply Plan').toUpperCase(), key: 'supplyPlan' },
        { label: t('productId', 'Product ID').toUpperCase(), key: 'productId' },
        {
            label: t('lineNumber', 'Line Number').toUpperCase(),
            key: 'lineNumber'
        },
        { label: t('ballOrderNumber', 'Ball Order Number').toUpperCase(), key: 'ballOrderNumber' },
        {
            label: (t('quantityToPurchase', 'Quantity To Purchase') + ' (PL)').toUpperCase(),
            key: 'quantityPallets'
        },
        {
            label: (t('quantityToPurchase', 'Quantity To Purchase') + ' (EA)').toUpperCase(),
            key: 'quantityEaches'
        }
    ];

    const csvArray = () => {
        let rows: any[] = [];

        props.lines?.forEach((line) => {
            line.lines.forEach((lineItem) => {
                const row: CSVRow = {
                    requestedWeek: formattedDateWithTimezone(
                        moment(props.atmProductionOrder.weekStart),
                        'MM/DD/YYYY'
                    ),
                    shipToId: props.atmProductionOrder.shipToId,
                    characteristics: getDesc(lineItem),
                    productId: lineItem.displayId,
                    supplyPlan: line.allocationAmount,
                    lineNumber: lineItem.productionOrderLineNumber,
                    ballOrderNumber: props.atmProductionOrder.productionOrderNumber,
                    quantityPallets: lineItem.palletQuantity,
                    quantityEaches:
                        lineItem.quantityPerPallet && lineItem.palletQuantity
                            ? lineItem.quantityPerPallet * lineItem.palletQuantity
                            : 0
                };
                rows.push(row);
            });
        });
        return rows;
    };

    const csvContents = csvArray();

    return (
        <Grid container item xs={12} spacing={2} alignItems="center" justify="flex-end">
            <Grid item data-testid="share-btn">
                <Typography variant="h6" className={classes.label}>
                    <Trans i18nKey="share">share</Trans>
                </Typography>
            </Grid>
            {/* 
                NOT IN SCOPE - for use when PDF is added CP-3550
                <Grid item>
                    <Link
                        className={classes.link}
                        underline="always"
                        data-testid="print-btn"
                        onClick={printForm}
                    >
                        <Trans i18nKey="print">Print</Trans>
                    </Link>
                    <PrintOutlinedIcon fontSize="small" className={classes.icon} />
                </Grid>
            */}

            <Grid item>
                <CSVLink
                    className={
                        'MuiTypography-root MuiLink-root MuiLink-underlineAlways MuiTypography-colorPrimary'
                    }
                    style={
                        props.atmProductionOrder.productionOrderNumber
                            ? pdfStyles.link
                            : pdfStyles.linkDisabled
                    }
                    underline="always"
                    data-testid="download-btn"
                    headers={csvHeaders}
                    data={csvContents}
                    filename={`${props.atmProductionOrder.shipToId}_${t(
                        'makeIt',
                        'Make It'
                    )}_${formattedDateWithTimezone(
                        moment(props.atmProductionOrder.weekStart),
                        'MM/DD/YYYY'
                    )}.csv`}
                >
                    <Trans i18nKey="downloadCSV">Download .CSV</Trans>
                </CSVLink>
                <GetAppOutlinedIcon
                    fontSize="small"
                    className={
                        props.atmProductionOrder.productionOrderNumber
                            ? classes.icon
                            : classes.iconDisabled
                    }
                />
            </Grid>
            <Modal
                open={openPdf}
                title={t('atmDetails', 'ATM Details')}
                close={onClose}
                closeIcon={true}
                fullScreen={true}
            >
                {/* TODO: CP-3550 */}
            </Modal>
        </Grid>
    );
};

export default MakeItATMOrderDetailsShareLinks;
