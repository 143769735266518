import React, { ReactNode } from 'react';
import { makeStyles, Container } from '@material-ui/core';
import { containerMaxWidth, ltBlueGrey_15 } from '../../../themes/globalConstants';

interface Props {
    children?: ReactNode;
}

const useStyles = makeStyles((theme) => ({
    subheader: {
        backgroundColor: ltBlueGrey_15,
        padding: '0.625em 0',
        marginBottom: '0.625em'
    },
    container: {
        maxWidth: containerMaxWidth,
        padding: '0 0.875em'
    }
}));

export default function SubheaderBar({ children }: Props) {
    const classes = useStyles();

    return (
        <div className={classes.subheader}>
            {children && (
                <Container className={classes.container}>{children}</Container>
            )}
        </div>
    );
}
