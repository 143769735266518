import { Grid, makeStyles, MenuItem } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ballGray, boldWeight } from '../../../themes/globalConstants';
import Link from '../atoms/Link';
import NavBarTile from './NavBarTile';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { resetMakeItState } from '../../../store/actions/makeit-dashboard';
import { resetShipItState } from '../../../store/actions/shipping-dashboard';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import {
    selectIsCopackerAccount,
    selectIsLargeCustomerAccount,
    selectHasMakeItEdit,
    selectHasMakeItView,
    selectHasShipItEdit,
    selectHasShipItView,
    selectHasPlanItEdit,
    selectHasPlanItView,
    selectHasPlanningView
} from '../../../store/selectors';
import { AuthState } from '../../../store/reducers/auth';
import { Persona, hasPersonasByShipTo } from '../../../utility/auth/useSecurity';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { resetPlanItBulkState } from '../../../store/actions/planit-bulk-atm';
import { FlagTypes, GetFeatureFlag } from '../../../utility/helpers/feature-flag';
import { resetMakeItBulkATMState } from '../../../store/actions/makeit-bulk-atm';
import NavBarMenu, { MenuSection } from './NavBarMenu';

const useStyles = makeStyles((theme) => ({
    menuItems: {
        overflow: 'visible',
        width: 'fit-content',
        padding: '4px 0'
    },
    linkMenuItem: {
        '&:hover': {
            backgroundColor: 'transparent'
        },
        '&.MuiMenuItem-root': {
            whiteSpace: 'normal'
        }
    },
    link: {
        fontSize: 12,
        fontWeight: boldWeight,
        color: theme.palette.secondary.main,
        letterSpacing: '1px'
    },
    altIcon: {
        color: ballGray,
        marginTop: '15%',
        marginBottom: '0.625em',
        maxHeight: '2.5em'
    },
    indentedLink: {
        width: 'auto',
        paddingLeft: '0.75em',
        paddingTop: '0em'
    }
}));

const OrderingNav = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const shipItIcon = '/Ship_It_Icon_Nav.svg';
    const makeItIcon = '/Make_It_Icon_Nav.svg';
    const productPortfolioIcon = '/Product_Planning_Icon_Nav.svg';
    const planItIcon = '/Plan_It_Icon_Nav.svg';

    const auth = useTypedSelector<AuthState>((state) => state.auth);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const hasMakeItEdit = useTypedSelector<boolean>(selectHasMakeItEdit);
    const hasMakeItView = useTypedSelector<boolean>(selectHasMakeItView);
    const hasShipItEdit = useTypedSelector<boolean>(selectHasShipItEdit);
    const hasShipItView = useTypedSelector<boolean>(selectHasShipItView);
    const hasPlanItEdit = useTypedSelector<boolean>(selectHasPlanItEdit);
    const hasPlanItView = useTypedSelector<boolean>(selectHasPlanItView);
    const hasPlanningView = useTypedSelector<boolean>(selectHasPlanningView);
    const isLargeCustomerAccount = useTypedSelector<boolean>(selectIsLargeCustomerAccount);
    const isCopacker = useTypedSelector<boolean>(selectIsCopackerAccount);

    const [menuLinks, setMenuLinks] = useState<MenuSection[]>([]);

    const showTradeItSummaryLink = GetFeatureFlag(FlagTypes.TradeItSummary) ?? false;
    const showScrapItSummaryLink = GetFeatureFlag(FlagTypes.ScrapIt) ?? false;

    const clearMakeState = () => {
        dispatch(resetMakeItState());
        dispatch(resetMakeItBulkATMState());
    };

    const clearShipState = () => {
        dispatch(resetShipItState());
    };

    const clearPlanItState = () => {
        dispatch(resetPlanItBulkState());
    };

    const isOrderFulfillment: boolean = useMemo(
        () =>
            !!auth.permissions &&
            hasPersonasByShipTo([Persona.OrderFulfillment], selectedAccountId, auth.permissions),
        [selectedAccountId, auth.permissions]
    );

    const isAllAccess: boolean = useMemo(
        () =>
            !!auth.permissions &&
            hasPersonasByShipTo([Persona.AllAccess], selectedAccountId, auth.permissions),
        [selectedAccountId, auth.permissions]
    );

    useEffect(() => {
        const links: MenuSection[] = [
            {
                key: 'submissions',
                name: t('submissions', 'Submissions'),
                items: []
            },
            {
                key: 'requests',
                name: t('requests', 'Requests'),
                items: []
            }
        ];
        if (hasPlanItView) {
            links[0].items!.push(
                <MenuItem
                    key="planItSummary"
                    data-testid="plan-it-summary-link"
                    disableGutters={true}
                    className={clsx(classes.linkMenuItem, classes.menuItems)}
                >
                    <Link
                        component={RouterLink}
                        to={'/plan-it-summary'}
                        underline="hover"
                        aria-label="plan it summary"
                        className={classes.link}
                        smallDivider
                    >
                        <Trans i18nKey="planItSummary">Plan It Summary</Trans>
                    </Link>
                </MenuItem>
            );
        }

        if (hasMakeItView) {
            links[0].items!.push(
                <MenuItem
                    key="productionOrderSummary"
                    data-testid="make-it-summary-link"
                    disableGutters={true}
                    className={clsx(classes.linkMenuItem, classes.menuItems)}
                >
                    <Link
                        component={RouterLink}
                        to={isLargeCustomerAccount ? '/make-it-po-summary' : '/make-it-summary'}
                        underline="hover"
                        aria-label="production order summary"
                        className={classes.link}
                        smallDivider
                    >
                        <Trans i18nKey="makeItSummary">Make It Summary</Trans>
                    </Link>
                </MenuItem>
            );
        }

        if (hasShipItView) {
            links[0].items!.push(
                <MenuItem
                    key="shipmentSummary"
                    data-testid="ship-it-summary-link"
                    disableGutters={true}
                    className={clsx(classes.linkMenuItem, classes.menuItems)}
                >
                    <Link
                        component={RouterLink}
                        to="/ship-it-summary"
                        underline="hover"
                        aria-label="product order summary"
                        className={classes.link}
                        smallDivider
                    >
                        <Trans i18nKey="shipItSummary">Ship It Summary</Trans>
                    </Link>
                </MenuItem>
            );
        }

        if (hasMakeItEdit) {
            links[0].items!.push(
                <MenuItem
                    key="makeItDrafts"
                    data-testid="make-it-drafts-link"
                    disableGutters={true}
                    className={clsx(classes.linkMenuItem, classes.menuItems)}
                >
                    <Link
                        component={RouterLink}
                        to="/make-it-drafts-summary"
                        underline="hover"
                        aria-label="make it drafts"
                        className={classes.link}
                        smallDivider
                    >
                        <Trans i18nKey="makeItDrafts">Make It Drafts</Trans>
                    </Link>
                </MenuItem>
            );
        }

        //TODO: Eventually this link will need to go to Scrap It Summary, this page does not yet exist, so it is routing to scrap it for now
        if (showTradeItSummaryLink && isLargeCustomerAccount && hasMakeItEdit) {
            links[1].items!.push(
                <MenuItem
                    key="tradeItRequests"
                    data-testid="trade-it-summary-link"
                    disableGutters={true}
                    className={clsx(classes.linkMenuItem, classes.menuItems)}
                >
                    <Link
                        component={RouterLink}
                        to="/trade-it-summary"
                        underline="hover"
                        aria-label="trade it requests"
                        className={classes.link}
                        smallDivider
                    >
                        <Trans i18nKey="tradeItRequests">Trade It Requests</Trans>
                    </Link>
                </MenuItem>
            );
        }

        //TODO: Eventually this link will need to go to Scrap It Summary, this page does not yet exist, so it is routing to scrap it for now

        if (showScrapItSummaryLink && isLargeCustomerAccount && hasMakeItEdit) {
            links[1].items!.push(
                <MenuItem
                    key="scrapItRequests"
                    data-testid="scrap-it-summary-link"
                    disableGutters={true}
                    className={clsx(classes.linkMenuItem, classes.menuItems)}
                >
                    <Link
                        component={RouterLink}
                        to="/scrap-it-requests"
                        underline="hover"
                        aria-label="scrap it requests"
                        className={classes.link}
                        smallDivider
                    >
                        <Trans i18nKey="scrapItRequests">Scrap It Requests</Trans>
                    </Link>
                </MenuItem>
            );
        }

        setMenuLinks(links);
        // reload links when language or isLargeCustomer changes
    }, [
        t,
        cultureCode,
        classes,
        isLargeCustomerAccount,
        isOrderFulfillment,
        isAllAccess,
        hasMakeItEdit,
        hasMakeItView,
        hasShipItView,
        hasPlanItView,
        showScrapItSummaryLink,
        showTradeItSummaryLink
    ]);

    return (
        <NavBarMenu menuName={<Trans i18nKey="ordering">Ordering</Trans>} sections={menuLinks}>
            <Grid container spacing={1}>
                {hasPlanItEdit && (
                    <Grid item xs={4}>
                        <NavBarTile
                            link={isLargeCustomerAccount ? '/plan-it-bulk-upload' : '/plan-it'}
                            translationKey="planIt"
                            defaultText="Plan It"
                            icon={planItIcon}
                            isProduct={false}
                            onClick={clearPlanItState}
                            testId={'planItCard'}
                            secondaryText="Build, submit, and update your order forecasts."
                            secondaryTranslationKey="buildSubmitUpdate"
                        />
                    </Grid>
                )}
                {hasMakeItEdit && (
                    <Grid item xs={4}>
                        <NavBarTile
                            link="/make-it"
                            translationKey="makeIt"
                            defaultText="Make It"
                            icon={makeItIcon}
                            isProduct={false}
                            onClick={clearMakeState}
                            testId={'makeItCard'}
                            secondaryText="Create Make It orders."
                            secondaryTranslationKey="createMakeItOrders"
                        />
                    </Grid>
                )}
                {hasShipItEdit ? (
                    <Grid item xs={4}>
                        <NavBarTile
                            link="/ship-it"
                            translationKey="shipIt"
                            defaultText="Ship It"
                            icon={shipItIcon}
                            isProduct={false}
                            onClick={clearShipState}
                            testId={'shipItCard'}
                            secondaryText="Schedule delivery orders and pick ups."
                            secondaryTranslationKey="scheduleOrders"
                        />
                    </Grid>
                ) : (
                    <Grid item xs={4}>
                        <NavBarTile
                            link="/ship-it"
                            translationKey="shipIt"
                            defaultText="Ship It"
                            secondaryTranslationKey="comingSoon"
                            secondaryText="Coming Soon"
                            icon={shipItIcon}
                            isProduct={false}
                            testId={'shipItCardATM'}
                            disabled={true}
                        />
                    </Grid>
                )}
                {hasPlanningView && (
                    <Grid item xs={4}>
                        <NavBarTile
                            link={
                                isLargeCustomerAccount && !isCopacker
                                    ? '/po-product-planning'
                                    : '/product-planning'
                            }
                            translationKey="productPlanning"
                            defaultText="Product Planning"
                            icon={productPortfolioIcon}
                            isProduct={false}
                            onClick={clearShipState}
                            testId={
                                isLargeCustomerAccount
                                    ? 'productPlanningCardATM'
                                    : 'productPlanningCard'
                            }
                            secondaryText="View production balances and scheduled shipments for up to sixteen weeks."
                            secondaryTranslationKey="viewProductionBalances"
                        />
                    </Grid>
                )}
            </Grid>
        </NavBarMenu>
    );
};

export default OrderingNav;
