import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import { ballGray, boldWeight, medium } from '../../../../themes/globalConstants';
import {
    ProductionOrder,
    ProductCountInformation
} from '../../../../store/reducers/makeit-dashboard';
import { formatFromDatetime } from '../../../../utility/helpers/date-helpers';
import clsx from 'clsx';

interface Props {
    order?: ProductionOrder;
    countInformation: ProductCountInformation;
    shipToName: string;
    shipToAddress: string;
    shipToId: string;
}

const useStyles = makeStyles((theme) => ({
    main: {
        marginBottom: '1.5em'
    },
    subtitle: {
        color: ballGray
    },
    item: {
        paddingLeft: '1em'
    },
    caps: {
        textTransform: 'uppercase',
        fontWeight: boldWeight
    },
    capitalize: {
        textTransform: 'capitalize'
    },
    shipToText: {
        fontSize: medium,
        letterSpacing: '0.2'
    }
}));

const ShipToSubheader = ({
    order,
    countInformation,
    shipToAddress,
    shipToName,
    shipToId
}: Props) => {
    const classes = useStyles();
    const date = formatFromDatetime(order?.submittedDate as string);

    useTranslation();

    return (
        <Grid container className={classes.main}>
            <Grid container item xs={7}>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="shipTo">Ship To</Trans>
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography className={clsx(classes.caps, classes.shipToText)}>
                        {shipToName}
                    </Typography>
                    <Typography className={clsx(classes.capitalize, classes.shipToText)}>
                        {shipToAddress}
                    </Typography>
                    <Typography className={clsx(classes.capitalize, classes.shipToText)}>
                        {'#' + shipToId}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item xs={5} justify="flex-end">
                <Grid item className={classes.item}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="dateSubmitted">Date Submitted</Trans>
                    </Typography>
                    <Typography variant="body1">{date}</Typography>
                </Grid>
                <Grid item className={classes.item}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="canQuantity">Can Quantity</Trans>
                    </Typography>
                    <Typography variant="body1">
                        {countInformation.canBtlCount.toLocaleString()} PL /{' '}
                        {countInformation.canBtlSkuCount} SKU(s)
                    </Typography>
                    <Typography variant="body1">
                        {countInformation.canEachesCount?.toLocaleString()} ea.
                    </Typography>
                </Grid>
                <Grid item className={classes.item}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="endQuantity">End Quantity</Trans>
                    </Typography>
                    <Typography variant="body1">
                        {countInformation.endCount.toLocaleString()} PL /{' '}
                        {countInformation.endSkuCount} SKU(s)
                    </Typography>
                    <Typography variant="body1">
                        {countInformation.endEachesCount?.toLocaleString()} ea.
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ShipToSubheader;
