import React from 'react';
import { Card, CardContent, CardActions, Typography, makeStyles } from '@material-ui/core';
import HelpOutline from '@material-ui/icons/HelpOutline';
import { useTranslation, Trans } from 'react-i18next';
import Link from '../../../reusable/atoms/Link';
import { ivory, ballDrkBlue } from '../../../../themes/globalConstants';

interface Props {
    onLinkClick: () => void;
}

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: ivory,
        width: '99%',
        height: '99%',
        minHeight: '21.875em',
        position: 'relative',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    icon: {
        fill: ballDrkBlue,
        padding: '0.75em 0.5em',
        cursor: 'pointer'
    },
    title: {
        color: ballDrkBlue,
        textTransform: 'uppercase',
        fontWeight: 'bold',
        padding: '0 0.5em',
        lineHeight: 1.35,
        letterSpacing: 2.5
    },
    link: {
        paddingRight: '1.5em',
        paddingBottom: '1.875em',
        position: 'absolute',
        left: '7%',
        bottom: 0,
        cursor: 'pointer',
        '& .MuiTypography-root': {
            textAlign: 'left'
        }
    }
}));

const AssistTile = ({ onLinkClick }: Props) => {
    const classes = useStyles();

    useTranslation();

    return (
        <Card
            variant="outlined"
            className={classes.root}
            onClick={onLinkClick}
            data-testid="assist-tile"
        >
            <CardContent>
                <HelpOutline className={classes.icon} />
                <Typography variant="h3" component="h3" className={classes.title}>
                    <Trans i18nKey="lookingForSomething">Looking for something different?</Trans>
                </Typography>
            </CardContent>
            <CardActions className={classes.link}>
                <Link
                    component="button"
                    underline="none"
                    data-testid="contact-form-link"
                    onClick={onLinkClick}
                >
                    <Trans i18nKey="sendUsYourComments">Send us your comments</Trans>
                </Link>
            </CardActions>
        </Card>
    );
};

export default AssistTile;
