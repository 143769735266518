import {
    PRODUCT_ESTIMATE_LOADING,
    PRODUCT_ESTIMATE_LOADED,
    PRODUCT_ESTIMATE_ERROR,
    PRODUCT_ESTIMATE_DETAILS_LOADED,
    PRODUCT_ESTIMATE_BANNER_LOADED
} from './action-types';

import ProductService, { ProductSearchResponse } from '../../utility/services/product-service';

export const loadProductEstimatePageContent = (ids: string[]) => {
    return (dispatch: any, getState: any) => {
        dispatch({
            type: PRODUCT_ESTIMATE_LOADING
        });

        let productResponse: ProductSearchResponse;
        const bannerUrl = `${process.env.REACT_APP_CONTENT_API}/content/banners/budgetary-banner`;

        const banner = ProductService.getBanner(getState, bannerUrl);
        banner.then((response) => {
            dispatch({
                banner: response.data?.section,
                type: PRODUCT_ESTIMATE_BANNER_LOADED
            });
        });

        const productDetails = ProductService.getMultipleProducts(getState, {
            productCodes: ids
        });
        productDetails
            .then((products) => {
                productResponse = products.data;
                return ProductService.getProductContent(productResponse, getState);
            })
            .then((products) => {
                dispatch({
                    type: PRODUCT_ESTIMATE_DETAILS_LOADED,
                    products: products
                });
            });

        const promises = [banner, productDetails];
        // when everything is loaded or an error occurs
        return Promise.all(promises)
            .then(() => {
                dispatch({
                    type: PRODUCT_ESTIMATE_LOADED
                });
            })
            .catch((error) => {
                dispatch({
                    type: PRODUCT_ESTIMATE_ERROR,
                    error: 'Unable to load product details content due to the following: ' + error
                });
            });
    };
};
