import React from 'react';
import { makeStyles, Box } from '@material-ui/core';

interface Props {
    embedLink: string;
}

const useStyles = makeStyles({
    iframe: {
        margin: 0,
        padding: 0,
        border: 0,
        borderBottom: 0,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
    },
    box: {
        margin: 0,
        padding: 0
    }
});

const VideoPlayer = (props: Props) => {
    const classes = useStyles();

    return (
        <Box className={classes.box}>
            <iframe
                title="Ball Video Player"
                src={props.embedLink}
                width="640"
                height="360"
                allow="autoplay; fullscreen"
                // allowfullscreen
                className={classes.iframe}
                frameBorder="0"
            ></iframe>
        </Box>
    );
};

export default VideoPlayer;
