import React from 'react';
import PdfPageTemplate from '../../../templates/PdfPageTemplate';
import { Document, Page, Text, View, BlobProvider, Image } from '@react-pdf/renderer';
import pdfStyles from '../../../../themes/pdfStyles';
import { isIE } from '../../../../utility/helpers/user-helpers';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { TabStatus } from './ManageProductsTabs';
import { selectIsLargeCustomerAccount } from '../../../../store/selectors';

interface Props {
    title: string;
    content: any[];
    tabStat: TabStatus;
    lockPeriodWeeks: number;
}

const ManageProductsPDF = ({ title, content, tabStat, lockPeriodWeeks }: Props) => {
    const ballLogo = process.env.PUBLIC_URL + '/assets/Ball_RGB.jpg';
    const sourceLogo = process.env.PUBLIC_URL + '/assets/TheSource_Logo.png';
    const { shipToAccounts, selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const isLargeCustomer = useTypedSelector<boolean>(selectIsLargeCustomerAccount);
    const summary = (
        <>
            <View style={[pdfStyles.borderlessTable, pdfStyles.marginTop20]}>
                <View style={[pdfStyles.flexSection, pdfStyles.pageHeader]}>
                    <View style={[pdfStyles.divider25, pdfStyles.marginBottom10]} fixed />
                    <View>
                        <View>
                            <Text style={pdfStyles.header2}>
                                {tabStat ? 'Inactive Products' : 'Active Products'}
                            </Text>
                        </View>
                        <View>
                            <Text style={[pdfStyles.subtitle14, pdfStyles.marginBottom3]}>
                                SHIP TO: #{selectedAccountId}
                            </Text>
                        </View>
                    </View>
                    <View style={[pdfStyles.borderLessTableColNarrow7, pdfStyles.textAlignRight]}>
                        <View>
                            <Text style={pdfStyles.header2}>
                                Number of {tabStat ? 'Inactive Products' : 'Active Products'}
                            </Text>
                        </View>
                        <View>
                            <Text style={[pdfStyles.subtitle14, pdfStyles.marginBottom3]}>
                                {content.length}
                            </Text>
                        </View>
                    </View>

                    <View style={[pdfStyles.divider25, pdfStyles.marginBottom10]} fixed />
                    <View style={[pdfStyles.borderLessTableCol, pdfStyles.textAlignLeft]}>
                        <View>
                            <Text style={[pdfStyles.header2, pdfStyles.marginBottom3]}>
                                {!!shipToAccounts
                                    ? shipToAccounts
                                          .filter((obj) => obj.accountId === selectedAccountId)
                                          .map((obj) => obj.name)
                                    : 'Invalid'}
                            </Text>
                        </View>
                        <View>
                            <Text style={[pdfStyles.header12, pdfStyles.marginBottom3]}>
                                {!!shipToAccounts
                                    ? shipToAccounts
                                          .filter((obj) => obj.accountId === selectedAccountId)
                                          .map((obj) => obj.address.address1)
                                    : 'Invalid'}
                                ,
                            </Text>
                            <Text style={[pdfStyles.header12, pdfStyles.marginBottom3]}>
                                {!!shipToAccounts
                                    ? shipToAccounts
                                          .filter((obj) => obj.accountId === selectedAccountId)
                                          .map((obj) => obj.address.city)
                                    : 'Invalid'}
                                ,{' '}
                                {!!shipToAccounts
                                    ? shipToAccounts
                                          .filter((obj) => obj.accountId === selectedAccountId)
                                          .map((obj) => obj.address.stateProvince)
                                    : 'Invalid'}
                                ,{' '}
                                {!!shipToAccounts
                                    ? shipToAccounts
                                          .filter((obj) => obj.accountId === selectedAccountId)
                                          .map((obj) => obj.address.postalCode)
                                    : 'Invalid'}
                            </Text>
                        </View>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol, pdfStyles.textAlignLeft]} />
                </View>
            </View>
        </>
    );

    const tableHeaders = (
        <>
            <View style={[pdfStyles.borderlessTable, pdfStyles.marginTop20]}>
                <View style={[pdfStyles.flexSection, pdfStyles.pageHeader]}>
                    <View style={[pdfStyles.borderLessTableColNarrow8, pdfStyles.textAlignLeft]}>
                        <View>
                            <Text style={pdfStyles.header2}>Ball Product ID / Graphic ID</Text>
                        </View>
                    </View>
                    <View style={[pdfStyles.borderLessTableColNarrow8, pdfStyles.textAlignLeft]}>
                        <View>
                            <Text style={pdfStyles.header2}>Customer</Text>
                            <Text style={pdfStyles.header2}>Product ID</Text>
                        </View>
                    </View>
                    <View
                        style={
                            isLargeCustomer
                                ? [pdfStyles.borderLessTableColNarrow17, pdfStyles.textAlignLeft]
                                : [pdfStyles.borderLessTableColNarrow25, pdfStyles.textAlignLeft]
                        }
                    >
                        <View>
                            <Text style={pdfStyles.header2}>Product Name /</Text>
                            <Text style={pdfStyles.header2}>Description</Text>
                        </View>
                    </View>
                    <View
                        style={
                            isLargeCustomer
                                ? [pdfStyles.borderLessTableColNarrow9, pdfStyles.textAlignLeft]
                                : [pdfStyles.borderLessTableColNarrow8, pdfStyles.textAlignLeft]
                        }
                    >
                        <View>
                            <Text style={pdfStyles.header2}>Coating</Text>
                        </View>
                    </View>
                    <View
                        style={
                            isLargeCustomer
                                ? [pdfStyles.borderLessTableColNarrow9, pdfStyles.textAlignLeft]
                                : [pdfStyles.borderLessTableColNarrow8, pdfStyles.textAlignLeft]
                        }
                    >
                        <View>
                            <Text style={pdfStyles.header2}>Lead Time</Text>
                        </View>
                    </View>

                    {isLargeCustomer ? (
                        <>
                            <View
                                style={[
                                    pdfStyles.borderLessTableColNarrow8,
                                    pdfStyles.textAlignLeft
                                ]}
                            >
                                <View>
                                    <Text style={pdfStyles.header2}>Make It Lock</Text>
                                    <Text style={pdfStyles.header2}>Window</Text>
                                </View>
                            </View>

                            <View
                                style={[
                                    pdfStyles.borderLessTableColNarrow12,
                                    pdfStyles.textAlignLeft
                                ]}
                            >
                                <View>
                                    <Text style={pdfStyles.header2}>Status</Text>
                                </View>
                            </View>
                        </>
                    ) : (
                        <View
                            style={[pdfStyles.borderLessTableColNarrow8, pdfStyles.textAlignLeft]}
                        >
                            <View>
                                <Text style={pdfStyles.header2}>Status</Text>
                            </View>
                        </View>
                    )}
                    <View style={[pdfStyles.dividerThinGray]} fixed />
                    <View style={[pdfStyles.flexSection, pdfStyles.marginBottom10]}></View>
                </View>
            </View>
        </>
    );

    const tableItems = content.map((line) => {
        return (
            <>
                <View style={[pdfStyles.borderlessTable]}>
                    <View style={[pdfStyles.flexSection, pdfStyles.pageHeader]}>
                        <View
                            style={
                                line.graphic_id.length > 0
                                    ? [
                                          pdfStyles.borderLessTableColNarrow8,
                                          pdfStyles.textAlignLeft,
                                          pdfStyles.marginBottom3
                                      ]
                                    : [
                                          pdfStyles.borderLessTableColNarrow8,
                                          pdfStyles.textAlignLeft,
                                          pdfStyles.marginTop3
                                      ]
                            }
                        >
                            <View>
                                <Text style={pdfStyles.header13}>{line.ball_product_id}</Text>
                            </View>
                            <View>
                                <Text style={pdfStyles.header2}>{line.graphic_id}</Text>
                            </View>
                        </View>
                        <View
                            style={[
                                pdfStyles.borderLessTableColNarrow8,
                                pdfStyles.textAlignLeft,
                                pdfStyles.marginTop3
                            ]}
                        >
                            <View>
                                <Text style={pdfStyles.header2}>{line.customer_product_id}</Text>
                            </View>
                        </View>
                        <View
                            style={
                                isLargeCustomer
                                    ? [
                                          pdfStyles.borderLessTableColNarrow17,
                                          pdfStyles.textAlignLeft
                                      ]
                                    : [
                                          pdfStyles.borderLessTableColNarrow25,
                                          pdfStyles.textAlignLeft
                                      ]
                            }
                        >
                            <View>
                                <Text style={pdfStyles.header13}>{line.display_name}</Text>
                            </View>
                            <View>
                                <Text style={pdfStyles.header2}>{line.product_description}</Text>
                            </View>
                        </View>
                        <View
                            style={
                                isLargeCustomer
                                    ? [pdfStyles.borderLessTableColNarrow9, pdfStyles.textAlignLeft]
                                    : [pdfStyles.borderLessTableColNarrow8, pdfStyles.textAlignLeft]
                            }
                        >
                            <View>
                                <Text style={pdfStyles.header2}>{line.coating}</Text>
                            </View>
                        </View>
                        <View
                            style={
                                isLargeCustomer
                                    ? [pdfStyles.borderLessTableColNarrow9, pdfStyles.textAlignLeft]
                                    : [pdfStyles.borderLessTableColNarrow8, pdfStyles.textAlignLeft]
                            }
                        >
                            <View>
                                <Text style={pdfStyles.header2}>{line.lead_time}</Text>
                            </View>
                        </View>

                        {isLargeCustomer ? (
                            <>
                                <View
                                    style={[
                                        pdfStyles.borderLessTableColNarrow8,
                                        pdfStyles.textAlignLeft,
                                        pdfStyles.marginTop3
                                    ]}
                                >
                                    <View>
                                        <Text style={pdfStyles.header2}>
                                            {lockPeriodWeeks} Weeks
                                        </Text>
                                    </View>
                                </View>
                                <View
                                    style={[
                                        pdfStyles.borderLessTableColNarrow12,
                                        pdfStyles.textAlignLeft,
                                        pdfStyles.marginTop3
                                    ]}
                                >
                                    <View>
                                        <Text style={pdfStyles.header2}>{line.status}</Text>
                                    </View>
                                </View>
                            </>
                        ) : (
                            <View
                                style={[
                                    pdfStyles.borderLessTableColNarrow8,
                                    pdfStyles.textAlignLeft,
                                    pdfStyles.marginTop3
                                ]}
                            >
                                <View>
                                    <Text style={pdfStyles.header2}>{line.status}</Text>
                                </View>
                            </View>
                        )}
                        <View style={[pdfStyles.dividerThinGray, pdfStyles.marginBottom10]} fixed />
                    </View>
                </View>
            </>
        );
    });

    const myDoc = (
        <Document title={title}>
            <Page size="A4" style={pdfStyles.pagePadding25} orientation={'portrait'}>
                <View style={pdfStyles.logoSection}>
                    <Image src={sourceLogo} style={pdfStyles.sourceLogo} />
                    <Image src={ballLogo} style={pdfStyles.ballLogo} />
                </View>
                <View style={[pdfStyles.flexSection, pdfStyles.pageHeader]}>{summary}</View>
                <View style={[pdfStyles.flexSection, pdfStyles.pageHeader]}>{tableHeaders}</View>
                <View style={[pdfStyles.flexSection, pdfStyles.pageHeader]}>{tableItems}</View>
                <View style={[pdfStyles.fullWidth, pdfStyles.footer]} fixed>
                    <Text
                        style={pdfStyles.header2}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </View>
            </Page>
        </Document>
    );

    if (!isIE()) {
        return <PdfPageTemplate title={title}>{myDoc}</PdfPageTemplate>;
    } else {
        return (
            <BlobProvider document={myDoc}>
                {({ blob, url, loading, error }) => {
                    if (blob && window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob);
                    }
                    return null;
                }}
            </BlobProvider>
        );
    }
};

export default ManageProductsPDF;
