import OrdersService, { QuantityType } from '../../utility/services/orders-service';
import { MakeItProductsRequest } from '../reducers/makeit-dashboard';
import { TradeItRequest } from '../reducers/trade-it';
import ProductService from '../../utility/services/product-service';
import {
    TRADE_IT_BALANCES_LOADED,
    TRADE_IT_BALANCES_LOADING,
    TRADE_IT_BALANCES_LOADING_ERROR,
    TRADE_IT_BALANCES_PRODUCT_LOADING_ERROR,
    TRADE_IT_EMAILS_LOADED,
    TRADE_IT_EMAILS_LOADING,
    TRADE_IT_EMAILS_LOADING_ERROR,
    TRADE_IT_REQUEST_SUBMITTED,
    TRADE_IT_UPDATE_QUANTITY_TO_TRADE
} from './action-types';
import {
    createProductRequestObj,
    getMatchingProductInformation
} from '../../utility/helpers/production-order-helpers';
import UserService from '../../utility/services/user-service';

export const getAvailableTradeBalances = (
    originalShipTo: string,
    receivingShipTo: string,
    requestedDate: string
) => {
    return async (dispatch, getState) => {
        const state = getState();
        const { accessToken } = state.auth;
        const region = state.regionCulture.regionCode;
        const culture = state.regionCulture.cultureCode;

        dispatch({ type: TRADE_IT_BALANCES_LOADING });
        dispatch({ type: TRADE_IT_EMAILS_LOADING });
        const makeItProductRequest: MakeItProductsRequest = {
            ShipToIds: [parseInt(originalShipTo), parseInt(receivingShipTo)]
        };
        const makeItProductsResponse: any = await OrdersService.getMakeItProducts(
            accessToken,
            makeItProductRequest
        ).catch((error) => {
            dispatch({ type: TRADE_IT_BALANCES_PRODUCT_LOADING_ERROR, error });
            return;
        });

        let productApiRequest = createProductRequestObj(
            makeItProductsResponse?.data?.products,
            undefined,
            [originalShipTo, receivingShipTo]
        );
        const productApiResponse: any = await ProductService.getMultipleProducts(
            getState,
            productApiRequest
        ).catch((error) => {
            dispatch({ type: TRADE_IT_BALANCES_PRODUCT_LOADING_ERROR, error });
            return;
        });

        OrdersService.getProductBalances(
            accessToken,
            {
                originalShipTo: [originalShipTo],
                receivingShipTo: [receivingShipTo],
                requestedDate: [requestedDate]
            },
            culture,
            region
        )
            .then((response) => {
                const products = makeItProductsResponse?.data?.products;
                products.forEach((product) => {
                    getMatchingProductInformation(
                        productApiResponse.data.products,
                        product,
                        product.size,
                        product.type,
                        product.shape
                    );

                    const destination = product.destinations.find(
                        (d) => d.shipToId.toString() === originalShipTo
                    );
                    product.displayId = destination?.customerProductId ?? '-';
                    product.displayName = destination?.customerProductName ?? product.name;

                    product.graphicIdAndVersion =
                        product.graphicId && product.graphicVersion
                            ? product.graphicId + '-' + product.graphicVersion
                            : product.graphicId
                            ? product.graphicId
                            : undefined;
                });
                const balances = response.data;
                const productsWithAvailableBalance = products.filter((p) =>
                    balances.map((b) => b.productSku).includes(p.productSku)
                );

                productsWithAvailableBalance.forEach((product) => {
                    const targetBalance = balances.find((b) => b.productSku === product.productSku);
                    if (targetBalance) {
                        product.originalShipToProductionBalance =
                            targetBalance.originalShipToProductionBalance;
                        product.receivingShipToProductionBalance =
                            targetBalance.receivingShipToProductionBalance;
                    }
                });
                dispatch({
                    products: productsWithAvailableBalance,
                    type: TRADE_IT_BALANCES_LOADED
                });
            })
            .catch((error) => {
                dispatch({
                    type: TRADE_IT_BALANCES_LOADING_ERROR,
                    error
                });
            });
        UserService.getTradeItEmails(originalShipTo, receivingShipTo, culture, region, accessToken)
            .then((response) => {
                dispatch({
                    fromEmails: response.data.accountFromEmails,
                    toEmails: response.data.accountToEmails,
                    type: TRADE_IT_EMAILS_LOADED
                });
            })
            .catch((error) => dispatch({ type: TRADE_IT_EMAILS_LOADING_ERROR, error }));
    };
};

export const updateQuantityToTrade = (productSku: string, quantityToTrade: number | undefined) => {
    return async (dispatch, getState) => {
        const state = getState();
        const products = state.tradeItRequest.products;
        const updatedProducts = products.map((product) => {
            if (product.productSku === productSku) {
                return { ...product, quantityToTrade };
            } else {
                return product;
            }
        });
        dispatch({
            type: TRADE_IT_UPDATE_QUANTITY_TO_TRADE,
            products: updatedProducts
        });
    };
};

export const submitTradeItRequest = (
    accountFrom: string,
    accountTo: string,
    fillerContactFrom: string,
    fillerContactTo: string
) => {
    return async (dispatch, getState) => {
        const state = getState();
        const products = state.tradeItRequest.products;
        const request: TradeItRequest = {
            accountFrom,
            accountTo,
            fillerContactFrom,
            fillerContactTo,
            requestLines: products
                .filter(
                    (product) =>
                        product.quantityToTrade &&
                        product.originalShipToProductionBalance &&
                        product.quantityToTrade <= product.originalShipToProductionBalance
                )
                .map((product) => {
                    return {
                        productSku: product.productSku,
                        quantity: product.quantityToTrade,
                        quantityUnits: QuantityType.Pallets,
                        accountFromOriginalBalance: product.originalShipToProductionBalance,
                        accountToOriginalBalance: product.receivingShipToProductionBalance
                            ? product.receivingShipToProductionBalance
                            : 0
                    };
                })
        };
        OrdersService.submitTradeItRequest(request, state).then((response) => {
            const submittedRequest = response.data;
            dispatch({
                type: TRADE_IT_REQUEST_SUBMITTED,
                submittedRequest
            });
        });
    };
};
