import React, { useEffect, useState } from 'react';
import { Grid, Link, makeStyles, Paper, Typography } from '@material-ui/core';
import { Trans } from 'react-i18next';
import { ballGray, boldWeight, medium } from '../../../../themes/globalConstants';
import { enUS } from '../../../../utility/translations/locales';
import OnboardingSectionDivider from './OnboardingSectionDivider';
import OnboardingWizardStepLabel from './OnboardingWizardStepLabel';
import OnboardingWizardStepSection from './OnboardingWizardStepSection';
import { AccountSetup, ProductSelection } from './steps';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { AuthState } from '../../../../store/reducers/auth';
import { Link as RouterLink } from 'react-router-dom';
import { hasPersonas, Persona } from '../../../../utility/auth/useSecurity';
import { useDispatch } from 'react-redux';
import { loadCompletedCapacityChecks } from '../../../../store/root-actions';

interface Props {
    processComplete: boolean;
}

const useStyles = makeStyles((theme) => ({
    paper: {
        borderRadius: 4,
        marginTop: '2em',
        padding: '2em'
    },
    subHeader: {
        color: ballGray,
        fontWeight: boldWeight,
        marginBottom: '.5em',
        fontSize: '1.5625em'
    },
    link: {
        fontSize: medium,
        textDecoration: 'underline'
    }
}));

export default function OnboardingWizardStepper({ processComplete }: Props) {
    const classes = useStyles();
    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);
    const auth = useTypedSelector<AuthState>((state) => state.auth);
    const [showUserAdminText, setShowUserAdminText] = useState<boolean>(false);
    const dispatch = useDispatch();
    const submitGraphicsLink = '/graphics-intake';
    const submitLiquidLink = '/liquid-test-intake';
    const userAdminLink = '/user-admin-dashboard';

    useEffect(() => {
        if (permissions) {
            setShowUserAdminText(hasPersonas([Persona.AccountAdmin], auth));
        }
    }, [permissions]);

    useEffect(() => {
        dispatch(loadCompletedCapacityChecks());
    }, [dispatch]);

    return (
        <Paper elevation={2} className={classes.paper}>
            {processComplete ? (
                <>
                    <Typography
                        variant="subtitle1"
                        className={classes.subHeader}
                        data-testid="congratulations-text"
                    >
                        <Trans i18nKey="congratulations">${enUS.congratulations}</Trans>
                    </Typography>
                    <Typography variant="body1">
                        <Trans i18nKey="onboardingCongratulationsText">
                            ${enUS.onboardingCongratulationsText}
                        </Trans>
                    </Typography>
                    <ul>
                        <li>
                            <Link
                                to={submitGraphicsLink}
                                underline="none"
                                component={RouterLink}
                                data-testid="submit-graphics-link"
                                className={classes.link}
                            >
                                <Trans i18nKey="submitAGraphic">${enUS.submitAGraphic}</Trans>
                            </Link>
                        </li>
                        <li>
                            <Link
                                to={submitLiquidLink}
                                underline="none"
                                component={RouterLink}
                                data-testid="submit-liquid-link"
                                className={classes.link}
                            >
                                <Trans i18nKey="submitLiquidSample">
                                    ${enUS.submitLiquidSample}
                                </Trans>
                            </Link>
                        </li>
                    </ul>
                    {showUserAdminText && (
                        <Typography variant="body1">
                            <Trans i18nKey="onboardingCongratulationsAdditionalText">
                                ${enUS.onboardingCongratulationsAdditionalText}
                            </Trans>{' '}
                            <Link
                                to={userAdminLink}
                                underline="none"
                                component={RouterLink}
                                data-testid="user-admin-link"
                                className={classes.link}
                            >
                                <Trans i18nKey="clickingHere">${enUS.clickingHere}</Trans>
                            </Link>
                            {'.'}
                        </Typography>
                    )}
                </>
            ) : (
                <>
                    <Typography
                        variant="subtitle1"
                        className={classes.subHeader}
                        data-testid="getting-started-text"
                    >
                        <Trans i18nKey="gettingStarted">${enUS.gettingStarted}</Trans>
                    </Typography>
                    <Typography variant="body1">
                        <Trans i18nKey="gettingStartedText">${enUS.gettingStartedText}</Trans>
                    </Typography>
                </>
            )}

            <Grid container item xs={12} data-testid="onboarding-wizard-stepper-container">
                <OnboardingSectionDivider />
                <OnboardingWizardStepSection
                    stepComponent={<OnboardingWizardStepLabel step={'01'} />}
                    disabled={false}
                >
                    <ProductSelection />
                </OnboardingWizardStepSection>
                <OnboardingSectionDivider />
                <OnboardingWizardStepSection
                    stepComponent={<OnboardingWizardStepLabel step={'02'} />}
                    disabled={false}
                >
                    <AccountSetup />
                </OnboardingWizardStepSection>
            </Grid>
        </Paper>
    );
}
