export const deDE = {
    // Reusable
    cans: 'Getränkedosen',
    view: 'Ansicht',
    design: 'Design',
    filling: 'Abfüllung',
    canHandling: 'Handhabung Getränkedosen',
    regulations: 'Vorschriften',
    labLiquid: 'Testmenge Getränk',
    sustainability: 'Nachhaltigkeit',
    faq: 'Häufig gestellte Fragen',
    designInspiration: 'Design Inspiration',
    backToAllProducts: 'Zurück zu allen Produkten',
    shipTo: 'Ausliefern',
    billTo: 'Gesetzesentwurf für',
    new: 'Neu',
    existing: 'Bestehender',
    unknown: 'Unbekannt',
    playground: 'Playground',

    // -- NAVIGATION -- //
    home: 'Home',
    homepage: 'Meine Homepage',
    ourProcess: 'Der Prozess',
    processOverview: 'Prozessübersicht',
    labDashLiquid: 'Labor / Flüssigkeit',
    handling: 'Handhabung',
    regulatory: 'Regulatorisch',
    ourProducts: 'Die Produkte',
    productPortfolio: 'Produkt Portfolio ',
    contactUs: 'Kontakt',
    logout: 'Logout',
    backToPortfolio: 'Zurück zum Portfolio',
    navigation: 'Navigation',
    buildAndEstimateNav: 'Erstellen & Schätzen',
    buildNav: 'Erstellen',
    ballProductPortfolio: 'Produktportfolio von Ball',

    // -- USER ADMIN -- //
    admin: 'Admin',
    addNewUser: 'Neuen Benutzer Hinzufügen',
    userInformation: 'Informationen zum Benutzer',
    userAdmin: 'Benutzer-Admin',
    myAccount: 'My Account',
    pendingAccessRequests: 'Pending Access Requests',
    myAccess: 'My Access',
    allAccess: 'Alle Zugangsoptionen',
    locationRoleAccess: 'Standort- und Rollenzugriff',
    orderFulfillment: 'Auftragsabwicklung',
    procurementManagement: 'Beschaffungsmanagement',
    forecastManagement: 'Prognosemanagement',
    packagingManagement: 'Verpackungsmanagement',
    coPacker: 'Co-Packer',
    warehousingLogistics: 'Lagerhaltung / Logistik',
    artManagement: 'Kunst-Management',
    marketingManagement: 'Marketing-Management',
    accountAdmin: 'Kontoadministrator',
    accountsPayableAccountsReceivable: 'Kreditorenbuchhaltung / Debitorenbuchhaltung',
    graphicsVendor: 'Grafikanbieter',
    edit: 'Bearbeiten',
    graphics: 'Grafikabteilung',
    selectedAccess: 'Ausgewählter Zugang',
    graphicsIntakeForm: 'Grafikaufnahmeformular',
    draftGraphicsIntakeForms: 'Entwürfe von Grafik-Aufnahmeformularen',
    inProgressGraphicsStatuses: 'In Bearbeitung Grafikstatus',
    graphicsQuote: 'Grafik-Zitat',
    graphicsProof: 'Grafik-Nachweis',
    graphicsStandardPilotCan: 'Grafikstandard (Pilot Can)',
    planning: 'Planung',
    newOpenProductionOrders: 'Neue / Offene Fertigungsaufträge',
    draftProductionOrders: 'Entwürfe von Fertigungsaufträgen',
    closedProductionOrders: 'Geschlossene Produktionsaufträge',
    newOpenDeliveryOrders: 'Neue / offene Lieferaufträge',
    closedDeliveryOrders: 'Geschlossene Lieferaufträge',
    planItPlanItSummary: 'Planen Sie es/ Planen Sie es Zusammenfassung',
    createNewUser: 'Neue Benutzer erstellen',
    changesWillNotBeSaved: 'Änderungen werden nicht gespeichert',
    areYouSureDiscard: 'Sind Sie sicher, dass Sie Änderungen verwerfen möchten?',
    yesDiscard: 'Ja, verwerfen',
    designSubmitted: 'Design Development Form Submitted',
    glnNumberError: 'Bitte geben Sie einen 13-stelligen Zahlenwert ein',
    previousForecasts: 'Previous Forecasts',
    copyToMakeIt: 'Copy To Make It',
    planItSummaryDashboardDesc:
        'View previously submitted order forecasts. Edit future order forecasts.',

    // -- WELCOME WIDGET -- //
    welcomeToTheSource: 'Willkommen bei <i>The Source</i>',
    welcomeWidgetDesc:
        '<i>The Source</i> wird Ihr One-Stop-Shop für die Lieferung von Grafiken sein. Im Laufe der Zeit werden auf dieser Seite nützliche Metriken wie "Make It" -Produktionsaufträge, "Ship It" -Lieferaufträge, Produktmanagement und mehr angezeigt. Wir freuen uns über Ihr Feedback und Ihre Geduld, wenn wir Ihre Erfahrung verbessern.',
    readyToOrder: 'Bereit, Produkte zu bestellen?',
    makeIt: 'Mach es',
    readyToSchedule: 'Bereit, eine Sendung zu planen?',
    shipIt: 'Es versenden',

    // -- USER ADMIN WIDGET -- //
    userAdministration: 'User Administration',
    setupNewUser:
        'Setup a new user with the correct permissions and locations in <i>The Source</i>.',
    userAdminDashboard: 'User Admin Dashboard',
    viewPendingRequests:
        'View pending access requests, pending new user requests, and active users in <i>The Source</i>.',
    viewUserAdminDashboard: 'View User Admin Dashboard',

    // -- Dashboard -- //
    agedSku: 'Gealterte SKU',
    agedSkus: 'Gealterte SKUs',

    // -- FAQ Page -- //
    searchFAQs: 'Search Häufig gestellte Fragen',
    searchFAQplaceholder: 'Cans, Bottles, etc!',

    // -- QUICK VIEW -- //
    production: 'Produktion',
    limitedProductionRun: 'Begrenzte Produktionsläufe',
    seeFullProductDtls: 'Siehe alle Produktdetails',
    timesPerYear: 'Times Per Year',

    // -- ATM PRODUCT FORECAST -- //
    atmProductForecastBannerTitle: 'Product Information',
    lockWindow: 'Lock Window',
    makeItLockWindow: 'Make It Lock Window',
    firstAvailableWeek: 'First Available Week',
    viewAtmProductForecast: 'View My Product Information',
    customerProductId: 'Customer Product Id',
    productInformationToday: '{{shipToId}} - Product Information as of {{today}}',
    ballProductId: 'Ball Product ID',

    // -- PRODUCT PORTFOLIO -- //
    all: 'Alle',
    bottles: 'Flaschen',
    ends: 'Dosendeckel',
    end: 'Dosendeckel',
    closures: 'Verschlüsse',
    closure: 'Schließung',
    lookingForInspiration: 'Sie suchen nach Inspiration?',
    pallets: 'Paletten',
    itemsPerPallet: 'Stück pro Palette',
    lookingForSomething: 'Sie finden nicht, was Sie suchen?',
    sendUsYourComments: 'Wonach suchen Sie?',
    minOrderPallet: 'Mindestbestellmenge in Paletten',
    cansPerPallet: 'Dosen pro Palette',
    quickLook: 'Kurzer Blick',
    select: 'Wählen',
    seeDetailsBtn: 'Siehe Produktdetails',
    compareProducts: 'Produkte vergleichen',
    pleaseSelectProducts: 'Bitte wählen Sie Produkte aus, um einen Kostenvoranschlag zu erhalten',
    endTile: 'Dosendeckel',
    myProjects: 'Meine Projekte',
    compare: 'Vergleichen',
    belowAreYourSavedProjects:
        'Nachfolgend finden Sie Ihre gespeicherten Projekte der letzten 180 Tage.',
    projectName: 'Projektname',
    productCode: 'Artikelnummer',
    savedOn: 'Gespeichert auf',
    viewProject: 'Projekt anzeigen',

    // -- FACETS -- //
    filterBy: 'Filtern nach',
    clearAll: 'Alles löschen',
    // Facet Category
    stylesCategory: 'Produktauswahl',
    sizesCategory: 'Größe',
    featuresCategory: 'Produkterweiterungen',
    endsCategory: 'Endet',
    // Facet Tooltips
    stylesTooltip: 'Produktauswahl tooltip',
    sizesTooltip: 'Größe tooltip',
    featuresTooltip: 'Produkterweiterungen tooltip',
    endsTooltip: 'Endet tooltip',

    // -- PRODUCT DETAILS -- //
    requestEstBtn: 'Angebot erfragen',
    productDetails: 'Produkt Details',
    printGuide: 'Print Guide',
    reproStudioGrid: 'Repro Studio Grid',
    customerDesignGuide: 'Kunde Design Guide',
    download: 'Download',
    canSpecification: 'Dosenspezifikation',
    dieLine: 'Die Line',
    palletSpecifications: 'Palettespezifikationen',
    greatFor: 'Perfekt für',
    specifications: 'Spezifikationen',
    currentLeadTime: 'Aktuelle Vorlaufzeit',
    fillingAvailability: 'Verfügbare Abfüllkapazitäten',
    compatibleEnds: 'Kompatible Dosendeckel',
    weeks: 'Wochen',
    months: 'Monate',
    minimum: 'Minimum',
    productEnhancements: 'Produkterweiterungen',
    compatibleFilling: 'Kompatible Abfüllung',
    pasteurization: 'Pasteurisierung',
    retort: 'Retort',
    hotFill: 'Hot Fill',
    coldFill: 'Cold Fill',
    color: 'Farbe',
    aluminum: 'Aluminum',
    // Ordering
    ordering: 'Bestellung',
    pricePerThousand: 'Preis pro 1000',
    quantityPerPallet: 'Anzahl pro Palette',
    minOrder: 'Mindestbestellung',
    // Design Specs
    designSpecs: 'Design Spezifikationen',
    circumference: 'Umfang',
    designLength: 'Design Länge (Umfang)',
    designHeight: 'Design Höhe',
    maxTALength: 'Max Textfläche (Länge)',
    maxTAHeight: 'Max Textfläche (Höhe)',
    maximumColors: 'Maximale Anzahl Farbe pro Design',
    windowLimitWidth: 'Umfang',
    windowLimitHeight: 'Designhöhe',
    underOverlap: 'Bereich Unter-/Überlappen',
    // Can Specs
    canSpecs: 'Getränkedosen Spezifikation',
    endSize: 'Dosendeckel Größe',
    bodyOutsideDiameter: 'Außendurchmesser Dose',
    flangedCanHeight: 'Dosenhöhe',
    coldFillVolume: 'Cold Fill Füllvolumen',
    retortFillVolume: 'Retort Fill Füllvolumen',
    flangedCanWidth: 'Bördelbreite',
    endDiameter: 'Endet Durchmesser',
    // Can Finishes and Effects
    canEffectsAndFeatures: 'Effekte und Funktionen für diese Dose verfügbar',
    canFinishesAndEffects: 'Druckoptionen & Effekte',
    inks: 'Druckfarben',
    overvarnish: 'Überlack',
    effects: 'Effekte',
    specialFeatures: 'Besondere Merkmale',

    // -- END DETAILS -- //
    endDetails: 'Dosendeckel Details',
    endFinishesAndEffects: 'Dosendeckel Optionen & Effekte',
    compatibleCans: 'Kompatible Getränkedosen',
    endFeaturesAndEffects: 'Dosendeckel Optionen & Effekte',
    orderIndividually: 'Kann einzeln bestellt werden',
    roppClosures: 'ROPP Closures are sold via 3rd party',
    endsPack: 'Dosendeckel Informationspaket',

    // Product Comparison
    addProduct: 'Produkt hinzufügen',
    details: 'Einzelheiten',
    name: 'Name',
    type: 'Art',
    maxColors: 'Max Farben',
    innovativeFeatures: 'Innovative Eigenschaften',
    leadTimes: 'Vorlaufzeiten',
    currentFillingAvail: 'Aktuelle Füllverfügbarkeit',
    products: 'Produkte',
    addAnotherToCompare: 'Fügen Sie ein weiteres Produkt zum Vergleichen hinzu',
    removeSelection: 'Auswahl entfernen',
    addToCompare: 'Hinzufügen zum vergleichen',
    prodComparisonDesc:
        'Vergleichen Sie bis zu drei verschiedene Produkte gleichzeitig, unabhängig von Volumen oder Stil. Sehen Sie, welche Produkte anbieten',
    prodEstimateDesc:
        'Unser Schätzer kann Ihnen helfen, die Kosten für die Bestellung von Dosen bei uns besser zu verstehen. Beachten Sie beim Erstellen Ihres Kostenvoranschlags einige Dinge',
    prodHasMinOrder: 'Jedes Produkt hat eine Mindestbestellmenge',
    orderInFullPallets:
        'Kostenvoranschläge und Bestellungen können nur in vollen Paletten aufgegeben werden',
    orderInFullTrucks:
        'Wir empfehlen die Bestellung in vollen LKW-Ladungen. Die meisten LKWs bieten Platz für ca. 25 Paletten. Bei weniger als vollen LKW-Ladungen können zusätzliche Kosten anfallen',
    // placeholders:
    productComparison: 'Produktvergleich',
    compareBuildEstimate: 'Vergleichen, Erstellen & Schätzen',
    compareBuild: 'Vergleichen & Erstellen',
    myProject: 'Mein Projekt',

    // -- CONTACT FORM -- //
    howCanWeHelp: 'How Can We Help?',
    yourName: 'Dein Name',
    email: 'Email',
    confirmEmail: 'E-Mail bestätigen',
    yourCountry: 'Ihr Füllland',
    subjectLine: 'Betreff',
    contactMessage: 'Sagen Sie uns, wie wir helfen können ...',
    submit: 'Einreichen',
    cancel: 'Stornieren',
    sendUsANote: 'Wie können wir helfen?',
    msgSuccessful: 'Mitteilung erfolgreich gesendet.',
    msgFailed:
        'Die Nachricht konnte nicht gesendet werden. Bitte versuchen Sie es zu einem anderen Zeitpunkt erneut.',
    sending: 'Senden',
    close: 'Beenden',
    contactReason: 'Kontakt Grund',
    contact: 'Ich habe eine Frage',
    support: 'Ich brauche Hilfe',
    login: `I can't log in`,

    // --- PRODUCT ESTIMATE -- //
    getAnEstimate: 'Holen Sie sich einen Kostenvoranschlag',
    desiredAnnualQuantity: 'Gewünschte jährliche Menge',
    enterQuantity: 'Menge eingeben',
    // wouldYouLikeEndsWithThisProduct: 'Möchten Sie mit diesem Produkt Dosendeckel?',
    remove: 'Entfernen',
    estimateFailedToSend:
        'Die Schätzung konnte nicht gesendet werden. Bitte versuchen Sie es zu einem anderen Zeitpunkt erneut',
    productEstInquiry: 'Produktschätzung Anfrage',
    submittingEstimate: 'Senden Sie die folgenden Informationen für eine Schätzung',
    enterValueGreaterThan: 'Bitte geben Sie einen Wert größer als 0 ein',

    // -- CONFIRMATION POP UP -- //
    backToHome: 'Zurück nach Hause',
    thankYou: 'Vielen Dank',
    yourRequestHasBeenSubmitted: 'Ihre Anfrage wurde eingereicht!',
    someoneFromOurTeamWillBeInTouchSoon: 'Jemand aus unserem Team wird sich bald bei Ihnen melden.',

    // -- REGISTRATION -- //
    registrationHeader: 'Lass uns die Kugel ins Rollen bringen',
    registrationDescription:
        'Wir sind fast da, wir brauchen nur ein paar Informationen von Ihnen, um zum Ball-Kundenportal zu gelangen, wo Sie mehr über Dosen erfahren und wie Sie bestellen.',
    region: 'Region',
    language: 'Sprache',
    aboutYourCompany: 'Über Ihr Unternehmen',
    companyName: 'Firmenname',
    country: 'Land',
    stateProvince: 'Staat / Provinz',
    city: 'Stadt',
    postalCode: 'Postleitzahl',
    yearsInBusiness: 'Jahre im Geschäft',
    numberOfEmployees: 'Anzahl der Angestellten',
    estimatedAnnualRevenue: 'Geschätzter Jahresumsatz',
    beverageInRetail: 'Haben Sie derzeit ein Getränk im Einzelhandel?',
    currentPackageType: 'Aktueller Pakettyp',
    beveragePackagingNeeds: 'Ihre Getränkeverpackungsbedürfnisse',
    beverageCategory: 'Getränkekategorie',
    productSize: 'Produktgröße',
    expectedAnnualQuantity: 'Erwartete jährliche Menge',
    canningLine: 'Besitzen Sie oder planen Sie den Kauf einer Dosenlinie?',
    copackerName: 'Co-Packer Name',
    fillingCountry: 'Land füllen',
    fillingCity: 'Stadt füllen',
    additionalComments: 'Zusätzliche Kommentare: (optional)',
    artworkComments: 'Bildmaterial-Kommentare (optional)',
    setupCustomerPortalAccount: 'Richten Sie Ihr Kundenportal-Konto ein',
    firstName: 'Vorname',
    lastName: 'Nachname',
    title: 'Title',
    jobTitle: 'Job Title',
    phoneNumber: 'Telefonnummer',
    password: 'Passwort',
    confirmPassword: 'CKennwort bestätigen',
    alreadyRegistered: 'Bereits registriert?',
    accountCreated: 'This email account is already tied to The Source.',
    signIn: 'Anmelden',
    signInConfirmation: 'Please Sign In to The Source and if needed, reset your password.',
    yes: 'Ja',
    no: 'Nein',
    incorrectEmailAddress: 'Falsche E-Mail Adresse',
    atLeastEightLetters: 'Mindestens 8 Buchstaben',
    atLeastOneCapitalLetter: 'Mindestens ein Großbuchstabe',
    atLeastOneLowerCaseLetter: 'Mindestens ein Kleinbuchstabe',
    atLeastOneNumber: 'Mindestens eine Nummer',
    mustNotBeACommonPassword: 'Darf kein allgemeines Passwort sein',
    passwordsShouldMatch: 'Passwörter sollten übereinstimmen!',
    year: 'jahr',
    printType: 'Would you like any of the following can types?',
    iAgree: 'Ich stimme den',
    termsOfUse: 'Nutzungsbedingungen und den Datenschutzrichtlinien zu',
    million: 'Million',
    newToBeverage: 'New to Beverage',
    selectSecurityQuestion: 'Frage',
    securityAnswer: 'Antwort',
    invalidSecurityAnswer: 'Antwort muss größer als 5 Zeichen sein',
    emailValidationError: 'E-Mail stimmt nicht mit dem erforderlichen Muster überein',
    passwordValidationError: 'Passwort darf kein allgemeines Passwort sein',
    securityAnswerValidationError:
        'Die Antwort der Sicherheitsfrage ist zu schwach, die Antwort darf nicht Teil der Frage sein.',
    unknownValidationError:
        'Beim Erstellen eines neuen Benutzerkontos ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Helpdesk.',
    selectSecurityQuestionPrompt:
        'Wählen Sie eine Sicherheitsfrage und eine Antwort aus, damit Sie Ihr Kennwort automatisch zurücksetzen können, wenn Sie Probleme bei der Anmeldung bei Ihrem Konto haben.',
    emailOptOut1:
        'By submitting this form, you are subscribing to receive the latest news and updates straight to your inbox.',
    emailOptOut2:
        'Ball Corporation may also contact you from time to time about our products and services as detailed in our',
    privacyPolicy: 'Privacy Policy',
    emailOptOut3: 'Please check this box if you’d rather not receive these emails.',

    // Registration Print Types
    brightCans: 'Bright Cans',
    sleevedCans: 'Sleeved Cans',
    printedCans: 'Printed Cans',

    // Registration Beverage Categories
    beverageCategoryBeer: 'Bier',
    beverageCategoryBeerCraft: 'Bier- Handwerk',
    beverageCategoryBeerDomestic: 'Bier- Inländischen',
    beverageCategoryBeerImport: 'Bier- Importieren',
    beverageCategoryCsd: 'Kohlensäurehaltiges Softdrink',
    beverageCategoryCider: 'Cidre',
    beverageCategoryCoffee: 'Kaffee',
    beverageCategoryIcedCoffee: 'Eiskaffee',
    beverageCategoryEnergyDrink: 'Energy Drinks',
    beverageCategoryFab: 'FAB’s',
    beverageCategoryFmb: 'Aromatiertemal Getränk',
    beverageCategoryHardLiquor: 'Hard Liquor',
    beverageCategoryThc: 'Hanf/CBD/THC',
    beverageCategoryJuice: 'Saft',
    beverageCategoryProtein: 'Protein-basierte/Milchbasiert',
    beverageCategoryRtd: 'RTD',
    beverageCategorySpirits: 'Spirituosen',
    beverageCategorySportsDrink: 'Sportgetränke',
    beverageCategoryTea: 'Tee',
    beverageCategoryIcedTea: 'Eistee',
    beverageCategoryWater: 'Wasser',
    beverageCategoryWine: 'Wein',
    beverageCategoryHardSeltzer: 'Hard Seltzer',
    beverageCategoryOther: 'Andere',

    // Registration Security Questions
    securityQuestionFood: 'Welches Nahrungsmittel mochten Sie als Kind am wenigsten?',
    securityQuestionStuffAnimal: 'Was ist der Name Ihres ersten Stofftiers?',
    securityQuestionAward: 'Wofür verdienten Sie sich Ihre erste Medaille oder Auszeichnung?',
    securityQuestionComputerGame: 'Was war das erste Computerspiel, das Sie gespielt haben?',
    securityQuestionMovieQuote: 'Was ist Ihr Lieblingsfilmzitat?',
    securityQuestionSportsTeam:
        'Was war das Maskottchen der ersten Sportmannschaft, bei der Sie mitspielten?',
    securityQuestionDessert: 'Was war das Lieblingsdessert Ihrer Großmutter?',
    securityQuestionCook: 'Was war das erste Gericht, das Sie zu kochen gelernt haben?',
    securityQuestionDreamJob: 'Was war Ihr Traumberuf als Kind?',
    securityQuestionVacation: 'Wohin sind Sie in Ihrem Lieblingsurlaub gereist?',

    // -- PRICING BREAKDOWN -- //
    downloadPDF: 'Download .PDF',
    effectiveDate: 'Effective Date',
    currency: 'Currency',
    itemDescription: 'Item Description',
    currentBasePrice: 'Current Base Price',
    newBasePrice: 'New Base Price',
    freightChargeFromLocation: 'Freight Charge From Location (if available)',
    freightChargesAvailable: 'Freight Charges (if available)',
    newBaseWithFreight: 'New Base Price With Freight (if available)',
    pricingLetterDisclaimer:
        'This price sheet serves as notice of new pricing effective {{ effectiveDate }} and is not a guarantee of current or future supply. Your submission of an order to purchase products constitutes your acceptance of this updated pricing under Ball’s current terms of sale.',
    pricingQtyNote: 'Prices are listed at a per thousand rate unless otherwise noted',
    priceSheet: 'Price Sheet',

    // -- KB MAX -- //
    errorProcessing: 'Es ist ein Fehler bei der Verarbeitung aufgetreten.',
    exitWarning: 'Sie sind sicher, dass Sie es beenden wollen?',
    yesExit: 'Ja, Beenden',
    exit: 'Beenden',

    // Validation
    required: 'Erforderlich',

    // -- EMEA GRAPHIC INTAKE -- //
    // Form Overview
    graphicsSubmissionInfo: 'Allgemeine Daten',
    designInfo: 'Dekor Daten',
    colourInfo: 'Farben',
    marketInfo: 'Markt',
    productQuality: 'Spezifikation der produktqualität',
    approverInfo: 'Für Abnahme',
    // General Info
    graphicsSubInfo: 'Allgemeine Daten',
    companyId: 'Kunde',
    contractFiller: 'Abfüller',
    brandOwner: 'Markeninhaber',
    canSize: 'Dosengröße',
    canType: 'Dosen Typ',
    metalType: 'Metall',
    // Design Info
    designSubInfo: 'Dekor Daten',
    graphicType: 'Anfrage',
    requestTypeNew: 'Neues Dekor?',
    requestTypeExisting: 'Änderung?',
    requestTypePromo: 'Promo Design – basierend auf (aber nicht ersetzen) bestehenden Designs',
    designPoNo: 'Dekor Bestellnr.',
    designName: 'Dekorname',
    previousBallDesignNum: 'Letzte Ball Dekor Nr.',
    newCustRefNum: 'Neu Kunden Dekor Nr.',
    oldCustRefNum: 'Letzte Kunden Dekor Nr.',
    barcode: 'EAN Code',
    barcodeBarsColor: 'EAN Code Farbe (Streifen)',
    barcodeBarsColorHelper: 'Geben Sie den Farbton für die Streifen ein.',
    barcodeInfillColor: 'EAN Code Farbe (Hintergrund)',
    barcodeInfillColorHelper: 'Geben Sie den Farbton für den Barcode-Hintergrund ein.',
    specialFinish: 'Ball Spezial Anfertigung',
    specialFinishHelper:
        'Wählen Sie aus, welchen Spezialeffekt für das Layout Sie Ihrem Artwork hinzufügen möchten. Standardmäßig ist dieses Feld auf "keine besondere Ausführung" eingestellt. Bitte beachten Sie: es wird ein Preisaufschlag berechnet. Wenden Sie sich an Ihr Ball-Werbeteam, um die aktuellen Preise für unsere Mehrwerte zu erfahren.',
    requestType: 'Anfrage',
    // Color Info
    colourSubInfo: 'Farben',
    overVarnish: 'Überlackierung',
    basecoatColour: 'Untergrundierung (nur Stahl)',
    specialInk: 'Ball Spezial Farbe',
    colourTarget: 'Farbvorgabe',
    colourTargetHelper: 'Example: Crown Can',
    // Market Info
    marketSubInfo: 'Markt',
    distributeCountriesHelperText:
        'Bitte spezifizieren Sie in welchen Ländern dieses Artwork verkauft werden wird. (Alle zutreffenden Antworten auswählen)',
    depositScheme: 'Pfandsystem',
    depositSchemeHelper:
        'Geben Sie an, ob Ihr Artwork in einem Land vermarktet werden soll, das für ein Pfandsystem zugelassen ist (wählen Sie aus der Dropdown-Liste)',
    recycleMark: '“Metal recycles forever Mark” auf dem Dekor. Verfügbar in mehreren Sprachen.',
    recycleMarkHelper:
        'Wählen Sie "Ja", wenn Sie möchten, dass Ball das spezielle MRF-Logo auf Ihrer Dose anbringt. Durch die Verwendung dieses Logos fördern Sie die unendliche Recyclingfähigkeit von Aluminium und eine echte Nachhaltigkeitsbotschaft an Ihre Verbraucher.',
    glnNumber: 'Kunde GLN nr.',
    glnNumberHelper:
        'Geben Sie Ihren 13-stelligen GLN-Code in dieses Feld ein. Wenn Ihr Artwork auf dem deutschen Markthinterlegungsberechtigt ist, ist Ball verpflichtet, Ihre GLN-Nummer in unserem System zu registrieren. Die GLN-Nummer wird von der deutschen Pfandorganisation nach der Registrierung in ihrem System bereitgestellt. Ball muss diese Registrierung vor der Produktion Ihres Artworks überprüfen. ',
    asiCertified: 'Ist dieses Design ASI-zertifiziert?',
    // Product Quality Info
    productQualitySubInfo: 'Especificación de calidad del producto',
    productContent: 'Produktinhalt',
    productFormulation: 'Produktrezeptur',
    refDesignNum: 'Falls existent Dekornr.als Referenz?',
    productPasteurisation: 'Produkt Pasteurisierung',
    bpaFree: 'BPANI erforderlich?',
    bpaniId: 'BPANI Identifizierung',
    // Approval Info (For Approval)
    approvalSubInfo: 'Für Abnahme',
    forApprovalSubTitle:
        'Ball schickt zur Prüfung und Freigabe immer ein PDF. Ball schickt zur Farbfreigabe entweder einen Contract Proof oder Anrollungen außer wenn wir eine klare Produktionsvorgabe erhalten haben.',
    proofSession: 'Hand-Anmusterung',
    attendProduction: 'Anwesenheit bei Anmusterung',
    responsibleForApproval: 'Abnahme von',
    emailAddress: 'E-Mail',
    // Additional Remarks
    generalCommentsSubInfo: 'Allgemeine Bemerkungen',
    generalComments: 'Allgemeine Bemerkungen',
    generalCommentsHelper: 'Geben Sie allgemeine Kommentare ein, die Ball beachten soll',
    additionalRemarksSubInfo: 'Weitere Kommentare',
    additionalRemarks: 'Weitere Kommentare',
    responsibleForCompletion: 'Ausgefüllt von',
    hard: 'Schwer',
    soft: 'Sanft',
    addAnotherApprover: 'Add Another Approver',

    // -- NOTIFICATIONS -- //
    CAMPAIGN_ENDING:
        'Ordering for your {0} products will close on {1}.  The first available production date is {2}. ',
    CAMPAIGN_UPCOMING:
        'Ordering for your {0} products will begin on {1}.  Orders must be placed by {2} and the first available production date is {3}. ',
    CAMPAIGN_UPDATE:
        'Ordering information for your {0} products has changed.  The updated ordering window is: {1} - {2} and the first available production date is {3}. ',

    // -- MAKE IT DRAFTS SUMMARY -- //
    createdDate: 'Created Date',
    unauthorizedPoTooltip: 'Not authorized to manage POs for this location',
    discard: 'Discard',
    resumeOrder: 'Resume Order',

    // -- MANAGE PRODUCTS -- //
    manageProducts: 'Manage Products',
    productInformation: 'Product Information',
    activeProducts: 'Active Products',
    inactiveProducts: 'Inactive Products',

    // Countries
    usa: 'USA',
    eu: 'Europa',
    uk: 'Vereinigtes Königreich',
    germany: 'Deutschland',
    france: 'Frankreich',
    canada: 'Kanada',
    china: 'China',
    japan: 'Japan',
    southKorea: 'Südkorea',
    ausNZ: 'Australien/Neuseeland',
    australia: 'Australia',
    nz: 'Neuseeland',
    mexico: 'Mexiko',
    india: 'Indien',
    vietnam: 'Vietnam',
    taiwan: 'Taiwan',
    russia: 'Russland',
    argentina: 'Argentinien',
    brazil: 'Brazilien',
    paraguay: 'Paraguay',
    uruguay: 'Uruguay',
    venezuela: 'Venezuela',
    noCountryApplies: 'Keines der angegebenen Länder gilt',

    // -- User Admin -- //
    arAp: 'Kreditorenbuchhaltung / Debitorenbuchhaltung',
    artMgmt: 'Kunst-Management',
    forecastMgmt: 'Prognosemanagement',
    marketingMgmt: 'Marketing-Management',
    packagingMgmt: 'Verpackungsmanagement',
    procurementMgmt: 'Beschaffungsmanagement',
    impersonation: 'Impersonation',
    addUser: 'Benutzer hinzufügen',
    pendingRequests: 'Offene Anfragen',
    pendingRegistration: 'Ausstehende Registrierung',
    activeUsers: 'Aktive Benutzer',
    rolesLocations: 'Rollen (Anzahl der Standorte)',
    actions: 'Maßnahmen',
    viewEdit: 'Anzeigen / Bearbeiten',
    disable: 'Deaktivieren',
    roles: 'Rolle (n)',
    sentOn: 'Gesendet am',
    resend: 'Erneut senden',
    reviewRequest: 'Anfrage prüfen',
    disableUserError: 'Das System konnte den ausgewählten Benutzer nicht deaktivieren',
    disableUser: 'Benutzer deaktivieren',
    disableUserWarning:
        'Sie sind dabei, die Berechtigungen dieses Benutzers für Ihr Konto zu deaktivieren. Dieser muss eine neue Anfrage erstellen, um die Berechtigungen wiederzuerlangen. Sind Sie sicher, dass Sie ',
    yesDisable: 'Ja, deaktivieren',
    location: 'Standort',

    // -- GRAPHICS NAVIGATION -- //
    graphicsTitle: 'Grafik',
    submitNewGraphicLink: 'Beginnen Sie das Formular Neue Grafik',

    // -- GRAPHICS SUMMARY -- //
    graphicsSummary: 'Kurzvorstellung der Grafikabteilung',
    recentlyCompletedEmea: 'Fertiggestellt',
    draftGraphicsFormsEmea: 'Formulare für Grafik-Entwürfe',
    draftGraphics: 'Formulare für Grafik-Entwürfe',
    lastThreeSixtyFive: 'Letzte 365 Tage',
    graphicNameAndIdEmea: 'Name und Nummer des Designs',
    completeIntakeForm: 'Aufnahmeformular abschließen',
    status: 'Status',
    availableActions: 'Verfügbare Maßnahmen',
    graphicName: 'Name der Grafik',
    learnAboutGraphics: 'Erfahren Sie mehr über unsere Grafikabteilung',
    graphicsProcessEmea:
        'Bei Ball folgen wir einem bewährten Verfahren, um zu gewährleisten, dass unser Produkt gemäß den höchsten Qualitäts- und Sorgfaltsmaßstäben gefertigt und geliefert wird. Im Folgenden sehen Sie eine Zeitschiene, um unseren Grafikprozess besser zu verstehen.',
    graphicsNoteEmea:
        'Hinweis: Wenn ein Anbieter Ihre Grafik einreicht, erhalten Sie eine Benachrichtigung, wenn Ihre Grafik eingeht und wenn diese fertiggestellt ist.',
    newGraphicLink: 'Beginnen Sie das Formular Neue Grafik',
    inProgress: 'In Bearbeitung',
    inProgressGraphics: 'Grafik in Bearbeitung',
    graphicSpecialistEmea: 'Grafik-Koordinator',
    graphicStatusHistory: 'Grafik-Statusverlauf',
    statusDate: 'Statusdatum',
    currentStatus: 'Aktueller Status',
    inStatusSince: 'In Status Since',
    deleteYourDraftEmea: 'Löschen Sie Ihre Entwurfsgrafik',
    graphicDeleteConfirmationEmea:
        'Sind Sie sicher, dass Sie das Eingabeformular für diese Entwurfsgrafik löschen möchten?',
    graphicPermantlyGone: ' Das Formular wird dauerhaft gelöscht.',
    saveProgressAttachments:
        "Angehängte Dokumente werden nicht gespeichert, wenn 'PROGRESS SPEICHERN' ausgewählt ist.",
    yesDelete: 'Ja, löschen',
    noCancel: 'Nein, abbrechen',
    graphicDraftSavedEmea: 'Entwurfsgrafik gespeichert',
    draftHasBeenSuccessfullySaved: 'Ihr Entwurf wurde erfolgreich gespeichert!',
    backToDashboardEmea: 'Zurück zu meinem Dashboard',
    viewMyInProgressGraphicsEmea: 'Kurzvorstellung der Grafikabteilung ansehen',
    cancelSubmissionEmea: 'Ihre Einreichung abbrechen',
    intakeCancelConfirmationEmea:
        'Sind Sie sicher, dass Sie Ihre Arbeit abbrechen möchten, ohne zu speichern?',
    changesPermanentlyGoneEmea:
        'Jegliche Änderungen an Ihrer Einreichung werden dauerhaft durchgeführt.',
    deleteProgressEmea: 'Fortschritte löschen',
    saveProgressEmea: 'Fortschritte speichern',
    submitNewGraphic: 'Neue Grafik',
    submitNewGraphicEmea: 'Beginnen Sie das Formular Neue Grafik',
    pending: 'Ausstehend',
    selectBillTo: 'Rechnungsadresse auswählen',
    billTos: 'Rechnungsadressen',

    // Graphics Status
    ART_REC: 'Artwork Received; Preparing your Quote',
    QOT_APPRVL: 'Quote Ready for Approval',
    PRF_INPRGRS: 'Creating Proofs',
    PRF_APPRVL: 'Proof Ready for Approval',
    PLT_SCHLD: 'Pilot Scheduled',
    PLT_APPRVL: 'Standard Ready for Approval',
    GRPHC_CMPL: 'Graphics Complete',
    noRecordsFound: 'No Records Found',

    // EMEA Graphics Status
    ARFC: 'Antwort erhalten; Prüfung der Machbarkeit wird durchgeführt',
    FCOH: 'Machbarkeitsprüfung angehalten',
    FCCI: 'Prüfung der Machbarkeit abgeschlossen; Eingaben des Kunden erforderlich – Wiederholung {0}',
    LDIP: 'Layout-Entwicklung wird durchgeführt',
    LDOH: 'Layout-Entwicklung angehalten',
    LPRA: 'Layout-Probedruck bereit zur Vorlage',
    LAPP: 'Layout genehmigt',
    CDIP: 'Farb-Entwicklung wird durchgeführt',
    CDOH: 'Farb-Entwicklung angehalten',
    CTRA: 'Farb-Zielbereich bereit zur Vorlage',
    CAPP: 'Farben genehmigt',
    PIPR: 'Probedruck wird begutachtet',
    PCRA: 'Probedosen bereit zur Vorlage',
    DDCA: 'Designentwicklung abgeschlossen',
    DDCC: 'Designentwicklung abgeschlossen – Änderungen ausstehend',
    PAPP: 'Proof-Dose genehmigt',

    //Bulk Upload Errors
    EP100004:
        'Das angegebene Datum ist ungültig oder hat das falsche Format. Bitte überprüfen und als TT/MM/JJJJ eingeben.',

    // -- MAKE IT BULK UPLOAD -- //
    inactiveProductId: 'Dieses Produkt ist inaktiv und kann nicht bestellt werden.',

    // -- FEEDBACK SURVEY -- //
    feedbackHeader: 'Wir möchten Ihr Feedback!',
    satisfaction: 'Wie zufrieden sind Sie insgesamt mit The Source?',
    enterScore: 'Bitte geben Sie eine Punktzahl ein',
    visitPurpose: 'Was war der Hauptzweck für Ihren heutigen Besuch?',
    selectOption: 'Wählen Sie eine Option aus',
    'Learn about working with Ball': 'Erfahren Sie mehr über die Arbeit mit Ball',
    'Get Design Inspiration': 'Eine Design-Inspiration bekommen',
    'Investigate Lab and Liquid': 'Labor und Flüssigkeit untersuchen',
    'Explore Sustainability & Regulatory': 'Nachhaltigkeit und Regulierung entdecken',
    'Learn about Handling & Filling': 'Erfahren Sie mehr über Handhabung und Befüllung',
    'Access Can Specifications': 'Zugriff auf Dosenspezifikationen',
    'Obtain Pricing Information': 'Preisinformationen erhalten',
    Other: 'Sonstige',
    pleaseSpecify: 'Bitte angeben:',
    suggestionLabel:
        'Gibt es noch etwas, was wir tun können, um Ihren Besuch bei The Source zu verbessern?',
    contactPreferenceLabel:
        'Wenn wir eine Klarstellung zu Einer Ihrer Antworten benötigen, können wir uns mit Ihnen in Verbindung setzen?',
    'Yes, I prefer a phone call': 'Ja, ich bevorzuge einen Anruf',
    'Yes, I prefer email': 'Ja, ich bevorzuge E-Mail',
    'No, please do not contact me': 'Nein, bitte nicht mit mir kontaktiere',
    selectContactPreference: 'Bitte wählen Sie eine Kontaktpräferenz aus',
    nameLabel: 'Name',
    phoneLabel: 'Telefonnummer',
    emailLabel: 'Email address:',
    emailError: 'Bitte geben Sie eine gültige Email-Adresse ein',
    closeButton: 'Beenden',
    submitButton: 'Senden',
    feedbackThankYou: 'Vielen Dank für Ihr Feedback',
    feedbackPrivacyPolicy: 'Datenschutzrichtlinie verwendet',
    privacyPolicyMessage: 'Ihre Antworten werden gemäß unserer'
};
