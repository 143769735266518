import { Grid, makeStyles, Typography, FormHelperText } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { small, red } from '../../../../themes/globalConstants';
import { useFormContext, ErrorMessage } from 'react-hook-form';
import RadioInput from '../../../reusable/molecules/RadioInput';
import TextInput from '../../../reusable/molecules/TextInput';
import FormSection from '../../../reusable/molecules/FormSection';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { setFormSectionStatus } from '../../../../store/root-actions';
import { useDispatch } from 'react-redux';
import { COLOR_MATCHING_STATUS_UPDATE } from '../../../../store/actions/action-types';
import { Graphics } from '../../../../store/reducers/graphic-intake';

interface Props {
    isEditDraft: boolean;
    graphicData?: Graphics;
}

const useStyles = makeStyles((theme) => ({
    label: {
        color: theme.palette.info.dark,
        fontSize: small,
        margin: '2.5em 0'
    },
    radioGroup: {
        marginTop: '1.5em',
        paddingLeft: '0.5em'
    },
    helperText: {
        '& .MuiFormHelperText-root': {
            marginLeft: 0
        }
    },
    errorText: {
        color: red
    }
}));

const ColorMatching = ({ isEditDraft, graphicData }: Props) => {
    const classes = useStyles();
    const graphicIntake = useTypedSelector((state) => state.graphicIntake);
    const { control, errors, formState, watch, setValue } = useFormContext();
    const { isValid } = formState;
    const dispatch = useDispatch();
    const isColorMatching = watch('colorMatchingStr') === 'true';
    const isRequired = <Trans i18nKey="required">Required</Trans>;

    useEffect(() => {
        if (graphicIntake && isValid !== graphicIntake.colorMatchingValid) {
            dispatch(setFormSectionStatus(isValid, COLOR_MATCHING_STATUS_UPDATE));
        }
    }, [dispatch, isValid]);

    useEffect(() => {
        if (isEditDraft && graphicData && isColorMatching) {
            setValue('colorComment', graphicData.colorComment);
            setValue('dateCode', graphicData.dateCode);
        }
    }, [isColorMatching, setValue]);

    useEffect(() => {
        if (isEditDraft && graphicData) {
            setValue('colorMatchingStr', graphicData.colorMatching?.toString());
        }
    }, [isEditDraft, graphicData, setValue]);

    useTranslation();

    return (
        <FormSection
            testId={'color-matching-section'}
            sectionHeader={<Trans i18nKey="colorMatching">Color Matching</Trans>}
        >
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        <Trans i18nKey="colorMatchingText">
                            Color matching of existing aluminum cans (does not have to be
                            Ball-produced) or from other printed collateral (cardboard packaging,
                            can/bottle wrap/printed paper, etc.) Upon completion of this intake
                            form, you will be provided a printable document to include in your
                            package with your color matching sample, and the address where you
                            should mail your sample.
                        </Trans>
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item xs={12}>
                <Grid item xs={10}>
                    <RadioInput
                        styles={classes.radioGroup}
                        label={
                            <Trans i18nKey="doYouNeedColorMatching">
                                Do you need to color match existing aluminum or other collateral?
                            </Trans>
                        }
                        name="colorMatchingStr"
                        testId="colorMatching"
                        control={control}
                        radioControls={[
                            { value: 'true', label: <Trans i18nKey="yes">Yes</Trans> },
                            { value: 'false', label: <Trans i18nKey="no">No</Trans> }
                        ]}
                        rules={{ required: isRequired }}
                        error={errors.colorMatchingStr}
                        errors={errors}
                        defaultValue=""
                    />
                </Grid>
            </Grid>
            {isColorMatching && (
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            <Trans i18nKey="colorMatchComment">
                                Please describe which color you would like matched on your
                                collateral
                            </Trans>
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            type="text"
                            label={<Trans i18nKey="comments">Comments</Trans>}
                            name="colorComment"
                            testId="colorComment"
                            multiline={true}
                            control={control}
                            rules={{ required: isRequired }}
                            error={errors.colorComment}
                            errors={errors}
                        />
                        <FormHelperText className={classes.errorText}>
                            {errors.colorComment && errors.colorComment.type === 'required' && (
                                <ErrorMessage
                                    errors={errors}
                                    name={'colorComment'}
                                    message={isRequired}
                                />
                            )}
                        </FormHelperText>
                    </Grid>
                </Grid>
            )}
            <Grid container item xs={12} spacing={2}>
                {isColorMatching && (
                    <Grid item xs={6}>
                        <TextInput
                            type="text"
                            label={<Trans i18nKey="dateCode">Date Code Matching</Trans>}
                            name="dateCode"
                            testId="dateCode"
                            control={control}
                            rules={{ pattern: /^[a-zA-Z0-9]{9}\*?$/ }}
                            error={errors.dateCode}
                            errors={errors}
                            styles={classes.helperText}
                            helperText={
                                <Trans i18nKey="dateCodeText">
                                    9 or 10 digit code located near the UPC barcode. Exact location
                                    will vary by can size and manufacturer.
                                </Trans>
                            }
                        />
                        <FormHelperText className={classes.errorText}>
                            {errors.dateCode && (
                                <ErrorMessage
                                    errors={errors}
                                    name={'dateCode'}
                                    message={
                                        <Trans i18nKey="dateCodeMinMaxError">
                                            Please make sure Date Code is 9 or 10 characters. Tenth
                                            character may only be an asterisk.
                                        </Trans>
                                    }
                                />
                            )}
                        </FormHelperText>
                    </Grid>
                )}
            </Grid>
        </FormSection>
    );
};
export default ColorMatching;
