import { DeliveryOrder, ProductToShip } from './shipping-dashboard';
import { Account } from './customer-context';
import {
    EDIT_SHIPMENTS_SUMMARY_LOADING,
    EDIT_SHIPMENTS_SUMMARY_LOADED,
    EDIT_SHIPMENTS_SUMMARY_LOADING_ERROR,
    EDIT_SHIPMENTS_SUMMARY_UPDATE_ORDER,
    EDIT_SHIPMENTS_SHIPMENT_EDITED,
    EDIT_SHIPMENTS_SHIPMENT_CANCELLED,
    EDIT_SHIPMENTS_CLEAR_STATE,
    EDIT_SHIPMENTS_UPDATE_AVAILABLE_PALLETS,
    EDIT_SHIPMENTS_UPDATE_DELIVERY_DATE,
    EDIT_SHIPMENTS_PRODUCTS_LOADED,
    EDIT_SHIPMENTS_PRODUCTS_LOADING,
    EDIT_SHIPMENTS_PRODUCTS_LOADING_ERROR,
    EDIT_SHIPMENTS_NO_PRODUCTS,
    EDIT_SHIPMENTS_UPDATE_PALLETS_BY_DAY,
    EDIT_SHIPMENTS_UPDATE_PALLET_TOTALS,
    EDIT_SHIPMENTS_UPDATE_PRODUCTS_TO_EDIT,
    EDIT_SHIPMENTS_CLEAR_PRODUCTS_TO_EDIT,
    EDIT_SHIPMENTS_UPDATE_PRODUCT_AVAILBILITY_SUCCESS,
    EDIT_SHIPMENTS_UPDATE_PRODUCT_AVAILBILITY_EXCLUDE_DO,
    EDIT_SHIPMENTS_EXPANDABLE_LOAD_CHANGE,
    EDIT_SHIPMENTS_LAST_EDITED_SHIPMENT_ID
} from '../actions/action-types';

export enum DateChangeTypes {
    InitialDateOrAfterOriginal,
    DateEditedOrBeforeOriginal,
    DateEdited,
    DateSameOrAfterOriginal
}

export interface ProductPalletTotals {
    productSku: string;
    palletTotal: number;
    originalPalletTotal?: number;
}

export interface ProductPalletsByDate {
    date: string;
    shipmentId: string;
    products: ProductPalletTotals[];
}

export interface EditShipment {
    deliveryOrder: DeliveryOrder;
    shipToAccount: Account;
    modeOfTransport: string;
}
export interface ExpandableLoad {
    shipmentId?: string;
    expanded: boolean;
    loadId?: number;
}

export interface EditShipmentsState {
    deliveryToEdit?: EditShipment;
    productsToEdit: ProductToShip[];
    productPalletTotals: ProductPalletTotals[];
    palletTotalsByDay: ProductPalletsByDate[];
    loading: boolean;
    loaded: boolean;
    lastEditedShipmentId?: string;
    error?: any;
    /**
     * The products available for the requested date.  Accessed using the requested date,
     * formatted as 'MM/DD/YYYY'.
     */
    newProductAvailabilityByDay: { [key: string]: Array<NewProductAvailability> };
    newProductAvailabilityByDayExcludeDO: { [key: string]: Array<NewProductAvailability> };
    expandableLoads?: ExpandableLoad[];
}

export interface NewProductAvailability {
    productSku: string;
    palletsAvailable: number;
}

const initialState: EditShipmentsState = {
    deliveryToEdit: undefined,
    productsToEdit: [],
    productPalletTotals: [],
    palletTotalsByDay: [],
    loading: false,
    loaded: false,
    newProductAvailabilityByDay: {},
    newProductAvailabilityByDayExcludeDO: {},
    expandableLoads: []
};

const editShipments = (state = initialState, action: any): EditShipmentsState => {
    switch (action.type) {
        case EDIT_SHIPMENTS_SUMMARY_LOADING:
            return {
                ...state,
                loaded: false,
                loading: true,
                error: undefined
            };
        case EDIT_SHIPMENTS_SUMMARY_LOADED:
            return {
                ...state,
                loaded: true,
                loading: false,
                deliveryToEdit: action.deliveryToEdit
            };
        case EDIT_SHIPMENTS_SUMMARY_LOADING_ERROR:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: action.error
            };
        case EDIT_SHIPMENTS_SUMMARY_UPDATE_ORDER:
            return {
                ...state,
                loading: false,
                deliveryToEdit: action.deliveryToEdit
            };
        case EDIT_SHIPMENTS_PRODUCTS_LOADING:
            return {
                ...state,
                loaded: false,
                loading: true,
                error: undefined
            };
        case EDIT_SHIPMENTS_PRODUCTS_LOADED:
            return {
                ...state,
                loaded: true,
                loading: false,
                productsToEdit: action.productsToEdit
            };
        case EDIT_SHIPMENTS_PRODUCTS_LOADING_ERROR:
            return {
                ...state,
                loaded: false,
                loading: false,
                error: action.error
            };
        case EDIT_SHIPMENTS_NO_PRODUCTS:
            return {
                ...state,
                loaded: false,
                loading: false,
                productsToEdit: action.productsToEdit
            };
        case EDIT_SHIPMENTS_SHIPMENT_EDITED:
            return {
                ...state,
                loading: false,
                loaded: true,
                deliveryToEdit: action.deliveryToEdit
            };
        case EDIT_SHIPMENTS_SHIPMENT_CANCELLED:
            return {
                ...state,
                loading: false,
                loaded: true,
                deliveryToEdit: action.deliveryToEdit
            };
        case EDIT_SHIPMENTS_CLEAR_STATE:
            return {
                ...state,
                loading: false,
                loaded: true,
                deliveryToEdit: action.deliveryToEdit
            };
        case EDIT_SHIPMENTS_UPDATE_DELIVERY_DATE:
            return {
                ...state,
                loading: false,
                loaded: true,
                deliveryToEdit: action.deliveryToEdit,
                productsToEdit: action.productsToEdit
            };
        case EDIT_SHIPMENTS_UPDATE_AVAILABLE_PALLETS:
            return {
                ...state,
                loading: false,
                loaded: true,
                deliveryToEdit: action.deliveryToEdit
            };
        case EDIT_SHIPMENTS_UPDATE_PALLETS_BY_DAY:
            return {
                ...state,
                loading: false,
                loaded: true,
                palletTotalsByDay: action.palletTotalsByDay
            };
        case EDIT_SHIPMENTS_UPDATE_PALLET_TOTALS:
            return {
                ...state,
                loading: false,
                loaded: true,
                productPalletTotals: action.productPalletTotals
            };
        case EDIT_SHIPMENTS_UPDATE_PRODUCTS_TO_EDIT:
            return {
                ...state,
                loading: false,
                loaded: true,
                productsToEdit: action.productsToEdit
            };
        case EDIT_SHIPMENTS_CLEAR_PRODUCTS_TO_EDIT:
            return {
                ...state,
                productsToEdit: []
            };
        case EDIT_SHIPMENTS_UPDATE_PRODUCT_AVAILBILITY_SUCCESS:
            return {
                ...state,
                newProductAvailabilityByDay: action.newAvailability
            };
        case EDIT_SHIPMENTS_UPDATE_PRODUCT_AVAILBILITY_EXCLUDE_DO:
            return {
                ...state,
                newProductAvailabilityByDayExcludeDO: action.newAvailabilityExcludingDo
            };
        case EDIT_SHIPMENTS_EXPANDABLE_LOAD_CHANGE:
            return { ...state, expandableLoads: action.expandableLoads };
        case EDIT_SHIPMENTS_LAST_EDITED_SHIPMENT_ID:
            return { ...state, lastEditedShipmentId: action.shipmentId };

        default:
            return state;
    }
};

export default editShipments;
