import { AgedProductsResponse, ScrapItRequestResponse } from '../../utility/services/orders-service';
import {
    SCRAP_IT_LOADING,
    SCRAP_IT_LOADED,
    SCRAP_IT_LOADING_ERROR,
    SCRAP_IT_UPDATE_VIEW,
    SCRAP_IT_VIEW_ERROR,
    SCRAP_IT_NO_PRODUCTS,
    SCRAP_IT_ORDER_PRODUCTS_UPDATE,
    SCRAP_IT_UPDATE_PALLETS,
    SCRAP_IT_UPDATE_QUANTITY_UNIT,
    SCRAP_IT_RESET_PRODUCT_STATE,
    SCRAP_IT_SUBMIT_ERROR,
    SCRAP_IT_LOAD_AGED_PRODUCTS,
    SCRAP_IT_SUMMARY_ERROR,
    SCRAP_IT_SUMMARY_LOADED,
    SCRAP_IT_SUBMIT_SUCCESS,
    SCRAP_IT_SUBMIT_LOADING
} from '../actions/action-types';
import { OrderProduct } from './orders-dashboard';
import { ProductToShip, ProductWithPallets, QuantityUnit } from './shipping-dashboard';

export interface ScrapItState {
    products?: OrderProduct[];
    productsWithPallets: ProductWithPallets[];
    productsToBeScrapped: ProductToShip[];
    view?: string[];
    loading: boolean;
    loaded: boolean;
    error?: any;
    availablePallets?: number;
    selectedDeliveryDate?: any;
    quantityUnit: QuantityUnit;
    agedProducts: AgedProductsResponse[];
    scrapItRequests: ScrapItRequest[];
    submitScrapItState: 'idle' | 'loading' | 'error' | 'success';
    scrapItConfirmation?: ScrapItRequestResponse;
}

export interface ScrapItSubmissionProduct {
    productSku: string,
    requestedQuantity: number,
    quantityUnits: string,
    status: string,
    approvedAmount: number
}

export interface ScrapItRequest {
    requestOrderNumber: string,
    requestedQuantity: string,
    submittedDate: string,
    status: string,
    quantityEaches: string
}

const initialState: ScrapItState = {
    products: [],
    productsToBeScrapped: [],
    productsWithPallets: [],
    loading: false,
    loaded: false,
    view: [''],
    quantityUnit: 'pallets',
    agedProducts: [],
    scrapItRequests: [],
    submitScrapItState: 'idle',
};

const scrapItState = (state = initialState, action: any): ScrapItState => {
    switch (action.type) {
        case SCRAP_IT_LOADED:
            return {
                ...state,
                loaded: true,
                loading: false,
                products: action.products,
                productsWithPallets: action.products
            };
        case SCRAP_IT_LOADING:
            return { ...state, loaded: false, loading: true, error: undefined };
        case SCRAP_IT_LOADING_ERROR:
            return { ...state, loaded: true, loading: false, error: action.error };
        case SCRAP_IT_UPDATE_VIEW:
            return { ...state, loaded: true, loading: false, view: action.view };
        case SCRAP_IT_VIEW_ERROR:
            return { ...state, loading: false, loaded: false, error: action.error };
        case SCRAP_IT_NO_PRODUCTS:
            return {
                ...state,
                loaded: true,
                loading: false,
                products: action.products,
                productsWithPallets: action.products
            };
        case SCRAP_IT_UPDATE_PALLETS:
            return {
                ...state,
                loaded: true,
                loading: false,
                productsWithPallets: action.productsWithPallets,
            };
        case SCRAP_IT_ORDER_PRODUCTS_UPDATE:
            return { ...state, loaded: false, productsToBeScrapped: action.productsToBeShipped };
        case SCRAP_IT_RESET_PRODUCT_STATE:
            return {
                ...state,
                products: action.products,
                productsToBeScrapped: action.productsToBeScrapped,
                scrapItRequests: [],
                submitScrapItState: 'idle'
            };
        case SCRAP_IT_UPDATE_QUANTITY_UNIT:
            return {
                ...state,
                quantityUnit: action.quantityUnit,
                productsWithPallets: action.productsWithPallets
            };
        case SCRAP_IT_SUBMIT_LOADING:
            return {
                ...state,
                submitScrapItState: 'loading'
            }
        case SCRAP_IT_SUBMIT_ERROR:
            return {
                ...state,
                submitScrapItState: 'error',
                error: action.error
            };
        case SCRAP_IT_LOAD_AGED_PRODUCTS:
            return {
                ...state,
                loaded: true,
                agedProducts: action.agedProducts
            };
        case SCRAP_IT_SUBMIT_SUCCESS:
            return {
                ...state,
                submitScrapItState: 'success',
                scrapItConfirmation: action.scrapItConfirmation
            };
        case SCRAP_IT_SUMMARY_LOADED:
            return {
                ...state,
                scrapItRequests: action.scrapItRequests,
                loaded: true
            };
        case SCRAP_IT_SUMMARY_ERROR:
            return {
                ...state,
                error: action.error
            };
        default:
            return state;
    }
};

export default scrapItState;
