import React from 'react';
import PdfPageTemplate from '../../../templates/PdfPageTemplate';
import { Document, Page, Text, Image, View, BlobProvider } from '@react-pdf/renderer';
import pdfStyles from '../../../../themes/pdfStyles';
import {
    GraphicIntakeRequestType,
    Graphics,
    Region
} from '../../../../store/reducers/graphic-intake';
import { isIE } from '../../../../utility/helpers/user-helpers';
import { useTranslation, Trans } from 'react-i18next';
import { answerFormatter } from '../../../../utility/helpers/graphic-helpers';
import moment from 'moment';
import { useTypedSelector } from '../../../../store/reducers/reducer';

interface Props {
    title: string;
    graphics: Graphics;
    ballLogo: string;
    sourceLogo: string;
}

const GraphicsIntakeFormPDF = ({ title, graphics, ballLogo, sourceLogo }: Props) => {
    useTranslation();
    const { regionCode } = useTypedSelector((state) => state.regionCulture);

    const getConversiontype = () => {
        switch (graphics.conversionType?.toLowerCase()) {
            case 'hard':
                return <Trans i18nKey="hard">Hard</Trans>;
            case 'soft':
                return <Trans i18nKey="soft">Soft</Trans>;
            default:
                return null;
        }
    };

    const formatDate = (date?: Date) => {
        if (date) {
            return moment(date).format('MM/DD/YYYY');
        } else {
            return '';
        }
    };

    const approverInformation = graphics.approvers?.map((approver) => {
        return (
            <>
                <View style={pdfStyles.tableRow}>
                    <View style={pdfStyles.borderLessTableColWide3}>
                        <Text style={pdfStyles.subtitle3}>{approver.approverName}</Text>
                    </View>
                    <View style={pdfStyles.borderLessTableColWide3}>
                        <Text style={pdfStyles.subtitle3}>{approver.approverEmail}</Text>
                    </View>
                    <View style={pdfStyles.borderLessTableColWide3}>
                        <Text style={pdfStyles.subtitle3}>{approver.approverAddress}</Text>
                    </View>
                    <View style={pdfStyles.borderLessTableColNarrow3}>
                        <Text style={pdfStyles.subtitle3}>{approver.approverAddressCountry}</Text>
                    </View>
                </View>
                <View style={pdfStyles.divider7} />
            </>
        );
    });

    const productOptions = () => {
        return (
            <>
                <View style={pdfStyles.borderlessTable3}>
                    <View style={pdfStyles.tableRow}>
                        <View style={[pdfStyles.borderLessTableCol1]}>
                            <Text style={pdfStyles.header6}>
                                <Trans i18nKey="productOptions">PRODUCT OPTIONS</Trans>
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                        <View style={pdfStyles.borderLessTableColNarrow4}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="customerGraphicsID">CUSTOMER GRAPHIC ID</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow10}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="customerSKUNo">CUSTOMER SKU #</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow10}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="upc">UPC #/EAN</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColWide}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="productSize">PRODUCT SIZE</Trans>
                            </Text>
                        </View>
                        {regionCode === Region.NA && (
                            <View style={pdfStyles.borderLessTableColNarrow4}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="beverageCategory">BEVERAGE CATEGORY</Trans>
                                </Text>
                            </View>
                        )}
                    </View>
                    <View style={pdfStyles.divider7} />
                    <View style={pdfStyles.tableRow}>
                        <View style={pdfStyles.borderLessTableColNarrow4}>
                            <Text style={pdfStyles.subtitle7}>
                                {graphics.customerGraphicsID !== ''
                                    ? graphics.customerGraphicsID
                                    : 'N/A'}
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow10}>
                            <Text style={pdfStyles.subtitle7}>
                                {graphics.customerSKU !== '' ? graphics.customerSKU : 'N/A'}
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow10}>
                            <Text style={pdfStyles.subtitle7}>
                                {graphics.upc !== '' ? graphics.upc : 'N/A'}
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColWide}>
                            <Text style={pdfStyles.subtitle7}>{graphics.productCode}</Text>
                        </View>
                        {regionCode === Region.NA && (
                            <View style={pdfStyles.borderLessTableColNarrow4}>
                                <Text style={pdfStyles.subtitle7}>{graphics.beverageType}</Text>
                            </View>
                        )}
                    </View>
                    <View style={pdfStyles.divider7} />
                </View>
            </>
        );
    };

    const canFinishes = () => {
        return (
            <>
                <View style={pdfStyles.borderlessTable4}>
                    <View style={pdfStyles.tableRow}>
                        <View style={[pdfStyles.borderLessTableCol1]}>
                            <Text style={pdfStyles.header6}>
                                <Trans i18nKey="canFinishes">CAN FINISHES</Trans>
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                        <View style={pdfStyles.borderLessTableColNarrow6}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="ink">INK</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow3}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="effect">EFFECT</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow4}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="overvarnish">OVERVARNISH</Trans>
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.divider8} />
                    <View style={pdfStyles.tableRow}>
                        <View style={pdfStyles.borderLessTableColNarrow6}>
                            <Text style={pdfStyles.subtitle7}>
                                {graphics.ink ? graphics.ink : 'N/A'}
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow3}>
                            <Text style={pdfStyles.subtitle7}>
                                {graphics.effect ? graphics.effect : 'N/A'}
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow4}>
                            <Text style={pdfStyles.subtitle7}>
                                {graphics.overvarnish ? graphics.overvarnish : 'N/A'}
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.divider7} />
                </View>
            </>
        );
    };

    const colorMatching = () => {
        return (
            <>
                <View style={pdfStyles.borderlessTable3}>
                    <View style={pdfStyles.tableRow}>
                        <View style={[pdfStyles.borderLessTableCol1]}>
                            <Text style={pdfStyles.header6}>
                                <Trans i18nKey="colorMatching">COLOR MATCHING</Trans>
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                        <View style={pdfStyles.borderLessTableColNarrow6}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="comments">COMMENTS</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow6}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="dateCodeMatching">DATE CODE MATCHING</Trans>
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.divider7} />
                    <View style={pdfStyles.tableRow}>
                        <View style={pdfStyles.borderLessTableColNarrow6}>
                            <Text style={pdfStyles.subtitle7}>{graphics.colorComment}</Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow6}>
                            <Text style={pdfStyles.subtitle7}>
                                {graphics.dateCode !== '' ? graphics.dateCode : 'N/A'}
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.divider7} />
                </View>
            </>
        );
    };

    const myDoc = (
        <Document title={title}>
            <Page size="A4" style={pdfStyles.page} orientation={'portrait'}>
                <View style={pdfStyles.logoSection}>
                    <Image src={sourceLogo} style={pdfStyles.sourceLogo} />
                    <Image src={ballLogo} style={pdfStyles.ballLogo} />
                </View>
                <View style={pdfStyles.divider6} fixed />

                <View style={[pdfStyles.flexSection, pdfStyles.pageHeader]}>
                    <View>
                        <Text style={pdfStyles.header5}>
                            <Trans i18nKey="graphics">GRAPHICS</Trans>
                        </Text>
                    </View>
                    <View>
                        <View>
                            <Text style={pdfStyles.fieldLabel}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="companyName">Company Name</Trans>
                                    {' / '}
                                    <Trans i18nKey="companyId">Company ID</Trans>
                                </Text>
                            </Text>
                        </View>
                        <View>
                            <Text style={pdfStyles.fieldText}>
                                {graphics.customerName}
                                {' / '}
                                {graphics.customerAccount}
                            </Text>
                        </View>
                    </View>
                </View>

                <View style={pdfStyles.divider6} fixed />
                <View style={pdfStyles.borderlessTable}>
                    <View style={pdfStyles.tableRow}>
                        <View style={[pdfStyles.borderLessTableCol]}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="submissionName">Submission Name</Trans>
                            </Text>
                        </View>

                        <View style={[pdfStyles.borderLessTableCol]}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="submissionType">Submission Type</Trans>
                            </Text>
                        </View>
                        <View style={[pdfStyles.borderLessTableColNarrow3]}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="requestedByDate">Requested Date</Trans>
                            </Text>
                        </View>
                        <View style={[pdfStyles.borderLessTableCol]}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="shortTerm">Is this a promotion or does this graphic require an in or out of market date?</Trans>
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                        <View style={[pdfStyles.borderLessTableCol]}>
                            <Text style={pdfStyles.subtitle8}>{graphics.requestName}</Text>
                        </View>

                        <View style={[pdfStyles.borderLessTableCol]}>
                            <Text style={pdfStyles.subtitle8}>{graphics.requestType}</Text>
                        </View>
                        <View style={[pdfStyles.borderLessTableColNarrow3]}>
                            <Text style={pdfStyles.subtitle8}>
                                {formatDate(graphics.requestedByDate)}
                            </Text>
                        </View>
                        <View style={[pdfStyles.borderLessTableCol]}>
                            <Text style={pdfStyles.subtitle8}>
                                <Trans i18nKey={answerFormatter(graphics.shortTerm)}>
                                    {graphics.shortTerm || ''}
                                </Trans>
                            </Text>
                        </View>
                    </View>
                </View>
                {(answerFormatter(graphics.shortTerm) === 'yes' ||
                    graphics.requestType === GraphicIntakeRequestType.Revision) && (
                    <View style={pdfStyles.borderlessTable5}>
                        <View style={pdfStyles.tableRow}>
                            {answerFormatter(graphics.shortTerm) === 'yes' && (
                                <>
                                    <View style={[pdfStyles.borderLessTableCol]}>
                                        <Text style={pdfStyles.header2}>
                                            <Trans i18nKey="inRetailDate">In-Retail Date</Trans>
                                        </Text>
                                    </View>

                                    <View style={[pdfStyles.borderLessTableCol]}>
                                        <Text style={pdfStyles.header2}>
                                            <Trans i18nKey="outOfRetailDate">
                                                Out-of-Retail Date
                                            </Trans>
                                        </Text>
                                    </View>
                                </>
                            )}
                            {graphics.requestType === GraphicIntakeRequestType.Revision && (
                                <>
                                    <View style={[pdfStyles.borderLessTableColNarrow3]}>
                                        <Text style={pdfStyles.header2}>
                                            <Trans i18nKey="conversion">
                                                Is this a Hard or Soft conversion?
                                            </Trans>
                                        </Text>
                                    </View>

                                    <View style={[pdfStyles.borderLessTableCol]}>
                                        <Text style={pdfStyles.header2}>
                                            <Trans i18nKey="graphicYouAreUpdating">
                                                Graphic You Are Updating
                                            </Trans>
                                        </Text>
                                    </View>
                                </>
                            )}
                        </View>
                        <View style={pdfStyles.tableRow}>
                            {answerFormatter(graphics.shortTerm) === 'yes' && (
                                <>
                                    <View style={[pdfStyles.borderLessTableCol]}>
                                        <Text style={pdfStyles.subtitle8}>
                                            {formatDate(graphics.shortTermStartDate)}
                                        </Text>
                                    </View>

                                    <View style={[pdfStyles.borderLessTableCol]}>
                                        <Text style={pdfStyles.subtitle8}>
                                            {formatDate(graphics.shortTermEndDate)}
                                        </Text>
                                    </View>
                                </>
                            )}
                            {graphics.requestType === GraphicIntakeRequestType.Revision && (
                                <>
                                    <View style={[pdfStyles.borderLessTableColNarrow3]}>
                                        <Text style={pdfStyles.subtitle8}>
                                            {getConversiontype()}
                                        </Text>
                                    </View>

                                    <View style={[pdfStyles.borderLessTableCol]}>
                                        <Text style={pdfStyles.subtitle8}>
                                            {graphics.revisionGraphicName}
                                        </Text>
                                    </View>
                                </>
                            )}
                        </View>
                    </View>
                )}
                <View style={pdfStyles.borderlessTable}>
                    <View style={pdfStyles.tableRow}>
                        <View style={[pdfStyles.borderLessTableColWide4]}>
                            <Text style={pdfStyles.header6}>
                                <Trans i18nKey="graphicsContact">Graphics Contact</Trans>
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                        <View style={pdfStyles.borderLessTableColWide3}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="name">Name</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColWide3}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="email">Email</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColWide3}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="phone">Phone</Trans>
                                {' #'}
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow3}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="billTo">Bill To</Trans>
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.divider7} />
                    <View style={pdfStyles.tableRow}>
                        <View style={pdfStyles.borderLessTableColWide3}>
                            <Text style={pdfStyles.subtitle3}>{graphics.fullName}</Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColWide3}>
                            <Text style={pdfStyles.subtitle3}>{graphics.submitter}</Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColWide3}>
                            <Text style={pdfStyles.subtitle3}>{graphics.phoneNumber}</Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow3}>
                            <Text style={pdfStyles.subtitle3}>{graphics.billToAddress}</Text>
                        </View>
                    </View>
                    <View style={pdfStyles.divider7} />

                    <View style={pdfStyles.borderlessTable}>
                        <View style={pdfStyles.tableRow}>
                            <View style={[pdfStyles.borderLessTableColWide4]}>
                                <Text style={pdfStyles.header6}>
                                    <Trans i18nKey="colorStandardsApprover">
                                        COLOR STANDARDS APPROVER
                                    </Trans>
                                </Text>
                            </View>
                        </View>
                        <View style={pdfStyles.tableRow}>
                            <View style={pdfStyles.borderLessTableColWide3}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="name">Name</Trans>
                                </Text>
                            </View>
                            <View style={pdfStyles.borderLessTableColWide3}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="email">Email</Trans>
                                </Text>
                            </View>
                            <View style={pdfStyles.borderLessTableColWide3}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="approverAddress">Approver's Address</Trans>
                                </Text>
                            </View>
                            <View style={pdfStyles.borderLessTableColNarrow3}>
                                <Text style={pdfStyles.header2}>
                                    <Trans i18nKey="approverCounty">Approver's Country</Trans>
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View style={pdfStyles.divider7} />
                    {approverInformation}
                </View>
                <View style={pdfStyles.flexSection1}>
                    {productOptions()}
                    {canFinishes()}
                </View>
                {colorMatching()}
            </Page>
        </Document>
    );

    if (!isIE()) {
        return <PdfPageTemplate title={title}>{myDoc}</PdfPageTemplate>;
    } else {
        return (
            <BlobProvider document={myDoc}>
                {({ blob, url, loading, error }) => {
                    if (blob && window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob);
                    }
                    return null;
                }}
            </BlobProvider>
        );
    }
};

export default GraphicsIntakeFormPDF;
