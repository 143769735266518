import { isProductEndType } from '../../utility/helpers/order-helpers';
import OrdersService from '../../utility/services/orders-service';
import {
    SCRAP_IT_REQUEST_DETAILS_LOADING,
    SCRAP_IT_REQUEST_DETAILS_LOADED,
    SCRAP_IT_REQUEST_DETAILS_ERROR
} from './action-types';

export const getScrapItRequest = (scrapRequestId: string) => {
    return (dispatch, getState) => {
        const state = getState();
        const accessToken = state.auth.accessToken;

        dispatch({ type: SCRAP_IT_REQUEST_DETAILS_LOADING });

        OrdersService.getScrapItRequest(accessToken, scrapRequestId)
            .then((response) => {
                const scrapItRequest = response.data;
                if (scrapItRequest && scrapItRequest.shipToId) {
                    OrdersService.getDeliveryOrderProducts(accessToken, [scrapItRequest.shipToId])
                        .then((response) => {
                            const products = response.data.products;

                            let canPallets = 0;
                            let canSkus = 0;
                            let canEaches = 0;
                            let endPallets = 0;
                            let endSkus = 0;
                            let endEaches = 0;
                            scrapItRequest.scrapLines.forEach((line) => {
                                const product = products.find(
                                    (p) => p.productSku === line.productSku
                                );
                                if (product) {
                                    const isEndType = isProductEndType(product.type);
                                    const destination = product.destinations.find(
                                        (d) => d.shipToId === scrapItRequest.shipToId
                                    );
                                    line.productDescription = destination?.customerProductName
                                        ? destination?.customerProductName
                                        : product.name;
                                    line.customerProductId = destination?.customerProductId
                                        ? destination?.customerProductId
                                        : '-';
                                    line.graphicIdAndVersion =
                                        product.graphicId && product.graphicVersion
                                            ? product.graphicId + '-' + product.graphicVersion
                                            : '';
                                    line.eachesQuantity = product.quantityPerPallet
                                        ? line.quantity * product.quantityPerPallet
                                        : undefined;
                                    if (isEndType) {
                                        endSkus += 1;
                                        endPallets += line.quantity;
                                        endEaches += line.eachesQuantity ?? 0;
                                    } else {
                                        canSkus += 1;
                                        canPallets += line.quantity;
                                        canEaches += line.eachesQuantity ?? 0;
                                    }
                                }
                            });
                            scrapItRequest.canPallets = canPallets;
                            scrapItRequest.canSkus = canSkus;
                            scrapItRequest.canEaches = canEaches;
                            scrapItRequest.endPallets = endPallets;
                            scrapItRequest.endSkus = endSkus;
                            scrapItRequest.endEaches = endEaches;

                            dispatch({ type: SCRAP_IT_REQUEST_DETAILS_LOADED, scrapItRequest });
                        })
                        .catch((error) => {
                            dispatch({ type: SCRAP_IT_REQUEST_DETAILS_ERROR, error });
                        });
                }
            })
            .catch((error) => {
                dispatch({ type: SCRAP_IT_REQUEST_DETAILS_ERROR, error });
            });
    };
};
