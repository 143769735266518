import React, { useState, ReactElement } from 'react';
import {
    ClickAwayListener,
    Tooltip as MaterialTooltip,
    IconButton,
    makeStyles,
    withStyles
} from '@material-ui/core';
import { ballGreen, tooltipBackground } from '../../../themes/globalConstants';

interface Props {
    content: ReactElement;
    ariaLabel: string;
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
        padding: '0',
        marginTop: '-0.250em',
        marginLeft: '0.125em',
        pointerEvents: 'all'
    },
    icon: {
        color: ballGreen,
        height: '0.6em',
        width: '0.6em',
        marginLeft: '0.2em'
    }
}));

const StyledTooltip = withStyles(() => ({
    arrow: {
        color: tooltipBackground
    },
    tooltip: {
        backgroundColor: tooltipBackground,
        textTransform: 'none'
    }
}))(MaterialTooltip);

const Tooltip = (props: Props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const infoIcon = (
        <img
            src={process.env.PUBLIC_URL + '/assets/Info_Icon.svg'}
            alt="Info Icon"
            className={classes.icon}
        />
    );

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = (event: any) => {
        event.stopPropagation();
        setOpen(true);
    };

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <StyledTooltip
                PopperProps={{
                    disablePortal: true
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={props.content}
                aria-label={props.ariaLabel}
                className={classes.wrapper}
                placement="right"
                arrow
            >
                <IconButton
                    onFocus={(event) => event.stopPropagation()}
                    onClick={handleTooltipOpen}
                >
                    {infoIcon}
                </IconButton>
            </StyledTooltip>
        </ClickAwayListener>
    );
};

export default Tooltip;
