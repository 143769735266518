import { AllocationRequest, SnoSkuAllocationsByWeek } from '../../store/reducers/makeit-bulk-atm';
import Axios from 'axios';
import { createAPIQueryParam } from '../helpers/query-helpers';

export default class AllocationService {
    private static createConfig = (accessToken: string, regionCode: string) => ({
        headers: { Authorization: `Bearer ${accessToken}`, 'Accept-Region': `${regionCode}` }
    });

    static getAllocations = (state: any, regionCode: string, requestObject: AllocationRequest) => {
        const baseUrl = `${process.env.REACT_APP_ALLOCATION_API}/allocations`;
        const urlContent = createAPIQueryParam(requestObject);
        const requestUrl = baseUrl + urlContent;

        return Axios.get<{ allocations: SnoSkuAllocationsByWeek[] }>(
            requestUrl,
            AllocationService.createConfig(state.auth.accessToken, regionCode)
        );
    };
}
