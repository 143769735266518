import React from 'react';
import { makeStyles } from '@material-ui/core';
import { red, green } from '@material-ui/core/colors';

interface HelperProps {
    invalid: boolean;
    helpText: React.ReactNode;
    testId?: string;
}

const useStyles = makeStyles((theme) => ({
    success: { color: green[400] },
    error: { color: red[400] }
}));

export default function PasswordHelperText({ helpText, invalid, testId }: HelperProps) {
    const classes = useStyles();

    return <li 
            className={invalid ? classes.error : classes.success}
            data-testid={testId}
        >{helpText}</li>;
}
