import React, { useState } from 'react';
import { ContentSection } from '../../../../store/reducers/content-page';
import { Grid, TextField, makeStyles, InputAdornment } from '@material-ui/core';
import SectionFilter from '../components/SectionFilter';
import FaqSection from '../components/FaqSection';
import SearchIcon from '@material-ui/icons/Search';
import TranslationService from '../../../../utility/services/translation-service';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { useTypedSelector } from '../../../../store/reducers/reducer';

interface Props {
    sections: ContentSection[];
}

const useStyles = makeStyles({
    search: {
        marginTop: '2em',
        marginBottom: '2em'
    }
});

export default function FaqPageTemplate({ sections }: Props) {
    const [filterText, setFilterText] = useState('');
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((store) => store.regionCulture);

    return (
        <Grid item container data-testid="faq-page-template">
            <Grid container item xs={12} className={classes.search}>
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                    <TextField
                        label={TranslationService.getTranslatedText(cultureCode, 'searchFAQs')}
                        fullWidth
                        onChange={(control) => setFilterText(control.target.value)}
                        placeholder={TranslationService.getTranslatedText(
                            cultureCode,
                            'searchFAQplaceholder'
                        )}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon color="primary" />
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
            </Grid>
            {sections.map((section) => (
                <SectionFilter
                    section={section}
                    filterText={filterText}
                    render={(filteredSection, words) => (
                        <FaqSection section={filteredSection} searchWords={words} />
                    )}
                />
            ))}
        </Grid>
    );
}
