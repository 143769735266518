import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography, TableRow, TableCell, Grid } from '@material-ui/core';
import { small, regularWeight, lightBlue, boldWeight } from '../../../../themes/globalConstants';
import { formatUTCToDateDisplay } from '../../../../utility/helpers/date-helpers';
import { MakeItBulkLineItem } from '../../../../store/reducers/makeit-bulk-upload';
import { formatNumberWithLocale } from '../../../../utility/helpers/formatters';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';

interface Props {
    item: MakeItBulkLineItem;
}

const useStyles = makeStyles((theme) => ({
    tableRowContainer: {
        '& .helper-text': {
            fontWeight: `${regularWeight} !important`,
            fontSize: small
        },
        '& .validation-error-text': {
            fontWeight: `${boldWeight} !important`,
            fontSize: small
        },
        '& .MuiInputBase-input': {
            textAlign: 'right',
            paddingRight: '.25em'
        }
    },
    alignCenter: {
        textAlign: 'center'
    },
    blueBackground: {
        backgroundColor: lightBlue
    }
}));

const MakeItBulkUploadConfirmationRow = ({ item }: Props) => {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    useTranslation();

    return (
        <>
            <TableRow
                className={classes.tableRowContainer}
                data-testid={`product-${item.displayId}`}
            >
                <TableCell data-testid="product-graphic-id">
                    <Typography>{item.displayId}</Typography>
                    <Typography>{item.graphicIdAndVersion}</Typography>
                </TableCell>
                <TableCell data-testid="product-description">
                    <Typography>{item.displayName}</Typography>
                    <Typography>{item.description}</Typography>
                </TableCell>
                <TableCell data-testid="coating">
                    <Typography>{item.productCoating}</Typography>
                </TableCell>
                <TableCell data-testid="requested-date">
                    <Grid container>
                        <Grid container item alignItems="flex-start">
                            <Grid item xs={12}>
                                {formatUTCToDateDisplay(item.requestedDate)}
                            </Grid>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell data-testid="pallet-quantity">
                    <Grid container>
                        <Grid item xs={12}>
                            {item.palletQuantity} PL
                            <Typography>
                                {formatNumberWithLocale(
                                    cultureCode,
                                    item.palletQuantity && item.quantityPerPallet
                                        ? item.quantityPerPallet * item.palletQuantity
                                        : 0
                                )}{' '}
                                ea.
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
            </TableRow>
        </>
    );
};

export default MakeItBulkUploadConfirmationRow;
