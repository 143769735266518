import {
    ATM_PRODUCTION_ORDER_LOADING,
    ATM_PRODUCTION_ORDER_LOADED,
    ATM_PRODUCTION_ORDER_LOADING_ERROR,
    ATM_PRODUCTION_ORDER_RESET_STATE,
    ATM_PRODUCTION_ORDER_DELIVERY_ORDER_LOADING,
    ATM_PRODUCTION_ORDER_DELIVERY_ORDER_LOADED,
    ATM_PRODUCTION_ORDER_DELIVERY_ORDER_LOADING_ERROR
} from './action-types';
import OrdersService, { ProductStatus } from '../../utility/services/orders-service';
import { MakeItBulkLineItem } from '../reducers/makeit-bulk-upload';
import { MakeItBulkATMOrder, MakeItBulkATMLineItem } from '../reducers/makeit-bulk-atm';

export const resetATMProductionOrderState = () => {
    return (dispatch) => {
        dispatch({
            type: ATM_PRODUCTION_ORDER_RESET_STATE,
            atmProductionOrder: null
        });
    };
};

export const loadATMProductionOrder = (atmProductionOrderId: number) => {
    return (dispatch, getState) => {
        const state = getState();
        const products = state.bulkUploadMakeItState.products as MakeItBulkLineItem[];

        dispatch({ type: ATM_PRODUCTION_ORDER_LOADING });

        OrdersService.getATMProductionOrder(getState(), atmProductionOrderId)
            .then((atmProductionOrder) => {
                const order = atmProductionOrder.data;
                if (order && (order.atmWeekStart || order.lines?.length)) {
                    order.lines?.forEach((line) => {
                        line.displayId = line.customerProductId
                            ? line.customerProductId
                            : line.productSku;
                        line.displayName = line.customerProductName
                            ? line.customerProductName
                            : line.productName;
                        line.productStatus = line.productStatus
                            ? line.productStatus?.toUpperCase()
                            : ProductStatus.Inactive;
                    });
                    // Forcing these only because of above check.
                    const weekStart: string = order.atmWeekStart
                        ? order.atmWeekStart
                        : order.lines![0].weekStart!;
                    const atmOrder = { ...order, weekStart: weekStart } as MakeItBulkATMOrder;

                    bulkUploadResponseHelper(atmOrder, products);
                    dispatch({
                        type: ATM_PRODUCTION_ORDER_LOADED,
                        atmProductionOrder: atmOrder
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: ATM_PRODUCTION_ORDER_LOADING_ERROR,
                    error
                });
            });
    };
};

export const loadATMLinkedDeliveryOrders = (productionOrderId: number) => {
    return (dispatch, getState) => {
        dispatch({ type: ATM_PRODUCTION_ORDER_DELIVERY_ORDER_LOADING });

        OrdersService.getProductionOrderLinkedDeliveryOrders(getState(), productionOrderId)
            .then((deliveryOrder) => {
                const deliveryOrderData = deliveryOrder.data;
                dispatch({
                    type: ATM_PRODUCTION_ORDER_DELIVERY_ORDER_LOADED,
                    linkedDeliveryOrder: deliveryOrderData
                });
            })
            .catch((error) => {
                dispatch({
                    type: ATM_PRODUCTION_ORDER_DELIVERY_ORDER_LOADING_ERROR,
                    error
                });
            });
    };
};

export const bulkUploadResponseHelper = (order: MakeItBulkATMOrder, products: any) => {
    order.lines.forEach((line: MakeItBulkATMLineItem, index) => {
        if (line.userSuppliedProductId || line.productSku) {
            let idToMatch = line.userSuppliedProductId
                ? line.userSuppliedProductId
                : line.productSku;
            let matchingProduct = products.find(
                (product) =>
                    product.productSku === idToMatch || product.customerProductId === idToMatch
            );
            if (matchingProduct) {
                order.lines[index] = combineProductData(line, matchingProduct);
            }
            order.lines.forEach((line) => {
                line.deleted = false;
                if (line.eachesQuantity && line.palletQuantity && line.quantityPerPallet) {
                    line.palletsRounded =
                        line.eachesQuantity !== line.palletQuantity * line.quantityPerPallet;
                }
            });
        }
    });
};

/**
 * Combines product data with data from the line.
 * @param line
 * @param matchingProduct
 */
function combineProductData(line, matchingProduct) {
    return { ...line, ...matchingProduct, status: line.status };
}
