import React, { useEffect, useState } from 'react';
import {
    Button,
    CircularProgress,
    Container,
    Grid,
    Hidden,
    makeStyles,
    Paper,
    Typography
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { lato, ltBlueGrey_8, red } from '../../../themes/globalConstants';
import { Activity } from '../../../utility/auth/useSecurity';
import { enUS } from '../../../utility/translations/locales';
import HoverNavMenu from '../../reusable/molecules/HoverNavMenu';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import {
    ConfirmationIcon,
    defaultFormState,
    getFormStateFromTaxApp,
    useSalesAndUseTaxSection
} from './utils/sales-and-use-tax-utilities';
import { FormContext, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import CancelModal from '../../reusable/molecules/CancelModal';
import {
    continueEditingSalesAndUseTax,
    cancelSalesAndUseTax,
    saveProgressSalesAndUseTax,
    backAfterSaveSalesAndUseTax,
    submitSalesAndUseTax
} from '../../../store/root-actions';
import { useDispatch } from 'react-redux';
import { OnboardingDashboardState } from '../../../store/reducers/onboarding-dashboard';
import { useTypedSelector } from '../../../store/reducers/reducer';
import SingleActionModal from '../../reusable/molecules/SingleActionModal';
import ErrorAlert from '../../reusable/atoms/ErrorAlert';
import NotFoundPage from '../NotFound/NotFoundPage';
import { redirectToNotFoundPage } from '../../../utility/helpers/onboardHelpers';

const useStyles = makeStyles((theme) => ({
    salesAndUseTax: {
        fontFamily: lato,
        margin: '3em 0'
    },
    paper: {
        boxShadow: `0 3px 6px 0 ${ltBlueGrey_8}`,
        borderRadius: 4,
        padding: '2em',
        marginRight: '2em',
        position: 'sticky',
        top: '5em'
    },
    formSectionContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    formSection: {
        marginBottom: '2.5em'
    },
    actionBtn: {
        height: '3.375em',
        width: '13.75em',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom'
    },
    saveProgressError: {
        color: red
    },
    error: {
        color: theme.palette.error.main,
        marginTop: '0.625em'
    },
    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '0.5em'
    }
}));

export default function SalesAndUseTax() {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [sections, sectionRefs, formSections] = useSalesAndUseTaxSection();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [submitClicked, setSubmitClicked] = useState<boolean>(false);
    const [showSaveProgressBlockedError, setShowSaveProgressBlockedError] =
        useState<boolean>(false);
    const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
    const [openSaveModal, setOpenSaveModal] = useState<boolean>(false);
    const { accountStatus, salesAndUseTax, billingAndShipping } =
        useTypedSelector<OnboardingDashboardState>((state) => state.onboardingDashboardState);

    useEffect(() => {
        switch (salesAndUseTax.saveStatus) {
            case 'success':
                setOpenSaveModal(true);
                break;
            default:
                setOpenSaveModal(false);
        }
    }, [salesAndUseTax.saveStatus]);

    useEffect(() => {
        if (salesAndUseTax.submissionStatus === 'success') {
            setIsSubmitting(false);
            history.push('/onboard');
        } else if (salesAndUseTax.submissionStatus === 'error') {
            setIsSubmitting(false);
        }
    }, [salesAndUseTax.submissionStatus]);

    const onSaveModalClose = () => {
        dispatch(continueEditingSalesAndUseTax());
    };

    const onBackAfterSave = () => {
        dispatch(backAfterSaveSalesAndUseTax());
        history.push('/onboard');
    };

    const methods = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        defaultValues: salesAndUseTax.salesAndUseTaxForm
            ? getFormStateFromTaxApp(salesAndUseTax.salesAndUseTaxForm)
            : defaultFormState
    });
    const { dirty, isValid } = methods.formState;

    const header = (
        <Trans i18nKey="salesAndUseTaxSideHeader">{enUS.salesAndUseTaxSideHeader}</Trans>
    );

    const onSubmit = (taxAppData) => {
        setIsSubmitting(true);
        dispatch(submitSalesAndUseTax(taxAppData));
    };

    const onSaveDraft = () => {
        if (hasPatternErrors()) {
            setShowSaveProgressBlockedError(true);
        } else {
            setShowSaveProgressBlockedError(false);
            dispatch(saveProgressSalesAndUseTax(methods.getValues()));
        }
    };

    const hasPatternErrors = () => {
        let error = false;

        if (
            methods.errors.signatureEmail &&
            methods.errors.signatureEmail.type &&
            methods.errors.signatureEmail.type === 'pattern'
        ) {
            return true;
        }

        if (
            methods.errors.signaturePhoneNumber &&
            methods.errors.signaturePhoneNumber.type &&
            methods.errors.signaturePhoneNumber.type === 'pattern'
        ) {
            return true;
        }

        return error;
    };

    const handleConfirmCancel = () => {
        dispatch(cancelSalesAndUseTax());
        history.push('/onboard');
    };

    const handleCloseCancelModal = () => {
        setOpenCancelModal(false);
    };

    const handleOpenCancelModal = () => {
        setOpenCancelModal(true);
    };

    const handleSubmitCapture = () => {
        setSubmitClicked(true);
        methods.triggerValidation();
    };

    const footerActions = (
        <>
            <Grid container item xs={2}>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="cancel-button"
                    onClick={handleOpenCancelModal}
                    className={classes.actionBtn}
                >
                    <Trans i18nKey="cancel">Cancel</Trans>
                </Button>
            </Grid>
            <Grid container item xs={2}>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="draft-button"
                    onClick={onSaveDraft}
                    className={classes.actionBtn}
                    disabled={isSubmitting || !dirty}
                >
                    <Trans i18nKey="saveProgress">Save Progress</Trans>
                </Button>
            </Grid>
            <Grid container item xs={6} alignItems="center" justify="center">
                <ErrorAlert
                    errorMessage={
                        <Trans i18nKey="fillOutRequiredFields">
                            All required fields must be completed prior to submitting.
                        </Trans>
                    }
                    showError={!isValid && submitClicked}
                    dataTestId="validation-error"
                ></ErrorAlert>
            </Grid>
            <Grid container item xs={2}>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    data-testid="submit-button"
                    className={classes.actionBtn}
                    disabled={isSubmitting}
                    onClickCapture={handleSubmitCapture}
                    onClick={methods.handleSubmit(onSubmit)}
                >
                    <Trans i18nKey="submit">Submit</Trans>
                </Button>
            </Grid>
        </>
    );

    if (redirectToNotFoundPage(accountStatus, billingAndShipping)) {
        return <NotFoundPage />;
    }

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('salesAndUseTaxBanner', enUS.salesAndUseTaxBanner),
                description: '',
                thinBanner: true,
                displayDropdown: false
            }}
            actionFooter={{
                footerAction: footerActions,
                justify: 'space-between',
                sticky: true
            }}
            activity={Activity.SalesAndUseTax}
        >
            <Grid className={classes.salesAndUseTax} container data-testid="sales-and-use-tax-page">
                <Grid item sm={4}>
                    <Hidden xsDown>
                        <Paper className={classes.paper}>
                            <HoverNavMenu
                                translatedHeader={header}
                                readOnly={false}
                                sections={sections
                                    .map((section, index) => ({
                                        ref: sectionRefs[index],
                                        header: section.header,
                                        icon: section.icon,
                                        iconSrc: <ConfirmationIcon />
                                    }))
                                    .filter((section) => section.header)}
                            />
                        </Paper>
                    </Hidden>
                </Grid>
                <Grid container item xs={8} className={classes.formSectionContainer}>
                    <FormContext {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
                            {formSections.map((form, index) => {
                                return (
                                    <section ref={sectionRefs[index]} key={index}>
                                        <Grid item xs={12} className={classes.formSection}>
                                            {form.section}
                                        </Grid>
                                    </section>
                                );
                            })}
                        </form>
                        {showSaveProgressBlockedError && (
                            <Typography variant="body1" className={classes.saveProgressError}>
                                <Trans i18nKey="saveProgressText">{enUS.saveProgressText}</Trans>
                            </Typography>
                        )}
                        <Container>
                            {isSubmitting && (
                                <Grid container item xs={12} className={classes.spinningLoader}>
                                    <CircularProgress />
                                </Grid>
                            )}
                        </Container>
                    </FormContext>
                </Grid>
            </Grid>
            <CancelModal
                onConfirmCancel={handleConfirmCancel}
                onClose={handleCloseCancelModal}
                open={openCancelModal}
            />
            <SingleActionModal
                title={<Trans i18nKey="saveProgress">{enUS.saveProgress}</Trans>}
                open={openSaveModal}
                subheader={
                    <Trans i18nKey="saveProgressSecondaryText">
                        {enUS.saveProgressSecondaryText}
                    </Trans>
                }
                actionButtonText={
                    <Trans i18nKey="returnToDashboard">{enUS.returnToDashboard}</Trans>
                }
                onAction={onBackAfterSave}
                onClose={onSaveModalClose}
            />
        </ProcessingPageTemplate>
    );
}
