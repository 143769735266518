import React, { useState, useEffect } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import { ballGray } from '../../../../themes/globalConstants';
import { medium } from '../../../../themes/globalConstants';
import { getFormattedDateStringForApplication } from '../../../../utility/helpers/date-helpers';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { formatNumberWithLocale } from '../../../../utility/helpers/formatters';
import clsx from 'clsx';
import { isProductCanType, isProductEndType } from '../../../../utility/helpers/order-helpers';
import { ScrapItRequestResponse } from '../../../../utility/services/orders-service';

interface Props {
    shipToAddress: string;
    shipToName: string;
    shipToId: string;
    confirmation: ScrapItRequestResponse;
}

const useStyles = makeStyles((theme) => ({
    main: {
        marginBottom: '1.5em'
    },
    subtitle: {
        color: ballGray
    },
    fontSizeMedium: {
        fontSize: medium
    },
    item: {
        paddingLeft: '1em'
    },
    createdBy: {
        marginTop: '1em',
        marginBottom: '.5em'
    },
    longValue: {
        overflowWrap: 'anywhere'
    }
}));

type DisplayTotals = {
    pallets: number;
    eaches: number;
};

const ScrapItConfirmationSubheader = ({
    shipToAddress,
    shipToId,
    shipToName,
    confirmation
}: Props) => {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [cansTotal, setCansTotal] = useState<DisplayTotals[]>([]);
    const [endsTotal, setEndsTotal] = useState<DisplayTotals[]>([]);

    useEffect(() => {
        let cansTotal: DisplayTotals[] = [];
        let endsTotal: DisplayTotals[] = [];
        confirmation.requestLines.map((product) => {
            if (isProductCanType(product.type)) {
                cansTotal.push({
                    pallets: product.inputPallets || 0,
                    eaches: product.inputEaches || 0
                });
            }
            if (isProductEndType(product.type)) {
                endsTotal.push({
                    pallets: product.inputPallets || 0,
                    eaches: product.inputEaches || 0
                });
            }
            return null;
        });
        setCansTotal(cansTotal);
        setEndsTotal(endsTotal);
    }, [confirmation]);

    useTranslation();

    return (
        <Grid container>
            <Grid container item xs={4} justify="flex-start" data-testid="shipto">
                <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="shipTo">Ship To</Trans>
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        variant="subtitle2"
                        className={clsx(classes.subtitle, classes.fontSizeMedium)}
                    >
                        {shipToName}
                    </Typography>
                    <Typography>{shipToAddress}</Typography>
                    <Typography>{`#${shipToId}`}</Typography>
                </Grid>
            </Grid>
            <Grid container item xs={8} justify="flex-end" data-testid="date-submitted">
                <Grid item xs={2} className={classes.item}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="dateSubmitted">Date Submitted</Trans>
                    </Typography>
                    <Typography variant="body1">
                        {getFormattedDateStringForApplication(confirmation.submittedDate)}
                    </Typography>
                </Grid>
                <Grid item xs={3} className={classes.item} data-testid="can-quantity">
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="canQuantity">Can Quantity</Trans>
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        className={clsx(classes.subtitle, classes.fontSizeMedium)}
                    >
                        {cansTotal.length > 0
                            ? formatNumberWithLocale(
                                  cultureCode,
                                  cansTotal.reduce((prev, curr) => prev + curr.pallets, 0)
                              ) +
                              ' PL / ' +
                              cansTotal.length +
                              ' SKU'
                            : '0 PL / 0 SKU'}
                    </Typography>
                    <Typography
                        variant="body1"
                        data-testid="can-quantity-eaches"
                        className={classes.longValue}
                    >
                        {cansTotal.length > 0
                            ? formatNumberWithLocale(
                                  cultureCode,
                                  cansTotal.reduce((prev, curr) => prev + curr.eaches, 0)
                              ) + ' ea.'
                            : '0 ea.'}
                    </Typography>
                </Grid>
                <Grid item xs={3} className={classes.item} data-testid="end-quantity">
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="endQuantity">End Quantity</Trans>
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        className={clsx(classes.subtitle, classes.fontSizeMedium)}
                    >
                        {endsTotal.length > 0
                            ? formatNumberWithLocale(
                                  cultureCode,
                                  endsTotal.reduce((prev, curr) => prev + curr.pallets, 0)
                              ) +
                              ' PL / ' +
                              endsTotal.length +
                              ' SKU'
                            : '0 PL / 0 SKU'}
                    </Typography>
                    <Typography
                        variant="body1"
                        data-testid="end-quantity-eaches"
                        className={classes.longValue}
                    >
                        {endsTotal.length > 0
                            ? formatNumberWithLocale(
                                  cultureCode,
                                  endsTotal.reduce((prev, curr) => prev + curr.eaches, 0)
                              ) + ' ea.'
                            : '0 ea.'}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ScrapItConfirmationSubheader;
