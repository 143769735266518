import { Grid, FormHelperText, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import {
    medium,
    ltBlueGrey_34,
    xl,
    boldWeight,
    red,
    warningLabel
} from '../../../../themes/globalConstants';
import TextInput from '../../../reusable/molecules/TextInput';
import FormSection from '../../../reusable/molecules/FormSection';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { EmeaGraphics } from '../../../../store/reducers/graphic-intake';
import { useFormContext, ErrorMessage } from 'react-hook-form';
import { AuthState } from '../../../../store/reducers/auth';

interface Props {
    isEditDraft: boolean;
    graphicData?: EmeaGraphics;
}

const useStyles = makeStyles((theme) => ({
    subsectionHeader: {
        fontSize: xl,
        fontWeight: boldWeight,
        color: theme.palette.info.dark,
        marginBottom: '2em'
    },
    label: {
        color: theme.palette.info.dark,
        fontSize: medium,
        marginBottom: '0.5em'
    },
    radioGroup: {
        marginTop: '1.5em',
        paddingLeft: '0.5em'
    },
    divider: {
        height: 5,
        backgroundColor: ltBlueGrey_34,
        margin: '1.5em 0'
    },
    helperText: {
        '& .MuiFormHelperText-root': {
            marginLeft: 0
        }
    },
    warningContainer: {
        display: 'flex',
        backgroundColor: warningLabel,
        margin: '1em 1.5em 1em 0.5em'
    },
    warningIconCtn: {
        textAlign: 'center'
    },
    warningIcon: {
        fill: red
    },
    warningLabel: {
        fontSize: medium,
        fontWeight: boldWeight
    },
    capitalize: {
        textTransform: 'capitalize'
    },
    errorText: {
        color: red
    }
}));

const AdditionalRemarks = ({ isEditDraft, graphicData }: Props) => {
    const classes = useStyles();
    const { control, errors, setValue } = useFormContext();
    const { userInfo } = useTypedSelector<AuthState>((state) => state.auth);

    useEffect(() => {
        if (isEditDraft && graphicData) {
            setValue('additionalRemarks', graphicData.additionalRemarks);
            setValue('submitter', graphicData.submitter);
        }
    }, [isEditDraft, graphicData, setValue]);

    const isRequired = <Trans i18nKey="required">Required</Trans>;

    return (
        <FormSection
            testId={'additional-remarks-section'}
            sectionHeader={<Trans i18nKey="generalCommentsSubInfo">General Comments</Trans>}
        >
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                    <TextInput
                        type="text"
                        label={<Trans i18nKey="generalComments">General Comments</Trans>}
                        name="additionalRemarks"
                        testId="additionalRemarks"
                        control={control}
                        rules={''}
                        error={errors.additionalRemarks}
                        errors={errors}
                        styles={classes.helperText}
                        helperText={
                            <Trans i18nKey="generalCommentsHelper">
                                Enter general comments you would like Ball to be aware of{' '}
                            </Trans>
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        type="text"
                        label={
                            <Trans i18nKey="responsibleForCompletion">
                                Responsible for Completion
                            </Trans>
                        }
                        name="submitter"
                        testId="submitter"
                        control={control}
                        rules={{ required: isRequired }}
                        error={errors.submitter}
                        errors={errors}
                        styles={classes.helperText}
                        defaultValue={userInfo?.email}
                        readOnly={true}
                        helperText={
                            <Trans i18nKey="responsibleForCompletionHelper">
                                Name of customer contact responsible for data in form{' '}
                            </Trans>
                        }
                    />
                    <FormHelperText className={classes.errorText}>
                        {errors.submitter && errors.submitter.type === 'required' && (
                            <ErrorMessage errors={errors} name={'submitter'} message={isRequired} />
                        )}
                    </FormHelperText>
                </Grid>
            </Grid>
        </FormSection>
    );
};

export default AdditionalRemarks;
