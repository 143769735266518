import { FormHelperText, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { ReactElement, useEffect } from 'react';
import { useFormContext, ErrorMessage } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { CreditApplication } from '../../../../store/reducers/onboarding-dashboard';
import { red } from '../../../../themes/globalConstants';
import { enUS } from '../../../../utility/translations/locales';
import CurrencyInput from '../../../reusable/molecules/CurrencyInput';
import FormSection from '../../../reusable/molecules/FormSection';
import TextInput from '../../../reusable/molecules/TextInput';

interface Props {
    creditAppData?: CreditApplication;
}

const useStyles = makeStyles(() => ({
    headerList: {
        marginTop: '0.5em'
    },
    headerListItem: {
        marginTop: '5px'
    },
    helperText: {
        '& .MuiFormHelperText-root': {
            marginLeft: 0
        }
    },
    errorText: {
        color: red
    },
    inputLine: {
        marginBottom: '0.75em'
    },
    formSectionWrapper: {
        padding: '8px 8px 0px 8px'
    }
}));

export default function CreditApplicationHeader({ creditAppData }: Props): ReactElement {
    const classes = useStyles();
    const { control, errors, setValue } = useFormContext();

    useEffect(() => {
        if (creditAppData) {
            setValue('legalBusinessName', creditAppData.legalBusinessName);
            setValue('businessName', creditAppData.businessName);
            setValue('businessType', creditAppData.businessType);
            setValue('monthlyCreditDesired', creditAppData.monthlyCreditDesired);
        }
    }, [creditAppData]);

    const isRequired = <Trans i18nKey="required">Required</Trans>;

    return (
        <FormSection
            testId={'credit-app-header-section'}
            sectionHeader={
                <Trans i18nKey="creditApplicationTitle">{enUS.creditApplicationTitle}</Trans>
            }
        >
            <Grid xs={12} className={classes.formSectionWrapper}>
                <Grid xs={12}>
                    <Typography>
                        <Trans i18nKey="creditAppListHeader">
                            In order to apply for credit with Ball, you must supply the following:
                        </Trans>
                    </Typography>
                </Grid>
                <Grid xs={12}>
                    <ul>
                        <li className={classes.headerListItem}>
                            <Typography>
                                <Trans i18nKey="creditAppFirstLI">{enUS.creditAppFirstLI}</Trans>
                            </Typography>
                        </li>
                        <li className={classes.headerListItem}>
                            <Typography>
                                <Trans i18nKey="creditAppSecondLI">{enUS.creditAppSecondLI}</Trans>
                            </Typography>
                        </li>
                        <li className={classes.headerListItem}>
                            <Typography>
                                <Trans i18nKey="creditAppThirdLI">{enUS.creditAppThirdLI}</Trans>
                            </Typography>
                        </li>
                    </ul>
                </Grid>
                <Grid container xs={12} spacing={2} className={classes.inputLine}>
                    <Grid item xs={6}>
                        <TextInput
                            type="text"
                            label={
                                <Trans i18nKey="legalNameOfBusiness">Legal Name of Business</Trans>
                            }
                            name="legalBusinessName"
                            testId="legalBusinessName"
                            control={control}
                            rules={{ required: isRequired }}
                            error={errors.legalBusinessName}
                            errors={errors}
                            styles={classes.helperText}
                            inputProps={{
                                'data-testid': 'legalBusinessName',
                                maxLength: 50
                            }}
                        />
                        <FormHelperText className={classes.errorText}>
                            {errors.legalBusinessName &&
                                errors.legalBusinessName.type === 'required' && (
                                    <ErrorMessage
                                        errors={errors}
                                        name={'legalBusinessName'}
                                        message={isRequired}
                                    />
                                )}
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput
                            type="text"
                            label={
                                <Trans i18nKey="businessName">Business Name (if different)</Trans>
                            }
                            name="businessName"
                            testId="businessName"
                            control={control}
                            rules={''}
                            error={errors.businessName}
                            errors={errors}
                            styles={classes.helperText}
                            inputProps={{
                                'data-testid': 'businessName',
                                maxLength: 50
                            }}
                        />
                        <FormHelperText>
                            <Trans i18nKey="businessNameHelper">If division, subsidiary</Trans>
                        </FormHelperText>
                    </Grid>
                </Grid>
                <Grid container xs={12} spacing={2}>
                    <Grid item xs={6}>
                        <TextInput
                            type="text"
                            label={<Trans i18nKey="typeOfBusiness">Type of Business</Trans>}
                            name="businessType"
                            testId="businessType"
                            control={control}
                            rules={{ required: isRequired }}
                            error={errors.businessType}
                            errors={errors}
                            styles={classes.helperText}
                            inputProps={{
                                'data-testid': 'businessType',
                                maxLength: 50
                            }}
                        />
                        <FormHelperText className={classes.errorText}>
                            {errors.businessType && errors.businessType.type === 'required' && (
                                <ErrorMessage
                                    errors={errors}
                                    name={'businessType'}
                                    message={isRequired}
                                />
                            )}
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={6}>
                        <CurrencyInput
                            label={
                                <Trans i18nKey="monthlyLineOfCredit">
                                    Monthly Line of Credit Desired
                                </Trans>
                            }
                            name="monthlyCreditDesired"
                            testId="monthlyCreditDesired"
                            control={control}
                            rules={{
                                required: isRequired,
                                pattern: /^(\d{1,15})([.,]?)(\d{0,2})$/
                            }}
                            error={errors.monthlyCreditDesired}
                            errors={errors}
                            styles={classes.helperText}
                            inputProps={{
                                'data-testid': 'monthlyCreditDesired',
                                maxLength: 15
                            }}
                        />
                        <FormHelperText className={classes.errorText}>
                            {errors.monthlyCreditDesired &&
                                errors.monthlyCreditDesired.type === 'required' && (
                                    <ErrorMessage
                                        errors={errors}
                                        name={'monthlyCreditDesired'}
                                        message={isRequired}
                                    />
                                )}
                            {errors.monthlyCreditDesired &&
                                errors.monthlyCreditDesired.type === 'pattern' && (
                                    <ErrorMessage
                                        errors={errors}
                                        name={'monthlyCreditDesired'}
                                        message={
                                            <Trans i18nKey="numericInput">
                                                Invalid Monetary Input
                                            </Trans>
                                        }
                                    />
                                )}
                        </FormHelperText>
                    </Grid>
                </Grid>
            </Grid>
        </FormSection>
    );
}
