import React from 'react';
import MathJax from 'react-mathjax2';
import { makeStyles, Typography } from '@material-ui/core';
import { atmFreightFormula, freightFormula, small } from '../../../themes/globalConstants';

const useStyles = makeStyles((theme) => ({
    formula: {
        fontSize: small,
        marginTop: '0.750em'
    }
}));

interface Props {
    maxPallets: number;
}

export default function FreightFormula({ maxPallets }: Props) {
    const classes = useStyles();
    return (
        <MathJax.Context input="ascii">
            <Typography className={classes.formula}>
                <MathJax.Node inline>
                    {maxPallets === 25 ? freightFormula : atmFreightFormula}
                </MathJax.Node>
            </Typography>
        </MathJax.Context>
    );
}
