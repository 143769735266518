import React from 'react';
import { Grid, Typography, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Link from '../../../reusable/atoms/Link';
import Button from '../../../reusable/atoms/Button';
import { Section } from '../../../../store/reducers/glp';
import { yellow, white } from '../../../../themes/globalConstants';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    container: {
        maxWidth: '50em',
        marginTop: '3em',
        marginBottom: '2em',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    header: {
        margin: '3.75em 0 1em 3.75em',
        [theme.breakpoints.down('xs')]: {
            marginLeft: '2em'
        }
    },
    description: {
        marginLeft: '3.75em',
        [theme.breakpoints.down('xs')]: {
            marginLeft: '2.5em'
        }
    },
    btnGrid: {
        marginLeft: '3.75em',
        marginBottom: '3.75em',
        [theme.breakpoints.down('xs')]: {
            margin: 'auto'
        }
    },
    btn: {
        marginTop: '2em',
        padding: '.5em 1em',
        width: '13em',
        [theme.breakpoints.down('xs')]: {
            margin: '3em 0 3em 0'
        }
    },
    secondaryBtn: {
        border: '1px solid white',
        color: white,
        borderRadius: 0,
        textTransform: 'uppercase',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom',
        '&:hover': {
            border: '1px solid white',
            backgroundPosition: 'left bottom',
            color: '#000',
            backgroundColor: white
        }
    }
}));

const ProductPortfolioBanner = ({ header, description, links, image }: Section) => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const btnMatches = useMediaQuery(theme.breakpoints.down('xs'));

    const gridStyle = image
        ? {
              backgroundImage: matches ? `url(${image.imageUrl})` : undefined,
              backgroundColor: yellow,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right bottom',
              backgroundSize: 'auto 18.75em',
              color: white,
              width: '100%',
              height: '100%'
          }
        : undefined;

    return header ? (
        <Grid container className={classes.container}>
            <section style={gridStyle}>
                <Grid
                    container
                    item
                    xs={11}
                    sm={6}
                    md={5}
                    xs-alignitems="center"
                    className={classes.header}
                >
                    <Typography variant="h2">{header}</Typography>
                </Grid>
                <Grid container item xs={10} sm={5} md={4} className={classes.description}>
                    <Typography variant="subtitle1" component="p">
                        {description}
                    </Typography>
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    md={4}
                    justify={btnMatches ? 'center' : 'flex-start'}
                    className={classes.btnGrid}
                >
                    {links?.length ? (
                        <Link component={RouterLink} to="/product-portfolio" underline="none">
                            <Button
                                data-testid="product-portfolio-button"
                                className={clsx(classes.btn, classes.secondaryBtn)}
                            >
                                {links[0].linkText}
                            </Button>
                        </Link>
                    ) : null}
                </Grid>
            </section>
        </Grid>
    ) : null;
};

export default ProductPortfolioBanner;
