import {
    MAKE_IT_ATM_SUMMARY_LOADING,
    MAKE_IT_ATM_SUMMARY_LOADED,
    MAKE_IT_ATM_SUMMARY_ERROR
} from '../actions/action-types';
import { ProductionOrder } from './makeit-dashboard';

export interface MakeItATMSummaryState {
    orders: ProductionOrder[];
    loaded: boolean;
    atmOrdersLoaded: boolean;
    error?: any;
}

const initialState: MakeItATMSummaryState = {
    orders: [],
    atmOrdersLoaded: false,
    loaded: false,
    error: undefined
};

const makeItATMSummary = (
    state: MakeItATMSummaryState = initialState,
    action: { type: string; [x: string]: any }
): MakeItATMSummaryState => {
    switch (action.type) {
        case MAKE_IT_ATM_SUMMARY_LOADING:
            return { ...state, loaded: false };
        case MAKE_IT_ATM_SUMMARY_LOADED:
            return { ...state, orders: action.orders, loaded: true, atmOrdersLoaded: true };
        case MAKE_IT_ATM_SUMMARY_ERROR:
            return { ...state, error: action.error };
        default:
            return state;
    }
};

export default makeItATMSummary;
