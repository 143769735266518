import {
    USER_CONFIGURATION_FETCH_DASHBOARD_POSITIONS_SUCCESS,
    USER_CONFIGURATION_UPDATE_DASHBOARD_POSITIONS
} from '../actions/action-types';

export type DashboardElementId = 'makeit' | 'shipit';

export interface DashboardElement {
    name: DashboardElementId;
    elementCode?: string;
    rowPosition?: number;
    columnPosition: number;
}

export interface UserConfiguration {
    dashboardPositions: Array<DashboardElement>;
}

export const userConfigurationInitialState: UserConfiguration = {
    dashboardPositions: [
        {
            name: 'makeit',
            columnPosition: 0
        },
        {
            name: 'shipit',
            columnPosition: 1
        }
    ]
};

const userConfiguration = (state = userConfigurationInitialState, action: any): UserConfiguration => {
    switch (action.type) {
        case USER_CONFIGURATION_UPDATE_DASHBOARD_POSITIONS:
        case USER_CONFIGURATION_FETCH_DASHBOARD_POSITIONS_SUCCESS:
            return {
                ...state,
                dashboardPositions: action.dashboardPositions
            };
        default:
            return state;
    }
};

export default userConfiguration;
