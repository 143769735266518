import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class TradeRequestSummaryColumns {
    static getColumns = (culture: string) => {
        return [
            createColumn('tradeRequestId', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'tradeItRequestOrderNumber'),
                filterable: true,
                sortable: true,
                searchable: true
            }),
            createColumn('accountFrom', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'originalShipTo'),
                sortable: true,
                searchable: true
            }),
            createColumn('accountFromName', {
                dataType: ColumnDataType.String,
                label: 'accountFromName',
                sortable: true,
                searchable: true,
                visible: false
            }),
            createColumn('accountFromAddress', {
                dataType: ColumnDataType.String,
                label: 'accountFromAddress',
                sortable: true,
                searchable: true,
                visible: false
            }),
            createColumn('accountTo', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'receivingShipTo'),
                sortable: true,
                searchable: true
            }),
            createColumn('accountToName', {
                dataType: ColumnDataType.String,
                label: 'accountToName',
                sortable: true,
                searchable: true,
                visible: false
            }),
            createColumn('accountToAddress', {
                dataType: ColumnDataType.String,
                label: 'accountToAddress',
                sortable: true,
                searchable: true,
                visible: false
            }),
            createColumn('requestedAmount', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'requestedAmount'),
                filterable: true,
                sortable: true,
                searchable: true
            }),
            createColumn('quantitySkus', {
                dataType: ColumnDataType.Numeric,
                visible: false,
                filterable: false,
                sortable: false,
                searchable: true
            }),
            createColumn('quantityEaches', {
                dataType: ColumnDataType.Numeric,
                visible: false,
                filterable: false,
                sortable: false,
                searchable: true
            }),
            createColumn('submittedDate', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'dateSubmitted'),
                filterable: true,
                searchable: true,
                sortable: true
            })
        ];
    };
}
