import { Grid, makeStyles, TableCell, TableRow } from '@material-ui/core';
import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { Region } from '../../../../store/reducers/graphic-intake';
import { boldWeight, medium, orange } from '../../../../themes/globalConstants';
import { Link as RouterLink } from 'react-router-dom';
import Link from '../../../reusable/atoms/Link';
import { Trans } from 'react-i18next';
import useSecurity, { Activity, SecurityLevel } from '../../../../utility/auth/useSecurity';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { InProgressGraphicForTable } from '../models/InProgressGraphicForTable';
interface Props {
    graphic: InProgressGraphicForTable;
    onDeleteGraphic: (graphic: InProgressGraphicForTable) => void;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme) => ({
    action: {
        cursor: 'pointer'
    },
    name: {
        fontWeight: boldWeight,
        fontSize: medium,
        letterSpacing: 0.17
    },
    trashIcon: {
        fill: orange
    }
}));

const GraphicsDraftRow = ({ graphic, onDeleteGraphic, open, setOpen }: Props) => {
    const classes = useStyles();
    const intakeSecurity = useSecurity(Activity.GraphicsIntakeForm);
    const { regionCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [intakeFormPath, setIntakeFormPath] = useState<string>('graphics-intake');

    useEffect(() => {
        regionCode === Region.EU
            ? setIntakeFormPath('emea-graphics-intake')
            : setIntakeFormPath('graphics-intake');
    }, [regionCode]);

    const onDelete = () => {
        onDeleteGraphic(graphic);
    };

    return (
        <TableRow hover={true} data-testid={`${graphic.requestName}`}>
            <TableCell data-testid="submission-name">
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        {graphic.requestName}
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell data-testid="draft-actions">
                <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                        <Link
                            to={`/${intakeFormPath}/${graphic.graphicsId}`}
                            component={RouterLink}
                            underline="always"
                            data-testid="complete-intake-link"
                            disabled={intakeSecurity !== SecurityLevel.Edit}
                        >
                            <Trans i18nKey="completeIntakeForm">Complete Intake Form</Trans>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link
                            onClick={onDelete}
                            className={classes.action}
                            data-testid="delete-btn"
                            disabled={intakeSecurity !== SecurityLevel.Edit}
                        >
                            {intakeSecurity !== SecurityLevel.Edit ? (
                                <img
                                    src={process.env.PUBLIC_URL + '/assets/DisabledTrashIcon.svg'}
                                    alt="Disabled Trash Icon"
                                    data-testid="trash-icon"
                                />
                            ) : (
                                <img
                                    src={process.env.PUBLIC_URL + '/assets/Trash.svg'}
                                    alt="Trash Icon"
                                    data-testid="trash-icon"
                                />
                            )}
                        </Link>
                    </Grid>
                </Grid>
            </TableCell>
        </TableRow>
    );
};

export default GraphicsDraftRow;
