import {
    USER_ADMIN_USERS_CLEARED,
    USER_ADMIN_USERS_LOADED,
    USER_ADMIN_USERS_LOADING,
    USER_ADMIN_USERS_LOADING_ERROR,
    USER_ADMIN_USER_CLEARED,
    USER_ADMIN_USER_LOADED,
    USER_ADMIN_USER_LOADING,
    USER_ADMIN_VIEW_EDIT_USER,
    USER_ADMIN_USER_LOADING_ERROR,
    USER_ADMIN_PENDING_REGISTRATIONS,
    USER_ADMIN_ADD_USER_LOADING,
    USER_ADMIN_ADD_USER_SUCCESS,
    USER_ADMIN_ADD_USER_ERROR,
    USER_ADMIN_PENDING_REQUESTS,
    USER_ADMIN_USER_DISABLING_ERROR,
    MY_ACCOUNT_PERMISSION_CHANGES_LOADING,
    MY_ACCOUNT_PERMISSION_CHANGES_LOADED,
    MY_ACCOUNT_PERMISSION_CHANGES_ERROR,
    USER_ADMIN_PENDING_REQUEST_LOADED,
    USER_ADMIN_PENDING_REQUEST_REJECTED,
    USER_ADMIN_PENDING_REQUEST_APPROVED,
    USER_ADMIN_PENDING_REQUEST_RESET,
    USER_ADMIN_ADD_USER_RESET_STATUS
} from '../actions/action-types';
import { UserPermission } from './auth';

export interface UserAdminUserPermissions {
    userName: string;
    permissions: UserPermission[] | null;
    status: boolean;
    firstName?: string;
    lastName?: string;
    lastLogin?: Date;
}

export interface RegistrationPermission {
    accountId: string;
    persona: string;
}

export interface Registrations {
    userName: string;
    permissions: RegistrationPermission[];
    createDate: string;
    registrationId: string;
}
export interface UserAdminPendingRegistrations {
    registrations: Registrations[];
}

export interface PermissionRequest {
    accountId: string;
    personaId: string;
}

export interface AccountRoles {
    accountId: string;
    personaIds: string[];
}
export interface UserAdminPendingRequests {
    permissionChanges: UserPermissionChange[];
}

export interface UserPermissionChange {
    permissionChangeId: number;
    userName: string;
    comments: string;
    createDate: string;
    latestSubmissionDate?: string;
    lastSubmittedDate?: string;
    firstName?: string;
    lastName?: string;
    accountId: string;
    accountCity?: string;
    accountStateProvince?: string;
    personas: string[];
}

export interface UserAdminUsers {
    users: UserAdminUserPermissions[];
}

export interface GetUserPermissionsResponse {
    firstName: string;
    lastName: string;
    lastLogin: string;
    userName: string;
    permissions: Array<UserPermission>;
}

export interface UserPermissionChanges {
    permissionChanges: UserPermissionChange[];
}

export interface PermissionChangeItem {
    accountId: string;
    personas: string[];
}

export interface UserAdminState {
    userToEdit: string;
    pendingRequest?: UserPermissionChange;
    firstName?: string;
    lastName?: string;
    lastLogin?: Date;
    permissions: UserPermission[] | null;
    loading: boolean;
    loaded: boolean;
    error?: any;
    addUserSubmitStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
    editable: boolean;
}

const initialState: UserAdminState = {
    userToEdit: '',
    pendingRequest: undefined,
    firstName: undefined,
    lastName: undefined,
    lastLogin: undefined,
    permissions: null,
    loading: false,
    loaded: false,
    error: undefined,
    addUserSubmitStatus: 'idle',
    editable: false
};

const userAdmin = (state = initialState, action: any): UserAdminState => {
    switch (action.type) {
        case USER_ADMIN_USER_LOADING:
            return {
                ...state,
                loading: true,
                loaded: false
            };
        case USER_ADMIN_USER_LOADED:
            return {
                ...state,
                loading: false,
                loaded: true,
                userToEdit: action.userToEdit,
                permissions: action.permissions,
                firstName: action.firstName,
                lastName: action.lastName,
                lastLogin: action.lastLogin,
                editable: action.editable
            };
        case USER_ADMIN_PENDING_REQUEST_LOADED:
            return {
                ...state,
                loading: false,
                loaded: true,
                pendingRequest: action.pendingRequest
            };
        case USER_ADMIN_PENDING_REQUEST_RESET:
        case USER_ADMIN_PENDING_REQUEST_REJECTED:
            return {
                ...state,
                pendingRequest: undefined
            };
        case USER_ADMIN_PENDING_REQUEST_APPROVED:
            return {
                ...state,
                pendingRequest: undefined
            };
        case USER_ADMIN_USER_CLEARED:
            return {
                ...state,
                loading: false,
                loaded: false,
                userToEdit: '',
                permissions: null,
                error: undefined,
                firstName: undefined,
                lastName: undefined,
                lastLogin: undefined,
                addUserSubmitStatus: 'idle',
                editable: false
            };
        case USER_ADMIN_USER_LOADING_ERROR:
            return {
                ...state,
                loading: false,
                loaded: false,
                userToEdit: '',
                permissions: null,
                error: action.error,
                firstName: undefined,
                lastName: undefined,
                lastLogin: undefined,
                editable: false
            };
        case USER_ADMIN_VIEW_EDIT_USER:
            return {
                ...state,
                loading: false,
                loaded: true,
                userToEdit: action.userToEdit,
                permissions: action.permissions,
                firstName: action.firstName,
                lastName: action.lastName,
                lastLogin: action.lastLogin,
                editable: action.editable
            };
        case USER_ADMIN_ADD_USER_LOADING:
            return {
                ...state,
                addUserSubmitStatus: 'loading'
            };
        case USER_ADMIN_ADD_USER_SUCCESS:
            return {
                ...state,
                addUserSubmitStatus: 'succeeded'
            };
        case USER_ADMIN_ADD_USER_ERROR:
            return {
                ...state,
                addUserSubmitStatus: 'failed'
            };
        case USER_ADMIN_ADD_USER_RESET_STATUS:
            return {
                ...state,
                addUserSubmitStatus: 'idle'
            };
        default:
            return state;
    }
};

export interface AdminUserTableRow {
    userName: string;
    roles: string;
    currentUserHasAdminPermission?: boolean;
}

export interface PendingRequestTableRow {
    requestId: number;
    userName: string;
    roles: string;
    accountId: string;
    locationAddress?: string;
    accountName?: string;
    sentOn: string;
    currentUserHasAdminPermission: boolean;
}

export interface PendingRegistrationTableRow extends AdminUserTableRow {
    registrationId: string;
    createDate: string;
}

export interface UserDashboardState {
    activeUsers: UserAdminUserPermissions[] | null;
    inactiveUsers: UserAdminUserPermissions[] | null;
    pendingRegistrations: Registrations[] | null;
    permissionChanges: UserPermissionChange[] | null;
    loading: boolean;
    loaded: boolean;
    error?: any;
    disableError?: any;
}

const userDashboardInitialState: UserDashboardState = {
    activeUsers: null,
    inactiveUsers: null,
    pendingRegistrations: null,
    permissionChanges: null,
    loading: false,
    loaded: false,
    error: undefined,
    disableError: undefined
};

export const userAdminDashboard = (
    state = userDashboardInitialState,
    action: any
): UserDashboardState => {
    switch (action.type) {
        case USER_ADMIN_USERS_LOADING:
            return {
                ...state,
                loading: true,
                loaded: false,
                error: undefined,
                disableError: undefined
            };
        case USER_ADMIN_USERS_LOADED:
            return {
                ...state,
                loading: false,
                loaded: true,
                activeUsers: action.activeUsers,
                inactiveUsers: action.inactiveUsers
            };
        case USER_ADMIN_PENDING_REGISTRATIONS:
            return {
                ...state,
                loading: false,
                loaded: true,
                pendingRegistrations: action.pendingRegistrations
            };
        case USER_ADMIN_PENDING_REQUESTS:
            return {
                ...state,
                loading: false,
                loaded: true,
                permissionChanges: action.permissionChanges
            };
        case USER_ADMIN_USER_DISABLING_ERROR:
            return {
                ...state,
                disableError: action.error
            };
        case USER_ADMIN_USERS_CLEARED:
            return {
                ...state,
                loading: false,
                loaded: false,
                activeUsers: null,
                error: undefined,
                disableError: undefined
            };
        case USER_ADMIN_USERS_LOADING_ERROR:
            return {
                ...state,
                loading: false,
                loaded: false,
                activeUsers: null,
                error: action.error
            };
        default:
            return state;
    }
};
export interface MyAccountDashboardState {
    permissionChanges: UserPermissionChange[] | null;
    loading: boolean;
    loaded: boolean;
    error?: any;
}

const myAccountInitialState: MyAccountDashboardState = {
    permissionChanges: null,
    loading: false,
    loaded: false,
    error: undefined
};

export const myAccountDashboard = (
    state = myAccountInitialState,
    action: any
): MyAccountDashboardState => {
    switch (action.type) {
        case MY_ACCOUNT_PERMISSION_CHANGES_LOADING:
            return {
                ...state,
                loading: true,
                loaded: false,
                error: undefined
            };
        case MY_ACCOUNT_PERMISSION_CHANGES_LOADED:
            return {
                ...state,
                loading: false,
                loaded: true,
                permissionChanges: action.permissionChanges
            };
        case MY_ACCOUNT_PERMISSION_CHANGES_ERROR:
            return {
                ...state,
                loading: false,
                loaded: false,
                permissionChanges: null,
                error: action.error
            };
        default:
            return state;
    }
};

export default userAdmin;
