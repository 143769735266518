import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
    black,
    boldWeight,
    yellowNoticeBackground,
    errorBackground,
    informationAlert,
    ballBlue,
    shipWarningBlue
} from '../../../themes/globalConstants';

export interface NotificationProps {
    communications: any;
    notificationType?: 'SUCCESS' | 'INFO' | 'WARNING' | 'ERROR';
    messagesCallback;
}

interface Props extends NotificationProps {}

const useStyles = makeStyles((theme) => ({
    notificationContainer: {
        whiteSpace: 'pre-wrap',
        alignItems: 'flex-start',
        marginTop: '1em',
        width: '100%',
        '& .MuiAlert-icon': {
            color: '#009BBB',
            display: 'flex',
            alignItems: 'center'
        },
        '& .MuiAlert-message': {
            flexGrow: '100',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& .MuiTypography-root': {
                color: black,
                fontWeight: boldWeight,
                display: 'inline'
            },
            '& .MuiLink-root': {
                textDecoration: 'underline',
                color: ballBlue,
                '&:hover': {
                    textDecoration: 'underline'
                }
            }
        },
        backgroundColor: shipWarningBlue
    },
    successContainer: {
        backgroundColor: shipWarningBlue
    },
    infoContainer: {
        backgroundColor: informationAlert
    },
    warningContainer: {
        backgroundColor: yellowNoticeBackground
    },
    errorContainer: {
        backgroundColor: errorBackground
    },
    btn: {
        color: theme.palette.info.dark
    },
    alert: {
        paddingLeft: 24,
        paddingRight: 24,
        maxWidth: '85em',
        wordBreak: 'break-word',
        margin: '0 auto'
    }
}));

const successIcon = (
    <img
        src={process.env.PUBLIC_URL + '/assets/Confirmation_Icon.svg'}
        alt="Success Icon"
        width="24"
        height="24"
    />
);
const infoIcon = (
    <img src={process.env.PUBLIC_URL + '/assets/InfoTriangle_Icon.svg'} alt="Info Icon" />
);
const warningIcon = (
    <img src={process.env.PUBLIC_URL + '/assets/Warning_Icon.svg'} alt="Warning Icon" />
);
const errorIcon = <img src={process.env.PUBLIC_URL + '/assets/Error_Icon.svg'} alt="Error Icon" />;

const getIcon = (notificationType) => {
    switch (notificationType) {
        case 'WARNING':
            return warningIcon;
        case 'ERROR':
            return errorIcon;
        case 'INFO':
            return infoIcon;
        case 'SUCCESS':
            return successIcon;
        default:
            return infoIcon;
    }
};

const getSeverity = (notificationType) => {
    return notificationType.toLowerCase();
};

export default function CommunicationMessagesBanner({
    communications,
    notificationType = 'INFO',
    messagesCallback
}: Props) {
    const classes = useStyles();
    const [messageIndex, setMessageIndex] = useState<number>(communications?.length - 1);
    const [showBanner, setShowBanner] = useState<boolean>(communications?.length ? true : false);

    const allMessages = communications?.map((each) => each.content);

    useEffect(() => {
        // This check handles a message being dismissed via modal when it's also displayed here as a banner
        if (allMessages && messageIndex >= 0 && !allMessages[messageIndex])
            setMessageIndex(messageIndex - 1);

        if (messageIndex < 0 && showBanner) setShowBanner(false);
    }, [allMessages, messageIndex]);

    return (
        <>
            {showBanner && (
                <Grid container item xs={12} className={classes.alert}>
                    <Alert
                        icon={getIcon(notificationType)}
                        severity={getSeverity(notificationType)}
                        className={clsx(
                            classes.notificationContainer,
                            classes[`${notificationType.toLowerCase()}Container`]
                        )}
                        data-testid="notification"
                        onClose={() => {
                            messagesCallback(communications[messageIndex]);
                            if (messageIndex > 0) {
                                setMessageIndex(messageIndex - 1);
                            } else setShowBanner(false);
                        }}
                    >
                        <Typography>{allMessages[messageIndex]}</Typography>
                    </Alert>
                </Grid>
            )}
        </>
    );
}
