import { TableRow, TableCell, Grid, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { PlanItBulkATMLineItem } from '../../../../store/reducers/planit-bulk-atm';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { blackWeight, regularWeight, small } from '../../../../themes/globalConstants';
import { formatNumberWithLocale } from '../../../../utility/helpers/formatters';

interface Props {
    item: PlanItBulkATMLineItem;
}

const useStyles = makeStyles(() => ({
    tableRowContainer: {
        '& .helper-text': {
            fontWeight: `${regularWeight} !important`,
            fontSize: small
        }
    },
    bold: {
        fontWeight: blackWeight
    }
}));

const PlanItBulkUploadConfirmationRow = ({ item }: Props) => {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const originalEachesValue =
        item.originalPalletQuantity && item.quantityPerPallet
            ? item.originalPalletQuantity * item.quantityPerPallet
            : 0;
    const revisedEachesValue =
        item.palletQuantity && item.quantityPerPallet
            ? item.palletQuantity * item.quantityPerPallet
            : 0;
    return (
        <>
            <TableRow
                className={classes.tableRowContainer}
                data-testid={`product-${item.displayId}`}
            >
                <TableCell data-testid="display-id">
                    <Grid container alignContent="center">
                        <Grid container>
                            <Typography>
                                <strong>{item.customerProductId ?? '-'}</strong>
                            </Typography>
                        </Grid>
                        <Grid container>
                            <strong>
                                <Typography className={classes.bold}>{item.productSku}</Typography>
                            </strong>
                        </Grid>
                        <Grid container>
                            <Typography>{item.graphicIdAndVersion}</Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell data-testid="product-description">
                    <Grid container alignContent="center">
                        <Grid container>
                            <Typography className={classes.bold}>{item.displayName}</Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell data-testid="coating">
                    <Grid container alignContent="center">
                        <Grid container>
                            <Typography className={classes.bold}>{item.coating}</Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell data-testid="previous-quantity">
                    <Grid container direction="column">
                        <Grid item>
                            <Typography className={classes.bold}>
                                {formatNumberWithLocale(
                                    cultureCode,
                                    item.originalPalletQuantity || 0
                                )}{' '}
                                PL
                            </Typography>
                        </Grid>
                        {item.originalPalletQuantity !== undefined && (
                            <Grid item>
                                <Typography
                                    className="helper-text"
                                    data-testid="previous-quantity-eaches"
                                >
                                    {formatNumberWithLocale(
                                        cultureCode,
                                        Number(originalEachesValue)
                                    )}
                                    {' ea.'}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </TableCell>
                <TableCell data-testid="pallet-quantity">
                    <Grid container alignContent="center">
                        <Grid container>
                            <Typography className={classes.bold}>
                                {!item.deleted && item.palletQuantity
                                    ? formatNumberWithLocale(cultureCode, item.palletQuantity)
                                    : 0}{' '}
                                PL
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography className="helper-text" data-testid="previous-quantity-eaches">
                            {item.deleted
                                ? 0
                                : formatNumberWithLocale(cultureCode, Number(revisedEachesValue))}
                            {' ea.'}
                        </Typography>
                    </Grid>
                </TableCell>
            </TableRow>
        </>
    );
};

export default PlanItBulkUploadConfirmationRow;
