import { OrderType, QuantityType } from '../../utility/services/orders-service';
import {
    MAKEIT_BULK_UPLOAD_LOADING,
    MAKEIT_BULK_UPLOAD_ERROR,
    MAKEIT_BULK_UPLOAD_LOADED,
    MAKEIT_BULK_UPLOAD_RESET_STATE,
    MAKEIT_BULK_UPLOAD_GET_PRODUCTS,
    MAKEIT_BULK_SUBMIT_REVIEW,
    MAKEIT_BULK_UPLOAD_FILE,
    MAKEIT_BULK_UPDATE_PALLET_QUANTITY,
    MAKEIT_BULK_UPDATED_REQUESTED_DATE,
    MAKEIT_BULK_UPDATED_PRODUCT_SKU,
    MAKEIT_BULK_UPDATED_LINE_ITEM,
    MAKEIT_BULK_MARK_ORDER_REMOVED,
    MAKEIT_BULK_UNDO_DELETE_ORDER,
    MAKEIT_BULK_DELETE_ORDER,
    MAKEIT_BULK_UPLOAD_SUBMIT,
    MAKEIT_BULK_DELETE_PRODUCT,
    MAKEIT_BULK_UPLOAD_REMOVE_ORDER,
    MAKEIT_BULK_ATM_SUBMIT_ORDERS_SUCCESS,
    MAKEIT_BULK_UPLOAD_REFRESH_STATE
} from '../actions/action-types';
import { TemplateMapping } from './bulk-upload';
import { ProductionOrderLine } from './makeit-dashboard';

export interface MakeItLineError {
    lineNumber?: number;
    shipToId?: number;
    deliveryDateTime?: string;
    productId?: string;
    availableBalance?: number;
    propertyName?: string;
    errorMessage?: string;
    attemptedValue?: any;
    severity?: string;
    errorCode?: string;
    leadTimeDays?: number;
    leadTimeWeeks?: number;
    firstAvailableDate?: string;
}

export enum LineErrorCode {
    EA100001 = 'EA100001',
    EA000004 = 'EA000004',
    EA000006 = 'EA000006',
    EP000001 = 'EP000001',
    EP000002 = 'EP000002',
    EP000003 = 'EP000003',
    EP000004 = 'EP000004',
    EP000005 = 'EP000005',
    EP100006 = 'EP100006',
    EP100001 = 'EP100001',
    EP110001 = 'EP110001',
    EP100002 = 'EP100002',
    EP100003 = 'EP100003',
    EP100004 = 'EP100004',
    EP100005 = 'EP100005',
    EP100007 = 'EP100007',
    EP110005 = 'EP110005',
    EP200001 = 'EP200001',
    EP200002 = 'EP200002',
    EP200003 = 'EP200003',
    EP200004 = 'EP200004',
    EP200006 = 'EP200006',
    EP200005 = 'EP200005',
    EP300001 = 'EP300001',
    EP400001 = 'EP400001',
    EP500002 = 'EP500002',
    EP100008 = 'EP100008',
    EP100009 = 'EP100009',
    EP100010 = 'EP100010',
    EP100011 = 'EP100011'
}

export interface MakeItBulkLineItem extends ProductionOrderLine {
    userSuppliedProductId?: string;
    addDisabled?: boolean;
    parentItem?: boolean;
    childLines?: MakeItBulkLineItem[];
    leadTimeDisplay?: string;
    firstAvailableDate?: string;
    customerProductionOrderId?: string;
    displayQuantity?: number;
    shipToId?: number;
    shipToName?: string;
    shipToAddress?: string;
    originalCsvLineNumber?: number;
    isCustomerProductIdDistinct?: boolean;
    candidateProductSkus?: string[];
    snoSku?: string;
    destinations?: [
        {
            shipToId: number;
            customerProductId: string;
            customerProductName: string;
            status: string;
        }
    ];
    palletsRounded?: boolean;
    eachesQuantity?: number;
    originalQuantity?: string;
}

export interface MakeItBulkOrder {
    customerProductionOrderId: string;
    lines: MakeItBulkLineItem[];
    canQuantityPallets?: number;
    canQuantitySKUs?: number;
    canQuantityEaches?: number;
    endQuantityPallets?: number;
    endQuantitySKUs?: number;
    endQuantityEaches?: number;
    shipToId: number;
    shipToName: string;
    shipToAddress: string;
    markedRemoved?: boolean;
    createdBy?: string;
}

export interface BulkUploadTemplateRequest {
    userName: string;
    orderType: OrderType;
    mappings: TemplateMapping[];
    quantityType?: QuantityType;
}

export interface BulkUploadDeliveryReviewRequest {
    userName: string;
    csvData: FormData;
}

export interface BulkUploadMakeItState {
    templateMappings?: TemplateMapping[];
    loading: boolean;
    loaded: boolean;
    error?: any;
    file?: File;
    orders?: MakeItBulkOrder[];
    products?: MakeItBulkLineItem[];
    lineErrors?: MakeItLineError[];
    makeItQuantityType?: QuantityType;
}

const initialState: BulkUploadMakeItState = {
    templateMappings: undefined,
    loading: false,
    loaded: false,
    orders: undefined,
    products: undefined
};

const bulkUploadMakeItState = (
    state: BulkUploadMakeItState = initialState,
    action: { type: string; [x: string]: any }
): BulkUploadMakeItState => {
    switch (action.type) {
        case MAKEIT_BULK_UPLOAD_LOADING:
            return { ...state, loaded: false, loading: true, error: undefined };
        case MAKEIT_BULK_UPLOAD_ERROR:
            return { ...state, loaded: true, loading: false, error: action.error };
        case MAKEIT_BULK_UPLOAD_LOADED:
            return {
                ...state,
                templateMappings: action.mappings,
                makeItQuantityType: action.quantityType,
                loading: false,
                error: undefined
            };
        case MAKEIT_BULK_UPLOAD_GET_PRODUCTS:
            return {
                ...state,
                products: action.products,
                loaded: true,
                loading: false
            };
        case MAKEIT_BULK_UPLOAD_FILE:
            return {
                ...state,
                file: action.file
            };
        case MAKEIT_BULK_UPDATED_REQUESTED_DATE:
            return {
                ...state,
                orders: [...action.orders]
            };
        case MAKEIT_BULK_UPDATED_LINE_ITEM:
            return {
                ...state,
                orders: [...action.orders]
            };
        case MAKEIT_BULK_SUBMIT_REVIEW:
            return {
                ...state,
                orders: action.orders,
                loading: false,
                lineErrors: action.lineErrors,
                error: undefined
            };
        case MAKEIT_BULK_UPDATED_PRODUCT_SKU:
        case MAKEIT_BULK_UPDATE_PALLET_QUANTITY:
        case MAKEIT_BULK_MARK_ORDER_REMOVED:
        case MAKEIT_BULK_DELETE_ORDER:
        case MAKEIT_BULK_UNDO_DELETE_ORDER:
        case MAKEIT_BULK_DELETE_PRODUCT: {
            return { ...state, orders: [...action.orders] };
        }
        case MAKEIT_BULK_UPLOAD_SUBMIT:
            return {
                ...state,
                orders: action.orders,
                loading: false,
                loaded: true,
                error: undefined,
                file: undefined
            };
        case MAKEIT_BULK_UPLOAD_REFRESH_STATE:
            return {
                ...state,
                orders: undefined,
                lineErrors: undefined,
                error: undefined,
                loaded: false,
                loading: false
            };
        case MAKEIT_BULK_UPLOAD_RESET_STATE:
            return {
                ...initialState
            };
        case MAKEIT_BULK_UPLOAD_REMOVE_ORDER:
            return {
                ...state,
                orders: undefined
            };
        case MAKEIT_BULK_ATM_SUBMIT_ORDERS_SUCCESS:
            return {
                ...state,
                file: undefined
            };
        default:
            return state;
    }
};

export default bulkUploadMakeItState;
