import React, { useState, useEffect } from 'react';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import { Grid, makeStyles } from '@material-ui/core';
import { boldWeight, orange, blackWeight } from '../../../../themes/globalConstants';
import shipmentLoadDetailColumns from './ShipmentLoadDetailColumns';
import ShipmentLoadDetailRow from './ShipmentLoadDetailRow';
import {
    ProductToShip,
    LinkedProductionOrders
} from '../../../../store/reducers/shipping-dashboard';
import { getFilteredProductionOrders } from '../../../../utility/helpers/order-helpers';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { generateKey } from '../../../../utility/helpers/order-helpers';

interface Props {
    loads?: ProductToShip[];
    linkedProductionOrders?: LinkedProductionOrders;
}

const useStyles = makeStyles((theme) => ({
    action: {
        cursor: 'pointer'
    },
    trashIcon: {
        fill: orange
    },
    tableWrapper: {
        '& .MuiToolbar-root': {
            minHeight: 0,
            padding: 0,
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                },
                '&:first-of-type ': {
                    paddingLeft: 0
                }
            },
            '& > h2': {
                fontSize: '1.5em',
                fontWeight: boldWeight,
                color: theme.palette.secondary.main,
                paddingLeft: '0.625em',
                fontFamily: 'Lato',
                minWidth: '12em'
            }
        },
        '& .MuiTableSortLabel-root': {
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em',
            width: '99%',
            justifyContent: 'space-between',
            '& .MuiSvgIcon-root': {
                margin: 0
            }
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                '&:nth-child(1)': {
                    width: '10%'
                },
                '&:nth-child(2)': {
                    width: '30%'
                },
                '&:nth-child(3)': {
                    width: '12%'
                },
                '&:nth-child(4)': {
                    width: '12%'
                },
                '&:nth-child(5)': {
                    width: '12%'
                },
                '&:nth-child(6)': {
                    width: '12%'
                },
                '&:nth-child(7)': {
                    width: '12%'
                }
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    }
}));

const ShipmentLoadDetailGrid = ({ loads, linkedProductionOrders }: Props) => {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [shipmentLoads, setShipmentLoads] = useState<ProductToShip[]>();

    const CustomToolbarOptions = new ToolbarOptions({
        advancePagination: false,
        exportButton: false,
        printButton: false,
        searchText: false
    });

    const ShipmentLoadDetailItemRow = ({ row }: { row: Partial<ProductToShip> }) => {
        let filteredProductionOrder = getFilteredProductionOrders(
            row.loadId,
            linkedProductionOrders
        );
        if (row) {
            return (
                <ShipmentLoadDetailRow
                    key={row.loadId}
                    line={row as ProductToShip}
                    linkedProductionOrder={filteredProductionOrder}
                />
            );
        } else {
            return null;
        }
    };

    useEffect(() => {
        if (loads && linkedProductionOrders) {
            setShipmentLoads(loads);
        }
    }, [loads, linkedProductionOrders]);

    return (
        <Grid className={classes.tableWrapper} key={generateKey('delivery-grid')}>
            {shipmentLoads && (
                <DataGrid
                    gridName="Shipment Loads"
                    columns={shipmentLoadDetailColumns.getDeliveryOrderColumns(cultureCode)}
                    dataSource={shipmentLoads}
                    toolbarOptions={CustomToolbarOptions}
                    rowComponent={ShipmentLoadDetailItemRow}
                ></DataGrid>
            )}
        </Grid>
    );
};

export default ShipmentLoadDetailGrid;
