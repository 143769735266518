import { Grid, Collapse, Typography, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { warningLabel, boldWeight } from '../../../themes/globalConstants';
import Button from '../atoms/Button';

interface Props {
    showWarning: boolean;
    warningText: React.ReactNode;
    handleClearFilter: () => void;
}

const useStyles = makeStyles(() => ({
    warningContainer: {
        marginTop: '1em',
        width: '50%',
        padding: '0.875em 0',
        borderRadius: '.125em',
        '& .MuiAlert-icon': {
            display: 'flex',
            alignItems: 'center'
        }
    },
    alert: {
        backgroundColor: warningLabel,
        justifyContent: 'center',
        '& .MuiAlert-message': {
            display: 'flex',
            alignItems: 'center'
        }
    },
    warningText: {
        fontWeight: boldWeight
    },
    filter: {
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    filterIcon: {
        height: 20,
        width: 20
    }
}));

export default function ClearFilterWarning({ showWarning, warningText, handleClearFilter }: Props) {
    const classes = useStyles();
    const warningIcon = (
        <img src={process.env.PUBLIC_URL + '/assets/Warning_Icon.svg'} alt="Filter Warning" />
    );
    const clearFilter = (
        <img
            src={process.env.PUBLIC_URL + '/assets/Clear_Filter.png'}
            alt="Clear Filter"
            className={classes.filterIcon}
        />
    );

    return (
        <>
            {showWarning && (
                <Grid container justify="center" data-testid="warning-message">
                    <Collapse in={showWarning} className={classes.warningContainer}>
                        <Alert severity="warning" className={classes.alert} icon={warningIcon}>
                            <Typography className={classes.warningText}>{warningText}</Typography>
                            <Button
                                type="button"
                                onClick={() => handleClearFilter()}
                                className={classes.filter}
                            >
                                {clearFilter}
                            </Button>
                        </Alert>
                    </Collapse>
                </Grid>
            )}
        </>
    );
}
