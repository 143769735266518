import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class MakeItConversionReviewOrderSectionColumns {
    static getColumns = (culture: string) => {
        return [
            createColumn('displayId', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productGraphicId', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'productGraphicId')
            }),
            createColumn('displayName', {
                dataType: ColumnDataType.String,
                label: `${TranslationService.getTranslatedText(
                    culture,
                    'productDescription'
                )} / ${TranslationService.getTranslatedText(culture, 'characteristics')}`
            }),
            createColumn('leadTime', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'leadTime')
            }),
            createColumn('coating', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'coating')
            }),

            createColumn('originalPalletQuantity', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'forecastedQuantity'),
                visible: true
            }),
            createColumn('palletQuantity', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'quantityToPurchase')
            }),
            createColumn('productId', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('graphicIdAndVersion', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('eachesQuantity', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('leadTimeWeeks', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('minimumOrderQuantity', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('userSuppliedProductId', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('quantityPerPallet', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('palletsRounded', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('customerProductId', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productSku', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productionOrderLineId', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('', {
                dataType: ColumnDataType.String,
                visible: true
            }),
            createColumn('productStatus', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('moqFees', {
                dataType: ColumnDataType.String,
                visible: false
            })
        ];
    };
}
