import React from 'react';
import clsx from 'clsx';
import CheckIcon from '@material-ui/icons/Check';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { ballBlue, white, statusTrackerGray, blackWeight, medium, ballGray } from '../../../themes/globalConstants';

interface StatusTrackerMarker {
    icon?: boolean;
    active?: boolean;
    label?: React.ReactNode;
    lastMarker?: boolean;
};

interface Props extends StatusTrackerMarker {};

const useStyles = makeStyles((theme) => ({
    container: {
        textAlign: 'center'
    },
    indicator: {
        width: '1.125em',
        height: '1.125em',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
        marginTop: '0.25em',
        marginBottom: '0.25em',
        backgroundColor: white,
        '& svg': {
            width: '80%',
            height: '80%'
        }
    },
    label: {
        fontSize: medium,
        fontWeight: blackWeight,
        color: statusTrackerGray
    },
    activeBackground: {
        backgroundColor: ballBlue,
        width: '1.625em',
        height: '1.625em',
        marginTop: '0',
        marginBottom: '0.25em',
        '& svg': {
            fill: white
        }
    },
    active: {
        color: ballBlue
    },
    lastMarker: {
        border: `3px solid ${ballGray}`,
        width: '1.375em',
        height: '1.375em',
        marginTop: '0',
        marginBottom: '0.125em'
    }
}));

// eslint-disable-next-line @typescript-eslint/no-redeclare
const StatusTrackerMarker = ({ icon = false, active = false, label = ('default label'), lastMarker = false }: Props) => {
    const classes = useStyles();

    return (
        <Grid item className={classes.container}>
            <Typography 
                component="span" 
                className={(!lastMarker) ?
                    clsx(classes.indicator, {[classes.activeBackground] : active})
                    :
                    clsx(classes.indicator, {[classes.activeBackground] : active}, {[classes.lastMarker] : !active})
                }
            >
                {(icon) &&
                    <CheckIcon />
                }
            </Typography>
            <Typography className={clsx(classes.label, {[classes.active] : active})}>
                {label}
            </Typography>
        </Grid>
    );
};

export default StatusTrackerMarker;
