import {
    Container,
    FormHelperText,
    Grid,
    Hidden,
    Link,
    makeStyles,
    Paper,
    Typography
} from '@material-ui/core';
import React, { createRef, useEffect, useMemo, useState } from 'react';
import { Controller, ErrorMessage, FormContext, useForm, useFieldArray } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import {
    blackWeight,
    containerMaxWidth,
    ltBlueGrey_8,
    successGreen,
    xxl,
    ballGray,
    red,
    xl,
    boldWeight
} from '../../../themes/globalConstants';
import { Activity } from '../../../utility/auth/useSecurity';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import clsx from 'clsx';
import HoverNavMenu from '../../reusable/molecules/HoverNavMenu';
import TextInput from '../../reusable/molecules/TextInput';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { getAccountAddress } from '../../../utility/helpers/address-helpers';
import { Region } from '../../../store/reducers/graphic-intake';
import CountrySelect from '../../reusable/molecules/CountrySelect';
import StateProvinceSelect, {
    getStateProvinceList
} from '../../reusable/molecules/StateProvinceSelect';
import PhoneInput from '../../reusable/molecules/PhoneInput';
import { isValidEmail } from '../../../utility/helpers/user-helpers';
import { useDispatch } from 'react-redux';
import RadioInput from '../../reusable/molecules/RadioInput';
import SelectInput from '../../reusable/molecules/SelectInput';
import {
    customerTypeOptions,
    regions,
    specialtyPrintingOptions,
    specialtyTabOptions
} from '../../../static-data/dropdown-lists';
import Button from '../../reusable/atoms/Button';
import AddItemFormButton from '../../reusable/atoms/AddItemFormButton';
import MultiSelectInput from '../../reusable/molecules/MultiSelectInput';
import { freightManagementOptions } from './../../../static-data/dropdown-lists';
import Tooltip from '../../reusable/atoms/Tooltip';
import { Link as RouterLink } from 'react-router-dom';
import lodash from 'lodash';
import { resetShipToAddForm, reviewShipToAddForm } from '../../../store/actions/ship-to-locations';
import { ShipToLocationItem, ShipToLocationState } from '../../../store/reducers/ship-to-locations';
import { useQuery } from '../../../utility/helpers/query-helpers';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '3em'
    },
    formSection: {
        marginBottom: '2.5em',
        padding: '4em',
        boxShadow: `0 0 6px ${ltBlueGrey_8}`,
        borderRadius: 4,
        '& .sectionHeader': {
            fontSize: xxl,
            fontWeight: blackWeight,
            color: ballGray,
            paddingBottom: '2rem'
        },
        palletSpecs: {
            paddingTop: '8px'
        },
        '& .noPadding': {
            padding: 0
        },
        '& .lastRow': {
            paddingBottom: 0
        }
    },
    error: {
        color: theme.palette.error.main,
        marginTop: '0.625em'
    },
    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '0.5em'
    },
    paper: {
        boxShadow: `0 0 6px ${ltBlueGrey_8}`,
        borderRadius: 4,
        padding: '2em'
    },
    formNavContainer: {
        position: 'sticky',
        top: '5em',
        marginRight: '2em'
    },
    actionBtn: {
        height: '3.375em',
        width: '13.75em',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom',
        transition: 'all linear 150ms'
    },
    buttonContainer: {
        paddingBottom: '3em',
        maxWidth: containerMaxWidth
    },
    vendorButtons: {
        justifyContent: 'flex-end'
    },
    customerButtons: {
        justifyContent: 'flex-start'
    },
    completedIcon: {
        fill: successGreen,
        width: 23,
        height: 23
    },
    formSectionContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    errorText: {
        color: red
    },
    radioGroup: {
        marginTop: '1.5em',
        paddingLeft: '0.5em'
    },
    subsectionHeader: {
        fontSize: xl,
        fontWeight: boldWeight,
        color: ballGray,
        marginBottom: '0.5em',
        marginTop: '1em'
    },
    hidden: {
        visibility: 'hidden'
    }
}));

interface Params {
    billToId: string;
}

const ShipToLocationAdd = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const query = useQuery();
    const from = query.get('from');
    const fromReview = from === 'review';
    const classes = useStyles();
    const onSubmit = (data) => {};
    const { billToId } = useParams<Params>();
    const { adminAccounts, adminAccountsLoaded } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const form = useForm({ mode: 'onBlur', reValidateMode: 'onBlur' });
    const { newShipToLocation } = useTypedSelector<ShipToLocationState>(
        (state) => state.shipToLocations
    );
    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: 'productInformation.products'
    });
    const countryWatch = form.watch('newShipToInformation.country');
    const specialtyPrinting = form.watch('productInformation.specialtyPrinting') as string[];
    const specialtyTab = form.watch('productInformation.specialtyTab') as string[];
    const productType = form.watch('productInformation.productType') as 'cans' | 'ends' | 'both';
    const [countryCode, setCountryCode] = useState<string | undefined>();
    const [incompleteSections, setIncompleteSections] = useState<Set<string>>();
    const [continueDisabled, setContinueDisabled] = useState<boolean>(true);
    const icon = (
        <img
            className={classes.completedIcon}
            src={process.env.PUBLIC_URL + '/assets/Confirmation_icon.svg'}
            alt="Confirmation Icon"
        />
    );
    const trashIcon = process.env.PUBLIC_URL + '/assets/Trash.svg';

    const onCountryChange = (name, selectedCountry) => {
        form.setValue('newShipToInformation.stateProvince', null);
        setCountryCode(selectedCountry?.value?.toLowerCase());
    };

    const onDeleteRow = (idx: number) => {
        remove(idx);
    };

    const handleContinue = () => {
        if (form) {
            const items: ShipToLocationItem[] = [];
            fields.forEach((item, index) => {
                const graphicNumber = form.getValues(
                    `productInformation.products[${index}].graphicNumber`
                );
                const labelName = form.getValues(`productInformation.products[${index}].labelName`);
                if (graphicNumber.length && labelName.length) {
                    items.push({
                        itemID: graphicNumber,
                        itemName: labelName
                    });
                }
            });
            const specialtyTabValues = form.getValues('productInformation.specialtyTab');
            const specialtyPrintingValues = form.getValues('productInformation.specialtyPrinting');
            const newShipToLocation = {
                billToID: form.getValues('customerInformation.id'),
                newShipToName: form.getValues('newShipToInformation.name'),
                customerType: form.getValues('newShipToInformation.customerType'),
                newShipToAddress1: form.getValues('newShipToInformation.addressLine1'),
                newShipToAddress2: form.getValues('newShipToInformation.addressLine2'),
                newShipToAddress3: form.getValues('newShipToInformation.addressLine3'),
                newShipToCity: form.getValues('newShipToInformation.city'),
                newShipToStateProvince: form.getValues('newShipToInformation.stateProvince')?.value,
                newShipToPostalCode: form.getValues('newShipToInformation.postalCode'),
                newShipToCountry: form.getValues('newShipToInformation.country')?.value,
                annualVolume: form.getValues('newShipToInformation.estimatedAnnualVolume'),
                userAdminName: form.getValues('newShipToInformation.userAdminContact'),
                userAdminPhone: form.getValues('newShipToInformation.userAdminPhoneNumber'),
                userAdminEmail: form.getValues('newShipToInformation.userAdminEmail'),
                vatid: form.getValues('newShipToInformation.vat'),
                cfid: form.getValues('newShipToInformation.cfid'),
                taxID: form.getValues('newShipToInformation.taxId'),
                productSelection: form.getValues('productInformation.productType'),
                canFreight: form.getValues('freightInformation.canFreightManagement'),
                endFreight: form.getValues('freightInformation.endFreight'),
                specialEquipment: form.getValues('freightInformation.specialEquipment'),
                layersPerPallet: form.getValues('freightInformation.layersPerPallet'),
                items: items,

                // specialtyTab
                laserTab: specialtyTabValues.includes('laserTab'),
                punch: specialtyTabValues.includes('punch'),
                coloredTab: specialtyTabValues.includes('coloredTab'),
                coloredShell: specialtyTabValues.includes('coloredShell'),

                // specialtyPrinting
                basecoat: specialtyPrintingValues.includes('basecoat'),
                retort: specialtyPrintingValues.includes('retort'),
                tactile: specialtyPrintingValues.includes('tactile'),
                satin: specialtyPrintingValues.includes('satin')
            };
            dispatch(reviewShipToAddForm(newShipToLocation));
            history.push('/add-ship-to-review');
        }
    };

    useEffect(() => {
        if (!fromReview) {
            dispatch(resetShipToAddForm());
        }
    }, [fromReview]);

    useEffect(() => {
        if (fromReview) {
            // changing the country after the form has already been validated
            form.triggerValidation();
        }
    }, [countryWatch, fromReview]);

    useEffect(() => {
        setTimeout(() => {
            if (adminAccountsLoaded) {
                const matchingBillTo = adminAccounts?.find((acc) => acc.accountId === billToId);
                if (matchingBillTo) {
                    form.setValue(
                        'customerInformation',
                        {
                            name: matchingBillTo.name,
                            id: matchingBillTo.accountId,
                            address: getAccountAddress(matchingBillTo)
                        },
                        true
                    );
                }
            }
        });
    }, [adminAccountsLoaded]);

    useEffect(() => {
        append([{ graphicNumber: '', labelName: '' }]);
    }, []);

    useEffect(() => {
        //Handle the field array manually due to removed fields being retained in fieldsWithValidationRef
        const validationFields = Array.from(
            form.control.fieldsWithValidationRef.current.values()
        ).filter((field) => !field.startsWith('productInformation.products['));
        const validFields = Array.from(form.control.validFieldsRef.current.values());
        const invalidFields = lodash.difference(validationFields, validFields);
        const validProductFields = validFields.filter((field) =>
            field.startsWith('productInformation.products[')
        );
        const newIncompleteSections = new Set<string>(
            invalidFields.map((field) => field.split('.')[0])
        );
        if (validProductFields.length < fields.length * 2) {
            newIncompleteSections.add('productInformation');
        }
        if (
            newIncompleteSections.size !== incompleteSections?.size ||
            Array.from(newIncompleteSections).some((section) => !incompleteSections.has(section))
        ) {
            setIncompleteSections(newIncompleteSections);
        }
    }, [form.formState]);

    useEffect(() => {
        setContinueDisabled(incompleteSections ? incompleteSections.size > 0 : true);
    }, [incompleteSections]);

    useEffect(() => {
        if (newShipToLocation && fromReview) {
            setTimeout(() => {
                if (newShipToLocation.items.length > 1) {
                    for (let i = 1; i < newShipToLocation.items.length; i++) {
                        append([{ graphicNumber: '', labelName: '' }]);
                    }
                }
                const countryList = regions.find((r) => r.value === Region.NA)?.countries;
                const country = countryList?.find(
                    (c) => c.value === newShipToLocation.newShipToCountry
                );
                if (country) {
                    form.setValue('newShipToInformation.country', country);
                    const stateProvinceList = getStateProvinceList(country.value);
                    const stateProvince = stateProvinceList.find(
                        (state) => state.value === newShipToLocation.newShipToStateProvince
                    );
                    form.setValue('newShipToInformation.stateProvince', stateProvince);
                }

                form.setValue('newShipToInformation.name', newShipToLocation.newShipToName);
                form.setValue('newShipToInformation.customerType', newShipToLocation.customerType);
                form.setValue(
                    'newShipToInformation.addressLine1',
                    newShipToLocation.newShipToAddress1
                );
                form.setValue(
                    'newShipToInformation.addressLine2',
                    newShipToLocation.newShipToAddress2
                );
                form.setValue(
                    'newShipToInformation.addressLine3',
                    newShipToLocation.newShipToAddress3
                );
                form.setValue('newShipToInformation.city', newShipToLocation.newShipToCity);

                form.setValue(
                    'newShipToInformation.postalCode',
                    newShipToLocation.newShipToPostalCode
                );

                form.setValue(
                    'newShipToInformation.estimatedAnnualVolume',
                    newShipToLocation.annualVolume
                );
                form.setValue(
                    'newShipToInformation.userAdminContact',
                    newShipToLocation.userAdminName
                );
                form.setValue(
                    'newShipToInformation.userAdminPhoneNumber',
                    newShipToLocation.userAdminPhone
                );
                form.setValue(
                    'newShipToInformation.userAdminEmail',
                    newShipToLocation.userAdminEmail
                );
                form.setValue('newShipToInformation.vat', newShipToLocation.vatid);
                form.setValue('newShipToInformation.cfid', newShipToLocation.cfid);
                form.setValue('newShipToInformation.taxId', newShipToLocation.taxID);
                form.setValue('productInformation.productType', newShipToLocation.productSelection);
                form.setValue(
                    'freightInformation.canFreightManagement',
                    newShipToLocation.canFreight
                );
                form.setValue('freightInformation.endFreight', newShipToLocation.endFreight);
                form.setValue(
                    'freightInformation.specialEquipment',
                    newShipToLocation.specialEquipment
                );
                form.setValue(
                    'freightInformation.layersPerPallet',
                    newShipToLocation.layersPerPallet
                );

                newShipToLocation.items.forEach((item, index) => {
                    form.setValue(
                        `productInformation.products[${index}].graphicNumber`,
                        item.itemID
                    );
                });

                newShipToLocation.items.forEach((item, index) => {
                    form.setValue(`productInformation.products[${index}].labelName`, item.itemName);
                });

                const specialtyTabValues: string[] = [];
                if (newShipToLocation.laserTab) {
                    specialtyTabValues.push('laserTab');
                }
                if (newShipToLocation.punch) {
                    specialtyTabValues.push('punch');
                }
                if (newShipToLocation.coloredTab) {
                    specialtyTabValues.push('coloredTab');
                }
                if (newShipToLocation.coloredShell) {
                    specialtyTabValues.push('coloredShell');
                }
                form.setValue('productInformation.specialtyTab', specialtyTabValues);
                const specialtyPrintingValues: string[] = [];
                if (newShipToLocation.basecoat) {
                    specialtyPrintingValues.push('basecoat');
                }
                if (newShipToLocation.retort) {
                    specialtyPrintingValues.push('retort');
                }
                if (newShipToLocation.tactile) {
                    specialtyPrintingValues.push('tactile');
                }
                if (newShipToLocation.satin) {
                    specialtyPrintingValues.push('satin');
                }
                form.setValue('productInformation.specialtyPrinting', specialtyPrintingValues);
                form.triggerValidation();
            });
        }
    }, [newShipToLocation, fromReview]);

    const formSections = [
        {
            section: (
                <Grid container spacing={2} data-testid="customer-information">
                    <Grid item md={6} sm={12}>
                        <TextInput
                            type="text"
                            readOnly
                            label={<Trans i18nKey="customerName">Customer Name</Trans>}
                            testId="customerInformation.name"
                            control={form.control}
                            name="customerInformation.name"
                        ></TextInput>
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <TextInput
                            type="text"
                            readOnly
                            label={<Trans i18nKey="customerId">Customer ID</Trans>}
                            testId="customerInformation.id"
                            control={form.control}
                            name="customerInformation.id"
                        ></TextInput>
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            type="text"
                            readOnly
                            label={<Trans i18nKey="customerAddress">Customer Address</Trans>}
                            testId="customerInformation.address"
                            control={form.control}
                            name="customerInformation.address"
                        ></TextInput>
                    </Grid>
                </Grid>
            )
        },
        {
            section: (
                <Grid container spacing={5} data-testid="new-shipto-information">
                    <Grid item xs={12} container spacing={2}>
                        <Grid item md={6} sm={12}>
                            <TextInput
                                type="text"
                                label={<Trans i18nKey="shipToName">Ship To Name</Trans>}
                                testId="newShipToInformation.name"
                                control={form.control}
                                name="newShipToInformation.name"
                                rules={{ required: true }}
                                error={form.errors.newShipToInformation?.name}
                            ></TextInput>
                            <FormHelperText className={classes.errorText}>
                                {form.errors.newShipToInformation?.name && (
                                    <ErrorMessage
                                        errors={form.errors}
                                        name={'newShipToInformation.name'}
                                        message={
                                            <Typography>
                                                <Trans i18nKey="required">Required</Trans>
                                            </Typography>
                                        }
                                    />
                                )}
                            </FormHelperText>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <SelectInput
                                label={<Trans i18nKey="customerType">Customer Type</Trans>}
                                name="newShipToInformation.customerType"
                                testId="newShipToInformation.customerType"
                                control={form.control}
                                selectObj={customerTypeOptions}
                                rules={{
                                    required: true
                                }}
                                error={form.errors?.newShipToInformation?.customerType}
                            />
                            <FormHelperText className={classes.errorText}>
                                <ErrorMessage
                                    errors={form.errors}
                                    name={'newShipToInformation.customerType'}
                                    message={
                                        <Typography>
                                            <Trans i18nKey="required">Required</Trans>
                                        </Typography>
                                    }
                                />
                            </FormHelperText>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container spacing={2}>
                        <Grid item xs={12}>
                            <TextInput
                                type="text"
                                label={<Trans i18nKey="addressLine1">Address Line 1</Trans>}
                                testId="newShipToInformation.addressLine1"
                                control={form.control}
                                name="newShipToInformation.addressLine1"
                                rules={{ required: true }}
                                error={form.errors.newShipToInformation?.addressLine1}
                            ></TextInput>
                            <FormHelperText className={classes.errorText}>
                                {form.errors.newShipToInformation?.addressLine1 && (
                                    <ErrorMessage
                                        errors={form.errors}
                                        name={'newShipToInformation.addressLine1'}
                                        message={
                                            <Typography>
                                                <Trans i18nKey="required">Required</Trans>
                                            </Typography>
                                        }
                                    />
                                )}
                            </FormHelperText>
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput
                                type="text"
                                label={
                                    <Trans i18nKey="addressLine2">Address Line 2 (Optional)</Trans>
                                }
                                testId="newShipToInformation.addressLine2"
                                control={form.control}
                                name="newShipToInformation.addressLine2"
                            ></TextInput>
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput
                                type="text"
                                label={
                                    <Trans i18nKey="addressLine3">Address Line 3 (Optional)</Trans>
                                }
                                testId="newShipToInformation.addressLine3"
                                control={form.control}
                                name="newShipToInformation.addressLine3"
                            ></TextInput>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextInput
                                type="text"
                                label={<Trans i18nKey="city">City</Trans>}
                                testId="newShipToInformation.city"
                                control={form.control}
                                name="newShipToInformation.city"
                                rules={{ required: true }}
                                error={form.errors.newShipToInformation?.city}
                            ></TextInput>
                            <FormHelperText className={classes.errorText}>
                                {form.errors.newShipToInformation?.city && (
                                    <ErrorMessage
                                        errors={form.errors}
                                        name={'newShipToInformation.city'}
                                        message={
                                            <Typography>
                                                <Trans i18nKey="required">Required</Trans>
                                            </Typography>
                                        }
                                    />
                                )}
                            </FormHelperText>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <CountrySelect
                                name={`newShipToInformation.country`}
                                regionCode={Region.NA}
                                control={form.control}
                                rules={{ required: true }}
                                error={form.errors.newShipToInformation?.country}
                                errors={form.errors}
                                handleLoadDraftApproverCountry={onCountryChange}
                            />
                            <FormHelperText className={classes.errorText}>
                                {form.errors.newShipToInformation?.country && (
                                    <ErrorMessage
                                        errors={form.errors}
                                        name={'newShipToInformation.country'}
                                        message={
                                            <Typography>
                                                <Trans i18nKey="required">Required</Trans>
                                            </Typography>
                                        }
                                    />
                                )}
                            </FormHelperText>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <StateProvinceSelect
                                countryCode={countryWatch?.value}
                                disabled={!countryWatch}
                                control={form.control}
                                name="newShipToInformation.stateProvince"
                                rules={{ required: true }}
                                handleLoadStateProvince={() => {}}
                                error={form.errors.newShipToInformation?.stateProvince}
                                errors={form.errors}
                            />
                            <FormHelperText className={classes.errorText}>
                                {form.errors.newShipToInformation?.stateProvince && (
                                    <ErrorMessage
                                        errors={form.errors}
                                        name={'newShipToInformation.stateProvince'}
                                        message={
                                            <Typography>
                                                <Trans i18nKey="required">Required</Trans>
                                            </Typography>
                                        }
                                    />
                                )}
                            </FormHelperText>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextInput
                                type="text"
                                label={<Trans i18nKey="postalCode">Postal Code</Trans>}
                                testId="newShipToInformation.postalCode"
                                control={form.control}
                                name="newShipToInformation.postalCode"
                                rules={{ required: true }}
                                error={form.errors.newShipToInformation?.postalCode}
                            ></TextInput>
                            <FormHelperText className={classes.errorText}>
                                {form.errors.newShipToInformation?.postalCode && (
                                    <ErrorMessage
                                        errors={form.errors}
                                        name={'newShipToInformation.postalCode'}
                                        message={
                                            <Typography>
                                                <Trans i18nKey="required">Required</Trans>
                                            </Typography>
                                        }
                                    />
                                )}
                            </FormHelperText>
                        </Grid>
                    </Grid>
                    {(countryWatch?.value === 'CA' || countryWatch?.value === 'MX') && (
                        <Grid item xs={12} container spacing={2}>
                            {countryWatch?.value === 'MX' && (
                                <Grid item md={6} sm={12}>
                                    <TextInput
                                        type="text"
                                        label={<Trans i18nKey="cfid">CFID</Trans>}
                                        testId="newShipToInformation.cfid"
                                        control={form.control}
                                        name="newShipToInformation.cfid"
                                        rules={{ required: true }}
                                        error={form.errors.newShipToInformation?.cfid}
                                    ></TextInput>
                                    <FormHelperText className={classes.errorText}>
                                        {form.errors.newShipToInformation?.cfid && (
                                            <ErrorMessage
                                                errors={form.errors}
                                                name={'newShipToInformation.cfid'}
                                                message={
                                                    <Typography>
                                                        <Trans i18nKey="required">Required</Trans>
                                                    </Typography>
                                                }
                                            />
                                        )}
                                    </FormHelperText>
                                </Grid>
                            )}
                            {countryWatch?.value === 'MX' && (
                                <Grid item md={6} sm={12}>
                                    <TextInput
                                        type="text"
                                        label={<Trans i18nKey="taxId">Tax ID</Trans>}
                                        testId="newShipToInformation.taxId"
                                        control={form.control}
                                        name="newShipToInformation.taxId"
                                        rules={{ required: true }}
                                        error={form.errors.newShipToInformation?.taxId}
                                    ></TextInput>
                                    <FormHelperText className={classes.errorText}>
                                        {form.errors.newShipToInformation?.taxId && (
                                            <ErrorMessage
                                                errors={form.errors}
                                                name={'newShipToInformation.taxId'}
                                                message={
                                                    <Typography>
                                                        <Trans i18nKey="required">Required</Trans>
                                                    </Typography>
                                                }
                                            />
                                        )}
                                    </FormHelperText>
                                </Grid>
                            )}
                            <Grid item md={6} sm={12}>
                                <TextInput
                                    type="text"
                                    label={<Trans i18nKey="vat">VAT</Trans>}
                                    testId="newShipToInformation.vat"
                                    control={form.control}
                                    name="newShipToInformation.vat"
                                    rules={{ required: true }}
                                    error={form.errors.newShipToInformation?.vat}
                                ></TextInput>
                                <FormHelperText className={classes.errorText}>
                                    {form.errors.newShipToInformation?.vat && (
                                        <ErrorMessage
                                            errors={form.errors}
                                            name={'newShipToInformation.vat'}
                                            message={
                                                <Typography>
                                                    <Trans i18nKey="required">Required</Trans>
                                                </Typography>
                                            }
                                        />
                                    )}
                                </FormHelperText>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item xs={12} container spacing={2}>
                        <Grid item md={6} sm={12}>
                            <TextInput
                                type="text"
                                label={
                                    <Trans i18nKey="estimatedAnnualVolume">
                                        Estimated Annual Volume
                                    </Trans>
                                }
                                testId="newShipToInformation.estimatedAnnualVolume"
                                control={form.control}
                                name="newShipToInformation.estimatedAnnualVolume"
                                rules={{ required: true }}
                                error={form.errors.newShipToInformation?.estimatedAnnualVolume}
                            ></TextInput>
                            <FormHelperText className={classes.errorText}>
                                {form.errors.newShipToInformation?.estimatedAnnualVolume && (
                                    <ErrorMessage
                                        errors={form.errors}
                                        name={'newShipToInformation.estimatedAnnualVolume'}
                                        message={
                                            <Typography>
                                                <Trans i18nKey="required">Required</Trans>
                                            </Typography>
                                        }
                                    />
                                )}
                            </FormHelperText>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container spacing={2}>
                        <Grid item md={6} sm={12}>
                            <TextInput
                                type="text"
                                label={<Trans i18nKey="userAdminContact">User Admin Contact</Trans>}
                                testId="newShipToInformation.userAdminContact"
                                control={form.control}
                                name="newShipToInformation.userAdminContact"
                                rules={{ required: true }}
                                error={form.errors.newShipToInformation?.userAdminContact}
                            ></TextInput>
                            <FormHelperText className={classes.errorText}>
                                {form.errors.newShipToInformation?.userAdminContact && (
                                    <ErrorMessage
                                        errors={form.errors}
                                        name={'newShipToInformation.userAdminContact'}
                                        message={
                                            <Typography>
                                                <Trans i18nKey="required">Required</Trans>
                                            </Typography>
                                        }
                                    />
                                )}
                            </FormHelperText>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Controller
                                as={
                                    <PhoneInput
                                        label={
                                            <Trans i18nKey="userAdminPhoneNumber">
                                                User Admin Phone Number
                                            </Trans>
                                        }
                                        testId="registration-phone-input"
                                        disableAreaCodes={true}
                                        defaultCountry={countryCode ?? 'us'}
                                        defaultValue={form.getValues(
                                            'newShipToInformation.userAdminPhoneNumber'
                                        )}
                                    />
                                }
                                name="newShipToInformation.userAdminPhoneNumber"
                                control={form.control}
                                rules={{ required: true }}
                                errors={form.errors}
                                error={form.errors?.newShipToInformation?.userAdminPhoneNumber}
                            />
                            <FormHelperText className={classes.errorText}>
                                {form.errors.newShipToInformation?.userAdminPhoneNumber && (
                                    <ErrorMessage
                                        errors={form.errors}
                                        name={'newShipToInformation.userAdminPhoneNumber'}
                                        message={
                                            <Typography>
                                                <Trans i18nKey="required">Required</Trans>
                                            </Typography>
                                        }
                                    />
                                )}
                            </FormHelperText>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <TextInput
                                type="text"
                                label={<Trans i18nKey="userAdminEmail">User Admin Email</Trans>}
                                testId="email"
                                control={form.control}
                                name="newShipToInformation.userAdminEmail"
                                rules={{
                                    required: true,
                                    validate: {
                                        isValidEmail: (value) => {
                                            return (
                                                isValidEmail(value) || (
                                                    <Trans i18nKey="incorrectEmailAddress">
                                                        Invalid Email Format
                                                    </Trans>
                                                )
                                            );
                                        }
                                    }
                                }}
                                error={form.errors?.newShipToInformation?.userAdminEmail}
                            ></TextInput>
                            <FormHelperText className={classes.errorText}>
                                <ErrorMessage
                                    errors={form.errors}
                                    name={'newShipToInformation.userAdminEmail'}
                                    message={
                                        <Typography>
                                            <Trans i18nKey="required">Required</Trans>
                                        </Typography>
                                    }
                                />
                            </FormHelperText>
                        </Grid>
                    </Grid>
                </Grid>
            )
        },
        {
            section: (
                <Grid container spacing={2} data-testid="product-information">
                    <Grid item xs={12}>
                        <RadioInput
                            styles={classes.radioGroup}
                            label={<Trans i18nKey="selectProductsShort">Select Products:</Trans>}
                            name="productInformation.productType"
                            testId="productInformation.productType"
                            control={form.control}
                            radioControls={[
                                {
                                    value: 'cans',
                                    label: <Trans i18nKey="cansBottles">Cans/Bottles</Trans>
                                },
                                { value: 'ends', label: <Trans i18nKey="ends">Ends</Trans> },
                                { value: 'both', label: <Trans i18nKey="both">Both</Trans> }
                            ]}
                            defaultValue="cans"
                            sideLabel
                            small
                            error={form.errors?.productInformation?.productType}
                        />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <MultiSelectInput
                            label={<Trans i18nKey="specialtyPrinting">Specialty Printing</Trans>}
                            name="productInformation.specialtyPrinting"
                            testId="productInformation.specialtyPrinting"
                            control={form.control}
                            selectObj={specialtyPrintingOptions}
                            selected={specialtyPrinting}
                        />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <MultiSelectInput
                            label={<Trans i18nKey="specialtyTab">Specialty Tab</Trans>}
                            name="productInformation.specialtyTab"
                            testId="productInformation.specialtyTab"
                            control={form.control}
                            selectObj={specialtyTabOptions}
                            selected={specialtyTab}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.subsectionHeader}>
                            {(t('anticipatedItems'), 'Anticipated Items')}
                        </Typography>
                    </Grid>
                    <Grid item container spacing={2} justify="flex-end">
                        {fields.map((item, index) => (
                            <Grid
                                item
                                container
                                xs={12}
                                alignItems="center"
                                spacing={2}
                                key={index}
                                className={`productRow ${
                                    index === fields.length - 1 ? 'lastRow' : ''
                                }`}
                            >
                                <Grid item xs="auto">
                                    <Typography>{index + 1}.</Typography>
                                </Grid>
                                <Grid item md sm={12}>
                                    <TextInput
                                        label={
                                            <Trans i18nKey="ballItemNumberOrGraphicNumber">
                                                Ball Item Number/Graphic Number
                                            </Trans>
                                        }
                                        type="text"
                                        name={`productInformation.products[${index}].graphicNumber`}
                                        testId={`productInformation.products[${index}].graphicNumber`}
                                        control={form.control}
                                        defaultValue={item.graphicNumber}
                                        rules={{ required: true }}
                                        error={
                                            form.errors.productInformation?.products?.[`${index}`]
                                                ?.graphicNumber
                                        }
                                    />
                                    <FormHelperText className={classes.errorText}>
                                        {form.errors.productInformation?.products?.[`${index}`]
                                            ?.graphicNumber && (
                                            <ErrorMessage
                                                errors={form.errors}
                                                name={`productInformation.products[${index}].graphicNumber`}
                                                message={
                                                    <Typography>
                                                        <Trans i18nKey="required">Required</Trans>
                                                    </Typography>
                                                }
                                            />
                                        )}
                                    </FormHelperText>
                                </Grid>
                                <Grid item md sm={12}>
                                    <TextInput
                                        label={<Trans i18nKey="labelName">Label Name</Trans>}
                                        type="text"
                                        name={`productInformation.products[${index}].labelName`}
                                        testId={`productInformation.products[${index}].labelName`}
                                        control={form.control}
                                        defaultValue={item.labelName}
                                        rules={{ required: true }}
                                        error={
                                            form.errors.productInformation?.products?.[`${index}`]
                                                ?.labelName
                                        }
                                    />
                                    <FormHelperText className={classes.errorText}>
                                        {form.errors.productInformation?.products?.[`${index}`]
                                            ?.labelName && (
                                            <ErrorMessage
                                                errors={form.errors}
                                                name={`productInformation.products[${index}].labelName`}
                                                message={
                                                    <Typography>
                                                        <Trans i18nKey="required">Required</Trans>
                                                    </Typography>
                                                }
                                            />
                                        )}
                                    </FormHelperText>
                                </Grid>
                                <Grid item xs="auto">
                                    <Button
                                        type="button"
                                        onClick={() => onDeleteRow(index)}
                                        data-testid="delete-file-btn"
                                        className={index === 0 ? classes.hidden : ''}
                                    >
                                        <img
                                            src={trashIcon}
                                            alt="Trash Icon"
                                            data-testid={'delete-file'}
                                        />
                                    </Button>
                                </Grid>
                            </Grid>
                        ))}
                        <Grid item xs={12} container direction="row-reverse" className="noPadding">
                            <AddItemFormButton
                                onClick={() => append({ graphicNumber: '', labelName: '' })}
                                text={t('addItem', 'Add Item')}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )
        },
        {
            section: (
                <Grid container spacing={2} data-testid="freight-information">
                    <Grid item container xs={12} spacing={2}>
                        {(productType === 'cans' || productType === 'both') && (
                            <Grid item md={6} sm={12}>
                                <SelectInput
                                    label={
                                        <Trans i18nKey="canFreightManagement">
                                            Can Freight Management
                                        </Trans>
                                    }
                                    name="freightInformation.canFreightManagement"
                                    testId="freightInformation.canFreightManagement"
                                    control={form.control}
                                    selectObj={freightManagementOptions}
                                    rules={{
                                        required: productType === 'cans' || productType === 'both'
                                    }}
                                    error={form.errors?.freightInformation?.canFreightManagement}
                                />
                                <FormHelperText className={classes.errorText}>
                                    <ErrorMessage
                                        errors={form.errors}
                                        name={'freightInformation.canFreightManagement'}
                                        message={
                                            <Typography>
                                                <Trans i18nKey="required">Required</Trans>
                                            </Typography>
                                        }
                                    />
                                </FormHelperText>
                            </Grid>
                        )}
                        {(productType === 'ends' || productType === 'both') && (
                            <Grid item md={6} sm={12}>
                                <SelectInput
                                    label={<Trans i18nKey="endFreight">End Freight</Trans>}
                                    name="freightInformation.endFreight"
                                    testId="freightInformation.endFreight"
                                    control={form.control}
                                    selectObj={freightManagementOptions}
                                    rules={{
                                        required: productType === 'ends' || productType === 'both'
                                    }}
                                    error={form.errors?.freightInformation?.endFreight}
                                />
                                <FormHelperText className={classes.errorText}>
                                    <ErrorMessage
                                        errors={form.errors}
                                        name={'freightInformation.endFreight'}
                                        message={
                                            <Typography>
                                                <Trans i18nKey="required">Required</Trans>
                                            </Typography>
                                        }
                                    />
                                </FormHelperText>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item container xs={12} spacing={2}>
                        <Grid item md={6} sm={12}>
                            <TextInput
                                type="text"
                                label={
                                    <Grid container>
                                        <Typography>
                                            <Trans i18nKey="specialEquipmentOptional">
                                                Special Equipment (Optional)
                                            </Trans>
                                        </Typography>
                                        <Tooltip
                                            content={
                                                <Typography>
                                                    <Trans i18nKey="specialEquipmentTooltip">
                                                        Roller bed truck, Pallet jack, etc.
                                                    </Trans>
                                                </Typography>
                                            }
                                            ariaLabel={t(
                                                'specialEquipmentTooltip',
                                                'Roller bed truck, Pallet jack, etcetera'
                                            )}
                                        />
                                    </Grid>
                                }
                                testId="freightInformation.specialEquipment"
                                control={form.control}
                                name="freightInformation.specialEquipment"
                            ></TextInput>
                        </Grid>
                        <Grid item container md={6} sm={12}>
                            <TextInput
                                type="text"
                                label={<Trans i18nKey="layersPerPallet">Layers per Pallet</Trans>}
                                testId="freightInformation.layersPerPallet"
                                control={form.control}
                                name="freightInformation.layersPerPallet"
                                rules={{ required: true }}
                                error={form.errors.freightInformation?.layersPerPallet}
                            ></TextInput>
                            <FormHelperText className={classes.errorText}>
                                <ErrorMessage
                                    errors={form.errors}
                                    name={'freightInformation.layersPerPallet'}
                                    message={
                                        <Typography>
                                            <Trans i18nKey="required">Required</Trans>
                                        </Typography>
                                    }
                                />
                            </FormHelperText>
                            <Grid item xs={12} container justify="flex-end" className="palletSpecs">
                                <Typography>
                                    <Link
                                        component={RouterLink}
                                        underline="none"
                                        target="_blank"
                                        to="/content/NA/Pages/Can-Specifications"
                                    >
                                        <Trans i18nKey="canSpecifications">
                                            Can Specifications
                                        </Trans>
                                    </Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )
        }
    ];

    const sections = useMemo(
        () => [
            {
                header: t('customerInformation', 'Customer Information'),
                icon: !incompleteSections?.has('customerInformation')
            },
            {
                header: t('newShipToInformation', 'New Ship To Information'),
                icon: !incompleteSections?.has('newShipToInformation')
            },
            {
                header: t('productInformation', 'Product Information'),
                icon: !incompleteSections?.has('productInformation')
            },
            {
                header: t('freightInformation', 'Freight Information'),
                icon: !incompleteSections?.has('freightInformation')
            }
        ],
        [t, incompleteSections]
    );

    const sectionRefs = sections.map(() => createRef<HTMLElement>());
    const header = <Trans i18nKey="formOverview">Form Overview</Trans>;

    const footerActions = (
        <>
            <Grid container item xs={3} justify="space-between">
                {/* To be implemented in future story */}
                {/* <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="cancel-button"
                    onClick={() => {}}
                    className={classes.actionBtn}
                    disabled={true}
                >
                    <Trans i18nKey="cancel">Cancel</Trans>
                </Button>

                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="draft-button"
                    onClick={() => {}}
                    className={classes.actionBtn}
                    disabled={true}
                >
                    <Trans i18nKey="saveProgress">Save Progress</Trans>
                </Button> */}
            </Grid>

            <Grid container item xs={2}>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    data-testid="submit-button"
                    className={classes.actionBtn}
                    disabled={continueDisabled}
                    onClick={handleContinue}
                >
                    <Trans i18nKey="continue">Continue</Trans>
                </Button>
            </Grid>
        </>
    );

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('addShipTo', 'Add Ship To'),
                description: '',
                thinBanner: true
            }}
            activity={Activity.AddShipTo}
            loading={false}
            actionFooter={{
                footerAction: footerActions,
                justify: 'space-between',
                sticky: true
            }}
        >
            <FormContext {...form}>
                <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    autoComplete="off"
                    data-testid="add-shipto-form"
                >
                    <Grid container data-testid="add-shipto-page">
                        <Container className={classes.container}>
                            <Grid container item xs={12}>
                                <Grid item sm={4}>
                                    <Hidden xsDown>
                                        <Paper
                                            className={clsx(
                                                classes.formNavContainer,
                                                classes.paper
                                            )}
                                        >
                                            <HoverNavMenu
                                                translatedHeader={header}
                                                readOnly={false}
                                                sections={sections
                                                    .map((section, index) => ({
                                                        ref: sectionRefs[index],
                                                        header: section.header,
                                                        icon: section.icon,
                                                        iconSrc: icon
                                                    }))
                                                    .filter((section) => section.header)}
                                            />
                                        </Paper>
                                    </Hidden>
                                </Grid>
                                <Grid
                                    container
                                    item
                                    xs={8}
                                    className={classes.formSectionContainer}
                                >
                                    {formSections.map((form, index) => {
                                        return (
                                            <section ref={sectionRefs[index]} key={index}>
                                                <Grid item xs={12}>
                                                    <Paper className={clsx(classes.formSection)}>
                                                        <Typography className="sectionHeader">
                                                            {sections[index].header}
                                                        </Typography>
                                                        {form.section}
                                                    </Paper>
                                                </Grid>
                                            </section>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        </Container>
                    </Grid>
                </form>
            </FormContext>
        </ProcessingPageTemplate>
    );
};

export default ShipToLocationAdd;
