import { ShipToRoleAccess } from '../../components/reusable/molecules/LocationRoleAccessTable/models/role-access.model';
import {
    REQUEST_ACCESS_SET_ACCOUNTS,
    REQUEST_ACCESS_SET_COMMENT,
    REQUEST_ACCESS_SUBMIT_REQUEST_SUCCESS,
    REQUEST_ACCESS_LOADING,
    REQUEST_ACCESS_SUBMIT_REQUEST_ERROR,
    REQUEST_ACCESS_CLEARED
} from './action-types';
import UserService from '../../utility/services/user-service';
import { PermissionChangeItem } from '../reducers/user-admin';

export const setRequestAccounts = (accounts: ShipToRoleAccess[]) => {
    return (dispatch) => {
        dispatch({
            type: REQUEST_ACCESS_SET_ACCOUNTS,
            accounts
        });
    };
};

export const clearRequestAccounts = () => {
    return (dispatch) => {
        dispatch({
            type: REQUEST_ACCESS_CLEARED
        });
    };
};

export const setRequestComment = (comment: string) => {
    return (dispatch) => {
        dispatch({
            type: REQUEST_ACCESS_SET_COMMENT,
            comment
        });
    };
};

export const requestUserPermissions = (
    userEmail: string,
    permissions: PermissionChangeItem[],
    onSuccess: () => void,
    onError: () => void,
    comments?: string
) => {
    return (dispatch, getState) => {
        dispatch({ type: REQUEST_ACCESS_LOADING });
        const accessToken = getState().auth.accessToken;

        UserService.submitAccessRequest(accessToken, userEmail, permissions, comments)
            .then((response) => {
                if (response) {
                    dispatch({ type: REQUEST_ACCESS_SUBMIT_REQUEST_SUCCESS });
                    onSuccess();
                } else {
                    dispatch({ type: REQUEST_ACCESS_SUBMIT_REQUEST_ERROR });
                    onError();
                }
            })
            .catch((error) => {
                dispatch({ type: REQUEST_ACCESS_SUBMIT_REQUEST_ERROR });
                onError();
            });
    };
};
