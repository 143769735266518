import * as types from './action-types';
import { UserPermission } from '../reducers/auth';
import UserService from '../../utility/services/user-service';
import { getAxiosConfig } from '../../utility/helpers/axios-helpers';
import { clearState } from '../../utility/helpers/sessionStorage';

export const setAuth = (
    authenticated: boolean,
    userInfo: UserInfo | null,
    accessToken: string
) => ({
    type: types.SET_AUTH,
    authenticated: authenticated,
    userInfo: userInfo,
    accessToken: accessToken
});

export const setAccessToken = (accessToken: string) => ({
    type: types.SET_AUTH_TOKEN,
    accessToken: accessToken
});

export const login = (authService: AuthService) => {
    return () => {
        authService.login('/');
        return Promise.resolve();
    };
};

export const logout = (authService: AuthService, accessToken?: string) => {
    clearState();
    return (dispatch: any) => {
        if (accessToken) UserService.blacklistAuthToken(accessToken);
        authService.logout('/source');
        return dispatch(setAuth(false, null, ''));
    };
};

export const getPermissions = (userInfo: UserInfo | null, authService: AuthService) => {
    return (dispatch: any, getState: any) => {
        const config = getAxiosConfig(getState);

        if (userInfo) {
            UserService.getPermissions(userInfo, config).then((response) => {
                const permissions = response.data.permissions;
                const isNotActive = response.data.isActive === false ? true : false;
                if (isNotActive) {
                    localStorage.setItem('inactive', 'true');
                    authService.logout('/source');
                    return dispatch(setAuth(false, null, ''));
                }
                return dispatch(setPermissions(permissions));
            });
        }
    };
};

export const setPermissions = (permissions: UserPermission[]) => ({
    type: types.SET_PERMISSIONS,
    permissions: permissions
});
