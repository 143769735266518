import React from 'react';
import { makeStyles, TableRow, TableCell, Grid, Typography, Button } from '@material-ui/core';
import { ballBlue, blackWeight, boldWeight, medium, red } from '../../../../themes/globalConstants';
import { PendingRequestTableRow } from '../Models/PendingRequestTableRow';
import { Trans } from 'react-i18next';
import { resubmitRequest, cancelRequest } from '../../../../store/actions/user-admin';
import { useDispatch } from 'react-redux';

interface Props {
    request: PendingRequestTableRow;
    key: number;
    handleRequestSubmittedSuccess: () => void;
}

const useStyles = makeStyles((theme) => ({
    name: {
        fontWeight: boldWeight,
        fontSize: medium,
        letterSpacing: 0.17
    },
    resubmitButton: {
        fontSize: '1em',
        '&:hover': {
            backgroundColor: 'transparent',
            color: ballBlue,
            fontWeight: blackWeight,
            outline: 'none'
        },
        '&:focus': {
            backgroundColor: 'transparent',
            color: ballBlue,
            fontWeight: blackWeight
        }
    },
    cancelRequestButton: {
        color: red,
        fontSize: '1em',
        '&:hover': {
            backgroundColor: 'transparent',
            color: red,
            fontWeight: blackWeight,
            outline: 'none'
        },
        '&:focus': {
            backgroundColor: 'transparent',
            color: red,
            fontWeight: blackWeight
        }
    }
}));

export default function MyAccountPendingRequestsRow({
    request,
    key,
    handleRequestSubmittedSuccess
}: Props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const onResubmit = () => {
        dispatch(
            resubmitRequest(request.requestId, () => {
                handleRequestSubmittedSuccess();
            })
        );
    };

    const onCancel = () => {
        dispatch(cancelRequest(request.requestId));
    };

    const actions = () => {
        return (
            <TableCell>
                <Button
                    variant="text"
                    color="primary"
                    data-testid="resubmit-button"
                    className={classes.resubmitButton}
                    onClick={onResubmit}
                >
                    <Trans i18nKey="resubmit">Resubmit</Trans>
                </Button>
                {' | '}
                <Button
                    type="button"
                    color="primary"
                    variant="text"
                    data-testid="cancel-request-btn"
                    className={classes.cancelRequestButton}
                    onClick={onCancel}
                >
                    <Trans i18nKey="cancelRequest">Cancel Request</Trans>
                </Button>
            </TableCell>
        );
    };

    return (
        <TableRow hover={true} key={key}>
            <TableCell data-testid="location">
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        <Typography>{request.location}</Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell data-testid="roles">
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        <Typography>{request.roles}</Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell data-testid="dateSubmitted">
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        <Typography>{request.dateSubmitted}</Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell data-testid="latestSubmissionDate">
                <Grid container>
                    <Grid item xs={12} className={classes.name}>
                        <Typography>{request.latestSubmissionDate}</Typography>
                    </Grid>
                </Grid>
            </TableCell>
            {actions()}
        </TableRow>
    );
}
