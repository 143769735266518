import ConfirmationModal from './ConfirmationModal';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { Region } from '../../../store/reducers/graphic-intake';

interface Props {
    deleteGraphic: () => void;
    onClose: () => void;
    open: boolean;
    navigationLink?: string;
    closeModalBtn?: boolean;
}

const DeleteGraphicModal = ({
    deleteGraphic,
    open,
    navigationLink,
    onClose,
    closeModalBtn
}: Props) => {
    const { regionCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const [deleteYourDraftKey, setDeleteYourDraftKey] = useState<string>('deleteYourDraft');
    const [graphicDeleteConfirmationKey, setGraphicDeleteConfirmationKey] = useState<string>(
        'graphicDeleteConfirmation'
    );

    useEffect(() => {
        const region = regionCode === Region.EU ? 'Emea' : '';
        setDeleteYourDraftKey(`deleteYourDraft${region}`);
        setGraphicDeleteConfirmationKey(`graphicDeleteConfirmation${region}`);
    }, [regionCode]);

    return (
        <ConfirmationModal
            title={<Trans i18nKey={deleteYourDraftKey}>Delete Your Draft Graphic</Trans>}
            open={open}
            logo={false}
            subheader={
                <Trans i18nKey={graphicDeleteConfirmationKey}>
                    Are you sure you want to delete this draft graphic intake form?
                </Trans>
            }
            description={
                <Trans i18nKey="graphicPermantlyGone">The form will be permanently deleted.</Trans>
            }
            cancelText={<Trans i18nKey="noCancel">No, Cancel</Trans>}
            continueText={<Trans i18nKey="yesDelete">Yes, Delete</Trans>}
            saveProgress={deleteGraphic}
            navigationLink={navigationLink}
            onClose={onClose}
            onCancel={onClose}
        />
    );
};

export default DeleteGraphicModal;
