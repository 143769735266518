import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'proxy-polyfill/proxy.min.js';
import ProxyPolyfillBuilder from 'proxy-polyfill/src/proxy';

import 'fast-text-encoding';
import 'webcrypto-shim';

import 'react-dates/initialize';
import '@formatjs/intl-pluralrules/polyfill-locales';
import '@formatjs/intl-getcanonicallocales/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import { store } from './store/root-store';
import { setAxiosDefaults } from './utility/helpers/axios-helpers';
import setAxiosInterceptors from './utility/helpers/axios-interceptors';

import './index.css';
import getAppInsights from './utility/AppInsights';

if (!window.TextEncoder) {
    window.TextEncoder = TextEncoder;
}

if (!window.TextDecoder) {
    window.TextDecoder = TextDecoder;
}

if (!window.Proxy) {
    const proxyPolyfill = ProxyPolyfillBuilder();
    window.Proxy = new proxyPolyfill();
}

setAxiosDefaults();
const shouldImplementAxiosCaching: boolean =
    process.env.REACT_APP_CLIENT_NETWORK_CACHING?.toString() === 'true';
if (shouldImplementAxiosCaching) setAxiosInterceptors();
getAppInsights();

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);
