import React, { useState, useEffect } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import {
    ballBlue,
    blackWeight,
    boldWeight,
    containerMaxWidth,
    small,
    warningBorder
} from '../../../themes/globalConstants';
import { useHistory } from 'react-router-dom';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { Activity } from '../../../utility/auth/useSecurity';
import { Trans, useTranslation } from 'react-i18next';
import { FormattedAccount } from '../MakeItBulkReviewATM/models/FormattedAccount';
import ShipToDropdown from '../MakeItBulkReviewATM/components/ShipToDropdown';
import { Account, CustomerContextState } from '../../../store/reducers/customer-context';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { PricingBreakdownState, PricingLine } from '../../../store/reducers/pricing-breakdown';
import PricingBreakdownGrid from './components/PricingBreakdownGrid';
import { AuthState } from '../../../store/reducers/auth';
import { useDispatch } from 'react-redux';
import { loadPricingData } from '../../../store/actions/pricing-breakdown';
import { PRICING_BREAKDOWN_RESET } from '../../../store/actions/action-types';
import { formatDate } from '../../../utility/helpers/date-helpers';
import moment from 'moment';
import { selectHasPricingBillTo, selectHasAdminBillTo } from '../../../store/selectors';

const useStyles = makeStyles((theme) => ({
    actionBtn: {
        borderRadius: 'unset'
    },
    summarySection: {
        flexWrap: 'nowrap'
    },
    subheaderPaper: {
        padding: '1em',
        borderRadius: 2,
        maxWidth: containerMaxWidth
    },
    dateSelectSection: {
        paddingTop: '1em'
    },
    viewAtmTitle: {
        fontWeight: blackWeight,
        color: theme.palette.secondary.main,
        textTransform: 'capitalize',
        margin: '1em 0'
    },
    btn: {
        padding: 0,
        minWidth: 'auto',
        fontSize: small,
        fontWeight: boldWeight,
        margin: '0 0.5em'
    },
    expandCollapseWrapper: {
        alignItems: 'center',
        color: ballBlue
    },
    selector: {
        display: 'flex',
        marginTop: '1em'
    },
    shareLinksContainer: {
        marginBottom: '1em'
    },
    disclaimer: {
        display: 'flex',
        margin: '20px 100px 20px 10px',
        textAlign: 'center',
        width: '95%',
        borderRadius: '.125em',
        justifyContent: 'center'
    },
    disclaimerIcon: {
        '& .MuiAlert-icon': {
            color: warningBorder,
            display: 'flex',
            alignItems: 'center'
        }
    }
}));

export default function PricingBreakdown() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();

    const { accounts } = useTypedSelector<CustomerContextState>((state) => state.customerContext);
    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);

    const { loading, pricingData, pricingShipTos, pricingDataLoaded } =
        useTypedSelector<PricingBreakdownState>((state) => state.pricingBreakdown);

    const [shipToAccountsList, setShipToAccountsList] = useState<FormattedAccount[]>([]);
    const [selectedShipTo, setSelectedShipTo] = useState<string>();
    const [selectedShipToAccount, setSelectedShipToAccount] = useState<Account>();
    const [selectedBillToAccount, setSelectedBillToAccount] = useState<Account>();
    const [selectedPricingData, setSelectedPricingData] = useState<PricingLine[]>([]);
    const [effectiveDate, setEffectiveDate] = useState<string>('');

    const isPricingCustomer = useTypedSelector<boolean>(selectHasPricingBillTo);
    const isBillToAdmin = useTypedSelector<boolean>(selectHasAdminBillTo);

    useEffect(() => {
        if (accounts && permissions && accounts.length > 0 && !pricingDataLoaded) {
            if (isPricingCustomer || isBillToAdmin) {
                dispatch(loadPricingData(accounts));
            } else {
                history.push('/unauthorized', { activity: Activity.PricingBreakdown });
            }
        }
    }, [accounts, permissions]);

    useEffect(() => {
        if (shipToAccountsList.length && !selectedShipTo) {
            setSelectedShipTo(shipToAccountsList[0].account.accountId);
        }
    }, [shipToAccountsList]);

    useEffect(() => {
        if (pricingShipTos.length) {
            setShipToAccountsList(
                pricingShipTos.map((account) => {
                    return {
                        account: account,
                        state: 'valid'
                    } as FormattedAccount;
                })
            );
        }
    }, [pricingShipTos]);

    useEffect(() => {
        // Change data in pricing table when selectedShipTo changes
        if (selectedShipTo) {
            setSelectedPricingData(pricingData[selectedShipTo] ?? []);
            if (pricingData[selectedShipTo]) {
                setEffectiveDate(
                    formatDate(pricingData[selectedShipTo][0]?.effectiveDate ?? moment().toString())
                );
            }

            const shipTo = pricingShipTos.find((account) => account.accountId === selectedShipTo);

            setSelectedShipToAccount(
                pricingShipTos.find((account) => account.accountId === selectedShipTo)
            );

            setSelectedBillToAccount(shipTo?.parentAccount ?? shipTo);
        }
    }, [selectedShipTo]);

    useEffect(() => {
        return () => {
            // clear state on page leave
            if (!history.location.pathname.includes('pricing')) {
                dispatch({ type: PRICING_BREAKDOWN_RESET });
            }
        };
    }, []);

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('pricingBreakdown', 'Pricing Breakdown'),
                description: '',
                thinBanner: true,
                displayDropdown: true,
                completeDropdown: true
            }}
            activity={Activity.PricingBreakdown}
            loading={loading}
        >
            <Grid container data-testid="pricing-breakdown">
                <Grid container item xs={4}>
                    <Grid item>
                        <Typography variant="subtitle2" className={classes.viewAtmTitle}>
                            {t('shipTo', 'Ship To').toUpperCase()}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item xs={8} />
                <Grid container item xs={4} data-testid="ship-to-selector">
                    <ShipToDropdown
                        name="shipToSelect"
                        testId="shipToSelect"
                        selectObjects={shipToAccountsList}
                        selected={selectedShipTo}
                        updateSelected={setSelectedShipTo}
                        showIcons={false}
                    />
                </Grid>
                <Grid container item xs={8} />
                {selectedShipToAccount && selectedBillToAccount && (
                    <>
                        <PricingBreakdownGrid
                            items={selectedPricingData}
                            selectedBillTo={selectedBillToAccount}
                            selectedShipTo={selectedShipToAccount}
                            currency={selectedPricingData[0]?.currency ?? 'USD'}
                            effectiveDate={effectiveDate}
                        />
                        <Grid className={classes.disclaimer}>
                            <img
                                src={process.env.PUBLIC_URL + '/assets/Warning_Icon.svg'}
                                alt="Warning Icon"
                                className={classes.disclaimerIcon}
                            />
                            <Typography>
                                <Trans i18nKey="pricingLetterDisclaimer">
                                    This price sheet serves as notice of new pricing effective{' '}
                                    {{ effectiveDate }} and is not a guarantee of current or future
                                    supply. Your submission of an order to purchase products
                                    constitutes your acceptance of this updated pricing under the
                                    current terms of sale.
                                </Trans>
                            </Typography>
                        </Grid>
                    </>
                )}
            </Grid>
        </ProcessingPageTemplate>
    );
}
