import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import {
    Button,
    CircularProgress,
    Container,
    Grid,
    makeStyles,
    Typography
} from '@material-ui/core';
import { Activity } from '../../../utility/auth/useSecurity';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { AuthState } from '../../../store/reducers/auth';
import { TemplateMapping } from '../../../store/reducers/bulk-upload';
import { useTypedSelector } from '../../../store/reducers/reducer';
import {
    createMakeItBulkUploadATMTemplate,
    loadBulkUploadMakeIt,
    loadBulkUploadMakeItATM,
    loadMakeItBulkProducts,
    refreshMakeItBulkState,
    removeMakeItBulkOrder,
    resetMakeItBulkState,
    resetTemplateError,
    reviewMakeItBulkUpload,
    reviewMakeItBulkUploadATM,
    updateMakeItBulkUploadATMTemplate,
    uploadMakeItFile
} from '../../../store/root-actions';
import { getShipToAccounts } from '../../../store/actions/customer-context';
import ConfirmationModal from '../../reusable/molecules/ConfirmationModal';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { BulkUploadMakeItState } from '../../../store/reducers/makeit-bulk-upload';
import { OrderType, RowsOrColumns } from '../../../utility/services/orders-service';
import BulkUploadMappingTemplate from '../../reusable/molecules/BulkUploadMappingTemplate';
import UploadMakeItOrder from './components/UploadMakeItOrder';
import { selectIsLargeCustomerAccount } from '../../../store/selectors';
import { enUS } from '../../../utility/translations/locales';
import { BulkUploadMakeItATMState } from '../../../store/reducers/makeit-bulk-atm';
import { BulkUploadFieldDefinition } from '../DeliveryBulkUpload/DeliveryBulkUpload';
import ATMBulkUploadMappingTemplate from '../../reusable/molecules/ATMBulkUploadMappingTemplate';
import { FormContext, useForm } from 'react-hook-form';
import moment from 'moment';
import { useQuery } from '../../../utility/helpers/query-helpers';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '3em',
        display: 'flex',
        justifyContent: 'center'
    },
    formSection: {
        marginBottom: '2.5em',
        flex: '1 1 auto'
    },
    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '0.5em'
    },
    actionBtn: {
        height: '3.375em',
        width: '13.75em',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom'
    },
    formSectionContainer: {
        display: 'flex',
        flexDirection: 'column'
    }
}));

export default function MakeItBulkUpload() {
    const query = useQuery();
    const from = query.get('from');
    const [fromReview, setFromReview] = useState<boolean>(from === 'review');
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const { userInfo } = useTypedSelector<AuthState>((state) => state.auth);
    const { templateMappings, file, loading, loaded, products, error, orders, lineErrors } =
        useTypedSelector<BulkUploadMakeItState>((state) => state.bulkUploadMakeItState);
    const {
        atmTemplateMappings,
        atmOrders,
        atmLineErrors,
        rowsOrColumns,
        quantityType,
        templateError,
        weekQuantities,
        firstRowOfData,
        weeks
    } = useTypedSelector<BulkUploadMakeItATMState>((state) => state.bulkUploadMakeItATMState);
    const { accounts, shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [templateData, setTemplateData] = useState<TemplateMapping[]>([]);
    const [isValidFile, setIsValidFile] = useState<boolean>(false);
    const [hasTemplate, setHasTemplate] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(hasTemplate);
    const [openCancel, setOpenCancel] = useState<boolean>(false);
    const [displayServerError, setDisplayServerError] = useState<boolean>(false);
    const [initialFieldDefinitionsSet, setInitialFieldDefinitionsSet] = useState<boolean>(false);
    const [fieldDefinitions, setFieldDefinitions] = useState<BulkUploadFieldDefinition>();
    const isLargeCustomer = useTypedSelector<boolean>(selectIsLargeCustomerAccount);

    const methods = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur'
    });

    const onSubmit = () => {
        setFromReview(false);
        if (rowsOrColumns === RowsOrColumns.Columns) {
            methods.triggerValidation().then(() => {
                if (!methods.errors.weekDate) {
                    const weeks = extractDatesFromFormFields();
                    handleFileProcessingColumns(weeks);
                }
            });
        } else {
            handleFileProcessing();
        }
    };

    // very tightly coupled with how we pull in the form data but not sure how else to handle bc of limitations of react-hook-form
    const extractDatesFromFormFields = (): moment.Moment[] => {
        const formData = methods.getValues();
        const weeks: moment.Moment[] = [];
        let index = 0;
        while (true) {
            const currentWeek = formData[`weekDate[${index++}].date`];
            if (currentWeek) {
                weeks.push(currentWeek);
            } else {
                break;
            }
        }
        return weeks;
    };

    const handleFileProcessing = () => {
        if (file && userInfo) {
            isLargeCustomer
                ? dispatch(reviewMakeItBulkUploadATM(userInfo!.preferred_username, file!))
                : dispatch(reviewMakeItBulkUpload(userInfo!.preferred_username, file!));
            setIsSubmitting(true);
        }
    };

    const handleFileProcessingColumns = (weeks: moment.Moment[]) => {
        if (file && userInfo) {
            dispatch(reviewMakeItBulkUploadATM(userInfo!.preferred_username, file!, weeks));
            setIsSubmitting(true);
        }
    };

    const onOpenCancelModal = () => {
        setOpenCancel(true);
    };

    const onCancel = () => {
        setOpenCancel(false);
        dispatch(resetMakeItBulkState());
        if (isLargeCustomer) {
            history.push('/');
        } else {
            history.push('/make-it');
        }
    };

    const onClose = () => {
        setOpenCancel(false);
    };

    const setEditState = useCallback((edit: boolean) => {
        setIsEdit(edit);
    }, []);

    const updateFieldDefinitions = (rowsOrColumns: RowsOrColumns) => {
        if (rowsOrColumns === RowsOrColumns.Columns) {
            setFieldDefinitions({
                shipToId: { defaultLabel: 'Ship To ID', defaultValue: '', required: true },
                productId: { defaultLabel: 'Product ID', defaultValue: '', required: true }
            });
        } else {
            setFieldDefinitions({
                shipToId: { defaultLabel: 'Ship To ID', defaultValue: '', required: true },
                productId: { defaultLabel: 'Product ID', defaultValue: '', required: true },
                requestedDate: {
                    defaultLabel: 'Requested Date',
                    defaultValue: '',
                    required: true
                },
                quantity: {
                    defaultLabel: enUS.quantity,
                    defaultValue: '',
                    required: true,
                    translationValue: 'quantity'
                }
            });
        }
    };

    useEffect(() => {
        if (shipToAccounts && shipToAccounts.length) {
            if (!templateMappings && !atmTemplateMappings && userInfo) {
                isLargeCustomer
                    ? dispatch(loadBulkUploadMakeItATM(userInfo.preferred_username))
                    : dispatch(loadBulkUploadMakeIt(userInfo.preferred_username));
            }
            if (templateMappings) {
                setHasTemplate(templateMappings.length > 0);
                setTemplateData(templateMappings);
            }
            if (atmTemplateMappings) {
                setHasTemplate(atmTemplateMappings.length > 0);
                setTemplateData(atmTemplateMappings);
            }
        }
    }, [
        dispatch,
        templateMappings,
        userInfo,
        shipToAccounts,
        atmTemplateMappings,
        isLargeCustomer
    ]);

    useEffect(() => {
        if (shipToAccounts && shipToAccounts.length > 0 && !initialFieldDefinitionsSet) {
            if (isLargeCustomer) {
                const defaultRowsOrColumns = rowsOrColumns ?? RowsOrColumns.Rows;
                updateFieldDefinitions(defaultRowsOrColumns);
            } else if (!isLargeCustomer) {
                setFieldDefinitions({
                    shipToId: { defaultLabel: 'Ship To ID', defaultValue: '', required: true },
                    productionOrderNumber: {
                        defaultLabel: 'Customer PO #',
                        defaultValue: '',
                        required: true,
                        translationValue: 'customerPoNumber'
                    },
                    productId: { defaultLabel: 'Product ID', defaultValue: '', required: true },
                    requestedDate: {
                        defaultLabel: 'Requested Date',
                        defaultValue: '',
                        required: true
                    },
                    quantity: {
                        defaultLabel: enUS.quantity,
                        defaultValue: '',
                        required: true,
                        translationValue: 'quantity'
                    }
                });
            }
            setInitialFieldDefinitionsSet(true);
        }
    }, [
        shipToAccounts,
        atmTemplateMappings,
        initialFieldDefinitionsSet,
        isLargeCustomer,
        rowsOrColumns
    ]);

    useEffect(() => {
        if (shipToAccounts && shipToAccounts.length > 0 && !loading && !loaded) {
            const shipToIds = shipToAccounts.map((account) => {
                return account.accountId;
            });
            dispatch(loadMakeItBulkProducts(shipToIds, true, false, false));
        }
    }, [dispatch, loaded, loading, shipToAccounts]);

    useEffect(() => {
        if (!isLargeCustomer && orders && (!lineErrors || lineErrors.length === 0) && !fromReview) {
            history.push('/make-it?from=bulk');
        }
        setIsSubmitting(false);
        // removed isSubmitting because lineErrors while only get trigger while upload process failed
    }, [orders, lineErrors, isLargeCustomer, history]);

    useEffect(() => {
        if (
            isLargeCustomer &&
            atmOrders &&
            (!atmLineErrors || atmLineErrors.length === 0) &&
            !fromReview
        ) {
            history.push('/make-it-bulk-upload-po-review?from=bulk');
        }
        setIsSubmitting(false);
    }, [atmOrders, atmLineErrors, isLargeCustomer, history]);

    useEffect(() => {
        if (permissions && !accounts?.length) {
            dispatch(getShipToAccounts());
        }
    }, [dispatch, accounts, permissions]);

    useEffect(() => {
        if (error) {
            setIsSubmitting(false);
            setDisplayServerError(true);
        } else {
            setDisplayServerError(false);
        }
    }, [error]);

    //always remove orders in state on first load, if they exist
    useEffect(() => {
        if (isLargeCustomer) {
            dispatch(refreshMakeItBulkState());
        }

        if (orders && orders.length) {
            dispatch(removeMakeItBulkOrder());
        }
        //always remove orders in state on first load, if they exist
    }, []);

    const footerActions = (
        <>
            <Grid item xs={2}>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="bottom-cancel-button"
                    onClick={onOpenCancelModal}
                    className={classes.actionBtn}
                >
                    <Trans i18nKey="cancel">Cancel</Trans>
                </Button>
            </Grid>
            <Grid item xs={2}>
                <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    data-testid="upload-button"
                    className={classes.actionBtn}
                    onClick={onSubmit}
                    disabled={!hasTemplate || !isValidFile || isSubmitting || !products}
                >
                    <Trans i18nKey="uploadFile">Upload File</Trans>
                </Button>
            </Grid>
        </>
    );

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('makeIt', 'Make It'),
                description: t('bulkUpload', 'Bulk Upload').toUpperCase(),
                thinBanner: true,
                displayDropdown: false
            }}
            actionFooter={{
                footerAction: footerActions,
                justify: 'space-between',
                sticky: true
            }}
            activity={Activity.MakeItBulkUpload}
            shipTos={false}
            loading={loading}
        >
            <Grid container justify="space-between" data-testid="make-it-bulk-upload-page">
                <Container className={classes.container}>
                    <Grid container item xs={8} className={classes.formSectionContainer}>
                        <Grid
                            item
                            xs={12}
                            className={classes.formSection}
                            data-testid="make-it-mapping-template"
                        >
                            <Grid item xs={12} className={classes.formSection}>
                                {fieldDefinitions && !isLargeCustomer && (
                                    <BulkUploadMappingTemplate
                                        hasTemplate={hasTemplate}
                                        templateData={templateData}
                                        templateType={OrderType.Production}
                                        fieldDefinitions={fieldDefinitions}
                                        isEdit={isEdit}
                                        setIsEdit={setEditState}
                                    />
                                )}
                                {fieldDefinitions && isLargeCustomer && (
                                    <ATMBulkUploadMappingTemplate
                                        hasTemplate={hasTemplate}
                                        templateData={templateData}
                                        fieldDefinitions={fieldDefinitions}
                                        isEdit={isEdit}
                                        setIsEdit={setEditState}
                                        updateFieldDefinitions={updateFieldDefinitions}
                                        onSubmitCreateMapping={createMakeItBulkUploadATMTemplate}
                                        onSubmitUpdateMapping={updateMakeItBulkUploadATMTemplate}
                                        createMappingText={t(
                                            'mapMakeItTemplateValues',
                                            enUS.mapMakeItTemplateValues
                                        )}
                                        updateMappingText={t(
                                            'updateMakeItTemplateValues',
                                            enUS.updateMakeItTemplateValues
                                        )}
                                        quantityType={quantityType}
                                        rowsOrColumns={rowsOrColumns}
                                        weekQuantities={weekQuantities}
                                        templateError={templateError}
                                        firstRowOfData={firstRowOfData}
                                        onResetTemplateErrors={resetTemplateError}
                                    />
                                )}
                            </Grid>
                        </Grid>
                        <FormContext {...methods}>
                            <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
                                <Grid data-testid="make-it-file-upload">
                                    <Grid>
                                        <UploadMakeItOrder
                                            isEdit={isEdit}
                                            setIsValidFile={setIsValidFile}
                                            headerText={t(
                                                'uploadMakeItOrder',
                                                enUS.uploadMakeItOrder
                                            )}
                                            onFileLoad={uploadMakeItFile}
                                            file={file}
                                            lineErrors={atmLineErrors || lineErrors}
                                            products={products}
                                            rowsOrColumns={rowsOrColumns}
                                            weekQuantities={weekQuantities}
                                            weeks={weeks}
                                        />
                                    </Grid>
                                </Grid>
                            </form>
                        </FormContext>
                    </Grid>
                </Container>
                {displayServerError && (
                    <Grid container item xs={12} justify="center">
                        <Typography>
                            <Trans i18nKey="pleaseReviewCsvData">
                                There has been an error with processing the CSV data. Please double
                                check the column types are set correctly.
                            </Trans>
                        </Typography>
                    </Grid>
                )}
                {isSubmitting && (
                    <Grid container item xs={12} className={classes.spinningLoader}>
                        <CircularProgress />
                    </Grid>
                )}
            </Grid>
            <ConfirmationModal
                saveProgress={onCancel}
                onClose={onClose}
                logo={false}
                title={<Trans i18nKey="cancelSubmission">Cancel Submissions</Trans>}
                subheader={<Trans i18nKey="confirmCancel">Are you sure you want to cancel?</Trans>}
                description={
                    <Trans i18nKey="lostFile">
                        Any file uploaded or unsaved mappings will be permanently gone.
                    </Trans>
                }
                continueText={<Trans i18nKey="yesCancel">Yes, Cancel</Trans>}
                cancelText={<Trans i18nKey="no">No</Trans>}
                open={openCancel}
                navigationLink=""
                onCancel={onClose}
            />
        </ProcessingPageTemplate>
    );
}
