import Axios from 'axios';
import { Graphics, GraphicsState, SubmissionType } from '../../store/reducers/graphic-intake';
import { createAPIQueryParam } from '../helpers/query-helpers';

interface GraphicLookup {
    Username?: string[];
    AccountIds?: string[];
}

export default class GraphicsService {
    static getGraphicsByStateAndShipTo = (
        request: GraphicLookup,
        accessToken: string,
        regionCode: string
    ) => {
        let queryString = createAPIQueryParam(request);
        return Axios.get<Graphics[]>(
            `${process.env.REACT_APP_GRAPHICS_API}/graphics${queryString}`,
            GraphicsService.createConfigWithRegion(accessToken, regionCode)
        );
    };

    static deleteGraphics = (graphicsId: string, accessToken: string) => {
        return Axios.delete(
            `${process.env.REACT_APP_GRAPHICS_API}/graphics/${graphicsId}`,
            GraphicsService.createConfig(accessToken)
        );
    };

    static saveDraftGraphicsForm = (form: Graphics, accessToken: string) => {
        return GraphicsService.saveGraphicsForm(
            form,
            accessToken,
            GraphicsState.Draft,
            SubmissionType.Save
        );
    };

    static submitGraphicsForm = (form: Graphics, accessToken: string) => {
        return GraphicsService.saveGraphicsForm(
            form,
            accessToken,
            GraphicsState.InProgress,
            SubmissionType.Submit
        );
    };

    static saveGraphicsForm = (
        form: Graphics,
        accessToken: string,
        formState: GraphicsState,
        type: SubmissionType
    ) => {
        const axiosConfig = GraphicsService.createConfig(accessToken);
        form.state = formState;
        form.type = type;
        if (form.graphicsId) {
            // update existing record
            return Axios.put(
                `${process.env.REACT_APP_GRAPHICS_API}/graphics/${form.graphicsId}`,
                form,
                axiosConfig
            );
        } else {
            // create new record
            return Axios.post(`${process.env.REACT_APP_GRAPHICS_API}/graphics`, form, axiosConfig);
        }
    };

    static submitGraphicsImage = (
        graphicId: string,
        files: File[] = [],
        accessToken: string,
        filesToDelete: string[] = []
    ) => {
        const formData = new FormData();
        files.forEach((file) => {
            formData.append('Image', file, file.name);
        });
        filesToDelete.forEach((fileToDelete) => formData.append('removedFiles', fileToDelete));
        return Axios.post(
            `${process.env.REACT_APP_GRAPHICS_API}/graphics/${graphicId}/image`,
            formData,
            GraphicsService.createMultipartConfig(accessToken)
        );
    };

    static getGraphicData = (state: any, id: string) => {
        return Axios.get<Graphics>(
            `${process.env.REACT_APP_GRAPHICS_API}/graphics/${id}`,
            GraphicsService.createConfig(state.auth.accessToken)
        );
    };

    private static createConfig = (accessToken: string) => ({
        headers: { Authorization: `Bearer ${accessToken}` }
    });

    private static createConfigWithRegion = (accessToken: string, regionCode: string) => ({
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Accept-Region': `${regionCode}`
        }
    });

    private static createMultipartConfig = (accessToken: string) => ({
        headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'multipart/form-data' }
    });
}
