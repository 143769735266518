import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { UserPermissionChange } from '../../../../store/reducers/user-admin';
import { blackWeight, ltBlueGrey_8, xxl } from '../../../../themes/globalConstants';
import { formatForUsersTimezone } from '../../../../utility/helpers/date-helpers';
import { enUS } from '../../../../utility/translations/locales';

interface Props {
    userInformation: UserPermissionChange;
}

const useStyles = makeStyles((theme) => ({
    paper: {
        boxShadow: `0 3px 6px 0 ${ltBlueGrey_8}`,
        borderRadius: 4
    },
    main: {
        padding: '4em',
        marginTop: 0
    },
    sectionHeader: {
        fontSize: xxl,
        letterSpacing: '0.21px',
        color: theme.palette.secondary.main,
        fontWeight: blackWeight,
        marginBottom: '1em'
    },
    userName: {
        textTransform: 'lowercase'
    },
    tableHeader: {
        color: theme.palette.secondary.main
    }
}));

const ReviewRequestUserInformation = ({ userInformation }: Props) => {
    const classes = useStyles();
    useTranslation();

    return (
        <Paper className={classes.paper}>
            <Grid container data-testid="user-information-section" className={classes.main}>
                <Grid item xs={12}>
                    <Typography className={classes.sectionHeader}>
                        <Trans i18nKey="userInformation">${enUS.userInformation}</Trans>
                    </Typography>
                </Grid>
                <Grid container item xs={12}>
                    <Grid item xs={3}>
                        <Typography variant="subtitle2" className={classes.tableHeader}>
                            <Trans i18nKey="email">Email</Trans>
                        </Typography>
                        <Typography
                            variant="body1"
                            className={classes.userName}
                            data-testid="user-email"
                        >
                            {userInformation.userName}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="subtitle2" className={classes.tableHeader}>
                            <Trans i18nKey="name">Name</Trans>
                        </Typography>
                        <Typography variant="body1" data-testid="user-full-name">
                            {userInformation.firstName} {userInformation.lastName}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="subtitle2" className={classes.tableHeader}>
                            <Trans i18nKey="dateSubmitted">Date Submitted</Trans>
                        </Typography>
                        <Typography variant="body1" data-testid="date-submitted">
                            {userInformation.lastSubmittedDate
                                ? formatForUsersTimezone(
                                      new Date(userInformation.lastSubmittedDate + 'Z')
                                  )
                                : ''}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};
export default ReviewRequestUserInformation;
