import React from 'react';
import { makeStyles, Typography, TableRow, TableCell, Grid } from '@material-ui/core';
import { ltBlueGrey_34, medium } from '../../../../themes/globalConstants';
import {
    getGraphicIdAndVersion,
    formatStatus,
    orderStatusCodeFormatter
} from '../../../../utility/helpers/order-helpers';
import { useTranslation } from 'react-i18next';
import { MakeItBulkATMLineItem } from '../../../../store/reducers/makeit-bulk-atm';
import { formatNumberWithLocale } from '../../../../utility/helpers/formatters';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { ProductStatus } from '../../../../utility/services/orders-service';

interface Props {
    line: MakeItBulkATMLineItem;
}

const useStyles = makeStyles((theme) => ({
    name: {
        fontSize: medium,
        letterSpacing: 0.17
    },
    standardRow: {
        '& > td:nth-child(n+4)': {
            textAlign: 'right'
        }
    },
    altRow: {
        backgroundColor: ltBlueGrey_34,
        '& > td': {
            borderBottomColor: 'white'
        }
    },
    display: {
        display: 'table-row'
    },
    borderlessCell: {
        borderLeft: 'unset'
    },
    activeCell: {
        backgroundColor: ltBlueGrey_34
    },
    activeRow: {
        boxShadow:
            '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 4px 3px 0px rgba(0,0,0,0.12)'
    },
    cancelledCell: {
        color: 'red'
    }
}));

const PlanItOrderDetailsLineRow = ({ line }: Props) => {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { t } = useTranslation();

    const getEaches = (line: MakeItBulkATMLineItem) => {
        if (line.quantityPerPallet && line.palletQuantity) {
            let eaches = line.quantityPerPallet * line.palletQuantity;
            return formatNumberWithLocale(cultureCode, eaches) + ' ea.';
        } else {
            return '0 ea.';
        }
    };

    const getLineStatusValue = (line: MakeItBulkATMLineItem): JSX.Element | undefined => {
        const lineStatus = orderStatusCodeFormatter(line.status);
        const lineStatusDisplayed = lineStatus ? t(lineStatus, formatStatus(line.status)) : '';
        return (
            <>
                <span className={line.status === 'CANC' ? classes.cancelledCell : ''}>
                    {lineStatusDisplayed}
                </span>
                {line.productStatus === ProductStatus.Inactive ? (
                    <span>
                        {' '}
                        - <span className={classes.cancelledCell}>{t('inactive', 'Inactive')}</span>
                    </span>
                ) : (
                    ''
                )}
            </>
        );
    };

    return (
        <>
            <TableRow hover={true} key={line.productionOrderLineId}>
                <TableCell>
                    <Grid container>
                        <Grid item xs={10} className={classes.name}>
                            <Typography>
                                <strong>
                                    {line.customerProductId ? line.customerProductId : '-'}
                                </strong>
                            </Typography>
                            <Typography
                                key={'product-id-' + line.productionOrderLineId}
                                data-testid="display-id"
                            >
                                <strong>{line.displayId}</strong>
                            </Typography>
                            <Typography
                                key={'graphic-id-' + line.productionOrderLineId}
                                data-testid="graphic-id"
                            >
                                {getGraphicIdAndVersion(line.productType, line.productGraphicId)}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid container>
                        <Grid item xs={12} className={classes.name}>
                            <Typography
                                key={'product-desc-' + line.productionOrderLineId}
                                data-testid="display-name"
                            >
                                {line.displayName}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid container>
                        <Grid item xs={12} className={classes.name}>
                            <Typography
                                key={'status-' + line.customerProductStatus}
                                data-testid="status"
                            >
                                {line.customerProductStatus}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid container>
                        <Grid item xs={12} className={classes.name}>
                            <Typography
                                key={'coating-' + line.productionOrderLineId}
                                data-testid="coating"
                            >
                                {line.productCoating}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid container>
                        <Grid item xs={12} className={classes.name}>
                            <Typography
                                key={'product-quantity-' + line.productionOrderLineId}
                                data-testid="quantity"
                            >
                                {line.palletQuantity &&
                                    formatNumberWithLocale(cultureCode, line.palletQuantity)}{' '}
                                PL
                            </Typography>
                            <Typography
                                key={'product-eaches-' + line.productionOrderLineId}
                                data-testid="eaches"
                            >
                                {getEaches(line)}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Grid container>
                        <Grid item xs={12} className={classes.name}>
                            <Typography style={{ display: 'inline-block' }}>
                                {getLineStatusValue(line)}
                                {line.customerProductStatus === 'INACTIVE' ? ' - ' : ''}
                            </Typography>
                            <Typography
                                style={{ display: 'inline-block' }}
                                key={'status-' + line.productionOrderLineId}
                                data-testid="status"
                                className={
                                    line.customerProductStatus === 'INACTIVE'
                                        ? classes.cancelledCell
                                        : ''
                                }
                            >
                                {line.customerProductStatus === 'INACTIVE' ? ' Inactive' : ''}
                            </Typography>
                        </Grid>
                    </Grid>
                </TableCell>
            </TableRow>
        </>
    );
};

export default PlanItOrderDetailsLineRow;
