import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Moment } from 'moment';
import React from 'react';
import {
    addFormItemButtonBlue,
    ballGray,
    ballLtGray,
    blackWeight,
    white
} from '../../../../themes/globalConstants';
import clsx from 'clsx';

export type AllocationState = Array<'MET' | 'UNDER' | 'OVER' | 'NONE'>;
interface Props {
    date: Moment;
    isSelected: boolean;
    handleClick: (week: Moment) => void;
    allocationState: AllocationState | [];
}

const useStyles = makeStyles((theme) => ({
    root: {
        color: ballGray,
        width: '75px',
        backgroundColor: white,
        display: 'flex',
        justifyContent: 'center',
        padding: '0.5em',
        border: `1px solid ${ballLtGray}`,
        alignItems: 'top',
        gap: '8px',
        cursor: 'pointer',
        '&:nth-of-type(n + 2)': {
            borderLeft: 'none'
        },
        '&:hover': {
            color: addFormItemButtonBlue,
            boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.2)',
            zIndex: 10,
            '& *': {
                fontWeight: blackWeight
            }
        }
    },
    selected: {
        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.2)',
        color: addFormItemButtonBlue,
        zIndex: 10
    },
    blackWeight: {
        fontWeight: blackWeight
    },
    date: {
        width: '45px',
        textAlign: 'center',
        '& h3': {
            fontSize: '16px',
            marginBottom: '4px'
        },
        '& h4': {
            fontSize: '14px'
        }
    },
    iconContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '2px',
        gap: '0.25em'
    },
    metIcon: {
        margin: 'auto 0',
        alignSelf: 'center'
    }
}));

const MakeItSpotWeekButton = ({ date, isSelected, handleClick, allocationState }: Props) => {
    const classes = useStyles();
    // should be met iff met is true and met is the only value
    const isMet = allocationState.find((a) => a === 'MET') && allocationState.length === 1;

    const hasUnder = allocationState.find((a) => a === 'UNDER');
    const hasOver = allocationState.find((a) => a === 'OVER');
    const hasNone = allocationState.find((a) => a === 'NONE');
    return (
        <Grid
            className={clsx(classes.root, { [classes.selected]: isSelected })}
            onClick={() => handleClick(date)}
        >
            <Grid className={classes.iconContainer}>
                {hasOver && (
                    <img
                        src={process.env.PUBLIC_URL + '/assets/Allocation_Over.svg'}
                        alt="Supply Plan Over"
                    />
                )}
                {hasUnder && (
                    <img
                        src={process.env.PUBLIC_URL + '/assets/Allocation_Under.svg'}
                        alt="Supply Plan Under"
                    />
                )}
                {isMet && (
                    <Grid className={classes.metIcon}>
                        <img
                            src={process.env.PUBLIC_URL + '/assets/Allocation_Met.svg'}
                            alt="Supply Plan Met"
                        />
                    </Grid>
                )}
                {hasNone && (
                    <img
                        src={process.env.PUBLIC_URL + '/assets/Allocation_None.svg'}
                        alt="No Supply Plan"
                    />
                )}
            </Grid>
            <Grid className={classes.date}>
                <Typography variant="h3" className={clsx({ [classes.blackWeight]: isSelected })}>
                    {date.format('MM/DD')}
                </Typography>
                <Typography variant="h4" className={clsx({ [classes.blackWeight]: isSelected })}>
                    {date.format('YYYY')}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default MakeItSpotWeekButton;
