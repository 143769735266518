import { TableRow, TableCell, Typography, Grid, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
    activateProduct,
    deactivateProduct,
    updateProductInformation
} from '../../../../store/actions/manage-products';
import { OrderProductTableRow } from '../../../../store/reducers/orders-dashboard';
import { UpdateProductInformationRequest } from '../../../../utility/services/orders-service';
import EditProductModal from '../../../reusable/molecules/EditProductModal';
import ManageProductsContextMenu from './ManageProductsContextMenu';
import StatusLabel from './StatusLabel';
import DeactivateProductModal from './../../../reusable/molecules/DeactivateProductModal';
import ActivateProductModal from '../../../reusable/molecules/ActivateProductModal';
import { yellowNoticeBackground } from '../../../../themes/globalConstants';

interface Props {
    line: OrderProductTableRow;
    lockPeriodWeeks: number;
    tab?: String;
}

const useStyles = makeStyles((theme) => ({
    productDetailCell: {
        width: '8em'
    },
    actionCell: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    clockIcon: {
        width: '8%',
        marginRight: '3.75%',
        marginTop: '1.5%',
        float: 'left',
        color: yellowNoticeBackground
    }
}));

const ManageProductsRow = ({ tab, line, lockPeriodWeeks }: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [editMode, setEditMode] = useState<undefined | 'edit' | 'activate' | 'deactivate'>(
        undefined
    );
    const handleActionMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleRequestActivation = () => {
        setEditMode('activate');
        setAnchorEl(null);
    };
    const handleRequestDeactivation = () => {
        setEditMode('deactivate');
        setAnchorEl(null);
    };
    const handleEditProductInfo = () => {
        setEditMode('edit');
        setAnchorEl(null);
    };
    const handleMenuBlur = () => {
        setAnchorEl(null);
    };

    const onCloseEdit = (
        editSubmitted: boolean,
        productUpdates?: UpdateProductInformationRequest
    ) => {
        setEditMode(undefined);
        if (editSubmitted) {
            dispatch(updateProductInformation(productUpdates!));
        }
    };

    const onCloseDeactivate = (deactivate: boolean) => {
        setEditMode(undefined);
        if (deactivate) {
            dispatch(deactivateProduct(line));
        }
    };

    const onCloseActivate = (projectedOrderDate?: moment.Moment) => {
        setEditMode(undefined);
        if (!!projectedOrderDate) {
            dispatch(activateProduct(line, projectedOrderDate));
        }
    };

    return (
        <>
            <TableRow hover={true}>
                <TableCell data-testid={'ball-product-id'} className={classes.productDetailCell}>
                    <Typography display="block">
                        <strong>{line.customerProductId ?? '-'}</strong>
                    </Typography>
                    <Typography display="block">
                        <strong>{line.productSku ?? '-'}</strong>
                    </Typography>
                    <div>
                        {line.hasMatchingGraphicId && tab === 'Inactive' && (
                            <img
                                data-testid="info-icon-yellow"
                                className={classes.clockIcon}
                                src={process.env.PUBLIC_URL + '/assets/Info_Icon_yellow.svg'}
                                alt="Graphic ID assigned"
                            />
                        )}
                        <Typography>{line.graphicIdAndVersion || line.graphicId || '-'}</Typography>
                    </div>
                </TableCell>
                <TableCell data-testid={'description'}>
                    <Typography data-testid="display-name" display="block">
                        <strong>{line.displayName}</strong>
                    </Typography>
                    <Typography>{line.description}</Typography>
                </TableCell>
                <TableCell data-testid={'coating'}>
                    <Typography>{line.coating}</Typography>
                </TableCell>
                <TableCell data-testid={'lead-time'}>
                    <Typography>{line.leadTimeString}</Typography>
                </TableCell>
                {lockPeriodWeeks > 0 && (
                    <TableCell data-testid={'lock-period-weeks'}>
                        <Typography>
                            {lockPeriodWeeks} <Trans i18nKey="weeks">Weeks</Trans>
                        </Typography>
                    </TableCell>
                )}

                <TableCell data-testid={'status'}>
                    <StatusLabel line={line} />
                </TableCell>
                <TableCell
                    data-testid={'actions'}
                    onClick={handleActionMenuClick}
                    className={classes.actionCell}
                >
                    <Grid container justify="center">
                        <img
                            src={process.env.PUBLIC_URL + '/assets/ellipsis.svg'}
                            alt="Actions menu"
                        />
                    </Grid>
                </TableCell>
            </TableRow>
            <ManageProductsContextMenu
                onRequestActivation={handleRequestActivation}
                onRequestDeactivation={handleRequestDeactivation}
                onEditProductInfo={handleEditProductInfo}
                onMenuBlur={handleMenuBlur}
                anchorEl={anchorEl}
                statusChanges={line.statusChanges}
            />
            {editMode === 'edit' && (
                <EditProductModal product={line} onClose={onCloseEdit}></EditProductModal>
            )}
            {editMode === 'deactivate' && (
                <DeactivateProductModal
                    product={line}
                    onClose={onCloseDeactivate}
                ></DeactivateProductModal>
            )}
            {editMode === 'activate' && (
                <ActivateProductModal
                    product={line}
                    onClose={onCloseActivate}
                ></ActivateProductModal>
            )}
        </>
    );
};

export default ManageProductsRow;
