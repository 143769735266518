import {
    SHIPPING_DASHBOARD_LOADING,
    SHIPPING_DASHBOARD_LOADED,
    SHIPPING_DASHBOARD_LOADING_ERROR,
    SHIPPING_DASHBOARD_UPDATE_VIEW,
    SHIPPING_DASHBOARD_VIEW_ERROR,
    SHIPPING_DASHBOARD_UPDATE_RANGE,
    SHIPPING_DASHBOARD_NO_PRODUCTS,
    SHIPPING_ORDER_LOADING,
    SHIPPING_ORDER_LOADED,
    SHIPPING_ORDER_LOADING_ERROR,
    SHIPPING_ORDER_PRODUCTS_UPDATE,
    SHIPPING_DASHBOARD_UPDATE_DELIVERY_DATE,
    SHIPPING_ORDER_SHIPMENTS_UPDATE,
    SHIPPING_DASHBOARD_UPDATE_RESULTS,
    SHIPPING_DASHBOARD_UPDATE_PALLETS,
    SHIPPING_UPDATE_DELIVERY_ORDER,
    SHIPPING_DASHBOARD_RESET_STATE,
    SHIPPING_DASHBOARD_CONFIG_FLAG,
    SHIPPING_DASHBOARD_EXPANDABLE_LOAD_CHANGE,
    SHIPPING_DASHBOARD_UPDATE_QUANTITY_UNIT,
    SHIPPING_DASHBOARD_RESET_PRODUCT_STATE
} from '../actions/action-types';
import { OrderProduct } from './orders-dashboard';
import { Moment } from 'moment';

export interface DeliveryTime {
    time: moment.Moment;
    label: string;
}

export enum ShipmentEditStatus {
    Unedited = 'Unedited',
    Edited = 'Edited',
    Cancelled = 'Cancelled'
}

export enum ReviewStatus {
    New = 'New',
    Approved = 'Approved',
    Rejected = 'Rejected',
    Unknown = 'Unknown'
}

export enum ReviewedOrderStatus {
    UnderReview = 'Edit Under Review',
    OpenEditReviewed = 'Open (Edit Reviewed)',
    OpenEditRejected = 'Open (Edit Rejected)'
}

export interface DeliveryShipment {
    loads?: ProductToShip[];
    palletCapacity?: number;
    palletCount?: number;
    shipmentType?: string;
    shipmentId?: number;
    truckId?: number;
    deliveryDateTime?: string;
    originalDateTime?: string;
    deliveryTime?: string;
    deliveryInstructions?: string;
    sendDeliveryInstructions?: boolean;
    shipDateTime?: string;
    loadOrder?: string;
    status?: string;
    pallets?: number;
    saved?: boolean;
    id?: string;
    deliveryDateIndex?: number;
    shipmentQuantity?: number;
    carrier?: string;
    warehouseName?: string;
    warehouseAddress?: string;
    shipmentSKUs?: number;
    canQuantityPallets?: number;
    canQuantitySKUs?: number;
    endQuantityPallets?: number;
    endQuantitySKUs?: number;
    shipmentQuantityEaches?: number;
    shipmentEditStatus?: ShipmentEditStatus;
    edited?: boolean;
    cancelled?: boolean;
    updatedDeliveryDate?: string;
    shipToId?: number;
    dateEditedBefore?: boolean;
}

/**
 * Interface for a load on a given shipment.
 * @param loadId Unique identifier supplied by server. This is not to be manipulated and serves as a base definition for editLoadId.
 * @param editLoadId Unique identifier for client side logic. Used for load isolation. Set on data load or when a load is added to a shipment.
 */
export interface ProductToShip extends ProductWithPallets {
    palletQuantity: number;
    palletsOnShipments?: number;
    availableDate?: string;
    shipmentIndex?: number;
    loadId?: number;
    editLoadId?: number;
    releaseNumber?: string;
    reference?: string;
    referenceNumber?: string;
    fillerLine?: string;
    purchaseOrderNumber?: string;
    sequence?: number;
    addDisabled?: boolean;
    calculatedTotalsForPrevNDays?: number[];
    productWithPallets?: number[];
    previousPalletTotals?: number[];
    status?: string;
    customerProductName?: string;
    userSuppliedProductId?: string;
    customerProductId?: string;
    deliveryInstructions?: string;
    originalCsvLineNumber?: number;
    eachesQuantity?: number;
    palletsRounded?: boolean;
    isCustomerProductIdDistinct?: boolean;
    candidateProductSkus?: string[];
    originalPalletQuantity?: number;
}

export interface LinkedProductionOrders {
    loadsToLinkedOrders?: DeliveryOrderLinkedProductionOrder;
}

export interface DeliveryOrderLinkedProductionOrder {
    productionOrderId: number;
    productionOrderNumber: number; //this is the JDE one
    customerProductionOrderId: string;
    quantity: number;
}

export interface ProductWithPallets extends OrderProduct {
    availableItemsPerPallet?: number;
    availablePallets?: number;
    previousPalletTotals?: number[];
    inputPallets?: number;
    inputEaches?: number;
    orderedPallets?: number;
    agedPalletQuantity?: number;
}

export interface Load {
    loadId?: number;
    releaseNumber?: string;
    referenceNumber?: string;
    fillerLine?: string;
    palletQuantity?: number;
    sequence?: number;
    purchaseOrderNumber?: string;
    deliveryInstructions?: string;
}

export interface DeliveryOrder {
    deliveryOrderId?: number;
    deliveryOrderNumber?: string;
    deliveryOrderNumberString?: string;
    submittedDate?: string;
    formattedSubmittedDate?: string;
    earliestShipmentDate?: string | Moment;
    latestShipmentDate?: string | Moment;
    submittedBy?: string;
    createdBy?: string;
    type?: string;
    status?: string;
    shipments?: DeliveryShipment[];
    shipToId?: number;
    totalOrderQuantity?: number;
    totalOrderSkus?: number;
    deliveryWeekDate?: any;
    totalShipments?: number;
    customerPickup?: boolean;
    canQuantityPallets?: number;
    endQuantityPallets?: number;
    canQuantitySKUs?: number;
    endQuantitySKUs?: number;
    canQuantityEaches?: number;
    endQuantityEaches?: number;
    remainingThisWeekShipments?: number;
    remainingThisWeekOrderQuantity?: number;
    remainingThisWeekOrderSkus?: number;
    isLocked?: boolean;
    totalOrderQuantityEaches?: number;
    searchTerms?: string;
    statusReview?: OrderStatusReview;
    reviewStatus?: ReviewStatus;
}

export interface OrderStatusReview {
    status: ReviewStatus;
    reason: string;
    acknowledged: boolean;
    createDate: Date;
    updateDate: Date;
}

export interface DeliveryOrderRequest extends DeliveryOrder {
    region: string;
    country: string;
    createdBy: string;
    status: string;
    submittedDate: string;
    shipToId: number;
    updateDate?: string;
}

export interface ExpandableLoad {
    shipmentId?: string;
    displayId?: string;
    expanded: boolean;
}

export type QuantityUnit = 'pallets' | 'eaches';

export interface ShippingState {
    products?: OrderProduct[];
    productsWithPallets: ProductWithPallets[];
    productsToBeShipped: ProductToShip[];
    deliveryOrder?: DeliveryOrder;
    shipmentsOrder?: DeliveryShipment[];
    view?: string[];
    range?: string[];
    loading: boolean;
    loaded: boolean;
    error?: any;
    availablePallets?: number;
    selectedDeliveryDate?: any;
    fromConfig: boolean;
    expandableLoads?: ExpandableLoad[];
    quantityUnit: QuantityUnit;
}

const initialState: ShippingState = {
    products: [],
    productsToBeShipped: [],
    productsWithPallets: [],
    shipmentsOrder: [],
    deliveryOrder: {},
    loading: false,
    loaded: false,
    view: [''],
    range: [''],
    fromConfig: false,
    expandableLoads: [],
    quantityUnit: 'pallets'
};

export interface ShipItProductsRequest {
    ShipToIds: number[];
    Types?: string[];
    UsedWithinNMonths?: number;
    ActiveOnly?: boolean;
}

const shippingDashboard = (state = initialState, action: any): ShippingState => {
    switch (action.type) {
        case SHIPPING_DASHBOARD_LOADED:
            return {
                ...state,
                loaded: true,
                loading: false,
                products: action.products,
                productsWithPallets: action.products
            };
        case SHIPPING_DASHBOARD_LOADING:
            return { ...state, loaded: false, loading: true, error: undefined };
        case SHIPPING_DASHBOARD_LOADING_ERROR:
            return { ...state, loaded: true, loading: false, error: action.error };
        case SHIPPING_DASHBOARD_UPDATE_VIEW:
            return { ...state, loaded: true, loading: false, view: action.view };
        case SHIPPING_DASHBOARD_VIEW_ERROR:
            return { ...state, loading: false, loaded: false, error: action.error };
        case SHIPPING_DASHBOARD_UPDATE_RANGE:
            return { ...state, loaded: true, loading: false, range: action.range };
        case SHIPPING_DASHBOARD_NO_PRODUCTS:
            return {
                ...state,
                loaded: true,
                loading: false,
                products: action.products,
                productsWithPallets: action.products
            };
        case SHIPPING_DASHBOARD_UPDATE_RESULTS:
            return { ...state, loaded: true, loading: false, products: action.products };
        case SHIPPING_DASHBOARD_UPDATE_DELIVERY_DATE:
            return {
                ...state,
                loaded: true,
                loading: false,
                selectedDeliveryDate: action.selectedDeliveryDate
            };
        case SHIPPING_DASHBOARD_UPDATE_PALLETS:
            return {
                ...state,
                loaded: true,
                loading: false,
                productsWithPallets: action.productsWithPallets
            };
        case SHIPPING_ORDER_LOADED:
            return {
                ...state,
                loaded: true,
                loading: false,
                productsToBeShipped: action.productsToBeShipped
            };
        case SHIPPING_ORDER_LOADING:
            return { ...state, loaded: false, loading: true, error: undefined };
        case SHIPPING_ORDER_LOADING_ERROR:
            return { ...state, loaded: true, loading: false, error: action.error };
        case SHIPPING_ORDER_PRODUCTS_UPDATE:
            return { ...state, loaded: false, productsToBeShipped: action.productsToBeShipped };
        case SHIPPING_ORDER_SHIPMENTS_UPDATE:
            return { ...state, loaded: false, shipmentsOrder: action.shipmentsOrder };
        case SHIPPING_UPDATE_DELIVERY_ORDER:
            return { ...state, loaded: false, deliveryOrder: action.deliveryOrder };
        case SHIPPING_DASHBOARD_RESET_STATE:
            return {
                ...initialState
            };
        case SHIPPING_DASHBOARD_RESET_PRODUCT_STATE:
            return {
                ...state,
                products: action.products,
                productsToBeShipped: action.productsToBeShipped,
                shipmentsOrder: action.shipmentsOrder,
                deliveryOrder: action.deliveryOrder
            };
        case SHIPPING_DASHBOARD_CONFIG_FLAG:
            return { ...state, fromConfig: action.fromConfig };
        case SHIPPING_DASHBOARD_EXPANDABLE_LOAD_CHANGE:
            return { ...state, expandableLoads: action.expandableLoads };
        case SHIPPING_DASHBOARD_UPDATE_QUANTITY_UNIT:
            return {
                ...state,
                quantityUnit: action.quantityUnit,
                productsWithPallets: action.productsWithPallets
            };
        default:
            return state;
    }
};

export default shippingDashboard;
