import {
    FormControl,
    FormHelperText,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText
} from '@material-ui/core';
import React, { ReactElement } from 'react';
import { ErrorMessage, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface Props {
    label: ReactElement;
    name: string;
    testId: string;
    control: any;
    selectObj: any;
    rules?: any;
    error?: boolean;
    errors?: any;
    styles?: any;
    helperText?: ReactElement;
    defaultValue?: string;
    disabled?: boolean;
    selected?: string[];
}

const MultiSelectInput = (props: Props) => {
    const { t } = useTranslation();
    const error = props.error !== undefined;
    return (
        <FormControl fullWidth error={error} className={props.styles}>
            <InputLabel>{props.label}</InputLabel>
            <Controller
                as={
                    <Select
                        MenuProps={{ variant: 'menu' }}
                        inputProps={{
                            'data-testid': 'multiselect-input'
                        }}
                        multiple
                        renderValue={(selected: any) => {
                            return selected.map((s) => t(s, s)).join(', ') ?? '';
                        }}
                    >
                        {props.selectObj.length &&
                            props.selectObj.map((option) => (
                                <MenuItem
                                    key={option.value}
                                    value={option.value}
                                    disabled={option.disabled}
                                >
                                    <Checkbox checked={props.selected?.includes(option.value)} />
                                    <ListItemText primary={option.name} />
                                </MenuItem>
                            ))}
                    </Select>
                }
                disabled={props.disabled}
                name={props.name}
                control={props.control}
                rules={props.rules}
                defaultValue={props.defaultValue ? props.defaultValue : []}
            />
            {!error && props.helperText && <FormHelperText>{props.helperText}</FormHelperText>}
            <FormHelperText data-testid={error ? `${props.name}-error` : ''}>
                <ErrorMessage errors={props.errors} name={props.name} />
            </FormHelperText>
        </FormControl>
    );
};

export default MultiSelectInput;
