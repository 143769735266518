import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { enUS } from '../../../../../utility/translations/locales';
import OnboardingInfoTile from '../OnboardingInfoTile';

interface Props {
    disabled?: boolean;
}

const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: '1em'
    },
}));

export default function ProductSetup({ disabled }: Props) {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <>
            <Typography variant="h3" className={classes.header} data-testid="product-setup-header">
                <Trans i18nKey="productSetupHeader">${enUS.productSetupHeader}</Trans>
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <OnboardingInfoTile
                        title={t('graphicsTitle', enUS.graphicsTitle)}
                        description={t('graphicsText', enUS.graphicsText)}
                        stepDisabled={disabled}
                        actionButton={
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                data-testid="graphics-button"
                            >
                                <Trans i18nKey="graphicsAction">{enUS.graphicsAction}</Trans>
                            </Button>
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <OnboardingInfoTile
                        title={t('liquidTitle', enUS.liquidTitle)}
                        description={t('liquidText', enUS.liquidText)}
                        stepDisabled={disabled}
                        actionButton={
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                data-testid="liquid-button"
                            >
                                <Trans i18nKey="liquidAction">{enUS.liquidAction}</Trans>
                            </Button>
                        }
                    />
                </Grid>
            </Grid>
        </>
    );
}
