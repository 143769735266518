import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsights } from '../../utility/AppInsights';
import ProductConfigurationService from '../../utility/services/product-configuration-service';
import { ProductConfiguration } from '../reducers/product-configurations';
import * as types from './action-types';

export const getProductsConfigurations = () => {
    return (dispatch, getState) => {
        dispatch({
            type: types.PRODUCTS_CONFIGURATIONS_LOADING
        });

        const accessToken = getState().auth.accessToken;
        ProductConfigurationService.getProductConfiguration(accessToken)
            .then((response) => {
                if (response) {
                    dispatch({
                        type: types.PRODUCTS_CONFIGURATIONS_LOADED,
                        products: response.data.configuredProducts
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: types.PRODUCTS_CONFIGURATIONS_ERROR,
                    error
                });
            });
    };
};

export const saveConfiguredProduct = (product: ProductConfiguration) => {
    return (dispatch, getState) => {
        dispatch({ type: types.PRODUCTS_CONFIGURED_LOADING });

        ProductConfigurationService.saveConfiguredProduct(product, getState().auth.accessToken)
            .then((response) => {
                const savedProduct = response.data;
                dispatch({
                    type: types.PRODUCTS_CONFIGURED_SAVED,
                    configuredProduct: savedProduct
                });
            })
            .catch((error) => {
                dispatch({ type: types.PRODUCTS_CONFIGURED_ERROR, error: error });
                const user = getState().auth?.userInfo?.email;
                if (appInsights && user) {
                    const appInsightsError = error.message + ' saveConfiguredProduct-' + user;
                    appInsights.trackException({
                        error: new Error(appInsightsError),
                        severityLevel: SeverityLevel.Error
                    });
                }
            });
    };
};

// adds configuredProduct to state to be consumed by KB Max
export const setConfiguredProductSelected = (product: ProductConfiguration) => {
    return {
        type: types.PRODUCTS_CONFIGURED_SAVED,
        configuredProduct: product
    };
};

export const setConfigError = (error: string) => ({
    type: types.PRODUCTS_CONFIGURED_ERROR,
    error: error
});
