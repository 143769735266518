import React from 'react';
import { FormattedShipToAccount } from '../../../../store/reducers/customer-context';
import { Grid, TableCell, TableRow, Typography } from '@material-ui/core';

interface Props {
    account: FormattedShipToAccount;
}

const ShipToAddressRow = ({ account }: Props) => {
    return (
        <TableRow hover={true} key={account.accountId} data-testid="ship-to-account-row">
            <TableCell data-testid="account-name">
                <Grid container>
                    <Grid item xs={12}>
                        <Typography>{account.name}</Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell data-testid="account-id">
                <Grid container>
                    <Grid item xs={12}>
                        <Typography>{account.accountId}</Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell data-testid="account-address">
                <Grid container>
                    <Grid item xs={12}>
                        <Typography>{account.formattedAddress}</Typography>
                    </Grid>
                </Grid>
            </TableCell>
        </TableRow>
    );
};

export default ShipToAddressRow;
