import {
    PENDING_PAYMENTS_LOADING,
    PENDING_PAYMENTS_LOADED,
    PENDING_PAYMENTS_LOADING_ERROR,
    PENDING_PAYMENTS_SUBMITTED
} from '../actions/action-types';
import { Invoice } from './make-it-summary';

export interface PendingPaymentsState {
    invoices: Invoice[];
    submittedInvoices: Invoice[];
    loading: boolean;
    loaded: boolean;
    error?: any;
    paymentSubmitted: boolean;
}

export interface IPaymentSubmitted {
    messageType: string;
    fillCountry: string;
    region: string;
    language: string;
    Email: string;
    messageData: {
        firstName: string,
        lastName: string,
        Email: string,
        companyName: string,
        companyId: string,
        Date: string,
        Time: string,
        customerPO: string,
        productionOrderNumber: string,
        RemittanceNumber?: string
    };
}

const initialState: PendingPaymentsState = {
    invoices: [],
    submittedInvoices: [],
    loading: false,
    loaded: false,
    paymentSubmitted: false
};

const pendingPaymentsDashboard = (state = initialState, action: any): PendingPaymentsState => {
    switch (action.type) {
        case PENDING_PAYMENTS_LOADED:
            return { ...state, loaded: true, loading: false, invoices: action.invoices };
        case PENDING_PAYMENTS_LOADING:
            return { ...state, loaded: false, loading: true, error: undefined };
        case PENDING_PAYMENTS_LOADING_ERROR:
            return { ...state, loaded: true, loading: false, error: action.error };
        case PENDING_PAYMENTS_SUBMITTED:
            return { ...state, loaded: true, loading: false, submittedInvoices: action.submittedInvoices };
        default:
            return state;
    }
};

export default pendingPaymentsDashboard;
