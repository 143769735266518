export const defaultFormValues = {
    // Billing Address
    billingAddress: {
        name: '',
        country: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        city: '',
        state: '',
        zipcode: ''
    },
    // Shipping Address
    shippingAddress: {
        name: '',
        country: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        city: '',
        state: '',
        zipcode: ''
    },
    // Shipping Information
    shipType: '',
    palletConfiguration: '',
    specialEquipment: '',
    cfdi: '',
    taxId: '',
    entityType: '',
    importer: '',
    shippingSameAsBillingAddress: 'No'
};