import {
    Button,
    Grid,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { GraphicsDetailStatus } from '../../../../store/reducers/graphic-intake';
import { boldWeight, medium, xl } from '../../../../themes/globalConstants';
import { formatDateForEU } from '../../../../utility/helpers/date-helpers';
import { generateKey } from '../../../../utility/helpers/order-helpers';
import clsx from 'clsx';

interface Props {
    selectedStatusHistory: GraphicsDetailStatus[];
    onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
    main: {
        fontSize: medium,
        padding: '1em'
    },
    columnHeaders: {
        fontSize: xl,
        fontWeight: boldWeight
    },
    closeButton: {
        marginTop: '1.5em'
    },
    statusColumn: {
        width: '80%'
    },
    noRecordsFound: {
        marginLeft: '0.5em'
    }
}));

const GraphicsStatusHistoryModal = ({ selectedStatusHistory, onClose }: Props) => {
    const classes = useStyles();
    const [sortedStatusHistory, setSortedStatusHistory] = useState<GraphicsDetailStatus[]>([]);
    useEffect(() => {
        setSortedStatusHistory(
            selectedStatusHistory.sort((a, b) => moment(b.iterationDate).diff(a.iterationDate))
        );
    }, [selectedStatusHistory]);

    return (
        <Grid container className={classes.main}>
            <Grid container item xs={12}>
                <Table
                    size="medium"
                    aria-label="status history table"
                    key={generateKey('status-history-table')}
                    data-testid="graphic-status-history-table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell
                                colSpan={2}
                                className={classes.columnHeaders}
                                align="left"
                                data-testid="status-date"
                            >
                                <Trans i18nKey="statusDate">Status Date</Trans>
                            </TableCell>
                            <TableCell
                                colSpan={10}
                                className={clsx(classes.columnHeaders, classes.statusColumn)}
                                align="left"
                                data-testid="status"
                            >
                                <Trans i18nKey="status">Status</Trans>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedStatusHistory.length === 0 ? (
                            <TableRow key={0}>
                                <TableCell colSpan={12} align="left">
                                    <Grid container justify="flex-start">
                                        <Grid item>
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    '/assets/NoRecordsFound_Icon.svg'
                                                }
                                                alt="No Records Found Icon"
                                            />
                                        </Grid>
                                        <Grid item className={classes.noRecordsFound}>
                                            <Typography>
                                                <Trans i18nKey="noRecordsFound">
                                                    No records found
                                                </Trans>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        ) : (
                            sortedStatusHistory.map((statusDetail, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell
                                            colSpan={2}
                                            align="left"
                                            data-testid={`iteration-date-${index}`}
                                        >
                                            <Typography>
                                                {statusDetail.iterationDate
                                                    ? formatDateForEU(statusDetail.iterationDate)
                                                    : ''}
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            colSpan={10}
                                            align="left"
                                            data-testid={`display-status-${index}`}
                                        >
                                            <Typography>{statusDetail.displayStatus}</Typography>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        )}
                    </TableBody>
                </Table>
            </Grid>
            <Grid container item xs={12} justify="flex-end" className={classes.closeButton}>
                <Button variant="contained" color="primary" onClick={onClose}>
                    <Trans i18nKey="close">Close</Trans>
                </Button>
            </Grid>
        </Grid>
    );
};

export default GraphicsStatusHistoryModal;
