import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, CircularProgress, Grid, makeStyles, Paper } from '@material-ui/core';
import { Activity } from '../../../utility/auth/useSecurity';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { LocationRoleAccessTable } from '../../reusable/molecules/LocationRoleAccessTable/LocationRoleAccessTable';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { getAdminAccounts } from '../../../utility/helpers/admin-helpers';
import { ShipToRoleAccess } from '../../reusable/molecules/LocationRoleAccessTable/models/role-access.model';
import { getUserCustomerAccounts } from '../../../store/actions/customer-context';
import UserInformation from './components/UserInformation';
import {
    clearUserPermissions,
    loadUserPermissions,
    updateUserPermissions
} from '../../../store/root-actions';
import { useDispatch } from 'react-redux';
import { UserAdminState } from '../../../store/reducers/user-admin';
import { AuthState } from '../../../store/reducers/auth';
import {
    clearTableValues,
    getTableUserPermissions,
    updateShipToRoleAccess
} from '../../../utility/helpers/user-admin-helpers';
import {
    useLocationRoleAccess,
    withLocationRoleAccessProvider
} from '../../reusable/molecules/LocationRoleAccessTable/context/LocationRoleAccessContext';
import { updateLocationRoleAccessAccounts } from '../../reusable/molecules/LocationRoleAccessTable/context/actions';
import ConfirmationModal from '../../reusable/molecules/ConfirmationModal';
import { enUS } from '../../../utility/translations/locales/en-US/en-US';
import { useHistory } from 'react-router';
import { UserAdminDashboardSelectedTab } from '../UserAdminDashboard/components/UserAdminDashboardTabs';
import ErrorAlert from '../../reusable/atoms/ErrorAlert';
import { USER_ADMIN_ADD_USER_RESET_STATUS } from '../../../store/actions/action-types';

const useStyles = makeStyles((theme) => ({
    actionBtn: {
        height: '3.375em',
        width: '13.75em',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom'
    },
    formSection: {
        marginBottom: '2.5em',
        marginTop: '2.5em'
    },
    formSectionContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    paper: {
        marginBottom: '1em',
        padding: '3em',
        marginLeft: '-2em'
    },
    locationTableWrapper: {
        marginBottom: '1.5em',
        paddingBottom: '1em'
    },
    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '0.5em'
    },
    warningIconRed: {
        '& .MuiAlert-icon': {},
        minWidth: '95%',
        justifyContent: 'center'
    }
}));

function UserAdminAddUser() {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);
    const {
        userToEdit,
        permissions: userPermissions,
        loaded,
        addUserSubmitStatus
    } = useTypedSelector<UserAdminState>((state) => state.userAdmin);
    const { accounts } = useTypedSelector<CustomerContextState>((state) => state.customerContext);

    const [submit, setSubmit] = useState<boolean>(false);
    const [displayServerError, setDisplayServerError] = useState<boolean>(false);
    const [accessAccounts, setAccessAccounts] = useState<Array<ShipToRoleAccess>>([]);
    const [addUserPermissionsLoaded, setAddUserPermissionsLoaded] = useState<boolean>(false);
    const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
    const [locationRoleAccessState, locationRoleAccessDispatch] = useLocationRoleAccess();

    const history = useHistory();
    const userAdminDashboardLink = '/user-admin-dashboard';

    const onCloseCancelModal = () => {
        setOpenCancelModal(false);
    };

    const onCancelRequest = () => {
        // resetting status to 'idle'
        dispatch({ type: USER_ADMIN_ADD_USER_RESET_STATUS });

        // resetting error message and permissions loaded flags
        setDisplayServerError(false);
        setAddUserPermissionsLoaded(false);

        // redirect to User Admin Dashboard
        history.push(userAdminDashboardLink);
    };

    const onOpenCancelModal = () => {
        setOpenCancelModal(true);
    };

    const onSubmit = () => {
        setSubmit(true);
        const accounts = locationRoleAccessState.accounts;
        const permissions = getTableUserPermissions(accounts);
        const destinationQuery = userHasExistingPermissions()
            ? UserAdminDashboardSelectedTab.ActiveUsers
            : UserAdminDashboardSelectedTab.PendingRegistration;

        dispatch(
            updateUserPermissions(userToEdit, permissions, () => {
                setSubmit(false);
                history.push(`/user-admin-dashboard?status=${destinationQuery}`);
            })
        );
    };

    const handleVerifyUser = (userEmail: string) => {
        if (userEmail) {
            dispatch(loadUserPermissions(userEmail));
        }
    };

    const userHasExistingPermissions = () => {
        return userPermissions && userPermissions.length ? true : false;
    };

    const getHeader = (): string => {
        if (addUserPermissionsLoaded && userHasExistingPermissions()) {
            return t('editUser', 'Edit User');
        }
        return t('addNewUser', 'Add New User');
    };

    const handleEditUserEmailClick = () => {
        setDisplayServerError(false);

        clearTableValues(accessAccounts, locationRoleAccessDispatch);
        setAddUserPermissionsLoaded(false);
    };

    useEffect(() => {
        updateLocationRoleAccessAccounts(accessAccounts, locationRoleAccessDispatch);
    }, [accessAccounts, locationRoleAccessDispatch]);

    useEffect(() => {
        dispatch(clearUserPermissions());
        // only runs once
    }, []);

    useEffect(() => {
        if (loaded && permissions && accounts) {
            const adminAccounts = getAdminAccounts(permissions, accounts);
            updateLocationRoleAccessAccounts(
                updateShipToRoleAccess(userPermissions, accessAccounts, adminAccounts),
                locationRoleAccessDispatch
            );
            setAddUserPermissionsLoaded(true);
        } else {
            setAddUserPermissionsLoaded(false);
        }
    }, [
        userToEdit,
        userPermissions,
        loaded,
        locationRoleAccessDispatch,
        accessAccounts,
        permissions,
        accounts
    ]);

    useEffect(() => {
        if (permissions && !accounts?.length) {
            dispatch(getUserCustomerAccounts());
        }
    }, [permissions]);

    useEffect(() => {
        if (permissions && accounts && accounts.length) {
            const accts = getAdminAccounts(permissions, accounts).map(
                (account) => new ShipToRoleAccess(account)
            );
            setAccessAccounts(accts);
        }
    }, [permissions, accounts]);

    useEffect(() => {
        // Based on the status after the user has clicked "Submit"
        // Toggle the error message display and enable/disable the "Submit" button
        switch (addUserSubmitStatus) {
            case 'failed':
                setDisplayServerError(true);
                setSubmit(false);
                break;
            case 'succeeded':
                setDisplayServerError(false);
                setSubmit(false);
                break;
            default:
                // this will also handle 'idle' and 'loading' status
                break;
        }
    }, [addUserSubmitStatus]);

    const footerActions = (
        <>
            <Grid item xs={2}>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="bottom-cancel-button"
                    onClick={onOpenCancelModal}
                    className={classes.actionBtn}
                >
                    <Trans i18nKey="cancel">Cancel</Trans>
                </Button>
            </Grid>
            <Grid item xs={2}>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    data-testid="submit-button"
                    className={classes.actionBtn}
                    onClick={onSubmit}
                    disabled={submit || !addUserPermissionsLoaded}
                >
                    <Trans i18nKey="submit">Submit</Trans>
                </Button>
            </Grid>
        </>
    );

    return (
        <>
            <ProcessingPageTemplate
                banner={{
                    header: getHeader(),
                    description: '',
                    thinBanner: true,
                    displayDropdown: true,
                    completeDropdown: true
                }}
                actionFooter={{
                    footerAction: footerActions,
                    justify: 'space-between',
                    sticky: true
                }}
                activity={Activity.UserAdminAddUser}
            >
                <Grid
                    container
                    item
                    xs={12}
                    className={classes.formSectionContainer}
                    data-testid="add-new-user-page"
                >
                    <Grid item xs={12} className={classes.formSection}>
                        <UserInformation
                            handleVerifyUser={handleVerifyUser}
                            handleEditClick={handleEditUserEmailClick}
                            permissionsLoaded={addUserPermissionsLoaded}
                        />
                    </Grid>
                    {addUserPermissionsLoaded && (
                        <Paper elevation={2} className={classes.paper}>
                            <Grid container className={classes.locationTableWrapper}>
                                <Grid container item xs={12} justify="flex-start">
                                    <LocationRoleAccessTable />
                                </Grid>
                            </Grid>
                        </Paper>
                    )}
                </Grid>
                {submit && (
                    <Grid container item xs={12} className={classes.spinningLoader}>
                        <CircularProgress />
                    </Grid>
                )}
                {displayServerError && (
                    <Grid container xs={12} data-testid="error-container">
                        <ErrorAlert
                            showError={displayServerError}
                            errorMessage={t(
                                'permissionsFailedToUpdate',
                                'The requested changes failed to submit due to an unknown error.'
                            )}
                        />
                    </Grid>
                )}
            </ProcessingPageTemplate>
            <ConfirmationModal
                logo={false}
                title={<Trans i18nKey="changesWillNotBeSaved">{enUS.changesWillNotBeSaved}</Trans>}
                subheader={<Trans i18nKey="areYouSureDiscard">{enUS.areYouSureDiscard}</Trans>}
                open={openCancelModal}
                continueText={<Trans i18nKey="yesDiscard">{enUS.yesDiscard}</Trans>}
                cancelText={<Trans i18nKey="no">{enUS.no}</Trans>}
                saveProgress={onCancelRequest}
                onCancel={onCloseCancelModal}
                onClose={onCloseCancelModal}
                data-testid="cancel-confirmation-modal"
            />
        </>
    );
}

export default withLocationRoleAccessProvider(UserAdminAddUser);
