import {
    PENDING_PAYMENTS_LOADING,
    PENDING_PAYMENTS_LOADED,
    PENDING_PAYMENTS_LOADING_ERROR,
    PENDING_PAYMENTS_SUBMITTED
} from '../actions/action-types';
import OrdersService, { Order_Status } from '../../utility/services/orders-service';
import { Invoice } from '../reducers/make-it-summary';
import { AuthState } from '../reducers/auth';
import {
    Activity,
    filterShipToIdsByPermission,
    SecurityLevel,
    ValidationMethod
} from '../../utility/auth/useSecurity';
import moment from 'moment';

export const loadInvoices = (paymentType?: string, selectedShipToId?: string) => {
    return (dispatch, getState) => {
        dispatch({ type: PENDING_PAYMENTS_LOADING });
        const accessToken = getState().auth.accessToken;
        const selectedShipToId = getState().customerContext.selectedAccountId;

        if (selectedShipToId) {
            const auth: AuthState = getState().auth;
            const today = moment().startOf('day');
            const sevenDaysOut = moment().startOf('day').add(7, 'days');
            const accounts = [selectedShipToId];
            const requestObj = {
                ShipToIds: filterShipToIdsByPermission(
                    auth,
                    accounts,
                    Activity.OrderPaymentFinancials,
                    SecurityLevel.View,
                    ValidationMethod.byAccess
                )
            };
            OrdersService.getInvoices(accessToken, requestObj)
                .then((response) => {
                    let invoices = response.data.invoices;
                    if (paymentType && paymentType === 'pending') {
                        invoices = response.data.invoices.filter(
                            (invoice) =>
                                invoice.status === Order_Status.Payment_Delinquent &&
                                moment(invoice.paymentDueDate).startOf('day').isBefore(today)
                        );
                    } else if (paymentType && paymentType === 'approaching') {
                        invoices = response.data.invoices.filter(
                            (invoice) =>
                                invoice.status === Order_Status.Payment_Delinquent &&
                                moment(invoice.paymentDueDate)
                                    .startOf('day')
                                    .isBetween(today, sevenDaysOut)
                        );
                    }
                    dispatch({
                        type: PENDING_PAYMENTS_LOADED,
                        invoices
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: PENDING_PAYMENTS_LOADING_ERROR,
                        error
                    });
                });
        } else {
            dispatch({
                type: PENDING_PAYMENTS_LOADED,
                invoices: []
            });
        }
    };
};

export const addInvoiceToSubmitList = (invoice: Invoice) => {
    return (dispatch, getState) => {
        const { submittedInvoices } = getState().pendingPaymentsDashboard;

        dispatch({
            type: PENDING_PAYMENTS_SUBMITTED,
            submittedInvoices: [...submittedInvoices, invoice]
        });
    };
};
