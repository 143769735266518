import React, { useState, useEffect } from 'react';
import { Grid, Typography, makeStyles, Divider } from '@material-ui/core';
import { Trans } from 'react-i18next';
import { DeliveryShipment } from '../../../../store/reducers/shipping-dashboard';
import {
    addTimezoneOffset,
    getFullFormattedDateTime
} from '../../../../utility/helpers/date-helpers';
import {
    ballGray,
    ballDrkBlue,
    xxl,
    blackWeight,
    medium,
    ltBlueGrey_34,
    small
} from '../../../../themes/globalConstants';
import clsx from 'clsx';
import ShipItConfirmationGrid from './ShipItConfirmationGrid';
import { formatNumberWithLocale } from '../../../../utility/helpers/formatters';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';

interface Props {
    currentShipment: DeliveryShipment;
    shipmentIndex: number;
}

const useStyles = makeStyles((theme) => ({
    main: {
        paddingTop: '1em',
        width: '100%'
    },
    cardSubheader: {
        color: ballGray
    },
    dateDescription: {
        color: ballGray,
        display: 'flex',
        alignItems: 'center'
    },
    altDescriptionColor: {
        color: ballDrkBlue
    },
    dateDescriptionMain: {
        fontSize: xxl,
        fontWeight: blackWeight
    },
    deliveryTitle: {
        fontWeight: blackWeight,
        fontSize: medium
    },
    actionsBar: {
        marginTop: '1.25em'
    },
    divider: {
        height: '0.32em',
        backgroundColor: ltBlueGrey_34,
        width: '100%',
        margin: '.5em 0em .5em 0em'
    },
    eaches: {
        fontSize: small
    }
}));

const ShipmentConfirmationSection = ({ currentShipment }: Props) => {
    const classes = useStyles();
    const [isCanType, setIsCanType] = useState(true);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    useEffect(() => {
        currentShipment.shipmentType &&
        currentShipment.shipmentType.toLowerCase() === 'ends/closures'
            ? setIsCanType(false)
            : setIsCanType(true);
        // Runs only once
    }, []);

    const adjustedDateTime = addTimezoneOffset(currentShipment.deliveryDateTime);
    const shipmentDayAndTime = getFullFormattedDateTime('', adjustedDateTime);

    return (
        <>
            <Grid className={classes.main} container item>
                <Divider className={classes.divider} />
                <Grid item md={7} className={classes.dateDescription}>
                    <Typography
                        className={clsx(classes.dateDescriptionMain, classes.altDescriptionColor)}
                        data-testid="arriving-time"
                    >
                        <Trans i18nKey="deliveryDate">Delivery Date</Trans>
                        {': ' + shipmentDayAndTime}
                    </Typography>
                </Grid>
                <Grid container item md={5} justify="flex-end">
                    <Grid item xs={3}>
                        <Typography variant="subtitle2" className={classes.cardSubheader}>
                            <Trans i18nKey="Type">Type</Trans>
                        </Typography>
                        <Typography data-testid="type">
                            {isCanType ? (
                                <Trans i18nKey="cansBottles">Cans/Bottles</Trans>
                            ) : (
                                <Trans i18nKey="endClosures">End/Closures</Trans>
                            )}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="subtitle2" className={classes.cardSubheader}>
                            <Trans i18nKey="quantity">Quantity</Trans>
                        </Typography>
                        <Typography data-testid="quantity-pallets">
                            {currentShipment.shipmentQuantity} PL
                        </Typography>
                        <Typography
                            variant="body1"
                            data-testid="quantity-eaches"
                            className={classes.eaches}
                        >
                            {currentShipment?.shipmentQuantityEaches &&
                                formatNumberWithLocale(
                                    cultureCode,
                                    currentShipment.shipmentQuantityEaches
                                ) + ' ea.'}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="subtitle2" className={classes.cardSubheader}>
                            <Trans i18nKey="products">Products</Trans>
                        </Typography>
                        <Typography data-testid="products">
                            {currentShipment.loads?.length}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider className={classes.divider} />
            </Grid>
            <Grid container>
                <Grid container item>
                    {currentShipment.loads && currentShipment.loads.length && (
                        <ShipItConfirmationGrid shipment={currentShipment} />
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default ShipmentConfirmationSection;
