import {
    EDIT_PRODUCTION_ORDER_ERROR,
    EDIT_PRODUCTION_ORDER_LOADING,
    EDIT_PRODUCTION_ORDER_SET_ORDER,
    EDIT_PRODUCTION_ORDER_RESET
} from '../actions/action-types';
import { ProductionOrder } from './makeit-dashboard';

export interface EditProductionOrderState {
    loading: boolean;
    productionOrderToEdit?: ProductionOrder;
    updatedProductionOrder?: ProductionOrder;
    error?: any;
}

const initialState: EditProductionOrderState = {
    loading: false,
    productionOrderToEdit: undefined,
    updatedProductionOrder: undefined,
    error: undefined
};

const editProductionOrder = (
    state: EditProductionOrderState = initialState,
    action: { type: string; [x: string]: any }
): EditProductionOrderState => {
    switch (action.type) {
        case EDIT_PRODUCTION_ORDER_LOADING:
            return {
                ...state,
                loading: true
            };
        case EDIT_PRODUCTION_ORDER_SET_ORDER:
            return {
                ...state,
                productionOrderToEdit: action.productionOrderToEdit,
                loading: false
            };
        case EDIT_PRODUCTION_ORDER_ERROR:
            return {
                ...state,
                error: action.error,
                loading: false
            };
        case EDIT_PRODUCTION_ORDER_RESET:
            return {
                ...state,
                productionOrderToEdit: undefined
            };
        default:
            return state;
    }
};

export default editProductionOrder;
