import React, { useEffect, useState } from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { black, boldWeight, shipWarningBlue } from '../../../../themes/globalConstants';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { stringFormatterReplace } from '../../../../utility/helpers/formatters';
import { formattedDateWithTimezone } from '../../../../utility/helpers/date-helpers';
import moment from 'moment';

interface Props {
    lastShipToRequestDate: string | Date;
}

const useStyles = makeStyles((theme) => ({
    warningContainer: {
        marginTop: '1em',
        width: '100%',
        '& .MuiAlert-icon': {
            color: '#009BBB',
            display: 'flex',
            alignItems: 'center'
        },
        '& .MuiAlert-message': {
            flexGrow: '100',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& .MuiTypography-root': {
                color: black,
                fontWeight: boldWeight,
                display: 'inline'
            }
        },
        backgroundColor: shipWarningBlue
    },
    btn: {
        color: theme.palette.info.dark
    }
}));

export default function ShipToRequestBanner({ lastShipToRequestDate }: Props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const regionCulture = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [notificationMessage, setNotificationMessage] = useState('');
    const warningIcon = (
        <img src={process.env.PUBLIC_URL + '/assets/InfoTriangle_Icon.svg'} alt="Warning Icon" />
    );

    useEffect(() => {
        if (lastShipToRequestDate) {
            const formattedRequestDate = formattedDateWithTimezone(
                moment(lastShipToRequestDate),
                'D MMMM YYYY'
            );
            setNotificationMessage(
                stringFormatterReplace(
                    t(
                        'lastShipToRequestDateBanner',
                        'The last notification requesting Ship To Location was sent to the customer on {0}'
                    ),
                    formattedRequestDate
                )
            );
        }
    }, [t, lastShipToRequestDate, regionCulture]);

    return (
        <>
            {lastShipToRequestDate && (
                <Grid container item xs={12}>
                    <Alert
                        icon={warningIcon}
                        severity="warning"
                        className={classes.warningContainer}
                        data-testid="ship-to-request-banner"
                    >
                        <Typography>{notificationMessage}</Typography>
                    </Alert>
                </Grid>
            )}
        </>
    );
}
