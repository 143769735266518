import { SeverityLevel } from '@microsoft/applicationinsights-web';
import moment from 'moment';
import { appInsights } from '../../utility/AppInsights';
import { ErrorStatus } from '../../utility/helpers/axios-helpers';
import CapacityCheckService, {
    CapacityCheckStatus
} from '../../utility/services/capacity-check-service';
import { CapacityCheck, CapacityCheckOption } from '../reducers/capacity-checks';
import {
    CAPACITY_CHECKS_ERROR,
    CAPACITY_CHECKS_LOADED,
    CAPACITY_CHECKS_LOADING,
    CAPACITY_CHECK_ERROR,
    CAPACITY_CHECK_LOADING,
    CAPACITY_CHECK_SAVED
} from './action-types';

export const saveCapacityCheck = (capacityCheck: CapacityCheckOption[]) => {
    return (dispatch, getState) => {
        if (!getState().capacityCheck.loading) {
            dispatch({ type: CAPACITY_CHECK_LOADING });

            CapacityCheckService.saveCapacityCheck(capacityCheck, getState().auth.accessToken)
                .then((response) => {
                    dispatch({
                        type: CAPACITY_CHECK_SAVED,
                        capacityCheck: capacityCheck
                    });
                })
                .catch((error) => {
                    dispatch({ type: CAPACITY_CHECK_ERROR, error: error });
                    const user = getState().auth?.userInfo?.email;
                    if (appInsights && user) {
                        const appInsightsError = error.message + ' saveCapacityCheck-' + user;
                        appInsights.trackException({
                            error: new Error(appInsightsError),
                            severityLevel: SeverityLevel.Error
                        });
                    }
                });
        }
    };
};

export const loadCapacityChecks = () => {
    return (dispatch, getState) => {
        dispatch({
            type: CAPACITY_CHECKS_LOADING
        });

        CapacityCheckService.getCapacityChecks(getState().auth.accessToken)
            .then((response) => {
                const capacityCheckFilterRange = 180;
                const capacityCheckFutureEndDate = moment().subtract(
                    capacityCheckFilterRange,
                    'day'
                );

                let filteredCapacityChecks: CapacityCheck[] = [];
                if (response.data.capacityChecks) {
                    filteredCapacityChecks = response.data.capacityChecks.filter((capacityCheck) =>
                        moment(capacityCheck.submittedDate).isSameOrAfter(
                            capacityCheckFutureEndDate
                        )
                    );
                }
                dispatch({
                    type: CAPACITY_CHECKS_LOADED,
                    capacityChecks: filteredCapacityChecks
                });
            })
            .catch((error) => {
                if (error.response?.status === ErrorStatus.NotFound) {
                    dispatch({ type: CAPACITY_CHECKS_LOADED, capacityChecks: [] });
                } else {
                    dispatch({ type: CAPACITY_CHECKS_ERROR, error: error });
                    const user = getState().auth?.userInfo?.email;
                    if (appInsights && user) {
                        const appInsightsError = error.message + ' getCapacityChecks-' + user;
                        appInsights.trackException({
                            error: new Error(appInsightsError),
                            severityLevel: SeverityLevel.Error
                        });
                    }
                }
            });
    };
};

export const cancelCapacityCheck = (opportunityId: string) => {
    return (dispatch, getState) => {
        dispatch({
            type: CAPACITY_CHECKS_LOADING
        });

        CapacityCheckService.cancelCapacityCheck(opportunityId, getState().auth.accessToken)
            .then((response) => {
                dispatch(loadCapacityChecks());
            })
            .catch((error) => {
                dispatch({ type: CAPACITY_CHECKS_ERROR, error: error });
                const user = getState().auth?.userInfo?.email;
                if (appInsights && user) {
                    const appInsightsError = error.message + ' cancelCapacityCheck-' + user;
                    appInsights.trackException({
                        error: new Error(appInsightsError),
                        severityLevel: SeverityLevel.Error
                    });
                }
            });
    };
};

export const getCapacityCheckStatus = (status: CapacityCheckStatus) => {
    switch (status) {
        case CapacityCheckStatus.Unknown:
            return 'unknown';
        case CapacityCheckStatus.Submitted:
            return 'submitted';
        case CapacityCheckStatus.UnderReview:
            return 'underReview';
        case CapacityCheckStatus.Complete:
            return 'complete';
        case CapacityCheckStatus.Canceled:
            return 'cancelled';
        default:
            return '';
    }
};
