import { makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { SelectableAccount } from '../../../store/reducers/customer-context';
import { blackWeight, paleGrey } from '../../../themes/globalConstants';
import AccountFilter from './AccountFilter';
import AccountListItem from './AccountListItem';

interface Props {
    accounts: SelectableAccount[];
    filterText: string;
    selectedAccountId?: string;
    showHeader?: boolean;
}

const useStyles = makeStyles((theme) => ({
    otherAccountTitle: {
        fontWeight: blackWeight,
        color: theme.palette.secondary.main,
        backgroundColor: paleGrey,
        paddingLeft: '0.5em',
        textTransform: 'uppercase'
    }
}));

const OtherAccounts = ({ accounts, filterText, selectedAccountId, showHeader = true }: Props) => {
    const classes = useStyles();
    const [selectableAccounts, setSelectableAccounts] = useState<SelectableAccount[]>([]);
    const [anyVisible, setAnyVisible] = useState<boolean>(true);

    const setVisibility = (account: SelectableAccount, visible: boolean) => {
        const accountToEdit = selectableAccounts.find(
            (selectableAccount) => selectableAccount.accountId === account.accountId
        );
        if (accountToEdit) {
            accountToEdit.hasMatch = visible;
            setSelectableAccounts((accounts) => [
                ...accounts.filter((value) => value.accountId !== account.accountId),
                accountToEdit
            ]);
        }
    };

    useEffect(() => {
        const hasMatches = selectableAccounts
            .map((account) => account.hasMatch)
            .filter((value, index, self) => self.indexOf(value) === index);
        setAnyVisible(hasMatches.includes(true));
    }, [selectableAccounts]);

    useEffect(() => {
        if (accounts) {
            accounts.forEach((account) => {
                account.hasMatch = true;
                setSelectableAccounts((accounts) => [...accounts, account]);
            });
        }
    }, [accounts]);

    return (
        <>
            {anyVisible && showHeader && (
                <Typography
                    className={classes.otherAccountTitle}
                    data-testid={'other-shiptos-title'}
                >
                    <Trans i18nKey="otherShipTos">OTHER SHIP-TOS</Trans>
                </Typography>
            )}

            {accounts &&
                accounts.map((account, i) => {
                    return (
                        <AccountFilter
                            key={i}
                            account={account}
                            filterText={filterText}
                            setVisibility={setVisibility}
                            render={(account, words) => (
                                <AccountListItem
                                    account={account}
                                    selected={
                                        selectedAccountId
                                            ? account.accountId === selectedAccountId
                                            : undefined
                                    }
                                />
                            )}
                        />
                    );
                })}
        </>
    );
};

export default OtherAccounts;
