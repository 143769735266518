import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { blackWeight } from '../../../../themes/globalConstants';
// import OnboardingSectionCompleted from './OnboardingSectionCompleted';
import clsx from 'clsx';
import { Trans } from 'react-i18next';
import { enUS } from '../../../../utility/translations/locales';

interface Props {
    title?: string;
    optional?: boolean;
    description: string;
    actionButton: React.ReactNode;
    stepDisabled?: boolean;
}

const useStyles = makeStyles((theme) => ({
    infoTile: {
        flexDirection: 'column',
        height: '100%'
    },
    title: {
        textTransform: 'uppercase',
        fontWeight: blackWeight,
        color: theme.palette.info.dark,
        letterSpacing: 1.17,
        marginBottom: '1em'
    },
    description: {
        color: theme.palette.info.dark,
        marginBottom: '1em'
    },
    action: {
        marginTop: 'auto'
    },
    titleOptional: {
        position: 'relative',
        '& .optional': {
            position: 'absolute',
            left: 0,
            top: '-1.25em',
            fontSize: '.625rem',
            fontWeight: 'normal',
            textTransform: 'initial',
            letterSpacing: 'normal'
        }
    }
}));

export default function OnboardingInfoTile({
    title,
    description,
    actionButton,
    stepDisabled,
    optional
}: Props) {
    const classes = useStyles();
    const actionButtonWithProps = React.Children.map(actionButton, (child) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { disabled: stepDisabled });
        }
        return child;
    });

    return (
        <Grid container className={classes.infoTile}>
            {title && (
                <Grid
                    item
                    className={clsx(classes.title, {
                        [classes.titleOptional]: optional
                    })}
                >
                    {title}
                    {optional === true && (
                        <span className="optional">
                            <Trans i18nKey="optionalStepText">{enUS.optionalStepText}</Trans>
                        </span>
                    )}
                </Grid>
            )}
            <Grid item>
                <Typography variant="body1" className={classes.description}>
                    {description}
                </Typography>
            </Grid>
            <Grid item className={classes.action}>
                {actionButtonWithProps}
            </Grid>
        </Grid>
    );
}
