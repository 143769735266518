import {
    GRAPHICS_FILE_STATUS_UPDATE,
    SAVE_GRAPHICS_FORM_DATA,
    GRAPHICS_ID_UPDATE,
    GRAPHICS_DATA_LOADED,
    GRAPHICS_DATA_LOADING,
    GRAPHICS_DATA_LOADING_ERROR,
    GRAPHICS_RESET_FILE,
    GRAPHICS_UPDATE_EXISTING_FILES,
    GRAPHICS_DATA_RESET_STATE
} from './action-types';
import GraphicsService from '../../utility/services/graphics-service';
import { FileInfo } from './../reducers/graphic-intake';

export const setFileSection = (files?: File[]) => {
    return (dispatch: any) => {
        if (files) {
            dispatch({ type: GRAPHICS_FILE_STATUS_UPDATE, files: files });
        } else {
            dispatch({
                type: GRAPHICS_RESET_FILE
            });
        }
    };
};

export const resetFile = () => {
    return (dispatch: any) => {
        dispatch({
            type: GRAPHICS_RESET_FILE
        });
    };
};

export const setFormSectionStatus = (validStatus: boolean, type: string) => {
    return (dispatch: any) => {
        dispatch({
            type: type,
            valid: validStatus
        });
    };
};

export const saveFormData = (data) => {
    return (dispatch: any) => {
        dispatch({
            type: SAVE_GRAPHICS_FORM_DATA,
            graphics: data
        });
    };
};
export const setGraphicsID = (id: string) => {
    return (dispatch: any) => {
        dispatch({
            type: GRAPHICS_ID_UPDATE,
            id: id
        });
    };
};

export const getGraphic = (id: string) => {
    return (dispatch, getState) => {
        dispatch({ type: GRAPHICS_DATA_LOADING });

        GraphicsService.getGraphicData(getState(), id)
            .then((response) => {
                dispatch({
                    type: GRAPHICS_DATA_LOADED,
                    graphics: response.data ?? null
                });
            })
            .catch((error) => {
                dispatch({
                    type: GRAPHICS_DATA_LOADING_ERROR,
                    error
                });
            });
    };
};

export const removeExistingFile = (index: number) => {
    return (dispatch, getState) => {
        const existingGraphics = [...getState().graphicIntake.graphics.fileInfo];
        const deletedFile = existingGraphics.splice(index, 1)[0];
        const deletedFiles = [
            ...(getState().graphicIntake.graphics.deletedFiles ?? []),
            deletedFile.fileName
        ];
        dispatch({
            type: GRAPHICS_UPDATE_EXISTING_FILES,
            updatedFiles: existingGraphics,
            deletedFiles: deletedFiles
        });
    };
};

export const updateExistingFiles = (existingFiles: FileInfo[]) => {
    return (dispatch) => {
        dispatch({
            type: GRAPHICS_UPDATE_EXISTING_FILES,
            updatedFiles: existingFiles,
            deletedFiles: []
        });
        dispatch({ type: GRAPHICS_RESET_FILE });
    };
};

export const resetGraphicsForm = () => {
    return (dispatch) => {
        dispatch({ type: GRAPHICS_DATA_RESET_STATE });
    };
};
