import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { NavigationalPageTemplate } from '../../templates/NavigationalPageTemplate';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { loadProductDetails } from '../../../store/root-actions';
import { useDispatch } from 'react-redux';
import { Grid, makeStyles, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import { ProductDetailsState } from '../../../store/reducers/product-details';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import ProductDetailsTable from './components/ProductDetailsTable';
import ProductDetailsSidePanel from './components/ProductDetailsSidePanel';
import ProductFeatureContent from './components/ProductFeatureContent';
import ProductDetailsSubheader from './components/ProductDetailsSubheader';
import { ProductPortfolioState } from '../../../store/reducers/product-portfolio';
import { ltBlueGrey_3, medium, boldWeight } from '../../../themes/globalConstants';
import { DefaultQueryParams } from '../../../utility/helpers/query-helpers';
import { getProductDetailsHeader } from '../../../utility/helpers/product-helpers';

const useStyles = makeStyles((theme) => ({
    sectionHeader: {
        margin: '3.125em 0 2.125px 0'
    },
    lineSeperator: {
        marginTop: '3.75em',
        marginBottom: '2.5em',
        border: '0.5px solid',
        borderColor: ltBlueGrey_3
    },
    contentTitle: {
        marginBottom: '1.875em'
    },
    featureHeader: {
        fontSize: medium,
        fontWeight: boldWeight,
        color: theme.palette.secondary.main,
        textTransform: 'uppercase',
        marginBottom: '1.25em'
    },
    featureSection: {
        display: 'flex',
        marginBottom: '2em',
        [theme.breakpoints.down('xs')]: {
            marginBottom: '1em'
        }
    }
}));

const ProductDetailsPage = () => {
    const { id } = useParams<DefaultQueryParams>();
    const { regionCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { details } = useTypedSelector<ProductDetailsState>((state) => state.productDetails);
    const productCulture = useTypedSelector<ProductPortfolioState>(
        (store) => store.productPortfolio
    );
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const headerImage =
        details?.content?.headerImage !== undefined
            ? details?.content?.headerImage
            : details?.content?.canImage;
    const queryContent = { ids: [id] };

    const header = details ? getProductDetailsHeader(details, regionCode) : '';

    const features = details?.features ? details.features.filter(feature => !!feature.productPortfolio) : null;

    const inkFeatures = features
        ? features.filter((item) => item.type === 'INKS')
        : null;
    const varnishFeatures = features
        ? features.filter((item) => item.type === 'VARNISH')
        : null;
    const effectsFeatures = features
        ? features.filter((item) => item.type === 'EFFECTS')
        : null;
    const productEnhancements = features
        ? features.filter((item) => item.type === 'SPCL')
        : null;
    const fill = features ? features.filter((item) => item.type === 'FILL') : null;

    useTranslation();
    useEffect(() => {
        // Region code check is for issues when the user reloads the page
        if (id || regionCode !== productCulture.regionCode) {
            dispatch(loadProductDetails(id));
        }
    }, [dispatch, id, productCulture, regionCode]);

    const icon = (
        <img
            src={process.env.PUBLIC_URL + '/assets/DesignInspiration_Icon_white.svg'}
            alt="Inspiration Icon"
        />
    );

    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <NavigationalPageTemplate
            banner={{
                header: header,
                description: details?.content?.description || '',
                link: '/product-portfolio',
                linkContent: <Trans i18nKey="backToPortfolio">Back to Portfolio</Trans>,
                imageUrl: headerImage,
                buttonLink: `/content/${regionCode}/Pages/Design-Inspiration`,
                buttonContent: <Trans i18nKey="designInspiration">Design Inspiration</Trans>,
                buttonIcon: icon
            }}
            subheader={
                regionCode !== 'NA' && <ProductDetailsSubheader queryContent={queryContent} />
            }
        >
            <Grid container item xs={12}>
                <Grid item xs={12}>
                    <Typography variant="h3" className={classes.sectionHeader}>
                        <Trans i18nKey="productDetails">Product Details</Trans>
                    </Typography>
                </Grid>
                {matches ? (
                    <Grid item xs={12}>
                        {details && (
                            <ProductDetailsSidePanel details={details} regionCode={regionCode} />
                        )}
                    </Grid>
                ) : null}
                <Grid item xs={12} sm={6}>
                    {details ? (
                        <ProductDetailsTable details={details} regionCode={regionCode} />
                    ) : null}
                </Grid>
                <Grid item xs={1} />
                {!matches
                    ? details && (
                          <ProductDetailsSidePanel details={details} regionCode={regionCode} />
                      )
                    : null}
            </Grid>
            <div className={classes.lineSeperator} />
            <Grid container>
                <Grid item md={12}>
                    <Typography variant="h3" className={classes.contentTitle}>
                        <Trans i18nKey="canEffectsAndFeatures">
                            Can Effects & Features Available for this Can
                        </Trans>
                    </Typography>

                    {inkFeatures && inkFeatures.length > 0 ? (
                        <Grid item xs={12}>
                            <Typography className={classes.featureHeader}>
                                <Trans i18nKey="inks">Inks</Trans>
                            </Typography>
                        </Grid>
                    ) : null}

                    <Grid container item xs={12} className={classes.featureSection}>
                        {inkFeatures &&
                            inkFeatures.map((feature, index) => (
                                <Grid item xs={6} sm={3} md={2} key={index}>
                                    <ProductFeatureContent feature={feature} key={index} />
                                </Grid>
                            ))}
                    </Grid>
                    {varnishFeatures && varnishFeatures.length > 0 ? (
                        <Grid item xs={12}>
                            <Typography className={classes.featureHeader}>
                                <Trans i18nKey="overvarnish">Overvarnish</Trans>
                            </Typography>
                        </Grid>
                    ) : null}
                    <Grid container item xs={12} className={classes.featureSection}>
                        {varnishFeatures &&
                            varnishFeatures.map((feature, index) => (
                                <Grid item xs={6} sm={3} md={2} key={index}>
                                    <ProductFeatureContent feature={feature} key={index} />
                                </Grid>
                            ))}
                    </Grid>
                    {effectsFeatures && effectsFeatures.length > 0 ? (
                        <Grid item>
                            <Typography className={classes.featureHeader}>
                                <Trans i18nKey="effects">Effects</Trans>
                            </Typography>
                        </Grid>
                    ) : null}
                    <Grid container item xs={12} className={classes.featureSection}>
                        {effectsFeatures &&
                            effectsFeatures.map((feature, index) => (
                                <Grid item xs={6} sm={3} md={2} key={index}>
                                    <ProductFeatureContent feature={feature} key={index} />
                                </Grid>
                            ))}
                    </Grid>
                    {productEnhancements && productEnhancements.length > 0 ? (
                        <Grid item>
                            <Typography className={classes.featureHeader}>
                                <Trans i18nKey="productEnhancements">Product Enhancements</Trans>
                            </Typography>
                        </Grid>
                    ) : null}
                    <Grid container item xs={12} className={classes.featureSection}>
                        {productEnhancements &&
                            productEnhancements.map((feature, index) => (
                                <Grid item xs={6} sm={3} md={2} key={index}>
                                    <ProductFeatureContent feature={feature} key={index} />
                                </Grid>
                            ))}
                    </Grid>
                    {fill && fill.length > 0 ? (
                        <Grid item>
                            <Typography className={classes.featureHeader}>
                                <Trans i18nKey="compatibleFilling">Compatible Filling</Trans>
                            </Typography>
                        </Grid>
                    ) : null}
                    <Grid container item xs={12} className={classes.featureSection}>
                        {fill &&
                            fill.map((feature, index) => (
                                <Grid item xs={6} sm={3} md={2} key={index}>
                                    <ProductFeatureContent feature={feature} key={index} />
                                </Grid>
                            ))}
                    </Grid>
                </Grid>
                <Grid item xs={1} />
            </Grid>
        </NavigationalPageTemplate>
    );
};

export default ProductDetailsPage;
