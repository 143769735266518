import React from 'react';
import { makeStyles, Grid, Collapse, Typography } from '@material-ui/core';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import { DeliveryShipment, ProductToShip } from '../../../../store/reducers/shipping-dashboard';
import {
    blackWeight,
    ballGray,
    small,
    warningLabel,
    ballBlue,
    boldWeight
} from '../../../../themes/globalConstants';
import { generateKey } from '../../../../utility/helpers/order-helpers';
import { Alert } from '@material-ui/lab';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import CancelDeliveryOrderConfirmationConfigRow from './CancelDeliveryOrderConfirmationConfigRow';
import CancelDeliveryOrderConfirmationConfigColumns from './CancelDeliveryOrderConfirmationConfigColumns';
import { Trans } from 'react-i18next';

interface Props {
    items: ProductToShip[];
    warning?: boolean;
    warningText?: React.ReactNode;
    shipment: DeliveryShipment;
}

const useStyles = makeStyles((theme) => ({
    tableWrapper: {
        width: '100%',
        '& .MuiPaper-root': {
            boxShadow: 'unset'
        },
        '& .MuiToolbar-root': {
            minHeight: 25,
            marginTop: '-2em',
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                }
            }
        },
        '& .MuiTableSortLabel-root': {
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em'
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableCell-head': {
            color: ballGray,
            fontSize: small,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            '&:not(:first-of-type)': {
                borderLeft: 'unset'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                '&:nth-child(1)': {
                    width: '5%'
                },
                '&:nth-child(2)': {
                    width: '12.5%'
                },
                '&:nth-child(3)': {
                    width: '20%'
                },
                '&:nth-child(4)': {
                    width: '8%'
                },
                '&:nth-child(5)': {
                    width: '10%'
                },
                '&:nth-child(6)': {
                    width: '6.5%'
                },
                '&:nth-child(7)': {
                    width: '8%'
                },
                '&:nth-child(8)': {
                    width: '10%'
                },
                '&:nth-child(9)': {
                    width: '20%'
                }
            }
        }
    },
    warningContainer: {
        marginTop: '1em',
        width: '100%',
        padding: '0.875em 0',
        borderRadius: '.125em',
        '& .MuiAlert-icon': {
            color: ballBlue,
            display: 'flex',
            alignItems: 'center'
        }
    },
    warning: {
        backgroundColor: warningLabel
    },
    warningText: {
        fontWeight: boldWeight
    }
}));

export default function CancelDeliveryOrderConfirmationConfigGrid({
    items,
    warning = false,
    warningText,
    shipment
}: Props) {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const CollapsibleHeader = () => {
        return (
            <>
                {warning && (
                    <Grid container justify="center" data-testid="warning-message">
                        <Collapse in={warning} className={classes.warningContainer}>
                            <Alert severity="warning" className={classes.warning}>
                                <Typography className={classes.warningText}>
                                    {warningText}
                                </Typography>
                            </Alert>
                        </Collapse>
                    </Grid>
                )}
            </>
        );
    };

    const CustomToolbarOptions = new ToolbarOptions({
        customItems: <CollapsibleHeader />,
        exportButton: false,
        printButton: false,
        advancePagination: false,
        enablePagination: false,
        searchText: false
    });

    const CancelDeliveryOrderRow = ({ row }: { row: Partial<ProductToShip> }) => {
        let itemIndex = items.findIndex((item) => item.productSku === row.productSku);

        return (
            <CancelDeliveryOrderConfirmationConfigRow
                key={generateKey('product')}
                item={row as ProductToShip}
                sequence={itemIndex + 1}
                deliveryInstructions={
                    shipment.deliveryInstructions ? (
                        <Typography>{shipment.deliveryInstructions}</Typography>
                    ) : (
                        <Typography>
                            <Trans i18nKey="none">None</Trans>
                        </Typography>
                    )
                }
            />
        );
    };

    return (
        <Grid container className={classes.tableWrapper}>
            <DataGrid
                columns={CancelDeliveryOrderConfirmationConfigColumns.getCancelDeliveryOrderConfirmationConfigColumns(
                    cultureCode
                )}
                dataSource={items}
                gridName="Cancel Delivery Order Config Grid"
                rowComponent={CancelDeliveryOrderRow}
                toolbarOptions={CustomToolbarOptions}
            />
        </Grid>
    );
}
