import React, { useEffect, useState } from 'react';
import PdfPageTemplate from '../../../templates/PdfPageTemplate';
import { View, BlobProvider, Text } from '@react-pdf/renderer';
import { isIE } from '../../../../utility/helpers/user-helpers';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { getAccountAddress } from '../../../../utility/helpers/address-helpers';
import StandardPDF from '../../../reusable/molecules/StandardPDF';
import StandardPDFFlex from '../../../reusable/molecules/StandardPDFFlex';
import StandardPDFLabeledText from '../../../reusable/molecules/StandardPDFLabeledText';
import { ShipToLocation } from '../../../../store/reducers/ship-to-locations';
import { blackWeight, boldWeight, regularWeight } from '../../../../themes/globalConstants';
import pdfStyles from '../../../../themes/pdfStyles';
import {
    customerTypeOptions,
    freightManagementOptions
} from '../../../../static-data/dropdown-lists';

interface Props {
    title: string;
    newShipToLocation: ShipToLocation;
}

const ShipToLocationAddConfirmationPDF = ({ title, newShipToLocation }: Props) => {
    const { t } = useTranslation();
    const { adminAccounts, adminAccountsLoaded } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const [billToName, setBillToName] = useState<string>('');
    const [billToAddress, setBillToAddress] = useState<string>('');
    const [canFreightManagement, setCanFreightManagement] = useState<string | undefined>(undefined);
    const [endFreightManagement, setEndFreightManagement] = useState<string | undefined>(undefined);
    const [customerType, setCustomerType] = useState<string | undefined>(undefined);
    const [specialtyTab, setSpecialtyTab] = useState<string>('');
    const [specialtyPrinting, setSpecialtyPrinting] = useState<string>('');

    useEffect(() => {
        if (adminAccounts && adminAccountsLoaded && newShipToLocation) {
            const matchingBillTo = adminAccounts?.find(
                (acc) => acc.accountId === newShipToLocation.billToID
            );
            if (matchingBillTo) {
                setBillToName(matchingBillTo.name);
                setBillToAddress(getAccountAddress(matchingBillTo));
            }
        }
    }, [adminAccounts, adminAccountsLoaded, newShipToLocation]);
    useEffect(() => {
        if (newShipToLocation) {
            if (newShipToLocation.canFreight) {
                const canFreightMgmt = freightManagementOptions.find(
                    (option) => option.value === newShipToLocation.canFreight
                );
                setCanFreightManagement(canFreightMgmt?.i18nKey);
            }
            if (newShipToLocation.endFreight) {
                const endFreightMgmt = freightManagementOptions.find(
                    (option) => option.value === newShipToLocation.endFreight
                );
                setEndFreightManagement(endFreightMgmt?.i18nKey);
            }
            if (newShipToLocation.customerType) {
                const custType = customerTypeOptions.find(
                    (option) => option.value === newShipToLocation.customerType
                );
                setCustomerType(custType?.i18nKey);
            }

            const specialtyTabValues: string[] = [];
            if (newShipToLocation.laserTab) {
                specialtyTabValues.push(t('laserTab', 'Laser Tab'));
            }
            if (newShipToLocation.punch) {
                specialtyTabValues.push(t('punch', 'Punch'));
            }
            if (newShipToLocation.coloredTab) {
                specialtyTabValues.push(t('coloredTab', 'Colored Tab'));
            }
            if (newShipToLocation.coloredShell) {
                specialtyTabValues.push(t('coloredShell', 'Colored Shell'));
            }
            setSpecialtyTab(specialtyTabValues.join(', '));
            const specialtyPrintingValues: string[] = [];
            if (newShipToLocation.basecoat) {
                specialtyPrintingValues.push(t('basecoat', 'Basecoat'));
            }
            if (newShipToLocation.retort) {
                specialtyPrintingValues.push(t('retort', 'Retort'));
            }
            if (newShipToLocation.tactile) {
                specialtyPrintingValues.push(t('tactile', 'Tactile'));
            }
            if (newShipToLocation.satin) {
                specialtyPrintingValues.push(t('satin', 'Satin'));
            }
            setSpecialtyPrinting(specialtyPrintingValues.join(', '));
        }
    }, [newShipToLocation, freightManagementOptions, customerTypeOptions]);

    const headerStyle = {
        color: 'black',
        fontSize: 16,
        fontFamily: 'Lato Bold'
    };
    const sectionStyle = {
        color: 'black',
        fontSize: 10,
        fontWeight: blackWeight,
        marginTop: 16,
        marginBottom: 5,
        fontFamily: 'Lato Bold'
    };
    const formDataBoldStyle = {
        color: 'black',
        fontSize: 10,
        fontWeight: boldWeight,
        marginTop: 5,
        marginBottom: 0,
        fontFamily: 'Lato Bold'
    };
    const formDataStyle = {
        color: 'black',
        fontSize: 10,
        fontWeight: regularWeight,
        marginTop: 5,
        marginBottom: 0,
        fontFamily: 'Lato'
    };
    const formAddressStyle = {
        color: 'black',
        fontSize: 10,
        fontWeight: regularWeight,
        marginTop: 5,
        marginBottom: 10,
        fontFamily: 'Lato'
    };

    const getProductSelection = () => {
        const cans = `${t('cans', 'Cans')}/${t('bottles', 'Bottles')}`;
        const ends = t('ends', 'Ends');
        switch (newShipToLocation.productSelection) {
            case 'cans':
                return cans;
            case 'ends':
                return ends;
            case 'both':
                return `${cans}, ${ends}`;
            default:
                return '';
        }
    };

    const getAnticipatedItems = () => {
        return newShipToLocation.items.map((item) => {
            return <Text style={formDataStyle}>{`#${item.itemID}-${item.itemName}`}</Text>;
        });
    };

    const getCustomerType = () => {
        if (customerType) {
            const label = t('customerType', 'Customer Type');
            const value = t(customerType, newShipToLocation.customerType);
            return `${label}: ${value}`;
        }
    };

    const getCanFreightManagement = () => {
        if (canFreightManagement) {
            const label = t('canFreightManagement', 'Can Freight Management');
            const value = t(canFreightManagement, newShipToLocation.canFreight);
            return (
                <Text style={formDataStyle}>
                    {label}: {value}
                </Text>
            );
        }
    };

    const getEndFreightManagement = () => {
        if (endFreightManagement) {
            const label = t('endFreight', 'End Freight');
            const value = t(endFreightManagement, newShipToLocation.endFreight);
            return (
                <Text style={formDataStyle}>
                    {label}: {value}
                </Text>
            );
        }
    };

    const getSpecialtyEquipment = () => {
        if (newShipToLocation.specialEquipment) {
            return (
                <Text style={formDataStyle}>
                    {`${t('specialtyEquipment', 'Specialty Equipment')}: ${
                        newShipToLocation.specialEquipment
                    }`}
                </Text>
            );
        }
    };

    const getSpecialtyPrinting = () => {
        if (specialtyPrinting.length) {
            return (
                <Text style={formDataStyle}>
                    {`${t('specialtyPrintingReview', 'Specialty Printing')}: ${specialtyPrinting}`}
                </Text>
            );
        }
    };
    const getNewLocationAddress = () => {
        const addressFields: any = [];
        addressFields.push(
            <Text style={formDataStyle}>{newShipToLocation.newShipToAddress1}</Text>
        );
        if (newShipToLocation.newShipToAddress2) {
            addressFields.push(
                <Text style={formDataStyle}>{newShipToLocation.newShipToAddress2}</Text>
            );
        }
        if (newShipToLocation.newShipToAddress3) {
            addressFields.push(
                <Text style={formDataStyle}>{newShipToLocation.newShipToAddress3}</Text>
            );
        }
        addressFields.push(
            <Text style={formAddressStyle}>
                {`${newShipToLocation.newShipToCity} ${newShipToLocation.newShipToStateProvince} ${newShipToLocation.newShipToPostalCode}`.trim()}
            </Text>
        );
        return addressFields;
    };

    const getSpecialtyTabs = () => {
        if (specialtyTab.length) {
            return (
                <Text style={formDataStyle}>
                    {`${t('specialtyTabs', 'Specialty Tabs')}: ${specialtyTab}`}
                </Text>
            );
        }
    };

    const getRequest = (request: ShipToLocation) => {
        return (
            <View style={{ marginBottom: '30px' }}>
                <StandardPDFFlex
                    justify="space-between"
                    style={{
                        paddingVertical: '8px',
                        borderBottom: '1px solid #B9D3DC',
                        borderTop: '1px solid #B9D3DC',
                        marginBottom: '8px'
                    }}
                >
                    <StandardPDFFlex justify="space-between">
                        {
                            <Text style={headerStyle}>
                                {t('requestNewShipTo', 'Request New Ship To').toLocaleUpperCase()}
                            </Text>
                        }
                    </StandardPDFFlex>
                    <StandardPDFFlex justify="flex-end">
                        <StandardPDFLabeledText
                            labelText={t('dateSubmitted', 'Date Submitted').toLocaleUpperCase()}
                            headerText={moment(request.submittedDate).format('MM/DD/YYYY')}
                            headerSize={10}
                            textAlign={'right'}
                        />
                    </StandardPDFFlex>
                </StandardPDFFlex>
                <StandardPDFFlex style={{ borderBottom: '1px solid #202020' }}>
                    {
                        <Text style={sectionStyle}>
                            {t('customerInformation', 'Customer Information')}
                        </Text>
                    }
                </StandardPDFFlex>
                <StandardPDFFlex>
                    {
                        <>
                            <Text
                                style={formDataBoldStyle}
                            >{`${billToName}, ${newShipToLocation.billToID}`}</Text>
                        </>
                    }
                </StandardPDFFlex>
                <StandardPDFFlex>
                    {
                        <>
                            <Text style={formDataStyle}>{billToAddress}</Text>
                        </>
                    }
                </StandardPDFFlex>
                <StandardPDFFlex style={{ borderBottom: '1px solid #202020' }}>
                    {
                        <Text style={sectionStyle}>
                            {t('newShipToInformation', 'New Ship To Information')}
                        </Text>
                    }
                </StandardPDFFlex>
                <StandardPDFFlex>
                    <View style={pdfStyles.tableRow}>
                        <View style={pdfStyles.borderLessTableColWide7}>
                            <Text style={formDataBoldStyle}>{newShipToLocation.newShipToName}</Text>
                            <Text style={formDataStyle}>{getCustomerType()}</Text>
                            {getNewLocationAddress().map((field) => {
                                return field;
                            })}
                            {newShipToLocation.cfid && (
                                <Text style={formDataStyle}>
                                    {`${t('cfid', 'CFID')}: ${newShipToLocation.cfid}`}
                                </Text>
                            )}
                            {newShipToLocation.taxID && (
                                <Text style={formDataStyle}>
                                    {`${t('taxId', 'Tax ID')}: ${newShipToLocation.taxID}`}
                                </Text>
                            )}
                            {newShipToLocation.vatid && (
                                <Text style={formDataStyle}>
                                    {`${t('vat', 'VAT')}: ${newShipToLocation.vatid}`}
                                </Text>
                            )}
                            <Text style={formDataStyle}>
                                {`${t('estimatedAnnualVolume', 'Estimated Annual Volume')}: ${
                                    newShipToLocation.annualVolume
                                }`}
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColWide7}>
                            <Text style={formDataBoldStyle}>{t('userAdmin', 'User Admin')}</Text>
                            <Text style={formDataStyle}>{newShipToLocation.userAdminName}</Text>
                            <Text style={formDataStyle}>{newShipToLocation.userAdminPhone}</Text>
                            <Text style={formDataStyle}>{newShipToLocation.userAdminEmail}</Text>
                        </View>
                    </View>
                </StandardPDFFlex>
                <StandardPDFFlex style={{ borderBottom: '1px solid #202020' }}>
                    {
                        <Text style={sectionStyle}>
                            {t('productInformation', 'Product Information')}
                        </Text>
                    }
                </StandardPDFFlex>
                <StandardPDFFlex>
                    <View style={pdfStyles.tableRow}>
                        <View style={pdfStyles.borderLessTableColWide7}>
                            <Text style={formDataStyle}>{getProductSelection()}</Text>
                            {getSpecialtyPrinting()}
                            {getSpecialtyTabs()}
                        </View>
                        <View style={pdfStyles.borderLessTableColWide7}>
                            <Text style={formDataStyle}>
                                {`${t('anticipatedItems', 'Anticipated Items')}`}
                            </Text>
                            {getAnticipatedItems()}
                        </View>
                    </View>
                </StandardPDFFlex>
                <StandardPDFFlex style={{ borderBottom: '1px solid #202020' }}>
                    {
                        <Text style={sectionStyle}>
                            {t('freightInformation', 'Freight Information')}
                        </Text>
                    }
                </StandardPDFFlex>
                <StandardPDFFlex>
                    <View style={pdfStyles.tableRow}>
                        <View style={pdfStyles.borderLessTableColWide7}>
                            {getCanFreightManagement()}
                            {getEndFreightManagement()}
                            {getSpecialtyEquipment()}
                            <Text style={formDataStyle}>
                                {`${t('layersPerPallet', 'Layers per Pallet')}: ${
                                    newShipToLocation.layersPerPallet
                                }`}
                            </Text>
                        </View>
                    </View>
                </StandardPDFFlex>
            </View>
        );
    };

    const myDoc = (
        <StandardPDF title={title}>
            <View>{getRequest(newShipToLocation)}</View>
        </StandardPDF>
    );

    if (!isIE()) {
        return <PdfPageTemplate title={title}>{myDoc}</PdfPageTemplate>;
    } else {
        return (
            <BlobProvider document={myDoc}>
                {({ blob, url, loading, error }) => {
                    if (blob && window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
                        (window.navigator as any).msSaveOrOpenBlob(blob);
                    }
                    return null;
                }}
            </BlobProvider>
        );
    }
};

export default ShipToLocationAddConfirmationPDF;
