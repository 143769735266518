import moment from 'moment';
import { QuantityType, RowsOrColumns } from '../../utility/services/orders-service';
import {
    MAKEIT_BULK_UPLOAD_ATM_LOADING,
    MAKEIT_BULK_UPLOAD_ATM_LOADED,
    MAKEIT_BULK_UPLOAD_RESET_STATE,
    MAKEIT_BULK_ATM_SUBMIT_REVIEW,
    MAKEIT_BULK_UPLOAD_ATM_UPDATE_PALLET_QUANTITY,
    MAKEIT_BULK_ATM_SNOSKU_ORDER,
    MAKEIT_BULK_UPLOAD_ATM_LOAD_ALLOCATIONS,
    MAKEIT_BULK_UPLOAD_ATM_LOAD_ALLOCATIONS_ERROR,
    MAKEIT_BULK_ATM_LOAD_ORDERS,
    MAKEIT_BULK_ATM_DELETE_PRODUCT,
    MAKEIT_BULK_ATM_ADD_PRODUCT,
    MAKEIT_BULK_ATM_COPY_PRODUCT,
    MAKEIT_BULK_ATM_SUBMIT_ORDERS_SUCCESS,
    MAKEIT_BULK_ATM_SUBMIT_ORDERS_LOADING,
    MAKEIT_BULK_ATM_SUBMIT_ORDERS_ERROR,
    MAKEIT_BULK_ATM_SAVE_DRAFT_SUCCESS,
    MAKEIT_BULK_ATM_SAVE_DRAFT_LOADING,
    MAKEIT_BULK_ATM_SAVE_DRAFT_ERROR,
    MAKEIT_BULK_ATM_EDIT_ORDER_LOADED,
    MAKEIT_BULK_ATM_EDIT_ORDER_LOADING,
    MAKEIT_BULK_ATM_EDIT_ORDER_ERROR,
    MAKEIT_BULK_ATM_UPDATED_PRODUCT_SKU,
    MAKEIT_BULK_ATM_EXISTING_ORDERS,
    MAKEIT_BULK_UPLOAD_ATM_TEMPLATE_ERROR,
    MAKEIT_BULK_UPLOAD_ATM_TEMPLATE_CLEAR,
    MAKEIT_BULK_UPLOAD_ATM_RESET_ALLOCATIONS_STATE,
    MAKEIT_BULK_UPLOAD_REFRESH_STATE,
    MAKEIT_BULK_UPLOAD_ATM_LOAD_ALLOCATIONS_LOADING,
    MAKEIT_BULK_ATM_DRAFT_ORDER_ERROR,
    MAKEIT_BULK_ATM_DRAFT_ORDER_LOADED,
    MAKEIT_BULK_ATM_DRAFT_ORDER_LOADING,
    MAKEIT_BULK_ATM_ADD_PRODUCT_ERROR,
    MAKEIT_BULK_ATM_UPDATE_CUSTOMER_PO,
    MAKEIT_BULK_ATM_ADD_WEEK
} from '../actions/action-types';
import { TemplateMapping } from './bulk-upload';
import { DraftInformation } from './make-it-drafts';
import { MakeItLineError } from './makeit-bulk-upload';
import { ProductionOrderLine } from './makeit-dashboard';

export interface MakeItBulkATMLineItem extends ProductionOrderLine {
    userSuppliedProductId: string;
    snoSku: string;
    productSku?: string;
    coating?: string;
    eachesQuantity?: number;
    originalQuantity?: string;
    requestedDate: string;
    originalCsvLineNumber?: number;
    isCustomerProductIdDistinct: boolean;
    candidateProductSkus?: string[];
    customerProductId?: string;
    deleted: boolean;
    palletsRounded?: boolean;
    shipToId?: string;
    shipToName?: string;
    shipToAddress?: string;
    weekStart?: string;
    displayQuantity?: number;
    productStatus?: string;
    notIncludedInCsv?: boolean;
    supplyPlanWeeks?: string[];
    isRequestedDateAfterLeadTime?: boolean;
}

export interface MakeItBulkATMLinesBySnoSku {
    snoSku: string;
    lines: MakeItBulkATMLineItem[];
    isActive: boolean;
    weekStart: string;
    totalPalletQuantity: number;
    allocationAmount?: number;
    hasError?: boolean;
    hasWarning?: boolean;
    initiallyEmpty?: boolean;
}

export interface MakeItBulkATMOrderByWeekAndSku {
    weekStart: string;
    shipToId: number;
    linesBySnoSku: MakeItBulkATMLinesBySnoSku[];
    state: 'valid' | 'error' | 'warning' | 'locked';
    shipToName?: string;
    shipToAddress?: string;
    productionOrderId?: number;
    productionOrderNumber?: number;
}

export interface SnoSkuPalletQuantity {
    snoSku: string;
    palletQuantity: number;
}

export interface DeletedSnoSku {
    shipToId: number;
    snoSku: string;
    date: string;
}

export interface MakeItBulkATMOrder {
    customerProductionOrderId: string;
    productionOrderId?: number;
    productionOrderNumber?: number;
    weekStart: string;
    shipToId: number;
    lines: MakeItBulkATMLineItem[];
    canQuantityPallets: number;
    canQuantitySKUs: number;
    canQuantityEaches?: number;
    endQuantityPallets: number;
    endQuantitySKUs: number;
    endQuantityEaches?: number;
    createdBy?: string;
    submittedBy?: string;
    createDate?: string;
    atmWeekStart?: string;
    status?: string;
    otherOrdersWeeklyPalletQuantityBySnoSku?: SnoSkuPalletQuantity[];
}

export interface MakeItBulkATMOrderRequest extends MakeItBulkATMOrder {
    createdName?: string;
    region?: string;
    shipToName?: string;
    shipToAddress?: string;
}

export interface BulkUploadDeliveryReviewRequest {
    userName: string;
    csvData: FormData;
}

export interface AllocationRequest {
    shipToIds: number[];
    startDate: string[];
    endDate: string[];
}

export interface WeekQuantity {
    quantity: string;
}

export enum ForecastStatus {
    Forecast = 'FCST',
    ATM = 'ATM',
    Unknown = 'UNKNOWN'
}

export interface SnoSkuAllocationsByWeek {
    shipToId: number;
    week: string;
    snoSku: string;
    quantity: number;
    ignoreAllocation?: boolean;
}
export interface ProductAvailableBalance {
    productSku: string;
    quantity: number;
}
export interface AvailableBalance {
    shipToId: number;
    requestedDate: string;
    balances: ProductAvailableBalance[];
}

export interface BulkUploadMakeItATMState {
    atmTemplateMappings?: TemplateMapping[];
    loading: boolean;
    loaded: boolean;
    ordersLoaded: boolean;
    error?: any;
    templateError?: any;
    file?: File;
    atmOrders?: MakeItBulkATMOrder[];
    existingOrders: MakeItBulkATMOrder[];
    existingDrafts: MakeItBulkATMOrder[];
    products?: MakeItBulkATMLineItem[];
    atmLineErrors?: MakeItLineError[];
    quantityType?: QuantityType;
    rowsOrColumns?: RowsOrColumns;
    weekQuantities?: WeekQuantity[];
    firstRowOfData?: number;
    ordersBySnoSku?: MakeItBulkATMOrderByWeekAndSku[];
    allocations?: SnoSkuAllocationsByWeek[];
    allocationsAndProducts?: SnoSkuAllocationsByWeek[];
    submitAtmOrdersSubmissionState: 'idle' | 'loading' | 'error' | 'success';
    submitAtmOrderSaveDraftState: 'idle' | 'loading' | 'error' | 'success';
    isEdit?: boolean;
    weeks?: moment.Moment[];
    allocationsLoadingState?: 'idle' | 'loading' | 'error' | 'success';
    draft?: DraftInformation;
    isRevision?: boolean;
    addedWeeks?: string[];
    deletedOrders?: MakeItBulkATMOrder[];
    weekToDisplay?: string[];
    availableBalances?: AvailableBalance[];
    readonly?: boolean;
}

const initialState: BulkUploadMakeItATMState = {
    atmTemplateMappings: undefined,
    loading: false,
    loaded: false,
    ordersLoaded: false,
    atmOrders: undefined,
    ordersBySnoSku: undefined,
    submitAtmOrdersSubmissionState: 'idle',
    submitAtmOrderSaveDraftState: 'idle',
    allocationsLoadingState: 'idle',
    existingOrders: [],
    existingDrafts: [],
    weeks: undefined,
    isEdit: false,
    isRevision: false,
    addedWeeks: [],
    deletedOrders: [],
    weekToDisplay: undefined,
    availableBalances: undefined,
    readonly: false
};

const bulkUploadMakeItATMState = (
    state: BulkUploadMakeItATMState = initialState,
    action: { type: string; [x: string]: any }
): BulkUploadMakeItATMState => {
    switch (action.type) {
        case MAKEIT_BULK_UPLOAD_ATM_LOADING:
            return { ...state, loaded: false, loading: true, error: undefined };
        case MAKEIT_BULK_UPLOAD_ATM_LOADED:
            return {
                ...state,
                atmTemplateMappings: action.mappings,
                quantityType: action.quantityType,
                rowsOrColumns: action.rowsOrColumns,
                weekQuantities: action.weekQuantities,
                firstRowOfData: action.firstRowOfData,
                loading: false,
                loaded: true,
                error: undefined,
                templateError: undefined
            };
        case MAKEIT_BULK_ATM_SUBMIT_REVIEW:
            return {
                ...state,
                atmOrders: action.atmOrders,
                loading: false,
                loaded: true,
                atmLineErrors: action.lineErrors,
                error: undefined,
                weeks: action.weeks,
                isEdit: action.isEdit,
                availableBalances: action.availableBalances
            };
        case MAKEIT_BULK_ATM_SNOSKU_ORDER:
            return {
                ...state,
                ordersBySnoSku: action.ordersBySnoSku
            };
        case MAKEIT_BULK_UPLOAD_REFRESH_STATE:
            return {
                ...state,
                loading: false,
                loaded: false,
                ordersLoaded: false,
                atmOrders: undefined,
                ordersBySnoSku: undefined,
                submitAtmOrdersSubmissionState: 'idle',
                existingOrders: [],
                atmLineErrors: undefined,
                isEdit: false,
                allocations: undefined,
                allocationsAndProducts: undefined,
                addedWeeks: [],
                deletedOrders: [],
                availableBalances: undefined
            };
        case MAKEIT_BULK_UPLOAD_RESET_STATE:
            return {
                ...initialState
            };
        case MAKEIT_BULK_UPLOAD_ATM_RESET_ALLOCATIONS_STATE:
            return {
                ...state,
                allocations: action.allocations
            };
        case MAKEIT_BULK_UPLOAD_ATM_UPDATE_PALLET_QUANTITY:
            return {
                ...state,
                atmOrders: action.atmOrders
            };
        case MAKEIT_BULK_ATM_ADD_PRODUCT:
            return {
                ...state,
                atmOrders: action.atmOrders,
                allocationsAndProducts: action.allocationsAndProducts
            };
        case MAKEIT_BULK_ATM_LOAD_ORDERS:
            return {
                ...state,
                atmOrders: action.atmOrders,
                allocationsAndProducts: action.allocationsAndProducts,
                deletedOrders: action.deletedOrders,
                weekToDisplay: !!action.weekToDisplay ? action.weekToDisplay : undefined
            };
        case MAKEIT_BULK_ATM_ADD_WEEK:
            return {
                ...state,
                atmOrders: action.atmOrders,
                allocationsAndProducts: action.allocationsAndProducts,
                addedWeeks: action.addedWeeks
            };
        case MAKEIT_BULK_ATM_ADD_PRODUCT_ERROR:
            return {
                ...state,
                atmOrders: [],
                error: action.error
            };
        case MAKEIT_BULK_ATM_COPY_PRODUCT:
            return {
                ...state,
                atmOrders: action.atmOrders
            };
        case MAKEIT_BULK_ATM_UPDATED_PRODUCT_SKU:
            return {
                ...state,
                atmOrders: action.atmOrders
            };
        case MAKEIT_BULK_ATM_EXISTING_ORDERS:
            return {
                ...state,
                existingOrders: action.existingOrders,
                existingDrafts: action.existingDrafts,
                ordersLoaded: true
            };
        case MAKEIT_BULK_UPLOAD_ATM_LOAD_ALLOCATIONS_LOADING:
            return {
                ...state,
                allocationsLoadingState: 'loading'
            };
        case MAKEIT_BULK_UPLOAD_ATM_LOAD_ALLOCATIONS:
            return {
                ...state,
                allocationsLoadingState: 'success',
                allocations: action.allocations
            };
        case MAKEIT_BULK_UPLOAD_ATM_LOAD_ALLOCATIONS_ERROR:
            return {
                ...state,
                allocationsLoadingState: 'error',
                error: action.error
            };
        case MAKEIT_BULK_ATM_DELETE_PRODUCT:
            return { ...state, atmOrders: [...action.atmOrders] };
        case MAKEIT_BULK_ATM_SUBMIT_ORDERS_SUCCESS: {
            return {
                ...state,
                submitAtmOrdersSubmissionState: 'success',
                atmOrders: action.atmOrders,
                weeks: undefined
            };
        }
        case MAKEIT_BULK_ATM_SUBMIT_ORDERS_LOADING: {
            return {
                ...state,
                submitAtmOrdersSubmissionState: 'loading'
            };
        }
        case MAKEIT_BULK_ATM_SUBMIT_ORDERS_ERROR: {
            return {
                ...state,
                submitAtmOrdersSubmissionState: 'error',
                error: action.error
            };
        }
        case MAKEIT_BULK_ATM_SAVE_DRAFT_SUCCESS: {
            return {
                ...state,
                submitAtmOrderSaveDraftState: 'success',
                error: undefined
            };
        }
        case MAKEIT_BULK_ATM_SAVE_DRAFT_LOADING: {
            return {
                ...state,
                submitAtmOrderSaveDraftState: 'loading'
            };
        }
        case MAKEIT_BULK_ATM_SAVE_DRAFT_ERROR: {
            return {
                ...state,
                submitAtmOrderSaveDraftState: 'error',
                error: action.error
            };
        }
        case MAKEIT_BULK_ATM_EDIT_ORDER_LOADING: {
            return {
                ...state,
                loading: true,
                loaded: false,
                submitAtmOrdersSubmissionState: 'idle'
            };
        }
        case MAKEIT_BULK_ATM_EDIT_ORDER_LOADED: {
            return {
                ...state,
                atmOrders: action.atmOrders,
                loading: false,
                loaded: true,
                //Set orders loaded to true for edit flow
                ordersLoaded: true,
                isEdit: true,
                isRevision: true,
                availableBalances: action.availableBalances
            };
        }
        case MAKEIT_BULK_ATM_EDIT_ORDER_ERROR: {
            return {
                ...state,
                error: action.error
            };
        }
        case MAKEIT_BULK_UPLOAD_ATM_TEMPLATE_ERROR: {
            return {
                ...state,
                templateError: action.error
            };
        }
        case MAKEIT_BULK_UPLOAD_ATM_TEMPLATE_CLEAR: {
            return {
                ...state,
                templateError: undefined,
                error: undefined
            };
        }
        case MAKEIT_BULK_ATM_DRAFT_ORDER_LOADING: {
            return {
                ...state,
                loading: true,
                loaded: false,
                submitAtmOrdersSubmissionState: 'idle'
            };
        }
        case MAKEIT_BULK_ATM_DRAFT_ORDER_LOADED: {
            return {
                ...state,
                atmOrders: action.atmOrders,
                loading: false,
                loaded: true,
                atmLineErrors: action.lineErrors,
                error: undefined,
                weeks: action.weeks,
                isEdit: action.isEdit,
                draft: action.draft,
                readonly: action.readonly
            };
        }
        case MAKEIT_BULK_ATM_DRAFT_ORDER_ERROR: {
            return {
                ...state,
                error: action.error
            };
        }
        case MAKEIT_BULK_ATM_UPDATE_CUSTOMER_PO: {
            return {
                ...state,
                atmOrders: action.atmOrders
            };
        }
        default:
            return state;
    }
};

export default bulkUploadMakeItATMState;
