import Axios from 'axios';
import { ProductConfiguration } from '../../store/reducers/product-configurations';

interface ProductConfigurationResponse {
    configuredProducts: ProductConfiguration[]
}
export default class ProductConfigurationService {

    private static createConfig = (accessToken: string) => ({
        headers: { Authorization: `Bearer ${accessToken}` }
    });

    static getProductConfiguration = (accessToken: string) => {
        return Axios.get<ProductConfigurationResponse>(
            `${process.env.REACT_APP_PRODUCT_CONFIGURATION_API}/products/configurations/`,
            ProductConfigurationService.createConfig(accessToken)
        ).catch(() => { });
    }

    static saveConfiguredProduct(product: any, accessToken: string) {
        return Axios.put(
            `${process.env.REACT_APP_PRODUCT_CONFIGURATION_API}/products/configurations`,
            product,
            ProductConfigurationService.createConfig(accessToken)
        );
    }
}