import { ColumnDataType, createColumn } from 'tubular-common';
import { FlagTypes, useFeatureFlag } from '../../../../utility/helpers/feature-flag';
import TranslationService from '../../../../utility/services/translation-service';

export default function DeliveryBulkUploadColumns(culture: string) {
    const cokeFeatureFlag = useFeatureFlag().find((flag) => flag.name === FlagTypes.Coke)?.isActive;

    return [
        createColumn('originalCsvLineNumber', {
            dataType: ColumnDataType.Numeric,
            label: TranslationService.getTranslatedText(culture, 'csvRowNumber'),
            filterable: false,
            sortable: true,
            searchable: false
        }),
        createColumn('sequence', {
            dataType: ColumnDataType.Numeric,
            label: TranslationService.getTranslatedText(culture, 'loadSeq'),
            filterable: true,
            sortable: true
        }),
        createColumn('userSuppliedProductId', {
            dataType: ColumnDataType.String,
            label: TranslationService.getTranslatedText(culture, 'productId'),
            filterable: true,
            sortable: true
        }),
        createColumn('displayId', {
            dataType: ColumnDataType.String,
            visible: false,
            filterable: true,
            sortable: true
        }),
        createColumn('productSku', {
            dataType: ColumnDataType.String,
            visible: false
        }),
        createColumn('name', {
            dataType: ColumnDataType.String,
            visible: false
        }),
        createColumn('type', {
            dataType: ColumnDataType.String,
            visible: false
        }),
        createColumn('description', {
            dataType: ColumnDataType.String,
            label: TranslationService.getTranslatedText(culture, 'productDescription'),
            filterable: true,
            sortable: true,
            searchable: true
        }),
        createColumn('palletQuantity', {
            dataType: ColumnDataType.Numeric,
            label: TranslationService.getTranslatedText(culture, 'quantity'),
            filterable: true,
            sortable: true
        }),
        createColumn('releaseNumber', {
            dataType: ColumnDataType.String,
            label: TranslationService.getTranslatedText(culture, 'releaseNumber'),
            visible: false
        }),
        createColumn('fillerLine', {
            dataType: ColumnDataType.String,
            label: TranslationService.getTranslatedText(culture, 'fillerLineNumber'),
            visible: false
        }),
        createColumn('referenceNumber', {
            dataType: ColumnDataType.String,
            visible: false
        }),
        createColumn('purchaseOrderNumber', {
            dataType: ColumnDataType.String,
            label: TranslationService.getTranslatedText(culture, 'customerPoNumber'),
            filterable: true,
            sortable: true,
            visible: !!cokeFeatureFlag
        }),
        createColumn('updatedDeliveryDate', {
            dataType: ColumnDataType.String,
            visible: false
        }),
        createColumn('edited', {
            dataType: ColumnDataType.Boolean,
            visible: false
        }),
        createColumn('deliveryInstructions', {
            dataType: ColumnDataType.String,
            visible: false
        }),
        createColumn('quantityPerPallet', {
            dataType: ColumnDataType.Numeric,
            visible: false
        }),
        createColumn('palletsRounded', {
            dataType: ColumnDataType.Boolean,
            visible: false
        }),
        createColumn('isCustomerProductIdDistinct', {
            dataType: ColumnDataType.Boolean,
            visible: false
        })
    ];
}
