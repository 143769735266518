import { makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { TradeItProduct } from '../../../../store/reducers/trade-it';
import { ltBlueGrey_15, regularWeight, small } from '../../../../themes/globalConstants';
import CampaignPill from '../../../reusable/atoms/CampaignPill';

interface Props {
    product: TradeItProduct;
}

const useStyles = makeStyles((theme) => ({
    tableRowContainer: {
        '& .helper-text': {
            fontWeight: `${regularWeight} !important`,
            fontSize: small
        },
        '&:hover': {
            backgroundColor: ltBlueGrey_15
        },
        '& > td': {
            padding: '1.250em'
        },
        '& .MuiInputBase-input': {
            textAlign: 'right',
            paddingRight: '.25em'
        },
        '& .MuiTableCell-root': {
            '&:not(:last-child)': {
                paddingLeft: '1.125em'
            }
        },
        '& .normal': {
            fontWeight: `${regularWeight} !important`,
            textAlign: 'right'
        }
    }
}));

export default function TradeItConfirmationRow({ product }: Props) {
    const classes = useStyles();
    const [quantityToTradeEaches, setQuantityToTradeEaches] = useState<number | undefined>(
        undefined
    );
    const [originalShipToProductionBalanceEaches, setOriginalShipToProductionBalanceEaches] =
        useState<number | undefined>(undefined);
    const [receivingShipToProductionBalanceEaches, setReceivingShipToProductionBalanceEaches] =
        useState<number | undefined>(undefined);
    const [originalShipToProductionBalanceUpdated, setOriginalShipToProductionBalanceUpdated] =
        useState<number | undefined>(undefined);
    const [receivingShipToProductionBalanceUpdated, setReceivingShipToProductionBalanceUpdated] =
        useState<number | undefined>(undefined);
    const [
        originalShipToProductionBalanceEachesUpdated,
        setOriginalShipToProductionBalanceEachesUpdated
    ] = useState<number | undefined>(undefined);
    const [
        receivingShipToProductionBalanceEachesUpdated,
        setReceivingShipToProductionBalanceEachesUpdated
    ] = useState<number | undefined>(undefined);

    useEffect(() => {
        if (product) {
            setOriginalShipToProductionBalanceEaches(
                product.originalShipToProductionBalance && product.quantityPerPallet
                    ? product.originalShipToProductionBalance * product.quantityPerPallet
                    : undefined
            );
            setReceivingShipToProductionBalanceEaches(
                product.receivingShipToProductionBalance && product.quantityPerPallet
                    ? product.receivingShipToProductionBalance * product.quantityPerPallet
                    : undefined
            );
            setQuantityToTradeEaches(
                product.quantityToTrade && product.quantityPerPallet
                    ? product.quantityToTrade * product.quantityPerPallet
                    : undefined
            );
            if (product.quantityToTrade && product.originalShipToProductionBalance) {
                const calculatedOriginalShipToProductionBalance =
                    product.originalShipToProductionBalance - product.quantityToTrade;
                setOriginalShipToProductionBalanceUpdated(
                    calculatedOriginalShipToProductionBalance >= 0
                        ? calculatedOriginalShipToProductionBalance
                        : undefined
                );
                setReceivingShipToProductionBalanceUpdated(
                    product.receivingShipToProductionBalance
                        ? product.receivingShipToProductionBalance + product.quantityToTrade
                        : product.quantityToTrade
                );
            }
        }
    }, [product]);

    useEffect(() => {
        if (originalShipToProductionBalanceUpdated !== undefined && product.quantityPerPallet) {
            setOriginalShipToProductionBalanceEachesUpdated(
                originalShipToProductionBalanceUpdated * product.quantityPerPallet
            );
        } else {
            setOriginalShipToProductionBalanceEachesUpdated(undefined);
        }
    }, [product, originalShipToProductionBalanceUpdated]);

    useEffect(() => {
        if (receivingShipToProductionBalanceUpdated && product.quantityPerPallet) {
            setReceivingShipToProductionBalanceEachesUpdated(
                receivingShipToProductionBalanceUpdated * product.quantityPerPallet
            );
        } else {
            setReceivingShipToProductionBalanceEachesUpdated(undefined);
        }
    }, [product, receivingShipToProductionBalanceUpdated]);

    const getProductDescription = () => {
        return `${product.displayName} (${product.productSku}${
            product.graphicIdAndVersion ? `/${product.graphicIdAndVersion}` : ''
        })`;
    };

    return (
        <TableRow
            className={classes.tableRowContainer}
            data-testid={`product-${product.productSku}`}
        >
            <TableCell data-testid="product-description">
                <Typography>{getProductDescription()}</Typography>
                {!!product.campaignTiming && <CampaignPill />}
            </TableCell>
            <TableCell data-testid="customer-product-id">
                <Typography>{product.displayId}</Typography>
            </TableCell>
            <TableCell data-testid="original-product-balance">
                <Typography>
                    {product.originalShipToProductionBalance?.toLocaleString()} PL
                </Typography>
                <Typography className="helper-text">
                    {originalShipToProductionBalanceEaches
                        ? `${originalShipToProductionBalanceEaches.toLocaleString()} ea.`
                        : ''}
                </Typography>
            </TableCell>
            <TableCell data-testid="receiving-product-balance">
                <Typography>
                    {product.receivingShipToProductionBalance?.toLocaleString()} PL
                </Typography>
                <Typography className="helper-text">
                    {receivingShipToProductionBalanceEaches
                        ? `${receivingShipToProductionBalanceEaches.toLocaleString()} ea.`
                        : ''}
                </Typography>
            </TableCell>
            <TableCell data-testid="requested-quantity">
                <Typography>{product.quantityToTrade?.toLocaleString()} PL</Typography>
                <Typography className="helper-text">
                    {quantityToTradeEaches ? `${quantityToTradeEaches.toLocaleString()} ea.` : ''}
                </Typography>
            </TableCell>
            <TableCell data-testid="original-product-balance-updated">
                <>
                    <Typography>
                        {originalShipToProductionBalanceUpdated?.toLocaleString()} PL
                    </Typography>
                    <Typography className="helper-text">
                        {originalShipToProductionBalanceEachesUpdated !== undefined
                            ? `${originalShipToProductionBalanceEachesUpdated.toLocaleString()} ea.`
                            : ''}
                    </Typography>
                </>
            </TableCell>
            <TableCell data-testid="receiving-product-balance-updated">
                <>
                    <Typography>
                        {receivingShipToProductionBalanceUpdated?.toLocaleString()} PL
                    </Typography>
                    <Typography className="helper-text">
                        {receivingShipToProductionBalanceEachesUpdated
                            ? `${receivingShipToProductionBalanceEachesUpdated.toLocaleString()} ea.`
                            : ''}
                    </Typography>
                </>
            </TableCell>
        </TableRow>
    );
}
