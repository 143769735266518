import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class PlanItConfirmationColumns {
    static getPlanItConfigColumns = (culture: string) => {
        return [
            createColumn('displayId', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'customerProductGraphicId'),
                visible: true
            }),
            createColumn('productSku', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true,
                sortable: true
            }),
            createColumn('graphicIdAndVersion', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('displayName', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'productDescription')
            }),
            createColumn('productName', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true,
                sortable: true
            }),
            createColumn('productStatus', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'status')
            }),
            createColumn('coating', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'coating')
            }),
            createColumn('productCoating', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: true,
                sortable: true
            }),
            createColumn('originalPalletQuantity', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'previousForecastedQuantity')
            }),
            createColumn('palletQuantity', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'revisedForecastedQuantity')
            }),
            createColumn('originalEachesQuantity', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('eachesQuantity', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('quantityPerPallet', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('deleted', {
                dataType: ColumnDataType.Boolean,
                visible: false
            })
        ];
    };
}
