import { updateDeliveryOrderWithProductInfo } from '../../utility/helpers/order-helpers';
import {
    Activity,
    filterShipToIdsByPermission,
    SecurityLevel
} from '../../utility/auth/useSecurity';
import OrdersService from '../../utility/services/orders-service';
import { AuthState } from '../reducers/auth';
import { DeliveryOrder } from '../reducers/shipping-dashboard';
import {
    DELIVERY_ORDER_LOADING,
    DELIVERY_ORDER_LOADED,
    DELIVERY_ORDER_LOADING_ERROR,
    DELIVERY_ORDER_LINKED_PRODUCTION_ORDER_LOADING,
    DELIVERY_ORDER_LINKED_PRODUCTION_ORDER_LOADED,
    DELIVERY_ORDER_LINKED_PRODUCTION_ORDER_ERROR
} from './action-types';

export const loadDeliveryOrder = (deliveryOrderId: string) => {
    return (dispatch, getState) => {
        dispatch({ type: DELIVERY_ORDER_LOADING });
        const { auth } = getState();

        OrdersService.getDeliveryOrder(auth.accessToken, deliveryOrderId)
            .then((response) => {
                let deliveryOrder: DeliveryOrder = response.data;
                const auth: AuthState = getState().auth;
                const filteredShipToIds = filterShipToIdsByPermission(
                    auth,
                    [deliveryOrder.shipToId as number],
                    Activity.NewOpenDeliveryOrders,
                    SecurityLevel.View
                );
                OrdersService.getDeliveryOrderProducts(auth.accessToken, filteredShipToIds)
                    .then((response) => {
                        const availableProducts = response.data.products;
                        deliveryOrder = updateDeliveryOrderWithProductInfo(
                            deliveryOrder,
                            availableProducts
                        );
                        dispatch({
                            type: DELIVERY_ORDER_LOADED,
                            deliveryOrder
                        });
                    })
                    .catch((error) => {
                        dispatch({
                            type: DELIVERY_ORDER_LOADING_ERROR,
                            error
                        });
                    });
            })
            .catch((error) => {
                dispatch({
                    type: DELIVERY_ORDER_LOADING_ERROR,
                    error
                });
            });
    };
};

export const loadLinkedProductionOrders = (deliveryOrderId: string) => {
    return (dispatch, getState) => {
        dispatch({ type: DELIVERY_ORDER_LINKED_PRODUCTION_ORDER_LOADING });
        const { auth } = getState()
        OrdersService.getDeliveryOrderLinkedProductionOrders(auth.accessToken, deliveryOrderId)
            .then((response) => {
                const productionOrderData = response.data;
                dispatch({
                    type: DELIVERY_ORDER_LINKED_PRODUCTION_ORDER_LOADED,
                    linkedProductionOrders: productionOrderData
                });
            })
            .catch((error) => {
                dispatch({
                    type: DELIVERY_ORDER_LINKED_PRODUCTION_ORDER_ERROR,
                    error
                });
            });
    };
};
