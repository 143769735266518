import React from 'react';
import { makeStyles, ListItemText, Typography } from '@material-ui/core';

interface Props {
    name: string;
    accountId: string;
    address1: string | undefined;
    city: string | undefined;
    state: string | undefined;
    postalCode: string | undefined;
    country: string | undefined;
    showAddress?: boolean;
    showWarning?: boolean;
    warningMessage?: string | undefined;
}

const useStyles = makeStyles((theme) => ({
    accountLabel: {
        color: theme.palette.info.dark
    }
}));

const AccountRadioLabel = ({
    name,
    accountId,
    address1,
    city,
    state,
    postalCode,
    country,
    showAddress = true,
    warningMessage,
    showWarning = false
}: Props) => {
    const classes = useStyles();

    return (
        <ListItemText>
            <Typography variant="body2" className={classes.accountLabel}>
                {`${name} (${accountId})`}
            </Typography>
            {showAddress && (
                <Typography variant="body1" className={classes.accountLabel}>
                    {`${address1}, ${city}, ${state} ${postalCode}, ${country}`}
                </Typography>
            )}
            {showWarning && (
                <Typography variant="body1" className={classes.accountLabel}>
                    {warningMessage}
                </Typography>
            )}
        </ListItemText>
    );
};

export default AccountRadioLabel;
