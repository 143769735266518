import { Activity, SecurityLevel } from '../../../utility/auth/useSecurity';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import {
    MAKEIT_DASHBOARD_UPDATE_RANGE,
    MAKEIT_DASHBOARD_UPDATE_VIEW
} from '../../../store/actions/action-types';
import { MakeState } from '../../../store/reducers/makeit-dashboard';

import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
    blackWeight,
    ltBlueGrey_34,
    planItGridName,
    ballBlue,
    medium,
    large
} from '../../../themes/globalConstants';
import {
    loadMakeDashboard,
    makeItDashboardUpdateRange,
    makeItDashboardUpdateView,
    resetMakeItState
} from '../../../store/actions/makeit-dashboard';

import { AuthState } from '../../../store/reducers/auth';
import Button from '../../reusable/atoms/Button';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { LocalStorage } from 'tubular-common';
import CurrentCampaignsModal from '../../reusable/molecules/CurrentCampaignsModal';
import { CampaignRun } from '../../../store/reducers/product-portfolio';
import PlanItCheckout from './components/PlanItCheckout';
import PlanItGrid from './components/PlanItGrid';
import ConfirmationModal from '../../reusable/molecules/ConfirmationModal';
import { OrderProductTableRow } from '../../../store/reducers/orders-dashboard';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { getCampaignInformation } from '../../../utility/helpers/production-order-helpers';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { getStringInputValue } from '../../../utility/helpers/grid-helpers';
import { QuantityUnitSelectorProvider } from '../../reusable/molecules/QuantityUnitSelectorContext';
import {
    addProductPlanItGuided,
    addWeeksPlanItGuided,
    initializeAvailableWeeks,
    removeAllPlanItGuided,
    removeProductPlanItGuided,
    resetPlanItGuidedState,
    toggleWeekPlanItGuided
} from '../../../store/actions/plan-it-guided';
import { PlanItGuidedState } from '../../../store/reducers/plan-it-guided';
import moment, { Moment } from 'moment';
import { enUS } from '../../../utility/translations/locales';
import OrderWeekSelector from '../MakeIt/components/OrderWeekSelector';
import { useQuery } from '../../../utility/helpers/query-helpers';
import { ContractSummaryState } from '../../../store/reducers/contract-summary';
import {
    clearContractsAndDocuments,
    loadContractsAndDocuments
} from '../../../store/actions/contract-summary';
import { selectIsBillToAccount } from '../../../store/selectors';
import { getCurrentlyActiveDocuments } from '../../../utility/helpers/pricing-helpers';
import NotificationBanner from '../../reusable/molecules/NotificationBanner';
import { getLineIsWithinCampaign } from '../MakeIt/utilities/make-it-utils';

const useStyles = makeStyles((theme) => ({
    dashboard: {
        padding: '0'
    },
    main: {
        padding: '0',
        marginTop: '1.750em'
    },
    side: {
        backgroundColor: ltBlueGrey_34,
        flexDirection: 'column',
        padding: '1em',
        marginTop: '0.5em'
    },
    actionBar: {
        margin: '1.5em 0',
        padding: '0'
    },
    btnWrapper: {
        '& button:last-child': {
            marginLeft: '1.25em'
        }
    },
    actionBtn: {
        borderRadius: 'unset'
    },
    bulkUploadBtn: {
        borderRadius: 'unset',
        marginRight: '2em'
    },
    title: {
        fontWeight: blackWeight,
        color: theme.palette.secondary.main,
        padding: '0 0 0.5em 0'
    },
    subtitle: {
        color: theme.palette.secondary.main
    },
    warningContainer: {
        width: '100%'
    },
    viewCampaignsButton: {
        fontSize: medium,
        '&:hover': {
            backgroundColor: 'transparent',
            color: ballBlue,
            outline: 'none'
        },
        '&:focus': {
            backgroundColor: 'transparent',
            color: ballBlue
        }
    },
    stepNum: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        borderRadius: '50%',
        width: '1.875em',
        height: '1.875em',
        fontSize: large,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: blackWeight,
        marginTop: '0.25em'
    },
    stepWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    stepTitle: {
        paddingLeft: '0.5em',
        fontWeight: blackWeight,
        color: theme.palette.secondary.main
    },
    addProducts: {
        marginBottom: '5em'
    },
    quantityUnitSelector: {
        marginTop: '1em'
    },
    linkSpacer: {
        marginRight: '.25em'
    },
    linkedPO: {
        textDecoration: `underline ${ballBlue}`
    },
    makeSelections: {
        marginTop: '1.5em',
        marginLeft: '1em'
    }
}));

export default function PlanItGuided() {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const query = useQuery();
    const from = query.get('from');
    const fromReview = from === 'review';
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [paginationReset, setPaginationReset] = useState<boolean>(false);
    const [currentViewTypes, setCurrentViewTypes] = useState([''] as string[]);
    const [currentRangeTypes, setCurrentRangeTypes] = useState([''] as string[]);
    const [openViewCampaignsModal, setOpenViewCampaignsModal] = useState<boolean>(false);
    const [storage] = useState<any>(new LocalStorage(planItGridName));
    const [showViewCurrentCampaignsLink, setShowViewCurrentCampaignsLink] =
        useState<boolean>(false);
    const [campaignRuns, setCampaignRuns] = useState<CampaignRun[]>();
    const [campaignProducts, setCampaignProducts] = useState<OrderProductTableRow[]>();
    const [filteredViewProducts, setFilteredViewProducts] = useState<OrderProductTableRow[]>();
    const { products, error, shipToId, view, range, loading, loaded } = useTypedSelector<MakeState>(
        (state) => state.makeItDashboard
    );
    const { selectedAccountId, accounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { productsToOrder, weeks, selectedWeeks } = useTypedSelector<PlanItGuidedState>(
        (state) => state.planItGuided
    );
    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);
    const auth = useTypedSelector<AuthState>((state) => state.auth);
    const dashboardLink = '/dashboard';
    const [availableWeeks, setAvailableWeeks] = useState<Moment[]>([]);
    const { documents } = useTypedSelector<ContractSummaryState>((state) => state.contractSummary);
    const isBillToAccount = useTypedSelector<boolean>(selectIsBillToAccount);

    useEffect(() => {
        const account = accounts?.find((acc) => acc.accountId === selectedAccountId);
        if (selectedAccountId && account && (account?.listCustomer || !isBillToAccount)) {
            dispatch(loadContractsAndDocuments([selectedAccountId], !isBillToAccount));
        } else {
            dispatch(clearContractsAndDocuments());
        }
    }, [dispatch, selectedAccountId, isBillToAccount, accounts]);

    const someCurrentlyActiveDocumentIsUnapproved = useMemo(() => {
        const currentlyActiveDocuments = getCurrentlyActiveDocuments(documents);
        const someCurrentlyActiveDocumentIsUnapproved = currentlyActiveDocuments.some(
            (d) => !d.isApproved
        );

        return someCurrentlyActiveDocumentIsUnapproved;
    }, [documents]);

    const onCloseConfirmation = () => {
        setOpen(false);
    };

    const onConfirmedCancel = () => {
        handleBackNavigation();
        setOpen(false);
    };

    const onCancel = () => {
        setOpen(true);
    };

    const onViewCampaigns = () => {
        setOpenViewCampaignsModal(true);
    };

    const onCloseCampaignsModal = () => {
        setOpenViewCampaignsModal(false);
    };

    const handleBackNavigation = () => {
        dispatch(resetMakeItState());
        history.push(dashboardLink);
    };

    const onPlanItBulkClicked = () => {
        history.push('/plan-it-bulk-upload');
    };

    const handleAddItem = (itemAdded: OrderProductTableRow) => {
        handleSearchText();
        let item = products?.find((product) => product.productSku === itemAdded.productSku);
        if (item) {
            dispatch(addProductPlanItGuided(item));
        }
    };

    const handleRemoveAll = (itemList: OrderProductTableRow[]) => {
        handleSearchText();
        dispatch(removeAllPlanItGuided(itemList));
    };

    const handleRemoveItem = (item: OrderProductTableRow) => {
        handleSearchText();
        dispatch(removeProductPlanItGuided(item));
    };

    const handleSubmitClick = () => {
        // add current checkout list to state
        // history.push('/plan-it-configuration');
        history.push('/plan-it-configuration');
    };

    const handleViewFilterClick = useCallback(
        (viewType: string[]) => {
            setCurrentViewTypes(viewType);
            if (products) {
                // sort products first, to display active products at top of table
                const sortActiveInactiveProducts = products.sort((a, b) =>
                    (a.status ?? 'INACTIVE').localeCompare(b.status ?? 'INACTIVE')
                );
                const productsIndexes = sortActiveInactiveProducts.map((product, index) => ({
                    ...product,
                    sortIndex: index,
                    outsideCampaign: !getLineIsWithinCampaign(product)
                }));

                if (viewType[0] === '') {
                    setFilteredViewProducts(productsIndexes);
                } else if (viewType[0] === 'CAMPAIGN') {
                    const updatedProducts = productsIndexes.filter(
                        (product) => product.campaignTiming
                    );
                    setFilteredViewProducts(updatedProducts);
                } else if (viewType[0] === 'ACTIVE' || viewType[0] === 'INACTIVE') {
                    const updatedProducts = productsIndexes.filter(
                        (product) => product.status === viewType[0]
                    );
                    setFilteredViewProducts(updatedProducts);
                } else {
                    const updatedProducts = productsIndexes.filter((product) =>
                        viewType.includes(product.type!)
                    );
                    setFilteredViewProducts(updatedProducts);
                }
            }
        },
        [products]
    );

    const handleRangeFilterClick = (rangeTypes: string[]) => {
        setCurrentRangeTypes(rangeTypes);
    };

    //This is used to reset the cascadePaginationReset to false, so it doesn't continue to reset
    const handleResetPagination = () => {
        setPaginationReset(false);
    };

    // save tubular search text in storage
    const handleSearchText = () => {
        const gridElementValue = getStringInputValue('.MuiInputBase-input');
        storage.setTextSearch(gridElementValue);
    };

    const handleWeekClicked = (week: Moment) => {
        dispatch(toggleWeekPlanItGuided(week));
    };
    const handleWeekAdded = (week: Moment) => {
        dispatch(addWeeksPlanItGuided(week));
    };

    // update local state campaign products and campaign runs data
    const handleUpdateCampaigns = useCallback(
        (products: OrderProductTableRow[]) => {
            if (selectedAccountId) {
                let campaignInformation = getCampaignInformation(products, selectedAccountId);

                // store unique campaign products
                setCampaignProducts(campaignInformation.filteredCampaignProducts);
                // store campaign products that have campaign runs associated to them
                setCampaignRuns(campaignInformation.updatedCampaigns);
            }
        },

        [selectedAccountId]
    );

    useEffect(() => {
        if (!fromReview) {
            dispatch(loadMakeDashboard(true, false));
        }
    }, [dispatch, fromReview]);

    useEffect(() => {
        handleViewFilterClick(currentViewTypes);
    }, [currentViewTypes, handleViewFilterClick, products]);

    useEffect(() => {
        if (weeks && weeks.length > 0) {
            setAvailableWeeks(weeks.map((week) => moment(week)));
        } else {
            dispatch(initializeAvailableWeeks());
        }
    }, [dispatch, weeks]);

    // when campaign runs or products are updated (possibly switching ship to), check
    // whether or not to show view current campaigns link
    useEffect(() => {
        const hasCampaignRuns = !!campaignRuns && campaignRuns.length > 0;
        const hasCampaignProducts = !!campaignProducts && campaignProducts.length > 0;
        setShowViewCurrentCampaignsLink(hasCampaignRuns || hasCampaignProducts);
    }, [campaignProducts, campaignRuns]);

    useEffect(() => {
        if (products) {
            handleUpdateCampaigns(products);
        }
    }, [dispatch, handleUpdateCampaigns, products, productsToOrder]);

    useEffect(() => {
        // If Ship To ID changes, requery the products
        if (selectedAccountId !== shipToId && !error && loaded) {
            dispatch(resetMakeItState());
            dispatch(resetPlanItGuidedState());
            setCurrentViewTypes(['']);
            dispatch(makeItDashboardUpdateView([''], MAKEIT_DASHBOARD_UPDATE_VIEW));
        }

        if (selectedAccountId !== shipToId && !error) {
            dispatch(loadMakeDashboard(true, false));
        }

        //Make sure selectedShipToId and shipToId are both defined
        //Then, if selectedShipToId changes, reset pagination rows to default and reset search
        if (selectedAccountId && shipToId && selectedAccountId !== shipToId) {
            setPaginationReset(true);
            storage.setTextSearch('');
        }
    }, [
        selectedAccountId,
        permissions,
        shipToId,
        error,
        products?.length,
        dispatch,
        storage,
        auth,
        loaded
    ]);

    useEffect(() => {
        // Using stringify allows us to compare the length and contents of the arrays
        if (JSON.stringify(currentViewTypes) !== JSON.stringify(view)) {
            dispatch(makeItDashboardUpdateView(currentViewTypes, MAKEIT_DASHBOARD_UPDATE_VIEW));
        }
        if (JSON.stringify(currentRangeTypes) !== JSON.stringify(range)) {
            dispatch(makeItDashboardUpdateRange(currentRangeTypes, MAKEIT_DASHBOARD_UPDATE_RANGE));
        }
    }, [currentViewTypes, currentRangeTypes, view, range, dispatch]);

    useTranslation();

    const footerActions = (
        <>
            <Grid container item xs={2} className={classes.btnWrapper}>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="cancel-btn"
                    onClick={onCancel}
                    className={classes.actionBtn}
                >
                    <Trans i18nKey="cancel">Cancel</Trans>
                </Button>
            </Grid>
            {someCurrentlyActiveDocumentIsUnapproved ? (
                <Grid container item xs={7}>
                    <NotificationBanner
                        notificationMessage={t(
                            'pricingWarningText',
                            `Terms & Conditions require acknowledgement for one or more bill to's on your account. Orders cannot not be placed until new Terms & Conditions have been acknowledged. Users within your organization with the 'Account Admin' access/permission have the ability to acknowledge new Terms and Conditions within The Source via the 'Admin' tab.`
                        )}
                        notificationType={'WARNING'}
                    />
                </Grid>
            ) : null}
            <Grid container item xs={3} justify="flex-end" className={classes.btnWrapper}>
                <Button
                    type="button"
                    color="primary"
                    variant="outlined"
                    data-testid="back-btn"
                    className={classes.actionBtn}
                    onClick={handleBackNavigation}
                >
                    <Trans i18nKey="back">Back</Trans>
                </Button>

                <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    data-testid="plan-submit-btn"
                    className={classes.actionBtn}
                    onClick={handleSubmitClick}
                    disabled={
                        !(
                            selectedWeeks &&
                            selectedWeeks.length > 0 &&
                            productsToOrder.length > 0
                        ) || someCurrentlyActiveDocumentIsUnapproved
                    }
                >
                    <Trans i18nKey="setQuantities">Set Quantities</Trans>
                </Button>
            </Grid>
        </>
    );

    useEffect(() => {
        return () => {
            if (
                history.location.pathname !== '/plan-it' &&
                history.location.pathname !== '/plan-it-configuration' &&
                history.location.pathname !== '/plan-it-confirmation'
            ) {
                dispatch(resetPlanItGuidedState());
            }
        };
    }, [dispatch, history.location.pathname]);

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('planIt', 'Plan It'),
                description: t('createForecast', 'Create Forecast').toUpperCase(),
                thinBanner: true,
                displayDropdown: true
            }}
            actionFooter={{
                footerAction: footerActions,
                justify: 'space-between',
                sticky: true
            }}
            loading={loading}
            activity={Activity.PlanItBulkUpload}
            restrictToSecurityLevel={SecurityLevel.Edit}
        >
            <Grid
                container
                spacing={2}
                alignItems="flex-start"
                className={classes.dashboard}
                data-testid="plan-it"
            >
                <QuantityUnitSelectorProvider state={{ quantityUnit: 'pallets' }}>
                    <Grid container item md={9} className={classes.main}>
                        <Grid container item xs={12} justify="flex-end">
                            <Button
                                type="button"
                                color="primary"
                                variant="outlined"
                                data-testid="plan-it-bulk-btn"
                                onClick={onPlanItBulkClicked}
                                className={classes.bulkUploadBtn}
                            >
                                <Trans i18nKey="bulkUpload">Bulk Upload</Trans>
                            </Button>
                        </Grid>
                        <Grid item container className={classes.addProducts}>
                            <OrderWeekSelector
                                weeks={availableWeeks}
                                onWeekClick={handleWeekClicked}
                                onWeekAdded={handleWeekAdded}
                                selectedWeek={moment()}
                                selectedWeeks={selectedWeeks}
                                allocationStateByWeek={[]}
                                showAddWeek={true}
                                guided={true}
                            />
                        </Grid>

                        <Grid container item justify="space-between" alignItems="baseline">
                            <Grid container item xs={9}>
                                <Grid item className={classes.stepWrapper}>
                                    <Typography
                                        component="span"
                                        className={classes.stepNum}
                                        data-testid="step-number"
                                    >
                                        2
                                    </Typography>
                                    <Typography
                                        variant="h3"
                                        className={classes.stepTitle}
                                        data-testid="step-title"
                                    >
                                        <Trans i18nKey="selectProductsPlanIt">
                                            {enUS.selectProductsPlanIt}
                                        </Trans>
                                    </Typography>
                                </Grid>
                                {!(selectedWeeks && selectedWeeks.length > 0) && (
                                    <Grid container item xs={12}>
                                        <Grid item className={classes.makeSelections}>
                                            <Typography>
                                                <Trans i18nKey="selecteForecastWeeks">
                                                    Select your forecast week(s) to continue.
                                                </Trans>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                                {selectedWeeks &&
                                    selectedWeeks.length > 0 &&
                                    showViewCurrentCampaignsLink && (
                                        <Typography variant="h5" className={classes.subtitle}>
                                            <Trans i18nKey="lookingForCampaignSchedules">
                                                Looking for campaign schedules?
                                            </Trans>
                                            <Button
                                                variant="text"
                                                color="primary"
                                                data-testid="view-campaigns-button"
                                                className={classes.viewCampaignsButton}
                                                onClick={onViewCampaigns}
                                            >
                                                <Trans i18nKey="viewCurrentUpcomingCampaigns">
                                                    View Current & Upcoming Campaigns
                                                </Trans>
                                            </Button>
                                        </Typography>
                                    )}
                            </Grid>
                        </Grid>
                        {selectedWeeks && selectedWeeks.length > 0 && (
                            <PlanItGrid
                                makeItItems={filteredViewProducts as OrderProductTableRow[]}
                                checkOutList={productsToOrder}
                                onAddItem={handleAddItem}
                                onViewFiltersClick={handleViewFilterClick}
                                onRangeFiltersClick={handleRangeFilterClick}
                                resetPagination={paginationReset}
                                onResetPagination={handleResetPagination}
                                storage={storage}
                            />
                        )}
                    </Grid>
                    <Grid container item md={3} className={classes.side}>
                        <PlanItCheckout
                            products={productsToOrder}
                            onRemoveAll={handleRemoveAll}
                            onRemoveOrder={handleRemoveItem}
                        />
                    </Grid>
                </QuantityUnitSelectorProvider>
            </Grid>
            <ConfirmationModal
                data-testid="cancel-modal"
                saveProgress={onConfirmedCancel}
                onClose={onCloseConfirmation}
                logo={false}
                title={<Trans i18nKey="cancel">Cancel</Trans>}
                subheader={<Trans i18nKey="changesNotSaved">Your changes will not be saved</Trans>}
                description={
                    <Trans i18nKey="confirmCancel">Are you sure you want to cancel?</Trans>
                }
                continueText={<Trans i18nKey="yesCancel">Yes, Cancel</Trans>}
                cancelText={<Trans i18nKey="no">No</Trans>}
                open={open}
                navigationLink={dashboardLink}
                onCancel={onCloseConfirmation}
            />
            {campaignRuns && (
                <CurrentCampaignsModal
                    title={
                        <Trans i18nKey="currentUpcomingCampaigns">
                            Current & Upcoming Campaigns
                        </Trans>
                    }
                    open={openViewCampaignsModal}
                    onClose={onCloseCampaignsModal}
                    campaignRuns={campaignRuns}
                    campaignProducts={campaignProducts}
                />
            )}
        </ProcessingPageTemplate>
    );
}
