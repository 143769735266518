import {
    Activity,
    SecurityLevel,
    filterShipToIdsByPermission
} from '../../utility/auth/useSecurity';
import {
    MAKEIT_DASHBOARD_LOADED,
    MAKEIT_DASHBOARD_LOADING,
    MAKEIT_DASHBOARD_LOADING_ERROR,
    MAKEIT_DASHBOARD_NO_PRODUCTS,
    MAKEIT_ORDER_LOADED,
    MAKEIT_ORDER_LOADING,
    MAKEIT_ORDER_LOADING_ERROR,
    MAKEIT_PRODUCTS_TO_ORDER_UPDATE,
    MAKEIT_RESET_STATE,
    MAKEIT_SAVE_INPROGRESS,
    MAKEIT_SAVE_ORDER_LIST,
    MAKEIT_SET_ORDER_LINES,
    MAKEIT_TOGGLE_ADD,
    SAVE_MAKEIT_DASHBOARD_ORDER,
    MAKEIT_DASHBOARD_UPDATE_REQUESTED_DATE,
    MAKEIT_BULK_ATM_SUBMIT_REVIEW,
    MAKEIT_BULK_ATM_EXISTING_ORDERS,
    MAKEIT_BULK_UPLOAD_ERROR,
    MAKEIT_DASHBOARD_UPDATE_QUANTITY_UNIT,
    MAKEIT_DASHBOARD_UPDATE_PRODUCTS,
    MAKE_SUMMARY_PENDING_CANCEL_REQUESTED,
    MAKE_SUMMARY_PENDING_CANCEL_COMPLETED,
    MAKE_SUMMARY_PENDING_CANCEL_ERROR
} from './action-types';
import {
    MakeItProductsRequest,
    ProductionOrder,
    ProductionOrderLine
} from '../reducers/makeit-dashboard';
import OrdersService, { Order_Status } from '../../utility/services/orders-service';
import {
    createProductRequestObj,
    getMakeItMatchingProductInformation
} from '../../utility/helpers/production-order-helpers';

import { AuthState } from '../reducers/auth';
import { OrderProductTableRow } from '../reducers/orders-dashboard';
import ProductService, { LeadTimesRequest } from '../../utility/services/product-service';
import { MakeItBulkLineItem } from '../reducers/makeit-bulk-upload';
import moment, { Moment } from 'moment';
import { isProductCanType, isProductEndType } from '../../utility/helpers/order-helpers';
import {
    getSnoSkuAttributes,
    getSnoSkuDescription
} from '../../utility/helpers/make-it-bulk-helpers';
import {
    AvailableBalance,
    ForecastStatus,
    MakeItBulkATMLineItem,
    MakeItBulkATMOrder
} from '../reducers/makeit-bulk-atm';
import { setFeesMOQData } from './makeit-bulk-atm';
import { QuantityUnit } from '../reducers/shipping-dashboard';
import TranslationService from '../../utility/services/translation-service';

/**
 *
 * @param requestedDate Starting day of available week.  Expected to be a Monday.
 */
export const setRequestedMakeItWeek = (requestedDate: Moment) => {
    return (dispatch) => {
        dispatch({
            type: MAKEIT_DASHBOARD_UPDATE_REQUESTED_DATE,
            requestedDate: requestedDate.format('MM/DD/YYYY')
        });
    };
};

export const loadMakeDashboard = (isPlanIt = false, activeOnly = true) => {
    return (dispatch, getState) => {
        dispatch({ type: MAKEIT_DASHBOARD_LOADING });
        const state = getState();
        const cultureCode = getState().regionCulture?.cultureCode || 'en-US';
        const accessToken = state.auth.accessToken;
        const shipToId = state.customerContext.selectedAccountId;
        const productsToOrder = state.makeItDashboard.productsToOrder
            ? state.makeItDashboard.productsToOrder
            : [];
        const rangeState = state.makeItDashboard.range;
        const currentlyAdded = productsToOrder.filter((item) => item.addDisabled);
        const auth: AuthState = state.auth;

        const requestObj: MakeItProductsRequest = {
            ShipToIds: filterShipToIdsByPermission(
                auth,
                [shipToId],
                !isPlanIt ? Activity.NewOpenProductionOrders : Activity.PlanItSummary,
                SecurityLevel.Edit
            ),
            activeOnly: activeOnly
        };
        if (!!rangeState && rangeState[0].length !== 0) {
            requestObj.UsedWithinNMonths = rangeState;
        }

        if (shipToId) {
            OrdersService.getMakeItProducts(accessToken, requestObj)
                .then((response) => {
                    // get lead time && moq information here
                    let products = response.data.products;

                    // determine product status
                    products.forEach((product) => {
                        const destination =
                            product.destinations &&
                            product.destinations.find(
                                (dest) =>
                                    dest.shipToId.toString() === shipToId &&
                                    dest.status === 'ACTIVE'
                            );
                        product.status =
                            destination && product.status === 'ACTIVE' ? 'ACTIVE' : 'INACTIVE';
                    });

                    // This if() statement prevents continual loading loop if no products
                    if (products.length > 0) {
                        let productRequest = createProductRequestObj(products, currentlyAdded, [
                            shipToId
                        ]);

                        const productReq = { ...productRequest, accountId: shipToId };

                        ProductService.getMultipleProducts(getState, productReq)
                            .then((response) => {
                                // Response information from the product-api
                                const responseData = response?.data?.products;
                                if (responseData) {
                                    // Loop over products from order-api to find matches
                                    products.forEach((product) => {
                                        getMakeItMatchingProductInformation(
                                            responseData,
                                            product,
                                            product.size,
                                            product.type,
                                            product.shape
                                        );
                                        product.snoSkuDescription = getSnoSkuDescription(
                                            isProductCanType(product.type),
                                            product
                                        );
                                        product.snoSkuAttributes = getSnoSkuAttributes(
                                            isProductCanType(product.type),
                                            product
                                        );
                                    });
                                }

                                return Promise.resolve(products);
                            })
                            .then((products) => getProductLeadTimes(products, state))
                            .then((leadTimes) => {
                                products.forEach((product) => {
                                    var productLeadTime = leadTimes.find(
                                        (lt) => lt.referenceId === product.productSku
                                    );
                                    if (!!productLeadTime) {
                                        product.leadTimeWeeks = productLeadTime.leadTimeWeeks;
                                        product.leadTimeString = !!productLeadTime.leadTimeWeeks
                                            ? productLeadTime.leadTimeWeeks.toString() +
                                              ' ' +
                                              TranslationService.getTranslatedText(
                                                  cultureCode,
                                                  'weeks'
                                              )
                                            : '';
                                        product.firstAvailableDate =
                                            !!productLeadTime.nextAvailableDate
                                                ? moment(productLeadTime.nextAvailableDate).format(
                                                      'MM-DD-yyyy'
                                                  )
                                                : '';
                                    }
                                });
                                dispatch({
                                    type: MAKEIT_DASHBOARD_LOADED,
                                    products,
                                    shipToId
                                });
                            })
                            .catch((error) => {
                                dispatch({
                                    type: MAKEIT_DASHBOARD_LOADING_ERROR,
                                    error
                                });
                            });
                    } else {
                        dispatch({
                            type: MAKEIT_DASHBOARD_NO_PRODUCTS,
                            shipToId: shipToId
                        });
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: MAKEIT_DASHBOARD_LOADING_ERROR,
                        error
                    });
                });
        }
    };
};

/**
 * Retrieves lead times for each product.
 * @param products
 * @param state
 * @returns
 */
export function getProductLeadTimes(
    products: Array<OrderProductTableRow | MakeItBulkLineItem>,
    state: any
) {
    const accessToken = state.auth.accessToken;
    const shipToId = state.customerContext.selectedAccountId;
    const region = state.regionCulture.regionCode;
    const culture = state.regionCulture.cultureCode;

    // Create request objects for each product.
    const requestObjects = products.reduce((previousProduct, currentProduct) => {
        const requestObj: LeadTimesRequest = {
            accountId: currentProduct.requestShipToId ?? shipToId,
            basecoat: currentProduct.basecoat!,
            ink: currentProduct.specialtyInk!,
            neckDiameter: isProductEndType(currentProduct.type)
                ? currentProduct.size!.toString().split('.').join('')
                : Math.trunc(currentProduct.neckDiameter!).toString(),
            overvarnish: currentProduct.overvarnish ?? '',
            referenceId: currentProduct.productSku!,
            retort: currentProduct.fillRetort!,
            size: currentProduct.size!.toString().split('.').join(''),
            styleCode: currentProduct.shape!,
            type: currentProduct.type!
        };
        previousProduct.push(requestObj);
        return previousProduct;
    }, [] as Array<LeadTimesRequest>);
    return ProductService.getLeadTimesBatch(accessToken, region, culture, requestObjects);
}

export const saveDraftMakeItOrder = (order: ProductionOrder, orderLines: ProductionOrderLine[]) => {
    return (dispatch: any, getState: any) => {
        dispatch({ type: MAKEIT_SAVE_INPROGRESS });
        const state = getState();
        const account = state.customerContext.shipToAccounts.filter(
            (account) => account.accountId === state.customerContext.selectedAccountId
        );
        const accessToken = state.auth.accessToken;
        order.shipToId = parseInt(state.customerContext.selectedAccountId);
        order.country = account[0].address.country;
        order.createdBy = state.auth.userInfo.email;
        order.region = state.regionCulture.regionCode;
        order.lines = orderLines;

        OrdersService.saveDraftMakeItOrder(order, accessToken)
            .then((response) => {
                dispatch({
                    type: SAVE_MAKEIT_DASHBOARD_ORDER,
                    productionOrder: response.data
                });
            })
            .catch((error) => {
                dispatch({
                    type: MAKEIT_DASHBOARD_LOADING_ERROR,
                    error
                });
            });
    };
};

export const saveSubmittedProductionOrder = (order: ProductionOrder) => {
    return (dispatch) => {
        dispatch({
            type: SAVE_MAKEIT_DASHBOARD_ORDER,
            productionOrder: order
        });
    };
};

export const updateMakeItGuidedQuantityUnit = (quantityUnit: QuantityUnit) => {
    return (dispatch: any, getState) => {
        const productsToOrder: Array<OrderProductTableRow> =
            getState().makeItDashboard.productsToOrder;
        const existingQuantityUnit = getState().makeItDashboard.quantityUnit;

        if (existingQuantityUnit !== quantityUnit) {
            const convertedProductsToOrder = productsToOrder.map((product) => {
                if (product.palletQuantity && product.eachesQuantity) {
                    let palletQuantity: number = product.palletQuantity;
                    let eachesQuantity: number = product.eachesQuantity;
                    switch (quantityUnit) {
                        case 'pallets': {
                            eachesQuantity = product.palletQuantity * product.quantityPerPallet!;
                            break;
                        }
                    }
                    return {
                        ...product,
                        palletQuantity,
                        eachesQuantity
                    };
                }
                return product;
            });
            dispatch({
                type: MAKEIT_DASHBOARD_UPDATE_QUANTITY_UNIT,
                quantityUnit: quantityUnit,
                productsToOrder: convertedProductsToOrder
            });
        }
    };
};

export const updateProductList = (
    addDisabled: boolean,
    removeAll: boolean,
    product?: OrderProductTableRow
) => {
    return (dispatch: any, getState: any) => {
        const { products, productsToOrder, orderLines } = getState().makeItDashboard;
        let updatedProducts = [...products];
        let updatedProductsToOrder: any[] = [];
        let updatedOrderLines = [];

        if (removeAll) {
            updatedProducts.forEach((item) => (item.addDisabled = false));
        } else if (product) {
            updatedProducts = toggleAdd(updatedProducts, product, addDisabled);
        }

        // Removes single item to "Your Order"
        if (!removeAll && productsToOrder) {
            updatedProductsToOrder = productsToOrder.filter(
                (item) => product && item.productSku !== product.productSku
            );
            updatedOrderLines = orderLines.filter(
                (item) => product && item.productSku !== product.productSku
            );
        }

        if (product) {
            updatedProducts.forEach((prod) => {
                if (product.productSku === prod.productSku) {
                    prod.palletQuantity = undefined;
                    prod.eachesQuantity = undefined;
                }
            });
        }
        dispatch({
            type: MAKEIT_TOGGLE_ADD,
            products: updatedProducts,
            productsToOrder: updatedProductsToOrder,
            orderLines: updatedOrderLines
        });
    };
};

export const updateOrderList = (
    productsToOrder: OrderProductTableRow[],
    removing?: boolean,
    product?: OrderProductTableRow
) => {
    return (dispatch, getState) => {
        const { products } = getState().makeItDashboard;
        let updatedProducts = [...products] as OrderProductTableRow[];
        if (removing && product) {
            updatedProducts = toggleAdd(updatedProducts, product, false);
        }
        dispatch({
            type: MAKEIT_SAVE_ORDER_LIST,
            productsToOrder: productsToOrder,
            products: updatedProducts,
            orderLines: []
        });
    };
};

const toggleAdd = (
    products: OrderProductTableRow[],
    product: OrderProductTableRow,
    remove: boolean
) => {
    return products.map((item) => {
        if (product && item.productSku === product.productSku) {
            item.addDisabled = remove;
        }
        return item;
    });
};

export const resetMakeItState = () => {
    return (dispatch) => {
        dispatch({
            type: MAKEIT_RESET_STATE,
            loaded: false,
            products: [],
            productsToOrder: [],
            orderLines: [],
            productionOrder: null,
            quantityUnit: 'pallets',
            requestedDate: ''
        });
    };
};

export const updateProductsToOrder = (productsToOrder: OrderProductTableRow[]) => {
    return (dispatch) => {
        dispatch({
            type: MAKEIT_PRODUCTS_TO_ORDER_UPDATE,
            productsToOrder: productsToOrder
        });
    };
};

export const updateOrderLineState = (orderLines: ProductionOrderLine[]) => {
    return (dispatch) => {
        dispatch({
            type: MAKEIT_SET_ORDER_LINES,
            orderLines: orderLines
        });
    };
};

export const updateProductPalletInput = (products: OrderProductTableRow[]) => {
    return (dispatch) => {
        dispatch({
            type: MAKEIT_DASHBOARD_UPDATE_PRODUCTS,
            products: products
        });
    };
};

export const makeItDashboardUpdateView = (view: string[], type: string) => {
    return (dispatch: any) => {
        dispatch({
            type: type,
            view: view
        });
    };
};

export const makeItDashboardUpdateRange = (range: string[], type: string) => {
    return (dispatch: any) => {
        dispatch({
            type: type,
            range: range
        });
        dispatch(loadMakeDashboard());
    };
};

export const loadOrder = (productionOrderId: number) => {
    return (dispatch, getState) => {
        dispatch({ type: MAKEIT_ORDER_LOADING });

        OrdersService.getProductionOrder(getState(), productionOrderId)
            .then((productionOrder) => {
                const order = productionOrder.data;
                dispatch({
                    type: MAKEIT_ORDER_LOADED,
                    order
                });
            })
            .catch((error) => {
                dispatch({
                    type: MAKEIT_ORDER_LOADING_ERROR,
                    error
                });
            });
    };
};

// Check with API team before using. API set to ignore MOST manual status changes
export const updateMakeItOrderStatus = (productionOrderId: number, status: Order_Status) => {
    return (dispatch: any, getState: any) => {
        dispatch({ type: MAKEIT_SAVE_INPROGRESS });
        const state = getState();
        const accessToken = state.auth.accessToken;

        OrdersService.getProductionOrder(getState(), productionOrderId)
            .then((productionOrder) => {
                const order = productionOrder.data;
                order.status = status;

                OrdersService.updateMakeItOrder(order, accessToken)
                    .then((response) => {
                        const order = response.data;
                        order.lines.forEach((line) => {
                            line.displayId = line.customerProductId
                                ? line.customerProductId
                                : line.productSku;
                            line.displayName = line.customerProductName
                                ? line.customerProductName
                                : line.productName;
                        });
                        dispatch({
                            type: SAVE_MAKEIT_DASHBOARD_ORDER,
                            productionOrder: order
                        });
                    })
                    .catch((error) => {
                        dispatch({
                            type: MAKEIT_DASHBOARD_LOADING_ERROR,
                            error
                        });
                    });
            })
            .catch((error) => {
                dispatch({
                    type: MAKEIT_DASHBOARD_LOADING_ERROR,
                    error
                });
            });
    };
};

export const createATMOrder = (checkOutList: OrderProductTableRow[]) => {
    return (dispatch, getState) => {
        const state = getState();
        const requestedDate = state.makeItDashboard.requestedDate;
        const shipToId = parseInt(state.customerContext.selectedAccountId);
        const products: OrderProductTableRow[] = state.makeItDashboard.products;
        const accessToken = state.auth.accessToken;
        const auth: AuthState = state.auth;

        let atmOrder: MakeItBulkATMOrder = {
            customerProductionOrderId: '',
            weekStart: requestedDate,
            shipToId,
            canQuantityPallets: 0,
            endQuantityPallets: 0,
            canQuantitySKUs: 0,
            endQuantitySKUs: 0,
            lines: []
        };
        if (shipToId && requestedDate) {
            const requestObj: MakeItProductsRequest = {
                ShipToIds: filterShipToIdsByPermission(
                    auth,
                    [shipToId],
                    Activity.NewOpenProductionOrders,
                    SecurityLevel.Edit
                ),
                RequestedDate: [requestedDate],
                WithAvailablePallets: true
            };
            OrdersService.getMakeItProducts(accessToken, requestObj)
                .then((response) => {
                    const productsWithAvailablePallets = response.data.products;
                    const availableBalances = [
                        {
                            shipToId,
                            requestedDate,
                            balances: productsWithAvailablePallets.map((product) => {
                                return {
                                    productSku: product.productSku,
                                    quantity: product.availablePallets
                                };
                            })
                        } as AvailableBalance
                    ];
                    atmOrder.lines = checkOutList.map((product) => {
                        const matchingProduct = products.find(
                            (prod) => prod.productSku === product.productSku!
                        );

                        const productRow = {
                            ...matchingProduct,
                            ...product,
                            availableQuantity: getAvailableQuantity(
                                availableBalances,
                                atmOrder.shipToId,
                                moment.utc(requestedDate).format('MM/DD/YYYY'),
                                product.productSku
                            ),
                            userSuppliedProductId: product.productSku!,
                            requestedDate: moment(requestedDate).format('YYYY-MM-DDThh:mm:ssZ'),
                            isCustomerProductIdDistinct: true,
                            deleted: false
                        } as MakeItBulkATMLineItem;
                        const productRowMOQSet = setFeesMOQData(matchingProduct, productRow);
                        return productRowMOQSet;
                    });
                    totalPalletQuantities(atmOrder);

                    dispatch({
                        type: MAKEIT_BULK_ATM_SUBMIT_REVIEW,
                        atmOrders: [atmOrder],
                        availableBalances,
                        isEdit: true
                    });
                    // Get existing ATM orders
                    OrdersService.getExistingOrders(
                        state,
                        requestedDate,
                        requestedDate,
                        [shipToId],
                        ForecastStatus.ATM
                    ).then((resp) => {
                        if (resp) {
                            const existingOrders = JSON.parse(JSON.stringify(resp));
                            existingOrders.forEach((order) => {
                                order.weekStart = order.atmWeekStart;
                                order.lines = order.lines.map((line) => {
                                    const matchingProduct = products.find(
                                        (prod) => prod.productSku === line.productSku!
                                    );
                                    return {
                                        ...line,
                                        weekStart: order.atmWeekStart,
                                        snoSku: matchingProduct?.snoSku
                                    };
                                });
                            });
                            dispatch({
                                type: MAKEIT_BULK_ATM_EXISTING_ORDERS,
                                existingOrders: existingOrders.filter(
                                    (order) =>
                                        order.status !== Order_Status.Draft &&
                                        order.status !== Order_Status.Cancelled
                                ),
                                existingDrafts: existingOrders.filter(
                                    (order) => order.status === Order_Status.Draft
                                )
                            });
                        }
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: MAKEIT_BULK_UPLOAD_ERROR,
                        error
                    });
                });
        }
    };
};

export const getExistingOrders = (
    shipToIds: number[],
    products: OrderProductTableRow[],
    startDate: Moment,
    endDate: Moment
) => {
    return (dispatch, getState) => {
        const state = getState();

        // Get existing ATM orders
        OrdersService.getExistingOrders(
            state,
            startDate.format('M/D/YYYY'),
            endDate.format('M/D/YYYY'),
            shipToIds,
            ForecastStatus.ATM,
            true
        )
            .then((resp) => {
                if (resp) {
                    const existingOrders = JSON.parse(JSON.stringify(resp));
                    existingOrders.forEach((order) => {
                        order.weekStart = order.atmWeekStart;
                        order.lines = order.lines.map((line) => {
                            const matchingProduct = products.find(
                                (prod) => prod.productSku === line.productSku!
                            );
                            return {
                                ...line,
                                weekStart: order.atmWeekStart,
                                snoSku: matchingProduct?.snoSku
                            };
                        });
                    });
                    dispatch({
                        type: MAKEIT_BULK_ATM_EXISTING_ORDERS,
                        existingOrders: existingOrders.filter(
                            (order) => order.status !== Order_Status.Draft
                        ),
                        existingDrafts: existingOrders.filter(
                            (order) => order.status === Order_Status.Draft
                        )
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: MAKEIT_BULK_UPLOAD_ERROR,
                    error
                });
            });
    };
};

const totalPalletQuantities = (atmOrder: MakeItBulkATMOrder) => {
    atmOrder.lines.forEach((line) => {
        if (isProductCanType(line.type)) {
            atmOrder.canQuantityPallets += line.palletQuantity!;
            atmOrder.canQuantitySKUs += 1;
        } else {
            atmOrder.endQuantityPallets += line.eachesQuantity!;
            atmOrder.endQuantitySKUs += 1;
        }
    });
};

export const cancelPendingProductionOrder = (productionOrderId: number) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: MAKE_SUMMARY_PENDING_CANCEL_REQUESTED });
        const pendingOrders = state.makeItSummary.pendingOrders;
        const accessToken = state.auth.accessToken;
        const orderSummary = pendingOrders.find(
            (order) => order.productionOrderId === productionOrderId
        );
        if (orderSummary) {
            let productionOrder: ProductionOrder = {
                productionOrderId: productionOrderId,
                productionOrderNumber: Number(orderSummary?.productionOrderNumber),
                region: orderSummary?.region ?? '',
                country: orderSummary?.country ?? '',
                customerProductionOrderId: orderSummary?.customerProductionOrderId ?? '',
                submittedDate: undefined,
                submittedBy: orderSummary?.submittedBy ?? '',
                createdBy: orderSummary?.createdBy ?? '',
                createDate: moment(orderSummary?.createDate!).format('MM/DD/YYYY'),
                updateDate: moment(orderSummary?.updateDate!).format('MM/DD/YYYY'),
                totalOrderQuantity: orderSummary?.totalOrderQuantity ?? 0,
                totalOrderSKUs: orderSummary?.totalOrderSKUs ?? 0,
                availableToShipQuantity: orderSummary?.availableToShipQuantity ?? 0,
                availableToShipSKUs: orderSummary?.availableToShipSKUs ?? 0,
                lines: [],
                shipToId: orderSummary?.shipToId!,
                status: Order_Status.CancelledPending
            };
            OrdersService.updateMakeItOrder(productionOrder, accessToken)
                .then((response) => {
                    dispatch({
                        type: MAKE_SUMMARY_PENDING_CANCEL_COMPLETED,
                        productionOrderId: productionOrderId
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: MAKE_SUMMARY_PENDING_CANCEL_ERROR,
                        error
                    });
                });
        }
    };
};

export const getAvailableQuantity = (
    availableBalances: AvailableBalance[],
    shipToId: number,
    requestedDate: string,
    productSku: undefined | string
) => {
    const availableBalancesForShipTo = availableBalances.filter(
        (ab) => ab.shipToId === shipToId && ab.requestedDate === requestedDate
    );
    if (availableBalancesForShipTo.length === 1 && productSku) {
        const balances = availableBalancesForShipTo[0].balances.filter(
            (b) => b.productSku === productSku
        );
        if (balances.length === 1) {
            return balances[0].quantity;
        }
    }
    return 0;
};
