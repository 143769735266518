import React from 'react';
import { Grid, TableCell, TableRow, Typography } from '@material-ui/core';
import { DataValidation } from './DataValidationGrid';

interface Props {
    row: DataValidation;
}

const DataValidationRow = ({ row }: Props) => {
    return (
        <TableRow key={'field_' + row?.validationField} data-testid="data-validation-row">
            <TableCell>
                <Grid container>
                    <Grid item xs={12} data-testid="data-validation-row-field">
                        <Typography>{row.validationField}</Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid container>
                    <Grid item xs={12} data-testid="data-validation-row-format">
                        <Typography>{row.validationFormat}</Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid container>
                    <Grid item xs={12} data-testid="data-validation-row-rule">
                        <Typography>{row.validationRule}</Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid container>
                    <Grid item xs={12} data-testid="data-validation-example">
                        <Typography>{row.validationExample}</Typography>
                    </Grid>
                </Grid>
            </TableCell>
        </TableRow>
    );
};

export default DataValidationRow;
