import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from '../../../reusable/molecules/Modal';
import PaymentSubmittedConfirmationModal from './PaymentSubmittedConfirmationModal';
import { Grid, Typography, makeStyles, TextField, CircularProgress } from '@material-ui/core';
import Button from '../../../reusable/atoms/Button';
import { Trans, useTranslation } from 'react-i18next';
import { white, boldWeight, ballLtGray_2, xl } from '../../../../themes/globalConstants';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { AxiosRequestConfig } from 'axios';
import { createAxiosHeader } from '../../../../utility/helpers/axios-helpers';
import ContactService from '../../../../utility/services/contact-service';
import moment from 'moment';
import { Invoice } from '../../../../store/reducers/make-it-summary';
import { IPaymentSubmitted } from '../../../../store/reducers/pending-payments';
import { addInvoiceToSubmitList } from '../../../../store/actions/pending-payments';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { Account } from '../../../../store/reducers/customer-context';
import { formattedMTDate } from '../../../../utility/helpers/date-helpers';
import { AuthState } from '../../../../store/reducers/auth';
import { isInvoiceImpersonation } from '../../../../utility/helpers/pending-payment-helpers';
import ImpersonationWarning from '../../../reusable/molecules/ImpersonationWarning';

interface Props {
    open: boolean;
    onClose: () => void;
    item?: Invoice;
}

const useStyles = makeStyles((theme) => ({
    '& .MuiInputBase-input': {
        backgroundColor: white,
        color: white
    },

    remittanceText: {
        fontWeight: boldWeight,
        color: theme.palette.info.dark,
        fontSize: xl
    },

    remittanceField: {
        width: '14em',
        marginTop: 0,
        marginBottom: 20,
        '& .MuiInputBase-root': {
            backgroundColor: theme.palette.common.white,
            border: '1px solid',
            borderColor: theme.palette.secondary.light,
            '&:before': {
                display: 'none'
            }
        },
        '& .MuiFormLabel-filled': {
            fontWeight: 700
        }
    },

    grayBox: {
        backgroundColor: ballLtGray_2,
        width: '19.21em',
        height: '14.93em',
        margin: '2em',
        paddingTop: '2em'
    },

    buttonGrid: {
        paddingBottom: '5.4em',
        height: '4em',
        width: '20.21em',
        margin: 'auto'
    },

    modalButton: {
        width: '14.03em',
        height: '3.3em'
    },

    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '0.5em'
    },
    submitMessage: {
        maxWidth: '30em',
        marginBottom: '1em'
    },
    failure: {
        color: theme.palette.error.main
    },
    spacing: {
        marginRight: '1.5em'
    },
    impersonationWarning: {
        marginLeft: '2em',
        marginBottom: '2em'
    }
}));

const SubmitPaymentModal = ({ open, onClose, item }: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const { userInfo, accessToken } = useTypedSelector((state) => state.auth);
    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const regionCulture = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);
    const isImpersonation = useMemo(()=> 
        !!item && !!permissions && isInvoiceImpersonation(item, permissions), 
        [item, permissions]
    )
    const [error, setError] = useState(false);
    const [remittanceNumber, handleChange] = useState('');
    const [submit, setSubmit] = useState(false);
    const [disabled, setDisabled] = useState(false);
    let filteredAccount: Account[];

    const formatShipToString = () => {
        if (shipToAccounts && item) {
            filteredAccount = shipToAccounts.filter(
                (shipToAccount) => item.shipToId === +shipToAccount.accountId
            );
        }
    };

    formatShipToString();

    const confirmPayment = () => {
        setSubmit(true);
        setDisabled(true);
        setError(false);
        if (item) {
            var currentDate,
                currentTime = moment();

            const paymentRequest = {
                messageType: 'REMITTANCE',
                fillCountry: 'NONE',
                region: regionCulture.regionCode,
                language: regionCulture.cultureCode,
                Email: userInfo?.email as string,
                messageData: {
                    firstName: userInfo?.given_name as string,
                    lastName: userInfo?.family_name as string,
                    Email: userInfo?.email as string,
                    companyName: filteredAccount[0].name,
                    companyId: item.shipToId.toString(),
                    Date: formattedMTDate(moment(currentDate), 'MM/DD/YYYY').toString() + ' ',
                    Time: ' ' + formattedMTDate(moment(currentTime), 'hh:mm A z').toString(),
                    customerPO: item.customerProductionOrderId,
                    productionOrderNumber: item.productionOrderNumber.toString(),
                    RemittanceNumber: remittanceNumber
                }
            } as IPaymentSubmitted;

            const axiosConfig: AxiosRequestConfig = createAxiosHeader(
                regionCulture?.cultureCode,
                regionCulture?.regionCode,
                accessToken
            );

            ContactService.sendMessage(paymentRequest, axiosConfig)
                .then((response) => {
                    dispatch(addInvoiceToSubmitList(item));
                    onClose();
                    setConfirmationOpen(true);
                    setSubmit(false);
                    setDisabled(false);
                })
                .catch(() => {
                    setError(true);
                    setSubmit(false);
                    setDisabled(false);
                });
        }
    };

    const setConfirmationClose = () => {
        setConfirmationOpen(false);
    };

    const onCancel = () => {
        onClose();
    };

    const { t } = useTranslation();

    return (
        <>
            <Modal
                open={open}
                close={onClose}
                title={t('provideRemittanceNumber', 'Provide a Remittance Number')}
                maxWidth="md"
                closeIcon={true}
                fullWidth={true}
            >
                <Grid container>
                    <Grid container item justify="center" className={classes.grayBox} xs={12}>
                        <Grid item xs={7}>
                            <Typography
                                align="center"
                                className={classes.remittanceText}
                                data-testid="provide-remittance"
                            >
                                <Trans i18nKey="provideRemittance">
                                    Providing a remittance number can speed up the processing of
                                    funds to your account.
                                </Trans>
                            </Typography>
                        </Grid>
                        <Grid container justify="center" item xs={12}>
                            <TextField
                                data-testid="remittance-number"
                                type="text"
                                className={classes.remittanceField}
                                placeholder={t('remittanceNumber', 'Remittance Number')}
                                helperText={t('optional', 'Optional')}
                                name="remittanceNumber"
                                onChange={(remittanceNumber) =>
                                    handleChange(remittanceNumber.target.value)
                                }
                            />
                        </Grid>
                    </Grid>
                    {isImpersonation && (
                        <Grid 
                            container 
                            item 
                            justify="flex-start" 
                            xs={10} 
                            className={classes.impersonationWarning}
                            data-testid={`impersonation-warning`}
                        >
                            <ImpersonationWarning 
                                showWarning={isImpersonation} 
                                warningType={'SUBMIT'}
                            />
                        </Grid>
                    )}
                    <Grid container justify="center" item xs={12}>
                        {submit && (
                            <Grid container item xs={12} className={classes.spinningLoader}>
                                <CircularProgress />
                            </Grid>
                        )}
                        {error && (
                            <Typography className={classes.submitMessage + ' ' + classes.failure}>
                                <Trans i18nKey="msgFailed">
                                    Message failed to send, please try again at a different time.
                                </Trans>
                            </Typography>
                        )}
                    </Grid>
                    <Grid
                        container
                        item
                        className={classes.buttonGrid}
                        xs={12}
                        spacing={2}
                        justify="flex-end"
                    >
                        <Grid container item justify="flex-end" xs={3}>
                            <Button
                                data-testid="cancel-button"
                                variant="outlined"
                                color="secondary"
                                onClick={onCancel}
                                className={classes.modalButton}
                            >
                                <Trans i18nKey="cancel">Cancel</Trans>
                            </Button>
                        </Grid>
                        <Grid container item justify="flex-end" xs={3} className={classes.spacing}>
                            <Button
                                data-testid="confirm-payment-button"
                                variant="contained"
                                color="primary"
                                onClick={confirmPayment}
                                className={classes.modalButton}
                                disabled={disabled || isImpersonation}
                            >
                                <Trans i18nKey="confirmPayment">Confirm Payment</Trans>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Modal>
            <PaymentSubmittedConfirmationModal
                open={confirmationOpen}
                onClose={setConfirmationClose}
            />
        </>
    );
};

export default SubmitPaymentModal;
