import React, { ReactElement } from 'react';
import {
    Accordion as MuiExpansionPanel,
    AccordionSummary as MuiExpansionPanelSummary,
    makeStyles,
    Typography,
    withStyles
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckboxFacetPanelDetails from './CheckboxFacetPanelDetails';
import Tooltip from '../../../reusable/atoms/Tooltip';
import { ltBlueGrey_3 } from '../../../../themes/globalConstants';

interface Props {
    key?: any;
    translatedCategory: ReactElement;
    category: string;
    value: Array<any>;
    tooltip: ReactElement;
    defaultExpanded?: boolean;
}

const useStyles = makeStyles({
    title: {
        display: 'flex',
        alignItems: 'center',
        margin: '0',
        fontSize: 16,
        textTransform: 'capitalize'
    }
});

const StyledExpansionPanel = withStyles((theme) => ({
    root: {
        margin: 0,
        boxShadow: 'none',
        borderRadius: '0',
        '&:before': {
            display: 'none'
        },
        '&:not(:last-child)': {
            borderBottom: '1px solid ' + ltBlueGrey_3
        },
        '&$expanded': {
            margin: 0
        }
    },
    expanded: {
        paddingBottom: '0.75em'
    }
}))(MuiExpansionPanel);

const StyledExpansionPanelSummary = withStyles((theme) => ({
    root: {
        margin: '0',
        padding: '0',
        minHeight: '2.125em',
        '&$expanded': {
            minHeight: '2.125em'
        }
    },
    content: {
        margin: '0',
        '&$expanded': {
            margin: '0.75em 0'
        }
    },
    expanded: {},
    expandIcon: {
        color: theme.palette.primary.main,
        transform: 'rotate(-90deg)',
        '&$expanded': {
            transform: 'rotate(0deg)'
        }
    }
}))(MuiExpansionPanelSummary);

const Facet = (props: Props) => {
    const classes = useStyles();

    return (
        <StyledExpansionPanel defaultExpanded={props.defaultExpanded ?? false}>
            <StyledExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={'filter-panel-option' + props.category}
                id={'key-' + props.category}
            >
                <Typography variant="h5" className={classes.title}>
                    {props.translatedCategory}
                    <Tooltip content={props.tooltip} ariaLabel={`${props.category}-tooltip`} />
                </Typography>
            </StyledExpansionPanelSummary>
            <CheckboxFacetPanelDetails {...props} />
        </StyledExpansionPanel>
    );
};

export default Facet;
