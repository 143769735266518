import {
    Account,
    AddressType,
    ProdOrderType,
    Status
} from '../../../../../store/reducers/customer-context';

export enum RoleAccessKey {
    AllAccess = 'allAccess',
    OrderFullfillment = 'orderFullfillment',
    ProcurementMgt = 'procurementMgt',
    ForecastMgt = 'forecastMgt',
    PackagingMgt = 'packagingMgt',
    CoPacker = 'coPacker',
    WarehousingLogistics = 'warehousingLogistics',
    ArtMgt = 'artMgt',
    MarketingMgt = 'marketingMgt',
    AcctPayableReceivable = 'acctPayableReceivable',
    GraphicsVendor = 'graphicsVendor',
    PricingContact = 'pricingContact',
    MakeItOnly = 'makeItOnly',
    ShipItOnly = 'shipItOnly'
}

const disabledCheckList = {
    [RoleAccessKey.AllAccess]: [
        RoleAccessKey.OrderFullfillment,
        RoleAccessKey.ProcurementMgt,
        RoleAccessKey.ForecastMgt,
        RoleAccessKey.PackagingMgt,
        RoleAccessKey.CoPacker,
        RoleAccessKey.WarehousingLogistics,
        RoleAccessKey.ArtMgt,
        RoleAccessKey.MarketingMgt,
        RoleAccessKey.AcctPayableReceivable,
        RoleAccessKey.GraphicsVendor,
        RoleAccessKey.PricingContact,
        RoleAccessKey.MakeItOnly,
        RoleAccessKey.ShipItOnly
    ],
    [RoleAccessKey.OrderFullfillment]: [
        RoleAccessKey.AllAccess,
        RoleAccessKey.ForecastMgt,
        RoleAccessKey.GraphicsVendor,
        RoleAccessKey.MakeItOnly,
        RoleAccessKey.ShipItOnly
    ],
    [RoleAccessKey.ProcurementMgt]: [RoleAccessKey.AllAccess, RoleAccessKey.GraphicsVendor],
    [RoleAccessKey.ForecastMgt]: [
        RoleAccessKey.AllAccess,
        RoleAccessKey.OrderFullfillment,
        RoleAccessKey.GraphicsVendor,
        RoleAccessKey.MakeItOnly,
        RoleAccessKey.ShipItOnly
    ],
    [RoleAccessKey.PackagingMgt]: [RoleAccessKey.AllAccess, RoleAccessKey.GraphicsVendor],
    [RoleAccessKey.CoPacker]: [RoleAccessKey.AllAccess, RoleAccessKey.GraphicsVendor],
    [RoleAccessKey.WarehousingLogistics]: [RoleAccessKey.AllAccess, RoleAccessKey.GraphicsVendor],
    [RoleAccessKey.ArtMgt]: [RoleAccessKey.AllAccess, RoleAccessKey.GraphicsVendor],
    [RoleAccessKey.MarketingMgt]: [RoleAccessKey.AllAccess, RoleAccessKey.GraphicsVendor],
    [RoleAccessKey.AcctPayableReceivable]: [RoleAccessKey.AllAccess, RoleAccessKey.GraphicsVendor],
    [RoleAccessKey.GraphicsVendor]: [
        RoleAccessKey.AllAccess,
        RoleAccessKey.OrderFullfillment,
        RoleAccessKey.ProcurementMgt,
        RoleAccessKey.ForecastMgt,
        RoleAccessKey.PackagingMgt,
        RoleAccessKey.CoPacker,
        RoleAccessKey.WarehousingLogistics,
        RoleAccessKey.ArtMgt,
        RoleAccessKey.MarketingMgt,
        RoleAccessKey.AcctPayableReceivable,
        RoleAccessKey.PricingContact,
        RoleAccessKey.MakeItOnly,
        RoleAccessKey.ShipItOnly
    ],
    [RoleAccessKey.PricingContact]: [RoleAccessKey.AllAccess, RoleAccessKey.GraphicsVendor],
    [RoleAccessKey.MakeItOnly]: [
        RoleAccessKey.AllAccess,
        RoleAccessKey.OrderFullfillment,
        RoleAccessKey.ForecastMgt,
        RoleAccessKey.GraphicsVendor,
        RoleAccessKey.ShipItOnly
    ],
    [RoleAccessKey.ShipItOnly]: [
        RoleAccessKey.AllAccess,
        RoleAccessKey.OrderFullfillment,
        RoleAccessKey.ForecastMgt,
        RoleAccessKey.GraphicsVendor,
        RoleAccessKey.MakeItOnly
    ]
};

export class ShipToRoleAccess {
    private _allAccess = false;
    private _orderFullfillment = false;
    private _procurementMgt = false;
    private _forecastMgt = false;
    private _packagingMgt = false;
    private _coPacker = false;
    private _warehousingLogistics = false;
    private _artMgt = false;
    private _marketingMgt = false;
    private _acctPayableReceivable = false;
    private _graphicsVendor = false;
    private _pricingContact = false;
    private _makeItOnly = false;
    private _shipItOnly = false;
    private _isBillToAccount: boolean;
    private _isShipToAccount: boolean;
    private _isUserAdmin: boolean;

    name: string;
    addressType: AddressType;
    status: Status;
    prodOrderType?: ProdOrderType;
    accountId: string;
    address: {
        address1?: string;
        address2?: string;
        address3?: string;
        address4?: string;
        city?: string;
        stateProvince?: string;
        postalCode?: string;
        country?: string;
    };
    disabledList: {
        allAccess: false;
        orderFullfillment: false;
        procurementMgt: false;
        forecastMgt: false;
        packagingMgt: false;
        coPacker: false;
        warehousingLogistics: false;
        artMgt: false;
        marketingMgt: false;
        acctPayableReceivable: false;
        graphicsVendor: false;
        pricingContact: false;
        makeItOnly: false;
        shipItOnly: false;
    };

    constructor(account: Account) {
        this.name = account.name;
        this.addressType = account.addressType;
        this.status = account.status;
        this.prodOrderType = account.prodOrderType;
        this.accountId = account.accountId;
        this.address = { ...account.address };
        this._isBillToAccount =
            this.addressType === AddressType.CX || this.addressType === AddressType.CB;
        this._isShipToAccount =
            this.addressType === AddressType.CX || this.addressType === AddressType.CS;
        this._isUserAdmin = false;
        this.disabledList = {
            allAccess: false,
            orderFullfillment: false,
            procurementMgt: false,
            forecastMgt: false,
            packagingMgt: false,
            coPacker: false,
            warehousingLogistics: false,
            artMgt: false,
            marketingMgt: false,
            acctPayableReceivable: false,
            graphicsVendor: false,
            pricingContact: false,
            makeItOnly: false,
            shipItOnly: false
        };
    }

    checkPermissionDisabled = (type: string, clear: boolean = false) => {
        for (const [key] of Object.entries(this.disabledList)) {
            if (disabledCheckList[type].includes(key)) {
                this.disabledList[key] = clear ? false : true;
            } else {
                this.disabledList[key] = false;
            }
        }
    };

    get isBillToAccount() {
        return this._isBillToAccount;
    }

    get isShipToAccount() {
        return this._isShipToAccount;
    }

    get isUserAdmin() {
        return this._isUserAdmin;
    }

    set isUserAdmin(val: boolean) {
        this._isUserAdmin = val;
    }

    get allAccess() {
        return this._allAccess;
    }
    set allAccess(val: boolean) {
        if (this.disabledList.allAccess === false) {
            if (val === true) {
                this._orderFullfillment = false;
                this._procurementMgt = false;
                this._forecastMgt = false;
                this._packagingMgt = false;
                this._coPacker = false;
                this._warehousingLogistics = false;
                this._artMgt = false;
                this._marketingMgt = false;
                this._acctPayableReceivable = false;
                this._graphicsVendor = false;
                this._pricingContact = false;
                this._makeItOnly = false;
                this._shipItOnly = false;
                this.checkPermissionDisabled(RoleAccessKey.AllAccess);
            } else {
                this.checkPermissionDisabled(RoleAccessKey.AllAccess, true);
            }
            this._allAccess = val;
        }
    }

    get orderFullfillment() {
        return this._orderFullfillment;
    }
    set orderFullfillment(val: boolean) {
        if (this.disabledList.orderFullfillment === false) {
            if (this.isShipToAccount) {
                if (val === true) {
                    this._allAccess = false;
                    this._graphicsVendor = false;
                    this._makeItOnly = false;
                    this._shipItOnly = false;
                    this.checkPermissionDisabled(RoleAccessKey.OrderFullfillment);
                } else {
                    this.checkPermissionDisabled(RoleAccessKey.OrderFullfillment, true);
                }
                this._orderFullfillment = val;
            }
        }
    }
    get procurementMgt() {
        return this._procurementMgt;
    }
    set procurementMgt(val: boolean) {
        if (this.disabledList.procurementMgt === false) {
            if (this.isShipToAccount) {
                if (val === true) {
                    this._allAccess = false;
                    this._graphicsVendor = false;
                    this.checkPermissionDisabled(RoleAccessKey.ProcurementMgt);
                } else {
                    this.checkPermissionDisabled(RoleAccessKey.ProcurementMgt, true);
                }
                this._procurementMgt = val;
            }
        }
    }
    get forecastMgt() {
        return this._forecastMgt;
    }
    set forecastMgt(val: boolean) {
        if (this.disabledList.forecastMgt === false) {
            if (this.isShipToAccount) {
                if (val === true) {
                    this._allAccess = false;
                    this._graphicsVendor = false;
                    this.checkPermissionDisabled(RoleAccessKey.ForecastMgt);
                } else {
                    this.checkPermissionDisabled(RoleAccessKey.ForecastMgt, true);
                }
                this._forecastMgt = val;
            }
        }
    }
    get packagingMgt() {
        return this._packagingMgt;
    }
    set packagingMgt(val: boolean) {
        if (this.disabledList.packagingMgt === false) {
            if (this.isShipToAccount) {
                if (val === true) {
                    this._allAccess = false;
                    this._graphicsVendor = false;
                    this.checkPermissionDisabled(RoleAccessKey.PackagingMgt);
                } else {
                    this.checkPermissionDisabled(RoleAccessKey.PackagingMgt, true);
                }
                this._packagingMgt = val;
            }
        }
    }
    get coPacker() {
        return this._coPacker;
    }
    set coPacker(val: boolean) {
        if (this.disabledList.coPacker === false) {
            if (this.isShipToAccount) {
                if (val === true) {
                    this._allAccess = false;
                    this._graphicsVendor = false;
                    this.checkPermissionDisabled(RoleAccessKey.CoPacker);
                } else {
                    this.checkPermissionDisabled(RoleAccessKey.CoPacker, true);
                }
                this._coPacker = val;
            }
        }
    }
    get warehousingLogistics() {
        return this._warehousingLogistics;
    }
    set warehousingLogistics(val: boolean) {
        if (this.disabledList.warehousingLogistics === false) {
            if (this.isShipToAccount) {
                if (val === true) {
                    this._allAccess = false;
                    this._graphicsVendor = false;
                    this.checkPermissionDisabled(RoleAccessKey.WarehousingLogistics);
                } else {
                    this.checkPermissionDisabled(RoleAccessKey.WarehousingLogistics, true);
                }
                this._warehousingLogistics = val;
            }
        }
    }
    get artMgt() {
        return this._artMgt;
    }
    set artMgt(val: boolean) {
        if (this.disabledList.artMgt === false) {
            if (this.isBillToAccount) {
                if (val === true) {
                    this._allAccess = false;
                    this._graphicsVendor = false;
                    this.checkPermissionDisabled(RoleAccessKey.ArtMgt);
                } else {
                    this.checkPermissionDisabled(RoleAccessKey.ArtMgt, true);
                }
                this._artMgt = val;
            }
        }
    }
    get marketingMgt() {
        return this._marketingMgt;
    }
    set marketingMgt(val: boolean) {
        if (this.disabledList.marketingMgt === false) {
            if (this.isBillToAccount) {
                if (val === true) {
                    this._allAccess = false;
                    this._graphicsVendor = false;
                    this.checkPermissionDisabled(RoleAccessKey.MarketingMgt);
                } else {
                    this.checkPermissionDisabled(RoleAccessKey.MarketingMgt, true);
                }
                this._marketingMgt = val;
            }
        }
    }

    get acctPayableReceivable() {
        return this._acctPayableReceivable;
    }
    set acctPayableReceivable(val: boolean) {
        if (this.disabledList.acctPayableReceivable === false) {
            if (this.isBillToAccount) {
                if (val === true) {
                    this._allAccess = false;
                    this._graphicsVendor = false;
                    this.checkPermissionDisabled(RoleAccessKey.AcctPayableReceivable);
                } else {
                    this.checkPermissionDisabled(RoleAccessKey.AcctPayableReceivable, true);
                }
                this._acctPayableReceivable = val;
            }
        }
    }
    get graphicsVendor() {
        return this._graphicsVendor;
    }
    set graphicsVendor(val: boolean) {
        if (this.disabledList.graphicsVendor === false) {
            if (this.isBillToAccount) {
                if (val === true) {
                    this._allAccess = false;
                    this._orderFullfillment = false;
                    this._procurementMgt = false;
                    this._forecastMgt = false;
                    this._packagingMgt = false;
                    this._coPacker = false;
                    this._warehousingLogistics = false;
                    this._artMgt = false;
                    this._marketingMgt = false;
                    this._acctPayableReceivable = false;
                    this._pricingContact = false;
                    this._makeItOnly = false;
                    this._shipItOnly = false;
                    this.checkPermissionDisabled(RoleAccessKey.GraphicsVendor);
                } else {
                    this.checkPermissionDisabled(RoleAccessKey.GraphicsVendor, true);
                }
                this._graphicsVendor = val;
            }
        }
    }

    get pricingContact() {
        return this._pricingContact;
    }
    set pricingContact(val: boolean) {
        if (this.disabledList.pricingContact === false) {
            if (this.isBillToAccount) {
                if (val === true) {
                    this._allAccess = false;
                    this._graphicsVendor = false;
                    this.checkPermissionDisabled(RoleAccessKey.PricingContact);
                } else {
                    this.checkPermissionDisabled(RoleAccessKey.PricingContact, true);
                }
                this._pricingContact = val;
            }
        }
    }

    get makeItOnly() {
        return this._makeItOnly;
    }
    set makeItOnly(val: boolean) {
        if (this.disabledList.makeItOnly === false) {
            if (this.isShipToAccount) {
                if (val === true) {
                    this._allAccess = false;
                    this._graphicsVendor = false;
                    this._orderFullfillment = false;
                    this._shipItOnly = false;
                    this.checkPermissionDisabled(RoleAccessKey.MakeItOnly);
                } else {
                    this.checkPermissionDisabled(RoleAccessKey.MakeItOnly, true);
                }
                this._makeItOnly = val;
            }
        }
    }
    get shipItOnly() {
        return this._shipItOnly;
    }
    set shipItOnly(val: boolean) {
        if (this.disabledList.shipItOnly === false) {
            if (this.isShipToAccount) {
                if (val === true) {
                    this._allAccess = false;
                    this._graphicsVendor = false;
                    this._orderFullfillment = false;
                    this._makeItOnly = false;
                    this.checkPermissionDisabled(RoleAccessKey.ShipItOnly);
                } else {
                    this.checkPermissionDisabled(RoleAccessKey.ShipItOnly, true);
                }
                this._shipItOnly = val;
            }
        }
    }
}
