import React, { ReactElement, useMemo } from 'react';
import rootTheme from '../../../../themes/rootTheme';
import { Grid, makeStyles, Button } from '@material-ui/core';
import { useHistory } from 'react-router';
import { styles } from './styles';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
    handleSubmit: () => void;
    disableSubmission: boolean;
    allowReturn: boolean;
}

export default function Footer({
    handleSubmit,
    disableSubmission,
    allowReturn
}: Props): ReactElement {
    const useStyles = makeStyles(styles(rootTheme) as any);
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();

    const cancelButtonText = useMemo(() => {
        return allowReturn ? t('returnToSummary', 'Return to Summary') : t('cancel', 'Cancel');
    }, [allowReturn]);

    return (
        <>
            <Grid item xs={2} className={classes.cancelContainer}>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    className={classes.actionButton}
                    onClick={() => history.push('/manage-communications')}
                >
                    {cancelButtonText}
                </Button>
            </Grid>
            <Grid item xs={2} className={classes.submitContainer}>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    className={classes.actionButton}
                    disabled={disableSubmission}
                    onClick={handleSubmit}
                >
                    <Trans i18nKey="submit">Submit</Trans>
                </Button>
            </Grid>
        </>
    );
}
