import React from 'react';
import PdfPageTemplate from '../../../templates/PdfPageTemplate';
import { Document, Page, Text, View, BlobProvider, Image } from '@react-pdf/renderer';
import pdfStyles from '../../../../themes/pdfStyles';
import { isIE } from '../../../../utility/helpers/user-helpers';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { useTranslation } from 'react-i18next';

interface Props {
    title: string;
    content: any[];
}

const UnrecognizedProductsPDF = ({ title, content }: Props) => {
    const { t } = useTranslation();
    const ballLogo = process.env.PUBLIC_URL + '/assets/Ball_RGB.jpg';
    const sourceLogo = process.env.PUBLIC_URL + '/assets/TheSource_Logo.png';
    const { shipToAccounts, selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const summary = (
        <>
            <View style={[pdfStyles.borderlessTable, pdfStyles.marginTop20]}>
                <View style={[pdfStyles.flexSection, pdfStyles.pageHeader]}>
                    <View style={[pdfStyles.divider25, pdfStyles.marginBottom10]} fixed />
                    <View>
                        <View>
                            <Text style={pdfStyles.header2}>
                                {t('unrecognizedProducts', 'Unrecognized Products')}
                            </Text>
                        </View>
                        <View>
                            <Text style={[pdfStyles.subtitle14, pdfStyles.marginBottom3]}>
                                SHIP TO: #{selectedAccountId}
                            </Text>
                        </View>
                    </View>
                    <View style={[pdfStyles.borderLessTableColNarrow6, pdfStyles.textAlignRight]}>
                        <View>
                            <Text style={pdfStyles.header2}>
                                Number of {t('unrecognizedProducts', 'Unrecognized Products')}
                            </Text>
                        </View>
                        <View>
                            <Text style={[pdfStyles.subtitle14, pdfStyles.marginBottom3]}>
                                {content.length}
                            </Text>
                        </View>
                    </View>

                    <View style={[pdfStyles.divider25, pdfStyles.marginBottom10]} fixed />
                    <View style={[pdfStyles.borderLessTableCol, pdfStyles.textAlignLeft]}>
                        <View>
                            <Text style={[pdfStyles.header2, pdfStyles.marginBottom3]}>
                                {!!shipToAccounts
                                    ? shipToAccounts
                                          .filter((obj) => obj.accountId === selectedAccountId)
                                          .map((obj) => obj.name)
                                    : 'Invalid'}
                            </Text>
                        </View>
                        <View>
                            <Text style={[pdfStyles.header12, pdfStyles.marginBottom3]}>
                                {!!shipToAccounts
                                    ? shipToAccounts
                                          .filter((obj) => obj.accountId === selectedAccountId)
                                          .map((obj) => obj.address.address1)
                                    : 'Invalid'}
                                ,
                            </Text>
                            <Text style={[pdfStyles.header12, pdfStyles.marginBottom3]}>
                                {!!shipToAccounts
                                    ? shipToAccounts
                                          .filter((obj) => obj.accountId === selectedAccountId)
                                          .map((obj) => obj.address.city)
                                    : 'Invalid'}
                                ,{' '}
                                {!!shipToAccounts
                                    ? shipToAccounts
                                          .filter((obj) => obj.accountId === selectedAccountId)
                                          .map((obj) => obj.address.stateProvince)
                                    : 'Invalid'}
                                ,{' '}
                                {!!shipToAccounts
                                    ? shipToAccounts
                                          .filter((obj) => obj.accountId === selectedAccountId)
                                          .map((obj) => obj.address.postalCode)
                                    : 'Invalid'}
                            </Text>
                        </View>
                    </View>
                    <View style={[pdfStyles.borderLessTableCol, pdfStyles.textAlignLeft]} />
                </View>
            </View>
        </>
    );

    const tableHeaders = (
        <>
            <View style={[pdfStyles.borderlessTable, pdfStyles.marginTop20]}>
                <View style={[pdfStyles.flexSection, pdfStyles.pageHeader]}>
                    <View style={[pdfStyles.borderLessTableColNarrow5, pdfStyles.textAlignLeft]}>
                        <View>
                            <Text style={pdfStyles.header2}>
                                {t('customerProductId', 'Customer Product ID')}
                            </Text>
                        </View>
                    </View>
                    <View style={[pdfStyles.borderLessTableColNarrow5, pdfStyles.textAlignLeft]}>
                        <View>
                            <Text style={pdfStyles.header2}>
                                {t('productName', 'Product Name')}
                            </Text>
                        </View>
                    </View>
                    <View style={[pdfStyles.borderLessTableColNarrow5, pdfStyles.textAlignLeft]}>
                        <View>
                            <Text style={pdfStyles.header2}>
                                {t('productSizeAndShape', 'Product Size/Shape')}
                            </Text>
                        </View>
                    </View>
                    <View style={[pdfStyles.dividerThinGray]} fixed />
                    <View style={[pdfStyles.flexSection, pdfStyles.marginBottom10]}></View>
                </View>
            </View>
        </>
    );

    const tableItems = content.map((line) => {
        return (
            <>
                <View style={[pdfStyles.borderlessTable]}>
                    <View style={[pdfStyles.flexSection, pdfStyles.pageHeader]}>
                        <View
                            style={[pdfStyles.borderLessTableColNarrow5, pdfStyles.textAlignLeft]}
                        >
                            <View>
                                <Text style={pdfStyles.header2}>{line.customer_product_id}</Text>
                            </View>
                        </View>
                        <View
                            style={[pdfStyles.borderLessTableColNarrow5, pdfStyles.textAlignLeft]}
                        >
                            <View>
                                <Text style={pdfStyles.header2}>{line.display_name}</Text>
                            </View>
                        </View>
                        <View
                            style={[pdfStyles.borderLessTableColNarrow5, pdfStyles.textAlignLeft]}
                        >
                            <View>
                                <Text style={pdfStyles.header2}>{line.product_description}</Text>
                            </View>
                        </View>
                        <View style={[pdfStyles.dividerThinGray, pdfStyles.marginBottom10]} fixed />
                    </View>
                </View>
            </>
        );
    });

    const myDoc = (
        <Document title={title}>
            <Page size="A4" style={pdfStyles.pagePadding25} orientation={'portrait'}>
                <View style={pdfStyles.logoSection}>
                    <Image src={sourceLogo} style={pdfStyles.sourceLogo} />
                    <Image src={ballLogo} style={pdfStyles.ballLogo} />
                </View>
                <View style={[pdfStyles.flexSection, pdfStyles.pageHeader]}>{summary}</View>
                <View style={[pdfStyles.flexSection, pdfStyles.pageHeader]}>{tableHeaders}</View>
                <View style={[pdfStyles.flexSection, pdfStyles.pageHeader]}>{tableItems}</View>
                <View style={[pdfStyles.fullWidth, pdfStyles.footer]} fixed>
                    <Text
                        style={pdfStyles.header2}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </View>
            </Page>
        </Document>
    );

    if (!isIE()) {
        return <PdfPageTemplate title={title}>{myDoc}</PdfPageTemplate>;
    } else {
        return (
            <BlobProvider document={myDoc}>
                {({ blob, url, loading, error }) => {
                    if (blob && window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob);
                    }
                    return null;
                }}
            </BlobProvider>
        );
    }
};

export default UnrecognizedProductsPDF;
