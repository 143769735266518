import React, { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    Button,
    makeStyles,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from '@material-ui/core';
import {
    brightGrey,
    boldWeight,
    large,
    xl,
    blackWeight,
    small,
    regularWeight
} from '../../../themes/globalConstants';
import { useTranslation, Trans } from 'react-i18next';
import Modal from './Modal';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { BulkUploadDeliveryState } from '../../../store/reducers/bulk-upload';
import { ProductWithPallets } from '../../../store/reducers/shipping-dashboard';

interface Props {
    customerProductId: string;
    candidateProductSkus: string[];
    requestedQuantity: number;
    open: boolean;
    onClose: () => void;
    onAcceptProductSku: (product: ProductWithPallets) => void;
}

const useStyles = makeStyles((theme) => ({
    modal: {
        '& .MuiPaper-root': {
            maxWidth: '50em'
        },
        '& .MuiDialog-paperScrollPaper': {
            borderRadius: 'unset',
            overflow: 'hidden'
        },
        '& .MuiDialogTitle-root': {
            justifyContent: 'left'
        }
    },
    content: {
        letterSpacing: '1px',
        color: brightGrey,
        padding: '2em'
    },
    contentWrapper: {
        width: '100%'
    },
    textBuffer: {
        marginTop: '1em'
    },
    text: {
        fontSize: large,
        fontWeight: regularWeight,
        letterSpacing: '0.2px'
    },
    button: {
        height: 40,
        marginTop: 30,
        fontSize: small,
        fontWeight: blackWeight,
        textTransform: 'uppercase',
        marginRight: 5,
        padding: '0.5em',
        width: '90%'
    },
    buttonContainer: {
        marginTop: '1em'
    },
    contentHeader: {
        fontSize: xl,
        fontWeight: boldWeight,
        color: theme.palette.info.dark
    },
    table: {
        margin: '1.5em 0'
    }
}));

const DeliveryBulkProductIdModal = ({
    customerProductId,
    candidateProductSkus,
    requestedQuantity,
    open,
    onClose,
    onAcceptProductSku
}: Props) => {
    const classes = useStyles();
    const { productsWithPallets } = useTypedSelector<BulkUploadDeliveryState>(
        (state) => state.bulkUploadDelivery
    );
    const [isOpen, setIsOpen] = useState(open);
    const [radioValue, setRadioValue] = useState<string>('');

    const handleCloseModal = () => {
        setIsOpen(false);
        setRadioValue('');
        onClose();
    };

    const handleAcceptSku = () => {
        onAcceptProductSku(productsWithPallets?.find((p) => p.productSku === radioValue)!);
        handleCloseModal();
    };

    const bubbleRadioChange = (event) => {
        setRadioValue(event.currentTarget.value);
    };

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    const { t } = useTranslation();

    return (
        <Modal
            open={isOpen}
            close={handleCloseModal}
            title={t('productIdModalTitle', 'Confirm Requested Product')}
            closeIcon={true}
            fullWidth={true}
            className={classes.modal}
        >
            <Grid
                container
                justify="flex-start"
                className={classes.content}
                data-testid="product-id-modal"
            >
                <Grid item className={classes.contentWrapper}>
                    <Grid item className={classes.textBuffer}>
                        <Typography className={classes.text} data-testid="subheader">
                            <Trans i18nKey="productIdDeliveryModalBody">
                                The customer product ID: <strong>{{ customerProductId }}</strong>{' '} you requested <strong>{{ requestedQuantity }} Pallets</strong>
                                for in your .csv file returned multiple products. Please
                                choose the correct product for this order.
                            </Trans>
                        </Typography>
                    </Grid>
                    <TableContainer>
                        <Table
                            className={classes.table}
                            size="medium"
                            aria-label="fee table"
                            data-testid="fee-table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={2} data-testid="ball-product-sku-cell">
                                        <Typography className={classes.contentHeader}>
                                            <Trans i18nKey="ballProductSku">Ball Product SKU</Trans>
                                        </Typography>
                                    </TableCell>
                                    <TableCell colSpan={2} data-testid="product-descr-cell">
                                        <Typography className={classes.contentHeader}>
                                            <Trans i18nKey="productDescription">
                                                Product Description
                                            </Trans>
                                        </Typography>
                                    </TableCell>
                                    <TableCell colSpan={2} data-testid="graphics-id-cell">
                                        <Typography className={classes.contentHeader}>
                                            <Trans i18nKey="graphicsID">Graphics ID</Trans>
                                        </Typography>
                                    </TableCell>
                                    <TableCell colSpan={2} data-testid="available-balance-cell">
                                        <Typography className={classes.contentHeader}>
                                            <Trans i18nKey="availBalance">Avail. Balance</Trans>
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {candidateProductSkus?.map((sku, index) => {
                                    const product = productsWithPallets?.find(
                                        (p) => p.productSku === sku
                                    );
                                    return (
                                        <TableRow key={index}>
                                            <TableCell colSpan={2}>
                                                <input
                                                    value={sku}
                                                    name="product-sku-radio"
                                                    type="radio"
                                                    onChange={bubbleRadioChange}
                                                />{' '}
                                                {sku}
                                            </TableCell>
                                            <TableCell colSpan={2}>
                                                <Typography>{product?.displayName}</Typography>
                                            </TableCell>
                                            <TableCell colSpan={2}>
                                                <Typography>
                                                    {product?.graphicIdAndVersion}
                                                </Typography>
                                            </TableCell>
                                            <TableCell colSpan={2}>
                                                <Typography>{product?.availablePallets}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid
                        container
                        item
                        xs={12}
                        alignItems="center"
                        justify="flex-end"
                        className={classes.buttonContainer}
                    >
                        <Grid item xs={3}>
                            <Button
                                type="button"
                                color="primary"
                                variant="outlined"
                                data-testid="cancel-product-id-btn"
                                onClick={handleCloseModal}
                                className={classes.button}
                            >
                                <Trans i18nKey="cancel">Cancel</Trans>
                            </Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                data-testid="accept-product-sku-btn"
                                onClick={handleAcceptSku}
                                className={classes.button}
                                disabled={!radioValue}
                            >
                                <Trans i18nKey="confirm">Confirm</Trans>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default DeliveryBulkProductIdModal;
