import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Activity } from '../../../utility/auth/useSecurity';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import Button from '../../reusable/atoms/Button';
import { useTypedSelector } from '../../../store/reducers/reducer';
import {
    ballBlue,
    blackWeight,
    boldWeight,
    containerMaxWidth,
    small
} from '../../../themes/globalConstants';
import { formattedMTDate } from '../../../utility/helpers/date-helpers';
import moment from 'moment';
import { InfoSubheaderItem } from '../../../store/reducers/glp';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { RegionCultureState } from '../../../store/reducers/region-culture';
import { usePrevious } from '../../../utility/helpers/react-util';
import {
    resetMakeItBulkATMState,
    saveOrdersBySnoSku,
    updateExpandAll
} from '../../../store/actions/makeit-bulk-atm';
import {
    BulkUploadMakeItATMState,
    MakeItBulkATMLinesBySnoSku,
    MakeItBulkATMOrder,
    MakeItBulkATMOrderByWeekAndSku
} from '../../../store/reducers/makeit-bulk-atm';
import MakeItBulkATMConfirmationShareLinks from './components/MakeItBulkATMConfirmationShareLinks';
import { FormattedWeek } from '../MakeItBulkReviewATM/models/FormattedWeek';
import {
    DateProperties,
    evaluatePalletQuantityValid,
    getDateProperties,
    getFeeStructure,
    groupOrdersBySnoSku,
    PalletQuantityState
} from '../../../utility/helpers/make-it-bulk-helpers';
import { ToggleAll } from '../../../utility/helpers/order-helpers';
import MakeItBulkATMConfirmationSubheader from './components/MakeItBulkATMConfirmationSubheader';
import MakeItBulkATMConfirmationOrderSection from './components/MakeItBulkATMConfirmationOrderSection';
import { getFormattedEachesFromNumber } from '../../../utility/helpers/shipment-helpers';
import { filterOutDeletedOrders } from './utils/mib-atm-confirmation-utils';
import { formatNumberWithLocale } from '../../../utility/helpers/formatters';
import ShipToDropdown from '../MakeItBulkReviewATM/components/ShipToDropdown';
import WeekSelectorDropdown from '../MakeItBulkReviewATM/components/WeekSelectorDropdown';
import { FormattedAccount } from '../MakeItBulkReviewATM/models/FormattedAccount';
import { CustomerContextState } from '../../../store/reducers/customer-context';
import { getOrderQuantities } from '../MakeItATMSummary/utils/makeit-atm-summary-utils';
import {
    getMoqFeeCeiling,
    getMoqFeeFloor
} from '../../../utility/helpers/production-order-helpers';
import { selectIsLargeCustomerAccount, selectIsCIACustomer } from '../../../store/selectors';
import PaymentStatusHeader from '../../reusable/molecules/PaymentStatusHeader';
import { useQuery } from '../../../utility/helpers/query-helpers';

const useStyles = makeStyles((theme) => ({
    actionBtn: {
        borderRadius: 'unset'
    },
    summarySection: {
        flexWrap: 'nowrap'
    },
    subheaderPaper: {
        padding: '1em',
        borderRadius: 2,
        maxWidth: containerMaxWidth
    },
    dateSelectSection: {
        paddingTop: '1em'
    },
    viewAtmTitle: {
        fontWeight: blackWeight,
        color: theme.palette.secondary.main,
        textTransform: 'capitalize'
    },
    btn: {
        padding: 0,
        minWidth: 'auto',
        fontSize: small,
        fontWeight: boldWeight,
        margin: '0 0.5em'
    },
    expandCollapseWrapper: {
        alignItems: 'center',
        color: ballBlue
    },
    selector: {
        display: 'flex',
        marginTop: '1em'
    },
    shareLinksContainer: {
        marginBottom: '1em'
    }
}));

export default function MakeItBulkATMConfirmation() {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const query = useQuery();
    const from = query.get('from');
    const fromSpot = from === 'spot';

    const { atmOrders, ordersBySnoSku, allocations } = useTypedSelector<BulkUploadMakeItATMState>(
        (state) => state.bulkUploadMakeItATMState
    );

    const CustomerPONum = !!atmOrders ? atmOrders[0].customerProductionOrderId : 'None';
    const { shipToAccounts } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [formattedWeekList, setFormattedWeekList] = useState<FormattedWeek[]>();
    const [activeDate, setActiveDate] = useState<DateProperties>({
        fullDate: '',
        week: '',
        year: ''
    });
    const [orderFailed, setOrderFailed] = useState<boolean>(false);
    const [subheaderItems, setSubheaderItems] = useState<InfoSubheaderItem[]>();
    const [activeDateOrders, setActiveDateOrders] = useState<MakeItBulkATMOrderByWeekAndSku[]>();
    const [shipToAccountsList, setShipToAccountsList] = useState<FormattedAccount[]>([]);
    const [expandAll, setExpandAll] = useState<ToggleAll>({ toggle: false });
    const [selectedShipTo, setSelectedShipTo] = useState<string>();
    const prevCultureCode = usePrevious(cultureCode);
    const prevActiveDate = usePrevious(activeDate);
    const isLargeCustomer = useTypedSelector<boolean>(selectIsLargeCustomerAccount);
    const isCIACustomer = useTypedSelector<boolean>(selectIsCIACustomer);

    const handleDashboardNavigation = () => {
        dispatch(resetMakeItBulkATMState());
        history.push('/dashboard');
    };

    const handleExpandAll = () => {
        setExpandAll({ toggle: true });
        dispatch(updateExpandAll(false, activeDate.fullDate));
    };

    const handleCollapseAll = () => {
        setExpandAll({ toggle: false });
        dispatch(updateExpandAll(true, activeDate.fullDate));
    };

    useEffect(() => {
        return () => {
            if (!history.location.pathname.includes('make-it-bulk-po-confirmation')) {
                dispatch(resetMakeItBulkATMState());
            }
        };
    }, [dispatch, history]);

    const getQuantitiesMakeItSpotConfirmation = (
        atmOrders: MakeItBulkATMOrder[],
        activeDate: string
    ) => {
        let canQuantityEaches = 0;
        let canQuantityPallets = 0;
        let canSkus = 0;
        let endQuantityEaches = 0;
        let endQuantityPallets = 0;
        let endSkus = 0;
        const filteredOrders = atmOrders.filter((order) => order.weekStart === activeDate);
        filteredOrders.forEach((order) => {
            canQuantityEaches = canQuantityEaches + (order.canQuantityEaches ?? 0);
            canQuantityPallets = canQuantityPallets + order.canQuantityPallets;
            canSkus = canSkus + order.canQuantitySKUs;
            endQuantityEaches = endQuantityEaches + (order.endQuantityEaches ?? 0);
            endQuantityPallets = endQuantityPallets + order.endQuantityPallets;
            endSkus = endSkus + order.endQuantitySKUs;
        });
        return [
            canQuantityPallets,
            canQuantityEaches,
            canSkus,
            endQuantityPallets,
            endQuantityEaches,
            endSkus
        ];
    };

    const createSubheader = (activeDate: DateProperties, selectedShipTo?: string) => {
        if (isLargeCustomer) {
            let values = getOrderQuantities(activeDate, atmOrders, selectedShipTo);
            setSubheaderItems([
                {
                    subtitle: t('dateRevised', 'Date Revised'),
                    body: formattedMTDate(moment(), 'MM/DD/YYYY')
                },
                {
                    subtitle: t('canQuantity', 'Can Quantity'),
                    body: `${formatNumberWithLocale(cultureCode, values.canPallets)} PL/${
                        values.canSkus
                    } ${values.canSkus > 1 ? t('skus', 'SKUs') : t('sku', 'SKU')}`,
                    subtext: `${getFormattedEachesFromNumber(values.canEaches, cultureCode)} ea.`
                },
                {
                    subtitle: t('endQuantity', 'End Quantity'),
                    body: `${formatNumberWithLocale(cultureCode, values.endPallets)} PL/${
                        values.endSkus
                    } ${values.endSkus > 1 ? t('skus', 'SKUs') : t('sku', 'SKU')}`,
                    subtext: `${getFormattedEachesFromNumber(values.endEaches, cultureCode)} ea.`
                }
            ] as InfoSubheaderItem[]);
        } else {
            if (!!atmOrders) {
                let values = getQuantitiesMakeItSpotConfirmation(
                    atmOrders,
                    moment.utc(activeDate.fullDate).format('MM/DD/YYYY')
                );
                let noOrder = true;
                values.forEach((value) => {
                    if (value !== 0) {
                        noOrder = false;
                    }
                });
                if (noOrder) {
                    setOrderFailed(true);
                }
                setSubheaderItems([
                    {
                        subtitle: t('dateRevised', 'Date Revised'),
                        body: formattedMTDate(moment(), 'MM/DD/YYYY')
                    },
                    {
                        subtitle: t('canQuantity', 'Can Quantity'),
                        body: `${formatNumberWithLocale(cultureCode, values[0])} PL/${values[2]} ${
                            values[2] > 1 ? t('skus', 'SKUs') : t('sku', 'SKU')
                        }`,
                        subtext: `${getFormattedEachesFromNumber(values[1], cultureCode)} ea.`
                    },
                    {
                        subtitle: t('endQuantity', 'End Quantity'),
                        body: `${formatNumberWithLocale(cultureCode, values[3])} PL/${values[5]} ${
                            values[5] > 1 ? t('skus', 'SKUs') : t('sku', 'SKU')
                        }`,
                        subtext: `${getFormattedEachesFromNumber(values[4], cultureCode)} ea.`
                    }
                ] as InfoSubheaderItem[]);
            }
        }
    };
    const getBallProdOrderAndWeek = () => {
        let ballProductionOrder = ' ' + t('pending', 'Pending');
        if (atmOrders?.length === 1 && atmOrders[0].productionOrderNumber) {
            ballProductionOrder = atmOrders[0].productionOrderNumber.toString();
        }
        return `${t('ballProdOrder', 'Ball Production Order')} #${ballProductionOrder} ${t(
            'forTheWeekOf',
            'for the week of'
        )} ${activeDate.week}`;
    };

    /**
     * Load the Ship To Accounts from the order - only include a single ship to once.
     * @when Triggers on initial page load, after shipToAccounts and atmOrders are in state.
     */
    useEffect(() => {
        if (atmOrders && shipToAccounts) {
            let accountsInOrder: FormattedAccount[] = [];
            atmOrders.forEach((order) => {
                let orderShipTo = order.shipToId;
                let account = shipToAccounts.find(
                    (account) => parseInt(account.accountId) === orderShipTo
                );

                let formattedAccount = {
                    account: account,
                    state: 'valid'
                } as FormattedAccount;

                if (
                    account &&
                    !accountsInOrder.find((accountInList) => accountInList.account === account)
                ) {
                    accountsInOrder.push(formattedAccount);
                }
            });
            setShipToAccountsList(accountsInOrder);
        }
    }, [atmOrders, shipToAccounts]);

    useEffect(() => {
        if (orderFailed) {
            setOrderFailed(false);
        }
    }, [activeDate, orderFailed]);

    /**
     * Set the currently selected ship to as the first Account in the shipToAccountsList.
     * @when Trigger once on initial page load, after shipToAccountsList has been set from the above useEffect.
     */
    useEffect(() => {
        if (shipToAccountsList.length && !selectedShipTo) {
            setSelectedShipTo(shipToAccountsList[0].account.accountId);
        }
    }, [selectedShipTo, shipToAccountsList]);

    /**
     * Filter ordersBySnoSku by the currently selected ship to and active date.
     * Last step before order information is displayed.
     * @when Trigger if orders have been grouped by snoSku, when the activeDate
     * or the selectedShipTo are changed.
     * @deps ordersBySnoSku, activeDate, selectedShipTo
     */
    useEffect(() => {
        if (ordersBySnoSku && ordersBySnoSku.length > 0 && activeDate && selectedShipTo) {
            const ordersBySnoSkuUpdate = JSON.parse(JSON.stringify(ordersBySnoSku));
            const activeOrders = ordersBySnoSkuUpdate.filter((week) => {
                return (
                    getDateProperties(moment(week.weekStart)).week === activeDate.week &&
                    week.shipToId === parseInt(selectedShipTo)
                );
            });

            let formattedOrders: Array<MakeItBulkATMLinesBySnoSku> = [];

            activeOrders.forEach((order) => {
                order.linesBySnoSku.forEach((productGroup) => {
                    const palletQuantityStates: PalletQuantityState[] = [];
                    let hasDuplicateProducts = false;
                    productGroup.lines.forEach((line) => {
                        if (line.minimumOrderQuantity && !line.deleted) {
                            palletQuantityStates.push(
                                evaluatePalletQuantityValid(
                                    line.palletQuantity,
                                    line.minimumOrderQuantity,
                                    getFeeStructure(line),
                                    getMoqFeeFloor(line.moqFees),
                                    getMoqFeeCeiling(line.moqFees)
                                )
                            );
                        }
                        if (!line.isCustomerProductIdDistinct) {
                            hasDuplicateProducts = true;
                        }
                    });

                    productGroup.hasError =
                        palletQuantityStates.some(
                            (state) =>
                                state === 'minimumOrderQuantityError' ||
                                state === 'undefinedQuantity'
                        ) || hasDuplicateProducts;
                    productGroup.hasWarning = palletQuantityStates.some(
                        (state) => state === 'feeWarning'
                    );

                    if (productGroup.allocationAmount !== undefined) {
                        if (productGroup.totalPalletQuantity < productGroup.allocationAmount) {
                            productGroup.hasWarning = true;
                        }
                        if (productGroup.totalPalletQuantity > productGroup.allocationAmount) {
                            productGroup.hasError = true;
                        }
                    }
                    formattedOrders.push(productGroup);
                });
                order.linesBySnoSku = formattedOrders;
            });
            setActiveDateOrders(activeOrders);
            if (
                cultureCode !== prevCultureCode ||
                !subheaderItems?.length ||
                activeDate !== prevActiveDate
            ) {
                createSubheader(activeDate, selectedShipTo);
            }
        }
    }, [ordersBySnoSku, activeDate, selectedShipTo]);

    /**
     * Group atmOrders by snoSku.
     * @when Trigger only once on load, after we have queried for allocations.
     * @deps atmOrders, allocations
     */
    useEffect(() => {
        if (atmOrders && allocations) {
            const groupedOrders = groupOrdersBySnoSku(atmOrders, allocations);
            dispatch(saveOrdersBySnoSku(groupedOrders));
        }
    }, [atmOrders, allocations, dispatch]);

    useEffect(() => {
        if (atmOrders) {
            if (
                cultureCode !== prevCultureCode ||
                !subheaderItems?.length ||
                activeDate !== prevActiveDate
            ) {
                createSubheader(activeDate, selectedShipTo);
            }
            let weeksList: string[] = [];
            atmOrders
                .map((order) => order.weekStart)
                .forEach((weekStart) => {
                    if (!weeksList.includes(weekStart)) {
                        weeksList.push(weekStart);
                    }
                });
            let weekListAsDate: FormattedWeek[] = weeksList.map((week) => {
                return { weekAsDate: moment(week), state: 'valid' };
            });

            // Ensure active date is reset (when a new ship to is selected)
            setActiveDate({
                fullDate: '',
                week: '',
                year: ''
            } as DateProperties);

            weekListAsDate.sort((a, b) => a.weekAsDate.valueOf() - b.weekAsDate.valueOf());
            setFormattedWeekList(weekListAsDate);
        }
    }, [atmOrders, cultureCode, selectedShipTo]);

    useEffect(() => {
        if (formattedWeekList && formattedWeekList.length && !activeDate.fullDate.length) {
            setActiveDate(getDateProperties(formattedWeekList[0].weekAsDate));
        }
    }, [activeDate.fullDate.length, formattedWeekList]);

    const footerActions = (
        <Button
            type="button"
            color="primary"
            variant="contained"
            data-testid="dashboard-btn"
            className={classes.actionBtn}
            onClick={handleDashboardNavigation}
        >
            <Trans i18nKey="myDashboard">My Dashboard</Trans>
        </Button>
    );

    return (
        <ProcessingPageTemplate
            banner={{
                header: t('makeIt', 'Make It'),
                description: t(
                    'atmChangesReceived',
                    'Thank you, your changes have been received.'
                ).toLocaleUpperCase(),
                thinBanner: true,
                displayDropdown: false
            }}
            actionFooter={{
                footerAction: footerActions,
                justify: 'center',
                sticky: true
            }}
            activity={Activity.MakeIt}
        >
            {isCIACustomer && <PaymentStatusHeader isBulk={true} />}

            <Grid container item justify="space-between" className={classes.dateSelectSection}>
                <Grid
                    item
                    xs={12}
                    data-testid="share-links"
                    className={classes.shareLinksContainer}
                >
                    <Paper className={classes.subheaderPaper}>
                        <Grid container xs={12}>
                            <Grid xs={7} className={classes.summarySection}>
                                {subheaderItems && (
                                    <MakeItBulkATMConfirmationSubheader
                                        subheaderItems={subheaderItems}
                                        justify="flex-start"
                                    />
                                )}
                            </Grid>
                            <Grid justify="flex-end" xs={5}>
                                {ordersBySnoSku && (
                                    <MakeItBulkATMConfirmationShareLinks
                                        atmOrdersBySnoSku={ordersBySnoSku}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid container item xs={12}>
                    <Grid container item xs={4}>
                        <Grid item>
                            <Typography variant="subtitle2" className={classes.viewAtmTitle}>
                                {t('shipTo', 'Ship To').toUpperCase()}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={8}>
                        <Grid item>
                            <Typography variant="subtitle2" className={classes.viewAtmTitle}>
                                {fromSpot
                                    ? t(
                                          'requestedWeekAndPONumber',
                                          'Requested Week and PO Number'
                                      ).toUpperCase()
                                    : t('requestedWeek', 'Requested Week')}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container xs={12}>
                    <Grid
                        container
                        item
                        xs={4}
                        className={classes.selector}
                        data-testid="ship-to-selector"
                    >
                        <ShipToDropdown
                            name="shipToSelect"
                            testId="shipToSelect"
                            selectObjects={shipToAccountsList}
                            selected={selectedShipTo}
                            updateSelected={setSelectedShipTo}
                            showIcons={false}
                        />
                    </Grid>
                    {formattedWeekList && (
                        <Grid
                            container
                            item
                            xs={4}
                            className={classes.selector}
                            data-testid="dateBar"
                        >
                            <WeekSelectorDropdown
                                testId="weekSelect"
                                weeks={formattedWeekList}
                                selectedDate={activeDate}
                                onDateSelected={setActiveDate}
                                showIcons={false}
                                customerPONumber={CustomerPONum}
                                showCustomerPoNumber={fromSpot ? true : undefined}
                            />
                        </Grid>
                    )}
                </Grid>
                <Grid container xs={12} className={classes.selector}>
                    <Grid container item xs={6}>
                        <Typography
                            variant="h3"
                            className={classes.viewAtmTitle}
                            data-testid="current-selected-date"
                        >
                            {getBallProdOrderAndWeek()}
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        item
                        justify="flex-end"
                        className={classes.expandCollapseWrapper}
                        xs={6}
                    >
                        <Button
                            className={classes.btn}
                            color="primary"
                            onClick={handleExpandAll}
                            data-testid="expand-all"
                        >
                            <Trans i18nKey="expandAll">Expand All</Trans>
                        </Button>
                        <Typography>|</Typography>
                        <Button
                            className={classes.btn}
                            color="primary"
                            onClick={handleCollapseAll}
                            data-testid="collapse-all"
                        >
                            <Trans i18nKey="collapseAll">Collapse All</Trans>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid xs={12}>
                {activeDateOrders &&
                    !orderFailed &&
                    filterOutDeletedOrders(activeDateOrders).map((order) =>
                        order.linesBySnoSku.map((grouping) => {
                            return (
                                <MakeItBulkATMConfirmationOrderSection
                                    order={grouping}
                                    shipToId={order.shipToId}
                                    expand={expandAll}
                                    isActive={grouping.isActive}
                                />
                            );
                        })
                    )}
            </Grid>
        </ProcessingPageTemplate>
    );
}
