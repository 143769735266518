import { Account, ProdOrderType } from '../../store/reducers/customer-context';
import { Region } from '../../store/reducers/graphic-intake';

const atmBannerRouteKeywords = [
    'plan-it',
    'make-it',
    'ship-it',
    'product-planning',
    'bulk',
    'edit-shipments',
    'delivery-order',
    'makeit-conversion-review',
    'manage-products',
    'trade-it',
    'trade-it-summary',
    'scrap-it'
];

export const shouldShowAtmBanner = (
    shipToAccounts: Account[],
    selectedShipToId: string,
    location: string,
    regionCode: string
): boolean => {
    const selectedShipTo = shipToAccounts?.find(
        (account) => account.accountId === selectedShipToId
    );
    const isLargeCustomer =
        selectedShipTo && selectedShipTo.prodOrderType === ProdOrderType.AuthorizationToManufacture;
    const onRouteThatShouldShowAtmBanner = atmBannerRouteKeywords.some((route) => {
        return location.includes(route);
    });

    return isLargeCustomer!! && onRouteThatShouldShowAtmBanner && regionCode !== Region.EU;
};
