import React, { useState } from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import Link from '../../../reusable/atoms/Link';
import { CSVLink } from 'react-csv';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { ballBlue, small, medium, regularWeight } from '../../../../themes/globalConstants';
import Modal from '../../../reusable/molecules/Modal';
import { ScrapItRequestResponse } from '../../../../utility/services/orders-service';
import { getFormattedDateStringForApplication } from '../../../../utility/helpers/date-helpers';
import ScrapItConfirmationPDF from './ScrapItConfirmationPDF'

const useStyles = makeStyles((theme) => ({
    label: {
        textTransform: 'uppercase',
        fontSize: small,
        paddingTop: '0.5em'
    },
    printLink: {
        fontSize: medium,
        fontWeight: regularWeight,
        width: '8.75em',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    link: {
        fontSize: small,
        fontWeight: regularWeight,
        width: '8.75em',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    icon: {
        color: ballBlue
    },
    iconContainer: {
        display: 'flex',
        marginTop: '0.75em'
    }
}));

interface ScrapItConfirmationShareLinkProps {
    scrapItResponse?: ScrapItRequestResponse
    shipToAddress?: string
    shipToName?: string
}

const ScrapItConfirmationShareLinks = (props: ScrapItConfirmationShareLinkProps) => {
    const classes = useStyles();
    const [openPdf, setOpenPdf] = useState(false);
    const { t } = useTranslation();

    const printForm = () => {
        setOpenPdf(true);
    };

    const onClose = () => {
        setOpenPdf(false);
    };

    const fileName = 'scrap-it-request-' + getFormattedDateStringForApplication(props.scrapItResponse?.submittedDate) + '.csv';
    const csvHeaders = [
        {
            label: t('scrapItOrderNumber', 'Scrap It Request Order Number').toUpperCase(),
            key: 'requestNumber'
        },
        {
            label: t('shipTo', 'Ship To').toUpperCase(),
            key: 'shipTo'
        },
        {
            label: t('submittedDate', 'Submitted Date').toUpperCase(),
            key: 'submittedDate'
        },
        {
            label: t('productDescription', 'Product Description').toUpperCase(),
            key: 'productDescription'
        },
        {
            label: t('ballProductId', 'Ball Product ID').toUpperCase(),
            key: 'ballProductId'
        },
        {
            label: t('graphicID', 'Graphic ID').toUpperCase(),
            key: 'graphicId'
        },
        {
            label: t('customerProductId', 'Customer Product ID').toUpperCase(),
            key: 'customerProductId'
        },
        {
            label: t('coating', 'Coating').toUpperCase(),
            key: 'coating'
        },
        {
            label: t('quantityPL', 'Quantity (PL)').toUpperCase(),
            key: 'quantityPl'
        },
        {
            label: t('quantityEA', 'Quantity (EA)').toUpperCase(),
            key: 'quantityEa'
        }
    ];

    interface CSVRow {
        requestNumber?: string;
        shipTo?: string;
        submittedDate?: string;
        productDescription?: string;
        ballProductId?: string;
        graphicId?: string;
        customerProductId?: string;
        coating?: string;
        quantityPl?: string;
        quantityEa?: string;
    }

    const csvArray = () => {
        let rows: any[] = [];
        if (!!props.scrapItResponse) {
            props.scrapItResponse.requestLines.forEach((product) => {
                const row: CSVRow = {
                    requestNumber: props.scrapItResponse?.scrapRequestId.toString(),
                    shipTo: props.scrapItResponse?.accountId.toString(),
                    submittedDate: getFormattedDateStringForApplication(props.scrapItResponse?.submittedDate),
                    productDescription: product.displayName,
                    ballProductId: product.productSku,
                    graphicId: product.graphicIdAndVersion,
                    customerProductId: product.customerProductId,
                    coating: product.coating,
                    quantityPl: Number(product.inputPallets).toLocaleString(),
                    quantityEa: Number(product.inputPallets! * product.quantityPerPallet!).toLocaleString()
                };
                rows.push(row);
            });
        }

        return rows;
    };

    const csvContents = csvArray();

    return (
        <Grid container item xs={12} spacing={2} justify="flex-end">
            <Grid item data-testid="share-btn">
                <Typography variant="h6" className={classes.label}>
                    <Trans i18nKey="share">share</Trans>
                </Typography>
            </Grid>
            <Grid item>
                <Link
                    className={classes.printLink}
                    underline="always"
                    data-testid="print-btn"
                    onClick={printForm}
                >
                    <Trans i18nKey="print">Print</Trans>
                </Link>
                <PrintOutlinedIcon fontSize="small" className={classes.icon} />
            </Grid>
            <Grid className={classes.iconContainer}>
                <CSVLink
                    className={classes.link}
                    underline="always"
                    data-testid="csv-download-btn"
                    headers={csvHeaders}
                    data={csvContents}
                    filename={fileName}
                >
                    <Typography>
                        <Trans i18nKey="downloadCSV">Download .CSV</Trans>
                    </Typography>
                </CSVLink>
                <GetAppOutlinedIcon fontSize="small" className={classes.icon} />
            </Grid>
            <Modal
                open={openPdf}
                title={t('scrapIt', 'Scrap It')}
                close={onClose}
                closeIcon={true}
                fullScreen={true}
                data-testid='print-modal'
            >
                <ScrapItConfirmationPDF
                    shipToAddress={props.shipToAddress!}
                    title={t('scrapIt', 'Scrap It')}
                    shipToName={props.shipToName!}
                    shipToId={props.scrapItResponse!.accountId!.toString()}
                    confirmation={props.scrapItResponse!}
                    data-testid='scrap-pdf'
                />
            </Modal>
        </Grid>
    );
};

export default ScrapItConfirmationShareLinks;
