import moment from 'moment';

export const notificationActionToTranslationKey = (type) => {
    switch (type.toUpperCase()) {
        case 'CAMPAIGN_ENDING':
            return 'createAPlanIt';
        case 'CAMPAIGN_UPCOMING':
            return 'createAPlanIt';
        case 'CAMPAIGN_UPDATE':
            return 'createAPlanIt';
        case 'ALLOCATIONS':
            return 'createAMakeIt';
        default:
            return '';
    }
};

export const notificationActionToPage = (type) => {
    switch (type.toUpperCase()) {
        case 'CAMPAIGN_ENDING':
            return '/plan-it';
        case 'CAMPAIGN_UPCOMING':
            return '/plan-it';
        case 'CAMPAIGN_UPDATE':
            return '/plan-it';
        case 'ALLOCATIONS':
            return '/make-it';
        default:
            return '';
    }
};

// Format content as String[] to be inserted into translation template
export const formatNotificationContent: (type: any, content: any) => string[] = (type, content) => {
    switch (type.toUpperCase()) {
        case 'CAMPAIGN_ENDING':
            return [
                content.ProductDescription,
                moment(content.OrderEndDate).format('M/D/YYYY'),
                moment(content.FirstAvailableDate).format('M/D/YYYY')
            ];
        case 'CAMPAIGN_UPCOMING':
            return [
                content.ProductDescription,
                moment(content.OrderStartDate).format('M/D/YYYY'),
                moment(content.OrderEndDate).format('M/D/YYYY'),
                moment(content.FirstAvailableDate).format('M/D/YYYY')
            ];
        case 'CAMPAIGN_UPDATE':
            return [
                content.ProductDescription,
                moment(content.OrderStartDate).format('M/D/YYYY'),
                moment(content.OrderEndDate).format('M/D/YYYY'),
                moment(content.FirstAvailableDate).format('M/D/YYYY')
            ];
        case 'ALLOCATIONS':
            return [
                Object.keys(content)
                    .map((dateStr) => moment(dateStr).format('MM/DD/YYYY'))
                    .join(', ')
            ];
        case 'NEW_PRODUCT':
            return [content.ProductSku, content.ProductDescription];
        default:
            return Object.values(content);
    }
};
