import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import { ballGray } from '../../../../themes/globalConstants';
import {
    DeliveryOrder,
    LinkedProductionOrders
} from '../../../../store/reducers/shipping-dashboard';
import { medium } from '../../../../themes/globalConstants';
import ShipmentDetails from './ShipmentDetails';
import { getFormattedDateStringForApplication } from '../../../../utility/helpers/date-helpers';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { formatNumberWithLocale } from '../../../../utility/helpers/formatters';

interface Props {
    modeOfTransport: string;
    shipToAddress: string;
    shipToName: string;
    shipToId: string;
    deliveryOrder: DeliveryOrder | undefined;
    linkedProductionOrders?: LinkedProductionOrders;
}

const useStyles = makeStyles((theme) => ({
    main: {
        marginBottom: '1.5em'
    },
    subtitle: {
        color: ballGray
    },
    item: {
        paddingLeft: '1em'
    },
    shipToText: {
        marginLeft: '2em',
        fontSize: medium
    },
    createdBy: {
        marginTop: '1em',
        marginBottom: '.5em'
    },
    longValue: {
        overflowWrap: 'anywhere'
    }
}));

const DeliveryOrderDetailsSubheader = (props: Props) => {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    useTranslation();

    return (
        <Grid container className={classes.main}>
            <Grid container item xs={4} justify="flex-start" data-testid="shipto">
                <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="shipTo">Ship To</Trans>
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography className="classes.shipToText">{props.shipToName}</Typography>
                    <Typography className="classes.shipToText">{props.shipToAddress}</Typography>
                    <Typography className="classes.shipToText">{`#${props.shipToId}`}</Typography>
                </Grid>
            </Grid>
            <Grid container item xs={8} justify="flex-end" data-testid="date-submitted">
                <Grid item xs={2} className={classes.item}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="dateSubmitted">Date Submitted</Trans>
                    </Typography>
                    <Typography variant="body1">
                        {getFormattedDateStringForApplication(props.deliveryOrder?.submittedDate)}
                    </Typography>
                </Grid>
                <Grid item xs={3} className={classes.item} data-testid="can-quantity">
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="canQuantity">Can Quantity</Trans>
                    </Typography>
                    <Typography variant="body1">
                        {props.deliveryOrder && props.deliveryOrder.canQuantityPallets
                            ? formatNumberWithLocale(
                                  cultureCode,
                                  props.deliveryOrder.canQuantityPallets
                              ) +
                              ' PL / ' +
                              props.deliveryOrder?.canQuantitySKUs +
                              ' SKU'
                            : '0 PL / 0 SKU'}
                    </Typography>
                    <Typography
                        variant="body1"
                        data-testid="can-quantity-eaches"
                        className={classes.longValue}
                    >
                        {props.deliveryOrder && props.deliveryOrder.canQuantityEaches
                            ? formatNumberWithLocale(
                                  cultureCode,
                                  props.deliveryOrder.canQuantityEaches
                              ) + ' ea.'
                            : '0 ea.'}
                    </Typography>
                </Grid>
                <Grid item xs={3} className={classes.item} data-testid="end-quantity">
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="endQuantity">End Quantity</Trans>
                    </Typography>
                    <Typography variant="body1">
                        {props.deliveryOrder && props.deliveryOrder.endQuantityPallets
                            ? formatNumberWithLocale(
                                  cultureCode,
                                  props.deliveryOrder.endQuantityPallets
                              ) +
                              ' PL / ' +
                              props.deliveryOrder?.endQuantitySKUs +
                              ' SKU'
                            : '0 PL / 0 SKU'}
                    </Typography>
                    <Typography
                        variant="body1"
                        data-testid="end-quantity-eaches"
                        className={classes.longValue}
                    >
                        {props.deliveryOrder && props.deliveryOrder.endQuantityEaches
                            ? formatNumberWithLocale(
                                  cultureCode,
                                  props.deliveryOrder.endQuantityEaches
                              ) + ' ea.'
                            : '0 ea.'}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.createdBy} data-testid="user">
                <Grid item xs={2}>
                    <Typography variant="subtitle2" className={classes.subtitle}>
                        <Trans i18nKey="createdBy">Created By</Trans>
                    </Typography>
                    <Typography variant="body1">
                        {props.deliveryOrder?.createdBy === 'ETL'
                            ? 'Ball'
                            : props.deliveryOrder?.createdBy ?? props.deliveryOrder?.submittedBy}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item xs={12} justify="flex-start">
                {props.deliveryOrder?.shipments?.map((shipment, index) => (
                    <ShipmentDetails
                        key={shipment.shipmentId}
                        shipment={shipment}
                        index={index}
                        modeOfTransport={props.modeOfTransport}
                        linkedProductionOrders={props.linkedProductionOrders}
                    />
                ))}
            </Grid>
        </Grid>
    );
};

export default DeliveryOrderDetailsSubheader;
