import React, { ReactNode } from 'react';
import { makeStyles, Grid, Typography, Zoom } from '@material-ui/core';
import { black, boldWeight, shipGreen } from '../../../themes/globalConstants';
import { Alert } from '@material-ui/lab';

interface Props {
    successMessage: ReactNode;
    showSuccess: boolean;
    dataTestId?: String;
}

const useStyles = makeStyles((theme) => ({
    alert: {
        width: '95%',
        border: `1px solid ${shipGreen}`,
        borderRadius: '.125em',
        justifyContent: 'center',
        '& .MuiAlert-message': {
            '& .MuiTypography-root': {
                color: black,
                fontWeight: boldWeight
            }
        }
    }
}));

export default function SuccessAlert({ successMessage, showSuccess, dataTestId }: Props) {
    const classes = useStyles();

    return (
        <Zoom in={showSuccess} timeout={{ enter: 500, exit: 700 }}>
            <Grid container item justify="center" xs={12}>
                <Alert severity="success" className={classes.alert} data-testid={dataTestId ?? ''}>
                    <Typography>{successMessage}</Typography>
                </Alert>
            </Grid>
        </Zoom>
    );
}
