import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, CircularProgress, Grid, makeStyles, Paper } from '@material-ui/core';
import { Activity, Persona } from '../../../utility/auth/useSecurity';
import { ProcessingPageTemplate } from '../../templates/ProcessingPageTemplate';
import { boldWeight } from '../../../themes/globalConstants';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { LocationRoleAccessTable } from '../../reusable/molecules/LocationRoleAccessTable/LocationRoleAccessTable';
import { RequestAccessState } from '../../../store/reducers/request-access';
import { useDispatch } from 'react-redux';
import {
    useLocationRoleAccess,
    withLocationRoleAccessProvider
} from '../../reusable/molecules/LocationRoleAccessTable/context/LocationRoleAccessContext';
import { updateLocationRoleAccessAccounts } from '../../reusable/molecules/LocationRoleAccessTable/context/actions';
import ReviewRequestUserInformation from './components/ReviewRequestUserInformation';
import { getUserCustomerAccounts } from '../../../store/actions/customer-context';
import { AuthState, UserPermission } from '../../../store/reducers/auth';
import { CustomerContextState, Status } from '../../../store/reducers/customer-context';
import { UserAdminState } from '../../../store/reducers/user-admin';
import {
    approveUserPermissionChange,
    getPendingRequest,
    loadUserPermissions,
    rejectUserPermissionChange
} from '../../../store/root-actions';
import {
    getTableUserPermissions,
    updateShipToRoleAccess
} from '../../../utility/helpers/user-admin-helpers';
import { ShipToRoleAccess } from '../../reusable/molecules/LocationRoleAccessTable/models/role-access.model';
import { getAdminAccounts } from '../../../utility/helpers/admin-helpers';
import ConfirmationModal from '../../reusable/molecules/ConfirmationModal';
import { enUS } from '../../../utility/translations/locales/en-US/en-US';
import { useHistory } from 'react-router';
import { useParams } from 'react-router';
import { DefaultQueryParams } from '../../../utility/helpers/query-helpers';
import { resetPendingRequest } from '../../../store/actions/user-admin';

const useStyles = makeStyles((theme) => ({
    actionBtn: {
        height: '3.375em',
        width: '13.75em',
        backgroundSize: '200% 100%',
        backgroundPosition: 'right bottom'
    },
    paper: {
        marginBottom: '1em',
        padding: '3em 3em 0 3em'
    },
    titleContainer: {
        marginBottom: '1em'
    },
    title: {
        fontSize: '1.5em',
        fontWeight: boldWeight,
        color: theme.palette.secondary.main
    },
    locationTableWrapper: {
        marginBottom: '1.5em',
        paddingBottom: '1em'
    },
    container: {
        paddingTop: '3em'
    },
    formSection: {
        marginBottom: '2.5em'
    },
    formSectionContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    spinningLoader: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '0.5em'
    },
    reject: {
        marginRight: '1em'
    },
    editBtnContainer: {
        marginTop: 15,
        marginRight: 25
    }
}));

function UserAdminReviewRequest() {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { requestAccounts, loading } = useTypedSelector<RequestAccessState>(
        (state) => state.requestAccess
    );
    const { permissions } = useTypedSelector<AuthState>((state) => state.auth);
    const {
        userToEdit,
        pendingRequest,
        loading: userLoading
    } = useTypedSelector<UserAdminState>((state) => state.userAdmin);
    const { accounts } = useTypedSelector<CustomerContextState>((state) => state.customerContext);
    const [accessAccounts, setAccessAccounts] = useState<Array<ShipToRoleAccess>>([]);
    const [userLoaded, setUserLoaded] = useState<boolean>(false);
    const [readOnly, setReadOnly] = useState<boolean>(true);
    const [inactiveAccount, setInactiveAccount] = useState<boolean>(false);
    const [locationRoleAccessState, locationRoleAccessDispatch] = useLocationRoleAccess();
    const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
    const history = useHistory();
    const userAdminDashboardLink = '/user-admin-dashboard';
    const { id } = useParams<DefaultQueryParams>();
    const [requestedPermissions, setRequestedPermissions] = useState<UserPermission[]>([]);

    useEffect(() => {
        if (permissions && !accounts?.length) {
            dispatch(getUserCustomerAccounts());
        }
    }, [permissions]);

    useEffect(() => {
        if (permissions && accounts && accounts.length && !accessAccounts.length) {
            const accts = getAdminAccounts(permissions, accounts).map(
                (account) => new ShipToRoleAccess(account)
            );
            setAccessAccounts(accts);
        }
    }, [permissions, accounts]);

    useEffect(() => {
        if (id && permissions) {
            dispatch(getPendingRequest(id));
        }
    }, [dispatch, id, permissions]);

    useEffect(() => {
        if (accessAccounts.length && pendingRequest && permissions && accounts) {
            const userPermissions: UserPermission[] = [];
            const requestedAccount = accessAccounts.filter(
                (acct) => acct.accountId === pendingRequest.accountId
            );
            const adminAccounts = getAdminAccounts(permissions, accounts);
            if (requestedAccount.length) {
                pendingRequest.personas.forEach((p) => {
                    userPermissions.push({
                        personaId: p as Persona,
                        accountIds: [pendingRequest.accountId]
                    });
                });
                if (userPermissions.length) {
                    updateLocationRoleAccessAccounts(
                        updateShipToRoleAccess(userPermissions, requestedAccount, adminAccounts),
                        locationRoleAccessDispatch
                    );
                }
            }
        }
    }, [accessAccounts, locationRoleAccessDispatch, pendingRequest, permissions, accounts]);

    useEffect(() => {
        if (locationRoleAccessState.accounts && pendingRequest) {
            const requestedAccount = accessAccounts.filter(
                (acct) => acct.accountId === pendingRequest.accountId
            );
            const permissions = getTableUserPermissions(requestedAccount);
            setRequestedPermissions(permissions);
            if (requestedAccount.length && requestedAccount[0].status === Status.Inactive) {
                setInactiveAccount(true);
            }
        }
    }, [pendingRequest, locationRoleAccessState.accounts, accessAccounts]);

    useEffect(() => {
        if (!userLoaded) {
            dispatch(loadUserPermissions(userToEdit));
            setUserLoaded(true);
        }
    }, [dispatch, userLoaded, userToEdit]);

    useEffect(() => {
        updateLocationRoleAccessAccounts(requestAccounts, locationRoleAccessDispatch);
    }, [requestAccounts, locationRoleAccessDispatch]);

    const onCloseCancelModal = () => {
        setOpenCancelModal(false);
    };
    const onCancelRequest = () => {
        dispatch(resetPendingRequest());
        history.push(userAdminDashboardLink);
    };
    const onOpenCancelModal = () => {
        setOpenCancelModal(true);
    };

    const handleReject = () => {
        if (id) {
            dispatch(rejectUserPermissionChange(id));
            history.push(userAdminDashboardLink);
        }
    };
    const handleApprove = () => {
        if (pendingRequest && requestedPermissions.length) {
            const approvedChanged = {
                ...pendingRequest,
                personas: requestedPermissions.map((req) => req.personaId)
            };
            dispatch(approveUserPermissionChange(approvedChanged));
            history.push(userAdminDashboardLink);
        }
    };

    const editClicked = () => {
        setReadOnly(!readOnly);
    };

    const footerActions = (
        <>
            <Grid container item xs={4}>
                <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    data-testid="bottom-cancel-button"
                    onClick={onOpenCancelModal}
                    className={classes.actionBtn}
                >
                    <Trans i18nKey="cancel">Cancel</Trans>
                </Button>
            </Grid>
            <Grid container item xs={8} justify="flex-end">
                <Grid container item xs={2} className={classes.reject}>
                    <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        data-testid="reject-button"
                        className={classes.actionBtn}
                        onClick={handleReject}
                        disabled={inactiveAccount}
                    >
                        <Trans i18nKey="rejectAction">Reject</Trans>
                    </Button>
                </Grid>
                <Grid container item xs={2} justify="flex-end">
                    <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        data-testid="approve-button"
                        className={classes.actionBtn}
                        onClick={handleApprove}
                        disabled={!requestedPermissions.length || inactiveAccount}
                    >
                        <Trans i18nKey="approve">Approve</Trans>
                    </Button>
                </Grid>
            </Grid>
        </>
    );

    return (
        <>
            <ProcessingPageTemplate
                banner={{
                    header: t('reviewAccessRequest', 'Review Access Request'),
                    description: '',
                    thinBanner: true,
                    displayDropdown: false
                }}
                actionFooter={{
                    footerAction: footerActions,
                    justify: 'space-between',
                    sticky: true
                }}
                activity={Activity.UserAdminReviewRequest}
                loading={userLoading}
            >
                <Grid
                    container
                    justify="space-between"
                    data-testid="request-access-page"
                    className={classes.container}
                >
                    {pendingRequest && (
                        <Grid item xs={12} className={classes.formSection}>
                            <ReviewRequestUserInformation userInformation={pendingRequest} />
                        </Grid>
                    )}
                    {loading && (
                        <Grid container item xs={12} className={classes.spinningLoader}>
                            <CircularProgress />
                        </Grid>
                    )}
                    <Paper elevation={2} className={classes.paper}>
                        <Grid container className={classes.locationTableWrapper}>
                            <Grid container item xs={12} justify="flex-start">
                                <LocationRoleAccessTable readOnly={readOnly} />
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    justify="flex-end"
                                    className={classes.editBtnContainer}
                                >
                                    {readOnly && (
                                        <Button
                                            type="button"
                                            color="primary"
                                            variant="contained"
                                            data-testid="edit-btn"
                                            className={classes.actionBtn}
                                            onClick={editClicked}
                                            disabled={inactiveAccount}
                                        >
                                            <Trans i18nKey="edit">Edit</Trans>
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </ProcessingPageTemplate>
            <ConfirmationModal
                logo={false}
                title={<Trans i18nKey="changesWillNotBeSaved">{enUS.changesWillNotBeSaved}</Trans>}
                subheader={<Trans i18nKey="areYouSureDiscard">{enUS.areYouSureDiscard}</Trans>}
                open={openCancelModal}
                continueText={<Trans i18nKey="yesDiscard">{enUS.yesDiscard}</Trans>}
                cancelText={<Trans i18nKey="no">{enUS.no}</Trans>}
                saveProgress={onCancelRequest}
                onCancel={onCloseCancelModal}
                onClose={onCloseCancelModal}
                data-testid="cancel-confirmation-modal"
            />
        </>
    );
}

export default withLocationRoleAccessProvider(UserAdminReviewRequest);
