import React, { useEffect, useState } from 'react';
import { makeStyles, Grid, Toolbar } from '@material-ui/core';
import { useTypedSelector } from '../../../store/reducers/reducer';
import { useTitle } from '../../../utility/use-title';
import { useDispatch } from 'react-redux';
import { getHeaderContent } from '../../../store/root-actions';
import { Helmet } from 'react-helmet';
import LoadingIndicator from '../atoms/LoadingIndicator';
import MobileLogo from '../atoms/MobileLogo';
import ProfileMenu from './ProfileMenu';
import { Culture, RegionCultureState } from '../../../store/reducers/region-culture';
import { Region } from '../../../store/reducers/graphic-intake';
import { getSpanishCulture } from '../../../utility/helpers/content-helpers';

interface Props {
    title?: string;
    loading?: boolean;
}

const useStyles = makeStyles((theme) => ({
    toolbar: {
        backgroundColor: theme.palette.common.white,
        minHeight: '2.5em',
        color: theme.palette.common.black,
        fontSize: '0.875rem',
        paddingLeft: 0,
        paddingRight: 0
    },
    home: {
        fontSize: '0.875rem',
        color: theme.palette.primary.main,
        fontWeight: 500
    },
    container: {},
    profile: {
        fontSize: 11,
        fontWeight: 'bold',
        letterSpacing: '1px',
        textTransform: 'uppercase',
        color: theme.palette.secondary.main,
        marginTop: 'auto',
        marginBottom: 'auto'
    },
    menuBtn: {},
    menuItem: {
        fontSize: '0.8rem',
        alignContent: 'right',
        justify: 'right',
        alignItems: 'right'
    }
}));

const MobileHeader = ({ title = 'The Source', loading }: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { headerContent, regionCulture } = useTypedSelector((state) => state);
    const [cultureCode, setCultureCode] = useState<string>('es-ES');
    const [walkMeRegionCode, setWalkMeRegionCode] = useState<string>('NCA');
    const { regionCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    useTitle(title);

    useEffect(() => {
        if (regionCulture.cultureCode !== headerContent.cultureCode) {
            dispatch(getHeaderContent());
        }
    }, [dispatch, headerContent.cultureCode, regionCulture.cultureCode]);

    useEffect(() => {
        let code = regionCulture.cultureCode;
        if (regionCulture.cultureCode === Culture.esES) {
            code = getSpanishCulture(regionCulture.regionCode);
        }
        setCultureCode(code);

        const walkMeRegionCode = 
            regionCode === Region.EU ? 'EMEA' : 
            regionCode === Region.SA ? 'SA' :
            'NCA';

        setWalkMeRegionCode(walkMeRegionCode);
    }, [regionCulture.regionCode, regionCulture.cultureCode, regionCode]);
    

    return (
        <>
            <Toolbar className={classes.toolbar}>
                <Grid container className={classes.container} alignItems="center">
                    <Grid item>
                        <MobileLogo />
                    </Grid>
                    <Helmet htmlAttributes={{ lang: cultureCode }} />
                    <Helmet meta={[{ name: 'region', content: walkMeRegionCode }]} />
                    <Grid container item justify="flex-end">
                        <ProfileMenu />
                    </Grid>
                </Grid>
            </Toolbar>
            {loading ? <LoadingIndicator /> : null}
        </>
    );
};

export default MobileHeader;
