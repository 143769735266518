import React, { useEffect, useState } from 'react';
import {
    ballBlue,
    blackWeight,
    boldWeight,
    containerMaxWidth,
    dateOptionInvalidGray,
    small
} from '../../../../themes/globalConstants';
import { FormattedShipToAccount } from '../../../../store/reducers/customer-context';
import { Grid, makeStyles } from '@material-ui/core';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import ShipToAddressRow from './ShipToAddressRow';
import ShipToAddressColumns from './ShipToAddressColumns';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import TranslationService from '../../../../utility/services/translation-service';
import { generateKey } from '../../../../utility/helpers/order-helpers';

interface Props {
    shipToAccounts: FormattedShipToAccount[];
}

const useStyles = makeStyles((theme) => ({
    action: {
        cursor: 'pointer'
    },
    tableWrapper: {
        marginTop: '3em',
        padding: '2em',
        borderRadius: 4,
        maxWidth: containerMaxWidth,
        '& .MuiToolbar-root': {
            padding: 0,
            '& > button': {
                '&:hover': {
                    backgroundColor: 'transparent'
                },
                '&:last-of-type': {
                    display: 'none'
                },
                '&:first-of-type ': {
                    paddingLeft: 0
                }
            },
            '& > h2': {
                fontSize: '1.5em',
                fontWeight: boldWeight,
                color: theme.palette.secondary.main,
                paddingLeft: '0.625em',
                fontFamily: 'Lato',
                minWidth: '12em'
            }
        },
        '& .MuiTableSortLabel-root': {
            fontSize: 12,
            fontWeight: blackWeight,
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            borderRadius: '0.25em',
            paddingLeft: '0.5em',
            width: '99%',
            justifyContent: 'space-between',
            '& .MuiSvgIcon-root': {
                fontSize: 14,
                opacity: '1'
            }
        },
        '& .MuiTableSortLabel-active': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '& .MuiSvgIcon-root': {
                fill: 'white'
            }
        },
        '& .MuiTableHead-root': {
            '& th': {
                borderLeft: 'none',
                '&:nth-child(1)': {
                    width: '30%'
                },
                '&:nth-child(2)': {
                    width: '30%'
                },
                '&:nth-child(3)': {
                    width: '40%',
                    fontSize: small,
                    fontWeight: blackWeight,
                    textTransform: 'uppercase',
                    color: theme.palette.secondary.main,
                    paddingLeft: '1em'
                }
            }
        },
        '& .MuiInputBase-formControl': {
            border: `1px solid ${dateOptionInvalidGray}`,
            borderRadius: '.125em',
            '&:before': {
                display: 'none'
            },
            '&:after': {
                display: 'none'
            },
            '& svg': {
                height: '.875em',
                width: '.875em',
                fill: ballBlue
            },
            '& .MuiIconButton-root': {
                '&:hover': {
                    backgroundColor: 'transparent !important'
                }
            },
            '& .MuiInput-input': {
                paddingLeft: '.25em'
            }
        },
        '& .MuiTable-root': {
            '&:last-child': {
                display: 'none'
            }
        },
        '& .MuiTableCell-root': {
            wordWrap: 'break-word'
        }
    }
}));

const ShipToRow = ({ row }: { row: Partial<FormattedShipToAccount> }) => {
    if (row) {
        return <ShipToAddressRow account={row as FormattedShipToAccount} key={row.accountId} />;
    } else {
        return null;
    }
};

const ShipToAddressGrid = ({ shipToAccounts }: Props) => {
    const classes = useStyles();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const [columns, setColumns] = useState<any>([]);
    const shipTosShown = 100;

    const options = new ToolbarOptions({
        title: TranslationService.getTranslatedText(cultureCode, 'shipTos'),
        advancePagination: false,
        exportButton: false,
        printButton: false,
        searchText: true,
        itemsPerPage: shipTosShown
    });

    useEffect(() => {
        setColumns(ShipToAddressColumns.getShipToColumns(cultureCode));
    }, [cultureCode]);

    return (
        <Grid className={classes.tableWrapper} key={generateKey('ship-to-address-list')}>
            {shipToAccounts && (
                <DataGrid
                    gridName="Ship To Addresses"
                    columns={columns}
                    dataSource={shipToAccounts}
                    rowComponent={ShipToRow}
                    toolbarOptions={options}
                ></DataGrid>
            )}
        </Grid>
    );
};

export default ShipToAddressGrid;
