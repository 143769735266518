import {
    Activity,
    filterShipToIdsByPermission,
    SecurityLevel
} from '../../utility/auth/useSecurity';
import { getAccountAddress } from '../../utility/helpers/address-helpers';
import OrdersService from '../../utility/services/orders-service';
import { Account } from '../reducers/customer-context';
import { MakeItProductsRequest } from '../reducers/makeit-dashboard';
import { TradeRequestDetails } from '../reducers/trade-it-details';
import {
    TRADEIT_REQUEST_DETAIL_LOADED,
    TRADEIT_REQUEST_DETAIL_LOADING,
    TRADEIT_REQUEST_DETAIL_LOADING_ERROR,
    TRADEIT_REQUEST_DETAIL_PRODUCTS_LOADING_ERROR
} from './action-types';

export const loadTradeItRequest = (tradeRequestId: number, shipToIds: string[]) => {
    return async (dispatch, getState) => {
        const state = getState();
        const { accessToken } = state.auth;
        dispatch({ type: TRADEIT_REQUEST_DETAIL_LOADING });

        const requestShipToIds = filterShipToIdsByPermission(
            state.auth,
            [parseInt(shipToIds[0]), parseInt(shipToIds[1])],
            Activity.TradeItDetails,
            SecurityLevel.View
        );

        const makeItProductRequest: MakeItProductsRequest = {
            ShipToIds: requestShipToIds,
            activeOnly: false
        };
        const makeItProductsResponse: any = await OrdersService.getMakeItProducts(
            accessToken,
            makeItProductRequest
        ).catch((error) => {
            dispatch({ type: TRADEIT_REQUEST_DETAIL_PRODUCTS_LOADING_ERROR, error });
            return;
        });

        OrdersService.getTradeRequestDetails(getState(), tradeRequestId)
            .then((response) => {
                const products = makeItProductsResponse?.data?.products;
                products.forEach((product) => {
                    const destination = product.destinations.find(
                        (d) => d.shipToId === parseInt(shipToIds[0])
                    );
                    product.displayId = destination?.customerProductId;
                    product.displayName = destination?.customerProductName ?? product.name;

                    product.graphicIdAndVersion =
                        product.graphicId && product.graphicVersion
                            ? product.graphicId + '-' + product.graphicVersion
                            : product.graphicId
                            ? product.graphicId
                            : undefined;
                });
                const tradeRequestFromApi = response.data;
                const tradeRequest: TradeRequestDetails = {
                    tradeRequestId: tradeRequestFromApi.tradeRequestId,
                    accountFrom: tradeRequestFromApi.accountFrom,
                    accountTo: tradeRequestFromApi.accountTo,
                    requestedAmount: tradeRequestFromApi.requestedAmount,
                    submittedDate: tradeRequestFromApi.submittedDate,
                    accountFromOriginalBalance: tradeRequestFromApi.accountFromOriginalBalance,
                    accountToOriginalBalance: tradeRequestFromApi.accountToOriginalBalance,
                    quantity: tradeRequestFromApi.quantity,
                    fillerContactFrom: tradeRequestFromApi.fillerContactFrom,
                    fillerContactTo: tradeRequestFromApi.fillerContactTo,
                    weekOf: tradeRequestFromApi.weekOf && tradeRequestFromApi.weekOf,
                    accountFromName: tradeRequestFromApi.nameFrom,
                    accountFromAddress: getAccountAddress({
                        address: tradeRequestFromApi.addressFrom
                    } as Account),
                    accountToName: tradeRequestFromApi.nameTo,
                    accountToAddress: getAccountAddress({
                        address: tradeRequestFromApi.addressTo
                    } as Account),
                    tradeLines: tradeRequestFromApi.tradeLines.map((line) => {
                        const product = products.find((p) => p.productSku === line.productSku);
                        return {
                            ...line,
                            displayName: product && product.displayName,
                            displayId: product && product.displayId,
                            graphicIdAndVersion: product && product.graphicIdAndVersion,
                            quantityPerPallet: product && product.quantityPerPallet
                        };
                    })
                };

                dispatch({
                    type: TRADEIT_REQUEST_DETAIL_LOADED,
                    tradeRequest
                });
            })
            .catch((error) => {
                dispatch({
                    type: TRADEIT_REQUEST_DETAIL_LOADING_ERROR,
                    error
                });
            });
    };
};
