import React from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import clsx from 'clsx';
import { makeStyles, Grid, Typography, Box } from '@material-ui/core';
import {
    ballLtGray,
    ballBlue,
    ltBlueGrey,
    ballLtBlue,
    paleGrey
} from '../../../themes/globalConstants';
import { useTranslation, Trans } from 'react-i18next';
import Button from '../atoms/Button';
import DayRangePicker from '../atoms/DayRangePicker';
import { Moment } from 'moment';
interface Props {
    startDate: Moment;
    endDate: Moment;
    onCancel: () => void;
    onOk: () => void;
    firstMonthVisible?: () => Moment;
    isOutsideRange: (day: any) => boolean;
    onDateChange?: (startDate: Moment, endDate: Moment) => void;
    disableSubmit?: boolean;
}

const useStyles = makeStyles((theme) => ({
    legend: {
        height: '4.57em',
        width: '36.57em',
        border: `1px solid ${ltBlueGrey}`,
        borderRadius: 4
    },
    legendText: {
        marginLeft: '2em',
        color: ballBlue
    },
    dateLegendBox: {
        height: 55,
        width: 60,
        borderRadius: 4
    },
    weekText: {
        width: '100%',
        height: '100%',
        margin: '10%',
        marginTop: '25%'
    },
    restrictedBox: {
        backgroundColor: paleGrey,
        marginLeft: 0
    },
    firstAvailableBox: {
        backgroundColor: ballLtBlue
    },
    availableReleaseBox: {
        border: `1px solid ${ballLtGray}`
    },
    keyText: {
        marginLeft: '0.5em'
    },
    btnContainer: {
        paddingRight: '0.7em'
    },
    cancelBtn: {
        width: '6.43em',
        height: '2.86em'
    },
    okBtn: {
        width: '6.43em',
        height: '2.86em'
    }
}));

const DaypickerRangeModal = (props: Props) => {
    const classes = useStyles();
    useTranslation();

    return (
        <div data-testid="day-picker-range">
            <DayRangePicker
                startDate={props.startDate}
                endDate={props.endDate}
                isOutsideRange={props.isOutsideRange}
                firstMonthVisible={props.firstMonthVisible}
                onDateChange={props.onDateChange}
            />
            {/* LEGEND */}
            <Grid container>
                <Grid
                    container
                    item
                    xs={7}
                    alignItems="center"
                    spacing={0}
                    className={classes.legend}
                >
                    <Grid item xs={2}>
                        <Typography variant="subtitle2" className={classes.legendText}>
                            <Trans i18nKey="legend">Legend</Trans>
                        </Typography>
                    </Grid>
                    <Grid container item xs={1}>
                        <Box className={clsx(classes.dateLegendBox, classes.restrictedBox)}>
                            <Typography variant="body1" className={classes.weekText}>
                                <Trans i18nKey="week">Week</Trans>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body1" className={classes.keyText}>
                            <Trans i18nKey="restrictedDate"> Restricted Week </Trans>
                        </Typography>
                    </Grid>
                    <Grid container item xs={1}>
                        <Box className={clsx(classes.dateLegendBox, classes.firstAvailableBox)}>
                            <Typography variant="body1" className={classes.weekText}>
                                <Trans i18nKey="week">Week</Trans>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body1" className={classes.keyText}>
                            <Trans i18nKey="selectedWeek">Selected Week</Trans>
                        </Typography>
                    </Grid>
                    <Grid container item xs={1}>
                        <Box className={clsx(classes.dateLegendBox, classes.availableReleaseBox)}>
                            <Typography variant="body1" className={classes.weekText}>
                                <Trans i18nKey="week">Week</Trans>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body1" className={classes.keyText}>
                            <Trans i18nKey="availableReleaseDate">Available Week</Trans>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    item
                    xs={5}
                    justify="flex-end"
                    alignItems="center"
                    spacing={0}
                    className={classes.btnContainer}
                >
                    <Grid item xs={2}>
                        <Button
                            type="button"
                            variant="outlined"
                            color="secondary"
                            data-testid="cancel-btn"
                            onClick={props.onCancel}
                            className={classes.cancelBtn}
                        >
                            <Trans i18nKey="cancel">Cancel</Trans>
                        </Button>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={2}>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            data-testid="ok-btn"
                            onClick={props.onOk}
                            className={classes.okBtn}
                            disabled={!!props.disableSubmit ? props.disableSubmit : false}
                        >
                            <Trans i18nKey="ok">Ok</Trans>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default DaypickerRangeModal;
