import { HEADER_LABELS_LOAD } from './action-types';
import { AxiosRequestConfig } from 'axios';
import { getAxiosConfig } from '../../utility/helpers/axios-helpers';
import Axios from 'axios';

export const getHeaderContent = () => {
    return (dispatch: any, getState: any) => {
        let axiosConfig: AxiosRequestConfig = getAxiosConfig(getState);
        const cultureCode = getState().regionCulture?.cultureCode || 'en-US';

        Axios.get(`${process.env.REACT_APP_CONTENT_API}/content/header`, axiosConfig)
            .then((response) => {
                dispatch({
                    type: HEADER_LABELS_LOAD,
                    content: response.data,
                    cultureCode
                });
            })
            .catch((error) => {
                console.log('Error: ' + error);
            });
    };
};
