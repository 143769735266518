import React, { useEffect, useState } from 'react';
import { Grid, Typography, makeStyles, Link, Paper } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { boldWeight, medium, ballGray, black_5 } from '../../../../themes/globalConstants';
import { hasPersonas, Persona } from '../../../../utility/auth/useSecurity';
import Image from '../../../reusable/atoms/Image';
import { AuthState, UserPermission } from '../../../../store/reducers/auth';
import { enUS } from '../../../../utility/translations/locales';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { Region } from '../../../../store/reducers/graphic-intake';

interface Props {
    permissions: UserPermission[] | null;
}

const useStyles = makeStyles((theme) => ({
    paper: {
        borderRadius: 4,
        padding: '2em'
    },
    wrapper: {
        color: theme.palette.secondary.main,
        '& svg': {
            width: '1em',
            marginRight: '0.25em'
        }
    },
    header: {
        fontWeight: boldWeight,
        marginBottom: '1em'
    },
    subHeader: {
        color: ballGray,
        fontWeight: boldWeight,
        marginBottom: '.5em',
        textTransform: 'uppercase'
    },
    subHeaderDetails: {
        color: black_5,
        fontWeight: boldWeight,
        marginBottom: '1em'
    },
    detailsWrapper: {
        alignItems: 'flex-start'
    },
    iconTextWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    icon: {
        marginBottom: '0.625em',
        maxHeight: '2.5em'
    },
    image: {
        height: '9em',
        backgroundSize: 'contain'
    },
    iconContainer: {
        marginTop: '1em'
    },
    link: {
        fontSize: medium,
        textDecoration: 'underline'
    },
    linkDivider: {
        fontWeight: boldWeight,
        margin: '0 1em'
    },
    subContainer: {
        paddingRight: '2em'
    }
}));

export default function GraphicsWidget({ permissions }: Props) {
    const classes = useStyles();
    useTranslation();
    const auth = useTypedSelector<AuthState>((state) => state.auth);
    const { regionCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const [showStartNewGraphics, setShowStartNewGraphics] = useState<boolean>(false);
    const [showInProgressGraphics, setShowInProgressGraphics] = useState<boolean>(false);

    const graphicsIcon = `${process.env.PUBLIC_URL}/assets/Graphics_Icon.svg`;
    const graphicsIconText = 'Graphics Icon';
    const [startNewGraphicLink, setStartNewGraphicLink] = useState<string>('/graphics-intake');
    const viewGraphicsLink = '/graphics-summary';

    useEffect(() => {
        if (regionCode === Region.EU) {
            setStartNewGraphicLink('/emea-graphics-intake');
        } else {
            setStartNewGraphicLink('/graphics-intake');
        }
    }, [regionCode]);

    useEffect(() => {
        if (permissions) {
            if (hasPersonas([Persona.AllAccess], auth)) {
                setShowStartNewGraphics(true);
                setShowInProgressGraphics(true);
            } else {
                // set conditional display of sections based on user's personas
                setShowStartNewGraphics(
                    hasPersonas(
                        [Persona.MarketingMgmt, Persona.ProcurementMgmt, Persona.ArtMgmt],
                        auth
                    )
                );

                setShowInProgressGraphics(
                    hasPersonas(
                        [
                            Persona.MarketingMgmt,
                            Persona.PackagingMgmt,
                            Persona.ProcurementMgmt,
                            Persona.ArtMgmt,
                            Persona.ForecastMgmt,
                            Persona.OrderFulfillment,
                            Persona.WarehousingLogistics,
                            Persona.CoPacker
                        ],
                        auth
                    )
                );
            }
        }
    }, [permissions]);

    return (
        <Paper elevation={2} className={classes.paper}>
            <Grid container item alignItems="flex-start">
                <Grid item xs={12} className={classes.iconContainer}>
                    <Image src={graphicsIcon} alt={graphicsIconText} className={classes.icon} />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h3" className={classes.header}>
                        <Trans i18nKey="graphics">Graphics</Trans>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container item>
                        {showStartNewGraphics && (
                            <Grid item xs={4} className={classes.subContainer}>
                                <Typography variant="subtitle1" className={classes.subHeader}>
                                    <Trans i18nKey="submitNewGraphic">
                                        ${enUS.submitNewGraphic}
                                    </Trans>
                                </Typography>
                                <Typography className={classes.subHeaderDetails}>
                                    <Trans i18nKey="startGraphicsProcess">
                                        ${enUS.startGraphicsProcess}
                                    </Trans>
                                </Typography>
                                <Link
                                    to={startNewGraphicLink}
                                    underline="none"
                                    component={RouterLink}
                                    data-testid="start-new-graphic-link"
                                    className={classes.link}
                                >
                                    <Trans i18nKey="newGraphicLink">${enUS.newGraphicLink}</Trans>
                                </Link>
                            </Grid>
                        )}
                        {showInProgressGraphics && (
                            <Grid item xs={4} className={classes.subContainer}>
                                <Typography variant="subtitle1" className={classes.subHeader}>
                                    <Trans i18nKey="inProgressGraphics">
                                        ${enUS.inProgressGraphics}
                                    </Trans>
                                </Typography>
                                <Typography className={classes.subHeaderDetails}>
                                    <Trans i18nKey="checkGraphicsProcess">
                                        ${enUS.checkGraphicsProcess}
                                    </Trans>
                                </Typography>
                                <Link
                                    to={viewGraphicsLink}
                                    underline="none"
                                    component={RouterLink}
                                    data-testid="view-in-progress-graphics-link"
                                    className={classes.link}
                                >
                                    <Trans i18nKey="viewInProgressGraphics">
                                        ${enUS.viewInProgressGraphics}
                                    </Trans>
                                </Link>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
}
