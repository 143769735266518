import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    makeStyles
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { Controller, ErrorMessage, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { red } from '../../../../themes/globalConstants';
import { enUS } from '../../../../utility/translations/locales';
import DatePicker from '../../../reusable/atoms/DatePicker';
import FormSection from '../../../reusable/molecules/FormSection';
import TextInput from '../../../reusable/molecules/TextInput';
import moment from 'moment';
import PhoneInput from '../../../reusable/molecules/PhoneInput';
import clsx from 'clsx';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { OnboardingDashboardState } from '../../../../store/reducers/onboarding-dashboard';
import { isValidEmail } from '../../../../utility/helpers/user-helpers';

const useStyles = makeStyles((theme) => ({
    eSignatureContainer: {
        '& p': {
            marginBottom: '1em'
        },
        '& h4': {
            width: '100%',
            marginBottom: '1em',
            marginTop: '1em'
        }
    },
    errorText: {
        color: red
    },
    certifyLabelContainer: {
        alignItems: 'flex-start'
    },
    certifyCheck: {
        top: '-.3333em'
    }
}));

export default function ESignature() {
    const { t } = useTranslation();
    const { control, errors, setValue, getValues, watch, register } = useFormContext();
    const { salesAndUseTax } = useTypedSelector<OnboardingDashboardState>(
        (state) => state.onboardingDashboardState
    );
    const tomorrow = moment().add(1, 'day').startOf('day');
    const watchCheckbox = watch('acceptTerms');

    useEffect(() => {
        if (salesAndUseTax.salesAndUseTaxForm && salesAndUseTax.salesAndUseTaxForm.eSignature) {
            setValue('acceptTerms', !!salesAndUseTax.salesAndUseTaxForm.eSignature.certification);
        }
    }, [salesAndUseTax.salesAndUseTaxForm]);

    const handleChange = () => {
        setValue('acceptTerms', !watchCheckbox);
    };
    const classes = useStyles();

    const isOutsideRange = (day) => {
        return day.isBefore(tomorrow.startOf('day'));
    };

    // Sets the date value to the form element
    const handleDateChange = (name: string, newDate: moment.Moment | null) => {
        setValue(name, newDate);
    };

    const isRequired = <Trans i18nKey="required">Required</Trans>;

    return (
        <FormSection
            testId={'esignature-section'}
            sectionHeader={
                <Trans i18nKey="eSignatureAnchorText">{enUS.eSignatureAnchorText}</Trans>
            }
        >
            <Grid item xs={12} className={classes.eSignatureContainer}>
                <Controller
                    as={
                        <FormControlLabel
                            className={classes.certifyLabelContainer}
                            control={
                                <Checkbox
                                    className={classes.certifyCheck}
                                    checked={watchCheckbox}
                                    onChange={handleChange}
                                    value={true}
                                    color="primary"
                                    data-testid="checkbox-accept-terms"
                                />
                            }
                            label={t('eSigCertify', enUS.eSigCertify)}
                            inputRef={register}
                        />
                    }
                    name="acceptTerms"
                    rules={{ required: true }}
                    control={control}
                />
                <FormHelperText className={classes.errorText}>
                    {errors.acceptTerms && (
                        <ErrorMessage errors={errors} name="acceptTerms" message={isRequired} />
                    )}
                </FormHelperText>
            </Grid>
            <Grid item xs={3}>
                <TextInput
                    type="text"
                    label={<Trans i18nKey="firstName">{enUS.firstName}</Trans>}
                    name="signatureFirstName"
                    testId="signatureFirstName"
                    control={control}
                    rules={{ required: true }}
                    error={errors.signatureFirstName}
                    errors={errors}
                    inputProps={{
                        maxLength: 50
                    }}
                />
                <FormHelperText className={classes.errorText}>
                    {errors.signatureFirstName && (
                        <ErrorMessage
                            errors={errors}
                            name="signatureFirstName"
                            message={isRequired}
                        />
                    )}
                </FormHelperText>
            </Grid>
            <Grid item xs={3}>
                <TextInput
                    type="text"
                    label={<Trans i18nKey="lastName">{enUS.lastName}</Trans>}
                    name="signatureLastName"
                    testId="signatureLastName"
                    control={control}
                    rules={{ required: true }}
                    error={errors.signatureLastName}
                    errors={errors}
                    inputProps={{
                        maxLength: 50
                    }}
                />
                <FormHelperText className={classes.errorText}>
                    {errors.signatureLastName && (
                        <ErrorMessage
                            errors={errors}
                            name="signatureLastName"
                            message={isRequired}
                        />
                    )}
                </FormHelperText>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth error={!!errors.signatureDate} data-testid="signatureDate">
                    <Controller
                        as={
                            <DatePicker
                                keepOpen={false}
                                isOutsideRange={isOutsideRange}
                                numberOfMonths={1}
                                placeholder={t('date', enUS.date)}
                                onDateChange={handleDateChange}
                                name="signatureDate"
                                isEditDraft={false}
                                date={getValues('signatureDate')}
                            />
                        }
                        control={control}
                        name="signatureDate"
                        rules={{ required: isRequired }}
                    />
                    {errors.signatureDate && (
                        <FormHelperText>
                            <ErrorMessage errors={errors} name={'signatureDate'} />
                        </FormHelperText>
                    )}
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <TextInput
                    type="text"
                    label={<Trans i18nKey="title">{enUS.title}</Trans>}
                    name="signatureTitle"
                    testId="signatureTitle"
                    control={control}
                    rules={{ required: true }}
                    error={errors.signatureTitle}
                    errors={errors}
                    inputProps={{
                        maxLength: 50
                    }}
                />
                <FormHelperText className={classes.errorText}>
                    {errors.signatureTitle && (
                        <ErrorMessage errors={errors} name="signatureTitle" message={isRequired} />
                    )}
                </FormHelperText>
            </Grid>
            <Grid item xs={6}>
                <TextInput
                    type="text"
                    label={<Trans i18nKey="email">{enUS.email}</Trans>}
                    name="signatureEmail"
                    testId="signatureEmail"
                    control={control}
                    rules={{
                        required: isRequired,
                        validate: {
                            isValidEmail: (value) => {
                                return (
                                    isValidEmail(value) || (
                                        <Trans i18nKey="incorrectEmailAddress">
                                            Invalid Email Format
                                        </Trans>
                                    )
                                );
                            }
                        }
                    }}
                    error={errors.signatureEmail}
                    errors={errors}
                    inputProps={{
                        maxLength: 255
                    }}
                />
                <FormHelperText className={classes.errorText}>
                    {errors.signatureEmail && (
                        <ErrorMessage errors={errors} name={'signatureEmail'} />
                    )}
                </FormHelperText>
            </Grid>
            <Grid item xs={6}>
                <Controller
                    as={
                        <PhoneInput
                            label={<Trans i18nKey="phoneNumber">{enUS.phoneNumber}</Trans>}
                            testId="signaturePhoneNumber"
                            disableAreaCodes={true}
                            defaultCountry={'us'}
                            error={!!errors.signaturePhoneNumber}
                            defaultValue={getValues('signaturePhoneNumber')}
                        />
                    }
                    rules={{
                        required: isRequired,
                        pattern: /^\+([0-9][ \-()]{0,2}){9,14}[0-9]$/
                    }}
                    name="signaturePhoneNumber"
                    control={control}
                    errors={errors}
                    styles={clsx({
                        [classes.errorText]: errors.signaturePhoneNumber
                    })}
                    defaultValue=""
                />
                <FormHelperText className={classes.errorText}>
                    {errors.signaturePhoneNumber &&
                        errors.signaturePhoneNumber.type === 'required' && (
                            <ErrorMessage
                                errors={errors}
                                name={'signaturePhoneNumber'}
                                message={isRequired}
                            />
                        )}
                    {errors.signaturePhoneNumber &&
                        errors.signaturePhoneNumber.type === 'pattern' && (
                            <ErrorMessage
                                errors={errors}
                                name={'signaturePhoneNumber'}
                                message={t('invalidPhoneNumber', 'Invalid Phone Number')}
                            />
                        )}
                </FormHelperText>
            </Grid>
        </FormSection>
    );
}
