import React, { useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { viewTypesCampaignOnly, getViewLabel } from '../../../../utility/helpers/filter-helpers';
import ViewFilterToolbar from '../../../reusable/molecules/ViewFilterToolbar';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { ProductInfoState } from '../../../../store/reducers/product-information';

interface Props {
    onViewFiltersClicked: (viewType: string[]) => void;
}

const useStyles = makeStyles((theme) => ({
    toolbarWrapper: {
        padding: '1.125em 0',
        alignItems: 'center',
        '& .MuiGrid-container': {
            margin: 0
        }
    },
    filtersWrapper: {
        display: 'flex',
        alignItems: 'baseline'
    }
}));

export default function ProductInformationToolbar({ onViewFiltersClicked }: Props) {
    const classes = useStyles();
    const { view } = useTypedSelector<ProductInfoState>((state) => state.productInformation);
    const [viewLabel, setViewLabel] = useState<string>('');

    useEffect(() => {
        setViewLabel(getViewLabel(view, viewTypesCampaignOnly));
    }, [view]);

    useTranslation();

    return (
        <Grid container className={classes.toolbarWrapper} justify="space-between">
            <Grid item xs={12} className={classes.filtersWrapper}>
                <ViewFilterToolbar
                    viewTypes={viewTypesCampaignOnly}
                    viewChange={(newView) => onViewFiltersClicked(newView)}
                    testId={'product-info-views'}
                    value={viewLabel}
                />
            </Grid>
        </Grid>
    );
}
