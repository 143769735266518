import {
    LOCATION_MGMT_SHIPTO_ADD_ERROR,
    LOCATION_MGMT_SHIPTO_ADD_RESET,
    LOCATION_MGMT_SHIPTO_ADD_REVIEW,
    LOCATION_MGMT_SHIPTO_ADD_SUBMIT,
    LOCATION_MGMT_SHIPTO_ADD_SUBMITTING
} from '../actions/action-types';

export interface ShipToLocationItem {
    itemID: string;
    itemName: string;
}
export interface ShipToLocation {
    requestID?: number;
    billToID: string;
    newShipToName: string;
    customerType: string;
    newShipToAddress1: string;
    newShipToAddress2?: string;
    newShipToAddress3?: string;
    newShipToCity: string;
    newShipToStateProvince: string;
    newShipToPostalCode: string;
    newShipToCountry: string;
    annualVolume: string;
    userAdminName: string;
    userAdminPhone: string;
    userAdminEmail: string;
    vatid?: string;
    cfid?: string;
    taxID?: string;
    productSelection: string;
    specialtyPrinting?: string;
    specialtyTab?: string;
    canSizeShapeNeck?: string;
    endDiaProfile?: string;
    canFreight: string;
    endFreight: string;
    specialEquipment?: string;
    layersPerPallet: string;
    laserTab: boolean;
    punch: boolean;
    coloredTab: boolean;
    coloredShell: boolean;
    basecoat: boolean;
    retort: boolean;
    tactile: boolean;
    satin: boolean;
    items: ShipToLocationItem[];
    submittedDate: string;
}

export interface ShipToLocationState {
    newShipToLocation?: ShipToLocation;
    submitNewShipToLocationSubmissionState: 'idle' | 'loading' | 'error' | 'success';
    error?: any;
}

const initialState: ShipToLocationState = {
    newShipToLocation: undefined,
    submitNewShipToLocationSubmissionState: 'idle',
    error: undefined
};

const shipToLocations = (state: ShipToLocationState = initialState, action: any) => {
    switch (action.type) {
        case LOCATION_MGMT_SHIPTO_ADD_REVIEW:
            return {
                ...state,
                newShipToLocation: action.newShipToLocation
            };
        case LOCATION_MGMT_SHIPTO_ADD_RESET:
            return {
                ...initialState
            };
        case LOCATION_MGMT_SHIPTO_ADD_SUBMITTING:
            return {
                ...state,
                submitNewShipToLocationSubmissionState: 'loading'
            };
        case LOCATION_MGMT_SHIPTO_ADD_SUBMIT:
            return {
                ...state,
                submitNewShipToLocationSubmissionState: 'success',
                newShipToLocation: action.newShipToLocation
            };
        case LOCATION_MGMT_SHIPTO_ADD_ERROR:
            return {
                ...state,
                submitNewShipToLocationSubmissionState: 'error',
                error: action.error
            };
        default:
            return state;
    }
};

export default shipToLocations;
