import moment from 'moment';
import { DateProperties } from '../../utility/helpers/make-it-bulk-helpers';
import AllocationService from '../../utility/services/allocation-service';
import OrdersService, {
    Order_Status,
    UpdateProductionOrderRequest
} from '../../utility/services/orders-service';
import {
    AllocationRequest,
    ForecastStatus,
    MakeItBulkATMLineItem,
    MakeItBulkATMOrder,
    MakeItBulkATMOrderByWeekAndSku
} from '../reducers/makeit-bulk-atm';
import { MakeItBulkLineItem } from '../reducers/makeit-bulk-upload';
import {
    MAKEIT_CONVERSION_ORDER_ADD_PRODUCT,
    MAKEIT_CONVERSION_ORDER_COPY_FORECAST,
    MAKEIT_CONVERSION_ORDER_DELETE_PRODUCT,
    MAKEIT_CONVERSION_ORDER_LOADED,
    MAKEIT_CONVERSION_ORDER_LOADING,
    MAKEIT_CONVERSION_ORDER_LOADING_ERROR,
    MAKEIT_CONVERSION_ORDER_LOAD_ALLOCATIONS,
    MAKEIT_CONVERSION_ORDER_LOAD_ALLOCATIONS_ERROR,
    MAKEIT_CONVERSION_ORDER_LOAD_ALLOCATIONS_LOADING,
    MAKEIT_CONVERSION_ORDER_PRODUCTS_ERROR,
    MAKEIT_CONVERSION_ORDER_PRODUCTS_LOADED,
    MAKEIT_CONVERSION_ORDER_PRODUCTS_LOADING,
    MAKEIT_CONVERSION_ORDER_UPDATE_PALLET_QUANTITY,
    MAKEIT_CONVERSION_RESET_STATE,
    MAKEIT_CONVERSION_SUBMIT_SUCCESS,
    MAKEIT_CONVERSION_SUBMIT_LOADING,
    MAKEIT_CONVERSION_SUBMIT_ERROR,
    MAKEIT_BULK_ATM_SUBMIT_ORDERS_SUCCESS,
    MAKEIT_CONVERSION_ORDER_EXISTING_ORDERS
} from './action-types';
import { bulkUploadResponseHelper } from './atm-production-order-details';
import { filterAllocations } from './makeit-bulk-atm';
import { getProductInformation } from './makeit-bulk-upload';

export const loadForecastForOrderConversion = (atmProductionOrderIds: number[]) => {
    return (dispatch, getState) => {
        const state = getState();
        const products = state.bulkUploadMakeItState.products as MakeItBulkLineItem[];

        dispatch({ type: MAKEIT_CONVERSION_ORDER_LOADING });
        const requests = atmProductionOrderIds.map((id) =>
            OrdersService.getATMProductionOrder(state, id)
        );
        Promise.all(requests)
            .then((atmProductionOrders) => {
                let orders: MakeItBulkATMOrder[] = [];
                atmProductionOrders.forEach((atmProductionOrder) => {
                    const order = atmProductionOrder.data;
                    if (order && (order.atmWeekStart || order.lines?.length)) {
                        order.lines = order.lines?.filter(
                            (line) => line.customerProductStatus !== 'INACTIVE'
                        );
                        order.lines?.forEach((line) => {
                            line.originalPalletQuantity =
                                line.status === Order_Status.Cancelled ? 0 : line.palletQuantity;
                            line.palletQuantity =
                                line.status === Order_Status.Cancelled ? 0 : line.palletQuantity;
                            line.status = undefined;
                            line.isCustomerProductIdDistinct = true;
                            line.displayId = line.customerProductId
                                ? line.customerProductId
                                : line.productSku;
                            line.displayName = line.customerProductName
                                ? line.customerProductName
                                : line.productName;
                        });
                        // Forcing these only because of above check.
                        const weekStart: string = order.atmWeekStart
                            ? order.atmWeekStart
                            : order.lines![0].weekStart!;
                        const atmOrder = { ...order, weekStart: weekStart } as MakeItBulkATMOrder;

                        bulkUploadResponseHelper(atmOrder, products);
                        orders.push(atmOrder);
                    }
                });
                dispatch({
                    type: MAKEIT_CONVERSION_ORDER_LOADED,
                    atmOrders: orders
                });
            })
            .then(() => {
                const atmOrders = getState().makeItConversionATMState.atmOrders;
                if (atmOrders) {
                    const startWeek = moment
                        .min(atmOrders.map((o) => moment(o.atmWeekStart)))
                        .format('M/D/YYYY');
                    const endWeek = moment
                        .max(atmOrders.map((o) => moment(o.atmWeekStart)))
                        .format('M/D/YYYY');
                    const shipToId = atmOrders[0].shipToId;
                    return OrdersService.getExistingOrders(
                        state,
                        startWeek,
                        endWeek,
                        [shipToId],
                        ForecastStatus.Forecast,
                        true
                    );
                }
            })
            .then((resp) => {
                if (resp) {
                    const existingOrders = JSON.parse(JSON.stringify(resp));
                    existingOrders.forEach((order) => {
                        order.weekStart = order.atmWeekStart;
                        order.lines = order.lines.map((line) => {
                            const matchingProduct = products.find(
                                (prod) => prod.productSku === line.productSku!
                            );
                            return {
                                ...line,
                                weekStart: order.atmWeekStart,
                                snoSku: matchingProduct?.snoSku
                            };
                        });
                    });

                    dispatch({
                        type: MAKEIT_CONVERSION_ORDER_EXISTING_ORDERS,
                        existingOrders: existingOrders.filter(
                            (order) => order.status !== Order_Status.Draft
                        ),
                        existingDrafts: existingOrders.filter(
                            (order) => order.status === Order_Status.Draft
                        )
                    });
                }
            })
            .catch((error) => {
                dispatch({
                    type: MAKEIT_CONVERSION_ORDER_LOADING_ERROR,
                    error
                });
            });
    };
};

export const loadAllocationsForOrderConversion = (
    atmWeekStart: string,
    atmWeekEnd: string,
    shipToId: number
) => {
    return (dispatch, getState) => {
        dispatch({ type: MAKEIT_CONVERSION_ORDER_LOAD_ALLOCATIONS_LOADING });
        const state = getState();
        const { regionCode } = state.regionCulture;
        const products = state.makeItConversionATMState.products as MakeItBulkLineItem[];

        //Create request object
        const allocationRequest = {
            shipToIds: [shipToId],
            startDate: [atmWeekStart],
            endDate: [atmWeekEnd]
        } as AllocationRequest;

        AllocationService.getAllocations(state, regionCode, allocationRequest)
            .then((response) => {
                const allocations = filterAllocations(response.data.allocations, products);
                dispatch({
                    type: MAKEIT_CONVERSION_ORDER_LOAD_ALLOCATIONS,
                    allocations
                });
            })
            .catch((error) => {
                dispatch({
                    type: MAKEIT_CONVERSION_ORDER_LOAD_ALLOCATIONS_ERROR,
                    error
                });
            });
    };
};

export const resetMakeItConversionATMState = () => {
    return (dispatch) => {
        dispatch({ type: MAKEIT_CONVERSION_RESET_STATE });
    };
};

export const loadProductsForOrderConversion = (shipToId: number, loadLeadTimes: boolean = true) => {
    return (dispatch, getState) => {
        dispatch({ type: MAKEIT_CONVERSION_ORDER_PRODUCTS_LOADING });
        getProductInformation(getState, [shipToId.toString()], loadLeadTimes).then((response) => {
            if (response && response.type === 'success') {
                //Save product information to state
                dispatch({
                    type: MAKEIT_CONVERSION_ORDER_PRODUCTS_LOADED,
                    products: response.products
                });
            } else if (response && response.type === 'error') {
                // Save error to state
                dispatch({
                    type: MAKEIT_CONVERSION_ORDER_PRODUCTS_ERROR,
                    error: response.error
                });
            }
        });
    };
};

export const updatePalletQuantity = (
    activeDate: DateProperties,
    shipToId: number,
    quantity: number,
    updatedLine: MakeItBulkATMLineItem
) => {
    return (dispatch, getState) => {
        const state = getState();
        const atmOrders = state.makeItConversionATMState.atmOrders as MakeItBulkATMOrder[];
        const existingOrders = state.makeItConversionATMState
            .existingOrders as MakeItBulkATMOrder[];
        if (atmOrders) {
            const atmOrdersToUpdate = atmOrders.map((order) => {
                if (
                    !(
                        moment(order.weekStart)
                            .startOf('day')
                            .isSame(moment(activeDate.fullDate).startOf('day')) &&
                        order.shipToId === shipToId
                    )
                ) {
                    return order;
                }

                //If existingOrders, isolate existing order
                const existingOrderToUpdate = existingOrders.length
                    ? existingOrders.find(
                          (existingOrder) =>
                              moment(existingOrder.atmWeekStart)
                                  .startOf('day')
                                  .isSame(moment(activeDate.fullDate).startOf('day')) &&
                              existingOrder.shipToId === shipToId
                      )
                    : undefined;

                const updatedLines = order.lines.map((line) => {
                    if (
                        line.originalCsvLineNumber &&
                        line.originalCsvLineNumber !== updatedLine.originalCsvLineNumber
                    ) {
                        return line;
                    } else if (
                        !line.originalCsvLineNumber &&
                        (line.displayId !== updatedLine.displayId ||
                            line.productSku !== updatedLine.productSku)
                    ) {
                        return line;
                    } else {
                        if (existingOrderToUpdate) {
                            //If the line being updated matches, we want to preserve any updated quantities
                            const lineToUpdate = existingOrderToUpdate.lines.find(
                                (existingLine) => existingLine.productSku === line.productSku
                            );
                            if (lineToUpdate) {
                                lineToUpdate.palletQuantity = quantity >= 0 ? quantity : undefined;
                            }
                        }
                        line.palletsRounded = false;
                        return {
                            ...line,
                            palletQuantity: quantity >= 0 ? quantity : undefined,
                            minimumMet: updatedLine.minimumMet,
                            withinRange: updatedLine.withinRange
                        };
                    }
                });
                return { ...order, lines: updatedLines };
            });
            dispatch({
                type: MAKEIT_CONVERSION_ORDER_UPDATE_PALLET_QUANTITY,
                atmOrders: atmOrdersToUpdate
            });
        }
    };
};

export function deleteProductFromOrder(shipToId: number, weekStart: string, productSku: string) {
    return (dispatch, getState) => {
        const state = getState();
        const atmOrders = state.makeItConversionATMState.atmOrders as MakeItBulkATMOrder[];

        if (atmOrders) {
            const updatedAtmOrders = atmOrders.map((order) => {
                if (
                    order.shipToId === shipToId &&
                    moment(order.weekStart).format('MM/DD/YYYY') ===
                        moment(weekStart).format('MM/DD/YYYY')
                ) {
                    const updatedLines = order.lines.filter(
                        (line) => line.productSku !== productSku
                    );
                    return { ...order, lines: updatedLines };
                } else {
                    return order;
                }
            });
            dispatch({
                type: MAKEIT_CONVERSION_ORDER_DELETE_PRODUCT,
                atmOrders: updatedAtmOrders
            });
        }
    };
}

export function copyFromForecast(productionOrderIds: number[], shipToId: number) {
    return (dispatch) => {
        dispatch({
            type: MAKEIT_CONVERSION_ORDER_COPY_FORECAST,
            copyFromProductionOrderIds: productionOrderIds,
            copyFromShipToId: shipToId
        });
    };
}

export function addProductToOrder(activeDate: DateProperties, item: MakeItBulkLineItem) {
    return (dispatch, getState) => {
        const state = getState();
        const products = state.makeItConversionATMState.products as MakeItBulkLineItem[];
        const atmOrders = state.makeItConversionATMState.atmOrders as MakeItBulkATMOrder[];
        const requestedDate = moment(activeDate.fullDate).format('YYYY-MM-DDTHH:mm:ss');
        const atmOrderIndex = atmOrders.findIndex((o) => o.atmWeekStart === requestedDate);
        if (atmOrderIndex !== -1 && products) {
            let updatedLines = atmOrders[atmOrderIndex].lines.filter((line) => {
                return line && line.displayId !== item.displayId;
            });
            const addItem = products.find((product) => product.productId === item.productId);
            const newLine = {
                ...(addItem as MakeItBulkATMLineItem),
                isCustomerProductIdDistinct: true,
                originalPalletQuantity: 0,
                requestedDate: requestedDate
            };
            updatedLines.push(newLine);

            atmOrders[atmOrderIndex] = { ...atmOrders[atmOrderIndex], lines: updatedLines };

            dispatch({
                type: MAKEIT_CONVERSION_ORDER_ADD_PRODUCT,
                atmOrders: Object.assign([], atmOrders)
            });
        }
    };
}

export function submitMakeItConversion(activeDateOrders: MakeItBulkATMOrderByWeekAndSku[]) {
    return (dispatch, getState) => {
        const state = getState();
        const accessToken = state.auth.accessToken;
        const userName = state.auth.userInfo.preferred_username;
        const region = state.regionCulture.regionCode;
        const atmOrders = state.makeItConversionATMState.atmOrders as MakeItBulkATMOrder[];
        const payloads = activeDateOrders.map((o) =>
            constructRequestPayload([o], userName, region)
        );

        dispatch({ type: MAKEIT_CONVERSION_SUBMIT_LOADING });

        OrdersService.submitMakeItConversion(payloads, accessToken, atmOrders[0].productionOrderId!)
            .then((response) => {
                let updatedAtmOrders: MakeItBulkATMOrder[] = [];
                if (response.data?.orders?.length) {
                    atmBulkUploadResponseHelper(response.data?.orders);
                    atmOrders.forEach((atmOrder) => {
                        const orderFromApi = response.data.orders.find(
                            (apiOrder) =>
                                apiOrder.shipToId === atmOrder.shipToId &&
                                moment(apiOrder.atmWeekStart)
                                    .startOf('day')
                                    .isSame(moment(atmOrder.weekStart).startOf('day'))
                        );
                        const updatedAtmOrder: MakeItBulkATMOrder = {
                            ...atmOrder,
                            canQuantityPallets: orderFromApi?.canQuantityPallets
                                ? orderFromApi.canQuantityPallets
                                : 0,
                            canQuantitySKUs: orderFromApi?.canQuantitySKUs
                                ? orderFromApi.canQuantitySKUs
                                : 0,
                            canQuantityEaches: orderFromApi?.canQuantityEaches
                                ? orderFromApi.canQuantityEaches
                                : 0,
                            endQuantityPallets: orderFromApi?.endQuantityPallets
                                ? orderFromApi.endQuantityPallets
                                : 0,
                            endQuantitySKUs: orderFromApi?.endQuantitySKUs
                                ? orderFromApi.endQuantitySKUs
                                : 0,
                            endQuantityEaches: orderFromApi?.endQuantityEaches
                                ? orderFromApi.endQuantityEaches
                                : 0
                        };
                        updatedAtmOrders.push(updatedAtmOrder);
                    });
                } else {
                    atmOrders.forEach((atmOrder) => {
                        const updatedAtmOrder: MakeItBulkATMOrder = {
                            ...atmOrder,
                            canQuantityPallets: 0,
                            canQuantitySKUs: 0,
                            canQuantityEaches: 0,
                            endQuantityPallets: 0,
                            endQuantitySKUs: 0,
                            endQuantityEaches: 0
                        };
                        updatedAtmOrders.push(updatedAtmOrder);
                    });
                }
                dispatch({
                    type: MAKEIT_BULK_ATM_SUBMIT_ORDERS_SUCCESS,
                    atmOrders: updatedAtmOrders
                });
                dispatch({ type: MAKEIT_CONVERSION_SUBMIT_SUCCESS });
            })
            .catch((error) => {
                dispatch({ type: MAKEIT_CONVERSION_SUBMIT_ERROR, error: 'submit' });
            });
    };
}

const constructRequestPayload = (
    activeDateOrders: MakeItBulkATMOrderByWeekAndSku[],
    userName: string,
    region: string
): UpdateProductionOrderRequest => {
    const request: UpdateProductionOrderRequest = {
        region: region,
        createdBy: userName,
        submittedBy: userName,
        orderStatus: Order_Status.Submit,
        shipToId: activeDateOrders[0]?.shipToId,
        lines: []
    };
    activeDateOrders.forEach((atmOrder) => {
        atmOrder.linesBySnoSku.forEach((snoSkuLine) => {
            const linesByWeekAndShipTo = snoSkuLine.lines
                .filter((line) => !line.deleted && line.palletQuantity && line.palletQuantity > 0)
                .map((line) => ({ ...line, shipToId: atmOrder.shipToId }));

            linesByWeekAndShipTo.forEach((line) => {
                const { productSku, palletQuantity, shipToId, requestedDate } = line;
                request.lines?.push({
                    productSku,
                    palletQuantity,
                    shipToId,
                    requestedDate
                });
            });
        });
    });
    return request;
};

const atmBulkUploadResponseHelper = (orders: any) => {
    orders.forEach((order) => {
        order.weekStart = order.atmWeekStart;
    });
};
