import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Trans, useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid, makeStyles, Typography, TableRow, TableCell } from '@material-ui/core';
import {
    medium,
    lightBlue,
    inactiveTabBlue,
    boldWeight,
    small,
    blackWeight,
    regularWeight,
    successGreen,
    warningLabel
} from '../../../../themes/globalConstants';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import {
    OrderPlanningProduct,
    PendingPaymentPalletInfo,
    PlanningType,
    TotalNDayRanges
} from '../../../../store/reducers/product-planning';
import { ToggleAll } from '../../../../utility/helpers/order-helpers';
import { AllocationProduct } from '../models/AllocationForTable';
import Link from '../../../reusable/atoms/Link';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { formatNumberWithLocale } from '../../../../utility/helpers/formatters';
import { wrapEachesForTableCell } from '../../../../utility/helpers/shipment-helpers';
import { CustomerContextState } from '../../../../store/reducers/customer-context';
import { usePrevious } from '../../../../utility/helpers/react-util';
import { FlagTypes, GetFeatureFlag } from '../../../../utility/helpers/feature-flag';
import {
    selectIsCIACustomer,
    selectIsLargeCustomerAccount,
    selectStateByName
} from '../../../../store/selectors';
import { getPaymentStatus } from '../../../../utility/helpers/pending-payment-helpers';
import { StateName } from '../../../../utility/helpers/state-helpers';
import CampaignPill from '../../../reusable/atoms/CampaignPill';

interface Props {
    product: AllocationProduct | OrderPlanningProduct;
    expand: ToggleAll;
    onWeeklyLookup: (
        orderAllocation: AllocationProduct | OrderPlanningProduct,
        weekNumber: number,
        quantityType: PlanningType,
        quantity: number
    ) => void;
    stateName: StateName;
}

const useStyles = makeStyles((theme) => ({
    expand: {
        transform: 'rotate(-90deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: 'rotate(0)'
    },
    expandContainer: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto'
    },
    standardRow: {
        '& > td:nth-child(n+2)': {
            textAlign: 'right'
        }
    },
    altRow: {
        backgroundColor: lightBlue,
        '& > td': {
            borderBottomColor: 'white'
        },
        '& > td:nth-child(n+2)': {
            textAlign: 'right'
        }
    },
    hidden: {
        display: 'none'
    },
    display: {
        display: 'table-row'
    },
    borderlessCell: {
        borderLeft: 'none'
    },
    activeHeaderCell: {
        backgroundColor: inactiveTabBlue
    },
    activeRow: {
        boxShadow:
            '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 4px 3px 0px rgba(0,0,0,0.12)'
    },
    standardCell: {
        textAlign: 'left',
        position: 'relative'
    },
    snoSkuDescription: {
        fontWeight: blackWeight,
        fontSize: medium
    },
    snoSkuAttributes: {
        fontWeight: boldWeight,
        fontSize: small
    },
    weekTotal: {
        fontWeight: blackWeight,
        fontSize: medium
    },
    weekAllocation: {
        fontWeight: blackWeight,
        fontSize: small
    },
    linkWeekTotal: {
        fontWeight: boldWeight,
        fontSize: medium,
        textAlign: 'right'
    },
    productDescriptionHeading: {
        fontSize: small,
        fontWeight: blackWeight,
        textTransform: 'uppercase',
        color: theme.palette.secondary.main
    },
    productDescription: {
        fontWeight: blackWeight,
        fontSize: medium,
        marginTop: '0.75em'
    },
    productDetails: {
        marginLeft: '2em',
        fontWeight: regularWeight,
        fontSize: medium
    },
    icon: {
        width: '1.5em',
        height: '1.5em',
        position: 'absolute',
        left: '1em',
        top: '1em',
        '& + p': {
            paddingTop: '2em'
        }
    },
    clockIcon: {
        width: '5%',
        height: '5%',
        alignSelf: 'center'
    },
    noProductDescription: {
        marginTop: '1%'
    },
    agedProductIdText: {
        marginLeft: '5%'
    },
    completedIcon: {
        fill: successGreen
    },
    availableBalance: {
        fontSize: medium,
        fontWeight: boldWeight
    },
    textAlignRight: {
        textAlign: 'right'
    },
    regularTotal: {
        fontSize: medium,
        fontWeight: regularWeight
    },
    boldTotal: {
        fontSize: medium,
        fontWeight: boldWeight
    },
    warningBackground: {
        backgroundColor: warningLabel
    }
}));

export default function ProductPlanningATMRow({
    product,
    expand,
    onWeeklyLookup,
    stateName
}: Props) {
    const classes = useStyles();
    const [active, setActive] = useState<boolean>(product.isActive ?? false);
    const [weekRange, setWeekRange] = useState<number>(TotalNDayRanges.nineWeeks);
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);
    const { range, quantityUnit } = useTypedSelector((state) =>
        selectStateByName(state, stateName)
    );
    const isCiaAccount = useTypedSelector<boolean>(selectIsCIACustomer);
    const isLargeCustomer = useTypedSelector<boolean>(selectIsLargeCustomerAccount);
    const { selectedAccountId } = useTypedSelector<CustomerContextState>(
        (state) => state.customerContext
    );
    const prevExpand = usePrevious(expand.toggle);

    const longRangeStart = 9;
    const longRangeEnd = 17;

    const errorIcon = {
        src: process.env.PUBLIC_URL + '/assets/Error_Icon.svg',
        altText: 'Error Icon'
    };
    const warningIcon = {
        src: process.env.PUBLIC_URL + '/assets/Warning_Icon.svg',
        altText: 'Warning Icon'
    };
    const infoIcon = {
        src: process.env.PUBLIC_URL + '/assets/InfoTriangle_Icon.svg',
        altText: 'Info Icon'
    };

    const boldPlanningTypes = [PlanningType.PalletTotal, PlanningType.AvailableToTrade];

    const toggleExpand = () => {
        setActive(!active);
        product.isActive = !active;
    };

    const showAvailableToTradeBalance = GetFeatureFlag(FlagTypes.AvailableToTrade);

    useEffect(() => {
        if (prevExpand && expand.toggle !== prevExpand) {
            setActive(expand.toggle);
        }
    }, [expand]);

    useEffect(() => {
        setActive(product.isActive ?? false);
    }, [product.isActive]);

    useEffect(() => {
        if (weekRange !== range) {
            setWeekRange(range);
        }
    }, [range]);

    const getProductDescription = (product: AllocationProduct | OrderPlanningProduct) => {
        const productDestination = product.destinations?.find(
            (dest) => String(dest.shipToId) === selectedAccountId
        );
        const productId = productDestination?.customerProductId ?? product.productSku;
        const graphicId = product.graphicId ? ` / ${product.graphicId}` : '';
        if (product.productDescription || product.displayName) {
            return (
                <>
                    <Grid container justify="flex-start" alignItems="flex-start">
                        <Typography
                            className={clsx(
                                classes.productDescription,
                                product.isAged && classes.agedProductIdText
                            )}
                        >
                            {product.productDescription ?? product.displayName}
                        </Typography>
                    </Grid>
                    <Typography
                        className={clsx({ [classes.agedProductIdText]: product.isAged })}
                    >{`${product.description} (${productId}${graphicId})`}</Typography>
                </>
            );
        } else {
            return (
                <>
                    <Grid container justify="flex-start" alignItems="flex-start">
                        <Typography>{`${product.description} (${productId}${graphicId})`}</Typography>
                    </Grid>
                </>
            );
        }
    };

    const pendingPaymentNotification = (
        pendingPaymentCell: PendingPaymentPalletInfo,
        quantity: number,
        index: number,
        type: PlanningType
    ) => {
        if (pendingPaymentCell.firstRequestedDateInBucket) {
            const paymentStatus = getPaymentStatus(pendingPaymentCell.firstRequestedDateInBucket);
            if (paymentStatus === 'duePastThisMonth') {
                return (
                    <TableCell colSpan={1} key={index}>
                        <Grid container justify="flex-end" alignItems="center">
                            <Link
                                component="button"
                                underline="always"
                                data-testid="weekly-balance"
                                onClick={() => onWeeklyLookup(product, index, type, quantity)}
                            >
                                <Typography className={classes.linkWeekTotal} color="primary">
                                    {pendingPaymentCell.bucketQuantity}
                                </Typography>
                            </Link>
                        </Grid>
                    </TableCell>
                );
            } else {
                return (
                    <TableCell colSpan={1} key={index} className={classes.warningBackground}>
                        <Grid container justify="space-between" alignItems="center">
                            {paymentStatus === 'overdue' && (
                                <img
                                    src={errorIcon.src}
                                    alt={errorIcon.altText}
                                    data-testid="planning-alert-icon"
                                />
                            )}
                            {paymentStatus === 'dueThisWeek' && (
                                <img
                                    src={warningIcon.src}
                                    alt={warningIcon.altText}
                                    data-testid="planning-warning-icon"
                                />
                            )}
                            {paymentStatus === 'dueThisMonth' && (
                                <img
                                    src={infoIcon.src}
                                    alt={infoIcon.altText}
                                    data-testid="planning-info-icon"
                                />
                            )}
                            <Link
                                component="button"
                                underline="always"
                                data-testid="weekly-balance"
                                onClick={() => onWeeklyLookup(product, index, type, quantity)}
                            >
                                <Typography color="primary">
                                    {pendingPaymentCell.bucketQuantity}
                                </Typography>
                            </Link>
                        </Grid>
                    </TableCell>
                );
            }
        } else {
            return (
                <TableCell colSpan={1} key={index}>
                    <Typography>{pendingPaymentCell.bucketQuantity}</Typography>
                </TableCell>
            );
        }
    };

    const getProductRow = (product: AllocationProduct | OrderPlanningProduct) => {
        return (
            <>
                <TableRow
                    className={clsx(classes.altRow, classes.hidden, {
                        [classes.display]: active
                    })}
                    data-testid="production-balance-row"
                >
                    <TableCell
                        colSpan={1}
                        className={clsx(classes.borderlessCell, classes.activeHeaderCell)}
                        data-testid="production-balance-header"
                    >
                        <Typography className={classes.productDetails}>
                            <Trans i18nKey="productionBalance">Production Balance</Trans>
                        </Typography>
                    </TableCell>
                    <TableCell colSpan={1} />
                    {handleWeeklyQuantities(
                        product,
                        PlanningType.ProductionBalance,
                        getQuantities(
                            isLargeCustomer
                                ? product.productionBalance
                                    ? product.productionBalance
                                    : []
                                : product.previousPalletTotals
                                ? product.previousPalletTotals
                                : [],
                            product.quantityPerPallet
                        ),
                        true
                    )}
                </TableRow>
                {isCiaAccount && (
                    <TableRow
                        className={clsx(classes.altRow, classes.hidden, {
                            [classes.display]: active
                        })}
                        data-testid="pending-payment-row"
                    >
                        <TableCell
                            colSpan={1}
                            className={clsx(classes.borderlessCell, classes.activeHeaderCell)}
                            data-testid="orders-pending-header"
                        >
                            <Typography className={classes.productDetails}>
                                <Trans i18nKey="ordersPendingPayment">Orders Pending Payment</Trans>
                            </Typography>
                        </TableCell>
                        <TableCell colSpan={1} />
                        {handleWeeklyQuantities(
                            product,
                            PlanningType.PendingPayments,
                            product.previousPendingPaymentPalletTotals?.map((item) => {
                                return item.bucketQuantity;
                            }),
                            true,
                            product.previousPendingPaymentPalletTotals
                        )}
                    </TableRow>
                )}
                <TableRow
                    className={clsx(classes.altRow, classes.hidden, {
                        [classes.display]: active
                    })}
                    data-testid="shipments-row"
                >
                    <TableCell
                        colSpan={1}
                        className={clsx(classes.borderlessCell, classes.activeHeaderCell)}
                        data-testid="shipments-header"
                    >
                        <Typography className={classes.productDetails}>
                            <Trans i18nKey="shipments">Shipments</Trans>
                        </Typography>
                    </TableCell>
                    <TableCell colSpan={1} />
                    {handleWeeklyQuantities(
                        product,
                        PlanningType.Shipments,
                        getQuantities(
                            isLargeCustomer
                                ? product.shipments
                                : product.previousShipmentTotals
                                ? product.previousShipmentTotals
                                : [],
                            product.quantityPerPallet
                        ),
                        true
                    )}
                </TableRow>
                {showAvailableToTradeBalance && isLargeCustomer && (
                    <TableRow
                        className={clsx(classes.altRow, classes.hidden, {
                            [classes.display]: active
                        })}
                        data-testid="available-to-trade-row"
                    >
                        <TableCell
                            colSpan={1}
                            className={clsx(classes.borderlessCell, classes.activeHeaderCell)}
                            data-testid="available-to-trade-header"
                        >
                            <Typography className={classes.availableBalance}>
                                <Trans i18nKey="availableToTrade">Available to Trade</Trans>
                            </Typography>
                        </TableCell>
                        <TableCell colSpan={1} className={classes.borderlessCell}></TableCell>
                        {handleWeeklyQuantities(
                            product,
                            PlanningType.AvailableToTrade,
                            getAvailableToTradeQuantities(product)
                        )}
                    </TableRow>
                )}
            </>
        );
    };

    const handleWeeklyQuantities = (
        product: AllocationProduct | OrderPlanningProduct,
        planningType: PlanningType,
        quantities?: number[],
        isLink: boolean = false,
        pendingPaymentCells?: PendingPaymentPalletInfo[]
    ) => {
        let styledTableCells: Array<any> = [];
        const startCount = weekRange === TotalNDayRanges.nineWeeks ? 0 : longRangeStart;
        const endCount = weekRange === TotalNDayRanges.nineWeeks ? longRangeStart : longRangeEnd;
        quantities?.forEach((quantity, index) => {
            const displayQuantity = Math.max(0, quantity);

            if (index >= startCount && index < endCount) {
                if ((quantity === 0 || !isLink) && planningType !== PlanningType.PendingPayments) {
                    styledTableCells.push(
                        <TableCell className={classes.standardCell} data-testid="weekly-balance">
                            <Typography
                                className={
                                    boldPlanningTypes.includes(planningType)
                                        ? classes.boldTotal
                                        : classes.regularTotal
                                }
                            >
                                {getQuantityCellValue(displayQuantity, planningType, index)}
                            </Typography>
                        </TableCell>
                    );
                } else if (planningType === PlanningType.PendingPayments && pendingPaymentCells) {
                    styledTableCells.push(
                        <>
                            {pendingPaymentNotification(
                                pendingPaymentCells[index],
                                displayQuantity,
                                index,
                                PlanningType.PendingPayments
                            )}
                        </>
                    );
                } else {
                    styledTableCells.push(
                        <TableCell className={classes.standardCell}>
                            <Link
                                component="button"
                                underline="always"
                                data-testid="weekly-balance"
                                onClick={() =>
                                    onWeeklyLookup(product, index, planningType, quantity)
                                }
                            >
                                <Typography
                                    className={clsx(classes.textAlignRight, {
                                        [classes.boldTotal]:
                                            boldPlanningTypes.includes(planningType),
                                        [classes.regularTotal]:
                                            !boldPlanningTypes.includes(planningType)
                                    })}
                                    color="primary"
                                >
                                    {getQuantityCellValue(displayQuantity, planningType, index)}
                                </Typography>
                            </Link>
                        </TableCell>
                    );
                }
            }
        });
        return styledTableCells;
    };

    const getQuantityCellValue = (
        displayQuantity: number,
        planningType: PlanningType,
        index: number
    ) => {
        if (planningType === PlanningType.AvailableToTrade && index > 0) {
            return '-';
        }
        return quantityUnit === 'pallets'
            ? `${displayQuantity.toLocaleString()} PL`
            : wrapEachesForTableCell(formatNumberWithLocale(cultureCode, displayQuantity));
    };

    const getQuantities = (palletQuantity: number[], quantityPerPallet?: number) => {
        if (quantityUnit === 'eaches' && quantityPerPallet) {
            return palletQuantity.map((quantity) => {
                return quantity * quantityPerPallet;
            });
        } else {
            return palletQuantity;
        }
    };

    const getNetQuantities = (
        productionBalancePalletQuantity: number[],
        shipmentPalletQuantity: number[],
        quantityPerPallet?: number
    ) => {
        if (quantityUnit === 'eaches' && quantityPerPallet) {
            return productionBalancePalletQuantity.map((productionBalanceQuantity, index) => {
                return (
                    (productionBalanceQuantity -
                        (shipmentPalletQuantity.length > index
                            ? shipmentPalletQuantity[index]
                            : 0)) *
                    quantityPerPallet
                );
            });
        }
        return productionBalancePalletQuantity.map((productionBalanceQuantity, index) => {
            return (
                productionBalanceQuantity -
                (shipmentPalletQuantity.length > index ? shipmentPalletQuantity[index] : 0)
            );
        });
    };

    // Available to trade quantity should only be shown for the first/current week,
    // so create an array of empty data as long as the other fields and only populate the first index
    // FutureShipmentsTotal returns total pallets of all shipments after today, so this week's shipments need to be subtracted as well
    const getAvailableToTradeQuantities = (product: AllocationProduct | OrderPlanningProduct) => {
        const palletQuantity = Math.max(
            0,
            product.productionBalance[0] - product.shipments[0] - product.futureShipmentsTotal
        );
        var quantities = Array(product.productionBalance.length).fill(0);
        if (quantityUnit === 'eaches' && product.quantityPerPallet) {
            quantities[0] = palletQuantity * product.quantityPerPallet;
        } else {
            quantities[0] = palletQuantity;
        }
        return quantities;
    };

    useTranslation();

    return (
        <>
            <TableRow className={classes.standardRow} data-testid="production-description-row">
                <TableCell
                    colSpan={1}
                    className={classes.standardCell}
                    data-testid="production-description-header"
                >
                    <Grid container>
                        {product.isAged && (
                            <img
                                data-testid="aging-balance-icon"
                                className={classes.clockIcon}
                                src={process.env.PUBLIC_URL + '/assets/icon-clock.png'}
                                alt="Aging Balance"
                            />
                        )}
                        <Grid item xs={product.isAged ? 9 : 10}>
                            <Typography className={classes.productDescription}>
                                {getProductDescription(product)}
                            </Typography>
                            {product.campaignTiming && (
                                <CampaignPill
                                    styles={clsx({
                                        [classes.agedProductIdText]: product.isAged
                                    })}
                                />
                            )}
                        </Grid>
                        <Grid item className={classes.expandContainer}>
                            <ExpandMoreIcon
                                className={clsx(classes.expand, {
                                    [classes.expandOpen]: active
                                })}
                                onClick={() => toggleExpand()}
                                color="primary"
                                data-testid="expand-icon"
                            />
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell
                    colSpan={1}
                    className={classes.standardCell}
                    data-testid="coating-header"
                >
                    <Grid item>
                        <Typography className={classes.boldTotal}>{product.coating}</Typography>
                    </Grid>
                </TableCell>

                {handleWeeklyQuantities(
                    product,
                    PlanningType.PalletTotal,
                    getNetQuantities(
                        isLargeCustomer ? product.productionBalance : product.previousPalletTotals!,
                        isLargeCustomer ? product.shipments : product.previousShipmentTotals!,
                        product.quantityPerPallet
                    )
                )}
            </TableRow>
            {getProductRow(product)}
        </>
    );
}
