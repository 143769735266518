import { PricingLine } from "../../store/reducers/pricing-breakdown";
import { Culture } from "../../store/reducers/region-culture";
import { currencyFormatter } from "./formatters";

export function formatPricingBreakdownData(lines: PricingLine[], cultureCode: Culture, currency: string) {
    const formattedLines: PricingLine[] = [];
    lines.forEach((line: PricingLine) => {
        line.currentBasePriceFormat = currencyFormatter(cultureCode, line.currentBasePrice, currency);
        line.freightChargesFormat = validatedPricingLineData(cultureCode, currency, line.freightCharges);
        line.newBasePriceFormat = currencyFormatter(cultureCode, line.newBasePrice, currency);
        line.newBasePriceWithFreightFormat = validatedPricingLineData(cultureCode, currency, line.newBasePriceWithFreight);
        formattedLines.push(line);
    });
    return formattedLines;
}

export function validatedPricingLineData(cultureCode: Culture, currency: string, value?: number | string) {
    // Adding a string and undefined check here to prevent unintentional NaN vaules
    if (typeof value !== 'undefined' && typeof value !== 'string') {
        return currencyFormatter(cultureCode, value, currency);
    } else if (typeof value === 'undefined') {
        return '-';
    }
}

