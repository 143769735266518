import { ColumnDataType, createColumn } from 'tubular-common';
import { FlagTypes, GetFeatureFlag } from '../../../../utility/helpers/feature-flag';
import TranslationService from '../../../../utility/services/translation-service';

export default class DeliveryOrderColumns {
    static getDeliveryOrderColumns = (culture: string) => {
        const cokeFeatureFlag = GetFeatureFlag(FlagTypes.Coke);

        return [
            createColumn('loadId', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('productionorderNumber', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('displayId', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'customerProductGraphicId'),
                filterable: true,
                sortable: true,
                searchable: false
            }),
            createColumn('productSku', {
                dataType: ColumnDataType.String,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            }),
            createColumn('displayName', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'descriptionCharacteristics'),
                filterable: true,
                sortable: true,
                searchable: false
            }),
            createColumn('description', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('name', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('palletQuantity', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'quantity'),
                filterable: true,
                sortable: true,
                searchable: false
            }),
            createColumn('quantityPerPallet', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('releaseNumber', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'releaseNumber'),
                filterable: true,
                sortable: true,
                searchable: false
            }),
            createColumn('fillerLine', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'fillerLineNumber'),
                filterable: true,
                sortable: true,
                searchable: false
            }),
            createColumn('referenceNumber', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'referenceNumber'),
                filterable: true,
                sortable: true,
                searchable: false
            }),
            createColumn('purchaseOrderNumber', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'customerPoNumber'),
                filterable: true,
                sortable: true,
                searchable: false,
                visible: !!cokeFeatureFlag
            }),
            createColumn('productGraphicId', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('deliveryInstructions', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('quantityPerPallet', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('type', {
                dataType: ColumnDataType.String,
                visible: false
            })
        ];
    };
}
