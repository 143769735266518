import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { Draggable as DNDDraggable } from 'react-beautiful-dnd';
import { DragAndDropItem } from './models';

interface Props {
    item: DragAndDropItem;
    index: number;
    styleConfig: DraggableStyleProps;
    disabled?: boolean
}

export interface DraggableStyleProps {
    draggablePaddingBottom: string;
}

const useStyles = makeStyles<Theme, DraggableStyleProps>(() => ({
    draggable: {
        margin: '0 0 1em 0'
    }
}));

export default function Draggable({ item, index, styleConfig, disabled }: Props) {
    const classes = useStyles(styleConfig);
    const Component = item.component as React.ComponentType;
    return (
        <DNDDraggable isDragDisabled={disabled} draggableId={item.id} index={index}>
            {(provided) => (
                <div
                    className={classes.draggable}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                >
                    <Component {...item.props} dragHandleProps={provided.dragHandleProps} />
                </div>
            )}
        </DNDDraggable>
    );
}
