import { ColumnDataType, createColumn } from 'tubular-common';
import TranslationService from '../../../../utility/services/translation-service';

export default class ProductionOrderDetailsColumns {
    static getProductionOrderDetailsColumns = (culture: string) => {
        return [
            createColumn('productionOrderLineId', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('displayId', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'customerProductGraphicId'),
                filterable: true,
                sortable: true,
                searchable: false
            }),
            createColumn('displayName', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'descriptionCharacteristics'),
                filterable: true,
                sortable: true,
                searchable: false
            }),
            createColumn('productDescription', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productSku', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productName', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productCoating', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'coating'),
                filterable: true,
                sortable: true,
                searchable: false
            }),
            createColumn('palletQuantity', {
                dataType: ColumnDataType.Numeric,
                label: TranslationService.getTranslatedText(culture, 'quantityToDeliver'),
                filterable: true,
                sortable: true,
                searchable: false
            }),
            createColumn('requestedDate', {
                dataType: ColumnDataType.Date,
                label: TranslationService.getTranslatedText(culture, 'requestedDate'),
                filterable: true,
                sortable: true,
                searchable: false
            }),
            createColumn('requiredShipDate', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'requiredShipDate'),
                filterable: true,
                sortable: true,
                searchable: false
            }),
            createColumn('availableQuantity', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: false
            }),
            createColumn('availableEaches', {
                dataType: ColumnDataType.String,
                visible: false,
                searchable: false
            }),
            createColumn('status', {
                dataType: ColumnDataType.String,
                label: TranslationService.getTranslatedText(culture, 'lineStatus'),
                filterable: true,
                sortable: true,
                searchable: false
            }),
            createColumn('productUnits', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productSize', {
                dataType: ColumnDataType.Numeric,
                visible: false
            }),
            createColumn('productShape', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productNeckSize', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productGraphicId', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productTabColor', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productType', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('productEndProfile', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('customerProductId', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('customerProductName', {
                dataType: ColumnDataType.String,
                visible: false
            }),
            createColumn('assessedFee', {
                dataType: ColumnDataType.Numeric,
                visible: false,
                searchable: false
            }),
            createColumn('quantityPerPallet', {
                dataType: ColumnDataType.Numeric,
                filterable: false,
                sortable: false,
                searchable: false,
                visible: false
            })
        ];
    };
}
