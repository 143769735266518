import React from 'react';
import PdfPageTemplate from '../../../templates/PdfPageTemplate';
import { Document, Page, Text, View, BlobProvider, Image } from '@react-pdf/renderer';
import pdfStyles from '../../../../themes/pdfStyles';
import { formatFromDatetime, getUtcMidnight } from '../../../../utility/helpers/date-helpers';
import { isIE } from '../../../../utility/helpers/user-helpers';
import {
    constructPDFCanDescription,
    constructPDFEndDescription,
    getEachesForOrder,
    getGraphicIdAndVersion,
    getTotalQuantityAmounts
} from '../../../../utility/helpers/order-helpers';
import { Trans, useTranslation } from 'react-i18next';
import { ProductionOrder, ProductionOrderLine } from '../../../../store/reducers/makeit-dashboard';
import { enUS } from '../../../../utility/translations/locales';
import { ProductType } from '../../../../utility/services/orders-service';
import { RegionCultureState } from '../../../../store/reducers/region-culture';
import { useTypedSelector } from '../../../../store/reducers/reducer';
import { formatNumberWithLocale } from '../../../../utility/helpers/formatters';
import { getEachesConversion } from '../../../../utility/helpers/shipment-helpers';

interface Props {
    title: string;
    productionOrder: ProductionOrder;
    lines: ProductionOrderLine[];
    shipToAddress: string;
    shipToName: string;
}

const EditProductionOrderConfirmationPDF = ({
    title,
    productionOrder,
    lines,
    shipToAddress,
    shipToName
}: Props) => {
    const ballLogo = process.env.PUBLIC_URL + '/assets/Ball_RGB.jpg';
    const sourceLogo = process.env.PUBLIC_URL + '/assets/TheSource_Logo.png';
    const { t } = useTranslation();
    const { cultureCode } = useTypedSelector<RegionCultureState>((state) => state.regionCulture);

    const formatDate = (date?: string) => {
        if (date) {
            return getUtcMidnight(date).format('MM/DD/YYYY');
        } else {
            return '';
        }
    };

    const getColumnHeads = () => {
        return (
            <>
                <View style={pdfStyles.tableRow}>
                    <View style={[pdfStyles.borderLessTableColNarrow3]}>
                        <Text style={[pdfStyles.header2]}>
                            {t('productGraphicId', 'PRODUCT ID / GRAPHIC ID').toLocaleUpperCase()}
                        </Text>
                    </View>
                    <View style={pdfStyles.borderLessTableColNarrow4}>
                        <Text style={pdfStyles.header2}>
                            {t(
                                'productDescription',
                                'PRODUCT DESCRIPTION / CHARACTERISTICS'
                            ).toLocaleUpperCase()}
                            /
                        </Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableColNarrow8]}>
                        <Text style={[pdfStyles.header2]}>
                            {t('coating', 'Coating').toLocaleUpperCase()}
                        </Text>
                    </View>
                    <View style={pdfStyles.borderLessTableColNarrow8}>
                        <Text style={[pdfStyles.header2]}>
                            {t(
                                'originalRequestedDate',
                                'Original Requested Date'
                            ).toLocaleUpperCase()}
                        </Text>
                    </View>
                    <View style={pdfStyles.borderLessTableColNarrow8}>
                        <Text style={[pdfStyles.header2]}>
                            {t(
                                'revisedRequestedDate',
                                'Revised Requested Date'
                            ).toLocaleUpperCase()}
                        </Text>
                    </View>
                    <View style={pdfStyles.borderLessTableColNarrow8}>
                        <Text style={[pdfStyles.header2]}>
                            {t(
                                'originalQuantityOrdered',
                                enUS.originalQuantityOrdered
                            ).toLocaleUpperCase()}
                        </Text>
                    </View>
                    <View style={pdfStyles.borderLessTableColNarrow8}>
                        <Text style={[pdfStyles.header2]}>
                            {t(
                                'revisedQuantityOrdered',
                                'Revised Quantity Ordered'
                            ).toLocaleUpperCase()}
                        </Text>
                    </View>
                </View>
            </>
        );
    };

    const isDateChanged = (line: ProductionOrderLine) => {
        let isDateChanged = false;
        if (formatDate(line.originalRequestedDate) !== formatDate(line.requestedDate)) {
            isDateChanged = true;
        }
        return isDateChanged;
    };

    const isQuantityChanged = (line: ProductionOrderLine) => {
        let isQuantityChanged = false;
        if (line.palletQuantity !== line.originalPalletQuantity) {
            isQuantityChanged = true;
        }
        return isQuantityChanged;
    };

    const lineItems = lines?.map((line) => {
        const graphicId = getGraphicIdAndVersion(line.productType, line.productGraphicId);

        const getDesc = (line) => {
            return line.type === 'END'
                ? constructPDFEndDescription(line)
                : constructPDFCanDescription(line);
        };

        return (
            <>
                <View style={pdfStyles.divider7} />
                <View style={pdfStyles.tableRow}>
                    <View style={pdfStyles.borderLessTableColNarrow3}>
                        <Text style={[pdfStyles.tableCell2]}>
                            {line.displayId}
                            {' / '}
                            {graphicId}
                        </Text>
                    </View>
                    <View style={pdfStyles.borderLessTableColNarrow4}>
                        <Text style={[pdfStyles.tableCell2]}>
                            {line.displayName}
                            {' / '}
                            {getDesc(line)}
                        </Text>
                    </View>
                    <View style={[pdfStyles.borderLessTableColNarrow2]}>
                        <Text style={[pdfStyles.tableCell2]}>{line.productCoating}</Text>
                    </View>
                    <View style={pdfStyles.borderLessTableColNarrow2}>
                        <Text style={[pdfStyles.tableCell2]}>
                            {formatDate(line.originalRequestedDate)}
                        </Text>
                    </View>
                    <View style={pdfStyles.borderLessTableColNarrow2}>
                        <Text style={[pdfStyles.tableCell2]}>
                            {line.isLineRemoved
                                ? t('removed', 'REMOVED')
                                : isDateChanged(line)
                                ? formatDate(line.requestedDate)
                                : '-'}
                        </Text>
                    </View>
                    <View style={pdfStyles.borderLessTableColNarrow2}>
                        <Text style={[pdfStyles.tableCell2]}>
                            {line.originalPalletQuantity?.toLocaleString() + ' PL'}
                        </Text>
                        <Text style={[pdfStyles.subtitle7]}>
                            {line.originalPalletQuantity &&
                                getEachesConversion(
                                    line.originalPalletQuantity!,
                                    line.quantityPerPallet!
                                )}
                        </Text>
                    </View>
                    <View style={pdfStyles.borderLessTableColNarrow2}>
                        <Text style={[pdfStyles.tableCell2]}>
                            {isQuantityChanged(line)
                                ? line.palletQuantity?.toLocaleString() + ' PL'
                                : '-'}
                        </Text>
                        <Text style={[pdfStyles.subtitle7]}>
                            {isQuantityChanged(line) &&
                                getEachesConversion(line.palletQuantity!, line.quantityPerPallet!)}
                        </Text>
                    </View>
                </View>
            </>
        );
    });

    const getQuantity = (type: string): String => {
        const quantityInformation = getTotalQuantityAmounts(type, [productionOrder]);
        return `${formatNumberWithLocale(
            cultureCode,
            quantityInformation.pallets
        )} PL/${formatNumberWithLocale(cultureCode, quantityInformation.skus)} ${
            quantityInformation.skus > 1 ? t('skus', 'SKUs') : t('sku', 'SKU')
        }`;
    };

    const myDoc = (
        <Document title={title}>
            <Page size="A4" style={pdfStyles.page} orientation={'portrait'}>
                <View style={pdfStyles.logoSection} fixed>
                    <Image src={sourceLogo} style={pdfStyles.sourceLogo} />
                    <Image src={ballLogo} style={pdfStyles.ballLogo} />
                </View>
                <View style={pdfStyles.divider6} fixed />
                <View style={pdfStyles.borderlessTable}>
                    <View style={pdfStyles.tableRow}>
                        <View style={[pdfStyles.borderLessTableColWide4]}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="customerPoNumber">Customer PO #</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow2}>
                            <Text style={[pdfStyles.header4]}>
                                <Trans i18nKey="dateRevised">Date Revised</Trans>
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                        <View style={[pdfStyles.borderLessTableColWide4]}>
                            <Text style={[pdfStyles.subtitle6]}>
                                {productionOrder?.customerProductionOrderId
                                    ? productionOrder?.customerProductionOrderId
                                    : productionOrder?.productionOrderNumber}
                            </Text>
                        </View>
                        <View style={[pdfStyles.borderLessTableColNarrow2]}>
                            <Text style={[pdfStyles.subtitle5]}>
                                {formatFromDatetime(productionOrder.updateDate as string)}
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={pdfStyles.divider6} fixed />

                <View style={pdfStyles.borderlessTable}>
                    <View style={pdfStyles.tableRow}>
                        <View style={pdfStyles.borderLessTableColWide5}>
                            <Text style={pdfStyles.header2}>
                                <Trans i18nKey="shipTo">Ship To</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow3}>
                            <Text style={pdfStyles.header4}>
                                <Trans i18nKey="cans">Cans</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow3}>
                            <Text style={pdfStyles.header4}>
                                <Trans i18nKey="ends">Ends</Trans>
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow3}>
                            <Text style={pdfStyles.header4}>
                                <Trans i18nKey="ballOrderNumber">Ball Order #</Trans>
                            </Text>
                        </View>
                    </View>
                    <View style={pdfStyles.tableRow}>
                        <View style={[pdfStyles.borderLessTableColWide5]}>
                            <Text style={pdfStyles.subtitle3}>{shipToName}</Text>
                            <Text style={pdfStyles.subtitle3}>{shipToAddress}</Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow3}>
                            <Text style={[pdfStyles.subtitle4]}>
                                {getQuantity(ProductType.Cans)}
                            </Text>
                            <Text style={[pdfStyles.subtitle15]}>
                                {getEachesForOrder(
                                    productionOrder,
                                    ProductType.Cans
                                ).toLocaleString()}{' '}
                                ea.
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow3}>
                            <Text style={[pdfStyles.subtitle4]}>
                                {getQuantity(ProductType.Ends)}
                            </Text>
                            <Text style={[pdfStyles.subtitle15]}>
                                {getEachesForOrder(
                                    productionOrder,
                                    ProductType.Ends
                                ).toLocaleString()}{' '}
                                ea.
                            </Text>
                        </View>
                        <View style={pdfStyles.borderLessTableColNarrow3}>
                            <Text style={[pdfStyles.subtitle4]}>
                                {productionOrder.productionOrderNumber}
                            </Text>
                        </View>
                    </View>

                    {getColumnHeads()}
                    {lineItems}
                </View>
                <View style={[pdfStyles.fullWidth, pdfStyles.footer]} fixed>
                    <Text
                        style={pdfStyles.header2}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                </View>
            </Page>
        </Document>
    );

    if (!isIE()) {
        return <PdfPageTemplate title={title}>{myDoc}</PdfPageTemplate>;
    } else {
        return (
            <BlobProvider document={myDoc}>
                {({ blob, url, loading, error }) => {
                    if (blob && window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob);
                    }
                    return null;
                }}
            </BlobProvider>
        );
    }
};

export default EditProductionOrderConfirmationPDF;
